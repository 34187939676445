import moment from 'moment';
import { api } from 'helpers/api';
import { success, error } from 'helpers/response';
import { filters } from './constants';
import {
	CLEAR_ROADS_DATA,
	SET_BNSO_LIST,
	SET_CARRIER_UNITS,
	SET_COMPONENT,
	SET_DICTIONARIES,
	SET_HISTORY_ORDERS,
	SET_HISTORY_ORDER_EXECUTIONS,
	SET_LOADING,
	SET_ROAD,
	SET_ROAD_PART,
	SET_ROAD_REPAIR_PART,
	SET_UNITS,
	SET_MO,
	SET_MUNICIPALITIES,
	SET_FILTERS,
	SET_DIAPASON,
	SET_ANALITIC_DIAPASON,
	SET_SHOW_EVENTS,
	SET_SHOW_HISTORY,
	SET_SHOW_HISTORY_SLIDER,
	SET_ROUTE,
	SET_ROUTES,
	SET_ROUTE_STOP_POINTS,
	SET_SELECTED_ROUTES,
	SET_SELECTED_ROUTE_VARIANTS,
	SET_VEHICLE_FOR_POPUP,
	SET_TELEMATICS,
	SET_OPERATOR_COMPONENTS,
	SET_OPERATOR_COMPONENTS_FILTER_SHOWN,
} from './actions';

const initialState = {
	component: null,
	original_component: null,
	dictionaries: {},
	units: [],
	carrierUnits: [],
	bnso: [],
	road: null,
	roadPart: null,
	roadRepairPart: null,
	loading: false,
	historyOrders: [],
	historyOrderExecutions: [],
	mo: [],
	municipalities: [],
	filters,
	from_date: moment(),
	from_time: moment().subtract(6, 'hour').format('HH:mm'),
	to_date: moment(),
	to_time: moment().format('HH:mm'),
	from_analytic: moment().startOf('day'),
	to_analytic: moment().endOf('day'),
	showEvents: false,
	showHistory: false,
	showHistorySlider: false,
	route: null,
	routes: [],
	routePanelOpened: false,
	routeStopPoints: {},
	selectedRoutes: [],
	selectedRouteVariants: [],
	vehicleForPopup: {},
	shownTelematics: {},
	operatorComponents: [],
	isOperatorComponentsFilterShown: false,
};
export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SET_DICTIONARIES:
			return { ...state, dictionaries: { ...state.dictionaries, ...action.payload } };
		case SET_UNITS:
			return { ...state, units: action.payload };
		case SET_CARRIER_UNITS:
			return { ...state, carrierUnits: action.payload };
		case SET_BNSO_LIST:
			return { ...state, bnso: action.payload };
		case SET_COMPONENT:
			return { ...state, component: action.payload, original_component: action.payload};
		case SET_ROAD:
			return { ...state, road: action.payload, roadPart: null, roadRepairPart: null, loading: false };
		case SET_ROAD_PART:
			return { ...state, roadPart: action.payload, road: null, roadRepairPart: null, loading: false };
		case SET_ROAD_REPAIR_PART:
			return { ...state, roadRepairPart: action.payload, road: null, roadPart: null, loading: false };
		case CLEAR_ROADS_DATA:
			return { ...state, road: null, roadRepairPart: null, roadPart: null };
		case SET_LOADING:
			return { ...state, loading: action.payload };
		case SET_HISTORY_ORDERS:
			return { ...state, historyOrders: action.payload };
		case SET_HISTORY_ORDER_EXECUTIONS:
			return { ...state, historyOrderExecutions: action.payload };
		case SET_MO:
			return { ...state, mo: action.payload };
		case SET_MUNICIPALITIES:
			return { ...state, municipalities: action.payload };
		case SET_FILTERS:
			return { ...state, filters: action.payload };
		case SET_DIAPASON:
			return { ...state, [action.payload.field]: action.payload.value };
		case SET_ANALITIC_DIAPASON:
			return { ...state, [action.payload.field]: action.payload.value };
		case SET_SHOW_EVENTS:
			return { ...state, showEvents: action.payload };
		case SET_SHOW_HISTORY:
			return { ...state, showHistory: action.payload };
		case SET_SHOW_HISTORY_SLIDER:
			return { ...state, showHistorySlider: action.payload };
		case SET_ROUTE:
			return { ...state, route: action.payload };
		case SET_ROUTES:
			return { ...state, routes: action.payload };
		case SET_ROUTE_STOP_POINTS:
			return { ...state, routeStopPoints: action.payload };
		case SET_SELECTED_ROUTES:
			return { ...state, selectedRoutes: action.payload };
		case SET_SELECTED_ROUTE_VARIANTS:
			return { ...state, selectedRouteVariants: action.payload };
		case SET_VEHICLE_FOR_POPUP:
			return { ...state, vehicleForPopup: action.payload };
		case SET_TELEMATICS:
			return { ...state, shownTelematics: {...state.shownTelematics, ...action.payload} };
		case SET_OPERATOR_COMPONENTS:
			return { ...state, operatorComponents: action.payload };
		case SET_OPERATOR_COMPONENTS_FILTER_SHOWN:
			return { ...state, isOperatorComponentsFilterShown: action.payload}
	}
	return state;
}

export const getTelematics = (source, compression = 'precalculated', boundingBox = null, mapSize = null) => async (
	dispatch
) => {
	try {
		const response = await api.telematics.getTelematics(source, compression, boundingBox, mapSize);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getTelematicsWithDevices = (
	devices,
	source,
	boundingBox,
	mapSize,
	compression = 'precalculated',
	zoom = null,
	withClusters = true
) => async (dispatch) => {
	try {
		const response = await api.telematics.getTelematicsWithDevices(
			devices,
			source,
			boundingBox,
			mapSize,
			compression,
			zoom,
			withClusters
		);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getTelematicsBoundsWithDevices = (devices, source) => async (dispatch) => {
	try {
		const response = await api.telematics.getTelematicsBoundsWithDevices(devices, source);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getTelematicsWithTemperatureGrpc = (devices) => async (dispatch) => {
	try {
		const response = await api.telematics.getTelematicsWithTemperatureGrpc(devices);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getObjectsHistoryData = (devices, dateFrom, dateTo) => async (dispatch) => {
	try {
		const response = await api.telematics.getObjectsHistoryData(devices, dateFrom, dateTo);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getTelematicsLogs = (meta = {}) => async (dispatch) => {
	try {
		const response = await api.telematics.getTelematicsLogs(meta);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getPanics = (meta = {}) => async (dispatch) => {
	try {
		const response = await api.telematics.getPanics(meta);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getHistory = (device_id, date_start, date_end, full = false) => async (dispatch) => {
	try {
		const response = await api.t1sync.getHistory(device_id, date_start, date_end, full);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getHistoryByVehicle = (vehicle_uuid, date_start, date_end, full = false) => async (dispatch) => {
	try {
		const response = await api.t1sync.getHistoryByVehicle(vehicle_uuid, date_start, date_end, full);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getDeviceExtendedData = (bnsoNumber, port, sensor) => async (dispatch) => {
	try {
		const response = await api.t1sync.getDeviceExtendedData(bnsoNumber, port, sensor);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getOdometrMultiple = (bnsoNumbers, timestamp) => async (dispatch) => {
	try {
		const response = await api.t1sync.getOdometrMultiple(bnsoNumbers, timestamp);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getEtpEvents = (data) => async (dispatch) => {
	try {
		const response = await api.t1sync.getEtpEvents(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getMotohours = (bnsoNumbers, timestamp) => async (dispatch) => {
	try {
		const response = await api.t1sync.getMotohours(bnsoNumbers, timestamp);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getMileage = (bnsoNumbers, timestamp) => async (dispatch) => {
	try {
		const response = await api.t1sync.getMileage(bnsoNumbers, timestamp);

		return success(response);
	} catch (e) {
		return error(e);
	}
};
