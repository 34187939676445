import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import $ from 'jquery';
import classNames from 'classnames';

import './style.less';
import ContextTooltip from "components/ui/context-tooltip";
import {connect} from "react-redux";
import {getUnits} from "store/reducers/organizational_units/units";
import {getRouteRegistries} from "store/reducers/kiutr/route_registries";
import {LabeledCheckbox} from "components/ui/checkbox";
import AccordionItem from "components/ui/accordion/accordion-item";
import Accordion from "components/ui/accordion/accordion";
import IconButton from "components/ui/icon-button";
import Popup from "components/ui/popup";
import GlobalLoaderComponent from "components/ui/global-loader";
import LoaderComponent from "components/ui/loader";
import debounce from 'throttle-debounce/debounce';
import {makeCancelable} from "helpers/promise";
import {getRoutes} from "store/reducers/routes/route_editor";

@propTypes({
    onSelect: PropTypes.func.isRequired,
})

@connect(state => ({}), {getUnits, getRouteRegistries, getRoutes})

export default class RouteSelectBtn extends Component {

    state = {
        units: [],
        routes: [],
        showPopup: false,
        loading: false,
    };

    searchPromise = null;

    searchCallbackDebounce = debounce(300, ::this.onSearch);

    async componentDidMount() {
        /*this.setState({
            loading: true,
        });
        await this.loadUnits();
        this.setState({
            loading: false,
        });*/
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            filters: {
                withComponent: 'kiutr',
            },
        });

        if (response.isOk) {
            this.setState({
                units: _.sortBy(response.payload.items, 'name'),
            });
        } else {
            response.showErrors();
        }
    }

    async onSearch() {
        this.setState({
            loading: true,
        });
        if (this.searchPromise) {
            this.searchPromise.cancel();
        }

        this.searchPromise = makeCancelable(this.props.getRoutes({
            search: this.state.search,
            pagination: {
                limit: 20,
                page: 1,
            },
        }));
        const response = await this.searchPromise.promise;
        this.setState({
            loading: false,
        });

        if (response.isOk) {
            this.setState({
                routes: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        const popup = this.state.showPopup ? this.renderPopup() : '';

        return (
            <div>
                <ContextTooltip key="base-table-list.create" code="base-table-list.create" default="Добавить маршрут" position="left">
                    <IconButton
                        icon="plus"
                        ref="button"
                        onClick={::this.onToggle}/>
                </ContextTooltip>
                {popup}
            </div>
        );
    }

    async onSearchChange() {
        const search = this.refs.search.value;
        await this.setState({
            search,
        });

        this.searchCallbackDebounce();
    }

    renderPopup() {
        const loader = this.state.loading ? (<LoaderComponent color="red"/>) : null;

        return (
            <Popup
                className="SelectFieldsPopup top-link accordion-dropdown"
                show={true}
                onClose={::this.onToggle}>
                <div className="popup-container__content">
                    <div className="filtration__search">
                        <input type="text" ref="search" value={this.state.search} className="filtration__pole"
                               placeholder="Поиск"
                               onChange={::this.onSearchChange}
                        />
                        <input type="button" className="filtration__button"/>
                    </div>
                    <div className="content">
                        {loader}
                        {_.map(this.state.routes, ::this.renderRoute)}
                    </div>
                </div>
            </Popup>
        );
    }

    renderRoute(route) {
        return (
            <div key={route.uuid} className="route-select-item">
                <a href="#"
                   onClick={this.onClick.bind(this, route)}>{_.get(route, 'number')}</a>
                <span>{_.get(route, 'title')}</span>
            </div>
        );
    }

    onClick(route, e) {
        e.preventDefault();

        this.props.onSelect(route);
    }

    onToggle() {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    }

}