import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getReports = () => async (dispatch) => {
    try {
        const response = await api.reports.getReports();

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getSolveProblemWidgetData = (data = {}, meta) => async (dispatch) => {
    try {
        const response = await api.reports.getSolveProblemWidgetData(data, meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getReportsViolationsKiutr = (payload) => async (dispatch) => {
    try {
        const response = await api.reports.getReportsViolationsKiutr(payload);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getReportParameters = (report, parameters = []) => async (dispatch) => {
    try {
        const response = await api.reports.getReportParameters(report, parameters);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createReport = (data, meta) => async (dispatch) => {
    try {
        const response = await api.reports.createReport(data, meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getDocumentsList = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.reports.getDocumentsList(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const listReportTemplates = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.reports.listReportTemplates(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteReportTemplate = (data) => async (dispatch) => {
    try {
        const response = await api.reports.deleteReportTemplate(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};