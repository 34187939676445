import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import _ from 'lodash';

import {connect} from "react-redux";
import {getReports} from "store/reducers/reports/reports";
import GlobalLoaderComponent from "components/ui/global-loader";
import moment from "moment";
import formats from "dictionaries/formats";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Input from "components/ui/form/input";
import {isTest, isLocalhost} from "helpers/functions";

@propTypes({
    selectedReport: PropTypes.object,
    onReportClick: PropTypes.func.isRequired,
})

@connect((state) => ({}), {getReports}, null, {withRef: true})

export default class ReportList extends Component {

 /*   repA = {
        name: 'Отчет о соблюдении расписания по Государственным контрактам 2019',
        link: `/files/Отчет о соблюдении расписания по Государственным контрактам 2019.xlsx`
    };

    repB = {
        name: 'Отчет о соблюдении расписания по муниципальным контрактам 2019',
        link: `/files/Отчет о соблюдении расписания по муниципальным контрактам 2019.xlsx`
    };*/

    state = {
        loading: true,
        reports: [],
        search: '',
    };

    async componentDidMount() {
        const response = await this.props.getReports();
        if (response.isOk) {
            this.setState({
                loading: false,
                reports: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    setActiveReport(uri) {
        if (this.state.loading) {
            setTimeout(this.setActiveReport.bind(this, uri), 500);
            return;
        }

        _.each(this.state.reports, (reportGroup, index) => {
            const report = _.find(reportGroup.items, {
                uri,
            });

            if (report) {
                this.setState({
                    search: report.name,
                });
                this.refs[`group-${index}`].toggle();
                return false;
            }
        });
    }

    render() {
        const loader = this.state.loading ? <GlobalLoaderComponent/> : null;

        return (
            <div className="page-block available-reports">
                <div className="page-block__caption">
                    Доступные отчеты
                </div>
                <div className={classNames('page-block__content', {
                    'reports-single-block': this.state.reports.length === 1,
                })}>
                    <Input
                        size="md"
                        value={this.state.search}
                        onChange={::this.onSearch}
                        placeholder="Поиск доступных отчетов..."
                    />
                    <Accordion>
                        {this.state.reports.map(::this.renderReportGroup)}
                    </Accordion>
                </div>
                {loader}
            </div>
        );
    }

    onSearch({target: {value}}) {
        this.setState({
            search: value,
        });
    }

    // Удаление отчетов по подсистемам, согласно файлу настроек - если подсистема отключена, значит отчеты удаляем полностью
    deleteReportCategories(reportGroup) {
        if (!window.RNIS_SETTINGS.CUSTOMMENU) return;
        let a = window.RNIS_SETTINGS.CUSTOMMENUSTATE;
        let categoriesNames = [
            { name: _.find(a, ['id', '100']).text, show: _.find(a, ['id', '100']).show }, // Оператор системы
            { name: _.find(a, ['id', '116']).text, show: _.find(a, ['id', '116']).show },   // Аналитическая платформа
            { name: _.find(a, ['id', '147']).text, show: _.find(a, ['id', '147']).show },  // Контроль пассажирских перевозок
            { name: _.find(a, ['id', '125']).text, show: _.find(a, ['id', '125']).show },  // Управление пассажирскими перевозками
            { name: _.find(a, ['id', '139']).text, show: _.find(a, ['id', '139']).show },  // Электронная маршрутная сеть
            { name: _.find(a, ['id', '161']).text, show: _.find(a, ['id', '161']).show },  // Обслуживание дорог
            { name: _.find(a, ['id', '181']).text, show: _.find(a, ['id', '181']).show },  // Коммунальная техника
            { name: _.find(a, ['id', '186']).text, show: _.find(a, ['id', '186']).show },  // Управление перевозками детей
            { name: _.find(a, ['id', '198']).text, show: _.find(a, ['id', '198']).show },  // Контроль вывоза мусора
            { name: _.find(a, ['id', '205']).text, show: _.find(a, ['id', '205']).show },  // Транспорт ОГВ / ГУ ГАТН
            { name: _.find(a, ['id', '300']).text, show: _.find(a, ['id', '300']).show },  // Мониторинг легковых такси
            { name: _.find(a, ['id', '307']).text, show: _.find(a, ['id', '307']).show },  // Транспорт сельского хозяйства
            { name: _.find(a, ['id', '314']).text, show: _.find(a, ['id', '314']).show },  // Контроль медицинского транспорта
            { name: _.find(a, ['id', '321']).text, show: _.find(a, ['id', '321']).show },  // Перевозки лесоматериалов
            { name: _.find(a, ['id', '332']).text, show: _.find(a, ['id', '332']).show },  // Перевозки опасных грузов
            { name: _.find(a, ['id', '363']).text, show: _.find(a, ['id', '363']).show },  // ГУ ГАТН
            { name: _.find(a, ['id', '365']).text, show: _.find(a, ['id', '365']).show }, // Центр медицины и катастроф
            { name: _.find(a, ['id', '372']).text, show: _.find(a, ['id', '372']).show }, // Лесное хозяйство
            { name: _.find(a, ['id', '379']).text, show: _.find(a, ['id', '379']).show }, // Жилищно-коммунальное хозяйство
            { name: _.find(a, ['id', '400']).text, show: _.find(a, ['id', '400']).show }, // Сельское хозяйство
            { name: _.find(a, ['id', '407']).text, show: _.find(a, ['id', '407']).show }, // Пожарно-спасательная служба
            { name: _.find(a, ['id', '414']).text, show: _.find(a, ['id', '414']).show }, // Служебный транспорт
        ];
        let filteredReportGroup = _.find(categoriesNames, {name: reportGroup.name});
        return filteredReportGroup && !filteredReportGroup.show;
    }
    // _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '365']).text


    // фильтрация списка отчетов для Новосибирска
    reportsListNSO(reportGroup) {
        if (reportGroup.name === 'Управление пассажирскими перевозками') {
            let reportsNames = [
                'Итоговый отчет о выполнении рейсов с нагоном',
                'Итоговый отчет о работе выходов по маршруту (общие показатели)',
                'Итоговый отчет о работе выходов по маршруту (рейсы)',
                'Итоговый отчет о работе выходов по филиалу (общие показатели)',
                'Итоговый отчет о работе маршрутов (рейсы)',
                'Итоговый отчет о работе маршрутов/выходов (общие показатели, сводный по филиалам)',
                'Итоговый отчет о работе филиалов (рейсы)',
                'Отчет о выполнении рейсов с нагоном',
                'Отчет об истории перемещения транспортных средств',
                'Отчет об осуществлении регулярных перевозок',
                'Отчет об отклонениях от расписания движения',
                'Отчет общий контроль работы по маршруту',
                'Отчет сводка по водителям за день',
                'Отчет сводка по выпуску подвижного состава на линию по регулируемым (нерегулируемым) тарифам',
                'Сводный отчет о выполнении рейсов с нагоном',
                'Сводный отчет по выполнению транспортной работы филиалами (краткий)',
                'Отчёт о проезде первого/последнего остановочных пунктов'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        // оставляем только необходимые отчеты
        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '147']).text) {
            let reportsNames = [
                'Итоговый отчет о работе маршрутов',
                'Итоговый отчет о работе перевозчиков',
                'Краткий сводный отчет по выполнению транспортной работы по Госконтрактам',
                'Отчет об осуществлении регулярных перевозок Приложение 7 к контракту',
                'Отчет точности выполнения расписания (регулярности движения) на маршруте',
                'Сводный отчет нарушений параметров пассажирских перевозок',
                'Форма контроля выполнения расписания перевозчиками помаршрутно',
                'Форма контроля выполнения транспортной работы (пробег) по маршрутам регулярных перевозок',
                'Форма контроля за выполнением рейсов на маршруте',
                'Форма контроля соблюдения количества ТС и экологического класса',
                'Формы контроля точности выполнения расписания (регулярности движения)'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
        // оставляем только необходимые отчеты
        if (reportGroup.name === 'Оператор системы') {
            let reportsNames = [
                'Отчет об истории перемещения транспортных средств',
                'Отчет сводный по транспортным средствам',
                'Отчет о выполнении обязательств перевозчика по выпуску ТС',
                'Отчет по транспортным средствам (детализация по направлению)',
                'Отчет о выполнении транспортной работы (рейсов) по отрасли',
                'Отчет о количестве маршрутов по видам сообщений',
                'Отчёт о выполненных рейсах по маршруту регулярных перевозок',
                'Отчет о поступлении данных от ТС'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'Обслуживание дорог') {
            let reportsNames = [
                '10А Отчет по исправности ТС предприятия',
                '1А Справка о работе ТС',
                '1А Справка о работе ТС (детализированная)',
                '2А Справка об остановках ТС',
                '2А Справка об остановках ТС (детализированная)',
                '3А Справка о нахождении ТС на КП (краткая)',
                '4А Справка о нахождении ТС на КП (подробная)',
                '5А Итоговый отчёт о работе водителей на объектах',
                '6А Итоговый отчет о работе водителей на объектах (общая)',
                '7А Ведомость пробегов (краткая)',
                '8А Ведомость пробегов',
                '10Д Ведомость заданий и проезда по треку',
                '12Д Сведения об объектах обслуживания, количество обработанных объектов обслуживания',
                '1Д Справка о времени ТС',
                '3Д Итоговый отчет о работе на объектах',
                '4Д Итоговый отчет о работе ТС на задании',
                '5Д Справка о работе на объектах по типам ТС за период',
                '6Д Справка о работе по маршрутам за период',
                '13К Отчет о выходе рабочей техники и рабочих за период',
                '14К Оперативная сводка за сутки',
                '14К Оперативная сводка за сутки город Киров',
                '1Н Справка о последней навигации в базе данных',
                '2Н Справка об интервалах отсутствия навигации за период',
                '3Н Справка об интервалах присутствия навигации за период',
                '4Н Справка об интервалах превышения скорости за период',
                '5Н Графическая справка о запаздывании навигации за период',
                '6Н Графическая справка о получении и вставки навигации в базу данных',
                '1Т График изменения уровня топлива за период',
                '2Т Фактический расход топлива за период',
                '3Т Подробный анализ расхода топлива',
                '4Т Фактический расход топлива по дням за период',
                '6Т Фактический расход топлива за период с графой пробега',
                '10У Отчет по выполнению по отдельным видам работ',
                '11У Выполнение работ по участкам (на карте)',
                '12У Общее количество техники, количество вышедшей за период техники по типам ТС и видам работ',
                '1У Справка о работе предприятий по эксплуатации дорог',
                '2У Итоговый отчёт о работе ТС на объектах',
                '3У Суммарный отчёт о работе ТС'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '186']).text) {
            let reportsNames = [
                'Отчет о нажатиях на «Тревожную кнопку», возникновении нештатных ситуаций',
                'Отчет по рейтингу водителей',
                'Отчет о выполнении транспортной работы (рейсов) по Школьным перевозкам',
                'Отчет о выполнении обязательств перевозчика по выпуску ТС'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'Мониторинг легковых такси') {
            let reportsNames = [
                'Отчет по фактическому пробегу ТС',
                'Отчет о фактическом количестве и видам выявленных нарушений'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'Транспорт сельского хозяйства') {
            let reportsNames = [
                'Отчет по фактическому пробегу ТС',
                'Отчет о фактическом количестве и видам выявленных нарушений'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'Контроль медицинского транспорта') {
            let reportsNames = [
                'Отчет по фактическому пробегу ТС',
                'Отчет о фактическом количестве и видам выявленных нарушений'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'Перевозки лесоматериалов') {
            let reportsNames = [
                'Отчет по фактическому пробегу ТС',
                'Отчет о фактическом количестве и видам выявленных нарушений'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '332']).text) {
            let reportsNames = [
                'Отчет по фактическому пробегу ТС',
                'Отчет о фактическом количестве и видам выявленных нарушений'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
    }
    // фильтрация списка отчетов для ХМАО
    reportsListXMAO(reportGroup) {
        let reportsNames = ['гу гатн', 'коммунальная техника', 'контроль вывоза мусора', 'управление перевозками детей',
            'коммунальная техника', 'обслуживание дорог', 'электронная маршрутная сеть'];
        return reportsNames.includes(reportGroup.name.toLowerCase()) ? null : reportGroup;
    }
    // фильтрация списка отчетов для Нижнего
    reportsListNNovgorod(reportGroup) {
        if (reportGroup.name === 'Оператор системы') {
            let reportsNames = [
                'Отчет об истории перемещения транспортных средств',
                'Отчет о количестве маршрутов по видам деятельности',
                'Отчет о количестве маршрутов по видам сообщений',
                'Отчет по оснащенности и подключению Транспортных средств',
                'Отчет сводный по транспортным средствам',
                'Отчет по транспортным средствам (детализация по направлению)',
                'Отчет сводный по транспортным средствам в разрезе подсистем',
                'Отчет об оснащении ТС в разрезе подсистем и районов',
                'Отчет о выполнении обязательств перевозчика по выпуску ТС',
                'Отчет о выполнении транспортной работы (рейсов) по отрасли',
                'Отчет о количестве маршрутов по видам сообщений',
                'Отчет о невыполнении транспортной работы',
                'Отчет об истории перемещения транспортных средств',
                'Отчет по данным РНИС',
                'Отчет по оснащенности и подключению Транспортных средств',
                'Отчет по транспортным средствам (детализация по направлению)',
                'Отчет рейтинг выполнения транспортной работы',
                'Отчет сводный по транспортным средствам',
                'Отчет активности по АРМ',
                'Отчет о поступлении данных от ТС'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
        if (reportGroup.name === 'Управление пассажирскими перевозками') {
            let reportsNames = [
                'Итоговый отчет о работе выходов по маршруту (общие показатели)',
                'Итоговый отчет о работе выходов по маршруту (рейсы)',
                'Итоговый отчет о работе выходов по филиалу (общие показатели)',
                'Итоговый отчет о работе маршрутов (рейсы)',
                'Отчет о фактическом пробеге транспортных средств (детализация по филиалу)',
                'Итоговый отчет о выполнении рейсов с нагоном',
                'Итоговый отчет о работе маршрутов/выходов (общие показатели, сводный по филиалам)',
                'Итоговый отчет о работе филиалов (рейсы)',
                'Отчет ТЭП план моточасов',
                'Отчет ТЭП по филиалу (детализация)',
                'Отчет ТЭП по филиалу (сводный)',
                'Отчет о выполнении рейсов с нагоном',
                'Отчет о фактическом пробеге транспортных средств (сводный по объединению)',
                'Отчет об отклонениях от расписания движения',
                'Отчет общий контроль работы по маршруту',
                'Отчет сводка по водителям за день',
                'Отчет сводка по выпуску подвижного состава на линию по регулируемым (нерегулируемым) тарифам',
                'Сводный отчет ТЭП по предприятию (объединению)',
                'Сводный отчет о выполнении рейсов с нагоном',
                'Сводный отчет по выполнению транспортной работы филиалами (краткий)',
                'ТЭП план рейсов (детализация)',
                'ТЭП план рейсов (сводный по филиалам)',
                'Отчет об отклонениях от расписания движения',
                'Отчет о фактах необеспечения юридическим лицом требований по передаче мониторинговой информации',
                'Расход топлива по нормативу и по ДУТ',
                'Отчет по пробегу и среднедневному выпуску',
                'График изменения уровня топлива за период',
                'Фактический расход топлива за период'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '147']).text) {
            let reportsNames = [
                'Форма контроля возраста ТС по перевозчикам',
                'Форма контроля выполнения расписания перевозчиками помаршрутно',
                'Форма контроля выполнения транспортной работы (пробег) по маршрутам регулярных перевозок',
                'Форма контроля за возрастом ТС (рейтинг)',
                'Форма контроля за выполнением рейсов на маршруте',
                'Форма контроля соблюдения количества ТС и экологического класса',
                'Форма контроля сроков выполнения перевозок, указанных в части 4 статьи 21 Федерального закона 220-Ф по нерегулируемым тарифам регулярных перевозок',
                'Формы контроля выполнения рейсов по нерегулируемым тарифам регулярных перевозок',
                'Формы контроля передачи мониторинговой информации о параметрах движения и местоположения транспортных средств, используемых для перевозки пассажиров автомобильным транспортом и городским наземным транспортом по маршрутам регулируемого тарифа в РНИС МО',
                'Формы контроля точности выполнения расписания (регулярности движения)',
                'Отчет о фактах самовольного изменения маршрута',
                'Отчет о фактах необеспечения юридическим лицом требований по передаче мониторинговой информации',
                'Отчет об отсутствии навигации за период',
                'Расход топлива по нормативу и по ДУТ'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '186']).text) {
            let reportsNames = [
                'Отчет о выполнении обязательств перевозчика по выпуску ТС',
                'Отчет о выполнении транспортной работы (рейсов) по Школьным перевозкам',
                'Отчет о нажатиях на «Тревожную кнопку», возникновении нештатных ситуаций',
                'Отчет о невыполнении транспортной работы',
                'Отчет по невыполненным рейсам',
                'Отчет о фактах необеспечения юридическим лицом требований по передаче мониторинговой информации',
                'Расход топлива по нормативу и по ДУТ',
                'График изменения уровня топлива за период',
                'Фактический расход топлива за период',
                'Справка об интервалах превышения скорости за период'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
        // ГУ ГАТН (Прочие АТП)
        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '205']).text) {
            let reportsNames = [
                //'Отчет о фактическом количестве и видам выявленных нарушений',
                //'Отчет по фактическому пробегу ТС',
                'Фактический расход топлива за период',
                'Отчет о фактах необеспечения юридическим лицом требований по передаче мониторинговой информации',
                'Расход топлива по нормативу и по ДУТ',
                'График изменения уровня топлива за период',
                '2А Справка об остановках ТС',
                '2А Справка об остановках ТС (детализированная)'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
        if (reportGroup.name === 'Обслуживание дорог') {
            let reportsNames = [
                '10А Отчет по исправности ТС предприятия',
                '1А Справка о работе ТС',
                '1А Справка о работе ТС (детализированная)',
                '2А Справка об остановках ТС',
                '2А Справка об остановках ТС (детализированная)',
                '3А Справка о нахождении ТС на КП (краткая)',
                '4А Справка о нахождении ТС на КП (подробная)',
                '5А Итоговый отчёт о работе водителей на объектах',
                '6А Итоговый отчет о работе водителей на объектах (общая)',
                '7А Ведомость пробегов (краткая)',
                '8А Ведомость пробегов',
                '10Д Ведомость заданий и проезда по треку',
                '12Д Сведения об объектах обслуживания, количество обработанных объектов обслуживания',
                '1Д Справка о времени ТС',
                '3Д Итоговый отчет о работе на объектах',
                '4Д Итоговый отчет о работе ТС на задании',
                '5Д Справка о работе на объектах по типам ТС за период',
                '6Д Справка о работе по маршрутам за период',
                '13К Отчет о выходе рабочей техники и рабочих за период',
                '14К Оперативная сводка за сутки',
                '1Н Справка о последней навигации в базе данных',
                '2Н Справка об интервалах отсутствия навигации за период',
                '3Н Справка об интервалах присутствия навигации за период',
                '4Н Справка об интервалах превышения скорости за период',
                '5Н Графическая справка о запаздывании навигации за период',
                '6Н Графическая справка о получении и вставки навигации в базу данных',
                '1Т График изменения уровня топлива за период',
                '2Т Фактический расход топлива за период',
                '3Т Подробный анализ расхода топлива',
                '4Т Фактический расход топлива по дням за период',
                '6Т Фактический расход топлива за период с графой пробега',
                '10У Отчет по выполнению по отдельным видам работ',
                '11У Выполнение работ по участкам (на карте)',
                '12У Общее количество техники, количество вышедшей за период техники по типам ТС и видам работ',
                '1У Справка о работе предприятий по эксплуатации дорог',
                '2У Итоговый отчёт о работе ТС на объектах',
                '3У Суммарный отчёт о работе ТС',
                'Отчет о фактах необеспечения юридическим лицом требований по передаче мониторинговой информации',
                'Расход топлива по нормативу и по ДУТ'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
    }
    // фильтрация списка отчетов для Мурманска
    reportsListMurmansk(reportGroup) {
        if (reportGroup.name === 'Оператор системы') {
            let reportsNames = [
               /* 'Ведомость пробегов (детализация по дням)',
                'Отчет об истории перемещения транспортных средств',
                'Отчет о выполнении обязательств перевозчика по выпуску ТС',
                'Отчет о выполнении транспортной работы (рейсов) по отрасли',
                'Отчет о выполнении транспортной работы (рейсов) с нарушением расписания',
                'Отчет о количестве маршрутов по видам деятельности',
                'Отчет о количестве маршрутов по видам сообщений',
                'Отчет о невыполнении транспортной работы',
                'Отчет о поступлении данных от ТС',
                'Отчет по данным РНИС Московской области',
                'Отчет по оснащенности и подключению Транспортных средств',
                'Отчет по транспортным средствам (детализация по направлению)',
                'Отчет рейтинг выполнения транспортной работы',
                'Отчет сводный по транспортным средствам'*/
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
        if (reportGroup.name === 'Обслуживание дорог') {
            let reportsNames = [
                'Ведомость заданий и проезда по треку',
                'Ведомость заданий по ручной уборке',
                'Ведомость пробегов',
                'Ведомость уборки за период в метрах квадратных',
                'Выполнение работ по участкам (на карте)',
                'Итоговый отчет по ручной уборке на объектах',
                'Итоговый отчёт о работе ТС на объектах',
                'Общее количество техники и СПУ, количество вышедшей за период техники и СПУ',
                'Справка о работе ТС и специалистов по уборке',
                'Справка о работе предприятий по эксплуатации дорог',
                'Фактическое исполнение заданий',
                'Отчет о поступлении данных от ТС',
                'Отчет по контрактам и обслуживаемым улицам',
                'Отчет по контрактам и исполнителям',
                //'Отчет по учтенным в контрактах улицам и объектам',
                'Отчет по учтенным в контрактах улицам и объектам (на карте)',
                'Процент выполнения заданий по видам работ',
                'Статус предприятия в СМУТ',
                'Отчет о работе предприятий',
                'Объем выполненной работы',
                'Контроль уборки по объекту инфраструктуры',
                'Внеплановые работы',
                'Оценка периодичности учета объектов инфраструктуры в заданиях'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
    }
    // фильтрация списка отчетов для Омска
    reportsListOmsk(reportGroup) {
        if (reportGroup.name === 'Управление пассажирскими перевозками') {
            let reportsNames = [
                'Отчет о выполнении рейсов с нагоном',
                'Сводный отчет о выполнении рейсов с нагоном',
                'Итоговый отчет о выполнении рейсов с нагоном',
                'Итоговый отчет о работе выходов по маршруту (общие показатели)',
                'Итоговый отчет о работе выходов по маршруту (рейсы)',
                'Итоговый отчет о работе выходов по филиалу (общие показатели)',
                'Итоговый отчет о работе маршрутов (рейсы)',
                'Итоговый отчет о работе маршрутов/выходов (общие показатели, сводный по филиалам)',
                'Итоговый отчет о работе филиалов (рейсы)',
                'Отчет об истории перемещения транспортных средств',
                'Отчет об осуществлении регулярных перевозок',
                'Отчет об отклонениях от расписания движения',
                'Отчет общий контроль работы по маршруту',
                'Отчет сводка по водителям за день',
                'Отчет сводка по выпуску подвижного состава на линию по регулируемым (нерегулируемым) тарифам',
                'Сводный отчет по выполнению транспортной работы филиалами (краткий)',
                'Отчет о посещении геозон',
                'Отчет о фактической работе ТС по парку.',
                'Отчет о фактической работе ТС по парку',
                'Отчет о соблюдении интервального расписания',
                'Отчет о фактическом выполнении рейсов за сутки'

                /*'Баллы водителей',
                'График изменения уровня топлива за период',
                'Информация о реализации билетной продукции (детализация по дням) Форма №6.2',
                'Информация о реализации билетной продукции (сводная по рейсам за период) Форма №6.1',
                'Отчет ТЭП план моточасов',
                'Отчет ТЭП по филиалу (детализация)',
                'Отчет ТЭП по филиалу (сводный)',
                'Отчет анализ пассажиропотока и расчет рекомендованной вместимости ТС',
                'Отчет об энергопотреблении оборудования',
                'Отчет о зафиксированных ДТП',
                'Отчет о резких и опасных маневрах',

                'Отчет о фактическом пробеге транспортных средств (детализация по филиалу)',
                'Отчет о фактическом пробеге транспортных средств (сводный по объединению)',
                'Отчёт о проезде первого/последнего остановочных пунктов',
                'Сводный отчет ТЭП по предприятию (объединению)',
                'ТЭП план рейсов (детализация)',
                'ТЭП план рейсов (сводный по филиалам)',
                'Фактический расход топлива за период',
                'Фактический расход топлива за период с графой пробега (6Т)',
                'Фактический расход топлива по дням за период (4Т)'*/
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        // оставляем только необходимые отчеты
        if (reportGroup.name === 'Коммунальная техника') {
            let reportsNames = [
                'Отчет о работе одного ТС коммунальной уборочной техники (по дням)',
                'Отчет о работе всех ТС коммунальной уборочной техники определенной организации',
                'Отчет о работе коммунальной уборочной техники муниципального образования',
                'Отчет о работе всех ТС коммунальной уборочной техники',
                'Отчет о работе коммунальной уборочной техники',
                'Отчет об истории перемещения транспортных средств',
                /*
                'Отчет о посещении геозон',
                'Отчет о работе единицы ТС: простои и стоянки',*/
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
        // оставляем только необходимые отчеты
        if (reportGroup.name === 'Контроль вывоза мусора') {
            let reportsNames = [
                'Отчет о движении транспортного средства по объектам ТКО',
                'Отчет о перечне работ на объектах обращения ТКО',
                'Отчет о работе транспортных средств компании',
                'Отчет по выходу транспортных средств по вывозу ТКО',
                'Сводный отчет по посещениям стационарной площадки',

                /*'Отчет о посещении геозон',
                'Отчет о сводном выходе техники по вывозу ТБО',
                'Сводный отчет по незапланированным остановкам транспортных средств',
                'Сводный отчет по незапланированным остановкам транспортных средств организаций',*/
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '186']).text) {
            let reportsNames = [
                'Отчет о выполнении обязательств перевозчика по выпуску ТС',
                'Отчет о выполнении транспортной работы (рейсов) по Школьным перевозкам',
                'Отчет о нажатиях на «Тревожную кнопку», возникновении нештатных ситуаций',

                /*'График изменения уровня топлива за период',
                'Исходные данные для составления рейтинга невыполнения транспортной работы',
                'Отчет о невыполнении транспортной работы',
                'Отчет о посещении геозон',
                'Отчет по невыполненным рейсам',
                'Отчет по рейтингу водителей',
                'Отчет по рейтингу невыполнения транспортной работы',
                'Фактический расход топлива за период'*/
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'Оператор системы') {
            let reportsNames = [
                'Отчет об истории перемещения транспортных средств',
                'Отчет сводный по транспортным средствам',
                'Отчет о выполнении обязательств перевозчика по выпуску ТС',
                'Отчет по транспортным средствам (детализация по направлению)',
                'Отчет о выполнении транспортной работы (рейсов) по отрасли',
                'Отчет о количестве маршрутов по видам сообщений'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '147']).text) {
            let reportsNames = [
                'Отчет о фактическом выполнении рейсов за сутки'

            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'Обслуживание дорог') {
            let reportsNames = [
                '1А Справка о работе ТС',
                '2А Справка об остановках ТС',
                '5А Итоговый отчёт о работе водителей на объектах',
                '6А Итоговый отчет о работе водителей на объектах (общая)',
                '7А Ведомость пробегов (краткая)',
                '8А Ведомость пробегов',
                '10А Отчет по исправности ТС предприятия',
                '1Д Справка о времени ТС',
                '3Д Итоговый отчет о работе на объектах',
                '4Д Итоговый отчет о работе ТС на задании',
                '5Д Справка о работе на объектах по типам ТС за период',
                '6Д Справка о работе по маршрутам за период',
                '10Д Ведомость заданий и проезда по треку',
                '12Д Сведения об объектах обслуживания, количество обработанных объектов обслуживания',
                //'13У Отчет о посещении участков автодорог дорожной техникой',
                '14К Оперативная сводка за сутки',
                '1Н Справка о последней навигации в базе данных',
                '2Н Справка об интервалах отсутствия навигации за период',
                '3Н Справка об интервалах присутствия навигации за период',
                '4Н Справка об интервалах превышения скорости за период',
                '5Н Графическая справка о запаздывании навигации за период',
                '6Н Графическая справка о получении и вставки навигации в базу данных',
                '1Т График изменения уровня топлива за период',
                '2Т Фактический расход топлива за период',
                '3Т Подробный анализ расхода топлива',
                '4Т Фактический расход топлива по дням за период',
                '6Т Фактический расход топлива за период с графой пробега',
                '1У Справка о работе предприятий по эксплуатации дорог',
                '2У Итоговый отчёт о работе ТС на объектах',
                '3У Суммарный отчёт о работе ТС',
                '11У Выполнение работ по участкам (на карте)',
                '12У Общее количество техники, количество вышедшей за период техники по типам ТС и видам работ',
                '13К Отчет о выходе рабочей техники и рабочих за период',

                /*'1А Справка о работе ТС (детализированная)',
                '2А Справка об остановках ТС (детализированная)',
                '2Д Итоговый отчёт о работе ТС на объектах',
                '3А Справка о нахождении ТС на КП (краткая)',
                '4А Справка о нахождении ТС на КП (подробная)',
                '10К Отчет об исполнении контрактов (сводный)',
                '10У Отчет по выполнению по отдельным видам работ',
                '11А Баллы водителей',
                '11Д Итоговый отчет о работе ТС на объектах (детализация по типу ТС)',
                '11К Отчет об исполнении контрактов по виду работ',
                '12А Отчет о резких и опасных маневрах',
                '12К Отчет об исполнении контрактов по предприятию',
                '13К Отчет о выходе рабочей техники и рабочих за период',
                '14К Оперативная сводка за сутки город Киров',
                '14У Отчет о непосещении участков автодорог дорожной техникой',
                '15К Сравнение удельной продолжительности выполнения операций по видам работ в разрезе организаций',
                '15У Отчет о посещении участков автодорог дорожной техникой по заданиям',
                'Отчет о вывозе снега'*/
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'Транспорт ОИВ') {
            let reportsNames = [
                'Отчет по фактическому пробегу ТС',
                'Отчет о фактическом количестве и видам выявленных нарушений',
                'Отчет о фактической работе ТС по парку'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
    }
    // фильтрация списка отчетов для Московской области
    reportsListMoscow(reportGroup) {
        if (reportGroup.name === 'Управление пассажирскими перевозками') {
            let reportsNames = [
                'Баллы водителей',
                'Ведомость пробегов (детализация по дням)',
                'График изменения уровня топлива за период',
                'Информация о реализации билетной продукции (детализация по дням) Форма №6.2',
                'Информация о реализации билетной продукции (сводная по рейсам за период) Форма №6.1',
                'Итоговый отчет о выполнении рейсов с нагоном',
                'Итоговый отчет о работе выходов по маршруту (общие показатели)',
                'Итоговый отчет о работе выходов по маршруту (рейсы)',
                'Итоговый отчет о работе выходов по филиалу (общие показатели)',
                'Итоговый отчет о работе маршрутов (рейсы)',
                'Итоговый отчет о работе маршрутов/выходов (общие показатели сводный по филиалам)',
                'Итоговый отчет о работе филиалов (рейсы)',
                'Отчет ТЭП план моточасов',
                'Отчет ТЭП по филиалу (детализация)',
                'Отчет ТЭП по филиалу (сводный)',
                'Отчет анализ пассажиропотока и расчет рекомендованной вместимости ТС',
                'Отчет контроля проезда остановок',
                'Отчет об истории перемещения транспортных средств',
                'Отчет об осуществлении регулярных перевозок',
                'Отчет об отклонениях от расписания движения',
                'Отчет общий контроль работы по маршруту',
                'Отчет об энергопотреблении оборудования',
                'Отчет о выполнении рейсов с нагоном',
                'Отчет о зафиксированных ДТП',
                'Отчет о посещении геозон',
                'Отчет о резких и опасных маневрах',
                'Отчет о соблюдении интервального расписания',
                'Отчет о фактическом пробеге транспортных средств (детализация по филиалу)',
                'Отчет о фактическом пробеге транспортных средств (сводный по объединению)',
                'Отчет сводка по водителям за день',
                'Отчет сводка по выпуску подвижного состава на линию по регулируемым (нерегулируемым) тарифам',
                'Отчёт о проезде первого/последнего остановочных пунктов',
                'Сводный отчет ТЭП по предприятию (объединению)',
                'Сводный отчет о выполнении рейсов с нагоном',
                'Сводный отчет по выполнению транспортной работы филиалами (краткий)',
                'ТЭП план рейсов (детализация)',
                'ТЭП план рейсов (сводный по филиалам)',
                'Фактический расход топлива за период',
                'Фактический расход топлива за период с графой пробега (6Т)',
                'Фактический расход топлива по дням за период (4Т)'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        // оставляем только необходимые отчеты
        if (reportGroup.name === 'Коммунальная техника') {
            let reportsNames = [
                'Ведомость пробегов (детализация по дням)',
                'Отчет об истории перемещения транспортных средств',
                'Отчет о посещении геозон',
                'Отчет о работе всех ТС коммунальной уборочной техники определенной организации',
                'Отчет о работе единицы ТС: простои и стоянки',
                'Отчет о работе коммунальной уборочной техники Московской области',
                'Отчет о работе коммунальной уборочной техники муниципального образования',
                'Отчет о работе одного ТС коммунальной уборочной техники (по дням)',
                'Отчет об использовании техники за период'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
        // оставляем только необходимые отчеты
        if (reportGroup.name === 'Контроль вывоза мусора') {
            let reportsNames = [
                'Ведомость пробегов (детализация по дням)',
                'Отчет о движении транспортного средства по объектам ТКО',
                'Отчет о перечне работ на объектах обращения ТКО',
                'Отчет о посещении геозон',
                'Отчет о работе транспортных средств компании',
                'Отчет о сводном выходе техники по вывозу ТБО',
                'Отчет по выходу транспортных средств по вывозу ТКО',
                'Сводный отчет по незапланированным остановкам транспортных средств',
                'Сводный отчет по незапланированным остановкам транспортных средств организаций',
                'Сводный отчет по посещениям стационарной площадки'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '186']).text) {
            let reportsNames = [
                'Ведомость пробегов (детализация по дням)',
                'График изменения уровня топлива за период',
                'Исходные данные для составления рейтинга невыполнения транспортной работы',
                'Отчет о выполнении обязательств перевозчика по выпуску ТС',
                'Отчет о выполнении транспортной работы (рейсов) по Школьным перевозкам',
                'Отчет о нажатиях на «Тревожную кнопку» возникновении нештатных ситуаций',
                'Отчет о невыполнении транспортной работы',
                'Отчет о посещении геозон',
                'Отчет по невыполненным рейсам',
                'Отчет по рейтингу водителей',
                'Отчет по рейтингу невыполнения транспортной работы',
                'Фактический расход топлива за период'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'Оператор системы') {
            let reportsNames = [
                'Ведомость пробегов (детализация по дням)',
                'Отчет контроля режимов труда и отдыха водителей',
                'Отчет об истории перемещения транспортных средств',
                'Отчет о выполнении обязательств перевозчика по выпуску ТС',
                'Отчет о выполнении транспортной работы (рейсов) по отрасли',
                'Отчет о выполнении транспортной работы (рейсов) с нарушением расписания',
                'Отчет о количестве маршрутов по видам деятельности',
                'Отчет о количестве маршрутов по видам сообщений',
                'Отчет о невыполнении транспортной работы',
                'Отчет о поступлении данных от ТС',
                'Отчет по данным РНИС Московской области',
                'Отчет по оснащенности и подключению Транспортных средств',
                'Отчет по транспортным средствам (детализация по направлению)',
                //  'Отчет рейтинг выполнения транспортной работы',
                'Отчет сводный по транспортным средствам',
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '147']).text) {
            let reportsNames = [
                'Ведомость пробегов (детализация по дням)',
                'Итоговый отчет о работе маршрутов',
                'Итоговый отчет о работе перевозчиков',
                'Краткий сводный отчет по выполнению транспортной работы по Госконтрактам',
                'Общий контроль работы по маршруту (детализация)',
                'Общий контроль работы по маршруту (сводный)',
                'Отчет ТЭП по перевозчику',
                'Отчет об осуществлении регулярных перевозок Приложение 7 к контракту',
                'Отчет об отсутствии навигации за период',
                'Отчет о выполнении авто-часов в наряде по маршрутам регулярных перевозок',
                'Отчет о выполнении параметров пассажирских перевозок (количество и вместимость транспортных средств на маршруте)',
                'Отчет о выполнении параметров пассажирских перевозок (приложение 3 к ГК)',
                'Отчет о нарушении ТС установленных маршрутов пассажирских перевозок',
                'Отчет по возрасту ТС',
                'Отчет точности выполнения расписания (регулярности движения) на маршруте',
                'Сводный отчет нарушений параметров пассажирских перевозок',
                'Форма контроля возраста ТС по перевозчикам',
                'Форма контроля выполнения расписания перевозчиками помаршрутно',
                'Форма контроля выполнения транспортной работы (пробег) по маршрутам регулярных перевозок',
                'Форма контроля за возрастом ТС (рейтинг)',
                'Форма контроля за выполнением рейсов на маршруте',
                'Форма контроля соблюдения количества ТС и экологического класса',
                'Форма контроля сроков выполнения перевозок указанных в части 4 статьи 21 Федерального закона 220-Ф по нерегулируемым тарифам регулярных перевозок',
                'Форма точности соблюдения расписания по ОМСУ',
                'Формы контроля выполнения рейсов по нерегулируемым тарифам регулярных перевозок',
                'Формы контроля передачи мониторинговой информации о параметрах движения и местоположения транспортных средств используемых для перевозки пассажиров автомобильным транспортом и городским наземным транспортом по маршрутам регулируемого тарифа в РНИС МО',
                'Формы контроля точности выполнения расписания (регулярности движения)',
                'Отчет для контроля перевозчиков в части выполнения запланированных рейсов'

            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'Обслуживание дорог') {
            let reportsNames = [
                '1А Справка о работе ТС',
                '1А Справка о работе ТС (детализированная)',
                '1Д Справка о времени ТС',
                '1Н Справка о последней навигации в базе данных',
                '1Т График изменения уровня топлива за период',
                '1У Справка о работе предприятий по эксплуатации дорог',
                '2А Справка об остановках ТС',
                '2А Справка об остановках ТС (детализированная)',
                '2Д Итоговый отчёт о работе ТС на объектах',
                '2Н Справка об интервалах отсутствия навигации за период',
                '2Т Фактический расход топлива за период',
                '2У Итоговый отчёт о работе ТС на объектах',
                '3А Справка о нахождении ТС на КП (краткая)',
                '3Д Итоговый отчет о работе на объектах',
                '3Н Справка об интервалах присутствия навигации за период',
                '3Т Подробный анализ расхода топлива',
                '3У Суммарный отчёт о работе ТС',
                '4А Справка о нахождении ТС на КП (подробная)',
                '4Д Итоговый отчет о работе ТС на задании',
                '4Н Справка об интервалах превышения скорости за период',
                '4Т Фактический расход топлива по дням за период',
                '5А Итоговый отчёт о работе водителей на объектах',
                '5Д Справка о работе на объектах по типам ТС за период',
                '5Н Графическая справка о запаздывании навигации за период',
                '6А Итоговый отчет о работе водителей на объектах (общая)',
                '6Д Справка о работе по маршрутам за период',
                '6Н Графическая справка о получении и вставки навигации в базу данных',
                '6Т Фактический расход топлива за период с графой пробега',
                '7А Ведомость пробегов (краткая)',
                '8А Ведомость пробегов',
                '10А Отчет по исправности ТС предприятия',
                '10Д Ведомость заданий и проезда по треку',
                '10К Отчет об исполнении контрактов (сводный)',
                '10У Отчет по выполнению по отдельным видам работ',
                '11А Баллы водителей',
                '11Д Итоговый отчет о работе ТС на объектах (детализация по типу ТС)',
                '11К Отчет об исполнении контрактов по виду работ',
                '11У Выполнение работ по участкам (на карте)',
                '12А Отчет о резких и опасных маневрах',
                '12Д Сведения об объектах обслуживания, количество обработанных объектов обслуживания',
                '12К Отчет об исполнении контрактов по предприятию',
                '12У Общее количество техники, количество вышедшей за период техники по типам ТС и видам работ',
                '13К Отчет о выходе рабочей техники и рабочих за период',
                '13У Отчет о посещении участков автодорог дорожной техникой',
                '14К Оперативная сводка за сутки',
                '14К Оперативная сводка за сутки город Киров',
                '14У Отчет о непосещении участков автодорог дорожной техникой',
                '15К Сравнение удельной продолжительности выполнения операций по видам работ в разрезе организаций',
                '15У Отчет о посещении участков автодорог дорожной техникой по заданиям',
                'Ведомость пробегов (детализация по дням)',
                'Отчет об использовании техники за период',
                'Отчет об использовании техники за сутки (расширенный)',
                'Отчет об использовании техники за сутки по МО',
                'Отчет о вывозе снега',
                'Отчет проверки качества телематики',
                'Отчет об использовании техники за период'

            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'Электронная маршрутная сеть') {
            let reportsNames = [
                'Ведомость пробегов (детализация по дням)',
                'Отчет о количестве маршрутов (общий)',
                'Отчет о количестве расписаний',
                'Отчет о протяженности маршрутной сети'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'ГУ ГАТН') {
            let reportsNames = [
                'Баллы водителей',
                'Ведомость пробегов (детализация по дням)',
                'График изменения уровня топлива за период',
                'Отчет о посещении геозон',
                'Отчет о фактической работе ТС по парку',
                'Отчет о фактическом количестве и видам выявленных нарушений',
                'Отчет по геозонам о входе-выходе',
                'Отчет по фактическому пробегу ТС',
                'Фактический расход топлива за период'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
    }
    // фильтрация списка отчетов для Тулы
    reportsListTula(reportGroup) {
        if (reportGroup.name === 'Управление пассажирскими перевозками') {
            let reportsNames = [
                //'Отчет о выполнении рейсов с нагоном',
                'Итоговый отчет о работе выходов по маршруту (общие показатели)',
                'Итоговый отчет о работе выходов по маршруту (рейсы)',
                'Итоговый отчет о работе выходов по филиалу (общие показатели)',
                'Итоговый отчет о работе маршрутов (рейсы)',
                'Итоговый отчет о работе филиалов (рейсы)',
                'Отчет об отсутствии навигации за период',
                '1Н Справка о последней навигации в базе данных',
                'Отчет общий контроль работы по маршруту',
                'Отчет об отклонениях от расписания движения',
                'Сводный отчет по выполнению транспортной работы филиалами (краткий)',
                'Отчет о выполнении рейсов с нагоном/отставанием',
                'Сводный отчет о пробеге ТС',
                'Отчет почасовой о пробеге ТС',
                'Отчет рейтинг выполнения транспортной работы',
                'Расход топлива по нормативу и по ДУТ',
                'Сводный отчет о выполнении транспортной работы перевозчиками',
                'Отчет сводка по выпуску подвижного состава на линию по регулируемым (нерегулируемым) тарифам (Альтернативный)'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        // оставляем только необходимые отчеты
        if (reportGroup.name === 'Коммунальная техника') {
            let reportsNames = [
                'Ведомость пробегов (детализация по дням)',
                'Отчет об истории перемещения транспортных средств',
                'Отчет о посещении геозон',
                'Отчет о работе всех ТС коммунальной уборочной техники определенной организации',
                'Отчет о работе единицы ТС: простои и стоянки',
                'Отчет о работе коммунальной уборочной техники Московской области',
                'Отчет о работе коммунальной уборочной техники муниципального образования',
                'Отчет о работе одного ТС коммунальной уборочной техники (по дням)',
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
        // оставляем только необходимые отчеты
        if (reportGroup.name === 'Контроль вывоза мусора') {
            let reportsNames = [
                'Ведомость пробегов (детализация по дням)',
                'Отчет о движении транспортного средства по объектам ТКО',
                'Отчет о перечне работ на объектах обращения ТКО',
                'Отчет о посещении геозон',
                'Отчет о работе транспортных средств компании',
                'Отчет о сводном выходе техники по вывозу ТБО',
                'Отчет по выходу транспортных средств по вывозу ТКО',
                'Сводный отчет по незапланированным остановкам транспортных средств',
                'Сводный отчет по незапланированным остановкам транспортных средств организаций',
                'Сводный отчет по посещениям стационарной площадки'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '186']).text) {
            let reportsNames = [
                'Отчет о нажатиях на «Тревожную кнопку», возникновении нештатных ситуаций',
                'Отчет о фактическом количестве и видам выявленных нарушений',
                'Фактический расход топлива за период',
                'Отчет об истории перемещения транспортных средств',
                'Отчет о выполнении транспортной работы (рейсов) по Школьным перевозкам',
                'Отчет о выполнении обязательств перевозчика по выпуску ТС',
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });

        }

        const operatorTitle = _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '100']).text;
        if (reportGroup.name === operatorTitle) {
            let reportsNames = [
                'Отчет активности по АРМ',
                'Отчет по оснащенности и подключению Транспортных средств',
                'Отчет о выполнении обязательств перевозчика по выпуску ТС',
                'Отчет о количестве маршрутов по видам деятельности',
                'Отчет о количестве маршрутов по видам сообщений',
                'Отчет по транспортным средствам (детализация по направлению)',
                'Отчет сводный по транспортным средствам',
                'Рейтинг выполнения транспортной работы (%)',
                'Отчет о фактическом количестве и видам выявленных нарушений',
                'Отчет об итогах эксплуатации и развития СПО.',
                'Отчет об истории перемещения транспортных средств',
                'Отчет об оснащении ТС в разрезе подсистем и районов'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '147']).text) {
            let reportsNames = [
                'Ведомость пробегов (детализация по дням)',
                'Итоговый отчет о работе маршрутов',
                'Итоговый отчет о работе перевозчиков',
                'Краткий сводный отчет по выполнению транспортной работы по Госконтрактам',
                'Общий контроль работы по маршруту (детализация)',
                'Общий контроль работы по маршруту (сводный)',
                'Отчет ТЭП по перевозчику',
                'Отчет об осуществлении регулярных перевозок Приложение 7 к контракту',
                'Отчет об отсутствии навигации за период',
                'Отчет о выполнении авто-часов в наряде по маршрутам регулярных перевозок',
                'Отчет о выполнении параметров пассажирских перевозок (количество и вместимость транспортных средств на маршруте)',
                'Отчет о выполнении параметров пассажирских перевозок (приложение 3 к ГК)',
                'Отчет о нарушении ТС установленных маршрутов пассажирских перевозок',
                'Отчет по возрасту ТС',
                'Отчет точности выполнения расписания (регулярности движения) на маршруте',
                'Сводный отчет нарушений параметров пассажирских перевозок',
                'Форма контроля возраста ТС по перевозчикам',
                'Форма контроля выполнения расписания перевозчиками помаршрутно',
                'Форма контроля выполнения транспортной работы (пробег) по маршрутам регулярных перевозок',
                'Форма контроля за возрастом ТС (рейтинг)',
                'Форма контроля за выполнением рейсов на маршруте',
                'Форма контроля соблюдения количества ТС и экологического класса',
                'Форма контроля сроков выполнения перевозок, указанных в части 4 статьи 21 Федерального закона 220-Ф по нерегулируемым тарифам регулярных перевозок',
                'Форма точности соблюдения расписания по ОМСУ',
                'Формы контроля выполнения рейсов по нерегулируемым тарифам регулярных перевозок',
                'Формы контроля передачи мониторинговой информации о параметрах движения и местоположения транспортных средств, используемых для перевозки пассажиров и багажа на территории Тульской области по маршрутам регулярных перевозок',
                'Формы контроля точности выполнения расписания (регулярности движения)',
                'Отчет о количестве маршрутов по перевозчику',
                'Отчет о выполнении рейсов с нагоном/отставанием',
                '2Н Справка об интервалах отсутствия навигации за период',
                'Отчет о фактах самовольного изменения маршрута',
                'Расход топлива по нормативу и по ДУТ',
                'Отчет об истории перемещения транспортных средств',
                'Справка об интервалах превышения скорости за период',

            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        const roadTitle = _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text;
        if (reportGroup.name === roadTitle) {
            let reportsNames = [
                '1А Справка о работе ТС',
                '1Д Справка о времени ТС',
                '1Н Справка о последней навигации в базе данных',
                '1Т График изменения уровня топлива за период',
                '2А Справка об остановках ТС',
                '2Н Справка об интервалах отсутствия навигации за период',
                '2Т Фактический расход топлива за период',
                '2У Итоговый отчёт о работе ТС на объектах',
                '3А Справка о нахождении ТС на КП (краткая)',
                '3Т Подробный анализ расхода топлива',
                '3У Суммарный отчёт о работе ТС',
                '4Д Итоговый отчет о работе ТС на задании',
                '4Н Справка об интервалах превышения скорости за период',
                '6А Итоговый отчет о работе водителей на объектах (общая)',
                '6Д Справка о работе по маршрутам за период',
                '8А Ведомость пробегов',
                '10А Отчет по исправности ТС предприятия',
                '12А Отчет о резких и опасных маневрах',
                '12Д Сведения об объектах обслуживания, количество обработанных объектов обслуживания',
                '12У Общее количество техники, количество вышедшей за период техники по типам ТС и видам работ',
                '14К Оперативная сводка за сутки',
                '14К Оперативная сводка за сутки город Киров',
                '15К Сравнение удельной продолжительности выполнения операций по видам работ в разрезе организаций',
                'Ведомость пробегов (детализация по дням)',
                'Отчет о работе ТС',
                'Отчет об использовании техники за сутки (расширенный)',
                'Отчет об использовании техники за сутки по МО',
                'Отчет о вывозе снега',
                'Отчет об истории перемещения транспортных средств'

            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'Жилищно-коммунальное хозяйство') {
            let reportsNames = [
                'Отчет о работе ТС',
                '2А Справка об остановках ТС',
                '3А Справка о нахождении ТС на КП (краткая)',
                '6А Итоговый отчет о работе водителей на объектах (общая)',
                '8А Ведомость пробегов',
                '1Н Справка о последней навигации в базе данных',
                '2Н Справка об интервалах отсутствия навигации за период',
                '3Н Справка об интервалах присутствия навигации за период',
                '4Н Справка об интервалах превышения скорости за период',
                '2Т Фактический расход топлива за период',
                '2У Итоговый отчёт о работе ТС на объектах',
                '1Д Справка о времени ТС',
                '4Д Итоговый отчет о работе ТС на задании',
                '6Д Справка о работе по маршрутам за период',
                '12У Общее количество техники, количество вышедшей за период техники по типам ТС и видам работ',
                '12Д Сведения об объектах обслуживания, количество обработанных объектов обслуживания',
                '14К Оперативная сводка за сутки',
                '1Т График изменения уровня топлива за период',
                '10А Отчет по исправности ТС предприятия',
                'Отчет об истории перемещения транспортных средств',
                '3Т Подробный анализ расхода топлива'

            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'Электронная маршрутная сеть') {
            let reportsNames = [
                'Ведомость пробегов (детализация по дням)',
                'Отчет о количестве маршрутов (общий)',
                'Отчет о количестве расписаний',
                'Отчет о протяженности маршрутной сети'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === 'ГУ ГАТН') {
            let reportsNames = [
                'Баллы водителей',
                'Ведомость пробегов (детализация по дням)',
                'График изменения уровня топлива за период',
                'Отчет о посещении геозон',
                'Отчет о фактической работе ТС по парку',
                'Отчет о фактическом количестве и видам выявленных нарушений',
                'Отчет по геозонам о входе-выходе',
                'Отчет по фактическому пробегу ТС',
                'Фактический расход топлива за период'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '407']).text) {
            let reportsNames = [
                '1Д Справка о времени ТС',
                '2Н Справка об интервалах отсутствия навигации за период',
                '2Т Фактический расход топлива за период',
                'Отчет о посещении геозон',
                'Отчет о фактическом количестве и видам выявленных нарушений',
                'Отчет об истории перемещения транспортных средств',
                'Отчет по фактическому пробегу ТС',
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '365']).text) {
            let reportsNames = [
                '1Д Справка о времени ТС',
                '2Н Справка об интервалах отсутствия навигации за период',
                '2Т Фактический расход топлива за период',
                'Отчет о посещении геозон',
                'Отчет о фактическом количестве и видам выявленных нарушений',
                'Отчет об истории перемещения транспортных средств',
                'Отчет по фактическому пробегу ТС'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '414']).text) {
            let reportsNames = [
                'Отчет по фактическому пробегу ТС',
                '1Д Справка о времени ТС',
                '2Н Справка об интервалах отсутствия навигации за период',
                'Отчет о фактическом количестве и видам выявленных нарушений',
                'Отчет о посещении геозон',
                '2Т Фактический расход топлива за период',
                'Отчет об истории перемещения транспортных средств',
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '332']).text) {
            let reportsNames = [
                '1Д Справка о времени ТС',
                '2Н Справка об интервалах отсутствия навигации за период',
                '2Т Фактический расход топлива за период',
                'Отчет о посещении геозон',
                'Отчет о фактическом количестве и видам выявленных нарушений',
                'Отчет об истории перемещения транспортных средств',
                'Отчет по фактическому пробегу ТС'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });

        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '372']).text) {
            let reportsNames = [
                '1Д Справка о времени ТС',
                '2Н Справка об интервалах отсутствия навигации за период',
                '2Т Фактический расход топлива за период',
                'Отчет о посещении геозон',
                'Отчет о фактическом количестве и видам выявленных нарушений',
                'Отчет об истории перемещения транспортных средств',
                'Отчет по фактическому пробегу ТС'
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }

        if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '400']).text) {
            let reportsNames = [
                'Отчет по фактическому пробегу ТС',
                '1Д Справка о времени ТС',
                '2Н Справка об интервалах отсутствия навигации за период',
                'Отчет о фактическом количестве и видам выявленных нарушений',
                'Отчет о посещении геозон',
                'Отчет об истории перемещения транспортных средств',
            ];
            reportGroup.items = reportGroup.items.filter(item => {
                return _.includes(reportsNames, item.name) ? item : null
            });
        }
    }

    renderReportGroup(reportGroup, index) {
        const {search} = this.state;

        let reportsGroupCopy = _.clone(reportGroup)
        // если подсистема отключена в настройках, то не отображать эту категорию отчетов
        if(this.deleteReportCategories(reportGroup)) return;

        // фильтрация списка отчетов для ХМАО
        if (window.RNIS_SETTINGS.HIDESOMEREPORTS && !isTest() && !isLocalhost() ) {
           this.reportsListXMAO(reportGroup)
        }
        // фильтрация списка отчетов для Новосибирска
        if (window.RNIS_SETTINGS.CITY_NOVOSIBIRSK && !isTest() && !isLocalhost()) {
            this.reportsListNSO(reportGroup)
        }
        // фильтрация списка отчетов для Нижнего Новгорода
        if (window.RNIS_SETTINGS.CITY_NNOVGOROD && !isTest() && !isLocalhost()) {
            this.reportsListNNovgorod(reportGroup)
        }

        // фильтрация списка отчетов для Мурманска
        if (window.RNIS_SETTINGS.CITY_MURMANSK) {
            this.reportsListMurmansk(reportGroup)
        }

        // фильтрация списка отчетов для Омска
        if (window.RNIS_SETTINGS.CITY_OMSK && !isTest() && !isLocalhost()) {
            this.reportsListOmsk(reportGroup)
        }
        // фильтрация списка отчетов для Московской области
        if (window.RNIS_SETTINGS.CITY_MO && !isTest() && !isLocalhost()) {
            this.reportsListMoscow(reportGroup)
        }
        // фильтрация списка отчетов для Тулы
        if (window.RNIS_SETTINGS.CITY_TULA && !isTest() && !isLocalhost()) {
            this.reportsListTula(reportGroup)
        }


        /*if (reportGroup.name === _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '147']).text && !window.RNIS_SETTINGS.CITY_NOVOSIBIRSK && !window.RNIS_SETTINGS.CITY_NNOVGOROD) {
            (!_.find(reportGroup.items, {name: this.repA.name})) ? reportGroup.items.push({
                name: this.repA.name,
                uri: 'report_1'
            }) : null;
            (!_.find(reportGroup.items, {name: this.repB.name})) ? reportGroup.items.push({
                name: this.repB.name,
                uri: 'report_2'
            }) : null;
        }*/


        let a  = _.find(reportsGroupCopy.items, {name: 'Ведомость пробегов (детализация по дням)'})

        a ? reportGroup.items.push({
            name: a.name,
            uri: a.uri
        }) : null;

        // в мурманске не нужен раздел Арм пользователя и отчеты оттуда удаляем, сделано отдельное строкой, чтобы не городить условия

        if (reportGroup.name === 'Оператор системы' && window.RNIS_SETTINGS.CITY_MURMANSK) {
            reportGroup.items.pop();
        }

        reportGroup.items = _.uniqBy(reportGroup.items, 'uri')

        if (_.filter(reportGroup.items, (report) => {
            if (search && report.name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
                return false;
            }
            return true;
        }).length === 0) {
            return null;
        }

        const items = _.sortBy(reportGroup.items, (report) => {
            const name = report.name;
            let prefix = '';

            const matches = /^([0-9]+)(.) /.exec(name);
            if (matches) {
                const number = matches[1];
                const letter = matches[2];

                switch (letter) {
                    case 'У':
                        prefix = '1';
                        prefix += _.get({
                            '10': '1',
                            '11': '2',
                            '1': '3',
                            '2': '4',
                            '3': '5',
                            '12': '6',
                            '13': '7',
                            '14': '8',
                            '15': '9',
                        }, number);
                        break;
                    case 'А':
                        prefix = '2';
                        prefix += _.get({
                            '1': 'a',
                            '2': 'b',
                            '3': 'c',
                            '4': 'd',
                            '5': 'e',
                            '6': 'f',
                            '7': 'g',
                            '8': 'h',
                            '10': 'i',
                            '11': 'j',
                            '12': 'k',
                        }, number);
                        break;
                    case 'Д':
                        prefix = '3';
                        prefix += _.get({
                            '1': 'a',
                            '2': 'b',
                            '3': 'c',
                            '4': 'd',
                            '5': 'e',
                            '6': 'f',
                            '10': 'g',
                            '11': 'h',
                            '12': 'i',
                        }, number);
                        break;
                    case 'Т':
                        prefix = '4';
                        break;
                    case 'Н':
                        prefix = '5';
                        break;
                    case 'К':
                        prefix = '6';
                        break;
                }
            }

            return prefix + name;
        });

        let title = reportGroup.name

        if (title === 'Оператор системы' && window.RNIS_SETTINGS.operator_title) {
            title = window.RNIS_SETTINGS.operator_title
        }

        return (
            <AccordionItem opened={false} title={title} key={index} ref={`group-${index}`}>
                <div className="reports">
                    <ul className="reports__list">
                        {_.map(items, ::this.renderReport)}
                    </ul>
                </div>
            </AccordionItem>
        );
    }

    renderReport(report, index) {
        const {search} = this.state;

        if (search && report.name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
            return null;
        }

        const className = classNames('reports__item', {
            'active': this.isSelected(report),
        });

        switch (report.name) {
            /*case this.repA.name:
                return (
                    <li key={index} className={className}>
                        <a href={this.repA.link} target="_blank">{report.name}</a>
                    </li>
                );
            case this.repB.name:
                return (
                    <li key={index} className={className}>
                        <a href={this.repB.link} target="_blank">{report.name}</a>
                    </li>
                );*/
            default:
                return (
                    <li key={index} className={className} onClick={this.props.onReportClick.bind(this, report)}>
                        {report.name}
                    </li>
                )
        }


    }

    isSelected(report) {
        return this.props.selectedReport && (this.props.selectedReport.uri === report.uri);
    }
}
