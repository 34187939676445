import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './checkbox-dropdown.less';
import IconButton from "components/ui/icon-button";
import Popup from "components/ui/popup";
import {LabeledCheckbox} from "components/ui/checkbox";
import _ from 'lodash';
import ContextTooltip from "components/ui/context-tooltip";
import FilterHeader from 'components/ui/filter-header';

@propTypes({
    items: PropTypes.array.isRequired,
    selectedItems: PropTypes.object,
    toggleSelectedItem: PropTypes.func.isRequired,
    toggleSelectedItems: PropTypes.func,
    withSearch: PropTypes.bool,
    withToggle: PropTypes.bool,
    contextKey: PropTypes.string,
    contextDefault: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
    closeOnSelect: PropTypes.bool,
})

@defaultProps({
    withSearch: true,
    withToggle: true,
    icon: 'add-remove',
    className: null,
    closeOnSelect: false,
})

export default class CheckboxDropdown extends Component {

    state = {
        opened: false,
        isDeselectAll: true,
        search: '',
        search_lower: '',
    };

    render() {
        const button = (
            <IconButton
                icon={this.props.icon}
                ref="button"
                onClick={::this.toggleBlock}/>
        );

        return (
            <div>
                {this.props.contextKey ? (
                    <ContextTooltip key={this.props.contextKey} code={this.props.contextKey}
                                    default={this.props.contextDefault}>
                        {button}
                    </ContextTooltip>
                ) : button}
                {this.state.opened ? this.renderPopup() : null}
            </div>
        );
    }

    renderPopup() {
      
        const items = this.props.items || [];
        const selectedCount = _.filter(items, item => (this.props.selectedItems || {})[item.value]).length;
        const totalCount = items.length;

        const sliderClassName = classNames({
            'b-slider__line': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        const sliderCircleClassName = classNames({
            'b-slider__circle': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        const filteredItems = _.filter(items, (item) => {
            if (this.state.search_lower) {
                return (item.label.toLowerCase().indexOf(this.state.search_lower) !== -1);
            }
            return true;
        });

        return (
            <Popup
                className={classNames("SelectFieldsPopup top-link checkbox-dropdown", this.props.className)}
                show={true}
                onClose={::this.toggleBlock}>
                <div className="popup-container__content">
                  {this.props.withTabs ? (
                    <FilterHeader onChange={this.props.onChangeTab} items={this.props.tabsNames} currentItem={this.props.activeTab} />
                  ) : null}
                    {this.props.withSearch ? (
                        <div className="filtration__search">
                            <input type="text" ref="search" value={this.state.search} className="filtration__pole"
                                   placeholder="Поиск"
                                   onChange={::this.onSearchChange}
                            />
                            <input type="button" className="filtration__button"/>
                        </div>
                    ) : null}

                    <div className="content">
                        {_.slice(filteredItems, 0, 100).map((item) => (
                            <LabeledCheckbox
                                key={item.value}
                                checked={(this.props.selectedItems || {})[item.value] || false}
                                label={item.label}
                                onChange={this.onClick.bind(this, item)}/>
                        ))}
                    </div>
                    {(this.props.withToggle && (totalCount > 0)) ? (
                        <div className="b-slider _options clearAfter" onClick={::this.selectDeselectAll}>
                            <div className="b-slider__title" style={{display: 'none'}}>Выбрать все</div>
                            <div
                                className="b-slider__title">{this.state.isDeselectAll ? 'Снять все' : 'Выбрать все'}</div>

                            <div className="b-slider__control">
                                <div className={sliderClassName}/>
                                <div className={sliderCircleClassName}/>
                            </div>
                        </div>
                    ) : null}
                </div>
            </Popup>
        );
    }

    async onSearchChange() {
        const search = this.refs.search.value;
        await this.setState({
            search_lower: search.toLowerCase(),
            search,
        });
    }

    selectDeselectAll() {
        if (this.state.isDeselectAll) {
            if (this.props.toggleSelectedItems) {
                this.props.toggleSelectedItems({})
            } else {
                _.each(_.filter(this.props.items, item => this.props.selectedItems[item.value]), (item) => {
                    this.onClick(item);
                });
            }
        } else {
            if (this.props.toggleSelectedItems) {
                this.props.toggleSelectedItems(_.mapValues(_.keyBy(this.props.items, 'value'), item => true))
            } else {
                _.each(_.filter(this.props.items, item => !this.props.selectedItems[item.value]), (item) => {
                    this.onClick(item);
                });
            }
        }

        this.setState({
            isDeselectAll: !this.state.isDeselectAll,
        });
    }

    async onClick(vehicle) {
        this.props.toggleSelectedItem(vehicle.value);

        if (this.props.closeOnSelect) {
            this.setState({
                opened: false,
            });
        }
    }

    toggleBlock() {
        this.setState({
            opened: !this.state.opened,
        });
    }
}