import _ from "lodash";

export function set(key, value) {
    return save(key, value);
}

export function remove(key) {
    save(key, null);
}

export function save(key, value) {
    if (typeof value === 'object') {
        value = JSON.stringify(value);
    }

    try {
        return localStorage.setItem(key, value);
    } catch (e) {
    }
    return null;
}

export function get(key, defaultValue = null) {
    const value = localStorage.getItem(key);
    if (value === null) {
        return defaultValue;
    }
    try {
        return JSON.parse(value);
    }
    catch (e) {
    }
    return value;
}

export function clear() {
    // оставить настройки пользователя (центрирование карты)
    let settings = JSON.parse(localStorage.getItem("users_settings"));
    localStorage.clear();
    localStorage.setItem('users_settings', JSON.stringify(settings))
}