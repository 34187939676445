import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {
    getEventRule, createEventRule, updateEventRule, getEventTypes,
    getNotification, readNotification
} from "store/reducers/notifications";
import debounce from 'throttle-debounce/debounce';
import systems from "dictionaries/systems";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import vehicle_statuses from "dictionaries/vehicle_statuses";
import {getVehicle, getVehicleList} from "store/reducers/vehicles/vehicles";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getLayerObjectsFull, getLayersFull} from "store/reducers/user-map-objects/layer_list";
import NotificationRuleEditorMapComponent from "components/modules/notifications/rules/map";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import NotificationRulesNotificationsComponent from "components/modules/notifications/rules/notifications/index";
import moment from "moment";
import formats from "dictionaries/formats";
import {getUser} from "store/reducers/staffing/staffing_editor";
import {getRoute} from "store/reducers/routes/route_editor";
import {Link} from "react-router";
import {User} from "helpers/user";
import * as alerts from "helpers/alerts";
import {getContract} from "store/reducers/kiutr/contracts/contracts";
import {getOrders} from "store/reducers/kiutr/orders/orders";
@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
    entityUuid: PropTypes.string,
    entityClass: PropTypes.string,
})

@connect(state => ({}), {getNotification, getOrders})

export default class Editor extends BaseEditor {

    getFullTitle(mode) {
        return `Данные оповещения`;
    }

    async loadData(uuid) {
        return await this.props.getNotification(uuid);
    }

    saveButton(onClick) {
        return null;
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getEventTypes, getDictionaryList, getVehicle, getUnit, getUser, getContract,
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        notification: {},
        event_types: [],
        vehicle: {},
        driver: {},
        unit: {},
        contract: {},
        vehicle_models: [],
    };

    getData() {
        return this.state.notification;
    }

    async componentDidMount() {
        await this.setState({
            notification: this.props.data,
        });
        this.loadEventTypes();
        this.loadVehicle();
        this.loadDriver();
        this.loadUnit();
        this.loadContract();
        this.loadDictionaries([
            'vehicle_models',
        ]);
    }

    async loadEventTypes() {
        const response = await this.props.getEventTypes();
        if (response.isOk) {
            this.setState({
                event_types: _.mapValues(_.keyBy(response.payload.items, 'key'), 'name'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadVehicle() {
        if (!this.get('data.vehicle_uuid')) {
            return;
        }
        const response = await this.props.getVehicle(this.get('data.vehicle_uuid'));
        if (response.isOk) {
            this.setState({
                vehicle: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadDriver() {
        if (!this.get('data.driver_uuid')) {
            return;
        }
        const response = await this.props.getUser(this.get('data.driver_uuid'));
        if (response.isOk) {
            this.setState({
                driver: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadUnit() {
        if (!this.get('data.unit_uuid')) {
            return;
        }
        const response = await this.props.getUnit(this.get('data.unit_uuid'));
        if (response.isOk) {
            this.setState({
                unit: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadContract() {
        if (!this.get('data.contract_uuid')) {
            return;
        }
        const response = await this.props.getContract(this.get('data.contract_uuid'));
        if (response.isOk) {
            this.setState({
                contract: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.notification, path, defaultValue);
    }

    render() {
        const eventType = this.get('data.event_type');
        const routeRegistryUuid = _.get(this.get('data'), 'custom_data.route_registry_uuid');

        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} title="Общая информация">
                        <Block title="Событие">
                            {this.get('data.title')} ({this.get('data.event_rule_code')})
                            <br/>
                            {_.get(this.state.event_types, this.get('data.event_type'))}
                        </Block>
                        <Block title="Время события">
                            {moment(this.get('created_at')).format(formats.DATETIME)}
                        </Block>
                        {(this.get('data.latitude') && this.get('data.longitude')) ? (
                            <Block title="Место события">
                                {this.get('data.latitude')}, {this.get('data.longitude')}
                                <br/>
                                {this.get('data.address')}
                                <br/>
                                <a href="javascript:void(0)" onClick={::this.gotoMap}>Посмотреть на карте</a>
                            </Block>
                        ) : null}
                        {(eventType === 'op_control') ? (
                            <Block>
                                <Link to={'/kiutr/transport-work/' + routeRegistryUuid + '?date=' + moment(this.get('created_at')).format(formats.DATE)}>Перейти в детализацию по рейсам</Link>
                            </Block>
                        ) : null}
                        <Block size="xl" title="Текст сообщения">
                            <pre>{this.get('data.text')}</pre>
                        </Block>
                    </AccordionItem>
                    <AccordionItem opened={true} title="Участники события">
                        {this.get('data.vehicle_uuid') ? (
                            <Block size="xl" title="ТС">
                                {this.renderVehicle(this.get('data.vehicle_uuid'))}
                            </Block>
                        ) : null}
                        {this.get('data.driver_uuid') ? (
                            <Block size="xl" title="Водитель">
                                {this.renderDriver(this.get('data.driver_uuid'))}
                            </Block>
                        ) : null}
                        {this.get('data.unit_uuid') ? (
                            <Block size="xl" title="Предприятие">
                                {this.renderUnit(this.get('data.unit_uuid'))}
                            </Block>
                        ) : null}
                        {this.get('data.contract_uuid') ? (
                            <Block size="xl" title={window.RNIS_SETTINGS.rename_contracts ? "Подрядное обязательство" :"Контракт"}>
                                {this.renderContract(this.get('data.contract_uuid'))}
                            </Block>
                        ) : null}
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    gotoMap = async (e) => {
        e.preventDefault();
        const to =  moment( this.state.notification.created_at).add(10, "minutes").format()
        const from = moment( this.state.notification.created_at).subtract(10, "minutes").format()
        this.props.router.push(`/${this.state.vehicle.component}/map?vehicles=${this.state.vehicle.uuid}&from_time=${from}&to_time=${to}`);
    }

    renderVehicle(vehicleUuid) {
        if (!vehicleUuid) {
            return '-';
        }

        return (
            <div>
                {this.state.vehicle.state_number}
                <br/>
                <Link
                    to={`/${this.state.vehicle.component}/vehicles/vehicles/${this.state.vehicle.uuid}`}>перейти</Link>
                <br/>
                Модель: {_.get(_.find(this.state.vehicle_models, {value: this.state.vehicle.vehicle_model_uuid}), 'label')}
                <br/>
                Гар. номер: {this.state.vehicle.garage_number}
            </div>
        );
    }

    renderDriver(driverUuid) {
        if (!driverUuid) {
            return '-';
        }

        return (
            <div>
                {(new User(this.state.driver)).getFullName()}
                <br/>
                Табельный номер: {_.get(this.state.driver, 'info.personnel_number')}
                <br/>
                <a href="javascript:void(0)" onClick={::this.demo}>Звонок</a>
                <a href="javascript:void(0)" onClick={::this.demo}>СМС</a>
            </div>
        );
    }

    demo() {
        alerts.alert('В разработке');
    }

    renderUnit(unitUuid) {
        if (!unitUuid) {
            return '-';
        }

        return (
            <div>
                {this.state.unit.name}
                <br/>
                <Link to={`/${this.state.unit.component}/units/units/${this.state.unit.uuid}`}>перейти</Link>
            </div>
        );
    }

    renderContract(contractUuid) {
        if (!contractUuid) {
            return '-';
        }

        return (
            <div>
                {window.RNIS_SETTINGS.rename_contracts ? "Подрядное обязательство" : "Контракт"} №{this.state.contract.number}
                <br/>
                <Link to={`/kiutr/contracts/${this.state.contract.uuid}`}>перейти</Link>
            </div>
        );
    }
}