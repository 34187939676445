import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './map-cleanup-specialist-icon.less';
import _ from 'lodash';
import currentUser from "../../helpers/current-user";

@propTypes({
    device: PropTypes.object,
    direction: PropTypes.number,
    fast: PropTypes.bool,
    color: PropTypes.string,
    stateNumber: PropTypes.string,
})

@defaultProps({
    device: null,
    fast: false,
    color: null,
    stateNumber: null,
})

export default class MapCleanupSpecialistIcon extends Component {

    render() {
        let isCustomMarker = currentUser.user.is_custommarker && currentUser.user.is_custommarker.custommarkericon ? currentUser.user.is_custommarker.custommarkericon : false;
        const className = classNames('marker__icon',`icon-${this.props.device && this.props.device.type || 'bus'}`,
            isCustomMarker ? `custom-icon icon-${this.props.device && this.props.device.type || 'bus'}-custom` : null);

        const style = {
            //backgroundColor: this.props.color,
            //background: this.props.color,
            background: className.includes("stop") ? "#fff": "#1186e9",
            width: 'inherit',
            height: 'inherit',
            borderRadius: 'inherit',
            border: 'inherit',
            top: '-2px',
            left: '-2px',
            position: 'inherit'
        };

        return (
            <div style={style}>
                {this.props.stateNumber ? (
                    <div className="marker-transfer__route">
                        {this.props.stateNumber}
                    </div>
                ) : null}
                {this.props.device ? (
                    <span className={className}/>
                ) : null}
                <div className="marker__center">
                    {!_.isUndefined(this.props.direction) ? (
                        <span className={classNames('marker__direction', isCustomMarker ? 'custom-direction' : '', this.props.fast ? 'fast' : '')}
                              style={{transform: `rotate(${this.props.direction}deg)`}}/>
                    ) : null}
                </div>
            </div>
        );
    }
}
