import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './checkbox.less';

@propTypes({
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
})

@defaultProps({
    checked: false,
    label: '',
    onChange: () => {
    },
})

export default class Checkbox extends Component {
    render() {
        return (
            <div className={classNames('b-checkbox', this.props.className || 'login-password')} onClick={::this.onChange}>
                <input type="checkbox" className="b-checkbox__inner" checked={this.props.checked}/>
                <label className="b-checkbox__label">{this.props.label}</label>
            </div>
        );
    }

    onChange() {
        this.props.onChange({
            target: {
                value: !this.props.checked,
            },
        });
    }
}