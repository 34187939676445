import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './slider.less';

@propTypes({
    selected: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    selectAll: PropTypes.func.isRequired,
    deselectAll: PropTypes.func.isRequired,
    clearAfter: PropTypes.bool,
    title: PropTypes.string,
})

@defaultProps({
    clearAflter: true,
})

export class Slider extends Component {

    async toggle() {
        const {selected, total} = this.props;

        if (selected !== total) {
            this.props.selectAll();
        } else {
            this.props.deselectAll();
        }
    }

    render() {
        const {selected, total} = this.props;

        const sliderClassName = classNames({
            'b-slider__line': true,
            '_selected_yes': selected && selected !== total,
            '_selected_all': selected === total,
        });

        const sliderCircleClassName = classNames({
            'b-slider__circle': true,
            '_selected_yes': selected && selected !== total,
            '_selected_all': selected === total,
        });

        return (
            <div className={classNames('b-slider _options', {
                clearAfter: this.props.clearAfter,
            })} onClick={::this.toggle}>
                {this.props.title ? (
                    <div className="b-slider__title">{this.props.title}</div>
                ) : null}
                <div className="b-slider__control">
                    <div className={sliderClassName}/>
                    <div className={sliderCircleClassName}/>
                </div>
            </div>
        );
    }

}