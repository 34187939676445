import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {getUnits} from "store/reducers/organizational_units/units";
import {unitCountVehiclesChart} from "store/reducers/kurs/tasks";
import "./style.less";
import * as alerts from "helpers/alerts";

@connect(state => ({}), {
    getUnits,
    unitCountVehiclesChart,
    },
    null, {withRef: true}
)

class UnitCountVehicles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            unit_data: [],
        }
    }

    componentDidMount() {
        this.loadUnitData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
            this.loadUnitData();
        }
    }

    async loadUnitData() {
        const response = await this.props.unitCountVehiclesChart({
            filters: this.props.filters,
        });

        if (response.isOk) {

            this.setState({
                unit_data: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    render() {

        return (
            <div className="full-size unit-percent-table__container">
                <div className="Table">
                    <table className="b-table">
                        <thead className="b-table__header">
                            <tr>
                                <td>Предприятие</td>
                                <td>Количество ТС на заданиях</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.unit_data.map((unitData) => {
                                return (
                                    <tr>
                                        <td>{(this.props.units.find((unit) => unit.value === unitData.unit_uuid) || {} ).label}</td>
                                        <td>{unitData.vehicle_count}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default UnitCountVehicles;

