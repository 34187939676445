import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import {DefectTypes} from "dictionaries/kiutr";
import {createDefect, updateDefect, updateOrder} from "store/reducers/kiutr/orders/orders";
import {getVehicle, getVehicleList} from "store/reducers/vehicles/vehicles";
import moment from "moment";
import formats from "dictionaries/formats";
import runs from "dictionaries/runs";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getRouteVariantNullRuns} from "store/reducers/kiutr/route_variant_null_run";
import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";

@propTypes({
    order: PropTypes.object.isRequired,
    uuid: PropTypes.string,
})

@connect(state => ({}), {createDefect, updateDefect, getVehicleList, getUsers})

export default class DefectEditor extends BaseEditor {

    modalClassName = 'orders-modal orders-modal-defect';
    withFade = false;

    getFullTitle(mode) {
        return (mode === 'add') ? 'Регистрация брака/вывода' : 'Редактирование брака/вывода';
    }

    async loadData(uuid) {
        return {
            isOk: true,
            payload: _.find(this.props.order.defects, {uuid}),
        };
    }

    componentDidMount() {
        this.forceUpdate();
    }

    init() {
        this.loadVehicles();
        this.loadDrivers();
    }

    async loadVehicles() {
        const vehicleUuids = _.filter(_.uniq(_.map(_.flatten(_.map(this.props.order.shifts, 'runs')), 'vehicle_uuid')));

        const response = await this.props.getVehicleList({
            filters: {
                withUuid: vehicleUuids,
            },
            response_data: [
                'items/uuid',
                'items/state_number',
            ],
        });

        if (response.isOk) {
            this.setState({
                vehicles: _.map(response.payload.items, (vehicle) => ({
                    value: vehicle.uuid,
                    label: vehicle.state_number,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async loadDrivers() {
        const driverUuids = _.filter(_.uniq(_.map(_.flatten(_.map(this.props.order.shifts, 'runs')), 'driver_uuid')));

        const response = await this.props.getUsers({
            filters: {
                withUuid: driverUuids,
            },
            response_data: [
                'items/uuid',
                'items/info/surname',
                'items/info/name',
                'items/info/second_name',
            ],
        });

        if (response.isOk) {
            this.setState({
                drivers: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: (new User(item)).getFullName(),
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async createItem(data) {
        data.order_uuid = this.props.order.uuid;
        return await this.props.createDefect(data);
    }

    async updateItem(data) {
        data.order_uuid = this.props.order.uuid;
        return await this.props.updateDefect(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                vehicles={this.state.vehicles}
                drivers={this.state.drivers}
            />
        );
    }

    renderHeaderBtns(mode) {
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getDictionaryList,
    getVehicle,
    getRouteVariantNullRuns,
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        defect: {},
        defects: [],
        vehicle: {},
    };

    getData() {
        return this.state.defect;
    }

    async componentDidMount() {
        await this.setState({
            defect: this.props.data,
        });

        this.loadNullRuns();
        this.loadDictionaries([
            'defects',
        ]);
        if (this.props.mode === 'edit') {
            this.loadVehicle();
        }
    }

    async loadNullRuns() {
        const uuids = _.uniq(_.filter(_.map(_.map(this.props.order.shifts, 'runs'), 'route_variant_uuid')));

        const response = await this.props.getRouteVariantNullRuns({
            filters: {
                withRouteVariants: uuids,
            },
            response_data: [
                'items/uuid',
                'items/is_forward',
                'items/name',
            ],
        });

        if (response.isOk) {
            this.setState({
                routeVariantNullRuns: _.map(_.filter(response.payload.items, item => !item.is_forward), (item) => ({
                    value: item.uuid,
                    label: item.name,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async loadVehicle() {
        const response = await this.props.getVehicle(this.get('vehicle_uuid'));

        if (response.isOk) {
            this.setState({
                vehicle: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.defect, path, defaultValue);
    }

    selectRun(run) {
        this.setValue('defect.time_from', run.date_to);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} title="Общая информация">
                        <Block size="xl" title="ТС">
                            {(this.props.mode === 'add') ? (
                                this.select('defect.vehicle_uuid', this.props.vehicles)
                            ) : (
                                this.state.vehicle.state_number
                            )}
                        </Block>
                        <Block size="xl" title="Водитель">
                            {(this.props.mode === 'add') ? (
                                this.select('defect.driver_uuid', this.props.drivers)
                            ) : (
                                _.get(_.find(this.state.drivers, {uuid: this.get('driver_uuid')}), 'label')
                            )}
                        </Block>
                        <Block size="xl" title="Тип">
                            {(this.props.mode === 'add') ? (
                                this.select('defect.type', _.map(DefectTypes, (label, value) => ({
                                    label,
                                    value,
                                })))
                            ) : (
                                DefectTypes[this.get('type')]
                            )}
                        </Block>
                        <Block size="xl" title="Причина">
                            {this.select('defect.defect_uuid', this.state.defects)}
                        </Block>

                    </AccordionItem>
                    {this.get('type') ? (
                        <AccordionItem opened={true} title="Оформление">
                            {(this.get('type') === 'return' || this.get('type') === 'crash') ? ([
                                <Block size="xl" key="time_from" title="Предполагаемое время происшествия">
                                    {(this.props.mode === 'add') ? (
                                        this.maskInput('defect.time_from', '99:99', {
                                            withTimeIcon: true,
                                        })
                                    ) : (
                                        moment(this.get('time_from')).format(formats.TIME)
                                    )}
                                </Block>,
                                <Block size="xl" key="route_variant_null_run_uuid" title="Выходной маневр">
                                    {this.select('defect.route_variant_null_run_uuid', this.state.routeVariantNullRuns)}
                                </Block>,
                                <Block size="xl" key="is_important">
                                    {this.checkbox('defect.is_important', 'Пометить вмешательство как важное')}
                                </Block>,
                                <Block size="xl" key="comment" title="Примечание">
                                    {this.textarea('defect.comment')}
                                </Block>,
                            ]) : null}
                            {(this.get('type') === 'downtime') ? ([
                                <Block size="xl" key="runs" title="Рейсы">
                                    <div className="Table">
                                        <TableContainer>
                                            <table className="b-table">
                                                <tbody>
                                                {this.props.order.shifts.map((shift) => ([
                                                    <tr key={`shift:${shift.shift}`}>
                                                        <td colSpan="3">Смена {shift.shift}</td>
                                                    </tr>,
                                                    shift.runs.map((run) => (
                                                        <tr key={run.run} onClick={this.selectRun.bind(this, run)}>
                                                            <td>{run.run + 1}</td>
                                                            <td>{runs[run.type]}</td>
                                                            <td>{moment(run.date_from).format(formats.TIME)}-{moment(run.date_to).format(formats.TIME)}</td>
                                                        </tr>
                                                    )),
                                                ]))}
                                                </tbody>
                                            </table>
                                        </TableContainer>
                                    </div>
                                </Block>,
                                <Block size="xl" key="time_from" title="Простой с">
                                    {moment(this.get('time_from')).format(formats.TIME)}
                                </Block>,
                                <Block size="xl" key="time_to" title="Простой по">
                                    {(this.props.mode === 'add') ? (
                                        this.maskInput('defect.time_to', '99:99', {
                                            withTimeIcon: true,
                                        })
                                    ) : (
                                        moment(this.get('time_to')).format(formats.TIME)
                                    )}
                                </Block>,
                                <Block size="xl" key="is_important">
                                    {this.checkbox('defect.is_important', 'Пометить вмешательство как важное')}
                                </Block>,
                                <Block size="xl" key="comment" title="Примечание">
                                    {this.textarea('defect.comment')}
                                </Block>,
                            ]) : null}
                            {(this.get('type') === 'resource_output') ? ([
                                <Block size="xl" key="runs" title="Рейсы">
                                    <div className="Table">
                                        <TableContainer>
                                            <table className="b-table">
                                                <tbody>
                                                {this.props.order.shifts.map((shift) => ([
                                                    <tr key={`shift:${shift.shift}`}>
                                                        <td colSpan="3">Смена {shift.shift}</td>
                                                    </tr>,
                                                    shift.runs.map((run) => (
                                                        <tr key={run.run} onClick={this.selectRun.bind(this, run)}>
                                                            <td>{run.run + 1}</td>
                                                            <td>{runs[run.type]}</td>
                                                            <td>{moment(run.date_from).format(formats.TIME)}-{moment(run.date_to).format(formats.TIME)}</td>
                                                        </tr>
                                                    )),
                                                ]))}
                                                </tbody>
                                            </table>
                                        </TableContainer>
                                    </div>
                                </Block>,
                                <Block size="xl" key="time_from" title="Время вывода">
                                    {this.get('time_from') ? moment(this.get('time_from')).format(formats.TIME) : '-'}
                                </Block>,
                                <Block size="xl" key="is_important">
                                    {this.checkbox('defect.is_important', 'Пометить вмешательство как важное')}
                                </Block>,
                                <Block size="xl" key="comment" title="Примечание">
                                    {this.textarea('defect.comment')}
                                </Block>,
                            ]) : null}
                        </AccordionItem>
                    ) : null}
                </Accordion>
            </div>
        );
    }
}
