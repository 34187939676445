import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import PageModal from 'components/ui/page-modal';
import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import TelematicsDevice from "components/modules/maps/telematics-packet";
import {getVehicle} from "store/reducers/vehicles/vehicles";
import {getHistory, getHistoryByVehicle} from "store/reducers/maps";
import ShiftMap from './ShiftMap';
import _ from 'lodash';
import './style.less';
import {getRouteVariant} from "store/reducers/routes/route_variants";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import {getStopPoints} from "store/reducers/geo/stop-points";

@propTypes({
    data: PropTypes.object,
    onClose: PropTypes.func
})

@defaultProps({
    onClose: () => {
    }
})

@connect(state => ({}), {getVehicle, getHistory, getHistoryByVehicle, getRouteVariant, getStopPoints})

export default class ShiftMapForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vehicle: {},
            history: [],
            isError: false,
            isLoading: false,
            routeVariant: {},
            routeVisible: true,
            stopPoints: {},
        };
    }

    componentWillMount() {
        this.loadData();
    }

    renderContent() {
        const {run} = this.props.data;

        const points = this.state.routeVisible ? _.get(this.state.routeVariant, (run.type === 'production_forward') ? 'forward_points' : 'reverse_points') : [];

        return <ShiftMap data={{
            ...this.props.data, ...{
                history: this.state.history,
                points,
                stopPoints: this.state.stopPoints,
            }
        }}/>
    }

    render() {
        const stateNumber = this.state.vehicle['state_number'];
        const title = 'Мониторинг ТС' + (stateNumber ? ` - гос. номер ${stateNumber}` : '');
        const content = this.state.isError ? null : this.state.isLoading ?
            <GlobalLoaderComponent/> : this.renderContent();

        const buttons = (
            <ModalTopMenuList>
                <ModalTopMenuList className="top-menu_modal_edit">
                    <ContextTooltip key="shift-map.toggle-route" code="shift-map.toggle-route"
                                    default="Отображение планового маршрута">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_planned-route"
                            onClick={::this.toggleRoute}
                        />
                    </ContextTooltip>
                    <ModalTopMenuListSeparator key="separator"/>
                </ModalTopMenuList>
                <ModalTopMenuButton
                    key="close"
                    className="_close"
                    tooltip="Отменить"
                    onClick={this.props.onClose}
                />
            </ModalTopMenuList>
        );

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}
                className="shift-map-modal"
                withFade
            >
                {content}
            </PageModal>
        );
    }

    toggleRoute() {
        this.setState({
            routeVisible: !this.state.routeVisible,
        });
    }

    async loadVehicle() {
        const uuid = this.props.data.vehicleUuid;
        if (!uuid) {
            return {};
        }

        const response = await this.props.getVehicle(uuid);
        if (response.isOk) {
            return response.payload;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadHistory(vehicle) {
        const response = await this.props.getHistoryByVehicle(vehicle.uuid, this.props.data.from, this.props.data.to);

        if (response.isOk) {
            const telematics = _.first(response.payload.telematics);
            if (telematics) {
                return _.map(telematics.points, (point) => {
                    return new TelematicsDevice(telematics.device_id, point);
                });
            }
            else {
                return [];
            }
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadStopPoints(uuids) {
        const response = await this.props.getStopPoints({
            filters: {
                withUuid: uuids,
            },
        });
        if (response.isOk) {
            this.setState({
                stopPoints: _.keyBy(response.payload.items, 'uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadData() {
        this.setState({isError: false, isLoading: true});


        await this.loadRouteVariant();

        const points = _.get(this.state.routeVariant, (this.props.data.run.type === 'production_forward') ? 'forward_points' : 'reverse_points') || [];
        await this.loadStopPoints(_.filter(_.uniq(_.map(points, 'type_uuid'))));

        const vehicle = await this.loadVehicle();
        //console.log(vehicle);

        if (vehicle) {
            const history = await this.loadHistory(vehicle);
            //console.log(history);

            if (history) {
                this.setState({isError: false, isLoading: false, vehicle, history});
                return;
            }
        }

        this.setState({isError: true, isLoading: false});
    }

    async loadRouteVariant() {
        const response = await this.props.getRouteVariant(this.props.data.run.route_variant_uuid);

        if (response.isOk) {
            this.setState({
                routeVariant: response.payload,
            });
        } else {
            response.showErrors();
        }
    }
}