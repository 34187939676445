import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import classNames from 'classnames';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import $ from 'jquery';
import './page-header.less';
import ReactResizeDetector from 'react-resize-detector';
import currentUser from 'helpers/current-user';

@propTypes({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object,]),
    actions: PropTypes.array,
    contents: PropTypes.array,
})

export default class PageHeader extends Component {

    componentDidUpdate() {
        this.onResize();
    }

    onResize() {
        const block = $(this.refs.block);
        const title = $(this.refs.title);
        const menu = $(this.refs.menu);

        const width = block.width() - 100 - menu.width();
        title.css('width', width);

        let direction = (width >= 500) ? 'ltr' : 'rtl';
        if (_.isString(this.props.title) && this.props.title.length < 20) {
            direction = 'ltr';
        }
        title.css('direction', direction);
    }

    render() {
        let list = [];

        _.each(this.props.contents, (content, i) => {
            if (!content) return;

            const className = classNames('top-menu__item', {
                '_edge': true,//i >= this.props.contents.length - 3,
            });
            list.push(
                <li className={className} key={`content${i}`}>
                    {content}
                </li>
            );
        });
        if (this.props.contents && this.props.actions && this.props.contents.length && this.props.actions.length) {
            list.push(
                <li className="top-menu__item" key="separator">
                    <div className="top-menu__separator"/>
                </li>
            );
        }
        _.each(this.props.actions, (action, i) => {
            if (!action) return;

            const className = classNames('top-menu__item', {
                '_edge': i >= this.props.actions.length - 2,
            });
            list.push(
                <li className={className} key={`action${i}`}>
                    {action}
                </li>
            );
        });

        return (
            <div ref="block" className="header header_fixed clearAfter" style={{'height': `${currentUser.user.is_demobar.length ? '73px' : '54px'}`}}>
                <div className="top-notification" style={{ 'display': `${currentUser.user.is_demobar.length ? 'block' : 'none'}`}}>{`${currentUser.user.is_demobar}`}</div>
                <ReactResizeDetector handleWidth handleHeight onResize={::this.onResize}/>
                <div ref="title" className="header__title" style={{'top': `${currentUser.user.is_demobar.length ? '19px' : '0'}`}}>
                    {this.props.title}
                </div>
                <div ref="menu" className="top-menu clearAfter">
                    <ul className="top-menu__list">
                        {list}
                    </ul>
                </div>
            </div>
        )
    }

}


@propTypes({
    text: PropTypes.string,
    icon: PropTypes.string
})

export class PageHeaderButton extends Component {
    render() {
        const Icon = this.props.icon
            ? (<i className={['icon', 'icon-' + this.props.icon, 'icon-16'].join(' ')}/> )
            : '';

        const Text = this.props.text
            ? <div className="text">{this.props.text}</div>
            : '';

        return (
            <div className="page-header-button">
                {Icon}
                {Text}
            </div>
        );
    }
}