import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getVehicles = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.getVehicles(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
export const getVehicles2 = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.getVehicles2(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getVehicle = (uuid) => async (dispatch) => {
    try {
        const response = await api.commdept.getVehicle({uuid});

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getKursVehicleByBaseVehicle = (vehicle_uuid) => async (dispatch) => {
    try {
        const response = await api.commdept.getVehicle({vehicle_uuid});

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createVehicle = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.createVehicle(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateVehicle = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.updateVehicle(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteVehicle = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.deleteVehicle(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
