import React, {Component} from 'react';
import formats from "dictionaries/formats";
import moment from "moment";
import _ from 'lodash';

export default class OrderByDayPrint extends Component {

    render() {
        let index = {
            number: 0,
        };

        return (
            <table>
                <thead>
                <tr>
                    <th colSpan="14" className="border-none text-left font-bold">Наряды
                        на {moment().add(1, 'day').format(formats.DATE)}</th>
                </tr>
                <tr>
                    <th colSpan="14" className="border-none text-left font-bold">{_.get(this.props.unit, 'name')}</th>
                </tr>
                <tr>
                    <th className="border-left">
                        №<br/>
                        п.п
                    </th>
                    <th className="border-left">
                        Маршрут
                    </th>
                    <th className="border-left">
                        Рег.№
                    </th>
                    <th className="border-left">
                        Выход
                    </th>
                    <th className="border-left">
                        Смена
                    </th>
                    <th className="border-left">
                        Вид перевозок
                    </th>
                    <th className="border-left">
                        ТС
                    </th>
                    <th className="border-left">
                        Водитель
                    </th>
                    <th className="border-left">
                        Кондуктор
                    </th>
                    <th className="border-left">
                        Рейсов
                    </th>
                    <th className="border-left">
                        Выезд
                    </th>
                    <th className="border-left">
                        На линии
                    </th>
                    <th className="border-left">
                        Возврат
                    </th>
                    <th className="border-left">
                        С линии
                    </th>
                </tr>
                </thead>
                <tbody className="border-none">
                {_.map(this.props.orders, this.renderOrder.bind(this, index))}
                </tbody>
            </table>
        );
    }

    renderOrder(index, order) {
        return _.map(order.shifts || [], this.renderOrderShift.bind(this, order, index));
    }

    renderOrderShift(order, index, shift) {
        index.number++;
        const productionRuns = _.filter(shift.runs || [], (run) => {
            return (run.type === 'production_forward') || (run.type === 'production_reverse');
        });
        const firstProductionRun = _.first(productionRuns);
        const lastProductionRun = _.last(productionRuns);

        const routeRegistry = _.find(this.props.routeRegistries, {uuid: order.route_registry_uuid});

        return (
            <tr key={`${order.uuid}:${shift.shift}`}>
                <td>{index.number}</td>
                <td>{_.uniq(_.map(shift.runs, 'route_number')).join(', ')}</td>
                <td>{_.uniq(_.map(shift.runs, 'route_registration_number')).join(', ')}</td>
                <td>{order.turn}</td>
                <td>{shift.shift}</td>
                <td>{routeRegistry ? _.get(_.find(this.props.rates_types, {value: routeRegistry.rates_type_uuid}), 'label') : '-'}</td>
                <td>{this.renderVehicles(order)}</td>
                <td>{this.renderDrivers(order)}</td>
                <td>{this.renderCheckTakers(order)}</td>
                <td>{productionRuns.length}</td>
                <td>{moment(shift.start_at).format(formats.TIME)}</td>
                <td>{firstProductionRun ? moment(firstProductionRun.date_from).format(formats.TIME) : '-'}</td>
                <td>{moment(shift.end_at).format(formats.TIME)}</td>
                <td>{lastProductionRun ? moment(lastProductionRun.date_to).format(formats.TIME) : '-'}</td>
            </tr>
        );
    }

    renderVehicles(order) {
        return _.uniq(_.filter(_.map(_.flatten(_.map(order.shifts, 'runs')), 'vehicle_state_number'))).join(', ');
    }

    renderDrivers(order) {
        return _.uniq(_.filter(_.map(_.flatten(_.map(order.shifts, 'runs')), 'driver_name'))).join(', ');
    }

    renderCheckTakers(order) {
        return _.map(_.uniq(_.filter(_.map(_.flatten(_.map(order.shifts, 'runs')), 'check_taker_uuid'))), (uuid) => {
            return this.props.related.get(uuid);
        }).join(', ');
    }

}