import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import Page from "components/ui/page";
import {connect} from "react-redux";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import GlobalLoaderComponent from "components/ui/global-loader";
import systems from "dictionaries/systems";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import classNames from 'classnames';

import './index.less';
import moment from "moment";
import formats from "dictionaries/formats";
import {getOrders, resourceCheck, updateOrder} from "store/reducers/kiutr/orders/orders";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getContracts} from "store/reducers/kiutr/contracts/contracts";
import {getRoutes} from "store/reducers/routes/route_editor";
import CheckboxDropdown from "components/ui/checkbox-dropdown";
import {SelectAsync} from "components/ui/select";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getUsers} from "store/reducers/staffing/staffing";
import OrderEditor from "components/modules/kiutr/orders/OrderOperationalForm/OrderEditor/index";
import KiutrOrderRunsComponent from "components/modules/kiutr/orders/OrderPlanningForm/OrderRuns/index";
import {User} from "helpers/user";
import {print} from "helpers/print";
import ReactDOMServer from 'react-dom/server';
import OrderByDayPrint from "components/modules/kiutr/orders/OrderByDayPrint/index";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import {getRouteRegistries} from "store/reducers/kiutr/route_registries";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {component_mapper} from "helpers/component_mapper";
import * as alerts from "helpers/alerts";

@connect(state => ({}), {
    getUnit,
    getOrders,
    getContracts,
    getRoutes,
    getVehicleList,
    updateOrder,
    resourceCheck,
    getUsers,
    getEntityNames,
    getRouteRegistries,
    getDictionaryList,
})

export default class OrderByDay extends Component {

    state = {
        unit: null,
        isLoading: false,
        orders: [],
        contracts: [],
        routes: [],
        selectedContracts: {},
        selectedRoutes: {},
        selectedDriver: [],
        runsActive: null,
        runsActiveShift: null,
        editorActive: null,
        editorActiveShift: null,
        related: new EntityList,
        routeRegistries: [],
        rates_types: [],
        page: 1,
        pages: 1,
    };

    getTitle() {
        return `Суточная разнарядка: ${_.get(this.state.unit, 'name', '...')} на ${moment().add(1, 'day').format(formats.DATE)}`;
    }

    async componentWillMount() {
        this.setState({
            isLoading: true,
        });
        this.loadDictionaries([
            'rates_types',
        ]);
        await Promise.all([
            this.loadUnit(),
            this.loadOrders(),
        ]);
        this.loadContracts();
        this.loadRoutes();
        this.setState({
            isLoading: false,
        });
    }

    async loadUnit() {
        const response = await this.props.getUnit(this.props.params.unitUuid);

        if (response.isOk) {
            this.setState({
                unit: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadOrders() {
        const response = await this.props.getOrders({
            filters: {
                withCarriers: [
                    this.props.params.unitUuid,
                ],
                withDate: moment().add(1, 'day').format(formats.DATE_API),
            },
            order: {
                column: 'route_number',
                direction: 'asc',
            },
            pagination: {
                page: this.state.page,
                limit: 25,
            },
        });

        if (response.isOk) {
            let newOrders;

            if (window.RNIS_SETTINGS.order_daily_optimization) {
                const previousEditedOrders = this.state.orders.filter(item => item.editStatus);
                const previousOrdersUuid = previousEditedOrders.map(item => item.uuid);
                const filteredNewOrders = response.payload.items.filter(item => !previousOrdersUuid.includes(item.uuid))
                newOrders = [...filteredNewOrders, ...previousEditedOrders]
            } else {
                newOrders = response.payload.items
            }

            await this.setState({
                pages: response.data.headers.meta.pagination.total_pages,
                orders: _.orderBy(newOrders, ['route_number', 'turn'], ['asc', 'asc']),
            });
            this.loadRelatedEntities();
            this.loadRouteRegistries();
        } else {
            response.showErrors();
        }
    }

    async loadRouteRegistries() {
        const uuids = _.uniq(_.filter(_.map(this.state.orders, 'route_registry_uuid')));

        const response = await this.props.getRouteRegistries({
            filters: {
                withUuid: uuids,
            },
            response_data: [
                'items/uuid',
                'items/rates_type_uuid',
            ],
        });

        if (response.isOk) {
            this.setState({
                routeRegistries: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadContracts() {
        const response = await this.props.getContracts({
            filters: {
                withCarriers: this.props.params.unitUuid,
            },
            response_data: [
                'items/uuid',
                'items/number',
            ],
        });

        if (response.isOk) {
            this.setState({
                contracts: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.number,
                })),
                selectedContracts: _.mapValues(_.keyBy(response.payload.items, 'uuid'), item => true),
            });
        } else {
            response.showErrors();
        }
    }

    async loadRoutes() {
        const response = await this.props.getRoutes({
            filters: {
                withCarriers: this.props.params.unitUuid,
            },
            response_data: [
                'items/uuid',
                'items/number',
                'items/title',
            ],
        });

        if (response.isOk) {
            this.setState({
                routes: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: `${item.number} ${item.title}`,
                })),
                selectedRoutes: _.mapValues(_.keyBy(response.payload.items, 'uuid'), item => true),
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        const title = this.getTitle();

        const loader = this.state.isLoading ? (<GlobalLoaderComponent/>) : null;

        return (
            <Page
                pageId="OrderByDay"
                title={title}
                className="profile-modal"
                headerActions={this.renderHeaderActions()}
                headerContents={this.renderHeaderContents()}
            >
                {loader}
                {this.renderContent()}
                {this.renderPages()}
                {this.renderModals()}
            </Page>
        );
    }

    async setPage(page) {
        await this.setState({
            page,
        });
        this.setState({
            isLoading: true,
        });
        await this.loadOrders();
        this.setState({
            isLoading: false,
        });
    }

    renderPages() {
        return (
            <div className="dataTables_paginate paging_simple_numbers">
                <span>
                    {_.map(_.range(1, this.state.pages + 1), (page) => {
                        return (
                            <a key={page} href="javascript:void(0)" onClick={this.setPage.bind(this, page)} className={classNames('paginate_button', (page === this.state.page) ? 'current' : '')}>{page}</a>
                        );
                    })}
                </span>
            </div>
        );
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="base.back" code="base.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ];
    }

    renderHeaderContents() {
        return [
            <ContextTooltip key="base-table-list.print" code="base-table-list.print" default="Печать">
                <IconButton icon="print" onClick={::this.print}/>
            </ContextTooltip>,
            <CheckboxDropdown
                key="order-by-day-contracts"
                items={this.state.contracts}
                selectedItems={this.state.selectedContracts}
                toggleSelectedItem={this.toggleSelected.bind(this, 'selectedContracts')}
                contextKey="order-by-day.contracts-select"
                contextDefault={`Выбор ${window.RNIS_SETTINGS.rename_contracts ? 'подрядных обязательств' : 'контрактов'}`}
                icon="organizer"
            />,
            <CheckboxDropdown
                key="order-by-day-routes"
                items={this.state.routes}
                selectedItems={this.state.selectedRoutes}
                toggleSelectedItem={this.toggleSelected.bind(this, 'selectedRoutes')}
                contextKey="order-by-day.routes-select"
                contextDefault="Выбор маршрутов"
                icon="route"
            />,
        ];
    }

    async toggleSelected(type, uuid) {
        let selected = this.state[type] || {};
        selected[uuid] = !selected[uuid];

        let newState = {};
        newState[type] = selected;
        await this.setState(newState);
    }

    onClose() {
        this.props.router.push('/kiutr/orders');
    }

    renderContent() {
        return (
            <div className="Table">
                <TableContainer>
                    <table className="b-table">
                        <thead>
                        <tr>
                            <th colSpan={8}>
                                <span className="total-apparel">
                                    <span className="table-header_title">Всего нарядов/обеспечено:</span>
                                    &nbsp;
                                    <span className="table-header_text">
                                        {this.state.orders.length}/{_.filter(this.state.orders, {provision_status: 'full'}).length}
                                    </span>
                                </span>
                                <span className="selection-apparel">
                                    <span className="table-header_title">В текущей выборке/обеспечено:</span>
                                    &nbsp;
                                    <span className="table-header_text">
                                        {this.getOrders().length}/{_.filter(this.getOrders(), {provision_status: 'full'}).length}
                                    </span>
                                </span>
                            </th>
                        </tr>
                        <tr role="row">
                            <th>№ Маршрута</th>
                            <th>№ Выхода</th>
                            <th>Смена</th>
                            <th>ТС</th>
                            <th>Водитель</th>
                            <th>Начало наряда</th>
                            <th>Окончание наряда</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {_.map(this.getOrders(), ::this.renderOrder)}
                        </tbody>
                    </table>
                </TableContainer>
            </div>
        );
    }

    getOrders() {
        const contracts = _.keys(_.pickBy(this.state.selectedContracts, item => !!item));
        const routes = _.keys(_.pickBy(this.state.selectedRoutes, item => !!item));

        return _.filter(this.state.orders, (order) => {
            return (contracts.indexOf(order.contract_uuid) !== -1) && (routes.indexOf(order.route_uuid) !== -1);
        });
    }

    async loadVehiclesForSelect(input, callback) {
        if (!input) {
            callback(null, {
                options: [],
                complete: false,
            });
            return;
        }
        const result = await this.props.getVehicleList({
            filters: {
                withComponent: component_mapper(this.props.params.component),
                withoutScrapped: true,
                onlyApproved: true,
                withActiveBnso: true,
            },
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
            response_data: [
                'items/uuid',
                'items/state_number',
            ],
        });

        if (result.isOk) {
            let vehicles;
            if (window.RNIS_SETTINGS.order_daily_optimization) {
                vehicles = result.payload.items
            } else {
                vehicles = result.payload.items
            }

            callback(null, {
                options: _.sortBy(vehicles.map(item => ({
                    label: item.state_number,
                    value: item.uuid,
                })), 'label'),
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    async loadDriversForSelect(input, callback) {
        if (!input) {
            callback(null, {
                options: [],
                complete: false,
            });
            return;
        }
        const result = await this.props.getUsers({
            filters: {
                withComponent: component_mapper(this.props.params.component),
                withPositionTypes: [
                    'driver',
                ],
            },
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
            response_data: [
                'items/uuid',
                'items/info/surname',
                'items/info/name',
                'items/info/second_name',
            ],
        });

        if (result.isOk) {
            let drivers;
            if (window.RNIS_SETTINGS.order_daily_optimization) {
                drivers = result.payload.items.filter(item => {
                    return !this.state.selectedDriver.includes(item.uuid)
                })
            } else {
                drivers = result.payload.items
            }

            callback(null, {
                options: _.sortBy(drivers.map(i => ({
                    label: new User(i).getFullName(),
                    value: i.uuid,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    async checkVehicle(order, shift, vehicleUuid) {
        const response = await this.props.resourceCheck({
            order_uuid: order.uuid,
            vehicle_uuid: vehicleUuid,
            date_from: shift.start_at,
            date_to: shift.end_at,
        });

        if (!response.isOk) {
            response.showErrors();
            return false;
        }
        return true;
    }

    async checkDriver(order, shift, driverUuid) {
        const response = await this.props.resourceCheck({
            order_uuid: order.uuid,
            driver_uuid: driverUuid,
            date_from: shift.start_at,
            date_to: shift.end_at,
        });

        if (!response.isOk) {
            response.showErrors();
            return false;
        }
        return true;
    }

    async onChange(order, shift, field, e) {
        const value = e ? e.value : null;
        if (!value) {
            return;
        }

        this.setState({
            isLoading: true,
        });
        if ((field === 'vehicle_uuid') && !await this.checkVehicle(order, shift, value)) {
            this.setState({
                isLoading: false,
            });
            return;
        }
        if ((field === 'driver_uuid') && !await this.checkDriver(order, shift, value)) {
            this.setState({
                isLoading: false,
            });
            return;
        }

        if (window.RNIS_SETTINGS.order_daily_optimization) {
            order.editStatus = true;
            order.shifts = _.map(order.shifts || [], (_shift) => {
                if (_shift.shift === shift.shift) {
                    _shift[field] = value;
                    if (field === 'vehicle_uuid') {
                        const vehicle_state_number = e ? e.label : null;
                        _shift.runs = _.map(_shift.runs || [], (_run) => {
                            _run['vehicle_uuid'] = value;
                            _run['vehicle_state_number'] = vehicle_state_number;
                            return _run })
                    }
                    if (field === 'driver_uuid') {
                        const driver_name = e ? e.label : null;
                        _shift['driver_name'] = driver_name;
                        _shift.runs = _.map(_shift.runs || [], (_run) => {
                            _run['driver_uuid'] = value;
                            _run['driver_name'] = driver_name;
                            return _run })
                    }

                }
                return _shift;
            });

            const filter = this.state.orders.filter(item => item.uuid !== order.uuid);
            const localUpdatedOrders = _.orderBy([...filter, order], ['route_number', 'turn'], ['asc', 'asc']);

            if (field === 'vehicle_uuid') {
                this.setState((prevState) => ({
                    orders: localUpdatedOrders,
                    isLoading: false,
                }))
            }

            if (field === 'driver_uuid') {
                this.setState((prevState) => ({
                    orders: localUpdatedOrders,
                    isLoading: false,
                    selectedDriver: [...prevState.selectedDriver, value]
                }))
            }

            for (let _shift of order.shifts) {
                if (_shift.vehicle_uuid && _shift.driver_name) {
                    const response = await this.props.updateOrder(order);
                    if (response.isOk) {
                        this.reload();
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                        alerts.error(Object.values(response.errors[0].data))
                        response.showErrors();
                    }
                    break;
                }
            }
        } else {
            order.shifts = _.map(order.shifts || [], (_shift) => {
                if (_shift.shift === shift.shift) {
                    _shift[field] = value;
                }
                return _shift;
            });

            const response = await this.props.updateOrder(order);
            if (response.isOk) {
                this.reload();
            } else {
                this.setState({
                    isLoading: false,
                });
                alerts.error(Object.values(response.errors[0].data))
                response.showErrors();
            }

        }
    }

    async reload() {
        if (window.RNIS_SETTINGS.order_daily_optimization) {
            await this.loadOrders();
        } else {
            this.setState({
                isLoading: true,
            });
            await this.loadOrders();
            this.setState({
                isLoading: false,
            });
        }
    }

    renderOrder(order) {
        return _.map(order.shifts || [], this.renderOrderShift.bind(this, order));
    }

    renderOrderShift(order, shift) {
        return (
            <tr key={`${order.uuid}:${shift.shift}`}>
                <td>{_.uniq(_.map(shift.runs, 'route_number')).join(', ')}</td>
                <td>{order.turn}</td>
                <td>{shift.shift}</td>
                <td className={classNames(this.isRed(shift, 'vehicle_uuid') ? 'red' : '', 'cell-with-select')}>
                    {(_.filter(_.map(shift.runs, 'vehicle_uuid')).length === 0) ? (
                        <SelectAsync
                            loadOptions={::this.loadVehiclesForSelect}
                            value={null}
                            onChange={this.onChange.bind(this, order, shift, 'vehicle_uuid')}
                        />
                    ) : this.renderVehicles(shift)}
                </td>
                <td className={classNames(this.isRed(shift, 'driver_uuid') ? 'red' : '', 'cell-with-select')}>
                    {(_.filter(_.map(shift.runs, 'driver_uuid')).length === 0) ? (
                        <SelectAsync
                            loadOptions={::this.loadDriversForSelect}
                            value={null}
                            onChange={this.onChange.bind(this, order, shift, 'driver_uuid')}
                        />
                    ) : this.renderDrivers(shift)}
                </td>
                <td>{moment(shift.start_at).format(formats.TIME)}</td>
                <td>{moment(shift.end_at).format(formats.TIME)}</td>
                <td>
                    <ul className="top-menu_modal__list">
                        <li className="top-menu_modal__item">
                            <ContextTooltip key="order-by-day.resources" code="order-by-day.resources"
                                            default="Порейсовое обеспечение">
                                <a className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_book"
                                   href="javascript:void(0)" onClick={this.showRuns.bind(this, order, shift.shift)}></a>
                            </ContextTooltip>
                        </li>
                        <li className="top-menu_modal__item">
                            <ContextTooltip default="Редактировать смену">
                                <a className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_edit"
                                   href="javascript:void(0)"
                                   onClick={this.showEditor.bind(this, order, shift.shift)}></a>
                            </ContextTooltip>
                        </li>
                    </ul>
                </td>
            </tr>
        );
    }

    renderVehicles(shift) {
        return _.uniq(_.filter(_.map(shift.runs, 'vehicle_state_number'))).join(', ');
    }

    renderDrivers(shift) {
        return _.uniq(_.filter(_.map(shift.runs, 'driver_name'))).join(', ');
    }

    async loadRelatedEntities() {
        const users = _.map(_.uniq(_.filter(_.map(_.flatten(_.map(_.flatten(_.map(this.state.orders, 'shifts')), 'runs')), 'check_taker_uuid'))), (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));

        const response = await this.props.getEntityNames(users);

        if (response.isOk) {
            this.state.related.add(response);
        }
    }

    isRed(shift, field) {
        const values = _.map(shift.runs, field);
        const filteredValues = _.filter(values);

        return ((filteredValues.length > 0) && (filteredValues.length !== values.length));
    }

    showRuns(order, shift) {
        this.setState({
            runsActive: order,
            runsActiveShift: shift,
        });
    }

    showEditor(order, shift) {
        this.setState({
            editorActive: order,
            editorActiveShift: shift,
        });
    }

    hideRuns(reload = false) {
        reload && this.reload();

        this.setState({
            runsActive: null,
        });
    }

    hideEditor(reload = false) {
        reload && this.reload();

        this.setState({
            editorActive: null,
        });
    }

    renderModals() {
        if (this.state.runsActive) {
            return (
                <KiutrOrderRunsComponent
                    {...this.props}
                    withFade={true}
                    order={_.cloneDeep(this.state.runsActive)}
                    shift={this.state.runsActiveShift}
                    onSubmit={this.hideRuns.bind(this, true)}
                    onClose={this.hideRuns.bind(this, false)}
                />
            );
        }

        if (this.state.editorActive) {
            return (
                <OrderEditor
                    {...this.props}
                    withFade={true}
                    onClose={this.hideEditor.bind(this, false)}
                    onSubmit={this.hideEditor.bind(this, true)}
                    shift={this.state.editorActiveShift}
                    order={_.cloneDeep(this.state.editorActive)}
                />
            );
        }

        return null;
    }

    print() {
        const style = `
            <style>
                table {
                    color: #000;
                    border-collapse: collapse;
                    vertical-align: middle;
                    font: 400 11px/1.4 "Arial", sans-serif;
                }
                
                th, td {
                    border: 1px solid #000;
                    text-align: center;
                    padding: 5px;
                }
                
                tbody td {
                    border: 1px solid #000;
                    border-width: 0 0 1px 0;
                }
                tfoot td {
                    border: 1px solid #000;
                    border-width: 0 0 1px 0;
                }
                
                .word-wrap {
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    -ms-word-break: break-all;
                    word-break: break-all;
                    word-break: break-word;
                    -ms-hyphens: auto;
                    -moz-hyphens: auto;
                    -webkit-hyphens: auto;
                    hyphens: auto;
                }
                
                .medium-table {
                    font-size: 9px;
                }
                
                .small-table {
                    font-size: 8px;
                }
                
                .small-table td{
                    padding: 1px;
                }
                
                .small-table th{
                    padding: 1px;
                }
                
                .text-nowrap {
                    white-space: nowrap;
                }
                .text-left {
                    text-align: left;
                }
                .text-right {
                    text-align: right;
                }
                .font-bold {
                    font-weight: 700;
                }
                
                .font-bold td{
                    font-weight: 700;
                }
                
                .font-normal {
                    font-weight: 400;
                }
                .text-uppercase {
                    text-transform: uppercase;
                }
                .width-40 {
                    width: 40px;
                }
                
                .border-n-child td {
                    border: none;
                }
                
                .border-n-child th {
                    border: none;
                }
                
                .border-t-child td {
                    border-top: 1px solid #000;
                }
                
                .border-t-child th {
                    border-top: 1px solid #000;
                }
                
                .border-b-child td {
                    border-bottom: 1px solid #000;
                }
                
                .border-b-child th {
                    border-bottom: 1px solid #000;
                }
                
                .border-r-child td {
                    border-right: 1px solid #000;
                }
                
                th.border-none,
                td.border-none {
                    border: none;
                }
                
                th.border-all,
                td.border-all {
                    border: 1px solid #000 !important;
                }
                th.border-bottom,
                td.border-bottom {
                    border-bottom: 1px solid #000 !important;
                }
                th.border-top,
                td.border-top {
                    border-top: 1px solid #000 !important;
                }
                th.border-right, 
                td.border-right {
                    border-right: 1px solid #000 !important;
                }
                th.border-left, 
                td.border-left {
                    border-left: 1px solid #000 !important;
                }
            </style>
        `;

        print(style + ReactDOMServer.renderToStaticMarkup(<OrderByDayPrint
            {...this.state}
        />));
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }
}
