import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import classNames from 'classnames';

import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import GlobalLoaderComponent from "components/ui/global-loader";
import PageModal from 'components/ui/page-modal';
import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import SlideLeftTransition from "components/ui/transitions/slide-left";
import Editor from "./editor";
import {
    cloneSchedule, createSchedule, deleteSchedule, getSchedule,
    getSchedules
} from "store/reducers/kiutr/schedules/schedules";
import formats from "dictionaries/formats";
import moment from "moment";
import './schedules.less';
import ContextTooltip from "components/ui/context-tooltip";
import RouteDeviations from "components/modules/kiutr/routes/RouteDeviations/index";
import TableComponent from "components/ui/Table/Component/TableComponent";
import TableContainer from "components/ui/Table/Container/TableContainer";
import currentUser from 'helpers/current-user';
import {isCarriersLimits} from "../../../../../helpers/functions";

@propTypes({
    routeUuid: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    single: PropTypes.bool,
})

@defaultProps({
    single: false,
})

@connect(state => ({}), {getSchedules, deleteSchedule, cloneSchedule})

export default class KiutrRouteSchedulesComponent extends BaseTableWithEditorComponent {

    title = 'Варианты расписаний';

    modelClass = 'App\\Model\\Schedule';
    modelSource = 'geo';

    static contextTypes = {
        resizeModals: PropTypes.func
    };

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            loading: false,
            schedule_statuses: [],

            showRouteDeviations: false,
        });
    }

    async componentDidMount() {

    }

    async componentWillUpdate(props, state) {
    }

    getEditor() {
        return (
            <Editor
                isPageWithDetect={true}
                key="editor"
                {...this.props}
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    async submitEditor(data) {
        this.reload();
        if (!this.state.editorUuid) {
            await this.closeEditor();
            this.props.router.push(`/${this.props.params.component}/routes/${this.props.params.uuid}/schedules/${data.uuid}/turn/1`);
        } else {
            await this.closeEditor();
        }
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Наименование')
                .fromField('name'),

            new Column('Дата начала')
                .fromField('date_from')
                .withDrawer((item) => item.date_from && moment(item.date_from).format(formats.DATE)),

            new Column('Дата конца')
                .fromField('date_to')
                .withDrawer((item) => item.date_to && moment(item.date_to).format(formats.DATE)),

            new Column('Сезон')
                .fromField('seasons')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer((item) => _.map(item.seasons || [], (season) => this.state.related.get(season)).join('<br/>')),

            new Column('Пн')
                .fromField('monday')
                .withDrawer(item => item.monday ? '&check;' : '<span class="muted-text">&times;</span>')
                .withAlign('center')
                .withClassName('width-narrow')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Вт')
                .fromField('tuesday')
                .withDrawer(item => item.tuesday ? '&check;' : '<span class="muted-text">&times;</span>')
                .withAlign('center')
                .withClassName('width-narrow')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Ср')
                .fromField('wednesday')
                .withDrawer(item => item.wednesday ? '&check;' : '<span class="muted-text">&times;</span>')
                .withAlign('center')
                .withClassName('width-narrow')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Чт')
                .fromField('thursday')
                .withDrawer(item => item.thursday ? '&check;' : '<span class="muted-text">&times;</span>')
                .withAlign('center')
                .withClassName('width-narrow')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Пт')
                .fromField('friday')
                .withDrawer(item => item.friday ? '&check;' : '<span class="muted-text">&times;</span>')
                .withAlign('center')
                .withClassName('width-narrow')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Сб')
                .fromField('saturday')
                .withDrawer(item => item.saturday ? '&check;' : '<span class="muted-text">&times;</span>')
                .withAlign('center')
                .withClassName('width-narrow')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Вс')
                .fromField('sunday')
                .withDrawer(item => item.sunday ? '&check;' : '<span class="muted-text">&times;</span>')
                .withAlign('center')
                .withClassName('width-narrow')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Пр.дни')
                .fromField('holiday')
                .withDrawer(item => item.holiday ? '&check;' : '<span class="muted-text">&times;</span>')
                .withAlign('center')
                .withClassName('width-narrow')
                .denyOrder()
                .denyColumnFilter(),
        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteSchedule(data);
    }

    async loadData(meta) {
        meta.filters.withRoute = this.props.routeUuid;
        if (currentUser.isSupervisor()) {
            meta.filters.withTrashed = true;
        }
        return await this.props.getSchedules(meta);
    }

    async onDblClick(data) {
        if (data && data.uuid) {
            this.props.router.push(`/${this.props.params.component}/routes/${this.props.params.uuid}/schedules/${data.uuid}`);
        }
    }

    render() {
        let table = this.renderTable();

        const editor = this.state.showEditorModal ? this.getEditor() : '';

        const buttons = (
            <ModalTopMenuButtons>
                <ModalTopMenuList className="top-menu_modal_edit">
                    <ContextTooltip key="route-deviations.show" code="route-deviations.show"
                                    default="Допустимые отклонения">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_deviations"
                            onClick={::this.showRouteDeviations}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="base-editor.clone" code="base-editor.clone" default="Клонировать">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_clone"
                            onClick={::this.cloneSelected}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="base-editor.create" code="base-editor.create" default="Добавить">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_plus"
                            onClick={::this.showEditor}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="base-editor.update" code="base-editor.update" default="Редактировать">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_edit"
                            onClick={::this.editSelected}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="base-editor.delete" code="base-editor.delete" default="Удалить">
                        <ModalTopMenuListItem
                            key="delete"
                            className="b-icon-link_icon_basket"
                            title="Удалить"
                            onClick={::this.deleteSelected}
                        />
                    </ContextTooltip>
                    <ModalTopMenuListSeparator key="separator"/>
                </ModalTopMenuList>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        const loader = this.state.loading ? <GlobalLoaderComponent/> : null;

        return (
            <div>
                <PageModal
                    header={{title: 'Варианты расписаний', buttons}}
                    className={classNames('b-modal-edit b-modal-route route-schedules-modal Schedules', {
                        'route-schedules-modal-single': this.props.single, 'route-schedules-modal-withInterval': this.props.interval
                    })}
                    withFade={this.props.single}
                    onClose={this.props.onClose}
                >
                    {loader}
                    <TableContainer>
                        {table}
                    </TableContainer>
                </PageModal>
                {this.state.showEditorModal ? (
                    <SlideLeftTransition>
                        {editor}
                    </SlideLeftTransition>
                ) : null}
                {this.state.showRouteDeviations ? (
                    <RouteDeviations
                        uuid={this.props.routeUuid}
                        mode="edit"
                        onSubmit={::this.hideRouteDeviations}
                        onClose={::this.hideRouteDeviations}
                    />
                ) : null}
            </div>
        );
    }

    async cloneSelected() {
        if (!this.refs.table) return;
        let selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            this.setState({loading: true});
            const response = await this.props.cloneSchedule({
                uuid: selected.uuid,
            });
            this.setState({loading: false});
            if (response.isOk) {
                this.reload();
            } else {
                response.showErrors();
            }
        }
    }

    showRouteDeviations() {
        this.setState({
            showRouteDeviations: true,
        });
    }

    hideRouteDeviations() {
        this.setState({
            showRouteDeviations: false,
        });
    }

    renderTable() {
        const className = classNames({
            'show-table-filters': this.state.showTableFilters,
        });

        return (
            <div className={className}>
                <TableComponent
                    ref="table"
                    select={this.select}
                    columns={this.state.columns}
                    loadCallback={::this.loadCallbackMiddleware}
                    onColsReordered={::this.onColsReordered}
                    onCheck={::this.selectedRowsRecalc}
                    checkCache={::this.checkCache}
                    query={this.state.query}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    onSettingsLoad={::this.onSettingsLoad}
                    onDblClick={::this.onDblClick}
                    shortPagination={true}
                    showAudit={::this.onAuditClick}
                />
            </div>
        );
    }

    resize() {
        this.context.resizeModals && this.context.resizeModals();
    }

    async closeEditor() {
        await this.setState({
            showEditorModal: false,
            editorUuid: null,
        });

        this.resize();
    }

    async showEditorWithUuid(uuid = null) {
        await this.closeEditor();

        this.setState({
            showEditorModal: true,
            editorUuid: uuid,
        });

        this.resize();
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const seasons = _.map(_.uniq(_.flatten(_.map(result, 'seasons'))), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\Seasons\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(seasons);

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}
