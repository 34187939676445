import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import _ from 'lodash';
import {success, error} from 'helpers/response';

const GET_USER = 'GET_USER';

const initialState = new Map({
    user: Map({})
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_USER:
            return state
                .set('user', action.payload);
    }

    return state;
}

export const getUser = (uuid) => async (dispatch) => {
    try {
        let response = await api.auth.getUser(uuid);

        dispatch({
            type: GET_USER, payload: response.payload
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createUser = (user) => async (dispatch) => {
    try {
        let response = await api.auth.createUser(user);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateUser = (user) => async (dispatch) => {
    try {
        let response = await api.auth.updateUser(user);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteUser = (user) => async (dispatch) => {
    try {
        let response = await api.auth.deleteUser(user);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};