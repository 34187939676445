import React from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import moment from 'moment';
import formats from 'dictionaries/formats';
import _ from 'lodash';
import './style.less';
import TableContainer from "components/ui/Table/Container/TableContainer";
import {connect} from "react-redux";
import {getVehicleList} from "store/reducers/vehicles/vehicles";

@propTypes({
    data: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired
})

@connect(state => ({}), {getVehicleList})

export default class RouteSummary extends React.Component {
    state = {
        vehicles: [],
    };

    componentWillMount() {
        this.loadData();
    }

    async getVehicles(uuids) {
        const response = await this.props.getVehicleList({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            return response.payload.items;
        } else {
            response.showErrors();
        }
        return [];
    }

    async loadData() {
        const vehicleUuids = _.filter(_.uniq(_.map(_.flatten(_.map(_.flatten(_.map(this.props.data.orders, 'shifts')), 'runs')), 'vehicle_uuid')));
        const vehicles = await this.getVehicles(vehicleUuids);

        this.setState({
            vehicles,
        });
    }

    getPlanEnvironment() {
        const {data} = this.props;

        const envClassUuid = _.get(data, 'contract.environmental_class_uuid');

        if (envClassUuid) {
            return data.vehicleEnvironmentClasses[envClassUuid].name;
        }

        return null;
    }

    getFactEnvironment() {
        const {data} = this.props;

        const classes = _.uniq(_.filter(_.map(this.state.vehicles, 'environmental_class_uuid')));

        return _.map(classes, (environmentClass) => {
            return data.vehicleEnvironmentClasses[environmentClass].name;
        }).join(', ');
    }

    render() {
        const {data, date} = this.props;

        const indexCapacityTypes = {};

        const keys = Object.keys(data.vehicleCapacityTypes);
        const doubleKeys = keys.concat(keys);

        let i = 0;
        keys.forEach((key, index) => {
            indexCapacityTypes[i] = key;
            indexCapacityTypes[i + 1] = key;
            i += 2;
        });

        const day = moment(date, formats.DATE).isoWeekday();
        const month = moment(date, formats.DATE).month() + 1;

        const routeScheduleType = data.routeScheduleTypes[day];
        const season = data.seasons[month];

        const vehiclesPlan = _.filter(data['vehicles_plan'], {
            'season_uuid': season,
            'route_schedule_type_uuid': routeScheduleType
        });

        return (
            <div className="page-block info">
                <div>
                    <div className="info__detail">
                        <span className="bold">Дата: </span> {date}
                    </div>
                    <div className="info__detail">
                        <span className="bold">Маршрут: </span> №{_.get(data, 'route.number')}
                        &nbsp;
                        ({_.get(data, 'route.title')})
                    </div>
                    <div className="info__detail">
                        <span className="bold">Перевозчик: </span> {_.get(data, 'contract.unit_title')}
                    </div>
                </div>
                <div className="Table indent-none">
                    <TableContainer>
                        <table className="b-table b-table-thead-no-hover">
                            <thead>
                            <tr>
                                <th rowSpan="2">Сводка по маршруту</th>
                                <th colSpan="2">Все классы</th>
                                {keys.map((key, index) =>
                                    <th key={index} colSpan="2">{data.vehicleCapacityTypes[key].name}</th>
                                )}
                            </tr>
                            <tr>
                                <th>{window.RNIS_SETTINGS.rename_contracts ? 'Подрядное обязательство' : 'Контракт'}</th>
                                <th>Факт</th>
                                {doubleKeys.map((key, index) =>
                                    index % 2 === 0 ? <th key={index}>{window.RNIS_SETTINGS.rename_contracts ? 'Подрядное обязательство' : 'Контракт'}</th> : <th key={index}>Факт</th>
                                )}
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Количество ТС</td>
                                <td>{_.toInteger(_.sumBy(vehiclesPlan, 'value'))}</td>
                                <td className={(_.toInteger(_.sumBy(vehiclesPlan, 'value')) === this.state.vehicles.length) ? 'normal' : 'failure'}>{this.state.vehicles.length}</td>
                                {doubleKeys.map((key, index) => {
                                    const plan = _.toInteger(_.get(_.find(vehiclesPlan, {'vehicle_capacity_type_uuid': indexCapacityTypes[index]}), 'value', 0));

                                    if (index % 2 === 0) {
                                        return (
                                            <td key={index}>{plan}</td>
                                        );
                                    } else {
                                        const fact = _.filter(this.state.vehicles, {capacity_type_uuid: indexCapacityTypes[index]}).length;
                                        return (
                                            <td key={index} className={(plan === fact) ? 'normal' : 'failure'}>
                                                {fact}
                                            </td>
                                        );
                                    }
                                })}
                            </tr>
                            <tr>
                                <td>Экологический класс</td>
                                <td>{this.getPlanEnvironment()}</td>
                                <td className={(this.getPlanEnvironment() === this.getFactEnvironment()) ? 'normal' : 'failure'}>{this.getFactEnvironment()}</td>
                                {doubleKeys.map((key, index) => {
                                    if (index % 2 === 0) {
                                        return <td key={index}/>;
                                    }
                                    else {
                                        return <td key={index}/>
                                    }
                                })}
                            </tr>
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </div>
        );
    }
}