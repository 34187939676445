import React from 'react';
import moment from "moment";
import formats from "dictionaries/formats";
import _ from 'lodash';
import {State} from "components/ui/state";
import ReactDOMServer from 'react-dom/server';

export class DictionaryField {
    constructor(field) {
        this.__field = field;
    }

    /**
     * @return {String}
     */
    get description() {
        return this.__field.description;
    }

    /**
     * @return {String}
     */
    get title() {
        return this.__field.title;
    }

    /**
     * @return {String}
     */
    get key() {
        return this.__field.key;
    }

    /**
     * @return {boolean}
     */
    get editable() {
        return this.__field.editable;
    }

    /**
     * @return {Object}
     */
    get meta() {
        return this.__field.meta;
    }

    /**
     * @return {String}
     */
    get type() {
        return this.__field.type;
    }

    /**
     * @param {String} value
     * @return {String}
     */
    prepareValue(value) {
        switch (this.type) {
            case 'App\\Meta\\Fields\\UUID':
                return value;

            case 'App\\Meta\\Fields\\DatePicker':
                return moment(value).format(formats.DATE);
            case 'App\\Meta\\Fields\\DateTimePicker':
                return moment(value).format(formats.DATETIME_SHORT);

            case 'App\\Meta\\Fields\\Checkbox':
                return ReactDOMServer.renderToStaticMarkup(<State positive={value}/>);

            case 'App\\Meta\\Fields\\Select':
                return _.get(_.find(_.values(this.meta.options), {value}), 'label');
        }

        return value;
    }
}