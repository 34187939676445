import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {orderBy, cloneDeep} from 'lodash';
import {AutoSizer, MultiGrid, ColumnSizer, CellMeasurerCache, CellMeasurer} from 'react-virtualized';
import './style.less';


const STYLE = {
    border: "1px solid #ddd"
};

const cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 40,
});

@propTypes({
    data: PropTypes.array,
    metadata: PropTypes.object,
    rowHeight: PropTypes.number
})

@defaultProps({
    data: [],
    metadata: {},
    rowHeight: 40
})

export default class DashboardTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sortIndex: -1,
            sort: '',
            data: []
        };

        //this.rowHeight = 40;
    }

    componentWillMount() {
        this.setState({data: cloneDeep(this.props.data)});
    }

    componentWillReceiveProps(nextProps) {
        const data = cloneDeep(nextProps.data);
        this.setState({data: (this.state.sortIndex !== -1) ? orderBy(data, [this._getColumnField(this.state.sortIndex)], [this.state.sort]) : data});
    }

    _onHeaderClick(index) {
        if (!this._isColumnSortable(index)) {
            return;
        }

        let sort;

        if (this.state.sort !== 'asc') {
            sort = 'asc';
        }
        else {
            sort = 'desc';
        }

        this.setState({sortIndex: index, sort, data: orderBy(this.state.data, [this._getColumnField(index)], [sort])});
    }

    _isColumnSortable(columnIndex) {
        return this.props.metadata[columnIndex].sort;
    }

    _getColumnField(columnIndex) {
        return this.props.metadata[columnIndex].field;
    }

    _getColumnName(columnIndex) {
        return this.props.metadata[columnIndex].name;
    }

    _headerCellRenderer({columnIndex, key, rowIndex, style}) {

        const sortClass = this._isColumnSortable(columnIndex) && columnIndex === this.state.sortIndex && this.state.sort ? 'sorting_' + this.state.sort : '';
        return (
            <div className={"dashboard_table__header_cell " + sortClass} key={key} style={style}
                 onClick={this._onHeaderClick.bind(this, columnIndex)}>
                <div>
                    {this._getColumnName(columnIndex)}
                </div>
            </div>
        );
    }

    _getItem(rowIndex, columnIndex) {
        const field = this._getColumnField(columnIndex);
        return this.state.data[rowIndex - 1][field];
    }

    _cellRenderer = ({columnIndex, key, rowIndex, style, parent}) => {
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={columnIndex}
                key={key}
                parent={parent}
                rowIndex={rowIndex}
            >
                {(rowIndex === 0) ? (
                    this._headerCellRenderer({columnIndex, key, rowIndex, style})
                ) : (
                    <div className="dashboard_table__cell" key={key} style={style}>
                        <div>
                            {this._getItem(rowIndex, columnIndex)}
                        </div>
                    </div>
                )}
            </CellMeasurer>
        );
    };

    render() {
        const rowCount = this.state.data.length + 1;
        //let tableHeight = rowCount * this.props.rowHeight + 20;
        //const maxHeight = 700;
        const columnCount = Object.keys(this.props.metadata).length;

        return (
            <AutoSizer /*disableHeight*/>
                {({width, height}) => {
                    //const maxHeight = height - 110;

                    /*if (tableHeight > maxHeight) {
                     tableHeight = maxHeight
                     }*/


                    return (
                        <ColumnSizer
                            columnMaxWidth={1000}
                            columnMinWidth={100}
                            columnCount={columnCount}
                            key="GridColumnSizer"
                            width={width}
                        >
                            {({adjustedWidth, columnWidth, registerChild}) => {
                                //console.log(adjustedWidth, columnWidth);
                                return (
                                    <div
                                        //className={styles.GridContainer}
                                        style={{
                                            //height: this.props.rowHeight,
                                            width: adjustedWidth
                                        }}
                                    >
                                        <MultiGrid
                                            sort={this.state.sort}
                                            ref={registerChild}
                                            cellRenderer={this._cellRenderer}
                                            columnWidth={columnWidth}
                                            columnCount={columnCount}
                                            //enableFixedColumnScroll
                                            //enableFixedRowScroll
                                            width={adjustedWidth}
                                            height={height}
                                            rowHeight={cache.rowHeight}
                                            deferredMeasurementCache={cache}
                                            rowCount={rowCount}
                                            style={STYLE}
                                            className="dashboard_table"
                                            fixedRowCount={1}
                                            data={this.state.data}
                                            autoContainerWidth
                                        />
                                    </div>)
                            }}
                        </ColumnSizer>
                    )
                }}
            </AutoSizer>
        );
    }
}