import React from 'react';

import Loader from 'components/ui/loader';

const RoutePlannerPopup = ({node, address}) => {
  const loader = <Loader color="red" align="bottom"/>
  return (
    <div className="route-info-popup">
      <table>
        <tbody>
          <tr><td className="route-info-popup__address">Адрес:</td><td>{address ? address : loader}</td></tr>
          <tr><td>Координаты точки</td><td>{node.coords[0].toFixed(6)}, {node.coords[1].toFixed(6)}</td></tr>
        </tbody>
      </table>
    </div>
  );
}

export default RoutePlannerPopup;