import React, { Component } from "react";
import GlobalLoaderComponent from "components/ui/global-loader";
import _ from "lodash";

import "./index.less"

export default class DetalizationTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            table_sort_column: 'unit_name',
            table_sort_direction: 'asc',
        };
    }

    componentDidMount() {
        if (!this.props.data) {
            setTimeout(() => {
                this.setState({
                    isLoading: false
                })
            }, 3500);
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    onSortChange(field) {
        if (field === this.state.table_sort_column) {
            this.setState({
                table_sort_direction: (this.state.table_sort_direction === 'asc') ? 'desc' : 'asc',
            });
        } else {
            this.setState({
                table_sort_column: field,
                table_sort_direction: 'asc',
            });
        }
    }

    getRows() {
        return _.orderBy(this.props.data, [this.state.table_sort_column], [this.state.table_sort_direction]);
    }

    render() {
        const loader = !this.props.data && this.state.isLoading
            ? <GlobalLoaderComponent block={false} style={{zIndex: 99999}}/>
            : '';

        return <div className="table-wrap dropdown-detail">
            {loader ? loader : <table className="table">
                <thead>
                <tr>
                    <th className="cursor" rowSpan="2" onClick={this.onSortChange.bind(this, 'route_name')}>
                        Маршруты
                        {(this.state.table_sort_column === 'route_name') ? (
                            (this.state.table_sort_direction === 'asc') ? (
                                <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                </svg>
                            ) : (
                                <svg className="icon icon_long-arrow icon_red">
                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                </svg>
                            )
                        ) : null}
                    </th>
                    <th colSpan="2" style={{textAlign: "center"}}>Отклонения</th>
                    <th className="cursor" rowSpan="2" onClick={this.onSortChange.bind(this, 'unit_name')}>
                        Предприятия
                        {(this.state.table_sort_column === 'unit_name') ? (
                            (this.state.table_sort_direction === 'asc') ? (
                                <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                </svg>
                            ) : (
                                <svg className="icon icon_long-arrow icon_red">
                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                </svg>
                            )
                        ) : null}
                    </th>
                </tr>
                <tr>
                    <th className="cursor" onClick={this.onSortChange.bind(this, 'deviation.schedule')}>от расписаний
                        {(this.state.table_sort_column === 'deviation.schedule') ? (
                            (this.state.table_sort_direction === 'asc') ? (
                                <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                </svg>
                            ) : (
                                <svg className="icon icon_long-arrow icon_red">
                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                </svg>
                            )
                        ) : null}
                    </th>
                    <th className="cursor" onClick={this.onSortChange.bind(this, 'deviation.route')}>от маршрутов
                        {(this.state.table_sort_column === 'deviation.route') ? (
                            (this.state.table_sort_direction === 'asc') ? (
                                <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                </svg>
                            ) : (
                                <svg className="icon icon_long-arrow icon_red">
                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                </svg>
                            )
                        ) : null}
                    </th>
                </tr>
                </thead>
                <tbody>
                    {this.getRows().map((item, index) => {
                        return (
                            <tr key={item.unit_name + index}>
                            <td>{item.route_name}</td>
                            <td>{item.deviation.schedule}</td>
                            <td>{item.deviation.route}</td>
                            <td>{item.unit_name}</td>
                            </tr>
                        )
                    })}

                    {this.props.data && this.props.data.length === 0 || !this.props.data ? <p className="no-data">Нет данных</p> : null}
                </tbody>
            </table>}
        </div>
    }
}