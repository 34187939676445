import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getStos = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.getStos(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getSto = (uuid) => async (dispatch) => {
    try {
        const response = await api.commdept.getSto(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createSto = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.createSto(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateSto = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.updateSto(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteSto = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.deleteSto(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
