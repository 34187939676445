import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getRouteVariantNullRuns = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getRouteVariantNullRuns(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRouteVariantNullRun = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getRouteVariantNullRun(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createRouteVariantNullRun = (data) => async (dispatch) => {
    try {
        const response = await api.geo.createRouteVariantNullRun(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateRouteVariantNullRun = (data) => async (dispatch) => {
    try {
        const response = await api.geo.updateRouteVariantNullRun(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteRouteVariantNullRun = (data) => async (dispatch) => {
    try {
        const response = await api.geo.deleteRouteVariantNullRun(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
