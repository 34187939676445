import React from 'react';
import TableContainer from 'components/ui/Table/Container/TableContainer';

const columnsHeaders = [
	{
		title: 'Последний рейс'
	},
	{
		title: 'Первый рейс'
	},
	{
		title: 'Расст. (км)'
	},
	{
		title: 'Время'
	},
	{
		title: 'Наименование остановочного пункта',
		size: 4
	},
	{
		title: 'Время'
	},
	{
		title: 'Расст. (км)'
	},
	{
		title: 'Первый рейс'
	},
	{
		title: 'Последний рейс'
	}
];

const routeDirections = [
	{
		title: 'Прямое направление',
		size: 4
	},
	{
		title: '',
		size: 1
	},
	{
		title: 'Индекс',
		size: 2
	},
	{
		title: '',
		size: 1
	},
	{
		title: 'Обратное направление',
		size: 4
	}
];
export const IntervalTable = ({ runs, routeNames }) => {
	return (
		<TableContainer>
			<div className="Table">
				<table className="b-table b-table-thead-no-hover">
					<thead>
						<tr className="border-top-bold-2 border-bottom-bold">
							{columnsHeaders.map((column, i) => (
								<th
									key={i}
									className={`${i === 0 ? 'border-left-bold-2' : ''} border-bottom-bold-2  border-right-bold-2`}
									colSpan={`${column.size}`}
								>
									{column.title}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						<tr className="border-bottom-bold-2">
							{routeNames.map((routeName, i) => (
								<td
									className={`${i === 0 ? 'border-left-bold-2' : ''} border-bottom-bold-2  border-right-bold-2`}
									colSpan={`${routeName.size}`}
									key={i}
								>
									{routeName.title}
								</td>
							))}
						</tr>
						<tr className="border-bottom-bold-2 border-right-bold-2 border-left-bold-2">
							{routeDirections.map((routeName, i) => {
								let className = 'border-bottom-bold-2';
								if (i === 0) {
									className = className + ' border-left-bold-2'
								}
								if (i === 1) {
									className = className + ' border-right-bold-2'
								}
								if (i === 2) {
									className = className + ' border-right-bold-2'
								}
								if (i === 4) {
									className = className + ' border-right-bold-2'
								}
								return (
									<td
										className={className}
										colSpan={`${routeName.size}`}
										style={{ fontStyle: i !== 2	 && 'italic' }}
										key={i}
									>
										{routeName.title}
									</td>
								);
							})}
						</tr>
						{runs &&
							runs.map((run, index) => {
								if (index === runs.length - 1) {
									// last row
									return (
										<tr className={`border-bottom-bold-2`} key={index}>
											{run.map((title, i) => {
												let className = 'border-bottom-bold';
												if (i === 0) {
													className = className + ' border-left-bold-2';
												}
												if ( i === 1 || i === 2 || i === 3 ||  i === 7 || i === 8 || i === 6) {
													className = className + ' border-right-bold';
												}
												if (i === 10) {
													className = className + ' border-right-bold-2';
												}
												return (
													<td className={className} colSpan={i === 5 ? '2' : ''} key={i}>
														{title}
													</td>
												);
											})}
										</tr>
									);
								} else {
									return (
										<tr className={`border-bottom-bold`} key={index}>
											{run.map((title, i) => {
												let className = 'border-bottom-bold';
												if (i === 0) {
													className = className + ' border-left-bold-2';
												}
												if (i === 0 || i === 1 || i === 2 || i === 3 || i === 5 ||  i === 7 || i === 8 || i === 9 || i === 10) {
													className = className + ' border-right-bold';
												}
												if (i === 6) {
													className = className + ' border-right-bold-2';
												}
												if (i === 4 || i === 11) {
													className = className + ' border-right-bold-2';
												}
												if (i === 7) {
													className = className + ' border-right-bold';
												}
												return (
													<td
														className={className}
														key={i}
													>
														{title}
													</td>
												);
											})}
										</tr>
									);
								}
							})}
					</tbody>
				</table>
			</div>
		</TableContainer>
	);
};
