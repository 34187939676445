import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import _ from 'lodash';

import Editor from './editor';

import Column from "components/ui/column";
import {deleteVehicle, getVehicleList} from "store/reducers/vehicles/vehicles";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {Link} from "react-router";
import {getUnits} from "store/reducers/organizational_units/units";
import moment from "moment";
import formats from "dictionaries/formats";
import ReactDOMServer from 'react-dom/server';
import {State} from "components/ui/state";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import KursMaintenancesComponent from "components/modules/kurs/maintenances/list";
import LoaderComponent from "components/ui/loader";
import ExportTableButton from "components/ui/Export/ExportTableButton";
import {mapDatatablesRequestToMeta} from "helpers/api";
import {getBnsoList} from "../../../../store/reducers/vehicles/bnso";

let unitsWithParentNames = {};
let unitsWithLessee = {};

@connect(state => ({}), {getVehicleList, deleteVehicle, getEntityNames, getDictionaryList, getUnits, getBnsoList})

export default class VehiclesComponent extends BaseTableWithEditorComponent {

    statuses = {
        active: 'Активен',
        inactive: 'Неактивен',
        break: 'Перерыв',
        dinner: 'Обед',
        coming_off: 'Сошел с рейса',
        scrapped: 'Списано',
        'not-scrapped': 'Не списано',
    };

    exportRowsCount = 100000;

    modelClass = 'App\\Model\\Vehicle';
    modelSource = 'vehicles';

    getTitle() {
        if (this.state.component === 'road') {
            return `${systems[this.state.component]} → Учет дорожной техники`;
        }
        return <span>{systems[this.state.component]} → <Link
            to={`/${this.state.component}/dictionaries`}>Справочники</Link> → ТС</span>;
    }

    getBaseUrl() {
        return `/${this.state.component}/vehicles/vehicles`;
    }

    showAudit(rowData) {
        this.props.router.push(`/system/audit/${rowData.uuid}?class=App\\Model\\Vehicle`)
    }

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            seasons: [],
            component: null,
            gatn_responsives: [],
            communal_municipalities: [],
            omsuFilters: [],
            siogvFilters: [],
            regoperFilters: [],
            siogvUnits: [],
        });
    }

    async componentWillUpdate(props, state) {
        super.componentWillUpdate(props, state);

        if (props.params.component !== state.component) {
            await this.setState({
                component: props.params.component,
            });
            this.initComponent();
            this.reload();
        }
    }

    initComponent() {
        if (this.state.component === 'control') {
            this.loadDictionaries([
                'gatn_responsives',
            ]);
        }
    }

    async componentDidMount() {
        this.forceUpdate();
        this.loadUnits();
        if (window.RNIS_SETTINGS.CITY_MO) {
            await this.loadSeasonsDictionary();
        }
    }

    async loadSeasonsDictionary() {
        const response = await this.props.getDictionaryList('seasons');
        if (response.isOk) {
            this.setState({
                seasons: response.payload.documents
            })
        }
    }

    async getSeasonsDictionary() {
        return this.state.seasons;
    }

    async loadUnits() {
        const response = await this.props.getUnits();
        if (response.isOk) {
            if (window.RNIS_SETTINGS.show_omsu) {
                const namesMatchForSIOGV = _.sortBy(_.filter(_.map(response.payload.items, (unit) => ({
                    value: unit.uuid,
                    label: unit.name,
                })), (item) => item.value), 'label')
                this.setState({
                    siogvUnits: namesMatchForSIOGV
                })
            }
            this.setState({
                units: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getSeasonLabels(seasons) {
        if (seasons.length) {
            const allSeasonsUuid = seasons.map(season => season.season_uuid);
            return this.state.seasons.filter(season => allSeasonsUuid.includes(season.uuid)).map(season => season.name).join(", ")
        }
        return '<code>-</code>'
    }

    getSiogvNames(array_siogv) {
        return array_siogv.map(siogvUuid => {
            const variants = this.state.siogvUnits.filter(item => item.value === siogvUuid);
            return variants.length ? variants[0].label : '—'
        }).join('<br/>')
    }

    getOmsuNames(array_omsu) {
        return array_omsu.map(omsuUuid => {
            const variants = this.state.communal_municipalities[omsuUuid];
            return  variants ? variants.name : '—'
        }).join('<br/>')
    }

    getRegoperNames(array_regoper) {
        return array_regoper.map(regoperUuid => {
            const variants = this.state.siogvUnits.filter(item => item.value === regoperUuid);
            return variants.length ? variants[0].label : '—'
        }).join('<br/>')
    }

    showOmsuSiogv() {
        if (window.RNIS_SETTINGS.show_omsu) {
            return location.pathname.includes('communal/vehicles/vehicles') ? true : false;
        } else {
            return false;
        }
    }

    getColumns() {
        let columns;
        const communal = location.pathname.includes('/communal');
        const kiutr = location.pathname.includes('/kiutr');

        if (this.props.params.component === 'control') {
            columns = [

                new Column('Государственный номер')
                    .fromField('state_number')
                    .withDrawer(item => item.state_number.replace(/([a-z])/g, '<span style="color:red;">$1</span>')),

                ...(window.RNIS_SETTINGS.CITY_NNOVGOROD ? [
                    new Column('Дата подключения ТС')
                        .fromField('date_activation')
                        .withDrawer(item => item.date_activation ? moment(item.date_activation).format('DD.MM.YYYY') : '<code>-не установлено-</code>'),

                    new Column('Дата отключения ТС')
                        .fromField('date_disactivation')
                        .withDrawer(item => item.date_disactivation ? moment(item.date_disactivation).format('DD.MM.YYYY') : '<code>-не установлено-</code>'),
                ] : []),

                new Column('Марка ТС')
                    .fromField('vehicle_mark_uuid')
                    .withDrawer(item => item.vehicle_mark_uuid ? this.state.related.get(item.vehicle_mark_uuid) : '<code>-</code>')
                    .denyColumnFilter(),

                new Column('Модель ТС')
                    .fromField('vehicle_model_uuid')
                    .withDrawer(item => item.vehicle_model_uuid ? this.state.related.get(item.vehicle_model_uuid) : '<code>-</code>')
                    .denyColumnFilter(),

                new Column('Ответственный')
                    .fromField('gatn_responsive_uuid')
                    .withDrawer(item => item.gatn_responsive_uuid ? this.state.related.get(item.gatn_responsive_uuid) : '<code>-</code>')
                    .denyColumnFilter(),

                ...(window.RNIS_SETTINGS.vehicle_editor_subsystem ? [(new Column('Подсистема ТС')
                    .fromField('vehicle_subsystem_uuid')
                    .withDrawer(item => item.vehicle_subsystem_uuid ? this.state.related.get(item.vehicle_subsystem_uuid) : '<code>-не указано-</code>')
                    .withFilter('withSubsystem', async () => {
                        const response = await this.props.getDictionaryList('vehicle_subsystems', {
                            filters: {
                                withComponent: this.props.params.component,
                            },
                        });
                        if (response.isOk) {
                            return response.payload.documents;
                        }
                        return {};
                    }))] : []),

                new Column('Год выпуска')
                    .fromField('release_year'),

                new Column('БНСО')
                    .fromField('bnso.bnso_code')
                    .withDrawer(item => item.current_bnso_uuid ? this.state.related.get(item.current_bnso_uuid) : '<code>-не установлено-</code>'),

                new Column('Подразделение')
                    .fromField('unit_uuid')
                    .withDrawer(item => item.unit_uuid ? this.state.related.get(item.unit_uuid) : '<code>-не указано-</code>')
                    .withFilter('withUnit', async () => {
                        const response = await this.props.getUnits({
                            pagination: {
                                page: 1,
                                limit: 1000,
                            },
                            filters: {
                                withComponent: this.props.params.component,
                            },
                            response_data: [
                                'items/uuid',
                                'items/name',
                            ],
                        });
                        if (response.isOk) {
                            return response.payload.items;
                        }
                        return {};
                    }),

                new Column('Одобрено')
                    .fromField('is_approved')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_approved}/>)),

            ];
        } else if (this.props.params.component === 'children') {
            columns = [

                new Column('Муниципальное образование')
                    .fromField('communal_municipality_uuid')
                    .denyColumnFilter()
                    .withDrawer(item => item.communal_municipality_uuid ? this.state.related.get(item.communal_municipality_uuid) : '<code>-</code>')
                    .withFilter('withMunicipality', async () => {
                        return await this.getDictionary('communal_municipalities');
                    }),

                new Column('Контакты в муниципальном образовании')
                    .fromField('municipality_contact'),

                new Column('Предприятие-владелец')
                    .fromField('unit_uuid')
                    .withDrawer(item => item.unit_uuid ? this.state.related.get(item.unit_uuid) : '<code>-не указано-</code>')
                    .withFilter('withUnit', async () => {
                        const response = await this.props.getUnits({
                            pagination: {
                                page: 1,
                                limit: 1000,
                            },
                            filters: {
                                withComponent: this.props.params.component,
                            },
                            response_data: [
                                'items/uuid',
                                'items/name',
                            ],
                        });
                        if (response.isOk) {
                            return response.payload.items;
                        }
                        return {};
                    }),

                new Column('Контакты предприятия-владельца')
                    .fromField('owner_contact'),

                new Column('Родительское предприятие')
                    .denyOrder()
                    .denyColumnFilter()
                    .withDrawer(item => {
                        return unitsWithParentNames[item.unit_uuid] || '<code>-не указано-</code>';
                    })
                    .withFilter('withParentUnit', async () => {
                        const response = await this.props.getUnits({
                            pagination: {
                                page: 1,
                                limit: 1000,
                            },
                            filters: {
                                withComponent: this.props.params.component,
                            },
                        });
                        if (response.isOk) {
                            return response.payload.items;
                        }
                        return {};
                    }),

                ...(window.RNIS_SETTINGS.CITY_NNOVGOROD ? [
                    new Column('Дата подключения ТС')
                        .fromField('date_activation')
                        .withDrawer(item => item.date_activation ? moment(item.date_activation).format('DD.MM.YYYY') : '<code>-не установлено-</code>'),

                    new Column('Дата отключения ТС')
                        .fromField('date_disactivation')
                        .withDrawer(item => item.date_disactivation ? moment(item.date_disactivation).format('DD.MM.YYYY') : '<code>-не установлено-</code>'),
                ] : []),

                ...(window.RNIS_SETTINGS.vehicle_editor_subsystem ? [(new Column('Подсистема ТС')
                    .fromField('vehicle_subsystem_uuid')
                    .withDrawer(item => item.vehicle_subsystem_uuid ? this.state.related.get(item.vehicle_subsystem_uuid) : '<code>-не указано-</code>')
                    .withFilter('withSubsystem', async () => {
                        const response = await this.props.getDictionaryList('vehicle_subsystems', {
                            filters: {
                                withComponent: this.props.params.component === 'kiutr-control' ? 'kiutr' : this.props.params.component,
                            },
                        });
                        if (response.isOk) {
                            return response.payload.documents;
                        }
                        return {};
                    }))] : []),

                new Column('Марка ТС')
                    .fromField('vehicle_mark_uuid')
                    .withDrawer(item => item.vehicle_mark_uuid ? this.state.related.get(item.vehicle_mark_uuid) : '<code>-</code>')
                    .denyColumnFilter(),

                new Column('Модель ТС')
                    .fromField('vehicle_model_uuid')
                    .withDrawer(item => item.vehicle_model_uuid ? this.state.related.get(item.vehicle_model_uuid) : '<code>-</code>')
                    .denyColumnFilter(),

                new Column('Государственный номер ТС')
                    .fromField('state_number'),

                new Column('Год выпуска')
                    .fromField('release_date')
                    .denyColumnFilter()
                    .withDrawer(item => item.release_date && moment(item.release_date).format('YYYY')),

                new Column('Вид перевозок')
                    .fromField('transportation_type_uuid')
                    .withDrawer(item => item.transportation_type_uuid ? this.state.related.get(item.transportation_type_uuid) : '<code>-</code>')
                    .denyColumnFilter(),

                ...(window.RNIS_SETTINGS.show_column_bnso_with_filter_ts_dictionary ? [(new Column('ID БНСО')
                    .fromField('current_bnso.bnso_code')
                    .withAsyncFilter('withBnso', async (search) => {
                        const response = await this.props.getBnsoList({
                            search,
                            order: {
                                column: 'bnso_code',
                                direction: 'asc',
                            },
                            pagination: {
                                page: 1,
                                limit: 50,
                            },
                        });
                        if (response.isOk) {
                            return _.map(response.payload.items, item => ({
                                uuid: item.uuid,
                                name: item.bnso_code,
                            }));
                        }
                        return [];
                    }))] : [
                    (new Column('ID БНСО')
                        .fromField('current_bnso.bnso_code')
                        .denyOrder()
                        .denyColumnFilter())
                ]),

                new Column('Наименование БНСО')
                    .fromField('current_bnso.bnso_type')
                    .denyOrder()
                    .denyColumnFilter()
                    .withDrawer(item => _.get(item, 'current_bnso.bnso_type') ? this.state.related.get(item.current_bnso.bnso_type) : '<code>-</code>'),

                new Column('Номер SIM карты БНСО')
                    .fromField('current_bnso.phone_number')
                    .denyOrder()
                    .denyColumnFilter(),

                new Column('IMEI БНСО')
                    .fromField('current_bnso.sim_card_number')
                    .denyOrder()
                    .denyColumnFilter(),

                new Column('Дополнительное оборудование')
                    .fromField('additional_equipment'),

                new Column('Дата установки БНСО')
                    .fromField('bnso_installation_date')
                    .denyColumnFilter()
                    .withDrawer(item => item.bnso_installation_date && moment(item.bnso_installation_date).format(formats.DATE)),

                new Column('Параметры отправки данных')
                    .fromField('data_send_parameters'),

                new Column('Протокол передачи данных')
                    .fromField('data_send_protocol'),

                new Column('Поддержка EGTS')
                    .fromField('data_egts'),

                new Column('Соответствие 285 Приказу Минтранса от 31 июля 2012 г.')
                    .fromField('data_285'),

                new Column('Наименование организации, обслуживающей навигационное оборудование, установленное на ТС')
                    .fromField('bnso_unit'),

                new Column('Одобрено')
                    .fromField('is_approved')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_approved}/>)),

            ];
        } else if (this.props.params.component === 'garbage') {
            columns = [
                new Column('Государственный номер')
                    .fromField('state_number')
                    .withDrawer(item => item.state_number.replace(/([a-z])/g, '<span style="color:red;">$1</span>')),

                ...(window.RNIS_SETTINGS.show_omsu ? [new Column('ОМСУ')
                    .fromField('unit.unit_municipals')
                    .withDrawer(item => {
                        if (item.unit && item.unit.unit_municipals) {
                            return item.unit.unit_municipals.length ? this.getOmsuNames(item.unit.unit_municipals) : '<code>-</code>'
                        } else {
                            return '<code>-</code>'
                        }
                    })
                    .withFilter('withOMSU', () => {
                        return this.state.omsuFilters.length ? this.state.omsuFilters.map(uuid => {
                            return { name: this.state.communal_municipalities[uuid] ? this.state.communal_municipalities[uuid].name : uuid, uuid: uuid }
                        }) : {};
                    })] : []),

                ...(window.RNIS_SETTINGS.CITY_NNOVGOROD ? [
                    new Column('Дата подключения ТС')
                        .fromField('date_activation')
                        .withDrawer(item => item.date_activation ? moment(item.date_activation).format('DD.MM.YYYY') : '<code>-не установлено-</code>'),

                    new Column('Дата отключения ТС')
                        .fromField('date_disactivation')
                        .withDrawer(item => item.date_disactivation ? moment(item.date_disactivation).format('DD.MM.YYYY') : '<code>-не установлено-</code>'),
                ] : []),

                ...(window.RNIS_SETTINGS.show_omsu ? [new Column('ЦИОГВ')
                    .fromField('unit.unit_siogv')
                    .withDrawer(item => {
                        if (item.unit && item.unit.unit_siogv) {
                            return item.unit.unit_siogv.length ? this.getSiogvNames(item.unit.unit_siogv) : '<code>-</code>';
                        } else {
                            return '<code>-</code>';
                        }
                    })
                    .withFilter('withSIOGV', () => {
                        return this.state.siogvFilters.length ? this.state.siogvFilters.map(uuid => {
                            const variants = this.state.siogvUnits.filter(item => item.value === uuid);
                            const name = variants.length ? variants[0].label : uuid;
                            return { name, uuid: uuid }
                        }) : {};
                    })] : []),

                ...(window.RNIS_SETTINGS.show_omsu ? [new Column('Рег.оператор')
                    .fromField('unit.unit_regoper')
                    .withDrawer(item => {
                        if (item.unit && item.unit.unit_regoper) {
                            return item.unit.unit_regoper.length ? this.getRegoperNames(item.unit.unit_regoper) : '<code>-</code>'
                        } else {
                            return '<code>-</code>'
                        }
                    })
                    .withFilter('withRegOper', () => {
                        return this.state.regoperFilters.length ? this.state.regoperFilters.map(uuid => {
                            const variants = this.state.siogvUnits.filter(item => item.value === uuid);
                            const name = variants.length ? variants[0].label : uuid;
                            return { name, uuid: uuid }
                        }) : {};
                    })] : []),

                new Column('Гаражный номер')
                    .fromField('garage_number'),

                new Column('Текущий БНСО')
                    .fromField('bnso.bnso_code')
                    .withDrawer(item => item.current_bnso_uuid ? this.state.related.get(item.current_bnso_uuid) : '<code>-не установлено-</code>'),

                new Column('Емкость ТС')
                    .fromField('vehicle_capacity_uuid')
                    .withDrawer(item => item.vehicle_capacity_uuid ? this.state.related.get(item.vehicle_capacity_uuid) : '<code>-не указано-</code>'),

                new Column('Предприятие-владелец')
                    .fromField('unit_uuid')
                    .withDrawer(item => item.unit_uuid ? this.state.related.get(item.unit_uuid) : '<code>-не указано-</code>')
                    .withFilter('withUnit', async () => {
                        const response = await this.props.getUnits({
                            pagination: {
                                page: 1,
                                limit: 1000,
                            },
                            filters: {
                                withComponent: this.props.params.component,
                            },
                            response_data: [
                                'items/uuid',
                                'items/name',
                            ],
                        });
                        if (response.isOk) {
                            return response.payload.items;
                        }
                        return {};
                    }),

                new Column('Родительское предприятие')
                    .denyOrder()
                    .denyColumnFilter()
                    .withDrawer(item => {
                        return unitsWithParentNames[item.unit_uuid] || '<code>-не указано-</code>';
                    }),

                new Column('Год выпуска')
                    .fromField('release_year'),

                new Column('Одобрено')
                    .fromField('is_approved')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_approved}/>)),

            ];
        } else {
            columns = [

                new Column('Государственный номер')
                    .fromField('state_number')
                    .withDrawer(item => item.state_number.replace(/([a-z])/g, '<span style="color:red;">$1</span>')),

                ...(this.showOmsuSiogv() ? [new Column('ОМСУ')
                    .fromField('unit.unit_municipals')
                    .withDrawer(item => {
                        if (item.unit && item.unit.unit_municipals) {
                            return item.unit.unit_municipals.length ? this.getOmsuNames(item.unit.unit_municipals) : '<code>-</code>'
                        } else {
                            return '<code>-</code>'
                        }
                    })
                    .withFilter('withOMSU', () => {
                        return this.state.omsuFilters.length ? this.state.omsuFilters.map(uuid => {
                            return { name: this.state.communal_municipalities[uuid] ? this.state.communal_municipalities[uuid].name : uuid, uuid: uuid }
                        }) : {};
                    })] : []),

                ...(window.RNIS_SETTINGS.CITY_NNOVGOROD ? [
                    new Column('Дата подключения ТС')
                        .fromField('date_activation')
                        .withDrawer(item => item.date_activation ? moment(item.date_activation).format('DD.MM.YYYY') : '<code>-не установлено-</code>'),

                    new Column('Дата отключения ТС')
                        .fromField('date_disactivation')
                        .withDrawer(item => item.date_disactivation ? moment(item.date_disactivation).format('DD.MM.YYYY') : '<code>-не установлено-</code>'),
                ] : []),

                ...(this.showOmsuSiogv() ? [new Column('ЦИОГВ')
                    .fromField('unit.unit_siogv')
                    .withDrawer(item => {
                        if (item.unit && item.unit.unit_siogv) {
                            return item.unit.unit_siogv.length ? this.getSiogvNames(item.unit.unit_siogv) : '<code>-</code>';
                        } else {
                            return '<code>-</code>';
                        }
                    })
                    .withFilter('withSIOGV', () => {
                        return this.state.siogvFilters.length ? this.state.siogvFilters.map(uuid => {
                            const variants = this.state.siogvUnits.filter(item => item.value === uuid);
                            const name = variants.length ? variants[0].label : uuid;
                            return { name, uuid: uuid }
                        }) : {};
                    })] : []),

                new Column('Гаражный номер')
                    .fromField('garage_number'),

                new Column('Текущий БНСО')
                    .fromField('bnso.bnso_code')
                    .withDrawer(item => item.current_bnso_uuid ? this.state.related.get(item.current_bnso_uuid) : '<code>-не установлено-</code>'),

                ...(window.RNIS_SETTINGS.vehicle_editor_subsystem ? [(new Column('Подсистема ТС')
                    .fromField('vehicle_subsystem_uuid')
                    .withDrawer(item => item.vehicle_subsystem_uuid ? this.state.related.get(item.vehicle_subsystem_uuid) : '<code>-не указано-</code>')
                    .withFilter('withSubsystem', async () => {
                        const response = await this.props.getDictionaryList('vehicle_subsystems', {
                            filters: {
                                withComponent: this.props.params.component === 'kiutr-control' ? 'kiutr' : this.props.params.component,
                            },
                        });
                        if (response.isOk) {
                            return response.payload.documents;
                        }
                        return {};
                    }))] : []),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [new Column('Сезонность')
                    .fromField('season')
                    .withDrawer(item => this.getSeasonLabels(item.season))
                    .withFilter('withSeasons', async () => { return await this.getSeasonsDictionary() } )] : [new Column('Класс транспортного средства')
                    .fromField('capacity_type_uuid')
                    .withDrawer(item => item.capacity_type_uuid ? this.state.related.get(item.capacity_type_uuid) : '<code>-не указано-</code>')
                    .withFilter('withCapacityType', async () => {
                        const response = await this.props.getDictionaryList('vehicle_capacity_types');
                        if (response.isOk) {
                            return response.payload.documents;
                        }
                        return {};
                    })]),



                new Column('Тип ТС')
                    .fromField('vehicle_type_uuid')
                    .withDrawer(item => item.vehicle_type_uuid ? this.state.related.get(item.vehicle_type_uuid) : '<code>-не указано-</code>')
                    .withFilter('withVehicleType', async () => {
                        const response = await this.props.getDictionaryList('vehicle_types', {
                            filters: {
                                withComponent: this.props.params.component,
                            },
                        });
                        if (response.isOk) {
                            return response.payload.documents;
                        }
                        return {};
                    }),

                new Column('Предприятие-владелец')
                    .fromField('unit_uuid')
                    .withDrawer(item => item.unit_uuid ? this.state.related.get(item.unit_uuid) : '<code>-не указано-</code>')
                    .withFilter('withUnit', async () => {
                        const response = await this.props.getUnits({
                            pagination: {
                                page: 1,
                                limit: 1000,
                            },
                            filters: {
                                withComponent: this.props.params.component,
                            },
                            response_data: [
                                'items/uuid',
                                'items/name'
                            ],
                        });
                        if (response.isOk) {
                            return response.payload.items;
                        }
                        return {};
                    }),

                new Column('Предприятие-арендатор')
                    .fromField('lessee_company_uuid')
                    .withDrawer(item => {
                        return unitsWithLessee[item.lessee_company_uuid] || '<code>-не указано-</code>';
                    })
                    .withFilter('withLessee', async () => {
                        const response = await this.props.getUnits({
                            pagination: {
                                page: 1,
                                limit: 10000,
                            },
                            filters: {
                                withComponent: this.props.params.component,
                            },
                            response_data: [
                                'items/uuid',
                                'items/name',
                            ],
                        });
                        if (response.isOk) {
                            return response.payload.items;
                        }
                        return {};
                    }),

                new Column('Родительское предприятие')
                    .denyOrder()
                    .denyColumnFilter()
                    .withDrawer(item => {
                        return unitsWithParentNames[item.unit_uuid] || '<code>-не указано-</code>';
                    }),

                new Column('Статус')
                    .fromField('status')
                    .withDrawer(item => item.status && this.statuses[item.status])
                    .withFilter('withStatus', () => {
                        return _.map(this.statuses, (title, key) => ({
                            uuid: key,
                            name: title,
                        }));
                    }),

                new Column('Год выпуска')
                    .fromField('release_year'),

                new Column('Одобрено')
                    .fromField('is_approved')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_approved}/>)),

                new Column('Время простоя, ч')
                    .fromField('idle_time'),

                ...(window.RNIS_SETTINGS.CITY_MO ? [(new Column('Марка ТС')
                    .fromField('vehicle_mark_uuid')
                    .withDrawer(item => item.vehicle_mark_uuid ? this.state.related.get(item.vehicle_mark_uuid) : '<code>-</code>')
                    .denyColumnFilter())] : []),

                ...(window.RNIS_SETTINGS.CITY_MO ? [(new Column('Модель ТС')
                    .fromField('vehicle_model_uuid')
                    .withDrawer(item => item.vehicle_model_uuid ? this.state.related.get(item.vehicle_model_uuid) : '<code>-</code>')
                    .denyColumnFilter())] : []),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Вместимость')
                    .fromField('capacity')
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Мест для сидения')
                    .fromField('seating_capacity')
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Экологический класс ')
                        .fromField('environmental_class_uuid')
                        .withDrawer(item => item.environmental_class_uuid ? this.state.related.get(item.environmental_class_uuid) : '<code>-</code>')
                        .denyColumnFilter()
                )]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Низкий уровень пола')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.is_low_floor_level}/>))
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Климатическая установка')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.is_air_conditioning_installation}/>))
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Электронное табло')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.is_electronic_scoreboard}/>))
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Видеонаблюдение')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.is_audio_video_fixation}/>))
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Количество камер видеонаблюдения')
                    .fromField('number_of_cctv_cameras')
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Безналичная оплата')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.is_cashless_payment}/>))
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Система мониторинга пассажиропотока')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.is_passenger_monitoring_system}/>))
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Оснащено для перевозки инвалидов')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.is_for_disabled}/>))
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Аудиоинформатор')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.audioinformer}/>))
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Ремни безопасности пассажиров')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.seat_belts_for_passengers}/>))
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Монитор информирования')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.monitor_for_information}/>))
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Стационарный валидатор')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.stationary_validator}/>))
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO ? [(new Column('Тахограф')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.tachometer}/>))
                    .denyColumnFilter())] : []),

                ...(window.RNIS_SETTINGS.CITY_MO && communal ? [] : [(new Column('Брендирование')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.branded}/>))
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && (communal || kiutr) ? [] : [(new Column('Багажный отсек')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.luggage_compartment}/>))
                    .denyColumnFilter())]),

                ...(window.RNIS_SETTINGS.CITY_MO && (communal || kiutr) ? [] : [(new Column('Турникет')
                    .withAlign('center')
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                        positive={item.turnstile}/>))
                    .denyColumnFilter())]),
            ]
        }

        return this.prepareColumns(columns);
    }

    async deleteItem(data) {
        return await this.props.deleteVehicle(data);
    }

    async collectOMSUandSiogvFilters(items) {
        const allOmsu = items.reduce((acc, value) => {
            if (value.unit && value.unit.unit_municipals && !acc.includes(value.unit.unit_municipals[0])) {
                acc = [...acc, ...value.unit.unit_municipals];
                return acc
            }
            return acc
        }, []);
        const allSiogv = items.reduce((acc, value) => {
            if (value.unit && value.unit.unit_siogv && !acc.includes(value.unit.unit_siogv[0])) {
                acc = [...acc, ...value.unit.unit_siogv];
                return acc
            }
            return acc
        }, []);
        const allRegOper = items.reduce((acc, value) => {
            if (value.unit && value.unit.unit_regoper && !acc.includes(value.unit.unit_regoper[0])) {
                acc = [...acc, ...value.unit.unit_regoper];
                return acc
            }
            return acc
        }, []);
        await this.setState({
            omsuFilters: [...new Set(allOmsu)],
            siogvFilters: [...new Set(allSiogv)],
            regoperFilters: [...new Set(allRegOper)],
        })
    }

    async loadData(meta) {
        meta.filters.withComponent = this.state.component;
        meta.response_data = [
            'items/uuid',
            'items/deleted_at',
            'items/state_number',
            'items/vehicle_mark_uuid',
            'items/vehicle_model_uuid',
            'items/gatn_responsive_uuid',
            'items/release_year',
            'items/current_bnso_uuid',
            'items/is_approved',
            'items/communal_municipality_uuid',
            'items/municipality_contact',
            'items/unit_uuid',
            'items/owner_contact',
            'items/release_date',
            'items/transportation_type_uuid',
            'items/current_bnso/bnso_code',
            'items/current_bnso/bnso_type',
            'items/current_bnso/phone_number',
            'items/current_bnso/sim_card_number',
            'items/additional_equipment',
            'items/bnso_installation_date',
            'items/vehicle_subsystem_uuid',
            'items/data_send_parameters',
            'items/data_send_protocol',
            'items/data_egts',
            'items/data_285',
            'items/bnso_unit',
            'items/garage_number',
            'items/vehicle_capacity_uuid',
            'items/capacity_type_uuid',
            'items/vehicle_type_uuid',
            'items/status',
            'items/idle_time',
            'items/lessee_company_uuid',
            'items/is_low_floor_level',
            'items/capacity',
            'items/seating_capacity',
            'items/environmental_class_uuid',
            'items/is_air_conditioning_installation',
            'items/monitor_for_information',
            'items/is_audio_video_fixation',
            'items/number_of_cctv_cameras',
            'items/is_cashless_payment',
            'items/is_passenger_monitoring_system',
            'items/is_for_disabled',
            'items/audioinformer',
            'items/seat_belts_for_passengers',
            'items/monitor_for_information',
            'items/stationary_validator',
            'items/tachometer',
            'items/branded',
            'items/luggage_compartment',
            'items/turnstile',
            'items/is_electronic_scoreboard',
            'items/season',
            'items/unit',
            'items/unit_municipals',
            'items/unit_siogv',
            'items/date_activation',
            'items/date_disactivation'
        ];
        if (location.pathname.includes("/garbage/vehicles/vehicles")) {
            meta.response_data.push('items/unit_regoper')
        }

        const response = await this.props.getVehicleList(meta);

        if (response.isOk) {
            /* const lesseeUnits = response.payload.items.filter((item) => {
                 console.log(item);
                 return item.lessee_company_uuid;
             });*/
            let isLocation;
            if (window.RNIS_SETTINGS.show_omsu) {
               isLocation = location.pathname.includes('communal/vehicles/vehicles')
                || location.pathname.includes('garbage/vehicles/vehicles') ? true : false;
            } else {
                isLocation = false;
            }
            if (isLocation && response.payload.items.length && !this.state.omsuFilters.length) {
                await this.collectOMSUandSiogvFilters(response.payload.items)
            }

            const lesseeUnits = response.payload.items.reduce((acc, item) => ({
                ...acc,
                [item.lessee_company_uuid]: item.lessee_company_uuid
            }), {})

            const lesseeUnitsData = await this.props.getUnits({
                pagination: {
                    page: 1,
                    limit: 1000,
                },
                filters: {
                    withComponent: this.props.params.component,
                    withUuid: _.map(response.payload.items, 'lessee_company_uuid')
                },
            });
            if (lesseeUnitsData.isOk) {
                const parentsNames = lesseeUnitsData.payload.items.reduce((acc, item) => ({
                    ...acc,
                    [item.uuid]: item.name
                }), {})
                unitsWithLessee = _.mapValues(lesseeUnits, (value) => parentsNames[value]);
            }
            //return data;
        } else {
            response.showErrors();
            return null;
        }
        return response;
    }

    async loadRelatedEntities(json, drawCallback) {
        if (!this.state.communal_municipalities.length) {
            const communal_municipalities = await this.getDictionary('communal_municipalities');
            this.setState({
                communal_municipalities: communal_municipalities
            })
        }


        const result = json.data;
        const unitsData = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withComponent: this.props.params.component,
                withUuid: _.map(result, 'unit_uuid')
            },
        });

        if (unitsData.isOk) {
            const parentUnits = unitsData.payload.items.reduce((acc, item) => ({
                ...acc,
                [item.uuid]: item.parent_unit_uuid
            }), {})
            const parentUnitsData = await this.props.getUnits({
                pagination: {
                    page: 1,
                    limit: 1000,
                },
                filters: {
                    withComponent: this.props.params.component,
                    withUuid: _.map(unitsData.payload.items, 'parent_unit_uuid')
                },
            });
            if (parentUnitsData.isOk) {
                const parentsNames = parentUnitsData.payload.items.reduce((acc, item) => ({
                    ...acc,
                    [item.uuid]: item.name
                }), {})
                unitsWithParentNames = _.mapValues(parentUnits, (value) => parentsNames[value]);
            }
        }

        const units = _.map(_.filter(_.map(result, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));
        const bnso = _.map(_.filter(_.map(result, 'current_bnso_uuid')), (uuid) => ({
            class: 'App\\Model\\Bnso',
            uuid: uuid,
            source: 'vehicles',
        }));
        const capacities = _.map(_.filter(_.map(result, 'capacity_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleCapacityType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const capacities2 = _.map(_.filter(_.map(result, 'vehicle_capacity_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleCapacity\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const types = _.map(_.filter(_.map(result, 'vehicle_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const marks = _.map(_.filter(_.map(result, 'vehicle_mark_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleMark\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const subsystems = _.map(_.filter(_.map(result, 'vehicle_subsystem_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleSubsystems\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const municipalities = _.map(_.filter(_.map(result, 'communal_municipality_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Communal\\CommunalMunicipalities\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const environmentalClasses = _.map(_.uniq(_.filter(_.map(result, 'environmental_class_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleEnvironmentalClass\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const transportationTypes = _.map(_.filter(_.map(result, 'transportation_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\TransportationType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const bnsoTypes = _.map(_.filter(_.map(result, 'current_bnso.bnso_type')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\BnsoType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const models = _.map(_.filter(_.map(result, 'vehicle_model_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleModel\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const responsives = _.map(_.filter(_.map(result, 'gatn_responsive_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Gatn\\GatnResponsives\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const responsiveUnits = _.map(_.uniq(_.filter(_.map(result, (item) => {
            return _.get(this.state.gatn_responsives, `${item.gatn_responsive_uuid}.unit_uuid`);
        }))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(_.concat(units, bnso, capacities, capacities2, types, marks, models, responsives, responsiveUnits, municipalities, transportationTypes, bnsoTypes, subsystems, environmentalClasses));
        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="kurs-vehicles.maintenance" code="kurs-vehicles.maintenance"
                            default="График прохождения ТО">
                <IconButton icon="pass-schedule" onClick={::this.showMaintenanceGraphic}/>
            </ContextTooltip>,
            <ContextTooltip key="base-table-list.delete" code="base-table-list.delete">
                <IconButton icon="basket" disabled={this.state.selectedRowsCount !== 1}
                            onClick={::this.deleteSelected}/>
            </ContextTooltip>,
        ];
    }

    showMaintenanceGraphic() {
        this.setState({
            maintenanceGraphicActive: true,
        });
    }

    hideMaintenanceGraphic() {
        this.setState({
            maintenanceGraphicActive: false,
        });
    }

    renderModals() {
        if (this.state.maintenanceGraphicActive) {
            return (
                <KursMaintenancesComponent
                    component={this.props.params.component}
                    onClose={::this.hideMaintenanceGraphic}
                />
            );
        }
    }

    exportToXlsButton() {
        if (this.refs.export && this.refs.export.getWrappedInstance().isLoading()) {
            return (
                <LoaderComponent color="red"/>
            );
        }

        return (
            <div>
                <ContextTooltip key="base-table-list.export" code="base-table-list.export" default="Экспорт в Excel">
                    <IconButton icon="export" onClick={::this.exportToXlsReal}/>
                </ContextTooltip>
                {
                    window.RNIS_SETTINGS.CITY_MO ? (
                        <ContextTooltip key="base-table-list.export.extended" code="base-table-list.export" default="Экспорт в Excel (расширенный)">
                            <IconButton icon="export" onClick={this.exportToXlsReal.bind(this, 'vehicle_extended')}/>
                        </ContextTooltip>
                    ) : null
                }
            </div>
        );
    }

    renderHeaderContents() {
        return _.concat(super.renderHeaderContents(), [
            <ExportTableButton
                key="export-log"
                ref="export"
                source="vehicles"
                entity="vehicles"
                forceUpdate={::this.forceUpdate}
            />,
            //...(window.RNIS_SETTINGS.CITY_MO ? [
                /*<ExportTableButton
                    key="export-log"
                    ref="export"
                    source="vehicles"
                    entity="vehicle_extended"
                    forceUpdate={::this.forceUpdate}
                />*/
           // ] : []),
        ]);
    }

    async exportToXlsReal(page) {
        //debugger
        if (!this.refs.export) {
            return;
        }
        let meta = mapDatatablesRequestToMeta(this.lastRequest, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        _.set(meta, 'filters.withComponent', this.state.component);
        _.set(meta, 'pagination', {
            page: 1,
            limit: this.exportRowsCount,
        });
        this.refs.export.getWrappedInstance().exportToXls(meta, page);
    }
}
