import React, {Component} from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapLayer from "components/ui/map/base/layer";
import {connect} from "react-redux";
import {getStopPointsWithBounds} from "store/reducers/routes/routes";
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import RailroadCrossingMarker from "components/ui/map/markers/railrodcrossing-marker";
import StopPointsEditor from "components/modules/geo/stop-points/editor";
import MapModalStopPointRoutes from "components/ui/map/Modal/MapModalStopPointRoutes";
import {getRailroadCrossings} from "../../../../helpers/api/geo";
import {rpc} from "../../../../helpers/api/session";

@propTypes({
    onRailroadCrossClick: PropTypes.func,
    //onStopPointDblClick: PropTypes.func,
    //setSelectedRoutes: PropTypes.func,
})

@defaultProps({
    onRailroadCrossClick: null
})

@connect((state) => ({}), {getStopPointsWithBounds, getRailroadCrossings})

export default class RailroadCrossLayer extends AbstractMapLayer {

    state = {
        stopPoints: [],
        editorUuid: null,
        routesStopPointUuid: null,
        railroadsCrossings: [],
    };

    loadStopPointsCallback = ::this.loadStopPoints;

    componentWillMount() {
        super.componentWillMount();

        //this.props.leafletMap.on('moveend', this.loadStopPointsCallback);

        //this.loadStopPoints();
        //fixme
        if (window.RNIS_SETTINGS.RAILROADCROSSING) {
            this.loadRailRoadsList();
        }
    }

    componentWillUnmount() {

        super.componentWillUnmount();

        this.props.leafletMap.off('moveend', this.loadStopPointsCallback);
    }

    //fixme запрос списка Переездов
    async loadStopPoints() {
        const boundingBox = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getBoundingBox() : this.props.map.getBoundingBox();
        const zoom = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getZoom() : this.props.map.getZoom();
        const response = await this.props.getStopPointsWithBounds(boundingBox, zoom);

        if (response.isOk) {
            this.setState({
                stopPoints: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadRailRoadsList() {
        let meta = {}
        const response = await rpc.request('com.rnis.crossroads.action.list', {}, {meta});
        this.setState({
            stopPoints: response.payload.items,
        });
    }

    onEditClick(uuid) {
        this.setState({
            editorUuid: uuid,
        });
    }

    closeEditor() {
        this.setState({
            editorUuid: null,
        });
    }

    getEditor() {
        return (
            <StopPointsEditor
                mode="edit"
                uuid={this.state.editorUuid}
                onSubmit={::this.closeEditor}
                onClose={::this.closeEditor}
            />
        );
    }

    getStopPointRoutes() {
        return (
            <MapModalStopPointRoutes
                stopPointUuid={this.state.routesStopPointUuid}
                onClose={::this.stopPointRoutesClose}
                setSelectedRoutes={this.props.setSelectedRoutes}
            />
        );
    }

    render() {
        const editor = this.state.editorUuid ? this.getEditor() : null;
        const stopPointRoutes = this.state.routesStopPointUuid ? this.getStopPointRoutes() : null;

        return (
            <div>
                {editor}
                {stopPointRoutes}
                <div style={{display: 'none'}}>
                    {this.state.stopPoints.map(::this.renderRailroadCrossingPoint)}
                </div>
            </div>
        );
    }

    renderRailroadCrossingPoint(stopPoint) {
        return (
            <RailroadCrossingMarker
                key={stopPoint.uuid}
                {...this.props}
                latitude={stopPoint.latitude}
                longitude={stopPoint.longitude}
                item={stopPoint}
                onClick={this.props.onRailroadCrossClick ? this.onStopPointClick.bind(this, stopPoint.uuid) : null}
            />
        );
    }

    onStopPointClick(uuid, e) {
        this.props.onRailroadCrossClick(e, uuid);
    }

    onRoutesClick(uuid) {
        this.setState({
            routesStopPointUuid: uuid,
        });
    }

    stopPointRoutesClose() {
        this.props.setSelectedRoutes([]);
        this.setState({
            routesStopPointUuid: null,
        });
    }

}