import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getRegisterRequests = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.portal.getRegisterRequests(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRegisterEsiaRequests = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.portal.getRegisterEsiaRequests(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateRegisterEsiaRequest = (data) => async (dispatch) => {
    try {
        const response = await api.portal.updateRegisterEsiaRequest(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRegisterRequest = (uuid) => async (dispatch) => {
    try {
        const response = await api.portal.getRegisterRequest(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateRegisterRequest = (data) => async (dispatch) => {
    try {
        const response = await api.portal.updateRegisterRequest(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};