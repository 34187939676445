import React from 'react';
import Alert from 'react-s-alert';
import _ from 'lodash';
import moment from 'moment';
import formats from 'dictionaries/formats';
import { connect } from 'react-redux';
import { getVehicle } from 'store/reducers/vehicles/vehicles';
import ContextTooltip from 'components/ui/context-tooltip';
import Tooltip from 'react-tooltip-component';
import { getUnit } from 'store/reducers/organizational_units/units';
import { api } from 'helpers/api';
import { error } from 'helpers/response';
import './alert-template.less';
import notifications from 'dictionaries/notifications';
import { GlobalEvent } from 'helpers/event-system';
import classNames from 'classnames';
import { RecalculationAlert } from './recalculation-alert';

export default class AlertTemplate extends React.Component {
	state = {
		vehicle: {},
		order: {},
		unit: {}
	};

	levels = {
		normal: 'normal',
		high: 'important',
		critical: 'critical'
	};

	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.loadVehicle();
		this.loadOrder();
		this.loadUnit();
	}

	async loadVehicle() {
		const vehicleUuid = _.get(this.props.customFields.item, 'data.vehicle_uuid');
		if (!vehicleUuid) {
			return;
		}
		try {
			const response = await api.vehicles.getVehicle(vehicleUuid);

			this.setState({
				vehicle: response.payload
			});
		} catch (e) {}
	}

	async loadOrder() {
		const orderUuid = _.get(this.props.customFields.item, 'data.order_uuid');
		if (!orderUuid) {
			return;
		}
		try {
			const response = await api.geo.getOrder(orderUuid);

			this.setState({
				order: response.payload
			});
		} catch (e) {}
	}

	async loadUnit() {
		const unitUuid = _.get(this.props.customFields.item, 'data.unit_uuid');
		if (!unitUuid) {
			return;
		}
		try {
			const response = await api.organizational_units.getUnit(unitUuid);

			this.setState({
				unit: response.payload
			});
		} catch (e) {}
	}

	gotoMap() {
		const to =  moment( this.state.unit.created_at).add(10, "minutes").format()
		const from = moment( this.state.unit.created_at).subtract(10, "minutes").format()
		GlobalEvent().call(
			'goto',
			`/${this.state.vehicle.component}/map?vehicles=${this.props.customFields.item.data.vehicle_uuid}&from_time=${from}&to_time=${to}`
		);
	}

	getOnClick(eventType) {
		if (eventType === 'op_control') {
			const routeRegistryUuid = _.get(this.props.customFields.item.data, 'custom_data.route_registry_uuid');
			return () => {
				window.location.href =
					'/kiutr/transport-work/' +
					routeRegistryUuid +
					'?date=' +
					moment(this.props.customFields.item.data.created_at).format(formats.DATE);
			};
		}

		return () => {};
	}

	render() {
		const item = this.props.customFields.item;
		const vehicleModel = this.state.vehicle
			? _.get(_.find(this.props.customFields.vehicle_models, { value: this.state.vehicle.vehicle_model_uuid }), 'label')
			: null;
		const notificationLevel = this.levels[_.get(item, 'data.notification_level')] || 'normal';
		const address = _.get(item, 'data.address');
		const eventType = this.props.customFields.item.data.event_type;
		const onClick = this.getOnClick(eventType);

		if (window.RNIS_SETTINGS.recalculation_notifications) {
			const isRecalculationNotification = this.props.customFields.item && this.props.customFields.item.source === 'geo_calc';

			if (isRecalculationNotification) {
				// notification from recalculations
				return (
					<RecalculationAlert
						className={this.props.classNames}
						id={this.props.id}
						styles={this.props.styles}
						onClick={onClick}
						notificationLevel={notificationLevel}
						item={item}
						onClose={this.props.handleClose}
						message={this.props.message}
					/>
				);
			}
		}
        
		const isOverrideText = _.get(item, 'data.is_override_text');

		if (isOverrideText) {
			return (
				<div className={this.props.classNames} id={this.props.id} style={this.props.styles} onClick={onClick}>
					<div
						className={classNames(
							'alert',
							'alert_level_' + notificationLevel,
							item.reminder ? 'alert_status_reminder' : null
						)}
					>
						<div className="alert__header" onClick={this.props.handleClose}>
							<div className="alert__name">{_.get(item, 'data.event_rule_code')}</div>
							<div className="alert__time">
								{item.reminder ? 'Напоминание ' : ''}
								{moment(item.created_at).format(formats.TIME)}
							</div>
						</div>
						<div className="alert__body">{this.props.message}</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className={this.props.classNames} id={this.props.id} style={this.props.styles} onClick={onClick}>
					<Tooltip title={this.props.message} position="left">
						<div
							className={classNames(
								'alert',
								'alert_level_' + notificationLevel,
								item.reminder ? 'alert_status_reminder' : null
							)}
						>
							<div className="alert__header" onClick={this.props.handleClose}>
								<div className="alert__name">{_.get(item, 'data.event_rule_code')}</div>
								<div className="alert__time">
									{item.reminder ? 'Напоминание ' : ''}
									{moment(item.created_at).format(formats.TIME)}
								</div>
							</div>
							<div className="alert__body">
								{address ? (
									<div className="alert__address">
										<a href="javascript:void(0)" onClick={::this.gotoMap}>
											{address}
										</a>
									</div>
								) : null}
								<div className="alert__info">
									{this.state.vehicle ? (
										<div>
											{vehicleModel}, Гос. номер {this.state.vehicle.state_number}
										</div>
									) : null}
									{this.state.order ? <div>№ {this.state.order.number}</div> : null}
									{_.get(this.state.unit, 'name')}
								</div>
							</div>
						</div>
					</Tooltip>
				</div>
			);
		}
	}
}
