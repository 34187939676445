import {showError} from "helpers/errors";
import * as validation from 'helpers/validation';
import _ from 'lodash';

export class Response {
    constructor(response) {
        this.__response = response
    }

    /**
     * @return {Object}
     */
    get data() {
        return this.__response;
    }

    /**
     * @return {Object}
     */
    get payload() {
        return this.data.payload;
    }

    /**
     * @
     * @param {Object} payload
     */
    set payload(payload) {
        this.data.payload = payload;
    }

    /**
     * @param {string} key
     */
    get(key) {
        return _.get(this.__response, key);
    }

    /**
     * @return {string}
     */
    get status() {
        return 'ok';
    }

    /**
     * @return {boolean}
     */
    get isOk() {
        return true;
    }

    /**
     * @return {boolean}
     */
    get hasErrors() {
        return false;
    }

    showErrors() {
    }
}

class ErrorResponse extends Response {

    constructor(response) {
        super(response);
    }

    /**
     * @return {boolean}
     */
    get isOk() {
        return false;
    }

    /**
     * @return {string}
     */
    get status() {
        return 'error';
    }

    /**
     * @return {boolean}
     */
    get hasErrors() {
        return true;
    }

    /**
     * @return {Array}
     */
    get errors() {
        return this.__response.errors;
    }

    get validationErrors() {
        return validation.getErrors(this)
    }

    /**
     * @param {string} code
     * @return {boolean}
     */
    hasErrorWithCode(code) {
        return _.filter(this.errors, error => error.code === code).length > 0;
    }

    /**
     * Показать ошибки
     */
    showErrors() {
        showError(this)
    }
}

export const success = data => {
    return new Response(data);
};
export const error = error => {
    return new ErrorResponse(error);
};
export const emptyResponseWithItems = () => ({
    isOk: true,
    payload: {
        items: [],
    },
});

let currentRequests = {};

export async function makeResponse(callback, unionKey = null) {
    if (unionKey) {
        if (currentRequests[unionKey]) {
            return new Promise((resolve, reject) => {
                currentRequests[unionKey].then((result) => {
                    resolve(result);
                });
            });
        }
    }
    const promise = new Promise(async (resolve, reject) => {
        try {
            const response = await callback();

            resolve(success(response));
        }
        catch (e) {
            resolve(error(e));
        }
    });
    if (unionKey) {
        currentRequests[unionKey] = promise;
    }

    const result = await promise;

    if (unionKey) {
        currentRequests[unionKey] = null;
    }

    return result;
};

// const success = response => ({status: 'ok', data: response});
// const error = error => ({status: 'error', error: error});