import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';


@propTypes({
    route: PropTypes.object.isRequired,
    variants: PropTypes.array.isRequired,
    stopPoints: PropTypes.object.isRequired,
})

export default class KiutrRouteVariantsPrintComponent extends Component {

    render() {
        return (
            <div>
                <table>
                    <thead>
                    <tr>
                        <th className="no-border">
                            <div className="route-number">
                                Маршрут № {this.props.route.number}
                            </div>
                            <div className="route-name">
                                {this.props.route.title}
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="no-border">
                            {this.props.variants.map(::this.renderVariant)}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderVariant(variant) {
        return (
            <div key={variant.uuid} className="variant">
                <div className="variant-name">
                    Вариант движения: {variant.name}
                </div>
                {(variant.forward_points.length > 0) ? (
                    <div>
                        <div className="variant-direction">
                            <strong>Прямое направление</strong> (общая
                            протяженность: {this.formatDistance(_.sumBy(variant.forward_points, 'distance_to_the_next_point'))}км)
                        </div>
                        {this.renderPoints(variant, 'forward_points')}
                    </div>
                ) : null}
                {(variant.reverse_points.length > 0) ? (
                    <div>
                        <div className="variant-direction">
                            <strong>Обратное направление</strong> (общая
                            протяженность: {this.formatDistance(_.sumBy(variant.reverse_points, 'distance_to_the_next_point'))}км)
                        </div>
                        {this.renderPoints(variant, 'reverse_points')}
                    </div>
                ) : null}
            </div>
        );
    }

    renderPoints(variant, direction) {
        return (
            <table key={`${variant.uuid}:${direction}`}>
                <thead>
                <tr>
                    <th>Остановочные пункты</th>
                    <th>#</th>
                    <th>Расст. между остановками, км</th>
                    <th>Расст. от нач. пункта, км</th>
                    <th>Время движения между ост., мин</th>
                    <th>Время движения от начала, мин</th>
                    <th>Улицы</th>
                </tr>
                </thead>
                <tbody>
                {variant[direction].map(this.renderPoint.bind(this, variant, direction))}
                </tbody>
            </table>
        );
    }

    renderPoint(variant, direction, point, index) {
        if (point.point_type !== 'stop_point') {
            return null;
        }

        const prev = _.filter(variant[direction], (point, _index) => {
            return (point.point_type === 'stop_point') && (_index <= index);
        });

        let streets = point.streets || [];
        if (streets.length >= 3) {
            streets = [
                streets[0],
                '...',
                streets[streets.length - 1],
            ];
        }

        return (
            <tr key={`${variant.uuid}:${direction}:${index}`}>
                <td>{_.get(this.props.stopPoints, point.type_uuid, '-')}</td>
                <td>{prev.length}</td>
                <td>{this.formatDistance(point.distance_to_the_next_point)}</td>
                <td>{this.formatDistance(_.sumBy(prev, item => _.toInteger(item.distance_to_the_next_point)))}</td>
                <td>{point.time_to_get_to_the_next_point}</td>
                <td>{_.sumBy(prev, item => _.toInteger(item.time_to_get_to_the_next_point))}</td>
                <td>{streets.map((street, index) => {
                    return (
                        <div key={index}>{street}</div>
                    );
                })}</td>
            </tr>
        );
    }

    formatDistance(distance) {
        return Math.round((distance / 1000) * 100) / 100;
    }
}