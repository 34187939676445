
export const analiticsRadioButtons = [
    {
        key: 1,
        label: "Пассажирские перевозки",
        value: "passenger",
    },
    {
        key: 2,
        label: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text,
        value: "road",
    },
    {
        key: 3,
        label: "Вывоз мусора",
        value: "garbage",
    },
    {
        key: 4,
        label:"ГУ ГАТН",
        value: "control",
    },
    {
        key: 5,
        label:"Коммунальная техника",
        value: "communal",
    }
];