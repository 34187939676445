import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import Datepicker from "components/ui/form/datepicker";
import moment from "moment";
import formats from "dictionaries/formats";
import {TableColumnFilterBase} from "components/ui/Table/Column/Filter/TableColumnFilterBase";

@propTypes({
    index: PropTypes.number.isRequired,
    column: PropTypes.object.isRequired,
    opened: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onFilterOpened: PropTypes.func
})

@defaultProps({
    opened: false,
    onFilterOpened: () => {
    }
})

export class TableColumnFilterDateSingle extends TableColumnFilterBase {

    constructor(...args) {
        super(...args);

        Object.assign(this.state, {
            value: null,
        });
    }

    componentWillMount() {
        super.componentWillMount();

        if (this.props.column.filterValue) {
            const filterValue = this.props.column.filterValue;
            this.setState({
                value: filterValue,
            });
        }
    }

    async componentDidUpdate() {
        if ($(".top-menu__link.top-menu__link_icon_filter.active").length === 0 && this.state.value.length > 0) {
            this.setState({
                value: null
            })
        }
    }

    hasValue() {
        return this.state.value;
    }

    renderContent() {
        return (
            <div className="filtration__search">
                <Datepicker
                    value={this.state.value}
                    onChange={::this.onDateChange}
                />
            </div>
        );
    }

    onDateChange({target: {value}}) {
        this.setState({value});

        this.props.onChange(this.props.index, value);
    }
}