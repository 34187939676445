import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import moment from "moment";
import formats from "dictionaries/formats";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import _ from 'lodash';
import {Link} from "react-router";
import {deleteContractWork, getContractWorks} from "store/reducers/commdept/contract_works";
import TabBlock from "components/ui/tabs/tab-block";
import Block from "components/ui/form/block";
import TableContainer from "components/ui/Table/Container/TableContainer";

@propTypes({
    contractUuid: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
})

@connect(state => ({}), {getContractWorks, deleteContractWork, getEntityNames})

export default class CommdeptContractWorksComponent extends BaseTableWithEditorComponent {

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            showEditorModal: false,
            editorUuid: null,
        });
    }

    getTitle() {
        return <span>{systems.road} → <Link
            to={`/commdept/contracts/${this.props.contractUuid}`}>Ведение {window.RNIS_SETTINGS.rename_contracts ? 'подрядных обязательств' : 'контрактов'}</Link> → {this.isRepair() ? `Работы по ${window.RNIS_SETTINGS.rename_contracts ? 'подрядному обязательству' : 'контракту'}` : 'Объекты обслуживания'}</span>;
    }

    getBaseUrl() {
        return `/commdept/contracts/${this.props.contractUuid}/${this.props.type}`;
    }

    getEditor() {
        return (
            <Editor
                {...this.props}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    isRepair() {
        return this.props.type === 'works';
    }

    getColumns() {
        if (this.isRepair()) {
            return this.prepareColumns([

                new Column('Участок дороги')
                    .fromField('road_uuid')
                    .denyOrder()
                    .denyColumnFilter()
                    .withClassName('link')
                    .withDrawer(item => item.road_uuid && this.state.related.get(item.road_uuid)),

                new Column('Виды работ')
                    .fromField('work_type_uuid')
                    .denyColumnFilter()
                    .denyOrder()
                    .withDrawer(item => item.works.map(work => work.work_type_uuid && this.state.related.get(work.work_type_uuid)).join('<br/>')),

                new Column('Ед. изм.')
                    .fromField('measure_uuid')
                    .denyColumnFilter()
                    .denyOrder()
                    .withDrawer(item => item.works.map(work => work.measure_uuid && this.state.related.get(work.measure_uuid)).join('<br/>')),

                new Column('Об. работ')
                    .fromField('count')
                    .denyColumnFilter()
                    .denyOrder()
                    .withDrawer(item => item.volume.join('<br/>')),

                new Column('Дата начала')
                    .fromField('date_from')
                    .denyColumnFilter()
                    .denyOrder()
                    .withDrawer(item => item.date_from && moment(item.date_from).format(formats.DATE)),

                new Column('Дата окончания')
                    .fromField('date_to')
                    .denyColumnFilter()
                    .denyOrder()
                    .withDrawer(item => item.date_to && moment(item.date_to).format(formats.DATE)),

                new Column('Нач. км')
                    .fromField('start_at'),

                new Column('Конеч. км')
                    .fromField('end_at'),

                new Column('Прот. участка')
                    .fromField('length'),

                new Column('Субподрядчик')
                    .fromField('unit_uuid')
                    .denyColumnFilter()
                    .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid)),
            ]);
        } else {
            return this.prepareColumns([

                new Column('Объект')
                    .fromField('road_uuid')
                    .denyOrder()
                    .denyColumnFilter()
                    .withClassName('link')
                    .withDrawer(item => item.road_uuid && this.state.related.get(item.road_uuid)),

                new Column('Группа по содержанию')
                    .fromField('maintenance_group_uuid')
                    .denyColumnFilter()
                    .withDrawer(item => item.maintenance_group_uuid && this.state.related.get(item.maintenance_group_uuid)),

                new Column('Нач. км')
                    .fromField('start_at'),

                new Column('Конеч. км')
                    .fromField('end_at'),

                new Column('Прот. участка')
                    .fromField('length'),

                new Column('Субподрядчик')
                    .fromField('unit_uuid')
                    .denyColumnFilter()
                    .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid)),
            ]);
        }
    }

    async deleteItem(data) {
        return await this.props.deleteContractWork(data);
    }

    async loadData(meta) {
        meta.filters = {
            withContract: this.props.contractUuid,
        };
        let response = await this.props.getContractWorks(meta);
        if (response.isOk) {
            response.payload.items = _.map(response.payload.items, (item) => {
                const works = item.works || [];

                item.work_type_uuid = _.map(works, 'work_type_uuid');
                item.measure_uuid = _.map(works, 'measure_uuid');
                item.volume = _.map(works, 'volume');

                return item;
            });
        }
        return response;
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        let roads = [];
        if (this.isRepair()) {
            roads = _.map(_.filter(_.map(result, 'road_uuid')), (uuid) => ({
                class: 'App\\Dictionaries\\Commdept\\CommdeptRoadRepairParts\\Model',
                uuid: uuid,
                source: 'dictionary',
            }));
        } else {
            roads = _.map(_.filter(_.map(result, 'road_uuid')), (uuid) => ({
                class: 'App\\Model\\RoadPart',
                uuid: uuid,
                source: 'commdept',
            }));
        }

        const workTypes = _.map(_.filter(_.map(_.flatten(_.map(result, 'works')), 'work_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Commdept\\WorkTypes\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const measures = _.map(_.filter(_.map(_.flatten(_.map(result, 'works')), 'measure_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Commdept\\Measures\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const units = _.map(_.filter(_.map(result, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const maintenanceGroups = _.map(_.filter(_.map(result, 'maintenance_group_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Commdept\\MaintenanceGroups\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(_.concat(roads, workTypes, measures, units, maintenanceGroups));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    async showEditorWithUuid(uuid = null) {
        this.setState({
            showEditorModal: true,
            editorUuid: uuid,
        });
    }

    async closeEditor() {
        this.setState({
            showEditorModal: false,
            editorUuid: null,
        });
    }

    render() {
        let table = this.renderTable();

        const editor = this.state.showEditorModal ? this.getEditor() : '';

        return (
            <TabBlock>
                <div className="b-block _full">
                    <TableContainer>
                        {table}
                        <span className="add-line" onClick={::this.showEditor}>Добавить запись</span>
                    </TableContainer>
                </div>
                {editor}
            </TabBlock>
        );
    }
}
