import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import Tooltip from 'react-tooltip-component';
import 'react-tooltip-component/lib/tooltip.css';
import {getTooltip, updateTooltip} from "helpers/tooltips";
import * as ReactDOM from "react-dom";
import * as alerts from "helpers/alerts";
import {connect} from "react-redux";
import $ from 'jquery';

@propTypes({
    code: PropTypes.string,
    default: PropTypes.string,
    position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    space: PropTypes.number,
    isEdge: PropTypes.bool,
})

@defaultProps({
    position: 'bottom',
    default: '',
    space: 0,
    isEdge: false,
})

@connect((state) => ({
    active: state.tooltips.get('active'),
}))

export default class ContextTooltip extends Component {

    componentDidMount() {
        if (!this.props.active) {
            return;
        }

        const el = ReactDOM.findDOMNode(this);
        el.addEventListener('contextmenu', (e) => {
            e.preventDefault();

            this.requestUpdateValue();
        });
    }

    render() {
        const title = this.getTitle();

        return (
            <Tooltip title={title || (this.props.active ? '-нет справки-' : this.props.default)}
                     position={this.props.position}
                     space={this.props.space}
                     container={this.getContainer()}>
                {this.props.children}
            </Tooltip>
        );
    }

    getContainer() {
        const {isEdge} = this.props;

        if (!isEdge) {
            return document.body;
        }

        let container = $('.tooltip_edge')[0];
        if (!container) {
            $('<div class="tooltip_edge"/>').appendTo(document.body);
            container = $('.tooltip_edge')[0];
        }

        return container;
    }

    getTitle() {
        return this.props.code ? getTooltip(this.props.code) : null;
    }

    requestUpdateValue() {
        alerts.ask('Содержимое контекстной справки:', ::this.updateValue, this.getTitle());
    }

    async updateValue(value) {
        await updateTooltip(this.props.code, value);

        this.forceUpdate();
    }

}