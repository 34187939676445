import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import * as validation from 'helpers/validation';

import PageModal from 'components/ui/page-modal';
import Button from 'components/ui/button';

import './editor.less';
import {showError} from "helpers/errors";
import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import BaseEditorFormComponent from "components/base/base-editor-form";
import {
    getUserGeoObject,
    createUserGeoObject,
    updateUserGeoObject,
    deleteUserGeoObject
} from "store/reducers/user-map-objects/object_editor";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Block from "components/ui/form/block";
import {getUnits} from "store/reducers/organizational_units/units";
import {getLayer} from "store/reducers/user-map-objects/layer_editor";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import currentUser from 'helpers/current-user';
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ColorPicker from "react-color-picker";
import 'react-color-picker/index.css';

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    geometry: PropTypes.object,
    layerUuid: PropTypes.string.isRequired,
    uuid: PropTypes.string,
    withUnit: PropTypes.bool,
})

@connect(state => ({
    object: state.user_map_objects.object_editor.get('object')
}), {getUserGeoObject, createUserGeoObject, updateUserGeoObject, deleteUserGeoObject, getDictionaryList})

export default class ObjectsEditor extends Component {

    state = {
        uuid: null,
        isLoading: this.props.mode === 'edit',
        saving: false,
        errors: {}
    };

    componentDidMount() {
        if (this.props.uuid) {
            this.loadObject(this.props.uuid);
        }
    }

    render() {
        let title = 'Добавление пользовательского объекта';

        let buttons;
        let form;

        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {
            title = 'Редактирование пользовательского объекта';

            if (!this.state.isLoading) {
                form = <ObjectEditorForm
                    {...this.props}
                    mode={this.props.mode}
                    onSubmit={::this.edit}
                    onDelete={::this.deleteItem}
                    onClose={::this.props.onClose}
                    data={this.props.object}
                    errors={this.state.errors}
                    geometry={this.props.geometry}
                />;

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            {(currentUser.can('com.rnis.system.permission.audit', 'read')) ?
                                (
                                    <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                                    default="Журнал аудита">
                                        <ModalTopMenuListItem
                                            className="b-icon-link_params b-icon-link_icon_history"
                                            href={`/system/audit/${this.state.uuid}`}
                                        />
                                    </ContextTooltip>
                                ) : null}
                        </ModalTopMenuList>
                    </ModalTopMenuButtons>
                );
            }
        }
        else {
            form = <ObjectEditorForm
                {...this.props}
                mode={this.props.mode}
                onSubmit={::this.create}
                onClose={::this.props.onClose}
                data={{}}
                errors={this.state.errors}
                geometry={this.props.geometry}
            />;
        }

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}>

                {loader}
                {form}

            </PageModal>
        );
    }

    async loadObject(uuid) {
        this.setState({
            uuid,
            isLoading: true
        });
        const response = await this.props.getUserGeoObject(this.props.layerUuid, uuid);
        if (response.status === 'ok') {
            this.setState({isLoading: false});
        } else {
            showError(response.error);
        }
    }

    clearErrors() {
        this.setState({errors: {}});
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    async create(object) {
        const geometry = this.props.geometry.geometry;
        object.geometry = geometry;
        object.object_type = geometry.type;
        object.layer_uuid = this.props.layerUuid;

        this.clearErrors();
        this.startSave();
        const response = await this.props.createUserGeoObject(object);
        this.endSave();
        if (response.status === 'ok') {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: validation.getErrors(response.error)
            });
            showError(response.error);
        }
    }

    async edit(object) {
        this.clearErrors();
        this.startSave();
        const response = await this.props.updateUserGeoObject(object);
        this.endSave();
        if (response.status === 'ok') {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: validation.getErrors(response.error)
            });
            showError(response.error);
        }
    }

    async deleteItem(uuid) {
        this.startSave();
        const response = await this.props.deleteUserGeoObject({uuid});
        this.endSave();
        if (response.status === 'ok') {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: validation.getErrors(response.error)
            });
            showError(response.error);
        }
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    geometry: PropTypes.object,
})

@connect(state => ({}), {getUnits, getLayer})

class ObjectEditorForm extends BaseEditorFormComponent {

    state = {
        units: [],
        layer_parameters: [],
    };

    componentWillMount() {
        this.loadUnits();

        if (window.RNIS_SETTINGS.CITY_MURMANSK) {
            this.loadOrganizationalUnits();
        }
        this.loadLayerParameters();
        this.loadMeasuresAndCommunalMunicipalities();
        this.state.color = this.props.color || '#1186e9'
    }

    async loadLayerParameters() {
        const response = await this.props.getLayer(this.props.layerUuid);

        if (response.isOk) {
            this.setState({
                layer_parameters: response.payload.parameters || [],
            });
        } else {
            response.showErrors();
        }
    }

    async loadOrganizationalUnits() {
        const meta = {
            filters: {
                appendChildrenAttribute: true,
                withParentUnit: null,
                withComponent: 'road'
            },
            order: {
                column: 'name',
                direction: 'asc'
            },
        };
        meta.response_data = [
            'items/uuid',
            'items/name',
        ];

        const response = await this.props.getUnits(meta, false);

        if (response.isOk) {
            this.setState({
                organizational_units: response.payload.items.map((item) => ({ value: item.uuid, label: item.name })),
            });
        } else {
            response.showErrors();
        }
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            filters: {
                withComponent: 'kiutr',
            },
        });

        if (response.isOk) {
            this.setState({
                units: _.sortBy(_.map(response.payload.items, item => ({
                    value: item.uuid,
                    label: item.name,
                })), 'label'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadMeasuresAndCommunalMunicipalities() {
        await this.loadDictionaries(['measures', 'communal_municipalities']);
    }

    onColorChange(color) {
        this.setState({
            color: color
        })
        this.props.object.color = color;
    }

    get(path, defaultValue = null) {
        return _.get(this.props.object, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Block size="xl" title="Наименование">
                    {this.textInput('title')}
                </Block>

                <Block title="Наименование организации">
                    {this.textInput('org_name')}
                </Block>

                <Block title="Контактный телефон">
                    {this.textInput('phone')}
                </Block>

                <Block title="Электронная почта">
                    {this.textInput('email')}
                </Block>

                {this.props.withUnit ? (
                    <Block title="Предприятие">
                        {this.select('unit_uuid', this.state.units)}
                    </Block>
                ) : null}

                <Block size="md" title="Цвет">
                    <div className="colorpicker-container">
                        <div className="colorpicker-color"
                             style={{backgroundColor: this.get('color') || '#3388ff'}}/>
                        <ColorPicker color={this.get('color')} onDrag={::this.onColorChange}
                                     saturationWidth={200} saturationHeight={200}/>
                    </div>
                </Block>
                {!window.RNIS_SETTINGS.hide_user_map_map_objects_parameters ? 
                    _.map(this.state.layer_parameters, (parameter, index) => {
                        return (
                            <Block key={index} title={parameter}>
                                {this.textInput(`parameters.${index}`)}
                            </Block>
                        );
                    })
                : null}

                <div>
                    <Block size="md" title="Единицы измерения">
                        {this.select('measure_uuid', this.state.measures)}
                    </Block>

                    <Block size="md" title="Объем">
                        {this.textInput('volume', {
                            type: 'number',
                            positive: true,
                            step: 0.01,
                        })}
                    </Block>

                    <Block size="md" title="Муниципальное образование">
                        {this.select('communal_municipality_uuid', this.state.communal_municipalities)}
                    </Block>
                </div>

                {window.RNIS_SETTINGS.object_with_organizational_unit ? ( 
                    <Block size="xl" title="Организация ответственная за уборку">
                        {this.select('implementer_uuid', this.state.organizational_units)}
                    </Block>
                ) : null}

                <Block size="xl" title="Описание">
                    {this.textarea('description')}
                </Block>

                <div className="submit">
                    <Button size="md" text={(this.state.uuid) ? 'Сохранить' : 'Добавить'} color="red" shadow="red"
                            onClick={::this.submit}/>
                    {(this.state.uuid) ?
                        <Button size="md" text="Удалить" color="white" shadow="gray"
                                onClick={::this.onDeleteClick}/> : ''}
                    <Button size="md" text="Отмена" color="white" shadow="gray" onClick={::this.cancel}/>
                </div>

            </div>
        );
    }

}
