import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {propTypes} from "react-props-decorators";
import PageModal from "components/ui/page-modal";
import Button from "components/ui/button";
import "./editor.less";
import {createDocument, deleteDocument, getDocument, updateDocument} from "store/reducers/dictionaries/editor";
import GlobalLoaderComponent from "components/ui/global-loader";
import BaseEditorFormComponent from "components/base/base-editor-form";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {DictionaryMeta} from "helpers/components/dictionaries/dictionary_meta";
import {api} from "helpers/api";
import {request} from 'helpers/api/session';
import {makeResponse} from "helpers/response";
import Block from "components/ui/form/block";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import Modal from "components/ui/modal";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import currentUser from 'helpers/current-user';
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {values} from 'lodash';
import ContextTooltip from "components/ui/context-tooltip";
import _ from 'lodash';
import classNames from 'classnames';
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import MapComponent from "components/ui/map";
import MapGeojson from "components/ui/map/base/geojson";
import L from 'leaflet';
import {component_mapper} from "helpers/component_mapper";
import Input from "components/ui/form/input";
import {SelectAsync} from "components/ui/select";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    dictionary: PropTypes.string.isRequired,
    dictionaryMeta: PropTypes.object.isRequired,
    uuid: PropTypes.string
})

@connect(state => ({
    document: state.dictionary_editor.get('document')
}), {getDocument, createDocument, updateDocument, deleteDocument, getUnits})

export default class DictionaryEditor extends Component {

    state = {
        uuid: null,
        document: null,
        isLoading: false,
        saving: false,
        errors: {},
        units: [],
    };

    componentWillMount() {
        if (this.props.dictionary === 'off_days') {
            this.loadUnits();
        }
    }

    componentWillReceiveProps(props) {
        if (this.state.uuid !== props.uuid) {
            this.loadDocument(props.uuid);
        }
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withComponent: component_mapper(this.props.location.query.component || this.props.component),
            },
        });

        if (response.isOk) {
            this.setState({
                units: _.sortBy(_.map(response.payload.items, (item) => ({
                    uuid: item.uuid,
                    name: item.name,
                })), 'name'),
            });
        } else {
            response.showErrors();
        }
    }

    getTitle() {
        if ((this.props.component === 'garbage' ) && (this.props.dictionaryMeta.key === 'vehicle_models')) {
            return 'Типы ТС';
        }
        return this.props.dictionaryMeta.title;
    }

    render() {
        let title = `Добавление: ${this.getTitle()}`;

        let form;
        let buttons;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {
            title = `Редактирование: ${this.getTitle()}`;
            if (this.state.document) {
                form = <DictionaryEditorForm
                    ref="form"
                    location={this.props.location}
                    component={component_mapper(this.props.component)}
                    meta={this.props.dictionaryMeta}
                    onSubmit={::this.edit}
                    onDelete={::this.deleteItem}
                    onClose={::this.props.onClose}
                    data={this.state.document.data}
                    errors={this.state.errors}
                    units={this.state.units}
                />;

                buttons = (
                    <ModalTopMenuButtons>
                        {this.auditButton()}
                        {this.saveButton(::this.onEdit)}
                        {this.closeButton()}
                    </ModalTopMenuButtons>
                );
            }
        } else {
            form = <DictionaryEditorForm
                ref="form"
                location={this.props.location}
                component={component_mapper(this.props.component)}
                meta={this.props.dictionaryMeta}
                onSubmit={::this.create}
                onClose={::this.props.onClose}
                data={{}}
                errors={this.state.errors}
                units={this.state.units}
            />;

            buttons = (
                <ModalTopMenuButtons>
                    {this.saveButton(::this.onCreate)}

                    {this.closeButton()}
                </ModalTopMenuButtons>
            );
        }

        return (
            <div>
                <PageModal
                    header={{title, buttons}}
                    onClose={this.props.onClose}
                    className={`profile-modal`}
                >
                    {loader}
                    {form}
                </PageModal>
            </div>
        );
    }

    auditButton() {
        return (
            <ModalTopMenuList className="top-menu_modal_edit">
                {(currentUser.can('com.rnis.system.permission.audit', 'read')) ?
                    (
                        <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                        default="Журнал аудита">
                            <ModalTopMenuListItem
                                className="b-icon-link_params b-icon-link_icon_history"
                                href={`/system/audit/${this.props.uuid}`}
                            />
                        </ContextTooltip>
                    ) : null}

                <ModalTopMenuListSeparator key="separator"/>
            </ModalTopMenuList>
        );
    }

    saveButton(onClick) {
        return ([
            <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                <ModalTopMenuButton
                    className="_save"
                    title="Сохранить"
                    onClick={onClick}
                />
            </ContextTooltip>,
            <ModalTopMenuButtonsSeparator key="save-button-separator"/>,
        ]);
    }

    closeButton() {
        return (
            <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                <ModalTopMenuButton
                    className="_close"
                    onClick={this.props.onClose}
                />
            </ContextTooltip>
        );
    }

    onCreate(e) {
        e && e.preventDefault();
        const state = this.refs.form.getWrappedInstance().getData();
        this.create(state);
    }

    onEdit(e) {
        e && e.preventDefault();
        const state = this.refs.form.getWrappedInstance().getData();
        this.edit(state);
    }

    async loadDocument(uuid) {
        this.setState({
            uuid,
            document: null,
            isLoading: true
        });
        const response = await this.props.getDocument(this.props.dictionary, uuid);
        if (response.isOk) {
            this.setState({document: response.payload, isLoading: false});
        } else {
            response.showErrors();
        }
    }

    clearErrors() {
        this.setState({errors: {}});
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    async create(data) {
        this.clearErrors();

        delete data.isDeselectAll;

        if (window.RNIS_SETTINGS.temporarily_sbdd && this.props.component === 'operator') {
            data.component = 'sbdd';
        } else {
            data.component = component_mapper(this.props.location.query.component || data.component || this.props.component);
        }

        const document = {
            dictionary: {
                key: this.props.dictionaryMeta.key
            },
            data
        };

        this.startSave();
        const response = await this.props.createDocument(document);

        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async edit(data) {
        this.clearErrors();

        delete data.isDeselectAll;

        if (window.RNIS_SETTINGS.temporarily_sbdd && this.props.component === 'operator') {
            data.component = 'sbdd';
        } else {
            data.component = component_mapper(this.props.location.query.component || data.component || this.props.component);
        }

        const document = {
            dictionary: {
                key: this.props.dictionaryMeta.key
            },
            uuid: this.props.uuid,
            data
        };

        this.startSave();
        const response = await this.props.updateDocument(document);

        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async deleteItem(uuid) {
        this.startSave();
        const response = await this.props.deleteDocument({
            dictionary: {
                key: this.props.dictionaryMeta.key
            },
            uuid
        });
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

}

@propTypes({
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    meta: PropTypes.object.isRequired,
})

@connect(state => ({}), {getDictionaryList}, null, {withRef: true})

class DictionaryEditorForm extends BaseEditorFormComponent {

    state = {
        isDeselectAll: {},
        kurs_formulas: [],
    };

    async componentDidMount() {
        let data = this.props.data;

        console.log(' this.props.data;', this.props.data)
        _.each(this.props.meta.fields, (field) => {
            if (_.indexOf([
                    'App\\Meta\\Fields\\UnitChildren',
                    'App\\Meta\\Fields\\Ports',
                    'App\\Meta\\Fields\\MultiText',
                    'App\\Meta\\Fields\\BelongsToMulti',
                    'App\\Meta\\Fields\\MultiSelect',
                ], field.type) !== -1) {
                _.set(data, field.key, JSON.parse(_.get(data, field.key) || '[]'));
            }
        });
        await this.setState(data);
        if ((this.props.meta.key === 'vehicle_models') && (this.props.component === 'road')) {
            this.loadDictionaries([
                'kurs_formulas',
            ]);
        }
    }

    render() {
        if (!this.props.meta.fields) {
            return null;
        }

      //  console.log('this.props.meta.fields', this.props.meta.fields)
        return (
            <Accordion>
                <AccordionItem single={true}>
                    {this.props.meta.fields.map(field => this.renderField(field))}
                    {this.renderAdditionalFields()}
                </AccordionItem>
            </Accordion>
        );
    }

    renderField(field) {
        if (!field.editable) {
            return null;
        }

        if (field.meta.component && field.key !== 'vehicle_mark_uuid' && (_.indexOf(field.meta.component.split('|'), new URL(window.location.href).searchParams.get("component") ?
            new URL(window.location.href).searchParams.get("component") : this.props.component) === -1)) {
            console.log('return null;', field)
            return null;
        }
        if(!window.RNIS_SETTINGS.CITY_MO && field.key === 'dobrodel_rating'){
            return null;
        }
        return this.getField(field);
    }

    getField(field) {
        if (!this.isVisibleField(field.key)) {
            return;
        }

        switch (field.type) {
            case "App\\Meta\\Fields\\Text":
                return (
                    <Block key={field.key} title={field.title}>
                        {this.textInput(
                            field.key,
                        )}
                    </Block>
                );

            case "App\\Meta\\Fields\\Label":
                return (
                    <Block key={field.key} title={field.title}>
                        {this.textInput(
                            field.key,
                            {
                                disabled: true,
                            }
                        )}
                    </Block>
                );

            case "App\\Meta\\Fields\\Textarea":
                return (
                    <Block key={field.key} size="xl" title={field.title}>
                        {this.textarea(
                            field.key,
                        )}
                    </Block>
                );

            case "App\\Meta\\Fields\\IntegerField":
            case "App\\Meta\\Fields\\FloatField":
                return (
                    <Block key={field.key} title={field.title}>
                        {this.textInput(
                            field.key,
                        )}
                    </Block>
                );

            case 'App\\Meta\\Fields\\Marker':
                return (
                    <Block key={field.key} title={field.title}>
                        {_.map(field.meta.options, (icon) => {
                            return (
                                <MarkerSelect
                                    key={icon}
                                    icon={icon}
                                    isSelected={icon === this.getValue(field.key)}
                                    onClick={this.setValue.bind(this, field.key, icon)}
                                />
                            )
                        })}
                    </Block>
                );

            case "App\\Meta\\Fields\\Checkbox":
                return (
                    <Block key={field.key} title=" ">
                        {this.checkbox(
                            field.key,
                            field.title
                        )}
                    </Block>
                );

            case "App\\Meta\\Fields\\Select":
                return (
                    <Block key={field.key} title={field.title}>
                        {this.select(
                            field.key,
                            values(field.meta.options)
                        )}
                    </Block>
                );

            case "App\\Meta\\Fields\\MultiSelect":
                return (
                    <Block key={field.key} title={field.title}>
                        {this.select(
                            field.key,
                            values(field.meta.options),
                            {
                                multi: true,
                            }
                        )}
                    </Block>
                );

            case "App\\Meta\\Fields\\DatePicker":
            case "App\\Meta\\Fields\\DateTimePicker":
                return (
                    <Block key={field.key} title={field.title}>
                        {this.datepicker(
                            field.key,
                        )}
                    </Block>
                );

            case "App\\Meta\\Fields\\BelongsTo":
                return (
                    <BelongsToComponent
                        key={field.key}
                        {...this.props}
                        data={this.props.data}
                        onSubmit={this.props.onSubmit}
                        onClose={this.props.onClose}
                        editor={this}
                        dictionary={field.meta.dictionary}
                        field={field}
                    />
                );

            case "App\\Meta\\Fields\\BelongsToMulti":
                return (
                    <BelongsToComponent
                        key={field.key}
                        {...this.props}
                        data={this.props.data}
                        onSubmit={this.props.onSubmit}
                        onClose={this.props.onClose}
                        editor={this}
                        dictionary={field.meta.dictionary}
                        field={field}
                        multi={true}
                    />
                );

            case "App\\Meta\\Fields\\BelongsToExchange":
                return (
                    <BelongsToExchangeComponent
                        key={field.key}
                        {...this.props}
                        data={this.props.data}
                        onSubmit={this.props.onSubmit}
                        onClose={this.props.onClose}
                        editor={this}
                        field={field}
                    />
                );

            case "App\\Meta\\Fields\\Geometry":
                const geometryText = _.get(this.state, field.key);
                const geometry = geometryText ? JSON.parse(geometryText) : null;

                return (
                    <Block key={field.key} size="xl">
                        <div style={{height: '500px'}}>
                            <MapComponent
                                ref="map"
                                showLayers={false}
                            >
                                {(this.refs.map && geometry) ? (
                                    <MapGeojson
                                        map={this.refs.map}
                                        ref={() => {
                                            try {
                                                const group = L.geoJSON(geometry);
                                                this.refs.map.getWrappedInstance().fitBounds(group.getBounds());
                                            } catch (e) {
                                            }
                                        }}
                                        leafletMap={this.refs.map.getWrappedInstance().map}
                                        geometry={geometry}
                                    />
                                ) : null}

                            </MapComponent>
                        </div>
                    </Block>
                );

            case "App\\Meta\\Fields\\UnitChildren":
                const selectedCount = (_.get(this.state, field.key) || []).length;
                const totalCount = this.props.units.length;

                const sliderClassName = classNames({
                    'b-slider__line': true,
                    '_selected_yes': selectedCount && selectedCount !== totalCount,
                    '_selected_all': selectedCount === totalCount,
                });

                const sliderCircleClassName = classNames({
                    'b-slider__circle': true,
                    '_selected_yes': selectedCount && selectedCount !== totalCount,
                    '_selected_all': selectedCount === totalCount,
                });
                return (
                    <Block key={field.key} title={field.title}>
                        <div className="b-slider _options clearAfter"
                             onClick={this.selectDeselectAll.bind(this, field.key)}>
                            <div className="b-slider__title" style={{display: 'none'}}>Выбрать все</div>
                            <div
                                className="b-slider__title">{_.get(this.state.isDeselectAll, field.key) ? 'Снять все' : 'Выбрать все'}</div>

                            <div className="b-slider__control">
                                <div className={sliderClassName}/>
                                <div className={sliderCircleClassName}/>
                            </div>
                        </div>
                        {_.map(this.props.units, (unit) => {
                            return this.checkbox(`${field.key}.${unit.uuid}`, unit.name, {
                                value: _.indexOf(_.get(this.state, field.key) || [], unit.uuid) !== -1,
                                onChange: ({target: {value}}) => {
                                    let state = this.state;
                                    let values = _.get(state, field.key) || [];
                                    const position = _.indexOf(values, unit.uuid);
                                    if (position !== -1) {
                                        values.splice(position, 1);
                                    } else {
                                        values.push(unit.uuid);
                                    }
                                    _.set(state, field.key, values);
                                    this.setState(state);
                                },
                            });
                        })}
                    </Block>
                );

            case "App\\Meta\\Fields\\Ports":
                const data = _.get(this.state, field.key) || [];

                return (
                    <Accordion key={field.key} className="b-modal-bnso b-modal-bnso-types_edit">
                        <AccordionItem title={field.title} opened={true}>
                            <div className="b-draggable">
                                <div className="b-draggable__row fs-zero">
                                    <div className="b-block _sm">
                                        <div className="b-block__title">Номер</div>
                                    </div>
                                    <div className="b-block _md">
                                        <div className="b-block__title">Режимы</div>
                                    </div>
                                    <div className="b-block _lg">
                                        <div className="b-block__title">Заметка</div>
                                    </div>
                                </div>
                                {_.map(data, (item, index) => {
                                    return (
                                        <div className="b-draggable__item sector sector_idling sector_state_violation">
                                            <div className="b-draggable__top fs-zero">
                                                <div className="b-draggable__menu">
                                                    <div
                                                        className="b-draggable__menu-link b-draggable__menu-link_basket"
                                                        onClick={this.deleteArrayItem.bind(this, field.key, index)}/>
                                                </div>
                                                <Block size="sm">
                                                    {this.textInput(`ports.${index}.number`, {
                                                        type: 'number',
                                                        positive: true
                                                    })}
                                                </Block>
                                                <Block>
                                                    {this.select(`ports.${index}.types`, [
                                                        {
                                                            value: 'toggle',
                                                            label: 'Свитч',
                                                        },
                                                        {
                                                            value: 'numeric',
                                                            label: 'Числовой',
                                                        },
                                                    ], {
                                                        multi: true,
                                                    })}
                                                </Block>
                                                <Block size="lg">
                                                    {this.textInput(`ports.${index}.text`)}
                                                </Block>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="add-draggable">
                                    <div className="add-draggable__icon" onClick={::this.addPort}>+</div>
                                </div>
                            </div>
                        </AccordionItem>
                    </Accordion>
                );

            case "App\\Meta\\Fields\\MultiText":
                const multiTextData = _.get(this.state, field.key) || [];

                return (
                    <Accordion key={field.key} className="b-modal-bnso b-modal-bnso-types_edit">
                        <AccordionItem title={field.title} opened={true}>
                            <div className="b-draggable">
                                <div className="b-draggable__row fs-zero">
                                    <div className="b-block _sm">
                                        <div className="b-block__title">№</div>
                                    </div>
                                    <div className="b-block _lg">
                                        <div className="b-block__title">Наименование</div>
                                    </div>
                                    <div className="b-block _md">
                                        <div className="b-block__title">Связанное значение свитча</div>
                                    </div>
                                </div>
                                {_.map(multiTextData, (item, index) => {
                                    return (
                                        <div key={index}
                                             className="b-draggable__item sector sector_idling sector_state_violation">
                                            <div className="b-draggable__top fs-zero">
                                                <div className="b-draggable__menu">
                                                    <div
                                                        className="b-draggable__menu-link b-draggable__menu-link_basket"
                                                        onClick={this.deleteArrayItem.bind(this, field.key, index)}/>
                                                </div>
                                                <Block size="sm">
                                                    {index}
                                                </Block>
                                                <Block size="lg">
                                                    {this.textInput(`${field.key}.${index}`)}
                                                </Block>
                                                <Block size="md">
                                                    {(index === 1) ? 'true' : ((index === 0) ? 'false' : '')}
                                                </Block>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="add-draggable">
                                    <div className="add-draggable__icon"
                                         onClick={this.addArrayItem.bind(this, field.key)}>+
                                    </div>
                                </div>
                            </div>
                        </AccordionItem>
                    </Accordion>
                );

            default:
                console.warn(`Field type [${field.type}] not found`)
        }

        return null;
    }

    renderAdditionalFields() {
        if ((this.props.meta.key === 'vehicle_models') && (this.props.component === 'road')) {
            const formula = this.getFormula();
            if (!formula) {
                return null;
            }

            return (
                <Block key="formula" title="Формула">
                    <Input value={formula.label} disabled={true}/>
                </Block>
            );
        }

        return null;
    }

    getFormula() {
        console.log(this.state.kurs_formulas)
        return _.first(_.filter(this.state.kurs_formulas || [], (formula) => {
            const vehicle_models = JSON.parse(formula.document.vehicle_models || '[]');
            return _.indexOf(vehicle_models, this.state.uuid) !== -1;
        }));
    }

    isVisibleField(field) {
        if ((this.props.meta.key === 'vehicle_models') && (this.props.component === 'road')) {
            const formula = this.getFormula();
           // console.log(formula)
            if (_.indexOf([
                    'name',
                    'vehicle_mark_uuid',
                    'comment',
                    'vehicle_model_uuid',
                    'fuel_spent_work',
                    'vehicle_capacity_type_uuid',
                    'fuel_spent',
                    'fuel_spent_warm',
                    'fuel_spent_trailer',
                    'fuel_spent_additional',
                    'fuel_spent_additional_2',
                    'fuel_spent_2',
                    'trailer_weight',
                    'trailer_carry_weight',
                ], field) !== -1) {
                return true;
            }

            if (!formula) {
                return false;
            }

            switch (parseInt(formula.document.key)) {
                case 1:
                    return _.indexOf([
                        'fuel_spent',
                    ], field) !== -1;
                case 2:
                    return _.indexOf([
                        'fuel_spent',
                        'fuel_spent_warm',
                    ], field) !== -1;
                case 3:
                    return _.indexOf([
                        'fuel_spent',
                        'fuel_spent_trailer',
                        'trailer_weight',
                        'fuel_spent_work',
                    ], field) !== -1;
                case 4:
                    return _.indexOf([
                        'fuel_spent',
                        'fuel_spent_work',
                        'trailer_weight',
                        'fuel_spent_additional',
                    ], field) !== -1;
                case 5:
                    return _.indexOf([
                        'fuel_spent',
                        'fuel_spent_additional_2',
                    ], field) !== -1;
                case 6:
                    return _.indexOf([
                        'fuel_spent',
                        'fuel_spent_2',
                    ], field) !== -1;
                case 7:
                    return _.indexOf([
                        'fuel_spent',
                        'fuel_spent_additional_2',
                    ], field) !== -1;
            }

            return false;
        }
        return true;
    }

    addPort() {
        let ports = this.state.ports || [];
        ports.push({
            number: '0',
            type: 'toggle',
            text: '',
        });
        this.setState({ports});
    }

    addArrayItem(key) {
        let state = this.state;
        state[key] = state[key] || [];
        state[key].push('');
        this.setState(state);
    }

    deleteArrayItem(key, index) {
        let state = this.state;
        state[key] = state[key] || [];
        state[key].splice(index, 1);
        this.setState(state);
    }

    selectDeselectAll(key) {
        if (this.state.isDeselectAll[key]) {
            let state = this.state;
            _.set(state, key, []);
            this.setState({state});
        } else {
            let state = this.state;
            _.set(state, key, _.map(this.props.units, 'uuid'));
            this.setState({state});
        }

        let isDeselectAll = this.state.isDeselectAll;
        isDeselectAll[key] = !isDeselectAll[key];
        this.setState({
            isDeselectAll,
        });
    }
}

class BelongsToExchangeComponent extends BaseEditorFormComponent {

    state = {
        options: []
    };

    async componentDidMount() {
        this.setState({loading: true});
        const response = await request(this.props.field.meta.list.method, {}, {
            meta: {
                pagination: {
                    page: 1,
                    limit: 10000,
                },
                filters: {
                    withComponent: this.props.location.query.component || this.props.component,
                },
            },
        });

        //console.log('BelongsToExchangeComponent', response);

        if (response.isOk) {
            let docs = response.get(`payload.${this.props.field.meta.list.path}`);

            this.setState({
                options: _.map(docs, (document) => {
                    return {
                        value: document.uuid,
                        label: document[this.props.field.meta.list.title_field]
                    };
                })
            });
        } else {
            response.showErrors();
        }

        //console.log('BelongsToExchangeComponent [options]', this.state.options)

        this.setState({loading: false});
    }

    render() {
        const loader = this.state.loading ? <GlobalLoaderComponent/> : null;
        const select = (!this.state.loading) ? (
            this.select(
                this.props.field.key,
                _.sortBy(this.state.options, 'label')
            )
        ) : null;

        return (
            <Block title={this.props.field.title}>
                {loader}
                {select}
            </Block>
        );
    }

    getState() {
        return this.props.editor.state;
    }
}


@connect(state => ({}), {getDictionaryList})

class BelongsToComponent extends BaseEditorFormComponent {

    state = {
        options: [],
        dictionary: null,
    };

    async componentDidMount() {
        this.setState({loading: true});

        const response = await makeResponse(() => {
            return api.dictionary.getDictionaryMeta(this.props.dictionary);
        });


        //console.log('BelongsToComponent [componentDidMount]', response)

        if (response.isOk) {
            this.setState({
                dictionary: new DictionaryMeta(response.payload),
            });
            //this.loadDocuments(new DictionaryMeta(response.payload));
        } else {
            response.showErrors();
        }
        this.setState({loading: false});
    }

    async loadDocuments(dictionary) {
        this.setState({loading: true});
        const response = await this.props.getDictionaryList(dictionary.key, {
            filters: {
                withComponent: this.props.location.query.component || this.props.component,
            },
            pagination: {
                page: 1,
                limit: 10000,
            },
        }, false);

        //console.log('BelongsToComponent [loadDocuments]', response)

        if (response.isOk) {
            this.setState({
                options: _.map(response.payload.documents, (document) => {
                    return {
                        value: document.uuid,
                        label: document[dictionary.title_field]
                    };
                })
            });
        } else {
            response.showErrors();
        }

        //console.log('BelongsToComponent [options]', this.state.options)

        this.setState({loading: false});
    }

    async loadOptions(input, callback) {
        let result;
        if (!input) {
            result = await this.props.getDictionaryList(this.state.dictionary.key, {
                filters: {
                    withComponent: this.props.location.query.component || this.props.component,
                    uuid: _.get(this.props.data, this.props.field.key),
                },
                pagination: {
                    page: 1,
                    limit: 1000,
                },
            }, false);
        } else {
            result = await this.props.getDictionaryList(this.state.dictionary.key, {
                filters: {
                    withComponent: this.props.location.query.component || this.props.component,
                },
                search: input,
                pagination: {
                    page: 1,
                    limit: 20,
                },
            }, false);
        }

        if (result.isOk) {
            let newOptions = result.payload.documents.map(item => ({
                value: item.uuid,
                label: item[this.state.dictionary.title_field]
            }));
            let options = this.state.options;
            options = _.concat(options, newOptions);

            if (this.props.dictionary === 'work_categories') {
                if (window.RNIS_SETTINGS.SHOWED_WORKS_CATEGORIES && window.RNIS_SETTINGS.SHOWED_WORKS_CATEGORIES.length) {
                    newOptions = newOptions.filter((option) => window.RNIS_SETTINGS.SHOWED_WORKS_CATEGORIES.find((swc) => swc === option.label))
                    options = options.filter((option) => window.RNIS_SETTINGS.SHOWED_WORKS_CATEGORIES.find((swc) => swc === option.label))
                }
            }

            this.setState({
                options
            });

            callback(null, {
                options: newOptions,
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    render() {
        if (this.state.loading) {
            return null;
        }

        return (
            <Block title={this.props.field.title} size={this.props.multi ? 'xl' : 'md'}>
                {this.props.multi ? this.renderSelectedValues() : null}
                {this.selectAsync(
                    this.props.field.key,
                    ::this.loadOptions,
                )}
            </Block>
        );
    }

    renderSelectedValues() {
        return _.map(_.get(this.state, this.props.field.key) || _.get(this.getState(), this.props.field.key) || [], (value, index) => {
            return (
                <div key={index}>
                    {_.get(_.find(this.state.options, {value}), 'label')}
                    &nbsp;
                    <a href="javascript:void(0)" onClick={this.deleteValue.bind(this, index)}>x</a>
                </div>
            );
        });
    }

    deleteValue(index) {
        let state = this.state;
        if (!_.get(this.state, this.props.field.key)) {
            state = this.getState();
        }
        state[this.props.field.key].splice(index, 1);
        this.setState({state});
    }

    selectAsync(fieldName, loadOptions, props = {}) {
        if (this.props.multi) {
            props.value = null;
            props.name = fieldName;
            props.loadOptions = loadOptions;
            if (!props.onChange) {
                props.onChange = (e) => {
                    let value = e ? e.value : null;
                    let label = e ? e.label : null;

                    if (props.multi) {
                        value = e;
                        value = _.map(value, 'value');
                    }

                    this.onChangeInput(fieldName, {target: {value, label}});
                };
            }

            const error = this.getError(fieldName);
            if (error) {
                props.className = 'wrong';
            }

            const styleClassName = classNames(error ? 'input__style_state_wrong' : '');

            return (
                <ContextTooltip key={`form.${fieldName}`} code={`form.${fieldName}`}>
                    <div>
                        <SelectAsync {...props}/>
                        <div className={styleClassName}>
                            {error ?
                                <span className="input__style_state_msg_wrong">{error}</span> : null}
                        </div>
                    </div>
                </ContextTooltip>
            );
        } else {
            return super.selectAsync(fieldName, loadOptions, props);
        }
    }

    async setValue(field, value) {
        if (this.props.multi) {
            let state = this.getState();

            state[field] = state[field] || [];
            state[field].push(value);

            return this.setState(state);
        } else {
            super.setValue(field, value);
        }
    }

    getState() {
        return this.props.editor.state;
    }
}

class MarkerSelect extends Component {

    onClick() {
        this.props.onClick();
    }

    render() {
        return (
            <div className={classNames('marker', 'marker__select', `marker_type_${this.props.icon}`)}
                 onClick={::this.onClick}>
                <div>
                    {this.props.isSelected ? (
                        <div className="marker__active marker__active_blue marker__active_round"/>
                    ) : null}
                    <span className={classNames('marker__icon', `icon-${this.props.icon}`)}/>
                </div>
            </div>
        );
    }
}
