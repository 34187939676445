import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getUnitPermissions = (baseUnitUuid) => async (dispatch) => {
    try {
        const response = await api.auth.getUnitPermissions(baseUnitUuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateUnitPermission = (data) => async (dispatch) => {
    try {
        const response = await api.auth.updateUnitPermission(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
