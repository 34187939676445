import React from 'react';
import {connect} from 'react-redux';

import Editor from './RoadPartsEditor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {EntityList} from "helpers/entity";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getEntityNames} from "store/reducers/system";
import _ from 'lodash';
import {deleteRoadPart, getRoadParts} from "store/reducers/kurs/road_parts";
import systems from "dictionaries/systems";

@connect(state => ({}), {getRoadParts, deleteRoadPart, getEntityNames, getDictionaryList})

export default class KursRoadPartsComponent extends BaseTableWithEditorComponent {

    title = `${systems.road} → Участки дорог`;
    baseUrl = '/road/road_parts';

    getEditor() {
        return (
            <Editor
                {...this.props}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    getColumns() {
        const { CITY_MO } = window.RNIS_SETTINGS
        const communalMunicipalityFilter = (CITY_MO === false
            || CITY_MO === undefined || CITY_MO === "undefined") ? 'withFilter' : 'denyColumnFilter'


        return [
            new Column('Название участка')
                .fromField('name')
                .withClassName('link'),

            ...(window.RNIS_SETTINGS.municipality_in_road_part ? [
                new Column('Муниципальное образование')
                    .fromField('communal_municipality_uuid')
                    .denyColumnFilter()
                    .withDrawer(item => item.communal_municipality_uuid ? this.state.related.get(item.communal_municipality_uuid) : '<code>-</code>')
                    [communalMunicipalityFilter]('withMunicipality', async () => {
                        return await this.getDictionary('communal_municipalities');
                }),
            ] : []),

            new Column('Длина')
                .fromField('length'),

            new Column('Ширина проезжей части, м')
                .fromField('width'),

            new Column('Начало')
                .fromField('start'),

            new Column('Конец')
                .fromField('end'),

            new Column('Категория')
                .fromField('category'),

            new Column('Группа по содержанию')
                .fromField('maintenance_group_uuid')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => item.maintenance_group_uuid && this.state.related.get(item.maintenance_group_uuid)),
        ];
    }

    async deleteItem(data) {
        return await this.props.deleteRoadPart(data);
    }

    async loadData(meta) {
        return await this.props.getRoadParts(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const maintenanceGroups = _.map(_.filter(_.map(result, 'maintenance_group_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\MaintenanceGroups\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const municipalities = _.map(_.filter(_.map(result, 'communal_municipality_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Communal\\CommunalMunicipalities\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(_.concat(maintenanceGroups, municipalities));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}