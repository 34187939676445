import _ from 'lodash';

const compare = (setting, defaultSetting, path) => {
    if (_.isArray(defaultSetting) && !_.isObject(defaultSetting[0])) {
        return setting;
    }

    if (_.isArray(defaultSetting) && defaultSetting[0].id) {
        return defaultSetting.map((dset, index) => {
            let settingElement = setting.find((set) => set.id === dset.id)
            if (!settingElement) {
                // console.warn(`Не удаётся найти ${path} с id ${dset.id}`)
                return dset
            }

            return compare(settingElement, dset, `${path}.${index}`)
        })
    }

    const se = {}
    for (const key in defaultSetting) {
        if (typeof setting[key] === 'undefined') {
            // console.warn(`Не задано значение ${path}.${key}`)
            se[key] = defaultSetting[key]
        } else {
            if (_.isObject(defaultSetting[key])) {
                se[key] = compare(setting[key], defaultSetting[key], `${path}.${key}`)
            } else {
                se[key] = setting[key]
            }
        }
    }

    return se
}

export const installSettings = (settings) => {
    window.RNIS_SETTINGS = compare(settings, DEFAULT_SETTINGS, '')
}

const DEFAULT_SETTINGS = {
    "RNIS_SIP_URL": "wss://rnis2.nso.ru/sip",
    "RNIS_SIP_URL_TURNSERVER": {
        "urls": "turn:185.138.128.10?transport=udp",
        "username": "anutkaTurn",
        "password": "Rnso2020wtrD"
    },
    "RNIS_HOME_URL": "api-rnis.t1-group.ru",
    "RNIS_API_URL": "api-smut.t1-group.ru",
    "TIMEZONE_OFFSET":[{"ids":[],"offset":0,"correction":0}],
    "TOP_NOTIFICATION_TEXT": "",
    "BLOCKING_ALERTS": [
        {
        "message": "",
        "units": [
        ],
        "frequency": 30,
        "unlock_delay": 5
        }
    ],
    "TITLE": "Региональная навигационно-информационная система",
    "SHORT_TITLE": "РНИС",
    "SECONDARY_TITLE": "Московской",
    "SECONDARY_TITLE_S": "Московская",
    "GUGATNTITLE": "Контроль служебного транспорта",
    "CUSTOM_PUSH": false,
    "SNAKEDELAY": 0,
    "RECALCDELAY": 10,
    "ORDERSBYDAY": "hide",
    "OPERATIONCHANGES": 0,
    "VEHICLETOMORROW": 30,
    "OSMDEFAULTLAYER": true,
    "HISTORYPOINTSCOEF": 0.65,
    "HARDRELOADTIME": "2019-08-20T10:53:17+00:00",
    "HIDESNAKEPOINTS": false,
    "OPDISPATCHING": true,
    "SHORTCALENDAR": {
        "MINBEFORE": 2,
        "MAXAFTER": 1
    },
    "TRACKCOLOR": "#f65d50",
    "MAPCENTER_F": true,
    "PHOTOBUSSTOP_F": true,
    "HIDEIMPORTMOSAVTODORTAB" : true,
    "HIDEIMPORTSKPDITAB" : true,

    "HIDESETTINGSCOMMONTAB": false,
    "HIDESETTINGSKIUTRTAB": false,
    "HIDESETTINGSKURSTAB": false,
    "HIDESETTINGSSTATISTICSTAB": false,
    "HIDESETTINGSSKPDILOGSTAB": false,
    "HIDESETTINGSSTOPPOINTSTAB": true,
    "HIDESETTINGSLOGICTRIPCHECKTAB": false,

    "HIDESETTINGSBTNAPIDESCRIPTION": false,

    "HIDESOMEREPORTS": false,
    "HIDEREPORTSNSO": false,
    "CITY_NNOVGOROD": false,
    "CITY_NOVOSIBIRSK": false,
    "CITY_MO": false,
    "CITY_TULA": false,
    "HIDERNISSYSTEMSTATUS": false,
    "WMSRGISURL": "http://int.rgis.mosreg.ru/wms/cache/?service=WMS&request=GetCapabilities" ,
    "TELEMATICLINERETROSPECTIVE": [
        {
        "ids": [
            "438fe43e-fd0a-11e7-8d99-02f42b019a3!b"
        ]
        }
    ],
    "CUSTOMMARKER": [
        {
        "ids": [
            "438fe43e-fd0a-11e7-8d99-02f42b019a3b000"
        ],
        "marker": {
            "min": 24,
            "max": 38,
            "custommarkericon": true
        }
        }
    ],

    "TIMESOZESETTING": false,
    "HINTDRIVERNAME": true,
    "SHOWTABLOCOORD": true,
    "ECPOFF": true,
    "OFFICIALPRINT": true,
    "RAILROADCROSSING": false,
    "FILTERSEXTENDED": true,
    "EXITCONTROL": true,
    "FREEACCOUNTROLENAME": "[КиУТР] Коммерческий перевозчик",
    "PAIDACCOUNTROLENAME": "[КиУТР] Коммерческий перевозчик (копия с расширенными правами)",
    "SHOWFREEDRIVERSLIST": false,
    "TILE_REQUEST_METHOD": "https",
    "TILE_SERVER_HOSTNAME": "tile.t1-group.ru/tile",
    "GISMETEOWEATHER": true,

    "LOGOIMAGE": "",

    "SUBSYSTEMMENU": [
        {
            "id": "administration",
            "name": "Настройки",
            "hideForImport": false
        },
        {
            "id": "operator",
            "name": "Оператор системы",
            "hideForImport": false
        },
        {
            "id": "analytics",
            "name": "Аналитическая платформа",
            "hideForImport": false
        },
        {
            "id": "kiutr",
            "name": "Управление пассажирскими перевозками",
            "hideForImport": false
        },
        {
            "id": "road",
            "name": "Обслуживание дорог",
            "hideForImport": false
        },
        {
            "id": "communal",
            "name": "Коммунальная техника",
            "hideForImport": false
        },
        {
            "id": "children",
            "name": "Управление перевозками детей",
            "hideForImport": false
        },
        {
            "id": "garbage",
            "name": "Контроль вывоза мусора",
            "hideForImport": false
        },
        {
            "id": "control",
            "name": "ГУ ГАТН",
            "hideForImport": false
        },
        {
            "id": "taxi",
            "name": "Мониторинг легковых такси",
            "hideForImport": true
        },
        {
            "id": "agricultural",
            "name": "Транспорт сельского хозяйства",
            "hideForImport": true
        },
        {
            "id": "agricultural",
            "name": "Транспорт сельского хозяйства",
            "hideForImport": true
        },
        {
            "id": "medic",
            "name": "Контроль медицинского транспорта",
            "hideForImport": true
        },
        {
            "id": "timber",
            "name": "Перевозки лесоматериалов",
            "hideForImport": true
        },
        {
            "id": "dangerous",
            "name": "Перевозки опасных грузов",
            "hideForImport": true
        },
        {
            "id": "medicine",
            "name": "Центр медицины и катастроф",
            "hideForImport": true
        },
        {
            "id": "forestry",
            "name": "Лесное хозяйство",
            "hideForImport": true
        },
        {
            "id": "farming",
            "name": "Сельское хозяйство",
            "hideForImport": true
        },
        {
            "id": "official",
            "name": "Служебный транспорт",
            "hideForImport": true
        },
        {
            "id": "emercom",
            "name": "Пожарно-спасательная служба",
            "hideForImport": true
        },
        {
            "id": "commdept",
            "name": "Коммунальная техника (ДТ)",
            "hideForImport": true
        },
    ],

    "DICTIONARIESSYSTEMS": {
        "administration": {
            "hideForRoles": false
        },
        "operator": {
            "hideForRoles": false,
            "name": "Оператор системы"
        },
        "analytics": {
            "hideForRoles": false
        },
        "kiutr": {
            "hideForRoles": false
        },
        "ems": {
            "hideForRoles": true
        },
        "kiutr-control": {
            "hideForRoles": false
        },
        "road": {
            "hideForRoles": false
        },
        "utility": {
            "hideForRoles": true
        },
        "communal": {
            "hideForRoles": true
        },
        "children": {
            "hideForRoles": false
        },
        "garbage": {
            "hideForRoles": false
        },
        "taxi": {
            "hideForRoles": true
        },
        "agricultural": {
            "hideForRoles": true
        },
        "medic": {
            "hideForRoles": true
        },
        "timber": {
            "hideForRoles": true
        },
        "dangerous": {
            "hideForRoles": true
        },
        "medicine": {
            "hideForRoles": true
        },
        "forestry": {
            "hideForRoles": true
        },
        "farming": {
            "hideForRoles": true
        },
        "official": {
            "hideForRoles": true
        },
        "emercom": {
            "hideForRoles": true
        },
        "commdept": {
            "hideForRoles": true
        },
        "control": {
            "hideForRoles": false
        },
        "system": {
            "hideForRoles": false,
            "name": "Оператор системы"
        },
        "passengers": {
            "hideForRoles": false
        },
        "roads": {
            "hideForRoles": false
        },
        "gatn": {
            "hideForRoles": false
        },
        "reports": {
            "hideForRoles": false
        }
    },

    "HIDDENROADDICTIONARIES": [],

    "CUSTOMMENU": true,
    "CUSTOMMENUSTATE": [
        {
        "text": "Оператор системы",
        "id": "100",
        "show": true,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "101",
            "show": true
            },
            {
            "text": "Права доступа",
            "id": "102",
            "show": true,
            "submenu": [
                {
                "text": "Роли",
                "id": "103",
                "show": true
                },
                {
                "id": "104",
                "text": "Структурные права",
                "show": true
                }
            ]
            },
            {
            "text": "Справочники",
            "id": "105",
            "show": true
            },
            {
            "text": "Настройки",
            "id": "106",
            "show": true
            },
            {
            "text": "Диагностика БНСО",
            "id": "107",
            "show": true
            },
            {
            "text": "Комплекты регистрационных документов",
            "id": "108",
            "show": true
            },
            {
            "text": "Заявления на подключение",
            "id": "109",
            "show": true
            },
            {
                "text": "Заявки из ESIA",
                "id": "510",
                "show": false
            },
            {
            "text": "Шаблоны документов",
            "id": "110",
            "show": true
            },
            {
            "text": "Соглашения о взаимодействии",
            "id": "111",
            "show": true
            },
            {
            "text": "Корзина",
            "id": "112",
            "show": true
            },
            {
            "text": "Журнал событий",
            "id": "113",
            "show": true
            },
            {
            "text": "Мониторинг пользователей",
            "id": "114",
            "show": true
            },
            {
            "text": "Мониторинг ТС",
            "id": "115",
            "show": true
            }
        ]
        },
        {
            "text": "Аналитическая платформа",
            "id": "116",
            "show": true,
            "submenu": [
                {
                    "text": "Сводные показатели",
                    "id": "117",
                    "show": true
                },
                {
                    "text": "Карта нарушений",
                    "id": "342",
                    "show": true
                },
                {
                    "text": "Пассажирские перевозки",
                    "id": "118",
                    "show": true
                },
                {
                    "text": "Обслуживание дорог",
                    "id": "119",
                    "show": true
                },
                {
                    "text": "Обслуживание дорог",
                    "id": "219",
                    "show": true
                },
                {
                    "text": "Перевозки детей",
                    "id": "120",
                    "show": true
                },
                {
                    "text": "Обслуживание дорог",
                    "id": "121",
                    "show": true
                },
                {
                    "text": "Вывоз мусора",
                    "id": "122",
                    "show": false
                },
                {
                    "text": "Транспорт ОГВ",
                    "id": "123",
                    "show": true
                },
                {
                    "text": "Мониторинг легковых такси",
                    "id": "328",
                    "show": true
                },
                {
                    "text": "Транспорт сельсокого хозяйства",
                    "id": "329",
                    "show": true
                },
                {
                    "text": "Контроль медицинского транспорта",
                    "id": "330",
                    "show": true
                },
                {
                    "text": "Перевозки лесоматериалов",
                    "id": "331",
                    "show": true
                },
                {
                    "text": "Интерактивная карта",
                    "id": "124",
                    "show": true
                },
                {
                    "text": "Коммунальная техника",
                    "id": "339",
                    "show": true
                },
                {
                    "text": "Перевозки опасных грузов",
                    "id": "340",
                    "show": true
                },
                {
                    "text": "Служебный транспорт",
                    "id": "414",
                    "show": true
                }, {
                    "text": "Пожарно-спасательная служба",
                    "id": "407",
                    "show": true
                }, {
                    "text": "Сельское хозяйство",
                    "id": "400",
                    "show": true
                }, {
                    "text": "Лесное хозяйство",
                    "id": "372",
                    "show": true
                }, {
                    "text": "Жилищно-коммунальное хозяйство",
                    "id": "379",
                    "show": true
                },{
                    "text": "Центр медицины и катастроф",
                    "id": "365",
                    "show": true
                }
            ]
        },
        {
        "text": "Управление пассажирскими перевозками",
        "id": "125",
        "show": true,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "126",
            "show": true
            },
            {
            "text": "Права доступа",
            "id": "127",
            "show": true
            },
            {
            "text": "Справочники",
            "id": "128",
            "show": true
            },
            {
            "text": "Паспорта маршрутов",
            "id": "129",
            "show": true
            },
            {
            "text": "Реестр маршрутов",
            "id": "130",
            "show": true
            },
            {
            "text": "Расписания",
            "id": "131",
            "show": true
            },
            {
            "text": "План-наряды",
            "id": "132",
            "show": true
            },
            {
            "text": "Контракты",
            "id": "133",
            "show": true
            },
            {
            "text": "Учет транспортной работы",
            "id": "134",
            "show": true
            },
            {
            "text": "Контроль прохождения КП",
            "id": "135",
            "show": true
            },
            {
            "text": "Мониторинг ТС",
            "id": "136",
            "show": true
            },
            {
            "text": "Остановочные табло",
            "id": "137",
            "show": true
            },
            {
            "text": "Нарушения",
            "id": "138",
            "show": true
            }
        ]
        },
        {
        "text": "Электронная маршрутная сеть",
        "id": "139",
        "show": false,
        "submenu": [
            {
            "text": "Права доступа",
            "id": "140",
            "show": true
            },
            {
            "text": "Справочники",
            "id": "141",
            "show": true
            },
            {
            "text": "Паспорта маршрутов",
            "id": "142",
            "show": true
            },
            {
            "text": "Реестр маршрутов",
            "id": "143",
            "show": true
            },
            {
            "text": "Расписания",
            "id": "144",
            "show": true
            },
            {
            "text": "Контракты",
            "id": "145",
            "show": true
            },
            {
            "text": "Мониторинг ТС",
            "id": "146",
            "show": true
            }
        ]
        },
        {
        "text": "Контроль пассажирских перевозок",
        "id": "147",
        "show": true,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "148",
            "show": true
            },
            {
            "text": "Права доступа",
            "id": "149",
            "show": true
            },
            {
            "text": "Справочники",
            "id": "150",
            "show": true
            },
            {
            "text": "Паспорта маршрутов",
            "id": "151",
            "show": true
            },
            {
            "text": "Реестр маршрутов",
            "id": "152",
            "show": true
            },
            {
            "text": "Расписания",
            "id": "153",
            "show": true
            },
            {
            "text": "План-наряды",
            "id": "154",
            "show": true
            },
            {
            "text": "Контракты",
            "id": "155",
            "show": true
            },
            {
            "text": "Учет транспортной работы",
            "id": "156",
            "show": true
            },
            {
            "text": "Контроль прохождения КП",
            "id": "157",
            "show": true
            },
            {
            "text": "Мониторинг ТС",
            "id": "158",
            "show": true
            },
            {
            "text": "Остановочные табло",
            "id": "159",
            "show": true
            },
            {
            "text": "Нарушения",
            "id": "160",
            "show": true
            }
        ]
        },
        {
        "text": "Обслуживание дорог",
        "id": "161",
        "show": true,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "162",
            "show": true
            },
            {
            "text": "Права доступа",
            "id": "163",
            "show": true
            },
            {
            "text": "Справочники",
            "id": "164",
            "show": true
            },
            {
            "text": "Ведение контрактов",
            "id": "165",
            "show": true
            },
            {
            "text": "Нормативы",
            "id": "166",
            "show": false
            },
            {
            "text": "Внештатные ситуации",
            "id": "167",
            "show": false
            },
            {
            "text": "Технологические карты",
            "id": "168",
            "show": false,
            "submenu": [
                {
                "text": "Технологические карты",
                "id": "169",
                "show": true
                },
                {
                "text": "Назначение карт",
                "id": "170",
                "show": true
                }
            ]
            },
            {
            "text": "Контроль ТС",
            "id": "171",
            "show": true
            },
            {
            "text": "Планирование работ",
            "id": "172",
            "show": true
            },
            {
            "text": "Распределение работ СКПДИ",
            "id": "173",
            "show": false
            },
            {
            "text": "Задания (плиточное представление)",
            "id": "174",
            "show": true
            },
            {
            "text": "Путевые листы",
            "id": "175",
            "show": true
            },
            {
            "text": "Программа ремонта",
            "id": "176",
            "show": false
            },
            {
            "text": "Плановые задачи",
            "id": "177",
            "show": false
            },
            {
            "text": "Мониторинг ТС",
            "id": "178",
            "show": true
            },
            {
            "text": "Учет дорожной техники",
            "id": "179",
            "show": true
            },
            {
            "text": "Нарушения",
            "id": "180",
            "show": true
            },
            {
            "text": "Жалобы",
            "id": "227",
            "show": false
            }
        ]
        },
        {
        "text": "Коммунальная техника (ДТ)",
        "id": "343",
        "show": false,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "344",
            "show": true
            },
            {
            "text": "Права доступа",
            "id": "345",
            "show": true
            },
            {
            "text": "Справочники",
            "id": "346",
            "show": true
            },
            {
            "text": "Ведение контрактов",
            "id": "347",
            "show": true
            },
            {
            "text": "Нормативы",
            "id": "348",
            "show": true
            },
            {
            "text": "Внештатные ситуации",
            "id": "349",
            "show": true
            },
            {
            "text": "Технологические карты",
            "id": "350",
            "show": true,
            "submenu": [
                {
                "text": "Технологические карты",
                "id": "351",
                "show": true
                },
                {
                "text": "Назначение карт",
                "id": "352",
                "show": true
                }
            ]
            },
            {
            "text": "Контроль ТС",
            "id": "353",
            "show": true
            },
            {
            "text": "Планирование работ",
            "id": "354",
            "show": true
            },
            {
            "text": "Распределение работ СКПДИ",
            "id": "355",
            "show": true
            },
            {
            "text": "Задания (плиточное представление)",
            "id": "356",
            "show": true
            },
            {
            "text": "Путевые листы",
            "id": "357",
            "show": true
            },
            {
            "text": "Программа ремонта",
            "id": "358",
            "show": true
            },
            {
            "text": "Плановые задачи",
            "id": "359",
            "show": true
            },
            {
            "text": "Мониторинг ТС",
            "id": "360",
            "show": true
            },
            {
            "text": "Учет дорожной техники",
            "id": "361",
            "show": true
            },
            {
            "text": "Нарушения",
            "id": "362",
            "show": true
            }
        ]
        },
        {
        "text": "Коммунальная техника",
        "id": "181",
        "show": true,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "182",
            "show": true
            },
            {
            "text": "Права доступа",
            "id": "183",
            "show": true
            },
            {
            "text": "Справочники",
            "id": "184",
            "show": true
            },
            {
            "text": "Мониторинг ТС",
            "id": "185",
            "show": true
            },
            {
            "text": "Нарушения",
            "id": "341",
            "show": true
            }
        ]
        },
        {
        "text": "Управление перевозками детей",
        "id": "186",
        "show": true,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "187",
            "show": true
            },
            {
            "text": "Права доступа",
            "id": "188",
            "show": true
            },
            {
            "text": "Справочники",
            "id": "189",
            "show": true
            },
            {
            "text": "Паспорта маршрутов",
            "id": "190",
            "show": true
            },
            {
            "text": "Реестр маршрутов",
            "id": "191",
            "show": true
            },
            {
            "text": "Расписания",
            "id": "192",
            "show": true
            },
            {
            "text": "План-наряды",
            "id": "193",
            "show": true
            },
            {
            "text": "Контракты",
            "id": "194",
            "show": true
            },
            {
            "text": "Учет транспортной работы",
            "id": "195",
            "show": true
            },
            {
            "text": "Нарушения",
            "id": "196",
            "show": true
            },
            {
            "text": "Мониторинг ТС",
            "id": "197",
            "show": true
            }
        ]
        },
        {
        "text": "Контроль вывоза мусора",
        "id": "198",
        "show": false,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "199",
            "show": false
            },
            {
            "text": "Права доступа",
            "id": "200",
            "show": false
            },
            {
            "text": "Справочники",
            "id": "201",
            "show": false
            },
            {
            "text": "Контроль работы",
            "id": "202",
            "show": false
            },
            {
            "text": "Мониторинг ТС",
            "id": "203",
            "show": false
            },
            {
            "text": "Карта вывоза ТБО",
            "id": "204",
            "show": false
            },
            {
            "text": "Нарушения",
            "id": "227",
            "show": false
            }
        ]
        },
        {
            "text": "ГУ ГАТН",
            "id": "363",
            "show": false
        },
        {
        "text": "Контроль служебного транспорта",
        "id": "205",
        "show": true,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "206",
            "show": true
            },
            {
            "text": "Права доступа",
            "id": "207",
            "show": true
            },
            {
            "text": "Справочники",
            "id": "208",
            "show": true
            },
            {
            "text": "Нарушения",
            "id": "209",
            "show": true
            },
            {
            "text": "Контроль работы",
            "id": "210",
            "show": true
            },
            {
            "text": "Мониторинг ТС",
            "id": "211",
            "show": true
            }
        ]
        },
        {
        "text": "Мониторинг легковых такси",
        "id": "300",
        "show": true,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "301",
            "show": true
            },
            {
            "text": "Права доступа",
            "id": "302",
            "show": true
            },
            {
            "text": "Справочники",
            "id": "303",
            "show": true
            },
            {
            "text": "Нарушения",
            "id": "304",
            "show": true
            },
            {
            "text": "Контроль работы",
            "id": "305",
            "show": true
            },
            {
            "text": "Мониторинг ТС",
            "id": "306",
            "show": true
            }
        ]
        },
        {
        "text": "Транспорт сельского хозяйства",
        "id": "307",
        "show": true,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "308",
            "show": true
            },
            {
            "text": "Права доступа",
            "id": "309",
            "show": true
            },
            {
            "text": "Справочники",
            "id": "310",
            "show": true
            },
            {
            "text": "Нарушения",
            "id": "311",
            "show": true
            },
            {
            "text": "Контроль работы",
            "id": "312",
            "show": true
            },
            {
            "text": "Мониторинг ТС",
            "id": "313",
            "show": true
            }
        ]
        },
        {
        "text": "Контроль медицинского транспорта",
        "id": "314",
        "show": true,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "315",
            "show": true
            },
            {
            "text": "Права доступа",
            "id": "316",
            "show": true
            },
            {
            "text": "Справочники",
            "id": "317",
            "show": true
            },
            {
            "text": "Нарушения",
            "id": "318",
            "show": true
            },
            {
            "text": "Контроль работы",
            "id": "319",
            "show": true
            },
            {
            "text": "Мониторинг ТС",
            "id": "320",
            "show": true
            }
        ]
        },
        {
        "text": "Перевозки лесоматериалов",
        "id": "321",
        "show": true,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "322",
            "show": true
            },
            {
            "text": "Права доступа",
            "id": "323",
            "show": true
            },
            {
            "text": "Справочники",
            "id": "324",
            "show": true
            },
            {
            "text": "Нарушения",
            "id": "325",
            "show": true
            },
            {
            "text": "Контроль работы",
            "id": "326",
            "show": true
            },
            {
            "text": "Мониторинг ТС",
            "id": "327",
            "show": true
            }
        ]
        },
        {
        "text": "Перевозки опасных грузов",
        "id": "332",
        "show": true,
        "submenu": [
            {
            "text": "Кадровый учет",
            "id": "333",
            "show": true
            },
            {
            "text": "Права доступа",
            "id": "334",
            "show": true
            },
            {
            "text": "Справочники",
            "id": "335",
            "show": true
            },
            {
            "text": "Нарушения",
            "id": "336",
            "show": true
            },
            {
            "text": "Контроль работы",
            "id": "337",
            "show": true
            },
            {
            "text": "Мониторинг ТС",
            "id": "338",
            "show": true
            }
        ]
        },
        {
            "text": "Центр медицины и катастроф",
            "id": "365",
            "show": false,
            "submenu": [
                {
                    "text": "Кадровый учет",
                    "id": "366",
                    "show": true
                },
                {
                    "text": "Права доступа",
                    "id": "367",
                    "show": true
                },
                {
                    "text": "Справочники",
                    "id": "368",
                    "show": true
                },
                {
                    "text": "Нарушения",
                    "id": "369",
                    "show": true
                },
                {
                    "text": "Контроль работы",
                    "id": "370",
                    "show": true
                },
                {
                    "text": "Мониторинг ТС",
                    "id": "371",
                    "show": true
                }
            ]
        },
        {
            "text": "Лесное хозяйство",
            "id": "372",
            "show": false,
            "submenu": [
                {
                    "text": "Кадровый учет",
                    "id": "373",
                    "show": true
                },
                {
                    "text": "Права доступа",
                    "id": "374",
                    "show": true
                },
                {
                    "text": "Справочники",
                    "id": "375",
                    "show": true
                },
                {
                    "text": "Нарушения",
                    "id": "376",
                    "show": true
                },
                {
                    "text": "Контроль работы",
                    "id": "377",
                    "show": true
                },
                {
                    "text": "Мониторинг ТС",
                    "id": "378",
                    "show": true
                }
            ]
        },
        {
            "text": "Жилищно-коммунальное хозяйство",
            "id": "379",
            "show": false,
            "submenu": [
                {
                    "text": "Кадровый учет",
                    "id": "380",
                    "show": true
                },
                {
                    "text": "Права доступа",
                    "id": "381",
                    "show": true
                },
                {
                    "text": "Справочники",
                    "id": "382",
                    "show": true
                },
                {
                    "text": "Ведение контрактов",
                    "id": "383",
                    "show": true
                },
                {
                    "text": "Нормативы",
                    "id": "384",
                    "show": true
                },
                {
                    "text": "Внештатные ситуации",
                    "id": "385",
                    "show": true
                },
                {
                    "text": "Технологические карты",
                    "id": "386",
                    "show": true,
                    "submenu": [
                        {
                            "text": "Технологические карты",
                            "id": "387",
                            "show": true
                        },
                        {
                            "text": "Назначение карт",
                            "id": "388",
                            "show": true
                        }
                    ]
                },
                {
                    "text": "Контроль ТС",
                    "id": "389",
                    "show": true
                },
                {
                    "text": "Планирование работ",
                    "id": "390",
                    "show": true
                },
                {
                    "text": "Распределение работ СКПДИ",
                    "id": "391",
                    "show": true
                },
                {
                    "text": "Задания (плиточное представление)",
                    "id": "392",
                    "show": true
                },
                {
                    "text": "Путевые листы",
                    "id": "393",
                    "show": true
                },
                {
                    "text": "Программа ремонта",
                    "id": "394",
                    "show": true
                },
                {
                    "text": "Плановые задачи",
                    "id": "395",
                    "show": true
                },
                {
                    "text": "Мониторинг ТС",
                    "id": "396",
                    "show": true
                },
                {
                    "text": "Учет дорожной техники",
                    "id": "397",
                    "show": true
                },
                {
                    "text": "Нарушения",
                    "id": "398",
                    "show": true
                },
                {
                    "text": "Жалобы",
                    "id": "399",
                    "show": false
                }
            ]
        },
        {
            "text": "Сельское хозяйство",
            "id": "400",
            "show": false,
            "submenu": [
                {
                    "text": "Кадровый учет",
                    "id": "401",
                    "show": true
                },
                {
                    "text": "Права доступа",
                    "id": "402",
                    "show": true
                },
                {
                    "text": "Справочники",
                    "id": "403",
                    "show": true
                },
                {
                    "text": "Нарушения",
                    "id": "404",
                    "show": true
                },
                {
                    "text": "Контроль работы",
                    "id": "405",
                    "show": true
                },
                {
                    "text": "Мониторинг ТС",
                    "id": "406",
                    "show": true
                }
            ]
        },
        {
            "text": "Пожарно-спасательная служба",
            "id": "407",
            "show": false,
            "submenu": [
                {
                    "text": "Кадровый учет",
                    "id": "408",
                    "show": true
                },
                {
                    "text": "Права доступа",
                    "id": "409",
                    "show": true
                },
                {
                    "text": "Справочники",
                    "id": "410",
                    "show": true
                },
                {
                    "text": "Нарушения",
                    "id": "411",
                    "show": true
                },
                {
                    "text": "Контроль работы",
                    "id": "412",
                    "show": true
                },
                {
                    "text": "Мониторинг ТС",
                    "id": "413",
                    "show": true
                }
            ]
        },
        {
            "text": "Служебный транспорт",
            "id": "414",
            "show": false,
            "submenu": [
                {
                    "text": "Кадровый учет",
                    "id": "415",
                    "show": true
                },
                {
                    "text": "Права доступа",
                    "id": "416",
                    "show": true
                },
                {
                    "text": "Справочники",
                    "id": "417",
                    "show": true
                },
                {
                    "text": "Нарушения",
                    "id": "418",
                    "show": true
                },
                {
                    "text": "Контроль работы",
                    "id": "419",
                    "show": true
                },
                {
                    "text": "Мониторинг ТС",
                    "id": "420",
                    "show": true
                }
            ]
        },
        {
        "text": "Отчёты",
        "id": "212",
        "show": true
        },
        {
        "text": "Настройка событий",
        "id": "213",
        "show": true
        },
        {
        "text": "Оповещения",
        "id": "214",
        "show": true
        },
        {
        "text": "Прочее",
        "id": "215",
        "show": true,
        "submenu": [
            {
            "text": "Портал РНИС МО",
            "id": "216",
            "show": true,
            "submenu": [
                {
                "text": "Новости",
                "id": "217",
                "show": false
                },
                {
                "text": "Вопрос-ответ",
                "id": "218",
                "show": false
                },
                {
                "text": "Страницы",
                "id": "219",
                "show": false
                }
            ]
            },
            {
            "text": "МП",
            "id": "220",
            "show": false,
            "submenu": [
                {
                "text": "Контакты",
                "id": "221",
                "show": false
                },
                {
                "text": "Страницы",
                "id": "222",
                "show": false
                }
            ]
            },
            {
            "text": "Импорт ТС",
            "id": "223",
            "show": true
            },
            {
                "text": "Профиль организации",
                "id": "364",
                "show": true
            }
        ]
        },
        {
        "text": "Профиль",
        "id": "224",
        "show": true
        },
        {
        "text": "Справка",
        "id": "225",
        "show": false
        },
        {
        "text": "Выход",
        "id": "226",
        "show": true
        },
    ],
    "columns_acc_and_control_fuel_consumption": true,
    "reports_activity_arm_report": true,
    "columns_user_subsystems_column": false,
    "date_activation": true,
    "date_disactivation": true,
    "reports_completed_runs_on_scheduled_transportation_report": true,
    "active_ts_animation": false,
    "telematics_frequency": 10,
    "analytic_filter_for_municipality": false,
    "distance_in_history_player": false,
    "hide_pers_data": false,
    "import_ts_tab_name": "Импорт дорожной техники",
    "export_button_mta_name": "Экспорт остановок",
    "different_ts_select": true,
    "history_parking_show": false,
    "history_stops_show": false,
    "hide_columns_personnel_deviations_column": true,
    "unit_dictionary_cache_off": true,
    "hide_columns_personnel_groups_column": true,
    "hide_road_tasks_create_skpdi": false,
    "hide_pers_education": true,
    "hide_pers_tachographs": true,
    "hide_pers_graphic": true,
    "hide_pers_rtio": true,
    "waybill_batch": false,
    "task_with_time": false,
    "hide_confrim_mode": true,
    "skpdi_batch": false,
    "hide_road_vehicles_tab_technical": false,
    "hide_road_vehicles_tab_documents": false,
    "hide_road_vehicles_tab_service": false,
    "hide_road_vehicles_tab_details": false,
    "hide_road_vehicles_tab_tasks": false,
    "operator_title": "Оператор системы",
    "grantMO": false,
    "object_with_organizational_unit": false,
    "organizational_units_name": "Предприятия (РУАД)",
    "hide_weather": false,
    "hide_traffic": false,
    "hide_work_type_skpdi_column": false,
    "SHOWED_WORKS_CATEGORIES": [],
    "hide_pl": false,
    "rename_contracts": false,
    "show_column_unit_staffing": false,
    "show_column_unit_with_filter_bnso": true,
    "show_column_ts_with_filter_bnso": false,
    "show_column_bnso_with_filter_ts_dictionary": true,
    "isHTTP": false,
    "CONTROLWORK": false,
    "CONTRACT_GROUP": false,
    "graphic_with_periodicity": false,
    "mother_contract": false,
    "hide_subcontractors": false,
    "with_delegation": false,
    "is_need_complaints": false,
    "colored_plan": false,
    "cleanup_specialist_for_task": false,

    "GEOMETRY_TYPES": [
        "road_part",
        "road_repair_part",
        "stop_point",
        "idle",
        "other",
    ],
    "vehicle_subsystem": true,
    "movement_type_on_map_not_idle_default": false,
    "hide_user_map_map_objects_parameters": false,
    "hide_skpdi": false,
    "count_from_distance_or_object": false,
    "CITY_MURMANSK": false,
    "is_need_communal_municipality": true,
    "different_object_types_in_contract": false,
    "personnel_with_cleanup_specialist_filter": false,
    "esia": false,
    "hide_zone_violations": false,
    "hide_all_dictionaries_except_road": false,
    "CITY_OMSK": false,
    "modal_confirmation":  false,
    "cluster_size_coeff": 72,
    "shedule_stop_points_page": false,
    "shedule_stop_points_total_time_changing": false,
    "shedule_stop_points_page_interval": 30000,
    "shedule_modalFooter": false,
    "schedule_interval_deviations": false,
    "order_daily_optimization": false,
    "unique_ts_pagination": false,
    "sms_gateway": false,
    "show_vehicle_kurs_distance": false,
    "sort_route_schedule": true,
    "scroll_pagination_in_history_modal": true,
    "short_order_list": false,
    "tasks_day_multiple_filter_units": true,
    "road_map_filter_by_units_for_road_parts": false,
    "monitoring_work_history": true,
    "municipality_in_road_part": false,
    "unitsTree_childrenCount": false,
    "bnso_activity_time": false,
    "show_column_status_bnso": true,
    "bnso_dictionary_initial_unit": false,
    "road_skpdi_work_type_filters": true,
    "print_shedule_with_summary": true,
    "vehicle_editor_subsystem": false,
    "unit_color_picker": false,
    "dictionary_sbdd": true,
    "show_omsu": true,
    "work_type_filter_skpdi": false,
    "count_vehicle_by_norm_vehicle_MO": false,
    "mass_toggle_skdpi_tasks": false,
    "show_vehicle_numbers_rnis_tasks": false,
    "waybill_batch_close": false,
    "show_operator_export": true,
    "show_bnso_export": {
        "kiutr": {"show": true},
        "road": {"show": true},
        "sbdd": {"show": true},
        "ems": {"show": true},
        "communal": {"show": true},
        "garbage": {"show": true},
        "control": {"show": true},
        "children": {"show": true},
        "kiutr-control": {"show": true},
    },
    "show_addResourceDriver_field": false,
    "recalculation_notifications": false,
    "mass_road_parts_add": false,
    "temporarily_sbdd": false,
    "ISEDITABLECHILDRENSTOPS": true,
    "show_unique_counters": false,
    "hide_layer_objects": false,
    "show_municipality_task_map": false,
    "show_mass_history": false,
    "hide_spu_filter": true,
    "is_need_communal_municipality_in_road_dictionary": true,
    "installation_limitation": false,
    "installation_limitation_role_name": "Диспетчер[Пассажирские перевозки]",
    "installation_limitation_time": "9:00",
    "distance_in_history_player_grpc": false,
    "grpc_temperature": false,
    "cacheVehicle": {
      value: 60,
      param: 'm'
    },
    "vehicle_skipped": false,
    "reverseStopPointsTumbler": false,
    "resourcesGroupCheck": true,
    "road_skpdi_road_part_filters": false,
    "telematic_bounding_box": true,
    "show_delegate_button": false,
    "show_cycle_spread_button": false,
    "CITY_TOMSK": false,
    "histogramWidgetInterval": 600000,
    "maintenanceStatus": true,
    "SKPDI_title": "СКПДИ",
    "edit_length_routes_role_name":[],
    "arrowRepeat": '200px',
    "debugMode": false
}
