import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import PieChart from 'components/modules/analytics/base-components/charts/PieChart';
import WidgetSelector from 'components/modules/analytics/base-components/WidgetSelector';
import GlobalLoaderComponent from "components/ui/global-loader";
import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import _ from 'lodash';
import './style.less';

@propTypes({
    pieChartFillColor: PropTypes.string
})

@defaultProps({
    pieChartFillColor: ''
})

export default class VehicleDataWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isError: false,
            isLoading: false,
            data: {
                organizationUnits: [],
                drivers: []
            }
        };
    }

    componentWillMount() {
        this.loadData();
    }

    getUnits() {
        return api.organizational_units.getUnits({
            filters: {
                withComponent: 'dangerous',
            },
        });
    }

    async loadData() {
        this.setState({isError: false, isLoading: true});

        try {
            const response = await this.getUnits();

            const data = {
                organizationUnits: _.map(response.payload.items, (item) => _.pick(item, ['uuid', 'name'])),
                vehiclesData: [
                    {
                        title: 'Отдел 1',
                        data: [
                            {name: 'ТС в работе', value: 50, fill: '#82ca9d'},
                            {name: 'ТС в парке', value: 20, fill: '#8dd1e1'}
                        ]
                    },
                    {
                        title: 'Отдел 2',
                        data: [
                            {name: 'ТС в работе', value: 20, fill: '#82ca9d'},
                            {name: 'ТС в парке', value: 10, fill: '#8dd1e1'}
                        ]
                    }
                ]
            };

            this.setState({data, isError: false, isLoading: false});
        }
        catch (e) {
            this.setState({isError: true, isLoading: false});
            error(e).showErrors();
        }
    }

    renderContent() {
        return (
            <div className="full-size">
                <div className="select-wrapper">
                    <WidgetSelector title="Отделы" items={this.state.data.organizationUnits}/>
                </div>
                <div className="charts-wrapper">
                    {this.state.data.vehiclesData.map((item, index) =>
                        <div key={index} className="chart-wrapper">
                            <div className="chart-title">{item.title}</div>
                            <PieChart data={item.data}
                                      nameKey="name"
                                      dataKey="value"
                                      title={`${item.data[0].value}/${item.data[1].value}`}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const content = this.state.isError ? null : this.state.isLoading ? <GlobalLoaderComponent/> : this.renderContent();

        return (
            <div className="gatn-vehicle-data-widget">
                {content}
            </div>
        );
    }
}