import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import _ from 'lodash';

import IconButton from 'components/ui/icon-button';


import {tableExportCreate, tableExportGet, tableExportList} from "store/reducers/system";
import ContextTooltip from "components/ui/context-tooltip";
import Popup from "components/ui/popup";
import moment from "moment";
import formats from "dictionaries/formats";
import download from 'downloadjs'

@propTypes({
    source: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
})

@connect(state => ({}), {
    tableExportList,
    tableExportGet,
    tableExportCreate
}, null, {withRef: true})

export default class ExportTableButton extends Component {

    state = {
        exports: [],
        exportLogsVisible: false,
    };

    render() {
        return (
            <div>
                <ContextTooltip key="orders.export.list" code="orders.export.list" default="Файлы экспорта в Excel">
                    <IconButton icon="export" onClick={::this.exportLogsToggle}/>
                </ContextTooltip>
                {this.renderExportList()}
            </div>
        );
    }

    isLoading() {
        return this.state.isExporting;
    }

    async exportLogsToggle() {
        await this.setState({
            exportLogsVisible: !this.state.exportLogsVisible,
        });

        if (this.state.exportLogsVisible) {
            this.loadExports();
        }
    }

    async loadExports() {
        const response = await this.props.tableExportList({
            filters: {
                withEntity: this.props.entity,
            },
        });

        if (response.isOk) {
            this.setState({
                exports: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    renderExportList() {
        if (!this.state.exportLogsVisible) {
            return null;
        }

        return (
            <Popup
                className="SelectFieldsPopup top-link"
                show={true}
                onClose={::this.exportLogsToggle}>
                <div className="popup-container__content">
                    <div className="content">
                        {_.map(this.state.exports || [], ::this.renderExport)}
                        {(this.state.exports.length === 0) ? (
                            <div className="checkbox-labeled"><span>нет записей</span></div>
                        ) : null}
                    </div>
                </div>
            </Popup>
        );
    }

    renderExport(item) {
        if (item.content) {
            return (
                <div key={item.uuid} className="checkbox-labeled" onClick={this.exportDownload.bind(this, item.uuid)}>
                    <span>{moment(item.created_at).format(formats.DATETIME)} (готово)</span></div>
            );
        }

        return (
            <div key={item.uuid} className="checkbox-labeled"><span>{moment(item.created_at).format(formats.DATETIME)}
                (не готово)</span></div>
        );
    }

    async exportDownload(uuid) {
        const response = await this.props.tableExportGet(uuid);

        if (response.isOk) {
            if (response.payload.content) {
                const content = response.payload.content;
                download(`data:application/excel;base64,${content}`, `Экспорт ${moment().format(formats.DATETIME)}.xls`);
            }
        } else {
            response.showErrors();
        }
    }

    async exportToXls(meta, ex) {
        let entity = (ex && ex === 'vehicle_extended') ? 'vehicle_extended' : this.props.entity;
        const response = await this.props.tableExportCreate(this.props.source, entity, meta);
        if (response.isOk) {
            await this.setState({
                isExporting: true,
                exportingErrorCount: 0,
            });
            this.props.forceUpdate();
            setTimeout(this.checkExport.bind(this, response.payload.uuid), 5000);
        } else {
            response.showErrors();
        }
    }

    async checkExport(uuid) {
        const response = await this.props.tableExportGet(uuid);

        if (response.isOk) {
            if (response.payload.content) {
                await this.setState({
                    isExporting: false,
                });
                setTimeout(() => {
                    this.props.forceUpdate();
                }, 1000);
                const content = response.payload.content;
                download(`data:application/excel;base64,${content}`, `Экспорт ${moment().format(formats.DATETIME)}.xls`);
            } else {
                setTimeout(this.checkExport.bind(this, response.payload.uuid), 5000);
            }
        } else {
            this.setState({
                exportingErrorCount: this.state.exportingErrorCount + 1,
            });
            if (this.state.exportingErrorCount >= 5) {
                response.showErrors();
                await this.setState({
                    isExporting: false,
                });
                setTimeout(() => {
                    this.props.forceUpdate();
                }, 1000);
            } else {
                setTimeout(this.checkExport.bind(this, uuid), 5000);
            }
        }
    }
}
