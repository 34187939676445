import React, {Component} from "react";
import {connect} from "react-redux";

import GlobalLoader from "./ui/global-loader";
import {connectionEstablished, connectionLost, connectWsRpc} from "store/reducers/main";
import {checkToken} from "store/reducers/auth";
import "./App.less";
import * as bowser from "bowser";
import moment from "moment";
import {CycleFetch} from "helpers/api";
import {activityStore} from "store/reducers/system";
import Settings from "settings";
import {getOptions, optionsUpdated} from "store/reducers/settings";
import currentUser from 'helpers/current-user';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'react-virtualized/styles.css';
import {GlobalEvent} from "helpers/event-system";
import * as storage from "utils/storage";
import * as svg4everybody from "svg4everybody";

let subscribed = false;

@connect(state => ({
    isConnected: state.main.get('isConnected'),
    isAuthorized: state.auth.get('isAuthorized')
}), {connectWsRpc, checkToken, getOptions, optionsUpdated, activityStore, connectionLost, connectionEstablished})

export default class TabletApp extends Component {
    state = {
        websocketsActive: false,
        loaded: false,
    };

    _activityStoreCycle = null;
    _activityStoreCycleTimeout = 30000;

    componentWillMount() {
        svg4everybody();
        document.addEventListener('keydown', this._handleKeyPress, false);

        this.requireAuthorization(this.props);

        if (this.props.isAuthorized && !this.props.isConnected) {
            this.props.connectWsRpc();
        }

        if (!subscribed) {
            /*this.props.optionsUpdated((settings) => {
                Settings.set(settings);
            });*/
            subscribed = true;
        }

        GlobalEvent().subscribe('goto', (url) => {
            this.props.router.push(url);
        });
    }

    async componentDidMount() {
        this.listenWebsocketEvents();
        setTimeout(() => {
            this._activityStoreCycle = new CycleFetch(
                () => {
                    if (!this.props.isConnected) {
                        return null;
                    }
                    return this.props.activityStore(this.getActivityData());
                },
                () => {
                }, this._activityStoreCycleTimeout);

            this._activityStoreCycle.run();
        }, 10000);

        this.props.getOptions();
        await currentUser.init();
        this.setState({loaded: true});
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._handleKeyPress, false);

        if (this._activityStoreCycle) {
            this._activityStoreCycle.stop();
            delete this['_activityStoreCycle'];
        }
    }

    componentWillUpdate(props) {
        this.requireAuthorization(props);
    }

    _handleKeyPress(e) {
        if (e.code === 'Digit1' && e.ctrlKey === true) {
            alert(`Дата сборки ${process.env.BUILD_DATE}`);
            e.preventDefault();
        }
    }

    requireAuthorization(props) {
        if (!props.isAuthorized) {
            this.props.router.push('/tablet/auth');
        } else {
            this.props.checkToken();
        }
    }

    render() {
        if (this.props.isAuthorized) {
            if (this.props.isConnected && this.state.loaded) {
                return this.props.children;
            } else {
                return (<GlobalLoader />);
            }
        }

        return null;
    }

    getActivityData() {
        let os = 'Другое';
        if (bowser.mac) {
            os = `MacOS ${bowser.osversion}`;
        } else if (bowser.windows) {
            os = `Windows ${bowser.osversion}`;
        }

        const timezone = moment().utcOffset() / 60;

        return {
            path: window.location.pathname,
            data: {
                websockets: this.state.websocketsActive,
                os: os,
                browser: `${bowser.name} ${bowser.version}`,
                resolution: {
                    width: window.screen.availWidth,
                    height: window.screen.availHeight,
                },
                timezone: (timezone > 0) ? ('+' + timezone) : timezone,
            },
        };
    }

    listenWebsocketEvents() {
        this.props.connectionLost(() => {
            this.setState({websocketsActive: false});
        });

        this.props.connectionEstablished(() => {
            this.setState({websocketsActive: true});
        });
    }
}