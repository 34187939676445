import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import {connect} from "react-redux";
import moment from 'moment';
import formats from 'dictionaries/formats';
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Loader from 'components/ui/loader';
import ViolationTable from '../ViolationTable';
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getUser} from "store/reducers/staffing/staffing_editor";
import {getOrders} from "store/reducers/kiutr/orders/orders";
import Block from "components/ui/form/block";
import {getStopPoints} from "store/reducers/routes/routes";
import {getUsers} from "store/reducers/staffing/staffing";

@propTypes({
    data: PropTypes.object
})

@defaultProps({
    data: {}
})

@connect((state) => ({}), {
    getDictionaryList,
    getStopPoints,
    getVehicleList,
    getUsers,
    getOrders
}, null, {withRef: true})

export default class ViolationPointSkipClassForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableData: [],
            isLoading: true
        };

        this.rowHeight = 40;

        this.metadata = [
            {field: 'turn', name: 'Выход'},
            {field: 'shift', name: 'Смена'},
            {field: 'run', name: 'Рейс'},
            {field: 'vehicle', name: 'ТС'},
            {field: 'driver', name: 'Водитель'},
            {field: 'stop_point', name: 'Контрольный пункт'},
        ];

        this.violationsConfig = {};
    }

    componentWillMount() {
        this.loadData();
    }

    async loadDrivers() {
        const data = {};

        const uuids = this.props.data.items.map((item) => item.data['driver_uuid']);

        const response = await this.props.getUsers({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            response.payload.items.forEach((item) => {
                data[item.uuid] = item;
            });

            return data;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadVehicles() {
        const data = {};

        const uuids = this.props.data.items.map((item) => item.data['vehicle_uuid']);

        const response = await this.props.getVehicleList({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            response.payload.items.forEach((item) => {
                data[item.uuid] = item;
            });

            return data;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadStopPoints() {
        const data = {};

        const uuids = this.props.data.items.map((item) => item.data['stop_point_uuid']);

        const response = await this.props.getStopPoints({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            response.payload.items.forEach((item) => {
                data[item.uuid] = item;
            });

            return data;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    getDriverFullName(info) {
        let fullName = _.get(info, 'surname');
        const name = _.get(info, 'name');
        const secondName = _.get(info, 'second_name');

        if (name) {
            fullName += ' ' + name.charAt(0).toUpperCase() + '.';
        }

        if (secondName) {
            fullName += ' ' + secondName.charAt(0).toUpperCase() + '.';
        }

        return fullName;
    }

    async loadData() {
        this.setState({isLoading: true});

        const vehicles = await this.loadVehicles();
        const stopPoints = await this.loadStopPoints();
        const drivers = await this.loadDrivers();

        if (vehicles) {
            const data = [];

            this.props.data.items.forEach((item, index) => {
                this.violationsConfig[index] = {};

                const vehicleUuid = item.data.vehicle_uuid;
                const vehicle = vehicles[vehicleUuid];

                const driverUuid = item.data.driver_uuid;
                const driver = drivers[driverUuid];

                data.push({
                    turn: item.data.turn,
                    shift: item.data.shift,
                    run: item.data.run,
                    vehicle:
                        <span><span>{_.get(vehicle, 'state_number')}</span><br/><span>{_.get(vehicle, 'garage_number')}</span></span>,
                    driver: driver ? this.getDriverFullName(driver.info) : null,
                    stop_point: _.get(stopPoints, `${item.data.stop_point_uuid}.title`),
                });
            });

            this.setState({tableData: data, isLoading: false});
            return;
        }

        this.setState({isLoading: false});
    }

    render() {
        const {date, contractUnitName, routeNumber, routeTitle} = this.props.data;

        return (
            <div className="b-modal__block">
                <Block>
                    <div className="violation-info">
                        <span className="bold">Дата: </span>
                        {date}
                    </div>
                    <div className="violation-info">
                        <span className="bold">Нарушений: </span>
                        {this.props.data.items.length}
                    </div>
                </Block>
                <Block size="xl">
                    <div className="violation-info">
                        <span className="bold">Маршрут: </span>
                        №{routeNumber} ({routeTitle})
                    </div>
                    <div className="violation-info">
                        <span className="bold">Транспортное предприятие: </span>
                        {contractUnitName}
                    </div>
                </Block>

                <div className="violation_table__content">
                    {this.state.isLoading ? <Loader color="red" size={32}/> :
                        <ViolationTable data={this.state.tableData} metadata={this.metadata}
                                        violationsConfig={this.violationsConfig} rowHeight={50}/>}
                </div>
            </div>
        );
    }
}