import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import Page from '../../ui/page';

import './not-found.less';


export default class NotFoundComponent extends Component {

    state = {};

    componentWillMount() {

    }

    componentWillUnmount() {
    }

    render() {
        return (
            <Page pageId="NotFound" title="Страница не найдена">
                <div>
                    404 Страница не найдена
                </div>
            </Page>
        );
    }

}