import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import classNames from 'classnames';

import currentUser from 'helpers/current-user';

import * as alerts from "helpers/alerts";

import '../../modules/tablet/_style/style.less';
import {Link} from "react-router";
import {logout} from "store/reducers/auth";
import {connect} from "react-redux";
import _ from "lodash";
import {getUnit} from "store/reducers/organizational_units/units";
import {getHostName} from "../../../helpers/wsrpc";

@propTypes({
    items: PropTypes.array,
    expanded: PropTypes.bool,
})

@defaultProps({})

@connect(() => ({}), {logout})

export default class TabletMenu extends Component {

    render() {
        return (
            <div className={classNames('m-menu', this.props.expanded ? 'expand' : '')} id="m-menu">
                <div className="m-menu__inner">

                    {window.RNIS_SETTINGS.LOGOIMAGE ?
                        (<a className="image-logo" href="/tablet">
                            <img src={window.RNIS_SETTINGS.LOGOIMAGE} alt="" className="logo_tablet"/>
                        </a>) : null}

                    <ul className="m-menu__list m-menu__list_bb">
                        {this.props.items.map(::this.renderItem)}
                    </ul>
                    <div className="m-menu__auth">
                        <div className="title-text">
                            <div className="title-text__value">Вы авторизованы как:</div>
                            <div className="title-text__dscn">{currentUser.name}</div>
                        </div>
                        <button className="btn btn_white btn_exit" onClick={::this.logout}>Выйти</button>
                    </div>
                </div>
            </div>
        );
    }

    renderItem(item, index) {
        return (
            <li key={index} className="m-menu__item">
                <Link className={classNames('m-menu__link', item.superclass, item.disabled ? 'disabled' : '')} activeClassName="active" to={item.route}>
                    {item.text}
                    <svg className={classNames('icon', `icon_${item.class}`, 'icon_red')}>
                        <use xlinkHref={`/img/spriteSVG.svg#icon_${item.class}`}/>
                    </svg>
                </Link>
            </li>
        );
    }

    logout() {
        this.props.logout();
    }

}