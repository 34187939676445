import React, {Component} from 'react';
import Page from 'components/ui/page';

import RegularityMatrixContainer from "./RegularityMatrixContainer";

export default class RegularityMatrixPage extends Component {
    render() {
        return (
            <Page pageId="RegularityMatrixPage"
                  title="Матрица регулярности рейсов">
                <RegularityMatrixContainer {...this.props}/>
            </Page>
        );
    }
}
