import {Map} from "immutable";
import {api, CycleFetch} from "helpers/api";
import _ from "lodash";
import {success, error} from 'helpers/response';

const ENABLE_EDIT = 'ENABLE_EDIT';
const DISABLE_EDIT = 'DISABLE_EDIT';

const initialState = new Map({
    active: false,
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ENABLE_EDIT:
            return state.set('active', true);
        case DISABLE_EDIT:
            return state.set('active', false);
    }
    return state;
}

export const enableTooltipEdit = () => async (dispatch) => {
    dispatch({
        type: ENABLE_EDIT,
        payload: {}
    });
};

export const disableTooltipEdit = () => async (dispatch) => {
    dispatch({
        type: DISABLE_EDIT,
        payload: {}
    });
};