import React, {Component} from "react";
import {connect} from "react-redux";
import {checkServiceStatuses, log, serviceStatusesChecked, getProjectsVersion} from "store/reducers/system";
import {connectionEstablished, connectionLost} from "store/reducers/main";
import ReactTooltip from "react-tooltip";
import Settings from 'settings';

import "./system_status.less";

let subscribed = false;
let connected = true;
let status = true;
let speed = '100 [Mb/s]';
let speed_status = 'OK';

@connect(state => ({
    services: state.system.get('services') || [],
    versions: state.system.get('versions') || []
}), {checkServiceStatuses, serviceStatusesChecked, connectionLost, connectionEstablished, log, getProjectsVersion})

export default class SystemStatus extends Component {

    componentDidMount() {
        if (!subscribed) {
            this.props.connectionLost(() => {
                connected = false;
            });

            this.props.connectionEstablished(() => {
                connected = true;
            });

            this.checkStatusInterval = setInterval(() => this.checkStatus(), App.pinger.interval);
            this.checkSpeedInterval = setInterval(() => {
                    this.checkConnectionSpeed()
                },
                Settings.get('connection_check_interval', App.connection.interval) * 60 * 1000
            );

            subscribed = true;
        }

        this.checkStatus();
        this.props.getProjectsVersion();
    }

    componentWillUnmount() {
      clearInterval(this.checkStatusInterval);
      clearInterval(this.checkSpeedInterval);
    }

    /**
     *  Status Pinger
     */
    checkStatus() {
        if (connected) {
            this.props.checkServiceStatuses();
        }

        if (!connected) {
            status = false;
        } else if (this.getFailedServices().length > 0) {
            status = false;
        } else {
            status = true;
        }
        this.forceUpdate();
    }

    getFailedServices() {
        return _.filter(this.props.services, (service) => {
            return service.status != 'ok';
        });
    }

    render() {
        const color = status ? "green" : "red";
        let a = window.RNIS_SETTINGS.HIDERNISSYSTEMSTATUS;
        return (
            a === false || a === undefined || a === "undefined" ? (
                <div className="pinger">
                    <div data-tip data-for="serviceStatuses">
                        <div className={`pinger__service pinger__${color}`}/>
                    </div>

                    {this.renderTooltip()}
                </div>
            ) : null

        );
    }

    renderTooltip() {
        let speedtest = `Скорость соединения [${speed_status}]: ${speed}`;
        let message = "Подключение установлено.";
        let color = "info";

        if (!connected) {
            color = 'error';
            message = "Соединение с сервером потеряно.";
        } else if (!status) {
            color = 'error';
            message = this.getFailedServices().map(item => this.renderItem(item));
        } else if (speed_status == 'LOW') {
            color = 'warning';
        }

        return (
            <ReactTooltip id="serviceStatuses" place="bottom" type={color} effect="solid">
                <div>{message}</div>
                <div>{speedtest}</div>
                <div
                    className="projects-version">{this.props.versions.map(project => this.renderProject(project))}</div>
            </ReactTooltip>
        );
    }

    renderProject(project) {
        return (
            <div key={project.project} className="project__row">
                <span className="project__name">{project.project}:</span> <strong>{project.version}</strong>
            </div>
        )
    }

    renderItem(item) {
        return (
            <div key={item.service} className="pinger__row">
                {item.service}: {item.status}
            </div>
        )
    }

    /**
     * Connection test
     */
    async showResult(speed) {
        speed = speed.Mbps + ' [Mb/s]';
        speed_status = speed.Mbps >= App.connection.min_speed ? 'OK' : 'LOW';

        // Log connection inforamtion
        const response = await this.props.log('speed_test', speed.Mbps + ' [Mb/s]');

        if (!response.isOk) {
            console.warn('Error with storing test to log', response.errors)
        }
    }

    /**
     * @param {function} callback
     */
    checkConnectionSpeed(callback) {
        let fileSize = App.connection.file.size;

        let img = document.createElement('img');

        let start = (new Date()).getTime();

        img.onload = () => {
            let end = (new Date()).getTime();
            this.showResult(
                this.calculateSpeed(start, end, fileSize)
            )
        };

        img.onerror = () => {
            this.showResult(
                this.calculateSpeed(0, 0, fileSize)
            )
        };

        img.src = App.connection.file.url + '?' + Math.random();
    }

    /**
     * @param {int} start
     * @param {int} end
     * @param {int} filesize
     * @return {{Bps: string, Kbps: string, Mbps: string}}
     */
    calculateSpeed(start, end, filesize) {
        let duration = (end - start) / 1000;
        let bitsLoaded = filesize * 8;
        let speedBps = (bitsLoaded / duration).toFixed(0);
        let speedKbps = (speedBps / 1024).toFixed(0);
        let speedMbps = (speedKbps / 1024).toFixed(0);

        return {
            Bps: speedBps,
            Kbps: speedKbps,
            Mbps: speedMbps
        };
    }
}
