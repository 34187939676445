import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/button';
import GlobalLoader from 'components/ui/global-loader';
import {Link} from 'react-router';

import {connect} from 'react-redux';
import {getMaintenanceStatus, login} from 'store/reducers/auth';
import {connectWsRpc} from 'store/reducers/main';
import {api} from "../../../helpers/api";
import {getUser, updateUser} from "store/reducers/staffing/staffing_editor";

import Errors from 'dictionaries/errors';

import './registration.less';
import Input from "components/ui/input";
import moment from "moment";
import formats from "dictionaries/formats";
import classNames from 'classnames';
import * as storage from "utils/storage";
import {getOptions, setUserOptionValue} from "../../../store/reducers/settings";
import {getUnits} from "../../../store/reducers/organizational_units/units";
import {get} from "lodash";
import currentUser from "../../../helpers/current-user";
import {rpc} from "../../../helpers/api/session";
import {error, success} from "../../../helpers/response";
import {logout} from 'store/reducers/auth';

@connect(state => ({}), {getOptions, setUserOptionValue, getUser, updateUser, getUnits , logout})

export default class RegistrationComponent extends Component {

    state = {
        loading: true,
        passwordControl: '',
        password: '',
        token: '',
        error: null,
        user: null,

        maintenance: {
            status: false,
            message: '',
        },
    };

    async componentDidMount() {
        let match = /activationCode=(.*)/.exec(window.location.search.substr(1));
        if (match) {
            try {
                await this.props.logout();
                const response = await rpc.request('com.rnis.auth.action.user.get', {}, {token: match[1]});
                this.setState({
                    loading: false,
                    token: match[1],
                    user: response.payload
                });
            } catch (e) {
                console.log(e)
                this.props.router.push('/auth');
            }
        }
    }

    onChangePassword({target: {value}}) {
        this.setState({error: null, password: value});
    }

    onChangePasswordControl({target: {value}}) {
        this.setState({error: null, passwordControl: value});
    }

    async login() {
        let tempUser = {...this.state.user};
        tempUser.password = this.state.password;
        tempUser.confirm_password = this.state.password;
        tempUser.current_password = '';
       // delete tempUser.uuid;
        await this.setState({user: tempUser});
       // console.log(this.state.user)
        try {
            const response = await rpc.request('com.rnis.auth.action.user.update', this.state.user, {token: this.state.token});
            this.props.router.push('/auth');
        } catch (e) {
            this.setState({error: e.errors[0].text});
        }
    }

    render() {
        const loader = (this.state.loading) ? (<GlobalLoader/>) : '';
        return (
            <div className="Login">
                {loader}
                {!window.RNIS_SETTINGS.LOGOIMAGE ? (<Link to="/" className={classNames('b-logo', {
                    t1: window.location.hostname.indexOf('mosreg') === -1,
                })}/>) : (<a className="image-logo" href="/">
                    <img src={window.RNIS_SETTINGS.LOGOIMAGE} alt=""/>
                </a>)}

                <div className="Login__title">
                    Регистрация в системе
                </div>
                {this.state.maintenance.status ? (
                    <div>{this.state.maintenance.message} до {moment(this.state.maintenance.will_be_available_at).format(formats.DATETIME_SHORT)}</div>
                ) : null}
                <form action="#" className="login-form">
                    <div className="password-input">
                        <Input
                            placeholder="Пароль"
                            type="password"
                            onChange={::this.onChangePassword}
                            value={this.state.password}
                        />
                    </div>
                    <div className="password-input">
                        <Input
                            placeholder="Пароль еще раз"
                            type="password"
                            onChange={::this.onChangePasswordControl}
                            value={this.state.passwordControl}
                            onEnter={::this.login}
                        />
                    </div>
                    <div className="error">{this.state.error}
                    </div>

                    <Button
                        text="Отправить"
                        size="lg"
                        disabled={!(this.state.password && this.state.passwordControl && this.state.password === this.state.passwordControl) }
                        onClick={::this.login}
                    />
                </form>
            </div>
        );
    }
}
