import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getTechnocardAssigns = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.kurs.getTechnocardAssigns(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTechnocardAssign = (uuid) => async (dispatch) => {
    try {
        const response = await api.kurs.getTechnocardAssign(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createTechnocardAssign = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.createTechnocardAssign(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateTechnocardAssign = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.updateTechnocardAssign(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteTechnocardAssign = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.deleteTechnocardAssign(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};