import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import './history-modal.less';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import PageModal from 'components/ui/page-modal';
import TableContainer from "components/ui/Table/Container/TableContainer";
import moment from "moment";
import formats from "dictionaries/formats";
import {getBnsoList} from "store/reducers/vehicles/bnso";
import {connect} from "react-redux";
import Tooltip from 'react-tooltip-component';
import Block from "components/ui/form/block";
import SelectFilter from "components/ui/select-filter";
import {getUsers} from "store/reducers/staffing/staffing";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import runTypes from "dictionaries/runs";
import classNames from 'classnames';
import {getRouteVariant} from "store/reducers/routes/route_variants";
import {Link} from "react-router";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {Slider} from "components/ui/slider";
import Checkbox from "components/ui/form/checkbox";

@propTypes({})

export default class VehiclePopupConfigModal extends Component {

    state = {};

    blocks = [
        {
            label: 'Индикация состояния',
            items: {
                'speed': 'Скорость',
                'bnso_number': 'Номер БНСО',
                'bnso_model': 'Модель БНСО',
                'overspeed': 'Превышение',
                "temperature": "Температура",
                'base': 'Базовые датчики',
                'extended': 'Индивидуальные датчики',
            },
        },
        {
            label: 'Характеристики',
            items: {
                'model': 'Модель',
                'type': 'Тип',
                'environment': 'Экологический класс',
                'capacity': this.isKiutrOrChildren() ? 'Класс вместимости' : null,
                'garage_number': 'Гаражный номер',
                'state_number': 'Государственный номер',
            },
        },
        {
            label: 'Структурная принадлежность',
            items: {
                'unit': 'Предприятие',
                'parent_unit': 'Головное предприятие',
                //'to': 'Территориальная принадлежность',
            },
        },
        {
            label: 'Работы',
            items: {
                'driver': 'Водитель',
                //'diff': 'Отклонение в рейсе',
                'order': this.isKiutrOrChildren() ? 'Наряд' : null,
                'route': 'Маршрут/задание',
                'run_direction': this.isKiutrOrChildren() ? 'Направление рейса' : null,
                'done': this.isKiutrOrChildren() ? 'Выполнение рейса' : null,
                'shift': this.isKiutrOrChildren() ? 'Смена' : null,
                'done_road': this.isKurs() ? 'Выполнение задания' : null,
            },
        },
    ];

    isKiutrOrChildren() {
        return _.indexOf([
            'kiutr',
            'children',
            'ems',
        ], this.props.component) !== -1;
    }

    isKurs() {
        return this.props.component === 'road';
    }

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{
                    rawTitle: (
                        <div className="title">
                            <div className="title__name">Настройка содержимого попапа ТС</div>
                        </div>
                    ),
                    buttons,
                }}
                className={`b-modal-trip-planner-result b-modal-trip-planner-result-panel specifications_tool b-modal_w-footer`}
                withFade={false}
            >
                <Accordion>
                    <AccordionItem title="Попап">
                        <Accordion>
                            {_.map(this.blocks, this.renderBlock.bind(this, 'popup'))}
                        </Accordion>
                    </AccordionItem>
                    <AccordionItem title="Хинт">
                        <Accordion>
                            {_.map(this.blocks, this.renderBlock.bind(this, 'hint'))}
                        </Accordion>
                    </AccordionItem>
                    <AccordionItem title="Постоянный хинт">
                        <Accordion>
                            {_.map(this.blocks, this.renderBlock.bind(this, 'permanent'))}
                        </Accordion>
                    </AccordionItem>
                </Accordion>
            </PageModal>
        )
    }

    renderBlock(type, block, index) {
        const items = this.getItems(block, type);

        const selected = _.filter(_.keys(items), (item) => {
            return this.props.data[item];
        }).length;

        return (
            <AccordionItem key={index}
                           opened={true}
                           collapsable={false}
                           label={(
                               <div className="transport_info">
                                   <Slider
                                       selected={selected}
                                       total={_.keys(items).length}
                                       selectAll={this.onBlockSelect.bind(this, block, type)}
                                       deselectAll={this.onBlockDeselect.bind(this, block, type)}
                                   />

                                   <span className="tool_title">{block.label}</span>
                               </div>
                           )}>
                {_.map(items, ::this.renderBlockItem)}
            </AccordionItem>
        );
    }

    renderBlockItem(label, item) {
        return (
            <div key={item} className="transport_row">
                <Checkbox checked={this.props.data[item]} onChange={this.onChange.bind(this, item)} label={label}/>
            </div>
        );
    }

    onBlockSelect(block, type) {
        this.props.onChange(_.filter(_.keys(this.getItems(block, type)), (item) => {
            return !this.props.data[item];
        }));
    }

    onBlockDeselect(block, type) {
        this.props.onChange(_.filter(_.keys(this.getItems(block, type)), (item) => {
            return this.props.data[item];
        }));
    }

    getItems(block, type) {
        let items = {};
        _.each(block.items, (label, key) => {
            if (label) {
                if ((type === 'permanent') && (_.indexOf([
                        'base',
                        'extended',
                    ], key) !== -1)) {
                    return;
                }

                items[this.getKey(key, type)] = label;
            }
        });
        return items;
    }

    getKey(key, type) {
        switch (type) {
            case 'popup':
                return key;
            case 'hint':
                return key + '_tp';
            case 'permanent':
                return key + '_ptp';
        }

        return key;
    }

    onChange(item) {
        this.props.onChange([item]);
    }

}
