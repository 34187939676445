import React from 'react';
import {Route, IndexRoute} from 'react-router'
import AppComponent from './App';
import Main from './Main';
import Login from './modules/auth/login';
import Registration from './modules/registration/registration';
import Staffing from './modules/staffing/staffing';
import Notifications from './modules/notifications/list';
import Settings from './modules/system/settings';
import DictionaryStructure from './modules/dictionaries/structure';
import Dictionary from './modules/dictionaries/dictionary';
import Reports from './modules/reports/reports';
import Maps from './modules/maps/maps';
import UserMapObjects from "components/modules/user-map-objects/user-map-objects";
import NotFoundComponent from "components/modules/not-found/not-found";
import ActivityComponent from "components/modules/system/activity/activity";
import UserLogsComponent from "components/modules/system/user-logs/user-logs";
import AuditComponent from "components/modules/system/audit/audit";
import BNSOComponent from "components/modules/vehicles/bnso/bnso";
import VehiclesComponent from "components/modules/vehicles/vehicles/vehicles";
import TrashLogsComponent from "components/modules/system/trash-logs/trash-logs";
import RolesComponent from "components/modules/system/roles/roles";
import UnitPermissionsComponent from "components/modules/system/unit-permissions/unit-permissions";
import UnitsTree from "components/modules/organizational-units/units/units_tree";
import UnitsTable from "components/modules/organizational-units/units/units_table";
import StopPointsComponent from "components/modules/geo/stop-points/stop-points";
import PortalNewsComponent from "components/modules/portal/news/news";
import PortalFaqComponent from "components/modules/portal/faq/faq";
import PortalPagesComponent from "components/modules/portal/pages/pages";
import KiutrRoutesComponent from "components/modules/kiutr/routes/routes";
import KiutrRoutesGrantComponent from "components/modules/kiutr/routes-grant/routes";
import DtpMapsComponent from "components/modules/dtp/maps";
import CarriersComponent from "components/modules/organizational-units/carriers/carriers";
import KiutrRouteScheduleViewComponent from "components/modules/kiutr/routes/schedule/view";
import KiutrRouteScheduleViewGrantComponent from "components/modules/kiutr/routes-grant/schedule/view";
import KiutrRouteScheduleTurnComponent from "components/modules/kiutr/routes/schedule/turn";
import KiutrRouteScheduleTurnGrantComponent from "components/modules/kiutr/routes-grant/schedule/turn";
import KiutrContractsComponent from "components/modules/kiutr/contracts/contracts";
import KiutrRouteRegistriesComponent from "components/modules/kiutr/route_registries/route_registries";
import OperatorBNSOCheckComponent from "components/modules/operator/bnso_check/bnso_check";
import OperatorBNSOSmsComponent from "components/modules/operator/bnso_sms/bnso_sms";
import OperatorBnsoCheckMapComponent from "components/modules/operator/bnso_check/map";
import OperatorBnsoSmsReportComponent from "components/modules/operator/bnso_report/bnso_report";
import NotificationEventsComponent from "components/modules/notifications/events/notification_events";
import GatnViolationsComponent from "components/modules/gatn/gatn_violations/gatn_violations";
import AgricultureViolationsComponent from "components/modules/agriculture/agriculture_violations/agriculture_violations";
import TaxiViolationsComponent from "components/modules/taxi/taxi_violations/taxi_violations";
import MedicViolationsComponent from "components/modules/medic/medic_violations/medic_violations";
import TimberViolationsComponent from "components/modules/timber/timber_violations/timber_violations";
import KiutrRouteScheduleGraphicViewComponent from "components/modules/kiutr/routes/schedule/graphic/graphic";
import KiutrRouteScheduleGraphicViewGrantComponent from "components/modules/kiutr/routes-grant/schedule/graphic/graphic";
import MapsHistoryComponent from "components/modules/maps/history/maps";
import KiutrSchedulesComponent from "components/modules/kiutr/schedules/schedules";
import KiutrSchedulesGrantComponent from "components/modules/kiutr/schedules-grant/schedules";
import KiutrRouteScheduleAdditionalTurnComponent from "components/modules/kiutr/orders/turn/turn";
import MobilePagesComponent from "components/modules/mobile/mobile_pages/pages";
import MobileContactsComponent from "components/modules/mobile/contacts/pages";
import ProfileComponent from "components/modules/profile/profile";
import KiutrTransportWork from 'components/modules/kiutr/transport-work';
import KursContractsComponent from "components/modules/kurs/contracts/list";
import KursContractWorksComponent from "components/modules/kurs/contract_works/list";
import VehiclesImportComponent from "components/modules/vehicles/import/import";
import KiutrRouteScheduleEllipseViewComponent from "components/modules/kiutr/routes/schedule/ellipse/ellipse";
import KiutrRouteScheduleEllipseViewGrantComponent from "components/modules/kiutr/routes-grant/schedule/ellipse/ellipse";
import KursVehicleListComponent from "components/modules/kurs/vehicles/index";
import KursVehicleEditorForm from "components/modules/kurs/vehicles/vehicle-editor-form/index";
import KursMaintenancesComponent from "components/modules/kurs/maintenances/list";

import CommdeptContractsComponent from "components/modules/commdept/contracts/list";
import CommdeptContractEditor from "components/modules/commdept/contracts/editor";
import CommdeptStosComponent from "components/modules/commdept/stos/list";
import CommdeptRoadStoEditor from "components/modules/commdept/stos/editor";
import CommdeptSupernumerarySituationsComponent from "components/modules/commdept/supernumerary_situations/index";
import CommdeptTechnocardsComponent from "components/modules/commdept/technocards/index";
import CommdeptTechnocardEditor from "components/modules/commdept/technocards/editor";
import CommdeptTechnocardAssignsComponent from "components/modules/commdept/technocard_assigns/index";
import CommdeptVehiclesControl from "components/modules/commdept/vehicles_control/index";
import CommdeptTasksComponent from "components/modules/commdept/tasks/index";
import CommdeptTaskEditor from "components/modules/commdept/tasks/editor";
import CommdeptSkpdiAssign from "components/modules/commdept/SkpdiAssign/KursSkpdiAssign";
import CommdeptTasksDayComponent from "components/modules/commdept/tasks_day/index";
import CommdeptWaybillsComponent from "components/modules/commdept/waybills/index";
import CommdeptWaybillEditor from "components/modules/commdept/waybills/WaybillEditor/index";
import CommdeptRepairProgramComponent from "components/modules/commdept/repair_program/index";
import CommdeptVehicleListComponent from "components/modules/commdept/vehicles/index";
import CommdeptVehicleEditorForm from "components/modules/commdept/vehicles/vehicle-editor-form/index";
import CommdeptRoadsComponent from "components/modules/commdept/roads/index";
import CommdeptRoadEditor from "components/modules/commdept/roads/RoadEditor/index";
import CommdeptRoadPartsComponent from "components/modules/commdept/roads/RoadParts/index";
import CommdeptTaskTemplatesComponent from "components/modules/commdept/task_templates/index";
import CommdeptContractWorksComponent from "components/modules/commdept/contract_works/list";
import CommdeptMaintenancesComponent from "components/modules/commdept/maintenances/list";
import CommdeptPlanDailyComponent from "components/modules/commdept/plan/PlanDaily/index";

import AnalyticsPassengerTransportation from 'components/modules/analytics/passenger-transportation';
import AnalyticGrantPassengerTransportationUnits from 'components/modules/analytics/grantmo/passenger';
import AnalyticGrantMunicipality from 'components/modules/analytics/grantmo/municipality';
import AnalyticsChildrenTransportation from 'components/modules/analytics/children-transportation';
import AnalyticsRoadMaintenance from 'components/modules/analytics/road-maintenance';
import AnalyticsWaste from 'components/modules/analytics/waste';
import AnalyticsGatn from 'components/modules/analytics/gatn';
import AnalyticsOfficial from 'components/modules/analytics/official';
import AnalyticsMedicine from 'components/modules/analytics/medicine';
import AnalyticsForestry from 'components/modules/analytics/forestry';
import AnalyticsFarming from 'components/modules/analytics/farming';
import AnalyticsEmercom from "components/modules/analytics/emercom/index";
import AnalyticsMap from 'components/modules/analytics/map';
import KursStosComponent from "components/modules/kurs/stos/list";
import NotificationRulesComponent from "components/modules/notifications/rules/index";
import NotificationsViewComponent from "components/modules/notifications/view/index";
import NotificationUserSettingsComponent from "components/modules/notifications/user-settings/index";
import KiutrOrderListComponent from "components/modules/kiutr/orders/index";
import HomeComponent from "components/modules/home/home";
import KursTasksComponent from "components/modules/kurs/tasks/index";
import KursTaskTemplatesComponent from "components/modules/kurs/task_templates/index";
import KurdContractEditor from "components/modules/kurs/contracts/editor";
import HelpComponent from "components/modules/help/index";
import RegisterRequestsComponent from "components/modules/operator/register_requests/index";
import KursRoadsComponent from "components/modules/kurs/roads/index";
import KursRoadEditor from "components/modules/kurs/roads/RoadEditor/index";
import KursWaybillsComponent from "components/modules/kurs/waybills/index";
import KursWaybillEditor from "components/modules/kurs/waybills/WaybillEditor/index";
import WaybillBatchClose from "components/modules/kurs/waybills/WaybillBatchClose/index";
import KursRepairProgramComponent from "components/modules/kurs/repair_program/index";
import KursPlanDailyComponent from "components/modules/kurs/plan/PlanDaily/index";
import KursColoredPlanDailyComponent from "components/modules/kurs/plan/PlanDaily/ColoredPlanDaily";
import GarbageViolationsComponent from "components/modules/garbage/violations/index";
import GarbageMapComponent from "components/modules/garbage/garbagemap/index";
import UnitProfileComponent from "components/modules/UnitProfile/index";
import UnitProfileVehiclesComponent from "components/modules/UnitProfile/UnitProfileVehicles/index";
import ConfirmRequestsComponent from "components/modules/operator/confirm_requests/index";
import EsiaRequestsComponent from "components/modules/operator/esia_requests/index";
import KursRoadStoEditor from "components/modules/kurs/stos/editor";
import KursMapEditor from "components/modules/kurs/tasks/KursTaskMapEditor/index";
import MapFullComponent from "components/modules/maps/MapFull/index";
import KursTaskEditor from "components/modules/kurs/tasks/editor";
import KursTaskEditorSmut from "components/modules/kurs/tasks/editor_smut";
import SupernumerarySituationsComponent from "components/modules/kurs/supernumerary_situations/index";
import KursRoadPartsComponent from "components/modules/kurs/roads/RoadParts/index";
import KursVehiclesControl from "components/modules/kurs/vehicles_control/index";
import GarbageObjectsComponent from "components/modules/garbage/objects/index";
import GatnWorkComponent from "components/modules/gatn/gatn_work/index";
import TaxiWorkComponent from "components/modules/taxi/taxi_work/index";
import MedicWorkComponent from "components/modules/medic/medic_work/index";
import AgricultureWorkComponent from "components/modules/agriculture/agriculture_work/index";
import TabletLogin from "components/modules/tablet/auth/login";
import TabletApp from "components/TabletApp";
import TabletHome from "components/modules/tablet/home/index";
import TabletKiutr from "components/modules/tablet/kiutr/index";
import TabletChildren from "components/modules/tablet/children/index";
import TabletKurs from "components/modules/tablet/kurs/index";
import TabletUnit from "components/modules/tablet/unit/index";
import AnalyticsDashboard from "components/modules/analytics/dashboard/index";
import KursAnalytics from "components/modules/analytics/kurs/index";
import KursRoadPartsMap from "components/modules/reports/Maps/KursRoadPartsMap/index";
import KursTasksDayComponent from "components/modules/kurs/tasks_day/index";
import KursTechnocardsComponent from "components/modules/kurs/technocards/index";
import TabletGatn from "components/modules/tablet/gatn/index";
import TabletGarbage from "components/modules/tablet/garbage/index";
import AnalyticsGarbage from "components/modules/analytics/garbage/index";
import AnalyticsTaxi from "components/modules/analytics/taxi/index";
import AnalyticsAgricultural from "components/modules/analytics/agricultural/index";
import AnalyticsTimber from "components/modules/analytics/timber/index";
import AnalyticsMedic from "components/modules/analytics/medic/index";
import AnalyticsDangerous from "components/modules/analytics/dangerous/index";
import OrderByDay from "components/modules/kiutr/orders/OrderByDay/index";
import TemplateDocumentsComponent from "components/modules/operator/template_documents/index";
import UnitProfileAgreement from "components/modules/UnitProfile/UnitProfileAgreement/index";
import UnitProfileAgreementSign from "components/modules/UnitProfile/UnitProfileAgreementSign/index";
import CooperationAgreements from "components/modules/operator/cooperation_agreements/index";
import KursTechnocardAssignsComponent from "components/modules/kurs/technocard_assigns/index";
import KursTechnocardEditor from "components/modules/kurs/technocards/editor";
import AnalyticsCommunal from "components/modules/analytics/communal/index";
import UtilityCommunal from "components/modules/analytics/utility/index";
import AnalyticsUtility from "components/modules/analytics/utility/index";
import ReportsGraphic from "components/modules/reports/Maps/ReportsGraphic/index";
import TabletCommunal from "components/modules/tablet/communal/index";
import SpeedViolations from "components/modules/SpeedViolations/index";
import ReportSchedules from "components/modules/reports/schedules/index";
import DisplaysMapComponent from "components/modules/kiutr/displays/map/index";
import DisplaysTableComponent from "components/modules/kiutr/displays/table/index";
import DisplaysStopPointsComponent from "components/modules/kiutr/displays/stopPoint/index";
import VehiclesRepair from "components/modules/vehicles/vehicles/repair/index";
import TerritorialEntitiesTree from "components/modules/geo/TerritorialEntities/TerritorialEntitiesTree";
import TerritorialEntitiesTable from "components/modules/geo/TerritorialEntities/TerritorialEntitiesTable";
import KursSkpdiAssign from "components/modules/kurs/SkpdiAssign/KursSkpdiAssign";
import NotificationsMailing from "components/modules/notifications/mailing/index";
import DuplicationMatrix from "components/modules/kiutr/DuplicationMatrix/DuplicationMatrix";
import VehiclesFuel from "components/modules/vehicles/vehicles/fuel/index";
import ExecutedWorksList from "components/modules/analytics/executed-works";
import ComplaintsComponent from "components/modules/complaints/index";
import ComplaintsEditor from "components/modules/complaints/ComplaintsEditor/index";
import RegularityMatrixPage from "components/modules/kiutr/RegularityMatrixPage";



import UtilityContractsComponent from "components/modules/utility/contracts/list";
import UtilityContractEditor from "components/modules/utility/contracts/editor";
import UtilityRoadsComponent from "components/modules/utility/roads/index";
import UtilityRoadEditor from "components/modules/utility/roads/RoadEditor/index";
import UtilityRoadPartsComponent from "components/modules/utility/roads/RoadParts/index";
import UtilityVehiclesControl from "components/modules/utility/vehicles_control/index";
import UtilityWaybillsComponent from "components/modules/utility/waybills/index";
import UtilityWaybillEditor from "components/modules/utility/waybills/WaybillEditor/index";
import UtilityWaybillBatchClose from "components/modules/utility/waybills/WaybillBatchClose/index";
import UtilityRepairProgramComponent from "components/modules/utility/repair_program/index";
import UtilityColoredPlanDailyComponent from "components/modules/utility/plan/PlanDaily/ColoredPlanDaily";
import UtilityPlanDailyComponent from "components/modules/utility/plan/PlanDaily/index";
import UtilityStosComponent from "components/modules/utility/stos/list";
import UtilityRoadStoEditor from "components/modules/utility/stos/editor";
import UtilityTasksComponent from "components/modules/utility/tasks/index";
import UtilityTaskEditorSmut from "components/modules/utility/tasks/editor_smut";
import UtilityTaskEditor from "components/modules/utility/tasks/editor";
import UtilityTasksDayComponent from "components/modules/utility/tasks_day/index";
import UtilitySkpdiAssign from "components/modules/utility/SkpdiAssign/UtilitySkpdiAssign";
import UtilityTaskTemplatesComponent from "components/modules/utility/task_templates/index";
import UtilityContractWorksComponent from "components/modules/utility/contract_works/list";
import UtilityVehicleListComponent from "components/modules/utility/vehicles/index";
import UtilityVehicleEditorForm from "components/modules/utility/vehicles/vehicle-editor-form/index";
import UtilityMaintenancesComponent from "components/modules/utility/maintenances/list";
import UtilitySupernumerarySituationsComponent from "components/modules/utility/supernumerary_situations/index";
import UtilityTechnocardsComponent from "components/modules/utility/technocards/index";
import UtilityTechnocardEditor from "components/modules/utility/technocards/editor";
import UtilityTechnocardAssignsComponent from "components/modules/utility/technocard_assigns/index";
import Refresh from 'components/modules/refresh/refresh';




export default (
    <div>
        <Route path="/auth" component={Login}/>
        <Route path="/refresh" component={Refresh}/>
        <Route path="/registration" component={Registration}/>
        <Route path="/map-standalone" component={MapFullComponent}/>

        <Route path="/tablet/auth" component={TabletLogin}/>
        <Route path="/tablet" component={TabletApp}>
            <IndexRoute component={TabletHome}/>
            <Route path="/tablet/dashboard" component={TabletHome}/>
            <Route path="/tablet/kiutr" component={TabletKiutr}/>
            <Route path="/tablet/kurs" component={TabletKurs}/>
            <Route path="/tablet/children" component={TabletChildren}/>
            <Route path="/tablet/garbage" component={TabletGarbage}/>
            <Route path="/tablet/gatn" component={TabletGatn}/>
            <Route path="/tablet/communal" component={TabletCommunal}/>
            <Route path="/tablet/:component/:uuid" component={TabletUnit}/>
        </Route>

        <Route path="/" component={AppComponent}>
            <IndexRoute component={HomeComponent}/>

            <Route path="/help" component={HelpComponent}/>

            <Route path="/:system/ellipse" component={Main}/>

            /************************************************************
             * Справочники
             ************************************************************/

            <Route path="/:system/dictionaries" component={DictionaryStructure}/>
            <Route path="/:system/dictionaries/:dictionary" component={Dictionary}>
                <Route path="/:system/dictionaries/:dictionary/:uuid"/>
            </Route>
            <Route path="/:component/vehicles/bnso" component={BNSOComponent}>
                <Route path="/:component/vehicles/bnso/:uuid"/>
            </Route>

            /************************************************************
             * Кадровый учет
             ************************************************************/

            <Route path="/:component/personnel" component={Staffing}>
                <Route path="/:component/personnel/:uuid"/>
                <Route path="/:component/personnel/:uuid/:mode"/>
            </Route>

            /************************************************************
             * Права доступа
             ************************************************************/

            <Route path="/:component/permissions/roles" component={RolesComponent}/>

            /************************************************************
            * КУРС-Д
            ************************************************************/

            <Route path="/road/contracts" component={KursContractsComponent}/>
            <Route path="/road/contracts/:uuid" component={KurdContractEditor}>
                <Route path="/road/contracts/:uuid/:delegation_uuid" />
            </Route>

            <Route path="/road/roads" component={KursRoadsComponent}/>
            <Route path="/road/roads/:uuid" component={KursRoadEditor}/>

            <Route path="/road/road_parts" component={KursRoadPartsComponent}>
                <Route path="/road/road_parts/:uuid"/>
            </Route>

            <Route path="/road/vehicles_control" component={KursVehiclesControl}/>

            <Route path="/road/waybills" component={KursWaybillsComponent}/>
            <Route path="/road/waybills/:uuid" component={KursWaybillEditor}/>
            <Route path="/road/waybills-batch-close" component={WaybillBatchClose}/>

            <Route path="/road/repair-program" component={KursRepairProgramComponent}>
                <Route path="/road/repair-program/:uuid"/>
            </Route>

            <Route path="/road/plan/daily" component={window.RNIS_SETTINGS.colored_plan ? KursColoredPlanDailyComponent : KursPlanDailyComponent}/>

            <Route path="/road/sto" component={KursStosComponent}/>
            <Route path="/road/sto/:uuid" component={KursRoadStoEditor}/>

            <Route path="/road/tasks" component={KursTasksComponent}/>
            <Route path="/road/tasks/:uuid" component={window.RNIS_SETTINGS.CITY_MURMANSK ? KursTaskEditorSmut : KursTaskEditor }>
                <Route path="/road/tasks/:uuid/:type"/>
            </Route>
            <Route path="/road/tasks-day" component={KursTasksDayComponent}/>

            <Route path="/road/skpdi" component={KursSkpdiAssign}/>

            <Route path="/road/task_templates" component={KursTaskTemplatesComponent}/>
            <Route path="/road/contracts/:contractUuid/:type" component={KursContractWorksComponent}>
                <Route path="/road/contracts/:contractUuid/:type/:uuid"/>
            </Route>

            <Route path="/road/vehicles" component={KursVehicleListComponent}/>
            <Route path="/road/vehicles/:uuid" component={KursVehicleEditorForm}/>
            <Route path="/road/vehicles/base/:uuid" component={KursVehicleEditorForm}/>

            <Route path="/road/maintenance" component={KursMaintenancesComponent}>
                <Route path="/road/maintenance/:uuid"/>
            </Route>

            <Route path="/road/supernumerary-situations" component={SupernumerarySituationsComponent}>
                <Route path="/road/supernumerary-situations/:uuid"/>
            </Route>

            <Route path="/road/technocards" component={KursTechnocardsComponent}/>
            <Route path="/road/technocards/:uuid" component={KursTechnocardEditor}/>

            <Route path="/road/technocard_assigns" component={KursTechnocardAssignsComponent}>
                <Route path="/road/technocard_assigns/:uuid"/>
            </Route>

            /************************************************************
            * Жилищно-коммунальное хозяйство
            ************************************************************/

            <Route path="/utility/contracts" component={UtilityContractsComponent}/>
            <Route path="/utility/contracts/:uuid" component={UtilityContractEditor}>
                <Route path="/utility/contracts/:uuid/:delegation_uuid" />
            </Route>

            <Route path="/utility/roads" component={UtilityRoadsComponent}/>
            <Route path="/utility/roads/:uuid" component={UtilityRoadEditor}/>

            <Route path="/utility/road_parts" component={UtilityRoadPartsComponent}>
                <Route path="/utility/road_parts/:uuid"/>
            </Route>

            <Route path="/utility/vehicles_control" component={UtilityVehiclesControl}/>

            <Route path="/utility/waybills" component={UtilityWaybillsComponent}/>
            <Route path="/utility/waybills/:uuid" component={UtilityWaybillEditor}/>
            <Route path="/utility/waybills-batch-close" component={UtilityWaybillBatchClose}/>

            <Route path="/utility/repair-program" component={UtilityRepairProgramComponent}>
                <Route path="/utility/repair-program/:uuid"/>
            </Route>

            <Route path="/utility/plan/daily" component={window.RNIS_SETTINGS.colored_plan ? UtilityColoredPlanDailyComponent : UtilityPlanDailyComponent}/>

            <Route path="/utility/sto" component={UtilityStosComponent}/>
            <Route path="/utility/sto/:uuid" component={UtilityRoadStoEditor}/>

            <Route path="/utility/tasks" component={UtilityTasksComponent}/>
            <Route path="/utility/tasks/:uuid" component={window.RNIS_SETTINGS.CITY_MURMANSK ? UtilityTaskEditorSmut : UtilityTaskEditor }>
                <Route path="/utility/tasks/:uuid/:type"/>
            </Route>
            <Route path="/utility/tasks-day" component={UtilityTasksDayComponent}/>

            <Route path="/utility/skpdi" component={UtilitySkpdiAssign}/>

            <Route path="/utility/task_templates" component={UtilityTaskTemplatesComponent}/>
            <Route path="/utility/contracts/:contractUuid/:type" component={UtilityContractWorksComponent}>
                <Route path="/utility/contracts/:contractUuid/:type/:uuid"/>
            </Route>

            <Route path="/utility/vehicles" component={UtilityVehicleListComponent}/>

            <Route exact path="/utility/vehicles/vehicles" component={VehiclesComponent} />
            <Route path="/utility/vehicles/:uuid" component={UtilityVehicleEditorForm}/>
            <Route path="/utility/vehicles/base/:uuid" component={UtilityVehicleEditorForm}/>

            <Route path="/utility/maintenance" component={UtilityMaintenancesComponent}>
                <Route path="/utility/maintenance/:uuid"/>
            </Route>

            <Route path="/utility/supernumerary-situations" component={UtilitySupernumerarySituationsComponent}>
                <Route path="/utility/supernumerary-situations/:uuid"/>
            </Route>

            <Route path="/utility/technocards" component={UtilityTechnocardsComponent}/>
            <Route path="/utility/technocards/:uuid" component={UtilityTechnocardEditor}/>

            <Route path="/utility/technocard_assigns" component={UtilityTechnocardAssignsComponent}>
                <Route path="/utility/technocard_assigns/:uuid"/>
            </Route>

            /************************************************************
            * Коммунальная техника - копия КУРС-Д
            ************************************************************/

            <Route path="/commdept/contracts" component={CommdeptContractsComponent}/>
            <Route path="/commdept/contracts/:uuid" component={CommdeptContractEditor}/>

            <Route path="/commdept/roads" component={CommdeptRoadsComponent}/>
            <Route path="/commdept/roads/:uuid" component={CommdeptRoadEditor}/>

            <Route path="/commdept/road_parts" component={CommdeptRoadPartsComponent}>
                <Route path="/commdept/road_parts/:uuid"/>
            </Route>

            <Route path="/commdept/vehicles_control" component={CommdeptVehiclesControl}/>

            <Route path="/commdept/waybills" component={CommdeptWaybillsComponent}/>
            <Route path="/commdept/waybills/:uuid" component={CommdeptWaybillEditor}/>

            <Route path="/commdept/repair-program" component={CommdeptRepairProgramComponent}>
                <Route path="/commdept/repair-program/:uuid"/>
            </Route>

            <Route path="/commdept/plan/daily" component={CommdeptPlanDailyComponent}/>

            <Route path="/commdept/sto" component={CommdeptStosComponent}/>
            <Route path="/commdept/sto/:uuid" component={CommdeptRoadStoEditor}/>

            <Route path="/commdept/tasks" component={CommdeptTasksComponent}/>
            <Route path="/commdept/tasks/:uuid" component={CommdeptTaskEditor}>
                <Route path="/commdept/tasks/:uuid/:type"/>
            </Route>
            <Route path="/commdept/tasks-day" component={CommdeptTasksDayComponent}/>

            <Route path="/commdept/skpdi" component={CommdeptSkpdiAssign}/>

            <Route path="/commdept/task_templates" component={CommdeptTaskTemplatesComponent}/>
            <Route path="/commdept/contracts/:contractUuid/:type" component={CommdeptContractWorksComponent}>
                <Route path="/commdept/contracts/:contractUuid/:type/:uuid"/>
            </Route>

            <Route path="/commdept/vehicles" component={CommdeptVehicleListComponent}/>
            <Route path="/commdept/vehicles/:uuid" component={CommdeptVehicleEditorForm}/>
            <Route path="/commdept/vehicles/base/:uuid" component={CommdeptVehicleEditorForm}/>

            <Route path="/commdept/maintenance" component={CommdeptMaintenancesComponent}>
                <Route path="/commdept/maintenance/:uuid"/>
            </Route>

            <Route path="/commdept/supernumerary-situations" component={CommdeptSupernumerarySituationsComponent}>
                <Route path="/commdept/supernumerary-situations/:uuid"/>
            </Route>

            <Route path="/commdept/technocards" component={CommdeptTechnocardsComponent}/>
            <Route path="/commdept/technocards/:uuid" component={CommdeptTechnocardEditor}/>

            <Route path="/commdept/technocard_assigns" component={CommdeptTechnocardAssignsComponent}>
                <Route path="/commdept/technocard_assigns/:uuid"/>
            </Route>

            /************************************************************
             * Оператор системы
             ************************************************************/

            <Route path="/operator/permissions/unit_permissions" component={UnitPermissionsComponent}>
                <Route path="/operator/permissions/unit_permissions/:uuid"/>
            </Route>

            <Route path="/operator/bnso_check" component={OperatorBNSOCheckComponent}>
                <Route path="/operator/bnso_check/:uuid"/>
            </Route>
            <Route path="/operator/register_requests" component={RegisterRequestsComponent}>
                <Route path="/operator/register_requests/:uuid"/>
            </Route>
            <Route path="/operator/confirm_requests" component={ConfirmRequestsComponent}>
                <Route path="/operator/confirm_requests/:uuid"/>
            </Route>
            <Route path="/operator/esia_requests" component={EsiaRequestsComponent} />
            <Route path="/operator/template_documents" component={TemplateDocumentsComponent}>
                <Route path="/operator/template_documents/:uuid"/>
            </Route>
            <Route path="/operator/cooperation_agreements" component={CooperationAgreements}>
                <Route path="/operator/cooperation_agreements/:uuid"/>
            </Route>
            <Route path="/operator/bnso_check/:uuid/map" component={OperatorBnsoCheckMapComponent}/>
            <Route path="/operator/bnso_sms" component={OperatorBNSOSmsComponent} />
            <Route path="/operator/bnso_report" component={OperatorBnsoSmsReportComponent}/>

            /************************************************************
             * Вывоз мусора
             ************************************************************/

            <Route path="/garbage/objects" component={GarbageObjectsComponent}>
                <Route path="/garbage/objects/:uuid"/>
            </Route>
            <Route path="/garbage/violations" component={GarbageViolationsComponent}/>
            <Route path="/garbage/garbagemap" component={GarbageMapComponent}/>
            <Route path="/:component/violationscopy" component={GatnViolationsComponent}>
                <Route path="/:component/violationscopy/:vehicle/:date/:type"/>
            </Route>

            /************************************************************
             * ГУАТН, Маршрутное такси, Транспорт сельского хозяйства, Контроль медицинского транспорта, Перевозки лесоматериалов,
            Перевозки опасных грузов, Центр медицины и катастроф, Лесное хозяйство
             ************************************************************/

            <Route path="/:component/violations" component={GatnViolationsComponent}>
                <Route path="/:component/violations/:vehicle/:date/:type/:vehicle_uuid"/>
            </Route>

            {/*<Route path="/control/work" component={GatnWorkComponent}/>*/}

            <Route path="/:component/speed-violations" component={SpeedViolations}>
                <Route path="/:component/speed-violations/:vehicle/:date/:vehicle_uuid"/>
            </Route>

            /************************************************************
             * КиУТР, Школьники
             ************************************************************/

            <Route path="/:component/routes" component={ window.RNIS_SETTINGS.grantMO ? KiutrRoutesGrantComponent : KiutrRoutesComponent}>
                <Route path="/:component/routes/:uuid"/>

                <Route path="/:component/routes/:uuid/variants" name="kiutr-route-variants"/>
                <Route path="/:component/routes/:uuid/variants/:uuid2" name="kiutr-route-variants"/>
                <Route path="/:component/routes/:uuid/variants/:uuid2/:type" name="kiutr-route-variants"/>
                <Route path="/:component/routes/:uuid/variants/:uuid2/:type/:uuid3" name="kiutr-route-variants"/>

                <Route path="/:component/routes/:uuid/schedules" name="kiutr-route-schedules"/>
                <Route path="/:component/routes/:uuid/schedules/interval/:schedule" name="kiutr-route-schedules-interval"/>
            </Route>
            <Route path="/:component/routes/:uuid/schedules/:scheduleUuid"
                   component={ window.RNIS_SETTINGS.grantMO ? KiutrRouteScheduleViewGrantComponent : KiutrRouteScheduleViewComponent}/>
            <Route path="/:component/routes/:uuid/schedules/:scheduleUuid/turn/:turnNumber"
                   component={ window.RNIS_SETTINGS.grantMO ? KiutrRouteScheduleTurnGrantComponent : KiutrRouteScheduleTurnComponent}/>
            <Route path="/:component/routes/:uuid/schedules/:scheduleUuid/graphic"
                   component={ window.RNIS_SETTINGS.grantMO ? KiutrRouteScheduleGraphicViewGrantComponent : KiutrRouteScheduleGraphicViewComponent}/>
            <Route path="/:component/routes/:uuid/schedules/:scheduleUuid/ellipse"
                   component={ window.RNIS_SETTINGS.grantMO ? KiutrRouteScheduleEllipseViewGrantComponent : KiutrRouteScheduleEllipseViewComponent}/>

            <Route path="/:component/schedules" component={ window.RNIS_SETTINGS.grantMO ? KiutrSchedulesGrantComponent : KiutrSchedulesComponent}>
                <Route path="/:component/schedules/:uuid"/>
                <Route path="/:component/schedules/:uuid/:uuid2"/>
            </Route>

            <Route path="/:component/orders" component={KiutrOrderListComponent}>
                <Route path="/:component/orders/matrices" name="matrices"/>
                <Route path="/:component/orders/:uuid"/>
                <Route path="/:component/orders/:uuid/order/:uuid2"/>
            </Route>
            <Route path="/kiutr/interval_orders/regularity-matrix" component={RegularityMatrixPage}>
            <Route path="/kiutr/interval_orders/:order/regularity-matrix"/>
            </Route>
            <Route path="/:component/duplication_matrices/:uuid" component={DuplicationMatrix}/>
            <Route path="/:component/orders/:uuid/route/:routeUuid"
                   component={KiutrRouteScheduleAdditionalTurnComponent}/>
            <Route path="/:component/orders-by-day/:unitUuid" component={OrderByDay}/>

            <Route path="/:component/contracts" component={KiutrContractsComponent}>
                <Route path="/:component/contracts/:uuid"/>
                <Route path="/:component/contracts/:uuid/routes" name="kiutr-contract-routes"/>
            </Route>

            <Route path="/:component/route_registries" component={KiutrRouteRegistriesComponent}>
                <Route path="/:component/route_registries/:uuid"/>
            </Route>

            <Route path="/:component/transport-work" component={KiutrTransportWork}>
                <Route path="/:component/transport-work/:uuid"/>
            </Route>

            <Route path="/:component/displays/map" component={DisplaysMapComponent}/>
            <Route path="/:component/displays/table" component={DisplaysTableComponent}>
                <Route path="/:component/displays/table/:uuid"/>
            </Route>
            <Route path="/:component/displays/stoppoint" component={DisplaysStopPointsComponent} />

            /************************************************************
             * Мониторинг ТС
             ************************************************************/

            <Route path="/settings/:component/map" component={UserMapObjects}/>
            <Route path="/:component/map" component={Maps}>
                <Route path="/:component/map/notification/:uuid" name="from-notification"/>
                <Route path="/:component/map/order/:uuid" name="from-order"/>
                <Route path="/:component/map/route/:routeUuid" name="from-route"/>
            </Route>
            <Route path="/:component/map/history" component={MapsHistoryComponent}/>

            /************************************************************
             * Прочее
             ************************************************************/

            <Route path="/dtp-heat-map" component={DtpMapsComponent}/>

            <Route path="/reports" component={Reports}/>
            <Route path="/reports/schedule" component={ReportSchedules}>
                <Route path="/reports/schedule/:uuid"/>
            </Route>
            <Route path="/reports/map/:uri/:uuid" component={KursRoadPartsMap}/>
            <Route path="/reports/graphic/:uuid" component={ReportsGraphic}/>

            <Route path="/system/vehicles/import" component={VehiclesImportComponent}/>

            <Route path="/:component/vehicles/vehicles" component={VehiclesComponent}>
                <Route path="/:component/vehicles/vehicles/:uuid"/>
            </Route>
            <Route path="/:component/vehicles/vehicles/:uuid/repair" component={VehiclesRepair}/>
            <Route path="/:component/vehicles/vehicles/:uuid/fuel" component={VehiclesFuel}/>

            <Route path="/:component/stop-points" component={StopPointsComponent}>
                <Route path="/:component/stop-points/:uuid"/>
            </Route>

            <Route path="/settings" component={Settings}/>

            <Route path="/notifications/settings" component={NotificationUserSettingsComponent}>
                <Route path="/notifications/settings/:uuid"/>
            </Route>
            <Route path="/notifications/mailing" component={NotificationsMailing}>
                <Route path="/notifications/mailing/:uuid"/>
            </Route>
            <Route path="/notifications" component={NotificationsViewComponent}>
                <Route path="/notifications/:uuid"/>
            </Route>

            <Route path="/:component/units/units" component={UnitsTree}>
                <Route path="/:component/units/units/:uuid"/>
            </Route>

            <Route path="/:component/units/units-table" component={UnitsTable}>
                <Route path="/:component/units/units-table/:uuid"/>
            </Route>

            <Route path="/:component/territorial_entities/tree" component={TerritorialEntitiesTree}>
                <Route path="/:component/territorial_entities/tree/:uuid"/>
            </Route>

            <Route path="/:component/territorial_entities/table" component={TerritorialEntitiesTable}>
                <Route path="/:component/territorial_entities/table/:uuid"/>
            </Route>

            <Route path="/units/carriers" component={CarriersComponent}>
                <Route path="/units/carriers/:uuid"/>
            </Route>
            <Route path="/road/complaints" component={ComplaintsComponent}/>
            <Route path="/road/complaints/:uuid" component={ComplaintsEditor}/>

            /************************************************************
             * Портал РНИС МО
             ************************************************************/

            <Route path="/portal/news" component={PortalNewsComponent}>
                <Route path="/portal/news/:uuid"/>
            </Route>
            <Route path="/portal/faq" component={PortalFaqComponent}>
                <Route path="/portal/faq/:uuid"/>
            </Route>
            <Route path="/portal/pages" component={PortalPagesComponent}>
                <Route path="/portal/pages/:uuid"/>
            </Route>

            /************************************************************
             * Мобильное приложение
             ************************************************************/

            <Route path="/mobile/mobile_pages" component={MobilePagesComponent}>
                <Route path="/mobile/mobile_pages/:uuid"/>
            </Route>
            <Route path="/mobile/contacts" component={MobileContactsComponent}>
                <Route path="/mobile/contacts/:uuid"/>
            </Route>

            /************************************************************
             * Профиль
             ************************************************************/

            <Route path="/profile" component={ProfileComponent}/>
            <Route path="/profile/unit" component={UnitProfileComponent}/>
            <Route path="/profile/unit/vehicles/:uuid/:uuid2" component={UnitProfileVehiclesComponent}/>
            <Route path="/profile/unit/agreement/:uuid" component={UnitProfileAgreement}/>
            <Route path="/profile/unit/agreement/:uuid/sign" component={UnitProfileAgreementSign}/>

            /************************************************************
             * Системные страницы
             ************************************************************/

            <Route path="/system/activity" component={ActivityComponent}/>
            <Route path="/system/user_logs" component={UserLogsComponent}/>
            <Route path="/system/trash_logs" component={TrashLogsComponent}/>
            <Route path="/system/audit/:entityUuid" component={AuditComponent}/>

            <Route path="/operator/notification_events/:entityClass/:entityUuid"
                   component={NotificationEventsComponent}>
                <Route path="/operator/notification_events/:entityClass/:entityUuid/:uuid"/>
            </Route>
            <Route path="/operator/notifications" component={NotificationRulesComponent}>
                <Route path="/operator/notifications/:uuid"/>
            </Route>

            /************************************************************
             * Аналитическая платформа
             ************************************************************/

            <Route path="/analytics/dashboard" component={AnalyticsDashboard}/>
            <Route path="/analytics/passenger-transportation" component={AnalyticsPassengerTransportation}/>
            <Route path="/analytics/children-transportation" component={AnalyticsChildrenTransportation}/>
            <Route path="/analytics/kurs" component={KursAnalytics}/>
            <Route path="/analytics/road-maintenance" component={AnalyticsRoadMaintenance}/>
            <Route path="/analytics/garbage" component={AnalyticsGarbage}/>
            <Route path="/analytics/gatn" component={AnalyticsGatn}/>
            <Route path="/analytics/taxi" component={AnalyticsTaxi}/>
            <Route path="/analytics/agricultural" component={AnalyticsAgricultural}/>
            <Route path="/analytics/timber" component={AnalyticsTimber}/>
            <Route path="/analytics/medic" component={AnalyticsMedic}/>
            <Route path="/analytics/dangerous" component={AnalyticsDangerous}/>
            <Route path="/analytics/communal" component={AnalyticsCommunal}/>
            <Route path="/analytics/utility" component={UtilityCommunal}/>
            <Route path="/analytics/official" component={AnalyticsOfficial}/>
            <Route path="/analytics/emercom" component={AnalyticsEmercom}/>
            <Route path="/analytics/medicine" component={AnalyticsMedicine}/>
            <Route path="/analytics/forestry" component={AnalyticsForestry}/>
            <Route path="/analytics/farming" component={AnalyticsFarming}/>
            <Route path="/analytics/:type/units/:uuid" component={AnalyticGrantPassengerTransportationUnits}/>
            <Route path="/analytics/:type/municipality/:uuid" component={AnalyticGrantMunicipality}/>
            {/*<Route path="/analytics/interactive-map" component={AnalyticsMap}/>*/}

            <Route path="/:component/executedworks/map" component={Maps} >
                <Route path="/:component/executedworks/map/:work_type_uuid" />
            </Route>
            <Route path="/:component/roadparts/map" component={Maps} />
            <Route path="/analytics/executedworks" component={ExecutedWorksList}>
                <Route path="/analytics/executedworks/:uuid" />
            </Route>

            /************************************************************
             * Страница 404
             ************************************************************/

            <Route path="*" component={NotFoundComponent}/>
        </Route>
    </div>
);
