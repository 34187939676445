import {rpc} from "./session";
import _ from 'lodash';

export async function getNotifications(meta = {}) {
    return await rpc.request('com.rnis.notifications.action.list', {}, {meta});
}

export async function getNotification(uuid) {
    return await rpc.request('com.rnis.notifications.action.get', {uuid});
}

export async function getTotalUnreadNotifications() {
    return await rpc.request('com.rnis.notifications.action.count', {});
}

export async function notificationEventCreated(callback) {
    await rpc.subscribe('com.rnis.notifications.event.created', callback);
}

export async function notificationEventRead(callback) {
    await rpc.subscribe('com.rnis.notifications.event.read', callback);
}

export async function readNotification(notification) {
    return await rpc.request('com.rnis.notifications.action.read', {
        notifications: [
            {
                uuid: notification.uuid
            }
        ]
    });
}

export async function readNotifications(uuids) {
    return await rpc.request('com.rnis.notifications.action.read', {
        notifications: _.map(uuids, (uuid) => ({uuid})),
    });
}

export async function getNotificationEvents(meta = {}) {
    return await rpc.request('com.rnis.notifications.action.notification_event.list', {}, {meta});
}

export async function getNotificationEvent(uuid) {
    return await rpc.request('com.rnis.notifications.action.notification_event.get', {uuid});
}

export async function sendNotificationEvent(uuid) {
    return await rpc.request('com.rnis.notifications.action.notification_event.send', {uuid});
}

export async function createNotificationEvent(data) {
    return await rpc.request('com.rnis.notifications.action.notification_event.create', data);
}

export async function updateNotificationEvent(data) {
    return await rpc.request('com.rnis.notifications.action.notification_event.update', data);
}

export async function deleteNotificationEvent(data) {
    return await rpc.request('com.rnis.notifications.action.notification_event.delete', data);
}


export async function getMailings(meta = {}) {
    return await rpc.request('com.rnis.notifications.action.mailing.list', {}, {meta});
}

export async function getMailing(uuid) {
    return await rpc.request('com.rnis.notifications.action.mailing.get', {uuid});
}

export async function createMailing(data) {
    return await rpc.request('com.rnis.notifications.action.mailing.create', data);
}

export async function updateMailing(data) {
    return await rpc.request('com.rnis.notifications.action.mailing.update', data);
}

export async function deleteMailing(data) {
    return await rpc.request('com.rnis.notifications.action.mailing.delete', data);
}

export async function getEventRules(meta = {}) {
    return await rpc.request('com.rnis.notifications.action.event_rule.list', {}, {meta});
}

export async function getEventRule(uuid) {
    return await rpc.request('com.rnis.notifications.action.event_rule.get', {uuid});
}

export async function createEventRule(data) {
    return await rpc.request('com.rnis.notifications.action.event_rule.create', data);
}

export async function updateEventRule(data) {
    return await rpc.request('com.rnis.notifications.action.event_rule.update', data);
}

export async function deleteEventRule(data) {
    return await rpc.request('com.rnis.notifications.action.event_rule.delete', data);
}

export async function getEventTypes() {
    return await rpc.request('com.rnis.notifications.action.event_rule.events');
}

export async function getEventRuleNotifications(meta = {}) {
    return await rpc.request('com.rnis.notifications.action.event_rule_notification.list', {}, {meta});
}

export async function getEventRuleNotification(uuid) {
    return await rpc.request('com.rnis.notifications.action.event_rule_notification.get', {uuid});
}

export async function createEventRuleNotification(data) {
    return await rpc.request('com.rnis.notifications.action.event_rule_notification.create', data);
}

export async function updateEventRuleNotification(data) {
    return await rpc.request('com.rnis.notifications.action.event_rule_notification.update', data);
}

export async function deleteEventRuleNotification(data) {
    return await rpc.request('com.rnis.notifications.action.event_rule_notification.delete', data);
}

export async function getUserNotificationSettings(meta = {}) {
    return await rpc.request('com.rnis.notifications.action.user_notification_setting.list', {}, {meta});
}

export async function getUserNotificationSetting(uuid) {
    return await rpc.request('com.rnis.notifications.action.user_notification_setting.get', {uuid});
}

export async function createUserNotificationSetting(data) {
    return await rpc.request('com.rnis.notifications.action.user_notification_setting.create', data);
}

export async function updateUserNotificationSetting(data) {
    return await rpc.request('com.rnis.notifications.action.user_notification_setting.update', data);
}

export async function deleteUserNotificationSetting(data) {
    return await rpc.request('com.rnis.notifications.action.user_notification_setting.delete', data);
}

export async function getSpeedViolations(meta = {}) {
    return await rpc.request('com.rnis.notifications.action.speed_violation.list', {}, {meta});
}

export async function getSpeedViolationJournal(meta = {}) {
    return await rpc.request('com.rnis.notifications.action.speed_violation.journal', {}, {meta});
}

export async function speedViolationsSummary(units, dateFrom, dateTo) {
    return await rpc.request('com.rnis.notifications.action.speed_violation.summary', {
        units,
        date_from: dateFrom,
        date_to: dateTo,
    });
}

export async function speedViolationsDriversSummary(component, dateFrom, dateTo) {
    return await rpc.request('com.rnis.notifications.action.speed_violation.drivers.summary', {
        date_from: dateFrom,
        date_to: dateTo,
        component,
    });
}
