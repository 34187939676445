import React from 'react';
import ReactDOMServer from 'react-dom/server';
import MapVehicleIcon from "components/ui/map-vehicle-icon";

export default class StopPointIcon {

    static getIcon(color = null, sizeMultiplier = 1) {
        color = color || 'bus';
        const html = ReactDOMServer.renderToStaticMarkup(<MapVehicleIcon device={{type: `${color}-stop`}}/>);

        let className = `marker marker-stop marker-stop_type_${color} marker_sm`;
        let size = 23 * sizeMultiplier;
        return new L.DivIcon({
            html: html,
            className: className,
            iconSize: new L.Point(size, size),
            iconAnchor: new L.Point(size / 2, size / 2),
        });
    }
}