import currentUser from "helpers/current-user";

const Autobahn = require('./autobahn');
const _ = require('lodash');
const debug = require('debug');
const $ = require('jquery');
const log = debug('wsrpc');
const moment = require('moment');
import formats from "dictionaries/formats";
import hash from 'object-hash';

class WebSocketTransport {
    constructor(options) {
        this.__connection = new Autobahn.Connection(options.ws);

        this.__session = null;
        this.__subscriptions = [];
        this.__subscribed = [];
        this.__onEstablished = [];
        this.__onLost = [];
        this.__connectionEstablished = () => {
        };

        this.setToken(options.token || null);

        this.__connection.onopen = (session, details) => {
            this.__session = session;

            this.subscribe();

            // engage promise
            this.connectionEstablished();

            _.forEach(this.__onEstablished, (callback) => {
                callback();
            });

            log('WebSocket: Connection established');
        };

        this.__connection.onclose = (reason, details) => {
            this.__session = null;

            this.__subscribed = [];

            for (let i in _.reverse(this.__onLost)) {
                this.__onLost[i](reason)
            }

            // engage promise
            this.connectionEstablished();

            log('WebSocket: Connection lost, reason:', reason);
        };
    }

    connectionEstablished() {
        if (_.isFunction(this.__connectionEstablished)) {
            this.__connectionEstablished();
        }
    }

    set subscriptions(subscriptions) {
        this.__subscriptions = subscriptions;
    }

    onEstablished(callback) {
        this.__onEstablished.push(callback);
    }

    onLost(callback) {
        this.__onLost.push(callback);
    }

    setToken(token) {
        this.__token = token;
    }

    setIp(ip) {
        this.__ip = ip;
    }

    subscribe() {
        if (!this.__session) {
            return;
        }

        _.forEach(this.__subscriptions, (subscription, i) => {
            if (_.indexOf(this.__subscribed, i) > -1) {
                return;
            }

            log('WebSocket: Subscribing', subscription.subject);

            this.__session.subscribe(subscription.subject, (args) => {
                subscription.handler(args[0]);
            }, {
                filter: {
                    user_uuid: currentUser.uuid(),
                },
                token: this.__token,
            });

            this.__subscribed.push(i);
        });
    }

    connect() {
        this.__connectPromise = new Promise((resolve) => {
            this.__connectionEstablished = resolve;
            this.__connection.open();
        });

        return this.__connectPromise;
    }

    request(subject, headers, payload) {
        log('WebSocket: Performing', subject, 'with', headers, payload);

        if (this.__token) {
            headers.token = this.__token;
        }
        if (this.__ip) {
            headers.ip = this.__ip;
        }

        if (!/^com\.rnis\.ecp/.test(subject)) {
            headers.timestamp = moment().format(formats.DATETIME_API);
        }

        return this.__session.call(
            'com.rnis.wsrpc.action.request',
            [
                JSON.stringify({
                    subject: subject,
                    body: JSON.stringify({
                        headers: headers,
                        payload: payload
                    })
                })
            ]
        ).then(function (response) {
            response = JSON.parse(response);
            log('WebSocket: Request', subject, 'response:', response);

            if (_.size(response.errors) > 0) {
                return Promise.reject(response);
            } else {
                return Promise.resolve(response);
            }
        });
    }

    isAvailable() {
        return this.__connection.isOpen;
    }
}

class AjaxTransport {
    constructor(options) {
        this.__isEnabled = false;
        this.__options = options;

        this.setToken(options.token || null);
    }

    request(subject, headers, payload) {
        return new Promise((resolve, reject) => {
            const start = (new Date()).getTime();
            log('AJAX: Performing', subject, 'with', headers, payload);

            if (this.__token) {
                headers.token = this.__token;
            }

            $.ajax(
                `${this.__options.ajax.url}/request?${subject}`,
                {
                    method: 'POST',
                    headers: {
                        'Subject': subject
                    },
                    data: JSON.stringify({
                        headers: headers,
                        payload: payload
                    }),
                    dataType: 'json',
                    contentType: 'application/json;charset=utf-8',
                    success: (response) => {
                        log('AJAX: Request', subject, 'response:', response, 'time:', `${(new Date()).getTime() - start}ms`);
                        if (_.size(response.errors) > 0) {
                            reject(response);
                        } else {
                            resolve(response);
                        }
                    },
                    error: (error) => {
                        reject(error);
                    }
                }
            );
        });
    }

    ip() {
        return new Promise((resolve) => {
            resolve(null);
        });
        /*return new Promise((resolve, reject) => {
            $.ajax(
                `${this.__options.ajax.url}/ip`,
                {
                    method: 'GET',
                    dataType: 'json',
                    success: (response) => {
                        if (response) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                }
            );
        });*/
    }

    subscribe(subscriptions) {
        // Seems like other transport is up, stop subscribing
        if (this.__isEnabled === false) {
            return;
        }

        subscriptions = _.groupBy(subscriptions, 'subject')

        $.ajax(
            `${this.__options.ajax.url}/subscribe`,
            {
                method: 'POST',
                dataType: 'json',
                headers: {
                    'Subject': subjects,
                    'Filter': {
                        user_uuid: currentUser.uuid(),
                    },
                    'Token': this.__token,
                },
                success: (event, status, xhr) => {
                    // In case of no content response
                    // And also checking if other transport is up already
                    if (xhr.status !== 204 && this.__isEnabled === true) {
                        // Call specific handler
                        let subjects = _.get(subscriptions, event.subject, [])

                        _.forEach(subjects, (subject) => {
                            subject.handler(event.body)
                        });
                    }
                },
                complete: (xhr) => {
                    if (xhr.status !== 204 && xhr.status !== 200) {
                        // Deny spam in case of error
                        setTimeout(() => {
                            this.subscribe(subscriptions);
                        }, this.__options.ajax.retry_timeout * 1000);

                        return;
                    }

                    this.subscribe(subscriptions);
                }
            }
        );
    }

    fallbackSubscribes(subscriptions) {
        // Deny cycling on connection retries
        if (this.__isEnabled === true) {
            return;
        }

        this.__isEnabled = true;

        log('AJAX: Subscribing', Object.keys(subscriptions));

        this.subscribe(subscriptions);
    }

    set isEnabled(isEnabled) {
        this.__isEnabled = isEnabled;
    }

    setToken(token) {
        this.__token = token;
    }

    getToken() {
        return this.__token;
    }
}

export default class WsRpc {
    __requests = {};

    options = {
        onlyAjax: false,
    };

    constructor(options) {
        this.options = options;

        this.__webSocket = new WebSocketTransport({
            token: options.token,
            ws: options.ws
        });

        this.__ajax = new AjaxTransport({
            token: options.token,
            ajax: options.ajax
        });

        this.__subscriptions = [];

        this.onEstablished(() => {
            this.__ajax.isEnabled = false;
        });

        this.onLost(() => {
            log('Connection lost, reason:', reason);
            this.__ajax.fallbackSubscribes(this.__subscriptions);
        });
    }

    connect() {
        if (!this.options.onlyAjax) {
            return this.__webSocket.connect();
        }
    }

    subscribe(subject, handler) {
        this.__subscriptions.push({
            subject, handler
        });

        this.__webSocket.subscriptions = this.__subscriptions;

        // Resubscribe immediately if possible,
        // otherwise it is done on connection establishment
        if (this.__webSocket.isAvailable()) {
            this.__webSocket.subscribe();
        }
    }

    ajaxRequest(subject, payload, headers = {meta: {}}) {
        return this.__ajax.request(subject, headers, payload);
    }

    ip() {
        return this.__ajax.ip();
    }

    requestWithoutOverlapping(subject, payload = {}, headers = {meta: {}}) {
        const key = hash.MD5({subject, payload, headers});

        if (this.__requests[key]) {
            return this.__requests[key];
        }

        const promise = this.request(subject, payload, headers);

        this.__requests[key] = promise;

        promise.then(() => {
            delete this.__requests[key];
        });

        return promise;
    }

    request(subject, payload = {}, headers = {meta: {}}) {
        /*if (!this.options.onlyAjax && this.__webSocket.isAvailable()) {
            return this.__webSocket.request(subject, headers, payload);
        } else {*/
        return this.__ajax.request(subject, headers, payload);
        //}
    }

    setToken(token) {
        this.__ajax.setToken(token);
        this.__webSocket.setToken(token);
    }

    getToken() {
        return this.__ajax.getToken();
    }

    setIp(ip) {
        this.__webSocket.setIp(ip);
    }

    onEstablished(callback) {
        this.__webSocket.onEstablished(callback);
    }

    onLost(callback) {
        this.__webSocket.onLost(callback);
    }
};