import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import _ from 'lodash';

import PageModal from 'components/ui/page-modal';

import { connect } from 'react-redux';
import GlobalLoaderComponent from 'components/ui/global-loader';
import * as alerts from 'helpers/alerts';
import { getUploadToken, uploadFile } from 'helpers/api/storage';
import BaseEditorFormComponent from 'components/base/base-editor-form';
import Block from 'components/ui/form/block';
import { getDictionaryList } from 'store/reducers/dictionaries/dictionary';
import Accordion from 'components/ui/accordion/accordion';
import AccordionItem from 'components/ui/accordion/accordion-item';
import BaseEditor from 'components/base/base-editor';
import { getUnit, getUnits } from 'store/reducers/organizational_units/units';
import moment from 'moment';
import formats from 'dictionaries/formats';
import systems from 'dictionaries/systems';
import Button from 'components/ui/button';
import ContextTooltip from 'components/ui/context-tooltip';
import ModalTopMenuListItem from 'components/ui/modal/modal-top-menu-list-item';
import {
	documentCooperationAgreement,
	getCooperationAgreements,
	updateCooperationAgreement,
	createCooperationAgreement
} from 'store/reducers/portal/cooperation_agreements';
import { setCooperationAgreements } from 'store/reducers/kurs/actions';
import { getRegisterRequests } from 'store/reducers/portal/register_requests';
import CooperationAgreementOperatorSign from 'components/modules/operator/cooperation_agreements/sign';
import { resizeModals } from 'helpers/modal-size';
import { events } from 'dom-helpers';
import RegisterRequestHistory from 'components/modules/operator/register_requests/history';
import CooperationAgreementOperatorWarning from 'components/modules/operator/cooperation_agreements/warning';
import { getUsers } from 'store/reducers/staffing/staffing';
import { getUser } from 'store/reducers/staffing/staffing_editor';
import EasyXml from 'easyxml';
import { xmlVerify } from 'store/reducers/ecp/ecp';
import download from 'downloadjs';
import debounce from 'throttle-debounce/debounce';
import { getCooperationAgreementSelector } from '../../../../store/reducers/kurs/selectors';
import currentUser from '../../../../helpers/current-user';
import FileReaderInput from 'react-file-reader-input';

let resizeId;
@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	uuid: PropTypes.string
})
@connect(
	(state) => ({
		cooperationAgreement: getCooperationAgreementSelector(state)
	}),
	{ getCooperationAgreements, updateCooperationAgreement, documentCooperationAgreement, createCooperationAgreement }
)
export default class Editor extends BaseEditor {
	modelClass = 'App\\Model\\CooperationAgreement';
	modalClassName = 'Agreement AgreementCooperation b-modal-register-requests';

	static childContextTypes = {
		resizeModals: PropTypes.func
	};

	constructor(props, context) {
		super(props, context);

		Object.assign(this.state, {
			signActive: false,
			warningActive: false
		});

		this.onResize = this.onResize.bind(this);
	}

	getChildContext() {
		return { resizeModals: this.resizeModals };
	}

	componentWillMount() {
		events.on(window, 'resize', this.onResize);
	}

	componentWillUnmount() {
		events.off(window, 'resize', this.onResize);
	}

	onResize() {
		clearTimeout(resizeId);
		resizeId = setTimeout(this.resizeModals, 1000);
	}

	resizeModals() {
		const ignoreClasses = [ '' ];
		resizeModals('.b-modal', 'b-modal__collapse', 'move-right', ignoreClasses);
	}

	resize() {
		this.resizeModals();
	}

	printButton() {
		return null;
	}

	async createItem(data) {
		if (window.RNIS_SETTINGS.CITY_TULA) {
			return await this.props.createCooperationAgreement(this.props.cooperationAgreement);
		}
		return null;
	}

	// saveButton(onClick) {
	// 	return null;
	// }

	getFullTitle() {
		if (this.props.mode === 'add') {
			return `Соглашение`;
		}
		return `Соглашение №${_.get(this.state.item, 'document_number', '...')}`;
	}

	async loadData(uuid) {
		return await this.props.getCooperationAgreements({
			filters: {
				withUuid: [ uuid ]
			}
		});
	}

	async componentWillReceiveProps(props) {
		if (this.state.uuid !== props.uuid) {
			this.setState({
				uuid: props.uuid,
				item: null,
				isLoading: !!props.uuid
			});
			this.init();
			if (props.uuid) {
				const response = await this.loadData(props.uuid);
				if (response.isOk) {
					this.setState({
						item: _.first(response.payload.items),
						isLoading: false
					});
				} else {
					response.showErrors();
				}
			}
		}

		setTimeout(() => {
			this.resizeModals();
		}, 500);
	}

	async updateItem(data) {
		data.source = 'operator';
		data.operator_sign = null;
		data.status = 'active';
		return await this.props.updateCooperationAgreement(data);
	}

	getForm(item, onSubmit) {
		return (
			<EditorForm
				{...this.props}
				ref="form"
				mode={this.props.mode}
				onSubmit={onSubmit}
				onClose={::this.props.onClose}
				data={item}
				errors={this.state.errors}
			/>
		);
	}

	async showSign() {
		await this.setState({
			signActive: true
		});
		this.resize();
	}

	async hideSign() {
		await this.setState({
			signActive: false
		});
		this.resize();
	}

	async showWarning() {
		await this.setState({
			warningActive: true
		});
		this.resize();
	}

	async hideWarning() {
		await this.setState({
			warningActive: false
		});
		this.resize();
	}

	renderModals() {
		if (this.state.signActive) {
			return <CooperationAgreementOperatorSign item={this.state.item} onClose={::this.hideSign} />;
		}
		if (this.state.warningActive) {
			return <CooperationAgreementOperatorWarning item={this.state.item} onClose={::this.hideWarning} />;
		}
	}

	renderHeaderBtns(mode) {
		return [
			this.state.item && this.state.item.status !== 'terminated' ? (
				<ContextTooltip
					key="cooperation_agreements.terminate"
					code="cooperation_agreements.terminate"
					default="Расторгнуть"
				>
					<ModalTopMenuListItem className="b-icon-link_icon_basket" onClick={::this.terminate} />
				</ContextTooltip>
			) : null,
			this.state.item && this.state.item.is_signed_by_company && this.state.item.status !== 'terminated' ? (
				<ContextTooltip key="cooperation_agreements.sign" code="cooperation_agreements.sign" default="Подписать">
					<ModalTopMenuListItem className="b-icon-link_icon_order-close" onClick={::this.showSign} />
				</ContextTooltip>
			) : null,
			<ContextTooltip
				key="cooperation_agreements.warning"
				code="cooperation_agreements.warning"
				default="Предупреждения"
			>
				<ModalTopMenuListItem className="b-icon-link_icon_alert" onClick={::this.showWarning} />
			</ContextTooltip>
		];
	}

	terminate() {
		alerts.prompt(
			'Вы уверены что хотите расторгнуть соглашение?',
			'',
			() => {
				if (!this.refs.form) return;

				let state = this.refs.form.getWrappedInstance().getData();
				state.status = 'terminated';
				this.edit(state);
			},
			'Расторгнуть'
		);
	}
}

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	data: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	errors: PropTypes.object
})
@connect(
	(state) => ({
		permissions: state.roles.get('permissions'),
		cooperationAgreement: getCooperationAgreementSelector(state)
	}),
	{
		getDictionaryList,
		getUnit,
		getRegisterRequests,
		xmlVerify,
		documentCooperationAgreement,
		getUnits,
		setCooperationAgreements
	},
	null,
	{ withRef: true }
)
class EditorForm extends BaseEditorFormComponent {
	state = {
		cooperation_agreement: {},
		unit: null,
		register_request: null,
		signed_by: null,
		signed_by_operator: null,
		request_documents: [],
		withComponent: null
	};

	getData() {
		return this.state.cooperation_agreement;
	}

	async componentDidMount() {
		await this.setState({
			cooperation_agreement: this.props.data
		});
		if (this.props.mode === 'add') {
			this.props.setCooperationAgreements({
				...this.props.cooperationAgreement,
				user_uuid: currentUser.user.uuid,
				status: 'active'
			});
		}

		if (this.props.mode === 'edit') {
			this.setState({ unit_name: this.props.data.unit_uuid, withComponent: this.props.data.component });
			this.loadDictionaries([ 'request_documents' ]);
			this.verifySign(this.state.cooperation_agreement);
			this.verifySignOperator(this.state.cooperation_agreement);
			this.loadUnit();
			this.loadRegisterRequest();
		}
	}

	async verifySign(item) {
		const sign = item.company_sign;

		let xml = this.getXml(_.clone(item));
		const signPosition = xml.indexOf('</Data>') + '</Data>'.length;
		xml = xml.substr(0, signPosition + 1) + sign + xml.substr(signPosition + 1);

		xml = xml.replace(/\s{2}/g, '');

		const response = await this.props.xmlVerify(xml);
		if (response.isOk) {
			const subjectName = _.get(response.payload, 'certificateInfo.subjectName');
			const matches = /CN=([^,]+)(,|$)/.exec(subjectName);
			const signed_by = matches[1].replace(/\\"/g, '"');

			this.setState({
				signed_by,
				company_ecp: _.get(response.payload, 'certificateInfo')
			});
		}
	}

	async verifySignOperator(item) {
		const sign = item.operator_sign;

		let xml = this.getXml(_.clone(item));
		const signPosition = xml.indexOf('</Data>') + '</Data>'.length;
		xml = xml.substr(0, signPosition + 1) + sign + xml.substr(signPosition + 1);

		xml = xml.replace(/\s{2}/g, '');

		const response = await this.props.xmlVerify(xml);
		if (response.isOk) {
			const subjectName = _.get(response.payload, 'certificateInfo.subjectName');
			const matches = /CN=([^,]+)(,|$)/.exec(subjectName);
			const signed_by = matches[1].replace(/\\"/g, '"');

			this.setState({
				signed_by_operator: signed_by,
				operator_ecp: _.get(response.payload, 'certificateInfo')
			});
		}
	}

	getXml() {
		const easyXml = new EasyXml({
			singularize: true,
			rootElement: 'Envelope',
			manifest: true
		});
		const item = this.state.cooperation_agreement;
		const data = {
			uuid: item.uuid,
			template_document_uuid: item.template_document_uuid,
			unit_uuid: item.unit_uuid,
			document_number: item.document_number,
			expiration_date: item.expiration_date,
			document_url: item.document_url,
			component: item.component
		};
		let xml = easyXml.render({
			_xmlns: 'urn:envelope',
			Data: data
		});
		xml = xml.replace(/\s{2}/g, '');
		return xml;
	}

	async loadUnit(unit_uuid) {
		const response = await this.props.getUnit(unit_uuid || this.get('unit_uuid'));
		if (response.isOk) {
			this.setState({
				unit: response.payload
			});
			this.props.setCooperationAgreements({ ...this.props.cooperationAgreement, unit_uuid: response.payload.uuid });
		} else {
			response.showErrors();
		}
	}

	loadUnitsDebounce = debounce(500, ::this.loadUnits);

	async loadUnits(input, callback) {
		const response = await this.props.getUnits({
			pagination: {
				page: 1,
				limit: 1000
			},
			search: input ? input : undefined,
			...(this.state.withComponent ? { withComponent: this.state.withComponent } : {})
		});

		if (response.isOk) {
			callback(null, {
				options: _.sortBy(
					response.payload.items.map((i) => {
						let newItem = {
							label: i.name,
							value: i.uuid,
							fullName: i.name_full
						};

						if (i.workday_begin) {
							newItem['workday_begin'] = i.workday_begin;
						}
						if (i.workday_finish) {
							newItem['workday_finish'] = i.workday_finish;
						}

						return newItem;
					}),
					'label'
				),
				complete: false
			});
		} else {
			response.showErrors();
		}
	}

	onСompanyChange = (e) => {
		const value = e ? e.value : null;
		this.loadUnit(value);
		this.setState({ unit_name: value });
	};

	onComponentChange = (e) => {
		this.props.setCooperationAgreements({ ...this.props.cooperationAgreement, component: e.value });
		this.setState({ withComponent: e.value });
	};

	async loadRegisterRequest() {
		const response = await this.props.getRegisterRequests({
			filters: {
				withUnit: this.get('unit_uuid')
			}
		});

		if (response.isOk) {
			this.setState({
				register_request: {
					documents: _.flatten(
						_.map(response.payload.items, (item) => {
							return _.filter(item.documents || [], (document) => {
								return !(document.valid_to && moment(document.valid_to).isBefore(moment()));
							});
						})
					)
				}
			});
		} else {
			response.showErrors();
		}
	}

	get(path, defaultValue = null) {
		return _.get(this.state.cooperation_agreement, path, defaultValue);
	}

	getFilteredSystems() {
		const showedSystems = {};
		Object.keys(systems).forEach((systemKey) => {
			if (!window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[systemKey]) {
				showedSystems[systemKey] = systems[systemKey];
			} else if (!window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[systemKey].hideForRoles) {
				showedSystems[systemKey] = window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[systemKey].name || systems[systemKey];
			}
		});

		return _.map(showedSystems, (label, value) => ({
			value,
			label
		}));
	}

	async getUploadTokenRequest() {
		try {
			const response = await getUploadToken();
			return response.payload;
		} catch (e) {
			console.log('Ошибка получения токена загрузки', e);
		}
	}

	uploadDocument = async (e, results) => {
		e.preventDefault();

		const tokenInfo = await this.getUploadTokenRequest();

		let formData = new FormData();

		results.forEach((result) => {
			const [ e, file ] = result;
			formData.append('file', file);
		});

		formData.append('token', tokenInfo.token);

		const response = await uploadFile(tokenInfo.upload_url, formData);
		const value = response.url.replace('http://', 'https://');

		this.props.setCooperationAgreements({
			...this.props.cooperationAgreement,
			document_url: value
		});
	};

	// {"success":true,"headers":null,"payload":{"uuid":"5ce88458-c9ea-11eb-a881-021fa1a87d01","original_filename":"\u0441\u043f\u0438\u0440\u0430\u043b\u044c\u043d\u043e\u0435 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435.docx","filename":"gff8OBng1Wp80poZhUuDMCQdGkMFrmexYeygrH6J","content_type":"application\/vnd.openxmlformats-officedocument.wordprocessingml.document","file":"1a\/gff8OBng1Wp80poZhUuDMCQdGkMFrmexYeygrH6J.docx","size":13256,"deleted_at":null,"created_at":"2021-06-10 15:49:58","updated_at":"2021-06-10 15:49:58","is_temporary":false,"url":"https:\/\/rnis-test.t1-group.ru\/storage\/1a\/gff8OBng1Wp80poZhUuDMCQdGkMFrmexYeygrH6J.docx"},"errors":[]}

	removeDocument = () => {
		this.props.setCooperationAgreements({ ...this.props.cooperationAgreement, document_url: '' });
	};

	render() {
		const { unit } = this.state;

		return (
			<div>
				<div className="b-modal-agreement">
					<div className="b-modal-agreement_header">
						<div className="b-block_left">
							<div className="b-block _xl">
								<span className="b-modal-agreement_title">Соглашение с {_.get(this.state.unit, 'name', '...')}</span>
								{/* {this.get('is_signed_by_operator') ? (
									<ContextTooltip
										default={`${this.state.signed_by_operator || '-'}, ${moment(
											this.get('signed_by_operator_date')
										).format(formats.DATE)}`}
									>
										<span className={this.get('is_signed_by_operator') ? 'b-modal_checked' : 'b-modal_no-checked'} />
									</ContextTooltip>
								) : (
									<span className={this.get('is_signed_by_operator') ? 'b-modal_checked' : 'b-modal_no-checked'} />
								)} */}
							</div>
							<div className="b-block _xl">
								<div className="select-wrapper">
									{this.select('withComponent', this.getFilteredSystems(), {
										onChange: this.onComponentChange
									})}
								</div>
							</div>
							<div className="b-block _xl">
								<span className="b-modal-agreement_title b-modal-agreement_title_grey">
									{systems[this.get('component')]}
								</span>
								{/* {this.get('is_signed_by_company') ? (
									<ContextTooltip
										default={`${this.state.signed_by || '-'}, ${moment(this.get('signed_by_company_date')).format(
											formats.DATE
										)}`}
									>
										<span className={this.get('is_signed_by_company') ? 'b-modal_checked' : 'b-modal_no-checked'} />
									</ContextTooltip>
								) : (
									<span className={this.get('is_signed_by_company') ? 'b-modal_checked' : 'b-modal_no-checked'} />
								)} */}
							</div>
						</div>
						{this.props.mode === 'edit' && (
							<div className="b-block_right">
								<ContextTooltip default="Просмотреть соглашение">
									<span>
										<a href="javascript:void(0)" onClick={::this.getDocument} className="b-modal_sign b-modal_sign-3" />
									</span>
								</ContextTooltip>
							</div>
						)}
					</div>
					<div className="b-block _md">
						<div className="b-block__title">Срок действия по:</div>
					</div>
					<div className="b-block _md">
						{this.props.mode === 'edit' && (
							<div className="b-block__text">{moment(this.get('expiration_date')).format(formats.DATE)}</div>
						)}
					</div>
					{this.get('status') === 'terminated' ? (
						<div className="b-block _md">
							<div className="b-block__title">Расторгнуто</div>
						</div>
					) : null}
					<div className="b-block _md">
						<div className="b-block__title">Предупреждений:</div>
					</div>
					<div className="b-block _md">
						{this.props.mode === 'edit' && <div className="b-block__text">{this.get('warnings', []).length}</div>}
					</div>
					<div className="b-block _md">
						<div className="b-block__title">Открытых:</div>
					</div>
					<div className="b-block _md">
						{this.props.mode === 'edit' && (
							<div className="b-block__text">
								{_.filter(this.get('warnings', []), (item) => !item.warning_date_remove).length}
							</div>
						)}
					</div>
				</div>
				<Accordion>
					<AccordionItem opened={true} title="Реквизиты контрагента">
						<div className="b-block _xl">
							<div className="b-block__title">Наименование:</div>
							{this.selectAsync('unit_name', this.state.unit ? ::this.loadUnits : ::this.loadUnitsDebounce, {
								onChange: this.onСompanyChange
							})}
						</div>
						{/* <div className="b-block _md">
							<div className="b-block__text">{_.get(unit, 'name')}</div>
						</div> */}
						<div className="b-block _md">
							<div className="b-block__title">ИНН:</div>
						</div>
						<div className="b-block _md">
							<div className="b-block__text">{_.get(unit, 'inn')}</div>
						</div>
						<div className="b-block _md">
							<div className="b-block__title">ОГРН:</div>
						</div>
						<div className="b-block _md">
							<div className="b-block__text">{_.get(unit, 'ogrn')}</div>
						</div>
						<div className="b-block _md">
							<div className="b-block__title">Адрес фактический:</div>
						</div>
						<div className="b-block _md">
							<div className="b-block__text">{_.get(unit, 'actual_address')}</div>
						</div>
						<div className="b-block _md">
							<div className="b-block__title">ФИО директора:</div>
						</div>
						<div className="b-block _md">
							<div className="b-block__text">{_.get(unit, 'senior')}</div>
						</div>
						<div className="b-block _md">
							<div className="b-block__title">Телефон:</div>
						</div>
						<div className="b-block _md">
							<div className="b-block__text">{_.get(unit, 'phone')}</div>
						</div>
						<div className="b-block _md">
							<div className="b-block__title">Email:</div>
						</div>
						<div className="b-block _md">
							<div className="b-block__text">{_.get(unit, 'email')}</div>
						</div>
					</AccordionItem>
					<AccordionItem opened={true} title="Профильные документы">
						<div className="file-load">
							{!this.props.cooperationAgreement.document_url ? (
								<FileReaderInput as="binary" id="my-file-input" onChange={this.uploadDocument}>
									<a href="javascript:void(0)">Загрузить соглашение</a>
								</FileReaderInput>
							) : (
								<div>
								<a href="javascript:void(0)" onClick={this.removeDocument}>
									Удалить соглашение
								</a>
									<a href={this.props.cooperationAgreement.document_url} target="_blank">
									{`  Посмотреть соглашение`}
								</a>
								</div>
							)}
						</div>
					</AccordionItem>
				</Accordion>
			</div>
		);
	}

	getCN(text) {
		const matches = /CN=([^,]+)(,|$)/.exec(text);
		if (matches) {
			return matches[1].replace(/\\"/g, '"');
		}
		return '';
	}

	async getDocument() {
		let agreement = _.cloneDeep(this.state.cooperation_agreement);

		agreement.company_ecp = {
			UNIT_ECP_SERIAL: _.get(this.state.company_ecp, 'thumbprint'),
			UNIT_ECP_ISSUER: this.getCN(_.get(this.state.company_ecp, 'issuerName')),
			UNIT_ECP_VALID_FROM: moment(_.get(this.state.company_ecp, 'certFromDate')).format(formats.DATE),
			UNIT_ECP_VALID_TO: moment(_.get(this.state.company_ecp, 'certTillDate')).format(formats.DATE),
			UNIT_ECP_NAME: this.getCN(_.get(this.state.company_ecp, 'subjectName'))
		};

		agreement.operator_ecp = {
			OPERATOR_ECP_SERIAL: _.get(this.state.operator_ecp, 'thumbprint'),
			OPERATOR_ECP_ISSUER: this.getCN(_.get(this.state.operator_ecp, 'issuerName')),
			OPERATOR_ECP_VALID_FROM: moment(_.get(this.state.operator_ecp, 'certFromDate')).format(formats.DATE),
			OPERATOR_ECP_VALID_TO: moment(_.get(this.state.operator_ecp, 'certTillDate')).format(formats.DATE),
			OPERATOR_ECP_NAME: this.getCN(_.get(this.state.operator_ecp, 'subjectName'))
		};

		const response = await this.props.documentCooperationAgreement(agreement);

		if (response.isOk) {
			download(`data:application/pdf;base64,${response.payload.content}`, `Соглашение о взаимодействии.pdf`);
		} else {
			response.showErrors();
		}
	}

	renderDocument(document, index) {
		return (
			<div key={index} className="b-block _xl">
				<div className="b-block__title">{document.filename}:</div>
				<div className="b-block__text">
					Тип:
					<span>{_.get(_.find(this.state.request_documents, { value: document.request_document_uuid }), 'label')}</span>
					<br />
					Оформлен {moment(document.issued_at).format(formats.DATE)} <br />
					Действует до {moment(document.valid_to).format(formats.DATE)}
				</div>
				<div className="archives-menu">
					<span className="archives-menu__item download">
						<a href={document.url} target="_blank">
							Скачать файл
						</a>
					</span>
				</div>
			</div>
		);
	}
}
