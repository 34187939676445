import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import * as alerts from "helpers/alerts";

import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import PageModal from 'components/ui/page-modal';
import {getRouteVariant, updateRouteVariant, createRouteVariant} from "store/reducers/routes/route_variants";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import FilterHeader from "components/ui/filter-header";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getStopPoints} from "store/reducers/geo/stop-points";
import './variant_view.less';
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import StopPointAddEditor from "components/modules/kiutr/routes/view/stop_point_add_editor";
import classNames from 'classnames';
import Input from "components/ui/input";
import Block from "components/ui/form/block";
import {events} from 'dom-helpers';
import ContextTooltip from "components/ui/context-tooltip";
import GlobalLoaderComponent from "components/ui/global-loader";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getRoute} from "store/reducers/routes/route_editor";
import Settings from 'settings';
import L from 'leaflet';
import $ from 'jquery';
import Checkbox from "components/ui/form/checkbox";
import currentUser from 'helpers/current-user';
import WarningModal from "components/ui/WarningModal/warning-modal";

import ReactDOMServer from 'react-dom/server';
import {print} from "helpers/print";
import KiutrRouteVariantsPrintComponent from "components/modules/kiutr/routes/print/variants";


@propTypes({
    uuid: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    showIntervalMaps: PropTypes.func.isRequired,
    showVariantPointMap: PropTypes.func.isRequired,
    routeUuid: PropTypes.string.isRequired,
    isPageWithDetect:  PropTypes.bool,
})

@connect(state => ({}), {
    getRoute,
    getDictionaryList,
    getRouteVariant,
    getStopPoints,
    updateRouteVariant,
    createRouteVariant
})

export default class KiutrRouteVariantViewComponent extends Component {

    title = '';
    modelClass = 'App\\Model\\RouteVariant';
    modalConfirmation = window.RNIS_SETTINGS.modal_confirmation;

    state = {
        routeVariant: null,
        uuid: null,
        stopPoints: {},
        showEditor: false,
        streetsVisible: false,
        loading: false,
        route_types: [],
        route: {},
        needSave: false,
        isWarningModal: false,
        // пришлось и сюда добавить флаг, чтобы не прокидывать от родителя
        isEditingNowForm: false,
        isNeedReverseRouteVariantsFill: true,
    };

    constructor(...args) {
        super(...args);

        this.onKeyup = ::this._onKeyup;
        this.renderWarningModal = this.renderWarningModal.bind(this);
        this.closeCheckModal = this.closeCheckModal.bind(this);
        this.openCheckModal = this.openCheckModal.bind(this);
    }

    _onKeyup(e) {
        if (String.fromCharCode(e.which) === 'B' && e.ctrlKey === true) {
            this.showEditor();
        }
        if (String.fromCharCode(e.which) === 'S' && e.ctrlKey === true) {
            this.saveVariant();
        }
    }

    componentWillUnmount() {
        $(document).off('keyup', this.onKeyup);
    }

    async componentWillUpdate(props, state) {
        const propsUuid = (props.uuid === 'create') ? null : props.uuid;
        if (propsUuid !== state.uuid) {
            if (propsUuid) {
                await this.setState({
                    uuid: propsUuid,
                });
                this.loadData();
            } else {
                this.setState({
                    uuid: null,
                    routeVariant: null,
                });
            }
        }
    }

    componentDidMount() {
        $(document).on('keyup', this.onKeyup);
        this.loadDictionaries([
            'route_types',
        ]);
        if(window.RNIS_SETTINGS.CITY_NOVOSIBIRSK && window.RNIS_SETTINGS.edit_length_routes_role_name.length > 0){
                const userRoles = currentUser.user.roles.map((role) => role.name);
                for(let i = 0; i < userRoles.length; i++){
                    if(window.RNIS_SETTINGS.edit_length_routes_role_name.includes(userRoles[i])){
                        this.roleCantChangeDistanceInNSO = false
                        break
                    }
                }
        }
    }

    roleCantChangeDistanceInNSO = true

    async loadRoute(uuid) {
        const response = await this.props.getRoute(uuid);

        if (response.isOk) {
            this.setState({
                route: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }

    isBothSide() {
        return _.get(_.find(this.state.route_types, {value: this.state.route.route_type_uuid}), 'label') === 'Маятниковый';
    }

    async loadData() {
        this.setState({loading: true});
        const response = await this.props.getRouteVariant(this.state.uuid);
        this.setState({loading: false});
        if (response.isOk) {
            await this.setState({
                routeVariant: this.composeItem(response.payload),
            });
            this.loadStopPoints();
            this.loadRoute(response.payload.route_uuid);
        } else {
            response.showErrors();
        }
    }

    composeItem(payload) {
        _.each(['forward_points', 'reverse_points'], (direction) => {
            payload[direction] = _.map(payload[direction], (point) => {
                point.distance_to_the_next_point = (point.distance_to_the_next_point / 1000).toFixed(2);
                return point;
            });
        });

        return payload;
    }

    async loadStopPoints() {
        const stopPoints = _.uniq(_.filter(_.concat(_.map(this.state.routeVariant.forward_points, 'type_uuid'), _.map(this.state.routeVariant.reverse_points, 'type_uuid'))));
        const response = await this.props.getStopPoints({
            pagination: {
                page: 1,
            },
            filters: {
                withUuid: stopPoints,
            },
        });
        if (response.isOk) {
            this.setState({
                stopPoints: _.keyBy(response.payload.items, 'uuid'),
            });
        }
    }

    openCheckModal() {
        this.setState({isWarningModal: true})
    }

    closeCheckModal() {
        this.setState({isWarningModal: false})
    }

    renderWarningModal() {
        return (
            <WarningModal
                closeModal={this.closeCheckModal}
                goToAnotherPage={::this.props.onClose}
                isNeedBackground={true}
            />
        )
    }

    async printVariant() {
        this.setState({loading: true});

        const currentVariant = await this.props.getRouteVariant(this.state.uuid);
        await this.loadRoute(currentVariant.payload.route_uuid)
        await this.loadStopPoints();

        this.setState({loading: false});
        if (this.state.route && currentVariant && this.state.stopPoints) {
            const style = `
            <style>
                @page {
                    size: A4;
                }
                
                table {
                    width: 100%;
                    border-collapse: collapse;
                    text-align: center;
                }
                
                th, td {
                    border: 1px black solid !important;
                    padding: 5px;
                    page-break-inside: avoid;
                }
                
                .no-border {
                    border: none !important;
                    padding: 0 !important;
                }
                
                .route-number, .route-name, .variant-name, .variant-direction {
                    text-align: center;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border: 1px #000 solid;
                }
                
                .route-number {
                    font-weight: bold;
                    border-bottom: none;
                }
                .route-name {
                    font-style: italic;
                    color: #666;
                    margin-bottom: 10px;
                }
                .variant-name {
                    font-weight: bold;
                }
                
                .variant-name, .variant-direction {
                    border-bottom: none;
                }
            </style>
        `;

            print(style + ReactDOMServer.renderToStaticMarkup(<KiutrRouteVariantsPrintComponent
                route={this.state.route}
                variants={[currentVariant.payload]}
                stopPoints={_.mapValues(this.state.stopPoints, (point) => point.title )}
            />));
        }
    }

    handleTumbler = () => {
        this.setState(prevState => ({
            isNeedReverseRouteVariantsFill: !prevState.isNeedReverseRouteVariantsFill
        }))
    }
    
    render() {
        const reverseStopPointsTumblerMargin = {marginTop:'30px'}
        const buttons = (
            <ModalTopMenuButtons>

                <ModalTopMenuList className="top-menu_modal_edit">
                    {this.state.uuid && window.RNIS_SETTINGS.reverseStopPointsTumbler &&
                    <div className="top-menu_modal__item" >
                        <span className="b-slider__text" style={reverseStopPointsTumblerMargin}>изменять обратное направление</span>
                        <div className="b-slider__control" onClick={this.handleTumbler} style={reverseStopPointsTumblerMargin}>
                            <div className={classNames("b-slider__line", this.state.isNeedReverseRouteVariantsFill && "_selected_all")} />
                            <div className={classNames("b-slider__circle", this.state.isNeedReverseRouteVariantsFill && "_selected_all")} />
                        </div>
                    </div>}

                    {(this.state.uuid && this.state.routeVariant) ? ([

                        ...(window.RNIS_SETTINGS.CITY_TULA && this.props.params.component === 'children' ? [
                            <ContextTooltip key="base-table-list.print" code="base-table-list.print" default="Печать">
                                <ModalTopMenuListItem
                                    className="b-icon-link_icon_print"
                                    onClick={::this.printVariant}
                                />
                            </ContextTooltip>
                        ] : []),
                        <ContextTooltip key="base-editor.create" code="base-editor.create" default="Добавить">
                            <ModalTopMenuListItem
                                className="b-icon-link_icon_plus"
                                onClick={::this.showEditor}
                            />
                        </ContextTooltip>,
                        <ModalTopMenuListSeparator key="separator"/>,
                    ]) : null}

                    {this.state.uuid ? [
                        <ContextTooltip key="kiutr-routes-variants.map" code="kiutr-routes-variants.map"
                                        default="Карта">
                            <ModalTopMenuListItem
                                className="b-icon-link_params b-icon-link_icon_map"
                                onClick={::this.showMap}
                            />
                        </ContextTooltip>,

                        <ContextTooltip key="kiutr-routes-variants.intervals" code="kiutr-routes-variants.intervals"
                                        default="Варианты интервалов времени">
                            <ModalTopMenuListItem
                                className="b-icon-link_params b-icon-link_icon_intervals"
                                onClick={this.props.showIntervalMaps.bind(this, this.props.uuid)}
                            />
                        </ContextTooltip>
                    ] : null}
                    {(currentUser.can('com.rnis.system.permission.audit', 'read') && this.modelClass) ?
                        (
                            <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                            default="Журнал аудита">
                                <ModalTopMenuListItem
                                    className="b-icon-link_params b-icon-link_icon_history"
                                    href={`/system/audit/${this.props.uuid}?class=${this.modelClass}`}
                                />
                            </ContextTooltip>
                        ) : null}
                </ModalTopMenuList>

                <ModalTopMenuButtonsSeparator/>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить" >
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.isPageWithDetect && this.modalConfirmation && this.state.isEditingNowForm ? this.openCheckModal : ::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        const editor = this.state.showEditor ? (
            <StopPointAddEditor
                params={this.props.params}
                mode="add"
                uuid={null}
                number={_.filter(this.state.routeVariant[((this.state.currentFilterItem || 0) === 0) ? 'forward_points' : 'reverse_points'], {point_type: 'stop_point'}).length + 1}
                onAdd={::this.onPointAdd}
                onSubmit={() => {
                }}
                onClose={::this.hideEditor}
                direction={((this.state.currentFilterItem || 0) === 0) ? 'forward_points' : 'reverse_points'}
                routeUuid={this.props.routeUuid}
            />
        ) : null;

        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;

        return (
            <div>
                <PageModal
                    header={{title: this.state.uuid ? 'Остановочные пункты варианта движения' : 'Создание варианта движения', buttons}}
                    //className="profile-modal b-modal-edit b-modal-route stopping-points-modal _tablet _open"
                    className="b-modal-edit b-modal-route stopping-points-modal"
                    withFade={false}
                    onClose={this.props.onClose}
                    buttons={!this.state.loading ? [
                        <div key="tooltip" className="b-modal__footer-txt">Вы хотите сохранить все изменения?</div>,
                        <a key="cancel" href="#"
                           className="b-button b-button_size_md b-button_white b-button_shadow_gray b-button_cancel"
                           onClick={::this.cancel}>Отменить</a>,
                        <a key="save" href="#" className="b-button b-button_red b-button_size_md b-button_save"
                           onClick={::this.saveVariant}>{(this.state.uuid ? 'Сохранить' : 'Создать')}</a>
                    ] : []}
                >
                    {loader}
                    {this.renderInfo()}
                </PageModal>
                {editor}
                {this.state.isWarningModal && this.modalConfirmation ? this.renderWarningModal() : null}
            </div>
        );
    }

    async getPairedStopPoints(latitude, longitude) {
        const threshold = Settings.get('paired_stop_points_threshold', 300);

        if (!latitude || !longitude) {
            return;
        }
        const bounds = L.latLng(latitude, longitude).toBounds(threshold);

        const response = await this.props.getStopPoints({
            filters: {
                withBoundingBoxExact: {
                    left_top: {
                        latitude: bounds.getNorth(),
                        longitude: bounds.getWest(),
                    },
                    right_bottom: {
                        latitude: bounds.getSouth(),
                        longitude: bounds.getEast(),
                    },
                },
            },
        });

        if (response.isOk) {
            return _.map(_.uniqBy(response.payload.items, 'uuid'), (item) => ({
                label: `${item.register_number} ${item.title}`,
                value: item.uuid,
                latitude: item.latitude,
                longitude: item.longitude,
            }));
        } else {
            response.showErrors();
        }
    }

    async onPointAdd(points) {
        const point = points[0];
        const pairedPoint = points[1];
        const reopen = points[2];

        const isForward = (this.state.currentFilterItem || 0) === 0;
        point.is_forward_direction = isForward;

        const direction = isForward ? 'forward_points' : 'reverse_points';

        let routeVariant = this.state.routeVariant;

        let index = 0;
        let count = 0;
        let inserted = false;
        while (index <= routeVariant[direction].length - 1) {
            if (routeVariant[direction][index].point_type === 'stop_point') {
                count++;
                if (count === _.toInteger(point.number)) {
                    const reverseIndex = routeVariant.reverse_points.length - index;
                    routeVariant[direction].splice(index, 0, point);
                    if (window.RNIS_SETTINGS.reverseStopPointsTumbler) {
                        if (this.state.isNeedReverseRouteVariantsFill) {
                            if ((direction === 'forward_points') && this.isBothSide()) {
                                routeVariant.reverse_points.splice(reverseIndex, 0, pairedPoint);
                            }
                        }
                    } else {
                        if ((direction === 'forward_points') && this.isBothSide()) {
                            routeVariant.reverse_points.splice(reverseIndex, 0, pairedPoint);
                        }
                    }
                    inserted = true;
                    break;
                }
            }
            index++;
        }
        if (!inserted) {
            routeVariant[direction].push(point);
            if (window.RNIS_SETTINGS.reverseStopPointsTumbler) {
                if (this.state.isNeedReverseRouteVariantsFill) {
                    if ((direction === 'forward_points') && this.isBothSide()) {
                        routeVariant.reverse_points.splice(0, 0, pairedPoint);
                    }
                }
            } else {
                if ((direction === 'forward_points') && this.isBothSide()) {
                    routeVariant.reverse_points.splice(0, 0, pairedPoint);
                }
            }
        }

        await this.setState({
            routeVariant,
            needSave: true,
        });

        this.loadStopPoints();

        if (reopen) {
            await this.hideEditor();
            this.showEditor();
        } else {
            this.hideEditor();
        }
    }

    async create(routeVariant) {
        routeVariant.route_uuid = this.props.routeUuid;

        const response = await this.props.createRouteVariant(routeVariant);
        if (response.isOk) {
            return response.payload.uuid;
        }

        response.showErrors();
        return null;
    }

    async save(routeVariant) {
        this.setState({loading: true});
        const response = await this.props.updateRouteVariant(routeVariant);
        this.setState({loading: false});
        if (!response.isOk) {
            response.showErrors();
            return false;
        }

        return true;
    }

    cancel() {
        this.props.onClose();
    }

    async saveVariant(e, callback = null) {
        e && e.preventDefault();

        let item = _.cloneDeep(this.state.routeVariant);
        let uuid = null;

        if (this.state.uuid) {
            uuid = this.state.uuid;
            _.each(['forward_points', 'reverse_points'], (direction) => {
                item[direction] = _.map(item[direction], (point) => {
                    point.distance_to_the_next_point = point.distance_to_the_next_point * 1000;
                    return point;
                });

                return item;
            });

            if (!await this.save(item)) {
                return;
            }
        }
        else {
            this.setState({loading: true});
            uuid = await this.create(item);
            this.setState({loading: false});
        }
        this.setState({loading: false});

        if (callback) {
            callback();
        } else {
            if (uuid) {
                this.props.onSubmit(uuid);
            }
        }
    }

    showEditor() {
        this.setState({
            showEditor: true,
        });
    }

    hideEditor() {
        this.setState({
            showEditor: false,
        });
    }

    textInput(fieldName, props = {}) {
        return (
            <Input
                size="md"
                value={_.get(this.state, fieldName)}
                onChange={this.onChangeInput.bind(this, fieldName)}
                name={fieldName}
                {...props}/>
        );
    }

    get(fieldName) {
        return _.get(this.state.routeVariant, fieldName);
    }

    renderInfo() {
        return (
            <div className="VariantView">
                <div className="b-modal__block">
                    <Block title="Наименование">
                        {this.editable() ? this.textInput('routeVariant.name') : this.get('name')}
                    </Block>
                    <Block title="Комментарий">
                        {this.editable() ? this.textInput('routeVariant.comment') : this.get('comment')}
                    </Block>
                </div>

                {(this.state.uuid && this.state.routeVariant) ? [
                    (this.isBothSide()) ? (
                        <FilterHeader key="filter_header"
                                      items={['Прямое направление', 'Обратное направление']}
                                      currentItem={this.state.currentFilterItem || 0}
                                      onChange={(e) => {
                                          this.setState({currentFilterItem: e.value});
                                      }}
                        />
                    ) : null,
                    this.renderDirection('forward_points'),
                    this.isBothSide() ? this.renderDirection('reverse_points') : null,
                ] : null}
            </div>
        );
    }

    isDefault() {
        return this.state.routeVariant && this.state.routeVariant.is_default;
    }

    editable() {
        return !this.isDefault();
    }

    toggleStreetsVisible() {
        this.setState({
            streetsVisible: !this.state.streetsVisible,
        });
    }

    renderDirection(direction) {
        const current = this.state.currentFilterItem || 0;
        if ((current === 0 && direction !== 'forward_points') || (current === 1 && direction !== 'reverse_points')) {
            return null;
        }
        return (
            <Accordion key={`direction_${direction}`} className="indent-none">
                <AccordionItem opened={true} collapsable={false}
                               title={`Вариант движения "${this.state.routeVariant.name}"`}
                               afterTitle={<div className="accordion__menu">
                                   <ContextTooltip code="route-variant.streets-visible"
                                                   default="Показать/скрыть улицы">
                                       <div
                                           className={classNames('accordion__link accordion__link_icon accordion__link_icon_streets', {
                                               active: this.state.streetsVisible,
                                           })} onClick={::this.toggleStreetsVisible}/>
                                   </ContextTooltip>
                               </div>}>
                    <div className="Table indent-none">
                        <TableContainer>
                            <table className="b-table">
                                <thead>
                                <tr>
                                    <th width="50px">№</th>
                                    <th>Остановочный пункт</th>
                                    <th key="distance'">Расст. между ост. пунктами, км</th>
                                    <th key="time">Время между ост. пунктами, мин</th>
                                    <th key="control">КП</th>
                                    {this.state.streetsVisible ? (
                                        <th key="streets">Улицы</th>
                                    ) : null}
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.routeVariant[direction].map(this.renderPoint.bind(this, direction))}
                                </tbody>
                            </table>
                        </TableContainer>
                        {window.RNIS_SETTINGS.CITY_NOVOSIBIRSK ? (
                            <div style={{'padding': '15px 20px'}}>Итоговая протяжённость: {(this.state.routeVariant[direction].reduce( (acc, el)  => {
                                return acc + (Number(el.distance_to_the_next_point) ? Number(el.distance_to_the_next_point) : 0);
                            }, 0)).toFixed(2)}</div>
                        ) : null}

                    </div>
                </AccordionItem>
            </Accordion>
        );
    }

    deleteRow(direction, index, e) {
        e.preventDefault();

        let routeVariant = this.state.routeVariant;
        alerts.prompt('Удалить точку маршрута?', '', () => {
            routeVariant[direction].splice(index, 1);

            this.setState({
                routeVariant,
                needSave: true,
            });
        });
    }

    renderPoint(direction, point, index) {
        if (point.point_type !== 'stop_point') {
            return;
        }

        const streets = this.getAllStopPointStreets(direction, index);

        return (
            <tr key={index}>
                <td className="align-center">
                    {this.stopPointIndex(direction, index)} <a href="#"
                                                               onClick={this.deleteRow.bind(this, direction, index)}>&times;</a>
                </td>
                <td>{_.get(this.state.stopPoints[point.type_uuid], 'register_number')} {_.get(this.state.stopPoints[point.type_uuid], 'title')}</td>

                <td key={`distance:${index}`}>
                    {this.roleCantChangeDistanceInNSO
                        ? <Input size="md" value={point.distance_to_the_next_point}
                             positive={true}
                             type="number"
                             onChange={this.onChangeInput.bind(this, `routeVariant.${direction}.${index}.distance_to_the_next_point`)}/>
                        : point.distance_to_the_next_point
                 }
                </td>
                <td key={`time:${index}`}>
                    <Input size="md" value={point.time_to_get_to_the_next_point}
                           positive={true}
                           type="number"
                           onChange={this.onChangeInput.bind(this, `routeVariant.${direction}.${index}.time_to_get_to_the_next_point`)}/>
                </td>
                <td>
                    <Checkbox
                        checked={point.is_control}
                        onChange={this.toggleControl.bind(this, direction, index)}
                    />
                </td>
                {this.state.streetsVisible ? (
                    <td key={`streets:${index}`}>
                        {_.map(streets, (street, index) => <span className="street" key={index}>{street}</span>)}
                    </td>
                ) : null}
            </tr>
        );
    }

    toggleControl(direction, index) {
        this.setValue(`routeVariant.${direction}.${index}.is_control`, !this.get(`${direction}.${index}.is_control`));
        this.props.turnedEditingMode()
        this.setState({isEditingNowForm: true})
    }

    onChangeInput(field, {target: {value}}) {
        this.setValue(field, value);
        this.props.turnedEditingMode()
        this.setState({isEditingNowForm: true})
    }

    async setValue(field, value) {
        let state = this.state;

        _.set(state, field, value);

        this.setState(state);
    }

    getAllStopPointStreets(direction, index) {
        let streets = this.state.routeVariant[direction][index].streets || [];
        index++;

        while (index < this.state.routeVariant[direction].length && this.state.routeVariant[direction][index].point_type !== 'stop_point') {
            streets = _.concat(streets, this.state.routeVariant[direction][index].streets);
            index++;
        }

        return this.filterStreets(streets);
    }

    filterStreets(streets) {
        let result = [];
        let current = null;

        _.each(streets, (name) => {
            if (name !== current) {
                current = name;
                result.push(name);
            }
        });

        return result;
    }

    stopPointIndex(direction, index) {
        let count = 0;
        while (index >= 0) {
            if (this.state.routeVariant[direction][index].point_type === 'stop_point') {
                count++;
            }
            index--;
        }
        return count;
    }

    showMap() {
        if (this.state.needSave) {
            this.saveVariant(null, () => {
                this.props.showVariantPointMap(this.props.uuid);
            });
        } else {
            this.props.showVariantPointMap(this.props.uuid);
        }
    }
}
