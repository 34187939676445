import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapMarker from "components/ui/map/base/marker";
import ControlPointIcon from "components/ui/map/markers/icons/control-point-icon";

@propTypes({
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
    name: PropTypes.string,
})

@defaultProps({
    onDrag: () => {
    },
    onDragEnd: () => {
    },
    name: null,
})

export default class ControlPointMarker extends AbstractMapMarker {

    getOptions() {
        return {
            icon: ControlPointIcon.getIcon(),
            riseOnHover: true,
            draggable: true,
        };
    }

    componentWillMount() {
        super.componentWillMount();

        this.getMarker().on('drag', this.props.onDrag);
        this.getMarker().on('dragend', this.props.onDragEnd);

        if (this.props.name) {
            this.getMarker().bindTooltip(this.props.name, {sticky: true});
        }
    }

}