import React from 'react';
import PageModalComponent from 'components/ui/page-modal';
import { connect } from 'react-redux';
import {
	setCurrentTaskRoadParts,
	setAddRoadModal,
	setRoadParts,
	setSearchRoadParts,
	setCurrentTaskType
} from '../../../../../store/reducers/kurs/actions';
import ModalTopMenuButtons from 'components/ui/modal/modal-top-menu-buttons';
import ModalTopMenuButton from 'components/ui/modal/modal-top-menu-button';
import ContextTooltip from 'components/ui/context-tooltip';
import { AddRoadPartsModalBody } from './add-road-parts-modal-body';
import './styles.less';
import { getRoadParts } from '../../../../../store/reducers/kurs/road_parts';
import {
	getCurrentTaskRoadParts,
	getLoadingRoadPartsSelector,
	getRoadPartsForAddModalSelector,
	getSearchRoadPartsInput,
	getCurrentTaskTypeSelector,
	getItemsForAddToTaskSelector,
	getIsFormValidSelector,
	getWorkTypesForRoadPartsModalSelector
} from '../../../../../store/reducers/kurs/selectors';
import { debounce } from 'lodash';
import { getContractsTasks } from '../../../../../store/reducers/kurs/tasks';
@connect(
	(state) => ({
		roadParts: getRoadPartsForAddModalSelector(state),
		currentTaskRoadParts: getCurrentTaskRoadParts(state),
		searchInput: getSearchRoadPartsInput(state),
		loadingRoadParts: getLoadingRoadPartsSelector(state),
		tasksOptions: getWorkTypesForRoadPartsModalSelector(state),
		currentTaskType: getCurrentTaskTypeSelector(state),
		itemsForAddTask: getItemsForAddToTaskSelector(state),
		isFormValid: getIsFormValidSelector(state)
	}),
	(dispatch) => ({
		onSetAddRoadModal: (flag) => dispatch(setAddRoadModal(flag)),
		onGetRoadParts: (payload) => dispatch(getRoadParts(payload)),
		onSetCurrentTaskRoadParts: (currentTaskRoadParts) => dispatch(setCurrentTaskRoadParts(currentTaskRoadParts)),
		onSetRoadParts: (roadParts) => dispatch(setRoadParts(roadParts)),
		onSetSearchRoadParts: (searchInput) => dispatch(setSearchRoadParts(searchInput)),
		onGetTasks: (filters) => dispatch(getContractsTasks(filters)),
		onSetCurrentTaskType: (taskType) => dispatch(setCurrentTaskType(taskType))
	})
)
export class AddRoadPartsModal extends React.Component {
	componentDidMount() {
		this.handleGetRoads();
		this.props.onGetTasks({
			pagination: {
				page: 1,
				limit: 100
			},

		});
	}

	handleGetRoads = () => {
		this.props.onGetRoadParts({
			search: this.props.searchInput,
			response_data: [ 'items/uuid', 'items/register_number', 'items/name', 'items/length'],
			filters: {
				withImplementers: [ this.props.choosenUnit ]
			}
		});
	};
	handleCloseModal = () => {
		this.props.onSetAddRoadModal(false); // close modal
		this.props.onSetCurrentTaskRoadParts([]); // clear list of choosenTasks
	};
	handleChangeDirection = ({ value }, uuid) => {
		this.props.onSetCurrentTaskRoadParts(
			this.props.currentTaskRoadParts.map((roadPart) => {
				if (roadPart.uuid === uuid) {
					return { ...roadPart, direction: value };
				}
				return roadPart;
			})
		);
	};

	handleAddRoadPartsToCurrentTask = (roadPart) => {
		if(Array.isArray(roadPart)){
			const roadParts = roadPart.map(part =>({ ...part, direction: 'forward' }))
			const roadPartsUuid = roadParts.map(roadPart => roadPart.uuid)
			const setRoadParts = this.props.roadParts.filter(el=> !roadPartsUuid.includes(el.uuid))
			this.props.onSetRoadParts(setRoadParts)
			this.props.onSetCurrentTaskRoadParts(this.props.currentTaskRoadParts.concat(roadParts))
		} else {
			roadPart = { ...roadPart, direction: 'forward' }; // set direction to forward by default
			this.props.onSetRoadParts(this.props.roadParts.filter((el) => el.uuid !== roadPart.uuid));
			this.props.onSetCurrentTaskRoadParts([ ...this.props.currentTaskRoadParts, roadPart ]);
		}
	};

	handleRemoveRoadPartsToCurrentTask = (roadPart) => {
		this.props.onSetCurrentTaskRoadParts(this.props.currentTaskRoadParts.filter((el) => el.uuid !== roadPart.uuid));
		this.props.onSetRoadParts([ roadPart, ...this.props.roadParts ]);
	};

	handleChangeSearchInput = ({ target: { value } }) => {
		this.props.onSetSearchRoadParts(value);
		debounce(this.handleGetRoads, 1000)();
	};

	handleChangeTaskOptions = ({ value }) => {
		this.props.onSetCurrentTaskType(value);
	};

	handleConfirmAddedRoadParts = () => {
		if (!this.props.isFormValid) return; // do nothing if form not valid
		const field = this.props.isFactVisible ? 'items_fact' : 'items';
		this.props.itemsForAddTask.forEach((item) => this.props.addItem('lastIndex', field, item));
		this.handleCloseModal();
	};

	render() {
		const {
			roadParts,
			currentTaskRoadParts,
			searchInput,
			loadingRoadParts,
			tasksOptions,
			currentTaskType,
			isFormValid
		} = this.props;
		return (
			<PageModalComponent
				header={{
					title: 'Добавление участков дорог к заданию',
					buttons: (
						<ModalTopMenuButtons>
							<ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
								<ModalTopMenuButton className="_close" onClick={this.handleCloseModal} />
							</ContextTooltip>
						</ModalTopMenuButtons>
					)
				}}
				onClose={this.handleCloseModal}
				className="Add-road-parts-modal"
				withFade
				buttons={[
					<div key="tooltip" className="b-modal__footer-txt">
						Вы хотите добавить выбранные участки?
					</div>,
					<a
						key="cancel"
						href="#"
						className="b-button b-button_size_md b-button_white b-button_shadow_gray b-button_cancel"
						onClick={this.handleCloseModal}
					>
						Отменить
					</a>,
					<a
						key="save"
						href="#"
						className="b-button b-button_red b-button_size_md b-button_save"
						onClick={this.handleConfirmAddedRoadParts}
						style={{ backgroundColor: !isFormValid ? 'gray' : '' }}
					>
						Добавить
					</a>
				]}
			>
				<AddRoadPartsModalBody
					onChangeDirection={this.handleChangeDirection}
					roadParts={roadParts}
					currentTaskRoadParts={currentTaskRoadParts}
					onClickPlusButton={this.handleAddRoadPartsToCurrentTask}
					onClickMinusButton={this.handleRemoveRoadPartsToCurrentTask}
					searchInput={searchInput}
					onChangeSearchInput={this.handleChangeSearchInput}
					loadingRoadParts={loadingRoadParts}
					tasksOptions={tasksOptions}
					onChangeTask={this.handleChangeTaskOptions}
					currentTaskType={currentTaskType}
				/>
			</PageModalComponent>
		);
	}
}
