import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import formats from "dictionaries/formats";
import moment from "moment";
import '../../../../../modules/kiutr/routes/schedule/view.less'
import '../../../../../modules/kiutr/routes/schedule/turn.less'

export default class  extends Component {

    render() {
        if (!this.props.turns) {
            return null;
        }

        return this.renderTurn()
    }

    getProductionRuns() {
    const firstTurn = _.head(this.props.turns)

       return _.filter(firstTurn.runs, (run) => (run.type === 'production_forward' || run.type === 'production_reverse'));
    }

    renderContent(productionRuns) {

        let forwardDefaultVariants = [];
        let reverseDefaultVariants = [];
 
        if(this.props.defaultVariant) {
         forwardDefaultVariants = _.cloneDeep(this.props.defaultVariant).map((variant) => {
             variant.items.map((item) => {
                 item.inclusions = item.inclusions.filter(el => el.is_forward)
                 return item
             })
            
             return variant
         })
         reverseDefaultVariants = _.cloneDeep(this.props.defaultVariant).map((variant) => {
             variant.items.map((item) => {
                 item.inclusions = item.inclusions.filter(el => !el.is_forward)
                 return item
             })
            
             return variant
         })
 
         reverseDefaultVariants.reverse()
        }

        // TODO: Подумать, как избавиться от лишних циклов

         return (
             <div>
                 <div className='period'>Период действия
                     с {this.props.schedule ? moment(this.props.schedule.date_from).format(formats.DATE) : ''}&nbsp;
                     по {this.props.schedule ? moment(this.props.schedule.date_to).format(formats.DATE) : ''}
                 </div>
                 <table className='turnOfficialPrint'>
                 <tfoot>
                    <tr>
                        <td>М.П.</td>
                        <td>_______________</td>
                        <td />
                        <td>_____________________</td>
                    </tr>
                </tfoot>
                     <thead>
                         <tr>
                             <td>Наименование остановочного пункта</td>
                             <td>Регистрационный номер остановочного пункта</td>
                             <td>Дни прибытия (отправления)</td>
                             <td>Прибытие, час:мин</td>
                             <td>Стоянка, час:мин</td>
                             <td>Отправление, час:мин</td>
                         </tr>
                     </thead>
                     <tr><td colSpan='6'>Прямое направление</td></tr>
                     {forwardDefaultVariants && forwardDefaultVariants.map((variant) => {   
                                 return (
                                     <tbody>
                                             {
                                                 productionRuns.map((run) => { 
                                                     let point = null;
                                                     _.each(variant.items, (item) => {
                                                         point = _.find(item.inclusions, {
                                                             route_variant_uuid: run.route_variant_uuid,
                                                             is_forward: run.type === 'production_forward',
                                                         })
                             
                                                         if (point) {
                                                             return false;
                                                         }
                                                     });
 
                                                     if (!point) {
                                                         return;
                                                     }
                                                     const routeVariant = _.find(this.props.routeVariants, {uuid: run.route_variant_uuid});
                                                     if (!routeVariant) {
                                                        return
                                                     }
                                                     // находим общее время
                                                     const time = _.sumBy(_.filter(run.production_interval_map, (item) => {
                                                         return item.index < point.index;
                                                     }), 'interval');
 
                                                     const timeObject = moment(run.start_time, formats.TIME).add(time, 'minutes');
                                                     return (
                                                         <tr className='rowContent'>
                                                             <td><span>{variant.name}</span></td> 
                                                             <td />
                                                             <td />
                                                             <td>{timeObject.format(formats.TIME)}</td>
                                                             <td>-</td>
                                                             <td>{timeObject.format(formats.TIME)}</td>
                                                         </tr>
                                                     )
                                                 })
                                             }
                                     </tbody>
                                 )              
                     })}
                     <tr><td colSpan='6'>Обратное направление</td></tr>
                      {
                             reverseDefaultVariants && reverseDefaultVariants.map((variant) => { 
                                 return (
                                     <tbody>
                                             {
                                                 productionRuns.map((run) => { 
                                                     let point = null;   
                                                     _.each(variant.items, (item) => {
 
                                                         point = _.find(item.inclusions, {
                                                             route_variant_uuid: run.route_variant_uuid,
                                                             is_forward: run.type === 'production_forward',
                                                         })
                             
                                                         if (point) {
                                                             return false;
                                                         }
                                                     });
 
                                                     if (!point) {
                                                         return
                                                     }
 
                                                     const routeVariant = _.find(this.props.routeVariants, {uuid: run.route_variant_uuid});
                                                     if (!routeVariant) {
                                                         console.log('!routeVariant')
                                                     }
                                                     const time = _.sumBy(_.filter(run.production_interval_map, (item) => {
                                                         return item.index < point.index;
                                                     }), 'interval');
 
                                                     const timeObject = moment(run.start_time, formats.TIME).add(time, 'minutes');
                                                     return (
                                                         <tr className='rowContent'>
                                                             <td><span>{variant.name}</span></td> 
                                                             <td />
                                                             <td />
                                                             <td>{timeObject.format(formats.TIME)}</td>
                                                             <td>-</td>
                                                             <td>{timeObject.format(formats.TIME)}</td>
                                                         </tr>
                                                     )
                                                 })
                                             }
                                     </tbody>
                                 )              
                             })
                     }
                 </table>
             </div>
         )
    }

    renderTurn() {

        const productionRuns = this.getProductionRuns();

        return (
            <div className="page print by-flex">
                <h1>РАСПИСАНИЕ</h1>
                <div className="schedule-content">
                    <div className="schedule-main">
                        {this.renderContent(productionRuns)}
                    </div>
                </div>
            </div>
        );
    }
}