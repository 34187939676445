import React from 'react';
import './styles.less';

const CLASS_NAME = 'list-item';
export const ListItem = ({ text, actions: Actions }) => (
	<div className="list-item">
		<div className="list-item__text">{text}</div>
		{Actions && (
			<div className="list-item__actions">
				<Actions />
			</div>
		)}
	</div>
);
