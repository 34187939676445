import React, {Component} from "react";
import {connect} from "react-redux";
import {
    getTotalUnreadNotifications,
    notificationEventCreated,
    notificationEventRead
} from "../../store/reducers/notifications";
import Alert from "react-s-alert";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import _ from 'lodash';
import {GlobalEvent} from "helpers/event-system";

let subscribed = false;
let dictionariesLoaded = false;
let dictionaries = {
    vehicle_models: [],
};

@connect(state => ({
    count: state.notifications.get('count')
}), {getTotalUnreadNotifications, notificationEventCreated, notificationEventRead, getDictionaryList})

class NotificationsCounter extends Component {

    componentWillMount() {
        if (!dictionariesLoaded) {
            this.loadDictionaries([
                'vehicle_models',
            ]);
            dictionariesLoaded = true;
        }
        if (!subscribed) {
            this.props.notificationEventCreated((payload) => {
                this.updateCounter();
                if (payload.data.with_alert) {
                    Alert.info(payload.data.text, {
                        customFields: {
                            item: payload,
                            vehicle_models: dictionaries.vehicle_models,
                        },
                    });
                }
                GlobalEvent().call('notificationEventCreated');
            });

            this.props.notificationEventRead((payload) => {
                this.updateCounter();
                GlobalEvent().call('notificationEventRead');
            });

            this.updateCounter();

            subscribed = true;
        }
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        if (response.isOk) {
            _.each(response.payload.items, (item) => {
                dictionaries[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
        } else {
            response.showErrors();
        }
    }

    updateCounter() {
        this.props.getTotalUnreadNotifications();
    }


    render() {
        const totalNotifications = this.props.count;

        if (totalNotifications > 0) {
            return (this.props.count < 1000) ? (
                <span className="left-menu__numb">{this.props.count}</span>
            ) : (
                <span className="left-menu__numb">999+</span>
            );
        }

        return (
            <span/>
        )
    }

}

export default NotificationsCounter