import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import _ from 'lodash';

import BaseTableComponent from 'components/base/base_table';
import Page from 'components/ui/page';
import IconButton from 'components/ui/icon-button';
import Editor from './editor';

import Column from "components/ui/column";
import {getEntityNames} from "store/reducers/system";
import {deleteCarrier, getCarriers} from "store/reducers/organizational_units/carriers";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";

@connect(state => ({}), {getCarriers, deleteCarrier, getEntityNames})

export default class CarriersComponent extends BaseTableWithEditorComponent {

    title = 'Перевозчики';
    baseUrl = '/units/carriers';

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Наименование')
                .fromField('name'),

            new Column('ИНН')
                .fromField('inn'),

            new Column('КПП')
                .fromField('kpp'),

            new Column('Предприятие')
                .fromField('unit_uuid')
                .withDrawer(item => _.get(item, 'unit.name')),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteCarrier(data);
    }

    async loadData(meta) {
        return await this.props.getCarriers(meta);
    }
}