import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import TabletPage from 'components/ui/tablet/page';
import {
    Bar, BarChart, CartesianGrid, Label, LabelList, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer,
    Tooltip, XAxis,
    YAxis, Text, isNumOrStr
} from "recharts";
import moment from "moment";
import formats from "dictionaries/formats";
import Datepicker from "components/ui/form/datepicker";
import {getTabletKiutrSummary, getTabletSummary, getTabletUnitSummary} from "store/reducers/reports/tablet";
import _ from 'lodash';
import {Link} from "react-router";
import Select from "components/ui/select";
import {getUnit} from "store/reducers/organizational_units/units";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import * as storage from "utils/storage";
import GlobalLoaderComponent from "components/ui/global-loader";
import classNames from 'classnames';
import DateTick from "components/modules/analytics/base-components/charts/DateTick/index";
import CustomTooltip from "components/modules/tablet/tooltip/index";
import randomColor from 'randomcolor';
import {TabletTimeInstance} from 'components/modules/tablet/home/index';

@connect(state => ({}), {getUnit, getDictionaryList, getTabletUnitSummary})

export default class TabletUnit extends Component {

    state = {
        data: {},
        unit: '-',
        vehicle_types: [],
        fullscreen: null,
    };

    components = {
        kiutr: 'Пассажирские перевозки',
        kurs: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text,
        utility: 'Жилищно-коммунальное хозяйство',
        children: 'Перевозки детей',
        gatn: window.RNIS_SETTINGS.GUGATNTITLE ? window.RNIS_SETTINGS.GUGATNTITLE : 'ГУ ГАТН',
        communal: 'Коммунальная техника',
    };

    async componentWillMount() {
        await this.init();
        this.loadUnit();
        this.loadData();
        this.loadDictionaries([
            'vehicle_types',
        ]);
    }

    activateFullscreen(widget) {
        this.setState({
            fullscreen: widget,
        });
    }

    deactivateFullscreen() {
        this.setState({
            fullscreen: null,
        });
    }

    async init() {
        await this.setState({
            from: TabletTimeInstance.from,
            to: TabletTimeInstance.to,
            displayed_from: TabletTimeInstance.from,
            displayed_to: TabletTimeInstance.to,
        });
    }

    async loadUnit() {
        const response = await this.props.getUnit(this.props.params.uuid);

        if (response.isOk) {
            this.setState({
                unit: _.get(response.payload, 'name'),
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        return (
            <TabletPage
                title={this.state.unit}
                header_btns={this.getHeaderBtns()}
                header_top_right={this.getHeaderTopRight()}
                header_bottom={this.getHeaderBottom()}
                modalsActive={this.state.fullscreen !== null}
            >
                <div className="rating">
                    {this.state.loading ? <GlobalLoaderComponent/> : null}
                    <div className="rating__top">
                        <div className="row">
                            <div className="col col-8">
                                <ul className="breadcrumbs">
                                    <li className="breadcrumbs__item"><Link
                                        className="breadcrumbs__link breadcrumbs__link_na" to="/tablet/dashboard">Дашборд</Link><span
                                        className="breadcrumbs__jump"><span
                                        className="breadcrumbs__jump-dot"></span><span
                                        className="breadcrumbs__jump-dot"></span><span
                                        className="breadcrumbs__jump-arrow"></span></span></li>
                                    <li className="breadcrumbs__item"><Link
                                        className="breadcrumbs__link breadcrumbs__link_na"
                                        to={`/tablet/${this.props.params.component}`}>{this.components[this.props.params.component]}</Link><span
                                        className="breadcrumbs__jump"><span
                                        className="breadcrumbs__jump-dot"></span><span
                                        className="breadcrumbs__jump-dot"></span><span
                                        className="breadcrumbs__jump-arrow"></span></span></li>
                                    <li className="breadcrumbs__item"><a
                                        className="breadcrumbs__link">{this.state.unit}</a></li>
                                </ul>
                            </div>
                            <div className="col col-1"/>
                            <div className="col col-3"/>
                        </div>
                    </div>
                    <div className="rating__bottom">
                        <div className="widgets">
                            {this.renderCharts()}
                        </div>
                    </div>
                </div>
            </TabletPage>
        );
    }

    renderCharts() {
        if (this.props.params.component === 'kurs') {
            return this.renderKursCharts();
        } else if ((this.props.params.component === 'gatn') || (this.props.params.component === 'garbage') || (this.props.params.component === 'communal')) {
            return this.renderGatnCharts();
        } else {
            return this.renderKiutrCharts();
        }
    }

    renderKiutrCharts() {
        return (
            <div>
                <div className="row">
                    <div className="col col-6">
                        <div
                            className={classNames('widgets__item', (this.state.fullscreen === 'regularity') ? 'fullscreen' : '')}>
                            <div className="widgets__header">
                                <div className="widgets__title">Регулярность движения</div>
                                {(this.state.fullscreen === 'regularity') ? (
                                    <svg className="icon icon_cross icon_gray"
                                         onClick={::this.deactivateFullscreen}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                    </svg>
                                ) : (
                                    <svg className="icon icon_fullscreen icon_gray"
                                         onClick={this.activateFullscreen.bind(this, 'regularity')}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                    </svg>
                                )}
                            </div>
                            <div className="widgets__content">
                                {this.renderRegularityChart()}
                            </div>
                        </div>
                    </div>
                    <div className="col col-6">
                        <div
                            className={classNames('widgets__item', (this.state.fullscreen === 'violations') ? 'fullscreen' : '')}>
                            <div className="widgets__header">
                                <div className="widgets__title">Количество нарушений параметров перевозок</div>
                                {(this.state.fullscreen === 'violations') ? (
                                    <svg className="icon icon_cross icon_gray"
                                         onClick={::this.deactivateFullscreen}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                    </svg>
                                ) : (
                                    <svg className="icon icon_fullscreen icon_gray"
                                         onClick={this.activateFullscreen.bind(this, 'violations')}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                    </svg>
                                )}
                            </div>
                            <div className="widgets__content">
                                {this.renderViolationsChart()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-6">
                        <div
                            className={classNames('widgets__item', (this.state.fullscreen === 'speed_violations') ? 'fullscreen' : '')}>
                            <div className="widgets__header">
                                <div className="widgets__title">Количество нарушений скоростного режима</div>
                                {(this.state.fullscreen === 'speed_violations') ? (
                                    <svg className="icon icon_cross icon_gray"
                                         onClick={::this.deactivateFullscreen}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                    </svg>
                                ) : (
                                    <svg className="icon icon_fullscreen icon_gray"
                                         onClick={this.activateFullscreen.bind(this, 'speed_violations')}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                    </svg>
                                )}
                            </div>
                            <div className="widgets__content">
                                {this.renderSpeedViolationsChart()}
                            </div>
                        </div>
                    </div>
                    <div className="col col-6">
                        <div
                            className={classNames('widgets__item', (this.state.fullscreen === 'vehicles_plan_fact') ? 'fullscreen' : '')}>
                            <div className="widgets__header">
                                <div className="widgets__title">Выполнение плана по выходу ТС</div>
                                {(this.state.fullscreen === 'vehicles_plan_fact') ? (
                                    <svg className="icon icon_cross icon_gray"
                                         onClick={::this.deactivateFullscreen}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                    </svg>
                                ) : (
                                    <svg className="icon icon_fullscreen icon_gray"
                                         onClick={this.activateFullscreen.bind(this, 'vehicles_plan_fact')}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                    </svg>
                                )}
                            </div>
                            <div className="widgets__content">
                                <div className="widgets__legend widgets__legend_inline">
                                    <ul className="widgets__legend-list">
                                        <li className="widgets__legend-item">факт
                                            <div className="indicators indicators_green"/>
                                        </li>
                                        <li className="widgets__legend-item">план
                                            <div className="indicators indicators_orange"/>
                                        </li>
                                    </ul>
                                </div>
                                {this.renderVehiclesPlanFactChart()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-6">
                        <div
                            className={classNames('widgets__item', (this.state.fullscreen === 'vehicles_bnso') ? 'fullscreen' : '')}>
                            <div className="widgets__header">
                                <div className="widgets__title">Оснащенность ГЛОНАСС</div>
                                {(this.state.fullscreen === 'vehicles_bnso') ? (
                                    <svg className="icon icon_cross icon_gray"
                                         onClick={::this.deactivateFullscreen}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                    </svg>
                                ) : (
                                    <svg className="icon icon_fullscreen icon_gray"
                                         onClick={this.activateFullscreen.bind(this, 'vehicles_bnso')}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                    </svg>
                                )}
                            </div>
                            <div className="widgets__content">
                                <div className="widgets__legend">
                                    <ul className="widgets__legend-list">
                                        <li className="widgets__legend-item">Количество ТС с ГЛОНАСС
                                            <div className="indicators indicators_green"/>
                                        </li>
                                        <li className="widgets__legend-item">Количество ТС без ГЛОНАСС
                                            <div className="indicators indicators_orange"/>
                                        </li>
                                    </ul>
                                </div>
                                {this.renderVehiclesBnsoChart()}
                            </div>
                        </div>
                    </div>
                    <div className="col col-6">
                        <div
                            className={classNames('widgets__item', (this.state.fullscreen === 'transport_work') ? 'fullscreen' : '')}>
                            <div className="widgets__header">
                                <div className="widgets__title">Доля выполнения рейсов</div>
                                {(this.state.fullscreen === 'transport_work') ? (
                                    <svg className="icon icon_cross icon_gray"
                                         onClick={::this.deactivateFullscreen}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                    </svg>
                                ) : (
                                    <svg className="icon icon_fullscreen icon_gray"
                                         onClick={this.activateFullscreen.bind(this, 'transport_work')}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                    </svg>
                                )}
                            </div>
                            <div className="widgets__content">
                                {this.renderTransportWorkChart()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderKursCharts() {
        return (
            <div>
                <div className="row">
                    <div className="col col-6">
                        <div
                            className={classNames('widgets__item', (this.state.fullscreen === 'speed_violations') ? 'fullscreen' : '')}>
                            <div className="widgets__header">
                                <div className="widgets__title">Количество нарушений скоростного режима</div>
                                {(this.state.fullscreen === 'speed_violations') ? (
                                    <svg className="icon icon_cross icon_gray"
                                         onClick={::this.deactivateFullscreen}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                    </svg>
                                ) : (
                                    <svg className="icon icon_fullscreen icon_gray"
                                         onClick={this.activateFullscreen.bind(this, 'speed_violations')}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                    </svg>
                                )}
                            </div>
                            <div className="widgets__content">
                                {this.renderSpeedViolationsChart()}
                            </div>
                        </div>
                    </div>
                    <div className="col col-6">
                        <div
                            className={classNames('widgets__item', (this.state.fullscreen === 'vehicles_bnso') ? 'fullscreen' : '')}>
                            <div className="widgets__header">
                                <div className="widgets__title">Оснащенность ГЛОНАСС</div>
                                {(this.state.fullscreen === 'vehicles_bnso') ? (
                                    <svg className="icon icon_cross icon_gray"
                                         onClick={::this.deactivateFullscreen}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                    </svg>
                                ) : (
                                    <svg className="icon icon_fullscreen icon_gray"
                                         onClick={this.activateFullscreen.bind(this, 'vehicles_bnso')}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                    </svg>
                                )}
                            </div>
                            <div className="widgets__content">
                                <div className="widgets__legend">
                                    <ul className="widgets__legend-list">
                                        <li className="widgets__legend-item">Количество ТС с ГЛОНАСС
                                            <div className="indicators indicators_green"/>
                                        </li>
                                        <li className="widgets__legend-item">Количество ТС без ГЛОНАСС
                                            <div className="indicators indicators_orange"/>
                                        </li>
                                    </ul>
                                </div>
                                {this.renderVehiclesBnsoChart()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-6">
                        <div
                            className={classNames('widgets__item', (this.state.fullscreen === 'vehicles_work') ? 'fullscreen' : '')}>
                            <div className="widgets__header">
                                <div className="widgets__title">Техники в работе</div>
                                {(this.state.fullscreen === 'vehicles_work') ? (
                                    <svg className="icon icon_cross icon_gray"
                                         onClick={::this.deactivateFullscreen}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                    </svg>
                                ) : (
                                    <svg className="icon icon_fullscreen icon_gray"
                                         onClick={this.activateFullscreen.bind(this, 'vehicles_work')}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                    </svg>
                                )}
                            </div>
                            <div className="widgets__content">
                                {this.renderVehiclesWorkChart()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderGatnCharts() {
        return (
            <div>
                <div className="row">
                    <div className="col col-6">
                        <div
                            className={classNames('widgets__item', (this.state.fullscreen === 'speed_violations') ? 'fullscreen' : '')}>
                            <div className="widgets__header">
                                <div className="widgets__title">Количество нарушений скоростного режима</div>
                                {(this.state.fullscreen === 'speed_violations') ? (
                                    <svg className="icon icon_cross icon_gray"
                                         onClick={::this.deactivateFullscreen}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                    </svg>
                                ) : (
                                    <svg className="icon icon_fullscreen icon_gray"
                                         onClick={this.activateFullscreen.bind(this, 'speed_violations')}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                    </svg>
                                )}
                            </div>
                            <div className="widgets__content">
                                {this.renderSpeedViolationsChart()}
                            </div>
                        </div>
                    </div>
                    <div className="col col-6">
                        <div
                            className={classNames('widgets__item', (this.state.fullscreen === 'vehicles_bnso') ? 'fullscreen' : '')}>
                            <div className="widgets__header">
                                <div className="widgets__title">Оснащенность ГЛОНАСС</div>
                                {(this.state.fullscreen === 'vehicles_bnso') ? (
                                    <svg className="icon icon_cross icon_gray"
                                         onClick={::this.deactivateFullscreen}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                    </svg>
                                ) : (
                                    <svg className="icon icon_fullscreen icon_gray"
                                         onClick={this.activateFullscreen.bind(this, 'vehicles_bnso')}>
                                        <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                    </svg>
                                )}
                            </div>
                            <div className="widgets__content">
                                <div className="widgets__legend">
                                    <ul className="widgets__legend-list">
                                        <li className="widgets__legend-item">Количество ТС с ГЛОНАСС
                                            <div className="indicators indicators_green"/>
                                        </li>
                                        <li className="widgets__legend-item">Количество ТС без ГЛОНАСС
                                            <div className="indicators indicators_orange"/>
                                        </li>
                                    </ul>
                                </div>
                                {this.renderVehiclesBnsoChart()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getHeaderBtns() {
        return (
            <li className="header-top__icons-item">
                <Link className="header-top__icons-link header-top__icons-link_back"
                      to={`/tablet/${this.props.params.component}`}>
                    <svg className="icon icon_back icon_white">
                        <use xlinkHref="/img/spriteSVG.svg#icon_back"/>
                    </svg>
                </Link>
            </li>
        );
    }

    getHeaderTopRight() {
        return (
            <ul className="header-top__icons header-top__icons_tar">
                <li className="header-top__icons-item">
                    <div className="search">
                        <form className="search__form">
                            <input className="search__input" type="text" placeholder="поиск"/>
                            <button className="search__submit" type="submit">
                                <svg className="icon icon_search icon_red">
                                    <use xlinkHref="/img/spriteSVG.svg#icon_search"/>
                                </svg>
                            </button>
                        </form>
                    </div>
                    <a className="header-top__icons-link header-top__icons-link_none-bg header-top__icons-link_search">
                        <svg className="icon icon_search icon_red">
                            <use xlinkHref="/img/spriteSVG.svg#icon_search"/>
                        </svg>
                    </a>
                </li>
            </ul>
        );
    }

    getHeaderBottom() {
        const from = moment(this.state.displayed_from);
        const to = moment(this.state.displayed_to);

        return (
            <div className="row">
                <div className="header-bottom__left col col-6">
                    <div className="title-text">
                        <div className="title-text__value">Текущий период</div>
                        <div className="title-text__dscn">{this.formatDate(from)} - {this.formatDate(to)}</div>
                    </div>
                </div>
                <div className="header-bottom__right col col-6">
                    <div className="row">
                        <div className="col col-4">
                            <Datepicker
                                value={this.state.from}
                                onChange={this.onChange.bind(this, 'from')}
                                readOnly={true}
                            />
                        </div>
                        <div className="col col-4">
                            <Datepicker
                                value={this.state.to}
                                onChange={this.onChange.bind(this, 'to')}
                                readOnly={true}
                            />
                        </div>
                        <div className="col col-4">
                            <button className="btn btn_form btn_colored" onClick={::this.reload}>Сформировать</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onChange(field, e) {
        const value = e.value || e.target.value;

        let state = this.state;
        state[field] = value;
        this.setState(state);

        if (field === 'from' || field === 'to') {
            storage.save(`tablet-${field}`, value);
        }
    }

    async reload() {
        TabletTimeInstance.update(this.state.from, this.state.to);
        await this.setState({
            displayed_from: this.state.from,
            displayed_to: this.state.to,
        });

        this.loadData();
    }

    formatDate(date) {
        const months = {
            0: 'января',
            1: 'февраля',
            2: 'марта',
            3: 'апреля',
            4: 'мая',
            5: 'июня',
            6: 'июля',
            7: 'августа',
            8: 'сентября',
            9: 'октября',
            10: 'ноября',
            11: 'декабря',
        };
        return `${date.date()} ${months[date.month()]} ${date.year()}`;
    }

    async loadData() {
        this.setState({loading: true});
        const response = await this.props.getTabletUnitSummary({
            date_from: this.state.from,
            date_to: this.state.to,
            component: this.props.params.component,
            unit_uuid: this.props.params.uuid,
        });
        this.setState({loading: false});

        if (response.isOk) {
            this.setState({
                data: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    renderRegularityChart() {
        let rows = this.state.data.regularity || [];

        const data = _.map(rows, (row) => {
            return {
                name: moment(row.date).format(formats.DATE),
                value: row.value,
                label: row.value || '',
                fill: this.getFill(row.value),
                radius: [4, 4, 0, 0],
            };
        });

        return (
            <ResponsiveContainer>
                <BarChart data={data}>
                    <XAxis dataKey="name" tick={<DateTick/>} interval="preserveStartEnd"/>
                    <YAxis dataKey="value"
                           label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle"
                                                 angle={-90}>%</Text>}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Bar dataKey="value" fill="#57AF7D" name="Регулярность движения"
                         type="monotone"/>
                </BarChart>
            </ResponsiveContainer>
        );
    }

    renderTransportWorkChart() {
        let rows = this.state.data.transport_work || [];

        const data = _.map(rows, (row) => {
            return {
                name: moment(row.date).format(formats.DATE),
                value: row.value,
                label: row.value || '',
                fill: this.getFill(row.value),
                radius: [4, 4, 0, 0],
            };
        });

        return (
            <ResponsiveContainer>
                <BarChart data={data}>
                    <XAxis dataKey="name" tick={<DateTick/>} interval="preserveStartEnd"/>
                    <YAxis dataKey="value"
                           label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle"
                                                 angle={-90}>%</Text>}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Bar dataKey="value" fill="#57AF7D" name="Доля выполнения рейсов" type="monotone"/>
                </BarChart>
            </ResponsiveContainer>
        );
    }

    renderViolationsChart() {
        let rows = this.state.data.violations || [];

        const data = _.map(rows, (row) => {
            return {
                name: moment(row.date).format(formats.DATE),
                value: row.value,
                label: row.value || '',
            };
        });

        return (
            <ResponsiveContainer>
                <LineChart data={data}>
                    <XAxis dataKey="name" tick={<DateTick/>} interval="preserveStartEnd"/>
                    <YAxis dataKey="value"
                           label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle" angle={-90}>Количество</Text>}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Line dataKey="value" stroke="#95B400" strokeWidth={3} fill="#fff" r={5}
                          name="Количество нарушений параметров перевозок" type="monotone"/>
                </LineChart>
            </ResponsiveContainer>
        );
    }

    renderSpeedViolationsChart() {
        let rows = this.state.data.speed_violations || [];

        const data = _.map(rows, (row) => {
            return {
                name: moment(row.date).format(formats.DATE),
                value: row.value,
                label: row.value || '',
                fill: this.getCountFill(row.value, 'speed_violations'),
                radius: [4, 4, 0, 0],
            };
        });

        return (
            <ResponsiveContainer>
                <BarChart data={data}>
                    <XAxis dataKey="name" tick={<DateTick/>} interval="preserveStartEnd"/>
                    <YAxis dataKey="value"
                           label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle" angle={-90}>Количество</Text>}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Bar dataKey="value" fill="#57AF7D" name="Количество нарушений скоростного режима" type="monotone"/>
                </BarChart>
            </ResponsiveContainer>
        );
    }

    renderVehiclesPlanFactChart() {
        let rows_plan = this.state.data.vehicles_plan || [];
        let rows_fact = this.state.data.vehicles_fact || [];

        const data = _.map(rows_plan, (row) => {
            const fact = _.find(rows_fact, {date: row.date});

            return {
                name: moment(row.date).format(formats.DATE),
                value: row.value,
                label: row.value || '',
                fact_value: fact.value,
                fact_label: fact.value || '',
            };
        });

        return (
            <ResponsiveContainer>
                <LineChart data={data}>
                    <XAxis dataKey="name" tick={<DateTick/>} interval="preserveStartEnd"/>
                    <YAxis dataKey="value"
                           label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle" angle={-90}>Количество</Text>}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Line dataKey="value" stroke="#F4A44B" strokeWidth={3} fill="#fff" r={5} name="План"
                          type="monotone"/>
                    <Line dataKey="fact_value" stroke="#95B400" strokeWidth={3} fill="#fff" r={5} name="Факт"
                          type="monotone"/>
                </LineChart>
            </ResponsiveContainer>
        );
    }

    renderVehiclesBnsoChart() {
        const total = _.get(this.state.data, 'vehicles_bnso.total') || 0;
        const withoutBnso = _.get(this.state.data, 'vehicles_bnso.without_bnso') || 0;

        const data = [
            {
                name: 'Количество ТС с ГЛОНАСС',
                value: total - withoutBnso,
                fill: '#95B400',
                percent: ((total !== 0) ? _.round(100 * (total - withoutBnso) / total, 0) : 0) + '%',
            },
            {
                name: 'Количество ТС без ГЛОНАСС',
                value: withoutBnso,
                fill: '#F79900',
                percent: ((total !== 0) ? _.round(100 * withoutBnso / total, 0) : 0) + '%',
            },
        ];

        return (
            <ResponsiveContainer>
                <PieChart>
                    <Pie data={data}
                         nameKey="name"
                         dataKey="value"
                         cx="50%"
                         cy="50%"
                         outerRadius="80%"
                         startAngle={90}
                         endAngle={-270}
                    >
                        <LabelList dataKey="percent" fill="#fff"/>
                    </Pie>
                    <Tooltip/>
                </PieChart>
            </ResponsiveContainer>
        );
    }

    renderVehiclesWorkChart() {
        let rows = this.state.data.vehicles_work || [];

        const series = _.filter(_.map(this.state.vehicle_types, (vehicleType) => {
            return {
                name: vehicleType.label,
                data: _.map(_.filter(rows, {vehicle_type_uuid: vehicleType.value}), (row) => {
                    return {
                        name: moment(row.date).format(formats.DATE),
                        value: row.value,
                        total: row.total,
                    };
                }),
            };
        }), (seria) => {
            return _.filter(seria.data, (item) => item.value).length > 0;
        });

        return (
            <ResponsiveContainer>
                <LineChart>
                    <XAxis dataKey="name" tick={<DateTick/>} type="category" allowDuplicatedCategory={false}
                           interval="preserveStartEnd"/>
                    <YAxis dataKey="value"
                           label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle"
                                                 angle={-90}>%</Text>}/>
                    <Tooltip content={<CustomTooltip/>} formatter={(value, name, entry) => {
                        return entry.payload.value + '% (' + entry.payload.total + ' шт.)';
                    }}/>
                    {_.map(series, (s) => {
                        return (
                            <Line key={s.name} data={s.data} dataKey="value" stroke={randomColor()} strokeWidth={3}
                                  fill="#fff" r={5} name={s.name} type="monotone"/>
                        );
                    })}
                </LineChart>
            </ResponsiveContainer>
        );
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }

    getFill(value) {
        if (value >= 80) {
            return '#95B400';
        }
        if (value >= 50) {
            return '#F4A44B';
        }
        return '#F65C50';
    }

    getCountFill(value, field, invert = true) {
        if (value > 0) {
            return '#F65C50';
        } else {
            return '#95B400';
        }
    }
}