import React, { Component } from 'react';
import Button from "components/ui/button";
import Select from "components/ui/select";
import * as alerts from "helpers/alerts";

import { createUrlWithQueries } from "helpers/regularity_matrix";
import { updateStatusOrder } from "helpers/api/geofence.js";

import "./style.less";

export default class RegularityMatrixActions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            direction: "forward"
        };
    }

    async componentDidMount() {
        if (this.props.direction) {
           await this.setState({
                direction: this.props.direction
            })
        }
    }

    async handleOrderStatus() {
        const response = await updateStatusOrder({"uuid": this.props.order.uuid});
        if (response.success) {
            this.props.onReloadMatrix();
        } else {
            alerts.error("Не удалось сменить статус план наряда");
        }
    }

    handleReloadMatrix() {
        this.props.onReloadMatrix();
    }

    handleCandidates() {
        const pathname = this.props.location.pathname;
        const query = this.props.location.query;
        const lastQuery = "need_candidates";
        const candidatesValue = "true";
        const url = createUrlWithQueries(pathname, query, lastQuery, candidatesValue);
        this.props.router.push(`${url}`);
        this.props.onCandidates();
    }

    computeCandidatesStatus() {
        if (this.props.vehicle) {
            return false;
        } else {
            return true;
        }
    }

    handleDirectionChange(e) {
       this.setState({
        direction: e.value
       });
       const pathname = this.props.location.pathname;
       const query = this.props.location.query;
       const lastQuery = "route_direction";
       const directionValue = e.value;
       const url = createUrlWithQueries(pathname, query, lastQuery, directionValue);
       this.props.router.push(`${url}`);
       this.props.onToggleDirection(e.value);
    }

    handleDebugMode() {
        const element = document.querySelector(".regularityContainer");
        if (!element.classList.contains("debug-mode")) {
            element.classList.add("debug-mode")
        } else {
            element.classList.remove("debug-mode")
        }
    }

    render() {
        const { isclosed } = this.props.order;
        return (
        <div className="regularityActions">
        <Select className="regularityActions__select" value={this.state.direction}
                                                onChange={(e) => this.handleDirectionChange(e)}
                                                clearable={false} searchable={false} options={[
                                            {
                                                value: 'forward',
                                                label: 'Прямое',
                                            },
                                            {
                                                value: 'backward',
                                                label: 'Обратное',
                                            },
                                        ]}/>
            <Button
                text="кандидаты рейсов"
                color="white"
                shadow="gray"
                size="lg"
                className="regularityActions__button"
                onClick={() => this.handleCandidates()}
                disabled={this.computeCandidatesStatus()}
            />
            <Button
                onClick={() => this.handleReloadMatrix()}
                text="обновить"
                color="white"
                shadow="gray"
                size="lg"
                className="regularityActions__button"
            />
            <div className="space"></div>
            {window.RNIS_SETTINGS.debugMode && <Button
            text="Debug"
            color="white"
            shadow="gray"
            size="lg"
            className="regularityActions__button debug-button"
            onClick={this.handleDebugMode}
            />   }
            <Button
            text="пересчитать план-наряд"
            color="white"
            shadow="gray"
            size="lg"
            className="regularityActions__button"
            />
        <Button
            text={isclosed ? "открыть план-наряд" : "закрыть план-наряд"}
            color="white"
            shadow="gray"
            size="lg"
            className="regularityActions__button regularityActions__button--last"
            onClick={() => this.handleOrderStatus()}
        />
        </div>
        )
    }
}