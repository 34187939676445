import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import './style.less';
import {connect} from "react-redux";
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";
import {getUnits} from "store/reducers/organizational_units/units";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import FileReaderInput from 'react-file-reader-input';

import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import {api} from "helpers/api";
import {createVehicleWork} from "store/reducers/commdept/vehicle_works";
import TableContainer from "components/ui/Table/Container/TableContainer";
import KursDetailAddForm from "components/modules/commdept/vehicles/vehicle-editor-form/operation-add-form/detail-add-form/index";
import {createVehicleMechanism} from "store/reducers/commdept/vehicle_mechanisms";
import {getTasks} from "store/reducers/commdept/tasks";
import moment from "moment";
import formats from "dictionaries/formats";
import Button from "components/ui/button";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import TabBlock from "components/ui/tabs/tab-block";
import {getVehicleFuelEvents, getVehicleFuelLevels} from "store/reducers/commdept/fuel";
import {
    Bar, BarChart, CartesianGrid, Label, LabelList, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis,
    YAxis, Text, AreaChart, Area, Legend
} from "recharts";

@propTypes({
    uuid: PropTypes.string.isRequired,
})

@connect(state => ({}), {getVehicleFuelLevels, getVehicleFuelEvents})

export default class KursVehicleFuel extends BaseEditorFormComponent {

    state = {
        from: moment().subtract(1, 'day'),
        to: moment(),
        levels: [],
        events: [],
    };

    types = {
        drain: 'Возможный слив',
        fill: 'Заправка',
    };

    lines = [
        {
            dataKey: 'level_sum',
            stroke: '#5C9AD5',
            name: 'Суммарный уровень топлива (литры)',
            type: 'monotone',
        },
        {
            dataKey: 'level_sum_normal',
            stroke: '#ED7D31',
            name: 'Нормализованный суммарный уровень топлива (литры)',
            type: 'monotone',
        },
        {
            dataKey: 'fuel_level_percent',
            stroke: '#49d540',
            name: 'Уровень топлива в процентах от емкости топливного бака',
            type: 'monotone',
        },
    ];

    componentDidMount() {
        this.loadData();
    }

    async setValue(field, value) {
        super.setValue(field, value);

        this.loadData();
    }

    async loadData() {
        if (!this.state.from || !this.state.to) {
            return;
        }

        this.loadLevels();
        this.loadEvents();
    }

    async loadLevels() {
        const response = await this.props.getVehicleFuelLevels({
            pagination: {
                page: 1,
                limit: 10000,
            },
            filters: {
                withVehicle: this.props.uuid,
                withPeriod: [
                    moment(this.state.from).startOf('day').format(formats.DATETIME_API),
                    moment(this.state.to).endOf('day').format(formats.DATETIME_API),
                ],
            },
        });

        if (response.isOk) {
            await this.setState({
                levels: _.map(_.sortBy(response.payload.items, 'timestamp'), (item) => {
                    item.timestamp = moment(item.timestamp).unix();
                    return item;
                }),
            });
        } else {
            response.showErrors();
        }
    }

    async loadEvents() {
        const response = await this.props.getVehicleFuelEvents({
            filters: {
                withVehicle: this.props.uuid,
                withPeriod: [
                    moment(this.state.from).startOf('day').format(formats.DATETIME_API),
                    moment(this.state.to).endOf('day').format(formats.DATETIME_API),
                ],
            },
        });

        if (response.isOk) {
            await this.setState({
                events: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        return (
            <div>
                <TabBlock>
                    <Block size="md" className="expand-right" title="Дата с">
                        {this.datepicker('from')}
                    </Block>
                    <Block size="md" title="Дата по">
                        {this.datepicker('to')}
                    </Block>
                    <Block size="xl" title="">
                        <ResponsiveContainer width="100%" height={500}>
                            <LineChart data={this.state.levels}>
                                <XAxis dataKey="timestamp" type="number" domain={['dataMin', 'dataMax']}
                                       tickFormatter={(tick) => {
                                           return moment.unix(tick).format(formats.DATETIME_SHORT);
                                       }}/>
                                <YAxis/>
                                <CartesianGrid horizontal={false}/>
                                <Tooltip labelFormatter={(label) => {
                                    return moment.unix(label).format(formats.DATETIME_SHORT);
                                }}/>
                                <Legend verticalAlign="top" height={50}/>
                                {_.map(this.lines, (line, index) => {
                                    return (
                                        <Line key={index} dot={false} {...line}/>
                                    )
                                })}
                            </LineChart>
                        </ResponsiveContainer>
                    </Block>
                    <Block size="xl" title="">
                        <div className="Table">
                            <TableContainer>
                                <table className="b-table">
                                    <thead>
                                    <tr>
                                        <th>Вид события</th>
                                        <th>Дата</th>
                                        <th>Время</th>
                                        <th>Объем</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.events.map(::this.renderEvent)}
                                    </tbody>
                                </table>
                            </TableContainer>
                        </div>
                    </Block>
                </TabBlock>
            </div>
        );
    }

    renderEvent(event, index) {
        return (
            <tr key={index}>
                <td>{this.types[event.type]}</td>
                <td>{moment(event.timestamp).format(formats.DATE)}</td>
                <td>{moment(event.timestamp).format(formats.TIME)}</td>
                <td>{(!isNaN(event.amount)) ? _.round(event.amount, 2) : '-'}</td>
            </tr>
        );
    }
}
