export default () => {
    const gatn = window.RNIS_SETTINGS.GUGATNTITLE;

    return {
        'system': {
            permissions: [
                'com.rnis.dictionary.dictionary.request_documents',
                'com.rnis.auth.permission.independent',
                'com.rnis.reports.document',
                'com.rnis.portal.menu',
                'com.rnis.system.permission.messages',
                'com.rnis.vehicles.event',
                'com.rnis.system.maintenance',
                'com.rnis.auth.permission.unit_permission',
                'com.rnis.auth.permission.component',
                'com.rnis.bnso.check.link',
            ],
            innerPermissions: {
                'Перенесенные': [
                    'com.rnis.geo.monitoring',
                    'com.rnis.geo.speed-violations',
                    'com.rnis.geo.user_geo_object',
                    'com.rnis.vehicles.vehicle',
                    'com.rnis.gatn.gatn_violation',
                ],
                'Сущности': [
                    'com.rnis.system.permission.audit',
                    'com.rnis.vehicles.bnso',
                    'com.rnis.vehicles.graduation',
                    'com.rnis.organizational_units.position',
                    'com.rnis.auth.permission.user.position',
                    'com.rnis.auth.permission.user.dismissal',
                    'com.rnis.mobile.contact',
                    'com.rnis.system.tooltip',
                    'com.rnis.system.permission.trash',
                    'com.rnis.system.permission.activity',
                    'com.rnis.portal.news',
                    'com.rnis.portal.faq',
                    'com.rnis.notifications.event_rule_notification',
                    'com.rnis.notifications.mailing',
                    'com.rnis.reports.report',
                    'com.rnis.auth.permission.user',
                    'com.rnis.auth.permission.user.short',
                    'com.rnis.vehicles.port',
                    'com.rnis.notifications.event_rule',
                    'com.rnis.organizational_units.unit',
                    'com.rnis.portal.cooperation_agreement_warnings',
                    'com.rnis.portal.unit_profile',
                    'com.rnis.auth.permission.role',
                    'com.rnis.vehicles.bnso_to_vehicle',
                    'com.rnis.system.options',
                    'com.rnis.geo.layer',
                    'com.rnis.notifications.notification_event',
                    'com.rnis.system.permission.log',
                    'com.rnis.portal.cooperation_agreements',
                    'com.rnis.portal.page',
                    'com.rnis.mobile.mobile_page',
                    'com.rnis.telematics.permission.telematics',
                    'com.rnis.vehicles.graduation_templates',
                    'com.rnis.portal.template_document',
                    'com.rnis.auth.permission.user.education',
                    'com.rnis.auth.permission.user.refresh',
                    'com.rnis.auth.permission.grafana',
                    'com.rnis.auth.permission.unit_permission.use',
                    'com.rnis.vehicles.vehicle.import',
                    'com.rnis.kurs.repair_program',
                    'com.rnis.kurs.vehicle.work',
                    'com.rnis.kurs.maintenance',
                ],
                'Справочник': [
                    'com.rnis.dictionary.dictionary.portal_cooperation_agreement_warnings',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                    'com.rnis.dictionary.dictionary.notification_levels',
                    'com.rnis.dictionary.dictionary.communal_municipalities',
                    'com.rnis.dictionary.dictionary.vehicle_subsystems'
                ],
            }
        },
        'analytics': {
            innerPermissions: {
                'Сущности': [
                    'com.rnis.system.analytics_platform',
                    'com.rnis.system.analytics_platform.summary',
                    'com.rnis.system.analytics_platform.kiutr',
                    'com.rnis.system.analytics_platform.communal',
                    'com.rnis.system.analytics_platform.road',
                    'com.rnis.system.analytics_platform.children',
                    'com.rnis.system.analytics_platform.garbage',
                    'com.rnis.system.analytics_platform.control',
                    'com.rnis.system.analytics_platform.medic',
                    'com.rnis.system.analytics_platform.timber',
                    'com.rnis.system.analytics_platform.agricultural',
                    'com.rnis.system.analytics_platform.taxi',
                    'com.rnis.system.analytics_platform.dangerous',
                    'com.rnis.system.analytics_platform.route',
                    'com.rnis.system.analytics_platform.utility',
                    'com.rnis.system.analytics_platform.official',
                    'com.rnis.system.analytics_platform.emercom',
                    'com.rnis.system.analytics_platform.farming',
                    'com.rnis.system.analytics_platform.forestry',
                    'com.rnis.system.analytics_platform.medicine'
                ],
            },
        },
        'passengers': {
            permissions: [
                'com.rnis.geo.violation',
                'com.rnis.geo.ellipse',
                'com.rnis.geo.monitoring',
                'com.rnis.geo.speed-violations',
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.geo.ecp_sign_log',
                    'com.rnis.geo.duplication_matrix',
                    'com.rnis.geo.territorial_entity',
                    'com.rnis.geo.driver_summary',
                    'com.rnis.geo.route_variant',
                    'com.rnis.geo.order_execution',
                    'com.rnis.geo.schedule_turn',
                    'com.rnis.geo.route_deviation',
                    'com.rnis.portal.confirm_request',
                    'com.rnis.portal.register_request',
                    'com.rnis.geo.interval_map',
                    'com.rnis.geo.contract',
                    'com.rnis.geo.display_configuration',
                    'com.rnis.geo.route_registry',
                    'com.rnis.geo.route_variant_null_run',
                    'com.rnis.geo.route_variant_default',
                    'com.rnis.geo.stop_point',
                    'com.rnis.geo.stop_point_pavilion',
                    'com.rnis.geo.route',
                    'com.rnis.organizational_units.carrier',
                    'com.rnis.geo.schedule_switch',
                    'com.rnis.geo.order',
                    'com.rnis.geo.order.manual',
                    'com.rnis.geo.user_geo_object',
                    'com.rnis.geo.schedule',
                    'com.rnis.geo.order_run',
                    'com.rnis.geo.daily_order',
                    'com.rnis.geo.display',
                    'com.rnis.vehicles.vehicle',
                    'com.rnis.vehicles.vehicle.short',
                    'com.rnis.geo.schedule_interval_deviations',
                    'com.rnis.geo.schedule_interval_fact',
                    'com.rnis.geo.schedule_interval',
                    'com.rnis.geo.schedule_interval_report_by_unit'
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.driver_license_categories',
                    'com.rnis.dictionary.dictionary.personnel_dismissal_types',
                    'com.rnis.dictionary.dictionary.vehicle_capacity_types',
                    'com.rnis.dictionary.dictionary.vehicle_environmental_classes',
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.vehicle_types',
                    'com.rnis.dictionary.dictionary.bnso_types',
                    'com.rnis.dictionary.dictionary.bnso_manufacturers',
                    'com.rnis.dictionary.dictionary.bnso_operators',
                    'com.rnis.dictionary.dictionary.distance_normals',
                    'com.rnis.dictionary.dictionary.vehicle_thresholds',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                    'com.rnis.dictionary.dictionary.bnso_equipments',
                    'com.rnis.dictionary.dictionary.kurs_vehicle_repair_types',
                    'com.rnis.dictionary.dictionary.kurs_accounting_groups',
                    'com.rnis.dictionary.dictionary.kurs_details',
                    'com.rnis.dictionary.dictionary.kurs_malfunctions',
                    'com.rnis.dictionary.dictionary.kurs_service_types',
                    'com.rnis.dictionary.dictionary.kurs_service_units',
                    'com.rnis.dictionary.dictionary.contract_statuses',
                    'com.rnis.dictionary.dictionary.vis_status_mappers',
                    'com.rnis.dictionary.dictionary.replacement_types',
                    'com.rnis.dictionary.dictionary.transport_connection_types',
                    'com.rnis.dictionary.dictionary.route_types',
                    'com.rnis.dictionary.dictionary.route_passport_statuses',
                    'com.rnis.dictionary.dictionary.route_kinds',
                    'com.rnis.dictionary.dictionary.route_directions',
                    'com.rnis.dictionary.dictionary.road_infrastructure_types',
                    'com.rnis.dictionary.dictionary.crosswalk_types',
                    'com.rnis.dictionary.dictionary.route_orientations',
                    'com.rnis.dictionary.dictionary.road_networks',
                    'com.rnis.dictionary.dictionary.route_statuses',
                    'com.rnis.dictionary.dictionary.scheduled_traffic_types',
                    'com.rnis.dictionary.dictionary.seasons',
                    'com.rnis.dictionary.dictionary.route_schedule_types',
                    'com.rnis.dictionary.dictionary.trip_route_types',
                    'com.rnis.dictionary.dictionary.schedule_statuses',
                    'com.rnis.dictionary.dictionary.display_models',
                    'com.rnis.dictionary.dictionary.defects',
                    'com.rnis.dictionary.dictionary.pavilion_types',
                    'com.rnis.dictionary.dictionary.week_days',
                    'com.rnis.dictionary.dictionary.off_days',
                    'com.rnis.dictionary.dictionary.organizational_forms',
                    'com.rnis.dictionary.dictionary.company_types',
                    'com.rnis.dictionary.dictionary.ownership_forms',
                    'com.rnis.dictionary.dictionary.rates_types',
                    'com.rnis.dictionary.dictionary.rates_kinds',
                    'com.rnis.dictionary.dictionary.fixed_rates',
                    'com.rnis.dictionary.dictionary.tariff_zones',
                    'com.rnis.dictionary.dictionary.run_check_reasons',
                    'com.rnis.dictionary.dictionary.run_uncheck_reasons',
                    'com.rnis.dictionary.dictionary.stop_point_check_reasons',
                    'com.rnis.dictionary.dictionary.stop_point_uncheck_reasons',
                    'com.rnis.dictionary.dictionary.work_graphics',
                    'com.rnis.dictionary.dictionary.kiutr_run_types',
                    //'com.rnis.dictionary.dictionary.territorial_entities',
                    'com.rnis.dictionary.dictionary.through_stop_point_vehicle_types',
                    'com.rnis.dictionary.dictionary.electronic_stop_point_displays',
                    'com.rnis.dictionary.dictionary.vehicle_techreg_categories',
                    'com.rnis.dictionary.dictionary.vehicle_engine_types',
                    'com.rnis.dictionary.dictionary.vehicle_engine_kind'

                ],
            }
        },
        'ems': {
            permissions: [
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.geo.route',
                    'com.rnis.geo.route_deviation',
                    'com.rnis.geo.stop_point',
                    'com.rnis.geo.stop_point_pavilion',
                    'com.rnis.geo.layer',
                    'com.rnis.geo.user_geo_object',
                    'com.rnis.geo.route_variant_default',
                    'com.rnis.geo.route_variant',
                    'com.rnis.geo.route_variant_null_run',
                    'com.rnis.geo.interval_map',
                    'com.rnis.geo.schedule',
                    'com.rnis.geo.formal_schedule',
                    'com.rnis.geo.schedule_turn',
                    'com.rnis.geo.schedule_switch',
                    'com.rnis.geo.contract',
                    'com.rnis.geo.route_registry',
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.transport_connection_types',
                    'com.rnis.dictionary.dictionary.route_types',
                    'com.rnis.dictionary.dictionary.route_passport_statuses',
                    'com.rnis.dictionary.dictionary.route_kinds',
                    'com.rnis.dictionary.dictionary.route_directions',
                    'com.rnis.dictionary.dictionary.road_infrastructure_types',
                    'com.rnis.dictionary.dictionary.route_statuses',
                    'com.rnis.dictionary.dictionary.contract_statuses',
                    'com.rnis.dictionary.dictionary.scheduled_traffic_types',
                    'com.rnis.dictionary.dictionary.seasons',
                    'com.rnis.dictionary.dictionary.route_schedule_types',
                    'com.rnis.dictionary.dictionary.schedule_statuses',
                ],
            }
        },
        'roads': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.kurs.contract',
                    'com.rnis.kurs.vehicle',
                    'com.rnis.kurs.vehicle.mechanism',
                    'com.rnis.kurs.sto',
                    'com.rnis.kurs.task',
                    'com.rnis.kurs.task_template',
                    'com.rnis.kurs.road',
                    'com.rnis.kurs.road_part',
                    'com.rnis.kurs.waybill',
                    'com.rnis.kurs.supernumerary_situation',
                    'com.rnis.kurs.technocard',
                    'com.rnis.kurs.technocard_assign',
                    'com.rnis.kurs.complaint',
                    'com.rnis.kurs.vehicle.work'
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.work_categories',
                    'com.rnis.dictionary.dictionary.work_types',
                    'com.rnis.dictionary.dictionary.measures',
                    'com.rnis.dictionary.dictionary.maintenance_groups',
                    'com.rnis.dictionary.dictionary.areas',
                    'com.rnis.dictionary.dictionary.vehicle_types',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.kurs_malfunctions',
                    'com.rnis.dictionary.dictionary.kurs_registration_units',
                    'com.rnis.dictionary.dictionary.kurs_technical_conditions',
                    'com.rnis.dictionary.dictionary.kurs_tenures',
                    'com.rnis.dictionary.dictionary.kurs_lease_prolongation_types',
                    'com.rnis.dictionary.dictionary.kurs_accounting_groups',
                    'com.rnis.dictionary.dictionary.kurs_details',
                    'com.rnis.dictionary.dictionary.kurs_engine_types',
                    'com.rnis.dictionary.dictionary.kurs_thruster_types',
                    'com.rnis.dictionary.dictionary.kurs_colors',
                    'com.rnis.dictionary.dictionary.kurs_vehicle_categories',
                    'com.rnis.dictionary.dictionary.kurs_vehicle_manufacturers',
                    'com.rnis.dictionary.dictionary.kurs_insurance_companies',
                    'com.rnis.dictionary.dictionary.kurs_service_types',
                    'com.rnis.dictionary.dictionary.kurs_service_units',
                    'com.rnis.dictionary.dictionary.kurs_repair_types',
                    'com.rnis.dictionary.dictionary.kurs_mechanism_types',
                    'com.rnis.dictionary.dictionary.kurs_contract_statuses',
                    'com.rnis.dictionary.dictionary.kurs_mechanism_uses',
                    'com.rnis.dictionary.dictionary.kurs_mechanism_bindings',
                    'com.rnis.dictionary.dictionary.kurs_task_statuses',
                    'com.rnis.dictionary.dictionary.vehicle_environmental_classes',
                    'com.rnis.dictionary.dictionary.supernumerary_situation_types',
                    'com.rnis.dictionary.dictionary.kurs_violation_types',
                    'com.rnis.dictionary.dictionary.kurs_road_repair_parts',
                    'com.rnis.dictionary.dictionary.kurs_vehicle_repair_types',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                    'com.rnis.dictionary.dictionary.kurs_formulas',
                    'com.rnis.dictionary.dictionary.work_graphics',
                    'com.rnis.dictionary.dictionary.kurs_materials',
                    'com.rnis.dictionary.dictionary.periods',
                    'com.rnis.dictionary.dictionary.work_type_groups',
                    'com.rnis.dictionary.dictionary.complaint_statuses',
                    'com.rnis.dictionary.dictionary.task_priority_additional',
                    'com.rnis.dictionary.dictionary.task_priority_main',
                    'com.rnis.dictionary.dictionary.work_class',
                    'com.rnis.dictionary.dictionary.subjects',
                    'com.rnis.dictionary.dictionary.task_type'
                ],
            }
        },
        'utility': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.utility.contract',
                    'com.rnis.utility.vehicle',
                    'com.rnis.utility.vehicle.mechanism',
                    'com.rnis.utility.sto',
                    'com.rnis.utility.task',
                    'com.rnis.utility.task_template',
                    'com.rnis.utility.road',
                    'com.rnis.utility.road_part',
                    'com.rnis.utility.waybill',
                    'com.rnis.utility.supernumerary_situation',
                    'com.rnis.utility.technocard',
                    'com.rnis.utility.technocard_assign',
                    'com.rnis.utility.complaint',
                    'com.rnis.utility.vehicle.work'
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.utility_work_categories',
                    'com.rnis.dictionary.dictionary.utility_work_types',
                    'com.rnis.dictionary.dictionary.utility_measures',
                    'com.rnis.dictionary.dictionary.utility_maintenance_groups',
                    'com.rnis.dictionary.dictionary.utility_areas',
                    'com.rnis.dictionary.dictionary.vehicle_types',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.utility_malfunctions',
                    'com.rnis.dictionary.dictionary.utility_registration_units',
                    'com.rnis.dictionary.dictionary.utility_technical_conditions',
                    'com.rnis.dictionary.dictionary.utility_tenures',
                    'com.rnis.dictionary.dictionary.utility_lease_prolongation_types',
                    'com.rnis.dictionary.dictionary.utility_accounting_groups',
                    'com.rnis.dictionary.dictionary.utility_details',
                    'com.rnis.dictionary.dictionary.utility_engine_types',
                    'com.rnis.dictionary.dictionary.utility_thruster_types',
                    'com.rnis.dictionary.dictionary.utility_colors',
                    'com.rnis.dictionary.dictionary.utility_vehicle_categories',
                    'com.rnis.dictionary.dictionary.utility_vehicle_manufacturers',
                    'com.rnis.dictionary.dictionary.utility_insurance_companies',
                    'com.rnis.dictionary.dictionary.utility_service_types',
                    'com.rnis.dictionary.dictionary.utility_service_units',
                    'com.rnis.dictionary.dictionary.utility_repair_types',
                    'com.rnis.dictionary.dictionary.utility_mechanism_types',
                    'com.rnis.dictionary.dictionary.utility_contract_statuses',
                    'com.rnis.dictionary.dictionary.utility_mechanism_uses',
                    'com.rnis.dictionary.dictionary.utility_mechanism_bindings',
                    'com.rnis.dictionary.dictionary.utility_task_statuses',
                    'com.rnis.dictionary.dictionary.vehicle_environmental_classes',
                    'com.rnis.dictionary.dictionary.utility_supernumerary_situation_types',
                    'com.rnis.dictionary.dictionary.utility_violation_types',
                    'com.rnis.dictionary.dictionary.utility_road_repair_parts',
                    'com.rnis.dictionary.dictionary.utility_vehicle_repair_types',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                    'com.rnis.dictionary.dictionary.utility_formulas',
                    'com.rnis.dictionary.dictionary.utility_work_graphics',
                    'com.rnis.dictionary.dictionary.utility_materials',
                    'com.rnis.dictionary.dictionary.periods',
                    'com.rnis.dictionary.dictionary.utility_work_type_groups',
                    'com.rnis.dictionary.dictionary.utility_complaint_statuses',
                    'com.rnis.dictionary.dictionary.utility_task_priority_additional',
                    'com.rnis.dictionary.dictionary.utility_task_priority_main',
                    'com.rnis.dictionary.dictionary.utility_work_class',
                    'com.rnis.dictionary.dictionary.utility_subjects',
                    'com.rnis.dictionary.dictionary.utility_task_type'
                ],
            }
        },
        'communal': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Справочники': [
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.vehicle_types',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                ],
            }
        },
        'children': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Справочники': [
                    'com.rnis.dictionary.dictionary.driver_license_categories',
                    'com.rnis.dictionary.dictionary.personnel_dismissal_types',
                    'com.rnis.dictionary.dictionary.vehicle_capacity_types',
                    'com.rnis.dictionary.dictionary.vehicle_environmental_classes',
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.vehicle_types',
                    'com.rnis.dictionary.dictionary.bnso_types',
                    'com.rnis.dictionary.dictionary.bnso_manufacturers',
                    'com.rnis.dictionary.dictionary.bnso_operators',
                    'com.rnis.dictionary.dictionary.distance_normals',
                    'com.rnis.dictionary.dictionary.vehicle_thresholds',
                    'com.rnis.dictionary.dictionary.contract_statuses',
                    'com.rnis.dictionary.dictionary.replacement_types',
                    'com.rnis.dictionary.dictionary.transport_connection_types',
                    'com.rnis.dictionary.dictionary.route_passport_statuses',
                    'com.rnis.dictionary.dictionary.route_kinds',
                    'com.rnis.dictionary.dictionary.route_directions',
                    'com.rnis.dictionary.dictionary.road_infrastructure_types',
                    'com.rnis.dictionary.dictionary.crosswalk_types',
                    'com.rnis.dictionary.dictionary.route_orientations',
                    'com.rnis.dictionary.dictionary.road_networks',
                    'com.rnis.dictionary.dictionary.route_statuses',
                    'com.rnis.dictionary.dictionary.scheduled_traffic_types',
                    'com.rnis.dictionary.dictionary.seasons',
                    'com.rnis.dictionary.dictionary.schedule_statuses',
                    'com.rnis.dictionary.dictionary.defects',
                    'com.rnis.dictionary.dictionary.week_days',
                    'com.rnis.dictionary.dictionary.off_days',
                    'com.rnis.dictionary.dictionary.organizational_forms',
                    'com.rnis.dictionary.dictionary.company_types',
                    'com.rnis.dictionary.dictionary.ownership_forms',
                    'com.rnis.dictionary.dictionary.rates_types',
                    'com.rnis.dictionary.dictionary.rates_kinds',
                    'com.rnis.dictionary.dictionary.transportation_types',
                ],
            }
        },
        'garbage': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.garbage.object',
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.vehicle_capacities',
                    'com.rnis.dictionary.dictionary.garbage_object_types',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                ]
            }
        },
        'gatn': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.gatn.gatn_violation',
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.gatn_responsives',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                    'com.rnis.dictionary.dictionary.vehicle_types'
                ]
            }
        },
        'taxi': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.gatn.gatn_violation',
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.gatn_responsives',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                ]
            }
        },
        'agricultural': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.gatn.gatn_violation',
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.gatn_responsives',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                ]
            }
        },
        'medic': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.gatn.gatn_violation',
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.gatn_responsives',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                ]
            }
        },
        'timber': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.gatn.gatn_violation',
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.gatn_responsives',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                ]
            }
        },
        'dangerous': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.gatn.gatn_violation',
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.gatn_responsives',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                ]
            }
        },
        'medicine': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.organizational_units.unit',
                    'com.rnis.gatn.gatn_violation',
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.gatn_responsives',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                    'com.rnis.geo.user_geo_object'
                ]
            }
        },
        'forestry': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.organizational_units.unit',
                    'com.rnis.gatn.gatn_violation',
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.gatn_responsives',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                    'com.rnis.geo.user_geo_object'
                ]
            }
        },
        'farming': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.organizational_units.unit',
                    'com.rnis.gatn.gatn_violation',
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.gatn_responsives',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                    'com.rnis.geo.user_geo_object'
                ]
            }
        },
        'official': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.organizational_units.unit',
                    'com.rnis.gatn.gatn_violation',
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.gatn_responsives',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                    'com.rnis.geo.user_geo_object'
                ]
            }
        },
        'emercom': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.organizational_units.unit',
                    'com.rnis.gatn.gatn_violation',
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.gatn_responsives',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                    'com.rnis.geo.user_geo_object'
                ]
            }
        },
        'commdept': {
            permissions: [
                'com.rnis.dictionary.document',
            ],
            innerPermissions: {
                'Сущности': [
                    'com.rnis.commdept.contract',
                    'com.rnis.commdept.vehicle',
                    'com.rnis.commdept.vehicle.mechanism',
                    'com.rnis.commdept.sto',
                    'com.rnis.commdept.task',
                    'com.rnis.commdept.task_template',
                    'com.rnis.commdept.road',
                    'com.rnis.commdept.road_part',
                    'com.rnis.commdept.waybill',
                    'com.rnis.commdept.supernumerary_situation',
                    'com.rnis.commdept.technocard',
                    'com.rnis.commdept.technocard_assign',
                    'com.rnis.commdept.vehicle.work',
                    'com.rnis.commdept.repair_program',
                    'com.rnis.commdept.maintenance'
                ],
                'Справочники': [
                    'com.rnis.dictionary.dictionary.work_categories',
                    'com.rnis.dictionary.dictionary.work_types',
                    'com.rnis.dictionary.dictionary.measures',
                    'com.rnis.dictionary.dictionary.maintenance_groups',
                    'com.rnis.dictionary.dictionary.areas',
                    'com.rnis.dictionary.dictionary.vehicle_types',
                    'com.rnis.dictionary.dictionary.vehicle_models',
                    'com.rnis.dictionary.dictionary.vehicle_marks',
                    'com.rnis.dictionary.dictionary.kurs_malfunctions',
                    'com.rnis.dictionary.dictionary.kurs_registration_units',
                    'com.rnis.dictionary.dictionary.kurs_technical_conditions',
                    'com.rnis.dictionary.dictionary.kurs_tenures',
                    'com.rnis.dictionary.dictionary.kurs_lease_prolongation_types',
                    'com.rnis.dictionary.dictionary.kurs_accounting_groups',
                    'com.rnis.dictionary.dictionary.kurs_details',
                    'com.rnis.dictionary.dictionary.kurs_engine_types',
                    'com.rnis.dictionary.dictionary.kurs_thruster_types',
                    'com.rnis.dictionary.dictionary.kurs_colors',
                    'com.rnis.dictionary.dictionary.kurs_vehicle_categories',
                    'com.rnis.dictionary.dictionary.kurs_vehicle_manufacturers',
                    'com.rnis.dictionary.dictionary.kurs_insurance_companies',
                    'com.rnis.dictionary.dictionary.kurs_service_types',
                    'com.rnis.dictionary.dictionary.kurs_service_units',
                    'com.rnis.dictionary.dictionary.kurs_repair_types',
                    'com.rnis.dictionary.dictionary.kurs_mechanism_types',
                    'com.rnis.dictionary.dictionary.kurs_contract_statuses',
                    'com.rnis.dictionary.dictionary.kurs_mechanism_uses',
                    'com.rnis.dictionary.dictionary.kurs_mechanism_bindings',
                    'com.rnis.dictionary.dictionary.kurs_task_statuses',
                    'com.rnis.dictionary.dictionary.vehicle_environmental_classes',
                    'com.rnis.dictionary.dictionary.supernumerary_situation_types',
                    'com.rnis.dictionary.dictionary.kurs_violation_types',
                    'com.rnis.dictionary.dictionary.kurs_road_repair_parts',
                    'com.rnis.dictionary.dictionary.kurs_vehicle_repair_types',
                    'com.rnis.dictionary.dictionary.bnso_indicators',
                    'com.rnis.dictionary.dictionary.kurs_formulas',
                    'com.rnis.dictionary.dictionary.work_graphics',
                    'com.rnis.dictionary.dictionary.kurs_materials',
                    'com.rnis.dictionary.dictionary.periods'
                ]
            }
        },
        'reports': {
            permissions: [],
            innerPermissions: {
                'КиУТР': [
                    'com.rnis.reports.report.kiutr.transport_work',
                    'com.rnis.reports.report.kiutr.overall_route_transport_work',
                    'com.rnis.reports.report.kiutr.summary_route_turns_report',
                    'com.rnis.reports.report.kiutr.summary_route_turn_runs_report',
                    'com.rnis.reports.report.kiutr.summary_units_route_turns_report',
                    'com.rnis.reports.report.kiutr.summary_unit_turns_report',
                    'com.rnis.reports.report.kiutr.summary_unit_runs_report',
                    'com.rnis.reports.report.kiutr.summary_unit_routes_report',
                    'com.rnis.reports.report.kiutr.tep_plan_machine_hours_report',
                    'com.rnis.reports.report.kiutr.tep_runs_plan_summary_report',
                    'com.rnis.reports.report.kiutr.tep_runs_plan_detailed_report',
                    'com.rnis.reports.report.kiutr.tep_unit_summary_report',
                    'com.rnis.reports.report.kiutr.tep_units_summary_report',
                    'com.rnis.reports.report.kiutr.tep_unit_detailed_report',
                    'com.rnis.reports.report.kiutr.ticket_sales_summary_report',
                    'com.rnis.reports.report.kiutr.ticket_sales_detailed_report',
                    'com.rnis.reports.report.kiutr.kiutr_mileages_report',
                    'com.rnis.reports.report.kiutr.kiutr_mileages_detailed_report',
                    'com.rnis.reports.report.kiutr.schedule_errors_report',
                    'com.rnis.reports.report.kiutr.kiutr_vehicles_count_report',
                    'com.rnis.reports.report.kiutr.summary_before_runs_report',
                    'com.rnis.reports.report.kiutr.units_before_runs_report',
                    'com.rnis.reports.report.kiutr.union_before_runs_report',
                    'com.rnis.reports.report.kiutr.kiutr_route_vehicles_count_report',
                    'com.rnis.reports.report.kiutr.drivers_summary_report',
                    'com.rnis.reports.report.kiutr.kiutr_vehicle_fuel_spent_report',
                    'com.rnis.reports.report.kiutr.kiutr_vehicle_fuel_spent_with_mileage_report',
                    'com.rnis.reports.report.kiutr.kiutr_vehicle_fuel_spent_by_day_report',
                    'com.rnis.reports.report.kiutr.kiutr_vehicle_fuel_level_report',
                    'com.rnis.reports.report.kiutr.kiutr_dtp_report',
                    'com.rnis.reports.report.kiutr.kiutr_telematics_history_report',
                    'com.rnis.reports.report.kiutr.passenger_traffic_report',
                    //'com.rnis.reports.report.vehicles.summary_driver_work_report',
                    //'com.rnis.reports.report.vehicles.summary_object_works_report',
                    'com.rnis.reports.report.kiutr.kiutr_equipment_consumption',
                    //'com.rnis.reports.report.vehicles.telematics_report',
                    //'com.rnis.reports.report.vehicles.vehicle_kp_report',
                    //'com.rnis.reports.report.vehicles.vehicle_kp_details_report',
                    //'com.rnis.reports.report.vehicles.unit_roads_report',
                    //'com.rnis.reports.report.vehicles.vehicle_work_report',
                    //'com.rnis.reports.report.vehicles.vehicle_work_details_report',
                    'com.rnis.reports.report.kiutr.kiutr_drivers_score_report',
                    'com.rnis.reports.report.kiutr.kiutr_vehicle_etp_analytics_report',
                    'com.rnis.reports.report.kiutr.schedule_errors_report',
                    'com.rnis.reports.report.kiutr.vehicles_lost_telematics_extend_kiutr_report',
                    'com.rnis.reports.report.kiutr.vehicle_fuel_spent_by_normative_and_equipment_kiutr_report',
                    'com.rnis.reports.report.kiutr.kiutr_units_mileage_and_run_count_report',
                    'com.rnis.reports.report.kiutr.vehicles_daily_mileage_unit_kiutr_report',
                    'com.rnis.reports.report.kiutr.kiutr_first_last_stops_report',
                    'com.rnis.reports.report.kiutr.kiutr_stops_control_report',
                    'com.rnis.reports.report.kiutr.kiutr_geozone_visit_report',
                    'com.rnis.reports.report.kiutr.kiutr_vehicle_actual_job_by_park_report',
                    'com.rnis.reports.report.kiutr.interval_schedule_report',
                    'com.rnis.reports.report.kiutr.kiutr_mileages_hourly_report',
                    'com.rnis.reports.report.kiutr.kiutr_mileages_summary_report',
                    'com.rnis.reports.report.kiutr.daily_actual_done_runs_report',
                    'com.rnis.reports.report.kiutr.kiutr_units_before_and_after_runs_report',
                    'com.rnis.reports.report.kiutr.kiutr_vehicles_last_telematics_report',
                    'com.rnis.reports.report.kiutr.kiutr_component_unit_rating_report',
                    'com.rnis.reports.report.kiutr.kiutr_vehicles_lost_telematics_report',
                    'com.rnis.reports.report.kiutr.kiutr_unit_summary_transport_work_report',
                    'com.rnis.reports.report.kiutr.kiutr_vehicles_count_alternate_report'
                ],
                'Коммунальная техника': [
                    'com.rnis.reports.report.communal.communal_vehicle_idle',
                    'com.rnis.reports.report.communal.communal_municipality_work',
                    'com.rnis.reports.report.communal.communal_total_work',
                    'com.rnis.reports.report.communal.communal_vehicle_work',
                    'com.rnis.reports.report.communal.communal_unit_work',
                    'com.rnis.reports.report.communal.communal_telematics_history_report',
                    //'com.rnis.reports.report.communal.vehicle_stops_report',
                    //'com.rnis.reports.report.communal.vehicle_stops_detailed_report',
                    'com.rnis.reports.report.communal.vehicles_daily_mileage_unit_communal_report',
                    'com.rnis.reports.report.communal.communal_geozone_visit_report',
                    'com.rnis.reports.report.communal.communal_vehicles_used_report'
                ],
                'ЦБДД': [
                    'com.rnis.reports.report.cbdd.summary_vehicles_report',
                    'com.rnis.reports.report.cbdd.component_vehicles_report',
                    'com.rnis.reports.report.cbdd.rnis_mo_report',
                    'com.rnis.reports.report.cbdd.kiutr_work_report',
                    'com.rnis.reports.report.cbdd.undone_work_report',
                    'com.rnis.reports.report.cbdd.vehicle_count_work_report',
                    'com.rnis.reports.report.cbdd.telematics_history_report',
                    'com.rnis.reports.report.cbdd.schedule_violations_report',
                    'com.rnis.reports.report.cbdd.vehicles_connect_report',
                    'com.rnis.reports.report.cbdd.component_route_rates_report',
                    'com.rnis.reports.report.cbdd.component_route_connections_report',
                    'com.rnis.reports.report.cbdd.component_unit_rating_report',
                    'com.rnis.reports.report.cbdd.summary_vehicles_with_subsystems_report',
                    'com.rnis.reports.report.cbdd.summary_vehicles_with_subsystems_and_districts_report',
                    'com.rnis.reports.report.cbdd.activity_arm_report',
                    'com.rnis.reports.report.cbdd.completed_runs_on_scheduled_transportation_report',
                    //'com.rnis.reports.report.cbdd.component_route_count_report',
                    //'com.rnis.reports.report.cbdd.routes_length_report',
                    'com.rnis.reports.report.cbdd.vehicles_daily_mileage_unit_cbdd_report',
                    'com.rnis.reports.report.cbdd.last_data',
                    'com.rnis.reports.report.cbdd.vehicles_last_data_report',
                    'com.rnis.reports.report.cbdd.cbdd_vehicles_last_data_report',
                    'com.rnis.reports.report.cbdd.cbdd_vehicles_last_data_report_mo',
                    'com.rnis.reports.report.cbdd.drivers_work_and_rest_regimes_control_report', //Отчет контроля режимов труда и отдыха водителей
                    'com.rnis.reports.report.cbdd.component_unit_rating_percent_report',
                    'com.rnis.reports.report.cbdd.spo_operation_results_report',
                    'com.rnis.reports.report.cbdd.cbdd_component_unit_rating_report',
                    'com.rnis.reports.report.cbdd.cbdd_violations_report'
                ],
                'МТДИ': [
                    'com.rnis.reports.report.mtdi.summary_units_report',
                    'com.rnis.reports.report.mtdi.summary_routes_report',
                    'com.rnis.reports.report.mtdi.work_implementation_report',
                    'com.rnis.reports.report.mtdi.work_implementation2_report',
                    'com.rnis.reports.report.mtdi.summary_violations_report',
                    'com.rnis.reports.report.mtdi.route_work_hours_report',
                    'com.rnis.reports.report.mtdi.work_accuracy_report',
                    'com.rnis.reports.report.mtdi.route_summary_report',
                    'com.rnis.reports.report.mtdi.route_summary_detailed_report',
                    'com.rnis.reports.report.mtdi.short_summary_contract_work_report',
                    'com.rnis.reports.report.mtdi.contract_runs_count_report',
                    'com.rnis.reports.report.mtdi.mtdi_unit_tep_report',
                    'com.rnis.reports.report.mtdi.mtdi_vehicle_count_report',
                    'com.rnis.reports.report.mtdi.mtdi_vehicle_age_report',
                    'com.rnis.reports.report.mtdi.mtdi_carrier_vehicle_age_report',
                    'com.rnis.reports.report.mtdi.mtdi_mileage_report',
                    'com.rnis.reports.report.mtdi.mtdi_work_dates_report',
                    'com.rnis.reports.report.mtdi.mtdi_monitoring_report',
                    'com.rnis.reports.report.mtdi.mtdi_monitoring_report_to',
                    'com.rnis.reports.report.mtdi.mtdi_dog_work_report',
                    'com.rnis.reports.report.mtdi.mtdi_work_control_report',
                    'com.rnis.reports.report.mtdi.mtdi_regularity_report',
                    'com.rnis.reports.report.mtdi.mtdi_route_regularity_report',
                    'com.rnis.reports.report.mtdi.average_age_report',
                    'com.rnis.reports.report.mtdi.mtdi_vehicles_lost_telematics_report',
                    'com.rnis.reports.report.mtdi.vehicle_violations_report',
                    'com.rnis.reports.report.mtdi.summary_routes_selfwilled_change_report',
                    'com.rnis.reports.report.mtdi.vehicles_lost_telematics_extend_mtdi_report',
                    'com.rnis.reports.report.mtdi.vehicle_fuel_spent_by_normative_and_equipment_mtdi_report',
                    'com.rnis.reports.report.mtdi.vehicles_daily_mileage_unit_mtdi_report',
                    'com.rnis.reports.report.mtdi.mtdi_route_regularity_accuracy_report',
                    'com.rnis.reports.report.mtdi.daily_actual_done_runs_report',
                    'com.rnis.reports.report.mtdi.mtdi_units_route_count_report',
                    'com.rnis.reports.report.mtdi.orders_plan_carriers_control',
                    'com.rnis.reports.report.mtdi.mtdi_summary_route_turns_report',
                    'com.rnis.reports.report.mtdi.mtdi_telematics_history_report',
                    'com.rnis.reports.report.mtdi.mtdi_speed_violations_report',
                    'com.rnis.reports.report.mtdi.kiutr_telematics_history_report',
                    'com.rnis.reports.report.mtdi.mtdi_units_before_and_after_runs_report'
                ],
                'КУРС-Д': [
                    'com.rnis.reports.report.kurs.contracts_summary_report',
                    'com.rnis.reports.report.kurs.contracts_unit_report',
                    'com.rnis.reports.report.kurs.contracts_work_type_report',
                    'com.rnis.reports.report.kurs.road_part_visits',
                    'com.rnis.reports.report.kurs.road_part_not_visits',
                    'com.rnis.reports.report.kurs.road_part_vehicle_visits',
                    'com.rnis.reports.report.kurs.summary_driver_work_report',
                    'com.rnis.reports.report.kurs.summary_object_works_report',
                    'com.rnis.reports.report.kurs.vehicle_work_report',
                    'com.rnis.reports.report.kurs.vehicle_work_details_report',
                    'com.rnis.reports.report.kurs.vehicles_last_telematics_report',
                    'com.rnis.reports.report.kurs.vehicles_lost_telematics_report',
                    'com.rnis.reports.report.kurs.vehicles_has_telematics_report',
                    'com.rnis.reports.report.kurs.speed_violations_report',
                    'com.rnis.reports.report.kurs.road_part_combined_report',
                    'com.rnis.reports.report.kurs.driver_work_detailed_report',
                    'com.rnis.reports.report.kurs.vehicles_mileage_short_report',
                    'com.rnis.reports.report.kurs.vehicles_mileage_unit_report',
                    'com.rnis.reports.report.kurs.summary_vehicle_types_report',
                    'com.rnis.reports.report.kurs.detailed_vehicle_types_report',
                    'com.rnis.reports.report.kurs.kurs_unit_work_report',
                    'com.rnis.reports.report.kurs.vehicle_malfunction_report',
                    'com.rnis.reports.report.kurs.vehicles_late_telematics_report',
                    'com.rnis.reports.report.kurs.kurs_vehicle_by_work_type_report',
                    'com.rnis.reports.report.kurs.contracts_objects_report',
                    'com.rnis.reports.report.kurs.kurs_work_types_report',
                    'com.rnis.reports.report.kurs.kurs_task_work_report',
                    'com.rnis.reports.report.kurs.kurs_summary_vehicle_work_report',
                    'com.rnis.reports.report.kurs.telematics_insert_report',
                    'com.rnis.reports.report.kurs.kurs_vehicles_work_report',
                    'com.rnis.reports.report.kurs.kurs_vehicle_time_report',
                    'com.rnis.reports.report.kurs.kurs_vehicle_points_short_report',
                    'com.rnis.reports.report.kurs.kurs_vehicle_points_short_full_report',
                    'com.rnis.reports.report.kurs.kurs_vehicle_fuel_level_report',
                    'com.rnis.reports.report.kurs.kurs_vehicle_fuel_spent_report',
                    'com.rnis.reports.report.kurs.kurs_vehicle_fuel_spent_by_day_report',
                    'com.rnis.reports.report.kurs.kurs_vehicle_fuel_spent_with_mileage_report',
                    'com.rnis.reports.report.kurs.kurs_vehicle_fuel_spent_detailed_report',
                    'com.rnis.reports.report.kurs.kurs_objects_report',
                    'com.rnis.reports.report.kurs.kurs_vehicle_work_4d_report',
                    'com.rnis.reports.report.kurs.kurs_objects_by_vehicle_types_report',
                    'com.rnis.reports.report.kurs.kurs_objects_by_vehicle_types_count_report',
                    'com.rnis.reports.report.kurs.kurs_daily_summary_report',
                    'com.rnis.reports.report.kurs.kurs_daily_summary_report_kirov',
                    'com.rnis.reports.report.kurs.object_visits_snow_report',
                    'com.rnis.reports.report.kurs.drivers_score_report',
                    'com.rnis.reports.report.kurs.kurs_work_type_deviation_report',
                    'com.rnis.reports.report.kurs.vehicle_stops_report',
                    'com.rnis.reports.report.kurs.vehicle_stops_detailed_report',
                    'com.rnis.reports.report.kurs.kurs_vehicle_etp_analytics_report',
                    'com.rnis.reports.report.kurs.vehicles_lost_telematics_extend_kurs_report',
                    'com.rnis.reports.report.kurs.vehicle_fuel_spent_by_normative_and_kurs_equipment_kurs_report',
                    'com.rnis.reports.report.kurs.vehicles_daily_mileage_unit_kurs_report',
                    'com.rnis.reports.report.kurs.vehicle_and_human_work_report',
                    'com.rnis.reports.report.kurs.kurs_task_manual_clean_report',
                    'com.rnis.reports.report.kurs.kurs_manual_clean_period_report',
                    'com.rnis.reports.report.kurs.kurs_task_fact_performance_report',
                    'com.rnis.reports.report.kurs.cleanup_specialists_report',
                    'com.rnis.reports.report.kurs.kurs_vehicles_used_report',
                    'com.rnis.reports.report.kurs.kurs_vehicles_used_by_client_report',
                    'com.rnis.reports.report.kurs.kurs_vehicles_uskurs_vehicles_used_per_day_reported_report',
                    'com.rnis.reports.report.kurs.kurs_vehicles_used_per_day_report',
                    'com.rnis.reports.report.kurs.kurs_vehicles_kurs_vehicles_used_com_mun_report',
                    'com.rnis.reports.report.kurs.vehicles_last_data_report',
                    'com.rnis.reports.report.kurs.vehicles_last_data_report_mo',
                    'com.rnis.reports.report.kurs.kurs_vehicles_last_data_report',
                    'com.rnis.reports.report.kurs.telematics_quality_check_report',
                    'com.rnis.reports.report.kurs.contracts_and_streets_report',
                    'com.rnis.reports.report.kurs.contracts_and_units_report',
                    'com.rnis.reports.report.kurs.contracts_street_maintenance_report',
                    'com.rnis.reports.report.kurs.contracts_street_maintenance_map_report',
                    'com.rnis.reports.report.kurs.kurs_telematics_history_report',
                    'com.rnis.reports.report.kurs.kurs_vehicles_used_report',
                    'com.rnis.reports.report.kurs.kurs_task_works',
                    'com.rnis.reports.report.kurs.kurs_unit_status',
                    'com.rnis.reports.report.kurs.kurs_unit_works',
                    'com.rnis.reports.report.kurs.works_volume',
                    'com.rnis.reports.report.kurs.road_part_control_work',
                    'com.rnis.reports.report.kurs.kurs_no_plan_work',
                    'com.rnis.reports.report.kurs.kurs_periodicity_work_on_tasks'
                ],
                [gatn]: [
                    'com.rnis.reports.report.gatn.gatn_violations',
                    'com.rnis.reports.report.gatn.gatn_mileages',
                    'com.rnis.reports.report.gatn.gatn_geozone_in_out',
                    'com.rnis.reports.report.gatn.gatn_geozone_visit',
                    'com.rnis.reports.report.gatn.gatn_speed_violations_report',
                    'com.rnis.reports.report.gatn.gatn_vehicle_fuel_spent_report',
                    'com.rnis.reports.report.gatn.gatn_vehicle_fuel_level_report',
                    'com.rnis.reports.report.gatn.gatn_drivers_score_report',
                    'com.rnis.reports.report.gatn.vehicles_lost_telematics_extend_gatn_report',
                    'com.rnis.reports.report.gatn.vehicle_fuel_spent_by_normative_and_gatn_equipment_gatn_report',
                    'com.rnis.reports.report.gatn.reports_vehicles_daily_mileage_unit_report',
                    'com.rnis.reports.report.gatn.vehicles_daily_mileage_unit_gatn_report',
                    'com.rnis.reports.report.gatn.gatn_geozone_visit',
                    'com.rnis.reports.report.gatn.gatn_vehicle_stops_report',
                    'com.rnis.reports.report.gatn.gatn_vehicle_stops_detailed_report'
                ],
                'Мониторинг легковых такси': [
                    'com.rnis.reports.report.taxi.taxi_violations',
                    'com.rnis.reports.report.taxi.taxi_mileages'
                ],
                'Транспорт сельского хозяйства': [
                    'com.rnis.reports.report.agricultural.agricultural_violations',
                    'com.rnis.reports.report.agricultural.agricultural_mileages'
                ],
                'Контроль медицинского транспорта': [
                    'com.rnis.reports.report.medic.medic_violations',
                    'com.rnis.reports.report.medic.medic_mileages'
                ],
                'Перевозки лесоматериалов': [
                    'com.rnis.reports.report.timber.timber_violations',
                    'com.rnis.reports.report.timber.timber_mileages'
                ],
                'Перевозки специальных опасных грузов': [
                    'com.rnis.reports.report.dangerous.dangerous_violations',
                    'com.rnis.reports.report.dangerous.dangerous_mileages',
                    'com.rnis.reports.report.dangerous.dangerous.common_vehicle_time_report',
                    'com.rnis.reports.report.dangerous.dangerous.common_vehicle_fuel_spent_report',
                    'com.rnis.reports.report.dangerous.dangerous.common_vehicles_lost_telematics_report',
                    'com.rnis.reports.report.dangerous.dangerous.common_geozone_visit_report',
                    'dangerous.common_telematics_history_report',
                    'com.rnis.reports.report.dangerous.dangerous.common_telematics_history_report'
                ],
                'Центр медицины и катастроф': [
                    'com.rnis.reports.report.medicine.medicine.common_vehicle_time_report',
                    'com.rnis.reports.report.medicine.medicine.common_vehicles_lost_telematics_report',
                    'com.rnis.reports.report.medicine.medicine.common_vehicle_fuel_spent_report',
                    'com.rnis.reports.report.medicine.medicine.common_telematics_history_report',
                    'com.rnis.reports.report.medicine.medicine.common_geozone_visit_report',
                    'com.rnis.reports.report.medicine.medicine.common_speed_violations_report',
                    'com.rnis.reports.report.medicine.medicine.common_mileages_report',
                ],
                'Лесное хозяйство': [
                    'com.rnis.reports.report.forestry.forestry.common_vehicle_time_report',
                    'com.rnis.reports.report.forestry.forestry.common_vehicles_lost_telematics_report',
                    'com.rnis.reports.report.forestry.forestry.common_vehicle_fuel_spent_report',
                    'com.rnis.reports.report.forestry.forestry.common_telematics_history_report',
                    'com.rnis.reports.report.forestry.forestry.common_geozone_visit_report',
                    'com.rnis.reports.report.forestry.forestry.common_speed_violations_report',
                    'com.rnis.reports.report.forestry.forestry.common_mileages_report',
                ],
                'Сельское хозяйство': [
                    'com.rnis.reports.report.farming.farming.common_vehicle_time_report',
                    'com.rnis.reports.report.farming.farming.common_vehicles_lost_telematics_report',
                    'com.rnis.reports.report.farming.farming.common_telematics_history_report',
                    'com.rnis.reports.report.farming.farming.common_geozone_visit_report',
                    'com.rnis.reports.report.farming.farming.common_speed_violations_report',
                    'com.rnis.reports.report.farming.farming.common_mileages_report',
                ],
                'Служебный транспорт': [
                    'com.rnis.reports.report.official.official.common_vehicle_time_report',
                    'com.rnis.reports.report.official.official.common_vehicles_lost_telematics_report',
                    'com.rnis.reports.report.official.official.common_vehicle_fuel_spent_report',
                    'com.rnis.reports.report.official.official.common_telematics_history_report',
                    'com.rnis.reports.report.official.official.common_geozone_visit_report',
                    'com.rnis.reports.report.official.official.common_speed_violations_report',
                    'com.rnis.reports.report.official.official.common_mileages_report',
                ],
                'Пожарно-спасательная служба': [
                    'com.rnis.reports.report.emercom.emercom.common_vehicle_time_report',
                    'com.rnis.reports.report.emercom.emercom.common_vehicles_lost_telematics_report',
                    'com.rnis.reports.report.emercom.emercom.common_vehicle_fuel_spent_report',
                    'com.rnis.reports.report.emercom.emercom.common_telematics_history_report',
                    'com.rnis.reports.report.emercom.emercom.common_geozone_visit_report',
                    'com.rnis.reports.report.emercom.emercom.common_speed_violations_report',
                    'com.rnis.reports.report.emercom.emercom.common_mileages_report',
                ],
                'Контроль вывоза мусора': [
                    'com.rnis.reports.report.garbage.garbage_object_visits',
                    //'com.rnis.reports.report.garbage.garbage_object_visits_2',
                    //'com.rnis.reports.report.garbage.garbage_object_visits_3',
                    //'com.rnis.reports.report.garbage.garbage_object_path',
                    'com.rnis.reports.report.garbage.garbage_unit_unexpected_stops',
                    'com.rnis.reports.report.garbage.garbage_unit_vehicles_work',
                    'com.rnis.reports.report.garbage.garbage_vehicle_unexpected_stops',
                    'com.rnis.reports.report.garbage.garbage_vehicle_work',
                    'com.rnis.reports.report.garbage.garbage_object_work',
                    'com.rnis.reports.report.garbage.garbage_unit_work',
                    'com.rnis.reports.report.garbage.garbage_unit_vehicles_turn_work',
                    'com.rnis.reports.report.garbage.vehicles_daily_mileage_unit_garbage_report',
                    'com.rnis.reports.report.garbage.garbage_geozone_visit_report'
                ],
                'Управление перевозками детей': [
                    'com.rnis.reports.report.children.children_carrier_failures',
                    'com.rnis.reports.report.children.children_carrier_fail_runs',
                    'com.rnis.reports.report.children.children_carrier_source_for_failures',
                    'com.rnis.reports.report.children.children_carrier_vehicle_turns',
                    'com.rnis.reports.report.children.children_total',
                    'com.rnis.reports.report.children.children_carrier_fail_rating',
                    'com.rnis.reports.report.children.children_carrier_drivers_rating',
                    'com.rnis.reports.report.children.children_alarms',
                    'com.rnis.reports.report.children.vehicles_lost_telematics_extend_children_report',
                    'com.rnis.reports.report.children.vehicle_fuel_spent_by_normative_and_equipment_children_report',
                    'com.rnis.reports.report.children.children_vehicle_fuel_level_report',
                    'com.rnis.reports.report.children.children_vehicle_fuel_spent_report',
                    'com.rnis.reports.report.children.vehicles_daily_mileage_unit_children_report',
                    'com.rnis.reports.report.kurs.kurs_vehicle_fuel_level_report',
                    'com.rnis.reports.report.children.children_geozone_visit_report',
                    'com.rnis.reports.report.children.children_violations_report',
                    'com.rnis.reports.report.children.children_telematics_history_report',
                    'com.rnis.reports.report.children.children_speed_violations_report'
                ],
                'Электронная маршрутная сеть': [
                    'com.rnis.reports.report.ems.routes_length_report',
                    'com.rnis.reports.report.ems.schedules_count_report',
                    'com.rnis.reports.report.ems.component_route_count_report',
                    'com.rnis.reports.report.ems.vehicles_daily_mileage_unit_ems_report'
                ],
                'Жилищно-коммунальное хозяйство': [
                    'com.rnis.reports.report.utility.utility_vehicle_malfunction_report',
                    'com.rnis.reports.report.utility.utility_contracts_objects_report',
                    'com.rnis.reports.report.utility.utility_vehicle_by_work_type_report',
                    'com.rnis.reports.report.utility.utility_daily_summary_report',
                    'com.rnis.reports.report.utility.utility_vehicle_time_report',
                    'com.rnis.reports.report.utility.utility_vehicles_last_telematics_report',
                    'com.rnis.reports.report.utility.utility_vehicle_fuel_level_report',
                    'com.rnis.reports.report.utility.utility_vehicle_stops_report',
                    'com.rnis.reports.report.utility.utility_vehicles_lost_telematics_report',
                    'com.rnis.reports.report.utility.utility_vehicle_fuel_spent_report',
                    'com.rnis.reports.report.utility.utility_summary_vehicle_types_report',
                    'com.rnis.reports.report.utility.utility_vehicle_points_short_report',
                    'com.rnis.reports.report.utility.utility_vehicles_has_telematics_report',
                    'com.rnis.reports.report.utility.utility_vehicle_work_4d_report',
                    'com.rnis.reports.report.utility.utility_speed_violations_report',
                    'com.rnis.reports.report.utility.utility_summary_driver_work_report',
                    'com.rnis.reports.report.utility.utility_objects_by_vehicle_types_count_report',
                    'com.rnis.reports.report.utility.utility_vehicles_mileage_unit_report',
                    'com.rnis.reports.report.utility.utility_vehicle_used_report',
                    'com.rnis.reports.report.utility.utility.common_telematics_history_report',
                    'com.rnis.reports.report.utility.utility_vehicle_fuel_spent_detailed_report',
                ],
            }
        },
    };
}
