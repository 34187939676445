import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import 'leaflet-polylinedecorator';

import MapComponent from "components/ui/map";
import PageModal from 'components/ui/page-modal';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import DirectionalPolyline from "components/ui/map/objects/directional-polyline";
import MapGeojson from "components/ui/map/base/geojson";

@propTypes({
    geometry: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
})

export default class NotificationRuleEditorMapComponent extends Component {

    componentDidMount() {
        this.forceUpdate();
    }

    fitBounds(element) {
        this.refs.map && this.refs.map.getWrappedInstance().fitBounds(element.getLayer().getBounds());
    }

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{title: 'Выбранная геозона', buttons}}
                onClose={this.props.onClose}
                className={`profile-modal`}
            >
                <MapComponent ref="map">
                    {this.refs.map ? (
                        <MapGeojson
                            ref={::this.fitBounds}
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            geometry={this.props.geometry}
                        />
                    ) : null}
                </MapComponent>
            </PageModal>
        );
    }
}