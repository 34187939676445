import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import $ from 'jquery';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";

import {getRouteVariant} from "store/reducers/routes/route_variants";
import {getStopPoints} from "store/reducers/routes/routes";
import {api} from "helpers/api";
import {
    createRouteVariantNullRun, getRouteVariantNullRun,
    updateRouteVariantNullRun
} from "store/reducers/kiutr/route_variant_null_run";
import Block from "components/ui/form/block";
import {getUserGeoObjects} from "store/reducers/user-map-objects/object_editor";
import RouteVariantNullRunMap from "components/modules/kiutr/routes/RouteVariantNullRunEditor/RouteVariantNullRunMap/index";
import L from 'leaflet';
import {getRoute} from "store/reducers/routes/route_editor";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import ValidationError from "components/ui/validation-error";
import {createRouteDeviation, getRouteDeviations} from "store/reducers/kiutr/route_deviations";
import {getEntityNames} from "store/reducers/system";
import Settings from 'settings';
import FileReaderInput from 'react-file-reader-input';
import GlobalLoaderComponent from "components/ui/global-loader";
import {EntityList} from "helpers/entity";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
})

@connect(state => ({}), {getRouteDeviations, createRouteDeviation})

export default class RouteDeviations extends BaseEditor {

    modalClassName = 'b-modal-route route-deviation-modal';

    getFullTitle() {
        return 'Допустимые отклонения';
    }

    printButton() {
        return null;
    }

    componentDidMount() {
        this.forceUpdate();
    }

    async loadData(uuid) {
        return await this.props.getRouteDeviations({
            order: {
                column: 'created_at',
                direction: 'asc',
            },
            filters: {
                withRoute: uuid,
            },
        });
    }

    async updateItem(data) {
        return await this.props.createRouteDeviation(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                routeUuid={this.props.uuid}
            />
        );
    }


    composeItem(data) {
        let item = _.clone(data);

        item.route_uuid = this.props.uuid;

        return item;
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getEntityNames,
    getRoute,
    getDictionaryList,
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        route_deviation: {},
        items: [],
        transport_connection_types: [],
        route: {},
        min: null,
        max: null,
        related: new EntityList,
    };

    typeMapping = {
        'Городской': 'city',
        'Пригородный': 'suburb',
        'Междугородный': 'intercity',
    };

    getData() {
        return this.state.route_deviation;
    }

    async componentDidMount() {
        await this.setState({
            items: this.props.data.items,
            route_deviation: {
                value: _.get(_.last(this.props.data.items), 'value', 0),
                file: null,
            },
        });

        this.preloadUsers();

        await this.loadDictionaries([
            'transport_connection_types',
        ]);
        this.loadRoute(this.props.routeUuid);
    }

    async preloadUsers() {
        const users = _.map(_.filter(_.map(this.state.items, 'created_by_uuid')), (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));

        const response = await this.props.getEntityNames(users);

        if (response.isOk) {
            this.state.related.add(response);
        }
    }

    async loadRoute(uuid) {
        const response = await this.props.getRoute(uuid);

        if (response.isOk) {
            this.setState({
                route: response.payload,
            });
            const type = this.getType();
            if (type && !this.state.route_deviation.value) {
                this.setValue('route_deviation.value', Settings.get(`check_${type}_after_threshold`));
            }
            if (type) {
                this.setValue('min', _.toInteger(Settings.get(`check_${type}_after_threshold`)));
                this.setValue('max', _.toInteger(Settings.get(`check_${type}_after_threshold`)) + _.toInteger(Settings.get(`check_${type}_after_limit`)));
            }
        } else {
            response.showErrors();
        }
    }

    getType() {
        return _.get(this.typeMapping, _.get(_.find(this.state.transport_connection_types, {value: _.get(this.state.route, 'transport_connection_type_uuid')}), 'label')) || 'city';
    }

    get(fieldName, defaultValue = null) {
        return _.get(this.state.route_deviation, fieldName, defaultValue);
    }

    async setValue(field, value) {
        if (field === 'route_deviation.value' && this.state.min !== null && this.state.max !== null) {
            value = Math.min(Math.max(value, this.state.min), this.state.max);
        }

        return super.setValue(field, value);
    }

    render() {
        const type = this.getType();
        if (!type) {
            return (
                <GlobalLoaderComponent/>
            );
        }

        return (
            <div>
                {this.renderDefault(type)}
                {_.map(this.state.items, ::this.renderItem)}
                {this.renderNew(type)}
            </div>
        );
    }

    renderNew(type) {
        const before = Settings.get(`check_${type}_before_threshold`);
        return (
            <div className="b-modal__block">
                <div className="b-modal__block-title">Переопределить</div>
                <Block title="Допуск по опережению, мин">
                    {before}
                </Block>
                <Block title="Допуск по опозданию, мин" className="b-block_number">
                    <div className="b-block__desc">(Допустимый диапазон: от {this.state.min} до {this.state.max})</div>
                    {this.textInput('route_deviation.value', {
                        type: 'number',
                    })}
                </Block>
                <Block size="xl" title="Документ обоснования">
                    <div className="input">
                    <div className="input__style"/>
                        {this.get('file') ? (
                            <div className="input__edit-file">
                                <a className="open-file" href={this.get('file')} target="_blank">Открыть</a>
                                <a className="delete-file" href="javascript:void(0)" onClick={::this.deleteFile}>Удалить</a>
                            </div>
                        ) : (
                            <FileReaderInput as="binary" id="my-file-input" onChange={::this.uploadFile}>
                                <a className="input__load-file" href="#">Загрузить файл</a>
                            </FileReaderInput>
                        )}
                    </div>
                </Block>
            </div>
        );
    }

    renderDefault(type) {
        const before = Settings.get(`check_${type}_before_threshold`);
        const after = Settings.get(`check_${type}_after_threshold`);
        return (
            <div className="b-modal__block">
                <div className="b-modal__block-title">Значение по умолчанию</div>
                <Block title="Допуск по опережению, мин">
                    {before}
                </Block>
                <Block title="Допуск по опозданию, мин">
                    {after}
                </Block>
            </div>
        );
    }

    renderItem(item) {
        const before = Settings.get(`check_${this.getType()}_before_threshold`);

        return (
            <div key={item.uuid}>
                <div className="b-modal__block">
                    <div className="b-modal__block-title">{`Переопределено (${this.state.related.getReact(item.created_by_uuid)})`}</div>
                    <Block title="Допуск по опережению, мин">
                        {before}
                    </Block>
                    <Block title="Допуск по опозданию, мин">
                        {item.value}
                    </Block>
                    <Block title="Документ обоснования">
                        {item.file ? (
                            <a href={item.file} target="_blank">Открыть</a>
                        ) : 'нет'}
                    </Block>
                </div>
            </div>
        );
    }

    deleteFile(e) {
        e && e.preventDefault();

        this.setValue('route_deviation.file', null);
    }

    async uploadFile(e, results) {
        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        results.forEach(result => {
            const [e, file] = result;
            //formData.append('file', e.target.result);
            formData.append('file', file);
        });

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');

        this.setValue('route_deviation.file', value);
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }
}