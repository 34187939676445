import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {getRoutes} from "store/reducers/routes/route_editor";
import {getContracts} from "store/reducers/kiutr/contracts/contracts";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {
    getNotificationEvent, createNotificationEvent, updateNotificationEvent,
    sendNotificationEvent
} from "store/reducers/notifications";
import notifications from "dictionaries/notifications";
import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import debounce from 'throttle-debounce/debounce';
import {getRoles} from "store/reducers/roles/roles";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import currentUser from 'helpers/current-user';
import PageModal from 'components/ui/page-modal';
import GlobalLoaderComponent from "components/ui/global-loader";
import ContextTooltip from "components/ui/context-tooltip";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
    entityUuid: PropTypes.string,
    entityClass: PropTypes.string,
})

@connect(state => ({}), {getNotificationEvent, createNotificationEvent, updateNotificationEvent, sendNotificationEvent})

export default class Editor extends BaseEditor {

    title = 'фильтра оповещений';
    modelClass = 'App\\Model\\NotificationEvent';

    async loadData(uuid) {
        return await this.props.getNotificationEvent(uuid);
    }

    async createItem(data) {
        return await this.props.createNotificationEvent(data);
    }

    async updateItem(data) {
        return await this.props.updateNotificationEvent(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    composeItem(item) {
        let result = _.clone(item);

        result.entity_uuid = this.props.entityUuid;
        result.entity_class = this.props.entityClass;

        return result;
    }

    render() {
        let title = this.title ? `Добавление ${this.title}` : '';

        let form;
        let buttons;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {
            title = this.title ? `Редактирование ${this.title}` : '';

            if (this.state.item) {
                form = this.getForm(this.state.item, ::this.edit);

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            <ContextTooltip key="notifications.send" code="notifications.send"
                                            default="Отправить тестовое оповещение">
                                <ModalTopMenuListItem
                                    className="b-icon-link_icon_book"
                                    onClick={::this.send}
                                />
                            </ContextTooltip>

                            <ContextTooltip key="base-editor.print" code="base-editor.print" default="Печать">
                                <ModalTopMenuListItem
                                    className="b-icon-link_icon_print"
                                    onClick={::this.print}
                                />
                            </ContextTooltip>

                            <ModalTopMenuListSeparator key="separator"/>
                        </ModalTopMenuList>

                        <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                            <ModalTopMenuButton
                                className="_save"
                                title="Сохранить"
                                onClick={::this.onEdit}
                            />
                        </ContextTooltip>

                        <ModalTopMenuButtonsSeparator/>

                        <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                            <ModalTopMenuButton
                                className="_close"
                                onClick={::this.props.onClose}
                            />
                        </ContextTooltip>
                    </ModalTopMenuButtons>
                );
            }
        } else {
            form = this.getForm({}, ::this.create);

            buttons = (
                <ModalTopMenuButtons>
                    <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                        <ModalTopMenuButton
                            className="_save"
                            title="Сохранить"
                            onClick={::this.onCreate}
                        />
                    </ContextTooltip>

                    <ModalTopMenuButtonsSeparator/>

                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={::this.props.onClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );
        }

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}
                className={`profile-modal b-modal-${this.props.mode} ${this.modalClassName}`}
                withFade={this.withFade}
            >
                {loader}
                {form}
            </PageModal>
        );
    }

    async send() {
        const response = await this.props.sendNotificationEvent(this.props.uuid);
        if (!response.isOk) {
            response.showErrors();
        }
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getUsers, getRoles}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        notification_event: {},
        roles: [],
        selectedUsers: {},
    };

    usersLoadDebounce = debounce(500, ::this.loadUsers);

    getData() {
        return this.state.notification_event;
    }

    async componentDidMount() {
        await this.setState({
            notification_event: this.props.data,
        });
        this.loadRoles();
        this.loadSelectedUsers();
    }

    async loadRoles() {
        const response = await this.props.getRoles();
        if (response.isOk) {
            this.setState({
                roles: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.notification_event, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} single={true}>
                        <Block title="Событие">
                            {this.select('notification_event.event', _.map(_.get(notifications.events, this.props.entityClass, []), (label, value) => ({
                                label,
                                value,
                            })))}
                        </Block>
                        <Block title="Тип">
                            {this.select('notification_event.via', _.map(notifications.via, (label, value) => ({
                                label,
                                value,
                            })), {
                                multi: true,
                            })}
                        </Block>
                        <Block size="xl" title="Пользователи">
                            {this.get('users', []).map((userUuid, index) => {
                                return (
                                    <div
                                        key={userUuid + index}>{_.get(_.find(this.state.selectedUsers, {value: userUuid}), 'label', '...')}
                                        <a href="#" onClick={this.removeUser.bind(this, index)}>&times;</a></div>
                                );
                            })}
                            {this.selectAsync('notification_event.user', ::this.usersLoadDebounce)}
                        </Block>
                        <Block size="xl" title="Роли">
                            {this.select('notification_event.roles', this.state.roles, {
                                multi: true,
                            })}
                        </Block>
                        <Block size="xl" title="Текст">
                            {this.textarea('notification_event.text')}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    removeUser(index, e) {
        e.preventDefault();

        let notification_event = this.state.notification_event;
        notification_event.users.splice(index, 1);

        this.setState({notification_event});
    }

    async setValue(key, value) {
        super.setValue(key, value);

        if (key === 'notification_event.user' && value) {
            let notification_event = this.state.notification_event;
            notification_event.users = notification_event.users || [];
            notification_event.users.push(value);

            await this.setState({notification_event});
            this.loadSelectedUsers();
            this.setValue(key, null);
        }
    }

    async loadSelectedUsers() {
        const response = await this.props.getUsers({
            filters: {
                withUuid: this.get('users'),
            },
        });
        if (response.isOk) {
            this.setState({
                selectedUsers: _.map(response.payload.items, (i) => ({
                    label: new User(i).getFullName(),
                    value: i.uuid,
                })),
            });
        }
    }

    async loadUsers(input, callback) {
        const result = await this.props.getUsers({
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
        });

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: new User(i).getFullName(),
                    value: i.uuid,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }
}