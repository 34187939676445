import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapMarker from "components/ui/map/base/marker";
import $ from 'jquery';
import VehicleIcon from "components/ui/map/markers/icons/vehicle-icon";
import ReactPopup from "react-leaflet-popup";

@propTypes({
    vehicle: PropTypes.object,
    device: PropTypes.object,
})

@defaultProps({
    vehicle: null,
    device: null,
})

export default class VehicleMarker extends AbstractMapMarker {

    animation = true;

    getOptions() {
        return {
            icon: VehicleIcon.getIcon(this.props.vehicle, this.props.device, this.props.fast, this.props.color),
            riseOnHover: true,
        };
    }

    componentWillMount() {
        super.componentWillMount();

        if (this.props.onClick === null) {
            this.getMarker().on('click', ::this.loadPopup)
        }
        if (this.props.title) {
            this.getMarker().bindTooltip(this.props.title, {
                sticky: true,
                permanent: this.props.permanentToolTip,
            });
        }
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        const icon = this.getMarker()._icon;
        if (icon) {
            $(icon).find('.marker__direction').css('transform', `rotate(${this.props.device.course}deg)`);
        }
    }

    loadPopup() {
        if (!this.props.vehicle) {
            return;
        }

        const marker = this.getMarker();
        marker.unbindPopup();
        marker.closePopup();
        marker.bindPopup(
            this.createPopup()
        );
        marker.openPopup();
    }

    createPopup() {
        const vehicle = this.props.vehicle;
        if (!vehicle) {
            return null;
        }

        return new ReactPopup({
            reactComponent: Popup,
            reactComponentProps: {
                ...this.props,
                vehicle,
                device: this.props.device,
            }
        });
    }
}

class Popup extends Component {

    render() {
        const vehicle = this.props.vehicle;
        const device = this.props.device;

        return (
            <div className="map-popup" onClick={thos.props.onClick}>
                <p>{vehicle.state_number}</p>
                <p>{device.speed} км/ч</p>
            </div>
        );
    }
}