import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {api} from "helpers/api";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUnits} from "store/reducers/organizational_units/units";
import FileReaderInput from 'react-file-reader-input';
import PageModal from 'components/ui/page-modal';
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import currentUser from 'helpers/current-user';
import GlobalLoaderComponent from "components/ui/global-loader";
import ContextTooltip from "components/ui/context-tooltip";
import {
    createKursMaintenance, getKursMaintenance, getKursMaintenances,
    updateKursMaintenance
} from "store/reducers/commdept/maintenances";
import TableContainer from "components/ui/Table/Container/TableContainer";
import './editor.less';
import {MaintenancePeriods} from "dictionaries/commdept";
import {getVehicleWorks} from "store/reducers/commdept/vehicle_works";
import * as alerts from "helpers/alerts";


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getKursMaintenance, createKursMaintenance, updateKursMaintenance})

export default class Editor extends BaseEditor {

    title = 'графика ТО';
    modelClass = 'App\\Model\\KursMaintenance';
    modalClassName = 'b-modal-road-accounting edit-service-modal';
    withFade = false;

    getButtons() {
        return [
            <div key="tooltip" className="b-modal__footer-txt">Вы хотите сохранить все изменения?</div>,
            <a key="cancel" href="#"
               className="b-button b-button_size_md b-button_white b-button_shadow_gray b-button_cancel"
               onClick={this.props.onClose}>Отменить</a>,
            <a key="save" href="#" className="b-button b-button_red b-button_size_md b-button_save"
               onClick={(this.props.mode === 'edit') ? ::this.onEdit : ::this.onCreate}>Сохранить</a>
        ];
    }

    saveButton(onClick) {
        return null;
    }

    async loadData(uuid) {
        return await this.props.getKursMaintenance(uuid);
    }

    async createItem(data) {
        data.data.forEach(item => {
                item.machine_hours_value = item.machine_hours_value ? Number(item.machine_hours_value) : null;
                item.mileage_value = item.mileage_value ? Number(item.mileage_value) : null;
                item.months = item.months ? Number(item.months) : null;
        })

        return await this.props.createKursMaintenance(data);
    }

    async updateItem(data) {
        data.data.forEach(item => {
            item.machine_hours_value = item.machine_hours_value ?  Number(item.machine_hours_value) : null;
            item.mileage_value = item.mileage_value ?  Number(item.mileage_value) : null;
            item.months = item.months ?  Number(item.months) : null;
        })
        return await this.props.updateKursMaintenance(data);
    }

    printButton() {
        return null;
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                onDataLoad={::this.forceUpdate}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    composeItem(data) {
        let item = _.clone(data);
        item.component = this.props.component;
        return item;
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDataLoad: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getDictionaryList, getKursMaintenances, getVehicleWorks}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        kurs_maintenance: {},
        vehicle_models: [],
        vehicle_marks: [],
        months: MaintenancePeriods,
        maintenance_models: [],
    };

    getData() {
        return this.state.kurs_maintenance;
    }

    async componentDidMount() {

        await this.setState({
            kurs_maintenance: this.props.editorCloneData || this.props.data,
        });

        this.loadMaintenances();

        this.loadDictionaries([
            'vehicle_models',
            'vehicle_marks',
        ], this.props.component);
    }

    async loadMaintenances() {
        const response = await this.props.getKursMaintenances();

        if (response.isOk) {
            this.setState({
                maintenance_models: _.map(response.payload.items, 'vehicle_model_uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.kurs_maintenance, path, defaultValue);
    }

    render() {
        return (
            <div>
                <div className="b-modal__block">
                    <Block title="Марка ТС">
                        {this.select('kurs_maintenance.vehicle_mark_uuid', this.state.vehicle_marks)}
                    </Block>
                    <Block title="Модель ТС">
                        {this.select('kurs_maintenance.vehicle_model_uuid', _.filter(this.state.vehicle_models, (model) => {
                            return (_.get(model, 'document.vehicle_mark_uuid') === this.get('vehicle_mark_uuid')) && (model.value === this.get('vehicle_model_uuid') || _.indexOf(this.state.maintenance_models, model.value) === -1);
                        }))}
                    </Block>
                    <Block title="Выбор единиц измерения">
                        {this.radio('kurs_maintenance.type', 'mileage', 'Пробег')}
                        {this.radio('kurs_maintenance.type', 'machine_hours', 'Моточасы')}
                    </Block>
                </div>
                <div className="Table">
                    <TableContainer>
                        <table className="b-table b-table-no-hover">
                            <thead>
                            <tr>
                                <th>№ ТО</th>
                                <th>План. срок, мес</th>
                                {this.isMileage() ? (
                                    <th>План. пробег (км.)</th>
                                ) : null}
                                {this.isMachineHours() ? (
                                    <th>План. м/ч (ч.)</th>
                                ) : null}
                                {!window.RNIS_SETTINGS.CITY_NNOVGOROD ? (
                                    <th>Повтор</th>
                                ) : null}

                            </tr>
                            </thead>
                            <tbody>
                            {this.get('data', []).map(::this.renderDataRow)}
                            </tbody>
                        </table>
                        <div className="add-line" onClick={::this.addRow}>
                            Добавить строку
                        </div>
                        <div className="add-line" onClick={::this.deleteRow}>
                            Удалить строку
                        </div>
                    </TableContainer>
                </div>
            </div>
        );
    }

    isMileage() {
        return this.get('type') === 'mileage';
    }

    isMachineHours() {
        return this.get('type') === 'machine_hours';
    }

    renderDataRow(row, index) {
        return (
            <tr key={index}>
                <td>ТО {row.number}</td>
                <td>{this.textInput(`kurs_maintenance.data.${index}.months`)}</td>
                {this.isMileage() ? (
                    <td>{this.textInput(`kurs_maintenance.data.${index}.mileage_value`)}</td>
                ) : null}
                {this.isMachineHours() ? (
                    <td>{this.textInput(`kurs_maintenance.data.${index}.machine_hours_value`)}</td>
                ) : null}
                <td>{this.checkbox(`kurs_maintenance.data.${index}.is_repeated`)}</td>
            </tr>
        );
    }

    async checkRepeatedUncheck() {
        const checked = _.find(this.state.kurs_maintenance.data, {is_repeated: true});
        if (!checked) {
            return true;
        }
        if (!this.get('vehicle_model_uuid')) {
            return true;
        }

        const response = await this.props.getVehicleWorks({
            filters: {
                withModel: this.get('vehicle_model_uuid'),
                withType: 'maintenance',
                withName: _.toInteger(checked.number) + 1,
            },
        });

        if (response.isOk) {
            if (response.payload.items.length === 0) {
                return true;
            } else {
                alerts.alert('Снятие отметки невозможно');
                return false;
            }
        } else {
            response.showErrors();
        }
        return true;
    }

    async setValue(field, value) {
        if (_.endsWith(field, 'is_repeated') && !value) {
            if (!await this.checkRepeatedUncheck()) {
                return;
            }
        }

        super.setValue(field, value);

        if (_.endsWith(field, 'is_repeated') && value) {
            _.each(this.get('data', []), (row, index) => {
                const fieldname = `kurs_maintenance.data.${index}.is_repeated`;
                if ((field !== fieldname) && row.is_repeated) {
                    this.setValue(fieldname, false);
                }
            });
        }

        if (field === 'kurs_maintenance.vehicle_mark_uuid') {
            this.setValue('kurs_maintenance.vehicle_model_uuid', null);
        }
    }

    addRow() {
        let kurs_maintenance = this.state.kurs_maintenance;
        let data = _.get(kurs_maintenance, 'data', []);
        data.push({
            number: _.get(_.last(data), 'number', -1) + 1,
            months: null,
            mileage_value: null,
            machine_hours_value: null,
            is_repeated: false,
        });
        _.set(kurs_maintenance, 'data', data);
        this.setState({kurs_maintenance});
    }

    async deleteRow() {
        const last = _.last(this.state.kurs_maintenance.data);

        const response = await this.props.getVehicleWorks({
            filters: {
                withModel: this.get('vehicle_model_uuid'),
                withType: 'maintenance',
                withName: last.number,
            },
        });

        if (response.isOk) {
            if (response.payload.items.length === 0) {
                let kurs_maintenance = this.state.kurs_maintenance;
                kurs_maintenance.data.splice(kurs_maintenance.data.length - 1, 1);
                this.setState({kurs_maintenance});
            } else {
                alerts.alert('Удаление невозможно');
            }
        } else {
            response.showErrors();
        }
    }
}
