import React from 'react';
import {connect} from 'react-redux';

import Editor from './RoadEditor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import {getEntityNames} from "store/reducers/system";
import {deleteRoad, getRoads} from "store/reducers/commdept/roads";
import KursRoadMapComponent from "./Map";
import $ from 'jquery';

@connect(state => ({}), {getRoads, deleteRoad, getEntityNames})

export default class CommdeptRoadsComponent extends BaseTableWithEditorComponent {

    title = `${systems.commdept} → Справочники → Дороги`;
    baseUrl = '/commdept/roads';

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            showMapData: null,
        });
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Название автодороги')
                .fromField('name'),

            new Column('Вид дороги')
                .fromField('kind'),

            new Column('Протяженность, км')
                .fromField('length'),

            /*new Column('Карта')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => (
                    `<a href="javascript:void(0)" class="map-link" data-start-latitude="${_.get(item, 'start_at.latitude')}" data-start-longitude="${_.get(item, 'start_at.longitude')}" data-end-latitude="${_.get(item, 'end_at.latitude')}" data-end-longitude="${_.get(item, 'end_at.longitude')}">Карта</a>`
                )),*/
        ]);
    }

    onInitialized(table) {
        table.on('click', '.map-link', ::this.showMap);
    }

    renderModals() {
        return this.state.showMapData ? (
            <KursRoadMapComponent
                data={this.state.showMapData}
                onClose={::this.hideMap}
            />
        ) : null;
    }

    showMap(e) {
        const data = {
            from: [
                $(e.target).data('start-latitude'),
                $(e.target).data('start-longitude'),
            ],
            to: [
                $(e.target).data('end-latitude'),
                $(e.target).data('end-longitude'),
            ],
        };
        this.setState({
            showMapData: data,
        });
    }

    hideMap() {
        this.setState({
            showMapData: null,
        });
    }

    async deleteItem(data) {
        return await this.props.deleteRoad(data);
    }

    async loadData(meta) {
        return await this.props.getRoads(meta);
    }
}
