import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './input.less';
import InputElement from 'react-input-mask';

@propTypes({
    mask: PropTypes.string,
})

@defaultProps({})

export default class InputMask extends Component {
    render() {
        const inputProps = {};

        if (this.props.onEnter)
            inputProps.onKeyPress = ::this.handleKeyPress;

        if (this.props.name)
            inputProps.name = this.props.name;

        if (this.props.value) {
            inputProps.value = this.props.value;
        }

        inputProps.disabled = this.props.disabled;
        inputProps.onKeyPress = this.props.onKeyPress;

        const styleClassName = classNames('input__style', this.props.error ? 'input__style_state_wrong' : '');

        return (
            <div className={classNames('input', this.props.className)}>
                <InputElement
                    ref="input"
                    mask={this.props.mask}
                    className="input__control"
                    defaultValue={this.props.defaultValue}
                    type={this.props.type}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    {...inputProps} />
                <div className={styleClassName}>
                    {this.props.error ? <span className="input__style_state_msg input__style_state_msg_wrong">{this.props.error}</span> : null}
                </div>
            </div>
        );
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.props.onEnter();
        }
    }
}