export default {
    DATE: 'DD.MM.YYYY',
    DATE_SHORT: 'DD.MM',
    TIME: 'HH:mm',
    TIME_FULL: 'HH:mm:ss',
    TIME_FULL_SHORT: 'mm:ss',
    DATETIME: 'DD.MM.YYYY HH:mm:ss',
    DATETIME_SHORT: 'DD.MM.YYYY HH:mm',
    DATE_API: 'YYYY-MM-DDT00:00:00Z',
    DATETIME_API: 'YYYY-MM-DDTHH:mm:ssZ',
    T1DATE: 'YYYY-MM-DD',
    T1DATETIME: 'YYYY-MM-DD HH:mm:ss',
    DATE_URL: 'YYYY-MM-DD',
};