import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getTechnocardAssigns = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.getTechnocardAssigns(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTechnocardAssign = (uuid) => async (dispatch) => {
    try {
        const response = await api.commdept.getTechnocardAssign(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createTechnocardAssign = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.createTechnocardAssign(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateTechnocardAssign = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.updateTechnocardAssign(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteTechnocardAssign = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.deleteTechnocardAssign(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
