import {Map} from "immutable";
import {api} from "helpers/api";
import {success, error} from 'helpers/response';

const GET_DOCUMENT = 'GET_DOCUMENT';
const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
const DELETE_DOCUMENT = 'DELETE_DOCUMENT';

const initialState = new Map({
    document: Map({})
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_DOCUMENT:
            return state
            .set('document', action.payload);
    }

    return state;
}

export const getDocument = (key, uuid) => async (dispatch) => {
    try {
        let response = await api.dictionary.getDocument(key, uuid);

        dispatch({
            type: GET_DOCUMENT,
            payload: response.payload
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createDocument = (key, document) => async (dispatch) => {
    try {
        let response = await api.dictionary.createDocument(key, document);

        dispatch({
            type: CREATE_DOCUMENT,
            payload: response.payload
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateDocument = (key, document) => async (dispatch) => {
    try {
        // конвертирование портов (редактирование: типы БНСО) из числа в строку
        if (key.dictionary.key === "bnso_types") {
            convertBnsoPortsToString(key.data);
        }
        let response = await api.dictionary.updateDocument(key, document);

        dispatch({
            type: UPDATE_DOCUMENT,
            payload: response.payload
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteDocument = (key, document) => async (dispatch) => {
    try {
        let response = await api.dictionary.deleteDocument(key, document);

        dispatch({
            type: DELETE_DOCUMENT,
            payload: response.payload
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

// В справочнике Редактирование: Типы БНСО (Внешние порты) преобраование номера порта из числа в строку
const convertBnsoPortsToString = (data) => {
    return data.ports.map((port) => {
        port.number = port.number.toString();
        return port;
    })
};