import {rpc} from './session';

export async function getComplaints(meta = {}) {
    return await rpc.request('com.rnis.kurs.action.complaint.list', {}, {meta});
}

export async function getComplaintById(uuid) {
    return await rpc.request('com.rnis.kurs.action.complaint.get', {uuid});
}

export async function updateComplaint(data) {
    return await rpc.request('com.rnis.kurs.action.complaint.update', data);
}

export async function deleteComplaint(data) {
    return await rpc.request('com.rnis.kurs.action.complaint.delete', data);
}

export async function updateNSUsernameWithUnitUuid(data) {
    return await rpc.request('com.rnis.kurs.action.complaint_account_unit.assign', data);
}

export async function getNSUsernameWithUnitUuid() {
    return await rpc.request('com.rnis.kurs.action.complaint_account_unit.list');
}

export async function getNSUsernameWithEmailPhoneInfo() {
    return await rpc.request('com.rnis.kurs.action.complaint.accounts.list');
}