import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import $ from 'jquery';
import uuidv1 from 'uuid/v1';
import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";

import {getRouteVariants} from "store/reducers/routes/route_variants";
import {getStopPoints} from "store/reducers/routes/routes";
import {api} from "helpers/api";
import Block from "components/ui/form/block";
import {getRoute, updateRoute} from "store/reducers/routes/route_editor";
import Settings from 'settings';
import FileReaderInput from 'react-file-reader-input';
import GlobalLoaderComponent from "components/ui/global-loader";
import {EntityList} from "helpers/entity";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import TableContainer from "components/ui/Table/Container/TableContainer";
import * as alerts from "helpers/alerts";
import './index.less';

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
})

@connect(state => ({}), {getRoute, updateRoute})

export default class RouteSpiral extends BaseEditor {

    modalClassName = 'b-modal-route spiral-structure-modal';
    withFade = false;

    componentDidMount() {
        if(this.props.isPageWithDetect && this.modalConfirmation) {
            document.addEventListener('click', this.handleClickOutside, true)
        }
    }

    componentWillUnmount() {
        if(this.props.isPageWithDetect && this.modalConfirmation) {
            document.removeEventListener('click', this.handleClickOutside)
        }
    }

    getFullTitle() {
        return 'Структура спирального расписания по маршруту';
    }

    async loadData(uuid) {
        return await this.props.getRoute(uuid);
    }

    async updateItem(data) {
        return await this.props.updateRoute(data);
    }

    turnedEditingMode() {
        this.setState({isEditingNow: true})
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                routeUuid={this.props.uuid}
                turnedEditingMode={this.turnedEditingMode}
            />
        );
    }

    printButton() {
        return null;
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    turnedEditingMode: PropTypes.func,
})

@connect((state) => ({}), {
    getRouteVariants,
    getStopPoints,
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        route: {},
        routeVariants: {},
        stopPoints: [],
        selectedGtem: null,
    };

    getData() {
        return this.state.route;
    }

    async componentDidMount() {
        await this.setState({
            route: this.prepareRoute(this.props.data),
        });

        this.loadRouteVariants();
    }

    prepareRoute(route) {
        const groups = route.groups || [];
        route.spiral = _.filter(route.spiral || [], (uuid) => (_.find(groups, {uuid})));
        return route;
    }

    async loadRouteVariants() {
        const response = await this.props.getRouteVariants({
            filters: {
                withRoute: this.state.route.uuid,
            },
        });

        if (response.isOk) {
            await this.setState({
                routeVariants: _.keyBy(response.payload.items, 'uuid'),
            });

            this.loadStopPoints();
        } else {
            response.showErrors();
        }
    }

    async loadStopPoints() {
        const uuids = _.uniq(_.flatten(_.map(this.state.routeVariants, (routeVariant) => {
            return _.filter(_.concat(_.map(routeVariant.forward_points, 'type_uuid'), _.map(routeVariant.reverse_points, 'type_uuid')));
        })));

        const response = await this.props.getStopPoints({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            this.setState({
                stopPoints: _.sortBy(_.map(response.payload.items, item => ({
                    value: item.uuid,
                    label: `${item.register_number} ${item.title}`,
                })), 'label'),
            });
        } else {
            response.showErrors();
        }
    }

    get(fieldName, defaultValue = null) {
        return _.get(this.state.route, fieldName, defaultValue);
    }

    render() {
        return (
            <div className="clearAfter">
                <div className="half">
                    <div className="Table">
                        <TableContainer>
                            <table className="b-table b-table-no-hover">
                                <thead>
                                <tr className="wrap-normal">
                                    <th>Прямое направление</th>
                                    <th>Номер</th>
                                    <th>Обратное направление</th>
                                </tr>
                                </thead>
                                <tbody>
                                {_.map(this.get('spiral') || [], ::this.renderRow)}
                                <tr>
                                    <td colSpan={3} className="align-center">
                                        <a className="add-block" href="javascript:void(0)"
                                           onClick={this.addGroup.bind(this, (this.get('spiral') || []).length)}>+
                                            Добавить</a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </TableContainer>
                    </div>
                </div>
                {this.renderUnusedStopPoints()}
            </div>
        );
    }

    renderUnusedStopPoints() {
        const unusedStopPoints = this.getUnusedStopPoints();

        return (
            <div className="half undisposed">
                <div className="page-block">
                    <div className="page-block__title">Нераспределенные ОП</div>
                    {_.map(unusedStopPoints, ({unusedForward, unusedReverse}, routeVariantUuid) => {
                        if (unusedForward.length + unusedReverse.length === 0) {
                            return null;
                        }

                        return (
                            <Block key={routeVariantUuid}
                                   title={_.get(this.state.routeVariants, `${routeVariantUuid}.name`)}>
                                {(unusedForward.length > 0) ? (
                                    <div className="points-block">
                                        <div className="direction-type">Прямой:</div>
                                        {_.map(unusedForward, (point) => {
                                            return (
                                                <div className="stop-point" key={point.uuid}>
                                                    {point.index + 1}. {_.get(_.find(this.state.stopPoints, {value: point.uuid}), 'label')}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                                {(unusedReverse.length > 0) ? (
                                    <div className="points-block">
                                        <div className="direction-type">Обратный:</div>
                                        {_.map(unusedReverse, (point) => {
                                            return (
                                                <div className="stop-point" key={point.uuid}>
                                                    {point.index + 1}. {_.get(_.find(this.state.stopPoints, {value: point.uuid}), 'label')}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}

                            </Block>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderRow(groupUuid, index) {
        const group = _.find(this.get('groups'), {uuid: groupUuid});

        return (
            <tr key={index}>
                <td>
                    {this.renderGroupItems(group, true)}
                </td>
                <td className="align-center vertical-middle">
                    <a className="add-item" href="javascript:void(0)" onClick={this.addGroup.bind(this, index)}>+</a>
                    {index + 1}
                    <a className="remove-item" href="javascript:void(0)"
                       onClick={this.deleteGroup.bind(this, index)}>&times;</a>
                </td>
                <td>
                    {this.renderGroupItems(group, false)}
                </td>
            </tr>
        )
    }

    renderGroupItems(group, isForward) {
        return _.map(group.items, (item, index) => {
            if (_.filter(item.inclusions, {is_forward: isForward}).length === 0) {
                return null;
            }
            const stopPoint = _.get(_.find(this.state.stopPoints, {value: item.stop_point_uuid}), 'label');

            return (
                <div className="point" key={index}>
                    {stopPoint}
                    ({_.filter(item.inclusions, {is_forward: isForward}).length}/{_.filter(this.getAllInclusions(item.stop_point_uuid), {is_forward: isForward}).length})
                </div>
            )
        });
    }

    getAllInclusions(uuid) {
        let result = [];

        _.each(this.state.routeVariants, (routeVariant) => {
            const forwardPoints = _.filter(routeVariant.forward_points, {point_type: 'stop_point'});
            _.each(forwardPoints, (point, index) => {
                if (point.type_uuid === uuid) {
                    result.push({
                        route_variant_uuid: routeVariant.uuid,
                        is_forward: true,
                        index,
                    });
                }
            });
            const reversePoints = _.filter(routeVariant.reverse_points, {point_type: 'stop_point'});
            _.each(reversePoints, (point, index) => {
                if (point.type_uuid === uuid) {
                    result.push({
                        route_variant_uuid: routeVariant.uuid,
                        is_forward: false,
                        index,
                    });
                }
            });
        });

        return result;
    }

    addGroup(index) {
        const groups = _.filter(this.get('groups'), (group) => {
            return _.indexOf(this.get('spiral'), group.uuid) === -1;
        });

        alerts.askSelect('Выберите группу:', _.map(groups, group => ({
            value: group.uuid,
            label: group.name,
        })), (groupUuid) => {
            if (!groupUuid) {
                alerts.alert('Необходимо выбрать группу');
                return;
            }
            let route = this.state.route;
            route.spiral = route.spiral || [];
            route.spiral.splice(index, 0, groupUuid);
            this.setState({route});
        });
    }

    deleteGroup(index) {
        let route = this.state.route;
        route.spiral = route.spiral || [];
        route.spiral.splice(index, 1);
        this.props.turnedEditingMode()
 
    }

    isUsed(routeVariantUuid, stopPointUuid, index, isForward) {
        return _.filter(this.get('groups') || [], (group) => {
            if (_.indexOf(this.get('spiral'), group.uuid) === -1) {
                return false;
            }
            return _.filter(group.items, (item) => {
                return (item.stop_point_uuid === stopPointUuid) && (_.filter(item.inclusions, {
                    route_variant_uuid: routeVariantUuid,
                    is_forward: isForward,
                    index,
                }).length > 0);
            }).length > 0;
        }).length > 0;
    }

    getUnusedStopPoints() {
        return _.mapValues(_.keyBy(this.state.routeVariants, 'uuid'), (routeVariant) => {
            const unusedForward = _.filter(_.map(_.filter(routeVariant.forward_points, {point_type: 'stop_point'}), (point, index) => {
                return {
                    used: !this.isUsed(routeVariant.uuid, point.type_uuid, index, true),
                    index,
                    uuid: point.type_uuid,
                };
            }), {
                used: true,
            });
            const unusedReverse = _.filter(_.map(_.filter(routeVariant.reverse_points, {point_type: 'stop_point'}), (point, index) => {
                return {
                    used: !this.isUsed(routeVariant.uuid, point.type_uuid, index, false),
                    index,
                    uuid: point.type_uuid,
                };
            }), {
                used: true,
            });

            return {
                unusedForward,
                unusedReverse,
            };
        });
    }
}