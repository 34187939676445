import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import L from 'leaflet';

import './map-search.less';
import SearchField from "components/ui/search-field";
import {getMapSearch, selectSearchResult} from "store/reducers/user-map-objects/map_search";
import {connect} from "react-redux";
import TextField from "components/ui/text-field";
import 'leaflet.pm';
import 'leaflet.pm/dist/leaflet.pm.css';
import _ from 'lodash';
import ContextTooltip from "components/ui/context-tooltip";
import {activateLayer, deactivateLayer} from "store/reducers/user-map-objects/layers";

@propTypes({
    onClick: PropTypes.func.isRequired,
    map: PropTypes.object.isRequired,
    parent: PropTypes.object.isRequired,
    searchByMO: PropTypes.bool,
})

@defaultProps({
    searchByMO: false,
})

@connect(state => ({
    map_search: state.user_map_objects.map_search.get('map_search'),
    map_search_string: state.user_map_objects.map_search.get('map_search_string'),
    layers: state.user_map_objects.layers.get('layers'),
    activeLayers: state.user_map_objects.layers.get('activeLayers'),
    selectedSearchResult: state.user_map_objects.map_search.get('selectedSearchResult'),
}), {
    getMapSearch,
    selectSearchResult,
    activateLayer,
    deactivateLayer,
}, null, {withRef: true})

export default class MapSearch extends Component {

    boundsLayer = null;

    state = {
        collapsed: false,
        hasResult: false,
        boundsActive: false,
        selectMOActive: false,
        selectOneMOActive: false,
    };

    render() {
        const toggleBtnText = this.state.collapsed ? <span>&darr; Показать</span> : <span>&uarr; Скрыть</span>;

        let results = null;
        if (this.props.map_search.length > 0) {
            results = (
                <div className="results">
                    <div className="toggle-btn" onClick={::this.toggleClick}>
                        {toggleBtnText}
                    </div>
                    <div className="list">
                        {this.state.collapsed ? null : this.props.map_search.map(::this.renderItem)}
                    </div>
                </div>
            );
        } else if (this.state.hasResult) {
            results = (
                <div className="results">
                    <div className="list">
                        <div className="list-item inactive">
                            Поиск не дал результатов
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="MapSearch">
                    <div className="f-search f-search-posit visible">
                        <form action="#">
                            <TextField placeholder="Поиск" withoutWrapper={true} className="f-search__pole" ref="input"
                                       defaultValue={this.props.map_search_string}
                                       onEnter={::this.onClick}/>
                            <input className="f-search__button" type="button" onClick={::this.onClick}/>
                        </form>
                    </div>
                    {results}
                </div>
                <div className="searchBlock">
                    {this.state.selectMOActive ? [
                        <ContextTooltip key="polygon" default="Выделение области на карте" position="left">
                            <div key="polygon" className="searchBlock__item">
                                <div className={classNames('searchBlock__btn searchBlock__btn_area', {
                                    'searchBlock__btn_white': !this.state.boundsActive,
                                    'searchBlock__btn_red': this.state.boundsActive,
                                })} onClick={::this.togglePolygonActive}>
                                    <i className={classNames('rnis-icon', {
                                        'rnis-icon_search_area': !this.state.boundsActive,
                                        'rnis-icon_close_white': this.state.boundsActive,
                                    })}/>
                                </div>
                            </div>
                        </ContextTooltip>,
                        <ContextTooltip key="mo" default="Выделение МО" position="left">
                            <div key="mo" className="searchBlock__item">
                                <div className={classNames('searchBlock__btn searchBlock__btn_area', {
                                    'searchBlock__btn_white': !this.state.selectOneMOActive,
                                    'searchBlock__btn_red': this.state.selectOneMOActive,
                                })} onClick={::this.toggleOneMOActive}>
                                    <i className={classNames('rnis-icon', {
                                        'rnis-icon_search_area': !this.state.selectOneMOActive,
                                        'rnis-icon_close_white': this.state.selectOneMOActive,
                                    })}/>
                                </div>
                            </div>
                        </ContextTooltip>,
                    ] : null}
                    <ContextTooltip default="Выделение области на карте" position="left">
                        <div className="searchBlock__item">
                            <div className={classNames('searchBlock__btn searchBlock__btn_area', {
                                'searchBlock__btn_white': !(this.state.boundsActive || this.state.selectMOActive),
                                'searchBlock__btn_red': this.state.boundsActive || this.state.selectMOActive,
                            })} onClick={::this.toggleBoundsActive}>
                                <i className={classNames('rnis-icon', {
                                    'rnis-icon_search_area': !(this.state.boundsActive || this.state.selectMOActive),
                                    'rnis-icon_close_white': this.state.boundsActive || this.state.selectMOActive,
                                })}/>
                            </div>
                        </div>
                    </ContextTooltip>
                </div>
            </div>
        );
    }

    async toggleBoundsActive() {
        if (this.props.searchByMO) {
            await this.setState({
                selectMOActive: !this.state.selectMOActive,
            });
        } else {
            this.togglePolygonActive();
        }
    }

    getMOLayerUuid() {
        return _.get(_.find(this.props.layers, {
            title: 'Муниципальные образования (МО)',
        }), 'uuid');
    }

    async toggleOneMOActive() {
        await this.setState({
            selectOneMOActive: !this.state.selectOneMOActive,
        });

        const uuid = this.getMOLayerUuid();

        if (this.state.selectOneMOActive) {
            this.props.activateLayer(uuid);
        } else {
            this.props.deactivateLayer(uuid);
            this.boundsLayer && this.props.map.removeLayer(this.boundsLayer);
            this.boundsLayer = null;
        }

        this.props.map.fire('moveend');
    }

    async togglePolygonActive() {
        await this.setState({
            boundsActive: !this.state.boundsActive,
        });

        this.checkPolygonState();
    }

    selectMO(geometry) {
        if (this.boundsLayer) {
            this.props.map.removeLayer(this.boundsLayer);
            this.boundsLayer = null;
        }
        this.boundsLayer = new L.GeoJSON(geometry);
        this.boundsLayer.addTo(this.props.map);

        const uuid = this.getMOLayerUuid();
        this.props.deactivateLayer(uuid);
        this.props.map.fire('moveend');
    }

    checkPolygonState() {
        if (this.state.boundsActive) {
            const bounds = this.props.map.getBounds().pad(-0.1);
            this.boundsLayer = new L.Polygon([
                [
                    bounds.getNorth(),
                    bounds.getEast(),
                ],
                [
                    bounds.getSouth(),
                    bounds.getEast(),
                ],
                [
                    bounds.getSouth(),
                    bounds.getWest(),
                ],
                [
                    bounds.getNorth(),
                    bounds.getWest(),
                ],
            ]);
            this.boundsLayer.addTo(this.props.map);
            this.boundsLayer.pm.enable({
                draggable: true,
                preventVertexEdit: true,
            });
        } else {
            this.props.map.removeLayer(this.boundsLayer);
        }
    }

    renderItem(item, index) {
        return (
            <div className="list-item" key={index} onClick={this.onItemClick.bind(this, item, index)}>
                {item.title}
            </div>
        );
    }

    async onClick() {
        const search = this.refs.input.getValue();
        const bounds = this.state.boundsActive ? this.boundsLayer.getBounds() : this.props.map.getBounds();

        let boundingBox = {
            top: bounds.getNorth(),
            left: bounds.getEast(),
            right: bounds.getWest(),
            bottom: bounds.getSouth(),
        };

        if (this.boundsLayer && this.boundsLayer.toGeoJSON) {
            const geojson = this.boundsLayer.toGeoJSON();
            if (geojson.geometry) {
                boundingBox.geometry = geojson.geometry;
            } else if (geojson.features && _.first(geojson.features) && _.first(geojson.features).geometry) {
                boundingBox.geometry = _.first(geojson.features).geometry;
            }
        }

        this.setState({
            hasResult: false,
        });

        await this.props.getMapSearch(search, boundingBox, 20, this.getSearchEntityList(), this.props.component);

        this.setState({
            hasResult: true,
        });

        this.expand();
    }

    getSearchEntityList() {
        return _.filter(_.map(_.keys(_.pickBy(this.props.activeLayers, selected => selected)), (item) => {
            switch (item) {
                case 'vehicles':
                    return 'vehicle,bnso';
                case 'stop_points_mta':
                    return 'stopPoint';
                case 'stop_points_children':
                    return null;
                case 'map_search':
                    return null;
                case 'garbage_objects_kp':
                    return null;
                case 'garbage_objects_op':
                    return null;
                case 'garbage_objects_opp':
                    return null;
                case 'garbage_objects_os':
                    return null;
                case 'road_parts':
                    return 'roadPart';
                case 'road_repair_parts':
                    return 'roadRepairPart';
                case 'roads':
                    return 'road';
            }
            return item;
        })).join(',');
    }

    onItemClick(object, index) {
        const geometry = {
            type: 'Feature',
            geometry: object.geometry,
        };
        let layer = new L.GeoJSON(geometry);
        this.props.onClick(layer.getBounds());

        this.props.selectSearchResult((_.isEqual(this.props.selectedSearchResult, object.geometry)) ? null : object.geometry);
        this.props.activateLayer('map_search');
    }

    expand() {
        this.setState({collapsed: false});
    }

    collapse() {
        this.setState({collapsed: true});
    }

    toggleClick() {
        this.state.collapsed ? this.expand() : this.collapse();
    }
}