import { api } from 'helpers/api';
import { success, error } from 'helpers/response';
import { setKursTasks, setKursTasksLoading } from './actions';
import { getWorkTypesFromKursTasks } from './utils';

export const getTasks = (meta = {}) => async (dispatch) => {
    dispatch(setKursTasksLoading(true));
	try {
		const response = await api.kurs.getTasks(meta);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getContractsTasks = (meta = {}) => async (dispatch, state) => {
    dispatch(setKursTasksLoading(true));
	try {
		const response = await api.kurs.getContractWorks(meta);
        dispatch(setKursTasks(getWorkTypesFromKursTasks(response.payload.items)));
        dispatch(setKursTasksLoading(false));

		return success(response);
	} catch (e) {
        dispatch(setKursTasksLoading(false));
		return error(e);
	}
};


export const getTask = (uuid) => async (dispatch) => {
	try {
		const response = await api.kurs.getTask(uuid);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const createTask = (data) => async (dispatch) => {
	try {
		const response = await api.kurs.createTask(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const createMultipleTask = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.createMultipleTask(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const importTask = (data) => async (dispatch) => {
	try {
		const response = await api.kurs.importTask(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const updateTask = (data) => async (dispatch) => {
	try {
		if (data.time_from && data.time_from.split(":").length === 3) {
			data.time_from = data.time_from.split(':').slice(0, 2).join(':')
		}
		if (data.time_to && data.time_to.split(":").length === 3) {
			data.time_to = data.time_to.split(':').slice(0, 2).join(':')
		}

		const response = await api.kurs.updateTask(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const updateTaskCheck = (data) => async (dispatch) => {
	try {
		const response = await api.kurs.updateTaskCheck(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const deleteTask = (data) => async (dispatch) => {
	try {
		const response = await api.kurs.deleteTask(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const repeatTask = (data) => async (dispatch) => {
	try {
		const response = await api.kurs.repeatTask(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getTaskViolations = (meta = {}) => async (dispatch) => {
	try {
		const response = await api.kurs.getTaskViolations(meta);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const unitTasksDoneChart = (meta = {}) => async (dispatch) => {
	try {
		const response = await api.kurs.unitTasksDoneChart(meta);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getGeometryFromContract = (meta = {}) => async (dispatch) => {
	try {
		const response = await api.kurs.getGeometryFromContract(meta);

		return success(response);
	}
	catch (e) {
		return error(e);
	}
};


export const dateTasksDoneChart = (meta = {}) => async (dispatch) => {
	try {
		const response = await api.kurs.dateTasksDoneChart(meta);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const unitCountVehiclesChart = (meta = {}) => async (dispatch) => {
	try {
		const response = await api.kurs.unitCountVehiclesChart(meta);

		return success(response);
	} catch (e) {
		return error(e);
	}
};
