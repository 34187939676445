import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import classNames from 'classnames';

import {getSchedule, createSchedule, updateSchedule} from "store/reducers/kiutr/schedules/schedules";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import TableContainer from "components/ui/Table/Container/TableContainer";
import moment from "moment";
import formats from "dictionaries/formats";
import {isCarriersLimits, isMunicipalType} from 'helpers/functions';

import "./editor.less";
import {getRoute} from "store/reducers/routes/route_editor";
import Settings from "../../../../../settings";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getSchedule, createSchedule, updateSchedule})

export default class Editor extends BaseEditor {

    title = 'расписания';
    modelClass = 'App\\Model\\Schedule';
    withFade = false;
    getModalClassName() {
        return `b-modal-route route-schedule-editor-modal ${this.props.single ? 'route-schedule-editor-modal-single' : ''}`;
    }

    async loadData(uuid) {
        return await this.props.getSchedule(uuid);
    }

    async createItem(data) {
        return await this.props.createSchedule(data);
    }

    async updateItem(data) {
        return await this.props.updateSchedule(data);
    }

    async componentDidMount() {
        if(this.props.isPageWithDetect && this.modalConfirmation) {
            document.addEventListener('click', this.handleClickOutside, true)
        }
        this.setState({
            uuid: this.props.uuid,
            item: null,
        });
        if (this.props.uuid) {
            this.setState({isLoading: true});
            const response = await this.loadData(this.props.uuid);
            if (response.isOk) {
                this.setState({
                    item: response.payload,
                    isLoading: false,
                });
            } else {
                response.showErrors();
            }
        }

        if(this.props.isPageWithDetect && this.modalConfirmation) {
            document.addEventListener('click', this.handleClickOutside, true)
        }
    }

    componentWillUnmount() {
        if(this.props.isPageWithDetect && this.modalConfirmation) {
            document.removeEventListener('click', this.handleClickOutside)
        }
    }

    getForm(item, onSubmit) {
        if (_.isEmpty(item)) {
            item = {
                name: 'Основное расписание',
                is_null_run_separated: true,
                date_from: moment().startOf('month').format(formats.DATE_API),
                date_to: moment().endOf('month').format(formats.DATE_API),
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
                holiday: true,
            };
        }

        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                turnedEditingMode={this.turnedEditingMode}
            />
        );
    }

    composeItem(data) {
        let item = _.clone(data);

        item.route_uuid = this.props.routeUuid;

        return item;
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    turnedEditingMode: PropTypes.func
})

@connect((state) => ({}), {getDictionaryList, getRoute}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        schedule: {},
        schedule_statuses: [],
        route: {},
        route_kinds: [],
    };

    async loadRoute(uuid) {
        const response = await this.props.getRoute(uuid);

        if (response.isOk) {
            this.setState({
                route: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    getData() {
        return this.state.schedule;
    }

    async componentDidMount() {

        await this.setState({
            schedule: this.props.data,
        });

        if (isCarriersLimits()) {
            await this.loadRoute(this.state.schedule.route_uuid || window.location.pathname.split('/')[3])
        }
        console.log(this.state)
        await this.loadDictionaries([
            'schedule_statuses',
            'seasons',
            'route_kinds'
        ]).then(() => {
            if (!this.get('schedule_status_uuid')) {
                this.setValue('schedule.schedule_status_uuid', _.get(_.find(this.state.schedule_statuses, {label: 'Утверждено'}), 'value'));
            }
            if (isCarriersLimits() && isMunicipalType(this.state.route_kinds, this.state.route.route_kind_uuid) && this.props.mode === 'add') {
                this.setValue('schedule.schedule_status_uuid', _.get(_.find(this.state.schedule_statuses, {label: 'Проект'}), 'value'));
            }

            if (isCarriersLimits() && isMunicipalType(this.state.route_kinds, this.state.route.route_kind_uuid) && this.props.mode === 'edit') {
                $("._save").hide()
                $(".top-menu_modal__separator").hide()
            }

            if (isCarriersLimits() && isMunicipalType(this.state.route_kinds, this.state.route.route_kind_uuid) && this.props.mode === 'edit' && this.get('schedule_status_uuid') === _.get(_.find(this.state.schedule_statuses, {label: 'Проект'}), 'value')) { // если бесплатный перевозчик и редактирование расписание со статусом Проект
                $("._save").show()
                $(".top-menu_modal__separator").show()
            }
        });


    }

    onChangeInput(field, {target: {value, type}}) {
        super.onChangeInput(field, {target: {value, type}});
        this.props.turnedEditingMode();
    }

    get(path, defaultValue = null) {
        return _.get(this.state.schedule, path, defaultValue);
    }

    handleIntervalLink() {
        this.props.router.push(`${this.props.router.getCurrentLocation().pathname}/interval/${this.state.schedule.uuid}`);
    }

    logicViewScheduleIntervals() {
        const correctUrl = location.pathname.includes('kiutr/routes');
        if (window.RNIS_SETTINGS.shedule_modalFooter && correctUrl) {
            return <div className="b-modal__footer">
                <a href="javascript:void(0)" onClick={::this.handleIntervalLink} className="b-button b-button_red b-button_size_md b-button_routes">Интервалы движения</a>
                <div className="b-modal__checkbox">
                    {this.checkbox('schedule.is_schedule_interval', 'Контроль интервалов движения', { canChange: this.state.schedule.interval_uuid ? true : false} )}
                </div>
            </div>
        }
        return null;
    }

    render() {
        return (
            <div className="Shedule__editorForm">
                {this.logicViewScheduleIntervals()}
                <Accordion>
                    <AccordionItem opened={true} single={true} title="Основная информация">
                        <Block size="xl" title="Наименование">
                            {this.textInput('schedule.name')}
                        </Block>
                        <Block size="xl">
                            {this.checkbox('schedule.is_null_run_separated', 'Нулевые рейсы отображаются отдельным столбцом')}
                        </Block>
                        <Block title="Дата начала">
                            {this.datepicker('schedule.date_from')}
                        </Block>
                        <Block title="Дата конца">
                            {this.datepicker('schedule.date_to')}
                        </Block>
                        <Block title="Сезоны">
                            {this.select('schedule.seasons', this.state.seasons, {
                                multi: true,
                            })}
                        </Block>
                        <Block title="Статус">
                            {this.select('schedule.schedule_status_uuid', this.state.schedule_statuses ,{
                                disabled: isCarriersLimits() && isMunicipalType(this.state.route_kinds, this.state.route.route_kind_uuid)
                            })}
                        </Block>
                        <Block size="xl">
                            <TableContainer>
                                <div className="Table">
                                    <table className="b-table">
                                        <thead>
                                        <tr className="b-table__header">
                                            <th className="b-table__mon align-center">Пн</th>
                                            <th className="b-table__tue align-center">Вт</th>
                                            <th className="b-table__wed align-center">Ср</th>
                                            <th className="b-table__thu align-center">Чт</th>
                                            <th className="b-table__fri align-center">Пт</th>
                                            <th className="b-table__sat align-center">Сб</th>
                                            <th className="b-table__sun align-center">Вс</th>
                                            <th className="b-table__holidays align-center">Пр. дни</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className="align-center select-checkbox">
                                                {this.checkbox('schedule.monday')}
                                            </td>
                                            <td className="align-center select-checkbox">
                                                {this.checkbox('schedule.tuesday')}
                                            </td>
                                            <td className="align-center select-checkbox">
                                                {this.checkbox('schedule.wednesday')}
                                            </td>
                                            <td className="align-center select-checkbox">
                                                {this.checkbox('schedule.thursday')}
                                            </td>
                                            <td className="align-center select-checkbox">
                                                {this.checkbox('schedule.friday')}
                                            </td>
                                            <td className="align-center select-checkbox">
                                                {this.checkbox('schedule.saturday')}
                                            </td>
                                            <td className="align-center select-checkbox">
                                                {this.checkbox('schedule.sunday')}
                                            </td>
                                            <td className="align-center select-checkbox">
                                                {this.checkbox('schedule.holiday')}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </TableContainer>
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}
