import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const GET_EDUCATION = 'GET_EDUCATION';

const initialState = new Map({
    education: Map({})
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_EDUCATION:
            return state
                .set('education', action.payload);
    }

    return state;
}

export const getUserEducation = (user_uuid, uuid) => async (dispatch) => {
    try {
        let response = await api.auth.getUserEducations(user_uuid, {
            filters: {
                withUuid: uuid
            }
        });

        response = response.payload.items[0];

        dispatch({
            type: GET_EDUCATION,
            payload: response
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createUserEducation = (userEducation) => async (dispatch) => {
    try {
        let response = await api.auth.createUserEducation(userEducation);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateUserEducation = (userEducation) => async (dispatch) => {
    try {
        let response = await api.auth.updateUserEducation(userEducation);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteUserEducation = (userEducation) => async (dispatch) => {
    try {
        let response = await api.auth.deleteUserEducation(userEducation);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};