import React, {Component} from "react";
import {connect} from "react-redux";

import Page from "components/ui/page";
import Button from "components/ui/button";
import BaseEditorFormComponent from "components/base/base-editor-form";

import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";

import currentUser from 'helpers/current-user';

import "./import.less";
import GlobalLoaderComponent from "components/ui/global-loader";
import {
    importKursVehicles, importKursVehiclesGet, importVehicles,
    importVehiclesGet
} from "store/reducers/vehicles/vehicles";
import {getUnits} from "store/reducers/organizational_units/units";
import _ from 'lodash';
import {api, CycleFetch} from "helpers/api";
import FileReaderInput from 'react-file-reader-input';
import {State} from "components/ui/state";
import classNames from 'classnames';
import Tabs from "components/ui/tabs/tabs";
import TabItem from "components/ui/tabs/tab-item";
import {importTask} from "store/reducers/kurs/tasks";
import * as alerts from "helpers/alerts";
import systems from "../../../../dictionaries/systems";

@connect(state => ({}), {
    importVehicles,
    importVehiclesGet,
    importKursVehicles,
    importKursVehiclesGet,
    importTask,
})
export default class VehiclesImportComponent extends Component {

    state = {
        saving: false,
        data: {
            file: null,
            unit_uuid: null,
            component: null,
        },
        importUuid: null,
        import: null,
        errors: {},
    };

    _cycleFetch = null;

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    async onSubmit(type, data) {
        let item = _.clone(data.vehicle_import);
        delete item.units;

        switch (type) {
            case 'vehicles':
            case 'kurs':
                this.submitVehiclesImport(type, item);
                break;
            case 'task':
                this.submitTaskImport(item);
                break;
        }
    }

    async submitVehiclesImport(type, item) {
        this.startSave();
        const response = (type === 'vehicles') ? await this.props.importVehicles(item) : await this.props.importKursVehicles(item);
        this.endSave();
        if (response.isOk) {
            await this.setState({
                importUuid: response.payload.uuid,
            });
            this._cycleFetch = new CycleFetch(
                () => {
                    const promise = (type === 'vehicles') ? this.props.importVehiclesGet(this.state.importUuid) : this.props.importKursVehiclesGet(this.state.importUuid);

                    promise.then((response) => {
                        if (response.isOk) {
                            if (response.payload.is_done) {
                                this._cycleFetch.stop();
                            }
                            this.setState({
                                import: response.payload,
                            });
                        }
                    });

                    return promise;
                },
                () => {
                }, 1000);

            this._cycleFetch.run();
        } else {
            this.setState({
                errors: response.validationErrors,
            });
            response.showErrors();
        }
    }

    async submitTaskImport(item) {
        this.startSave();
        const response = await this.props.importTask(item);
        this.endSave();
        if (response.isOk) {
            this.props.router.push(`/road/tasks/${response.payload.uuid}`);
        } else {
            if (_.filter(response.errors, (error) => {
                    return error.code === '17-0-0-25';
                }).length > 0) {
                alerts.prompt('Задание уже импортировано. Переимпортировать?', '', async () => {
                    this.startSave();
                    const response = await this.props.importTask({
                        external_id: item.external_id,
                        force: true,
                    });
                    this.endSave();
                    if (response.isOk) {
                        this.props.router.push(`/road/tasks/${response.payload.uuid}`);
                    } else {
                        response.showErrors();
                    }
                }, 'Импортировать');
            } else {
                response.showErrors();
            }
        }
    }

    onClose() {

    }

    closeResults() {
        this.setState({
            importUuid: null,
            import: null,
        });
    }

    render() {
        const loader = (this.state.saving) ? (<GlobalLoaderComponent/>) : null;
        let form = this.state.importUuid ? (
            <ImportResultComponent
                import={this.state.import}
                closeResults={::this.closeResults}
            />
        ) : (
            <ImportEditorComponent
                data={this.state.data}
                errors={this.state.errors}
                onSubmit={::this.onSubmit}
                onClose={::this.onClose}
            />
        );

        return (
            <Page pageId="Main" title="Импорт ТС">
                {loader}

                {form}
            </Page>
        )
    }
}

@connect(state => ({}), {getUnits})
class ImportEditorComponent extends BaseEditorFormComponent {

    async componentDidMount() {
        await this.setState({
            vehicle_import: this.props.data,
        });
        this.loadUnits();
    }

    getData() {
        return this.state.vehicle_import;
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 10000,
            },
            response_data: [
                'items/uuid',
                'items/name',
                'items/component',
            ],
        });
        if (response.isOk) {
            this.setState({
                units: _.sortBy(_.map(response.payload.items, (unit) => ({
                    label: unit.name,
                    value: unit.uuid,
                    component: unit.component,
                })), 'label'),
            })
        } else {
            response.showErrors();
        }
    }

    filterSystems(systems) {
        if (!window.RNIS_SETTINGS.SUBSYSTEMMENU || !window.RNIS_SETTINGS.SUBSYSTEMMENU.length) return systems;
        let newSystems =  {};
        window.RNIS_SETTINGS.SUBSYSTEMMENU.forEach(el => {
            if (!el.hideForImport) {
                newSystems[el.id] = el.name;
            }
        });
        return newSystems;
    }

    isMosreg() {
        return (/\.mosreg.ru$/.test(window.location.hostname));
    }

    isNso() {
        return (/\.nso.ru$/.test(window.location.hostname));
    }

    isLocalhost() {
        return (/localhost$/.test(window.location.hostname));
    }

    isNO() {
        return (/\.rnc52.ru$/.test(window.location.hostname));
    }

    isTO() {
        return (/\.orgpn.ru$/.test(window.location.hostname));
    }

    getImportFile() {
        let city = '';
        if(this.isMosreg()) {
            city = '_MO';
        } else if(this.isNO()) {
            city = '_NO';
        } else if(this.isNso()) {
            city = '_NSO';
        } else if(this.isTO()) {
            city = '_TO';
        }
        return <a href={`/files/vehicle_import${city}.xlsx`}>Образец файла для импорта</a>
    }

    render() {
        const error = this.getError('vehicle_import.file');
        const styleClassName = classNames(error ? 'input__style_state_wrong' : '');

        let systems = {
            kiutr: 'Управление пассажирскими перевозками',
            road: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text,
            utility: 'Жилищно-коммунальное хозяйство',
            communal: 'Коммунальная техника',
            children: 'Управление перевозками детей',
            garbage: 'Контроль вывоза мусора',
            control: 'ГУ ГАТН',
        };

        systems = this.filterSystems(systems);


        return (
            <div className="VehiclesImportForm">
                <Tabs>
                    <TabItem title="Импорт ТС">
                        <Accordion>
                            <AccordionItem single={true} opened={true}>
                                <Block size="xl" title="Файл">
                                    {this.getValue('vehicle_import.file') ? ([
                                        <a key="open" href={this.getValue('vehicle_import.file')}
                                           target="_blank">{this.getValue('vehicle_import.filename')}</a>,
                                        <span key="span"> (<a href="#" onClick={::this.deleteFile}>удалить</a>)</span>,
                                        <br key="separator"/>,
                                    ]) : (
                                        <FileReaderInput as="binary" id="my-file-input" onChange={::this.uploadFile}>
                                            <a href="javascript:void(0)">Загрузить файл</a> (формат - xlsx)
                                        </FileReaderInput>
                                    )}
                                    <div className={styleClassName}>
                                        {error ?
                                            <span
                                                className="input__style_state_msg_wrong no-position">{error}</span> : null}
                                    </div>
                                    <div>
                                        <br/>
                                        {this.getImportFile()}
                                    </div>
                                </Block>
                                <Block size="xl" title="Подсистема">
                                    {this.select('vehicle_import.component', _.map(systems, (label, value) => ({
                                        label,
                                        value,
                                    })))}
                                </Block>
                                <Block size="xl" title="Предприятие">
                                    {this.select('vehicle_import.unit_uuid', _.filter(this.state.units, {component: _.get(this.state.vehicle_import, 'component')}))}
                                </Block>

                                <Block size="xl">
                                    <Button size="md" text="Импортировать" color="red" shadow="red"
                                            onClick={this.submit.bind(this, 'vehicles')}/>
                                </Block>
                            </AccordionItem>
                        </Accordion>
                    </TabItem>
                    { (window.RNIS_SETTINGS.HIDEIMPORTMOSAVTODORTAB === false || window.RNIS_SETTINGS.HIDEIMPORTMOSAVTODORTAB === undefined || window.RNIS_SETTINGS.HIDEIMPORTMOSAVTODORTAB === "undefined") ?
                        (  <TabItem title={window.RNIS_SETTINGS.import_ts_tab_name ? window.RNIS_SETTINGS.import_ts_tab_name : 'Импорт Мосавтодор'}>
                        <Accordion>
                            <AccordionItem single={true} opened={true}>
                                <Block size="xl" title="Файл">
                                    {this.getValue('vehicle_import.file') ? ([
                                        <a key="open" href={this.getValue('vehicle_import.file')}
                                           target="_blank">{this.getValue('vehicle_import.filename')}</a>,
                                        <span key="span"> (<a href="#" onClick={::this.deleteFile}>удалить</a>)</span>,
                                        <br key="separator"/>,
                                    ]) : (
                                        <FileReaderInput as="binary" id="my-file-input" onChange={::this.uploadFile}>
                                            <a href="javascript:void(0)">Загрузить файл</a> (формат - xlsx)
                                        </FileReaderInput>
                                    )}
                                    <div className={styleClassName}>
                                        {error ?
                                            <span
                                                className="input__style_state_msg_wrong no-position">{error}</span> : null}
                                    </div>
                                    <div>
                                        <br/>
                                        <a href="/files/kurs_vehicle_import.xlsx">Образец файла для импорта</a>
                                    </div>
                                </Block>
                                <Block size="xl" title="Подсистема">
                                    {_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text}
                                </Block>
                                <Block size="xl" title="Предприятие">
                                    {this.select('vehicle_import.unit_uuid', _.filter(this.state.units, {component: 'road'}))}
                                </Block>

                                <Block size="xl">
                                    <Button size="md" text="Импортировать" color="red" shadow="red"
                                            onClick={this.submit.bind(this, 'kurs')}/>
                                </Block>
                            </AccordionItem>
                        </Accordion>
                    </TabItem>) : null}

                    { (window.RNIS_SETTINGS.HIDEIMPORTSKPDITAB === false || window.RNIS_SETTINGS.HIDEIMPORTSKPDITAB === undefined || window.RNIS_SETTINGS.HIDEIMPORTSKPDITAB === "undefined") ?
                    <TabItem title="Импорт заданий СКПДИ">
                        <Accordion>
                            <AccordionItem single={true} opened={true}>
                                <Block size="xl" title="Номер задания">
                                    {this.textInput('vehicle_import.external_id')}
                                </Block>

                                <Block size="xl">
                                    <Button size="md" text="Импортировать" color="red" shadow="red"
                                            onClick={this.submit.bind(this, 'task')}/>
                                </Block>
                            </AccordionItem>
                        </Accordion>
                    </TabItem> : null }
                </Tabs>
            </div>
        )
    }

    async uploadFile(e, results) {
        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        results.forEach(result => {
            const [e, file] = result;
            //formData.append('file', e.target.result);
            formData.append('file', file);
        });

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');

        this.onChangeInput('vehicle_import.file', {target: {value}});
        this.onChangeInput('vehicle_import.filename', {target: {value: response.original_filename}});
    }

    deleteFile(e) {
        e.preventDefault();

        this.onChangeInput('vehicle_import.file', {target: {value: null}});
        this.onChangeInput('vehicle_import.filename', {target: {value: null}});
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }

    submit(type) {
        this.props.onSubmit(type, this.getState());
    }
}

class ImportResultComponent extends BaseEditorFormComponent {

    render() {
        const queuePosition = _.get(this.props.import, 'queue_position');

        return (
            <div className="VehiclesImportForm">
                <Accordion>
                    <AccordionItem single={true} opened={true}>
                        {queuePosition ? (
                            <Block size="xl" title="Позиция в очереди">
                                {queuePosition}
                            </Block>
                        ) : null}
                        <Block size="xl" title="Лог импорта">
                            <ul>
                                {_.get(this.props.import, 'log', []).map((log, index) => {
                                    return (
                                        <li key={index}>{log}</li>
                                    );
                                })}
                            </ul>
                        </Block>
                        <Block size="xl" title="Завершено">
                            <State positive={_.get(this.props.import, 'is_done')}/>
                        </Block>
                        <Block size="xl">
                            <Button text="Закрыть результаты импорта" width="auto" size="md"
                                    shadow="red"
                                    onClick={this.props.closeResults}/>
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        )
    }
}
