import React, {Component} from "react";
import {propTypes} from "react-props-decorators";
import PropTypes from "prop-types";
import JsSIP from "jssip";
import SIP from "sip.js";
import Button from "components/ui/button";
import * as storage from "utils/storage";
import {api} from "helpers/api";
import {User} from "helpers/user";

@propTypes({
    phone: PropTypes.any.isRequired
})

export default class SIPComponent extends Component {

    /**
     * @type {CallSounds}
     */
    audio = null;

    state = {
        session: null,
        calling: false,
        sip: null,
        phone: 1520,
        connected: false
    }

    async componentWillMount() {
        this.audio = new CallSounds();

        const user = storage.get('user');

        const response = await api.auth.getUser(user.uuid);

        if (response.success) {
            const user = new User(response.payload);

            if (user.hasSipExt) {
                this.setState({
                    sip: this.initJsSIP(user.SIPData)
                });
            }
        } else {
            console.error('User not loaded')
        }
    }

    urlDomain(url) {
        let a = document.createElement('a');
        a.href = url;
        return a.hostname;
    }

    initJsSIP(number) {
        return;
        let sip = new SIP.UA({
            log: {level: 1},
            wsServers: [window.RNIS_SETTINGS.RNIS_SIP_URL],
            rtcpMuxPolicy: "negotiate",
            uri: `sip:${number.ext}@${this.urlDomain(window.RNIS_SETTINGS.RNIS_SIP_URL)}`,
            password: number.password
        });

        sip.on('connected', (e) => {
            this.setState({
                connected: true
            });
            console.log(`SIP connected to the server ${window.RNIS_SETTINGS.RNIS_SIP_URL}`);
        });

        sip.on('registered', (e) => {
            this.setState({
                connected: true
            });
            console.log(`SIP registered with number`, number);
        });

        sip.on('unregistered', (e) => {
            console.log(`SIP unregistered`);
        });

        sip.on('disconnected', (e) => {
            console.warn('SIP disconnected');
        });

        sip.start();

        return sip;
    }

    callJsSIPTerminate() {
        if (this.state.session) {
            this.setState({
                session: null
            });

            this.state.session.terminate();
        }
    }

    callJsSIPPhone(phone) {
        console.log(`Call to number [${phone}]`);
        let session = this.state.sip.invite(`sip:${phone}`, {
            media: {
                constraints: {
                    audio: true,
                    video: false
                },
                render: {
                    remote: document.getElementById('audio')
                }
            }
        });

        this.setState({session});

        session.on('accepted', (e) => {
            console.log('Call accepted');
            this.audio.stop('ringback');
            this.audio.play('answered');
        });

        session.on('progress', (e) => {
            console.log('Call is in progress');

            this.setState({calling: true});

            this.audio.play('ringback', true);
        });

        session.on('failed', (e) => {
            this.setState({
                calling: false,
                session: null
            });

            console.warn('Call failed with cause', e);

            this.audio.stop('ringback');
            this.audio.play('rejected');
        });

        session.on('rejected', (e) => {
            this.setState({
                calling: false,
                session: null
            });
            console.warn('Call rejected with cause', e);

            this.audio.stop('ringback');
            this.audio.play('rejected');
        });

        session.on('cancel', (e) => {
            this.setState({
                calling: false,
                session: null
            });
            console.warn('Call canceled with cause', e);

            this.audio.stop('ringback');
            this.audio.play('rejected');
        });

        session.on('bye', (e) => {
            this.setState({
                calling: false,
                session: null
            });

            console.warn('Call send bye', e);
        });
    }

    call() {
        if(!this.state.phone) {
            console.error('Phone number is not set');
        } else {
            this.callJsSIPPhone(this.state.phone)
        }
    }

    updatePhone() {
        this.setState({
            phone: this.getPhone()
        });
    }

    getPhone() {
        return this.refs.phone.value;
    }

    render() {
        if (this.state.connected) {
            const terminateButton = this.state.session && (<Button
                    size="sm"
                    color="red"
                    shadow="red"
                    text="Положить трубку"
                    onClick={(e) => { this.callJsSIPTerminate() }}
                />);

            const callButton = !this.state.session && !this.state.calling && this.state.phone && (<Button
                    size="sm"
                    color="green"
                    text="Позвонить"
                    onClick={(e) => { this.call() }}
                />);

            return (
                <div className="sip-block">
                    <audio id="audio"/>
                    <input value={this.state.phone} onChange={::this.updatePhone} ref="phone" />
                    {callButton} {terminateButton}
                </div>
            )
        }

        return (
            <Button
                size="sm"
                color="white"
                shadow="gray"
                text="Позвонить"
                disabled={true}
            />
        );

    }
}

const FILES = require('../../../static/sounds/sounds.json');
const SOUNDS = new Map([
    ['ringback', {audio: new Audio(FILES['ringback']), volume: 1.0}],
    ['ringing', {audio: new Audio(FILES['ringing']), volume: 1.0}],
    ['answered', {audio: new Audio(FILES['answered']), volume: 1.0}],
    ['rejected', {audio: new Audio(FILES['rejected']), volume: 0.5}]
]);

let initialized = false;

class CallSounds {

    constructor() {
        if (!initialized) {
            for (let sound of SOUNDS.values()) {
                sound.audio.volume = 0;
                try {
                    sound.audio.play();
                } catch (error) {
                }
            }

            initialized = true;
        }
    }


    stop(name) {
        let sound = SOUNDS.get(name);

        if (!sound)
            throw new Error(`unknown sound name "${name}"`);

        sound.audio.pause();
        sound.audio.currentTime = 0.0;
    }

    play(name, loop) {
        let sound = SOUNDS.get(name);

        if (!sound)
            throw new Error(`unknown sound name "${name}"`);

        try {
            sound.audio.pause();
            sound.audio.currentTime = 0.0;
            sound.audio.volume = 1.0;
            sound.audio.loop = loop || false;
            sound.audio.play();
        }
        catch (error) {
            console.warn('play() | error: %o', error);
        }
    }

}