import React from 'react';
import {List} from 'immutable';
import {connect} from 'react-redux';
import _ from 'lodash';

import BaseTreeTableComponent from 'components/base/base_tree_table';
import Page from 'components/ui/page';
import IconButton from 'components/ui/icon-button';
import TerritorialEntitiesEditor from './TerritorialEntitiesEditor';

import {mapDatatablesRequestToMeta} from 'helpers/api';

import * as alerts from "helpers/alerts";
import TableContainer from "components/ui/Table/Container/TableContainer";
import Column from "components/ui/column";
import AppearTransition from "components/ui/transitions/appear";
import {EntityList} from "helpers/entity";
import currentUser from 'helpers/current-user';
import ContextTooltip from "components/ui/context-tooltip";
import systems from "dictionaries/systems";
import {Link} from "react-router";
import HotKeysManager from "components/ui/HotKeys/Manager/HotKeysManager";
import {deleteTerritorialEntity, getTerritorialEntities} from "store/reducers/geo/territorial-entities";
import {preventDefaultEventHandlers} from "helpers/hot_key_handlers";

@connect(state => ({}), {getTerritorialEntities, deleteTerritorialEntity})

export default class TerritorialEntitiesTree extends BaseTreeTableComponent {

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            component: null,
            columns: List(this.getColumns()),
            related: new EntityList(),
        });
    }

    getBaseUrl() {
        return `/${this.state.component}/territorial_entities/tree`;
    }

    getTitle() {
        let title = 'Территориальные образования';
        return <span>{systems[this.state.component]} → <Link
            to={`/${this.state.component}/dictionaries`}>Справочники</Link> → {title}</span>;
    }

    async componentWillUpdate(props, state) {
        if (props.params.component !== state.component) {
            await this.setState({
                component: props.params.component,
            });
            this.reload();
        }

        const uuid = props.params.uuid;
        const newEditorUuid = (uuid && uuid !== 'create') ? uuid : null;
        if (this.state.editorUuid !== newEditorUuid || this.state.showEditorModal !== !!uuid) {
            this.setState({
                showEditorModal: !!uuid,
                editorUuid: newEditorUuid,
                editorModalMode: newEditorUuid ? 'edit' : 'add',
            });
        }
    }

    componentDidMount() {
        this.forceUpdate();
    }

    getHotKeyHandlers() {
        return preventDefaultEventHandlers(Object.assign(super.getHotKeyHandlers(), {
            delete: ::this.deleteSelected,
            table: ::this.goToTableView,
        }));
    }

    render() {
        let table = (!this.state.queryBounced) ? this.renderTable() : this.renderNonTreeTable();

        const editor = this.state.showEditorModal
            ? (
                <TerritorialEntitiesEditor
                    {...this.props}
                    key="editor"
                    onClose={::this.closeEditor}
                    onSubmit={::this.submitEditor}
                    onEditClick={::this.onEditorEditClick}
                    onDeleteClick={::this.onEditorDeleteClick}
                    mode={this.state.editorModalMode}
                    uuid={this.state.editorUuid}
                />
            ) : '';

        return (
            <HotKeysManager {...this.getHotKeyHandlers()}>
                <Page title={this.getTitle()}
                      pageId="TerritorialEntities"
                      headerActions={this.renderHeaderActions()}
                      headerContents={this.renderHeaderContents()}>

                    <TableContainer>
                        {table}
                    </TableContainer>

                    <AppearTransition>
                        {editor}
                    </AppearTransition>
                </Page>
            </HotKeysManager>
        );
    }

    getColumns() {
        return this.prepareColumns([
            new Column('Наименование')
                .fromField('name'),

            new Column('Краткое наименование')
                .fromField('short_name'),

            new Column('Маска ОКТМО')
                .fromField('oktmo_mask'),
        ]);
    }

    async closeEditor() {
        await this.props.router.push(this.getBaseUrl());
    }

    async submitEditor() {
        this.reload();
        await this.closeEditor();
    }

    async showEditor() {
        this.showEditorWithUuid();
    }

    async onEditorEditClick() {
        const uuid = this.state.editorUuid;
        await this.closeEditor();
        return this.showEditorWithUuid(uuid);
    }

    async onEditorDeleteClick() {
        return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            await this.props.deleteTerritorialEntity({
                uuid: this.state.editorUuid,
            });

            await this.closeEditor();
            this.reload();
        });
    }

    async showEditorWithUuid(uuid = null) {
        await this.closeEditor();
        this.props.router.push(`${this.getBaseUrl()}/${uuid || 'create'}`);
    }

    onDblClick(data) {
        if (data.uuid && currentUser.can('com.rnis.geo.territorial_entity', 'update')) {
            this.showEditorWithUuid(data.uuid);
        }
    }

    async editSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            this.showEditorWithUuid(selected.uuid);
        }
    }

    async deleteSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
                await this.props.deleteTerritorialEntity({
                    uuid: selected.uuid,
                });
                this.reload();
            });
        }
    }

    goToTableView() {
        this.props.router.push(`/${this.state.component}/territorial_entities/table`);
    }

    async loadSuboardinationData(data, callback) {
        const meta = {
            filters: {
                appendChildrenAttribute: true,
                withParentTerritorialEntity: data.uuid,
                withComponent: this.state.component,
            },
        };

        const response = await this.props.getTerritorialEntities(meta, false);
        //console.log(response);

        if (response.isOk) {
            const data = response.payload.items;

            callback(data);
        } else {
            response.showErrors();
            callback([]);
        }
    }

    async loadCallback(request, drawCallback) {
        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        meta.filters.appendChildrenAttribute = true;
        meta.filters.withParentTerritorialEntity = null;
        meta.filters.withComponent = this.state.component;
        const response = await this.props.getTerritorialEntities(meta, false);

        if (response.isOk) {

            const data = response.payload.items;
            //console.log(data);

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: response.data.headers.meta.pagination.total,
                recordsTotal: response.data.headers.meta.pagination.total
            };


            drawCallback(json);

            this.selectedRowsRecalc();
            this.setState({success: true});
        } else {
            response.showErrors();
        }
    }

    getCreateButton() {
        if (!currentUser.can('com.rnis.geo.territorial_entity', 'create')) {
            return null;
        }
        return (
            <ContextTooltip key="base-table-list.create" code="base-table-list.create">
                <IconButton
                    icon="plus"
                    tooltip="Добавить"
                    onClick={::this.showEditor}
                    active={this.state.showEditorModal && (this.state.editorUuid === 'create')}
                    className="popup-link"
                />
            </ContextTooltip>
        );
    }

    renderHeaderActions() {
        return [
            currentUser.can('com.rnis.geo.territorial_entity', 'delete') ? (
                <ContextTooltip key="base-table-list.delete" code="base-table-list.delete">
                    <IconButton icon="basket" disabled={this.state.selectedRowsCount === 0}
                                onDisabledClick={::this.alertToSelect}
                                onClick={::this.deleteSelected}/>
                </ContextTooltip>
            ) : null,
            <ContextTooltip key="units.table" code="units.table" default="Табличный вид">
                <IconButton
                    icon="thumbnails"
                    onClick={::this.goToTableView}
                />
            </ContextTooltip>
        ];
    }
}