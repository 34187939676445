import moment from 'moment';
import formats from 'dictionaries/formats';

export const handleInvalidEndedAtDate = (violation) => {
    const normalizedItems = violation.items.map(item => {
        return formattingItem(item)
    });
    violation.items = normalizedItems;
    return violation;
}

export const formattingItem = (item) => {
    if (item.ended_at === "Invalid date") {
        return {...item, ended_at: moment(item.started_at).add(3, 'minutes').format(formats.DATETIME_API), is_formated: true}
    } else {
        return item
    }
}