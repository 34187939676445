import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import classNames from 'classnames';

import IconButton from './icon-button';
import debounce from 'throttle-debounce/debounce';

import './search-field.less';
import ContextTooltip from "components/ui/context-tooltip";


@propTypes({
    value: PropTypes.string,
    onChange: PropTypes.func,
    onShow: PropTypes.func,
    onHide: PropTypes.func
})

@defaultProps({
    onChange: () => {
    },
    onShow: () => {
    },
    onHide: () => {
    }
})

export default class SearchField extends Component {

    state = {
        showInput: false,
        value: '',
    };

    render() {
        const className = classNames('f-search f-search-posit', this.state.showInput ? 'visible' : '');

        return (
            <div>
                <IconButton icon="search" onClick={::this.onClickIcon}/>
                <div className={className}>
                    <form action="#">
                        <input className="f-search__pole" type="text" placeholder="поиск"
                               onChange={::this.submitChanges}
                               onKeyPress={::this.onKeyPress}
                               value={this.state.value} ref="input"/>
                        <input className="f-search__button" type="button" onClick={::this.onClickIcon}/>
                    </form>
                </div>
            </div>
        );
    }

    async open() {
        await this.setState({showInput: true});
        this.focus();
    }

    focus() {
        this.refs.input.focus();
    }

    getValue() {
        return this.refs.input.value;
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    submitChanges() {
        this.setState({
            value: this.getValue(),
        });
        this.props.onChange(this.getValue());
    }

    onClickIcon() {
        if (this.state.showInput) {
            this.setState({showInput: false});
            this.props.onHide();
        } else {
            this.setState({showInput: true});
            this.props.onShow();

            setTimeout(() => {
                this.refs.input.focus();
            }, 100)
        }
    }

    isExpanded() {
        return this.state.showInput;
    }

}