import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import {
    createContract, getContract, getContractPlanSummary,
    updateContract
} from "store/reducers/kiutr/contracts/contracts";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import currentUser from 'helpers/current-user';
import PageModal from 'components/ui/page-modal';
import GlobalLoaderComponent from "components/ui/global-loader";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import KiutrContractRouteRegistriesComponent from "components/modules/kiutr/contracts/route_registries/route_registries";
import classNames from 'classnames';
import TableContainer from "components/ui/Table/Container/TableContainer";
import {resizeModals} from 'helpers/modal-size';
import {events} from 'dom-helpers';
import ContextTooltip from "components/ui/context-tooltip";
import moment from "moment";
import formats from "dictionaries/formats";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import IconButton from "components/ui/icon-button";
import Popup from "components/ui/popup";
import debounce from 'throttle-debounce/debounce';
import Button from "components/ui/button";
import contract_types from "dictionaries/contract_types";
import {component_mapper} from "helpers/component_mapper";
import {isRouteA} from "helpers/route";

let resizeId;

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getContract, createContract, updateContract})

export default class Editor extends BaseEditor {

    title = 'контракта';
    modelClass = 'App\\Model\\Contract';

    static childContextTypes = {
        resizeModals: PropTypes.func
    };

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            showRouteRegistries: false,
        });

        this.onResize = this.onResize.bind(this);
    }
    

    getChildContext() {
        return {resizeModals: this.resizeModals};
    }

    componentWillMount() {
        events.on(window, 'resize', this.onResize);
    }

    async componentWillReceiveProps(props) {
        await super.componentWillReceiveProps(props);

        this.setState({showRouteRegistries: isRouteA(props, 'kiutr-contract-routes')});

        setTimeout(() => {
            this.resizeModals();
        }, 500);
    }

    componentDidMount() {
        if(this.props.isPageWithDetect && this.modalConfirmation) {
            document.addEventListener('click', this.handleClickOutside, true)
        }
     }

    componentWillUnmount() {
        events.off(window, 'resize', this.onResize);

        if(this.props.isPageWithDetect && this.modalConfirmation) {
            document.removeEventListener('click', this.handleClickOutside)
        }
    }

    onResize() {
        clearTimeout(resizeId);
        resizeId = setTimeout(this.resizeModals, 1000);
    }

    resizeModals() {
        const ignoreClasses = ['route-registry-editor'];
        resizeModals('.b-modal', 'b-modal__collapse', 'move-right', ignoreClasses);
    }

    async loadData(uuid) {
        return await this.props.getContract(uuid);
    }

    async createItem(data) {
        return await this.props.createContract(data);
    }

    async updateItem(data) {
        return await this.props.updateContract(data);
    }

    getForm(item, onSubmit) {
        if (_.isEmpty(item)) {
            item = {
                date: moment().startOf('month').format(formats.DATE_API),
                date_from: moment().startOf('month').format(formats.DATE_API),
                date_to: moment().endOf('month').format(formats.DATE_API),
                work_from: moment().startOf('month').format(formats.DATE_API),
                work_to: moment().endOf('month').format(formats.DATE_API),
            };
        }

        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                turnedEditingMode={this.turnedEditingMode}
            />
        );
    }

    showRouteRegistries() {
        if (this.state.showRouteRegistries) {
            this.props.router.push(`/${this.props.params.component}/contracts/${this.props.uuid}`);
        } else {
            this.props.router.push(`/${this.props.params.component}/contracts/${this.props.uuid}/routes`);
        }
    }

    closeSubmodals() {
        this.setState({showRouteRegistries: false});
    }

    composeItem(data) {
        let item = super.composeItem(data);

        item.component = component_mapper(this.props.params.component);

        return item;
    }

    render() {
        let title = `Карточка ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}/свидетельства`;

        let form;
        let buttons;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {
            title = `Карточка ${(_.get(this.state.item, 'type') === 'license') ? 'свидетельства' : (window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта')}`;

            if (this.state.item) {
                form = this.getForm(this.state.item, ::this.edit);

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            {currentUser.can('com.rnis.system.permission.audit', 'read') ? (
                                <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                                default="Журнал аудита">
                                    <ModalTopMenuListItem
                                        className="b-icon-link_params b-icon-link_icon_history"
                                        href={`/system/audit/${this.props.uuid}?class=${this.modelClass}`}
                                    />
                                </ContextTooltip>
                            ) : null}

                            <ContextTooltip key="base-editor.print" code="base-editor.print" default="Печать">
                                <ModalTopMenuListItem
                                    key="print"
                                    className="b-icon-link_icon_print"
                                    title="Печать"
                                    onClick={::this.print}
                                />
                            </ContextTooltip>

                            <ModalTopMenuListSeparator key="separator"/>
                        </ModalTopMenuList>

                        <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                            <ModalTopMenuButton
                                className="_close"
                                onClick={this.props.isPageWithDetect && this.modalConfirmation && this.state.isEditingNow ? this.openCheckModal : ::this.props.onClose}
                            />
                        </ContextTooltip>
                    </ModalTopMenuButtons>
                );
            }
        } else {
            form = this.getForm({}, ::this.create);

            buttons = (
                <ModalTopMenuButtons>
                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={this.props.isPageWithDetect && this.modalConfirmation && this.state.isEditingNow ? this.openCheckModal : ::this.props.onClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );
        }

        const className = classNames(`profile-modal b-modal-${this.props.mode} b-modal-contract contract-card-modal`);

        return (
            <div ref={this.setWrapperRef}>
                <PageModal
                    header={{title, buttons}}
                    onClose={this.props.onClose}
                    className={className}
                    buttons={[
                        (this.props.mode === 'edit') ? (
                            <a key="variants" href="javascript:void(0)"
                               className={classNames("b-button b-button_red b-button_size_md b-button_routes", this.state.showRouteRegistries ? 'active' : '')}
                               onClick={::this.showRouteRegistries}>{!this.state.showRouteRegistries ? 'Показать маршруты' : 'Скрыть маршруты'}</a>
                        ) : null,
                        <a key="save" href="javascript:void(0)"
                           className="b-button b-button_red b-button_size_md b-button_save"
                           onClick={(this.props.mode === 'edit') ? ::this.onEdit : ::this.onCreate}>Сохранить</a>,
                    ]}
                >
                    {loader}
                    {form}
                </PageModal>
                {this.state.showRouteRegistries ? (
                    <KiutrContractRouteRegistriesComponent
                        {...this.props}
                        params={{
                            component: component_mapper(this.props.params.component),
                        }}
                        contractUuid={this.state.uuid}
                        onClose={::this.closeSubmodals}
                        
                    />
                ) : null}
                {this.state.isWarningModal && this.modalConfirmation ? this.renderWarningModal() : null}
            </div>
        );
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    turnedEditingMode: PropTypes.func,
})

@connect((state) => ({}), {
    getUnit,
    getUnits,
    getDictionaryList,
    getContractPlanSummary,
    getEntityNames
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        contract: {},
        contract_statuses: [],
        units: [],
        vehicle_capacity_types: [],
        route_schedule_types: [],
        vehicle_environmental_classes: [],
        company_types: [],
        related: new EntityList,
        searchPopupField: null,
        search: {},
        searchResult: null,
        searchLoading: false,
    };

    searchDebounce = debounce(500, ::this.search);

    getData() {
        return this.state.contract;
    }

    async componentDidMount() {
        await this.setState({
            contract: this.props.data,
        });
        this.loadRelated();
        this.loadUnits().then(() => {
            if (!this.get('unit_uuid')) {
                this.setValue('contract.unit_uuid', _.get(_.find(this.state.units, {label: '1 МТДИ'}), 'value'));
            }
        });
        this.loadDictionaries([
            'rates_types',
            'contract_statuses',
            'vehicle_environmental_classes',
            'company_types',
        ]).then(() => {
            if (!this.get('status_uuid')) {
                this.setValue('contract.status_uuid', _.get(_.find(this.state.contract_statuses, {label: 'Утверждён'}), 'value'));
            }
        });
        if (this.props.mode === 'edit') {
            this.loadPlanSummary();
            this.loadDictionaries([
                'vehicle_capacity_types',
                'route_schedule_types',
            ], null, true);
        }
    }

    onChangeInput(field, {target: {value, type}}) {
        super.onChangeInput(field, {target: {value, type}});
        this.props.turnedEditingMode();
    }

    async loadRelated() {
        const unit = {
            class: 'App\\Model\\Unit',
            uuid: this.get('unit_uuid'),
            source: 'organizational_units',
        };

        const carrier = {
            class: 'App\\Model\\Unit',
            uuid: this.get('carrier_uuid'),
            source: 'organizational_units',
        };

        this.props.getEntityNames([unit, carrier]).then(async (response) => {
            if (response.isOk) {
                this.state.related.add(response);
            }
        });
    }

    async loadPlanSummary() {
        const response = await this.props.getContractPlanSummary(this.state.contract.uuid);
        if (response.isOk) {
            this.setState({
                plan_summary: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 10000,
            },
            filters: {
                withComponent: component_mapper(this.props.params.component),
            },
            response_data: [
                'items/uuid',
                'items/name',
                'items/component',
                'items/unit_types',
            ],
        });
        if (response.isOk) {
            await this.setState({
                units: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                    component: item.component,
                    unit_types: item.unit_types,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.contract, path, defaultValue);
    }

    searchUnit(field) {
        this.setState({
            searchPopupField: (this.state.searchPopupField === field) ? null : field,
        });
    }

    renderSearchPopup() {
        const {searchResult} = this.state;
        return (
            <Popup>
                <Block title="ИНН">
                    {this.textInput('search.inn')}
                </Block>
                <Block title="КПП">
                    {this.textInput('search.kpp')}
                </Block>
                <Block title="ОГРН">
                    {this.textInput('search.ogrn')}
                </Block>
                <div className="search-result">
                    {this.state.searchLoading ? (<GlobalLoaderComponent/>) : null}
                    {(searchResult === null) ? 'Укажите реквизиты исполнителя' : (
                        (searchResult === false) ? 'Соответствие не установлено' : (
                            <div>
                                <Block title="Наименование">{searchResult.name}</Block>
                                <Block title="ИНН">{searchResult.inn}</Block>
                                <Block title="КПП">{searchResult.kpp}</Block>
                                <Block title="ОГРН">{searchResult.ogrn}</Block>
                                <Block title="Юр. адрес">{searchResult.legal_address}</Block>
                                <Button color="red" shadow="red" text="OK" onClick={::this.confirmSearch}/>
                            </div>
                        )
                    )}
                </div>
            </Popup>
        );
    }

    async confirmSearch() {
        const {searchPopupField} = this.state;
        await this.setValue(`contract.${searchPopupField}`, this.state.searchResult.uuid);
        this.setState({
            searchPopupField: null,
        });

        this.loadRelated();
    }

    async setValue(field, value) {
        super.setValue(field, value);

        if (/^search\./.test(field)) {
            this.searchDebounce();
        }
    }

    async search() {
        this.setState({searchLoading: true});
        const searchData = this.state.search || {};
        const response = await this.props.getUnit(null, {
            inn: searchData.inn,
            kpp: searchData.kpp,
            ogrn: searchData.ogrn,
        });
        this.setState({searchLoading: false});

        if (response.isOk) {
            this.setState({
                searchResult: response.payload,
            });
        } else {
            this.setState({
                searchResult: false,
            });
        }
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} single={this.props.mode !== 'edit'}
                                   title={`Основные параметры ${(this.get('type') === 'license')  ? 'свидетельства' : (window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта')}`}>
                        <Block title="Тип">
                            {(this.props.mode !== 'edit') ? this.select('contract.type', _.map(contract_types, (label, value) => ({
                                value,
                                label,
                            }))) : _.get(contract_types, this.get('type'))}
                        </Block>
                        <Block title={window.RNIS_SETTINGS.rename_contracts ? "№ подрядного обязательства" : "№ контракта"}>
                            {this.textInput('contract.number')}
                        </Block>
                        <Block title={`Серия ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}/свидетельства`}>
                            {this.textInput('contract.series')}
                        </Block>
                        <Block title={`Дата ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}`}>
                            {this.datepicker('contract.date')}
                        </Block>
                        <Block title="Дата утверждения результатов открытого конкурса">
                            {this.datepicker('contract.date_confirmed', {
                                className: 'centered',
                            })}
                        </Block>
                        <Block title={<span>Перевозчик <IconButton icon="search"
                                                                   onClick={this.searchUnit.bind(this, 'carrier_uuid')}/></span>}>
                            {(this.state.searchPopupField === 'carrier_uuid') ? this.renderSearchPopup() : null}
                            {(!this.get('carrier_uuid') || _.find(this.getCarriers(), {value: this.get('carrier_uuid')})) ? (
                                this.select('contract.carrier_uuid', this.getCarriers())
                            ) : (
                                this.state.related.getReact(this.get('carrier_uuid'))
                            )}
                        </Block>
                        <Block title={<span>Организатор перевозок <IconButton icon="search"
                                                                              onClick={this.searchUnit.bind(this, 'unit_uuid')}/></span>}>
                            {(this.state.searchPopupField === 'unit_uuid') ? this.renderSearchPopup() : null}
                            {(!this.get('unit_uuid') || _.find(this.getUnits(), {value: this.get('unit_uuid')})) ? (
                                this.select('contract.unit_uuid', this.getUnits())
                            ) : (
                                this.state.related.getReact(this.get('unit_uuid'))
                            )}
                        </Block>
                        <Block title="Вступает в силу">
                            {this.datepicker('contract.date_from')}
                        </Block>
                        <Block title="Действует по">
                            {this.datepicker('contract.date_to', {
                                className: 'centered',
                            })}
                        </Block>
                        <Block title="Дата начала перевозок">
                            {this.datepicker('contract.work_from')}
                        </Block>
                        <Block title="Дата окончания перевозок">
                            {this.datepicker('contract.work_to', {
                                className: 'centered',
                            })}
                        </Block>
                        <Block title="Статус">
                            {this.select('contract.status_uuid', this.state.contract_statuses)}
                        </Block>
                        <Block title="Экологический класс">
                            {this.select('contract.environmental_class_uuid', this.state.vehicle_environmental_classes)}
                        </Block>
                        <Block title="Вид тарифа">
                            {this.select('contract.rates_type_uuid', this.state.rates_types)}
                        </Block>
                        <Block size="xl" title="Максимальный срок эксплуатации, дней">
                            {this.textInput('contract.max_service_lifetime')}
                        </Block>
                        <br/>

                        {
                           !window.RNIS_SETTINGS.CITY_TULA ? (
                                <span>
                                    <Block>
                                        {this.checkbox('contract.is_low_floor_level', 'Низкопольные', {
                                            className: '',
                                        })}
                                    </Block>
                                    <Block>
                                        {this.checkbox('contract.is_audio_video_fixation', 'Аудио/Видео фиксация', {
                                            className: '',
                                        })}
                                    </Block>
                                    <Block>
                                        {this.checkbox('contract.is_air_conditioning_installation', 'Кондиционирование', {
                                            className: '',
                                        })}
                                    </Block>
                                    <Block>
                                        {this.checkbox('contract.is_cashless_payment', 'Безналичная оплата', {
                                            className: '',
                                        })}
                                    </Block>
                                    <Block>
                                        {this.checkbox('contract.is_electronic_scoreboard', 'Электронное табло', {
                                            className: '',
                                        })}
                                    </Block>
                                    <Block>
                                        {this.checkbox('contract.is_passenger_monitoring_system', 'Мониторинг пассажиропотока', {
                                            className: '',
                                        })}
                                    </Block>
                                    <Block>
                                        {this.checkbox('contract.is_service', 'Свидетельство', {
                                            className: '',
                                        })}
                                    </Block>
                                </span>
                            ) : null
                        }            
                    </AccordionItem>
                    {((this.props.mode === 'edit') && this.state.plan_summary && !window.RNIS_SETTINGS.CITY_TULA) ? ([
                        <AccordionItem title="Плановый пробег">
                            <Block title="1-й квартал">
                                {this.state.plan_summary.mileage_quarter_1}
                            </Block>
                            <Block title="2-й квартал">
                                {this.state.plan_summary.mileage_quarter_2}
                            </Block>
                            <Block title="3-й квартал">
                                {this.state.plan_summary.mileage_quarter_3}
                            </Block>
                            <Block title="Октябрь">
                                {this.state.plan_summary.mileage_october}
                            </Block>
                            <Block title="Ноябрь">
                                {this.state.plan_summary.mileage_november}
                            </Block>
                            <Block title="Декабрь">
                                {this.state.plan_summary.mileage_december}
                            </Block>
                        </AccordionItem>,
                        <AccordionItem title="Классы ТС">
                            <Block size="xl">
                                <div className="Table indent-none">
                                    <TableContainer>
                                        <table className="b-table">
                                            <thead>
                                            <tr>
                                                <th width="200px">Класс</th>
                                                {this.state.route_schedule_types.map((scheduleType) => (
                                                    <th key={scheduleType.value}
                                                        className="text-center">{scheduleType.label}</th>
                                                ))}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.vehicle_capacity_types.map(this.renderPlanRow.bind(this))}
                                            {this.renderSummary()}
                                            </tbody>
                                        </table>
                                    </TableContainer>
                                </div>
                            </Block>
                        </AccordionItem>
                    ]) : null}
                </Accordion>
            </div>
        );
    }

    renderPlanRow(capacityType, index) {
        return (
            <tr key={index}>
                <td>{capacityType.label}</td>
                {this.state.route_schedule_types.map(this.renderPlanCell.bind(this, capacityType))}
            </tr>
        );
    }

    renderPlanCell(capacityType, scheduleType, index) {
        const value = _.get(_.find(this.state.plan_summary.vehicles_plan, {
            route_schedule_type_uuid: scheduleType.value,
            vehicle_capacity_type_uuid: capacityType.value,
        }), 'value', 0);
        return (
            <td key={index} className="text-center">
                {value}
            </td>
        )
    }

    renderSummary() {
        return (
            <tr key="summary">
                <td>Итого</td>
                {this.state.route_schedule_types.map(::this.renderPlanSummaryCell)}
            </tr>
        );
    }

    renderPlanSummaryCell(scheduleType, index) {
        const value = _.sumBy(_.filter(this.state.plan_summary.vehicles_plan, {
            route_schedule_type_uuid: scheduleType.value,
        }), 'value');
        return (
            <td key={index} className="text-center">
                {value}
            </td>
        )
    }

    getUnits() {
        const unitTypeUuid = _.get(_.find(this.state.company_types, {label: 'Организатор работ'}), 'value');
        return _.filter(this.state.units, (unit) => {
            return _.indexOf(unit.unit_types, unitTypeUuid) !== -1;
        });
    }

    getCarriers() {
        const unitTypeUuid = _.get(_.find(this.state.company_types, {label: 'Исполнитель работ'}), 'value');
        return _.filter(this.state.units, (unit) => {
            return _.indexOf(unit.unit_types, unitTypeUuid) !== -1;
        });
    }
}