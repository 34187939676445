import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {cloneDeep} from 'lodash';

import './style.less';
import TableContainer from "components/ui/Table/Container/TableContainer";
import _ from 'lodash';


@propTypes({
    data: PropTypes.array,
    metadata: PropTypes.array,
})

@defaultProps({
    data: [],
    metadata: [],
})

export default class ViolationTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: []
        };
    }

    componentWillMount() {
        this.setState({data: cloneDeep(this.props.data)});
    }

    componentWillReceiveProps(nextProps) {
        this.setState({data: cloneDeep(nextProps.data)});
    }

    render() {
        return (
            <div className="Table indent-none">
                <TableContainer>
                    <table className="b-table">
                        <thead>
                        <tr>
                            {_.map(this.props.metadata, ::this.renderHeaderCell)}
                        </tr>
                        </thead>
                        <tbody>
                        {_.map(this.props.data, ::this.renderBodyRow)}
                        </tbody>
                    </table>
                </TableContainer>
            </div>
        );
    }

    renderHeaderCell(item) {
        return (
            <th key={item.field}>
                {item.name}
            </th>
        )
    }

    renderBodyRow(item, index) {
        return (
            <tr key={index}>
                {_.map(this.props.metadata, this.renderBodyCell.bind(this, item, index))}
            </tr>
        );
    }

    renderBodyCell(item, index, meta) {
        return (
            <td key={index.toString() + meta.field} className={meta.className || ''}>
                {_.get(item, meta.field)}
            </td>
        );
    }
}