import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import Colors from '../../helpers/colors';

import {PercentSpinner} from '../ui/spinner';
import PercentViewer from '../ui/percent-viewer';
import BestWorstCompanies from './best-worst-companies';

import './time-control-stat.less'


@propTypes({
  bestOnes: PropTypes.array,
  worstOnes: PropTypes.array,
  stat: PropTypes.object
})

export default class TimeControlStat extends Component {

  render() {
    return (
      <div className="TimeControlStat">
        <div className="Spinners">
          <div>
            <h4>По расписанию</h4>
            <PercentSpinner progress={68} fill={Colors.grayLight} color={Colors.green} />
          </div>
          <div className="notInTime">
            <h4>Не по расписанию</h4>
            <PercentViewer progress={32} />
          </div>
          <div>
            <h4>Опоздание</h4>
            <PercentSpinner progress={80} color={Colors.red} />
          </div>
          <div>
            <h4>Опережение</h4>
            <PercentSpinner progress={50} color={Colors.yellow} />
          </div>
        </div>
        <div className="row Companies">
          <div className="col-md-4 title-block">
            <div className="title">Рейтинг предприятий</div>
          </div>
          <div className="col-md-8">
            <BestWorstCompanies
              bestOnes={this.props.bestOnes.map(i => i.name)}
              worstOnes={this.props.worstOnes.map(i => i.name)} />
          </div>
        </div>
      </div>
    );
  }

}
