import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapMarker from "components/ui/map/base/marker";
import ControlPointIcon from "components/ui/map/markers/icons/control-point-icon";
import KursPointIcon from "components/ui/map/markers/icons/kurs-point-icon";
import _ from 'lodash';

@propTypes({
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
    name: PropTypes.string,
    zIndex: PropTypes.number,
    color: PropTypes.string,
    icon: PropTypes.bool,
})

@defaultProps({
    onDrag: () => {
    },
    onDragEnd: () => {
    },
    zIndex: 0,
    name: null,
    color: 'red',
    icon: true,
})

export default class KursPointMarker extends AbstractMapMarker {

    getOptions(props = null) {
        props = props || this.props;

        return _.merge(super.getOptions(), {
            icon: KursPointIcon.getIcon(props.color, props.icon),
            riseOnHover: true,
            zIndexOffset: props.zIndex,
        });
    }

    componentWillMount() {
        super.componentWillMount();
    }

}