import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import './modal-top-menu-list.less';

@propTypes({})

@defaultProps({})

export default class ModalTopMenuButtons extends Component {
    render() {
        return (
            <div className="b-modal__button-block">
                {this.props.children}
            </div>
        );
    }
}