import React from "react";
import {List} from "immutable";
import {connect} from "react-redux";
import _ from "lodash";

import Page from "components/ui/page";
import Loader from "components/ui/loader";
import currentUser from 'helpers/current-user';

import {getDictionaryList, getDictionaryMeta, reset} from "store/reducers/dictionaries/dictionary";
import {DictionaryMeta} from "helpers/components/dictionaries/dictionary_meta";
import {api} from "helpers/api";

import BaseTableComponent from "components/base/base_table";
import IconButton from "components/ui/icon-button";
import {mapDatatablesRequestToMeta} from "helpers/api";

import DictionaryEditor from "./editor";
import SlideLeftTransition from "components/ui/transitions/slide-left";
import TableContainer from "components/ui/Table/Container/TableContainer";

import "./dictionary.less";
import "./structure.less";
import * as alerts from "helpers/alerts";
import {deleteDocument} from "store/reducers/dictionaries/editor";
import {makeResponse} from "helpers/response";
import {request} from 'helpers/api/session';
import systems from "dictionaries/systems";
import {Link} from "react-router";
import ContextTooltip from "components/ui/context-tooltip";
import Column from "components/ui/column";
import classNames from 'classnames';
import ReactDOMServer from 'react-dom/server';
import HotKeysManager from "components/ui/HotKeys/Manager/HotKeysManager";
import {component_mapper} from "helpers/component_mapper";
import {preventDefaultEventHandlers} from "helpers/hot_key_handlers";

@connect(state => ({
    meta: state.dictionary_list.meta,
}), {getDictionaryMeta, getDictionaryList, reset, deleteDocument})

export default class DictionariesListComponent extends BaseTableComponent {

    state = {
        ...this.state,
        loading: false,
        columns: List([]),
        component: null,
    };

    async componentWillUpdate(props, state) {
        if (props.params.system !== state.component) {
            await this.setState({
                component: props.params.system,
            });
        }

        const uuid = props.params.uuid;

        const newEditorUuid = (uuid && uuid !== 'create') ? uuid : null;

        if (this.state.editorUuid !== newEditorUuid || this.state.showEditorModal !== !!uuid) {
            this.setState({
                showEditorModal: !!uuid,
                editorUuid: newEditorUuid
            });
        }
    }

    componentWillMount() {
        this.props.reset();
    }

    async componentDidMount() {
        this.setState({loading: true});
        const response = await this.props.getDictionaryMeta(this.props.params.dictionary);
        if (response.isOk) {
            const columns = this.parseColumns(response);
            this.setState({columns});
        } else {
            response.showErrors();
        }

        this.setState({loading: false});
    }

    getHotKeyHandlers() {
        return preventDefaultEventHandlers(Object.assign(super.getHotKeyHandlers(), {
            delete: ::this.deleteSelected,
        }));
    }

    render() {
        const loader = this.state.loading ? <Loader color="red" align="center"/> : null;
        let table = (!this.state.loading) ? this.renderTable() : null;
        const editor = this.state.showEditorModal
            ? <DictionaryEditor
                location={this.props.location}
                component={this.state.component}
                dictionary={this.props.params.dictionary}
                dictionaryMeta={this.props.meta}
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
            : '';

        return (
            <HotKeysManager {...this.getHotKeyHandlers()}>
                <Page title={<span>{systems[this.state.component]} → <Link to={`/${this.state.component}/dictionaries`}>Справочники</Link> → {this.getTitle()}</span>}
                      pageId="DictionaryList"
                      headerActions={this.renderHeaderActions()}
                      headerContents={this.renderHeaderContents()}>

                    {loader}

                    <TableContainer>
                        {table}
                    </TableContainer>

                    <SlideLeftTransition>
                        {editor}
                    </SlideLeftTransition>
                </Page>
            </HotKeysManager>
        );
    }

    getTitle() {
        if ((this.state.component === 'garbage') && (this.props.meta.key === 'vehicle_models')) {
            return 'Типы ТС';
        }
        return this.props.meta.title || '...';
    }

    async closeEditor() {
        const component = this.props.location.query.component;
        if (component) {
            await this.props.router.push(`/${this.state.component}/dictionaries/${this.props.params.dictionary}?component=${component}`);
        } else {
            await this.props.router.push(`/${this.state.component}/dictionaries/${this.props.params.dictionary}`);
        }
    }

    submitEditor() {
        if (!this.refs.table) return;

        this.refs.table.getWrappedInstance().reload();
        this.closeEditor();
    }

    async showEditor() {
        this.showEditorWithUuid();
    }

    async showEditorWithUuid(uuid = null) {
        await this.closeEditor();
        const component = this.props.location.query.component;
        if (component) {
            this.props.router.push(`/${this.state.component}/dictionaries/${this.props.params.dictionary}/${uuid || 'create'}?component=${component}`);
        } else {
            this.props.router.push(`/${this.state.component}/dictionaries/${this.props.params.dictionary}/${uuid || 'create'}`);
        }
    }

    async onDblClick(data) {
        if (!this.props.meta.editable || !currentUser.can(`com.rnis.dictionary.dictionary.${this.props.params.dictionary}`, 'update')) {
            return;
        }
        if (data && data.uuid) {
            this.showEditorWithUuid(data.uuid);
        }
    }

    async editSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            this.showEditorWithUuid(selected.uuid);
        }
    }

    async deleteSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (!selected) {
            return;
        }
        return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            await this.props.deleteDocument({
                dictionary: {
                    key: this.props.params.dictionary
                },
                uuid: selected.uuid,
            });
            super.deleteSelected(selected.uuid);
            this.reload();
        });
    }

    async loadCallback(request, drawCallback, settings) {
        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        if (window.RNIS_SETTINGS.temporarily_sbdd && location.pathname.includes('vehicle_marks') && this.props.location.query.component || window.RNIS_SETTINGS.temporarily_sbdd && location.pathname.includes('vehicle_models') && this.props.location.query.component) {
            meta.filters.withComponent = ['operator', this.props.location.query.component];
        } else {
            meta.filters.withComponent = component_mapper(this.props.location.query.component || this.state.component);
        }
        const response = await this.props.getDictionaryList(this.props.params.dictionary, meta, false);

        if (response.isOk) {
            const data = await this.prepareData(response.payload.documents);

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: response.data.headers.meta.pagination.total,
                recordsTotal: response.data.headers.meta.pagination.total
            };

            drawCallback(json);
            this.selectedRowsRecalc();
            this.setState({success: true});
        } else {
            response.showErrors();
        }
    }

    async prepareData(data) {
        let preload = {};

        for (let i in this.props.meta.fields) {
            const field = this.props.meta.fields[i];
            switch (field.type) {
                case 'App\\Meta\\Fields\\BelongsTo':
                    await this.loadDocuments(field.key, field.meta.dictionary);
                    break;
                case 'App\\Meta\\Fields\\BelongsToExchange':
                    // todo: доделать запрос связанных документов из Exchange
                    const uuids = _.map(data, field.key);
                    await this.loadExchangeDocuments(field.key, field.meta.list, uuids);
                    break;
            }
        }

        return _.map(data, (row) => {
            return this.props.meta.prepareData(row, this.state);
        });
    }

    async loadExchangeDocuments(key, item, uuids = []) {
        const response = await request(item.method, {}, {
            meta: {
                filters: {
                    withUuid: uuids,
                },
            },
        });

        if (response.isOk) {
            let data = {};
            data[`loaded_${key}`] = _.map(response.get(`payload.${item.path}`), (document) => {
                return {
                    value: document.uuid,
                    label: document[item.title_field]
                };
            });

            this.setState(data);
        } else {
            response.showErrors();
        }

    }

    async loadDocuments(key, dictionaryKey) {
        const metaResponse = await makeResponse(() => {
            return api.dictionary.getDictionaryMeta(dictionaryKey);
        });


        if (metaResponse.isOk) {
            const dictionary = new DictionaryMeta(metaResponse.payload);
            const response = await this.props.getDictionaryList(dictionary.key, {
                filters: {
                    withTrashed: true,
                    withComponent: component_mapper(this.props.location.query.component || this.state.component),
                },
                pagination: {
                    page: 1,
                    limit: 1000,
                },
            }, false);


            if (response.isOk) {
                let data = {};
                data[`loaded_${key}`] = _.map(response.payload.documents, (document) => {
                    return {
                        value: document.uuid,
                        label: document[dictionary.title_field],
                        deleted_at: document.deleted_at,
                    };
                });

                this.setState(data);
            } else {
                response.showErrors();
            }
        } else {
            metaResponse.showErrors();
        }
    }

    renderHeaderActions() {
        if (this.props.params.dictionary === 'kurs_formulas') {
            return;
        }

        return [
            ((this.props.meta.editable && currentUser.can(`com.rnis.dictionary.dictionary.${this.props.params.dictionary}`, 'delete')) ? (
                <ContextTooltip key="base-table-list.delete" code="base-table-list.delete" default="Удалить">
                    <IconButton icon="basket" disabled={this.state.selectedRowsCount !== 1}
                                onClick={::this.deleteSelected}/>
                </ContextTooltip>
            ) : null),
        ];
    }

    getCreateButton() {
        if (!this.props.meta.editable || !currentUser.can(`com.rnis.dictionary.dictionary.${this.props.params.dictionary}`, 'create')) {
            return;
        }

        if (this.props.params.dictionary === 'kurs_formulas') {
            return;
        }

        return (
            <ContextTooltip key="base-table-list.create" code="base-table-list.create" default="Добавить">
                <IconButton
                    icon="plus"
                    tooltip="Добавить"
                    onClick={::this.showEditor}
                />
            </ContextTooltip>
        );
    }

    parseColumns(response) {
        return List(this.prepareColumns(_.filter(_.map(_.filter(response.payload.fields, (field) => {
            if (field.type === 'App\\Meta\\Fields\\Geometry') {
                return false;
            }
            if (field.type === 'App\\Meta\\Fields\\Ports') {
                return false;
            }
            if (field.type === 'App\\Meta\\Fields\\MultiText') {
                return false;
            }
            if (field.type === 'App\\Meta\\Fields\\BelongsToMulti') {
                return false;
            }
            if (field.key === "vehicle_mark_uuid") {
                return field;
            }

            if (this.props.params.dictionary === 'work_types' && window.RNIS_SETTINGS.hide_work_type_skpdi_column) {
                if (field.key === 'external_id') {
                    return false
                }
            }

            return ((field.key !== 'uuid') && (!field.meta.component || (_.indexOf(field.meta.component.split('|'), this.state.component) !== -1)));
        }), (field, index) => {
            let column = new Column(field.title)
                .fromField(field.key);

            if (field.type === 'App\\Meta\\Fields\\UnitChildren') {
                return null;
            }

            switch (field.type) {
                case 'App\\Meta\\Fields\\Checkbox':
                case 'App\\Meta\\Fields\\MultiSelect':
                case "App\\Meta\\Fields\\BelongsTo":
                case "App\\Meta\\Fields\\Marker":
                    column.denyOrder();
                    break;
            }

            switch (field.type) {
                case 'App\\Meta\\Fields\\Text':
                case 'App\\Meta\\Fields\\Label':
                case 'App\\Meta\\Fields\\Textarea':
                case 'App\\Meta\\Fields\\IntegerField':
                case 'App\\Meta\\Fields\\FloatField':
                    break;
                case 'App\\Meta\\Fields\\DatePicker':
                    column.withDateFilter();
                    break;
                case 'App\\Meta\\Fields\\Select':
                    column.withFilter(field.key, async () => {
                        return _.map(_.values(field.meta.options), (option) => ({
                            uuid: option.value,
                            name: option.label,
                        }));
                    });
                    break;
                case 'App\\Meta\\Fields\\BelongsTo':
                    column.withFilter(field.key, async () => {
                        const response = await this.props.getDictionaryList(field.meta.dictionary, {
                            filters: {
                                withComponent: this.props.location.query.component || this.state.component,
                            },
                        });
                        if (response.isOk) {
                            return response.payload.documents;
                        }
                        return {};
                    });
                    // NOTE: здесь нет break -_-
                case 'App\\Meta\\Fields\\BelongsToExchange':
                    let filterPayload = {};
                    if (field.meta.dictionary) {
                        field.meta.list = {
                            method: 'com.rnis.dictionary.action.list',
                            path: 'documents',
                            title_field: 'name',
                        };
                        filterPayload = {
                            dictionary: {
                                key: field.meta.dictionary,
                            },
                        };
                    }
                    column.withFilter(field.key, async () => {
                        if (!_.get(field, 'meta.list.method')) {
                            return [];
                        }
                        const response = await request(field.meta.list.method, filterPayload, {
                            meta: {
                                pagination: {
                                    page: 1,
                                    limit: 10000,
                                },
                                filters: {
                                    withComponent: this.props.location.query.component || this.state.component,
                                },
                            },
                        });
                        if (response.isOk) {
                            let docs = response.get(`payload.${field.meta.list.path}`);

                            let list = _.map(docs, (document) => {
                                return {
                                    uuid: document.uuid,
                                    name: document[field.meta.list.title_field]
                                };
                            });

                            if (field.key === 'work_category_uuid'
                                && window.RNIS_SETTINGS.SHOWED_WORKS_CATEGORIES
                                && window.RNIS_SETTINGS.SHOWED_WORKS_CATEGORIES.length) {
                                list = list.filter((item) => window.RNIS_SETTINGS.SHOWED_WORKS_CATEGORIES.find((swc) => swc === item.name))
                            }

                            return list;
                        }
                        return [];
                    });
                default:
                    column.denyColumnFilter();
                    break;
            }

            switch (field.type) {
                case 'App\\Meta\\Fields\\MultiSelect':
                    column.withDrawer((item) => {
                        const values = JSON.parse(_.get(item, field.key) || '[]');
                        return _.filter(_.map(values, (value) => {
                            return _.get(_.find(_.values(field.meta.options), {value}), 'label');
                        })).join('<br/>');
                    });
                    break;
                case 'App\\Meta\\Fields\\Marker':
                    column.withAlign('center');
                    column.withDrawer((item) => {
                        const icon = _.get(item, field.key);

                        if (!icon) {
                            return null;
                        }
                        return (
                            ReactDOMServer.renderToStaticMarkup(<div
                                className={classNames('marker', 'marker__select', `marker_type_${icon}`)}
                                onClick={::this.onClick}>
                                <div>
                                    <span className={classNames('marker__icon', `icon-${icon}`)}/>
                                </div>
                            </div>)
                        );
                    });
                    break;
            }

            return column;
        }))));
    }
}
