import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import Page from "components/ui/page";

import UserMapObjectsMap from "./map";

import './user-map-objects.less';
import LayersList from "./layers/list";
import ObjectsEditor from "components/modules/user-map-objects/objects/editor";
import SlideLeftTransition from "components/ui/transitions/slide-left";
import {showError} from "helpers/errors";
import * as validation from "helpers/validation";
import {getUserGeoObject, updateUserGeoObject} from "store/reducers/user-map-objects/object_editor";
import systems from "dictionaries/systems";
import {Link} from "react-router";
import {getLayerObjectsWithoutStore} from "store/reducers/user-map-objects/layer_list";

@connect(state => ({
    layers: state.user_map_objects.layer_list.get('layers'),
}), {
    updateUserGeoObject,
    getLayerObjectsWithoutStore,
    getUserGeoObject,
})

export default class UserMapObjects extends Component {
    state = {
        selectedLayer: null,
        geometryInCreation: null,
        objectEdit: null,
        geometryEdit: null,
        objects: [],
    };

    componentDidMount() {
        const uuid = this.props.location.query.uuid;
        if (uuid) {
            this.loadSelectedObject(uuid);
        }
    }

    componentWillUnmount() {
        localStorage.setItem("previousUrl", window.location.pathname)
    }

    async loadSelectedObject(uuid) {
        const response = await this.props.getUserGeoObject(null, uuid);

        if (response.isOk) {
            const item = response.payload;

            await this.onLayerSelect(item.layer_uuid);
            this.onObjectEditGeometry(item.uuid);

            this.refs.map.getWrappedInstance().fitOn(item.uuid);
        } else {
            response.showErrors();
        }
    }

    async loadObjects() {
        this.setState({
            objects: [],
        });
        if (!this.state.selectedLayer) {
            return;
        }

        this.setState({loading: true});
        const response = await this.props.getLayerObjectsWithoutStore(this.state.selectedLayer);
        if (response.isOk) {
            this.setState({
                objects: response.payload.items,
            });
        }
        this.setState({loading: false});
    }

    render() {

        //console.log(this.state)
        const editorCreate = this.state.geometryInCreation ? <ObjectsEditor
            key="editorCreate"
            onClose={::this.closeEditor}
            onSubmit={::this.submitEditor}
            mode="add"
            layerUuid={this.state.selectedLayer}
            withUnit={!!_.get(_.find(this.props.layers, {uuid: this.state.selectedLayer}), 'is_null_run')}
            geometry={this.state.geometryInCreation && this.state.geometryInCreation.toGeoJSON()}
        /> : '';

        const editorEdit = this.state.objectEdit ? <ObjectsEditor
            key="editorEdit"
            onClose={::this.closeEditor}
            onSubmit={::this.submitEditor}
            mode="edit"
            uuid={this.state.objectEdit}
            withUnit={!!_.get(_.find(this.props.layers, {uuid: this.state.selectedLayer}), 'is_null_run')}
            layerUuid={this.state.selectedLayer}
        /> : '';

        return (
            <Page pageId="UserMapObjects"
                  className="r-block_noIndent"
                  title={<span>{systems[this.props.params.component]} → <Link to={`/${this.props.params.component}/dictionaries`}>Справочники</Link> → Пользовательские объекты на карте</span>}>
                <SlideLeftTransition>
                    {editorCreate}
                    {editorEdit}
                </SlideLeftTransition>
                <div className="map">
                    <UserMapObjectsMap
                        ref="map"
                        component={this.props.params.component}
                        objects={this.state.objects}
                        displayControls={!!this.state.selectedLayer && !editorCreate && !editorEdit && !this.state.geometryEdit}
                        onlyMarker={!!_.get(_.find(this.props.layers, {uuid: this.state.selectedLayer}), 'is_null_run')}
                        onObjectCreate={::this.onObjectCreate}
                        geometryEdit={this.state.geometryEdit}
                    />
                </div>
                <div className="form">
                    <LayersList
                        ref="layers_list"
                        objects={this.state.objects}
                        selectedLayer={this.state.selectedLayer}
                        onLayerSelect={::this.onLayerSelect}
                        onObjectClick={::this.onObjectClick}
                        onObjectEdit={::this.onObjectEdit}
                        onObjectEditGeometry={::this.onObjectEditGeometry}
                        onObjectSaveGeometry={::this.onObjectSaveGeometry}
                        geometryEdit={this.state.geometryEdit}
                    />
                </div>
            </Page>
        );
    }

    closeEditor() {
        if (this.state.geometryInCreation) {
            this.state.geometryInCreation.remove();
            if (this.state.geometryInCreation.original) {
                this.state.geometryInCreation.original.remove();
            }
        }
        this.setState({
            geometryInCreation: null,
            objectEdit: null,
            geometryEdit: null,
        });
    }

    submitEditor() {
        this.closeEditor();

        this.reloadSelectedLayer();
    }

    async onLayerSelect(uuid) {
        this.closeEditor();
        await this.setState({
            selectedLayer: (this.state.selectedLayer !== uuid) ? uuid : null,
            geometryEdit: null,
        });
        await this.reloadSelectedLayer();
    }

    onObjectClick(object) {
        this.refs.map.getWrappedInstance().fitOn(object.uuid);
    }

    async reloadSelectedLayer() {
        await this.loadObjects();
    }

    onObjectCreate(layer) {
        this.setState({geometryInCreation: layer});
    }

    onObjectEdit(uuid) {
        this.setState({
            objectEdit: uuid,
        });
    }

    onObjectEditGeometry(uuid) {
        this.setState({geometryEdit: uuid});
    }

    async onObjectSaveGeometry(object) {
        object.geometry = this.refs.map.getWrappedInstance().getGeometry(object.uuid);

        const response = await this.props.updateUserGeoObject(object);
        if (response.status === 'ok') {
            this.submitEditor();
        } else {
            this.setState({
                errors: validation.getErrors(response.error)
            });
            showError(response.error);
        }
    }
}