import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './checkbox.less';


@propTypes({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    checked: PropTypes.bool,
})

@defaultProps({
    onChange: () => {
    }
})

export class LabeledCheckbox extends Component {

    render() {
        return (
            <div className="checkbox-labeled" onClick={::this.onChange}>
                <Checkbox checked={this.props.checked}/>
                <span>{this.props.label}</span>
            </div>
        );
    }

    onChange(e) {
        this.props.onChange(!this.props.checked);
    }
}


@propTypes({
    checked: PropTypes.bool,
    onChange: PropTypes.func
})

@defaultProps({
    onChange: () => {
    }
})

export default class Checkbox extends Component {

    render() {
        return (
            <div className="b-checkbox">
                <input type="checkbox" className="b-checkbox__inner" checked={this.props.checked} onChange={::this.onChange} />
                <label className="b-checkbox__label"/>
            </div>
        );
    }

    onChange() {
        this.props.onChange(!this.props.checked);
    }
}

