import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getEvents = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.vehicles.getEvents(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};