import React from 'react';
import _ from 'lodash';
import TableContainer from "components/ui/Table/Container/TableContainer";


export default function AutoRunTable({plan, fact}) {
    return (
        <div className="Table indent-none">
            <TableContainer>
                <table className="b-table">
                    <thead>
                    <tr>
                        <th/>
                        <th>План</th>
                        <th>Факт</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Количество авто-часов</td>
                        <td>{plan}</td>
                        <td>{fact}</td>
                    </tr>
                    </tbody>
                </table>
            </TableContainer>
        </div>
    );
}