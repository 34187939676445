import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { propTypes, defaultProps } from 'react-props-decorators';
import { connect } from 'react-redux';
import moment from 'moment';
import * as alerts from "helpers/alerts";
import formats from 'dictionaries/formats';
import _ from 'lodash';
import './KursSkpdiAssign.less';
import { getTasks, updateTask } from 'store/reducers/kurs/tasks';
import { EntityList } from 'helpers/entity';
import { getEntityNames } from 'store/reducers/system';
import classNames from 'classnames';
import GlobalLoaderComponent from 'components/ui/global-loader';
import Page from 'components/ui/page';
import ContextTooltip from 'components/ui/context-tooltip';
import IconButton from 'components/ui/icon-button';
import Popup from 'components/ui/popup';
import { getDictionaryList } from 'store/reducers/dictionaries/dictionary';
import { getVehicleList } from 'store/reducers/vehicles/vehicles';
import { getRoadParts } from 'store/reducers/kurs/road_parts';
import Select, { SelectAsync } from 'components/ui/select';
import Datepicker from 'components/ui/form/datepicker';
import { getUnits } from 'store/reducers/organizational_units/units';
import { getContracts } from 'store/reducers/kurs/contracts';
import Input from 'components/ui/form/input';
import debounce from 'throttle-debounce/debounce';
import { Link } from 'react-router';
import currentUser from 'helpers/current-user';
import Accordion from 'components/ui/accordion/accordion';
import AccordionItem from 'components/ui/accordion/accordion-item';
import * as storage from 'utils/storage';
import { LabeledCheckbox }  from "components/ui/checkbox";
import CheckboxDropdown from 'components/ui/checkbox-dropdown';
import { component_mapper } from 'helpers/component_mapper';
import Button from '../../../ui/button';
import {
	getCollapseMultiSelector,
	getExpandedSkpdiSelector,
	getExpandedRnisSelector
} from '../../../../store/reducers/kurs/selectors';
import { setCollapseMulti, setExpandedSkpdi, setExpandedRnis } from '../../../../store/reducers/kurs/actions';
import SearchField from 'components/ui/search-field';


@connect(
	(state) => ({
		collapseMulti: getCollapseMultiSelector(state),
		expandedSkpdi: getExpandedSkpdiSelector(state),
		expandedRnis: getExpandedRnisSelector(state)
	}),
	{
		getTasks,
		getEntityNames,
		getDictionaryList,
		getUnits,
		getContracts,
		updateTask,
		getVehicleList,
		getRoadParts,
		setCollapseMulti,
		setExpandedSkpdi,
		setExpandedRnis
	}
)


@connect(state => ({}), {getTasks, getEntityNames, getDictionaryList, getUnits, getContracts, updateTask, getRoadParts})

export default class KursSkpdiAssign extends Component {

    state = {
        skpdi_loading: false,
        rnis_loading: false,
        saving: false,
        relatedExpanded: false,
		allVehicles: {},

        skpdi_tasks: [],
        selectedSkpdiTasks: [],
        allSkpdiTasksSelected: false,
        skpdi_tasksUnique: [],
        select_workType: [],
        skpdi_related_tasks: [],
        rnis_tasks: [],
		tasks_for_add: [],
        units: [],
        contracts: [],
        filters: {},
        skpdi_page: 1,
        skpdi_pages: 1,
        skpdi_total: 0,
        rnis_page: 1,
        rnis_pages: 1,
        rnis_total: 0,

        expandedSkpdi: null,
        expandedRnis: null,

        showFilters: false,
        kurs_task_statuses: [],
        work_types: [],
        
        related: new EntityList(),
        
    };

    rnisReloadDebounce = debounce(500, ::this.loadRnisTasks);
    skpdiReloadDebounce = debounce(500, ::this.loadSkpdiTasks);
    skpdiReloadDebounceSlowly = debounce(800, ::this.loadSkpdiTasks);
    loadWorkTypesDebounce = debounce(500, ::this.loadWorkTypes);

    limit = 20;

    statuses = {
		none: 'uncheck',
		draft: 'open-link',
		'in-work': 'time-at-work',
		confirmed: 'check',
		'not-confirmed': 'no-done'
    };

    skpdiStatuses = {
        none: 'Не распределено',
        partial: 'Распределено частично',
        full: 'Распределено полностью',
        semiconfirmed: 'Выполнено частично',
        confirmed: 'Выполнено полностью',
    };

    componentWillMount() {
        const filters = storage.get('road:skpdi:filter', {
            date: moment(),
            unit: null,
            contract: null,
            skpdi_search: '',
            rnis_search: '',
            status: null,
            skpdi_status: null
        });
        this.setState({
            filters
        });
        this.loadUnits();
        this.loadContracts();
		this.loadVehicles();

        this.loadDictionaries([ 'work_types', 'kurs_task_statuses' ], 'road');
    }


    async loadUnits() {
        const response = await this.props.getUnits({
            filters: {
                withComponent: 'road'
            },
            pagination: {
                page: 1,
                limit: 10000
            },
            response_data: [ 'items/uuid', 'items/name' ]
        });

        if (response.isOk) {
            this.setState({
                units: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name
                }))
            });
            if (_.find(response.payload.items, {uuid: currentUser.unitUuid()}) && !this.state.filters.unit) {
                this.onFilterChange('unit', {
                    value: currentUser.unitUuid(),
                });
            } else {
                this.reload();
            }
        } else {
            response.showErrors();
        }
    }

	async loadVehicles() {
		let filters = {
			withActiveBnso: true,
			short: true,
			onlyApproved: true
		};
		const component = component_mapper('road');
		filters.withComponent = component;
		const response = await this.props.getVehicleList({
			pagination: {
				page: 1,
				limit: 100000
			},
			filters
		});
		const allVehicles = {};
		let i = 0;
		const length = response.payload.items.length;
		while (i < length) {
			const vehicle = response.payload.items[i];
			allVehicles[vehicle.uuid] = vehicle.state_number;
			i++;
		}
		if (response.isOk) {
			this.setState({
				allVehicles
			});
		} else {
			response.showErrors();
		}
	}


    async loadWorkTypes(input, callback) {
		if (!input) {
			if (window.RNIS_SETTINGS.work_type_filter_skpdi) {
				callback(null, {
					options: _.sortBy(
						this.state.work_types.filter((item) => this.state.skpdi_tasksUnique.includes(item.value)),
						'label'
					),
					complete: false
				});
			} else {
				callback(null, {
					options: _.sortBy(this.state.work_types, 'label'),
					complete: false
				});
			}
		} else {
			let result = await this.props.getDictionaryList(
				'work_types',
				{
					search: input,
					pagination: {
						page: 1,
						limit: 20
					}
				},
				false
			);

			if (result.isOk) {
				const work_types = result.payload.documents.map((type) => ({
					value: type.uuid,
					label: `${type.name}`
				}));

				callback(null, {
					options: _.sortBy(work_types, 'label'),
					complete: false
				});
			} else {
				result.showErrors();
			}
		}
	}

    async loadContracts() {
        const response = await this.props.getContracts({
            pagination: {
                page: 1,
                limit: 10000
            },
            response_data: [ 'items/uuid', 'items/name', 'items/implementer_uuid' ]
        });

        if (response.isOk) {
            this.setState({
                contracts: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                    implementer_uuid: item.implementer_uuid
                }))
            });
        } else {
            response.showErrors();
        }
    }

	componentDidUpdate(prevProps, prevState) {
		if(prevState.filters.skpdi_road_part !== this.state.filters.skpdi_road_part && window.RNIS_SETTINGS.road_skpdi_road_part_filters) {
			this.setState({ skpdi_page: 1 });
		}
	}

    // слева
    async loadSkpdiTasks(withoutLoading = false) {
        let filters = {
            withPeriod: [
				moment(this.state.filters.date).startOf('day').format(formats.DATE_API),
				moment(this.state.filters.date).endOf('day').format(formats.DATE_API)
			]
        };
        if (this.state.filters.unit) {
			filters.withUnits = [ this.state.filters.unit ];
        }
        if (this.state.filters.contract && this.state.filters.contract.length > 0) {
            filters.withContract = this.state.filters.contract;
        }
        if (this.state.filters.skpdi_status) {
            filters.withSkpdiStatus = this.state.filters.skpdi_status;
        }
        if (this.state.filters.work_type) {
			filters.withWorkTypes = [ this.state.filters.work_type ];
        }
		if (this.state.filters.skpdi_road_part) {
			filters.withRoadPartName = this.state.filters.skpdi_road_part;
		}

        filters.onlyFromSkpdi = 1;

        !withoutLoading && this.setState({skpdi_loading: true});
       const response = await this.props.getTasks({
			filters,
			search: this.state.filters.skpdi_search || null,
			pagination: {
				page: this.state.skpdi_page,
				limit: this.limit
			}
		});
		let responseForFilter = null;
		if (this.state.skpdi_page === 1 && window.RNIS_SETTINGS.work_type_filter_skpdi) {
			responseForFilter = await this.props.getTasks({
				filters,
				search: this.state.filters.skpdi_search || null,
				pagination: {
					page: this.state.skpdi_page,
					limit: 600
				},
				response_data: [
					"items/uuid",
					"items/items/work_type_uuid"]
			});
		}
        !withoutLoading && this.setState({skpdi_loading: false});

        if (response.isOk) {
			if (this.state.skpdi_page === 1 && window.RNIS_SETTINGS.work_type_filter_skpdi) {
				const uniqueSkpdiTasksUuid = responseForFilter ? _.uniq(responseForFilter.payload.items.map(el => {
					return el.items.map(el => el.work_type_uuid)
				}).flat()) : [];
				await this.setState({
					skpdi_tasks: response.payload.items,
					skpdi_tasksUnique: uniqueSkpdiTasksUuid,
					skpdi_pages: response.data.headers.meta.pagination.total_pages,
					skpdi_total: response.data.headers.meta.pagination.total,
					select_workType: _.sortBy(
						this.state.work_types.filter((item) => uniqueSkpdiTasksUuid.includes(item.value)),
						'label'
					)
				});
			} else {
				if (window.RNIS_SETTINGS.work_type_filter_skpdi === false) {
					await this.setState({
						skpdi_tasks: response.payload.items,
						skpdi_pages: response.data.headers.meta.pagination.total_pages,
						skpdi_total: response.data.headers.meta.pagination.total,
						select_workType: this.state.work_types,
					});
				} else {
					await this.setState({
						skpdi_tasks: response.payload.items,
						skpdi_pages: response.data.headers.meta.pagination.total_pages,
						skpdi_total: response.data.headers.meta.pagination.total,
					});
				}
			}

			if (this.props.collapseMulti) {
				// expand all if in collapseMulti mode
				this.setState({ expandedSkpdi: response.payload.items.map((el) => el.uuid) });
			} else {
				this.setState({ expandedSkpdi: [] });
			}

		} else {
			response.showErrors();
		}
    }

    async loadSkpdiRelatedTasks() {
        const externalId = _.get(this.getExpandedSkpdiTask(), 'external_id');
        if (!externalId) {
            return;
        }

        let filters = {
            withPeriod: [
                moment(this.state.filters.date).startOf('day').format(formats.DATE_API),
				moment(this.state.filters.date).endOf('day').add(1, 'days').format(formats.DATE_API)
            ],
            withItemExternalId: externalId
        };

        const response = await this.props.getTasks({
            filters,
            pagination: {
                page: 1,
                limit: 100
            }
        });

        if (response.isOk) {
            await this.setState({
                skpdi_related_tasks: response.payload.items
            });
        } else {
            response.showErrors();
        }
    }

    async loadRnisTasks() {
        const getPeriod = (date) =>{
            return [
                moment(date).startOf('day').format(formats.DATE_API),
                moment(date).endOf('day')
                    .subtract(1, 'seconds').format()
            ]
        };

        let filters = {
			withPeriod: getPeriod(this.state.filters.date)
        };

        if (this.state.filters.unit) {
            filters.withUnits = [ this.state.filters.unit ];
        }
        if (this.state.filters.contract && this.state.filters.contract.length > 0) {
            filters.withContract = this.state.filters.contract;
        }
        if (this.state.filters.status) {
            filters.withStatus = this.state.filters.status;
        }

		// если window.RNIS_SETTINGS.work_type_filter_skpdi = false,
		// тогда запрашивать с фильтром типов работ, иначе без него
		if (!window.RNIS_SETTINGS.work_type_filter_skpdi) {
			if (this.state.filters.work_type) {
				filters.withWorkTypes = [ this.state.filters.work_type ];
			}
		}

		this.setState({ rnis_loading: true });
        const response = await this.props.getTasks({
            filters,
            search: this.state.filters.rnis_search || null,
            pagination: {
                page: this.state.rnis_page,
                limit: this.limit
            }
        });
        this.setState({ rnis_loading: false });

        if (response.isOk) {
            await this.setState({
                rnis_tasks: response.payload.items,
                rnis_pages: response.data.headers.meta.pagination.total_pages,
                rnis_total: response.data.headers.meta.pagination.total
            });
        } else {
            response.showErrors();
        }
    }

    // слева
    async loadExpandedSkpdiTask(expandedSkpdiTask = null) {
        if (!expandedSkpdiTask) {
            expandedSkpdiTask = this.props.expandedSkpdi;
        }
        if (!expandedSkpdiTask) {
            return;
        }

		let filters = {
			withUuid: [ expandedSkpdiTask ],
			onlyFromSkpdi: 1
		};

        const response = await this.props.getTasks({
            filters
        });

        if (response.isOk) {
            const skpdiTask = _.first(response.payload.items);

            await this.setState({
                skpdi_tasks: _.map(this.state.skpdi_tasks, (task) => {
                    if (task.uuid === expandedSkpdiTask) {
                        return skpdiTask;
                    }

                    return task;
                })
            });
        } else {
            response.showErrors();
        }
    }

    async loadExpandedRnisTask() {
        if (!this.props.expandedRnis) {
            return;
        }

        let filters = {
            withUuid: [ _.get(this.getExpandedRnisTask(), 'uuid') ]
        };

        const response = await this.props.getTasks({
            filters
        });

        if (response.isOk) {
            const rnisTask = _.first(response.payload.items);

            await this.setState({
                rnis_tasks: _.map(this.state.rnis_tasks, (task) => {
                    if (task.uuid === this.props.expandedRnis) {
                        return rnisTask;
                    }

                    return task;
                }),
            });
        } else {
            response.showErrors();
        }
    }

    async loadRelated(tasks) {
        let items = [];

        _.each(tasks, (task) => {
            _.each(task.items || [], (item) => {
                if (item.geometry_type === 'road_part') {
                    items.push({
                        class: 'App\\Model\\RoadPart',
                        uuid: _.get(item, 'geometry.0.item_uuid'),
                        source: 'kurs'
                    });
                }
            });
        });

        const response = await this.props.getEntityNames(items);

        if (response.isOk) {
            this.state.related.add(response);
            this.forceUpdate();
        }
    }

    dateChange = async ({ target: { value } }) => {
		let filters = this.state.filters;
		filters.date = value;
		await this.setState({ filters });
		this.reload();
		this.saveFilters();
	};

    reload(isNeedRnisTasksReload) {
        this.skpdiReloadDebounce();
        if (isNeedRnisTasksReload !== false) {
			this.rnisReloadDebounce();
		}
    }

    toggleLegend() {
        this.setState({
            legendActive: !this.state.legendActive
        });
    }

    hideLegend() {
        this.setState({
            legendActive: false
        });
    }

    renderLegend() {
        return (
            <Popup className="top-link SkpdiAssignLegendModal" show={true} onClose={::this.hideLegend}>
                <div className="popup-container__content">
                    <div className="jobDistribution__item jobDistribution__item_full">
                        <div className="jobDistribution__left">
							<div className="jobDistribution__row">Условные обозначения</div>
                            <div className="jobDistribution__row">
								<i className="rnis-icon rnis-icon_add-once" />
                                Добавить участок в раскрытое задание РНИС
                            </div>
                            <div className="jobDistribution__row">
                                <i className="rnis-icon rnis-icon_remove-once" />
                                Убрать участок из задания РНИС
                            </div>
                            <div className="jobDistribution__row">
                                <i className="rnis-icon rnis-icon_add-all" />
                                Добавить все участки в раскрытое задание РНИС
                            </div>
                            <div className="jobDistribution__row">
                                <i className="rnis-icon rnis-icon_remove-all" />
                                Убрать все участки из задания РНИС
                            </div>
                            <div className="jobDistribution__row">
                                <i className="rnis-icon rnis-icon_plus-filled-small" />
                                Создать новое задание РНИС
                            </div>
                            <div className="jobDistribution__row">
                                <i className="rnis-icon rnis-icon_arrow-up" />
                                Раскрыть задание для отображения участков работ
                            </div>
                            <div className="jobDistribution__row">
                                <i className="rnis-icon rnis-icon_arrow-down" />
                                Скрыть задание для отображения участков работ
                            </div>
                        </div>
                        <div className="jobDistribution__right">
                            <div className="jobDistribution__row">&nbsp;</div>
                            <div className="jobDistribution__row">
                                <i className="rnis-icon rnis-icon_check" />
                                Выполненный участок работ подтвержден и задание РНИС в статусе "Закрыто"
                            </div>
                            <div className="jobDistribution__row">
                                <i className="rnis-icon rnis-icon_uncheck" />
                                Участок не распределен
                            </div>
                            <div className="jobDistribution__row">
                                <i className="rnis-icon rnis-icon_time-at-work" />
                                Участок распределен и находится в статусе задания РНИС "В работе" или "На обработке"
                            </div>
                            <div className="jobDistribution__row">
                                <i className="rnis-icon rnis-icon_open-link" />
                                Участок распределен и находится в статусе задания РНИС "Открыто" или "Черновик"
                            </div>
                            <div className="jobDistribution__row">
                                <i className="rnis-icon rnis-icon_no-done" />
                                Невыполненный участок работ и задание РНИС в статусе "Закрыто"
                            </div>
                            <div className="jobDistribution__row jobDistribution__row_outer-indent">
                                <i className="rnis-icon rnis-icon_edit" />
                                Перейти в редактирование задания РНИС
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }

    renderSelectWorkTypeLogicView() {
		if (window.RNIS_SETTINGS.work_type_filter_skpdi) {
			if (this.state.work_types.length && this.state.skpdi_tasksUnique.length) {
				return [
					<div className="top-menu__item__text">Вид работ (СКПДИ):</div>,
					<SelectAsync
						key="work_types"
						value={this.state.filters.work_type}
						options={this.state.select_workType ? this.state.select_workType : []}
						onChange={this.onFilterChange.bind(this, 'work_type')}
						loadOptions={::this.loadWorkTypesDebounce}
						className="SelectWorkTypes"
					/>
				];
			} else {
				return [
					<div className="top-menu__item__text">Вид работ (СКПДИ):</div>,
					<Select key="work_types" value={null} className="SelectWorkTypes_disable" />
				];
			}
		} else {
			if (this.state.work_types.length) {
				return [
					<div className="top-menu__item__text">Вид работ:</div>,
					<SelectAsync
						key="work_types"
						value={this.state.filters.work_type}
						options={this.state.select_workType ? this.state.select_workType : []}
						onChange={this.onFilterChange.bind(this, 'work_type')}
						loadOptions={::this.loadWorkTypesDebounce}
						className="SelectWorkTypes"
					/>
				];
			} else {
				return [
					<div className="top-menu__item__text">Вид работ:</div>,
					<Select key="work_types" value={null} className="SelectWorkTypes_disable" />
				];
			}
		}
	}

    async toggleContract(uuid) {
        let filters = this.state.filters;

        const index = _.indexOf(filters.contract, uuid);
        if (index !== -1) {
            filters.contract.splice(index, 1);
        } else {
            filters.contract.push(uuid);
        }

        await this.setState({
            filters
        });

        this.reload();
        this.saveFilters();
    }

    async onFilterChange(type, e) {
		const value = e ? e.value : null;
		await this.setState({
			skpdi_page: 1,
			rnis_page: 1,
		})

		let filters = this.state.filters;
		filters[type] = value;
		if (type === 'unit') {
			filters.contract = [];
		}
		await this.setState({
			filters
		});


		if (type === 'status') {
			this.rnisReloadDebounce();
		} else if (type === 'skpdi_status') {
			this.skpdiReloadDebounce();
		} else {
			if (type === 'work_type' && value) {
				// попросили отключить загрузку заданий РНИС при смене фильтра типа работ
				this.reload(false);
			} else {
				this.reload();
			}

		}
		this.saveFilters();
	}

	renderHeaderActions() {
		return [
			<div key="kurs.skpdi-assign">
				<ContextTooltip code="kurs.skpdi-assign" default="Легенда" position="left">
					<IconButton icon="eye" onClick={::this.toggleLegend} />
				</ContextTooltip>
				{this.state.legendActive ? this.renderLegend() : null}
			</div>,
			...(window.RNIS_SETTINGS.road_skpdi_road_part_filters ? ([
				<div className="SelectFieldsPopup SearchFieldHeader">
					<div className="filtration__search">
						<input
							type="text"
							value={this.state.filters.skpdi_road_part}
							className="filtration__pole"
							placeholder="Поиск"
							onChange={this.onSearchChange.bind(this, 'skpdi_road_part')}
						/>
						<input type="button" className="filtration__button" value="" />
					</div>
				</div>]) : []),
			 ...(window.RNIS_SETTINGS.road_skpdi_work_type_filters ? this.renderSelectWorkTypeLogicView() : []),

			<Select
				key="unit"
				options={this.state.units}
				value={this.state.filters.unit}
				onChange={this.onFilterChange.bind(this, 'unit')}
			/>,
			<div key="contract" className="top-menu__item">
				<CheckboxDropdown
					items={_.filter(this.state.contracts, { implementer_uuid: this.state.filters.unit })}
					selectedItems={_.mapValues(
						_.mapKeys(this.state.filters.contract, (uuid) => {
							return uuid;
						}),
						(item) => true
					)}
					toggleSelectedItem={::this.toggleContract}
					contextKey="kurs.skpdi.contracts"
					contextDefault={`Выбор ${window.RNIS_SETTINGS.rename_contracts ? 'подрядных обязательств' : 'контрактов'}`}
					icon="select"
				/>
			</div>,
			<div key="diapason">
				<Datepicker style="dark" value={this.state.filters.date} onChange={this.dateChange} />
			</div>
		];
	}

    saveFilters() {
        storage.save('road:skpdi:filter', this.state.filters);
    }

    render() {
        const roadTitle = _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text;
		return (
			<Page
				pageId="jobDistribution"
				title={`${roadTitle} → Распределение работ ${window.RNIS_SETTINGS.SKPDI_title}`}
				headerActions={this.renderHeaderActions()}
				className="control-works-tiles b-tiles"
				rootClassName="skpdi-assign"
			>
				{this.renderContent()}
			</Page>
		);
	}

    async onSearchChange(field, { target: { value } }) {

		await this.setState({ filters: {...this.state.filters, [field]: value}});

		this.saveFilters();

		if( field === 'skpdi_road_part' && value.length <= 2 && value != 0) {
			return
		}

		if (field === 'skpdi_search') {
			this.skpdiReloadDebounce();
		} else if (field === 'skpdi_road_part'){
			this.skpdiReloadDebounceSlowly()
		} else {
			this.rnisReloadDebounce();
		}
	}

    isClosedTask(task) {
		const closedStatusUuid = _.get(_.find(this.state.kurs_task_statuses, { label: 'Закрыт' }), 'value');

        return _.get(task, 'status_uuid') === closedStatusUuid;
    }

    isExpandedRnisClosed() {
        return this.isClosedTask(this.getExpandedRnisTask());
    }


	checkDisabledSelectAllSkpdiTasks = () => {
		return !this.props.expandedRnis || this.isExpandedRnisClosed()
	}

    selectAllSkpdiTasks = () => {
        this.setState({
            selectedSkpdiTasks: this.state.tasks_for_add,
            allSkpdiTasksSelected: true
        })
    }

    unselectAllSkpdiTasks = () => {
        this.setState({
            selectedSkpdiTasks: [],
            allSkpdiTasksSelected: false
        })
    }

	checkDisabledSkpdiBatch = () => {
		return this.state.selectedSkpdiTasks.length === 0 || !this.props.expandedRnis || this.isExpandedRnisClosed();
	}

    renderContent() {
		const skpdiLoader = this.state.skpdi_loading ? <GlobalLoaderComponent /> : null;
		const rnisLoader = this.state.rnis_loading ? <GlobalLoaderComponent /> : null;
		const loader = this.state.saving ? <GlobalLoaderComponent /> : null;

        const contract = _.first(this.state.filters.contract);
		const collapseButtonText = this.props.collapseMulti ? 'Свернуть все' : 'Развернуть все';

        return (
            <div className="jobDistribution">
                {loader}
                <div className="jobDistribution__item jobDistribution__item_half">
                    {skpdiLoader}
                    <div className="jobDistribution__header">
						<div className="jobDistribution__title rnis-title">{window.RNIS_SETTINGS.SKPDI_title}</div>
                        <div className="jobDistribution__header-right">
							{window.RNIS_SETTINGS.mass_toggle_skdpi_tasks && (
								<Button color="white" size="md" text={collapseButtonText} onClick={::this.handleChangeCollapseMethod} />
							)}
                            {window.RNIS_SETTINGS.skpdi_batch ? (
                                <button
                                    className='b-button b-button_red b-button_size_md'
                                    onClick={this.selectAllSkpdiTasks}
									disabled={this.checkDisabledSelectAllSkpdiTasks()}
                                >
                                    Выделить все
                                </button>
                            ) : null}
                            {window.RNIS_SETTINGS.skpdi_batch ? (
                                <button
                                    className='b-button b-button_red b-button_size_md'
                                    onClick={this.unselectAllSkpdiTasks}
									disabled={this.state.selectedSkpdiTasks.length === 0}
                                >
                                    Снять выделение
                                </button>
                            ) : null}

                            <div className="SelectFieldsPopup">
                                <div className="filtration__search">
									<input
										type="text"
										value={this.state.filters.skpdi_search}
										className="filtration__pole"
										placeholder="Поиск"
										onChange={this.onSearchChange.bind(this, 'skpdi_search')}
									/>
									<input type="button" className="filtration__button" value="" />
                                </div>
                            </div>
                            <Select
                                options={_.map(this.skpdiStatuses, (label, value) => ({
                                    value,
                                    label
                                }))}
                                value={this.state.filters.skpdi_status}
                                onChange={this.onFilterChange.bind(this, 'skpdi_status')}
                                placeholder="Статус"
                            />
                        </div>
                    </div>
                    <div className="jobDistribution__body">
						<Accordion>{_.map(this.state.skpdi_tasks, ::this.renderSkpdiTask)}</Accordion>
                    </div>
                    {this.renderPages('skpdi_page', 'skpdi_pages', 'skpdi_total')}
                </div>
                <div className="jobDistribution__item jobDistribution__item_half jobDistribution__item_detailed">
                    {rnisLoader}
                    <div className="jobDistribution__header">
						<div className="jobDistribution__title rnis-title">РНИС</div>
                        <div className="jobDistribution__header-right">
                            <div className="SelectFieldsPopup">
                                <div className="filtration__search">
									<input
										type="text"
										value={this.state.filters.rnis_search}
										className="filtration__pole"
										placeholder="Поиск"
										onChange={this.onSearchChange.bind(this, 'rnis_search')}
									/>
									<input type="button" className="filtration__button" value="" />
                                </div>
                            </div>
                            <Select
                                options={this.state.kurs_task_statuses}
                                value={this.state.filters.status}
                                onChange={this.onFilterChange.bind(this, 'status')}
                                placeholder="Статус"
                            />
                            {contract ? (
                                <Link
                                    to={`/road/tasks/create?fromSkpdi=1&contractUuid=${contract}&unitUuid=${this.state.filters.unit}`}
                                    className="rnis-icon rnis-icon_plus-filled"
								/>
                            ) : null}
                        </div>

                    </div>
                    <div className="jobDistribution__body">
						<Accordion>{_.map(this.state.rnis_tasks, ::this.renderRnisTask)}</Accordion>
                    </div>
                    {this.renderPages('rnis_page', 'rnis_pages', 'rnis_total')}
                </div>
            </div>
        );
    }

	getTotalTaskCountForSelect = () => {
		return this.state.tasks_for_add.length;
	}

    renderPages(pageField, pagesField, totalField) {
        const { allSkpdiTasksSelected, selectedSkpdiTasks } = this.state;

        const total = this.state[totalField];
		const totalForAdd = this.getTotalTaskCountForSelect();
        const currentPage = this.state[pageField];
        const pages = _.filter(_.range(1, this.state[pagesField] + 1), (page) => {
            return Math.abs(page - currentPage) <= 3;
        });
        const selectedTasksCount = allSkpdiTasksSelected ? totalForAdd : selectedSkpdiTasks.length;

        return (
			<div className="dataTables__footer">
				<div className="dataTables_paginate paging_simple_numbers">
					<span>
						<span className="rows-total">Всего записей: {total}</span>
						{_.map(pages, (page) => {
							return (
								<a
									key={page}
									href="javascript:void(0)"
									onClick={this.setCurrentPage.bind(this, pageField, page)}
									className={classNames('paginate_button', page === this.state[pageField] ? 'current' : '')}
								>
									{page}
								</a>
							);
						})}
					</span>
				</div>
				{window.RNIS_SETTINGS.skpdi_batch && pageField === 'skpdi_page' ? (
					<div className="dataTables__footer_batch">
						<Button
							size="md"
							color="red"
							shadow="red"
							text="Распределить"
							disabled={this.checkDisabledSkpdiBatch()}
							onClick={this.addSelectedSkpdiTasks}
						/>
						<div>Выделено {selectedTasksCount} задач(и) из {totalForAdd} нераспределенных</div>
					</div>
				) : null}
			</div>
        );
    }

    async setCurrentPage(pageField, page) {

        let state = this.state;
        state[pageField] = page;
        await this.setState(state);

        if (pageField === 'skpdi_page') {
            this.loadSkpdiTasks();
        } else {
            this.loadRnisTasks();
        }
    }

    async toggle(field, task) {
        const {uuid, external_id} = task;

        let state = this.state;
        state[field] = (state[field] === uuid) ? null : uuid;
        await this.setState(state);

        this.loadRelated([task]);
        if (field === 'expandedSkpdi') {
            this.loadSkpdiRelatedTasks();
        }
    }

    getSkpdiRelatedTaskItem(skpdiTask, skpdiItem) {
		let result = [];

		_.each(this.state.skpdi_related_tasks, (task) => {
			_.each(task.items_fact || task.items, (item) => {
				if (
					item.external_id === skpdiTask.external_id &&
					_.get(_.first(item.geometry), 'item_uuid') === _.get(_.first(skpdiItem.geometry), 'item_uuid') &&
					_.get(_.first(item.geometry), 'direction') === _.get(_.first(skpdiItem.geometry), 'direction')
				) {
					result.push({
						task,
						item
					});
				}
			});
		});

		return result;
	}

    getSkpdiTaskItemStatus(skpdiTask, skpdiItem) {
		const related = _.first(this.getSkpdiRelatedTaskItem(skpdiTask, skpdiItem));
		if (!related) {
			return 'none';
		}
		const { task, item } = related;

		const taskStatus = _.get(_.find(this.state.kurs_task_statuses, { value: task.status_uuid }), 'label');
		if (_.indexOf([ 'Черновик', 'Открыт' ], taskStatus) !== -1) {
			return 'draft';
		}

		if (_.indexOf([ 'В работе', 'На рассмотрении' ], taskStatus) !== -1) {
			return 'in-work';
		}

		if (item.is_confirmed) {
			return 'confirmed';
		}

		return 'not-confirmed';
	}

    addTaskItem(skpdiTask, skpdiItem) {
        let task = this.getExpandedRnisTask();

        const contractUuid = _.get(_.first(skpdiTask.contracts), 'uuid');
        if (_.indexOf(_.map(task.contracts, 'uuid'), contractUuid) === -1) {
            task.contracts.push({
                uuid: contractUuid
            });
        }

        let item = _.cloneDeep(skpdiItem);
        item.external_id = skpdiTask.external_id;
        task.items.push(item);
        if (task.items_fact) {
            task.items_fact.push(item);
        }

        this.saveTask(task);
    }

    addSkpdiItemsToRnis = (skpdiTask, rnisTask) => {
        // Получаем uuid первого контракта из СКПДИ
        const contractUuid = _.get(_.first(skpdiTask.contracts), 'uuid');

        // Если в контрактах РНИС нет такого uuid, добавляем его
        if (_.indexOf(_.map(rnisTask.contracts, 'uuid'), contractUuid) === -1) {
            rnisTask.contracts.push({
                uuid: contractUuid,
            });
        }

        skpdiTask.items.forEach(skpdiItem => {
            let item = _.cloneDeep(skpdiItem);
            item.external_id = skpdiTask.external_id;

            const relatedTask = this.getSkpdiRelatedTaskItem(skpdiTask, skpdiItem);
            const relatedUuids = _.map(relatedTask, 'task.uuid');

            if (_.indexOf(relatedUuids, this.state.expandedRnis) === -1) {
                rnisTask.items.push(item);
                if (rnisTask.items_fact) {
                    rnisTask.items_fact.push(item);
                }
            }
        });

        return rnisTask;
    }

    addTaskItemsAll = skpdiTask => {
        // let task = this.addSkpdiItemsToRnis(skpdiTask, this.getExpandedRnisTask());
        
		let notMoved = "";
		let task = this.getExpandedRnisTask();

		const contractUuid = _.get(_.first(skpdiTask.contracts), 'uuid');
		if (_.indexOf(_.map(task.contracts, 'uuid'), contractUuid) === -1) {
			task.contracts.push({
				uuid: contractUuid
			});
		}

		_.each(skpdiTask.items, (skpdiItem) => {
			let item = _.cloneDeep(skpdiItem);
			item.external_id = skpdiTask.external_id;

			const relatedTask = this.getSkpdiRelatedTaskItem(skpdiTask, skpdiItem);
			const relatedUuids = _.map(relatedTask, 'task.uuid');

			if (_.indexOf(relatedUuids, this.props.expandedRnis) === -1) {
				task.items.push(item);
				if (task.items_fact) {
					task.items_fact.push(item);
				}
			} else {
				if (notMoved.length) {
					notMoved + ", " + item.external_id
				} else {
					notMoved + item.external_id
				}
			}
		});

		if (notMoved.length > 1) {
			alerts.alert(`Items со следующими external_id не будут перенесены: ${notMoved}`);
		}
		this.saveTask(task, skpdiTask);
    }

    addSelectedSkpdiTasks = () => {
		this.state.selectedSkpdiTasks.forEach(skpdiTask => {
            this.addTaskItemsAll(skpdiTask);
        })
    }

    async removeTaskItem(skpdiTask, skpdiItem, task) {
		task.items = this.filterItems(
			task.items,
			skpdiTask.external_id,
			_.get(_.first(skpdiItem.geometry), 'item_uuid'),
			_.get(_.first(skpdiItem.geometry), 'direction')
		);
		if (task.items_fact) {
			task.items_fact = this.filterItems(
				task.items_fact,
				skpdiTask.external_id,
				_.get(_.first(skpdiItem.geometry), 'item_uuid'),
				_.get(_.first(skpdiItem.geometry), 'direction')
			);
		}

		await this.saveTask(task);

		this.loadRnisTasks();
	}

    filterItems(items, externalId, itemUuid, direction) {
		return _.filter(items, (item) => {
			return !(
				item.external_id === externalId &&
				_.get(_.first(item.geometry), 'item_uuid') === itemUuid &&
				_.get(_.first(item.geometry), 'direction') === direction
			);
		});
	}

    async removeTaskItemsAll(skpdiTask) {
		let tasksToSave = {};

		_.each(skpdiTask.items, (skpdiItem) => {
			_.each(this.getSkpdiRelatedTaskItem(skpdiTask, skpdiItem), ({ task }) => {
				let taskFromQueue = _.get(tasksToSave, task.uuid);
				if (!taskFromQueue) {
					taskFromQueue = task;
				}

				if (this.isClosedTask(taskFromQueue)) {
					return;
				}

				taskFromQueue.items = this.filterItems(
					taskFromQueue.items,
					skpdiTask.external_id,
					_.get(_.first(skpdiItem.geometry), 'item_uuid'),
					_.get(_.first(skpdiItem.geometry), 'direction')
				);
				if (taskFromQueue.items_fact) {
					taskFromQueue.items_fact = this.filterItems(
						taskFromQueue.items_fact,
						skpdiTask.external_id,
						_.get(_.first(skpdiItem.geometry), 'item_uuid')
					);
				}

				tasksToSave[taskFromQueue.uuid] = taskFromQueue;
			});
		});

		await Promise.all(
			_.map(tasksToSave, async (task) => {
				await this.saveTask(task);
			})
		);

		this.loadRnisTasks();
	}

    removeRnisTaskItem(task, index) {
        task.items.splice(index, 1);

        this.saveTask(task);
    }

    async saveTask(task, expandedSkpdiTask = null) {
		this.setState({ saving: true });

		task.without_sync = true;
		const response = await this.props.updateTask(task);

		if (response.isOk) {
			setTimeout(() => {
				this.loadSkpdiTasks(true);
			}, 1000);
			await Promise.all([ this.loadSkpdiRelatedTasks(), this.loadExpandedRnisTask() ]);
			this.setState({ saving: false });
		} else {
			this.setState({ saving: false });
			response.showErrors();
		}
	}

    toggleSkpdiTaskForAdd = skpdiTask => {
        const { selectedSkpdiTasks = [] } = this.state;
        const index = selectedSkpdiTasks.findIndex(({ uuid }) => skpdiTask.uuid === uuid);

        if (index === -1) {
			const newSelected = [].concat(selectedSkpdiTasks);
			newSelected.push(skpdiTask);
            this.setState({
                selectedSkpdiTasks: newSelected,
                allSkpdiTasksSelected: false
            })
        } else {
			const newSelected = [].concat(selectedSkpdiTasks);
			newSelected.splice(index, 1);
            this.setState({
                selectedSkpdiTasks: newSelected,
            })
        }
    }

	toggleSkpdiTask = (task) => {
		if (!this.props.collapseMulti) {
			// only one task can be opened at a time
			if (this.props.expandedSkpdi.includes(task.uuid)) {
				this.props.setExpandedSkpdi([]);
			} else {
				this.props.setExpandedSkpdi([ task.uuid ]);
			}
			this.loadRelated([ task ]);
		} else {
			// all tasks can be opened
			if (this.props.expandedSkpdi.includes(task.uuid)) {
				this.props.setExpandedSkpdi(this.props.expandedSkpdi.filter((el) => el !== task.uuid));
			} else {
				this.props.setExpandedSkpdi([ ...this.state.expandedSkpdi, task.uuid ]);
			}
		}
	};

    skpdiIsSelected = skpdiUuid => {
        return !!this.state.selectedSkpdiTasks.find(({ uuid }) => uuid === skpdiUuid);
    }


	loadSkpdiTasksForAdd = async (task) => {
		let filters = {
            withPeriod: [
				moment(this.state.filters.date).startOf('day').format(formats.DATE_API),
				moment(this.state.filters.date).endOf('day').format(formats.DATE_API)
			]
        };

		const unitUuid = _.find(this.state.rnis_tasks, {uuid: task }).unit_uuid;
		filters.withUnits = [unitUuid];
        if (this.state.filters.contract && this.state.filters.contract.length > 0) {
            filters.withContract = this.state.filters.contract;
        }
		filters.withSkpdiStatus = 'none'
        if (this.state.filters.work_type) {
			filters.withWorkTypes = [ this.state.filters.work_type ];
        }
		if (this.state.filters.skpdi_road_part) {
			filters.withRoadPartName = this.state.filters.skpdi_road_part;
		}

        filters.onlyFromSkpdi = 1;

		const filtersPartial = Object.assign({}, filters);
		filtersPartial.withSkpdiStatus = 'partial'

		const response = await Promise.all([
			this.props.getTasks({
				filters,
				search: this.state.filters.skpdi_search || null,
				pagination: {
					page: 1,
					limit: 100000,
				}
			}),
			this.props.getTasks({
				filters: filtersPartial,
				search: this.state.filters.skpdi_search || null,
				pagination: {
					page: 1,
					limit: 100000,
				}
			})
		]);

        if (response[0].isOk && response[1].isOk) {
			this.setState({
				tasks_for_add:
					[].concat(response[0].payload.items, response[1].payload.items),
			})
		} else {
			response[0].showErrors();
		}
	}

	toggleRnisTask = (task) => { 
		if (task === this.props.expandedRnis) {
			this.props.setExpandedRnis(null);
		} else {
			if (!this.isExpandedRnisClosed()) {
				this.loadSkpdiTasksForAdd(task);
			} else {
				this.setState({
					tasks_for_add: [],
				})
			}

			this.props.setExpandedRnis(task);
		}
	};

	handleChangeCollapseMethod() {
		if (!this.props.collapseMulti) {
			// if change collapseMulti to true, toggle all tasks open
			this.props.setExpandedSkpdi(this.state.skpdi_tasks.map((el) => el.uuid));
		} else {
			// if change collapseMulti to false, toggle all tasks close
			this.props.setExpandedSkpdi([]);
		}
		this.props.setCollapseMulti(!this.props.collapseMulti);
	}

    renderSkpdiTask(task) {
        const shouldRenderAddSkpdiBtn = (
            this.props.expandedRnis &&
            this.isExpandedRnisUnitEqualTo(task) &&
            !this.isExpandedRnisClosed()
        )

        const shouldRenderCheckboxForAdd = (
            window.RNIS_SETTINGS.skpdi_batch &&
            ['none', 'partial'].includes(task.skpdi_status) &&
            shouldRenderAddSkpdiBtn
        );

		return (
			<div className="accordion__item" key={task.uuid}>
				<input
					type="checkbox"
					className="accordion__open"
					value="on"
					defaultChecked={this.props.expandedSkpdi.includes(task.uuid) ? '' : 'false'}
					disabled=""
					onClick={() => this.toggleSkpdiTask(task)}
				/>
				<i className="accordion__arrow" />
				<div className="accordion__title">
					<span>
						№{task.external_id} {_.get(_.find(this.state.units, { value: task.unit_uuid }), 'label')}
					</span>
					<p
						style={{
							'paddingTop': '21px',
							'fontSize': '10px',
							'color': 'gray',
							'fontStyle': 'italic',
							'fontWeight': 400
						}}
					>
						{task.items[0] ? (
							_.get(_.find(this.state.work_types, { value: task.items[0].work_type_uuid }), 'label')
						) : (
							''
						)}
					</p>
				</div>
				<div className="accordion__menu accordion__menu_right">
					{shouldRenderCheckboxForAdd ? (
                        <LabeledCheckbox
                            label=""
                            checked={this.skpdiIsSelected(task.uuid)}
                            onChange={() => this.toggleSkpdiTaskForAdd(task)}
                        />
                    ) : null}
					{shouldRenderAddSkpdiBtn ? (
                        <button className="rnis-icon rnis-icon_add-all"
                                onClick={() => this.addTaskItemsAll(task)}/>
                    ) : null}
					<div>{this.skpdiStatuses[task.skpdi_status]}</div>
					{task.skpdi_status !== 'none' && this.props.expandedSkpdi.includes(task.uuid) ? (
						<button className="rnis-icon rnis-icon_remove-all" onClick={this.removeTaskItemsAll.bind(this, task)} />
					) : null}
					{this.props.expandedRnis && this.isExpandedRnisUnitEqualTo(task) && !this.isExpandedRnisClosed() ? (
						<button className="rnis-icon rnis-icon_add-all" onClick={this.addTaskItemsAll.bind(this, task)} />
					) : null}
				</div>
				{this.props.expandedSkpdi.includes(task.uuid) && (
					<div className="accordion__content">
						<div className="accordion__posit accordion__posit_top-bord">
							{_.map(task.items, this.renderSkpdiTaskItem.bind(this, task))}
						</div>
					</div>
				)}
			</div>
		);
	}

    toggleRelated() {
        this.setState({
            relatedExpanded: !this.state.relatedExpanded
        });
    }

    renderSkpdiTaskItem(task, item, index) {
		const { geometry, geometry_type, work_type_uuid } = item;

		if (geometry_type !== 'road_part') {
			return null;
		}

		const { item_uuid, direction } = _.first(geometry);

		const relatedTask = this.getSkpdiRelatedTaskItem(task, item);
		const relatedUuids = _.map(relatedTask, 'task.uuid');
		const status = this.getSkpdiTaskItemStatus(task, item);

		return (
			<div key={index} className="jobDistribution__row">
				<div className="jobDistribution__left">
					<a className="jobDistribution__area">Участок {item.geometry[0].item_name}</a>
					<div className="jobDistribution__dir">Направление {direction === 'forward' ? 'прямое' : 'обратное'}</div>
				</div>
				<div className="jobDistribution__right">
					<div className="jobDistribution__type-of-work">
						Вид работ:<br />
						{_.get(_.find(this.state.work_types, { value: work_type_uuid }), 'label')}
					</div>
					<div className={`rnis-icon jobDistribution__state rnis-icon_${this.statuses[status]}`} />

					{status !== 'none' && relatedTask.length > 0 ? (
						<div className="jobDistribution__task-list-wrap">
							<div
								className={classNames('jobDistribution__task-list', {
									'jobDistribution__task-list_multiple': relatedTask.length > 1,
									expand: this.state.relatedExpanded
								})}
							>
								{status !== 'none' ? (
									_.map(relatedTask, (related, index) => {
										const rnisTask = related.task;

										return (
											<div className="jobDistribution__task-item" key={`${rnisTask.number}:${index}`}>
												{!this.isClosedTask(rnisTask) ? (
													<button
														className="rnis-icon rnis-icon_remove-once"
														onClick={this.removeTaskItem.bind(this, task, item, rnisTask)}
													/>
												) : null}
												<div className="jobDistribution__task">РНИС {rnisTask.number}</div>
											</div>
										);
									})
								) : null}
							</div>
							<a className="rnis-icon rnis-icon_small-arrow" onClick={::this.toggleRelated} />
						</div>
					) : null}

					{_.indexOf(relatedUuids, this.props.expandedRnis) === -1 &&
					this.props.expandedRnis &&
					this.isEqualUnitsInBothParts() &&
					!this.isExpandedRnisClosed() ? (
						<button className="rnis-icon rnis-icon_add-once" onClick={this.addTaskItem.bind(this, task, item)} />
					) : null}
				</div>
			</div>
		);
	}

    isEqualUnitsInBothParts() {
        return _.get(this.getExpandedRnisTask(), 'unit_uuid') === _.get(this.getExpandedSkpdiTask(), 'unit_uuid');
    }

    isExpandedRnisUnitEqualTo(task) {
        return _.get(this.getExpandedRnisTask(), 'unit_uuid') === _.get(task, 'unit_uuid');
    }

    getExpandedRnisTask() {
        return _.find(this.state.rnis_tasks, {uuid: this.props.expandedRnis});
    }

    getExpandedSkpdiTask() {
        return _.find(this.state.skpdi_tasks, { uuid: this.props.expandedSkpdi[0] });
    }

    renderRnisTask(task) {
		const status = _.get(_.find(this.state.kurs_task_statuses, { value: task.status_uuid }), 'label');

		return (
			<div className="accordion__item" key={task.uuid}>
				<input
					type="checkbox"
					className="accordion__open"
					value="on"
					defaultChecked={this.props.expandedRnis === task.uuid ? '' : 'false'}
					disabled=""
					onClick={() => this.toggleRnisTask(task.uuid)}
				/>
				<i className="accordion__arrow" />
				<div className="accordion__title">
					<div className="accordion__title-number">
						№{task.number} {_.get(_.find(this.state.units, { value: task.unit_uuid }), 'label')}
					</div>
					{window.RNIS_SETTINGS.show_vehicle_numbers_rnis_tasks && (
						<div className="accordion__title-vehicle-numbers">
							{task.resources.length &&
								task.resources.map((el, index) => (
									<div key={el.vehicle_state_number + index} className="accordion__title-vehicle-numbers-item">{el.vehicle_state_number}</div>
								))}
						</div>
					)}
				</div>
				<div className="accordion__menu accordion__menu_right">
					<div>{status}</div>
					<Link to={`/road/tasks/${task.uuid}?fromSkpdi=1`} className="rnis-icon rnis-icon_edit" />
				</div>
				{this.props.expandedRnis === task.uuid && (
					<div className="accordion__content">
						<div className="accordion__posit accordion__posit_top-bord">
							{_.map(task.items, this.renderRnisTaskItem.bind(this, task))}
						</div>
					</div>
				)}
			</div>
		);
	}

    renderRnisTaskItem(task, item, index) {
		const { geometry, geometry_type, work_type_uuid } = item;

		if (geometry_type !== 'road_part') {
			return null;
		}

		const { item_uuid, direction } = _.first(geometry);

		return (
			<div key={index} className="jobDistribution__row">
				<div className="jobDistribution__left">
					<a className="jobDistribution__area">Участок {this.state.related.getReact(item_uuid)}</a>
					<div className="jobDistribution__type-of-work-wrap">
						<div className="jobDistribution__type-of-work">
							Вид работ:<br />
							{_.get(_.find(this.state.work_types, { value: work_type_uuid }), 'label')}
						</div>
					</div>
					<div className="jobDistribution__dir">Направление {direction === 'forward' ? 'прямое' : 'обратное'}</div>
				</div>
				{item.external_id ? (
					<div className="jobDistribution__right">
						<div className="jobDistribution__task-item">
							{!this.isClosedTask(task) ? (
								<button
									className="rnis-icon rnis-icon_remove-once"
									onClick={this.removeRnisTaskItem.bind(this, task, index)}
								/>
							) : null}
							<div className="jobDistribution__task">СКДИ № {item.external_id}</div>
						</div>
					</div>
				) : null}
			</div>
		);
	}

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
		this.setState({ dictionariesLoading: true });
		let meta = {
			filters: {
				withComponent: component
			}
		};
		if (!withoutOrder) {
			meta.order = {
				column: 'name',
				direction: 'asc'
			};
		}
		const response = await this.props.getDictionaryList(dictionaries, meta);
		this.setState({ dictionariesLoading: false });
		if (response.isOk) {
			let state = this.state;
			_.each(response.payload.items, (item) => {
				state[item.key] = _.map(item.documents, (document) => ({
					value: document.uuid,
					label: document.short_name || document.name,
					document
				}));
			});
			this.setState(state);
		} else {
			response.showErrors();
		}
	}
}
