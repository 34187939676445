import React from 'react';
import IconButton from 'components/ui/icon-button';
import ContextTooltip from 'components/ui/context-tooltip';

export const FilterButtonWithTooltip = ({ tooltipText, onOpenFilter, icon }) => {
	return tooltipText ? (
		<ContextTooltip key={tooltipText} code={tooltipText} default={tooltipText}>
			<IconButton icon={icon ? icon : 'legend'} onClick={onOpenFilter} />
		</ContextTooltip>
	) : (
		<IconButton icon={icon ? icon : 'legend'} onClick={onOpenFilter} />
	);
};
