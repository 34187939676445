import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import L from 'leaflet';

import PageModal from 'components/ui/page-modal';


import MapComponent from "components/ui/map";

export default class TerritorialEntitiesMap extends Component {

    layer = null;
    editLayer = null;

    componentDidMount() {
        if (!this.refs.map) return;

        setTimeout(::this.init, 500);
    }

    init() {
        this.layer = L.layerGroup();
        this.refs.map.getWrappedInstance().map.addLayer(this.layer);

        const layerGeometry = this.props.callback();

        if (layerGeometry) {
            const geometry = {
                type: 'Feature',
                geometry: layerGeometry,
            };
            this.editLayer = new L.GeoJSON(geometry);
            this.layer.addLayer(this.editLayer);

            this.editLayer.pm.enable();

            this.editLayer.on('pm:edit', (e) => {
                const geometry = this.editLayer.toGeoJSON().features[0].geometry;
                this.props.callback(geometry, false);
            });
        } else {
            this.refs.map.getWrappedInstance().map.pm.enableDraw('Poly');

            this.refs.map.getWrappedInstance().map.on('pm:create', (e) => {
                let layer = e.layer;
                const geometry = layer.toGeoJSON().geometry;

                this.props.callback(geometry, true);
            });
        }
    }

    getDefaultPolygon() {
        const bounds = this.refs.map.getWrappedInstance().map.getBounds().pad(-0.1);

        return {
            type: 'Polygon',
            coordinates: [
                [
                    bounds.getEast(),
                    bounds.getNorth(),
                ],
                [
                    bounds.getEast(),
                    bounds.getSouth(),
                ],
                [
                    bounds.getWest(),
                    bounds.getSouth(),
                ],
                [
                    bounds.getWest(),
                    bounds.getNorth(),
                ],
            ],
        };
    }

    getGeometry() {
        const geoJSON = this.editLayer.toGeoJSON();
        return geoJSON.features[0].geometry;
    }

    render() {
        return (
            <div
                className="territorial-entity-map"
            >
                <MapComponent
                    ref="map"
                    showLayers={false}
                />
            </div>
        );
    }

}