import {rpc} from './session';
import _ from 'lodash';

export async function createUser(data) {
    return await rpc.request('com.rnis.auth.action.user.create', data);
}

export async function updateUser(data) {
    return await rpc.request('com.rnis.auth.action.user.update', data);
}

export async function deleteUser(data) {
    return await rpc.request('com.rnis.auth.action.user.delete', data);
}

export async function getUser(uuid, meta = {}) {
    return await rpc.request('com.rnis.auth.action.user.get', {user_id: uuid}, {meta});
}

export async function getCleanupSpecialists(meta = {}) {
    return await rpc.request('com.rnis.kurs.action.task.cleanup_specialists.free.list', {}, { meta });
}

export async function refreshUser(uuid) {
    return await rpc.request('com.rnis.auth.action.user.refresh', {user_id: uuid});
}

export async function getUsers(meta = {}) {
    return await rpc.request('com.rnis.auth.action.user.list', {}, {meta});
}

export async function getUsersByUnits(units, meta = {}) {
    return await rpc.request('com.rnis.auth.action.user.by_units.list', {units}, {meta});
}

export async function getUserTachographs(user_id, meta = {}) {
    return await rpc.request('com.rnis.auth.action.user.tachographs', {user_id}, {meta});
}

export async function getUserEducations(user_id, meta = {}) {
    return await rpc.request('com.rnis.auth.action.user.educations', {user_id}, {meta});
}

export async function createUserEducation(data) {
    return await rpc.request('com.rnis.auth.action.user.education.create', data);
}

export async function updateUserEducation(data) {
    return await rpc.request('com.rnis.auth.action.user.education.update', data);
}

export async function deleteUserEducation(data) {
    return await rpc.request('com.rnis.auth.action.user.education.delete', data);
}

export async function getUserPositions(user_id, meta = {}) {
    return await rpc.request('com.rnis.auth.action.user.positions', {user_id}, {meta});
}

export async function createUserPosition(data) {
    return await rpc.request('com.rnis.auth.action.user.position.create', data);
}

export async function updateUserPosition(data) {
    return await rpc.request('com.rnis.auth.action.user.position.update', data);
}

export async function deleteUserPosition(data) {
    return await rpc.request('com.rnis.auth.action.user.position.delete', data);
}

let permissions;

export async function getPermissions() {
    if (permissions) {
        return permissions;
    }
    permissions = await rpc.request('com.rnis.auth.action.permission.list');
    return permissions;
}

export async function getUserCan(code, grant, unitUuid) {
    return await rpc.request('com.rnis.auth.action.user.can', {code, grant, unit_uuid: unitUuid});
}

export async function getUserPermissions(userId = null, code = null, grant = null) {
    return await rpc.request('com.rnis.auth.action.user.permissions', {user_id: userId, code, grant});
}

export async function getRoles(meta = {}) {
    return await rpc.request('com.rnis.auth.action.role.list', {}, {meta});
}

export async function createRole(data) {
    return await rpc.request('com.rnis.auth.action.role.create', data);
}

export async function updateRole(data) {
    return await rpc.request('com.rnis.auth.action.role.update', data);
}

export async function deleteRole(data) {
    return await rpc.request('com.rnis.auth.action.role.delete', data);
}

export async function getUnitPermissions(baseUnitUuid) {
    const payload = _.isArray(baseUnitUuid) ? {
        units: baseUnitUuid,
    } : {
        unit_uuid: baseUnitUuid,
    };

    return await rpc.request('com.rnis.auth.action.unit.permissions.list', payload);
}

export async function updateUnitPermission(data) {
    return await rpc.request('com.rnis.auth.action.unit.permissions.update', data);
}
