import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getRetrospective = (data) => async (dispatch) => {
    try {
        const response = await api.monitoring.getRetrospective(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const monitoringFilterVehicles = (data) => async (dispatch) => {
    try {
        const response = await api.monitoring.monitoringFilterVehicles(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTripList = (data) => async (dispatch) => {
    try {
        const response = await api.monitoring.getTripList(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getStopList = (data) => async (dispatch) => {
    try {
        const response = await api.monitoring.getStopList(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getParkingList = (data) => async (dispatch) => {
    try {
        const response = await api.monitoring.getParkingList(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
