import React, { Component } from 'react';
import './SelectContainer.less'

export default class SelectContainer extends Component {

  state = {
    isOpened: false,
    selectedValue: this.props.defaultValue || 'round'
  }

  toggleSelect = () => {
    this.setState({isOpened: !this.state.isOpened});
  }

  onSelect = value => () => {
    this.setState({selectedValue: value});
    this.toggleSelect();
    this.props.onChange(this.props.settingsName, value);
  }

  render() {
    const { children } = this.props;
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { onClick: this.onSelect(child.props['data-value']), })
    );

    return (
      <div className="Select Select--single is-clearable is-searchable">
        <div className="Select-control" onClick={this.toggleSelect}>
          <span className="Select-multi-value-wrapper">
              
            <div className="Select-input selected" style={{ display: 'inline-block' }}>
              {
                React.Children.map(children, child => 
                  child.props['data-value'] === this.state.selectedValue ? child : null 
                )
              }
            </div>
          </span>
          <span className="Select-arrow-zone"><span className="Select-arrow"></span></span>
        </div>
        { this.state.isOpened &&
          <div className="Select-menu-outer">
            <div className="Select-menu" id="react-select-9--list" role="listbox">
            {childrenWithProps}
            </div>
          </div>
        }
      </div>
    );
  }
}