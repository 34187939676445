import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import moment from "moment";
import formats from "dictionaries/formats";
import _ from 'lodash';
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import {deleteTaskTemplate, getTaskTemplate, getTaskTemplates} from "store/reducers/commdept/task_templates";
import * as storage from "utils/storage";
import {getUnits} from "store/reducers/organizational_units/organizational_units";

@connect(state => ({}), {getTaskTemplate, getTaskTemplates, deleteTaskTemplate, getEntityNames, getUnits})

export default class CommdeptTaskTemplatesComponent extends BaseTableWithEditorComponent {

    title = `${systems.commdept} → Планирование работ → Шаблоны`;
    baseUrl = '/commdept/task_templates';

    getColumns() {
        return this.prepareColumns([

            new Column('Наименование')
                .fromField('name')
                .withClassName('link'),

            new Column('Предприятие')
                .fromField('task->unit_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.task.unit_uuid && this.state.related.get(item.task.unit_uuid))
                .withFilter('withUnits', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 1000,
                        },
                        filters: {
                            withComponent: 'commdept',
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            new Column(window.RNIS_SETTINGS.rename_contracts ? 'Подрядное обязательство' : 'Контракт')
                .fromField('task->contract_uuid')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => _.map(item.task.contracts || [], (contract) => {
                    return this.state.related.get(contract.uuid);
                }).join('<br/>')),

            new Column('Ответственный')
                .fromField('task->responsive_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.task.responsive_uuid && this.state.related.get(item.task.responsive_uuid)),

            new Column('Дата создания шаблона')
                .fromField('created_at')
                .denyColumnFilter()
                .withDrawer(item => item.created_at && moment(item.created_at).format(formats.DATE)),

            new Column('Карта')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => (
                    `<a href="javascript:void(0)" class="map-link" data-uuid="${_.get(item, 'uuid')}">Карта</a>`
                )),
        ]);
    }

    onInitialized(table) {
        table.on('click', '.map-link', ::this.showMap);
    }

    showMap(e) {
        const uuid = $(e.target).data('uuid');

        this.props.router.push(`/commdept/tasks/create/map?template_uuid=${uuid}`);
    }

    async deleteItem(data) {
        return await this.props.deleteTaskTemplate(data);
    }

    async loadData(meta) {
        return await this.props.getTaskTemplates(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const units = _.map(_.filter(_.map(result, 'task.unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const contracts = _.map(_.filter(_.map(_.flatten(_.map(result, 'task.contracts')), 'uuid')), (uuid) => ({
            class: 'App\\Model\\Contract',
            uuid: uuid,
            source: 'commdept',
        }));

        const responsives = _.map(_.filter(_.map(result, 'task.responsive_uuid')), (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));

        const response = await this.props.getEntityNames(_.concat(units, contracts, responsives));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    getCreateButton() {
        return null;
    }

    async showEditorWithUuid(uuid = null) {
        /*let task = storage.get('kurs-task') || {};
        task.unit_uuid = taskTemplate.unit_uuid;
        task.items = taskTemplate.items;
        storage.set('kurs-task', task);*/
        this.props.router.push(`/commdept/tasks/create?template_uuid=${uuid}`);
    }
}
