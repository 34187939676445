export const getWorkTypesFromKursTasks = (contracts) => {
    const workTypes = [];
    contracts.forEach(contract => {
        if (!contract.works.length) {
            return 
        } else {
            contract.works.forEach(work => {
                workTypes.push(work.work_type_uuid);
            })
        }
    })
    return workTypes;
}