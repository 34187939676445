import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import PageModal from 'components/ui/page-modal';

import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import * as alerts from "helpers/alerts";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {getUnits} from "store/reducers/organizational_units/units";
import {getRegisterRequest, updateRegisterRequest} from "store/reducers/portal/register_requests";
import moment from "moment";
import formats from "dictionaries/formats";
import systems from "dictionaries/systems";
import Button from "components/ui/button";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getTelematicsWithDevices} from "store/reducers/maps";
import {State} from "components/ui/state";
import {getBnsoCheckLink} from "store/reducers/vehicles/bnso";

@propTypes({
    request: PropTypes.object,
    onClose: PropTypes.func,
})

@connect(state => ({}), {getEntityNames, getVehicleList, getDictionaryList, getTelematicsWithDevices, getBnsoCheckLink})

export default class ConfirmRequestVehicles extends BaseEditor {

    state = {
        vehicles: [],
        vehicle_models: [],
        bnso_types: [],
        telematics: {},
    };

    async componentWillMount() {
        this.loadVehicles();
        this.loadDictionaries([
            'vehicle_models',
            'vehicle_types',
            'bnso_types',
        ])
    }

    async loadVehicles() {
        const response = await this.props.getVehicleList({
            filters: {
                withUuid: this.props.request.vehicles,
                withSemiConfirmed: true,
            },
        });

        if (response.isOk) {
            await this.setState({
                vehicles: response.payload.items,
            });

            this.loadTelematics();
        } else {
            response.showErrors();
        }
    }

    async loadTelematics() {
        const devices = _.filter(_.map(this.state.vehicles, 'current_bnso.bnso_number'));
        const response = await this.props.getTelematicsWithDevices(devices, 't1sync', null, null, 'none');

        if (response.isOk) {
            this.setState({
                telematics: _.mapValues(_.keyBy(response.payload.items, 'device_id'), (item) => {
                    return true;
                }),
            });
        }
    }

    getFullTitle() {
        return `Состав ТС заявления №${_.get(this.props.request, 'number', '...')}`;
    }

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        const vehicleTypes = _.mapValues(_.keyBy(_.concat(this.state.vehicle_types, {
            value: undefined,
            label: 'нет типа',
        }), 'value'), 'label');

        const vehicleGroups = _.groupBy(this.state.vehicles, 'vehicle_type_uuid');

        return (
            <div>
                <PageModal
                    header={{title: 'Заявленный автопарк', buttons}}
                    onClose={this.props.onClose}
                    className={`profile-modal b-modal-register-requests-vehicles b-modal-with-x-scroll b-modal_stretch`}
                    withFade={false}
                >

                    <TableContainer>
                        <div className="Table">
                            <table className="b-table">
                                <thead>
                                <tr>
                                    <th rowSpan="2">Гос. номер</th>
                                    <th rowSpan="2">ПТС</th>
                                    <th rowSpan="2">Дата ПТС</th>
                                    <th rowSpan="2">VIN</th>
                                    <th rowSpan="2">Модель, марка</th>
                                    <th rowSpan="2">Год</th>
                                    <th rowSpan="2">Право владения ТС</th>
                                    <th colSpan="4" className="align-center">БНСО</th>
                                </tr>
                                <tr>
                                    <th>Марка, модель</th>
                                    <th>Номер</th>
                                    <th>Телефон</th>
                                    <th>Статус</th>
                                </tr>
                                </thead>
                                <tbody>
                                {_.map(vehicleGroups, (vehicles, group) => {
                                    return _.concat([
                                        <tr key={group}>
                                            <td colSpan="11" className="align-center separator">
                                                {vehicleTypes[group]}
                                            </td>
                                        </tr>
                                    ], vehicles.map(::this.renderVehicle))
                                })}
                                </tbody>
                                <tfoot>
                                <tr className="bold">
                                    <td colSpan="4">Всего ТС:</td>
                                    <td colSpan="3">{this.state.vehicles.length}</td>
                                    <td colSpan="3">БНСО</td>
                                    <td>{this.getActiveBnso()}/{this.getTotalBnso()}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </TableContainer>
                </PageModal>
            </div>
        );
    }

    getTotalBnso() {
        return _.filter(this.state.vehicles, (vehicle) => {
            return _.get(vehicle.current_bnso, 'bnso_number');
        }).length;
    }

    getActiveBnso() {
        return _.filter(this.state.vehicles, (vehicle) => {
            const bnsoNumber = _.get(vehicle.current_bnso, 'bnso_number');
            return this.state.telematics[bnsoNumber] || false;
        }).length;
    }

    renderVehicle(vehicle) {
        const bnsoUuid = _.get(vehicle.current_bnso, 'uuid');
        const bnsoNumber = _.get(vehicle.current_bnso, 'bnso_number');

        return (
            <tr key={vehicle.uuid}>
                <td>{vehicle.state_number}</td>
                <td>{vehicle.pts}
                {vehicle.pts_file ? (
                    <a target="_blank" className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_book" href={vehicle.pts_file}></a>
                ) : null}              
                </td>
                <td>{moment(vehicle.pts_date).format(formats.DATE)}</td>
                <td>{vehicle.vin}</td>
                <td>{_.get(_.find(this.state.vehicle_models, {value: vehicle.vehicle_model_uuid}), 'label', '-')}</td>
                <td>{vehicle.release_year}</td>
                <td>Собственность</td>
                <td>{_.get(_.find(this.state.bnso_types, {value: _.get(vehicle.current_bnso, 'bnso_type')}), 'label', '-')}</td>
                <td>{_.get(vehicle.current_bnso, 'bnso_code')}</td>
                <td>{_.get(vehicle.current_bnso, 'phone_number')}</td>
                <td className="align-center">{bnsoNumber ? (
                    <div className="diagnostics-block">
                        <State positive={this.state.telematics[bnsoNumber] || false}/>
                        <a href="javascript:void(0)" className="btn-diagnostics" onClick={this.gotoDiagnostic.bind(this, bnsoUuid)}></a>
                    </div>
                ) : null}</td>
            </tr>
        );
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }

    async gotoDiagnostic(uuid) {
        const response = await this.props.getBnsoCheckLink(uuid);

        if (response.isOk) {
            const link = response.payload.link;

            const a = document.createElement("a");
            a.setAttribute("href", link);
            a.setAttribute("target", "_blank");
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
            }, 0);
        } else {
            response.showErrors();
        }
    }
}