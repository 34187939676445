import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import _ from 'lodash';
import L from 'leaflet';
import MapComponent from "components/ui/map";
import {connect} from "react-redux";
import {getRoadPart, getRoadParts} from "store/reducers/kurs/road_parts";
import {getDocumentsList} from "store/reducers/reports/reports";
import Page from 'components/ui/page';
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import GlobalLoaderComponent from "components/ui/global-loader";
import DirectionalGeojson from "components/ui/map/objects/directional-geojson";

import DirectionalPolyline from "components/ui/map/objects/directional-polyline";
import MapGeojson from "components/ui/map/base/geojson";
import PageModalComponent from "components/ui/page-modal";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";

@connect(state => ({}), {getRoadParts}, null, {withRef: true})

export default class RoadPartsSelect extends Component {

    map = null;
    boundsLayer = null;

    state = {
        loading: false,
        roadParts: [],
    };

    render() {
        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;

        const buttons = (
            <ModalTopMenuList>
                <ContextTooltip default="Выбрать">
                    <ModalTopMenuButton
                        className="_save"
                        title="Выбрать"
                        onClick={::this.submit}
                    />
                </ContextTooltip>
                <ModalTopMenuButtonsSeparator key="save-button-separator"/>
                <ModalTopMenuButton
                    key="close"
                    className="_close"
                    tooltip="Отменить"
                    onClick={this.props.onClose}
                />
            </ModalTopMenuList>
        );

        return (
            <PageModalComponent
                header={{
                    title: 'Выбор участков работ',
                    buttons,
                }}
                onClose={this.props.onClose}
                withFade>
                {loader}
                {this.renderContent()}
            </PageModalComponent>
        );
    }

    async submit() {
        await this.loadRoadParts();

        this.props.onSubmit(_.map(this.state.roadParts, (roadPart) => ({
            value: roadPart.uuid,
            label: roadPart.name,
        })));

        this.props.onClose();
    }

    async loadRoadParts() {
        this.setState({loading: true});

        const geoJSON = this.boundsLayer.toGeoJSON();
        const geometry = geoJSON.geometry;

        const response = await this.props.getRoadParts({
            filters: {
                withBoundingBoxGeojson: geometry,
            },
        });

        this.setState({loading: false});

        if (response.isOk) {
            await this.setState({
                roadParts: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    init() {
        const map = this.map.getWrappedInstance().map;
        const bounds = map.getBounds().pad(-0.1);
        this.boundsLayer = new L.polygon([
            bounds.getSouthWest(),
            bounds.getNorthWest(),
            bounds.getNorthEast(),
            bounds.getSouthEast(),
        ]);
        this.boundsLayer.addTo(map);
        this.boundsLayer.pm.enable({
            draggable: true,
            preventVertexEdit: true,
        });
    }

    renderContent() {
        return (
            <MapComponent
                ref={(map) => {
                    if (!this.map) {
                        this.map = map;
                        this.init();
                    }
                }}
            >
                {this.map ? this.renderMapContents() : null}
            </MapComponent>
        );
    }

    renderMapContents() {
        return null;
        //return _.map(this.state.road_parts, ::this.renderRoadPart);
    }

    renderRoadPart(roadPart) {
        return (
            <MapGeojson
                key={roadPart.uuid}
                map={this.map}
                leafletMap={this.map.getWrappedInstance().map}
                geometry={roadPart.geometry}
                tooltip={`${roadPart.register_number} ${roadPart.name}`}
                opacity={1}
            />
        );
    }

}
