import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {getRouteRegistry, createRouteRegistry, updateRouteRegistry} from "store/reducers/kiutr/route_registries";
import {getRoutes} from "store/reducers/routes/route_editor";
import {getContracts} from "store/reducers/kiutr/contracts/contracts";
import TableContainer from "components/ui/Table/Container/TableContainer";
import debounce from 'throttle-debounce/debounce';
import {createDisplay, getDisplay, getDisplays, updateDisplay} from "store/reducers/kiutr/displays";
import {getStopPoints} from "store/reducers/routes/routes";
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import {
    createDisplayConfiguration, deleteDisplayConfiguration, getDisplayConfigurations,
    updateDisplayConfiguration
} from "store/reducers/kiutr/display_configurations";
import Button from "components/ui/button";
import * as alerts from "helpers/alerts";
import DisplaysSelectOnMapComponent from "components/modules/kiutr/displays/table/select-on-map/index";
import DisplayLogsComponent from "components/modules/kiutr/displays/table/logs/index";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";

import './index.less';

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
})

@defaultProps({})

@connect(state => ({}), {getDisplay, createDisplay, updateDisplay})

export default class DisplayEditor extends BaseEditor {

    modelClass = 'App\\Model\\Display';
    title = 'табло';
    modalClassName = 'display-modal';

    async loadData(uuid) {
        return await this.props.getDisplay(uuid);
    }

    async createItem(data) {
        return await this.props.createDisplay(data);
    }

    async updateItem(data) {
        return await this.props.updateDisplay(data);
    }

    getForm(item, onSubmit) {
        if (!item.parameters) {
            item.parameters = {};
        }

        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    renderHeaderBtns(mode) {
        return [
            <ContextTooltip key="display_logs.show" code="display_logs.show"
                            default="Журнал">
                <ModalTopMenuListItem
                    className="b-icon-link_icon_book"
                    onClick={::this.showLogs}
                />
            </ContextTooltip>,
        ];
    }

    showLogs() {
        this.refs.form.getWrappedInstance().showLogs();
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getStopPoints,
    getDictionaryList,
    getUnits,
    getDisplayConfigurations,
    getRoutes,
    createDisplayConfiguration,
    updateDisplayConfiguration,
    deleteDisplayConfiguration,
    getDisplays,
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        display: {},
        units: [],
        display_models: [],
        display_configurations: [],
        routes: [],
        selectOnMap: false,
        logsActive: false,
    };

    getData() {
        return this.state.display;
    }

    async componentWillMount() {
        await this.setState({
            display: this.props.data,
        });
        this.loadUnits();
        this.loadRoutes();
        this.loadDisplayConfigurations();
        this.loadDictionaries([
            'display_models',
        ], null, true);
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            filters: {
                withComponent: 'kiutr',
            },
            pagination: {
                page: 1,
                limit: 10000,
            },
        });
        if (response.isOk) {
            this.setState({
                units: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async loadRoutes() {
        if (!this.get('stop_point_uuid')) {
            return;
        }
        const response = await this.props.getRoutes({
            pagination: {
                page: 1,
                limit: 10000,
            },
            filters: {
                withoutGeometry: true,
                withStopPoint: this.get('stop_point_uuid'),
            },
        });
        if (response.isOk) {
            await this.setState({
                routes: _.sortBy(_.map(response.payload.items, route => ({
                    value: _.get(route, 'uuid'),
                    label: `${_.get(route, 'number')} ${_.get(route, 'title')}`,
                })), 'label'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadDisplayConfigurations() {
        const response = await this.props.getDisplayConfigurations({
            pagination: {
                page: 1,
                limit: 10000,
            },
        });
        if (response.isOk) {
            this.setState({
                display_configurations: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                    parameters: item.parameters,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.display, path, defaultValue);
    }

    async loadStopPoints(input, callback) {
        if (!input) {
            input = this.get('stop_point_uuid');
        }
        const result = await this.props.getStopPoints({
            search: input,
        }, true);

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: `${i.register_number} ${i.title}`,
                    value: i.uuid,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    saveConfigurationAsNew() {
        alerts.ask('Введите наименование:', async (name) => {
            const response = await this.props.createDisplayConfiguration({
                name,
                parameters: this.get('parameters'),
            });

            if (response.isOk) {
                alerts.success('Сохранено');
            }
        });
    }

    async saveConfiguration() {
        const selectedDisplayConfiguration = _.find(this.state.display_configurations, {value: this.get('display_configuration_uuid')});

        const response = await this.props.updateDisplayConfiguration({
            uuid: selectedDisplayConfiguration.value,
            name: selectedDisplayConfiguration.label,
            parameters: this.get('parameters'),
        });

        if (response.isOk) {
            alerts.success('Обновлено');
        }
    }

    render() {
        return (
            <div>
                {this.renderSelectOnMap()}
                {this.renderLogs()}
                <Accordion>
                    <AccordionItem opened={true} title="Основная информация">
                        <div className="accordion__posit-side accordion__posit-side_left">
                            <Block title="Модель">
                                {this.select('display.display_model_uuid', this.state.display_models)}
                            </Block>
                            <Block title="Балансодержатель">
                                {this.select('display.unit_uuid', this.state.units)}
                            </Block>
                            <Block title="Маршруты">
                                {this.select('display.routes', this.state.routes, {
                                    multi: true,
                                })}
                            </Block>
                            <Block title={window.RNIS_SETTINGS.shedule_stop_points_page ? 'Остановочный пункт' : 'Текущее размещение'}>
                                {this.selectAsync('display.stop_point_uuid', ::this.loadStopPoints, {
                                    ref: 'stop_point',
                                })}
                                <Button size="md" width="auto" color="red"
                                        text="Карта"
                                        onClick={::this.showSelectOnMap}/>
                            </Block>
                        </div>
                        <div className="accordion__posit-side accordion__posit-side_right">
                            <Block title="Конфигурация">
                                {this.select('display.display_configuration_uuid', this.state.display_configurations)}
                                {this.get('display_configuration_uuid') ? (
                                    <Button className="b-button_multiple outer-indent-bottom" size="md" width="full"
                                            color="red"
                                            text="Применить текущие настройки к данной конфигурации"
                                            onClick={::this.saveConfiguration}/>
                                ) : null}
                                {this.get('display_configuration_uuid') ? (
                                    <Button className="b-button_multiple outer-indent-bottom" size="md" width="full"
                                            color="red"
                                            text="Удалить данную конфигурацию"
                                            onClick={::this.deleteConfiguration}/>
                                ) : null}
                                <Button className="b-button_multiple" size="md" width="full" color="red"
                                        text="Сохранить как новую конфигурацию"
                                        onClick={::this.saveConfigurationAsNew}/>
                            </Block>
                        </div>
                    </AccordionItem>
                    <AccordionItem opened={true} title="Основные настройки">
                        <Block title="Идентификатор табло">
                            {this.textInput('display.identifier')}
                        </Block>
                        <Block title="Период обновления">
                            {this.textInput('display.period', {
                                type: 'number',
                            })}
                        </Block>
                        <Block title="Корректировка хода часов">
                            {this.textInput('display.time_diff', {
                                type: 'number',
                            })}
                        </Block>
                        <Block title="Установка яркости (от 1 до 9)">
                            {this.textInput('display.brightness', {
                                type: 'number',
                            })}
                        </Block>
                        <Block title="Сторожевой таймер">
                            {this.textInput('display.guard_timer', {
                                type: 'number',
                            })}
                        </Block>
                    </AccordionItem>
                    <AccordionItem opened={false} title="Расширенные настройки отображения">
                        <Block title="Формат отображения">
                            {this.select('display.parameters.format', [
                                {
                                    value: 'route-time',
                                    label: 'Маршрут время',
                                },
                                {
                                    value: 'route-stop-time',
                                    label: 'Маршрут остановка время',
                                },
                                {
                                    value: 'route-time-route-time',
                                    label: 'Маршрут время маршрут время',
                                },
                            ])}
                        </Block>
                        <Block title="Скроллинг, движение">
                            {this.textInput('display.parameters.scroll_movement')}
                        </Block>
                        <Block title="Скроллинг, пауза">
                            {this.textInput('display.parameters.scroll_pause')}
                        </Block>
                        <Block title="Скорость бегущей строки (от 0 до 9)">
                            {this.textInput('display.parameters.scroll_speed')}
                        </Block>
                        <Block title="Текст строки">
                            {this.textInput('display.parameters.scroll_text')}
                        </Block>
                        <Block title="Разделитель D1">
                            {this.textInput('display.parameters.split_d1')}
                        </Block>
                        <Block title="Ширина S1">
                            {this.textInput('display.parameters.width_s1')}
                        </Block>
                        <Block title="Выравнивание полей">
                            <ContextTooltip default="0 - левый край, 1 - правый край, 2 - центр">
                                {this.textInput('display.parameters.alignment')}
                            </ContextTooltip>
                        </Block>
                        <Block title="Разделитель D2">
                            {this.textInput('display.parameters.split_d2')}
                        </Block>
                        <Block title="Ширина S2">
                            {this.textInput('display.parameters.width_s2')}
                        </Block>
                        <Block title="Размер по вертикали">
                            {this.textInput('display.parameters.height')}
                        </Block>
                        <Block title="Разделитель D3">
                            {this.textInput('display.parameters.split_d3')}
                        </Block>
                        <Block title="Ширина S3">
                            {this.textInput('display.parameters.width_s3')}
                        </Block>
                        <Block title="Размер по горизонтали">
                            {this.textInput('display.parameters.width')}
                        </Block>
                        <Block size="xl">
                            {this.checkbox('display.need_reboot', 'Перезагрузить табло')}
                            {this.checkbox('display.need_send', 'Загрузить в табло')}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    async setValue(field, value) {
        await super.setValue(field, value);

        if (field === 'display.display_configuration_uuid') {
            const parameters = _.get(_.find(this.state.display_configurations, {value}), 'parameters') || {};
            this.setValue('display.parameters', parameters);
        }

        if (field === 'display.stop_point_uuid') {
            this.setValue('display.routes', []);
            this.loadRoutes();
        }
    }

    showSelectOnMap() {
        this.setState({
            selectOnMap: true,
        });
    }

    hideSelectOnMap() {
        this.setState({
            selectOnMap: false,
        });
    }

    renderSelectOnMap() {
        if (!this.state.selectOnMap) {
            return;
        }
        return (
            <DisplaysSelectOnMapComponent
                onClose={::this.hideSelectOnMap}
                onStopPointClick={::this.onStopPointClick}
            />
        );
    }

    async onStopPointClick(e, stopPointUuid) {
        await this.setValue('display.stop_point_uuid', stopPointUuid);
        this.hideSelectOnMap();

        this.refs.stop_point && this.refs.stop_point.reload();
    }

    showLogs() {
        this.setState({
            logsActive: true,
        });
    }

    hideLogs() {
        this.setState({
            logsActive: false,
        });
    }

    renderLogs() {
        if (!this.state.logsActive) {
            return;
        }

        return (
            <DisplayLogsComponent
                displayUuid={this.get('uuid')}
                onClose={::this.hideLogs}
            />
        );
    }

    async deleteConfiguration() {
        const response = await this.props.getDisplays({
            filters: {
                withDisplayConfiguration: this.get('display_configuration_uuid'),
            },
        });

        if (response.isOk) {
            const uses = (response.payload.items.length > 0) ? `Используется в ${_.map(response.payload.items, 'identifier').join(', ')}` : 'Не используется';

            alerts.prompt(`Удалить конфигурацию? ` + uses, null, async () => {
                const response2 = await this.props.deleteDisplayConfiguration({
                    uuid: this.get('display_configuration_uuid'),
                });

                if (response2.isOk) {
                    alerts.success('Удалено');
                    this.loadDisplayConfigurations();
                } else {
                    response2.showErrors();
                }
            });
        } else {
            response.showErrors();
        }
    }
}