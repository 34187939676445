import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import classNames from 'classnames';
import Block from "components/ui/form/block";
import Select from "components/ui/select";
import systems from "dictionaries/systems";
import MapFilterExtendedTabBase from "components/modules/maps/Filter/Extended/Tab/MapFilterExtendedTabBase";
import MapFilterExtendedFilter from "components/modules/maps/Filter/Extended/Filter/MapFilterExtendedFilter";
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import MapFilterExtendedFilterDictionary from "components/modules/maps/Filter/Extended/Filter/MapFilterExtendedFilterDictionary";
import {getRoutes} from "store/reducers/routes/routes";
import MapFilterExtendedFilterEnum from "components/modules/maps/Filter/Extended/Filter/MapFilterExtendedFilterEnum";
import Datepicker from "components/ui/form/datepicker";
import Input from "components/ui/form/input";
import {getOrders} from "store/reducers/kiutr/orders/orders";
import {getTasks} from "store/reducers/kurs/tasks";
import moment from "moment";
import formats from "dictionaries/formats";
import TextFieldMask from "components/ui/text-field-mask";
import {getContracts} from "store/reducers/kurs/contracts";

@propTypes({
    isActive: PropTypes.bool,
})

@connect(state => ({}), {
    getUnits,
    getUsers,
    getRoutes,
    getContracts,
    getOrders,
    getTasks,
})

export default class MapFilterExtendedTabWorks extends MapFilterExtendedTabBase {

    state = {};

    getModificators() {
        if (this.isKiutrOrChildren() || this.isCommunal() || this.isKurs()) {
            return {
                'Включая дочерние предприятия': 'extended.works.modificators.with_children',
            };
        }

        return {};
    }

    renderContent() {
        return (
            <div>
                <Block size="sm" title="Время с">
                    <TextFieldMask
                        mask="99:99"
                        value={_.get(this.props.filters, 'extended.from')}
                        onChange={this.onChange.bind(this, 'extended.from')}
                        withTimeIcon={true}
                    />
                </Block>
                <Block size="sm" title="Время по">
                    <TextFieldMask
                        mask="99:99"
                        value={_.get(this.props.filters, 'extended.to')}
                        onChange={this.onChange.bind(this, 'extended.to')}
                        withTimeIcon={true}
                    />
                </Block>
                {this.isKiutrOrChildren() ? (
                    <MapFilterExtendedFilter
                        title="Предприятия-заказчики работ"
                        selected={_.get(this.props.filters, 'extended.works.units')}
                        onSelectedChange={this.onChange.bind(this, 'extended.works.units')}
                        loadData={::this.loadUnits}
                    />
                ) : null}
                {(this.isKiutrOrChildren() || this.isKurs()) ? (
                    <MapFilterExtendedFilter
                        title="Предприятия-исполнители работ"
                        selected={_.get(this.props.filters, 'extended.works.carriers')}
                        onSelectedChange={this.onChange.bind(this, 'extended.works.carriers')}
                        loadData={::this.loadUnits}
                    />
                ) : null}
                {this.isKiutrOrChildren() ? (
                    <MapFilterExtendedFilter
                        title="Наряды"
                        selected={_.get(this.props.filters, 'extended.works.orders')}
                        onSelectedChange={this.onChange.bind(this, 'extended.works.orders')}
                        loadData={::this.loadOrders}
                    />
                ) : null}
                {this.isKurs() ? (
                    <MapFilterExtendedFilter
                        title="Задания"
                        selected={_.get(this.props.filters, 'extended.works.tasks')}
                        onSelectedChange={this.onChange.bind(this, 'extended.works.tasks')}
                        loadData={::this.loadTasks}
                    />
                ) : null}
                {this.isKurs() ? (
                    <MapFilterExtendedFilter
                        title={window.RNIS_SETTINGS.rename_contracts ? "Подрядные обязательства" : "Контракты"}
                        selected={_.get(this.props.filters, 'extended.works.kurs_contracts')}
                        onSelectedChange={this.onChange.bind(this, 'extended.works.kurs_contracts')}
                        loadData={::this.loadContracts}
                    />
                ) : null}
                <MapFilterExtendedFilter
                    title="Водители"
                    selected={_.get(this.props.filters, 'extended.works.drivers')}
                    onSelectedChange={this.onChange.bind(this, 'extended.works.drivers')}
                    loadData={::this.loadDrivers}
                />
                {this.isKiutrOrChildren() ? (
                    <MapFilterExtendedFilterDictionary
                        title="Типы маршрутов"
                        dictionaryKey="route_kinds"
                        selected={_.get(this.props.filters, 'extended.works.route_kinds')}
                        onSelectedChange={this.onChange.bind(this, 'extended.works.route_kinds')}
                    />
                ) : null}
                {this.isKiutrOrChildren() ? (
                    <MapFilterExtendedFilterDictionary
                        title="Виды тарифов"
                        dictionaryKey="rates_types"
                        selected={_.get(this.props.filters, 'extended.works.rates_types')}
                        onSelectedChange={this.onChange.bind(this, 'extended.works.rates_types')}
                    />
                ) : null}
                {this.isKiutrOrChildren() ? (
                    <MapFilterExtendedFilter
                        title="Маршруты"
                        selected={_.get(this.props.filters, 'extended.works.routes')}
                        onSelectedChange={this.onChange.bind(this, 'extended.works.routes')}
                        loadData={::this.loadRoutes}
                    />
                ) : null}
                {this.isKurs() ? (
                    <MapFilterExtendedFilterDictionary
                        title="Виды работ"
                        dictionaryKey="work_types"
                        selected={_.get(this.props.filters, 'extended.works.work_types')}
                        onSelectedChange={this.onChange.bind(this, 'extended.works.work_types')}
                    />
                ) : null}
                {/*this.isKurs() ? (
                    <MapFilterExtendedFilterDictionary
                        title="Виды ремонта"
                        dictionaryKey="kurs_repair_types"
                        selected={_.get(this.props.filters, 'extended.works.kurs_repair_types')}
                        onSelectedChange={this.onChange.bind(this, 'extended.works.kurs_repair_types')}
                    />
                ) : null*/}
                {this.isKurs() ? (
                    <MapFilterExtendedFilterEnum
                        title="Типы дорог"
                        items={[
                            {
                                value: 'local',
                                label: 'Местные',
                            },
                            {
                                value: 'federal',
                                label: 'Региональные/межмуниципальные',
                            },
                            {
                                value: 'federal_roads',
                                label: 'Федеральные',
                            }
                        ]}
                        selected={_.get(this.props.filters, 'extended.works.driveway_categories')}
                        onSelectedChange={this.onChange.bind(this, 'extended.works.driveway_categories')}
                    />
                ) : null}
                <MapFilterExtendedFilterEnum
                    title="Только в работе"
                    items={[
                        {
                            value: 'yes',
                            label: 'Да',
                        },
                        {
                            value: 'no',
                            label: 'Нет',
                        },
                    ]}
                    selected={_.get(this.props.filters, 'extended.works.in_work')}
                    onSelectedChange={this.onChange.bind(this, 'extended.works.in_work')}
                />
            </div>
        )
    }

    isKiutrOrChildren() {
        return _.indexOf(['kiutr', 'children', 'ems'], _.get(this.props.filters, 'extended.parameters.component')) !== -1;
    }

    isCommunal() {
        return _.get(this.props.filters, 'extended.parameters.component') === 'communal';
    }

    isKurs() {
        return _.get(this.props.filters, 'extended.parameters.component') === 'road';
    }

    async loadUnits(search = null, limit = 25) {
        let filters = {};
        if (_.get(this.props.filters, 'extended.parameters.component')) {
            filters.withComponent = _.get(this.props.filters, 'extended.parameters.component');
        }

        const response = await this.props.getUnits({
            filters,
            search,
            pagination: {
                page: 1,
                limit: limit,
            },
        });

        if (response.isOk) {
            return _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.name,
            }));
        } else {
            response.showErrors();
            return [];
        }
    }

    async loadOrders(search = null, limit = 25) {
        let filters = {};
        if (_.get(this.props.filters, 'extended.parameters.component')) {
            filters.withComponent = _.get(this.props.filters, 'extended.parameters.component');
        }
        if ((_.get(this.props.filters, 'extended.works.carriers') || []).length > 0) {
            filters.withCarriers = _.get(this.props.filters, 'extended.works.carriers');
        }
        filters.withWorkPeriod = [
            moment().startOf('day').minute(this.timeStrToMinutes(_.get(this.props.filters, 'extended.from'))).format(formats.DATE_API),
            moment().startOf('day').minute(this.timeStrToMinutes(_.get(this.props.filters, 'extended.to'))).format(formats.DATE_API),
        ];

        const response = await this.props.getOrders({
            filters,
            search,
            pagination: {
                page: 1,
                limit: limit,
            },
            response_data: [
                'items/uuid',
                'items/number',
            ],
        });

        if (response.isOk) {
            return _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.number,
            }));
        } else {
            response.showErrors();
            return [];
        }
    }

    async loadTasks(search = null, limit = 25) {
        let filters = {};
        if (_.get(this.props.filters, 'extended.parameters.component')) {
            filters.withComponent = _.get(this.props.filters, 'extended.parameters.component');
        }
        if ((_.get(this.props.filters, 'extended.works.carriers') || []).length > 0) {
            filters.withUnits = _.get(this.props.filters, 'extended.works.carriers');
        }
        filters.withWorkPeriod = [
            moment().startOf('day').minute(this.timeStrToMinutes(_.get(this.props.filters, 'extended.from'))).format(formats.DATE_API),
            moment().startOf('day').minute(this.timeStrToMinutes(_.get(this.props.filters, 'extended.to'))).format(formats.DATE_API),
        ];

        const response = await this.props.getTasks({
            filters,
            search,
            pagination: {
                page: 1,
                limit: limit,
            },
            response_data: [
                'items/uuid',
                'items/number',
            ],
        });

        if (response.isOk) {
            return _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.number,
            }));
        } else {
            response.showErrors();
            return [];
        }
    }

    async loadDrivers(search = null, limit = 25) {
        let filters = {
            withPositionTypes: [
                'driver',
            ],
        };
        if (_.get(this.props.filters, 'extended.parameters.component')) {
            filters.withComponent = _.get(this.props.filters, 'extended.parameters.component');
        }
        if ((_.get(this.props.filters, 'extended.works.carriers') || []).length > 0) {
            filters.withUnits = _.get(this.props.filters, 'extended.works.carriers');
        }

        const response = await this.props.getUsers({
            filters,
            search,
            pagination: {
                page: 1,
                limit: limit,
            },
        });

        if (response.isOk) {
            return _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: new User(item).getFullName(),
            }));
        } else {
            response.showErrors();
            return [];
        }
    }

    async loadRoutes(search = null, limit = 25) {
        let filters = {};
        if (_.get(this.props.filters, 'extended.parameters.component')) {
            filters.withComponent = _.get(this.props.filters, 'extended.parameters.component');
        }
        if ((_.get(this.props.filters, 'extended.works.rates_types') || []).length > 0) {
            filters.withRatesTypes = _.get(this.props.filters, 'extended.works.rates_types');
        }
        if ((_.get(this.props.filters, 'extended.works.route_kinds') || []).length > 0) {
            filters.withRouteKind = _.get(this.props.filters, 'extended.works.route_kinds');
        }

        const response = await this.props.getRoutes({
            filters,
            search,
            pagination: {
                page: 1,
                limit: limit,
            },
        });

        if (response.isOk) {
            return _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: `${item.number} ${item.title}`,
            }));
        } else {
            response.showErrors();
            return [];
        }
    }

    async loadContracts(search = null, limit = 25) {
        let filters = {};

        if ((_.get(this.props.filters, 'extended.works.carriers') || []).length > 0) {
            filters.withImplementer = _.get(this.props.filters, 'extended.works.carriers');
        }

        const response = await this.props.getContracts({
            filters,
            search,
            pagination: {
                page: 1,
                limit: limit,
            },
        });

        if (response.isOk) {
            return _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.number || item.name || '-',
            }));
        } else {
            response.showErrors();
            return [];
        }
    }

    timeStrToMinutes(time) {
        const timeObject = moment(time, formats.TIME);
        return timeObject.hours() * 60 + timeObject.minutes();
    }
}
