import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import {DefectTypes} from "dictionaries/kiutr";
import {createDefect, updateDefect, updateOrder, updateOrderRecalc} from "store/reducers/kiutr/orders/orders";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import GlobalLoaderComponent from "components/ui/global-loader";
import PageModal from 'components/ui/page-modal';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import TableContainer from "components/ui/Table/Container/TableContainer";
import runs from "dictionaries/runs";
import moment from "moment";
import formats from "dictionaries/formats";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import Input from "components/ui/form/input";
import * as alerts from "helpers/alerts";
import uuidv1 from 'uuid/v1';
import {getRouteVariants} from "store/reducers/routes/route_variants";
import {getDefaultVariant} from "helpers/kiutr";
import {getStopPoints} from "store/reducers/geo/stop-points";
import {getIntervalMaps} from "store/reducers/routes/interval_maps";
import ProductionRunEditor from "components/modules/kiutr/orders/OrderOperationalForm/OrderEditor/ProductionRunEditor/index";
import {SelectAsync} from "components/ui/select";
import Select from "components/ui/select";
import currentUser from "helpers/current-user";
import {isCarriersLimits, isMunicipalType} from 'helpers/functions';
import { checkLimitationInstallationMidnight } from "helpers/functions";

@propTypes({
    order: PropTypes.object.isRequired,
    type: PropTypes.string,
    shift: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
})

@connect(state => ({}), {updateOrder, updateOrderRecalc, getRouteVariants, getStopPoints, getIntervalMaps})

export default class OrderEditor extends Component {

    state = {
        saving: false,
        order: null,
        productionRunEditIndex: null,
        defaultStopPoints: {},
        addRunSelectActive: false,
        run_type: null,
        run_time: null,
        index: null,
        run_distance: null,
        route_uuid: null,
    };

    async componentWillMount() {
        await this.setState({
            order: this.props.order,
            shift: _.cloneDeep(_.find(this.props.order.shifts, {shift: this.props.shift}) || {}),
        });

        this.loadRouteVariants();
    }

    async loadRouteVariants() {
        const response = await this.props.getRouteVariants({
            filters: {
                withRoute: _.uniq(_.map(this.state.shift.runs, 'route_uuid')),
            },
        });
        if (response.isOk) {
            this.setState({
                routeVariants: response.payload.items,
            });
            //const defaultVariant = getDefaultVariant(response.payload.items);
            let points = response.payload.items.map(v => _.concat(v.forward_points, v.reverse_points))
            this.loadStopPoints(_.flatten(_.concat(points)));
        } else {
            response.showErrors();
        }
    }

    async loadStopPoints(points) {
        const response = await this.props.getStopPoints({
            filters: {
                withUuid: _.uniq(_.filter(_.map(points, 'type_uuid'))),
            },
            response_data: [
                'items/uuid',
                'items/title',
            ],
        });
        if (response.isOk) {
            this.setState({
                defaultStopPoints: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'title'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadIntervalMap(routeVariantUuid, isForward) {
        const response = await this.props.getIntervalMaps({
            filters: {
                withRouteVariant: routeVariantUuid,
                withDirection: isForward,
                withDays: this.getDaysForIntervalMap(),
            },
        });
        if (response.isOk) {
            return _.first(response.payload.items);
        } else {
            response.showErrors();
        }
    }

    getDaysForIntervalMap() {
        const days = [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
        ];

        return [
            days[moment(this.state.order.date).day()],
        ];
    }

    formatTime(minutes) {
        if (isNaN(minutes)) {
            return '00:00';
        }
        return _.padStart(Math.floor(minutes / 60), 2, '0') + ':' + _.padStart(minutes % 60, 2, '0');
    }

    async save(type) {
        // если пользователь ввел отрицательную длительность и не суперпользователь
        if (this.state.shift.runs.filter(run => run.time < 0 || run.time > 1440).length) {
            alerts.error('Задана некорретная длительность!');
            return;
        }

        if (this.state.order.shifts[0].runs.filter(run => run.time < 0 || run.time > 1440).length) {
            alerts.error('Задана некорретная длительность!');
            return;
        }

        let order = this.state.order;
        const index = _.findIndex(order.shifts, {shift: this.props.shift});

        const oldCount = order.shifts[index].runs.length;
        const newCount = this.state.shift.runs.length;
        const oldDuration = Math.abs(moment(_.first(order.shifts[index].runs).date_from).diff(moment(_.last(order.shifts[index].runs).date_to), 'minutes'));
        const newDuration = Math.abs(moment(_.first(this.state.shift.runs).date_from).diff(moment(_.last(this.state.shift.runs).date_to), 'minutes'));
        const logText = `Рейсов: ${oldCount} -> ${newCount}; Продолжительность: ${this.formatTime(oldDuration)} -> ${this.formatTime(newDuration)}`;

        order.shifts[index] = this.state.shift;
        order.shifts[index].start_at = _.first(this.state.shift.runs).date_from;
        order.shifts[index].end_at = _.last(this.state.shift.runs).date_to;
        order.defects = _.map(order.defects, (defect) => {
            defect.can_cancel = false;

            return defect;
        });
        let originalOrder = this.props.originalOrder;
        originalOrder.logs.push({
            type: 'edit',
            time: moment().format(formats.DATETIME_API),
            text: logText,
            user_uuid: currentUser.uuid(),
        });

        this.setState({
            saving: true,
        });
        if (this.props.type === 'order') {
            const response = await this.props.updateOrder(order);
            this.setState({
                saving: false,
            });
            if (response.isOk) {
                this.props.onSubmit(type);
            } else {
                alerts.error(Object.values(response.errors[0].data))
                response.showErrors();
            }
        } else {
            const response = await this.props.updateOrder(originalOrder);
            const response2 = await this.props.updateOrderRecalc(order);
            this.setState({
                saving: false,
            });
            if (response.isOk && response2.isOk) {
                this.props.onSubmit(type);
            } else {
                let errorsArray = [];
                if (!response.isOk) {
                    errorsArray.push(Object.values(response.errors[0].data))
                }
                if (!response2.isOk) {
                    errorsArray.push(Object.values(response2.errors[0].data))
                }
                if (errorsArray.length > 0) {
                    alerts.error(errorsArray.flat(1))
                }
                response.showErrors();
                response2.showErrors();
            }
        }
    }

    checkSave() {
        if (checkLimitationInstallationMidnight( _.get(this.state.item, 'date'), true)) {
            alerts.alert(`Данное действие невозможно после ${moment(_.get(this.state.item, 'date')).format(formats.DATE)} 23:59`);
            return;
        }
        this.save('resources')
    }

    render() {
        const loader = (this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        const form = this.renderForm();

        const buttons = (
            <ModalTopMenuButtons>
                {(this.props.type === 'order') ? (
                    <ModalTopMenuList className="top-menu_modal_edit">
                        <ContextTooltip key="order.edit.save-select" code="order.edit.save-select"
                                        default="Оформить и выделить ресурсы">
                            <ModalTopMenuListItem
                                className="b-icon-link_icon_order-resources"
                               // onClick={this.save.bind(this, 'resources')}
                                onClick={::this.checkSave}
                            />
                        </ContextTooltip>

                        <ModalTopMenuListSeparator key="separator"/>
                    </ModalTopMenuList>
                ) : null}

                {!currentUser.user.free_user_settings.opDispatching ? (<ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                    <ModalTopMenuButton
                        className="_save"
                        title="Сохранить"
                        onClick={this.save.bind(this, 'close')}
                    />
                </ContextTooltip>) : null}

                <ModalTopMenuButtonsSeparator/>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <div>
                <PageModal
                    header={{title: 'Конструктор опервыхода', buttons}}
                    onClose={this.props.onClose}
                    className={`profile-modal b-modal-edit orders-modal orders-modal-runs b-modal_stretch`}
                    withFade={this.props.withFade || false}
                    disableOutsideClick={false}
                >
                    {loader}
                    {form}
                </PageModal>
                {this.state.productionRunEditIndex !== null ? (
                    <ProductionRunEditor
                        index={this.state.productionRunEditIndex}
                        shift={this.state.shift}
                        stopPoints={this.state.defaultStopPoints}
                        onUpdate={::this.updateProductionRun}
                        onClose={::this.hideProductionRunEdit}
                        routeVariants={this.state.routeVariants}
                    />
                ) : null}
                {this.state.addRunSelectActive ? (
                    this.renderRunSelectModal()
                ) : null}
            </div>
        );
    }

    async updateProductionRun(data) {
        const index = this.state.productionRunEditIndex;
        let shift = this.state.shift;
        shift.runs[index] = data;
        await this.setState({
            shift,
        });

        this.recalc();

        this.hideProductionRunEdit();
    }

    renderForm() {
        return (
            <div className="b-modal__block">
                <TableContainer>
                    <div className="Table">
                        <table className="b-table b-table-no-hover">
                            <thead>
                            <tr className="wrap-normal">
                                <th>№</th>
                                <th>Тип рейса</th>
                                <th>Длительность, мин</th>
                                <th>С</th>
                                <th>По</th>
                                <th>Соответствие расп. выхода ({this.getShiftAccordance()}%)</th>
                                <th>Ресурсы</th>
                                <th>Маршрут</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.shift.runs.map(::this.renderRun)}
                            </tbody>
                        </table>
                    </div>
                </TableContainer>
                {this.renderTotalSummary()}
            </div>
        );
    }

    renderRun(run, index) {
        const isInFuture = true;//moment(run.date_from).isAfter();
        const isProductionRun = run.type === 'production_forward' || run.type === 'production_reverse';
        const canMoveUp = (index > 0);// && moment(this.state.shift.runs[index - 1].date_from).isAfter();
        const canMoveDown = (index < this.state.shift.runs.length - 1);// && moment(this.state.shift.runs[index + 1].date_from).isAfter() && isInFuture;

        return ([
            <tr key={index}>
                <td>
                    {index + 1}
                    <br/>
                    {canMoveUp ? (
                        <a className="move-up" href="javascript:void(0)"
                           onClick={this.moveUp.bind(this, index)}>&uarr;</a>
                    ) : null}
                    {canMoveDown ? (
                        <a className="move-down" href="javascript:void(0)"
                           onClick={this.moveDown.bind(this, index)}>&darr;</a>
                    ) : null}
                </td>
                <td>{runs[run.type]}</td>
                <td>
                    {isProductionRun ? (
                        <div>
                            {run.time}
                            {isInFuture ? (
                                <a className="edit" href="javascript:void(0)"
                                   onClick={this.editProductionRun.bind(this, index)}></a>
                            ) : null}
                        </div>
                    ) : (isInFuture ? (
                        <Input
                            positive
                            value={run.time}
                            onChange={this.onChange.bind(this, `runs.${index}.time`)}
                        />
                    ) : run.time)}
                </td>
                <td>{moment(run.date_from).add(currentUser.user.is_timezoneoffset, 'hours').format(formats.TIME)}</td>
                <td>{moment(run.date_to).add(currentUser.user.is_timezoneoffset, 'hours').format(formats.TIME)}</td>
                <td>{isProductionRun ? this.getTextAccordance(run) : ''}</td>
                <td>
                    {run.vehicle_state_number || <i>-</i>}, {run.driver_name || <i>-</i>}
                </td>
                <td>{run.route_number}</td>
                <td>
                    {isCarriersLimits() && this.props.originalOrder.processing_status === 'ended' ? null :
                        (
                            <a className="remove-run" href="javascript:void(0)"
                               onClick={this.deleteRun.bind(this, index)}>&times;</a>
                        )}
                </td>
            </tr>,
            ((this.props.type === 'recalc') || moment(run.date_to).isAfter()) ? (
                <tr key={`${index}:add`}>
                    <td className="height-zero">
                        <a className="add-run" href="javascript:void(0)" onClick={this.requestRunAdd.bind(this, index)}>+</a>
                    </td>
                    <td className="height-zero" colSpan={8}></td>
                </tr>
            ) : null,
        ]);
    }

    getTextAccordance(run) {
        const {offset, compression} = this.getAccordance(run);
        if (offset === 0 && compression === 0) {
            return 'Точно';
        } else {
            let parts = [];
            if (offset !== 0) {
                parts.push(`Смещ. ${offset} мин`);
            }
            if (compression > 0) {
                parts.push(`Расш. ${compression} мин`);
            }
            if (compression < 0) {
                parts.push(`Сжатие ${compression} мин`);
            }

            return parts.join(', ');
        }
    }

    getShiftAccordance() {
        const accordance = Math.max(0, 1 - _.sum(_.map(this.state.shift.runs, (run) => {
            const {offset, compression, originalLength} = this.getAccordance(run);

            return (originalLength !== 0) ? ((Math.abs(offset) + Math.abs(compression)) / originalLength) : 0;
        })));

        return Math.round(accordance * 100);
    }

    getAccordance(run) {
        const offset = Math.abs(moment(run.original_date_from).diff(moment(run.date_from), 'minutes'));
        const originalLength = Math.abs(moment(run.original_date_from).diff(moment(run.original_date_to), 'minutes'));
        const runLength = Math.abs(moment(run.date_from).diff(moment(run.date_to), 'minutes'));
        const compression = runLength - originalLength;

        return {
            offset,
            compression,
            originalLength
        };
    }

    async deleteRun(index) {
        let shift = this.state.shift;
        shift.runs.splice(index, 1);
        await this.setState({
            shift,
        });

        this.recalc();
    }

    async onChange(field, {target: {value}}) {
        let shift = this.state.shift;
        _.set(shift, field, value);
        await this.setState({
            shift,
        });
        this.recalc();
    }

    async moveUp(index) {
        let shift = this.state.shift;
        const run = shift.runs.splice(index, 1);
        shift.runs.splice(index - 1, 0, ...run);
        await this.setState({
            shift,
        });

        this.recalc();
    }

    async moveDown(index) {
        let shift = this.state.shift;
        const run = shift.runs.splice(index, 1);
        shift.runs.splice(index + 1, 0, ...run);
        await this.setState({
            shift,
        });

        this.recalc();
    }

    async requestRunAdd(index) {
        this.setState({
            addRunSelectActive: true,
            index,
        });
    }

    addRunAfter(index) {
        alerts.askSelect('Выберите тип рейса:', _.map(_.omit(runs, [
            'switch_out',
            'switch_in'
        ]), (label, value) => ({
            value,
            label,
        })), async (type) => {
            switch (type) {
                // только время
                case 'parking':
                case 'dinner':
                case 'settling':
                case 'gap':
                case 'reshift':
                    alerts.ask('Введите время:', (time) => {
                        time = _.toInteger(time);

                        this.addRunReal(index, {
                            type,
                            distance: 0,
                            time,
                        });
                    });
                    break;

                //время и расстояние
                case 'refill':
                case 'null':
                case 'techno':
                    alerts.ask('Введите время:', (time) => {
                        time = _.toInteger(time);
                        alerts.ask('Введите расстояние:', (distance) => {
                            distance = _.toInteger(distance) * 1000;

                            this.addRunReal(index, {
                                type,
                                distance,
                                time,
                            });
                        });
                    });
                    break;

                case 'production_forward':
                case 'production_reverse':
                    alerts.askSelect('Выберите вариант движения:', _.map(this.state.routeVariants, (routeVariant) => ({
                        value: routeVariant.uuid,
                        label: routeVariant.name,
                    })), async (routeVariantUuid) => {
                        const routeVariant = _.find(this.state.routeVariants, {uuid: routeVariantUuid}) || {};
                        const intervalMap = await this.loadIntervalMap(routeVariantUuid, type === 'production_forward');
                        if (!intervalMap) {
                            alerts.error('Не найдена подходящая интервальная карта');
                            return;
                        }
                        const endAt = moment(this.state.shift.runs[index].date_to);
                        const time = endAt.hours() * 2 + ((endAt.minutes() < 30) ? 0 : 1);

                        const intervalMapValuesUnordered = _.mapValues(intervalMap.values, (values) => _.toInteger(values[time]) || 0);
                        const intervalMapValues = _.map(_.filter(_.map(routeVariant[(type === 'production_forward') ? 'forward_points' : 'reverse_points'], 'type_uuid')), (stopPointUuid, _index) => ({
                            uuid: stopPointUuid,
                            interval: intervalMapValuesUnordered[_index],
                            index: _index,
                        }));

                        this.addRunReal(index, {
                            type,
                            distance: _.sumBy(routeVariant[(type === 'production_forward') ? 'forward_points' : 'reverse_points'], 'distance_to_the_next_point'),
                            time: _.sum(_.map(intervalMapValues, 'interval')),
                            route_variant_uuid: routeVariantUuid,
                            production_interval_map: intervalMapValues,
                        });
                    });
                    break;
            }
        });
    }

    async addRunReal(index, {type, distance, time, route_variant_uuid, production_interval_map, contract_uuid, route_registry_uuid, route_uuid, route_number}) {
        let shift = this.state.shift;
        const run = shift.runs[index];

        shift.runs.splice(index + 1, 0, {
            uuid: uuidv1(),
            type,
            route_uuid: route_uuid,
            route_number: route_number || run.route_number,
            route_registration_number: run.route_registration_number,
            route_name: run.route_name,
            run: null,
            date_from: null,
            date_to: null,
            vehicle_uuid: null,
            vehicle_bnso: null,
            vehicle_state_number: null,
            vehicle_garage_number: null,
            driver_uuid: null,
            driver_name: null,
            driver_personnel_number: null,
            check_taker_uuid: null,
            score: null,
            mileage: null,
            contract_uuid,
            route_registry_uuid,
            vehicle_capacity_type_uuid: null,
            points: null,
            distance,
            time,
            route_variant_uuid,
            production_interval_map,
            schedule_switch_uuid: null,
        });

        await this.setState({
            shift,
        });
        this.recalc();
    }

    renderTotalSummary() {
        const from = moment(_.first(this.state.shift.runs).date_from);
        const to = moment(_.last(this.state.shift.runs).date_to);
        const minutes = Math.abs(to.diff(from, 'minutes'));

        return (
            <Block size="xl" className="total">
                <div className="bold">Общая продолжительность: {Math.floor(minutes / 60)}ч {minutes % 60}мин</div>
                <span>с {from.add(currentUser.user.is_timezoneoffset, 'hours').format(formats.TIME)} по {to.add(currentUser.user.is_timezoneoffset, 'hours').format(formats.TIME)}</span>
            </Block>
        );
    }

    recalc() {
        let shift = this.state.shift;
        let time = moment(_.first(shift.runs).date_from);

        for (let i = 0; i < shift.runs.length; i++) {
            shift.runs[i].run = i;
            shift.runs[i].date_from = time.format(formats.DATETIME_API);

            if ((shift.runs[i].type === 'production_forward') || (shift.runs[i].type === 'production_reverse')) {
                shift.runs[i].time = _.sumBy(shift.runs[i].production_interval_map, interval => _.toInteger(interval.interval));
            }

            time = time.add(shift.runs[i].time, 'minutes');
            shift.runs[i].date_to = time.format(formats.DATETIME_API);
        }

        this.setState({
            shift,
        });
    }

    editProductionRun(index) {
        this.setState({
            productionRunEditIndex: index,
        });
    }

    hideProductionRunEdit() {
        this.setState({
            productionRunEditIndex: null,
        });
    }

    hideRunAdd() {
        this.setState({
            addRunSelectActive: false,
            run_type: null,
            run_time: null,
            run_distance: null,
        });
    }

    async addRun() {
        const {timeInput, distanceInput} = this.getRunFields();

        const type = this.state.run_type;
        const time = timeInput ? _.toInteger(this.state.run_time) : 0;
        const distance = distanceInput ? _.toInteger(this.state.run_distance) : 0;

        if (!type) {
            alerts.error('Не выбран тип рейса');
            return;
        }

        const prevRuns = _.filter(this.state.shift.runs, (run, index) => {
            return index <= this.state.index;
        });
        const nextRuns = _.filter(this.state.shift.runs, (run, index) => {
            return index > this.state.index;
        });
        let prevRun = _.last(prevRuns);
        if (prevRun.type === 'switch_out') {
            prevRun = _.first(nextRuns);
        }

        if (type === 'null') {
            if (!this.state.route_variant_null_run_uuid) {
                alerts.error('Не выбран нулевой рейс');
                return;
            }
            this.addRunReal(this.state.index, {
                type,
                distance: this.state.route_variant_null_run.distance,
                time: this.state.route_variant_null_run.time,
                route_variant_null_run_uuid: this.state.route_variant_null_run_uuid,
                route_uuid: _.get(prevRun, 'contract_uuid'),
                route_number: _.get(prevRun, 'route_number'),
                contract_uuid: _.get(prevRun, 'contract_uuid'),
                route_registry_uuid: _.get(prevRun, 'route_registry_uuid'),
            });

            this.hideRunAdd();
        } else if (type === 'production_forward' || type === 'production_reverse') {
            const routeVariantUuid = this.state.route_variant_uuid;
            const routeVariant = _.find(this.state.routeVariants, {uuid: routeVariantUuid}) || {};
            const intervalMap = await this.loadIntervalMap(routeVariantUuid, type === 'production_forward');
            if (!intervalMap) {
                alerts.error('Не найдена подходящая интервальная карта');
                return;
            }
            const endAt = moment(this.state.shift.runs[this.state.index].date_to);
            const time = endAt.hours() * 2 + ((endAt.minutes() < 30) ? 0 : 1);

            const intervalMapValuesUnordered = _.mapValues(intervalMap.values, (values) => _.toInteger(values[time]) || 0);
            const intervalMapValues = _.map(_.filter(_.map(routeVariant[(type === 'production_forward') ? 'forward_points' : 'reverse_points'], 'type_uuid')), (stopPointUuid, _index) => ({
                uuid: stopPointUuid,
                interval: intervalMapValuesUnordered[_index],
                index: _index,
            }));

            const sameRouteRun = _.get(this.state.shift.runs, {route_uuid: this.state.route_uuid});

            this.addRunReal(this.state.index, {
                type,
                distance: _.sumBy(routeVariant[(type === 'production_forward') ? 'forward_points' : 'reverse_points'], 'distance_to_the_next_point'),
                time: _.sum(_.map(intervalMapValues, 'interval')),
                route_variant_uuid: routeVariantUuid,
                production_interval_map: intervalMapValues,
                route_uuid: this.state.route_uuid,
                route_number: _.get(sameRouteRun, 'route_number'),
                contract_uuid: _.get(sameRouteRun, 'contract_uuid'),
                route_registry_uuid: _.get(sameRouteRun, 'route_registry_uuid'),
            });

            this.hideRunAdd();
        } else {
            this.addRunReal(this.state.index, {
                type,
                time,
                distance,
                route_uuid: _.get(prevRun, 'route_uuid'),
                route_number: _.get(prevRun, 'route_number'),
                contract_uuid: _.get(prevRun, 'contract_uuid'),
                route_registry_uuid: _.get(prevRun, 'route_registry_uuid'),
            });

            this.hideRunAdd();
        }
    }

    renderRunSelectModal() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                    <ModalTopMenuButton
                        className="_save"
                        title="Сохранить"
                        onClick={::this.addRun}
                    />
                </ContextTooltip>

                <ModalTopMenuButtonsSeparator/>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.hideRunAdd}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        const {timeInput, distanceInput} = this.getRunFields();
        const routeVariantNullRunInput = this.state.run_type === 'null';
        const routeInput = this.state.run_type === 'switch_out';

        const routeVariantInput = (this.state.run_type === 'production_forward') || (this.state.run_type === 'production_reverse');

        let types = _.map(_.omit(runs, [
            'switch_out',
            'switch_in'
        ]), (text, type) => ({
            value: type,
            label: text,
        }));

        const routes = _.uniqBy(_.map(this.state.shift.runs, (run) => ({
            value: run.route_uuid,
            label: run.route_name,
        })), 'value');

        return (
            <PageModal
                header={{title: 'Добавление рейса', buttons}}
                className="b-modal add-route-order-modal b-modal_centered"
                headerClassName="b-modal__header_dark"
            >
                <Block size="xl" title="Тип рейса">
                    <Select
                        value={this.state.run_type}
                        options={types}
                        onChange={::this.onRunTypeChange}
                    />
                </Block>
                {routeVariantNullRunInput ? (
                    <Block size="xl" title="Нулевой рейс">
                        <Select
                            value={this.state.route_variant_null_run_uuid}
                            options={this.state.routeVariantNullRuns}
                            onChange={::this.onRouteVariantNullRunUuidChange}
                        />
                    </Block>
                ) : null}
                {routeInput ? (
                    <Block size="lg" title="Переключение на маршрут">
                        <SelectAsync
                            value={this.state.route_uuid}
                            onChange={::this.onRouteUuidChange}
                            loadOptions={::this.loadRoutes}
                        />
                    </Block>
                ) : null}
                {routeVariantInput ? (
                    <Block size="xl" title="Маршрут">
                        <Select
                            value={this.state.route_uuid}
                            options={routes}
                            onChange={::this.onRouteUuidChange}
                        />
                    </Block>
                ) : null}
                {routeVariantInput ? (
                    <Block size="xl" title="Вариант движения">
                        <Select
                            value={this.state.route_variant_uuid}
                            options={_.map(_.filter(_.filter(this.state.routeVariants, {route_uuid: this.state.route_uuid}), item => !item.is_stop_point_list), (routeVariant) => ({
                                value: routeVariant.uuid,
                                label: routeVariant.name,
                            }))}
                            onChange={::this.onRouteVariantUuidChange}
                        />
                    </Block>
                ) : null}
                {distanceInput ? (
                    <Block size="md" title="Расстояние, м">
                        <Input
                            value={this.state.run_distance}
                            onChange={::this.onDistanceChange}
                        />
                    </Block>
                ) : null}
                {timeInput ? (
                    <Block size="md" title="Время, мин">
                        <Input
                            value={this.state.run_time}
                            onChange={::this.onTimeChange}
                        />
                    </Block>
                ) : null}
            </PageModal>
        );
    }

    onRunTypeChange(e) {
        const value = e ? e.value : null;

        this.setState({
            run_type: value,
        });
    }

    onRouteVariantNullRunUuidChange(e) {
        const value = e ? e.value : null;

        this.setState({
            route_variant_null_run_uuid: value,
            route_variant_null_run: e,
        });
    }

    onRouteUuidChange(e) {
        const value = e ? e.value : null;

        this.setState({
            route_uuid: value,
        });
    }

    async loadRoutes(input, callback) {
        let result;
        if (!input) {
            callback(null, {
                options: [],
                complete: false,
            });
        } else {
            result = await this.props.getRoutes({
                search: input,
                pagination: {
                    page: 1,
                    limit: 20,
                },
            });
        }

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: `${i.number} ${i.title}`,
                    value: i.uuid,
                })), 'label'),
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    onRouteUuidChange(e) {
        const value = e ? e.value : null;

        this.setState({
            route_uuid: value,
        });
    }

    onRouteVariantUuidChange(e) {
        const value = e ? e.value : null;

        this.setState({
            route_variant_uuid: value,
        });
    }

    onTimeChange({target: {value}}) {
        this.setState({
            run_time: value,
        });
    }

    onIndexChange({target: {value}}) {
        this.setState({
            index: value,
        });
    }

    onDistanceChange({target: {value}}) {
        this.setState({
            run_distance: value,
        });
    }

    getRunFields() {
        let timeInput = false;
        let distanceInput = false;

        switch (this.state.run_type) {
            // только время
            case 'parking':
            case 'dinner':
            case 'settling':
            case 'gap':
            case 'reshift':
                timeInput = true;
                break;
            case 'refill':
            case 'techno':
            case 'switch_out':
                timeInput = true;
                distanceInput = true;
                break;
        }

        return {
            timeInput,
            distanceInput,
        };
    }
}
