import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import debounce from 'throttle-debounce/debounce';

import {TableColumnFilterBase} from "components/ui/Table/Column/Filter/TableColumnFilterBase";

@propTypes({
    index: PropTypes.number.isRequired,
    column: PropTypes.object.isRequired,
    opened: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onFilterOpened: PropTypes.func
})

@defaultProps({
    opened: false,
    onFilterOpened: () => {
    }
})

export class TableColumnFilterTextSimple extends TableColumnFilterBase {

    searchCallbackDebounce = debounce(1000, ::this.onSearchChange);

    constructor(...args) {
        super(...args);

        Object.assign(this.state, {
            value: '',
        });
    }

    componentWillMount() {

        super.componentWillMount();

        if (this.props.column.filterValue) {
            this.setState({
                value: this.props.column.filterValue,
            });
        }
    }

    async componentDidUpdate() {
        if ($(".top-menu__link.top-menu__link_icon_filter.active").length === 0 && this.state.value.length > 0) {
            this.setState({
                value: '',
            });
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    async onSearchChange() {
        this.setState({value: this.refs.search.value});
        this.props.onChange(this.props.index, this.refs.search.value);
    }

    renderContent() {

        return (
            <div className="filtration__search">
                <input key="search" type="text" ref="search" className="filtration__pole" placeholder="Поиск"
                       onChange={this.searchCallbackDebounce} onKeyDown={::this.handleKeyPress}/>
                <input key="button" type="button" className="filtration__button"/>
            </div>
        );
    }
}