import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import _ from 'lodash';
import {success, error} from 'helpers/response';

const initialState = new Map({
});

export default function reducer(state = initialState, action = {}) {
    return state;
}

export const getActivity = (meta) => async (dispatch) => {
    try {
        const response = await api.system.getActivity(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};