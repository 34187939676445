import {rpc} from './session';
import _ from 'lodash';

let endPointComponent = "kurs";

async function subscriberEvent() {
    const componentPromise = await new Promise((resolve) => {
        setTimeout(() => {
          resolve(location.pathname.split("/").filter(el => el)[0]);
        }, 400)
      });
    /* либо kurs по умолчанию, либо utility для ЖКХ подсистемы в запросах */
    if (componentPromise === "utility") {
        endPointComponent = "utility"
    } else {
        endPointComponent = "kurs"
    }
    return await Promise.resolve("complete");
}

export async function getFreeDrivers(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.available.driver.list`, {}, {meta});
}

export async function getContracts(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.contract.list`, {}, {meta});
}

export async function getContract(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.contract.get`, {uuid});
}

export async function getGeometryFromContract(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.analytics.geometry_form_contract.list`, {}, {meta});
}

export async function createContract(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.contract.create`, data);
}

export async function updateContract(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.contract.update`, data);
}

export async function deleteContract(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.contract.delete`, data);
}

export async function getRepairPrograms(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.repair_program.list`, {}, {meta});
}

export async function getRepairProgram(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.repair_program.get`, {uuid});
}

export async function createRepairProgram(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.repair_program.create`, data);
}

export async function updateRepairProgram(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.repair_program.update`, data);
}

export async function deleteRepairProgram(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.repair_program.delete`, data);
}

export async function getContractWorks(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.contract_work.list`, {}, {meta});
}

export async function getContractWork(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.contract_work.get`, {uuid});
}

export async function createContractWork(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.contract_work.create`, data);
}

export async function updateContractWork(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.contract_work.update`, data);
}

export async function deleteContractWork(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.contract_work.delete`, data);
}

export async function getVehicles(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.list`, {}, {meta});
}

export async function getVehicle(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.get`, data);
}

export async function createVehicle(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.create`, data);
}

export async function updateVehicle(data) {
    await subscriberEvent();
    if (data.date_activation === null) data.date_activation = ``;
    if (data.date_disactivation === null) data.date_disactivation = ``;
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.update`, data);
}

export async function deleteVehicle(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.delete`, data);
}

export async function getVehicleWorks(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.work.list`, {}, {meta});
}

export async function getVehicleWork(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.work.get`, {uuid});
}

export async function createVehicleWork(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.work.create`, data);
}

export async function updateVehicleWork(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.work.update`, data);
}

export async function deleteVehicleWork(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.work.delete`, data);
}

export async function getVehicleMechanisms(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.mechanism.list`, {}, {meta});
}

export async function getVehicleMechanism(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.mechanism.get`, {uuid});
}

export async function createVehicleMechanism(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.mechanism.create`, data);
}

export async function updateVehicleMechanism(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.mechanism.update`, data);
}

export async function deleteVehicleMechanism(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.mechanism.delete`, data);
}

export async function getKursMaintenances(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.maintenance.list`, {}, {meta});
}

export async function getKursMaintenance(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.maintenance.get`, {uuid});
}

export async function createKursMaintenance(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.maintenance.create`, data);
}

export async function updateKursMaintenance(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.maintenance.update`, data);
}

export async function deleteKursMaintenance(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.maintenance.delete`, data);
}

export async function getStos(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.sto.list`, {}, {meta});
}

export async function getSto(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.sto.get`, {uuid});
}

export async function createSto(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.sto.create`, data);
}

export async function updateSto(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.sto.update`, data);
}

export async function deleteSto(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.sto.delete`, data);
}

export async function getStoWorks(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.sto_work.list`, {}, {meta});
}

export async function getStoWork(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.sto_work.get`, {uuid});
}

export async function createStoWork(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.sto_work.create`, data);
}

export async function updateStoWork(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.sto_work.update`, data);
}

export async function deleteStoWork(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.sto_work.delete`, data);
}

export async function getTasks(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task.list`, {}, {meta});
}

export async function getTask(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task.get`, {uuid});
}

export async function createTask(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task.create`, data);
}

export async function createMultipleTask(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task.create.multiple`, data);
}

export async function importTask(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task.import`, data);
}

export async function updateTask(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task.update`, data);
}

export async function updateTaskCheck(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task.update.check`, data);
}

export async function deleteTask(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task.delete`, data);
}

export async function repeatTask(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task.repeat`, data);
}

export async function getTaskTemplates(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task_template.list`, {}, {meta});
}

export async function getTaskTemplate(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task_template.get`, {uuid});
}

export async function createTaskTemplate(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task_template.create`, data);
}

export async function updateTaskTemplate(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task_template.update`, data);
}

export async function deleteTaskTemplate(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task_template.delete`, data);
}

export async function getRoads(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road.list`, {}, {meta});
}

export async function getRoad(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road.get`, {uuid});
}

export async function createRoad(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road.create`, data);
}

export async function updateRoad(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road.update`, data);
}

export async function deleteRoad(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road.delete`, data);
}

export async function getRoadParts(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road_part.list`, {}, {meta});
}

export async function getRoadPart(uuid, slice = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road_part.get`, _.merge({
        uuid,
    }, slice));
}

export async function getRoadPartWorkTypes(uuid, date) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road_part.work_types`, {uuid, date});
}

export async function getRoadPartWorkTypesMultiple(uuids, date, withoutUsed = false) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road_part.work_types.multiple`, {
        uuids,
        date,
        without_used: withoutUsed,
    });
}

export async function checkRoadPartWorkType(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road_part.work_type.check`, data);
}

export async function createRoadPart(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road_part.create`, data);
}

export async function updateRoadPart(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road_part.update`, data);
}

export async function deleteRoadPart(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road_part.delete`, data);
}

export async function getWaybills(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.waybill.list`, {}, {meta});
}

export async function getWaybill(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.waybill.get`, {uuid});
}

export async function createWaybill(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.waybill.create`, data);
}

export async function updateWaybill(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.waybill.update`, data);
}

export async function deleteWaybill(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.waybill.delete`, data);
}

export async function getDriversScoreSummary(drivers) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.drivers.score`, {drivers});
}

export async function getSupernumerarySituations(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.supernumerary_situation.list`, {}, {meta});
}

export async function getSupernumerarySituation(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.supernumerary_situation.get`, {uuid});
}

export async function createSupernumerarySituation(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.supernumerary_situation.create`, data);
}

export async function updateSupernumerarySituation(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.supernumerary_situation.update`, data);
}

export async function deleteSupernumerarySituation(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.supernumerary_situation.delete`, data);
}

export async function importKursVehicles(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.import`, data);
}

export async function importKursVehiclesGet(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle.import.get`, {uuid});
}

export async function listObjectVisits(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.object_visit.list`, {}, {meta});
}

export async function getTechnocards(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.technocard.list`, {}, {meta});
}

export async function getTechnocard(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.technocard.get`, {uuid});
}

export async function createTechnocard(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.technocard.create`, data);
}

export async function updateTechnocard(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.technocard.update`, data);
}

export async function deleteTechnocard(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.technocard.delete`, data);
}

export async function getTechnocardAssigns(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.technocard_assign.list`, {}, {meta});
}

export async function getTechnocardAssign(uuid) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.technocard_assign.get`, {uuid});
}

export async function createTechnocardAssign(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.technocard_assign.create`, data);
}

export async function updateTechnocardAssign(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.technocard_assign.update`, data);
}

export async function deleteTechnocardAssign(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.technocard_assign.delete`, data);
}

export async function getVehicleFuelEvents(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle_fuel_event.list`, {}, {meta});
}

export async function getVehicleFuelLevels(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.vehicle_fuel_level.list`, {}, {meta});
}

export async function getTaskViolations(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.task_violation.list`, {}, {meta});
}

export async function getSkpdiLogs(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.skpdi_log.list`, {}, {meta});
}

export async function getRoadRepairPart(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.road_repair_part.get`, data);
}

export async function skpdiImportTasks() {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.skpdi.import.tasks`, {});
}

export async function vehicleFuelRecalc(data) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.fuel.recalc`, data);
}

export async function unitTasksDoneChart(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.unit.tasks.done_chart`, {}, {meta});
}

export async function dateTasksDoneChart(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.date.tasks.done_chart`, {}, {meta});
}

export async function unitCountVehiclesChart(meta = {}) {
    await subscriberEvent();
    return await rpc.request(`com.rnis.${endPointComponent}.action.date.tasks.resources.count`, {}, {meta});
}

