import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import currentUser from 'helpers/current-user';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {deleteFaq, getFaq} from "store/reducers/portal/faq";

@connect(state => ({}), {getFaq, deleteFaq})

export default class PortalFaqComponent extends BaseTableWithEditorComponent {

    title = `Портал ${currentUser.user.title.SHORT_TITLE} → Вопрос-ответ`;
    baseUrl = '/portal/faq';

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Вопрос')
                .fromField('question'),

            new Column('Активна')
                .fromField('is_active')
                .withFilter('withActive', async () => {
                    return [
                        {
                            name: 'Активна',
                            uuid: 1,
                        },
                        {
                            name: 'Неактивна',
                            uuid: 0,
                        },
                    ];
                })
                .withDrawer(item => item.is_active ? '&check;' : '&times;'),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteFaq(data);
    }

    async loadData(meta) {
        return await this.props.getFaq(meta);
    }
}