import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import 'rc-time-picker/assets/index.css'
import TextFieldMask from 'components/ui/text-field-mask'
import moment from 'moment'
import PropTypes from 'prop-types'
import { propTypes } from 'react-props-decorators'

import systems from 'dictionaries/systems'
import { getEntityNames } from 'store/reducers/system'
import { getWaybills, updateWaybill } from 'store/reducers/kurs/waybills'
import { getUnits } from 'store/reducers/organizational_units/units'
import { success } from 'helpers/alerts'

import Button from 'components/ui/button'
import GlobalLoaderComponent from 'components/ui/global-loader'
import Datepicker from 'components/ui/form/datepicker'
import Page from 'components/ui/page'
import Select from 'components/ui/select'
import TableContainer from 'components/ui/Table/Container/TableContainer'
import TextField from 'components/ui/text-field'

import './index.less'

@propTypes({
  getEntityNames: PropTypes.func,
  getUnits: PropTypes.func,
  getWaybills: PropTypes.func,
  updateWaybill: PropTypes.func
})
@connect(state => ({}), {
  getEntityNames,
  getUnits,
  getWaybills,
  updateWaybill
})
class WaybillBatchClose extends Component {
  state = {
    isLoading: false,
    relatedNames: {},
    waybills: [],
    waybillsErrors: {},
    meta: {
      column_search: [
        {
          value: `date;${moment().startOf('day').format('DD-MM-YYYY')};${moment()
            .startOf('day')
            .format('DD-MM-YYYY')}`,
          column: 'waybills.date'
        }
      ]
    },
    units: [],
    selectedDate: moment().startOf('day'),
    selectedUnit: null
  }

  async componentDidMount() {
    this.fetchUnits()
    if (this.state.selectedUnit && this.state.selectedUnit.uuid) {
      const waybills = await this.fetchWaybills()
      this.loadRelatedEntities(waybills)
    }
  }

  selectUnitHandler = unit => {
    this.setState(
      prev => ({
        meta: {
          ...this.state.meta,
          filters: { ...prev.meta.filters, withUnits: unit ? [unit.value] : [] }
        },
        selectedUnit: unit
      }),
      () => {
        this.fetchWaybills()
      }
    )
  }

  selectDateHandler = e => {
    const { value: date } = e.target
    this.setState(
      {
        selectedDate: date,
        meta: {
          ...this.state.meta,
          column_search: [
            {
              value: `date;${date};${date}`,
              column: 'waybills.date'
            }
          ]
        }
      },
      () => {
        this.fetchWaybills()
      }
    )
  }

  getHeaderActions = () => {
    return [
      <Select
        className="waybill-batch-close-company-select"
        key="unit"
        isClearable={true}
        placeholder="Компания"
        options={this.state.units}
        value={this.state.selectedUnit ? this.state.selectedUnit.value : ''}
        onChange={this.selectUnitHandler}
      />,

      <Datepicker style="dark" value={this.state.selectedDate} onChange={this.selectDateHandler} />
    ]
  }

  fetchUnits = async () => {
    const response = await this.props.getUnits({
      pagination: {
        page: 1,
        limit: 1000
      },
      filters: {
        withComponent: 'utility'
      }
    })

    if (response.isOk) {
      this.setState({
        units: response.payload.items.map(unit => ({
          value: unit.uuid,
          label: unit.name
        }))
      })
    } else {
      response.showErrors()
    }
  }

  fetchWaybills = async () => {
    if (this.state.selectedUnit) {
      this.setState({
        isLoading: true
      })

      const response = await this.props.getWaybills(this.state.meta)

      if (response.isOk) {
        const clearedWaybills = response.payload.items
          .filter(({ status }) => status !== 'closed')
          .map(waybill => ({
            ...waybill,
            status: 'closed',
            arrival_mileage: '',
            fact_arrival_date: waybill.arrival_date || '',
            fact_arrival_time: waybill.arrival_time || '',
            fuel_consumption_fact: '',
            motohours: ''
          }))

        this.setState({
          waybills: clearedWaybills
        })

        this.loadRelatedEntities(clearedWaybills)
      } else {
        response.showErrors()
      }

      this.setState({
        isLoading: false
      })
    } else {
      this.setState({
        waybills: []
      })
    }
  }

  changeHandler = (waybillUuid, field, value) => {
    const { waybills } = this.state
    const waybillIndex = waybills.findIndex(({ uuid }) => waybillUuid === uuid)
    if (waybillIndex !== -1) {
      const waybill = { ...waybills[waybillIndex] }
      waybill[field] = value
      this.setState({
        waybills: [...waybills.slice(0, waybillIndex), waybill, ...waybills.slice(waybillIndex + 1)]
      })
    }
  }

  checkWaybillsBeforeSave = () => {
    const waybillsErrors = {}
    let withoutErrors = true

    this.state.waybills.forEach(waybill => {
      const errors = {
        fact_arrival_date: !waybill.fact_arrival_date,
        fact_arrival_time: !waybill.fact_arrival_time,
        fuel_consumption_fact: !waybill.fuel_consumption_fact,
        arrival_mileage: !waybill.arrival_mileage && !waybill.motohours,
        motohours: !waybill.arrival_mileage && !waybill.motohours
      }

      waybillsErrors[waybill.uuid] = errors

      if (Object.values(errors).some(error => error)) {
        withoutErrors = false
      }
    })

    this.setState({
      waybillsErrors
    })

    return withoutErrors
  }

  getTableBody = () => {
    const { relatedNames, waybillsErrors } = this.state

    return this.state.waybills.map(waybill => {
      return (
        <tr key={waybill.number}>
          <td>{waybill.number}</td>
          <td className="align-center">{relatedNames[waybill.vehicle_uuid]}</td>
          <td>{relatedNames[waybill.task_uuid]}</td>
          <td>{relatedNames[waybill.driver_uuid]}</td>
          <td>
            <Datepicker
              value={waybill.fact_arrival_date}
              error={waybillsErrors[waybill.uuid] && waybillsErrors[waybill.uuid].fact_arrival_date}
              onChange={e => this.changeHandler(waybill.uuid, 'fact_arrival_date', e.target.value)}
            />
          </td>
          <td>
            <TextFieldMask
              mask="99:99"
              placeholder="чч:мм"
              withTimeIcon={true}
              error={waybillsErrors[waybill.uuid] && waybillsErrors[waybill.uuid].fact_arrival_time}
              value={waybill.fact_arrival_time}
              onChange={e => this.changeHandler(waybill.uuid, 'fact_arrival_time', e.target.value)}
            />
          </td>
          <td>
            <TextField
              size="lg"
              type="number"
              placeholder="Расход"
              error={
                waybillsErrors[waybill.uuid] && waybillsErrors[waybill.uuid].fuel_consumption_fact
              }
              value={waybill.fuel_consumption_fact}
              onChange={e =>
                this.changeHandler(waybill.uuid, 'fuel_consumption_fact', e.target.value)
              }
            />
          </td>
          <td>
            <TextField
              size="lg"
              className={`${waybill.motohours ? 'text-field-disabled' : ''}`}
              type="number"
              placeholder="Пробег"
              error={waybillsErrors[waybill.uuid] && waybillsErrors[waybill.uuid].arrival_mileage}
              value={waybill.arrival_mileage}
              onChange={e => this.changeHandler(waybill.uuid, 'arrival_mileage', e.target.value)}
            />
          </td>
          <td>
            <TextField
              size="lg"
              className={`${waybill.arrival_mileage ? 'text-field-disabled' : ''}`}
              type="number"
              placeholder="Моточасы"
              error={waybillsErrors[waybill.uuid] && waybillsErrors[waybill.uuid].motohours}
              value={waybill.motohours}
              onChange={e => this.changeHandler(waybill.uuid, 'motohours', e.target.value)}
            />
          </td>
        </tr>
      )
    })
  }

  loadRelatedEntities = async (data = []) => {
    const vehicles = []
    const drivers = []
    const tasks = []

    data.forEach(item => {
      vehicles.push({
        class: 'App\\Model\\Vehicle',
        uuid: item.vehicle_uuid,
        source: 'vehicles'
      })
      drivers.push({
        class: 'App\\Model\\UserInfo',
        uuid: item.driver_uuid,
        source: 'auth'
      })
      tasks.push({
        class: 'App\\Model\\Task',
        uuid: item.task_uuid,
        source: 'kurs'
      })
    })

    const response = await this.props.getEntityNames([...vehicles, ...drivers, ...tasks])

    if (response.isOk) {
      const relatedNames = {}
      response.payload.items.forEach(item => {
        relatedNames[item.uuid] = item.name
      })
      this.setState({
        relatedNames
      })
    }
  }

  saveWaybill = async data => {
    return await this.props.updateWaybill(data)
  }

  save = async () => {
    if (this.checkWaybillsBeforeSave()) {
      this.setState({
        isLoading: true
      })

      const responses = await Promise.all(
        this.state.waybills.map(async waybill => {
          return await this.saveWaybill(waybill)
        })
      )

      if (responses) {
        const savedWaybillUuids = responses
          .filter(({ isOk }) => isOk)
          .map(response => response.payload.uuid)

        this.setState({
          waybills: this.state.waybills.filter(({ uuid }) => !savedWaybillUuids.includes(uuid))
        })

        success(`Закрыто ${savedWaybillUuids.length} ПЛ`)
      }

      this.setState({
        isLoading: false
      })
    }
  }

  getTableWarning = title => {
    return (
      <tr>
        <td className="table-warning" colSpan="9">
          {title}
        </td>
      </tr>
    )
  }

  render() {
    if (!window.RNIS_SETTINGS.waybill_batch_close) return null

    const { isLoading, selectedUnit, waybills } = this.state

    return (
      <Page
        pageId="waybill-batch-close"
        title={`${systems.utility} → Пакетное закрытие путевых листов`}
        headerActions={this.getHeaderActions()}
      >
        {isLoading ? <GlobalLoaderComponent /> : ''}

        <div className="Table">
          <TableContainer>
            <table className="b-table b-table-no-hover">
              <thead style={{ position: 'static' }}>
                <tr className="b-table__header wrap-normal">
                  <th>№ ПЛ</th>
                  <th>ТС</th>
                  <th>Задание</th>
                  <th>Водитель</th>
                  <th>Факт дата возвращения</th>
                  <th>Факт время возвращения</th>
                  <th>Расход</th>
                  <th>Пробег по ПЛ</th>
                  <th>Моточасы</th>
                </tr>
              </thead>
              <tbody>
                {!selectedUnit && this.getTableWarning('Необходимо выбрать компанию')}
                {this.getTableBody()}
              </tbody>
            </table>
          </TableContainer>
        </div>

        {waybills.length ? (
          <div style={{ textAlign: 'right' }}>
            <Button text="Закрыть ПЛ" size="md" color="red" shadow="red" onClick={this.save} />
          </div>
        ) : (
          ''
        )}
      </Page>
    )
  }
}

export default WaybillBatchClose
