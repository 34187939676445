import { Map } from 'immutable';
import { api } from 'helpers/api';
import { success, error } from 'helpers/response';
import { setUniqueList, SET_UNIQUE_LIST } from './actions';

const initialState = {
	uniqueList: {},
	uniqueListIsLoading: false,
	uniqueListIsLoaded: false
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SET_UNIQUE_LIST:
			return { ...state, uniqueList: action.payload.uniqueList, uniqueListIsLoading: action.payload.uniqueListIsLoading, uniqueListIsLoaded: action.payload.uniqueListIsLoaded };
	}

	return state;
}

export const getOrder = (uuid) => async (dispatch) => {
	try {
		const response = await api.geo.getOrder(uuid);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getOrders = (meta, fullMethod) => async (dispatch) => {
	try {
		const response = await api.geo.getOrders(meta, fullMethod);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getUniqueVehicles = (meta) => async (dispatch) => {
	try {
		const response = await api.geo.getUniqueVehicles(meta);
		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const exportOrders = (meta) => async (dispatch) => {
	try {
		const response = await api.geo.exportOrders(meta);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const exportOrdersExcel = (meta, from, to) => async (dispatch) => {
	try {
		const response = await api.geo.exportOrdersExcel(meta, from, to);
		return response;
	} catch (e) {
		return error(e);
	}
};

export const exportOrderGet = (uuid) => async (dispatch) => {
	try {
		const response = await api.geo.exportOrderGet(uuid);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const exportOrderList = () => async (dispatch) => {
	try {
		const response = await api.geo.exportOrderList();

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getOrderVehicles = (data) => async (dispatch) => {
	try {
		const response = await api.geo.orderVehicles(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const updateOrder = (data) => async (dispatch) => {
	try {
		const response = await api.geo.updateOrder(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const deleteOrder = (data) => async (dispatch) => {
	try {
		const response = await api.geo.deleteOrder(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const cloneOrder = (data) => async (dispatch) => {
	try {
		const response = await api.geo.cloneOrder(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const createDefect = (data) => async (dispatch) => {
	try {
		const response = await api.geo.createDefect(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const updateDefect = (data) => async (dispatch) => {
	try {
		const response = await api.geo.updateDefect(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const generateOrder = (data) => async (dispatch) => {
	try {
		const response = await api.geo.generateOrder(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getOrderRecalc = (orderUuid) => async (dispatch) => {
	try {
		const response = await api.geo.getOrderRecalc(orderUuid);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const updateOrderRecalc = (data) => async (dispatch) => {
	try {
		const response = await api.geo.updateOrderRecalc(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const resourceCheck = (data) => async (dispatch) => {
	try {
		const response = await api.geo.resourceCheck(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const resourceGroupCheck = (data) => async (dispatch) => {
	try {
		const response = await api.geo.resourceGroupCheck(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getDriversScoreSummary = (drivers, from, to) => async (dispatch) => {
	try {
		const response = await api.geo.getDriversScoreSummary(drivers, from, to);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getOrdersUniqueList = (meta) => async (dispatch) => {
	dispatch(setUniqueList({uniqueListIsLoading: true, uniqueList: {}, uniqueListIsLoaded: false}));
	try {
		const response = await api.geo.getOrdersUniqueList(meta);
		dispatch(setUniqueList({uniqueListIsLoading: false, uniqueList: response.payload, uniqueListIsLoaded: true}));
		return success(response);
	} catch (e) {
		dispatch(setUniqueList({uniqueListIsLoading: false, uniqueList: {}, uniqueListIsLoaded: false}));
		return error(e);
	}
};
