import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getRepairPrograms = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.getRepairPrograms(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRepairProgram = (uuid) => async (dispatch) => {
    try {
        const response = await api.commdept.getRepairProgram(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createRepairProgram = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.createRepairProgram(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateRepairProgram = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.updateRepairProgram(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteRepairProgram = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.deleteRepairProgram(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
