import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import debounce from 'throttle-debounce/debounce';

import {TableColumnFilterBase} from "components/ui/Table/Column/Filter/TableColumnFilterBase";

import {TableColumnFilterSelect} from "components/ui/Table/Column/Filter/TableColumnFilterSelect";

import {TableColumnFilterTextSimple} from "components/ui/Table/Column/Filter/TableColumnFilterTextSimple";
import _ from 'lodash';

@propTypes({
    index: PropTypes.number.isRequired,
    column: PropTypes.object.isRequired,
    opened: PropTypes.bool,
    onChange: PropTypes.func,
    onFilterOpened: PropTypes.func,
})

@defaultProps({
    opened: false,
    onFilterOpened: () => {
    },
})


export class TableColumnFilterModes extends TableColumnFilterBase {

    constructor(...args) {
        super(...args);

        this.state = {
            selected: [],
        };

    }

    componentWillMount() {
        super.componentWillMount();
        const tabs = {
            full: {
                id: this.props.column.filterName,
                name: 'Полный'
            },
            partial: {
                id: this.props.column.filterName2,
                name: 'Частичный'
            }
        }

        this.setState({
            tabs,
            currentFilter: tabs.full.id
        })
        if (this.props.column.filterValue) {
            this.setState({
                selected: this.props.column.filterValue,
            });
        }

    }

    async componentDidUpdate() {
        if ($(".top-menu__link.top-menu__link_icon_filter.active").length === 0 && this.state.selected.length > 0 && !this.state.visible) {
            this.setState({
                selected: [],
            });
        }
    }
    onChangeTabs(tab) {
        this.setState({
            ...this.state,
            currentFilter: tab
        })

        this.props.column.onChangeMultiFilter(tab, this.props.index)
    }

    renderTabs() {
        return(
            <div className='tabs'>
                {
                    Object.values(this.state.tabs).map((tab) => {
                        return (
                            <div key={tab.id} className={`tabs__item ${this.state.currentFilter === tab.id ? 'active' : ''}`} onClick={() => {this.onChangeTabs(tab.id)}} role='button'>{tab.name}</div>
                        )
                    })
                }
            </div>
            )   
    }
    hasValue = () => {
        return this.state.selected.length > 0;
    }

    onChange = (index, selected) => {
        this.setState({selected});
        this.props.onChange(this.props.index, selected);
    }

    renderContent() {
        return (
            <div className='filter-with-modes__wrapper'>
                {this.renderTabs()}
                <TableColumnFilterSelect
                    ref={`tableFilterSelect${this.props.index}`}
                    index={this.props.index}
                    column={this.props.column}
                    onChange={this.onChange}
                    onFilterOpened={this.onFilterOpened}
                    opened={this.state.currentFilter === this.state.tabs.full.id}
                    classNameRoot='filter-nested'
                />
                <TableColumnFilterTextSimple
                    index={this.props.index}
                    column={this.props.column}
                    onChange={this.props.onChange}
                    onFilterOpened={this.onFilterOpened}
                    opened={this.state.currentFilter === this.state.tabs.partial.id}
                    classNameRoot='filter-nested'
                />
            </div>     
        );
    }
}