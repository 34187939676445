import React, {Component} from 'react';
import {defaultProps, propTypes} from 'react-props-decorators';
import {Map, List} from 'immutable';

import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';


import './line-charts.less';

@defaultProps({
  itemTypes: new Map({
    'план': 'green',
    'факт': 'red'
  }),
  items: new List([
    {name: '30 января', 'факт': 1500, 'план': 3000, amt: 2400},
    {name: '6 февраля', 'факт': 2000, 'план': 3200, amt: 2210},
    {name: '13 февраля', 'факт': 1500, 'план': 4000, amt: 2290},
    {name: '20 февраля', 'факт': 2780, 'план': 3908, amt: 2000},
    {name: '27 февраля', 'факт': 1890, 'план': 4800, amt: 2181},
    {name: '6 марта', 'факт': 2390, 'план': 3800, amt: 2500},
    {name: '13 марта', 'факт': 3490, 'план': 4300, amt: 2100},
    {name: '20 марта', 'факт': 3490, 'план': 4300, amt: 2100},
  ])
})

export default class LineChartComponent extends Component {

  render() {
    const legendPayload = this.props.itemTypes
      .map((color, key) => ({key: `${key}`, color: color}))
      .toArray();

    const Lines = this.props.itemTypes
      .map(::this.renderLine)
      .toArray();

    return (
      <div className="line-chart-example">
        <ResponsiveContainer>
          <LineChart data={this.props.items.toArray()}
                     margin={{top: 0, right: 0, left: 0, bottom: 0}}>

            <XAxis dataKey="name" name="Hello"/>
            <YAxis />
            <CartesianGrid strokeDasharray="3 3"/>
            <Tooltip/>

            <Legend
              content={::this.renderLegend}
              verticalAlign="top"
              align="right"
              height={36}
              payload={legendPayload} />

            {Lines}

          </LineChart>
        </ResponsiveContainer>

      </div>
    );
  }

  renderLine(color, key){
    return (
      <Line
        key={`line-${key}`}
        type="monotone"
        dataKey={key}
        strokeWidth="2"
        className={`line-chart-${color}`}
        dot={{r: 6, className: `line-dot-${color}`}}
        activeDot={{r: 8, className: `line-dot-${color}-active`}}/>
    );
  }

  renderLegend(props) {
    const {payload} = props;

    return (
      <div className="line-chart-legend">
        {
          payload.map((entry, index) => (
            <div key={`item-${index}`} className={`legend-color-${entry.color}`}><i></i><span>{entry.key}</span></div>
          ))
        }
      </div>
    );
  }
}
