import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {deleteFaq, getFaq} from "store/reducers/portal/faq";
import {deletePage, getPages} from "store/reducers/portal/pages";
import IconButton from "components/ui/icon-button";
import {deleteContact, getContacts} from "store/reducers/mobile/contacts";
import contact_types from "dictionaries/contact_types";
import {deleteReportSchedule, getReportSchedules} from "store/reducers/reports/report_schedules";
import _ from 'lodash';
import {getEntityNames} from "store/reducers/system";
import {getReports} from "store/reducers/reports/reports";
import {State} from "components/ui/state";
import ReactDOMServer from 'react-dom/server';

import './index.less';
import ContextTooltip from "components/ui/context-tooltip";

@connect(state => ({}), {getReportSchedules, deleteReportSchedule, getEntityNames, getReports})

export default class ReportSchedules extends BaseTableWithEditorComponent {

    title = 'Отчеты → Формирование отчетов';
    baseUrl = '/reports/schedule';

    componentDidMount() {
        this.loadReports();
    }

    async loadReports() {
        const response = await this.props.getReports();
        if (response.isOk) {
            this.setState({
                reports: _.mapValues(_.keyBy(_.flatten(_.map(response.payload.items, 'items')), 'uri'), 'name'),
            });
        } else {
            response.showErrors();
        }
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    renderHeaderActions() {
        return _.concat([
            <ContextTooltip key="kurs.sto.back" code="kurs.sto.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ], super.renderHeaderActions());
    }

    onClose() {
        this.props.router.push('/reports');
    }

    renderTable() {
        if (!this.state.reports) {
            return null;
        }

        return super.renderTable();
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Название отчета')
                .fromField('report_uri')
                .denyColumnFilter()
                .withDrawer((item) => _.get(this.state.reports, item.report_uri)),

            new Column('Название шаблона')
                .fromField('report_template.name')
                .withDrawer((item) => this.state.related.get(item.report_template_uuid)),

            new Column('Периодичность')
                .fromField('report_template_uuid')
                .denyColumnFilter()
                .withDrawer((item) => `${this.renderPeriod(item)}: ${item.time}`),

            new Column('Отправка на email')
                .fromField('send_email')
                .denyColumnFilter()
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.send_email}/>)),

        ]);
    }

    renderPeriod(item) {
        switch (item.type) {
            case 'daily':
                return 'Ежедневно';
            case 'days':
                return _.map(_.pickBy(item.days, selected => selected), (value, day) => {
                    return _.get({
                        monday: 'Пн',
                        tuesday: 'Вт',
                        wednesday: 'Ср',
                        thursday: 'Чт',
                        friday: 'Пт',
                        saturday: 'Сб',
                        sunday: 'Вс',
                    }, day);
                }).join(', ');
            case 'monthly':
                return 'Последний день месяца';
        }

        return '-';
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const types = _.map(_.filter(_.map(result, 'report_template_uuid')), (uuid) => ({
            class: 'App\\Model\\ReportTemplate',
            uuid: uuid,
            source: 'reports',
        }));
        const response = await this.props.getEntityNames(types);
        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    async deleteItem(data) {
        return await this.props.deleteReportSchedule(data);
    }

    async loadData(meta) {
        return await this.props.getReportSchedules(meta);
    }
}