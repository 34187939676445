import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";
import {getUnits} from "store/reducers/organizational_units/units";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import FileReaderInput from 'react-file-reader-input';

import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import {api} from "helpers/api";
import {createVehicleWork} from "store/reducers/kurs/vehicle_works";
import TableContainer from "components/ui/Table/Container/TableContainer";
import UtilityDetailAddForm from "components/modules/utility/vehicles/vehicle-editor-form/operation-add-form/detail-add-form/index";
import {createVehicleMechanism} from "store/reducers/kurs/vehicle_mechanisms";
import {getTasks} from "store/reducers/kurs/tasks";
import moment from "moment";
import formats from "dictionaries/formats";
import Button from "components/ui/button";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import TabBlock from "components/ui/tabs/tab-block";

@propTypes({
    uuid: PropTypes.string.isRequired,
})

@connect(state => ({}), {getTasks, getEntityNames})

export default class UtilityVehicleTasksForm extends BaseEditorFormComponent {

    state = {
        from: moment().subtract(1, 'day'),
        to: moment(),
        tasks: [],
        related: new EntityList,
        loadIndex: 0,
    };

    componentDidMount() {
        this.loadData();
    }

    async setValue(field, value) {
        super.setValue(field, value);

        this.loadData();
    }

    async loadData() {
        if (!this.state.from || !this.state.to) {
            return;
        }

        const loadIndex = this.state.loadIndex + 1;
        this.setState({
            loadIndex,
        });

        const response = await this.props.getTasks({
            filters: {
                withVehicle: this.props.uuid,
                withPeriod: [
                    moment(this.state.from).format(formats.DATETIME_API),
                    moment(this.state.to).format(formats.DATETIME_API),
                ],
            },
        });

        if (this.state.loadIndex !== loadIndex) {
            return;
        }

        if (response.isOk) {
            await this.setState({
                tasks: response.payload.items,
            });
            this.loadRelatedEntities();
        } else {
            response.showErrors();
        }
    }

    async loadRelatedEntities() {
        const result = this.state.tasks;

        const units = _.map(_.filter(_.map(result, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const workTypes = _.map(_.filter(_.map(_.flatten(_.map(result, 'items')), 'work_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\WorkTypes\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const statuses = _.map(_.filter(_.map(result, 'status_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursTaskStatuses\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(_.concat(units, workTypes, statuses));

        if (response.isOk) {
            this.state.related.add(response);
            this.forceUpdate();
        }
    }

    getError(fieldName) {
        if ((fieldName === 'from') && !this.state.from) {
            return 'Обязательно к заполнению';
        }
        if ((fieldName === 'to') && !this.state.to) {
            return 'Обязательно к заполнению';
        }

        return super.getError(fieldName);
    }

    render() {
        return (
            <div>
                <TabBlock>
                    <Block size="sm" title="Дата с" className="expand-right">
                        {this.datepicker('from')}
                    </Block>
                    <Block size="sm" title="Дата по">
                        {this.datepicker('to')}
                    </Block>
                    <Block size="xl" title="">
                        <div className="Table">
                            <TableContainer>
                                <table className="b-table">
                                    <thead>
                                    <tr>
                                        <th>№ задания</th>
                                        <th>Дата создания</th>
                                        <th>Дата закрытия</th>
                                        <th>Организация</th>
                                        <th>Категория работ</th>
                                        <th>Участок</th>
                                        <th>Статус</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.tasks.map(::this.renderTask)}
                                    </tbody>
                                </table>
                            </TableContainer>
                        </div>
                    </Block>
                </TabBlock>
            </div>
        );
    }

    renderTask(task) {
        return _.map(task.items, (vehicleTaskItem, index) => (
            <tr key={`${task.uuid}:${index}`}>
                <td>{task.number}</td>
                <td>{moment(task.date).format(formats.DATE)}</td>
                <td>-</td>
                <td>{this.state.related.getReact(task.unit_uuid)}</td>
                <td>{vehicleTaskItem.work_type_uuid ? this.state.related.getReact(vehicleTaskItem.work_type_uuid) : '-'}</td>
                <td>{(vehicleTaskItem.geometry_type === 'road') ? vehicleTaskItem.geometry[0].road : 'Произвольный маршрут'}</td>
                <td>{this.state.related.getReact(task.status_uuid)}</td>
            </tr>
        ));
    }

}