import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import PageModal from 'components/ui/page-modal';

import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import * as alerts from "helpers/alerts";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import {getRegisterRequest, updateRegisterRequest} from "store/reducers/portal/register_requests";
import moment from "moment";
import formats from "dictionaries/formats";
import systems from "dictionaries/systems";
import Button from "components/ui/button";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import {
    createCooperationAgreementWarning,
    getCooperationAgreementWarnings,
    updateCooperationAgreement, updateCooperationAgreementWarning
} from "store/reducers/portal/cooperation_agreements";
import {getTemplateDocuments} from "store/reducers/portal/template_documents";
import {xmlVerify} from "store/reducers/ecp/ecp";
import Select from "components/ui/select";
import Script from 'react-load-script';
import * as rfc2253 from 'rfc2253';
import EasyXml from 'easyxml';
import {State} from "components/ui/state";
import Textarea from "components/ui/form/textarea";
import currentUser from 'helpers/current-user';
import ValidationError from "components/ui/validation-error";

@propTypes({
    item: PropTypes.object,
    onClose: PropTypes.func,
})

@connect(state => ({}), {
    createCooperationAgreementWarning,
    getDictionaryList,
})

export default class CooperationAgreementOperatorWarningAdd extends BaseEditor {

    state = {
        item: {},
        related: new EntityList,
        portal_cooperation_agreement_warnings: [],
    };

    async componentWillMount() {
        this.setState({
            item: {
                cooperation_agreement_uuid: this.props.item.uuid,
                user_apply_warning_uuid: currentUser.uuid(),
                warning_date_apply: moment().format(formats.DATETIME_API),
            },
        });
        this.loadDictionaries([
            'portal_cooperation_agreement_warnings',
        ]);
    }

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <div>
                <PageModal
                    header={{title: 'Вынесение предупреждения', buttons}}
                    onClose={this.props.onClose}
                    className={`profile-modal b-modal-register-requests-history AgreementWarningAdd AgreementCooperation`}
                    withFade={false}
                >
                    {this.renderContent()}
                </PageModal>
            </div>
        );
    }

    onCauseChange(e) {
        const value = e ? e.value : null;

        let item = this.state.item;
        item.warning_cause_uuid = value;
        this.setState({item});
    }

    onCommentChange({target: {value}}) {
        let item = this.state.item;
        item.text_warning = value;
        this.setState({item});
    }

    getError(fieldName) {
        const field = 'cooperationAgreementWarning.' + _.findLast(fieldName.split('.'));

        return _.get(this.state.errors, field) || _.get(this.state.errors, fieldName);
    }

    renderContent() {
        const loader = this.state.saving ? (<GlobalLoaderComponent/>) : null;

        return (
            <div className="b-modal-agreement">
                {loader}
                <Block title="Причина">
                    <Select options={this.state.portal_cooperation_agreement_warnings} value={_.get(this.state.item, 'warning_cause_uuid')} onChange={::this.onCauseChange}/>
                    <ValidationError error={this.getError('warning_cause_uuid')}/>
                </Block>
                <Block title="Сообщение">
                    <Textarea value={_.get(this.state.item, 'text_warning')} onChange={::this.onCommentChange}/>
                    <ValidationError error={this.getError('text_warning')} className="relative"/>
                </Block>
                <div className="b-block _sm">
                    <div className="b-modal_button" onClick={::this.submit}>Вынести предупреждение</div>
                </div>
            </div>
        );
    }

    async submit() {
        this.setState({saving: true});

        const response = await this.props.createCooperationAgreementWarning(this.state.item);

        this.setState({saving: false});

        if (response.isOk) {
            this.props.onClose(true);
        } else {
            response.showErrors();
            this.setState({
                errors: response.validationErrors
            });
        }
    }
}