import React from 'react';
import ReactDOMServer from 'react-dom/server';
import MapVehicleIcon from "components/ui/map-vehicle-icon";
import L from 'leaflet';
import './garbage-icon.less'

export default class GarbageIcon {

    static getIcon(type) {
        const html = `<i class="map-container__obj-icon map-container__obj-icon_${type}"/>`;

        let className = `map-container__obj-item map-container__obj-item_red map-container__obj-item_${type}`;
        const size = (type === 'op') ? [
            46,
            40,
        ] : [
            32,
            28,
        ];
        return new L.DivIcon({
            html: html,
            className: className,
            iconSize: new L.Point(size[0], size[1]),
            iconAnchor: new L.Point(size[0] / 2, size[1]),
        });
    }
}