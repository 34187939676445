import _ from 'lodash';


export default class Column {
    // Название
    name = null;
    // Поле
    field = null;
    // нужен ли debounce
    isDebounce = null;
    // Обработчик
    data = null;
    // Доступно для фильтрации
    filterable = true;
    // Доступно для сортировки
    orderable = true;
    // Название фильтра
    filterName = null;
    // Замыкание для загрузки фильтра по столбцу
    filter = null;
    // Асинхронный фильтр
    filterAsync = false;
    // Выравнивание столбца
    align = null;
    // Класс ячейки
    className = null;
    // Ширина столбца
    width = null;
    // Положение попапа фильтрации
    filterPosition = 'right';
    // Тип фильтрации
    filterType = 'text';

    //Нужно ли переключение режима фильтра
    toggleTypeFilter = false;

    constructor(name) {
        this.name = name;
    }

    fromField(field) {
        this.field = field;
        return this;
    }

    withDrawer(callback) {

        if (_.isString(callback)) {
            this.fromField(callback);
        }
        this.data = callback;
        return this;
    }

    denyColumnFilter() {
        this.filterable = false;
        return this;
    }

    denyOrder() {
        this.orderable = false;
        return this;
    }

    withClassName(className) {
        this.className = className;
        return this;
    }

    withDebounce(isDebounce) {
        this.isDebounce = isDebounce;
        return this;
    }

    withFilter(filterName, callback, position = 'right') {
        this.filterName = filterName;
        this.filter = callback;
        this.filterAsync = false;
        this.filterPosition = position;
        return this;
    }

    withAsyncFilter(filterName, callback) {
        this.filterName = filterName;
        this.filter = callback;
        this.filterAsync = true;
        return this;
    }

    withDateFilter() {
        return this.withFilterType('date');
    }

    withNumberFilter() {
        return this.withFilterType('number');
    }

    withFilterType(type) {
        this.filterType = type;
        return this;
    }

    withMultiFilter(multiFilter){
        this.toggleTypeFilter = true;
        this.withAsyncFilter(multiFilter.async.filterName, multiFilter.async.callback)
        this.withFilterSimple(multiFilter.simple.filterName)
        this.onChangeMultiFilter = multiFilter.parentCallback
        return this;
    }

    withFilterSimple(filterName) {
        this.filterName2 = filterName;
        return this.withFilterType('simple');
    }

    withAlign(align) {
        this.align = align;
        return this;
    }

    withWidth(width) {
        this.width = width;
        return this;
    }

    defaultHidden() {
        this.hidden = true;
        return this;
    }
}
