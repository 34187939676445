import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import Page from 'components/ui/page';

import {connect} from 'react-redux';

import GlobalLoaderComponent from "components/ui/global-loader";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import systems from "dictionaries/systems";
import GatnVehicleSelect from "components/modules/maps/gatn/vehicle_select";
import MapComponent from "components/ui/map";
import Datepicker from "components/ui/form/datepicker";
import moment from "moment";
import formats from "dictionaries/formats";
import Input from "components/ui/form/input";
import {getHistory, getHistoryByVehicle} from "store/reducers/maps";
import './maps.less';
import debounce from 'throttle-debounce/debounce';
import TelematicsDevice from "components/modules/maps/telematics-packet";
import DirectionalPolyline from "components/ui/map/objects/directional-polyline";
import HistoryPointMarker from "components/ui/map/markers/history-point-marker";
import VehicleMarker from "components/ui/map/markers/vehicle-marker";
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
const Handle = Slider.Handle;

@connect(state => ({}), {getHistory, getHistoryByVehicle, getVehicleList})

export default class MapsHistoryComponent extends Component {

    state = {
        loading: true,
        vehicles: [],
        history: [],
        component: null,
        selectedVehicle: null,
        date: moment().startOf('day'),
        from: '00:00',
        to: moment().format(formats.TIME),
        currentTime: null,
        playing: false,
    };

    playInterval = null;

    reloadDebounce = debounce(500, ::this.reload);

    async componentWillUpdate(props, state) {
        if (props.params.component !== state.component) {
            await this.setState({
                component: props.params.component,
            });
            this.loadVehicles();
        }
    }

    componentDidMount() {
        this.forceUpdate();
    }

    async loadVehicles() {
        const response = await this.props.getVehicleList({
            filters: {
                withActiveBnso: true,
                withComponent: this.state.component,
            },
        });

        if (response.isOk) {
            this.setState({
                vehicles: _.sortBy(response.payload.items, 'state_number'),
            });
        } else {
            response.showErrors();
        }
        this.setState({loading: false});
    }

    async selectVehicle(vehicleUuid) {
        await this.setState({
            selectedVehicle: vehicleUuid,
        });
        this.reloadDebounce();
    }

    render() {
        const loader = this.state.loading === true
            ? <GlobalLoaderComponent block={false} style={{zIndex: 99999}}/>
            : '';

        let components = [];

        let title = `${systems[this.state.component]} → История перемещения ТС`;

        let selectedVehicles = {};
        selectedVehicles[this.state.selectedVehicle] = true;
        components.push(<GatnVehicleSelect
            key="vehicle-select"
            vehicles={this.state.vehicles}
            selectedVehicles={selectedVehicles}
            toggleVehicle={::this.selectVehicle}
        />);
        console.log("🚀 ~ file: maps.js ~ line 179 ~ MapsHistoryComponent ~ render ~  this.state.history.length",  this.state.history.length)

        const currentHistory = this.getCurrentHistory();
        const start = _.first(this.state.history);
        const end = _.last(this.state.history);
        this.state.history.length
        return (
            <Page
                pageId="MapsHistory"
                title={title}
                headerActions={this.getHeaderActions()}
            >
                {loader}
                <MapComponent
                    ref="map"
                    components={components}
                >
                    {(this.refs.map && (this.state.history.length > 0)) ? ([
                        <DirectionalPolyline
                            key="path"
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            coordinates={this.getHistoryCoordinates()}
                        />,
                        <HistoryPointMarker
                            key="start"
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            latitude={_.first(this.state.history).lat}
                            longitude={_.first(this.state.history).lng}
                            title={`Начало: ${moment.unix(start.type).format(formats.DATETIME)}<br/>Координаты: ${start.lat}, ${start.lng}<br/>Скорость: ${start.speed} км/ч`}
                        />,
                        <HistoryPointMarker
                            key="end"
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            latitude={_.last(this.state.history).lat}
                            longitude={_.last(this.state.history).lng}
                            title={`Окончание: ${moment.unix(end.type).format(formats.DATETIME)}<br/>Координаты: ${end.lat}, ${end.lng}<br/>Скорость: ${end.speed} км/ч`}
                        />,
                        currentHistory ? (
                            <VehicleMarker
                                key="current-history"
                                map={this.refs.map}
                                leafletMap={this.refs.map.getWrappedInstance().map}
                                latitude={currentHistory.lat}
                                longitude={currentHistory.lng}
                                device={currentHistory}
                                vehicle={_.find(this.state.vehicles, {uuid: this.state.selectedVehicle})}
                                fast={true}
                            />
                        ) : null,
                    ]) : null}
                </MapComponent>
                {(this.state.history.length > 0) ? (
                    <div className="history-player">
                        <div className="play" onClick={::this.togglePlay}>{this.state.playing ? '||' : '►'}</div>
                        <div className="slider">
                            <Slider
                                value={this.timeToInt(this.state.currentTime)}
                                min={this.timeToInt(this.state.from)}
                                max={this.timeToInt(this.state.to) + (this.timeToInt(this.state.to) - this.timeToInt(this.state.from) % 5)}
                                step={5}
                                marks={this.getSliderMarks(this.timeToInt(this.state.from), this.timeToInt(this.state.to))}
                                handle={::this.getSliderHandle}
                                onChange={::this.onCurrentTimeChange}
                            />
                        </div>
                    </div>
                ) : null}
            </Page>
        );
    }

    async togglePlay() {
        await this.setState({
            playing: !this.state.playing,
        });
        if (this.state.playing) {
            this.playInterval = setInterval(::this.play, 200);
        } else {
            clearInterval(this.playInterval);
        }
    }

    play() {
        let currentTime = moment(this.state.currentTime, formats.TIME);
        currentTime.add(1, 'minutes');
        if (currentTime.isAfter(moment(this.state.to, formats.TIME))) {
            this.togglePlay();
        }
        this.setState({
            currentTime: currentTime.format(formats.TIME),
        });
    }

    async onCurrentTimeChange(value) {
        this.setState({
            currentTime: moment(this.intToTime(value), formats.TIME).format(formats.TIME),
        });
    }

    getSliderHandle(props) {
        const {value, dragging, index, ...restProps} = props;
        return (
            <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={this.intToTime(value)}
                visible={dragging}
                placement="bottom"
                key={index}
            >
                <Handle value={value} {...restProps} />
            </Tooltip>
        );
    };

    getSliderMarks(min, max, step = 60) {
        let result = {};
        for (let i = min; i <= max; i += step) {
            result[i] = this.intToTime(i);
        }
        return result;
    }

    intToTime(int) {
        const hours = _.padStart(Math.floor(int / 60), 2, '0');
        const minutes = _.padStart(int % 60, 2, '0');
        return `${hours}:${minutes}`;
    }

    timeToInt(time) {
        const timeObject = moment(time, formats.TIME);
        return timeObject.hours() * 60 + timeObject.minutes();
    }

    getCurrentHistory() {
        const currentTime = this.timeToInt(this.state.currentTime);
        return _.last(_.filter(this.state.history, (point) => {
            const pointTime = this.timeToInt(moment.unix(point.type).format(formats.TIME));
            return pointTime <= currentTime;
        }));
    }

    getHistoryCoordinates() {
        return _.map(this.state.history, (point) => ([
            point.lat,
            point.lng,
        ]));
    }

    getHeaderActions() {
        return [
            this.diapasonFilter(),
        ];
    }

    diapasonFilter() {
        return (
            <div key="diapason">
                <div className="top-menu__label">Выбор диапазона:</div>
                <Datepicker style="dark" value={this.state.date} onChange={this.onFilterChange.bind(this, 'date')}/>
                <Input value={this.state.from} onChange={this.onFilterChange.bind(this, 'from')}/>
                <Input value={this.state.to} onChange={this.onFilterChange.bind(this, 'to')}/>
            </div>
        );
    }

    async onFilterChange(field, {target: {value}}) {
        let state = this.state;
        state[field] = value;
        await this.setState(state);

        this.reloadDebounce();
    }

    async reload() {
        if (!(this.state.date && this.state.from && this.state.to && this.state.selectedVehicle)) {
            return;
        }

        this.setState({loading: true, history: []});
        const from = moment(moment(this.state.date).format(formats.DATE_URL) + ' ' + this.state.from);
        const to = moment(moment(this.state.date).format(formats.DATE_URL) + ' ' + this.state.to);
        const response = await this.props.getHistoryByVehicle(this.state.selectedVehicle, from.format(formats.DATETIME_API), to.format(formats.DATETIME_API));
        this.setState({loading: false});
        if (response.isOk) {
            const telematics = _.first(response.payload.telematics);
            if (telematics) {
                this.setState({
                    history: _.map(telematics.points, (point) => {
                        return new TelematicsDevice(telematics.device_id, point);
                    }),
                    currentTime: from.format(formats.TIME),
                });
            }
        } else {
            //response.showErrors();
        }
    }
}