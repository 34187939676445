import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import MapPolyline from "components/ui/map/base/polyline";

@propTypes({})

@defaultProps({})

export default class DirectionalPolyline extends MapPolyline {

    componentWillMount() {
        super.componentWillMount();
    }

}