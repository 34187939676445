import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getScheduleSwitches = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getScheduleSwitches(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getScheduleSwitch = (unitUuid) => async (dispatch) => {
    try {
        const response = await api.geo.getScheduleSwitch(unitUuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createScheduleSwitch = (data) => async (dispatch) => {
    try {
        const response = await api.geo.createScheduleSwitch(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateScheduleSwitch = (data) => async (dispatch) => {
    try {
        const response = await api.geo.updateScheduleSwitch(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteScheduleSwitch = (data) => async (dispatch) => {
    try {
        const response = await api.geo.deleteScheduleSwitch(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const listSwitches = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.listSwitches(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const hideSwitch = (data) => async (dispatch) => {
    try {
        const response = await api.geo.hideSwitch(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const showSwitch = (data) => async (dispatch) => {
    try {
        const response = await api.geo.showSwitch(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};