import { rpc } from './session';

export async function getNews(meta = {}) {
	return await rpc.request('com.rnis.portal.action.news', {}, { meta });
}

export async function getNewsItem(uuid) {
	return await rpc.request('com.rnis.portal.action.news.get', { uuid });
}

export async function createNews(data) {
	return await rpc.request('com.rnis.portal.action.news.create', data);
}

export async function updateNews(data) {
	return await rpc.request('com.rnis.portal.action.news.update', data);
}

export async function deleteNews(data) {
	return await rpc.request('com.rnis.portal.action.news.delete', data);
}

export async function getFaq(meta = {}) {
	return await rpc.request('com.rnis.portal.action.faq', {}, { meta });
}

export async function getFaqItem(uuid) {
	return await rpc.request('com.rnis.portal.action.faq.get', { uuid });
}

export async function createFaq(data) {
	return await rpc.request('com.rnis.portal.action.faq.create', data);
}

export async function updateFaq(data) {
	return await rpc.request('com.rnis.portal.action.faq.update', data);
}

export async function deleteFaq(data) {
	return await rpc.request('com.rnis.portal.action.faq.delete', data);
}

export async function getPages(meta = {}) {
	return await rpc.request('com.rnis.portal.action.page', {}, { meta });
}

export async function getPage(uuid) {
	return await rpc.request('com.rnis.portal.action.page.get', { uuid });
}

export async function createPage(data) {
	return await rpc.request('com.rnis.portal.action.page.create', data);
}

export async function updatePage(data) {
	return await rpc.request('com.rnis.portal.action.page.update', data);
}

export async function deletePage(data) {
	return await rpc.request('com.rnis.portal.action.page.delete', data);
}

export async function getRegisterRequests(meta = {}) {
	return await rpc.request('com.rnis.portal.action.register_request.list', {}, { meta });
}

export async function getRegisterEsiaRequests(meta = {}) {
	return await rpc.request('com.rnis.auth.action.esia.new_user.list', {}, { meta });
}

export async function getRegisterRequest(uuid) {
	return await rpc.request('com.rnis.portal.action.register_request.get', { uuid });
}

export async function updateRegisterRequest(data) {
	return await rpc.request('com.rnis.portal.action.register_request.update', data);
}

export async function updateRegisterEsiaRequest(data) {
	return await rpc.request('com.rnis.auth.action.esia.new_user.update', data);
}

export async function createConfirmRequest(data) {
	return await rpc.request('com.rnis.portal.action.confirm_request.create', data);
}

export async function getConfirmRequest(uuid) {
	return await rpc.request('com.rnis.portal.action.confirm_request.get', { uuid });
}

export async function listConfirmRequests(meta = {}) {
	return await rpc.request('com.rnis.portal.action.confirm_request.list', {}, { meta });
}

export async function updateConfirmRequest(data) {
	return await rpc.request('com.rnis.portal.action.confirm_request.update', data);
}

export async function createTemplateDocument(data) {
	return await rpc.request('com.rnis.portal.action.template_document.create', data);
}

export async function updateTemplateDocument(data) {
	return await rpc.request('com.rnis.portal.action.template_document.update', data);
}

export async function deleteTemplateDocument(data) {
	return await rpc.request('com.rnis.portal.action.template_document.delete', data);
}

export async function getTemplateDocumentNextVersion(data) {
	return await rpc.request('com.rnis.portal.action.template_document.get_next_version', data);
}

export async function listTemplateDocuments(meta = {}) {
	return await rpc.request('com.rnis.portal.action.template_document.list', {}, { meta });
}

export async function getTemplateDocument(uuid) {
	return await rpc.request('com.rnis.portal.action.template_document.get', { uuid });
}

export async function getCooperationAgreements(meta = {}) {
	return await rpc.request('com.rnis.portal.action.cooperation_agreements.list', {}, { meta });
}

export async function updateCooperationAgreement(data) {
	return await rpc.request('com.rnis.portal.action.cooperation_agreements.update', data);
}

export async function documentCooperationAgreement(data) {
	return await rpc.request('com.rnis.portal.action.cooperation_agreements.document', data);
}

export async function createCooperationAgreement(data) {
	return await rpc.request('com.rnis.portal.action.cooperation_agreements.create', data);
}

export async function testTemplateDocument(data) {
	return await rpc.request('com.rnis.portal.action.template_document.document', data);
}

export async function createCooperationAgreementWarning(data) {
	return await rpc.request('com.rnis.portal.action.cooperation_agreement_warnings.create', data);
}

export async function getCooperationAgreementWarnings(meta = {}) {
	return await rpc.request('com.rnis.portal.action.cooperation_agreement_warnings.list', {}, { meta });
}

export async function updateCooperationAgreementWarning(data) {
	return await rpc.request('com.rnis.portal.action.cooperation_agreement_warnings.update', data);
}

export async function confirmRequestUnits() {
	return await rpc.request('com.rnis.portal.action.confirm_request.units');
}
