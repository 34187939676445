import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import _ from 'lodash';

import BaseTableComponent from 'components/base/base_table';
import Page from 'components/ui/page';
import IconButton from 'components/ui/icon-button';
import Editor from './editor';

import {mapDatatablesRequestToMeta} from 'helpers/api';

import * as alerts from "helpers/alerts";
import TableContainer from "components/ui/Table/Container/TableContainer";
import Column from "components/ui/column";
import AppearTransition from "components/ui/transitions/appear";
import {EntityList} from "helpers/entity";
import currentUser from 'helpers/current-user';
import {getEntityNames} from "store/reducers/system";
import {deleteCarrier, getCarriers} from "store/reducers/organizational_units/carriers";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import {getRegisterRequests} from "store/reducers/portal/register_requests";
import formats from "dictionaries/formats";
import moment from "moment";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import './index.less';
import {confirmRequestUnits, getConfirmRequests} from "store/reducers/portal/confirm_requests";
import ReactDOMServer from 'react-dom/server';
import {State} from "components/ui/state";
import {getUnits} from "store/reducers/organizational_units/units";
import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";

@connect(state => ({}), {getConfirmRequests, getEntityNames, getDictionaryList, getUnits, getUsers, confirmRequestUnits})

export default class ConfirmRequestsComponent extends BaseTableWithEditorComponent {

    title = `${systems.operator} → Заявления на подключение`;
    baseUrl = '/operator/confirm_requests';

    statuses = {
        draft: 'Черновик',
        sent_first: 'Отправлено впервые',
        returned: 'Возвращено',
        sent: 'Отправлено повторно',
        confirmed: 'Принято',
    };

    getEditor() {
        return (
            <Editor
                {...this.props}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Номер')
                .fromField('number'),

            new Column('Поступление')
                .fromField('created_at')
                .withDateFilter()
                .withDrawer(item => moment(item.created_at).format(formats.DATETIME)),

            new Column('Обновление')
                .fromField('updated_at')
                .withDateFilter()
                .withDrawer(item => moment(item.updated_at).format(formats.DATETIME)),

            new Column('Статус')
                .fromField('status')
                .denyColumnFilter()
                .withDrawer(item => this.statuses[item.status])
                .withFilter('withStatuses', async () => {
                    return _.map(this.statuses, (name, uuid) => ({
                        uuid,
                        name,
                    }));
                }),

            new Column('Обрабатывают')
                .fromField('operators')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => _.map(_.uniq(_.filter(_.map(item.history, 'user_uuid'))), (userUuid) => {
                    return this.state.related.get(userUuid);
                }).join(', '))
                .withAsyncFilter('withOperators', async (search) => {
                    const response = await this.props.getUsers({
                        search,
                        pagination: {
                            page: 1,
                            limit: 30,
                        },
                    });
                    if (response.isOk) {
                        return _.map(response.payload.items, (user) => ({
                            uuid: user.uuid,
                            name: new User(user).getFullName(),
                        }));
                    }
                    return [];
                }),

            new Column('Предприятие')
                .fromField('unit_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid))
                .withFilter('withUnits', async (search) => {
                    const uuids = await this.getUnitUuids();
                    const response = await this.props.getUnits({
                        filters: {
                            withUuid: uuids,
                        },
                        search,
                        order: {
                            column: 'name',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 1000,
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return [];
                }),

            new Column('Заявлено ТС')
                .fromField('vehicles_count')
                .denyColumnFilter()
                .withDrawer(item => (item.vehicles || []).length),

            new Column('Обновляет соглашения')
                .fromField('refresh_agreement')
                .denyColumnFilter()
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.refresh_agreement}/>))
                .withFilter('withRefresh', async () => {
                    return [
                        {
                            name: 'Активна',
                            uuid: 1,
                        },
                        {
                            name: 'Неактивна',
                            uuid: 0,
                        },
                    ];
                }),

        ]);
    }

    async getUnitUuids() {
        const response = await this.props.confirmRequestUnits();

        if (response.isOk) {
            return response.payload.items;
        } else {
            return [];
        }
    }

    async loadData(meta) {
        _.set(meta, 'filters.withoutDraft', 1);
        return await this.props.getConfirmRequests(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const userUuids = _.uniq(_.filter(_.map(_.flatten(_.map(result, 'history')), 'user_uuid')));
        const users = _.map(userUuids, (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));

        const units = _.map(_.uniq(_.filter(_.map(result, 'unit_uuid'))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(_.concat(users, units));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}