import React from 'react';
import FormGroup from 'components/ui/form-group';
import classNames from 'classnames';

const RenderTextField = ({placeholder, disabled, input, min, max, label, type, meta: {touched, error, warning}}) => {
    const className = classNames(
        'text-field', 'text-field-md');

    return (
        <FormGroup label={label} name={input.name}>
            <div className={className}>
                <input disabled={disabled} {...input} min={min} max={max} placeholder={placeholder} type={type}/>
                {touched && ((error && <span className="error">{error}</span>) ||
                    (warning && <span className="error">{warning}</span>))}
            </div>
        </FormGroup>
    );
};

export default RenderTextField;
