export const ProcessingStatuses = {
    draft: 'Оформляется',
    active: 'Действует',
    ended: 'Завершен',
    closed: 'Закрыт',
    defect: 'Брак планирования',
};

export const ProvisionStatuses = {
    full: 'Обеспечен',
    none: 'Не обеспечен',
    partial: 'Не полностью обеспечен',
    overfull: 'Сверхобеспечен',
    partial_full: 'Перекрыт',
};

export const ExecutionStatuses = {
    none: '-',
    operational_defect: 'Оперативный брак',
    potential_fact_defect: 'Потенциальный фактический брак',
    fact_defect: 'Фактический брак',
    partial: 'Частичное выполнение',
    done: 'Выполнен',
};

export const DefectTypes = {
    return: 'Сход-возврат',
    crash: 'Сход-авария',
    downtime: 'Простой',
    resource_output: 'Вывод ресура',
};