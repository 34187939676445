import React, {Component} from 'react';
import systems from "dictionaries/systems";
import WidgetPage from '../base-components/WidgetPage';
import VehicleDataWidget from './widgets/VehicleDataWidget';
import RUADRatingWidget from './widgets/RUADRatingWidget';
import VehicleDataDateWidget from './widgets/VehicleDataDateWidget';
import MechanismDowntimeWidget from './widgets/MechanismDowntimeWidget';
import SpeedViolationWidget from './widgets/SpeedViolationWidget';
import TransportWorkWidget from './widgets/TransportWorkWidget';
import randomColor from 'randomcolor';
import BaseAnalyticsComponent from "components/modules/analytics/base";
import ConvoyDriverScoreWidget from "components/modules/analytics/road-maintenance/widgets/ConvoyDriverScoreWidget/index";

const convoyDriverScoreWidgetBarColor = randomColor();
const vehicleDataWidgetPieColor = randomColor();
const RUADRatingWidgetBarColor = randomColor();
const speedViolationWidgetBarColor = randomColor();
const transportWorkWidgetBarColor = randomColor();

export default class PassengerTransportation extends BaseAnalyticsComponent {

    constructor(props) {
        super(props);

        this.widgetItems = [
            { id: 'vehicleDataWidget', name: 'Данные по ТС', widget: this.vehicleDataWidget },
            { id: 'RUADRatingWidget', name: 'Рейтинг РУАД', widget: this.RUADRatingWidget },
            { id: 'vehicleDataDateWidget', name: 'Данные по ТС на текущую дату', widget: VehicleDataDateWidget },
            { id: 'mechanismDowntimeWidget', name: 'Простой техники', widget: MechanismDowntimeWidget },
            { id: 'speedViolationWidget', name: 'Количество нарушений скоростного режима', widget: this.speedViolationWidget },
            { id: 'transportWorkWidget', name: 'Учет транспортной работы', widget: this.transportWorkWidget },
            { id: 'convoyDriverScoreWidget', name: 'Баллы водителей по автоколонне', widget: this.convoyDriverScoreWidget, checked: true},
        ];

        const roadTitle = _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text;
        this.title = `${systems.analytics} → ${roadTitle}`;
    }

    convoyDriverScoreWidget(props) {
        return <ConvoyDriverScoreWidget {...props} barChartFillColor={convoyDriverScoreWidgetBarColor}/>
    }

    vehicleDataWidget(props) {
        return <VehicleDataWidget {...props} pieChartFillColor={vehicleDataWidgetPieColor}/>
    }

    RUADRatingWidget(props) {
        return <RUADRatingWidget {...props} barChartFillColor={RUADRatingWidgetBarColor}/>
    }

    speedViolationWidget(props) {
        return <SpeedViolationWidget {...props} barChartFillColor={speedViolationWidgetBarColor}/>
    }

    transportWorkWidget(props) {
        return <TransportWorkWidget {...props} barChartFillColor={transportWorkWidgetBarColor}/>
    }

    render() {
        return (
            <WidgetPage cacheKey="road"
                        title={this.title}
                        widgetItems={this.widgetItems}
            />
        );
    }
}