import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import PageModal from 'components/ui/page-modal';
import TableContainer from "components/ui/Table/Container/TableContainer";
import moment from "moment";
import formats from "dictionaries/formats";
import {getBnsoList} from "store/reducers/vehicles/bnso";
import {connect} from "react-redux";
import Tooltip from 'react-tooltip-component';
import Block from "components/ui/form/block";
import SelectFilter from "components/ui/select-filter";
import {getUsers} from "store/reducers/staffing/staffing";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import runTypes from "dictionaries/runs";
import classNames from 'classnames';
import {getRouteVariant} from "store/reducers/routes/route_variants";
import {Link} from "react-router";
import {makeResponse} from "helpers/response";
import {api} from "helpers/api";
import download from 'downloadjs'
import {getRoutes} from "store/reducers/routes/routes";
import Checkbox from "components/ui/form/checkbox";
import {getSchedules} from "store/reducers/kiutr/schedules/schedules";

import './MapModalStopPointRoutes.less';
import {State} from "components/ui/state";
import {getContracts} from "store/reducers/kiutr/contracts/contracts";
import GlobalLoaderComponent from "components/ui/global-loader";
import {LabeledCheckbox} from "components/ui/checkbox";

@propTypes({
    stopPointUuid: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    setSelectedRoutes: PropTypes.func,
})

@connect(state => ({}), {
    getRoutes,
    getSchedules,
    getContracts,
}, null, {withRef: true})

export default class MapModalStopPointRoutes extends Component {

    state = {
        routes: [],
        schedules: [],

        loading: false,

        expandedRoutes: {},
        selected: [],
        selectedForMatrix: [],
        isDeselectAll: true,
    };

    componentDidMount() {
        this.loadRoutes(this.props);
    }

    componentWillUpdate(props) {
        if (this.props.stopPointUuid !== props.stopPointUuid) {
            this.loadRoutes(props);
        }
    }

    async loadRoutes(props) {
        const {stopPointUuid} = props;

        this.setState({
            loading: true,
        });
        const response = await this.props.getRoutes({
            filters: {
                withStopPoint: stopPointUuid,
                withoutGeometry: true,
            },
        });

        this.setState({
            loading: false,
        });
        if (response.isOk) {
            await this.setState({
                routes: _.orderBy(response.payload.items, ['number', 'title'], ['asc', 'asc']),
                selected: [],
                expandedRoutes: {},
                selectedForMatrix: [],
                schedules: [],
            });
            this.refreshSelected();

            this.loadSchedules();
        } else {
            response.showErrors();
        }
    }

    async loadSchedules() {
        const response = await this.props.getSchedules({
            filters: {
                withRoute: _.map(this.state.routes, 'uuid'),
            },
        });

        if (response.isOk) {
            this.setState({
                schedules: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{
                    title: (
                        <div>
                            Список маршрутов
                        </div>
                    ), buttons
                }}
                onClose={this.props.onClose}
                className={`profile-modal b-modal-edit HistoryModal StopPointRoutesModal`}
                withFade={false}
            >
                {this.state.loading ? <GlobalLoaderComponent/> : null}
                <div className="accordion">
                    {_.map(this.state.routes, ::this.renderRoute)}
                    {(!this.state.loading && (this.state.routes.length === 0)) ? (
                        <div>
                            Нет активных маршрутов по данной остановке
                        </div>
                    ) : null}
                </div>
            </PageModal>
        )
    }

    renderRoute(route) {
        const checked = _.indexOf(this.state.selected, route.uuid) !== -1;
        const isExpanded = _.get(this.state.expandedRoutes, route.uuid);

        return (
            <div key={route.uuid}>
                <ContextTooltip key="kiutr.stop-point.routes.toggle" code="kiutr.stop-point.routes.toggle"
                                default="Отобразить/скрыть">
                    <div
                        className={classNames('b-draggable__menu-link b-draggable__menu-link_eye', checked ? '' : 'active')}
                        onClick={this.toggleSelected.bind(this, route.uuid)}/>
                </ContextTooltip>

                <Link to={`/kiutr/routes/${route.uuid}`} target="_blank">{route.number} {route.title}</Link>

                <ContextTooltip key="kiutr.stop-point.routes.schedules" code="kiutr.stop-point.routes.schedules"
                                default="Расписания">
                    <div
                        className={classNames('b-draggable__menu-link b-draggable__menu-link_timetables')}
                        onClick={this.toggleSchedules.bind(this, route.uuid)}/>
                </ContextTooltip>

                <ContextTooltip key="kiutr.stop-point.routes.report" code="kiutr.stop-point.routes.report"
                                default="Анализ пассажиропотока">
                    <div
                        className={classNames('b-draggable__menu-link b-draggable__menu-link_reports')}
                        onClick={this.gotoPassengerTraffic.bind(this, route)}/>
                </ContextTooltip>

                <div className="matrix-checkbox">
                    <ContextTooltip key="kiutr.stop-point.routes.matrix" code="kiutr.stop-point.routes.matrix"
                                    default="Добавить для сравнения матрицы дублирования">
                        <LabeledCheckbox
                            checked={_.indexOf(this.state.selectedForMatrix, route.uuid) !== -1}
                            onChange={this.toggleSelectedForMatrix.bind(this, route.uuid)}
                            label=" "
                        />
                    </ContextTooltip>
                </div>

                <ContextTooltip key="kiutr.stop-point.routes.matrix" code="kiutr.stop-point.routes.matrix"
                                default="Матрица дублирования">
                    <div
                        className={classNames('b-draggable__menu-link b-draggable__menu-link_system-diagnostics')}
                        onClick={::this.gotoMatrix}/>
                </ContextTooltip>

                {isExpanded ? this.renderSchedules(route) : null}
            </div>
        );
    }

    gotoMatrix() {
        const link = `/kiutr/orders/matrices?uuids=${this.state.selectedForMatrix.join(',')}`;

        const a = document.createElement("a");
        a.setAttribute("href", link);
        a.setAttribute("target", "_blank");
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
        }, 0);
    }

    async gotoPassengerTraffic(route) {
        const units = await this.getRouteCarriers(route.uuid);

        const link = `/reports?uri=passenger_traffic_report&route=${route.uuid}&units=${units.join(';')}`;

        const a = document.createElement("a");
        a.setAttribute("href", link);
        a.setAttribute("target", "_blank");
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
        }, 0);
    }

    async getRouteCarriers(routeUuid) {
        const response = await this.props.getContracts({
            filters: {
                withRoute: routeUuid,
            },
        });

        if (response.isOk) {
            return _.uniq(_.filter(_.map(response.payload.items, 'carrier_uuid')));
        }
        return [];
    }

    renderSchedules(route) {
        const schedules = _.filter(this.state.schedules, {route_uuid: route.uuid});

        return (
            <div className="Table">
                <TableContainer>
                    <table className="b-table">
                        <thead>
                        <tr>
                            <th>Наименование</th>
                            <th>Дата начала</th>
                            <th>Дата конца</th>
                            <th>Пн</th>
                            <th>Вт</th>
                            <th>Ср</th>
                            <th>Чт</th>
                            <th>Пт</th>
                            <th>Сб</th>
                            <th>Вс</th>
                            <th>Пр.дни</th>
                        </tr>
                        </thead>
                        <tbody>
                        {_.map(schedules, (schedule) => {
                            return (
                                <tr key={schedule.uuid}>
                                    <td><Link
                                        to={`/kiutr/routes/${route.uuid}/schedules/${schedule.uuid}`}>{schedule.name}</Link>
                                    </td>
                                    <td>{schedule.date_from && moment(schedule.date_from).format(formats.DATE)}</td>
                                    <td>{schedule.date_to && moment(schedule.date_to).format(formats.DATE)}</td>
                                    <td><State positive={schedule.monday}/></td>
                                    <td><State positive={schedule.tuesday}/></td>
                                    <td><State positive={schedule.wednesday}/></td>
                                    <td><State positive={schedule.thursday}/></td>
                                    <td><State positive={schedule.friday}/></td>
                                    <td><State positive={schedule.saturday}/></td>
                                    <td><State positive={schedule.sunday}/></td>
                                    <td><State positive={schedule.holiday}/></td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </TableContainer>
            </div>
        );
    }

    toggleSchedules(routeUuid, e) {
        e && e.preventDefault();
        e && e.stopPropagation();

        let expandedRoutes = this.state.expandedRoutes;

        expandedRoutes[routeUuid] = !_.get(expandedRoutes, routeUuid, false);

        this.setState({
            expandedRoutes,
        });
    }

    async toggleSelected(uuid) {
        let selected = this.state.selected;

        const index = _.indexOf(selected, uuid);
        if (index !== -1) {
            selected.splice(index, 1);
        } else {
            selected.push(uuid);
        }

        await this.setState({
            selected,
        });

        this.refreshSelected();
    }

    async toggleSelectedForMatrix(uuid) {
        let selectedForMatrix = this.state.selectedForMatrix;

        const index = _.indexOf(selectedForMatrix, uuid);
        if (index !== -1) {
            selectedForMatrix.splice(index, 1);
        } else {
            selectedForMatrix.push(uuid);
        }

        await this.setState({
            selectedForMatrix,
        });
    }

    async selectDeselectAll() {
        let selected = [];
        if (!this.state.isDeselectAll) {
            selected = _.map(this.state.routes, 'uuid');
        }

        await this.setState({
            isDeselectAll: !this.state.isDeselectAll,
            selected,
        });

        this.refreshSelected();
    }

    refreshSelected() {
        const selected = _.filter(this.state.routes, (route) => {
            return _.indexOf(this.state.selected, route.uuid) !== -1;
        });

        this.props.setSelectedRoutes(selected);
    }

    renderToggleAll() {
        const selectedCount = this.state.selected.length;
        const totalCount = this.state.routes.length;

        const sliderClassName = classNames({
            'b-slider__line': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        const sliderCircleClassName = classNames({
            'b-slider__circle': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        return (
            <div className="b-slider _options clearAfter" onClick={::this.selectDeselectAll}>
                <div className="b-slider__title" style={{display: 'none'}}>Выбрать все</div>
                <div
                    className="b-slider__title">{this.state.isDeselectAll ? 'Снять все' : 'Выбрать все'}</div>

                <div className="b-slider__control">
                    <div className={sliderClassName}/>
                    <div className={sliderCircleClassName}/>
                </div>
            </div>
        );
    }
}