import React, {Component} from "react";
import _ from 'lodash';
import moment from "moment";

const isActive = (field, vehiclePopup) => {
    return _.get(vehiclePopup, field + '_ptp');
};

const isKiutrOrChildren = (component) => {
    return _.indexOf([
        'kiutr',
        'children',
        'ems',
    ], component) !== -1;
};

const isKurs = (component) =>  {
    return component === 'road';
};

const getTasks = (tasks, vehicleUuid) => {
    return _.filter(tasks, (task) => {
        return _.find(task.resources, {base_vehicle_uuid: vehicleUuid});
    });
};

export const PermamentTooltipGetBlocks = (vehicle, device, vehiclePopup, data, component) => {
    let items = [];
   // debugger

    if (isActive('garage_number', vehiclePopup)) {
        items.push({
            icon: 'garage-number',
            text: vehicle.garage_number || '-',
        });
    }

    if (isActive('state_number', vehiclePopup)) {
        items.push({
            icon: 'garage-number',
            text: vehicle.state_number || '-',
        });
    }

    if (isActive('model', vehiclePopup)) {
        const model = _.get(_.find(data.vehicle_models, {uuid: vehicle.vehicle_model_uuid}), 'name');
        const mark = _.get(_.find(data.vehicle_marks, {uuid: vehicle.vehicle_mark_uuid}), 'name');
        items.push({
            icon: 'model',
            text: `${model || '-'} ${mark || '-'}`,
        });
    }

    if (isActive('type', vehiclePopup)) {
        const type = _.get(_.find(data.vehicle_types, {uuid: vehicle.vehicle_type_uuid}), 'name');
        items.push({
            icon: 'type',
            text: type || '-',
        });
    }

    if (isActive('speed', vehiclePopup)) {
        items.push({
            icon: 'speed',
            text: `${device.speed} км/ч`,
        });
    }

    if (isActive('bnso_number', vehiclePopup)) {
        items.push({
            icon: 'device',
            text: vehicle.current_bnso.bnso_number,
        });
    }

    if (isActive('overspeed', vehiclePopup)) {
        if (device.speedLimit && device.speed && (device.speed >= device.speedLimit + 20)) {
            items.push({
                icon: 'speed-limit',
                text: 'превышение',
            });
        } else {
            items.push({
                icon: 'speed-limit',
                text: '-',
            });
        }
    }

    if (isActive('environment', vehiclePopup)) {
        const environmentClass = _.get(_.find(data.vehicle_environmental_classes, {uuid: vehicle.environmental_class_uuid}), 'name');
        items.push({
            icon: 'ecology-class',
            text: environmentClass || '-',
        });
    }

    if (isActive('capacity', vehiclePopup)) {
        const capacityType = _.get(_.find(data.vehicle_capacity_types, {uuid: vehicle.capacity_type_uuid}), 'name');
        items.push({
            icon: 'capacity-class',
            text: capacityType || '-',
        });
    }

    if (isActive('properties', vehiclePopup)) {
        const allProperties = {
            is_cashless_payment: 'Безналичная оплата',
            is_low_floor_level: 'Низкий уровень пола',
            is_audio_video_fixation: 'Аудио/Видео фиксация',
            is_passenger_monitoring_system: 'Система мониторинга пассажиропотока',
            is_for_disabled: 'Оснащено для перевозки инвалидов',
            is_air_conditioning_installation: 'Климатическая установка',
        };
        const properties = _.filter(allProperties, (label, name) => {
            return vehicle[name];
        });
        items.push({
            icon: 'outfit',
            text: _.values(properties).join(', ') || '-',
        });
    }

    if (isActive('unit', vehiclePopup)) {
        items.push({
            icon: 'company',
            text: _.get(data.units, vehicle.unit_uuid, '-') || '-',
        });
    }

    if (isActive('parent_unit', vehiclePopup)) {
        items.push({
            icon: 'head-company',
            text: _.get(data.units, _.get(data.parentUnits, vehicle.unit_uuid), '-') || '-',
        });
    }

    if (isKiutrOrChildren(component) && isActive('driver', vehiclePopup)) {
        items.push({
            icon: 'driver',
            text: vehicle.driver_name || '-',
        });
    }

    if (isActive('order', vehiclePopup)) {
        items.push({
            icon: 'execution',
            text: vehicle.order_uuid ? vehicle.order_number : '-',
        });
    }

    if (isKiutrOrChildren(component) && isActive('route', vehiclePopup)) {
        items.push({
            icon: 'route',
            text: vehicle.route_number || '-',
        });
    }

    const tasks = getTasks(data.tasks, vehicle.uuid);

    if (isKurs(component) && isActive('driver', vehiclePopup)) {
        const driverUuids = _.uniq(_.filter(_.map(_.flatten(_.map(tasks, 'resources')), 'driver_uuid')));
        const drivers = _.map(driverUuids, (uuid) => {
            return _.get(data.drivers, uuid);
        });

        items.push({
            icon: 'driver',
            text: _.map(drivers, 'name').join(', ') || '-',
        });
    }

    if (isKurs(component) && isActive('route', vehiclePopup)) {
        items.push({
            icon: 'route',
            text: _.map(tasks, 'number').join(', ') || '-',
        });
    }

    if (isKurs(component) && isActive('done_road', vehiclePopup)) {
        items.push({
            icon: 'execution',
            text: _.map(tasks, (task) => {
                const fact = _.filter(task.items_fact || [], {is_confirmed: true, movement_type: 'work'}).length;
                const plan = _.filter(task.items_fact || [], {movement_type: 'work'}).length;
                return ((plan !== 0) ? _.round(100 * fact / plan, 2) : 0) + '%';
            }).join(', ') || '-',
        });
    }

    const orderExecution = _.find(data.order_executions, {vehicle_uuid: vehicle.uuid});

    if (isKiutrOrChildren(component) && isActive('run_direction', vehiclePopup)) {
        if (orderExecution) {
            items.push({
                icon: 'direction',
                text: (orderExecution.type === 'production_forward') ? 'Прямое' : 'Обратное',
            });
        } else {
            items.push({
                icon: 'direction',
                text: '-',
            });
        }
    }

    if (isKiutrOrChildren(component) && isActive('shift', vehiclePopup)) {
        if (orderExecution) {
            items.push({
                icon: 'route',
                text: orderExecution.shift,
            });
        } else {
            items.push({
                icon: 'route',
                text: '-',
            });
        }
    }

    if (isKiutrOrChildren(component) && isActive('done', vehiclePopup)) {
        if (orderExecution) {
            const stopPoints = _.filter(orderExecution.data, {point_type: 'stop_point'});
            const factStopPoints = _.filter(stopPoints, item => item.time_fact);
            const lastFactStopPoint = _.last(factStopPoints);

            items.push({
                icon: 'execution',
                text: ((stopPoints.length !== 0) ? _.round(100 * factStopPoints.length / stopPoints.length) : 0) + '%',
            });

            if (lastFactStopPoint) {
                const diff = Math.abs(moment(lastFactStopPoint.time_fact).diff(moment(lastFactStopPoint.time_plan), 'minutes'));

                items.push({
                    icon: 'execution',
                    text: (lastFactStopPoint.is_before_time ? 'Нагон' : 'Отставание') + ` ${diff} мин.`,
                });
            } else {
                items.push({
                    icon: 'execution',
                    text: '-',
                });
            }
        } else {
            items.push({
                icon: 'execution',
                text: '-',
            });
        }
    }

    return (
        <div className="attrs">
           {/* {vehicle.state_number}*/}
           {items.map((el, i) => {
               return (
                <div key={el.text + i} className="attrs__item">
                    <i className={`attrs__icon attrs__icon_${el.icon}`}></i>
                    <div className="attrs__text">{el.text}</div>
                </div>
            );
           })}
        </div>
    );
};
