import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './vehicle_select.less';
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import IconButton from "components/ui/icon-button";
import Popup from "components/ui/popup";
import {LabeledCheckbox} from "components/ui/checkbox";
import _ from 'lodash';
import ContextTooltip from "components/ui/context-tooltip";

@propTypes({
    vehicles: PropTypes.array.isRequired,
    selectedVehicles: PropTypes.object,
    toggleVehicle: PropTypes.func.isRequired,
    withToggle: PropTypes.bool,
})

@defaultProps({
    withToggle: true,
})

export default class GatnVehicleSelect extends Component {

    state = {
        opened: false,
        isDeselectAll: true,
    };

    render() {
        return (
            <div>
                <ContextTooltip key="base-map.vehicle-select" code="base-map.vehicle-select" default="Выбор ТС">
                    <IconButton
                        icon="add-remove"
                        ref="button"
                        onClick={::this.toggleBlock}/>
                </ContextTooltip>
                {this.state.opened ? this.renderPopup() : null}
            </div>
        );
    }

    renderPopup() {
        const vehicles = this.props.vehicles || [];
        const selectedCount = _.filter(vehicles, vehicle => (this.props.selectedVehicles || {})[vehicle.uuid]).length;
        const totalCount = vehicles.length;

        const sliderClassName = classNames({
            'b-slider__line': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        const sliderCircleClassName = classNames({
            'b-slider__circle': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        return (
            <Popup
                className="SelectFieldsPopup top-link"
                show={true}
                onClose={::this.toggleBlock}>

                <div className="content">
                    {vehicles.map((vehicle) => (
                        <LabeledCheckbox
                            key={vehicle.uuid}
                            checked={(this.props.selectedVehicles || {})[vehicle.uuid]}
                            label={vehicle.state_number}
                            onChange={this.onClick.bind(this, vehicle)}/>
                    ))}
                </div>
                {this.props.withToggle ? (
                    <div className="b-slider _options clearAfter" onClick={::this.selectDeselectAll}>
                        <div className="b-slider__title" style={{display: 'none'}}>Выбрать все</div>
                        <div className="b-slider__title">{this.state.isDeselectAll ? 'Снять все' : 'Выбрать все'}</div>

                        <div className="b-slider__control">
                            <div className={sliderClassName}/>
                            <div className={sliderCircleClassName}/>
                        </div>
                    </div>
                ) : null}
            </Popup>
        );
    }

    selectDeselectAll() {
        if (this.state.isDeselectAll) {
            _.each(_.filter(this.props.vehicles, vehicle => this.props.selectedVehicles[vehicle.uuid]), (vehicle) => {
                this.onClick(vehicle);
            });
        } else {
            _.each(_.filter(this.props.vehicles, vehicle => !this.props.selectedVehicles[vehicle.uuid]), (vehicle) => {
                this.onClick(vehicle);
            });
        }

        this.setState({
            isDeselectAll: !this.state.isDeselectAll,
        });
    }

    async onClick(vehicle) {
        this.props.toggleVehicle(vehicle.uuid);
    }

    toggleBlock() {
        this.setState({
            opened: !this.state.opened,
        });
    }

    renderVehicle(vehicle) {
        const className = classNames({
            'GatnVehicleSelect-list-item': true,
            'active': this.props.selectedVehicles[vehicle.uuid],
        });
        return (
            <div key={vehicle.uuid} className={className} onClick={this.onClick.bind(this, vehicle)}>
                {vehicle.state_number}
            </div>
        );
    }
}