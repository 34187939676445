import React, {Component} from 'react';
import systems from "dictionaries/systems";
import WidgetPage from '../../base-components/WidgetPage';
import ConvoyDriverScoreWidget from '../../passenger-transportation/widgets/ConvoyDriverScoreWidget';
import ConvoyRatingWidget from '../../passenger-transportation/widgets/ConvoyRatingWidget';
import VehicleDriverDataWidget from '../../passenger-transportation/widgets/VehicleDriverDataWidget';
import BacklogWidget from '../../passenger-transportation/widgets/BacklogWidget';
import SpeedViolationWidget from '../../passenger-transportation/widgets/SpeedViolationWidget';
import TransportWorkWidget from '../../passenger-transportation/widgets/TransportWorkWidget';
import randomColor from 'randomcolor';
import BaseAnalyticsComponent from "components/modules/analytics/base";
import RegularityWidget from "components/modules/analytics/passenger-transportation/widgets/RegularityWidget/index";
import AverageCarAge from "components/modules/analytics/passenger-transportation/widgets/AverageCarAge/index";
import SpeedViolationsLineWidget
    from "components/modules/analytics/passenger-transportation/widgets/SpeedViolationsLineWidget/index";
import SpeedViolationsWidget
    from "components/modules/analytics/passenger-transportation/widgets/SpeedViolationsWidget/index";
import VehiclesPlanWidget from "components/modules/analytics/passenger-transportation/widgets/VehiclesPlanWidget/index";
import VehiclesBnsoWidget from "components/modules/analytics/passenger-transportation/widgets/VehiclesBnsoWidget/index";
import TransportWorkChartWidget
    from "components/modules/analytics/passenger-transportation/widgets/TransportWorkChartWidget/index";
import UnitsTableWidget from "components/modules/analytics/passenger-transportation/widgets/UnitsTableWidget/index";
import {connect} from "react-redux";
import {listUserGeoObjectsMO} from "store/reducers/user-map-objects/object_editor";

const convoyDriverScoreWidgetBarColor = randomColor();
const convoyRatingWidgetBarColor = randomColor();
const speedViolationWidgetBarColor = randomColor();
const transportWorkWidgetBarColor = randomColor();

@connect(state => ({}), {
    listUserGeoObjectsMO
})

export default class AnalyticGrantMunicipality extends BaseAnalyticsComponent {

    constructor(props) {
        super(props);

        this.state = {
            mo: null,
            municipalityTitle: ''
        };

        this.widgetItemsPassengerDefault = [
            {
                id: 'regularityWidget',
                name: 'Регулярность движения',
                widget: this.regularityWidget,
                checked: true,
                faq: true
            },
            {
                id: 'speedViolationsLineWidget',
                name: 'Количество нарушений скоростного режима',
                widget: this.speedViolationsLineWidget,
                checked: true
            },
            {
                id: 'speedViolationsWidget',
                name: 'Нарушители скоростного режима',
                widget: this.speedViolationsWidget,
                checked: true
            },
            {
                id: 'vehiclesPlanWidget',
                name: 'Выполнение плана по выходу ТС',
                widget: this.vehiclesPlanWidget,
                checked: true
            },
            {id: 'vehiclesBnsoWidget', name: 'Оснащенность ГЛОНАСС', widget: this.vehiclesBnsoWidget, checked: true},
            {
                id: 'transportWorkChartWidget',
                name: 'Доля выполнения рейсов',
                widget: this.transportWorkChartWidget,
                checked: true
            },
            {id: 'unitsTableWidget', name: 'Статистика по предприятиям', widget: this.unitsTableWidget, checked: true},
            {
                id: 'convoyDriverScoreWidget',
                name: 'Баллы водителей по автоколонне',
                widget: this.convoyDriverScoreWidget
            },
            {id: 'convoyRatingWidget', name: 'Рейтинг автоколонн', widget: this.convoyRatingWidget},
            {
                id: 'vehicleDriverDataWidget',
                name: 'Данные по ТС и водителям на текущую дату',
                widget: VehicleDriverDataWidget
            },
            {id: 'backlogWidget', name: 'Отставание от графика', widget: BacklogWidget},
            //{ id: 'speedViolationWidget', name: 'Количество нарушений скоростного режима', widget: this.speedViolationWidget },
            {id: 'transportWorkWidget', name: 'Учет транспортной работы', widget: this.transportWorkWidget},
        ];

        this.widgetItemsPassengerGrantMO = [
            {
                id: 'regularityWidget',
                name: 'Регулярность движения',
                widget: this.regularityWidget,
                checked: true,
                faq: true
            },
            {
                id: 'speedViolationsLineWidget',
                name: 'Количество нарушений скоростного режима',
                widget: this.speedViolationsLineWidget,
                checked: false
            },
            {
                id: 'speedViolationsWidget',
                name: 'Нарушители скоростного режима',
                widget: this.speedViolationsWidget,
                checked: false
            },
            {
                id: 'vehiclesPlanWidget',
                name: 'Выполнение плана по выходу ТС',
                widget: this.vehiclesPlanWidget,
                checked: true
            },
            {id: 'vehiclesBnsoWidget', name: 'Оснащенность ГЛОНАСС', widget: this.vehiclesBnsoWidget, checked: false},
            {
                id: 'transportWorkChartWidget',
                name: 'Доля выполнения рейсов',
                widget: this.transportWorkChartWidget,
                checked: true
            },
            {
                id: 'averageCarAgeWidget',
                name: 'Средний возраст ТС по классам с предельно-допустимым возрастом',
                widget: this.averageCarAge,
                checked: true
            },
            {id: 'unitsTableWidget', name: 'Статистика по предприятиям', widget: this.unitsTableWidget, checked: true},
            {
                id: 'convoyDriverScoreWidget',
                name: 'Баллы водителей по автоколонне',
                widget: this.convoyDriverScoreWidget
            },
            {id: 'convoyRatingWidget', name: 'Рейтинг автоколонн', widget: this.convoyRatingWidget},
            {
                id: 'vehicleDriverDataWidget',
                name: 'Данные по ТС и водителям на текущую дату',
                widget: VehicleDriverDataWidget
            },
            {id: 'backlogWidget', name: 'Отставание от графика', widget: BacklogWidget},
            {id: 'transportWorkWidget', name: 'Учет транспортной работы', widget: this.transportWorkWidget},

        ];

        this.title = `${systems.analytics} → Пассажирские перевозки → `;
    }

    async componentWillMount() {
        await this.loadMo();
    }

    async loadMo(type) {
        const meta = {
            filters: {
                withComponent: type
            }
        };
        const response = await this.props.listUserGeoObjectsMO(meta);

        if (response.isOk) {
            let uuid = _.last(window.location.pathname.split('/'));
            let title =_.find(response.payload.items, {uuid: uuid}).title;
            console.log(title)
            this.setState({
                mo: response.payload.items,
                municipalityTitle: title
            });
        } else {
            response.showErrors();
        }
    }

    convoyDriverScoreWidget(props) {
        return <ConvoyDriverScoreWidget {...props} component="kiutr"
                                        barChartFillColor={convoyDriverScoreWidgetBarColor}/>
    }

    convoyRatingWidget(props) {
        return <ConvoyRatingWidget {...props} barChartFillColor={convoyRatingWidgetBarColor}/>
    }

    speedViolationWidget(props) {
        return <SpeedViolationWidget {...props} barChartFillColor={speedViolationWidgetBarColor}/>
    }

    transportWorkWidget(props) {
        return <TransportWorkWidget {...props} barChartFillColor={transportWorkWidgetBarColor}/>
    }

    regularityWidget(props) {
        return <RegularityWidget {...props}/>
    }

    speedViolationsLineWidget(props) {
        return <SpeedViolationsLineWidget {...props}/>
    }

    speedViolationsWidget(props) {
        return <SpeedViolationsWidget {...props}/>
    }

    vehiclesPlanWidget(props) {
        return <VehiclesPlanWidget {...props}/>
    }

    vehiclesBnsoWidget(props) {
        return <VehiclesBnsoWidget {...props}/>
    }

    transportWorkChartWidget(props) {
        return <TransportWorkChartWidget {...props}/>
    }

    unitsTableWidget(props) {
        return <UnitsTableWidget {...props}/>
    }

    averageCarAge(props) {
        return <AverageCarAge {...props}/>
    }


    render() {
        let title = `${this.title} ${this.state.municipalityTitle}`
        return (
            <WidgetPage cacheKey="passenger"
                        title={title}
                        widgetItems={window.RNIS_SETTINGS.grantMO ? this.widgetItemsPassengerGrantMO : this.widgetItemsPassengerDefault}
            />
        );
    }
}
