import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {api} from "helpers/api";
import classNames from 'classnames';
import {createReportSchedule, getReportSchedule, updateReportSchedule} from "store/reducers/reports/report_schedules";
import {getReports, listReportTemplates} from "store/reducers/reports/reports";
import ContextTooltip from "components/ui/context-tooltip";
import ReactTooltip from 'react-tooltip'
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getReportSchedule, createReportSchedule, updateReportSchedule})

export default class Editor extends BaseEditor {

    title = 'расписания';
    modalClassName = 'b-modal-schedule-report';

    async loadData(uuid) {
        return await this.props.getReportSchedule(uuid);
    }

    async createItem(data) {
        return await this.props.createReportSchedule(data);
    }

    async updateItem(data) {
        return await this.props.updateReportSchedule(data);
    }

    printButton() {
        return null;
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getReports, listReportTemplates, getDictionaryList}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        report_schedule: {},
        reports: [],
        templates: [],
        seasons: [],
    };

    getData() {
        return this.state.report_schedule;
    }

    async componentDidMount() {
        await this.setState({
            report_schedule: this.props.data,
        });

        this.loadDictionaries([
            'seasons',
        ]);

        this.loadReports();
        this.loadTemplates();
    }

    async loadReports() {
        const response = await this.props.getReports();
        if (response.isOk) {
            this.setState({
                reports: _.map(response.payload.items, item => ({
                    label: item.name,
                    options: _.map(item.items, (subitem) => ({
                        value: subitem.uri,
                        label: subitem.name,
                    })),
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async loadTemplates() {
        const report = this.get('report_uri');
        if (!report) {
            this.setState({
                templates: [],
            });
            return;
        }

        const response = await this.props.listReportTemplates({
            filters: {
                withReport: report,
            },
        });
        if (response.isOk) {
            this.setState({
                templates: _.map(response.payload.items, item => ({
                    value: item.uuid,
                    label: item.name,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.report_schedule, path, defaultValue);
    }

    async setValue(field, value) {
        super.setValue(field, value);

        if (field === 'report_schedule.report_uri') {
            this.loadTemplates();
        }

        if (field === 'report_schedule.type') {
            if (_.indexOf([
                    'days',
                    'seasonly',
                    'monthly',
                ], value) !== -1) {
                this.setValue('report_schedule.is_full_period', true);
            }
        }
    }

    render() {
        return (
            <div className='b-modal__body-posit_container'>
                <div className="row">
                    <Block size="xl" title="Отчет">
                        {this.select('report_schedule.report_uri', this.state.reports)}
                    </Block>
                    <Block size="xl" title="Шаблон">
                        {this.select('report_schedule.report_template_uuid', this.state.templates)}
                    </Block>
                    <div className="col col-12">
                        <div className="b-block__title">Настройка отчетного периода</div>
                        <div className="row">
                            <div className="col col-12">
                                {this.checkbox('report_schedule.is_full_period', 'Весь период')}
                            </div>
                        </div>
                        {(!this.get('is_full_period')) ? (
                            <div className="row">
                                <div className="col col-3">
                                    <div className="b-block__title">C</div>
                                    {this.textInput('report_schedule.period_start', {
                                        type: 'number',
                                        positive: true,
                                    })}
                                </div>
                                <div className="col col-3">
                                    <div className="b-block__title">По</div>
                                    {this.textInput('report_schedule.period_end', {
                                        type: 'number',
                                        positive: true,
                                    })}
                                </div>
                                <div className="col col-3">
                                    <div className="b-block__text b-block__text_no-header b-block__text_align-center">
                                        Кол-во дней: {this.get('period_start') - this.get('period_end')}
                                    </div>
                                </div>
                                <div className="col col-3">
                                    <ReactTooltip/>
                                    <div className="b-block__text b-block__text_no-header b-block__text_align-center">
                                        <a data-multiline={true}
                                           data-tip="С 1 по 0 = отчёт за вчера<br/>С 2 по 1 = отчёт за позавчера<br/>С 7 по 0 = отчёт за последние 7 дней">?</a>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="col col-12">
                        <div className="b-block__title">Периодичность формирования отчёта</div>
                        <div className="row">
                            <div className="col col-3 col-offset-right-3">
                                <div className="b-block__title">Время форм. отч.</div>
                                <div className="b-block__text">
                                    {this.maskInput('report_schedule.time', '99:99', {
                                        withTimeIcon: true,
                                    })}
                                </div>
                            </div>
                            <div className="col col-3">
                                <div className="b-block__title">Начальная дата</div>
                                <div className="b-block__text">
                                    {this.datepicker('report_schedule.date_from')}
                                </div>
                            </div>
                            <div className="col col-3">
                                <div className="b-block__title">Конечная</div>
                                <div className="b-block__text">
                                    {this.datepicker('report_schedule.date_to')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12">
                        <div className="b-block__title">Интервал формирования отчета</div>
                    </div>
                    <div className="col col-12">
                        <div className="b-block__text b-block__text_no-header">
                            {this.radio('report_schedule.type', 'daily', 'Единоразово')}
                        </div>
                    </div>
                    <div className="col col-12">
                        <div className="b-block__text b-block__text_no-header">
                            {this.radio('report_schedule.type', 'days', 'По дням недели')}
                        </div>
                    </div>
                    {(this.get('type') === 'days') ? (
                        <div className="col col-12">
                            <div className="b-block__text">
                                <div className="table-container">
                                    <div className="Table _no-indent">
                                        <table className="b-table">
                                            <thead>
                                            <tr className="b-table__header _text-normal">
                                                <th>Пн</th>
                                                <th>Вт</th>
                                                <th>Ср</th>
                                                <th>Чт</th>
                                                <th>Пт</th>
                                                <th>Сб</th>
                                                <th>Вс</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    {this.checkbox('report_schedule.days.monday')}
                                                </td>
                                                <td>
                                                    {this.checkbox('report_schedule.days.tuesday')}
                                                </td>
                                                <td>
                                                    {this.checkbox('report_schedule.days.wednesday')}
                                                </td>
                                                <td>
                                                    {this.checkbox('report_schedule.days.thursday')}
                                                </td>
                                                <td>
                                                    {this.checkbox('report_schedule.days.friday')}
                                                </td>
                                                <td>
                                                    {this.checkbox('report_schedule.days.saturday')}
                                                </td>
                                                <td>
                                                    {this.checkbox('report_schedule.days.sunday')}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div className="col col-12">
                        <div className="b-block__text b-block__text_no-header">
                            {this.radio('report_schedule.type', 'seasonly', 'Сезонно')}
                        </div>
                    </div>
                    {(this.get('type') === 'seasonly') ? (
                        <div className="col col-12">
                            <div className="b-block__text b-block__text_no-header">
                                {this.select('report_schedule.season_uuid', this.state.seasons)}
                            </div>
                        </div>
                    ) : null}
                    <div className="col col-12">
                        <div className="b-block__text b-block__text_no-header">
                            {this.radio('report_schedule.type', 'monthly', 'Ежемесячно в последний день месяца')}
                        </div>
                    </div>
                    <hr/>
                    <div className="col col-12">
                        <div className="b-block__text b-block__text_no-header">
                            {this.checkbox('report_schedule.send_email', 'Отправить на E-mail сформированый отчёт')}
                        </div>
                    </div>
                    <div className="col col-12">
                        <div className="b-block__text b-block__text_no-header">
                            {this.checkbox('report_schedule.send_notification', 'Создать оповещение о формировании отчёта')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
