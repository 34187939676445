import React, { Component } from 'react';
import moment from "moment";
import classNames from 'classnames';

import { connect } from "react-redux";

import { getStopPointRoutesRun } from "store/reducers/kiutr/displays";

import './SheduleComponent.less';

class SvgIcon extends Component {
  render() {
    return (
      <span className="routes__icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13 13.616c0 .212-.188.384-.4.384h-1.332a.5.5 0 0 1-.416-.223L10 12.5H6l-.852 1.277a.5.5 0 0 1-.416.223H3.4c-.212 0-.4-.172-.4-.384V7l-.927-.426A.25.25 0 0 1 2 6.396V4.75C2 4 2.724 4 3 4v-.21c0-.649.352-1.225 1-1.426C4.808 2.114 5.843 2 8 2c2.156 0 3.192.114 4 .364.648.201 1 .777 1 1.427V4c.276 0 1 0 1 .75v1.648a.25.25 0 0 1-.071.175L13 7.01v6.605zM8 3.5c-1.988 0-3.235.14-3.74.417a.5.5 0 0 0-.26.438v3.27c0 .207.15.375.333.375h7.334c.184 0 .333-.168.333-.375V4.251c-.001-.501-1.335-.751-4-.751zm3 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-6 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" fill="white"></path></svg>
      </span>
    )
  }
}

@connect((state) => ({}), { getStopPointRoutesRun })
export default class SheduleComponent extends Component {

  state = {
    isSheduleTimeUpdate: false,
    sheduleIsLoading: true,
    shedule: {},
  }

  componentDidMount() {
    this.props.routes.forEach(route => {
      this.getSheduleForRoute(route).then(item => {
        this.setState((prevState) => {
          return { shedule: { ...prevState.shedule, [route.route_uuid]: item } }
        })
      })
    });
    this.setState({ sheduleIsLoading: false })
  }

  componentDidUpdate(prevProps) {
    if (this.props.timer !== 0) {
      if (prevProps.timer !== this.props.timer) {
        this.setState({ shedule: {}, isSheduleTimeUpdate: true })
        this.props.routes.forEach(route => {
          this.getSheduleForRoute(route).then(item => {
            this.setState((prevState) => {
              return { shedule: { ...prevState.shedule, [route.route_uuid]: item }, isSheduleTimeUpdate: false }
            })
          })
        });
      }
    }
  }

  async getRoutesRuns(meta) {
    const result = await this.props.getStopPointRoutesRun(meta);
    if (result.payload.items) {
      const allStopPoints = result.payload.items;
      const allTimes = allStopPoints.map(routeTime => {
        return moment(routeTime.time).format("HH:mm")
      });
      return allTimes;
    }
    return []
  }

  async getSheduleForRoute(route) {
    const meta = {
      "uuid": this.props.stopPointID,
      "route_uuid": route.uuid,
      "only_first": false,
      "time": null,
      page: 1
    }
    return await this.getRoutesRuns(meta);
  }

  checkTimeColor(time, index) {
    const currentTime = +moment(new Date()).format("HH:mm").match(/\d/g).join('');
    const result = (+time.match(/\d/g).join('')) - currentTime;

    // ближайший в пределах 15 минут зеленным красим, если выходит за рамки 15 минут, то первый по индексу, который больше текущего времени
    if (result > 0 && result < 15 && index === 0) {
      return "green";
    } else if (result > 0 && index === 0) {
      return "green";
    }
    // если время прошло в красный красим
    if (result < 0) {
      return "red";
    }
  }

  renderSheduleTimeList(timeList) {
    return timeList.map((item, index) => {
      return <span key={item+index} className={classNames("routes__shedule__timeItem", this.checkTimeColor(item, index))}>{item}</span>
    })
  }

  renderShedule(item, index) {
    const sheduleTimeArrayByRouteID = this.state.shedule[item.route_uuid]
    return <li key={item.route_uuid + index} className="routes__shedule"><div className="mr"><SvgIcon /></div>
      <div className="routes__shedule__cl1 ds-column">
        <span className="routes__shedule__number">{item.number}</span>
        {/* подпись к маршруту, сейчас title, возможно в будущем надо будет соединить from и to */}
        <span>{item.title}</span>
      </div>
      <div className="ds-column">
        {/* вывод времени прибытия для выбранной остановки. Выводится списком */}
        <div className="routes__shedule__time">
          {this.state.isSheduleTimeUpdate ? <span>Загрузка</span> : null}
          {!this.state.isSheduleTimeUpdate && sheduleTimeArrayByRouteID && sheduleTimeArrayByRouteID.length
            ? this.renderSheduleTimeList(sheduleTimeArrayByRouteID) : <span>&mdash;</span>}
        </div>
      </div></li>
  }

  render() {
    const { routes } = this.props;

    return <div className="routes">
      <ul className="routes__list">
        <span className="routes__list__label">Автобусы</span>
        {routes.map((item, index) => {
          return <li key={index} className="routes__list__item">{item.number}</li>
        })}
      </ul>

      <ul className="routes__sheduleList">
        <div className="routes__sheduleList__title">
          <span className="routes__sheduleList__label">Прогноз прибытия</span>
          <span className="routes__sheduleList__label routes__sheduleList__label_right">Время прибытия</span>
        </div>
        {!this.state.sheduleIsLoading && routes.map((item, index) => {
          return this.renderShedule(item, index)
        })}
      </ul>
    </div>
  }
}