import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import './form-group.less';

@propTypes({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
})

export default class FormGroup extends Component {

    render() {
        return (
            <div className="formGroup">
                <label htmlFor={this.props.name}>{this.props.label}</label>
                {this.props.children}
            </div>
        );
    }

}