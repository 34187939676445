export const SET_ADD_ROAD_MODAL = 'kurs/SET_ADD_ROAD_MODAL';
export const setAddRoadModal = (payload) => ({
	type: SET_ADD_ROAD_MODAL,
	payload
});

export const SET_ROAD_PARTS = 'kurs/SET_ROAD_PARTS';
export const setRoadParts = (payload) => ({
	type: SET_ROAD_PARTS,
	payload
});

export const SET_CURRENT_TASK_ROAD_PARTS = 'kurs/SET_CURRENT_TASK_ROAD_PARTS';
export const setCurrentTaskRoadParts = (payload) => ({
	type: SET_CURRENT_TASK_ROAD_PARTS,
	payload
});

export const SET_SEARCH_ROAD_PARTS = 'kurs/SET_SEARCH_ROAD_PARTS';
export const setSearchRoadParts = (payload) => ({
	type: SET_SEARCH_ROAD_PARTS,
	payload
});

export const SET_LOADING_ROAD_PARTS = 'kurs/SET_LOADING_ROAD_PARTS';
export const setLoadingRoadParts = (payload) => ({
	type: SET_LOADING_ROAD_PARTS,
	payload
});

export const SET_KURS_TASKS = 'kurs/SET_KURS_TASKS';
export const setKursTasks = (payload) => ({
	type: SET_KURS_TASKS,
	payload
});

export const SET_KURS_TASKS_LOADING = 'kurs/SET_KURS_TASKS_LOADING';
export const setKursTasksLoading = (payload) => ({
	type: SET_KURS_TASKS_LOADING,
	payload
});

export const SET_CONTRACTS_LIST = 'kurs/SET_CONTRACTS_LIST';
export const setContractsList = (payload) => ({
	type: SET_CONTRACTS_LIST,
	payload
});

export const SET_CURRENT_TASK_TYPE = 'kurs/SET_CURRENT_TASK_TYPE';
export const setCurrentTaskType = (payload) => ({
	type: SET_CURRENT_TASK_TYPE,
	payload
});

export const SET_COLLAPSE_MULTI = 'kurs/SET_COLLAPSE_MULTI';
export const setCollapseMulti = (payload) => ({
	type: SET_COLLAPSE_MULTI,
	payload,
})

export const SET_EXPANDED_SKPDI = 'kurs/SET_EXPANDED_SKPDI';
export const setExpandedSkpdi = (payload) => ({
	type: SET_EXPANDED_SKPDI,
	payload,
})

export const SET_EXPANDED_RNIS = 'kurs/SET_EXPANDED_RNIS';
export const setExpandedRnis = (payload) => ({
	type: SET_EXPANDED_RNIS,
	payload,
})

export const SET_COOPERATION_AGREEMENTS = 'kurs/SET_COOPERATION_AGREEMENTS';
export const setCooperationAgreements = (payload) => ({
	type: SET_COOPERATION_AGREEMENTS,
	payload,
})

export const SET_CONFIRM_REQUEST = 'kurs/SET_CONFIRM_REQUEST';
export const setConfirmRequest = (payload) => ({
	type: SET_CONFIRM_REQUEST,
	payload,
})

