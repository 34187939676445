import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import _ from 'lodash';
import {success, error} from 'helpers/response';
import {User} from "helpers/user";

const initialState = new Map({
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getStopPointPavilions = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.listStopPointPavilions(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getStopPointPavilion = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getStopPointPavilion(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createStopPointPavilion = (data) => async (dispatch) => {
    try {
        const response = await api.geo.createStopPointPavilion(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateStopPointPavilion = (data) => async (dispatch) => {
    try {
        const response = await api.geo.updateStopPointPavilion(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteStopPointPavilion = (data) => async (dispatch) => {
    try {
        const response = await api.geo.deleteStopPointPavilion(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};