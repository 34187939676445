import React, {Component} from "react";
import {connect} from 'react-redux';
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';
import Datepicker from "../../../../../../ui/form/datepicker";
import {Label, Pie, PieChart } from "recharts";
import Select from "../../../../../../ui/select";
import formats from "dictionaries/formats";
import moment from "moment";
import classnames from "classnames"
import _ from "lodash";
import $ from "jquery"

import "./style.less"
import {getDictionaryList} from "../../../../../../../store/reducers/dictionaries/dictionary";
import {getUnits} from "../../../../../../../store/reducers/organizational_units/units";
import {setWidgetFrom, setWidgetTo} from "../../../../../../../store/reducers/widget/widget";
import {
  getOption,
  getOptions,
  optionsUpdated,
  setOptionValue,
  setUserOptionValue
} from "../../../../../../../store/reducers/settings";

@propTypes({
  height: PropTypes.number,
  from: PropTypes.string,
  to: PropTypes.string,
  calculateMiddlePercent: PropTypes.number,
})

@connect(state => ({
  widget_from: state.widget.widget_from,
  widget_to: state.widget.widget_to,
}), {})

class WorkPercent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selected: "best",
      widgetHeight: null,
      data: {
        percent: null,
      }
    };
  }

  componentDidMount() {
    var _ = this;
    $(".tiles__r-progress-bar .recharts-text.recharts-label").on('click', () => {
      _.redirect()
    })


  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.widget_from !== prevProps.widget_from) {
      this.props.fromChange({target: {value: this.props.widget_from}})
    }

    if (this.props.widget_to !== prevProps.widget_to) {
      this.props.toChange({target: {value: this.props.widget_to}})
    }

    if (this.props.calculateMiddlePercent >= 0 && this.props.calculateMiddlePercent !== prevProps.calculateMiddlePercent) {
      this.setState({
        data: { percent: this.props.calculateMiddlePercent }
      })
    }
  }

  get(field, forDisplay = true) {
    const value = _.get(this.state.data, field, null);
    if (forDisplay) {
      if (value !== null) {
        return _.round(value, 0);
      } else {
        return '-';
      }
    }
    return value || 0;
  }

  renderWorkTypes() {
    if (this.props.normalizedJobsData) {
      return this.props.normalizedJobsData.sort((a,b) => {
        if (this.state.selected === "worst") {
          return a.percent - b.percent
        } else {
          return b.percent - a.percent
        }
      }).map((job, index) => {
        return <li onClick={() => this.handleLink(job)}className="jobs-list__item" key={index}>{job.label} - {job.percent} %</li>
      })
    }
    return null
  }

  handleLink(job) {
    const date_from = moment(this.props.from).format("YYYY-MM-DD")
    const date_to = moment(this.props.to).format("YYYY-MM-DD")
    this.props.router.push(`/analytics/executedworks/map?work_type=${job.work_type_uuid}&date_from=${date_from}&date_to=${date_to}`);
  }

  redirect() {
    const date_from = moment(this.props.from).format("YYYY-MM-DD")
    const date_to = moment(this.props.to).format("YYYY-MM-DD")
    this.props.router.push(`/analytics/executedworks/map?date_from=${date_from}&date_to=${date_to}`)
  }

  render() {
    return (
      <div className="percent-widget">
        <div className="date-wrapper">
          <div className="date-item widget-datepicker">
            <span className="date-label">Дата с:</span>
            <Datepicker style="light" value={this.props.from} onChange={this.props.fromChange}/>
          </div>
          <div className="date-item widget-datepicker">
            <span className="date-label">Дата по:</span>
            <Datepicker style="light" value={this.props.to} onChange={this.props.toChange}/>
          </div>
        </div>
        <div className="row" style={{height: this.props.height ? this.props.height * 0.7 : '187.6px'}}>
          <div className="col col-5">
            <div className="tiles__row tiles__row_bdn">
              <div style={{fontSize:`${this.props.fontSize}px`, marginBottom: `${this.props.fontSize * 0.95}px` }} className="tiles__col">Выполнено работ</div>
              <div className={classnames("tiles__r-progress-bar")}>
                <PieChart width={103} height={103}>
                  <Pie data={[
                    {
                      value: this.get('percent', false),
                    },
                    {
                      value: 100 - this.get('percent', false),
                      fill: 'rgba(255, 255, 255, 0.5)',
                    }
                  ]}
                       nameKey="name"
                       dataKey="value"
                       cx="50%"
                       cy="50%"
                       outerRadius={50}
                       innerRadius={43}
                       startAngle={90}
                       endAngle={-270}
                       fill="#FFF"
                       stroke="none">
                    <Label value={`${this.get('percent')}%`}
                           position="center" fill="#000" />
                  </Pie>
                </PieChart>
              </div>
            </div>
          </div>
          <div className="col col-5">
            <div className="widgets__selector widgets__selector_top">
              <Select value={this.state.selected}
                      onChange={async (e) => {
                        const value = e ? e.value : null;
                        await this.setState({
                          selected: value,
                        });
                      }}
                      clearable={false} options={[
                {
                  value: 'best',
                  label: 'Топ лучших',
                },
                {
                  value: 'worst',
                  label: 'Топ худших',
                },
              ]}/>
            </div>
            <p style={{fontSize:`${this.props.fontSize * 1.75}px`}}>Виды работ</p>
            <div className="job">
              <ul className="jobs-list">
                {this.renderWorkTypes()}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(WorkPercent)