import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import Datepicker from "components/ui/form/datepicker";
import moment from "moment";
import formats from "dictionaries/formats";
import {TableColumnFilterBase} from "components/ui/Table/Column/Filter/TableColumnFilterBase";

@propTypes({
    index: PropTypes.number.isRequired,
    column: PropTypes.object.isRequired,
    opened: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onFilterOpened: PropTypes.func
})

@defaultProps({
    opened: false,
    onFilterOpened: () => {
    }
})

export class TableColumnFilterDate extends TableColumnFilterBase {

    constructor(...args) {
        super(...args);

        Object.assign(this.state, {
            from: null,
            to: null,
        });
    }

    componentWillMount() {
        super.componentWillMount();

        if (this.props.column.filterValue) {
            const filterValue = this.props.column.filterValue.split(';');
            this.setState({
                from: (filterValue[1] && filterValue[1] !== '.') ? moment(filterValue[1], formats.DATE_URL).format(formats.DATE_URL) : null,
                to: (filterValue[2] && filterValue[2] !== '.') ? moment(filterValue[2], formats.DATE_URL).format(formats.DATE_URL) : null,
            });
        }
    }

    async componentDidUpdate() {
        if ($(".top-menu__link.top-menu__link_icon_filter.active").length === 0 && this.state.from) {
            this.setState({
                from: null,
                to: null,
            })
        }
    }

    hasValue() {
        return this.state.from || this.state.to;
    }

    renderContent() {
        return (
            <div className="filtration__search">
                <Datepicker
                    key="from"
                    value={this.state.from}
                    onChange={this.onDateChange.bind(this, 'from')}
                />
                <Datepicker
                    key="to"
                    value={this.state.to}
                    onChange={this.onDateChange.bind(this, 'to')}
                />
            </div>
        );
    }

    onDateChange(field, {target: {value}}) {
        let state = this.state;
        state[field] = value;
        this.setState(state);

        let changeValue = [
            '.',
            '.',
        ];
        if (this.state.from) {
            changeValue[0] = moment(this.state.from).format(formats.DATE_URL);
        }
        if (this.state.to) {
            changeValue[1] = moment(this.state.to).format(formats.DATE_URL);
        }
        if (!this.state.from && !this.state.to) {
            changeValue = null;
        }

        this.props.onChange(this.props.index, changeValue ? `date;${changeValue.join(';')}` : null);
    }
}