import React from 'react';
import { List } from 'immutable';
import _ from 'lodash';
import { mapDatatablesRequestToMeta } from 'helpers/api';
import * as alerts from "helpers/alerts";
import * as storage from "utils/storage";
import BaseTableComponent from "components/base/base_table";
import { EntityList } from "helpers/entity";
import TableContainer from "components/ui/Table/Container/TableContainer";
import Page from 'components/ui/page';
import IconButton from 'components/ui/icon-button';
import SlideLeftTransition from "components/ui/transitions/slide-left";
import ContextTooltip from "components/ui/context-tooltip";
import HotKeysManager from "components/ui/HotKeys/Manager/HotKeysManager";
import { preventDefaultEventHandlers } from "helpers/hot_key_handlers";

export default class BaseTableWithEditorComponent extends BaseTableComponent {

    lastRequest = null;

    getEditor() {
        throw new Error('You have to implement this method: getEditor');
    }

    getColumns() {
        throw new Error('You have to implement this method: getColumns');
    }

    async deleteItem(data) {
        throw new Error('You have to implement this method: deleteItem');
    }

    async loadData(meta) {
        throw new Error('You have to implement this method: loadData');
    }

    async loadRelatedEntities(json, drawCallback) {
        drawCallback(json);
    }

    renderModals() {

    }

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            columns: List(this.getColumns()),
            related: new EntityList(),
            initial: true,
        });
    }

    getTitle() {
        return this.title;
    }

    getBaseUrl() {
        return this.baseUrl;
    }

    async componentWillUpdate(props, nextState, nextContext) {
        if (props.params) {
            const uuid = props.params.uuid;
            if (location.pathname.includes('/operator/esia_requests')) {
                if (uuid) {
                    const newEditorUuid = (uuid && uuid !== 'create') ? uuid : null;
                    if (this.state.editorUuid !== newEditorUuid || this.state.showEditorModal !== !!uuid) {
                        this.setState({
                            showEditorModal: !!uuid,
                            editorUuid: newEditorUuid
                        });
                    }
                }
            } else {
                const newEditorUuid = (uuid && uuid !== 'create') ? uuid : null;
                if (this.state.editorUuid !== newEditorUuid || this.state.showEditorModal !== !!uuid) {
                    this.setState({
                        showEditorModal: !!uuid,
                        editorUuid: newEditorUuid
                    });
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.from !== this.state.from || prevState.to !== this.state.to) {
            this.setState({
                filterChange: true,
            })
        }
    }

    getHotKeyHandlers() {
        return preventDefaultEventHandlers(Object.assign(super.getHotKeyHandlers(), {
            delete: :: this.deleteSelected,
        }));
    }

    render() {
        let table = this.renderTable();

        const editor = this.state.showEditorModal ? this.getEditor() : '';

        return (
            <HotKeysManager {...this.getHotKeyHandlers()}>
                <Page key={this.props.params.component || 'page'}
                    title={this.getTitle()}
                    pageId="BaseTableWithEditor"
                    headerActions={this.renderHeaderActions()}
                    headerContents={this.renderHeaderContents()}>
                    <TableContainer>
                        {table}
                    </TableContainer>

                    <SlideLeftTransition>
                        {editor}
                    </SlideLeftTransition>

                    {this.renderModals()}
                </Page>
            </HotKeysManager>
        );
    }

    async closeEditor() {
        await this.props.router.push(this.getBaseUrl());
    }

    reload() {
        this.refs.table && this.refs.table.getWrappedInstance().reload();
    }

    async submitEditor() {
        this.reload();
        await this.closeEditor();
    }

    async showEditor() {
        this.showEditorWithUuid();
    }

    async showEditorWithUuid(uuid = null) {
        await this.closeEditor();
        this.props.router.push(`${this.getBaseUrl()}/${uuid || 'create'}`);
    }

    async onDblClick(data) {
        if (data && data.uuid) {
            this.showEditorWithUuid(data.uuid);
        }
    }

    async editSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            this.showEditorWithUuid(selected.uuid);
        }
    }

    async deleteSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (!selected) {
            return;
        }
        return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            const response = await this.deleteItem({
                uuid: selected.uuid,
            });
            if (response.isOk) {
                await this.setState({
                    selectedRows: [],
                    selectedRowsCount: 0
                })
                this.reload();
            } else {
                response.showErrors();
            }
        });
    }

    getStorageKey() {
        const path = window.location.pathname.replace(/\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/g, '/_uuid_');
        return `table-filters:${path}`;
    }

    prepareColumns(columns) {
        const filters = storage.get(this.getStorageKey(), {});
        if (filters && !this.state.showTableFilters) {
            this.state.showTableFilters = true;
        }

        return super.prepareColumns(columns).map((column, index) => {
            if (column.filterName) {
                const filterValue = _.get(filters, column.filterName);
                if (filterValue) {
                    column.filterValue = filterValue;
                }
            } else if (column.field) {
                const filterValue = _.get(filters, column.field);
                if (filterValue) {
                    column.filterValue = filterValue;
                }
            }
            return column;
        });
    }

    async loadCallback(request, drawCallback, settings) {

        this.lastRequest = request;
        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);

        if (this.state.showTableFilters) {
            storage.save(this.getStorageKey(), Object.assign(_.cloneDeep(meta.filters), _.mapValues(_.keyBy(meta.column_search, 'column'), 'value')));
        } else {
            storage.remove(this.getStorageKey());
        }

        const response = await this.loadData(meta);

        if (response.isOk) {

            const data = response.payload.items;

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: response.data.headers.meta.pagination ? response.data.headers.meta.pagination.total : 0,
                recordsTotal: response.data.headers.meta.pagination ? response.data.headers.meta.pagination.total : 0,
            };

            if (!request.sync && (request.length === 25)) {
                drawCallback(json);
                this.loadRelatedEntities(json, drawCallback);
            } else {
                await this.loadRelatedEntities(json, drawCallback);
            }
            this.selectedRowsRecalc();
            this.setState({ success: true });
        } else {
            response.showErrors();
        }
    }

    getWaybillBarchCloseBtn = () => {
        return (
            <ContextTooltip
                key="base-table-list.waybill-batch-close"
                code="base-table-list.waybill-batch-close"
                default="Пакетное закрытие ПЛ"
                position="left"
            >
                <IconButton 
                    icon="check"
                    onClick={() => this.props.router.push('/road/waybills-batch-close')}
                />
            </ContextTooltip>
        )
    }

    renderHeaderActions() {
        const actions = [
            <ContextTooltip key="base-table-list.delete" code="base-table-list.delete" default="Удалить"
                position="left">
                <IconButton icon="basket" disabled={this.state.selectedRowsCount !== 1}
                    onClick={::this.deleteSelected}/>
            </ContextTooltip>,
        ];

        return this.showWaybillBarchCloseBtn && window.RNIS_SETTINGS.waybill_batch_close ? (
            [this.getWaybillBarchCloseBtn(), ...actions]
        ) : actions;
    }
}
