import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import classNames from 'classnames';

import _ from 'lodash';

import BaseTableComponent from 'components/base/base_table';
import Page from 'components/ui/page';

import {mapDatatablesRequestToMeta} from 'helpers/api';

import './trash-logs.less';

import TableContainer from "components/ui/Table/Container/TableContainer";
import Column from "components/ui/column";
import {getUsers} from "store/reducers/staffing/staffing";
import moment from "moment";
import formats from "dictionaries/formats";
import {User} from "helpers/user";
import {getEntityNames, restoreEntity} from "store/reducers/system";
import {getTrashLogs} from "store/reducers/trash-logs/trash-logs";
import IconButton from "components/ui/icon-button";
import {EntityList} from "helpers/entity";
import Datepicker from "components/ui/form/datepicker";
import Button from "components/ui/button";
import * as alerts from "helpers/alerts";
import systems from "dictionaries/systems";
import ContextTooltip from "components/ui/context-tooltip";
import TableColumnSelectFields from "components/ui/Table/Column/SelectFields/TableColumnSelectFields";
import TableComponent from "components/ui/Table/Component/TableComponent";
import InputMask from "../../../ui/form/input-mask";

@connect(state => ({}), {getTrashLogs, getUsers, getEntityNames, restoreEntity})

export default class TrashLogsComponent extends BaseTableComponent {

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            entities: new EntityList(),
            users: new EntityList(),
            columns: List(this.getColumns()),
            from: moment().startOf('day'),
            to: moment().add(1, 'day').startOf('day'),
            from_time: '00:00',
            to_time: '23:59'
        });
    }

    renderTable() {
        const className = classNames({
            'show-table-filters': this.state.showTableFilters,
        });

        return (
            <div className={className}>
                <TableComponent
                    ref="table"
                    columns={this.state.columns}
                    select="single"
                    loadCallback={::this.loadCallbackMiddleware}
                    onDblClick={::this.onDblClick}
                    onColsReordered={::this.onColsReordered}
                    onCheck={::this.selectedRowsRecalc}
                    checkCache={::this.checkCache}
                    query={this.state.query}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    defaultOrder={[[0, 'desc']]}
                />
            </div>
        );
    }

    async onDblClick(data) {
        if (data && data.entity_uuid) {
            this.props.router.push(`/system/audit/${data.entity_uuid}?class=${data.entity_class}`);
        }
    }

    render() {
        let table = this.renderTable();

        return (
            <Page title={`${systems.administration} → Корзина`}
                  pageId="TrashLogs"
                  headerActions={this.renderHeaderActions()}
                  headerContents={this.renderHeaderContents()}>

                <TableContainer>
                    {table}
                </TableContainer>
            </Page>
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Дата/время')
                .fromField('timestamp')
                .denyColumnFilter()
                .withDrawer(item => moment(item.timestamp).format(formats.DATETIME)),

            new Column('Пользователь')
                .fromField('user_uuid')
                .withDrawer(item => item.user_uuid && (this.state.users.get(item.user_uuid) + ` (${item.ip || '-'})`))
                .denyColumnFilter()
                .withAsyncFilter('withUsers', async (search) => {
                    const response = await this.props.getUsers({
                        search,
                        order: {
                            column: 'surname',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 50,
                        },
                    });
                    if (response.isOk) {
                        return _.map(response.payload.items, user => ({
                            uuid: user.uuid,
                            name: new User(user).getFullName()
                        }));
                    }
                    return [];
                }),

            new Column('Сущность')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => item.entity_uuid && (this.state.entities.get(item.entity_uuid, true) || '<code>-неизвестно-</code>')),

        ]);
    }

    async reload() {
        this.refs.table && this.refs.table.getWrappedInstance().reload();
    }

    async loadCallback(request, drawCallback, settings) {
        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);

        meta.filters.withDates = [
            moment(this.state.from).format(formats.DATETIME_API),
            moment(this.state.to).format(formats.DATETIME_API),
        ];
        const response = await this.props.getTrashLogs(meta);

        if (response.isOk) {
            const data = response.payload.items;

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: response.data.headers.meta.pagination.total,
                recordsTotal: response.data.headers.meta.pagination.total
            };

            drawCallback(json);
            this.prepareData(json, drawCallback);
            this.selectedRowsRecalc();
            this.setState({success: true});
        } else {
            response.showErrors();
        }
    }

    async prepareData(json, drawCallback) {
        const result = json.data;

        const users = _.map(_.uniq(_.filter(_.map(result, 'user_uuid'))), (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));
        const entities = _.map(result, (item) => ({
            class: item.entity_class,
            uuid: item.entity_uuid,
            source: item.source,
        }));
        await this.loadUsers(users);
        const response = await this.props.getEntityNames(_.concat(users, entities), true);
        if (response.isOk) {
            this.setState({
                entities: new EntityList(response),
            });
        }

        drawCallback(json);
    }

    async loadUsers(users) {
        const response = await this.props.getEntityNames(users);
        if (response.isOk) {
            this.setState({
                users: new EntityList(response),
            });
        }
    }

    renderHeaderActions() {
        return [
            this.diapasonFilter(),
            <li className="top-menu__item" key="separator2">
                <div className="top-menu__separator"/>
            </li>,
            <Button key="restore" text="Восстановить" color="dark"
                    disabled={this.state.selectedRowsCount !== 1}
                    className="top-menu__button top-menu__button_recovery" onClick={::this.restore}/>,
        ];
    }

    async restore() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (!selected) {
            return;
        }
        return alerts.prompt('Вы действительно хотите восстановить выбранный объект?', '', async () => {
            const response = await this.props.restoreEntity({
                class: selected.entity_class,
                uuid: selected.entity_uuid,
            }, selected.source);
            if (response.isOk) {
                alerts.success('Выбранный объект восстановлен!');
                this.reload();
            } else {
                response.showErrors();
            }
        }, 'Восстановить');
    }

    diapasonFilter() {
        return (
            <div key="diapason">
                <div className="top-menu__label">Выбор диапазона:</div>
                <Datepicker style="dark" value={this.state.from} onChange={::this.fromChange}/>
                <InputMask mask="99:99" value={this.state.from_time}
                           onChange={::this.fromTimeChange}
                           className="header-time"
                           onKeyPress={::this.onFilterKeyPress}/>
                <Datepicker style="dark" value={this.state.to} onChange={::this.toChange}/>
                <InputMask mask="99:99" value={this.state.to_time}
                           onChange={::this.toTimeChange}
                           className="header-time"
                           onKeyPress={::this.onFilterKeyPress}/>
            </div>
        );
    }

    onFilterKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();

           // this.loadHistory();
        }
    }

    async fromChange({target: {value}}) {
        await this.setState({from: value});
        this.reload();
    }

    async fromTimeChange({target: {value}}) {
        let date = moment(this.state.from).format(formats.DATE)
        let dateTime = moment(date + ' ' + value, formats.DATETIME_SHORT);

        console.log('dateTime', dateTime)
        await this.setState({
            from_time: value,
            from: dateTime
        });
        this.reload();
    }

    async toTimeChange({target: {value}}) {
        let date = moment(this.state.to).format(formats.DATE)
        let dateTime = moment(date + ' ' + value, formats.DATETIME_SHORT);
        await this.setState({
            to_time: value,
            to: dateTime
        });
        this.reload();
    }

    async toChange({target: {value}}) {
        await this.setState({to: value});
        this.reload();
    }

    renderHeaderContents() {
        return [
            <ContextTooltip key="base-table-list.filter" code="base-table-list.filter">
                <IconButton
                    icon="filter"
                    active={this.state.showTableFilters}
                    onClick={::this.toggleTableFilters}/>
            </ContextTooltip>,
            <ContextTooltip key="base-table-list.columns-select" code="base-table-list.columns-select">
                <TableColumnSelectFields
                    columns={this.state.columns}
                    showPopup={this.state.showSelectFieldsPopup}
                    onChange={::this.onChangeSelectFieldsCheckbox}
                    onToggle={::this.onToggleSelectFieldsPopup}/>
            </ContextTooltip>
        ];
    }
}
