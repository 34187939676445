import React from 'react';
import ContextTooltip from "components/ui/context-tooltip";

export default function DriverTable({
                                        fullName, personnelNumber,
                                        onRtiOClick, disableRtiO,
                                        isCommunication, disableCommunication, onCallClick, onSMSClick,
                                        from, to
                                    }) {
    return (
        <div className="output__cell-group">
            <div className="output__cell">
                <span>Водитель:</span>
            </div>
            <div className="output__cell">
                {(isCommunication && !disableCommunication) ? [
                    <a key="call" href="javascript:void(0)" onClick={onCallClick}
                       className="b-button b-button_dark b-button_shadow_gray b-button_call"/>,
                    <a key="sms" href="javascript:void(0)" onClick={onSMSClick}
                       className="b-button b-button_dark b-button_shadow_gray b-button_email"/>,
                ] : null}
            </div>
            <div className="output__cell">
                <ContextTooltip key="transport-work.rtio" code="transport-work.rtio"
                                default="Сводка по режиму труда водителя">
                    <div className="output__driver" onClick={disableRtiO ? () => (null) : onRtiOClick}>{fullName}</div>
                </ContextTooltip>
            </div>
            <div className="output__cell _border_left">
                <span>С</span><br/>
                <span>По</span>
            </div>
            <div className="output__cell">
                {from}<br/>
                {to}
            </div>
        </div>
    );
}