import { query } from 'dom-helpers';
import dhClass from 'dom-helpers/class';
import $ from 'jquery';

function maxWidth() {
    const padding = 2 * 20;

    let root = query.querySelectorAll(document, '#root');

    root = root.length ? root[0] : null;

    if (root) {
        let leftMenu = query.querySelectorAll(root, '.left-menu');
        leftMenu = leftMenu.length ? leftMenu[0] : null;

        if (leftMenu) {
            return query.width(root) - query.width(leftMenu) - padding;
        }

        return query.width(root) - padding;
    }

    return 0;
}

function getNodes(selector, ignoreClasses) {
    let nodes = query.querySelectorAll(document, selector);

    nodes = nodes.filter((node) => {
        for (let i = 0; i < ignoreClasses.length; i++) {
            if (dhClass.hasClass(node, ignoreClasses[i])) {
                return false;
            }
        }

        return true;
    });
    return nodes;
}

function currentWidth(nodes) {
    let width = 0;

    nodes.forEach((node) => {
        width += query.width(node);
    });

    return width;
}

function removeMoveClasses(node, count, className) {
    let i = 2;
    while (i <= count) {
        dhClass.removeClass(node, className + '-' + i);
        i++
    }
}

function getExpandedCount(i, nodes, className) {
    let expandedCount = 0;
    for (let k = i; k >= 0; k--) {
        if (!dhClass.hasClass(nodes[k], className)) {
            expandedCount += 1;
        }
    }

    return expandedCount;
}

function collapse(selector, collapseClass, moveClass, ignoreClasses) {
    const nodes = getNodes(selector, ignoreClasses);
    const width = currentWidth(nodes);

    const size = nodes.length - 1;

    if (width > maxWidth()) {
        //console.log('collapse');
        let isCollapsed = false;

        for (let i = 0; i <= size; i++) {
            const node = nodes[i];
            if (!isCollapsed && i !== size && !dhClass.hasClass(node, collapseClass)) {
                dhClass.addClass(node, collapseClass);
                isCollapsed = true;
            }

            if (isCollapsed) {
                let curNode = node;

                for (let j = i + 1; j <= size; j++) {
                    const nextNode = nodes[j];

                    if (j > 1) {
                        const expandedCount = getExpandedCount(j - 1, nodes, collapseClass);
                        //console.log('1 expandedCount: ', expandedCount);

                        removeMoveClasses(nextNode, nodes.length, moveClass);
                        dhClass.removeClass(nextNode, moveClass);

                        if (expandedCount === 0) {
                            dhClass.addClass(nextNode, moveClass);
                        }
                        else if (expandedCount !== j) {
                            dhClass.addClass(nextNode, moveClass + '-' + (expandedCount + 1));
                        }
                    }
                    else {
                        if (!dhClass.hasClass(curNode, collapseClass)) {
                            dhClass.removeClass(nextNode, moveClass);
                        }
                        else {
                            dhClass.addClass(nextNode, moveClass);
                        }
                    }

                    curNode = nodes[j];
                }
            }
        }

        if (isCollapsed) {
            collapse(selector, collapseClass, moveClass, ignoreClasses);
        }
    }
}

function expand(selector, collapseClass, moveClass, ignoreClasses) {
    const nodes = getNodes(selector, ignoreClasses);
    const width = currentWidth(nodes);

    const size = nodes.length - 1;

    if (width <= maxWidth()) {
        //console.log('expand');
        let isExpanded = false;

        for (let i = size; i >= 0; i--) {
            const node = nodes[i];
            if (!isExpanded && dhClass.hasClass(node, collapseClass)) {
                dhClass.removeClass(node, collapseClass);

                const nodes = getNodes(selector, ignoreClasses);
                const width = currentWidth(nodes);

                if (width > maxWidth()) {
                    dhClass.addClass(node, collapseClass);
                    return;
                }

                isExpanded = true;
            }

            if (isExpanded) {
                let curNode = node;

                for (let j = i + 1; j <= size; j++) {
                    const prevNode = nodes[j];

                    if (j > 1) {
                        const expandedCount = getExpandedCount(j - 1, nodes, collapseClass);
                        //console.log('qwe 2 expandedCount: ', expandedCount);

                        removeMoveClasses(prevNode, nodes.length, moveClass);
                        dhClass.removeClass(prevNode, moveClass);

                        if (expandedCount === 0) {
                            dhClass.addClass(prevNode, moveClass);
                        }
                        else if (expandedCount !== j) {
                            dhClass.addClass(prevNode, moveClass + '-' + (expandedCount + 1));
                        }
                    }
                    else {
                        if (!dhClass.hasClass(curNode, collapseClass)) {
                            dhClass.removeClass(prevNode, moveClass);
                        }
                        else {
                            dhClass.addClass(prevNode, moveClass);
                        }
                    }

                    curNode = nodes[j];
                }
            }
        }

        if (isExpanded) {
            expand(selector, collapseClass, moveClass, ignoreClasses);
        }
    }
}

function moveAll(selector, collapseClass, moveClass, ignoreClasses) {
    const nodes = getNodes(selector, ignoreClasses);

    const size = nodes.length - 1;

    let index = 0;
    for (let i = 0; i <= size; i++) {
        const node = nodes[i];
        if (!dhClass.hasClass(node, collapseClass)) {
            removeMoveClasses(node, nodes.length, moveClass);
            if (index === 0) {
                dhClass.addClass(node, moveClass);
            } else {
                dhClass.addClass(node, moveClass + '-' + (index + 1));
            }
            index++;
        }
    }
}

function autowidthLeft(selector, ignoreClasses) {
    const nodes = getNodes(selector, ignoreClasses);

    const autowidthClassName = 'autowidth';

    for (let i = 0; i < nodes.length; i++) {
        dhClass.removeClass(nodes[i], autowidthClassName);
    }
    if (nodes.length === 1) {
        return;
    }
    const lastNode = nodes[nodes.length - 1];
    if ($(lastNode).hasClass('b-modal_stretch')) {
        dhClass.addClass(lastNode, autowidthClassName);
    }
}

export function resizeModals(selector, collapseClass, moveClass, ignoreClasses) {
    const nodes = getNodes(selector, ignoreClasses);
    const width = currentWidth(nodes);

    if (width > maxWidth()) {
        collapse(selector, collapseClass, moveClass, ignoreClasses);
    }
    else {
        expand(selector, collapseClass, moveClass, ignoreClasses);
        moveAll(selector, collapseClass, moveClass, ignoreClasses);
    }

    autowidthLeft(selector, ignoreClasses);

    //console.log(nodes);
}