import React, {Component} from "react";
import {connect} from "react-redux";

import Page from "components/ui/page";
import Loader from "components/ui/loader";
import Button from "components/ui/button";
import BaseEditorFormComponent from "components/base/base-editor-form";

import {get, forEach, isBoolean, merge} from 'lodash';
import currentUser from 'helpers/current-user';

import GlobalLoaderComponent from "components/ui/global-loader";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import {
    createConfirmRequest, getConfirmRequest, getConfirmRequests,
    updateConfirmRequest
} from "store/reducers/portal/confirm_requests";
import _ from 'lodash';
import Block from "components/ui/form/block";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import TableContainer from "components/ui/Table/Container/TableContainer";
import moment from "moment";
import formats from "dictionaries/formats";
import './index.less';
import {State} from "components/ui/state";
import {getTelematicsWithDevices} from "store/reducers/maps";
import $ from 'jquery';
import Tables from 'dictionaries/tables';
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import classNames from 'classnames';
import CheckboxDropdown from "components/ui/checkbox-dropdown";

@connect(state => ({}), {getConfirmRequests, createConfirmRequest, updateConfirmRequest, getDictionaryList})

export default class UnitProfileVehiclesComponent extends Component {

    state = {
        loading: true,
        saving: false,
        errors: {},
        selectedVehicleTypes: {},
        selectedVehicleBnso: {
            'active': true,
            'inactive': true,
        },
        selectedVehicleOwn: {
            'own': true,
            'leasee': true,
        },
    };

    async componentDidMount() {
        this.loadConfirmRequest();

        await this.loadDictionaries([
            'vehicle_types',
        ]);
        this.setState({
            selectedVehicleTypes: _.mapValues(_.mapKeys(this.state.vehicle_types, item => item.value), item => true),
        });
    }

    async loadConfirmRequest() {
        const response = await this.props.getConfirmRequests({
            filters: {
                withUnit: this.props.params.uuid,
            }
        });

        if (response.isOk) {
            this.setState({
                loading: false,
                data: _.find(response.payload.items, {
                    uuid: this.props.params.uuid2,
                }) || _.first(response.payload.items),
            });
        } else {
            response.showErrors();
        }
    }

    async createConfirmRequest() {
        const response = await this.props.createConfirmRequest({
            unit_uuid: currentUser.unitUuid(),
            user_uuid: currentUser.uuid(),
            vehicles: [],
        });

        this.setState({
            loading: false,
        });
        if (response.isOk) {
            this.setState({
                data: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    clearErrors() {
        this.setState({errors: {}});
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    async onSubmit(data) {
        this.startSave();
        const response = await this.props.updateConfirmRequest(data);
        this.endSave();

        if (response.isOk) {
            this.props.router.push(`/profile/unit?confirm_request=${this.props.params.uuid2}`);
        } else {
            response.showErrors();
        }
    }

    onClose() {

    }

    render() {
        const loader = (this.state.loading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;
        let form = null;

        if (this.state.data) {
            form = <UnitProfileEditorComponent
                {...this.props}
                ref="form"
                data={this.state.data}
                onSubmit={::this.onSubmit}
                onClose={::this.onClose}
                forceUpdate={::this.forceUpdate}
                errors={this.state.errors}
                selectedVehicleTypes={this.state.selectedVehicleTypes}
                selectedVehicleBnso={this.state.selectedVehicleBnso}
                selectedVehicleOwn={this.state.selectedVehicleOwn}
            />;
        }
        return (
            <Page pageId="Main" title={`Профиль организации → Выбор ТС`}
                  headerActions={this.renderHeaderActions()}
                  headerContents={this.renderHeaderContents()}>
                {loader}

                {form}
            </Page>
        )
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="unit-profile-vehicles.select-all" code="unit-profile-vehicles.select-all"
                            default={(this.refs.form && this.refs.form.getWrappedInstance().get('selectedAll')) ? 'Очистить' : 'Выбрать все'}
                            position="left">
                <IconButton icon="select-all" onClick={::this.selectAll}/>
            </ContextTooltip>,
        ];
    }

    renderHeaderContents() {
        let vehicles = [];
        if (this.refs.form) {
            vehicles = this.refs.form.getWrappedInstance().get('rawVehicles') || [];
        }

        return [
            <CheckboxDropdown
                key="vehicle-type"
                items={_.filter(this.state.vehicle_types, (type) => {
                    return _.filter(vehicles, {vehicle_type_uuid: type.value}).length > 0;
                })}
                selectedItems={this.state.selectedVehicleTypes}
                toggleSelectedItem={::this.toggleSelectedVehicleType}
                contextKey="unit-profile.vehicle-type"
                contextDefault="Выбор типов ТС"
                icon="vehicle-types"
            />,
            <CheckboxDropdown
                key="vehicle-bnso"
                items={[
                    {
                        value: 'active',
                        label: 'Активно',
                    },
                    {
                        value: 'inactive',
                        label: 'Неактивно',
                    },
                ]}
                selectedItems={this.state.selectedVehicleBnso}
                toggleSelectedItem={::this.toggleSelectedVehicleBnso}
                contextKey="unit-profile.vehicle-bnso"
                contextDefault="Статус БНСО"
                icon="status-bnso"
            />,
            <CheckboxDropdown
                key="vehicle-own"
                items={[
                    {
                        value: 'own',
                        label: 'Собственность',
                    },
                    {
                        value: 'leasee',
                        label: 'Аренда',
                    },
                ]}
                selectedItems={this.state.selectedVehicleOwn}
                toggleSelectedItem={::this.toggleSelectedVehicleOwn}
                contextKey="unit-profile.vehicle-own"
                contextDefault="Статус собственности"
                icon="status-property"
            />,
        ];
    }

    toggleSelectedVehicleType(uuid) {
        let selectedVehicleTypes = this.state.selectedVehicleTypes || {};
        selectedVehicleTypes[uuid] = !selectedVehicleTypes[uuid];
        this.setState({selectedVehicleTypes});
    }

    toggleSelectedVehicleBnso(uuid) {
        let selectedVehicleBnso = this.state.selectedVehicleBnso || {};
        selectedVehicleBnso[uuid] = !selectedVehicleBnso[uuid];
        this.setState({selectedVehicleBnso});
    }

    toggleSelectedVehicleOwn(uuid) {
        let selectedVehicleOwn = this.state.selectedVehicleOwn || {};
        selectedVehicleOwn[uuid] = !selectedVehicleOwn[uuid];
        this.setState({selectedVehicleOwn});
    }

    async selectAll() {
        if (this.refs.form) {
            await this.refs.form.getWrappedInstance().selectAll();
            this.forceUpdate();
        }
    }
}

@connect(state => ({}), {getUnits, getVehicleList, getDictionaryList, getTelematicsWithDevices}, null, {withRef: true})

class UnitProfileEditorComponent extends BaseEditorFormComponent {

    state = {
        confirm_request: {},
        units: [],
        rawVehicles: [],
        vehicles: [],
        vehicle_types: [],
        vehicle_models: [],
        bnso_types: [],
        telematics: [],
        sortColumn: 0,
        sortDirection: 'asc',
        selectedAll: false,
    };

    async componentDidMount() {
        await this.setState({
            confirm_request: this.props.data,
        });

        await Promise.all([
            this.loadDictionaries([
                'vehicle_types',
                'vehicle_models',
                'bnso_types',
            ]),
            this.loadUnits(),
        ]);
        this.loadVehicles();
    }

    async loadUnits() {
        const response = await this.props.getUnits({});


        if (response.isOk) {
            this.setState({
                units: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'name'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadVehicles() {
        const response = await this.props.getVehicleList({
            filters: {
                totallyUnapproved: true,
                withUnit: this.get('confirm_request.unit_uuid'),
            },
        });

        if (response.isOk) {
            this.loadTelematics(response.payload.items);
            await this.setState({
                rawVehicles: response.payload.items,
                vehicles: this.prepareVehicles(response.payload.items),
            });
            this.props.forceUpdate();
        } else {
            response.showErrors();
        }
    }

    prepareVehicles(vehicles) {
        return _.map(vehicles, (vehicle) => {
            const bnsoNumber = _.get(vehicle.current_bnso, 'bnso_number');

            return [
                _.get(_.find(this.state.vehicle_types, {value: vehicle.vehicle_type_uuid}), 'label'),
                vehicle.state_number,
                _.get(_.find(this.state.vehicle_models, {value: vehicle.vehicle_model_uuid}), 'label'),
                _.get(vehicle.current_bnso, 'bnso_code'),
                _.get(_.find(this.state.bnso_types, {value: _.get(vehicle.current_bnso, 'bnso_type')}), 'label'),
                (this.props.params.uuid === vehicle.unit_uuid) ? 'Собственность' : 'Аренда',
                this.state.units[vehicle.unit_uuid],
                <State positive={this.state.telematics[bnsoNumber] || false}/>,
                vehicle,
            ];
        });
    }

    async loadTelematics(vehicles) {
        const devices = _.filter(_.map(vehicles, 'current_bnso.bnso_number'));
        const response = await this.props.getTelematicsWithDevices(devices, 't1sync', null, null, 'none');

        if (response.isOk) {
            this.setState({
                telematics: _.mapValues(_.keyBy(response.payload.items, 'device_id'), (item) => {
                    return true;
                }),
            });
        }
    }

    get(field, defaultValue = null) {
        return this.getValue(field) || defaultValue;
    }

    submit() {
        this.props.onSubmit(this.state.confirm_request);
    }

    render() {
        const columns = [
            'Тип',
            'Гос. номер',
            'Модель',
            'ID БНСО',
            'Модель БНСО',
            'Статус собственности',
            'Владелец',
            'Статус БНСО',
        ];

        return (
            <div className="ProfileForm">
                <div className="Table">
                    <TableContainer>
                        <table ref="table" className="b-table">
                            <thead>
                            <tr>
                                {_.map(columns, (column, index) => {
                                    const className = classNames({
                                        sorting_asc: (this.state.sortColumn === index) && (this.state.sortDirection === 'asc'),
                                        sorting_desc: (this.state.sortColumn === index) && (this.state.sortDirection === 'desc'),
                                    });
                                    return (
                                        <th key={index} className={className}
                                            onClick={this.sortBy.bind(this, index)}>{column}</th>
                                    );
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {_.orderBy(this.getVehicles(), [this.state.sortColumn], [this.state.sortDirection]).map(::this.renderVehicleRow)}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>

                <div className="ProfileForm__buttons-block">
                    <div className="ProfileForm__buttons-block-text">Сохранить выбор?</div>
                    <Button size="md" text="Сохранить" color="red" shadow="red" onClick={::this.submit}/>
                    <a className="b-button b-button_red b-button_shadow_gray b-button_size_md b-button_disabled"
                       href="javascript:void(0)" onClick={::this.close}>Отмена</a>
                </div>
            </div>
        )
    }

    getVehicles() {
        const selectedVehicleTypes = _.keys(_.pickBy(this.props.selectedVehicleTypes || [], selected => selected));

        const byType = (vehicleData) => {
            const vehicle = _.last(vehicleData);

            return !vehicle.vehicle_type_uuid || _.indexOf(selectedVehicleTypes, vehicle.vehicle_type_uuid) !== -1;
        };
        const byOwn = (vehicleData) => {
            const vehicle = _.last(vehicleData);

            if (this.props.selectedVehicleOwn.own && (vehicle.unit_uuid === this.get('unit_uuid'))) {
                return true;
            }
            if (this.props.selectedVehicleOwn.leasee && (vehicle.unit_uuid !== this.get('unit_uuid'))) {
                return true;
            }
            return false;
        };
        const byBnso = (vehicleData) => {
            const vehicle = _.last(vehicleData);
            const bnsoNumber = _.get(vehicle.current_bnso, 'bnso_number');
            const active = this.state.telematics[bnsoNumber] || false;
            if (active && this.props.selectedVehicleBnso.active) {
                return true;
            }
            if (!active && this.props.selectedVehicleBnso.inactive) {
                return true;
            }
            return false;
        };
        return _.filter(_.filter(_.filter(this.state.vehicles, byType), byOwn), byBnso);
    }

    sortBy(index) {
        if (this.state.sortColumn === index) {
            this.setState({
                sortDirection: (this.state.sortDirection === 'asc') ? 'desc' : 'asc',
            });
        } else {
            this.setState({
                sortColumn: index,
                sortDirection: 'asc',
            });
        }
    }

    renderVehicleRow(vehicleData) {
        const vehicle = _.last(vehicleData);
        const bnsoNumber = _.get(vehicle.current_bnso, 'bnso_number');

        return (
            <tr key={vehicle.uuid} onClick={this.toggleSelected.bind(this, vehicle.uuid)}
                className={this.isSelected(vehicle.uuid) ? 'selected' : ''}>
                <td>{_.get(_.find(this.state.vehicle_types, {value: vehicle.vehicle_type_uuid}), 'label')}</td>
                <td>{vehicle.state_number}</td>
                <td>{_.get(_.find(this.state.vehicle_models, {value: vehicle.vehicle_model_uuid}), 'label')}</td>
                <td>{_.get(vehicle.current_bnso, 'bnso_code')}</td>
                <td>{_.get(_.find(this.state.bnso_types, {value: _.get(vehicle.current_bnso, 'bnso_type')}), 'label')}</td>
                <td>{(this.props.params.uuid === vehicle.unit_uuid) ? 'Собственность' : 'Аренда'}</td>
                <td>{this.state.units[vehicle.unit_uuid]}</td>
                <td className="align-center">{bnsoNumber ? (
                    <State positive={this.state.telematics[bnsoNumber] || false}/>
                ) : null}</td>
            </tr>
        );
    }

    isSelected(uuid) {
        return _.indexOf(this.state.confirm_request.vehicles || [], uuid) !== -1;
    }

    async selectAll() {
        let confirm_request = this.state.confirm_request;
        if (!this.state.selectedAll) {
            confirm_request.vehicles = _.map(this.state.vehicles, vehicleData => _.last(vehicleData).uuid);
        } else {
            confirm_request.vehicles = [];
        }
        await this.setState({
            confirm_request,
            selectedAll: !this.state.selectedAll,
        });
    }

    toggleSelected(uuid) {
        let confirm_request = this.state.confirm_request;
        confirm_request.vehicles = confirm_request.vehicles || [];
        const index = _.indexOf(confirm_request.vehicles, uuid);
        if (index === -1) {
            confirm_request.vehicles.push(uuid);
        } else {
            confirm_request.vehicles.splice(index, 1);
        }

        this.setState({
            confirm_request,
        });
    }

    close() {
        this.props.router.push(`/profile/unit?confirm_request=${this.props.params.uuid2}`);
    }
}