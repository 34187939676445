import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import {connect} from "react-redux";
import moment from 'moment';
import formats from 'dictionaries/formats';
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Loader from 'components/ui/loader';
import ViolationTable from '../ViolationTable';
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getUser} from "store/reducers/staffing/staffing_editor";
import {getOrders} from "store/reducers/kiutr/orders/orders";
import Block from "components/ui/form/block";

@propTypes({
    data: PropTypes.object
})

@defaultProps({
    data: {}
})

@connect((state) => ({}), {getDictionaryList, getVehicleList, getUser, getOrders}, null, {withRef: true})

export default class ViolationEnvironmentalClassForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableData: [],
            isLoading: true
        };

        this.rowHeight = 40;

        this.metadata = [
            {field: 'turn', name: 'Выход'},
            {field: 'vehicleNumber', name: 'ТС'},
            {field: 'vehicleModel', name: 'Модель'},
            {field: 'capacityType', name: 'Тип вмест.'},
            {field: 'environmentalClassPlan', name: 'Эко класс (План)'},
            {field: 'environmentalClassFact', name: 'Эко класс (Факт)'},
            {field: 'equipment', name: 'Комплектация', className: 'align-center'},
            {field: 'driver', name: 'Водитель'}
        ];

        this.violationsConfig = {};
    }

    componentWillMount() {
        this.loadData();
    }

    async getDriverUUid() {
        const meta = {
            filters: {
                withDate: moment(this.props.data.date, formats.DATE).format(formats.DATE_API)
            }
        };
        const response = await this.props.getOrders(meta);
        if (response.isOk) {
            const orders = response.payload.items;

            for (let i = 0; i < this.props.data.items.length; i++) {
                const item = this.props.data.items[i];

                const orderItem = _.find(orders, {'vehicle_uuid': item.data.vehicle_uuid, 'turn': item.data.turn});

                if (orderItem) {
                    return _.get(_.first(orderItem.shifts), 'driver_uuid');
                }
            }
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadDriver(uuid) {
        if (!uuid) {
            return {};
        }

        const response = await this.props.getUser(uuid);
        if (response.isOk) {
            return response.payload;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadVehicles() {
        const data = {};

        const uuids = this.props.data.items.map((item) => item.data['vehicle_uuid']);

        const response = await this.props.getVehicleList({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            response.payload.items.forEach((item) => {
                data[item.uuid] = item;
            });

            return data;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadCapacityTypes() {
        const data = {};

        const response = await this.props.getDictionaryList('vehicle_capacity_types');

        if (response.isOk) {
            const capacityTypes = response.payload.documents;

            capacityTypes.forEach((type) => {
                data[type.uuid] = type['short_name'];
            });

        } else {
            response.showErrors();
        }

        return data;
    }


    async loadEnvironmentalClasses() {
        const data = {};

        const response = await this.props.getDictionaryList('vehicle_environmental_classes');

        if (response.isOk) {
            const envClasses = response.payload.documents;

            envClasses.forEach((envClass) => {
                data[envClass.uuid] = envClass.name;
            });
        } else {
            response.showErrors();
        }

        return data;
    }

    async loadVehicleModels(vehicles) {
        let data = {};

        const response = await this.props.getDictionaryList('vehicle_models');
        if (response.isOk) {
            const vehicleModels = response.payload.documents;

            const vehiclesList = Object.keys(vehicles).map((key) => vehicles[key]);

            for (let i = 0; i < vehiclesList.length; i++) {
                const vehicle = vehiclesList[i];
                const modelUuid = vehicle['vehicle_model_uuid'];

                const modelItem = _.find(vehicleModels, {'uuid': modelUuid});

                if (modelItem) {
                    data[vehicle.uuid] = modelItem.name;
                }
            }

            return data;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    getDriverFullName(info) {
        let fullName = _.get(info, 'surname');
        const name = _.get(info, 'name');
        const secondName = _.get(info, 'second_name');

        if (name) {
            fullName += ' ' + name.charAt(0).toUpperCase() + '.';
        }

        if (secondName) {
            fullName += ' ' + secondName.charAt(0).toUpperCase() + '.';
        }

        return fullName;
    }

    async loadData() {
        this.setState({isLoading: true});

        const vehicles = await this.loadVehicles();

        if (vehicles) {
            const vehicleModels = await this.loadVehicleModels(vehicles);

            const capacityTypes = await this.loadCapacityTypes();

            const environmentalClasses = await this.loadEnvironmentalClasses();

            //const driverUuid = await this.getDriverUUid();
            const driverUuid = null;

            const driver = (driverUuid) ? await this.loadDriver(driverUuid) : null;

            const data = [];

            this.props.data.items.forEach((item, index) => {
                this.violationsConfig[index] = {};

                const vehicleUuid = item.data.vehicle_uuid;
                const vehicle = vehicles[vehicleUuid];

                if (item.data.planned !== item.data.fact) {
                    this.violationsConfig[index] = {environmentalClassFact: true};
                }

                const equipment = {
                    'low-floor': vehicle.is_low_floor_level,
                    'cashless': vehicle.is_cashless_payment,
                    'audio-video': vehicle.is_audio_video_fixation,
                    'monitoring': vehicle.is_passenger_monitoring_system,
                };

                data.push({
                    turn: item.data.turn,
                    vehicleNumber:
                        <span><span>{vehicle['state_number']}</span><br/><span>{vehicle['garage_number']}</span></span>,
                    vehicleModel: vehicleModels[vehicleUuid],
                    capacityType: capacityTypes[vehicle['capacity_type_uuid']],
                    environmentalClassPlan: environmentalClasses[item.data.planned],
                    environmentalClassFact: environmentalClasses[item.data.fact],
                    equipment: _.keys(_.pickBy(equipment, item => !!item)).map((icon) => {
                        return (
                            <span key={icon} className={`equipment-icon equipment-icon_${icon}`}/>
                        );
                    }),
                    driver: driver ? this.getDriverFullName(driver.info) : '-',
                });
            });

            this.setState({tableData: data, isLoading: false});
            return;
        }

        this.setState({isLoading: false});
    }

    render() {
        const {date, contractUnitName, routeNumber, routeTitle} = this.props.data;

        return (
            <div className="b-modal__block">
                <Block>
                    <div className="violation-info">
                        <span className="bold">Дата: </span>
                        {date}
                    </div>
                    <div className="violation-info">
                        <span className="bold">Нарушений: </span>
                        {this.props.data.items.length}
                    </div>
                </Block>
                <Block size="xl">
                    <div className="violation-info">
                        <span className="bold">Маршрут: </span>
                        №{routeNumber} ({routeTitle})
                    </div>
                    <div className="violation-info">
                        <span className="bold">Транспортное предприятие: </span>
                        {contractUnitName}
                    </div>
                </Block>

                <div className="violation_table__content">
                    {this.state.isLoading ? <Loader color="red" size={32}/> :
                        <ViolationTable data={this.state.tableData} metadata={this.metadata}
                                        violationsConfig={this.violationsConfig} rowHeight={50}/>}
                </div>
            </div>
        );
    }
}