import {Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const searchFias = (data) => async (dispatch) => {
    try {
        const response = await api.fias.searchFias(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getFias = (aoguid) => async (dispatch) => {
    try {
        const response = await api.fias.getFias(aoguid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};