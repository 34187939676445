import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import Datepicker from "components/ui/form/datepicker";
import moment from 'moment';
import formats from 'dictionaries/formats';
import { getSolveProblemWidgetData } from 'store/reducers/reports/reports';

import "./style.less";

const options = {
	responsive: true,
    maintainAspectRatio: false,
	onResize: function() {
		$(".react-grid-item").each(function() {
			if ($( this ).hasClass("fullscreen")) {
				$(".histogram-widget").css("height", "95%");
			} else {
				$(".histogram-widget").css("height", "84%");
			}
		})
	},
	scales: {
		xAxes: [{
			barThickness: 73
		}],
		yAxes: [
			{
				ticks: {
					beginAtZero: true
				}
			}
		]
	}
};

@connect(
	(state) => ({}),
	(dispatch) => ({
		onGetSolveProblemWidgetData: (payload, meta) => dispatch(getSolveProblemWidgetData(payload, meta)),
	})
)
class HistogramChart extends Component {

	constructor(props) {
        super(props);

        this.state = {
            data: {new_count: 0, closed_count: 0, in_progress_count: 0}
        };

		this.dataInterval = null;
    }

	async loadData() {
		await this.setState({
			data: {new_count: 0, closed_count: 0, in_progress_count: 0}
		})
		const formattingDate = moment(this.props.date).format("YYYY-MM-DD");
		const meta = {
			filters: {
				dateTo: formattingDate
			}
		}
		const response = await this.props.onGetSolveProblemWidgetData({}, meta);
		if (response.isOk) {
			await this.setState({
				data: response.payload
			})
		} else {
			response.showError();
		}
	}

	renderData() {
		const data = {
			labels: [moment(this.props.date).format("DD.MM")],
			datasets: [
				{
					label: 'Количество поступивших',
					data: [this.state.data.in_progress_count],
					backgroundColor: 'rgb(255, 99, 132)'
				},
				{
					label: 'Закрытых',
					data: [this.state.data.closed_count],
					backgroundColor: 'rgb(54, 162, 235)'
				},
				{
					label: 'В работе',
					data: [this.state.data.in_progress_count],
					backgroundColor: 'rgb(75, 192, 192)'
				}
			]
		};
		return data
	}

	runIntervalLoadData() {
		this.loadData();
		const minutesToReloadData = window.RNIS_SETTINGS.histogramWidgetInterval ? window.RNIS_SETTINGS.histogramWidgetInterval : 600000;
		this.dataInterval = setInterval(() => {
			this.loadData();
		}, minutesToReloadData);
	}

	clearIntervalLoadData() {
		if (this.dataInterval) clearInterval(this.dataInterval);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.date !== this.props.date) {
			this.clearIntervalLoadData();
			this.runIntervalLoadData();
		}
	}

	componentWillMount() {
		this.runIntervalLoadData();
	}

	componentWillUnmount() {
		this.clearIntervalLoadData();
	}

	render() {
		return (<div className="histogram-widget">
			<Bar data={this.renderData()} options={options} />
		</div>)
	}
}

export default class Histogram extends Component {

	constructor(props) {
        super(props);

        this.state = { date: moment().format("YYYY-MM-DD") };
    }

	dateChange = async ({target: {value}}) => {
        await this.setState({date: value});
    };

	render() {
		return (
			<div className="histogram-wrapper">
				<div className="date-wrapper">
				<div className="date-item widget-datepicker">
					<span className="date-label">Дата</span>
					<Datepicker style="light" value={this.state.date} onChange={this.dateChange} />
				</div>
				</div>
				<HistogramChart date={this.state.date} />
			</div>
		)
	}
}
