import React from 'react';
import ReactDOMServer from 'react-dom/server';
import MapVehicleIcon from "components/ui/map-vehicle-icon";
import L from 'leaflet';

export default class RoutingIcon {

    static getIcon(type) {
        let text = '';
        switch (type) {
            case 'from':
                text = 'A';
                break;
            case 'to':
                text = 'B';
                break;
        }

        const html = ReactDOMServer.renderToStaticMarkup(<div>
            {text}
        </div>);

        return new L.DivIcon({
            html: html,
            className: `routing-marker routing-marker-${(type === 'transit') ? 'transit' : type}`,
            iconSize: new L.Point(20, 20),
            iconAnchor: new L.Point(10, 10),
        });
    }
}