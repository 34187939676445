import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const calcDuplicationMatrix = (data) => async (dispatch) => {
    try {
        const response = await api.geo.calcDuplicationMatrix(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createDuplicationMatrix = (data) => async (dispatch) => {
    try {
        const response = await api.geo.createDuplicationMatrix(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const recalcDuplicationMatrix = (data) => async (dispatch) => {
    try {
        const response = await api.geo.recalcDuplicationMatrix(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getDuplicationMatrix = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getDuplicationMatrix(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getDuplicationMatrices = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.geo.getDuplicationMatrices(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteDuplicationMatrix = (data) => async (dispatch) => {
    try {
        const response = await api.geo.deleteDuplicationMatrix(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getDuplicationMatrixRoutes = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.geo.getDuplicationMatrixRoutes(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getDuplicationMatrixRouteParts = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.geo.getDuplicationMatrixRouteParts(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
