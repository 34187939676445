import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {deleteFaq, getFaq} from "store/reducers/portal/faq";
import {deletePage, getPages} from "store/reducers/portal/pages";
import IconButton from "components/ui/icon-button";
import {getMobilePages} from "store/reducers/mobile/mobile_pages";

@connect(state => ({}), {getMobilePages})

export default class MobilePagesComponent extends BaseTableWithEditorComponent {

    title = 'МП → Страницы';
    baseUrl = '/mobile/mobile_pages';

    pages = {
        guide: 'Руководство пользователя',
        agreement: 'Пользовательское соглашение',
        contacts: 'Полезные контакты',
        forgotten: 'Забытые вещи',
    };

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Страница')
                .fromField('key')
                .withDrawer(item => this.pages[item.key]),

        ]);
    }

    async loadData(meta) {
        return await this.props.getMobilePages(meta);
    }

    renderHeaderActions() {
        return [];
    }

    getCreateButton() {
        return null;
    }
}