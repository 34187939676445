import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './radio.less';

@propTypes({
    checked: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    value: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
})

@defaultProps({
    checked: false,
    label: '',
    value: '',
    field: '',
    onChange: () => {
    },
})

export default class Radio extends Component {
    render() {
        return (
            <div className="b-radio">
                <input type="radio" id={this.props.value} className="b-radio__inner" name={this.props.field}
                       checked={this.props.checked} onChange={this.props.onChange} value={this.props.value}/>
                <label htmlFor={this.props.value} className="b-radio__label">{this.props.label}</label>
            </div>
        );
    }
}