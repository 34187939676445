import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import Dropzone from 'react-dropzone'
import * as alerts from "helpers/alerts";
import Button from "components/ui/button";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import { itImage } from "helpers/files";

import './drag-and-drop-modal.less';


@propTypes({
  title: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSize: PropTypes.number,
  files: PropTypes.array,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
})

@defaultProps({
  title: 'Загрузка фотографии',
  accept: 'image/*, application/pdf, video/*',
  multiple: false,
  disabled: false,
  maxSize: 50*1024*1024,
  files: [],
  onClose: () => {},
  onSave: () => {},
  onDelete: () => {},
})

export default class DragAndDropModal extends Component {
  renderHeaderButtons() {
    return (
      <div className="drag-drop-modal__header_btn">
        <Button
          size="md"
          color="red"
          text="Сохранить"
          onClick={this.props.onSave}
          className="drag-drop-modal__header_btn-save"
        />
        <span className="drag-drop-modal__header_btn_separator"></span>
        <ModalTopMenuButton
          key="close"
          className="_close"
          tooltip="Отменить"
          onClick={this.props.onClose}
        />
      </div>
    )
  }

  onError(files) {
    files.forEach((file) => {
      const accept = this.props.accept.split(', ').map((format) => format.replace('/*', ''))
      let typeError = true

      for(const type in accept) {
        if (file.type.indexOf(type) !== -1) {
          typeError = false;
          break;
        }
      }

      if (typeError) {
        alerts.error(`Недопустимый формат файла ${file.name}`);
      }
      if (file.size > this.props.maxSize) {
        alerts.error(`Недопустимый размер файла ${file.name}`);
      }
    })
  }

  render() {
    return (
      <span>
        <div className="drag-drop-modal_fade" onClick={this.props.onClose}>
        </div>
        <div className="drag-drop-modal__root">
          <div className="drag-drop-modal__header">
            <div className="drag-drop-modal__header_title">{this.props.title}</div>
            {this.renderHeaderButtons()}
          </div>
          <Dropzone
            onDrop={this.props.onUpload}
            accept={this.props.accept}
            multiple={this.props.multiple}
            disabled={this.props.disabled}
            maxSize={this.props.maxSize}
            onDropRejected={::this.onError}
          >
            {({getRootProps, getInputProps, isDragActive }) => (
                <div className={isDragActive ? "drag-drop-modal__zone active" : "drag-drop-modal__zone"} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="drag-drop-modal__texts">
                    <div className="drag-drop-modal__texts_title">
                      {isDragActive ? "Отпустите здесь" : "Перетащите фотографию сюда"}
                    </div>
                    {!isDragActive ? (
                      <div className="drag-drop-modal__texts_or">или</div>
                    ) : null }
                    {!isDragActive ? (
                      <Button
                        size="lg"
                        color="white"
                        shadow="gray"
                        text="Выберите файл на компьютере"
                      />
                    ) : null}
                  </div>
                </div>
            )}
          </Dropzone>
          <div className="drag-drop-modal__files">
            {this.props.files.map((file, index) => (
              <div key={file.original_filename} className="drag-drop-modal__files_item">
                <div role="button" tabIndex="0" onClick={() => this.props.onDelete(index)} className="drag-drop-modal__files_item-delete">x</div>
                {itImage(file.url)
                  ? <img src={file.url} className="drag-drop-modal__files_item-preview" />
                  : <a className="drag-drop-modal__files_item-name" href={file.url} target="_blank">{index}.{file.url.split('.').pop()}</a>
                }
              </div>
            ))}
          </div>
        </div>
      </span>
    );
  }
}