import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import TableContainer from "components/ui/Table/Container/TableContainer";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import PageModal from 'components/ui/page-modal';
import GlobalLoaderComponent from "components/ui/global-loader";
import moment from "moment";
import formats from "dictionaries/formats";
import {getGatnViolations} from "store/reducers/gatn/gatn_violations";
import {getVehicle} from "store/reducers/vehicles/vehicles";
import Button from "components/ui/button";
import GatnViolationsMapComponent from "components/modules/gatn/gatn_violations/map";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ContextTooltip from "components/ui/context-tooltip";
import {getSpeedViolations} from "store/reducers/notifications/speed-violations";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
})

@connect(state => ({}), {getSpeedViolations})

export default class Editor extends BaseEditor {

    title = '';

    async loadData(uuid) {
        return await this.props.getSpeedViolations({
            order: {
                column: 'timestamp',
                direction: 'asc',
            },
            filters: {
                withPeriod: [
                    moment(this.props.date).startOf('day').format(formats.DATETIME_API),
                    moment(this.props.date).endOf('day').format(formats.DATETIME_API),
                ],
                withVehicles: [this.props.uuid],
            },
        });
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                vehicleUuid={this.props.uuid}
                date={this.props.date}
                type={this.props.type}
            />
        );
    }

    render() {
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        const title = 'Нарушения скоростного режима';
        let form;
        let buttons;

        if (this.state.item) {
            form = this.getForm(this.state.item, () => {
            });

            buttons = (
                <ModalTopMenuButtons>
                    <ModalTopMenuList className="top-menu_modal_edit">

                        <ContextTooltip key="base-editor.print" code="base-editor.print" default="Печать">
                            <ModalTopMenuListItem
                                className="b-icon-link_icon_print"
                                onClick={::this.print}
                            />
                        </ContextTooltip>

                        <ModalTopMenuListSeparator key="separator"/>
                    </ModalTopMenuList>

                    <ModalTopMenuButton
                        key="close"
                        className="_close"
                        tooltip="Отменить"
                        onClick={::this.props.onClose}
                    />
                </ModalTopMenuButtons>
            );
        }

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}
                className={`profile-modal b-modal-${this.props.mode}`}
            >
                {loader}
                {form}
            </PageModal>
        );
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    vehicleUuid: PropTypes.string,
    date: PropTypes.string,
})

@connect((state) => ({}), {getVehicle, getDictionaryList}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        payload: {},
        vehicle: {},
        vehicle_marks: [],
        vehicle_models: [],
        gatn_responsives: [],
        showMap: null,
    };

    getData() {
        return this.state.payload;
    }

    async componentDidMount() {
        await this.setState({
            payload: this.props.data,
        });
        this.loadVehicle();
        this.loadDictionaries([
            'vehicle_marks',
            'vehicle_models',
        ]);
    }

    async loadVehicle() {
        const response = await this.props.getVehicle(this.props.vehicleUuid);
        if (response.isOk) {
            this.setState({vehicle: response.payload});
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.payload, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true}
                                   title="Нарушения скоростного режима">
                        <Block title="Дата">
                            {moment(this.props.date).format(formats.DATE)}
                        </Block>
                        <Block title="Гос. номер">
                            {_.get(this.state.vehicle, 'state_number')}
                        </Block>
                        <Block title="Марка ТС">
                            {_.get(_.find(this.state.vehicle_marks, {value: _.get(this.state.vehicle, 'vehicle_mark_uuid')}), 'label')}
                        </Block>
                        <Block title="Модель ТС">
                            {_.get(_.find(this.state.vehicle_models, {value: _.get(this.state.vehicle, 'vehicle_model_uuid')}), 'label')}
                        </Block>
                    </AccordionItem>
                    <AccordionItem opened={true}
                                   title={`Нарушений: ${this.state.payload.items && this.state.payload.items.length}`}>
                        <Block size="xl">
                            <div className="Table">
                                <TableContainer>
                                    <table className="b-table">
                                        <thead>
                                        <tr>
                                            <th width="50px">№</th>
                                            <th>Время</th>
                                            <th>Максимальная скорость</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.payload.items && this.state.payload.items.map(::this.renderRow)}
                                        </tbody>
                                    </table>
                                </TableContainer>
                            </div>
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    renderRow(violation, index) {
        return (
            <tr key={index}>
                <td className="align-center">{index + 1}</td>
                <td className="align-center">{moment(violation.timestamp).format(formats.TIME_FULL)}</td>
                <td className="align-center">{violation.amount}</td>
            </tr>
        );
    }
}