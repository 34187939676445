import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {Loader} from 'react-loaders'
import classNames from 'classnames';

import 'loaders.css/loaders.css';

import './loader.less';

import Colors from '../../helpers/colors';

@propTypes({
  style: PropTypes.object,
  size: PropTypes.oneOf([22, 32, 64, 128]),
  color: PropTypes.oneOf(Colors.AllNames),
  align: PropTypes.oneOf(['none', 'center', 'top', 'bottom'])
})

@defaultProps({
  style: {},
  size: 32,
  color: 'white',
  align: 'center'
})

export default class LoaderComponent extends Component {

  render() {
    const className = classNames('tail-spin', `tail-spin-${this.props.size}`, `tail-spin-${this.props.color}`, `tail-spin-${this.props.align}`);


    return (
      <div className="Loader" style={this.props.style}>
        <div className={className} />
      </div>
    );
  }

}