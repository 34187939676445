import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import PageModal from 'components/ui/page-modal';

import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import * as alerts from "helpers/alerts";

import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import currentUser from 'helpers/current-user';
import {getUnit, createUnit, updateUnit, deleteUnit, getUnits} from "store/reducers/organizational_units/units";
import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {getPositions} from "store/reducers/organizational_units/positions";
import ContextTooltip from "components/ui/context-tooltip";
import ColorPicker from 'react-color-picker';
import 'react-color-picker/index.css';
import {
    createTerritorialEntity, deleteTerritorialEntity, getTerritorialEntities, getTerritorialEntity,
    updateTerritorialEntity
} from "store/reducers/geo/territorial-entities";
import Button from "components/ui/button";

import './TerritorialEntitiesEditor.less';
import TerritorialEntitiesMap from "components/modules/geo/TerritorialEntities/Map/TerritorialEntitiesMap";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
})

@defaultProps({
    onEditClick: () => {
    },
    onDeleteClick: () => {
    },
})

@connect(state => ({}), {
    getTerritorialEntity,
    createTerritorialEntity,
    updateTerritorialEntity,
    deleteTerritorialEntity
})

export default class TerritorialEntitiesEditor extends Component {

    state = {
        uuid: null,
        territorial_entity: null,
        isLoading: this.props.mode === 'edit',
        saving: false,
        errors: {},
        mapCallback: null,
    };

    componentWillReceiveProps(props) {
        if (this.state.uuid !== props.uuid) {
            this.loadItem(props.uuid);
        }
    }

    componentWillMount() {
        this.componentWillReceiveProps(this.props);
    }

    showMap(mapCallback) {
        this.setState({
            mapCallback
        });
    }

    closeMap() {
        this.setState({
            mapCallback: null,
        });
    }

    render() {
        let title = 'Добавление ТО';

        let form;
        let buttons;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {
            title = 'Редактирование ТО';

            if (this.state.territorial_entity) {
                form = <EditorForm
                    {...this.props}
                    ref="form"
                    mode={this.props.mode}
                    onSubmit={::this.edit}
                    onDelete={::this.deleteItem}
                    onClose={::this.props.onClose}
                    data={this.state.territorial_entity}
                    errors={this.state.errors}
                    clearErrors={::this.clearErrors}
                    showMap={::this.showMap}
                    closeMap={::this.closeMap}
                />;

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            {currentUser.can('com.rnis.system.permission.audit', 'read') ? (
                                <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                                default="Журнал аудита">
                                    <ModalTopMenuListItem
                                        className="b-icon-link_params b-icon-link_icon_history"
                                        href={`/system/audit/${this.props.uuid}?class=App\\Model\\TerritorialEntity`}
                                    />
                                </ContextTooltip>
                            ) : null}

                            <ModalTopMenuListSeparator key="separator"/>
                        </ModalTopMenuList>

                        <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                            <ModalTopMenuButton
                                className="_save"
                                title="Сохранить"
                                onClick={::this.onEdit}
                            />
                        </ContextTooltip>

                        <ModalTopMenuButtonsSeparator/>

                        <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                            <ModalTopMenuButton
                                className="_close"
                                onClick={::this.props.onClose}
                            />
                        </ContextTooltip>
                    </ModalTopMenuButtons>
                );
            }
        } else {
            form = <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={::this.create}
                onClose={::this.props.onClose}
                data={{}}
                errors={this.state.errors}
                clearErrors={::this.clearErrors}
                showMap={::this.showMap}
                closeMap={::this.closeMap}
            />;

            buttons = (
                <ModalTopMenuButtons>
                    <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                        <ModalTopMenuButton
                            className="_save"
                            title="Сохранить"
                            onClick={::this.onCreate}
                        />
                    </ContextTooltip>

                    <ModalTopMenuButtonsSeparator/>

                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={::this.props.onClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );
        }

        return (
            <div>
                <PageModal
                    header={{title, buttons}}
                    onClose={this.props.onClose}
                    className={`profile-modal territorial-entity-modal b-modal-${this.props.mode}`}
                >
                    {loader}
                    {form}
                </PageModal>
                {this.renderMap()}
            </div>
        );
    }

    renderMap() {
        if (!this.state.mapCallback) {
            return null;
        }

        return (
            <TerritorialEntitiesMap
                callback={this.state.mapCallback}
                closeMap={::this.closeMap}
            />
        );
    }

    async loadItem(uuid) {
        this.setState({
            uuid,
            territorial_entity: null,
            isLoading: true
        });
        if (uuid) {
            const response = await this.props.getTerritorialEntity(uuid);
            if (response.isOk) {
                this.setState({
                    territorial_entity: response.payload,
                    isLoading: false,
                });
            } else {
                response.showErrors();
            }
        }
    }

    clearErrors() {
        this.setState({errors: {}});
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    onCreate() {
        if (!this.refs.form) return;
        const state = this.refs.form.getWrappedInstance().getData();
        this.create(state);
    }

    onEdit() {
        if (!this.refs.form) return;
        const state = this.refs.form.getWrappedInstance().getData();
        this.edit(state);
    }

    async create(data) {
        this.clearErrors();
        this.startSave();

        const response = await this.props.createTerritorialEntity(this.composeData(data));
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();

        const response = await this.props.updateTerritorialEntity(this.composeData(data));
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async deleteItem(uuid) {
        this.startSave();
        const response = await this.props.deleteTerritorialEntity({
            uuid,
        });
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    composeData(data) {
        let item = _.clone(data);

        item.component = this.props.params.component;

        return item;
    }

}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
})

@connect((state) => ({}), {getTerritorialEntities, getTerritorialEntity}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        territorial_entity: {},
        territorial_entities: [],
        mapActive: false,
    };

    getData() {
        return this.state.territorial_entity;
    }

    async setValue(field, value) {
        this.props.clearErrors();

        return super.setValue(field, value);
    }

    async componentDidMount() {
        await this.setState({
            territorial_entity: this.props.data,
        });
    }

    async loadTerritorianEntities(input, callback) {
        if (!input) {
            input = this.get('parent_uuid');
        }
        const response = await this.props.getTerritorialEntities({
            pagination: {
                page: 1,
                limit: 20,
            },
            search: input,
            filters: {
                withComponent: this.props.params.component,
            },
        });
        if (response.isOk) {
            callback(null, {
                options: _.sortBy(_.filter(_.map(response.payload.items, (unit) => ({
                    value: unit.uuid,
                    label: unit.name,
                })), (item) => item.value !== this.state.unit.uuid), 'label'),
                complete: false,
            });
            //this.setState({units});
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.territorial_entity, path, defaultValue);
    }

    render() {
        return (
            <div className="StaffingEditorForm">
                <Accordion>
                    <AccordionItem title="Основные сведения" opened={true} single={true}>
                        <Block size="xl" title="Наименование">
                            {this.textInput('territorial_entity.name')}
                        </Block>
                        <Block size="xl" title="Краткое наименование">
                            {this.textInput('territorial_entity.short_name')}
                        </Block>
                        <Block size="xl" title="Маска ОКТМО">
                            {this.textInput('territorial_entity.oktmo_mask')}
                        </Block>
                        <Block title="Вышестоящее образование">
                            {this.selectAsync('territorial_entity.parent_uuid', ::this.loadTerritorianEntities)}
                        </Block>
                        <Block title="Территория">
                            <Button size="md" width="auto" color="red" shadow="red" text="Редактировать на карте"
                                    onClick={this.props.showMap.bind(this, ::this.mapCallback)}/>
                            {this.get('geometry') ? (
                                <Button size="md" width="auto" color="red" shadow="red" text="Очистить"
                                        onClick={::this.clearGeometry}/>
                            ) : null}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    mapCallback(geometry = null, closeMap = false) {
        if (geometry) {
            this.setValue('territorial_entity.geometry', geometry);
            closeMap && this.props.closeMap();
        } else {
            return this.get('geometry');
        }
    }

    clearGeometry() {
        this.setValue('territorial_entity.geometry', null);
        this.props.closeMap();
    }
}