import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import L from 'leaflet';
import 'leaflet-polylinedecorator';

import ReactDOMServer from 'react-dom/server';
import MapComponent from "components/ui/map";
import Page from 'components/ui/page';

import {connect} from "react-redux";
import {getBnso} from "store/reducers/vehicles/bnso";
import {getHistory, getPanics} from "store/reducers/maps";
import moment from "moment";
import formats from "dictionaries/formats";
import MapVehicleIcon from "components/ui/map-vehicle-icon";

const pathStyle = {
    color: '#FF0000',
    opacity: 0.6,
};

@connect(state => ({}), {getBnso, getHistory, getPanics})

export default class OperatorBnsoCheckMapComponent extends Component {

    state = {
        bnso: {},
    };

    pathLayer = null;
    panicLayer = null;

    componentDidMount() {
        if (!this.refs.map) return;

        const map = this.refs.map.getWrappedInstance().map;

        this.pathLayer = new L.featureGroup().addTo(map);
        this.panicLayer = new L.featureGroup().addTo(map);

        this.loadBnso(this.props.params.uuid);
    }

    async loadBnso(uuid) {
        const response = await this.props.getBnso(uuid);
        if (response.isOk) {
            await this.setState({
                bnso: response.payload,
            });
            this.loadHistory();
            this.loadPanics();
        } else {
            response.showErrors();
        }
    }

    async loadHistory() {
        const response = await this.props.getHistory([this.state.bnso.bnso_number], moment().subtract(1, 'day').format(formats.T1DATE), moment().format(formats.T1DATE));
        if (response.isOk) {
            const telematics = _.first(response.payload.telematics);
            if (telematics) {
                const path = _.map(telematics.points, (point) => ([
                    point[0],
                    point[1],
                ]));
                this.drawPath(path);
            }
        } else {
            response.showErrors();
        }
    }

    async loadPanics() {
        const response = await this.props.getPanics({
            filters: {
                lastDay: true,
                withDevices: [
                    this.state.bnso.bnso_number,
                ],
            },
        });
        if (response.isOk) {
            _.each(response.payload.items, (item) => {
                this.drawPanic(item);
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        return (
            <Page title="Диагностика БНСО карта, срабатывание тревожной кнопки за последние сутки"
                  pageId="BNSOCheckMap">
                <MapComponent ref="map"/>
            </Page>
        );
    }

    drawPath(path) {
        if (!path) return;

        const layer = new L.polyline(path, pathStyle);

        this.pathLayer.addLayer(layer);

        layer.setText('\u25B6          ', {
            repeat: true,
            offset: 5,
            attributes: {
                fill: pathStyle.color,
                'font-size': '12',
            },
        });
    }

    createPanicIcon() {
        let type = 'bus';

        const html = ReactDOMServer.renderToStaticMarkup(<MapVehicleIcon device={{type}}/>);

        let className = `marker marker_type_red marker_sm`;
        let size = 23;
        return new L.DivIcon({
            html: html,
            className: className,
            iconSize: new L.Point(size, size),
            iconAnchor: new L.Point(size / 2, size / 2),
        });
    }

    drawPanic(panic) {
        const marker = L.marker([panic.latitude, panic.longitude], {
            icon: this.createPanicIcon(),
            riseOnHover: true,
        });

        marker.bindTooltip(moment(panic.created_at).format(formats.DATETIME), {sticky: true});

        this.panicLayer.addLayer(marker);
    }
}