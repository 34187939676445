import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {
    getTemplateDocumentNextVersion, getTemplateDocuments, testTemplateDocument,
    updateTemplateDocument
} from "store/reducers/portal/template_documents";
import {createTemplateDocument, getTemplateDocument} from "store/reducers/portal/template_documents";
import document_types from "dictionaries/document_types";
import FileReaderInput from 'react-file-reader-input';
import {api} from "helpers/api";
import {State} from "components/ui/state";
import moment from "moment";
import formats from "dictionaries/formats";
import * as alerts from "helpers/alerts";
import download from 'downloadjs';

const systems = {
    kiutr: 'Пассажирские перевозки',
    road: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text,
    utility: 'Жилищно-коммунальное хозяйство',
    communal: 'Коммунальные работы',
    children: 'Перевозка детей',
    garbage: 'Вывоз мусора',
};

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getTemplateDocument, createTemplateDocument, updateTemplateDocument, getTemplateDocuments})

export default class Editor extends BaseEditor {

    title = 'шаблона документа';
    modelClass = 'App\\Model\\TemplateDocument';

    async loadData(uuid) {
        return await this.props.getTemplateDocument(uuid);
    }

    async createItem(data) {
        if (data.is_active) {
            let filters = {
                onlyActive: true,
                withDocuments: [
                    data.document,
                ],
            };
            if (data.domain) {
                filters.withDomains = [data.domain];
            }
            const response = await this.props.getTemplateDocuments({
                filters,
            });

            if (response.isOk && response.payload.items.length > 0) {
                const isReplace = await new Promise((resolve, reject) => {
                    alerts.prompt('Заменить действующий шаблон?', null, () => {
                        resolve(true);
                    }, 'Заменить', () => {
                        resolve(false);
                    });
                });
                if (!isReplace) {
                    return;
                }
            }
        }
        return await this.props.createTemplateDocument(data);
    }

    async updateItem(data) {
        return await this.props.updateTemplateDocument(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getTemplateDocumentNextVersion, testTemplateDocument}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {};

    getData() {
        return this.state.template_document;
    }

    async componentDidMount() {
        await this.setState({
            template_document: this.props.data,
        });
    }

    get(path, defaultValue = null) {
        return _.get(this.state.template_document, path, defaultValue);
    }

    async setValue(field, value, ignoreRules = false) {
        if (value && (field === 'template_document.is_active') && !this.get('document_agreement_url')) {
            alerts.error('Необходимо загрузить файл');
            value = false;
        }
        await super.setValue(field, value);
        if (ignoreRules) {
            return;
        }

        if (field === 'template_document.document') {
            this.setValue('template_document.domain', null, true);
        }

        if ((this.props.mode === 'add') && ((field === 'template_document.document') || (field === 'template_document.domain'))) {
            this.getVersion();
        }
    }

    async getVersion() {
        const response = await this.props.getTemplateDocumentNextVersion({
            document: this.get('document'),
            domain: this.get('domain'),
        });

        if (response.isOk) {
            this.setValue('template_document.version', response.payload.version);
        } else {
            response.showErrors();
        }
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} single={true}>
                        <Block size="xl" title="Документ">
                            {(this.canEdit()) ? (
                                this.select('template_document.document', _.map(document_types, (label, value) => ({
                                    value,
                                    label,
                                })))
                            ) : document_types[this.get('document')]}
                        </Block>
                        <Block size="xl" title={this.canEdit() ? null : 'Активный статус'}>
                            {(this.canEdit()) ? (
                                this.checkbox('template_document.is_active', 'Активный статус')
                            ) : (
                                <State positive={this.get('is_active')}/>
                            )}
                        </Block>
                        {(this.get('document') === 'agreement') ? (
                            <Block size="xl" title="Область деятельности">
                                {(this.canEdit()) ? (
                                    this.select('template_document.domain', _.map(systems, (label, value) => ({
                                        value,
                                        label,
                                    })))
                                ) : systems[this.get('domain')]}
                            </Block>
                        ) : null}
                        <Block size="xl" title="Версия">
                            {this.get('version', '-')}
                        </Block>
                        <Block title="Файл">
                            <div className="input">
                                <div className="input__style"/>
                                {this.get('document_agreement_url') ? (
                                    <span className="input__edit-file">
                                        <a className="open-file" href={this.get('document_agreement_url')}
                                           target="_blank">Открыть</a>
                                        <a className="open-file" href="javascript:void(0)" onClick={::this.testDocument}>Пробная генерация</a>
                                        {(this.canEdit()) ? (
                                            <a className="delete-file" href="javascript:void(0)"
                                               onClick={::this.deleteFile}>Удалить</a>
                                        ) : null}
                                    </span>
                                ) : (
                                    (this.canEdit()) ? (
                                        <FileReaderInput as="binary" id="my-file-input"
                                                         onChange={::this.uploadFile}>
                                            <span className="input__load-file">Добавить файл</span>
                                        </FileReaderInput>
                                    ) : null
                                )}
                            </div>
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    async testDocument()
    {
        const response = await this.props.testTemplateDocument(this.state.template_document);

        if (response.isOk) {
            download(`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${response.payload.content}`, `Соглашение о взаимодействии.docx`);
        } else {
            response.showErrors();
        }
    }

    canEdit() {
        return (this.props.mode === 'add') || !this.props.data.is_active;
    }

    deleteFile(e) {
        e.preventDefault();

        this.setValue('template_document.document_agreement_url', null);
    }

    async uploadFile(e, results) {
        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        results.forEach(result => {
            const [e, file] = result;
            //formData.append('file', e.target.result);
            formData.append('file', file);
        });

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');

        this.onChangeInput('template_document.document_agreement_url', {target: {value}});
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }
}