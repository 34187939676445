import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import PageModal from 'components/ui/page-modal';
import debounce from 'throttle-debounce/debounce';
import GlobalLoaderComponent from "components/ui/global-loader";
import {getStopPoints} from "store/reducers/geo/stop-points";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";

import './stop_point_add_editor.less';
import {searchFias} from "store/reducers/fias/fias";
import ContextTooltip from "components/ui/context-tooltip";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
    onAdd: PropTypes.func.isRequired,
})

@connect(state => ({}), {})

export default class StreetAddEditor extends BaseEditor {

    async createItem(data) {
        this.props.onAdd(data);

        return {
            isOk: true,
        };
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    render() {
        const title = 'Добавление улицы';

        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        const form = this.getForm({}, ::this.create);

        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                    <ModalTopMenuButton
                        className="_save"
                        title="Сохранить"
                        onClick={::this.onCreate}
                    />
                </ContextTooltip>

                <ModalTopMenuButtonsSeparator/>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}
                className={`b-modal-${this.props.mode} b-modal-route route-add-points-modal`}
            >
                {loader}
                {form}
            </PageModal>
        );
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {searchFias}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    isMosreg() {
        return (/\.mosreg.ru$/.test(window.location.hostname));
    }

    isNO() {
        return (/\.rnc52.ru$/.test(window.location.hostname));
    }


    state = {
        street: {
            area: this.isMosreg() ? '29251dcf00a14e3498d45c47484a36d4' : this.isNO() ? '88cd27e26a8a44219718719a28a0a088' : '29251dcf00a14e3498d45c47484a36d4',
        },
    };

    areaLoadDebounce = debounce(500, ::this.areaLoad);
    localityLoadDebounce = debounce(500, ::this.localityLoad);
    streetLoadDebounce = debounce(800, ::this.fiasLoad);

    getData() {
        return this.state.street;
    }

    async componentDidMount() {
    }

    get(path, defaultValue = null) {
        return _.get(this.state.street, path, defaultValue);
    }

    render() {
        return (
            <div className="StreetAddEditor">

                   {/* <Block size="xl" title="Поиск">
                        {this.selectAsync('street.area', ::this.fiasLoadDebounce)}
                    </Block>*/}
                   {/* <Block title="Нас. пункт">
                        {this.selectAsync('street.locality', ::this.localityLoadDebounce)}
                    </Block>*/}
                    <Block size="xl" title="Поиск">
                        {this.selectAsync('street.street', ::this.streetLoadDebounce)}
                    </Block>

            </div>
        );
    }

    async setValue(field, value) {
        let state = this.getState();

        _.set(state, field, value);

        await this.setState(state);

        if (field === 'street.area') {
            this.setValue('street.locality', null);
        }
        if (field === 'street.locality') {
            this.setValue('street.street', null);
        }
    }

    async areaLoad(input, callback) {
        return await this.loadFias('country', null, input, callback);
    }

    async fiasLoad(input, callback) {
        return await this.loadFias('fullname', null, input, callback);
    }

    async localityLoad(input, callback) {
        return await this.loadFias('locality', this.get('area'), input, callback);
    }

    async streetLoad(input, callback) {
        return await this.loadFias('street', this.get('locality'), input, callback);
    }

    async loadFias(level, parentguid, input, callback) {

        const result = await this.props.searchFias({
            level,
            scan: input,
            parentguid,
        });

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: i.fullname,
                    value: i.aoguid,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }
}