import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import GlobalLoaderComponent from "components/ui/global-loader";
import _ from 'lodash';
import './style.less';
import {connect} from "react-redux";
import moment from "moment";
import { getTabletKiutrSummary } from "store/reducers/reports/tablet";
import Datepicker from "components/ui/form/datepicker";
import {
    Bar, ResponsiveContainer, Text, Tooltip, XAxis,
    YAxis, CartesianGrid, ComposedChart
} from "recharts";
import formats from "dictionaries/formats";
import ReactResizeDetector from 'react-resize-detector';
import Select from "components/ui/select";

import "./style.less"

@propTypes({
    barChartFillColor: PropTypes.string,
    component: PropTypes.string,
})

@defaultProps({
    barChartFillColor: '',
    component: 'kiutr',
})

@connect(state => ({}), {getTabletKiutrSummary})

export default class SpecialVehicles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isError: false,
            isLoading: false,
            from: moment().subtract(1, 'day').format(formats.DATE_API),
            to: moment().subtract(1, 'day').format(formats.DATE_API),
            data: null,
            compliance: 'best',
            uuid: window.location.pathname.split('/')[4] || null
        };

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('dateFrom') && urlParams.get('dateTo')) {
            this.state.from = urlParams.get('dateFrom')
            this.state.to = urlParams.get('dateTo')
        }
    }

    componentWillMount() {
        this.loadData();
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value});
        this.loadData();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value});
        this.loadData();
    };

    onChange(field, e) {
        const value = e.value || e.target.value;
    
        let state = this.state;
        state[field] = value;
        this.setState(state);
      }

    async loadData() {
        this.setState({isLoading: true});

        let summaryOptions = {
            date_from: moment(this.state.from).format(formats.DATE_API),
            date_to: moment(this.state.to).format(formats.DATE_API),
            component: this.props.component
        };

        const response = await this.props.getTabletKiutrSummary(summaryOptions);

        if (response.isOk) {
            const result = response.payload.units.map((unit) => {
                return {
                    name: unit.unit_name,
                    percent: unit.disabled_transport ? unit.disabled_transport.amount / unit.disabled_transport.total : 0,
                    disabled_transport: unit.disabled_transport ? unit.disabled_transport : 0,
                }
            });
             await this.setState({
                data: result
            });
            this.setState({isLoading: false});
        } else {
            response.showErrors();
        }
    }

    renderChart() {
        let data;
        if (this.state.compliance === 'best') {
            data = this.state.data.concat().sort((a, b) => {
                a.percent - b.percent
            })
        } else {
            data = this.state.data.concat().sort((a, b) => {
                b.percent - a.percent
            })
        }

        return <div className="chart"><ResponsiveContainer>
            <ComposedChart data={data}
                margin={{ bottom: 60 }} barGap='-19%' barCategoryGap='20%' isAnimationActive={false}>
                <CartesianGrid horizontalPoints={[83, 45, 8]} strokeDasharray="3 3" />
                <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" />
                <YAxis label={'%'} dataKey="percent" />
                {data && <Tooltip content={<CustomTooltip />} />}
                <Bar dataKey="percent" fill="#ddd" name="Процент" />
            </ComposedChart >
        </ResponsiveContainer></div>
    }

    renderContent() {
        return (
            <div className="full-size kiutr-widget specialVehiclesWidget">
                {/*<div className="date-wrapper">
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange} />
                    </div>
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange} />
                    </div>
                    <div className="widgets__selector">
                        <Select value={this.state.compliance}
                            onChange={this.onChange.bind(this, 'compliance')}
                            clearable={false} options={[
                                {
                                    value: 'best',
                                    label: 'Топ лучших',
                                },
                                {
                                    value: 'worst',
                                    label: 'Топ худших',
                                },
                            ]} />
                    </div>
                </div>*/}
                {this.state.data && this.renderChart()}
            </div>
        );
    }

    _onResize(width, height) {
        $( ".specialVehiclesWidget .recharts-responsive-container" ).css( "width", "99.9%").css("height", "99%");
    }

    render() {
        $( ".specialVehiclesWidget .recharts-responsive-container" ).css( "width", "99.9%").css("height", "99%");
        const loader = this.state.isLoading ? <GlobalLoaderComponent/> : null;

        return (
            <div className="kiutr-widget">
                {loader}
                {this.renderContent()}
                <ReactResizeDetector handleWidth handleHeight onResize={::this._onResize}/>
            </div>
        );
    }
}

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
        const { name, percent, disabled_transport } = payload[0].payload;

        const amount = disabled_transport !== 0 ? disabled_transport.amount : 0;
        const total = disabled_transport !== 0 ? disabled_transport.total : 0;
        return (
            <div className="custom-tooltip-compliance">
              <p>{name}</p>
              <div className="numbers">
              <p className="number"><b>{amount}</b></p>
              <span className="part"></span>
              <p className="number"><b>{total}</b></p>
              </div>
            </div>
          );
    }
    return null;
  };
