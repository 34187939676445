import React from 'react';

import PropTypes from 'prop-types';
import { propTypes } from 'react-props-decorators';
import moment from "moment";

import * as alerts from "helpers/alerts";
import TableContainer from "components/ui/Table/Container/TableContainer";
import BaseEditorFormComponent from "components/base/base-editor-form";

import "./intervalEditor.less";

@propTypes({
  runs: PropTypes.number,
  isSave: PropTypes.bool,
})

export default class EditorFormInterval extends BaseEditorFormComponent {

  constructor(props) {
    super(props);

    Object.assign(this.state, {
      startWorkTime: "00:00",
      endWorkTime: "23:59",
      intervalMinutes: {},
      intervalTimePeriod: {},
      interval5ValueFrom4end: false,
    });
  }


  convertTimeToMoment(stringTime) {
    const split = stringTime.split(":");
    return moment({
      h: split[0],
      s: split[1]
    })
  }

    logicForInitialValues() {
    const startTime = this.convertTimeToMoment(this.state.startWorkTime);
    const endTime = this.convertTimeToMoment(this.state.endWorkTime);

    const startCondition = moment({
      h: '07',
      s: '00'
    });
    const endCondition = moment({
      h: "21",
      s: "00"
    });

    if (startTime.isBefore(startCondition) && endTime.isAfter(endCondition)) {
      this.setState({
        intervalTimePeriod: {
          interval_1_end_at: "07:00",
          interval_2_end_at: "10:00",
          interval_3_end_at: "16:00",
          interval_4_end_at: "21:00",
          interval_5_end_at: "21:00"
        }
      })
    } else {
      this.setState({
        interval5ValueFrom4end: true
      })
      }
  }

  validationPrevEndPeriodisBeforeNextPrevPeriod() {
    let validationStatus = "";
    const interval_1_end_at = this.convertTimeToMoment(this.state.intervalTimePeriod.interval_1_end_at);
    const interval_2_end_at = this.convertTimeToMoment(this.state.intervalTimePeriod.interval_2_end_at);
    const interval_3_end_at = this.convertTimeToMoment(this.state.intervalTimePeriod.interval_3_end_at);
    const interval_4_end_at = this.convertTimeToMoment(this.state.intervalTimePeriod.interval_4_end_at);
    const interval_5_end_at = this.convertTimeToMoment(this.state.intervalTimePeriod.interval_5_end_at);

    const endWorkTimeCondition = this.convertTimeToMoment(this.state.endWorkTime);

    if (interval_1_end_at.isBefore(interval_2_end_at) && interval_2_end_at.isBefore(interval_3_end_at) && interval_3_end_at.isBefore(interval_4_end_at)) {
      validationStatus = true;
    } else {
      validationStatus = false;
    }

    if (validationStatus) {
      if (interval_2_end_at.isAfter(interval_1_end_at) && interval_3_end_at.isAfter(interval_2_end_at) && interval_4_end_at.isAfter(interval_3_end_at) && endWorkTimeCondition.isAfter(interval_5_end_at)) {
        validationStatus = true;
      } else {
        validationStatus = false;
      }
    }

    return validationStatus;
  }

  validateAllFieldsHasValues() {
    let errors = [];
    const arrayOfEndTimePeriods = ['interval_1_end_at', 'interval_2_end_at', 'interval_3_end_at', 'interval_4_end_at', 'interval_5_end_at'];
    for (let item of arrayOfEndTimePeriods) {
      if (!this.state.intervalTimePeriod[item]) {
        errors.push(false);
      }
    }
    if (errors.includes(false)) {
      return false;
    } else {
      return true;
    }
  }

  validateAllFieldsHasValuesMinutes() {
    let errors = [];
    const arrayOfMinutesPeriods = ['first', 'second', 'third', 'fourth', 'fifth'];
    for (let item of arrayOfMinutesPeriods) {
      if (!this.state.intervalMinutes[item]) {
        errors.push(false);
      }
    }
    if (errors.includes(false)) {
      return false;
    } else {
      return true;
    }
  }

  checkTimeFieldIsAfterEndWorkTime() {
    let error;
    const endWorkTimeCondition = this.convertTimeToMoment(this.state.endWorkTime);
    const arrayOfEndTimePeriods = ['interval_1_end_at', 'interval_2_end_at', 'interval_3_end_at', 'interval_4_end_at', 'interval_5_end_at'];

    for (let [index, item] of arrayOfEndTimePeriods.entries()) {
      const timeToCompare = this.convertTimeToMoment(this.state.intervalTimePeriod[item]);
      if (timeToCompare.isAfter(endWorkTimeCondition)) {
        error = true;
        this.setState((prevState) => ({
          intervalTimePeriod: { ...prevState.intervalTimePeriod, [item]: ""}
        }))
      }
      return error ? false : true;
    }
  }

  async componentDidMount() {
    await this.InitialTimeStartEndPeriods();
    if (this.props.mode === 'edit') {
      await this.InitialIntervals();
    }
    if (this.props.mode === 'add') {
      this.logicForInitialValues();
    }
  }

  async InitialIntervals() {
    const values = ['first', 'second', 'third', 'fourth', 'fifth'];
    let localIntervalMinutes = {};
    let localIntervalPeriods = {};
    this.props.intervalPeriods.forEach( (interval, index) => {
      localIntervalMinutes[values[index]] = interval.value
      localIntervalPeriods[`interval_${index + 1}_end_at`] = moment.parseZone(interval.end_at).format('HH:mm')
    })
    await this.setState({
      intervalMinutes: {...localIntervalMinutes},
      intervalTimePeriod: {...localIntervalPeriods}
    })
  }

  async InitialTimeStartEndPeriods() {
    if (this.props.timeTurnsPeriods.start_at !== null) {
      await this.setState({
        startWorkTime: this.props.timeTurnsPeriods.start_at,
        endWorkTime: this.props.timeTurnsPeriods.end_at
      })
    }
  }

  setValueToInterval5StartAt(value) {
    this.setState((prevState) => ({
      intervalTimePeriod: {...prevState.intervalTimePeriod, interval_5_end_at: value}
    }))
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.intervalTimePeriod.interval_4_end_at !== this.state.intervalTimePeriod.interval_4_end_at) {
      this.setValueToInterval5StartAt(this.state.intervalTimePeriod.interval_4_end_at)
    }
    if (prevProps.isSave !== this.props) {
      if (this.props.isSave) {
        if (!this.validateAllFieldsHasValues()) {
          alerts.alert('Требуется заполнить все значения для интервалов (часы)');
          this.props.onSave({status: false})
          return false;
        }

        if (!this.validateAllFieldsHasValuesMinutes()) {
          alerts.alert('Требуется заполнить все значения для интервалов (минуты)');
          this.props.onSave({status: false})
          return false;
        }

        if (!this.checkTimeFieldIsAfterEndWorkTime()) {
          alerts.alert('Поля со значением больше времени закрытия очищены!');
          this.props.onSave({status: false})
          return false;
        }


        if (!this.validationPrevEndPeriodisBeforeNextPrevPeriod()) {
          this.props.onSave({status: false})
          alerts.alert('Конец интервала прошлого периода, должен быть меньше конца следующего периода');
          return false;
        }

        this.submit()
      }
    }
  }

  submit() {
    const payload = [
      {
        start_at: this.state.startWorkTime,
        end_at: this.state.intervalTimePeriod.interval_1_end_at,
        value: this.state.intervalMinutes.first,
      },
      {
        start_at: this.state.intervalTimePeriod.interval_1_end_at,
        end_at: this.state.intervalTimePeriod.interval_2_end_at,
        value: this.state.intervalMinutes.second,
      },
      {
        start_at: this.state.intervalTimePeriod.interval_2_end_at,
        end_at: this.state.intervalTimePeriod.interval_3_end_at,
        value: this.state.intervalMinutes.third,
      },
      {
        start_at: this.state.intervalTimePeriod.interval_3_end_at,
        end_at: this.state.intervalTimePeriod.interval_4_end_at,
        value: this.state.intervalMinutes.fourth,
      },
      {
        start_at: this.state.intervalTimePeriod.interval_5_end_at,
        end_at: this.state.endWorkTime,
        value: this.state.intervalMinutes.fifth,
      }
    ]

    this.props.mode === 'add' ? this.props.onCreate({
      status: true,
      payload: payload
    }) : this.props.onUpdate({status: true, payload: payload});
  }

  getMinTime(time) {
    if (time) {
      return time
    }
    return this.state.startWorkTime;
  }

  getMaxTime(time) {
    if (time) {
      return time
    }
    return this.state.endWorkTime;
  }

  getTableIntervalHours() {
    const intervals = this.state.intervalTimePeriod;
    return <tr>
      <td width="155px" className="align-center">
        Время суток, часы
    </td>
      <td className="timeDeck">
        {this.state.startWorkTime}
      </td>
      <td className="timeDeck">
        {this.timepicker('intervalTimePeriod.interval_1_end_at', this.state.intervalTimePeriod.interval_1_end_at, this.getMinTime(), this.getMaxTime(intervals.interval_2_end_at))}
      </td>
      <td className="timeDeck">
        {this.state.intervalTimePeriod.interval_1_end_at}
      </td>
      <td className="timeDeck">
        {this.timepicker('intervalTimePeriod.interval_2_end_at', this.state.intervalTimePeriod.interval_2_end_at, this.getMinTime(intervals.interval_1_end_at), this.getMaxTime(intervals.interval_3_end_at))}
      </td>
      <td className="timeDeck">
        {this.state.intervalTimePeriod.interval_2_end_at}
      </td>
      <td className="timeDeck">
        {this.timepicker('intervalTimePeriod.interval_3_end_at', this.state.intervalTimePeriod.interval_3_end_at, this.getMinTime(intervals.interval_2_end_at), this.getMaxTime(intervals.interval_4_end_at))}
      </td>
      <td className="timeDeck">
        {this.state.intervalTimePeriod.interval_3_end_at}
      </td>
      <td className="timeDeck">
        {this.timepicker('intervalTimePeriod.interval_4_end_at', this.state.intervalTimePeriod.interval_4_end_at, this.getMinTime(intervals.interval_3_end_at), this.getMaxTime(this.state.endWorkTime))}
      </td>
      <td className="timeDeck">
        {this.state.interval5ValueFrom4end ? this.state.intervalTimePeriod.interval_4_end_at : this.state.intervalTimePeriod.interval_5_end_at}
      </td>
      <td className="timeDeck">
        {this.state.endWorkTime}
      </td>
    </tr>
  }

  getTableIntervalMinutes() {
    return <tr>
      <td className="align-center">
        Интервал, мин
    </td>
      <td className="align-center" colSpan="2">
        {this.textInput('intervalMinutes.first', {
          type: 'number',
          min: 1,
          positive: true,
        })}
      </td>
      <td className="align-center" colSpan="2">
        {this.textInput('intervalMinutes.second', {
          type: 'number',
          min: 1,
          positive: true,
        })}
      </td>
      <td className="align-center" colSpan="2">
        {this.textInput('intervalMinutes.third', {
          type: 'number',
          min: 1,
          positive: true,
        })}
      </td>
      <td className="align-center" colSpan="2">
        {this.textInput('intervalMinutes.fourth', {
          type: 'number',
          min: 1,
          positive: true,
        })}
      </td>
      <td className="align-center" colSpan="2">
        {this.textInput('intervalMinutes.fifth', {
          type: 'number',
          min: 1,
          positive: true,
        })}
      </td>
    </tr>
  }

  render() {
    return (
      <div>
        <TableContainer>
          <div className="Table">
            <table className="b-table">
              <thead>
                <tr className="b-table__header">
                  <th className="b-table__mon align-center">Начало работы</th>
                  <th className="b-table__tue align-center">Окончание работы</th>
                  <th className="b-table__wed align-center">Количество рейсов</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-center">
                    {this.state.startWorkTime}
                  </td>
                  <td className="align-center">
                    {this.state.endWorkTime}
                  </td>
                  <td className="align-center">
                    {this.props.runs}
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="b-table">
              <thead>
                <tr className="b-table__header">
                  <th colSpan="11" className="b-table__mon align-center">Интервалы движения на маршруте</th>
                </tr>
              </thead>
              <tbody>
                {this.getTableIntervalHours()}
                {this.getTableIntervalMinutes()}
              </tbody>
            </table>
          </div>
        </TableContainer>
      </div>
    )
  }
}