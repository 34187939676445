import React from 'react';
import AccordionItem from 'components/ui/accordion/accordion-item';
import Block from 'components/ui/form/block';
import InputMask from 'components/ui/form/input-mask';
import SelectFilter from '../../../../../../../ui/select-filter';
import TableContainer from 'components/ui/Table/Container/TableContainer';
import './styles.less';

export const IntervalEditor = ({
	onChangeIntervalType,
	intervalType,
	select,
	intervals,
	onAddInterval,
	onRemoveInterval,
	isIntervalTypeDefault,
	routeVariants,
	onCountDefaultIntervals,
	onDeleteIntervals,
	maskInput,
}) => (
	<div className="interval-editor">
		<AccordionItem opened={true} title="Направление движения">
			<div className="interval-editor__direction-wrapper">
				<div className="interval-editor__direction-item">
					<div className="interval-editor__title">Прямое направление</div>
					<div className="interval-editor__input-title">Вариант движения</div>
					{select('schedule.route_variant_forward', routeVariants, {
					})}
					<div className="interval-editor__input-title">Время первого рейса</div>
					{maskInput(`schedule.start_time_first_forward`, '99:99', {
						withTimeIcon: true
					})}
					<div className="interval-editor__input-title">Время последнего рейса</div>
					{maskInput(`schedule.start_time_last_forward`, '99:99', {
						withTimeIcon: true
					})}
				</div>
				<div className="interval-editor__direction-item">
					<div className="interval-editor__title">Обратное направление</div>
					<div className="interval-editor__input-title">Вариант движения</div>
					{select('schedule.route_variant_backward', routeVariants, {
					})}
					<div className="interval-editor__input-title">Время первого рейса</div>
					{maskInput(`schedule.start_time_first_backward`, '99:99', {
						withTimeIcon: true
					})}
					<div className="interval-editor__input-title">Время последнего рейса</div>
					{maskInput(`schedule.start_time_last_backward`, '99:99', {
						withTimeIcon: true
					})}
				</div>
			</div>
		</AccordionItem>
		<AccordionItem opened={true} title="Интервал движения">
			<SelectFilter
				items={[ 'По умолчанию', 'Конфигурируемый' ]}
				classNames={[ 'Shedule__editorForm__type-selector__item', 'Shedule__editorForm__type-selector__item' ]}
				className="Shedule__editorForm__type-selector"
				currentItem={intervalType}
				onChange={onChangeIntervalType}
			/>
			{intervalType === 1 ? (
				<div>
					<Block size="xl">
						<div style={{ display: 'flex' }}>
							<div style={{ width: 140, paddingRight: 20 }}>
								<div className="interval-editor__input-title">Начало интервала</div>
								{maskInput(`schedule.interval_start`, '99:99', {
									withTimeIcon: true
								})}
							</div>
							<div style={{ width: 140, paddingRight: 20 }}>
								<div className="interval-editor__input-title">Конец интервала</div>
								{maskInput(`schedule.interval_end`, '99:99', {
									withTimeIcon: true
								})}
							</div>
							<div style={{ width: 140, paddingRight: 20 }}>
								<div className="interval-editor__input-title">Интервал(мин)</div>
								{maskInput(`schedule.interval`, '99', {
									withTimeIcon: true
								})}
							</div>
							<button className="interval-editor__button" onClick={onAddInterval}>
								Добавить интервал
							</button>
						</div>
					</Block>
				</div>
			) : (
				<div>
					<Block size="xl">
						<div style={{ display: 'flex' }}>
							<div style={{ width: 140, paddingRight: 20 }}>
								<div className="interval-editor__input-title">Интервал(мин)</div>
								{maskInput(`schedule.interval`, '99', {
									withTimeIcon: true
								})}
							</div>
							<button className="interval-editor__button" onClick={onCountDefaultIntervals}>
								Рассчитать
							</button>
						</div>
					</Block>
				</div>
			)}
			<Block size="xl">
				<TableContainer>
					<div className="Table">
						<table className="b-table">
							<thead>
								<tr className="b-table__header">
									<th className="b-table__mon align-left">Время суток</th>
									{intervals.map((interval) => (
										<th className="b-table__tue align-center">{`${interval.start_at} - ${interval.end_at}`}</th>
									))}
								</tr>
							</thead>
							<tbody>
								<tr className="b-table__header">
									<th className="b-table__mon align-left">Интервал(мин)</th>
									{intervals.map((interval) => <th className="b-table__tue align-center">{`${interval.interval}`}</th>)}
								</tr>
								{!isIntervalTypeDefault && (
									<tr className="b-table__header">
										<th className="b-table__mon align-left" />
										{intervals.map((interval) => (
											<th className="b-table__tue align-center">
												<div onClick={() => onRemoveInterval(`${interval.start_at} - ${interval.end_at}`)}>❌</div>
											</th>
										))}
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</TableContainer>
			</Block>
			<Block size="xl">
				<button className="interval-editor__button" onClick={onDeleteIntervals}>
					Удалить интервалы
				</button>
			</Block>
		</AccordionItem>
	</div>
);
