import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {getRouteRegistry, createRouteRegistry, updateRouteRegistry} from "store/reducers/kiutr/route_registries";
import {getRoutes} from "store/reducers/routes/route_editor";
import {getContracts} from "store/reducers/kiutr/contracts/contracts";
import TableContainer from "components/ui/Table/Container/TableContainer";
import debounce from 'throttle-debounce/debounce';
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import moment from "moment";
import months from "dictionaries/months";
import {prepareDateForPrint} from "../../../../helpers/print_official";
import {isCarriersLimits, isMunicipalType} from 'helpers/functions';

import {api} from "helpers/api";
import FileReaderInput from 'react-file-reader-input';
import { render } from 'less';

import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";

import ContextTooltip from "components/ui/context-tooltip";


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
    contractUuid: PropTypes.string,
    fromContract: PropTypes.bool,
})

@defaultProps({
    fromContract: false,
})

@connect(state => ({}), {getRouteRegistry, createRouteRegistry, updateRouteRegistry, getContracts})

export default class RouteRegistryEditor extends BaseEditor {

    modelClass = 'App\\Model\\RouteRegistry';
    modalClassName = 'route-registry-editor';

    getFullTitle() {
        return 'Обслуживание маршрута';
    }

    componentDidMount() {
        this.componentWillReceiveProps(this.props);
    }

    async loadData(uuid) {
        return await this.props.getRouteRegistry(uuid);
    }

    async createItem(data) {
        return await this.props.createRouteRegistry(data);
    }

    async updateItem(data) {
        return await this.props.updateRouteRegistry(data);
    }

    getForm(item, onSubmit) {
        if (this.props.contractUuid) {
            item.contract_uuid = this.props.contractUuid;
        }
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    composeItem(data) {
        let result = _.clone(data);

        result.vehicles_plan = [];

        _.each(data.vehicles_plan_flat, (plan, seasonUuid) => {
            _.each(plan, (plan2, capacityTypeUuid) => {
                _.each(plan2, (value, scheduleTypeUuid) => {
                    result.vehicles_plan.push({
                        season_uuid: seasonUuid,
                        route_schedule_type_uuid: scheduleTypeUuid,
                        vehicle_capacity_type_uuid: capacityTypeUuid,
                        value,
                        fact: _.get(data.vehicles_fact_flat, `${seasonUuid}.${capacityTypeUuid}.${scheduleTypeUuid}`),
                    });
                });
            });
        });

        return result;
    }

    renderHeaderBtns() {
        super.renderHeaderBtns()

        if(this.props.routeParams.component === "children" && window.RNIS_SETTINGS.CITY_TULA && this.state.item) {
            return (
                <ContextTooltip key="base-editor.test" code="base-editor.route"
                                default="Карта маршрута">
                    <ModalTopMenuListItem
                        className="b-icon-link_params b-icon-link_icon_route"
                        href={`/children/routes/${this.state.item.route_uuid}`}
                    />
                </ContextTooltip>
            );
        }

    }

}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getRoutes, getDictionaryList, getContracts, getUnit, getUnits}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        route_registry: {},
        routes: [],
        contracts: [],
        route_statuses: [],
        seasons: [],
        vehicle_capacity_types: [],
        route_schedule_types: [],
        units: [],
        vehicle_types: [],
        route_kinds: []
    };

    routesLoadDebounce = debounce(500, ::this.loadRoutes);

    getData() {
        return this.state.route_registry;
    }

    async componentDidMount() {
        await this.setState({
            route_registry: this.composeItem(this.props.data),
        });
        if (this.props.mode === 'edit') {
            this.loadUnits();
        }

        this.loadContracts();
        this.loadDictionaries([
            'route_statuses',
            'seasons',
            'vehicle_capacity_types',
            'route_schedule_types',
            'vehicle_types',
            'route_kinds'
        ], null, true);
    }

    collectDataForPrintForm(item) {
        return prepareDateForPrint('routemap', item, this.state);
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 10000,
            },
            filters: {
                withUuid: [this.props.data.contract.carrier_uuid, this.props.data.contract.unit_uuid],
            },
        });
        if (response.isOk) {

            await this.setState({
                units: response.payload.items
            })
        } else {
            response.showErrors();
        }
    }

    composeItem(item) {
        item.vehicles_plan_flat = {};

        _.each(item.vehicles_plan, (plan) => {
            _.set(item, `vehicles_plan_flat.${plan.season_uuid}.${plan.vehicle_capacity_type_uuid}.${plan.route_schedule_type_uuid}`, plan.value);
        });

        return item;
    }

    async loadRoutes(input, callback) {
        let result;
        if (!input && this.get('route_uuid')) {
            result = await this.props.getRoutes({
                filters: {
                    withUuid: [this.get('route_uuid')],
                    withComponent: this.props.params.component
                },
                response_data: [
                    'items/uuid',
                    'items/title',
                ],
            });
        } else {
            result = await this.props.getRoutes({
                search: input,
                pagination: {
                    page: 1,
                    limit: 20,
                },
                filters: {
                    withComponent: this.props.params.component
                },
                response_data: [
                    'items/uuid',
                    'items/title',
                ],
            });
        }

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: i.title,
                    value: i.uuid,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    async loadContracts() {
        const uuids = this.state.route_registry.contract_uuid
        const meta = {
            filters: {
                withComponent: this.props.params.component
            },
            pagination: {
                page: 1,
                limit: 10000,
            },
            response_data: [
                'items/uuid',
                'items/number',
            ],
        }
        if(uuids){
            meta['filters'] = {
                withUuid:[uuids],
            }
        }
        const response = await this.props.getContracts(meta);
        if (response.isOk) {

            this.setState({
                contracts: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.number,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async setValue(field, value) {
        return super.setValue(field, value);
    }

    get(path, defaultValue = null) {
        return _.get(this.state.route_registry, path, defaultValue);
    }

    deleteFile() {
        this.setValue('route_registry.file', '');
    }

    render() {
        let routeKindDisabled = false;
        if (this.state.route_registry.route) {
            routeKindDisabled= isMunicipalType(this.state.route_kinds, this.state.route_registry.route.route_kind_uuid)
        }
        if (this.state.units.length) {
            this.props.data.printForm = this.collectDataForPrintForm(this.props.data)
        }
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} title="Общая информация" single={!this.props.fromContract}>
                        <Block title="Паспорт маршрута">
                            {this.selectAsync('route_registry.route_uuid', ::this.routesLoadDebounce)}
                        </Block>
                        {this.props.fromContract ? null : (
                            <Block title={window.RNIS_SETTINGS.rename_contracts ? "Подрядное обязательство" : "Контракт"}>
                                {(this.props.mode !== 'edit') ? (
                                    this.select('route_registry.contract_uuid', this.state.contracts)
                                ) : _.get(_.find(this.state.contracts, {value: this.get('contract_uuid')}), 'label')}
                            </Block>
                        )}
                        <Block title="Статус">
                            {this.select('route_registry.status_uuid', this.state.route_statuses,  {
                                disabled: isCarriersLimits() && routeKindDisabled
                            })}
                        </Block>
                        {
                            window.RNIS_SETTINGS.CITY_TULA ? (
                                <Block title="Карта маршрута">
                                    <FileReaderInput as="binary" id="my-file-input" onChange={::this.uploadFile}>
                                        <a href="#">Загрузить карту маршрута</a>
                                    </FileReaderInput>
                                    {
                                        this.get('file') ? (
                                            <span>
                                                <a href={this.get('file')} target="_blank" className="link-download">Копия карты маршрута</a>
                                                <br />
                                                {/* <a href="#" onClick={::this.deleteFile} className="link-download">Удалить карту маршрута</a> */}
                                            </span>
                                        ) : null
                                    }
                                </Block>

                            ) : null
                        }

                    </AccordionItem>
                    {this.props.fromContract ? ([
                        <AccordionItem key="plan" title="Плановый пробег">
                            <Block title="Плановый пробег за квартал 1">
                                {this.textInput('route_registry.mileage_quarter_1')}
                            </Block>
                            <Block title="Плановый пробег за квартал 2">
                                {this.textInput('route_registry.mileage_quarter_2')}
                            </Block>
                            <Block title="Плановый пробег за квартал 3">
                                {this.textInput('route_registry.mileage_quarter_3')}
                            </Block>
                            <Block title="Плановый пробег за октябрь">
                                {this.textInput('route_registry.mileage_october')}
                            </Block>
                            <Block title="Плановый пробег за ноябрь">
                                {this.textInput('route_registry.mileage_november')}
                            </Block>
                            <Block title="Плановый пробег за декабрь">
                                {this.textInput('route_registry.mileage_december')}
                            </Block>
                        </AccordionItem>,
                        this.state.seasons.map(::this.renderSeason),
                    ]) : null}
                </Accordion>
            </div>
        );
    }

    renderSeason(season, index) {
        return (
            <AccordionItem key={index} title={`План ТС: ${season.label}`}>
                <Block size="xl">
                    <div className="Table">
                        <TableContainer>
                            <table className="b-table b-table-no-hover">
                                <thead>
                                <tr>
                                    <th width="200px">Класс</th>
                                    {this.state.route_schedule_types.map((scheduleType) => (
                                        <th key={scheduleType.value}>{scheduleType.label}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.vehicle_capacity_types.map(this.renderPlanRow.bind(this, season))}
                                </tbody>
                            </table>
                        </TableContainer>
                    </div>
                </Block>
            </AccordionItem>
        );
    }

    renderPlanRow(season, capacityType, index) {
        return (
            <tr key={index}>
                <td>{capacityType.label}</td>
                {this.state.route_schedule_types.map(this.renderPlanCell.bind(this, season, capacityType))}
            </tr>
        );
    }

    renderPlanCell(season, capacityType, scheduleType, index) {
        return (
            <td key={index}>
                {this.textInput(`route_registry.vehicles_plan_flat.${season.value}.${capacityType.value}.${scheduleType.value}`)}
            </td>
        )
    }

    async uploadFile(e, results) {

        console.log('uploadFile')
        console.log('results', results)

        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        results.forEach(result => {
            const [e, file] = result;
            formData.append('file', file);
        });

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');
        this.setValue('route_registry.file', value);
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }
}
