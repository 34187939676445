import WsRpc from "../lib/wsrpc";
import $ from 'jquery';
import moment from "moment";
import formats from "../dictionaries/formats";
import { installSettings } from "./settings";

export function createConnection(token = null, hostname = null) {
    let timeoutSec = 240; // запрашиваем сеттинги каждые x секунд
    // первый запрос настроек
    if (!window.RNIS_SETTINGS) {
        const resSettings = $.ajax({
            dataType: "json",
            url: `${window.location.origin}/settings/settings.json`,
            async: false,
        })

        installSettings(resSettings.responseJSON)
    }

    // периодический запрос настроек через х секунд после первого
    setTimeout(() => {
        if (!window.settingsTimer) {
            window.settingsTimer = setInterval(function () {
                $.when(
                    $.ajax({
                        dataType: "json",
                        url: `${window.location.origin}/settings/settings.json`,
                        async: true,
                    }),
                ).then(function ([settings]) {
                    installSettings(settings)
                    // если нет настройки, отвечающей за принудительное обновление пользователей, то аут
                    if (!RNIS_SETTINGS.HARDRELOADTIME) {
                        return;
                    }
                    // берем время перезагрузки
                    let reloadTime = moment(window.RNIS_SETTINGS.HARDRELOADTIME).format(formats.DATETIME);
                    let rt = {
                        time: reloadTime,
                        isReload: false
                    };

                    // если переменной reloadtime нет в localstorage, то кладем туда текущее значение из файла
                    localStorage.getItem("reloadtime") === null ? localStorage.setItem('reloadtime', JSON.stringify(rt)) : null;
                    // если текущее значение отличается от нового - перезаписываем его
                    JSON.parse(localStorage.getItem("reloadtime")).time.toString() !== reloadTime.toString() ? localStorage.setItem('reloadtime', JSON.stringify(rt)) : null;

                    let now = moment().format(formats.DATETIME);

                    // если время "сейчас" после чем время обновления из файла настроек
                    if (moment(now, formats.DATETIME).isAfter(moment(rt.time, formats.DATETIME), 'second')) {
                        let isReloaded = JSON.parse(localStorage.getItem("reloadtime")).isReload;
                        // проверяем может уже перезагружали страницу
                        if (!isReloaded) {
                            let rt = {
                                time: reloadTime,
                                isReload: true
                            };
                            // ставим флаг "перезагружено"
                            localStorage.setItem('reloadtime', JSON.stringify(rt));
                            // перезгружаем страницу
                            location.reload(true)
                        }
                    }
                })
            }, timeoutSec * 1000);

        }
    }, timeoutSec * 1000);


    let wsProtocol = App.isSecure ? 'wss://' : 'ws://';
    let ajaxProtocol = App.isSecure ? 'https://' : 'http://';

    if (window.RNIS_SETTINGS.isHTTP) {
        wsProtocol = 'ws://';
        ajaxProtocol  = 'http://';
    }

    const onlyAjax = hostname || (window.location.hostname === 'auto-rnis.regeora.ru') || window.RNIS_SETTINGS.RNIS_API_URL.includes("grant");

    const wsUrl = `${wsProtocol}${getHostName('ws', true, hostname)}`;
    const ajaxUrl = `${ajaxProtocol}${getHostName('ajax', true, hostname)}`;

    return new WsRpc({
        token,
        onlyAjax,
        ws: {
            url: wsUrl,
            realm: 'default',
            max_retries: App.ws.max_retries,
            max_retry_delay: App.ws.max_retry_delay
        },

        ajax: {
            url: ajaxUrl,
            retry_timeout: 5
        }
    });
}

export function getHostName(protocol = 'ajax', appendUri = true, hostname = null) {
    let locationHostname = '';
    const windowLocationHostname = hostname || window.location.hostname;

    if (window.RNIS_SETTINGS.RNIS_API_URL) {
        locationHostname = window.RNIS_SETTINGS.RNIS_API_URL;
    } else {
        if (/^([^.]+)-rnis\.regeora\.ru$/.test(windowLocationHostname)) {
            locationHostname = windowLocationHostname;//.replace('-rnis.', '-rnisapi.');
        } else if (windowLocationHostname === 'dev-rnis2.regeora.ru') {
            locationHostname = 'auto-rnis.regeora.ru';
        } else if (windowLocationHostname === 'localhost') {
            locationHostname = 'dev-rnis.regeora.ru';
        } else if (/^([^.]+)-rnis\.t1-group.ru$/.test(windowLocationHostname)) {
            locationHostname = windowLocationHostname;
        } else if (/\.mosreg.ru$/.test(windowLocationHostname)) {
            locationHostname = 'api.rnis.mosreg.ru';
        } else if (/demornis\.t1-group\.ru$/.test(windowLocationHostname)) {
            locationHostname = 'api.demornis.t1-group.ru';
        }
    }
    return locationHostname + (appendUri ? App[protocol].uri : '');
}

