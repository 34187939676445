import React, {Component} from 'react';
import Page from '../../../ui/page';

import './index.less';


export default class GarbageMapComponent extends Component {

    state = {};

    componentDidMount() {
        // слушаем дочерний фрейм
        let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        let eventer = window[eventMethod];
        let messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

        eventer(messageEvent, function (e) {
            console.log('parent received message!:  ', e.data);
        }, false);
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <Page pageId="GarbageMap" title="Вывоз музора" className="iframe_main" showHeader={false}>
                <div className="iframe_garbage">
                    <iframe src="https://moscow.kgdesoo.ru/?email=rnis@moscow.ru&password=hybc" width="100%"
                            height="100%" frameBorder="yes | no" seamless name="myIFrame">
                        Ваш браузер не поддерживает плавающие фреймы!
                    </iframe>
                </div>

            </Page>
        );
    }
}
