import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {
    getEventRuleNotification,
    createEventRuleNotification,
    updateEventRuleNotification, getEventTypes, getEventRule, getMailing, createMailing, updateMailing
} from "store/reducers/notifications";
import debounce from 'throttle-debounce/debounce';
import systems from "dictionaries/systems";
import {getUnits} from "store/reducers/organizational_units/units";
import vehicle_statuses from "dictionaries/vehicle_statuses";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getLayerObjectsFull, getLayersFull} from "store/reducers/user-map-objects/layer_list";
import NotificationRuleEditorMapComponent from "components/modules/notifications/rules/map";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import {getRoles} from "store/reducers/roles/roles";
import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import NotificationTextParameters from "components/modules/notifications/rules/text-parameters/index";
import SelectFilter from "components/ui/select-filter";
import reactions from "dictionaries/reactions";
import notifications from "dictionaries/notifications";
import currentUser from 'helpers/current-user';

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
})

@connect(state => ({}), {getMailing, createMailing, updateMailing})

export default class Editor extends BaseEditor {

    title = 'рассылки';
    modelClass = 'App\\Model\\Mailing';
    modalClassName = 'b-modal-mailing-editor';

    componentDidMount() {
        this.componentWillReceiveProps(this.props);
    }

    async loadData(uuid) {
        return await this.props.getMailing(uuid);
    }

    async createItem(data) {
        return await this.props.createMailing(data);
    }

    async updateItem(data) {
        return await this.props.updateMailing(data);
    }

    getForm(item, onSubmit) {
        item.is_send_now = false;
        
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    renderHeaderBtns(mode) {
        return ([
            <ContextTooltip key="mailing.save-and-send" code="mailing.save-and-send" default="Сохранить и разослать">
                <ModalTopMenuListItem
                    className="b-icon-link_icon_save-send"
                    onClick={this.saveAndSend.bind(this, (mode === 'edit') ? ::this.onEdit : ::this.onCreate)}
                />
            </ContextTooltip>,
        ]);
    }

    onCreate(e) {
        e && e.preventDefault();
        if (!this.refs.form) return;

        const state = this.refs.form.getWrappedInstance().getData();
        this.create(state);
    }

    onEdit(e) {
        e && e.preventDefault();
        if (!this.refs.form) return;

        const state = this.refs.form.getWrappedInstance().getData();
        this.edit(state);
    }

    saveAndSend(callback) {
        this.refs.form.getWrappedInstance().markSend();

        callback();
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getRoles,
    getUnits,
    getDictionaryList,
    getUsers,
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        mailing: {},
        units: [],
        roles: [],
        users: [],
        currentTextType: 0,
    };

    getData() {
        return this.state.mailing;
    }

    markSend() {
        this.setValue('mailing.is_send_now', true);
    }

    async componentDidMount() {
        await this.setState({
            mailing: this.props.data,
        });
        this.loadUnits();
        this.loadRoles();
        this.loadUsers();
    }

    async loadUnits() {
        const response = await this.props.getUnits();

        if (response.isOk) {
            this.setState({
                units: _.sortBy(_.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                })), 'name'),
            });
        } else {
            response.showErrors();
        }
    }

    createUnitsTree(units, parentUnitUuid = null, level = 0) {
        const coreUnits = _.filter(units, (unit) => {
            if (parentUnitUuid === null) {
                return !unit.parent_unit_uuid;
            }
            return unit.parent_unit_uuid === parentUnitUuid;
        });

        return _.flatten(_.map(coreUnits, (unit) => {
            let result = [
                {
                    value: unit.uuid,
                    label: unit.name,//<span style={{paddingLeft: `${level * 10}px`}}>{unit.name}</span>,
                },
            ];
            result = _.concat(result, this.createUnitsTree(units, unit.uuid, level + 1));
            return result;
        }));
    }

    async loadRoles() {
        const response = await this.props.getRoles();

        if (response.isOk) {
            this.setState({
                roles: _.map(response.payload.items, unit => ({
                    label: unit.name,
                    value: unit.uuid,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.mailing, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} title="Общая информация">
                        <Block size="xl" title="Наименование">
                            {this.textInput('mailing.name')}
                        </Block>
                        <Block title="Код">
                            {this.textInput('mailing.code')}
                        </Block>
                        <Block title="Уровень важности">
                            {this.select('mailing.notification_level', _.map(notifications.levels, (label, value) => ({
                                value,
                                label
                            })))}
                        </Block>
                        <Block size="md" title="Методы доставки по умолчанию">
                            {this.checkbox('mailing.via.email', 'Email')}
                        </Block>
                        <Block size="sm" title="Разрешить изменение">
                            {this.checkbox('mailing.via_allow_add.email')}
                        </Block>
                        <Block size="md">
                            {this.checkbox('mailing.via.sms', 'СМС')}
                        </Block>
                        <Block size="sm">
                            {this.checkbox('mailing.via_allow_add.sms')}
                        </Block>
                        <Block size="md">
                            {this.checkbox('mailing.via.system', 'Лента')}
                        </Block>
                        <Block size="sm">
                            {this.checkbox('mailing.via_allow_add.system')}
                        </Block>
                        <Block size="md">
                            {this.checkbox('mailing.via.journal', 'Журнал')}
                        </Block>
                        <Block size="sm">
                            {this.checkbox('mailing.via_allow_add.journal')}
                        </Block>
                    </AccordionItem>
                    <AccordionItem title="Отложенная рассылка">
                        <Block>
                            {this.checkbox('mailing.is_delayed', 'Активировать отложенную рассылку')}
                        </Block>
                        {this.get('is_delayed') ? ([
                            <Block key="date" title="Дата">
                                {this.datepicker('mailing.delayed_date')}
                            </Block>,
                            <Block key="time" title="Время">
                                {this.maskInput('mailing.delayed_time', '99:99')}
                            </Block>,
                        ]) : null}
                    </AccordionItem>
                    <AccordionItem title="Аудитория доставки">
                        <Block size="xl" title="Роли">
                            {this.select('mailing.roles', _.map(currentUser.user.roles, role => ({
                                value: role.uuid,
                                label: role.name,
                            })), {multi: true, sort: true})}
                        </Block>
                        <Block size="xl" title="Предприятия">
                            {this.select('mailing.units', this.state.units, {multi: true, sort: true})}
                        </Block>
                        <Block size="xl" title="Пользователи">
                            {this.select('mailing.users', this.state.users, {multi: true, sort: true})}
                        </Block>
                        <Block size="xl">
                            Всего получателей: {((this.get('users') || []).length > 0) ? this.get('users').length : this.state.users.length}
                        </Block>
                    </AccordionItem>
                    <AccordionItem title="Текст сообщения">
                        <Block size="xl">
                            <SelectFilter
                                items={[
                                    'Журнал',
                                    'Лента',
                                    'Почта',
                                    'Смс',
                                ]}
                                currentItem={this.state.currentTextType}
                                onChange={(index) => this.setState({currentTextType: index})}/>
                        </Block>
                        {(this.state.currentTextType === 0) ? (
                            <Block size="xl" title="Журнал">
                                {this.textarea('mailing.text.journal')}
                            </Block>
                        ) : null}
                        {(this.state.currentTextType === 1) ? (
                            <Block size="xl" title="Лента">
                                {this.textarea('mailing.text.system')}
                            </Block>
                        ) : null}
                        {(this.state.currentTextType === 2) ? (
                            <Block size="xl" title="Почта">
                                {this.textarea('mailing.text.email')}
                            </Block>
                        ) : null}
                        {(this.state.currentTextType === 3) ? (
                            <Block size="xl" title="Смс">
                                {this.textarea('mailing.text.sms')}
                            </Block>
                        ) : null}
                    </AccordionItem>
                    <AccordionItem title="Поведение оповещения">
                        <Block>
                            {this.checkbox('mailing.settings.at_time', 'Напоминать в фикс. время')}
                            {this.checkbox('mailing.settings.is_to_the_day_end', 'Напоминать до конца дня')}
                        </Block>
                        {this.get('settings.at_time') ? (
                            <Block title="Время суток">
                                {this.textInput('mailing.settings.time')}
                            </Block>
                        ) : (
                            <Block title="Периодичность напоминаний, мин">
                                {this.textInput('mailing.settings.period')}
                            </Block>
                        )}
                        {!this.get('settings.is_to_the_day_end') ? (
                            <Block title="Напоминать в течение, мин">
                                {this.textInput('mailing.settings.duration')}
                            </Block>
                        ) : null}
                        <Block title="Ожидаемый тип реакции">
                            {this.select('mailing.settings.reaction', _.map(reactions, (label, value) => ({
                                label,
                                value
                            })))}
                        </Block>
                        <Block>
                            {this.checkbox('mailing.settings.allow_mass_read', 'Разрешить пакетное изменение статусов прочтения')}
                            {this.checkbox('mailing.settings.allow_level_change', 'Разрешить изменять важность')}
                            {this.checkbox('mailing.settings.allow_config', 'Разрешить настраивать напоминания')}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    async setValue(field, value) {
        super.setValue(field, value);

        if ((field === 'mailing.roles') || (field === 'mailing.units')) {
            this.loadUsers();
        }
    }

    async loadUsers() {
        if ((this.get('roles', []).length + this.get('units', []).length) === 0) {
            this.setState({
                users: [],
            });
            return;
        }

        let result = {
            isOk: true,
            payload: {
                items: [],
            },
        };
        if ((this.get('roles', []).length > 0) && (this.get('units', []).length > 0)) {
            result = await this.props.getUsers({
                filters: {
                    notVirtual: true,
                    withRoles: this.get('roles', []),
                    withUnits: this.get('units', []),
                },
            });
        } else if (this.get('roles', []).length > 0) {
            result = await this.props.getUsers({
                filters: {
                    notVirtual: true,
                    withRoles: this.get('roles', []),
                },
            });
        } else if (this.get('units', []).length > 0) {
            result = await this.props.getUsers({
                filters: {
                    notVirtual: true,
                    withUnits: this.get('units', []),
                },
            });
        }

        if (result.isOk) {
            this.setState({
                users: _.map(result.payload.items, (item) => ({
                    label: new User(item).getFullName(),
                    value: item.uuid,
                })),
            });
        } else {
            result.showErrors();
        }
    }
}