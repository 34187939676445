import {
	SET_ADD_ROAD_MODAL,
	SET_ROAD_PARTS,
	SET_CURRENT_TASK_ROAD_PARTS,
	SET_SEARCH_ROAD_PARTS,
	SET_LOADING_ROAD_PARTS,
	SET_KURS_TASKS_LOADING,
	SET_KURS_TASKS,
	SET_CONTRACTS_LIST,
	SET_CURRENT_TASK_TYPE,
	SET_COLLAPSE_MULTI,
	SET_EXPANDED_SKPDI,
	SET_EXPANDED_RNIS,
	SET_COOPERATION_AGREEMENTS,
	SET_CONFIRM_REQUEST
} from './actions';

const initialState = {
	isRoadAddModalOpened: false,
	roadParts: [],
	currentTaskRoadParts: [],
	currentTaskType: '',
	searchRoadParts: '',
	loadingRoadParts: false,
	kursTasks: [],
	loadingKursTasks: false,
	contracts: [],
	collapseMulti: false,
	expandedSkpdi: [],
	expandedRnis: false,
	cooperationAgreement: {},
	confirmRequest: {}
};
export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SET_ADD_ROAD_MODAL:
			return { ...state, isRoadAddModalOpened: action.payload };
		case SET_ROAD_PARTS:
			return { ...state, roadParts: action.payload };
		case SET_CURRENT_TASK_ROAD_PARTS:
			return { ...state, currentTaskRoadParts: action.payload };
		case SET_SEARCH_ROAD_PARTS:
			return { ...state, searchRoadParts: action.payload };
		case SET_LOADING_ROAD_PARTS:
			return { ...state, loadingRoadParts: action.payload };
		case SET_KURS_TASKS:
			return { ...state, kursTasks: action.payload };
		case SET_KURS_TASKS_LOADING:
			return { ...state, loadingKursTasks: action.payload };
		case SET_CONTRACTS_LIST:
			return { ...state, contracts: action.payload };
		case SET_CURRENT_TASK_TYPE:
			return { ...state, currentTaskType: action.payload };
		case SET_COLLAPSE_MULTI:
			return { ...state, collapseMulti: action.payload };
		case SET_EXPANDED_SKPDI:
			return { ...state, expandedSkpdi: action.payload };
		case SET_EXPANDED_RNIS:
			return { ...state, expandedRnis: action.payload };
		case SET_COOPERATION_AGREEMENTS:
			return { ...state, cooperationAgreement: action.payload };
		case SET_CONFIRM_REQUEST:
			return { ...state, confirmRequest: action.payload };
	}
	return state;
}
