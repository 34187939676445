import { rpc, rpcExcel } from './session';
import lscache from 'lscache';
import stopPointsCache from 'helpers/components/cache/StopPointsCache';
import stopPointsBoundingBoxCache from 'helpers/components/cache/StopPointsBoundingBoxCache';
import _ from 'lodash';
import moment from 'moment';
import { emptyResponseWithItems } from 'helpers/response';
import formats from '../../dictionaries/formats';

export async function getRoutes(meta = {}) {
	return await rpc.request('com.rnis.geo.action.route.list', {}, { meta });
}

export async function getRoutesShort(meta = {}) {
	return await rpc.request('com.rnis.geo.action.route.list.short', {}, { meta });
}

export async function createdByUuidList(meta = {}) {
	return await rpc.request('com.rnis.geo.action.route.created_by_uuid.list', {}, { meta });
}

export async function getRoute(uuid) {
	return await rpc.request('com.rnis.geo.action.route.get', { uuid });
}

export async function createRoute(data) {
	return await rpc.request('com.rnis.geo.action.route.create', data);
}

export async function cloneRoute(data) {
	return await rpc.request('com.rnis.geo.action.route.clone', data);
}

export async function cloneFillRoute(data) {
	return await rpc.request('com.rnis.geo.action.route.clone_fill', data);
}

export async function updateRoute(data) {
	return await rpc.request('com.rnis.geo.action.route.update', data);
}

export async function deleteRoute(data) {
	return await rpc.request('com.rnis.geo.action.route.delete', data);
}

export async function getStopPoints(meta, ignoreCache = false) {
	if (_.get(meta, 'filters.withZoom', 20) <= 13) {
		return emptyResponseWithItems();
	}
	if (!ignoreCache && stopPointsCache.isCacheable(meta)) {
		return await stopPointsCache.get(meta);
	}
	if (!ignoreCache && stopPointsBoundingBoxCache.isCacheable(meta)) {
		return await stopPointsBoundingBoxCache.get(meta);
	}
	return await rpc.request('com.rnis.geo.action.stop_point.list', {}, { meta });
}

export async function getStopPoint(uuid) {
	return await rpc.request('com.rnis.geo.action.stop_point.get', { uuid });
}

export async function createStopPoint(data) {
	return await rpc.request('com.rnis.geo.action.stop_point.create', data);
}

export async function updateStopPoint(data) {
	return await rpc.request('com.rnis.geo.action.stop_point.update', data);
}

export async function deleteStopPoint(data) {
	return await rpc.request('com.rnis.geo.action.stop_point.delete', data);
}

export async function createStopPointPavilion(data) {
	return await rpc.request('com.rnis.geo.action.stop_point_pavilion.create', data);
}

export async function getStopPointPavilion(uuid) {
	return await rpc.request('com.rnis.geo.action.stop_point.delete', { uuid });
}

export async function listStopPointPavilions(meta = {}) {
	return await rpc.request('com.rnis.geo.action.stop_point_pavilion.list', {}, { meta });
}

//fixme
export async function getRailroadCrossings(meta = {}) {
	return await rpc.request('com.rnis.crossroads.action.list', {}, { meta });
}

export async function updateStopPointPavilion(data) {
	return await rpc.request('com.rnis.geo.action.stop_point_pavilion.update', data);
}

export async function deleteStopPointPavilion(data) {
	return await rpc.request('com.rnis.geo.action.stop_point_pavilion.delete', data);
}

export async function routing(points) {
	return await rpc.request('com.rnis.geo.action.service.routing', { points });
}

export async function getClosestStopPoint(latitude, longitude) {
	return await rpc.request('com.rnis.geo.action.stop_point.closest.get', { latitude, longitude });
}

export async function routingByStreets(from, to, streets) {
	return await rpc.request('com.rnis.geo.action.service.routing.streets', { from, to, streets });
}

export async function getLayers(meta = {}) {
	return await rpc.request('com.rnis.geo.action.layer.list', {}, { meta });
}

export async function getLayer(uuid) {
	return await rpc.request('com.rnis.geo.action.layer.get', { uuid });
}

export async function createLayer(data) {
	return await rpc.request('com.rnis.geo.action.layer.create', data);
}

export async function updateLayer(data) {
	return await rpc.request('com.rnis.geo.action.layer.update', data);
}

export async function deleteLayer(data) {
	return await rpc.request('com.rnis.geo.action.layer.delete', data);
}

export async function getUserGeoObjects(meta = {}) {
	return await rpc.request('com.rnis.geo.action.user_geo_object.list', {}, { meta });
}

export async function getUserGeoObject(uuid) {
	return await rpc.request('com.rnis.geo.action.user_geo_object.get', { uuid });
}

export async function getUserGeoObjectByLayers(uuids, meta = {}) {
	return await rpc.request('com.rnis.geo.action.user_geo_object.list_by_layers', { items: uuids }, { meta });
}

export async function createUserGeoObject(data) {
	return await rpc.request('com.rnis.geo.action.user_geo_object.create', data);
}

export async function updateUserGeoObject(data) {
	return await rpc.request('com.rnis.geo.action.user_geo_object.update', data);
}

export async function deleteUserGeoObject(data) {
	return await rpc.request('com.rnis.geo.action.user_geo_object.delete', data);
}

export async function searchGeoObjects(data) {
	return await rpc.request('com.rnis.geo.action.search_geo', data);
}

export async function getRouteVariants(meta = {}) {
	return await rpc.request('com.rnis.geo.action.route_variant.list', {}, { meta });
}

export async function getRouteVariant(uuid) {
	return await rpc.request('com.rnis.geo.action.route_variant.get', { uuid });
}

export async function createRouteVariant(data) {
	return await rpc.request('com.rnis.geo.action.route_variant.create', data);
}

export async function updateRouteVariant(data) {
	return await rpc.request('com.rnis.geo.action.route_variant.update', data);
}

export async function deleteRouteVariant(data) {
	return await rpc.request('com.rnis.geo.action.route_variant.delete', data);
}

export async function getRouteVariantNullRuns(meta = {}) {
	return await rpc.request('com.rnis.geo.action.route_variant_null_run.list', {}, { meta });
}

export async function getRouteVariantNullRun(uuid) {
	return await rpc.request('com.rnis.geo.action.route_variant_null_run.get', { uuid });
}

export async function createRouteVariantNullRun(data) {
	return await rpc.request('com.rnis.geo.action.route_variant_null_run.create', data);
}

export async function updateRouteVariantNullRun(data) {
	return await rpc.request('com.rnis.geo.action.route_variant_null_run.update', data);
}

export async function deleteRouteVariantNullRun(data) {
	return await rpc.request('com.rnis.geo.action.route_variant_null_run.delete', data);
}

export async function getIntervalMaps(meta = {}) {
	return await rpc.request('com.rnis.geo.action.interval_map.list', {}, { meta });
}

export async function getIntervalMap(uuid) {
	return await rpc.request('com.rnis.geo.action.interval_map.get', { uuid });
}

export async function createIntervalMap(data) {
	return await rpc.request('com.rnis.geo.action.interval_map.create', data);
}

export async function cloneIntervalMap(uuid) {
	return await rpc.request('com.rnis.geo.action.interval_map.clone', { uuid });
}

export async function updateIntervalMap(data) {
	return await rpc.request('com.rnis.geo.action.interval_map.update', data);
}

export async function deleteIntervalMap(data) {
	return await rpc.request('com.rnis.geo.action.interval_map.delete', data);
}

export async function getSchedules(meta = {}) {
	return await rpc.request('com.rnis.geo.action.schedule.list', {}, { meta });
}

export async function getScheduleIntervals(uuid, meta = {}) {
	return await rpc.request('com.rnis.geo.action.schedule.interval.get', { uuid }, { meta });
}

export async function createScheduleIntervals(payload, meta = {}) {
	return await rpc.request('com.rnis.geo.action.schedule.interval.create', payload, { meta });
}

export async function updateScheduleIntervals(payload, meta = {}) {
	return await rpc.request('com.rnis.geo.action.schedule.interval.upsert', payload, { meta });
}

export async function deleteScheduleIntervals(payload, meta = {}) {
	return await rpc.request('com.rnis.geo.action.schedule.interval.delete', payload, { meta });
}

export async function getSchedule(uuid) {
	return await rpc.request('com.rnis.geo.action.schedule.get', { uuid });
}

export async function getScheduleInfo(uuid) {
	return await rpc.request('com.rnis.geo.action.schedule.get.info', { uuid });
}

export async function createSchedule(data) {
	return await rpc.request('com.rnis.geo.action.schedule.create', data);
}

export async function createScheduleInterval(data) {
	return await rpc.request('com.rnis.geo.action.schedule_interval.create', data);
}

export async function updateScheduleInterval(data) {
	return await rpc.request('com.rnis.geo.action.schedule_interval.update', data);
}

export async function deleteScheduleInterval(uuid) {
	return await rpc.request('com.rnis.geo.action.schedule_interval.delete', uuid);
}

export async function getScheduleInterval(uuid) {
	return await rpc.request('com.rnis.geo.action.schedule_interval.get', uuid);
}

export async function exportExcelSchedule(payload) {
	return await rpc.request('com.rnis.geo.action.schedule.export=', payload);
}

export async function getExportHtmlByExportUuid(payload) {
	return await rpc.request('com.rnis.system.action.export.get=', payload);
}

export async function cloneSchedule(data) {
	return await rpc.request('com.rnis.geo.action.schedule.clone', data);
}

export async function updateSchedule(data) {
	return await rpc.request('com.rnis.geo.action.schedule.update', data);
}

export async function deleteSchedule(data) {
	return await rpc.request('com.rnis.geo.action.schedule.delete', data);
}

export async function getScheduleTurns(meta = {}) {
	return await rpc.request('com.rnis.geo.action.schedule_turn.list', {}, { meta });
}

export async function getScheduleTurn(uuid) {
	return await rpc.request('com.rnis.geo.action.schedule_turn.get', { uuid });
}

export async function createScheduleTurn(data) {
	return await rpc.request('com.rnis.geo.action.schedule_turn.create', data);
}

export async function updateScheduleTurn(data) {
	return await rpc.request('com.rnis.geo.action.schedule_turn.update', data);
}

export async function deleteScheduleTurn(data) {
	return await rpc.request('com.rnis.geo.action.schedule_turn.delete', data);
}

export async function getScheduleSwitches(meta = {}) {
	return await rpc.request('com.rnis.geo.action.schedule_switch.list', {}, { meta });
}

export async function getScheduleSwitch(uuid) {
	return await rpc.request('com.rnis.geo.action.schedule_switch.get', { uuid });
}

export async function createScheduleSwitch(data) {
	return await rpc.request('com.rnis.geo.action.schedule_switch.create', data);
}

export async function updateScheduleSwitch(data) {
	return await rpc.request('com.rnis.geo.action.schedule_switch.update', data);
}

export async function deleteScheduleSwitch(data) {
	return await rpc.request('com.rnis.geo.action.schedule_switch.delete', data);
}

export async function getContracts(meta = {}) {
	return await rpc.request('com.rnis.geo.action.contract.list', {}, { meta });
}

export async function getContract(uuid) {
	return await rpc.request('com.rnis.geo.action.contract.get', { uuid });
}

export async function getContractPlanSummary(uuid) {
	return await rpc.request('com.rnis.geo.action.contract.plan_summary', { uuid });
}

export async function createContract(data) {
	return await rpc.request('com.rnis.geo.action.contract.create', data);
}

export async function updateContract(data) {
	return await rpc.request('com.rnis.geo.action.contract.update', data);
}

export async function deleteContract(data) {
	return await rpc.request('com.rnis.geo.action.contract.delete', data);
}

export async function getRouteRegistries(meta = {}) {
	return await rpc.request('com.rnis.geo.action.route_registry.list', {}, { meta });
}

export async function getRouteRegistry(uuid) {
	return await rpc.request('com.rnis.geo.action.route_registry.get', { uuid });
}

export async function getRouteRegistryFact(uuid) {
	return await rpc.request('com.rnis.geo.action.route_registry.get.fact', { uuid });
}

export async function createRouteRegistry(data) {
	return await rpc.request('com.rnis.geo.action.route_registry.create', data);
}

export async function updateRouteRegistry(data) {
	return await rpc.request('com.rnis.geo.action.route_registry.update', data);
}

export async function deleteRouteRegistry(data) {
	return await rpc.request('com.rnis.geo.action.route_registry.delete', data);
}

export async function getOrder(uuid) {
	return await rpc.request('com.rnis.geo.action.order.get', { uuid });
}

export async function getOrders(meta = {}, fullMethod) {
	if (window.RNIS_SETTINGS.short_order_list && !fullMethod) {
		return await rpc.request('com.rnis.geo.action.order.short.list', {}, { meta });
	}
	return await rpc.request('com.rnis.geo.action.order.list', {}, { meta });
}

export async function getOrdersUniqueList(meta = {}) {
	return await rpc.request('com.service.orders.unique.list', {}, { meta });
}

export async function getUniqueVehicles(meta = {}) {
	return await rpc.request('com.rnis.geo.action.order.list.stat', {}, { meta });
}

export async function exportOrders(meta = {}) {
	return await rpc.request('com.rnis.geo.action.order.list.export', {}, { meta });
}

export async function exportOrdersExcel(meta = {}, from, to) {
	const url = location.origin.includes('localhost') ? 'https://rnis-test.t1-group.ru' : location.origin;
	const body = {
		headers: {
			meta: meta,
			token: JSON.parse(localStorage.getItem('user')).token
		},
		payload: {}
	};
	const response = await fetch(`${url}/export/orders`, {
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify(body)
	});

	if (response.status === 200) {
		const blob = await response.blob();
		let a = document.createElement('a');
		a.href = window.URL.createObjectURL(blob);
		a.setAttribute(
			'download',
			`report${moment(meta.filters.withPeriod[0]).format(formats.DATE_URL)}-${moment(meta.filters.withPeriod[1]).format(
				formats.DATE_URL
			)}.xlsx`
		);
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	return response;
}

export async function exportOrderGet(uuid) {
	return await rpc.request('com.rnis.geo.action.order.list.export.get', { uuid });
}

export async function exportOrderList() {
	return await rpc.request('com.rnis.geo.action.order.list.export.list', {});
}

export async function updateOrder(data) {
	return await rpc.request('com.rnis.geo.action.order.update', data);
}

export async function deleteOrder(data) {
	return await rpc.request('com.rnis.geo.action.order.delete', data);
}

export async function cloneOrder(data) {
	return await rpc.request('com.rnis.geo.action.order.clone', data);
}

export async function getOrderRecalc(orderUuid) {
	return await rpc.request('com.rnis.geo.action.order_recalc.get', { order_uuid: orderUuid });
}

export async function updateOrderRecalc(data) {
	return await rpc.request('com.rnis.geo.action.order_recalc.update', data);
}

export async function createDefect(data) {
	return await rpc.request('com.rnis.geo.action.order.defect.create', data);
}

export async function updateDefect(data) {
	return await rpc.request('com.rnis.geo.action.order.defect.update', data);
}

export async function generateOrder(data) {
	return await rpc.request('com.rnis.geo.action.order.generate', data);
}

export async function getDailyViolations(meta = {}) {
	return await rpc.request('com.rnis.geo.action.daily_violations.list', {}, { meta });
}

export async function getDailyViolation(uuid) {
	return await rpc.request('com.rnis.geo.action.daily_violations.get', { uuid });
}

export async function getViolations(meta = {}) {
	return await rpc.request('com.rnis.geo.action.violations.list', {}, { meta });
}

export async function driverSummary(data) {
	return await rpc.request('com.rnis.geo.action.driver.summary', data);
}

export async function orderVehicles(data) {
	return await rpc.request('com.rnis.geo.action.order.vehicles', data);
}

export async function orderRunVehicles(data) {
	return await rpc.request('com.rnis.geo.action.order_run.vehicles', data);
}

export async function orderRunSummary(data) {
	return await rpc.request('com.rnis.geo.action.order_run.summary', data);
}

export async function getUnitContractsPlanSummary(data) {
	return await rpc.request('com.rnis.geo.action.contract.units_plan_summary', data);
}

export async function getOrderExecutions(meta) {
	return await rpc.request('com.rnis.geo.action.order_execution.list', {}, { meta });
}

export async function orderExecutionsStatistics(meta) {
	return await rpc.request('com.rnis.geo.action.order_execution.statistics', {}, { meta });
}

export async function getOrderExecutionUnitsSummary(data) {
	return await rpc.request('com.rnis.geo.action.order_execution.units_summary', data);
}

export async function geocode(data) {
	return await rpc.request('com.rnis.geo.action.service.geocoding', data);
}

export async function searchAddress(q, bounding_box, zoom) {
	return await rpc.request('com.rnis.geo.action.service.geocoding.search', { q, bounding_box, zoom });
}

export async function reverseGeocode(coordinates, bounding_box, zoom) {
	return await rpc.request('com.rnis.geo.action.service.geocoding', { coordinates, bounding_box, zoom });
}

export async function geoSearch(query) {
	return await rpc.request('com.rnis.geo.action.service.geocoding.search', {
		q: query
	});
}

export async function recalcOrderExecution(uuid) {
	const data = _.isString(uuid) ? { uuid } : uuid;

	return await rpc.request('com.rnis.geo.action.order_execution.recalc', data);
}

export async function getOrderExecutionRecalc(data) {
	return await rpc.request('com.rnis.geo.action.order_execution_recalc.get', data);
}

export async function getRouteDeviations(meta = {}) {
	return await rpc.request('com.rnis.geo.action.route_deviation.list', {}, { meta });
}

export async function createRouteDeviation(data) {
	return await rpc.request('com.rnis.geo.action.route_deviation.create', data);
}

export async function resourceCheck(data) {
	return await rpc.request('com.rnis.geo.action.resource.check', data);
}

export async function resourceGroupCheck(data) {
	return await rpc.request('com.rnis.geo.action.resource.check.group', data);
}

export async function getDriversScoreSummary(drivers, from, to) {
	return await rpc.request('com.rnis.geo.action.order.drivers_score_summary', { drivers, from, to });
}

export async function getDisplays(meta = {}) {
	return await rpc.request('com.rnis.geo.action.display.list', {}, { meta });
}

export async function getDisplay(uuid) {
	return await rpc.request('com.rnis.geo.action.display.get', { uuid });
}

export async function createDisplay(data) {
	return await rpc.request('com.rnis.geo.action.display.create', data);
}

export async function updateDisplay(data) {
	return await rpc.request('com.rnis.geo.action.display.update', data);
}

export async function deleteDisplay(data) {
	return await rpc.request('com.rnis.geo.action.display.delete', data);
}

export async function getDisplayConfigurations(meta = {}) {
	return await rpc.request('com.rnis.geo.action.display_configuration.list', {}, { meta });
}

export async function getDisplayConfiguration(uuid) {
	return await rpc.request('com.rnis.geo.action.display_configuration.get', { uuid });
}

export async function createDisplayConfiguration(data) {
	return await rpc.request('com.rnis.geo.action.display_configuration.create', data);
}

export async function updateDisplayConfiguration(data) {
	return await rpc.request('com.rnis.geo.action.display_configuration.update', data);
}

export async function deleteDisplayConfiguration(data) {
	return await rpc.request('com.rnis.geo.action.display_configuration.delete', data);
}

export async function getDisplayLogs(meta = {}) {
	return await rpc.request('com.rnis.geo.action.display_log.list', {}, { meta });
}

export async function getStopPointRoutes(uuid) {
	return await rpc.request('com.rnis.geo.action.stop_point.routes', { uuid });
}

export async function getStopPointRoutesRun(meta = {}) {
	return await rpc.request('com.rnis.geo.action.stop_point.route.runs', meta);
}

export async function getTerritorialEntities(meta = {}) {
	return await rpc.request('com.rnis.geo.action.territorial_entity.list', {}, { meta });
}

export async function getTerritorialEntity(uuid) {
	return await rpc.request('com.rnis.geo.action.territorial_entity.get', { uuid });
}

export async function createTerritorialEntity(data) {
	return await rpc.request('com.rnis.geo.action.territorial_entity.create', data);
}

export async function updateTerritorialEntity(data) {
	return await rpc.request('com.rnis.geo.action.territorial_entity.update', data);
}

export async function deleteTerritorialEntity(data) {
	return await rpc.request('com.rnis.geo.action.territorial_entity.delete', data);
}

export async function listSwitches(meta = {}) {
	return await rpc.request('com.rnis.geo.action.schedule_switch_hide.list', {}, { meta });
}

export async function hideSwitch(data) {
	return await rpc.request('com.rnis.geo.action.schedule_switch_hide.hide', data);
}

export async function showSwitch(data) {
	return await rpc.request('com.rnis.geo.action.schedule_switch_hide.show', data);
}

export async function calcDuplicationMatrix(data) {
	return await rpc.request('com.rnis.geo.action.duplication_matrix.calc', data);
}

export async function createDuplicationMatrix(data) {
	return await rpc.request('com.rnis.geo.action.duplication_matrix.create', data);
}

export async function recalcDuplicationMatrix(data) {
	return await rpc.request('com.rnis.geo.action.duplication_matrix.recalc', data);
}

export async function getDuplicationMatrix(uuid) {
	return await rpc.request('com.rnis.geo.action.duplication_matrix.get', { uuid });
}

export async function getDuplicationMatrices(meta = {}) {
	return await rpc.request('com.rnis.geo.action.duplication_matrix.list', {}, { meta });
}

export async function deleteDuplicationMatrix(data) {
	return await rpc.request('com.rnis.geo.action.duplication_matrix.delete', data);
}

export async function getDuplicationMatrixRoutes(meta = {}) {
	return await rpc.request('com.rnis.geo.action.duplication_matrix.route.list', {}, { meta });
}

export async function getDuplicationMatrixRouteParts(meta = {}) {
	return await rpc.request('com.rnis.geo.action.duplication_matrix.route_part.list', {}, { meta });
}

export async function getEcpSignLogs(meta = {}) {
	return await rpc.request('com.rnis.geo.action.ecp_sign_log.list', {}, { meta });
}

export async function getVehicleIdleTimes(meta = {}) {
	return await rpc.request('com.rnis.geo.action.vehicle_idle_time.list', {}, { meta });
}

export async function listUserGeoObjectsMO(meta) {
	return await rpc.request('com.rnis.geo.action.user_geo_object.mo', {}, { meta });
}

export async function listUserGeoObjectsMunicipalitiesList(meta) {
	let payload = {
		regions: [ 'Муниципальные образования (МО)' ]
	};
	return await rpc.request('com.rnis.geo.action.user_geo_object.municipalities.list', payload, { meta });
}
