import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import classNames from 'classnames';
import $ from 'jquery';

export default class TaskItemUpdateTest extends Component {
    shouldComponentUpdate(nextProps) {
        return (nextProps.item !== this.props.item) || !_.isEqual(nextProps.data, this.props.data);
    }

    render() {
        return this.props.render();
    }
}