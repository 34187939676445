import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";
import {getUnits} from "store/reducers/organizational_units/units";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import FileReaderInput from 'react-file-reader-input';

import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import {api} from "helpers/api";
import {createVehicleWork, getVehicleWork, updateVehicleWork} from "store/reducers/kurs/vehicle_works";
import TableContainer from "components/ui/Table/Container/TableContainer";
import KursDetailAddForm from "components/modules/kurs/vehicles/vehicle-editor-form/operation-add-form/detail-add-form/index";
import classNames from 'classnames';
import ValidationError from "components/ui/validation-error";
import * as alerts from "helpers/alerts";

@connect(state => ({}), {getVehicleWork, createVehicleWork, updateVehicleWork})

export default class KursOperationAddForm extends BaseEditor {

    modalClassName = 'b-modal-road-accounting edit-operation-modal';

    getFullTitle() {
        return 'Выбор операции';
    }

    loadData(uuid) {
        return this.props.getVehicleWork(uuid);
    }

    async createItem(data) {
        if (data.type === 'maintenance') {
            const isConfirmed = await new Promise((resolve, reject) => {
                alerts.prompt('Вы уверены, что хотите сохранить? Дальнейшее изменение будет невозможно.', null, () => {
                    resolve(true);
                }, 'Сохранить', () => {
                    resolve(false);
                });
            });
            if (!isConfirmed) {
                return;
            }
        }
        return await this.props.createVehicleWork(data);
    }

    async updateItem(data) {
        return await this.props.updateVehicleWork(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                clearErrors={::this.clearErrors}
            />
        );
    }

    composeItem(data) {
        let item = _.cloneDeep(data);

        item.vehicle_uuid = this.props.vehicleUuid;

        return item;
    }

    getButtons() {
        return [
            <div key="tooltip" className="b-modal__footer-txt">Вы хотите сохранить все изменения?</div>,
            <a key="cancel" href="javascript:void(0)"
               className="b-button b-button_size_md b-button_white b-button_shadow_gray b-button_cancel"
               onClick={this.props.onClose}>Отменить</a>,
            <a key="save" href="javascript:void(0)" className="b-button b-button_red b-button_size_md b-button_save"
               onClick={(this.props.mode === 'edit') ? ::this.onEdit : ::this.onCreate}>Сохранить</a>
        ];
    }

    saveButton(onClick) {
        return null;
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getUnits, getUsers, getDictionaryList}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {

    state = {
        kurs_vehicle_work: {},
        kurs_vehicle_repair_types: [],
        detailAddShow: false,
    };

    getData() {
        return this.state.kurs_vehicle_work;
    }

    async componentDidMount() {
        await this.setState({
            kurs_vehicle_work: this.props.data,
        });
        this.loadDictionaries([
            'kurs_vehicle_repair_types',
            'kurs_accounting_groups',
            'kurs_details',
        ], this.props.component);
    }

    get(path, defaultValue = null) {
        return _.get(this.state.kurs_vehicle_work, path, defaultValue);
    }

    getMaintenanceNames() {
        const existingMaintenances = this.props.existingMaintenances || [];

        return _.map(this.props.maintenanceNames, (number) => {
            return {
                value: number,
                label: (_.indexOf(existingMaintenances, number) !== -1) ? (
                    <div>✓ {number}</div>
                ) : number,
            };
        });
    }

    render() {
        const error = this.getError('kurs_vehicle_work.details');
        const styleClassName = classNames(error ? 'input__style_state_wrong' : '');

        return (
            <div>
                <div className="b-modal__block">
                    <Block size="sm" title="Дата операции">
                        {this.datepicker('kurs_vehicle_work.date', {
                            className: 'centered',
                        })}
                    </Block>
                    <Block title="Тип операции">
                        {this.radio('kurs_vehicle_work.type', 'maintenance', 'ТО')}
                        {this.radio('kurs_vehicle_work.type', 'repair', 'Другое')}
                        <ValidationError error={this.getError('kurs_vehicle_work.type')}/>
                    </Block>
                    {(this.get('type') === 'maintenance') ? (
                        <Block title="№ ТО">
                            {this.select('kurs_vehicle_work.maintenance_name', this.getMaintenanceNames())}
                        </Block>
                    ) : null}
                    {(this.get('type') === 'repair') ? (
                        <Block title="Вид ремонта">
                            {this.select('kurs_vehicle_work.repair_type_uuid', this.state.kurs_vehicle_repair_types)}
                        </Block>
                    ) : null}
                    <Block size="xl" title={(this.props.type === 'repair') ? 'Запчасти' : null}>
                        <div className="Table">
                            <TableContainer>
                                <table className="b-table">
                                    <thead>
                                    <tr>
                                        <th>Группа учета</th>
                                        <th>Наименование</th>
                                        <th>Количество</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.get('details', []).map(::this.renderDetailRow)}
                                    </tbody>
                                </table>
                            </TableContainer>
                        </div>
                        <div className={styleClassName}>
                            {error ?
                                <span className="input__style_state_msg_wrong"
                                      style={{top: '60px'}}>{error}</span> : null}
                        </div>
                        <div className="add-link">
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="javascript:void(0)"
                               onClick={::this.showDetailAdd}>Добавить {(this.props.type === 'repair') ? 'запчасть' : 'деталь'}</a>
                        </div>
                    </Block>
                    <Block title="Комментарий">
                        {this.textInput('kurs_vehicle_work.comment')}
                    </Block>
                    <div className="b-modal__block-title">Документы</div>
                    <Block size="xl">
                        {this.renderDocumentScans()}
                    </Block>
                </div>
                {this.state.detailAddShow ? this.renderDetailAddForm() : null}
            </div>
        );
    }

    renderDetailRow(detail, index) {
        return (
            <tr key={`${detail.accounting_group_uuid}.${detail.detail_uuid}.${detail.amount}`}>
                <td>{_.get(_.find(this.state.kurs_accounting_groups, {value: detail.accounting_group_uuid}), 'label')}</td>
                <td>{_.get(_.find(this.state.kurs_details, {value: detail.detail_uuid}), 'label')}</td>
                <td>{detail.amount}</td>
                <td><a href="javascript:void(0)" onClick={this.deleteDetail.bind(this, index)}>x</a></td>
            </tr>
        );
    }

    deleteDetail(index, e) {
        e.preventDefault();

        let kurs_vehicle_work = this.state.kurs_vehicle_work;
        let details = kurs_vehicle_work.details || [];
        details.splice(index, 1);
        kurs_vehicle_work.details = details;

        this.setState({kurs_vehicle_work});
    }

    renderDetailAddForm() {
        return (
            <KursDetailAddForm
                component={this.props.component}
                mode="add"
                uuid={null}
                onSubmit={::this.addDetail}
                onClose={::this.hideDetailAdd}
            />
        );
    }

    addDetail(data) {
        this.hideDetailAdd();
        this.props.clearErrors();

        let kurs_vehicle_work = this.state.kurs_vehicle_work;
        let details = kurs_vehicle_work.details || [];
        details.push(data);
        kurs_vehicle_work.details = details;

        this.setState({kurs_vehicle_work});
    }

    showDetailAdd(e) {
        e && e.preventDefault();

        this.setState({detailAddShow: true});
    }

    hideDetailAdd(e) {
        e && e.preventDefault();

        this.setState({detailAddShow: false});
    }

    renderDocumentScans() {
        const list = this.get('documents', []);

        if (list.length === 0) {
            return (
                <div className="add-link">
                    <a className="add-job b-icon-link b-icon-link_icon_plus" href="javascript:void(0)"
                       onClick={::this.addDocumentScan}>Добавить
                        документ</a>
                </div>
            );
        }
        return list.map(::this.renderDocumentScan);
    }

    addDocumentScan(e) {
        e.preventDefault();

        let kurs_vehicle_work = this.state.kurs_vehicle_work;
        let documents = _.get(kurs_vehicle_work, 'documents', []);
        documents.push({});
        _.set(kurs_vehicle_work, 'documents', documents);

        this.setState({kurs_vehicle_work});
    }

    deleteDocumentScan(index, e) {
        e.preventDefault();

        let kurs_vehicle_work = this.state.kurs_vehicle_work;
        let documents = _.get(kurs_vehicle_work, 'documents', []);
        documents.splice(index, 1);
        _.set(kurs_vehicle_work, 'documents', documents);

        this.setState({kurs_vehicle_work});
    }

    renderDocumentScan(bnso, index) {
        return (
            <div key={index}>
                <Block title="Наименование">
                    {this.textInput(`kurs_vehicle_work.documents.${index}.name`)}
                </Block>
                <Block title="Файл">
                    <div className="photo__manual">Размер: не более 20 Мб на файл</div>
                    {this.get(`documents.${index}.file`) ? ([
                        <a key="open" href={this.get(`documents.${index}.file`)} target="_blank">Открыть</a>,
                        <br key="separator"/>,
                        <br key="separator2"/>,
                    ]) : null}
                    <FileReaderInput as="binary" id="my-file-input"
                                     onChange={this.uploadDocumentScan.bind(this, index)}>
                        <a href="javascript:void(0)">Загрузить файл</a>
                    </FileReaderInput>
                </Block>
                <div className="add-link">
                    <a className="remove-job b-icon-link b-icon-link_icon_basket" href="javascript:void(0)"
                       onClick={this.deleteDocumentScan.bind(this, index)}>Удалить документ</a>
                    {(index === this.get('documents', []).length - 1) ? (
                        <span>
                            <span className="add-link__separator"/>
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="javascript:void(0)"
                               onClick={::this.addDocumentScan}>Добавить документ</a>
                        </span>
                    ) : null}

                </div>
                {(index !== this.get('documents', []).length - 1) ? <div className="hr"/> : null}
            </div>
        );
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }

    async uploadDocumentScan(index, e, results) {
        e.preventDefault();

        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        const allowedTypes = [
            'application/pdf',
            'image/png',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'image/jpeg',
        ];

        let hasError = false;

        results.forEach(result => {
            const [e, file] = result;
            formData.append('file', file);

            let errors = [];
            if (_.indexOf(allowedTypes, file.type) === -1) {
                errors.push('Недопустимый формат файла');
            }
            if ((file.size / (1024 * 1024)) >= 20) {
                errors.push('Недопустимый размер файла');
            }
            if (errors.length > 0) {
                alerts.error(errors.join('<br/>'));
                hasError = true;
            }
        });

        if (hasError) {
            return;
        }

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');

        let kurs_vehicle_work = this.state.kurs_vehicle_work;
        let documents = _.get(kurs_vehicle_work, 'documents', []);
        documents[index].file = value;
        _.set(kurs_vehicle_work, 'documents', documents);
        this.setState({kurs_vehicle_work});
    }
}