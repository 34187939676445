import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapMarker from "components/ui/map/base/marker";
import DisplayIcon from "components/ui/map/markers/icons/display-icon";
import ReactPopup from "react-leaflet-popup";
import L from 'leaflet';
import _ from 'lodash';
import moment from "moment";
import formats from "dictionaries/formats";
import {connect} from "react-redux";
import {getUnit} from "store/reducers/organizational_units/units";
import {getStopPointRoutes} from "store/reducers/kiutr/displays";

@propTypes({
    display: PropTypes.object,
})

@defaultProps({})

@connect(state => ({}), {getUnit, getStopPointRoutes})

export default class DisplayMarker extends AbstractMapMarker {

    getOptions() {
        return {
            icon: DisplayIcon.getIcon(),
            riseOnHover: true,
        };
    }

    componentWillMount() {
        super.componentWillMount();

        this.getMarker().on('click', ::this.loadPopup)
    }

    async loadUnit() {
        const response = await this.props.getUnit(this.props.display.unit_uuid);

        if (response.isOk) {
            return response.payload;
        }
        return null;
    }

    async loadRoutes() {
        const response = await this.props.getStopPointRoutes(this.props.display.stop_point_uuid);

        if (response.isOk) {
            return _.filter(response.payload.items, (route) => {
                return _.indexOf(this.props.display.routes, route.uuid) !== -1;
            });
        }
        return null;
    }

    async loadPopup() {
        if (!this.props.display) {
            return;
        }

        const unit = await this.loadUnit();
        const routes = await this.loadRoutes();

        const marker = this.getMarker();
        if (marker.isPopupOpen()) {
            marker.closePopup();
            marker.unbindPopup();
            return;
        }
        marker.unbindPopup();
        marker.closePopup();
        marker.bindPopup(
            this.createStopPointPopup(unit, routes, ::marker.closePopup)
        );
        marker.openPopup();
    }

    createStopPointPopup(unit, routes, onClose) {
        const display = this.props.display;
        if (!display) {
            return null;
        }

        return new ReactPopup({
            reactComponent: Popup,
            reactComponentProps: {
                ...this.props,
                display,
                unit,
                routes,
                onClose,
            },
            minWidth: 434,
            maxWidth: 434,
            offset: new L.Point(3, -250),
        });
    }
}

class Popup extends Component {

    render() {
        const {display, unit, routes, onEditClick} = this.props;

        return (
            <div className="b-modal b-modal-map map-tooltip-modal tooltip-stop tooltip-scoreboard">
                <div className="b-modal__header">
                    <a href="#" className="b-modal__close" onClick={::this.onClose}/>
                    <div className="b-modal__name align-middle">Табло №{display.identifier}</div>
                    <div
                        className="b-modal__name b-modal__name_sub align-middle">{_.get(display, 'stop_point.title')}</div>
                </div>
                <div className="b-modal__body">
                    <div className="b-block">
                        <div className="b-block__desc">Маршрут</div>
                        <div className="b-block__value">Время прибытия</div>
                    </div>
                    {_.map(routes, (route) => {
                        return (
                            <div key={route.uuid} className="b-block">
                                <div className="b-block__desc">{route.number}</div>
                                <div
                                    className="b-block__value">{(route.time !== -1) ? `${Math.round(route.time / 60)} мин` : '-'}</div>
                            </div>
                        );
                    })}
                    <hr className="hr"></hr>
                    <div className="b-block">
                        <div className="b-block__desc">Балансодержатель</div>
                        <div className="b-block__value">{_.get(unit, 'name')}</div>
                    </div>
                    <hr className="hr"></hr>
                    {window.RNIS_SETTINGS.SHOWTABLOCOORD ? (
                    <div className="b-block">
                        <div className="b-block__desc">Координаты</div>
                        <div className="b-block__value">{_.get(display.stop_point, 'latitude')} {_.get(display.stop_point, 'longitude')}</div>
                    </div>) : null}

                </div>
                <div className="b-modal__footer">
                    {onEditClick ? (
                        <a href="javascript:void(0)" className="b-button b-button_size_md b-button_red b-button_card"
                           onClick={onEditClick}>Редактировать</a>
                    ) : null}
                    <div className="update-info">
                        Последнее обновление
                        <br/>
                        {display.last_success_update ? moment(display.last_success_update).format(formats.DATETIME) : '-'}
                    </div>
                </div>
            </div>
        );
    }

    onClose(e) {
        e && e.preventDefault();
        this.props.onClose();
    }
}