import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {defaultProps, propTypes} from 'react-props-decorators';
import moment from "moment";
import formats from "dictionaries/formats";
import Page from 'components/ui/page';
import _ from 'lodash';
import ContextTooltip from "components/ui/context-tooltip";
import Select from "components/ui/select";
import Popup from "components/ui/popup";
import SelectButtonPopup from 'components/ui/SelectButtonPopup';
import Datepicker from "components/ui/form/datepicker";
import IconButton from "components/ui/icon-button";
import WidgetLayout from 'components/modules/analytics/base-components/WidgetLayout';
import {getUnits} from "store/reducers/organizational_units/units";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {setWidgetFrom, setWidgetTo} from "store/reducers/widget/widget";
import './style.less';
import Settings from 'settings';
import ShowDeleted from "../../../../ui/show-deleted";

@propTypes({
    widgetItems: PropTypes.array,
    title: PropTypes.string,
    cacheKey: PropTypes.string.isRequired,
    className: PropTypes.string,
})

@defaultProps({
    widgetItems: [],
    title: '',
    className: '',
})

@connect(state => ({}), {
    getDictionaryList,
    getUnits,
    setWidgetFrom,
    setWidgetTo
})

export default class WidgetPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            widgetItems: null,
            disabledSelectButton: false,
            showMunicipality: false,
            showTableSearchFooter: false,


            showFilters: false,
            filters: {
                work_type: null,
                vehicle_type: null,
                kurs_task_status: null,
                units: null,
                task_done: null,
            },
            work_types: [],
            vehicle_types: [],
            kurs_task_statuses: [],
            units: [],
            from: moment(),
            to: moment().add(1, 'day'),
        };
    }

    task_done = [
        {
            value: 'done',
            label: 'Выполненные задания',
        },
        {
            value: 'undone',
            label: 'Невыполненные задания',
        },
        {
            value: 'partial',
            label: 'Частично выполненные задания',
        },
    ];

    parseJson(value, defaultValue = null) {
        try {
            return JSON.parse(value);
        } catch (e) {
        }
        return defaultValue;
    }

    async componentWillMount() {
        let widgetItems = _.clone(this.props.widgetItems);
        await Promise.all(_.map(this.parseJson(await Settings.getUser(`analytics:${this.props.cacheKey}`, []), []), async (item) => {
            let widgetItem = _.find(widgetItems, {id: item});
            if (!widgetItem) {
                return;
            }
            const layout = _.cloneDeep(this.parseJson(await Settings.getUser(`analytics:layout:${this.props.cacheKey}:${item}`), []));
            if (layout && (layout.w !== 0) && (layout.h !== 0)) {
                widgetItem.checked = true;
                //widgetItem.layout = layout;
            }
        }));
        this.setState({widgetItems});

        if (window.RNIS_SETTINGS.CITY_MURMANSK) {
            this.loadDictionaries([
                'work_types',
                'kurs_task_statuses',
                'vehicle_types',
                'communal_municipalities',
            ], 'road');
            this.loadUnits();
        }
    }


    getFilters() {
        let filters = {
            withPeriod: [
                moment(this.state.from).format(formats.DATE_API),
                moment(this.state.to).format(formats.DATE_API),
            ],
        };
        if (this.state.filters.work_type) {
            filters.withWorkTypes = [this.state.filters.work_type];
        }
        if (this.state.filters.vehicle_type) {
            filters.withVehicleTypes = [this.state.filters.vehicle_type];
        }
        if (this.state.filters.kurs_task_status) {
            filters.withStatus = this.state.filters.kurs_task_status;
        }
        if (this.state.filters.units) {
            filters.withUnits = [this.state.filters.units];
        }
        if (this.state.filters.task_done) {
            filters.withTaskDone = this.state.filters.task_done;
        }
        if (this.state.filters.communal_municipalities) {
            filters.withCommunalMunicipalities = [this.state.filters.communal_municipalities];
        }

        return filters;
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            filters: {
                withComponent: 'road',
            },
            pagination: {
                page: 1,
                limit: 10000,
            },
        });

        if (response.isOk) {
            this.setState({
                units: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    fromChange = async ({target: {value}}) => {
        this.props.setWidgetFrom(value);
        await this.setState({from: value});
    };

    toChange = async ({target: {value}}) => {
        this.props.setWidgetTo(value);
        await this.setState({to: value});
    };

    async onFilterChange(type, e) {
        const value = e ? e.value : null;

        let filters = this.state.filters;

        filters[type] = value;
        await this.setState({
            filters,
        });
    }

    toggleFilters() {
        this.setState({
            showFilters: !this.state.showFilters,
        });
    }

    renderFiltersPopup() {
        return (
            <Popup
                className="SelectFieldsPopup SelectFieldsPopup_tiles top-link"
                show={true}>
                <div className="popup__title">Фильтры карточек</div>
                <div className="content">
                    <div className="content__row">
                        <div className="b-block _md">
                            <div className="b-block__text-el">Вид работ</div>
                        </div>
                        <div className="b-block _md">
                            <Select
                                options={this.state.work_types}
                                value={this.state.filters.work_type}
                                onChange={this.onFilterChange.bind(this, 'work_type')}
                            />
                        </div>
                    </div>
                    {
                        window.RNIS_SETTINGS.CITY_MURMANSK ? (
                            <div className="content__row">
                                <div className="b-block _md">
                                    <div className="b-block__text-el">МО</div>
                                </div>
                                <div className="b-block _md">
                                    <Select
                                        options={this.state.communal_municipalities}
                                        value={this.state.filters.communal_municipalities}
                                        onChange={this.onFilterChange.bind(this, 'communal_municipalities')}
                                    />
                                </div>
                            </div>
                        )
                        : null
                    }
                    <div className="content__row">
                        <div className="b-block _md">
                            <div className="b-block__text-el">Тип техники</div>
                        </div>
                        <div className="b-block _md">
                            <Select
                                options={this.state.vehicle_types}
                                value={this.state.filters.vehicle_type}
                                onChange={this.onFilterChange.bind(this, 'vehicle_type')}
                            />
                        </div>
                    </div>
                    <div className="content__row">
                        <div className="b-block _md">
                            <div className="b-block__text-el">Статус задания</div>
                        </div>
                        <div className="b-block _md">
                            <Select
                                options={this.state.kurs_task_statuses}
                                value={this.state.filters.kurs_task_status}
                                onChange={this.onFilterChange.bind(this, 'kurs_task_status')}
                            />
                        </div>
                    </div>
                    <div className="content__row">
                        <div className="b-block _md">
                            <div className="b-block__text-el">Предприятие</div>
                        </div>
                        <div className="b-block _md">
                            <Select
                                options={this.state.units}
                                value={this.state.filters.units}
                                onChange={this.onFilterChange.bind(this, 'units')}
                            />
                        </div>
                    </div>
                    <div className="content__row">
                        <div className="b-block _md">
                            <div className="b-block__text-el">Выполнение задания</div>
                        </div>
                        <div className="b-block _md">
                            <Select
                                options={this.task_done}
                                value={this.state.filters.task_done}
                                onChange={this.onFilterChange.bind(this, 'task_done')}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }

    renderHeaderContents() {
        if (window.RNIS_SETTINGS.CITY_MURMANSK) {
            return [
                <div key="diapason">
                    <div className="top-menu__label">Период:</div>
                    <Datepicker style="dark" value={this.state.from} onChange={this.fromChange}/>
                    <Datepicker style="dark" value={this.state.to} onChange={this.toChange}/>
                </div>,
                <div key="filter">
                    <ContextTooltip key="base-table-list.filter" code="base-table-list.filter" default="Фильтр">
                        <IconButton icon="filter" tooltip="Фильтр" active={this.state.showFilters}
                                    onClick={::this.toggleFilters}/>
                    </ContextTooltip>
                    {this.state.showFilters ? this.renderFiltersPopup() : null}
                </div>,
                (this.props.withExportTable ?
                    <ContextTooltip key="base-table-list.export" code="base-table-list.export"
                        default="Экспорт в Excel (ctrl+shift+e)">
                        <IconButton icon="export"
                            onClick={this.props.exportToXls}
                        />
                    </ContextTooltip> : []),
            ]
        }

        return [
            (window.RNIS_SETTINGS.analytic_filter_for_municipality ? (
                <ContextTooltip key="base-table-list.show-deleted" code="base-table-list.show-deleted" space={20}
                                default="Статистика по предприятиям/муниципалитетам">
                    <ShowDeleted
                        active={this.state.showMunicipality}
                        onChange={::this.toggleShowDeleted}
                        className={this.state.showTableSearchFooter ? 'left-shift' : ''}
                    />
                </ContextTooltip>
            ) : null),
            <ContextTooltip key="analytics.widget-select"
                            code="analytics.widget-select"
                            default="Виджеты"
                            position="left"
            >
                <SelectButtonPopup ref="selectButtonPopup"
                                   items={this.state.widgetItems || []}
                                   onChange={this.onSelectWidget}
                                   disabled={this.state.disabledSelectButton}
                />
            </ContextTooltip>

        ];
    }

    async toggleShowDeleted() {
        await this.setState({
            showMunicipality: !this.state.showMunicipality,
        });
        this.render();
    }

    getItems() {
        return this.refs.selectButtonPopup ? this.refs.selectButtonPopup.getItems() : [];
    }

    onSelectWidget = (items) => {
        const allItems = this.getItems();
        const widgetItems = this.state.widgetItems.filter(el => el); // TODO: check why empty strings

        widgetItems.forEach((item, index) => {
            item.checked = allItems[index].checked;
        });

        this.setState({widgetItems});

        Settings.setUser(`analytics:${this.props.cacheKey}`, JSON.stringify(_.map(_.filter(widgetItems, (item) => item.checked), 'id')));
    };

    onCloseWidget = (id) => {
        const index = this.state.widgetItems.findIndex((item) => item.id === id);

        if (index >= 0) {
            const widgetItems = this.state.widgetItems;
            widgetItems[index].checked = false;
            this.setState({widgetItems, disabledSelectButton: false});

            Settings.setUser(`analytics:${this.props.cacheKey}`, JSON.stringify(_.map(_.filter(widgetItems, (item) => item.checked), 'id')));
        }
    };

    onMaximizeWidget = (id) => {
        this.setState({disabledSelectButton: true});
    };

    onMinimizeWidget = (id) => {
        this.setState({disabledSelectButton: false});
    };

    render() {
        const widgetItems = (this.state.widgetItems || []).filter((item) => item.checked);
        widgetItems.forEach(item => {
            item.municipality = this.state.showMunicipality;
        })
        return (
            <Page pageId="Analytics" className={this.props.className} title={this.props.title}
                  headerContents={this.renderHeaderContents()}>
                {this.state.widgetItems ? (
                    <WidgetLayout title={this.props.title}
                                  municipality={this.state.showMunicipality}
                                  router={this.props.router}
                                  cacheKey={this.props.cacheKey}
                                  widgetItems={widgetItems}
                                  onCloseWidget={this.onCloseWidget}
                                  onMaximizeWidget={this.onMaximizeWidget}
                                  onMinimizeWidget={this.onMinimizeWidget}
                                  filters={this.getFilters()}
                                  units={this.state.units}
                    />
                ) : null}
            </Page>
        );
    }
}