import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import IconButton from './icon-button';

import './page-modal.less';

@propTypes({
    header: PropTypes.object,
    closeButton: PropTypes.bool,
    onClose: PropTypes.func,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    withFade: PropTypes.bool,
    buttons: PropTypes.array,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    disableOutsideClick: PropTypes.bool,
    titleClassName: PropTypes.string,
})

@defaultProps({
    closeButton: true,
    className: '',
    headerClassName: '',
    withFade: true,
    buttons: [],
    disabled: false,
    style: {},
    disableOutsideClick: true,
    titleClassName: '',
})

export default class PageModalComponent extends Component {
    
    render() {
        const paranjaClassName = classNames('b-modal__paranja', {
            'paranja_fade': this.props.withFade,
        });

        const className = classNames(this.props.className || 'PageModal', 'b-modal', {
            'disabled': this.props.disabled,
        });

        return (
            <div>
                {this.props.withFade ? (
                    <div className={paranjaClassName} onClick={::this.onClickCloseButton}/>
                ) : null}
                <div key="PageModal" className={className} style={this.props.style}>
                    <div className={classNames('b-modal__header', this.props.headerClassName)}>
                        {this.props.header.title ? (
                            <div
                                className={classNames('b-modal__name', this.props.titleClassName)}>{this.props.header.title}</div>
                        ) : null}
                        {this.props.header.rawTitle}
                        <div className="b-modal__menu">
                            {this.props.header && this.props.header.buttons}
                        </div>
                    </div>
                    <div className={classNames('b-modal__body', {
                        'b-modal__body_w-footer': this.props.buttons.length > 0,
                    })}>
                        <div className="b-modal__body-posit">
                            {this.props.children}
                        </div>
                    </div>
                    {(this.props.buttons.length > 0) ? (
                        <div className="b-modal__footer">
                            {this.props.buttons}
                        </div>
                    ) : null}
                </div>
            </div>
                
        );
    }

    onClickCloseButton() {
        if (!this.props.disableOutsideClick) {
            if (this.props.onClose) {
                this.props.onClose();
            }
        }
    }

}