import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import BarChart from 'components/modules/analytics/base-components/charts/BarChart';
import DashboardTable from 'components/modules/analytics/base-components/DashboardTable';
import './style.less';
import {connect} from "react-redux";
import {getUnits} from "store/reducers/organizational_units/units";
import {orderRunSummary} from "store/reducers/kiutr/orders/order_runs";
import _ from 'lodash';
import GlobalLoaderComponent from "components/ui/global-loader";
import moment from "moment";
import formats from "dictionaries/formats";
import {Text} from "recharts";

@propTypes({
    barChartFillColor: PropTypes.string
})

@defaultProps({
    barChartFillColor: ''
})

@connect(state => ({}), {getUnits, orderRunSummary})

export default class ConvoyRatingWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            data: []
        };

        this.metadata = {
            0: {field: 'rating', name: 'Рейтинг', sort: true},
            1: {field: 'shortName', name: 'Автоколонна', sort: true}
        };
    }

    componentWillMount() {
        this.loadUnits();
    }

    async loadUnits() {
        this.setState({
            isLoading: true,
        });

        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withComponent: 'children',
            },
        });

        if (response.isOk) {
            const units = response.payload.items;
            const summary = await this.getSummary(units);

            this.setState({
                isLoading: false,
                data: _.map(units, (unit) => {
                    const name = _.truncate(unit.name, {
                        length: 20,
                    });

                    return {
                        rating: _.get(_.find(summary, {unit_uuid: unit.uuid}), 'score'),
                        name,
                        shortName: name,
                    };
                })
            });
        } else {
            response.showErrors();
        }
    }

    async getSummary(units) {
        const response = await this.props.orderRunSummary({
            units: _.map(units, 'uuid'),
            date_from: moment().startOf('year').format(formats.DATE),
            date_to: moment().endOf('year').format(formats.DATE),
        });

        if (response.isOk) {
            return response.payload.items;
        } else {
            response.showErrors();
        }
    }

    render() {
        const loader = this.state.isLoading ? (<GlobalLoaderComponent/>) : null;

        return (
            <div className="convoy-rating-widget">
                {loader}

                <div className="chart-wrapper">
                    <BarChart data={this.state.data}
                              xDataKey="name"
                              yDataKey="rating"
                              yLabel={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle" angle={-90}>Баллы</Text>}
                              xName="Рейтинг"
                              color={this.props.barChartFillColor}
                    />
                </div>
                <div className="table-wrapper">
                    <DashboardTable data={this.state.data} metadata={this.metadata}/>
                </div>
            </div>
        );
    }
}