import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';
import _ from 'lodash';
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import 'leaflet-textpath';

const GET_ROUTES = 'GET_ROUTES';
const GET_STOP_POINTS = 'GET_STOP_POINTS';

const initialState = new Map({
    routes: [],
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_ROUTES:
            const items = _.map(_.filter(action.payload.items, (route) => !!route.geometry), (route) => {
                let coordinates = _.map(route.geometry.coordinates, item => {
                    return _.clone(item).reverse();
                });
                route.layer = new L.polyline(coordinates, {
                    color: '#FF0000',
                    opacity: 0.6,
                });
                route.layer.bindTooltip(route.title, {sticky: true});

                route.layer.setText('\u25B6          ', {
                    repeat: true,
                    offset: 5,
                    attributes: {
                        fill: '#FF0000',
                        'font-size': '12',
                    },
                });

                return route;
            });
            return state.set('routes', items);
    }

    return state;
}

export const getRoutes = (meta = {}) => async (dispatch) => {
    try {
        let response = await api.geo.getRoutesShort(meta);

        dispatch({
            type: GET_ROUTES,
            payload: {
                items: response.payload.items
            }
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getStopPoints = (meta = {}, ignoreCache = false) => async (dispatch, state) => {
    try {
        if (!meta.pagination) {
            meta.pagination = {
                page: 1,
                limit: 20,
            };
        }

        const response = await api.geo.getStopPoints(meta, ignoreCache);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRailroadCrossings = (meta = {}, ignoreCache = false) => async (dispatch, state) => {
    try {
        if (!meta.pagination) {
            meta.pagination = {
                page: 1,
                limit: 20,
            };
        }

        const response = await api.geo.getRailroadCrossings(meta, ignoreCache);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getStopPointsWithBounds = (boundingBox, zoom, source = 'inventarisation_active') => async (dispatch, state) => {
    try {
        const response = await api.geo.getStopPoints({
            filters: {
                withBoundingBox: boundingBox,
                withZoom: zoom,
                withSource: source,
            },
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createdByUuidList = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.geo.createdByUuidList(meta);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getEcpSignLogs = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.geo.getEcpSignLogs(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getVehicleIdleTimes = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.geo.getVehicleIdleTimes(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
