import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import 'react-color-picker/index.css';
import PageModal from 'components/ui/page-modal';
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import {Tabs, Tab, TabList, TabPanel} from 'react-tabs';
import {getLayer} from "../../store/reducers/user-map-objects/layer_editor";
import {getLayerObjects} from "../../store/reducers/user-map-objects/layer_list";
import LayerForm from './LayerForm';



class LayerEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uuid: null,
            isLoading: this.props.mode === 'edit',
            search: '',
        };
        this.fitOn = this.fitOn.bind(this);
    }

     componentDidMount() {
        if (this.props.selectedLayer) {
            this.props.getLayerObjects(this.props.selectedLayer);
        }
    }

    fitOn(objectUuid) {
        if (!this.refs.map) return;
        const layer = this.findLayer(objectUuid);
        if (layer) {
            this.refs.map.getWrappedInstance().fitBounds(layer.getBounds());
        }
    }

    changeSearch() {
        const search = this.refs.search.value;
        this.setState({
            search,
        });
    }

    render() {
        const {title, selectedLayer, isEditing} = this.props;
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.hideEditor}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{title: title, buttons}}
                className={classNames("profile-modal b-modal-edit")}
                onClose={this.props.hideEditor}
            >
                <Tabs>
                    <TabList>
                        <Tab>Созд/ред слоя</Tab>
                        <Tab>Список объектов</Tab>
                    </TabList>
                    <TabPanel>
                        <LayerForm selectedLayer={selectedLayer} isEditing={isEditing}/>
                    </TabPanel>
                    <TabPanel>
                        <div>
                            <div className="filtration__search">
                                <input ref="search" type="text" placeholder="Поиск" value={this.state.search} onChange={::this.changeSearch}/>
                                <input type="button" className="filtration__button" value="Найти"/>
                            </div>
                            {this.props.objects.map((object, index) =>
                                <div key={object.uuid}>
                                    <p style={{cursor: 'pointer'}} onClick={() => this.props.fitOn(object.uuid)}>{object.title}</p>
                                </div>
                            )}
                        </div>
                    </TabPanel>
                </Tabs>
            </PageModal>
        )
    }
}

const mapStateToProps = (state) => ({
    objects: state.user_map_objects.layer_list.get('objects'),
});

const mapDispatchToProps = dispatch => ({
   getLayerObjects: (layerUuid) => dispatch(getLayerObjects(layerUuid)),
});


export default connect(mapStateToProps, mapDispatchToProps)(LayerEditor);