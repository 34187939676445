import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';
import './warning-modal.less';

@propTypes({
    goToAnotherPage: PropTypes.func,
    closeModal: PropTypes.func,
    isNeedBackground: PropTypes.bool,
})

export default class WarningModal extends Component {

    renderContent() {
        return (
            <div className='warning-modal__content'>
                <div className='warning-modal__inner'>
                    <div>
                        Вы уверены, что хотите покинуть форму? Введенные данные будут потеряны
                    </div>
                    <div className='warning-modal__buttons'>
                        <button className='b-button b-button_red b-button_size_md' onClick={this.props.goToAnotherPage}>
                            Покинуть
                        </button>
                        <button className='b-button b-button_red b-button_size_md' onClick={this.props.closeModal}>
                            Отмена
                        </button>
                    </div>
                    
                </div>
                
            </div>
        )
        
    }

    render() {
        if(this.props.isNeedBackground) {
            return (
                <div className='warning-modal__wrap'>
                    {this.renderContent()}
                </div>
                
            )
        } else {
            return(
                this.renderContent()
            )  
        }      
    }
}

