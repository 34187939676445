import moment, { utc } from "moment";

export const createUrlWithQueries = (pathname, query, lastQuery, queryValue) => {
    let url = pathname;
    if (Object.keys(query).length) {
        const queryKeys = query ? Object.keys(query) : [];
        queryKeys.forEach((key, index) => {
            if (key !== lastQuery && index === 0) {
                url = url + `?${key}=${query[key]}`
            }
            if (key !== lastQuery && index !== 0) {
                url = url + `&${key}=${query[key]}`
            }
        });
        if (queryKeys && queryKeys.length && queryValue) {
            url = url + `&${lastQuery}=${queryValue}`
        }
    } else {
        if (queryValue) {
            url = url + `?${lastQuery}=${queryValue}`
        }
    }
    return url;
}

export const computeTimeZoneOffset = (visittime) => {
    if (visittime) {
        const time = moment.unix(visittime);
        let timeZoneOffset;
        if (window.RNIS_SETTINGS.TIMEZONE_OFFSET[0].offset) {
            timeZoneOffset = window.RNIS_SETTINGS.TIMEZONE_OFFSET[0].offset;
        } else {
            timeZoneOffset = parseInt((moment().utcOffset()) / 60);
        }
        return moment(time).utc().utcOffset(timeZoneOffset).format("HH:mm")
    } else {
        if (window.RNIS_SETTINGS.TIMEZONE_OFFSET[0].offset) {
            return window.RNIS_SETTINGS.TIMEZONE_OFFSET[0].offset;
        } else {
            return parseInt((moment().utcOffset()) / 60);
        }
    }
}