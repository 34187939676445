import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import { connect } from 'react-redux';
import debounce from 'throttle-debounce/debounce';
import _ from 'lodash';
import { List } from 'immutable';
import Column from 'components/ui/column';
import BaseTableWithEditorComponent from 'components/base/base_table_with_editor';
import moment from 'moment';
import formats from 'dictionaries/formats';
import { getEntityNames } from 'store/reducers/system';
import { EntityList } from 'helpers/entity';
import systems from 'dictionaries/systems';
import ReactDOMServer from 'react-dom/server';
import { State } from 'components/ui/state';
import ContextTooltip from 'components/ui/context-tooltip';
import { ExecutionStatuses, ProcessingStatuses, ProvisionStatuses } from 'dictionaries/kiutr';
import {
	cloneOrder,
	deleteOrder,
	exportOrderGet,
	exportOrderList,
	exportOrders,
	exportOrdersExcel,
	generateOrder,
	getOrders,
	getUniqueVehicles,
	getOrdersUniqueList
} from 'store/reducers/kiutr/orders/orders';
import Datepicker from 'components/ui/form/datepicker';
import SearchField from 'components/ui/search-field';
import IconButton from 'components/ui/icon-button';
import { getUnits } from 'store/reducers/organizational_units/units';
import * as alerts from 'helpers/alerts';
import './index.less';
import OrderFormSelect from './OrderFormSelect/index';
import * as store from 'utils/storage';
import $ from 'jquery';
import { makeResponse } from 'helpers/response';
import { api, mapDatatablesRequestToMeta } from 'helpers/api';
import currentUser from 'helpers/current-user';
import download from 'downloadjs';
import { decodeUnicode } from 'utils/index';
import LoaderComponent from 'components/ui/loader';
import Popup from 'components/ui/popup';
import TableColumnSelectFields from 'components/ui/Table/Column/SelectFields/TableColumnSelectFields';
import { component_mapper } from 'helpers/component_mapper';
import { getVehicleList } from 'store/reducers/vehicles/vehicles';
import { getUsers } from 'store/reducers/staffing/staffing';
import { User } from 'helpers/user';
import DuplicationMatrixModal from 'components/modules/kiutr/DuplicationMatrix/DuplicationMatrixModal';
import { isRouteA } from 'helpers/route';
import ExportTableButton from 'components/ui/Export/ExportTableButton';
import { getDictionaryList } from 'store/reducers/dictionaries/dictionary';
import {
	getOrdersUniqueCounts,
	getOrdersUniqueIsLoading,
	getOrdersUniqueIsLoaded
} from '../../../../store/reducers/kiutr/orders/selectors';

import { getRoutes } from "store/reducers/routes/route_editor";

@connect(
	(state) => ({
		all: state,
		uniqueCounts: getOrdersUniqueCounts(state),
		uniqueListIsLoading: getOrdersUniqueIsLoading(state),
		uniqueListIsLoaded: getOrdersUniqueIsLoaded(state)
	}),
	(dispatch) => ({
		getOrders: (meta) => dispatch(getOrders(meta)),
		getUniqueVehicles: (meta) => dispatch(getUniqueVehicles(meta)),
		getEntityNames: (meta) => dispatch(getEntityNames(meta)),
		getUnits: (meta) => dispatch(getUnits(meta)),
		generateOrder: (meta) => dispatch(generateOrder(meta)),
		cloneOrder: (meta) => dispatch(cloneOrder(meta)),
		deleteOrder: (meta) => dispatch(deleteOrder(meta)),
		exportOrders: (meta) => dispatch(exportOrders(meta)),
		exportOrderGet: (meta) => dispatch(exportOrderGet(meta)),
		exportOrderList: (meta) => dispatch(exportOrderList(meta)),
		getVehicleList: (meta) => dispatch(getVehicleList(meta)),
		getUsers: (meta) => dispatch(getUsers(meta)),
		getDictionaryList: (meta) => dispatch(getDictionaryList(meta)),
		exportOrdersExcel: (meta) => dispatch(exportOrdersExcel(meta)),
		onGetOrdersUniqueList: (meta) => dispatch(getOrdersUniqueList(meta)),
		getRoutes: (meta) => dispatch(getRoutes(meta)),
	})
)
export default class KiutrOrderListComponent extends BaseTableWithEditorComponent {
	defaultOrder = [ [ 3, 'desc' ] ];

	exportRowsCount = 10000;

	onRowCreate = (row, data) => {
		if (!this.isOpened(data)) {
			$(row).addClass('bold');
		}
	};

	getTitle() {
		return `${systems[this.props.params.component]} → План-наряды`;
	}

	getBaseUrl() {
		return `/${this.props.params.component}/orders`;
	}

	async componentDidMount() {
		this.forceUpdate();
	}

	async componentWillUpdate(props, state) {
		super.componentWillUpdate(props, state);

		if (component_mapper(props.params.component) !== state.component) {
			await this.setState({
				component: component_mapper(props.params.component),
				columns: List(this.getColumns(component_mapper(props.params.component))),
				duplicationMatrixActive: isRouteA(props, 'matrices')
			});
			state.component && this.reload();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.uniqueListIsLoaded !== prevProps.uniqueListIsLoaded) {
			this.setState({
				uniqueCountsLoaded: this.props.uniqueListIsLoaded,
			});
		}
	}

	constructor(props) {
		super(props);

		Object.assign(this.state, {
			component: null,
			from: moment().startOf('day').format(formats.DATETIME_API),
			to: moment().add(1, 'day').startOf('day').format(formats.DATETIME_API),
			units: [],
			showTableFilters: true,
			isExporting: false,
			exportingErrorCount: 0,
			exportFilters: null,
			duplicationMatrixActive: this.props.location.query.matrices,
			uniqueBlockLoaded: false,
			uniqueCountsLoaded: this.props.uniqueListIsLoaded,
			uniqueBlockStartLoaded: false,
			totalOrders: 0
		});

		this.loadUnits();
	}

	async loadUnits() {
		const response = await this.props.getUnits({
			pagination: {
				page: 1,
				limit: 1000
			},
			filters: {
				withComponent: component_mapper(this.props.params.component)
			}
		});

		if (response.isOk) {
			this.setState({
				units: _.map(response.payload.items, (unit) => ({
					value: unit.uuid,
					label: unit.name
				}))
			});
		} else {
			response.showErrors();
		}
	}

	getEditor() {
		return (
			<OrderFormSelect
				{...this.props}
				key="editor"
				onClose={::this.closeEditor}
				onSubmit={::this.submitEditor}
				mode={this.state.editorUuid ? 'edit' : 'add'}
				uuid={this.state.editorUuid}
			/>
		);
	}

	isOpened(item) {
		return item.is_opened;
	}

	getColumns() {

		return this.prepareColumns([

			new Column('Номер').fromField('number'),

			new Column('Организатор перевозок')
				.fromField('unit_uuid')
				.withDrawer((item) => item.unit_uuid && this.state.related.get(item.unit_uuid))
				.withAsyncFilter('withUnits', async (search) => {
					const response = await this.props.getUnits({
						search,
						filters: {
							withComponent: component_mapper(this.props.params.component)
						},
						order: {
							column: 'name',
							direction: 'asc'
						},
						pagination: {
							page: 1,
							limit: 30
						},
						response_data: [ 'items/uuid', 'items/name' ]
					});
					if (response.isOk) {
						return response.payload.items;
					}
					return [];
				}),

			new Column('Перевозчик')
				.fromField('carrier_uuid')
				.withDrawer((item) => item.carrier_uuid && this.state.related.get(item.carrier_uuid))
				.withAsyncFilter('withCarriers', async (search) => {
					const response = await this.props.getUnits({
						search,
						filters: {
							withComponent: component_mapper(this.props.params.component)
						},
						order: {
							column: 'name',
							direction: 'asc'
						},
						pagination: {
							page: 1,
							limit: 30
						},
						response_data: [ 'items/uuid', 'items/name' ]
					});
					if (response.isOk) {
						return response.payload.items;
					}
					return [];
				}),

			new Column('Дата')
				.fromField('date')
				.withDateFilter()
				.withDrawer((item) => item.date && moment(item.date).format(formats.DATE)),

			new Column('Дата изменения')
				.fromField('updated_at')
				.withDateFilter()
				.withDrawer((item) => item.updated_at && moment(item.updated_at).format(formats.DATETIME_SHORT)),

			new Column('№ выхода').fromField('turn'),

			new Column('Маршрут')
				.fromField('route_name')
				.withDrawer((item) => _.uniq(_.map(_.flatten(_.map(item.shifts, 'runs')), 'route_name')).join('<br/>')),

			...(window.RNIS_SETTINGS.CITY_MO ? ([
				new Column('№ маршрута')
					.fromField('route_number')
					.withDrawer((item) => _.uniq(_.map(_.flatten(_.map(item.shifts, 'runs')), 'route_number')).join('<br/>'))
					.withMultiFilter(this.getDataForMultiFilter({
						simple: 'withRouteNumber',
						async: 'withRouteNumbers'
					}, async (search) => {
						const response = await this.loadListNumbers(
							search,
							'withRouteNumber',
							'items/number',
							this.props.getRoutes);
						if (response.isOk) {
							return _.map(response.payload.items, item => ({
								uuid: item.number,
								name: item.number
							}));
						}
						return [];
					}))
					.withDebounce(true),
			]) : [
				new Column('№ маршрута')
					.fromField('route_number')
					.withDrawer((item) => _.uniq(_.map(_.flatten(_.map(item.shifts, 'runs')), 'route_number')).join('<br/>')),
			]),

			...(window.RNIS_SETTINGS.CITY_MO
				? [
					new Column('Вид маршрута')
						.fromField('route_kind_uuid')
						.withDrawer((item) => item.route_kind_uuid && this.state.related.get(item.route_kind_uuid))
						.denyColumnFilter()
						.withFilter('withRouteKind', async () => {
							const response = await this.props.getDictionaryList('route_kinds');
							if (response.isOk) {
								return response.payload.documents;
							}
							return [];
						})
				]
				: []),

			...(window.RNIS_SETTINGS.CITY_MO ? ([
				new Column('Рег. № маршрута')
					.fromField('route_registration_number')
					.withDrawer((item) =>
						_.uniq(_.map(_.flatten(_.map(item.shifts, 'runs')), 'route_registration_number')).join('<br/>')
					)
					.withMultiFilter(this.getDataForMultiFilter({
						simple: 'withRouteRegNumber',
						async: 'withRouteRegNumbers'
					}, async (search) => {
						const response = await this.loadListNumbers(
							search,
							'withRouteRegNumber',
							'items/registration_number',
							this.props.getRoutes);
						if (response.isOk) {
							return _.map(response.payload.items, item => ({
								uuid: item.registration_number,
								name: item.registration_number
							}));
						}
						return [];
					}))
					.withDebounce(true),

			]) : [
				new Column('Рег. № маршрута')
					.fromField('route_registration_number')
					.withDrawer((item) =>
						_.uniq(_.map(_.flatten(_.map(item.shifts, 'runs')), 'route_registration_number')).join('<br/>')
					)
			]),

			new Column('ТС в наряде')
				.fromField('vehicles')
				.denyOrder()
				.denyColumnFilter()
				.withDrawer((item) => {
					return decodeUnicode(
						_.uniq(
							_.filter(
								_.map(
									_.flatten(_.map((item.order_recalc ? item.order_recalc.shifts : item.shifts) || [], 'runs')),
									'vehicle_state_number'
								)
							)
						).join('<br/>')
					);
				})
				.withAsyncFilter('withVehicles', async (search) => {
					const response = await this.props.getVehicleList({
						search,
						filters: {
							withComponent: component_mapper(this.props.params.component)
						},
						order: {
							column: 'state_number',
							direction: 'asc'
						},
						pagination: {
							page: 1,
							limit: 30
						}
					});
					if (response.isOk) {
						return _.map(response.payload.items, (item) => ({
							uuid: item.uuid,
							name: item.state_number
						}));
					}
					return [];
				}),

			new Column('Водители в наряде')
				.fromField('drivers')
				.denyOrder()
				.denyColumnFilter()
				.withDrawer((item) => {
					return decodeUnicode(
						_.uniq(
							_.filter(
								_.map(
									_.flatten(_.map((item.order_recalc ? item.order_recalc.shifts : item.shifts) || [], 'runs')),
									'driver_name'
								)
							)
						).join('<br/>')
					);
				})
				.withAsyncFilter('withDrivers', async (search) => {
					const response = await this.props.getUsers({
						search,
						filters: {
							withComponent: component_mapper(this.props.params.component),
							withPositionTypes: [ 'driver' ]
						},
						pagination: {
							page: 1,
							limit: 30
						}
					});
					if (response.isOk) {
						return _.sortBy(
							_.map(response.payload.items, (user) => ({
								uuid: user.uuid,
								name: new User(user).getFullName()
							})),
							[ 'name' ]
						);
					}
					return [];
				}),

			...(window.RNIS_SETTINGS.CITY_MO
				? [
					new Column('Тип вместимости').fromField('capacity_type_uuids').denyColumnFilter().withDrawer((item) => {
						return item.capacity_type_uuids
							? item.capacity_type_uuids
								.map(
									(capacity_type_uuid) =>
										this.state.related.get(capacity_type_uuid) ? this.state.related.get(capacity_type_uuid) : '-'
								)
								.join('<br/>')
							: '';
					})
				]
				: []),
			//.denyColumnFilter())] : []),

			/* ...(window.RNIS_SETTINGS.CITY_MO ? [(new Column('Экологический класс ')
                    .fromField('environmental_class_uuid')
                    .withDrawer(item => _.uniq(_.map(_.flatten(_.map(item.shifts, 'runs')), 'environmental_class_uuid')).join('<br/>'))
                    .denyColumnFilter()
            )] : []),

            ...(window.RNIS_SETTINGS.CITY_MO ? [(new Column('Низкий уровень пола')
                .withAlign('center')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                    positive={(item => _.uniq(_.map(_.flatten(_.map(item.shifts, 'runs')), 'is_low_floor_level')).join('<br/>'))}/>)))] : []),

            ...(window.RNIS_SETTINGS.CITY_MO ? [(new Column('Климатическая установка')
                .withAlign('center')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                    positive={(item => _.uniq(_.map(_.flatten(_.map(item.shifts, 'runs')), 'is_air_conditioning_installation')).join('<br/>'))}/>)))] : []),

            ...(window.RNIS_SETTINGS.CITY_MO ? [(new Column('Электронное табло')
                .withAlign('center')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                    positive={(item => _.uniq(_.map(_.flatten(_.map(item.shifts, 'runs')), 'is_electronic_scoreboard')).join('<br/>'))}/>)))] : []),*/

			...(window.RNIS_SETTINGS.CITY_MO
				? [
					new Column('Год выпуска ТС')
						.fromField('release_years')
						.denyColumnFilter()
						.withDrawer((item) => (item.release_years ? item.release_years.join('<br/>') : null))
				]
				: []),

			/* ...(window.RNIS_SETTINGS.CITY_MO ? [(new Column('Комплекс видеонаблюдения')
                .withAlign('center')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                    positive={(item => _.uniq(_.map(_.flatten(_.map(item.shifts, 'runs')), 'is_audio_video_fixation')).join('<br/>'))}/>)))] : []),

            ...(window.RNIS_SETTINGS.CITY_MO ? [(new Column('Класс ТС')
                .fromField('capacity_type_uuid')
                .withDrawer(item => item.capacity_type_uuid ? this.state.related.get(item.capacity_type_uuid) : '<code>-</code>')
                .denyColumnFilter())] : []),

            ...(window.RNIS_SETTINGS.CITY_MO ? [(new Column('Марка ТС')
                .fromField('vehicle_mark_uuid')
                .withDrawer(item => item.vehicle_mark_uuid ? this.state.related.get(item.vehicle_mark_uuid) : '<code>-</code>')
                .denyColumnFilter())] : []),

            ...(window.RNIS_SETTINGS.CITY_MO ? [(new Column('Модель ТС')
                .fromField('vehicle_model_uuid')
                .withDrawer(item => item.vehicle_model_uuid ? this.state.related.get(item.vehicle_model_uuid) : '<code>-</code>')
                .denyColumnFilter())] : []),
*/
			/*...(window.RNIS_SETTINGS.CITY_MO ? [(new Column('Вместимость')
                .fromField('capacity'))] : []),*/

			new Column('Статус обработки')
				.fromField('processing_status')
				.withDrawer((item) => item.processing_status && ProcessingStatuses[item.processing_status])
				.withFilter('withProcessingStatuses', () => {
					return _.map(ProcessingStatuses, (title, key) => ({
						uuid: key,
						name: title
					}));
				}),

			new Column('Статус обеспечения')
				.fromField('provision_status')
				.withDrawer((item) => {
					if (item.provision_status) {
						const className = {
							full: 'guarantee',
							partial: 'partly-guarantee',
							none: 'no-guarantee'
						}[item.provision_status];
						return (
							'<span class="guarantees ' +
							className +
							'">' +
							ProvisionStatuses[item.provision_status] +
							': ' +
							item.provision_status_data +
							'</span>'
						);
					}
					return null;
				})
				.withFilter('withProvisionStatuses', () => {
					return _.map(ProvisionStatuses, (title, key) => ({
						uuid: key,
						name: title
					}));
				}),

			new Column('Статус выполнения')
				.fromField('execution_status')
				.withDrawer((item) => item.execution_status && ExecutionStatuses[item.execution_status])
				.withFilter('withExecutionStatuses', () => {
					return _.map(ExecutionStatuses, (title, key) => ({
						uuid: key,
						name: title
					}));
				}),

			new Column('Разбирательство')
				.fromField('is_proceeding')
				.withDrawer((item) => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_proceeding}/>))
				.denyColumnFilter(),

			new Column('Дополнительный выход')
				.fromField('is_additional')
				.withDrawer((item) => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_additional}/>))
				.denyColumnFilter(),

			new Column('Расчет завершен')
				.fromField('is_calc_ended')
				.withDrawer((item) => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_calc_ended}/>))
				.denyColumnFilter()
		]);
	}

	async deleteItem(data) {
		return await this.props.deleteOrder(data);
	}

	async loadData(meta) {
		_.set(meta, 'filters.withComponent', this.state.component);
		_.set(meta, 'filters.short', true);
		_.set(meta, 'response_data', [
			'items/uuid',
			'items/number',
			'items/unit_uuid',
			'items/carrier_uuid',
			'items/date',
			'items/updated_at',
			'items/turn',
			'items/is_opened',
			'items/shifts/runs/route_name',
			'items/shifts/runs/route_number',
			'items/shifts/runs/route_registration_number',
			'items/shifts/runs/vehicle_state_number',
			'items/shifts/runs/driver_name',
			'items/order_recalc/shifts/runs/vehicle_state_number',
			'items/order_recalc/shifts/runs/driver_name',
			'items/processing_status',
			'items/provision_status',
			'items/provision_status_data',
			'items/execution_status',
			'items/is_proceeding',
			'items/is_additional',
			'items/is_calc_ended'
		]);

		if (window.RNIS_SETTINGS.CITY_MO) {
			meta.response_data.push(
				'items/capacity_type_uuids',
				'items/route_kind_uuid',
				'items/shifts/runs/capacity',
				'items/release_years'
			);
		}

		meta.filters.withPeriod = [ this.state.from, this.state.to ];
		if (window.RNIS_SETTINGS.show_unique_counters) {
			if (this.state.exportFilters) {
				if (
					!_.isEqual(this.state.exportFilters.filters, meta.filters) ||
					this.state.exportFilters.search !== meta.search ||
					!_.isEqual(this.state.exportFilters.column_search, meta.column_search)
				) {
					// if filters change request for uniques
					//this.props.onGetOrdersUniqueList(meta);
					//this.loadUniqueVehicles(meta);
				}
			}
		}

		if(this.state.exportFilters){
			console.log()
			if (JSON.stringify(meta.filters) !== JSON.stringify(this.state.exportFilters.filters)) {
				this.setState({
					uniqueBlockLoaded: false,
					uniqueCountsLoaded: false
				});
			}
		}

		this.setState({
			exportFilters: meta,
		});
		const response = await this.props.getOrders(meta);
		if (!!response.data && !!response?.data?.headers?.meta?.pagination?.total) {
			this.setState({
				totalOrders: response.data.headers.meta.pagination.total
			})
		}

		return response
	}

	async loadUniqueVehicles(meta) {
		_.set(meta, 'filters.withComponent', this.state.component);
		_.set(meta, 'filters.short', true);
		_.set(meta, 'response_data', [
			'items/uuid',
			'items/number',
			'items/unit_uuid',
			'items/carrier_uuid',
			'items/date',
			'items/updated_at',
			'items/turn',
			'items/is_opened',
			'items/shifts/runs/route_name',
			'items/shifts/runs/route_number',
			'items/shifts/runs/route_registration_number',
			'items/shifts/runs/vehicle_state_number',
			'items/shifts/runs/driver_name',
			'items/order_recalc/shifts/runs/vehicle_state_number',
			'items/order_recalc/shifts/runs/driver_name',
			'items/processing_status',
			'items/provision_status',
			'items/provision_status_data',
			'items/execution_status',
			'items/is_proceeding',
			'items/is_additional',
			'items/is_calc_ended'
		]);

		if (window.RNIS_SETTINGS.CITY_MO) {
			meta.response_data.push(
				'items/capacity_type_uuids',
				'items/route_kind_uuid',
				'items/shifts/runs/capacity',
				'items/release_years',
				'unique_vehicles',
				'unique_orders'
			);
		}

		meta.filters.withPeriod = [ this.state.from, this.state.to ];
		const response = await this.props.getUniqueVehicles(meta);
		if (response.isOk) {
			this.setState({ uniqueVehicles: response.payload.unique_vehicles });
			return response.payload.unique_vehicles;
		} else {
			this.setState({ uniqueVehicles: '' });
			return '';
		}
	}

	lastPeriod = {};

	checkCache(request) {
		const period = [ this.state.from, this.state.to ];
		const result = JSON.stringify(period) === JSON.stringify(this.lastPeriod);
		this.lastPeriod = period;
		return super.checkCache(request) && result;
	}

	async loadRelatedEntities(json, drawCallback) {
		const result = json.data;

		const units = _.map(_.uniq(_.filter(_.map(result, 'unit_uuid'))), (uuid) => ({
			class: 'App\\Model\\Unit',
			uuid: uuid,
			source: 'organizational_units'
		}));

		let capacities_temp = _.map(_.filter(_.map(result, 'capacity_type_uuids')), (uuid) => ({
			class: 'App\\Dictionaries\\Vehicles\\VehicleCapacityType\\Model',
			uuid: uuid,
			source: 'dictionary'
		}));

		const routeKinds = _.map(_.filter(_.map(result, 'route_kind_uuid')), (uuid) => ({
			class: 'App\\Dictionaries\\Route\\RouteKinds\\Model',
			uuid: uuid,
			source: 'dictionary'
		}));

		const environmentalClasses = _.map(_.uniq(_.filter(_.map(result, 'environmental_class_uuid'))), (uuid) => ({
			class: 'App\\Dictionaries\\Vehicles\\VehicleEnvironmentalClass\\Model',
			uuid: uuid,
			source: 'dictionary'
		}));

		const carriers = _.map(_.uniq(_.filter(_.map(result, 'carrier_uuid'))), (uuid) => ({
			class: 'App\\Model\\Unit',
			uuid: uuid,
			source: 'organizational_units'
		}));

		let capacities = [];
		if (capacities_temp.length) {
			capacities = capacities_temp.map((c) =>
				c.uuid.map((u) => ({
					class: 'App\\Dictionaries\\Vehicles\\VehicleCapacityType\\Model',
					uuid: u,
					source: 'dictionary'
				}))
			);
		}

		const response = await this.props.getEntityNames(
			_.concat(units, carriers, environmentalClasses, _.flatten(capacities), routeKinds)
		);

		if (response.isOk) {
			this.state.related.add(response);
			drawCallback(json);
		}
	}

	diapasonFilter() {
		return (
			<div key="diapason">
				<div className="top-menu__label">Выбор диапазона:</div>
				<Datepicker style="dark" value={this.state.from} onChange={::this.fromChange}/>
				<Datepicker style="dark" value={this.state.to} onChange={::this.toChange}/>
			</div>
		);
	}

	async fromChange({ target: { value } }) {
		// коррекция ввода даты, если прописана в settings.json
		if (currentUser.user.free_user_settings.shortCalendar) {
			let momentValue = moment(value);
			let maxAfter = moment(new Date()).add(currentUser.user.free_user_settings.shortCalendar.MAXAFTER, 'days');
			let minBefore = moment(new Date()).add(-currentUser.user.free_user_settings.shortCalendar.MINBEFORE, 'days');
			// если дата меньше требуемой в настройках
			if (momentValue.isBefore(minBefore)) {
				// приведи ее к сегодняшней дате
				momentValue = minBefore;
			}
			// если больше чем требуемая в настройках
			if (momentValue.isAfter(maxAfter)) {
				momentValue = maxAfter;
			}
			// приводим к необходимому формату
			value = momentValue.format(formats.T1DATE);
		}

		value = moment(value).format(formats.DATETIME_API);
		await this.setState({
			from: value,
			uniqueBlockLoaded: false,
			uniqueCountsLoaded: false
		});
		this.reload();
	}

	async toChange({ target: { value } }) {
		if (currentUser.user.free_user_settings.shortCalendar) {
			let momentValue = moment(value);
			let maxAfter = moment(new Date()).add(currentUser.user.free_user_settings.shortCalendar.MAXAFTER, 'days');
			let minBefore = moment(new Date()).add(-currentUser.user.free_user_settings.shortCalendar.MINBEFORE, 'days');
			// если дата больше требуемой в настройках
			if (momentValue.isAfter(maxAfter)) {
				// приведи ее к сегодняшней дате
				momentValue = maxAfter;
			}
			// если больше требуемая в настройках
			if (momentValue.isBefore(minBefore)) {
				momentValue = minBefore;
			}
			// приводим к необходимому формату
			value = momentValue.format(formats.T1DATE);
		}

		value = moment(value).format(formats.DATETIME_API);
		await this.setState({
			to: value,
			from: moment(this.state.from).isAfter(moment(value)) ? value : this.state.from,
			uniqueBlockLoaded: false,
			uniqueCountsLoaded: false
		});
		this.reload();
	}

	getCreateButton() {
		return null;
	}

	showDuplicationMatrix() {
		this.setState({
			duplicationMatrixActive: true
		});
	}

	hideDuplicationMatrix() {
		this.setState({
			duplicationMatrixActive: false
		});
	}

	renderHeaderActions() {
		return [
			this.diapasonFilter(),
			this.state.component === 'kiutr' ? (
				<ContextTooltip
					key="orders.duplication_matrix"
					code="orders.duplication_matrix"
					default="Матрицы дублирования"
					position="left"
				>
					<IconButton icon="system-diagnostics" onClick={::this.showDuplicationMatrix}/>
				</ContextTooltip>
			) : null,
			currentUser.can('com.rnis.geo.order', 'delete') ? super.renderHeaderActions() : null
		];
	}

	renderHeaderUniqueBlock = () => {
		const uniqueVehicles = this.state.uniqueVehicles
			? this.state.uniqueVehicles
			: this.state.uniqueBlockStartLoaded ?
				'Загрузка...'
				: 'Нет данных'

		const uniqueCounts = this.props.uniqueCounts
			? this.props.uniqueCounts
			: this.props.uniqueListIsLoading ?
				'Загрузка...'
				: 'Нет данных'

		let text = `Уникальных ТС: ${uniqueVehicles}
					Уникальных выходов: ${uniqueCounts}`
		if (this.state.totalOrders > 60000) {
			text = 'Уменьшите кол-во ПН'
		}

		return <ContextTooltip
			key="order.counter"
			code="order.counter"
			default={text}
		>
			<div onMouseOver={() => this.loadInfo()}
				 className="header_counter">Показать счетчик
			</div>
		</ContextTooltip>

	}

	loadInfo = debounce(500, () => {
		if (this.state.totalOrders > 60000 || this.state.uniqueBlockStartLoaded || this.props.uniqueListIsLoading) {
			return false
		}

		this.setState({
			uniqueBlockStartLoaded: true,
		}, async () => {
			const { filters } = this.state.exportFilters
			if (!this.state.uniqueCountsLoaded) {
				this.props.onGetOrdersUniqueList({
					response_data: [ 'unique_orders' ],
					filters
				});
			}

			if (!this.state.uniqueBlockLoaded) {
				this.setState({
					uniqueVehicles: '',
				})
				const response = await this.props.getUniqueVehicles(
					{
						response_data: [ 'unique_vehicles' ],
						filters
					}
				);
				if (response.isOk) {
					this.setState({
						uniqueVehicles: response.payload.unique_vehicles,
						uniqueBlockLoaded: true,

					});
				} else {
					this.setState({
						uniqueVehicles: '',
						uniqueBlockLoaded: false,
					});
				}
			}
			this.setState({
				uniqueBlockStartLoaded: false,
			})
		});
	})


	renderHeaderContents() {
		return _.concat(
			window.RNIS_SETTINGS.show_unique_counters && (
				this.renderHeaderUniqueBlock()
			),
			super.renderHeaderContents(),
			[
				/*<ContextTooltip key="orders.generate" code="orders.generate" default="Сформировать план-наряды">
                <IconButton icon="plus" onClick={::this.generateOrders}/>
            </ContextTooltip>,*/
				currentUser.user.free_user_settings.ordersByDay === 'show' ? (
					<ContextTooltip key="orders.by-day" code="orders.by-day" default="Суточная разнарядка">
						<IconButton icon="outfit" onClick={::this.gotoByDay}/>
					</ContextTooltip>
				) : (
					''
				),
				<ContextTooltip key="order.clone" code="order.clone" default="Дополнительный выход">
					<IconButton icon="clone" onClick={::this.cloneOrder}/>
				</ContextTooltip>,
				this.state.exportExcelLoading ? (
					<div className="excelLoader">
						<LoaderComponent color="red"/>
					</div>
				) : (
					<ContextTooltip key="base-table-list.export" code="base-table-list.export"
									default="Экспорт в Excel">
						<IconButton icon="export" onClick={::this.exportOrdersToXlsSimple}/>
					</ContextTooltip>
				)
				/*this.exportToXlsButton(true),
            <ExportTableButton
                key="export-log"
                ref="export"
                source="geo"
                entity="orders"
                forceUpdate={::this.forceUpdate}
            />,*/
			]
		);
	}

	async exportOrdersToXlsSimple() {
		this.setState({
			exportExcelLoading: true
		});
		const response = await this.props.exportOrdersExcel(this.state.exportFilters);
		if (response.status !== 200) {
			alerts.error('Ошибка. Не удалось сделать экспорт отчета');
			this.setState({
				exportExcelLoading: false
			});
		} else {
			this.setState({
				exportExcelLoading: false
			});
		}
	}

	async exportLogsToggle() {
		await this.setState({
			exportLogsVisible: !this.state.exportLogsVisible
		});

		if (this.state.exportLogsVisible) {
			this.loadExports();
		}
	}

	async cloneOrder() {
		if (!this.refs.table) return;

		const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
		if (selected) {
			alerts.askSelect(
				'Выберите день для создания:',
				[
					{
						value: moment().format(formats.DATE_API),
						label: `Сегодня, ${moment().format(formats.DATE)}`
					},
					{
						value: moment().add(1, 'day').format(formats.DATE_API),
						label: `Завтра, ${moment().add(1, 'day').format(formats.DATE)}`
					}
				],
				async (date) => {
					const response = await this.props.cloneOrder({
						uuid: selected.uuid,
						date
					});

					if (response.isOk) {
						alerts.success('Дополнительный выход создан');
						this.reload();
					} else {
						response.showErrors();
					}
				}
			);
		} else {
			alerts.alert('План-наряд не выбран');
		}
	}

	async generateOrders() {
		alerts.askSelect('Выберите организатора перевозок:', this.state.units, async (unitUuid) => {
			const response = await this.props.generateOrder({
				unit_uuid: unitUuid,
				force: false
			});

			if (response.isOk) {
				if (response.payload.ask_force) {
					alerts.prompt(
						'Генерируемые наряды уже имеются в системе. Заменить?',
						'',
						async () => {
							const response2 = await this.props.generateOrder({
								unit_uuid: unitUuid,
								force: true
							});
							if (response2.isOk) {
								alerts.success('План-наряды сгенерированы');
								this.reload();
							} else {
								response2.showErrors();
							}
						},
						'Заменить'
					);
				} else if (response.payload.message) {
					alerts.alert(response.payload.message);
				} else {
					alerts.success('План-наряды сгенерированы');
					this.reload();
				}
			} else {
				response.showErrors();
			}
		});
	}

	async gotoByDay() {
		const response = await makeResponse(() => {
			return api.auth.getUserPermissions(null, 'com.rnis.geo.order', 'read');
		});
		if (response.isOk) {
			const uuids = response.payload.units;
			const units = await this.loadUnitsByUuids(uuids);
			if (units.length === 1) {
				this.props.router.push(`/kiutr/orders-by-day/${_.first(units).value}`);
			} else {
				alerts.askSelect(
					'Выберите перевозчика',
					units,
					(unitUuid) => {
						this.props.router.push(`/kiutr/orders-by-day/${unitUuid}`);
					},
					'Выбрать'
				);
			}
		} else {
			response.showErrors();
		}
	}

	async loadUnitsByUuids(uuids) {
		let filters = {
			withComponent: component_mapper(this.props.params.component)
		};
		if (uuids) {
			filters.withUuid = uuids;
		}
		const response = await this.props.getUnits({
			pagination: {
				page: 1,
				limit: 1000
			},
			filters
		});

		if (response.isOk) {
			return _.sortBy(
				_.map(response.payload.items, (unit) => ({
					value: unit.uuid,
					label: unit.name
				})),
				'label'
			);
		} else {
			response.showErrors();
		}
	}

	async exportToXlsReal(page) {
		if (!this.refs.export) {
			return;
		}

		let meta = mapDatatablesRequestToMeta(
			this.lastRequest,
			this.state.columns,
			this.state.showTableFilters,
			this.state.showDeleted
		);
		_.set(meta, 'filters.withComponent', this.state.component);
		_.set(meta, 'filters.short', true);
		_.set(meta, 'pagination', {
			page: 1,
			limit: this.exportRowsCount
		});

		meta.filters.withPeriod = [ this.state.from, this.state.to ];

		this.refs.export.getWrappedInstance().exportToXls(meta);
	}

	exportToXlsButton(forceRender = false) {
		if (!forceRender) {
			return;
		}
		if (this.refs.export && this.refs.export.getWrappedInstance().isLoading()) {
			return <LoaderComponent color="red"/>;
		}

		return (
			<ContextTooltip key="base-table-list.export" code="base-table-list.export" default="Экспорт в Excel">
				<IconButton icon="export" onClick={::this.exportToXls}/>
			</ContextTooltip>
		);
	}

	renderModals() {
		if (this.state.duplicationMatrixActive) {
			return (
				<DuplicationMatrixModal
					location={this.props.location}
					router={this.props.router}
					component={this.props.params.component}
					onClose={::this.hideDuplicationMatrix}
				/>
			);
		}
	}
}
