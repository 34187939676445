import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import classNames from 'classnames';

import './TableColumnSelectFields.less';
import IconButton from "components/ui/icon-button";
import {LabeledCheckbox} from "components/ui/checkbox";
import Popup from "components/ui/popup";

@propTypes({
    showPopup: PropTypes.bool,
    onToggle: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    columns: PropTypes.object.isRequired
})

@defaultProps({
    showPopup: false
})

export default class TableColumnSelectFields extends Component {

    state = {
        isDeselectAll: true,
        opened: false,
    };

    selectDeselectAll() {
        if (this.state.isDeselectAll) {
            _.each(_.filter(this.props.columns.toArray(), column => !column.hidden), (column) => {
                this.props.onChange(this.props.columns.indexOf(column), false);
            });
        } else {
            _.each(_.filter(this.props.columns.toArray(), column => column.hidden), (column) => {
                this.props.onChange(this.props.columns.indexOf(column), true);
            });
        }

        this.setState({
            isDeselectAll: !this.state.isDeselectAll,
        });
    }

    onChangeSelectFieldsCheckbox(...args) {
        this.props.onChange(...args);
    }

    onToggle() {
        this.setState({
            opened: !this.state.opened,
        });
    }

    render() {
        const popup = this.state.opened ? this.renderSelectFieldsPopup() : '';

        return (
            <div>
                <IconButton
                    icon="add-remove"
                    ref="button"
                    onClick={::this.onToggle}/>
                {popup}
            </div>
        );
    }

    renderSelectFieldsPopup() {
        const columns = this.props.columns.toArray();
        const selectedCount = _.filter(columns, column => !column.hidden).length;
        const totalCount = columns.length;

        const sliderClassName = classNames({
            'b-slider__line': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        const sliderCircleClassName = classNames({
            'b-slider__circle': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        return (
            <Popup
                className="SelectFieldsPopup top-link"
                show={true}
                onClose={::this.onToggle}>
                <div className="popup-container__content">

                    <div className="content">
                        {this.props.columns.map((item, i) => {
                            return (
                                <LabeledCheckbox
                                    key={_.isString(item.name) ? item.name : item.field}
                                    checked={!item.hidden}
                                    label={item.name}
                                    onChange={this.onChangeSelectFieldsCheckbox.bind(this, this.props.columns.indexOf(item))}/>
                            );
                        })}
                    </div>
                    <div className="b-slider _options clearAfter" onClick={::this.selectDeselectAll}>
                        <div className="b-slider__title" style={{display: 'none'}}>Выбрать все</div>
                        <div className="b-slider__title">{this.state.isDeselectAll ? 'Снять все' : 'Выбрать все'}</div>

                        <div className="b-slider__control">
                            <div className={sliderClassName}/>
                            <div className={sliderCircleClassName}/>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}