import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import PageModal from 'components/ui/page-modal';
import moment from 'moment';
import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import formats from "dictionaries/formats";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import ReactDOM from 'react-dom'
import {print} from "helpers/print";
import ViolationVehiclesForm from '../ViolationVehiclesForm';
import ViolationEnvironmentalClassForm from '../ViolationEnvironmentalClassForm';
import {getEntityNames} from 'store/reducers/system';
import {getDailyViolation, getViolations} from 'store/reducers/kiutr/transport-work';
import './style.less';
import ContextTooltip from "components/ui/context-tooltip";
import ViolationPointSkipClassForm from "components/modules/kiutr/transport-work/violations/forms/ViolationPointSkipClassForm/index";
import ViolationScheduleErrorClassForm from "components/modules/kiutr/transport-work/violations/forms/ViolationScheduleErrorClassForm/index";

@propTypes({
    uuid: PropTypes.string,
    type: PropTypes.string,
    onClose: PropTypes.func,
    onPrint: PropTypes.func
})

@defaultProps({
    onClose: () => {
    },
    onPrint: () => {
    }
})

@connect(state => ({}), {getDailyViolation, getViolations, getEntityNames})

export default class ViolationBaseForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            isError: false,
            isLoading: false
        };
    }

    componentWillMount() {
        this.loadViolation();
    }

    getTitle(type) {
        switch (type) {
            case 'vehicles':
                return 'Нарушения кол-ва и типа вместимости ТС';
            case 'environmental_class':
                return 'Нарушения экологического класса ТС';
            case 'point_skip':
                return 'Отсутствие отметки прохождения КП';
            case 'schedule_error':
                return 'Отклонение от расписания движения по маршруту';
            default:
                return '';
        }
    }

    getForm(type) {
        switch (type) {
            case 'vehicles':
                return ViolationVehiclesForm;
            case 'environmental_class':
                return ViolationEnvironmentalClassForm;
            case 'point_skip':
                return ViolationPointSkipClassForm;
            case 'schedule_error':
                return ViolationScheduleErrorClassForm;
            default:
                return null;
        }
    }

    render() {
        const title = this.getTitle(this.props.type);
        //const loader = (this.state.isLoading) ? (<GlobalLoaderComponent/>) : null;
        //const content = this.state.isError ? null : this.state.isLoading ? <GlobalLoaderComponent/> : this.getForm(this.props.type, this.state.data);
        let content = null;

        if (!this.state.isError) {
            if (this.state.isLoading) {
                content = <GlobalLoaderComponent/>;
            }
            else {
                const ViolationForm = this.getForm(this.props.type);
                content = ViolationForm ? <ViolationForm ref="form" data={this.state.data}/> : null;
            }
        }

        const buttons = (
            <ModalTopMenuList>
                <ContextTooltip key="base-editor.print" code="base-editor.print" default="Печать">
                    <ModalTopMenuListItem
                        className="b-icon-link_icon_print"
                        onClick={this.printForm}
                    />
                </ContextTooltip>
                <ModalTopMenuButtonsSeparator/>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuList>
        );

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}
                className={`violation-modal b-modal-transport-work ${this.props.type}`}
                withFade
            >
                {content}
            </PageModal>
        );
    }

    printForm = () => {
        const element = ReactDOM.findDOMNode(this.refs.form);
        if (element) {
            let html = element.outerHTML;
            html = html.replace(/\<table/g, '<table style="border-collapse: collapse;"');
            html = html.replace(/\<td/g, '<td style="border: 1px black solid;"');
            html = html.replace(/\<th/g, '<th style="border: 1px black solid;"');
            print(html);
        }
    };

    async loadCommonData() {
        return await this.props.getDailyViolation(this.props.uuid);
    }

    async loadRelatedEntities(data) {
        const units = [{
            class: 'App\\Model\\Unit',
            uuid: _.get(data, 'route_registry.contract.carrier_uuid'),
            source: 'organizational_units',
        }];

        return await this.props.getEntityNames(units);
    }

    async loadRelatedData(data) {
        const meta = {
            filters: {
                withRouteRegistry: data['route_registry_uuid'],
                withDate: moment(data.date).format(formats.DATE_API),
                withType: this.props.type
            }
        };

        return await this.props.getViolations(meta);
    }

    async loadViolation() {
        this.setState({isError: false, isLoading: true});

        let response = await this.loadCommonData();

        if (response.isOk) {
            const commonData = response.payload;
            //console.log(commonData);

            const response2 = await this.loadRelatedEntities(commonData);

            if (response2.isOk) {
                const items = response2.data.payload.items;
                //console.log(items);

                const response3 = await this.loadRelatedData(commonData);

                if (response3.isOk) {
                    //console.log(response3.data.payload.items);

                    const data = {
                        date: moment(commonData.date).format(formats.DATE),
                        contractUnitName: items.length ? items[0].name : '',
                        routeNumber: _.get(commonData, 'route_registry.route.number'),
                        routeTitle: _.get(commonData, 'route_registry.route.title'),
                        items: response3.data.payload.items
                    };

                    //console.log(data);

                    this.setState({data, isError: false, isLoading: false});
                    return;
                } else {
                    response = response3;
                }
            } else {
                response = response2;
            }
        }

        this.setState({isError: true, isLoading: false});
        response.showErrors();
    }
}