class GlobalEventClass {
    queue = {};

    call(event, data) {
        let queue = this.queue[event];

        if (typeof queue === 'undefined') {
            return false;
        }

        for (let i = 0; i < queue.length; i++) {
            (queue[i])(data);
        }

        return true;
    }

    subscribe(event, callback) {
        if (typeof this.queue[event] === 'undefined') {
            this.queue[event] = [];
        }

        this.queue[event].push(callback);
    }

    unsubscribe(event, callback) {
        if (typeof this.queue[event] === 'undefined') {
            this.queue[event] = [];
        }
        for (let i = 0; i < this.queue[event].length; i++) {
            if (this.queue[event][i] === callback) {
                this.queue[event].splice(i, 1);
            }
        }
    }
}

const instance = new GlobalEventClass;
export const GlobalEvent = () => {
    return instance;
};