import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import _ from 'lodash';

import { connect } from 'react-redux';

import BaseEditorFormComponent from 'components/base/base-editor-form';
import Block from 'components/ui/form/block';
import Button from 'components/ui/button';
import { getDictionaryList } from 'store/reducers/dictionaries/dictionary';
import Accordion from 'components/ui/accordion/accordion';
import AccordionItem from 'components/ui/accordion/accordion-item';
import BaseEditor from 'components/base/base-editor';
import { getRouteRegistry, createRouteRegistry, updateRouteRegistry } from 'store/reducers/kiutr/route_registries';
import { getRoutes } from 'store/reducers/routes/route_editor';
import { getContracts } from 'store/reducers/kiutr/contracts/contracts';
import TableContainer from 'components/ui/Table/Container/TableContainer';
import ModalTopMenuList from 'components/ui/modal/modal-top-menu-list';
import ModalTopMenuListItem from 'components/ui/modal/modal-top-menu-list-item';
import ModalTopMenuListSeparator from 'components/ui/modal/modal-top-menu-list-separator';
import ModalTopMenuButtons from 'components/ui/modal/modal-top-menu-buttons';
import ModalTopMenuButton from 'components/ui/modal/modal-top-menu-button';
import ModalTopMenuButtonsSeparator from 'components/ui/modal/modal-top-menu-buttons-separator';
import currentUser from 'helpers/current-user';
import PageModal from 'components/ui/page-modal';
import GlobalLoaderComponent from 'components/ui/global-loader';
import { getBnso, getBnsoCheckLink } from 'store/reducers/vehicles/bnso';
import { getTelematicsLogs, getTelematicsWithDevices } from 'store/reducers/maps';
import moment from 'moment';
import formats from 'dictionaries/formats';
import './editor.less';
import ContextTooltip from 'components/ui/context-tooltip';
import { getVehicleList } from 'store/reducers/vehicles/vehicles';
import { EntityList } from 'helpers/entity';
import { getEntityNames } from 'store/reducers/system';
import { Link } from 'react-router';

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	uuid: PropTypes.string
})
@connect((state) => ({}), { getBnso, getBnsoCheckLink, getVehicleList, getTelematicsWithDevices })
export default class Editor extends BaseEditor {
	title = '';
	_time = undefined;
	_isShowSwitchToMapIcon = false;

	async loadData(uuid) {
		const response = await this.props.getBnso(uuid);

		if (response.isOk) {
			this._time = _.get(this.props.related.getItem(response.payload.bnso_number), 'point.7');
			this.loadVehicle(response.payload.bnso_number);
			this.loadTelematics(response.payload.bnso_number);
		}

		return response;
	}

	async loadVehicle(number) {
		const response = await this.props.getVehicleList({
			filters: {
				withCurrentBnsoNumber: [ number ]
			}
		});

		if (response.isOk) {
			this.setState({
				vehicle: _.get(_.first(response.payload.items), 'uuid')
			});
		}
	}

	async loadTelematics(number) {
		const response = await this.props.getTelematicsWithDevices([ `${number}` ], 't1sync', null, null, 'none');
		if (response.isOk && response.payload.items.length) {
			this._isShowSwitchToMapIcon = true;
		}
	}

	getForm(item, onSubmit) {
		return (
			<EditorForm
				{...this.props}
				ref="form"
				mode={this.props.mode}
				onSubmit={onSubmit}
				onClose={::this.props.onClose}
				data={item}
				errors={this.state.errors}
			/>
		);
	}

	render() {
		const loader = this.state.isLoading || this.state.saving ? <GlobalLoaderComponent /> : null;

		const title = 'БНСО';
		let form;
		let buttons;

		if (this.state.item) {
			form = this.getForm(this.state.item, () => {});

			buttons = (
				<ModalTopMenuButtons>
					<ModalTopMenuList className="top-menu_modal_edit">
						<ContextTooltip key="bnso-check.diagnostic" code="bnso-check.diagnostic" default="Инструменты диагностики">
							<ModalTopMenuListItem className="b-icon-link_icon_tool" onClick={::this.gotoDiagnostic} />
						</ContextTooltip>

						<ContextTooltip key="bnso-check.notifications" code="bnso-check.notifications" default="Фильтры оповещений">
							<ModalTopMenuListItem className="b-icon-link_icon_notification" onClick={::this.gotoNotifications} />
						</ContextTooltip>
						{this._isShowSwitchToMapIcon && (
							<ContextTooltip key="bnso-check.map" code="bnso-check.map" default="Посмотреть на карте">
								<ModalTopMenuListItem className="b-icon-link_icon_placemark" onClick={::this.showMap} />
							</ContextTooltip>
						)}
					</ModalTopMenuList>

					<ModalTopMenuButtonsSeparator />

					<ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
						<ModalTopMenuButton className="_close" onClick={::this.props.onClose} />
					</ContextTooltip>
				</ModalTopMenuButtons>
			);
		}

		return (
			<PageModal
				header={{ title, buttons }}
				onClose={this.props.onClose}
				className={`profile-modal b-modal-${this.props.mode}`}
			>
				{loader}
				{form}
			</PageModal>
		);
	}

	showMap() {
		// this.state.item.bnso_code.replace(/^0+/, '') - убрал, ибо ломает номер бнсо начинающийся на 0 и от того нет данных по нему на карте потом
		this.props.router.push(`/operator/map?bnso=${this.state.item.bnso_code}&component=${this.state.item.component}`);
	}

	gotoNotifications() {
		this.props.router.push(`/operator/notifications?vehicle_uuid=${this.state.vehicle}`);
	}

	async gotoDiagnostic() {
		const response = await this.props.getBnsoCheckLink(this.props.uuid);

		if (response.isOk) {
			const link = response.payload.link;

			const a = document.createElement('a');
			a.setAttribute('href', link);
			a.setAttribute('target', '_blank');
			a.style.display = 'none';
			document.body.appendChild(a);
			a.click();
			setTimeout(function() {
				document.body.removeChild(a);
			}, 0);
		} else {
			response.showErrors();
		}
	}
}

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	data: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	errors: PropTypes.object
})
@connect((state) => ({}), { getTelematicsLogs, getEntityNames }, null, { withRef: true })
class EditorForm extends BaseEditorFormComponent {
	_isMounted = false;

	state = {
		bnso: {},
		logs: [],
		related: new EntityList()
	};

	getData() {
		return this.state.bnso;
	}

	async componentDidMount() {
		this._isMounted = true;
		await this.setState({
			bnso: this.props.data
		});
		this.initialRequests();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	initialRequests() {
		if (this._isMounted) {
			setTimeout(() => {
				this.loadLogs();
				this.loadRelated();
			}, 0);
		}
	}

	async loadRelated() {
		const entries = [
			{
				class: 'App\\Dictionaries\\Vehicles\\BnsoType\\Model',
				uuid: this.get('bnso_type'),
				source: 'dictionary'
			},
			{
				class: 'App\\Dictionaries\\Vehicles\\BnsoManufacturer\\Model',
				uuid: this.get('manufacturer'),
				source: 'dictionary'
			},
			{
				class: 'App\\Dictionaries\\Vehicles\\BnsoOperator\\Model',
				uuid: this.get('mobile_operator'),
				source: 'dictionary'
			}
		];

		const response = await this.props.getEntityNames(entries);

		if (response.isOk) {
			this.state.related.add(response);
			this.forceUpdate();
		}
	}

	async loadLogs() {
		if (this._isMounted) {

			const response = await this.props.getTelematicsLogs({
				bnso_number: this.state.bnso.bnso_number
			});
			if (response.isOk) {
				const logs = response.data.objectsList.length ? response.data.objectsList[0].dataList : [];
				this.setState({ logs });
			} else {
				response.showErrors();
			}
		}
	}

	get(path, defaultValue = null) {
		return _.get(this.state.bnso, path, defaultValue);
	}

	handleSmsSend() {
		const { bnso_code, phone_number, phone_number2 } = this.props.data;
		const bnso_uuid = this.props.data.uuid;
		const bnso = { bnso_code, bnso_uuid, phone_number, phone_number2 };
		localStorage.setItem('bnso', JSON.stringify(bnso));
		this.props.router.push(`/operator/bnso_sms`);
	}

	handleSmsReport() {
		const { bnso_code, uuid } = this.props.data;
		const bnso = { bnso_uuid: uuid, bnso_code };
		localStorage.setItem('bnso', JSON.stringify(bnso));
		this.props.router.push(`/operator/bnso_report`);
	}

	render() {
		return (
			<div>
				<Accordion>
					<AccordionItem opened={true} title="Информация о БНСО">
						<Block size="xl" title="Код БНСО">
							{this.get('bnso_code')}
						</Block>
						<Block title="Тип БНСО">
							{this.get('bnso_type') ? this.state.related.getReact(this.get('bnso_type')) : null}
						</Block>
						<Block title="Производитель">
							{this.get('manufacturer') ? this.state.related.getReact(this.get('manufacturer')) : null}
						</Block>
						<Block title="Серийный номер">{this.get('serial_number')}</Block>
						<Block title="Мобильный оператор">
							{this.get('mobile_operator') ? this.state.related.getReact(this.get('mobile_operator')) : null}
						</Block>
						<Block title="Номер SIM - карты">{this.get('sim_card_number')}</Block>
						<Block title="Номер телефона">{this.get('phone_number')}</Block>
						<Block title="Версия ПО">{this.get('version')}</Block>
					</AccordionItem>

					<AccordionItem opened={true} title="История опроса БНСО">
						<Block size="xl">
							<div className="Table" style={{ width: 500 }}>
								<TableContainer>
									<table className="b-table">
										<thead>
											<tr>
												<th width="200px">Дата/время</th>
												<th width="200px">Интервал, сек</th>
											</tr>
										</thead>
										<tbody>{this.state.logs.map(::this.renderLogRow)}</tbody>
									</table>
								</TableContainer>
							</div>
						</Block>
					</AccordionItem>
					{window.RNIS_SETTINGS.CITY_OMSK ? (
						<AccordionItem opened={true} title="СМС">
							<Block size={'flex'}>
								<Button text="Отправить" color="white" shadow="gray" size="md" onClick={::this.handleSmsSend} />
								<Button
									text="Посмотреть отчет"
									color="white"
									shadow="gray"
									size="md"
									width="auto"
									onClick={::this.handleSmsReport}
								/>
							</Block>
						</AccordionItem>
					) : null}
				</Accordion>
			</div>
		);
	}

	renderLogRow(log, index) {
		let interval;
		let time;

		if (index !== this.state.logs.length - 1) {
			interval = moment.unix(log.receivedtime).diff(moment.unix(this.state.logs[index + 1].receivedtime), 'seconds');
		}
		time = moment.unix(log.receivedtime).format(formats.DATETIME);

		return (
			<tr key={index}>
				<td className="align-center">{time}</td>
				<td className="align-center">
					{interval <= 60 ? (
						<span className="interval interval-green">{interval}</span>
					) : (
						<span className="interval interval-red">{interval}</span>
					)}
				</td>
			</tr>
		);
	}
}
