import React from 'react';
import TableContainer from 'components/ui/Table/Container/TableContainer';

export const SmallIntervalTable = ({ intervals }) => {
	return (
		<TableContainer>
			<div className="Table">
				<table className="b-table b-table-thead-no-hover">
					{Object.keys(intervals).map((key, i) => {
						const dataCells = intervals[key]
							.map((el, index) => {
								return (
									<td className={`${index === 0 && 'border-left-bold-2'} border-bottom-bold-2 border-right-bold-2`} key={index}>
										{el}
									</td>
								);
							});
						return (
							<tr className={`${i === 0 && 'border-top-bold-2'} border-left-bold-2 border-bottom-bold-2`} key={i}>
								<th className={`border-left-bold-2`}>{key}</th>
								{dataCells}
							</tr>
						);
					})}
				</table>
			</div>
		</TableContainer>
	);
};
