import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import GlobalLoaderComponent from "components/ui/global-loader";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getSchedule} from "store/reducers/kiutr/schedules/schedules";
import formats from "dictionaries/formats";
import moment from "moment";
import './graphic.less';
import {getScheduleTurns, updateScheduleTurn} from "store/reducers/kiutr/schedules/schedule_turns";
import Button from "components/ui/button";
import Page from 'components/ui/page';
import {getRouteVariants} from "store/reducers/routes/route_variants";
import {getStopPoints} from "store/reducers/geo/stop-points";
import systems from "dictionaries/systems";
import IconButton from "components/ui/icon-button";
import {Line} from 'react-chartjs-2';
import runs from "dictionaries/runs";
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import {timeCorrection} from "helpers/kiutr";
import debounce from 'throttle-debounce/debounce';
import * as alerts from "helpers/alerts";
import ContextTooltip from "components/ui/context-tooltip";
import {getDefaultVariant} from "helpers/kiutr";
import currentUser from "../../../../../../helpers/current-user";
import {saveTimeCorrection} from "helpers/kiutr";
const Handle = Slider.Handle;

@connect(state => ({}), {getSchedule, getScheduleTurns, getRouteVariants, getStopPoints, updateScheduleTurn})

export default class KiutrRouteScheduleGraphicViewComponent extends Component {

    state = {
        routeUuid: null,
        scheduleUuid: null,
        schedule: null,
        loading: false,
        turns: [],
        defaultVariant: null,
        defaultStopPoints: null,
        firstStopPoint: null,
        lastStopPoint: null,
        selectedItem: null,
    };

    colors = [
        '151,187,205',
        '247,70,74',
        '70,191,189',
        '253,180,92',
        '148,159,177',
        '77,83,96',
    ];

    recalcDebounce = debounce(100, ::this.recalc);

    async componentWillUpdate(props, state) {
        if (props.params.uuid !== state.routeUuid) {
            await this.setState({routeUuid: props.params.uuid});
            this.loadRouteVariant();
        }
        if (props.params.scheduleUuid !== state.scheduleUuid) {
            await this.setState({scheduleUuid: props.params.scheduleUuid});
            this.loadSchedule();
        }
    }

    async loadRouteVariant() {
        const response = await this.props.getRouteVariants({
            filters: {
                withRoute: this.state.routeUuid,
            },
        });
        if (response.isOk) {
            const defaultVariant = getDefaultVariant(response.payload.items);
            const firstStopPoint = _.find(defaultVariant.forward_points, {point_type: 'stop_point'});
            const lastStopPoint = _.findLast(defaultVariant.forward_points, {point_type: 'stop_point'});
            this.setState({
                defaultVariant,
                firstStopPoint,
                lastStopPoint,
            });
            this.loadStopPoints(_.concat(defaultVariant.forward_points, defaultVariant.reverse_points));
        } else {
            response.showErrors();
        }
    }

    async loadStopPoints(points) {
        const response = await this.props.getStopPoints({
            filters: {
                withUuid: _.uniq(_.filter(_.map(points, 'type_uuid'))),
            },
        });
        if (response.isOk) {
            this.setState({
                defaultStopPoints: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'title'),
            });
        } else {
            response.showErrors();
        }
    }

    componentDidMount() {
        this.forceUpdate();
    }

    async loadSchedule() {
        this.setState({loading: true});
        const response = await this.props.getSchedule(this.state.scheduleUuid);
        if (response.isOk) {
            await this.setState({
                schedule: response.payload,
            });
            await this.loadTurns();

            this.setState({loading: false});
        } else {
            response.showErrors();
        }
    }

    async loadTurns() {
        const response = await this.props.getScheduleTurns({
            order: {
                column: 'number',
                direction: 'asc',
            },
            filters: {
                withSchedule: this.state.scheduleUuid,
            },
        });
        if (response.isOk) {
            const turns = currentUser.user.is_timecorrection ? timeCorrection(response.payload.items) : response.payload.items;
            await this.setState({
                turns
            });
        } else {
            response.showErrors();
        }
    }


    render() {
        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;

        return (
            <Page
                title={`${systems.kiutr} → Графический вариант расписания`}
                pageId="ScheduleGraphicView"
                headerActions={this.renderHeaderActions()}
            >
                {loader}
                {(this.state.schedule && this.state.defaultVariant && this.state.defaultStopPoints) ? this.renderSchedule() : null}
            </Page>
        );
    }

    getDatasets() {
        return this.state.turns.map(::this.getTurnDataset);
    }

    getTurnDataset(turn, index) {
        let currentStopPoint = 0;
        const lastRun = _.last(turn.runs);

        const color = this.colors[index % this.colors.length];

        let colors = [];
        _.each(turn.runs, (run, runIndex) => {
            if ((_.get(this.state.selectedItem, 'dataset') === index) && _.get(this.state.selectedItem, 'index') === runIndex) {
                colors.push(`rgba(${color},1)`);
            } else {
                colors.push('#fff');
            }
        });
        if ((_.get(this.state.selectedItem, 'dataset') === index) && _.get(this.state.selectedItem, 'index') === turn.runs.length) {
            colors.push(`rgba(${color},1)`);
        } else {
            colors.push('#fff');
        }

        return {
            label: `Выход ${turn.number}`,
            fill: false,
            lineTension: 0,
            backgroundColor: `rgba(${color},0.4)`,
            borderColor: `rgba(${color},1)`,
            borderCapStyle: 'butt',
            borderDash: [],
            borderWidth: (_.get(this.state.selectedItem, 'dataset') === index) ? 6 : 3,
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: `rgba(${color},1)`,
            pointBackgroundColor: colors,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: `rgba(${color},1)`,
            pointHoverBorderColor: `rgba(${color},1)`,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: _.concat(_.map(turn.runs, (run) => {
                const result = {
                    x: moment(run.start_time, 'HH:mm').format(formats.DATETIME_API),
                    y: currentStopPoint,
                    type: runs[run.type],
                    run_type: run.type,
                };

                if ((run.type === 'production_forward') || (run.type === 'production_reverse')) {
                    currentStopPoint = (currentStopPoint === 0) ? 1 : 0;
                }

                return result;
            }), lastRun ? {
                x: moment(lastRun.start_time, 'HH:mm').add(lastRun.time, 'minutes').format(formats.DATETIME_API),
                y: currentStopPoint,
                type: 'Окончание выхода',
            } : null),
        };
    }

    renderSchedule() {
        const data = {
            datasets: this.getDatasets(),
        };

        const options = {
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        unit: 'minute',
                        displayFormats: {
                            minute: 'HH:mm'
                        }
                    },
                }],
                yAxes: [{
                    ticks: {
                        callback: (value) => {
                            if (value === 0) {
                                return this.state.defaultStopPoints[this.state.firstStopPoint && this.state.firstStopPoint.type_uuid];
                            }
                            if (value === 1) {
                                return this.state.defaultStopPoints[this.state.lastStopPoint && this.state.lastStopPoint.type_uuid];
                            }
                        },
                    },
                }],
            },
            tooltips: {
                callbacks: {
                    title: (tooltipItem) => {
                        return moment(tooltipItem[0].xLabel).format(formats.TIME);
                    },
                    label: (tooltipItem, data) => {
                        const item = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                        return item.type;
                    },
                },
            },
            hover: {
                mode: 'nearest'
            },
            events: ['click', 'mousemove'],
            onClick: ::this.onChartClick,
        };

        let selected = null;
        let min = 0;
        let max = 24 * 60;
        let minTime = 0;
        let maxTime;
        const step = 5;
        let selectedRun = null;
        if (this.state.selectedItem) {
            selectedRun = this.state.turns[this.state.selectedItem.dataset].runs[this.state.selectedItem.index];
            selected = this.timeToInt(this.state.turns[this.state.selectedItem.dataset].start_at);
            max = 24 * 60 - this.getTurnLength(this.state.selectedItem.dataset);
            max -= max % step;
            maxTime = 3 * 60;
        }

        return (
            <div>
                <Line
                    data={data}
                    height={50}
                    options={options}
                />
                {this.state.selectedItem ? (
                    <div>
                        <div className="schedule-slider-total">
                            Изменение начала выхода {this.state.turns[this.state.selectedItem.dataset].number}:
                            <Slider
                                value={selected}
                                min={min}
                                max={max}
                                step={step}
                                marks={this.getSliderMarks(min, max)}
                                handle={::this.getSliderHandle}
                                onChange={::this.onTurnStartChange}
                            />
                        </div>
                        {(selectedRun && selectedRun.type !== 'production_forward' && selectedRun.type !== 'production_reverse') ? (
                            <div className="schedule-slider-run">
                                Изменение продолжительности рейса "{runs[selectedRun.type]}":
                                <Slider
                                    value={selectedRun.time}
                                    min={minTime}
                                    max={maxTime}
                                    step={step}
                                    marks={this.getSliderMarks(minTime, maxTime, 15)}
                                    handle={::this.getSliderHandle}
                                    onChange={::this.onTurnRunChange}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        );
    }

    getTurnLength(index, runIndex = 0) {
        return _.sum(_.map(this.state.turns[index].runs, (run, _index) => {
            return (_index >= runIndex) ? run.time : 0;
        }));
    }

    async onTurnStartChange(value) {
        let state = this.state;
        _.set(state, `turns.${this.state.selectedItem.dataset}.start_at`, this.intToTime(value));
        await this.setState(state);
        this.recalcDebounce();
    }

    async onTurnRunChange(value) {
        let state = this.state;
        _.set(state, `turns.${this.state.selectedItem.dataset}.runs.${this.state.selectedItem.index}.time`, value);
        await this.setState(state);
        this.recalcDebounce();
    }

    getSliderHandle(props) {
        const {value, dragging, index, ...restProps} = props;
        return (
            <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={this.intToTime(value)}
                visible={dragging}
                placement="top"
                key={index}
            >
                <Handle value={value} {...restProps} />
            </Tooltip>
        );
    };

    recalc() {
        let state = this.state;
        state.turns = _.map(state.turns, (turn) => {
            let time = moment(turn.start_at, formats.TIME);

            _.each(turn.runs, (run) => {
                run.start_time = time.format(formats.TIME);
                time = time.add(run.time, 'minutes');
            });

            turn.end_at = time.format(formats.TIME);

            return turn;
        });

        this.setState(state);
    }

    getSliderMarks(min, max, step = 60) {
        let result = {};
        for (let i = min; i <= max; i += step) {
            result[i] = this.intToTime(i);
        }
        return result;
    }

    intToTime(int) {
        const hours = _.padStart(Math.floor(int / 60), 2, '0');
        const minutes = _.padStart(int % 60, 2, '0');
        return `${hours}:${minutes}`;
    }

    timeToInt(time) {
        const timeObject = moment(time, formats.TIME);
        return timeObject.hours() * 60 + timeObject.minutes();
    }

    onChartClick(e, chartItems) {
        const chartItem = _.first(chartItems);
        if (!chartItem) {
            return;
        }
        this.setState({
            selectedItem: {
                dataset: chartItem._datasetIndex,
                index: chartItem._index,
            },
        });
    }

    async save() {
        this.setState({loading: true});
        Promise.all(_.map(this.state.turns, async (turn) => {
            turn = currentUser.user.is_timecorrection ? saveTimeCorrection(turn) : turn;
            const response = await this.props.updateScheduleTurn(turn);
            if (response.isOk) {
            } else {
                response.showErrors();
            }
        })).then(() => {
            this.setState({loading: false});
           currentUser.user.is_timecorrection ?  this.setState({turns: timeCorrection(this.state.turns)}) : null;
            alerts.success('Расписание сохранено');
        }, () => {
            this.setState({loading: false});
        });
    }

    close() {
        this.props.router.push(`/${this.props.params.component}/routes/${this.props.params.uuid}/schedules/${this.props.params.scheduleUuid}`);
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="kiutr-route-schedule-graphic.save" code="kiutr-route-schedule-graphic.save" default="Сохранить">
                <IconButton icon="save" onClick={::this.save}/>
            </ContextTooltip>,
            <ContextTooltip key="kiutr-route-schedule-graphic.close" code="kiutr-route-schedule-graphic.close" default="Назад" position="left">
                <IconButton icon="back-0" onClick={::this.close}/>
            </ContextTooltip>,
        ];
    }
}
