import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {deleteFaq, getFaq} from "store/reducers/portal/faq";
import {deletePage, getPages} from "store/reducers/portal/pages";
import IconButton from "components/ui/icon-button";
import {deleteContact, getContacts} from "store/reducers/mobile/contacts";
import contact_types from "dictionaries/contact_types";
import systems from "dictionaries/systems";
import {deleteObject, getObjects} from "store/reducers/garbage/objects";
import {EntityList} from "helpers/entity";
import _ from 'lodash';
import {getEntityNames} from "store/reducers/system";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {Link} from "react-router";

@connect(state => ({}), {getObjects, deleteObject, getEntityNames, getDictionaryList})

export default class GarbageObjectsComponent extends BaseTableWithEditorComponent {

    baseUrl = '/garbage/objects';

    getTitle() {
        return <span>{systems.garbage} → <Link to={`/garbage/dictionaries`}>Справочники</Link> → Объекты ТКО</span>;
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Наименование')
                .fromField('name'),

            new Column('Адрес')
                .fromField('address'),

            new Column('Тип объекта')
                .fromField('garbage_object_type_uuid')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => item.garbage_object_type_uuid && this.state.related.get(item.garbage_object_type_uuid))
                .withFilter('withGarbageObjectTypes', async () => {
                    const response = await this.props.getDictionaryList('garbage_object_types');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }, 'left'),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteObject(data);
    }

    async loadData(meta) {
        return await this.props.getObjects(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const types = _.map(_.filter(_.map(result, 'garbage_object_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Garbage\\GarbageObjectType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const response = await this.props.getEntityNames(types);
        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}