import {rpc} from './session';

export async function getObjects(meta = {}) {
    return await rpc.request('com.rnis.garbage.action.object.list', {}, {meta});
}

export async function getObject(uuid) {
    return await rpc.request('com.rnis.garbage.action.object.get', {uuid});
}

export async function createObject(data) {
    return await rpc.request('com.rnis.garbage.action.object.create', data);
}

export async function updateObject(data) {
    return await rpc.request('com.rnis.garbage.action.object.update', data);
}

export async function deleteObject(data) {
    return await rpc.request('com.rnis.garbage.action.object.delete', data);
}
