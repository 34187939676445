import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defaultProps, propTypes} from 'react-props-decorators';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import _ from 'lodash';

const defaultColor = '#00ff00';

@propTypes({
    data: PropTypes.array,
    xDataKey: PropTypes.string,
    yDataKey: PropTypes.string,
    xName: PropTypes.string,
    configKey: PropTypes.string,
    color: PropTypes.string
})

@defaultProps({
    data: [],
    xDataKey: 'name',
    yDataKey: 'value',
    xName: '',
    configKey: '',
    color: defaultColor
})

export default class LineChartComponent extends Component {

    renderLine = () => {
        const data = [];
        let fields = {};

        const fieldItems = _.filter(_.flatten(_.map(this.props.data, this.props.configKey)));

        fieldItems.forEach((item) => {
            const keys = Object.keys(item);

            keys.forEach((key) => {
                fields[key] = item[key];
            });
            if (keys.length) {
                fields[keys[0]] = item[keys[0]];
            }
        });

        Object.keys(fields).forEach((key, index) => {
            data.push(<Line key={index}
                            dataKey={key}
                            stroke={fields[key].color || this.props.color || defaultColor}
                            name={fields[key].name}
                            type="monotone"
            />);
        });

        return data;
    };

    render() {
        return (
                <ResponsiveContainer>
                    <LineChart data={this.props.data}>
                        <XAxis dataKey={this.props.xDataKey} interval="preserveStartEnd"/*label="Баллы"*/ />
                        <YAxis dataKey={this.props.yDataKey} /*label="Баллы"*//>
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        {/*<Legend />*/}
                        {this.props.configKey ?
                            this.renderLine()
                            :
                            <Line dataKey={this.props.yDataKey}
                                  stroke={this.props.color || defaultColor}
                                  name={this.props.xName}
                                  type="monotone"
                            />
                        }
                    </LineChart>
                </ResponsiveContainer>

        );
    }
}