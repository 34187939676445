import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {Loader} from 'react-loaders'
import classNames from 'classnames';
import _ from 'lodash';
import moment from "moment";
import L from 'leaflet';
import * as storage from 'utils/storage';
import 'leaflet.markercluster';
import 'leaflet.marker.slideto';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import formats from "dictionaries/formats";
import './map.less';
import Layers from "components/ui/layers";
import {connect} from "react-redux";
import MapSearch from "components/ui/map-search";
import MapZoomButton from "components/ui/map-zoom-button";
import ReactDOMServer from 'react-dom/server';
import MapVehicleIcon from "components/ui/map-vehicle-icon";
import PageModal from 'components/ui/page-modal';
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import {Tabs, Tab, TabList, TabPanel} from 'react-tabs';
import LayerEditor from 'components/redux-forms/LayerEditor';
import MapGeojson from "components/ui/map/base/geojson";
import Ruler from 'components/modules/maps/Ruler';
import TrafficLayer from 'components/modules/maps/TrafficLayer';
import RoutePlanner from 'components/modules/maps/RoutePlanner';
import {toggleLayer} from "store/reducers/user-map-objects/layers";
import * as alerts from "helpers/alerts";
import {geocode, reverseGeocode, searchAddress, routing} from "store/reducers/geo/geocode";
import {getClosestStopPoint} from "store/reducers/geo/stop-points";
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import RoutingMarker from "components/ui/map/markers/routing-marker";
import RoutingModal from "components/ui/map/controls/routing/index";
import RoutingResultModal from "components/ui/map/controls/routing-result/index";
import {formatDistance} from "helpers/math";
import WeatherLayer from "components/modules/maps/WeatherLayer";
//import leafletImage from 'leaflet-image';
import currentUser from 'helpers/current-user';
import {setComponent} from "../../store/reducers/maps/actions";

@propTypes({
    showLayers: PropTypes.bool,
    showSearch: PropTypes.bool,
    searchByMO: PropTypes.bool,
    layers: PropTypes.array,
    onZoom: PropTypes.func,
    onStopPointClick: PropTypes.func,
    onStopPointDblClick: PropTypes.func,
    onStopPointForwardAddClick: PropTypes.func,
    onStopPointReverseAddClick: PropTypes.func,
    onUserGeoObjectDblClick: PropTypes.func,
    onClick: PropTypes.func,
    onDblClick: PropTypes.func,
    components: PropTypes.array,
    withoutStopPoints: PropTypes.array,
})

@defaultProps({
    showLayers: true,
    showSearch: true,
    searchByMO: false,
    layers: [],
    onZoom: () => {
    },
    onClick: () => {
    },
    onDblClick: null,
    onStopPointClick: null,
    onStopPointDblClick: () => {
    },
    onStopPointForwardAddClick: null,
    onStopPointReverseAddClick: null,
    onUserGeoObjectDblClick: () => {
    },
    components: [],
    withoutStopPoints: [],
})

@connect(state => ({
    userGeoObjects: state.user_map_objects.layers.get('objects'),
    activeLayers: state.user_map_objects.layers.get('activeLayers'),
    userGeoObjectsLayers: state.user_map_objects.layers.get('layers'),
    mapSearchObjects: state.user_map_objects.map_search.get('map_search'),
    selectedSearchResult: state.user_map_objects.map_search.get('selectedSearchResult'),
    stateFilters: state.maps.filters,
}), (dispatch) => ({
    onSetComponent: (component) => dispatch(setComponent(component)),
    toggleLayer,
    geocode,
    searchAddress,
    reverseGeocode,
    getClosestStopPoint,
    routing,
}), null, {withRef: true})

export default class MapComponent extends Component {

    defaultRouting = {
        date: moment(),
        time: moment().format(formats.TIME),
        max_time: '04:00',
        max_transfers: 2,
        walk_reluctance: 2,
        is_wheelchair: false,
        mode_bus: true,
        mode_tram: true,
        mode_train: true,
        mode_subway: true,
        from: {
            latitude: null,
            longitude: null,
            address: '',
        },
        to: {
            latitude: null,
            longitude: null,
            address: '',
        },
        transit: [],
    };

    state = {
        showEditor: false,
        layerUuid: null,
        isEditing: false,
        activeWidget: '',
        routingActive: false,
        routingResultActive: false,
        routing: _.cloneDeep(this.defaultRouting),
        routingResult: null,
        activeRoutingResultItem: 0,
        activeSegment: null,
        activeSearchMarker: null,
    };

    map = null;
    mapSearchObjectsLayer = null;
    locateBtn = null;

    tilePath(tile) {
        return currentUser.user.is_osm_defaultLayer ? `${RNIS_SETTINGS.TILE_SERVER_HOSTNAME}/${tile.z}/${tile.x}/${tile.y}.png` :
            (tile.z + '00' + (1000000000 + tile.x).toString().substr(1)
                + (1000000000 + tile.y).toString().substr(1)).replace(/^(\d{1,2})00(\d{3})(\d{3})(\d{3})(\d{3})(\d{3})(\d{3})/, '$1/00/$2/$3/$4/$5/$6/$7');
    }

    // тайлы новосибирска
    arcgisTilePath(tile) {
        return `maps.nso.ru/arcgis3/rest/services/Common/BASEMAP_BIPD/MapServer/tile/${tile.z}/${tile.y}/${tile.x}`
    }

    // тайлы ОФМ (МО)
    ofmTilePath(tile) {
        return `rnis.mosreg.ru/wmts/ofm/${tile.z}/${tile.x}/${tile.y}.jpeg`
    }

    // тайлы РГИС (МО)
    rgisMOTilePath(tile) {
        return `rnis.mosreg.ru/wmts/m10/${tile.z}/${tile.x}/${tile.y}.png`
    }

    // тайлы ОФМ (МО)
    newWmsTilePath(tile) {
       // return `int.rgis.mosreg.ru/wmts/m10/${tile.z}/${tile.x}/${tile.y}.png`
        return `http://int.rgis.mosreg.ru/wms/cache/?service=WMS&request=GetCapabilities`
    }

    isMosreg() {
        return (/\.mosreg.ru$/.test(window.location.hostname));
    }

    isNso() {
        return (/\.nso.ru$/.test(window.location.hostname));
    }

    isLocalhost() {
        return (/localhost$/.test(window.location.hostname));
    }

    isOmsk() {
        return (/\.omskportal.ru$/.test(window.location.hostname));
    }

    componentDidMount() {
        let osmUrl = window.RNIS_SETTINGS.OSMDEFAULTLAYER ? `${window.RNIS_SETTINGS.TILE_REQUEST_METHOD}://{path}` : this.isMosreg() ? 'https://rnis.mosreg.ru/g-map/lv{path}.png' : 'https://portal.rnis.mosreg.ru/g-map/lv{path}.png';
        console.log(osmUrl)

        this.osmLayer = new L.TileLayer(osmUrl, {
            path: this.tilePath,
            maxZoom: 17,
            minZoom: 3,
            tms: !currentUser.user.is_osm_defaultLayer,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        });

        this.arcgisLayer = new L.TileLayer(osmUrl, {
            path: this.arcgisTilePath,
            maxZoom: 17,
            minZoom: 3,
            tms: !currentUser.user.is_osm_defaultLayer,
        });

        this.ofmLayer = new L.TileLayer('http://{path}', {
            path: this.ofmTilePath,
            maxZoom: 17,
            minZoom: 3,
            tms: currentUser.user.is_osm_defaultLayer,
        });

        this.rgisMOLayer = new L.TileLayer('http://{path}', {
            path: this.rgisMOTilePath,
            maxZoom: 17,
            minZoom: 3,
            tms: currentUser.user.is_osm_defaultLayer,
        });

        this.newWmsLayer = new L.TileLayer('http://{path}', {
            path: this.newWmsTilePath,
            maxZoom: 17,
            minZoom: 3,
            tms: currentUser.user.is_osm_defaultLayer,
        });

        this.wmsLayer = new L.tileLayer.wms(window.RNIS_SETTINGS.WMSRGISURL, {
            layers: window.RNIS_SETTINGS.CITY_OMSK ? 'rnis:rnis' : 'm10',
            format: 'image/png',
            maxZoom: 17,
        });


        let userZoom = null, userCenter = null;

        let objFromLs = JSON.parse(localStorage.getItem("users_settings"));
        if (objFromLs && objFromLs[currentUser.user.uuid]) {
            userZoom = objFromLs[currentUser.user.uuid].map.zoom;
            userCenter = objFromLs[currentUser.user.uuid].map.center;
        }

        let center = {}, zoom = 3;
        if (userCenter && window.RNIS_SETTINGS.MAPCENTER_F) {
            center = new L.LatLng(userCenter.lat, userCenter.lng);
            zoom = userZoom;
        }
        if (!userCenter && window.RNIS_SETTINGS.MAPCENTER_F) {
            center = new L.LatLng(47, 75);
            zoom = 3;
        }
        if (!window.RNIS_SETTINGS.MAPCENTER_F) {
            center = new L.LatLng(55.753559, 37.609218);
            zoom = 11;
        }

        L.Icon.Default.imagePath = '/img/vendor/leaflet/';
        this.map = new L.map(this.refs.container, {
            center: center,
            zoom: zoom,
            //maxZoom: 19,
            minZoom: 3,
            preferCanvas: false,
            layers: this.isMosreg() ? [
                this.osmLayer,
               // this.newWmsLayer,
            ] : this.isNso() ? [this.arcgisLayer] : [this.osmLayer],
            zoomControl: false,
        });

        /*this.locateBtn = L.control.locate({
            position: 'topright',
            onLocationError: (err) => {
                alerts.error('Ошибка определения местоположения.');
            },
            showPopup: false,
            strings: {
                title: "Определить местоположение",
            },
        });*/
        //this.locateBtn.addTo(this.map);

        if (this.isMosreg() || this.isNso() || this.isLocalhost() || this.isOmsk()) {
            let layers = {
                //'РГИС': this.isOmsk() ? this.wmsLayer : this.newWmsLayer,
                'РГИС': this.rgisMOLayer,
                'Основной': this.osmLayer,
                'РГИС НСО': this.arcgisLayer,
                'ОФМ': this.ofmLayer,
            };

            this.isMosreg() && delete layers['РГИС НСО'];

           // this.isNso() && delete layers['РГИС'];
           // this.isNso() && delete layers['ОФМ'];

           if (this.isNso()) {
               delete layers['РГИС'];
               delete layers['ОФМ'];
               layers['OpenStreetMap'] = layers['Основной'];
               delete layers['Основной'];
            }

           if (this.isOmsk() ) {
               delete layers['РГИС НСО'];
               delete layers['ОФМ'];
              // layers['OpenStreetMap'] = layers['Основной'];
              // delete layers['Основной'];
            }



            L.control.layers(layers).addTo(this.map);
        }

        this.props.onMapInit && this.props.onMapInit(this.map);

        this.map.on('mousemove', ::this.onMapMouseMove);
        this.map.on('moveend', ::this.onZoom);

        this.map.on('click', ::this.onMapClick);

        if (this.props.onDblClick) {
            this.map.doubleClickZoom.disable();
            this.map.on('dblclick', this.props.onDblClick);
        }

        setTimeout(() => {
            this.map.invalidateSize();
        }, 500);

        this.mapSearchObjectsLayer = new L.layerGroup([], {
            zIndexOffset: 99999,
        }).addTo(this.map);

        this.setState({map: this.map});
        this.props.onSetComponent(this.props.component);
    }

    onMapClick(e) {
        this.props.onClick(e.latlng.lat, e.latlng.lng);
    }

    toggleEditor(layerUuid) {
        if (layerUuid) {
            this.setState({showEditor: !this.state.showEditor, layerUuid, isEditing: true})
        } else {
            this.setState({showEditor: !this.state.showEditor, isEditing: false})
        }
    }

    getBoundingBox() {
        const bounds = this.map.getBounds();

        return {
            left_top: {
                latitude: bounds.getNorth(),
                longitude: bounds.getEast(),
            },
            right_bottom: {
                latitude: bounds.getSouth(),
                longitude: bounds.getWest(),
            },
        };
    }

    getMapSize() {
        const map = this.map;

        const size = map.getSize();
        return {
            width: size.x,
            height: size.y,
        };
    }

    getZoom() {
        return this.map.getZoom();
    }

    invalidateSize() {
        return this.map.invalidateSize();
    }

    setZoom(zoom) {
        this.map.setZoom(zoom);
    }

    onZoom() {
        this.props.onZoom();
        this.setMapSettingsToLocalStorage();
    }

    setMapSettingsToLocalStorage() {
        // текущие параметры карты
        let currentMap = {
            zoom: this.map.getZoom(),
            center: this.map.getBounds().getCenter()
        };
        // если переменной нет в localStorage - создай
        if (storage.get("users_settings") === null) {
            let lsObject = {};
            lsObject[currentUser.user.uuid] = {
                map: currentMap,
            };
            storage.save('users_settings', lsObject);
            // или обнови
        } else {
            let objFromLs = storage.get("users_settings");
            const vehiclePopupSettings = storage.get('map:vehicle:popup:kiutr')
            if (!objFromLs) {
                objFromLs = {}
            }
            objFromLs[currentUser.user.uuid] = {
                map: currentMap,
                'map:vehicle:popup:kiutr': vehiclePopupSettings // set map vehicle popup settings to user_settings localStorage
            };
            storage.save('users_settings', objFromLs);
        }
    }

    getLayers(props) {
        let layers = _.clone(props.layers);

        if (this.props.showSearch) {
            layers.push({
                uuid: 'map_search',
                title: 'Результаты поиска',
                layer: this.mapSearchObjectsLayer,
            });
        }

        return layers;
    }

    componentWillUpdate(props) {
        this.renderMapSearchObjects(props.mapSearchObjects);

        _.each(this.getLayers(props), (layer) => {
            if (layer.layer && !this.props.activeLayers[layer.uuid] && this.map) {
                this.map.removeLayer(layer.layer);
            }
            if (layer.layer && this.props.activeLayers[layer.uuid] && this.map) {
                this.map.addLayer(layer.layer);
            }
        });
    }

    showTraffic() {
        this.map.removeLayer(this.osmLayer);
        this.map.addLayer(this.trafficLayer);
    }

    hideTraffic() {
        this.map.removeLayer(this.trafficLayer);
        this.map.addLayer(this.osmLayer);
    }

    isUserObjectsDisplayed() {
        return this.props.displayUserObjects || (this.props.component === 'control') || (this.props.component === 'road') || (this.props.component === 'communal') || (this.props.component === 'garbage');
    }

    toggleFaq() {
        this.setState({
            faqOpened: !this.state.faqOpened,
        });
    }

    renderLegend() {
        return (
            <div className={classNames('faq', 'reports', {
                'expand': this.state.faqOpened,
            })}>
                <div className={classNames('faq__btn', {
                    'faq__btn_opened': this.state.faqOpened,
                })} onClick={::this.toggleFaq}></div>
                <div className="faq__inner big">
                    <div className="faq__title">Расшифровка обозначений на графике</div>
                    <div className="faq__content">
                        <div className="faq__row">
                            Работа выполнена
                            <div className="indicators indicators_report_green2"/>
                        </div>
                        <div className="faq__row">
                            Работа выполнена частично
                            <div className="indicators indicators_report_yellow" />
                        </div>
                        <div className="faq__row">
                            Работа не выполнена
                            <div className="indicators indicators_report_red2"/>
                        </div>
                        <div className="faq__row">
                            Объекты по подрядам
                            <div className="indicators indicators_report_blue"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let layersComponent;
        if (window.RNIS_SETTINGS.CITY_MURMANSK && this.props.component === 'analytics') {
            layersComponent = null;
        } else {
            layersComponent = <Layers
            ref="layers"
            toggleEditor={::this.toggleEditor}
            layers={this.getLayers(this.props)}
            map={this.map}
            parent={this}
            onStopPointClick={this.props.onStopPointClick}
            onStopPointDblClick={this.props.onStopPointDblClick}
            onStopPointForwardAddClick={this.props.onStopPointForwardAddClick}
            onStopPointReverseAddClick={this.props.onStopPointReverseAddClick}
            withoutStopPoints={this.props.withoutStopPoints}
            layersFilter={this.props.layersFilter}
            onRoadClick={this.props.onRoadClick}
            onRoadPartClick={this.props.onRoadPartClick}
            onRoadRepairPartClick={this.props.onRoadRepairPartClick}
            displayUserObjects={this.isUserObjectsDisplayed()}
            setSelectedRoutes={this.props.setSelectedRoutes}
            changeLayerCallback={this.props.changeLayerCallback ? ::this.props.changeLayerCallback : undefined}
            filters={this.props.stateFilters}
            />
        }

        const mapSearch = (this.map && this.props.showSearch) ? (
            <MapSearch ref="search" onClick={::this.fitBounds} map={this.map} parent={this}
                       component={this.props.component} searchByMO={this.props.searchByMO}/>
        ) : null;
        const mapZoomControls = this.map ? (
            <div className={classNames('leaflet-map-zoom-buttons', {
                'routing-active': this.state.routingActive,
                'routing-result-active': this.state.routingResultActive,
            })}>
                <ContextTooltip key="map.zoomin" code="map.zoomin" default="Приблизить"
                                position="left">
                    <MapZoomButton type="in" onClick={::this.zoomIn}/>
                </ContextTooltip>
                <ContextTooltip key="map.zoomout" code="map.zoomout" default="Отдалить"
                                position="left">
                    <MapZoomButton type="out" onClick={::this.zoomOut}/>
                </ContextTooltip>
                {this.props.withRouting ? (
                    <MapZoomButton type="routing" onClick={::this.routing} tooltip="Построить маршрут"/>
                ) : null}
            </div>
        ) : null;
        const routingControls = (this.state.routingActive && !this.state.routingResultActive) ? (
            <RoutingModal
                ref="routing"
                onClose={::this.closeRouting}
                data={this.state.routing}
                onChange={::this.onRoutingChange}
                routingFocus={::this.routingFocus}
                startRouting={::this.startRouting}
                suggestAddress={::this.suggestAddress}
            />
        ) : null;
        const routingResultControls = (this.state.routingActive && this.state.routingResultActive) ? (
            <RoutingResultModal
                ref="routing-results"
                onClose={::this.closeRouting}
                data={this.state.routingResult}
                onRoutingConfigure={::this.onRoutingConfigure}
                onRoutingReset={::this.onRoutingReset}
                activeRoutingResultItem={this.state.activeRoutingResultItem}
                setActiveRoutingResultItem={::this.setActiveRoutingResultItem}
                activeSegment={this.state.activeSegment}
                setActiveSegment={::this.setActiveSegment}
            />
        ) : null;

        const ruler = (
            <ContextTooltip
                key="map.ruler"
                code="map.ruler"
                default="Линейка"
                position="left"
            >
                <Ruler
                    map={this.map}
                    isActive={this.state.activeWidget === 'ruler'}
                    onToggle={this.toggleWidget}
                />
            </ContextTooltip>);
        const trafficLayer = (
            <ContextTooltip
                key="map.traffic"
                code="map.traffic"
                default="Пробки"
                position="left"
            >
                <TrafficLayer
                    map={this.map}
                    showTraffic={::this.showTraffic}
                    hideTraffic={::this.hideTraffic}
                />
            </ContextTooltip>
        );
        const weatherLayer = (
            <ContextTooltip
                key="map.weather"
                code="map.weather"
                default="Погода"
                position="left"
            >
                <WeatherLayer/>
            </ContextTooltip>
        );

        return (
            <div className={classNames('leaflet-map',
            this.map ? `zoom-${this.map.getZoom()}` : '',
            this.props.isHistoryMulti ? 'leaflet-map_multi' : '',
            {
                'routing-result-active': this.state.routingResultActive,
            },
            )}>
                {this.state.showEditor &&
                <LayerEditor
                    title="Создать/редактировать слой"
                    layerUuid={this.state.layerUuid}
                    isEditing={this.state.isEditing}
                    toggleEditor={::this.toggleEditor}
                />}
                {layersComponent}
                {this.props.component !== 'analytics' && !window.RNIS_SETTINGS.CITY_MURMANSK  ? mapSearch : null}
                {mapZoomControls}

                {this.props.component !== 'analytics' ? ruler : null}
                {!window.RNIS_SETTINGS.hide_traffic && this.props.component !== 'analytics' ? trafficLayer : null}
                {!window.RNIS_SETTINGS.hide_weather && this.props.component !== 'analytics' ? weatherLayer : null}
                {this.props.component !== 'analytics' ? routingControls : null}
                {this.props.component !== 'analytics' ? routingResultControls : null}

                {this.props.components}
                <div className="map-container" ref="container"/>
                <div style={{display: 'none'}}>
                    {this.renderRoutingRequestMarkers()}
                    {this.renderRoutingResult()}
                    {this.props.children}
                    {(this.props.showLayers && this.isUserObjectsDisplayed()) ? this.renderUserGeoObjects() : null}
                </div>
            </div>
        );
    }

    toggleWidget = (activeWidget) => {
        this.setState({activeWidget: activeWidget === this.state.activeWidget ? '' : activeWidget});
    }

    zoomIn() {
        this.map.setZoom(this.map.getZoom() + 1);
    }

    zoomOut() {
        this.map.setZoom(this.map.getZoom() - 1);
    }

    locate() {
        this.locateBtn._onClick();
        this.measureBtn._disableMeasure();
    }

    fitBounds(bounds) {
        this.map.fitBounds(bounds);
    }

    panTo(coordinates) {
        this.map.panTo(coordinates);
    }

    flyTo(coordinates, zoom) {
        this.map.flyTo(coordinates, zoom);
    }

    setZoom(zoom) {
        this.map.setZoom(zoom);
    }

    setZoomAround(coordinates, zoom) {
        this.map.setZoomAround(coordinates, zoom);
    }

    renderUserGeoObjects() {
        return _.map(this.props.userGeoObjects, (object) => {
            const bringToBack = object.title === 'Московская область';
            return (
                <MapGeojson
                    key={object.uuid}
                    map={this}
                    leafletMap={this.map}
                    geometry={object.geometry}
                    tooltip={object.title}
                    bringToBack={bringToBack}
                    popup={this.renderUserGeoObjectPopup.bind(this, object)}
                   // color={_.isEqual(object.geometry, this.props.selectedSearchResult) ? 'green' : 'blue'}
                    color={object.color ? object.color : _.isEqual(object.geometry, this.props.selectedSearchResult) ? 'green' : 'blue'}
                    onClick={this.props.searchByMO ? this.selectMO.bind(this, object.geometry) : null}
                />
            );
        });
    }

    selectMO(geometry) {
        this.refs.search.getWrappedInstance().selectMO(geometry);
    }

    renderUserGeoObjectPopup(object, onClose) {
        const layer = _.find(this.props.userGeoObjectsLayers, {uuid: object.layer_uuid});

        const center = (new L.geoJSON(object.geometry)).getBounds().getCenter();

        return (
            <div
                className={classNames('b-modal b-modal-map map-tooltip-modal user-geo-objects tooltip-ts')}>
                <div className="b-modal__header">
                    <a href="javascript:void(0)" className="b-modal__close" onClick={onClose}/>
                    <div className="b-modal__title">{object.title}</div>
                    <div className="b-modal__type">
                        ({layer.title})
                    </div>
                    <div className="b-modal__coordinates">
                        {_.round(center.lat, 6)} {_.round(center.lng, 6)}
                    </div>
                </div>
                <div className="b-modal__body">
                    {_.map(layer.parameters || [], (label, index) => {
                        const value = _.get(object.parameters, index);
                        return (
                            <div key={index} className="b-block">
                                <div className="b-block__desc">{label}</div>
                                <div className="b-block__value">{value}</div>
                            </div>
                        );
                    })}
                </div>
                <div className="b-modal__footer">
                    {
                        !window.RNIS_SETTINGS.CITY_MURMANSK && (
                            <a title="Скрыть слой" href="javascript:void(0)" className="b-button b-button_red"
                            onClick={this.disableLayer.bind(this, layer)}>
                                <i className="button__icon button__icon_visible"/>
                            </a>
                        )
                    }
                    <a title="Перейти к редактированию объекта" href={`/settings/kiutr/map?uuid=${object.uuid}`} target="_blank"
                       className="b-button b-button_red">
                        <i className="button__icon button__icon_history"/>
                    </a>
                </div>
            </div>
        );
    }

    async disableLayer(layer) {
        await this.props.toggleLayer(layer.uuid);

        this.map.fire('moveend');
    }

    createUserGeoObjectIcon() {
        let type = 'system';

        const html = ReactDOMServer.renderToStaticMarkup(<MapVehicleIcon device={{type}}/>);

        let className = `marker marker_type_${type} marker_sm`;
        let size = 14;
        return new L.DivIcon({
            html: html,
            className: className,
            iconSize: new L.Point(size, size),
            iconAnchor: new L.Point(size / 2, size / 2),
        });
    }

    renderMapSearchObjects(mapSearchObjects) {
        if (!this.mapSearchObjectsLayer) {
            return;
        }
        this.mapSearchObjectsLayer.clearLayers();

        _.each(mapSearchObjects, (object) => {
            try {
                const geometry = {
                    type: 'Feature',
                    geometry: object.geometry,
                };
                const color = _.isEqual(object.geometry, this.props.selectedSearchResult) ? '#00FF00' : '#0000FF';
                let layer = new L.GeoJSON(geometry, {
                    zIndexOffset: 99999,
                    style: {
                        color,
                    },
                    pointToLayer: function (feature, latlng) {
                        return new L.circleMarker(latlng, {
                            radius: 15,
                            color,
                            weight: 1,
                            opacity: 1,
                            fillOpacity: 0.5,
                        });
                    },
                });

                if (object.hint) {
                    layer.bindTooltip(object.hint, {sticky: true});
                }
                if (object.popup) {
                    layer.bindPopup(object.popup);
                }

                this.mapSearchObjectsLayer.addLayer(layer);
            } catch (e) {
            }
        });
    }

    exportToPdf() {
        leafletImage(this.map, (err, canvas) => {
            const imgData = canvas.toDataURL("image/svg+xml", 1.0);
            const dimensions = this.map.getSize();

            /*const pdf = new jsPDF('l', 'pt', 'letter');
            pdf.addImage(imgData, 'PNG', 10, 10, dimensions.x * 0.5, dimensions.y * 0.5);

            pdf.save('report.pdf');*/
        });
    }

    toggleWidget = (activeWidget) => {
        this.setState({activeWidget: activeWidget === this.state.activeWidget ? '' : activeWidget});
    }

    setActiveRoutingResultItem(index) {
        if (index < 0) {
            index = this.state.routingResult.items.length - 1;
        }
        if (index >= this.state.routingResult.items.length) {
            index = 0;
        }
        this.setState({
            activeRoutingResultItem: index,
        });
    }

    async suggestAddress(input, callback) {
        if (!input) {
            callback(null, {
                options: [],
                complete: false,
            });
            return;
        }

        const bounds = this.map.getBounds();
        const boundingBox = {
            left: bounds.getNorth(),
            top: bounds.getEast(),
            right: bounds.getSouth(),
            bottom: bounds.getWest(),
        };

        const response = await this.props.searchAddress(input, boundingBox, this.getZoom());

        if (response.isOk) {
            callback(null, {
                options: _.sortBy(_.map(response.payload.items, (item) => ({
                    value: item.name,
                    label: item.name,
                    latitude: item.coordinates.latitude,
                    longitude: item.coordinates.longitude,
                })), 'label'),
                complete: false,
            });
        } else {
            response.showErrors();
        }
    }

    onRoutingConfigure() {
        this.setState({
            routingResultActive: false,
        });
    }

    onRoutingReset() {
        this.setState({
            routingResultActive: false,
            routing: _.cloneDeep(this.defaultRouting),
        });
    }

    async onRoutingChange(field, value, ignore = false) {
        if (field === 'max_transfers') {
            value = _.toInteger(value);
        }

        let routing = this.state.routing;
        _.set(routing, field, value);
        await this.setState({routing});

        if (ignore) {
            return;
        }

        const addressMatches = /^(.+)\.address$/.exec(field);
        if (addressMatches) {
            this.geocodeDebounce(addressMatches[1]);
        }
    }

    async geocode(focusRoute) {
        const bounds = this.map.getBounds();

        const boundingBox = {
            left: bounds.getNorth(),
            top: bounds.getEast(),
            right: bounds.getSouth(),
            bottom: bounds.getWest(),
        };
        const response = await this.props.geocode(_.get(this.state.routing, `${focusRoute}.address`), boundingBox, this.getZoom());
        if (response.isOk) {
            this.onRoutingChange(`${focusRoute}.latitude`, response.payload.coordinates.latitude);
            this.onRoutingChange(`${focusRoute}.longitude`, response.payload.coordinates.longitude);
        }
    }

    routingFocus(focusRoute) {
        this.setState({focusRoute});
    }

    onMapClick(e) {
        if (!this.state.routingActive || !this.state.focusRoute) {
            return;
        }
        if (/transit/.test(this.state.focusRoute)) {
            this.findClosestStopPoint(e.latlng.lat, e.latlng.lng);
            return;
        }

        this.reverseGeocode(e.latlng.lat, e.latlng.lng, this.state.focusRoute);
        this.setState({
            focusRoute: null,
        });
    }

    async findClosestStopPoint(latitude, longitude) {
        const response = await this.props.getClosestStopPoint(latitude, longitude);

        if (response.isOk) {
            const stopPoint = response.payload;

            this.onRoutingChange(`${this.state.focusRoute}.address`, stopPoint.title, true);
            this.onRoutingChange(`${this.state.focusRoute}.latitude`, stopPoint.latitude);
            this.onRoutingChange(`${this.state.focusRoute}.longitude`, stopPoint.longitude);

            this.setState({
                focusRoute: null,
            });
        } else {
            response.showErrors();
        }
    }

    async onStopPointClick(stopPoint) {
        this.onRoutingChange(`${this.state.focusRoute}.address`, stopPoint.title, true);
        this.onRoutingChange(`${this.state.focusRoute}.latitude`, stopPoint.latitude);
        this.onRoutingChange(`${this.state.focusRoute}.longitude`, stopPoint.longitude);

        this.setState({
            focusRoute: null,
        });
    }

    onMapMouseMove(e) {
        if (!this.state.routingActive || !this.state.focusRoute) {
            return;
        }

        this.onRoutingChange(`${this.state.focusRoute}.latitude`, e.latlng.lat);
        this.onRoutingChange(`${this.state.focusRoute}.longitude`, e.latlng.lng);
    }

    onMarkerDragEnd(focusRoute, {target}) {
        const {lat, lng} = target.getLatLng();
        this.onRoutingChange(`${focusRoute}.latitude`, lat);
        this.onRoutingChange(`${focusRoute}.longitude`, lng);
        this.reverseGeocode(lat, lng, focusRoute);
    }

    async reverseGeocode(latitude, longitude, focusRoute) {
        this.onRoutingChange(`${focusRoute}.latitude`, latitude);
        this.onRoutingChange(`${focusRoute}.longitude`, longitude);

        const bounds = this.map.getBounds();

        const boundingBox = {
            left: bounds.getNorth(),
            top: bounds.getEast(),
            right: bounds.getSouth(),
            bottom: bounds.getWest(),
        };
        const response = await this.props.reverseGeocode({latitude, longitude}, boundingBox, this.getZoom());
        if (response.isOk) {
            this.onRoutingChange(`${focusRoute}.address`, response.payload.address, true);
        }
    }

    async routing() {
        await this.setState({
            routingActive: !this.state.routingActive,
        });
    }

    async closeRouting() {
        await this.setState({
            routingActive: false,
            routingResultActive: false,
            routing: _.cloneDeep(this.defaultRouting),
        });
    }

    renderRoutingRequestMarkers() {
        if (!this.state.routingActive) {
            return;
        }

        const routing = this.state.routing;

        return _.concat([
            (routing.from.latitude && routing.from.longitude) ? (
                <RoutingMarker
                    key="from"
                    map={this}
                    leafletMap={this.map}
                    latitude={routing.from.latitude}
                    longitude={routing.from.longitude}
                    type="from"
                    onDrag={this.onMarkerDragEnd.bind(this, 'from')}
                    options={{
                        //interactive: this.state.focusRoute !== 'from',
                    }}
                />
            ) : null,
            (routing.to.latitude && routing.to.longitude) ? (
                <RoutingMarker
                    key="to"
                    map={this}
                    leafletMap={this.map}
                    latitude={routing.to.latitude}
                    longitude={routing.to.longitude}
                    type="to"
                    onDrag={this.onMarkerDragEnd.bind(this, 'to')}
                    options={{
                        //interactive: this.state.focusRoute !== 'to',
                    }}
                />
            ) : null,
        ], _.map(routing.transit, (transit, index) => {
            if (transit.latitude && transit.longitude) {
                return (
                    <RoutingMarker
                        key={`transit:${index}`}
                        map={this}
                        leafletMap={this.map}
                        latitude={transit.latitude}
                        longitude={transit.longitude}
                        onDrag={this.onMarkerDragEnd.bind(this, `transit.${index}`)}
                        options={{
                            //interactive: this.state.focusRoute !== `transit.${index}`,
                        }}
                    />
                );
            }

            return null;
        }));
    }

    renderRoutingResult() {
        if (!this.state.routingActive || !this.state.routingResultActive) {
            return;
        }
        const result = this.state.routingResult;
        const item = _.get(result.items, this.state.activeRoutingResultItem);
        if (!item) {
            return;
        }

        return _.map(item.segments, (segment, index) => {
            const isActive = this.state.activeSegment === index;

            const from = _.first(segment.geometry.coordinates);

            switch (segment.mode) {
                case 'walk':
                    const tooltip = `<i class="p-result__icon p-result__icon_wh24 p-result__icon_walk"></i> ${segment.time}мин, ${formatDistance(segment.distance)}`;
                    return [
                        <MapGeojson
                            key={index}
                            map={this}
                            leafletMap={this.map}
                            geometry={segment.geometry}
                            color="#000000"
                            weight={isActive ? 8 : 2}
                            onClick={this.setActiveSegment.bind(this, index)}
                        />,
                        <RoutingMarker
                            key={`${index}:from`}
                            map={this}
                            leafletMap={this.map}
                            latitude={from[1]}
                            longitude={from[0]}
                            tooltip={isActive ? `<b>${tooltip}</b>` : tooltip}
                            onClick={this.setActiveSegment.bind(this, index)}
                            permanentTooltip={true}
                            zIndex={isActive ? 100 : 10}
                            options={{
                                interactive: true,
                                opacity: 0,
                            }}
                        />,
                    ];
                case 'bus':
                    const tooltipBus = `<i class="p-result__icon p-result__icon_wh24 p-result__icon_bus"></i> ${segment.route_number}, ${segment.time}мин`;
                    return _.concat([
                        <MapGeojson
                            key={index}
                            map={this}
                            leafletMap={this.map}
                            geometry={segment.geometry}
                            color="#FF0000"
                            weight={isActive ? 8 : 2}
                            onClick={this.setActiveSegment.bind(this, index)}
                        />,
                        <RoutingMarker
                            key={`${index}:from`}
                            map={this}
                            leafletMap={this.map}
                            latitude={from[1]}
                            longitude={from[0]}
                            tooltip={isActive ? `<b>${tooltipBus}</b>` : tooltipBus}
                            onClick={this.setActiveSegment.bind(this, index)}
                            permanentTooltip={true}
                            zIndex={isActive ? 100 : 10}
                            options={{
                                interactive: true,
                                opacity: 0,
                            }}
                        />,
                    ], _.map(segment.stop_points, (stopPoint, stopPointIndex) => {
                        if (!isActive && stopPointIndex !== 0) {
                            return;
                        }
                        return (
                            <StopPointMarker
                                key={`${index}:${stopPointIndex}`}
                                map={this}
                                leafletMap={this.map}
                                latitude={stopPoint.latitude}
                                longitude={stopPoint.longitude}
                                color="red"
                                item={{
                                    title: (stopPointIndex === 0) ? `${stopPoint.name}, "${segment.route_number}", ${segment.time}мин` : stopPoint.name,
                                }}
                                withoutPopup={true}
                                zIndex={9999}
                            />
                        );
                    }));
            }
        });
    }

    async invalidateSize() {
        await setTimeout(() => {
            this.map.invalidateSize();
        }, 500);
    }

    async startRouting() {
        this.invalidateSize();
        /*await this.setState({
            routingResult: payload,
            routingResultActive: true,
        });
        return;*/

        const routing = this.state.routing;
        if (!routing.from.latitude || !routing.from.longitude) {
            alerts.alert('Не задан начальный пункт');
            return;
        }
        if (!routing.to.latitude || !routing.to.longitude) {
            alerts.alert('Не задан конечный пункт');
            return;
        }

        this.setState({
            loading: true,
        });

        const response = await this.props.routing({
            from: routing.from,
            to: routing.to,
            transit: routing.transit,
            datetime: moment(moment(routing.date).format(formats.DATE) + ' ' + routing.time, formats.DATETIME_SHORT).format(formats.DATETIME_API),
            max_time: this.timeToInt(routing.max_time),
            max_transfers: routing.max_transfers,
            modes: this.getRoutingModes(),
            walk_reluctance: this.getWalkReluctance(routing.walk_reluctance),
            is_wheelchair: routing.is_wheelchair,
            version: 2,
        });

        this.setState({
            loading: false,
        });

        if (response.isOk) {
            await this.setState({
                routingResult: this.prepareRouting(response.payload),
                routingResultActive: true,
            });
        } else {
            response.showErrors();
        }
    }

    prepareRouting(payload) {
        let transitIndex = 0;

        payload.items = _.map(payload.items, (item) => {
            item.segments = _.map(item.segments, (segment) => {
                segment.stop_points = _.map(segment.stop_points || [], (stopPoint) => {
                    if (stopPoint.name === 'Конечный пункт') {
                        const transit = _.get(this.state.routing.transit, transitIndex);
                        if (transit) {
                            stopPoint.name = transit.address || '-';
                            transitIndex++;
                        }
                    }

                    return stopPoint;
                });

                return segment;
            });

            return item;
        });

        return payload;
    }

    getWalkReluctance(walkReluctance) {
        switch (walkReluctance) {
            case 0:
                return 16;
            case 1:
                return 4;
            case 2:
                return 2;
            case 3:
                return 1;
        }
    }

    timeToInt(time) {
        const timeObject = moment(time, formats.TIME);
        return timeObject.hours() * 60 + timeObject.minutes();
    }

    getRoutingModes() {
        let modes = [];

        modes.push('WALK');
        if (this.state.routing.mode_bus) {
            modes.push('TRANSIT');
        }

        return modes;
    }

    async setActiveSegment(index) {
        await this.setState({
            activeSegment: (this.state.activeSegment !== index) ? index : null,
        });

        const segment = _.get(this.state.routingResult, `items.${this.state.activeRoutingResultItem}.segments.${this.state.activeSegment}`);
        if (segment) {
            this.map.fitBounds((new L.geoJSON(segment.geometry)).getBounds(), {
                padding: new L.point(20, 20),
            });
        }
    }
}
