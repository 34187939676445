import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import InputElement from 'react-input-mask';
import moment from "moment";
import formats from "dictionaries/formats";

@propTypes({
    placeholder: PropTypes.string,
    name: PropTypes.string,
    mask: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    success: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

    value: PropTypes.string,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    withTimeIcon: PropTypes.bool,
    minTime: PropTypes.string,
    maxTime: PropTypes.string,
})

@defaultProps({
    value: '',
    mask: '',
    onChange: () => {
    },
    placeholder: '',
    type: 'text',
    size: 'lg',
    withTimeIcon: false,
})

export default class TextFieldMask extends Component {

    state = {
        valid: true,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.minTime !== this.props.minTime || prevProps.maxTime !== this.props.maxTime) {
            this.setState({
                valid: this.checkValidTime(this.props.value),
            })
        }
    }

    checkValidTime (currentValue) {
        const time = moment(currentValue, formats.TIME);

        if (currentValue === '__:__') return true
        if (currentValue.includes('_') & currentValue !== '__:__') {
            return false
        }
        if (!time.isValid()) return false

        if (this.props.maxTime) {
            const maxTime = moment(this.props.maxTime, formats.TIME);
            if (maxTime.isBefore(time)) return false
        }

        if (this.props.minTime) {
            const minTime = moment(this.props.minTime, formats.TIME);
            if (minTime.isAfter(time)) return false
        }

        return true
    }

    render() {
        const textFieldState = this.getCurrentTextfieldState();
        const className = classNames(
            'text-field', `text-field-${this.props.size}`,
            textFieldState ? `text-field-${textFieldState}` : '');

        const style = {};

        const Hint = this.renderHint();

        let props = Object.assign({}, this.props);
        delete props.error;
        delete props.withTimeIcon;

        return (
            <div className={className} style={style}>
                <InputElement mask={this.props.mask} {...props} onChange={::this.onChange}/>
                {this.props.withTimeIcon ? (
                    <span className="b-icon-link b-icon-link_icon_timepicker datepicker__icon"/>
                ) : null}
                {Hint}
            </div>
        )
    }

    onChange(e) {
        if (this.props.withTimeIcon) {
            this.setState({
                valid: this.checkValidTime(e.target.value)
            })
        }

        this.props.onChange(e);
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.props.onEnter();
        }
    }

    getCurrentTextfieldState() {
        if (this.props.success)
            return 'success';

        if (this.props.error)
            return 'error';

        if (this.props.withTimeIcon && !this.state.valid) {
            return 'error';
        }

        return false;
    }

    renderHint() {
        if (this.props.success && this.props.success.length > 0) {
            return (<div className="hint">{this.props.success}</div>);
        }
        else if (this.props.error && this.props.error.length) {
            return (
                <div className="input__style input__style_state_wrong">
                    {this.props.error ? <span
                        className="input__style_state_msg input__style_state_msg_wrong">{this.props.error}</span> : null}
                </div>
            )
        }
    }

}