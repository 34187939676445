import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {CycleFetch} from 'helpers/api';
import {List, Map} from 'immutable';
import _ from 'lodash';

import Page from 'components/ui/page';
import * as storage from 'utils/storage';

import {connect} from 'react-redux';
import {
    getHistory, getHistoryByVehicle, getTelematicsBoundsWithDevices,
    getTelematicsWithDevices
} from 'store/reducers/maps';

import Leaflet from '../leaflet';
import L from 'leaflet';

import GlobalLoaderComponent from "components/ui/global-loader";
import {getVehicleListForPortal} from "store/reducers/vehicles/vehicles";
import debounce from 'throttle-debounce/debounce';
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getBnsoList} from "store/reducers/vehicles/bnso";
import {getOption} from "store/reducers/settings";
import {activateLayer} from "store/reducers/user-map-objects/layers";
import 'rc-slider/assets/index.css';
import MapFullscreenControl from "components/ui/map/controls/fullscreen/index";
import {getUnits} from "store/reducers/organizational_units/units";
import CheckboxDropdown from "components/ui/checkbox-dropdown";
import {getRouteRegistries} from "store/reducers/kiutr/route_registries";
import {getOrderVehicles} from "store/reducers/kiutr/orders/orders";
import {getRoute, getRoutes} from "store/reducers/routes/route_editor";
import DirectionalPolyline from "components/ui/map/objects/directional-polyline";
import {getRouteVariants} from "store/reducers/routes/route_variants";
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import {getStopPoints} from "store/reducers/geo/stop-points";
import {getEvents} from "store/reducers/vehicles/events";
import {requestTelematics} from "helpers/telematics/requester";
import {getNotification, getNotifications, getNotificationsList} from "store/reducers/notifications";
import {getTasks} from "store/reducers/kurs/tasks";
import {getUserGeoObjects} from "store/reducers/user-map-objects/object_editor";
import './index.less';

@connect(state => ({}), {
    getTelematicsWithDevices,
    getTelematicsBoundsWithDevices,
    getVehicleListForPortal,
    getDictionaryList,
    getBnsoList,
    getOption,
    activateLayer,
    getHistory,
    getHistoryByVehicle,
    getUnits,
    getRouteRegistries,
    getOrderVehicles,
    getRoute,
    getRoutes,
    getRouteVariants,
    getStopPoints,
    getEvents,
    getNotificationsList,
    getNotification,
    getTasks,
    getUserGeoObjects,
})

export default class MapFullComponent extends Component {

    zoomDebounce = debounce(1000, ::this.onZoomLoad);

    _cycleFetch = null;

    state = {
        loading: true,
        telematics: new Map({}),
        clusters: [],
        sourceType: 't1sync',
        allVehicles: {},
        vehicles: {},
        component: null,
        gatn_responsives: [],
        vehicle_models: [],
        vehicle_marks: [],
        vehicle_types: [],
        bnso_types: [],
        selectedVehicles: null,
        clustersActive: false,
        selectedVehicle: null,
        selectedRoute: null,

        fullscreen: false,
        useOrders: true,

        units: [],
        selectedUnits: null,

        routeRegistries: [],
        selectedRouteRegistries: null,

        orderVehicles: [],

        route: null,
        routes: [],
        routePanelOpened: false,
        routeStopPoints: {},

        showEvents: false,
        events: [],

        animationActive: true,

        notificationInited: false,
        notification: null,

        tasks: [],
        work_types: [],

        vehicleEditUuid: null,
    };

    componentWillMount() {
        this._cycleFetch = new CycleFetch(
            () => {
                if (!this.refs.map) {
                    return null;
                }

                const boundingBox = this.refs.map.getBoundingBox();
                const mapSize = this.refs.map.getMapSize();
                const devices = _.keys(this.state.vehicles);

                const promise = requestTelematics(devices, this.state.telematics.toJS(), {
                    boundingBox,
                    mapSize,
                    zoom: this.refs.map.getZoom(),
                    clustersActive: this.state.clustersActive
                });
                promise.then((response) => {
                    let telematics = {};
                    let clusters = [];

                    _.each(_.get(response, 'payload.items', []), (item) => {
                        const latitude = item[0];
                        const longitude = item[1];
                        const type = item[2];

                        if (type === 'cluster') {
                            const info = item[3];
                            clusters.push([
                                latitude,
                                longitude,
                                info,
                            ]);
                        } else {
                            const deviceId = item[3];
                            const course = item[4];
                            const speed = item[5];
                            const type = item[6];
                            const time = item[7];
                            const animationHistoryData = item[8];

                            telematics[deviceId] = [
                                latitude,
                                longitude,
                                course,
                                speed,
                                type,
                                time,
                                animationHistoryData,
                            ];
                        }

                    });
                    this.setState({telematics: new Map(telematics), clusters, loading: false});
                }, () => {
                    this.setState({loading: false});
                });
                return promise;
            },
            () => {
            }, this.speed);

        this._cycleFetch.run();
    }

    componentWillUnmount() {
        this._cycleFetch.stop();
        delete this['_cycleFetch'];
    }

    async loadDictionaries(dictionaries, component = null) {
        _.each(dictionaries, async (dictionary) => {
            const documents = await this.getDictionary(dictionary, component);

            let state = this.state;
            state[dictionary] = documents;
            this.setState(state);
        });
    }

    async getDictionary(dictionary, component = null) {
        const response = await this.props.getDictionaryList(dictionary, {
            order: {
                column: 'name',
                direction: 'asc',
            },
            filters: {
                withComponent: component,
            },
        });
        if (response.isOk) {
            return _.keyBy(response.payload.documents, 'uuid');
        } else {
            response.showErrors();
        }
    }

    async componentDidMount() {
        await this.setState({
            component: 'kiutr',
            useOrders: true,
        });
        this.setState({
            clustersActive: true,
        });

        this.loadDictionaries([
            'vehicle_models',
            'vehicle_types',
        ]);
        this.loadRoutes();
    }

    async loadRoutes() {
        const response = await this.props.getRoutes({
            filters: {
                withComponent: 'kiutr',
                onlyToday: true,
            },
        });

        if (response.isOk) {
            this.setState({
                routes: _.sortBy(_.map(response.payload.items, (route) => ({
                    value: route.uuid,
                    label: route.title,
                    default_variant: route.default_variant,
                })), 'label'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadStopPoints(uuids) {
        const response = await this.props.getStopPoints({
            filters: {
                withUuid: uuids,
            },
        });
        if (response.isOk) {
            this.setState({
                routeStopPoints: _.keyBy(response.payload.items, 'uuid'),
            })
        } else {
            response.showErrors();
        }
    }

    async loadVehicles() {
        let filters = {
            withActiveBnso: true,
            withComponent: this.state.component,
            withRoute: this.state.selectedRoute,
        };
        const response = await this.props.getVehicleListForPortal({
            filters,
        });

        if (response.isOk) {
            const vehicles = _.keyBy(response.payload.items, 'current_bnso.bnso_number');

            await this.setState({
                vehicles,
                allVehicles: vehicles,
            });
            this._cycleFetch && this._cycleFetch.forceNext();
        } else {
            response.showErrors();
            this.setState({loading: false});
        }
    }

    reload() {
        this._cycleFetch.forceNext();
    }

    render() {
        const loader = this.state.loading === true
            ? <GlobalLoaderComponent block={false} style={{zIndex: 99999}}/>
            : '';

        let components = [];

        let title = ``;

        components.push(<MapFullscreenControl
            key="map-fullscreen"
            active={this.state.fullscreen}
            toggle={::this.toggleFullscreen}
        />);

        return (
            <Page pageId="MapFull" title={title}
                  headerContents={this.renderHeaderContents()}
                  fullscreen={this.state.fullscreen}
                  withoutMenu={true}
            >
                {loader}
                <Leaflet
                    ref="map"
                    {...this.props}
                    showLayers={false}
                    showSearch={false}
                    component={this.state.component}
                    telematics={this.state.telematics}
                    vehicles={this.state.vehicles}
                    clusters={this.state.clusters}
                    onClusterRendered={::this.onClusterRendered}
                    onZoom={::this.zoomDebounce}
                    components={components}
                    animationActive={true}
                    data={{
                        vehicle_models: this.state.vehicle_models,
                        vehicle_types: this.state.vehicle_types,
                    }}
                >
                    {this.renderRoutes()}
                </Leaflet>
            </Page>
        );
    }

    async toggleFullscreen() {
        await this.setState({
            fullscreen: !this.state.fullscreen,
        });
        setTimeout(() => {
            this.refs.map.invalidateSize();
        }, 200);
    }

    onClusterRendered() {
        this.setState({loading: false});
    }

    onZoomLoad() {
        this._cycleFetch && this._cycleFetch.forceNextWithDelay(300);
    }

    renderHeaderContents() {
        let headerContents = [];

        let selected = {};
        selected[this.state.selectedRoute] = true;

        headerContents.push(
            <CheckboxDropdown
                key="route-select"
                items={this.state.routes}
                selectedItems={selected}
                toggleSelectedItem={::this.toggleSelectedRoute}
                contextKey="kiutr.route-select"
                contextDefault="Выбор маршрута"
                icon="route"
            />
        );
        return headerContents;
    }

    async toggleSelectedRoute(uuid) {
        await this.setState({
            selectedRoute: (this.state.selectedRoute !== uuid) ? uuid : null,
        });

        if (this.state.selectedRoute) {
            const routeVariant = _.find(this.state.routes, {value: this.state.selectedRoute}).default_variant;

            const uuids = _.uniq(_.filter(_.map(routeVariant.forward_points, 'type_uuid')));

            this.loadStopPoints(uuids);

            this.loadVehicles();
        }
    }

    renderRoutes() {
        if (this.state.selectedRoute) {
            return this.renderRoute(_.find(this.state.routes, {value: this.state.selectedRoute}).default_variant);
        }
    }

    renderRoute(route) {
        let coordinates = [];
        _.each(route.forward_points, (point) => {
            coordinates = _.concat(coordinates, _.map(_.get(point, 'path_to_the_next_point_geometry.coordinates', []), (coord) => _.clone(coord).reverse()));
        });

        return (
            <div key={route.uuid}>
                {route.forward_points.map(::this.renderRoutePoint)}
                <DirectionalPolyline
                    key="route"
                    map={this.refs.map.refs.map}
                    leafletMap={this.refs.map.refs.map.getWrappedInstance().map}
                    color="blue"
                    coordinates={coordinates}
                    tooltip={route.label}
                />
            </div>
        );
    }

    renderRoutePoint(point, index) {
        if (!point.latitude || !point.longitude || point.point_type !== 'stop_point') {
            return null;
        }

        return (
            <StopPointMarker
                key={`${index}:${point.type_uuid}`}
                map={this.refs.map.refs.map}
                leafletMap={this.refs.map.refs.map.getWrappedInstance().map}
                latitude={point.latitude}
                longitude={point.longitude}
                item={this.state.routeStopPoints[point.type_uuid]}
                color="green"
                options={{
                    zIndexOffset: 100,
                }}
            />
        );
    }
}