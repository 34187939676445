export const GET_DICTIONARIES = 'app/maps/GET_DICTIONARIES';
export function getDictionaries(payload) {
	return {
		type: GET_DICTIONARIES,
		payload
	};
}

export const SET_DICTIONARIES = 'app/maps/SET_DICTIONARIES';
export function setDictionaries(payload) {
	return {
		type: SET_DICTIONARIES,
		payload
	};
}

export const ERROR_DICTIONARY = 'app/maps/ERROR_DICTIONARY';
export function errorDictionary(payload) {
	return {
		type: ERROR_DICTIONARY,
		payload
	};
}

export const SET_UNITS = 'app/maps/SET_UNITS';
export function setUnits(payload) {
	return {
		type: SET_UNITS,
		payload
	};
}

export const SET_CARRIER_UNITS = 'app/maps/SET_CARRIER_UNITS';
export function setCarrierUnits(payload) {
	return {
		type: SET_CARRIER_UNITS,
		payload
	};
}

export const SET_BNSO_LIST = 'app/maps/SET_BNSO_LIST';
export function setBnsoList(payload) {
	return {
		type: SET_BNSO_LIST,
		payload
	};
}

export const SET_COMPONENT = 'app/maps/SET_COMPONENT';
export function setComponent(payload) {
	return {
		type: SET_COMPONENT,
		payload
	};
}

export const SET_ROAD = 'app/maps/SET_ROAD';
export function setRoad(payload) {
	return { type: SET_ROAD, payload };
}

export const SET_ROAD_PART = 'app/maps/SET_ROAD_PART';
export function setRoadPart(payload) {
	return { type: SET_ROAD_PART, payload };
}

export const SET_ROAD_REPAIR_PART = 'app/maps/SET_ROAD_REPAIR_PART';
export function setRoadRepairPart(payload) {
	return { type: SET_ROAD_REPAIR_PART, payload };
}

export const SET_LOADING = 'app/maps/SET_LOADING';
export function setLoading(payload) {
	return { type: SET_LOADING, payload };
}

export const CLEAR_ROADS_DATA = 'app/maps/CLEAR_ROADS_DATA';
export function clearRoadsData(payload) {
	return { type: CLEAR_ROADS_DATA, payload };
}

export const SET_HISTORY_ORDERS = 'app/maps/SET_HISTORY_ORDERS';
export function setHistoryOrders(payload) {
	return { type: SET_HISTORY_ORDERS, payload };
}

export const SET_HISTORY_ORDER_EXECUTIONS = 'app/maps/SET_HISTORY_ORDER_EXECUTIONS';
export function setHistoryOrderExecutions(payload) {
	return { type: SET_HISTORY_ORDER_EXECUTIONS, payload };
}

export const SET_MO = 'app/maps/SET_MO';
export function setMo(payload) {
	return { type: SET_MO, payload };
}

export const SET_MUNICIPALITIES = 'app/maps/SET_MUNICIPALITIES';
export function setMunicipalities(payload) {
	return { type: SET_MUNICIPALITIES, payload };
}

export const SET_FILTERS = 'app/maps/SET_FILTERS';
export function setFilters(payload) {
	return { type: SET_FILTERS, payload };
}

export const SET_DIAPASON = 'app/maps/SET_DIAPASON';
export function setDiapason(payload) {
	return { type: SET_DIAPASON, payload };
}

export const SET_ANALITIC_DIAPASON = 'app/maps/SET_ANALITIC_DIAPASON';
export function setAnaliticDiapason(payload) {
	return { type: SET_ANALITIC_DIAPASON, payload };
}

export const SET_SHOW_EVENTS = 'app/maps/SET_SHOW_EVENTS';
export function setShowEvents(payload) {
	return { type: SET_SHOW_EVENTS, payload };
}

export const SET_SHOW_HISTORY = 'app/maps/SET_SHOW_HISTORY';
export function setShowHistory(payload) {
	return { type: SET_SHOW_HISTORY, payload };
}

export const SET_SHOW_HISTORY_SLIDER = 'app/maps/SET_SHOW_HISTORY_SLIDER';
export function setShowHistorySlider(payload) {
	return { type: SET_SHOW_HISTORY_SLIDER, payload };
}

export const SET_ROUTE = 'app/maps/SET_ROUTE';
export function setRoute(payload) {
	return { type: SET_ROUTE, payload };
}

export const SET_ROUTES = 'app/maps/SET_ROUTES';
export function setRoutes(payload) {
	return { type: SET_ROUTES, payload };
}

export const SET_ROUTE_STOP_POINTS = 'app/maps/SET_ROUTE_STOP_POINTS';
export function setRouteStopPoints(payload) {
	return { type: SET_ROUTE_STOP_POINTS, payload };
}

export const SET_SELECTED_ROUTES = 'app/maps/SET_SELECTED_ROUTES';
export function setSelectedRoutes(payload) {
	return { type: SET_SELECTED_ROUTES, payload };
}

export const SET_SELECTED_ROUTE_VARIANTS = 'app/maps/SET_SELECTED_ROUTE_VARIANTS';
export function setSelectedRouteVariants(payload) {
	return { type: SET_SELECTED_ROUTE_VARIANTS, payload };
}

export const SET_VEHICLE_FOR_POPUP = 'app/maps/SET_VEHICLE_FOR_POPUP';
export function setVehicleForPopup(payload) {
	return { type: SET_VEHICLE_FOR_POPUP, payload };
}

export const SET_TELEMATICS = 'app/maps/SET_TELEMATICS';
export function setTelematics(payload) {
	return { type: SET_TELEMATICS, payload };
}
export const SET_OPERATOR_COMPONENTS = 'app/maps/SET_OPERATOR_COMPONENTS';
export function setOperatorComponents(payload) {
	return { type: SET_OPERATOR_COMPONENTS, payload };
}

export const SET_OPERATOR_COMPONENTS_FILTER_SHOWN = 'app/maps/SET_OPERATOR_COMPONENTS_FILTER_SHOWN';
export function setOperatorComponentsFilterShown(payload) {
	return { type: SET_OPERATOR_COMPONENTS_FILTER_SHOWN, payload };
}
