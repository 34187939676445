export default {
    'Превышение более 20 км/ч': 'OverSpeed20TotalCount',
    'Превышение более 40 км/час': 'OverSpeed40TotalCount',
    'Превышение более 60 км/час': 'OverSpeed60TotalCount',
    'Резкое ускорение': 'SharpAccelerationLowTotalCount',
    'Опасное ускорение': 'SharpAccelerationModerateTotalCount',
    'Аварийное ускорение': 'SharpAccelerationHighTotalCount',
    'Резкое торможение': 'SharpBrakingLowTotalCount',
    'Опасное торможение': 'SharpBrakingModerateTotalCount',
    'Аварийное торможение': 'SharpBrakingHighTotalCount',
    'Резкое боковое ускорение': 'SharpTurnLowTotalCount',
    'Опасное боковое ускорение': 'SharpTurnModerateTotalCount',
    'Аварийное боковое ускорение': 'SharpTurnHighTotalCount',
};