import { APIClient } from '@contracts/proto-js/Api_grpc_web_pb';
import { EventsClient } from '@contracts/proto-js/eventsapi_grpc_web_pb';
import { DistanceClient } from '@contracts/proto-js/distanceapi_grpc_web_pb';

const enableDevTools = window.__GRPCWEB_DEVTOOLS__;

export const GATEWAY_ADDR = 'https://' + window.RNIS_SETTINGS.RNIS_API_URL;

export const gatewayService = new APIClient(GATEWAY_ADDR);
export const gatewayServiceMetadata = {
	'x-service': 'gateway'
};

export const eventsService = new EventsClient(GATEWAY_ADDR);
export const eventsServiceMetadata = {
	'x-service': 'events'
};

export const distanceService = new DistanceClient(GATEWAY_ADDR);
export const distanceServiceMetadata = {
	'x-service': 'distance'
};

if (enableDevTools) {
	enableDevTools([ gatewayService, eventsService, distanceService ]);
}
