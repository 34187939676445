import React, { Component } from 'react';
import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import Datepicker from "components/ui/form/datepicker";
import Select from "components/ui/select";

import moment from "moment";
import formats from "dictionaries/formats";

import { getTabletScheduleIntervalSummary } from "store/reducers/reports/tablet";
import _ from "lodash";
import {Bar, BarChart, LabelList, ResponsiveContainer, Text, Tooltip, XAxis, YAxis} from "recharts";

import "./style.less"

class CustomTooltip extends Component {
  render() {
    const value = this.props.value.filter(item => item.name === this.props.label)[0];
    const style = {
      "color": '#57AF7D',
      "margin-top": '5px'
    };
    return <div className="custom-tooltip-compliance">
      <p>{this.props.label}</p>
      <p style={style}>Соблюдение интервального движения: {value ? value.value : ''} %</p>
    </div>
  }
};

@connect(state => ({}), {getTabletScheduleIntervalSummary})

export default class ComplianceIntervalSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
      isLoading: false,
      from: moment().format(formats.DATE_API),
      to: moment().format(formats.DATE_API),
      compliance: 'best',
      data: {
        items: []
      },
    };
  }

  componentWillMount() {
    this.loadData();
  }

  async loadData() {
    this.setState({ isLoading: true });

    let summaryOptions = {
      date_from: moment(this.state.from).format(formats.DATE_API),
      date_to: moment(this.state.to).format(formats.DATE_API),
      component: this.props.component,
    };

    const response = await this.props.getTabletScheduleIntervalSummary(summaryOptions);
    this.setState({ isLoading: false });

    if (response.isOk) {
      this.setState({
        data: response.payload,
      });
    } else {
      response.showErrors();
    }
  }

  fromChange = async ({ target: { value } }) => {
    await this.setState({ from: value });
    this.loadData();
  };

  toChange = async ({ target: { value } }) => {
    await this.setState({ to: value });
    this.loadData();
  };

  onChange(field, e) {
    const value = e.value || e.target.value;

    let state = this.state;
    state[field] = value;
    this.setState(state);
  }

  get(field, forDisplay = true) {
    const value = _.get(this.state.data, field, null);
    if (forDisplay) {
      if (value !== null) {
        return _.round(value, 2);
      } else {
        return '-';
      }
    }
    return value || 0;
  }

  renderRegularityChart() {
    let rows;
    if (this.state.compliance === 'best') {
      rows = _.slice(_.orderBy(this.state.data.items || [], ['value'], ['desc']), 0, 10);
    } else {
      rows = _.slice(_.orderBy(this.state.data.items || [], ['value'], ['asc']), 0, 10).sort((a, b) => b.value - a.value);
    }

    const data = _.map(rows, (row) => {
      return {
        name: row.unit_name_full,
        value: row.value,
        visits_in_time: row.visits_in_time,
        visits_total: row.visits_total,
        radius: [4, 4, 0, 0],
      };
    });

    return (
        <ResponsiveContainer>
          <BarChart data={data} margin={{bottom: 100}}>
            <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" fontSize={10}
                   tick={props => <Text {...props} width={130}>{props.payload.value}</Text>}/>
            <YAxis dataKey="value" label="%"/>
            <Tooltip content={<CustomTooltip value={data} />} />
            <Bar dataKey="value" fill="#57AF7D" name="Соблюдение интервального движения" barSize={30} isAnimationActive={false}>
              <LabelList dataKey="value" position="insideTop" fill="#fff"/>
            </Bar>
          </BarChart>
        </ResponsiveContainer>
    );
  }

  renderContent() {
    const future = {
      after: new Date(),
    }
    return (
      <div className="full-size kiutr-widget">
        <div className="date-wrapper">
          <div className="date-item widget-datepicker">
            <span className="date-label">Дата с:</span>
            <Datepicker disabledDays={future} style="light" value={this.state.from} onChange={this.fromChange} />
          </div>
          <div className="date-item widget-datepicker">
            <span className="date-label">Дата по:</span>
            <Datepicker disabledDays={future} style="light" value={this.state.to} onChange={this.toChange} />
          </div>
        </div>
        <div className="widgets__selector">
          <Select value={this.state.compliance}
            onChange={this.onChange.bind(this, 'compliance')}
            clearable={false} options={[
              {
                value: 'best',
                label: 'Топ лучших',
              },
              {
                value: 'worst',
                label: 'Топ худших',
              },
            ]} />
        </div>
        <div className="chart">
          {this.state.data.items.length ? this.renderRegularityChart() : 'Нет данных для отображения'}
        </div>
      </div>
    );
  }

  render() {
    const loader = this.state.isLoading ? <GlobalLoaderComponent /> : null;

    return (
      <div className="kiutr-widget">
        {loader}
        {this.renderContent()}
      </div>
    );
  }
}