import _ from 'lodash';
import * as store from "utils/storage";
import {api} from "helpers/api";
import {makeResponse} from "helpers/response";

class StopPointsCache {

    stopPoints = {};
    key = 'stop-points';

    constructor() {
        this.stopPoints = store.get(this.key) || {};
    }

    isCacheable(meta) {
        const filters = meta.filters;
        return (_.size(filters) === 1) && _.get(filters, 'withUuid');
    }

    notCovers(uuids) {
        return _.difference(uuids, _.keys(this.stopPoints));
    }

    save(items) {
        _.each(items, (item) => {
            this.stopPoints[item.uuid] = {
                uuid: item.uuid,
                latitude: item.latitude,
                longitude: item.longitude,
                title: item.title,
                register_number: item.register_number,
            };
        });

        store.set(this.key, this.stopPoints);
    }

    flush() {
        store.remove(this.key);
    }

    async get(meta) {
        const uuids = _.get(meta, 'filters.withUuid', []);
        const notCoverUuids = this.notCovers(uuids);
        if (notCoverUuids.length > 0) {
            const response = await makeResponse(() => {
                return api.geo.getStopPoints({
                    filters: {
                        withUuid: notCoverUuids,
                    },
                }, true);
            });
            if (response.isOk) {
                this.save(response.payload.items);
            }
        }

        return {
            isOk: true,
            payload: {
                items: _.filter(_.map(uuids, (uuid) => {
                    return _.get(this.stopPoints, uuid);
                })),
            },
        };
    }

}

const stopPointsCache = new StopPointsCache();

export default stopPointsCache;