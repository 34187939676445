import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import formats from "dictionaries/formats";
import _ from 'lodash';
import classNames from 'classnames';
import $ from 'jquery';

import './datepicker.less';
import moment from "moment";
import Select from "components/ui/select";
import TextFieldMask from "components/ui/text-field-mask";
import InputElement from 'react-input-mask';
import debounce from 'throttle-debounce/debounce';

const monthsRU = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

@propTypes({
    value: PropTypes.any,
    style: PropTypes.string,
    onChange: PropTypes.func,
    disabledDays: PropTypes.func,
    disableYears: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
})

@defaultProps({
    style: null,
    onChange: () => {
    },
    disabledDays: () => false,
    disabled: false,
    className: '',
})

export default class Datepicker extends Component {

    changeDebounce = debounce(200, ::this.change);

    state = {
        value: null,
        synced: true,
    };

    componentWillUpdate(props, state) {
        if (state.synced) {
            //if ((!props.value && state.value) || (props.value && !state.value)) {
            if (props.value !== state.value) {
                this.setState({
                    value: props.value,
                });
            }
        }
    }

    componentDidMount() {
        this.forceUpdate();
    }

    render() {
        const value = this.state.value ? moment(this.state.value).format(formats.DATE) : '';

        const styleClassName = classNames('input__style', this.props.error ? 'input__style_state_wrong' : '');

        const inputClassName = classNames('input', this.props.style ? `input_${this.props.style}` : null);

        const classes = classNames('datepicker', this.props.className, this.props.disabled ? 'disabled' : '');

        return (
            <div className={classes}>
                <div className={inputClassName}>
                    <DayPickerInput
                        placeholder="дд.мм.гггг"
                        classNames={{
                            container: 'input__wrapper',
                            overlayWrapper: 'DayPickerInput-OverlayWrapper',
                            overlay: 'DayPickerInput-Overlay',
                        }}
                        disabled={this.props.disabled}
                        readOnly={this.props.readOnly}
                        component={DatepickerInput}
                        format="DD.MM.YYYY"
                        value={value}
                        onDayChange={::this.onChange}
                        hideOnDayClick={true}
                        dayPickerProps={{
                            months: monthsRU,
                            disabledDays: this.props.disabledDays,
                            weekdaysLong: [
                                'Воскресенье',
                                'Понедельник',
                                'Вторник',
                                'Среда',
                                'Четверг',
                                'Пятница',
                                'Суббота',
                            ],
                            weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                            firstDayOfWeek: 1,
                            labels: {nextMonth: 'следующий месяц', previousMonth: 'предыдущий месяц'},
                            captionElement: <YearMonthForm disableYears={this.props.disableYears} onChange={::this.onChange}/>,
                        }}
                        
                    />
                    <div className={styleClassName}>
                        {this.props.error ? <span
                            className="input__style_state_msg input__style_state_msg_wrong">{this.props.error}</span> : null}
                    </div>
                </div>
            </div>
        );
    }

    async onChange(date, reload = true) {
        date = date ? moment(date).format(formats.T1DATE) : null;

        await this.setState({
            value: date,
            synced: reload,
        });

        reload && this.changeDebounce(date);
    }

    change(value) {
        this.props.onChange({
            target: {
                value: value,
            },
        });
    }
}

function YearMonthForm({date, localeUtils, onChange, disableYears}) {
    const months = monthsRU;

    const years = [];
    for (let i = moment().subtract(100, 'years').year(); i <= moment().add(2, 'years').year(); i += 1) {
        years.push(i);
    }

    const handleChangeMonth = function (e) {
        onChange(date.setMonth(e.value), false);
    };

    const handleChangeYear = function (e) {
        onChange(date.setFullYear(e.value), false);
    };

    return (
        <form className="DayPicker-Caption clearAfter">
            <Select onChange={handleChangeMonth} value={date.getMonth()}
                    clearable={false}
                    options={months.map((month, i) => ({value: i, label: month}))}/>
            {disableYears ? null : <Select onChange={handleChangeYear} value={date.getFullYear()}
                                           clearable={false}
                                           options={years.map((year) => ({value: year, label: year}))}/>}
        </form>
    );
}

class DatepickerInput extends React.Component {
    state = {
        opened: false,
    };

    constructor(...args) {
        super(...args);

        this.onClickDocument = ::this._onClickDocument;
    }

    async componentDidMount() {
        $(document).on('click', this.onClickDocument);
    }

    componentWillUnmount() {
        $(document).off('click', this.onClickDocument);
    }

    _onClickDocument(e) {
        if ($(e.target).closest($(this.refs.container).closest('.datepicker')).length === 0) {
            this.props.onBlur();
        }
    }

    render() {
        let props = _.clone(this.props);
        delete props['onBlur'];
        //delete props['onFocus'];
        delete props['onClick'];
        props.onBlur = (e) => {
            if ($(e.relatedTarget).closest('.DayPicker-wrapper').length === 0) {
                this.props.onBlur();
            }
        };

        return (
            <div ref="container">
                <InputElement mask="99.99.9999" className="input__control" {...props}/>
                <a className="b-icon-link b-icon-link_icon_datepicker datepicker__icon" href="#"
                   onClick={::this.toggle}/>
            </div>
        );
    }

    async toggle(e) {
        e.preventDefault();

        const opened = $(this.refs.container).closest('.datepicker').find('.DayPicker-wrapper').length > 0;
        if (opened) {
            this.props.onBlur();
        } else {
            this.props.onFocus();
        }
    }

    focus() {
    }
}