import React, {Component} from 'react';
import classNames from 'classnames';
import L from 'leaflet';
import 'leaflet-plugins/layer/tile/Yandex.js'
import Script from 'react-load-script';
import currentUser from "../../../helpers/current-user";

export default class WeatherLayer extends Component {

    onClick() {
        const a = document.createElement("a");

        let userCenter = null, position = null;

        let objFromLs = JSON.parse(localStorage.getItem("users_settings"));
        if (objFromLs && objFromLs[currentUser.user.uuid]) {
            userCenter = objFromLs[currentUser.user.uuid].map.center;
            position = `?lat=${userCenter.lat}&lon=${userCenter.lng}`;
        }

        a.setAttribute("href", userCenter ? `https://yandex.ru/pogoda/${position}` : 'https://yandex.ru/pogoda/');
        a.setAttribute("target", "_blank");
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
        }, 0);
    }

    render() {
        return (
            <div className="route-block weather-button">
                <div className="route-block__item">
                    <div className={classNames('route-block__btn route-block__btn_area', {
                        'route-block__btn_white': true,
                    })} onClick={::this.onClick}>
                        <i className={classNames('rnis-icon', {
                            'rnis-icon_thermo': true,
                        })}/>
                    </div>
                </div>
            </div>
        );
    }
}