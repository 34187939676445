import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import _ from 'lodash';
import * as moment from "moment";
import formats from "dictionaries/formats";

const requestFullTelematicsCurrentData = async (devices, clustersActive, {boundingBox, mapSize, zoom, time_limit}) => {
    try {
        const response = await api.telematics.getTelematicsWithDevices(devices, 't1sync', boundingBox, mapSize, 'precalculated', zoom, clustersActive, time_limit);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

const getNewDevices = (requestedDevices, currentData) => {
    return _.difference(requestedDevices, _.keys(currentData));
};

const getCurrentDevices = (requestedDevices, newDevices) => {
    return _.difference(requestedDevices, newDevices);
};

const getCurrentDevicesDataRequest = (currentDevices, currentData) => {
    let items = [];

    _.each(currentDevices, (deviceId) => {
        const data = _.get(currentData, deviceId, []);
        const date = data[5];
        if (date) {
            items.push({
                device_id: deviceId,
                date: moment.unix(date).format(formats.DATETIME_API),
            });
        }
    });

    return {items};
};

const getCurrentDevicesData = async (currentDevicesDataRequest) => {
    try {
        const response = await api.t1sync.getHistoryMultiple(currentDevicesDataRequest);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const requestTelematics = async (requestedDevices, currentData, {boundingBox, mapSize, zoom, clustersActive, time_limit}) => {
    if (clustersActive) {
        return await requestFullTelematicsCurrentData(requestedDevices, true, {boundingBox, mapSize, zoom, time_limit});
    }

    const newDevices = getNewDevices(requestedDevices, currentData);
    const currentDevices = getCurrentDevices(requestedDevices, newDevices);

    const devicesDataResponse = await requestFullTelematicsCurrentData(requestedDevices, false, {
        boundingBox,
        mapSize,
        zoom,
        time_limit,
    });
    if (devicesDataResponse.isOk) {
        let devicesData = devicesDataResponse.payload.items;

        const currentDevicesDataRequest = getCurrentDevicesDataRequest(currentDevices, currentData);
        const currentDevicesDataResponse = await getCurrentDevicesData(currentDevicesDataRequest);
        if (currentDevicesDataResponse.isOk) {
            const historyData = _.mapValues(_.keyBy(currentDevicesDataResponse.payload.items, 'device_id'), 'points');

            devicesData = _.map(devicesData, (item) => {
                const deviceId = item[3];
                const deviceHistoryData = _.get(historyData, deviceId);

                if (deviceHistoryData) {
                    const lastDeviceHistoryData = _.last(deviceHistoryData);
                    if (lastDeviceHistoryData) {
                        item = [
                            lastDeviceHistoryData[0],
                            lastDeviceHistoryData[1],
                            lastDeviceHistoryData[3],
                            lastDeviceHistoryData[2],
                            'point',
                            lastDeviceHistoryData[4],
                        ];
                    }
                    item[8] = deviceHistoryData;
                } else {
                    item[8] = null;
                }

                return item;
            });
        }

        return {
            payload: {
                items: devicesData,
            },
        };
    }

    return {};
};