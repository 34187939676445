import {api} from "helpers/api";
import _ from 'lodash';
import {User} from "helpers/user";
import {makeResponse} from "helpers/response";
import * as alerts from "./alerts";

class CurrentUser {
    user = {
        info: {},
        roles: [],
    };
    permissions = null;
    inited = false;

    async init() {
        await Promise.all([
            this.loadGlobalSettings(),
            this.loadUser(),
            this.loadPermissions(),
        ]);
        this.inited = true;
    }

    get name() {
        if (!this.inited) {
            return null;
        }
        return new User(this.user).getFullName();
    }

    get login() {
        if (!this.inited) {
            return null;
        }
        return this.user.login;
    }

    async loadUser() {
        const response = await makeResponse(() => {
            return api.auth.getUser(null, {
                response_data: [
                    'uuid',
                    'login',
                    'info/name',
                    'info/surname',
                    'info/second_name',
                    'is_supervisor',
                    'is_semi_confirmed',
                    'info/unit_uuid',
                    'roles/access_type',
                    'roles/name',
                    'roles/uuid',
                ],
            });
        }, 'current-user');
        if (response.isOk) {
            this.user = response.payload;
            this.user.is_demobar = this.isDemoBar(); // отображать ли демобар ?
            this.user.is_osm_defaultLayer = this.isOsmDefaultLayer();  // карты OSM по умолчанию?
            this.user.title = this.setRnisTitle(); // название в шапках страниц (РИНС?)
            this.user.is_timezoneoffset = this.isTimezoneOffset(response.payload.info.unit_uuid); // смещение таймзоны (для расписания)
            this.user.is_TelematicLineRetrospective = this.isTelematicLineRetrospective(response.payload.info.unit_uuid); // отображать ли точки итории на плеере поездок
            this.user.is_custommarker = this.isTCustomMarker(response.payload.info.unit_uuid); // кастомные маркеры на карте
            this.user.is_timecorrection = this.isTimeCorrection(response.payload.info.unit_uuid);
            this.user.is_paymodal = this.isPayModal(response.payload.info.unit_uuid); // отображать ли окно "Вы используете .... заплатите"
            this.user.is_custompush = this.isCustomPush(); // кастомные браузерные уведомления (демо функционал)
            this.user.free_user_settings = this.setFreeUserSettings(response.payload);
            this.user.is_snakedelay = this.isSnakeDelay(response.payload);
            setTimeout(() => {
                this.checkMaintenance();
                setInterval(::this.checkMaintenance, 300000);
            }, 15000);
        }
    }

    async checkMaintenance() {
        const response = await api.system.getMaintenanceStatus();
        const status = _.get(response, 'payload.status', false);
        if (status && !this.can('com.rnis.system.maintenance', 'update')) {
            await api.session.logout();
            window.location.reload();
        }
    }

    async loadPermissions() {
        const response = await api.auth.getUserPermissions();
        if (response.success) {
            this.permissions = _.keyBy(response.payload.components, 'component');
        }
    }

    async loadGlobalSettings() {
        if (!window.RNIS_SETTINGS) {
            const res = await fetch(`${window.location.origin}/settings/settings.json`)
            const settings = await res.json()
            installSettings(settings)
        }
    }

    /**
     * Отображать ли "Отображать push уведомленя по этому выходу" и три чекбокса на
     * странице Управление пассажирскими перевозками → Детализация по рейсам
     * @returns {*|boolean}
     */
    isCustomPush() {
        return window.RNIS_SETTINGS.CUSTOM_PUSH || false;
    }

    /**
     * Установка настроек функционала "за##бывания" пользователя - задержки, счетчики обратного отсчета
     */
    setFreeUserSettings(user) {
        //if (!user) return 0;
        let SNAKEDELAY = window.RNIS_SETTINGS.SNAKEDELAY || 0; // задержка перед отображением змейки
        let RECALCDELAY = window.RNIS_SETTINGS.RECALCDELAY || 0; // задержка перед тем как разршерить пересчет выхода
        let ORDERSBYDAY = window.RNIS_SETTINGS.ORDERSBYDAY || "show"; // отображать в шапке кнопку "суточная разнарядка"
        let OPERATIONCHANGES = window.RNIS_SETTINGS.OPERATIONCHANGES || 0; // задержка перед тем как разрешить нажать "оформить" в Оперативное выделение ресурсов
        let VEHICLETOMORROW = window.RNIS_SETTINGS.VEHICLETOMORROW || 0; // задержка перед тем как разрешить нажать "сохранить" в "форма помашинная на завтра"
        let HIDESNAKEPOINTS = window.RNIS_SETTINGS.HIDESNAKEPOINTS && window.RNIS_SETTINGS.HIDESNAKEPOINTS !== undefined ?  window.RNIS_SETTINGS.HIDESNAKEPOINTS : false; // отображать ли точки, названия и время на змейке
        let OPDISPATCHING = window.RNIS_SETTINGS.OPDISPATCHING && window.RNIS_SETTINGS.OPDISPATCHING !== undefined ?  window.RNIS_SETTINGS.OPDISPATCHING : false; // включить возможность оперативной диспетчеризации ?
        let SHORTCALENDAR = window.RNIS_SETTINGS.SHORTCALENDAR && !_.isEmpty(window.RNIS_SETTINGS.SHORTCALENDAR) ?  window.RNIS_SETTINGS.SHORTCALENDAR : false; // отображение только нарядов за сегодня и завтра
        let userRole = user.roles.filter((role) => role.name === window.RNIS_SETTINGS.FREEACCOUNTROLENAME);
        //let userRole = user.roles.filter((role) => role.name === 'Администратор подсистемы оператора РНИС МО'); // для отладки
        return {
            snakeDalay: userRole.length ? SNAKEDELAY * 1000 : 0,
            hideSnakePoints: userRole.length ? HIDESNAKEPOINTS : false,
            opDispatching: userRole.length ? OPDISPATCHING : false,
            recalcDelay: userRole.length  ? RECALCDELAY : 0,
            ordersByDay: userRole.length  ? ORDERSBYDAY : "show",
            operationChanges: userRole.length  ? OPERATIONCHANGES : 0,
            vehicleTomorrow: userRole.length  ? VEHICLETOMORROW : 0,
            shortCalendar: userRole.length ? SHORTCALENDAR : false,
        }

    }

    isOsmDefaultLayer() {
        window.RNIS_SETTINGS.OSMDEFAULTLAYER === undefined ? window.RNIS_SETTINGS.OSMDEFAULTLAYER = false : null;
        return window.RNIS_SETTINGS.OSMDEFAULTLAYER;
    }

    isDemoBar() {
        return window.RNIS_SETTINGS.TOP_NOTIFICATION_TEXT || '';
    }

    isTimezoneOffset(id) {
        let TIMEZONE_OFFSET = window.RNIS_SETTINGS.TIMEZONE_OFFSET || 0;
        if (!TIMEZONE_OFFSET) return 0;

        let target = _.filter(TIMEZONE_OFFSET, (el) => {
            if (_.includes(el.ids, id)) {
                return el;
            }
        });
        if (!target.length) return 0;
        return target[0].offset;
    }

    isTelematicLineRetrospective(id) {
        let TELEMATICLINERETROSPECTIVE = window.RNIS_SETTINGS.TELEMATICLINERETROSPECTIVE;
        if (!TELEMATICLINERETROSPECTIVE) return false;

        let target = _.filter(TELEMATICLINERETROSPECTIVE, (el) => {
            if (_.includes(el.ids, id)) {
                return true;
            }
        });
        return !!target.length;

    }

    isTCustomMarker(id) {
        let CUSTOMMARKER = window.RNIS_SETTINGS.CUSTOMMARKER || false;
        if (!CUSTOMMARKER) return false;

        let target = _.filter(CUSTOMMARKER, (el) => {
            if (_.includes(el.ids, id)) {
                return el;
            }
        });
        if (!target.length) return false;
        return target[0].marker;
    }

    /**
     * Таймаут перед отображением змейки для бесплатных перевозчиков
     * @param user
     * @returns {number}
     */
    isSnakeDelay(user) {
        if (!user) return 0;
        let SNAKEDELAY = window.RNIS_SETTINGS.SNAKEDELAY || 0;
        return user.roles.filter((role) => role.name === this.freeUserName).length ? SNAKEDELAY * 1000 : 0;
    }

    isTimeCorrection(id) {
        let TIMEZONE_OFFSET = window.RNIS_SETTINGS.TIMEZONE_OFFSET || 0;
        if (!TIMEZONE_OFFSET) return 0;

        let target = _.filter(TIMEZONE_OFFSET, (el) => {
            if (_.includes(el.ids, id)) {
                return el;
            }
        });
        if (!target.length) return 0;
        console.log(target);
        return target[0].correction;
    }

    isPayModal(id) {
        let BLOCKING_ALERTS = window.RNIS_SETTINGS.BLOCKING_ALERTS;
        if (!BLOCKING_ALERTS) return;

        let target = _.filter(BLOCKING_ALERTS, (el) => {
            if (_.includes(el.units, id)) {
                return el;
            }
        });

        if (!target.length) return;

        let text = target[0].message;
        let frequencyTime = target[0].frequency;
        let delayTime = target[0].unlock_delay;

        alerts.handyCountdown(`${text}`, delayTime);

        if (window.payTimer != undefined && window.payTimer != 'undefined') {
            window.clearInterval(window.payTimer);
        }
        window.payTimer = setInterval(function () {
            alerts.handyCountdown(`${text}`, delayTime);
        }, frequencyTime * 1000);
    }

    isSupervisor() {
        if (!this.inited) {
            return null;
        }
        return !!_.get(this.user, 'is_supervisor');
    }

    setRnisTitle() {
        return {
            TITLE: window.RNIS_SETTINGS.TITLE || 'РНИС',
            SHORT_TITLE: window.RNIS_SETTINGS.SHORT_TITLE || 'РНИС'
        };
    }

    isSemiConfirmed() {
        if (!this.inited) {
            return null;
        }
        return !!_.get(this.user, 'is_semi_confirmed');
    }

    uuid() {
        if (!this.inited) {
            return null;
        }
        return _.get(this.user, 'uuid');
    }

    unitUuid() {
        if (!this.inited) {
            return null;
        }
        return _.get(this.user, 'info.unit_uuid');
    }

    accessType() {
        if (!this.inited) {
            return null;
        }
        if (this.isSupervisor()) {
            return 'all';
        }

        let result = 'unit';

        _.each(this.user.roles, (role) => {
            if (role.access_type === 'all') {
                result = 'all';
            }
            if ((role.access_type === 'unit_with_children') && (result === 'unit')) {
                result = 'unit_with_children';
            }
        });

        return result;
    }

    can(permissionCode, grant = 'read', component = null) {
        if (!this.inited) {
            return null;
        }
        if (this.isSupervisor()) {
            return true;
        }

        if (this.permissions === null) {
            return false;
        }

        if (_.isArray(component)) {
            return _.filter(component, (c) => {
                return !this.can(permissionCode, grant, c);
            }).length === 0;
        }

        let permissions = component ? _.get(this.permissions[component], 'items', []) : _.flatten(_.map(this.permissions, 'items'));

        return _.filter(_.filter(permissions, {code: permissionCode}), (permission) => {
            return _.indexOf(permission.grants, grant) !== -1;
        }).length > 0;
    }
}

const currentUser = new CurrentUser();

export default currentUser;
