import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import * as alerts from "helpers/alerts";
import {success, error} from 'helpers/response';

const GET_LAYER = 'GET_LAYER';

const initialState = new Map({
    layer: Map({})
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_LAYER:
            return state.set('layer', action.payload);
    }

    return state;
}

export const getLayer = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getLayer(uuid);

        dispatch({
            type: GET_LAYER,
            payload: response.payload
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createLayer = (layer) => async (dispatch) => {
    try {
        const response = await api.geo.createLayer(layer);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateLayer = (layer) => async (dispatch) => {
    try {
        let response = await api.geo.updateLayer(layer);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteLayer = (layer) => async (dispatch) => {
    try {
        let response = await api.geo.deleteLayer(layer);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};