import { createSelector } from 'reselect';

export const getOrdersKiutrStateSelector = (state) => state.kiutrOrders;

export const getOrdersUniqueCounts = createSelector(
	[ getOrdersKiutrStateSelector ],
	({ uniqueList }) => uniqueList.count || ''
);

export const getOrdersUniqueIsLoading = createSelector(
	[ getOrdersKiutrStateSelector ],
	({ uniqueListIsLoading }) => uniqueListIsLoading || false
);
export const getOrdersUniqueIsLoaded = createSelector(
	[ getOrdersKiutrStateSelector ],
	({ uniqueListIsLoaded }) => uniqueListIsLoaded || false
);