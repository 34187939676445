import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import _ from 'lodash';
import {success, error} from 'helpers/response';

const GET_USER_EDUCATIONS = 'GET_USER_EDUCATIONS';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_USER_EDUCATIONS:
            return state;
    }

    return state;
}

export const getUserEducations = (user_uuid, meta) => async (dispatch) => {
    try {
        let response = await api.auth.getUserEducations(user_uuid, meta);

        dispatch({
            type: GET_USER_EDUCATIONS,
            payload: {
                pagination: response.headers.meta.pagination,
                items: response.payload.items
            }
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};