import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import classNames from 'classnames';
import BaseEditorFormComponent from "components/base/base-editor-form";
import {getBnso, createBnso, updateBnso} from "store/reducers/vehicles/bnso";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUnits} from "store/reducers/organizational_units/units";
import {State} from "components/ui/state";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import PageModalComponent from "components/ui/page-modal";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import {createGraduationTemplate, getGraduationTemplates} from "store/reducers/vehicles/graduations";
import GlobalLoaderComponent from "components/ui/global-loader";
import * as alerts from "helpers/alerts";
import {
    Bar, BarChart, CartesianGrid, Label, LabelList, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis,
    YAxis, Text, AreaChart, Area
} from "recharts";
import {getDeviceExtendedData} from "store/reducers/maps";
import {getUser} from "store/reducers/staffing/staffing_editor";
import Button from "components/ui/button";
import moment from "moment";
import formats from "dictionaries/formats";
import ColorPicker from 'react-color-picker';
import 'react-color-picker/index.css';
import debounce from 'throttle-debounce/debounce';
import currentUser from 'helpers/current-user';

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getBnso, createBnso, updateBnso, getDictionaryList, getUser})

export default class Editor extends BaseEditor {

    title = 'БНСО';
    modelClass = 'App\\Model\\Bnso';

    async loadData(uuid) {
        return await this.props.getBnso(uuid);
    }

    async createItem(data) {
        return await this.props.createBnso(data);
    }

    async updateItem(data) {
        return await this.props.updateBnso(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    composeItem(data) {
        let item = _.clone(data);

        item.component = this.props.params.component;

        return item;
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getUnits,
    getGraduationTemplates,
    createGraduationTemplate,
    getDeviceExtendedData,

}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        bnso: {},
        bnso_types: [],
        bnso_manufacturers: [],
        bnso_operators: [],
        units: [],
        teleoperators: [],
        bnso_equipments: [],
        bnso_indicators: [],
        graduationActive: null,
        graduation_templates: [],
        extendedData: [],
    };

    portTypes = {
        toggle: 'Свитч',
        numeric: 'Числовой',
    };

    debounceLoadUnits = debounce(500, ::this.loadUnits)

    getData() {
        return this.state.bnso;
    }

    async loadUser(uuid) {
        const response = await this.props.getUser(uuid);

        if (response.isOk) {
            this.setState({
                user: response.payload,
            });
        } else {
            response.showErrors();
        }
        return null;
    }

    async componentDidMount() {
        await this.setState({
            bnso: this.props.data,
        });
        if(!this.props.data.unit_uuid && window.RNIS_SETTINGS.bnso_dictionary_initial_unit) {
            console.log('2--')
            this.setValue('bnso.unit_uuid', currentUser.unitUuid());
        }
        this.loadGraduationTemplates();
        await this.loadDictionaries([
            'company_types',
            'bnso_types',
            'bnso_manufacturers',
            'bnso_operators',
            'bnso_equipments',
            'bnso_indicators',
        ]);
        this.loadTeleoperators();
        this.initPorts();

    }

    initPorts() {
        const bnso_type = _.find(this.state.bnso_types, {value: this.get('bnso_type')});
        if (!bnso_type) {
            this.setValue('bnso.ports', []);
            return;
        }

        const ports = JSON.parse(bnso_type.document.ports || '[]');

        const bnsoPorts = this.get('ports') || [];
        this.setValue('bnso.ports', _.map(ports, (port) => {
            const bnsoPort = _.find(bnsoPorts, {
                number: (port.number).toString()
            });
            if (bnsoPort) {
                return bnsoPort;
            } else {
                return {
                    number: port.number,
                    bnso_indicator_uuid: null,
                    graduation: {},
                };
            }
        }));
    }

    async loadUnits(input, callback) {

        let uuid = null;

        if(this.props.data.unit_uuid) {
            uuid = this.props.data.unit_uuid
        } else if (window.RNIS_SETTINGS.bnso_dictionary_initial_unit) {
            console.log('2')
            uuid = currentUser.unitUuid()
        }

        const response = await this.props.getUnits({
            search: input ? input : undefined,
            filters: {
                withUuid: !input ? [uuid] : undefined,
                withComponent: this.props.params.component,
                appendChildrenAttribute :true,
            },
            response_data: [
                'items/uuid',
                'items/name',
                'items/name_full',
            ],
        });

        if (response.isOk) {
            callback(null, {
                options: _.sortBy(response.payload.items.map(i => ({
                    label: i.name,
                    value: i.uuid,
                    fullName: i.name_full,
                })), 'label'),
                complete: false
            });

        } else {
            response.showErrors();
        }
    }

    async loadTeleoperators() {
        const response = await this.props.getUnits({
            filters: {
                withUnitType: _.get(_.find(this.state.company_types, {label: 'Телематический оператор'}), 'value'),
            },
        });
        if (response.isOk) {
            const teleoperators = _.sortBy(_.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.name,
                fullName: item.name_full,
            })), 'label');
            this.setState({teleoperators});
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.bnso, path, defaultValue);
    }

    async setValue(field, value) {
        await super.setValue(field, value);

        if (field === 'bnso.bnso_type') {
            this.initPorts();
        }

        if (value && (field === 'current_graduation_template')) {
            const template = _.find(this.state.graduation_templates, {uuid: value});
            this.setValue(`bnso.ports.${this.state.graduationActive}.graduation`, template.graduation);
        }
    }

    onUnitChange(e) {
        const value = e ? e.value : null;

        this.setValue('bnso.unit_uuid', value);
    }

    render() {
        const numberError = this.getError('bnso_number');
        const styleClassName = classNames(numberError ? 'input__style_state_wrong' : '');

        return (
            <div>
                <Accordion className="b-modal-bnso b-modal-bnso_edit">
                    <AccordionItem opened={true} title="Общая информация">
                        <Block size="xl" title="Код БНСО *">
                            {this.textInput('bnso.bnso_code')}
                        </Block>
                        <Block size="xl">
                            <div className={styleClassName}>
                                {numberError ?
                                    <span style={{color: '#f65d50'}}>{numberError}</span> : null}
                            </div>
                        </Block>
                        <Block title="Одобрено">
                            <State positive={this.get('is_approved')}/>
                        </Block>
                        <Block title="Предприятие *">
                            {this.selectAsync('bnso.unit_uuid', ::this.debounceLoadUnits, {
                                onChange: ::this.onUnitChange,
                            })}
                        </Block>
                        <Block title="Предприятие-телеоператор">
                            {this.select('bnso.teleoperator_uuid', this.state.teleoperators, {
                                filterOptions: (options, filter, currentValues) => {
                                    return _.filter(options, (option) => {
                                        return (option.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1) || (option.fullName.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
                                    });
                                },
                            })}
                        </Block>
                        <Block title="Тип БНСО">
                            {this.select('bnso.bnso_type', this.state.bnso_types)}
                        </Block>
                        <Block title="Производитель">
                            {this.select('bnso.manufacturer', this.state.bnso_manufacturers)}
                        </Block>
                        <Block title="Серийный номер">
                            {this.textInput('bnso.serial_number')}
                        </Block>
                        <Block title="Мобильный оператор">
                            {this.select('bnso.mobile_operator', this.state.bnso_operators)}
                        </Block>
                        <Block title="Номер SIM - карты">
                            {this.textInput('bnso.sim_card_number')}
                        </Block>
                        <Block title="Номер телефона">
                            {this.maskInput('bnso.phone_number', '+7(999)999-99-99')}
                        </Block>
                        <Block title="Номер телефона 2">
                            {this.maskInput('bnso.phone_number2', '+7(999)999-99-99')}
                        </Block>
                        <Block title="Выход тревожной кнопки">
                            {this.textInput('bnso.alarm_number')}
                        </Block>
                        <Block title="Версия ПО">
                            {this.textInput('bnso.version')}
                        </Block>
                        <Block title=" ">
                            {this.checkbox('bnso.has_headset', 'Наличие гарнитуры')}
                            {this.checkbox('bnso.is_malfunction', 'Неисправно')}
                        </Block>
                    </AccordionItem>
                    <AccordionItem opened={true} title="Порты">
                        <div className="b-draggable">
                            {_.map(this.get('ports'), ::this.renderPort)}
                        </div>
                    </AccordionItem>
                </Accordion>
                {this.renderGraduation()}
            </div>
        );
    }

    getPortTypes(basePort) {
        return _.map(basePort.types, (type) => {
            return {
                value: type,
                label: this.portTypes[type],
            };
        });
    }

    renderPort(port, index) {
        const bnso_type = _.find(this.state.bnso_types, {value: this.get('bnso_type')});

        if (!bnso_type) {
            return;
        }

        let ports = JSON.parse(bnso_type.document.ports || '[]');
        if (ports.length) {
            ports = ports.map(port => {
                port.number = Number(port.number);
                return port;
            })
        }
        const basePort = _.find(ports, {number: Number(port.number)});
        if (!basePort) {
            return;
        }

        const bnsoIndicator = _.find(this.state.bnso_indicators, {value: port.bnso_indicator_uuid});

        return (
            <div key={index} className="b-draggable__item">
                <div className="b-draggable__top">
                    <div className="b-draggable__title">
                        <div>Внешний порт #{port.number} / {basePort.text}</div>
                    </div>
                    <div className="b-draggable__menu">
                        <div className="b-draggable__menu-text b-draggable__menu-text_red">
                            {this.select(`bnso.ports.${index}.type`, this.getPortTypes(basePort), {
                                clearable: false,
                            })}
                        </div>
                    </div>
                </div>
                <div className="b-draggable__content fs-zero">
                    <Block title="Индикатор">
                        {this.select(`bnso.ports.${index}.bnso_indicator_uuid`, this.state.bnso_indicators)}
                    </Block>
                    <Block title="Тип оборудования">
                        {bnsoIndicator ? _.get(_.find(this.state.bnso_equipments, {value: bnsoIndicator.document.bnso_equipment_uuid}), 'label') : null}
                    </Block>
                    <Block size="sm" title="Градуировка">
                        {this.isGraduationFull(port, basePort) ? 'Настроена' : 'Не настроена'}
                    </Block>
                    <Block size="sm" title={true}>
                        {(port.type && bnsoIndicator) ? (
                            (port.type !== 'toggle') ? (
                                <div className="ports-icon ports-icon_center ports-icon_red ports-icon_settings"
                                     onClick={this.showGraduation.bind(this, index)}></div>
                            ) : (
                                <div className="custom">
                                    <div className="ports-icon ports-icon_center ports-icon_red ports-icon_settings"
                                         onClick={this.showGraduation.bind(this, index)}></div>
                                    Не требуется
                                    <br/>
                                    {this.checkbox(`bnso.ports.${index}.is_inverted`, 'Инвертировать')}
                                </div>
                            )
                        ) : (
                            'Градиуровка недоступна'
                        )}
                    </Block>
                    <Block size="md" title={true}>
                        {this.checkbox(`bnso.ports.${index}.is_always_positive`, 'Эмулировать положительное значение')}
                    </Block>
                    {(_.get(_.find(this.state.bnso_indicators, {value: this.get(`ports.${index}.bnso_indicator_uuid`)}), 'label') !== 'Одометр') ? [
                        <Block key="port" size="sm" title="Номер">
                            {this.textInput(`bnso.ports.${index}.inner_port`, {
                                type: 'number',
                                positive: true,
                            })}
                        </Block>,
                        <Block key="sensor" size="sm" title="Датчик">
                            {this.textInput(`bnso.ports.${index}.inner_sensor`, {
                                type: 'number',
                                positive: true,
                            })}
                        </Block>,
                    ] : null}
                </div>
            </div>
        );
    }

    isGraduationFull(port, basePort) {
        const bnsoIndicator = _.find(this.state.bnso_indicators, {value: port.bnso_indicator_uuid});
        if (!bnsoIndicator) {
            return false;
        }
        const bnsoIndicatorStatuses = JSON.parse(bnsoIndicator.document.statuses || '[]');

        if (bnsoIndicatorStatuses.length === 0) {
            return _.get(port, 'graduation.scales', []).length > 0;
        } else {
            return _.uniq(_.map(_.get(port, 'graduation.spreadsheets', []), 'status')).length === bnsoIndicatorStatuses.length;
        }
    }

    async showGraduation(index) {
        await this.setState({
            graduationActive: index,
        });
        const port = this.get(`ports.${this.state.graduationActive}`);
        this.reloadExtendedData(port);
    }

    closeGraduation() {
        this.setState({
            graduationActive: null,
        });
    }

    renderGraduation() {
        if (this.state.graduationActive === null) {
            return;
        }
        const bnso_type = _.find(this.state.bnso_types, {value: this.get('bnso_type')});
        if (!bnso_type) {
            return;
        }

        const ports = JSON.parse(bnso_type.document.ports || '[]');
        const port = this.get(`ports.${this.state.graduationActive}`);
        const basePort = _.find(ports, {number: port.number});
        if (!basePort) {
            return;
        }

        const title = `Градуировка порта ${port.number} БНСО №${this.get('bnso_code')}`;

        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Закрыть">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.closeGraduation}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        const bnsoIndicator = _.find(this.state.bnso_indicators, {value: port.bnso_indicator_uuid});
        const bnsoIndicatorStatuses = JSON.parse(bnsoIndicator.document.statuses || '[]');

        return (
            <PageModalComponent
                header={{title, buttons}}
                onClose={this.props.onClose}
                className={`profile-modal b-modal-bnso b-modal-bnso_graduation b-modal-bnso_graduation_2 b-modal-${this.props.mode}`}
            >
                {this.state.saving ? (<GlobalLoaderComponent/>) : null}
                <Accordion>
                    <AccordionItem title="Основные настройки" opened={true}>
                        <Block size="lg" title="Выводимый индикатор">
                            {bnsoIndicator ? _.get(_.find(this.state.bnso_equipments, {value: bnsoIndicator.document.bnso_equipment_uuid}), 'label') : null}
                            - {_.get(bnsoIndicator, 'label')}
                        </Block>
                        <Block size="lg" title="Выходная размерность">
                            {_.get(bnsoIndicator, 'document.measure')}
                        </Block>
                        {(port.type !== 'toggle') ? (
                            <Block size="lg" title="Шаблоны">
                                {this.select('current_graduation_template', _.map(this.state.graduation_templates, (template) => ({
                                    value: template.uuid,
                                    label: template.name,
                                })))}
                            </Block>
                        ) : null}
                        {(port.type !== 'toggle') ? (
                            <Block size="xl">
                                <div className="b-draggable__item padding-none">
                                    <div className="b-draggable__top">
                                        <div className="b-draggable__title">
                                            <div>Сохранить как шаблон</div>
                                        </div>
                                    </div>
                                    <div className="b-draggable__content fs-zero">
                                        <Block title="Наименование">
                                            {this.textInput('graduation_template.name')}
                                        </Block>
                                        <Block>
                                            <a className="b-button b-button_size_xl b-button_red b-button_save"
                                               type="button" onClick={::this.saveGraduationTemplate}>Сохранить
                                                шаблон</a>
                                        </Block>
                                    </div>
                                </div>
                            </Block>
                        ) : null}
                    </AccordionItem>
                    {(port.type === 'numeric') ? (
                        <AccordionItem title={`Опрос порта: Analog_${port.inner_port}_${port.inner_sensor}`}
                                       opened={true}>
                            {this.renderPortData(port, 'analog')}
                        </AccordionItem>
                    ) : null}
                    {(port.type === 'toggle') ? (
                        <AccordionItem title={`Опрос порта: Digital_${port.inner_port}_${port.inner_sensor}`}
                                       opened={true}>
                            {this.renderPortData(port, 'digital')}
                        </AccordionItem>
                    ) : null}
                    {(port.type !== 'toggle') ? (
                        (bnsoIndicatorStatuses.length === 0) ? (
                            <AccordionItem title="Настройка шкалы" opened={true}>
                                {this.renderGraduationScales()}
                            </AccordionItem>
                        ) : (
                            <AccordionItem key="settings" title="Градуировочная таблица" opened={true}>
                                {this.renderGraduationTable()}
                            </AccordionItem>
                        )
                    ) : null}
                </Accordion>
            </PageModalComponent>
        )
    }

    renderPortData(port, type) {
        return (
            <div>
                <Block size="xl">
                    <Button size="md" width="auto" color="red" shadow="red" text="Обновить"
                            onClick={this.reloadExtendedData.bind(this, port)}/>
                </Block>
                {(this.state.extendedData.length === 0) ? (
                    <Block size="xl">
                        -нет данных-
                    </Block>
                ) : null}
                <div className="b-block _xl">
                    <div className="b-block__text">
                        <div className="b-block _md">
                            <div className="b-block__title">Время поступления</div>
                            {_.map(this.state.extendedData, (item, index) => {
                                return (
                                    <div key={index}
                                         className="b-block__text b-block__text_no-header">{moment(item.timestamp).format(formats.DATETIME)}</div>
                                );
                            })}
                        </div>
                        <div className="b-block _md">
                            <div className="b-block__title">Значение на порту</div>
                            {_.map(this.state.extendedData, (item, index) => {
                                return (
                                    <div key={index} className="b-block__text b-block__text_no-header">
                                        {(type === 'analog') ? item[type] : (
                                            <State positive={item[type]}/>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async reloadExtendedData(port) {
        const response = await this.props.getDeviceExtendedData(this.get('bnso_number'), port.inner_port, port.inner_sensor);

        if (response.isOk) {
            this.setState({
                extendedData: response.payload.items,
            });
        }
    }

    async saveGraduationTemplate() {
        this.setState({saving: true});
        const response = await this.props.createGraduationTemplate({
            name: _.get(this.state, 'graduation_template.name'),
            graduation: this.get(`ports.${this.state.graduationActive}.graduation`),
        });
        this.setState({saving: false});

        if (response.isOk) {
            alerts.success('Шаблон сохранен');
            this.setState({
                graduation_template: {
                    name: '',
                },
            });
            this.loadGraduationTemplates();
        } else {
            response.showErrors();
        }
    }

    async loadGraduationTemplates() {
        const response = await this.props.getGraduationTemplates();

        if (response.isOk) {
            this.setState({
                graduation_templates: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    renderGraduationTable() {
        const items = this.get(`ports.${this.state.graduationActive}.graduation.spreadsheets`) || [];

        const port = this.get(`ports.${this.state.graduationActive}`);
        const bnsoIndicator = _.find(this.state.bnso_indicators, {value: port.bnso_indicator_uuid});
        if (!bnsoIndicator) {
            return;
        }

        const statuses = JSON.parse(bnsoIndicator.document.statuses || '[]');

        return (
            <div className="b-draggable">
                <div className="b-draggable__row fs-zero">
                    <div className="b-block _lg">
                        <div className="b-block__title">Диапазон значений на порту</div>
                    </div>
                    <div className="b-block _lg">
                        <div className="b-block__title">Состояние</div>
                    </div>
                </div>
                {_.map(items, (item, index) => {
                    return (
                        <div key={index} className="b-draggable__item sector sector_idling sector_state_violation">
                            <div className="b-draggable__top fs-zero">
                                <div className="b-draggable__menu">
                                    <div className="b-draggable__menu-link b-draggable__menu-link_basket"
                                         onClick={this.deleteRowFromGraduationTable.bind(this, index)}/>
                                </div>
                                <div className="b-block _sm _sm-m">
                                    <div className="b-block__text-el">От</div>
                                </div>

                                <Block size="sm">
                                    {this.textInput(`bnso.ports.${this.state.graduationActive}.graduation.spreadsheets.${index}.port_value_from`, {
                                        type: 'number',
                                    })}
                                </Block>

                                <div className="b-block _sm _sm-m">
                                    <div className="b-block__text-el">До</div>
                                </div>

                                <Block size="sm">
                                    {this.textInput(`bnso.ports.${this.state.graduationActive}.graduation.spreadsheets.${index}.port_value_to`, {
                                        type: 'number',
                                    })}
                                </Block>

                                <div className="b-block _sm _sm-m">
                                </div>

                                <Block size="lg">
                                    {this.select(`bnso.ports.${this.state.graduationActive}.graduation.spreadsheets.${index}.status`, _.map(statuses, (status, statusIndex) => ({
                                        value: statusIndex,
                                        label: status,
                                    })))}
                                </Block>

                                <div className="b-block _sm _sm-m">
                                </div>

                                <Block size="lg">
                                    <div className="colorpicker-container">
                                        <div className="colorpicker-color"
                                             style={{backgroundColor: this.get(`ports.${this.state.graduationActive}.graduation.spreadsheets.${index}.color`) || '#1186e9'}}/>
                                        <ColorPicker
                                            color={this.get(`ports.${this.state.graduationActive}.graduation.spreadsheets.${index}.color`)}
                                            onDrag={this.onColorChange.bind(this, `bnso.ports.${this.state.graduationActive}.graduation.spreadsheets.${index}.color`)}
                                            saturationWidth={200} saturationHeight={200}/>
                                    </div>
                                </Block>
                            </div>
                        </div>
                    );
                })}
                <span className="add-line" onClick={::this.addRowToGraduationTable}>Добавить строку</span>
            </div>
        );
    }

    onColorChange(field, color) {
        this.setValue(field, color);
    }

    addRowToGraduationTable() {
        const items = this.get(`ports.${this.state.graduationActive}.graduation.spreadsheets`) || [];
        items.push({
            port_value_from: 0,
            port_value_to: 0,
            status: 0,
        });
        this.setValue(`bnso.ports.${this.state.graduationActive}.graduation.spreadsheets`, items);
    }

    deleteRowFromGraduationTable(index) {
        let items = this.get(`ports.${this.state.graduationActive}.graduation.spreadsheets`) || [];
        items.splice(index, 1);
        this.setValue(`bnso.ports.${this.state.graduationActive}.graduation.spreadsheets`, items);
    }

    renderGraduationScales() {
        const items = this.get(`ports.${this.state.graduationActive}.graduation.scales`) || [];

        const port = this.get(`ports.${this.state.graduationActive}`);
        const bnsoIndicator = _.find(this.state.bnso_indicators, {value: port.bnso_indicator_uuid});

        const data = _.map(items, (item) => {
            return {
                name: _.toNumber(item.port_value),
                value: _.toNumber(item.indicator_value),
            };
        });

        return (
            <div>
                <Block size="xl">
                    <div className="table-container">
                        <div className="Table _no-indent">
                            <table className="b-table">
                                <thead>
                                <tr className="b-table__header _text-normal">
                                    <th>Значение на порту</th>
                                    <th>Значение индикатора в ({_.get(bnsoIndicator, 'document.measure')})</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {_.map(items, (item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {this.textInput(`bnso.ports.${this.state.graduationActive}.graduation.scales.${index}.port_value`, {
                                                    type: 'number',
                                                })}
                                            </td>
                                            <td>
                                                {this.textInput(`bnso.ports.${this.state.graduationActive}.graduation.scales.${index}.indicator_value`, {
                                                    type: 'number',
                                                })}
                                            </td>
                                            <td>
                                            <span className="clear"
                                                  onClick={this.deleteRowFromGraduationScales.bind(this, index)}>×</span>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                        <span className="add-line" onClick={::this.addRowToGraduationScales}>Добавить строку</span>
                    </div>
                </Block>
                <Block size="xl">
                    <div className="widget-block">
                        <ResponsiveContainer>
                            <LineChart data={data}>
                                <XAxis dataKey="name" type="number" interval={0}/>
                                <YAxis/>
                                <CartesianGrid horizontal={false}/>
                                <Line type="linear" dataKey="value" name="Значение индикатора"/>
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </Block>
            </div>
        )
    }

    addRowToGraduationScales() {
        const items = this.get(`ports.${this.state.graduationActive}.graduation.scales`) || [];
        items.push({
            port_value: 0,
            indicator_value: 0,
        });
        this.setValue(`bnso.ports.${this.state.graduationActive}.graduation.scales`, items);
    }

    deleteRowFromGraduationScales(index) {
        let items = this.get(`ports.${this.state.graduationActive}.graduation.scales`) || [];
        items.splice(index, 1);
        this.setValue(`bnso.ports.${this.state.graduationActive}.graduation.scales`, items);
    }
}
