import { map, sortBy, uniq, flatten, filter, concat } from 'lodash';

export const reduceDictionariesResponseToMap = (items) =>
	items.reduce(
		(prev, cur) => ({
			...prev,
			[cur.key]: cur.documents.reduce((prev, cur) => ({ ...prev, [cur.uuid]: cur }), {})
		}),
		{}
	);
export const mapUnits = (items) =>
	sortBy(
		map(items, (unit) => ({
			value: unit.uuid,
			label: unit.name,
			component: unit.component,
			color: unit.color,
			parent_unit_uuid: unit.parent_unit_uuid
		})),
		'label'
	);

export const getUniqueRoutesUuid = (routes) =>
	uniq(
		map(
			flatten(
				map(routes, (item) => {
					return filter(concat(item.forward_points, item.reverse_points), { point_type: 'stop_point' });
				})
			),
			'type_uuid'
		)
	);
