export const validationFactDateTime = (data) => {
    //проверка на пустые значения fact_date_from fact_date_to
    function isFactDateEmpty(element) {
        return element.fact_date_from && element.fact_date_to;
    }
    //формируем массив для проверки только с item, у которых есть признак is_confirmed
    if(!data.items_fact) {
        return true;
    }
    const itemsWithConfirmed = data.items_fact.filter((item, index) => {
        if (item.is_confirmed) {
            item["index"] = index;
            return item
        }
    });
    //если таких item нет - значит валидация прошла
    if (itemsWithConfirmed.length === 0) {
        return true;
    } else {
        // если есть item.is_confirmed, проверяем каждый на значение и с 1 false
        // уже останавливаем проверку и валидация не прошла
        return itemsWithConfirmed.every(isFactDateEmpty);
    }
}