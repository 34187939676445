import {rpc} from './session';

export async function getReports() {
    return await rpc.request('com.rnis.reports.action.reports.list');
}

export async function getSolveProblemWidgetData(payload, meta) {
    return await rpc.request('com.rnis.reports.action.or71.summary', payload, {meta});
}

export async function getReportsViolationsKiutr(payload) {
    return await rpc.request('com.rnis.reports.action.stats.violations.kiutr=', payload);
}

export async function getReportParameters(report, parameters = []) {
    return await rpc.request('com.rnis.reports.action.report.parameters', {
        report,
        parameters: {
            items: parameters,
        }
    });
}

export async function createReport(data, meta) {
    return await rpc.request('com.rnis.reports.action.report.create', data, {meta});
}

export async function getDocumentsList(meta) {
    return await rpc.request('com.rnis.reports.action.document.list', {}, {meta});
}

export async function listReportTemplates(meta) {
    return await rpc.request('com.rnis.reports.action.report_templates.list', {}, {meta});
}

export async function deleteReportTemplate(data) {
    return await rpc.request('com.rnis.reports.action.report_template.delete', data);
}

export async function getTabletSummary(date_from, date_to, field = null) {
    return await rpc.request('com.rnis.reports.action.tablet.summary', {date_from, date_to, field});
}

export async function getTabletWorkSummary(meta = {}) {
    return await rpc.request('com.rnis.kurs.action.date.tasks.item_chart.list', {}, {meta});
}

export async function getTabletWorkSummaryPercent(meta = {}) {
    return await rpc.request('com.rnis.kurs.action.analytics.objects.completeness.list', {}, {meta});
}

export async function getTabletKiutrSummary(data) {
    return await rpc.request('com.rnis.reports.action.tablet.summary.kiutr', data);
}

export async function getTabletKiutrSummaryUnit(data) {
    return await rpc.request('com.rnis.reports.action.tablet.summary.kiutr.unit', data);
}

export async function getMunicipalitiesKiutrSummary(data) {
    return await rpc.request('com.rnis.reports.action.stats.municipalities.kiutr', data);
}

export async function getTabletUnitSummary(data) {
    return await rpc.request('com.rnis.reports.action.tablet.summary.unit', data);
}

export async function getReportSchedules(meta = {}) {
    return await rpc.request('com.rnis.reports.action.report_schedule.list', {}, {meta});
}

export async function getReportSchedule(uuid) {
    return await rpc.request('com.rnis.reports.action.report_schedule.get', {uuid});
}

export async function createReportSchedule(data) {
    return await rpc.request('com.rnis.reports.action.report_schedule.create', data);
}

export async function updateReportSchedule(data) {
    return await rpc.request('com.rnis.reports.action.report_schedule.update', data);
}

export async function deleteReportSchedule(data) {
    return await rpc.request('com.rnis.reports.action.report_schedule.delete', data);
}

export async function getTabletScheduleIntervalSummary(data) {
    return await rpc.request('com.rnis.geo.action.schedule_interval_report_by_unit.get_unit_top', data);
}
