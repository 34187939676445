import {rpc} from './session';
import _ from 'lodash';

export async function getFreeDrivers(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.available.driver.list', {}, {meta});
}

export async function getContracts(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.contract.list', {}, {meta});
}

export async function getContract(uuid) {
    return await rpc.request('com.rnis.commdept.action.contract.get', {uuid});
}

export async function createContract(data) {
    return await rpc.request('com.rnis.commdept.action.contract.create', data);
}

export async function updateContract(data) {
    return await rpc.request('com.rnis.commdept.action.contract.update', data);
}

export async function deleteContract(data) {
    return await rpc.request('com.rnis.commdept.action.contract.delete', data);
}

export async function getRepairPrograms(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.repair_program.list', {}, {meta});
}

export async function getRepairProgram(uuid) {
    return await rpc.request('com.rnis.commdept.action.repair_program.get', {uuid});
}

export async function createRepairProgram(data) {
    return await rpc.request('com.rnis.commdept.action.repair_program.create', data);
}

export async function updateRepairProgram(data) {
    return await rpc.request('com.rnis.commdept.action.repair_program.update', data);
}

export async function deleteRepairProgram(data) {
    return await rpc.request('com.rnis.commdept.action.repair_program.delete', data);
}

export async function getContractWorks(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.contract_work.list', {}, {meta});
}

export async function getContractWork(uuid) {
    return await rpc.request('com.rnis.commdept.action.contract_work.get', {uuid});
}

export async function createContractWork(data) {
    return await rpc.request('com.rnis.commdept.action.contract_work.create', data);
}

export async function updateContractWork(data) {
    return await rpc.request('com.rnis.commdept.action.contract_work.update', data);
}

export async function deleteContractWork(data) {
    return await rpc.request('com.rnis.commdept.action.contract_work.delete', data);
}

export async function getVehicles(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.vehicle.list', {}, {meta});
}

export async function getVehicle(data) {
    return await rpc.request('com.rnis.commdept.action.vehicle.get', data);
}

export async function createVehicle(data) {
    return await rpc.request('com.rnis.commdept.action.vehicle.create', data);
}

export async function updateVehicle(data) {
    if (data.date_activation === null) data.date_activation = '';
    if (data.date_disactivation === null) data.date_disactivation = '';
    return await rpc.request('com.rnis.commdept.action.vehicle.update', data);
}

export async function deleteVehicle(data) {
    return await rpc.request('com.rnis.commdept.action.vehicle.delete', data);
}

export async function getVehicleWorks(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.vehicle.work.list', {}, {meta});
}

export async function getVehicleWork(uuid) {
    return await rpc.request('com.rnis.commdept.action.vehicle.work.get', {uuid});
}

export async function createVehicleWork(data) {
    return await rpc.request('com.rnis.commdept.action.vehicle.work.create', data);
}

export async function updateVehicleWork(data) {
    return await rpc.request('com.rnis.commdept.action.vehicle.work.update', data);
}

export async function deleteVehicleWork(data) {
    return await rpc.request('com.rnis.commdept.action.vehicle.work.delete', data);
}

export async function getVehicleMechanisms(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.vehicle.mechanism.list', {}, {meta});
}

export async function getVehicleMechanism(uuid) {
    return await rpc.request('com.rnis.commdept.action.vehicle.mechanism.get', {uuid});
}

export async function createVehicleMechanism(data) {
    return await rpc.request('com.rnis.commdept.action.vehicle.mechanism.create', data);
}

export async function updateVehicleMechanism(data) {
    return await rpc.request('com.rnis.commdept.action.vehicle.mechanism.update', data);
}

export async function deleteVehicleMechanism(data) {
    return await rpc.request('com.rnis.commdept.action.vehicle.mechanism.delete', data);
}

export async function getKursMaintenances(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.maintenance.list', {}, {meta});
}

export async function getKursMaintenance(uuid) {
    return await rpc.request('com.rnis.commdept.action.maintenance.get', {uuid});
}

export async function createKursMaintenance(data) {
    return await rpc.request('com.rnis.commdept.action.maintenance.create', data);
}

export async function updateKursMaintenance(data) {
    return await rpc.request('com.rnis.commdept.action.maintenance.update', data);
}

export async function deleteKursMaintenance(data) {
    return await rpc.request('com.rnis.commdept.action.maintenance.delete', data);
}

export async function getStos(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.sto.list', {}, {meta});
}

export async function getSto(uuid) {
    return await rpc.request('com.rnis.commdept.action.sto.get', {uuid});
}

export async function createSto(data) {
    return await rpc.request('com.rnis.commdept.action.sto.create', data);
}

export async function updateSto(data) {
    return await rpc.request('com.rnis.commdept.action.sto.update', data);
}

export async function deleteSto(data) {
    return await rpc.request('com.rnis.commdept.action.sto.delete', data);
}

export async function getStoWorks(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.sto_work.list', {}, {meta});
}

export async function getStoWork(uuid) {
    return await rpc.request('com.rnis.commdept.action.sto_work.get', {uuid});
}

export async function createStoWork(data) {
    return await rpc.request('com.rnis.commdept.action.sto_work.create', data);
}

export async function updateStoWork(data) {
    return await rpc.request('com.rnis.commdept.action.sto_work.update', data);
}

export async function deleteStoWork(data) {
    return await rpc.request('com.rnis.commdept.action.sto_work.delete', data);
}

export async function getTasks(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.task.list', {}, {meta});
}

export async function getTask(uuid) {
    return await rpc.request('com.rnis.commdept.action.task.get', {uuid});
}

export async function createTask(data) {
    return await rpc.request('com.rnis.commdept.action.task.create', data);
}

export async function importTask(data) {
    return await rpc.request('com.rnis.commdept.action.task.import', data);
}

export async function updateTask(data) {
    return await rpc.request('com.rnis.commdept.action.task.update', data);
}

export async function updateTaskCheck(data) {
    return await rpc.request('com.rnis.commdept.action.task.update.check', data);
}

export async function deleteTask(data) {
    return await rpc.request('com.rnis.commdept.action.task.delete', data);
}

export async function repeatTask(data) {
    return await rpc.request('com.rnis.commdept.action.task.repeat', data);
}

export async function getTaskTemplates(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.task_template.list', {}, {meta});
}

export async function getTaskTemplate(uuid) {
    return await rpc.request('com.rnis.commdept.action.task_template.get', {uuid});
}

export async function createTaskTemplate(data) {
    return await rpc.request('com.rnis.commdept.action.task_template.create', data);
}

export async function updateTaskTemplate(data) {
    return await rpc.request('com.rnis.commdept.action.task_template.update', data);
}

export async function deleteTaskTemplate(data) {
    return await rpc.request('com.rnis.commdept.action.task_template.delete', data);
}

export async function getRoads(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.road.list', {}, {meta});
}

export async function getRoad(uuid) {
    return await rpc.request('com.rnis.commdept.action.road.get', {uuid});
}

export async function createRoad(data) {
    return await rpc.request('com.rnis.commdept.action.road.create', data);
}

export async function updateRoad(data) {
    return await rpc.request('com.rnis.commdept.action.road.update', data);
}

export async function deleteRoad(data) {
    return await rpc.request('com.rnis.commdept.action.road.delete', data);
}

export async function getRoadParts(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.road_part.list', {}, {meta});
}

export async function getRoadPart(uuid, slice = {}) {
    return await rpc.request('com.rnis.commdept.action.road_part.get', _.merge({
        uuid,
    }, slice));
}

export async function getRoadPartWorkTypes(uuid, date) {
    return await rpc.request('com.rnis.commdept.action.road_part.work_types', {uuid, date});
}

export async function getRoadPartWorkTypesMultiple(uuids, date, withoutUsed = false) {
    return await rpc.request('com.rnis.commdept.action.road_part.work_types.multiple', {
        uuids,
        date,
        without_used: withoutUsed,
    });
}

export async function checkRoadPartWorkType(data) {
    return await rpc.request('com.rnis.commdept.action.road_part.work_type.check', data);
}

export async function createRoadPart(data) {
    return await rpc.request('com.rnis.commdept.action.road_part.create', data);
}

export async function updateRoadPart(data) {
    return await rpc.request('com.rnis.commdept.action.road_part.update', data);
}

export async function deleteRoadPart(data) {
    return await rpc.request('com.rnis.commdept.action.road_part.delete', data);
}

export async function getWaybills(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.waybill.list', {}, {meta});
}

export async function getWaybill(uuid) {
    return await rpc.request('com.rnis.commdept.action.waybill.get', {uuid});
}

export async function createWaybill(data) {
    return await rpc.request('com.rnis.commdept.action.waybill.create', data);
}

export async function updateWaybill(data) {
    return await rpc.request('com.rnis.commdept.action.waybill.update', data);
}

export async function deleteWaybill(data) {
    return await rpc.request('com.rnis.commdept.action.waybill.delete', data);
}

export async function getDriversScoreSummary(drivers) {
    return await rpc.request('com.rnis.commdept.action.drivers.score', {drivers});
}

export async function getSupernumerarySituations(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.supernumerary_situation.list', {}, {meta});
}

export async function getSupernumerarySituation(uuid) {
    return await rpc.request('com.rnis.commdept.action.supernumerary_situation.get', {uuid});
}

export async function createSupernumerarySituation(data) {
    return await rpc.request('com.rnis.commdept.action.supernumerary_situation.create', data);
}

export async function updateSupernumerarySituation(data) {
    return await rpc.request('com.rnis.commdept.action.supernumerary_situation.update', data);
}

export async function deleteSupernumerarySituation(data) {
    return await rpc.request('com.rnis.commdept.action.supernumerary_situation.delete', data);
}

export async function importKursVehicles(data) {
    return await rpc.request('com.rnis.commdept.action.vehicle.import', data);
}

export async function importKursVehiclesGet(uuid) {
    return await rpc.request('com.rnis.commdept.action.vehicle.import.get', {uuid});
}

export async function listObjectVisits(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.object_visit.list', {}, {meta});
}

export async function getTechnocards(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.technocard.list', {}, {meta});
}

export async function getTechnocard(uuid) {
    return await rpc.request('com.rnis.commdept.action.technocard.get', {uuid});
}

export async function createTechnocard(data) {
    return await rpc.request('com.rnis.commdept.action.technocard.create', data);
}

export async function updateTechnocard(data) {
    return await rpc.request('com.rnis.commdept.action.technocard.update', data);
}

export async function deleteTechnocard(data) {
    return await rpc.request('com.rnis.commdept.action.technocard.delete', data);
}

export async function getTechnocardAssigns(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.technocard_assign.list', {}, {meta});
}

export async function getTechnocardAssign(uuid) {
    return await rpc.request('com.rnis.commdept.action.technocard_assign.get', {uuid});
}

export async function createTechnocardAssign(data) {
    return await rpc.request('com.rnis.commdept.action.technocard_assign.create', data);
}

export async function updateTechnocardAssign(data) {
    return await rpc.request('com.rnis.commdept.action.technocard_assign.update', data);
}

export async function deleteTechnocardAssign(data) {
    return await rpc.request('com.rnis.commdept.action.technocard_assign.delete', data);
}

export async function getVehicleFuelEvents(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.vehicle_fuel_event.list', {}, {meta});
}

export async function getVehicleFuelLevels(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.vehicle_fuel_level.list', {}, {meta});
}

export async function getTaskViolations(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.task_violation.list', {}, {meta});
}

export async function getSkpdiLogs(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.skpdi_log.list', {}, {meta});
}

export async function getRoadRepairPart(data) {
    return await rpc.request('com.rnis.commdept.action.road_repair_part.get', data);
}

export async function skpdiImportTasks() {
    return await rpc.request('com.rnis.commdept.action.skpdi.import.tasks', {});
}

export async function vehicleFuelRecalc(data) {
    return await rpc.request('com.rnis.commdept.action.fuel.recalc', data);
}

export async function unitTasksDoneChart(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.unit.tasks.done_chart', {}, {meta});
}

export async function dateTasksDoneChart(meta = {}) {
    return await rpc.request('com.rnis.commdept.action.date.tasks.done_chart', {}, {meta});
}

