import React, {Component} from 'react';
import _ from 'lodash';
import './style.less';

export default class PlanRow extends Component {
  constructor() {
    super();

    this.state = {
      isShow: false,
      monthes: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентярбь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ]
    }
  }

  renderCells() {
    return this.state.monthes.map((value, idx) => {
      return (
        <div className="planning-works__cell planning-works__cell_td" key={idx}>
					<span className='planning-works__separate'></span>
					<div className="row">
            <div className="col col-3"></div>
            <div className="col col-3"></div>
            <div className="col col-3"></div>
            <div className="col col-3"></div>
					</div>
				</div>
      )
    });
  }

  toggleShow() {
    this.setState({
      isShow: !this.state.isShow
    })
  }

  render() {
    const work_label = this.props.work_types[this.props.data.uuid];

    //console.log("Work label", this.props, work_label)

    return (      
        <div className="planning-works__row">
          <div className="col col-4">
            <div className="accordion">
									<div className="accordion__item">
										<input className="accordion__open" type="checkbox" onChange={::this.toggleShow} />
										<i className="accordion__arrow"></i>
										<div className="accordion__title">{this.props.roadParts[this.props.data.road_uuid]}</div>
										{this.state.isShow && (
                      <div className="accordion__content">
                        {this.props.data && this.props.data.works.map((value, idx) => {
                          return (
                            <div className="accordion__content-row" key={`row${idx}`}>
                              <span>{value.title}</span>
                            </div>
                          )
                        })}
                        {/* {
                          _find(this.props.data, (o) => {
                            if(this.props.work_types === o.uuid) {
                              return this.props.work_types.label;
                            }
                          })
                        } */}
                      </div>
                    )}
									</div>
						</div>
          </div>
          <div className="col col-8">
            <div className="planning-works__table-wrap">
              <div className="planning-works__table">
                <div className="planning-works__row">
                  <div className="planning-works__ind planning-works__ind_r1">
										<div className="planning-works__ind-item planning-works__ind-left">100%</div>
										<div className="planning-works__ind-item planning-works__ind-sep"></div>
										<div className="planning-works__ind-item planning-works__ind-right">60%</div>
									</div>
                  {this.state.monthes.map((value, idx) => {
										return <div className="planning-works__cell planning-works__cell_td" key={idx}><span className='planning-works__separate'></span></div>
									})}
                </div>
                {this.state.isShow && this.props.data.works.map((value, idx) => {
                  return (
                    <div className="planning-works__row planning-works__ind_dim" key={idx}>
                      <div className="planning-works__ind planning-works__ind_r2 planning-works__ind_small planning-works__ind_small_1 planning-works__ind_dim">
                        <div className="planning-works__ind-item planning-works__ind-left">100%</div>
                        <div className="planning-works__ind-item planning-works__ind-sep"></div>
                        <div className="planning-works__ind-item planning-works__ind-right">10%</div>
                      </div>
                      {this.renderCells()}
                      
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
    
    )
  }
}