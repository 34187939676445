import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import _ from 'lodash';
import {success, error} from 'helpers/response';
import {cached} from "helpers/cached";

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getUnits = (meta, isCached = true) => async (dispatch) => {
    const cacheKey = 'units:' + JSON.stringify(meta);
    const callback = async () => {
        try {
            const response = await api.organizational_units.getUnits(meta);

            return success(response);
        }
        catch (e) {
            return error(e);
        }
    };
    return isCached ? cached(cacheKey, callback, 60, true) : await callback();
};

export const getUnitsForSelect = (input, component = null) => async (dispatch) => {
    try {
        const filters = component ? {
            withComponent: component,
        } : {};

        const result = await api.organizational_units.getUnits({
            filters,
            page: 1,
            limit: 50,
            search: input
        });

        return {
            options: result.payload.items.map(i => ({label: i.name, value: i.uuid})),
            complete: false
        };
    }
    catch (e) {
        return Promise.reject();
    }
};

export const getUnitPositionsForSelect = (unit_uuid, input) => async (dispatch) => {
    try {
        const result = await api.organizational_units.getPositions({
            search: input,
            filters: {
                withUnitUuid: [
                    unit_uuid,
                ],
            },
        });

        return {
            options: result.payload.items.map(i => ({label: i.name, value: i.uuid, type: i.type})),
            complete: false
        };
    }
    catch (e) {
        return Promise.reject();
    }
};

export const getUnitUserGroupsForSelect = (unit_uuid, input) => async (dispatch) => {
    try {
        const result = await api.organizational_units.getUserGroups({
            search: input,
            filters: {
                withUnitUuid: [
                    unit_uuid,
                ],
            },
        });

        return {
            options: result.payload.items.map(i => ({label: i.name, value: i.uuid})),
            complete: false
        };
    }
    catch (e) {
        return Promise.reject();
    }
};