import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';
import Column from 'components/ui/column';
import Page from 'components/ui/page';
import BaseTableComponent from 'components/base/base_table';
import TableContainer from 'components/ui/Table/Container/TableContainer';
import moment from 'moment';
import formats from 'dictionaries/formats';
import {getEntityNames} from 'store/reducers/system';
import {getRouteRegistries} from 'store/reducers/kiutr/route_registries';
import {EntityList} from 'helpers/entity';
import systems from 'dictionaries/systems';
import {mapDatatablesRequestToMeta} from 'helpers/api';
import {List} from 'immutable';
import Datepicker from "components/ui/form/datepicker";
import IconButton from 'components/ui/icon-button';
import SearchField from 'components/ui/search-field';
import currentUser from 'helpers/current-user';
import KiutrTransportWorkViolations from './violations';
import KiutrTransportWorkDispatcher from './dispatcher';
import ContextTooltip from "components/ui/context-tooltip";
import HeaderTextButton from 'components/ui/header-text-button';
import classnames from 'classnames';
import * as alerts from 'helpers/alerts';
import './style.less';
import {getUnits} from "store/reducers/organizational_units/units";
import HotKeysManager from "components/ui/HotKeys/Manager/HotKeysManager";
import {component_mapper} from "helpers/component_mapper";
import {preventDefaultEventHandlers} from "helpers/hot_key_handlers";

@connect(state => ({}), {getRouteRegistries, getEntityNames, getUnits})

export default class KiutrTransportWork extends BaseTableComponent {

    getTitle() {
        return `${systems[this.props.params.component]} → Учет транспортной работы`;
    }

    getBaseUrl() {
        return `/${this.props.params.component}/contracts`;
    }

    componentDidMount() {
        this.forceUpdate();

        if (this.props.params.uuid) {
            this.setState({
                uuids: [
                    this.props.params.uuid,
                ],
                from: moment(this.props.location.query.date, formats.DATE).startOf('day'),
                to: moment(this.props.location.query.date, formats.DATE).endOf('day'),
                isShowViolationsForm: false,
                isShowDispatcherForm: true
            });
        }
    }

    async componentWillUpdate(props, state) {
        if (component_mapper(props.params.component) !== state.component) {
            await this.setState({
                component: component_mapper(props.params.component),
                columns: List(this.getColumns()),
            });
            state.component && this.reload();
        }
    }

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            component: null,
            columns: List(this.getColumns()),
            related: new EntityList(),
            from: moment().startOf('day'),
            to: moment().endOf('day'),
            uuids: [],
            isShowViolationsForm: false,
            isShowDispatcherForm: false
        });

        //this.uuids = null;
        this.from = null;
        this.to = null;
    }

    componentDidUpdate() {
        //this.uuids = null;
        this.from = null;
        this.to = null;
    }

    onClick() {
        this.setState({uuids: this.getSelectedUuids()});
    };

    getSelectedUuids() {
        if (!this.refs.table) {
            return [];
        }
        let uuids = [];
        const selected = this.refs.table.getWrappedInstance().getSelected();
        if (!selected) {
            return [];
        }
        const data = selected.data();

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                uuids.push(data[i].uuid);
            }
        }

        return uuids;
    }

    isShowViolationsForm() {
        return this.state.uuids.length && this.state.isShowViolationsForm;
    }

    isShowDispatcherForm() {
        return this.state.uuids.length && this.state.isShowDispatcherForm;
    }

    goToViolationsForm = () => {
        const uuids = this.getSelectedUuids();

        if (uuids.length) {
            this.setState({
                uuids,
                isShowViolationsForm: true,
                isShowDispatcherForm: false
            });
        }
    };

    goToDispatcherForm = () => {
        const uuids = this.getSelectedUuids();

        if (uuids.length) {
            this.setState({
                uuids,
                isShowViolationsForm: false,
                isShowDispatcherForm: true
            });
        }
    };

    renderHeaderActions() {
        return [
            <ContextTooltip key="base-table-list.search" code="base-table-list.search">
                <SearchField ref="search_btn" onChange={::this.onSearchChange}
                             value={this.state.queryBounced}
                             onShow={::this.searchFieldShow}
                             onHide={::this.searchFieldHide}/>
            </ContextTooltip>,
            <ContextTooltip key="base-table-list.filter" code="base-table-list.filter">
                <IconButton icon="filter" active={this.state.showTableFilters}
                            onClick={::this.toggleTableFilters}/>
            </ContextTooltip>,
            <ContextTooltip key="transport-work.goto-violations" code="transport-work.goto-violations"
                            default="К нарушениям" position="left">
                <IconButton icon="violations" disabled={!this.getSelectedUuids().length}
                            onClick={this.goToViolationsForm}/>
            </ContextTooltip>,
        ];
    }

    fromChange = async ({target: {value}}) => {
        /*if (moment(value, formats.DATE_URL).isAfter(moment(this.state.to, formats.DATE_URL))) {
            const prevDate = moment(this.state.from).format(formats.DATE_API);
            await this.setState({from: prevDate});
            alerts.error('Неверный диапазон дат');
        }
        else {*/
        await this.setState({from: value});
        this.reload();
        //}
    };

    toChange = async ({target: {value}}) => {
        /*if (moment(value, formats.DATE_URL).isBefore(moment(this.state.from, formats.DATE_URL))) {
            const prevDate = moment(this.state.to).format(formats.DATE_API);
            await this.setState({to: prevDate});
            alerts.error('Неверный диапазон дат');
        }
        else {*/
        await this.setState({to: value});
        this.reload();
        //}
    };

    renderHeaderContents() {
        const classNames = classnames('transport-work-diapason-wrapper', {
            'left-shift': this.state.showTableSearchFooter
        });

        return [
            <div className={classNames}>
                <div key="diapason-from">
                    <div className="top-menu__label">Дата с:</div>
                    <Datepicker style="dark" value={this.state.from} onChange={this.fromChange}/>
                </div>
                <div key="diapason">
                    <div className="top-menu__label">Дата по:</div>
                    <Datepicker style="dark" value={this.state.to} onChange={this.toChange}/>
                </div>
            </div>
        ];
    }

    onViolationsFormClose = () => {
        this.setState({isShowViolationsForm: false});
    };

    onDispatcherFormClose = () => {
        this.setState({isShowDispatcherForm: false});
    };

    onGoToDispatcherFromViolations = (/*uuids,*/ from, to) => {
        //this.uuids = uuids;
        if (from && to) {
            this.from = from;
            this.to = to;
        }
        this.setState({
            isShowDispatcherForm: true,
            isShowViolationsForm: false
        });
    };

    onGoToViolationsFromDispatcher = (/*uuids,*/ from, to) => {
        //this.uuids = uuids;
        if (from && to) {
            this.from = from;
            this.to = to;
        }
        this.setState({
            isShowDispatcherForm: false,
            isShowViolationsForm: true
        });
    };

    async loadData(meta) {
        _.set(meta, 'filters.withComponent', this.state.component);
        meta.filters.withValidRoutes = true;
        meta.filters.withContractWorkPeriod = [
            moment(this.state.from).format(formats.DATE_API),
            moment(this.state.to).format(formats.DATETIME_API)
        ];
        return await this.props.getRouteRegistries(meta);
    }

    onDblClick() {
        this.goToDispatcherForm();
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Номер маршрута')
                .fromField('route.number'),

            new Column('Рег. номер маршрута')
                .fromField('route.registration_number'),

            new Column('Название маршрута')
                .fromField('route.title'),

            new Column(window.RNIS_SETTINGS.rename_contracts ? 'Подрядное обязательство' : 'Контракт')
                .fromField('contract.number'),

            new Column('Добавлен')
                .fromField('route.created_at')
                .withDateFilter()
                .withDrawer(item => moment(item.created_at).format(formats.DATETIME_SHORT)),

            new Column('Организатор перевозок')
                .fromField('contract.unit_uuid')
                .withDrawer(item => this.state.related.get(_.get(item, 'contract.unit_uuid')))
                .withFilter('withUnits', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 1000,
                        },
                        filters: {
                            withComponent: component_mapper(this.props.params.component),
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            new Column('Перевозчик')
                .fromField('contract.carrier_uuid')
                .withDrawer(item => this.state.related.get(_.get(item, 'contract.carrier_uuid')))
                .withFilter('withCarriers', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 1000,
                        },
                        filters: {
                            withComponent: component_mapper(this.props.params.component),
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

        ]);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const units = _.map(_.filter(_.map(result, 'contract.unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const carriers = _.map(_.filter(_.map(result, 'contract.carrier_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(_.concat(units, carriers));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    async loadCallback(request, drawCallback, settings) {
        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        const response = await this.loadData(meta);
        //console.log(meta);

        if (response.isOk) {
            const data = response.payload.items;
            //console.log(data);

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: response.data.headers.meta.pagination.total,
                recordsTotal: response.data.headers.meta.pagination.total
            };

            drawCallback(json);
            this.loadRelatedEntities(json, drawCallback);
            this.selectedRowsRecalc();
            this.setState({success: true});
        } else {
            response.showErrors();
        }
    }

    renderTransportWork(isHide) {
        return (
            <Page title={this.getTitle()}
                  pageId="TransportWork"
                  headerActions={this.renderHeaderActions()}
                  headerContents={this.renderHeaderContents()}
                //rootClassName={isHide ? 'hide-form': ''}
            >

                <TableContainer>
                    {this.renderTable()}
                </TableContainer>
            </Page>
        );
    }

    renderViolationsForm = () => {
        return (
            <KiutrTransportWorkViolations
                {...this.props}
                uuids={/*this.uuids ||*/ this.state.uuids}
                from={this.from || moment(this.state.from).format(formats.DATE)}
                to={this.to || moment(this.state.to).format(formats.DATE)}
                onClose={this.onViolationsFormClose}
                onGoToDispatcher={this.onGoToDispatcherFromViolations}
            />
        );
    };

    renderDispatcherForm = () => {
        return (
            <KiutrTransportWorkDispatcher
                {...this.props}
                uuids={/*this.uuids ||*/ this.state.uuids}
                from={this.from || moment(this.state.from).format(formats.DATE)}
                to={this.to || moment(this.state.to).format(formats.DATE)}
                onClose={this.onDispatcherFormClose}
                onGoToViolations={this.onGoToViolationsFromDispatcher}
            />
        );
    };

    renderContent() {
        return this.isShowViolationsForm() ? this.renderViolationsForm()
            : this.isShowDispatcherForm() ? this.renderDispatcherForm()
                : this.renderTransportWork();
    }

    getHotKeyHandlers() {
        return preventDefaultEventHandlers(Object.assign(super.getHotKeyHandlers(), {
            violations: ::this.onGoToViolationsFromDispatcher,
            list: ::this.onViolationsFormClose,
        }));
    }

    render() {
        return (
            <HotKeysManager {...this.getHotKeyHandlers()}>
                {this.renderContent()}
            </HotKeysManager>
        );
    }
}
