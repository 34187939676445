import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProp} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {api} from "helpers/api";
import classNames from 'classnames';

import FileReaderInput from 'react-file-reader-input';
import {getRoute, createRoute, updateRoute, cloneRoute, cloneFillRoute} from "store/reducers/routes/route_editor";
import {getUnits} from "store/reducers/organizational_units/units";
import {listUserGeoObjectsMunicipalitiesList} from "store/reducers/user-map-objects/object_editor";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import currentUser from 'helpers/current-user';
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import * as alerts from "helpers/alerts";
import GlobalLoaderComponent from "components/ui/global-loader";
import PageModal from 'components/ui/page-modal';
import Variants from './variants';
import {isRouteA} from "helpers/route";
import KiutrRouteSchedulesComponent from "components/modules/kiutr/routes/schedule/schedules";
import KiutrRouteIntervalSchedulesComponent from "components/modules/kiutr/routes/schedule/intervalSchedules";
import EasyXml from 'easyxml';
import {getUser} from "store/reducers/staffing/staffing_editor";
import {User} from "helpers/user";
import {xmlVerify} from "store/reducers/ecp/ecp";
import {resizeModals} from 'helpers/modal-size';
import {events} from 'dom-helpers';
import ContextTooltip from "components/ui/context-tooltip";
import moment from "moment";
import formats from "dictionaries/formats";
import Script from 'react-load-script';
import {component_mapper} from "helpers/component_mapper";
import EcpSignLog from "components/modules/kiutr/routes/EcpSignLog/EcpSignLog";
import {getRoutes} from "store/reducers/routes/routes";
import {SelectAsync} from "components/ui/select";
import WarningModal from "components/ui/WarningModal/warning-modal";
import {isCarriersLimits, isMunicipalType} from 'helpers/functions';

let resizeId;

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
    isPageWithDetect:  PropTypes.bool,
})

@connect(state => ({}), {
    getRoute,
    createRoute,
    cloneRoute,
    cloneFillRoute,
    getRoutes,
    updateRoute,
    getDictionaryList,
    getUser,
    xmlVerify,
    listUserGeoObjectsMunicipalitiesList
})

export default class Editor extends BaseEditor {

    title = 'Карта маршрута';
    modelClass = 'App\\Model\\Route';
    wrapperStyle = {height: '100%'};

    cryptopro = null;
    cryptoproCertificate = null;

    static childContextTypes = {
        resizeModals: PropTypes.func
    };

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            showVariants: false,
            showSchedules: false,
            showSchedulesInterval: false,
            signed_by: null,
            signed_status: null,
            route_passport_statuses: [],
        });

        this.onResize = this.onResize.bind(this);

        this.loadDictionaries([
            'route_passport_statuses'
        ]);
    }

    getChildContext() {
        return {resizeModals: this.resizeModals};
    }

    componentWillMount() {
        events.on(window, 'resize', this.onResize);
    }

    componentDidMount() {
        if(this.props.isPageWithDetect && this.modalConfirmation) {
            document.addEventListener('click', this.handleClickOutside, true)
        }
     }

    async componentWillReceiveProps(props) {
        await super.componentWillReceiveProps(props);

        this.setState({showVariants: isRouteA(props, 'kiutr-route-variants')});
        this.setState({showSchedules: isRouteA(props, 'kiutr-route-schedules')});
        this.setState({showSchedulesInterval: isRouteA(props, 'kiutr-route-schedules-interval')});

        if (isRouteA(props, 'kiutr-route-schedules-interval')) {
            this.setState({showSchedules: true, showSchedulesInterval: true});
        }

        setTimeout(() => {
            this.resizeModals();
        }, 500);
    }

    componentWillUnmount() {
        events.off(window, 'resize', this.onResize);

        if(this.props.isPageWithDetect && this.modalConfirmation) {
            document.removeEventListener('click', this.handleClickOutside)
        }
    }

    onResize() {
        clearTimeout(resizeId);
        resizeId = setTimeout(this.resizeModals, 1000);
    }

    resizeModals() {
        const ignoreClasses = ['route-add-points-modal', 'add-time-interval-modal', 'map-route-editor-modal', 'add-stopping-point-modal'];
        resizeModals('.b-modal', 'b-modal__collapse', 'move-right', ignoreClasses);
    }

    async loadData(uuid) {
        const response = await this.props.getRoute(uuid);
        if (!window.RNIS_SETTINGS.ECPOFF && response.isOk && response.payload.ecp_sign) {
            this.verifySign(response.payload);
        }
        return response;
    }

    async verifySign(item) {
        const sign = item.ecp_sign;

        let xml = this.getXml(_.clone(item));
        const signPosition = xml.indexOf('</Data>') + '</Data>'.length;
        xml = xml.substr(0, signPosition + 1) + sign + xml.substr(signPosition + 1);

        xml = xml.replace(/\s{2}/g, '');

        const response = await this.props.xmlVerify(xml);
        if (response.isOk) {
            const subjectName = _.get(response.payload, 'certificateInfo.subjectName');
            const matches = /CN=([^,]+)(,|$)/.exec(subjectName);
            const signed_by = matches[1].replace(/\\"/g, '"');

            this.setState({
                signed_status: response.payload.isSignatureValid && response.payload.certificateInfo.expiredCheck.code === 'VALID',
                signed_by,
            });
        }
    }

    async createItem(data) {
        return await this.props.createRoute(data);
    }

    async updateItem(data) {
        return await this.props.updateRoute(data);
    }

    composeItem(data) {
        let item = _.clone(data);

        item.component = component_mapper(this.props.params.component);
        item.groups = null;
        item.spiral = null;

        return item;
    }

    getXml(route = null) {
        const easyXml = new EasyXml({
            singularize: true,
            rootElement: 'Envelope',
            manifest: true,
        });
        let item = route || this.state.item;
        delete item.ecp_sign;
        delete item.ecp_sign_by_uuid;
        let xml = easyXml.render({
            _xmlns: 'urn:envelope',
            Data: item,
        });
        xml = xml.replace(/\s{2}/g, '');
        return xml;
    }

    async setConfirmStatus() {
        let item = this.state.item;
        item.status_uuid = _.get(_.find(this.state.route_passport_statuses, {label: 'Утверждён'}), 'value');
        this.setState({item});
        await this.edit(item, true);
    }

    async edit(data, stayOnPage = false) {
        this.clearErrors();
        this.startSave();

        const response = await this.updateItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            if (!stayOnPage) {
                this.props.onSubmit(response.payload);
            }
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async ecpSignRequest() {
        await this.setConfirmStatus();
        this.cryptopro = new cadesPluginWrapper(
            this.cadesOnLoad.bind(this, ::this.ecpSign),
            (error) => {
                this.cryptoproError(error);
            }
        );
    }

    async ecpUnsignRequest() {
        this.cryptopro = new cadesPluginWrapper(
            this.cadesOnLoad.bind(this, ::this.ecpUnsign),
            (error) => {
                this.cryptoproError(error);
            }
        );
    }

    async ecpSign() {
        this.setState({isLoading: true});

        const xml = this.getXml();

        this.cryptopro.SignXml(xml, this.cryptoproCertificate.certInstance, async (result) => {
            const start = result.indexOf('<Signature');
            const end = result.indexOf('</Signature>') + '</Signature>'.length;

            const ecp_sign = result.substr(start, end - start);
            let item = this.state.item;
            item.ecp_sign = ecp_sign;
            const response = await this.updateItem(item);
            this.setState({isLoading: false});
            if (response.isOk) {
                alerts.success('Подписано');
                this.verifySign(response.payload);
            } else {
                response.showErrors();
                //alerts.error('Ошибка подписи');
            }
        }, (error) => {
            this.setState({isLoading: false});
            this.cryptoproError(error);
        });
    }

    async ecpUnsign() {
        this.setState({isLoading: true});

        const xml = this.getXml();

        this.cryptopro.SignXml(xml, this.cryptoproCertificate.certInstance, async (result) => {
            const start = result.indexOf('<Signature');
            const end = result.indexOf('</Signature>') + '</Signature>'.length;

            const ecp_sign = result.substr(start, end - start);
            let item = this.state.item;
            item.ecp_sign = 'unsign;' + ecp_sign;
            const response = await this.updateItem(item);
            this.setState({isLoading: false});
            if (response.isOk) {
                alerts.success('Подпись ЭЦП снята');
                this.reload(item.uuid);
            } else {
                response.showErrors();
            }
        }, (error) => {
            this.setState({isLoading: false});
            this.cryptoproError(error);
        });
    }

    cryptoproError(error) {
        if (error === 'Плагин недоступен') {
            error = 'Плагин недоступен. Необходимо установить "КриптоПро".';
        }
        alerts.error('КриптоПро: ' + error);
    }

    cadesOnLoad(callback) {
        this.cryptopro.CheckForPlugIn((version) => {
            this.cryptopro.GetCertificates((list) => {
                if (list.length === 0) {
                    this.cryptoproError('не найден сертификат');
                    return;
                }

                const certificates = _.filter(list, (cert) => {
                    return cert.certInstance.IsValid();
                });

                alerts.askSelect('Выберите сертификат:', certificates.map((cert, index) => {
                    let cn = this.getEcpPart(cert.subjectName, 'CN');
                    while (cn.indexOf('""') !== -1) {
                        cn = cn.replace(/""/g, '"');
                    }
                    const ogrn = this.getEcpPart(cert.subjectName, 'ОГРН');
                    let inn = this.getEcpPart(cert.subjectName, 'ИНН');
                    const isPrivate = (inn && inn.substr(0, 1) !== '0') && !ogrn;
                    inn = inn.replace(/^[0]+/, '')
                    const sn = this.getEcpPart(cert.subjectName, 'SN');
                    const g = this.getEcpPart(cert.subjectName, 'G');
                    let label = cn;
                    if (inn && sn && g && !isPrivate) {
                        label = `${cn} (ИНН ${inn}, ${sn} ${g})`;
                    } else if (inn) {
                        label = `${cn} (ИНН ${inn})`;
                    }

                    return {
                        label,
                        value: index,
                    };
                }), (selectedIndex) => {
                    this.cryptoproCertificate = certificates[selectedIndex];
                    callback();
                }, 'Подписать');
            });
        }, (error) => {
            this.cryptoproError(error);
        })
    }

    getEcpPart(subject, part) {
        const matches = new RegExp(`${part}=([^,]+)(,|$)`).exec(subject);
        return matches ? matches[1] : '';
    }

    isArchived() {
        if (!this.refs.form) {
            return false;
        }

        return this.refs.form.getWrappedInstance().isArchived();
    }

    showEcpSignLog() {
        this.setState({
            ecpSignLogActive: true,
        });
    }

    hideEcpSignLog() {
        this.setState({
            ecpSignLogActive: false,
        });
    }

    render() {
        let title = this.title;

        let form;
        let buttons;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {
            title = this.title;

            if (this.state.item) {
                const customSubtitle = window.RNIS_SETTINGS.CITY_MO ? '(Проект)' : '';
                const ecpStatus = this.state.item.ecp_sign ? '(Утвержден)' : customSubtitle;
                let titleEnd = window.RNIS_SETTINGS.ECPOFF ? '' : ecpStatus;
                title = this.title + ' №' + this.state.item.number + titleEnd;
                form = this.getForm(this.state.item, ::this.edit);

                const statuses = {
                    null: 'Загрузка',
                    true: 'Подписано',
                    false: 'Неверная подпись',
                };
                const statusClassNames = {
                    null: '',
                    true: 'b-icon-link_icon_signed',
                    false: 'b-icon-link_icon_unsigned ',
                };

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            {(this.state.item.ecp_sign && this.state.signed_by) ? (
                                <ContextTooltip key="kiutr-routes.ecp-status" code="kiutr-routes.ecp-status"
                                                default={`${statuses[this.state.signed_status]}: ${this.state.signed_by}`}>
                                    <ModalTopMenuListItem
                                        className={statusClassNames[this.state.signed_status]}
                                        onClick={() => {
                                        }}
                                    />
                                </ContextTooltip>
                            ) : null}
                            {(!this.isArchived() && !window.RNIS_SETTINGS.ECPOFF) ? (
                                (this.state.item.ecp_sign) ? (
                                    <ContextTooltip key="kiutr-routes.ecp-unsign" code="kiutr-routes.ecp-unsign"
                                                    default="Снять подпись ЭЦП">
                                        <ModalTopMenuListItem
                                            className="b-icon-link_icon_sign"
                                            onClick={::this.ecpUnsignRequest}
                                        />
                                    </ContextTooltip>
                                ) : (
                                    <ContextTooltip key="kiutr-routes.ecp-sign" code="kiutr-routes.ecp-sign"
                                                    default="Подписать ЭЦП">
                                        <ModalTopMenuListItem
                                            className="b-icon-link_icon_sign"
                                            onClick={::this.ecpSignRequest}
                                        />
                                    </ContextTooltip>
                                )
                            ) : null}
                            {!window.RNIS_SETTINGS.ECPOFF ?
                                (<ContextTooltip key="kiutr-routes.ecp-sign-log" code="kiutr-routes.ecp-sign-log"
                                                    default="Лог подписания ЭЦП">
                                        <ModalTopMenuListItem
                                            className="b-icon-link_icon_sign"
                                            onClick={::this.showEcpSignLog}
                                        />
                                    </ContextTooltip>)
                             : null}
                            {(!this.isArchived()) ? (
                                <ContextTooltip key="kiutr-routes.clone" code="kiutr-routes.clone"
                                                default="Клонировать">
                                    <ModalTopMenuListItem
                                        className="b-icon-link_icon_clone"
                                        onClick={::this.requestCloneRoute}
                                    />
                                </ContextTooltip>
                            ) : null}
                            {(!this.isArchived()) ? (
                                <ContextTooltip key="kiutr-routes.schedules" code="kiutr-routes.schedules"
                                                default="Варианты расписаний">
                                    <ModalTopMenuListItem
                                        className="b-icon-link_icon_book"
                                        onClick={::this.showSchedules}
                                    />
                                </ContextTooltip>
                            ) : null}
                            {currentUser.can('com.rnis.system.permission.audit', 'read') ? (
                                <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                                default="Журнал аудита">
                                    <ModalTopMenuListItem
                                        className="b-icon-link_params b-icon-link_icon_history"
                                        href={`/system/audit/${this.props.uuid}?class=${this.modelClass}`}
                                    />
                                </ContextTooltip>
                            ) : null}

                            <ContextTooltip key="base-editor.print" code="base-editor.print" default="Печать">
                                <ModalTopMenuListItem
                                    className="b-icon-link_icon_print"
                                    onClick={::this.print}
                                />
                            </ContextTooltip>

                            <ModalTopMenuListSeparator key="separator"/>
                        </ModalTopMenuList>

                        <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                            <ModalTopMenuButton
                                className="_close"
                                onClick={this.props.isPageWithDetect && this.modalConfirmation && this.state.isEditingNow ? this.openCheckModal : ::this.props.onClose}
                            />
                        </ContextTooltip>
                    </ModalTopMenuButtons>
                );
            }
        } else {
            form = this.getForm({}, ::this.create);

            buttons = (
                <ModalTopMenuButtons>
                    <ModalTopMenuButton
                        key="close"
                        className="_close"
                        tooltip="Отменить"
                        onClick={this.props.isPageWithDetect && this.modalConfirmation ? this.openCheckModal : ::this.props.onClose}
                    />
                </ModalTopMenuButtons>
            );
        }

        const className = classNames(`b-modal-${this.props.mode} b-modal-route route-passport-modal`);

        return (
            <div ref={this.setWrapperRef}>
                <Script url="/js/cryptopro/cadesplugin_api.js"/>
                <Script url="/js/cryptopro/cadesplugin_wrapper.js"/>
                <Script url="/js/cryptopro/cadesplugin_wrapper_async.js"/>
                <PageModal
                    header={{title, buttons}}
                    onClose={this.props.onClose}
                    className={className}
                    buttons={[
                        ((this.props.mode === 'edit') && !this.isArchived()) ? (
                            <a key="variants" href="#"
                               className={classNames("b-button b-button_red b-button_size_md b-button_routes", this.state.showVariants ? 'active' : '')}
                               onClick={::this.showVariants}>Варианты
                                движения</a>
                        ) : null,
                        <a key="save" href="#" className="b-button b-button_red b-button_size_md b-button_save"
                           onClick={(this.props.mode === 'edit') ? ::this.onEdit : ::this.onCreate}>Сохранить</a>,
                    ]}
                    //disabled={this.state.showVariants || this.state.showSchedules}
                    //withFade={false}
                >
                    {loader}
                    {form}
                </PageModal>
                {(!this.isArchived() && this.state.showVariants) ? (
                    <Variants
                        {...this.props}
                        routeUuid={this.state.uuid}
                        onClose={::this.closeSubmodals}
                        turnedEditingMode={this.turnedEditingMode}
                    />
                ) : null}
                {(!this.isArchived() && this.state.showSchedules) ? (
                    <KiutrRouteSchedulesComponent
                        {...this.props}
                        routeUuid={this.state.uuid}
                        onClose={::this.closeSubmodals}
                        interval={this.state.showSchedulesInterval}
                    />
                ) : null}
                {(!this.isArchived() && this.state.showSchedulesInterval) ? (
                    <KiutrRouteIntervalSchedulesComponent
                    {...this.props}
                        test={this.state}
                    />
                ) : null}
                {this.state.ecpSignLogActive ? (
                    <EcpSignLog
                        {...this.props}
                        entityUuid={this.state.uuid}
                        onClose={::this.hideEcpSignLog}
                    />
                ) : null}
                {(!this.isArchived() && this.state.cloneFillActive) ? this.renderCloneFillModal() : null}
                {this.state.isWarningModal && this.modalConfirmation ? this.renderWarningModal() : null}
            </div>
        );
    }

    async cloneRoute() {
        this.props.cloneRoute({
            uuid: this.state.item.uuid,
        });

        alerts.alert('Клонирование произведено, результат будет доступен через 2 минуты');
    }

    requestCloneRoute() {
        alerts.askSelect('Выберите действие', [
            {
                value: 'clone',
                label: 'Клонировать маршрут',
            },
            {
                value: 'clone_fill',
                label: 'Перенести наполнение из другого маршрута',
            },
        ], (value) => {
            switch (value) {
                case 'clone':
                    this.cloneRoute();
                    break;
                case 'clone_fill':
                    this.cloneFillShow();
                    break;
            }
        });
    }

    cloneFillShow() {
        this.setState({
            cloneFillActive: true,
        });
    }

    cloneFillHide() {
        this.setState({
            cloneFillActive: false,
        });
    }

    async cloneFillSubmit() {
        const routeUuid = this.state.item.uuid;
        const externalRouteUuid = this.state.external_route_uuid
        if (!externalRouteUuid) {
            alerts.error('Выберите маршрут');
            return;
        }

        this.props.cloneFillRoute({
            route_uuid: routeUuid,
            external_route_uuid: externalRouteUuid,
        });

        alerts.alert('Перенос данных запущен, результат будет доступен в течение 2 минут');
    }

    renderCloneFillModal() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip default="Выполнить перенос">
                    <ModalTopMenuButton
                        className="_save"
                        title="Сохранить"
                        onClick={::this.cloneFillSubmit}
                    />
                </ContextTooltip>

                <ModalTopMenuButtonsSeparator/>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.cloneFillHide}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{title: 'Перенос наполнения из другого маршрута', buttons}}
                className="b-modal-route add-route-schedule-modal b-modal_centered"
                headerClassName="b-modal__header_dark"
            >
                <Block size="xl" title="Маршрут-источник данных">
                    <SelectAsync
                        value={this.state.external_route_uuid}
                        onChange={(e) => {
                            const value = e ? e.value : null;
                            this.setState({
                                external_route_uuid: value,
                            });
                        }}
                        loadOptions={::this.loadRoutes}
                    />
                </Block>
            </PageModal>
        );
    }

    async loadRoutes(input, callback) {
        if (!input) {
            callback(null, {
                options: [],
                complete: false,
            });
            return;
        }
        const result = await this.props.getRoutes({
            filters: {
                withComponent: this.props.params.component,
            },
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
        });

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(item => ({
                    label: `${item.number} ${item.title}`,
                    value: item.uuid,
                })), 'label'),
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    getForm(item, onSubmit) {
        if (_.isEmpty(item)) {
            item = {
                composed_at: moment().format(formats.DATE_API),
                work_from: moment().format(formats.DATE_API),
            };
        }

        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                turnedEditingMode={this.turnedEditingMode}
            />
        );
    }

    showVariants(e) {
        e.preventDefault();

        if (this.state.showVariants) {
            this.closeSubmodals();
        } else {
            this.props.router.push(`/${this.props.params.component}/routes/${this.props.uuid}/variants`);
        }
    }

    closeSubmodals() {
        this.props.router.push(`/${this.props.params.component}/routes/${this.props.uuid}`);
    }

    showSchedules() {
        this.props.router.push(`/${this.props.params.component}/routes/${this.props.uuid}/schedules`);
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    turnedEditingMode: PropTypes.func
})

@connect((state) => ({}), {getUnits}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        route: {},
        route_passport_statuses: [],
        route_types: [],
        transport_connection_types: [],
        route_kinds: [],
        units: [],
        communal_municipalities: [],
    };

    getData() {
        return this.state.route;
    }

    onChangeInput(field, {target: {value, type}}) {
        super.onChangeInput(field, {target: {value, type}});
        this.props.turnedEditingMode();
    }

    async componentDidMount() {
        let data = this.props.data;
        if (_.isUndefined(data.is_public)) {
            data.is_public = true;
        }
        await this.setState({
            route: data,
        });

        this.loadDictionaries([
            'route_passport_statuses',
            'route_types',
            'transport_connection_types',
            'route_kinds',
        ]).then(() => {
            if (!this.get('status_uuid')) {
                this.setValue('route.status_uuid', _.get(_.find(this.state.route_passport_statuses, {label: 'Утверждён'}), 'value'));
            }
            if (!this.get('transport_connection_type_uuid')) {
                this.setValue('route.transport_connection_type_uuid', _.get(_.find(this.state.transport_connection_types, {label: 'Пригородный'}), 'value'));
            }
            if (!this.get('route_type_uuid')) {
                this.setValue('route.route_type_uuid', _.get(_.find(this.state.route_types, {label: 'Маятниковый'}), 'value'));
            }
        });
        this.loadUnits().then(() => {
            if (!this.get('unit_uuid')) {
                this.setValue('route.unit_uuid', _.get(_.find(this.state.units, {label: '1 МТДИ'}), 'value'));
            }
        });

        if (window.RNIS_SETTINGS.grantMO) {
           // this.loadDictionaries(['communal_municipalities'], 'operator');
            this.loadMunicipalities();
        }

    }

    async loadUnits() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withComponent: component_mapper(this.props.params.component),
            },
        });
        if (response.isOk) {
            const units = _.map(response.payload.items, (item) => ({
                label: item.name,
                value: item.uuid,
            }));
            this.setState({units});
        } else {
            response.showErrors();
        }
    }

    async loadMunicipalities() {
        const response = await this.props.listUserGeoObjectsMunicipalitiesList({
           /* pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withComponent: component_mapper(this.props.params.component),
            },*/
        });
        if (response.isOk) {
            const communal_municipalities = _.map(response.payload.municipalities, (item) => ({
                label: item.communal_municipality_name,
                value: item.communal_municipality_uuid,
            }));
            this.setState({communal_municipalities});
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.route, path, defaultValue);
    }

    async setValue(field, value) {
        if (field === 'route.number') {
            value = value.substr(0, 20);
        }

        return super.setValue(field, value);
    }

    isArchived() {
        return _.get(_.find(this.state.route_passport_statuses, {value: this.get('status_uuid')}), 'label') === 'Архивный';
    }

    render() {
        return (
            <div className="b-modal__block">
                <Block title="№ маршрута">
                    {this.textInput('route.number', {
                        disabled: this.isArchived(),
                    })}
                </Block>
                <Block title="Рег. № маршрута">
                    {this.textInput('route.registration_number', {
                        disabled: this.isArchived(),
                    })}
                </Block>
                <Block size="xl" title="Наименование">
                    {this.textInput('route.title', {
                        disabled: this.isArchived(),
                    })}
                </Block>
                <Block title="Дата составления">
                    {this.datepicker('route.composed_at', {
                        className: 'centered',
                        disabled: this.isArchived(),
                    })}
                </Block>
                <Block title="Статус">
                    {this.select('route.status_uuid', _.filter(this.state.route_passport_statuses, ({label, value}) => {
                        /*if (label === 'Утверждён') {
                            return this.get('status_uuid') === value;
                        }*/
                        return true;
                    }), {
                        disabled : isCarriersLimits() && isMunicipalType(this.state.route_kinds, this.state.route.route_kind_uuid)
                    })}
                </Block>
                {(component_mapper(this.props.params.component) === 'kiutr') ? (
                    <Block title="Тип маршрута">
                        {this.select('route.route_type_uuid', this.state.route_types, {
                            disabled: this.isArchived(),
                        })}
                    </Block>
                ) : null}
                {(component_mapper(this.props.params.component) === 'kiutr') ? (
                    <Block title="Вид сообщения">
                        {this.select('route.transport_connection_type_uuid', this.state.transport_connection_types, {
                            disabled: this.isArchived(),
                        })}
                    </Block>
                ) : null}
                <Block title="Вид маршрута">
                    {this.select('route.route_kind_uuid', this.state.route_kinds, {
                        disabled: this.isArchived(),
                    })}
                </Block>
                <Block
                    title={(component_mapper(this.props.params.component) === 'kiutr') ? 'Организатор перевозок' : 'Организация фрахтователь'}>
                    {this.select('route.unit_uuid', this.state.units, {
                        disabled: this.isArchived(),
                    })}
                </Block>
                {window.RNIS_SETTINGS.grantMO ? (
                    <Block title="Муниципальное образование">
                        {this.select('route.communal_municipality_uuid', this.state.communal_municipalities)}
                    </Block>
                ) : null}
                {
                    window.RNIS_SETTINGS.CITY_TULA ? (
                        <Block title="Дата ввода в действие">
                            {this.datepicker('route.work_from', {
                                className: 'centered',
                                disabled: this.isArchived(),
                            })}
                        </Block>
                    ) : null
                }
                <Block title="Образ документа">
                    {this.get('files', []).map((file, index) => ([
                        <a key={`open:${index}`} href={file} target="_blank">Открыть</a>,
                        <br key={`separator:${index}`}/>,
                        (!this.isArchived()) ? (
                            <a key={`delete:${index}`} href="#" onClick={this.deleteFile.bind(this, index)}>Удалить</a>
                        ) : null,
                        <br key={`separator2:${index}`}/>,
                        <br key={`separator3:${index}`}/>,
                    ]))}
                    {(!this.isArchived()) ? (
                        <FileReaderInput as="binary" id="my-file-input" onChange={::this.uploadFile}>
                            <a href="#">Загрузить файл</a>
                        </FileReaderInput>
                    ) : null}
                </Block>
                <Block>
                    {(!this.isArchived()) ? this.checkbox('route.is_public', 'Публичный маршрут') : (this.get('is_public') ? 'Публичный маршрут' : 'Непубличный маршрут')}
                </Block>
            </div>
        );
    }

    deleteFile(index, e) {
        e && e.preventDefault();

        let files = this.get('files', []);
        files.splice(index, 1);
        this.setValue('route.files', files);
    }

    async uploadFile(e, results) {
        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        results.forEach(result => {
            const [e, file] = result;
            //formData.append('file', e.target.result);
            formData.append('file', file);
        });

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');

        let files = this.get('files', []);
        files.push(value);
        this.setValue('route.files', files);
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }
}

/*
 Editor.childContextTypes = {
 resizeModals: PropTypes.func
 };*/
