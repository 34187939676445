import React, { Component } from 'react';
import moment from "moment";

import { computeTimeZoneOffset } from "helpers/regularity_matrix";

import "./style.less"

export default class RegularityMatrixStats extends Component {

    convertMetresToKilometers(value) {
        return (value / 1000).toFixed(0);
    }

    convertMinutesToHours(value) {
        return (value / 60).toFixed(0);
    }

    computeTimeZoneOffset() {
        if (window.RNIS_SETTINGS.TIMEZONE_OFFSET) {
            return window.RNIS_SETTINGS.TIMEZONE_OFFSET
        } else {
            return parseInt((moment().utcOffset()) / 60);
        }
    }

    render() {
        const { date, number, carriername, orderinfo, orderstats, routevariantsList } = this.props.order;
        const forwardLength = routevariantsList.filter(item => item.isforwarddirection === true)[0].length;
        const backwardLength = routevariantsList.filter(item => item.isforwarddirection === false)[0].length;
        return (
            <div className="regularityStats">
                <div className="regularityStats__header">
                    <div className="regularityStats__routeDate">
                        <div className="regularityStats__statsTextWrapper">
                            <p className="regularityStats-subtitle">{carriername}</p>
                            <div className="regularityStats__block">
                                <p className="regularityStats-text" title="Номер план наряда">План наряда №</p>
                                <p className="regularityStats-value">{number}</p>
                                <div className="debug debug-circle">
                                    <span className="debug-info">Order.Number</span>
                                </div>
                            </div>

                            <div className="regularityStats__block regularityStats__block--marginBottom">
                                <p className="regularityStats-text" title="Регистрационнйы номер маршрута">Регистрационный №</p>
                                <p className="regularityStats-value">{orderinfo.routeregnumber}</p>
                                <div className="debug debug-circle">
                                    <span className="debug-info">OrderInfo.RouteRegNumber</span>
                                </div>
                            </div>

                            <div className="regularityStats__block">
                                <p className="regularityStats-text">Дата</p>
                                <p className="regularityStats-value">{moment.unix(date).format("DD.MM.YYYY")}</p>
                            </div>

                            <div className="regularityStats__block">
                                <p className="regularityStats-text" title="Номер маршрута">Маршрут №</p>
                                <p className="regularityStats-value">{orderinfo.routenumber}</p>
                                <div className="debug debug-circle">
                                    <span className="debug-info">OrderInfo.RouteNumber</span>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="regularityStats__routeInfo">
                        <div className="regularityStats__statsTextWrapper">
                            <p className="regularityStats-subtitle">Информация о маршруте:</p>
                            <div className="regularityStats__block">
                                <p className="regularityStats-text">длина прямого рейса:</p>
                                <p className="orderinfo_routeforwardlength regularityStats-value">{this.convertMetresToKilometers(forwardLength)} км</p>
                                <div className="debug debug-circle">
                                    <span className="debug-info">RouteVariant.Length(IsForwardDirection=true)</span>
                                </div>
                            </div>

                            <div className="regularityStats__block">
                                <p className="regularityStats-text">длина обратного рейса:</p>
                                <p className="orderinfo_routebackwardlength regularityStats-value">{this.convertMetresToKilometers(backwardLength)} км</p>
                                <div className="debug debug-circle">
                                    <span className="debug-info">RouteVariant.Length(IsForwardDirection=false)</span>
                                </div>
                            </div>

                            <div className="regularityStats__block">
                                <p className="regularityStats-text">минимально кол-во ТС:</p>
                                <p className="orderinfo_minvehiclecount regularityStats-value">{orderinfo.minvehiclecount}</p>
                                <div className="debug debug-circle">
                                    <span className="debug-info">OrderInfo.MinVehicleCount</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="regularityStats__statsInfo">
                        <div className="regularityStats__statsTextWrapper">
                            <p className="regularityStats-subtitle">Статистика по маршруту:</p>
                            <div className="regularityStats__block">
                                <p className="regularityStats-text">количество ТС на линии</p>
                                <p className="orderstats_vehiclecount regularityStats-value">{orderstats.vehiclecount}</p>
                                <div className="debug debug-circle">
                                    <span className="debug-info">OrderStats.VehicleCount</span>
                                </div>
                            </div>

                            <div className="regularityStats__block">
                                <p className="regularityStats-text">количество выполненных рейсов</p>
                                <p className="orderstats_completedtrackcount regularityStats-value">{orderstats.completedtrackcount}</p>
                                <div className="debug debug-circle">
                                    <span className="debug-info">OrderStats.CompletedTrackCount</span>
                                </div>
                            </div>

                            <div className="regularityStats__block">
                                <p className="regularityStats-text">количество регулярных рейсов</p>
                                <p className="orderstats_regulartrackcount regularityStats-value">{orderstats.regulartrackcount}</p>
                                <div className="debug debug-circle">
                                    <span className="debug-info">OrderStats.RegularTrackCount</span>
                                </div>
                            </div>

                            <div className="regularityStats__block">
                                <p className="regularityStats-text">количество посещений остановок</p>
                                <p className="orderstats_stoppointregularity regularityStats-value">{orderstats.stoppointregularity.toFixed(0)}</p>
                                <div className="debug debug-circle">
                                    <span className="debug-info">OrderStats.StopPointRegularity</span>
                                </div>
                            </div>

                            <div className="regularityStats__block">
                                <p className="regularityStats-text">количество регулярных посещений остановок</p>
                                <p className="orderstats_regularstoppointvisitcount regularityStats-value">{orderstats.regularstoppointvisitcount}</p>
                                <div className="debug debug-circle">
                                    <span className="debug-info">OrderStats.RegularStopPointVisitCount</span>
                                </div>
                            </div>

                            <div className="regularityStats__block">
                                <p className="regularityStats-text">количество отработанных часов</p>
                                <p className="orderstats_totalworktime regularityStats-value">{this.convertMinutesToHours(orderstats.totalworktime)}</p>
                                <div className="debug debug-circle">
                                    <span className="debug-info">OrderStats.TotalWorkTime</span>
                                </div>
                            </div>

                            <div className="regularityStats__block">
                                <p className="regularityStats-text">пробег на линии</p>
                                <p className="orderstats_totallength regularityStats-value">{this.convertMetresToKilometers(orderstats.totallength)}</p>
                                <div className="debug debug-circle">
                                    <span className="debug-info">OrderStats.TotalLength</span>
                                </div>
                            </div>

                            <div className="regularityStats__block">
                            <p className="regularityStats-text">часовой пояс</p>
                            <p className="regularityStats-value">{computeTimeZoneOffset()}</p>
                            </div>
                        </div>
                    </div>
                    <div className="regularityStats__statsInfoBottom">
                        <div className="orderstats_routeregularity regularityStats__statsInfoBottom__item regularityStats__statsInfoBottom__item_red">
                            Регулярность рейсов<br />
                            <span className="regularityStats__percent">{orderstats.routeregularity.toFixed(1)} %</span>
                            <div className="debug">
                                    <span className="debug-info">OrderStats.RouteRegularity</span>
                            </div>
                        </div>
                        <div className="orderstats_stoppointregularity regularityStats__statsInfoBottom__item regularityStats__statsInfoBottom__item_red">
                            Поостановочная регулярность<br />
                            <span className="regularityStats__percent">{orderstats.stoppointregularity.toFixed(1)} %</span>
                            <div className="debug">
                                    <span className="debug-info">OrderStats.StopPointRegularity</span>
                            </div>
                        </div>
                        <div className="orderstats_routeprovisionindex regularityStats__statsInfoBottom__item regularityStats__statsInfoBottom__item_yellow">
                            Индекс обеспечения<br />
                            <span className="regularityStats__percent">{orderstats.routeprovisionindex.toFixed(1)} %</span>
                            <div className="debug">
                                    <span className="debug-info">OrderStats.RouteProvisionIndex</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}