export const getPointsFromGrpc = (grpcObject) => {
	return [
		grpcObject.position.latitude,
		grpcObject.position.longitude,
		'point',
		grpcObject.devicecode,
		grpcObject.position.speed,
		grpcObject.position.course,
		'bus',
		grpcObject.devicetime,
	];
};
