import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {get, map} from 'lodash';
import _ from 'lodash';
import {User} from "helpers/user";
import moment from "moment";
import formats from "dictionaries/formats";
import {events} from "dom-helpers";

@propTypes({
    order: PropTypes.object,
    unit: PropTypes.object,
    vehicles: PropTypes.array,
    drivers: PropTypes.array,
    vehicle_models: PropTypes.array,
    vehicle_marks: PropTypes.array,
})

export default class OrderPrintComponent extends Component {

    months = {
        1: 'января',
        2: 'февраля',
        3: 'марта',
        4: 'апреля',
        5: 'мая',
        6: 'июня',
        7: 'июля',
        8: 'августа',
        9: 'сентября',
        10: 'октября',
        11: 'ноября',
        12: 'декабря',
    };

    render() {
        return (
            <body className="page print">
            {_.map(this.props.vehicles, (vehicle) => {
                return this.renderOrder(vehicle);
            })}
            <style dangerouslySetInnerHTML={{__html: this.getStyle()}}/>
            </body>
        );
    }

    renderDriver(index) {
        const {order, drivers} = this.props;
        const driver = _.get(order.shifts[index], 'driver_uuid');

        return (
            <tr key={`driver:${index}`}>
                <td className="width-50 _no-border">Водителя</td>
                <td>
                    <div className="input">{driver ? (new User(drivers[driver]).getFullName(true)) : null}</div>
                </td>
                <td>
                    <div
                        className="input center">{driver ? _.get(drivers[driver], 'info.personnel_number') : null}</div>
                </td>
                <td>
                    <div
                        className="input center">{driver ? `${_.get(drivers[driver], 'info.driver_serial', '')} ${_.get(drivers[driver], 'info.driver_number', '')}` : null}</div>
                </td>
                <td/>
            </tr>
        );
    }

    renderCheckTaker(index) {
        const {order, drivers} = this.props;
        const checkTaker = _.get(order.shifts[index], 'check_taker_uuid');

        return (
            <tr key={`check:taker:${index}`}>
                <td className="width-50 _no-border">Кондуктора</td>
                <td>
                    <div className="input">{checkTaker ? (new User(drivers[checkTaker]).getFullName(true)) : null}</div>
                </td>
                <td>
                    <div
                        className="input center">{checkTaker ? _.get(drivers[checkTaker], 'info.personnel_number') : null}</div>
                </td>
                <td>
                    <div className="input center"/>
                </td>
                <td/>
            </tr>
        );
    }

    runsCalc(order) {
        return order.shifts.map(shift => {
            return shift.runs.filter(run => run.type !== 'parking' || run.type === 'refill' || run.type === 'switch_out' || run.type === 'switch_in')
        }).flat();
    }

    getPointTitle(run) {
        if (!run.points) return;
        switch (run.type) {
            case 'techno':
                return 'Технологический';
            case 'dinner':
                return 'Обед';
            case 'parking':
                return 'Стоянка';
            case 'settling':
                return 'Отстой';
            case 'reshift':
                return 'Пересменка';
            case 'null':
                return 'Нулевой';
            default:
                return `${run.points.length ? _.get(window._stop_points, _.first(run.points.filter(point => point.point_type === 'stop_point')).type_uuid) : ''} -
                 ${ run.points.length ? _.get(window._stop_points, _.last(run.points.filter(point => point.point_type === 'stop_point')).type_uuid) : ''}`
        }
    }

    renderPrintRow(order) {
        let runs = this.runsCalc(order);
        if (runs.length < 40) {
            let i = 40 - runs.length;
            while (i--) {
                runs.push({})
            }
        }
        return runs.map(run => {
            return <tr key={run.uuid}>
                <td>
                    <div className="input"
                         style={{'fontSize': '7px', 'lineHeight': '7px'}}>{this.getPointTitle(run)}</div>
                </td>
                <td>
                    <div
                        className="input">{run.date_from ? moment(run.date_from).format(formats.TIME) : null}</div>
                </td>
                <td>
                    <div className="input"/>
                </td>
                <td/>
                <td>
                    <div
                        className="input">{run.date_to ? moment(run.date_to).format(formats.TIME) : null}</div>
                </td>
                <td>
                    <div className="input"/>
                </td>
                <td>
                    <div className="input">{run.type && run.type !== 'null' ? run.distance / 1000 : null}</div>
                </td>
                <td>
                    <div className="input">{run.type && run.type === 'null' ? run.distance / 1000 : null}</div>
                </td>
                <td/>
                <td>
                    <div className="input"/>
                </td>
                <td>
                    <div className="input"/>
                </td>
                <td>
                    <div className="input"/>
                </td>
                <td/>
                <td>
                    <div className="input"/>
                </td>
                <td>
                    <div className="input"/>
                </td>
                <td>
                    <div className="input"/>
                </td>
                <td>
                    <div className="input"/>
                </td>
                <td/>
            </tr>
        })


    }

    productionRunLength() {
        const {order} = this.props;

        const runs = _.flatten(_.map(order.shifts, 'runs'));

        return _.sumBy(_.filter(runs, (run) => {
            return (run.type === 'production_forward') || (run.type === 'production_reverse');
        }), 'distance') / 1000;
    }

    nullRunLength() {
        const {order} = this.props;

        const runs = _.flatten(_.map(order.shifts, 'runs'));

        return _.sumBy(_.filter(runs, (run) => {
            return run.type === 'null';
        }), 'distance') / 1000;
    }

    getShift(vehicle, shiftNumber) {
        const {order} = this.props;

        const shift = _.get(order.shifts, shiftNumber);
        if (shift && _.filter(shift.runs, {vehicle_uuid: vehicle.uuid}).length > 0) {
            return shift;
        }

        return null;
    }

    renderOrder(vehicle) {
        const {unit, order, drivers} = this.props;

        const runs = _.flatten(_.map(order.shifts, 'runs'));
        const vehicleRuns = _.filter(runs, {vehicle_uuid: vehicle.uuid});

        const firstDriver = _.first(vehicleRuns).driver_uuid;
        const lastDriver = _.last(vehicleRuns).driver_uuid;

        const firstShift = this.getShift(vehicle, 0);
        const secondShift = this.getShift(vehicle, 1);

        return (
            <div>
                <div className="page-wrap clearAfter">
                    <div className="page-block _left">
                        <div className="stamp _top center">Место для штампа<br/>организации</div>
                        <div className="company">
                            <div className="caption">Организация</div>
                            <div className="input">{unit.name}
                                <span className="input__hint">наименование</span>
                                <span className="line1"/>
                                <span className="line2"/>
                            </div>
                        </div>
                        <div className="okpo">
                            <div className="caption">Код по ОКПО</div>
                            <div className="input _bordered center width-60"/>
                        </div>
                        <div className="address">
                            <div className="input">{unit.legal_address}
                                <span className="input__hint _centered">адрес</span>
                                <span className="line1"/>
                                <span className="line2"/>
                            </div>
                        </div>
                        <div className="phone">
                            <div className="input center">{unit.phone}
                                <span className="input__hint _centered">номер телефона</span>
                                <span className="line1"/>
                            </div>
                        </div>
                        <div className="state">Автобус технически исправен<br/>Выезд разрешен</div>
                        <div className="person">
                            <div className="person__caption bold italic">Механик</div>
                            <div className="input signature">
                                <span className="input__hint _centered">подпись</span>
                                <span className="line1"/>
                            </div>
                            <div className="input fullname center">
                                <span className="input__hint _centered text-normal">расшифровка подписи</span>
                                <span className="line1"/>
                            </div>
                        </div>
                        <div className="condition center">Автобус, кассы и переговорное устройство в исправном
                            состоянии,
                            указатели установлены
                        </div>
                        <div className="person">
                            <div className="person__caption">Принял<span className="bold italic">водитель</span></div>
                            <div className="input signature">
                                <span className="input__hint _centered">подпись</span>
                                <span className="line1"/>
                            </div>
                            <div className="input fullname center">{(new User(drivers[firstDriver])).getFullName()}
                                <span className="input__hint _centered text-normal">расшифровка подписи</span>
                                <span className="line1"/>
                            </div>
                        </div>
                        <div className="mark center">Отметка о сдаче автобуса при<br/>смене водителя
                            <div className="input">
                                <span className="line1"/>
                                <span className="line2"/>
                                <span className="line3"/>
                            </div>
                        </div>
                        <div className="pass-accept">
                            <div className="pass">
                                <div className="caption">Сдал</div>
                                <div className="input signature width-70">
                                    <span className="input__hint _centered">подпись</span>
                                    <span className="line1"/>
                                </div>
                            </div>
                            <div className="accept">
                                <div className="caption">Принял</div>
                                <div className="input signature width-70">
                                    <span className="input__hint _centered">подпись</span>
                                    <span className="line1"/>
                                </div>
                            </div>
                        </div>

                        <div className="mark">Отметка о приеме автобуса при<br/>возвращении
                            <div className="input">
                                <span className="line1"/>
                                <span className="line2"/>
                                <span className="line3"/>
                            </div>
                        </div>
                        <div className="person">
                            <div className="person__caption">Сдал<span className="bold italic">водитель</span></div>
                            <div className="input signature">
                                <span className="input__hint _centered">подпись</span>
                                <span className="line1"/>
                            </div>
                            <div className="input fullname center">{(new User(drivers[lastDriver])).getFullName()}
                                <span className="input__hint _centered text-normal">расшифровка подписи</span>
                                <span className="line1"/>
                            </div>
                        </div>
                        <div className="person">
                            <div className="person__caption">Принял<span className="bold italic">механик</span></div>
                            <div className="input signature">
                                <span className="input__hint _centered">подпись</span>
                                <span className="line1"/>
                            </div>
                            <div className="input fullname center">
                                <span className="input__hint _centered text-normal">расшифровка подписи</span>
                                <span className="line1"/>
                            </div>
                        </div>
                        <div className="table">
                            <table>
                                <thead>
                                <tr>
                                    <th colSpan="2" className="center">Показание спидометра</th>
                                    <th className="center width-50">Подпись</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>При возвращении</td>
                                    <td className="width-40"/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td>При выезде</td>
                                    <td className="width-40"/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td>Результат</td>
                                    <td className="width-40"/>
                                    <td/>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table">
                            <div className="table__caption">Отметка о состоянии здоровья водителя</div>
                            <table>
                                <thead>
                                <tr>
                                    <th/>
                                    <th className="center width-30">I<br/>смена</th>
                                    <th className="center width-30">II<br/>смена</th>
                                    <th className="center width-50">Подпись</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>При выезде</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td>При возвращении</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="stamp _bottom center">М.П.<br/>или штампа</div>
                    </div>

                    <div className="page-block _center">
                        <div className="title">
                            <div className="caption bold">Путевой лист автобуса</div>
                            <div className="series width-100">
                                <div className="input center">{moment(order.date).format('YYYY')}
                                    <div className="input__hint _centered">серия</div>
                                    <span className="line1"/>
                                </div>
                            </div>
                            <div className="number">
                                <div className="caption bold">N</div>
                                <div className="input center width-100">{order.number}
                                    <span className="line1"/>
                                </div>
                            </div>
                            <div className="date">
                                <div className="date__num">"
                                    <div className="input center">{moment(order.date).format('DD')}</div>
                                    "
                                </div>
                                <div className="date__month">
                                    <div
                                        className="input center width-100">{this.months[_.toInteger(moment(this.props.order.date).format('MM'))]}
                                        <span className="line1"/>
                                    </div>
                                </div>
                                <div className="date__year">
                                    <div
                                        className="input center width-40">{moment(this.props.order.date).format('YYYY')}</div>
                                    <span>г.</span>
                                </div>
                            </div>
                        </div>
                        <div className="transport-info">
                            <div className="mark">
                                <div className="caption">Марка автобуса</div>
                                <div className="input">{this.props.vehicle_models[vehicle.vehicle_model_uuid]}
                                    <span className="line1"/>
                                </div>
                            </div>
                            <div className="state-number">
                                <div className="caption">Государственный номерной знак</div>
                                <div className="input">{vehicle.state_number}
                                    <span className="line1"/>
                                </div>
                            </div>
                            <div className="garage-number">
                                <div className="caption">Гаражный номер</div>
                                <div className="cells">
                                    <div className="cell"/>
                                    <div className="cell">
                                        <div className="input">{vehicle.garage_number}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="table">
                            <table>
                                <thead>
                                <tr>
                                    <th rowSpan="2" colSpan="2" className="center">Фамилия, имя, отчество</th>
                                    <th rowSpan="2" className="center width-60">Табельный<br/>номер</th>
                                    <th colSpan="2" className="center">Номер</th>
                                </tr>
                                <tr>
                                    <th className="center width-70">удостоверения</th>
                                    <th className="center width-70">билето-<br/>учетного<br/>листа</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.renderDriver(0)}
                                {this.renderDriver(1)}
                                {this.renderDriver(2)}
                                {this.renderDriver(3)}
                                {this.renderCheckTaker(0)}
                                {this.renderCheckTaker(1)}
                                </tbody>
                            </table>
                        </div>

                        <div className="route">
                            <div className="route-name">
                                <div className="caption">Наименование, номер маршрута</div>
                                <div className="input center">{order.route_name}
                                    <span className="line1"/>
                                </div>
                                <div className="cells">
                                    <div className="cell">
                                        <div className="input center">{order.route_number}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="route-card">
                                <div className="caption">Лицензионная карточка</div>
                                <div className="input center">стандартная, ограниченная
                                    <div className="input__hint _centered">ненужное зачеркнуть</div>
                                    <span className="line1"/>
                                </div>
                            </div>
                            <div className="route-register">
                                <div className="caption">Регистрационный №</div>
                                <div className="input center width-80">
                                    <span className="line1"/>
                                </div>
                            </div>
                            <div className="route-series">
                                <div className="caption">Серия</div>
                                <div className="input center width-60">
                                    <span className="line1"/>
                                </div>
                            </div>
                            <div className="route-number">
                                <div className="caption">N</div>
                                <div className="input center width-100">
                                    <span className="line1"/>
                                </div>
                            </div>
                        </div>
                        <div className="order">
                            <div className="caption">Подача по заказу</div>
                            <div className="order-block">
                                <div className="order-customer">
                                    <div className="caption">Заказчик</div>
                                    <div className="input center">
                                        <span className="line1"/>
                                    </div>
                                </div>
                                <div className="order-place">
                                    <div className="caption">место подачи</div>
                                    <div className="input center">
                                        <span className="line1"/>
                                    </div>
                                </div>
                                <div className="order-time">
                                    <div className="time time-from">с
                                        <div className="input center">
                                            <span className="line1"/>
                                        </div>
                                        ч
                                        <div className="input center">
                                            <span className="line1"/>
                                        </div>
                                        мин.
                                    </div>
                                    <div className="time time-till">до
                                        <div className="input center">
                                            <span className="line1"/>
                                        </div>
                                        ч
                                        <div className="input center">
                                            <span className="line1"/>
                                        </div>
                                        мин.
                                    </div>
                                </div>
                            </div>
                            <div className="order-block">
                                <div className="order-customer">
                                    <div className="caption">Заказчик</div>
                                    <div className="input center">
                                        <span className="line1"/>
                                    </div>
                                </div>
                                <div className="order-place">
                                    <div className="caption">место подачи</div>
                                    <div className="input center">
                                        <span className="line1"/>
                                    </div>
                                </div>
                                <div className="order-time">
                                    <div className="time time-from">с
                                        <div className="input center">
                                            <span className="line1"/>
                                        </div>
                                        ч
                                        <div className="input center">
                                            <span className="line1"/>
                                        </div>
                                        мин.
                                    </div>
                                    <div className="time time-till">до
                                        <div className="input center">
                                            <span className="line1"/>
                                        </div>
                                        ч
                                        <div className="input center">
                                            <span className="line1"/>
                                        </div>
                                        мин.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table">
                            <div className="block-caption bold center">Выезд и возвращение автобуса</div>
                            <table>
                                <thead>
                                <tr>
                                    <th rowSpan="2" className="center">Смена</th>
                                    <th colSpan="2" className="center">По расписанию</th>
                                    <th colSpan="2" className="center">Фактически</th>
                                </tr>
                                <tr>
                                    <th className="center width-80">выезд</th>
                                    <th className="center width-80">возвращение</th>
                                    <th className="center width-80">выезд</th>
                                    <th className="center width-80">возвращение</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Первая</td>
                                    <td>
                                        <div
                                            className="input center">{firstShift ? moment(_.first(_.filter(firstShift.runs, {vehicle_uuid: vehicle.uuid})).date_from).format(formats.TIME) : null}</div>
                                    </td>
                                    <td>
                                        <div
                                            className="input center">{firstShift ? moment(_.last(_.filter(firstShift.runs, {vehicle_uuid: vehicle.uuid})).date_to).format(formats.TIME) : null}</div>
                                    </td>
                                    <td>
                                        <div className="input center"/>
                                    </td>
                                    <td>
                                        <div className="input center"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Втовая</td>
                                    <td>
                                        <div
                                            className="input center">{secondShift ? moment(_.first(_.filter(secondShift.runs, {vehicle_uuid: vehicle.uuid})).date_from).format(formats.TIME) : null}</div>
                                    </td>
                                    <td>
                                        <div
                                            className="input center">{secondShift ? moment(_.last(_.filter(secondShift.runs, {vehicle_uuid: vehicle.uuid})).date_to).format(formats.TIME) : null}</div>
                                    </td>
                                    <td>
                                        <div className="input center"/>
                                    </td>
                                    <td>
                                        <div className="input center"/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="person person-dispatcher">
                            <div className="person__caption">Диспетчер</div>
                            <div className="input signature">
                                <span className="input__hint _centered">подпись</span>
                                <span className="line1"/>
                            </div>
                            <div className="input fullname center">
                                <span className="input__hint _centered text-normal">расшифровка подписи</span>
                                <span className="line1"/>
                            </div>
                        </div>
                        <div className="downtime">
                            <div className="block-caption bold center">Простой по техническим причинам</div>
                            <div className="downtime-garage">
                                <div className="caption">В гараже:</div>
                                <div className="downtime-block">
                                    <div className="downtime-time">
                                        <div className="time time-from">с
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            ч
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            мин.
                                        </div>
                                        <div className="time time-till">до
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            ч
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            мин.
                                        </div>
                                    </div>
                                    <div className="downtime-reason">
                                        <div className="caption">Причина</div>
                                        <div className="input center">
                                            <span className="line1"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="downtime-block">
                                    <div className="downtime-time">
                                        <div className="time time-from">с
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            ч
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            мин.
                                        </div>
                                        <div className="time time-till">до
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            ч
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            мин.
                                        </div>
                                    </div>
                                    <div className="downtime-reason">
                                        <div className="caption">Причина</div>
                                        <div className="input center">
                                            <span className="line1"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="downtime-line">
                                <div className="caption">На линии:</div>
                                <div className="downtime-block">
                                    <div className="downtime-time">
                                        <div className="time time-from">с
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            ч
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            мин.
                                        </div>
                                        <div className="time time-till">до
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            ч
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            мин.
                                        </div>
                                    </div>
                                    <div className="downtime-reason">
                                        <div className="caption">Причина</div>
                                        <div className="input center">
                                            <span className="line1"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="downtime-block">
                                    <div className="downtime-time">
                                        <div className="time time-from">с
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            ч
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            мин.
                                        </div>
                                        <div className="time time-till">до
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            ч
                                            <div className="input center">
                                                <span className="line1"/>
                                            </div>
                                            мин.
                                        </div>
                                    </div>
                                    <div className="downtime-reason">
                                        <div className="caption">Причина</div>
                                        <div className="input center">
                                            <span className="line1"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="controller">
                            <div className="caption">Отметка линейного контролера</div>
                            <div className="input center">
                                <div className="input__hint _centered">Обслуживание в пути</div>
                                <span className="line1"/>
                            </div>
                        </div>
                    </div>

                    <div className="page-block _right">
                        <div className="form">
                            <div className="form-info">Типовая межотраслевая форма №6
                                <br/>Утверждена постановлением Госкомстата
                                <br/>России от 28.11.97 № 78
                            </div>
                            <div className="form-code">
                                <div className="caption">Код формы по ОКУД</div>
                                <div className="cells">
                                    <div className="cell">Коды</div>
                                    <div className="cell">0345006</div>
                                </div>
                            </div>
                        </div>
                        <div className="table">
                            <div className="block-caption bold center">Выдача топлива (горючего)</div>
                            <table className="_no-border">
                                <thead>
                                <tr>
                                    <th className="center _no-border-right _no-border-bottom"/>
                                    <th className="center _no-border-right width-60">Л</th>
                                    <th className="center _no-border-right width-60">Подпись</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className="height-20">
                                    <td className="_no-border-bottom align-bottom">Замер остатка при
                                        выезде.................................................
                                    </td>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr className="height-20">
                                    <td className="_no-border-bottom align-bottom">
                                        Выдано................................................................................
                                    </td>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr className="height-20">
                                    <td className="_no-border-bottom align-bottom">Замер при смене
                                        водителя................................................
                                    </td>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr className="height-20">
                                    <td className="_no-border-bottom align-bottom">
                                        Выдано................................................................................
                                    </td>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr className="height-20">
                                    <td className="_no-border-bottom align-bottom">Замер остатка при
                                        возвращении.......................................
                                    </td>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr className="height-20">
                                    <td className="_no-border-bottom align-bottom">Выдача
                                        масла.....................................................................
                                    </td>
                                    <td/>
                                    <td/>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table">
                            <table className="_no-border">
                                <thead>
                                <tr className="_border-top">
                                    <th className="center _no-border-left"/>
                                    <th className="center width-60">Первая<br/>смена</th>
                                    <th className="center width-60">Вторая<br/>смена</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className="height-20">
                                    <td className="_no-border-bottom _no-border-left align-bottom">Расход по
                                        норме................................................................
                                    </td>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr className="height-20">
                                    <td className="_no-border-bottom _no-border-left align-bottom">
                                        Фактический.......................................................................
                                    </td>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr className="height-20">
                                    <td className="_no-border-bottom _no-border-left align-bottom">
                                        Экономия............................................................................
                                    </td>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr className="height-20 _border-bottom">
                                    <td className="_no-border-bottom _no-border-left align-bottom">
                                        Перерасход........................................................................
                                    </td>
                                    <td/>
                                    <td/>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table">
                            <table className="_no-border">
                                <thead>
                                <tr className="_border-top _border-left">
                                    <th className="center">Наименование показателей</th>
                                    <th className="center width-50">Первая<br/>смена</th>
                                    <th className="center width-50">Вторая<br/>смена</th>
                                    <th className="center width-50">Всего</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left">Выручка, руб.коп.: по плану</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-60">фактически</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left">Количество часов работы</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-20">в том числе в
                                        движении
                                    </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-40">а) на линии</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-40">б) по заказу</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-40">в) в простое</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-40">г) плановый заказ</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-40">д) неплановый заказ
                                    </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-40">е) в простое по
                                        заказу
                                    </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-40">ж) в ремонте</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-40">з)
                                        <div className="input width-100 height-8">
                                            <span className="line1"/>
                                        </div>
                                    </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left">Общий пробег</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-20">в том числе с
                                        пассажирами
                                    </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-40">а) на маршруте №
                                        <div className="input width-50 height-8">
                                            <span className="line1"/>
                                        </div>
                                    </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-40">б) на маршруте №
                                        <div className="input width-50 height-8">
                                            <span className="line1"/>
                                        </div>
                                    </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-40">в) на заказе
                                        <div className="input width-60 height-8">
                                            <span className="line1"/>
                                        </div>
                                    </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left">Ночные часы</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left">Нулевой пробег</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left">Плановое количество рейсов</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left">Фактически выполненное количество
                                        рей-
                                    </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-20">сов, в т.ч. из числа
                                        запланированных
                                    </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="_no-border-bottom _no-border-left padding-80">и регулярных</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div className="page-wrap backside clearAfter">
                    <div className="form-back">Оборотная сторона формы № 6</div>
                    <div className="table">
                        <table>
                            <thead>
                            <tr>
                                <th rowSpan="2">Наимено-<br/>вание или<br/>номер<br/>маршрута</th>
                                <th colSpan="2">Время<br/>отправления,<br/>ч, мин.</th>
                                <th rowSpan="2" className="width-40">Подпись</th>
                                <th colSpan="2">Время<br/>прибытия, ч, мин.</th>
                                <th colSpan="2">Пробег, км</th>
                                <th rowSpan="2" className="width-40">Подпись</th>
                                <th rowSpan="2">Наимено-<br/>вание или<br/>номер<br/>маршрута</th>
                                <th colSpan="2">Время<br/>отправления,<br/>ч, мин.</th>
                                <th rowSpan="2" className="width-40">Подпись</th>
                                <th colSpan="2">Время<br/>прибытия, ч, мин.</th>
                                <th colSpan="2">Пробег, км</th>
                                <th rowSpan="2" className="width-40">Подпись</th>
                            </tr>
                            <tr>
                                <th className="width-50">по графику</th>
                                <th className="width-50">факти-<br/>чески</th>
                                <th className="width-50">по графику</th>
                                <th className="width-50">факти-<br/>чески</th>
                                <th className="width-60">с пасса-<br/>жирами</th>
                                <th className="width-60">нулевой</th>
                                <th className="width-50">по графику</th>
                                <th className="width-50">факти-<br/>чески</th>
                                <th className="width-50">по графику</th>
                                <th className="width-50">факти-<br/>чески</th>
                                <th className="width-60">с пасса-<br/>жирами</th>
                                <th className="width-60">нулевой</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(this.renderPrintRow(order))}
                            </tbody>
                        </table>
                        <div>Всего рейсов по плану {this.runsCalc(order).length}</div>
                        <div>Всего рейсов по фактически</div>
                    </div>
                </div>
            </div>
        );
    }

    getStyle() {
        return `
            @page {
                size: A4 landscape;
            }
            body {
              color: #000;
              font: 400 8px/1.2 "Arial", sans-serif;
              text-align: left;
            }
            table {
                font: 400 8px/1.2 "Arial", sans-serif;
            }
            .clearAfter::after {
              content: '';
              display: table;
              clear: both;
            }
            .bold {
              font-weight: 700;
            }
            .italic {
              font-style: italic;
            }
            .center {
              text-align: center;
            }
            .text-normal {
              white-space: normal;
            }
            .align-bottom {
              vertical-align: bottom;
            }
            .input {
              color: #0070c0;
              position: relative;
              padding: 0 1px;
              height: 12px;
              line-height: 12px;
            }
            .input._bordered {
              border: 1px solid #000;
            }
            .input .line1,
            .input .line2,
            .input .line3 {
              position: absolute;
              left: 0;
              right: 0;
              border-top: 1px solid #000;
            }
            .input .line1 {
              bottom: 0;
            }
            .input__hint {
              color: #000;
              position: absolute;
              white-space: nowrap;
            }
            .input__hint._centered {
              left: 50%;
              -webkit-transform: translateX(-50%);
                      transform: translateX(-50%);
            }
            .form-back {
              text-align: right;
              font-size: 9px;
            }
            .page-wrap {
              width: 1044px;
              page-break-after: always;
            }
            .page-wrap.backside .table th,
            .page-wrap.backside .table td {
              text-align: center;
            }
            .page-wrap.backside .table .input {
              font-size: 10px;
            }
            .page-block {
              float: left;
              height: 700px;
              padding: 2px;
              position: relative;
            }
            .page-block._left {
              width: 220px;
            }
            .page-block._center {
              border: 1px solid #000;
              border-width: 0 1px;
              width: 470px;
            }
            .page-block._right {
              width: 340px;
            }
            .person {
              margin-top: 9px;
              position: relative;
              padding-bottom: 8px;
            }
            .person__caption > span {
              display: inline-block;
              padding-left: 3px;
            }
            .person .input {
              position: absolute;
              top: 0;
            }
            .person .input .line1 {
              bottom: 1px;
            }
            .person .input__hint {
              bottom: -9px;
            }
            .person .input.signature {
              right: 100px;
              width: 40px;
            }
            .person .input.fullname {
              right: 0;
              width: 90px;
            }
            .person.person-dispatcher {
              padding-bottom: 16px;
            }
            .person.person-dispatcher .person__caption {
              text-align: right;
              padding-right: 180px;
            }
            .block-caption {
              font-size: 9px;
              padding-bottom: 2px;
            }
            .table {
              margin-top: 9px;
            }
            .table table {
              width: 100%;
              border: 1px solid #000;
              border-collapse: collapse;
            }
            .table table td,
            .table table th {
              border-right: 1px solid #000;
              border-bottom: 1px solid #000;
              min-width: 20px;
              padding: 2px 2px;
            }
            .table table .input {
              display: inline-block;
            }
            .table ._no-border {
              border: none;
            }
            .table ._no-border-left {
              border-left: none;
            }
            .table ._no-border-right {
              border-right: none;
            }
            .table ._no-border-bottom {
              border-bottom: none;
            }
            .table ._border {
              border: 1px solid #000;
            }
            .table ._border-top {
              border-top: 1px solid #000;
            }
            .table ._border-bottom {
              border-bottom: 1px solid #000;
            }
            .table ._border-left {
              border-left: 1px solid #000;
            }
            .padding-20 {
              padding-left: 20px !important;
            }
            .padding-40 {
              padding-left: 40px !important;
            }
            .padding-60 {
              padding-left: 60px !important;
            }
            .padding-80 {
              padding-left: 80px !important;
            }
            .width-30 {
              width: 30px;
            }
            .width-40 {
              width: 40px;
            }
            .width-50 {
              width: 50px;
            }
            .width-60 {
              width: 60px;
            }
            .width-70 {
              width: 70px;
            }
            .width-80 {
              width: 80px;
            }
            .width-100 {
              width: 100px;
            }
            .height-8 {
              height: 8px;
            }
            .height-20 {
              height: 20px;
            }
            .cells {
              border: 1px solid #000;
              width: 60px;
              text-align: center;
            }
            .cells .cell {
              height: 12px;
              line-height: 12px;
              border-bottom: 1px solid #000;
            }
            .cells .cell:last-child {
              border-bottom: none;
            }
            .stamp._top {
              padding: 0 0 10px 10px;
              font-size: 9px;
              width: 90px;
            }
            .stamp._bottom {
              padding: 2px 0 0 0;
              width: 46px;
              line-height: 10px;
            }
            .page-block._left .company {
              position: relative;
            }
            .page-block._left .company .caption {
              position: absolute;
              left: 0;
              top: 6px;
            }
            .page-block._left .company .input {
              text-indent: 56px;
              line-height: 20px;
              height: 30px;
            }
            .page-block._left .company .input .line1 {
              left: 48px;
              top: 13px;
              bottom: auto;
            }
            .page-block._left .company .input .line2 {
              bottom: -4px;
            }
            .page-block._left .company .input__hint {
              top: 8px;
              right: 63px;
            }
            .page-block._left .okpo {
              position: relative;
              margin-top: 10px;
            }
            .page-block._left .okpo .caption {
              text-align: right;
              padding-right: 66px;
            }
            .page-block._left .okpo .input {
              position: absolute;
              right: 0;
              top: -3px;
            }
            .page-block._left .address {
              margin-top: 6px;
            }
            .page-block._left .address .input {
              height: 24px;
            }
            .page-block._left .address .input .line1 {
              top: 10px;
              bottom: auto;
            }
            .page-block._left .address .input .line2 {
              bottom: 1px;
            }
            .page-block._left .address .input__hint {
              bottom: -9px;
            }
            .page-block._left .phone {
              margin-top: 10px;
            }
            .page-block._left .phone .input__hint {
              bottom: -9px;
            }
            .page-block._left .state {
              margin-top: 10px;
              line-height: 10px;
            }
            .page-block._left .condition {
              margin-top: 18px;
              padding: 0 5px;
            }
            .page-block._left .mark {
              margin-top: 6px;
            }
            .page-block._left .mark .input {
              height: 30px;
            }
            .page-block._left .mark .input .line1 {
              top: 10px;
              bottom: auto;
            }
            .page-block._left .mark .input .line2 {
              top: 21px;
            }
            .page-block._left .mark .input .line3 {
              bottom: -4px;
            }
            .page-block._left .pass-accept {
              margin-top: 18px;
              position: relative;
              padding-bottom: 8px;
            }
            .page-block._left .pass-accept .input {
              position: absolute;
              top: -2px;
            }
            .page-block._left .pass-accept .input__hint {
              bottom: -10px;
            }
            .page-block._left .pass-accept .pass .input {
              left: 22px;
            }
            .page-block._left .pass-accept .accept {
              position: absolute;
              top: 0;
              left: 100px;
            }
            .page-block._left .pass-accept .accept .input {
              left: 28px;
            }
            .page-block._center .title {
              padding-left: 20px;
              font-size: 12px;
              line-height: 15px;
            }
            .page-block._center .title .caption {
              text-transform: uppercase;
            }
            .page-block._center .title .series,
            .page-block._center .title .number {
              position: absolute;
              top: 1px;
            }
            .page-block._center .title .series .input,
            .page-block._center .title .number .input {
              height: 15px;
            }
            .page-block._center .title .series {
              left: 196px;
            }
            .page-block._center .title .series .input__hint {
              font-size: 9px;
              bottom: -11px;
            }
            .page-block._center .title .number {
              left: 306px;
            }
            .page-block._center .title .number .input {
              position: absolute;
              top: 0;
              left: 18px;
            }
            .page-block._center .title .date {
              height: 15px;
              position: relative;
              margin-top: 8px;
              margin-left: 24px;
            }
            .page-block._center .title .date .input {
              height: 15px;
              display: inline-block;
            }
            .page-block._center .title .date__num,
            .page-block._center .title .date__month,
            .page-block._center .title .date__year {
              position: absolute;
            }
            .page-block._center .title .date__num .input {
              width: 12px;
            }
            .page-block._center .title .date__month {
              left: 36px;
            }
            .page-block._center .title .date__year {
              left: 140px;
            }
            .page-block._center .transport-info {
              margin-top: 8px;
              position: relative;
            }
            .page-block._center .transport-info .input {
              display: inline-block;
            }
            .page-block._center .transport-info .mark,
            .page-block._center .transport-info .state-number {
              position: relative;
            }
            .page-block._center .transport-info .mark .caption,
            .page-block._center .transport-info .state-number .caption {
              display: inline-block;
              padding-right: 3px;
            }
            .page-block._center .transport-info .mark .input .line1,
            .page-block._center .transport-info .state-number .input .line1 {
              bottom: 1px;
            }
            .page-block._center .transport-info .mark .input {
              width: 320px;
            }
            .page-block._center .transport-info .state-number .input {
              width: 110px;
            }
            .page-block._center .transport-info .garage-number {
              position: absolute;
              right: 67px;
              top: 14px;
            }
            .page-block._center .transport-info .garage-number .cells {
              position: absolute;
              left: 67px;
              bottom: -1px;
            }
            .page-block._center .route {
              position: relative;
              margin-top: 8px;
              border-bottom: 1px solid #000;
              padding-bottom: 12px;
            }
            .page-block._center .route .caption {
              display: inline-block;
            }
            .page-block._center .route .input {
              display: inline-block;
            }
            .page-block._center .route-name > .input {
              width: 250px;
            }
            .page-block._center .route-name .cells {
              position: absolute;
              right: 0;
              top: 0;
            }
            .page-block._center .route-card {
              margin-top: 4px;
            }
            .page-block._center .route-card .input {
              color: #000;
              font-size: 9px;
              width: 280px;
            }
            .page-block._center .route-card .input__hint {
              bottom: -10px;
              font-size: 8px;
            }
            .page-block._center .route-register,
            .page-block._center .route-series,
            .page-block._center .route-number {
              display: inline-block;
              margin-top: 8px;
            }
            .page-block._center .route-register {
              margin-right: 20px;
            }
            .page-block._center .route-series {
              margin-right: 4px;
            }
            .page-block._center .order {
              margin-top: 2px;
              position: relative;
              border-bottom: 1px solid #000;
              padding-bottom: 4px;
            }
            .page-block._center .order .caption,
            .page-block._center .order .input {
              display: inline-block;
            }
            .page-block._center .order-block {
              position: relative;
              margin-top: 2px;
            }
            .page-block._center .order-customer .input {
              width: 140px;
            }
            .page-block._center .order-place {
              position: absolute;
              right: 0;
              top: 0;
            }
            .page-block._center .order-place .input {
              width: 200px;
            }
            .page-block._center .order-time .time-till {
              position: absolute;
              left: 230px;
              top: 14px;
            }
            .page-block._center .time .input {
              display: inline-block;
              width: 50px;
            }
            .page-block._center .downtime {
              border-bottom: 1px solid #000;
              padding-bottom: 8px;
            }
            .page-block._center .downtime-line {
              padding-top: 5px;
            }
            .page-block._center .downtime-block {
              position: relative;
            }
            .page-block._center .downtime-time .time-from {
              padding-left: 80px;
            }
            .page-block._center .downtime-time .time-till {
              position: absolute;
              right: 10px;
              top: 0;
            }
            .page-block._center .downtime-reason .caption,
            .page-block._center .downtime-reason .input {
              display: inline-block;
            }
            .page-block._center .downtime-reason .input {
              width: 422px;
            }
            .page-block._center .controller {
              margin-top: 6px;
            }
            .page-block._center .controller .caption,
            .page-block._center .controller .input {
              display: inline-block;
            }
            .page-block._center .controller .input {
              width: 340px;
            }
            .page-block._center .controller .input .line1 {
              bottom: -1px;
            }
            .page-block._center .controller .input__hint {
              bottom: -11px;
            }
            .page-block._right .form {
              font-size: 9px;
              padding-bottom: 15px;
            }
            .page-block._right .form-info {
              text-align: right;
            }
            .page-block._right .form-code {
              text-align: right;
              padding: 15px 67px 0 0;
              position: relative;
            }
            .page-block._right .form-code .cells {
              position: absolute;
              right: 0;
              top: 0;
            }
        `;
    }
}