import React, {Component} from 'react';
import {List} from 'immutable';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import _ from 'lodash';
import $ from 'jquery';
import classNames from 'classnames';

import './TableComponent.less';
import {getOption, setUserOptionValue} from "store/reducers/settings";
import * as storage from "utils/storage";
import {TableColumnFilterDate} from "components/ui/Table/Column/Filter/TableColumnFilterDate";
import {TableColumnFilterText} from "components/ui/Table/Column/Filter/TableColumnFilterText";
import {TableColumnFilterSelect} from "components/ui/Table/Column/Filter/TableColumnFilterSelect";

import {TableColumnFilterTextSimple} from "components/ui/Table/Column/Filter/TableColumnFilterTextSimple";


import Tables from "dictionaries/tables";
import {TableColumnFilterNumber} from "components/ui/Table/Column/Filter/TableColumnFilterNumber";
import debounce from "lib/throttle-debounce/debounce";
import { connectionEstablished } from '../../../../helpers/api/session';

window.$ = require('jquery');
window.dt = require('datatables.net');
require('imports-loader?define=>false!datatables.net-colreorder')(window, $);
require('imports-loader?define=>false!datatables.net-select')(window, $);

import {TableColumnFilterModes} from "components/ui/Table/Column/Filter/TableColumnFilterModes";

@propTypes({
    columns: PropTypes.object,
    defaultOrder: PropTypes.array,
    select: PropTypes.oneOf(['none', 'single', 'multiple']),
    checkbox: PropTypes.bool,
    visibleCheckbox: PropTypes.bool,
    rows: PropTypes.instanceOf(List),
    onColsReordered: PropTypes.func,
    checkCache: PropTypes.func,
    onDblClick: PropTypes.func,
    showAudit: PropTypes.func,
    loadCallback: PropTypes.func,
    onCheck: PropTypes.func,
    onChangePage: PropTypes.func,
    query: PropTypes.string,
    showTableSearchFooter: PropTypes.bool,
    onColumnFilterChange: PropTypes.func.isRequired,
    onSettingsLoad: PropTypes.func,
    onClick: PropTypes.func,
    shortPagination: PropTypes.bool,
    stateSave: PropTypes.bool,
    onInitialized: PropTypes.func,
    className: PropTypes.string,
    onChangeSelectFieldsCheckbox: PropTypes.func,
})

@defaultProps({
    onColsReordered: () => {
    },
    onDblClick: () => {
    },
    showAudit: () => {
    },
    onCheck: () => {
    },
    onChangePage: () => {    
    },
    loadCallback: () => {
    },
    onSettingsLoad: () => {
    },
    checkCache: () => true,
    query: '',
    select: 'multiple',
    defaultOrder: [[0, 'asc']],
    showTableSearchFooter: false,
    shortPagination: false,
    onColumnFilterChange: () => {
    },
    onClick: () => {
    },
    stateSave: true,
    checkbox: false,
    visibleCheckbox: true,
    onInitialized: () => {
    },
    className: '',
})

@connect((state) => ({}), {getOption, setUserOptionValue}, null, {withRef: true})



export default class TableComponent extends Component {

    state = {};

    table = null;
    stateSaveDebounce = debounce(1000, ::this.saveState);
    preloadNextPageDebounce = debounce(20000, ::this.preloadNextPage);

    dataCache = {};
    lastRequest = {};

    async componentDidMount() {
        if (this.props.stateSave) {
            const savedState = await this.getSavedState();
            if (savedState) {
                this.props.onSettingsLoad(savedState);
                this.init(savedState);
            } else {
                this.init();
            }
        } else {
            this.init();
        } 
    }

    componentWillUnmount() {
        this.preloadNextPageDebounce.cancel && this.preloadNextPageDebounce.cancel();
    }

    async getSavedState() {
        return storage.get(this.getStateKey());
    }

    init(savedState = null) {
        if (savedState) {
            _.set(savedState, 'search.search', '');
        }
        if (this.table) {
            return;
        }

        this.bindTableDblClick($(this.refs.table));

        //какие-то настройки отрисовки
        const defaultOrder = _.cloneDeep(this.props.defaultOrder);


        if (defaultOrder.length && defaultOrder[0].length) {
            const orderItem = defaultOrder[0];
            if (orderItem[0] >= this.props.columns.size) {
                orderItem[0] = this.props.checkbox ? 1 : 0;
            }
        }

        if (savedState && this.props.onChangeSelectFieldsCheckbox) {
            _.each(_.get(savedState, 'columns', []), (column, index) => {
                if (!column.visible) {
                    this.props.onChangeSelectFieldsCheckbox(index, false);
                }
            });
        }

        let classToLinkNode;
        if (window.RNIS_SETTINGS.CITY_MO && location.pathname === '/kiutr/orders') {
            classToLinkNode = 'uniqueVehicles';
        }


        let datatableConfig = {
            dom: `lfrt<"t-pagination ${classToLinkNode ? classToLinkNode : null}"i<"split"<"splitter">>p>`,
            orderMulti: false,
            lengthChange: false,
            searching: true,
            ordering: true,
            paging: this.props.isPaging !== false,
            info: true,
            scrollCollapse: false,
            scrollY: '100%',

            stateSave: true,
            stateSaveCallback: this.stateSaveDebounce,
            stateLoadCallback: () => savedState,

            pageLength: 25,

            oLanguage: {
                sProcessing: Tables.PROCESSING,
                oPaginate: {
                    sFirst: this.props.shortPagination ? '' : Tables.PAGINATION.FIRST,
                    sLast: this.props.shortPagination ? '' : Tables.PAGINATION.LAST,
                    sPrevious: this.props.shortPagination ? '' : Tables.PAGINATION.PREVIOUS,
                    sNext: this.props.shortPagination ? '' : Tables.PAGINATION.NEXT
                },
                sEmptyTable: Tables.EMPTY,
                sZeroRecords: Tables.EMPTY,
                sInfo: this.props.shortPagination ? Tables.INFO_SHORT : Tables.INFO,
                sInfoEmpty: Tables.INFOEMPTY,
                select: {
                    rows: Tables.ROWS
                }
            },

            processing: true,
            serverSide: true,
            ajax: ::this.loadCallback,
            initComplete: () => {
                this.props.onInitialized($(this.refs.table));
            },

            createdRow: this.props.onRowCreate || ::this.onRowCreate,
            bDeferRender: true,
            deferRender: true,

            columns: this.getColumnDefs(),
            order: defaultOrder
        };
        
        if (this.props.select !== 'none') {
            datatableConfig.select = {
                style: this.props.select,
                selector: this.props.checkbox ? 'td:first-child' : 'tr, tr:first-child, td, td:first-child',
                // selector: 'tr, tr:first-child, td, td:first-child'
            };
        }
        this.table = $(this.refs.table).DataTable(datatableConfig);

        this.table.on('page.dt', ::this.scrollToTop);
        this.table.on('draw.dt', ::this.checkPagingVisibility);
        this.table.on('user-select', (e, dt, type, cell, originalEvent) => {
            if ($(originalEvent.target).closest('tr').hasClass('deleted')) {
                e.preventDefault();
            }
        });
        this.table.on('column-reorder', this.props.onColsReordered);
        this.table.on('init', () => {
            const self = this;
            $(this.refs.table).closest('.dataTables_wrapper').find('.dataTables_scrollHead').css('overflow', 'visible');
            $(this.refs.table).closest('.dataTables_wrapper').find('.dataTables_scrollBody').on('scroll', (e) => {
                const offset = $(e.target).scrollLeft && $(e.target).scrollLeft();

                $(e.target).prev().css({left: `-${offset}px`});
            });
            window.$(this.refs.table).closest('.dataTables_wrapper').find('thead th').each(function () {
                const $this = window.$(this);
                const container = window.$(self.refs.table);
                $this.on('mouseover', () => {
                    const index = $this.index() + 1;
                    container.find(`td:nth-child(${index})`).addClass('active');
                }).on('mouseout', () => {
                    const index = $this.index() + 1;
                    container.find(`td:nth-child(${index})`).removeClass('active');
                });
                const events = window.$._data(this, 'events');
                const clickEvent = events && events.click && events.click[0];
                if (clickEvent) {
                    $this.unbind('click');
                    $this.click((e) => {
                        if (window.$(e.target).is('th')) {
                            clickEvent.handler(e.originalEvent);
                        }
                    })
                }
                const mouseDownEvent = events && events.mousedown && events.mousedown[0];
                if (mouseDownEvent) {
                    $this.unbind('mousedown');
                    $this.mousedown((e) => {
                        if (window.$(e.target).is('th')) {
                            mouseDownEvent.handler(e.originalEvent);
                        }
                    })
                }
            });
        });

        if (this.props.checkbox) {
            window.$(this.refs.table).on('click', '.select-checkbox', () => {
                setTimeout(::this.props.onCheck, 100);
            });
        }
    }

    scrollToTop() {
        $('.dataTables_scrollBody').scrollTop(0);
    }

    async loadCallback(request, drawCallback, settings) {
        const page = request.start / request.length + 1;
        let useCache = !!this.props.useCache;
        if (!this.props.checkCache(request) ||
            JSON.stringify(request.order) !== JSON.stringify(this.lastRequest.order) ||
            JSON.stringify(request.columns) !== JSON.stringify(this.lastRequest.columns) ||
            JSON.stringify(request.search) !== JSON.stringify(this.lastRequest.search) ||
            request.length !== this.lastRequest.length) {
            useCache = false;
        }

        if (!useCache) {
            this.dataCache = {};
        }
        const cache = this.dataCache[page];

        if (cache) {
            cache.draw = request.draw;
            drawCallback(cache);
            this.preloadNextPageDebounce(request, settings);
            this.props.onChangePage();
            return;
        }

        this.lastRequest = _.cloneDeep(request);

        const drawCallbackMiddleware = (data) => {
            drawCallback(data);

            this.dataCache[page] = data;
            this.preloadNextPageDebounce(request, settings);
            this.props.onChangePage();
        };

        this.props.loadCallback(request, drawCallbackMiddleware, settings);
    }

    preloadNextPage(request, settings) {
        if (!this.props.useCache) {
            return;
        }

        const page = request.start / request.length + 1;

        if (this.dataCache[page + 1] || (request.length !== 25)) {
            return;
        }

        let nextPageRequest = _.cloneDeep(request);
        nextPageRequest.start += nextPageRequest.length;

        nextPageRequest.sync = true;

        this.props.loadCallback(nextPageRequest, (data) => {
            this.dataCache[page + 1] = data;
        }, settings);
    }

    checkPagingVisibility() {
        const info = this.table.page.info();

        if ((info.pages > 0) && (info.pages <= info.page)) {
            setTimeout(() => {
                this.table.page('first').draw();
            }, 500);
        }
        if (info.pages > 1) {
            $(this.refs.table).closest('.Table').find('.dataTables_paginate').show();
        } else {
            $(this.refs.table).closest('.Table').find('.dataTables_paginate').hide();
        }
        if (info.page === 0) {
            $(this.refs.table).closest('.Table').find('.paginate_button.previous').addClass('invisible');
        } else {
            $(this.refs.table).closest('.Table').find('.paginate_button.previous').removeClass('invisible');
        }
        if (info.page === info.pages - 1) {
            $(this.refs.table).closest('.Table').find('.paginate_button.next').addClass('invisible');
        } else {
            $(this.refs.table).closest('.Table').find('.paginate_button.next').removeClass('invisible');
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!this.table) {
            return false;
        }

        this.updateColumnsVisibility(nextProps);
        if (nextProps.query !== this.props.query) {
            this.table.search(nextProps.query).draw();
        }
        return false;
    }

    onRowCreate(row, data, index) {
        if (data.deleted_at) {
            $(row).addClass('deleted');
        }
    }

    getStateKey() {
        if (this.props.stateKey) {
            return `table-state:${this.props.stateKey}`;
        }

        const path = window.location.pathname.replace(/\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/g, '/_uuid_');
        return `table-state:${path}`;
    }

    async saveState(settings, data) {
        if (!this.props.stateSave) {
            return;
        }

        if (this.props.checkbox) {
            storage.set(this.getStateKey(), {
                ...data,
                columns: data.columns.slice(1, data.columns.length),
            });
        } else {
            storage.set(this.getStateKey(), data);
        }
    }

    getSelected() {
        if (!this.table) {
            return null;
        }
        return this.table.rows({selected: true});
    }

    reload() {
        this.dataCache = {};

        this.table && this.table.draw('full-hold');
    }

    getColumnDefs() {
        const defs = [];

        if (this.props.checkbox) {
            defs.push({
                orderable: false,
                className: 'select-checkbox',
                visible: this.props.visibleCheckbox,
                data: () => {
                    return '<div><i/></div>';
                }
            });
        }

        this.props.columns.forEach((column, index) => {
            defs.push({
                data: column.data || column.field,
                name: column.field || column.data,
                visible: !column.hidden,
                orderable: _.isUndefined(column.orderable) ? true : column.orderable,
                defaultContent: '',
                class: (column.align ? `align-${column.align} ` : '') + column.className,
                width: column.width,
            });
        });

        return defs;
    }

    updateColumnsVisibility(props) {
        props.columns.forEach((column, index) => {
            if (this.table.column(column.index + (props.checkbox ? 1 : 0)).visible() !== !column.hidden) {
                this.table.column(column.index + (props.checkbox ? 1 : 0)).visible(!column.hidden);
            }
        });

        if (props.checkbox) {
            if (this.table.column(0).visible() !== props.visibleCheckbox) {
                this.table.column(0).visible(props.visibleCheckbox);
            }
        }
    }

    render() {
        return (
            <div className={classNames('Table', this.props.className)}>
                <table ref="table" className="b-table order-column" style={{width: '100%'}}>
                    {/* это почему-то НЕ отрисовывает контент
                        отрисовывает один раз, потом всё блочится в shouldComponentUpdate 
                        потому что таблица отрисовывается в init с помощью jQuery -_-
                    */}
                    <TableHeader ref="tableHeader" columns={this.props.columns.sortBy((column) => column.index)} {...this.props}/>
                    <TableBody rows={[]} columns={this.props.columns}/>
                    {(this.props.showTableSearchFooter && this.table) ?
                        <TableFooter table={this.table} columns={this.props.columns}/> : null}
                </table>
            </div>
        );
    }

    rowGetter(i) {
        return this.props.rows.get(i);
    }

    bindTableDblClick(table) {
        table.on('click', 'tr', (e) => {
            const selector = $(e.target).closest('td');
            const row = this.table.row(selector);
            const col = this.table.column(selector);

            const columns = this.props.columns.toArray();
            const index = this.props.checkbox ? col.index() - 1 : col.index();
            const column = columns[index];

            const rowData = row.data();
            const data = {field: column && column.field, data: rowData};

            if (e.ctrlKey) {
                this.table.row(selector).deselect();
                this.props.onClick(data);
                return;
            }

            const selected = this.getSelected()[0];
            if (selected.length > 0) {
                if (selected[0] === row[0][0]) {
                    if (rowData && !rowData.deleted_at) {
                        this.props.onDblClick(rowData);
                        return;
                    } else if (rowData && rowData.deleted_at) {
                        this.props.showAudit && this.props.showAudit(rowData);
                        return;
                    }
                }
            }

            if (this.props.select !== 'none' && !$(e.target).is('.select-checkbox') && !$(e.target).is('.select-checkbox div') && !$(e.target).is('.select-checkbox i')) {
                this.table.row(selector).select();
            }

            this.props.onClick(data);

            setTimeout(::this.props.onCheck, 100);
        });
    }
}


@propTypes({
    columns: PropTypes.object.isRequired,
    onColumnFilterChange: PropTypes.func.isRequired,
})

export class TableHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            field: '',
            opened: false
        };
    }

    render() {
        return (
            <thead>
            <tr>
                {this.props.checkbox ? <th width="16px"/> : null}
                {this.props.columns.map(::this.renderColumnHeader)}
            </tr>
            </thead>
        );
    }

    onFilterOpened = (field, opened) => {
        this.setState({
            field,
            opened
        });
    };

    getColumnFilter(column, index) {
        const isOpened = (column.field === this.state.field) && this.state.opened;
        if(column.toggleTypeFilter) {
            return(
                <TableColumnFilterModes
                    ref={`tableFilterSelect${index}`}
                    index={index}
                    column={column}
                    onChange={this.props.onColumnFilterChange}
                    onFilterOpened={this.onFilterOpened}
                    opened={isOpened}
                    classNameRoot='filter-with-modes'

                />
            )
        }

        if (column.filter) {
            return (
                <TableColumnFilterSelect
                    ref={`tableFilterSelect${index}`}
                    index={index}
                    column={column}
                    onChange={this.props.onColumnFilterChange}
                    onFilterOpened={this.onFilterOpened}
                    opened={isOpened}
                />
            );
        }
        if (!column.filterable) {
            return null;
        }

        let FilterComponent = null;

        switch (column.filterType) {
            case 'date':
                FilterComponent = TableColumnFilterDate;
                break;
            case 'number':
                FilterComponent = TableColumnFilterNumber;
                break;
            case 'simple':
                FilterComponent = TableColumnFilterTextSimple;
                break;
            case 'text':
            default:
                FilterComponent = TableColumnFilterText;
                break;
        }

        return (
            <FilterComponent
                index={index}
                column={column}
                onChange={this.props.onColumnFilterChange}
                onFilterOpened={this.onFilterOpened}
                opened={isOpened}
            />
        );
    }

    renderColumnHeader(column, index) {
        const filter = this.getColumnFilter(column, index);

        return (
            <th key={column.index}>
                {column.name}
                {filter}
            </th>
        );
    }
}

@propTypes({
    table: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired
})

export class TableFooter extends Component {
    render() {
        return (
            <tfoot>
            <tr>
                <th></th>
                {this.props.columns.map(column => (
                    <th key={column.index}>
                        {(_.isUndefined(column.filterable) || column.filterable) ?
                            <ColumnSearchText column={column} onChange={::this.onColumnSearchTextChange}/> : null}
                    </th>
                ))}
            </tr>
            </tfoot>
        );
    }

    onColumnSearchTextChange(column, value) {
        this.props.table.column(column.index + 1).search(value).draw();
    }
}

@propTypes({
    column: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
})

export class ColumnSearchText extends Component {
    debounce = debounce(300, ::this.submitChanges);

    render() {
        return (
            <input ref="input" type="text" className="ColumnSearchText" onChange={::this.onChange}
                   placeholder={this.props.column.name}/>
        );
    }

    onChange() {
        this.debounce();
    }

    submitChanges() {
        const value = this.refs.input.value;
        this.props.onChange(this.props.column, value);
    }
}


@propTypes({
    rows: PropTypes.array.isRequired,
    columns: PropTypes.object.isRequired
})

export class TableBody extends Component {

    render() {
        return (
            <tbody>
                {this.props.rows.map(::this.renderRow)}
            </tbody>
        );
    }

    renderRow(item, i) {
        return (
            <tr key={i}>
                <td width={20}>
                    <div><i/></div>
                </td>
                {this.props.columns.map(column => (
                    <td>{item[column.key]}</td>
                ))}
            </tr>
        )
    }
}
