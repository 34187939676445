import React, {Component} from "react";
import {propTypes} from "react-props-decorators";
import PropTypes from "prop-types";
import JsSIP from "jssip";
import * as SIP from "sip.js";
import Button from "components/ui/button";
import * as storage from "utils/storage";
import {api} from "helpers/api";
import {User} from "helpers/user";
import ContextTooltip from "components/ui/context-tooltip";
import './sip_button.less';
import currentUser from "helpers/current-user";
import swal from 'sweetalert2';
import Alert from "react-s-alert";
import * as alerts from "helpers/alerts";
import _ from 'lodash';

let sip = null;

@propTypes({
    title: PropTypes.string,
})

export default class SIPButtonComponent extends Component {

    /**
     * @type {CallSounds}
     */
    audio = new CallSounds();

    state = {
        phone: null,
        session: null,
        calling: false,
        connected: false
    };

    async componentWillMount() {
    }

    connect() {
        if (sip === null) {
            const user = new User(currentUser.user);
            if (user.hasSipExt) {
                sip = this.initJsSIP(user.SIPData);
            } else {
                sip = false;
            }
        }
    }

    urlDomain(url) {
        let a = document.createElement('a');
        a.href = url;
        return a.hostname;
    }

    debug(text) {
        if (/sip=/.test(window.location.search)) {
            Alert.info(text, {
                customFields: {
                    item: {
                        data: {
                            is_override_text: true,
                        },
                    },
                    vehicle_models: [],
                },
            });
            /*swal({
                title: text,
                timer: 1000,
            });*/
        }
    }

    initJsSIP(number) {
        //return;
        /*if (this.sip) {
            this.sip.stop();
        }*/
        this.debug(`Соединение с ${number.ext} / ${number.password}`);
        const host = window.RNIS_SETTINGS.RNIS_SIP_URL;
        let sipObjectConfig = {
            log: {level: 3},
            wsServers: [host],
            rtcpMuxPolicy: "negotiate",
            uri: `sip:${number.ext}@${this.urlDomain(host)}`,
            password: number.password
        }

        if (window.RNIS_SETTINGS.CITY_NOVOSIBIRSK) {
            sipObjectConfig.turnServers =  {
                "urls": "turn:185.138.128.10:3479?transport=udp",
                "username": "anutkaTurn",
                "password": "Rnso2020wtrD"
            }
            sipObjectConfig.stunServers = []
            console.log('sip config: ', sipObjectConfig )
        }

        let sip = new SIP.UA(sipObjectConfig);

        console.log('sip object created: ', sip)

        sip.on('connected', (e) => {
            this.setState({
                connected: true
            });
            this.debug('Соединено');
            console.log('sip connected ', e)
            //console.log(`SIP connected to the server ${App.sip.host}`);
        });

        sip.on('registered', (e) => {
            this.setState({
                connected: true
            });
            this.debug('Зарегистрировано');
            console.log('sip registered ', e)
            // console.log(`SIP registered with number`, number);
        });

        sip.on('registrationFailed', (e) => {
            this.debug('Ошибка регистрации');
            console.log('sip registration failed ', e)
            // console.log(`SIP registered with number`, number);
        });

        sip.on('unregistered', (e) => {
            this.debug('Не зарегистрировано');
            console.log('sip unregistered ', e)
            // console.log(`SIP unregistered`);
        });

        sip.on('disconnected', (e) => {
            this.debug('Разъединено');
            console.log('sip disconnected ', e)
            // console.warn('SIP disconnected');
        });

        sip.start();
        //this.sip = sip;
        console.log('sip start ', sip)

        return sip;
    }

    callJsSIPTerminate() {
        if (this.state.session) {
            this.state.session.terminate();

            this.setState({
                session: null
            });
        }
    }

    callJsSIPPhone(phone) {
        // console.log(`Call to number [${phone}]`);
        if (!sip.invite) {
            alerts.error('Невозможно выполнить звонок');
            return;
        }
        let session = sip.invite(`sip:${phone}`, {
            media: {
                constraints: {
                    audio: true,
                    video: false
                },
                render: {
                    remote: document.getElementById('audio')
                }
            }
        });

        this.setState({session});

        session.on('accepted', (e) => {
            this.debug('Звонок принят');
            //console.log('Call accepted');
            this.audio.stop('ringback');
            this.audio.play('answered');
        });

        session.on('progress', (e) => {
            // console.log('Call is in progress');
            //
            this.debug('Звонок в процессе');
            this.setState({calling: true});

            this.audio.play('ringback', true);
        });

        session.on('failed', (response, e) => {
            this.setState({
                calling: false,
                session: null
            });

            this.debug('Вызов отклонен по причине: ' + e);
            //console.warn('Call failed with cause', e);

            this.audio.stop('ringback');
            this.audio.play('rejected');
        });

        session.on('rejected', (response, e) => {
            this.setState({
                calling: false,
                session: null
            });
            this.debug('Вызов отклонен по причине: ' + e);
            /*//console.warn('Call rejected with cause', e);
            if (_.indexOf([
                486,
                600,
            ], response.status_code) !== -1) {
                alerts.error('Телефон занят');
            } else {
                alerts.error('Невозможно установить связь');
            }*/

            switch (response.status_code) {
                case 486 :
                    alerts.error('Телефон занят');
                    break;
                case 600 :
                    alerts.error('Телефон занят');
                    break;
                case 480 :
                    alerts.error('Нет ответа');
                    break;
                case 487 :
                    alerts.error('Отменен');
                    break;
                case 404 :
                    alerts.error('Линия перегружена');
                    break;
                case 410 :
                    alerts.error('Не верные аргументы');
                    break;
                default:
                    alerts.error('Невозможно установить связь');
            }

            this.audio.stop('ringback');
            this.audio.play('rejected');
        });

        session.on('cancel', (response, e) => {
            this.setState({
                calling: false,
                session: null
            });
            this.debug('Вызов отклонен по причине: ' + e);
            //console.warn('Call canceled with cause', e);

            this.audio.stop('ringback');
            this.audio.play('rejected');
        });

        session.on('bye', (response, e) => {
            this.setState({
                calling: false,
                session: null
            });

            this.debug('Звонок завершен: ' + e);
            //console.warn('Call send bye', e);
        });
    }

    async call(phone) {
        console.log('call')

        this.connect();
        await this.setState({
            phone: phone,
        });
        this.callJsSIPPhone(phone)
    }

    render() {
        const terminateButton = this.state.session ? (
            <div className="overlay">
                <div className="overlay__info">
                    <span className="b-button b-button_red b-button_size_md b-button_width_auto"
                          onClick={::this.callJsSIPTerminate}>Завершить звонок</span>
                    <span className="overlay__text">Исходящий вызов на номер {this.state.phone}...</span>
                </div>
            </div>
        ) : null;

        return (
            <div>
                <audio id="audio"/>
                {terminateButton}
            </div>
        );
    }
}

const FILES = require('../../../static/sounds/sounds.json');
const SOUNDS = new Map([
    ['ringback', {audio: new Audio(FILES['ringback']), volume: 1.0}],
    ['ringing', {audio: new Audio(FILES['ringing']), volume: 1.0}],
    ['answered', {audio: new Audio(FILES['answered']), volume: 1.0}],
    ['rejected', {audio: new Audio(FILES['rejected']), volume: 0.5}]
]);

let initialized = false;

class CallSounds {

    constructor() {
        if (!initialized) {
            for (let sound of SOUNDS.values()) {
                sound.audio.volume = 0;
                /*try {
                    sound.audio.play();
                } catch (error) {
                }*/
            }

            initialized = true;
        }
    }


    stop(name) {
        let sound = SOUNDS.get(name);

        if (!sound)
            throw new Error(`unknown sound name "${name}"`);

        sound.audio.pause();
        sound.audio.currentTime = 0.0;
    }

    play(name, loop) {
        let sound = SOUNDS.get(name);

        if (!sound)
            throw new Error(`unknown sound name "${name}"`);

        try {
            sound.audio.pause();
            sound.audio.currentTime = 0.0;
            sound.audio.volume = 1.0;
            sound.audio.loop = loop || false;
            sound.audio.play();
        } catch (error) {
            // console.warn('play() | error: %o', error);
        }
    }

}
