export const directionOptions = [
    {
        value: 'forward',
        label: 'Прямое'
    },
    {
        value: 'reverse',
        label: 'Обратное'
    },
    {
        value: 'all',
        label: 'Прямое и Обратное'
    }
];