import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import PageModal from 'components/ui/page-modal';

import moment from 'moment';

import {connect} from "react-redux";
import {getUser, createUser, updateUser, deleteUser} from "store/reducers/staffing/staffing_editor";
import GlobalLoaderComponent from "components/ui/global-loader";
import * as alerts from "helpers/alerts";

import formats from "dictionaries/formats";
import {User} from "helpers/user";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import StaffingEditorForm from "components/modules/staffing/_forms/edit";
import StaffingViewerForm from "components/modules/staffing/_forms/view";
import currentUser from 'helpers/current-user';
import ReactDOM from 'react-dom'
import {printForm} from "helpers/print";
import ContextTooltip from "components/ui/context-tooltip";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add', 'view']),
    uuid: PropTypes.string,
    esiaRequestUuid: PropTypes.string,
    requestInfo: PropTypes.object,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
})

@defaultProps({
    onEditClick: () => {
    },
    onDeleteClick: () => {
    },
})

@connect(state => ({
    user: state.staffing_editor.get('user')
}), {getUser, createUser, updateUser, deleteUser})

export default class StaffingEditor extends Component {

    state = {
        uuid: null,
        user: null,
        isLoading: this.props.mode === 'edit',
        saving: false,
        errors: {},
        component: null,
    };

    componentWillReceiveProps(props) {
        if (this.state.uuid !== props.uuid) {
            this.loadUser(props.uuid);
        }
    }

    render() {
        let title = 'Добавление нового пользователя';

        let form;
        let buttons;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {
            title = 'Редактирование данных пользователя';

            if (this.state.user) {
                form = <StaffingEditorForm
                    {...this.props}
                    ref="form"
                    mode={this.props.mode}
                    onSubmit={::this.edit}
                    onDelete={::this.deleteItem}
                    onClose={::this.props.onClose}
                    data={this.state.user}
                    errors={this.state.errors}
                />;

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            {currentUser.can('com.rnis.system.permission.audit', 'read') ? (
                                <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                                default="Журнал аудита">
                                    <ModalTopMenuListItem
                                        className="b-icon-link_params b-icon-link_icon_history"
                                        href={`/system/audit/${this.props.uuid}?class=App\\Model\\User`}
                                    />
                                </ContextTooltip>
                            ) : null}

                            <ContextTooltip key="base-editor.print" code="base-editor.print" default="Печать">
                                <ModalTopMenuListItem
                                    className="b-icon-link_icon_print"
                                    onClick={::this.print}
                                />
                            </ContextTooltip>

                            <ModalTopMenuListSeparator key="separator"/>
                        </ModalTopMenuList>

                        <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                            <ModalTopMenuButton
                                className="_save"
                                title="Сохранить"
                                onClick={::this.onEdit}
                            />
                        </ContextTooltip>

                        <ModalTopMenuButtonsSeparator/>

                        <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                            <ModalTopMenuButton
                                className="_close"
                                onClick={::this.props.onClose}
                            />
                        </ContextTooltip>
                    </ModalTopMenuButtons>
                );
            }
        } else if (this.props.mode === 'view') {
            title = '...';
            if (this.state.user) {
                title = new User(this.state.user).getFullName(true);

                form = <StaffingViewerForm
                    {...this.props}
                    data={this.state.user}
                />;
            }
            buttons = (
                <ModalTopMenuList>
                    {currentUser.can('com.rnis.system.permission.audit', 'read') ? (
                        <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                        default="Журнал аудита">
                            <ModalTopMenuListItem
                                className="b-icon-link_params b-icon-link_icon_history"
                                href={`/system/audit/${this.props.uuid}?class=App\\Model\\User`}
                            />
                        </ContextTooltip>
                    ) : null}

                    <ContextTooltip key="base-editor.print" code="base-editor.print" default="Печать">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_print"
                            onClick={() => alerts.alert('В разработке')}
                        />
                    </ContextTooltip>

                    <ModalTopMenuListSeparator key="separator"/>

                    {currentUser.can('com.rnis.auth.permission.user', 'delete') ? (
                        <ContextTooltip key="base-editor.delete" code="base-editor.delete" default="Удалить">
                            <ModalTopMenuListItem
                                className="b-icon-link_icon_basket"
                                onClick={this.props.onDeleteClick}
                            />
                        </ContextTooltip>
                    ) : null}
                </ModalTopMenuList>
            );
        } else {
            form = <StaffingEditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={::this.create}
                onClose={::this.props.onClose}
                data={{}}
                errors={this.state.errors}
            />;

            buttons = (
                <ModalTopMenuButtons>
                    <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                        <ModalTopMenuButton
                            className="_save"
                            title="Сохранить"
                            onClick={::this.onCreate}
                        />
                    </ContextTooltip>

                    <ModalTopMenuButtonsSeparator/>

                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={::this.props.onClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );
        }

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}
                className={`profile-modal b-modal-${this.props.mode}`}
                withFade={this.props.mode !== 'view'}
            >
                {loader}
                {form}
            </PageModal>
        );
    }

    print() {
        if (!this.refs.form) return;
        printForm(ReactDOM.findDOMNode(this.refs.form.getWrappedInstance()));
    }

    async loadUser(uuid) {
        this.setState({
            uuid,
            user: null,
            isLoading: !!uuid
        });
        if (uuid) {
            const response = await this.props.getUser(uuid);
            let user = response.payload;
            user.roles = _.map(user.roles || [], 'uuid');
            if (response.isOk) {
                this.setState({user, isLoading: false});
            } else {
                response.showErrors();
            }
        }
    }

    clearErrors() {
        this.setState({errors: {}});
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    onCreate() {
        if (!this.refs.form) return;
        const state = this.refs.form.getWrappedInstance().getData();
        this.create(state);
    }

    onEdit() {
        if (!this.refs.form) return;
        const state = this.refs.form.getWrappedInstance().getData();
        this.edit(state);
    }

    async create(data) {
        this.clearErrors();
        this.startSave();
        const user = this.composeUser(data);
        /*user.roles = user.roles.map(role => {
            return (_.isObject(role)) ? {uuid: role.uuid.value} : role;
        });*/
        if (user.is_notificated) delete user.password;
        const response = await this.props.createUser(user);
        this.endSave();
        if (response.isOk) {
            if (this.props.esiaRequestUuid) {
                this.props.onSubmit(response);
                return;
            }
            this.props.onSubmit();
        } else {
            let {validationErrors} = response
            if(validationErrors['user.login'] === 'validation.regex') {
                validationErrors['user.login'] = 'Ошибка валидации'
            }
            this.setState({
                errors: validationErrors
            });
            response.showErrors();
        }
    }

    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();
        let response = null;
        const user = this.composeUser(data)
        // указан пароль - два вызова, один с паролем, другой нет, не указан пароль - один вызов.
        if (user.password) {
            response = await this.props.updateUser(user);
            if (response.isOk) {
                delete user.password;
            } else {
                this.setState({
                    errors: response.validationErrors
                });
                response.showErrors();
            }
        }

        await this.timeout(500);

        response = await this.props.updateUser(user);
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }

    }

    async deleteItem(uuid) {
        this.startSave();
        const response = await this.props.deleteUser({
            items: [{
                uuid,
            }]
        });
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    composeUser(data) {
        let user = _.clone(data);

        if (user.is_blocked && !user.blocked_at) {
            user.blocked_at = moment().format(formats.DATETIME_API);
        }
        if (!user.is_blocked) {
            user.blocked_at = null;
        }

        user.component = this.props.params.component ? this.props.params.component : data.component;

        if (user.roles) {
            user.roles = data.roles.map((roleUuid) => ({
                uuid: roleUuid,
            }));

            user.roles = user.roles.map(role => {
                if (role.uuid.label) {
                    return {uuid: role.uuid.value}
                } else return role;
            });
        }


        user.driver_license = {};
        return user;
    }

}