import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getPages = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.portal.getPages(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getPage = (uuid) => async (dispatch) => {
    try {
        const response = await api.portal.getPage(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createPage = (data) => async (dispatch) => {
    try {
        const response = await api.portal.createPage(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updatePage = (data) => async (dispatch) => {
    try {
        const response = await api.portal.updatePage(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deletePage = (data) => async (dispatch) => {
    try {
        const response = await api.portal.deletePage(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
