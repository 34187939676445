import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import classNames from 'classnames';
import $ from 'jquery';

@propTypes({
    title: PropTypes.string,
    className: PropTypes.string,
})

@defaultProps({
    title: '',
    className: '',
})

export default class TabItem extends Component {
    componentDidUpdate() {
        const hasError = $(this.refs.children).find('.input__style_state_wrong').length > 0;
        $(this.refs.container).toggleClass('tab_error', hasError);
    }

    onTabSelect() {
        const {index, onSelect} = this.props;

        onSelect(index);
    }

    render() {
        const {title, children, selected} = this.props;

        return (
            <div className={classNames('tab', this.props.className)} ref="container">
                <input type="radio" name="tabs" className="tab__open" checked={selected} onClick={::this.onTabSelect}/>
                <div className="tab__title">
                    <span>{title}</span>
                </div>
                <div className={classNames('tab__content', {
                    'hide': !selected,
                })}>
                    {selected ? children : null}
                </div>
                <div className="tab__hidden" ref="children">{children}</div>
            </div>
        );
    }
}