import {api} from "helpers/api";
import {mapValues, keyBy, get} from 'lodash';
import * as storage from "utils/storage";
import moment from "moment";

const cacheKey = 'tooltips';
let tooltips = null;
const stored = storage.get(cacheKey);
if (stored) {
    const time = moment(stored.time);
    if (time.isSameOrAfter(moment().subtract(60, 'minutes'))) {
        tooltips = stored.data;
    } else {
        storage.remove(cacheKey);
    }
}

if (!tooltips) {
    tooltips = {};
    setTimeout(() => {
        api.system.getTooltips().then((response) => {
            tooltips = mapValues(keyBy(response.payload.items, 'key'), 'value');
            storage.set(cacheKey, {
                data: tooltips,
                time: moment(),
            });
        });
    }, 15000);
}

export function getTooltip(key) {
    return get(tooltips, key, '');
}

export async function updateTooltip(key, value) {
    await api.system.updateTooltip({key, value});

    tooltips[key] = value;
    storage.set(cacheKey, {
        data: tooltips,
        time: moment(),
    });
}