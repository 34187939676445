import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {
    getEventRuleNotification,
    createEventRuleNotification,
    updateEventRuleNotification, getEventRuleNotifications
} from "store/reducers/notifications";
import debounce from 'throttle-debounce/debounce';
import systems from "dictionaries/systems";
import {getUnits} from "store/reducers/organizational_units/units";
import vehicle_statuses from "dictionaries/vehicle_statuses";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getLayerObjectsFull, getLayersFull} from "store/reducers/user-map-objects/layer_list";
import NotificationRuleEditorMapComponent from "components/modules/notifications/rules/map";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import {getRoles} from "store/reducers/roles/roles";
import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import NotificationTextParameters from "components/modules/notifications/rules/text-parameters/index";
import SelectFilter from "components/ui/select-filter";
import reactions from "dictionaries/reactions";
import {
    createUserNotificationSetting,
    getUserNotificationSetting, updateUserNotificationSetting
} from "store/reducers/notifications/user-notification-settings";
import currentUser from 'helpers/current-user';
import notifications from "dictionaries/notifications";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
})

@connect(state => ({}), {getUserNotificationSetting, createUserNotificationSetting, updateUserNotificationSetting})

export default class Editor extends BaseEditor {

    title = 'настроек оповещения';
    modelClass = 'App\\Model\\UserNotificationSetting';

    async loadData(uuid) {
        return await this.props.getUserNotificationSetting(uuid);
    }

    async createItem(data) {
        return await this.props.createUserNotificationSetting(data);
    }

    async updateItem(data) {
        return await this.props.updateUserNotificationSetting(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getEventRuleNotifications, getDictionaryList
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        user_notification_setting: {},
        event_rule_notifications: [],
    };

    getData() {
        return this.state.user_notification_setting;
    }

    async componentDidMount() {
        await this.setState({
            user_notification_setting: this.props.data,
        });
        this.loadEventRuleNotifications();
    }

    async loadEventRuleNotifications() {
        const response = await this.props.getEventRuleNotifications({
            filters: {
                withUsers: currentUser.uuid(),
            },
        });

        if (response.isOk) {
            this.setState({
                event_rule_notifications: _.filter(response.payload.items, (item) => {
                    return _.get(item, 'settings.allow_config');
                }),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.user_notification_setting, path, defaultValue);
    }

    render() {
        const eventRuleNotification = _.find(this.state.event_rule_notifications, {uuid: this.get('event_rule_notification_uuid')});
        const originalVia = _.get(eventRuleNotification, 'via', {});
        const allowAdd = _.get(eventRuleNotification, 'via_allow_add', {});
        const allowRemove = allowAdd;

        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} title="Общая информация">
                        <Block size="xl" title="Оповещение">
                            {this.select('user_notification_setting.event_rule_notification_uuid', _.map(this.state.event_rule_notifications, (item) => ({
                                label: item.name,
                                value: item.uuid,
                            })))}
                        </Block>
                        {eventRuleNotification ? ([
                            <Block key="via" size="xl" title="Методы доставки">
                                {((originalVia.email && allowRemove.email) || (!originalVia.email && allowAdd.email)) ? (
                                    this.checkbox('user_notification_setting.via.email', 'Email')
                                ) : null}
                                {((originalVia.sms && allowRemove.sms) || (!originalVia.sms && allowAdd.sms)) ? (
                                    this.checkbox('user_notification_setting.via.sms', 'СМС')
                                ) : null}
                                {((originalVia.system && allowRemove.system) || (!originalVia.system && allowAdd.system)) ? (
                                    this.checkbox('user_notification_setting.via.system', 'Лента')
                                ) : null}
                                {((originalVia.journal && allowRemove.journal) || (!originalVia.journal && allowAdd.journal)) ? (
                                    this.checkbox('user_notification_setting.via.journal', 'Журнал')
                                ) : null}
                            </Block>,
                            <Block key="checkboxes" size="xl">
                                {this.checkbox('user_notification_setting.at_time', 'Напоминать в фикс. время')}
                                {this.checkbox('user_notification_setting.is_to_the_day_end', 'Напоминать до конца дня')}
                            </Block>,
                            this.get('at_time') ? (
                                <Block key="time" title="Время суток">
                                    {this.textInput('user_notification_setting.time')}
                                </Block>
                            ) : (
                                <Block key="period" title="Периодичность напоминаний, мин">
                                    {this.textInput('user_notification_setting.period')}
                                </Block>
                            ),
                            !this.get('is_to_the_day_end') ? (
                                <Block key="duration" title="Напоминать в течение, мин">
                                    {this.textInput('user_notification_setting.duration')}
                                </Block>
                            ) : null,
                            _.get(eventRuleNotification, 'settings.allow_level_change') ? (
                                <Block key="level" size="xl" title="Уровень важности">
                                    {this.select('user_notification_setting.notification_level', _.map(notifications.levels, (label, value) => ({
                                        value,
                                        label
                                    })))}
                                </Block>
                            ) : null,
                        ]) : null}
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    async setValue(field, value) {
        super.setValue(field, value);

        if (field === 'user_notification_setting.event_rule_notification_uuid') {
            const eventRuleNotification = _.find(this.state.event_rule_notifications, {uuid: value});
            if (eventRuleNotification) {
                this.setValue('user_notification_setting.via.email', eventRuleNotification.via.email);
                this.setValue('user_notification_setting.via.sms', eventRuleNotification.via.sms);
                this.setValue('user_notification_setting.via.system', eventRuleNotification.via.system);
                this.setValue('user_notification_setting.via.journal', eventRuleNotification.via.journal);
                this.setValue('user_notification_setting.at_time', eventRuleNotification.settings.at_time);
                this.setValue('user_notification_setting.is_to_the_day_end', eventRuleNotification.settings.is_to_the_day_end);
                this.setValue('user_notification_setting.time', eventRuleNotification.settings.time);
                this.setValue('user_notification_setting.period', eventRuleNotification.settings.period);
                this.setValue('user_notification_setting.duration', eventRuleNotification.settings.duration);
                this.setValue('user_notification_setting.notification_level', eventRuleNotification.notification_level);
            }
        }
    }
}