import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

@propTypes({
    withBorder: PropTypes.bool,
    withTopBorder: PropTypes.bool,
    withLeftBorder: PropTypes.bool,
    withRightBorder: PropTypes.bool,
    size: PropTypes.oneOf(['lg', 'md']),
    className: PropTypes.string,
})

@defaultProps({
    withBorder: false,
    className: '',
})

export default class TabBlock extends Component {

    render() {
        const {withBorder, children} = this.props;

        return (
            <div className={classNames('tab__block', this.props.className, this.props.size ? `_${this.props.size}` : '', {
                '_border_bottom': withBorder,
                '_border_left': this.props.withLeftBorder,
                '_border_right': this.props.withRightBorder,
                '_border_top': this.props.withTopBorder,
            })}>
                {children}
            </div>
        );
    }
}