import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import moment from "moment";
import formats from "dictionaries/formats";
import _ from 'lodash';
import './style.less';
import {dateTasksDoneChart, getTasks, unitTasksDoneChart} from "store/reducers/commdept/tasks";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import classNames from 'classnames';
import GlobalLoaderComponent from "components/ui/global-loader";
import Page from 'components/ui/page';
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import Popup from "components/ui/popup";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Select from "components/ui/select";
import PageModal from 'components/ui/page-modal';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import Block from "components/ui/form/block";
import TableContainer from "components/ui/Table/Container/TableContainer";
import ReactResizeDetector from 'react-resize-detector';
import $ from 'jquery';
import Datepicker from "components/ui/form/datepicker";
import Button from "components/ui/button";
import {getUnits} from "store/reducers/organizational_units/units";
import {getContract} from "store/reducers/commdept/contracts";
import {
    Bar, BarChart, CartesianGrid, Label, LabelList, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer,
    Tooltip, XAxis,
    YAxis, Text, isNumOrStr
} from "recharts";
import DateTick from "components/modules/analytics/base-components/charts/DateTick/index";

@connect(state => ({}), {
    getTasks,
    getEntityNames,
    getDictionaryList,
    getUnits,
    getContract,
    unitTasksDoneChart,
    dateTasksDoneChart,
})

export default class CommdeptTasksDayComponent extends Component {

    state = {
        loading: false,
        tasks: [],
        tasks_total: '-',
        tasks_done: '-',
        related: new EntityList,
        sort_column: 'number',
        sort_direction: 'asc',
        showFilters: false,
        showGraphics: false,
        work_types: [],
        filters: {
            work_type: null,
            vehicle_type: null,
            kurs_task_status: null,
            units: null,
            task_done: null,
        },
        selected: null,
        expandedBlock: null,
        from: moment(),
        to: moment().add(1, 'day'),
        units: [],
        contract: null,
        page: 1,
        pages: 1,

        chart_done: null,
        unit_data: null,
        date_data: null,
        unit_percent_type: 'best',
    };

    task_done = [
        {
            value: 'done',
            label: 'Выполненные задания',
        },
        {
            value: 'undone',
            label: 'Невыполненные задания',
        },
        {
            value: 'partial',
            label: 'Частично выполненные задания',
        },
    ];

    sort = {
        'number': 'по номеру задания',
        'percent': '% выполнения задания',
        'vehicle_count': 'кол-ву ТС участвующих в задании',
        'parts_count': 'кол-ву участков работ в задании',
    };

    geometryTypes = {
        'road_part': 'Участок дороги',
        'stop_point': 'Остановка',
    };

    componentWillMount() {
        this.loadTasks();

        this.loadUnits();

        this.loadDictionaries([
            'work_types',
            'kurs_task_statuses',
            'vehicle_types',
        ], 'commdept');
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            filters: {
                withComponent: 'commdept',
            },
            pagination: {
                page: 1,
                limit: 10000,
            },
        });

        if (response.isOk) {
            this.setState({
                units: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    getFilters() {
        let filters = {
            withPeriod: [
                moment(this.state.from).format(formats.DATE_API),
                moment(this.state.to).format(formats.DATE_API),
            ],
        };
        if (this.state.filters.work_type) {
            filters.withWorkTypes = [this.state.filters.work_type];
        }
        if (this.state.filters.vehicle_type) {
            filters.withVehicleTypes = [this.state.filters.vehicle_type];
        }
        if (this.state.filters.kurs_task_status) {
            filters.withStatus = this.state.filters.kurs_task_status;
        }
        if (this.state.filters.units) {
            filters.withUnits = [this.state.filters.units];
        }
        if (this.state.filters.task_done) {
            filters.withTaskDone = this.state.filters.task_done;
        }

        return filters;
    }

    async loadTasks() {
        this.loadGraphicsData();
        const filters = this.getFilters();

        this.setState({loading: true});
        const response = await this.props.getTasks({
            filters,
            pagination: {
                page: this.state.page,
                limit: 40,
            },
            order: {
                column: this.state.sort_column,
                direction: this.state.sort_direction,
            },
        });
        this.setState({loading: false});

        if (response.isOk) {
            this.loadTasksDone(filters);
            await this.setState({
                tasks: this.prepareTasks(response.payload.items),
                pages: response.data.headers.meta.pagination.total_pages,
                tasks_total: response.data.headers.meta.pagination.total,
            });
            this.loadRelatedEntities();
            this._onResize();
        } else {
            response.showErrors();
        }
    }

    async loadTasksDone(filters) {
        if (!filters.withTaskDone) {
            filters.withTaskDone = 'done';
        }
        const response = await this.props.getTasks({
            filters,
            pagination: {
                page: 1,
                limit: 1,
            },
        });

        if (response.isOk) {
            this.setState({
                tasks_done: response.data.headers.meta.pagination.total,
            });
        }
    }

    async loadRelatedEntities() {
        let items = [];

        _.each(this.state.tasks, (task) => {
            const vehicles = _.map(task.resources, 'base_vehicle_uuid');
            if (vehicles.length === 1) {
                items.push({
                    class: 'App\\Model\\Vehicle',
                    uuid: _.first(vehicles),
                    source: 'vehicles',
                });
            }
        });

        if (this.state.selected) {
            const task = _.find(this.state.tasks, {uuid: this.state.selected});
            if (task) {
                _.each(task.resources || [], (resource) => {
                    items.push({
                        class: 'App\\Model\\Vehicle',
                        uuid: resource.base_vehicle_uuid,
                        source: 'vehicles',
                    });
                    items.push({
                        class: 'App\\Dictionaries\\Vehicles\\VehicleType\\Model',
                        uuid: resource.vehicle_type_uuid,
                        source: 'dictionary',
                    });
                    items.push({
                        class: 'App\\Model\\UserInfo',
                        uuid: resource.driver_uuid,
                        source: 'auth',
                    });
                });
                _.each(task[this.getField(task)], (item) => {
                    if (item.geometry_type === 'road_part') {
                        items.push({
                            class: 'App\\Model\\RoadPart',
                            uuid: _.get(item, 'geometry.0.item_uuid'),
                            source: 'commdept',
                        });
                    } else if (item.geometry_type === 'stop_point') {
                        items.push({
                            class: 'App\\Model\\StopPoint',
                            uuid: _.get(item, 'geometry.0.item_uuid'),
                            source: 'geo',
                        });
                    }
                });
            }
        }

        const response = await this.props.getEntityNames(items);

        if (response.isOk) {
            this.state.related.add(response);
            this.forceUpdate();
        }
    }

    prepareTasks(tasks) {
        return _.map(tasks, (task) => {
            const fact = _.filter(task.items_fact || [], {is_confirmed: true, movement_type: 'work'}).length;
            const plan = _.filter(task.items_fact || [], {movement_type: 'work'}).length;
            task.percent = (plan !== 0) ? _.round(100 * fact / plan, 2) : 0;

            task.vehicle_count = (task.resources || []).length;
            task.parts_count = _.filter(task[this.getField(task)], {movement_type: 'work'}).length;

            return task;
        });
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value});
        this.loadTasks();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value});
        this.loadTasks();
    };

    renderHeaderActions() {
        return [
            <div key="diapason">
                <div className="top-menu__label">Период:</div>
                <Datepicker style="dark" value={this.state.from} onChange={this.fromChange}/>
                <Datepicker style="dark" value={this.state.to} onChange={this.toChange}/>
            </div>,
            <div key="filter">
                <ContextTooltip key="base-table-list.filter" code="base-table-list.filter" default="Фильтр">
                    <IconButton icon="filter" tooltip="Фильтр" active={this.state.showFilters}
                                onClick={::this.toggleFilters}/>
                </ContextTooltip>
                {this.state.showFilters ? this.renderFiltersPopup() : null}
            </div>,
            <div key="graphics">
                <ContextTooltip default="Диаграммы">
                    <IconButton icon="filter" tooltip="Диаграммы" active={this.state.showGraphics}
                                onClick={::this.toggleGraphics}/>
                </ContextTooltip>
            </div>,
        ];
    }

    toggleFilters() {
        this.setState({
            showFilters: !this.state.showFilters,
        });
    }

    async toggleGraphics() {
        await this.setState({
            showGraphics: !this.state.showGraphics,
        });

        this.loadGraphicsData();
    }

    renderFiltersPopup() {
        return (
            <Popup
                className="SelectFieldsPopup SelectFieldsPopup_tiles top-link"
                show={true}>
                <div className="popup__title">Фильтры карточек</div>
                <div className="content">
                    <div className="content__row">
                        <div className="b-block _md">
                            <div className="b-block__text-el">Вид работ</div>
                        </div>
                        <div className="b-block _md">
                            <Select
                                options={this.state.work_types}
                                value={this.state.filters.work_type}
                                onChange={this.onFilterChange.bind(this, 'work_type')}
                            />
                        </div>
                    </div>
                    <div className="content__row">
                        <div className="b-block _md">
                            <div className="b-block__text-el">Тип техники</div>
                        </div>
                        <div className="b-block _md">
                            <Select
                                options={this.state.vehicle_types}
                                value={this.state.filters.vehicle_type}
                                onChange={this.onFilterChange.bind(this, 'vehicle_type')}
                            />
                        </div>
                    </div>
                    <div className="content__row">
                        <div className="b-block _md">
                            <div className="b-block__text-el">Статус задания</div>
                        </div>
                        <div className="b-block _md">
                            <Select
                                options={this.state.kurs_task_statuses}
                                value={this.state.filters.kurs_task_status}
                                onChange={this.onFilterChange.bind(this, 'kurs_task_status')}
                            />
                        </div>
                    </div>
                    <div className="content__row">
                        <div className="b-block _md">
                            <div className="b-block__text-el">Предприятие</div>
                        </div>
                        <div className="b-block _md">
                            <Select
                                options={this.state.units}
                                value={this.state.filters.units}
                                onChange={this.onFilterChange.bind(this, 'units')}
                            />
                        </div>
                    </div>
                    <div className="content__row">
                        <div className="b-block _md">
                            <div className="b-block__text-el">Выполнение задания</div>
                        </div>
                        <div className="b-block _md">
                            <Select
                                options={this.task_done}
                                value={this.state.filters.task_done}
                                onChange={this.onFilterChange.bind(this, 'task_done')}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }

    async onFilterChange(type, e) {
        const value = e ? e.value : null;

        let filters = this.state.filters;
        filters[type] = value;
        await this.setState({
            filters,
        });

        this.loadTasks();
    }

    _onResize() {
        $('.b-tiles__progress-text').css('width', $('.b-tiles__wrap-progress').width());
    }

    render() {
        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;

        return (
            <Page pageId="KursTasksDay"
                  title="Коммунальная техника → Задания (плиточное представление / день)"
                  headerActions={this.renderHeaderActions()}
                  className="control-works-tiles b-tiles">
                {loader}
                {!this.state.loading ? this.renderContent() : null}
                {this.renderModal()}
                <ReactResizeDetector handleWidth handleHeight onResize={::this._onResize}/>
            </Page>
        );
    }

    renderContent() {
        if (this.state.showGraphics) {
            return this.renderGraphics();
        }

        return this.renderTasksContent();
    }

    renderTasksContent() {
        return (
            <div>
                <div className="b-block b-block_vertical-indent b-block_white _xl b-block_r4">
                    Сортировать по:
                    {_.map(this.sort, ::this.renderSort)}

                    <div className="text-right">
                        Всего
                        заданий: {this.state.tasks_total}{(!this.state.filters.task_done) ? `, из них выполнено на 100% - ${this.state.tasks_done}` : ''}
                    </div>
                </div>
                <div className="b-tiles__wrap">
                    {_.map(this.getTasks(), ::this.renderTask)}
                </div>
                {this.renderPages()}
                {window.RNIS_SETTINGS.GISMETEOWEATHER ? this.renderWeather() : null}
            </div>
        )
    }

    giesmereoScript() {

    }

    renderWeather() {
        return (
            <div style={{
                'position': 'fixed',
                'bottom': '20px',
                'right': '30px',
                'box-shadow': '0px 10px 9px 0px rgba(0,0,0,0.1)'
            }}>
                <div id="GMI_120x240-3_ru" class="gm-info">
                    <div style={{position: 'relative',
                        width: '120px',
                        height: '240px',
                        border: 'solid 1px',
                        background:'#F5F5F5',
                        'border-color': '#EAEAEA #E4E4E4 #DDDDDD #E6E6E6',
                        'border-radius': '4px',
                        '-webkit-box-sizing': 'border-box',
                        '-moz-box-sizing': 'border-box',
                        'box-sizing': 'border-box'}}>
                        <a style={{font: '9px/11px Tahoma,Arial,sans-serif',
                            'text-align': 'center',
                            'text-decoration': 'none',
                            position: 'absolute',
                            bottom: '3px',
                            left: '0',
                            width: '100%',
                            color: '#333'}} href="https://www.gismeteo.ru/"><span style={{color: '#0099FF'}}>Gis</span>meteo</a>
                    </div>
                </div>
                {(function() {
                    var
                        d = window.document,
                        o = window.navigator.userAgent.match(/MSIE (6|7|8)/) ? true : false,
                        s = d.createElement('script');
                    s.src  = 'https://www.gismeteo.ru/informers/simple/install/';
                    s.type = 'text/javascript';
                    s[(o ? 'defer': 'async')] = true;
                    s[(o ? 'onreadystatechange' : 'onload')] = function() {
                        try {new GmI({
                            slug : '6438e53850fca10eefa1ee3f2d36051a',
                            type : '120x240-3',                lang : 'ru'
                        })} catch(e) {}
                    }

                    d.body.appendChild(s);
                })()}
            </div>
        )
    }

    renderPages() {
        const currentPage = this.state.page;
        const pages = _.filter(_.range(1, this.state.pages + 1), (page) => {
            return Math.abs(page - currentPage) <= 3;
        });
        return (
            <div className="dataTables_paginate paging_simple_numbers">
                <span>
                    {_.map(pages, (page) => {
                        return (
                            <a key={page} href="javascript:void(0)" onClick={this.setCurrentPage.bind(this, page)}
                               className={classNames('paginate_button', (page === this.state.page) ? 'current' : '')}>{page}</a>
                        );
                    })}
                </span>
            </div>
        );
    }

    async setCurrentPage(page) {
        await this.setState({page});
        this.loadTasks();
    }

    renderSort(label, value) {
        return (
            <span key={value} onClick={this.onSortChange.bind(this, value)}
                  className={classNames('b-tiles__item-sort', (value === this.state.sort_column) ? 'bold' : '')}>
                {label}
                <i className={classNames('b-tiles__icon', ((value !== this.state.sort_column) || (this.state.sort_direction === 'asc')) ? 'b-tiles__icon_down' : 'b-tiles__icon_up')}/>
            </span>
        );
    }

    async onSortChange(value) {
        if (this.state.sort_column === value) {
            await this.setState({
                sort_direction: (this.state.sort_direction === 'asc') ? 'desc' : 'asc',
            });
        } else {
            await this.setState({
                sort_column: value,
                sort_direction: 'asc',
            });
        }
        this.loadTasks();
    }

    getTasks() {
        return this.state.tasks;
        //return _.orderBy(this.state.tasks, [this.state.sort_column], [this.state.sort_direction]);
    }

    renderTask(task) {
        return (
            <div key={task.uuid} className="b-tiles__item">

                <div className="b-tiles__task">
                    <h2 className="b-tiles__title" onClick={this.onTaskClick.bind(this, task)}>Задание
                        №{task.number}</h2>
                    <div className="b-tiles__content">
                        <div className="b-tiles__wrap-table">
                            <table className="b-tiles__table">
                                <tr>
                                    <td>{((task.resources || []).length === 1) ? (
                                        this.state.related.getReact(_.first(task.resources).base_vehicle_uuid)
                                    ) : (
                                        `Кол-во ТС: ${(task.resources || []).length}`
                                    )}</td>
                                    <td>Участки
                                        работ: {_.filter(task[this.getField(task)], {movement_type: 'work'}).length}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="b-tiles__bottom">
                        <div className="b-tiles__wrap-progress">

                            <div className="b-tiles__progress-bar" style={{width: `${task.percent}%`}}>
                                <div className="b-tiles__progress-text">
                                    <span className="b-tiles__percent">{task.percent}</span>% выполнено
                                </div>
                            </div>

                            <span className="b-tiles__percent">{task.percent}</span>% выполнено

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }

    async onTaskClick(task) {
        await this.setState({
            selected: task.uuid,
            expandedBlock: null,
        });

        this.loadRelatedEntities();
        this.loadContract(_.get(_.first(task.contracts || []), 'uuid'));
    }

    async loadContract(uuid) {
        const response = await this.props.getContract(uuid);

        if (response.isOk) {
            this.setState({
                contract: response.payload.number,
            });
        } else {
            response.showErrors();
        }
    }

    closeModal() {
        this.setState({
            selected: null,
        });
    }

    getField(task) {
        const status = _.get(_.find(this.state.kurs_task_statuses, {value: task.status_uuid}), 'label');

        return (_.indexOf([
            'В работе',
            'На рассмотрении',
            'Закрыт',
        ], status) !== -1) ? 'items_fact' : 'items'
    }

    renderModal() {
        if (!this.state.selected) {
            return null;
        }

        const task = _.find(this.state.tasks, {uuid: this.state.selected});
        if (!task) {
            return null;
        }

        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Закрыть">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.closeModal}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        const field = this.getField(task);

        const title = `Задание №${task.number}`;

        return (
            <PageModal
                header={{title, buttons}}
                onClose={::this.closeModal}
                className={`profile-modal`}
            >
                {_.map(task[field], ::this.renderItem)}
                <div className="Table">
                    <TableContainer>
                        <table className="b-table">
                            <thead>
                            <tr>
                                <th>Тип ТС</th>
                                <th>Номер ТС</th>
                                <th>Водитель</th>
                            </tr>
                            </thead>
                            <tbody>
                            {_.map(task.resources || [], ::this.renderResource)}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
                <Button size="md" width="auto" color="red" shadow="red" text="Перейти в карточку задания"
                        href={`/commdept/tasks/${this.state.selected}`} target="_blank"/>
            </PageModal>
        )
    }

    renderResource(resource, index) {
        return (
            <tr key={index}>
                <td>{this.state.related.getReact(resource.vehicle_type_uuid)}</td>
                <td>{this.state.related.getReact(resource.base_vehicle_uuid)}</td>
                <td>{this.state.related.getReact(resource.driver_uuid)}</td>
            </tr>
        )
    }

    expand(index) {
        this.setState({
            expandedBlock: (this.state.expandedBlock === index) ? null : index,
        });
    }

    renderItem(item, index) {
        if (item.movement_type === 'idle') {
            return null;
        }

        const geometryType = item.geometry_type;

        return ([
            <div key={index} className={classNames('b-draggable__item sector', {
                sector_state_moving: item.movement_type === 'work',
                sector_idling: item.movement_type === 'idle',
                sector_state_success: item.is_confirmed === true,
                sector_state_violation: item.is_confirmed === false,
            })}>
                <div className="b-draggable__state"/>
                <div className="b-draggable__top">
                    <input type="checkbox" className="b-draggable__open" checked={this.state.expandedBlock !== index}
                           onChange={this.expand.bind(this, index)}/>
                    <i className="b-draggable__arrow"/>
                    <div className="b-draggable__title">
                        <div>Участок №{index + 1}</div>
                        <span>/{(item.movement_type === 'idle') ? 'Холостой ход' : _.get(_.find(this.state.work_types, {value: item.work_type_uuid}), 'label')}</span>
                    </div>
                </div>
                {(this.state.expandedBlock === index) ? (
                    <div className="b-draggable__content">
                        <div>
                            {(item.movement_type !== 'idle') ? (
                                <Block title="Тип">
                                    {_.get(this.geometryTypes, item.geometry_type)}
                                </Block>
                            ) : null}
                            {(item.geometry_type === 'road_part') ? ([
                                <Block key="part_start" size="sm" title="Начало участка, км">
                                    {item.part_start}
                                </Block>,
                                <Block key="part_end" size="sm" title="Конец участка, км">
                                    {item.part_end}
                                </Block>,
                            ]) : null}
                            {(item.movement_type === 'work') ? (
                                <Block key="distance" size="sm" title="Протяж, км">
                                    {item.distance}
                                </Block>
                            ) : null}
                            <Block size="sm" key="date_from" title="Время с">
                                {item.date_from}
                            </Block>
                            <Block size="sm" key="date_to" title="Время по">
                                {item.date_to}
                            </Block>
                            <Block size="sm" key="time" title="Продолжительность">
                                {item.time}
                            </Block>
                            <Block size="sm" key="fact_date_from" title="Факт время с">
                                {item.fact_date_from}
                            </Block>
                            <Block size="sm" key="fact_date_to" title="Факт время по">
                                {item.fact_date_to}
                            </Block>
                            <Block size="sm" key="fact_time" title="Факт продолжительность">
                                {item.fact_time}
                            </Block>
                            <Block key="work_type_uuid" title="Вид работы">
                                {_.get(_.find(this.state.work_types, {value: item.work_type_uuid}), 'label')}
                            </Block>
                            <Block key="count" title="Объем работ">
                                {item.count}
                            </Block>
                            {_.get(item, 'geometry.0.item_uuid') ? (
                                <Block key="item_uuid" title="Объект">
                                    {this.state.related.getReact(_.get(item, 'geometry.0.item_uuid'))}
                                </Block>
                            ) : null}
                            {(geometryType === 'road_part') ? (
                                <Block key="direction" title="Направление">
                                    {(item.geometry[0].direction === 'forward') ? 'Прямое' : 'Обратное'}
                                </Block>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>,
        ]);
    }

    renderGraphics() {
        return (
            <div id="main-wrapper">
                <div className="row">
                    <div className="col col-6">
                        <div
                            className="widgets__item">
                            <div className="widgets__header">
                                <div className="widgets__title">Выполнение заданий</div>
                            </div>
                            <div className="widgets__content">
                                <div className="widgets__legend">
                                    <ul className="widgets__legend-list">
                                        <li className="widgets__legend-item">Выполненные задания ({_.get(this.state.chart_done, 'done', '-')})
                                            <div className="indicators indicators_green"/>
                                        </li>
                                        <li className="widgets__legend-item">Частично выполненные задания ({_.get(this.state.chart_done, 'partial', '-')})
                                            <div className="indicators indicators_orange"/>
                                        </li>
                                        <li className="widgets__legend-item">Невыполненные задания ({_.get(this.state.chart_done, 'undone', '-')})
                                            <div className="indicators indicators_red"/>
                                        </li>
                                    </ul>
                                </div>
                                {this.renderDoneChart()}
                            </div>
                        </div>
                    </div>
                    <div className="col col-6">
                        <div
                            className="widgets__item">
                            <div className="widgets__header">
                                <div className="widgets__title">Отставание от графика</div>
                            </div>
                            <div className="widgets__content">
                                <div className="widgets__legend">
                                    <ul className="widgets__legend-list">
                                        <li className="widgets__legend-item">Работы по заданию начаты ({this.state.chart_done ? (this.state.chart_done.done + this.state.chart_done.partial) : '-'})
                                            <div className="indicators indicators_green"/>
                                        </li>
                                        <li className="widgets__legend-item">Работы по заданию не начаты ({_.get(this.state.chart_done, 'undone', '-')})
                                            <div className="indicators indicators_red"/>
                                        </li>
                                    </ul>
                                </div>
                                {this.renderDoneLateChart()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-6">
                        <div
                            className="widgets__item">
                            <div className="widgets__header">
                                <div className="widgets__title">Процент выполнения работ</div>
                            </div>
                            <div className="widgets__content">
                                <div className="widgets__selector">
                                    <Select value={this.state.unit_percent_type}
                                            onChange={this.onChangeType.bind(this, 'unit_percent_type')}
                                            clearable={false} searchable={false} options={[
                                        {
                                            value: 'best',
                                            label: 'Топ лучших',
                                        },
                                        {
                                            value: 'worst',
                                            label: 'Топ худших',
                                        },
                                    ]}/>
                                </div>
                                {this.renderUnitPercentChart()}
                            </div>
                        </div>
                    </div>
                    <div className="col col-6">
                        <div
                            className="widgets__item">
                            <div className="widgets__header">
                                <div className="widgets__title">Графики механизированной уборки</div>
                                <div className="widgets__legend widgets__legend_inline">
                                    <ul className="widgets__legend-list">
                                        <li className="widgets__legend-item">факт
                                            <div className="indicators indicators_green"/>
                                        </li>
                                        <li className="widgets__legend-item">план
                                            <div className="indicators indicators_orange"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="widgets__content">
                                {this.renderDateChart()}
                            </div>
                        </div>
                    </div>
                </div>
                {window.RNIS_SETTINGS.GISMETEOWEATHER ? this.renderWeather() : null}
            </div>
        );
    }

    onChangeType(field, e) {
        const value = e.value || e.target.value;

        let state = this.state;
        state[field] = value;
        this.setState(state);
    }

    renderDoneChart() {
        if (!this.state.chart_done) {
            return;
        }

        const total = this.state.chart_done.done + this.state.chart_done.partial + this.state.chart_done.undone;

        const data = [
            {
                name: 'Выполненные задания',
                value: this.state.chart_done.done,
                fill: '#95B400',
                percent: ((total !== 0) ? _.round(100 * (this.state.chart_done.done) / total, 0) : 0) + '%',
            },
            {
                name: 'Частично выполненные задания',
                value: this.state.chart_done.partial,
                fill: '#F79900',
                percent: ((total !== 0) ? _.round(100 * (this.state.chart_done.partial) / total, 0) : 0) + '%',
            },
            {
                name: 'Невыполненные задания',
                value: this.state.chart_done.undone,
                fill: '#F65C50',
                percent: ((total !== 0) ? _.round(100 * (this.state.chart_done.undone) / total, 0) : 0) + '%',
            },
        ];

        return (
            <ResponsiveContainer>
                <PieChart>
                    <Pie data={data}
                         nameKey="name"
                         dataKey="value"
                         cx="50%"
                         cy="50%"
                         outerRadius="80%"
                         startAngle={90}
                         endAngle={-270}
                         isAnimationActive={false}
                    >
                        <LabelList dataKey="percent" fill="#fff"/>
                    </Pie>
                    <Tooltip/>
                </PieChart>
            </ResponsiveContainer>
        );
    }

    renderDoneLateChart() {
        if (!this.state.chart_done) {
            return;
        }

        const total = this.state.chart_done.done + this.state.chart_done.partial + this.state.chart_done.undone;

        const data = [
            {
                name: 'Работы по заданию начаты',
                value: this.state.chart_done.done + this.state.chart_done.partial,
                fill: '#95B400',
                percent: ((total !== 0) ? _.round(100 * (this.state.chart_done.done + this.state.chart_done.partial) / total, 0) : 0) + '%',
            },
            {
                name: 'Работы по заданию не начаты',
                value: this.state.chart_done.undone,
                fill: '#F65C50',
                percent: ((total !== 0) ? _.round(100 * (this.state.chart_done.undone) / total, 0) : 0) + '%',
            },
        ];

        return (
            <ResponsiveContainer>
                <PieChart>
                    <Pie data={data}
                         nameKey="name"
                         dataKey="value"
                         cx="50%"
                         cy="50%"
                         outerRadius="80%"
                         startAngle={90}
                         endAngle={-270}
                         isAnimationActive={false}
                    >
                        <LabelList dataKey="percent" fill="#fff"/>
                    </Pie>
                    <Tooltip/>
                </PieChart>
            </ResponsiveContainer>
        );
    }

    getFill(value) {
        let green = 80;
        let orange = 50;

        if (value >= green) {
            return '#95B400';
        }
        if (value >= orange) {
            return '#F4A44B';
        }
        return '#F65C50';
    }

    renderUnitPercentChart() {
        let rows;
        if (this.state.unit_percent_type === 'best') {
            rows = _.slice(_.orderBy(this.state.unit_data || [], ['percent'], ['desc']), 0, 10);
        } else {
            rows = _.slice(_.orderBy(this.state.unit_data || [], ['percent'], ['asc']), 0, 10);
        }

        const data = _.map(rows, (row) => {
            return {
                name: _.get(_.find(this.state.units, {value: row.unit_uuid}), 'label'),
                value: _.round(row.percent * 100, 2),
                label: `${_.round(row.percent * 100, 2)}%`,
                fill: this.getFill(_.round(row.percent * 100, 2)),
                radius: [4, 4, 0, 0],
            };
        });

        return (
            <ResponsiveContainer>
                <BarChart data={data} margin={{bottom: 50}}>
                    <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" fontSize={10}
                           tick={props => <Text {...props} width={130}>{props.payload.value}</Text>}/>
                    <YAxis dataKey="value"
                           label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle"
                                                 angle={-90}>%</Text>}/>
                    <Tooltip/>
                    <Bar dataKey="value" fill="#57AF7D" name="Процент выполнения работ" barSize={30}
                         isAnimationActive={false}>
                        <LabelList dataKey="label" position="insideTop" fill="#fff"/>
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    }

    renderDateChart() {
        const isSingleDay = Math.abs(moment(this.state.from).diff(moment(this.state.to), 'hours')) === 24;
        const data = _.map(this.state.date_data , (row) => {
            return {
                name: (!isSingleDay) ? moment(row.date).format(formats.DATE) : moment(row.date).format(formats.TIME),
                value: row.plan,
                label: row.plan,
                fact_value: row.fact,
                fact_label: row.fact,
            };
        });

        return (
            <ResponsiveContainer>
                <LineChart data={data}>
                    <XAxis dataKey="name" tick={<DateTick/>} interval="preserveStartEnd"/>
                    <YAxis dataKey="value"
                           label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle" angle={-90}>Количество</Text>}/>
                    <Tooltip/>
                    <Line dataKey="value" stroke="#F4A44B" strokeWidth={3} fill="#fff" r={5} name="План"
                          type="monotone"/>
                    <Line dataKey="fact_value" stroke="#95B400" strokeWidth={3} fill="#fff" r={5} name="Факт"
                          type="monotone"/>
                </LineChart>
            </ResponsiveContainer>
        );
    }

    async loadGraphicsData() {
        if (this.state.showGraphics) {
            this.loadDoneData();
            this.loadUnitData();
            this.loadDateData();
        }
    }

    async loadDoneData() {
        let filters = this.getFilters();

        filters.withTaskDone = 'done';
        const done = await this.getTasksCount(filters);

        filters.withTaskDone = 'partial';
        const partial = await this.getTasksCount(filters);

        filters.withTaskDone = 'undone';
        const undone = await this.getTasksCount(filters);

        this.setState({
            chart_done: {
                done,
                partial,
                undone,
            },
        });
    }

    async getTasksCount(filters) {
        const meta = {
            filters,
            pagination: {
                page: 1,
                limit: 1,
            },
        };
        const response = await this.props.getTasks(meta);

        if (response.isOk) {
            return response.data.headers.meta.pagination.total;
        }

        return 0;
    }

    async loadUnitData() {
        const response = await this.props.unitTasksDoneChart({
            filters: this.getFilters(),
        });

        if (response.isOk) {
            this.setState({
                unit_data: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadDateData() {
        const response = await this.props.dateTasksDoneChart({
            filters: this.getFilters(),
        });

        if (response.isOk) {
            this.setState({
                date_data: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }
}
