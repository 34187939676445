import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import './show-deleted.less';
import {connect} from "react-redux";
import {getUsers} from "../../store/reducers/staffing/staffing";
import {getFreeDrivers} from "../../store/reducers/kurs/waybills";
import Select from "components/ui/select";


@propTypes({
    onChange: PropTypes.func,
    className: PropTypes.string,
})

@connect((state) => ({}), {
    getUsers,
    getFreeDrivers
}, null, {withRef: true})

@defaultProps({
    active: false,
    onChange: () => {
    },
    className: '',
})

export default class FreeDriversList extends Component {

    state = {
        drivers: []
    };

    async componentDidMount() {
        this.loadDrivers();
    }

    async loadDrivers() {

        const result = await this.props.getFreeDrivers({});

        if (result.isOk) {
           const a = result.payload.items.map((i) => {
                return {
                    label: `${i.surname} ${i.name ? i.name.charAt(0): ''}. ${i.second_name ? i.second_name.charAt(0): ''}.`,
                    value: i.uuid,
                };
            });

            this.setState({
                drivers: a
            });

        } else {
            //result.showErrors();
        }
    }

    render() {
        return (
           <div style={{'width': '200px', 'marginTop': '-5px'}}>
               <div className="select-wrapper">
                   <Select options={this.state.drivers} placeholder="Доступные водители" />
               </div>
           </div>
        );
    }
}