import {rpc} from './session';
import _ from 'lodash';

export async function getRetrospective(data) {
    return await rpc.request('com.rnis.monitoring.action.retrospective', data);
}

export async function monitoringFilterVehicles(data) {
    return await rpc.request('com.rnis.monitoring.action.vehicles.filter', data);
}

export async function getTripList(data) {
    return await rpc.request('com.service.events.trip.list', data);
}

export async function getParkingList(data) {
    return await rpc.request('com.service.events.parking.list', data);
}

export async function getStopList(data) {
    return await rpc.request('com.service.events.stop.list', data);
}
