import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';
import _ from 'lodash';
import * as storage from 'utils/storage';
import {makeCancelable} from "helpers/promise";

const GET_LAYERS = 'GET_LAYERS';
const GET_OBJECTS = 'GET_OBJECTS';
const TOGGLE_LAYER = 'TOGGLE_LAYER';
const ACTIVATE_LAYER = 'ACTIVATE_LAYER';
const DEACTIVATE_LAYER = 'DEACTIVATE_LAYER';

const defaultLayers = {
    vehicles: true,
};

const initialState = new Map({
    layers: [],
    objects: [],
    activeLayers: storage.get('map-active-layers', defaultLayers),
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_LAYERS:
            return state.set('layers', action.payload.items);
        case GET_OBJECTS:
            return state.set('objects', action.payload.items);
        case TOGGLE_LAYER:
            let activeLayers = _.clone(state.get('activeLayers'));
            const layer = action.payload;
            activeLayers[layer] = !activeLayers[layer];
            storage.set('map-active-layers', activeLayers);
            return state.set('activeLayers', activeLayers);
        case ACTIVATE_LAYER:
            let activeLayers2 = _.clone(state.get('activeLayers'));
            const layer2 = action.payload;
            activeLayers2[layer2] = true;
            storage.set('map-active-layers', activeLayers2);
            return state.set('activeLayers', activeLayers2);
        case DEACTIVATE_LAYER:
            let activeLayers3 = _.clone(state.get('activeLayers'));
            const layer3 = action.payload;
            activeLayers3[layer3] = false;
            storage.set('map-active-layers', activeLayers3);
            return state.set('activeLayers', activeLayers3);
    }

    return state;
}

export const getLayers = (meta = {}) => async (dispatch) => {

    try {
        const response = await api.geo.getLayers(meta);

        dispatch({
            type: GET_LAYERS,
            payload: {
                items: _.sortBy(response.payload.items, 'title'),
            },
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

let currentGetObjects = null;
export const getObjects = (layers, meta = {}, zoom = null, availableFilters = null) => async (dispatch) => {

    if (!location.pathname.includes('roadparts') && !location.pathname.includes('executedworks')) {
        if (currentGetObjects) {
            currentGetObjects.cancel();
        }

        const finalLayers = availableFilters !== null ? layers.filter(el => availableFilters.includes(el)) : null;

        if (finalLayers.length === 0 || finalLayers === null) {
            dispatch({
                type: GET_OBJECTS,
                payload: {
                    items: []
                }
            });
            return;
        }

        try {
            currentGetObjects = makeCancelable(api.geo.getUserGeoObjectByLayers(finalLayers, meta));
            const response = await currentGetObjects.promise;

            dispatch({
                type: GET_OBJECTS,
                payload: {
                    items: response.payload.items
                }
            });

            return success(response);
        }
        catch (e) {
            return error(e);
        }
    }
};

export const getUserGeoObjectByLayers = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getUserGeoObjectByLayers(meta);
        return success(response);
    } catch (error) {
        return error(e);
    }
}

export const toggleLayer = (layer) => async (dispatch) => {
    dispatch({
        type: TOGGLE_LAYER,
        payload: layer,
    });
};

export const activateLayer = (layer) => async (dispatch) => {
    dispatch({
        type: ACTIVATE_LAYER,
        payload: layer,
    });
};

export const deactivateLayer = (layer) => async (dispatch) => {
    dispatch({
        type: DEACTIVATE_LAYER,
        payload: layer,
    });
};