import {createSelector} from 'reselect';

export const getDictionariesStateSelector = (state) => state.dictionary_list;

export const getSubjectsDictionarySelector = createSelector(
    [getDictionariesStateSelector],
    ({dictionaries}) => dictionaries.subjects
        ? Object.keys(dictionaries.subjects).map((key) => ({
            label: dictionaries.subjects[key].name,
            value: key
        }))
        : []
);
export const getSubjectsTypeSelector = createSelector(
    [getDictionariesStateSelector],
    ({dictionaries}) => dictionaries.task_type
        ? Object.keys(dictionaries.task_type).map((key) => ({
            label: dictionaries.task_type[key].name,
            value: key
        }))
        : []
);

export const getTaskPriorityMainSelector = createSelector(
    [getDictionariesStateSelector],
    ({dictionaries}) => dictionaries.task_priority_main
        ? Object.keys(dictionaries.task_priority_main).map((key) => ({
            label: dictionaries.task_priority_main[key].name,
            value: key
        }))
        : []
);

export const getTaskPriorityAdditionalSelector = createSelector(
    [getDictionariesStateSelector],
    ({dictionaries}) => dictionaries.task_priority_additional
        ? Object.keys(dictionaries.task_priority_additional).map((key) => ({
            label: dictionaries.task_priority_additional[key].name,
            value: key
        }))
        : []
);

