import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import Page from "components/ui/page";
import {connect} from "react-redux";
import BaseEditorFormComponent from "components/base/base-editor-form";
import {
    getVehicle, createVehicle, updateVehicle, deleteVehicle, getVehicles,
    getKursVehicleByBaseVehicle
} from "store/reducers/kurs/vehicles";
import BaseEditor from "components/base/base-editor";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";
import {getUnits} from "store/reducers/organizational_units/units";
import currentUser from 'helpers/current-user';
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getBnsoList} from "store/reducers/vehicles/bnso";
import {getVehicleBnsoList} from "store/reducers/vehicles/vehicle_bnso";
import GlobalLoaderComponent from "components/ui/global-loader";
import systems from "dictionaries/systems";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import FileReaderInput from 'react-file-reader-input';

import './style.less';
import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import {api} from "helpers/api";
import KursOperationAddForm from "components/modules/kurs/vehicles/vehicle-editor-form/operation-add-form/index";
import {deleteVehicleWork, getVehicleWorks} from "store/reducers/kurs/vehicle_works";
import TableContainer from "components/ui/Table/Container/TableContainer";
import moment from "moment";
import formats from "dictionaries/formats";
import Button from "components/ui/button";
import {getKursMaintenances} from "store/reducers/kurs/maintenances";
import {MaintenancePeriods} from "dictionaries/kurs";
import {getVehicleMechanisms, updateVehicleMechanism} from "store/reducers/kurs/vehicle_mechanisms";
import * as alerts from "helpers/alerts";
import KursMechanismAddForm from "components/modules/kurs/vehicles/vehicle-editor-form/mechanism-add-form/index";
import KursTrailerAddForm from "components/modules/kurs/vehicles/vehicle-editor-form/trailer-add-form/index";
import KursVehicleTasksForm from "components/modules/kurs/vehicles/vehicle-editor-form/tasks/index";
import Tabs from "components/ui/tabs/tabs";
import TabItem from "components/ui/tabs/tab-item";
import TabBlock from "components/ui/tabs/tab-block";
import DictionaryEditor from "components/modules/dictionaries/editor";
import {makeResponse} from "helpers/response";
import pluralize from "pluralize-ru";
import KursVehicleFuel from "components/modules/kurs/vehicles/vehicle-editor-form/vehicle-fuel/index";
import {getTasks} from "store/reducers/kurs/tasks";
import Lightbox from "react-images";
import {getVehicle as getKiutrVehicle, getVehicleMalfunctions} from "store/reducers/vehicles/vehicles";
import {formatTime} from "helpers/functions";
import {getOrders} from "store/reducers/kiutr/orders/orders";
import {getVehicleIdleTimes} from "store/reducers/routes/routes";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getUnits,
    getUsers,
    getDictionaryList,
    getVehicleWorks,
    getBnsoList,
    getKursMaintenances,
    getVehicleMechanisms,
    updateVehicleMechanism,
    deleteVehicleWork,
    getVehicles,
    getTasks,
    getVehicle,
    getKiutrVehicle,
    getVehicleMalfunctions,
    getOrders,
    getVehicleIdleTimes,
}, null, {withRef: true})

export default class VehiclesRepair extends BaseEditorFormComponent {

    state = {
        showOperationAddForm: false,
        vehicle_works: [],
        kurs_vehicle_repair_types: [],
        kurs_accounting_groups: [],
        kurs_details: [],
        from: moment().subtract(1, 'day'),
        to: moment(),
        malfunctions: [],
        idle_times: [],
    };

    operationAddSubmitCallback = null;

    async loadVehicleWorks() {
        this.setState({loading: true});
        const response = await this.props.getVehicleWorks({
            order: {
                column: 'date',
                direction: 'desc',
            },
            filters: {
                withVehicle: this.props.params.uuid,
            },
        });
        this.setState({loading: false});

        if (response.isOk) {
            this.setState({
                vehicle_works: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async componentWillMount() {
        this.loadVehicleWorks();
        this.loadVehicle();
        this.loadMalfunctions();
        this.loadIdleTimes();
        this.loadDictionaries([
            'kurs_vehicle_repair_types',
            'kurs_accounting_groups',
            'kurs_details',
        ], this.props.params.component);
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="kurs.vehicle.back" code="kurs.vehicle.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ];
    }

    onClose() {
        this.props.router.push(`/${this.props.params.component}/vehicles/vehicles/${this.props.params.uuid}`);
    }

    render() {
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        return (
            <Page
                pageId="RoadMachineryAccountingView"
                title={`${systems[this.props.params.component]} → ТС → Ремонты и учет деталей`}
                className="profile-modal"
                headerActions={this.renderHeaderActions()}
            >
                {loader}
                {this.renderDetails()}
                {this.renderMalfunctions()}
                {this.state.showOperationAddForm ? this.renderOperationAddForm() : null}
            </Page>
        );
    }

    requestOperationAdd(callback, type = 'details') {
        this.operationAddSubmitCallback = callback;
        this.setState({
            showOperationAddForm: true,
            operationAddFormType: type,
            operationAddFormUuid: null,
        });
    }

    closeOperationAdd(withSubmit = false) {
        if (withSubmit) {
            this.loadVehicleWorks();
        }
        if (withSubmit && this.operationAddSubmitCallback) {
            try {
                this.operationAddSubmitCallback();
            } catch (e) {
            }
        }
        this.operationAddSubmitCallback = null;
        this.setState({
            showOperationAddForm: false,
        });
    }

    renderOperationAddForm() {
        let maintenanceNames = [];
        (this.state.maintenance.data || []).map(function (item) {
            maintenanceNames.push(item.number);
        });
        if (!_.isEmpty(this.state.maintenance)) {
            const maintenances = _.sortBy(_.filter(this.state.vehicle_works, {type: 'maintenance'}), 'maintenance_name');
            _.each(maintenances, (maintenance) => {
                maintenanceNames.push(_.toInteger(maintenance.maintenance_name));
            });
            maintenanceNames.push(_.toInteger(_.get(_.last(maintenances), 'maintenance_name')) + 1);
        }
        maintenanceNames = _.uniq(maintenanceNames);

        maintenanceNames = _.difference(_.map(maintenanceNames, name => name.toString()), _.map(_.filter(this.state.vehicle_works, {type: 'maintenance'}), 'maintenance_name'));

        return (
            <KursOperationAddForm
                component={this.props.params.component}
                mode={this.state.operationAddFormUuid ? 'edit' : 'add'}
                uuid={this.state.operationAddFormUuid || 'create'}
                type={this.state.operationAddFormType}
                onSubmit={this.closeOperationAdd.bind(this, true)}
                onClose={this.closeOperationAdd.bind(this, false)}
                vehicleUuid={this.props.params.uuid}
                maintenanceNames={maintenanceNames}
            />
        );
    }

    renderDetails() {
        return (
            <div>
                <TabBlock withBorder={true}>
                    <div className="tab__block-title">Перечень работ</div>
                    <Block size="xl">
                        <TableContainer>
                            <div className="Table">
                                <table className="b-table">
                                    <thead>
                                    <tr>
                                        <th>Дата</th>
                                        <th>Тип работ</th>
                                        <th>Документы</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.vehicle_works.map(::this.renderVehicleWorkRow)}
                                    </tbody>
                                </table>
                            </div>
                            <span className="add-line" onClick={::this.requestOperationAdd}>
                                Добавить строку
                            </span>
                        </TableContainer>
                    </Block>
                </TabBlock>
                <TabBlock>
                    <div className="tab__block-title">Перечень з/ч</div>
                    <Block size="xl">
                        <TableContainer>
                            <div className="Table">
                                <table className="b-table">
                                    <thead>
                                    <tr>
                                        <th>Группа учета</th>
                                        <th>Наименование детали</th>
                                        <th>Номенклатурный номер</th>
                                        <th>Количество</th>
                                        <th>Тип работ</th>
                                        <th>Дата списания</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.vehicle_works.map(::this.renderVehicleDetailsRow)}
                                    </tbody>
                                </table>
                            </div>
                            <span className="add-line" onClick={::this.requestOperationAdd}>
                                Добавить строку
                            </span>
                        </TableContainer>
                    </Block>
                </TabBlock>
            </div>
        );
    }

    renderVehicleWorkRow(work) {
        return (
            <tr key={work.uuid}>
                <td><a href="javascript:void(0)"
                       onClick={this.updateVehicleWork.bind(this, work)}>{moment(work.date).format(formats.DATE)}</a>
                </td>
                <td>{(work.type === 'maintenance') ? `ТО ${work.maintenance_name}` : _.get(_.find(this.state.kurs_vehicle_repair_types, {value: work.repair_type_uuid}), 'label')}</td>
                <td>{(work.documents || []).map(::this.renderVehicleWorkDocument)}</td>
                <td><a href="javascript:void(0)" onClick={this.deleteVehicleWork.bind(this, work)}>x</a></td>
            </tr>
        );
    }

    updateVehicleWork(work) {
        this.setState({
            showOperationAddForm: true,
            operationAddFormType: 'details',
            operationAddFormUuid: work.uuid,
        });
    }

    async deleteVehicleWork(work) {
        alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            const response = await this.props.deleteVehicleWork({
                uuid: work.uuid,
            });

            if (response.isOk) {
                this.loadVehicleWorks();
            } else {
                response.showErrors();
            }
        });
    }

    renderVehicleDetailsRow(work) {
        return (work.details || []).map(this.renderVehicleDetailRow.bind(this, work));
    }

    renderVehicleDetailRow(work, detail, index) {
        return (
            <tr key={`${work.uuid}.${index}`}>
                <td>{_.get(_.find(this.state.kurs_accounting_groups, {value: detail.accounting_group_uuid}), 'label')}</td>
                <td>{_.get(_.find(this.state.kurs_details, {value: detail.detail_uuid}), 'label')}</td>
                <td>{_.get(_.find(this.state.kurs_details, {value: detail.detail_uuid}), 'document.number')}</td>
                <td>{detail.amount}</td>
                <td>{(work.type === 'maintenance') ? `ТО ${work.maintenance_name}` : _.get(_.find(this.state.kurs_vehicle_repair_types, {value: work.repair_type_uuid}), 'label')}</td>
                <td>{moment(work.date).format(formats.DATE)}</td>
            </tr>
        );
    }

    renderVehicleWorkDocument(document, index) {
        return (
            <div key={index}>
                <a href={document.file} target="_blank">{document.name}</a>
            </div>
        );
    }

    async loadVehicle() {
        let response;

        if (this.props.params.component === 'road') {
            response = await this.props.getVehicle(this.props.params.uuid);
        } else {
            response = await this.props.getKiutrVehicle(this.props.params.uuid);
        }

        if (response.isOk) {
            this.loadMaintenance(response.payload);
        } else {
            response.showErrors();
        }
    }

    async loadMaintenance(vehicle) {
        const response = await this.props.getKursMaintenances({
            filters: {
                withModel: _.get(vehicle, 'vehicle_model_uuid'),
                withMark: _.get(vehicle, 'vehicle_mark_uuid'),
            },
        });
        if (response.isOk) {
            const maintenance = _.first(response.payload.items) || {};
            await this.setState({
                maintenance,
            });
        } else {
            response.showErrors();
        }
    }

    async setValue(field, value) {
        super.setValue(field, value);

        this.loadMalfunctions();
        this.loadIdleTimes();
    }

    async loadMalfunctions() {
        const response = await this.props.getVehicleMalfunctions({
            filters: {
                withVehicle: this.props.params.uuid,
                withPeriod: [
                    moment(this.state.from).startOf('day').format(formats.DATETIME_API),
                    moment(this.state.to).endOf('day').format(formats.DATETIME_API),
                ],
            },
            order: {
                column: 'date_from',
                direction: 'asc',
            },
        });

        if (response.isOk) {
            this.setState({
                malfunctions: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadIdleTimes() {
        const response = await this.props.getVehicleIdleTimes({
            filters: {
                withVehicle: this.props.params.uuid,
                withPeriod: [
                    moment(this.state.from).startOf('day').format(formats.DATETIME_API),
                    moment(this.state.to).endOf('day').format(formats.DATETIME_API),
                ],
            },
            order: {
                column: 'date_from',
                direction: 'asc',
            },
        });

        if (response.isOk) {
            this.setState({
                idle_times: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    renderMalfunctions() {
        return (
            <div>
                <TabBlock withBorder={true}>
                    <Block size="md" title="Дата с">
                        {this.datepicker('from')}
                    </Block>
                    <Block title="Дата по">
                        {this.datepicker('to')}
                    </Block>
                    <Block size="xl">
                        <TableContainer>
                            <div className="Table">
                                <table className="b-table">
                                    <thead>
                                    <tr>
                                        <th>Событие</th>
                                        <th>Начало</th>
                                        <th>Окончание</th>
                                        <th>Длительность</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.getMalfunctions().map(::this.renderMalfunctionRow)}
                                    </tbody>
                                </table>
                            </div>
                        </TableContainer>
                    </Block>
                </TabBlock>
            </div>
        );
    }

    renderMalfunctionRow(malfunction, index) {
        return (
            <tr key={index}>
                <td>{malfunction.type}</td>
                <td>{moment(malfunction.from).format(formats.DATETIME_SHORT)}</td>
                <td>{malfunction.to ? moment(malfunction.to).format(formats.DATETIME_SHORT) : '-'}</td>
                <td>{formatTime(moment(malfunction.to).diff(moment(malfunction.from), 'minutes'))}</td>
            </tr>
        );
    }

    getMalfunctions () {
        let malfunctions =_.map(this.state.malfunctions, (malfunction) => {
            return {
                type: 'Ремонт',
                from: malfunction.date_from,
                to: malfunction.date_to,
            };
        });

        malfunctions = _.concat(malfunctions, _.map(_.filter(this.state.idle_times, (idleTime) => {
            return moment(idleTime.date_from).isSameOrBefore(moment());
        }), (idleTime) => {
            return {
                type: 'Простой',
                from: idleTime.date_from,
                to: idleTime.date_to,
            };
        }));

        return _.sortBy(malfunctions, 'from');
    }

}