import React from 'react';

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "../../../../ui/form/block";

import {
    createSmsDevice, getSmsDevice, updateSmsDevice
} from "store/reducers/sms/sms";

import {connect} from "react-redux";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";


@connect(state => ({}), { createSmsDevice, getSmsDevice, updateSmsDevice })

export default class SmsDeviceEditor extends BaseEditor {

    title = 'устройства для СМС шлюза';

    componentDidMount() {
        this.forceUpdate();
    }

    async loadData(uuid) {
        return await this.props.getSmsDevice({ uuid: uuid });
    }

    async createItem(data) {
        let { provider_device_id, title, is_default } = data;
        if (!this.props.devices) {
            is_default = true
        }
        return await this.props.createSmsDevice({ provider_device_id, title, is_default })
    }

    async updateItem(data) {
        const { uuid, provider_device_id, title, is_default } = data;
        return await this.props.updateSmsDevice({ uuid, provider_device_id, title, is_default });
    }

    getForm(item, onSubmit) {
        return (
            <div>
                <EditorForm
                    {...this.props}
                    uuid={this.props.uuid}
                    ref="form"
                    mode={this.props.mode}
                    onSubmit={onSubmit}
                    onClose={this.props.onClose}
                    onDataLoad={::this.forceUpdate}
                    data={item}
                    errors={this.state.errors}
                />
            </div>
        );
    }

    printButton() {
        return null;
    }

}

@connect((state) => ({}), {}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {};

    getData() {
        return this.state;
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} single={true}>
                        <Block size="xl" title="ID устройства">
                            {this.textInput(`provider_device_id`, {
                                type: 'number',
                                positive: true,
                            })}
                        </Block>
                        <Block size="lg" title="Наименование">
                            {this.textInput(`title`)}
                        </Block>
                        <Block size="xl">
                            {this.checkbox('is_default', 'Устройство по умолчанию')}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}
