import React, {Component} from 'react';
export default class RouteRegisterPrintComponent extends Component {


    render() {
        return this.renderPrintForm();
    }

    renderPrintForm() {
        return (
            <div>
                <div className="form-title">КАРТА МАРШРУТА РЕГУЛЯРНЫХ ПЕРЕВОЗОК</div>
                <div className="org-name">
                    <div>выдана "{this.props.org_name}"</div>
                </div>
                <div className="contract">
                    <div className="contract-status">
                        {this.props.contract.status}
                    </div>
                    <div className="contract-dates">
                        c {this.props.contract.dates.startDay} {this.props.contract.dates.startYear} по
                        {this.props.contract.dates.endDay} {this.props.contract.dates.endYear}
                    </div>
                </div>
                <div className="Table">
                    <table>
                        <tr>
                            <td rowSpan="2" className="left">1. Маршрут</td>
                            <td colSpan="2">Регистрационный номер в реестре</td>
                            <td colSpan="2">Порядковый номер</td>
                            <td>Наименование</td>
                        </tr>
                        <tr>
                            <th colSpan="2">{this.props.registration_number}</th>
                            <th colSpan="2">{this.props.number}</th>
                            <th>{this.props.title}</th>
                        </tr>

                        <tr>
                            <td rowSpan="2" className="left">2. Перевозчик</td>
                            <td colSpan="2">Наименование (Ф.И.О.)</td>
                            <td colSpan="2">Место нахождения</td>
                            <td>ИНН</td>
                        </tr>
                        <tr>
                            <th colSpan="2">{this.props.carrier || "-"}</th>
                            <th colSpan="2">{this.props.place || "-"}</th>
                            <th>{this.props.inn || "-"}</th>
                        </tr>

                        <tr>
                            <td className="left">3. Вид транспортного средства</td>
                            <th>{this.props.tstype || "-"}</th>
                            <td className="left">4. Класс транспортного средства</td>
                            <th>{this.props.tsclass || "-"}</th>
                            <td className="left">5. Экологические характеристики</td>
                            <th>{this.props.eco || "-"}</th>
                        </tr>
                        <tr>
                            <td className="left">6. Характеристики транспортного средства</td>
                            <th colSpan="5">{this.props.properties || "не предусмотрены"}</th>
                        </tr>
                    </table>

                    <div className="bottom">
                        <div className="stamp">М.П.</div>
                        <div className="sign">(подпись)</div>
                        <div className="initials">(Ф.И.О.)</div>
                    </div>
                </div>

            </div>
        );
    }
}
