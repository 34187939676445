import React, {Component} from "react";
import {connect} from "react-redux";

import Page from "components/ui/page";
import Loader from "components/ui/loader";
import Button from "components/ui/button";
import BaseEditorFormComponent from "components/base/base-editor-form";

import {get, forEach, isBoolean, merge} from 'lodash';
import currentUser from 'helpers/current-user';

import GlobalLoaderComponent from "components/ui/global-loader";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import {createConfirmRequest, getConfirmRequest, updateConfirmRequest} from "store/reducers/portal/confirm_requests";
import _ from 'lodash';
import Block from "components/ui/form/block";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import TableContainer from "components/ui/Table/Container/TableContainer";
import moment from "moment";
import formats from "dictionaries/formats";
import './index.less';
import {State} from "components/ui/state";
import {getTelematicsWithDevices} from "store/reducers/maps";
import {
    documentCooperationAgreement, getCooperationAgreements,
    updateCooperationAgreement
} from "store/reducers/portal/cooperation_agreements";
import {getTemplateDocuments} from "store/reducers/portal/template_documents";
import * as alerts from "helpers/alerts";
import ContextTooltip from "components/ui/context-tooltip";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import systems from "dictionaries/systems";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Select from "components/ui/select";
import Script from 'react-load-script';
import * as rfc2253 from 'rfc2253';
import EasyXml from 'easyxml';
import {xmlVerify} from "store/reducers/ecp/ecp";
import ReactTooltip from "components/ui/ReactTooltip/index";
import Input from "components/ui/form/input";
import download from 'downloadjs';

@connect(state => ({}), {
    getCooperationAgreements,
    updateCooperationAgreement,
    getTemplateDocuments,
    getUnit,
    getUnits,
    getEntityNames,
    xmlVerify,
    documentCooperationAgreement,
})

export default class UnitProfileAgreementSign extends Component {

    state = {
        loading: true,
        saving: false,
        errors: {},
        agreement: null,
        unit: null,
        operator: null,
        related: new EntityList,
        certificates: [],
        selectedCertificate: null,
        company_signed_status: false,
        loaded: [],
        certificate: {},
    };

    cryptopro = null;

    async componentWillMount() {
        await this.loadAgreement();
        this.loadRelated();
        this.loadUnit();
        this.loadOperator();
    }

    onCadesInit(number) {
        let loaded = this.state.loaded;
        loaded.push(number);
        this.setState({loaded});

        if (_.uniq(loaded).length === 3) {
            this.cryptopro = new cadesPluginWrapper(
                ::this.cadesOnLoad,
                (error) => {
                    this.cryptoproError(error);
                }
            );
        }
    }

    cryptoproError(error) {
        if (error === 'Плагин недоступен') {
            error = 'Плагин недоступен. Необходимо установить "КриптоПро".';
        }
        alerts.error('КриптоПро: ' + error);
    }

    cadesOnLoad() {
        this.cryptopro.CheckForPlugIn((version) => {
            this.cryptopro.GetCertificates((list) => {
                if (list.length === 0) {
                    this.cryptoproError('не найден сертификат');
                    return;
                }

                const certificates = _.filter(list, (cert) => {
                    return cert.certInstance.IsValid();
                });

                this.setState({
                    certificates: certificates.map((cert, index) => {
                        const cn = this.getEcpPart(cert.subjectName, 'CN');
                        const ogrn = this.getEcpPart(cert.subjectName, 'ОГРН');
                        let inn = this.getEcpPart(cert.subjectName, 'ИНН');
                        const isPrivate = (inn && inn.substr(0, 1) !== '0') && !ogrn;
                        inn = inn.replace(/^[0]+/, '')
                        const sn = this.getEcpPart(cert.subjectName, 'SN');
                        const g = this.getEcpPart(cert.subjectName, 'G');
                        let label = cn;
                        if (inn && sn && g && !isPrivate) {
                            label = `${cn} (ИНН ${inn}, ${sn} ${g})`;
                        } else if (inn) {
                            label = `${cn} (ИНН ${inn})`;
                        }

                        return {
                            label,
                            value: index,
                            certificate: cert,
                        };
                    }),
                });
            });
        }, (error) => {
            this.cryptoproError(error);
        })
    }

    getEcpPart(subject, part) {
        return rfc2253.parse(subject).get(part) || '';
    }

    async loadRelated() {
        const {agreement} = this.state;
        const userUuids = [
            agreement.user_uuid,
            agreement.operator_uuid,
        ];
        const users = _.map(userUuids, (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));

        const response = await this.props.getEntityNames(users);

        if (response.isOk) {
            this.state.related.add(response);
        }
    }

    async loadAgreement() {
        const response = await this.props.getCooperationAgreements({
            filters: {
                withUuid: [this.props.params.uuid],
            },
        });

        this.setState({
            loading: false,
        });

        if (response.isOk) {
            const agreement = _.first(response.payload.items);
            await this.setState({
                agreement,
            });

            this.verifySign(agreement);
        } else {
            response.showErrors();
        }
    }

    async loadUnit() {
        const response = await this.props.getUnit(this.state.agreement.unit_uuid);

        if (response.isOk) {
            this.setState({
                unit: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadOperator() {
        const response = await this.props.getUnits({
            column_search: [
                {
                    column: 'name',
                    value: 'ГКУ "ЦБДДМО"',
                },
            ],
        });

        if (response.isOk) {
            const operator = _.first(response.payload.items);
            if (!operator) {
                alerts.error('Не найдено предприятие оператора РНИС');
                return;
            }
            this.setState({
                operator,
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;

        return (
            <Page pageId="Main" title={`Профиль организации → Соглашение о взаимодействии с оператором`}>
                {loader}
                {this.renderContent()}
            </Page>
        )
    }

    renderContent() {
        const {agreement, unit, operator} = this.state;

        if (!agreement || !unit || !operator) {
            return null;
        }

        return (
            <div className="AgreementCooperation AgreementInteraction">
                <Script url="/js/cryptopro/cadesplugin_api.js" onLoad={this.onCadesInit.bind(this, 1)}/>
                <Script url="/js/cryptopro/cadesplugin_wrapper.js" onLoad={this.onCadesInit.bind(this, 2)}/>
                <Script url="/js/cryptopro/cadesplugin_wrapper_async.js" onLoad={this.onCadesInit.bind(this, 3)}/>

                <div className="b-modal-agreement">
                    <div className="b-modal-agreement_header">
                        <div className="b-block_left">
                            <span>
                                <span
                                    className="b-modal-agreement_title b-modal-agreement_title_grey">Полный текст <br/> соглашения:</span>
                                <a href="javascript:void(0)" onClick={::this.getDocument}
                                   className="b-modal_sign b-modal_sign-3"/>
                            </span>
                        </div>
                        <div className="b-block_right">
                            <div>
                                <span className="b-modal-agreement_title b-modal-agreement_title_grey">
                                    Оператор РНИС:
                                </span>
                                {agreement.is_signed_by_operator ? (
                                    (this.state.operator_signed_status) ? (
                                        <ReactTooltip
                                            title={`${this.state.related.get(agreement.operator_uuid)} ${moment(agreement.signed_by_operator_date).format(formats.DATE)}`}>
                                            <span>
                                                <span><span className="b-modal_checked"></span></span>
                                                <span><span
                                                    className="b-modal_resolution b-modal_resolution-1">Подписано</span></span>
                                            </span>
                                        </ReactTooltip>
                                    ) : (
                                        <ReactTooltip
                                            title={`${this.state.related.get(agreement.operator_uuid)} ${moment(agreement.signed_by_operator_date).format(formats.DATE)}`}>
                                            <span>
                                                <span><span className="b-modal_no-checked"></span></span>
                                                <span><span className="b-modal_resolution b-modal_resolution-2">Подписано, подпись невалидна</span></span>
                                            </span>
                                        </ReactTooltip>
                                    )
                                ) : ([
                                    <span key="state"><span className="b-modal_no-checked"></span></span>,
                                    <span key="text"><span className="b-modal_resolution b-modal_resolution-2">Не подписано</span></span>,
                                ])}
                            </div>
                            <div>
                                <span className="b-modal-agreement_title b-modal-agreement_title_grey">
                                    Подключаемая <br/> организация:
                                </span>
                                {agreement.is_signed_by_company ? (
                                    (this.state.company_signed_status) ? (
                                        <ReactTooltip
                                            title={`${this.state.related.get(agreement.user_uuid)} ${moment(agreement.signed_by_company_date).format(formats.DATE)}`}>
                                            <span>
                                                <span><span className="b-modal_checked"></span></span>
                                                <span><span
                                                    className="b-modal_resolution b-modal_resolution-1">Подписано</span></span>
                                            </span>
                                        </ReactTooltip>
                                    ) : (
                                        <ReactTooltip
                                            title={`${this.state.related.get(agreement.user_uuid)} ${moment(agreement.signed_by_company_date).format(formats.DATE)}`}>
                                            <span>
                                                <span><span className="b-modal_no-checked"></span></span>
                                                <span><span className="b-modal_resolution b-modal_resolution-2">Подписано, подпись невалидна</span></span>
                                            </span>
                                        </ReactTooltip>
                                    )
                                ) : ([
                                    <span key="state"><span className="b-modal_no-checked"></span></span>,
                                    <span key="text"><span className="b-modal_resolution b-modal_resolution-2">Не подписано</span></span>,
                                ])}
                                <div className="b-modal_button" onClick={::this.sign}>Подписать от лица <br/>
                                    подключаемой организации
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="b-block _md">
                        <span className="b-block__title b-block__title__margin">Срок действия:</span>
                        <span className="b-block__text">
                            до {moment(agreement.expiration_date).format(formats.DATE)}
                        </span>
                    </div>
                    <div className="b-block _lg">
                        <span className="b-block__title b-block__title__margin">Область действия:</span>
                        <span className="b-block__text">{systems[agreement.component]}</span>
                    </div>
                    <Block size="xl">
                        <Block title="ФИО представителя организации (р. падеж)">
                            <Input value={_.get(this.state.agreement, 'fio_genitive')} size="md"
                                   onChange={this.onChange.bind(this, 'fio_genitive')}
                                   error={this.getError('agreement.fio_genitive')}
                                   placeholder="Иванова Ивана Ивановича"/>
                        </Block>
                        <Block className="custom" title="Должность представителя организации (р. падеж)">
                            <Input value={_.get(this.state.agreement, 'position_genitive')} size="md"
                                   onChange={this.onChange.bind(this, 'position_genitive')}
                                   error={this.getError('agreement.position_genitive')}
                                   placeholder="директора"/>
                        </Block>
                        <Block title="Основание для действия от лица организации">
                            <Input value={_.get(this.state.agreement, 'reason_genitive')} size="md"
                                   onChange={this.onChange.bind(this, 'reason_genitive')}
                                   error={this.getError('agreement.reason_genitive')}
                                   placeholder="устава"/>
                        </Block>
                    </Block>
                </div>
                <Accordion>
                    <AccordionItem opened={true} title="Стороны соглашения">
                        <div className="accordion__content-header">
                            <div className="b-block _lg">
                                <div className="b-block__title-main">Профильная организация</div>
                            </div>
                            <div className="b-block _lg">
                                <div className="b-block__title-main">Оператор РНИС</div>
                            </div>
                        </div>

                        {_.map({
                            'name': 'Наименование',
                            'inn': 'ИНН',
                            'ogrn': 'ОГРН',
                            'actual_address': 'Адрес фактический',
                            'senior': 'ФИО директора',
                            'phone': 'Телефон',
                            'email': 'Email',
                        }, (name, field) => ([
                            <div key={`${field}.name`} className="b-block _sm">
                                <div className="b-block__title">{name}</div>
                            </div>,
                            <div key={`${field}.value`} className="b-block _sm">
                                <div className="b-block__text">{unit[field]}</div>
                            </div>,
                            <div key={`${field}.operator.name`} className="b-block _sm">
                                <div className="b-block__title">{name}</div>
                            </div>,
                            <div key={`${field}.operator.value`} className="b-block _sm">
                                <div className="b-block__text">{operator[field]}</div>
                            </div>,
                        ]))}
                        <div className="b-block_representative">
                            <div className="b-block _xl">
                                <div className="b-block__title-main">Представитель организации</div>
                            </div>
                            <div className="b-block _lg">
                                <div className="b-block_signature">
                                    <div className="b-block__title">Сертификат ЭЦП</div>
                                    <Select
                                        options={this.state.certificates}
                                        value={this.state.selectedCertificate}
                                        onChange={::this.onCertificateSelect}
                                    />
                                </div>
                            </div>
                            {this.renderCertificateInfo()}
                        </div>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    getError(fieldName) {
        const field = _.findLast(fieldName.split('.'));

        return _.get(this.state.errors, field) || _.get(this.state.errors, fieldName);
    }

    onChange(field, {target: {value}}) {
        let agreement = this.state.agreement;
        _.set(agreement, field, value);
        this.setState({agreement});
    }

    renderCertificateInfo() {
        const certificate = this.getSelectedCertificate();

        if (!certificate) {
            return null;
        }

        return (
            <div className="accordion__posit electron-signature">
                <div className="b-block _sm">
                    <div className="b-block__title">Сертификат ЭЦП</div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__text">{this.get('certificate.serial') ||
                    <span className="error">Не найдено</span>}</div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__title">Наименование</div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__text">
                        {this.get('certificate.name') || <span className="error">Не найдено</span>}
                    </div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__title">Действителен до</div>
                </div>
                <div className="b-block _sm">
                    <div
                        className="b-block__text">{this.get('certificate.valid_to') ? moment(this.get('certificate.valid_to')).format(formats.DATE) : (
                        <span className="error">Не найдено</span>
                    )}</div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__title">ИНН</div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__text">
                        {this.get('certificate.inn') || <span className="error">Не найдено</span>}
                    </div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__title">Выдан</div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__text">
                        {this.get('certificate.issuer') || <span className="error">Не найдено</span>}
                    </div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__title">ОГРН</div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__text">
                        {this.get('certificate.ogrn') || <span className="error">Не найдено</span>}
                    </div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__title">Владелец</div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__text">
                        {this.get('certificate.owner') || <span className="error">Не найдено</span>}
                    </div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__title">Адрес регистрации</div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__text">
                        {this.get('certificate.address') || <span className="error">Не найдено</span>}
                    </div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__title"></div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__text"></div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__title">Должность</div>
                </div>
                <div className="b-block _sm">
                    <div className="b-block__text">
                        {this.get('certificate.position') || <span className="error">Не найдено</span>}
                    </div>
                </div>
            </div>
        );
    }

    getSelectedCertificate() {
        return _.find(this.state.certificates, {value: this.state.selectedCertificate});
    }

    setValue(field, value) {
        let state = this.state;
        _.set(state, field, value);
        this.setState(state);
    }

    get(field) {
        return _.get(this.state, field);
    }

    async onCertificateSelect(e) {
        const value = e ? e.value : null;

        await this.setState({
            selectedCertificate: value,
        });
        const certificate = _.get(this.getSelectedCertificate(), 'certificate');

        this.setValue('certificate.serial', certificate ? certificate.thumbprint : null);
        this.setValue('certificate.valid_to', certificate ? moment(certificate.certTillDate).format(formats.DATE_API) : null);
        this.setValue('certificate.issuer', certificate ? this.getEcpPart(certificate.issuerName, 'CN') : null);
        this.setValue('certificate.owner', certificate ? _.filter([this.getEcpPart(certificate.subjectName, 'SN'), this.getEcpPart(certificate.subjectName, 'G')]).join(' ') : null);
        this.setValue('certificate.name', certificate ? this.getEcpPart(certificate.subjectName, 'CN') : null);
        this.setValue('certificate.inn', certificate ? this.parseINN(
            this.getEcpPart(certificate.subjectName, 'ИНН')
            ||
            this.getEcpPart(certificate.subjectName, 'INN')
        ) : null);
        this.setValue('certificate.ogrn', certificate ? (
            this.getEcpPart(certificate.subjectName, 'ОГРН')
            ||
            this.getEcpPart(certificate.subjectName, 'OGRN')
        ) : null);
        this.setValue('certificate.address', certificate ? _.filter([this.getEcpPart(certificate.subjectName, 'L'), this.getEcpPart(certificate.subjectName, 'STREET') || this.getEcpPart(certificate.subjectName, 'ST')]).join(' ') : null);
        this.setValue('certificate.position', certificate ? (this.getEcpPart(certificate.subjectName, 'TITLE') || this.getEcpPart(certificate.subjectName, 'T')) : null);
    }

    parseINN(inn) {
        return inn.replace(/^([0]+)/, '')
    }

    renderUnit(unit) {
        return (
            <div>
                <Block title="Наименование">
                    {unit.name}
                </Block>
                <Block title="ИНН">
                    {unit.inn}
                </Block>
                <Block title="ОГРН">
                    {unit.ogrn}
                </Block>
                <Block title="Адрес фактический">
                    {unit.actual_address}
                </Block>
                <Block title="ФИО директора">
                    {unit.senior}
                </Block>
                <Block title="Конт. телефон">
                    {unit.phone}
                </Block>
                <Block title="Эл. почта">
                    {unit.email}
                </Block>
            </div>
        );
    }

    getXml(item = null) {
        const easyXml = new EasyXml({
            singularize: true,
            rootElement: 'Envelope',
            manifest: true,
        });
        item = item || this.state.agreement;
        const data = {
            uuid: item.uuid,
            template_document_uuid: item.template_document_uuid,
            unit_uuid: item.unit_uuid,
            document_number: item.document_number,
            expiration_date: item.expiration_date,
            document_url: item.document_url,
            component: item.component,
        };
        let xml = easyXml.render({
            _xmlns: 'urn:envelope',
            Data: data,
        });
        xml = xml.replace(/\s{2}/g, '');
        return xml;
    }

    async sign() {
        const certificate = this.getSelectedCertificate();

        if (!certificate) {
            alerts.alert('Необходимо выбрать сертификат');
            return;
        }

        let agreement = _.cloneDeep(this.state.agreement);
        agreement.generate_document = true;
        agreement.fio = this.get('certificate.owner') || null;
        agreement.position = this.get('certificate.position') || null;

        const response = await this.props.updateCooperationAgreement(agreement);
        if (response.isOk) {
            await this.setState({
                agreement: response.payload,
            });

            this.signReal();
        } else {
            response.showErrors();
            this.setState({
                errors: response.validationErrors,
            });
        }
    }

    async signReal() {
        const certificate = this.getSelectedCertificate();

        if (!certificate) {
            alerts.alert('Необходимо выбрать сертификат');
            return;
        }

        this.setState({loading: true});

        const xml = this.getXml();

        this.cryptopro.SignXml(xml, certificate.certificate.certInstance, async (result) => {
            const start = result.indexOf('<Signature');
            const end = result.indexOf('</Signature>') + '</Signature>'.length;

            const ecp_sign = result.substr(start, end - start);
            let item = this.state.agreement;
            item.company_sign = ecp_sign;
            const response = await this.props.updateCooperationAgreement(item);
            this.setState({loading: false});
            if (response.isOk) {
                this.props.router.push('/profile/unit');
            } else {
                alerts.error('Ошибка подписи');
            }
        }, (error) => {
            this.setState({loading: false});
            this.cryptoproError(error);
        });
    }

    async verifySign(item) {
        this.verifySignByField(item, 'company_sign', 'company_signed_status', 'company_ecp');
        this.verifySignByField(item, 'operator_sign', 'operator_signed_status', 'operator_ecp');
    }

    async verifySignByField(item, field, stateField, ecpField) {
        const sign = item[field];
        if (!sign) {
            let state = this.state;
            state[stateField] = false;
            this.setState(state);
            return;
        }

        let xml = this.getXml(_.clone(item));
        const signPosition = xml.indexOf('</Data>') + '</Data>'.length;
        xml = xml.substr(0, signPosition + 1) + sign + xml.substr(signPosition + 1);

        xml = xml.replace(/\s{2}/g, '');

        const response = await this.props.xmlVerify(xml);
        if (response.isOk) {
            let state = this.state;
            state[stateField] = response.payload.isSignatureValid && response.payload.certificateInfo.expiredCheck.code === 'VALID';
            state[ecpField] = _.get(response.payload, 'certificateInfo');
            this.setState(state);
        }
    }

    getCN(text) {
        const matches = /CN=([^,]+)(,|$)/.exec(text);
        if (matches) {
            return matches[1].replace(/\\"/g, '"');
        }
        return '';
    }

    async getDocument() {
        let agreement = _.cloneDeep(this.state.agreement);

        agreement.fio = this.get('certificate.owner') || null;
        agreement.position = this.get('certificate.position') || null;

        const companyEcp = this.state.company_ecp;
        const operatorEcp = this.state.operator_ecp;

        agreement.company_ecp = {
            UNIT_ECP_SERIAL: _.get(companyEcp, 'thumbprint'),
            UNIT_ECP_ISSUER: this.getCN(_.get(companyEcp, 'issuerName')),
            UNIT_ECP_VALID_FROM: moment(_.get(companyEcp, 'certFromDate')).format(formats.DATE),
            UNIT_ECP_VALID_TO: moment(_.get(companyEcp, 'certTillDate')).format(formats.DATE),
            UNIT_ECP_NAME: this.getCN(_.get(companyEcp, 'subjectName')),
        };

        agreement.operator_ecp = {
            OPERATOR_ECP_SERIAL: _.get(operatorEcp, 'thumbprint'),
            OPERATOR_ECP_ISSUER: this.getCN(_.get(operatorEcp, 'issuerName')),
            OPERATOR_ECP_VALID_FROM: moment(_.get(operatorEcp, 'certFromDate')).format(formats.DATE),
            OPERATOR_ECP_VALID_TO: moment(_.get(operatorEcp, 'certTillDate')).format(formats.DATE),
            OPERATOR_ECP_NAME: this.getCN(_.get(operatorEcp, 'subjectName')),
        };

        const response = await this.props.documentCooperationAgreement(agreement);

        if (response.isOk) {
            download(`data:application/pdf;base64,${response.payload.content}`, `Соглашение о взаимодействии.pdf`);
        } else {
            response.showErrors();
        }
    }
}