import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import PageModal from 'components/ui/page-modal';
import {connect} from "react-redux";

import './MapFilterResult.less';
import Accordion from "components/ui/accordion/accordion";
import classNames from 'classnames';
import AccordionItem from "components/ui/accordion/accordion-item";
import {getOrders} from "store/reducers/kiutr/orders/orders";
import {getTasks} from "store/reducers/kurs/tasks";
import moment from "moment";
import formats from "dictionaries/formats";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import Radio from "components/ui/form/radio";
import {getRoutes} from "store/reducers/routes/routes";
import {Slider} from "components/ui/slider";

@propTypes({
    visible: PropTypes.bool,
    component: PropTypes.string,
    vehicles: PropTypes.array,
    vehicle_types: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
    onPlayClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
})

@connect(state => ({}), {
    getOrders,
    getTasks,
    getEntityNames,
})

export default class MapFilterResult extends Component {

    state = {
        page: 1,
        page_route: 1,
        orders: [],
        tasks: [],
        related: new EntityList,
        type: 'vehicle',
    };

    perPage = 20;

    componentWillUpdate(props) {
        if (!_.isEqual(props.vehicles, this.props.vehicles)) {
            this.loadWorks(props);
        }
    }

    async loadWorks(props) {
        const {component, vehicles} = props;
        if (vehicles.length === 0) {
            this.setState({
                orders: [],
                tasks: [],
            });
            return;
        }

        switch (component) {
            case 'kiutr':
            case 'children':
            case 'ems':
                this.loadOrders(vehicles);
                break;
            case 'road':
                this.loadTasks(vehicles);
                break;
        }
    }

    async loadOrders(vehicles) {
        const response = await this.props.getOrders({
            filters: {
                withDate: moment().format(formats.DATE_API),
                withVehicles: _.map(vehicles, 'uuid'),
            },
        });

        if (response.isOk) {
            await this.setState({
                orders: response.payload.items,
                tasks: [],
            });
            this.loadRelated();
        } else {
            response.showErrors();
        }
    }

    async loadTasks(vehicles) {
        const response = await this.props.getTasks({
            filters: {
                today: true,
                withBaseVehicles: _.map(this.getVehiclesForCurrentPage(vehicles), 'uuid'),
            },
        });

        if (response.isOk) {
            await this.setState({
                tasks: response.payload.items,
                orders: [],
            });
            this.loadRelated();
        } else {
            response.showErrors();
        }
    }

    async loadRelated() {
        const carriers = _.map(_.uniq(_.filter(_.map(this.state.orders, 'carrier_uuid'))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const units = _.map(_.uniq(_.filter(_.map(this.state.tasks, 'unit_uuid'))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(_.concat(carriers, units));

        if (response.isOk) {
            this.state.related.add(response);
            this.forceUpdate();
        }
    }

    onTypeChange({target: {value}}) {
        this.setState({
            type: value,
        });
    }

    isKiutrOrChildren() {
        return _.indexOf([
            'kiutr',
            'children',
            'ems',
        ], this.props.component) !== -1;
    }

    isRoute() {
        return this.state.type === 'route';
    }

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{
                    rawTitle: (
                        <div className="title">
                            <div className="title__name">Объекты мониторинга</div>
                            {this.isKiutrOrChildren() ? (
                                <div className="title__date">
                                    <ContextTooltip default="ТС">
                                        <Radio
                                            checked={this.state.type === 'vehicle'}
                                            label={<span
                                                className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_ts"/>}
                                            value="vehicle"
                                            onChange={::this.onTypeChange}
                                        />
                                    </ContextTooltip>
                                    <ContextTooltip default="Маршруты">
                                        <Radio
                                            checked={this.state.type === 'route'}
                                            label={<span
                                                className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_maneuver"/>}
                                            value="route"
                                            onChange={::this.onTypeChange}
                                        />
                                    </ContextTooltip>
                                </div>
                            ) : null}
                        </div>
                    ), buttons
                }}
                className={classNames('b-modal-trip-planner-result b-modal-trip-planner-result-panel monitoring b-modal_w-footer', {
                    hide: !this.props.visible,
                })}
                withFade={false}
                buttons={[
                    <Slider
                        key="display-on-map"
                        title="Отобразить на карте"
                        selected={this.props.displayedOnMap ? 1 : 0}
                        total={1}
                        selectAll={this.props.displayOnMap}
                        deselectAll={this.props.hideOnMap}
                    />
                ]}
            >
                <Accordion>
                    {this.isRoute() ? this.renderRoutes() : this.renderVehicles()}
                </Accordion>

                <div className="history__footer">
                    {this.isRoute() ? (
                        <div className="history__statistics">
                            TC/в работе
                            <span>{this.props.vehicles.length}/{this.getVehiclesInWorkCount()}</span>
                        </div>
                    ) : null}
                    <div className="history__pagination">
                        {this.renderPages()}
                    </div>
                </div>
            </PageModal>
        )
    }

    getVehiclesInWorkCount() {
        return _.filter(this.props.vehicles, (vehicle) => {
            return this.getOrders(vehicle).length > 0;
        }).length;
    }

    renderRoutes() {
        const routes = _.uniq(_.map(this.state.orders, 'route_uuid'));

        const pageRoutes = routes.slice((this.state.page_route - 1) * this.perPage, this.state.page_route * this.perPage);
        let result = _.map(pageRoutes, ::this.renderRoute);

        if (this.state.page_route === this.getPagesCount()) {
            result.push(this.renderVehiclesNotInWork());
        }

        return result;
    }

    renderVehiclesNotInWork() {
        const vehicles = _.filter(this.props.vehicles, (vehicle) => {
            return this.getOrders(vehicle).length === 0;
        });

        return (
            <AccordionItem
                key="not-in-work"
                label={(
                    <div className="accordion__route transport_info">
                        <div className="transport_title">
                            <div className="transport_num-route">ТС не в работе</div>
                        </div>
                    </div>
                )}
            >
                {_.map(vehicles, (vehicle) => {
                    return (
                        <div key={vehicle.uuid} className="transport_row history__info">
                            <div className="history__icon-block">
                                <i className="history__icon b-icon-link b-icon-link_params b-icon-link_icon_bus"/>
                            </div>
                            <div className="history__header">
                                <div className="history__auto">
                                    {_.get(_.get(this.props.vehicle_types, vehicle.vehicle_type_uuid), 'name')}
                                    <i>{vehicle.state_number}</i>
                                </div>
                            </div>
                            <ContextTooltip default="Плеер">
                                <a href="javascript:void(0)" className="transport_play"
                                   onClick={this.onPlayClick.bind(this, vehicle)}>
                                    <i className="b-icon-link b-icon-link_params b-icon-link_icon_play"/>
                                </a>
                            </ContextTooltip>
                            <ContextTooltip default="История">
                                <a href="javascript:void(0)" className="history__button"
                                   onClick={this.onHistoryClick.bind(this, vehicle)}>
                                    <i className="b-icon-link b-icon-link_params b-icon-link_icon_history"/>
                                </a>
                            </ContextTooltip>
                        </div>
                    );
                })}
            </AccordionItem>
        );
    }

    renderRoute(routeUuid) {
        const order = _.find(this.state.orders, {route_uuid: routeUuid});

        return (
            <AccordionItem
                key={routeUuid}
                label={(
                    <div className="accordion__route transport_info">
                        <div className="transport_title">
                            <div className="transport_num-route">Маршрут {order.route_number}
                                ({order.route_registration_number})
                            </div>
                            <div className="transport_route">
                                {order.route_name}
                            </div>
                        </div>
                    </div>
                )}
            >
                {this.renderRouteVehicles(routeUuid)}
            </AccordionItem>
        );
    }

    renderRouteVehicles(routeUuid) {
        const vehicleUuids = _.uniq(_.map(_.flatten(_.map(_.flatten(_.map(_.filter(this.state.orders, {route_uuid: routeUuid}), 'shifts')), 'runs')), 'vehicle_uuid'));

        return _.map(_.filter(this.props.vehicles, (vehicle) => {
            return _.indexOf(vehicleUuids, vehicle.uuid) !== -1;
        }), this.renderRouteVehicle.bind(this, routeUuid));
    }

    renderRouteVehicle(routeUuid, vehicle) {
        const order = _.first(_.filter(this.getOrders(vehicle), {route_uuid: routeUuid}));
        const run = _.find(_.flatten(_.map(order.shifts, 'runs')), {vehicle_uuid: vehicle.uuid});

        return (
            <div key={vehicle.uuid} className="transport_row history__info">
                <div className="history__icon-block">
                    <i className="history__icon b-icon-link b-icon-link_params b-icon-link_icon_bus"/>
                </div>
                <div className="history__header">
                    <div className="history__title">
                        <b>{order.number}</b>
                        <span className="history__date">за {moment(order.date).format(formats.DATE)}</span>
                    </div>
                    <div className="history__road-train">{this.state.related.getReact(order.carrier_uuid)}</div>
                    <div className="history__auto">
                        {_.get(_.get(this.props.vehicle_types, vehicle.vehicle_type_uuid), 'name')}
                        <i>{vehicle.state_number}</i>
                    </div>
                    <div className="history__driver">{run.driver_name}</div>
                </div>
                <ContextTooltip default="Плеер">
                    <a href="javascript:void(0)" className="transport_play"
                       onClick={this.onOrderClick.bind(this, vehicle, order)}>
                        <i className="b-icon-link b-icon-link_params b-icon-link_icon_play"/>
                    </a>
                </ContextTooltip>
                <ContextTooltip default="История">
                    <a href="javascript:void(0)" className="history__button"
                       onClick={this.onOrderHistoryClick.bind(this, vehicle, order)}>
                        <i className="b-icon-link b-icon-link_params b-icon-link_icon_history"/>
                    </a>
                </ContextTooltip>
            </div>
        );
    }

    renderVehicles() {
        return _.map(this.getVehiclesForCurrentPage(), ::this.renderVehicle);
    }

    getVehiclesForCurrentPage(vehicles = null) {
        return (vehicles || this.props.vehicles).slice((this.state.page - 1) * this.perPage, this.state.page * this.perPage);
    }

    onPlayClick(vehicle, e) {
        e.preventDefault();
        e.stopPropagation();

        this.props.onPlayClick(vehicle.uuid);
    }

    onHistoryClick(vehicle, e) {
        e.preventDefault();
        e.stopPropagation();

        this.props.onClose();
        this.props.onHistoryClick(vehicle.uuid);
    }

    renderVehicle(vehicle) {
        return (
            <AccordionItem
                key={vehicle.uuid}
                label={(
                    <div className="accordion__route transport_info">
                        <div className="history__icon-block">
                            <i className="history__icon b-icon-link b-icon-link_params b-icon-link_icon_bus"/>
                        </div>
                        <div className="transport_title">
                            <div className="transport_num">{vehicle.state_number}</div>
                            <div className="transport_name">
                                {_.get(_.get(this.props.vehicle_types, vehicle.vehicle_type_uuid), 'name')}
                            </div>
                        </div>
                        {vehicle.bnso_number ? ([
                            <ContextTooltip key="play" default="Плеер">
                                <a key="play" href="javascript:void(0)" className="transport_play"
                                   onClick={this.onPlayClick.bind(this, vehicle)}>
                                    <i className="b-icon-link b-icon-link_params b-icon-link_icon_play"/>
                                </a>
                            </ContextTooltip>,
                            <ContextTooltip key="history" default="История">
                                <a key="history" href="javascript:void(0)" className="transport_history"
                                   onClick={this.onHistoryClick.bind(this, vehicle)}>
                                    <i className="b-icon-link b-icon-link_params b-icon-link_icon_history"/>
                                </a>
                            </ContextTooltip>,
                        ]) : null}
                    </div>
                )}
            >
                {this.renderWorks(vehicle)}
            </AccordionItem>
        );
    }

    renderWorks(vehicle) {
        switch (this.props.component) {
            case 'kiutr':
            case 'children':
            case 'ems':
                return this.renderOrders(vehicle);
            case 'road':
                return this.renderTasks(vehicle);
        }
    }

    getOrders(vehicle) {
        return _.filter(this.state.orders, (order) => {
            return _.indexOf(_.map(_.flatten(_.map(order.shifts, 'runs')), 'vehicle_uuid'), vehicle.uuid) !== -1;
        });
    }

    onOrderClick(vehicle, order) {
        const start = _.get(_.first(_.sortBy(order.shifts, ['start_at'])), 'start_at');
        const dateFrom = moment(start);
        const fromTime = moment(start).format(formats.TIME);

        const end = _.get(_.last(_.sortBy(order.shifts, ['end_at'])), 'end_at');
        const dateTo = moment(end);
        const toTime = moment(end).format(formats.TIME);

        this.props.onPlayClick(vehicle.uuid, dateFrom, fromTime, dateTo, toTime);
    }

    onOrderHistoryClick(vehicle, order) {
        const start = _.get(_.first(_.sortBy(order.shifts, ['start_at'])), 'start_at');
        const dateFrom = moment(start);
        const fromTime = moment(start).format(formats.TIME);

        const end = _.get(_.last(_.sortBy(order.shifts, ['end_at'])), 'end_at');
        const dateTo = moment(end);
        const toTime = moment(end).format(formats.TIME);

        this.props.onClose();
        this.props.onHistoryClick(vehicle.uuid, dateFrom, fromTime, dateTo, toTime);
    }

    renderOrders(vehicle) {
        const orders = this.getOrders(vehicle);

        if (orders.length === 0) {
            return (
                <div className="transport_row history__info">
                    нет данных
                </div>
            );
        }

        return _.map(orders, this.renderOrder.bind(this, vehicle));
    }

    renderOrder(vehicle, order) {
        return (
            <div key={order.uuid} className="transport_row history__info">
                <div className="history__header" onClick={this.onOrderClick.bind(this, vehicle, order)}>
                    <div className="history__title">
                        <b>{order.number}</b>
                        <span className="history__date">за {moment(order.date).format(formats.DATE)}</span>
                    </div>
                    <div className="history__road-train">
                        {this.state.related.getReact(order.carrier_uuid)}
                    </div>
                </div>
                <ContextTooltip default="История работы">
                    <a href="javascript:void(0)" className="history__button"
                       onClick={this.onOrderHistoryClick.bind(this, vehicle, order)}>
                        <i className="b-icon-link b-icon-link_params b-icon-link_icon_history"/>
                    </a>
                </ContextTooltip>
            </div>
        );
    }

    getTasks(vehicle) {
        return _.filter(this.state.tasks, (task) => {
            return _.indexOf(_.map(task.resources, 'base_vehicle_uuid'), vehicle.uuid) !== -1;
        });
    }

    onTaskClick(vehicle, task) {
        const date = moment(task.date);
        const fromTime = _.get(_.first(_.sortBy(task.items, ['date_from'])), 'date_from');
        const toTime = _.get(_.last(_.sortBy(task.items, ['date_to'])), 'date_to');

        this.props.onPlayClick(vehicle.uuid, date, fromTime, date, toTime);
    }

    onTaskHistoryClick(vehicle, task) {
        const date = moment(task.date);
        const fromTime = _.get(_.first(_.sortBy(task.items, ['date_from'])), 'date_from');
        const toTime = _.get(_.last(_.sortBy(task.items, ['date_to'])), 'date_to');

        this.props.onClose();
        this.props.onHistoryClick(vehicle.uuid, date, fromTime, date, toTime);
    }

    renderTasks(vehicle) {
        const tasks = this.getTasks(vehicle);

        if (tasks.length === 0) {
            return (
                <div className="transport_row history__info">
                    нет данных
                </div>
            );
        }

        return _.map(tasks, this.renderTask.bind(this, vehicle));
    }

    renderTask(vehicle, task) {
        return (
            <div key={task.uuid} className="transport_row history__info">
                <div className="history__header" onClick={this.onTaskClick.bind(this, vehicle, task)}>
                    <div className="history__title">
                        <b>{task.number}</b>
                        <span className="history__date">за {moment(task.date).format(formats.DATE)}</span>
                    </div>
                    <div className="history__road-train">
                        {this.state.related.getReact(task.unit_uuid)}
                    </div>
                </div>
                <ContextTooltip default="История работы">
                    <a href="javascript:void(0)" className="history__button"
                       onClick={this.onTaskHistoryClick.bind(this, vehicle, task)}>
                        <i className="b-icon-link b-icon-link_params b-icon-link_icon_history"/>
                    </a>
                </ContextTooltip>
            </div>
        );
    }

    renderPages() {
        const pagesCount = this.getPagesCount();
        const currentPage = this.isRoute() ? this.state.page_route : this.state.page;
        const pages = _.filter(_.range(1, pagesCount + 1), (page) => {
            return Math.abs(page - currentPage) <= 1;
        });
        return (
            <div className="dataTables_paginate paging_simple_numbers">
                <a className="paginate_button previous" onClick={::this.prevPage}/>

                <span>
                    {_.map(pages, (page) => {
                        return (
                            <a key={page} href="javascript:void(0)" onClick={this.setPage.bind(this, page)}
                               className={classNames('paginate_button', (page === this.props.page) ? 'current' : '')}>{page}</a>
                        );
                    })}
                </span>

                <a className="paginate_button next" onClick={::this.nextPage}/>
            </div>
        );
    }

    getPagesCount() {
        if (this.isRoute()) {
            const routes = _.uniq(_.map(this.state.orders, 'route_uuid'));
            return Math.max(1, Math.ceil(routes.length / this.perPage));
        } else {
            return Math.max(1, Math.ceil(this.props.vehicles.length / this.perPage));
        }
    }

    async setPage(page) {
        await this.setState({
            page,
        });

        if (this.props.component === 'road') {
            this.loadTasks();
        }
    }

    prevPage() {
        if (this.state.page > 1) {
            this.setPage(this.state.page - 1);
        }
    }

    nextPage() {
        if (this.state.page < this.getPagesCount()) {
            this.setPage(this.state.page + 1);
        }
    }

}