import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import * as validation from 'helpers/validation';

import PageModal from 'components/ui/page-modal';
import Button from 'components/ui/button';

import './editor.less';
import {showError} from "helpers/errors";
import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import BaseEditorFormComponent from "components/base/base-editor-form";
import {getLayer, createLayer, updateLayer, deleteLayer} from "store/reducers/user-map-objects/layer_editor";
import Block from "components/ui/form/block";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import currentUser from 'helpers/current-user';
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({
    layer: state.user_map_objects.layer_editor.get('layer')
}), {getLayer, createLayer, updateLayer, deleteLayer})

export default class LayersEditor extends Component {

    state = {
        uuid: null,
        isLoading: this.props.mode === 'edit',
        saving: false,
        errors: {}
    };

    modelClass = 'App\\Model\\Layer';

    componentDidMount() {
        if (this.props.uuid) {
            this.loadLayer(this.props.uuid);
        }
    }

    render() {
        let title = 'Добавление пользовательского слоя';

        let form;
        let buttons;

        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {
            title = 'Редактирование пользовательского слоя';

            if (!this.state.isLoading) {
                form = <LayerEditorForm
                    mode={this.props.mode}
                    onSubmit={::this.edit}
                    onDelete={::this.deleteItem}
                    onClose={::this.props.onClose}
                    data={this.props.layer}
                    errors={this.state.errors}
                />;

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            {(currentUser.can('com.rnis.system.permission.audit', 'read') && this.modelClass) ?
                                (
                                    <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                                    default="Журнал аудита">
                                        <ModalTopMenuListItem
                                            className="b-icon-link_params b-icon-link_icon_history"
                                            href={`/system/audit/${this.props.uuid}?class=${this.modelClass}`}
                                        />
                                    </ContextTooltip>
                                ) : null}

                        </ModalTopMenuList>
                    </ModalTopMenuButtons>
                );
            }
        }
        else {
            form = <LayerEditorForm
                mode={this.props.mode}
                onSubmit={::this.create}
                onClose={::this.props.onClose}
                data={{}}
                errors={this.state.errors}
            />;
        }

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}>

                {loader}
                {form}

            </PageModal>
        );
    }

    async loadLayer(uuid) {
        this.setState({
            uuid,
            isLoading: true
        });
        const response = await this.props.getLayer(uuid);
        if (response.status === 'ok') {
            this.setState({isLoading: false});
        } else {
            showError(response.error);
        }
    }

    clearErrors() {
        this.setState({errors: {}});
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    async create(layer) {
        this.clearErrors();
        this.startSave();
        layer.component = 'kiutr';
        const response = await this.props.createLayer(layer);
        this.endSave();
        if (response.status === 'ok') {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            showError(response.error);
        }
    }

    async edit(layer) {
        this.clearErrors();
        this.startSave();
        const response = await this.props.updateLayer(layer);
        this.endSave();
        if (response.status === 'ok') {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            showError(response.error);
        }
    }

    async deleteItem(uuid) {
        this.startSave();
        const response = await this.props.deleteLayer({uuid});
        this.endSave();
        if (response.status === 'ok') {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: validation.getErrors(response.error)
            });
            showError(response.error);
        }
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

class LayerEditorForm extends BaseEditorFormComponent {

    render() {
        return (
            <div className="LayerEditorForm">

                <Block size="xl" title="Наименование">
                    {this.textInput('title')}
                </Block>

                <div className="b-draggable">
                    <div className="b-draggable__row fs-zero">
                        <div className="b-block _lg">
                            <div className="b-block__title">Наименование</div>
                        </div>
                    </div>
                    {_.map(this.getValue('parameters') || [], (item, index) => {
                        return (
                            <div key={index} className="b-draggable__item sector sector_idling sector_state_violation">
                                <div className="b-draggable__top fs-zero">
                                    <div className="b-draggable__menu">
                                        <div
                                            className="b-draggable__menu-link b-draggable__menu-link_basket"
                                            onClick={this.deleteParameter.bind(this, index)}/>
                                    </div>
                                    <Block size="lg">
                                        {this.textInput(`parameters.${index}`)}
                                    </Block>
                                </div>
                            </div>
                        );
                    })}
                    <div className="add-draggable">
                        <div className="add-draggable__icon"
                             onClick={::this.addParameter}>+
                        </div>
                    </div>
                </div>

                <Block>
                    {this.checkbox('is_visible', 'Видимый слой')}
                </Block>

                <div className="submit">
                    <Button size="md" text={(this.state.uuid) ? 'Сохранить' : 'Добавить'} color="red" shadow="red"
                            onClick={::this.submit}/>
                    {(this.state.uuid) ?
                        <Button size="md" text="Удалить" color="white" shadow="gray"
                                onClick={::this.onDeleteClick}/> : ''}
                    <Button size="md" text="Отмена" color="white" shadow="gray" onClick={::this.cancel}/>
                </div>

            </div>
        );
    }

    addParameter() {
        let state = this.state;
        state.parameters = state.parameters || [];
        state.parameters.push('');
        this.setState(state);
    }

    deleteParameter(index) {
        let state = this.state;
        state.parameters = state.parameters || [];
        state.parameters.splice(index, 1);
        this.setState(state);
    }

}