import {rpc} from './session';

export async function getUnits(meta = {}) {
    return await rpc.request('com.rnis.organizational_units.action.units', {}, {meta});
}

export async function getUnit(unitUuid, search = {}) {
    search.unit_uuid = unitUuid;
    return await rpc.request('com.rnis.organizational_units.action.unit.get', search);
}

export async function createUnit(data) {
    return await rpc.request('com.rnis.organizational_units.action.unit.create', data);
}

export async function updateUnit(data) {
    return await rpc.request('com.rnis.organizational_units.action.unit.update', data);
}

export async function deleteUnit(data) {
    return await rpc.request('com.rnis.organizational_units.action.unit.delete', data);
}

export async function getUserGroups(meta = {}) {
    return await rpc.request('com.rnis.organizational_units.action.user_groups', {}, {meta});
}

export async function getPositions(meta = {}) {
    return await rpc.request('com.rnis.organizational_units.action.positions', {}, {meta});
}

export async function getPosition(positionUuid) {
    return await rpc.request('com.rnis.organizational_units.action.position.get', {position_uuid: positionUuid});
}

export async function createPosition(data) {
    return await rpc.request('com.rnis.organizational_units.action.position.create', data);
}

export async function updatePosition(data) {
    return await rpc.request('com.rnis.organizational_units.action.position.update', data);
}

export async function deletePosition(data) {
    return await rpc.request('com.rnis.organizational_units.action.position.delete', data);
}

export async function getCarriers(meta = {}) {
    return await rpc.request('com.rnis.organizational_units.action.carriers', {}, {meta});
}

export async function getCarrier(uuid) {
    return await rpc.request('com.rnis.organizational_units.action.carrier.get', {uuid});
}

export async function createCarrier(data) {
    return await rpc.request('com.rnis.organizational_units.action.carrier.create', data);
}

export async function updateCarrier(data) {
    return await rpc.request('com.rnis.organizational_units.action.carrier.update', data);
}

export async function deleteCarrier(data) {
    return await rpc.request('com.rnis.organizational_units.action.carrier.delete', data);
}