import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import Page from "components/ui/page";
import {connect} from "react-redux";
import BaseEditorFormComponent from "components/base/base-editor-form";
import GlobalLoaderComponent from "components/ui/global-loader";
import systems from "dictionaries/systems";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";

import './style.less';
import KursVehicleFuel from "components/modules/kurs/vehicles/vehicle-editor-form/vehicle-fuel/index";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {}, null, {withRef: true})

export default class VehiclesFuel extends BaseEditorFormComponent {

    state = {};

    renderHeaderActions() {
        return [
            <ContextTooltip key="kurs.vehicle.back" code="kurs.vehicle.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ];
    }

    onClose() {
        this.props.router.push(`/${this.props.params.component}/vehicles/vehicles/${this.props.params.uuid}`);
    }

    render() {
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        return (
            <Page
                pageId="RoadMachineryAccountingView"
                title={`${systems[this.props.params.component]} → ТС → Топливо`}
                className="profile-modal"
                headerActions={this.renderHeaderActions()}
            >
                {loader}
                <KursVehicleFuel
                    uuid={this.props.params.uuid}
                />
            </Page>
        );
    }
}