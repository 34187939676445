import {api} from 'helpers/api';
import {success, error} from 'helpers/response';
import {Map} from "immutable";

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    return state;
}

export const createSmsTemplate = (data) => async (dispatch) => {
    try {
        const response = await api.sms.createSmsTemplate(data);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getSmsTemplatesList = (meta) => async (dispatch) => {
    try {
        const response = await api.sms.getSmsTemplatesList(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getSmsTemplate = (uuid) => async (dispatch) => {
    try {
        const response = await api.sms.getSmsTemplate(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateSmsTemplate = (data) => async (dispatch) => {
    try {
        const response = await api.sms.updateSmsTemplate(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteSmsTemplate = (uuid) => async (dispatch) => {
    try {
        const response = await api.sms.deleteSmsTemplate(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createSmsDevice = (data) => async (dispatch) => {
    try {
        const response = await api.sms.createSmsDevice(data);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getSmsDevice = (uuid) => async (dispatch) => {
    try {
        const response = await api.sms.getSmsDevice(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getSmsDeviceByID = (uuid) => async (dispatch) => {
    try {
        const response = await api.sms.getSmsDeviceByID(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getSmsDevicesList = (meta) => async (dispatch) => {
    try {
        const response = await api.sms.getSmsDevicesList(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateSmsDevice = (data) => async (dispatch) => {
    try {
        const response = await api.sms.updateSmsDevice(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteSmsDevice = (uuid) => async (dispatch) => {
    try {
        const response = await api.sms.deleteSmsDevice(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getSmsDefaultDevice = (meta) => async (dispatch) => {
    try {
        const response = await api.sms.getSmsDefaultDevice(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const smsCreate = (payload) => async (dispatch) => {
    try {
        const response = await api.sms.smsCreate(payload);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getLastSmses = (payload) => async (dispatch) => {
    try {
        const response = await api.sms.getLastSmses(payload);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};