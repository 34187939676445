import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import _ from 'lodash';
import {success, error} from 'helpers/response';
import {User} from "helpers/user";

const initialState = {
    vehicles: null
};

let varDispatch;

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case 'SET_VEHICLES':
        return { ...state, vehicles: action.payload };
    }

    return state;
}

export const getVehicleList = (meta) => async (dispatch) => {
    try {

        if (meta && meta.filters && meta.filters.withComponent && meta.filters.withComponent.length === 0) {
            delete meta.filters.withComponent;
        }

        const response = await api.vehicles.getVehicleList(meta);
        if (response.payload && response.payload.items) {
            if (dispatch) {
                varDispatch = dispatch;
                dispatch({type: 'SET_VEHICLES', payload: response.payload.items});
            } else if (varDispatch) {
                varDispatch({type: 'SET_VEHICLES', payload: response.payload.items});
            }
        }
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const setVehicleList = (payload) => async (dispatch) => {
    if (dispatch) {
        varDispatch = dispatch;
        dispatch({type: 'SET_VEHICLES', payload});
    } else if (varDispatch) {
        varDispatch({type: 'SET_VEHICLES', payload});
    }
}

export const getVehiclesByUnits = (units, meta = {}) => async (dispatch) => {
    try {
        const response = await api.vehicles.getVehiclesByUnits(units, meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getVehicleListForPortal = (meta) => async (dispatch) => {
    try {
        const response = await api.vehicles.getVehicleListForPortal(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getVehicle = (uuid) => async (dispatch) => {
    try {
        const response = await api.vehicles.getVehicle(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createVehicle = (data) => async (dispatch) => {
    try {
        const response = await api.vehicles.createVehicle(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getVehicleDistance = (data) => async (dispatch) => {
    try {
        const response = await api.vehicles.getVehicleDistance(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateVehicle = (data) => async (dispatch) => {
    try {
        const response = await api.vehicles.updateVehicle(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteVehicle = (data) => async (dispatch) => {
    try {
        const response = await api.vehicles.deleteVehicle(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const importVehicles = (data) => async (dispatch) => {
    try {
        const response = await api.vehicles.importVehicles(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const importVehiclesGet = (uuid) => async (dispatch) => {
    try {
        const response = await api.vehicles.importVehiclesGet(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const importKursVehicles = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.importKursVehicles(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const importKursVehiclesGet = (uuid) => async (dispatch) => {
    try {
        const response = await api.kurs.importKursVehiclesGet(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getVehicleMalfunctions = (meta) => async (dispatch) => {
    try {
        const response = await api.vehicles.getVehicleMalfunctions(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
