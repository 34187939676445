import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import L from 'leaflet';
import MapComponent from "components/ui/map";
import './variant_map.less';
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import PointMarker from "components/ui/map/markers/point-marker";
import DirectionalPolyline from "components/ui/map/objects/directional-polyline";
import debounce from 'throttle-debounce/debounce';
import * as alerts from "helpers/alerts";
import ControlPointMarker from "components/ui/map/markers/control-point-marker";
import MapGeojson from "components/ui/map/base/geojson";
import MapPolyline from "components/ui/map/base/polyline";
import Settings from 'settings';
import StopPointsEditor from "components/modules/geo/stop-points/editor";
import RouteStopPointMarker from "components/ui/map/markers/route-stop-point-marker";

@propTypes({
    variant: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    deletePoint: PropTypes.func.isRequired,
    addStopPoint: PropTypes.func.isRequired,
    killRecalc: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    forwardVisible: PropTypes.bool,
    reverseVisible: PropTypes.bool,
    isBothSide: PropTypes.bool,
    stopPoints: PropTypes.object,
})

@defaultProps({
    visible: true,
    forwardVisible: true,
    reverseVisible: true,
    isBothSide: false,
    stopPoints: {},
})

export default class KiutrVariantMapComponent extends Component {

    state = {
        editorUuid: null,
    };

    pointDragDebounce = debounce(150, ::this.onPointDragEnd);

    inited = false;

    componentDidUpdate() {
        if (!this.inited) {
            this.fitBounds();
            this.inited = true;
        }
    }

    deletePoint(direction, index) {
        this.props.deletePoint(direction, index);
    }

    onPathClick(direction, index, {latlng: {lat, lng}}) {
        alerts.askSelect('Выберите действие:', [
            {
                value: 'point',
                label: 'Добавить промежуточную точку',
            },
            {
                value: 'control_point',
                label: 'Добавить контрольную точку',
            },
            /*{
                value: 'toggle_routing',
                label: 'Переключить автоматическое построение геометрии отрезка',
            },*/
        ], (selected) => {
            switch (selected) {
                case 'control_point':
                    alerts.ask('Название контрольной точки:', (name) => {
                        this.addPoint(direction, index, lat, lng, 'control_point', name);
                    });
                    break;
                case 'point':
                    this.addPoint(direction, index, lat, lng, 'point');
                    break;
                case 'toggle_routing':
                    this.toggleRoutingStatus(direction, index);
                    break;
            }
        });
    }

    toggleRoutingStatus(direction, index) {
        let variant = this.props.variant;
        let point = variant[direction][index];
        point.is_routing_enabled = !point.is_routing_enabled;

        variant[direction][index] = point;
        this.props.onUpdate(variant, direction, index);
    }

    addPoint(direction, index, lat, lng, point_type, name = null) {
        const from = new L.LatLng(this.props.variant[direction][index].latitude, this.props.variant[direction][index].longitude);

        let i = index + 1;
        let points = [];
        points.push({
            distance: from.distanceTo(new L.LatLng(lat, lng)),
            latitude: lat,
            longitude: lng,
            point_type,
            name,
            _uuid: Math.random(),
        });

        for (; (i < this.props.variant[direction].length) && (this.props.variant[direction][i].point_type === 'point' || this.props.variant[direction][i].point_type === 'control_point'); i++) {
            points.push(this.props.variant[direction][i]);
        }

        const sorted = points.concat().sort((a, b) => {
            return a.distance - b.distance;
        });


        let variant = this.props.variant;
        variant[direction].splice(index + 1, i - (index + 1), ...sorted);

        this.props.onUpdate(variant, direction, index + 1);
    }

    onPointDragEnd(direction, index, {target}) {
        const {lat, lng} = target.getLatLng();

        let variant = this.props.variant;
        variant[direction][index].latitude = lat;
        variant[direction][index].longitude = lng;

        this.props.onUpdate(variant, direction, index);
    }

    getEditor() {
        return (
            <StopPointsEditor
                mode="edit"
                uuid={this.state.editorUuid}
                onSubmit={::this.closeEditor}
                onClose={::this.closeEditor}
            />
        );
    }

    onEditClick(uuid) {
        this.setState({
            editorUuid: uuid,
        });
    }

    closeEditor() {
        this.setState({
            editorUuid: null,
        });
    }

    stopPointForwardAddClick(stopPointUuid, latitude, longitude) {
        this.props.addStopPoint(stopPointUuid, latitude, longitude, 'forward_points');
    }

    stopPointReverseAddClick(stopPointUuid, latitude, longitude) {
        this.props.addStopPoint(stopPointUuid, latitude, longitude, 'reverse_points');
    }

    render() {
        const editor = this.state.editorUuid ? this.getEditor() : null;

        return (
            <div className="route-variant-map-container">
                {editor}
                {this.renderStopPointModal()}
                <MapComponent
                    ref="map"
                    //onStopPointDblClick={this.props.addStopPoint}
                    onStopPointForwardAddClick={::this.stopPointForwardAddClick}
                    onStopPointReverseAddClick={::this.stopPointReverseAddClick}
                    onClick={() => {
                    }}
                    displayUserObjects={true}
                    withoutStopPoints={_.concat(_.map(this.props.variant.forward_points, 'type_uuid'), _.map(this.props.variant.reverse_points, 'type_uuid'))}
                >
                    {this.props.isBothSide && this.props.reverseVisible && this.props.variant.reverse_points && this.props.variant.reverse_points.map(this.renderPoint.bind(this, 'reverse_points'))}
                    {this.props.forwardVisible && this.props.variant.forward_points && this.props.variant.forward_points.map(this.renderPoint.bind(this, 'forward_points'))}

                    {this.props.isBothSide && this.props.reverseVisible && this.props.variant.reverse_points && this.props.variant.reverse_points.map(this.renderPath.bind(this, 'reverse_points'))}
                    {this.props.forwardVisible && this.props.variant.forward_points && this.props.variant.forward_points.map(this.renderPath.bind(this, 'forward_points'))}
                </MapComponent>
            </div>
        );
    }

    renderPointBounds(point, index) {
        if (!point.latitude || !point.longitude || (point.point_type !== 'stop_point')) {
            return null;
        }

        const threshold = Settings.get('paired_stop_points_threshold', 300);
        const bounds = L.latLng(point.latitude, point.longitude).toBounds(threshold);

        const geometry = {
            type: 'Polygon',
            coordinates: [
                [
                    [
                        bounds.getWest(),
                        bounds.getNorth(),
                    ],
                    [
                        bounds.getEast(),
                        bounds.getNorth(),
                    ],
                    [
                        bounds.getEast(),
                        bounds.getSouth(),
                    ],
                    [
                        bounds.getWest(),
                        bounds.getSouth(),
                    ],
                ],
            ],
        };

        return (
            <MapGeojson
                key={`bounds:${index}:${point.type_uuid}`}
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                geometry={geometry}
                interactive={false}
            />
        );
    }

    renderPointPair(point, index) {
        if (!point.latitude || !point.longitude || (point.point_type !== 'stop_point')) {
            return null;
        }

        const pairPoint = this.props.variant.reverse_points[this.props.variant.reverse_points.length - index - 1];
        if (!pairPoint || !pairPoint.latitude || !pairPoint.longitude) {
            return null;
        }

        const coordinates = [
            [
                point.latitude,
                point.longitude,
            ],
            [
                pairPoint.latitude,
                pairPoint.longitude,
            ],
        ];

        return (
            <MapPolyline
                key={`pair:${index}:${point.type_uuid}`}
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                color="gray"
                coordinates={coordinates}
                interactive={false}
            />
        );
    }

    onStopPointDisplayClick(uuid) {
        this.setState({
            stopPointFormUuid: uuid,
        });
    }

    closeEditor() {
        this.setState({
            stopPointFormUuid: null,
        });
    }

    renderStopPointModal() {
        if (!this.state.stopPointFormUuid) {
            return;
        }

        return (
            <StopPointsEditor
                mode="edit"
                uuid={this.state.stopPointFormUuid}
                onSubmit={::this.closeEditor}
                onClose={::this.closeEditor}
            />
        );
    }

    renderPoint(direction, point, index) {
        if (!point.latitude || !point.longitude) {
            return null;
        }

        switch (point.point_type) {
            case 'stop_point':
                let stopPoint = _.clone(this.props.stopPoints[point.type_uuid]);
                const number = _.filter(this.props.variant[direction].slice(0, index), {point_type: 'stop_point'}).length + 1;
                if (stopPoint) {
                    stopPoint.title = `${number}. ${stopPoint.title}`;
                }
                return (
                    <RouteStopPointMarker
                        key={`${direction}:${index}:${point.type_uuid}`}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        latitude={point.latitude}
                        longitude={point.longitude}
                        item={stopPoint}
                        color="green"
                        options={{
                            zIndexOffset: 100,
                        }}
                        permanentTooltip={this.props.permanentTooltips}
                        //onDblClick={this.addExistingStopPoint.bind(this, point.type_uuid, point.latitude, point.longitude)}
                        //onRightClick={this.deletePoint.bind(this, direction, index)}
                        //onEditClick={this.onEditClick.bind(this, point.type_uuid)}
                        onRoutingStatusToggle={this.toggleRoutingStatus.bind(this, direction, index)}
                        onDeleteClick={this.deletePoint.bind(this, direction, index)}
                        onEditClick={point.type_uuid ? this.onStopPointDisplayClick.bind(this, point.type_uuid) : null}
                        onStopPointForwardAddClick={this.stopPointForwardAddClick.bind(this, point.type_uuid, point.latitude, point.longitude)}
                        onStopPointReverseAddClick={this.stopPointReverseAddClick.bind(this, point.type_uuid, point.latitude, point.longitude)}
                    />
                );
            case 'point':
                return (
                    <PointMarker
                        key={`${direction}:${index}:${point._uuid}`}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        latitude={point.latitude}
                        longitude={point.longitude}
                        options={{
                            zIndexOffset: 100,
                        }}
                        onRightClick={this.deletePoint.bind(this, direction, index)}
                        onDrag={this.pointDragDebounce.bind(this, direction, index)}
                        onDragEnd={this.onPointDragEnd.bind(this, direction, index)}
                    />
                );
            case 'control_point':
                return (
                    <ControlPointMarker
                        key={`${direction}:${index}:${point._uuid}`}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        latitude={point.latitude}
                        longitude={point.longitude}
                        options={{
                            zIndexOffset: 100,
                        }}
                        onRightClick={this.deletePoint.bind(this, direction, index)}
                        onDrag={this.pointDragDebounce.bind(this, direction, index)}
                        onDragEnd={this.onPointDragEnd.bind(this, direction, index)}
                        name={point.name}
                    />
                );
        }

        return null;
    }

    addExistingStopPoint(pointUuid, latitude, longitude) {
        this.props.addStopPoint(pointUuid, latitude, longitude);
    }

    renderPath(direction, point, index) {
        const path = point.path_to_the_next_point_geometry;
        if (!path || point.point_type !== 'stop_point') {
            return;
        }

        return (
            <DirectionalPolyline
                key={`${direction}:${point.point_type}:${index}:${point.type_uuid}:${point._uuid}`}
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                color={(direction === 'forward_points') ? 'red' : 'blue'}
                dash={point.is_routing_enabled ? null : "7, 10"}
                coordinates={_.map(path.coordinates, (coord) => _.clone(coord).reverse())}
                onClick={this.onPathClick.bind(this, direction, index)}
                width={5}
            />
        );
    }

    fitBounds() {
        try {
            const forward_markers = this.props.variant.forward_points && this.props.variant.forward_points.map((point) => new L.Marker([point.latitude, point.longitude]));
            const reverse_markers = this.props.variant.reverse_points && this.props.variant.reverse_points.map((point) => new L.Marker([point.latitude, point.longitude]));
            const group = new L.featureGroup(_.concat(forward_markers, reverse_markers));
            this.refs.map.getWrappedInstance().fitBounds(group.getBounds());
        } catch (e) {
        }
    }

    panTo(coordinates) {
        this.refs.map.getWrappedInstance().panTo(coordinates);
    }

}
