import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {deleteFaq, getFaq} from "store/reducers/portal/faq";
import {deletePage, getPages} from "store/reducers/portal/pages";
import IconButton from "components/ui/icon-button";
import {deleteContact, getContacts} from "store/reducers/mobile/contacts";
import contact_types from "dictionaries/contact_types";
import systems from "dictionaries/systems";
import {deleteObject, getObjects} from "store/reducers/garbage/objects";
import {EntityList} from "helpers/entity";
import _ from 'lodash';
import {getEntityNames} from "store/reducers/system";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {Link} from "react-router";
import {deleteRepairProgram, getRepairPrograms} from "store/reducers/kurs/repair_programs";

@connect(state => ({}), {getRepairPrograms, deleteRepairProgram, getEntityNames, getDictionaryList})

export default class KursRepairProgramComponent extends BaseTableWithEditorComponent {

    title = `${systems.road} → Программа ремонта`;
    baseUrl = '/road/repair-program';

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Участок ремонта дорог')
                .fromField('road_part_uuid')
                .denyColumnFilter()
                .withDrawer(item => this.state.related.get(item.road_part_uuid)),

            new Column('РУАД')
                .fromField('unit_uuid')
                .denyColumnFilter()
                .withDrawer(item => this.state.related.get(item.unit_uuid)),

            new Column('Нач. участка')
                .fromField('start'),

            new Column('Конец участка')
                .fromField('end'),

            new Column('Протяженность')
                .fromField('length'),

            new Column('Вид ремонта')
                .fromField('repair_type'),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteRepairProgram(data);
    }

    async loadData(meta) {
        return await this.props.getRepairPrograms(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const units = _.map(_.filter(_.map(result, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const roadsParts = _.map(_.filter(_.map(result, 'road_part_uuid')), (uuid) => ({
            class: 'App\\Model\\RoadPart',
            uuid: uuid,
            source: 'kurs',
        }));

        const response = await this.props.getEntityNames(_.concat(units, roadsParts));
        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}