import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import _ from 'lodash';

import BaseTableComponent from 'components/base/base_table';
import Page from 'components/ui/page';
import IconButton from 'components/ui/icon-button';
import Editor from './editor';

import {mapDatatablesRequestToMeta} from 'helpers/api';

import * as alerts from "helpers/alerts";
import TableContainer from "components/ui/Table/Container/TableContainer";
import Column from "components/ui/column";
import AppearTransition from "components/ui/transitions/appear";
import {EntityList} from "helpers/entity";
import currentUser from 'helpers/current-user';
import {getEntityNames} from "store/reducers/system";
import {deleteCarrier, getCarriers} from "store/reducers/organizational_units/carriers";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import {getRegisterRequests} from "store/reducers/portal/register_requests";
import formats from "dictionaries/formats";
import moment from "moment";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import './index.less';
import {getCooperationAgreements} from "store/reducers/portal/cooperation_agreements";
import {getUnits} from "store/reducers/organizational_units/units";

@connect(state => ({}), {getCooperationAgreements, getEntityNames, getDictionaryList, getUnits})

export default class CooperationAgreements extends BaseTableWithEditorComponent {

    title = `${systems.operator} → Соглашения о взаимодействии`;
    baseUrl = '/operator/cooperation_agreements';

    getEditor() {
        return (
            <Editor
                {...this.props}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Номер')
                .fromField('document_number'),

            new Column('Контрагент')
                .fromField('unit_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid))
                .withAsyncFilter('withUnits', async (search) => {
                    const response = await this.props.getUnits({
                        search,
                        order: {
                            column: 'name',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 30,
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return [];
                }),

            new Column('Область действия')
                .fromField('component')
                .denyColumnFilter()
                .withDrawer(item => systems[item.component])
                .withFilter('withComponent', async () => {
                    return _.map(systems, (name, uuid) => ({
                        uuid,
                        name,
                    }));
                }),

            new Column('Подписано оператором')
                .fromField('signed_by_operator_date')
                .withDateFilter()
                .withDrawer(item => item.signed_by_operator_date ? moment(item.signed_by_operator_date).format(formats.DATE) : '-'),

            new Column('Истечение действия')
                .fromField('expiration_date')
                .withDateFilter()
                .withDrawer(item => item.expiration_date ? moment(item.expiration_date).format(formats.DATE) : '-'),

            new Column('Подписано контрагентом')
                .fromField('signed_by_company_date')
                .withDateFilter()
                .withDrawer(item => item.signed_by_company_date ? moment(item.signed_by_company_date).format(formats.DATE) : '-'),

            new Column('Предупреждения')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer((item) => {
                    const warnings = (item.warnings || []);
                    const total = warnings.length;
                    const opened = _.sortBy(_.filter(warnings, warning => !warning.warning_date_remove), 'warning_date_apply');

                    if (opened.length > 0) {
                        return `${opened.length}/${total} (${moment(_.first(opened).warning_date_apply).format(formats.DATE)})`;
                    } else {
                        return total;
                    }
                }),
            ...(!window.RNIS_SETTINGS.CITY_TULA ? ([
                new Column('Статус')
                    .fromField('status')
                    .denyColumnFilter()
                    .withDrawer((item) => {
                        if (item.status === 'terminated') {
                            return 'Расторгнуто';
                        }
                        if (moment(item.expiration_date).isBefore(moment())) {
                            return 'Истекло';
                        }
                        if (item.is_signed_by_company && !item.is_signed_by_operator) {
                            return 'Ожидает оператора';
                        }
                        if (!item.is_signed_by_company) {
                            return 'Ожидает контрагента';
                        }
                        return 'Действует';
                    })
                    .withFilter('withStatus', async () => {
                        return [
                            {
                                uuid: 'terminated',
                                name: 'Расторгнуто',
                            },
                            {
                                uuid: 'waiting_operator',
                                name: 'Ожидает оператора',
                            },
                            {
                                uuid: 'waiting_user',
                                name: 'Ожидает контрагента',
                            },
                            {
                                uuid: 'expired',
                                name: 'Истекло',
                            },
                            {
                                uuid: 'active',
                                name: 'Действует',
                            },
                        ];
                    }),
            ]) : [])


        ]);
    }

    async loadData(meta) {
        return await this.props.getCooperationAgreements(meta);
    }


    renderHeaderActions() {
        return null;
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const unitUuids = _.uniq(_.filter(_.map(result, 'unit_uuid')));
        const units = _.map(unitUuids, (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(units);

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}
