import {Map} from "immutable";
import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import {cached} from "helpers/cached";

const RESET = 'RESET';
const GET_DICTIONARY_STRUCTURE = 'GET_DICTIONARY_STRUCTURE';

const initialState = new Map({
    structure: new Map({})
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_DICTIONARY_STRUCTURE:
            return state.set('structure', action.payload.items);
        case RESET:
            return initialState;
    }

    return state;
}

export const getDictionaryStructure = (meta = {}) => async (dispatch) => {
    return cached('structure:'  + JSON.stringify(meta), async () => {
        try {
            let response = await api.dictionary.getDictionaryStructure(meta);

            dispatch({
                type: GET_DICTIONARY_STRUCTURE,
                payload: {
                    items: response.payload.structure.sections
                }
            });

            return success(response);
        }
        catch (e) {
            return error(e);
        }
    }, 60 * 24, true);
};

export const reset = (key) => async (dispatch) => {
    dispatch({
        type: RESET,
        payload: {}
    });
};