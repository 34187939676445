import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './button.less';

@propTypes({
    text: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    color: PropTypes.oneOf(['white', 'red', 'green', 'dark']),
    shadow: PropTypes.oneOf([null, 'gray', 'red']),
    width: PropTypes.oneOf([null, 'auto', 'full']),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    href: PropTypes.string,
    target: PropTypes.string,
})

@defaultProps({
    size: 'sm',
    color: 'red',
    shadow: null,
    width: null,
    disabled: false,
    className: '',
    href: null,
    target: null,

    onClick: () => {
    }
})

export default class Button extends Component {

    render() {
        const className = classNames(
            'b-button',
            `b-button_size_${this.props.size}`,
            `b-button_${this.props.color}`,
            this.props.shadow ? `b-button_shadow_${this.props.shadow}` : '',
            this.props.width ? `b-button_width_${this.props.width}` : '',
            this.props.disabled ? 'b-button_disabled' : '',
            this.props.className,
        );


        const style = {};

        if (this.props.fixWidth) {
            style.width = this.props.fixWidth;
        }

        let props = {};
        if (this.props.href) {
            props.href = this.props.href;
        }
        if (this.props.target) {
            props.target = this.props.target;
        }

        return (
            <a className={className} type="button" onClick={::this.onClick} {...props}>{this.props.text}</a>
        )
    }

    onClick(...args) {
        if (!this.props.disabled) {
            this.props.onClick(...args);
        }
    }

}
