import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getCarriers = (meta) => async (dispatch) => {
    try {
        const response = await api.organizational_units.getCarriers(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getCarrier = (unitUuid) => async (dispatch) => {
    try {
        const response = await api.organizational_units.getCarrier(unitUuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createCarrier = (data) => async (dispatch) => {
    try {
        const response = await api.organizational_units.createCarrier(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateCarrier = (data) => async (dispatch) => {
    try {
        const response = await api.organizational_units.updateCarrier(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteCarrier = (data) => async (dispatch) => {
    try {
        const response = await api.organizational_units.deleteCarrier(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
