import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import currentUser from 'helpers/current-user';
import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {getStopPoint, createStopPoint, updateStopPoint} from "store/reducers/geo/stop-points";
import BaseEditor from "components/base/base-editor";
import {getUnits} from "store/reducers/organizational_units/units";
import {getStopPointPavilions} from "store/reducers/geo/stop-point-pavilions";
import MapComponent from "components/ui/map";

import './editor.less';
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import * as alerts from "helpers/alerts";
import {State} from "components/ui/state";
import StopPointsMtaLayer from "components/ui/map/layers/stop-points-mta";
import L from 'leaflet';
import {printForm} from "helpers/print";
import ReactDOM from 'react-dom'
import {getHostName} from "../../../../helpers/wsrpc";
import FileReaderInput from "react-file-reader-input";
import {api} from "../../../../helpers/api";

;

@connect(state => ({}), {getStopPoint, createStopPoint, updateStopPoint, getStopPointPavilions})

export default class StopPointsEditor extends BaseEditor {

    title = 'остановки';
    modelClass = 'App\\Model\\StopPoint';

    componentDidMount() {
        this.forceUpdate();
    }

    print() {
        if (!this.refs.form) return;

        const map = this.refs.form.getWrappedInstance().refs.map.getWrappedInstance().map;
        map.export({
            format: 'image/png',
            fileName: 'map.png',
            container: map._container,
            afterExport: (dataUrl) => {
                const image = dataUrl.data;

                printForm(ReactDOM.findDOMNode(this.refs.form.getWrappedInstance()), image);
            },
        });
    }

    async loadData(uuid) {
        let response = await this.props.getStopPoint(uuid);
        if (response.isOk) {
            let stopPoint = response.payload;
            stopPoint.pavilions = await this.loadPavilions(stopPoint);

            response.payload = stopPoint;
        }
        if (response.isOk && (_.get(this.props, 'params.component') !== 'kiutr') && (_.get(response, 'payload.source') !== 'children' && !window.RNIS_SETTINGS.ISEDITABLECHILDRENSTOPS)) {
            this.setState({
                onlyView: true,
            });
        }
        return response;
    }

    async loadPavilions(stopPoint) {
        const response = await this.props.getStopPointPavilions({
            filters: {
                withStopPoint: stopPoint.uuid,
            },
        });

        if (response.isOk) {
            return response.payload.items;
        } else {
            response.showErrors();
        }
    }

    async createItem(data) {
        return await this.props.createStopPoint(data);
    }

    async updateItem(data) {
        return await this.props.updateStopPoint(data);
    }

    getForm(item, onSubmit) {
        if (_.isEmpty(item)) {
            item = {
                source: (this.props.params.component === 'kiutr') ? 'mta' : 'children',
            };
        }
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                onlyView={this.state.onlyView}
            />
        );
    }

    saveButton(onClick) {
        return this.state.onlyView ? null : super.saveButton(onClick);
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
})

@connect((state) => ({}), {getDictionaryList, getUnits}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        stop_point: {},
        road_infrastructure_types: [],
        crosswalk_types: [],
        pavilion_types: [],
        units: [],
    };

    async updateItem(data) {
        return await this.props.updateStopPoint(data);
    }

    getData() {
        return this.state.stop_point;
    }

    async componentDidMount() {
        await this.setState({
            stop_point: this.prepareData(this.props.data),
        });
        this.loadDictionaries([
            'road_infrastructure_types',
            'crosswalk_types',
            'pavilion_types',
        ]);
        this.loadUnits();
    }

    prepareData(data) {
        data.latitude = data.latitude && _.round(data.latitude, 6);
        data.longitude = data.longitude && _.round(data.longitude, 6);

        return data;
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
        });
        if (response.isOk) {
            const units = _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.name,
            }));
            this.setState({units});
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.stop_point, path, defaultValue);
    }

    hasImage() {
        return _.has(this.props.data, 'image') && this.props.data.image.length;
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }

    async deleteFile(e) {
        e && e.preventDefault();
        let stop_point = this.state.stop_point;
        stop_point.image = "";
        this.setState({stop_point});
        const response = await this.updateItem(this.state.stop_point)
    }

    async uploadFile(e, results) {
        e.preventDefault();

        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        const allowedTypes = [
            'image/png',
            'image/jpeg',
        ];

        let hasError = false;

        results.forEach(result => {
            const [e, file] = result;
            formData.append('file', file);

            let errors = [];
            if (_.indexOf(allowedTypes, file.type) === -1) {
                errors.push('Недопустимый формат файла');
            }
            if ((file.size / (2048 * 2048)) >= 20) {
                errors.push('Недопустимый размер файла');
            }
            if (errors.length > 0) {
                alerts.error(errors.join('<br/>'));
                hasError = true;
            }
        });

        if (hasError) {
            return;
        }

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);

        let stop_point = this.state.stop_point;
        stop_point.image = response.file;

        this.setState({stop_point});

        const response2 = await this.updateItem(this.state.stop_point)

    }

    showAlert(url, e) {
        e && e.preventDefault();
        alerts.gallery(url);
    }

    render() {
        let hasMarker = this.get('latitude') && this.get('longitude');
        if (hasMarker) {
            try {
                new L.latLng(this.get('latitude'), this.get('longitude'))
            } catch (e) {
                hasMarker = false;
            }
        }

        const protocol = App.isSecure ? 'https://' : 'http://';
        const url = protocol + getHostName('ajax', false) + `/storage/${this.props.data.image}`;

        return (
            <div>
                <Accordion>
                    <AccordionItem title="Основные сведения" opened={true}>
                        {this.props.onlyView ? this.renderGeneralView() : this.renderGeneral()}
                    </AccordionItem>
                    <AccordionItem title="Карта">
                        <div className="map-container">
                            <MapComponent
                                ref="map"
                                onClick={this.props.onlyView ? () => {
                                } : ::this.onMapClick}
                                showLayers={false}
                                onDblClick={::this.onDblClick}
                            >
                                {this.refs.map ? ([
                                    (hasMarker) ? (
                                        <StopPointMarker
                                            key="stop-point"
                                            ref={::this.panToStopPoint}
                                            map={this.refs.map}
                                            leafletMap={this.refs.map.getWrappedInstance().map}
                                            latitude={this.get('latitude')}
                                            longitude={this.get('longitude')}
                                            item={this.state.stop_point}
                                            color="green"
                                            options={{
                                                zIndexOffset: 100,
                                            }}
                                        />
                                    ) : null,
                                   null,
                                ]) : null}
                            </MapComponent>
                        </div>
                    </AccordionItem>
                    {(!this.props.onlyView) ? (
                        <AccordionItem title="Павильоны">
                            {this.renderPavilions()}
                        </AccordionItem>
                    ) : null}
                    {(window.RNIS_SETTINGS.PHOTOBUSSTOP_F) ? ( <AccordionItem title="Фотография">
                        <div className="b-block">
                            {(this.hasImage() ? (<a href="" onClick={this.showAlert.bind(this, url)} target="_blank"> <img src={url}
                                                                                                                           className="small_preview"/></a>) : null)}

                            <p>{(this.hasImage()) ? (
                                <a href="#" onClick={this.deleteFile.bind(this)}>Удалить фото</a>
                            ) : null}


                                <FileReaderInput as="binary" id="my-file-input" onChange={::this.uploadFile}>
                                    <a href="#">{(this.hasImage()) ? 'Заменить фото' : 'Загрузить фото'}</a>
                                </FileReaderInput></p>
                        </div>
                    </AccordionItem>) : null}
                </Accordion>
            </div>
        );
    }

    panToStopPoint() {
        this.refs.map && this.refs.map.getWrappedInstance().flyTo([
            this.get('latitude'),
            this.get('longitude'),
        ], 17);
    }

    gotoStopPoint(uuid) {
        alerts.prompt('Перейти к редактированию остановки?', null, async () => {
            await this.props.onClose();
            this.props.router.push(`/stop-points/${uuid}`);
        }, 'Перейти');
    }

    onMapClick(latitude, longitude) {
        this.setValue('stop_point.latitude', _.round(latitude, 6));
        this.setValue('stop_point.longitude', _.round(longitude, 6));
    }

    onDblClick(e) {
        let latitude = e.latlng.lat;
        let longitude = e.latlng.lng;
        this.setValue('stop_point.latitude', _.round(latitude, 6));
        this.setValue('stop_point.longitude', _.round(longitude, 6));
    }

    renderGeneral() {
        return (
            <div>
                <Block size="xl" title="Наименование">
                    {this.textInput('stop_point.title')}
                </Block>
                <Block size="xl" title="Сокращённое наименование">
                    {this.textInput('stop_point.title_short')}
                </Block>
                <Block title="Широта">
                    {this.textInput('stop_point.latitude')}
                </Block>
                <Block title="Долгота">
                    {this.textInput('stop_point.longitude')}
                </Block>
                <Block title="Тип объекта">
                    {this.select('stop_point.object_type_uuid', this.state.road_infrastructure_types)}
                </Block>
                <Block title="Регистрационный номер">
                    {this.textInput('stop_point.register_number')}
                </Block>
                <Block title="Дата регистрации">
                    {this.datepicker('stop_point.register_date')}
                </Block>
                <Block title="Место нахождения">
                    {this.textInput('stop_point.location')}
                </Block>
                <Block title="Организация-владелец">
                    {this.select('stop_point.owner_department_uuid', this.state.units)}
                </Block>
                <Block title="Пропускная способность">
                    {this.textInput('stop_point.throughput')}
                </Block>
                <Block title="Время технологических перерывов">
                    {this.textInput('stop_point.tech_break')}
                </Block>
                <Block title="Тип пешеходного перехода">
                    {this.select('stop_point.crosswalk_type_uuid', this.state.crosswalk_types)}
                </Block>
                <Block title="Расстояние до пешеходного перехода">
                    {this.textInput('stop_point.to_nearest_crosswalk')}
                </Block>
               {/* <Block title="Находится в Москве">
                    <State positive={this.get('is_in_moscow', false)}/>
                </Block>*/}
                <Block size="xl" title="Параметры" className="stop-point-options">
                    {this.checkbox('stop_point.has_stop_sign', 'Знак места остановки')}
                    {this.checkbox('stop_point.has_crosswalk_sign', 'Знак пешеходный переход')}
                    {this.checkbox('stop_point.has_speed_himp_sign', 'Знак искусственная неровность')}
                    {this.checkbox('stop_point.has_crosswalk', 'Наличие пешеходного перехода')}
                    {this.checkbox('stop_point.has_speed_himps', 'Наличие искусственных неровностей')}
                    {this.checkbox('stop_point.has_traffic_light', 'Наличие светофоров')}
                    {this.checkbox('stop_point.has_road_marking', 'Наличие разметки')}
                    {this.checkbox('stop_point.has_power_supply', 'Энерогообеспечение')}
                    {this.checkbox('stop_point.has_lighting', 'Освещение')}
                    {this.checkbox('stop_point.ad_placement_possible', 'Возможность размещения рекламы')}
                    {this.checkbox('stop_point.has_trash_cans', 'Наличие мусорных баков')}
                    {this.checkbox('stop_point.has_ticket_sale', 'Наличие киоска продажи билетов')}
                    {this.checkbox('stop_point.has_handicap_arrangements', 'Обустройство для лиц с ограниченными возможностями здоровья')}
                    {this.checkbox('stop_point.has_stop_pocket', 'Наличие заездного кармана')}
                </Block>
            </div>
        );
    }

    renderGeneralView() {
        return (
            <div>
                <Block size="xl" title="Наименование">
                    {this.get('title')}
                </Block>
                <Block size="xl" title="Сокращённое наименование">
                    {this.get('title_short')}
                </Block>
                <Block title="Широта">
                    {this.get('latitude')}
                </Block>
                <Block title="Долгота">
                    {this.get('longitude')}
                </Block>
                <Block title="Тип объекта">
                    {_.get(_.find(this.state.road_infrastructure_types, {value: this.get('object_type_uuid')}), 'label')}
                </Block>
                <Block title="Регистрационный номер">
                    {this.get('register_number')}
                </Block>
                <Block title="Дата регистрации">
                    {this.get('register_date')}
                </Block>
                <Block title="Место нахождения">
                    {this.get('location')}
                </Block>
                <Block title="Организация-владелец">
                    {_.get(_.find(this.state.units, {value: this.get('owner_department_uuid')}), 'label')}
                </Block>
                <Block title="Пропускная способность">
                    {this.get('throughput')}
                </Block>
                <Block title="Время технологических перерывов">
                    {this.get('tech_break')}
                </Block>
                <Block title="Тип пешеходного перехода">
                    {_.get(_.find(this.state.crosswalk_types, {value: this.get('crosswalk_type_uuid')}), 'label')}
                </Block>
                <Block title="Расстояние до пешеходного перехода">
                    {this.get('to_nearest_crosswalk')}
                </Block>
               {/* <Block title="Находится в Москве">
                    <State positive={this.get('is_in_moscow', false)}/>
                </Block>*/}
                <Block title="Знак места остановки">
                    <State positive={this.get('has_stop_sign', false)}/>
                </Block>
                <Block title="Знак пешеходный переход">
                    <State positive={this.get('has_crosswalk_sign', false)}/>
                </Block>
                <Block title="Знак искусственная неровность">
                    <State positive={this.get('has_speed_himp_sign', false)}/>
                </Block>
                <Block title="Наличие пешеходного перехода">
                    <State positive={this.get('has_crosswalk', false)}/>
                </Block>
                <Block title="Наличие искусственных неровностей">
                    <State positive={this.get('has_speed_himps', false)}/>
                </Block>
                <Block title="Наличие светофоров">
                    <State positive={this.get('has_traffic_light', false)}/>
                </Block>
                <Block title="Наличие разметки">
                    <State positive={this.get('has_road_marking', false)}/>
                </Block>
                <Block title="Энерогообеспечение">
                    <State positive={this.get('has_power_supply', false)}/>
                </Block>
                <Block title="Освещение">
                    <State positive={this.get('has_lighting', false)}/>
                </Block>
                <Block title="Возможность размещения рекламы">
                    <State positive={this.get('ad_placement_possible', false)}/>
                </Block>
                <Block title="Наличие мусорных баков">
                    <State positive={this.get('has_trash_cans', false)}/>
                </Block>
                <Block title="Наличие киоска продажи билетов">
                    <State positive={this.get('has_ticket_sale', false)}/>
                </Block>
                <Block title="Обустройство для лиц с ограниченными возможностями здоровья">
                    <State positive={this.get('has_handicap_arrangements', false)}/>
                </Block>
                <Block title="Наличие заездного кармана">
                    <State positive={this.get('has_stop_pocket', false)}/>
                </Block>
            </div>
        );
    }

    renderPavilions() {
        const list = this.state.stop_point.pavilions || [];

        if (list.length === 0 && currentUser.can('com.rnis.geo.stop_point_pavilion', 'create')) {
            return (
                <div className="add-link">
                    <a className="add-job b-icon-link b-icon-link_icon_plus" href="#" onClick={::this.addPavilion}>Добавить
                        павильон</a>
                </div>
            );
        }
        return list.map(::this.renderPavilion);
    }

    addPavilion(e) {
        e.preventDefault();

        let stop_point = this.state.stop_point;
        stop_point.pavilions = stop_point.pavilions || [];
        stop_point.pavilions.push({});

        this.setState({stop_point});
    }

    deletePavilion(index, e) {
        e.preventDefault();

        let stop_point = this.state.stop_point;
        stop_point.pavilions.splice(index, 1);
        this.setState({stop_point});
    }

    renderPavilion(pavilion, index) {
        return (
            <div key={index}>
                <Block title="Предприятие-владелец павильона">
                    {this.select(`stop_point.pavilions.${index}.unit_uuid`, this.state.units)}
                </Block>
                <Block title="Тип павильона">
                    {this.select(`stop_point.pavilions.${index}.pavilion_type_uuid`, this.state.pavilion_types)}
                </Block>
                <div className="add-link">
                    {currentUser.can('com.rnis.geo.stop_point_pavilion', 'delete') ? (
                        <a className="remove-job b-icon-link b-icon-link_icon_basket" href="#"
                           onClick={this.deletePavilion.bind(this, index)}>Удалить павильон</a>
                    ) : null}
                    {((index === this.state.stop_point.pavilions.length - 1) && (currentUser.can('com.rnis.geo.stop_point_pavilion', 'create'))) ? (
                        <span>
                            <span className="add-link__separator"/>
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="#"
                               onClick={::this.addPavilion}>Добавить павильон</a>
                        </span>
                    ) : null}

                </div>
                {(index !== this.state.stop_point.pavilions.length - 1) ? <div className="hr"/> : null}
            </div>
        );
    }
}