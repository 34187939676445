import React, { Component } from "react";
import classNames from 'classnames';
import moment from "moment";

import { computeTimeZoneOffset } from "helpers/regularity_matrix";

import "./style.less";

export default class RegularityRow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isActive: false
        };
    }

    randomColor() {
        const colors = ["gray", "green", "yellow", "red"];
        const currentColor = colors[this.props.status];
        return currentColor;
    }

    handleClick() {
        this.setState({
            isActive: !this.state.isActive
        })
    }

    onMouseEnterHandler(e) {
        const classElement = e.currentTarget.className;
        if (classElement.includes("row_")) {
            // get class row_533 for example and get index
            // - 533 to add active class on vehicle_index e.g vehicle_533
            const rowIndex = classElement.match(/row_[0-9]+/g)[0].match(/[0-9]+/g);
            e.currentTarget.parentNode.parentNode.parentNode.classList.add("active");
            document.querySelector(`.vehicle_${rowIndex}`).classList.add("active");
            
            const listener = function leave(event) {
                event.target.parentNode.parentNode.parentNode.classList.remove("active")
                document.querySelector(`.vehicle_${rowIndex}`).classList.remove("active");
                setTimeout(() => {
                    event.target.removeEventListener("mouseleave", listener)
                }, 0);
            }

            e.currentTarget.addEventListener("mouseleave", listener);
        }
    }

    render() {
        return (
                <div className="regularityMatrix__row__item">
                    {!this.props.isFirst && <div onClick={() => this.handleClick()} className="plannedtimetonextvisit regularityMatrix__row__circle">{this.props.nextTime}</div>}
                    {this.state.isActive && <div className="debug-interval">
                        <span>uuid: {this.props.uuid}</span>
                        <span>interval: {this.props.interval}</span>
                        <span>visittime: {this.props.visittime}</span>
                        <span>visittime from UNIX(UTC): {this.props.visittime !== 0 ? moment.unix(this.props.visittime).format("HH:mm") : "-"}</span>
                        <span>visittime timezoneOffset: {this.props.visittime !== 0 ? computeTimeZoneOffset(this.props.visittime) : "-"}</span>
                    </div>}
                    <div onMouseEnter={(e) => this.onMouseEnterHandler(e)} className={classNames("factvisittime regularityMatrix__row__cell", `row_${this.props.rowIndex}`, this.randomColor(), this.props.isFirst == false ? "bridge" : "")}>{this.props.time}</div>
                    <div className="debug debug-circle">
                        <span className="debug-info">{this.props.uuid}</span>
                        <span className="debug-info">UTC: {this.props.visittime !== 0 ? moment.unix(this.props.visittime).utc().format("HH:mm") : "-"}</span>
                        <span className="debug-info">UTC + TimeZone: {this.props.visittime !== 0 ? computeTimeZoneOffset(this.props.visittime) : "-"}</span>
                    </div>
                </div>
        );
    }
}