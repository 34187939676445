import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import _ from 'lodash';
import currentUser from 'helpers/current-user';
import ReactDOM from 'react-dom'
import {printForm} from "helpers/print";
import ContextTooltip from "components/ui/context-tooltip";

import PageModal from 'components/ui/page-modal';
import GlobalLoaderComponent from "components/ui/global-loader";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import ReactDOMServer from "react-dom/server";
import RouteRegisterPrintComponent from "../modules/kiutr/route_registries/print/route_register";
import {print} from "helpers/print";
import moment from "moment";
import formats from "../../dictionaries/formats";
import ContractPrintComponent from "../modules/kiutr/contracts/print/contracts";
import WarningModal from "components/ui/WarningModal/warning-modal";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    isPageWithDetect:  PropTypes.bool,
})

@defaultProps({
    onEditClick: () => {
    },
    onDeleteClick: () => {
    },
})

export default class BaseEditor extends Component {

    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.openCheckModal = this.openCheckModal.bind(this);
        this.closeCheckModal = this.closeCheckModal.bind(this);
        this.goToAnotherPage = this.goToAnotherPage.bind(this);
        this.renderWarningModal = this.renderWarningModal.bind(this);
        this.turnedEditingMode = this.turnedEditingMode.bind(this)
    }

    state = {
        uuid: -1,
        item: null,
        isLoading: this.props.mode === 'edit',
        saving: false,
        errors: {},
        isWarningModal: false,
        hrefAnotherPage: undefined,
        isEditingNow: false,
    };

    modalClassName = '';
    withFade = true;
    disableOutsideClick = false;

    modalConfirmation = window.RNIS_SETTINGS.modal_confirmation;


    getButtons() {
        return [];
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {


        let href
        if(event.target.hasAttribute('href')) {
            href = event.target.getAttribute('href')
        } else if (event.target.closest('a') !== null) {
            href = event.target.closest('a').getAttribute('href')
        } else {
            href = undefined
        }

        if ((this.wrapperRef && !this.wrapperRef.contains(event.target)) && href !== undefined && href !== '#' && this.state.isEditingNow) {
            this.openCheckModal(event, href)
        }
    }

    openCheckModal(event, href) {

        document.getElementById("leftMenu").addEventListener("click", (event) => {
            event.preventDefault()
          });

        this.setState({
            isWarningModal: true,
            hrefAnotherPage: href ? href : undefined,
        })
    }

    closeCheckModal() {
        this.setState({isWarningModal: false})
    }

    goToAnotherPage() {
        if(this.state.hrefAnotherPage) {
            this.props.router.push(this.state.hrefAnotherPage);
        } else {
            ::this.props.onClose()
        }
    }

    renderWarningModal() {
        return (
            <WarningModal
                closeModal={this.closeCheckModal}
                goToAnotherPage={this.goToAnotherPage}
                isNeedBackground={true}
            />
        )
    }

    turnedEditingMode() {
        this.setState({
            isEditingNow: true,
        })
    }

    loadData(uuid) {
        throw new Error('You have to implement this method: loadData');
    }

    getTitle() {
        return this.title;
    }

    getForm(item, onSubmit) {
        throw new Error('You have to implement this method: getForm');
    }

    async createItem(data) {
        throw new Error('You have to implement this method: createItem');
    }

    async updateItem(data) {
        throw new Error('You have to implement this method: updateItem');
    }

    renderHeaderBtns(mode) {
        return null;
    }

    renderModals() {
        return null;
    }

    componentDidMount() {
        this.forceUpdate();
    }

    async componentWillReceiveProps(props) {
    }

    async componentWillUpdate(props, state) {
        const uuid = (props.uuid === 'create') ? -1 : props.uuid;

        if (state.uuid !== uuid) {
            this.setState({
                uuid: uuid,
                item: null,
                isLoading: !!uuid
            });
            this.init();
            if (uuid) {
                this.reload(uuid);
            }
        }
    }

    async reload(uuid) {
        const response = await this.loadData(uuid);
        console.log("🚀 ~ file: base-editor.js ~ line 191 ~ BaseEditor ~ reload ~ response", response)
        console.log("🚀 ~ file: base-editor.js ~ line 193 ~ BaseEditor ~ reload ~ response.isOk", response.isOk)

        if (response.isOk) {
            this.setState({
                item: response.payload,
                isLoading: false,
            });
        } else {
            response.showErrors();
        }
    }

    componentDidMount() {
        this.componentWillReceiveProps(this.props);
    }

    init() {

    }

    getFullTitle(mode) {
        if (mode === 'add') {
            return this.getTitle() ? `Добавление ${this.getTitle()}` : ''
        } else {
            return this.getTitle() ? `Редактирование ${this.getTitle()}` : ''
        }
    }

    render() {

        const title = this.getFullTitle(this.props.mode);

        let form;
        let buttons;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {

            if (this.state.item) {
                form = this.getForm(this.state.item, ::this.edit);
                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList
                            className={`top-menu_modal_edit ${this.props.routeParams && this.props.routeParams.component === 'children' && window.RNIS_SETTINGS.CITY_TULA ? 'fix-margin' : ''}`}
                        >
                            {this.renderHeaderBtns(this.props.mode)}
                            {(currentUser.can('com.rnis.system.permission.audit', 'read') && this.modelClass) ?
                                (
                                    <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                                    default="Журнал аудита">
                                        <ModalTopMenuListItem
                                            className="b-icon-link_params b-icon-link_icon_history"
                                            href={`/system/audit/${this.props.uuid}?class=${this.modelClass}`}
                                        />
                                    </ContextTooltip>
                                ) : null}

                            {this.checkRoutePathForOfficialBlankPrint(this.props.route) ? this.printFormButton() : null}
                            {this.printButton()}
                            {this.regularityMatrixButton()}

                            <ModalTopMenuListSeparator key="separator"/>
                        </ModalTopMenuList>

                        {this.saveButton(::this.onEdit)}
                        {this.closeButton()}
                    </ModalTopMenuButtons>
                );
            }
        } else {
            form = this.getForm(this.getDefaultItem(), ::this.create);

            buttons = (
                <ModalTopMenuButtons>
                    <ModalTopMenuList className="top-menu_modal_edit">
                        {this.renderHeaderBtns(this.props.mode)}
                    </ModalTopMenuList>

                    {this.saveButton(::this.onCreate)}

                    {this.closeButton()}
                </ModalTopMenuButtons>
            );
        }

        return (
            <div ref={this.setWrapperRef}>
                <PageModal
                    header={{title, buttons}}
                    onClose={this.props.onClose}
                    className={`profile-modal b-modal-${this.props.mode} ${this.getModalClassName()}`}
                    withFade={this.withFade}
                    disableOutsideClick={this.disableOutsideClick}
                    buttons={this.getButtons()}
                >
                    {loader}
                    {form}
                </PageModal>
                {this.renderModals()}
                {this.state.isWarningModal && this.modalConfirmation ? this.renderWarningModal() : null}
            </div>
        );
    }

    checkRoutePathForOfficialBlankPrint(route){
        if (!route || !route.path) return false;
        return route.path === '/:component/route_registries' && window.RNIS_SETTINGS.OFFICIALPRINT;
    }

    getDefaultItem() {
        return {};
    }

    getModalClassName() {
        return this.modalClassName;
    }

    saveButton(onClick) {
        return ([
            <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                <ModalTopMenuButton
                    className="_save"
                    title="Сохранить"
                    onClick={onClick}
                />
            </ContextTooltip>,
            <ModalTopMenuButtonsSeparator key="save-button-separator"/>,
        ]);
    }

    closeButton() {
        return (
            <ContextTooltip key="base-editor.close" code="base-editor.close" default="Закрыть">
                <ModalTopMenuButton
                    className="_close"
                    onClick={this.props.isPageWithDetect && this.modalConfirmation && this.state.isEditingNow ? (event) => {this.openCheckModal(event, undefined)} : ::this.props.onClose}
                />
            </ContextTooltip>
        );
    }

    printButton() {
        return (
            <ContextTooltip key="base-editor.print" code="base-editor.print" default="Печать">
                <ModalTopMenuListItem
                    className="b-icon-link_icon_print"
                    onClick={::this.print}
                />
            </ContextTooltip>

        );
    }

    regularityMatrixButton() {}

    printFormButton() {
        return (
            <ContextTooltip key="base-editor.print.blank" code="base-editor.print.blank" default="Печать на бланке">
                <ModalTopMenuListItem
                    className="b-icon-link_icon_print"
                    onClick={::this.printOfficial}
                />
            </ContextTooltip>

        );
    }

    print() {
        if (!this.refs.form) return;

        printForm(ReactDOM.findDOMNode(this.refs.form.getWrappedInstance()));
    }

    printOfficial() {
        if (!this.refs.form) return;
        let a = this.refs.form.props.data.printForm._printType;

        switch (a) {

            case 'routemap':
                this.printRouteMap();
                break;
            case 'certificate':
                this.printCertificate();
                break;
        }

    }

    printCertificate() {
        const style = `
            <style>
                @page {
                    size: A4 landscape;
                    margin: 0;
                }
                
                @media print {
  
                .contract-serial {
                    position: absolute;
                    left: 180mm;
                    top: 18mm;
                }   
                .contract-number {
                    position: absolute;
                    left: 210mm;
                    top: 18mm;
                }
                .org-name {
                   text-align: center;
                   border-bottom: 1px solid black;
                }            
                .date-from {
                    position: absolute;
                    left: 105mm;
                    top: 52mm;
                    width: 50mm;
                    text-align: center;
                   
                } 
                .year-from {
                    position: absolute;
                    left: 163mm;
                    top: 52mm;
                    width: 12mm;
                    text-align: center;
                   
                } 
                .date-to {
                    position: absolute;
                    left: 192mm;
                    top: 52mm;
                    width: 52mm;
                    text-align: center;
                   
                } 
                .year-to {
                    position: absolute;
                    left: 252mm;
                    top: 52mm;
                    width: 12mm;
                    text-align: center;                    
                }  
                .registration-number {
                    position: absolute;
                    left: 80mm;
                    top: 70mm;
                    width: 50mm;
                    height: 5mm;
                    text-align: center;
                } 
                .number {
                    position: absolute;
                    left: 130mm;
                    top: 70mm;
                    width: 30mm;
                    height: 5mm;
                    text-align: center;                
                }  
                .title {
                    position: absolute;
                    left: 160mm;
                    top: 70mm;
                    width: 120mm;
                    height: 5mm;
                    text-align: center;  
                    vertical-align: middle; 
                    font-size: ${this.refs.form.props.data.printForm.title.length > 50 ? `12px` : '16px'} !important;              
                }   
                .carrier {
                    position: absolute;
                    left: 78mm;
                    top: 80mm;                    
                    width: 80mm;
                    height: 5mm;
                    text-align: center;
                    vertical-align: middle;  
                    font-size: ${this.refs.form.props.data.printForm.carrier && this.refs.form.props.data.printForm.carrier.length > 30 ? `10px` : '16px'} !important;                  
                }   
                .place {
                    position: absolute;
                    left: 160mm;
                    top: 80mm;                    
                    width: 80mm;
                    height: 5mm;
                    text-align: center;
                    vertical-align: middle;                    
                    font-size: ${this.refs.form.props.data.printForm.place && this.refs.form.props.data.printForm.place.length > 50 ? `10px` : '16px'} !important;                  
                }   
                .inn {
                    position: absolute;
                    left: 240mm;
                    top: 80mm;                    
                    width: 40mm;
                    height: 5mm;
                    text-align: center;
                    vertical-align: middle;                   
                }     
                .stops {
                    position: absolute;
                    left: 83mm;
                    top: 90mm;                    
                    width: 195mm;
                    height: 30mm;
                    text-align: left;
                    vertical-align: middle;  
                    font-size: ${this.refs.form.props.data.printForm.stops && this.refs.form.props.data.printForm.stops.length > 150 ? `12px` : '16px'} !important;                                     
                }      
                .streets {
                    position: absolute;
                    left: 83mm;
                    top: 110mm;                    
                    width: 195mm;
                    height: 30mm;
                    text-align: left;
                    vertical-align: middle;  
                    font-size: ${this.refs.form.props.data.printForm.streets && this.refs.form.props.data.printForm.streets.length > 150 ? `12px` : '16px'} !important;                                     
                }  
                
                .ts-type {
                    position: absolute;
                    left: 78mm;
                    top: 148mm;                    
                    width: 40mm;
                    height: 10mm;
                    text-align: center;
                    vertical-align: middle;  
                    /*border: 3px solid #000;    */
                }  
                .eco {
                    position: absolute;
                    left: 157mm;
                    top: 148mm;                    
                    width: 35mm;
                    height: 10mm;
                    text-align: center;
                    vertical-align: middle;  
                    /*border: 3px solid #000;    */
                }    
                .landing {
                    position: absolute;
                    left: 245mm;
                    top: 148mm;                    
                    width: 35mm;
                    height: 10mm;
                    text-align: center;
                    vertical-align: middle;  
                    font-size: 10px;        
                    /*border: 3px solid #000;    */
                }  
                .ts-class {
                    position: absolute;
                    left: 160mm;
                    top: 120mm;                    
                    width: 42mm;
                    height: 29mm;
                    text-align: center;                   
                }  
                .properties {
                    position: absolute;
                    left: 78mm;
                    top: 172mm;                    
                    width: 195mm;
                    height: 10mm;
                    text-align: left;                   
                }  
                .senior {
                    position: absolute;
                    left: 103mm;
                    top: 185mm;                    
                    width: 90mm;
                    height: 16mm;
                    text-align: center;                   
                }
                
                .dummy-child { height: 100%; }

                .valign {
                   
                }
                
                .dummy-child, .valign {
                    display: inline-block;
                    vertical-align: middle;
                }
                
                 }                                                                  
            </style>`;

        print(style + ReactDOMServer.renderToStaticMarkup(<ContractPrintComponent
            {...this.refs.form.props.data.printForm}
        />));


    }

    printRouteMap() {
        const style = `
            <style>
                @page {
                    size: A4 landscape;
                    margin: 0;
                }
                
                @media print {
  
                .contract-number {
                    position: absolute;
                    left: 210mm;
                    top: 18mm;
                }

                .date-from {
                    position: absolute;
                    left: 97mm;
                    top: 50mm;
                    width: 45mm;
                    text-align: center;
                   
                } 
                .year-from {
                    position: absolute;
                    left: 166mm;
                    top: 50mm;
                    width: 12mm;
                    text-align: center;
                   
                } 
                .date-to {
                    position: absolute;
                    left: 196mm;
                    top: 50mm;
                    width: 54mm;
                    text-align: center;
                   
                } 
                .year-to {
                    position: absolute;
                    left: 260mm;
                    top: 50mm;
                    width: 12mm;
                    text-align: center;                    
                }  
                .registration-number {
                    position: absolute;
                    left: 75mm;
                    top: 69mm;
                    width: 68mm;
                    height: 22mm;
                    text-align: center;
                } 
                .number {
                    position: absolute;
                    left: 143mm;
                    top: 69mm;
                    width: 68mm;
                    height: 22mm;
                    text-align: center;                
                }  
                .title {
                    position: absolute;
                    left: 210mm;
                    top: 69mm;
                    width: 68mm;
                    height: 22mm;
                    text-align: center;  
                    vertical-align: middle;              
                }   
                .carrier {
                    position: absolute;
                    left: 75mm;
                    top: 97mm;                    
                    width: 68mm;
                    height: 22mm;
                    text-align: center;
                    vertical-align: middle;                   
                }   
                .place {
                    position: absolute;
                    left: 145mm;
                    top: 97mm;                    
                    width: 65mm;
                    height: 22mm;
                    text-align: center;
                    vertical-align: middle;                    
                    font-size: ${this.refs.form.props.data.printForm.place && this.refs.form.props.data.printForm.place.length > 150 ? `12px` : '16px'} !important;                  
                }   
                .inn {
                    position: absolute;
                    left: 210mm;
                    top: 97mm;                    
                    width: 68mm;
                    height: 22mm;
                    text-align: center;
                    vertical-align: middle;                   
                }  
                .ts-type {
                    position: absolute;
                    left: 75mm;
                    top: 120mm;                    
                    width: 42mm;
                    height: 29mm;
                    text-align: center;
                    /*border: 3px solid #000;    */
                }  
                .ts-class {
                    position: absolute;
                    left: 160mm;
                    top: 120mm;                    
                    width: 42mm;
                    height: 29mm;
                    text-align: center;                   
                }  
                .properties {
                    position: absolute;
                    left: 75mm;
                    top: 145mm;                    
                    width: 205mm;
                    height: 29mm;
                    text-align: left;                   
                }  
                .senior {
                    position: absolute;
                    left: 103mm;
                    top: 185mm;                    
                    width: 90mm;
                    height: 16mm;
                    text-align: center;                   
                }
                
                .dummy-child { height: 100%; }

                .valign {
                   
                }
                
                .dummy-child, .valign {
                    display: inline-block;
                    vertical-align: middle;
                }

                table {
                    width: 100%;
                    border-collapse: collapse;
                    text-align: center;
                    margin-bottom: 60px;
                }

                th, td {
                    padding: 5px;
                }
                
                td.left {
                    text-align: left;
                }

                .contract {
                   display: flex;
                   justify-content: space-between;
                   padding: 0 72px;
                   margin-bottom: 36px;
                }
                
                .contract-status, .contract-dates {
                    padding: 12px 8px;
                    display: inline-block;
                }

                .contract-dates {
                    font-weight: bold;
                }

                .form-title {
                    text-align: center;
                    margin-bottom: 36px;
                    font-size: 24px;
                    font-weight: bold;
                    padding-top: 36px;
                }

                .org-name {
                    text-align: center;
                    margin-bottom: 24px;
                }

                .bottom {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 24px;
                }

                .sign, .initials, .stamp {
                    text-align: center;
                    font-size: 12px;
                }

                .sign, .initials {
                   border-top: 1px solid black;
                   flex-basis: 180px;
                }
                
                }                                                                  
            </style>
        `;

        print(style + ReactDOMServer.renderToStaticMarkup(<RouteRegisterPrintComponent
            {...this.refs.form.props.data.printForm}
        />));

    }


    clearErrors() {
        this.setState({errors: {}});
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    onCreate(e) {
        e && e.preventDefault();
        if (!this.refs.form) return;

        const state = this.refs.form.getWrappedInstance().getData();
        
        this.create(state);
    }

    onEdit(e) {
        e && e.preventDefault();
        if (!this.refs.form) return;

        const state = this.refs.form.getWrappedInstance().getData();
        this.edit(state);
    }

    async create(data) {
        this.clearErrors();
        this.startSave();
        const response = await this.createItem(this.composeItem(data));
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit(response.payload);
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async createBatch(data, vehicles) {
        this.clearErrors();
        this.startSave();

        const responses = await Promise.all(
            vehicles.map(async (vehicle) => {
                const { driver_uuid, base_vehicle_uuid } = vehicle;
                return await this.createItem({
                    ...data,
                    driver_uuid,
                    vehicle_uuid: base_vehicle_uuid
                })
            })
        )

        this.endSave();

        responses.forEach(response => {
            if (!response.isOk) {
                this.setState({
                    errors: response.validationErrors
                });
                response.showErrors();
            }
        })
        return responses;
    }

    async edit(data) {
        console.log('edit', data)
        this.clearErrors();
        this.startSave();

        const response = await this.updateItem(this.composeItem(data));
        console.log(response)

        this.endSave();
        if (response.isOk) {
            this.props.onSubmit(response.payload);
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    composeItem(data) {
        return _.clone(data);
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }
}
