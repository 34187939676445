import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import Page from "components/ui/page";
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import {api} from "helpers/api";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import GlobalLoaderComponent from "components/ui/global-loader";
import systems from "dictionaries/systems";
import TabBlock from "components/ui/tabs/tab-block";
import '../style.less';
import Button from "components/ui/button";
import {createWaybill, getWaybill, getWaybills, updateWaybill} from "store/reducers/kurs/waybills";
import {getTask, getTasks} from "store/reducers/kurs/tasks";
import {User} from "helpers/user";
import {getUsers} from "store/reducers/staffing/staffing";
import {getVehicle, getVehicleList} from "store/reducers/vehicles/vehicles";
import moment from "moment";
import formats from "dictionaries/formats";
import * as alerts from "helpers/alerts";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import {getVehicleMechanisms} from "store/reducers/kurs/vehicle_mechanisms";
import {getVehicles} from "store/reducers/kurs/vehicles";
import FilterHeader from "components/ui/filter-header";
import './index.less';
import {print} from "helpers/print";
import {motohoursCorrrection} from "helpers/kiutr";
import {getUser} from "store/reducers/staffing/staffing_editor";
import {
    printWaybillEsm2,
    printWaybillNum3, printWaybillNum412, printWaybillNum6, printWaybillP,
    printWaybillS
} from "components/modules/kurs/waybills/WaybillPrint/index";
import {Link} from "react-router";
import currentUser from 'helpers/current-user';
import debounce from 'throttle-debounce/debounce';

@connect(state => ({}), {
    getWaybill,
    createWaybill,
    updateWaybill,
    getUnit,
    getVehicle,
    getUser,
    getVehicleMechanisms,
    getVehicles,
    getDictionaryList
})

export default class KursWaybillEditor extends BaseEditor {

    modelClass = 'App\\Model\\Waybill';

    componentWillMount() {
        this.loadDictionaries([
            'vehicle_marks',
        ]);
    }

    async componentWillUpdate(props, state) {
        const propsUuid = (props.params.uuid === 'create') ? null : props.params.uuid;
        if (state.uuid !== propsUuid) {
            await this.setState({
                uuid: propsUuid,
                item: null,
                isLoading: !!propsUuid,
                mode: propsUuid ? 'edit' : 'add',
                itemIndex: _.toInteger(this.state.itemIndex) + 1,
                formUpdated: propsUuid ? false : true,
            });
            if (propsUuid) {
                const response = await this.loadData(propsUuid);
                if (response.isOk) {
                    this.setState({
                        item: response.payload,
                        isLoading: false,
                    });
                } else {
                    response.showErrors();
                }
            }
        }
    }

    componentDidMount() {
        this.forceUpdate();
    }

    componentWillReceiveProps(props) {
    }


    async loadData(uuid) {
        const response = await this.props.getWaybill(uuid);

        if (response.isOk) {
            // коррекция моточасов
            motohoursCorrrection(response.payload);
            this.loadUnit(response.payload.unit_uuid);
            this.loadVehicle(response.payload.vehicle_uuid);
            this.loadDriver(response.payload.driver_uuid);
            this.loadVehicleTrailer(response.payload.vehicle_uuid);
        }

        return response;
    }

    async createItem(data) {
        return await this.props.createWaybill(data);
    }

    async updateItem(data) {
        return await this.props.updateWaybill(data);
    }

    async loadUnit(uuid) {
        if (!uuid) {
            return null;
        }
        const response = await this.props.getUnit(uuid);
        const { workday_begin, workday_finish } = response.payload;

        if (response.isOk) {
            this.setState(prev => ({
                unit: response.payload,
                item: { ...prev.item, departure_time: workday_begin, arrival_time: workday_finish }
            }));
        } else {
            response.showErrors();
        }
    }


    async loadVehicle(uuid) {
        if (!uuid) {
            return null;
        }
        const response = await this.props.getVehicle(uuid);

        if (response.isOk) {
            this.setState({
                vehicle: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadDriver(uuid) {
        if (!uuid) {
            return null;
        }
        const response = await this.props.getUser(uuid);

        if (response.isOk) {
            this.setState({
                driver: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadVehicleTrailer(uuid) {
        if (!uuid) {
            return;
        }
        const response = await this.props.getVehicleMechanisms({
            filters: {
                withVehicle: uuid,
            },
        });
        if (response.isOk) {
            const trailerUuid = _.get(_.find(_.filter(response.payload.items, (mechanism) => !mechanism.dismantle_date), (item) => item.trailer_uuid), 'trailer_uuid');
            this.loadTrailer(trailerUuid);
        } else {
            response.showErrors();
        }
    }

    async loadTrailer(uuid) {
        if (!uuid) {
            return;
        }

        const response = await this.props.getVehicles({
            filters: {
                withUuid: [uuid],
            },
        });

        if (response.isOk) {
            this.setState({
                trailer: _.first(response.payload.items),
            });
        } else {
            response.showErrors();
        }
    }

    getForm(item, onSubmit) {

        if (!item.status) {
            item.status = 'draft';
            item.date = moment().format(formats.DATE_API);
        }

        return (
            <EditorForm
                key={this.state.itemIndex}
                {...this.props}
                ref="form"
                mode={this.state.mode}
                onClose={::this.onClose}
                data={item}
                errors={this.state.errors}
                onSubmit={onSubmit}
                onUpdate={::this.onFormUpdate}
            />
        );
    }

    onFormUpdate() {
        this.setState({
            formUpdated: true,
        });
    }

    onClose() {
        if (this.state.formUpdated) {
            alerts.prompt('Вы действительно хотите закрыть эту страницу без сохранения данных?', '', () => {
                this.props.router.push('/road/waybills');
            }, 'Закрыть');
        } else {
            this.props.router.push('/road/waybills');
        }
    }

    async create(data) {
        this.clearErrors();
        this.startSave();

        const newData = {
            ...data,
            arrival_date: moment(data.arrival_date).format(),
            departure_date: moment(data.departure_date).format()
        }

        const response = await this.createItem(this.composeItem(newData));

        this.endSave();
        if (response.isOk) {
            this.props.router.push(`/road/waybills/${response.payload.uuid}`);
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();

        if ((this.state.item.status !== 'closed') && (data.status === 'closed')) {
            alerts.prompt('ВНИМАНИЕ. После закрытия путевого листа данные в нём НЕДОСТУПНЫ для редактирования. Проверьте заполнение полей', '', async () => {
                this.realEdit(data);
            }, 'Продолжить', () => {
                this.endSave();
            });
        } else {
            this.realEdit(data);
        }
    }

    async realEdit(data) {
        const response = await this.updateItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            await this.setState({
                item: response.payload,
                formUpdated: false,
                itemIndex: this.state.itemIndex + 1,
            });
        } else {
            this.setState({
                errors: response.validationErrors,
            });
            if (_.keys(response.validationErrors).length > 0) {
                alerts.error('Изменения не сохранены. Проверьте заполнение полей');
            }
            response.showErrors();
        }
    }

    getDefaultItem() {
        let item = {};

        const vehicleUuid = this.props.location.query.vehicleUuid;
        if (vehicleUuid) {
            item.vehicle_uuid = vehicleUuid;
        }
        const taskUuid = this.props.location.query.taskUuid;
        if (taskUuid) {
            item.task_uuid = taskUuid;
        }
        const driverUuid = this.props.location.query.driverUuid;
        if (driverUuid) {
            item.driver_uuid = driverUuid;
        }
        const unitUuid = this.props.location.query.unitUuid;
        if (unitUuid) {
            item.unit_uuid = unitUuid;
        }

        return item;
    }

    createBatchWaybill = async (data) => {
        if (this.props.location.state &&
            this.props.location.state.resources &&
            this.props.location.state.resources.length) {
                const newData = {
                    ...data,
                    arrival_date: moment(data.arrival_date).format(),
                    departure_date: moment(data.departure_date).format()
                }
                const response = await this.createBatch(newData, this.props.location.state.resources);
                if (response[0].isOk) {
                    this.props.router.push({
                        pathname: `/road/tasks/${this.props.location.query.taskUuid}/?showVehicleList=${true}`
                    })
                } else {
                    const textError = response[0]?.errors[0]?.text || 'Ошибка валидации :('
                    alerts.error(textError);
                }
            }
    }

    render() {
        let title = 'Создание путевого листа';

        let form;
        let onSubmit;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.state.mode === 'edit') {
            title = '';

            if (this.state.item) {
                title = `Путевой лист №${this.state.item.number}`;
                form = this.getForm(this.state.item, ::this.edit);
            }

            onSubmit = () => {
                let data = this.refs.form.refs.wrappedInstance.state.waybill;

                // если есть дата возвращения
                if (data.arrival_date) {

                     let departureDate = moment(`${data.departure_date.substring(0,10)} ${data.departure_time}`);
                     let arrivalDate = moment(`${data.arrival_date.substring(0,10)} ${data.arrival_time}`);

                    if (!departureDate.isBefore(arrivalDate)) {
                        alerts.error('Дата и время выезда не может быть больше даты и времени возвращения');
                    } else {
                        this.onEdit()
                    }

                } else {
                    this.onEdit()
                }

                if (data.fact_arrival_date) {
                   let factDepartureDate = moment(`${data.fact_departure_date.substring(0,10)} ${data.fact_departure_time}`);
                   let factArrivalDate = moment(`${data.fact_arrival_date.substring(0,10)} ${data.fact_arrival_time}`);

                   if (!factDepartureDate.isBefore(factArrivalDate)) {
                       alerts.error('Дата и время выезда не может быть больше даты и времени возвращения');

                   } else {
                       this.onEdit()
                   }
               } else {
                   // this.onEdit()
               }
                this.setState({
                    isLoading: false,
                    saving: false,
                });
            };

        } else if (this.state.mode === 'add') {
            form = this.getForm(this.getDefaultItem(), ::this.create);

            onSubmit = () => {
                let data = _.cloneDeep(this.refs.form.refs.wrappedInstance.state.waybill);
                
                if (data.departure_date) {

                    let departureDate = moment(moment(data.departure_date).format(formats.DATE_URL) + " " + data.departure_time)
                    let arrivalDate = moment(moment(data.arrival_date).format(formats.DATE_URL) + " " + data.arrival_time)

                    if (data.departure_time && data.arrival_time && !departureDate.isBefore(arrivalDate)) {
                        alerts.error('Дата и время выезда не может быть больше даты и времени возвращения!');
                        return;
                    }
                }

                if (this.props.location.query.waybillBatch) {
                    this.createBatchWaybill(data);
                } else {
                    this.onCreate();
                }
            };

        }

        return (
            <Page
                pageId="PlanningWaybill"
                title={`${systems.road} → ${title}`}
                headerActions={this.renderHeaderActions()}
            >
                {loader}
                {form}
                {(this.state.formUpdated && !this.state.saving) ? (
                    <div className="page-footer">
                        <div className="page-footer__txt">Вы хотите сохранить все изменения?</div>
                        <Button size="md" color="white" shadow="gray" className="b-button_cancel" text="Отменить"
                                onClick={::this.onReset}/>
                        <Button size="md" color="red" className="b-button_save" text="Сохранить"
                                onClick={onSubmit}/>
                    </div>
                ) : null}
            </Page>
        );
    }

    gotoWaybillCreate() {
        this.props.router.push(`/road/waybills/create`);
    }

    renderHeaderActions() {
        return [
            ((this.state.mode === 'edit') && currentUser.can('com.rnis.system.permission.audit', 'read') && this.modelClass) ?
                (
                    <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                    default="Журнал аудита">
                        <IconButton icon="history" onClick={::this.gotoAudit}/>
                    </ContextTooltip>
                ) : null,
            <ContextTooltip key="kurs.task.waybill-create" code="kurs.task.waybill-create"
                            default="Создать путевой лист">
                <IconButton
                    icon="plus"
                    tooltip="Создать путевой лист"
                    onClick={::this.gotoWaybillCreate}
                />
            </ContextTooltip>,
            ((this.state.mode === 'edit') && this.state.item && this.state.unit && this.state.vehicle) ? [
                <ContextTooltip key="kurs.waybill.print" code="kurs.waybill.print" default="Печать">
                    <IconButton icon="print" onClick={::this.printDialog}/>
                </ContextTooltip>,
            ] : null,
            <ContextTooltip key="kurs.sto.back" code="kurs.sto.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ];
    }

    gotoAudit() {
        const url = `/system/audit/${this.state.uuid}?class=${this.modelClass}`;

        this.props.router.push(url);
    }

    printDialog() {
        alerts.askSelect('Печатные формы', [
            {
                value: 'esm2',
                label: 'ЭСМ-2'
            },
            {
                value: 'num3',
                label: 'Типовая межотраслевая форма №3'
            },
            {
                value: 'num412',
                label: 'Форма №412-АПК'
            },
            {
                value: 'num6',
                label: 'Типовая межотраслевая форма №6'
            },
            {
                value: '4s',
                label: '4С'
            },
            {
                value: '4p',
                label: '4П'
            },
        ], (value) => {
            switch (value) {
                case 'esm2':
                    this.printEsm2();
                    break;
                case 'num3':
                    this.printNum3();
                    break;
                case 'num412':
                    this.printNum412();
                    break;
                case 'num6':
                    this.printNum6();
                    break;
                case '4s':
                    this.printS();
                    break;
                case '4p':
                    this.printP();
                    break;
            }
        }, 'Печать');
    }

    printEsm2() {
        print(printWaybillEsm2({
            waybill: this.state.item,
            unit: this.state.unit,
            vehicle: this.state.vehicle,
            vehicle_marks: this.state.vehicle_marks,
            driver: this.state.driver,
            trailer: this.state.trailer,
        }));
    }

    printNum412() {
        print(printWaybillNum412({
            waybill: this.state.item,
            unit: this.state.unit,
            vehicle: this.state.vehicle,
            vehicle_marks: this.state.vehicle_marks,
            driver: this.state.driver,
            trailer: this.state.trailer,
        }));
    }

    printNum3() {
        print(printWaybillNum3({
            waybill: this.state.item,
            unit: this.state.unit,
            vehicle: this.state.vehicle,
            vehicle_marks: this.state.vehicle_marks,
            driver: this.state.driver,
            trailer: this.state.trailer,
        }));
    }

    printNum6() {
        print(printWaybillNum6({
            waybill: this.state.item,
            unit: this.state.unit,
            vehicle: this.state.vehicle,
            vehicle_marks: this.state.vehicle_marks,
            driver: this.state.driver,
            trailer: this.state.trailer,
        }));
    }

    printS() {
        print(printWaybillS({
            waybill: this.state.item,
            unit: this.state.unit,
            vehicle: this.state.vehicle,
            vehicle_marks: this.state.vehicle_marks,
            driver: this.state.driver,
            trailer: this.state.trailer,
        }));
    }

    printP() {
        print(printWaybillP({
            waybill: this.state.item,
            unit: this.state.unit,
            vehicle: this.state.vehicle,
            vehicle_marks: this.state.vehicle_marks,
            driver: this.state.driver,
            trailer: this.state.trailer,
        }));
    }

    onReset() {
        this.refs.form.getWrappedInstance().onReset();
        this.setState({
            formUpdated: false,
        });
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getDictionaryList,
    getUnits,
    getTasks,
    getUsers,
    getVehicleList,
    getVehicleMechanisms,
    getVehicles,
    getTask,
    getWaybills,
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        waybill: {},
        units: [],
        tasks: [],
        kurs_task_statuses: [],
        vehicles: [],
        trailer: null,
        fuel_norma: null,
        waybill_vehicles: [],
    };

    statuses = {
        draft: 'Черновик',
        opened: 'Открыт',
        closed: 'Закрыт',
    };

    getData() {
        return this.state.waybill;
    }

    onReset() {
        this.setState({
            waybill: _.cloneDeep(this.props.data),
        });
    }

    loadUnitsDebounce = debounce(500, ::this.loadUnits)
    loadUsersDebounce = debounce(500, ::this.loadDriversForSelect)

    async componentDidMount() {
        const { departure_date, arrival_date } = this.props.data;
        let departure_time, arrival_time;

        const waybill = _.cloneDeep(this.props.data);

        if (window.RNIS_SETTINGS.waybill_batch && this.props.location.query.waybillBatch) {
            waybill.status = 'opened';
        }

        if (waybill.unit_uuid && this.props.mode === "add") {
            const time = await this.loadUnitInfo(waybill.unit_uuid);
            if (time.isOk) {
            departure_time = time.workday_begin;
            arrival_time = time.workday_finish;
            }
        }

        if(arrival_time && departure_time) {
            await this.setState({
                waybill: {
                    ...waybill,
                    arrival_date: arrival_date ? arrival_date : moment().set({hour:0,minute:0,second:0,millisecond:0}),
                    departure_date: departure_date ? departure_date : moment().set({hour:0,minute:0,second:0,millisecond:0}),
                    arrival_time: arrival_time,
                    departure_time: departure_time,
                },
            });
        } else {
            await this.setState({
                waybill: {
                    ...waybill,
                    arrival_date: arrival_date ? arrival_date : moment().set({hour:0,minute:0,second:0,millisecond:0}),
                    departure_date: departure_date ? departure_date : moment().set({hour:0,minute:0,second:0,millisecond:0}),
                },
            });
        }

        this.loadVehicleTrailer();

        await this.loadDictionaries([
            'kurs_task_statuses',
            'kurs_formulas',
            'vehicle_models',
        ], 'road');
        await this.loadTasks();
        this.loadVehicles();
        this.loadWaybills();
    }

    async loadWaybills() {
        const task = this.get('task_uuid');
        if (!task) {
            this.setState({
                waybill_vehicles: [],
            });
            return;
        }

        const response = await this.props.getWaybills({
            filters: {
                withTask: task,
            },
        });

        if (response.isOk) {
            response.payload.items = response.payload.items.map((item) => {
                return motohoursCorrrection(item);
            });

            this.setState({
                waybill_vehicles: _.map(_.filter(response.payload.items, (item) => {
                    return item.uuid !== this.props.params.uuid;
                }), 'vehicle_uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadUnitInfo(unit_uuid) {

        function formatTimeFunc(time) {
            if(time.match(/:/g).length === 2) {
                return time.slice(0,5)
            } else {
                return time
            }
        }

        const response = await this.props.getUnits({
            filters: {
                withComponent: 'road',
                withUuid: [unit_uuid],
            },
        });
        if (response.isOk && response.payload.items[0].workday_begin && response.payload.items[0].workday_finish) {
            const {workday_begin, workday_finish} = response.payload.items[0];
            return {workday_begin: formatTimeFunc(workday_begin), workday_finish: formatTimeFunc(workday_finish), isOk: true}
        } else {
            return {isOk: false}
        }
    }

    async loadUnits(input, callback) {

        let uuid = this.state.waybill.unit_uuid || null;

        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            search: input ? input : undefined,
            filters: {
                withComponent: 'road',
                withUuid: !input ? [uuid] : undefined,
            },
        });

        if (response.isOk) {
            callback(null, {
                options: _.sortBy(response.payload.items.map((i) => {

                    let newItem = {
                        label: i.name,
                        value: i.uuid,
                        fullName: i.name_full,
                    }

                    if (i.workday_begin) {
                        newItem["workday_begin"] = i.workday_begin
                    }
                    if(i.workday_finish) {
                        newItem["workday_finish"] = i.workday_finish
                    }
                    return (newItem)
                }), 'label'),
                complete: false
            });

        } else {
            response.showErrors();
        }

    }

    async loadTasks() {
        const filters = (this.props.mode !== 'edit') ? {
            withStatus: _.get(_.find(this.state.kurs_task_statuses, {label: 'Открыт'}), 'value'),
        } : {
            withUuid: [this.get('task_uuid')],
        };

        const response = await this.props.getTasks({
            filters,
        });

        if (response.isOk) {
            this.setState({
                tasks: _.sortBy(_.map(response.payload.items, (task) => ({
                    value: task.uuid,
                    label: task.number,
                    unit_uuid: task.unit_uuid,
                    resources: task.resources,
                })), 'label'),
            });
        } else {
            response.showErrors();
        }
    }

    async onTaskChange(e) {
        const value = e ? e.value : null;

        this.setValue('waybill.task_uuid', value);

        if (e) {
            this.setValue('waybill.unit_uuid', e.unit_uuid);
        }
        this.loadVehicles();
    }

    async onVehicleChange(e) {
        const value = e ? e.value : null;

        await this.setValue('waybill.vehicle_uuid', value);

        this.loadVehicleTrailer();

        const taskUuid = this.get('task_uuid');
        const task = _.find(this.state.tasks, {value: taskUuid});
        if (task) {
            const driverUuid = _.get(_.find(task.resources, {base_vehicle_uuid: value}), 'driver_uuid');
            this.setValue('waybill.driver_uuid', driverUuid);

            this.refs.driver && this.refs.driver.reload();
        }
    }

    onСompanyChange = async (e) => {
        const value = e ? e.value : null;
        await this.setValue('waybill.unit_uuid', value);

        if(e && e.workday_begin) {
            this.setValue('waybill.departure_time', e.workday_begin);
        }
        if(e && e.workday_finish) {
            this.setValue('waybill.arrival_time', e.workday_finish);
        }

        if(e === null) {
            let copyWaybill = _.cloneDeep(this.state.waybill)

            if(copyWaybill.arrival_time) {
                delete copyWaybill.arrival_time
            }
            if(copyWaybill.departure_time) {
                delete copyWaybill.departure_time
            }
            this.setValue('waybill', copyWaybill);
        }
    }

    async loadVehicles() {
        const taskUuid = this.get('task_uuid');
        const task = _.find(this.state.tasks, {value: taskUuid});
        if (!taskUuid || !task) {
            this.setState({
                vehicles: [],
            });

        } else {
            const response = await this.props.getVehicleList({
                filters: {
                    withUuid: _.map(task.resources, 'base_vehicle_uuid'),
                },
            });

            if (response.isOk) {
                this.setState({
                    vehicles: _.map(response.payload.items, i => ({
                        value: i.uuid,
                        label: i.state_number,
                    })),
                });
            } else {
                response.showErrors();
            }
        }
    }

    async loadVehicleTrailer() {
        if (!this.get('vehicle_uuid')) {
            this.setState({
                trailer: null,
            });
            return;
        }
        const response = await this.props.getVehicleMechanisms({
            filters: {
                withVehicle: this.get('vehicle_uuid'),
            },
        });
        if (response.isOk) {
            const trailerUuid = _.get(_.find(_.filter(response.payload.items, (mechanism) => !mechanism.dismantle_date), (item) => item.trailer_uuid), 'trailer_uuid');
            this.loadTrailer(trailerUuid);
        } else {
            response.showErrors();
        }
    }

    async loadTrailer(uuid) {
        if (!uuid) {
            return;
        }

        const response = await this.props.getVehicles({
            filters: {
                withUuid: [uuid],
            },
        });

        if (response.isOk) {
            this.setState({
                trailer: _.get(_.first(response.payload.items), 'state_number'),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.waybill, path, defaultValue);
    }

    async openWaybill() {
        await this.setValue('waybill.status', 'opened');

        this.props.onSubmit(this.getData());
    }

    async closeWaybill() {
        await this.setValue('waybill.status', 'closed');

        this.props.onSubmit(this.getData());
    }

    getStatuses() {
        // При пакетном создании ПЛ создавать сразу со статусом "открытый"
        if (window.RNIS_SETTINGS.waybill_batch && this.props.location.query.waybillBatch) {
            return {
                opened: 'Открыт'
            };
        }
        const status = _.get(this.props.data, 'status');
        switch (status) {
            case 'closed':
                return {
                    closed: 'Закрыт',
                };
            case 'opened':
                return {
                    opened: 'Открыт',
                    closed: 'Закрыт',
                };
        }
        if (this.props.mode === 'add') {
            return {
                draft: 'Черновик',
            };
        }
        return {
            draft: 'Черновик',
            opened: 'Открыт',
            closed: 'Закрыт',
        };
    }

    getVehicles() {
        return _.filter(this.state.vehicles, (vehicle) => {
            return this.state.waybill_vehicles.indexOf(vehicle.value) === -1;
        });
    }

    render() {
        if (_.isEmpty(this.state.waybill)) {
            return null;
        }

        return (
            <div className="page-block">
                <TabBlock withBorder={false}>
                    <div className="page-block__content _lg">
                        <Block title="Предприятие">
                             {this.selectAsync('waybill.unit_uuid', (this.state.waybill.unit_uuid ? ::this.loadUnits : ::this.loadUnitsDebounce), {
                                disabled: this.get('status') !== 'draft',
                                onChange: this.onСompanyChange
                            })}
                        </Block>
                        <Block size="xs" title="Дата составления">
                            {this.datepicker('waybill.date', {
                                disabled: this.get('status') !== 'draft',
                            })}
                        </Block>
                        <Block size="xs" title="Задание №">
                            {(this.props.mode === 'edit') ? (
                                <Link
                                    to={`/road/tasks/${this.get('task_uuid')}`}>{_.get(_.find(this.state.tasks, {value: this.get('task_uuid')}), 'label')}</Link>
                            ) : this.select('waybill.task_uuid', this.state.tasks, {
                                onChange: ::this.onTaskChange,
                                disabled: this.props.mode === 'edit',
                            })}
                        </Block>
                        <Block size="sm" title="Дата предрейсового контроля">
                            {this.datepicker('waybill.control_date')}
                        </Block>
                        <Block size="sm" title="Время предрейсового контроля">
                            {this.maskInput('waybill.control_time', '99:99', {
                                withTimeIcon: true,
                            })}
                        </Block>
                        <Block size="sm" title="Статус">
                            {this.select('waybill.status', _.map(this.getStatuses(), (label, value) => ({
                                value,
                                label,
                            })))}
                        </Block>
                    </div>

                    <div className="page-block__content _lg">
                        <Block size="md" title="Водитель">
                            {this.selectAsync('waybill.driver_uuid',(this.state.waybill.driver_uuid ? ::this.loadDriversForSelect : this.loadUsersDebounce), {
                                disabled: this.get('status') !== 'draft',
                                ref: 'driver',
                            })}
                        </Block>
                        <Block size="md" title="ТС">
                            {this.select('waybill.vehicle_uuid', this.getVehicles(), {
                                onChange: ::this.onVehicleChange,
                                disabled: this.get('status') !== 'draft',
                            })}
                        </Block>
                        <Block size="md" title=""/>
                        <Block size="md" title="Прицеп">
                            {this.state.trailer}
                        </Block>
                    </div>
                    {(this.get('status') !== 'draft') ? (
                        <FilterHeader key="filter_header"
                                      items={['Данные по графику (плановые)', 'Данные по факту выполнения']}
                                      classNames={[
                                          this.hasErrorInFields([
                                              'departure_date',
                                              'departure_time',
                                              'departure_mileage',
                                              'arrival_date',
                                              'arrival_time',
                                              'arrival_mileage',
                                              'fuel_issued',
                                              'fuel_spent',
                                              'departure_fuel_level',
                                              'arrival_fuel_level',
                                          ]) ? 'has-error' : '',
                                          this.hasErrorInFields([
                                              'fact_departure_date',
                                              'fact_departure_time',
                                              'fact_departure_odometer',
                                              'shift_start_time',
                                              'manual_departure_odometer',
                                              'departure_motohours',
                                              'fact_arrival_date',
                                              'fact_arrival_time',
                                              'fact_distance',
                                              'fact_arrival_odometer',
                                              'total_bnso_mileage',
                                              'shift_end_time',
                                              'arrival_motohours',
                                              'motohours',
                                              'fuel_consumption_standart',
                                              'fuel_consumption_fact',
                                          ]) ? 'has-error' : '',
                                      ]}
                                      currentItem={this.state.currentFilterItem || 0}
                                      onChange={(e) => {
                                          this.setState({currentFilterItem: e.value});
                                      }}
                        />
                    ) : null}
                    {((this.state.currentFilterItem || 0) === 0) ? (
                        <div>
                            <div className="page-block__content _lg">
                                <div className="page-block__title">Выезд по графику</div>
                                <Block size="sm" title="Дата выезда">
                                    {this.datepicker('waybill.departure_date', {
                                       disabled: (this.get('status') === 'closed'),
                                    })}
                                </Block>
                                <Block size="sm" title="Время выезда">
                                    {this.maskInput('waybill.departure_time', '99:99', {
                                        withTimeIcon: true,
                                        disabled: (this.get('status') === 'closed'),
                                    })}
                                </Block>
                                <Block size="sm" title="Расстояние, км">
                                    {this.textInput('waybill.departure_mileage', {
                                        disabled: (this.get('status') === 'closed') || (this.get('status') === 'draft'),
                                    })}
                                </Block>
                                <div className="page-block__title">Возвращение по графику</div>
                                <Block size="sm" title="Дата возвращения">
                                    {this.datepicker('waybill.arrival_date', {
                                        disabled: (this.get('status') === 'closed'),
                                    })}
                                </Block>
                                <Block size="sm" title="Время возвращения">
                                    {this.maskInput('waybill.arrival_time', '99:99', {
                                        withTimeIcon: true,
                                        disabled: (this.get('status') === 'closed'),
                                    })}
                                </Block>
                                <Block size="sm" title="Расстояние, км">
                                    {this.textInput('waybill.arrival_mileage', {
                                        disabled: (this.get('status') === 'closed') || (this.get('status') === 'draft'),
                                    })}
                                </Block>
                            </div>
                            <div className="page-block__content _lg">
                                <div className="page-block__title">Топливо до поездки</div>
                                <Block size="xs" title="Выдано, л">
                                    {this.textInput('waybill.fuel_issued', {
                                        disabled: (this.get('status') === 'closed') || (this.get('status') === 'draft'),
                                    })}
                                </Block>
                                <Block size="xs" title="Израсходовано, л">
                                    {this.textInput('waybill.fuel_spent', {
                                        disabled: (this.get('status') === 'closed') || (this.get('status') === 'draft'),
                                    })}
                                </Block>
                                <Block size="xs" title="Остаток при выезде, л">
                                    {this.textInput('waybill.departure_fuel_level', {
                                        disabled: (this.get('status') === 'closed') || (this.get('status') === 'draft'),
                                    })}
                                </Block>
                                <Block size="xs" title="Остаток при возвр., л">
                                    {this.textInput('waybill.arrival_fuel_level', {
                                        disabled: (this.get('status') === 'closed') || (this.get('status') === 'draft'),
                                    })}
                                </Block>
                                <div className="page-block__title">Моточасы план</div>
                                <Block size="sm" title="Моточасы">
                                    {this.textInput('waybill.plan_motohours', {
                                        disabled: true,
                                    })}
                                </Block>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="page-block__content _lg">
                                <div className="page-block__title">Выезд факт</div>
                                <Block size="sm" title="Дата выезда">
                                    {this.datepicker('waybill.fact_departure_date', {
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'opened'),
                                    })}
                                </Block>
                                <Block size="sm" title="Время выезда">
                                    {this.maskInput('waybill.fact_departure_time', '99:99', {
                                        withTimeIcon: true,
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'opened'),
                                    })}
                                </Block>
                                <Block size="sm" title="Показания одометра, км">
                                    {this.textInput('waybill.fact_departure_odometer', {
                                        disabled: true,
                                    })}
                                </Block>
                                <Block size="sm" title="Время начала смены">
                                    {this.maskInput('waybill.shift_start_time', '99:99', {
                                        withTimeIcon: true,
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'opened'),
                                    })}
                                </Block>
                                <Block size="sm" title="Показания одометра ручной ввод, км">
                                    {this.textInput('waybill.manual_departure_odometer', {
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'closed'),
                                    })}
                                </Block>
                                <Block size="sm" title="Моточасы при выезде, ч">
                                    {this.textInput('waybill.departure_motohours', {
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'closed'),
                                    })}
                                </Block>
                                <div className="page-block__title">Возвращение факт</div>
                                <Block size="sm" title="Дата возвращения">
                                    {this.datepicker('waybill.fact_arrival_date', {
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'closed'),
                                    })}
                                </Block>
                                <Block size="sm" title="Время возвращения">
                                    {this.maskInput('waybill.fact_arrival_time', '99:99', {
                                        withTimeIcon: true,
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'closed'),
                                    })}
                                </Block>
                                <Block size="sm" title="Расстояние факт, км">
                                    {this.textInput('waybill.fact_distance', {
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'closed'),
                                    })}
                                </Block>
                                <Block size="sm" title="Показания одометра, км">
                                    {this.textInput('waybill.fact_arrival_odometer', {
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'closed'),
                                    })}
                                </Block>
                                <Block size="sm" title="Расстояние по БНСО">
                                    {this.textInput('waybill.total_bnso_mileage', {
                                        disabled: true,
                                    })}
                                </Block>
                                <Block size="sm" title="Время окончания смены">
                                    {this.maskInput('waybill.shift_end_time', '99:99', {
                                        withTimeIcon: true,
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'closed'),
                                    })}
                                </Block>
                                <Block size="sm" title="Моточасы при возращении, ч">
                                    {this.textInput('waybill.arrival_motohours', {
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'closed'),
                                    })}
                                </Block>
                                <Block size="sm" title="Моточасы всего, ч">
                                    {this.textInput('waybill.motohours', {
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'closed'),
                                    })}
                                </Block>
                            </div>
                            <div className="page-block__content _lg">
                                <div className="page-block__title">Топливо на момент выполнения работ</div>
                                <Block size="xs" title="Расход по норме, л">
                                    {this.textInput('waybill.fuel_consumption_standart', {
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'closed'),
                                    })}
                                </Block>
                                <Block size="xs" title="Расход по факту, л">
                                    {this.textInput('waybill.fuel_consumption_fact', {
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'closed'),
                                    })}
                                </Block>
                                <Block size="xs" title="По БНСО (начало)">
                                    {this.textInput('waybill.departure_fuel_level', {
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'closed'),
                                    })}
                                </Block>
                                <Block size="xs" title="По БНСО (конец)">
                                    {this.textInput('waybill.arrival_fuel_level', {
                                        disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'closed'),
                                    })}
                                </Block>
                                <div className="page-block__title">Расчет норм топлива</div>
                                <Block title="Поправочный коэффициент">
                                    {this.textInput('waybill.parameters.D', {
                                        disabled: (this.props.data.status === 'closed'),
                                    })}
                                </Block>
                                <Block title="Время работы автомобиля с включенным отопителем, ч">
                                    {this.textInput('waybill.parameters.T', {
                                        disabled: (this.props.data.status === 'closed'),
                                    })}
                                </Block>
                                <Block title="Масса груза, т">
                                    {this.textInput('waybill.parameters.Ggr', {
                                        disabled: (this.props.data.status === 'closed'),
                                    })}
                                </Block>
                                <Block title="Количество ездок с грузом за смену">
                                    {this.textInput('waybill.parameters.Z', {
                                        disabled: (this.props.data.status === 'closed'),
                                    })}
                                </Block>
                                <Block title="Суммарная относительная надбавка к линейному расходу топлива">
                                    {this.textInput('waybill.parameters.D2', {
                                        disabled: (this.props.data.status === 'closed'),
                                    })}
                                </Block>
                                <Block/>
                                <Block title="Норма топлива">
                                    {this.textInput('fuel_norma', {
                                        disabled: (this.props.data.status === 'closed'),
                                    })}
                                </Block>
                                <Block>
                                    <Button size="md" color="red" shadow="red" text="Расчитать"
                                            onClick={::this.calculateFuelNorma}/>
                                </Block>
                            </div>
                        </div>
                    )}
                    <hr/>
                    <div className="page-block__content _lg">
                        <Block title="Наименование груза">
                            {this.textInput('waybill.cargo_name', {
                                disabled: (this.props.data.status === 'closed'),
                            })}
                        </Block>
                        <Block title="Ездок">
                            {this.textInput('waybill.rider', {
                                disabled: (this.props.data.status === 'closed'),
                            })}
                        </Block>
                        <Block title="Общий пробег, км">
                            {this.textInput('waybill.total_mileage', {
                                disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'opened'),
                            })}
                        </Block>
                        <Block title="Пробег с грузом, км">
                            {this.textInput('waybill.cargo_mileage', {
                                disabled: (this.props.data.status === 'closed') || (this.get('status') !== 'opened'),
                            })}
                        </Block>
                    </div>
                    <div className="page-block__content _lg">
                        <Block size="xl" title="Маршрут">
                            {this.textarea('waybill.route', {
                                disabled: (this.props.data.status === 'closed'),
                            })}
                        </Block>
                    </div>
                </TabBlock>
            </div>
        );
    }

    hasErrorInFields(fields) {
        return _.filter(fields, (field) => {
            return this.getError('waybill.' + field);
        }).length > 0;
    }

    async loadDriversForSelect(input, callback) {
        let uuid = this.get('driver_uuid') || null;

        const result = await this.props.getUsers({
            filters: {
                /*withPositionTypes: [
                    'driver',
                ],*/
                withComponent: 'road',
                withUuid: !input ? [uuid] : undefined,
            },
            search: input ? input : undefined,
            pagination: {
                page: 1,
                limit: 20,
            },
        });

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: new User(i).getFullName(),
                    value: i.uuid,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    async setValue(field, value) {
        this.props.onUpdate();

        await super.setValue(field, value);

        if (field === 'waybill.task_uuid') {
            this.loadWaybills();
        }
    }

    async calculateFuelNorma() {
        const vehicle = await this.getVehicle();
        const task = await this.getTask();

        if (!vehicle || !task) {
            return;
        }

        const formula = await this.getFormula(vehicle);
        if (!formula) {
            return;
        }

        const model = _.get(_.find(this.state.vehicle_models, {value: vehicle.vehicle_model_uuid}), 'document') || {};

        this.setState({
            fuel_norma: this.getFuelNorma(formula.document.key, vehicle, model, task),
        });
    }

    getFuelNorma(key, vehicle, model, task) {
        const S = _.toNumber(this.get('total_bnso_mileage', 0));
        const D = _.toNumber(this.get('parameters.D', 0));
        const Hs = _.toNumber(_.get(model, 'fuel_spent', 0));
        const Hw = _.toNumber(_.get(model, 'fuel_spent_work', 0));
        const Hg = _.toNumber(_.get(model, 'fuel_spent_trailer', 0));
        const Hot = _.toNumber(_.get(model, 'fuel_spent_warm', 0));
        const T = _.toNumber(this.get('parameters.T', 0));
        const Gnp = _.toNumber(_.get(model, 'trailer_weight', 0));
        const Ggr = _.toNumber(this.get('parameters.Ggr', 0));
        const Z = _.toNumber(this.get('parameters.Z', 0));
        const D2 = _.toNumber(this.get('parameters.D2', 0));
        const q = _.toNumber(_.get(model, 'trailer_carry_weight', 0));
        const Hz = _.toNumber(_.get(model, 'fuel_spent_additional', 0));
        const Ht = _.toNumber(_.get(model, 'fuel_spent_additional_2', 0));
        const Hs2 = _.toNumber(_.get(model, 'fuel_spent_2', 0));
        const Sgr = _.sumBy(task.items_fact || [], item => _.toNumber(item.distance || 0));
        const S2 = S - Sgr;

        switch (parseInt(key)) {
            case 1:
                return 0.01 * Hs * S * (1 + 0.01 * D);
            case 2:
                return 0.01 * Hs * S * (1 + 0.01 * D) + Hot * T;
            case 3:
                return 0.01 * ((Hs + Hg * Gnp) * S + Hw * (Ggr * Sgr)) * (1 + 0.01 * D);
            case 4:
                return 0.01 * (Hs + Hw * (Gnp + 0.5 * q) * S * (1 + 0.01 * D)) + Hz * Z;
            case 5:
                return (0.01 * Hs * S + Ht * T) * (1 + 0.01 * D);
            case 6:
                return 0.01 * (Hs * S2 + Hs2 * Sgr) * (1 + 0.01 * D);
            case 7:
                return 0.01 * (Hs * (S - Sgr + Ht * Sgr)) * (1 + D2);
        }
    }

    async getFormula(vehicle) {
        return _.first(_.filter(this.state.kurs_formulas || [], (formula) => {
            const vehicle_models = JSON.parse(formula.document.vehicle_models || '[]');
            return _.indexOf(vehicle_models, vehicle.vehicle_model_uuid) !== -1;
        }));
    }

    async getVehicle() {
        if (!this.get('vehicle_uuid')) {
            return null;
        }

        const response = await this.props.getVehicles({
            filters: {
                withBaseVehicles: [this.get('vehicle_uuid')],
            },
        });
        if (response.isOk) {
            return _.first(response.payload.items);
        } else {
            response.showErrors();
        }
    }

    async getTask() {
        if (!this.get('task_uuid')) {
            return null;
        }

        const response = await this.props.getTask(this.get('task_uuid'));
        if (response.isOk) {
            return response.payload;
        } else {
            response.showErrors();
        }
    }
}
