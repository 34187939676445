import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import classNames from 'classnames';

import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";

import './view.less';
import Block from "components/ui/form/block";
import moment from "moment";
import formats from "dictionaries/formats";
import LoaderComponent from "components/ui/loader";
import {connect} from "react-redux";
import {getUserPositions} from "store/reducers/staffing/positions/positions";
import {EntityList} from "helpers/entity";
import {getUserEducations} from "store/reducers/staffing/education/educations";

import currentUser from 'helpers/current-user';

@propTypes({
    data: PropTypes.object.isRequired,
})

@connect((state) => ({}), {getUserPositions, getUserEducations})

export default class StaffingViewerForm extends Component {
    state = {
        user: null,
        user_positions: null,
        user_educations: null,
        positionRelated: new EntityList(),
    };

    genderOptions = {
        male: 'Мужской',
        female: 'Женский',
    };

    async componentDidMount() {
        await this.setState({
            user: this.props.data,
        });

        if (currentUser.can('com.rnis.auth.permission.user.position', 'read')) {
            this.loadPositions();
        }
        if (currentUser.can('com.rnis.auth.permission.user.education', 'read')) {
            this.loadEducations();
        }
    }

    async loadPositions() {
        const response = await this.props.getUserPositions(this.state.user.uuid, {
            order: {
                column: 'hired_at',
                direction: 'desc',
            },
            pagination: {
                limit: 10,
                page: 1,
            },
        });
        if (response.isOk) {
            const items = response.payload.items;
            this.setState({user_positions: items});
            this.loadPositionRelatedEntities(items)
        } else {
            response.showErrors();
        }
    }

    async loadEducations() {
        const response = await this.props.getUserEducations(this.state.user.uuid, {
            order: {
                column: 'date_from',
                direction: 'desc',
            },
            pagination: {
                limit: 10,
                page: 1,
            },
        });
        if (response.isOk) {
            const items = response.payload.items;
            this.setState({user_educations: items});
        } else {
            response.showErrors();
        }
    }

    async loadPositionRelatedEntities(items) {
        const units = _.uniq(_.filter(_.map(items, 'unit_uuid')));
        const positions = _.uniq(_.filter(_.map(items, 'position_uuid')));
        const response = await this.props.getEntityNames(_.concat(_.map(units, (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        })), _.map(positions, (uuid) => ({
            class: 'App\\Model\\Position',
            uuid: uuid,
            source: 'organizational_units',
        }))));

        if (response.isOk) {
            this.state.positionRelated.add(response);
        }
    }

    get(path, defaultValue = '-') {
        return _.get(this.state.user, path, defaultValue);
    }

    getAge() {
        const birthdate = this.get('info.birthdate');
        if (!birthdate) {
            return '-';
        }

        return moment().diff(moment(birthdate), 'years');
    }

    formatDate(date, defaultValue = '-') {
        if (!date || (date === '-')) {
            return defaultValue;
        }

        return moment(date).format(formats.DATE);
    }

    render() {
        return (
            <div className="StaffingViewerForm">
                <Accordion>
                    <AccordionItem title="Основные сведения" opened={true}>
                        {this.renderGeneral()}
                    </AccordionItem>
                    <AccordionItem title="Учет">
                        {this.renderPositions()}
                    </AccordionItem>
                    {(!window.RNIS_SETTINGS.hide_pers_data ?
                        (<div>
                            <AccordionItem title="Паспортные данные">
                                {this.renderPassport()}
                            </AccordionItem>
                            <AccordionItem title="Персональные данные">
                                {this.renderPersonal()}
                            </AccordionItem>
                        </div>)
                            : null)}
                    <AccordionItem title="Авторизация">
                        {this.renderAuth()}
                    </AccordionItem>
                    <AccordionItem title="Квалификация">
                        {this.renderEducations()}
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    renderGeneral() {
        const photo = this.get('info.photo', null);
        const photoClassName = classNames('profile-pic__img', photo ? '' : 'img-empty');

        return (
            <div>
                <div className="profile-pic">
                    <div className={photoClassName} style={{backgroundImage: `url(${this.get('info.photo', '/img/user_default_avatar.svg')})`}}/>
                </div>

                <div className="personal">
                    <Block title="Телефон">
                        <span className="text-border">{this.get('info.phone')}</span> {this.get('info.phone2')}
                    </Block>
                    <Block title="Электронная почта">
                        {this.get('info.email')}
                    </Block>
                    <Block title="Возраст">
                        {this.getAge()}
                    </Block>

                    <Block title="Дата рождения">
                        {this.formatDate(this.get('info.birthdate'))}
                    </Block>

                    <Block title="Пол">
                        {_.get(this.genderOptions, this.get('info.gender'))}
                    </Block>
                </div>
            </div>
        );
    }

    renderPositions() {
        if (!currentUser.can('com.rnis.auth.permission.user.position', 'read')) {
            return (
                <div>Недостаточно прав для отображения</div>
            );
        }
        const loader = !this.state.user_positions ? <LoaderComponent color="red" align="none"/> : null;

        return (
            <div>
                {loader}
                {this.state.user_positions && this.state.user_positions.map(::this.renderUserPosition)}
            </div>
        );
    }

    renderUserPosition(position, index) {
        return (
            <div key={index}>
                <Block title="Предприятие" border={false}>
                    {this.state.positionRelated.getReact(position.unit_uuid)}
                </Block>
                <Block title="Должность">
                    {this.state.positionRelated.getReact(position.position_uuid)}
                </Block>

                <Block title="Табельный номер" border={false}>
                    <span className="text-border">{position.personnel_number}</span>
                    {position.is_main_position ? 'Основная должность' : null}
                </Block>
                <Block title="Дата приема на работу">
                    {this.formatDate(position.hired_at)}
                </Block>
                <Block title="Дата увольнения">
                    {this.formatDate(position.fired_at, 'По настоящее время')}
                </Block>

                {(index !== this.state.user_positions.length - 1) ? <div className="hr"/> : null}
            </div>
        );
    }

    renderPassport() {
        return (
            <div>
                <Block title="Серия" border={false}>
                    {this.get('info.passport_serial')}
                </Block>
                <Block title="Номер">
                    {this.get('info.passport_number')}
                </Block>
                <Block title="Код подразделения">
                    {this.get('info.passport_issuer')}
                </Block>
                <Block title="Дата выдачи" border={false}>
                    {this.formatDate(this.get('info.passport_issue_date'))}
                </Block>
            </div>
        );
    }

    renderPersonal() {
        return (
            <div>
                <Block title="ИНН" border={false}>
                    {this.get('info.inn')}
                </Block>
                <Block title="СНИЛС">
                    {this.get('info.snils')}
                </Block>
                <Block title="Место рождения">
                    {this.get('info.birthplace')}
                </Block>
                <Block title="Адрес прописки" border={false}>
                    {this.get('info.residence_address')}
                </Block>
            </div>
        );
    }

    renderAuth() {
        return (
            <div>
                <Block title="Логин" border={false}>
                    {this.get('login')}
                    {(this.get('blocked_at') !== '-') ?
                        <div className="b-blocked">Заблокирован {this.formatDate(this.get('blocked_at'))}</div> : null}
                </Block>
                <Block title="Роли">
                    {this.renderRoles()}
                </Block>
            </div>
        );
    }

    renderRoles() {
        const roles = this.get('roles');
        if ((roles === '-') || (roles.length === 0)) {
            return '-';
        }

        return roles.map((role, index) => {
            return (
                <span key={index} className="text-border">{role.name}</span>
            );
        });
    }

    renderEducations() {
        if (!currentUser.can('com.rnis.auth.permission.user.education', 'read')) {
            return (
                <div>Недостаточно прав для отображения</div>
            );
        }
        const loader = !this.state.user_educations ? <LoaderComponent color="red" align="none"/> : null;

        return (
            <div>
                {loader}
                {this.state.user_educations && this.state.user_educations.map(::this.renderUserEducation)}
            </div>
        );
    }

    renderUserEducation(education, index) {
        return (
            <div key={index}>
                <Block title="Учебное заведение" border={false}>
                    {education.name}
                </Block>
                <Block title="Специальность">
                    {education.speciality}
                </Block>
                <Block className="expand-top" title="Дата поступления">
                    {this.formatDate(education.date_from)}
                </Block>
                <Block className="expand-top" title="Дата окончания" border={false}>
                    {this.formatDate(education.date_to, 'По настоящее время')}
                </Block>

                {(index !== this.state.user_educations.length - 1) ? <div className="hr"/> : null}
            </div>
        );
    }
}