import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SelectForm from "../../lib/react-select-plus/lib/Select";

import 'react-select/dist/react-select.css';
import 'react-select-plus/dist/react-select-plus.css';
import './select.less';

const translations = {
    addLabelText: 'Добавить "{label}"?',
    backspaceToRemoveMessage: 'Нажмите backspace чтобы удалить {label}',
    clearAllText: 'Очистить всё',
    clearValueText: 'Удалить',
    noResultsText: 'Не найдено',
    placeholder: 'Выбрать...',
    searchPromptText: 'Начните ввод для поиска',
    loadingPlaceholder: 'Загрузка...',
};

// для русификации react-select делаем обертку

const Select = props => <SelectForm {...translations} {...props} />;
Select.Async = props => <SelectForm.Async {...translations} {...props} />;
Select.Creatable = props => <SelectForm.Creatable {...translations} {...props} />;

export default Select;

export class SelectAsync extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.reload || Object.keys(this.props).includes('reload') && this.props.value === null) {
            this.reload();
        }
    }

    render() {
        return (
            <SelectForm.Async ref="item" cache={false} {...translations} {...this.props} filterOptions={::this.filterOptions}/>
        )
    }

    filterOptions(options) {
        return options;
    }

    focus () {
        this.refs.item.focus();
    }

    reload() {
        this.refs.item.loadOptions('');
    }
}