import React, {Component} from 'react';
export default class ContractPrintComponent extends Component {

    render() {
        /*if (!this.props.schedule) {
            return null;
        }*/

        return this.renderPrintForm();
    }

    renderPrintForm() {
        return (
            <div>
                <div className="contract-serial">
                    {this.props.contract.serial || '' + '   ' + this.props.contract.number || ''}
                </div>
                <div className="org-name">
                    {this.props.org_name.name_full}
                </div>
                <div className="status">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.contract.status}
                    </div>
                </div>
                <div className="date-from">
                    {this.props.contract.dates.startDay}
                </div>
                <div className="year-from">
                    {this.props.contract.dates.startYear}
                </div>
                <div className="date-to">
                    {this.props.contract.dates.endDay}
                </div>
                <div className="year-to">
                    {this.props.contract.dates.endYear}
                </div>
                <div className="registration-number">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.registration_number}
                    </div>

                </div>
                <div className="number">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.number}
                    </div>

                </div>
                <div className="title">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.title}
                    </div>
                </div>
                <div className="carrier">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.carrier}
                    </div>
                </div>
                <div className="place">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.place}
                    </div>
                </div>
                <div className="inn">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.inn}
                    </div>
                </div>
                <div className="stops">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.stops}
                    </div>
                </div>
                <div className="streets">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.streets}
                    </div>
                </div>
                <div className="ts-type">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.tstype}
                    </div>

                </div>
                <div className="ts-class">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.tsclass}
                    </div>
                </div>
                <div className="eco">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.eco}
                    </div>
                </div>
                <div className="landing">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.landing}
                    </div>
                </div>
                <div className="properties">
                    <div className="dummy-child"></div>
                    <div className="valign">
                        {this.props.properties}
                    </div>
                </div>

                <div className="senior">
                    {this.props.senior}
                </div>

            </div>
        );
    }


}
