import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getVehicleFuelEvents = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.kurs.getVehicleFuelEvents(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getVehicleFuelLevels = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.kurs.getVehicleFuelLevels(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};