import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './state.less'


@propTypes({
    positive: PropTypes.bool.isRequired,
})

export class State extends Component {

    render() {
        const className = classNames('b-state', {
            '_disabled': !this.props.positive,
        });

        return (
            <div className={className}>
                <span className="b-state__style"/>
            </div>
        );
    }

}