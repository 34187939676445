import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';

import PageModal from 'components/ui/page-modal';
import MapComponent from "components/ui/map";
import MapPolyline from "components/ui/map/base/polyline";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";

@propTypes({
    data: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
})

export default class CommdeptRoadMapComponent extends Component {

    getCoordinates() {
        return [
            this.props.data.from,
            this.props.data.to,
        ];
    }

    componentDidMount() {
        setTimeout(() => {
            this.refs.map && this.refs.map.getWrappedInstance().fitBounds(this.getCoordinates());
        }, 100);
    }

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );
        return (
            <PageModal
                header={{title: 'Карта', buttons}}
                onClose={this.props.onClose}
                className={`profile-modal`}
            >
                <MapComponent
                    ref="map"
                >
                    {(this.refs.map) ? (
                        <MapPolyline
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            coordinates={this.getCoordinates()}
                            interactive={false}
                        />
                    ) : null}
                </MapComponent>
            </PageModal>
        );
    }
}
