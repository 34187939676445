import React from 'react';
import ReactDOMServer from 'react-dom/server';
import MapVehicleIcon from "components/ui/map-vehicle-icon";
import L from 'leaflet';

export default class ControlPointIcon {

    static getIcon() {
        const html = ReactDOMServer.renderToStaticMarkup(<MapVehicleIcon device={{type: null}}/>);

        let className = `marker marker-box marker_type_red marker_sm`;
        let size = 26;
        return new L.DivIcon({
            html: html,
            className: className,
            iconSize: new L.Point(size, size),
            iconAnchor: new L.Point(size / 2, size / 2),
        });
    }
}