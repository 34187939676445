import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import {Link} from "react-router";
import classNames from 'classnames';

import './modal-top-menu-button.less';

@propTypes({
    className: PropTypes.string,
    title: PropTypes.string,
    tooltip: PropTypes.string,
    onClick: PropTypes.func.isRequired,
})

@defaultProps({
    className: '',
    title: '',
    tooltip: '',
})

export default class ModalTopMenuButton extends Component {
    render() {
        const className = classNames('b-modal__header-link', this.props.className, this.props.tooltip ? 'link-tooltip' : '');

        return (
            <div className={className} onClick={this.props.onClick}>
                {this.props.title}
            </div>
        );
    }
}