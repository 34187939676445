import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import classNames from 'classnames';
import {connect} from 'react-redux';

import SlideLeftTransition from "components/ui/transitions/slide-left";

import {showError} from "helpers/errors";
import GlobalLoaderComponent from "components/ui/global-loader";

import './list.less';
import LayersEditor from "./editor";
import Button from "components/ui/button";
import {getLayers, getLayerObjects} from "store/reducers/user-map-objects/layer_list";
import currentUser from 'helpers/current-user';

@propTypes({
    onLayerSelect: PropTypes.func.isRequired,
    onObjectClick: PropTypes.func.isRequired,
    onObjectEdit: PropTypes.func.isRequired,
    onObjectEditGeometry: PropTypes.func.isRequired,
    onObjectSaveGeometry: PropTypes.func.isRequired,
    selectedLayer: PropTypes.string,
    geometryEdit: PropTypes.string,
})

@connect(state => ({}), {getLayers, getLayerObjects}, null, {withRef: true})
export default class LayersList extends Component {
    state = {
        showEditor: false,
        editorUuid: null,
        loading: true,
        layers: [],
        objects: [],
        search: '',
    };

    async componentDidMount() {
        this.reload();
    }

    onSearchChange({target: {value}}) {
        this.setState({
            search: value,
        });
    }

    getLayers() {
        let {search} = this.state;
        search = search.toLowerCase();

        return _.filter(this.state.layers, (layer) => {
            if (!search) {
                return true;
            }

            const isSelected = this.props.selectedLayer === layer.uuid;

            if (isSelected) {
                if (_.filter(this.props.objects, (object) => {
                        return object.title.toLowerCase().indexOf(search) !== -1;
                    }).length !== 0) {
                    return true;
                }
            }

            return layer.title.toLowerCase().indexOf(search) !== -1;
        });
    }

    getObjects() {
        let {search} = this.state;
        search = search.toLowerCase();

        return _.filter(this.props.objects, (object) => {
            if (!search) {
                return true;
            }

            return object.title.toLowerCase().indexOf(search) !== -1;
        });
    }

    render() {
        const editor = this.state.showEditor ? <LayersEditor
            key="editor"
            onClose={::this.closeEditor}
            onSubmit={::this.submitEditor}
            mode={this.state.editorUuid ? 'edit' : 'add'}
            uuid={this.state.editorUuid}
        /> : '';

        const loader = this.state.loading ? <GlobalLoaderComponent/> : null;

        return (
            <div className="LayersList">
                {loader}
                <div className="row offset">
                    <div className="col-md-12">
                        <Button size="md" color="red" shadow="red" onClick={::this.showEditor} text="Добавить слой"/>
                    </div>
                </div>
                <div className="SelectFieldsPopup">
                    <div className="filtration__search">
                        <input type="text" value={this.state.search}
                               className="filtration__pole" placeholder="Поиск"
                               onChange={::this.onSearchChange}/>
                        <input type="button" className="filtration__button" value=""/>
                    </div>
                </div>
                {(window.RNIS_SETTINGS.CITY_TULA && !currentUser.isSupervisor())
                    ? this.getLayers().filter(item => item.title !== "Тульская область").map(item => this.renderItem(item))
                    : this.getLayers().map(item => this.renderItem(item))}
                <SlideLeftTransition>
                    {editor}
                </SlideLeftTransition>
            </div>
        );
    }

    async closeEditor() {
        this.setState({
            showEditor: false,
            editorUuid: null
        });
    }

    async showEditor() {
        await this.closeEditor();
        this.setState({
            showEditor: true
        });
    }

    async submitEditor() {
        this.reload();
        await this.closeEditor();
    }

    async reload() {
        this.setState({loading: true});
        const response = await this.props.getLayers();
        this.setState({loading: false});
        if (response.isOk) {
            this.setState({
                layers: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    renderItem(layer) {
        const isSelected = this.props.selectedLayer === layer.uuid;
        const className = classNames({
            'route-title': true,
            'selected': isSelected,
        });

        const objects = (isSelected && !this.state.loading) ? this.getObjects().map((item) => this.renderObjectItem(item)) : null;
        return (
            <div key={layer.uuid}>
                <h2 className={className} onClick={this.onClick.bind(this, layer.uuid)}>
                    {layer.title}
                    {(!layer.is_null_run) ? (
                        <a onClick={this.editClick.bind(this, layer.uuid)} className="edit"/>
                    ) : null}
                </h2>
                {objects}
            </div>
        );
    }

    renderObjectItem(object) {
        return (
            <div key={object.uuid} className="UserGeoObject">
                <a href="#" onClick={this.onObjectClick.bind(this, object)}>{object.title}</a>
                <a onClick={this.objectEditClick.bind(this, object.uuid)} className="edit"></a>
                {(!this.props.geometryEdit && this.props.geometryEdit !== object.uuid) ? (
                    <a onClick={this.objectEditGeometryClick.bind(this, object.uuid)} className="edit-geometry">
                    </a>) : null}
                {(this.props.geometryEdit === object.uuid) ? (
                    <a onClick={this.objectSaveGeometryClick.bind(this, object)} className="save-geometry">
                    </a>) : null}
            </div>
        );
    }

    onObjectClick(object, e) {
        e.preventDefault();

        this.props.onObjectClick(object);
    }

    onClick(uuid) {
        this.props.onLayerSelect(uuid);
    }

    editClick(uuid, e) {
        e.stopPropagation();

        this.setState({
            showEditor: true,
            editorUuid: uuid
        });
    }

    objectEditClick(uuid, e) {
        e.stopPropagation();
        e.preventDefault();

        this.props.onObjectEdit(uuid);
    }

    objectEditGeometryClick(uuid, e) {
        e.stopPropagation();
        e.preventDefault();

        this.props.onObjectEditGeometry(uuid);
    }

    objectSaveGeometryClick(object, e) {
        e.stopPropagation();
        e.preventDefault();

        this.props.onObjectSaveGeometry(object);
    }
}