import React from 'react';
import { DiapasonFilterSimple } from '../controls/diapason-filter-simple';
import { DiapasonFilter } from '../controls/diapason-filter';
import { HeaderSeparator } from '../header-separator';
import Button from 'components/ui/button';
import MapFilterUnit from 'components/modules/maps/Filter/MapFilterUnit';
import MapFilterOwner from 'components/modules/maps/Filter/MapFilterOwner';
import MapFilterRoute from 'components/modules/maps/Filter/MapFilterRoute';
import MapFilterExtended from 'components/modules/maps/Filter/Extended/MapFilterExtended';
import MapFilterVehicle from 'components/modules/maps/Filter/MapFilterVehicle';
import MapFilterBase from 'components/modules/maps/Filter/MapFilterBase';
import RailroadCrossing from 'components/modules/maps/RailroadCrossing/RailroadCrossing';
import { component_mapper } from 'helpers/component_mapper';
import { get } from 'lodash';
import { MapOperatorComponentFilter } from '../Filter/map-operator-component-filter';

export const renderHeaderContent = (
	filters,
	paramsComponent,
	component,
	analyticDiapason,
	onToChange,
	onFromChange,
	diapason,
	onChangeDiapason,
	onKeyPress,
	onToggleShowEvents,
	showHistory,
	onToggleShowHistory,
	onToggleFilterResults,
	refs,
	onExtendedFiltersSubmit,
	onFilterChange,
	onSetRouteSelected,
	isOperatorMap,
	onGetUnits,
	onGetVehicles,
	onForceNextCycle,
	shouldShowHistory,
	onPlusWeek,
	onMinusWeek,
) => {
	const getFilters = (path) => {
		return get(filters, path);
	};
	const selectedVehicles = filters.base.vehicle.vehicles;
	const historyButtonText = showHistory
		? 'Все ТС'
		: `История ${selectedVehicles.length > 1 && window.RNIS_SETTINGS.show_mass_history ? `${selectedVehicles.length} ТС` : ''}`;

	// let _shouldShowHistory;

	if (window.RNIS_SETTINGS.show_mass_history) {
		if (selectedVehicles.length > 0 && selectedVehicles < 6) {
			shouldShowHistory = true;
		}
	} else {
		if (selectedVehicles.length === 1) {
			shouldShowHistory = true;
		}
	}
	
	let headerContents = [
		window.RNIS_SETTINGS.grantMO && paramsComponent === 'analytics' ? (
			<DiapasonFilterSimple
				key="diapason"
				from_analytic={analyticDiapason.from_analytic}
				to_analytic={analyticDiapason.to_analytic}
				onToChange={onToChange}
				onFromChange={onFromChange}
			/>
		) : null,
		shouldShowHistory &&
		showHistory && (
			<DiapasonFilter
				from_date={diapason.from_date}
				to_date={diapason.to_date}
				to_time={diapason.to_time}
				from_time={diapason.from_time}
				onDiapasonChange={onChangeDiapason}
				onKeyPress={onKeyPress}
				onPlusWeek={onPlusWeek}
				onMinusWeek={onMinusWeek}
			/>
		),
		shouldShowHistory && <HeaderSeparator />,
		<Button
			key="events"
			text="События"
			color="dark"
			className="top-menu__button top-menu__button_recovery"
			onClick={onToggleShowEvents}
		/>,
		shouldShowHistory && (
			<Button
				key="today-history"
				text={historyButtonText}
				color="dark"
				className={
					showHistory ? 'top-menu__button top-menu__button_events' : 'top-menu__button top-menu__button_recovery'
				}
				onClick={onToggleShowHistory}
			/>
		),
		getFilters('extended.result').length > 0 && (
			<Button
				key="filter-result"
				text="Объекты"
				color="dark"
				className={'top-menu__button top-menu__button_events'}
				onClick={onToggleFilterResults}
			/>
		)
	];

	if (_.indexOf([ 'kiutr', 'children', 'ems' ], component) !== -1) {
		headerContents.push(
			<MapFilterUnit
				key="map.filters.base.unit"
				component={component}
				selected={[ getFilters('base.vehicle.owners'), getFilters('base.vehicle.units') ]}
				onSelectedChange={[
					(value) => onFilterChange('base.vehicle.owners', value),
					(value) => onFilterChange('base.vehicle.units', value)
				]}
			/>
		);
	} else {
		if (window.RNIS_SETTINGS.road_map_filter_by_units_for_road_parts) {
			headerContents.push(
				<MapFilterOwner
					key="map.filters.base.unit"
					component={component}
					selected={[ getFilters('base.common.units') ]}
					onSelectedChange={[ (value) => onFilterChange('base.common.units', value) ]}
				/>
			);
		} else {
			headerContents.push(
				<MapFilterOwner
					key="map.filters.base.unit"
					component={component}
					selected={[ getFilters('base.vehicle.owners') ]}
					onSelectedChange={[ (value) => onFilterChange('base.vehicle.owners', value) ]}
				/>
			);
		}
	}
	if (_.indexOf([ 'kiutr', 'kiutr-control', 'children', 'ems' ], component) !== -1) {
		headerContents.push(
			<MapFilterRoute
				key="map.filters.base.route"
				component={component_mapper(component)}
				units={getFilters('base.vehicle.units')}
				owners={getFilters('base.vehicle.owners')}
				selected={[ getFilters('base.routes') ]}
				onSelectedChange={[
					(value) => {
						onFilterChange('base.routes', value);
						onSetRouteSelected(value); // load route variants
					}
				]}
			/>
		);
	}
	headerContents.push(
		<MapFilterVehicle
			key="map.filters.base.vehicle"
			component={component_mapper(component)}
			units={getFilters('base.vehicle.units')}
			owners={getFilters('base.vehicle.owners')}
			types={getFilters('base.vehicle.types')}
			routes={getFilters('base.routes')}
			selected={[ getFilters('base.vehicle.vehicles'), getFilters('base.vehicle.types') ]}
			onSelectedChange={[
				(value) => onFilterChange('base.vehicle.vehicles', value),
				(value) => onFilterChange('base.vehicle.types', value)
			]}
			onVehiclesLoad={(value) => {
				onFilterChange('base.result', value);
			}}
			onForceNextCycle={onForceNextCycle}
		/>
	);

	if (isOperatorMap) {
		headerContents.push(<MapOperatorComponentFilter onGetUnits={onGetUnits} onGetVehicles={onGetVehicles} />);
	}

	if (window.RNIS_SETTINGS.FILTERSEXTENDED) {
		headerContents.push(
			<MapFilterExtended
				key="map.filters.extended"
				component={component}
				filters={filters}
				onFiltersChange={onFilterChange}
				// onReset={this.onFiltersReset.bind(this, true)}
				onSubmit={onExtendedFiltersSubmit}
				onSelectedChange={[]}
			/>
		);
	}

	if (window.RNIS_SETTINGS.RAILROADCROSSING) {
		headerContents.push(
			<RailroadCrossing
				key="map.filters.railroads"
				refs={refs}
				component={component}
				filters={filters}
				onFiltersChange={onFilterChange}
				// onReset={this.onFiltersReset.bind(this, true)}
				onSubmit={onExtendedFiltersSubmit}
			/>
		);
	}

	if (window.RNIS_SETTINGS.grantMO && paramsComponent === 'analytics') {
		headerContents = headerContents.filter((el) => {
			return !!el && el.key === 'diapason' ? el : null;
		});
	}

	return headerContents;
};
