import React from 'react';
import { ListItem } from './list-item';
import IconButton from 'components/ui/icon-button';
import SelectForm from 'react-select-plus';
import Input from 'components/ui/form/input';
import './styles.less';
import { directionOptions } from '../../constants';
import { isEmpty } from 'lodash';
import Loader from 'components/ui/loader';
import classNames from 'classnames';

const CLASS_NAME = 'Add-road-parts-modal-body';

export class AddRoadPartsModalBody extends React.Component {
	state = {
		currentPage: 1,
		paginationLength: 1,
		pageInPagination: 10
	}

	leftListContent = () => {
		if (isEmpty(this.props.roadParts)) {
			return ''
		}

		if (this.props.roadParts.length <= this.state.pageInPagination) {
			return this.props.roadParts.map((roadPart, i) => <ListItem key={i} text={roadPart.name}
																	   actions={() => <IconButton icon="plus"
																								  onClick={() => this.props.onClickPlusButton(roadPart)}
																								  key={roadPart.uuid}/>}/>)
		}
		const startParts = (this.state.currentPage -1) * this.state.pageInPagination
		const endParts = startParts + this.state.pageInPagination

		return <div>
			{
				this.props.roadParts.slice(startParts, endParts).map((roadPart, i) => <ListItem key={i}
																	text={roadPart.name}
																	actions={() => <IconButton icon="plus"
																							   onClick={() => this.props.onClickPlusButton(roadPart)}
																							   key={roadPart.uuid}/>}/>)}
			<div>
				{this.renderPages()}
			</div>

		</div>

	}
	addAllInPage = ()=> {
		const startParts = (this.state.currentPage - 1) * this.state.pageInPagination
		const endParts = startParts + this.state.pageInPagination
		this.props.onClickPlusButton(this.props.roadParts.slice(startParts, endParts))

	}


	renderPages = () => {
		const currentPage = this.state.currentPage;
		const pageCount = _.ceil(this.props.roadParts.length / this.state.pageInPagination);
		const pages = _.filter(_.range(1, pageCount + 1), (page) => {
			return Math.abs(page - currentPage) <= 3;
		});
		return (
			<div className="dataTables_paginate paging_simple_numbers">
				<span
					className={`${CLASS_NAME}__add_all_page`}
					onClick={()=>this.addAllInPage()}>Добавить все на текущей странице</span>
                <span>
                    {_.map(pages, (page) => {
						return (
							<a key={page}
							   href="javascript:void(0)"
							   onClick={()=>this.setState({ currentPage: page })}
							   className={classNames('paginate_button', (page === this.state.currentPage) ? 'current' : '')}>{page}</a>
						);
					})}
                </span>
			</div>
		);
	}

	render() {
		return (
			<div className={CLASS_NAME}>
				<div className={`${CLASS_NAME}__actions`}>
					<div className={`${CLASS_NAME}__actions-left`}>
						<div className={`${CLASS_NAME}__title-wrapper`}>
							<div className={`${CLASS_NAME}__title-text`}>Участки дорог в системе</div>
							<div className={`${CLASS_NAME}__title-quantity`}>Всего участков
								дорог: {this.props.roadParts.length}</div>
						</div>
						<div className={`${CLASS_NAME}__actions-wrapper`}>
							<div className={`${CLASS_NAME}__search-wrapper`}>
								<Input value={this.props.searchInput} onChange={this.props.onChangeSearchInput}
									   placeholder="Поиск участков дорог"/>
							</div>
						</div>
					</div>
					<div className={`${CLASS_NAME}__actions-right`}>
						<div className={`${CLASS_NAME}__title-wrapper`}>
							<div className={`${CLASS_NAME}__title-text`}>Выбранные участки дорог</div>
							<div className={`${CLASS_NAME}__title-quantity`}>Добавлено
								участков: {this.props.currentTaskRoadParts.length}</div>
						</div>
						<div className={`${CLASS_NAME}__actions-wrapper`}>
							<div className={`${CLASS_NAME}__select-tasks-wrapper`}>
								<SelectForm
									options={this.props.tasksOptions}
									onChange={this.props.onChangeTask}
									value={this.props.currentTaskType}
									placeholder="Вид работ для участков работ"
									clearable={false}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={`${CLASS_NAME}__lists-wrapper`}>
					<div className={`${CLASS_NAME}__left-list`}>{
						this.props.loadingRoadParts
							? <Loader color="red"/>
							: this.leftListContent()
					}</div>

					<div className={`${CLASS_NAME}__right-list`}>
						{!isEmpty(this.props.currentTaskRoadParts) &&
						this.props.currentTaskRoadParts.map((roadPart) => (
							<div className={`${CLASS_NAME}__list-item-wrapper`} key={roadPart.uuid}>
								<ListItem
									text={roadPart.name}
									actions={() => <IconButton icon="basket"
															   onClick={() => this.props.onClickMinusButton(roadPart)}/>}
								/>
								<div className={`${CLASS_NAME}__select-wrapper`}>
									<SelectForm
										options={directionOptions}
										onChange={(data) => this.props.onChangeDirection(data, roadPart.uuid)}
										placeholder="Направление"
										value={roadPart.direction}
										clearable={false}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}
};
