import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import {LabeledCheckbox} from "components/ui/checkbox";
import IconButton from "components/ui/icon-button";
import ContextTooltip from "components/ui/context-tooltip";
import Popup from "components/ui/popup";
import FilterHeader from "components/ui/filter-header";
import debounce from 'throttle-debounce/debounce';
import classNames from 'classnames';
import GlobalLoaderComponent from "components/ui/global-loader";
import LoaderComponent from "components/ui/loader";
import PageModalComponent from "components/ui/page-modal";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import SelectFilter from "components/ui/select-filter";

import './MapFilterExtended.less';
import MapFilterExtendedTabSubjects from "components/modules/maps/Filter/Extended/Tab/MapFilterExtendedTabSubjects";
import MapFilterExtendedTabState from "components/modules/maps/Filter/Extended/Tab/MapFilterExtendedTabState";
import MapFilterExtendedTabParameters from "components/modules/maps/Filter/Extended/Tab/MapFilterExtendedTabParameters";
import MapFilterExtendedTabWorks from "components/modules/maps/Filter/Extended/Tab/MapFilterExtendedTabWorks";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";

@propTypes({
    selected: PropTypes.array,
    onSelectedChange: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
})

export default class MapFilterExtended extends Component {

    tabs = {
        'Субъекты': MapFilterExtendedTabSubjects,
        'Работы': MapFilterExtendedTabWorks,
        'Параметры ТС': MapFilterExtendedTabParameters,
        'Состояния': MapFilterExtendedTabState,
    };
    icon = 'filter';
    contextKey = 'map.filters.extended';
    contextDefault = 'Расширенные фильтры';

    state = {
        opened: false,
        activeTab: 0,
        isSubmiting: false,
    };

    getButton() {
        return (
            <IconButton
                icon={this.icon}
                onClick={::this.toggleBlock}
            />
        );
    }

    onChangeTab(value) {
        this.setState({
            activeTab: value,
        });
    }

    toggleBlock() {
        this.setState({
            opened: !this.state.opened,
        });
    }

    async onSubmit() {
        this.setState({
            isSubmiting: true,
        });

        const result = await this.props.onSubmit();

        this.setState({
            isSubmiting: false,
        });

        if (result) {
            this.setState({
                opened: false,
            });
        }
    }

    render() {
        const button = this.getButton();

        return (
            <div>
                {this.contextKey ? (
                    <ContextTooltip key={this.contextKey} code={this.contextKey}
                                    default={this.contextDefault}>
                        {button}
                    </ContextTooltip>
                ) : button}
                {this.state.opened ? this.renderPopup() : null}
            </div>
        );
    }

    reset() {
        this.props.onReset();
    }

    renderPopup() {
        const buttons = (
            <ModalTopMenuButtons>
                <ModalTopMenuList className="top-menu_modal_edit" key="list">
                    <ContextTooltip default="Сбросить">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_recount"
                            onClick={::this.reset}
                        />
                    </ContextTooltip>
                </ModalTopMenuList>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Закрыть">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.toggleBlock}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModalComponent
                header={{
                    title: 'Расширенные объектовые фильтры', buttons
                }}
                onClose={() => {}}
                className={`b-modal-settingsFilterObject grid-fix`}
                withFade={false}
                buttons={[
                    <div key="tooltip" className="b-modal__footer-txt">Вы хотите сохранить данные настройки
                        фильтров?</div>,
                    <a key="save" href="javascript:void(0)"
                       className="b-button b-button_red b-button_size_md b-button_save"
                       onClick={::this.onSubmit}>Сохранить</a>
                ]}
            >
                <div className="b-modal__container">
                    {this.state.isSubmiting ? (<GlobalLoaderComponent/>) : null}
                    <SelectFilter
                        className="b-modal__tab"
                        items={_.keys(this.tabs)}
                        currentItem={this.state.activeTab}
                        onChange={::this.onChangeTab}/>
                    {_.map(_.keys(this.tabs), (tabName, tabIndex) => {
                        const TabComponent = this.tabs[tabName];
                        return (
                            <TabComponent
                                key={tabIndex}
                                isActive={tabIndex === this.state.activeTab}
                                {...this.props}
                            />
                        )
                    })}
                </div>
            </PageModalComponent>
        );
    }
}