import React, {Component} from 'react';
import systems from "dictionaries/systems";
import WidgetPage from '../base-components/WidgetPage';
import VehicleDataWidget from './widgets/VehicleDataWidget';
import DepartmentRatingWidget from './widgets/DepartmentRatingWidget';
import VehicleDataDateWidget from './widgets/VehicleDataDateWidget';
import MechanismDowntimeWidget from './widgets/MechanismDowntimeWidget';
import SpeedViolationWidget from './widgets/SpeedViolationWidget';
import randomColor from 'randomcolor';
import BaseAnalyticsComponent from "components/modules/analytics/base";
import SpeedViolationsLineWidget from "components/modules/analytics/passenger-transportation/widgets/SpeedViolationsLineWidget/index";
import SpeedViolationsWidget from "components/modules/analytics/passenger-transportation/widgets/SpeedViolationsWidget/index";
import VehiclesBnsoWidget from "components/modules/analytics/passenger-transportation/widgets/VehiclesBnsoWidget/index";
import KursRegularityWidget from "components/modules/analytics/passenger-transportation/widgets/KursRegularityWidget/index";
import GatnVehiclesWidget from "components/modules/analytics/passenger-transportation/widgets/GatnVehiclesWidget/index";
import ZoneViolationsExitCount from "components/modules/analytics/passenger-transportation/widgets/ZoneViolationsExitCount/index";
import ZoneViolationsExitCompany from "components/modules/analytics/passenger-transportation/widgets/ZoneViolationsExitCompany/index";

const vehicleDataWidgetPieColor = randomColor();
const departmentRatingWidgetBarColor = randomColor();
const speedViolationWidgetBarColor = randomColor();

export default class Forestry extends BaseAnalyticsComponent {

    constructor(props) {
        super(props);

        this.widgetItems = [
            { id: 'vehiclesWidget', name: 'ТС в работе', widget: this.vehiclesWidget, checked: true },
            { id: 'speedViolationsLineWidget', name: 'Количество нарушений скоростного режима', widget: this.speedViolationsLineWidget, checked: true },
            { id: 'speedViolationsWidget', name: 'Нарушители скоростного режима', widget: this.speedViolationsWidget, checked: true },
            { id: 'vehiclesBnsoWidget', name: 'Оснащенность ГЛОНАСС', widget: this.vehiclesBnsoWidget, checked: true },
            /*{ id: 'vehicleDataWidget', name: 'Данные по ТС', widget: this.vehicleDataWidget },
            { id: 'departmentRatingWidget', name: 'Рейтинг отделов', widget: this.departmentRatingWidget },
            { id: 'vehicleDataDateWidget', name: 'Данные по ТС на текущую дату', widget: VehicleDataDateWidget },
            { id: 'mechanismDowntimeWidget', name: 'Простой техники', widget: MechanismDowntimeWidget },
            { id: 'speedViolationWidget', name: 'Количество нарушений скоростного режима', widget: this.speedViolationWidget }*/
        ];
        if(window.RNIS_SETTINGS.CITY_TULA) {
            this.widgetItems.push({ id: 'zoneViolationsExitCount', name: 'Количество выходов из геозоны', widget: this.zoneViolationsExitCount, checked: true })
            this.widgetItems.push({ id: 'zoneViolationsExitCompany', name: 'Выходы из геозоны по предприятиям', widget: this.zoneViolationsExitCompany, checked: true })
        }

        this.title = `${systems.analytics} → Лесное хозяйство`;
    }

    vehicleDataWidget(props) {
        return <VehicleDataWidget {...props} pieChartFillColor={vehicleDataWidgetPieColor}/>
    }

    departmentRatingWidget(props) {
        return <DepartmentRatingWidget {...props} barChartFillColor={departmentRatingWidgetBarColor}/>
    }

    speedViolationWidget(props) {
        return <SpeedViolationWidget {...props} barChartFillColor={speedViolationWidgetBarColor}/>
    }

    vehiclesWidget(props) {
        return <GatnVehiclesWidget {...props} component="forestry"/>
    }

    speedViolationsLineWidget(props) {
        return <SpeedViolationsLineWidget {...props} component="forestry"/>
    }

    speedViolationsWidget(props) {
        return <SpeedViolationsWidget {...props} component="forestry"/>
    }

    zoneViolationsExitCount(props) {
        return <ZoneViolationsExitCount {...props} component="forestry"/>
    }

    zoneViolationsExitCompany(props) {
        return <ZoneViolationsExitCompany {...props} component="forestry"/>
    }

    vehiclesBnsoWidget(props) {
        return <VehiclesBnsoWidget {...props} component="forestry"/>
    }

    render() {
        return (
            <WidgetPage cacheKey="forestry"
                        title={this.title}
                        widgetItems={this.widgetItems}
            />
        );
    }
}