import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import moment from 'moment';
import _ from 'lodash';
import Loader from "components/ui/loader";
import {getVehicle, getVehicleList} from "store/reducers/vehicles/vehicles";
import {getUser} from "store/reducers/staffing/staffing_editor";
import VehicleTable from './vehicle-table';
import DriverTable from './driver-table';
import ShiftBoundsTable from './shift-bounds-table';
import AutoRunTable from './auto-run-table';
import MileageTable from './mileage-table';
import './style.less'
import Block from "components/ui/form/block";
import formats from "dictionaries/formats";
import {Link} from "react-router";
import {getUsers} from "store/reducers/staffing/staffing";
import VehicleEditor from "components/modules/vehicles/vehicles/editor";
import ShowDeleted from "components/ui/show-deleted"
import {LabeledCheckbox} from "../../../../../../../ui/checkbox";
import currentUser from 'helpers/current-user';

@propTypes({
    data: PropTypes.object.isRequired,
    load: PropTypes.bool.isRequired
})

@connect(state => ({}), {getVehicleList, getUsers})

export default class ShiftInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            schedulepush: false,
            notschedulepush: false,
            failureschedulepush: true,
            showPush: false,
            isNeedLoad: true,
            isLoading: false,
            vehicles: {},
            drivers: {},
            isShowRTiOForm: false,
            driverUuid: null
        };
    }

    componentWillMount() {
        if (this.props.load) {
            this.loadData();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.load && this.state.isNeedLoad) {
            this.loadData();
        }
    }

    /*shouldComponentUpdate(nextProps, nextState) {
     return (nextProps.load !== this.props.load) || (nextState.isLoading !== this.state.isLoading);
     }*/

    componentWillUpdate(nextProps) {
        if (!_.isEqual(this.props, nextProps)) {
            let pushTime = JSON.parse(localStorage.getItem('pushtime'));
            if (currentUser.user.is_custompush) {
                let a = this.props.data.shift.runs[0].vehicle_uuid;
                this.setState({
                    schedulepush: pushTime[a].schedulepush,
                    notschedulepush: pushTime[a].notschedulepush,
                    failureschedulepush: pushTime[a].failureschedulepush,
                });
            }

        }
    }

    async loadDrivers() {
        const {orders} = this.props.data;

        const uuids = _.uniq(_.filter(_.map(_.flatten(_.map(_.flatten(_.map(orders, 'shifts')), 'runs')), 'driver_uuid')));
        if (uuids.length === 0) {
            return {};
        }

        const response = await this.props.getUsers({
            filters: {
                withUuid: uuids,
            },
        });
        if (response.isOk) {
            return _.keyBy(response.payload.items, 'uuid');
        } else {
            response.showErrors();
            return null;
        }
    }

    async loadVehicles() {
        const {orders} = this.props.data;
        const uuids = _.uniq(_.filter(_.map(_.flatten(_.map(_.flatten(_.map(orders, 'shifts')), 'runs')), 'vehicle_uuid')));
        if (uuids.length === 0) {
            return {};
        }

        const response = await this.props.getVehicleList({
            filters: {
                withUuid: uuids,
            },
        });
        if (response.isOk) {
            return _.keyBy(response.payload.items, 'uuid');
        } else {
            response.showErrors();
            return null;
        }
    }

    async loadData() {
        //console.log(this.props.data);

        this.setState({isLoading: true});

        const vehicles = await this.loadVehicles();
        //console.log(vehicle);

        const drivers = await this.loadDrivers();
        //console.log(driver);

        this.setState({isLoading: false, isNeedLoad: false, vehicles, drivers});

        if (currentUser.user.is_custompush) {
            let pushTime = JSON.parse(localStorage.getItem('pushtime'));
            let a = this.props.data.shift.runs[0].vehicle_uuid;

            // устанавливаем галочки для push уведомлений
            this.setState({
                showPush: pushTime[a].isShowingPush,
                schedulepush: pushTime[a].schedulepush,
                notschedulepush: pushTime[a].notschedulepush,
                failureschedulepush: pushTime[a].failureschedulepush,
            });
        }

    }

    getCapacityName(uuid) {
        return _.get(this.props.data.vehicleCapacityTypes, `${uuid}.name`);
    }

    getVehicleModel(uuid) {
        return _.get(this.props.data.vehicleModels, `${uuid}.name`);
    }

    getEnvironmentClass(uuid) {
        return _.get(this.props.data.vehicleEnvironmentClasses, `${uuid}.name`);
    }

    getShiftPlanStartTime() {
        const firstRun = _.first(this.props.data.shift.runs);
        if (!firstRun) {
            return '-';
        }
        return moment(firstRun.date_from).format(formats.TIME);
    }

    getShiftPlanEndTime() {
        const lastRun = _.last(this.props.data.shift.runs);
        if (!lastRun) {
            return '-';
        }
        return moment(lastRun.date_to).format(formats.TIME);
    }

    getShiftFactStartTime() {
        const time = _.get(_.first(_.sortBy(this.props.data.orderExecutions, 'date_start_real')), 'date_start_real');
        return time ? moment(time).format(formats.TIME) : '';
    }

    getShiftFactEndTime() {
        const time = _.get(_.last(_.sortBy(this.props.data.orderExecutions, 'date_end_real')), 'date_end_real');
        return time ? moment(time).format(formats.TIME) : '';
    }

    getPlanAutoRunHours() {
        const runs = this.props.data.shift.runs;
        let minutes = 0;

        runs.forEach((run) => {
            //if (run.type !== 'dinner' && run.type !== 'gap' && run.type !== 'reshift' && run.type !== 'settling' && run.type !== 'parking') {
            if (run.type === 'production_forward' || run.type === 'production_reverse') {
                minutes += _.toInteger(run.time);
            }
        });

        return Math.round(minutes / 60);
    }

    getFactAutoRunHours() {
        const runs = this.props.data.shift.runs;
        let minutes = 0;

        runs.forEach((run) => {
            //if (run.type !== 'dinner' && run.type !== 'gap' && run.type !== 'reshift' && run.type !== 'settling' && run.type !== 'parking') {
            if (run.type === 'production_forward' || run.type === 'production_reverse') {
                const orderExecution = _.find(this.props.data.orderExecutions, {order_run_uuid: run.uuid});
                if (orderExecution) {
                    const points = _.filter(orderExecution.data, (point) => {
                        return !!point.time_fact;
                    });
                    if (points.length > 1) {
                        minutes += Math.abs(_.toInteger(moment(_.first(points).time_fact).diff(moment(_.last(points).time_fact), 'seconds')));
                    }
                }
            }
        });

        return _.round(minutes / 3600, 2);
    }

    getPlanMileage() {
        const runs = this.props.data.shift.runs;
        let mileage = 0;

        runs.forEach((run) => {
            if ( run.type === 'production_forward' || run.type === 'production_reverse') {
                mileage += _.toInteger(run.distance);
            }
        });

        return _.toInteger(mileage / 1000);
    }


    getFactMileage() {
        const shiftNumber = this.props.data.shift.shift;
        const orderShift = _.find(this.props.data.order.shifts, (shift) => {
            return shift.shift == shiftNumber;
        });
        const runs = orderShift.runs;
        let result = 0;
        runs.forEach((run) => {
            if (run.type === 'production_forward' || run.type === 'production_reverse') {
                const orderExecution = _.find(this.props.data.orderExecutions, {order_run_uuid: run.uuid});
                if (_.get(orderExecution, 'is_checked') || (run.check_type === 'auto')) {
                    result += _.toNumber(run.distance || 0);
                }
            }
        })
        return Math.round(result * 100) / 100000;
    }

    getDriverFullName(driver) {
        let fullName = _.get(driver, 'info.surname');
        const name = _.get(driver, 'info.name');
        const secondName = _.get(driver, 'info.second_name');

        if (name) {
            fullName += ' ' + name.charAt(0).toUpperCase() + '.';
        }

        if (secondName) {
            fullName += ' ' + secondName.charAt(0).toUpperCase() + '.';
        }

        return fullName;
    }

    onRTiOClick = (uuid, name) => {
        const data = {
            uuid,
            date: this.props.data.date,
            name,
        };

        this.props.data.onRTiOClick(data);
    };

    onCallClick = (uuid) => {
        /*const data = {
         uuid,
         date: this.props.data.date,
         name: this.getDriverFullName()
         };

         this.props.data.onCallClick(data);*/
    };

    onSMSClick = (uuid) => {
        /*const data = {
         uuid,
         date: this.props.data.date,
         name: this.getDriverFullName()
         };

         this.props.data.onSMSClick(data);*/
    };

    onVehicleEditClick(uuid) {
        this.setState({
            vehicleEditUuid: uuid,
        });
    }

    onVehicleCloseClick() {
        this.setState({
            vehicleEditUuid: null,
        });
    }

    renderContent() {
        const {data} = this.props;

        const vehicles = _.uniq(_.filter(_.map(data.shift.runs, 'vehicle_uuid')));
        const drivers = _.uniq(_.filter(_.map(data.shift.runs, 'driver_uuid')));

        const runs = _.flatten(_.map(data.order.shifts, 'runs'));
        const routes = _.filter(_.uniq(_.map(runs, 'route_uuid')));

        return (
            <div>
                <div className="push-checkbox" style={{
                    width: '60%',
                    padding: '10px',
                    'display': `${currentUser.user.is_custompush ? 'block' : 'none'}`
                }}>
                    <div style={{'padding-bottom': '15px'}}>Отображать push уведомленя по этому выходу</div>
                    <LabeledCheckbox
                        checked={this.getValue('schedulepush')}
                        onChange={(value => this.onChangeInput('schedulepush', {target: {value}}))}
                        label='По расписанию'/>
                    <LabeledCheckbox
                        checked={this.getValue('notschedulepush')}
                        onChange={(value => this.onChangeInput('notschedulepush', {target: {value}}))}
                        label='Не по расписанию'/>
                    <LabeledCheckbox
                        checked={this.getValue('failureschedulepush')}
                        onChange={(value => this.onChangeInput('failureschedulepush', {target: {value}}))}
                        label='Пропуск'/>
                </div>
                <div className="output">
                    <div className="output__table">
                        <div className="output__row">
                            <div className="output__cell">
                                <div className="output__name">Выход {data.turn.number}, Смена {data.shift.shift}</div>
                            </div>
                        </div>
                        <div className="output__row">
                            {_.map(vehicles, (vehicleUuid) => {
                                const vehicle = _.get(this.state.vehicles, vehicleUuid);
                                if (vehicle) {
                                    return (
                                        <VehicleTable
                                            key={vehicle.uuid}
                                            uuid={vehicle.uuid}
                                            stateNumber={vehicle.state_number}
                                            garageNumber={vehicle.garage_number}
                                            capacity={this.getCapacityName(vehicle['capacity_type_uuid'])}
                                            model={this.getVehicleModel(vehicle['vehicle_model_uuid'])}
                                            bnso={_.get(vehicle, 'current_bnso.bnso_code')}
                                            environmentalClass={this.getEnvironmentClass(vehicle['environmental_class_uuid'])}
                                            from={(_.first(_.filter(data.shift.runs, {vehicle_uuid: vehicle.uuid})).date_from)}
                                            to={moment(_.last(_.filter(data.shift.runs, {vehicle_uuid: vehicle.uuid})).date_from).add(_.last(_.filter(data.shift.runs, {vehicle_uuid: vehicle.uuid})).time, 'minutes')}
                                            onClick={::this.onVehicleEditClick}
                                        />
                                    );
                                }
                            })}

                            {_.map(drivers, (driverUuid) => {
                                const driver = _.get(this.state.drivers, driverUuid);
                                if (driver) {
                                    return (
                                        <DriverTable
                                            key={driver.uuid}
                                            fullName={this.getDriverFullName(driver)}
                                            personnelNumber={_.get(driver, 'info.personnel_number')}
                                            onRtiOClick={this.onRTiOClick.bind(this, driver.uuid, this.getDriverFullName(driver))}
                                            disableRtiO={!driver.uuid}
                                            isCommunication={this.props.data.isCurrent}
                                            disableCommunication={!(driver.uuid)}
                                            onCallClick={this.onCallClick.bind(this, driver.uuid)}
                                            onSMSClick={this.onSMSClick.bind(this, driver.uuid)}
                                            from={moment(_.first(_.filter(data.shift.runs, {driver_uuid: driver.uuid})).date_from).format(formats.TIME)}
                                            to={moment(_.last(_.filter(data.shift.runs, {driver_uuid: driver.uuid})).date_from).add(_.last(_.filter(data.shift.runs, {driver_uuid: driver.uuid})).time, 'minutes').format(formats.TIME)}
                                        />
                                    );
                                }
                            })}
                        </div>

                        <div className="output__row">
                            <Link to={`/kiutr/routes/${data.order.route_uuid}`}>Паспорт маршрута</Link>
                            {_.map(routes, (routeUuid) => {
                                const routeNumber = _.first(_.filter(runs, {route_uuid: routeUuid})).route_number;

                                const orderUuid = _.get(_.first(data.orders), 'uuid');

                                return orderUuid ? (
                                    <a href={`/kiutr/map/order/${orderUuid}?route=${routeUuid}`}
                                       target="_blank">Маршрут {routeNumber} на карте</a>
                                ) : null;
                            })}
                        </div>
                    </div>
                </div>
                <ShiftBoundsTable startTimePlan={this.getShiftPlanStartTime()}
                                  endTimePlan={this.getShiftPlanEndTime()}
                                  startTimeFact={this.getShiftFactStartTime()}
                                  endTimeFact={this.getShiftFactEndTime()}
                />
                <div className="output-amount">
                    <Block size="xl">
                        <AutoRunTable plan={this.getPlanAutoRunHours()}
                                      fact={this.getFactAutoRunHours()}
                        />
                    </Block>
                    <Block size="xl">
                        <MileageTable plan={this.getPlanMileage()}
                                      fact={this.getFactMileage()}
                        />
                    </Block>
                </div>
                {this.state.vehicleEditUuid ? (
                    <VehicleEditor
                        {...this.props}
                        uuid={this.state.vehicleEditUuid}
                        mode="edit"
                        onSubmit={::this.onVehicleCloseClick}
                        onClose={::this.onVehicleCloseClick}
                    />
                ) : null}
            </div>
        );
    }

    getState() {
        return this.state;
    }

    getData() {
        return this.state;
    }

    /**
     * @param {String} field
     * @return {String}
     */
    getValue(field) {
        return _.get(this.getState(), field);
    }

    onChangeInput(field, {target: {value}}) {
        this.setValue(field, value);
    }

    async setValue(field, value) {
        let state = this.getState();

        _.set(state, field, value);
        if (currentUser.user.is_custompush) {
            this.setPushState(field, value);
        }

        return this.setState(state);
    }

    async setPushState(field, value) {
        let a = this.props.data.shift.runs[0].vehicle_uuid;
        let pushTime = JSON.parse(localStorage.getItem('pushtime'));
        pushTime[a][field] = value;
        localStorage.setItem('pushtime', JSON.stringify(pushTime));
    }

    render() {
        return this.state.isLoading ? (
            <div className="shift-info_loader">
                <Loader color="red" size={32}/>
            </div>
        ) : !this.state.isNeedLoad ? (
            <div>
                {this.renderContent()}
            </div>
        ) : null;
    }
}
