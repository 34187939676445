import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import _ from 'lodash';

import { connect } from 'react-redux';

import BaseEditorFormComponent from 'components/base/base-editor-form';
import Block from 'components/ui/form/block';
import { getDictionaryList } from 'store/reducers/dictionaries/dictionary';
import Accordion from 'components/ui/accordion/accordion';
import AccordionItem from 'components/ui/accordion/accordion-item';
import BaseEditor from 'components/base/base-editor';
import TableContainer from 'components/ui/Table/Container/TableContainer';
import ModalTopMenuButtons from 'components/ui/modal/modal-top-menu-buttons';
import ModalTopMenuButton from 'components/ui/modal/modal-top-menu-button';
import PageModal from 'components/ui/page-modal';
import GlobalLoaderComponent from 'components/ui/global-loader';
import moment from 'moment';
import formats from 'dictionaries/formats';
import './editor.less';
import { getGatnViolations, getGatnOverspeenIntervalsGrpc, getGatnGeoIntervalsGrpc } from 'store/reducers/gatn/gatn_violations';
import { getVehicle } from 'store/reducers/vehicles/vehicles';
import Button from 'components/ui/button';
import GatnViolationsMapComponent from 'components/modules/gatn/gatn_violations/map';
import ModalTopMenuListSeparator from 'components/ui/modal/modal-top-menu-list-separator';
import ModalTopMenuList from 'components/ui/modal/modal-top-menu-list';
import ModalTopMenuListItem from 'components/ui/modal/modal-top-menu-list-item';
import ContextTooltip from 'components/ui/context-tooltip';

import { handleInvalidEndedAtDate } from "helpers/violations";

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	uuid: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired
})
@connect((state) => ({}), { getGatnViolations, getGatnOverspeenIntervalsGrpc, getGatnGeoIntervalsGrpc })
export default class Editor extends BaseEditor {
	title = '';

	async loadData(uuid) {
        const meta = {
            order: {
                column: 'started_at',
                direction: 'asc'
            },
            filters: {
                withPeriod: [
                    moment(this.props.dateFrom),
                    moment(this.props.dateTo),
                ],
                withVehicle: this.props.uuid,
                // withType: this.props.type
            }
        }
        if (this.props.type === 'speed') {
            return  await this.props.getGatnOverspeenIntervalsGrpc(meta);
        } else {
            return await this.props.getGatnGeoIntervalsGrpc(meta);
        }
      
	}

	getForm(item, onSubmit) {
		const data = handleInvalidEndedAtDate(item);
		return (
			<EditorForm
				{...this.props}
				ref="form"
				mode={this.props.mode}
				onSubmit={onSubmit}
				onClose={::this.props.onClose}
				data={data}
				errors={this.state.errors}
				vehicleUuid={this.props.uuid}
				date={this.props.date}
				type={this.props.type}
			/>
		);
	}

	render() {
		const loader = this.state.isLoading || this.state.saving ? <GlobalLoaderComponent /> : null;

		const title = this.props.type === 'speed' ? 'Нарушения скоростного режима' : 'Выезд за пределы региона';
		let form;
		let buttons;

		if (this.state.item) {
			form = this.getForm(this.state.item, () => {});

			buttons = (
				<ModalTopMenuButtons>
					<ModalTopMenuList className="top-menu_modal_edit">
						<ContextTooltip key="base-editor.print" code="base-editor.print" default="Печать">
							<ModalTopMenuListItem className="b-icon-link_icon_print" onClick={::this.print} />
						</ContextTooltip>

						<ModalTopMenuListSeparator key="separator" />
					</ModalTopMenuList>

					<ModalTopMenuButton key="close" className="_close" tooltip="Отменить" onClick={::this.props.onClose} />
				</ModalTopMenuButtons>
			);
		}

		return (
			<PageModal
				header={{ title, buttons }}
				onClose={this.props.onClose}
				className={`profile-modal b-modal-${this.props.mode} b-modal-l`}
			>
				{loader}
				{form}
			</PageModal>
		);
	}
}

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	data: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	errors: PropTypes.object,
	vehicleUuid: PropTypes.string,
	date: PropTypes.string,
	type: PropTypes.string
})
@connect((state) => ({}), { getVehicle, getDictionaryList }, null, { withRef: true })

class EditorForm extends BaseEditorFormComponent {
	state = {
		payload: {},
		vehicle: {},
		vehicle_marks: [],
		vehicle_models: [],
		gatn_responsives: [],
		showMap: null
	};

	getData() {
		return this.state.payload;
	}

	async componentDidMount() {
		await this.setState({
			payload: this.props.data
		});

		this.loadVehicle();
		this.loadDictionaries([ 'vehicle_marks', 'vehicle_models', 'gatn_responsives' ]);
	}

	async loadVehicle() {
		const response = await this.props.getVehicle(this.props.params.vehicle_uuid);

		if (response.isOk) {
			this.setState({ vehicle: response.payload });
		} else {
			response.showErrors();
		}
	}

	get(path, defaultValue = null) {
		return _.get(this.state.payload, path, defaultValue);
	}

	render() {
		const buttons = (
			<ModalTopMenuButtons>
				<ModalTopMenuButton key="close" className="_close" tooltip="Отменить" onClick={::this.closeMap} />
			</ModalTopMenuButtons>
		);

		let violation = null;
		if (this.state.showMap) {
			violation = _.find(this.state.payload.items, { uuid: this.state.showMap });
		}

		return (
			<div>
				<Accordion>
					<AccordionItem
						opened={true}
						title={this.props.type === 'speed' ? 'Нарушения скоростного режима' : 'Выезд за пределы региона'}
					>
						<Block title="Дата">{this.state?.payload?.items && this.state?.payload?.items?.length ? moment(this.state.payload.items[0].started_at).format(formats.DATE) : "-"}</Block>
						<Block title="Гос. номер">{_.get(this.state.vehicle, 'state_number')}</Block>
						<Block title="Марка ТС">
							{_.get(
								_.find(this.state.vehicle_marks, { value: _.get(this.state.vehicle, 'vehicle_mark_uuid') }),
								'label'
							)}
						</Block>
						<Block title="Модель ТС">
							{_.get(
								_.find(this.state.vehicle_models, { value: _.get(this.state.vehicle, 'vehicle_model_uuid') }),
								'label'
							)}
						</Block>
						{this.props.params.component === 'control' ? (
							<Block title="Ответственный">
								{_.get(
									_.find(this.state.gatn_responsives, { value: _.get(this.state.vehicle, 'gatn_responsive_uuid') }),
									'label'
								)}
							</Block>
						) : null}
					</AccordionItem>
					<AccordionItem
						opened={true}
						title={`Нарушений: ${this.state.payload.items && this.state.payload.items.length}`}
					>
						<Block size="xl">
							<div className="Table">
								<TableContainer>
									<table className="b-table">
										<thead>
											<tr>
												<th width="50px">№</th>
												<th>Время начала</th>
												<th>Координаты начала</th>
												<th>Время окончания</th>
												<th>Координаты окончания</th>
												{this.props.type === 'speed' ? (
													[
														<th key="fact">Фактическая скорость</th>,
														<th key="limit">Ограничение скорости</th>,
														<th key="diff">Превышение скорости</th>
													]
												) : null}
												<th>Отобразить на карте</th>
											</tr>
										</thead>
										<tbody>{this.state.payload.items && this.state.payload.items.map(::this.renderRow)}</tbody>
									</table>
								</TableContainer>
							</div>
						</Block>
					</AccordionItem>
				</Accordion>
				{this.state.showMap ? (
					<PageModal
						header={{ title: 'Нарушение на карте', buttons }}
						onClose={::this.closeMap}
						className={`profile-modal b-modal-edit b-modal-l`}
					>
						<GatnViolationsMapComponent
							bnso_number={violation.bnso_number}
							from={violation.started_at}
							to={violation.ended_at}
						/>
					</PageModal>
				) : null}
			</div>
		);
	}

	renderRow(violation, index) {
		return (
			<tr key={index} onClick={this.showMap.bind(this, violation)}>
				<td className="align-center">{index + 1}</td>
				<td className="align-center">{moment(violation.started_at).format(formats.DATETIME)}</td>
				<td className="align-center">
					{_.round(violation.started_latitude, 6)}, {_.round(violation.started_longitude, 6)}
				</td>
				<td className="align-center">
					{violation.is_formated ? '-' : moment(violation.ended_at).format(formats.DATETIME)}
				</td>
				<td className="align-center">
					{violation.is_formated ? ('-') : (
						`${_.round(violation.ended_latitude, 6)}, ${_.round(violation.ended_longitude, 6)}`
					)}
				</td>
				{this.props.type === 'speed' ? (
					[
						<td key="fact" className="align-center">
							{violation.max_speed}
						</td>,
						<td key="limit" className="align-center">
							{violation.speed_limit || '-'}
						</td>,
						<td key="diff" className="align-center">
							{violation.max_speed - violation.speed_limit || 0}
						</td>
					]
				) : null}
				<td className="align-center">
					<Button text="Показать" color="white" shadow="gray" size="md" onClick={this.showMap.bind(this, violation)} />
				</td>
			</tr>
		);
	}

	showMap(violation) {
		this.setState({
			showMap: this.state.showMap !== violation.uuid ? violation.uuid : null
		});
	}

	closeMap() {
		this.setState({
			showMap: null
		});
	}
}
