import React, { Component } from 'react';
import ColorPicker from 'react-color-picker';
import 'react-color-picker/index.css'

import './ColorBox.less';

export default class ColorBox extends Component {

    state = {
      color: this.props.defaultValue || 'black',
      isOpened: false 
    }

  onDrag = (color) => {
    const { onChange, settingsName } = this.props;
    this.setState({
      color
    }, () => onChange(settingsName, color))
  }

  togglePicker = () => {
    this.setState({ isOpened: !this.state.isOpened })
  }

  render() {
    return (
      <div className="color-box">
        {this.state.isOpened &&
          <div className="picker-wrapper">
            <ColorPicker
              value={this.state.color}
              onDrag={this.onDrag}
              saturationWidth={200}
              saturationHeight={200}
              hueWidth={30}
            />
          </div>
        }
        <div className="picker" onClick={this.togglePicker}>
          <div className="picker-inner" style={{ background: this.state.color}} />
        </div>
      </div>
    );
  }
}