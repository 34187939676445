import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import formats from "dictionaries/formats";
import moment from "moment";
import TableContainer from "components/ui/Table/Container/TableContainer";
import runs from "dictionaries/runs";
import currentUser from 'helpers/current-user';

export default class KiutrRouteScheduleOfficialPrintComponent extends Component {

    render() {
        if (!this.props.schedule) {
            return null;
        }

        return this.renderSchedule();
    }

    calcDispatchTime(i) {
        let f = _.find(this.props.turns[0].runs, el => {
            return el.type !== 'null' && el.type !== 'parking'
        });

        console.log(i)

        let a = f.start_time.split(':')
        let secs = (Number(a[0]) * 60 + Number(a[1])) * 60;

        if (i === 0) {
            return f.start_time;
        }
        else {
            let tempSec = 0;
            while (i--) {
                if (f.production_interval_map[i]) {
                    tempSec += f.production_interval_map[i].interval * 60;
                }

            }

            return  moment.utc((secs + tempSec) * 1000).format('HH:mm')
        }

    }


    renderSchedule() {
        console.log(this.props)
        const days = this.getDays();
        //период действия расписания
        const period = `с ${moment(this.props.schedule.date_from).format(formats.DATE)} 
        по ${this.props.schedule.date_to ? moment(this.props.schedule.date_to).format(formats.DATE) : '-'} 
        ${days.join(', ')}`;

        return (
            <div>
                <div className="period">{period}</div>
                <div className="Table">
                    <table className="b-table-official">
                        <tr>
                            <td className="stop-name">Наименование остановочного пункта</td>
                            <td className="reg-num">Регистрационный номер остановочного пункта</td>
                            <td className="dispatch">Дни прибытия (отправления)</td>
                            <td className="dispatch">Прибытие час:мин</td>
                            <td className="dispatch">Стоянка час:мин</td>
                            <td className="dispatch">Отправление час:мин</td>
                        </tr>
                        {this.props.defaultVariant.map((el, i) => {
                            return (<tr><td className="stop-name" key={`${el.uuid}`}>{el.name}</td>
                            <td className="reg-num">-</td>
                            <td className="dispatch">{days}</td>
                            <td className="dispatch">{this.calcDispatchTime(i)}</td>
                            <td className="dispatch">-</td>
                            <td className="dispatch">-</td>
                        </tr>
                            )
                        })}
                    </table>
                </div>
                {/*<div className="page-title">
                    !!!!!!!!!!!!!!!Маршрут №{this.props.schedule.route_number} {this.props.schedule.route_name}
                </div>
                <div className="page-block">
                    <p>
                        <span className="bold">Время действия расписания </span>
                        с {moment(this.props.schedule.date_from).format(formats.DATE)}
                        &nbsp;
                        по {this.props.schedule.date_to ? moment(this.props.schedule.date_to).format(formats.DATE) : '-'}
                        <span className="days">{days.join(', ')}</span>
                    </p>
                    {this.props.turns.map(::this.renderTurnInfo)}
                </div>

                {this.renderCircleRuns()}*/}
            </div>
        );
    }

    renderTurnInfo(turn, index) {
        const shifts = _.filter(turn.runs, {type: 'reshift'}).length + 1;
        const dinner = _.find(turn.runs, {type: 'dinner'});
        const dinnerTime = dinner ? (`${dinner.start_time} - ${moment(dinner.start_time, formats.TIME).add(dinner.time, 'minutes').format(formats.TIME)}`) : '-';
        const reshifts = _.filter(turn.runs, {type: 'reshift'});
        const reshiftsTime = _.map(reshifts, (run) => `${run.start_time} - ${moment(run.start_time, formats.TIME).add(run.time, 'minutes').format(formats.TIME)}`);

        const visibleRuns = _.filter(turn.runs, run => {
            return (run.type !== 'production_forward') && (run.type !== 'production_reverse') && (run.type !== 'null');
        });

        return (
            <div key={index}>
                <p>
                    <span className="bold">Выход {turn.number}</span> ({shifts}см. выход {turn.start_at} ;
                    возврат {turn.end_at};
                    &nbsp;
                    {_.map(visibleRuns, (run) => {
                        return `${runs[run.type]} ${run.start_time} - ${moment(run.start_time, formats.TIME).add(run.time, 'minutes').format(formats.TIME)}`;
                    }).join('; ')}
                    )
                </p>
            </div>
        );
    }

    isNullRunSeparated() {
        return _.get(this.props.schedule, 'is_null_run_separated');
    }

    getDays() {
        let days = [];

        if (this.props.schedule.monday) {
            days.push('Пн');
        }
        if (this.props.schedule.tuesday) {
            days.push('Вт');
        }
        if (this.props.schedule.wednesday) {
            days.push('Ср');
        }
        if (this.props.schedule.thursday) {
            days.push('Чт');
        }
        if (this.props.schedule.friday) {
            days.push('Пт');
        }
        if (this.props.schedule.saturday) {
            days.push('Сб');
        }
        if (this.props.schedule.sunday) {
            days.push('Вс');
        }
        if (this.props.schedule.holiday) {
            days.push('Праздник');
        }

        return days;
    }

    renderCircleRuns() {
        let turns = this.props.turns;
        _.each(turns, (turn, index) => {
            _.each(turn.runs, (run) => {
                run.turn_number = index + 1;
            });
        });
        const runs = _.flatten(_.map(this.props.turns, 'runs'));

        let productionForwardRuns = _.reverse(_.clone(_.filter(runs, (run) => {
            if (run.type === 'production_forward') {
                return true;
            }
            if (run.type === 'null') {
                const nullRun = this.props.nullRuns[run.route_variant_null_run_uuid];
                if (nullRun && nullRun.is_forward) {
                    return true;
                }
            }
            return false;
        })));
        const productionReverseRuns = _.filter(runs, (run) => {
            if (run.type === 'production_reverse') {
                return true;
            }
            if (run.type === 'null') {
                const nullRun = this.props.nullRuns[run.route_variant_null_run_uuid];
                if (nullRun && !nullRun.is_forward) {
                    return true;
                }
            }
            return false;
        });

        const forwardCount = productionForwardRuns.length - _.filter(productionForwardRuns, {type: 'null'}).length;

        let forwardIndex = -1;
        productionForwardRuns = _.map(productionForwardRuns, (run) => {
            if (run.type !== 'null') {
                forwardIndex++;
            }
            run.index = forwardCount - forwardIndex;

            return run;
        });
        let reverseIndex = -1;

        const perPage = 6;
        const pages = Math.max(Math.ceil(productionForwardRuns.length / perPage), Math.ceil(productionReverseRuns.length / perPage));

        return _.map(_.range(pages), (page) => {
            let forwardRuns = _.cloneDeep(productionForwardRuns);
            _.reverse(forwardRuns);
            forwardRuns = forwardRuns.slice(page * perPage, (page + 1) * perPage);
            _.reverse(forwardRuns);

            const reverseRuns = productionReverseRuns.slice(page * perPage, (page + 1) * perPage);
            let forwardIndex = -1;

            return (
                <div key={page} className="page-break">
                    <TableContainer>
                        <div className="Table">
                            <table className="b-table">
                                <thead>
                                <tr className="border-top-bold-2 border-bottom-bold">
                                    {forwardRuns.map((run, index) => {
                                        if (run.type !== 'null') {
                                            forwardIndex++;
                                        }
                                        if (run.type === 'null' && !this.isNullRunSeparated()) {
                                            return null;
                                        }
                                        return (
                                            <th key={`forward:${index}`} width="100px">
                                                {(run.type === 'null') ? 0 : (run.index)}
                                            </th>
                                        );
                                    })}
                                    <th className="border-left-bold-2 border-bottom-bold-2" rowSpan="3">Расст.км</th>
                                    <th className="border-left-bold border-bottom-bold-2" rowSpan="3">Время.мин</th>
                                    <th className="border-left-bold">Кругорейсы</th>
                                    <th className="border-left-bold border-bottom-bold-2" rowSpan="3">Время.мин</th>
                                    <th className="border-left-bold border-right-bold border-bottom-bold-2" rowSpan="3">
                                        Расст.км
                                    </th>
                                    {reverseRuns.map((run, index) => {
                                        if (run.type !== 'null') {
                                            reverseIndex++;
                                        }
                                        if (run.type === 'null' && !this.isNullRunSeparated()) {
                                            return null;
                                        }
                                        return (
                                            <th key={`reverse:${index}`} width="100px">
                                                {(run.type === 'null') ? 0 : (reverseIndex + 1)}
                                            </th>
                                        );
                                    })}
                                </tr>
                                {this.renderTurnsRow(forwardRuns, reverseRuns)}
                                {this.renderRouteVariantRow(forwardRuns, reverseRuns)}
                                </thead>
                                <tbody>
                                {this.props.nullObjects.map(this.renderNullObjectRow.bind(this, forwardRuns, reverseRuns))}
                                {this.props.defaultVariant && _.map(this.props.defaultVariant, this.renderPointRow.bind(this, forwardRuns, reverseRuns))}
                                {this.renderDistanceRow(forwardRuns, reverseRuns)}
                                {this.renderTimeRow(forwardRuns, reverseRuns)}
                                </tbody>
                            </table>
                        </div>
                    </TableContainer>
                </div>
            );
        });
    }

    getNullRunObjectsInUse() {
        const nullRuns = this.getNullRunsInUse();
        return _.map(nullRuns, (nullRun) => {
            if (nullRun.is_forward) {
                return nullRun.points[0].type_uuid;
            } else {
                return _.last(nullRun.points).type_uuid;
            }
        });
    }

    getNullRunsWithObject(objectUuid) {
        return _.filter(this.props.nullRuns, (nullRun) => {
            if (nullRun.is_forward) {
                return nullRun.points[0].type_uuid === objectUuid;
            } else {
                return _.last(nullRun.points).type_uuid === objectUuid;
            }
        });
    }

    getNullRunsInUse() {
        const uuids = _.filter(_.uniq(_.map(_.filter(_.flatten(_.map(this.props.turns, 'runs')), {type: 'null'}), 'route_variant_null_run_uuid')));
        return _.filter(this.props.nullRuns, (nullRun) => {
            return _.indexOf(uuids, nullRun.uuid) !== -1;
        });
    }

    renderNullObjectRow(productionForwardRuns, productionReverseRuns, nullObject) {
        const nullRunsWithObject = this.getNullRunsWithObject(nullObject.uuid);
        const forwardNullRun = _.first(_.filter(nullRunsWithObject, {is_forward: true}));
        const reverseNullRun = _.first(_.filter(nullRunsWithObject, {is_forward: false}));

        return (
            <tr key={nullObject.uuid}>
                {productionForwardRuns.map((run, index) => {
                    if (!this.isNullRunSeparated()) {
                        if (run.type === 'null') {
                            return null;
                        }
                        const siblingRun = _.get(productionForwardRuns, index + 1) || _.get(productionForwardRuns, index - 1);
                        if (siblingRun) {
                            if (siblingRun.type === 'null') {
                                return (
                                    <td key={`forward:${index}`}>
                                        {(_.indexOf(_.map(nullRunsWithObject, 'uuid'), siblingRun.route_variant_null_run_uuid) !== -1) ? siblingRun.start_time : ''}
                                    </td>
                                );
                            }
                        }
                    }

                    return (
                        <td key={`forward:${index}`}>
                            {(run.type === 'null' && _.indexOf(_.map(nullRunsWithObject, 'uuid'), run.route_variant_null_run_uuid) !== -1) ? run.start_time : ''}
                        </td>
                    );
                })}
                <td className="border-left-bold-2">{reverseNullRun ? this.formatDistance(reverseNullRun.distance) : ''}</td>
                <td className="border-left-bold">{reverseNullRun ? reverseNullRun.time : ''}</td>
                <td className="border-left-bold">{nullObject.title}</td>
                <td className="border-left-bold">{forwardNullRun ? forwardNullRun.time : ''}</td>
                <td className="border-left-bold border-right-bold">{forwardNullRun ? this.formatDistance(forwardNullRun.distance) : ''}</td>
                {productionReverseRuns.map((run, index) => {
                    if (!this.isNullRunSeparated()) {
                        if (run.type === 'null') {
                            return null;
                        }
                        const siblingRun = _.get(productionReverseRuns, index + 1) || _.get(productionReverseRuns, index - 1);
                        if (siblingRun) {
                            if (siblingRun.type === 'null') {
                                return (
                                    <td key={`reverse:${index}`}>
                                        {(_.indexOf(_.map(nullRunsWithObject, 'uuid'), siblingRun.route_variant_null_run_uuid) !== -1) ? moment(siblingRun.start_time, formats.TIME).add(siblingRun.time, 'minutes').format(formats.TIME) : ''}
                                    </td>
                                );
                            }
                        }
                    }

                    return (
                        <td key={`reverse:${index}`}>
                            {(run.type === 'null' && _.indexOf(_.map(nullRunsWithObject, 'uuid'), run.route_variant_null_run_uuid) !== -1) ? moment(run.start_time, formats.TIME).add(run.time, 'minutes').format(formats.TIME) : ''}
                        </td>
                    );
                })}
            </tr>
        );
    }

    renderPointRow(productionForwardRuns, productionReverseRuns, group, index) {
        let groups = this.props.defaultVariant;
        const forwardPoint = this.findPointByGroup(group, index, true, groups);
        const reversePoint = this.findPointByGroup(group, index, false, groups);
        return (
            <tr key={index}>
                {productionForwardRuns.map((run, _index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <td key={`forward:${index}:${_index}`}>{this.renderPointTime(group, run)}</td>
                    );
                })}
                <td className="border-left-bold-2">{this.formatDistance(_.get(forwardPoint, 'distance_to_the_next_point', 0)) || '0'}</td>
                <td className="border-left-bold">{_.get(forwardPoint, 'time_to_get_to_the_next_point', 0)}</td>
                <td className="border-left-bold">{group.name}</td>
                <td className="border-left-bold">{_.get(reversePoint, 'time_to_get_to_the_next_point', 0)}</td>
                <td className="border-left-bold border-right-bold">{this.formatDistance(_.get(reversePoint, 'distance_to_the_next_point', 0)) || '0'}</td>
                {productionReverseRuns.map((run, _index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <td key={`reverse:${index}:${_index}`}>{this.renderPointTime(group, run)}</td>
                    );
                })}
            </tr>
        );
    }

    findPointByGroup(group, index, isForward, groups) {
        if ((isForward && index >= groups.length - 1) || (!isForward && index <= 0)) {
            return null;
        }

        const nextGroup = isForward ? groups[index + 1] : groups[index - 1];
        const currentStopPoints = _.map(group.items, 'stop_point_uuid');
        const nextStopPoints = _.map(nextGroup.items, 'stop_point_uuid');

        let result = null;
        _.each(this.props.routeVariants, (routeVariant) => {
            const points = _.filter(_.concat(routeVariant.forward_points, routeVariant.reverse_points), {point_type: 'stop_point'});
            for (let i = 0; i < points.length - 1; i++) {
                if ((_.indexOf(currentStopPoints, points[i].type_uuid) !== -1) && (_.indexOf(nextStopPoints, points[i + 1].type_uuid) !== -1)) {
                    result = points[i];
                    return false;
                }
            }
        });

        return result;
    }

    renderPointTime(group, run) {
        if (run.type === 'null') {
            const nullRun = this.props.nullRuns[run.route_variant_null_run_uuid];
            if (nullRun) {
                if (_.filter(nullRun.points, {type_uuid: group.stop_point_uuid}).length > 0) {
                    return nullRun.is_forward ? moment(run.start_time, formats.TIME).add(run.time, 'minutes').format(formats.TIME) : run.start_time;
                }
            }
            return null;
        }

        let point = null;
        _.each(group.items, (item) => {
            point = _.find(item.inclusions, {
                route_variant_uuid: run.route_variant_uuid,
                is_forward: run.type === 'production_forward',
            });
            if (point) {
                return false;
            }
        });
        if (!point) {
            return null;
        }

        const routeVariant = _.find(this.props.routeVariants, {uuid: run.route_variant_uuid});
        if (!routeVariant) {
            return null;
        }

        const time = _.sumBy(_.filter(run.production_interval_map, (item) => {
            return item.index < point.index;
        }), 'interval');

        const timeObject = moment(run.start_time, formats.TIME).add(time, 'minutes');

        return timeObject.format(formats.TIME);
    }

    renderTurnsRow(productionForwardRuns, productionReverseRuns) {
        return (
            <tr className="border-bottom-bold-2">
                {productionForwardRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <th key={`forward:${index}`}>{run.turn_number}</th>
                    );
                })}

                <th className="border-left-bold">Выходы</th>

                {productionReverseRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <th key={`reverse:${index}`}>{run.turn_number}</th>
                    );
                })}
            </tr>
        );
    }

    renderRouteVariantRow(productionForwardRuns, productionReverseRuns) {
        return (
            <tr className="border-bottom-bold-2 wrap-normal">
                {productionForwardRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <th key={`forward:${index}`}>{_.get(_.find(this.props.routeVariants, {uuid: run.route_variant_uuid}), 'name')}</th>
                    );
                })}

                <th className="border-left-bold">Вариант движения</th>

                {productionReverseRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <th key={`reverse:${index}`}>{_.get(_.find(this.props.routeVariants, {uuid: run.route_variant_uuid}), 'name')}</th>
                    );
                })}
            </tr>
        );
    }

    renderDistanceRow(productionForwardRuns, productionReverseRuns) {
        return (
            <tr className="border-top-bold-2 border-bottom-bold">
                {productionForwardRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <td key={`forward:${index}`}>
                            {(run.type !== 'null') ? this.formatDistance(run.distance) : 0}
                        </td>
                    );
                })}
                <td className="border-right-bold-2 border-left-bold-2" colSpan="5">Пробег</td>
                {productionReverseRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <td key={`reverse:${index}`}>
                            {(run.type !== 'null') ? this.formatDistance(run.distance) : 0}
                        </td>
                    );
                })}
            </tr>
        );
    }

    formatDistance(distanceInMeters) {
        return Math.round((distanceInMeters / 1000) * 100) / 100;
    }

    renderTimeRow(productionForwardRuns, productionReverseRuns) {
        return (
            <tr className="border-bottom-bold-2">
                {productionForwardRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <td key={`forward:${index}`}>
                            {(run.type !== 'null') ? run.time : 0}
                        </td>
                    );
                })}
                <td className="border-right-bold-2 border-left-bold-2" colSpan="5">Время</td>
                {productionReverseRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <td key={`reverse:${index}`}>
                            {(run.type !== 'null') ? run.time : 0}
                        </td>
                    );
                })}
            </tr>
        );
    }
}
