import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import classNames from 'classnames';

import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import PageModal from 'components/ui/page-modal';
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getOrders, resourceCheck, updateOrder, updateOrderRecalc, resourceGroupCheck} from "store/reducers/kiutr/orders/orders";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import Select, {SelectAsync} from "components/ui/select";
import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import GlobalLoaderComponent from "components/ui/global-loader";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import ContextTooltip from "components/ui/context-tooltip";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";
import moment from "moment";
import formats from "dictionaries/formats";
import runs from "dictionaries/runs";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import './index.less';
import {component_mapper} from "helpers/component_mapper";
import currentUser from "../../../../../../../helpers/current-user";
import * as alerts from "helpers/alerts";

@propTypes({
    order: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func.isRequired,
})

@connect(state => ({}), {updateOrderRecalc, getUsers, getVehicleList, getDictionaryList, resourceCheck, resourceGroupCheck})

export default class OrderCloseRunsResources extends Component {

    state = {
        order: {},
        loading: false,
        vehicles: [],
        users: [],
    };
    requests = {
        vehicles: {},
        users: {},
    };
    async componentDidMount() {
        const order = _.cloneDeep(this.props.order)
        await this.setState({order});
        this.loadVehicles();
        this.loadUsers();
    }

    async loadVehicles() {
        const vehicles = _.map(_.flatten(_.map(this.state.order.shifts, 'runs')), 'vehicle_uuid');
        const response = await this.props.getVehicleList({
            filters: {
                withUuid: vehicles,
            },
            response_data: [
                'items/uuid',
                'items/state_number',
            ],
        });
        if (response.isOk) {
            this.setState({
                vehicles: _.map(response.payload.items, (item) => ({
                    label: item.state_number,
                    value: item.uuid,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async loadUsers() {
        const users = _.uniq(_.filter(_.concat(_.map(_.flatten(_.map(this.state.order.shifts, 'runs')), 'driver_uuid'), _.map(this.state.order.runs, 'check_taker_uuid'))));
        const response = await this.props.getUsers({
            filters: {
                withUuid: users,
            },
            response_data: [
                'items/uuid',
                'items/info/surname',
                'items/info/name',
                'items/info/second_name',
            ],
        });
        if (response.isOk) {
            this.setState({
                users: _.map(response.payload.items, (item) => ({
                    label: new User(item).getFullName(),
                    value: item.uuid,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async save(type) {
        this.setState({loading: true});
        let order = this.state.order;
        order.is_only_run_edit = true;

        if (window.RNIS_SETTINGS.resourcesGroupCheck) {
            const runs = order.shifts.reduce((accumulator, shift)=>[...accumulator, ...shift.runs], []);
            const orderID = order.order_uuid;
            const resourceGroupToCheck = runs.map(el => {
                return {
                    "order_uuid": orderID,
                    "vehicle_uuid": el.vehicle_uuid,
                    //"driver_uuid": el.driver_uuid,
                    "date_from": el.date_from,
                    "date_to": el.date_to
                }
            })
            const response = await this.props.resourceGroupCheck({"items": resourceGroupToCheck});
            if (response.isOk) {
                const responseUpdate = await this.props.updateOrderRecalc(order);
                this.setState({loading: false});

                if (responseUpdate.isOk) {
                    this.props.onSubmit(type);
                } else {
                    alerts.error(Object.values(responseUpdate.errors[0].data))
                    responseUpdate.showErrors();
                }
            } else {
                this.setState({loading: false});
                response.showErrors();
            }
        } else {
            const responseUpdate = await this.props.updateOrderRecalc(order);
            this.setState({loading: false});

            if (responseUpdate.isOk) {
                this.props.onSubmit(type);
            } else {
                alerts.error(Object.values(responseUpdate.errors[0].data))
                responseUpdate.showErrors();
            }
        }
    }

    getTitle() {
        return 'Переопределение ресурсов';
    }

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                    <ModalTopMenuButton
                        className="_save"
                        title="Сохранить"
                        onClick={this.save.bind(this, 'close')}
                    />
                </ContextTooltip>
                <ModalTopMenuButtonsSeparator key="separator"/>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        const loader = this.state.loading ? <GlobalLoaderComponent/> : null;

        return (
            <div>
                <PageModal
                    header={{title: this.getTitle(), buttons}}
                    className={classNames("profile-modal b-modal-edit orders-modal orders-modal-runs b-modal_stretch order-close-run-resources")}
                    onClose={this.props.onClose}
                    withFade={true}
                >
                    {loader}
                    <Accordion>
                        {(this.state.order.shifts || []).map(::this.renderShift)}
                    </Accordion>
                </PageModal>
            </div>
        );
    }

    renderShift(shift, index) {
        return (
            <AccordionItem key={shift.shift} opened={true}
                           title={`Смена ${shift.shift} ${moment(shift.start_at).format(formats.TIME)} - ${moment(shift.end_at).format(formats.TIME)}`}>
                <Block size="xl" title="Маршруты">
                    {_.map(_.uniq(_.map(shift.runs, 'route_number')), (routeNumber) => {
                        const run = _.find(shift.runs, {route_number: routeNumber});
                        if (run) {
                            const runs = _.filter(_.filter(shift.runs, {route_number: routeNumber}), (run) => (
                                run.type === 'production_forward' || run.type === 'production_reverse'
                            )).length;
                            const switchRuns = 0;

                            return (
                                <div key={run.route_uuid}>
                                    {run.route_number}: {run.route_name} (Рейсов: {runs})
                                </div>
                            );
                        }

                    })}
                </Block>
                <div className="Table indent-none" style={{overflow: 'visible'}}>
                    <TableContainer>
                        <table ref="table" className="b-table b-table-no-hover">
                            <thead>
                            <tr>
                                <th width="100px">Рейс</th>
                                <th width="50px">Время</th>
                                <th>ТС</th>
                                <th>Водитель</th>
                                <th>Кондуктор</th>
                            </tr>
                            </thead>
                            <tbody>
                            {shift.runs.map(this.renderRow.bind(this, index))}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </AccordionItem>
        );
    }

    renderRow(shiftIndex, orderRun, index) {
            /*
            завершен/обеспечен - можно менять (Ок)+
            завершен/не обеспечен - нельзя менять (Ок) +
            действует/обеспечен - можно менять (Ок) +
            действует/не обеспечен -  нельзя менять (Ок) +
            оформляется/обеспечен -  можно менять (Ок) +
            оформляется/не обеспечен - можно менять (Ок) +
         */
        let disabled = false;
        let userRole = currentUser.user.roles.filter((role) =>
            role.name === window.RNIS_SETTINGS.FREEACCOUNTROLENAME
            || role.name === window.RNIS_SETTINGS.PAIDACCOUNTROLENAME);

        let userRoleFree = currentUser.user.roles.filter((role) =>
            role.name === window.RNIS_SETTINGS.FREEACCOUNTROLENAME);
        // и его время больше чем +3 часа от даты формирования план-наряда
        let dateDiff = moment() > moment(this.state.order.shifts[0].runs[0].start_at).startOf('day').add(3, 'hours');
        userRoleFree.length && dateDiff && window.RNIS_SETTINGS.EXITCONTROL && !orderRun.vehicle_uuid ? disabled = true : null;
        if (userRoleFree.length && orderRun.vehicle_uuid === null) {
            disabled = false;
        }
        if (userRoleFree.length && this.state.order.shifts[0].end_at && !this.state.order.shifts[0].vehicle_uuid) {
            disabled = true;
        }
        if (currentUser.user.is_supervisor) {
            disabled = false;
        }
        /*if (currentUser.user.roles.filter((role) => role.name === '!(all)').length) {
            disabled = false;
        }*/


        return (
            <tr key={shiftIndex + ':' + index}>
                <td className="align-center">{orderRun.run + 1}<br/>{runs[orderRun.type]}</td>
                <td className="align-center">{moment(orderRun.date_from).format(formats.TIME)}-{moment(orderRun.date_to).format(formats.TIME)}</td>
                <td className="input-cell">
                    <div className="select-wrapper">
                        <SelectAsync
                            ref={`order.shifts.${shiftIndex}.runs.${index}.vehicle_uuid`}
                            name={`order.shifts.${shiftIndex}.runs.${index}.vehicle_uuid`}
                            loadOptions={(input, callback) => {
                                this.loadVehiclesForSelect(shiftIndex, index, input, callback);
                            }}
                            value={orderRun.vehicle_uuid}
                            options={this.state.vehicles}
                            disabled={disabled}
                            onChange={this.onChange.bind(this, `order.shifts.${shiftIndex}.runs.${index}.vehicle_uuid`)}
                        />
                    </div>
                </td>
                <td className="input-cell">
                    <div className="select-wrapper">
                        <SelectAsync
                            ref={`order.shifts.${shiftIndex}.runs.${index}.driver_uuid`}
                            name={`order.shifts.${shiftIndex}.runs.${index}.driver_uuid`}
                            loadOptions={(input, callback) => {
                                this.loadDriversForSelect(shiftIndex, index, input, callback);
                            }}
                            value={orderRun.driver_uuid}
                            options={this.state.users}
                            onChange={this.onChange.bind(this, `order.shifts.${shiftIndex}.runs.${index}.driver_uuid`)}
                        />
                    </div>
                </td>
                <td className="input-cell">
                    <div className="select-wrapper">
                        <SelectAsync
                            ref={`order.shifts.${shiftIndex}.runs.${index}.check_taker_uuid`}
                            name={`order.shifts.${shiftIndex}.runs.${index}.check_taker_uuid`}
                            loadOptions={(input, callback) => {
                                this.loadCheckTakersForSelect(shiftIndex, index, input, callback);
                            }}
                            value={6}
                            options={this.state.users}
                            onChange={this.onChange.bind(this, `order.shifts.${shiftIndex}.runs.${index}.check_taker_uuid`)}
                        />
                    </div>
                </td>
            </tr>
        );
    }

    onChange(fieldName, e) {
        let value = e ? e.value : null;

        let state = this.state;
        const originalValue = _.get(state, fieldName);
        _.set(state, fieldName, value);
        this.setState(state);

        const matches = /^order\.shifts\.([0-9]+)\.runs\.([0-9]+)\.driver_uuid$/.exec(fieldName);
        if (matches && value) {
            this.checkDriver(matches[1], matches[2]);
        }
        if (matches) {
            this.setResource(matches[1], matches[2], 'driver_uuid', value, originalValue);
        }
        const matches3 = /^order\.shifts\.([0-9]+)\.runs\.([0-9]+)\.check_taker_uuid$/.exec(fieldName);
        if (matches3 && value) {
            this.checkDriver(matches3[1], matches3[2]);
        }
        if (matches3) {
            this.setResource(matches3[1], matches3[2], 'check_taker_uuid', value, originalValue);
        }
        const matches2 = /^order\.shifts\.([0-9]+)\.runs\.([0-9]+)\.vehicle_uuid$/.exec(fieldName);
        if (matches2 && value) {
            this.checkVehicle(matches2[1], matches2[2]);
        }
        if (matches2) {
            this.setResource(matches2[1], matches2[2], 'vehicle_uuid', value, originalValue);
        }
    }

    async setResource(shiftIndex, runIndex, field, value, originalResource) {
        let order = this.state.order;

        shiftIndex = _.toInteger(shiftIndex);
        runIndex = _.toInteger(runIndex);

        let fieldsToUpdate = [];

        order.shifts = _.map(order.shifts, (shift, _shiftIndex) => {
            if (((field === 'vehicle_uuid') && (_shiftIndex >= shiftIndex)) || (_shiftIndex === shiftIndex)) {
                shift.runs = _.map(shift.runs, (run, _runIndex) => {
                    if (((_shiftIndex === shiftIndex) && (_runIndex > runIndex)) || (_shiftIndex > shiftIndex)) {
                        if (_.get(run, field) === originalResource) {
                            run[field] = value;

                            fieldsToUpdate.push(`order.shifts.${_shiftIndex}.runs.${_runIndex}.${field}`)
                        }
                    }
                    return run;
                });
            }
            return shift;
        });

        await this.setState({order});

        _.each(fieldsToUpdate, (field) => {
            this.refs[field] && this.refs[field].reload();
        })
    }

    async loadDriversForSelect(shiftIndex, runIndex, input, callback) {
        if (!input) {
            input = this.get(`shifts.${shiftIndex}.runs.${runIndex}.driver_uuid`);
            if (!input) {
                callback(null, {
                    options: [],
                    complete: false,
                });
                return;
            }
        }
        let request = _.get(this.requests.users, input);
        if (request) {
            const result = await request;

            if (result.isOk) {
                callback(null, {
                    options: _.sortBy(result.payload.items.map(i => ({
                        label: new User(i).getFullName(),
                        value: i.uuid,
                    })), 'label'),
                    complete: false
                });
            }
            return;
        }
        request = this.props.getUsers({
            filters: {
                withComponent: component_mapper(this.props.params.component),
                withPositionTypes: [
                    'driver',
                ],
            },
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
            response_data: [
                'items/uuid',
                'items/info/surname',
                'items/info/name',
                'items/info/second_name',
            ],
        });

        this.requests.users[input] = request;
        const result = await request;
        this.requests.users[input] = null;

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: new User(i).getFullName(),
                    value: i.uuid,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    async loadCheckTakersForSelect(shiftIndex, runIndex, input, callback) {
        if (!input) {
            input = this.get(`shifts.${shiftIndex}.runs.${runIndex}.check_taker_uuid`);
            if (!input) {
                callback(null, {
                    options: [],
                    complete: false,
                });
                return;
            }
        }
        let request = _.get(this.requests.users, input);
        if (request) {
            const result = await request;
            if (result.isOk) {
                callback(null, {
                    options: _.sortBy(result.payload.items.map(i => ({
                        label: new User(i).getFullName(),
                        value: i.uuid,
                    })), 'label'),
                    complete: false
                });
            }
            return;
        }
        request = this.props.getUsers({
            filters: {
                withComponent: component_mapper(this.props.params.component),
                withPositionTypes: [
                    'worker',
                ],
            },
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
            response_data: [
                'items/uuid',
                'items/info/surname',
                'items/info/name',
                'items/info/second_name',
            ],
        });

        this.requests.users[input] = request;
        const result = await request;
        this.requests.users[input] = null;

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: new User(i).getFullName(),
                    value: i.uuid,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    async loadVehiclesForSelect(shiftIndex, runIndex, input, callback) {
        if (!input) {
            input = this.get(`shifts.${shiftIndex}.runs.${runIndex}.vehicle_uuid`);
            if (!input) {
                callback(null, {
                    options: [],
                    complete: false,
                });
                return;
            }
        }

        let request = _.get(this.requests.vehicles, input);
        if (request) {
            const result = await request;
            if (result.isOk) {
                callback(null, {
                    options: _.sortBy(result.payload.items.map(item => ({
                        label: item.state_number,
                        value: item.uuid,
                    })), 'label'),
                    complete: false
                });
            }
            return;
        }
        request = this.props.getVehicleList({
            filters: {
                withComponent: component_mapper(this.props.params.component),
                withoutScrapped: true,
                onlyApproved: true,
                withActiveBnso: true,
            },
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
            response_data: [
                'items/uuid',
                'items/state_number',
            ],
        });

        this.requests.vehicles[input] = request;
        const result = await request;
        this.requests.vehicles[input] = null;

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(item => ({
                    label: item.state_number,
                    value: item.uuid,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        _.each(dictionaries, async (dictionary) => {
            const documents = await this.getDictionary(dictionary, component, withoutOrder);

            let state = this.state;
            state[dictionary] = documents;
            this.setState(state);
        });
    }

    async getDictionary(dictionary, component = null, withoutOrder = false) {
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionary, meta);
        if (response.isOk) {
            return _.map(response.payload.documents, (document) => ({
                value: document.uuid,
                label: document.short_name || document.name,
            }));
        } else {
            response.showErrors();
        }
    }

    get(field) {
        return _.get(this.state.order, field);
    }

    async checkVehicle(shiftIndex, index) {
        if (!window.RNIS_SETTINGS.resourcesGroupCheck) {
            const run = _.get(this.state.order, `shifts.${shiftIndex}.runs.${index}`);
            const lastRun = _.get(this.state.order, `shifts.${shiftIndex}.runs.${this.state.order.shifts[shiftIndex].runs.length - 1}`)
            const response = await this.props.resourceCheck({
                order_uuid: this.get('order_uuid'),
                vehicle_uuid: _.get(this.state.order, `shifts.${shiftIndex}.runs.${index}.vehicle_uuid`),
                date_from: run.date_from,
                date_to: lastRun.date_to,
            });

            if (!response.isOk) {
                response.showErrors();
                this.onChange(`order.shifts.${shiftIndex}.runs.${index}.vehicle_uuid`, null);
            }
        }
    }

    async checkDriver(shiftIndex, index) {
        if (!window.RNIS_SETTINGS.resourcesGroupCheck) {
            const run = _.get(this.state.order, `shifts.${shiftIndex}.runs.${index}`);
            const lastRun = _.get(this.state.order, `shifts.${shiftIndex}.runs.${this.state.order.shifts[shiftIndex].runs.length - 1}`)
            const response = await this.props.resourceCheck({
                order_uuid: this.get('order_uuid'),
                driver_uuid: _.get(this.state.order, `shifts.${shiftIndex}.runs.${index}.driver_uuid`),
                date_from: run.date_from,
                date_to: lastRun.date_to,
            });

            if (!response.isOk) {
                response.showErrors();
                this.onChange(`order.shifts.${shiftIndex}.runs.${index}.driver_uuid`, null);
            }
        }
    }
}
