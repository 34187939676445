import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import WidgetSelector from 'components/modules/analytics/base-components/WidgetSelector';
import BarChart from 'components/modules/analytics/base-components/charts/BarChart';
import Datepicker from "components/ui/form/datepicker";
import moment from 'moment';
import formats from 'dictionaries/formats';
import _ from 'lodash';
import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import GlobalLoaderComponent from "components/ui/global-loader";
import './style.less';
import {connect} from "react-redux";
import {speedViolationsSummary} from "store/reducers/notifications/speed-violations";
import {getUnits} from "store/reducers/organizational_units/units";
import * as storage from "utils/storage";
import * as alerts from "helpers/alerts";
import {Text} from "recharts";

@propTypes({
    barChartFillColor: PropTypes.string
})

@defaultProps({
    barChartFillColor: ''
})

@connect(state => ({}), {getUnits, speedViolationsSummary})

export default class SpeedViolationWidget extends Component {

    storageKey = 'analytics:passenger:speed-violations';

    constructor(props) {
        super(props);

        const savedState = storage.get(this.storageKey);
        this.state = {
            from: savedState ? moment(savedState.from).format(formats.DATE_API) : moment().subtract(1, 'day').format(formats.DATE_API),
            to: savedState ? moment(savedState.to).format(formats.DATE_API) : moment().endOf('day').format(formats.DATE_API),
            organizationUnits: [],
            data: {
                unitsByDate: []
            }
        };
    }

    saveState() {
        storage.set(this.storageKey, {
            from: moment(this.state.from).format(formats.DATE_API),
            to: moment(this.state.to).format(formats.DATE_API),
            organizationUnits: _.map(_.filter(this.state.organizationUnits, {checked: true}), 'uuid'),
        });
    }

    componentWillMount() {
        this.loadUnits();
        this.loadData();
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withComponent: 'kiutr',
            },
        });

        if (response.isOk) {
            const savedState = storage.get(this.storageKey);
            let organizationUnits = response.payload.items;
            if (savedState) {
                _.each(savedState.organizationUnits, (uuid) => {
                    let unit = _.find(organizationUnits, {uuid});
                    if (unit) {
                        unit.checked = true;
                    }
                });
            }
            this.setState({
                organizationUnits,
            });
        } else {
            response.showError();
        }
    }

    async getViolations(units) {
        const response = await this.props.speedViolationsSummary(_.map(units, 'uuid'), this.state.from, this.state.to);

        if (response.isOk) {
            return response.payload.items;
        } else {
            response.showErrors();
        }
    }

    async loadData() {
        this.setState({isError: false, isLoading: true});

        try {
            const units = _.filter(this.state.organizationUnits, {checked: true});
            const violationsSummary = await this.getViolations(units);

            const unitsByDate = _.map(violationsSummary, (summary) => {
                let data = summary.data;
                data.date = moment(summary.date).format(formats.DATE);
                data.config = {};

                _.each(units, (unit) => {
                    data.config[unit.uuid] = {
                        name: _.truncate(unit.name, {
                            length: 20,
                        }),
                    };
                });

                data.violationsCount = _.max(_.values(summary.data));
                return data;
            });

            const data = {
                unitsByDate,
            };

            this.setState({data, isError: false, isLoading: false});
        }
        catch (e) {
            this.setState({isError: true, isLoading: false});
            error(e).showErrors();
        }
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value});
        this.saveState();
        this.loadData();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value});
        this.saveState();
        this.loadData();
    };

    async onSelectedUnitsChange(param) {
        if ((_.filter(this.state.organizationUnits, {checked: true}).length + _.filter(param, {checked: true}).length) > 5) {
            if (_.filter(this.state.organizationUnits, {checked: true}).length > 5) {
                this.setState({
                    organizationUnits: _.map(this.state.organizationUnits, (unit) => {
                        unit.checked = false;
                        return unit;
                    }),
                });
            }
            alerts.error('Допустим выбор до 5ти предприятий включительно');
            return;
        }
        let organizationUnits = this.state.organizationUnits;
        _.each(param, (p) => {
            let index = p.index;
            organizationUnits[index].checked = p.checked;
        });
        await this.setState({
            organizationUnits,
        });
        this.saveState();
        this.loadData();
    }

    renderContent() {
        return (
            <div className="full-size">
                <div className="tools-wrapper">
                    <div className="tools-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange}/>
                    </div>
                    <div className="tools-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange}/>
                    </div>
                    <div className="tools-item select-wrapper">
                        <WidgetSelector
                            title="Транспортное предприятие"
                            items={this.state.organizationUnits}
                            onChange={::this.onSelectedUnitsChange}
                        />
                    </div>
                </div>
                <div className="chart-wrapper">
                    <BarChart data={this.state.data.unitsByDate}
                              xDataKey="date"
                              yDataKey="violationsCount"
                              yLabel={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle" angle={-90}>Кол-во</Text>}
                              configKey="config"
                              color={this.props.barChartFillColor}
                    />
                </div>
            </div>
        );
    }

    render() {
        const loader = this.state.isLoading ? (
            <GlobalLoaderComponent/>
        ) : null;

        const content = this.renderContent();

        return (
            <div className="speed-violation-widget">
                {loader}
                {content}
            </div>
        );
    }
}