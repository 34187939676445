import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import classNames from 'classnames';
import ReactDOMServer from 'react-dom/server';
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import PageModal from 'components/ui/page-modal';
import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import TableContainer from "components/ui/Table/Container/TableContainer";
import SlideLeftTransition from "components/ui/transitions/slide-left";
import {cloneIntervalMap, deleteIntervalMap, getIntervalMaps} from "store/reducers/routes/interval_maps";
import moment from "moment";
import formats from "dictionaries/formats";
import IntervalMapEditor from "components/modules/kiutr/routes/interval_map_editor";
import "./interval_maps.less";
import IntervalMapValuesEditor from "components/modules/kiutr/routes/interval_map_values_editor";
import FilterHeader from "components/ui/filter-header";
import {State} from "components/ui/state";
import ContextTooltip from "components/ui/context-tooltip";
import {resizeModals} from 'helpers/modal-size';
import {events} from 'dom-helpers';
import {getRoute} from "store/reducers/routes/route_editor";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import * as alerts from "helpers/alerts";
import GlobalLoaderComponent from "components/ui/global-loader";
import TableComponent from "components/ui/Table/Component/TableComponent";

let resizeId;

@propTypes({
    routeVariantUuid: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string
})

@connect(state => ({}), {getIntervalMaps, cloneIntervalMap, deleteIntervalMap, getRoute, getDictionaryList})

export default class KiutrIntervalMapsComponent extends BaseTableWithEditorComponent {

    static childContextTypes = {
        resizeModals: PropTypes.func
    };

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            intervalMapModalData: null,
            intervalMapModalDataMode: null,
            route: {},
            route_types: [],
        });

        this.onResize = this.onResize.bind(this);
    }

    getChildContext() {
        return {resizeModals: this.resizeModals};
    }

    componentWillMount() {
        events.on(window, 'resize', this.onResize);
    }

    async componentWillReceiveProps(props, state) {
        setTimeout(() => {
            this.resizeModals();
        }, 500);
    }

    componentWillUpdate(props, state) {
        if (props.params && props.params.uuid3) {
            if (!state.intervalMapModalData || state.intervalMapModalData.uuid !== props.params.uuid3) {
                this.setState({
                    intervalMapModalData: {
                        uuid: props.params.uuid3,
                    },
                    intervalMapModalDataMode: 'edit'
                });
            }
        }
    }

    componentWillUnmount() {
        events.off(window, 'resize', this.onResize);
    }

    onResize() {
        clearTimeout(resizeId);
        resizeId = setTimeout(this.resizeModals, 1000);
    }

    resizeModals() {
        const ignoreClasses = ['route-add-points-modal', 'add-time-interval-modal', 'map-route-editor-modal', 'add-stopping-point-modal'];
        resizeModals('.b-modal', 'b-modal__collapse', 'move-right', ignoreClasses);
    }

    componentDidMount() {
        this.loadDictionaries([
            'route_types',
        ]);
        this.getRoute();
        this.forceUpdate();
    }

    async getRoute() {
        const response = await this.props.getRoute(this.props.routeUuid);

        if (response.isOk) {
            this.setState({
                route: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    isBothSide() {
        return _.get(this.state.route_types, `${this.state.route.route_type_uuid}.name`) === 'Маятниковый';
    }

    getEditor() {
        return (
            <IntervalMapEditor
                key="editor"
                {...this.props}
                isForward={this.isForward()}
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('№ карты')
                .fromField('name')
                .withClassName('link'),

            new Column('Дата начала')
                .fromField('date_from')
                .withDrawer((item) => item.date_from && moment(item.date_from).format(formats.DATE_SHORT)),

            new Column('Дата конца')
                .fromField('date_to')
                .withDrawer((item) => item.date_to && moment(item.date_to).format(formats.DATE_SHORT)),

            new Column('Пн')
                .fromField('monday')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.monday}/>))
                .withClassName('width-narrow align-center border-none-right')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Вт')
                .fromField('tuesday')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.tuesday}/>))
                .withClassName('width-narrow align-center border-none-right')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Ср')
                .fromField('wednesday')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.wednesday}/>))
                .withClassName('width-narrow align-center border-none-right')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Чт')
                .fromField('thursday')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.thursday}/>))
                .withClassName('width-narrow align-center border-none-right')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Пт')
                .fromField('friday')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.friday}/>))
                .withClassName('width-narrow align-center border-none-right')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Сб')
                .fromField('saturday')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.saturday}/>))
                .withClassName('width-narrow align-center border-none-right')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Вс')
                .fromField('sunday')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.sunday}/>))
                .withClassName('width-narrow align-center border-none-right')
                .denyOrder()
                .denyColumnFilter(),

            new Column('Пр. дни')
                .fromField('holiday')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.holiday}/>))
                .withAlign('center')
                .denyOrder()
                .denyColumnFilter(),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteIntervalMap(data);
    }

    async loadData(meta) {
        meta.filters.withRouteVariant = this.props.routeVariantUuid;
        meta.filters.withDirection = this.isForward();

        return await this.props.getIntervalMaps(meta);
    }

    async onDblClick(data) {
        if (data && data.uuid) {
            this.showIntervalMapValues(data);
        }
    }

    renderTable() {
        const className = classNames({
            'show-table-filters': this.state.showTableFilters,
        });

        return (
            <div className={className}>
                <TableComponent
                    ref="table"
                    className="indent-none"
                    select={this.select}
                    columns={this.state.columns}
                    loadCallback={::this.loadCallbackMiddleware}
                    onColsReordered={::this.onColsReordered}
                    onCheck={::this.selectedRowsRecalc}
                    checkCache={::this.checkCache}
                    query={this.state.query}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    onSettingsLoad={::this.onSettingsLoad}
                    onDblClick={::this.onDblClick}
                    shortPagination={true}
                />
            </div>
        );
    }

    async cloneIntervalMap() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            this.setState({loading: true});
            const response = await this.props.cloneIntervalMap(selected.uuid);
            this.setState({loading: false});

            if (response.isOk) {
                this.reload();
            } else {
                response.showErrors();
            }
        } else {
            alerts.alert('Выберите интервальную карту для клонирования');
        }
    }

    render() {
        let table = this.renderTable();
        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;

        const editor = this.state.showEditorModal ? this.getEditor() : '';

        const buttons = (
            <ModalTopMenuButtons>
                <ModalTopMenuList className="top-menu_modal_edit">
                    <ContextTooltip key="base-editor.clone" code="base-editor.clone" default="Клонировать">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_clone"
                            onClick={::this.cloneIntervalMap}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="base-editor.create" code="base-editor.create" default="Добавить">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_plus"
                            onClick={::this.showEditor}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="base-editor.delete" code="base-editor.delete" default="Удалить">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_basket"
                            onClick={::this.deleteSelected}
                        />
                    </ContextTooltip>
                    <ModalTopMenuListSeparator key="separator"/>
                </ModalTopMenuList>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <div>
                <PageModal
                    header={{title: `Варианты интервалов времени`, buttons}}
                    className={`b-modal-edit time-intervals-modal ${this.props.className}`}
                    onClose={this.props.onClose}
                    withFade={false}
                >
                    {this.isBothSide() ? (
                        <FilterHeader
                            items={['Прямое направление', 'Обратное направление']}
                            currentItem={this.state.currentFilterItem || 0}
                            onChange={async (e) => {
                                await this.setState({currentFilterItem: e.value});
                                this.reload();
                            }}
                        />
                    ) : null}
                    <TableContainer>
                        {loader}
                        {table}
                    </TableContainer>

                    <SlideLeftTransition>
                        {editor}
                    </SlideLeftTransition>
                </PageModal>
                {this.state.intervalMapModalDataMode ? (
                    <IntervalMapValuesEditor
                        uuid={this.state.intervalMapModalData.uuid}
                        currentFilterItem={this.state.currentFilterItem}
                        isForward={this.isForward()}
                        routeVariantUuid={this.props.routeVariantUuid}
                        mode={this.state.intervalMapModalDataMode}
                        onClose={::this.closeIntervalMapValues}
                        onSubmit={this.closeIntervalMapValues.bind(this, true)}
                        className={this.props.className}
                    />
                ) : null}
            </div>
        );
    }

    isForward() {
        return (this.state.currentFilterItem || 0) === 0;
    }

    async closeEditor() {
        await this.setState({
            showEditorModal: false,
            editorUuid: null,
        });
    }

    async showEditorWithUuid(uuid = null) {
        await this.closeEditor();

        this.setState({
            showEditorModal: true,
            editorUuid: uuid,
        });
    }

    resize() {
        this.context.resizeModals && this.context.resizeModals();
    }

    async closeIntervalMapValues(reload = false) {
        await this.setState({
            intervalMapModalData: null,
            intervalMapModalDataMode: null,
        });
        this.resize();
        if (reload) {
            this.reload();
        }
    }

    async showIntervalMapValues(routeVariant) {
        await this.closeIntervalMapValues();
        await this.setState({
            intervalMapModalData: routeVariant,
            intervalMapModalDataMode: 'edit',
        });
        this.resize();
    }

    async showEditor() {
        await this.closeIntervalMapValues();
        await this.setState({
            intervalMapModalData: {
                uuid: null,
            },
            intervalMapModalDataMode: 'add',
        });
        this.resize();
    }
}