import {api} from 'helpers/api';
import {success, error} from 'helpers/response';


export const getObjects = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.garbage.getObjects(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getObject = (uuid) => async (dispatch) => {
    try {
        const response = await api.garbage.getObject(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createObject = (data) => async (dispatch) => {
    try {
        const response = await api.garbage.createObject(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateObject = (data) => async (dispatch) => {
    try {
        const response = await api.garbage.updateObject(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteObject = (data) => async (dispatch) => {
    try {
        const response = await api.garbage.deleteObject(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};