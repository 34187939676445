import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';


import _ from 'lodash';
import Editor from './editor';

import './contracts.less';
import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import moment from "moment";
import formats from "dictionaries/formats";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import {deleteContract, getContracts} from "store/reducers/kiutr/contracts/contracts";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import systems from "dictionaries/systems";
import {getRouteRegistries} from "store/reducers/kiutr/route_registries";
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import Settings from 'settings';
import ContextTooltip from "components/ui/context-tooltip";
import classNames from 'classnames';
import contract_types from "dictionaries/contract_types";
import {component_mapper} from "helpers/component_mapper";

@connect(state => ({}), {getContracts, getEntityNames, getRouteRegistries, deleteContract, getDictionaryList, getUnits})

export default class KiutrContractsComponent extends BaseTableWithEditorComponent {

    getTitle() {
        return `${systems[this.props.params.component]} → ${window.RNIS_SETTINGS.rename_contracts ? 'Подрядные обязательства' : 'Контракты'}`;
    }

    getBaseUrl() {
        return `/${this.props.params.component}/contracts`;
    }

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            component: null,
            routeRegistries: null,
            showVis: false,
        });
    }

    componentDidMount() {
       this.forceUpdate();
    }

    async componentWillUpdate(props, state) {
        super.componentWillUpdate(props, state);

        if (component_mapper(props.params.component) !== state.component) {
            await this.setState({
                component: component_mapper(props.params.component),
                columns: List(this.getColumns(component_mapper(props.params.component))),
            });
            state.component && this.reload();
        }
    }

    getEditor() {
        return (
            <Editor
                {...this.props}
                isPageWithDetect={true}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    async submitEditor(item) {
        const mode = this.state.editorUuid ? 'edit' : 'add';

        super.submitEditor();

        if (mode === 'add') {
            this.props.router.push(`/${this.props.params.component}/contracts/${item.uuid}/routes`);
        }
    }

    getColumns() {
        return this.prepareColumns([

            new Column(`№ ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}`)
                .fromField('number'),

            new Column('Тип')
                .fromField('type')
                .denyColumnFilter()
                .withDrawer((item) => _.get(contract_types, item.type))
                .withFilter('withType', async () => {
                    return _.map(contract_types, (name, uuid) => ({
                        uuid,
                        name,
                    }));
                }),

            new Column(window.RNIS_SETTINGS.rename_contracts ? 'Дата подрядного обязательства' : 'Дата контракта')
                .fromField('date')
                .withDateFilter()
                .withDrawer((item) => item.date && moment(item.date).format(formats.DATE)),

            new Column(`Дата добавления ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}`)
                .fromField('created_at')
                .withDateFilter()
                .withDrawer((item) => item.created_at && moment(item.created_at).format(formats.DATETIME_SHORT)),

            new Column('Перевозчик')
                .fromField('carrier_uuid')
                .withDrawer(item => item.carrier_uuid && this.state.related.get(item.carrier_uuid))
                .withFilter('withCarriers', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 1000,
                        },
                        filters: {
                            withComponent: component_mapper(this.props.params.component),
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            new Column('Дата начала перевозок')
                .fromField('work_from')
                .withDateFilter()
                .withDrawer((item) => item.work_from && moment(item.work_from).format(formats.DATE)),

            new Column('Дата окончания перевозок')
                .fromField('work_to')
                .withDateFilter()
                .withDrawer((item) => item.work_to && moment(item.work_to).format(formats.DATE)),

            new Column('Организатор перевозок')
                .fromField('unit_uuid')
                .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid))
                .withFilter('withUnits', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 1000,
                        },
                        filters: {
                            withComponent: component_mapper(this.props.params.component),
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            new Column('Маршруты')
                .fromField('route')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => {return (item.route ? item.route.join(', ') : undefined)} ),
                // .withDrawer(item => {
                //     return _.filter(this.state.routeRegistries, {contract_uuid: item.uuid})
                //     .map(routeRegistry => _.get(routeRegistry, 'route.number')).join(', ');
                // }),
                

            new Column('Статус')
                .fromField('status_uuid')
                .withDrawer(item => item.status_uuid && this.state.related.get(item.status_uuid))
                .denyColumnFilter()
                .withFilter('withStatus', async () => {
                    const response = await this.props.getDictionaryList('contract_statuses');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteContract(data);
    }

    async loadData(meta) {
        _.set(meta, 'filters.withComponent', this.state.component);
        _.set(meta, 'filters.onlyVis', this.state.showVis);
        _.set(meta, 'response_data', [
            'items/uuid',
            'items/deleted_at',
            'items/number',
            'items/date',
            'items/created_at',
            'items/carrier_uuid',
            'items/work_from',
            'items/work_to',
            'items/unit_uuid',
            'items/status_uuid',
            'items/type',
        ]);

        let response = await this.props.getContracts(meta);

        if (response.isOk) {

            let result = await this.props.getRouteRegistries({
                filters: {
                    withContracts: _.map(response.payload.items, 'uuid'),
                },
                response_data: [
                    'items/contract_uuid',
                    'items/route/number',
                ],
            });

            if(result.isOk) {

                response.payload.items.map((item) => {
                    result.payload.items.map((route) => {
                        if(route.contract_uuid === item.uuid) {
                            if(!item.route) {
                                item.route = [];
                            }
                            item.route.push(route.route.number);
                        }
                    })
                    return item;
                })
            }
        }
        return response;
    }

    async loadContracts(meta) {


    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const statuses = _.map(_.filter(_.map(result, 'status_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\ContractStatuses\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const units = _.map(_.filter(_.map(result, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const carriers = _.map(_.filter(_.map(result, 'carrier_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        this.props.getEntityNames(_.concat(statuses, units, carriers)).then(async (response) => {
            if (response.isOk) {
                this.state.related.add(response);

                drawCallback(json);
            }
        });

        // const response2 = await this.props.getRouteRegistries({
        //     filters: {
        //         withContracts: _.map(result, 'uuid'),
        //     },
        //     response_data: [
        //         'items/contract_uuid',
        //         'items/route/number',
        //     ],
        // });
        // if (response2.isOk) {
        //     console.log('getRouteRegistries')
        //     await this.setState({
        //         routeRegistries: response2.payload.items,
        //     });

        //     drawCallback(json);
        // }
    }

    renderTable() {
        return [
            super.renderTable(),
            <ContextTooltip key="status"
                            position="left"
                            default={Settings.get('vis_import') || '-'}>
                <div className="vis-status"/>
            </ContextTooltip>,
        ];
    }

    renderHeaderContents() {
        return _.concat([
            <ContextTooltip key="base-table-list.show-vis" code="base-table-list.show-vis" space={20}
                            default="РНИС/ВИС">
                {this.renderVisToggle()}
            </ContextTooltip>,
        ], super.renderHeaderContents());
    }

    renderVisToggle() {
        const lineClassName = classNames('b-slider__line', this.state.showVis ? '_selected_all' : '');
        const circleClassName = classNames('b-slider__circle', this.state.showVis ? '_selected_all' : '');

        return (
            <div className={`b-slider b-slider-deleted _top-menu`} onClick={::this.toggleShowVis}>
                <div className="b-slider__control">
                    <div className={lineClassName}/>
                    <div className={circleClassName}/>
                </div>
            </div>
        );
    }

    async toggleShowVis() {
        await this.setState({
            showVis: !this.state.showVis,
        });
        this.reload();
    }
}