import React, {Component} from 'react';
import classNames from 'classnames';
import L from 'leaflet';
import 'leaflet-plugins/layer/tile/Yandex.js'
import Script from 'react-load-script';
import $ from 'jquery';

export default class TrafficLayer extends Component {
    state = {
        isLayerActive: false,
    };

    traffic = null;

    toggleTrafficLayer() {
        const {map} = this.props;
        if (!this.state.isLayerActive) {
            this.traffic && this.displayTraffic();
        } else if (this.traffic) {
            $(".ymaps-i-ua_js_yes").hide();
            map.removeLayer(this.traffic);
            const el = this.getYMap();
            if (el) {
                el.style.zIndex = 0;
            }
        }
        this.setState({isLayerActive: !this.state.isLayerActive});
    }

    onYMapInit() {
        if (!this.traffic) {
            this.createTraffic();
        }
    }

    createTraffic() {
        setTimeout(() => {
            this.traffic = new L.Yandex("null", {traffic: true, opacity: 1, overlay: true});

            if (this.state.isLayerActive) {
                this.displayTraffic();
            }
        }, 1000);
    }

    displayTraffic() {
        $(".ymaps-i-ua_js_yes").show();
        const {map} = this.props;
        map.addLayer(this.traffic);
        this.moveYMapToFront();
    }

    moveYMapToFront() {
        const el = this.getYMap();
        if (!el) {
            setTimeout(::this.moveYMapToFront, 100);
            return;
        }

        const prevEl = document.getElementsByClassName('leaflet-tile-pane')[0];
        prevEl.parentNode.insertBefore(el, prevEl.nextSibling);
        el.style.zIndex = 300;

        const mapPane = $('.leaflet-map-pane');
        this.props.map.on('move', () => {
            const transform = mapPane.css('transform');
            const matches = transform.match(/matrix\(1, 0, 0, 1, (.+), (.+)\)/);
            const x = parseFloat(matches[1]);
            const y = parseFloat(matches[2]);

            $(el).css('transform', 'translate3d(' + (-x) + 'px, ' + (-y) + 'px, 0px)');
        });
    }

    getYMap() {
        return document.getElementsByClassName('ymaps-map')[0];
    }

    render() {
        const {isLayerActive} = this.state;
        return (
            <div className="route-block traffic-button">
                {isLayerActive ? (
                    <Script url="https://api-maps.yandex.ru/2.0/?load=package.map&lang=ru-RU"
                            onLoad={::this.onYMapInit}/>
                ) : null}
                <div className="route-block__item">
                    <div className={classNames('route-block__btn route-block__btn_area', {
                        'route-block__btn_white': !isLayerActive,
                        'route-block__btn_red': isLayerActive,
                    })} onClick={::this.toggleTrafficLayer}>
                        <i className={classNames('rnis-icon', {
                            'rnis-icon_traffic': !isLayerActive,
                            'rnis-icon_close_white': isLayerActive,
                        })}/>
                    </div>
                </div>
            </div>
        );
    }
}
