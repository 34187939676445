import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getSupernumerarySituations = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.getSupernumerarySituations(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getSupernumerarySituation = (uuid) => async (dispatch) => {
    try {
        const response = await api.commdept.getSupernumerarySituation(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createSupernumerarySituation = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.createSupernumerarySituation(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateSupernumerarySituation = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.updateSupernumerarySituation(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteSupernumerarySituation = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.deleteSupernumerarySituation(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
