import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import Page from 'components/ui/page';
import currentUser from 'helpers/current-user';
import _ from 'lodash';

import './home.less';
import SIPComponent from "components/ui/sip_block";
import {GlobalEvent} from "helpers/event-system";
import Button from "components/ui/button";
import TextField from "components/ui/text-field";
import Block from "components/ui/form/block";
import {connect} from "react-redux";
import {getUnit} from "store/reducers/organizational_units/units";
import * as alerts from "helpers/alerts";
import {getHostName} from "helpers/wsrpc";

@connect(state => ({}), {getUnit})

export default class HomeComponent extends Component {

    state = {
        unit: '-',
    };

    componentWillMount() {
        this.loadUnit();

    }

    async loadUnit() {
        const response = await this.props.getUnit(_.get(currentUser, 'user.info.unit_uuid'));
        if (response.isOk) {
            this.setUnitImage(response.payload.organization_image);
            this.setState({
                unit: response.payload.name,
            });
        } else {
            this.setState({
                unit: '-',
            });
        }
    }

    setUnitImage(img) {
        let lsObject = {
            organization_image: img
        };
        // если переменной localsettings нет в localstorage, то кладем туда текущее значение
        localStorage.getItem("localsettings") === null ? localStorage.setItem('localsettings', JSON.stringify(lsObject)) : null;
        // если текущее значение отличается от нового - перезаписываем его
        JSON.parse(localStorage.getItem("localsettings")).organization_image !== img ? localStorage.setItem('localsettings', JSON.stringify(lsObject)) : null;

        let image =  JSON.parse(localStorage.getItem("localsettings"));
        const protocol = App.isSecure ? 'https://' : 'http://';
        let url = protocol + getHostName('ajax', false) + '/storage/' + image.organization_image;

        this.setState({
            organization_image: url,
        });
    }



    hasImage() {
        let img = JSON.parse(localStorage.getItem("localsettings"));
        return _.has(img, 'organization_image') && img.organization_image && img.organization_image.length;
    }

    render() {
        return (
            <Page pageId="Home" title="Стартовая страница">
                {(this.hasImage() ? (
                    <img src={this.state.organization_image} className="org_img-preview"/>) : null)}
                <p>{currentUser.name}</p>
                <p>Учетная запись: {currentUser.login}</p>
                <p>Роль: {_.map(currentUser.user.roles, 'name').join(', ')}</p>
                <p>Предприятие: {this.state.unit}</p>
                {this.props.location.query.sip ? (
                    <div>
                        <Button size="md" color="red" shadow="red" text="Позвонить" onClick={::this.call}/>
                    </div>
                ) : null}
            </Page>
        );
    }

    onChange(field, {target: {value}}) {
        let state = this.state;
        _.set(state, field, value);
        this.setState(state);
    }

    call() {
        GlobalEvent().call('call:start', this.props.location.query.sip);
    }

}