import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";
import {getUnits} from "store/reducers/organizational_units/units";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import FileReaderInput from 'react-file-reader-input';

import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import {api} from "helpers/api";
import {createVehicleWork} from "store/reducers/kurs/vehicle_works";
import TableContainer from "components/ui/Table/Container/TableContainer";
import * as alerts from "helpers/alerts";

export default class UtilityDetailAddForm extends BaseEditor {

    modalClassName = 'b-modal-road-accounting edit-detail-modal';
    withFade = false;

    getFullTitle() {
        return 'Выбор детали';
    }

    async create(data) {
        let errors = {};
        if (!data.accounting_group_uuid) {
            errors.accounting_group_uuid = [
                'Поле обязательно для заполнения',
            ];
        }
        if (!data.detail_uuid) {
            errors.detail_uuid = [
                'Поле обязательно для заполнения',
            ];
        }
        if (!data.amount) {
            errors.amount = [
                'Поле обязательно для заполнения',
            ];
        }
        if (_.size(errors) > 0) {
            this.setState({
                errors,
            });
        } else {
            this.props.onSubmit(data);
        }
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    getButtons() {
        return [
            <div key="tooltip" className="b-modal__footer-txt">Вы хотите добавить з/ч?</div>,
            <a key="save" href="javascript:void(0)" className="b-button b-button_red b-button_size_md b-button_save"
               onClick={(this.props.mode === 'edit') ? ::this.onEdit : ::this.onCreate}>Добавить</a>
        ];
    }

    saveButton(onClick) {
        return null;
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getUnits, getUsers, getDictionaryList}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {

    state = {
        detail: {},
        kurs_accounting_groups: [],
        kurs_details: [],
    };

    getData() {
        return this.state.detail;
    }

    async componentDidMount() {
        await this.setState({
            detail: this.props.data,
        });
        this.loadDictionaries([
            'kurs_accounting_groups',
            'kurs_details',
        ], this.props.component);
    }

    get(path, defaultValue = null) {
        return _.get(this.state.detail, path, defaultValue);
    }

    async setValue(field, value) {
        if (field === 'detail.amount') {
            value = value.substr(0, 6);
        }

        super.setValue(field, value);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem title="" opened={true} single={true}>
                        <Block size="xl" title="Группа учета">
                            {this.select('detail.accounting_group_uuid', this.state.kurs_accounting_groups)}
                        </Block>
                        <Block size="xl" title="Наименование">
                            {this.select('detail.detail_uuid', _.filter(this.state.kurs_details, {document: {kurs_accounting_group_uuid: this.get('accounting_group_uuid')}}))}
                        </Block>
                        <Block size="xl" title="Количество">
                            {this.textInput('detail.amount', {
                                type: 'number',
                                positive: true,
                            })}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}