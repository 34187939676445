import React, {Component} from "react";
import TableContainer from "components/ui/Table/Container/TableContainer";

import "./style.less"
import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import classnames from "classnames";
import { callInitialOnce } from "helpers/functions";
import { getSmsDevicesList, deleteSmsDevice } from "store/reducers/sms/sms";
import Button from "../../../ui/button";
import SmsDeviceEditor from "./DeviceEditor/DeviceEditor";
import * as alerts from "helpers/alerts";

@connect(state => ({}), { getSmsDevicesList, deleteSmsDevice })

export default class SmsDevices extends Component {

    constructor() {
        super();

        this.state = {
            loading: true,
            devices: [],
            editorActive: false,
            editorActiveUuid: null,
            selectedTableItem: null,
        }
    }

    componentDidMount() {
        callInitialOnce(::this.loadDevicesList)
    }

    componentWillUnmount() {
        localStorage.removeItem("initial")
        this.props.reset && this.props.reset()
    }

    async loadDevicesList() {
        const resolve = await this.props.getSmsDevicesList();
        if (resolve.isOk) {
            this.setState({
                loading: false,
                selectedTableItem: null,
                devices: resolve.payload.items
            })
        }
    }

    renderTableBody() {
        return null
    }

    handleToggleEditor() {
        this.setState({
            editorActive: !this.state.editorActive,
        })
    }

    hideEditor() {
        this.handleToggleEditor();
    }

    submitEditor() {
        this.loadDevicesList();
        this.handleToggleEditor();
    }

    handleGoBack() {
        this.props.handleButtonClickCase('button')
    }

    handleTableItemClick(item) {
        if (!this.state.selectedTableItem) {
            this.setState({
                selectedTableItem: item.uuid
            })
        }
        if (this.state.selectedTableItem && this.state.selectedTableItem !== item.uuid) {
            this.setState({
                selectedTableItem: item.uuid
            })
        }
        if (this.state.selectedTableItem === item.uuid) {
            this.setState({
                editorActive: true,
                editorActiveUuid: item.uuid,
            })
        }
    }

    async handleDeleteTableItem() {
        const device = this.state.devices.filter(item => item.uuid === this.state.selectedTableItem)[0];
        const isConfirmed = await new Promise((resolve, reject) => {
            alerts.prompt(`Вы уверены, что хотите удалить устройство ${device.provider_device_id}?`, null, () => {
                resolve(true);
            }, 'Удалить', () => {
                resolve(false);
            });
        });
        if (!isConfirmed) {
            return;
        }
        await this.props.deleteSmsDevice({ uuid: device.uuid });
        this.loadDevicesList();
    }

    render() {
        if (this.state.loading) {
            return <GlobalLoaderComponent/>
        }
        return (
            <div className="sms-devices">
                {this.state.editorActive ? <SmsDeviceEditor devices={this.state.devices.length ? true : false} uuid={this.state.editorActiveUuid} mode={this.state.editorActiveUuid ? 'edit' : 'add'} onClose={::this.hideEditor}
                                                              onSubmit={::this.submitEditor} /> : null}
                <h2 className="sms-devices__title">Устройства для СМС шлюза</h2>
                <p className="sms-devices__goback" onClick={() => this.handleGoBack()}>&#129044; Назад</p>
                <Button className="sms-devices__add-button" size="md" text="Добавить" color="red" shadow="red"
                        onClick={() => this.handleToggleEditor()}/>
                <Button disabled={!this.state.selectedTableItem} className="sms-devices__delete-button" size="md" text="Удалить" color="red" shadow="red"
                        onClick={() => this.handleDeleteTableItem()}/>
                <div className="Table">
                    <TableContainer>
                        <table className="b-table b-table-no-hover">
                            <thead>
                            <tr className="b-table__header">
                                <th>ID устройства в SMS-шлюзе</th>
                                <th>Наименование</th>
                                <th>Использовать по умолчанию</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.devices.map( (device, index) => {
                                return <tr key={device.uuid + index}
                                           onClick={() => this.handleTableItemClick(device)}
                                           className={classnames("sms-templates__row-body", device.is_default && 'default', device.uuid === this.state.selectedTableItem && "active")}>
                                    <td>{device.provider_device_id}</td>
                                    <td>{device.title}</td>
                                    <td>{device.is_default ? 'Да' : 'Нет'}</td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </div>
        );
    }
}