import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import {List} from 'immutable';
import _ from 'lodash';
import $ from 'jquery';
import debounce from 'throttle-debounce/debounce';

import Tables from "dictionaries/tables";
import {getOption, setUserOptionValue} from "store/reducers/settings";

import {TableColumnFilterSelect} from "components/ui/Table/Column/Filter/TableColumnFilterSelect";
import {TableColumnFilterDate} from "components/ui/Table/Column/Filter/TableColumnFilterDate";
import {TableColumnFilterText} from "components/ui/Table/Column/Filter/TableColumnFilterText";
import {TableColumnFilterNumber} from "components/ui/Table/Column/Filter/TableColumnFilterNumber";
import * as storage from "utils/storage";

window.$ = require('jquery');
window.dt = require('datatables.net');
require('imports-loader?define=>false!datatables.net-colreorder')(window, $);
require('imports-loader?define=>false!datatables.net-select')(window, $);
require('imports-loader?define=>false!lib/datatables-treegrid')(window, $);

@propTypes({
    columns: PropTypes.object,
    defaultOrder: PropTypes.array,
    select: PropTypes.oneOf(['none', 'single', 'multiple']),
    rows: PropTypes.instanceOf(List),
    onColsReordered: PropTypes.func,
    onDblClick: PropTypes.func,
    loadCallback: PropTypes.func,
    onCheck: PropTypes.func,
    query: PropTypes.string,
    showTableSearchFooter: PropTypes.bool,
    onColumnFilterChange: PropTypes.func.isRequired,
    onSettingsLoad: PropTypes.func,
    onClick: PropTypes.func,
    shortPagination: PropTypes.bool,
    stateSave: PropTypes.bool,
    onExpand: PropTypes.func,
    onCollapse: PropTypes.func
})

@defaultProps({
    onColsReordered: () => {
    },
    onDblClick: () => {
    },
    onCheck: () => {
    },
    loadCallback: () => {
    },
    onSettingsLoad: () => {
    },
    query: '',
    select: 'multiple',
    defaultOrder: [[1, 'asc']],
    showTableSearchFooter: false,
    shortPagination: false,
    onColumnFilterChange: () => {
    },
    onClick: () => {
    },
    stateSave: true,
    onExpand: () => {
    },
    onCollapse: () => {
    }
})

@connect((state) => ({}), {getOption, setUserOptionValue}, null, {withRef: true})

export default class TableTreeComponent extends Component {

    state = {};

    table = null;
    treeGridInstance = null;
    stateSaveDebounce = debounce(300, ::this.saveState);

    async componentDidMount() {
        if (this.props.stateSave) {
            const savedState = await this.getSavedState();
            if (savedState) {
                this.props.onSettingsLoad(savedState);
                this.init(savedState);
            } else {
                this.init();
            }
        } else {
            this.init();
        }

        //this.props.onInitialized($(this.refs.table));
    }

    init(savedState = null) {
        if (savedState) {
            _.set(savedState, 'search.search', '');
        }
        if (this.table) {
            return;
        }

        this.bindTableDblClick($(this.refs.table));

        const defaultOrder = _.cloneDeep(this.props.defaultOrder);

        if (defaultOrder.length && defaultOrder[0].length) {
            const orderItem = defaultOrder[0];
            if (orderItem[0] >= this.props.columns.size) {
                orderItem[0] = 1;
            }
        }

        let datatableConfig = {
            dom: 'lfrt<"t-pagination"i<"split"<"splitter">>p>',
            colReorder: {
                fixedColumnsLeft: 1,
                realtime: false
            },
            orderMulti: false,
            lengthChange: false,
            searching: true,
            ordering: true,
            paging: true,
            info: true,
            scrollCollapse: false,
            scrollY: '100%',

            stateSave: true,
            stateSaveCallback: this.stateSaveDebounce,
            stateLoadCallback: () => savedState,

            pageLength: 25,

            oLanguage: {
                sProcessing: Tables.PROCESSING,
                oPaginate: {
                    sFirst: this.props.shortPagination ? '' : Tables.PAGINATION.FIRST,
                    sLast: this.props.shortPagination ? '' : Tables.PAGINATION.LAST,
                    sPrevious: this.props.shortPagination ? '' : Tables.PAGINATION.PREVIOUS,
                    sNext: this.props.shortPagination ? '' : Tables.PAGINATION.NEXT
                },
                sEmptyTable: Tables.EMPTY,
                sInfo: this.props.shortPagination ? Tables.INFO_SHORT : Tables.INFO,
                sInfoEmpty: Tables.INFOEMPTY,
                select: {
                    rows: Tables.ROWS
                }
            },

            processing: true,
            serverSide: true,
            ajax: this.props.loadCallback,

            createdRow: this.props.onRowCreate || ::this.onRowCreate,

            columns: this.getColumnDefs(),
            order: defaultOrder,
            treeGrid: {
                disableExpandEvent: true,
                disableCollapseEvent: false,
                left: 10,
                expandIcon: '<span class="icon-plus">+</span>', //'<span class="glyphicon chevron-right" aria-hidden="true"></span>', //ReactDOMServer.renderToStaticMarkup(<Checkbox checked/>),
                collapseIcon: '<span class="icon-minus">-</span>' //'<span class="glyphicon chevron-down" aria-hidden="true"></span>' //ReactDOMServer.renderToStaticMarkup(<Checkbox checked={false}/>)
            }
        };
        if (this.props.select !== 'none') {
            datatableConfig.select = {
                style: this.props.select,
                selector: 'tr, tr:first-child, td, td:first-child'
            };
        }
        this.table = $(this.refs.table).DataTable(datatableConfig);

        this.table.on('draw.dt', ::this.checkPagingVisibility);
        this.table.on('user-select', (e, dt, type, cell, originalEvent) => {
            if ($(originalEvent.target).closest('tr').hasClass('deleted')) {
                e.preventDefault();
            }
        });
        this.table.on('column-reorder', this.props.onColsReordered);
        this.table.on('init', () => {
            setTimeout(() => {
                this.treeGrid();
            }, 2000);
            const self = this;
            $(this.refs.table).closest('.dataTables_wrapper').find('.dataTables_scrollHead').css('overflow', 'visible');
            $(this.refs.table).closest('.dataTables_wrapper').find('.dataTables_scrollBody').on('scroll', (e) => {
                const offset = $(e.target).scrollLeft && $(e.target).scrollLeft();

                $(e.target).prev().css({left: `-${offset}px`});
            });
            window.$(this.refs.table).closest('.dataTables_wrapper').find('thead th').each(function () {
                const $this = window.$(this);
                const container = window.$(self.refs.table);
                $this.on('mouseover', () => {
                    const index = $this.index() + 1;
                    container.find(`td:nth-child(${index})`).addClass('active');
                }).on('mouseout', () => {
                    const index = $this.index() + 1;
                    container.find(`td:nth-child(${index})`).removeClass('active');
                });
                const events = window.$._data(this, 'events');
                const clickEvent = events && events.click && events.click[0];
                if (clickEvent) {
                    $this.unbind('click');
                    $this.click((e) => {
                        if (window.$(e.target).is('th')) {
                            clickEvent.handler(e.originalEvent);
                        }
                    })
                }
                const mouseDownEvent = events && events.mousedown && events.mousedown[0];
                if (mouseDownEvent) {
                    $this.unbind('mousedown');
                    $this.mousedown((e) => {
                        if (window.$(e.target).is('th')) {
                            mouseDownEvent.handler(e.originalEvent);
                        }
                    })
                }
            });
        });

        const table = this.table;
        const onExpand = this.props.onExpand;
        const onCollapse = this.props.onCollapse;

        window.$(this.refs.table).on('click', 'td.treegrid-control', function (e) {
            const row = table.row(this);
            const data = row.data();
            //table.row(this).data(data).draw();
            //table.row(this).invalidate();

            if (data['has_children']) {
                onExpand(data, this, row.index());
            }
        });

        window.$(this.refs.table).on('click', 'td.treegrid-control-open', function (e) {
            const row = table.row(this);
            const data = row.data();

            if (data['has_children']) {
                onCollapse(data, this);
            }
        });
    }

    async getSavedState() {
        return storage.get(this.getStateKey());
    }

    checkPagingVisibility() {
        const info = this.table.page.info();
        if ((info.pages > 0) && (info.pages <= info.page)) {
            setTimeout(() => {
                this.table.page('first').draw();
            }, 500);
        }
        if (info.pages > 1) {
            $(this.refs.table).closest('.Table').find('.dataTables_paginate').show();
        } else {
            $(this.refs.table).closest('.Table').find('.dataTables_paginate').hide();
        }
        if (info.page === 0) {
            $(this.refs.table).closest('.Table').find('.paginate_button.previous').addClass('invisible');
        } else {
            $(this.refs.table).closest('.Table').find('.paginate_button.previous').removeClass('invisible');
        }
        if (info.page === info.pages - 1) {
            $(this.refs.table).closest('.Table').find('.paginate_button.next').addClass('invisible');
        } else {
            $(this.refs.table).closest('.Table').find('.paginate_button.next').removeClass('invisible');
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!this.table) {
            return false;
        }

        this.updateColumnsVisibility(nextProps);
        if (nextProps.query !== this.props.query) {
            this.table.search(nextProps.query).draw();
        }

        return false;
    }

    onRowCreate(row, data, index) {
        if (data.deleted_at) {
            $(row).addClass('deleted');
        }
    }

    getStateKey() {
        if (this.props.stateKey) {
            return `table-state:${this.props.stateKey}`;
        }

        const path = window.location.pathname.replace(/\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/g, '/_uuid_');
        return `table-state:${path}`;
    }

    async saveState(settings, data) {
        if (this.getStateKey().includes('units/units') && window.RNIS_SETTINGS.unit_dictionary_cache_off) {
            return;
        }
        if (!this.props.stateSave) {
            return;
        }
        storage.set(this.getStateKey(), data);
    }

    getSelected() {
        return this.table.rows({selected: true});
    }

    reload() {
        this.table && this.table.draw('full-hold');
    }

    collapseAll() {
        const tg = this.treeGrid();
        if (tg) {
            tg.collapseAll();
        }
    }

    expand(element) {
        _.each(this.treeGrids(), (grid) => {
            grid.expand(element);
        });
    }

    collapse(element) {
        _.each(this.treeGrids(), (grid) => {
            grid.collapse(element);
        });
    }

    treeGrid() {
        if (this.treeGridInstance) {
            return this.treeGridInstance;
        }

        this.treeGridInstance = $(this.refs.table).DataTable.TreeGrid.instance;
        return this.treeGridInstance;
    }

    treeGrids() {
        /*if (this.treeGridInstance) {
            return [this.treeGridInstance, $(this.refs.table).DataTable.TreeGrid.instance];
        }*/

        this.treeGridInstance = $(this.refs.table).DataTable.TreeGrid.instance;
        return [this.treeGridInstance];
    }

    getColumnDefs() {
        const defs = [];

        defs.push({
            orderable: false,
            title: '',
            target: 0,
            className: 'treegrid-control',
            data: (item) => {
                return item['has_children'] /*&& item.children.length*/ ? '<span class="icon-plus">+</span>' : '';
                //return '<span>+</span>';
                //return item.children ? ReactDOMServer.renderToStaticMarkup(<Checkbox checked/>) : '';
            }
        });

        this.props.columns.forEach((column, index) => {
            defs.push({
                data: column.data || column.field,
                name: column.field || column.data,
                visible: !column.hidden,
                orderable: _.isUndefined(column.orderable) ? true : column.orderable,
                defaultContent: '',
                class: column.align ? `align-${column.align}` : column.className,
                width: column.width,
            });
        });

        return defs;
    }

    updateColumnsVisibility(props) {
        props.columns.forEach((column, index) => {
            this.table.column(column.index + 1).visible(!column.hidden);
        });
    }

    render() {
        let dataColsWidth = [10, 30, 50] // это базовые разные ширины колонок при выгрузке. остальные ширины - 40
        while (dataColsWidth.length < [...this.props.columns].length){
            dataColsWidth.push(40)
        }
        dataColsWidth = dataColsWidth.join(', ')

        return (
            <div className="Table">
                <table ref="table" className="b-table order-column" style={{width: '100%'}} data-cols-width={dataColsWidth}>
                    <TableHeader columns={this.props.columns.sortBy((column) => column.index)} {...this.props}/>
                    <TableBody rows={[]} columns={this.props.columns}/>
                    {(this.props.showTableSearchFooter) ?
                        <TableFooter table={this.table} columns={this.props.columns}/> : null}
                </table>
            </div>
        );
    }

    rowGetter(i) {
        return this.props.rows.get(i);
    }

    bindTableDblClick(table) {
        table.on('click', 'tr', (e) => {
            const selector = $(e.target).closest('td');
            const row = this.table.row(selector);
            const col = this.table.column(selector);
            const columns = this.props.columns.toArray();
            const index = col.index() - 1;
            const column = columns[index];
            const data = {field: column && column.field, data: row.data()};
            this.props.onClick(data);
        });

        if (this.props.select === 'none') {
            return;
        }

        table.on('click', 'tr', (e) => {
            const selected = this.getSelected()[0];
            if (selected.length > 0) {
                const row = this.table.row($(e.target).closest('td'));
                if (selected[0] === row[0][0]) {
                    const data = row.data();
                    if (data && !data.deleted_at) {
                        this.props.onDblClick(data, row);
                        return;
                    } else if (data && data.deleted_at) {
                        this.props.showAudit && this.props.showAudit(data);
                        return;
                    }
                }
            }

            if (!$(e.target).is('.treegrid-control') && !$(e.target).is('.treegrid-control span') && !$(e.target).is('.treegrid-control-open') && !$(e.target).is('.treegrid-control-open span')) {
                this.table.row($(e.target).closest('td')).select();
            }
            // else {
            //     this.table.row($(e.target).closest('td')).select();
            // }

            setTimeout(::this.props.onCheck, 100);
        });
    }
}


@propTypes({
    columns: PropTypes.object.isRequired,
    onColumnFilterChange: PropTypes.func.isRequired,
})

export class TableHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            field: '',
            opened: false
        };
    }

    render() {
        return (
            <thead>
            <tr>
                <th width="16px"/>
                {this.props.columns.map(::this.renderColumnHeader)}
            </tr>
            </thead>
        );
    }

    onFilterOpened = (field, opened) => {
        this.setState({
            field,
            opened
        });
    };

    getColumnFilter(column, index) {
        const isOpened = (column.field === this.state.field) && this.state.opened;

        if (column.filter) {
            return (
                <TableColumnFilterSelect
                    index={index}
                    column={column}
                    onChange={this.props.onColumnFilterChange}
                    onFilterOpened={this.onFilterOpened}
                    opened={isOpened}
                />
            );
        }
        if (!column.filterable) {
            return null;
        }

        let FilterComponent = null;
        switch (column.filterType) {
            case 'date':
                FilterComponent = TableColumnFilterDate;
                break;
            case 'number':
                FilterComponent = TableColumnFilterNumber;
                break;
            case 'text':
            default:
                FilterComponent = TableColumnFilterText;
                break;
        }

        return (
            <FilterComponent
                index={index}
                column={column}
                onChange={this.props.onColumnFilterChange}
                onFilterOpened={this.onFilterOpened}
                opened={isOpened}
            />
        );
    }

    renderColumnHeader(column, index) {
        const filter = this.getColumnFilter(column, index);

        return (
            <th key={column.index}>
                {column.name}
                {filter}
            </th>
        );
    }
}

@propTypes({
    table: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired
})

export class TableFooter extends Component {
    render() {
        return (
            <tfoot>
            <tr>
                <th></th>
                {this.props.columns.map(column => (
                    <th key={column.index}>
                        {(_.isUndefined(column.filterable) || column.filterable) ?
                            <ColumnSearchText column={column} onChange={::this.onColumnSearchTextChange}/> : null}
                    </th>
                ))}
            </tr>
            </tfoot>
        );
    }

    onColumnSearchTextChange(column, value) {
        this.props.table.column(column.index + 1).search(value).draw();
    }
}

@propTypes({
    column: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
})

export class ColumnSearchText extends Component {
    debounce = debounce(300, ::this.submitChanges);

    render() {
        return (
            <input ref="input" type="text" className="ColumnSearchText" onChange={::this.onChange}
                   placeholder={this.props.column.name}/>
        );
    }

    onChange() {
        this.debounce();
    }

    submitChanges() {
        const value = this.refs.input.value;
        this.props.onChange(this.props.column, value);
    }
}


@propTypes({
    rows: PropTypes.array.isRequired,
    columns: PropTypes.object.isRequired
})

export class TableBody extends Component {
    render() {
        return (
            <tbody>
            {this.props.rows.map(::this.renderRow)}
            </tbody>
        );
    }

    renderRow(item, i) {
        return (
            <tr key={i}>
                <td width={16}/>
                {this.props.columns.map(column => (
                    <td>{item[column.key]}</td>
                ))}
            </tr>
        )
    }
}