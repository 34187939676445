import React from 'react';
import classNames from 'classnames';
export const AnalyticsLegend = ({ faqOpened, onToggleFaq, isLegendShowBlue }) => {
	return (
		<div
			className={classNames('faq', {
				expand: faqOpened
			})}
		>
			<div
				className={classNames('faq__btn', {
					faq__btn_opened: faqOpened
				})}
				onClick={onToggleFaq}
			/>
			<div className="faq__inner big">
				<div className="faq__title">Расшифровка обозначений на графике</div>
				<div className="faq__content">
					<div className="faq__row">
						Работа выполнена
						<div className="indicators indicators_green2" />
					</div>
					<div className="faq__row">
						Выполнено частично
						<div className="indicators indicators_yellow" />
					</div>
					<div className="faq__row">
						Работа не выполнена
						<div className="indicators indicators_red2" />
					</div>
					<div className="faq__row">
						Работа запланирована/выполняется
						<div className="indicators indicators_gray2" />
					</div>
					{isLegendShowBlue && (
						<div className="faq__row">
							Объекты по подрядам
							<div className="indicators indicators_blue" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
