import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import WidgetSelector from 'components/modules/analytics/base-components/WidgetSelector';
import BarChart from 'components/modules/analytics/base-components/charts/BarChart';
import Datepicker from "components/ui/form/datepicker";
import moment from 'moment';
import _ from 'lodash';
import formats from 'dictionaries/formats';
import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import GlobalLoaderComponent from "components/ui/global-loader";
import './style.less';

@propTypes({
    barChartFillColor: PropTypes.string
})

@defaultProps({
    barChartFillColor: ''
})

export default class SpeedViolationWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            from: moment().subtract(1, 'day').format(formats.DATE_API),
            to: moment().subtract(1, 'day').format(formats.DATE_API),
            data: {
                organizationUnits: [],
                unitsByDate: []
            }
        };
    }

    componentWillMount() {
        this.loadData();
    }

    getUnits() {
        return api.organizational_units.getUnits({
            filters: {
                withComponent: 'component="taxi"',
            },
        });
    }

    async loadData() {
        this.setState({isError: false, isLoading: true});

        try {
            const response = await this.getUnits();

            const data = {
                organizationUnits: _.map(response.payload.items, (item) => _.pick(item, ['uuid', 'name'])),
                unitsByDate: [
                    {
                        date: '01.01.20017', violationsCount: 30, tp1: 10, tp2: 20,
                        config: {
                            tp1: {
                                name: 'ТП 1',
                                fill: '#35aa80'
                            },
                            tp2: {
                                name: 'ТП 2',
                                fill: '#537baa'
                            }
                        }
                    },
                    {
                        date: '02.01.20017', violationsCount: 60, tp1: 10, tp2: 30, tp3: 20,
                        config: {
                            tp1: {
                                name: 'ТП 1',
                                fill: '#35aa80'
                            },
                            tp2: {
                                name: 'ТП 2',
                                fill: '#537baa'
                            },
                            tp3: {
                                name: 'ТП 3',
                                fill: '#aa5a53'
                            }
                        }
                    }
                ]
            };

            this.setState({data, isError: false, isLoading: false});
        }
        catch (e) {
            this.setState({isError: true, isLoading: false});
            error(e).showErrors();
        }
    }

    fromChange = ({target: {value}}) => {
        this.setState({from: value});
    };

    toChange = ({target: {value}}) => {
        this.setState({to: value});
    };

    renderContent() {
        return (
            <div className="full-size widget-block_speed-violation">
                <div className="tools-wrapper">
                    <div className="tools-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange}/>
                    </div>
                    <div className="tools-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange}/>
                    </div>
                    <div className="tools-item select-wrapper">
                        <WidgetSelector title="Отделы" items={this.state.data.organizationUnits}/>
                    </div>
                </div>
                <div className="chart-wrapper">
                    <BarChart data={this.state.data.unitsByDate}
                              xDataKey="date"
                              yDataKey="violationsCount"
                              configKey="config"
                              color={this.props.barChartFillColor}
                    />
                </div>
            </div>
        );
    }

    render() {
        const content = this.state.isError ? null : this.state.isLoading ? <GlobalLoaderComponent/> : this.renderContent();

        return (
            <div className="speed-violation-widget">
                {content}
            </div>
        );
    }
}