import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import moment from "moment";
import formats from "dictionaries/formats";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import _ from 'lodash';
import {Link} from "react-router";
import {deleteContractWork, getContractWorks} from "store/reducers/kurs/contract_works";
import {saveWorkGroup, getWorkGroups} from "store/reducers/kurs/work_groups";
import TabBlock from "components/ui/tabs/tab-block";
import Block from "components/ui/form/block";
import Input from "components/ui/form/input";
import Button from "components/ui/button";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getUserGeoObjects} from "store/reducers/user-map-objects/object_editor";

@propTypes({
    contractUuid: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
})

@connect(state => ({}), {getContractWorks, deleteContractWork, getEntityNames, saveWorkGroup, getWorkGroups, getUserGeoObjects})

export default class KursContractWorksComponent extends BaseTableWithEditorComponent {
    select = 'multiple';
    checkbox = true;
    defaultOrder = [[1, 'asc']];

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            showEditorModal: false,
            editorUuid: null,
            groupName: '',
            visibleCheckbox: false,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.delegation_uuid !== prevProps.delegation_uuid) {
            this.toggleVisibleCheckbox(true);
        }
    }

    componentDidMount() {
        if (this.props.delegation_uuid) {
            this.toggleVisibleCheckbox(true);
        }
    }

    getTitle() {
        return <span>{systems.road} → <Link
            to={`/road/contracts/${this.props.contractUuid}`}>Ведение {window.RNIS_SETTINGS.rename_contracts ? 'подрядных обязательств' : 'контрактов'}</Link> → {this.isRepair() ? `Работы по ${window.RNIS_SETTINGS.rename_contracts ? 'подрядному обязательству' : 'контракту'}` : 'Объекты обслуживания'}</span>;
    }

    getBaseUrl() {
        return `/road/contracts/${this.props.contractUuid}/${this.props.type}`;
    }

    getEditor() {
        return (
            <Editor
                {...this.props}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    isRepair() {
        return this.props.type === 'works';
    }

    onInitialized() {
        const selected = [];

        const tableWrapped = this.refs.table.getWrappedInstance().table
    
        tableWrapped.rows().data().map((contract_work, index) => {
            if (this.props.selectedContracts.find((sc) => sc.uuid === contract_work.uuid)) {
                selected.push(index);
            }
        })

        setTimeout(() => {
            selected.map((index) => {
                tableWrapped.row(index).select()
            })
        })
    }

    getColumns() {
        if (this.isRepair()) {
            return this.prepareColumns([

                new Column('Участок дороги')
                    .fromField('road_uuid')
                    .denyOrder()
                    .denyColumnFilter()
                    .withClassName('link')
                    .withDrawer(item => item.road_uuid && this.state.related.get(item.road_uuid)),

                new Column('Виды работ')
                    .fromField('work_type_uuid')
                    .denyColumnFilter()
                    .denyOrder()
                    .withDrawer(item => item.works.map(work => work.work_type_uuid && this.state.related.get(work.work_type_uuid)).join('<br/>')),

                new Column('Ед. изм.')
                    .fromField('measure_uuid')
                    .denyColumnFilter()
                    .denyOrder()
                    .withDrawer(item => item.works.map(work => work.measure_uuid && this.state.related.get(work.measure_uuid)).join('<br/>')),

                new Column('Об. работ')
                    .fromField('count')
                    .denyColumnFilter()
                    .denyOrder()
                    .withDrawer(item => item.volume.join('<br/>')),

                new Column('Дата начала')
                    .fromField('date_from')
                    .denyColumnFilter()
                    .denyOrder()
                    .withDrawer(item => item.date_from && moment(item.date_from).format(formats.DATE)),

                new Column('Дата окончания')
                    .fromField('date_to')
                    .denyColumnFilter()
                    .denyOrder()
                    .withDrawer(item => item.date_to && moment(item.date_to).format(formats.DATE)),

                new Column('Нач. км')
                    .fromField('start_at'),

                new Column('Конеч. км')
                    .fromField('end_at'),

                new Column('Прот. участка')
                    .fromField('length'),

                new Column('Субподрядчик')
                    .fromField('unit_uuid')
                    .denyColumnFilter()
                    .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid)),

            ]);
        } else {
            return this.prepareColumns([
                ...(window.RNIS_SETTINGS.CITY_MURMANSK ? [
                    new Column('')
                        .fromField('created_at')
                        .denyOrder()
                        .denyColumnFilter()
                        .withDrawer(() => ''),
                ] : []),
                new Column('Объект')
                    .fromField('road_uuid')
                    .denyOrder()
                    .denyColumnFilter()
                    .withClassName('link')
                    .withDrawer(item => item.road_uuid && this.state.related.get(item.road_uuid)),

                new Column('Группа по содержанию')
                    .fromField('maintenance_group_uuid')
                    .denyColumnFilter()
                    .withDrawer(item => item.maintenance_group_uuid && this.state.related.get(item.maintenance_group_uuid)),

                ...(window.RNIS_SETTINGS.CONTRACT_GROUP ? [
                    new Column('Группы объектов')
                        .fromField('road_uuid')
                        .denyOrder()
                        .denyColumnFilter()
                        .withDrawer(item => item.work_groups.map((work_group) => work_group.name).join(', ')),
                ] : []),

                ...(!window.RNIS_SETTINGS.CITY_MURMANSK ? [
                    new Column('Нач. км')
                        .fromField('start_at'),

                    new Column('Конеч. км')
                        .fromField('end_at'),
                ] : []),

                new Column('Прот. участка')
                    .fromField('length'),

                ...(window.RNIS_SETTINGS.different_object_types_in_contract ? [new Column('Объем работ')
                    .fromField('volume')
                    .denyColumnFilter()
                    .withDrawer(item => item.volume[0] && this.state.related.get(item.volume[0]))

                ] : []),
                
                new Column('Субподрядчик')
                    .fromField('unit_uuid')
                    .denyColumnFilter()
                    .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid)),
            ]);
        }
    }

    async deleteItem(data) {
        return await this.props.deleteContractWork(data);
    }

    async loadData(meta) {
        meta.filters = {
            withContract: this.props.contractUuid,
        };

        let response = await this.props.getContractWorks(meta);
        if (response.isOk) {

            response.payload.items = _.map(response.payload.items, (item) => {
                const works = item.works || [];

                item.work_type_uuid = _.map(works, 'work_type_uuid');
                item.measure_uuid = _.map(works, 'measure_uuid');
                item.volume = _.map(works, 'volume');

                return item;
            });
        }
        return response;
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        let roads = [];
        if (this.isRepair()) {
            roads = _.map(_.filter(_.map(result, 'road_uuid')), (uuid) => ({
                class: 'App\\Dictionaries\\Kurs\\KursRoadRepairParts\\Model',
                uuid: uuid,
                source: 'dictionary',
            }));
        } else {
            roads = _.map(_.filter(_.map(result, 'road_uuid')), (uuid) => ({
                class: 'App\\Model\\RoadPart',
                uuid: uuid,
                source: 'kurs',
            }));
        }

        const workTypes = _.map(_.filter(_.map(_.flatten(_.map(result, 'works')), 'work_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\WorkTypes\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const measures = _.map(_.filter(_.map(_.flatten(_.map(result, 'works')), 'measure_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\Measures\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const units = _.map(_.filter(_.map(result, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const maintenanceGroups = _.map(_.filter(_.map(result, 'maintenance_group_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\MaintenanceGroups\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const geoObjects = _.map(_.filter(_.map(result, 'road_uuid')), (uuid) => ({
            class: 'App\\Model\\UserGeoObject',
            uuid: uuid,
            source: 'geo',
        }));
       
        const response = await this.props.getEntityNames(_.concat(roads, workTypes, measures, units, maintenanceGroups, geoObjects));

        if (response.isOk) {
            this.state.related.add(response);
         
            drawCallback(json);
        }
    }

    async showEditorWithUuid(uuid = null) {
        this.setState({
            showEditorModal: true,
            editorUuid: uuid,
        });
    }

    async closeEditor() {
        this.setState({
            showEditorModal: false,
            editorUuid: null,
        });
    }

    onChaneGroupName(e) {
        this.setState({
            groupName: e.target.value,
        })
    }

    async saveGroup() {
        const data = {
            name: this.state.groupName,
            contract_uuid: this.props.contractUuid,
            contract_works: this.state.selectedRows,
        }

        let response = await this.props.saveWorkGroup(data);
        if (response.isOk) {
            this.toggleVisibleCheckbox();
            this.setState({
                groupName: '',
            })
            this.reload();
        }
    }

    selectedRowsRecalc() {
        super.selectedRowsRecalc();

        if (this.props.delegation_uuid) {
            this.props.selectContract(this.state.selectedRows);
        }

    }

    createGroup() {
        this.refs.table.getWrappedInstance().getSelected().deselect()
        this.toggleVisibleCheckbox()
    }

    renderGroupForm() {
        return (
            <div>
                <Block size="md" title="Введите название группы">
                    <Input
                        value={this.state.groupName}
                        onChange={::this.onChaneGroupName}
                        error={(this.state.error || {}).groupName}
                    />
                </Block>
                <Block size="md">
                    <Button text="Сохранить" width="full" size="md"
                        disabled={!this.state.visibleCheckbox || !this.state.selectedRows.length || !this.state.groupName}
                        shadow="red"
                        onClick={::this.saveGroup} />
                </Block>
                <Block size="md">
                    <Button text="Создать группу объектов" width="auto" size="md"
                        shadow="red"
                        onClick={::this.createGroup} />
                </Block>
            </div>
        )
    }

    render() {
        let table = this.renderTable();

        const editor = this.state.showEditorModal ? this.getEditor() : '';

        return (
            <TabBlock>
                {window.RNIS_SETTINGS.CONTRACT_GROUP && !this.props.delegation_uuid ? this.renderGroupForm() : null}
                <div className="b-block _full">
                    <TableContainer>
                        {table}
                        {!this.props.delegation_uuid ? <span className="add-line" onClick={::this.showEditor}>Добавить запись</span> : null}
                    </TableContainer>
                </div>
                {editor}
            </TabBlock>
        );
    }
}