import _ from 'lodash';
import * as alerts from './alerts';
import {api} from "helpers/api";
import systems from "dictionaries/systems";

export function showError(error, title = 'Ошибка') {
    console.error('Error:', error);
    if (checkTokenRefreshError(error.errors)) return;
    if (!hasNotValidationErrors(error.errors)) return;
    if (!hasNotAccessErrors(error.errors)) {
        showAccessErrors(error.errors);
        return;
    }

    const html = formatError(error.errors);
    alerts.error(html, title);
}

function checkTokenRefreshError(errors) {
    if (hasError(errors, '1-0-0-16')) {
        api.session.logout();

        setTimeout(() => {
            window.location.href = '/auth';
        }, 1000);

        return true;
    }

    return false;
}

function hasError(errors, code) {
    return _.size(_.filter(errors, {code})) > 0;
}

async function showAccessErrors(errors) {
    let permissions;
    try {
        permissions = (await api.auth.getPermissions()).payload.items;
    } catch (e) {
        permissions = [];
    }
    const grants = {
        create: 'создание',
        read: 'чтение',
        update: 'редактирование',
        delete: 'удаление',
    };
    const html = _.join(_.map(errors, (error) => {
        return `[<strong>${error.code}</strong>] ${error.text}:<br/><br/>` +
            _.join(_.map(error.data, (grant, code) => {
                if (code === 'component') {
                    return `Подсистема - ${systems[grant]}`;
                }
                const permission = _.find(permissions, {code}) || {name: code};
                return `${permission.name} - ${grants[grant]}`;
            }), '<br/>');
    }), '<br/>');

    alerts.error(html);
}

function formatError(errors) {
    return _.join(_.map(errors, error => `[<strong>${error.code}</strong>] ${error.text}`), '<br/>');
}

function hasNotValidationErrors(errors) {
    return _.filter(errors, error => error.code !== '1-0-0-3').length > 0;
}

function hasNotAccessErrors(errors) {
    return _.filter(errors, error => error.code !== '1-0-0-4').length > 0;
}