import _ from 'lodash';
import moment from "moment";
import formats from "dictionaries/formats";
import {User} from "helpers/user";

const months = {
    0: 'января',
    1: 'февраля',
    2: 'марта',
    3: 'апреля',
    4: 'мая',
    5: 'июня',
    6: 'июля',
    7: 'августа',
    8: 'сентября',
    9: 'октября',
    10: 'ноября',
    11: 'декабря',
};

export function printWaybillNum6({waybill, unit, vehicle, vehicle_marks, driver, trailer}) {
    if (!waybill || !unit || !vehicle) {
        return null;
    }

    const date = moment(waybill.date);

    const departure = moment(moment(waybill.departure_date).format(formats.DATE) + ' ' + waybill.departure_time, formats.DATETIME_SHORT);
    const arrival = moment(moment(waybill.arrival_date).format(formats.DATE) + ' ' + waybill.arrival_time, formats.DATETIME_SHORT);

    return `
        <link rel="stylesheet" href="/css/print-form.css">
        <link rel="stylesheet" href="/css/printA4-landscape.css">
        <div class="container print">
          <div class="row justify-content-between">
            <div class="col-custom col-custom-8">
              <div class="row justify-content-between">
                <div class="col-auto tac">Место для <br>штампа <br>организации</div>
                <div class="col-5 bold tac">Путевой лист<br>
                  <table>
                    <tr>
                      <td class="bn w-200 tar">автобуса необщего пользования</td>
                      <td class="bn bb w-50 sign sign_serie"></td>
                      <td class="bn w-25">№</td>
                      <td class="bn bb w-50">${waybill.number || ''}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-auto tac">Типовая межотраслевая форма №6 (спец)<br>Утверждена постановлением Госкомстата России<br>от 28.11.97 № 78<br></div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn"></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn">«${date.format('D') || ''}» ${months[date.format('M') - 1] || ''} ${date.format('YYYY') || ''}г.</td>
                      <td class="bn w-150">Коды</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn"></td>
                      <td class="bn"></td>
                      <td class="bn w-100 tar">Форма по ОКУД</td>
                      <td class="w-150 btx2 brx2 blx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn bold w-100 tal">Организация</td>
                      <td class="bn bb sign sign_info">${unit.name_full || ''}</td>
                      <td class="bn w-100 tar">по ОКПО </td>
                      <td class="w-150 brx2 blx2 btx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn" colspan="2"></td>
                      <td class="bn w-100 tar">Режим работы</td>
                      <td class="bn w-150 btx2 brx2 blx2"></td>
                    </tr>
                    <tr>
                      <td class="bn" colspan="2"></td>
                      <td class="bn w-100 tar">Колонна</td>
                      <td class="bn w-150 btx2 brx2 blx2"></td>
                    </tr>
                    <tr>
                      <td class="bn" colspan="2"></td>
                      <td class="bn w-100 tar">Бригада</td>
                      <td class="bn w-150 btx2 brx2 blx2"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bold w-100 tal">Марка автобуса</td>
                      <td class="bn bb">${_.get(_.find(vehicle_marks, {value: vehicle.vehicle_mark_uuid}), 'label') || ''}</td>
                      <td class="bn w-100 tar"></td>
                      <td class="bn w-150 btx2 brx2 blx2">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-200 tal bold">Государственный номерной знак</td>
                      <td class="bn bb">${vehicle.state_number || ''}</td>
                      <td class="bn w-100 tar">Гаражный номер</td>
                      <td class="bn w-150 btx2 brx2 blx2">${_.get(vehicle, 'garage_number') || ''}</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-100 tal bold">Водитель</td>
                      <td class="bn bb sign sign_mashinist">${driver ? (new User(driver)).getFullName() : ''}</td>
                      <td class="bn w-100 tar">Табельный номер</td>
                      <td class="w-150 btx2 brx2 blx2 bbx2">${_.get(driver, 'info.personnel_number') || ''}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <table>
                    <tr>
                      <td class="bn w-100 tal">Удостоверение №</td>
                      <td class="bn bb">${driver ? (new User(driver)).getDriverNumber()  : ''}</td>
                      <td class="bn w-25"></td>
                      <td class="bn w-100 tar">Класс</td>
                      <td class="bn bb"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-125 tal">Лицензионная карточка</td>
                      <td class="bn bb sign sign_cross w-200">стандартная, ограниченная</td>
                      <td class="bn" colspan="3"></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn">&nbsp;</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bax2 bold">Работа водителя и автобуса</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn"></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <table>
                    <tr>
                      <td class="btx2 blx2">операция</td>
                      <td class="btx2">время по графику ч, мин.</td>
                      <td class="btx2">нулевой пробег,км</td>
                      <td class="btx2">показание спидометра</td>
                      <td class="btx2 brx2">время фактическое, число, месяц, ч, мин.</td>
                    </tr>
                    <tr>
                      <td class="blx2">1</td>
                      <td class="bbx2">2</td>
                      <td class="bbx2">3</td>
                      <td class="bbx2">4</td>
                      <td class="bbx2 brx2">5</td>
                    </tr>
                    <tr>
                      <td class="blx2">выезд из гаража</td>
                      <td class="blx2">${departure.format('HH:mm') || ''}</td>
                      <td></td>
                      <td>${waybill.fact_departure_odometer || ''}</td>
                      <td class="brx2"></td>
                    </tr>
                    <tr>
                      <td class="bbx2 blx2">возвращение в гараж</td>
                      <td class="bbx2 blx2">${arrival.format('HH:mm') || ''}</td>
                      <td class="bbx2"></td>
                      <td class="bbx2">${waybill.fact_arrival_odometer || ''}</td>
                      <td class="bbx2 brx2">${waybill.fact_arrival_time || ''}</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="btx2 blx2 brx2" colspan="7">Движение горючего</td>
                    </tr>
                    <tr>
                      <td class="blx2" colspan="2">горючее</td>
                      <td rowspan="2">выдано, л</td>
                      <td colspan="2">остаток при</td>
                      <td rowspan="2">сдано, л</td>
                      <td class="brx2" rowspan="2">коэффициент изменения нормы</td>
                    </tr>
                    <tr>
                      <td class="blx2">марка</td>
                      <td>код марки</td>
                      <td>выезде, л</td>
                      <td>возврате,л</td>
                    </tr>
                    <tr>
                      <td class="blx2">6</td>
                      <td>7</td>
                      <td>8</td>
                      <td>9</td>
                      <td>10</td>
                      <td>11</td>
                      <td class="brx2">12</td>
                    </tr>
                    <tr>
                      <td class="blx2">&nbsp;</td>
                      <td class="btx2 blx2">&nbsp;</td>
                      <td class="btx2">${_.isNumber(waybill.fuel_issued) ? waybill.fuel_issued : ''}</td>
                      <td class="btx2">${_.isNumber(waybill.departure_fuel_level) ? waybill.departure_fuel_level : ''}</td>
                      <td class="btx2">${_.isNumber(waybill.arrival_fuel_level) ? waybill.arrival_fuel_level : ''}</td>
                      <td class="btx2">&nbsp;</td>
                      <td class="btx2 brx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bbx2 blx2">&nbsp;</td>
                      <td class="bbx2 blx2">&nbsp;</td>
                      <td class="bbx2">&nbsp;</td>
                      <td class="bbx2">&nbsp;</td>
                      <td class="bbx2">&nbsp;</td>
                      <td class="bbx2">&nbsp;</td>
                      <td class="bbx2 brx2">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="ttu" rowspan="2">Подпись</td>
                      <td>заправщика</td>
                      <td>механика</td>
                      <td>механика</td>
                      <td>заправщика</td>
                      <td>диспетчера</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td rowspan="2">Серия и номера выданных талонов на горючее</td>
                      <td colspan="4"></td>
                      <td rowspan="2">Выдано по заправочному листу №</td>
                    </tr>
                    <tr>
                      <td colspan="4"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn tal">Водительское удостоверение проверил, задание выдал</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-100 tal">выдать горючего</td>
                      <td class="bn bb sign sign_words"></td>
                      <td class="bn w-50">литров</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bold w-100 tal">Диспетчер</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn tal">Водитель по состоянию здоровья к управлению автобусом</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-100 tal">допущен</td>
                      <td class="bn bb sign sign_post"></td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_signature"></td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn tal">Место для штампа</td>
                    </tr>
                  </table>
                </div>
                <div class="col-6">
                  <table>
                    <tr>
                      <td class="btx2 blx2 brx2" colspan="5">Задание водителю</td>
                    </tr>
                    <tr>
                      <td class="blx2 w-6" rowspan="2">в чье распоряжение (наименование и адрес заказчика)</td>
                      <td colspan="2">время, ч, мин.</td>
                      <td class="brx2" colspan="2">количество</td>
                    </tr>
                    <tr>
                      <td>прибытия</td>
                      <td>убытия</td>
                      <td>часов</td>
                      <td class="brx2">ездок</td>
                    </tr>
                    <tr>
                      <td class="btx2 bbx2 blx2">13</td>
                      <td class="btx2 bbx2">14</td>
                      <td class="btx2 bbx2">15</td>
                      <td class="btx2 bbx2">16</td>
                      <td class="btx2 bbx2 brx2">17</td>
                    </tr>
                    <tr>
                      <td class="blx2">&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td class="brx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="blx2">&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td class="brx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="blx2">&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td class="brx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="blx2">&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td class="brx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bbx2 blx2">&nbsp;</td>
                      <td class="bbx2">&nbsp;</td>
                      <td class="bbx2">&nbsp;</td>
                      <td class="bbx2">&nbsp;</td>
                      <td class="bbx2 brx2">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn tal w-100">Особые отметки</td>
                      <td class="bn bb"></td>
                    </tr>
                    <tr>
                      <td class="bn bb" colspan="2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn bb" colspan="2">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn tal">Автобус технически исправен. Выезд разрешен:</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bold w-100 tal">Механик</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bold w-100 tal">Водитель</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bold w-150 tal">При возвращении автобус</td>
                      <td class="bn w-100">
                        <div class="flex flex_column"><span class="bb">исправен</span><span>не исправен</span></div>
                      </td>
                      <td class="bn"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bold w-100 tal">Сдал водитель</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bold w-100 tal">Принял механик</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-custom col-custom-1 bld brd">
              <div class="flex flex_column justify-content-around h100-100">
                <div class="flex flex_column"><span class="tac ttu fs-14">л</span><span class="tac ttu fs-14">и</span><span class="tac ttu fs-14">н</span><span class="tac ttu fs-14">и</span><span class="tac ttu fs-14">я</span></div>
                <div class="flex flex_column"><span class="tac ttu fs-14">о</span><span class="tac ttu fs-14">т</span><span class="tac ttu fs-14">р</span><span class="tac ttu fs-14">е</span><span class="tac ttu fs-14">з</span><span class="tac ttu fs-14">а</span></div>
              </div>
            </div>
            <div class="col-custom col-custom-3">
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn ttu bold">талон заказчика</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-100 tal">к путевому листу</td>
                      <td class="bn bb sign sign_serie"></td>
                      <td class="bn w-25">№</td>
                      <td class="bn bb">${waybill.number || ''}</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn tal">от «${date.format('D') || ''}» ${months[date.format('M') - 1] || ''} ${date.format('YYYY') || ''}г.</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-75 tal">Организация</td>
                      <td class="bn bb sign sign_name">${unit.name_full || ''}</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bb sign sign_info_sm"> &nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-75 tal">Автобус</td>
                      <td class="bn bb sign sign_type-mark">${_.get(_.find(vehicle_marks, {value: vehicle.vehicle_mark_uuid}), 'label') || ''}</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn tal">Государственный номерной знак</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bb tal">${vehicle.state_number || ''}</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-75 tal">Заказчик</td>
                      <td class="bn bb sign sign_name">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bb sign sign_info_sm"> &nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bb sign sign_personCharge"> &nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="btx2 blx2">Операция</td>
                      <td class="btx2">Время, ч,мин.</td>
                      <td class="btx2 brx2">Показание спидометра,км</td>
                    </tr>
                    <tr>
                      <td class="btx2 bbx2 blx2">18</td>
                      <td class="btx2 bbx2">19</td>
                      <td class="btx2 bbx2 brx2">20</td>
                    </tr>
                    <tr>
                      <td class="blx2">прибытие</td>
                      <td></td>
                      <td class="brx2"></td>
                    </tr>
                    <tr>
                      <td class="bbx2 blx2">убытие</td>
                      <td class="bbx2"></td>
                      <td class="bbx2 brx2"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bold w-75 tal">Заказчик</td>
                      <td class="bn bb sign sign_post">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-75">
                         М.П. <br>или штампа</td>
                      <td class="bn bb sign sign_transcript"> &nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn">Заполняется организацией</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="btx2 blx2 w-3">Расчет стоимости</td>
                      <td class="btx2 w-3">Время оплачиваемое, ч, мин</td>
                      <td class="btx2 w-3">Пробег всего, км</td>
                      <td class="btx2 brx2 w-3">Всего к оплате руб.коп.</td>
                    </tr>
                    <tr>
                      <td class="btx2 bbx2 blx2">21</td>
                      <td class="btx2 bbx2">22</td>
                      <td class="btx2 bbx2">23</td>
                      <td class="btx2 bbx2 brx2">24</td>
                    </tr>
                    <tr>
                      <td class="blx2">Выполнено</td>
                      <td></td>
                      <td></td>
                      <td class="brx2">X</td>
                    </tr>
                    <tr>
                      <td class="blx2">Тариф,руб. коп.</td>
                      <td></td>
                      <td></td>
                      <td class="brx2">X</td>
                    </tr>
                    <tr>
                      <td class="bbx2 blx2">К оплате,руб. коп.</td>
                      <td class="bbx2"></td>
                      <td class="bbx2"></td>
                      <td class="bbx2 brx2"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bold w-75 tal">Таксировщик</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-custom col-custom-3">
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn ttu bold">талон заказчика</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-100 tal">к путевому листу</td>
                      <td class="bn bb sign sign_serie"></td>
                      <td class="bn w-25">№</td>
                      <td class="bn bb">${waybill.number || ''}</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn tal">от «${date.format('D') || ''}» ${months[date.format('M') - 1] || ''} ${date.format('YYYY') || ''}г.</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-75 tal">Организация</td>
                      <td class="bn bb sign sign_name">${unit.name_full || ''}</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bb sign sign_info_sm"> &nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-75 tal">Автобус</td>
                      <td class="bn bb sign sign_type-mark">${_.get(_.find(vehicle_marks, {value: vehicle.vehicle_mark_uuid}), 'label') || ''}</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn tal">Государственный номерной знак</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bb tal">${vehicle.state_number || ''}</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-75 tal">Заказчик</td>
                      <td class="bn bb sign sign_name">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bb sign sign_info_sm"> &nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bb sign sign_personCharge"> &nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="btx2 blx2">Операция</td>
                      <td class="btx2">Время, ч,мин.</td>
                      <td class="btx2 brx2">Показание спидометра,км</td>
                    </tr>
                    <tr>
                      <td class="btx2 bbx2 blx2">18</td>
                      <td class="btx2 bbx2">19</td>
                      <td class="btx2 bbx2 brx2">20</td>
                    </tr>
                    <tr>
                      <td class="blx2">прибытие</td>
                      <td></td>
                      <td class="brx2"></td>
                    </tr>
                    <tr>
                      <td class="bbx2 blx2">убытие</td>
                      <td class="bbx2"></td>
                      <td class="bbx2 brx2"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bold w-75 tal">Заказчик</td>
                      <td class="bn bb sign sign_post">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-75">
                         М.П. <br>или штампа</td>
                      <td class="bn bb sign sign_transcript"> &nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn">Заполняется организацией</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="btx2 blx2 w-3">Расчет стоимости</td>
                      <td class="btx2 w-3">Время оплачиваемое, ч, мин</td>
                      <td class="btx2 w-3">Пробег всего, км</td>
                      <td class="btx2 brx2 w-3">Всего к оплате руб.коп.</td>
                    </tr>
                    <tr>
                      <td class="btx2 bbx2 blx2">21</td>
                      <td class="btx2 bbx2">22</td>
                      <td class="btx2 bbx2">23</td>
                      <td class="btx2 bbx2 brx2">24</td>
                    </tr>
                    <tr>
                      <td class="blx2">Выполнено</td>
                      <td></td>
                      <td></td>
                      <td class="brx2">X</td>
                    </tr>
                    <tr>
                      <td class="blx2">Тариф,руб. коп.</td>
                      <td></td>
                      <td></td>
                      <td class="brx2">X</td>
                    </tr>
                    <tr>
                      <td class="bbx2 blx2">К оплате,руб. коп.</td>
                      <td class="bbx2"></td>
                      <td class="bbx2"></td>
                      <td class="bbx2 brx2"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn bold w-75 tal">Таксировщик</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-custom col-custom-1 bld brd">
              <div class="flex flex_column justify-content-around h100-100">
                <div class="flex flex_column"><span class="tac ttu fs-14">л</span><span class="tac ttu fs-14">и</span><span class="tac ttu fs-14">н</span><span class="tac ttu fs-14">и</span><span class="tac ttu fs-14">я</span></div>
                <div class="flex flex_column"><span class="tac ttu fs-14">о</span><span class="tac ttu fs-14">т</span><span class="tac ttu fs-14">р</span><span class="tac ttu fs-14">е</span><span class="tac ttu fs-14">з</span><span class="tac ttu fs-14">а</span></div>
              </div>
            </div>
            <div class="col-custom col-custom-8">
              <div class="row justify-content-between">
                <div class="col-auto"></div>
                <div class="col-auto">Оборотная сторона формы №6 (спец)</div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn"></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="btx2 blx2 brx2" colspan="5">Маршрут движения (заполняется заказчиком)</td>
                    </tr>
                    <tr>
                      <td class="blx2 w-2" rowspan="2">номер ездки</td>
                      <td class="w-2" rowspan="2">откуда</td>
                      <td class="w-2" rowspan="2">куда</td>
                      <td class="brx2" colspan="2">пробег, км</td>
                    </tr>
                    <tr>
                      <td>с пассажирами</td>
                      <td class="brx2">без пассажиров</td>
                    </tr>
                    <tr>
                      <td class="btx2 bbx2 blx2">25</td>
                      <td class="btx2 bbx2">26</td>
                      <td class="btx2 bbx2">27</td>
                      <td class="btx2 bbx2">28</td>
                      <td class="btx2 bbx2 brx2">29</td>
                    </tr>
                    <tr>
                      <td class="blx2">&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td class="brx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="blx2">&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td class="brx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bbx2 blx2">&nbsp;</td>
                      <td class="bbx2">&nbsp;</td>
                      <td class="bbx2">&nbsp;</td>
                      <td class="bbx2">&nbsp;</td>
                      <td class="bbx2 brx2">&nbsp;</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-9">
                  <table>
                    <tr>
                      <td class="bn"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn w-50 tac">М.П. <br>или штампа</td>
                      <td class="bn w-100 tac">Заказчик</td>
                      <td class="bn bb sign sign_post"></td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_signature"></td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript"></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="btx2 blx2" colspan="4">Простои на линии</td>
                      <td class="btx2 brx2" rowspan="3">Подпись ответственного работника</td>
                    </tr>
                    <tr>
                      <td class="blx2" rowspan="2">Наименование</td>
                      <td rowspan="2">Код</td>
                      <td colspan="2">время, ч, мин.</td>
                    </tr>
                    <tr>
                      <td>начало</td>
                      <td>окончание</td>
                    </tr>
                    <tr>
                      <td class="btx2 bbx2 blx2">30</td>
                      <td class="btx2 bbx2">31</td>
                      <td class="btx2 bbx2">32</td>
                      <td class="btx2 bbx2">33</td>
                      <td class="btx2 bbx2 brx2">34</td>
                    </tr>
                    <tr>
                      <td class="blx2">&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td class="brx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bbx2 blx2">&nbsp;</td>
                      <td class="bbx2">&nbsp;</td>
                      <td class="bbx2">&nbsp;</td>
                      <td class="bbx2">&nbsp;</td>
                      <td class="bbx2 brx2">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-9">
                  <table>
                    <tr>
                      <td class="btx2 blx2 brx2" colspan="6">Результат работы автобуса</td>
                    </tr>
                    <tr>
                      <td class="blx2" colspan="2">расход горючего</td>
                      <td colspan="2">время в наряде, ч, мин.</td>
                      <td class="brx2" colspan="2">пробег, км</td>
                    </tr>
                    <tr>
                      <td class="blx2">по норме</td>
                      <td>фактически</td>
                      <td>всего</td>
                      <td>в том числе простои по техническим неисправностям</td>
                      <td>всего</td>
                      <td class="brx2">в том числе с пассажирами</td>
                    </tr>
                    <tr>
                      <td class="btx2 bbx2 blx2">35</td>
                      <td class="btx2 bbx2">36 </td>
                      <td class="btx2 bbx2">37</td>
                      <td class="btx2 bbx2">38</td>
                      <td class="btx2 bbx2">39</td>
                      <td class="btx2 bbx2 brx2">40</td>
                    </tr>
                    <tr>
                      <td class="btx2 bbx2 blx2">&nbsp;</td>
                      <td class="btx2 bbx2">&nbsp;</td>
                      <td class="btx2 bbx2">&nbsp;</td>
                      <td class="btx2 bbx2">&nbsp;</td>
                      <td class="btx2 bbx2">&nbsp;</td>
                      <td class="btx2 bbx2 brx2">&nbsp;</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td class="bn h-10"></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn bb tal">Таксировка:</td>
                      <td class="btx2 blx2 w-50"></td>
                      <td class="btx2 brx2 tal w-100">Зарплата</td>
                    </tr>
                    <tr>
                      <td class="bn bb tal"></td>
                      <td class="blx2 w-50">код</td>
                      <td class="brx2 tal w-100">сумма, руб. коп.</td>
                    </tr>
                    <tr>
                      <td class="bn bb tal"></td>
                      <td class="bbx2 btx2 blx2 w-50">41</td>
                      <td class="bbx2 btx2 brx2 w-100">42</td>
                    </tr>
                    <tr>
                      <td class="bn bb tal">&nbsp;</td>
                      <td class="blx2 w-50">&nbsp;</td>
                      <td class="brx2 w-100">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn bb tal">&nbsp;</td>
                      <td class="blx2 w-50">&nbsp;</td>
                      <td class="brx2 w-100">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn bb tal">&nbsp;</td>
                      <td class="blx2 w-50">&nbsp;</td>
                      <td class="brx2 w-100">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn bb tal">&nbsp;</td>
                      <td class="blx2 w-50">&nbsp;</td>
                      <td class="brx2 w-100">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn bb tal">&nbsp;</td>
                      <td class="blx2 w-50">&nbsp;</td>
                      <td class="brx2 w-100">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn bb tal">&nbsp;</td>
                      <td class="bbx2 blx2 w-50">&nbsp;</td>
                      <td class="bbx2 brx2 w-100">&nbsp;</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    `;
}

export function printWaybillNum3({waybill, unit, vehicle, vehicle_marks, driver, trailer}) {
    if (!waybill || !unit || !vehicle) {
        return null;
    }

    const date = moment(waybill.date);

    const departure = moment(moment(waybill.departure_date).format(formats.DATE) + ' ' + waybill.departure_time, formats.DATETIME_SHORT);
    const arrival = moment(moment(waybill.arrival_date).format(formats.DATE) + ' ' + waybill.arrival_time, formats.DATETIME_SHORT);

    return `
        <link rel="stylesheet" href="/css/print-form.css">
        <link rel="stylesheet" href="/css/printA4-portrait.css">
        <div class="container print">
          <div class="row justify-content-between">
            <div class="col-auto">Место для штампа <br>организации</div>
            <div class="col-auto">Типовая  межотраслевая форма №  3<br>Утверждена постановлением Госкомстата России<br>от 28.11.97 №78</div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn" colspan="15"></td>
                </tr>
              </table>
            </div>
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn w-300 tar bold">Путевой лист легкового автомобиля</td>
                  <td class="bn bb sign sign_serie"></td>
                  <td class="bn w-25">№</td>
                  <td class="bn bb">${waybill.number || ''}</td>
                  <td class="bn"></td>
                </tr>
              </table>
            </div>
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn" colspan="15"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-6 offset-3">
              <table>
                <tr>
                  <td class="bn w-25"><<</td>
                  <td class="bn bb w-50">${date.format('D') || ''}</td>
                  <td class="bn w-25">>></td>
                  <td class="bn bb">${months[date.format('M') - 1] || ''}</td>
                  <td class="bn w-25"></td>
                  <td class="bn bb tar w-50">${date.format('YYYY') || ''}г.</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn" colspan="15"></td>
                </tr>
              </table>
            </div>
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn" colspan="3"></td>
                  <td class="bn w-100">Коды</td>
                </tr>
                <tr>
                  <td class="bn" colspan="2"></td>
                  <td class="bn w-100 tar">Форма по ОКУД</td>
                  <td class="w-100 btx2 brx2 blx2">0345001</td>
                </tr>
                <tr>
                  <td class="bn w-150 tal">Оганизация</td>
                  <td class="bn bb sign sign_info">${unit.name_full || ''}</td>
                  <td class="bn w-100 tar">по ОКПО</td>
                  <td class="w-100 btx2 brx2 blx2 bbx2">&nbsp;</td>
                </tr>
                <tr>
                  <td class="bn" colspan="4"></td>
                </tr>
                <tr>
                  <td class="bn w-150 tal">Марка автомобиля</td>
                  <td class="bn bb">${_.get(_.find(vehicle_marks, {value: vehicle.vehicle_mark_uuid}), 'label') || ''}</td>
                  <td class="bn w-100"></td>
                  <td class="w-100 btx2 brx2 blx2">&nbsp;</td>
                </tr>
                <tr>
                  <td class="bn w-150 tal">Государственный номерной знак</td>
                  <td class="bn bb">${vehicle.state_number || ''}</td>
                  <td class="bn w-100 tar">Гаражный номер</td>
                  <td class="w-100 btx2 brx2 blx2">${_.get(vehicle, 'garage_number') || ''}</td>
                </tr>
                <tr>
                  <td class="bn w-150 tal">Водитель</td>
                  <td class="bn bb sign sign_mashinist">${driver ? (new User(driver)).getFullName() : ''}</td>
                  <td class="bn w-100 tar">Табельный номер</td>
                  <td class="w-100 btx2 brx2 blx2 bbx2">${_.get(driver, 'info.personnel_number') || ''}</td>
                </tr>
              </table>
            </div>
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn w-150 tal">Удостоверение №</td>
                  <td class="bn bb">${driver ? (new User(driver)).getDriverNumber() : ''}</td>
                  <td class="bn w-25"></td>
                  <td class="bn w-50 tar">Класс</td>
                  <td class="bn bb"></td>
                </tr>
              </table>
            </div>
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn w-150 tal">Лицензионная карточка</td>
                  <td class="bn bb sign sign_cross w-200">стандартная, ограниченная</td>
                  <td class="bn" colspan="3"></td>
                </tr>
              </table>
            </div>
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn h-5" colspan="15"></td>
                </tr>
              </table>
            </div>
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn w-150 tal">Регистрационный №</td>
                  <td class="bn bb"></td>
                  <td class="bn w-25"></td>
                  <td class="bn w-25 tal">Серия</td>
                  <td class="bn bb"></td>
                  <td class="bn w-25"></td>
                  <td class="bn w-25 tal">№</td>
                  <td class="bn bb"></td>
                </tr>
              </table>
            </div>
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn" colspan="15"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn bold">Задание водителю</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn w-100 tal">В распоряжение</td>
                      <td class="bn bb sign sign_name"></td>
                    </tr>
                    <tr>
                      <td class="bn h-10" colspan="3"></td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn bb sign sign_org w-6"></td>
                      <td class="btx2 brx2 blx2 bbx2 w-6">&nbsp;</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn h-10" colspan="15"></td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn w-100 tal">Адрес подачи</td>
                      <td class="bn bb">&nbsp;</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn bb">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn bb">&nbsp;</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn" colspan="15"></td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn tal">Время выезда из гаража, ч. мин.</td>
                      <td class="btx2 brx2 blx2 bbx2 w-6">&nbsp;</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn" colspan="15"></td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn bold w-125 tal">Диспетчер-нарядчик</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn h-10" colspan="4"></td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn" colspan="15"></td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn tal">Время выезда из гаража, ч. мин.</td>
                      <td class="btx2 brx2 blx2 bbx2 w-6">&nbsp;</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn" colspan="15"></td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn bold w-125 tal">Диспетчер-нарядчик</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn h-10" colspan="4"></td>
                    </tr>
                    <tr>
                      <td class="bn bb" colspan="4">&nbsp;</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn" colspan="15"></td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn tal">Опоздания, ожидания, простои в пути, заезды в гараж и прочие отметки</td>
                      <td class="bn bb w-50"></td>
                    </tr>
                    <tr>
                      <td class="bn bb" colspan="2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn bb" colspan="2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn bb" colspan="2">&nbsp;</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn" colspan="15"></td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn bold w-125 tal">Автомобиль сдал водитель</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn h-10" colspan="4"></td>
                    </tr>
                    <tr>
                      <td class="bn h-10 tal" colspan="4">М.П.</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn">Автомобиль технически исправен</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn tal">Показания спидометра,  км</td>
                      <td class="btx2 brx2 blx2 bbx2 w-6">${waybill.fact_departure_odometer || ''}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn tal">Выезд разрешен</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn bold w-100 tal">Механик</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn h-10" colspan="4"></td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn tal">Автомобиль в технически исправном состоянии принял</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn bold w-100 tal">Водитель</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn h-10" colspan="4"></td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn tal w-6">Горючее</td>
                      <td class="bn">
                        <table>
                          <tr>
                            <td class="w-6">Марка</td>
                            <td class="w-6">Год</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn bold">Движение горючего</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn w-6 tal">Выдано</td>
                      <td class="w-6">${_.isNumber(waybill.fuel_issued) ? waybill.fuel_issued : 'количество'}, л</td>
                    </tr>
                    <tr>
                      <td class="bn w-6 tal">по заправочному листу №</td>
                      <td class="w-6">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn w-6 tal">Остаток: при выезде:</td>
                      <td class="w-6">${_.isNumber(waybill.departure_fuel_level) ? waybill.departure_fuel_level : ''}</td>
                    </tr>
                    <tr>
                      <td class="bn w-6 tal">при возвращении:</td>
                      <td class="w-6">${_.isNumber(waybill.arrival_fuel_level) ? waybill.arrival_fuel_level : ''}</td>
                    </tr>
                    <tr>
                      <td class="bn w-6 tal">Расход по норме:</td>
                      <td class="w-6">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn w-6 tal">при возвращении:</td>
                      <td class="w-6">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn w-6 tal">Экономия</td>
                      <td class="w-6">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn w-6 tal">Перерасход</td>
                      <td class="w-6">&nbsp;</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn tal" colspan="2">Автомобиль принял. Показания спидометра при возвращении</td>
                    </tr>
                    <tr>
                      <td class="bn tal">в гараж, км</td>
                      <td class="w-6">${waybill.fact_arrival_odometer || ''}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn bold w-100 tal">Механик</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-auto"></div>
            <div class="col-auto">Оборотная сторона формы № 3</div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td rowspan="3">Номер по порядку</td>
                  <td rowspan="3">Код заказчика</td>
                  <td colspan="2">Место</td>
                  <td colspan="4">Время</td>
                  <td rowspan="3">Пройдено, км</td>
                  <td rowspan="3">Подпись лица, пользовавшегося автомобилем</td>
                </tr>
                <tr>
                  <td rowspan="2">отправления</td>
                  <td rowspan="2">назначения</td>
                  <td colspan="2">выезда</td>
                  <td colspan="2">возвращения</td>
                </tr>
                <tr>
                  <td class="w-25">ч.</td>
                  <td class="w-25">мин.</td>
                  <td class="w-25">ч.</td>
                  <td class="w-25">мин.</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn" colspan="15"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn bold tal" colspan="2">Результат работы автомобиля за смену:</td>
                  <td class="bn w-25"></td>
                  <td class="bn bold tal" colspan="2">Расчет заработной платы:</td>
                </tr>
                <tr>
                  <td class="bn tal w-200">Время выезда из гаража, ч. мин.</td>
                  <td class="btx2 brx2 blx2 bbx2">&nbsp;</td>
                  <td class="bn w-25"></td>
                  <td class="bn tal w-200">Время выезда из гаража, ч. мин.</td>
                  <td class="btx2 brx2 blx2 bbx2">&nbsp;</td>
                </tr>
                <tr>
                  <td class="bn" colspan="5"></td>
                </tr>
                <tr>
                  <td class="bn" colspan="2"></td>
                  <td class="bn w-25"></td>
                  <td class="bn bb" colspan="2"></td>
                </tr>
                <tr>
                  <td class="bn" colspan="5"></td>
                </tr>
                <tr>
                  <td class="bn" colspan="5"></td>
                </tr>
                <tr>
                  <td class="bn" colspan="2"></td>
                  <td class="bn w-25"></td>
                  <td class="bn tal w-200">Итого, руб. коп.</td>
                  <td class="btx2 brx2 blx2 bbx2">&nbsp;</td>
                </tr>
              </table>
            </div>
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn bold w-100 tal">Расчет произвел</td>
                  <td class="bn bb sign sign_post">&nbsp;</td>
                  <td class="bn w-25"></td>
                  <td class="bn bb sign sign_signature">&nbsp;</td>
                  <td class="bn w-25"></td>
                  <td class="bn bb sign sign_transcript">&nbsp;</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
    `;
}

export function printWaybillNum412({waybill, unit, vehicle, vehicle_marks, driver, trailer}) {
    if (!waybill || !unit || !vehicle) {
        return null;
    }

    const date = moment(waybill.date);

    const departure = moment(moment(waybill.departure_date).format(formats.DATE) + ' ' + waybill.departure_time, formats.DATETIME_SHORT);
    const arrival = moment(moment(waybill.arrival_date).format(formats.DATE) + ' ' + waybill.arrival_time, formats.DATETIME_SHORT);

    return `
        <link rel="stylesheet" href="/css/print-form.css">
        <link rel="stylesheet" href="/css/printA4-landscape.css">
        <div class="container print">
          <div class="row flex flex_between">
            <div class="col-auto tac">Согласовано<br>Письмом Госкомстата России<br>от 10.04.2003 № КЛ-01-21/1381</div>
            <div class="col-auto bold tac align-self-end">ПУТЕВОЙ ЛИСТ ТРАКТОРА № ${waybill.number || ''}</div>
            <div class="col-auto tac">Утверждено<br>Приказом Минсельхоза России<br>от 16.05.2003 № 750</div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn h-10" colspan="15"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn"></td>
                      <td class="bn"></td>
                      <td class="bn w-150"></td>
                      <td class="bn w-150">Коды</td>
                    </tr>
                    <tr>
                      <td class="bn"></td>
                      <td class="bn"></td>
                      <td class="bn w-150 tar">Форма по ОКУД</td>
                      <td class="w-150 btx2 brx2 blx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn"></td>
                      <td class="bn"></td>
                      <td class="bn w-150 tar">Дата(месяц, год)</td>
                      <td class="w-150 btx2 brx2 blx2 no-padding">
                        <table>
                          <tr>
                            <td class="bn br">&nbsp;</td>
                            <td class="bn">&nbsp;</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td class="bn bold w-100 tal">Организация</td>
                      <td class="bn bb">${unit.name_full || ''}</td>
                      <td class="bn w-150 tar">по ОКПО </td>
                      <td class="w-150 brx2 blx2 btx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn w-100 tal bold">Тракторист</td>
                      <td class="bn bb sign sign_mashinist">${driver ? (new User(driver)).getFullName() : ''}</td>
                      <td class="bn w-150 tar">Табельный номер</td>
                      <td class="w-150 btx2 brx2 blx2 bbx2">${_.get(driver, 'info.personnel_number') || ''}</td>
                    </tr>
                    <tr>
                      <td class="bn w-100 tal bold">Марка машины</td>
                      <td class="bn bb">${_.get(_.find(vehicle_marks, {value: vehicle.vehicle_mark_uuid}), 'label') || ''}</td>
                      <td class="bn w-150 tar">Регистрационный номер</td>
                      <td class="w-150 btx2 brx2 blx2 bbx2">${vehicle.state_number || ''}</td>
                    </tr>
                    <tr>
                      <td class="bn"></td>
                      <td class="bn"></td>
                      <td class="bn w-150 tar">Инвентарный номер</td>
                      <td class="w-150 btx2 brx2 blx2 bbx2">${_.get(vehicle, 'garage_number') || ''}</td>
                    </tr>
                    <tr>
                      <td class="bn w-100 tal bold">Прицеп</td>
                      <td class="bn bb"></td>
                      <td class="bn w-150 tar">Регистрационный номер</td>
                      <td class="w-150 btx2 brx2 blx2 bbx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn"></td>
                      <td class="bn"></td>
                      <td class="bn w-150 tar">Инвентарный номер</td>
                      <td class="w-150 btx2 brx2 blx2 bbx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn w-100 tal bold">Прицепщик (Ф.И.О.)</td>
                      <td class="bn bb"></td>
                      <td class="bn w-150 tar"> </td>
                      <td class="w-150 btx2 brx2 blx2 bbx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn"></td>
                      <td class="bn"></td>
                      <td class="bn w-150 tar">Отделение (участок)</td>
                      <td class="w-150 btx2 brx2 blx2 bbx2">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn w-100 tal bold">Бригадир (Ф.И.О.)</td>
                      <td class="bn bb"></td>
                      <td class="bn w-150 tar">Бригада</td>
                      <td class="w-150 btx2 brx2 blx2 bbx2">&nbsp;</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn">&nbsp;</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn tal">Трактор технически исправен. Выезд разрешаю</td>
                    </tr>
                    <tr>
                      <td class="bn tal">"${date.format('D') || ''}" ${months[date.format('M') - 1] || ''} ${date.format('YYYY') || ''}г.</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12">
                  <table>
                    <tr>
                      <td class="bn bold w-100 tal">Механик/бригадир</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn w-25"></td>
                      <td class="bn bb sign sign_transcript">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="bn h-20" colspan="4"></td>
                    </tr>
                    <tr>
                      <td class="bn bold tal" colspan="4">Трактор в исправном состоянии.</td>
                    </tr>
                    <tr>
                      <td class="bn bold w-100 tal">Принял тракторист</td>
                      <td class="bn bb sign sign_signature">&nbsp;</td>
                      <td class="bn"></td>
                      <td class="bn"></td>
                    </tr>
                    <tr>
                      <td class="bn h-15" colspan="4"></td>
                    </tr>
                    <tr>
                      <td class="bn tal" colspan="4">"${date.format('D') || ''}" ${months[date.format('M') - 1] || ''} ${date.format('YYYY') || ''}г.</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn h-20"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn tal bold">Задание трактористу:</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <table>
                <tr>
                  <td class="h-32">В чье распоряжение</td>
                  <td class="h-32">Откуда взять груз</td>
                  <td class="h-32">Куда доставить груз</td>
                  <td class="h-32">Расстояние, км (код по ОКЕИ - 008)</td>
                  <td class="h-32">Название груза</td>
                  <td class="h-32">Класс груза</td>
                  <td class="h-32">Число ездок с грузом</td>
                  <td class="h-32">Количество мест, шт.</td>
                  <td class="h-32">Вес груза, тонн</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </table>
            </div>
            <div class="col-3">
              <table>
                <tr>
                  <td colspan="3">Выдача горючего:</td>
                </tr>
                <tr>
                  <td class="w-75"></td>
                  <td class="w-50">кг</td>
                  <td class="w-50">подпись заправщика</td>
                </tr>
                <tr>
                  <td>Остаток при выезде</td>
                  <td>${_.isNumber(waybill.departure_fuel_level) ? waybill.departure_fuel_level : ''}</td>
                  <td class="h-32"></td>
                </tr>
                <tr>
                  <td>Выдано</td>
                  <td>${_.isNumber(waybill.fuel_issued) ? waybill.fuel_issued : ''}</td>
                  <td class="h-32"></td>
                </tr>
                <tr>
                  <td>Остаток при возвращении</td>
                  <td>${_.isNumber(waybill.arrival_fuel_level) ? waybill.arrival_fuel_level : ''}</td>
                  <td class="h-32"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn tal w-200">Опоздания, простои и другие отметки</td>
                  <td class="bn bb"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn"></td>
                  <td class="bn bold w-150 tal">Трактор сдал. Тракторист</td>
                  <td class="bn bb sign w-150 sign_signDate">&nbsp;</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn w-150 tal">Начало работы (час. мин.)</td>
                  <td class="bax2 sign w-150">&nbsp;</td>
                  <td class="bn w-25"></td>
                  <td class="bn w-150 tal">Конец работы (час. мин.)</td>
                  <td class="bax2 sign w-150">&nbsp;</td>
                  <td class="bn"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn w-100 tal bold">Механик/бригадир</td>
                  <td class="bn bb sign sign_signature"></td>
                  <td class="bn w-50"></td>
                  <td class="bn w-100 tal bold">Механик/бригадир</td>
                  <td class="bn bb sign sign_signature"></td>
                  <td class="bn w-50"></td>
                  <td class="bn w-200 tal bold">Трактор принял. Механик/бригадир	</td>
                  <td class="bn bb sign sign_signDate"></td>
                  <td class="bn w-50"></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-auto"></div>
            <div class="col-auto">Оборотная сторона формы № 412-АПК</div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn bold">Выполнение задания</td>
                </tr>
              </table>
            </div>
            <div class="col-12">
              <table>
                <tr>
                  <td class="w-100" rowspan="3">
                    <div>Откуда</div>
                  </td>
                  <td class="w-100" rowspan="3">
                    <div>Куда</div>
                  </td>
                  <td class="w-100" rowspan="3">
                    <div>Название груза</div>
                  </td>
                  <td class="w-50" rowspan="3">
                    <div class="vlr nowrap">Класс груза</div>
                  </td>
                  <td class="w-50" rowspan="3"> 
                    <div class="vlr nowrap">Время отправки</div>
                  </td>
                  <td class="w-50" rowspan="3">
                    <div class="vlr nowrap">Время прибытия</div>
                  </td>
                  <td class="w-50" rowspan="3">
                    <div class="vlr nowrap">Бригада-заказчик</div>
                  </td>
                  <td class="w-50" rowspan="3">
                    <div class="vlr">Шифр синтетического<br>аналитического учета работы</div>
                  </td>
                  <td class="w-50" rowspan="3">
                    <div class="vlr nowrap">Единица измерения</div>
                  </td>
                  <td class="w-50" rowspan="3">
                    <div class="vlr nowrap">Отработано часов</div>
                  </td>
                  <td class="w-50" rowspan="3">
                    <div class="vlr nowrap">Перевезено груза, т</div>
                  </td>
                  <td class="w-50" rowspan="3">
                    <div class="vlr nowrap">Сделано тонно-километров</div>
                  </td>
                  <td class="w-50" rowspan="3">
                    <div class="vlr nowrap">Норма выработки</div>
                  </td>
                  <td class="w-50" rowspan="3">
                    <div class="vlr nowrap">Расценка</div>
                  </td>
                  <td colspan="4">
                    <div>Оплата труда в рублях</div>
                  </td>
                  <td class="w-50" rowspan="3">
                    <div>Выполнено нормо-смен</div>
                  </td>
                  <td class="w-50" rowspan="3">
                    <div>Условные гектары</div>
                  </td>
                  <td colspan="2" rowspan="2">
                    <div>Пробег, км	</div>
                  </td>
                  <td colspan="3">
                    <div>Расход горючего, км</div>
                  </td>
                </tr>
                <tr>
                  <td class="w-50" rowspan="2">
                    <div class="vlr nowrap">основная</div>
                  </td>
                  <td class="w-50" rowspan="2">
                    <div class="vlr nowrap">дополнительная</div>
                  </td>
                  <td class="w-50" rowspan="2">
                    <div class="vlr nowrap">сдельная</div>
                  </td>
                  <td class="w-50" rowspan="2">
                    <div class="vlr nowrap">всего</div>
                  </td>
                  <td colspan="2">
                    <div>по норме</div>
                  </td>
                  <td class="w-50" rowspan="2">
                    <div class="vlr nowrap">фактически</div>
                  </td>
                </tr>
                <tr>
                  <td class="w-50">
                    <div class="vlr nowrap">всего</div>
                  </td>
                  <td class="w-50">
                    <div class="vlr nowrap">в том числе без груза</div>
                  </td>
                  <td class="w-50">
                    <div class="vlr nowrap">на единицу работы</div>
                  </td>
                  <td class="w-50">
                    <div class="vlr nowrap">всего</div>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <table>
                <tr>
                  <td class="bn bold w-100 tal">Тракторист</td>
                  <td class="bn bb sign sign_signature">&nbsp;</td>
                  <td class="bn w-25"></td>
                  <td class="bn bb sign sign_transcript">&nbsp;</td>
                </tr>
              </table>
            </div>
            <div class="col-3">
              <table>
                <tr>
                  <td class="bn"></td>
                  <td class="bn bold tal w-100">Отработано:</td>
                  <td class="bn tar w-50">часов</td>
                  <td class="bax2 w-50">&nbsp;</td>
                  <td class="bn"></td>
                </tr>
                <tr>
                  <td class="bn"></td>
                  <td class="bn bold tal w-100"></td>
                  <td class="bn tar w-50">дней</td>
                  <td class="bax2 w-50">&nbsp;</td>
                  <td class="bn"></td>
                </tr>
              </table>
            </div>
            <div class="col-3">
              <table>
                <tr>
                  <td class="bn"></td>
                  <td class="bn bold tal w-100">Отработано:</td>
                  <td class="bn tar w-100">машино-дней</td>
                  <td class="bax2 w-50">&nbsp;</td>
                  <td class="bn"></td>
                </tr>
                <tr>
                  <td class="bn"></td>
                  <td class="bn bold tal w-100"></td>
                  <td class="bn tar w-100">машино-смен</td>
                  <td class="bax2 w-50">&nbsp;</td>
                  <td class="bn"></td>
                </tr>
              </table>
            </div>
            <div class="col-3">
              <table>
                <tr>
                  <td class="bn bold w-100 tal">Бригадир</td>
                  <td class="bn bb sign sign_signature">&nbsp;</td>
                  <td class="bn w-25"></td>
                  <td class="bn bb sign sign_transcript">&nbsp;</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
    `;
}

export function printWaybillEsm2({waybill, unit, vehicle, vehicle_marks, driver, trailer}) {
    if (!waybill || !unit || !vehicle) {
        return null;
    }

    const date = moment(waybill.date);

    const departure = moment(moment(waybill.departure_date).format(formats.DATE) + ' ' + waybill.departure_time, formats.DATETIME_SHORT);
    const arrival = moment(moment(waybill.arrival_date).format(formats.DATE) + ' ' + waybill.arrival_time, formats.DATETIME_SHORT);

    return `
        <link rel="stylesheet" href="/css/print-form.css">
        <link rel="stylesheet" href="/css/printA4-landscape.css">
        <div class="container print">
          <div class="row justify-content-between">
            <div class="col-auto"></div>
            <div class="col-auto">Типовая межотраслевая форма № ЭСМ-2<br>Утверждена постановлением Госкомстата России<br>от 28.11.97 № 78</div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn" colspan="15"></td>
                </tr>
                <tr>
                  <td class="bn ttu bold" colspan="13">ПУТЕВОЙ ЛИСТ №${waybill.number || ''}</td>
                  <td class="bn w-150"></td>
                  <td class="bn w-150">Коды</td>
                </tr>
                <tr>
                  <td class="bn bold towrap" colspan="13">строительной машины</td>
                  <td class="bn w-150 tar">Форма по ОКУД</td>
                  <td class="w-150 btx2 brx2 blx2">0340002</td>
                </tr>
                <tr>
                  <td class="bn" colspan="13"></td>
                  <td class="bn w-150 tar">Дата составления </td>
                  <td class="w-150 no-padding blx2 brx2">
                    <table>
                      <tr>
                        <td class="bn br">${date.format('D') || ''}</td>
                        <td class="bn br"${date.format('MM') || ''}</td>
                        <td class="bn">${date.format('YYYY') || ''}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td class="bn bold w-100 tal">Организация</td>
                  <td class="bn bb sign sign_info" colspan="12">${unit.name_full || ''}, ${unit.legal_address || ''}, ${unit.phone || ''}</td>
                  <td class="bn w-150 tar">по ОКПО </td>
                  <td class="w-150 brx2 blx2">&nbsp;</td>
                </tr>
                <tr>
                  <td class="bn bold w-100 tal">Заказчик</td>
                  <td class="bn bb sign sign_info" colspan="12"></td>
                  <td class="bn w-150 tar">по ОКПО </td>
                  <td class="w-150 brx2 blx2 bbx2">&nbsp;</td>
                </tr>
                <tr>
                  <td class="bn bold w-100 tal">Машина</td>
                  <td class="bn bb sign sign_marka" colspan="5">${_.get(_.find(vehicle_marks, {value: vehicle.vehicle_mark_uuid}), 'label') || ''}</td>
                  <td class="bn w-100"></td>
                  <td class="bn bold w-225 towrap">Государственный номерной знак</td>
                  <td class="bn bb sign" colspan="6">${vehicle.state_number || ''}</td>
                  <td class="bn w-150">&nbsp;</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <table>
              <tr>
                <td class="bn" colspan="15"></td>
              </tr>
            </table>
          </div>
          <div class="row">
            <div class="col-6">
              <table>
                <tr>
                  <td class="bn bold w-100 tal">Машинист</td>
                  <td class="bn bb sign sign_mashinist" colspan="6">${driver ? (new User(driver)).getFullName() : ''}</td>
                  <td class="bn w-100"></td>
                </tr>
              </table>
            </div>
            <div class="col-6">
              <table>
                <tr>
                  <td rowspan="2">Код вида операции</td>
                  <td colspan="2">Период работы</td>
                  <td rowspan="2">Колонна, участок</td>
                  <td colspan="2">Машина</td>
                  <td rowspan="2">Табельный номер</td>
                </tr>
                <tr>
                  <td>c</td>
                  <td>по</td>
                  <td>марка</td>
                  <td>инвентарный номер</td>
                </tr>
                <tr>
                  <td class="bax2">&nbsp;</td>
                  <td class="bax2">${departure.format('HH:mm') || ''}</td>
                  <td class="bax2">${arrival.format('HH:mm') || ''}</td>
                  <td class="bax2">&nbsp;</td>
                  <td class="bax2">${_.get(_.find(vehicle_marks, {value: vehicle.vehicle_mark_uuid}), 'label') || ''}</td>
                  <td class="bax2">${_.get(vehicle, 'garage_number') || ''}</td>
                  <td class="bax2">${_.get(vehicle, 'general.inventory_number') || ''}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn" colspan="15"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td rowspan="5">Числа месяца</td>
                  <td class="w-300" rowspan="5">Наименование и адрес объекта</td>
                  <td rowspan="5">Подпись диспетчера</td>
                  <td rowspan="3" colspan="2">Выезд из гаража</td>
                  <td class="w-75" rowspan="3">Машина технически исправна. Выезд разрешен. Подпись механика</td>
                  <td rowspan="3" colspan="2">Возвращение в гараж</td>
                  <td class="w-75" rowspan="3">Машину сдал машинист. Подпись машиниста</td>
                  <td colspan="6">Расход топлива (горючего), л</td>
                  <td class="w-75" rowspan="5">Подпись заправщика (машиниста)</td>
                </tr>
                <tr>
                  <td class="w-75" rowspan="4" colspan="2">наличие при выезде</td>
                  <td colspan="3">выдано</td>
                  <td class="w-75" rowspan="4">остаток при возвращении</td>
                </tr>
                <tr>
                  <td colspan="3">вид, марка</td>
                </tr>
                <tr>
                  <td class="w-75" rowspan="2">время,ч. мин.</td>
                  <td class="w-75" rowspan="2">показания спидометра,км</td>
                  <td class="w-75" rowspan="2">Машину принял машинист. Подпись машиниста</td>
                  <td class="w-75" rowspan="2">время,ч. мин.</td>
                  <td class="w-75" rowspan="2">показания спидометра,км</td>
                  <td class="w-75" rowspan="2">Машину принял механик. Подпись механика</td>
                  <td class="btx2 blx2 bbx2 w-75">бензина</td>
                  <td class="btx2 bbx2 w-75">дизельного топлива</td>
                  <td class="btx2 brx2 bbx2 w-75">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td> &nbsp;</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                  <td>5</td>
                  <td>6</td>
                  <td>7</td>
                  <td>8</td>
                  <td>9</td>
                  <td colspan="2">10</td>
                  <td>11</td>
                  <td>12</td>
                  <td>13</td>
                  <td>14</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td class="blx2 btx2 brx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 btx2 brx2" rowspan="2">${waybill.fact_departure_odometer || ''}</td>
                  <td>&nbsp;</td>
                  <td rowspan="2">${waybill.fact_arrival_time || ''}</td>
                  <td class="blx2 btx2 brx2" rowspan="2">${waybill.fact_arrival_odometer || ''}</td>
                  <td>&nbsp;</td>
                  <td class="blx2 btx2" rowspan="2" colspan="2">${_.isNumber(waybill.departure_fuel_level) ? waybill.departure_fuel_level : ''}</td>
                  <td class="btx2" rowspan="2">&nbsp;</td>
                  <td class="btx2" rowspan="2">&nbsp;</td>
                  <td class="btx2" rowspan="2">&nbsp;</td>
                  <td class="brx2 btx2" rowspan="2">${_.isNumber(waybill.arrival_fuel_level) ? waybill.arrival_fuel_level : ''}</td>
                  <td rowspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td class="blx2" rowspan="2" colspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="brx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td class="blx2" rowspan="2" colspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="brx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td class="blx2" rowspan="2" colspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="brx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td class="blx2" rowspan="2" colspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="brx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td class="blx2" rowspan="2" colspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="brx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td class="blx2" rowspan="2" colspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="brx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2 bb2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2 bb2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="blx2 brx2 bb2" rowspan="2">&nbsp;</td>
                  <td>&nbsp;</td>
                  <td class="blx2 bbx2" rowspan="2" colspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                  <td class="brx2 bbx2" rowspan="2">&nbsp;</td>
                  <td rowspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td colspan="4">Должность, фамилия, и., о. ответственного работника за перемещение груза</td>
                  <td colspan="5">Такелажники (стропальщики)</td>
                  <td class="w-25" rowspan="4">
                    <div class="flex flex_column"><span>Р</span><span>а</span><span>с</span><span>х</span><span>о</span><span>д</span></div>
                  </td>
                  <td class="brx2" rowspan="2">фактически</td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="brx2" rowspan="2"></td>
                </tr>
                <tr>
                  <td colspan="2">у владельца машины</td>
                  <td colspan="2">у заказчика</td>
                  <td colspan="2">фамилия, и., о.</td>
                  <td colspan="3">номер служебного удостоверения</td>
                </tr>
                <tr>
                  <td colspan="4">&nbsp;</td>
                  <td colspan="5">&nbsp;</td>
                  <td class="brx2 bb" rowspan="4">по норме</td>
                  <td class="bb2" rowspan="2"></td>
                  <td class="bb2" rowspan="2"></td>
                  <td class="bb2 brx2" rowspan="2"></td>
                </tr>
                <tr>
                  <td colspan="4">&nbsp;</td>
                  <td colspan="5"> &nbsp;</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-auto">Заполняется заказчиком</div>
            <div class="col-auto">Обортная сторона формы № ЭСМ-2<br>Заполняется владельцем машины</div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td rowspan="3">Числа месяца</td>
                  <td>Начало работы</td>
                  <td colspan="2">Объект</td>
                  <td colspan="2">Код</td>
                  <td rowspan="3">Отработано часов</td>
                  <td rowspan="3">Стоимость работы, руб. коп.</td>
                  <td colspan="3">Простои</td>
                  <td rowspan="3">Подпись и штамп заказчика</td>
                  <td colspan="6">Показатели для расчета заработной платы</td>
                </tr>
                <tr>
                  <td rowspan="2">Начало работы</td>
                  <td rowspan="2">наименование и адрес</td>
                  <td rowspan="2">код</td>
                  <td rowspan="2">вида работы</td>
                  <td rowspan="2">этапа работы</td>
                  <td colspan="2">код</td>
                  <td rowspan="2">часы</td>
                  <td rowspan="2">ночные часы</td>
                  <td colspan="2">пробег</td>
                  <td rowspan="2">стоянка на линии, ч.</td>
                  <td colspan="2">прочие (сверхурочные выходные и т.п.)</td>
                </tr>
                <tr>
                  <td>причины</td>
                  <td>виновника</td>
                  <td>часы</td>
                  <td>км</td>
                  <td>код вида оплаты</td>
                  <td>часы</td>
                </tr>
                <tr>
                  <td class="blx2 brx2 btx2" rowspan="2"></td>
                  <td></td>
                  <td rowspan="2"></td>
                  <td class="blx2 btx2" rowspan="2"></td>
                  <td class="btx2" rowspan="2"></td>
                  <td class="btx2" rowspan="2"></td>
                  <td class="btx2" rowspan="2"></td>
                  <td class="btx2" rowspan="2"></td>
                  <td class="btx2" rowspan="2"></td>
                  <td class="btx2" rowspan="2"></td>
                  <td class="btx2 brx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2 btx2" rowspan="2"></td>
                  <td class="brx2 btx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2 brx2 btx2" colspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td class="blx2">&nbsp;</td>
                  <td class="brx2">&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2" rowspan="2"></td>
                  <td></td>
                  <td rowspan="2"></td>
                  <td class="blx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="brx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2" rowspan="2"></td>
                  <td class="brx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2 brx2" colspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td class="blx2">&nbsp;</td>
                  <td class="brx2">&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2" rowspan="2"></td>
                  <td></td>
                  <td rowspan="2"></td>
                  <td class="blx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="brx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2" rowspan="2"></td>
                  <td class="brx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2 brx2" colspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td class="blx2">&nbsp;</td>
                  <td class="brx2">&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2" rowspan="2"></td>
                  <td></td>
                  <td rowspan="2"></td>
                  <td class="blx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="brx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2" rowspan="2"></td>
                  <td class="brx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2 brx2" colspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td class="blx2">&nbsp;</td>
                  <td class="brx2">&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2" rowspan="2"></td>
                  <td></td>
                  <td rowspan="2"></td>
                  <td class="blx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="brx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2" rowspan="2"></td>
                  <td class="brx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2 brx2" colspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td class="blx2">&nbsp;</td>
                  <td class="brx2">&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2" rowspan="2"></td>
                  <td></td>
                  <td rowspan="2"></td>
                  <td class="blx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="brx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2" rowspan="2"></td>
                  <td class="brx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2 brx2" colspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td class="blx2">&nbsp;</td>
                  <td class="brx2">&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2" rowspan="2"></td>
                  <td></td>
                  <td rowspan="2"></td>
                  <td class="blx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="brx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2" rowspan="2"></td>
                  <td class="brx2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2 brx2" colspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td class="blx2">&nbsp;</td>
                  <td class="brx2">&nbsp;</td>
                </tr>
                <tr>
                  <td class="blx2 brx2 bb2" rowspan="2"></td>
                  <td></td>
                  <td rowspan="2"></td>
                  <td class="blx2 bb2" rowspan="2"></td>
                  <td class="bb2" rowspan="2"></td>
                  <td class="bb2" rowspan="2"></td>
                  <td class="bb2" rowspan="2"></td>
                  <td class="bb2" rowspan="2"></td>
                  <td class="bb2" rowspan="2"></td>
                  <td class="bb2" rowspan="2"></td>
                  <td class="brx2 bb2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2 bb2" rowspan="2"></td>
                  <td class="brx2 bb2" rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td rowspan="2"></td>
                  <td class="blx2 brx2" colspan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td class="blx2 bb2">&nbsp;</td>
                  <td class="brx2 bb2">&nbsp;</td>
                </tr>
                <tr>
                  <td class="tar bn" colspan="6">Итого</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>X</td>
                  <td>X</td>
                  <td>&nbsp;</td>
                  <td>X</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>X</td>
                  <td>&nbsp;</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <table>
              <tr>
                <td class="bn h-25" colspan="15"></td>
              </tr>
            </table>
          </div>
          <div class="row">
            <div class="col-4">
              <table>
                <tr>
                  <td class="bn w-150">Стоимость одного машино-часа, руб. коп.</td>
                  <td class="w-300 h-50"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn h-25" colspan="15"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn w-150 tal">Претензии к работе машиниста</td>
                  <td class="bn bb sign sign_mashinist"></td>
                  <td class="bn w-75"></td>
                  <td class="bn bb"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn h-25" colspan="15"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn w-150 tal">Машинист</td>
                  <td class="bn bb sign sign_signature"></td>
                  <td class="bn w-25"></td>
                  <td class="bn bb sign sign_transcript"></td>
                  <td class="bn w-150"></td>
                  <td class="bn w-150 tal">Расчет произвел </td>
                  <td class="bn bb sign sign_post"></td>
                  <td class="bn w-25"></td>
                  <td class="bn bb sign sign_signature"></td>
                  <td class="bn w-25"></td>
                  <td class="bn bb sign sign_transcript"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn h-25" colspan="15"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn w-150 tal">Прораб</td>
                  <td class="bn bb sign sign_signature"></td>
                  <td class="bn w-25"></td>
                  <td class="bn bb sign sign_transcript"></td>
                  <td class="bn w-150"></td>
                  <td class="bn w-150 tal">Руководитель подразделения </td>
                  <td class="bn bb sign sign_post"></td>
                  <td class="bn w-25"></td>
                  <td class="bn bb sign sign_signature"></td>
                  <td class="bn w-25"></td>
                  <td class="bn bb sign sign_transcript"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn h-25" colspan="15"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <table>
                <tr>
                  <td class="bn w-25"><<</td>
                  <td class="bn bb w-50"></td>
                  <td class="bn w-25">>></td>
                  <td class="bn bb"></td>
                  <td class="bn w-25"></td>
                  <td class="bn bb tar w-75">г.</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table>
                <tr>
                  <td class="bn h-25" colspan="15"></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
    `;
}

export function printWaybillP({waybill, unit, vehicle, vehicle_marks, driver, trailer}) {
    waybill = waybill || {};
    unit = unit || {};
    vehicle = vehicle || {};

    const date = moment(waybill.date);

    const departure = moment(moment(waybill.departure_date).format(formats.DATE) + ' ' + waybill.departure_time, formats.DATETIME_SHORT);
    const arrival = moment(moment(waybill.arrival_date).format(formats.DATE) + ' ' + waybill.arrival_time, formats.DATETIME_SHORT);

    return `
        <link rel="stylesheet" href="/css/waybill_print.css"/>
        <div>
            <div class="row">
                <div class="col col_full">
                    <table>
                        <tr>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="5"></td>
                            <td class="border-none text-center font-bold text-uppercase" colspan="13">Путевой
                                лист
                            </td>
                            <td class="border-none text-right" colspan="5">
                                Типовая межотраслевая форма №4-П<br/>Утверждена постановлением Госкомстата
                                России<br/>от 28.11.97 №78
                            </td>
                        </tr>
                        <tr>
                            <td class="border-none font-normal text-center" colspan="3">Место для штампа<br/>организации
                            </td>
                            <td class="border-none text-right font-bold vab" colspan="4">грузового автомобиля
                            </td>
                            <td class="border-none"></td>
                            <td class="border-none border-bottom" colspan="3"></td>
                            <td class="border-none font-bold vab">№</td>
                            <td class="border-none border-bottom" colspan="3">${waybill.number || ''}</td>
                            <td class="border-none" colspan="8"></td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="8"></td>
                            <td class="border-none text-center font-normal" colspan="3">(серия)</td>
                            <td class="border-none" colspan="12"></td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="21"></td>
                            <td class="border-all w5" colspan="2">Коды</td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="6"></td>
                            <td class="border-none text-right">«</td>
                            <td class="border-none border-bottom">${date.format('D') || ''}</td>
                            <td class="border-none text-left">»</td>
                            <td class="border-none border-bottom" colspan="3">${months[date.format('M') - 1] || ''}</td>
                            <td class="border-none"></td>
                            <td class="border-none border-bottom text-right">${date.format('YYYY') || ''}г.</td>
                            <td class="border-none text-right" colspan="7">Форма по ОКУД</td>
                            <td class="blx2 btx2 brx2 w5" colspan="2">0345005</td>
                        </tr>
                        <tr>
                            <td class="border-none text-left" colspan="2">Организация</td>
                            <td class="border-none border-bottom" colspan="17">${unit.name_full || ''}, ${unit.legal_address || ''}, ${unit.phone || ''}, ${unit.ogrn || ''}</td>
                            <td class="border-none text-right" colspan="2">по ОКПО</td>
                            <td class="blx2 bbx2 brx2 w5" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left" colspan="2"></td>
                            <td class="border-none font-normal" colspan="17">(наименование, адрес и номер
                                телефона)
                            </td>
                            <td class="border-none text-right" colspan="2"></td>
                            <td class="border-none" colspan="2"></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col col_half indent">
                    <table>
                        <tr>
                            <td class="border-none text-right w90" colspan="10"></td>
                            <td class="border-all w10">Коды</td>
                        </tr>
                        <tr>
                            <td class="border-none text-right w90" colspan="10">Режим работы</td>
                            <td class="border-all blx2 btx2 brx2 w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-right w90" colspan="10">Колонна</td>
                            <td class="border-all blx2 btx2 brx2 w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-right w90" colspan="10">Бригада</td>
                            <td class="border-all blx2 btx2 brx2 w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Марка автомобиля</td>
                            <td class="border-none bb w50" colspan="8">${_.get(_.find(vehicle_marks, {value: vehicle.vehicle_mark_uuid}), 'label') || ''}</td>
                            <td class="border-none w10"></td>
                            <td class="border-all blx2 btx2 brx2 w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Государственный номерной знак</td>
                            <td class="border-none bb w50" colspan="8">${vehicle.state_number || ''}</td>
                            <td class="border-none text-right w10">Гаражный номер</td>
                            <td class="border-all blx2 btx2 brx2 w10">${_.get(vehicle, 'garage_number') || ''}</td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Водитель</td>
                            <td class="border-none bb w50" colspan="8">${driver ? (new User(driver)).getFullName() : ''}</td>
                            <td class="border-none text-right w10">Табельный номер</td>
                            <td class="border-all blx2 btx2 brx2 bbx2 w10">${_.get(driver, 'info.personnel_number') || ''}</td>
                        </tr>
                        <tr>
                            <td class="border-none w30"></td>
                            <td class="border-none text-center w50" colspan="8">(фамилия, имя, отчество)</td>
                            <td class="border-none text-right w20" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Удостоверение №</td>
                            <td class="border-none bb w40" colspan="6">${driver ? (new User(driver)).getDriverNumber() : ''}</td>
                            <td class="border-none text-right w10">Класс</td>
                            <td class="border-none bb w20" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none w100" colspan="11">&nbsp;</td>
                        </tr>
                    </table>
                </div>
                <div class="col col_half">
                    <table>
                        <tr>
                            <td class="text-center text-uppercase border-all" colspan="8">Работа водителя и
                                автомобиля
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center border-all w20" rowspan="3">операция</td>
                            <td class="text-center border-all w20" rowspan="2" colspan="4">время по графику</td>
                            <td class="text-center border-all w20" rowspan="3">нулевой пробег, км</td>
                            <td class="text-center border-all w20" rowspan="3">показание спидометра, км</td>
                            <td class="text-center border-all w20" rowspan="3">время фактическое, число, месяц,
                                ч.мин.
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center text-uppercase border-none" colspan="8"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-all w5">число</td>
                            <td class="text-center border-all w5">месяц</td>
                            <td class="text-center border-all w5">ч.</td>
                            <td class="text-center border-all w5">мин.</td>
                        </tr>
                        <tr>
                            <td class="text-center border-all">1</td>
                            <td class="text-center border-all">2</td>
                            <td class="text-center border-all">3</td>
                            <td class="text-center border-all">4</td>
                            <td class="text-center border-all">5</td>
                            <td class="text-center border-all">6</td>
                            <td class="text-center border-all">7</td>
                            <td class="text-center border-all">8</td>
                        </tr>
                        <tr>
                            <td class="text-center border-all">выезд из гаража</td>
                            <td class="text-center border-all blx2 btx2">${departure.format('DD') || ''}</td>
                            <td class="text-center border-all btx2">${departure.format('MM') || ''}</td>
                            <td class="text-center border-all btx2">${departure.format('HH') || ''}</td>
                            <td class="text-center border-all btx2">${departure.format('mm') || ''}</td>
                            <td class="text-center border-all btx2"></td>
                            <td class="text-center border-all btx2">${waybill.fact_departure_odometer || ''}</td>
                            <td class="text-center border-all btx2 brx2"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-all">возвращение в гараж</td>
                            <td class="text-center border-all blx2 bbx2">${arrival.format('DD') || ''}</td>
                            <td class="text-center border-all bbx2">${arrival.format('MM') || ''}</td>
                            <td class="text-center border-all bbx2">${arrival.format('HH') || ''}</td>
                            <td class="text-center border-all bbx2">${arrival.format('mm') || ''}</td>
                            <td class="text-center border-all bbx2"></td>
                            <td class="text-center border-all bbx2">${waybill.fact_arrival_odometer || ''}</td>
                            <td class="text-center border-all bbx2 brx2">${moment(waybill.fact_arrival_date).format(formats.DATETIME) || ''}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col col_half indent">
                    <table>
                        <tr>
                            <td class="border-none text-left w30">Лицензионная карточка</td>
                            <td class="border-none bb w50" colspan="8">стандартная, ограниченная</td>
                            <td class="border-none w20" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30"></td>
                            <td class="border-none w50" colspan="8">(ненужное зачеркнуть)</td>
                            <td class="border-none w20" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-none w100" colspan="11">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Регистрационный номер</td>
                            <td class="border-none bb w30" colspan="6"></td>
                            <td class="border-none text-right w10">Серия</td>
                            <td class="border-none bb w10"></td>
                            <td class="border-none text-right w10">№</td>
                            <td class="border-none bb w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none w100" colspan="11">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Прицеп 1</td>
                            <td class="border-none bb w20" colspan="4">${(trailer ? _.get(_.find(vehicle_marks, {value: trailer.vehicle_mark_uuid}), 'label') : '') || ''}</td>
                            <td class="border-none w20" colspan="3">Государственный номерной знак</td>
                            <td class="border-none bb w10">${_.get(trailer, 'state_number') || ''}</td>
                            <td class="border-none w10">Гаражный номер</td>
                            <td class="blx2 brx2 btx2 bbx2 w10">${_.get(trailer, 'garage_number') || ''}</td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30"></td>
                            <td class="border-none w20 tooltip" colspan="4">(марка)</td>
                            <td class="border-none w20" colspan="3"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Прицеп 2</td>
                            <td class="border-none bb w20" colspan="4"></td>
                            <td class="border-none w20" colspan="3">Государственный номерной знак</td>
                            <td class="border-none bb w10"></td>
                            <td class="border-none w10">Гаражный номер</td>
                            <td class="blx2 brx2 btx2 bbx2 w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30"></td>
                            <td class="border-none w20 tooltip" colspan="4">(марка)</td>
                            <td class="border-none w20" colspan="3"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Сопровождающие лица</td>
                            <td class="border-none border-bottom w70" colspan="10"></td>
                        </tr>
                    </table>
                </div>
                <div class="col col_half">
                    <table>
                        <tr>
                            <td class="text-center border-all" colspan="8">Движение горючего</td>
                            <td class="text-center border-all" rowspan="2" colspan="3">Время работы, ч.мин</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="2">горючее</td>
                            <td class="border-all" rowspan="2">выдано, л</td>
                            <td class="border-all" colspan="2">остаток при</td>
                            <td class="border-all" rowspan="2">сдано</td>
                            <td class="border-all" rowspan="2" colspan="2">
                                коэффициент <br/>изменения<br/>нормы
                            </td>
                        </tr>
                        <tr>
                            <td class="border-all">марка</td>
                            <td class="border-all">код марки</td>
                            <td class="border-all">выезд, л</td>
                            <td class="border-all">
                                возвра-<br/>щении, л
                            </td>
                            <td class="border-all" colspan="2">
                                оборудо-<br/>вания
                            </td>
                            <td class="border-all">двигателя</td>
                        </tr>
                        <tr>
                            <td class="border-all">9</td>
                            <td class="border-all">10</td>
                            <td class="border-all">11</td>
                            <td class="border-all">12</td>
                            <td class="border-all">13</td>
                            <td class="border-all">14</td>
                            <td class="border-all" colspan="2">15</td>
                            <td class="border-all" colspan="2">16</td>
                            <td class="border-all">17</td>
                        </tr>
                        <tr>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all btx2 blx2"></td>
                            <td class="border-all btx2">${_.isNumber(waybill.fuel_issued) ? waybill.fuel_issued : ''}</td>
                            <td class="border-all btx2">${_.isNumber(waybill.departure_fuel_level) ? waybill.departure_fuel_level : ''}</td>
                            <td class="border-all btx2">${_.isNumber(waybill.arrival_fuel_level) ? waybill.arrival_fuel_level : ''}</td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2" colspan="2"></td>
                            <td class="border-all btx2" colspan="2"></td>
                            <td class="border-all btx2 brx2">${waybill.motohours || ''}</td>
                        </tr>
                        <tr>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all blx2 bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2" colspan="2"></td>
                            <td class="border-all bbx2" colspan="2"></td>
                            <td class="border-all bbx2 brx2"></td>
                        </tr>
                        <tr>
                            <td class="border-all text-uppercase" colspan="11">подпись</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="2">заправщика</td>
                            <td class="border-all">механика</td>
                            <td class="border-all" colspan="2">механика</td>
                            <td class="border-all" colspan="3">заправщика</td>
                            <td class="border-all" colspan="3">диспетчера</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="2">&nbsp;</td>
                            <td class="border-all"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="3"></td>
                            <td class="border-all" colspan="3"></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col col_half indent">
                    <table>
                        <tr>
                            <td class="border-all" colspan="7" rowspan="2">в чье распоряжение (наименование и
                                адрес заказчика)
                            </td>
                            <td class="border-all" colspan="2">время, ч.мин</td>
                            <td class="border-all" colspan="2">количество</td>
                        </tr>
                        <tr>
                            <td class="border-all">прибытие</td>
                            <td class="border-all">убытие</td>
                            <td class="border-all">часов</td>
                            <td class="border-all">ездок</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="7">18</td>
                            <td class="border-all">19</td>
                            <td class="border-all">20</td>
                            <td class="border-all">21</td>
                            <td class="border-all">22</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="7"></td>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="7"></td>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left" colspan="9">Водительское удостоверение проверил,
                            </td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left" colspan="6">задание выдал, выдать горючего</td>
                            <td class="border-none bb" colspan="3"></td>
                            <td class="border-none">литр</td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left">Диспетчер</td>
                            <td class="border-none bb" colspan="4"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="3"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left"></td>
                            <td class="border-none" colspan="4">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="3">(расшифровка)</td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left" colspan="6">Водитель по состоянию здоровья</td>
                            <td class="border-none" colspan="5"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left" colspan="6">к управлению допущен</td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left" colspan="6"></td>
                            <td class="border-none">(должность)</td>
                            <td class="border-none"></td>
                            <td class="border-none">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none">(расшифровка)</td>
                        </tr>
                    </table>
                </div>
                <div class="col col_half">
                    <table>
                        <tr>
                            <td class="border-none text-left w30" colspan="3">Автомобиль технически исправен
                            </td>
                            <td class="border-none bb w20" colspan="3"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none w10">Особые отметки</td>
                            <td class="border-none bb w30" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w20">Выезд разрешен</td>
                            <td class="border-none"></td>
                            <td class="border-none">Механик</td>
                            <td class="border-none bb w10"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none bb w20" colspan="2"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none bb w30" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w20"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none w10">(подпись)</td>
                            <td class="border-none w10"></td>
                            <td class="border-none w20" colspan="2">(расшифровка)</td>
                            <td class="border-none w10"></td>
                            <td class="border-none bb w30" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w20">Автомобиль принял</td>
                            <td class="border-none"></td>
                            <td class="border-none">Водитель</td>
                            <td class="border-none bb w10"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none bb w20" colspan="2"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none bb w30" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w20"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none w10">(подпись)</td>
                            <td class="border-none w10"></td>
                            <td class="border-none w20" colspan="2">(расшифровка)</td>
                            <td class="border-none w10"></td>
                            <td class="border-none bb w30" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left">При возвращении автомобиль</td>
                            <td class="border-none"></td>
                            <td class="border-none bb">исправен</td>
                            <td class="border-none" colspan="5"></td>
                            <td class="border-none bb" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none">неисправен</td>
                            <td class="border-none" colspan="5"></td>
                            <td class="border-none" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left">Сдал водитель</td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="2"></td>
                            <td class="border-none"></td>
                            <td class="border-none text-center">Принял механик</td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left"></td>
                            <td class="border-none">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="2">(расшифровка)</td>
                            <td class="border-none"></td>
                            <td class="border-none text-left"></td>
                            <td class="border-none">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="2">(расшифровка)</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row print">
                <div class="fsn text-uppercase tac bb-x2">Линия отреза</div>
                <div class="col col_half indent">
                    <table>
                        <tr>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left border-right" colspan="22"><span
                                class="text-uppercase">талон второго заказчика</span>(заполняется в организации
                                - владельце автотранспорта)
                            </td>
                        </tr>
                        <tr>
                            <td class="border-none text-left border-left" colspan="2">
                                К путевому <br/>листу
                            </td>
                            <td class="border-none bb" colspan="3"></td>
                            <td class="border-none">№</td>
                            <td class="border-none bb" colspan="2"></td>
                            <td class="border-none">от</td>
                            <td class="border-none text-right">«</td>
                            <td class="border-none bb" colspan="2"></td>
                            <td class="border-none text-left">»</td>
                            <td class="border-none bb" colspan="4"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="3"></td>
                            <td class="border-none border-right">г.</td>
                        </tr>
                        <tr>
                            <td class="border-none border-left border-right" colspan="22"></td>
                        </tr>
                        <tr>
                            <td class="border-all" rowspan="3" colspan="2">Результат по второму заказчику</td>
                            <td class="border-all" rowspan="2" colspan="2">Время оплачиваемое</td>
                            <td class="border-all" rowspan="2" colspan="2">Ездки</td>
                            <td class="border-all" rowspan="2" colspan="2">Экспедитор</td>
                            <td class="border-all" colspan="4">Пробег</td>
                            <td class="border-all" rowspan="2" colspan="2">Перепробег</td>
                            <td class="border-all" rowspan="2" colspan="2">Тонны</td>
                            <td class="border-all" rowspan="2" colspan="2">Т-км</td>
                            <td class="border-all" rowspan="2" colspan="2"></td>
                            <td class="border-all" rowspan="2" colspan="2">Всего к оплате</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="2">всего</td>
                            <td class="border-all" colspan="2">с грузом</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="2">33</td>
                            <td class="border-all" colspan="2">34</td>
                            <td class="border-all" colspan="2">35</td>
                            <td class="border-all" colspan="2">36</td>
                            <td class="border-all" colspan="2">37</td>
                            <td class="border-all" colspan="2">38</td>
                            <td class="border-all" colspan="2">39</td>
                            <td class="border-all" colspan="2">40</td>
                            <td class="border-all" colspan="2">41</td>
                            <td class="border-all" colspan="2">42</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="2">Единица измерения</td>
                            <td class="border-all" colspan="2">ч. мин.</td>
                            <td class="border-all" colspan="2">ед.</td>
                            <td class="border-all" colspan="2">да/нет</td>
                            <td class="border-all" colspan="2">км</td>
                            <td class="border-all" colspan="2">км</td>
                            <td class="border-all" colspan="2">км</td>
                            <td class="border-all" colspan="2">т</td>
                            <td class="border-all" colspan="2">ткм</td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2">руб. коп.</td>
                        </tr>
                        <tr>
                            <td class="border-all text-left" colspan="2">Выполнено</td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all text-uppercase" colspan="2">x</td>
                        </tr>
                        <tr>
                            <td class="border-all text-left" colspan="2">Тариф, руб.коп</td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all text-uppercase" colspan="2">x</td>
                        </tr>
                        <tr>
                            <td class="border-all text-left" colspan="2">К оплате, руб.коп</td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                        </tr>
                    </table>
                </div>
                <div class="col col_half">
                    <table>
                        <tr>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left border-right" colspan="22"><span
                                class="text-uppercase">талон первого заказчика</span>(заполняется в организации
                                - владельце автотранспорта)
                            </td>
                        </tr>
                        <tr>
                            <td class="border-none text-left border-left" colspan="2">
                                К путевому <br/>листу
                            </td>
                            <td class="border-none bb" colspan="3"></td>
                            <td class="border-none">№</td>
                            <td class="border-none bb" colspan="2"></td>
                            <td class="border-none">от</td>
                            <td class="border-none text-right">«</td>
                            <td class="border-none bb" colspan="2"></td>
                            <td class="border-none text-left">»</td>
                            <td class="border-none bb" colspan="4"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="3"></td>
                            <td class="border-none border-right">г.</td>
                        </tr>
                        <tr>
                            <td class="border-none border-left border-right" colspan="22"></td>
                        </tr>
                        <tr>
                            <td class="border-all" rowspan="3" colspan="2">Результат по второму заказчику</td>
                            <td class="border-all" rowspan="2" colspan="2">Время оплачиваемое</td>
                            <td class="border-all" rowspan="2" colspan="2">Ездки</td>
                            <td class="border-all" rowspan="2" colspan="2">Экспедитор</td>
                            <td class="border-all" colspan="4">Пробег</td>
                            <td class="border-all" rowspan="2" colspan="2">Перепробег</td>
                            <td class="border-all" rowspan="2" colspan="2">Тонны</td>
                            <td class="border-all" rowspan="2" colspan="2">Т-км</td>
                            <td class="border-all" rowspan="2" colspan="2"></td>
                            <td class="border-all" rowspan="2" colspan="2">Всего к оплате</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="2">всего</td>
                            <td class="border-all" colspan="2">с грузом</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="2">43</td>
                            <td class="border-all" colspan="2">44</td>
                            <td class="border-all" colspan="2">45</td>
                            <td class="border-all" colspan="2">46</td>
                            <td class="border-all" colspan="2">47</td>
                            <td class="border-all" colspan="2">48</td>
                            <td class="border-all" colspan="2">49</td>
                            <td class="border-all" colspan="2">50</td>
                            <td class="border-all" colspan="2">51</td>
                            <td class="border-all" colspan="2">52</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="2">Единица измерения</td>
                            <td class="border-all" colspan="2">ч. мин.</td>
                            <td class="border-all" colspan="2">ед.</td>
                            <td class="border-all" colspan="2">да/нет</td>
                            <td class="border-all" colspan="2">км</td>
                            <td class="border-all" colspan="2">км</td>
                            <td class="border-all" colspan="2">км</td>
                            <td class="border-all" colspan="2">т</td>
                            <td class="border-all" colspan="2">ткм</td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2">руб. коп.</td>
                        </tr>
                        <tr>
                            <td class="border-all text-left" colspan="2">Выполнено</td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all text-uppercase" colspan="2">x</td>
                        </tr>
                        <tr>
                            <td class="border-all text-left" colspan="2">Тариф, руб.коп</td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all text-uppercase" colspan="2">x</td>
                        </tr>
                        <tr>
                            <td class="border-all text-left" colspan="2">К оплате, руб.коп</td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="fsn text-uppercase tar">Оборотная сторона формы №4-П</div>
                <div class="row">
                    <div class="div160 fsn text-uppercase tar">Выполнение задания</div>
                    <div class="div160 fsn tac">(заполняется заказчиком)</div>
                </div>
                <div class="col col_half indent">
                    <table>
                        <tr>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left text-left" colspan="16">Первый
                                заказчик:
                            </td>
                            <td class="border-none border-top" colspan="3">код</td>
                            <td class="border-none blx2 btx2 brx2 w5" colspan="3">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left text-left" colspan="7"></td>
                            <td class="border-none text-left tooltip" colspan="4">(наименование)</td>
                            <td class="border-none text-right">показание спидометра</td>
                            <td class="border-none text-left btx2 blx2" colspan="4"></td>
                            <td class="border-none text-left btx2" colspan="3"></td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="7">При прибытии к
                                заказчика:
                            </td>
                            <td class="border-none text-right" colspan="5">дата(число, месяц), время, ч.мин</td>
                            <td class="border-none border-top text-left blx2" colspan="4"></td>
                            <td class="border-none border-top text-left" colspan="3"></td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left text-left" colspan="7"></td>
                            <td class="border-none border-top text-left" colspan="4"></td>
                            <td class="border-none border-top text-right">показание спидометра</td>
                            <td class="border-none border-top text-left blx2" colspan="4"></td>
                            <td class="border-none border-top text-left" colspan="3"></td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="7">При убытии от заказчика:
                            </td>
                            <td class="border-none text-right" colspan="5">дата(число, месяц), время, ч.мин</td>
                            <td class="border-none border-top text-left blx2" colspan="4"></td>
                            <td class="border-none border-top text-left" colspan="3"></td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left text-left" colspan="12"></td>
                            <td class="border-none border-top text-right btx2 brx2" colspan="7">количество</td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="7">Приложенные ТТД: №№</td>
                            <td class="border-none text-right brx2" colspan="12">ТТД</td>
                            <td class="border-none brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left text-left" colspan="12">Маршрут
                                движения
                            </td>
                            <td class="border-none border-top text-right brx2" colspan="7">количество</td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="7">(откуда-куда):</td>
                            <td class="border-none text-right brx2" colspan="12">ездок</td>
                            <td class="border-none brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="12"></td>
                            <td class="border-none text-right brx2" colspan="7">код вида</td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-bottom border-left text-left" colspan="7">Место для
                                штампа Заказчика
                            </td>
                            <td class="border-none border-bottom text-right brx2" colspan="12">груза</td>
                            <td class="border-none brx2 bbx2 text-left" colspan="3"></td>
                        </tr>
                    </table>
                </div>
                <div class="col col_half">
                    <table>
                        <tr>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left text-left" colspan="16">Второй
                                заказчик:
                            </td>
                            <td class="border-none border-top" colspan="3">код</td>
                            <td class="border-none blx2 btx2 brx2 w5" colspan="3">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left text-left" colspan="7"></td>
                            <td class="border-none text-left tooltip" colspan="4">(наименование)</td>
                            <td class="border-none text-right">показание спидометра</td>
                            <td class="border-none text-left btx2 blx2" colspan="4"></td>
                            <td class="border-none text-left btx2" colspan="3"></td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="7">При прибытии к
                                заказчика:
                            </td>
                            <td class="border-none text-right" colspan="5">дата(число, месяц), время, ч.мин</td>
                            <td class="border-none border-top text-left blx2" colspan="4"></td>
                            <td class="border-none border-top text-left" colspan="3"></td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left text-left" colspan="7"></td>
                            <td class="border-none border-top text-left" colspan="4"></td>
                            <td class="border-none border-top text-right">показание спидометра</td>
                            <td class="border-none border-top text-left blx2" colspan="4"></td>
                            <td class="border-none border-top text-left" colspan="3"></td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="7">При убытии от заказчика:
                            </td>
                            <td class="border-none text-right" colspan="5">дата(число, месяц), время, ч.мин</td>
                            <td class="border-none border-top text-left blx2" colspan="4"></td>
                            <td class="border-none border-top text-left" colspan="3"></td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left text-left" colspan="12"></td>
                            <td class="border-none border-top text-right btx2 brx2" colspan="7">количество</td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="7">Приложенные ТТД: №№</td>
                            <td class="border-none text-right brx2" colspan="12">ТТД</td>
                            <td class="border-none brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left text-left" colspan="12">Маршрут
                                движения
                            </td>
                            <td class="border-none border-top text-right brx2" colspan="7">количество</td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="7">(откуда-куда):</td>
                            <td class="border-none text-right brx2" colspan="12">ездок</td>
                            <td class="border-none brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="12"></td>
                            <td class="border-none text-right brx2" colspan="7">код вида</td>
                            <td class="border-none border-top brx2 text-left" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-bottom border-left text-left" colspan="7">Место для
                                штампа Заказчика
                            </td>
                            <td class="border-none border-bottom text-right brx2" colspan="12">груза</td>
                            <td class="border-none brx2 bbx2 text-left" colspan="3"></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col col_half indent">
                    <table>
                        <tr>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="20">Простои на линии</td>
                            <td class="border-none border-all" rowspan="3" colspan="2">Подпись ответственного
                                лица
                            </td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="10">причина</td>
                            <td class="border-none border-all" colspan="10">дата(число, месяц), время, ч.мин
                            </td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="5">наименование</td>
                            <td class="border-none border-all" colspan="5">код</td>
                            <td class="border-none border-all" colspan="5">начало</td>
                            <td class="border-none border-all" colspan="5">окончание</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="5">23</td>
                            <td class="border-none border-all" colspan="5">24</td>
                            <td class="border-none border-all" colspan="5">25</td>
                            <td class="border-none border-all" colspan="5">26</td>
                            <td class="border-none border-all" colspan="2">27</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="5">&nbsp;</td>
                            <td class="border-none border-all btx2 blx2" colspan="5">&nbsp;</td>
                            <td class="border-none border-all btx2" colspan="5">&nbsp;</td>
                            <td class="border-none border-all btx2 brx2" colspan="5">&nbsp;</td>
                            <td class="border-none border-all" colspan="2">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="5">&nbsp;</td>
                            <td class="border-none border-all blx2" colspan="5">&nbsp;</td>
                            <td class="border-none border-all" colspan="5">&nbsp;</td>
                            <td class="border-none border-all brx2" colspan="5">&nbsp;</td>
                            <td class="border-none border-all" colspan="2">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="5">&nbsp;</td>
                            <td class="border-none border-all bbx2 blx2" colspan="5">&nbsp;</td>
                            <td class="border-none border-all bbx2" colspan="5">&nbsp;</td>
                            <td class="border-none border-all bbx2 brx2" colspan="5">&nbsp;</td>
                            <td class="border-none border-all" colspan="2">&nbsp;</td>
                        </tr>
                    </table>
                </div>
                <div class="col col_half">
                    <table>
                        <tr>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left" colspan="15">Таксировка</td>
                            <td class="border-none"></td>
                            <td class="border-none border-all" colspan="3">Расход горючего</td>
                            <td class="border-none"></td>
                            <td class="border-none border-all" colspan="2">Зарплата</td>
                        </tr>
                        <tr>
                            <td class="border-none bb" colspan="15"></td>
                            <td class="border-none"></td>
                            <td class="border-none border-all">марка</td>
                            <td class="border-none border-all">по норме</td>
                            <td class="border-none border-all">фактически</td>
                            <td class="border-none"></td>
                            <td class="border-none border-all">код</td>
                            <td class="border-none border-all">сумма, руб.коп</td>
                        </tr>
                        <tr>
                            <td class="border-none bb" colspan="15"></td>
                            <td class="border-none"></td>
                            <td class="border-none border-all">28</td>
                            <td class="border-none border-all">29</td>
                            <td class="border-none border-all">30</td>
                            <td class="border-none"></td>
                            <td class="border-none border-all">31</td>
                            <td class="border-none border-all">32</td>
                        </tr>
                        <tr>
                            <td class="border-none bb" colspan="15"></td>
                            <td class="border-none"></td>
                            <td class="border-none border-all btx2 blx2">&nbsp;</td>
                            <td class="border-none border-all btx2">&nbsp;</td>
                            <td class="border-none border-all btx2 brx2">&nbsp;</td>
                            <td class="border-none"></td>
                            <td class="border-none border-all btx2 blx2">&nbsp;</td>
                            <td class="border-none border-all btx2 brx2">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none bb" colspan="15"></td>
                            <td class="border-none"></td>
                            <td class="border-none border-all bbx2 blx2">&nbsp;&nbsp;</td>
                            <td class="border-none border-all bbx2">&nbsp;&nbsp;</td>
                            <td class="border-none border-all bbx2 brx2">&nbsp;</td>
                            <td class="border-none"></td>
                            <td class="border-none border-all blx2">&nbsp;</td>
                            <td class="border-none border-all brx2">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none bb" colspan="16"></td>
                            <td class="border-none bb">&nbsp;</td>
                            <td class="border-none bb">&nbsp;</td>
                            <td class="border-none bb">&nbsp;</td>
                            <td class="border-none"></td>
                            <td class="border-none border-all blx2">&nbsp;</td>
                            <td class="border-none border-all brx2">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none bb" colspan="16"></td>
                            <td class="border-none bb">&nbsp;</td>
                            <td class="border-none bb">&nbsp;</td>
                            <td class="border-none bb">&nbsp;</td>
                            <td class="border-none"></td>
                            <td class="border-none border-all bbx2 blx2">&nbsp;</td>
                            <td class="border-none border-all bbx2 brx2">&nbsp;</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col col_half indent">
                    <table>
                        <tr>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" rowspan="2" colspan="2">Результат по первому
                                заказчику
                            </td>
                            <td class="border-none border-all" colspan="2">Время<br/>оплачиваемое</td>
                            <td class="border-none border-all" colspan="2">Ездки</td>
                            <td class="border-none border-all" colspan="2">Экспедитор</td>
                            <td class="border-none border-all" colspan="4">Пробег</td>
                            <td class="border-none border-all" colspan="2">Перепробег</td>
                            <td class="border-none border-all" colspan="2">Тонны</td>
                            <td class="border-none border-all" colspan="2">Т-км</td>
                            <td class="border-none border-all" colspan="2">&nbsp;</td>
                            <td class="border-none border-all" colspan="2">Всего к оплате</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="2">33</td>
                            <td class="border-none border-all" colspan="2">34</td>
                            <td class="border-none border-all" colspan="2">35</td>
                            <td class="border-none border-all" colspan="2">36</td>
                            <td class="border-none border-all" colspan="2">37</td>
                            <td class="border-none border-all" colspan="2">38</td>
                            <td class="border-none border-all" colspan="2">39</td>
                            <td class="border-none border-all" colspan="2">40</td>
                            <td class="border-none border-all" colspan="2">41</td>
                            <td class="border-none border-all" colspan="2">42</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="2">Единица измерения</td>
                            <td class="border-none border-all" colspan="2">ч.мин</td>
                            <td class="border-none border-all" colspan="2">ед.</td>
                            <td class="border-none border-all" colspan="2">да/нет</td>
                            <td class="border-none border-all" colspan="2">км</td>
                            <td class="border-none border-all" colspan="2">км</td>
                            <td class="border-none border-all" colspan="2">км</td>
                            <td class="border-none border-all" colspan="2">т</td>
                            <td class="border-none border-all" colspan="2">ткм</td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2">руб.коп</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="2">Выполнено</td>
                            <td class="border-none border-all blx2 btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all text-uppercase brx2 btx2" colspan="2">x</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="2">Тариф, руб.коп.</td>
                            <td class="border-none border-all blx2" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all text-uppercase brx2" colspan="2">x</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="2">К оплате, руб.коп</td>
                            <td class="border-none border-all blx2 bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all text-uppercase brx2 bbx2" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left">Таксировщик</td>
                            <td class="border-none bb" colspan="2"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="3"></td>
                            <td class="border-none" colspan="2"></td>
                            <td class="border-none text-left" colspan="6">Начальник эксплуатации</td>
                            <td class="border-none bb" colspan="2"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="4"></td>
                        </tr>
                        <tr>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="2">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="3">(расшифровка)</td>
                            <td class="border-none" colspan="2"></td>
                            <td class="border-none" colspan="6"></td>
                            <td class="border-none" colspan="2">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="4"> (расшифровка)</td>
                        </tr>
                    </table>
                </div>
                <div class="col col_half">
                    <table>
                        <tr>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" rowspan="2" colspan="2">Результат по второму
                                заказчику
                            </td>
                            <td class="border-none border-all" colspan="2">Время<br/>оплачиваемое</td>
                            <td class="border-none border-all" colspan="2">Ездки</td>
                            <td class="border-none border-all" colspan="2">Экспедитор</td>
                            <td class="border-none border-all" colspan="4">Пробег</td>
                            <td class="border-none border-all" colspan="2">Перепробег</td>
                            <td class="border-none border-all" colspan="2">Тонны</td>
                            <td class="border-none border-all" colspan="2">Т-км</td>
                            <td class="border-none border-all" colspan="2">&nbsp;</td>
                            <td class="border-none border-all" colspan="2">Всего к оплате</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="2">43</td>
                            <td class="border-none border-all" colspan="2">44</td>
                            <td class="border-none border-all" colspan="2">45</td>
                            <td class="border-none border-all" colspan="2">46</td>
                            <td class="border-none border-all" colspan="2">47</td>
                            <td class="border-none border-all" colspan="2">48</td>
                            <td class="border-none border-all" colspan="2">49</td>
                            <td class="border-none border-all" colspan="2">50</td>
                            <td class="border-none border-all" colspan="2">51</td>
                            <td class="border-none border-all" colspan="2">52</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="2">Единица измерения</td>
                            <td class="border-none border-all" colspan="2">ч.мин</td>
                            <td class="border-none border-all" colspan="2">ед.</td>
                            <td class="border-none border-all" colspan="2">да/нет</td>
                            <td class="border-none border-all" colspan="2">км</td>
                            <td class="border-none border-all" colspan="2">км</td>
                            <td class="border-none border-all" colspan="2">км</td>
                            <td class="border-none border-all" colspan="2">т</td>
                            <td class="border-none border-all" colspan="2">ткм</td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2">руб.коп</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="2">Выполнено</td>
                            <td class="border-none border-all blx2 btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all btx2" colspan="2"></td>
                            <td class="border-none border-all text-uppercase brx2 btx2" colspan="2">x</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="2">Тариф, руб.коп.</td>
                            <td class="border-none border-all blx2" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all" colspan="2"></td>
                            <td class="border-none border-all text-uppercase brx2" colspan="2">x</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all" colspan="2">К оплате, руб.коп</td>
                            <td class="border-none border-all blx2 bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all bbx2" colspan="2"></td>
                            <td class="border-none border-all text-uppercase brx2 bbx2" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left">Таксировщик</td>
                            <td class="border-none bb" colspan="2"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="3"></td>
                            <td class="border-none" colspan="2"></td>
                            <td class="border-none text-left" colspan="6">Начальник эксплуатации</td>
                            <td class="border-none bb" colspan="2"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="4"></td>
                        </tr>
                        <tr>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="2">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="3">(расшифровка)</td>
                            <td class="border-none" colspan="2"></td>
                            <td class="border-none" colspan="6"></td>
                            <td class="border-none" colspan="2">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="4"> (расшифровка)</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="fsn text-uppercase tac bb-x2">Линия отреза</div>
                <div class="col col_half indent">
                    <table>
                        <tr>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left" colspan="17"></td>
                            <td class="border-none border-top border-right text-right" colspan="5">(заполняется
                                заказчиком)
                            </td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Организация</td>
                            <td class="border-none text-right bb" colspan="16"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5"></td>
                            <td class="border-none text-center tooltip" colspan="16">(наименование)</td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Марка автомобиля</td>
                            <td class="border-none bb" colspan="5"></td>
                            <td class="border-none" colspan="5">Государственный номерной знак</td>
                            <td class="border-none bb" colspan="6"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Прицепы</td>
                            <td class="border-none bb" colspan="5"></td>
                            <td class="border-none" colspan="5">Государственный номерной знак</td>
                            <td class="border-none bb" colspan="6"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Первый заказчик</td>
                            <td class="border-none text-right bb" colspan="16"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5"></td>
                            <td class="border-none text-center tooltip" colspan="16">(наименование организации,
                                фамилия, имя, отчество, должность ответственного лица)
                            </td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Прибытие к заказчику</td>
                            <td class="border-none bb" colspan="7"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="7"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5"></td>
                            <td class="border-none tooltip" colspan="7">(дата, время, ч.мин)</td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none tooltip" colspan="7">(показание спидометра)</td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Убытие от заказчика</td>
                            <td class="border-none bb" colspan="7"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="7"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5"></td>
                            <td class="border-none tooltip" colspan="7">(дата, время, ч.мин)</td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none tooltip" colspan="7">(показание спидометра)</td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Приложенные ТТД: №№</td>
                            <td class="border-none text-right bb" colspan="16"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="3">Количество ТТД</td>
                            <td class="border-all" colspan="3">&nbsp;</td>
                            <td class="border-all" colspan="3">Количество ездок</td>
                            <td class="border-all" colspan="3">&nbsp;</td>
                            <td class="border-all" colspan="3">Экспедитор</td>
                            <td class="border-all" colspan="3">да/нет</td>
                            <td class="border-all" colspan="4">Место для штампа Заказчика</td>
                        </tr>
                    </table>
                </div>
                <div class="col col_half">
                    <table>
                        <tr>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-top border-left" colspan="17"></td>
                            <td class="border-none border-top border-right text-right" colspan="5">(заполняется
                                заказчиком)
                            </td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Организация</td>
                            <td class="border-none text-right bb" colspan="16"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5"></td>
                            <td class="border-none text-center tooltip" colspan="16">(наименование)</td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Марка автомобиля</td>
                            <td class="border-none bb" colspan="5"></td>
                            <td class="border-none" colspan="5">Государственный номерной знак</td>
                            <td class="border-none bb" colspan="6"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Прицепы</td>
                            <td class="border-none bb" colspan="5"></td>
                            <td class="border-none" colspan="5">Государственный номерной знак</td>
                            <td class="border-none bb" colspan="6"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Первый заказчик</td>
                            <td class="border-none text-right bb" colspan="16"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5"></td>
                            <td class="border-none text-center tooltip" colspan="16">(наименование организации,
                                фамилия, имя, отчество, должность ответственного лица)
                            </td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Прибытие к заказчику</td>
                            <td class="border-none bb" colspan="7"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="7"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5"></td>
                            <td class="border-none tooltip" colspan="7">(дата, время, ч.мин)</td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none tooltip" colspan="7">(показание спидометра)</td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Убытие от заказчика</td>
                            <td class="border-none bb" colspan="7"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="7"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5"></td>
                            <td class="border-none tooltip" colspan="7">(дата, время, ч.мин)</td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none tooltip" colspan="7">(показание спидометра)</td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-none border-left text-left" colspan="5">Приложенные ТТД: №№</td>
                            <td class="border-none text-right bb" colspan="16"></td>
                            <td class="border-none border-right"></td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="3">Количество ТТД</td>
                            <td class="border-all" colspan="3">&nbsp;</td>
                            <td class="border-all" colspan="3">Количество ездок</td>
                            <td class="border-all" colspan="3">&nbsp;</td>
                            <td class="border-all" colspan="3">Экспедитор</td>
                            <td class="border-all" colspan="3">да/нет</td>
                            <td class="border-all" colspan="4">Место для штампа Заказчика</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    `;
}

export function printWaybillS({waybill, unit, vehicle, vehicle_marks, driver, trailer}) {
    waybill = waybill || {};
    unit = unit || {};
    vehicle = vehicle || {};

    const date = moment(waybill.date);

    const departure = moment(moment(waybill.departure_date).format(formats.DATE) + ' ' + waybill.departure_time, formats.DATETIME_SHORT);
    const arrival = moment(moment(waybill.arrival_date).format(formats.DATE) + ' ' + waybill.arrival_time, formats.DATETIME_SHORT);

    return `
        <div>
            <link rel="stylesheet" href="/css/waybill_print.css"/>
            <div class="row">
                <div class="col col_full">
                    <table>
                        <tr>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                            <td class="border-none">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="5"></td>
                            <td class="border-none text-center font-bold text-uppercase" colspan="13">Путевой
                                лист
                            </td>
                            <td class="border-none text-right" colspan="5">
                                Типовая межотраслевая форма №4-С<br/>Утверждена постановлением Госкомстата
                                России<br/>от
                                28.11.97 №78
                            </td>
                        </tr>
                        <tr>
                            <td class="border-none font-normal text-center" colspan="3">Место для штампа<br/>организации
                            </td>
                            <td class="border-none text-right font-bold vab" colspan="4">грузового автомобиля
                            </td>
                            <td class="border-none"></td>
                            <td class="border-none border-bottom" colspan="3"></td>
                            <td class="border-none font-bold vab">№</td>
                            <td class="border-none border-bottom" colspan="3">${waybill.number || ''}</td>
                            <td class="border-none" colspan="8"></td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="8"></td>
                            <td class="border-none text-center font-normal" colspan="3">(серия)</td>
                            <td class="border-none" colspan="12"></td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="21"></td>
                            <td class="border-all w5" colspan="2">Коды</td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="6"></td>
                            <td class="border-none text-right">«</td>
                            <td class="border-none border-bottom">${date.format('D') || ''}</td>
                            <td class="border-none text-left">»</td>
                            <td class="border-none border-bottom"
                                colspan="3">${months[date.format('M') - 1] || ''}</td>
                            <td class="border-none"></td>
                            <td class="border-none border-bottom text-right">${date.format('YYYY') || ''}г.</td>
                            <td class="border-none text-right" colspan="7">Форма по ОКУД</td>
                            <td class="blx2 btx2 brx2 w5" colspan="2">0345005</td>
                        </tr>
                        <tr>
                            <td class="border-none text-left" colspan="2">Организация</td>
                            <td class="border-none border-bottom"
                                colspan="17">${unit.name_full || ''}, ${unit.legal_address || ''}, ${unit.phone || ''}, ${unit.ogrn || ''}</td>
                            <td class="border-none text-right" colspan="2">по ОКПО</td>
                            <td class="blx2 bbx2 brx2 w5" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left" colspan="2"></td>
                            <td class="border-none font-normal" colspan="17">(наименование, адрес и номер
                                телефона)
                            </td>
                            <td class="border-none text-right" colspan="2"></td>
                            <td class="border-none" colspan="2"></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col col_half indent">
                    <table>
                        <tr>
                            <td class="border-none text-right w90" colspan="10"></td>
                            <td class="border-all w10">Коды</td>
                        </tr>
                        <tr>
                            <td class="border-none text-right w90" colspan="10">Режим работы</td>
                            <td class="border-all blx2 btx2 brx2 w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-right w90" colspan="10">Колонна</td>
                            <td class="border-all blx2 btx2 brx2 w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-right w90" colspan="10">Бригада</td>
                            <td class="border-all blx2 btx2 brx2 w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Марка автомобиля</td>
                            <td class="border-none bb w50" colspan="8">${_.get(_.find(vehicle_marks, {value: vehicle.vehicle_mark_uuid}), 'label') || ''}</td>
                            <td class="border-none w10"></td>
                            <td class="border-all blx2 btx2 brx2 w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Государственный номерной знак</td>
                            <td class="border-none bb w50" colspan="8">${vehicle.state_number || ''}</td>
                            <td class="border-none text-right w10">Гаражный номер</td>
                            <td class="border-all blx2 btx2 brx2 w10">${_.get(vehicle, 'garage_number') || ''}</td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Водитель</td>
                            <td class="border-none bb w50" colspan="8">${driver ? (new User(driver)).getFullName() : ''}</td>
                            <td class="border-none text-right w10">Табельный номер</td>
                            <td class="border-all blx2 btx2 brx2 bbx2 w10">${_.get(driver, 'info.personnel_number') || ''}</td>
                        </tr>
                        <tr>
                            <td class="border-none w30"></td>
                            <td class="border-none text-center w50" colspan="8">(фамилия, имя, отчество)</td>
                            <td class="border-none text-right w20" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Удостоверение №</td>
                            <td class="border-none bb w40" colspan="6">${driver ? (new User(driver)).getDriverNumber() : ''}</td>
                            <td class="border-none text-right w10">Класс</td>
                            <td class="border-none bb w20" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-none w100" colspan="11">&nbsp;</td>
                        </tr>
                    </table>
                </div>
                <div class="col col_half">
                    <table>
                        <tr>
                            <td class="text-center text-uppercase border-all" colspan="8">Работа водителя и
                                автомобиля
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center border-all w20" rowspan="3">операция</td>
                            <td class="text-center border-all w20" rowspan="2" colspan="4">время по графику</td>
                            <td class="text-center border-all w20" rowspan="3">нулевой пробег, км</td>
                            <td class="text-center border-all w20" rowspan="3">показание спидометра, км</td>
                            <td class="text-center border-all w20" rowspan="3">время фактическое, число, месяц,
                                ч.мин.
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center text-uppercase border-none" colspan="8"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-all w5">число</td>
                            <td class="text-center border-all w5">месяц</td>
                            <td class="text-center border-all w5">ч.</td>
                            <td class="text-center border-all w5">мин.</td>
                        </tr>
                        <tr>
                            <td class="text-center border-all">1</td>
                            <td class="text-center border-all">2</td>
                            <td class="text-center border-all">3</td>
                            <td class="text-center border-all">4</td>
                            <td class="text-center border-all">5</td>
                            <td class="text-center border-all">6</td>
                            <td class="text-center border-all">7</td>
                            <td class="text-center border-all">8</td>
                        </tr>
                        <tr>
                            <td class="text-center border-all">выезд из гаража</td>
                            <td class="text-center border-all blx2 btx2">${departure.format('DD') || ''}</td>
                            <td class="text-center border-all btx2">${departure.format('MM') || ''}</td>
                            <td class="text-center border-all btx2">${departure.format('HH') || ''}</td>
                            <td class="text-center border-all btx2">${departure.format('mm') || ''}</td>
                            <td class="text-center border-all btx2"></td>
                            <td class="text-center border-all btx2">${waybill.fact_departure_odometer || ''}</td>
                            <td class="text-center border-all btx2 brx2"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-all">возвращение в гараж</td>
                            <td class="text-center border-all blx2 bbx2">${arrival.format('DD') || ''}</td>
                            <td class="text-center border-all bbx2">${arrival.format('MM') || ''}</td>
                            <td class="text-center border-all bbx2">${arrival.format('HH') || ''}</td>
                            <td class="text-center border-all bbx2">${arrival.format('mm') || ''}</td>
                            <td class="text-center border-all bbx2"></td>
                            <td class="text-center border-all bbx2">${waybill.fact_arrival_odometer || ''}</td>
                            <td class="text-center border-all bbx2 brx2">${waybill.fact_arrival_time || ''}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col col_half indent">
                    <table>
                        <tr>
                            <td class="border-none text-left w30">Лицензионная карточка</td>
                            <td class="border-none bb w50" colspan="8">стандартная, ограниченная</td>
                            <td class="border-none w20" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30"></td>
                            <td class="border-none w50" colspan="8">(ненужное зачеркнуть)</td>
                            <td class="border-none w20" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Регистрационный номер</td>
                            <td class="border-none bb w30" colspan="6"></td>
                            <td class="border-none text-right w10">Серия</td>
                            <td class="border-none bb w10"></td>
                            <td class="border-none text-right w10">№</td>
                            <td class="border-none bb w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Прицеп 1</td>
                            <td class="border-none bb w20" colspan="4">${(trailer ? _.get(_.find(vehicle_marks, {value: trailer.vehicle_mark_uuid}), 'label') : '') || ''}</td>
                            <td class="border-none w20" colspan="3">Государственный номерной знак</td>
                            <td class="border-none bb w10">${_.get(trailer, 'state_number') || ''}</td>
                            <td class="border-none w10">Гаражный номер</td>
                            <td class="blx2 brx2 btx2 bbx2 w10">${_.get(trailer, 'garage_number') || ''}</td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30"></td>
                            <td class="border-none w20 tooltip" colspan="4">(марка)</td>
                            <td class="border-none w20" colspan="3"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Прицеп 2</td>
                            <td class="border-none bb w20" colspan="4"></td>
                            <td class="border-none w20" colspan="3">Государственный номерной знак</td>
                            <td class="border-none bb w10"></td>
                            <td class="border-none w10">Гаражный номер</td>
                            <td class="blx2 brx2 btx2 bbx2 w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30"></td>
                            <td class="border-none w20 tooltip" colspan="4">(марка)</td>
                            <td class="border-none w20" colspan="3"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Прицеп 3</td>
                            <td class="border-none bb w20" colspan="4"></td>
                            <td class="border-none w20" colspan="3">Государственный номерной знак</td>
                            <td class="border-none bb w10"></td>
                            <td class="border-none w10">Гаражный номер</td>
                            <td class="blx2 brx2 btx2 bbx2 w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30"></td>
                            <td class="border-none w20 tooltip" colspan="4">(марка)</td>
                            <td class="border-none w20" colspan="3"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Прицеп 4</td>
                            <td class="border-none bb w20" colspan="4"></td>
                            <td class="border-none w20" colspan="3">Государственный номерной знак</td>
                            <td class="border-none bb w10"></td>
                            <td class="border-none w10">Гаражный номер</td>
                            <td class="blx2 brx2 btx2 bbx2 w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30"></td>
                            <td class="border-none w20 tooltip" colspan="4">(марка)</td>
                            <td class="border-none w20" colspan="3"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none w10"></td>
                            <td class="border-none w10"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left w30">Сопровождающие лица</td>
                            <td class="border-none border-bottom w70" colspan="10"></td>
                        </tr>
                        <tr>
                            <td class="border-none w100" colspan="11"></td>
                        </tr>
                    </table>
                </div>
                <div class="col col_half">
                    <table>
                        <tr>
                            <td class="text-center border-all" colspan="8">Движение горючего</td>
                            <td class="text-center border-all" rowspan="2" colspan="3">Время работы, ч.мин</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="2">горючее</td>
                            <td class="border-all" rowspan="2">выдано, л</td>
                            <td class="border-all" colspan="2">остаток при</td>
                            <td class="border-all" rowspan="2">сдано</td>
                            <td class="border-all" rowspan="2" colspan="2">
                                коэффициент <br/>изменения<br/>нормы
                            </td>
                        </tr>
                        <tr>
                            <td class="border-all">марка</td>
                            <td class="border-all">код марки</td>
                            <td class="border-all">выезд, л</td>
                            <td class="border-all">
                                возвра-<br/>щении, л
                            </td>
                            <td class="border-all" colspan="2">
                                оборудо-<br/>вания
                            </td>
                            <td class="border-all">двигателя</td>
                        </tr>
                        <tr>
                            <td class="border-all">9</td>
                            <td class="border-all">10</td>
                            <td class="border-all">11</td>
                            <td class="border-all">12</td>
                            <td class="border-all">13</td>
                            <td class="border-all">14</td>
                            <td class="border-all" colspan="2">15</td>
                            <td class="border-all" colspan="2">16</td>
                            <td class="border-all">17</td>
                        </tr>
                        <tr>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all btx2 blx2"></td>
                            <td class="border-all btx2">${_.isNumber(waybill.fuel_issued) ? waybill.fuel_issued : ''}</td>
                            <td class="border-all btx2">${_.isNumber(waybill.departure_fuel_level) ? waybill.departure_fuel_level : ''}</td>
                            <td class="border-all btx2">${_.isNumber(waybill.arrival_fuel_level) ? waybill.arrival_fuel_level : ''}</td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2" colspan="2"></td>
                            <td class="border-all btx2" colspan="2"></td>
                            <td class="border-all btx2 brx2">${waybill.motohours || ''}</td>
                        </tr>
                        <tr>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all blx2 bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2" colspan="2"></td>
                            <td class="border-all bbx2" colspan="2"></td>
                            <td class="border-all bbx2 brx2"></td>
                        </tr>
                        <tr>
                            <td class="border-all text-uppercase" colspan="11">подпись</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="2">заправщика</td>
                            <td class="border-all">механика</td>
                            <td class="border-all" colspan="2">механика</td>
                            <td class="border-all" colspan="3">заправщика</td>
                            <td class="border-all" colspan="3">диспетчера</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="2">&nbsp;</td>
                            <td class="border-all"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="3"></td>
                            <td class="border-all" colspan="3"></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col col_full">
                    <table>
                        <tr>
                            <td class="border-all text-uppercase" colspan="23">задание водителю</td>
                        </tr>
                        <tr>
                            <td class="border-all" rowspan="2" colspan="3">
                                в чье распоряжение <br/>(наименование и адрес заказчика)
                            </td>
                            <td class="border-all" rowspan="2" colspan="3">время прибытия</td>
                            <td class="border-all w50" colspan="8">адрес пункта</td>
                            <td class="border-all" rowspan="2" colspan="3">наименование груза</td>
                            <td class="border-all" rowspan="2" colspan="2">количество ездок</td>
                            <td class="border-all" rowspan="2" colspan="2">расстояние, км</td>
                            <td class="border-all" rowspan="2" colspan="2">перевезти тонн</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="4">погрузки</td>
                            <td class="border-all" colspan="4">разгрузки</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="3">18</td>
                            <td class="border-all" colspan="3">19</td>
                            <td class="border-all" colspan="4">20</td>
                            <td class="border-all" colspan="4">21</td>
                            <td class="border-all" colspan="3">22</td>
                            <td class="border-all" colspan="2">23</td>
                            <td class="border-all" colspan="2">24</td>
                            <td class="border-all" colspan="2">25</td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="3">&nbsp;</td>
                            <td class="border-all" colspan="3"></td>
                            <td class="border-all" colspan="4"></td>
                            <td class="border-all" colspan="4"></td>
                            <td class="border-all" colspan="3"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="3">&nbsp;</td>
                            <td class="border-all" colspan="3"></td>
                            <td class="border-all" colspan="4"></td>
                            <td class="border-all" colspan="4"></td>
                            <td class="border-all" colspan="3"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="3">&nbsp;</td>
                            <td class="border-all" colspan="3"></td>
                            <td class="border-all" colspan="4"></td>
                            <td class="border-all" colspan="4"></td>
                            <td class="border-all" colspan="3"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="3">&nbsp;</td>
                            <td class="border-all" colspan="3"></td>
                            <td class="border-all" colspan="4"></td>
                            <td class="border-all" colspan="4"></td>
                            <td class="border-all" colspan="3"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-all" colspan="3">&nbsp;</td>
                            <td class="border-all" colspan="3"></td>
                            <td class="border-all" colspan="4"></td>
                            <td class="border-all" colspan="4"></td>
                            <td class="border-all" colspan="3"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                            <td class="border-all" colspan="2"></td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="3">&nbsp;</td>
                            <td class="border-none" colspan="3"></td>
                            <td class="border-none" colspan="4"></td>
                            <td class="border-none" colspan="4"></td>
                            <td class="border-none text-right" colspan="3">Итого</td>
                            <td class="blx2 btx2 bbx2" colspan="2"></td>
                            <td class="blx2 btx2 bbx2" colspan="2"></td>
                            <td class="blx2 btx2 bbx2 brx2" colspan="2"></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row print">
                <div class="col col_full">
                    <table>
                        <tr>
                            <td class="border-none text-left" colspan="23">Водительское удостоверение проверил,
                                задание выдал
                            </td>
                        </tr>
                        <tr>
                            <td class="border-none text-left">выдать горючего</td>
                            <td class="border-none bb" colspan="5"></td>
                            <td class="border-none">литров</td>
                            <td class="border-none"></td>
                            <td class="border-none text-left" colspan="3">Автомобиль технически исправен</td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="5"></td>
                            <td class="border-none" colspan="6"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left"></td>
                            <td class="border-none" colspan="5"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none text-left" colspan="2">Выезд разрешен</td>
                            <td class="border-none font-bold text-left">Механик</td>
                            <td class="border-none"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="3"></td>
                            <td class="border-none"></td>
                            <td class="border-none">Отметки организации-владельца</td>
                            <td class="border-none" colspan="4"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left"></td>
                            <td class="border-none" colspan="5"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none text-left" colspan="2"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="3">(расшифровка)</td>
                            <td class="border-none"></td>
                            <td class="border-none">авторанспорта</td>
                            <td class="border-none" colspan="4"></td>
                        </tr>
                        <tr>
                            <td class="border-none font-bold text-left">Диспетчер</td>
                            <td class="border-none bb" colspan="2"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="2"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none text-left" colspan="2">Автомобиль принял</td>
                            <td class="border-none font-bold text-left">Водитель</td>
                            <td class="border-none"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="3"></td>
                            <td class="border-none"></td>
                            <td class="border-none">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </td>
                            <td class="border-none" colspan="4"></td>
                        </tr>
                        <tr>
                            <td class="border-none font-bold text-left"></td>
                            <td class="border-none" colspan="2">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="2">(расшифровка)</td>
                            <td class="border-none" colspan="6"></td>
                            <td class="border-none">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="3">(расшифровка)</td>
                            <td class="border-none"></td>
                            <td class="border-none bb">&nbsp;</td>
                            <td class="border-none bb" colspan="4"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left" colspan="23">Водитель по состоянию</td>
                        </tr>
                        <tr>
                            <td class="border-none text-left" colspan="18">здоровья к управлению</td>
                            <td class="border-none text-left bb" colspan="5"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left">допущен</td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none text-left" rowspan="2" colspan="5">При возвращении
                                автомобиль
                            </td>
                            <td class="border-none bb">исправен</td>
                            <td class="border-none" colspan="9"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left"></td>
                            <td class="border-none">(должность)</td>
                            <td class="border-none"></td>
                            <td class="border-none">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none">(расшифровка)</td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none">неисправен</td>
                            <td class="border-none" colspan="4"></td>
                            <td class="border-none bb" colspan="5"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left"></td>
                            <td class="border-none" colspan="5"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none text-left" colspan="2">Сдал</td>
                            <td class="border-none font-bold text-left">Водитель</td>
                            <td class="border-none"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="3"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="4"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left"></td>
                            <td class="border-none" colspan="5"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none text-left" colspan="2"></td>
                            <td class="border-none font-bold text-left"></td>
                            <td class="border-none"></td>
                            <td class="border-none">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="3">(расшифровка)</td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="5"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-left"></td>
                            <td class="border-none" colspan="5"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none text-left" colspan="2">Принял</td>
                            <td class="border-none font-bold text-left">Механик</td>
                            <td class="border-none"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="3"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="4"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-center">Место для штампа</td>
                            <td class="border-none" colspan="5"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none text-left" colspan="2"></td>
                            <td class="border-none font-bold text-left"></td>
                            <td class="border-none"></td>
                            <td class="border-none">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="3">(расшифровка)</td>
                            <td class="border-none"></td>
                            <td class="border-none bb" colspan="5"></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="fsn text-uppercase tar">Оборотная сторона формы №4-C</div>
                <div class="col col_full">
                    <table>
                        <tr>
                            <td class="border-all text-uppercase" colspan="13">последовательность выполнения
                                задания
                            </td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                        </tr>
                        <tr>
                            <td class="border-all" rowspan="2">пункт погрузки, разгрузки и перецепки прицепов
                            </td>
                            <td class="border-all" rowspan="2">номер ездки</td>
                            <td class="border-all w15" colspan="3">прибытие</td>
                            <td class="border-all w15" colspan="2">убытие</td>
                            <td class="border-all w15" colspan="3">номер прицепа</td>
                            <td class="border-all" rowspan="2" colspan="3">номера приложенных
                                товаро-транспортных документов (ТТД)
                            </td>
                            <td class="border-all" rowspan="2">Наименование грузоотправителя (грузополучателя)
                            </td>
                            <td class="border-all" rowspan="2">подпись и печать грузоотправителя
                                (грузополучателя)
                            </td>
                        </tr>
                        <tr>
                            <td class="border-all">число</td>
                            <td class="border-all">ч.</td>
                            <td class="border-all">мин.</td>
                            <td class="border-all">ч.</td>
                            <td class="border-all">мин.</td>
                            <td class="border-all">прибывших</td>
                            <td class="border-all">убывших</td>
                            <td class="border-all">порожний пробег прицепа</td>
                        </tr>
                        <tr>
                            <td class="border-all">26</td>
                            <td class="border-all blx2 btx2">27</td>
                            <td class="border-all btx2">28</td>
                            <td class="border-all btx2">29</td>
                            <td class="border-all btx2">30</td>
                            <td class="border-all btx2">31</td>
                            <td class="border-all btx2">32</td>
                            <td class="border-all btx2">33</td>
                            <td class="border-all btx2">34</td>
                            <td class="border-all btx2 brx2">35</td>
                            <td class="border-all">36</td>
                            <td class="border-all">37</td>
                            <td class="border-all">38</td>
                            <td class="border-all">39</td>
                            <td class="border-all">40</td>
                        </tr>
                        <tr>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all blx2">1</td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all brx2"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                        </tr>
                        <tr>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all blx2">2</td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all brx2"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                        </tr>
                        <tr>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all blx2">3</td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all brx2"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                        </tr>
                        <tr>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all blx2">4</td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all brx2"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                        </tr>
                        <tr>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all blx2">5</td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all brx2"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                        </tr>
                        <tr>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all blx2">6</td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all brx2"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                        </tr>
                        <tr>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all blx2">7</td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all brx2"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                        </tr>
                        <tr>
                            <td class="border-all">&nbsp;</td>
                            <td class="border-all blx2">8</td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all brx2 bbx2"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                        </tr>
                        <tr>
                            <td class="border-none text-right">Всего</td>
                            <td class="border-none border-top blx2 bbx2 brx2"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none" colspan="2">ТТД в количестве</td>
                            <td class="border-none blx2 bbx2 brx2 btx2 w2-5"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none text-left">шт.</td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="13"></td>
                            <td class="border-none">(прописью)</td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="5"></td>
                            <td class="border-none font-bold text-left" colspan="10">Таксировка</td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="5"></td>
                            <td class="border-none text-left">Сдал водитель</td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none text-left">Принял диспетчер</td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none"></td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="5"></td>
                            <td class="border-none"></td>
                            <td class="border-none">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none">(расшифровка)</td>
                            <td class="border-none"></td>
                            <td class="border-none"></td>
                            <td class="border-none">(подпись)</td>
                            <td class="border-none"></td>
                            <td class="border-none">(расшифровка)</td>
                            <td class="border-none"></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row bi">
                <div class="col col_half indent">
                    <table>
                        <tr>
                            <td class="border-none text-left w20">Особые отметки</td>
                            <td class="border-none bb" colspan="8"></td>
                            <td class="border-none bb">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb w90">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb"></td>
                            <td class="border-none bb">&nbsp;</td>
                        </tr>
                    </table>
                </div>
                <div class="col col_half">
                    <table>
                        <tr>
                            <td class="border-none border-all" colspan="4">Простои на линии</td>
                            <td class="border-none border-all" rowspan="3">Подпись ответственного лица</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all width200" colspan="2">причина</td>
                            <td class="border-none border-all width200" colspan="2">дата(число, месяц), время,
                                ч.мин
                            </td>
                        </tr>
                        <tr>
                            <td class="border-none border-all">наименование</td>
                            <td class="border-none border-all">код</td>
                            <td class="border-none border-all">начало</td>
                            <td class="border-none border-all">окончание</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all">41</td>
                            <td class="border-none border-all">42</td>
                            <td class="border-none border-all">43</td>
                            <td class="border-none border-all">44</td>
                            <td class="border-none border-all">45</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all">&nbsp;</td>
                            <td class="border-none border-all btx2 blx2">&nbsp;</td>
                            <td class="border-none border-all btx2">&nbsp;</td>
                            <td class="border-none border-all btx2 brx2">&nbsp;</td>
                            <td class="border-none border-all">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all">&nbsp;</td>
                            <td class="border-none border-all blx2">&nbsp;</td>
                            <td class="border-none border-all">&nbsp;</td>
                            <td class="border-none border-all brx2">&nbsp;</td>
                            <td class="border-none border-all">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none border-all">&nbsp;</td>
                            <td class="border-none border-all bbx2 blx2">&nbsp;</td>
                            <td class="border-none border-all bbx2">&nbsp;</td>
                            <td class="border-none border-all bbx2 brx2">&nbsp;</td>
                            <td class="border-none border-all">&nbsp;</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="fsn text-uppercase tac">Результат работы автомобиля и прицепов</div>
                <div class="col col_full">
                    <table>
                        <tr>
                            <td class="border-all" colspan="2">расход горючего, л</td>
                            <td class="border-all" colspan="7">время в наряде, ч.мин.</td>
                            <td class="border-all" colspan="2">количество</td>
                            <td class="border-all" colspan="4">пробег, км</td>
                            <td class="border-all" colspan="2">перевезено, т</td>
                            <td class="border-all" colspan="2">выполнено, ткм</td>
                            <td class="border-all" colspan="4">зарплата</td>
                        </tr>
                        <tr>
                            <td class="border-all" rowspan="4">
                                по<br/>норме
                            </td>
                            <td class="border-all" rowspan="4">
                                факти-<br/>чески
                            </td>
                            <td class="border-all" colspan="2">всего</td>
                            <td class="border-all" colspan="5">в том числе автомобиля</td>
                            <td class="border-all" rowspan="4">ездок</td>
                            <td class="border-all" rowspan="4">заездов</td>
                            <td class="border-all" colspan="2">общий</td>
                            <td class="border-all" colspan="2">
                                в том <br/>числе<br/>с грузом
                            </td>
                            <td class="border-all" rowspan="4">всего</td>
                            <td class="border-all" rowspan="4">
                                в том <br/>числе на<br/>прице-<br/>пах
                            </td>
                            <td class="border-all" rowspan="4">всего</td>
                            <td class="border-all" rowspan="4">
                                в том <br/>числе на<br/>прице-<br/>пах
                            </td>
                            <td class="border-all">код</td>
                            <td class="border-all" colspan="3">сумма, ру.коп</td>
                        </tr>
                        <tr>
                            <td class="border-all" rowspan="3">автомо-<br/>биля</td>
                            <td class="border-all" rowspan="3">прицепа</td>
                            <td class="border-all" rowspan="3">в движении</td>
                            <td class="border-all" colspan="4">в простое</td>
                            <td class="border-all" rowspan="3">автомо-<br/>биля</td>
                            <td class="border-all" rowspan="3">прицепа</td>
                            <td class="border-all" rowspan="3">автомо-<br/>биля</td>
                            <td class="border-all" rowspan="3">прицепа</td>
                            <td class="border-all">65</td>
                            <td class="border-all" colspan="3">66</td>
                        </tr>
                        <tr>
                            <td class="border-all" rowspan="2">всего</td>
                            <td class="border-all" colspan="2">под нагрузкой, разгрузкой</td>
                            <td class="border-all" rowspan="2">по техническим неисправностям</td>
                            <td class="border-all btx2 blx2"></td>
                            <td class="border-all brx2 btx2" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-all">всего</td>
                            <td class="border-all">свернормат.</td>
                            <td class="border-all blx2"></td>
                            <td class="border-all brx2" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-all">46</td>
                            <td class="border-all">47</td>
                            <td class="border-all">48</td>
                            <td class="border-all">49</td>
                            <td class="border-all">50</td>
                            <td class="border-all">51</td>
                            <td class="border-all">52</td>
                            <td class="border-all">53</td>
                            <td class="border-all">54</td>
                            <td class="border-all">55</td>
                            <td class="border-all">56</td>
                            <td class="border-all">57</td>
                            <td class="border-all">58</td>
                            <td class="border-all">59</td>
                            <td class="border-all">60</td>
                            <td class="border-all">61</td>
                            <td class="border-all">62</td>
                            <td class="border-all">63</td>
                            <td class="border-all">64</td>
                            <td class="border-all blx2"></td>
                            <td class="border-all brx2" colspan="3"></td>
                        </tr>
                        <tr>
                            <td class="border-all blx2 btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all btx2"></td>
                            <td class="border-all"></td>
                            <td class="border-all brx2" colspan="3">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-all blx2"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all brx2" colspan="3">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-all blx2"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all"></td>
                            <td class="border-all brx2" colspan="3">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-all bbx2 blx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2"></td>
                            <td class="border-all bbx2 brx2" colspan="3">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="23"></td>
                        </tr>
                        <tr>
                            <td class="border-none" colspan="23">
                                <table>
                                    <tr>
                                        <td class="border-none" colspan="7"></td>
                                        <td class="border-none">Коды марок:</td>
                                        <td class="border-none text-right">Автомобиля</td>
                                        <td class="btx2 blx2 bbx2 brx2 w50w"></td>
                                        <td class="border-none text-right">Прицепов</td>
                                        <td class="btx2 blx2 bbx2 brx2 w50w"></td>
                                        <td class="btx2 blx2 bbx2 brx2 w50w"></td>
                                        <td class="btx2 blx2 bbx2 brx2 w50w"></td>
                                        <td class="border-none text-right">Автомобиль, дни в работе</td>
                                        <td class="btx2 blx2 bbx2 brx2 w50w"></td>
                                        <td class="border-none">Таксировщик</td>
                                        <td class="border-none bb"></td>
                                        <td class="border-none"></td>
                                        <td class="border-none bb" colspan="3"></td>
                                        <td class="border-none"></td>
                                    </tr>
                                    <tr>
                                        <td class="border-none" colspan="7"></td>
                                        <td class="border-none"></td>
                                        <td class="border-none"></td>
                                        <td class="border-none"></td>
                                        <td class="border-none"></td>
                                        <td class="border-none"></td>
                                        <td class="border-none"></td>
                                        <td class="border-none"></td>
                                        <td class="border-none"></td>
                                        <td class="border-none"></td>
                                        <td class="border-none"></td>
                                        <td class="border-none">(подпись)</td>
                                        <td class="border-none"></td>
                                        <td class="border-none" colspan="3">(расшифровка)</td>
                                        <td class="border-none"></td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    `;
}