import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import classNames from 'classnames';
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUnits} from "store/reducers/organizational_units/units";
import {
    getContractWork, createContractWork, updateContractWork,
    deleteContractWork
} from "store/reducers/commdept/contract_works";
import {getContract} from "store/reducers/commdept/contracts";
import PageModal from 'components/ui/page-modal';
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import currentUser from 'helpers/current-user';
import GlobalLoaderComponent from "components/ui/global-loader";
import ContextTooltip from "components/ui/context-tooltip";
import * as alerts from "helpers/alerts";
import Button from "components/ui/button";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getRoads} from "store/reducers/commdept/roads";
import {getRoadParts} from "store/reducers/commdept/road_parts";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getContract, getContractWork, createContractWork, updateContractWork, deleteContractWork})

export default class Editor extends BaseEditor {

    getFullTitle() {
        return this.isRepair() ? `Создание/редактирование работы по ${window.RNIS_SETTINGS.rename_contracts ? 'подрядному обязательству' : 'контракту'}` : 'Создание/редактирование объекта обслуживания';
    }

    modelClass = 'App\\Model\\ContractWork';
    modalClassName = 'b-modal-contract edit-work-contract-modal';

    isRepair() {
        return this.props.type === 'works';
    }

    componentDidMount() {
        super.componentDidMount();

        this.loadContract(this.props.contractUuid);
    }

    async loadData(uuid) {
        return await this.props.getContractWork(uuid);
    }

    async loadContract(contractUuid) {
        const response = await this.props.getContract(contractUuid);
        if (response.isOk) {
            return this.setState({
                contract: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async createItem(data) {
        return await this.props.createContractWork(data);
    }

    async updateItem(data) {
        return await this.props.updateContractWork(data);
    }

    getForm(item, onSubmit) {
        if (!this.state.contract) {
            return null;
        }
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                contract={this.state.contract}
                isRepair={this.isRepair()}
                errors={this.state.errors}
            />
        );
    }

    deleteItem() {
        return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            const response = await this.props.deleteContractWork({
                uuid: this.props.uuid,
            });
            if (response.isOk) {
                this.props.onSubmit();
            } else {
                response.showErrors();
            }
        });
    }

    render() {
        let form;
        let buttons;
        let onSubmit;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {
            if (this.state.item) {
                form = this.getForm(this.state.item, ::this.edit);

                onSubmit = ::this.onEdit;

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            <ContextTooltip key="base-table-list.delete" code="base-table-list.delete">
                                <ModalTopMenuListItem
                                    className="b-icon-link_params b-icon-link_icon_basket"
                                    onClick={::this.deleteItem}/>
                            </ContextTooltip>

                            <ContextTooltip key="base-editor.print" code="base-editor.print" default="Печать">
                                <ModalTopMenuListItem
                                    className="b-icon-link_icon_print"
                                    onClick={::this.print}
                                />
                            </ContextTooltip>

                            <ModalTopMenuListSeparator key="separator"/>
                        </ModalTopMenuList>

                        <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                            <ModalTopMenuButton
                                className="_close"
                                onClick={::this.props.onClose}
                            />
                        </ContextTooltip>
                    </ModalTopMenuButtons>
                );
            }
        } else {
            form = this.getForm({}, ::this.create);

            onSubmit = ::this.onCreate;

            buttons = (
                <ModalTopMenuButtons>
                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={::this.props.onClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );
        }

        return (
            <PageModal
                header={{title: this.getFullTitle(), buttons}}
                onClose={this.props.onClose}
                className={`profile-modal b-modal-${this.props.mode} ${this.modalClassName}`}
                withFade={this.withFade}
            >
                {loader}
                {form}
                <div className="b-modal__footer">
                    <div className="b-modal__footer-txt">Вы хотите сохранить все изменения?</div>
                    <Button size="md" color="white" shadow="gray" className="b-button_cancel" text="Отменить"
                            onClick={this.props.onClose}/>
                    <Button size="md" color="red" className="b-button_save" text="Сохранить"
                            onClick={onSubmit}/>
                </div>
            </PageModal>
        );
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    contract: PropTypes.object,
    isRepair: PropTypes.bool,
})

@connect((state) => ({}), {getDictionaryList, getUnits, getRoads, getRoadParts}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        contract_work: {},
        areas: [],
        units: [],
        work_types: [],
        measures: [],
        maintenance_groups: [],
    };

    getData() {
        return this.state.contract_work;
    }

    async componentWillMount() {
        let contract_work = this.props.data;
        contract_work.contract_uuid = this.props.contract.uuid;

        await this.setState({
            contract_work,
        });

        this.loadUnits(_.filter(this.props.contract.subcontractors));
        this.loadDictionaries([
            'areas',
            'work_types',
            'measures',
            'maintenance_groups',
        ]);
    }

    async loadUnits(unitUuids) {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withUuid: unitUuids,
            },
        });
        if (response.isOk) {
            this.setState({
                units: _.sortBy(_.map(response.payload.items, (item) => ({
                    label: item.name,
                    value: item.uuid,
                })), 'label'),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.contract_work, path, defaultValue);
    }

    render() {
        return (
            <div>
                <div className="b-modal__block">
                    {this.props.isRepair ? (
                        <Block title="Дорога">
                            {this.selectAsync('contract_work.road_uuid', ::this.loadRoadRepairParts, {
                                onChange: ::this.onRoadPartChange,
                            })}
                        </Block>
                    ) : (
                        <Block title="Объект">
                            {this.selectAsync('contract_work.road_uuid', ::this.loadRoadParts, {
                                onChange: ::this.onRoadPartChange,
                            })}
                        </Block>
                    )}
                    {(!this.props.isRepair) ? (
                        <Block title="Группа по содержанию">
                            {this.select('contract_work.maintenance_group_uuid', this.state.maintenance_groups, {
                                disabled: true,
                            })}
                        </Block>
                    ) : null}
                    <Block title="Начальный км.">
                        {this.textInput('contract_work.start_at')}
                    </Block>
                    <Block title="Конечный км.">
                        {this.textInput('contract_work.end_at')}
                    </Block>
                    <Block title="Протяженность участка">
                        {this.get('length')}
                    </Block>
                    <Block title="Субподрядчик">
                        {this.select('contract_work.unit_uuid', this.state.units)}
                    </Block>
                    {this.props.isRepair ? ([
                        <Block key="date_from" title="Дата начала">
                            {this.datepicker('contract_work.date_from')}
                        </Block>,
                        <Block key="date_to" title="Дата окончания">
                            {this.datepicker('contract_work.date_to')}
                        </Block>,
                    ]) : null}
                    {this.props.isRepair ? (
                        <TableContainer>
                            <div className="Table">
                                <table className="b-table b-table-no-hover">
                                    <thead>
                                    <tr className="b-table__header wrap-normal">
                                        <th>Виды работ</th>
                                        <th>Ед. измер. (справ.)</th>
                                        <th>Объем работ</th>
                                        <th>Срок начала</th>
                                        <th>Срок окончания</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.renderWorks()}
                                    </tbody>
                                </table>
                            </div>
                            <span className="add-line" onClick={::this.addWork}>Добавить строку</span>
                        </TableContainer>
                    ) : null}
                </div>
            </div>
        );
    }

    onRoadPartChange(e) {
        const value = e ? e.value : null;

        this.setValue('contract_work.road_uuid', value);
        if (e) {
            this.setValue('contract_work.start_at', 0);
            this.setValue('contract_work.end_at', e.length);
        }
        if (!this.props.isRepair) {
            this.setValue('contract_work.maintenance_group_uuid', e ? e.maintenance_group_uuid : null);
        }
    }

    async loadRoadRepairParts(input, callback) {
        let meta = {
            pagination: {
                page: 1,
                limit: 20,
            },
        };
        if (!input) {
            input = this.get('road_uuid');
            meta.column_search = [
                {
                    column: 'uuid',
                    value: input.toString(),
                },
            ];
        } else {
            meta.search = input.toString();
        }
        const result = await this.props.getDictionaryList('kurs_road_repair_parts', meta, false);

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.documents.map(i => ({
                    label: i.name,
                    value: i.uuid,
                    start: i.start,
                    end: i.end,
                    length: i.length,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    async loadRoadParts(input, callback) {
        if (!input) {
            input = this.get('road_uuid');
        }
        const result = await this.props.getRoadParts({
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
        });

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: i.name,
                    value: i.uuid,
                    maintenance_group_uuid: i.maintenance_group_uuid,
                    start: i.start,
                    end: i.end,
                    length: i.length,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    renderWorks() {
        const list = this.get('works', []);

        return list.map(::this.renderWorkItem);
    }

    addWork(e) {
        e.preventDefault();

        let contract_work = this.state.contract_work;
        contract_work.works = contract_work.works || [];
        contract_work.works.push({});

        this.setState({contract_work});
    }

    deleteWork(index, e) {
        e.preventDefault();

        let contract_work = this.state.contract_work;
        contract_work.works.splice(index, 1);
        this.setState({contract_work});
    }

    renderWorkItem(work, index) {
        return (
            <tr key={index} className="_text_normal">
                <td className="input-cell">
                    {this.select(`contract_work.works.${index}.work_type_uuid`, this.state.work_types)}
                </td>
                <td className="input-cell">
                    {this.select(`contract_work.works.${index}.measure_uuid`, this.state.measures, {
                        disabled: true,
                    })}
                </td>
                <td className="input-cell">
                    {this.textInput(`contract_work.works.${index}.volume`, {
                        type: 'number',
                        positive: true,
                    })}
                </td>
                <td className="input-cell expand-top">
                    {this.datepicker(`contract_work.works.${index}.date_from`)}
                </td>
                <td className="input-cell expand-top">
                    {this.datepicker(`contract_work.works.${index}.date_to`)}
                </td>
                <td>
                    <a href="javascript:void(0)" onClick={this.deleteWork.bind(this, index)}>x</a>
                </td>
            </tr>
        );
    }

    async setValue(field, value) {
        await super.setValue(field, value);

        if ((field === 'contract_work.start_at') || (field === 'contract_work.end_at')) {
            this.calculateLength();
        }

        const matches = /^contract_work\.works\.([0-9]+)\.work_type_uuid$/.exec(field);
        if (matches) {
            const index = matches[1];
            this.setValue(`contract_work.works.${index}.measure_uuid`, _.get(_.find(this.state.work_types, {value}), 'document.measure_uuid'));
        }
    }

    calculateLength() {
        const start = this.get('start_at');
        const end = this.get('end_at');
        const length = Math.abs(end - start) || 0;

        this.setValue('contract_work.length', Math.round(length * 100) / 100);
    }
}
