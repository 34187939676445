import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import classNames from 'classnames';

import {getRouteVariant, createRouteVariant, updateRouteVariant} from "store/reducers/routes/route_variants";
import PageModal from 'components/ui/page-modal';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import * as alerts from "helpers/alerts";
import ContextTooltip from "components/ui/context-tooltip";
import './index.less';
import TableContainer from "components/ui/Table/Container/TableContainer";

@propTypes({
    index: PropTypes.number,
    shift: PropTypes.object.isRequired,
    stopPoints: PropTypes.object.isRequired,
    routeVariants: PropTypes.array,
    onUpdate: PropTypes.func.isRequired,
})

@connect(state => ({}), {})

export default class ProductionRunEditor extends Component {

    state = {};

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    render() {
        const title = 'Интервалы производственного рейса';

        const form = this.getForm({});

        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                    <ModalTopMenuButton
                        className="_save"
                        title="Сохранить"
                        onClick={::this.save}
                    />
                </ContextTooltip>

                <ModalTopMenuButtonsSeparator/>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{
                    title,
                    buttons,
                }}
                headerClassName="b-modal__header_dark"
                onClose={this.props.onClose}
                className={`profile-modal b-modal-${this.props.mode} run-intervals-modal b-modal_centered`}
            >
                {form}
            </PageModal>
        );
    }

    save() {
        if (!this.refs.form) return;

        const data = this.refs.form.getWrappedInstance().getData();

        this.props.onUpdate(data);
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        run: {},
        stopPoints: [],
    };

    getData() {
        return this.state.run;
    }

    async componentDidMount() {
        const run = this.props.shift.runs[this.props.index];

        await this.setState({
            run,
            stopPoints: _.filter(run.points, {point_type: 'stop_point'}),
        });
    }

    get(path, defaultValue = null) {
        return _.get(this.state.run, path, defaultValue);
    }

    render() {
        return (
            <div>
                <div className="Table">
                    <TableContainer>
                        <table className="b-table b-table-no-hover">
                            <thead>
                            <tr className="b-table__header">
                                <th>Остановочный пункт</th>
                                <th>Интервал</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.get('production_interval_map', []).map(::this.renderInterval)}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </div>
        );
    }

    renderInterval(interval, index) {
        const stopPointUuid = _.get(this.state.stopPoints[interval.index], 'type_uuid');

        return (
            <tr key={interval.index}>
                <td>{this.props.stopPoints[stopPointUuid]}</td>
                <td>{this.textInput(`run.production_interval_map.${index}.interval`, {
                    type: 'number',
                    positive: true,
                })}</td>
            </tr>
        );
    }
}
