import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getTabletSummary = (date_from, date_to, field = null) => async (dispatch) => {
    try {
        const response = await api.reports.getTabletSummary(date_from, date_to, field);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTabletWorkSummary = (meta) => async (dispatch) => {
    try {
        const response = await api.reports.getTabletWorkSummary(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
export const getTabletWorkSummaryPercent = (meta) => async (dispatch) => {
    try {
        const response = await api.reports.getTabletWorkSummaryPercent(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTabletKiutrSummary = (data) => async (dispatch) => {
    try {
        const response = await api.reports.getTabletKiutrSummary(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTabletKiutrSummaryUnit = (data) => async (dispatch) => {
    try {
        const response = await api.reports.getTabletKiutrSummaryUnit(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getMunicipalitiesKiutrSummary = (data) => async (dispatch) => {
    try {
        const response = await api.reports.getMunicipalitiesKiutrSummary(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTabletUnitSummary = (data) => async (dispatch) => {
    try {
        const response = await api.reports.getTabletUnitSummary(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTabletScheduleIntervalSummary = (data) => async (dispatch) => {
    try {
        const response = await api.reports.getTabletScheduleIntervalSummary(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};


