import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import Select from "components/ui/select";
import {
    Bar, BarChart, LabelList, ResponsiveContainer,
    Tooltip, XAxis,
    YAxis, Text,
} from "recharts";
import {getUnits} from "store/reducers/organizational_units/units";
import {unitTasksDoneChart} from "store/reducers/kurs/tasks";
import './style.less';


@connect(state => ({}), {
    getUnits,
    unitTasksDoneChart,
})

export class UnitPercentType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            unit_percent_type: 'best',
            unit_data: null,
            units: [],
        }
    }

    componentDidMount() {
        this.loadUnits();
        this.loadUnitData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
            this.loadUnitData();
        }
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            filters: {
                withComponent: 'utility',
            },
            pagination: {
                page: 1,
                limit: 10000,
            },
        });

        if (response.isOk) {
            this.setState({
                units: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async loadUnitData() {
        const response = await this.props.unitTasksDoneChart({
            filters: this.props.filters,
        });

        if (response.isOk) {
            this.setState({
                unit_data: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    onChangeType(field, e) {
        const value = e.value || e.target.value;

        let state = this.state;
        state[field] = value;
        this.setState(state);
    }

    getFill(value) {
        let green = 80;
        let orange = 50;

        if (value >= green) {
            return '#95B400';
        }
        if (value >= orange) {
            return '#F4A44B';
        }
        return '#F65C50';
    }

    renderUnitPercentChart() {
        let rows;
        if (this.state.unit_percent_type === 'best') {
            rows = _.slice(_.orderBy(this.state.unit_data || [], ['percent'], ['desc']), 0, 10);
        } else {
            rows = _.slice(_.orderBy(this.state.unit_data || [], ['percent'], ['asc']), 0, 10);
        }

        const data = _.map(rows, (row) => {
            return {
                name: _.get(_.find(this.state.units, {value: row.unit_uuid}), 'label'),
                value: _.round(row.percent * 100),
                label: `${_.round(row.percent * 100)}`,
                fill: this.getFill(_.round(row.percent * 100)),
                radius: [4, 4, 0, 0],
                fontSize: 10
            };
        });

        return (
            <ResponsiveContainer>
                <BarChart data={data} margin={{bottom: 50}}>
                    <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" fontSize={10}
                           tick={props => <Text {...props} width={130}>{props.payload.value}</Text>}/>
                    <YAxis dataKey="value"
                           label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle"
                                                 angle={-90}>%</Text>}/>
                    <Tooltip/>
                    <Bar dataKey="value" fill="#57AF7D" name="Процент выполнения работ" barSize={30}
                         isAnimationActive={false}>
                        <LabelList dataKey="label" position="insideTop" fill="#fff"/>
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    }

    render() {
        return (
            <div className="widgets__content unit-percent-type__content">
                <div className="widgets__selector unit-percent-type__selector">
                    <Select value={this.state.unit_percent_type}
                            onChange={this.onChangeType.bind(this, 'unit_percent_type')}
                            clearable={false} searchable={false} options={[
                        {
                            value: 'best',
                            label: 'Топ лучших',
                        },
                        {
                            value: 'worst',
                            label: 'Топ худших',
                        },
                    ]}/>
                </div>
                {this.renderUnitPercentChart()}
            </div>
        )
    }
}
