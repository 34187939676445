import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './accordion.less';

@propTypes({
    className: PropTypes.string,
})

@defaultProps({
    className: '',
})

export default class Accordion extends Component {
    render() {
        return (
            <div className={classNames('accordion', this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}