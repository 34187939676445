import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import ContextTooltip from "components/ui/context-tooltip";
import PageModalComponent from "components/ui/page-modal";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";

import './DuplicationMatrixModal.less';
import Block from "components/ui/form/block";
import Select, {SelectAsync} from "components/ui/select";
import {getUnits} from "store/reducers/organizational_units/units";
import {getTerritorialEntities} from "store/reducers/geo/territorial-entities";
import {getContracts} from "store/reducers/kiutr/contracts/contracts";
import {getRoutes} from "store/reducers/routes/routes";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Checkbox from "components/ui/form/checkbox";
import Datepicker from "components/ui/form/datepicker";
import Input from "components/ui/form/input";
import {
    calcDuplicationMatrix, createDuplicationMatrix, deleteDuplicationMatrix,
    getDuplicationMatrices, recalcDuplicationMatrix
} from "store/reducers/kiutr/duplication_matrix";
import classNames from 'classnames';
import {CycleFetch} from "helpers/api";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import moment from "moment";
import formats from "dictionaries/formats";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import * as alerts from "helpers/alerts";

@propTypes({
    component: PropTypes.string,
    onClose: PropTypes.func,
})

@connect(state => ({}), {
    getUnits,
    getTerritorialEntities,
    getContracts,
    getRoutes,
    getDictionaryList,
    calcDuplicationMatrix,
    createDuplicationMatrix,
    recalcDuplicationMatrix,
    deleteDuplicationMatrix,
    getDuplicationMatrices,
    getEntityNames,
})

export default class DuplicationMatrixModal extends Component {

    state = {
        duplication_matrix: {},
        units: [],
        tos: [],
        contracts: [],
        routes: [],
        transport_connection_types: [],
        route_types: [],
        rates_types: [],
        matrices: [],
        page: 1,
        pages: 0,
        search: '',
        related: new EntityList,
    };

    perPage = 10;

    _cycleFetch = null;

    componentWillMount() {
        this.loadDictionaries([
            'transport_connection_types',
            'route_types',
            'rates_types',
        ]);

        if (this.props.location.query.uuids) {
            const uuids = this.props.location.query.uuids.split(',');
            this.onChange('routes', {target: {value: uuids}});
        }

        this._cycleFetch = new CycleFetch(() => {
            return this.reloadMatrices();
        }, () => {
        }, 60000);
        this._cycleFetch.run();
    }

    componentDidUnmount() {
        this._cycleFetch.stop();
        delete this['_cycleFetch'];
    }

    async reloadMatrices() {
        const response = await this.props.getDuplicationMatrices({
            search: this.state.search,
            pagination: {
                page: this.state.page,
                perPage: this.perPage,
            },
            order: {
                column: 'created_at',
                direction: 'desc',
            },
        });

        if (response.isOk) {
            this.setState({
                matrices: response.payload.items,
                pages: response.data.headers.meta.pagination.total_pages,
            });

            this.loadRelated(response.payload.items);
        }
    }

    async loadRelated(items) {
        const carriers = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'carriers')))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const units = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'units')))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const tos = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'tos')))), (uuid) => ({
            class: 'App\\Model\\TerritorialEntity',
            uuid: uuid,
            source: 'geo',
        }));

        const contracts = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'contracts')))), (uuid) => ({
            class: 'App\\Model\\Contract',
            uuid: uuid,
            source: 'geo',
        }));

        const routes = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'routes')))), (uuid) => ({
            class: 'App\\Model\\Route',
            uuid: uuid,
            source: 'geo',
        }));

        const transportConnectionTypes = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'transport_connection_types')))), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\TransportConnectionType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const routeTypes = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'route_types')))), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RouteType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const ratesTypes = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'rates_types')))), (uuid) => ({
            class: 'App\\Dictionaries\\Common\\RatesType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(_.concat(
            carriers,
            units,
            tos,
            contracts,
            routes,
            transportConnectionTypes,
            routeTypes,
            ratesTypes
        ));

        if (response.isOk) {
            this.state.related.add(response);

            this.forceUpdate();
        }
    }

    onChange(field, {target: {value}}) {
        let duplication_matrix = this.state.duplication_matrix;
        duplication_matrix[field] = value;
        this.setState({
            duplication_matrix,
        });
    }

    onChangeSelect(field, e) {
        if (!e) {
            return;
        }

        const value = e.value;
        let duplication_matrix = this.state.duplication_matrix;
        duplication_matrix[field] = duplication_matrix[field] || [];
        duplication_matrix[field].push(value);
        duplication_matrix[field] = _.uniq(duplication_matrix[field]);
        duplication_matrix.matrix_routes = null;
        duplication_matrix.matrix_route_variants_count = null;
        this.setState({
            duplication_matrix,
        });
    }

    onRemove(field, value) {
        let duplication_matrix = this.state.duplication_matrix;
        duplication_matrix[field] = duplication_matrix[field] || [];
        duplication_matrix[field].splice(_.indexOf(duplication_matrix[field], value), 1)
        this.setState({
            duplication_matrix,
        });
    }

    get(field, defaultValue = null) {
        return _.get(this.state.duplication_matrix, field, defaultValue);
    }

    async calc() {
        const response = await this.props.calcDuplicationMatrix(this.state.duplication_matrix);

        if (response.isOk) {
            let duplication_matrix = this.state.duplication_matrix;
            duplication_matrix.matrix_routes = response.payload.routes;
            duplication_matrix.matrix_route_variants_count = response.payload.route_variants_count;

            this.setState({
                duplication_matrix,
            });
        } else {
            response.showErrors();
        }
    }

    async create() {
        if (this.get('matrix_routes') === null) {
            return;
        }

        this.setState({
            errors: [],
        });

        const response = await this.props.createDuplicationMatrix(this.state.duplication_matrix);
        if (response.isOk) {
            this.setState({
                duplication_matrix: {},
            });
            this._cycleFetch.forceNext();
        } else {
            response.showErrors();
            this.setState({
                errors: response.validationErrors
            });
        }
    }

    render() {
        return (
            <div>
                {this.renderConstructor()}
                {this.renderMatricesModal()}
            </div>
        );
    }

    renderConstructor() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModalComponent
                className="b-modal-edit b-modal-route matrix-constructor move-right"
                header={{
                    title: 'Конструктор матриц дублирования маршрутов',
                    buttons,
                }}
                buttons={[
                    (this.get('matrix_routes') !== null) ? (
                        <div key="info" className="b-block__info">
                            <div className="b-block__title">В выборке</div>
                            <div className="b-block__row">
                                <div className="b-block__left">Маршрутов</div>
                                <div className="b-block__right">{this.get('matrix_routes').length}</div>
                            </div>
                            <div className="b-block__row">
                                <div className="b-block__left">Вариантов движения</div>
                                <div className="b-block__right">{this.get('matrix_route_variants_count')}</div>
                            </div>
                        </div>
                    ) : null,
                    <div key="buttons" className="b-block__button">
                        <a href="javascript:void(0)" onClick={::this.calc}
                           className="b-button b-button_red b-button_size_md">Рассчитать</a>
                        <a href="javascript:void(0)"
                           onClick={::this.create}
                           className={classNames('b-button b-button_red b-button_size_md', {
                               'b-button_disabled': this.get('matrix_routes') === null,
                           })}>Построить</a>
                    </div>,
                ]}
            >
                <div className="b-modal__block">
                    <Block title="Перевозчики" nowrap={true}>
                        <div className="b-block__text">
                            <SelectAsync
                                value={null}
                                onChange={this.onChangeSelect.bind(this, 'carriers')}
                                loadOptions={::this.loadUnits}
                            />
                        </div>
                        <div className="b-block__text picked">
                            {_.map(this.get('carriers', []), (uuid) => {
                                return (
                                    <div key={uuid} className="stop-point clearAfter text-left">
                                        <a className="stop-point__link" href="javascript:void(0)">
                                            {_.get(_.find(this.state.units, {value: uuid}), 'label')}
                                        </a>
                                        <a className="remove-point" href="javascript:void(0)"
                                           onClick={this.onRemove.bind(this, 'carriers', uuid)}>×</a>
                                    </div>
                                );
                            })}
                        </div>
                    </Block>

                    <Block title="Заказчики" nowrap={true}>
                        <div className="b-block__text">
                            <SelectAsync
                                value={null}
                                onChange={this.onChangeSelect.bind(this, 'units')}
                                loadOptions={::this.loadUnits}
                            />
                        </div>
                        <div className="b-block__text picked">
                            {_.map(this.get('units', []), (uuid) => {
                                return (
                                    <div key={uuid} className="stop-point clearAfter text-left">
                                        <a className="stop-point__link" href="javascript:void(0)">
                                            {_.get(_.find(this.state.units, {value: uuid}), 'label')}
                                        </a>
                                        <a className="remove-point" href="javascript:void(0)"
                                           onClick={this.onRemove.bind(this, 'units', uuid)}>×</a>
                                    </div>
                                );
                            })}
                        </div>
                    </Block>

                    <Block title="Территории" nowrap={true}>
                        <div className="b-block__text">
                            <SelectAsync
                                value={null}
                                onChange={this.onChangeSelect.bind(this, 'tos')}
                                loadOptions={::this.loadTerritorialEntities}
                            />
                        </div>
                        <div className="b-block__text picked">
                            {_.map(this.get('tos', []), (uuid) => {
                                return (
                                    <div key={uuid} className="stop-point clearAfter text-left">
                                        <a className="stop-point__link" href="javascript:void(0)">
                                            {_.get(_.find(this.state.tos, {value: uuid}), 'label')}
                                        </a>
                                        <a className="remove-point" href="javascript:void(0)"
                                           onClick={this.onRemove.bind(this, 'tos', uuid)}>×</a>
                                    </div>
                                );
                            })}
                        </div>
                    </Block>

                    <Block title={window.RNIS_SETTINGS.rename_contracts ? "Подрядные обязательства" : "Контракты"} nowrap={true}>
                        <div className="b-block__text">
                            <SelectAsync
                                value={null}
                                onChange={this.onChangeSelect.bind(this, 'contracts')}
                                loadOptions={::this.loadContracts}
                            />
                        </div>
                        <div className="b-block__text picked">
                            {_.map(this.get('contracts', []), (uuid) => {
                                return (
                                    <div key={uuid} className="stop-point clearAfter text-left">
                                        <a className="stop-point__link" href="javascript:void(0)">
                                            {_.get(_.find(this.state.contracts, {value: uuid}), 'label')}
                                        </a>
                                        <a className="remove-point" href="javascript:void(0)"
                                           onClick={this.onRemove.bind(this, 'contracts', uuid)}>×</a>
                                    </div>
                                );
                            })}
                        </div>
                    </Block>

                    <hr/>

                    <Block title="Маршруты" nowrap={true}>
                        <div className="b-block__text">
                            <SelectAsync
                                value={null}
                                onChange={this.onChangeSelect.bind(this, 'routes')}
                                loadOptions={::this.loadRoutes}
                            />
                        </div>
                        <div className="b-block__text picked">
                            {_.map(this.get('routes', []), (uuid) => {
                                return (
                                    <div key={uuid} className="stop-point clearAfter text-left">
                                        <a className="stop-point__link" href="javascript:void(0)">
                                            {_.get(_.find(this.state.routes, {value: uuid}), 'label')}
                                        </a>
                                        <a className="remove-point" href="javascript:void(0)"
                                           onClick={this.onRemove.bind(this, 'routes', uuid)}>×</a>
                                    </div>
                                );
                            })}
                        </div>
                    </Block>

                    <Block title="Виды сообщений" nowrap={true}>
                        <div className="b-block__text">
                            <Select
                                value={null}
                                options={this.state.transport_connection_types}
                                onChange={this.onChangeSelect.bind(this, 'transport_connection_types')}
                            />
                        </div>
                        <div className="b-block__text picked">
                            {_.map(this.get('transport_connection_types', []), (uuid) => {
                                return (
                                    <div key={uuid} className="stop-point clearAfter text-left">
                                        <a className="stop-point__link" href="javascript:void(0)">
                                            {_.get(_.find(this.state.transport_connection_types, {value: uuid}), 'label')}
                                        </a>
                                        <a className="remove-point" href="javascript:void(0)"
                                           onClick={this.onRemove.bind(this, 'transport_connection_types', uuid)}>×</a>
                                    </div>
                                );
                            })}
                        </div>
                    </Block>

                    <Block title="Типы маршрутов" nowrap={true}>
                        <div className="b-block__text">
                            <Select
                                value={null}
                                options={this.state.route_types}
                                onChange={this.onChangeSelect.bind(this, 'route_types')}
                            />
                        </div>
                        <div className="b-block__text picked">
                            {_.map(this.get('route_types', []), (uuid) => {
                                return (
                                    <div key={uuid} className="stop-point clearAfter text-left">
                                        <a className="stop-point__link" href="javascript:void(0)">
                                            {_.get(_.find(this.state.route_types, {value: uuid}), 'label')}
                                        </a>
                                        <a className="remove-point" href="javascript:void(0)"
                                           onClick={this.onRemove.bind(this, 'route_types', uuid)}>×</a>
                                    </div>
                                );
                            })}
                        </div>
                    </Block>

                    <Block title="Виды тарифов" nowrap={true}>
                        <div className="b-block__text">
                            <Select
                                value={null}
                                options={this.state.rates_types}
                                onChange={this.onChangeSelect.bind(this, 'rates_types')}
                            />
                        </div>
                        <div className="b-block__text picked">
                            {_.map(this.get('rates_types', []), (uuid) => {
                                return (
                                    <div key={uuid} className="stop-point clearAfter text-left">
                                        <a className="stop-point__link" href="javascript:void(0)">
                                            {_.get(_.find(this.state.rates_types, {value: uuid}), 'label')}
                                        </a>
                                        <a className="remove-point" href="javascript:void(0)"
                                           onClick={this.onRemove.bind(this, 'rates_types', uuid)}>×</a>
                                    </div>
                                );
                            })}
                        </div>
                    </Block>

                    <hr/>

                    <Block size="lg">
                        <Checkbox
                            checked={this.get('is_date_filter_active', false)}
                            label="Фильтровать по периоду активности"
                            onChange={this.onChange.bind(this, 'is_date_filter_active')}
                        />
                    </Block>
                    {this.get('is_date_filter_active', false) ? ([
                        <Block key="from" size="lg">
                            <span className="datepicker__label">с</span>
                            <Datepicker
                                value={this.get('date_from')}
                                onChange={this.onChange.bind(this, 'date_from')}
                                error={this.getError('duplication_matrix.date_from')}
                            />
                        </Block>,
                        <Block key="to" size="lg">
                            <span className="datepicker__label">по</span>
                            <Datepicker
                                value={this.get('date_to')}
                                onChange={this.onChange.bind(this, 'date_to')}
                                error={this.getError('duplication_matrix.date_to')}
                            />
                        </Block>,
                    ]) : null}
                    <Block size="lg" title="Название матрицы">
                        <Input
                            value={this.get('name')}
                            onChange={this.onChange.bind(this, 'name')}
                            error={this.getError('duplication_matrix.name')}
                        />
                    </Block>
                </div>
            </PageModalComponent>
        );
    }

    getError(fieldName) {
        const field = _.findLast(fieldName.split('.'));

        return _.get(this.state.errors, field) || _.get(this.state.errors, fieldName);
    }

    renderMatricesModal() {
        return (
            <PageModalComponent
                className="b-modal-edit b-modal-route matrix-archives move-right-2"
                withFade={false}
                header={{
                    title: 'Архив матриц дублирования',
                }}
            >
                <div>
                    <Accordion>
                        <div className="ObjectsList_Search">
                            <input type="search" className="ObjectsList_SearchInput" value={this.state.search}
                                   onChange={async ({target: {value}}) => {
                                       await this.setState({
                                           search: value,
                                       });

                                       this._cycleFetch.forceNext();
                                   }} placeholder="Найти..."/>
                        </div>
                        <hr/>
                        {_.map(this.state.matrices, ::this.renderMatrix)}
                    </Accordion>
                    {this.renderPages()}
                </div>
            </PageModalComponent>
        );
    }

    async setCurrentPage(page) {
        await this.setState({
            page,
        });

        this._cycleFetch.forceNext();
    }

    renderPages() {
        const currentPage = this.state.page;
        const pages = _.filter(_.range(1, this.state.pages + 1), (page) => {
            return Math.abs(page - currentPage) <= 3;
        });
        return (
            <div className="dataTables_paginate paging_simple_numbers">
                <span>
                    {_.map(pages, (page) => {
                        return (
                            <a key={page} href="javascript:void(0)" onClick={this.setCurrentPage.bind(this, page)}
                               className={classNames('paginate_button', (page === this.state.page) ? 'current' : '')}>{page}</a>
                        );
                    })}
                </span>
            </div>
        );
    }

    openMatrix(matrix) {
        this.props.router.push(`/kiutr/duplication_matrices/${matrix.uuid}`);
    }

    async recalcMatrix(matrix) {
        const response = await this.props.recalcDuplicationMatrix({
            uuid: matrix.uuid,
        });

        if (response.isOk) {
            this._cycleFetch.forceNext();
        } else {
            response.showErrors();
        }
    }

    deleteMatrix(matrix) {
        alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            const response = await this.props.deleteDuplicationMatrix({
                uuid: matrix.uuid,
            });
            if (response.isOk) {
                this._cycleFetch.forceNext();
            } else {
                response.showErrors();
            }
        });
    }

    renderMatrix(matrix) {
        const fields = {
            carriers: 'Перевозчики',
            units: 'Заказчики',
            tos: 'Территории',
            contracts: window.RNIS_SETTINGS.rename_contracts ? 'Подрядные обязательства' : 'Контракты',
            routes: 'Маршруты',
            transport_connection_types: 'Виды сообщений',
            route_types: 'Типы маршрутов',
            rates_types: 'Виды тарифов',
        };

        return (
            <AccordionItem
                key={matrix.uuid}
                afterTitle={(
                    <div className="accordion__title">
                        <span>
                            {matrix.name}
                            <ContextTooltip default="Маршрутов / Вариантов движения">
                                <span className="count">
                                    {matrix.matrix_routes.length}/{matrix.matrix_route_variants_count}
                                </span>
                            </ContextTooltip>
                        </span>
                        <div className="matrix-archives__info">
                            <div className="datatime">{moment(matrix.created_at).format(formats.DATETIME_SHORT)}</div>
                            <ContextTooltip default="Пересчитать">
                                <a className="b-icon-link b-icon-link_params b-icon-link_icon_recount"
                                   href="javascript:void(0)" onClick={this.recalcMatrix.bind(this, matrix)}/>
                            </ContextTooltip>
                            {matrix.done_at ? (
                                <ContextTooltip default="Перейти">
                                    <a className="b-icon-link b-icon-link_params b-icon-link_icon_sign"
                                       href="javascript:void(0)" onClick={this.openMatrix.bind(this, matrix)}/>
                                </ContextTooltip>
                            ) : (
                                <div className="matrix-archives__status">формируется</div>
                            )}
                            <a className="b-icon-link b-icon-link_params b-icon-link_icon_basket"
                               href="javascript:void(0)" onClick={this.deleteMatrix.bind(this, matrix)}/>
                        </div>
                    </div>
                )}
            >
                {_.map(fields, (label, value) => {
                    if (!matrix[value] || (matrix[value].length === 0)) {
                        return null;
                    }

                    return (
                        <Block key={value} size="xl" title={label}>
                            {_.map(matrix[value], (uuid) => {
                                return (
                                    <span key={uuid}>
                                        {this.state.related.getReact(uuid)}
                                    </span>
                                );
                            })}
                        </Block>
                    );
                })}

                {matrix.is_date_filter_active ? (
                    <Block title="Активность">
                        с {moment(matrix.date_from).format(formats.DATE)}
                        по {moment(matrix.date_to).format(formats.DATE)}
                    </Block>
                ) : null}
            </AccordionItem>
        );
    }

    async loadUnits(input, callback) {
        if (!input) {
            callback(null, {
                options: [],
                complete: false,
            });
            return;
        }

        const result = await this.props.getUnits({
            search: input,
            filters: {
                withComponent: this.props.component,
            },
            pagination: {
                page: 1,
                limit: 20,
            },
        });

        if (result.isOk) {
            const options = _.sortBy(result.payload.items.map(i => ({
                label: i.name,
                value: i.uuid,
            })), 'label');

            let units = this.state.units;
            units = _.uniqBy(_.concat(units, options), 'value');
            this.setState({units});

            callback(null, {
                options,
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    async loadTerritorialEntities(input, callback) {
        if (!input) {
            callback(null, {
                options: [],
                complete: false,
            });
            return;
        }

        const result = await this.props.getTerritorialEntities({
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
        });

        if (result.isOk) {
            const options = _.sortBy(result.payload.items.map(i => ({
                label: i.name,
                value: i.uuid,
            })), 'label');

            let tos = this.state.tos;
            tos = _.uniqBy(_.concat(tos, options), 'value');
            this.setState({tos});

            callback(null, {
                options,
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    async loadContracts(input, callback) {
        if (!input) {
            callback(null, {
                options: [],
                complete: false,
            });
            return;
        }

        let filters = {};
        const carriers = this.get('carriers', []);
        const units = this.get('units', []);
        if (units.length > 0) {
            filters.withUnits = units;
        }
        if (carriers.length > 0) {
            filters.withCarriers = carriers;
        }

        const result = await this.props.getContracts({
            //filters,
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
        });

        if (result.isOk) {
            const options = _.sortBy(result.payload.items.map(i => ({
                label: i.number,
                value: i.uuid,
            })), 'label');

            let contracts = this.state.contracts;
            contracts = _.uniqBy(_.concat(contracts, options), 'value');
            this.setState({contracts});

            callback(null, {
                options,
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    async loadRoutes(input, callback) {
        let filters = {};
        if (!input) {
            if (this.get('routes').length === 0) {
                callback(null, {
                    options: [],
                    complete: false,
                });
                return;
            }
            filters.withUuid = this.get('routes');
        }

        /*let filters = {};
        const carriers = this.get('carriers', []);
        const units = this.get('units', []);
        const contracts = this.get('contracts', []);
        if (units.length > 0) {
            filters.withUnits = units;
        }
        if (carriers.length > 0) {
            filters.withCarriers = carriers;
        }
        if (contracts.length > 0) {
            filters.withContracts = contracts;
        }*/

        const result = await this.props.getRoutes({
            filters,
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
        });

        if (result.isOk) {
            const options = _.sortBy(result.payload.items.map(i => ({
                label: `${i.number} ${i.title}`,
                value: i.uuid,
            })), 'label');

            let routes = this.state.routes;
            routes = _.uniqBy(_.concat(routes, options), 'value');
            this.setState({routes});

            callback(null, {
                options,
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }
}