import React from 'react';
import Page from 'components/ui/page';
import BaseEditorFormComponent from "components/base/base-editor-form";
import Button from "components/ui/button";
import Block from "components/ui/form/block";
import classnames from "classnames";
import * as alerts from "helpers/alerts";
import {connect} from "react-redux";
import { getSmsTemplatesList, getSmsDefaultDevice, smsCreate } from "store/reducers/sms/sms";

import "./bnso_sms.less";
import _ from "lodash";

@connect(state => ({}), { getSmsTemplatesList, getSmsDefaultDevice, smsCreate })

export default class OperatorBNSOSmsComponent extends BaseEditorFormComponent {

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            bnso: '',
            bnso_uuid: '',
            smsTemplates: [],
            provider_device_id: null,
            phone_numbers: [],
            phone_number: null,
            body: null,
            select: 'default',
            loading: false,
        });
    }

    componentWillMount() {
        if (localStorage.getItem("bnso")) {
            this.loadInitialData();
            this.loadTemplatesList();
            this.getSmsDefaultDevice();
        }
    }

    async getSmsDefaultDevice() {
        const response = await this.props.getSmsDefaultDevice();
        if (response.isOk) {
            this.setState({
                provider_device_id: response.payload.provider_device_id
            })
        }
    }

    async loadTemplatesList() {
        const response = await this.props.getSmsTemplatesList();
        if (response.isOk) {
            this.setState({
                smsTemplates: response.payload.items
            })
        }
    }

    loadInitialData() {
        const bnso = JSON.parse(localStorage.getItem("bnso"))
        const phone_numbers = [];
        if (bnso.phone_number.length) {
            phone_numbers.push({value:bnso.phone_number, label: bnso.phone_number})
        }
        if (bnso.phone_number2.length) {
            phone_numbers.push({value:bnso.phone_number2, label: bnso.phone_number2})
        }
        this.setState({
            bnso_uuid: bnso.bnso_uuid,
            bnso: bnso.bnso_code,
            phone_numbers,
        })
    }

    handleSelectChange(e) {
        this.setState({
            select: e.target.value,
            body: e.target.value
        })
    }

    handleRepeatClick(e) {
        if (this.state.body.length) {
            this.setState({
                body: e.target.value
            })
        }
    }

    checkButtonStatus() {
        const { phone_number, body } = this.state;
        if (phone_number && body) {
            return false
        } else {
            return true
        }
    }

    async handleSubmit() {
        this.setState({
            loading: true
        })
        const payload = {
            bnso_uuid: this.state.bnso_uuid,
            bnso_code: this.state.bnso,
            sendto: this.state.phone_number,
            body: this.state.body,
            provider_device_id: this.state.provider_device_id
        }
        const response = await this.props.smsCreate(payload)
        if (response.isOk) {
            alerts.success('Сообщение отправлено');
            this.setState({
                loading: false
            })
        } else {
            alerts.error('Не удалось отправить сообщение');
            this.setState({
                loading: false
            })
        }
    }

    goBack() {
        this.props.router.push(`/operator/bnso_check/${this.state.bnso_uuid}`);
    }

    render() {
        return (
            <Page pageId="OperatorBNSOSmsComponent"
                  title={`${_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '100']).text} → Отправка СМС в БНСО`}>
                <div className="sms-send-form">
                    <div className="sms-send-form__fields">
                        <Block size="md" title="Код БНСО">
                            {this.textInput('bnso', {
                                disabled: true,
                            })}
                        </Block>
                        <Block size="md" title="Номер телефона">
                            {this.select('phone_number', this.state.phone_numbers, { clearable: false})}
                        </Block>
                        <Block size="xl" title="Тело сообщения">
                            <select onChange={::this.handleSelectChange} onClick={::this.handleRepeatClick} value={this.state.select} className="select-css" name="template" id="template">
                                <option hidden value='default'>Выбрать шаблон</option>
                                {this.state.smsTemplates.map( (smsTemplate, index) => {
                                    return <option key={smsTemplate.title + index}
                                                   value={smsTemplate.template}>{smsTemplate.title}</option>
                                })}
                            </select>
                            {this.textarea('body')}
                        </Block>
                    </div>
                    <p className="sms-send-form__goback" onClick={() => this.goBack()}>&#129044; Назад</p>
                    <div className="sms-send-form-buttonWrapper">
                        <Button onClick={::this.handleSubmit} disabled={this.checkButtonStatus()} className={classnames("sms-send-form__sendButton", this.state.loading && "sms-send-form__sendButton_progress")} size="md" text={this.state.loading ? "Отправляется" : "Отправить"} />
                    </div>
                </div>
            </Page>
        );
    }
}