import React, {Component} from 'react';
import _ from 'lodash';

import {connect} from "react-redux";
import {getStopPoints} from "store/reducers/geo/stop-points";

import StopPointsComponent from "../Component/StopPointsComponent";

import './StopPointsContainer.less';
import { getDisplays } from "store/reducers/kiutr/displays";


@connect((state) => ({}), {getStopPoints, getDisplays})

export default class StopPointsContainer extends Component {

    state = {
        stopPointsList: [],
    }

    componentDidMount() {
        this.loadDisplays();
    }

    async loadDisplays() {
        const response = await this.props.getDisplays({
            pagination: {
                page: 1,
                limit: 1000,
            },
            response_data: [
                'items/stop_point',
            ],
        });

        if (response.isOk) {
            const stopPoints = response.payload.items.map(i => i.stop_point);
            this.setState({stopPointsList: _.sortBy(stopPoints.map(i => ({
                label: `${i.register_number} ${i.title}`,
                value: i.uuid,
                latitude: i.latitude,
                longitude: i.longitude,
            })), 'label')});
        } else {
            response.showErrors();
        }
    }

    render() {
        return (
            <div className="stopPoints-container">
                <StopPointsComponent stopPoints={this.state.stopPointsList}/>
            </div>
        );
    }
}