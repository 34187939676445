import React from 'react';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import classNames from 'classnames';

export default class RailroadCrossingIcon {

    static getIcon() {

            const html = ReactDOMServer.renderToStaticMarkup(<div>
                <span className="marker__icon icon-train"/>
            </div>);

            let className = `marker marker_type_flag marker_type_flag_red`;
            let size = 32;
            return new L.DivIcon({
                html: html,
                className: className,
                iconSize: new L.Point(size, size),
                iconAnchor: new L.Point(size / 2, size / 2),

            })
    }
}