import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const updateCooperationAgreement = (data) => async (dispatch) => {
    try {
        const response = await api.portal.updateCooperationAgreement(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const documentCooperationAgreement = (data) => async (dispatch) => {
    try {
        const response = await api.portal.documentCooperationAgreement(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createCooperationAgreement = (data) => async (dispatch) => {
    try {
        const response = await api.portal.createCooperationAgreement(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};


export const getCooperationAgreements = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.portal.getCooperationAgreements(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createCooperationAgreementWarning = (data) => async (dispatch) => {
    try {
        const response = await api.portal.createCooperationAgreementWarning(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getCooperationAgreementWarnings = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.portal.getCooperationAgreementWarnings(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateCooperationAgreementWarning = (data) => async (dispatch) => {
    try {
        const response = await api.portal.updateCooperationAgreementWarning(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};