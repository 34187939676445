import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import _ from 'lodash';
import TableContainer from "components/ui/Table/Container/TableContainer";
import Block from "components/ui/form/block";
import moment from "moment";
import AccordionItem from "components/ui/accordion/accordion-item";
import formats from "dictionaries/formats";
import Checkbox from "components/ui/form/checkbox";
import Select from "components/ui/select";
import runs from "dictionaries/runs";
import Input from "components/ui/form/input";
import classNames from 'classnames';
import {events} from 'dom-helpers';
import $ from 'jquery';
import Button from "components/ui/button";
import {State} from "components/ui/state";
import './index.less';
import currentUser from 'helpers/current-user';

@propTypes({
    order: PropTypes.object.isRequired,
    related: PropTypes.object.isRequired,
    runs: PropTypes.array.isRequired,
    stop_points: PropTypes.object.isRequired,
    route_variants: PropTypes.object.isRequired,
    order_executions: PropTypes.array.isRequired,
    onChangeRunTime: PropTypes.func.isRequired,
    onRunCheckToggle: PropTypes.func.isRequired,
    onStopPointCheckToggle: PropTypes.func.isRequired,
    onStopPointRegularToggle: PropTypes.func.isRequired,
    onRunCheckReasonChange: PropTypes.func.isRequired,
    onStopPointCheckReasonChange: PropTypes.func.isRequired,
    onMapOpen: PropTypes.func.isRequired,
    readonly: PropTypes.bool,
})

export default class OrderCloseRuns extends Component {
    state = {
        contentWidth: null,
        selectedUuid: null,
        selectedIndex: null,
    };

    constructor(props) {
        super(props);

        this.onResize = this.onResize.bind(this);
    }

    componentWillMount() {
        events.on(window, 'resize', this.onResize);
    }

    componentDidMount() {
        this.onResize();
    }

    componentWillUnmount() {
        events.off(window, 'resize', this.onResize);
    }

    onResize() {
        const width = $('.snake-content:first').width();
        this.setState({
            contentWidth: width,
        });
    }

    render() {
        const {runs} = this.props;
        return (
            <AccordionItem title={this.getTitle()} opened={true}
                           afterTitle={(this.props.isFirst && this.props.turn_start) ? (
                               <div className="accordion__menu">
                                   <Block size="sm" className="b-block_right" title="Выход в:">
                                       {this.props.readonly ? (
                                           this.props.turn_start
                                       ) : (
                                           <Input
                                               size="md"
                                               value={moment(this.props.turn_start, 'HH:mm').add(currentUser.user.is_timezoneoffset, 'hours').format('HH:mm')}
                                               onChange={this.props.onChangeTurnStart}
                                           />
                                       )}
                                   </Block>
                               </div>
                           ) : null}>
                {this.isFull() ? (
                    <div className="snake">
                        {_.map(runs, ::this.renderRunFull)}
                    </div>
                ) : (
                    <div className="runs">
                        {_.map(runs, ::this.renderRun)}
                    </div>
                )}
            </AccordionItem>
        )
    }

    isFull() {
        const {runs} = this.props;
        const run = _.first(runs);
        return run.vehicle_uuid && run.driver_uuid;
    }

    getTitle() {
        const {related, runs} = this.props;

        const run = _.first(runs);

        return (
            <span>
                {this.isFull() ? 'Рейсы' : 'Не обеспечены'} <span
                className="accent">{run.vehicle_uuid ? related.getReact(run.vehicle_uuid) : '-'}, {run.driver_uuid ? related.getReact(run.driver_uuid) : '-'}</span>
            </span>
        );
    }

    getOperations(index) {
        const {runs} = this.props;

        let result = [];
        let i = index + 1;
        while ((i < runs.length) && (runs[i].type !== 'production_forward') && (runs[i].type !== 'production_reverse')) {
            result.push(runs[i]);
            i++;
        }

        return result;
    }

    renderRunFull(run, index) {
        const {order, route_variants} = this.props;

        const originalRuns = _.values(_.filter(_.flatten(_.map(order.shifts, 'runs')), (run) => {
            return (run.type === 'production_forward') || (run.type === 'production_reverse');
        }));

        const number = _.findIndex(originalRuns, {uuid: run.uuid}) + 1;
        const routeVariant = _.get(route_variants, run.route_variant_uuid);
        const operations = this.getOperations(index);

        if ((run.type !== 'production_forward') && (run.type !== 'production_reverse')) {
            return null;
        }
        const orderExecution = this.findOrderExecution(run);

        let points = routeVariant ? _.filter(routeVariant[(run.type === 'production_forward') ? 'forward_points' : 'reverse_points'], {point_type: 'stop_point'}) : [];
        /*points = _.filter(points, (point, index) => {
            return this.getExecutionPoint(run, index) || false;
        });*/

        return (
            <div key={run.uuid}
                 className={(run.type === 'production_forward') ? 'snake-sector forward' : 'snake-sector backward'}>
                <div className={classNames('snake-content', {
                    'show-minimum': (points.length !== 0) ? (this.state.contentWidth / points.length <= 50) : false,
                })}>
                    <div className="snake-number">{number}</div>
                    <div className="snake-line">
                        <div className="snake-points">
                            {points.map(this.renderPoint.bind(this, run, points.length, routeVariant, number))}
                        </div>
                    </div>
                    <div className="snake-operations">
                        {_.map(operations, ::this.renderOperation)}
                    </div>
                </div>
                <div className="snake-info">
                    <div>
                        <span className="snake-info__title">
                            {_.get(routeVariant, 'name')}
                            {_.get(routeVariant, 'deleted_at') ? (
                                <span className="route-variant-deleted">(удален)</span>
                            ) : null}
                            {(currentUser.user.roles.length === 1  && currentUser.user.roles[0].name === window.RNIS_SETTINGS.FREEACCOUNTROLENAME) ? null : <span className="snake-info__icon-map" onClick={this.onMapOpen.bind(this, run)}/>}
                        </span>
                        <div className="snake-info__type">
                            {(run.type === 'production_forward') ? 'прямой' : 'обратный'}
                        </div>
                        <div>
                            {((run.is_checked && (run.check_type === 'auto')) || _.get(orderExecution, 'is_checked')) ? (
                                <div className="snake-info__select">Зачтен автоматически</div>
                            ) : (
                                (run.check_type === 'semimanual') ? (
                                    <div className="snake-info__select">Зачтен вручную по КП</div>
                                ) : (
                                    <div>
                                        <div className="snake-info__checkbox">
                                            {(this.props.readonly || !this.props.canManualEdit) ? (
                                                <State positive={run.is_checked}/>
                                            ) : (
                                                <Checkbox
                                                    checked={run.is_checked}
                                                    label={run.is_checked ? 'Зачет' : 'Незачет'}
                                                    onChange={this.onRunCheckToggle.bind(this, run)}
                                                />
                                            )}
                                        </div>
                                        <div className="snake-info__select">
                                            {(this.props.readonly) ? (
                                                _.get(_.find(run.is_checked ? this.props.run_check_reasons : this.props.run_uncheck_reasons, {value: run.run_check_reason_uuid}), 'label', 'Причина не указана')
                                            ) : (
                                                <Select
                                                    value={run.run_check_reason_uuid}
                                                    placeholder={run.is_checked ? 'Основание' : 'Причина'}
                                                    options={run.is_checked ? this.props.run_check_reasons : this.props.run_uncheck_reasons}
                                                    onChange={this.onRunCheckReasonChange.bind(this, run)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderOperation(run) {
        const inputs = !currentUser.user.free_user_settings.opDispatching ? ( <div key={run.uuid} className="snake-operation">
            <div className="b-block">
                <div className="b-block__title">{runs[run.type]}</div>
                {this.props.readonly ? (
                    run.time
                ) : (
                    <Input
                        size="md"
                        value={run.time}
                        positive
                        onChange={this.onChangeRunTime.bind(this, run)}
                    />
                )}
            </div>
        </div>) : null;
        return (
            inputs
        );
    }

    renderRun(run) {
        const {order, route_variants} = this.props;

        const originalRuns = _.values(_.filter(_.flatten(_.map(order.shifts, 'runs')), (run) => {
            return (run.type === 'production_forward') || (run.type === 'production_reverse');
        }));

        const number = _.findIndex(originalRuns, {uuid: run.uuid}) + 1;
        const routeVariant = _.get(route_variants, run.route_variant_uuid);

        if ((run.type !== 'production_forward') && (run.type !== 'production_reverse')) {
            return (
                <div key={run.uuid} className="run">
                    <div className="run__info">
                        <div className="run__name">
                            {_.get(routeVariant, 'name', runs[run.type])}
                        </div>
                        <div className="run__time">
                            {moment(run.date_from).add(currentUser.user.is_timezoneoffset, 'hours').format(formats.TIME)}-{moment(run.date_to).add(currentUser.user.is_timezoneoffset, 'hours').format(formats.TIME)}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div key={run.uuid} className="run">
                <div className="run__number">{number}</div>
                <div className="run__info">
                    <div className="run__name">
                        {_.get(routeVariant, 'name', runs[run.type])}
                    </div>
                    <div className="run__type">
                        {(run.type === 'production_forward') ? 'прямой' : 'обратный'}
                    </div>
                    <div className="run__time">
                        {moment(run.date_from).format(formats.TIME)}-{moment(run.date_to).add(currentUser.user.is_timezoneoffset, 'hours').format(formats.TIME)}
                    </div>
                    <div className={classNames('snake-info__select', {
                        active: this.state.opened === run.uuid,
                    })}>
                        {(this.props.readonly || !this.props.canManualEdit) ? (
                            _.get(_.find(run.is_checked ? this.props.run_check_reasons : this.props.run_uncheck_reasons, {value: run.run_check_reason_uuid}), 'label', 'Причина не указана')
                        ) : (
                            <Select
                                onOpen={this.setOpened.bind(this, run.uuid)}
                                onClose={this.setOpened.bind(this, null)}
                                value={run.run_check_reason_uuid}
                                placeholder={'Причина отсутствия ресурсов'}
                                options={this.props.run_uncheck_reasons}
                                onChange={this.onRunCheckReasonChange.bind(this, run)}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    setOpened(uuid) {
        this.setState({
            opened: uuid,
        });
    }

    onMapOpen(run) {
        this.props.onMapOpen(run);
    }

    onChangeRunTime(run, {target: {value}}) {
        this.props.onChangeRunTime(run, value);
    }

    onRunCheckToggle(run) {
        this.props.onRunCheckToggle(run);
    }

    onRunCheckReasonChange(run, e) {
        const value = e ? e.value : null;

        this.props.onRunCheckReasonChange(run, value);
    }

    togglePopup(selectedUuid, selectedIndex) {
        if ((this.state.selectedUuid === selectedUuid) && (this.state.selectedIndex === selectedIndex)) {
            selectedUuid = null;
            selectedIndex = null;
        }
        this.setState({
            selectedUuid,
            selectedIndex,
        });
    }

    renderPoint(run, totalCount, routeVariant, number, point, index) {
        const {stop_points} = this.props;

        const plan = this.getPlan(run, index);
        const fact = this.getFact(run, index);
        const executionPoint = this.getExecutionPoint(run, index) || point;

        if (!executionPoint) {
            return;
        }

        const checkInfo = _.find(run.stop_point_checks, {index});

        const isInTime = fact && !executionPoint.is_after_time && !executionPoint.is_before_time;
        const overshoot = fact && (executionPoint.is_after_time || executionPoint.is_before_time);

        if(isInTime) {
            run.points[index]["in_time"] = true;
        } else {
            run.points[index]["in_time"] = false;
        }

        if(overshoot) {
            run.points[index]["overshoot"] = true;
        } else {
            run.points[index]["overshoot"] = false;
        }


        return (
            <div key={`${run.uuid}:${index}`} className={classNames('snake-point', {
                'in-time': isInTime,
                'overshoot': overshoot,
                'pass': executionPoint.is_skipped || (!isInTime && !overshoot),
                'credited': (checkInfo && checkInfo.is_checked) || isInTime || overshoot,
                'active': ((this.state.selectedUuid === run.uuid) && (this.state.selectedIndex === index)),
                'recalc-required': _.get(this.findOrderExecution(run), 'need-recalc', false),
            })}
                 style={{left: `${(totalCount > 1) ? (index / (totalCount - 1) * 100) : 0}%`}}>
                <div style={{display: `${currentUser.user.free_user_settings.hideSnakePoints ? `none` : `block`}`}}>
                <div className={classNames('snake-point__icon', {
                    'snake-point__icon_important': executionPoint.is_control || point.is_control,
                })} onClick={this.togglePopup.bind(this, run.uuid, index)}/>
                <div className="snake-point__title"><span>{_.get(stop_points, point.type_uuid)}</span></div>
                <div className="snake-point__time">
                    <div className="plan">{plan ? plan.add(currentUser.user.is_timezoneoffset, 'hours').format(formats.TIME) : '-'}</div>
                    <div className="fact">{fact ? fact.add(currentUser.user.is_timezoneoffset, 'hours').format(formats.TIME) : '-'}</div>
                </div>
                </div>
                {((this.state.selectedUuid === run.uuid) && (this.state.selectedIndex === index)) ? (
                    <div className="snake-point-popup">
                        <div className="snake-point-popup__title">
                            <div className="bold">{_.get(stop_points, point.type_uuid)}</div>
                            <div>{_.get(routeVariant, 'name')}</div>
                            <span>{(run.type === 'production_forward') ? 'прямой' : 'обратный'}</span>
                        </div>
                        <div className="snake-point-popup__info">
                            <div className="bold">Рейс №{number}</div>
                            <div className="snake-point-popup__table">
                                <div>
                                    <span>Плановое время взятия:</span>
                                    <div className="bold">{plan ? plan.format(formats.TIME_FULL) : '-'}</div>
                                </div>
                                <div>
                                    <span>Фактическое взятие:</span>
                                    <div className="bold">{fact ? fact.format(formats.TIME_FULL) : '-'}</div>
                                </div>
                            </div>
                            <div>
                                {(isInTime || overshoot || (checkInfo && checkInfo.check_type === 'auto')) ? (
                                    <div>
                                        Зачтено автоматически
                                    </div>
                                ) : (
                                    <div>
                                        <div className="snake-point-popup__button">
                                            {(this.props.readonly || !this.props.canManualEdit) ? (
                                                <State positive={checkInfo && checkInfo.is_checked}/>
                                            ) : ([
                                                <Checkbox
                                                    key={`check.${run.uuid}.${index}`}
                                                    label="Зачет"
                                                    checked={checkInfo && checkInfo.is_checked}
                                                    onChange={this.onStopPointCheckToggle.bind(this, run, index)}
                                                />,
                                                (checkInfo && checkInfo.is_checked) ? (
                                                    <Checkbox
                                                        key={`regular.${run.uuid}.${index}`}
                                                        label="Регуляр."
                                                        checked={checkInfo && checkInfo.is_regular}
                                                        onChange={this.onStopPointRegularToggle.bind(this, run, index)}
                                                    />
                                                ) : null,
                                            ])}
                                        </div>
                                        <div className="snake-point-popup__select">
                                            {(this.props.readonly || !this.props.canManualEdit) ? (
                                                (checkInfo && checkInfo.is_checked) ? _.get(_.find(this.props.run_check_reasons, {value: checkInfo && checkInfo.stop_point_check_reason_uuid}), 'label', 'Причина не указана') : '-'
                                            ) : (
                                                (checkInfo && checkInfo.is_checked) ? (
                                                    <Select
                                                        value={checkInfo && checkInfo.stop_point_check_reason_uuid}
                                                        placeholder={(checkInfo && checkInfo.is_checked) ? 'Основание' : 'Причина'}
                                                        options={this.props.run_check_reasons}
                                                        onChange={this.onStopPointCheckReasonChange.bind(this, run, index)}
                                                    />
                                                ) : null
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }

    onStopPointCheckToggle(run, index) {
        this.props.onStopPointCheckToggle(run, index);
    }

    onStopPointRegularToggle(run, index) {
        this.props.onStopPointRegularToggle(run, index);
    }

    onStopPointCheckReasonChange(run, index, e) {
        const value = e ? e.value : null;

        this.props.onStopPointCheckReasonChange(run, index, value);
    }

    getStopPointIndex(run, index) {
        const {route_variants} = this.props;
        const routeVariant = _.get(route_variants, run.route_variant_uuid);

        let points = (run.type === 'production_reverse') ? routeVariant.reverse_points : routeVariant.forward_points;
        points = _.filter(points, (point) => {
            return point.point_type === 'stop_point' || point.point_type === 'stop_point_inactive';
        });

        if (index >= points.length || points[index].point_type === 'stop_point_inactive') {
            return null;
        }

        return _.filter(points, (point, _index) => {
            return (_index <= index) && (point.point_type === 'stop_point');
        }).length - 1;
    }

    findOrderExecution(run) {
        return _.find(this.props.order_executions, {order_run_uuid: run.uuid});
    }

    getPlan(run, index) {
        return this.getTime(run, index);
        const stopPointIndex = this.getStopPointIndex(run, index);

        const orderExecution = this.findOrderExecution(run);
        if (!orderExecution) {
            return null;
        }
        const points = _.filter(orderExecution.data, {point_type: 'stop_point'});

        const time = _.get(points, `${stopPointIndex}.time_plan`);
        if (!time) {
            return null;
        }

        return moment(time);
    }

    getTime(run, stopPointIndex) {
        const {route_variants} = this.props;
        const routeVariant = _.get(route_variants, run.route_variant_uuid);
        let points = (run.type === 'production_reverse') ? routeVariant.reverse_points : routeVariant.forward_points;
        points = _.filter(points, (point) => {
            return point.point_type === 'stop_point' || point.point_type === 'stop_point_inactive';
        });

        if (stopPointIndex >= points.length || points[stopPointIndex].point_type === 'stop_point_inactive') {
            return null;
        }

        stopPointIndex -= _.filter(points, (point, index) => {
            return point.point_type === 'stop_point_inactive' && index < stopPointIndex;
        }).length;

        const time = _.sumBy(_.filter(run.production_interval_map, (item) => {
            return item.index < stopPointIndex;
        }), (item) => _.toInteger(item.interval));

        return moment(run.date_from).add(time, 'minutes');
    }

    getFact(run, index) {
        const stopPointIndex = this.getStopPointIndex(run, index);

        const orderExecution = this.findOrderExecution(run);
        if (!orderExecution) {
            return null;
        }
        const points = _.filter(orderExecution.data, {point_type: 'stop_point'});

        const time = _.get(points, `${stopPointIndex}.time_fact`);
        if (!time) {
            return null;
        }

        return moment(time);
    }

    getExecutionPoint(run, index) {
        const stopPointIndex = this.getStopPointIndex(run, index);

        const orderExecution = this.findOrderExecution(run);
        if (!orderExecution) {
            return null;
        }
        const points = _.filter(orderExecution.data, {point_type: 'stop_point'});

        return _.get(points, stopPointIndex);
    }

    getOrderPoint(run, index) {
        const stopPoints = _.filter(run.points, {point_type: 'stop_point'});
        return _.get(stopPoints, index);
    }
}