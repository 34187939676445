import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import GlobalLoaderComponent from "components/ui/global-loader";
import LoaderComponent from "components/ui/loader";
import * as alerts from "helpers/alerts";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getSchedule, exportExcelSchedule} from "store/reducers/kiutr/schedules/schedules";
import formats from "dictionaries/formats";
import moment from "moment";
import './view.less';
import {getScheduleTurns} from "store/reducers/kiutr/schedules/schedule_turns";
import Button from "components/ui/button";
import Page from 'components/ui/page';
import {getRouteVariants} from "store/reducers/routes/route_variants";
import {getStopPoints} from "store/reducers/geo/stop-points";
import IconButton from "components/ui/icon-button";
import {print} from "helpers/print";
import ReactDOMServer from 'react-dom/server';
import KiutrRouteSchedulePrintComponent from "components/modules/kiutr/routes/schedule/print/schedule";
import KiutrRouteScheduleOfficialPrintComponent from "components/modules/kiutr/routes/schedule/print/schedule_official";
import KiutrRouteSchedulePrintOfficialNewComponent from "components/modules/kiutr/routes/schedule/print/shedule_official_new";
import ContextTooltip from "components/ui/context-tooltip";
import {getDefaultVariant, getGroupsFromRoute} from "helpers/kiutr";
import runs from "dictionaries/runs";
import {getRouteVariantNullRuns} from "store/reducers/kiutr/route_variant_null_run";
import {getUserGeoObjects} from "store/reducers/user-map-objects/object_editor";
import {getScheduleSwitches} from "store/reducers/kiutr/schedules/schedule_switches";
import {getRoute, getRoutes} from "store/reducers/routes/route_editor";
import currentUser from 'helpers/current-user';
import ScheduleCheckModal from "components/modules/kiutr/routes/schedule/check/ScheduleCheckModal";
import {timeCorrection} from "helpers/kiutr";
import {formatMileage} from "helpers/math";
import {api} from "helpers/api";
import {makeResponse} from "helpers/response";
import download from "downloadjs";

@connect(state => ({}), {
    getSchedule,
    getScheduleTurns,
    getRouteVariants,
    getRouteVariantNullRuns,
    getStopPoints,
    getUserGeoObjects,
    getScheduleSwitches,
    getRoutes,
    getRoute,
    exportExcelSchedule,
})

export default class KiutrRouteScheduleViewComponent extends Component {

    state = {
        routeUuid: null,
        route: {},
        scheduleUuid: null,
        schedule: null,
        loading: false,
        turns: [],
        nullRuns: [],
        nullObjects: [],
        defaultVariant: null,
        defaultStopPoints: {},
        routeVariants: [],
        scheduleSwitches: {},
        scheduleSwitchesTo: {},
        routes: {},
        isSortByTime: false,
        exportExcelLoading: false,
    };

    async componentWillUpdate(props, state) {
        if (props.params.uuid !== state.routeUuid) {
            await this.setState({routeUuid: props.params.uuid});
            this.loadRoute();
            this.loadScheduleSwitches();
            this.loadScheduleSwitchesTo();
        }
        if (props.params.scheduleUuid !== state.scheduleUuid) {
            await this.setState({scheduleUuid: props.params.scheduleUuid});
            this.loadSchedule();
        }
    }

    async loadScheduleSwitches() {
        const response = await this.props.getScheduleSwitches({
            filters: {
                withRouteFrom: this.state.routeUuid,
            },
        });

        if (response.isOk) {
            this.setState({
                scheduleSwitches: _.keyBy(response.payload.items, 'uuid'),
            });
            this.loadRoutes(_.map(response.payload.items, 'to_route_uuid'));
        } else {
            response.showErrors();
        }
    }

    async loadScheduleSwitchesTo() {
        const response = await this.props.getScheduleSwitches({
            filters: {
                withRouteTo: this.state.routeUuid,
            },
        });
        if (response.isOk) {
            this.setState({
                scheduleSwitchesTo: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadRoutes(uuids) {
        const response = await this.props.getRoutes({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            this.setState({
                routes: _.keyBy(response.payload.items, 'uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadRoute() {
        const response = await this.props.getRoute(this.state.routeUuid);
        if (response.isOk) {
            this.setState({
                route: response.payload,
            });
            this.loadRouteVariant(response.payload);
        } else {
            response.showErrors();
        }
    }

    async loadRouteVariant(route) {
        const response = await this.props.getRouteVariants({
            filters: {
                withTrashed: true,
                withRoute: this.state.routeUuid,
            },
        });
        if (response.isOk) {
            const defaultVariant = getGroupsFromRoute(route, response.payload.items);
            this.setState({
                defaultVariant,
                routeVariants: response.payload.items,
            });
            this.loadNullRuns(_.map(response.payload.items, 'uuid'));
            this.loadStopPoints(_.map(defaultVariant, 'stop_point_uuid'));
        } else {
            response.showErrors();
        }
    }

    async loadStopPoints(uuids) {
        const response = await this.props.getStopPoints({
            filters: {
                withUuid: _.uniq(_.filter(uuids)),
            },
        });
        if (response.isOk) {
            this.setState({
                defaultStopPoints: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'title'),
            });
        } else {
            response.showErrors();
        }
    }

    componentDidMount() {
        this.forceUpdate();
    }

    async loadSchedule() {
        this.setState({loading: true});
        const response = await this.props.getSchedule(this.state.scheduleUuid);
        if (response.isOk) {
            await this.setState({
                schedule: response.payload,
            });
            await this.loadTurns();

            this.setState({loading: false});
        } else {
            response.showErrors();
        }
    }


    async loadTurns() {
        const response = await this.props.getScheduleTurns({
            order: {
                column: 'number',
                direction: 'asc',
            },
            filters: {
                withSchedule: this.state.scheduleUuid,
            },
        });
        if (response.isOk) {
            const turns = currentUser.user.is_timecorrection ? timeCorrection(response.payload.items) : response.payload.items;
            await this.setState({
                turns,
            });
        } else {
            response.showErrors();
        }
    }

    async loadNullRuns(uuids) {
        const response = await this.props.getRouteVariantNullRuns({
            filters: {
                withRouteVariants: uuids,
            },
        });

        if (response.isOk) {
            const nullRuns = _.keyBy(response.payload.items, 'uuid');
            await this.setState({
                nullRuns,
            });

            this.loadUserGeoObjects();
        } else {
            response.showErrors();
        }
    }

    async loadUserGeoObjects() {
        const uuids = this.getNullRunObjectsInUse();

        const response = await this.props.getUserGeoObjects({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            this.setState({
                nullObjects: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    isNullRunSeparated() {
        return _.get(this.state.schedule, 'is_null_run_separated');
    }

    render() {

        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;

        return (
            <Page title="Расписание маршрута"
                  pageId="ScheduleView"
                  headerActions={this.renderHeaderActions()}
            >
                {loader}
                {this.state.schedule ? this.renderSchedule() : null}
            </Page>
        );
    }

    renderHeaderActions() {
        return [
            (currentUser.can('com.rnis.system.permission.audit', 'read')) ? (
                <ContextTooltip key="kiutr-routes-schedule.audit" code="kiutr-routes-schedule.audit" default="Аудит">
                    <IconButton icon="history" onClick={::this.gotoAudit}/>
                </ContextTooltip>
            ) : null,
            (window.RNIS_SETTINGS.OFFICIALPRINT ? (
                <ContextTooltip key="official-kiutr-routes-schedule.print" code="official-kiutr-routes-schedule.print"
                                default="Печать на бланке">
                    <IconButton icon="print" onClick={::this.printOfficial}/>
                </ContextTooltip>
            ) : null),
            <ContextTooltip key="kiutr-routes-schedule.print" code="kiutr-routes-schedule.print" default="Печать">
                <IconButton icon="print" onClick={::this.print}/>
            </ContextTooltip>,
            (this.state.exportExcelLoading ? <div className="excelLoader"><LoaderComponent color="red"/></div> : <ContextTooltip key="base-table-list.export" code="base-table-list.export"
                            default="Экспорт в Excel">
                <IconButton icon="export" onClick={::this.exportToXls}/>
            </ContextTooltip>),
            <div key="kiutr-routes-schedule.check">
                <ContextTooltip key="kiutr-routes-schedule.check" code="kiutr-routes-schedule.check"
                                default="Проверка расписаний" position="left">
                    <IconButton icon="calendar" onClick={::this.toggleScheduleCheck}/>
                </ContextTooltip>
                {this.state.scheduleCheckActive ? this.renderScheduleCheck() : null}
            </div>,
            <ContextTooltip key="kiutr-routes-schedule.ellipse" code="kiutr-routes-schedule.ellipse"
                            default="Контроль движения на маршруте" position="left">
                <IconButton icon="ellipse" onClick={::this.gotoEllipse}/>
            </ContextTooltip>,
            <ContextTooltip key="kurs.task.back" code="kurs.task.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.close}/>
            </ContextTooltip>,
        ];
    }

    toggleScheduleCheck() {
        this.setState({
            scheduleCheckActive: !this.state.scheduleCheckActive,
        });
    }

    hideScheduleCheck() {
        this.setState({
            scheduleCheckActive: false,
        });
    }

    gotoAudit() {
        this.props.router.push(`/system/audit/${this.props.params.scheduleUuid}`);
    }

    renderScheduleCheck() {
        return (
            <ScheduleCheckModal
                uuid={this.props.params.scheduleUuid}
                shifts={this.getShifts()}
                route={this.state.route}
                onClose={::this.hideScheduleCheck}
            />
        );
    }

    async exportToXls() {
        this.setState({
            exportExcelLoading: true,
        })
        const schedule_uuid = this.props.params.scheduleUuid;
        const route_uuid = this.state.routeUuid;
        const response = await this.props.exportExcelSchedule({schedule_uuid, route_uuid});
        this.setState({
            exportExcelLoading: false,
        })
        if (!response.data || response.data.status === "failed" || response.data.success === false) {
            alerts.error('Ошибка. Не удалось сделать экспорт отчета');
            return;
        }
        if (response.data.status === "completed") {
            const alert = alerts.loading('Конвертация в .xls ...');
            alert('show');
            const responseConverter = await makeResponse(() => {
                return api.converter.convertHtmlToXls(response.data.content);
            });
            if (responseConverter.isOk) {
                const content = responseConverter.payload.content;
                download(`data:application/excel;base64,${content}`, `Экспорт ${moment().format(formats.DATETIME)}.xls`);
            } else {
                responseConverter.showErrors();
            }
            alert('hide');
        }
    }

    printOfficial() {
        const style = `
        <style>
            @page {
                size: A4;
                margin: 0;
            }

            h1 {
                text-align: center;
                font-size: 18px;
                font-weight: normal;
            }

            .period {
                text-align: center;
                margin-bottom: 24px;
            }
            
            table.turnOfficialPrint {
                border-collapse: collapse;
            }
            td, th {
                border: 1px solid black;
                text-align: center;
            }
            td:nth-child(1), th::nth-child(1),
            td:nth-child(2), th::nth-child(2) {
                width: 19%;
            }
            td:nth-child(3), th::nth-child(3) {
                width: 26%;
            }
            td:nth-child(4), th::nth-child(4) {
                width: 11%;
            }
            td:nth-child(5), th::nth-child(5) {
                width: 9%;
            }
            td:nth-child(6), th::nth-child(6) {
                width: 16%;
            }

            .rowContent td span {
                display: none;
            }
            .rowContent:first-child td:first-child span{
                display: block;
            }

            table.turnOfficialPrint tfoot tr td {
                border: none;
                padding: 24px 0;
            }
        </style>
    `;


        print(style + ReactDOMServer.renderToStaticMarkup(<KiutrRouteSchedulePrintOfficialNewComponent
            {...this.state}
        />));
    }

    print() {
        const style = `
            <style>
                @page {
                    size: A3 landscape;
                }
                
                table {
                    width: 100%;
                    border-collapse: collapse;
                    text-align: center;
                }
                
                th, td {
                    border: 1px black solid !important;
                    padding: 5px;
                }
                
                .route-days {
                    margin-bottom: 20px;
                }
                
                .stop-point-key {
                    font-weight: bold;
                }
                
                .page-break { 
                    page-break-after: always;
                }
            </style>
        `;

        print(style + ReactDOMServer.renderToStaticMarkup(<KiutrRouteSchedulePrintComponent
            {...this.state}
        />));
    }

    isUsed(routeVariantUuid, stopPointUuid, index, isForward) {
        return _.filter(this.state.route.groups || [], (group) => {
            if (_.indexOf(this.state.route.spiral || [], group.uuid) === -1) {
                return false;
            }
            return _.filter(group.items, (item) => {
                return (item.stop_point_uuid === stopPointUuid) && (_.filter(item.inclusions, {
                    route_variant_uuid: routeVariantUuid,
                    is_forward: isForward,
                    index,
                }).length > 0);
            }).length > 0;
        }).length > 0;
    }

    getUnusedStopPoints() {
        const routeVariantUuids = _.uniq(_.filter(_.map(_.flatten(_.map(this.state.turns, 'runs')), 'route_variant_uuid')));
        const routeVariants = _.filter(this.state.routeVariants, (routeVariant) => {
            return _.indexOf(routeVariantUuids, routeVariant.uuid) !== -1;
        });

        return _.filter(_.mapValues(_.keyBy(routeVariants, 'uuid'), (routeVariant) => {
            const unusedForward = _.filter(_.map(_.filter(routeVariant.forward_points, {point_type: 'stop_point'}), (point, index) => {
                return {
                    used: !this.isUsed(routeVariant.uuid, point.type_uuid, index, true),
                    index,
                    uuid: point.type_uuid,
                };
            }), {
                used: true,
            });
            const unusedReverse = _.filter(_.map(_.filter(routeVariant.reverse_points, {point_type: 'stop_point'}), (point, index) => {
                return {
                    used: !this.isUsed(routeVariant.uuid, point.type_uuid, index, false),
                    index,
                    uuid: point.type_uuid,
                };
            }), {
                used: true,
            });

            return {
                unusedForward,
                unusedReverse,
            };
        }), item => item.unusedForward.length || item.unusedReverse.length);
    }

    renderSchedule() {
        const days = this.getDays();

        const unusedPointsRaw = this.getUnusedStopPoints();
        const unusedPoints = _.uniq(_.concat(_.map(_.flatten(_.map(unusedPointsRaw, 'unusedForward')), 'uuid'), _.map(_.flatten(_.map(unusedPointsRaw, 'unusedReverse')), 'uuid')));

        return (
            <div>
                <div className="page-title">
                    Маршрут №{this.state.schedule.route_number} {this.state.schedule.route_name}
                </div>
                <div className="page-block">
                    <p>
                        <span className="bold">Время действия расписания </span>
                        с {moment(this.state.schedule.date_from).format(formats.DATE)}
                        &nbsp;
                        по {this.state.schedule.date_to ? moment(this.state.schedule.date_to).format(formats.DATE) : '-'}
                        <span className="days">{days.join(', ')}</span>
                    </p>
                    {(unusedPoints.length > 0) ? (
                        <p><span className="bold">Не включенных ОП:</span> {unusedPoints.length}</p>
                    ) : null}
                    {this.state.turns.map(::this.renderTurnInfo)}
                    <div className="buttons">
                        {
                            window.RNIS_SETTINGS.sort_route_schedule ? (
                                <span>{this.state.isSortByTime ? 'Сортировка по времени' : 'Сортировка по выходам'}</span>
                            ) : null
                        }
                        <Button text="Перейти на графический вариант" width="auto" size="md"
                                shadow="red"
                                onClick={::this.gotoGraphic}/>
                        <Button text="Просмотр по выходам" width="auto" size="md" shadow="red"
                                onClick={::this.showTurns}/>
                        {
                            window.RNIS_SETTINGS.sort_route_schedule ? (
                                <Button text="Изменить сортировку" width="auto" size="md" shadow="red"
                                    onClick={::this.toggleSort}/>
                            ) : null
                        }
                    </div>
                </div>

                {this.renderCircleRuns()}

                {this.renderSummary()}
                <br/>

                {this.renderSwitches()}
            </div>
        );
    }

    renderTurnInfo(turn, index) {
        const shifts = _.filter(turn.runs, {type: 'reshift'}).length + 1;
        const dinner = _.find(turn.runs, {type: 'dinner'});
        const dinnerTime = dinner ? (`${dinner.start_time} - ${moment(dinner.start_time, formats.TIME).add(dinner.time, 'minutes').format(formats.TIME)}`) : '-';
        const reshifts = _.filter(turn.runs, {type: 'reshift'});
        const reshiftsTime = _.map(reshifts, (run) => `${run.start_time} - ${moment(run.start_time, formats.TIME).add(run.time, 'minutes').format(formats.TIME)}`);

        const visibleRuns = _.filter(turn.runs, run => {
            return (run.type !== 'production_forward') && (run.type !== 'production_reverse') && (run.type !== 'null');
        });

        return (
            <div key={index}>
                <p>
                    <span className="bold">Выход {turn.number}</span> ({shifts}см. выход {turn.start_at};
                    возврат {turn.end_at};
                    &nbsp;
                    {_.map(visibleRuns, (run) => {
                        return `${runs[run.type]} ${run.start_time} - ${moment(run.start_time, formats.TIME).add(run.time, 'minutes').format(formats.TIME)}`;
                    }).join('; ')}
                    )
                </p>
            </div>
        );
    }

    renderCircleRuns() {
        let turns = this.state.turns;
        _.each(turns, (turn, index) => {
            _.each(turn.runs, (run) => {
                //run.turn_number = index + 1;
                run.turn_number = turn.number;
            });
        });

        const runs = _.flatten(_.map(this.state.turns, 'runs'));

        let productionForwardRuns = _.reverse(_.clone(_.filter(runs, (run) => {
            if (run.type === 'production_forward') {
                return true;
            }
            if (run.type === 'null') {
                const nullRun = this.state.nullRuns[run.route_variant_null_run_uuid];
                if (nullRun && nullRun.is_forward) {
                    return true;
                }
            }
            return false;
        })));

        let productionReverseRuns = _.filter(runs, (run) => {
            if (run.type === 'production_reverse') {
                return true;
            }
            if (run.type === 'null') {
                const nullRun = this.state.nullRuns[run.route_variant_null_run_uuid];
                if (nullRun && !nullRun.is_forward) {
                    return true;
                }
            }
            return false;
        });

        if(window.RNIS_SETTINGS.sort_route_schedule && this.state.isSortByTime) {
            productionForwardRuns = _.sortBy(productionForwardRuns, 'start_time').reverse();
            productionReverseRuns = _.sortBy(productionReverseRuns, 'start_time');
        }

        const forwardCount = productionForwardRuns.length - _.filter(productionForwardRuns, {type: 'null'}).length;
        let forwardIndex = -1;
        let reverseIndex = -1;

        return (
            <div>
                <TableContainer>
                    <div className="Table">
                        <table className="b-table b-table-thead-no-hover">
                            <thead>
                            <tr className="border-top-bold-2 border-bottom-bold">
                                {productionForwardRuns.map((run, index) => {

                                    if (run.type !== 'null') {
                                        forwardIndex++;
                                    }
                                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                                        return null;
                                    }
                                    return (
                                        <th key={`forward:${index}`} width="100px">
                                            {(run.type === 'null') ? 0 : (forwardCount - forwardIndex)}
                                        </th>
                                    );
                                })}
                                <th className="border-left-bold-2 border-bottom-bold-2" rowSpan="3">Расст.км</th>
                                <th className="border-left-bold border-bottom-bold-2" rowSpan="3">Время.мин</th>
                                <th className="border-left-bold">Кругорейсы</th>
                                <th className="border-left-bold border-bottom-bold-2" rowSpan="3">Время.мин</th>
                                <th className="border-left-bold border-right-bold border-bottom-bold-2" rowSpan="3">
                                    Расcт.км
                                </th>
                                {productionReverseRuns.map((run, index) => {
                                    if (run.type !== 'null') {
                                        reverseIndex++;
                                    }
                                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                                        return null;
                                    }
                                    return (
                                        <th key={`reverse:${index}`} width="100px">
                                            {(run.type === 'null') ? 0 : (reverseIndex + 1)}
                                        </th>
                                    );
                                })}
                            </tr>
                            {this.renderTurnsRow(productionForwardRuns, productionReverseRuns)}
                            {this.renderRouteVariantRow(productionForwardRuns, productionReverseRuns)}
                            </thead>
                            <tbody>
                            {this.state.nullObjects.map(this.renderNullObjectRow.bind(this, productionForwardRuns, productionReverseRuns))}
                            {this.state.defaultVariant && _.map(this.state.defaultVariant, this.renderPointRow.bind(this, productionForwardRuns, productionReverseRuns, this.state.defaultVariant))}
                            {this.renderDistanceRow(productionForwardRuns, productionReverseRuns)}
                            {this.renderTimeRow(productionForwardRuns, productionReverseRuns)}
                            </tbody>
                        </table>
                    </div>
                </TableContainer>
            </div>
        );
    }

    getNullRunObjectsInUse() {
        const nullRuns = this.getNullRunsInUse();
        return _.map(nullRuns, (nullRun) => {
            if (nullRun.is_forward) {
                return nullRun.points[0].type_uuid;
            } else {
                return _.last(nullRun.points).type_uuid;
            }
        });
    }

    getNullRunsWithObject(objectUuid) {
        return _.filter(this.state.nullRuns, (nullRun) => {
            if (nullRun.is_forward) {
                return nullRun.points[0].type_uuid === objectUuid;
            } else {
                return _.last(nullRun.points).type_uuid === objectUuid;
            }
        });
    }

    getNullRunsInUse() {
        const uuids = _.filter(_.uniq(_.map(_.filter(_.flatten(_.map(this.state.turns, 'runs')), {type: 'null'}), 'route_variant_null_run_uuid')));
        return _.filter(this.state.nullRuns, (nullRun) => {
            return _.indexOf(uuids, nullRun.uuid) !== -1;
        });
    }

    renderNullObjectRow(productionForwardRuns, productionReverseRuns, nullObject) {
        const nullRunsWithObject = this.getNullRunsWithObject(nullObject.uuid);
        const forwardNullRun = _.first(_.filter(nullRunsWithObject, {is_forward: false}));
        const reverseNullRun = _.first(_.filter(nullRunsWithObject, {is_forward: true}));

        return (
            <tr key={nullObject.uuid}>
                {productionForwardRuns.map((run, index) => {
                    if (!this.isNullRunSeparated()) {
                        if (run.type === 'null') {
                            return null;
                        }
                        const siblingRun = _.get(productionForwardRuns, index + 1) || _.get(productionForwardRuns, index - 1);
                        if (siblingRun) {
                            if (siblingRun.type === 'null') {
                                return (
                                    <td key={`forward:${index}`}>
                                        {(_.indexOf(_.map(nullRunsWithObject, 'uuid'), siblingRun.route_variant_null_run_uuid) !== -1) ? siblingRun.start_time : ''}
                                    </td>
                                );
                            }
                        }
                    }

                    return (
                        <td key={`forward:${index}`}>
                            {(run.type === 'null' && _.indexOf(_.map(nullRunsWithObject, 'uuid'), run.route_variant_null_run_uuid) !== -1) ? run.start_time : ''}
                        </td>
                    );
                })}
                <td className="border-left-bold-2">{reverseNullRun ? this.formatDistance(reverseNullRun.distance) : ''}</td>
                <td className="border-left-bold">{reverseNullRun ? reverseNullRun.time : ''}</td>
                <td className="border-left-bold">{nullObject.title}</td>
                <td className="border-left-bold">{forwardNullRun ? forwardNullRun.time : ''}</td>
                <td className="border-left-bold border-right-bold">{forwardNullRun ? this.formatDistance(forwardNullRun.distance) : ''}</td>
                {productionReverseRuns.map((run, index) => {
                    if (!this.isNullRunSeparated()) {
                        if (run.type === 'null') {
                            return null;
                        }
                        const siblingRun = _.get(productionReverseRuns, index + 1) || _.get(productionReverseRuns, index - 1);
                        if (siblingRun) {
                            if (siblingRun.type === 'null') {
                                return (
                                    <td key={`reverse:${index}`}>
                                        {(_.indexOf(_.map(nullRunsWithObject, 'uuid'), siblingRun.route_variant_null_run_uuid) !== -1) ? moment(siblingRun.start_time, formats.TIME).add(siblingRun.time, 'minutes').format(formats.TIME) : ''}
                                    </td>
                                );
                            }
                        }
                    }

                    return (
                        <td key={`reverse:${index}`}>
                            {(run.type === 'null' && _.indexOf(_.map(nullRunsWithObject, 'uuid'), run.route_variant_null_run_uuid) !== -1) ? moment(run.start_time, formats.TIME).add(run.time, 'minutes').format(formats.TIME) : ''}
                        </td>
                    );
                })}
            </tr>
        );
    }

    findPointByGroup(group, index, isForward, groups) {
//        console.log(index)
        if ((isForward && index >= groups.length - 1) || (!isForward && index <= 0)) {
           // console.log (`${isForward} ${index}`)
            return null;
        }

        const nextGroup = isForward ? groups[index + 1] : groups[index - 1];
        const currentStopPoints = _.map(group.items, 'stop_point_uuid');
        const nextStopPoints = _.map(nextGroup.items, 'stop_point_uuid');

        let result = null;
        _.each(this.state.routeVariants, (routeVariant) => {
            const points = _.filter(_.concat(routeVariant.forward_points, routeVariant.reverse_points), {point_type: 'stop_point'});
            for (let i = 0; i < points.length - 1; i++) {
                if ((_.indexOf(currentStopPoints, points[i].type_uuid) !== -1) && (_.indexOf(nextStopPoints, points[i + 1].type_uuid) !== -1)) {
                    result = points[i];
                    return false;
                }
            }
        });

        return result;
    }

    renderPointRow(productionForwardRuns, productionReverseRuns, groups, group, index) {

        const forwardPoint = this.findPointByGroup(group, index, true, groups);
        const reversePoint = this.findPointByGroup(group, index, false, groups);

        return (
            <tr key={index}>
                {productionForwardRuns.map((run, _index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <td key={`forward:${index}:${_index}`}>{this.renderPointTime(group, run)}</td>
                    );
                })}
                <td className="border-left-bold-2">{this.formatDistance(_.get(forwardPoint, 'distance_to_the_next_point', 0)) || '0'}</td>
                <td className="border-left-bold">{_.get(forwardPoint, 'time_to_get_to_the_next_point', 0)}</td>
                <td className="border-left-bold">{group.name}</td>
                <td className="border-left-bold">{_.get(reversePoint, 'time_to_get_to_the_next_point', 0)}</td>
                <td className="border-left-bold border-right-bold">{this.formatDistance(_.get(reversePoint, 'distance_to_the_next_point', 0)) || '0'}</td>
                {productionReverseRuns.map((run, _index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <td key={`reverse:${index}:${_index}`}>{this.renderPointTime(group, run)}</td>
                    );
                })}
            </tr>
        );
    }

    renderPointTime(group, run) {
        if (run.type === 'null') {
            const nullRun = this.state.nullRuns[run.route_variant_null_run_uuid];
            if (nullRun) {
                if (_.filter(nullRun.points, {type_uuid: group.stop_point_uuid}).length > 0) {
                    return nullRun.is_forward ? moment(run.start_time, formats.TIME).add(run.time, 'minutes').format(formats.TIME) : run.start_time;
                }
            }
            return null;
        }

        let point = null;
        _.each(group.items, (item) => {
            point = _.find(item.inclusions, {
                route_variant_uuid: run.route_variant_uuid,
                is_forward: run.type === 'production_forward',
            });
            if (point) {
                return false;
            }
        });

        if (!point) {
            return null;
        }

        const time = _.sumBy(_.filter(run.production_interval_map, (item) => {
            return item.index < point.index;
        }), 'interval');

        const timeObject = moment(run.start_time, formats.TIME).add(time, 'minutes');

        return timeObject.format(formats.TIME);
    }

    renderTurnsRow(productionForwardRuns, productionReverseRuns) {
        return (
            <tr className="border-bottom-bold-2">
                {productionForwardRuns.map((run, index) => {
                    if(run.turn_number === 4) {
                       // console.log('run', run)
                    }
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <th key={`forward:${index}`}>{run.turn_number}</th>
                    );
                })}

                <th className="border-left-bold">Выходы</th>

                {productionReverseRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <th key={`reverse:${index}`}>{run.turn_number}</th>
                    );
                })}
            </tr>
        );
    }

    renderRouteVariantRow(productionForwardRuns, productionReverseRuns) {
        return (
            <tr className="border-bottom-bold-2 wrap-normal">
                {productionForwardRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <th key={`forward:${index}`}>{_.get(_.find(this.state.routeVariants, {uuid: run.route_variant_uuid}), 'name')}</th>
                    );
                })}

                <th className={`border-left-bold${window.RNIS_SETTINGS.CITY_TULA && !productionReverseRuns.length ? ' border-right-bold' : null}`}>Вариант движения</th>

                {productionReverseRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <th key={`reverse:${index}`}>{_.get(_.find(this.state.routeVariants, {uuid: run.route_variant_uuid}), 'name')}</th>
                    );
                })}
            </tr>
        );
    }

    renderDistanceRow(productionForwardRuns, productionReverseRuns) {
        return (
            <tr className="border-top-bold-2 border-bottom-bold">
                {productionForwardRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <td key={`forward:${index}`}>
                            {(run.type !== 'null') ? this.formatDistance(run.distance) : 0}
                        </td>
                    );
                })}
                <td className="border-right-bold-2 border-left-bold-2" colSpan={`${window.RNIS_SETTINGS.CITY_TULA ? 1 : 5}`}>Пробег, км</td>
                {productionReverseRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <td key={`reverse:${index}`}>
                            {(run.type !== 'null') ? this.formatDistance(run.distance) : 0}
                        </td>
                    );
                })}
            </tr>
        );
    }

    formatDistance(distanceInMeters) {
        return Math.round((distanceInMeters / 1000) * 100) / 100;
    }

    renderTimeRow(productionForwardRuns, productionReverseRuns) {
        return (
            <tr className="border-bottom-bold-2">
                {productionForwardRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <td key={`forward:${index}`}>
                            {(run.type !== 'null') ? run.time : 0}
                        </td>
                    );
                })}
                <td className="border-right-bold-2 border-left-bold-2" colSpan={`${window.RNIS_SETTINGS.CITY_TULA ? 1 : 5}`}>Время, мин</td>
                {productionReverseRuns.map((run, index) => {
                    if (run.type === 'null' && !this.isNullRunSeparated()) {
                        return null;
                    }
                    return (
                        <td key={`reverse:${index}`}>
                            {(run.type !== 'null') ? run.time : 0}
                        </td>
                    );
                })}
            </tr>
        );
    }

    gotoGraphic() {
        this.props.router.push(`/${this.props.params.component}/routes/${this.props.params.uuid}/schedules/${this.props.params.scheduleUuid}/graphic`);
    }

    gotoEllipse() {
        this.props.router.push(`/${this.props.params.component}/routes/${this.props.params.uuid}/schedules/${this.props.params.scheduleUuid}/ellipse`);
    }

    showTurns() {
        const turnNumber = _.get(_.first(this.state.turns), 'number');
        this.props.router.push(`/${this.props.params.component}/routes/${this.props.params.uuid}/schedules/${this.props.params.scheduleUuid}/turn/${turnNumber}`);
    }

    toggleSort() {
        this.setState(prevState => ({
            isSortByTime: !prevState.isSortByTime
        }));
    }

    close() {
        this.props.router.push(`/${this.props.params.component}/routes/${this.props.params.uuid}/schedules`);
    }

    getDays() {
        let days = [];

        if (this.state.schedule.monday) {
            days.push('Пн');
        }
        if (this.state.schedule.tuesday) {
            days.push('Вт');
        }
        if (this.state.schedule.wednesday) {
            days.push('Ср');
        }
        if (this.state.schedule.thursday) {
            days.push('Чт');
        }
        if (this.state.schedule.friday) {
            days.push('Пт');
        }
        if (this.state.schedule.saturday) {
            days.push('Сб');
        }
        if (this.state.schedule.sunday) {
            days.push('Вс');
        }
        if (this.state.schedule.holiday) {
            days.push('Праздник');
        }

        return days;
    }

    renderSwitches() {
        let switches = [];

        _.each(this.state.turns, (turn) => {
            const switchRuns = _.filter(turn.runs, {type: 'switch_out'});

            _.each(switchRuns, (switchRun) => {
                const switchItem = _.get(this.state.scheduleSwitches, switchRun.schedule_switch_uuid);
                if (!switchItem) {
                    return null;
                }
                const switchRoute = _.get(this.state.routes, switchItem.to_route_uuid);

                switches.push(
                    <tr key={switchRun.uuid}>
                        <td>Переключение {switches.length + 1}</td>
                        <td>{switchRun.turn_number}</td>
                        <td>{_.get(switchRoute, 'number')}</td>
                        <td>{_.get(switchRoute, 'title')}</td>
                    </tr>
                );
            });
        });

        if (switches.length > 0) {
            return (
                <div>
                    <TableContainer>
                        <div className="Table">
                            <table className="b-table b-table-thead-no-hover">
                                <thead>
                                <tr>
                                    <th rowSpan={2}/>
                                    <th rowSpan={2}>Текущий выход (откуда)</th>
                                    <th colSpan={2}>Переключение</th>
                                </tr>
                                <tr>
                                    <th>№ маршрута</th>
                                    <th>Наименование маршрута (куда)</th>
                                </tr>
                                </thead>
                                <tbody>
                                {switches}
                                </tbody>
                            </table>
                        </div>
                    </TableContainer>
                </div>
            );
        }
    }

    getProductionRuns(turn) {
        return _.filter(turn.runs, (run) => (
            run.type === 'production_forward' || run.type === 'production_reverse'
        ));
    }

    getShifts() {
        let shifts = [];

        _.each(this.state.turns, (turn) => {
            const runs = this.getTurnRuns(turn);
            const turnRuns = _.filter(runs, (run) => run.type !== 'reshift').length;
            let shift = {
                number: 1,
                turn: turn.number,
                runs: [],
            };
            _.each(runs, (run) => {
                if (run.type === 'reshift') {
                    shift.percent = (turnRuns !== 0) ? (shift.runs.length / turnRuns) : 0;
                    shifts.push(_.cloneDeep(shift));
                    shift = {
                        number: shift.number + 1,
                        turn: turn.number,
                        runs: [],
                    };
                } else {
                    shift.runs.push(run);
                }
            });
            if (shift.runs.length > 0 || shift.number === 1) {
                shift.percent = (turnRuns !== 0) ? (shift.runs.length / turnRuns) : 0;
                shift.single = shift.number === 1;
                shifts.push(_.cloneDeep(shift));
            }
        });

        return shifts;
    }

    getRuns() {
        const runs = _.flatten(_.map(this.state.turns, 'runs'));

        return _.filter(_.map(runs, (run) => {
            if (run.type === 'switch_in') {
                const switchObject = _.find(this.state.scheduleSwitchesTo, {uuid: run.schedule_switch_uuid});
                if (switchObject) {
                    const switchTurn = _.find(_.get(switchObject, 'schedule.turns', []), (item) => {
                        return _.toInteger(item.number) === _.toInteger(switchObject.schedule_turn_number)
                    });
                    const switchRun = _.find(_.get(switchTurn, 'runs', []), {schedule_switch_uuid: run.schedule_switch_uuid});
                    if (switchRun) {
                        let _run = _.clone(switchRun);
                        _run.type = 'null';
                        return _run;
                    }
                }

                return null;
            }

            return run;
        }));
    }

    getTurnRuns(turn) {
        const runs = _.flatten(turn.runs);

        return _.filter(
            _.map(runs, (run) => {
                if (run.type === 'switch_out') {
                    return null;
                }
                if (run.type === 'switch_in') {
                    const switchObject = _.find(this.state.scheduleSwitchesTo, { uuid: run.schedule_switch_uuid });
                    if (switchObject) {
                        const switchTurn = _.find(_.get(switchObject, 'schedule.turns', []), (item) => {
                            return _.toInteger(item.number) === _.toInteger(switchObject.schedule_turn_number);
                        });
                        const switchRun = _.find(_.get(switchTurn, 'runs', []), { schedule_switch_uuid: run.schedule_switch_uuid });
                        if (switchRun) {
                            let _run = _.clone(switchRun);
                            _run.type = 'null';
                            return _run;
                        }
                    }

                    return null;
                }

                return run;
            })
        );
    }


	isUsed(routeVariantUuid, stopPointUuid, index, isForward) {
		return (
			_.filter(this.state.route.groups || [], (group) => {
				if (_.indexOf(this.state.route.spiral || [], group.uuid) === -1) {
					return false;
				}
				return (
					_.filter(group.items, (item) => {
						return (
							item.stop_point_uuid === stopPointUuid &&
							_.filter(item.inclusions, {
								route_variant_uuid: routeVariantUuid,
								is_forward: isForward,
								index
							}).length > 0
						);
					}).length > 0
				);
			}).length > 0
		);
	}

	getUnusedStopPoints() {
		const routeVariantUuids = _.uniq(_.filter(_.map(_.flatten(_.map(this.state.turns, 'runs')), 'route_variant_uuid')));
		const routeVariants = _.filter(this.state.routeVariants, (routeVariant) => {
			return _.indexOf(routeVariantUuids, routeVariant.uuid) !== -1;
		});

		return _.filter(
			_.mapValues(_.keyBy(routeVariants, 'uuid'), (routeVariant) => {
				const unusedForward = _.filter(
					_.map(_.filter(routeVariant.forward_points, { point_type: 'stop_point' }), (point, index) => {
						return {
							used: !this.isUsed(routeVariant.uuid, point.type_uuid, index, true),
							index,
							uuid: point.type_uuid
						};
					}),
					{
						used: true
					}
				);
				const unusedReverse = _.filter(
					_.map(_.filter(routeVariant.reverse_points, { point_type: 'stop_point' }), (point, index) => {
						return {
							used: !this.isUsed(routeVariant.uuid, point.type_uuid, index, false),
							index,
							uuid: point.type_uuid
						};
					}),
					{
						used: true
					}
				);

				return {
					unusedForward,
					unusedReverse
				};
			}),
			(item) => item.unusedForward.length || item.unusedReverse.length
		);
	}

	renderSchedule() {
		const days = this.getDays();
		const isInterval = this.state.schedule && this.state.schedule.is_interval;
		const unusedPointsRaw = this.getUnusedStopPoints();
		const unusedPoints = _.uniq(
			_.concat(
				_.map(_.flatten(_.map(unusedPointsRaw, 'unusedForward')), 'uuid'),
				_.map(_.flatten(_.map(unusedPointsRaw, 'unusedReverse')), 'uuid')
			)
		);
		return (
			<div>
				<div className="page-title">
					Маршрут №{this.state.schedule.route_number} {this.state.schedule.route_name}
				</div>
				{isInterval && <div className="page-subtitle">{this.state.intervalVariant}</div>}
				<div className="page-block" style={{ paddingTop: isInterval && 5, paddingBottom: 5 }}>
					<div className="page-block-wrapper">
						<p>
							<span className="bold" className="page-block-title">
								Время действия расписания{' '}
							</span>
							с {moment(this.state.schedule.date_from).format(formats.DATE)}
							&nbsp; по {this.state.schedule.date_to ? moment(this.state.schedule.date_to).format(formats.DATE) : '-'}
							{isInterval && <span className="days">{this.state.monthsTitle}</span>}
							<span className="days">{days.join(', ')}</span>
						</p>
						{unusedPoints.length > 0 && !isInterval ? (
							<p>
								<span className="bold">Не включенных ОП:</span> {unusedPoints.length}
							</p>
						) : null}
						{!isInterval && this.state.turns.map(::this.renderTurnInfo)}
						{!isInterval && (
							<div className="buttons">
								{window.RNIS_SETTINGS.sort_route_schedule && (
									<span>{this.state.isSortByTime ? 'Сортировка по времени' : 'Сортировка по выходам'}</span>
								)}
								<Button
									text="Перейти на графический вариант"
									width="auto"
									size="md"
									shadow="red"
									onClick={::this.gotoGraphic}
								/>
								<Button text="Просмотр по выходам" width="auto" size="md" shadow="red" onClick={::this.showTurns} />
								{window.RNIS_SETTINGS.sort_route_schedule && (
									<Button text="Изменить сортировку" width="auto" size="md" shadow="red" onClick={::this.toggleSort} />
								)}
							</div>
						)}
					</div>
				</div>
				<br />
				{isInterval ? (
					<IntervalTable runs={this.state.intervalTableData} routeNames={this.state.routeNames || []} />
				) : (
					this.renderCircleRuns()
				)}
				{!isInterval && this.renderSummary()}
				<br />
				{isInterval && <NormativeTable data={this.state.normativeTableData} />}
				<br />
				{isInterval && (
					<SmallIntervalTable
						intervals={this.state.smallTableIntervalsData ? this.state.smallTableIntervalsData : {}}
					/>
				)}
				<br />
				{this.renderSwitches()}
			</div>
		);
	}

	renderTurnInfo(turn, index) {
		const shifts = _.filter(turn.runs, { type: 'reshift' }).length + 1;
		const dinner = _.find(turn.runs, { type: 'dinner' });
		const dinnerTime = dinner
			? `${dinner.start_time} - ${moment(dinner.start_time, formats.TIME)
					.add(dinner.time, 'minutes')
					.format(formats.TIME)}`
			: '-';
		const reshifts = _.filter(turn.runs, { type: 'reshift' });
		const reshiftsTime = _.map(
			reshifts,
			(run) =>
				`${run.start_time} - ${moment(run.start_time, formats.TIME).add(run.time, 'minutes').format(formats.TIME)}`
		);

		const visibleRuns = _.filter(turn.runs, (run) => {
			return run.type !== 'production_forward' && run.type !== 'production_reverse' && run.type !== 'null';
		});

		return (
			<div key={index}>
				<p>
					<span className="bold">Выход {turn.number}</span> ({shifts}см. выход {turn.start_at}; возврат {turn.end_at};
					&nbsp;
					{_.map(visibleRuns, (run) => {
						return `${runs[run.type]} ${run.start_time} - ${moment(run.start_time, formats.TIME)
							.add(run.time, 'minutes')
							.format(formats.TIME)}`;
					}).join('; ')}
					)
				</p>
			</div>
		);
	}

	renderCircleRuns() {
		let turns = this.state.turns;
		_.each(turns, (turn, index) => {
			_.each(turn.runs, (run) => {
				//run.turn_number = index + 1;
				run.turn_number = turn.number;
			});
		});
		const runs = _.flatten(_.map(this.state.turns, 'runs'));

		let productionForwardRuns = _.reverse(
			_.clone(
				_.filter(runs, (run) => {
					if (run.type === 'production_forward') {
						return true;
					}
					if (run.type === 'null') {
						const nullRun = this.state.nullRuns[run.route_variant_null_run_uuid];
						if (nullRun && nullRun.is_forward) {
							return true;
						}
					}
					return false;
				})
			)
		);

		let productionReverseRuns = _.filter(runs, (run) => {
			if (run.type === 'production_reverse') {
				return true;
			}
			if (run.type === 'null') {
				const nullRun = this.state.nullRuns[run.route_variant_null_run_uuid];
				if (nullRun && !nullRun.is_forward) {
					return true;
				}
			}
			return false;
		});

		if (window.RNIS_SETTINGS.sort_route_schedule && this.state.isSortByTime) {
			productionForwardRuns = _.sortBy(productionForwardRuns, 'start_time').reverse();
			productionReverseRuns = _.sortBy(productionReverseRuns, 'start_time');
		}

		const forwardCount = productionForwardRuns.length - _.filter(productionForwardRuns, { type: 'null' }).length;
		let forwardIndex = -1;
		let reverseIndex = -1;

		return (
			<div>
				<TableContainer>
					<div className="Table">
						<table className="b-table b-table-thead-no-hover">
							<thead>
								<tr className="border-top-bold-2 border-bottom-bold">
									{productionForwardRuns.map((run, index) => {
										if (run.type !== 'null') {
											forwardIndex++;
										}
										if (run.type === 'null' && !this.isNullRunSeparated()) {
											return null;
										}
										return (
											<th key={`forward:${index}`} width="100px">
												{run.type === 'null' ? 0 : forwardCount - forwardIndex}
											</th>
										);
									})}
                                    {!window.RNIS_SETTINGS.CITY_TULA && (
                                        <th className="border-left-bold-2 border-bottom-bold-2" rowSpan="3">
                                            Расст.км
                                        </th>)}
                                    {!window.RNIS_SETTINGS.CITY_TULA && (   <th className="border-left-bold border-bottom-bold-2" rowSpan="3">
                                            Время.мин
                                        </th>)}
									<th className={`border-left-bold${window.RNIS_SETTINGS.CITY_TULA && !productionReverseRuns.length ? ' border-right-bold' : null}`}>Кругорейсы</th>
                                    {!window.RNIS_SETTINGS.CITY_TULA && (
                                        <th className="border-left-bold border-bottom-bold-2" rowSpan="3">
                                            Время.мин
                                        </th> )}
                                    {!window.RNIS_SETTINGS.CITY_TULA && (  <th className="border-left-bold border-right-bold border-bottom-bold-2" rowSpan="3">
                                            Расcт.км
                                        </th>
                                    )}
									{productionReverseRuns.map((run, index) => {
										if (run.type !== 'null') {
											reverseIndex++;
										}
										if (run.type === 'null' && !this.isNullRunSeparated()) {
											return null;
										}
										return (
											<th key={`reverse:${index}`} width="100px">
												{run.type === 'null' ? 0 : reverseIndex + 1}
											</th>
										);
									})}
								</tr>
								{this.renderTurnsRow(productionForwardRuns, productionReverseRuns)}
								{this.renderRouteVariantRow(productionForwardRuns, productionReverseRuns)}
							</thead>
							<tbody>
								{this.state.nullObjects.map(
									this.renderNullObjectRow.bind(this, productionForwardRuns, productionReverseRuns)
								)}
								{this.state.defaultVariant &&
									_.map(
										this.state.defaultVariant,
										this.renderPointRow.bind(
											this,
											productionForwardRuns,
											productionReverseRuns,
											this.state.defaultVariant
										)
									)}
								{this.renderDistanceRow(productionForwardRuns, productionReverseRuns)}
								{this.renderTimeRow(productionForwardRuns, productionReverseRuns)}
							</tbody>
						</table>
					</div>
				</TableContainer>
			</div>
		);
	}

	getNullRunObjectsInUse() {
		const nullRuns = this.getNullRunsInUse();
		return _.map(nullRuns, (nullRun) => {
			if (nullRun.is_forward) {
				return nullRun.points[0].type_uuid;
			} else {
				return _.last(nullRun.points).type_uuid;
			}
		});
	}

	getNullRunsWithObject(objectUuid) {
		return _.filter(this.state.nullRuns, (nullRun) => {
			if (nullRun.is_forward) {
				return nullRun.points[0].type_uuid === objectUuid;
			} else {
				return _.last(nullRun.points).type_uuid === objectUuid;
			}
		});
	}

	getNullRunsInUse() {
		const uuids = _.filter(
			_.uniq(
				_.map(_.filter(_.flatten(_.map(this.state.turns, 'runs')), { type: 'null' }), 'route_variant_null_run_uuid')
			)
		);
		return _.filter(this.state.nullRuns, (nullRun) => {
			return _.indexOf(uuids, nullRun.uuid) !== -1;
		});
	}

	renderNullObjectRow(productionForwardRuns, productionReverseRuns, nullObject) {
		const nullRunsWithObject = this.getNullRunsWithObject(nullObject.uuid);
		const forwardNullRun = _.first(_.filter(nullRunsWithObject, { is_forward: false }));
		const reverseNullRun = _.first(_.filter(nullRunsWithObject, { is_forward: true }));

		return (
			<tr key={nullObject.uuid}>
				{productionForwardRuns.map((run, index) => {
					if (!this.isNullRunSeparated()) {
						if (run.type === 'null') {
							return null;
						}
						const siblingRun = _.get(productionForwardRuns, index + 1) || _.get(productionForwardRuns, index - 1);
						if (siblingRun) {
							if (siblingRun.type === 'null') {
								return (
									<td key={`forward:${index}`}>
										{_.indexOf(_.map(nullRunsWithObject, 'uuid'), siblingRun.route_variant_null_run_uuid) !== -1 ? (
											siblingRun.start_time
										) : (
											''
										)}
									</td>
								);
							}
						}
					}

					return (
						<td key={`forward:${index}`}>
							{run.type === 'null' &&
							_.indexOf(_.map(nullRunsWithObject, 'uuid'), run.route_variant_null_run_uuid) !== -1 ? (
								run.start_time
							) : (
								''
							)}
						</td>
					);
				})}
                {!window.RNIS_SETTINGS.CITY_TULA && (<td className="border-left-bold-2">{reverseNullRun ? this.formatDistance(reverseNullRun.distance) : ''}</td>)}
                {!window.RNIS_SETTINGS.CITY_TULA && (<td className="border-left-bold">{reverseNullRun ? reverseNullRun.time : ''}</td>)}
				<td className={`border-left-bold${window.RNIS_SETTINGS.CITY_TULA && !productionReverseRuns.length ? ' border-right-bold' : null}`}>{nullObject.title}</td>
                {!window.RNIS_SETTINGS.CITY_TULA && (<td className="border-left-bold">{forwardNullRun ? forwardNullRun.time : ''}</td>)}
                {!window.RNIS_SETTINGS.CITY_TULA && (<td className="border-left-bold border-right-bold">
					{forwardNullRun ? this.formatDistance(forwardNullRun.distance) : ''}
				</td>)}
				{productionReverseRuns.map((run, index) => {
					if (!this.isNullRunSeparated()) {
						if (run.type === 'null') {
							return null;
						}
						const siblingRun = _.get(productionReverseRuns, index + 1) || _.get(productionReverseRuns, index - 1);
						if (siblingRun) {
							if (siblingRun.type === 'null') {
								return (
									<td key={`reverse:${index}`}>
										{_.indexOf(_.map(nullRunsWithObject, 'uuid'), siblingRun.route_variant_null_run_uuid) !== -1 ? (
											moment(siblingRun.start_time, formats.TIME).add(siblingRun.time, 'minutes').format(formats.TIME)
										) : (
											''
										)}
									</td>
								);
							}
						}
					}

					return (
						<td key={`reverse:${index}`}>
							{run.type === 'null' &&
							_.indexOf(_.map(nullRunsWithObject, 'uuid'), run.route_variant_null_run_uuid) !== -1 ? (
								moment(run.start_time, formats.TIME).add(run.time, 'minutes').format(formats.TIME)
							) : (
								''
							)}
						</td>
					);
				})}
			</tr>
		);
	}

	findPointByGroup(group, index, isForward, groups) {
		//        console.log(index)
		if ((isForward && index >= groups.length - 1) || (!isForward && index <= 0)) {
			// console.log (`${isForward} ${index}`)
			return null;
		}

		const nextGroup = isForward ? groups[index + 1] : groups[index - 1];
		const currentStopPoints = _.map(group.items, 'stop_point_uuid');
		const nextStopPoints = _.map(nextGroup.items, 'stop_point_uuid');

		let result = null;
		_.each(this.state.routeVariants, (routeVariant) => {
			const points = _.filter(_.concat(routeVariant.forward_points, routeVariant.reverse_points), {
				point_type: 'stop_point'
			});
			for (let i = 0; i < points.length - 1; i++) {
				if (
					_.indexOf(currentStopPoints, points[i].type_uuid) !== -1 &&
					_.indexOf(nextStopPoints, points[i + 1].type_uuid) !== -1
				) {
					result = points[i];
					return false;
				}
			}
		});

		return result;
	}

	renderPointRow(productionForwardRuns, productionReverseRuns, groups, group, index) {
		const forwardPoint = this.findPointByGroup(group, index, true, groups);
        console.log("🚀 ~ file: view.js ~ line 1028 ~ KiutrRouteScheduleViewComponent ~ renderPointRow ~ forwardPoint", forwardPoint)
		const reversePoint = this.findPointByGroup(group, index, false, groups);
        console.log("🚀 ~ file: view.js ~ line 1030 ~ KiutrRouteScheduleViewComponent ~ renderPointRow ~ reversePoint", reversePoint)

		return (
			<tr key={index}>
				{productionForwardRuns.map((run, _index) => {
					if (run.type === 'null' && !this.isNullRunSeparated()) {
						return null;
					}
					return <td key={`forward:${index}:${_index}`}>{this.renderPointTime(group, run)}</td>;
				})}
                {!window.RNIS_SETTINGS.CITY_TULA && (<td className="border-left-bold-2">
					{this.formatDistance(_.get(forwardPoint, 'distance_to_the_next_point', 0)) || '0'}
				</td>)}
                {!window.RNIS_SETTINGS.CITY_TULA && (<td className="border-left-bold">{_.get(forwardPoint, 'time_to_get_to_the_next_point', 0)}</td>)}
				<td className={`border-left-bold${window.RNIS_SETTINGS.CITY_TULA ? ' border-right-bold' : null}`}>{group.name}</td>
                {!window.RNIS_SETTINGS.CITY_TULA && (<td className="border-left-bold">{_.get(reversePoint, 'time_to_get_to_the_next_point', 0)}</td>)}
                {!window.RNIS_SETTINGS.CITY_TULA && (<td className="border-left-bold border-right-bold">
					{this.formatDistance(_.get(reversePoint, 'distance_to_the_next_point', 0)) || '0'}
				</td>)}
				{productionReverseRuns.map((run, _index) => {
					if (run.type === 'null' && !this.isNullRunSeparated()) {
						return null;
					}
					return <td key={`reverse:${index}:${_index}`}>{this.renderPointTime(group, run)}</td>;
				})}
			</tr>
		);
	}

	renderPointTime(group, run) {
		if (run.type === 'null') {
			const nullRun = this.state.nullRuns[run.route_variant_null_run_uuid];
			if (nullRun) {
				if (_.filter(nullRun.points, { type_uuid: group.stop_point_uuid }).length > 0) {
					return nullRun.is_forward
						? moment(run.start_time, formats.TIME).add(run.time, 'minutes').format(formats.TIME)
						: run.start_time;
				}
			}
			return null;
		}

		let point = null;
		_.each(group.items, (item) => {
			point = _.find(item.inclusions, {
				route_variant_uuid: run.route_variant_uuid,
				is_forward: run.type === 'production_forward'
			});
			if (point) {
				return false;
			}
		});

		if (!point) {
			return null;
		}

		const time = _.sumBy(
			_.filter(run.production_interval_map, (item) => {
				return item.index < point.index;
			}),
			'interval'
		);

		const timeObject = moment(run.start_time, formats.TIME).add(time, 'minutes');

		return timeObject.format(formats.TIME);
	}

	renderTurnsRow(productionForwardRuns, productionReverseRuns) {
		return (
			<tr className="border-bottom-bold-2">
				{productionForwardRuns.map((run, index) => {
					if (run.turn_number === 4) {
						// console.log('run', run)
					}
					if (run.type === 'null' && !this.isNullRunSeparated()) {
						return null;
					}
					return <th key={`forward:${index}`}>{run.turn_number}</th>;
				})}

				<th className={`border-left-bold${window.RNIS_SETTINGS.CITY_TULA && !productionReverseRuns.length ? ' border-right-bold' : null}`}>Выходы</th>

				{productionReverseRuns.map((run, index) => {
					if (run.type === 'null' && !this.isNullRunSeparated()) {
						return null;
					}
					return <th key={`reverse:${index}`}>{run.turn_number}</th>;
				})}
			</tr>
		);
	}

	renderRouteVariantRow(productionForwardRuns, productionReverseRuns) {
		return (
			<tr className="border-bottom-bold-2 wrap-normal">
				{productionForwardRuns.map((run, index) => {
					if (run.type === 'null' && !this.isNullRunSeparated()) {
						return null;
					}
					return (
						<th key={`forward:${index}`}>
							{_.get(_.find(this.state.routeVariants, { uuid: run.route_variant_uuid }), 'name')}
						</th>
					);
				})}

				<th className={`border-left-bold${window.RNIS_SETTINGS.CITY_TULA && !productionReverseRuns.length ? ' border-right-bold' : null}`}>Вариант движения</th>

				{productionReverseRuns.map((run, index) => {
					if (run.type === 'null' && !this.isNullRunSeparated()) {
						return null;
					}
					return (
						<th key={`reverse:${index}`}>
							{_.get(_.find(this.state.routeVariants, { uuid: run.route_variant_uuid }), 'name')}
						</th>
					);
				})}
			</tr>
		);
	}

	renderDistanceRow(productionForwardRuns, productionReverseRuns) {
		return (
			<tr className="border-top-bold-2 border-bottom-bold">
				{productionForwardRuns.map((run, index) => {
					if (run.type === 'null' && !this.isNullRunSeparated()) {
						return null;
					}
					return <td key={`forward:${index}`}>{run.type !== 'null' ? this.formatDistance(run.distance) : 0}</td>;
				})}
				<td className="border-right-bold-2 border-left-bold-2" colSpan={`${window.RNIS_SETTINGS.CITY_TULA ? 1 : 5}`}>
					Пробег, км
				</td>
				{productionReverseRuns.map((run, index) => {
					if (run.type === 'null' && !this.isNullRunSeparated()) {
						return null;
					}
					return <td key={`reverse:${index}`}>{run.type !== 'null' ? this.formatDistance(run.distance) : 0}</td>;
				})}
			</tr>
		);
	}

	formatDistance(distanceInMeters) {
		return Math.round(distanceInMeters / 1000 * 100) / 100;
	}

	renderTimeRow(productionForwardRuns, productionReverseRuns) {
		return (
			<tr className="border-bottom-bold-2">
				{productionForwardRuns.map((run, index) => {
					if (run.type === 'null' && !this.isNullRunSeparated()) {
						return null;
					}
					return <td key={`forward:${index}`}>{run.type !== 'null' ? run.time : 0}</td>;
				})}
				<td className="border-right-bold-2 border-left-bold-2" colSpan={`${window.RNIS_SETTINGS.CITY_TULA ? 1 : 5}`}>
					Время, мин
				</td>
				{productionReverseRuns.map((run, index) => {
					if (run.type === 'null' && !this.isNullRunSeparated()) {
						return null;
					}
					return <td key={`reverse:${index}`}>{run.type !== 'null' ? run.time : 0}</td>;
				})}
			</tr>
		);
	}

	gotoGraphic() {
		this.props.router.push(
			`/${this.props.params.component}/routes/${this.props.params.uuid}/schedules/${this.props.params
				.scheduleUuid}/graphic`
		);
	}

	gotoEllipse() {
		this.props.router.push(
			`/${this.props.params.component}/routes/${this.props.params.uuid}/schedules/${this.props.params
				.scheduleUuid}/ellipse`
		);
	}

	showTurns() {
		const turnNumber = _.get(_.first(this.state.turns), 'number');
		this.props.router.push(
			`/${this.props.params.component}/routes/${this.props.params.uuid}/schedules/${this.props.params
				.scheduleUuid}/turn/${turnNumber}`
		);
	}

	toggleSort() {
		this.setState((prevState) => ({
			isSortByTime: !prevState.isSortByTime
		}));
	}

	close() {
		this.props.router.push(`/${this.props.params.component}/routes/${this.props.params.uuid}/schedules`);
	}

	getDays() {
		let days = [];

		if (this.state.schedule.monday) {
			days.push('Пн');
		}
		if (this.state.schedule.tuesday) {
			days.push('Вт');
		}
		if (this.state.schedule.wednesday) {
			days.push('Ср');
		}
		if (this.state.schedule.thursday) {
			days.push('Чт');
		}
		if (this.state.schedule.friday) {
			days.push('Пт');
		}
		if (this.state.schedule.saturday) {
			days.push('Сб');
		}
		if (this.state.schedule.sunday) {
			days.push('Вс');
		}
		if (this.state.schedule.holiday) {
			days.push('Праздник');
		}

		return days;
	}

	renderSwitches() {
		let switches = [];

		_.each(this.state.turns, (turn) => {
			const switchRuns = _.filter(turn.runs, { type: 'switch_out' });

			_.each(switchRuns, (switchRun) => {
				const switchItem = _.get(this.state.scheduleSwitches, switchRun.schedule_switch_uuid);
				if (!switchItem) {
					return null;
				}
				const switchRoute = _.get(this.state.routes, switchItem.to_route_uuid);

				switches.push(
					<tr key={switchRun.uuid}>
						<td>Переключение {switches.length + 1}</td>
						<td>{switchRun.turn_number}</td>
						<td>{_.get(switchRoute, 'number')}</td>
						<td>{_.get(switchRoute, 'title')}</td>
					</tr>
				);
			});
		});

		if (switches.length > 0) {
			return (
				<div>
					<TableContainer>
						<div className="Table">
							<table className="b-table b-table-thead-no-hover">
								<thead>
									<tr>
										<th rowSpan={2} />
										<th rowSpan={2}>Текущий выход (откуда)</th>
										<th colSpan={2}>Переключение</th>
									</tr>
									<tr>
										<th>№ маршрута</th>
										<th>Наименование маршрута (куда)</th>
									</tr>
								</thead>
								<tbody>{switches}</tbody>
							</table>
						</div>
					</TableContainer>
				</div>
			);
		}
	}

	getProductionRuns(turn) {
		return _.filter(turn.runs, (run) => run.type === 'production_forward' || run.type === 'production_reverse');
	}

	getShifts() {
		let shifts = [];

		_.each(this.state.turns, (turn) => {
			const runs = this.getTurnRuns(turn);
			const turnRuns = _.filter(runs, (run) => run.type !== 'reshift').length;
			let shift = {
				number: 1,
				turn: turn.number,
				runs: []
			};
			_.each(runs, (run) => {
				if (run.type === 'reshift') {
					shift.percent = turnRuns !== 0 ? shift.runs.length / turnRuns : 0;
					shifts.push(_.cloneDeep(shift));
					shift = {
						number: shift.number + 1,
						turn: turn.number,
						runs: []
					};
				} else {
					shift.runs.push(run);
				}
			});
			if (shift.runs.length > 0 || shift.number === 1) {
				shift.percent = turnRuns !== 0 ? shift.runs.length / turnRuns : 0;
				shift.single = shift.number === 1;
				shifts.push(_.cloneDeep(shift));
			}
		});

		return shifts;
	}

	getRuns() {
		const runs = _.flatten(_.map(this.state.turns, 'runs'));

		return _.filter(
			_.map(runs, (run) => {
				if (run.type === 'switch_in') {
					const switchObject = _.find(this.state.scheduleSwitchesTo, { uuid: run.schedule_switch_uuid });
					if (switchObject) {
						const switchTurn = _.find(_.get(switchObject, 'schedule.turns', []), (item) => {
							return _.toInteger(item.number) === _.toInteger(switchObject.schedule_turn_number);
						});
						const switchRun = _.find(_.get(switchTurn, 'runs', []), { schedule_switch_uuid: run.schedule_switch_uuid });
						if (switchRun) {
							let _run = _.clone(switchRun);
							_run.type = 'null';
							return _run;
						}
					}

					return null;
				}

				return run;
			})
		);
	}

	getTurnRuns(turn) {
		const runs = _.flatten(turn.runs);

		return _.filter(
			_.map(runs, (run) => {
				if (run.type === 'switch_out') {
					return null;
				}
				if (run.type === 'switch_in') {
					const switchObject = _.find(this.state.scheduleSwitchesTo, { uuid: run.schedule_switch_uuid });
					if (switchObject) {
						const switchTurn = _.find(_.get(switchObject, 'schedule.turns', []), (item) => {
							return _.toInteger(item.number) === _.toInteger(switchObject.schedule_turn_number);
						});
						const switchRun = _.find(_.get(switchTurn, 'runs', []), { schedule_switch_uuid: run.schedule_switch_uuid });
						if (switchRun) {
							let _run = _.clone(switchRun);
							_run.type = 'null';
							return _run;
						}
					}

					return null;
				}

				return run;
			})
		);
	}

	renderSummary() {
		const runs = this.getRuns();
		const shifts = this.getShifts();

		const totalMileage =
			_.sumBy(
				_.filter(runs, (run) => {
					return run.type !== 'switch_out';
				}),
				(run) => _.toInteger(run.distance)
			) / 1000;
		const totalTimeInDrive = _.sumBy(
			_.filter(runs, (run) => {
				return run.type === 'production_forward' || run.type === 'production_reverse';
			}),
			(run) => _.toInteger(run.time)
		);
		const totalTimeInMovement = _.sumBy(shifts, (turn) => {
			return _.sumBy(
				_.filter(turn.runs, (run) => {
					return (
						run.type === 'production_forward' ||
						run.type === 'production_reverse' ||
						run.type === 'techno' ||
						run.type === 'null' ||
						run.type === 'switch_out' ||
						run.type === 'switch_in'
					);
				}),
				(run) => _.toInteger(run.time)
			);
		});

		const routeVariantUuids = _.filter(_.uniq(_.map(runs, 'route_variant_uuid')));

		return (
			<div className="schedule-index">
				<div>
					Общий пробег: {_.round(totalMileage, 2)} км.
					<br />
					V с/т: {_.round(totalMileage / (totalTimeInDrive / 60), 2)}
					<br />
					V с: {_.round(totalMileage / (totalTimeInMovement / 60), 2)}
				</div>
				<TableContainer>
					<div className="Table">
						<table className="b-table">
							<thead>
								<tr>
									<th>Номер</th>
									<th>Доля вых.</th>
									<th>Rm</th>
									<th>T нач.</th>
									<th>T ок.</th>
									<th>T нар.</th>
									<th>T движ.</th>
									<th>T упр.</th>
									<th>L об.</th>
									<th>L нул.</th>
								</tr>
							</thead>
							<tbody>
								{_.map(shifts || [], ::this.renderTurnSummary)}
								<tr>
									<td>Итого:</td>
									<td>{shifts.length}</td>
									<td>{_.sumBy(shifts, (turn) => this.getProductionRuns(turn).length)}</td>
									<td />
									<td />
									<td>
										{this.formatTime(
											_.sumBy(shifts, (turn) => {
												const settlingTime = this.getSettlingTime(turn);
												const { driveStartTime, driveEndTime } = this.getDriveTime(turn);
												return driveStartTime && driveEndTime
													? Math.abs(driveStartTime.diff(driveEndTime, 'minutes')) - settlingTime
													: 0;
											})
										)}
									</td>
									<td>{this.formatTime(totalTimeInMovement)}</td>
									<td>{this.formatTime(totalTimeInDrive)}</td>
									<td>
										{_.round(
											_.sumBy(shifts, (turn) => {
												return _.sumBy(this.getProductionRuns(turn), (run) => _.toInteger(run.distance));
											}) / 1000,
											2
										)}
									</td>
									<td>
										{_.round(
											_.sumBy(shifts, (turn) => {
												return _.sumBy(_.filter(turn.runs, { type: 'null' }), (run) => _.toInteger(run.distance));
											}) / 1000,
											2
										)}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</TableContainer>
				Нормативные данные:
				<TableContainer>
					<div className="Table">
						<table className="b-table">
							<thead>
								<tr>
									<th>ВарРейс</th>
									<th>L пр.</th>
									<th>L об.</th>
									<th>Tдв.пр.</th>
									<th>Tдв.об.</th>
									<th>T обор.</th>
									<th>V с/т пр</th>
									<th>Vс пр.</th>
									<th>Vэкс пр</th>
									<th>V с/т об</th>
									<th>Vс об.</th>
									<th>Vэкс об</th>
								</tr>
							</thead>
							<tbody>{_.map(routeVariantUuids, this.renderRouteVariantSummary.bind(this, runs))}</tbody>
						</table>
					</div>
				</TableContainer>
			</div>
		);
	}

	renderRouteVariantSummary(allRuns, routeVariantUuid) {
		const routeVariant = _.find(this.state.routeVariants, { uuid: routeVariantUuid });
		if (!routeVariant) {
			return null;
		}

		const runs = _.filter(allRuns, { route_variant_uuid: routeVariantUuid });

		const totalMileage = _.sumBy(runs, (run) => _.toInteger(run.distance)) / 1000;
		const totalTimeInDrive = _.sumBy(
			_.filter(runs, (run) => run.type === 'production_forward' || run.type === 'production_reverse'),
			(run) => _.toInteger(run.time)
		);
		const totalTimeInMovement = _.sumBy(
			_.filter(runs, (run) => {
				return (
					run.type === 'production_forward' ||
					run.type === 'production_reverse' ||
					run.type === 'techno' ||
					run.type === 'null' ||
					run.type === 'switch_out' ||
					run.type === 'switch_in'
				);
			}),
			(run) => _.toInteger(run.time)
		);
		const totalTimeInMovementWithSettling = _.sumBy(
			_.filter(runs, (run) => {
				return (
					run.type === 'production_forward' ||
					run.type === 'production_reverse' ||
					run.type === 'techno' ||
					run.type === 'null' ||
					run.type === 'switch_out' ||
					run.type === 'switch_in' ||
					run.type === 'settling'
				);
			}),
			(run) => _.toInteger(run.time)
		);

		return (
			<tr key={routeVariantUuid}>
				<td>{routeVariant.name}</td>
				<th>{_.round(_.sumBy(routeVariant.forward_points, 'distance_to_the_next_point') / 1000, 2)}</th>
				<th>{_.round(_.sumBy(routeVariant.reverse_points, 'distance_to_the_next_point') / 1000, 2)}</th>
				<th>
					{this.formatTime(
						_.sumBy(routeVariant.forward_points, (point) => _.toInteger(point.time_to_get_to_the_next_point))
					)}
				</th>
				<th>
					{this.formatTime(
						_.sumBy(routeVariant.reverse_points, (point) => _.toInteger(point.time_to_get_to_the_next_point))
					)}
				</th>
				<th>
					{this.formatTime(
						_.sumBy(_.concat(routeVariant.forward_points, routeVariant.reverse_points), (point) =>
							_.toInteger(point.time_to_get_to_the_next_point)
						)
					)}
				</th>
				<th>{_.round(totalMileage / (totalTimeInDrive / 60), 2)}</th>
				<th>{_.round(totalMileage / (totalTimeInMovement / 60), 2)}</th>
				<th>{_.round(totalMileage / (totalTimeInMovementWithSettling / 60), 2)}</th>
				<th>{_.round(totalMileage / (totalTimeInDrive / 60), 2)}</th>
				<th>{_.round(totalMileage / (totalTimeInMovement / 60), 2)}</th>
				<th>{_.round(totalMileage / (totalTimeInMovementWithSettling / 60), 2)}</th>
			</tr>
		);
	}

	formatTime(minutes) {
		if (isNaN(minutes)) {
			return '00:00';
		}
		return _.padStart(Math.floor(minutes / 60), 2, '0') + ':' + _.padStart(minutes % 60, 2, '0');
	}

	getDriveTime(turn) {
		const driveRuns = _.filter(
			turn.runs,
			(run) => _.indexOf([ 'dinner', 'gap', 'reshift', 'settling' ], run.type) === -1
		);

		const driveStart = _.first(driveRuns);
		const driveEnd = _.last(driveRuns);

		let result = {
			driveStartTime: driveStart ? moment(driveStart.start_time, formats.TIME) : null,
			driveEndTime: driveEnd ? moment(driveEnd.start_time, formats.TIME).add(driveEnd.time, 'minutes') : null
		};

		if (result.driveStartTime && result.driveEndTime && result.driveEndTime.isBefore(result.driveStartTime)) {
			result.driveEndTime = result.driveEndTime.add(1, 'day');
		}

		return result;
	}

	getSettlingTime(turn) {
		return _.sumBy(
			_.filter(turn.runs, (run) => _.indexOf([ 'dinner', 'gap', 'reshift', 'settling' ], run.type) !== -1),
			(run) => _.toInteger(run.time)
		);
	}

	renderTurnSummary(shift, index) {
		const { driveStartTime, driveEndTime } = this.getDriveTime(shift);
		const settlingTime = this.getSettlingTime(shift);

		const runs = _.filter(shift.runs, (run) => run.type === 'production_forward' || run.type === 'production_reverse');

		let shiftNumber = shift.number;
		switch (shift.number) {
			case 1:
				shiftNumber = 'I';
				break;
			case 2:
				shiftNumber = 'II';
				break;
			case 3:
				shiftNumber = 'III';
				break;
		}

		const runTypesForCount = [
			'production_forward',
			'production_reverse',
			'techno',
			'null',
			'switch_out',
			'switch_in',
			'parking'
		];

		return (
			<tr key={index}>
				<td>
					{shift.turn}
					{!shift.single ? ` ${shiftNumber} см.` : ''}
				</td>
				<td>{_.round(shift.percent, 2)}</td>
				<td>{runs.length}</td>
				<td>{driveStartTime ? driveStartTime.format(formats.TIME) : '-'}</td>
				<td>{driveEndTime ? driveEndTime.format(formats.TIME) : '-'}</td>
				<td>{driveStartTime && driveEndTime ? this.formatTime(_.sumBy(shift.runs, (run) => +run.time)) : '-'}</td>
				<td>
					{this.formatTime(
						_.sumBy(shift.runs.filter((run) => runTypesForCount.includes(run.type)), (run) => +run.time)
					)}
				</td>
				<td>{this.formatTime(_.sumBy(runs, (run) => _.toInteger(run.time)))}</td>
				<td>{formatMileage(_.sumBy(shift.runs, (run) => _.toInteger(run.distance)) / 1000)}</td>
				<td>
					{_.round(_.sumBy(_.filter(shift.runs, { type: 'null' }), (run) => _.toInteger(run.distance)) / 1000, 2)}
				</td>
			</tr>
		);
	}
}
