import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';
import classNames from 'classnames';

import _ from 'lodash';
import moment from "moment";
import formats from "dictionaries/formats";
import Tooltip from 'react-tooltip-component';
import {api} from "helpers/api";
import {makeResponse} from "helpers/response";
import {EntityList} from "helpers/entity";
import {User} from "helpers/user";

export default class CleanupSpecialistTooltip extends Component {
    render() {
        const cleanupSpecialist = this.props.cleanupSpecialist;
        const device = this.props.device;

        return (
            <div className="b-modal b-modal-map cleanup-tooltip map-tooltip-modal">
                <div className="b-modal__header">
                    <div className="title">
                        {cleanupSpecialist.info.name} {cleanupSpecialist.info.second_name} {cleanupSpecialist.info.surname}
                    </div>
                </div>
                <div className="b-modal__footer">
                    <div className="datatime">
                        <span className="data">{moment.unix(device.time).format(formats.DATE)}{'  '}</span>
                        <span className="time">{moment.unix(device.time).format(formats.TIME_FULL)}</span>
                    </div>
                </div>
            </div>
        );
    }
}