import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import moment from "moment";
import formats from "dictionaries/formats";
import {getOrder, updateDefect, updateOrder} from "store/reducers/kiutr/orders/orders";
import {getUnit} from "store/reducers/organizational_units/units";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import LogItem from './LogItem';
import Shift from './Shift';
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import LoaderComponent from "components/ui/loader";
import KiutrOrderRunsComponent from "components/modules/kiutr/orders/OrderPlanningForm/OrderRuns/index";
import DefectEditor from "components/modules/kiutr/orders/OrderOperationalForm/DefectModal/index";
import {DefectTypes} from "dictionaries/kiutr";
import * as alerts from "helpers/alerts";
import OrderEditor from "components/modules/kiutr/orders/OrderOperationalForm/OrderEditor/index";
import {Link} from "react-router";
import OrderClose from "components/modules/kiutr/orders/OrderOperationalForm/OrderClose/index";
import Tooltip from 'react-tooltip-component';
import {print} from "helpers/print";
import ReactDOMServer from 'react-dom/server';
import OrderPrintComponent from "components/modules/kiutr/orders/OrderPrint/index";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUsers} from "store/reducers/staffing/staffing";
import {getContract} from "store/reducers/kiutr/contracts/contracts";
import {getStopPoints} from "store/reducers/geo/stop-points";
import { checkLimitationInstallation } from "helpers/functions";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string.isRequired,
    order: PropTypes.object,
})

@connect(state => ({}), {
    getOrder,
    updateOrder,
    getEntityNames,
    updateDefect,
    getVehicleList,
    getDictionaryList,
    getUsers,
    getContract,
    getUnit,
    getStopPoints
})

export default class OrderOperationalForm extends BaseEditor {

    modalClassName = 'orders-modal orders-modal-main';

    static contextTypes = {
        resizeModals: PropTypes.func
    };

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            related: new EntityList(),

            runsActive: false,
            defectActive: false,
            defectUuid: null,
            editorActive: false,
            editorShift: null,
            closeActive: false,
            vehicles: [],
            unit: {},
            drivers: {},
            vehicle_models: [],
            vehicle_marks: [],
        });
    }

    componentDidMount() {
        this.forceUpdate();
    }

    saveButton(onClick) {
    }

    getFullTitle() {
        if (!this.state.item) {
            return <LoaderComponent/>
        }
        return <span>План-наряд №{_.get(this.state.item, 'number', '-')} ({this.state.unit.name})</span>;
    }

    async loadData(uuid) {
        const response = {
            isOk: true,
            payload: this.props.order,
        };

        if (response.isOk) {
            const item = response.payload;
            this.loadRelated(item);

            this.loadDictionaries([
                'vehicle_models',
                'vehicle_marks',
            ]);
            this.loadVehicles(_.filter(_.uniq(_.map(_.flatten(_.map(response.payload.shifts, 'runs')), 'vehicle_uuid'))));
            this.loadContract(response.payload.contract_uuid);
            this.loadDrivers(_.concat(
                _.filter(_.uniq(_.map(_.flatten(_.map(response.payload.shifts, 'runs')), 'driver_uuid'))),
                _.filter(_.uniq(_.map(_.flatten(_.map(response.payload.shifts, 'runs')), 'check_taker_uuid')))
            ));
            this.loadStopPoints(_.uniq(_.flatten(this.props.order.shifts.map(shift => _.flatten(shift.runs.map(run => _.flatten(run.points.filter(point => point.point_type === "stop_point"))))))), 'uuid');
        }

        return response;
    }

    async loadStopPoints(points) {
        const meta = {
            filters: {
                withUuid: _.uniq(_.filter(_.map(points, 'type_uuid')))
            },
            response_data: [
                'items/uuid',
                'items/title',
            ],
        };

        const response = await this.props.getStopPoints(meta);
        if (response.isOk) {
            window._stop_points =  _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'title') //fixme для теста кидаю через window ибо вложенность компонентов не проследить
            this.setState({
                stop_points: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'title'),
            });
        } else {
            response.showErrors();
            return null;
        }
    }


    async loadDrivers(uuids) {
        if (!uuids || (uuids.length === 0)) {
            return null;
        }

        const response = await this.props.getUsers({
            filters: {
                withUuid: uuids,
            },
            response_data: [
                'items/uuid',
                'items/info/surname',
                'items/info/name',
                'items/info/second_name',
                'items/info/personnel_number',
                'items/info/driver_serial',
                'items/info/driver_number',
            ],
        });
        if (response.isOk) {
            this.setState({
                drivers: _.keyBy(response.payload.items, 'uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadContract(uuid) {
        if (!uuid) {
            return null;
        }

        const response = await this.props.getContract(uuid);
        if (response.isOk) {
            this.loadUnit(response.payload.carrier_uuid);
        }
    }

    async loadUnit(uuid) {
        if (!uuid) {
            return null;
        }

        const response = await this.props.getUnit(uuid);
        if (response.isOk) {
            this.setState({
                unit: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.mapValues(_.keyBy(item.documents, 'uuid'), 'name');
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }

    async loadVehicles(uuids) {
        if (uuids.length === 0) {
            return;
        }

        const response = await this.props.getVehicleList({
            filters: {
                withUuid: uuids,
            },
            response_data: [
                'items/uuid',
                'items/state_number',
                'items/garage_number',
                'items/vehicle_model_uuid',
            ],
        });
        if (response.isOk) {
            this.setState({
                vehicles: _.keyBy(response.payload.items, 'uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    async updateItem(data) {
        return await this.props.updateOrder(data);
    }

    async loadRelated(item) {
        const units = [
            {
                class: 'App\\Model\\Unit',
                uuid: item.unit_uuid,
                source: 'organizational_units',
            },
        ];

        const users = _.map(item.logs, (log) => ({
            class: 'App\\Model\\UserInfo',
            uuid: log.user_uuid,
            source: 'auth',
        }));

        const defectUsers = _.map(_.flatten(_.map(item.defects, 'history')), (history) => ({
            class: 'App\\Model\\UserInfo',
            uuid: history.user_uuid,
            source: 'auth',
        }));

        const response = await this.props.getEntityNames(_.concat(units, users, defectUsers));

        if (response.isOk) {
            this.state.related.add(response);
        }
    }

    getUserName(userUuid) {
        return this.state.related.getReact(userUuid);
    }

    renderHeaderBtns(mode) {
        if (mode === 'edit') {
            const closeTooltip = (!_.get(this.state.item, 'is_calc_ended')) ? 'Закрыть план-наряд (ожидает пересчета)' : 'Закрыть план-наряд';

            return ([
                (this.state.item.processing_status === 'ended') ? (
                    <ContextTooltip default={closeTooltip}>
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_order-close"
                            onClick={::this.closeOrder}
                        />
                    </ContextTooltip>
                ) : null,
                (this.state.item.processing_status === 'closed') ? (
                    <ContextTooltip key="orders.close" code="orders.close" default="Результаты выполнения">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_book"
                            onClick={::this.closeOrder}
                        />
                    </ContextTooltip>
                ) : null,
                (this.state.item.processing_status === 'active') ? (
                    <ContextTooltip key="orders.defect" code="orders.defect" default="Брак/вывод">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_order-defect"
                            onClick={this.showDefect.bind(this, null)}
                        />
                    </ContextTooltip>
                ) : null,
                (this.state.item.processing_status === 'active') ? (
                    <ContextTooltip key="orders.resources" code="orders.resources" default="Выделение доп. ресурсов">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_order-resources"
                            onClick={::this.showRuns}
                        />
                    </ContextTooltip>
                ) : null,
            ]);
        }

        return null;
    }

    async cancelDefect(defectUuid) {
        alerts.prompt('Отменить брак?', '', async () => {
            let defect = _.find(this.state.item.defects, {uuid: defectUuid});
            defect.is_canceled = true;

            this.startSave();
            const response = await this.props.updateDefect(defect);
            this.endSave();

            if (response.isOk) {
                this.reload();
            } else {
                response.showErrors();
            }
        }, 'Подтвердить');
    }

    getForm(item, onSubmit) {
        const defects = _.map(item.defects || [], (defect) => {
            const created = _.find(defect.history, {type: 'created'});

            return {
                time: _.get(created, 'time'),
                type: 'defect',
                user_uuid: _.get(created, 'user_uuid'),
                text: `Вмешательство (${DefectTypes[defect.type]})`,
                defectUuid: ((item.processing_status === 'active') && !defect.is_canceled) ? defect.uuid : null,
                history: defect.history,
                cancelDefect: ((item.processing_status === 'active') && !defect.is_canceled && defect.can_cancel) ? ::this.cancelDefect : null,
                is_canceled: defect.is_canceled,
            };
        });
        const logs = _.sortBy(_.concat(item.logs || [], defects), (log) => moment(log.time).format(formats.DATETIME_API));

        return (
            <div>
                <div className="b-modal__block">
                    <Block size="xl">
                        <span className="bold">
                            Выход {_.get(this.state.item, 'turn')} за {this.state.item ? moment(this.state.item.date).format(formats.DATE) : '-'}
                            {(!_.get(this.state.item, 'is_calc_ended') && (this.state.item.processing_status !== 'closed')) ? ' (ожидает пересчета)' : ''}
                        </span>
                        {(item.shifts || []).map((shift) => {
                            return (
                                <Shift
                                    params={this.props.params}
                                    key={shift.shift}
                                    shift={shift}
                                    order={this.state.item}
                                    onEdit={(this.state.item.processing_status !== 'closed') ? this.showEditor.bind(this, shift.shift) : null}
                                />
                            );
                        })}
                    </Block>

                    <Block size="xl">
                        {logs.map((log, index) => {
                            return (
                                <LogItem
                                    key={index}
                                    log={log}
                                    getUserName={::this.getUserName}
                                    showDefect={::this.showDefect}
                                />
                            );
                        })}
                    </Block>
                </div>
            </div>
        );
    }

    printButton() {
        if (this.state.vehicles.length === 0) {
            return null;
        }
        return (
            <ContextTooltip key="base-editor.close" code="base-editor.close" default="Печать" position="bottom">
                <ModalTopMenuListItem
                    className="b-icon-link_icon_print"
                    onClick={::this.print}
                />
            </ContextTooltip>
        );
    }

    print() {
        print(ReactDOMServer.renderToStaticMarkup(<OrderPrintComponent
            unit={this.state.unit}
            vehicles={this.state.vehicles}
            drivers={this.state.drivers}
            order={this.state.item}
            vehicle_models={this.state.vehicle_models}
            vehicle_marks={this.state.vehicle_marks}
        />));
    }

    renderModals() {
        if (this.state.runsActive) {
            return (
                <KiutrOrderRunsComponent
                    {...this.props}
                    order={this.state.item}
                    onSubmit={this.hideRuns.bind(this, true)}
                    onClose={this.hideRuns.bind(this, false)}
                />
            );
        }

        if (this.state.defectActive) {
            return (
                <DefectEditor
                    {...this.props}
                    key="editor"
                    onClose={::this.hideDefect.bind(this, false)}
                    onSubmit={::this.hideDefect.bind(this, true)}
                    mode={this.state.defectUuid ? 'edit' : 'add'}
                    uuid={this.state.defectUuid}
                    order={this.state.item}
                />
            );
        }

        if (this.state.editorActive) {
            return (
                <OrderEditor
                    {...this.props}
                    key="editor"
                    onClose={this.hideEditor.bind(this, false)}
                    onSubmit={this.hideEditor.bind(this, true)}
                    shift={this.state.editorShift}
                    order={this.state.item.order_recalc || this.state.item}
                    originalOrder={this.state.item}
                    type={this.state.item.order_recalc ? 'recalc' : 'order'}
                />
            );
        }

        if (this.state.closeActive) {
            return (
                <OrderClose
                    {...this.props}
                    onClose={this.hideClose.bind(this, false)}
                    onSubmit={this.hideClose.bind(this, true)}
                    reload={::this.reload}
                    order={this.state.item}
                    readonly={this.state.item.processing_status === 'closed'}
                />
            );
        }

        return null;
    }

    async closeOrder() {
        await this.setState({
            closeActive: true,
        });
        this.context.resizeModals();
        /*let item = this.state.item;
        item.processing_status = 'closed';

        this.clearErrors();
        this.startSave();

        const response = await this.updateItem(item);

        this.endSave();
        if (response.isOk) {
            this.setState({
                item: response.payload,
            });
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }*/
    }

    async hideClose(reload = false) {
        await this.setState({
            closeActive: false,
        });

        reload && this.reload();

        this.context.resizeModals();
    }

    async showEditor(editorShift = null) {
        await this.setState({
            runsActive: false,
            defectActive: false,
            editorActive: true,
            editorShift,
        });
        this.context.resizeModals();
    }

    async hideEditor(reload = false, type) {
        reload && this.reload();

        await this.setState({
            editorActive: false,
        });

        this.context.resizeModals();

        if (type === 'resources') {
            this.showRuns();
        }
    }

    showMap() {
        this.props.router.push(`/kiutr/map/order/${this.state.uuid}`);
    }

    async showDefect(defectUuid = null) {
        await this.setState({
            runsActive: false,
            editorActive: false,
            defectActive: true,
            defectUuid,
        });

        this.context.resizeModals();
    }

    async hideDefect(reload = false) {
        reload && this.reload();

        await this.setState({
            defectActive: false,
            defectUuid: null,
        });

        this.context.resizeModals();
    }

    async showRuns() {

        if (checkLimitationInstallation( _.get(this.state.item, 'date'), true)) {
            alerts.alert(`Данное действие невозможно после ${moment(_.get(this.state.item, 'date')).format(formats.DATE)} 23:59`);
            return;
        }

        await this.setState({
            defectActive: false,
            editorActive: false,
            runsActive: true,
        });
        this.context.resizeModals();
    }

    async hideRuns(reload = false) {
        reload && this.reload();

        await this.setState({
            runsActive: false,
        });
        this.context.resizeModals();
    }

    async reloadOrder(uuid) {
        return await this.props.getOrder(uuid);
    }

    async reload() {
        this.startSave();
        const response = await this.reloadOrder(this.props.uuid);
        this.loadData(this.props.uuid);
        this.endSave();

        if (response.isOk) {
            this.setState({
                item: response.payload,
                isLoading: false,
            });

            if (this.state.item.processing_status === 'ended') {
                this.closeOrder()
            }

        }
    }
}
