import $ from 'jquery';

export const print = (html, cb, delay) => {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('sandbox', 'allow-modals allow-same-origin allow-scripts');
    iframe.setAttribute('src', 'about:blank');
    iframe.setAttribute('style', 'visibility: hidden;pointer-events:none;border:none;');

    document.body.appendChild(iframe);

    const teardown = () => {
        // Safari crashes without a timeout.
        setTimeout(() => document.body.removeChild(iframe), 0);
        if (typeof cb === 'function') cb();
    };

    setTimeout(() => {
        iframe.contentDocument.write(html);

        setTimeout(() => {
            iframe.contentWindow.focus();
            try {
                iframe.contentWindow.document.execCommand('print', false, null) || iframe.contentWindow.print();
            } catch (e) {
                iframe.contentWindow.print();
            }
        }, delay || 500);
    }, 0);
};

export const getTableForPrint = (headers, rows) => {
    console.log(rows)
    // Construct a table for printing
    let table = $('<table cellspacing="0" cellpadding="0"><thead><tr></tr></thead><tbody></tbody></table>');

    let thead = table.find('thead tr');
    let tbody = table.find('tbody');

    $.each(headers, (index, header) => {
        if (!$(header).is(':visible')) {
            return;
        }
        let headerClone = $(header).clone();
        headerClone.find('div').remove();
        thead.append($('<th style="white-space:nowrap;">').text(headerClone.text()));
    });

    $.each(rows, (index, row) => {
        let rowElement = $('<tr>');
        tbody.append(rowElement);

        const cells = $(row).find('td');
        $.each(cells, (cellIndex, cell) => {
            let text = $(cell).text();
            if ($(cell).find('.b-state').length > 0) {
                text = $(cell).find('.b-state').hasClass('_disabled') ? 'x' : '✓';
            }
            const el = $('<td style="border: 1px black solid !important;padding: 5px;">').text(text);
            if ($(cell).find('.entity-deleted').length > 0) {
                el.addClass('entity-deleted');
            }

            rowElement.append(el);
        });
    });

    let container = $('<div>');
    container.append(table);
    return container.html();
};

export const printTable = (headers, rows, orientation = 'landscape', pageSize = 'A4') => {
    const html = getTableForPrint(headers, rows);
    const style = `
        <style>
            @page {
                size: ${pageSize} ${orientation};
            }
            .entity-deleted {
                color: #a1a4a6;
                text-decoration: line-through;
            }
        </style>
    `;
    print(style + html);
};

const addBlockToContainer = (item, container, image = null) => {
    $(item).find('.b-block').each((_index, block) => {
        const blockTitle = $(block).find('.b-block__title').text() || $(block).find('.b-checkbox__label').text();
        const input = $(block).find('input,textarea,.ql-editor');
        const select = $(block).find('.Select-value-label');
        const state = $(block).find('.b-state');
        const table = $(block).find('.Table');
        let blockValue = '';
        if (table.length > 0) {
            const tableClone = table.clone();
            tableClone.find('input:not([type=hidden])').each((index, el) => {
                $(el).replaceWith($(el).val());
            });
            tableClone.find('.dataTables_filter,.Select-clear-zone').remove();
            blockValue = tableClone.html();
        } else {
            if (select.length === 0) {
                if (input.length === 0) {
                    if (state.length === 0) {
                        blockValue = $(block).find('.b-block__text').html();
                    } else {
                        blockValue = state.is('._disabled') ? 'Нет' : 'Да';
                    }
                } else {
                    if (input.is('input[type="checkbox"]')) {
                        $(block).find('input:checkbox').each(function () {
                            const checkboxTitle = $(this).next().text();
                            blockValue = $(this).is(':checked') ? '&check;' : '&times;';
                            container.append(`<p><strong>${checkboxTitle}:</strong> ${blockValue}</p>`);
                        });
                        return;
                    } else if (input.is('input[type="radio"]')) {
                        const checked = $(block).find('input:radio:checked');
                        if (checked.length > 0) {
                            blockValue = checked.next().text();
                        }
                    } else {
                        blockValue = input.val() || input.html();
                    }
                }
            } else {
                blockValue = $(select).text();
            }
        }

        container.append(`<p>${blockTitle ? `<strong>${blockTitle}:</strong> ` : ''}${blockValue}</p>`);
    });

    const map = $(item).find('.map-container');
    if (image && (map.length > 0)) {
        container.append(`<img src="${image}/>`);
    }
};

export const printForm = (domNode, image = null) => {
    let container = $('<div>');

    const el = $(domNode);
    if (el.is('.b-modal__block')) {
        addBlockToContainer(el, container);
    }
    el.find('.accordion__item').each((index, item) => {
        const title = $(item).find('.accordion__title').text();
        container.append(`<h2>${title}</h2>`);

        addBlockToContainer(item, container, image);
    });
    if (!el.is('.b-modal__block') && el.find('.accordion__item').length === 0) {
        addBlockToContainer(el, container);
    }
    print(container.html());
};
