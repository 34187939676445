import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import SelectPopup from 'components/ui/SelectPopup';
import classNames from 'classnames';
import './style.less';

@propTypes({
    showPopup: PropTypes.bool,
    onChange: PropTypes.func,
    items: PropTypes.array,
    title: PropTypes.string,
    fieldKey: PropTypes.string,
    withToggle: PropTypes.bool,
})

@defaultProps({
    showPopup: false,
    onChange: () => {},
    items: [],
    title: '',
    fieldKey: 'name',
    withToggle: true,
})

export default class WidgetSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showPopup: false
        };
    }

    componentWillMount() {
        this.setState({ showPopup: this.props.showPopup});
    }

    componentWillReceiveProps(nextProps) {
        // if (this.state.showPopup !== nextProps.showPopup) {
        //     this.setState({ showPopup: nextProps.showPopup});
        // }
    }

    onChangeShowPopup = () => {
        this.setState({ showPopup: !this.state.showPopup});
    };

    render() {
        const selectorClassName = classNames('widget-selector__container', { 'opened': this.state.showPopup });
        //<span>{this.props.title}</span>
        return (
            <div>
                <div className="widget-selector">
                    <div className={selectorClassName}>
                        <input className="widget-selector__input-style" value={this.props.title} readOnly/>
                        <i className="widget-selector__arrow" onClick={this.onChangeShowPopup}/>
                    </div>
                </div>
                {this.state.showPopup ?
                    <SelectPopup items={this.props.items}
                                 onChange={this.props.onChange}
                                 fieldKey={this.props.fieldKey}
                                 className="widget-selector__popup"
                                 withToggle={this.props.withToggle}/>
                    : null}
            </div>
        );
    }
}