import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import WidgetSelector from 'components/modules/analytics/base-components/WidgetSelector';
import BarChart from 'components/modules/analytics/base-components/charts/BarChart';
import Datepicker from "components/ui/form/datepicker";
import moment from 'moment';
import formats from 'dictionaries/formats';
import _ from 'lodash';
import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import GlobalLoaderComponent from "components/ui/global-loader";
import './style.less';
import {connect} from "react-redux";
import {getSpeedViolations} from "store/reducers/notifications/speed-violations";
import {getUnits} from "store/reducers/organizational_units/units";
import {Text} from "recharts";

@propTypes({
    barChartFillColor: PropTypes.string
})

@defaultProps({
    barChartFillColor: ''
})

@connect(state => ({}), {getUnits, getSpeedViolations})

export default class SpeedViolationWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            from: moment().subtract(1, 'day').format(formats.DATE_API),
            to: moment().endOf('day').format(formats.DATE_API),
            organizationUnits: [],
            data: {
                unitsByDate: []
            }
        };
    }

    componentWillMount() {
        this.loadUnits();
        this.loadData();
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withComponent: 'children',
            },
        });

        if (response.isOk) {
            this.setState({
                organizationUnits: response.payload.items,
            });
        } else {
            response.showError();
        }
    }

    async getViolations(units) {
        const response = await this.props.getSpeedViolations({
            filters: {
                withPeriod: [
                    this.state.from,
                    this.state.to,
                ],
                withUnits: _.map(units, 'uuid'),
            },
        });

        if (response.isOk) {
            return response.payload.items;
        } else {
            response.showErrors();
        }
    }

    async loadData() {
        this.setState({isError: false, isLoading: true});

        try {
            const units = _.filter(this.state.organizationUnits, {checked: true});
            const violations = await this.getViolations(units);

            let unitsByDate = [];
            let date = moment(this.state.from);
            while (date.isSameOrBefore(moment(this.state.to))) {
                const dateViolations = _.filter(violations, (violation) => {
                    return moment(violation.timestamp).isSameOrAfter(date.startOf('day'))
                        &&
                        moment(violation.timestamp).isSameOrBefore(date.endOf('day'));
                });

                let data = {
                    date: date.format(formats.DATE),
                    violationsCount: dateViolations.length,
                    config: {},
                };

                _.each(units, (unit) => {
                    data[unit.uuid] = _.filter(dateViolations, {unit_uuid: unit.uuid}).length;
                    data.config[unit.uuid] = {
                        name: unit.name,
                    };
                });

                unitsByDate.push(data);

                date = date.add(1, 'day');
            }

            const data = {
                unitsByDate,
            };

            this.setState({data, isError: false, isLoading: false});
        }
        catch (e) {
            this.setState({isError: true, isLoading: false});
            error(e).showErrors();
        }
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value});
        this.loadData();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value});
        this.loadData();
    };

    onSelectedUnitsChange(param) {
        let organizationUnits = this.state.organizationUnits;
        _.each(param, (p) => {
            let index = p.index;
            organizationUnits[index].checked = p.checked;
        });
        this.setState({
            organizationUnits,
        });
        this.loadData();
    }

    renderContent() {
        return (
            <div className="full-size widget-block_speed-violation">
                <div className="tools-wrapper">
                    <div className="tools-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange}/>
                    </div>
                    <div className="tools-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange}/>
                    </div>
                    <div className="tools-item select-wrapper">
                        <WidgetSelector
                            title="Транспортное предприятие"
                            items={this.state.organizationUnits}
                            onChange={::this.onSelectedUnitsChange}
                        />
                    </div>
                </div>
                <div className="chart-wrapper">
                    <BarChart data={this.state.data.unitsByDate}
                              xDataKey="date"
                              yDataKey="violationsCount"
                              yLabel={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle" angle={-90}>Кол-во</Text>}
                              configKey="config"
                              color={this.props.barChartFillColor}
                    />
                </div>
            </div>
        );
    }

    render() {
        const loader = this.state.isLoading ? (
            <GlobalLoaderComponent/>
        ) : null;

        const content = this.renderContent();

        return (
            <div className="speed-violation-widget">
                {loader}
                {content}
            </div>
        );
    }
}