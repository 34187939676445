import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import _ from 'lodash';
import L from 'leaflet';
import MapComponent from "components/ui/map";
import {connect} from "react-redux";
import {getRoadPart, getRoadParts} from "store/reducers/kurs/road_parts";
import {getDocumentsList} from "store/reducers/reports/reports";
import Page from 'components/ui/page';
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import GlobalLoaderComponent from "components/ui/global-loader";
import DirectionalGeojson from "components/ui/map/objects/directional-geojson";

import './index.less';
import {
    Bar, BarChart, CartesianGrid, Label, LabelList, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis,
    YAxis, Text, AreaChart, Area, Legend, ReferenceLine, ReferenceDot
} from "recharts";
import * as moment from "moment";
import formats from "dictionaries/formats";
import Checkbox from "components/ui/form/checkbox";
import {LabeledCheckbox} from "components/ui/checkbox";
import ReportsGraphicTooltip from "components/modules/reports/Maps/ReportsGraphic/Tooltip/ReportsGraphicTooltip";

@connect(state => ({}), {getDocumentsList}, null, {withRef: true})

export default class ReportsGraphic extends Component {

    state = {
        loading: true,
        document: null,
        smoothing: false,
    };

    smoothFactor = 100;

    async componentWillMount() {
        this.loadDocument();
    }

    toggleSmoothing() {
        this.setState({
            smoothing: !this.state.smoothing,
        });
    }

    async loadDocument() {
        const response = await this.props.getDocumentsList({
            filters: {
                withUuid: this.props.params.uuid,
            },
        });

        if (response.isOk) {
            await this.setState({
                document: _.first(response.payload.items),
            });
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
            });
            response.showErrors();
        }
    }

    render() {
        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;

        return (
            <Page pageId="ReportsGraphic"
                  title={_.get(this.state.document, 'report_name', '...')}
                  className="r-block_noIndent"
                  headerActions={this.renderHeaderActions()}>
                {loader}
                {this.renderContent()}
            </Page>
        );
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="kurs.task.back" code="kurs.task.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ];
    }

    onClose() {
        this.props.router.push('/reports');
    }

    renderContent() {
        const {document} = this.state;
        if (!document) {
            return;
        }

        const {data} = document;

        return (
            <div className="ReportsGraphic">
                {this.renderHeader(document)}
                {_.map(data.graphic, (graphic, index) => {
                    return this['render_' + graphic.info.type](graphic, index);
                })}
            </div>
        );
    }

    renderHeader(document) {
        const {data} = document;
        const {headers} = data;
        if (!headers) {
            return null;
        }

        return (
            <div>
                <div className="ReportsGraphic__head-info">
                    с {moment(data.dateFrom).format(formats.DATETIME_SHORT)}
                    по {moment(data.dateTo).format(formats.DATETIME_SHORT)}<br/>
                    Организация: {_.get(data, 'unit.name')}
                </div>
                <table className="ReportsGraphic__table">
                    <tr>
                        {_.map(headers, (value, label) => {
                            return (
                                <th key={label}>{label}</th>
                            );
                        })}
                    </tr>
                    <tr>
                        {_.map(headers, (value, label) => {
                            return (
                                <td key={label}>{value}</td>
                            );
                        })}
                    </tr>
                </table>
            </div>
        );
    }

    render_bar(graphic, index) {
        const {info, bars, data} = graphic;

        return (
            <div key={index}>
                <h1>{info.title}</h1>
                <ResponsiveContainer width="100%" height={500}>
                    <BarChart data={data} layout={info.direction}>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <CartesianGrid horizontal={false}/>
                        <Tooltip/>
                        {_.map(bars, (bar, index) => {
                            return (
                                <Bar key={index} {...bar}/>
                            )
                        })}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }

    render_line(graphic, index) {
        let {info, lines, data} = graphic;
        const referenceLines = _.get(graphic, 'reference') || [];

        const dateFrom = moment(this.state.document.data.dateFrom.date);
        const dateTo = moment(this.state.document.data.dateTo.date);
        const dateStep = dateTo.diff(dateFrom, 'seconds') / this.smoothFactor;

        if (this.state.smoothing) {
            let currentTimestamp = null;
            data = _.filter(data, (value, index) => {
                const timestamp = moment.unix(value.name);

                if (!currentTimestamp || (timestamp.diff(currentTimestamp, 'seconds') >= dateStep)) {
                    currentTimestamp = timestamp;

                    return true;
                }
                return false;
            });
        }

        data = _.values(data);

        return (
            <div key={index}>
                <h1>{info.title}</h1>
                <div className="smoothing-checkbox">
                    <LabeledCheckbox label="Сглаживание" checked={this.state.smoothing}
                                     onChange={::this.toggleSmoothing}/>
                </div>
                <ResponsiveContainer width="100%" height={500}>
                    <LineChart data={data} layout={info.direction}>
                        <XAxis dataKey="name" type="number" domain={['dataMin', 'dataMax']} tickFormatter={(tick) => {
                            return moment.unix(tick).format(formats.DATETIME_SHORT);
                        }}/>
                        <YAxis/>
                        <CartesianGrid horizontal={false}/>
                        <Tooltip content={<ReportsGraphicTooltip referenceLines={referenceLines}/>} labelFormatter={(label) => {
                            return moment.unix(label).format(formats.DATETIME_SHORT);
                        }}/>
                        <Legend verticalAlign="top" height={50}/>
                        {_.map(referenceLines, (line, index) => {
                            return (
                                <ReferenceLine key={index} {...line}/>
                            );
                        })}
                        {_.map(lines, (line, index) => {
                            return (
                                <Line key={index} isAnimationActive={false} dot={false} {...line}
                                      type={this.state.smoothing ? 'monotone' : 'linear'}/>
                            )
                        })}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }

    render_pie(graphic, index) {
        const {info, data} = graphic;

        return (
            <div key={index}>
                <h1>{info.title}</h1>
                <ResponsiveContainer width="100%" height={500}>
                    <PieChart>
                        <Tooltip/>
                        <Legend/>
                        <Pie data={data} dataKey="value"/>
                    </PieChart>
                </ResponsiveContainer>
            </div>
        );
    }

}
