import React, {Component} from 'react';
import LoaderComponent from "components/ui/loader";
import ReactDOMServer from 'react-dom/server';

import _ from 'lodash';
import './entity.less';

export class EntityList {
    list = null;
    loader = null;

    constructor(response = null, keyBy = 'uuid') {
        this.list = response ? _.keyBy(response.payload.items, keyBy) : {};
        this.loader = ReactDOMServer.renderToStaticMarkup(this.getLoader());
    }

    add(response = null, keyBy = 'uuid') {
        const items = response ? response.payload.items : [];
        
        const nonEmptyItems = _.filter(items, (item) => {
            return item.name !== '-';
        });
        const emptyItems = _.filter(items, {name: '-'});

        this.newData = response ? _.keyBy(_.uniqBy(_.concat(nonEmptyItems, emptyItems), keyBy), keyBy) : {};
        _.assign(this.list, this.newData);
    }

    get(keyValue, ignoreDeleted = false, field = 'name') {
        if ( ! keyValue) {
            return null;
        }

        const entity = _.get(this.list, keyValue);
        if ( ! entity) {
            return this.loader;
        }

        return (entity.deleted && ! ignoreDeleted) ? ('<span class="entity-deleted" title="Сущность удалена">' + _.get(entity, field) + '</span>') : _.get(entity, field);
    }

    getItem(keyValue, defaultValue = null) {
        return _.get(this.list, keyValue, defaultValue);
    }

    getReact(uuid) {
        const entity = _.get(this.list, uuid);
        if ( ! entity) {
            return;
        }
        return entity.deleted ? <span class="entity-deleted" title="Сущность удалена">{entity.name}</span> : entity.name;
    }

    getLoader() {
        return <LoaderComponent color="red" align="none"/>;
    }
}
