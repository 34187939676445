import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defaultProps, propTypes} from 'react-props-decorators';
import _ from 'lodash';

import PageModal from 'components/ui/page-modal';

import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";

import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import {createRole, deleteRole, getPermissions, updateRole} from "store/reducers/roles/roles";
import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import Select from "components/ui/select";

import './editor.less';
import ContextTooltip from "components/ui/context-tooltip";
import {Slider} from "components/ui/slider";
import ValidationError from "components/ui/validation-error";
import currentUser from 'helpers/current-user';
import getGroups from './permissionsList';
import systems from "dictionaries/systems";

@propTypes({
    role: PropTypes.object.isRequired,
    uuid: PropTypes.string,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
})

@defaultProps({
    onEditClick: () => {
    },
    onDeleteClick: () => {
    },
})

@connect(state => ({}), {createRole, updateRole, deleteRole})

export default class RolesEditor extends Component {

    state = {
        uuid: null,
        saving: false,
        errors: {}
    };

    render() {
        let title = 'Добавление роли';

        const role = this.props.role;

        let form;
        let buttons;
        const loader = (this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (role.uuid) {
            title = 'Редактирование роли';

            form = <EditorForm
                {...this.props}
                ref="form"
                mode="edit"
                onSubmit={::this.edit}
                onDelete={::this.deleteItem}
                onClose={::this.props.onClose}
                data={role}
                errors={this.state.errors}
            />;

            buttons = (
                <ModalTopMenuButtons>
                    <ModalTopMenuList className="top-menu_modal_edit">
                        <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                        default="Журнал аудита">
                            <ModalTopMenuListItem
                                className="b-icon-link_params b-icon-link_icon_history"
                                href={`/system/audit/${role.uuid}?class=App\\Model\\Role`}
                            />
                        </ContextTooltip>

                        <ModalTopMenuListSeparator key="separator"/>
                    </ModalTopMenuList>

                    <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                        <ModalTopMenuButton
                            className="_save"
                            title="Сохранить"
                            onClick={::this.onEdit}
                        />
                    </ContextTooltip>

                    <ModalTopMenuButtonsSeparator/>

                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={::this.props.onClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );
        } else {
            form = <EditorForm
                {...this.props}
                ref="form"
                mode="add"
                onSubmit={::this.create}
                onClose={::this.props.onClose}
                data={role}
                errors={this.state.errors}
            />;

            buttons = (
                <ModalTopMenuButtons>
                    <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                        <ModalTopMenuButton
                            className="_save"
                            title="Сохранить"
                            onClick={::this.onCreate}
                        />
                    </ContextTooltip>

                    <ModalTopMenuButtonsSeparator/>

                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={::this.props.onClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );
        }

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}
                className="b-modal-role"
            >
                {loader}
                {form}
            </PageModal>
        );
    }

    clearErrors() {
        this.setState({errors: {}});
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    onCreate() {
        const state = this.refs.form.getWrappedInstance().getData();
        console.log("🚀 ~ file: editor.js ~ line 168 ~ RolesEditor ~ onCreate ~ state", state)
        this.create(state);
    }

    onEdit() {
        const state = this.refs.form.getWrappedInstance().getData();
        this.edit(state);
    }

    async create(data) {
        console.log("🚀 ~ file: editor.js ~ line 178 ~ RolesEditor ~ create ~ data", data)
        this.clearErrors();
        this.startSave();

        const role = this.composeRole(data);

        const response = await this.props.createRole(role);
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();

        const role = this.composeRole(data);

        const response = await this.props.updateRole(role);
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async deleteItem(uuid) {
        this.startSave();
        const response = await this.props.deleteRole({
            uuid,
        });
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    composeRole(data) {
        const result = {
            uuid: data.uuid,
            name: data.name,
            description: data.description,
            access_type: data.access_type,
            components: data.components || [this.props.params.component],
            permissions: _.map(data._permissions, (grants, code) => {
                return {
                    code: code.replace(/:::/g, '.'),
                    grants: _.keys(_.pickBy(grants, item => !!item)),
                };
            }),
        };
        return result;
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({
    permissions: state.roles.get('permissions'),
}), {
    getPermissions,
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        role: {},
        hiddenGroups: {},
        activeSection: 'system',
        custom: null,
    };

    sections = [
        {
            value: 'system',
            label: window.RNIS_SETTINGS.DICTIONARIESSYSTEMS.system.name || `${_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '100']).text} и настройки`
        },
        {value: 'analytics', label: 'Аналитическая платформа'},
        {value: 'passengers', label: 'Управление пассажирскими перевозками'},
        {value: 'ems', label: 'Электронная маршрутная сеть'},
        {value: 'roads', label: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text},
        {value: 'utility', label: 'Жилищно-коммунальное хозяйство'},
        {value: 'communal', label: 'Коммунальная техника'},
        {value: 'children', label: 'Управление перевозками детей'},
        {value: 'garbage', label: 'Контроль вывоза мусора'},
        {value: 'gatn', label: window.RNIS_SETTINGS.GUGATNTITLE},
        {value: 'reports', label: 'Отчёты'},
        {value: 'taxi', label: 'Мониторинг легковых такси'},
        {value: 'agricultural', label: 'Транспорт сельского хозяйства'},
        {value: 'medic', label: 'Контроль медицинского транспорта'},
        {value: 'dangerous', label: 'Перевозки опасных грузов'},
        {value: 'medicine', label: 'Центр медицины и катастроф'},
        {value: 'farming', label: 'Сельское хозяйство'},
        {value: 'official', label: 'Служебный транспорт'},
        {value: 'emercom', label: 'Пожарно-спасательная служба'},
        {value: 'forestry', label: 'Лесное хозяйство'},
        {value: 'timber', label: 'Перевозки лесоматериалов'},
        {value: 'commdept', label: 'Коммунальная техника (ДТ)'},
    ].filter((section) => !window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[section.value].hideForRoles);

    getData() {
        return this.state.role;
    }

    async componentDidMount() {
        let response;
        await this.setState({
            role: this.decomposeRole(this.props.data),
        });
        if (window.RNIS_SETTINGS.CITY_MURMANSK) {
            const isNeedCustomResponse = true;
            response = await this.props.getPermissions(isNeedCustomResponse);
        } else {
            response = await this.props.getPermissions();
        }
        if (!response.isOk) {
            response.showErrors();
        }
    }

    decomposeRole(role) {
        let data = {
            uuid: role.uuid,
            name: role.name,
            components: role.components || [],
            description: role.description || '',
            access_type: role.access_type,
            _permissions: {},
        };

        _.each(role.permissions || [], (permission) => {
            const code = permission.code.replace(/\./g, ':::');
            data._permissions[code] = {};
            _.each(permission.grants, (grant) => {
                data._permissions[code][grant] = true;
            });
        });

        return data;
    }

    get(path, defaultValue = null) {
        return _.get(this.state.role, path, defaultValue);
    }

    onChangeSection = ({value}) => {
        this.setState({activeSection: value});
    };

    getFilteredSystems() {
        const showedSystems = {}
        Object.keys(systems).forEach((systemKey) => {
            if (!window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[systemKey]) {
                showedSystems[systemKey] = systems[systemKey]
            } else if (!window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[systemKey].hideForRoles) {
                showedSystems[systemKey] = window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[systemKey].name || systems[systemKey]
            }
        })

        return _.map(showedSystems, (label, value) => ({
            value,
            label,
        }));
    }

    getOtherPermissions() {
        const permissionsInForm = _.flatten(_.map(_.values(getGroups()), (group) => {
            const innerPermissions = _.flatten(_.values(group.innerPermissions));
            return _.concat(group.permissions || [], innerPermissions);
        }));

        const permissions = _.map(this.props.permissions, 'code');

        return _.uniq(_.difference(permissions, permissionsInForm));
    }

    getGroups() {
        let groups = getGroups();
        if (!window.RNIS_SETTINGS.CITY_MURMANSK || this.state.role.components && !this.state.role.components.includes('road')) {
            delete groups.system.innerPermissions['Перенесенные']
        }
        if (window.RNIS_SETTINGS.CITY_MURMANSK) {
            const safeList = ['com.rnis.reports.report.kurs.kurs_vehicle_by_work_type_report', 'com.rnis.reports.report.kurs.road_part_combined_report', 'com.rnis.reports.report.kurs.kurs_task_fact_performance_report', 'com.rnis.reports.report.vehicles.unit_roads_report',
                'com.rnis.reports.report.kurs.kurs_unit_work_report', 'com.rnis.reports.report.kurs.vehicle_and_human_work_report', 'com.rnis.reports.report.kurs.summary_vehicle_types_report',
                'com.rnis.reports.report.kurs.kurs_task_work_report', 'com.rnis.reports.report.kurs.kurs_task_manual_clean_report', 'com.rnis.reports.report.kurs.vehicles_mileage_unit_report',
                'com.rnis.reports.report.kurs.kurs_manual_clean_period_report', 'com.rnis.reports.report.kurs.cleanup_specialists_report',
                'com.rnis.reports.report.kurs.contracts_and_streets_report',
                'com.rnis.reports.report.kurs.contracts_and_units_report',
                'com.rnis.reports.report.kurs.contracts_street_maintenance_report',
                'com.rnis.reports.report.kurs.contracts_street_maintenance_map_report',
                'com.rnis.reports.report.kurs.vehicles_last_data_report',
                'com.rnis.reports.report.kurs.kurs_vehicles_last_data_report',
                'com.rnis.reports.report.cbdd.vehicles_last_data_report',
                'com.rnis.reports.report.kurs.kurs_task_works',
                'com.rnis.reports.report.kurs.kurs_unit_status',
                'com.rnis.reports.report.kurs.kurs_unit_works',
                'com.rnis.reports.report.kurs.works_volume',
                'com.rnis.reports.report.kurs.road_part_control_work',
                'com.rnis.reports.report.kurs.kurs_no_plan_work',
                'com.rnis.reports.report.kurs.kurs_periodicity_work_on_tasks'];
            const keys = Object.keys(groups.reports.innerPermissions);
            for (const name of keys) {
                groups.reports.innerPermissions[name] = groups.reports.innerPermissions[name].filter(item => safeList.includes(item));
            }
        }
        return groups;
    }

    /*
    .filter((section) => console.log(!(window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[section.value] || {}).hideForRoles) || !(window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[section.value] || {}).hideForRoles);
    */

    render() {
        let groups = {[this.state.activeSection]: this.getGroups()[this.state.activeSection].permissions};

        const usedPermissions = _.flatten(_.values(groups));
        const otherPermissions = _.map(_.filter(this.props.permissions, (permission) => {
            return _.indexOf(usedPermissions, permission.code) === -1;
        }), 'code');

        const currentUserAccessType = currentUser.accessType();
        const activeSection = this.sections.find(section => section.value === this.state.activeSection);

        return (
            <div className="RoleEditorForm">
                <Block size="xl" title="Наименование">
                    {this.textInput('role.name')}
                </Block>
                <Block size="xl" title="Описание">
                    {this.textInput('role.description')}
                </Block>
                <Block size="xl" title="Подсистемы">
                    {this.select('role.components', this.getFilteredSystems(), {
                        multi: true,
                    })}
                </Block>
                <Block size="xl" title="Раздел">
                    <Select
                        value={activeSection}
                        options={this.sections}
                        onChange={this.onChangeSection}
                        clearable={false}
                    />
                </Block>
                <Block size="xl" title="Доступ к объектам">
                    {this.radio('role.access_type', 'unit', 'Свое предприятие')}
                    {((currentUserAccessType === 'unit_with_children') || (currentUserAccessType === 'all')) ? (
                        this.radio('role.access_type', 'unit_with_children', 'Свое и дочерние')
                    ) : null}
                    {(currentUserAccessType === 'all') ? (
                        this.radio('role.access_type', 'all', 'Все предприятия')
                    ) : null}
                    <ValidationError error={this.getError('role.access_type')}
                                     className="input__style_state_wrong-radio"/>
                </Block>
                <Block size="xl">
                    <table className="permissions-table">
                        <thead>
                        <tr>
                            <th/>
                            <th className="permission-cell">Создание</th>
                            <th className="permission-cell">Чтение</th>
                            <th className="permission-cell">Редакт.</th>
                            <th className="permission-cell">Удаление</th>
                        </tr>
                        </thead>
                        <tbody>
                        {_.map(groups, ::this.renderPermissionGroup)}
                        {/* {this.renderPermissionGroup(otherPermissions, 'Прочее')} */}
                        </tbody>
                    </table>
                </Block>
            </div>
        );
    }

    renderPermissionGroup(permissionCodes, title) {
        const permissions = _.filter(this.props.permissions, (permission) => (_.indexOf(permissionCodes, permission.code) !== -1));
        const InnerCodes = _.flatMap(this.getGroups()[title].innerPermissions, codes => codes);
        const allCodes = _.concat(permissionCodes, InnerCodes);
        const allPermissions = _.filter(this.props.permissions, (permission) => (_.indexOf(allCodes, permission.code) !== -1));

        const components = this.get('components') || [this.props.params.component];
        const createPermissions = _.filter(allPermissions, (permission) => {
            return currentUser.can(permission.code, 'create', components) && (_.indexOf(permission.grants, 'create') !== -1);
        });
        const readPermissions = _.filter(allPermissions, (permission) => {
            return currentUser.can(permission.code, 'read', components) && (_.indexOf(permission.grants, 'read') !== -1);
        });
        const updatePermissions = _.filter(allPermissions, (permission) => {
            return currentUser.can(permission.code, 'update', components) && (_.indexOf(permission.grants, 'update') !== -1);
        });
        const deletePermissions = _.filter(allPermissions, (permission) => {
            return currentUser.can(permission.code, 'delete', components) && (_.indexOf(permission.grants, 'delete') !== -1);
        });

        const isChecked = (grant, permission) => {
            const code = permission.code.replace(/\./g, ':::');

            return this.get(`_permissions.${code}.${grant}`);
        };

        const hasGrant = (grant, permission) => {
            return _.indexOf(permission.grants, grant) !== -1;
        };

        return (
            [
                <tr key={`${title}:select`}>
                    <td/>
                    <td>
                        {(_.filter(createPermissions, hasGrant.bind(this, 'create')).length > 0) ? (
                            <Slider
                                selected={_.filter(createPermissions, isChecked.bind(this, 'create')).length}
                                total={createPermissions.length}
                                selectAll={this.selectAll.bind(this, createPermissions, 'create')}
                                deselectAll={this.deselectAll.bind(this, createPermissions, 'create')}
                            />
                        ) : null}
                    </td>
                    <td>
                        {(_.filter(readPermissions, hasGrant.bind(this, 'read')).length > 0) ? (
                            <Slider
                                selected={_.filter(readPermissions, isChecked.bind(this, 'read')).length}
                                total={readPermissions.length}
                                selectAll={this.selectAll.bind(this, readPermissions, 'read')}
                                deselectAll={this.deselectAll.bind(this, readPermissions, 'read')}
                            />
                        ) : null}
                    </td>
                    <td>
                        {(_.filter(updatePermissions, hasGrant.bind(this, 'update')).length > 0) ? (
                            <Slider
                                selected={_.filter(updatePermissions, isChecked.bind(this, 'update')).length}
                                total={updatePermissions.length}
                                selectAll={this.selectAll.bind(this, updatePermissions, 'update')}
                                deselectAll={this.deselectAll.bind(this, updatePermissions, 'update')}
                            />
                        ) : null}
                    </td>
                    <td>
                        {(_.filter(deletePermissions, hasGrant.bind(this, 'delete')).length > 0) ? (
                            <Slider
                                selected={_.filter(deletePermissions, isChecked.bind(this, 'delete')).length}
                                total={deletePermissions.length}
                                selectAll={this.selectAll.bind(this, deletePermissions, 'delete')}
                                deselectAll={this.deselectAll.bind(this, deletePermissions, 'delete')}
                            />
                        ) : null}
                    </td>
                </tr>,
                (!this.state.hiddenGroups[title]) ? permissions.map((el, i) => this.renderPermission(el, i, false)) : null,
                (_.flatMap(this.getGroups()[title].innerPermissions, this.renderInnerPermission))
            ]
        );
    }

    renderInnerPermission = (permissionCodes, title) => {
        const permissions = _.filter(this.props.permissions, (permission) => (_.indexOf(permissionCodes, permission.code) !== -1));
        return [
            <tr key={title} className="permission-group-title">
                <td>{title}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>,
            (permissions.map((el, i) => this.renderPermission(el, i, true)))
        ];
    };

    selectAll(permissions, grant) {
        let role = this.state.role;

        _.each(permissions, (permission) => {
            if (permission.code === 'com.rnis.portal.menu') {
                return;
            }
            const code = permission.code.replace(/\./g, ':::');

            _.set(role, `_permissions.${code}.${grant}`, true);
        });

        this.setState({role});
    }

    deselectAll(permissions, grant) {
        let role = this.state.role;

        _.each(permissions, (permission) => {
            const code = permission.code.replace(/\./g, ':::');
            _.set(role, `_permissions.${code}.${grant}`, false);
        });

        this.setState({role});
    }

    toggleGroup(title) {
        let hiddenGroups = this.state.hiddenGroups;
        hiddenGroups[title] = !hiddenGroups[title];
        this.setState({hiddenGroups});
    }

    renderPermission(permission, index, isSubitem) {
        const code = permission.code.replace(/\./g, ':::');
        const component = this.get('component') || this.props.params.component;
        const hasGrant = (grant) => {
            if (_.indexOf(permission.grants, grant) === -1) {
                return false;
            }

            const components = this.get('components') || [];
            if (components.length === 0) {
                return false;
            }

            return _.filter(components, (component) => {
                return !currentUser.can(permission.code, grant, component);
            }).length === 0;
        };

        return (
            <tr key={index} className={isSubitem ? '' : 'permission-group-title'}>
                <td className={isSubitem ? "permission-title" : ''}>{permission.name}</td>
                <td className="permission-cell">{hasGrant('create') ? (
                    <ContextTooltip
                        default="Создание">{this.checkbox(`role._permissions.${code}.create`)}</ContextTooltip>
                ) : null}</td>
                <td className="permission-cell">{hasGrant('read') ? (
                    <ContextTooltip default="Чтение">{this.checkbox(`role._permissions.${code}.read`)}</ContextTooltip>
                ) : null}</td>
                <td className="permission-cell">{hasGrant('update') ? (
                    <ContextTooltip
                        default="Редактирование">{this.checkbox(`role._permissions.${code}.update`)}</ContextTooltip>
                ) : null}</td>
                <td className="permission-cell">{hasGrant('delete') ? (
                    <ContextTooltip
                        default="Удаление">{this.checkbox(`role._permissions.${code}.delete`)}</ContextTooltip>
                ) : null}</td>
            </tr>
        );
    }
}
