import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import PageModal from 'components/ui/page-modal';

import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import * as alerts from "helpers/alerts";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import {getRegisterRequest, updateRegisterRequest} from "store/reducers/portal/register_requests";
import moment from "moment";
import formats from "dictionaries/formats";
import systems from "dictionaries/systems";
import Button from "components/ui/button";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import {
    getCooperationAgreementWarnings,
    updateCooperationAgreement, updateCooperationAgreementWarning
} from "store/reducers/portal/cooperation_agreements";
import {getTemplateDocuments} from "store/reducers/portal/template_documents";
import {xmlVerify} from "store/reducers/ecp/ecp";
import Select from "components/ui/select";
import Script from 'react-load-script';
import * as rfc2253 from 'rfc2253';
import EasyXml from 'easyxml';
import {State} from "components/ui/state";
import Textarea from "components/ui/form/textarea";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import CooperationAgreementOperatorWarningAdd from "components/modules/operator/cooperation_agreements/warning-add";
import currentUser from 'helpers/current-user';

@propTypes({
    item: PropTypes.object,
    onClose: PropTypes.func,
})

@connect(state => ({}), {
    getCooperationAgreementWarnings,
    updateCooperationAgreementWarning,
    getEntityNames,
    getDictionaryList,
})

export default class CooperationAgreementOperatorWarning extends BaseEditor {

    state = {
        items: [],
        related: new EntityList,
        portal_cooperation_agreement_warnings: [],
    };

    async componentWillMount() {
        this.loadItems();

        this.loadDictionaries([
            'portal_cooperation_agreement_warnings',
        ]);
    }

    async loadItems() {
        const response = await this.props.getCooperationAgreementWarnings({
            filters: {
                withAgreement: this.props.item.uuid,
            },
        });

        if (response.isOk) {
            await this.setState({
                items: response.payload.items,
            });
            this.loadRelated();
        } else {
            response.showErrors();
        }
    }

    async loadRelated() {
        const userUuids = _.uniq(_.filter(_.flatten(_.map(this.state.items, (item) => ([
            item.user_apply_warning_uuid,
            item.user_remove_warning_uuid,
        ])))));
        const users = _.map(userUuids, (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));

        const response = await this.props.getEntityNames(users);

        if (response.isOk) {
            this.state.related.add(response);
            this.forceUpdate();
        }
    }

    showAdd() {
        this.setState({
            addActive: true,
        });
    }

    hideAdd(e) {
        if (e === true) {
            this.loadItems();
        }
        this.setState({
            addActive: false,
        });
    }

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ModalTopMenuList className="top-menu_modal_edit">
                    <ContextTooltip key="cooperation_agreements.terminate" code="cooperation_agreements.terminate"
                                    default="Вынести">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_plus"
                            onClick={::this.showAdd}
                        />
                    </ContextTooltip>
                </ModalTopMenuList>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <div>
                <PageModal
                    header={{title: 'Предупреждения', buttons}}
                    onClose={this.props.onClose}
                    className={`profile-modal b-modal-register-requests-history AgreementWarning AgreementCooperation`}
                    withFade={false}
                >
                    {this.renderContent()}
                    {this.renderModals()}
                </PageModal>
            </div>
        );
    }

    renderModals() {
        if (this.state.addActive) {
            return (
                <CooperationAgreementOperatorWarningAdd
                    {...this.props}
                    onClose={::this.hideAdd}
                    />
            );
        }
    }

    renderContent() {
        const loader = this.state.saving ? (<GlobalLoaderComponent/>) : null;

        if ((this.state.items || []).length === 0) {
            return null;
        }

        return (
            <div className="b-modal-agreement">
                <Block size="xl">
                    {loader}
                    {_.map(this.state.items || [], ::this.renderItem)}
                </Block>
            </div>
        );
    }

    renderItem(item, index) {
        return (
            <div key={item.uuid}>
                <div className="LogItem">
                    <div className="LogItem-table">
                        <div
                            className="LogItem-cell LogItem-time border-right LogItem-DatePronouncing">{moment(item.warning_date_apply).format(formats.DATE)}
                            <br/> {moment(item.warning_date_apply).format(formats.TIME)}</div>
                        {item.warning_date_remove ? (
                            <div
                                className="LogItem-cell LogItem-time border-right LogItem-DateRemoval">{moment(item.warning_date_remove).format(formats.DATE)}
                                <br/> {moment(item.warning_date_remove).format(formats.TIME)}</div>
                        ) : (
                            <div className="LogItem-cell LogItem-time border-right LogItem-DateDash"> - </div>
                        )}
                        <div
                            className="LogItem-cell LogItem-text">{_.get(_.find(this.state.portal_cooperation_agreement_warnings, {value: item.warning_cause_uuid}), 'label')}</div>
                        <div className="LogItem-cell LogItem-user border-left">
                            {item.user_apply_warning_uuid ? (
                                <div
                                    className="LogItem-userName">{this.state.related.getReact(item.user_apply_warning_uuid)}</div>
                            ) : null}
                            {item.user_remove_warning_uuid ? (
                                <div
                                    className="LogItem-userName">{this.state.related.getReact(item.user_remove_warning_uuid)}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
                {item.warning_date_remove ? (
                    <div className="LogItem LogItem-status">
                        <div className="LogItem-status-TextWarning">{item.text_warning}</div>
                        <div className="LogItem-status-TextComment">{item.text_removing}</div>
                    </div>
                ) : (
                    <div className="LogItem">
                        <div className="LogItem-status-TextWarning">{item.text_warning}</div>
                        <div className="textarea_input">
                            <Textarea
                                value={item.text_removing}
                                onChange={this.changeTextRemoving.bind(this, index)}
                                placeholder="Комментарий"
                            />
                        </div>
                        <div className="b-block _sm">
                            <div className="b-modal_button" onClick={this.closeWarning.bind(this, index)}>Снять предупреждение</div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    changeTextRemoving(index, {target: {value}}) {
        let items = this.state.items;
        items[index].text_removing = value;
        this.setState({items});
    }

    async closeWarning(index) {
        this.setState({saving: true});

        let item = _.cloneDeep(this.state.items[index]);
        item.user_remove_warning_uuid = currentUser.uuid();
        item.warning_date_remove = moment().format(formats.DATETIME_API);
        const response = await this.props.updateCooperationAgreementWarning(item);

        this.setState({saving: false});

        if (response.isOk) {
            this.loadItems();
        } else {
            response.showErrors();
        }
    }
}