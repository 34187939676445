import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import {connect} from "react-redux";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Loader from 'components/ui/loader';
import ViolationTable from '../ViolationTable';
import Block from "components/ui/form/block";

@propTypes({
    data: PropTypes.object
})

@defaultProps({
    data: {}
})

@connect((state) => ({}), {getDictionaryList}, null, {withRef: true})

export default class ViolationVehiclesForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableData: [],
            isLoading: true
        };

        this.rowHeight = 40;

        this.metadata = [
            {field: 'number', name: '№'},
            {field: 'total', name: 'Общее число ТС'},
        ];

        this.violationsConfig = {};
    }

    componentWillMount() {
        this.getCapacityTypes();
    }

    async getCapacityTypes() {
        const items = this.props.data.items;
        if (items.length) {
            this.setState({isLoading: true});
            const response = await this.props.getDictionaryList('vehicle_capacity_types');
            if (response.isOk) {
                const dictTypes = response.payload.documents;

                _.filter(dictTypes, 'name').forEach((type, index) => {
                    this.metadata[index + 2] = {field: type['name'], name: type['short_name'],};
                });

                this.metadata[Object.keys(this.metadata).length] = {
                    field: 'equipment',
                    name: 'Оснащенность',
                    className: 'align-center',
                };

                const data = [];
                const item = items[0];
                const planTypes = {};
                const factTypes = {};

                const planned = _.get(item, 'data.planned') || {equipment: {}};
                const fact = _.get(item, 'data.fact') || {equipment: {}};

                Object.keys(planned).forEach((key) => {
                    const dictItem = _.find(dictTypes, {'uuid': key});

                    if (dictItem) {
                        planTypes[dictItem['name']] = planned[key];
                    }
                });

                Object.keys(fact).forEach((key) => {
                    const dictItem = _.find(dictTypes, {'uuid': key});

                    if (dictItem) {
                        factTypes[dictItem['name']] = fact[key];
                    }
                });

                const equipmentPlanned = {
                    'low-floor': planned.equipment.is_low_floor_level,
                    'cashless': planned.equipment.is_cashless_payment,
                    'audio-video': planned.equipment.is_audio_video_fixation,
                    'monitoring': planned.equipment.is_passenger_monitoring_system,
                };
                const planObj = Object.assign({
                    number: window.RNIS_SETTINGS.rename_contracts ? 'Подрядное обязательство' : 'Контракт',
                    total: planned.total || 0,
                    equipment: _.keys(_.pickBy(equipmentPlanned, item => !!item)).map((icon) => {
                        return (
                            <span key={icon} className={`equipment-icon equipment-icon_${icon}`}/>
                        );
                    }),
                }, planTypes);

                data.push(planObj);

                const equipmentFact = {
                    'low-floor': fact.equipment.is_low_floor_level,
                    'cashless': fact.equipment.is_cashless_payment,
                    'audio-video': fact.equipment.is_audio_video_fixation,
                    'monitoring': fact.equipment.is_passenger_monitoring_system,
                };
                const factObj = Object.assign({
                    number: 'Факт',
                    total: fact.total || 0,
                    equipment: _.keys(_.pickBy(equipmentFact, item => !!item)).map((icon) => {
                        return (
                            <span key={icon} className={`equipment-icon equipment-icon_${icon}`}/>
                        );
                    }),
                }, factTypes);

                data.push(factObj);


                this.violationsConfig[1] = {
                    total: ((planned.total || 0) !== (fact.total || 0))
                };

                dictTypes.forEach((type) => {
                    const key = type['short_name'];

                    if (planTypes[key] !== factTypes[key]) {
                        this.violationsConfig[1][key] = true;
                    }
                });

                this.setState({tableData: data, isLoading: false});
            } else {
                this.setState({isLoading: false});
                response.showErrors();
            }
        }
    }

    render() {
        const {date, contractUnitName, routeNumber, routeTitle} = this.props.data;

        return (
            <div className="b-modal__block">
                <Block>
                    <div className="violation-info">
                        <span className="bold">Дата: </span>
                        {date}
                    </div>
                </Block>
                <Block size="xl">
                    <div className="violation-info">
                        <span className="bold">Маршрут: </span>
                        №{routeNumber} ({routeTitle})
                    </div>
                    <div className="violation-info">
                        <span className="bold">Транспортное предприятие: </span>
                        {contractUnitName}
                    </div>
                </Block>

                <div className="violation_table__content">
                    {this.state.isLoading ? <Loader color="red" size={32}/> :
                        <ViolationTable data={this.state.tableData} metadata={this.metadata}
                                        violationsConfig={this.violationsConfig}/>}
                </div>
            </div>
        );
    }
}