import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import PageModal from 'components/ui/page-modal';
import Page from "components/ui/page";
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import {api} from "helpers/api";
import {createContract, getContract, updateContract} from "store/reducers/commdept/contracts";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import FileReaderInput from 'react-file-reader-input';
import GlobalLoaderComponent from "components/ui/global-loader";
import TabItem from "components/ui/tabs/tab-item";
import Tabs from "components/ui/tabs/tabs";
import systems from "dictionaries/systems";
import TabBlock from "components/ui/tabs/tab-block";
import './style.less';
import BlockGroup from "components/ui/form/block-group";
import Button from "components/ui/button";
import TableContainer from "components/ui/Table/Container/TableContainer";
import CommdeptContractWorksComponent from "components/modules/commdept/contract_works/list";
import {updateContractWork} from "store/reducers/commdept/contract_works";
import UnitsEditor from "components/modules/organizational-units/units/editor";
import moment from "moment";
import formats from "dictionaries/formats";
import months from "dictionaries/months";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import Modal from "components/ui/modal";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import {getStos} from "store/reducers/commdept/stos";
import * as alerts from "helpers/alerts";
import IconButton from "components/ui/icon-button";
import ReactDOM from 'react-dom'
import {printForm} from "helpers/print";
import currentUser from 'helpers/current-user';

@connect(state => ({}), {getContract, createContract, updateContract, updateContractWork})

export default class CommdeptContractEditor extends BaseEditor {

    title = 'контракта';
    modelClass = 'App\\Model\\Contract';

    async componentWillMount() {
        const propsUuid = (this.props.params.uuid === 'create') ? null : this.props.params.uuid;
        if (this.state.uuid !== propsUuid) {
            this.setState({
                uuid: propsUuid,
                item: null,
                isLoading: !!propsUuid,
                mode: propsUuid ? 'edit' : 'add',
            });

            if (propsUuid) {
                const response = await this.loadData(propsUuid);
                if (response.isOk) {
                    this.setState({
                        item: response.payload,
                        isLoading: false,
                    });
                } else {
                    response.showErrors();
                }
            }
        }
    }

    async componentWillUpdate(props, state) {
    }

    async loadData(uuid) {
        return await this.props.getContract(uuid);
    }

    async createItem(data) {
        return await this.props.createContract(data);
    }

    async updateItem(data) {
        return await this.props.updateContract(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.state.mode}
                onClose={::this.onClose}
                onDataLoad={::this.forceUpdate}
                data={item}
                errors={this.state.errors}
                onUpdate={::this.onFormUpdate}
            />
        );
    }

    onFormUpdate() {
        this.setState({
            formUpdated: true,
        });
    }

    onClose() {
        if (this.state.formUpdated) {
            alerts.prompt('Вы действительно хотите закрыть эту страницу без сохранения данных?', '', () => {
                this.props.router.push('/commdept/contracts');
            }, 'Закрыть');
        } else {
            this.props.router.push('/commdept/contracts');
        }
    }

    async create(data) {
        this.clearErrors();
        this.startSave();

        this.setState({
            formUpdated: false,
        });
        const response = await this.createItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            await this.props.router.push('/commdept/contracts');
            this.props.router.push(`/commdept/contracts/${response.payload.uuid}`);
            //this.onClose();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();

        this.setState({
            formUpdated: false,
        });
        const response = await this.updateItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            this.setState({
                item: response.payload,
            });
            //this.onClose();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async saveGraph() {
        this.startSave();

        const data = this.refs.graph.getWrappedInstance().getData();

        await Promise.all(_.map(data, async (contractWork) => {
            return this.props.updateContractWork(contractWork);
        }));
    }

    async onEdit(e) {
        e && e.preventDefault();
        if (!this.refs.form) return;

        if (this.refs.graph) {
            await this.saveGraph();
        }

        const state = this.refs.form.getWrappedInstance().getData();
        this.edit(state);
    }

    render() {
        let title = window.RNIS_SETTINGS.rename_contracts ? 'Создание подрядного обязательства' : 'Создание контракта';

        let form;
        let onSubmit;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.state.mode === 'edit') {
            title = '';

            if (this.state.item) {
                title = `${window.RNIS_SETTINGS.rename_contracts ? 'Подрядное обязательство' : 'Контракт'} №${this.state.item.number || 'не указан'}`;
                form = this.getForm(this.state.item, ::this.edit);
            }

            onSubmit = ::this.onEdit;
        } else {
            form = this.getForm({}, ::this.create);

            onSubmit = ::this.onCreate;
        }

        return (
            <Page
                pageId="ContractManagement"
                title={`${systems.commdept} → ${title}`}
                headerActions={this.renderHeaderActions()}
            >
                {loader}
                {form}
                {this.state.formUpdated ? (
                    <div className="page-footer">
                        <div className="page-footer__txt">Вы хотите сохранить все изменения?</div>
                        <Button size="md" color="white" shadow="gray" className="b-button_cancel" text="Отменить"
                                onClick={::this.onReset}/>
                        <Button size="md" color="red" className="b-button_save" text="Сохранить"
                                onClick={onSubmit}/>
                    </div>
                ) : null}
            </Page>
        );
    }

    renderHeaderActions() {
        return [
            ((this.state.mode === 'edit') && currentUser.can('com.rnis.system.permission.audit', 'read') && this.modelClass) ?
                (
                    <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                    default="Журнал аудита">
                        <IconButton icon="history" onClick={::this.gotoAudit}/>
                    </ContextTooltip>
                ) : null,
            <ContextTooltip key="base-table-list.print" code="base-table-list.print" default="Печать">
                <IconButton icon="print" onClick={::this.print}/>
            </ContextTooltip>,
            <ContextTooltip key="kurs.contract.back" code="kurs.contract.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ];
    }

    gotoAudit() {
        const url = `/system/audit/${this.state.uuid}?class=${this.modelClass}`;

        this.props.router.push(url);
    }

    onReset() {
        this.refs.form.getWrappedInstance().onReset();
        this.setState({
            formUpdated: false,
        });
    }

    print() {
        if (!this.refs.form) return;

        printForm(ReactDOM.findDOMNode(this.refs.form.getWrappedInstance()));
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDataLoad: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getDictionaryList, getUnits, getUnit, getStos}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        contract: {},
        work_categories: [],
        units: [],
        unitsFull: [],
        kurs_contract_statuses: [],
        implementer: {},
        implementerActive: null,
        work_types: [],
        detailsUuid: null,
        stos: [],
    };

    getData() {
        return this.state.contract;
    }

    onReset() {
        let contract = _.cloneDeep(this.props.data);
        contract.works = _.map(contract.works || [], (work) => {
            work.key = work.uuid;
            return work;
        });
        this.setState({
            contract,
        });
    }

    async componentDidMount() {
        let contract = _.cloneDeep(this.props.data);
        contract.works = _.map(contract.works || [], (work) => {
            work.key = work.uuid;
            return work;
        });
        await this.setState({
            contract,
        });

        this.loadUnits();
        this.loadStos();
        this.loadImplementer();
        this.loadDictionaries([
            'work_categories',
            'kurs_contract_statuses',
            'work_types',
            'maintenance_groups',
        ]);
    }

    async loadStos() {
        /*if (!this.get('implementer_uuid')) {
            return;
        }*/
        const response = await this.props.getStos({
            filters: {
                onlyActive: true,
                //withUnit: this.get('implementer_uuid'),
            },
        });

        if (response.isOk) {
            this.setState({
                stos: _.map(response.payload.items, item => ({
                    value: item.uuid,
                    label: item.number,
                    works: item.works,
                    status_uuid: item.status_uuid,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async loadImplementer() {
        const uuid = this.get('implementer_uuid');
        if (!uuid) {
            this.setState({
                implementer: {},
            });
            return;
        }

        const response = await this.props.getUnit(uuid);

        if (response.isOk) {
            this.setState({
                implementer: response.payload,
            });
        }
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withComponent: 'commdept',
            },
        });
        if (response.isOk) {
            this.setState({
                unitsFull: _.keyBy(response.payload.items, 'uuid'),
                units: _.sortBy(_.map(response.payload.items, (item) => ({
                    label: item.name,
                    value: item.uuid,
                })), 'label'),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.contract, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Tabs>
                    <TabItem key="main" title={window.RNIS_SETTINGS.rename_contracts ? "Страница подрядного обязательства" : "Страница контракта"}>
                        <TabBlock withBorder={true}>
                            <div>
                                <Block title={window.RNIS_SETTINGS.rename_contracts ? "Наименование подрядного обязательства *" : "Наименование контракта *"}>
                                    {this.textInput('contract.name')}
                                </Block>
                                <Block title="Заказчик *">
                                    {this.textInput('contract.client')}
                                </Block>
                                <Block title="Статус *">
                                    {this.select('contract.status_uuid', this.state.kurs_contract_statuses)}
                                </Block>
                                <Block size="md" title="СКПДИ">
                                    {this.textInput('contract.external_id', {
                                        disabled: true,
                                    })}
                                </Block>
                            </div>
                            <div>
                                <Block size="md" title="root_id СКПДИ">
                                    {this.textInput('contract.root_id', {
                                        disabled: true,
                                    })}
                                </Block>
                                <Block size="md" title="Статус СКПДИ">
                                    {this.textInput('contract.skpdi_status', {
                                        disabled: true,
                                    })}
                                </Block>
                                <Block title="Категория работ *">
                                    {this.select('contract.work_category_uuid', this.state.work_categories, {
                                        disabled: this.props.mode === 'edit',
                                    })}
                                </Block>
                                {(!this.get('is_economic_method')) ? ([
                                    <Block key="number" title="Рег. номер документа">
                                        {this.textInput('contract.number')}
                                    </Block>,
                                    <Block key="signed_at" title="Дата заключения">
                                        {this.datepicker('contract.signed_at')}
                                    </Block>,
                                ]) : null}
                            </div>
                            <div>
                                <Block size="xl">
                                    {this.checkbox('contract.is_economic_method', 'Выполняется хозяйственным способом')}
                                </Block>
                            </div>
                        </TabBlock>
                        <TabBlock withBorder={true}>
                            <div>
                                <Block title="Действует с *">
                                    {this.datepicker('contract.date_from')}
                                </Block>
                                <Block title="Действует по *">
                                    {this.datepicker('contract.date_to')}
                                </Block>
                                <Block title={window.RNIS_SETTINGS.rename_contracts ? "Скан подрядного обязательства" : "Скан контракта"}>
                                    <div className="input">
                                        <div className="input__style"/>
                                        {this.get('file') ? (
                                            <span className="input__edit-file">
                                        <a className="open-file" href={this.get('file')} target="_blank">Открыть</a>
                                        <a className="delete-file" href="javascript:void(0)"
                                           onClick={::this.deleteFile}>Удалить</a>
                                    </span>
                                        ) : (
                                            <FileReaderInput as="binary" id="my-file-input"
                                                             onChange={::this.uploadFile}>
                                                <span className="input__load-file">Добавить скан {window.RNIS_SETTINGS.rename_contracts ? "подрядного обязательства" : "контракта"}</span>
                                            </FileReaderInput>
                                        )}
                                    </div>
                                </Block>
                            </div>
                            {this.isMaintenance() ? ([
                                <BlockGroup key="summer" title="Сезон Лето">
                                    <Block title="Дата с">
                                        {this.datepicker('contract.summer_from')}
                                    </Block>
                                    <Block title="Дата по">
                                        {this.datepicker('contract.summer_to')}
                                    </Block>
                                </BlockGroup>,
                                <BlockGroup key="winter" title="Сезон Зима">
                                    <Block title="Дата с">
                                        {this.datepicker('contract.winter_from')}
                                    </Block>
                                    <Block title="Дата по">
                                        {this.datepicker('contract.winter_to')}
                                    </Block>
                                </BlockGroup>,
                            ]) : null}
                        </TabBlock>
                        <TabBlock withBorder={true}>
                            <div>
                                <Block size="lg" title="Исполнитель *">
                                    {this.select('contract.implementer_uuid', this.state.units)}
                                </Block>
                                {this.get('implementer_uuid') ? (
                                    <Block>
                                        <Button size="lg" color="white" shadow="gray" width="full"
                                                text="Данные о контрагенте"
                                                onClick={::this.gotoImplementer}/>
                                    </Block>
                                ) : null}
                            </div>
                            {this.get('implementer_uuid') ? (
                                <div>
                                    <Block title="Руководитель">
                                        {this.state.implementer.senior}
                                    </Block>
                                    <Block title="Телефон">
                                        {this.state.implementer.phone}
                                    </Block>
                                    <Block title="Email">
                                        {this.state.implementer.email}
                                    </Block>
                                </div>
                            ) : null}
                        </TabBlock>
                        <TabBlock>
                            <Block size="xl">
                                <TableContainer>
                                    <div className="Table-title">Список субподрядных организаций</div>
                                    <div className="Table">
                                        <table className="b-table b-table-no-hover">
                                            <thead>
                                            <tr className="b-table__header">
                                                <th>Подрядчик</th>
                                                <th>Телефон</th>
                                                <th>Руководитель</th>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.renderSubcontractors()}
                                            </tbody>
                                        </table>
                                    </div>
                                    <span className="add-line"
                                          onClick={::this.addSubcontractor}>Добавить организацию</span>
                                </TableContainer>
                            </Block>
                        </TabBlock>
                        {this.state.implementerActive ? (
                            <UnitsEditor
                                mode="edit"
                                uuid={this.state.implementerActive}
                                onSubmit={::this.hideImplementer}
                                onClose={::this.hideImplementer}
                                params={{
                                    component: 'commdept',
                                }}
                            />
                        ) : null}
                    </TabItem>
                    {((this.props.mode === 'edit') && this.isRepair()) ? (
                        <TabItem key="works" title={`Работы по ${window.RNIS_SETTINGS.rename_contracts ? 'подрядному обязательству' : 'контракту'}`}>
                            <CommdeptContractWorksComponent
                                contractUuid={this.state.contract.uuid}
                                type="works"
                            />
                        </TabItem>
                    ) : null}
                    {((this.props.mode === 'edit') && this.isMaintenance()) ? (
                        <TabItem key="graphic" title={`График работ по ${window.RNIS_SETTINGS.rename_contracts ? 'подрядному обязательству' : 'контракту'}`}>
                            {this.renderGraphic()}
                        </TabItem>
                    ) : null}
                    {((this.props.mode === 'edit') && this.isMaintenance()) ? (
                        <TabItem key="maintenance" title="Объекты по содержанию">
                            <CommdeptContractWorksComponent
                                contractUuid={this.state.contract.uuid}
                                type="maintenance"
                            />
                        </TabItem>
                    ) : null}
                </Tabs>
            </div>
        );
    }

    selectSto(value) {
        const sto = _.find(this.state.stos, {value});
        const {works} = sto;

        let contractWorks = this.get('works', []);
        _.each(works, (work) => {
            let graphicByMaintenance = {};
            _.each(work.data, (item) => {
                graphicByMaintenance[item.maintenance_group_uuid] = {
                    contract_series_count: item.count,
                };
            });
            contractWorks.push({
                key: 'work-' + moment().format(formats.DATETIME) + '-' + work.uuid,
                work_type_uuid: work.work_type_uuid,
                season: _.find(this.state.work_types, {value: work.work_type_uuid}).document.season,
                contract_series_count: _.sumBy(work.data, 'count'),
                graphic_by_maintenance: graphicByMaintenance,
            });
        });
        this.setValue('contract.works', contractWorks);
    }

    getStos() {
        const statusUuid = _.get(_.find(this.state.kurs_contract_statuses || [], {label: 'Открыт'}), 'value');
        return _.filter(this.state.stos, {
            status_uuid: statusUuid,
        });
    }

    renderGraphic() {
        const years = _.groupBy(this.getMonths(), 'year');

        return (
            <TabBlock>
                <div className="b-block _full">
                    <Block size="xl" title="Реестр СТО">
                        {this.select('sto', this.getStos())}
                    </Block>
                    <div className="Table KursContractWorksTable">
                        <TableContainer>
                            <table className="b-table b-table-no-hover">
                                <thead>
                                <tr className="b-table__header">
                                    <th rowSpan="2">Вид работ</th>
                                    <th rowSpan="2">Сезон</th>
                                    {_.map(years, (months, year) => <th key={year} colSpan={months.length}
                                                                        className="align-center">{year} год</th>)}
                                    <th rowSpan="2">Кол-во циклов<br/>по {window.RNIS_SETTINGS.rename_contracts ? 'подрядному обязательству' : 'контракту'}</th>
                                </tr>
                                <tr>
                                    {_.flatten(_.values(years)).map(month => <th key={month.date}>{month.label}</th>)}
                                </tr>
                                </thead>
                                <tbody>
                                {(this.state.contract.works || []).map(::this.renderWork)}
                                </tbody>
                            </table>
                            <span className="add-line" onClick={::this.addWork}>Добавить запись</span>
                        </TableContainer>
                        {this.state.detailsUuid ? (
                            this.renderDetails()
                        ) : null}
                    </div>
                </div>
            </TabBlock>
        );
    }

    renderDetails() {
        const years = _.groupBy(this.getMonths(), 'year');

        const workUuid = this.state.detailsUuid;
        const workIndex = _.findIndex(this.get('works', []), {uuid: workUuid});
        const work = this.get(`works.${workIndex}`);
        if (!work) {
            return null;
        }

        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.hideDetails}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{title: 'Распределение циклов работ по содержанию', buttons}}
                onClose={::this.hideDetails}
                className={`profile-modal`}
            >
                <div className="Table KursContractWorksTable">
                    <TableContainer>
                        <table className="b-table b-table-no-hover">
                            <thead>
                            <tr className="b-table__header">
                                <th rowSpan="2">Группы по содержанию</th>
                                {_.map(years, (months, year) => <th key={year} colSpan={months.length}
                                                                    className="align-center">{year} год</th>)}
                                <th rowSpan="2">Кол-во циклов<br/>по {window.RNIS_SETTINGS.rename_contracts ? 'подрядному обязательству' : 'контракту'}</th>
                            </tr>
                            <tr>
                                {_.flatten(_.values(years)).map(month => <th key={month.date}>{month.label}</th>)}
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.maintenance_groups.map(this.renderMaintenanceGroup.bind(this, workIndex))}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </PageModal>
        );
    }

    renderMaintenanceGroup(workIndex, maintenanceGroup) {
        const months = this.getMonths();

        return (
            <tr key={maintenanceGroup.value}>
                <td>
                    {maintenanceGroup.label}
                </td>
                {months.map((month, index2) => {
                    return (
                        <td key={index2}>
                            {this.textInput(`contract.works.${workIndex}.graphic_by_maintenance.data.${maintenanceGroup.value}.${month.date}`)}
                        </td>
                    );
                })}
                <td>{this.textInput(`contract.works.${workIndex}.graphic_by_maintenance.${maintenanceGroup.value}.contract_series_count`)}</td>
            </tr>
        );
    }

    renderWork(work, index) {
        const months = this.getMonths();

        return (
            <tr key={work.key}>
                <td className="input-cell wide">
                    {this.select(`contract.works.${index}.work_type_uuid`, this.state.work_types, {
                        autosize: false,
                    })}
                    {work.uuid ? (
                        <a href="javascript:void(0)" onClick={this.showDetails.bind(this, work.uuid)}>Распределение
                            циклов работ по содержанию</a>
                    ) : null}
                </td>
                <td className="input-cell">
                    {this.select(`contract.works.${index}.season`, [
                        {
                            label: 'Лето',
                            value: 'summer',
                        },
                        {
                            label: 'Зима',
                            value: 'winter',
                        },
                    ])}
                </td>
                {months.map((month, index2) => {
                    return (
                        <td className="input-cell" key={index2}>
                            {this.textInput(`contract.works.${index}.graphic.${month.date}`)}
                        </td>
                    );
                })}
                <td className="input-cell">{this.textInput(`contract.works.${index}.contract_series_count`)}</td>
            </tr>
        );
    }

    showDetails(workUuid) {
        this.setState({
            detailsUuid: workUuid,
        });
    }

    hideDetails() {
        this.setState({
            detailsUuid: null,
        });
    }

    getMonths() {
        let result = [];
        let start = moment(this.state.contract.date_from).startOf('month');
        let end = moment(this.state.contract.date_to).endOf('month');
        while (start.isBefore(end)) {
            result.push({
                label: months[start.month()],
                year: start.year(),
                date: start.format(formats.DATE_URL),
            });

            start = start.add(1, 'month');
        }

        return result;
    }

    gotoImplementer() {
        this.setState({
            implementerActive: this.get('implementer_uuid'),
        });
    }

    hideImplementer() {
        this.setState({
            implementerActive: null,
        });
    }

    deleteFile(e) {
        e.preventDefault();

        this.setValue('contract.file', null);
    }

    async uploadFile(e, results) {
        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        results.forEach(result => {
            const [e, file] = result;
            //formData.append('file', e.target.result);
            formData.append('file', file);
        });

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');

        this.onChangeInput('contract.file', {target: {value}});
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }

    renderSubcontractors() {
        const list = this.state.contract.subcontractors || [];

        return list.map(::this.renderSubcontractorItem);
    }

    addSubcontractor(e) {
        e.preventDefault();

        let contract = this.state.contract;
        contract.subcontractors = contract.subcontractors || [];
        contract.subcontractors.push(null);

        this.setState({contract});
    }

    deleteSubcontractor(index, e) {
        e.preventDefault();

        let contract = this.state.contract;
        contract.subcontractors.splice(index, 1);
        this.setState({contract});
    }

    renderSubcontractorItem(subcontractor, index) {
        return (
            <tr key={index}>
                <td className="input-cell expand-top">
                    {this.select(`contract.subcontractors.${index}`, this.state.units)}
                </td>
                <td>
                    {_.get(this.state.unitsFull[this.get(`subcontractors.${index}`)], 'phone')}
                </td>
                <td>
                    {_.get(this.state.unitsFull[this.get(`subcontractors.${index}`)], 'senior')}
                </td>
                <td>
                    <a href="javascript:void(0)" onClick={this.deleteSubcontractor.bind(this, index)}>Удалить</a>
                </td>
            </tr>
        );
    }

    async setValue(field, value) {
        if (field === 'sto') {
            this.selectSto(value);
            return;
        }
        const prevState = _.cloneDeep(this.state.contract);

        this.props.onUpdate();
        await super.setValue(field, value);

        const matches2 = /^contract\.works\.([0-9]+)\.(graphic|contract_series_count)/.exec(field);
        if (matches2) {
            const index = matches2[1];
            const works = this.get(`works.${index}`);
            if (_.sum(_.map(_.values(works.graphic), item => _.toInteger(item))) > works.contract_series_count) {
                this.setState({
                    contract: prevState,
                });
                return;
            }
        }

        if (field === 'contract.implementer_uuid') {
            this.loadImplementer();
        }
        if (field === 'contract.is_economic_method') {
            this.setValue('contract.number', null);
            this.setValue('contract.signed_at', null);
        }
        const matches = /^contract\.works\.([0-9]+)\.work_type_uuid$/.exec(field);
        if (matches) {
            if (value === null) {
                const index = matches[1];
                let contract = this.state.contract;
                contract.works.splice(index, 1);
                this.setState({contract});
            } else {
                const workType = _.find(this.state.work_types, {value});
                if (workType) {
                    this.setValue(field.replace('work_type_uuid', 'season'), workType.document.season);
                }
            }
        }

        if (field === 'contract.implementer_uuid') {
            this.loadStos();
        }
    }

    isMaintenance() {
        const key = _.get(_.find(this.state.work_categories, {value: this.get('work_category_uuid')}), 'document.key');

        return key === 'maintenance';
    }

    isRepair() {
        const key = _.get(_.find(this.state.work_categories, {value: this.get('work_category_uuid')}), 'document.key');

        return key === 'repair';
    }

    addWork() {
        let works = this.get('works', []);
        works.push({
            key: 'work-' + moment().format(formats.DATETIME),
        });
        this.setValue('contract.works', works);
    }
}
