import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getRouteDeviations = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.geo.getRouteDeviations(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createRouteDeviation = (data) => async (dispatch) => {
    try {
        const response = await api.geo.createRouteDeviation(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};