import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import MapPolyline from "components/ui/map/base/polyline";
import MapGeojson from "components/ui/map/base/geojson";

@propTypes({})

@defaultProps({})

export default class DirectionalGeojson extends MapGeojson {

    getOptions() {
        return Object.assign(super.getOptions(), {
            onEachFeature: (feature, layer) => {
                layer.setText && layer.setText('\u25B6          ', {
                    repeat: true,
                    offset: 5,
                    attributes: {
                        fill: this.getColor(),
                        'font-size': '12',
                    },
                });
            }
        });
    }

    componentWillUpdate(props) {
        super.componentWillUpdate(props);

        if (props.color !== this.props.color) {
            this.getLayer().eachLayer((layer) => {
                layer.setText && layer.setText('\u25B6          ', {
                    repeat: true,
                    offset: 5,
                    attributes: {
                        fill: this.getColor(props),
                        'font-size': '12',
                    },
                });
            });
        }
    }
}