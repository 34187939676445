import React from 'react';
import {connect} from 'react-redux';

import systems from "dictionaries/systems";
import StaffingEditor from "components/modules/staffing/editor";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Select from "../../../ui/select";
import PageModal from 'components/ui/page-modal';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ContextTooltip from "components/ui/context-tooltip";
import Block from "components/ui/form/block";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import $ from "jquery"
import Column from "components/ui/column";
import {getRegisterEsiaRequests, updateRegisterEsiaRequest} from "store/reducers/portal/register_requests";

import "./index.less";
import moment from "moment";

@connect(state => ({}), {getRegisterEsiaRequests, updateRegisterEsiaRequest})

export default class EsiaRequestsComponent extends BaseTableWithEditorComponent {

    title = `${systems.operator} → Заявления из ESIA`;
    baseUrl = '/operator/esia_requests';

    statuses = {
        "new": "новая",
        "canceled":"отменена",
        "confirmed": "подтверждена",
        "rejected": "отклонена",
        "done": "исполнена",
    };

    constructor(props, context) {
        super(props, context);
        Object.assign(this.state, {
            staffEditorUuid: null,
            esiaRequestUuid: null,
            esiaRequestsList: null,
            showEditorModal: false,
            subsystems: null,
            isShowCreateUserModal: false,
            currentRequestInfo: null,
        });
    }

    componentDidMount() {
        let subsystems = [];
        for (const el in systems) {
            subsystems.push({
                value: el,
                label: systems[el]
            })
        }
        this.setState({
            subsystems
        })
        var _ = this;
        const listenerInverval = setInterval(() => {
                if ($("td").length && this.state.esiaRequestsList.length) {
                    $("td").on('click', (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if ($(e.target).hasClass('confirm') ) {
                            _.handleConfirm($(e.target).data('value'))
                        }
                        if ($(e.target).hasClass('reject') ) {
                            _.handleReject($(e.target).data('value'))
                        }
                    });
                    clearInterval(listenerInverval)
                }
        }, 3600);
    }

    async loadData(meta) {
        if (!meta.filters.status) {
            meta.filters.status = "confirmed";
        }
        meta.order.column = "status";
        const response = await this.props.getRegisterEsiaRequests(meta);
        const items = response.payload.items;
        if (response.isOk && this.state.esiaRequestsList === null) {
            await this.setState({
                esiaRequestsList: items
            })
        }
        return response;
    }

    getCreateButton() {
        return null;
    }

    renderHeaderActions() {
        return null;
    }

    closeEditor = () => {
        this.setState({
            showEditorModal: false
        })
    }

    onNext = () => {
        this.setState({
            isShowCreateUserModal: true
        })
    }

    onClose  = () => {
        this.setState({
            showEditorModal: false
        })
    }

    submitEditor = async (response) => {
        if (response.isOk) {
            const responseUpdate = await this.props.updateRegisterEsiaRequest({
                uuid: this.state.esiaRequestUuid,
                user_uuid: response.payload.uuid,
                status: "done"
            });
            if (responseUpdate.isOk) {
                this.reload();
                this.setState({
                    showEditorModal: false
                })
            } else {
                responseUpdate.showErrors();
            }
        }
    }

    getEditor() {
        if (this.state.isShowCreateUserModal) {
            return <StaffingEditor
                {...this.props}
                key="editor"
                onClose={this.closeEditor}
                onSubmit={this.submitEditor}
                onEditClick={() => null}
                onDeleteClick={() => null}
                mode="add"
                component={this.state.component}
                requestInfo={this.state.currentRequestInfo}
                uuid={this.state.staffEditorUuid}
                esiaRequestUuid={this.state.esiaRequestUuid}
            />;
        } else {
            let title = 'Выбор подсистемы';
            let buttons;
            buttons = (
                <ModalTopMenuButtons>
                    <ContextTooltip key="base-editor.save" code="base-editor.save" default="Далее">
                        <ModalTopMenuButton
                            className="_save"
                            title="Далее"
                            onClick={this.onNext}
                        />
                    </ContextTooltip>
                    <span className="b-modal__header-separator"></span>
                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отмена">
                        <ModalTopMenuButton
                            className="_save"
                            title="Отмена"
                            onClick={this.onClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            )
            return <PageModal
                header={{title, buttons}}
                onClose={() => null}
                className={`profile-modal b-modal-add`}
                withFade={this.props.mode !== 'view'}
            >
                <div className="StaffingEditorForm">
                    <Accordion>
                        <AccordionItem title="Подсистема" opened={true}>
                            <Block key="subsystems" className="expand-top">
                                <Select value={this.state.component}
                                        onChange={async (e) => {
                                            const value = e ? e.value : null;
                                            await this.setState({
                                                component: value,
                                            });
                                        }}
                                        clearable={false} options={this.state.subsystems}/>
                            </Block>
                        </AccordionItem>
                    </Accordion>
                </div>
            </PageModal>
        }
    }

    handleConfirm(uuid) {
        const currentRequestObject = this.state.esiaRequestsList.filter(el => el.uuid === uuid)[0];
        this.setState({
            currentRequestInfo: {
                firstName: currentRequestObject.service_payload.firstName,
                lastName: currentRequestObject.service_payload.lastName,
                middleName: currentRequestObject.service_payload.middleName,
                snils: currentRequestObject.service_payload.snils
            }
        })
        this.setState({
            showEditorModal: true,
            esiaRequestUuid: uuid
        })
    }

    async handleReject(uuid) {
        const currentRequestObject = this.state.esiaRequestsList.filter(el => el.uuid === uuid)[0];
        let payload = {
            uuid: uuid,
            status: 'rejected'
        }
        if (currentRequestObject.user_data && currentRequestObject.user_data.unit_name) {
            payload.unit_name = currentRequestObject.user_data.unit_name
        }
        if (currentRequestObject.user_data && currentRequestObject.user_data.unit_description) {
            payload.unit_description = currentRequestObject.user_data.unit_description
        }
        const response = await this.props.updateRegisterEsiaRequest(payload);
        if (response.isOk) {
            this.reload();
        }
    }

    getColumns() {
        return this.prepareColumns([
            new Column('Фамилия')
                .fromField('service_payload.lastName'),
            new Column('Имя')
                .fromField('service_payload.firstName'),
            new Column('Отчество')
                .fromField('service_payload.middleName')
                .withDrawer((item) => item.service_payload.middleName ? item.service_payload.middleName : 'Не получено из ESIA'),
            new Column('СНИЛС')
                .fromField('service_payload.snils'),
            new Column('Телефон')
                .fromField('telephone')
                .withDrawer((item) => item.service_payload.phone ? item.service_payload.phone : 'Не получен из ESIA'),
            new Column('E-mail')
                .fromField('email')
                .withDrawer((item) => item.service_payload.email ? item.service_payload.email : 'Не получен из ESIA'),
            new Column('Предприятие')
                .fromField('user_data.unit_name')
                .withDrawer((item) => item.user_data && item.user_data.unit_name ? item.user_data.unit_name : '-'),
            new Column('Направление деятельности')
                .fromField('user_data.unit_description')
                .withDrawer((item) => item.user_data && item.user_data.unit_description ? item.user_data.unit_description : '-'),
            new Column('Дата создания')
                .fromField('created_at')
                .withDrawer((item) => moment(item).format('DD.MM.YYYY')),
            new Column('Статус')
                .fromField('status')
                .withDrawer((item) => this.statuses[item.status])
                .withFilter('status', async () => {
                    return [{uuid: 'new', name: "новая"},
                        {uuid: 'canceled', name: "отменена"},
                        {uuid: 'confirmed', name: "подтверждена"},
                        {uuid: 'rejected', name: "отклонена"},
                        {uuid: 'done', name: "исполнена"}]
                }),
            new Column('')
                .withClassName('cl-button')
                .denyColumnFilter()
                .withDrawer((item) => `<button class="confirm ${item.status}" type="button" data-value=${item.uuid}>Создать учетную запись</button>`),
            new Column('')
                .withClassName('cl-button')
                .denyColumnFilter()
                .withDrawer((item) => `<button class="reject ${item.status}" type="button" data-value=${item.uuid}>Отклонить заявку</button>`)
                ])
    }
}
