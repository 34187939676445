import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import _ from 'lodash';
import {success, error} from 'helpers/response';
import {User} from "helpers/user";

const initialState = new Map({
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getBnsoList = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.vehicles.getBnsoList(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getBnso = (uuid) => async (dispatch) => {
    try {
        const response = await api.vehicles.getBnso(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createBnso = (data) => async (dispatch) => {
    try {
        const response = await api.vehicles.createBnso(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateBnso = (data) => async (dispatch) => {
    try {
        const response = await api.vehicles.updateBnso(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteBnso = (data) => async (dispatch) => {
    try {
        const response = await api.vehicles.deleteBnso(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getBnsoCheckLink = (bnsoUuid = null) => async (dispatch) => {
    try {
        const response = await api.vehicles.getBnsoCheckLink(bnsoUuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getBnsoPeriods = (data) => async (dispatch) => {
    try {
        const response = await api.vehicles.getBnsoPeriods(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const exportBNSOExcel = (meta, from, to) => async (dispatch) => {
    try {
        const response = await api.vehicles.exportBNSOExcel(meta, from, to);
        return response;
    }
    catch (e) {
        return error(e);
    }
};
