import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";
import {getUnits} from "store/reducers/organizational_units/units";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import FileReaderInput from 'react-file-reader-input';

import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import {api} from "helpers/api";
import {createVehicleWork} from "store/reducers/commdept/vehicle_works";
import TableContainer from "components/ui/Table/Container/TableContainer";
import KursDetailAddForm from "components/modules/commdept/vehicles/vehicle-editor-form/operation-add-form/detail-add-form/index";
import {createVehicleMechanism} from "store/reducers/commdept/vehicle_mechanisms";
import {getVehicles} from "store/reducers/commdept/vehicles";

@connect(state => ({}), {createVehicleMechanism})

export default class KursTrailerAddForm extends BaseEditor {

    modalClassName = 'b-modal-road-accounting edit-trailer-modal';

    getFullTitle() {
        return 'Добавление прицепа';
    }

    getButtons() {
        return [
            <div key="tooltip" className="b-modal__footer-txt">Вы хотите добавить прицеп?</div>,
            <a key="save" href="javascript:void(0)" className="b-button b-button_red b-button_size_md b-button_save"
               onClick={(this.props.mode === 'edit') ? ::this.onEdit : ::this.onCreate}>Добавить</a>
        ];
    }

    saveButton(onClick) {
        return null;
    }

    loadData(uuid) {
        return {
            isOk: true,
            data: {
                payload: null,
            },
        };
    }

    async createItem(data) {
        return await this.props.createVehicleMechanism(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    composeItem(data) {
        let item = _.cloneDeep(data);

        item.vehicle_uuid = this.props.vehicleUuid;

        return item;
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getUnits, getUsers, getDictionaryList, getVehicles}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {

    state = {
        kurs_vehicle_mechanism: {},
        kurs_vehicles: [],
        vehicle_types: [],
    };

    getData() {
        return this.state.kurs_vehicle_mechanism;
    }

    async componentDidMount() {
        await this.setState({
            kurs_vehicle_mechanism: this.props.data,
        });
        this.loadDictionaries([
            'vehicle_types',
        ]).then(() => {
            const trailerVehicleTypes = _.map(_.filter(this.state.vehicle_types, item => _.get(item, 'document.is_trailer')), 'value');

            this.loadVehicles(trailerVehicleTypes);
        });
    }

    async loadVehicles(trailerVehicleTypes) {
        const response = await this.props.getVehicles({
            filters: {
                withTypes: trailerVehicleTypes,
            },
        });

        if (response.isOk) {
            this.setState({
                kurs_vehicles: _.map(response.payload.items, item => ({
                    value: item.uuid,
                    label: item.state_number,
                    vehicle_type_uuid: item.vehicle_type_uuid,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.kurs_vehicle_mechanism, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem single={true} opened={true}>
                        <Block size="xl" title="Госномер">
                            {this.select('kurs_vehicle_mechanism.trailer_uuid', this.state.kurs_vehicles)}
                        </Block>
                        <Block size="xl" title="Тип ТС">
                            {_.get(_.find(this.state.vehicle_types, {value: _.get(_.find(this.state.kurs_vehicles, {value: this.get('trailer_uuid')}), 'vehicle_type_uuid')}), 'label')}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

}
