import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import DashboardTable from 'components/modules/analytics/base-components/DashboardTable';
import './style.less';

@propTypes({

})

@defaultProps({

})

export default class VehicleDataDateWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [
                {name: 'Отдел 1', vehicleCount: 10, driverCount: 11},
                {name: 'Отдел 2', vehicleCount: 5, driverCount: 6},
                {name: 'Отдел 3', vehicleCount: 8, driverCount: 8},
                {name: 'Отдел 4', vehicleCount: 3, driverCount: 0},
                {name: 'Отдел 5', vehicleCount: 1, driverCount: 2},
            ]
        };

        this.metadata = {
            0: {field: 'name', name: 'Отделы', sort: true},
            1: {field: 'vehicleCount', name: 'Кол-во тс ', sort: true},
            2: {field: 'driverCount', name: 'Кол-во водителей', sort: true}
        };
    }

    render() {
        return (
            <div className="vehicle-data-date-widget">
                <DashboardTable data={this.state.data} metadata={this.metadata}/>
            </div>
        );
    }
}