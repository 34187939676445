import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';
import classNames from 'classnames';

import RouteCreator from 'components/modules/maps/RouteCreator/RouteCreator';
import RulerPopup from 'components/modules/maps/elements/popup/Ruler';

@propTypes({
    map: PropTypes.object,
})

export default class Ruler extends Component {

    toggleRuler = () => {
        this.props.onToggle('ruler');
    }

    render() {
        const {isActive} = this.props;
        return (
            <div className="route-block ruler-button">
                <div className="route-block__item">
                    <div className={classNames('route-block__btn route-block__btn_area', {
                        'route-block__btn_white': !isActive,
                        'route-block__btn_red': isActive,
                    })} onClick={this.toggleRuler}>
                        <i className={classNames('rnis-icon', {
                            'rnis-icon_ruler': !isActive,
                            'rnis-icon_close_white': isActive,
                        })}/>
                    </div>
                </div>
                <RouteCreator
                    isActive={isActive}
                    map={this.props.map}
                    popup={RulerPopup}
                />
            </div>
        );
    }
}