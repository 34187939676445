import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import classNames from 'classnames';
import Block from "components/ui/form/block";
import Select from "components/ui/select";
import systems from "dictionaries/systems";
import AccordionItem from "components/ui/accordion/accordion-item";
import Accordion from "components/ui/accordion/accordion";
import Checkbox from "components/ui/form/checkbox";

@propTypes({
    isActive: PropTypes.bool,
})

export default class MapFilterExtendedTabBase extends Component {

    getModificators() {
        return {};
    }

    setValue(path, value) {
        this.props.onFiltersChange(path, value);
    }

    onChange(path, value) {
        if (value && value.target) {
            value = value.target.value;
        }
        this.setValue(path, value);
    }

    onCheckboxChange(path, {target: {value}}) {
        this.setValue(path, value);
    }

    onSelectChange(path, e) {
        const value = e ? e.value : null;

        this.setValue(path, value);
    }

    onSelectMultiChange(path, e) {
        const value = e ? _.map(e, 'value') : null;

        this.setValue(path, value);
    }

    render() {
        return (
            <div className={classNames({
                'b-modal__tab-content': true,
                'selected': this.props.isActive,
            })}>
                {this.renderContent()}

                {this.renderModificators()}
            </div>
        )
    }

    renderModificators() {
        const modificators = this.getModificators();

        if (modificators.length === 0) {
            return;
        }

        return (
            <Accordion>
                <AccordionItem title="Модификаторы">
                    <Block size="xl">
                        {_.map(modificators, ::this.renderModificator)}
                    </Block>
                </AccordionItem>
            </Accordion>
        )
    }

    renderModificator(value, label) {
        return (
            <Checkbox
                key={value}
                checked={_.get(this.props.filters, value)}
                label={label}
                onChange={this.onCheckboxChange.bind(this, value)}
            />
        );
    }
}