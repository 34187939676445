import React from 'react';
import './index.less';
import moment from 'moment';
import formats from 'dictionaries/formats';
import _ from 'lodash';
import { Link } from 'react-router';
import currentUser from '../../../../../../helpers/current-user';

export default function Shift({ shift, order, onEdit, params }) {
	const vehicles = {};
	shift.runs.forEach(({ vehicle_uuid }) => {
		vehicles[vehicle_uuid] = true;
	});
	return (
		<div className="Shift">
			<div className="Shift-item">
				{`Смена ${shift.shift} ${moment(shift.start_at)
					.add(currentUser.user.is_timezoneoffset, 'hours')
					.format(formats.TIME)} - ${moment(shift.end_at)
					.add(currentUser.user.is_timezoneoffset, 'hours')
					.format(formats.TIME)}`}
				{onEdit ? <a className="edit" href="javascript:void(0)" onClick={onEdit} /> : null}
			</div>

			{_.map(_.filter(_.uniq(_.map(shift.runs, 'route_number'))), (routeNumber) => {
				const run = _.find(shift.runs, { route_number: routeNumber });
				if (!run) {
					return null;
				}
				const runs = _.filter(
					_.filter(shift.runs, { route_number: routeNumber }),
					(run) => run.type === 'production_forward' || run.type === 'production_reverse'
				).length;

				return (
					<div key={run.route_uuid} className="Shift-route clearAfter">
						<span className="Shift-route-name">
							{run.route_number}: {run.route_name}
							<Link className="Shift-route-link" to={`/${params.component}/routes/${run.route_uuid}`}>
								Паспорт маршрута
							</Link>
							<a href={`/${params.component}/map/order/${order.uuid}?route=${run.route_uuid}`} target="_blank">
								Маршрут на карте
							</a>
							<Link
								className="Shift-route-link"
								to={`/${params.component}/map/order/${order.uuid}?route=${run.route_uuid}&vehicles=${Object.keys(
									vehicles
								).join(',')}&from_time=${shift.start_at}&to_time=${shift.end_at}`}
							>
								Мониторинг
							</Link>
						</span>
						<span className="Shift-route-runs">Рейсов: {runs}</span>
					</div>
				);
			})}
		</div>
	);
}

//http://localhost:3000/kiutr/map/order/00323dda-b199-11eb-a6b3-02f42b2fbbdb?route=2a644bdc-493e-11e8-b315-02f42b313e6a&vehicles=5eb58034-28f2-11e8-abb2-02f42b8bc000
