import React from 'react';
import {connect} from 'react-redux';


import _ from 'lodash';
import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {EntityList} from "helpers/entity";
import ReactDOMServer from 'react-dom/server';
import {
    deleteMailing,
    getEventTypes, getMailings, getNotifications, notificationEventCreated, notificationEventRead,
    readNotifications
} from "store/reducers/notifications";
import {getEntityNames} from "store/reducers/system";
import {State} from "components/ui/state";
import moment from "moment";
import formats from "dictionaries/formats";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import {GlobalEvent} from "helpers/event-system";
import {getUnits} from "store/reducers/organizational_units/units";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import notifications from "dictionaries/notifications";
import Datepicker from "components/ui/form/datepicker";

let subscribed = false;

@connect(state => ({}), {
    getMailings,
    getEntityNames,
    getUnits,
    deleteMailing,
})

export default class NotificationsMailing extends BaseTableWithEditorComponent {

    title = `Настройки → Шаблоны рассылки сообщений`;

    getBaseUrl() {
        return '/notifications/mailing';
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Наименование')
                .fromField('name'),

            new Column('Предприятие')
                .fromField('unit_uuid')
                .denyColumnFilter()
                .withDrawer(item => this.state.related.get(item.unit_uuid))
                .withFilter('withUnits', async () => {
                    const response = await this.props.getUnits();
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            new Column('Важность')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => _.get(item, 'notification_level') && notifications.levels[_.get(item, 'notification_level')]),

            new Column('Доставка')
                .fromField('via')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => _.keys(_.pickBy(item.via, (item) => !!item)).join('<br/>')),

            new Column('Последняя рассылка')
                .fromField('created_at')
                .withDateFilter()
                .withDrawer(item => item.last_send_at ? moment(item.last_send_at).format(formats.DATETIME) : '-'),

            new Column('Получателей')
                .fromField('last_send_count'),

        ]);
    }

    async loadData(meta) {
        return await this.props.getMailings(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const units = _.map(_.filter(_.flatten(_.map(result, 'unit_uuid'))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));
        const response = await this.props.getEntityNames(units);

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    async deleteItem(data) {
        return await this.props.deleteMailing(data);
    }
}