import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import './events-modal.less';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import PageModal from 'components/ui/page-modal';
import moment from "moment";
import formats from "dictionaries/formats";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {connect} from "react-redux";
import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import Button from "components/ui/button";
import events from "dictionaries/events";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getEventRules} from "store/reducers/notifications";
import currentUser from 'helpers/current-user';
import * as alerts from "helpers/alerts";
import FilterHeader from "components/ui/filter-header";
import etp_events from "dictionaries/etp_events";

@propTypes({
    vehicles: PropTypes.array,
    events: PropTypes.array,
    component: PropTypes.string,
    gatn_responsives: PropTypes.object,
    onSubmit: PropTypes.func,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
})

@connect(state => ({}), {})

export default class EventsModal extends Component {

    state = {};

    componentDidMount() {
    }

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{title: 'Поиск событий', buttons}}
                onClose={this.props.onClose}
                className={`profile-modal b-modal-edit EventsModal`}
                withFade={false}
            >
                <EditorForm
                    {...this.props}
                    ref="form"
                    mode="add"
                    onSubmit={this.props.onSubmit}
                    onClose={::this.props.onClose}
                    data={{
                        from_date: moment().startOf('day'),
                        from_time: moment().startOf('day').format(formats.TIME),
                        to_date: moment().endOf('day'),
                        to_time: moment().endOf('day').format(formats.TIME),
                        types: [],
                    }}
                />
            </PageModal>
        )
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    contract: PropTypes.object,
    isRepair: PropTypes.bool,
})

@connect((state) => ({}), {getEventRules}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        item: {},
        event_rules: [],
    };

    getData() {
        return this.state.item;
    }

    async componentDidMount() {
        await this.setState({
            item: this.props.data,
        });
        this.loadEventRules();
    }

    async loadEventRules() {
        const response = await this.props.getEventRules({
            filters: {
                withUser: currentUser.uuid(),
                withComponent: this.props.component,
            },
        });

        if (response.isOk) {
            const eventRules = _.map(_.filter(response.payload.items, (item) => {
                const telematicsTypes = [
                    'over_speed_absolute',
                    'bnso_connection_lost',
                    'bnso_connection_lost_at_work',
                    'geozone_out',
                    'geozone_in',
                    'geozone_in_out',
                    'highway_in',
                    'alarm',
                    'over_speed_relative',
                ];

                return _.indexOf(telematicsTypes, item.type) !== -1;
            }), (eventRule) => ({
                value: eventRule.uuid,
                label: eventRule.name,
            }));
            if (eventRules.length === 0) {
                alerts.alert('Нет настроенных событий в оповещениях');
            }
            this.setState({
                event_rules: eventRules,
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.item, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} title="Поиск">
                        <div className="interval">
                            <ul className="interval__list">
                                <li className="interval__item">
                                    <a href="javascript:void(0)" className="interval__link" onClick={::this.setToday}>cегодня</a>
                                </li>
                                <li className="interval__item">
                                    <a href="javascript:void(0)" className="interval__link"
                                       onClick={::this.setYesterday}>вчера</a>
                                </li>
                                <li className="interval__item">
                                    <a href="javascript:void(0)" className="interval__link" onClick={::this.setWeek}>за
                                        неделю</a>
                                </li>
                                <li className="interval__item">
                                    <a href="javascript:void(0)" className="interval__link" onClick={::this.setMonth}>за
                                        месяц</a>
                                </li>
                            </ul>
                        </div>
                        <Block size="md" title="Начальная дата">
                            {this.datepicker('item.from_date', {
                                className: 'centered',
                            })}
                        </Block>
                        <Block size="sm" title="Начальное время">
                            {this.renderInputMask('item.from_time', '', '99:99', {
                                withTimeIcon: true
                            })}
                        </Block>
                        <Block size="md" title="Конечная дата">
                            {this.datepicker('item.to_date', {
                                className: 'centered',
                            })}
                        </Block>
                        <Block size="sm" title="Конечное время">
                            {this.renderInputMask('item.to_time', '', '99:99', {
                                withTimeIcon: true
                            })}
                        </Block>
                        <FilterHeader key="filter_header"
                                      items={['Триггеры', 'Вождение']}
                                      currentItem={this.state.currentFilterItem || 0}
                                      onChange={(e) => {
                                          this.setState({currentFilterItem: e.value});
                                      }}
                        />
                        {((this.state.currentFilterItem || 0) === 0) ? (
                            <Block size="xl" title="События">
                                {this.select('item.types', this.state.event_rules, {
                                    multi: true,
                                })}
                            </Block>
                        ) : (
                            <Block size="xl" title="События по стилю вождения">
                                {this.select('item.drive_events', _.map(etp_events, (value, label) => ({
                                    value,
                                    label
                                })), {
                                    multi: true,
                                })}
                            </Block>
                        )}
                        <Block size="xl">
                            <Button size="md" color="red" shadow="red" className="b-button_confirm" text="Применить"
                                    onClick={::this.apply}/>
                            <Button size="md" text="Очистить" color="white" shadow="gray" className="b-button_clear"
                                    onClick={::this.reset}/>
                        </Block>
                    </AccordionItem>
                </Accordion>
                <div className="ts-list">
                    {this.props.events.map(::this.renderEventItem)}
                </div>
            </div>
        );
    }

    apply() {
        this.props.onSubmit(this.getData(), ((this.state.currentFilterItem || 0) === 0) ? 'notifications' : 'etp');
    }

    reset() {
        this.setToday();
        this.setValue('item.types', []);
    }

    setTime(from, to) {
        this.setValue('item.from_date', from);
        this.setValue('item.from_time', from.format(formats.TIME));
        this.setValue('item.to_date', to);
        this.setValue('item.to_time', to.format(formats.TIME));
    }

    setToday() {
        this.setTime(moment().startOf('day'), moment().endOf('day'));
    }

    setYesterday() {
        this.setTime(moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day'));
    }

    setWeek() {
        this.setTime(moment().startOf('isoWeek'), moment().endOf('isoWeek'));
    }

    setMonth() {
        this.setTime(moment().startOf('month'), moment().endOf('month'));
    }

    renderEventItem(event) {
        return (
            <div key={event.uuid} className="b-modal__block">
                <div className="ts">
                    <div
                        className="ts__datetime">{moment(event.created_at).format(formats.DATE)}<br/>{moment(event.created_at).format(formats.TIME)}
                    </div>
                    <div className="ts__button">
                        <a href="javascript:void(0)" className="ts__link"
                           onClick={this.props.onClick.bind(this, event)}/>
                    </div>
                    <div className="ts__icon">
                        <span className="icon-ts icon-ts_type_school"/>
                    </div>
                    <div className="ts__info">
                        <div className="ts__number">{_.get(event.vehicle, 'state_number')}</div>
                    </div>
                </div>
            </div>
        );
    }
}