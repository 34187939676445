import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import _ from 'lodash';
import moment from 'moment';
import debounce from 'throttle-debounce/debounce';

import { connect } from 'react-redux';

import { getUnits } from 'store/reducers/organizational_units/organizational_units';
import MapFilterBase from 'components/modules/maps/Filter/MapFilterBase';
import { getRoutes } from 'store/reducers/routes/routes';
import { getVehicleList, setVehicleList } from 'store/reducers/vehicles/vehicles';
import { getDictionaryList } from 'store/reducers/dictionaries/dictionary';
import { getOrderVehicles } from 'store/reducers/kiutr/orders/orders';
import { getOperatorComponentsSelector } from '../../../../store/reducers/maps/selectors';
import { getPagination } from '../utils';
import formats from 'dictionaries/formats';
import { getOrders } from 'store/reducers/kiutr/orders/orders';

@propTypes({
    component: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.instanceOf(null)]),
    owners: PropTypes.array,
    types: PropTypes.array,
    routes: PropTypes.array,
    onVehiclesLoad: PropTypes.func,
    onForceNextCycle: PropTypes.func,
})
@connect(
    (state) => ({
        operatorComponents: getOperatorComponentsSelector(state),
        vehicles: state.vehicles.vehicles
    }),
    {
        getVehicleList,
        setVehicleList,
        getOrderVehicles,
        getDictionaryList,
        getOrders
    }
)
export default class MapFilterVehicle extends MapFilterBase {
    tabs = [
        'По номеру',
        'По типу'
    ];
    icon = 'ts';
    contextKey = 'map.filters.base.vehicle';
    contextDefault = 'ТС';

    vehiclesDebounce = debounce(500, ::this.handleLoadVehicles);

    // componentDidUpdate(prevProps, prevState) {
    //     if (!_.isEqual(this.props.selected, prevProps.selected)) {
    //         console.log("🚀 ~ file: MapFilterVehicle.js ~ line 57 ~ MapFilterVehicle ~ componentDidUpdate ~ this.props.selected[0]", this.state)

    //         this.props.onForceNextCycle(this.props.selected[0], this.state.isDeselectAll);
    //     }
    // }

    // NOTE: сбиваем сохранённые итемы, если изменились сторонние фильтры
    componentDidUpdate(prevProps, ...args) {
        if (this.props.operatorComponents !== prevProps.operatorComponents) {
            const newItems = Object.assign({}, this.state.items);
            this.tabs.forEach((title, index) => {
                _.set(newItems, index, []);
            });

            this.setState({
                items: newItems,
            });
        }

        if (prevProps.vehicles.vehicles !== this.props.vehicles.vehicles) {
            const newItems = Object.assign({}, this.state.items);
            this.tabs.forEach((title, index) => {
                if (title === 'По номеру') {
                    if (this.props.vehicles.vehicles) {
                        _.set(newItems, index, this.props.vehicles.vehicles.map((item) => {
                            return {
                                value: item.uuid || item.value,
                                label: item.state_number
                            }
                        }));
                    } else {
                        _.set(newItems, index, this.props.vehicles.map(item => {
                            return {
                                value: item.uuid || item.value,
                                label: item.state_number
                            }
                        }));
                    }
                }
            });

            this.setState({
                items: newItems,
            });
        }
        if (this.state.items[1] === undefined) {
            (async () => {
                const newItems = Object.assign({}, this.state.items);
                newItems[1] = await this.loadVehicleTypes()
                this.setState({
                    items: newItems,
                });
            })()
        }
    }

    async loadData(tab = null, search = null) {
        switch (tab) {
            case 0:
                return await this.loadVehicles(search);
            case 1:
                return await this.loadVehicleTypes();
        }
    }

    async handleLoadVehicles(search) {
        if (!this.props.component || (search === null)) {
            return [];
        }
        super.setLoading(true);
        let filters = {
            short: true,
            onlyApproved: true,
            withActiveBnso: true
        };
        if (this.props.component !== 'operator') {
            filters.withComponent = this.props.component;
        } else {
            filters.withComponent = this.props.operatorComponents;
        }

        if (this.props.owners.length > 0) {
            filters.withUnit = this.props.owners;
        } else if (this.props.units.length > 0) {
            filters.withUnit = this.props.units;
        }
        if (this.props.types.length > 0) {
            filters.withVehicleType = this.props.types;
        }
        if (this.props.routes.length > 0) {
            filters.withUuid = await this.getVehicleUuidsByRoutes();
        }

        let meta = {
            pagination: getPagination(1, 15000),
            response_data: [
                'items/uuid',
                'items/garage_number',
                'items/state_number',
                'items/current_bnso',
            ],
            debugProperty: 'For Vehicle List Selector Request',
            filters,
        }

        if (meta.filters && Object.keys(meta.filters).length) {
            if (!meta.filters.withComponent.length || meta.filters.withComponent.includes(null)) {
                delete meta.filters.withComponent;
            }
        }
        if (this.state.search[0] && this.state.search[0].length > 0) {
            if (search && search.length > 0) {
                meta.pagination.limit = 50
                meta.search = search
            }
        }

        let result = [];
        const emptySearch = search && search.length ? false : true;
        if (localStorage.getItem('cacheVehicle') && emptySearch) {
            const cacheFilters = JSON.parse(localStorage.getItem('cacheVehicle')).filters
            if (_.isEqual(cacheFilters, filters)) {
                const {items, deleteAfter} = JSON.parse(localStorage.getItem('cacheVehicle'));
                if (new moment(deleteAfter).isBefore(new moment())) {
                    localStorage.removeItem('cacheVehicle')
                } else {
                    this.props.onVehiclesLoad && this.props.onVehiclesLoad(items);
                    this.props.setVehicleList(items)
                    super.setLoading(false);
                    return items
                }
            }
        }

        let response;

        if (this.state.units.length) {
            const responseUnits = await this.props.getOrders({
                filters: {
                    withDate: moment().format(formats.DATE_API),
                    withUnits: this.state.units,
                },
                response_data: [
                    'items/route_uuid',
                ],
            });

            if (this.props.routes.length > 0) {
                filters.withUuid = await this.getVehicleUuidsByRoutes();
            } else {
                const vehiclesUuids = await this.props.getOrderVehicles({
                    items: responseUnits.payload.items.map(unit => unit.route_uuid)
                });

                filters.withUuid = vehiclesUuids.payload.items.map(item => item);
            }

            delete meta.filters.withUnit;

            response = await this.props.getVehicleList(meta);
        } else {
            response = await this.props.getVehicleList(meta);
        }

        if (response.isOk) {
            result = _.map(response.payload.items, (item, index) => {
                this.state.items[this.state.activeTab][index].search_label =
                    `${item.state_number}${item.garage_number ? (` (${item.garage_number})`) : ''}`;

                return {
                    value: item.uuid,
                    label: `${item.state_number}${item.garage_number ? (` (${item.garage_number})`) : ''}`,
                    state_number: item.state_number,
                    current_bnso: item.current_bnso,
                }
            });
            try {
                if (emptySearch) {
                    const {value, param} = window.RNIS_SETTINGS.cacheVehicle;
                    localStorage.setItem('cacheVehicle', JSON.stringify({
                        deleteAfter: new moment().add(value, param),
                        items: result,
                        filters,
                    }))
                }
            } catch (e) {
                console.error('Ошибка при сохранении кеша: ' + e);
            }
        } else {
            response.showErrors();
        }

        super.setLoading(false);
        this.props.onVehiclesLoad && this.props.onVehiclesLoad(_.map(result, 'value')); // set choosen auto types to filter (maps.js line 2102)
        return result;
    }

    async loadVehicles(search = null) {
        this.vehiclesDebounce(search);
    }

    async loadVehicleTypes() {
        if (!this.props.component) return []
        const response = await this.props.getDictionaryList('vehicle_types', {
            order: {
                column: 'name',
                direction: 'asc',
            },
            filters: {
                withComponent: this.props.component,
            },
        });
        if (response.isOk) {
            return _.map(response.payload.documents, (item) => ({
                value: item.uuid,
                label: item.name,
            }));
        } else {
            response.showErrors();
            return [];
        }
    }

    tabs = ['По номеру', 'По типу'];
    icon = 'ts';
    contextKey = 'map.filters.base.vehicle';
    contextDefault = 'ТС';

    componentWillUpdate(props, state) {
        if (
            !_.isEqual(props.units, state.units) ||
            !_.isEqual(props.owners, state.owners) ||
            !_.isEqual(props.types, state.types) ||
            !_.isEqual(props.routes, state.routes) ||
            state.component !== props.component
        ) {
            this.setState({
                component: props.component,
                units: _.cloneDeep(props.units),
                owners: _.cloneDeep(props.owners),
                types: _.cloneDeep(props.types),
                routes: _.cloneDeep(props.routes)
            });
            this.reloadFull();
        }
    }

    async getVehicleUuidsByRoutes() {
        const response = await this.props.getOrderVehicles({
            items: this.props.routes
        });
        if (response.isOk) {
            return response.payload.items;
        } else {
            response.showErrors();
        }

        return [];
    }
}
