import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapMarker from "components/ui/map/base/marker";
import PointIcon from "components/ui/map/markers/icons/point-icon";
import _ from 'lodash';

@propTypes({
    title: PropTypes.string,
    permanentTooltip: PropTypes.bool,
})

@defaultProps({
    title: '',
    permanentTooltip: false,
})

export default class HistoryPointMarker extends AbstractMapMarker {

    getOptions() {
        return _.merge(super.getOptions(), {
            icon: PointIcon.getIcon(this.props.color),
            riseOnHover: true,
        });
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.props.title) {
            this.getMarker().bindTooltip(this.props.title, {
                sticky: true,
                permanent: this.props.permanentToolTip,
            });
        }
    }

    componentWillUpdate(props) {
        const marker = this.getMarker();
        const oldTitle = this.props.title;
        const newTitle = props.title;
        if (((oldTitle !== newTitle) || !marker.getTooltip())) {
            marker.unbindTooltip();
            if (newTitle) {
                marker.bindTooltip(newTitle, {
                    sticky: true,
                    permanent: this.props.permanentToolTip,
                });
            }
        }
    }

}