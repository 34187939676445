import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import DashboardTable from 'components/modules/analytics/base-components/DashboardTable';
import WidgetSelector from 'components/modules/analytics/base-components/WidgetSelector';
import GlobalLoaderComponent from "components/ui/global-loader";
import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import _ from 'lodash';
import './style.less';
import {connect} from "react-redux";
import {User} from "helpers/user";
import * as storage from "utils/storage";
import moment from "moment";
import {getTabletKiutrSummary, getTabletSummary} from "store/reducers/reports/tablet";
import Datepicker from "components/ui/form/datepicker";
import {
    Bar, BarChart, Label, LabelList, Pie, PieChart, ResponsiveContainer, Text, Tooltip, XAxis,
    YAxis
} from "recharts";
import Select from "components/ui/select";
import formats from "dictionaries/formats";

@propTypes({
    barChartFillColor: PropTypes.string,
    component: PropTypes.string,
})

@defaultProps({
    barChartFillColor: '',
    component: 'kiutr',
})

@connect(state => ({}), {getTabletKiutrSummary})

export default class KursRegularityWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isError: false,
            isLoading: false,
            from: moment().subtract(1, 'day').format(formats.DATE_API),
            to: moment().subtract(1, 'day').format(formats.DATE_API),
            regularity: 'best',
            data: {},
        };
    }

    componentWillMount() {
        this.loadData();
    }

    async componentWillReceiveProps(nextProps) {
        await this.setState({
            municipality: nextProps.municipality
        })
        this.loadData();
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value});
        this.loadData();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value});
        this.loadData();
    };

    async loadData() {
        this.setState({isLoading: true});

        let summaryOptions = {
            date_from: moment(this.state.from).format(formats.DATE_API),
            date_to: moment(this.state.to).format(formats.DATE_API),
            component: this.props.component,
            with_communal_municipalities: this.props.municipality
        };

        if (!window.RNIS_SETTINGS.analytic_filter_for_municipality) {
            delete summaryOptions.with_communal_municipalities
        }
        const response = await this.props.getTabletKiutrSummary(summaryOptions);
        this.setState({isLoading: false});

        if (response.isOk) {
            this.setState({
                data: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    get(field, forDisplay = true) {
        const value = _.get(this.state.data, field, null);
        if (forDisplay) {
            if (value !== null) {
                return _.round(value, 2);
            } else {
                return '-';
            }
        }
        return value || 0;
    }

    renderContent() {
        return (
            <div className="full-size kiutr-widget">
                <div className="date-wrapper">
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange}/>
                    </div>
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange}/>
                    </div>
                </div>
                <div className="widgets__selector">
                    <Select value={this.state.regularity}
                            onChange={this.onChange.bind(this, 'regularity')}
                            clearable={false} options={[
                        {
                            value: 'best',
                            label: 'Топ лучших',
                        },
                        {
                            value: 'worst',
                            label: 'Топ худших',
                        },
                    ]}/>
                </div>
                <div className="chart">
                    {this.renderRegularityChart()}
                </div>
            </div>
        );
    }

    renderRegularityChart() {
        let rows;
        if (this.state.regularity === 'best') {
            rows = _.slice(_.orderBy(this.state.data.units || [], ['regularity'], ['desc']), 0, 10);
        } else {
            rows = _.slice(_.orderBy(this.state.data.units || [], ['regularity'], ['asc']), 0, 10);
        }

        const data = _.map(rows, (row) => {
            return {
                name: row.unit_name,
                value: row.regularity,
                label: row.regularity ? `${row.regularity}` : '',
                fill: this.getFill(row.regularity, 'regularity'),
                radius: [4, 4, 0, 0],
            };
        });

        return (
            <ResponsiveContainer>
                <BarChart data={data} margin={{bottom: 100, top: 20}}>
                    <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" fontSize={10}
                           tick={props => <Text {...props} width={130}>{props.payload.value}</Text>}/>
                    <YAxis dataKey="value" label="%"/>
                    <Tooltip/>
                    <Bar dataKey="value" fill="#57AF7D" name="Техники в работе" barSize={30} isAnimationActive={false}>
                        <LabelList dataKey="label" position="top" fill="#666"/>
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    }

    async onSelectedUnitsChange(param) {
        let data = this.state.data;
        _.each(param, (p) => {
            let index = p.index;
            let checked = p.checked;

            data.organizationUnits[index].checked = checked;
        });
        await this.setState({data});
        this.loadDriversData();
    }

    onChange(field, e) {
        const value = e.value || e.target.value;

        let state = this.state;
        state[field] = value;
        this.setState(state);
    }

    render() {
        const loader = this.state.isLoading ? <GlobalLoaderComponent/> : null;

        return (
            <div className="kiutr-widget">
                {loader}
                {this.renderContent()}
            </div>
        );
    }

    getFill(value, field) {
        let green = 80;
        let orange = 50;

        if (field === 'vehicles_bnso') {
            green = 99;
            orange = 80;
        }

        if (value >= green) {
            return '#95B400';
        }
        if (value >= orange) {
            return '#F4A44B';
        }
        return '#F65C50';
    }
}