import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const createTemplateDocument = (data) => async (dispatch) => {
    try {
        const response = await api.portal.createTemplateDocument(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateTemplateDocument = (data) => async (dispatch) => {
    try {
        const response = await api.portal.updateTemplateDocument(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const testTemplateDocument = (data) => async (dispatch) => {
    try {
        const response = await api.portal.testTemplateDocument(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteTemplateDocument = (data) => async (dispatch) => {
    try {
        const response = await api.portal.deleteTemplateDocument(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTemplateDocumentNextVersion = (data) => async (dispatch) => {
    try {
        const response = await api.portal.getTemplateDocumentNextVersion(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTemplateDocuments = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.portal.listTemplateDocuments(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTemplateDocument = (uuid) => async (dispatch) => {
    try {
        const response = await api.portal.getTemplateDocument(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};