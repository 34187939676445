import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getTerritorialEntities = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getTerritorialEntities(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTerritorialEntity = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getTerritorialEntity(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createTerritorialEntity = (data) => async (dispatch) => {
    try {
        const response = await api.geo.createTerritorialEntity(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateTerritorialEntity = (data) => async (dispatch) => {
    try {
        const response = await api.geo.updateTerritorialEntity(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteTerritorialEntity = (data) => async (dispatch) => {
    try {
        const response = await api.geo.deleteTerritorialEntity(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};