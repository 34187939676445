import {api} from 'helpers/api';
import moment from "moment";
import {success, error} from 'helpers/response';

export const getComplaints = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.complaints.getComplaints(meta)

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getComplaintById = (uuid) => async (dispatch) => {
    try {
        const response = await api.complaints.getComplaintById(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

// Комментарии от BE:
// (Обновить можно только три поля unit_uuid, images, status_uuid,
// попытка обновить другие поля будет проигнорирована.
// Поле images это массив с объектами вида {url: "...", type: '...'}.
// Тип может быть или 'before', или 'after'.
// Обновлять можно только изображения с типом 'after',
// неуказание типа или указание какого-то стороннего типа автоматически определяет изображение в 'after',
// попытка добавить изображение с типом 'before' будет проигнорирована)

export const updateComplaint= (data) => async (dispatch) => {
    try {
        const response = await api.complaints.updateComplaint(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteComplaint = (data) => async (dispatch) => {
    try {
        const response = await api.complaints.deleteComplaint(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateNSUsernameWithUnitUuid = (data) => async (dispatch) => {
    try {
        const response = await api.complaints.updateNSUsernameWithUnitUuid(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getNSUsernameWithEmailPhoneInfo = () => async (dispatch) => {
    try {
        const response = await api.complaints.getNSUsernameWithEmailPhoneInfo();

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getNSUsernameWithUnitUuid = () => async (dispatch) => {
    try {
        const response = await api.complaints.getNSUsernameWithUnitUuid();

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
