import React from 'react';

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";

import {
    createSmsTemplate, getSmsTemplate, updateSmsTemplate
} from "store/reducers/sms/sms";

import {connect} from "react-redux";


@connect(state => ({}), { createSmsTemplate, getSmsTemplate, updateSmsTemplate })

export default class SmsTemplateEditor extends BaseEditor {

    title = 'смс шаблона';

    componentDidMount() {
        this.forceUpdate();
    }

    async loadData(uuid) {
        return await this.props.getSmsTemplate({ uuid: this.props.uuid});
    }

    async createItem(data) {
        const { title, template } = data;
        return await this.props.createSmsTemplate({ title, template })
    }

    async updateItem(data) {
        const { title, template } = data;
        return await this.props.updateSmsTemplate({ uuid: this.props.uuid, title, template });
    }

    getForm(item, onSubmit) {
        return (
            <div>
                <EditorForm
                    {...this.props}
                    uuid={this.props.uuid}
                    ref="form"
                    mode={this.props.mode}
                    onSubmit={onSubmit}
                    onClose={this.props.onClose}
                    onDataLoad={::this.forceUpdate}
                    data={item}
                    errors={this.state.errors}
                />
            </div>
        );
    }

    printButton() {
        return null;
    }

}

@connect((state) => ({}), {}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {};

    getData() {
        return this.state;
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true}>
                        <Block size="xl" title="Наименование шаблона">
                            {this.textInput(`title`)}
                        </Block>
                        <Block size="xl" title="Тело сообщения">
                            {this.textInput(`template`)}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}
