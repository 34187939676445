import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import GlobalLoaderComponent from "components/ui/global-loader";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getSchedule} from "store/reducers/kiutr/schedules/schedules";
import formats from "dictionaries/formats";
import moment from "moment";
import {getScheduleTurns, updateScheduleTurn} from "store/reducers/kiutr/schedules/schedule_turns";
import Button from "components/ui/button";
import Page from 'components/ui/page';
import {getRouteVariants} from "store/reducers/routes/route_variants";
import {getStopPoints} from "store/reducers/geo/stop-points";
import systems from "dictionaries/systems";
import IconButton from "components/ui/icon-button";
import {Line} from 'react-chartjs-2';
import runs from "dictionaries/runs";
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import debounce from 'throttle-debounce/debounce';
import * as alerts from "helpers/alerts";
import classNames from 'classnames';
import ContextTooltip from "components/ui/context-tooltip";
import {getOrders} from "store/reducers/kiutr/orders/orders";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {default as ReactTooltip} from 'react-tooltip-component';
import WidgetEllipse from "components/modules/main/WidgetEllipse/index";

export default class KiutrRouteScheduleEllipseViewComponent extends Component {

    state = {
        routeUuid: null,
        scheduleUuid: null,
    };

    async componentWillUpdate(props, state) {
        if (props.params.uuid !== state.routeUuid) {
            await this.setState({routeUuid: props.params.uuid});
        }
        if (props.params.scheduleUuid !== state.scheduleUuid) {
            await this.setState({scheduleUuid: props.params.scheduleUuid});
        }
    }

    componentDidMount() {
        this.forceUpdate();
    }

    render() {
        return (
            <Page
                title={`${systems.kiutr} → Контроль движения на маршруте`}
                pageId="Main"
                headerActions={this.renderHeaderActions()}
            >
                <div className="ellipse-container">
                {this.props.params.uuid ? (
                    <WidgetEllipse
                        routeUuid={this.props.params.uuid}
                    />
                ) : null}
                </div>
            </Page>
        );
    }

    close() {
        this.props.router.push(`/${this.props.params.component}/routes/${this.props.params.uuid}/schedules/${this.props.params.scheduleUuid}`);
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить" position="left">
                <IconButton
                    icon="times"
                    onClick={::this.close}/>
            </ContextTooltip>,
        ];
    }
}