import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import currentUser from 'helpers/current-user';
import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {getStopPoint, createStopPoint, updateStopPoint} from "store/reducers/geo/stop-points";
import BaseEditor from "components/base/base-editor";
import {getUnits} from "store/reducers/organizational_units/units";
import {getStopPointPavilions} from "store/reducers/geo/stop-point-pavilions";
import MapComponent from "components/ui/map";

import * as alerts from "helpers/alerts";
import {State} from "components/ui/state";
import {
    createSupernumerarySituation, getSupernumerarySituation,
    updateSupernumerarySituation
} from "store/reducers/kurs/supernumerary_situations";
import moment from "moment";
import formats from "dictionaries/formats";

@connect(state => ({}), {getSupernumerarySituation, createSupernumerarySituation, updateSupernumerarySituation})

export default class Editor extends BaseEditor {

    title = 'внештатной ситуации';
    modelClass = 'App\\Model\\SupernumerarySituation';

    async loadData(uuid) {
        return await this.props.getSupernumerarySituation(uuid);
    }

    async createItem(data) {
        return await this.props.createSupernumerarySituation(data);
    }

    async updateItem(data) {
        return await this.props.updateSupernumerarySituation(data);
    }

    getForm(item, onSubmit) {
        if (_.isEmpty(item)) {
            item = {
                date_from: moment(),
                time_from: moment().format(formats.TIME),
                status: 'opened',
            };
        }

        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
})

@connect((state) => ({}), {getDictionaryList}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        supernumerary_situation: {},
        supernumerary_situation_types: [],
    };

    getData() {
        return this.state.supernumerary_situation;
    }

    async componentDidMount() {
        await this.setState({
            supernumerary_situation: this.props.data,
        });
        this.loadDictionaries([
            'supernumerary_situation_types',
        ]);
    }

    get(path, defaultValue = null) {
        return _.get(this.state.supernumerary_situation, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem title="Основные сведения" opened={true} single={true}>
                        <Block title="Тип">
                            {this.select('supernumerary_situation.supernumerary_situation_type_uuid', this.state.supernumerary_situation_types)}
                        </Block>
                        <Block title="Дата">
                            {this.datepicker('supernumerary_situation.date_from')}
                        </Block>
                        <Block title="Время">
                            {this.maskInput('supernumerary_situation.time_from', '99:99', {
                                withTimeIcon: true,
                            })}
                        </Block>
                        <Block title="Дата решения">
                            {this.datepicker('supernumerary_situation.date_to')}
                        </Block>
                        <Block title="Время решения">
                            {this.maskInput('supernumerary_situation.time_to', '99:99', {
                                withTimeIcon: true,
                            })}
                        </Block>
                        <Block title="Статус">
                            {this.select('supernumerary_situation.status', [
                                {
                                    value: 'opened',
                                    label: 'Открыт',
                                },
                                {
                                    value: 'closed',
                                    label: 'Закрыт',
                                },
                            ])}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}