import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import classNames from 'classnames';

import {getIntervalMap, createIntervalMap, updateIntervalMap} from "store/reducers/routes/interval_maps";
import TableContainer from "components/ui/Table/Container/TableContainer";
import moment from "moment";
import formats from "dictionaries/formats";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getIntervalMap, createIntervalMap, updateIntervalMap})

export default class IntervalMapEditor extends BaseEditor {

    title = 'варианта инт. движ.';
    modelClass = 'App\\Model\\IntervalMap';

    modalClassName = 'interval-maps clarify-sel add-time-interval-modal';

    async loadData(uuid) {
        return await this.props.getIntervalMap(uuid);
    }

    async createItem(data) {
        return await this.props.createIntervalMap(data);
    }

    async updateItem(data) {
        return await this.props.updateIntervalMap(data);
    }

    async componentDidMount() {
        this.setState({
            uuid: this.props.uuid,
            item: null,
        });
        if (this.props.uuid) {
            this.setState({isLoading: true});
            const response = await this.loadData(this.props.uuid);
            if (response.isOk) {
                this.setState({
                    item: response.payload,
                    isLoading: false,
                });
            } else {
                response.showErrors();
            }
        }
    }

    getForm(item, onSubmit) {
        if (_.isEmpty(item)) {
            item = {
                name: '1',
                date_from: moment().startOf('month').format(formats.DATE_API),
                date_to: moment().endOf('month').format(formats.DATE_API),
                is_full: false,
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
                holiday: true,
            };
        }

        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    composeItem(data) {
        let item = _.clone(data);

        item.route_variant_uuid = this.props.routeVariantUuid;
        item.is_forward = this.props.isForward;

        return item;
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        interval_map: {},
    };

    getData() {
        return this.state.interval_map;
    }

    async componentDidMount() {
        await this.setState({
            interval_map: this.props.data,
        });
    }

    get(path, defaultValue = null) {
        return _.get(this.state.interval_map, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Block size="sm" title="№ карты">
                    {this.textInput('interval_map.name')}
                </Block>
                <Block size="sm" title="Дата начала">
                    {this.datepicker('interval_map.date_from')}
                </Block>
                <Block size="sm" title="Дата конца">
                    {this.datepicker('interval_map.date_to')}
                </Block>
                <Block size="xl">
                    <div className="Table">
                        <TableContainer>
                            <table className="b-table">
                                <thead>
                                <tr className="b-table__header">
                                    <th className="b-table__mon align-center">Пн</th>
                                    <th className="b-table__tue align-center">Вт</th>
                                    <th className="b-table__wed align-center">Ср</th>
                                    <th className="b-table__thu align-center">Чт</th>
                                    <th className="b-table__fri align-center">Пт</th>
                                    <th className="b-table__sat align-center">Сб</th>
                                    <th className="b-table__sun align-center">Вс</th>
                                    <th className="b-table__holidays align-center">Пр. дни</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.monday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.tuesday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.wednesday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.thursday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.friday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.saturday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.sunday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.holiday')}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </TableContainer>
                    </div>
                </Block>
            </div>
        );
    }
}