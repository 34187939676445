import React from 'react';
import {connect} from 'react-redux';

import Editor from './RoadPartsEditor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import _ from 'lodash';
import {deleteRoadPart, getRoadParts} from "store/reducers/commdept/road_parts";
import systems from "dictionaries/systems";

@connect(state => ({}), {getRoadParts, deleteRoadPart, getEntityNames})

export default class CommdeptRoadPartsComponent extends BaseTableWithEditorComponent {

    title = `${systems.commdept} → Участки дорог`;
    baseUrl = '/commdept/road_parts';

    getEditor() {
        return (
            <Editor
                {...this.props}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    getColumns() {
        return [
            new Column('Название участка')
                .fromField('name')
                .withClassName('link'),

            new Column('Длина')
                .fromField('length'),

            new Column('Ширина проезжей части, м')
                .fromField('width'),

            new Column('Начало')
                .fromField('start'),

            new Column('Конец')
                .fromField('end'),

            new Column('Категория')
                .fromField('category'),

            new Column('Группа по содержанию')
                .fromField('maintenance_group_uuid')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => item.maintenance_group_uuid && this.state.related.get(item.maintenance_group_uuid)),
        ];
    }

    async deleteItem(data) {
        return await this.props.deleteRoadPart(data);
    }

    async loadData(meta) {
        return await this.props.getRoadParts(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const maintenanceGroups = _.map(_.filter(_.map(result, 'maintenance_group_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\MaintenanceGroups\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(maintenanceGroups);

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}
