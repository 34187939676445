import React from 'react';
import {connect} from 'react-redux';

import Editor from './editor';

import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import Column from "components/ui/column";
import systems from "dictionaries/systems";
import moment from "moment";
import formats from "dictionaries/formats";
import {deleteTechnocard, getTechnocards} from "store/reducers/kurs/technocards";
import {deleteTechnocardAssign, getTechnocardAssigns} from "store/reducers/kurs/technocard_assigns";
import _ from 'lodash';
import {getEntityNames} from "store/reducers/system";

@connect(state => ({}), {getTechnocardAssigns, deleteTechnocardAssign, getEntityNames})

export default class UtilityTechnocardAssignsComponent extends BaseTableWithEditorComponent {

    types = {
        'road_part': 'Участки дорог',
        'repair_part': 'Участки ремонта',
    };

    statuses = {
        draft: 'Черновик',
        active: 'Действует',
        done: 'Обработан',
        closed: 'Закрыт',
    };

    getTitle() {
        return <span>{systems.utility} → Назначение технологических карт</span>;
    }

    baseUrl = '/utility/technocard_assigns';

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Участок')
                .fromField('item_uuid')
                .denyColumnFilter()
                .withDrawer(item => this.state.related.get(item.item_uuid)),

            new Column('Тип участка')
                .fromField('type')
                .denyColumnFilter()
                .withDrawer(item => this.types[item.type]),

            new Column('Наименование тех. карты')
                .fromField('technocard_uuid')
                .denyColumnFilter()
                .withDrawer(item => this.state.related.get(item.technocard_uuid)),

            new Column('Дата с')
                .fromField('date_from')
                .withDateFilter()
                .withDrawer(item => moment(item.date_from).format(formats.DATE)),

            new Column('Дата по')
                .fromField('date_to')
                .withDateFilter()
                .withDrawer(item => moment(item.date_to).format(formats.DATE)),

            new Column('Статус')
                .fromField('status')
                .denyColumnFilter()
                .withDrawer(item => this.statuses[item.status]),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteTechnocardAssign(data);
    }

    async loadData(meta) {
        return await this.props.getTechnocardAssigns(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const items = _.map(result, (item) => {
            if (item.type === 'road_part') {
                return {
                    class: 'App\\Model\\RoadPart',
                    uuid: item.item_uuid,
                    source: 'kurs',
                };
            } else {
                return {
                    class: 'App\\Dictionaries\\Kurs\\KursRoadRepairParts\\Model',
                    uuid: item.item_uuid,
                    source: 'dictionary',
                };
            }
        });

        const technocards = _.map(_.uniq(_.filter(_.map(result, 'technocard_uuid'))), (uuid) => ({
            class: 'App\\Model\\Technocard',
            uuid: uuid,
            source: 'kurs',
        }));

        const response = await this.props.getEntityNames(_.concat(items, technocards));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}