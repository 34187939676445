import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';
import { setContractsList } from './actions';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getContracts = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.kurs.getContracts(meta);
        dispatch(setContractsList(response.payload.items));

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getContract = (uuid) => async (dispatch) => {
    try {
        const response = await api.kurs.getContract(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createContract = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.createContract(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateContract = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.updateContract(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteContract = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.deleteContract(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};