import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {getNewsItem, createNews, updateNews} from "store/reducers/portal/news";
import {api} from "helpers/api";
import classNames from 'classnames';
import Select from 'components/ui/select';

import FileReaderInput from 'react-file-reader-input';

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getNewsItem, createNews, updateNews})

export default class Editor extends BaseEditor {

    title = 'новости';
    modelClass = 'App\\Model\\News';
    disableOutsideClick = true;

    async loadData(uuid) {
        return await this.props.getNewsItem(uuid);
    }

    async createItem(data) {
        return await this.props.createNews(data);
    }

    async updateItem(data) {
        return await this.props.updateNews(data);
    }

    getForm(item, onSubmit) {
        item.is_active = !!item.is_active;

        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        news: {},
    };

    getData() {
        return this.state.news;
    }

    async componentDidMount() {
        await this.setState({
            news: this.props.data,
        });
    }

    get(path, defaultValue = null) {
        return _.get(this.state.news, path, defaultValue);
    }

    render() {
        const photo = this.get('image');
        const photoClassName = classNames('profile-pic__img', photo ? '' : 'img-empty');

        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} single={true}>
                        <div className="profile-pic">
                            <div className={photoClassName}
                                 style={{backgroundImage: `url(${this.get('image', '/img/user_default_avatar.svg')})`}}>
                                <FileReaderInput as="binary" id="my-file-input" onChange={::this.uploadImage}>
                                    <a className="b-icon-link  b-icon-link_icon_edit_white"/>
                                </FileReaderInput>
                                {this.get('image') ? (
                                    <span className="b-icon-link left-top b-icon-link_icon_remove_white" onClick={::this.deleteImage}/>
                                ) : null}
                            </div>
                        </div>
                        <div className="personal">
                            <Block size="xl" title="Заголовок">
                                {this.textInput('news.title')}
                            </Block>
                            <Block size="xl" title="Текст">
                                {this.wysiwyg('news.text')}
                            </Block>
                            <Block title="Дата публикации">
                                {this.datepicker('news.timestamp')}
                            </Block>
                            <Block title="Источник">
                                {this.textInput('news.source')}
                            </Block>
                            <Block size="xl" title="Тэги">
                                <Select.Creatable
                                    multi={true}
                                    options={_.map(this.get('tags'), (tag) => ({
                                        label: tag,
                                        value: tag,
                                    }))}
                                    onChange={::this.onTagsChange}
                                    value={this.get('tags')}
                                />
                            </Block>
                            <Block title="">
                                {this.checkbox('news.is_active', 'Активна')}
                                {this.checkbox('news.is_pinned', 'Главная новость')}
                            </Block>
                            {window.RNIS_SETTINGS.grantMO ? (
                                <Block title="Платформы">
                                    {this.checkbox('news.web', 'Сайт')}
                                    {this.checkbox('news.mobile', 'Мобильное приложение')}
                                </Block>
                            ) : null}
                        </div>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    deleteImage() {
        this.setValue('news.image', null);
    }

    onTagsChange(value) {
        const tags = _.map(value, 'value');
        this.setValue('news.tags', tags);
    }

    async uploadImage(e, results) {
        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        results.forEach(result => {
            const [e, file] = result;
            //formData.append('file', e.target.result);
            formData.append('file', file);
        });

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');

        this.onChangeInput('news.image', {target: {value}});
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }
}
