import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import {CycleFetch} from 'helpers/api';
import { Map} from 'immutable';
import _ from 'lodash';
import {error, success} from "helpers/response";
import Page from 'components/ui/page';
import GlobalLoaderComponent from "components/ui/global-loader";
import ContextTooltip from "components/ui/context-tooltip";
import SelectButtonPopup from 'components/ui/SelectButtonPopup';

//import Leaflet from './leaflet';
//import L from 'leaflet';


import debounce from 'throttle-debounce/debounce';
import ClustersToggle from "components/modules/maps/clusters-toggle";
// import StopPointsZoom from "components/modules/maps/stop-points-zoom";
// import TelematicsDevice from "components/modules/maps/telematics-packet";
// import HistoryModal from "components/modules/maps/modals/history-modal";
// import Slider from 'rc-slider';
// import Tooltip from 'rc-tooltip';
//import 'rc-slider/assets/index.css';
//import MapFullscreenControl from "components/ui/map/controls/fullscreen/index";
//import {getUnits} from "store/reducers/organizational_units/units";

//import {getOrderVehicles} from "store/reducers/kiutr/orders/orders";
//import DirectionalPolyline from "components/ui/map/objects/directional-polyline";
//import RouteModal from "components/modules/maps/modals/route-modal";
//import StopPointMarker from "components/ui/map/markers/stop-point-marker";
//import {getStopPoints} from "store/reducers/geo/stop-points";
//const Handle = Slider.Handle;
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getBnsoList} from "store/reducers/vehicles/bnso";
import {getOption} from "store/reducers/settings";
import {getTelematicsBoundsWithDevices, getTelematicsWithDevices} from 'store/reducers/maps';
import {getUnits} from "store/reducers/organizational_units/units";
import {activateLayer} from "store/reducers/user-map-objects/layers";
import './style.less';
import BaseAnalyticsComponent from "components/modules/analytics/base";

@propTypes({
    /*variant: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    deletePoint: PropTypes.func.isRequired,
    addStopPoint: PropTypes.func.isRequired,
    killRecalc: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    stopPoints: PropTypes.object,*/
})

@defaultProps({
    /*visible: true,
    stopPoints: {},*/
})

@connect(state => ({}), {
    getTelematicsWithDevices,
    getTelematicsBoundsWithDevices,
    getVehicleList,
    getDictionaryList,
    getBnsoList,
    getOption,
    activateLayer,
    getUnits
})

export default class AnalyticsMapComponent extends BaseAnalyticsComponent {
    constructor(props) {
        super(props);

        this.state = {
            isError: false,
            isLoading: false,

            telematics: new Map({}),
            clusters: [],
            sourceType: 't1sync',

            gatnResponsives: {},
            vehicleModels: {},
            bnsoTypes: {},

            clustersActive: false,

            fullscreen: false,

            branches: [
                { id: 'kiutr', name: 'Пассажирские перевозки', checked: true },
                { id: 'children', name: 'Перевозки детей' },
                { id: 'road', name: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text },
                { id: 'garbage', name: 'Вывоз мусора' },
                { id: 'control', name: window.RNIS_SETTINGS.GUGATNTITLE || 'ГУ ГАТН' }
            ],

            gatnUnits: [],
            otherUnits: [],
            units: [],

            allVehicles: {},
            vehicles: []
        };

        //this.zoomDebounce = debounce(1000, ::this.onZoomLoad);
        //this.loadDebounce = debounce(1000, ::this.reload);
        this._cycleFetch = null;
    }

    componentWillMount() {
        this.loadData();
    }

    /*async componentDidMount() {
        const response = await this.props.getOption('vehicle-clusters-active');
        if (response.isOk) {
            const clustersActive = (_.first(response.payload.items || []) || false) == 1;
            this.setState({clustersActive});
        }

        //this.forceUpdate();
        this.loadDictionaries([
            'gatn_responsives',
            'vehicle_models',
            'bnso_types',
        ]);
        this.loadBnso();

        switch (this.state.component) {
            case 'kiutr':
            case 'children':
                this.loadUnits();
                break;
            case 'control':
                break;
        }
    }*/

    loadDictionary(dictionary, component = null) {
        const meta = {
            order: {
                column: 'name',
                direction: 'asc',
            },
            filters: {
                withComponent: component,
            }
        };

        return this.props.getDictionaryList(dictionary, meta);
    }

    loadGatnUnits() {
        return this.loadDictionary('gatn_responsives');
    }

    loadVehicleModels() {
        return this.loadDictionary('vehicle_models');
    }

    loadBnsoTypes() {
        return this.loadDictionary('bnso_types');
    }

    loadUnits() {
        return this.props.getUnits();
    }

    loadVehicles() {
        const meta = {
            filters: {
                withActiveBnso: true
                //withComponent: null
            }
        };


        /*if (this.state.selectedVehicles !== null) {
            filters.withUuid = _.keys(_.pickBy(this.state.selectedVehicles, (item) => !!item));
        }*/
        return this.props.getVehicleList(meta);

        /*if (response.isOk) {
            await this.setState({
                vehicles: _.keyBy(response.payload.items, 'current_bnso.bnso_number'),
            });
            if (this.state.selectedVehicles === null) {
                this.setState({
                    allVehicles: this.state.vehicles,
                });
                if (this.state.component === 'control') {
                    this.setState({
                        selectedVehicles: _.mapValues(_.keyBy(this.state.vehicles, 'uuid'), (item) => true),
                    });
                }
            }
            this._cycleFetch && this._cycleFetch.forceNext();
        } else {
            response.showErrors();
            this.setState({loading: false});
        }*/
    }

    async loadData() {
        this.setState({isError: false, isLoading: true});

        let gatnResponsives = {},
            gatnUnits = [],
            otherUnits = [],
            vehicleModels = {},
            bnsoTypes = {},
            allVehicles = {};

        try {
            const [ responseGatnUnits, responseUnits, responseVehicleModels, responseBnsoTypes, responseVehicles ] = await Promise.all([
                this.loadGatnUnits(),
                this.loadUnits(),
                this.loadVehicleModels(),
                this.loadBnsoTypes(),
                this.loadVehicles()
            ]);

            if (responseGatnUnits.isOk) {
                gatnResponsives = _.keyBy(responseGatnUnits.payload.documents, 'uuid');
                gatnUnits = _.sortBy(_.map(responseGatnUnits.payload.documents, unit => ({
                    id: unit.uuid,
                    name: unit.name
                })), 'name');
            } else {
                responseGatnUnits.showErrors();
            }

            if (responseUnits.isOk) {
                otherUnits = _.sortBy(_.map(responseUnits.payload.items, unit => ({
                    id: unit.uuid,
                    name: unit.name
                })), 'name');
                //console.log(units);
            } else {
                responseUnits.showErrors();
            }

            //console.log(responseGatnUnits.payload.documents.length);
            //console.log(responseUnits.payload.items.length);


            if (responseVehicleModels.isOk) {
                vehicleModels = _.keyBy(responseVehicleModels.payload.documents, 'uuid');
                //console.log(vehicleModels);
            } else {
                responseVehicleModels.showErrors();
            }

            if (responseBnsoTypes.isOk) {
                bnsoTypes = _.keyBy(responseBnsoTypes.payload.documents, 'uuid');
                //console.log(bnsoTypes);
            } else {
                responseBnsoTypes.showErrors();
            }

            if (responseVehicles.isOk) {
                const vehicles = responseVehicles.data.payload.items;
                console.log(vehicles);

                vehicles.forEach((vehicle) => {
                    const component = vehicle.component;
                    const unitUuid = vehicle.unit_uuid;

                    if (!_.has(allVehicles, `${component}.${unit_uuid}`)) {
                        _.set(allVehicles, `${component}.${unit_uuid}`, [])
                    }

                    allVehicles[component][unitUuid].push(vehicle);
                });

                console.log(allVehicles);
                //allVehicles = _.keyBy(responseVehicles.data.payload.items, 'uuid');
            }

            const units = this.getUpdatedUnits(true);
            const vehicles = this.getUpdatedVehicles(true);

            //console.log(gatnResponsives);
            //console.log(allVehicles);
            //let response = await api.dictionary.getDictionaryStructure(meta);
            //const response = await this.getUnits();



            this.setState({
                gatnResponsives,
                gatnUnits,
                otherUnits,
                vehicleModels,
                bnsoTypes,
                allVehicles,
                units,
                vehicles,
                isError: false,
                isLoading: false
            });
        }
        catch (e) {
            this.setState({isError: true, isLoading: false});
            error(e).showErrors();
        }
    }

    getBranchItemsState() {
        return this.refs.branchSelectButtonPopup ? this.refs.branchSelectButtonPopup.getItems() : [];
    }

    /*getCheckedBranches() {
        return _.filter(this.getBranchItemsState(), 'checked');
    }*/

    getUnitsItemsState() {
        return this.refs.unitsSelectButtonPopup ? this.refs.unitsSelectButtonPopup.getItems() : [];
    }

    /*getCheckedUnits() {
        return _.filter(this.getUnitsItemsState(), 'checked');
    }*/

    getVehiclesItemsState() {
        return this.refs.vehiclesSelectButtonPopup ? this.refs.vehiclesSelectButtonPopup.getItems() : [];
    }

    onBranchSelectWidget = (items) => {
        const branches = this.getUpdatedBranches();
        const units = this.getUpdatedUnits(true);
        const vehicles = this.getUpdatedVehicles(true);
        this.setState({ branches, units, vehicles });
    };

    onUnitsSelectWidget = (items) => {
        const units = this.getUpdatedUnits();
        const vehicles = this.getUpdatedVehicles(true);
        this.setState({ units, vehicles });
    };

    onVehiclesSelectWidget = (items) => {
        const vehicles = this.getUpdatedVehicles();
        this.setState({ vehicles });
    };

    getUpdatedBranches() {
        const branchItems = this.getBranchItemsState();
        const branches = this.state.branches;

        branches.forEach((item, index) => {
            item.checked = branchItems[index].checked;
        });

        return branches;
    }

    getUnits() {
        let units = [];
        //const branchItems = this.getBranchItemsState();
        const branches = this.state.branches;
        /*const branches = branchItems.length ? branchItems
            :
            _.mapValues(_.keyBy(_.map(this.state.branches, (item, index) => { return {...item, index}; }), 'index'), unit => !!(unit.checked));

        console.log(branches);*/

        let isOther = false,
            isControl = false;

        branches.forEach((item) => {
            if (item.checked) {
                if (item.id === 'control') {
                    isControl = true;
                }
                else {
                    isOther = true;
                }
            }
        });

        /*branches.forEach((item, index) => {
            if (branchItems[index].checked) {
                if (item.id === 'control') {
                    isControl = true;
                }
                else {
                    isOther = true;
                }
            }
        });*/

        if (isOther) {
            units.push(this.state.otherUnits);
        }

        if (isControl) {
            units = _.concat(units, this.state.gatnUnits);
        }

        return units;
    }

    getUpdatedUnits(uncheck = null) {
        const unitsItems = uncheck ? {} : this.getUnitsItemsState();
        const units = this.getUnits();
        //const units = this.state.units;

        units.forEach((item, index) => {
            item.checked = uncheck ? false : unitsItems[index].checked;
        });

        return units;
    }

    getVehicles() {
        //const branchItems = this.getBranchItemsState();
        //const unitsItems = this.getUnitsItemsState();
        const branches = this.state.branches;
        const units = this.state.units;
        //const units = this.getUnits();
        let vehicles = [];
        branches.forEach((branch) => {
            if (branch.checked) {
                units.forEach((unit) => {
                    if (unit.checked) {
                        vehicles = _.concat(vehicles, this.state.allVehicles[branch.id][unit.uuid]);
                    }
                });
            }
        });


        /*branches.forEach((branch, index) => {
            if (branchItems[index].checked) {
                units.forEach((unit, index) => {
                    if (unitsItems[index].checked) {
                        vehicles = _.concat(vehicles, this.state.allVehicles[branch.id][unit.uuid]);
                    }
                });
            }
        });*/

        return vehicles;
    }

    getUpdatedVehicles(uncheck = null) {
        const vehiclesItems = uncheck ? {} : this.getVehiclesItemsState();
        const vehicles = this.getVehicles();
        //const vehicles = this.state.vehicles;

        vehicles.forEach((item, index) => {
            item.checked = uncheck ? false : vehiclesItems[index].checked;
        });

        return vehicles;
    }

    renderContent() {
        return null;
    }


    renderHeaderContents() {
        const headerContents = [];

        headerContents.push(
            <ContextTooltip key="analytical-map.branch-widget-select"
                            code="analytical-map.branch-widget-select"
                            default="Отрасль"
            >
                <SelectButtonPopup ref="branchSelectButtonPopup"
                                   items={this.state.branches}
                                   onChange={this.onBranchSelectWidget}
                                   //disabled={this.state.disabledSelectButton}
                />
            </ContextTooltip>,

            <ContextTooltip key="analytical-map.units-widget-select"
                            code="analytical-map.units-widget-select"
                            default="Предприятия"
            >
                <SelectButtonPopup ref="unitsSelectButtonPopup"
                                   items={this.state.units}
                                   onChange={this.onUnitsSelectWidget}
                    //disabled={this.state.disabledSelectButton}
                />
            </ContextTooltip>,

            <ContextTooltip key="analytical-map.vehicles-widget-select"
                            code="analytical-map.vehicles-widget-select"
                            default="ТС"
            >
                <SelectButtonPopup ref="vehiclesSelectButtonPopup"
                                   items={this.state.vehicles}
                                   onChange={this.onVehiclesSelectWidget}
                    //disabled={this.state.disabledSelectButton}
                />
            </ContextTooltip>
        );

        return headerContents;
    }

    render() {
        /*const components = [];

        components.push(<ClustersToggle
            key="clusters-toggle"
            active={this.state.clustersActive}
            toggle={::this.toggleClusters}
        />);*/

        const content = this.state.isError ? null : this.state.isLoading ? <GlobalLoaderComponent/> : this.renderContent();

        return (
            <Page pageId="AnalyticalMap" title="Интерактивная карта"
                  headerContents={this.renderHeaderContents()}
                  //className={(this.state.history.length > 0) ? 'with-history' : ''}
                  //fullscreen={this.state.fullscreen}
            >
                {content}
            </Page>
        );


    }
}
