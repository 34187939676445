import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {deleteFaq, getFaq} from "store/reducers/portal/faq";
import {deletePage, getPages} from "store/reducers/portal/pages";
import IconButton from "components/ui/icon-button";
import {deleteContact, getContacts} from "store/reducers/mobile/contacts";
import contact_types from "dictionaries/contact_types";

@connect(state => ({}), {getContacts, deleteContact})

export default class MobileContactsComponent extends BaseTableWithEditorComponent {

    title = 'МП → Контакты';
    baseUrl = '/mobile/contacts';

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Тип')
                .fromField('type')
                .denyColumnFilter()
                .withDrawer((item) => contact_types[item.type]),

            new Column('Наименование')
                .fromField('name'),

            new Column('Значение')
                .fromField('value'),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteContact(data);
    }

    async loadData(meta) {
        return await this.props.getContacts(meta);
    }
}