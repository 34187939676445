import {Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getOrderExecutions = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getOrderExecutions(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const orderExecutionsStatistics = () => async (dispatch) => {
    try {
        const response = await api.geo.orderExecutionsStatistics();

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getOrderExecutionUnitsSummary = (data) => async (dispatch) => {
    try {
        const response = await api.geo.getOrderExecutionUnitsSummary(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const recalcOrderExecution = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.recalcOrderExecution(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getOrderExecutionRecalc = (data) => async (dispatch) => {
    try {
        const response = await api.geo.getOrderExecutionRecalc(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
