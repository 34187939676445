import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import classNames from 'classnames';

import Colors from 'helpers/colors';

import './icon-button.less';


@propTypes({
    icon: PropTypes.string.isRequired,
    size: PropTypes.number,
    color: PropTypes.oneOf(Colors.All),
    onClick: PropTypes.func,
    onDisabledClick: PropTypes.func,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    className: PropTypes.string,
    tooltipClassName: PropTypes.string,
})

@defaultProps({
    onClick: () => {
    },
    onDisabledClick: () => {
    },
    disabled: false,
    active: false,
    className: '',
    tooltipClassName: '',
})

export default class IconButton extends Component {

    render() {
        return this.renderIcon({
            onClick: this.props.disabled ? this.props.onDisabledClick : ::this.props.onClick
        });
    }

    renderIcon(props) {
        const wrapperClassName = classNames('top-menu__link', 'top-menu__link_icon_' + this.props.icon, this.props.disabled ? 'disabled' : '', this.props.active ? 'active' : '', this.props.className);
        const tooltipClassName = classNames('top-menu__title', 'top-menu__title_' + this.props.icon, this.props.tooltipClassName);

        const styles = {};

        if (this.props.size)
            styles.fontSize = this.props.size;

        if (this.props.color)
            styles.color = this.props.color;

        return (
            <div ref="container" className={wrapperClassName} {...props}/>
        );
    }

}