import React, { Component } from 'react';
import { api } from 'helpers/api';
import { Map } from 'immutable';
import { getPercentForWorksDone } from 'helpers/math';
import { Link } from 'react-router';
import _ from 'lodash';
import Page from 'components/ui/page';
import * as storage from 'utils/storage';
import { connect } from 'react-redux';
import {
	getEtpEvents,
	getHistory,
	getHistoryByVehicle,
	getTelematicsBoundsWithDevices,
	getTelematicsWithTemperatureGrpc
} from 'store/reducers/maps';
import { getUsers } from 'store/reducers/staffing/staffing';
import Leaflet from './leaflet';
import L from 'leaflet';
import './maps.less';
import GlobalLoaderComponent from 'components/ui/global-loader';
import { getVehicle, getVehicleList } from 'store/reducers/vehicles/vehicles';
import debounce from 'throttle-debounce/debounce';
import systems from 'dictionaries/systems';
import { activateLayer } from 'store/reducers/user-map-objects/layers';
import { getLayerObjects, getLayers } from 'store/reducers/user-map-objects/layer_list';
import { getTabletWorkSummary, getTabletWorkSummaryPercent } from 'store/reducers/reports/tablet';
import moment from 'moment';
import formats from 'dictionaries/formats';
import { HistoryTelematicsDevice } from 'components/modules/maps/telematics-packet';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import { getOrder } from 'store/reducers/kiutr/orders/orders';
import DirectionalPolyline from 'components/ui/map/objects/directional-polyline';
import StopPointMarker from 'components/ui/map/markers/stop-point-marker';
import EventMarker from 'components/ui/map/markers/event-marker';
import { requestTelematics } from 'helpers/telematics/requester';
import { getNotification, getNotificationsList } from 'store/reducers/notifications';
import { isRouteA } from 'helpers/route';
import { getGeometryFromContract, getTasks, getTaskViolations } from 'store/reducers/kurs/tasks';
import VehicleEditor from 'components/modules/vehicles/vehicles/editor';
import Settings from 'settings';
import * as alerts from 'helpers/alerts';
import PageModalComponent from 'components/ui/page-modal';
import KursVehicleFuel from 'components/modules/kurs/vehicles/vehicle-editor-form/vehicle-fuel/index';
import currentUser from 'helpers/current-user';
import MapRetrospective from 'components/modules/maps/Retrospective/MapRetrospective';
import { getOrderExecutions } from 'store/reducers/kiutr/orders/order_executions';
import { getReportsViolationsKiutr } from 'store/reducers/reports/reports';
import classNames from 'classnames';
import { monitoringFilterVehicles } from 'store/reducers/monitoring/monitoring';
import MapFilterResult from 'components/modules/maps/FilterResult/MapFilterResult';
import Select from 'components/ui/select';
import Datepicker from 'components/ui/form/datepicker';
import VehiclePopupConfigModal from 'components/modules/maps/modals/VehiclePopupConfigModal';
import MapGeojson from 'components/ui/map/base/geojson';
import { listObjectVisits } from 'store/reducers/kurs/object_visits';
import { component_mapper } from 'helpers/component_mapper';
import MapPolyline from 'components/ui/map/base/polyline';
import etp_events from 'dictionaries/etp_events';
import { makeResponse } from 'helpers/response';
import { User } from 'helpers/user';
import {
	currentSpeed,
	dictionaries,
	driversResponseData,
	filtersMap,
	initialState,
	speeds,
	vehicleListFilters,
	vehiclePopupDefaultValues
} from './constants';
import {
	filteredByCommunalUuid,
	getDefaultFilters,
	getOrderBy,
	getPagination,
	getRoadModalData,
	getRoadPartModalData,
	getSelectedRouteColor,
	getVehicleTasks,
	getWeekDifference,
	isKiutrOrChildren,
	isKurs,
	logicViewWorkTypeSelect,
	mapHistoryWorks,
	timeStrToMinutes,
	timeToInt
} from './utils';
import { RoadModal } from './modals/road-modal';
import { RoadModalButtons } from './modals/road-modal-buttons';
import {
	getBnsoList,
	getCarrierUnits,
	getDictionaries,
	getHistoryOrderExecutions,
	getHistoryOrders,
	getMo,
	getMunicipalitiesKiutrSummary,
	getRoute,
	getSelectedRoutes,
	getUnits,
	getVehicleForPopup
} from '../../../store/reducers/maps/thunk';
import {
	getAnalyticDiapasonSelector,
	getBnsoSelector,
	getComponentSelector,
	getDiapasonSelector,
	getDictionariesSelector,
	getFilterBaseResultSelector,
	getFiltersSelector,
	getHistoryOrderExecutionsSelector,
	getHistoryOrdersSelector,
	getIsLoadingSelector,
	getMoSelector,
	getMunicipalitiesKiutrSummarySelector,
	getOperatorComponentsSelector,
	getRouteSelector,
	getRoutesSelector,
	getRouteStopPointsSelector,
	getSelectedRoadPartSelector,
	getSelectedRoadRepairPartSelector,
	getSelectedRoadSelector,
	getSelectedRoutesSelector,
	getSelectedRouteVariantsSelector,
	getSelectedVehiclesSelector,
	getShouldHistoryBeSHownSelector,
	getShowEventsSelector,
	getShowHistorySelector,
	getShowHistorySliderSelector,
	getShownTelematicsSelector,
	getUnitsSelector,
	getVehicleForPopSelector
} from '../../../store/reducers/maps/selectors';
import {
	clearRoadsData,
	setAnaliticDiapason,
	setComponent,
	setDiapason,
	setFilters,
	setShowEvents,
	setShowHistory,
	setShowHistorySlider,
	setTelematics
} from '../../../store/reducers/maps/actions';
import { HistoryGeojson } from './history/history-geojson';
import { filters } from '../../../store/reducers/maps/constants';
import { renderHeaderContent } from './utils/render-header-content';
import { renderModalsComponents } from './utils/render-modals-components';
import { renderMoPopup } from './utils/render-mo-popup';
import { renderMoTooltip } from './utils/render-mo-tooltip';
import { AnalyticsLegend } from './analytics/legend';
import { getObjectsHistoryData } from '../../../store/reducers/maps';

@connect(
	(state) => ({
		vehicleForPopup: getVehicleForPopSelector(state),
		activeLayers: state.user_map_objects.layers.get('activeLayers'),
		dictionaries: getDictionariesSelector(state),
		units: getUnitsSelector(state),
		bnso: getBnsoSelector(state),
		component: getComponentSelector(state),
		original_component: getComponentSelector(state),
		selectedRoad: getSelectedRoadSelector(state),
		selectedRoadPart: getSelectedRoadPartSelector(state),
		selectedRoadRepairPart: getSelectedRoadRepairPartSelector(state),
		loading: getIsLoadingSelector(state),
		historyOrders: getHistoryOrdersSelector(state),
		historyOrderExecutions: getHistoryOrderExecutionsSelector(state),
		mo: getMoSelector(state),
		municipalities: getMunicipalitiesKiutrSummarySelector(state),
		filters: getFiltersSelector(state),
		diapason: getDiapasonSelector(state),
		analyticDiapason: getAnalyticDiapasonSelector(state),
		showEvents: getShowEventsSelector(state),
		showHistory: getShowHistorySelector(state),
		showHistorySlider: getShowHistorySliderSelector(state),
		route: getRouteSelector(state),
		routes: getRoutesSelector(state),
		routeStopPoints: getRouteStopPointsSelector(state),
		selectedRoutes: getSelectedRoutesSelector(state),
		selectedRouteVariants: getSelectedRouteVariantsSelector(state),
		layers: state.user_map_objects.layers.get('layers'),
		selector_municipalities: state.user_map_objects.layers.get('objects'),
		shownTelematics: getShownTelematicsSelector(state),
		operatorComponents: getOperatorComponentsSelector(state),
		vehiclesFilter: getSelectedVehiclesSelector(state),
		baseResultFilter: getFilterBaseResultSelector(state),
		shouldShowHistory: getShouldHistoryBeSHownSelector(state)
	}),
	(dispatch) => ({
		getTelematicsBoundsWithDevices,
		getVehicleList,
		getVehicle,
		activateLayer,
		getHistoryByVehicle,
		getHistory,
		onGetRoute: (routeUuid) => dispatch(getRoute(routeUuid)),
		getNotificationsList,
		getNotification,
		onGetTasks: (filters) => dispatch(getTasks(filters)),
		listObjectVisits,
		getOrderExecutions,
		getOrder,
		monitoringFilterVehicles,
		getTaskViolations,
		getEtpEvents,
		getGeometryFromContract,
		getTelematicsWithTemperatureGrpc: (payload) => dispatch(getTelematicsWithTemperatureGrpc(payload)),
		getObjectsHistoryDataRequest: (devices, dateFrom, dateTo) =>
			dispatch(getObjectsHistoryData(devices, dateFrom, dateTo)),
		onGetReportsViolations: (payload) => dispatch(getReportsViolationsKiutr(payload)),
		getLayerObjects: (layerUuid) => dispatch(getLayerObjects(layerUuid)),
		getLayers: () => dispatch(getLayers()),
		getTabletWorkSummaryPercent: (data) => dispatch(getTabletWorkSummaryPercent(data)),
		getTabletWorkSummary: (data) => dispatch(getTabletWorkSummary(data)),
		getUsers,
		onGetMunicipalitiesKiutrSummary: (type) => dispatch(getMunicipalitiesKiutrSummary(type)),
		onHideRoadModal: () => dispatch(clearRoadsData()),
		onGetDictionaries: (payload) => dispatch(getDictionaries(payload)),
		onGetUnits: (payload) => dispatch(getUnits(payload)),
		onGetCarrierUnits: (payload) => dispatch(getCarrierUnits(payload)),
		onGetBnsoList: () => dispatch(getBnsoList()),
		onSetComponent: (component) => dispatch(setComponent(component)),
		onLoadHistoryOrders: (uuids) => dispatch(getHistoryOrders(uuids)),
		onLoadHistoryOrderExecutions: (from, to, vehicleUuid) => dispatch(getHistoryOrderExecutions(from, to, vehicleUuid)),
		onGetMo: (type) => dispatch(getMo(type)),
		onSetFilters: (filters) => dispatch(setFilters(filters)),
		onSetDiapason: (payload) => dispatch(setDiapason(payload)),
		onSetAnaliticDiapason: (payload) => dispatch(setAnaliticDiapason(payload)),
		onSetShowEvents: (payload) => dispatch(setShowEvents(payload)),
		onSetShowHistory: (payload) => dispatch(setShowHistory(payload)),
		onSetShowHistorySlider: (payload) => dispatch(setShowHistorySlider(payload)),
		onSetSelectedRoutes: (routes) => dispatch(getSelectedRoutes(routes)),
		onGetVehicleForPopup: (deviceId) => dispatch(getVehicleForPopup(deviceId)),
		onSetTelematicsToStore: (telematics) => dispatch(setTelematics(telematics))
	})
)
export default class MapsComponent extends Component {
	_isMounted = false;

	zoomDebounce = debounce(1000, () => this.onZoomLoad);
	loadDebounce = debounce(1000, () => this.reload);

	savedSelectedVehicles = null;

	// _cycleFetch = null;

	playInterval = null;

	state = initialState;

	inited = false;

	municipalitiesUuid = '742b5146-181e-11eb-9cfe-0252e78cf96d';

	async componentWillMount() {
		if (!currentUser.can('com.rnis.geo.monitoring', 'read', this.props.params.component)) {
			this.props.router.push('/');
			return;
		}
	}

	componentWillUpdate(props, state) {
		if (
			!_.isEqual(props.owners, state.owners) ||
			!_.isEqual(props.types, state.types) ||
			!_.isEqual(props.routes, state.routes) ||
			state.component !== props.component
		) {
			this.setState({
				component: props.component,
				owners: _.cloneDeep(props.owners),
				types: _.cloneDeep(props.types),
				routes: _.cloneDeep(props.routes)
			});
			this.reloadFull();
		}
	}

	loadTelematics = (selectedVehicles) => {
		if (!this.refs.map) {
			return null;
		}
		/* if (this.props.location.query.route) {
			return;
		} */
		if (!selectedVehicles) {
			selectedVehicles = this.props.vehiclesFilter;
		}

		const boundingBox = this.refs.map.getBoundingBox();
		const mapSize = this.refs.map.getMapSize();
		let vehicles;

		if (this.state.filterResultDisplayedOnMap && !this.props.shouldShowHistory) {
			const filteredVehicles = _.map(this.props.filters.extended.result, 'uuid');
			vehicles = _.filter(this.state.vehicles, (vehicle) => {
				return _.indexOf(filteredVehicles, vehicle.uuid) !== -1;
			});
		} else {
			vehicles = Object.keys(this.state.vehicles)
				.filter((key) => selectedVehicles.includes(this.state.vehicles[key].uuid))
				.map((key) => this.state.vehicles[key]);
		}

		if (!vehicles?.length) {
			this.setState({
				mountVehiclesNull: true
			})
		}

		const cleanupSpecialistLogins = [];

		if (window.RNIS_SETTINGS.cleanup_specialist_for_task && this.props.activeLayers.cleanup_specialists) {
			cleanupSpecialistLogins.push(...this.state.cleanupSpecialists.filter((cs) => cs.login).map((cs) => cs.login));
		}

		const devices = _.map(_.sortBy(_.values(vehicles), 'state_number'), 'current_bnso.bnso_number');

		if (devices.length !== this.devicesLength) {
			this.devicesLength = devices.length;
			if (devices.length > 0){
				this.inited = true;
				const boundsPromise = this.props.getTelematicsBoundsWithDevices(devices, this.state.sourceType)();
				boundsPromise.then((response) => {
					if (response.payload.left_top.latitude !== 999) {
						const leftTop = new L.latLng(response.payload.left_top.latitude, response.payload.left_top.longitude);
						const rightBottom = new L.latLng(
							response.payload.right_bottom.latitude,
							response.payload.right_bottom.longitude
						);
						const bounds = new L.latLngBounds(leftTop, rightBottom);
						if (!isRouteA(this.props, 'from-notification')) {
							this.refs.map.fitBounds(bounds);
						}
					}
				});
			}
		}

		let parameters = {
			boundingBox,
			mapSize,
			zoom: this.refs.map.getZoom(),
			clustersActive: this.state.clustersActive
		};
		if (this.props.component === 'control') {
			parameters.time_limit = Settings.get('gatn_bnso_active_limit');
		}
		const promises = [];

		if (devices.length) {
			promises.push(requestTelematics(devices, this.state.telematics.toJS(), parameters));
		}
		if (window.RNIS_SETTINGS.cleanup_specialist_for_task && this.props.activeLayers.cleanup_specialists) {
			promises.push(
				requestTelematics(cleanupSpecialistLogins, this.state.cleanupSpecialistsTelematics.toJS(), parameters)
			);
		}

		const promise = Promise.all(promises);

		promise
			.then(
				(responses) => {
					let telematics = {};
					let cleanupSpecialistsTelematics = {};
					let clusters = [];

					responses.forEach((response, index) => {
						_.each(_.get(response, 'payload.items', []), (item) => {
							const latitude = item[0];
							const longitude = item[1];
							const type = item[2];
							let bounds;

							if (type === 'cluster') {
								const info = item[3];
								if (item[4]) {
									// if bounds in response
									bounds = item[4].bounds;
								}
								clusters.push([ latitude, longitude, info, bounds ]);
							} else {
								const deviceId = item[3];
								const course = item[4];
								const speed = item[5];
								const type = index === 0 ? item[6] : 'cleanup';
								const time = item[7];
								const animationHistoryData = null;

								if (index === 0) {
									telematics[deviceId] = [
										latitude,
										longitude,
										course,
										speed,
										type,
										time,
										animationHistoryData,
										item[8],
										item[9],
										item[10],
										item[11],
										item[12],
										item[13]
									];
								} else {
									cleanupSpecialistsTelematics[deviceId] = [
										latitude,
										longitude,
										course,
										speed,
										type,
										time,
										animationHistoryData,
										item[8],
										item[9],
										item[10],
										item[11],
										item[12],
										item[13]
									];
								}
							}
						});
					});
					this.setState({
						telematics: new Map(telematics),
						clusters,
						loading: false,
						cleanupSpecialistsTelematics: new Map(cleanupSpecialistsTelematics)
					});
				},
				() => {
					this.setState({ loading: false });
				}
			)
			.catch((err) => console.error(err));

		// return promise;
	};

	componentWillUnmount() {
		setTimeout(() => {
			if (this?.refs) {
				const currentMap = this?.refs?.map?.refs?.map?.getWrappedInstance().map;
				if (currentMap) {
					currentMap.eachLayer(function (layer) {
						if (
							(layer.feature && layer.feature.geometry && layer.feature.geometry.type === 'Polygon') ||
							(layer.feature && layer.feature.geometry && layer.feature.geometry.type === 'LineString')
						) {
							currentMap.removeLayer(layer);
						}
					});
				}
			}
		}, 100);
		// this._cycleFetch.stop();
		// delete this['_cycleFetch'];
		// clear history slider on leave page
		clearInterval(this.interval);
		this.props.onSetComponent(null);
		this._isMounted = false;
		this.props.onSetShowHistory(false);
		this.props.onSetShowHistorySlider(false);
	}

	componentWillUpdate(props, state) {
		if (isRouteA(props, 'from-notification')) {
			if (!this.state.notificationInited) {
				this.setState({
					notificationInited: true
				});

				this.loadNotification();
			}
		} else if (isRouteA(props, 'from-order')) {
			if (!this.state.orderInited) {
				this.setState({
					orderInited: true
				});

				this.loadOrder();
			}
		} else if (this.state.notificationInited) {
			this.setState({
				notificationInited: false,
				notification: null
			});
		} else if (this.state.orderInited) {
			this.setState({
				orderInited: false,
				order: null
			});
		}

		if (props.params.component !== props.component && this.props.original_component !== 'operator') {
			const component = props.params.component;
			this.props.onSetComponent(component);
			this.init();
		}
	}

	async loadNotification() {
		const response = await this.props.getNotification(this.props.params.uuid)();

		if (response.isOk) {
			const notification = response.payload;
			notification.vehicle = await this.loadVehicle(notification.data.vehicle_uuid);

			const createdAt = moment(notification.created_at);
			const showHistory = createdAt.isBefore(moment().subtract(60, 'minutes'));

			let filters = this.props.filters;
			_.set(filters, 'base.vehicle.vehicles', [ notification.data.vehicle_uuid ]);
			this.props.onSetFilters(filters);

			const leftTop = new L.latLng(notification.data.latitude, notification.data.longitude);
			const rightBottom = new L.latLng(notification.data.latitude, notification.data.longitude);
			const bounds = new L.latLngBounds(leftTop, rightBottom);

			this.refs.map.fitBounds(bounds);
			this.handleDiapasonChange(
				'from_date',
				showHistory ? createdAt.subtract(30, 'minutes') : this.props.diapason.from_date
			);
			this.handleDiapasonChange(
				'from_time',
				showHistory ? createdAt.subtract(30, 'minutes').format(formats.TIME) : this.props.diapason.from_time
			);
			this.handleDiapasonChange('to_date', showHistory ? createdAt.add(30, 'minutes') : this.props.diapason.to_date);
			this.handleDiapasonChange(
				'to_time',
				showHistory ? createdAt.add(30, 'minutes').format(formats.TIME) : this.props.diapason.to_time
			);
			this.props.onSetShowHistory(showHistory);
			await this.setState({
				notification
			});
			if (showHistory) {
				this.loadHistory();
			}
		} else {
			// response.showErrors();
		}
	}

	async loadOrder() {
		const response = await this.props.getOrder(this.props.params.uuid)();

		if (response.isOk) {
			const order = response.payload;

			let filters = this.props.filters;
			_.set(filters, 'base.vehicle.vehicles', [ order.vehicle_uuid ]);
			_.set(filters, 'base.vehicle.owners', [ order.unit_uuid ]);
			_.set(filters, 'base.routes', [ order.route_uuid ]);
			this.props.onSetFilters(filters);

			await this.setState({
				order
			});

			// this.props.onGetRoute(this.props.location.query.route || order.route_uuid);

			// focusOnRoutes(this.props.routes, this.refs.map.refs.map.getWrappedInstance().fitBounds);
		} else {
			response.showErrors();
		}
	}

	async loadVehicle(uuid) {
		const response = await this.props.getVehicle(uuid)();

		if (response.isOk) {
			return response.payload;
		}
		return {};
	}

	async componentDidMount() {
		this._isMounted = true;
		if (this.props.params.component === 'kiutr') {
			await this.loadVehicles(null, this.props.params.component);
		}
		this.interval = setInterval(() => this.loadTelematics(), currentSpeed);
		const { date_from, date_to, work_type, communal } = this.props.location.query;
		if (date_from || date_to) {
			// set dates from query TODO: pickout dates to redux
			this.handleDiapasonChange('from_date', moment(this.props.location.query['date_from']) || moment());
			this.handleDiapasonChange('to_date', moment(this.props.location.query['date_to']) || moment());
		}
		if (work_type) {
			this.setState({ selected_work_type: work_type });
		}
		if (communal) {
			this.setState({ selected_communal_uuid: communal });
		}
		if (this.props.params.uuid) {
			this.loadOrder();
		}
		if (this.props.params.routeUuid) {
			let filters = this.props.filters;
			_.set(filters, 'base.routes', [ this.props.params.routeUuid ]);
			this.props.onSetFilters(filters);
			this.props.onGetRoute(this.props.params.routeUuid);
			this.props.onSetSelectedRoutes([this.props.params.routeUuid]);
		}
		if (this.props.location.query.route) {
			this.props.onGetRoute(this.props.location.query.route);
			if (this.props.route) {
				// fit bound on route
				const pointsLength = this.props.route.default_variant.forward_points.length;
				const points = this.props.route.default_variant.forward_points;
				const bounds = [
					[ points[0].latitude, points[0].longitude ],
					[ points[pointsLength - 1].latitude, points[pointsLength - 1].longitude ]
				];
				this.refs.map.fitBounds(bounds, [ 50, 50 ]);
			}
		}
		this.setUpVehiclePopup();
		this.handleGetUnits();
		// this.props.onGetRoute(this.props.location.query.route || order.route_uuid);
	}

	async getColorsForRoadParts() {
		const { communal, work_type } = this.props.location.query;
		const body = {
			headers: {
				meta: {
					filters: {
						withPeriod: [moment(this.props.location.query.date_from).format(formats.DATE_API),
							moment(this.props.location.query.date_to).format(formats.DATE_API)],
						withCommunalMunicipalities: communal,
						withWorkTypes: work_type
					}
				},
				token: JSON.parse(localStorage.getItem("user")).token
			},
			payload: {}
		}
		const response = await fetch('https://smut.t1-group.ru/ajax/request?com.rnis.kurs.action.analytics.objects.colour.list', {
			method: "POST",
			body: JSON.stringify(body),
		}).then(response => response.json()).then(json => {return json});

		if (response.success) {
			const tasksUuids = [];

			for (let key in response.payload) {
				const arrayItems = response.payload[key];
				tasksUuids.push(arrayItems.map(el => el.task_uuid))
			}

			const uniqueTasksUuids = _.uniq(tasksUuids.flat(1))

			const roadPartsGreen = response.payload.green.map(el => el.uuid)
			const roadPartsGray = response.payload.gray.map(el => el.uuid)
			const roadPartsRed = response.payload.red.map(el => el.uuid)
			const roadPartsYellow = response.payload.yellow.map(el => el.uuid)

			this.setState({
				uniqueTasksUuids: uniqueTasksUuids,
				colors: {roadPartsGreen, roadPartsRed, roadPartsYellow, roadPartsGray}
			})
		}
	}

	async componentDidUpdate(prevProps, prevState, snapshot) {
		const previousUrl = localStorage.getItem('previousUrl') ? localStorage.getItem('previousUrl') : null;

		// следующий кусок кода нужен, чтобы заново init вызвать, если мы вернулись с экрана /settings/road/map
		// так как все написано так, что правильно componentDidMount не работает, и это обходное решение
		// через сохранение прошлого URL или текущего, если не успел записаться прошлый
		// также здесь проверка на то, что в loadTelematics поле vehicles пустое, что указывает на то,
		// что запроса на vehicles не было и телематика не загрузится
		if (previousUrl && previousUrl.includes('road/map')) {
			if (this.state.mountVehiclesNull) {
				this.init();
				localStorage.removeItem('previousUrl');
			}
		}

		if (previousUrl && previousUrl.includes('executedworks')) {
			localStorage.removeItem('previousUrl');
			const { communal, work_type } = this.props.location.query;

			if (communal && work_type) {
				this.setState({
					selected_communal_uuid: communal,
					selected_work_type: work_type ? work_type : 'all',
					initialUpdate: false
				});

				if (this.props.component === 'analytics') {
					if (location.pathname.includes('roadparts')) {
						this.loadMuRoadParts();
					}
				}
			} else {
				this.loadMu();
			}
		}

		if (prevProps.location.pathname !== this.props.location.pathname) {
			if (!location.pathname.includes("analytics")) {
				setTimeout(() => {
					clearInterval(updatePathInterval)
				}, 7000);
				const updatePathInterval = setInterval(() => {
					console.info("DidUpdate not analytics and setInterval");
					const currentMap = this.refs.map.refs.map.getWrappedInstance().map;
					currentMap.eachLayer(function(layer) {
						if (
							(layer.feature && layer.feature.geometry && layer.feature.geometry.type === 'Polygon') ||
							(layer.feature && layer.feature.geometry && layer.feature.geometry.type === 'LineString')
						) {
							currentMap.removeLayer(layer);
						}
					});
				}, 35);
			}
			setTimeout(() => {
				const currentMap = this.refs.map.refs.map.getWrappedInstance().map;
				currentMap.eachLayer(function(layer) {
					if (
						(layer.feature && layer.feature.geometry && layer.feature.geometry.type === 'Polygon') ||
						(layer.feature && layer.feature.geometry && layer.feature.geometry.type === 'LineString')
					) {
						currentMap.removeLayer(layer);
					}
				});
			}, 100);

			const { communal, work_type } = this.props.location.query;

			if (communal && work_type && this.state.initialUpdate) {
				this.props.getLayers().then((res) => {
					if (res) {
						setTimeout(() => {
							this.setState({
								selected_communal_uuid: communal,
								selected_work_type: work_type ? work_type : 'all',
								initialUpdate: false
							});

							if (this.props.component === 'analytics') {
								if (location.pathname.includes('roadparts')) {
									this.loadMuRoadParts();
								}
							}
						}, 2000);
					}
				});

				if (this.props.component === 'analytics') {
					if (location.pathname.includes('roadparts')) {
						this.loadMuRoadParts();
					}
				}
			}
		}
	}

	setUpVehiclePopup = () => {
		const userUuid = storage.get('user').uuid;
		const userSettings = storage.get('users_settings');
		const vehiclePopupState =
			userSettings !== null ? userSettings[userUuid]['map:vehicle:popup:' + this.props.component] : null;
		if (vehiclePopupState) {
			// set vehicle popup state from users localStorage if exists
			this.setState({ vehicle_popup: vehiclePopupState });
			storage.save('map:vehicle:popup:kiutr', vehiclePopupState); //save user setting to localStorage
		} else {
			// set vehicle popup state from defaults
			const capacity = isKiutrOrChildren(this.props.component);
			const defaultVehiclePopupState = vehiclePopupDefaultValues(capacity);
			const vehicle_popup = storage.get('map:vehicle:popup:' + this.props.component, defaultVehiclePopupState);

			this.setState({
				vehicle_popup
			});
		}
	};

	async init() {
		this.inited = false;
		this.devicesLength = 0;
		delete initialState.component;
		await this.setState(initialState);

		this.props.onSetFilters(filters);
		// this.onFiltersReset();
		setTimeout(() => {
			this.refs.map.invalidateSize();
		}, 200);

		this.setUpVehiclePopup();

		this.props.onGetDictionaries({ dictionaries, component: this.props.component });

		this.props.onGetBnsoList();

		this.handleGetUnits();
		await this.loadVehicles();

		if (window.RNIS_SETTINGS.cleanup_specialist_for_task) {
			this.loadCleanupSpecialist();
		}

		if (this.props.component !== 'kiutr') {
			this.props.onGetDictionaries({
				dictionaries: [ 'work_types', 'kurs_task_statuses' ],
				component: this.props.component
			});
		}

		if (this.props.component === 'control') {
			this.props.onGetDictionaries({ dictionaries: [ 'gatn_responsives' ], component: this.props.component });
		}

		if (this.props.component === 'road') {
			this.props.onGetDictionaries({
				dictionaries: [ 'work_types', 'kurs_mechanism_bindings', 'kurs_mechanism_types' ],
				component: this.props.component
			});
		}
		if (this.props.component !== 'road' && this.props.component !== 'commdept') {
			this.props.onGetCarrierUnits();
		}

		if (this.props.component === 'analytics') {
			this.loadMunicapalities();
			if (location.pathname.includes('roadparts')) {
				this.loadMuRoadParts();
			}
			if (location.pathname.includes('executedworks')) {
				this.loadMu();
			} else {
				this.props.onGetMo();
				this.loadMunicipalities();
			}
			this.loadViolationsKiutr();
		}
	}

	handleGetUnits = (operatorComponents) =>
		this.props.onGetUnits({
			component: this.props.original_component === 'operator' ? operatorComponents : [ this.props.component ] // load filtered Units if on operator page
		});

	async getViolationsDetails(item) {
		if (!this.state.reportsByCommunal) {
			let current = null;
			const filteredByMunicipality = this.state.reportsDetails.filter(
				(obj) => obj.communal_municipality_uuid === item.uuid
			);
			current = this.agregateViolationsKiutrDeviationStats(filteredByMunicipality);
			await this.setState({
				isOpenDetailization: !this.state.isOpenDetailization,
				reportsByCommunal: current,
				reportsSelectedItem: item.uuid
			});
		} else {
			await this.setState({
				isOpenDetailization: !this.state.isOpenDetailization
			});
		}
		if (this.state.isOpenDetailization) {
			setTimeout(() => {
				$('.block-slide').removeClass('block-slide_closed').addClass('block-slide_opened');
			}, 200);
		} else {
			setTimeout(() => {
				$('.block-slide').removeClass('block-slide_opened').addClass('block-slide_closed');
			}, 200);
		}
	}

	agregateViolationsKiutrDeviationStats(data) {
		const uniqRoad_uuid = _.uniq(data.map((item) => item.route_uuid));

		return uniqRoad_uuid.map((uniqUuid) => {
			const all = data.filter((item) => {
				return item.route_uuid === uniqUuid;
			});
			const result = all.reduce((acc, val) => {
				const deviation = {
					schedule: acc.deviation.schedule + val.deviation.schedule,
					route: acc.deviation.route + val.deviation.route
				};
				acc.deviation = deviation;
				return acc;
			});
			return result;
		});
	}

	async loadViolationsKiutr() {
		await this.setState({
			reportsDetails: null,
			reportsByCommunal: null
		});
		const payload = {
			date_from: this.props.analyticDiapason.from_analytic.format(formats.DATETIME_API),
			date_to: this.props.analyticDiapason.to_analytic.format(formats.DATETIME_API),
			component: 'kiutr',
			type: 'passenger'
		};
		const response = await this.props.onGetReportsViolations(payload);
		if (response.isOk) {
			/* обновить содержимое RenderMoPopup, только когда он открыт и после смены даты! */
			/* иначе просто подготовить данные для агрегации уже при открытии popup */
			if (this.state.isOpenDetailization) {
				let current = null;
				const filteredByMunicipality = response.payload.municipalities.filter(
					(obj) => obj.communal_municipality_uuid === this.state.reportsSelectedItem
				);
				current = this.agregateViolationsKiutrDeviationStats(filteredByMunicipality);
				this.setState({
					reportsDetails: response.payload.municipalities,
					reportsByCommunal: current
				});
			} else {
				this.setState({
					reportsDetails: response.payload.municipalities
				});
			}
		} else {
			response.showErrors();
		}
	}

	loadMunicapalities() {
		this.props.getLayers();
		this.props.getLayerObjects(this.municipalitiesUuid); // uuid Муниципальные образования
	}

	loadMunicipalities() {
		this.props.onGetMunicipalitiesKiutrSummary(this.state.filterAnalyticType);
	}

	renderMo() {
		// pick out to additional component
		return _.map(this.props.mo, (item) => (
			<MapGeojson
				key={item.uuid}
				map={this.refs.map.refs.map}
				leafletMap={this.refs.map.refs.map.getWrappedInstance().map}
				geometry={item.geometry}
				popup={(onClose) =>
					renderMoPopup(
						item,
						onClose,
						this.props.municipalities,
						this.props.router.push,
						this.state.filterAnalyticType,
						this.state.isOpenDetailization,
						this.state.reportsByCommunal,
						this.getViolationsDetails.bind(this)
					)}
				tooltip={renderMoTooltip(item)}
			/>
		));
	}

	async getWorksDoneByMunicipalityUuid(communal_municipality_uuid) {
		const meta = {
			filters: {
				withPeriod: [
					moment(this.props.location.query.date_from).format(formats.DATE_API),
					moment(this.props.location.query.date_to).format(formats.DATE_API)
				]
			}
		};

		if (communal_municipality_uuid !== 'all') {
			meta.filters.withCommunalMunicipalities = [ communal_municipality_uuid ];
		}

		if (this.state.selected_communal_uuid !== 'all' && this.state.selected_communal_uuid) {
			meta.filters.withCommunalMunicipalities = [ this.state.selected_communal_uuid ];
		}

		if (this.state.selected_work_type !== 'all') {
			meta.filters.withWorkTypes = [ this.state.selected_work_type ];
		}
		if (window.RNIS_SETTINGS.CITY_MURMANSK) {
			 const response = await this.props.getTabletWorkSummaryPercent(meta);
			if (response.isOk) {
				return response.payload;
			} else {
				response.showErrors();
			}
		} else {
			return await this.props.getTabletWorkSummary(meta);
		}

	}

	async getGeometryFromContract() {
		const meta = {
			filters: {
				withPeriod: [
					moment(this.props.location.query.date_from).format(formats.DATE_API),
					moment(this.props.location.query.date_to).format(formats.DATE_API)
				]
			}
		};
		if (this.state.selected_work_type !== 'all') {
			meta.filters.withWorkTypes = [ this.state.selected_work_type ];
		}

		if (this.state.selected_communal_uuid && this.state.selected_communal_uuid !== 'all') {
			meta.filters.withCommunalMunicipalities = [ this.state.selected_communal_uuid ];
		} else if (this.props.location.query.communal) {
			meta.filters.withCommunalMunicipalities = [ this.props.location.query.communal ];
		}

		const response = await this.props.getGeometryFromContract(meta)();
		let contracts = {};
		if (response.isOk) {
			if (response.payload && response.payload.items.length) {
				response.payload.items.forEach((itemContract) => {
					contracts[itemContract.uuid] = itemContract.contracts;
				});
				const bluePolygonsRoadPartUuid = _.uniq(response.payload.items.map((item) => item.uuid));
				await this.setState({
					bluePolygonsRoadPartUuid,
					contracts: contracts,
					contractsToRender: response.payload.items
				});
			}
		}
	}

	async loadMuRoadParts() {
		localStorage.removeItem('renderMuRoadParts');
		await this.setState({
			muTasksObjects: null,
			computedGeometry: null,
			contractsToRender: null
		});
		await this.getColorsForRoadParts();
		await this.getGeometryFromContract();
		let muTasksObjects = null;
		let localFilters = {
			withUuid: this.state.uniqueTasksUuids,
			withPeriod: [
				moment(this.props.location.query.date_from).format(formats.DATE_API),
				moment(this.props.location.query.date_to).format(formats.DATE_API)
			]
		};
		if (this.state.selected_communal_uuid && this.state.selected_communal_uuid !== 'all') {
			localFilters.withCommunalMunicipalities = [ this.state.selected_communal_uuid ];
		}
		if (this.state.selected_work_type && this.state.selected_work_type !== 'all') {
			localFilters.withWorkTypes = [ this.state.selected_work_type ];
		}

		const responseTasks = await this.props.onGetTasks({
			filters: {
				...localFilters
			}
		});
		if (responseTasks.isOk) {
			console.info("RoadParts: ", responseTasks.payload.items.map(item => item.number))
			muTasksObjects = responseTasks.payload.items;
		} else {
			responseTasks.showErrors();
		}

		this.setState({
			muTasksObjects,
		});
		
	}

	getLayerArea(items) {
		items.map(item => {
			const coordinates = item.geometry.coordinates[0];
			let total = 0;
			let prevX = coordinates[0][0];
			let prevY = coordinates[0][1];

			coordinates.slice(1).map(xy => {
				total += prevX * xy[1] - prevY * xy[0];
				prevX = xy[0];
				prevY = xy[1];
			});

			item.geometry._area = Math.abs(total / 2);
		});
		items.sort((a, b) => b.geometry._area - a.geometry._area);
	}

	async loadMu(type) {
		if (!this.state.mu) {
			const response = await this.props.getLayerObjects(this.municipalitiesUuid);
			if (response.isOk) {
				this.getLayerArea(response.payload.items);
				const withCommunal = filteredByCommunalUuid(response.payload.items, true);
				const withoutCommunal = filteredByCommunalUuid(response.payload.items, false);
				const communalPromises = withCommunal.map((itemMu) => {
					const result = this.getWorksDoneByMunicipalityUuid(itemMu.communal_municipality_uuid);
					return result.then((works) => {
						if (window.RNIS_SETTINGS.CITY_MURMANSK) {
							if (works.municipalities.length > 0) {
								itemMu.percent = works.municipalities[0].percent
							}
						}else {
							const isNeedByJobUniqueCalculate = true;
							const percent = getPercentForWorksDone(works.payload.items, itemMu.title, isNeedByJobUniqueCalculate);
							itemMu.percent = _.round(percent, 0);
						}
						return itemMu;
					});
				});
				const resultWithCommunal = await Promise.all(communalPromises);
				await this.setState({
					mu: [ ...resultWithCommunal, ...withoutCommunal ]
				});
			} else {
				response.showErrors();
			}
		}
	}

	handleToggleFaq = () => {
		this.setState({
			faqOpened: !this.state.faqOpened
		});
	};

	async handlePolygonClick(polygon) {
		const date_from = moment(this.props.diapason.from_date).format(formats.DATE_URL);
		const date_to = moment(this.props.diapason.to_date).format(formats.DATE_URL);
		if (this.state.selected_work_type === 'all') {
			await this.setState({
				mu: null,
				muTasksObjects: null,
				computedGeometry: null,
				initialUpdate: true
			});
			if (polygon.communal_municipality_uuid) {
				this.props.router.push(
					`/analytics/executedworks/${polygon.communal_municipality_uuid}?date_from=${date_from}&date_to=${date_to}`
				);
			} else {
				this.props.router.push(`/analytics/executedworks/?date_from=${date_from}&date_to=${date_to}`);
			}
		} else {
			const workTypeUuid = this.state.selected_work_type;
			await this.setState({
				mu: null,
				muTasksObjects: null,
				computedGeometry: null,
				initialUpdate: true
			});
			if (workTypeUuid) {
				this.props.router.push(
					`/analytics/roadparts/map?communal=${polygon.communal_municipality_uuid}&work_type=${workTypeUuid}&date_from=${date_from}&date_to=${date_to}`
				);
			}
		}
	}

	handlePolygonHover(polygon) {
		this.setState({
			defaultPolygonStyle: polygon.target.options.style()
		});
		polygon.target.setStyle({
			fillColor: '#FFFFFF',
			fillOpacity: '0.75'
		});
		polygon.target.eachLayer((layer) => {
			if (layer._content) {
				layer._contentNode.classList.value = 'leaflet-tooltip-own--black';
			}
		});
	}

	handlePolygonHoverOut(polygon) {
		polygon.target.setStyle({
			fillColor: this.state.defaultPolygonStyle.color,
			fillOpacity: this.state.defaultPolygonStyle.opacity - 0.1
		});
		polygon.target.eachLayer((layer) => {
			if (layer._content) {
				layer._contentNode.classList.value =
					'leaflet-tooltip leaflet-tooltip-own leaflet-zoom-animated leaflet-tooltip-center';
			}
		});
	}

	renderMuRoadPartsPopup(tasks, roadUuid) {
		if (this.state.contracts && this.state.contracts[roadUuid]) {
			const { name, uuid } = this.state.contracts[roadUuid];
			return (
				<div className="road-modal">
					<a href={`https://smut.t1-group.ru/road/contracts/${uuid}`}>{name}</a>
				</div>
			);
		} else {
			tasks = _.uniqBy(tasks, 'uuid');
			return (
				<div className="mu-road-parts-popup">
					<table className="b-table order-column dataTable no-footer">
						<thead>
							<tr>
								<td>Номер задания</td>
								<td>Дата</td>
							</tr>
						</thead>
						<tbody>
							{tasks &&
								tasks.map((task, index) => (
									<tr key={task.uuid + index}>
										<td>
											<Link className="link-in-table" href={`/road/tasks/${task.uuid}`}>
												{task.number}
											</Link>
										</td>
										<td>
											<Link className="link-in-table" href={`/road/tasks/${task.uuid}`}>
												{new Date(task.date).toLocaleDateString()}
											</Link>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			);
		}
	}

	renderMuContracts() {
		if (!localStorage.getItem('renderMuContracts')) {
			const geometry = this.state.contractsToRender.map((item, key) => {
				return (
					<MapGeojson
						color={'blue'}
						key={_.uniqueId(key)}
						map={this.refs.map.refs.map}
						leafletMap={this.refs.map.refs.map.getWrappedInstance().map}
						geometry={item.geometry}
						popup={this.getPopupContract.bind(this, item)}
						popupOffset={0}
						popupWidth={350}
					/>
				);
			});
			localStorage.setItem('renderMuContracts', true);
			this.setState({
				computedGeometry: geometry
			});
			return geometry;
		} else {
			return this.state.computedGeometry;
		}
	}

	getPopupContract(item, onClose) {
		if (item.uuid in this.state.groupsTasksByGeometryItem) {
			const tasks = _.uniqBy(this.state.groupsTasksByGeometryItem[item.uuid], 'uuid');
			return (
				<div className="mu-road-parts-popup">
					<table className="b-table order-column dataTable no-footer">
						<thead>
							<tr>
								<td>Номер задания</td>
								<td>Дата</td>
							</tr>
						</thead>
						<tbody>
							{tasks &&
								tasks.map((task, index) => (
									<tr key={task.uuid + index}>
										<td>
											<Link className="link-in-table" href={`/road/tasks/${task.uuid}`}>
												{task.number}
											</Link>
										</td>
										<td>
											<Link className="link-in-table" href={`/road/tasks/${task.uuid}`}>
												{new Date(task.date).toLocaleDateString()}
											</Link>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			);
		} else {
			return (
				<div className="road-modal">
					<ul>
						{item.contracts.map((contract, index) => {
							return (
								<li key={contract.name + index}>
									<a href={`https://smut.t1-group.ru/road/contracts/${contract.uuid}`}>{contract.name}</a>
								</li>
							);
						})}
					</ul>
				</div>
			);
		}
	}

	calculateColor(road_part_uuid) {
		if (this.state.colors.roadPartsGray.includes(road_part_uuid)) {
			return 'gray'
		}
		if (this.state.colors.roadPartsYellow.includes(road_part_uuid)) {
			return 'yellow'
		}
		if (this.state.colors.roadPartsRed.includes(road_part_uuid)) {
			return 'red'
		}
		if (this.state.colors.roadPartsGreen.includes(road_part_uuid)) {
			return 'green'
		}
		if (this.state.bluePolygonsRoadPartUuid.includes(road_part_uuid)) {
			return 'blue'
		}
	}

	renderMuRoadParts() {
		if (!localStorage.getItem('renderMuRoadParts')) {
		console.info("Debug: Рендерим участки дорог");
		const tasksObjects = this.state.muTasksObjects;
		let roads = [];

		const groupsTasksByGeometryItem = {};
		let contractsGeometry = []

		if(this.state.contractsToRender && this.state.contractsToRender.length) {
			contractsGeometry = this.state.contractsToRender.map((contract, key) => {
				const color = this.calculateColor(contract.uuid);
				if (contract.geometry) {
					return (
						<MapGeojson
							color={color}
							opacity={color === 'blue' && contract.geometry.type === 'Polygon' ? 0.6 : 1}
							key={_.uniqueId(`road_${_.uniqueId('prefix')}`)}
							map={this.refs.map.refs.map}
							leafletMap={this.refs.map.refs.map.getWrappedInstance().map}
							geometry={contract.geometry}
							popup={this.getPopupContract.bind(this, contract)}
							popupOffset={0}
							popupWidth={350}
						/>
					);
				} else {
					return null;
				}
			});
		}

		tasksObjects.forEach(task => {
			const items = task.items;
			const itemsFact = task.items_fact ? task.items_fact : [];

			items.forEach((item, index) => {
				if (item.geometry && item.geojson && item.geometry[0].item_uuid) {
					if (!groupsTasksByGeometryItem[item.geometry[0].item_uuid]) {
						groupsTasksByGeometryItem[item.geometry[0].item_uuid] = [task];
					} else {
						if (groupsTasksByGeometryItem[item.geometry[0].item_uuid].findIndex((t) => t.uuid === task.uid) < 0) {
							groupsTasksByGeometryItem[item.geometry[0].item_uuid].push(task);
						}
					}
				roads.push(
					<MapGeojson
							key={_.uniqueId(`road_${_.uniqueId('prefix')}`)}
							color={this.calculateColor(item.geometry[0].item_uuid)}
							opacity={1}
							map={this.refs.map.refs.map}
							leafletMap={this.refs.map.refs.map.getWrappedInstance().map}
							geometry={item.geojson}
							popup={this.renderMuRoadPartsPopup.bind(
								this,
								groupsTasksByGeometryItem[item.geometry[0].item_uuid],
								item.geometry[0].item_uuid
							)}
							popupOffset={0}
							popupWidth={350}

						/>

				)
				}
			})
			if (itemsFact.length) {
				itemsFact.forEach((item, index) => {
					if (item.geometry && item.geojson && item.geometry[0].item_uuid) {
						roads.push(
							<MapGeojson
									key={_.uniqueId(`road_${_.uniqueId('prefix')}`)}
									color={this.calculateColor(item.geometry[0].item_uuid)}
									opacity={1}
									map={this.refs.map.refs.map}
									leafletMap={this.refs.map.refs.map.getWrappedInstance().map}
									geometry={item.geojson}
									popup={this.renderMuRoadPartsPopup.bind(
										this,
										groupsTasksByGeometryItem[item.geometry[0].item_uuid],
										item.geometry[0].item_uuid
									)}
									popupOffset={0}
									popupWidth={350}
								/>)
					}
				})
			}
		});

		this.setState({
			groupsTasksByGeometryItem,
			computedGeometry: [...roads, ...contractsGeometry]
		});
		localStorage.setItem('renderMuRoadParts', true);
		return [...roads, ...contractsGeometry];
		} else {
			return this.state.computedGeometry;
		}
	}

	renderMu() {
		return _.map(this.state.mu, (item, index) => {
			return (
				<MapGeojson
					bringToBack={item.geometry.coordinates[0].length > 200 ? true : false}
					onClick={() => this.handlePolygonClick(item)}
					onMouseOver={(e) => this.handlePolygonHover(e)}
					onMouseOut={(e) => this.handlePolygonHoverOut(e)}
					key={item.uuid}
					map={this.refs.map.refs.map}
					leafletMap={this.refs.map.refs.map.getWrappedInstance().map}
					geometry={item.geometry}
					tooltip={`<h3 style="text-align: center">${item.title} </h3><p>${item.org_name
						? item.org_name
						: ''}</p> <p>${item.phone ? item.phone : ''}</p> <p>${item.email ? item.email : ''}</p>`}
					label={item.percent ? `<span style="font-size: 19px">${item.percent} %</span>` : null}
				/>
			);
		});
	}

	async loadCleanupSpecialist() {
		const response = await this.props.getUsers({
			filters: {
				withComponent: 'road',
				withPositionTypes: [ 'worker' ]
			},
			pagination: {
				page: 1,
				limit: 100000
			}
		})();
		if (response.isOk) {
			this.setState({
				cleanupSpecialists: response.payload.items
			});
		} else {
			response.showErrors();
		}
	}

	async loadVehicles(operatorComponents, componentArgument) {
		const component = component_mapper(this.props.component);

		if (component !== 'operator') {
			vehicleListFilters.withComponent = componentArgument || component;
		}
		if (this.props.original_component === 'operator') {
			vehicleListFilters.withComponent = this.props.location.query.component || operatorComponents; // load vehicles only with choosen components from query or arguments
		}
		if (this.props.location.query.bnso) {
			vehicleListFilters.withCurrentBnsoNumber = [ this.props.location.query.bnso ];
			// для проверки, а есть ли вообще такой vehicle по bnso_number без ограничений
			// это нужно только для отладки/проверки !!!
			/* delete vehicleListFilters.onlyApproved;
			delete vehicleListFilters.short;
			delete vehicleListFilters.withActiveBnso; */
		}

		let response = await this.props.getVehicleList({
			pagination: getPagination(1),
			response_data: [ 'items/uuid', 'items/state_number', 'items/current_bnso', 'items/unit_uuid' ],
			debugProperty: 'For Vehicle List On Main Map',
			filters: vehicleListFilters
		})();

		if (response.isOk) {
			const vehicles = _.mapValues(_.keyBy(response.payload.items, 'current_bnso.bnso_number'), (vehicle) => {
				vehicle.onEditClick = this.onVehicleEditClick;
				vehicle.onFuelClick = this.onVehicleFuelClick;
				return vehicle;
			});

			this.setState({
				vehicles,
				vehiclesBnso: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'current_bnso.bnso_number')
			});

			const selectedVehicles = storage.get(`map:vehicles:${this.props.component}`);
			if (_.isArray(selectedVehicles)) {
				this.onFiltersChange('base.vehicle.vehicles', selectedVehicles, true);
			}
			this.loadOrderExecutions();
			this.loadTasks();
			this.loadTelematics();
			if (this.props.location.query.vehicles) {
				// load history if vehicles in url
				const newFilters = this.props.filters;

				newFilters.base.vehicle.vehicles = this.props.location.query.vehicles.split(',');
				this.props.onSetFilters(newFilters);
				this.props.onSetDiapason({ field: 'from_date', value: this.props.location.query.from_time.split('T')[0] });
				this.props.onSetDiapason({ field: 'to_date', value: this.props.location.query.to_time.split('T')[0] });
				this.props.onSetDiapason({
					field: 'from_time',
					value: this.props.location.query.from_time.split('T')[1].slice(0, 5)
				});
				this.props.onSetDiapason({
					field: 'to_time',
					value: this.props.location.query.to_time.split('T')[1].slice(0, 5)
				});

				this.loadHistory(null, null, 'vehicle', newFilters.base.vehicle.vehicles);
			}

			if (this.props.location.query.bnso) {
				console.info('Искать', this.props.location.query.bnso, 'В: ', vehicles);
				const newFilters = this.props.filters;
				newFilters.base.vehicle.vehicles = vehicles[this.props.location.query.bnso]
					? [ vehicles[this.props.location.query.bnso].uuid ]
					: [];

				this.props.onSetFilters(newFilters);
			}
			// this._cycleFetch && this._cycleFetch.forceNext();
		} else {
			response.showErrors();
			this.setState({ loading: false });
		}
	}

	handleForceNextCycle = (selectedVehicles, isDeselectAll) => {
		if (isDeselectAll) {
			this.setState({ telematics: new Map() });
			this.onFiltersChange('base.vehicle.vehicles', [], true);
			this.loadTelematics([]);
		} else {
			this.onFiltersChange('base.vehicle.vehicles', selectedVehicles, true);
			this.loadTelematics(selectedVehicles);
		}
	};

	onVehicleEditClick = (vehicle) => {
		this.setState({
			vehicleEditUuid: vehicle.uuid
		});
	};

	onVehicleCloseClick = (reload = false) => {
		this.setState({
			vehicleEditUuid: null
		});
		if (reload) {
			this.loadVehicles();
		}
	};

	onVehicleFuelClick(vehicle) {
		this.setState({
			vehicleFuelUuid: vehicle.uuid
		});
	}

	onVehicleFuelCloseClick() {
		this.setState({
			vehicleFuelUuid: null
		});
	}

	reload = () => {
		if (this.props.shouldShowHistory) {
			// this._cycleFetch.stop();
			this.clearTelematics();
		} else {
			this.clearHistory();
			this.loadTelematics();
			// this._cycleFetch.run();
			// this._cycleFetch.forceNext();
		}
	};

	async loadHistory(from_time_arg, to_time_arg, type = 'vehicle', vehicles) {
		let from_time;
		let to_time;
		let cleanupSpecialist;
		let selectedDevices = [];
		let selectedVehicles;

		if (from_time_arg && to_time_arg) {
			from_time = from_time_arg;
			to_time = to_time_arg;
		} else {
			from_time = this.props.diapason.from_time;
			to_time = this.props.diapason.to_time;
		}

		switch (type) {
			case 'cleanup_specialist': {
				cleanupSpecialist = _.first(this.props.filters.base.cleanupSpecialists);
				if (
					!(
						this.props.diapason.from_date &&
						this.props.diapason.from_time &&
						this.props.diapason.to_date &&
						this.props.diapason.to_time &&
						cleanupSpecialist
					)
				) {
					return;
				}
				break;
			}
			default: {
				selectedVehicles = vehicles || this.props.vehiclesFilter; //get from func args or redux store
				Object.keys(this.state.vehicles).forEach((key) => {
					if (selectedVehicles.includes(this.state.vehicles[key].uuid)) {
						selectedDevices.push(key);
					}
				});

				if (
					!(
						this.props.diapason.from_date &&
						this.props.diapason.from_time &&
						this.props.diapason.to_date &&
						this.props.diapason.to_time &&
						selectedDevices.length &&
						selectedDevices.length < 6
					) // max for 6 devices call
				) {
					return;
				}
			}
		}

		this.setState({ loading: true, history: [] });
		const from = moment(moment(this.props.diapason.from_date).format(formats.DATE_URL) + ' ' + from_time).add(
			-currentUser.user.is_timecorrection,
			'hours'
		);
		const to = moment(moment(this.props.diapason.to_date).format(formats.DATE_URL) + ' ' + to_time).add(
			-currentUser.user.is_timecorrection,
			'hours'
		);

		if (!from.isValid() || !to.isValid()) {
			return;
		}

		let response;

		if (type === 'cleanup_specialist') {
			response = await this.props.getHistory(
				[ cleanupSpecialist ],
				from.format(formats.DATETIME_API),
				to.format(formats.DATETIME_API),
				true
			)();
		}
		if (type === 'vehicle') {
			if (selectedVehicles.length === 1) {
				response = await this.props.getHistoryByVehicle(
					selectedVehicles[0],
					from.format(formats.DATETIME_API),
					to.format(formats.DATETIME_API),
					true
				)();
			} else {
				response = await this.props.getObjectsHistoryDataRequest(selectedDevices, from.unix(), to.unix());
			}
		}

		this.setState({ loading: false });
		const bounds = [];
		if (response.isOk) {
			let history = [];
			if (response.payload && response.payload.telematics) {
				const telematics = response.payload.telematics;
				telematics.forEach((telematic) => {
					if (telematic.points.length) {
						// filter if no points
						history.push(
							_.map(telematic.points, (point) => {
								return new HistoryTelematicsDevice(telematic.device_id, point);
							})
						);
					}
				});
			} else { // if multi history
				const telematics = response.data.objectsList;
				telematics.forEach((telematic) => {
					if (telematic.dataList.length) {
						// filter if no points
						history.push(
							_.map(telematic.dataList, (point) => {
								return new HistoryTelematicsDevice(telematic.devicecode, [
									point.position.latitude,
									point.position.longitude,
									point.position.speed,
									point.position.course,
									point.devicetime,
									0,
									point.state.ignition,
									0,
									0,
									0,
									0,
									point.state.voltageint,
									0,
									0,
									point.receivedtime
								]);
							})
						);
					}
				});
			}


			if (history.length) {
				await this.setState({
					history,
					currentTime: from.format(formats.DATETIME),
					currentTimeTo: to.format(formats.DATETIME)
				});
			}
			this.props.onSetShowHistorySlider(true);

			setTimeout(() => {
				this.refs.map.invalidateSize();
			}, 200);
		}
		if (!_.isEmpty(bounds)) {
			this.refs.map.fitBounds(bounds);
		}

		this.loadHistoryTasks(from, to, type);

		if (type === 'vehicle') {
			this.props.onLoadHistoryOrderExecutions(from, to);
		}

		if (_.indexOf([ 'kiutr', 'ems' ], this.props.component) > -1) {
			this.props.onLoadHistoryOrderExecutions(from, to, this.getSelectedVehicleUuid());
		}
	}

	async loadHistoryTasks(from, to, moreFilters, type = 'vehicle') {
		if (!isKurs(this.props.component)) {
			return;
		}

		const vehicleUuid = this.getSelectedVehicleUuid();

		let filters = {
			withPeriod: [ from.format(formats.DATETIME_API), to.format(formats.DATETIME_API) ],
			withBaseVehicles: [ vehicleUuid ]
		};

		switch (type) {
			case 'vehicle': {
				const vehicleUuid = this.getSelectedVehicleUuid();
				filters.withBaseVehicles = [ vehicleUuid ];
				break;
			}
			case 'cleanup_specialist': {
				const cleanupSpecialistUuid = _.find(this.state.cleanupSpecialists, {
					login: _.first(this.props.filters.base.cleanupSpecialists)
				}).uuid;
				filters.withCleanupSpecialists = [ cleanupSpecialistUuid ];
				break;
			}
		}

		if (moreFilters) {
			filters = { ...filters, ...moreFilters };
		}

		const response = await this.props.onGetTasks({
			filters: filters
		});

		if (response.isOk) {
			const tasks = response.payload.items;
			const violations = await this.loadViolations(_.map(tasks, 'uuid'));
			const visits = await this.loadObjectVisits(_.map(tasks, 'uuid'));

			this.setState({
				historyTasks: tasks,
				historyTaskViolations: violations,
				historyObjectVisits: visits,
				historyWorks: mapHistoryWorks(tasks, this.props.dictionaries.work_types)
			});
		} else {
			response.showErrors();
		}
	}

	async loadViolations(uuids) {
		const response = await this.props.getTaskViolations({
			filters: {
				withTask: uuids
			},
			pagination: getPagination(1, 10000)
		})();

		if (response.isOk) {
			return response.payload.items;
		}

		return [];
	}

	async loadObjectVisits(uuids) {
		const response = await this.props.listObjectVisits({
			filters: {
				withTask: uuids
			},
			pagination: getPagination(1, 10000)
		})();

		if (response.isOk) {
			return response.payload.items;
		}

		return [];
	}

	clearTelematics() {
		this.setState({
			telematics: new Map({}),
			cleanupSpecialistsTelematics: new Map({}),
			clusters: []
		});
	}

	clearHistory() {
		const map = this.refs.map.refs.map.getWrappedInstance().map;
		map.eachLayer(function(layer) {
			if (layer.id) {
				if (layer.id.includes('stopsid') || layer.id.includes('parkingid')) {
					map.removeLayer(layer);
				}
			}
		});
		this.props.onSetShowHistorySlider(false);
		this.setState({
			history: [],
			currentTime: null,
			playing: false,
			historyGeojson: null
		});

		setTimeout(() => {
			this.refs.map.invalidateSize();
		}, 200);
	}

	clearHistoryGeojson() {
		this.setState({
			historyGeojson: null
		});
	}

	toggleDetalization = () => {
		this.setState({ showDetalization: !this.state.showDetalization });
	};

	onDetalizationFilterChange = (name) => {
		if (name instanceof Object) {
			this.setState({
				detalizationFilter: {
					...this.state.detalizationFilter,
					...name
				}
			});
		} else {
			this.setState({
				detalizationFilter: {
					...this.state.detalizationFilter,
					[name]: !this.state.detalizationFilter[name]
				}
			});
		}
	};

	setDataForModal = (props) => {
		this.setState({ dataForModal: { ...props, openRoutePanel: this.openRoutePanel.bind(this) } });
	};

	onTypeChange({ target: { value } }) {
		this.setState({
			filterAnalyticType: value
		});
		this.loadMunicipalities();
	}

	showHistory(vehicleUuid, dateFrom = null, from = null, dateTo = null, to = null) {
		this.savedSelectedVehicles = _.cloneDeep(this.props.filters.base.vehicle.vehicles);

		let filters = this.props.filters;

		_.set(filters, 'base.vehicle.vehicles', [ vehicleUuid ]);
		_.set(filters, 'base.cleanupSpecialists', []);
		this.props.onSetFilters(filters);
		this.handleDiapasonChange('from_date', dateFrom || moment().subtract(12, 'hour'));
		this.handleDiapasonChange('from_time', from || moment().subtract(12, 'hour').format('HH:mm'));
		this.handleDiapasonChange('to_date', dateTo || moment());
		this.handleDiapasonChange('to_time', to || moment().format('HH:mm'));
		this.props.onSetShowHistory(true);
		this.loadTelematics();
		// this._cycleFetch.forceNext();

		this.loadHistory(null, null, 'vehicle', [ vehicleUuid ]);
	}

	showHistoryModal(vehicleUuid, dateFrom = null, from = null, dateTo = null, to = null) {
		this.showHistory(vehicleUuid, dateFrom, from, dateTo, to);

		this.refs.historyModal.getWrappedInstance().showModal(dateFrom);
	}

	async toggleAnimationActive() {
		await this.setState({
			animationActive: !this.state.animationActive
		});
	}

	toggleFullscreen = () => {
		this.setState({
			fullscreen: !this.state.fullscreen
		});
		setTimeout(() => {
			this.refs.map.invalidateSize();
		}, 200);
	};

	onHistoryClick(history) {
		this.setState({
			currentTime: moment.unix(history.time).format(formats.DATETIME)
		});
	}

	toggleSpeed = () => {
		let index = speeds.indexOf(this.state.speed);
		index++;
		if (index >= speeds.length) {
			index = 0;
		}
		const speed = speeds[index];
		this.setState({ speed });
		if (this.state.playing) {
			clearInterval(this.playInterval);
			this.playInterval = setInterval(this.play, 4000 / speed);
		}
	};

	getSelectedBnsoNumber() {
		return _.get(this.getSelectedVehicle(), 'current_bnso.bnso_number');
	}

	getSelectedVehicleStateNumber() {
		return _.get(this.getSelectedVehicle(), 'state_number');
	}

	getSelectedVehicle() {
		return _.find(this.state.vehicles, { uuid: this.getSelectedVehicleUuid() });
	}

	getSelectedVehicleUuid() {
		return _.first(this.props.filters.base.vehicle.vehicles);
	}

	renderSlider() {
		return (
			<MapRetrospective
				ref="retrospective"
				refs={this.refs}
				vehicleUuid={this.getSelectedVehicleUuid()}
				bnsoNumber={this.getSelectedBnsoNumber()}
				from={moment(
					moment(this.props.diapason.from_date).format(formats.DATE_URL) + ' ' + this.props.diapason.from_time
				)}
				to={moment(moment(this.props.diapason.to_date).format(formats.DATE_URL) + ' ' + this.props.diapason.to_time)}
				cleanupSpecialist={
					window.RNIS_SETTINGS.CITY_MURMANSK && this.props.filters.base.cleanupSpecialists ? (
						this.props.filters.base.cleanupSpecialists[0]
					) : (
						[]
					)
				}
				historyType={this.state.historyType}
				history={this.state.history}
				prevTime={() => {
					this.moveHistoryTime(-1);
				}}
				prevTimeX2={() => {
					this.moveHistoryTime(-6);
				}}
				nextTime={() => {
					this.moveHistoryTime(1);
				}}
				nextTimeX2={() => {
					this.moveHistoryTime(6);
				}}
				fullscreen={this.state.retrospectiveFullscreen}
				onFullscreenToggle={this.onRetrospectiveFullscreenToggle}
				currentTime={moment(this.state.currentTime, formats.DATETIME)}
				onCurrentTimeChange={::this.onCurrentTimeChange}
				onGeojsonChange={::this.onGeojsonChange}
				togglePlay={this.togglePlay}
				toggleSpeed={this.toggleSpeed}
				playing={this.state.playing}
				speed={this.state.speed}
				bnsoIndicators={this.props.dictionaries.bnso_indicators}
				workTypes={this.props.dictionaries.work_types}
				bnsoTypes={this.props.dictionaries.bnso_types}
				component={this.props.component}
				tasks={this.state.historyTasks}
				taskViolations={this.state.historyTaskViolations}
				objectVisits={this.state.historyObjectVisits}
				orderExecutions={this.props.historyOrderExecutions}
				kurs_mechanism_bindings={this.props.dictionaries.kurs_mechanism_bindings}
				kurs_mechanism_types={this.props.dictionaries.kurs_mechanism_types}
				onUpdate={::this.forceUpdate}
				historyGeojson={this.state.historyGeojson}
				isHistoryMulti={this.state.history.length > 1}
			/>
		);
	}

	onRetrospectiveFullscreenToggle = () => {
		const states = [ 0, 1, 2 ];
		let index = _.indexOf(states, this.state.retrospectiveFullscreen) + 1;
		if (index >= states.length) {
			index = 0;
		}
		this.setState({
			retrospectiveFullscreen: states[index]
		});
	};

	moveHistoryTime(hours) {
		const from = moment(
			moment(this.props.diapason.from_date).format(formats.DATE_URL) + ' ' + this.props.diapason.from_time
		).add(hours, 'hours');
		const to = moment(
			moment(this.props.diapason.to_date).format(formats.DATE_URL) + ' ' + this.props.diapason.to_time
		).add(hours, 'hours');
		this.handleDiapasonChange('from_date', from);
		this.handleDiapasonChange('from_time', from.format(formats.TIME));
		this.handleDiapasonChange('to_date', to);
		this.handleDiapasonChange('to_time', to.format(formats.TIME));
	}

	onCurrentTimeChange(value) {
		this.setState({
			currentTime: moment(moment.unix(value).format(formats.DATETIME), formats.DATETIME).format(formats.DATETIME)
		});
	}

	onGeojsonChange(geojson) {
		this.setState({
			historyGeojson: geojson
		});

		if (_.isArray(geojson)) {
			const group = L.geoJSON(_.first(geojson).geojson);
			this.refs.map.fitBounds(group.getBounds());
		} else {
			const group = L.geoJSON(geojson);
			this.refs.map.fitBounds(group.getBounds());
		}
	}

	getSliderHandle(props) {
		const { value, dragging, index, ...restProps } = props;
		return (
			<Tooltip
				prefixCls="rc-slider-tooltip"
				overlay={moment.unix(value).format(formats.DATETIME_SHORT)}
				visible={dragging}
				placement="bottom"
				key={index}
			>
				<Slider.Handle value={value} {...restProps} />
			</Tooltip>
		);
	}

	togglePlay = () => {
		this.setState(
			{
				playing: !this.state.playing
			},
			() => {
				if (this.state.playing) {
					this.playInterval = setInterval(this.play, 4000 / this.state.speed);
				} else {
					clearInterval(this.playInterval);
				}
			}
		);
	};

	play = () => {
		let currentTime = moment(this.state.currentTime, formats.DATETIME);
		currentTime.add(1, 'minutes');

		const from = moment(
			moment(this.props.diapason.from_date).format(formats.DATE_URL) + ' ' + this.props.diapason.from_time
		);
		const to = moment(moment(this.props.diapason.to_date).format(formats.DATE_URL) + ' ' + this.props.diapason.to_time);
		if (currentTime.isAfter(to)) {
			currentTime = from;
		}
		this.setState({
			currentTime: currentTime.format(formats.DATETIME)
		});
	};

	zoomToStopPoints() {
		this.refs.map.setZoom(14);
		this.props.activateLayer('stop_points_mta')();
	}

	toggleClusters() {
		this.setState({
			clustersActive: !this.state.clustersActive
		});
		this.loadTelematics();
		// this._cycleFetch.forceNext();
	}

	onClusterRendered() {
		this.setState({ loading: false });
	}

	onZoomLoad = () => {
		this.loadTelematics();
		// this._cycleFetch && this._cycleFetch.forceNextWithDelay(300);
	};

	getFilters(path) {
		return _.get(this.props.filters, path);
	}

	async onFiltersChange (path, value, ignoreUpdates = false) {
		let filters = JSON.parse(JSON.stringify(this.props.filters));
		if(value.length && path === 'base.vehicle.vehicles' && !this.state.vehiclesLoading){

			this.setState({
				vehiclesLoading: true,
			},async ()=>{

				console.log(this.state.vehiclesLoading)
			let {vehicles,vehicle_popup} = this.state
			let response_data = [ 'items/uuid', 'items/state_number', 'items/current_bnso', 'items/unit_uuid' ]
			for (const i in vehicle_popup){
				if(vehicle_popup[i]){
					const field = i.replace('_ptp', '').replace('_tp','')
					if(!response_data.includes(`items/${field}`)){
						response_data.push(`items/${field}`)
					}
				}
			}


			const res = await makeResponse(() => {
				return api.vehicles.getVehicleList({
					pagination: getPagination(1, 10000),
					response_data,
					filters: { withUuid: value }});

			});
			await this.setState({
				vehiclesLoading: false,
			})

			if(res.isOk){
				res.payload.items.forEach(item=>{
					vehicles[item.current_bnso.bnso_number] = item
				})
				this.setState({
					vehicles,
				})
			}

			})
		}

		if (window.RNIS_SETTINGS.road_map_filter_by_units_for_road_parts) {
			if (path === 'base.common.units') {
				if (this.props.activeLayers.road_parts) {
					_.set(filters, 'base.road_parts.units', value);
				}
				if (this.props.activeLayers.vehicles) {
					_.set(filters, 'base.vehicle.owners', value);
				}
			}
		}

		_.set(filters, path, value);
		this.props.onSetFilters(filters);

		if (ignoreUpdates) {
			return;
		}

		if (path === 'base.vehicle.vehicles') {
			// this.reload();
			this.loadOrderExecutions();
			this.clearHistory();
			if (this.props.showHistory && !window.RNIS_SETTINGS.show_mass_history) {
				if (value[1]) {
					_.set(filters, path, [ value[1] ]);
					this.props.onSetFilters(filters);
					this.loadHistory(null, null, 'vehicle', [ value[1] ]);
				}
			}
			if (this.props.showHistory && value.length > 0 && value.length < 6 && window.RNIS_SETTINGS.show_mass_history) {
				this.loadHistory(null, null, 'vehicle', value);
			}

			if (this.props.showHistory && value.length === 1 && !window.RNIS_SETTINGS.show_mass_history) {
				this.loadHistory(null, null, 'vehicle', value);
			}
			this.loadTasks();
			setTimeout(() => {
				storage.set(`map:vehicles:${this.props.component}`, value);
			}, 500);
			if (!value.length) {
				this.props.onSetShowHistorySlider(false);
				this.props.onSetShowHistory(false);
			}
		}

		if (path === 'base.routes') {
			this.props.onGetRoute(value.length === 1 ? _.first(value) : null);
		}
	}

	async onExtendedFiltersSubmit() {
		const response = await this.props.monitoringFilterVehicles(this.getExtendedFiltersRequest())();

		if (response.isOk) {
			this.onFiltersChange('extended.result', response.payload.items);
			this.toggleFilterResult();
			return true;
		} else {
			response.showErrors();
			return false;
		}
	}

	getExtendedFiltersRequest() {
		if (this.props.component === 'operator') {
			filtersMap.vehicle_component = {
				path: 'filters.extended.parameters.component'
			};
		}

		let filters = [];
		_.each(filtersMap, (data, code) => {
			const path = data.path;
			const value = _.get(this.state, path);
			if (!value || (_.isArray(value) && value.length === 0)) {
				return;
			}
			let modifiers = [];
			if (data.modifiersPath) {
				_.each(data.modifiersPath, (mPath, mKey) => {
					if (_.get(this.state, mPath)) {
						modifiers.push(mKey);
					}
				});
			}

			filters.push({
				code,
				value: JSON.stringify(value),
				modifiers
			});
		});

		return {
			date_from: moment()
				.startOf('day')
				.minute(timeStrToMinutes(_.get(this.props.filters, 'extended.from')))
				.format(formats.DATETIME_API),
			date_to: moment()
				.startOf('day')
				.minute(timeStrToMinutes(_.get(this.props.filters, 'extended.to')))
				.format(formats.DATETIME_API),
			component: this.props.component !== 'operator' ? this.props.component : null,
			filters
		};
	}

	toggleFilterResult() {
		this.setState({
			filterResultActive: !this.state.filterResultActive
		});
	}

	fromChange = ({ target: { value } }) => {
		this.props.onSetAnaliticDiapason({ field: 'from_analytic', value: moment(value).startOf('day') });
		this.loadMunicipalities();
		this.loadViolationsKiutr();
		this.loadHistory();
	};

	toChange = ({ target: { value } }) => {
		this.props.onSetAnaliticDiapason({ field: 'to_analytic', value: moment(value).endOf('day') });
		this.loadMunicipalities();
		this.loadViolationsKiutr();
		this.loadHistory();
	};

	onFiltersReset(onlyExtended = false) {
		if (onlyExtended) {
			const filters = this.props.filters;
			filters.extended = getDefaultFilters(this.props.component).extended;
			this.props.onSetFilters(filters);
		} else {
			this.props.onSetFilters(getDefaultFilters(this.props.component));
		}
	}

	handleFilterKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			this.loadHistory();
		}
	};

	toggleShowEvents() {
		this.props.onSetShowEvents(!this.props.showEvents);
	}

	toggleShowHistory = () => {
		if (this.props.showHistory) {
			this.props.onSetShowHistory(false);
			let filters = this.props.filters;
			if (_.isArray(this.savedSelectedVehicles)) {
				_.set(filters, 'base.vehicle.vehicles', this.savedSelectedVehicles);
			}
			this.props.onSetFilters(filters);
			this.props.onSetShowHistorySlider(false);
			this.clearHistory();
			setTimeout(() => {
				this.refs.map.invalidateSize();
			}, 200);
			// this.forceUpdate();
		} else {
			this.loadHistory();
			this.props.onSetShowHistory(true);
		}

		// this.loadDebounce();
	};

	handleDiapasonChange(field, value) {
		if (window.RNIS_SETTINGS.CITY_TULA) {
			const timeDifference = getWeekDifference(field, value, this.props.diapason);
			if (timeDifference > 7 || timeDifference < -7) {
				alerts.alert('Диапазон не может быть больше недели');
				return;
			}
		}
		this.props.onSetDiapason({ field, value });
	}

	handlePlusWeek = () => {
		const dateFromPlusWeek = moment(this.props.diapason.from_date).add(7, 'days');
		const dateToPlusWeek = moment(this.props.diapason.to_date).add(7, 'days');

		if (dateToPlusWeek.diff(moment(), 'days') > 0) {
			alerts.alert('Дата истории не может быть в будущем');
			return;
		}
		this.props.onSetDiapason({ field: 'from_date', value: dateFromPlusWeek.format('YYYY-MM-DD') });
		this.props.onSetDiapason({ field: 'to_date', value: dateToPlusWeek.format('YYYY-MM-DD') });
	};

	handleMinusWeek = () => {
		const dateFromMinusWeek = moment(this.props.diapason.from_date).subtract(7, 'days').format('YYYY-MM-DD');
		const dateToMinusWeek = moment(this.props.diapason.to_date).subtract(7, 'days').format('YYYY-MM-DD');
		this.props.onSetDiapason({ field: 'from_date', value: dateFromMinusWeek });
		this.props.onSetDiapason({ field: 'to_date', value: dateToMinusWeek });
	};

	getCurrentHistory = () => {
		const currentTime = timeToInt(moment(this.state.currentTime, formats.DATETIME));

		if (!currentTime) {
			return [];
		}

		if (!this.props.shouldShowHistory) {
			return [];
		}

		const currentHistory = [];
		this.state.history.forEach((history) => {
			const filtered = history.filter((point) => point.time > currentTime);
			currentHistory.push(_.first(filtered));
		});

		return currentHistory;
	};

	getCurrentVehicle() {
		if (!this.props.shouldShowHistory) {
			return null;
		}

		return this.getSelectedVehicle();
	}

	renderSelectedRoutes() {
		let points = [];
		return _.concat(
			_.map(this.props.selectedRouteVariants, (routeVariants, index) => {
				return _.map(routeVariants, (route) => {
					points = _.concat(points, _.concat(route.forward_points, route.reverse_points));
					points.forEach((point) => {
						if(this.props.routeStopPoints[point.type_uuid]){
							point['stop_point_title'] = this.props.routeStopPoints[point.type_uuid].title
						}
					});
					return (
						<MapPolyline
							key={route.uuid}
							static={true}
							map={this.refs.map.refs.map}
							leafletMap={this.refs.map.refs.map.getWrappedInstance().map}
							color={getSelectedRouteColor(index)}
							coordinates={route.coordinates}
							tooltip={_.get(this.props.selectedRoutes, route.route_uuid)}
						/>
					);
				});
			}),
			_.map(_.uniqBy(points, 'type_uuid'), this.renderRoutePoint)
		);
	}

	renderRoutes() {
		return this.props.routes.map(this.renderRoute);
	}

	renderRoute = (routes) => {
		const renderedRoutes = {};
		const points = {};
		routes.forEach((route) => {
			// set only uniques routes to render
			if (renderedRoutes[route.uuid + route.type]) return;
			const stopPoints = this.props.routes.filter((routeProps) => {
				return routeProps.uuid === route.route_uuid;
			});

			if (!stopPoints[0]) return;

			route.points.forEach((point) => {
				for (const stopPoint of stopPoints[0].groups) {
					stopPoint.items.forEach((item) => {
						if (item.stop_point_uuid === point.type_uuid) {
							point['stop_point_title'] = stopPoint.name;
						}
					});
				}
				points[point.uuid] = point;
			});
			renderedRoutes[route.uuid + route.type] = route;
		});

		return (
			<div>
				{Object.values(points).map(this.renderRoutePoint)}
				{Object.values(renderedRoutes).map((route, i) => {
					return (
						<div key={route.uuid + i}>
							{route.type === 'production_forward' &&
								_.map(route.points, (point, index) => {
									return (
										<DirectionalPolyline
											key={`route:forward:${index}`}
											map={this.refs.map.refs.map}
											leafletMap={this.refs.map.refs.map.getWrappedInstance().map}
											color="red"
											coordinates={_.map(
												point.path_to_the_next_point_geometry ? point.path_to_the_next_point_geometry.coordinates : [],
												(coord) => _.clone(coord).reverse()
											)}
											tooltip={this.props.route.title}
											onClick={::this.openRoutePanel}
										/>
									);
								})}
							{route.type === 'production_reverse' &&
								_.map(route.points, (point, index) => {
									return (
										<DirectionalPolyline
											key={`route:reverse:${index}`}
											map={this.refs.map.refs.map}
											leafletMap={this.refs.map.refs.map.getWrappedInstance().map}
											color="blue"
											coordinates={_.map(
												point.path_to_the_next_point_geometry ? point.path_to_the_next_point_geometry.coordinates : [],
												(coord) => _.clone(coord).reverse()
											)}
											tooltip={this.props.route.title}
											onClick={::this.openRoutePanel}
										/>
									);
								})}
						</div>
					);
				})}
			</div>
		);
	};

	renderRoutePoint = (point, index) => {
		if (!point || !point.latitude || !point.longitude || point.point_type !== 'stop_point') {
			return null;
		}
		if (!point.title && point.streets) {
			point.title = point.streets.reduce((prev, cur, i) => {
				if (i === point.streets.length - 1) {
					return `${prev}${cur}`;
				}
				return `${prev}${cur}, `;
			}, '');
		}
		return (
			<StopPointMarker
				key={`${index}:${point.type_uuid}`}
				static={true}
				map={this.refs.map.refs.map}
				leafletMap={this.refs.map.refs.map.getWrappedInstance().map}
				latitude={point.latitude}
				longitude={point.longitude}
				item={point}
				color="green"
				options={{
					zIndexOffset: 100,
				}}
			/>
		);
	};

	openRoutePanel() {
		this.setState({
			routePanelOpened: true
		});
	}

	closeRoutePanel() {
		this.setState({
			routePanelOpened: false
		});
	}

	async loadEvents(data, type) {
		this.setState({
			events: []
		});

		switch (type) {
			case 'notifications':
				this.loadEventsFromNotifications(data);
				break;
			case 'etp':
				this.loadEventsFromEtp(data);
				break;
		}
	}

	async loadEventsFromNotifications(data) {
		const from = moment(moment(data.from_date).format(formats.DATE) + ' ' + data.from_time, formats.DATETIME_SHORT);
		const to = moment(moment(data.to_date).format(formats.DATE) + ' ' + data.to_time, formats.DATETIME_SHORT);

		if (from.format(formats.DATETIME_API) === 'Invalid date' || to.format(formats.DATETIME_API) === 'Invalid date') {
			alerts.alert('Неверная дата');
			return;
		}

		const response = await this.props.getNotificationsList({
			order: getOrderBy('created_at', 'desc'),
			filters: {
				withVehicles: this.props.filters.base.vehicle.vehicles,
				withPeriod: [ from.format(formats.DATETIME_API), to.format(formats.DATETIME_API) ],
				withEventRules: data.types
			}
		})();
		if (response.isOk) {
			const vehicleUuids = _.uniq(_.filter(_.map(response.payload.items, 'data.vehicle_uuid')));
			const vehicles = await this.loadEventVehicles(vehicleUuids);
			this.setState({
				events: _.map(response.payload.items, (item) => {
					item.vehicle = _.get(vehicles, item.data.vehicle_uuid);
					return item;
				})
			});
		} else {
			response.showErrors();
		}
	}

	async loadEventsFromEtp(data) {
		const from = moment(moment(data.from_date).format(formats.DATE) + ' ' + data.from_time, formats.DATETIME_SHORT);
		const to = moment(moment(data.to_date).format(formats.DATE) + ' ' + data.to_time, formats.DATETIME_SHORT);

		if (from.format(formats.DATETIME_API) === 'Invalid date' || to.format(formats.DATETIME_API) === 'Invalid date') {
			alerts.alert('Неверная дата');
			return;
		}

		const response = await this.props.getEtpEvents({
			vehicle_uuids: this.props.filters.base.vehicle.vehicles,
			date_from: from.format(formats.DATETIME_API),
			date_to: to.format(formats.DATETIME_API),
			codes: data.drive_events
		})();
		if (response.isOk) {
			const vehicleUuids = _.uniq(_.filter(_.map(response.payload.items, 'vehicle_uuid')));
			const vehicles = await this.loadEventVehicles(vehicleUuids);
			this.setState({
				events: _.map(response.payload.items, (item) => {
					item.vehicle = _.get(vehicles, item.vehicle_uuid);
					item.data = {
						latitude: item.latitude,
						longitude: item.longitude,
						title: _.get(_.invert(etp_events), item.code)
					};
					item.created_at = item.timestamp;
					return item;
				})
			});
		} else {
			response.showErrors();
		}
	}

	async loadEventVehicles(uuids) {
		const response = await this.props.getVehicleList({
			filters: {
				withUuid: uuids
			}
		});
		if (response.isOk) {
			return _.keyBy(response.payload.items, 'uuid');
		}

		return null;
	}

	renderEvents() {
		if (!this.props.showEvents) {
			return null;
		}

		return this.state.events.map(::this.renderEvent);
	}

	renderEvent(event) {
		return (
			<EventMarker
				key={event.uuid}
				item={event}
				map={this.refs.map.refs.map}
				leafletMap={this.refs.map.refs.map.getWrappedInstance().map}
				latitude={event.data.latitude}
				longitude={event.data.longitude}
				options={{
					zIndexOffset: 100
				}}
				vehicle_models={this.props.dictionaries.vehicle_models}
			/>
		);
	}

	onEventClick(event) {
		this.refs.map.refs.map.getWrappedInstance().panTo([ event.data.latitude, event.data.longitude ]);
	}

	async loadOrderExecutions() {
		if (!isKiutrOrChildren(this.props.component)) {
			return;
		}

		if (this.getFilters('base.vehicle.vehicles').length > 0) {
			const limit = 300;
			const response = await this.props.getOrderExecutions({
				pagination: getPagination(1),
				filters: {
					current: true,
					withVehicles: this.getFilters('base.vehicle.vehicles') || [],
					withTypes: [ 'production_forward', 'production_reverse' ]
				},
				orderBy: getOrderBy('uuid', 'asc')
			})();

			if (response.isOk) {
				let data = response.payload.items;
				const pages = response.data.headers.meta.pagination.total_pages;
				await Promise.all(
					_.map(_.range(2, pages), async (page) => {
						const response = await this.props.getOrderExecutions({
							pagination: getPagination(1, limit),
							filters: {
								current: true,
								withVehicles: this.getFilters('base.vehicle.vehicles') || [],
								withTypes: [ 'production_forward', 'production_reverse' ]
							},
							orderBy: getOrderBy('uuid', 'asc')
						})();

						if (response.isOk) {
							data = _.concat(data, response.payload.items);
						}
					})
				);

				if (this._isMounted) {
					this.setState({
						order_executions: data
					});
				}
			} else {
				response.showErrors();
			}
		}
	}

	async loadTasks(vehicleUuids = null) {
		if (!isKurs(this.props.component)) {
			return;
		}

		const limit = 50;
		const response = await this.props.onGetTasks({
			pagination: getPagination(1, limit),
			filters: {
				today: true,
				withBaseVehicles: vehicleUuids || this.getFilters('base.vehicle.vehicles') || []
			}
		});

		if (response.isOk) {
			let data = response.payload.items;

			const pages = response.data.headers.meta.pagination.total_pages;
			await Promise.all(
				_.map(_.range(2, pages), async (page) => {
					const response = await this.props.onGetTasks({
						pagination: getPagination(1, limit),
						filters: {
							today: true,
							withBaseVehicles: vehicleUuids || this.getFilters('base.vehicle.vehicles') || []
						}
					});

					if (response.isOk) {
						data = _.concat(data, response.payload.items);
					}
				})
			);
			if (vehicleUuids) {
				this.loadTaskGeometries(data);
			} else {
				await this.setState({
					tasks: data
				});

				this.loadDrivers();
			}
		}
	}

	async loadDrivers() {
		const driverUuids = _.uniq(_.filter(_.map(_.flatten(_.map(this.state.tasks, 'resources')), 'driver_uuid')));
		const response = await makeResponse(() => {
			return api.auth.getUsers({
				pagination: getPagination(1, 1000),
				response_data: driversResponseData,
				filters: {
					withUuid: driverUuids
				}
			});
		});

		if (response.isOk) {
			this.setState({
				drivers: _.mapValues(_.keyBy(response.payload.items, 'uuid'), (user) => {
					return {
						name: new User(user).getFullName(),
						phone: new User(user).getPhone()
					};
				})
			});
			this.forceUpdate();
		}
	}

	async showCleanupSpecialistHistory(cleanupSpecialistLogin, dateFrom = null, from = null, dateTo = null, to = null) {
		this.savedSelectedVehicles = _.cloneDeep(this.state.filters.base.vehicle.vehicles);

		let filters = this.props.filters;
		_.set(filters, 'base.cleanupSpecialists', [ cleanupSpecialistLogin ]);
		_.set(filters, 'base.vehicle.vehicles', []);
		this.props.setFilters(filters);
		this.props.setShowHistory(true);
		this.handleDiapasonChange('from_date', dateFrom || moment().subtract(12, 'hour'));
		this.handleDiapasonChange('from_time', from || moment().subtract(12, 'hour').format('HH:mm'));
		this.handleDiapasonChange('to_date', dateTo || moment());
		this.handleDiapasonChange('to_time', to || moment().format('HH:mm'));
		this.loadTelematics();
		// this._cycleFetch.forceNext();

		await this.loadHistory(_, _, 'cleanup_specialist');
	}

	async loadTaskGeometries(data) {
		const vehicles = _.mapValues(this.state.vehicles, (vehicle) => {
			vehicle.tasks = getVehicleTasks(data, vehicle.uuid);
			return vehicle;
		});

		this.setState({
			vehicles
		});
	}

	loading(status) {
		this.setState({
			loading: status
		});
	}

	async toggleFilterResultOnMap() {
		await this.setState({
			filterResultDisplayedOnMap: !this.state.filterResultDisplayedOnMap
		});

		this.reload();
	}

	toggleVehiclePopup() {
		this.setState({
			vehiclePopupActive: !this.state.vehiclePopupActive
		});
	}

	onVehiclePopupChange(items) {
		let vehicle_popup = this.state.vehicle_popup;
		_.each(items, (item) => {
			vehicle_popup[item] = !vehicle_popup[item];
		});
		this.setState({ vehicle_popup });

		// set vehicle popup setting to localStorage by user
		const userUuid = storage.get('user').uuid;
		const userSettings = storage.get('users_settings');
		if (userSettings[userUuid]) {
			userSettings[userUuid]['map:vehicle:popup:' + this.props.component] = vehicle_popup;
		}

		storage.save('users_settings', userSettings);

		storage.save('map:vehicle:popup:' + this.props.component, vehicle_popup);
	}

	renderGoback() {
		return (
			<button onClick={::this.handleGoBack} className="goback-button" type="button">
				Назад
			</button>
		);
	}

	fromChangeWidget = ({ target: { value } }) => {
		this.handleDiapasonChange('from_date', value);
		this.setState({ mu: null });
		const query = Object.assign({}, this.props.location.query);
		query.date_from = value;
		this.props.router.replace(`${this.props.location.pathname}?${new URLSearchParams(query).toString()}`);
		if (location.pathname.includes('executedworks')) {
			this.loadMu();
		}
		if (location.pathname.includes('roadparts')) {
			this.loadMuRoadParts();
		}
	};

	toChangeWidget = ({ target: { value } }) => {
		this.handleDiapasonChange('to_date', value);
		this.setState({ mu: null });
		const query = Object.assign({}, this.props.location.query);
		query.date_to = value;
		this.props.router.replace(`${this.props.location.pathname}?${new URLSearchParams(query).toString()}`);
		if (location.pathname.includes('executedworks')) {
			this.loadMu();
		}
		if (location.pathname.includes('roadparts')) {
			this.loadMuRoadParts();
		}
	};

	setPositionAndZoomMunicipality(label) {
		const selectedCommunal = this.props.selector_municipalities.filter((item) => item.title === label)[0];
		const __layer = new L.GeoJSON(selectedCommunal.geometry, {});
		if (label.includes('Апатиты')) {
			this.refs.map.refs.map.getWrappedInstance().map.setView(new L.LatLng(67.568023, 33.407187), 11);
		} else {
			const polygonCenter = __layer.getBounds().getCenter();
			this.refs.map.refs.map.getWrappedInstance().map.setView(new L.LatLng(polygonCenter.lat, polygonCenter.lng), 11);
		}
	}

	renderMunicipalities() {
		return (
			<div className="select-wrapper ap-component-municipalitySelect">
				<Select
					options={_.map(this.props.selector_municipalities, (municipality) => ({
						value: municipality.communal_municipality_uuid,
						label: municipality.title
					}))}
					value={this.state.selected_communal_uuid || this.props.location.query.communal}
					clearable={false}
					onChange={async (e) => {
						const value = e ? e.value : null;
						await this.setState({
							selected_communal_uuid: value
						});
						this.setPositionAndZoomMunicipality(e.label);
						this.loadMuRoadParts();
					}}
				/>
			</div>
		);
	}

	renderWorkTypeDate() {
		return (
			<div className="date-wrapper ap-component-datePicker">
				<div className="date-item widget-datepicker">
					<span className="date-label">Дата с:</span>
					<Datepicker style="light" value={this.props.diapason.from_date} onChange={this.fromChangeWidget} />
				</div>
				<div className="date-item widget-datepicker">
					<span className="date-label">Дата по:</span>
					<Datepicker style="light" value={this.props.diapason.to_date} onChange={this.toChangeWidget} />
				</div>
			</div>
		);
	}

	handleGoBack() {
		const previousUrl = localStorage.getItem('previousUrl');
		if (previousUrl && previousUrl.includes('/analytics/executedworks/')) {
			localStorage.removeItem('previousUrl');
			this.props.router.push(
				`${previousUrl}?date_from=${moment(this.props.diapason.from_date).format(formats.DATE_URL)}&date_to=${moment(
					this.props.diapason.to_date
				).format(formats.DATE_URL)}`
			);
		}
		if (location.pathname.includes('roadparts')) {
			this.setState({
				mu: null,
				muTasksObjects: null,
				selected_work_type: 'all',
				selected_communal_uuid: null
			});
			this.loadMu();
			this.props.router.push(
				`/analytics/executedworks/map?date_from=${moment(this.props.diapason.from_date).format(
					formats.DATE_URL
				)}&date_to=${moment(this.props.diapason.to_date).format(formats.DATE_URL)}`
			);
		} else if (!previousUrl || !previousUrl.includes('/analytics/executedworks/')) {
			this.props.router.push(
				`/analytics/dashboard?date_from=${moment(this.props.diapason.from_date).format(
					formats.DATE_URL
				)}&date_to=${moment(this.props.diapason.to_date).format(formats.DATE_URL)}`
			);
		}
	}

	async onChangeWorkType(field, e) {
		const value = e.value || e.target.value;
		if (location.pathname.includes('executedworks')) {
			await this.setState({
				mu: null,
				selected_communal_uuid: 'all',
				selected_work_type: value
			});
			this.loadMu();
		}
		if (location.pathname.includes('roadparts')) {
			await this.setState({
				selected_work_type: value
			});
			this.loadMuRoadParts();
		}
	}

	renderWorkTypeSelect() {
		let work_types;
		if (location.pathname.includes('executedworks')) {
			work_types = { ...{ '000f000': { uuid: 'all', name: 'Все виды работ' } }, ...this.props.dictionaries.work_types };
		} else {
			work_types = this.props.dictionaries.work_types;
		}

		return (
			<div className="ap-component-select">
				<Select
					name="form-field-name"
					options={_.map(work_types, (work_type) => ({
						value: work_type.uuid,
						label: work_type.name
					}))}
					value={this.state.selected_work_type}
					clearable={false}
					onChange={this.onChangeWorkType.bind(this, 'work_type')}
				/>
			</div>
		);
	}

	render() {
		let title = `${systems[this.props.params.component]} →  ${window.RNIS_SETTINGS.CITY_MURMANSK
			? 'Мониторинг ТС И СПУ'
			: 'Мониторинг ТС'}`;
		if (this.props.params.component === 'analytics') {
			title = `${systems[this.props.params.component]} →  Карта нарушений`;
		}

		if (location.pathname.includes('executedworks') || location.pathname.includes('roadparts')) {
			title = `${systems[this.props.params.component]} →  Выполненные задачи`;
		}

		const className = classNames(
			{
				'r-block_noIndent': true,
				'with-history': this.props.showHistorySlider
			},
			this.props.showHistorySlider ? `with-history_fullscreen-${this.state.retrospectiveFullscreen}` : null
		);

		let telematics = this.props.vehiclesFilter && this.props.vehiclesFilter.length ? this.state.telematics : new Map();
		let clusters = this.props.vehiclesFilter && this.props.vehiclesFilter.length ? this.state.clusters : [];

		return (
			<Page
				pageId="Map"
				title={title}
				className={className}
				fullscreen={this.state.fullscreen}
				headerContents={renderHeaderContent(
					this.props.filters,
					this.props.params.component,
					this.props.component,
					this.props.analyticDiapason,
					::this.toChange,
					::this.fromChange,
					this.props.diapason,
					::this.handleDiapasonChange,
					this.handleFilterKeyPress,
					::this.toggleShowEvents,
					this.props.showHistory,
					this.toggleShowHistory,
					::this.toggleFilterResult,
					this.refs,
					::this.onExtendedFiltersSubmit,
					::this.onFiltersChange,
					this.props.onSetSelectedRoutes,
					this.props.original_component === 'operator', // is operator component
					this.handleGetUnits,
					::this.loadVehicles,
					this.handleForceNextCycle,
					this.props.shouldShowHistory,
					this.handlePlusWeek,
					this.handleMinusWeek
				)}
			>
				{this.state.loading ||
					(this.props.loading && <GlobalLoaderComponent block={false} style={{ zIndex: 99999 }} />)}
				<Leaflet
					ref="map"
					component={this.props.component}
					getTelematicsWithTemperatureGrpc={this.props.getTelematicsWithTemperatureGrpc}
					telematics={telematics}
					cleanupSpecialistsTelematics={this.state.cleanupSpecialistsTelematics}
					cleanupSpecialists={this.state.cleanupSpecialists}
					vehicles={this.state.vehicles}
					clusters={clusters}
					onClusterRendered={::this.onClusterRendered}
					onZoom={() => this.zoomDebounce}
					onSetTelematicsToStore={this.props.onSetTelematicsToStore}
					shownTelematics={this.props.shownTelematics}
					shouldShowHistory={this.props.shouldShowHistory}
					isHistoryMulti={this.state.history.length > 1}
					components={renderModalsComponents(
						// pick out all state to redux, and pickout components to Map.js
						this.state.fullscreen,
						this.toggleFullscreen,
						::this.zoomToStopPoints,
						this.state.clustersActive,
						::this.toggleClusters,
						this.state.animationActive,
						::this.toggleAnimationActive,
						this.state.filterAnalyticType,
						::this.onTypeChange,
						this.props.shouldShowHistory,
						this.refs.retrospective,
						this.state.historyWorks,
						this.state.history,
						::this.onHistoryClick,
						this.props.diapason,
						this.props.dictionaries,
						this.getSelectedBnsoNumber(),
						this.getSelectedVehicleStateNumber(),
						this.props.component,
						this.state.historyTasks,
						this.props.historyOrders,
						this.props.historyOrderExecutions,
						::this.onCurrentTimeChange,
						::this.onGeojsonChange,
						::this.clearHistoryGeojson,
						this.props.location.query.export,
						::this.loadHistory,
						::this.loadHistoryTasks,
						this.state.currentTime,
						this.state.currentTimeTo,
						this.props.route,
						this.props.routes,
						::this.closeRoutePanel,
						this.state.vehicles,
						this.state.events,
						::this.loadEvents,
						::this.onEventClick,
						::this.toggleShowEvents,
						this.state.routePanelOpened,
						this.props.showEvents,
						this.state.showDetalization,
						this.toggleDetalization,
						this.state.detalizationFilter,
						this.onDetalizationFilterChange,
						this.state.dataForModal,
						this.props.params.component,
						this.props.vehiclesFilter,
						this.props.showHistory
					)}
					onGetVehicleForPopup={this.props.onGetVehicleForPopup}
					vehicleForPopup={this.props.vehicleForPopup}
					showSearch={true}
					showHistory={::this.showHistory}
					showCleanupSpecialistHistory={::this.showCleanupSpecialistHistory}
					toggleDetalization={this.toggleDetalization}
					detalizationFilter={this.state.detalizationFilter}
					setDataForModal={this.setDataForModal}
					history={this.state.history}
					onGetCurrentHistory={this.getCurrentHistory}
					currentVehicle={this.getCurrentVehicle()}
					animationActive={this.state.animationActive}
					units={this.props.units}
					loadTasks={::this.loadTasks}
					onHistoryGeojsonChange={::this.onGeojsonChange}
					vehiclePopup={this.state.vehicle_popup}
					showVehiclePopupConfig={::this.toggleVehiclePopup}
					withRouting={this.props.component === 'ems'}
					vehiclePopupActive={this.state.vehiclePopupActive}
					setSelectedRoutes={::this.props.onSetSelectedRoutes}
					data={{
						gatn_responsives: this.props.dictionaries.gatn_responsives,
						vehicle_models: this.props.dictionaries.vehicle_models,
						vehicle_marks: this.props.dictionaries.vehicle_marks,
						vehicle_types: this.props.dictionaries.vehicle_types,
						vehicle_capacity_types: this.props.dictionaries.vehicle_capacity_types,
						vehicle_environmental_classes: this.props.dictionaries.vehicle_environmental_classes,
						bnso_indicators: this.props.dictionaries.bnso_indicators,
						bnso: this.props.bnso,
						bnso_types: this.props.dictionaries.bnso_types,
						units: _.mapValues(_.keyBy(this.props.units, 'value'), 'label'),
						parentUnits: _.mapValues(_.keyBy(this.props.units, 'value'), 'parent_unit_uuid'),
						work_types: this.props.dictionaries.work_types,
						kurs_task_statuses: this.props.dictionaries.kurs_task_statuses,
						tasks: this.state.tasks || [],
						drivers: this.state.drivers || [],
						order_executions: this.state.order_executions || []
					}}
					filters={this.props.filters}
				>
					{/* {!this.props.location.query.route && this.renderRoutes()} */}
					{this.props.route && this.state.order && this.renderRoute(this.state.order.shifts[0].runs)}
					{this.renderSelectedRoutes()}
					{this.renderEvents()}
					{this.state.notification ? this.renderEvent(this.state.notification) : null}
					{this.state.historyGeojson && (
						<HistoryGeojson
							historyGeoJsonData={this.state.historyGeojson}
							map={this.refs.map.refs.map}
							leaflet={this.refs.map.refs.map.getWrappedInstance().map}
						/>
					)}
				</Leaflet>
				{this.props.showHistorySlider && this.renderSlider()}
				{this.state.vehicleEditUuid && (
					<VehicleEditor
						{...this.props}
						uuid={this.state.vehicleEditUuid}
						mode="edit"
						onSubmit={() => this.onVehicleCloseClick(true)}
						onClose={() => this.onVehicleCloseClick(false)}
					/>
				)}
				{this.props.selectedRoad && (
					<RoadModal
						buttons={<RoadModalButtons hideRoadModals={::this.props.onHideRoadModal} />}
						title="Дорога"
						hideRoadModals={::this.props.onHideRoadModal}
						data={getRoadModalData(this.props.selectedRoad, this.state.maintenance_groups)}
					/>
				)}
				{this.props.selectedRoadPart && (
					<RoadModal
						buttons={<RoadModalButtons hideRoadModals={::this.props.onHideRoadModal} />}
						title="Участок дороги"
						hideRoadModals={::this.props.onHideRoadModal}
						data={getRoadPartModalData(this.props.selectedRoadPart, this.state.maintenance_groups)}
					/>
				)}
				{this.props.selectedRoadRepairPart && (
					<RoadModal
						buttons={<RoadModalButtons hideRoadModals={::this.props.onHideRoadModal} />}
						title="Участок ремонта дороги"
						hideRoadModals={::this.props.onHideRoadModal}
						data={getRoadRepairModalData(this.props.selectedRoadRepairPart)}
					/>
				)}
				{this.state.vehicleFuelUuid && (
					<PageModalComponent
						header={{
							title: 'Уровень топлива',
							buttons: <RoadModalButtons hideRoadModals={::this.onVehicleFuelCloseClick} />
						}}
						onClose={::this.onVehicleFuelCloseClick}
						className={`profile-modal`}
						withFade={false}
					>
						<KursVehicleFuel uuid={this.state.vehicleFuelUuid} />
					</PageModalComponent>
				)}
				<MapFilterResult
					visible={this.state.filterResultActive}
					displayedOnMap={this.state.filterResultDisplayedOnMap}
					displayOnMap={::this.toggleFilterResultOnMap}
					hideOnMap={::this.toggleFilterResultOnMap}
					component={this.props.component}
					vehicles={this.getFilters('extended.result')}
					onClose={::this.toggleFilterResult}
					vehicle_types={this.props.dictionaries.vehicle_types}
					onPlayClick={::this.showHistory}
					onHistoryClick={::this.showHistoryModal}
				/>
				{this.state.vehiclePopupActive && (
					<VehiclePopupConfigModal
						component={this.props.component}
						data={this.state.vehicle_popup}
						onChange={::this.onVehiclePopupChange}
						onClose={::this.toggleVehiclePopup}
					/>
				)}
				{this.props.component === 'analytics' && this.renderMo()}
				{logicViewWorkTypeSelect() && this.renderGoback()}
				{logicViewWorkTypeSelect() && this.renderWorkTypeSelect()}
				{logicViewWorkTypeSelect() && this.renderWorkTypeDate()}
				{this.props.component === 'analytics' && (
					<AnalyticsLegend
						onToggleFaq={this.handleToggleFaq}
						faqOpened={this.state.faqOpened}
						isLegendShowBlue={this.state.isLegendShowBlue}
					/>
				)}
				{this.props.component === 'analytics' && location.pathname.includes('roadparts') && this.renderMunicipalities()}
				{this.props.component === 'analytics' && this.state.mu ? this.renderMu() : null}
				{this.props.component === 'analytics' && this.state.muTasksObjects ? this.renderMuRoadParts() : null}
				{window.RNIS_SETTINGS.CITY_MURMANSK &&
					this.state.contractsToRender && this.state.contractsToRender?.length &&
					this.renderMuContracts()}
			</Page>
		);
	}
}
