import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';

import './index.less';
import ContextTooltip from "components/ui/context-tooltip";

@propTypes({
    active: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
})

export default class MapFullscreenControl extends Component {

    render() {
        return (
            <ContextTooltip key="map.fullscreen" code="map.fullscreen" default="Развернуть карту по размеру окна" position="left">
                <div className="map-fullscreen-control" onClick={this.props.toggle}/>
            </ContextTooltip>
        )
    }

}