import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import PageModal from 'components/ui/page-modal';

import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import * as alerts from "helpers/alerts";

import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import currentUser from 'helpers/current-user';
import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {getCarrier, createCarrier, updateCarrier} from "store/reducers/organizational_units/carriers";
import BaseEditor from "components/base/base-editor";
import {getUnits} from "store/reducers/organizational_units/units";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getCarrier, createCarrier, updateCarrier})

export default class Editor extends BaseEditor {

    title = 'перевозчика';
    modelClass = 'App\\Model\\Carrier';

    async loadData(uuid) {
        return await this.props.getCarrier(uuid);
    }

    async createItem(data) {
        return await this.props.createCarrier(data);
    }

    async updateItem(data) {
        return await this.props.updateCarrier(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getUnits}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        carrier: {},
    };

    getData() {
        return this.state.carrier;
    }

    async componentDidMount() {
        await this.setState({
            carrier: this.props.data,
        });
        this.loadUnits();
    }

    async loadUnits() {
        const response = await this.props.getUnits();
        if (response.isOk) {
            this.setState({
                units: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.carrier, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} single={true} title="Общая информация">
                        <Block size="xl" title="Наименование">
                            {this.textInput('carrier.name')}
                        </Block>
                        <Block title="ИНН">
                            {this.textInput('carrier.inn')}
                        </Block>
                        <Block title="КПП">
                            {this.textInput('carrier.kpp')}
                        </Block>
                        <Block title="Предприятие">
                            {this.select('carrier.unit_uuid', this.state.units)}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}