import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import './clusters-toggle.less';
import {LabeledCheckbox} from "components/ui/checkbox";

@propTypes({
    active: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
})

export default class ClustersToggle extends Component {

    render() {
        return (
            <LabeledCheckbox
                label="Кластеры"
                checked={this.props.active}
                onChange={this.props.toggle}
            />
        );
    }

}