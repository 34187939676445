export default {
    events: {
        'App\\Model\\BNSO': {
            'com.rnis.telematics.event.panic': 'Нажата тревожная кнопка',
        },
        'App\\Model\\Vehicle': {
            'com.rnis.gatn.event.speed': 'Превышение скорости, ГАТН',
            'com.rnis.gatn.event.zone': 'Выход за пределы МО, ГАТН',
        },
    },
    via: {
        sms: 'СМС',
        email: 'Email',
        system: 'Лента',
        journal: 'Журнал',
    },
    levels: {
        normal: 'Обычный',
        high: 'Высокий',
        critical: 'Критический',
    },
};