import React from 'react';
import {connect} from 'react-redux';


import _ from 'lodash';
import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {EntityList} from "helpers/entity";
import systems from "dictionaries/systems";
import ReactDOMServer from 'react-dom/server';
import currentUser from 'helpers/current-user';
import {
    deleteEventRule, getEventRules, getEventTypes, notificationEventCreated,
    notificationEventRead
} from "store/reducers/notifications";
import {getEntityNames} from "store/reducers/system";
import {State} from "components/ui/state";
import {getVehicle} from "store/reducers/vehicles/vehicles";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";

@connect(state => ({}), {
    getVehicle,
    getEventRules,
    deleteEventRule,
    getEntityNames,
    getEventTypes,
    notificationEventCreated,
    notificationEventRead
})

export default class NotificationRulesComponent extends BaseTableWithEditorComponent {

    title = `${systems.administration} → События и оповещения`;

    componentDidMount() {
        this.loadEventTypes();
    }

    async loadEventTypes() {
        const response = await this.props.getEventTypes();
        if (response.isOk) {
            this.setState({
                event_types: _.mapValues(_.keyBy(response.payload.items, 'key'), 'name'),
            });
        } else {
            response.showErrors();
        }
    }

    getBaseUrl() {
        return '/operator/notifications';
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Событие')
                .fromField('name')
                .withDrawer(item => `${item.name} (${item.code})`),

            new Column('Тип')
                .fromField('type')
                .withDrawer(item => _.get(this.state.event_types, item.type)),

            new Column('Активно')
                .fromField('is_active')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_active}/>))
                .withFilter('isActive', async () => {
                    return [
                        {
                            uuid: 1,
                            name: 'Активно'
                        },
                        {
                            uuid: 0,
                            name: 'Не активно'
                        },
                    ]
                })
                .withAlign('center'),

            new Column('Структурная принадлежность')
                .fromField('units')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer((item) => _.map(item.units, (unitUuid) => this.state.related.get(unitUuid)).join('<br/>')),

            new Column('Оповещения по событию')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer((item) => _.map(item.notifications, (notification) => notification.name).join('<br/>')),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteEventRule(data);
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="notifications.mailing" code="notifications.mailing"
                            default="Пакетная рассылка сообщений" position="left">
                <IconButton icon="settings" onClick={::this.gotoMailing}/>
            </ContextTooltip>,
        ];
    }

    gotoMailing() {
        this.props.router.push('/notifications/mailing');
    }

    async loadData(meta) {
        const vehicleUuid = this.props.location.query.vehicle_uuid;
        if (vehicleUuid) {
            const response = await this.props.getVehicle(vehicleUuid);
            if (response.isOk && response.payload.unit_uuid) {
                meta.filters = {
                    withVehicle: response.payload,
                };
            }
        }
        return await this.props.getEventRules(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const units = _.filter(_.flatten(_.map(result, 'units')));
        const response = await this.props.getEntityNames(_.map(units, (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        })));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    getCreateButton() {
        if (!currentUser.can('com.rnis.notifications.event_rule', 'create')) {
            return null;
        }

        return super.getCreateButton();
    }

}