import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import Popup from "components/ui/popup";
import GlobalLoaderComponent from "components/ui/global-loader";
import {getOrders} from "store/reducers/kiutr/orders/orders";
import moment from "moment";
import formats from "dictionaries/formats";

import './ScheduleCheckModal.less';
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import ContextTooltip from "components/ui/context-tooltip";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";

@propTypes({
    uuid: PropTypes.string.isRequired,
    shifts: PropTypes.array,
    route: PropTypes.object,
    onClose: PropTypes.func,
})

@connect(state => ({}), {
    getOrders,
    getDictionaryList,
})

export default class ScheduleCheckModal extends Component {

    state = {
        loading: false,
        orders: [],
    };

    componentWillMount() {
        //this.loadOrders();
        this.getDictionary('transport_connection_types');
    }

    async getDictionary(dictionary, component = null, withoutOrder = false) {
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionary, meta);
        if (response.isOk) {
            const values = _.map(response.payload.documents, (document) => ({
                value: document.uuid,
                label: document.short_name || document.name,
                document,
            }));

            let state = this.state;
            state[dictionary] = values;
            this.setState(state);
        } else {
            response.showErrors();
        }
    }

    async loadOrders() {
        this.setState({loading: true});

        const response = await this.props.getOrders({
            filters: {
                withDate: moment().format(formats.DATE_API),
                withSchedule: this.props.uuid,
            },
            order: {
                column: 'turn',
                direction: 'asc',
            },
        });

        this.setState({loading: false});

        if (response.isOk) {
            await this.setState({
                orders: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        return (
            <Popup
                className="top-link ScheduleCheckModal"
                show={true}
                onClose={this.props.onClose}>
                <div className="popup-container__content">
                    {this.state.loading ? <GlobalLoaderComponent/> : null}
                    <Accordion>
                        {_.map(this.getParameters(), (parameter, index) => {
                            return (
                                <AccordionItem key={index} afterTitle={(
                                    <div className="accordion__title">
                                        {parameter.label}
                                        <ContextTooltip default={parameter.comment}>
                                            <span className="check-info">i</span>
                                        </ContextTooltip>
                                    </div>
                                )}>
                                    {(parameter.rows.length === 0) ? (
                                        <div>нет записей</div>
                                    ) : null}
                                    {_.map(parameter.rows, (rowLabel, _index) => {
                                        return (
                                            <div key={_index}>
                                                {rowLabel}
                                            </div>
                                        );
                                    })}
                                </AccordionItem>
                            );
                        })}
                    </Accordion>
                </div>
            </Popup>
        );
    }

    getParameters() {
        const isIntercity = _.get(_.find(this.state.transport_connection_types, {value: this.props.route.transport_connection_type_uuid}), 'label') === 'Междугородный';

        return _.filter([
            {
                label: 'Время в наряде',
                comment: 'Время в наряде для водителя не должно превышать 12 часов (параметр «Время в наряде» рассчитывается (в рамках каждой смены выхода (графика)) также, как значение колонки «Т нар.» выходной формы «Водительское расписание (спиральное)»)',
                rows: this.getTimeInOrderRows(),
            },
            {
                label: 'Время в управлении',
                comment: 'Время в управлении для водителя не должно превышать 10 часов (параметр «Время управления» рассчитывается (в рамках каждой смены выхода (графика)) также, как значение колонки «Т упр.» выходной формы «Водительское расписание (спиральное)»)',
                rows: this.getTimeInDriveRows(),
            },
            {
                label: 'Перерыв в графике',
                comment: 'Перерыв продолжительностью не менее 15 минут предоставляется при разрывном графике работы, если перерыв между двумя частями рабочей смены установлен после 4 часов с начала рабочей смены и не может составлять более 2 часов в рамках каждого выхода',
                rows: this.getSettlingRows(),
            },
            isIntercity ? {
                label: 'Перерыв в графике (меж.г.)',
                comment: 'На междугородних маршрутах перерыв для отдыха от управления автомобилем не менее 15 минут предоставляется после первых четырех часов непрерывного управления автомобилем, а в дальнейшем - не более, чем через каждые 2 часа',
                rows: this.getIntercitySettlingRows(),
            } : null,
            {
                label: 'Продолжительность обеда',
                comment: 'Минимальная продолжительность обеда должна быть не менее 30 минут (параметр «Минимальная продолжительность обеда» рассчитывается (в рамках каждого обеда) как разница времён окончания технологической операции «Обед» (далее - т/о) и начала т/о (исключая времена нулевого рейса и межрейсового отстоя перед началом т/о и после окончания т/о, при их наличии))',
                rows: this.getDinnerRows(),
            },
            {
                label: 'Суммарная продолжительность обеда',
                comment: 'Максимальная суммарная продолжительность обедов не должна превышать 2 часов (параметр «Максимальная суммарная продолжительность обедов» рассчитывается (в рамках каждого выхода(графика)) как сумма разниц времён окончаний и начала] каждого обеда (исключая времена нулевого рейса и межрейсового отстоя перед началом т/о и после окончания т/о, при их наличии))',
                rows: this.getDinnerSumRows(),
            },
        ]);
    }

    formatTime(minutes) {
        if (isNaN(minutes)) {
            return '00:00';
        }
        return _.padStart(Math.floor(minutes / 60), 2, '0') + ':' + _.padStart(minutes % 60, 2, '0');
    }

    getSettlingTime(turn) {
        return _.sumBy(_.filter(turn.runs, (run) => (
            _.indexOf([
                'dinner',
                'gap',
                'reshift',
                'settling',
            ], run.type) !== -1
        )), (run) => (_.toInteger(run.time)));
    }

    getDriveTime(turn) {
        const driveRuns = _.filter(turn.runs, (run) => (
            _.indexOf([
                'dinner',
                'gap',
                'reshift',
                'settling',
            ], run.type) === -1
        ));

        const driveStart = _.first(driveRuns);
        const driveEnd = _.last(driveRuns);

        return {
            driveStartTime: driveStart ? moment(driveStart.date_from) : null,
            driveEndTime: driveEnd ? moment(driveEnd.date_to) : null,
        };
    }

    getDriverNames(shift) {
        return _.uniq(_.filter(_.map(shift.runs, 'driver_name'))).join(', ') || '-';
    }

    timeToInt(time) {
        const timeObject = moment(time, formats.TIME);
        return timeObject.hours() * 60 + timeObject.minutes();
    }

    getShifts() {
        let date = moment().startOf('day');
        return _.map(this.props.shifts, (shift) => {
            shift.runs = _.map(shift.runs, (run) => {
                run.date_from = moment(date).minutes(this.timeToInt(run.start_time));
                run.date_to = moment(date).minutes(this.timeToInt(run.start_time)).add(run.time, 'minutes');
                if (run.date_from.dayOfYear() !== run.date_to.dayOfYear()) {
                    date = date.add(1, 'day');
                }

                if (!shift.start_at) {
                    shift.start_at = run.date_from;
                }

                return run;
            });


            return shift;
        });
    }

    getTimeInOrderRows() {
        return _.flatten(_.map(this.getShifts(), (shift) => {
            const settlingTime = this.getSettlingTime(shift);
            const {driveStartTime, driveEndTime} = this.getDriveTime(shift);
            const time = (driveStartTime && driveEndTime) ? (Math.abs(driveStartTime.diff(driveEndTime, 'minutes')) - settlingTime) : 0;
            return (
                <span className={(time > 60 * 12) ? 'has-error' : ''}>
                    Выход {shift.turn}, Смена {shift.number}, {this.formatTime(time)}
                </span>
            );
        }));
    }

    getTimeInDriveRows() {
        return _.flatten(_.map(this.getShifts(), (shift) => {
            const runs = _.filter(shift.runs, (run) => (
                run.type === 'production_forward' || run.type === 'production_reverse'
            ));
            const time = _.sumBy(runs, (run) => (_.toInteger(run.time)));

            return (
                <span className={(time > 60 * 10) ? 'has-error' : ''}>
                    Выход {shift.turn}, Смена {shift.number}, {this.formatTime(time)}
                </span>
            );
        }));
    }

    getSettlingRows() {
        return _.flatten(_.map(this.getShifts(), (shift) => {
            const runs = _.values(_.filter(shift.runs, {type: 'dinner'}));
            let lastSettlingTime = null;

            return _.map(runs, (run, index) => {
                const time = _.toInteger(run.time);
                const timeError = (time > 60 * 2) ? ', Время перерыва больше 2-х часов' : '';

                const minutesFromLastSettling = moment(run.date_from).diff(moment(lastSettlingTime ? lastSettlingTime : shift.start_at), 'minutes');
                lastSettlingTime = run.date_to;

                return (
                    <ContextTooltip default="Выход №, Смена №, Перерыв №, Время между перерывами, Время перерыва">
                        <span className={((time < 15) || (minutesFromLastSettling > 60 * 4)) ? 'has-error' : ''}>
                            Выход {shift.turn}, Смена {shift.number}, Перерыв {index + 1}, {this.formatTime(minutesFromLastSettling)}, {this.formatTime(time)}{timeError}
                        </span>
                    </ContextTooltip>
                );
            });
        }));
    }

    getIntercitySettlingRows() {
        return _.flatten(_.map(this.getShifts(), (shift) => {
            const runs = _.values(_.filter(shift.runs, {type: 'dinner'}));
            let lastSettlingTime = null;

            return _.map(runs, (run, index) => {
                const time = _.toInteger(run.time);
                const minutesFromLastSettling = moment(run.date_from).diff(moment(lastSettlingTime ? lastSettlingTime : shift.start_at), 'minutes');
                const hasError = (!lastSettlingTime && (minutesFromLastSettling > 60 * 4)) || (lastSettlingTime && (minutesFromLastSettling > 60 * 2)) || (time < 15);
                lastSettlingTime = run.date_to;

                return (
                    <ContextTooltip default="Выход №, Смена №, Перерыв №, Время между перерывами">
                        <span className={hasError ? 'has-error' : ''}>
                            Выход {shift.turn}, Смена {shift.number}, Перерыв {index + 1}, {this.formatTime(minutesFromLastSettling)}
                        </span>
                    </ContextTooltip>
                );
            });
        }));
    }

    getDinnerRows() {
        return _.flatten(_.map(this.getShifts(), (shift) => {
            const runs = _.values(_.filter(shift.runs, {type: 'dinner'}));

            return _.map(runs, (run, index) => {
                const time = _.toInteger(run.time);

                return (
                    <span className={(time < 30) ? 'has-error' : ''}>
                        Выход {shift.turn}, Смена {shift.number}, Перерыв на обед {index + 1}, {this.formatTime(time)}
                    </span>
                );
            });
        }));
    }

    getDinnerSumRows() {
        return _.flatten(_.map(this.getShifts(), (shift) => {
            const runs = _.values(_.filter(shift.runs, {type: 'dinner'}));
            const time = _.sumBy(runs, (run) => (_.toInteger(run.time)));

            return (
                <span className={(time > 60 * 2) ? 'has-error' : ''}>
                    Выход {shift.turn}, Смена {shift.number}, {this.formatTime(time)}
                </span>
            );
        }));
    }
}