import { date, months, days } from './constants';
import moment from 'moment';

export const getIntervalsBetweenTime = (hours, intervals, interval) => {
	return hours.reduce((prev, cur, i) => {
		intervals[`${prev} - ${cur}`] = {
			start_at: `${prev}`, // время начала действия интервала движения
			end_at: `${cur}`, // время окончания действия интервала движения
			interval
		};
		return cur;
	});
};

export const getHours = () =>
	Array(24).fill().map((_, index) =>
		moment({
			hour: index,
			minutes: 0
		}).format('HH:mm')
	);

export const getSelectedMonthCount = (data) =>
	Object.keys(data).reduce((prev, cur) => {
		if (months.includes(cur) && data[cur]) {
			return [ ...prev, true ];
		}
		return prev;
	}, []).length;

export const getSelectedDaysCount = (data) =>
	Object.keys(data).reduce((prev, cur) => {
		if (days.includes(cur) && data[cur]) {
			return [ ...prev, true ];
		}
		return prev;
	}, []).length;

export const isIntervalIntersectsOthers = (intervals, interval_start, interval_end) =>
	Object.values(intervals).filter(({ start_at, end_at }) => {
		return (
			moment(date + ' ' + interval_start).isBetween(moment(date + ' ' + start_at), moment(date + ' ' + end_at)) ||
			moment(date + ' ' + interval_end).isBetween(moment(date + ' ' + start_at), moment(date + ' ' + end_at))
		);
	}).length > 0;

export const isIntervalTimesNotValid = (interval_start, interval_end) =>
	!moment(date + ' ' + interval_start).isValid() || !moment(date + ' ' + interval_end).isValid();

export const getLastTurnTime = (routeVariants, start_time_last_backward) => {
	const minutesForTurnArray = routeVariants.map((routeVariant) => {
		if (routeVariant.forward_points.length > 0) {
			return routeVariant.forward_points.reduce((prev, cur) => {
				prev = prev + Number(cur.time_to_get_to_the_next_point);
				return prev;
			}, 0);
		}
		if (routeVariant.reverse_points.length > 0) {
			return routeVariant.reverse_points.reduce((prev, cur) => {
				prev = prev + Number(cur.time_to_get_to_the_next_point);
				return prev;
			}, 0);
		}
		return 0;
	});
	const maxTurnMinutes = Math.max(...minutesForTurnArray);
	return moment(date + ' ' + start_time_last_backward)
		.add(maxTurnMinutes, 'minutes')
		.format('HH:mm');
};
