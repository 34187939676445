import React, {Component} from 'react';
import Page from './ui/page';
import FilterHeader from './ui/filter-header';
import ReactGridLayout from 'react-grid-layout';
import {Responsive, WidthProvider} from 'react-grid-layout';
const ResponsiveReactGridLayout = WidthProvider(Responsive);
import Block from './ui/block';
import LineChart from './charts/line-chart';
import BarChart from './charts/bar-chart';
import SafeDriveStat from './stat/safe-drive-stat';
import TimeControlStat from './stat/time-control-stat';
import './Main.less';
import SIP from 'components/ui/sip_block';
import currentUser from 'helpers/current-user';
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import RouteSelectBtn from "components/modules/main/Route-Select-Btn/index";
import _ from 'lodash';
import WidgetEllipse from "components/modules/main/WidgetEllipse/index";
import WidgetBlock from "components/modules/analytics/base-components/WidgetBlock/index";

import $ from 'jquery';
import {GlobalEvent} from "helpers/event-system";

export default class MainComponent extends Component {

    state = {
        currentFilterItem: 0,
        ellipses: [],
    };

    componentDidMount() {
        if (!currentUser.isSupervisor() && currentUser.can('com.rnis.portal.menu')) {
            this.props.router.push('/portal/news');
        }

        this.onGridItemClick = this.onGridItemClick.bind(this);

        $('body').on('click', '.react-grid-item', this.onGridItemClick);
    }

    componentWillUnmount() {
        $('body').off('click', this.onGridItemClick);
    }

    onGridItemClick(e) {
        $('.react-grid-item.active').removeClass('active');
        $(e.target).closest('.react-grid-item').addClass('active');
    }

    render() {
        return (
            <Page
                pageId="Main"
                title="Контроль прохождения КП"
                headerContents={this.renderHeaderContents()}>
                <div id="blocks">
                    {this.renderContent()}
                </div>
            </Page>
        );
    }

    renderHeaderContents() {
        return [
            <RouteSelectBtn
                key="route-select-btn"
                onSelect={::this.onRouteSelect}
            />
        ];
    }

    onRouteSelect(route) {
        let ellipses = this.state.ellipses;
        if (_.find(ellipses, {uuid: route.uuid})) {
            return;
        }
        ellipses.push(route);
        this.setState({ellipses});
    }

    onRouteDeselect(index) {
        let ellipses = this.state.ellipses;
        ellipses.splice(index, 1);
        this.setState({ellipses});
    }

    renderContent() {
        const layout = _.map(this.state.ellipses, (route, index) => ({
            i: `${route.uuid}`,
            x: 6 * (index % 2),
            y: 5 * Math.floor(index / 2),
            w: 6,
            h: 11,
            minH: 11,
        }));

        const layoutMd = layout;

        const content = _.map(this.state.ellipses, ::this.renderEllipse);

        return (
            <ResponsiveReactGridLayout
                className="layout"
                layouts={{lg: layout, md: layoutMd}}
                rowHeight={40}
                breakpoints={{lg: 1200, md: 1000}}
                cols={{lg: 12, md: 12}}
                draggableCancel=".ellipse-vehicles-filter,.sip-block"
                onResize={::this.onResize}
            >
                {content}
            </ResponsiveReactGridLayout>
        );
    }

    onResize() {
        GlobalEvent().call('grid-resize');
    }

    renderEllipse(route, index) {
        return (
            <div key={`${route.uuid}`}>
                <Block
                    title={`Маршрут ${_.get(route, 'number')}`}
                    subtitle={_.get(route, 'title')}
                    onClose={this.onRouteDeselect.bind(this, index)}
                >
                    <WidgetEllipse
                        routeUuid={route.uuid}
                    />
                </Block>
            </div>
        );
    }
}