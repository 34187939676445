import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getVehicleMechanisms = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.kurs.getVehicleMechanisms(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getVehicleMechanism = (uuid) => async (dispatch) => {
    try {
        const response = await api.kurs.getVehicleMechanism(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createVehicleMechanism = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.createVehicleMechanism(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateVehicleMechanism = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.updateVehicleMechanism(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteVehicleMechanism = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.deleteVehicleMechanism(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};