import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import AbstractMapMarker from 'components/ui/map/base/marker';
import _ from 'lodash';
import ReactPopup from 'react-leaflet-popup';
import EventIcon from 'components/ui/map/markers/icons/event-icon';
import events from 'dictionaries/events';
import moment from 'moment';
import formats from 'dictionaries/formats';
import L from 'leaflet';

@propTypes({
	item: PropTypes.object
})
export default class EventMarker extends AbstractMapMarker {
	getOptions() {
		return {
			icon: EventIcon.getIcon(),
			riseOnHover: true
		};
	}

	componentWillMount() {
		super.componentWillMount();

		if (this.props.onClick === null) {
			this.getMarker().on('click', ::this.loadPopup);
		}
	}

	componentDidMount() {
		super.componentDidMount();

		this.forceUpdate();
	}

	componentWillUpdate(props) {
		const oldItem = this.props.item;
		const newItem = props.item;

		this.checkTitleUpdate(oldItem, newItem);
	}

	checkTitleUpdate(oldItem, newItem) {
		const marker = this.getMarker();
		const oldTitle = this.getTooltip(oldItem);
		const newTitle = this.getTooltip(newItem);
		if ((oldTitle !== newTitle || !marker.getTooltip()) && newTitle) {
			marker.unbindTooltip().bindTooltip(this.getTooltip(newItem), { sticky: true });
		}
	}

	getTooltip(item) {
		return `${item.data.title}: ${_.get(item.vehicle, 'state_number')}`;
	}

	loadPopup() {
		if (!this.props.item) {
			return;
		}

		const marker = this.getMarker();
		marker.unbindPopup();
		marker.closePopup();
		marker.bindPopup(this.createPopup(::marker.closePopup));
		marker.openPopup();
	}

	createPopup(onClose) {
		const item = this.props.item;
		if (!item) {
			return null;
		}

		return new ReactPopup({
			reactComponent: EventPopup,
			reactComponentProps: {
				...this.props,
				item,
				onClose
			},
			minWidth: 434,
			maxWidth: 434,
			offset: new L.Point(3, -250)
		});
	}
}

class EventPopup extends Component {
	render() {
		const { item, onClose, vehicle_models } = this.props;
		const vehicleModelUuid = _.get(item.vehicle, 'vehicle_model_uuid');

		return (
			<div className="b-modal b-modal-map map-tooltip-modal tooltip-event">
				<div className="b-modal__header">
					<a href="#" className="b-modal__close" onClick={onClose} />
					<div className="b-modal__name">{item.data.title}</div>
				</div>
				<div className="b-modal__body">
					<div className="b-block">
						<div className="b-block__desc">Время регистрации</div>
						<div className="b-block__value">{moment(item.created_at).format(formats.DATETIME)}</div>
					</div>
					<div className="b-block">
						<div className="b-block__desc">Модель ТС</div>
						<div className="b-block__value">
							{_.get(vehicle_models, vehicleModelUuid + '.name')}
						</div>
					</div>
					<div className="b-block">
						<div className="b-block__desc">Госномер ТС</div>
						<div className="b-block__value">{_.get(item.vehicle, 'state_number')}</div>
					</div>
					<div className="b-block">
						<div className="b-block__desc">Номер БНСО</div>
						<div className="b-block__value">{_.get(item.vehicle, 'current_bnso.bnso_code')}</div>
					</div>
					<div className="b-block">
						<div className="b-block__desc">Координаты</div>
						<div className="b-block__value">
							{_.round(item.data.latitude, 6)}, {_.round(item.data.longitude, 6)}
						</div>
					</div>
				</div>
				<div className="b-modal__footer" />
				;{' '}
			</div>
		);
	}
}
