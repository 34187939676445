import * as storage from "utils/storage";
import {api} from "helpers/api";
import _ from 'lodash';
import {getCurrentUser} from "helpers/api/session";
import {cached} from "helpers/cached";
import {makeResponse} from "helpers/response";

class SettingsObject {
    __settings = {};
    inited = false;
    userSettings = {};

    constructor() {
        this.__uuid = _.get(storage.get('user'), 'uuid');
        this.__settings = storage.get('settings', {});
    }

    /**
     * @param {string} key
     * @param {*} def
     * @return {*}
     */
    get(key, def = null) {
        return _.get(this.__settings, `global.${key}`, def);
    }

    /**
     * @param {object} settings
     */
    set(settings) {
        this.__settings = settings;
        storage.set('settings', settings);
    }

    async getUser(key) {
        const settings = this.userSettings;
        const userUuid = _.get(getCurrentUser(), 'uuid');
        const setting = _.get(settings, `${userUuid}.${key}`);
        return setting;

        /*try {
            const response = await api.system.getOption(key);

            return _.first(response.payload.items);
        }
        catch (e) {
        }
        return null;*/
    }

    async setUser(key, value) {
        await api.system.setUserOptionValue(key, value);
    }

    /**
     * @return {SettingsObject}
     */
    get user() {
        return new SettingsObject(this.get(this.__uuid, {}));
    }

    async init() {
        if (this.inited) return;
        this.inited = true;
        const response = await cached('options', async () => {
            return await makeResponse(() => {
                return api.system.getOptions();
            }, 'options');
        }, true, 15);
        if (response.isOk && response?.payload?.items) {
            this.set(response.payload.items);
        }
    }
}

let Settings = new SettingsObject();


api.wsrpc.connectionEstablished(() => {
    Settings.init();
});
export default Settings;
