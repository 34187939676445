import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import _ from 'lodash';

import {connect} from "react-redux";
import {
    createReport, deleteReportTemplate, getReportParameters, getReports,
    listReportTemplates
} from "store/reducers/reports/reports";
import GlobalLoaderComponent from "components/ui/global-loader";
import moment from "moment";
import formats from "dictionaries/formats";
import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ContextTooltip from "components/ui/context-tooltip";
import Button from "components/ui/button";
import {rpc} from 'helpers/api/session';
import {success, error} from 'helpers/response';
import RoadPartsSelect from "components/modules/reports/parameters/RoadPartsSelect/index";

/* import UseCases for Report */
import shortSummaryContractWorkReportCase from "./customUseCase/short_summary_contract_work_report";

@propTypes({
    selectedReport: PropTypes.object,
    onClose: PropTypes.func,
})

@defaultProps({
    onClose: () => {
    },
})

@connect((state) => ({}), {getReportParameters, createReport, listReportTemplates, deleteReportTemplate})

export default class ReportParameters extends BaseEditorFormComponent {
    state = {
        loading: false,
        report: null,
        parameters: [],
        parameterValues: [],
        errors: {},
        report_template: {
            report_template_uuid: null,
            create_template: false,
            name: '',
        },
        templates: [],
        roadPartsSelectActive: false,
    };

    async componentWillUpdate(props, state) {
        if (!props.selectedReport) {
            if (state.report) {
                this.setState({
                    loading: false,
                    report: null,
                    parameters: [],
                    errors: {},
                });
            }
        } else {
            if (!state.report || (state.report.uri !== props.selectedReport.uri)) {
                this.setState({loading: true, report: props.selectedReport});

                this.loadTemplates(props.selectedReport.uri);

                const response = await this.props.getReportParameters(props.selectedReport);
                if (response.isOk) {
                    const parameters = response.payload.items;

                    this.setState({
                        loading: false,
                        parameters,
                        parameterValues: this.getDefaultParameterValues(parameters),
                        errors: {},
                        report_template: {
                            report_template_uuid: null,
                            create_template: false,
                            name: '',
                        },
                    });
                } else {
                    response.showErrors();
                }
            }
        }
    }

    getDefaultParameterValues(parameters) {
        return _.map(parameters, (parameter) => {
            const {key, type} = parameter;

            const locationParameter = _.get(this.props.location.query, key);
            if (locationParameter) {
                if (type === 'multiSelectAsync') {
                    return _.filter(locationParameter.split(';'));
                }
                return locationParameter;
            }

            return _.get(parameter, 'value');
        });
    }

    async loadTemplates(uri) {
        const response = await this.props.listReportTemplates({
            filters: {
                withReport: uri,
            },
        });

        if (response.isOk) {
            this.setState({
                templates: _.map(response.payload.items, (template) => ({
                    value: template.uuid,
                    label: template.name,
                    parameters: template.parameters,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        const loader = this.state.loading ? <GlobalLoaderComponent/> : null;

        if (!this.state.report) {
            return null;
        }

        return (
            <div className="page-block report-param">
                <div className="page-block__caption">Параметры отчета</div>
                <div className="page-block__content">
                    {this.state.parameters.map(::this.renderParameter)}
                    <div>
                        <div className="block-title">Шаблон</div>
                        <Block size="xl" title="Выбрать шаблон">
                            {this.select('report_template.report_template_uuid', this.state.templates, {
                                onChange: ::this.onTemplateChange,
                            })}
                            {this.state.report_template.report_template_uuid ? (
                                <a href="javascript:void(0)" onClick={::this.deleteTemplate}>Удалить шаблон</a>
                            ) : null}
                        </Block>
                        <Block size="xl">
                            {this.checkbox('report_template.create_template', 'Сохранить шаблон')}
                        </Block>
                        <Block size="xl" title="Наименование">
                            {this.textInput('report_template.name')}
                        </Block>
                    </div>
                </div>
                <div className="page-block__footer">
                    <Button size="md" color="red" className="b-button_save" text="Сформировать"
                            onClick={::this.createReport}/>
                </div>
                {loader}

                {this.renderModals()}
            </div>
        );
    }

    async deleteTemplate() {
        const response = await this.props.deleteReportTemplate({
            uuid: this.state.report_template.report_template_uuid,
        });
        if (response.isOk) {
            this.setValue('report_template.report_template_uuid', null);
            this.loadTemplates(this.state.report.uri);
        } else {
            response.showErrors();
        }
    }

    onTemplateChange(e) {
        const value = e ? e.value : null;

        this.setValue('report_template.report_template_uuid', value);
        if (value) {
            const parameters = e.parameters;
            _.each(this.state.parameters, async (parameter, index) => {
                const templateParameter = _.find(parameters.items, {key: parameter.key});
                if (templateParameter) {
                    if ((parameter.type === 'multiSelectAsync') || (parameter.type === 'multiSelect')) {
                        await this.setValue(`parameterValues.${index}`, _.filter(templateParameter.value.split(';')), null, true);
                    } else {
                        await this.setValue(`parameterValues.${index}`, templateParameter.value, null, true);
                    }

                    this.refs[parameter.key] && this.refs[parameter.key].reload && this.refs[parameter.key].reload();
                }
            });
        } else {
            _.each(this.state.parameters, async (parameter, index) => {
                if ((parameter.type === 'multiSelectAsync') || (parameter.type === 'multiSelect')) {
                    await this.setValue(`parameterValues.${index}`, []);
                } else {
                    await this.setValue(`parameterValues.${index}`, null);
                }

                this.refs[parameter.key] && this.refs[parameter.key].reload && this.refs[parameter.key].reload();
            });
        }
    }

    async createReport() {
        this.setState({loading: true});
        const parameters = this.getParameters();
        let payload = {
            report: this.state.report,
                parameters: {
                    items: parameters,
                },
                report_template_uuid: this.state.report_template.report_template_uuid,
                create_template: this.state.report_template.create_template,
                template_name: this.state.report_template.name,
        }
        let meta = {}
        if(this.state.report.uri.includes("summary_vehicle_types_report")) {
            meta.filters = {
                withComponent: "road",
            }
        }
        if (this.state.report.name.includes("8А Ведомость пробегов")) {
            payload.withComponent = "road"
            meta.withComponent = "road"
        }
        const response = await this.props.createReport(payload, meta);
        this.setState({loading: false});
        if (response.isOk) {
            this.props.onClose();
        } else {
            response.showErrors();
            this.setState({
                errors: response.validationErrors,
            });
        }
    }

    getError(fieldName) {
        const matches = /^parameterValues\.([0-9]+)$/.exec(fieldName);
        if (matches) {
            const index = matches[1];
            const parameter = this.state.parameters[index];

            return _.get(this.state.errors, parameter.key);
        }
        const field = _.findLast(fieldName.split('.'));

        return _.get(this.state.errors, field) || _.get(this.state.errors, fieldName);
    }

    clearErrors() {
        this.setState({
            errors: {},
        });
    }

    async setValue(field, originalValue, additionalData = null, ignoreRules = false) {
        let value = originalValue;
        if (_.isArray(value)) {
            value = _.uniqBy(value, (v) => {
                return v.value || v;
            });
        }
        await super.setValue(field, value);
        this.clearErrors();

        if (ignoreRules) {
            return;
        }

        const result = await shortSummaryContractWorkReportCase.bind(this, ['select', field, originalValue])();

        let finded = false;
        _.each(this.state.parameters, (parameter, index) => {
            if (finded) {
                if (result !== false) {
                    this.refs[parameter.key] && this.refs[parameter.key].reload && this.refs[parameter.key].reload();
                }
            }
            if (field === `parameterValues.${index}`) {
                finded = true;

                const subject = _.get(parameter, 'options.subject');
                switch (subject) {
                    case 'com.rnis.vehicles.action.vehicle.list':
                        let unitUuid = _.get(additionalData, 'document.unit_uuid');
                        if (_.isArray(originalValue)) {
                            unitUuid = _.get(_.first(originalValue), 'document.unit_uuid');
                        }

                        this.setUnit(unitUuid);

                        /*let vehicleModelUuid = _.get(additionalData, 'document.vehicle_model_uuid');
                        if (_.isArray(originalValue)) {
                            vehicleModelUuid = _.get(_.first(originalValue), 'document.vehicle_model_uuid');
                        }
                        this.setVehicleModel(vehicleModelUuid);

                        let vehicleTypeUuid = _.get(additionalData, 'document.vehicle_type_uuid');
                        if (_.isArray(originalValue)) {
                            vehicleTypeUuid = _.get(_.first(originalValue), 'document.vehicle_type_uuid');
                        }
                        this.setVehicleType(vehicleTypeUuid);*/

                        break;
                }
            }
        });
    }

    setUnit(unitUuid) {
        _.each(this.state.parameters, async (parameter, index) => {
            const subject = _.get(parameter, 'options.subject');
            if (subject === 'com.rnis.organizational_units.action.units') {
                switch (parameter.type) {
                    case 'singleSelectAsync':
                        await this.setValue(`parameterValues.${index}`, unitUuid);
                        this.refs[parameter.key] && this.refs[parameter.key].reload && this.refs[parameter.key].reload();
                        break;
                    case 'multiSelectAsync':
                        await this.setValue(`parameterValues.${index}`, [unitUuid]);
                        this.refs[parameter.key] && this.refs[parameter.key].reload && this.refs[parameter.key].reload();
                        break;
                }
            }
        });
    }

    setVehicleModel(vehicleModelUuid) {
        if (!vehicleModelUuid) {
            return;
        }
        _.each(this.state.parameters, async (parameter, index) => {
            if (parameter.key === 'models') {
                await this.setValue(`parameterValues.${index}`, [vehicleModelUuid]);
            }
        });
    }

    setVehicleType(vehicleTypeUuid) {
        if (!vehicleTypeUuid) {
            return;
        }
        _.each(this.state.parameters, async (parameter, index) => {
            if (parameter.key === 'types') {
                await this.setValue(`parameterValues.${index}`, [vehicleTypeUuid]);
            }
        });
    }

    renderParameter(parameter, index) {
        switch (parameter.type) {
            case 'singleValueText':
                return (
                    <Block key={index} size="xl" title={parameter.name}>
                        {this.textInput(`parameterValues.${index}`)}
                    </Block>
                );
            case 'singleValueNumber':
                return (
                    <Block key={index} size="xl" title={parameter.name}>
                        {this.textInput(`parameterValues.${index}`, {
                            type: 'number',
                            positive: true,
                        })}
                    </Block>
                );
            case 'singleValueDate':
                return (
                    <Block key={index} size="xl" title={parameter.name}>
                        {this.datepicker(`parameterValues.${index}`)}
                    </Block>
                );
            case 'singleValueTime':
                return (
                    <Block key={index} size="xl" title={parameter.name}>
                        {this.maskInput(`parameterValues.${index}`, '99:99', {
                            withTimeIcon: true,
                        })}
                    </Block>
                );
            case 'singleSelect':
                return (
                    <Block key={index} size="xl" title={parameter.name}>
                        {this.select(`parameterValues.${index}`, _.toArray(this.filterOptions(parameter.options)), {
                            placeholder: parameter.placeholder || parameter.required ? 'Выбрать...' : 'Все',
                        })}
                    </Block>
                );
            case 'multiSelect':
                return (
                    <Block key={index} size="xl" title={parameter.name}>
                        {this.select(`parameterValues.${index}`, _.toArray(this.filterOptions(parameter.options)), {
                            multi: true,
                            placeholder: parameter.placeholder || parameter.required ? 'Выбрать...' : ((parameter.key !== 'municipalities') ? 'Все' : 'Выбрать...'),
                        })}
                    </Block>
                );
            case 'singleSelectAsync':
                return (
                    <Block key={index} size="xl" title={parameter.name}>
                        {this.selectAsync(`parameterValues.${index}`, (input, callback) => {
                            this.selectAsyncLoad(parameter, index, 20, input, callback);
                        }, {
                            ref: parameter.key,
                            placeholder: parameter.placeholder || parameter.required ? 'Выбрать...' : 'Все',
                            cache: false,
                            onChange: (e) => {
                                let value = e ? e.value : null;
                                this.setValue(`parameterValues.${index}`, value, e);
                            },
                        })}
                    </Block>
                );
            case 'multiSelectAsync':
                return (
                    <Block key={index} size="xl" title={<span>{parameter.name}</span>}>
                        {this.selectAsync(`parameterValues.${index}`, (input, callback) => {
                            this.selectAsyncLoad(parameter, index, 20, input, callback);
                        }, {
                            multi: true,
                            ref: parameter.key,
                            cache: false,
                            placeholder: parameter.placeholder || parameter.required ? 'Выбрать...' : 'Все',
                            onChange: this.onMultiSelectAsyncChange.bind(this, `parameterValues.${index}`),
                        })}
                        {(parameter.options.subject === 'com.rnis.kurs.action.road_part.list') ? (
                            <a href="javascript:void(0)" onClick={this.showRoadPartsSelect.bind(this, index)}>Выбрать на
                                карте</a>
                        ) : null}
                    </Block>
                );
        }

        return null;
    }

    selectAll(parameter, index) {
        this.selectAsyncLoad(parameter, index, 1000, '%', (tmp, {options}) => {
            this.setValue(`parameterValues.${index}`, options);
        });
    }

    showRoadPartsSelect(index) {
        this.setState({
            roadPartsSelectActive: true,
            roadPartsSelectIndex: index,
        });
    }

    hideRoadPartsSelect() {
        this.setState({
            roadPartsSelectActive: false,
        });
    }

    onMultiSelectAsyncChange(fieldName, e) {
        this.onChangeInput(fieldName, {target: {value: e}});
    }

    filterOptions(options) {
        if (_.isEmpty(options.filters)) {
            return options.options;
        }

        const parameters = this.getParametersRaw();

        return _.filter(options.options, (option) => {
            let good = false;
            _.each(options.filters, (filterValue, filterField) => {
                const matches = /^%([^%]+)%$/.exec(filterValue);
                if (matches) {
                    const value = _.get(_.find(parameters, {key: matches[1]}), 'value');
                    if (_.isArray(value)) {
                        if (_.indexOf(value, _.get(option, filterField)) !== -1) {
                            good = true;
                        }
                    } else {
                        if (value === _.get(option, filterField)) {
                            good = true;
                        }
                    }
                }
                return false;
            });

            return good;
        });
    }

    parseFilters(subject, filters) {
        const parameters = this.getParametersRaw();
        let result = {};
        _.each(filters, (value, key) => {
            const matches = /^%([^%]+)%$/.exec(value);
            if (matches) {
                const parameter = _.find(parameters, {key: matches[1]});
                const parameterValue = _.get(parameter, 'value');
                if (!parameterValue) {
                    return;
                }
                if (_.isArray(parameterValue) && (parameterValue.length > 0)) {
                    result[key] = parameterValue;
                    return;
                } else if (_.isArray(parameterValue) && (parameterValue.length === 0)) {
                    return;
                }
                result[key] = parameterValue;
                return;
            }
            result[key] = value;
            return;
        });

        return result;
    }

    async selectAsyncLoad(parameter, index, limit, input, callback) {
        parameter.key ==='contracts' ? limit = 100 : limit = 50;
        const subject = parameter.options.subject;
        const labelKey = parameter.options.labelKey;
        const valueKey = parameter.options.valueKey;
        const itemsKey = parameter.options.itemsKey || 'items';
        const payload = parameter.options.payload || {};
        const filters = this.parseFilters(parameter.options.subject, parameter.options.filters || {});

        if (!input) {
            await shortSummaryContractWorkReportCase.bind(this, ['load', null, null, subject, callback, filters, itemsKey, labelKey, valueKey])();
        }

        let response;
        if (!input) {
            input = this.getValue(`parameterValues.${index}`);

            if (input && parameter.type === 'multiSelectAsync') {
                if (_.isString(input)) {
                    return input;
                } else {
                    input = _.filter(_.map(input, (value) => {
                        if (!_.isString(value)) {
                            return value.value;
                        }
                        return value;
                    }));
                }

                try {
                    if (parameter.options.service === 'dictionary') {
                        filters.uuid = input;
                    } else {
                        filters.withUuid = input;
                    }
                    response = success(await rpc.request(subject, payload, {
                        meta: {
                            filters,
                        },
                    }));
                    const uuidsDiff = _.intersection(input, _.map(response.payload[itemsKey], 'uuid'));
                    if (input.length !== uuidsDiff.length) {
                        this.setValue(`parameterValues.${index}`, uuidsDiff.join(';'));
                    }
                }
                catch (e) {
                    response = error(e);
                }
            } else if (input && parameter.type === 'singleSelectAsync') {
                try {
                    if (parameter.options.service === 'dictionary') {
                        filters.uuid = [input];
                    } else {
                        filters.withUuid = [input];
                    }
                    response = success(await rpc.request(subject, payload, {
                        meta: {
                            filters,
                        },
                    }));
                    if (response.payload[itemsKey].length === 0) {
                        this.setValue(`parameterValues.${index}`, null);
                    }
                }
                catch (e) {
                    response = error(e);
                }
            }
        }
        if (!response) {
            try {
                response = success(await rpc.request(subject, payload, {
                    meta: {
                        filters,
                        search: input,
                        pagination: {
                            page: 1,
                            limit,
                        },
                    }
                }));
            }
            catch (e) {
                response = error(e);
            }
        }

        if (response.isOk) {
            callback(null, {
                options: _.sortBy(response.payload[itemsKey].map(item => ({
                    label: this.getLabel(item, labelKey),
                    value: _.get(item, valueKey),
                    document: item,
                })), 'label'),
                complete: false
            });
        } else {
            response.showErrors();
        }
    }

    getLabel(item, labelKey) {
        if (_.isArray(labelKey)) {
            return _.map(labelKey, (key) => {
                return _.get(item, key);
            }).join(' ');
        } else {
            return _.get(item, labelKey)
        }
    }

    getParameters() {
        return _.map(_.cloneDeep(this.state.parameters), (parameter, index) => {
            switch (parameter.type) {
                case 'singleSelect':
                    parameter.value = this.state.parameterValues[index];
                    parameter.options.options = [
                        _.find(_.get(parameter, 'options.options', []), {value: parameter.value}),
                    ];
                    break;
                case 'multiSelect':
                    parameter.value = (this.state.parameterValues[index] || []).join(';');
                    const values = _.split(parameter.value, ';');
                    parameter.options.options = _.filter(_.get(parameter, 'options.options', []), (option) => {
                        return _.indexOf(values, option.value) !== -1;
                    });
                    break;
                case 'multiSelectAsync':
                    if (_.isString(this.state.parameterValues[index])) {
                        parameter.value = this.state.parameterValues[index];
                    } else {
                        parameter.value = _.map(this.state.parameterValues[index] || [], item => _.isString(item) ? item : item.value).join(';');
                    }
                    break;
                default:
                    parameter.value = this.state.parameterValues[index];
                    break;
            }
            return parameter;
        });
    }

    getParametersRaw() {
        return _.map(this.state.parameters, (parameter, index) => {
            switch (parameter.type) {
                case 'multiSelect':
                    parameter.value = this.state.parameterValues[index] || [];
                    break;
                case 'multiSelectAsync':
                    parameter.value = _.map(this.state.parameterValues[index] || [], (item) => {
                        return _.isString(item) ? item : _.get(item, 'value');
                    });
                    break;
                default:
                    parameter.value = this.state.parameterValues[index];
                    break;
            }
            return parameter;
        });
    }

    renderModals() {
        if (this.state.roadPartsSelectActive) {
            return (
                <RoadPartsSelect
                    onSubmit={(result) => {
                        this.onMultiSelectAsyncChange(`parameterValues.${this.state.roadPartsSelectIndex}`, result);
                    }}
                    onClose={::this.hideRoadPartsSelect}
                />
            );
        }

        return null;
    }
}