import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {getOrder, updateOrder} from "store/reducers/kiutr/orders/orders";
import GlobalLoaderComponent from "components/ui/global-loader";
import OrderPlanningForm from "components/modules/kiutr/orders/OrderPlanningForm/index";
import OrderOperationalForm from "components/modules/kiutr/orders/OrderOperationalForm/index";
import {resizeModals} from 'helpers/modal-size';
import {events} from 'dom-helpers';

let resizeId;

@connect(state => ({}), {getOrder, updateOrder})

@propTypes({
    uuid: PropTypes.string.isRequired,
})

export default class OrderFormSelect extends Component {
    state = {
        uuid: null,
        order: null,
    };

    static childContextTypes = {
        resizeModals: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.onResize = this.onResize.bind(this);
    }

    getChildContext() {
        return {resizeModals: this.resizeModals};
    }

    componentWillMount() {
        events.on(window, 'resize', this.onResize);
    }

    componentWillUnmount() {
        events.off(window, 'resize', this.onResize);
    }

    onResize() {
        clearTimeout(resizeId);
        resizeId = setTimeout(this.resizeModals, 1000);
    }

    resizeModals() {
        const ignoreClasses = [];
        resizeModals('.b-modal', 'b-modal__collapse', 'move-right', ignoreClasses);
    }

    async componentWillUpdate(props, state) {
        if (props.uuid !== state.uuid) {
            await this.setState({
                uuid: props.uuid,
            });
            this.loadOrder();
        }
    }

    async loadOrder() {
        const response = await this.props.getOrder(this.state.uuid);

        if (response.isOk) {
            await this.setState({
                order: response.payload,
            });

            this.openOrder();
        } else {
            response.showErrors();
        }
    }

    async openOrder() {
        let order = this.state.order;
        if (!order.is_opened) {
            order.is_opened = true;
            //await this.props.updateOrder(order);
        }
    }

    render() {
        if (!this.state.order) {
            return (
                <GlobalLoaderComponent/>
            );
        }

        if (this.state.order.processing_status === 'draft') {
            return (
                <OrderPlanningForm
                    order={this.state.order}
                    {...this.props}
                />
            );
        } else {
            return (
                <OrderOperationalForm
                    order={this.state.order}
                    {...this.props}
                />
            )
        }
    }
}