import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import Colors from '../../helpers/colors';

import {ScoreSpinner} from '../ui/spinner';
import BestWorstCompanies from './best-worst-companies';

import './safe-drive-stat.less'


@propTypes({
  bestOnes: PropTypes.array,
  worstOnes: PropTypes.array,
  percent: PropTypes.number
})

export default class SafeDriveStat extends Component {

  render() {
    return (
      <div className="row SafeDriveStat">
        <div className="col-md-4 col-sd-4 Spinner-Container">
          <ScoreSpinner score={this.props.percent} max={100} color={Colors.green} />
        </div>
        <div className="col-md-8 col-sd-8 Companies">
          <h3>Рейтинг предприятий</h3>
          <BestWorstCompanies
            bestOnes={this.props.bestOnes.map(i => i.name)}
            worstOnes={this.props.worstOnes.map(i => i.name)} />
        </div>
      </div>
    );
  }

}
