import { createSelector } from 'reselect';

export const getMapsStateSelector = (state) => state.maps;

export const getDictionariesSelector = createSelector([ getMapsStateSelector ], ({ dictionaries }) => dictionaries);
export const getUnitsSelector = createSelector([ getMapsStateSelector ], ({ units }) => units);
export const getBnsoSelector = createSelector([ getMapsStateSelector ], ({ bnso }) => bnso);
export const getComponentSelector = createSelector([ getMapsStateSelector ], ({ component }) => component);
export const getSelectedRoadSelector = createSelector([ getMapsStateSelector ], ({ road }) => road);
export const getSelectedRoadPartSelector = createSelector([ getMapsStateSelector ], ({ roadPart }) => roadPart);
export const getSelectedRoadRepairPartSelector = createSelector(
	[ getMapsStateSelector ],
	({ roadRepairPart }) => roadRepairPart
);
export const getIsLoadingSelector = createSelector([ getMapsStateSelector ], ({ loading }) => loading);
export const getHistoryOrdersSelector = createSelector([ getMapsStateSelector ], ({ historyOrders }) => historyOrders);
export const getHistoryOrderExecutionsSelector = createSelector(
	[ getMapsStateSelector ],
	({ historyOrderExecutions }) => historyOrderExecutions
);
export const getMoSelector = createSelector([ getMapsStateSelector ], ({ mo }) => mo);
export const getMunicipalitiesKiutrSummarySelector = createSelector(
	[ getMapsStateSelector ],
	({ municipalities }) => municipalities
);
export const getFiltersSelector = createSelector([ getMapsStateSelector ], ({ filters }) => filters);
export const getDiapasonSelector = createSelector(
	[ getMapsStateSelector ],
	({ from_date, from_time, to_date, to_time }) => ({ from_date, from_time, to_date, to_time })
);
export const getAnalyticDiapasonSelector = createSelector(
	[ getMapsStateSelector ],
	({ from_analytic, to_analytic }) => ({ from_analytic, to_analytic })
);

export const getShowEventsSelector = createSelector([ getMapsStateSelector ], ({ showEvents }) => showEvents);
export const getShowHistorySelector = createSelector([ getMapsStateSelector ], ({ showHistory }) => showHistory);
export const getShowHistorySliderSelector = createSelector(
	[ getMapsStateSelector ],
	({ showHistorySlider }) => showHistorySlider
);

export const getRouteSelector = createSelector([ getMapsStateSelector ], ({ route }) => route);
export const getRoutesSelector = createSelector([ getMapsStateSelector ], ({ routes }) => routes);
export const getRouteStopPointsSelector = createSelector(
	[ getMapsStateSelector ],
	({ routeStopPoints }) => routeStopPoints
);
export const getSelectedRoutesSelector = createSelector(
	[ getMapsStateSelector ],
	({ selectedRoutes }) => selectedRoutes
);
export const getSelectedRouteVariantsSelector = createSelector(
	[ getMapsStateSelector ],
	({ selectedRouteVariants }) => selectedRouteVariants
);

export const getVehicleForPopSelector = createSelector(
	[ getMapsStateSelector ],
	({ vehicleForPopup }) => vehicleForPopup
);

export const getShownTelematicsSelector = createSelector(
	[ getMapsStateSelector ],
	({ shownTelematics }) => shownTelematics
);
export const getOperatorComponentsSelector = createSelector(
	[ getMapsStateSelector ],
	({ operatorComponents }) => operatorComponents
);

export const getisOperatorComponentsFilterShownSelector = createSelector(
	[ getMapsStateSelector ],
	({ isOperatorComponentsFilterShown }) => isOperatorComponentsFilterShown
);

export const getSelectedVehiclesSelector = createSelector(
	[ getMapsStateSelector ],
	({ filters }) => filters.base.vehicle.vehicles
);

export const getFilterBaseResultSelector = createSelector(
	[ getMapsStateSelector ],
	({ filters }) => filters.base.result
);

export const getShouldHistoryBeSHownSelector = createSelector(
	[ getMapsStateSelector ],
	({ filters }) =>
		window.RNIS_SETTINGS.show_mass_history
			? filters.base.vehicle.vehicles.length > 0 && filters.base.vehicle.vehicles.length < 6
			: filters.base.vehicle.vehicles.length === 1
);
