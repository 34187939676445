import React, {Component} from 'react';
import currentUser from 'helpers/current-user';

export default class BaseAnalyticsComponent extends Component {

    constructor(props) {
        super(props);

        if (!currentUser.can('com.rnis.system.analytics_platform')) {
            this.props.router.push('/');
        }
    }
}