import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import {getUnits} from "store/reducers/organizational_units/organizational_units";
import MapFilterBase from "components/modules/maps/Filter/MapFilterBase";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";

@propTypes({})

@connect(state => ({}), {
    getUnits,
    getDictionaryList
})

export default class MapFilterUnit extends MapFilterBase {

    tabs = [
        'Владелец',
        'Заказчик',
    ];
    icon = 'organizer';
    contextKey = 'map.filters.base.unit';
    contextDefault = 'Субъекты';

    async componentWillMount() {
        await this.loadTypes();

        super.componentWillMount();
    }

    async loadTypes() {
        const response = await this.props.getDictionaryList('company_types', {
            order: {
                column: 'name',
                direction: 'asc',
            },
        });
        if (response.isOk) {
            const companyTypes = _.keyBy(response.payload.documents, 'uuid');
            this.setState({companyTypes});
        } else {
            response.showErrors();
        }
    }

    async loadData(tab = null, search = null) {
        const unitTypeUuid = _.get(_.find(this.state.companyTypes, {name: 'Организатор работ'}), 'uuid');
        const carrierTypeUuid = _.get(_.find(this.state.companyTypes, {name: 'Исполнитель работ'}), 'uuid');

        const response = await this.props.getUnits({
            filters: {
                withUnitType: (tab === 0) ? carrierTypeUuid : unitTypeUuid,
                withComponent: this.props.component,
            },
            search,
            pagination: {
                page: 1,
                limit: this.limit,
            },
        });

        if (response.isOk) {
            return _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.name,
            }));
        } else {
            response.showErrors();
            return [];
        }
    }

}
