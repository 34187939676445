import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import _ from 'lodash';

import {connect} from "react-redux";
import {getDocumentsList, getReports, listReportTemplates} from "store/reducers/reports/reports";
import GlobalLoaderComponent from "components/ui/global-loader";
import moment from "moment";
import formats from "dictionaries/formats";
import {api, CycleFetch} from "helpers/api";
import pluralize from "pluralize-ru";
import Input from "components/ui/form/input";
import Select from "components/ui/select";
import {EntityList} from "helpers/entity";
import {ReportsMap, ReportsWithoutPdf} from "dictionaries/reports_map";
import {Link} from "react-router";
import {rpc} from "helpers/api/session";

@propTypes({
    documents: PropTypes.array,
    templates: PropTypes.object,
    search: PropTypes.string,
    onSearch: PropTypes.func,
    allTemplates: PropTypes.array,
})

@connect((state) => ({}), {})

export default class DocumentList extends Component {
    state = {};

    render() {
        return (
            <div className="page-block reports-archive">
                <div className="page-block__caption">Архив отчетов</div>
                <div className="page-block__content">
                    <Input
                        size="md"
                        value={this.props.search}
                        onChange={this.props.onSearch}
                        placeholder="Поиск отчетов..."
                    />
                    <Select
                        value={this.props.template}
                        onChange={this.props.onTemplateChange}
                        options={this.props.allTemplates}
                    />
                    <div className="archives">
                        <ul className="archives__list">
                            {this.props.documents.map(::this.renderDocument)}
                        </ul>
                    </div>
                    {this.renderPages()}
                </div>
            </div>
        );
    }

    renderPages() {
        const currentPage = this.props.page;
        const pages = _.filter(_.range(1, this.props.pages + 1), (page) => {
            return Math.abs(page - currentPage) <= 3;
        });
        return (
            <div className="dataTables_paginate paging_simple_numbers">
                <span>
                    {_.map(pages, (page) => {
                        return (
                            <a key={page} href="javascript:void(0)" onClick={this.props.setCurrentPage.bind(this, page)}
                               className={classNames('paginate_button', (page === this.props.page) ? 'current' : '')}>{page}</a>
                        );
                    })}
                </span>
            </div>
        );
    }

    renderDocument(document, index) {
        const className = classNames('archives__item');

        let status;
        switch (document.status) {
            case 'failed':
                status = <span className="failed">{document.status_description || 'Ошибка'}</span>;
                break;
            case 'done':
                status = <span className="done">Завершено</span>;
                break;
            case 'queued':
                if (_.isNumber(document.percent)) {
                    status = (
                        <span>Формируется ({document.percent}%)</span>
                    );
                } else {
                    status = <span>Формируется</span>;
                }
                break;
            case 'created':
                status = <span>В очереди</span>;
                break;
        }

        return (
            <li key={index} className={className}>
                <div className="archives__title">
                    {document.report_name}
                    &nbsp;
                    <span>({moment(document.created_at).format(formats.DATETIME)})</span>
                </div>
                <div className="archives__title">
                    Статус: <span>{status}</span>
                </div>
                {document.report_template_uuid ? (
                    <div className="archives__title">
                        Шаблон: <span>{_.get(this.props.templates, document.report_template_uuid)}</span>
                    </div>
                ) : null}
                <div className="archives__title">
                    {_.map(document.parameters, ::this.renderParameter)}
                </div>
                <div className="archives-menu">
                    {_.map(document.files, this.renderFile.bind(this, this.getDocumentName(document), document.created_at, _.indexOf(ReportsWithoutPdf, document.report_uri) !== -1, document))}
                    {this.renderMapBtn(document)}
                </div>
            </li>
        )
    }

    getDocumentName(document) {
        if (document.report_uri === 'mtdi_monitoring_report') {
            return 'Форма 6 - Формы контроля передачи мониторинговой информации';
        }
        return document.report_name;
    }

    renderParameter(parameter, index) {
        const {related} = this.props;

        let value = parameter.value;
        switch (parameter.type) {
            case 'singleValueDate':
                value = moment(value).format(formats.DATE);
                break;
            case 'singleValueDatetime':
                value = moment(value).format(formats.DATETIME);
                break;
            case 'singleSelect':
                value = _.get(_.find(_.get(parameter, 'options.options'), {value}), 'label');
                break;
            case 'multiSelect':
                value = _.map(_.filter(_.split(value, ';')), (singleValue) => {
                    return _.get(_.find(_.get(parameter, 'options.options', []), (option) => {
                        return option.value == singleValue;
                    }), 'label');
                }).join(', ');
                break;
            case 'singleSelectAsync':
                value = related.getReact(value);
                break;
            case 'multiSelectAsync':
                const values = _.filter(_.split(value, ';'));
                value = _.map(values, (singleValue, index) => {
                    return (
                        <span key={singleValue}>
                            {related.getReact(singleValue)}
                            {(index !== values.length - 1) ? ', ' : ''}
                        </span>
                    );
                });
                break;
        }
        if(parameter.key === "interval_uuid"){
            value = this.props.periods[parameter.value]
        }

        if(parameter.key === "communal_municipality_uuids"){
            if(parameter.value.split(';')[0] !== ''){
                value = parameter.value.split(';').map(item=> this.props.communalList[item]).join(', ')
            }
        }

        return (
            <div key={index} className="info">
                {parameter.name}: <span>{(value && value.length) ? value : 'Все'}</span>
            </div>
        );
    }

    renderFile(name, time, ignorePdf, document, file, format) {
        if (ignorePdf && (format === 'pdf')) {
            return null;
        }

        return (
            <span key={format} className="archives-menu__item download">
                {/*<a href={`${file}?title=${name}_${moment(time).format('DD-MM-YYYY_HH-mm-ss')}.${format}`} target="_blank">Скачать {format}</a>*/}
                <a href={`${rpc.options.ajax.url.replace('/ajax', '/ajax/download_report')}?uuid=${document.uuid}&format=${format}&name=${name}_${moment(time).format('DD-MM-YYYY_HH-mm-ss')}.${format}`}
                   target={(format === 'html') ? '_blank' : '_self'}>Скачать {format}</a>
            </span>
        );
    }

    renderMapBtn(document) {
        const url = _.get(ReportsMap, document.report_uri);

        if (!url || document.status !== 'done') {
            return null;
        }

        return (
            <span key="map" className="archives-menu__item download">
                <Link to={`${url}${document.uuid}`}>Графический вид</Link>
            </span>
        );
    }

    renderInteractive(document) {
        let query = [];
        _.each(document.parameters, (parameter, key) => {
            query.push(key + '=' + parameter.value);
        });
        return (
            <span className="archives-menu__item view">
                <a href={`https://jas.t1-group.ru:8443/jasperserver/flow.html?_flowId=viewReportFlow&reportUnit=${document.report_uri}&decorate=no&j_username=joeuser&j_password=joeuser&viewAsDashboardFrame=false&sessionDecorator=no&${query.join('&')}`}
                   target="_blank">Просмотр</a>
            </span>
        );
    }
}