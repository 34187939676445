import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import TableContainer from "components/ui/Table/Container/TableContainer";
import formats from "dictionaries/formats";
import moment from "moment";
import './shift-info.less';
import {formatMileage, formatTime} from "helpers/math";

@propTypes({
    turn: PropTypes.object.isRequired,
})

export default class KiutrRouteScheduleTurnShiftInfoComponent extends Component {

    getShiftsInfo() {
        const turn = this.props.turn;

        let shifts = {
            1: {
                runs: 0,
                mileage: 0,
                in_control: 0,
                in_movement: 0,
                night_time: 0,
                work_time: 0,
            },
        };

        let currentShift = 1;

        _.each(this.getRuns(), (run) => {
            switch (run.type) {
                case 'reshift':
                    currentShift++;
                    shifts[currentShift] = {
                        runs: 0,
                        mileage: 0,
                        in_control: 0,
                        in_movement: 0,
                        night_time: 0,
                        work_time: 0,
                    };
                    break;
                case 'production_forward':
                case 'production_reverse':
                    shifts[currentShift].runs++;
                    break;
            }
            shifts[currentShift].mileage += _.toInteger(run.distance);
            shifts[currentShift].in_control += ['production_forward', 'production_reverse'].includes(run.type) ? +run.time : 0;
            shifts[currentShift].in_movement += ((run.type !== 'dinner') && (run.type !== 'gap') && (run.type !== 'reshift') && (run.type !== 'settling')) ? _.toInteger(run.time) : 0;
            const time = moment(run.start_time, formats.TIME);
            shifts[currentShift].night_time += (time.hour() >= 22 || time.hour() < 6) ? _.toInteger(run.time) : 0;
            shifts[currentShift].work_time += ((run.type !== 'dinner') && (run.type !== 'gap') && (run.type !== 'reshift')) ? _.toInteger(run.time) : 0;
        });

        return shifts;
    }

    getRuns() {
        return _.filter(_.map(this.props.turn.runs, (run) => {
            if (run.type === 'switch_out') {
                return;
            }
            if (run.type === 'switch_in') {
                const switchObject = _.find(this.props.scheduleSwitchesIn, {uuid: run.schedule_switch_uuid});
                if (switchObject) {
                    const switchTurn = _.find(_.get(switchObject, 'schedule.turns', []), (item) => {
                        return _.toInteger(item.number) === _.toInteger(switchObject.schedule_turn_number)
                    });
                    const switchRun = _.find(_.get(switchTurn, 'runs', []), {schedule_switch_uuid: run.schedule_switch_uuid});
                    if (switchRun) {
                        let _run = _.clone(switchRun);
                        _run.type = 'null';
                        return _run;
                    }
                }

                return null;
            }

            return run;
        }));
    }

    render() {
        const shifts = this.getShiftsInfo();

        return (
            <div className="schedule-shifts">
                <div className="Table">
                    <TableContainer>
                        <table className="b-table b-table-no-hover">
                            <thead>
                            <tr className="wrap-normal">
                                <th>Cмена</th>
                                <th>Рейсов</th>
                                <th>Пробег, км</th>
                                <th>Время в управлении</th>
                                <th>Время в движении</th>
                                <th>Время ночное</th>
                                <th>Время рабочее</th>
                            </tr>
                            </thead>
                            <tbody>
                            {_.map(shifts, ::this.renderShift)}
                            <tr className="bold">
                                <td>Всего</td>
                                <td>{_.sumBy(_.values(shifts), 'runs')}</td>
                                <td>{formatMileage(_.sumBy(_.values(shifts), 'mileage') / 1000)}</td>
                                <td>{formatTime(_.sumBy(_.values(shifts), 'in_control'))}</td>
                                <td>{formatTime(_.sumBy(_.values(shifts), 'in_movement'))}</td>
                                <td>{formatTime(_.sumBy(_.values(shifts), 'night_time'))}</td>
                                <td>{formatTime(_.sumBy(_.values(shifts), 'work_time'))}</td>
                            </tr>
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </div>
        );
    }

    renderShift(shift, index) {
        return (
            <tr key={index}>
                <td>{index}</td>
                <td>{shift.runs}</td>
                <td>{formatMileage(shift.mileage / 1000)}</td>
                <td>{formatTime(shift.in_control)}</td>
                <td>{formatTime(shift.in_movement)}</td>
                <td>{formatTime(shift.night_time)}</td>
                <td>{formatTime(shift.work_time)}</td>
            </tr>
        );
    }
}