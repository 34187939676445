import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from 'react-props-decorators';
import classNames from 'classnames';
import _ from 'lodash';
import L from 'leaflet';

import './styles.less';
import PageModal from "components/ui/page-modal";
import RouteCreator from 'components/modules/maps/RouteCreator/RouteCreator';
import RoutePlannerPopup from 'components/modules/maps/elements/popup/RoutePlanner';
import IconButton from 'components/ui/icon-button';
import Loader from 'components/ui/loader';
import FilterHeader from 'components/ui/filter-header';
import { geocode } from 'store/reducers/geo/geocode';
import { api } from 'helpers/api';
import { success, error } from 'helpers/response';

const searchTypes = ['Быстрый', 'Короткий'];

const initialState = {
  addresses: [],
  action: null,
  screen: 'edit',
  route: null,
  searchType: 0,
};

@propTypes({
  map: PropTypes.object,
})
 
export default class RoutePlanner extends Component {

  state = {...initialState};

  routeLine = null;

  togglePlanner = () => {
    if (this.props.isActive) {
      this.setState({...initialState});
    }
    this.props.onToggle('route-planner');
  }

  onChangeRoute = (nodes) => {
    Promise.all( nodes.map( async (node) => {
      if (!node.address) {
        const response = await geocode({
          coordinates: {
            latitude: node.coords[0],
            longitude: node.coords[1],
          },
        })();
        if (response.isOk) {
          node.address = response.payload.address;
        }
      }
      return node;
    })).then(results => this.setState({ addresses: results.map( node => ({text: node.address, id: node.id, coords: node.coords}) ) }));
  }

  removeAddress = id => () => {
    this.setState({action: {type: 'removeNode', data: id}});
  }

  changeScreenTo = name => () => {
    this.setState({screen: name});
  }

  findRoute = async () => {
    this.changeScreenTo('searching')();
    const points = this.state.addresses.map( address => ({
      latitude: address.coords[0],
      longitude: address.coords[1],
      address: address.text,
    }));
    const start = points.shift();
    const finish = points.pop();
    const data = {
      "from": start,
      "to": finish,
      "transit": points,
      "datetime": "2018-07-03T20:36:00+03:00",
      "max_time": 240,
      "max_transfers": 2,
      "modes": [
        "WALK",
        "TRANSIT"
      ],
      "walk_reluctance": 2,
      "is_wheelchair": false,
      "version": 2
    }
    const response = await this.routeRequest(data);
    if (response.success) {
      this.setState({route: response.payload}, this.changeScreenTo('result'));
    }
  }

  routeRequest = async (data) => {
    try {
      const response = await api.routing.find(data);
      return success(response);
    }
    catch (e) {
      return error(e);
    }
  }

  getAddressesList = (param) => {
    const isEditable = param !== 'without-edit';
    return this.state.addresses.map(address => {
      return (
        <li key={address.id} className="route-planner__list-item">
          <span>{address.text}</span>
          {isEditable ? <div className="route-planner__basket"><IconButton icon="basket" onClick={this.removeAddress(address.id)} /></div> : null}
        </li>
      )
    });
  }

  getRouteTime = () => {
    return this.state.route.items.reduce((acc, item) => acc + item.time, 0);
  }

  getRouteDistance = () => {
    return this.state.route.items.reduce((acc, item) => acc + item.distance_total, 0);
  }

  drawRoute = () => {
    const points = _.flatMap(this.state.route.items, item => {
      return _.flatMap(item.segments, segment => segment.geometry.coordinates.map( coords => _.reverse(coords)));
    });
    this.routeLine = L.polyline(points, {
      color: 'blue',
      weight: 2
    }).addTo(this.props.map);
  }

  removeRoute = () => {
    this.routeLine && this.routeLine.remove();
  }

  switchSearchType = (value) => {
    this.setState({ searchType: value });
  }

  render() {
    const { isActive } = this.props;
    this.state.screen === 'result' && this.drawRoute();
    return (<div>
      {isActive ? this.renderPlanner() : this.removeRoute()}
      <div className="route-block route-planner-button">
        <div className="route-block__item">
          <div className={classNames('route-block__btn route-block__btn_area', {
            'route-block__btn_white': !isActive,
            'route-block__btn_red': isActive,
          })} onClick={this.togglePlanner}>
            <i className={classNames('rnis-icon', {
              'rnis-icon_route-planner': !isActive,
              'rnis-icon_close_white': isActive,
            })} />
          </div>
        </div>
        <RouteCreator
          map={this.props.map}
          isActive={this.props.isActive}
          popup={RoutePlannerPopup}
          onChange={this.onChangeRoute}
          action={this.state.action}
          isFrozen={this.state.screen !== 'edit'}
        />
      </div>
      </div>
    );
  }

  renderPlanner() {
    let screen;
    if (this.state.screen === 'edit') {
      let button;
      if (this.state.addresses.length > 1) {
        button = <button className="b-button b-button_size_md" onClick={this.findRoute}>Рассчитать маршрут</button>;
      } else {
        button = <button className="b-button b-button_size_md b-button_white">Рассчитать маршрут</button>;
      }
      screen = (
        <div>
          <ul className="route-planner__list">
            {this.getAddressesList()}
          </ul>
          <div className="route-planner__footer">
            {button}
          </div>
        </div>
      );
    } else if (this.state.screen === 'searching') {
      screen = (
        <div className="route-planner__search">
          <p>Расчет оптимального маршрута...</p>
          <Loader color="red" align="bottom" />
          <div className="route-planner__footer">
            <button className="b-button b-button_size_md" onClick={this.changeScreenTo('edit')}>Отмена</button>
          </div>
        </div>
      );
    } else if (this.state.screen === 'result') {
      const time = this.getRouteTime();
      const distance = this.getRouteDistance();
      screen = (
        <div>
          <FilterHeader onChange={this.switchSearchType} items={searchTypes} currentItem={this.state.searchType} />
          <div className="route-planner__result">в пути: <b>{time}</b>&nbsp;&nbsp;растояние: <b>{distance}</b></div>
          <ul className="route-planner__list">
            {this.getAddressesList('without-edit')}
          </ul>
          <div className="route-planner__footer">
            <button className="b-button b-button_size_md" onClick={this.changeScreenTo('edit')}>Изменить точки маршрута</button>
          </div>
        </div>
      );
    }

    return (
      <PageModal
        header={{ title: ''}}
        className="b-modal-route-planner"
        withFade={false}
      >
      {screen}
      </PageModal>
    );
  }

}