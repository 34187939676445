import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';


import _ from 'lodash';
import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import moment from "moment";
import formats from "dictionaries/formats";
import {getEntityNames} from "store/reducers/system";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import systems from "dictionaries/systems";
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import {deleteDisplay, getDisplays} from "store/reducers/kiutr/displays";
import $ from 'jquery';
import Button from "components/ui/button";

@connect(state => ({}), {getDisplays, getEntityNames, deleteDisplay, getDictionaryList, getUnits})

export default class DisplaysTableComponent extends BaseTableWithEditorComponent {

    getTitle() {
        return `${systems[this.props.params.component]} → Остановочные табло`;
    }

    getBaseUrl() {
        return `/kiutr/displays/table`;
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    renderHeaderContents() {
        return _.concat([
            <Button key="map" text="Карта" color="dark"
                    className="top-menu__button top-menu__button_recovery" onClick={::this.gotoMap}/>,
            (window.RNIS_SETTINGS.shedule_stop_points_page ?
                <Button key="stoppoint" text="Остановки" color="dark"
                    className="top-menu__button top-menu__button_recovery" onClick={::this.gotoStopPoint}/>
                        : null),
        ], super.renderHeaderContents());
    }

    componentDidMount() {
        if (!this.refs.table) return;

        $(this.refs.table.getWrappedInstance().refs.table).on('click', '.goto-map', ::this.onGotoMapClick);
    }

    onGotoMapClick(e) {
        const el = $(e.target);
        const uuid = el.data('uuid');

        this.props.router.push(`/kiutr/displays/map?uuid=${uuid}`);
    }

    gotoMap() {
        this.props.router.push('/kiutr/displays/map');
    }

    gotoStopPoint() {
        this.props.router.push('/kiutr/displays/stoppoint');
    }

    routesFormatting(routes_array) {
        const splitted = routes_array.split(/, /g);
        const numberRoute = splitted.map(item => item.split(" ")[0])
        return numberRoute.map((routeNumber, index) => `<span title="${splitted[index].toString()}">${routeNumber}</span>`).join(", ")
    }

    getColumns() {
        return this.prepareColumns(_.filter([

            new Column('Остановочный пункт')
                .fromField('stop_point_uuid')
                .denyColumnFilter()
                .withDrawer(item => _.get(item, 'stop_point.title')),

            new Column('Маршруты')
                .fromField('routes')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => this.routesFormatting(_.map(item.routes, routeUuid => this.state.related.get(routeUuid)).join(', '))),

            new Column('Идентификатор табло')
                .fromField('identifier'),

            new Column('Статус')
                .fromField('is_active')
                .denyColumnFilter()
                .withDrawer(item => item.is_active ? 'ОК' : 'Не отвечает'),

            new Column('Последняя транзакция')
                .fromField('last_success_update')
                .denyColumnFilter()
                .withDrawer(item => item.last_success_update ? moment(item.last_success_update).format(formats.DATETIME) : '-'),

            new Column('Модель')
                .fromField('display_model_uuid')
                .denyColumnFilter()
                .withDrawer(item => this.state.related.get(item.display_model_uuid)),

            new Column('Балансодержатель')
                .fromField('unit_uuid')
                .withDrawer(item => this.state.related.get(item.unit_uuid))
                .denyColumnFilter(),

            new Column('Карта')
                .withDrawer(item => `<a href="javascript:void(0)" class="goto-map" data-uuid="${item.uuid}">перейти</a>`)
                .denyColumnFilter(),
        ]));
    }

    async deleteItem(data) {
        return await this.props.deleteDisplay(data);
    }

    async loadData(meta) {
        meta.response_data = [
            'items/uuid',
            'items/deleted_at',
            'items/stop_point',
            'items/stop_point_uuid',
            'items/routes',
            'items/identifier',
            'items/is_active',
            'items/last_success_update',
            'items/display_model_uuid',
            'items/unit_uuid',
        ];

        return await this.props.getDisplays(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const routes = _.map(_.uniq(_.filter(_.flatten(_.map(result, 'routes')))), (uuid) => ({
            class: 'App\\Model\\Route',
            uuid: uuid,
            source: 'geo',
        }));

        const models = _.map(_.filter(_.map(result, 'display_model_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\DisplayModels\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const units = _.map(_.uniq(_.filter(_.map(result, 'unit_uuid'))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(_.concat(routes, models, units));

        if (response.isOk) {
            this.state.related.add(response);
            drawCallback(json);
        }
    }
}