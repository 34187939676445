import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import _ from 'lodash';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import moment from "moment";
import formats from "dictionaries/formats";
import {deleteRoute, getRoutes} from "store/reducers/routes/route_editor";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import systems from "dictionaries/systems";
import KiutrScheduleRouteVariantsComponent from "components/modules/kiutr/schedules/variants";
import KiutrRouteSchedulesComponent from "components/modules/kiutr/routes-grant/schedule/schedules";
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import {component_mapper} from "helpers/component_mapper";

@connect(state => ({}), {getRoutes, getEntityNames, deleteRoute, getDictionaryList, getUnits})

export default class KiutrSchedulesComponent extends BaseTableWithEditorComponent {

    getTitle() {
        return `${systems[this.props.params.component]} → Расписания`;
    }

    getBaseUrl() {
        return `/${this.props.params.component}/schedules`;
    }

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            component: null,
        });
    }

    componentDidMount() {
        this.forceUpdate();
    }

    async componentWillUpdate(props, state) {
        super.componentWillUpdate(props, state);

        if (component_mapper(props.params.component) !== state.component) {
            await this.setState({
                component: component_mapper(props.params.component),
                columns: List(this.getColumns(component_mapper(props.params.component))),
            });
            state.component && this.reload();
        }
    }

    getEditor() {
        return (
            <KiutrRouteSchedulesComponent
                {...this.props}
                single={true}
                routeUuid={this.state.editorUuid}
                onClose={::this.closeEditor}
            />
        );
    }

    getColumns() {
        return this.prepareColumns(_.filter([

            new Column('№ маршрута')
                .fromField('number'),

            new Column('Рег. № маршрута')
                .fromField('registration_number'),

            new Column('Наименование')
                .fromField('title'),

            new Column('Статус')
                .fromField('status_uuid')
                .withDrawer(item => item.status_uuid && this.state.related.get(item.status_uuid))
                .denyColumnFilter()
                .withFilter('withStatus', async () => {
                    const response = await this.props.getDictionaryList('route_passport_statuses');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }),

            new Column('Дата составления')
                .fromField('composed_at')
                .withDrawer(item => item.composed_at && moment(item.composed_at).format(formats.DATE))
                .withDateFilter(),

            (component_mapper(this.props.params.component) === 'kiutr') ? (
                new Column('Тип маршрута')
                    .fromField('route_type_uuid')
                    .withDrawer(item => item.route_type_uuid && this.state.related.get(item.route_type_uuid))
                    .denyColumnFilter()
                    .withFilter('withRouteType', async () => {
                        const response = await this.props.getDictionaryList('route_types');
                        if (response.isOk) {
                            return response.payload.documents;
                        }
                        return [];
                    })
            ) : null,

            (component_mapper(this.props.params.component) === 'kiutr') ? (
                new Column('Вид сообщения')
                    .fromField('transport_connection_type_uuid')
                    .withDrawer(item => item.transport_connection_type_uuid && this.state.related.get(item.transport_connection_type_uuid))
                    .denyColumnFilter()
                    .withFilter('withTransportConnectionType', async () => {
                        const response = await this.props.getDictionaryList('transport_connection_types');
                        if (response.isOk) {
                            return response.payload.documents;
                        }
                        return [];
                    })
            ) : null,

            new Column('Вид маршрута')
                .fromField('route_kind_uuid')
                .withDrawer(item => item.route_kind_uuid && this.state.related.get(item.route_kind_uuid))
                .denyColumnFilter()
                .withFilter('withRouteKind', async () => {
                    const response = await this.props.getDictionaryList('route_kinds');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }),

            ...(!window.RNIS_SETTINGS.CITY_NOVOSIBIRSK ? [(new Column('Общая протяженность, м')
                .fromField('length_total')
                .withNumberFilter())] : []),
            ...(!window.RNIS_SETTINGS.CITY_NOVOSIBIRSK ? [( new Column('Протяженность в прямом направлении, м')
                .fromField('length_forward')
                .withNumberFilter())] : []),

            ...(!window.RNIS_SETTINGS.CITY_NOVOSIBIRSK ? [(new Column('Протяженность в обратном направлении, м')
                .fromField('length_reverse')
                .withNumberFilter())] : []),

            new Column('Образ документа')
                .fromField('files')
                .denyOrder()
                .withDrawer(item => _.map(item.files || [], file => `<a href="${file}" target="_blank">Открыть</a>`).join('<br/>'))
                .denyColumnFilter()
                .withFilter('withFile', async () => {
                    return [
                        {
                            uuid: '0',
                            name: 'Отсутствует',
                        },
                        {
                            uuid: '1',
                            name: 'Присутствует',
                        },
                    ];
                }),

            new Column((component_mapper(this.props.params.component) === 'kiutr') ? 'Организатор перевозок' : 'Организация фрахтователь')
                .fromField('unit_uuid')
                .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid))
                .denyColumnFilter()
                .withFilter('withOriginalUnits', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 1000,
                        },
                        filters: {
                            withComponent: component_mapper(this.props.params.component),
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

        ]));
    }

    async deleteItem(data) {
        return await this.props.deleteRoute(data);
    }

    async loadData(meta) {
        _.set(meta, 'filters.withComponent', this.state.component);
        _.set(meta, 'filters.withoutGeometry', true);
        _.set(meta, 'response_data', [
            'items/uuid',
            'items/deleted_at',
            'items/number',
            'items/registration_number',
            'items/title',
            'items/status_uuid',
            'items/composed_at',
            'items/route_type_uuid',
            'items/transport_connection_type_uuid',
            'items/route_kind_uuid',
            'items/length_total',
            'items/length_forward',
            'items/length_reverse',
            'items/file',
            'items/unit_uuid',
        ]);

        return await this.props.getRoutes(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const statuses = _.map(_.filter(_.map(result, 'status_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RoutePassportStatuses\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const routeTypes = _.map(_.filter(_.map(result, 'route_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RouteTypes\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const connectionTypes = _.map(_.filter(_.map(result, 'transport_connection_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\TransportConnectionType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const routeKinds = _.map(_.filter(_.map(result, 'route_kind_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RouteKinds\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const units = _.map(_.filter(_.map(result, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(_.concat(statuses, routeTypes, connectionTypes, routeKinds, units));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }


    getCreateButton() {
        return null;
    }

    renderHeaderActions() {
        return [];
    }
}