import {rpc} from './session';

export async function getUploadToken() {
	const response = await rpc.request('com.rnis.storage.action.token');


	if(!window.RNIS_SETTINGS.CITY_MO && response.payload.upload_url){
		response.payload.upload_url = response.payload.upload_url.replace(/^.*\/\/[^\/]+/, '')
	}
	return response
}

export async function uploadFile(url, data)
{
  const response = await fetch(url, {
    method: 'POST',
    body: data
  });
  const json = await response.json();
  return json.payload;
}
