import React, {Component} from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapLayer from "components/ui/map/base/layer";
import {connect} from "react-redux";
import {getStopPointsWithBounds} from "store/reducers/routes/routes";
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import StopPointsEditor from "components/modules/geo/stop-points/editor";
import {getobjects} from "store/reducers/kurs/road_parts";
import MapGeojson from "components/ui/map/base/geojson";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getObjects} from "store/reducers/garbage/objects";
import _ from 'lodash';
import GarbagePointMarker from "components/ui/map/markers/garbage-point-marker";
import HistoryPointMarker from "components/ui/map/markers/history-point-marker";
import {getUserGeoObjects} from "store/reducers/user-map-objects/object_editor";

let garbageObjectTypes = null;

@propTypes({
    type: PropTypes.string,
    onClick: PropTypes.func,
    onDblClick: PropTypes.func,
})

@defaultProps({
    onClick: null,
    onDblClick: () => {
    },
})

@connect((state) => ({}), {getDictionaryList, getObjects, getUserGeoObjects})

export default class GarbageObjectsLayer extends AbstractMapLayer {

    state = {
        objects: [],
        active: null,
        garbage_object_types: [],
        userGeoObjects: []
    };

    loadCallback = ::this.load;

    async componentWillMount() {
        super.componentWillMount();

        if (!garbageObjectTypes) {
            await this.loadDictionaries([
                'garbage_object_types',
            ]);
            garbageObjectTypes = this.state.garbage_object_types;
        } else {
            await this.setState({
                garbage_object_types: garbageObjectTypes,
            });
        }

        this.props.leafletMap.on('moveend', this.loadCallback);

        this.load();
        this.loadGeozones();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.props.leafletMap.off('moveend', this.loadCallback);
    }

    getType() {
        let title = null;
        switch (this.props.type) {
            case 'kp':
                title = 'Контейнерные площадки';
                break;
            case 'op':
                title = 'Объекты перегрузки';
                break;
            case 'opp':
                title = 'Объекты размещения';
                break;
            case 'os':
                title = 'Объекты сортировки';
                break;
        }
        return _.get(_.find(this.state.garbage_object_types, {label: title}), 'value');
    }


    async loadGeozones(input, callback) {
        const result = await this.props.getUserGeoObjects({
            pagination: {
                page: 1,
                limit: 100,
            },
        });

        if (result.isOk) {
            await this.setState({
                userGeoObjects: result.payload.items,
            });

        } else {
            result.showErrors();
        }
    }

    async load() {
        const boundingBox = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getBoundingBox() : this.props.map.getBoundingBox();
        const zoom = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getZoom() : this.props.map.getZoom();
        if ((this.props.type !== 'kp' && zoom <= 11) || (this.props.type === 'kp' && zoom <= 13)) {
            this.setState({
                objects: [],
            });
            return;
        }

        const response = await this.props.getObjects({
            filters: {
                withBoundingBox: boundingBox,
                withGarbageObjectTypes: [
                    this.getType(),
                ],
            },
        });

        if (response.isOk) {
            this.setState({
                objects: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        return (
            <div>
                <div style={{display: 'none'}}>
                    {this.state.objects.map(::this.renderObject)}
                </div>
            </div>
        );
    }

    renderObject(object) {
        if (object.user_geo_object_uuid) {
            let objectPolygon = _.find(this.state.userGeoObjects, {uuid: object.user_geo_object_uuid})
            if(objectPolygon) {
                return (
                    <MapGeojson
                        map={this.props.map}
                        ref={() => {
                            try {
                                const group = L.geoJSON(objectPolygon.geometry);
                                this.refs.map.getWrappedInstance().fitBounds(group.getBounds());
                            } catch (e) {
                            }
                        }}
                        color={objectPolygon.color}
                        leafletMap={this.props.leafletMap}
                        geometry={objectPolygon.geometry}
                        tooltip={objectPolygon.title}
                    />
                )
            }
        } else {
            return (
                <GarbagePointMarker
                    key={object.uuid}
                    {...this.props}
                    latitude={object.latitude}
                    longitude={object.longitude}
                    type={this.props.type}
                    title={object.name}
                    options={{
                        zIndexOffset: 100,
                    }}
                />
            );
        }

    }

    setActive(uuid) {
        this.setState({
            active: uuid,
        });
    }

    setNotActive() {
        this.setState({
            active: null,
        });
    }

    onClick(roadPart) {
        this.props.onClick(roadPart.uuid, roadPart.geometry);
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            await this.setState(state);
        } else {
            response.showErrors();
        }
    }

    async getDictionary(dictionary, component = null, withoutOrder = false) {
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionary, meta);
        if (response.isOk) {
            return _.map(response.payload.documents, (document) => ({
                value: document.uuid,
                label: document.short_name || document.name,
                document,
            }));
        } else {
            response.showErrors();
        }
    }

}
