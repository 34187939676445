import React from 'react';
import {connect} from 'react-redux';

import Editor from './editor';

import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import Column from "components/ui/column";
import systems from "dictionaries/systems";
import moment from "moment";
import formats from "dictionaries/formats";
import {deleteTechnocard, getTechnocards} from "store/reducers/kurs/technocards";

@connect(state => ({}), {getTechnocards, deleteTechnocard})

export default class UtilityTechnocardsComponent extends BaseTableWithEditorComponent {

    getTitle() {
        return <span>{systems.utility} → Технологические карты</span>;
    }

    baseUrl = '/utility/technocards';

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Наименование')
                .fromField('name'),

            new Column('Дата утверждения')
                .fromField('confirm_date')
                .withDateFilter()
                .withDrawer(item => {
                    return moment(item.confirm_date).format(formats.DATE);
                }),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteTechnocard(data);
    }

    async loadData(meta) {
        return await this.props.getTechnocards(meta);
    }
}