import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import _ from 'lodash';
import {deleteKursMaintenance, getKursMaintenances} from "store/reducers/commdept/maintenances";
import PageModal from 'components/ui/page-modal';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import GlobalLoaderComponent from "components/ui/global-loader";
import TableContainer from "components/ui/Table/Container/TableContainer";
import classNames from 'classnames';

@propTypes({
    component: PropTypes.string.isRequired,
    onClose: PropTypes.func,
})

@connect(state => ({}), {getKursMaintenances, deleteKursMaintenance, getEntityNames})

export default class CommdeptMaintenancesComponent extends BaseTableWithEditorComponent {

    title = `${systems[this.props.component]} → График заполнения ТО`;
    baseUrl = '/commdept/maintenance';

    getEditor() {
        return (
            <Editor
                {...this.props}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                editorCloneData={this.state.editorCloneData}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Марка')
                .fromField('vehicle_mark_uuid')
                .denyColumnFilter()
                .withClassName('link')
                .withDrawer(item => item.vehicle_mark_uuid && this.state.related.get(item.vehicle_mark_uuid)),

            new Column('Модель')
                .fromField('vehicle_model_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.vehicle_model_uuid && this.state.related.get(item.vehicle_model_uuid)),
        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteKursMaintenance(data);
    }

    async loadData(meta) {
        meta.filters.withComponent = this.props.component;

        return await this.props.getKursMaintenances(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const marks = _.map(_.filter(_.map(result, 'vehicle_mark_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleMark\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const models = _.map(_.filter(_.map(result, 'vehicle_model_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleModel\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(_.concat(marks, models));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    async runClone() {
        if (!this.refs.table) return;

        const selected = _.cloneDeep(this.refs.table.getWrappedInstance().getSelected().data()[0]);
        if (selected) {
            selected.uuid = null;
            //selected.vehicle_mark_uuid = null;
            //selected.vehicle_model_uuid = null;
            selected.component = this.props.component;
            await this.setState({
                editorCloneData: selected,
            });
            await this.closeEditor();
            this.setState({
                showEditorModal: true,
                editorUuid: null,
            });
        }
    }

    /*async onDblClick(data) {
        this.getEditor()
    }*/

    render() {
        const editor = this.state.showEditorModal ? this.getEditor() : '';

        const buttons = (
            <ModalTopMenuButtons>
                <ModalTopMenuList className="top-menu_modal_edit">
                    <ContextTooltip key="base-table.create" code="base-table.create" default="Добавить">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_plus"
                            onClick={::this.showEditor}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="kurs.maintenance.clone" code="kurs.maintenance.clone" default="Создать новый график на основе выбранного">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_clone"
                            onClick={::this.runClone}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="base-editor.delete" code="base-editor.delete" default="Удалить">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_basket"
                            onClick={::this.deleteSelected}
                        />
                    </ContextTooltip>

                    <ModalTopMenuListSeparator key="separator"/>
                </ModalTopMenuList>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );


        return (
            <div>
                <PageModal
                    header={{title: 'График прохождения ТО', buttons}}
                    className={classNames("b-modal-edit b-modal-road-accounting service-modal")}
                    onClose={this.props.onClose}
                >
                    {this.state.loading ? <GlobalLoaderComponent/> : null}
                    <div className="b-modal__block">
                        <TableContainer>
                            {this.renderTable()}
                        </TableContainer>
                    </div>
                </PageModal>
                {editor}
            </div>
        );
    }

    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    async showEditorWithUuid(uuid = null) {
        if (!uuid) {
            await this.closeEditor();
        }
        //await this.closeEditor();

        this.setState({
            showEditorModal: true,
            editorCloneData: null,
            editorUuid: uuid,
        });
    }

    async closeEditor() {
        this.setState({
            showEditorModal: false,
        });
    }
}
