import React, {Component} from 'react';

import {connect} from 'react-redux';
import ReactDOMServer from 'react-dom/server';

import Editor from './ComplaintsEditor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getEntityNames} from "store/reducers/system";
import _ from 'lodash';
import {getTasks} from "store/reducers/kurs/tasks";
import {getComplaints, updateNSUsernameWithUnitUuid, getNSUsernameWithUnitUuid, getNSUsernameWithEmailPhoneInfo} from "store/reducers/complaints/complaints";
import moment from "moment";
import formats from "dictionaries/formats";
import {getUnits} from "store/reducers/organizational_units/units";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getUsers} from "store/reducers/staffing/staffing";
import {deleteComplaint} from "store/reducers/complaints/complaints";

import ContextTooltip from "../../ui/context-tooltip";
import IconButton from "../../ui/icon-button";
import ModalTopMenuButtons from "../../ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "../../ui/modal/modal-top-menu-button";
import PageModalComponent from "../../ui/page-modal";
import TableContainer from "../../ui/Table/Container/TableContainer";
import {SelectAsync} from "../../ui/select";
import {State} from "../../ui/state";
import GlobalLoaderComponent from "components/ui/global-loader";
import { getWaybills, deleteWaybill} from 'store/reducers/kurs/waybills';
import Datepicker from "components/ui/form/datepicker";
import * as storage from "utils/storage";

import "./index.less";
import Button from "../../ui/button";

@connect(state => ({}), {getTasks, getWaybills, deleteWaybill, getEntityNames, getUnits, getVehicleList, getUsers, getComplaints, getNSUsernameWithUnitUuid, getNSUsernameWithEmailPhoneInfo, updateNSUsernameWithUnitUuid, getDictionaryList, deleteComplaint})

export default class ComplaintsComponent extends BaseTableWithEditorComponent {


    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            isLoadingUnitFromNS: false,
            isLoadingUnitFromBE: false,
            previousModalState: {},
            units: [],
            isShowUnitsModal: false,
            selectedUnitUuid: {},
            removedSelector: {},
            formUpdated: false,

            from: storage.get('kurs-complaints-from') || moment(),
            to: storage.get('kurs-complaints-to') || moment().add(1, 'day'),
            withExternalDatefrom: storage.get('kurs-complaints-from') || moment(),
            withExternalDateto: storage.get('kurs-complaints-to') || moment().add(1, 'day'),
        });
    }

    title = `Жалобы`;

    baseUrl = '/road/complaints';

    defaultOrder = [[2, 'desc']];


    async componentDidMount() {
        if (!this.refs.table) return;
        $(this.refs.table.getWrappedInstance().refs.table).on('click', '.link', ::this.onLinkClick);
    }

    async onLinkClick(e) {
        const el = $(e.target);
        const url = el.data('url');

        this.props.router.push(url);
    }

    renderHeaderContents() {
        return [this.getFilterButton()]
    }

    async onDblClick(data) {
        if (data && data.uuid) {
            this.showEditorWithUuid(data.uuid);
        }
    }


    getEditor() {
        return (
            <Editor
                {...this.props}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([
            new Column('id Жалобы')
            .fromField('complaints.external_id')
            // .denyColumnFilter()
            .withDrawer(item => item.external_id),

            new Column('Заголовок')
                .fromField('complaints.title')
                .denyColumnFilter()
                .withDrawer(item => item.title),

            new Column('Дата составления')
                .fromField('complaints.external_created_at')
                .denyColumnFilter()
                .withDrawer(item => moment(item.external_created_at).format(formats.DATE))
                .withDateFilter(),

            new Column('Предприятие')
                .fromField('unit_uuid')
                .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid))
                .withFilter('withUnits', async () => {
                    const response = await this.props.getUnits({
                        filters: {
                            withComponent: 'road',
                        },
                        order: {
                            column: 'unit',
                            direction: 'asc',
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),
                
            new Column('Адрес')
                .fromField('address')
                .withDrawer(item => item.address && item.address),

            new Column('Выполнить до')
                .fromField('execution_date')
                .withDrawer(item => item.execution_date && moment(item.execution_date).format('DD.MM.YYYY HH:MM')),

            new Column('Статус')
                .fromField('status_uuid')
                .withDrawer(item => item.status_uuid && this.state.related.get(item.status_uuid))
                .withFilter('withStatuses', async () => {
                    const response = await this.props.getDictionaryList('complaint_statuses');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }),

            new Column('Электронная почта')
                .fromField('complaints.user_email')
                .denyColumnFilter()
                .withDrawer(item => item.user_email),

            new Column('Статус жалоб')
                .fromField('complaints.active')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.active}/>))
                .withFilter('withActive', async () => {
                    return [
                        {
                            name: 'Активные жалобы',
                            uuid: 1,
                        },
                        {
                            name: 'Не активные жалобы',
                            uuid: 0,
                        },
                    ];
                })
        ]);
    }

    onChangeSelect(field, e) {
        if (!e) {
            this.setState(prevState => ({
                previousModalState: prevState.selectedUnitUuid,
                formUpdated: true,
                selectedUnitUuid: {
                    ...prevState.selectedUnitUuid,
                    [field]: null
                },
                removedSelector: {
                    [field] : prevState.selectedUnitUuid[field]
                }
            }))
            return;
        }
        const value = e.value;
        this.setState(prevState => ({
            previousModalState: prevState.selectedUnitUuid,
            formUpdated: true,
            selectedUnitUuid: {
                ...prevState.selectedUnitUuid,
                [field]: value
            }
        }))
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value});
        storage.set('kurs-complaints-from', value);
        this.reload();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value});
        storage.set('kurs-complaints-to', value);
        this.reload();
    };

    withExternalDatefromChange = async ({target: {value}}) => {
        await this.setState({withExternalDatefrom: value});
        storage.set('kurs-complaints-from', value);
        this.reload();
    };

    withExternalDatetoChange = async ({target: {value}}) => {
        await this.setState({withExternalDateto: value});
        storage.set('kurs-complaints-to', value);
        this.reload();
    };


    async loadUnits(input, callback) {
        let meta = {
            pagination: {
                page: 1,
                limit: 120, //fixme убрать множественный вызов данного запроса на смуте /road/complaints (нажать плюсик)
            },
        }
        if (input) {
            meta.search = input;
        }
        const result = await this.props.getUnits(meta);

        if (result.isOk) {
            const options = _.sortBy(result.payload.items.map(i => ({
                label: i.name,
                value: i.uuid,
            })), 'label');

            callback(null, {
                options,
                complete: false,
            });
        } else {
            result.showErrors();
            callback(null, {
                options: [],
                complete: false,
            });
        }
    }

    async getUnitsFromNS() {
        this.setState({
            isLoadingUnitFromNS: true
        })
        const response = await this.props.getNSUsernameWithEmailPhoneInfo();
        this.setState({
            isLoadingUnitFromNS: false
        })
        if (response.isOk) {
            this.setState({
                units: response.payload.items ? response.payload.items : []
            })
        } else {
            response.showErrors();
        }
    }

    initialItems(items) {
        const localselectedUnitUuid = {};
        if (items.length) {
            items.forEach(unitItem => {
                localselectedUnitUuid[unitItem.account_name] = unitItem.unit_uuid
            });
            this.setState({
                selectedUnitUuid: localselectedUnitUuid
            })
        }
    }

    async getUnitsUuidFromBE() {
        this.setState({
            isLoadingUnitFromBE: true
        })
        const response = await this.props.getNSUsernameWithUnitUuid();
        this.setState({
            isLoadingUnitFromBE: false
        })
        if (response.isOk) {
            this.initialItems(response.payload.items);
        } else {
            response.showErrors();
        }
    }

    renderModalBody() {
        return (
            <TableContainer>
                <div className="Table">
                    <table className="b-table b-table-no-hover">
                        <thead>
                        <tr>
                            <th>Имя пользователя</th>
                            <th className="complaints-fio">ФИО</th>
                            <th>Телефон</th>
                            <th>Email</th>
                            <th>Предприятия СМУТ</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.units.length ? this.state.units.map((item, index) => {
                            return <tr key={item.username + index}>
                                <td>{item.username}</td>
                                <td>{item.name}</td>
                                <td>{item.phone}</td>
                                <td>{item.email}</td>
                                <td className="complaints-selector-smut"><SelectAsync
                                    value={this.state.selectedUnitUuid[item.username] ? this.state.selectedUnitUuid[item.username] : null}
                                    onChange={this.onChangeSelect.bind(this, `${item.username}`)}
                                    loadOptions={::this.loadUnits}
                                /></td>
                            </tr>
                        }) : <p>Нет данных для отображения</p>}
                        </tbody>
                    </table>
                </div>
            </TableContainer>
        );
    }

    renderUnitsModal() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={() => this.setShowModal(false)}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModalComponent
                header={{title: 'Предприятия СМУТ', buttons}}
                onClose={() => this.setShowModal(false)}
                className="b-modal-edit-ts-list complaints"
            >
                {this.state.isLoadingUnitFromNS || this.state.isLoadingUnitFromBE ? <GlobalLoaderComponent/> : this.renderModalBody()}
                {this.state.formUpdated ? (
                    <div className="b-modal__footer clearAfter">
                        <div className="page-footer__txt">Вы хотите сохранить все изменения?</div>
                        <Button size="md" color="white" shadow="gray" className="b-button_cancel" text="Отменить"
                                onClick={() => this.onReset()}/>
                        <Button size="md" color="red" className="b-button_save" text="Сохранить"
                                onClick={() => this.onSubmit()}/>
                    </div>
                ) : null}
            </PageModalComponent>
        )
    }

    async onSubmit() {
        const payload = [];
        const keys = Object.keys(this.state.selectedUnitUuid);
        for (const key of keys) {
            if (this.state.removedSelector[key]) {
                payload.push({"unit_uuid": this.state.removedSelector[key], "account_name": null})
            } else {
                payload.push({"unit_uuid": this.state.selectedUnitUuid[key], "account_name": key})
            }
        }
        const response = await this.props.updateNSUsernameWithUnitUuid({items: payload});
        if (response.isOk) {
            this.setState({
                formUpdated: false,
                units: [],
                selectedUnitUuid: {},
                previousModalState: {},
                removedSelector: {},
                isShowUnitsModal: false
            })
        } else {
            response.showErrors();
        }
    }

    onReset() {
        this.setState({
            selectedUnitUuid: this.state.previousModalState,
            formUpdated: false,
        })
    }

    renderModals() {
        return this.state.isShowUnitsModal ? this.renderUnitsModal() : null;
    }

    async setShowModal(boolean) {
        if (boolean) {
            this.getUnitsFromNS();
            this.getUnitsUuidFromBE();
        }
        this.setState({
            isShowUnitsModal: boolean,
            units: [],
            selectedUnitUuid: {},
            removedSelector: {},
            previousModalState: {},
            formUpdated: false,
        })
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="base-table-list.units" code="base-table-list.units" default="Предприятия СМУТ"
                            position="left">
                <IconButton icon="plus"
                            onClick={() => this.setShowModal(true)}/>
            </ContextTooltip>,
            <ContextTooltip key="base-table-list.delete" code="base-table-list.delete" default="Удалить"
                            position="left">
                <IconButton icon="basket" disabled={this.state.selectedRowsCount !== 1}
                            onClick={::this.deleteSelected}/>
            </ContextTooltip>,
             <div key="diapason">
             <div className="top-menu__label">Период по дате составления:</div>
             <Datepicker style="dark" value={this.state.withExternalDatefrom} onChange={this.withExternalDatefromChange}/>
             <Datepicker style="dark" value={this.state.withExternalDateto} onChange={this.withExternalDatetoChange}/>
             </div>,
            <div key="diapason">
                <div className="top-menu__label">Период по: Выполнить до:</div>
                <Datepicker style="dark" value={this.state.from} onChange={this.fromChange}/>
                <Datepicker style="dark" value={this.state.to} onChange={this.toChange}/>
            </div>,
        ];
    }

    async deleteItem(data) {
        return await this.props.deleteComplaint(data);
    }

    async loadData(meta) {
        if(meta.filters.withActive && meta.filters.withActive.length === 2) {
            delete meta.filters.withActive
        }
        if(meta.filters.withActive && meta.filters.withActive.length) {
            meta.filters.withActive = !!meta.filters.withActive[0]
        }

        meta.filters.withExecutionDate = [
            moment(this.state.from).format(formats.DATE_API),
            moment(this.state.to).format(formats.DATE_API),
        ];
        meta.filters.withExternalDate = [
            moment(this.state.withExternalDatefrom).format(formats.DATE_API),
            moment(this.state.withExternalDateto).format(formats.DATE_API),
        ];
        return await this.props.getComplaints(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const statuses = _.map(_.filter(_.map(result, 'status_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\ComplaintStatuses\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const units = _.map(_.filter(_.map(result, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(_.concat(statuses, units));

        if (response.isOk) {

            this.state.related.add(response);

            drawCallback(json);
        }
    }

    render() {
        if(!window.RNIS_SETTINGS.is_need_complaints) {
            return <div>Запрашиваемая страница недоступна</div>;
        }

        return super.render();
    }
}
