import React, {Component} from "react";
import {connect} from "react-redux";

import Page from "components/ui/page";
import Loader from "components/ui/loader";
import Button from "components/ui/button";
import BaseEditorFormComponent from "components/base/base-editor-form";

import {get, forEach, isBoolean, merge} from 'lodash';
import currentUser from 'helpers/current-user';

import GlobalLoaderComponent from "components/ui/global-loader";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import {createConfirmRequest, getConfirmRequest, updateConfirmRequest} from "store/reducers/portal/confirm_requests";
import _ from 'lodash';
import Block from "components/ui/form/block";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import TableContainer from "components/ui/Table/Container/TableContainer";
import moment from "moment";
import formats from "dictionaries/formats";
import './index.less';
import {State} from "components/ui/state";
import {getTelematicsWithDevices} from "store/reducers/maps";
import {getCooperationAgreements} from "store/reducers/portal/cooperation_agreements";
import {getTemplateDocuments} from "store/reducers/portal/template_documents";
import Checkbox from "components/ui/form/checkbox";

@connect(state => ({}), {getCooperationAgreements, getTemplateDocuments, getUnit})

export default class UnitProfileAgreement extends Component {

    state = {
        loading: true,
        saving: false,
        errors: {},
        document: null,
        agreement: null,
        unit: null,
        checked: false,
    };

    async componentWillMount() {
        this.loadDocument();
        await this.loadAgreement();
        this.loadUnit();
    }

    async loadAgreement() {
        const response = await this.props.getCooperationAgreements({
            filters: {
                withUuid: [this.props.params.uuid],
            },
        });

        this.setState({
            loading: false,
        });

        if (response.isOk) {
            await this.setState({
                agreement: _.first(response.payload.items),
            });
        } else {
            response.showErrors();
        }
    }

    async loadDocument() {
        const response = await this.props.getTemplateDocuments({
            filters: {
                onlyActive: true,
                withDocuments: [
                    'interaction_conditions',
                ],
            },
        });

        if (response.isOk) {
            this.setState({
                document: _.first(response.payload.items),
            });
        } else {
            response.showErrors();
        }
    }

    async loadUnit() {
        const response = await this.props.getUnit(this.state.agreement.unit_uuid);

        if (response.isOk) {
            this.setState({
                unit: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;

        return (
            <Page pageId="Main" title={`Профиль организации → Подтверждение согласия с условиями взаимодействия`}>
                {loader}
                {this.renderContent()}
            </Page>
        )
    }

    toggleChecked() {
        this.setState({
            checked: !this.state.checked,
        });
    }

    renderContent() {
        const {document, agreement, unit} = this.state;

        if (!agreement || !unit) {
            return null;
        }

        if (!document) {
            return (
                <div>
                    Нет действующего документа с условиями передачи и обработки данных.
                </div>
            );
        }

        return (
            <div className="dialog-block">
                Для полнофункциональной работы в РНИС Вам необходимо подтвердить согласие с изложенными в
                &nbsp;<a href={document.document_agreement_url} target="_blank">документе</a>
                &nbsp;условиями передачи и обработки данных между операторо РНИС и подключаемыми организациями.

                <Checkbox
                    checked={this.state.checked}
                    label={(
                        <div>
                            От лица организации
                            <span className="dialog-block__carrier"> "{unit.name}" </span>
                            соглашаюсь с условиями передачи и обработки данных
                        </div>
                    )}
                    onChange={::this.toggleChecked}
                />
                <hr/>
                <Button size="md" color="red" shadow="red" className="b-button_align-center" text="Продолжить"
                        disabled={!this.state.checked}
                        onClick={::this.submit}/>
            </div>
        );
    }

    submit() {
        this.props.router.push(`/profile/unit/agreement/${this.props.params.uuid}/sign`);
    }
}