import React, {Component} from 'react';
import moment from "moment";
import {connect} from "react-redux";

import classNames from 'classnames';
import {
    createScheduleInterval, getSchedule, getScheduleInterval, updateScheduleInterval, deleteScheduleInterval
} from "store/reducers/kiutr/schedules/schedules";

import GlobalLoaderComponent from "components/ui/global-loader";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import PageModal from 'components/ui/page-modal';
import * as alerts from "helpers/alerts";
import EditorFormInterval from "./intervalEditor";

import "./intervalSchedules.less";

@connect(state => ({}), {createScheduleInterval, updateScheduleInterval, getSchedule, getScheduleInterval, deleteScheduleInterval})

export default class KiutrRouteIntervalSchedulesComponent extends Component {

    state = {
        isSave: false,
        interval_uuid: null,
        interval_periods: [],
        timeTurnsPeriods: {},
        loading: true,
        runs: 0,
    }

    async componentWillMount() {
        await this.getSchedule();
        if (this.state.interval_uuid !== null) {
            this.setState({
                loading: true
            })
            await this.getSchedulePeriods();
        }
    }

    getEarlyLatestTime(timeCase, timeArray) {
        if (!timeArray.length) {
            return null
        }
        if (timeCase === 'early') {
            return timeArray.sort((a, b) => {
                return moment(a.start_at, 'HH:mm') - moment(b.start_at, 'HH:mm')
            })[0].start_at
        }
        if (timeCase === 'latest') {
            return timeArray.sort((a, b) => {
                return moment(b.end_at, 'HH:mm') - moment(a.end_at, 'HH:mm')
            })[0].end_at
        }
    }

    async getSchedulePeriods() {
        const response = await this.props.getScheduleInterval({uuid: this.state.interval_uuid})
        if (response.isOk) {
            this.setState({
                interval_periods: response.payload.interval_periods,
                loading: false
            })
        } else {
            response.showErrors();
            this.setState({
                loading: false
            })
        }
    }

    computeRunsSummary(turns) {
        return turns.map(turn => {
            return turn.runs.filter(item => item.type === 'production_forward' || item.type === 'production_reverse')
        }).reduce((acc, runs) => {
            return acc += runs.length
        }, 0)
    }

    async getSchedule() {
        const response = await this.props.getSchedule(this.props.params.schedule);
        if (response.isOk) {
            await this.setState({
                runs: this.computeRunsSummary(response.payload.turns),
                interval_uuid: response.payload.interval_uuid ? response.payload.interval_uuid : null,
                timeTurnsPeriods: {
                    start_at: this.getEarlyLatestTime('early', response.payload.turns.concat()),
                    end_at: this.getEarlyLatestTime('latest', response.payload.turns.concat())
                },
                loading: false,
            })
        }
    }

    close() {
        this.props.router.push(`/${this.props.params.component}/routes/${this.props.params.uuid}/schedules`);
    }

    save() {
        this.setState({isSave: true})
    }

    async delete() {
        return alerts.prompt('Вы действительно хотите удалить интервальное расписание?', '', async () => {
            const response = await this.props.deleteScheduleInterval({ uuid: this.state.interval_uuid });
            if (response.isOk) {
                alerts.success('Удалено');
                this.hide();
            } else {
                response.showErrors();
                alerts.error('Интервальное расписание не удалось удалить');
                this.hide();
            }
        });
    }

    onSave(status) {
        this.setState({isSave: status.status})
    }

    hide() {
        setTimeout(() => {
            this.close()
        }, 1200);
    }

    async onCreate(data) {
        if (!data.status) {
            this.setState({isSave: false})
        } else {
            this.setState({isSave: false})
            const response = await this.props.createScheduleInterval({
                schedule_uuid: this.props.params.schedule,
                interval_periods: data.payload
            });
            if (response.isOk) {
                alerts.success('Сохранено');
                this.hide();
            } else {
                response.showErrors();
                alerts.error('Интервальное расписание не удалось сохранить');
                this.hide();
            }
        }
    }

    async onUpdate(data) {
        if (!data.status) {
            this.setState({isSave: false})
        } else {
            this.setState({isSave: false})
            const response = await this.props.updateScheduleInterval({
                uuid: this.state.interval_uuid,
                interval_periods: data.payload
            });
            if (response.isOk) {
                alerts.success('Обновлено');
                this.hide();
            } else {
                response.showErrors();
                alerts.error('Интервальное расписание не удалось обновить');
                this.hide();
            }
        }
    }

    logicViewEditor() {
        if (this.state.loading) {
            return <GlobalLoaderComponent/>
        } else {
            return <EditorFormInterval timeTurnsPeriods={this.state.timeTurnsPeriods}
                                       intervalPeriods={this.state.interval_periods}
                                       runs={this.state.runs}
                                       isSave={this.state.isSave}
                                       mode={this.state.interval_uuid !== null ? 'edit' : 'add'}
                                       onCreate={::this.onCreate}
                                       onUpdate={::this.onUpdate}
                                       onSave={::this.onSave}/>
        }
    }

    render() {
        const title = this.state.interval_uuid ? 'Редактировать интервалы движения' : 'Добавить интервалы движения';
        const buttons = (
            <ModalTopMenuButtons>
                <ModalTopMenuButton
                    key="save"
                    className="_save"
                    title="Сохранить"
                    onClick={::this.save}
                />
                <ModalTopMenuButton
                    key="delete"
                    className="_delete"
                    title="Удалить интервалы"
                    onClick={::this.delete}
                />
                <ModalTopMenuButtonsSeparator/>
                <ModalTopMenuButton
                    key="close"
                    className="_close"
                    tooltip="Отменить"
                    onClick={::this.close}
                />
            </ModalTopMenuButtons>
        );

        return (
            <div>
                <PageModal
                    header={{title, buttons}}
                    className={classNames('b-modal-edit b-modal-route route-schedules-interval-modal Schedules', {
                        'route-schedules-modal-single': this.props.single,
                    })}
                    withFade={this.props.single}
                    onClose={::this.close}
                >
                    <div>
                        {this.logicViewEditor()}
                    </div>
                </PageModal>
            </div>
        )
    }
}