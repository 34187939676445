import React, {Component} from "react";
import {connect} from "react-redux";

import Page from "components/ui/page";
import Loader from "components/ui/loader";
import Button from "components/ui/button";
import BaseEditorFormComponent from "components/base/base-editor-form";

import {get, forEach, isBoolean, merge} from 'lodash';
import currentUser from 'helpers/current-user';

import GlobalLoaderComponent from "components/ui/global-loader";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import {
    createConfirmRequest, getConfirmRequest, getConfirmRequests,
    updateConfirmRequest
} from "store/reducers/portal/confirm_requests";
import _ from 'lodash';
import Block from "components/ui/form/block";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import TableContainer from "components/ui/Table/Container/TableContainer";
import moment from "moment";
import formats from "dictionaries/formats";
import {getRegisterRequest, getRegisterRequests, updateRegisterRequest} from "store/reducers/portal/register_requests";
import systems from "dictionaries/systems";
import {documentCooperationAgreement, getCooperationAgreements} from "store/reducers/portal/cooperation_agreements";
import {State} from "components/ui/state";
import classNames from 'classnames';
import FileReaderInput from 'react-file-reader-input';
import {api} from 'helpers/api';

import './index.less';
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {getUsers} from "store/reducers/staffing/staffing";
import * as alerts from "helpers/alerts";
import {getTemplateDocuments} from "store/reducers/portal/template_documents";
import * as storage from "utils/storage";
import {getTelematics, getTelematicsWithDevices} from "store/reducers/maps";
import {User} from "helpers/user";
import ContextTooltip from "components/ui/context-tooltip";
import {xmlVerify} from "store/reducers/ecp/ecp";
import EasyXml from 'easyxml';
import download from 'downloadjs';

@connect(state => ({}), {getConfirmRequests, createConfirmRequest, updateConfirmRequest})

export default class UnitProfileComponent extends Component {

    state = {
        loading: true,
        saving: false,
        errors: {},
        unitUuid: null,
        max_user_length: 1,
    };

    componentWillMount() {
        const storedUnitUuid = storage.get('unit-profile') || currentUser.unitUuid();
        this.setState({
            unitUuid: storedUnitUuid,
        });
    }

    async componentDidMount() {
        this.loadConfirmRequest();
    }

    async loadConfirmRequest() {
        const response = await this.props.getConfirmRequests({
            filters: {
                withUnit: this.state.unitUuid,
            }
        });

        if (response.isOk) {
            if (response.payload.items.length > 0) {
                this.setState({
                    loading: false,
                    data: response.payload.items,
                    max_user_length: Math.max(1, _.toInteger(_.max(_.map(_.filter(response.payload.items, {status: 'confirmed'}), (item) => {
                        return Math.max(1, _.toInteger(item.users_count));
                    })))),
                });
            } else {
                this.createConfirmRequest();
            }
        } else {
            response.showErrors();
        }
    }

    async createConfirmRequest() {
        const response = await this.props.createConfirmRequest({
            unit_uuid: this.state.unitUuid,
            user_uuid: currentUser.uuid(),
            vehicles: [],
            users_count: 1,
        });

        this.setState({
            loading: false,
        });
        if (response.isOk) {
            this.setState({
                data: [response.payload],
            });
        } else {
            response.showErrors();
        }
    }

    async onUnitChange(uuid) {
        storage.set('unit-profile', uuid);
        await this.setState({
            data: null,
            unitUuid: uuid,
        });

        this.loadConfirmRequest();
    }

    clearErrors() {
        this.setState({errors: {}});
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    async onSubmit(data) {
        this.startSave();

        data.status = 'sent';
        const response = await this.props.updateConfirmRequest(data);
        this.endSave();

        if (response.isOk) {
            window.location.reload();
        } else {
            response.showErrors();
        }
    }

    onClose() {

    }

    render() {
        const loader = (this.state.loading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;
        let form = null;

        if (this.state.data) {
            form = <UnitProfileEditorComponent
                key={this.state.unitUuid}
                {...this.props}
                unitUuid={this.state.unitUuid}
                data={this.state.data}
                onSubmit={::this.onSubmit}
                onClose={::this.onClose}
                errors={this.state.errors}
                onUnitChange={::this.onUnitChange}
                max_user_length={this.state.max_user_length}
            />;
        }
        return (
            <Page pageId="Main" title={`Профиль организации`}>
                {loader}

                {form}
            </Page>
        )
    }
}

@connect(state => ({}), {
    getUnits,
    getVehicleList,
    getDictionaryList,
    getRegisterRequests,
    getCooperationAgreements,
    getUsers,
    updateRegisterRequest,
    getTemplateDocuments,
    createConfirmRequest,
    getConfirmRequests,
    getTelematicsWithDevices,
    xmlVerify,
    documentCooperationAgreement,
})

class UnitProfileEditorComponent extends BaseEditorFormComponent {

    state = {
        confirm_requests: [],
        confirm_request: {},
        register_request: null,
        register_requests: [],
        agreements: [],
        agreement_users: [],
        units: [],
        unit: null,
        vehicles: [],
        vehicle_types: [],
        users: [],
        new_document: {},
        documentIndex: null,
        document: null,
        telematics: [],
        agreement_signs: [],
    };

    statuses = {
        draft: 'Черновик',
        sent_first: 'Отправлено впервые',
        returned: 'Возвращено',
        sent: 'Отправлено повторно',
        confirmed: 'Принято',
    };

    systems = {
        kiutr: 'Пассажирские перевозки',
        road: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text,
        utility: 'Жилищно-коммунальное хозяйство',
        communal: 'Коммунальные работы',
        children: 'Перевозка детей',
        garbage: 'Вывоз мусора',
    };

    async componentDidMount() {
        const confirm_request = this.props.location.query.confirm_request ? _.find(this.props.data, {
            uuid: this.props.location.query.confirm_request,
        }) : _.first(this.props.data);

        await this.setState({
            confirm_requests: this.props.data,
            confirm_request,
            currentConfirmRequestUuid: _.get(confirm_request, 'uuid'),
        });

        this.loadDictionaries([
            'vehicle_types',
            'request_documents',
        ]);

        this.loadRegisterRequest();
        this.loadAgreements();
        this.loadUnits();
        this.loadVehicles();
        this.loadUsers();
        this.loadTemplateDocument();
    }

    async loadTemplateDocument() {
        const response = await this.props.getTemplateDocuments({
            filters: {
                onlyActive: 1,
                withDocuments: ['statement'],
            },
        });

        if (response.isOk) {
            this.setState({
                document: _.first(response.payload.items),
            });
        } else {
            response.showErrors();
        }
    }

    async loadRegisterRequest() {
        const response = await this.props.getRegisterRequests({
            filters: {
                withUnit: this.props.unitUuid,
                createOnEmpty: true,
            },
            order: {
                column: 'created_at',
                direction: 'desc',
            },
        });

        if (response.isOk) {
            this.setState({
                register_requests: response.payload.items,
                register_request: _.first(response.payload.items),
            });
        } else {
            response.showErrors();
        }
    }

    async loadAgreements() {
        if (!this.get('confirm_request.unit_uuid')) {
            return;
        }
        
        const response = await this.props.getCooperationAgreements({
            filters: {
                withUnit: this.get('confirm_request.unit_uuid'),
            },
        });

        if (response.isOk) {
            await this.setState({
                agreements: response.payload.items,
            });
            this.loadAgreementUsers();
            this.verifySigns();
        } else {
            response.showErrors();
        }
    }

    async loadAgreementUsers() {
        const uuids = _.filter(_.concat(_.map(this.state.agreements, 'operator_uuid'), _.map(this.state.agreements, 'user_uuid')));

        const response = await this.props.getUsers({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            this.setState({
                agreement_users: _.mapValues(_.keyBy(response.payload.items, 'uuid'), (item) => {
                    return new User(item).getFullName();
                }),
            });
        } else {
            response.showErrors();
        }
    }

    async verifySigns() {
        let agreement_signs = [];

        await Promise.all(_.map(this.state.agreements, async (agreement) => {
            const sign = agreement.company_sign;
            if (!sign) {
                return;
            }

            let xml = this.getXml(_.clone(agreement));
            const signPosition = xml.indexOf('</Data>') + '</Data>'.length;
            xml = xml.substr(0, signPosition + 1) + sign + xml.substr(signPosition + 1);

            xml = xml.replace(/\s{2}/g, '');

            const response = await this.props.xmlVerify(xml);
            if (response.isOk) {
                const subjectName = _.get(response.payload, 'certificateInfo.subjectName');
                const matches = /CN=([^,]+)(,|$)/.exec(subjectName);
                const name = matches[1].replace(/\\"/g, '"');


                agreement_signs.push({
                    uuid: agreement.uuid,
                    name,
                    type: 'company',
                    certificate: _.get(response.payload, 'certificateInfo'),
                });
            }
        }));

        await Promise.all(_.map(this.state.agreements, async (agreement) => {
            const sign = agreement.operator_sign;
            if (!sign) {
                return;
            }

            let xml = this.getXml(_.clone(agreement));
            const signPosition = xml.indexOf('</Data>') + '</Data>'.length;
            xml = xml.substr(0, signPosition + 1) + sign + xml.substr(signPosition + 1);

            xml = xml.replace(/\s{2}/g, '');

            const response = await this.props.xmlVerify(xml);
            if (response.isOk) {
                const subjectName = _.get(response.payload, 'certificateInfo.subjectName');
                const matches = /CN=([^,]+)(,|$)/.exec(subjectName);
                const name = matches[1].replace(/\\"/g, '"');


                agreement_signs.push({
                    uuid: agreement.uuid,
                    name,
                    type: 'operator',
                    certificate: _.get(response.payload, 'certificateInfo'),
                });
            }
        }));

        this.setState({
            agreement_signs,
        });
    }

    getXml(item) {
        const easyXml = new EasyXml({
            singularize: true,
            rootElement: 'Envelope',
            manifest: true,
        });
        const data = {
            uuid: item.uuid,
            template_document_uuid: item.template_document_uuid,
            unit_uuid: item.unit_uuid,
            document_number: item.document_number,
            expiration_date: item.expiration_date,
            document_url: item.document_url,
            component: item.component,
        };
        let xml = easyXml.render({
            _xmlns: 'urn:envelope',
            Data: data,
        });
        xml = xml.replace(/\s{2}/g, '');
        return xml;
    }

    async loadUnits() {
        const response = await this.props.getUnits();

        if (response.isOk) {
            this.setState({
                units: response.payload.items,
                unit: this.props.unitUuid,
            });
        } else {
            response.showErrors();
        }
    }

    async loadUsers() {
        const response = await this.props.getUsers({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withUnits: this.props.unitUuid,
                notVirtual: true,
            },
        });

        if (response.isOk) {
            this.setState({
                users: response.payload.items,
            })
        } else {
            response.showErrors();
        }
    }

    async loadVehicles() {
        const response = await this.props.getVehicleList({
            filters: {
                withUnit: this.get('confirm_request.unit_uuid'),
            },
        });

        if (response.isOk) {
            await this.setState({
                vehicles: response.payload.items,
            });
            this.loadTelematics();
        } else {
            response.showErrors();
        }
    }

    async loadTelematics() {
        const devices = _.filter(_.map(this.state.vehicles, 'current_bnso.bnso_number'));
        const response = await this.props.getTelematicsWithDevices(devices, 't1sync', null, null, 'none');

        if (response.isOk) {
            this.setState({
                telematics: _.mapValues(_.keyBy(response.payload.items, 'device_id'), (item) => {
                    return true;
                }),
            });
        }
    }

    get(field, defaultValue = null) {
        return this.getValue(field) || defaultValue;
    }

    submit() {
        this.props.onSubmit(this.state.confirm_request);
    }

    currentUnit() {
        return _.find(this.state.units, {uuid: this.state.unit});
    }

    async setValue(field, value) {
        super.setValue(field, value);

        if (field === 'unit') {
            this.props.onUnitChange(value);
        }

        if (field === 'register_request.components') {
            this.setValue('register_request.documents', []);
            this.setState({
                documentIndex: null,
                new_document: {
                    filename: '',
                },
            });
        }
    }

    render() {
        const {vehicles} = this.state;
        const vehicleTypes = _.concat(this.state.vehicle_types, {
            label: 'без типа',
            value: undefined,
        });
        const activeVehicleTypes = _.filter(vehicleTypes, ({value}) => {
            return _.filter(vehicles, (vehicle) => {
                return vehicle.vehicle_type_uuid === value;
            }).length > 0;
        });

        const vehiclesInRequest = _.filter(vehicles, (vehicle) => {
            return _.indexOf(this.get('confirm_request.vehicles') || [], vehicle.uuid) !== -1;
        });

        const unitVehicles = _.filter(vehicles, {
            unit_uuid: this.get('unit'),
        });
        const lesseeVehicles = _.filter(vehicles, {
            lessee_company_uuid: this.get('unit'),
        });

        return (
            <div className="pc-company">
                {this.state.loading ? (<GlobalLoaderComponent/>) : null}
                <div className="b-block_pc-company">
                    <Block title="Предприятие" size="sm">
                        {this.select('unit', _.map(this.state.units, (unit) => ({
                            value: unit.uuid,
                            label: unit.name,
                        })), {
                            clearable: false,
                        })}
                    </Block>
                    <Block title="ОГРН" size="sm">
                        {_.get(this.currentUnit(), 'ogrn')}
                    </Block>
                    <Block title="ИНН" size="sm">
                        {_.get(this.currentUnit(), 'inn')}
                    </Block>
                    <Block title="КПП" size="sm">
                        {_.get(this.currentUnit(), 'kpp')}
                    </Block>
                </div>

                <Accordion>
                    <AccordionItem title="Профиль деятельности предприятия" opened={true}>
                        {_.map(this.systems, ::this.renderComponent)}
                    </AccordionItem>
                    <AccordionItem title="Автопарк предприятия" opened={true}>
                        <div className="Table _no-indent outer-indent-left">
                            <TableContainer>
                                <table className="b-table">
                                    <thead>
                                    <tr className="b-table__header _text-normal">
                                        <th>Транспорт</th>
                                        <th>Собственность</th>
                                        <th>Всего бортов</th>
                                        <th>с БНСО</th>
                                        <th>Заявлено</th>
                                        <th>Активировано</th>
                                        <th>Премиум базовый</th>
                                        <th>Премиум голд</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {activeVehicleTypes.map(({value, label}) => {
                                        const vehiclesByType = value ? _.filter(vehicles, {
                                            vehicle_type_uuid: value,
                                        }) : _.filter(vehicles, vehicle => !vehicle.vehicle_type_uuid);
                                        const unitVehiclesByType = _.filter(vehiclesByType, {
                                            unit_uuid: this.get('unit'),
                                        });
                                        const lesseeVehiclesByType = _.filter(vehiclesByType, {
                                            lessee_company_uuid: this.get('unit'),
                                        });
                                        return [
                                            <tr key={value}>
                                                <td rowSpan={2}>{label}</td>
                                                <td>Собственность</td>
                                                <td>{unitVehiclesByType.length}</td>
                                                <td>{_.filter(unitVehiclesByType, vehicle => vehicle.current_bnso_uuid).length}</td>
                                                <td>{_.filter(unitVehiclesByType, (vehicle) => {
                                                    return _.indexOf(this.get('confirm_request.vehicles') || [], vehicle.uuid) !== -1;
                                                }).length}</td>
                                                <td>{_.filter(unitVehiclesByType, {is_approved: true}).length}</td>
                                                <td>0</td>
                                                <td>0</td>
                                            </tr>,
                                            <tr key={`${value}:2`}>
                                                <td>Аренда</td>
                                                <td>{lesseeVehiclesByType.length}</td>
                                                <td>{_.filter(lesseeVehiclesByType, vehicle => vehicle.current_bnso_uuid).length}</td>
                                                <td>{_.filter(lesseeVehiclesByType, (vehicle) => {
                                                    return _.indexOf(this.get('confirm_request.vehicles') || [], vehicle.uuid) !== -1;
                                                }).length}</td>
                                                <td>{_.filter(lesseeVehiclesByType, {is_approved: true}).length}</td>
                                                <td>0</td>
                                                <td>0</td>
                                            </tr>,
                                        ];
                                    })}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td rowSpan={2}>Сумма</td>
                                        <td>Собственность</td>
                                        <td>{unitVehicles.length}</td>
                                        <td>{_.filter(unitVehicles, vehicle => vehicle.current_bnso_uuid).length}</td>
                                        <td>{_.filter(unitVehicles, (vehicle) => {
                                            return _.indexOf(this.get('confirm_request.vehicles') || [], vehicle.uuid) !== -1;
                                        }).length}</td>
                                        <td>{_.filter(unitVehicles, {is_approved: true}).length}</td>
                                        <td>0</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>Аренда</td>
                                        <td>{lesseeVehicles.length}</td>
                                        <td>{_.filter(lesseeVehicles, vehicle => vehicle.current_bnso_uuid).length}</td>
                                        <td>{_.filter(lesseeVehicles, (vehicle) => {
                                            return _.indexOf(this.get('confirm_request.vehicles') || [], vehicle.uuid) !== -1;
                                        }).length}</td>
                                        <td>{_.filter(lesseeVehicles, {is_approved: true}).length}</td>
                                        <td>0</td>
                                        <td>0</td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </TableContainer>
                        </div>
                    </AccordionItem>
                    <AccordionItem title="Взаимодействие с оператором РНИС" opened={true}>
                        <div className="b-block _sm b-block_right">
                            <div className="b-block__title">Отправка заявления</div>
                            <div className="b-block__text">
                                {this.select('currentConfirmRequestUuid', _.map(_.filter(this.state.confirm_requests, request => request.status !== 'confirmed'), (request) => ({
                                    value: request.uuid,
                                    label: `#${request.number} (${this.statuses[request.status]})`,
                                })), {
                                    clearable: false,
                                    onChange: ::this.onConfirmRequestChange,
                                })}
                            </div>
                        </div>
                        <div className="b-block _lg">
                            <a href={_.get(this.state.document, 'document_agreement_url')} target="_blank">
                                <div className="b-block__title _inline-block">Шаблон заявления</div>
                                <i className="rnis-icon rnis-icon_licence"></i>
                            </a>
                        </div>
                        <div className="b-block _sm">
                            <div className="table-container ov-h">
                                <div className="Table-title">
                                    Заявляемый автопарк
                                    {((this.get('confirm_request.status') === 'draft') || (this.get('confirm_request.status') === 'returned')) ? (
                                        <i className="rnis-icon rnis-icon_edit" onClick={::this.gotoVehiclesEdit}/>
                                    ) : null}
                                </div>
                                <div className="Table _no-indent">
                                    <table className="b-table">
                                        <thead>
                                        <tr className="b-table__header _text-normal">
                                            <th>ТС</th>
                                            <th>Кол-во</th>
                                            <th>БНСО</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {activeVehicleTypes.map(({value, label}) => {
                                            const vehiclesByType = value ? _.filter(vehiclesInRequest, {
                                                vehicle_type_uuid: value,
                                            }) : _.filter(vehiclesInRequest, vehicle => !vehicle.vehicle_type_uuid);
                                            return (
                                                <tr key={value}>
                                                    <td>{label}</td>
                                                    <td>{vehiclesByType.length}</td>
                                                    <td>{_.filter(vehiclesByType, vehicle => vehicle.current_bnso_uuid && this.state.telematics[_.get(vehicle.current_bnso, 'bnso_number')]).length}</td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td>Всего</td>
                                            <td>{vehiclesInRequest.length}</td>
                                            <td>{_.filter(vehiclesInRequest, vehicle => vehicle.current_bnso_uuid && this.state.telematics[_.get(vehicle.current_bnso, 'bnso_number')]).length}</td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className="btns-wrap">
                                {((this.get('confirm_request.status') === 'draft') || (this.get('confirm_request.status') === 'returned')) ? (
                                    <button className="b-button b-button_red b-button_shadow_red b-button_size_md"
                                            onClick={::this.submit}>
                                        Отправить
                                    </button>
                                ) : null}
                                <button className="b-button b-button_red b-button_shadow_red b-button_size_md"
                                        onClick={::this.createNewConfirmRequest}>
                                    Новое заявление
                                </button>
                            </div>
                        </div>
                        {((this.get('confirm_request.status') === 'draft') || (this.get('confirm_request.status') === 'returned')) ? ([
                            <Block key="status_comment" size="md" title="Комментарий">
                                {this.textarea('confirm_request.status_comment')}
                            </Block>,
                            <div key="users_count" className="b-block _sm">
                                <div className="b-block__title">Ожидаемое число пользователей</div>
                                <div className="b-block__text">
                                    {this.textInput('confirm_request.users_count', {
                                        type: 'number',
                                        positive: true,
                                    })}
                                </div>
                                <div className="b-block__foot">(действует: {this.state.users.length},
                                    максимум: {this.props.max_user_length})
                                </div>
                                <Block key="refresh_agreement" size="md">
                                    {this.checkbox('confirm_request.refresh_agreement', 'Обновить соглашения')}
                                </Block>
                            </div>,
                        ]) : null}
                    </AccordionItem>
                    <AccordionItem title="Актуализация профиля" opened={true}>
                        {this.renderRegisterRequest()}
                    </AccordionItem>
                    <AccordionItem title="Журнал взаимодействия" opened={true}
                                   className="accordion__posit_log">
                        <div className="b-block _md">
                            <div className="pc-company-log">
                                {this.renderFullHistory()}
                            </div>
                        </div>
                    </AccordionItem>
                </Accordion>
            </div>
        )
    }

    async onConfirmRequestChange(e) {
        const value = e ? e.value : null;

        const confirm_request = _.find(this.state.confirm_requests, {uuid: value});
        await this.setState({
            confirm_request,
            currentConfirmRequestUuid: value,
        });
        this.loadVehicles();
    }

    renderFullHistory() {
        let history = _.flatten(_.concat(_.map(this.state.confirm_requests, 'history'), _.map(this.state.register_requests, 'history')));
        history = _.orderBy(history, ['timestamp'], ['desc']);

        return history.map(::this.renderHistory);
    }

    renderHistory(history, index) {
        return (
            <div key={index} className="pc-company-log__item">
                <div className="pc-company-log__post">
                    <div className="pc-company-log__post_left pc-company-log__post_left_red">
                        {moment(history.timestamp).format(formats.DATETIME_SHORT)}
                    </div>
                    <div className="pc-company-log__post_right">
                        {history.text}
                        {(history.comment) ? (
                            <i className="rnis-icon rnis-icon_comment"></i>
                        ) : null}
                    </div>
                </div>
                {(history.comment) ? (
                    <div className="pc-company-log__comments">
                        <div className="pc-company-log__comments-item">
                            {history.comment}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }

    gotoVehiclesEdit() {
        this.props.router.push(`/profile/unit/vehicles/${this.state.unit}/${this.state.confirm_request.uuid}`);
    }

    renderComponent(componentName, component) {
        const agreements = _.filter(this.state.agreements, {component});

        const isValid = _.filter(agreements, (agreement) => {
            if (moment(agreement.expiration_date).isSameOrBefore(moment())) {
                return false;
            }
            return agreement.is_signed_by_company && agreement.is_signed_by_operator;
        }).length > 0;

        const documents = _.flatten(_.map(_.filter(this.state.register_requests, {status: 'confirmed'}), 'documents'));
        const filteredDocuments = _.filter(documents, (document) => {
            if (document.valid_to && moment(document.valid_to).isBefore(moment())) {
                return false;
            }
            const requestDocument = _.find(this.state.request_documents, {value: document.request_document_uuid});
            return !(!requestDocument || requestDocument.document.component !== component);
        });
        if (filteredDocuments.length === 0) {
            return;
        }

        return (
            <Block key={component} size="sm sm_pc-company">
                <div className={classNames('pc-company__profile-article', {
                    'pc-company__profile-article_pending': agreements.length === 0,
                    'pc-company__profile-article_valid': isValid,
                    'pc-company__profile-article_overdue': !isValid,
                })}>
                    <div className="pc-company__profile-title">{systems[component]}</div>
                    <ul className="pc-company__profile-list">
                        {_.map(filteredDocuments, this.renderDocument.bind(this, component))}
                        <li className="pc-company__profile-item pc-company__profile-item_separator"/>
                        {(agreements.length > 0) ? (
                            _.map(agreements, ::this.renderAgreement)
                        ) : (
                            <li className="pc-company__profile-item">
                                <div className="pc-company__profile-textblock pc-company__profile-textblock_half">
                                    <div className="pc-company__profile-doc">
                                        Соглашение
                                    </div>
                                    <div className="pc-company__profile-date">
                                        не сформировано
                                    </div>
                                </div>
                                <div
                                    className="b-button b-button_size_md b-button_multiline b-button_gray b-button_shadow_gray">
                                    Ожидает обработки заявления
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </Block>
        );
    }

    renderDocument(component, document, index) {
        const requestDocument = _.find(this.state.request_documents, {value: document.request_document_uuid});
        if (!requestDocument || requestDocument.document.component !== component) {
            return null;
        }

        return (
            <li key={index} className="pc-company__profile-item">
                <i className="rnis-icon rnis-icon_licence"/>
                <div className="pc-company__profile-textblock">
                    <div className="pc-company__profile-doc">
                        <a href={document.url}
                           target="_blank">{_.get(requestDocument, 'label')}</a>
                    </div>
                    <div className="pc-company__profile-date">
                        {document.valid_to ? `до ${moment(document.valid_to).format(formats.DATE)}` : 'бессрочно'}
                    </div>
                </div>
            </li>
        )
    }

    renderAgreement(agreement) {
        const signedBy = [];
        const companySign = _.find(this.state.agreement_signs, {uuid: agreement.uuid, type: 'company'});
        if (agreement.is_signed_by_company && companySign) {
            signedBy.push(`Предприятие: ${_.get(companySign, 'name')}, ${moment(agreement.signed_by_company_date).format(formats.DATE)}`);
        }
        const operatorSign = _.find(this.state.agreement_signs, {uuid: agreement.uuid, type: 'operator'});
        if (agreement.is_signed_by_operator && operatorSign) {
            signedBy.push(`Оператор: ${_.get(operatorSign, 'name')}, ${moment(agreement.signed_by_operator_date).format(formats.DATE)}`);
        }

        return (
            <li key={agreement.uuid} className="pc-company__profile-item">
                <ContextTooltip default={signedBy.join('; ')}>
                    <a href="javascript:void(0)"
                       onClick={this.getDocument.bind(this, agreement, _.get(companySign, 'certificate'), _.get(operatorSign, 'certificate'))}>
                        <i className="rnis-icon rnis-icon_licence-check"/>
                    </a>
                </ContextTooltip>
                <div className="pc-company__profile-textblock pc-company__profile-textblock_half">
                    <div className="pc-company__profile-doc">
                        Соглашение
                    </div>
                    <div className="pc-company__profile-date">
                        до {moment(agreement.expiration_date).format(formats.DATE)}
                    </div>
                </div>
                {this.renderAgreementStatus(agreement)}
            </li>
        );
    }

    getCN(text) {
        const matches = /CN=([^,]+)(,|$)/.exec(text);
        if (matches) {
            return matches[1].replace(/\\"/g, '"');
        }
        return '';
    }

    async getDocument(cooperationAgreement, companyEcp, operatorEcp) {
        let agreement = _.cloneDeep(cooperationAgreement);

        agreement.company_ecp = {
            UNIT_ECP_SERIAL: _.get(companyEcp, 'thumbprint'),
            UNIT_ECP_ISSUER: this.getCN(_.get(companyEcp, 'issuerName')),
            UNIT_ECP_VALID_FROM: moment(_.get(companyEcp, 'certFromDate')).format(formats.DATE),
            UNIT_ECP_VALID_TO: moment(_.get(companyEcp, 'certTillDate')).format(formats.DATE),
            UNIT_ECP_NAME: this.getCN(_.get(companyEcp, 'subjectName')),
        };

        agreement.operator_ecp = {
            OPERATOR_ECP_SERIAL: _.get(operatorEcp, 'thumbprint'),
            OPERATOR_ECP_ISSUER: this.getCN(_.get(operatorEcp, 'issuerName')),
            OPERATOR_ECP_VALID_FROM: moment(_.get(operatorEcp, 'certFromDate')).format(formats.DATE),
            OPERATOR_ECP_VALID_TO: moment(_.get(operatorEcp, 'certTillDate')).format(formats.DATE),
            OPERATOR_ECP_NAME: this.getCN(_.get(operatorEcp, 'subjectName')),
        };

        const response = await this.props.documentCooperationAgreement(agreement);

        if (response.isOk) {
            download(`data:application/pdf;base64,${response.payload.content}`, `Соглашение о взаимодействии.pdf`);
        } else {
            response.showErrors();
        }
    }

    renderAgreementStatus(agreement) {
        if (agreement.status === 'terminated') {
            return (
                <div className="b-button b-button_size_md b-button_gray b-button_shadow_gray" type="button">
                    Расторгнуто
                </div>
            );
        }
        if (moment(agreement.expiration_date).isSameOrBefore(moment())) {
            return (
                <div className="b-button b-button_size_md b-button_gray b-button_shadow_gray" type="button">
                    Истекло
                </div>
            );
        }
        if (!agreement.is_signed_by_company) {
            return (
                <a className="b-button b-button_size_md b-button_red b-button_shadow_red"
                   onClick={this.gotoSubscribe.bind(this, agreement)} type="button">Подписать</a>
            );
        }
        if (agreement.is_signed_by_operator) {
            return (
                <div className="b-button b-button_size_md b-button_valid b-button_shadow_gray"
                     type="button">
                    Действует
                </div>
            );
        } else {
            return (
                <div
                    className="b-button b-button_multiline b-button_size_md b-button_gray b-button_shadow_gray"
                    type="button">
                    Ожидает оператора
                </div>
            );
        }

        return null;
    }

    gotoSubscribe(agreement) {
        this.props.router.push(`/profile/unit/agreement/${agreement.uuid}`);
    }

    canEdit() {
        if (!this.state.register_request) {
            return false;
        }

        return this.state.register_request.status === 'draft' || this.state.register_request.status === 'returned' || this.state.register_request.status === 'confirmed';
    }

    renderRegisterRequest() {
        const fields = {
            'register_request.login': 'Логин',
            'register_request.password': 'Пароль',
            'register_request.email': 'Email',
            'register_request.certificate.name': 'ЭЦП: наименование',
            'register_request.certificate.serial': 'ЭЦП: номер',
            'register_request.certificate.valid_to': 'ЭЦП: действителен до',
            'register_request.certificate.issuer': 'ЭЦП: выдан',
            'register_request.certificate.owner': 'ЭЦП: владелец',
            'register_request.certificate.inn': 'ЭЦП: ИНН',
            'register_request.certificate.ogrn': 'ЭЦП: ОГРН',
            'register_request.certificate.address': 'ЭЦП: адрес регистрации',
            'register_request.certificate.position': 'ЭЦП: должность',
        };

        return (
            <div className="register_request">
                {_.map(fields, (name, field) => {
                    const error = this.getError(field);
                    return error ? (
                        <div key={field} className="input__style_state_wrong">
                            <span className="input__style_state_msg_wrong">{name}: {error}</span>
                        </div>
                    ) : null;
                })}
                <div>
                    <Block size="xl" title="Профильные области деятельности">
                        {this.canEdit() ? this.select('register_request.components', _.map(this.systems, (label, value) => ({
                            value,
                            label,
                        })), {
                            multi: true,
                        }) : _.map(this.get('register_request.components'), (component) => {
                            return _.get(systems, component);
                        })}
                    </Block>
                </div>
                <div className="set">
                    <div className="set__title">Комплект документов</div>
                    <div className="set__content clearAfter">
                        <div className="half">
                            {this.get('register_request.documents', []).map(::this.renderRegisterRequestDocument)}
                        </div>
                        <div className="half">
                            {this.canEdit() ? (
                                this.renderDocumentEdit()
                            ) : null}
                        </div>
                    </div>
                </div>
                <Block key="status_comment" size="md" title="Комментарий">
                    {this.textarea('register_request.status_comment')}
                </Block>
                <div className="page-block__bottom">
                    {this.canEdit() ? (
                        <Button size="md" color="red" shadow="red" width="auto" text="Отправить"
                                onClick={::this.send}/>
                    ) : null}
                </div>
            </div>
        );
    }

    renderRegisterRequestDocument(document, index) {
        return (
            <div key={index} className="document-set">
                <div className="document-set__title">
                    {_.get(_.find(this.state.request_documents, {value: document.request_document_uuid}), 'label')}
                </div>
                <div className="files">
                    <div className="files__item">
                        <div className="files__info">
                            <div>{document.filename}</div>
                        </div>
                        {this.canEdit() ? (
                            <div className="files-menu">
                            <span
                                className="files-menu__item edit"
                                onClick={this.editDocument.bind(this, index)}>Редактировать</span>
                                <span
                                    className="files-menu__item remove"
                                    onClick={this.deleteDocument.bind(this, index)}>Удалить файл</span>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        )
    }

    editDocument(index) {
        this.setState({
            documentIndex: index,
        });
    }

    deleteDocument(index) {
        let register_request = this.state.register_request;
        register_request.documents.splice(index, 1);
        this.setState({
            register_request,
        });
    }

    renderDocumentEdit() {
        const index = this.state.documentIndex;
        const title = (index !== null) ? 'Редактирование' : 'Добавление';

        const document = _.get(_.find(this.state.request_documents, {value: (index !== null) ? _.get(this.state, `register_request.documents.${index}.request_document_uuid`) : _.get(this.state, 'new_document.request_document_uuid')}), 'document');

        const urlError = this.getError((index !== null) ? `register_request.documents.${index}.url` : 'new_document.url');
        return (
            <div className="group group-document">
                <div className="group__content">
                    <div className="document">
                        <div className="group__title">{title} документа</div>
                        <div className="b-block _xl">
                            <div className="b-block__title">Файл</div>
                            <div className="b-block__text">
                                {this.textInput((index !== null) ? `register_request.documents.${index}.filename` : 'new_document.filename')}
                                <FileReaderInput as="binary" id="my-file-input"
                                                 onChange={this.uploadFile.bind(this, index)}>
                                    <span className="add-file">Загрузить</span>
                                </FileReaderInput>
                                {urlError ? (
                                    <div className="input__style_state_wrong">
                                        <span className="input__style_state_msg_wrong">{urlError}</span>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <Block size="xl" title="Тип документа">
                            {this.select((index !== null) ? `register_request.documents.${index}.request_document_uuid` : 'new_document.request_document_uuid', _.filter(this.state.request_documents, (document) => {
                                return _.indexOf(this.get('register_request.components'), document.document.component) !== -1;
                            }))}
                        </Block>
                        <Block title="Оформлен" className="expand-top">
                            {this.datepicker((index !== null) ? `register_request.documents.${index}.issued_at` : 'new_document.issued_at')}
                        </Block>
                        {(!document || !document.is_unlimited) ? (
                            <Block title="Истекает" className="expand-top">
                                {this.datepicker((index !== null) ? `register_request.documents.${index}.valid_to` : 'new_document.valid_to')}
                            </Block>
                        ) : null}
                        <div>
                            <div className="b-block _xl">
                                <button className="b-button b-button_red b-button_shadow_red b-button_size_md"
                                        onClick={::this.saveDocument}>Сохранить
                                </button>
                                <button className="b-button b-button_white b-button_shadow_gray b-button_size_md"
                                        onClick={::this.cancelDocument}>Отменить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getError(fieldName) {
        const field = _.findLast(fieldName.split('.'));

        return _.get(this.props.errors, field) || _.get(this.props.errors, fieldName) || _.get(this.state.errors, field) || _.get(this.state.errors, fieldName);
    }

    async uploadFile(index, e, results) {
        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        results.forEach(result => {
            const [e, file] = result;
            //formData.append('file', e.target.result);
            formData.append('file', file);
        });

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');

        this.setValue((index !== null) ? `register_request.documents.${index}.url` : 'new_document.url', value);
        this.setValue((index !== null) ? `register_request.documents.${index}.filename` : 'new_document.filename', response.original_filename);
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }

    saveDocument() {
        if (!this.validateDocument()) {
            return;
        }

        if (this.state.documentIndex === null) {
            let register_request = this.state.register_request;
            register_request.documents.push(this.state.new_document);
            this.setState({
                register_request,
                documentIndex: null,
                new_document: {
                    filename: '',
                    issued_at: null,
                    valid_to: null,
                },
            });
        } else {
            this.setState({
                documentIndex: null,
                new_document: {
                    filename: '',
                    issued_at: null,
                    valid_to: null,
                },
            });
        }
    }

    cancelDocument() {
        this.setState({
            documentIndex: null,
            new_document: {
                filename: '',
                issued_at: null,
                valid_to: null,
            },
        });
    }

    async send() {
        const documentTypes = _.map(_.filter(this.state.request_documents, (document) => {
            return _.indexOf(this.get('register_request.components'), document.document.component) !== -1;
        }), 'value');

        let full = true;
        _.each(documentTypes, (uuid) => {
            if (!_.find(this.get('register_request.documents'), {
                    request_document_uuid: uuid,
                })) {
                full = false;
            }
        });

        if (full) {
            this.sendReal();
        } else {
            alerts.prompt('Неполная комплектность документов. Продолжить?', null, () => {
                this.sendReal()
            }, 'Продолжить');
        }
    }

    async sendReal() {
        this.setState({loading: true});
        this.setState({
            errors: [],
        });
        let registerRequest = _.cloneDeep(this.state.register_request);
        registerRequest.update_source = 'user';
        const response = await this.props.updateRegisterRequest(registerRequest);
        this.setState({loading: false});

        if (response.isOk) {
            this.loadRegisterRequest();
        } else {
            response.showErrors();
            this.setState({
                errors: response.validationErrors,
            });
        }
    }

    async createNewConfirmRequest() {
        this.setState({
            loading: true,
        });
        const response = await this.props.createConfirmRequest({
            unit_uuid: this.state.unit,
            user_uuid: currentUser.uuid(),
            vehicles: [],
            users_count: this.props.max_user_length,
        });

        this.setState({
            loading: false,
        });
        if (response.isOk) {
            let confirm_requests = this.state.confirm_requests;
            confirm_requests.push(response.payload);
            this.setState({
                confirm_requests,
                confirm_request: response.payload,
                currentConfirmRequestUuid: response.payload.uuid,
            });
        } else {
            response.showErrors();
        }
    }

    validateDocument() {
        const index = this.state.documentIndex;
        const prefix = (index !== null) ? `register_request.documents.${index}.` : 'new_document.';

        const document = _.get(_.find(this.state.request_documents, {value: this.getValue(prefix + 'request_document_uuid')}), 'document');

        let errors = {};
        let fields = [
            'filename',
            'url',
            'request_document_uuid',
            'issued_at',
        ];
        if (!document || !document.is_unlimited) {
            fields.push('valid_to');
        }
        _.each(fields, (field) => {
            if (!this.getValue(prefix + field)) {
                errors[prefix + field] = 'Обязательно для заполнения';
            }
        });

        this.setState({
            errors,
        });

        return _.isEmpty(errors);
    }
}