import React from 'react';
import classNames from 'classnames';

export const SelectAllSlider = ({ onSelectDeselectAll, isDeselectAll, selectedCount, totalCount }) => {
	const sliderClassName = classNames({
		'b-slider__line': true,
		_selected_yes: selectedCount && selectedCount !== totalCount,
		_selected_all: selectedCount === totalCount
	});

	const sliderCircleClassName = classNames({
		'b-slider__circle': true,
		_selected_yes: selectedCount && selectedCount !== totalCount,
		_selected_all: selectedCount === totalCount
	});
	return (
		<div className="b-slider _options clearAfter" onClick={() => onSelectDeselectAll(isDeselectAll)}>
			<div className="b-slider__title">{isDeselectAll ? 'Снять все' : 'Выбрать все'}</div>
			<div className="b-slider__control">
				<div className={sliderClassName} />
				<div className={sliderCircleClassName} />
			</div>
		</div>
	);
};
