import React from 'react';
import PropTypes from 'prop-types';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ContextTooltip from "components/ui/context-tooltip";

const RoadModalButtons = ({ hideRoadModals }) => (
    <ModalTopMenuButtons>
        <ContextTooltip key="base-editor.close" code="base-editor.close" default="Закрыть">
            <ModalTopMenuButton
                className="_close"
                onClick={hideRoadModals}
            />
        </ContextTooltip>
    </ModalTopMenuButtons>
)

RoadModalButtons.propTypes = {
    hideRoadModals: PropTypes.func.isRequired,
}

export { RoadModalButtons };