import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';

import './index.less';
import PageModal from "components/ui/page-modal";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import _ from 'lodash';
import Datepicker from "components/ui/form/datepicker";
import moment from "moment";
import formats from "dictionaries/formats";
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Radio from "components/ui/form/radio";
import Checkbox from "components/ui/form/checkbox";
import Button from "components/ui/button";
import {connect} from "react-redux";
import {SelectAsync} from "components/ui/select";

const Handle = Slider.Handle;

@propTypes({
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object,
})

export default class RoutingModal extends Component {

    walkReluctances = [
        'По возможности исключить',
        'Минимизировать',
        'Без предпочтений',
        'Преимущественно пешком',
    ];

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ModalTopMenuButton
                    className="_close"
                    onClick={this.props.onClose}
                />
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{title: 'Планировщик поездки | Тестовая версия', buttons}}
                className="b-modal-trip-planner-params b-modal_w-footer"
                withFade={false}
                buttons={this.getRoutingButton()}>
                {this.renderContent()}
            </PageModal>
        );
    }

    getRoutingButton() {
        return [
            <Button key="routing" size="md" color="red" text="Построить маршрут" onClick={this.props.startRouting}/>
        ];
    }

    onKeyUp(e) {
        if (e.keyCode === 13) {
            this.props.startRouting();
        }
    }

    renderContent() {
        return (
            <Accordion onKeyUp={::this.onKeyUp}>
                <AccordionItem title="Основные точки маршрута"
                               opened={true}
                               afterTitle={<div className="accordion__menu">
                                   <div
                                       className="accordion__link accordion__link_icon accordion__link_icon_reverse"
                                       onClick={::this.reversePoints}/>
                               </div>}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-6">
                                <div className="b-block__title">Начальный пункт</div>
                                <div className="b-block__text">
                                    <div className="mappicker">
                                        <div className="input">
                                            <div className="input__wrapper">
                                                <div>
                                                    <SelectAsync
                                                        value={this.props.data.from.address}
                                                        onChange={this.onAddressChange.bind(this, 'from.address')}
                                                        loadOptions={this.props.suggestAddress}
                                                        options={this.props.data.from.address ? [
                                                            {
                                                                value: this.props.data.from.address,
                                                                label: this.props.data.from.address,
                                                            },
                                                        ] : null}
                                                    />
                                                    <a className="rnis-icon rnis-icon_big rnis-icon_map"
                                                       href="javascript:void(0)"
                                                       onClick={this.focusMapOn.bind(this, 'from')}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-6">
                                <div className="b-block__title">Конечный пункт</div>
                                <div className="b-block__text">
                                    <div className="mappicker">
                                        <div className="input">
                                            <div className="input__wrapper">
                                                <div>
                                                    <SelectAsync
                                                        value={this.props.data.to.address}
                                                        onChange={this.onAddressChange.bind(this, 'to.address')}
                                                        loadOptions={this.props.suggestAddress}
                                                        options={this.props.data.to.address ? [
                                                            {
                                                                value: this.props.data.to.address,
                                                                label: this.props.data.to.address,
                                                            },
                                                        ] : null}
                                                    />
                                                    <a className="rnis-icon rnis-icon_big rnis-icon_map"
                                                       href="javascript:void(0)"
                                                       onClick={this.focusMapOn.bind(this, 'to')}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="accordion__title"><span>Транзитные пункты</span></div>
                            {_.map(this.props.data.transit, ::this.renderTransit)}
                            {(this.props.data.transit.length === 0) ? (
                                <div className="col-xs-12 outer-indent-bottom">
                                    <span className="add-point"
                                          onClick={::this.addTransit}>+ Добавить</span>
                                </div>
                            ) : null}
                        </div>
                        <hr/>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="accordion__title"><span>Дата и время отправления:</span></div>
                            <div className="col-xs-3 expand-right">
                                <div className="b-block__text">
                                    <Datepicker
                                        value={this.props.data.date}
                                        onChange={this.onChange.bind(this, 'date')}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-9">
                                <Slider
                                    value={(this.timeToInt(this.props.data.time) < this.timeToInt('03:00')) ? (this.timeToInt(this.props.data.time) + 60 * 24) : this.timeToInt(this.props.data.time)}
                                    min={this.timeToInt('03:00')}
                                    max={this.timeToInt('02:50') + 60 * 24}
                                    step={10}
                                    marks={this.getSliderMarks(this.timeToInt('03:00'), this.timeToInt('03:00') + 60 * 24)}
                                    handle={::this.getSliderHandle}
                                    onChange={this.onCurrentTimeChange.bind(this, 'time')}
                                    className="mos-widget__row"
                                />
                            </div>
                        </div>
                    </div>
                </AccordionItem>
                <AccordionItem title="Дополнительные условия" opened={true}>
                    <div className="container-fluid">
                        <div className="row outer-indent-bottom">
                            <div className="col-xs-3">
                                <div className="b-block__title">Максимальная продолжительность, час</div>
                            </div>
                            <div className="col-xs-9">
                                <Slider
                                    value={this.timeToInt(this.props.data.max_time)}
                                    min={this.timeToInt('00:00')}
                                    max={this.timeToInt('04:00')}
                                    step={10}
                                    marks={this.getSliderMarks(this.timeToInt('00:00'), this.timeToInt('04:00'), 30)}
                                    handle={::this.getSliderHandle}
                                    onChange={this.onCurrentTimeChange.bind(this, 'max_time')}
                                    className="mos-widget__row"
                                />
                            </div>
                        </div>
                        {/*<div className="row outer-indent-bottom">
                            <div className="col-xs-3">
                                <div className="b-block__title">Максимальное число пересадок</div>
                            </div>
                            <div className="col-xs-9">
                                <div className="d-flex b-radio-wrap">
                                    <Radio
                                        checked={this.props.data.max_transfers === 0}
                                        label="Нет"
                                        value="0"
                                        field="max_transfers"
                                        onChange={this.onChange.bind(this, 'max_transfers')}
                                    />
                                    <Radio
                                        checked={this.props.data.max_transfers === 1}
                                        label="1"
                                        value="1"
                                        field="max_transfers"
                                        onChange={this.onChange.bind(this, 'max_transfers')}
                                    />
                                    <Radio
                                        checked={this.props.data.max_transfers === 2}
                                        label="2"
                                        value="2"
                                        field="max_transfers"
                                        onChange={this.onChange.bind(this, 'max_transfers')}
                                    />
                                    <Radio
                                        checked={this.props.data.max_transfers === 999}
                                        label="3+"
                                        value="999"
                                        field="max_transfers"
                                        onChange={this.onChange.bind(this, 'max_transfers')}
                                    />
                                </div>
                            </div>
                        </div>*/}
                        {/*<div className="row outer-indent-bottom">
                            <div className="col-xs-3">
                                <div className="b-block__title">Тип общественного транспорта</div>
                            </div>
                            <div className="col-xs-9">
                                <div className="d-flex b-checkbox-wrap">
                                    <Checkbox
                                        checked={this.props.data.mode_bus}
                                        label=""
                                        onChange={this.onChange.bind(this, 'mode_bus')}
                                    >
                                        <i className="rnis-icon rnis-icon_small rnis-icon_bus"/>
                                    </Checkbox>
                                    <Checkbox
                                        checked={this.props.data.mode_tram}
                                        label=""
                                        onChange={this.onChange.bind(this, 'mode_tram')}
                                    >
                                        <i className="rnis-icon rnis-icon_small rnis-icon_tram"/>
                                    </Checkbox>
                                    <Checkbox
                                        checked={this.props.data.mode_train}
                                        label=""
                                        onChange={this.onChange.bind(this, 'mode_train')}
                                    >
                                        <i className="rnis-icon rnis-icon_small rnis-icon_train"/>
                                    </Checkbox>
                                    <Checkbox
                                        checked={this.props.data.mode_subway}
                                        label=""
                                        onChange={this.onChange.bind(this, 'mode_subway')}
                                    >
                                        <i className="rnis-icon rnis-icon_small rnis-icon_subway"/>
                                    </Checkbox>
                                </div>
                            </div>
                        </div>*/}
                        <div className="row outer-indent-bottom">
                        </div>
                        <div className="row outer-indent-bottom custom">
                            <div className="col-xs-3">
                                <div className="b-block__title">Пешие переходы</div>
                            </div>
                            <div className="col-xs-9">
                                <Slider
                                    value={this.props.data.walk_reluctance}
                                    min={0}
                                    max={3}
                                    step={1}
                                    marks={this.walkReluctances}
                                    handle={::this.getSliderHandle2}
                                    onChange={this.onReluctanceChange.bind(this, 'walk_reluctance')}
                                    className="mos-widget__row"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-3">
                                <div className="b-block__title">Для людей с ограниченными возможностями</div>
                            </div>
                            <div className="col-xs-9">
                                <div className="d-flex b-checkbox-wrap">
                                    <Checkbox
                                        checked={this.props.data.is_wheelchair}
                                        label=""
                                        onChange={this.onChange.bind(this, 'is_wheelchair')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </AccordionItem>
            </Accordion>
        );
    }

    reversePoints() {
        const {from, to} = this.props.data;


        this.props.onChange('from', to);
        this.props.onChange('to', from);
    }

    async onCurrentTimeChange(field, value) {
        this.props.onChange(field, moment(this.intToTime(value), formats.TIME).format(formats.TIME));
    }

    async onReluctanceChange(field, value) {
        this.props.onChange(field, value);
    }

    getSliderHandle(props) {
        const {value, dragging, index, ...restProps} = props;
        return (
            <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={this.intToTime(value)}
                visible={dragging}
                placement="top"
                key={index}
            >
                <Handle value={value} {...restProps} />
            </Tooltip>
        );
    };

    getSliderHandle2(props) {
        const {value, dragging, index, ...restProps} = props;
        return (
            <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={this.walkReluctances[value]}
                visible={dragging}
                placement="top"
                key={index}
            >
                <Handle value={value} {...restProps} />
            </Tooltip>
        );
    };

    getSliderMarks(min, max, step = 120, inTime = true) {
        let result = {};
        for (let i = min; i <= max; i += step) {
            result[i] = inTime ? this.intToTime(i) : i;
        }
        return result;
    }

    intToTime(int) {
        let hours = _.padStart(Math.floor(int / 60), 2, '0');
        if (hours >= 24) {
            hours -= 24;
        }
        const minutes = _.padStart(int % 60, 2, '0');
        return `${hours}:${minutes}`;
    }

    timeToInt(time) {
        const timeObject = moment(time, formats.TIME);
        return timeObject.hours() * 60 + timeObject.minutes();
    }

    renderTransit(transit, index) {
        return (
            <div key={index}>
                <div className="col-xs-12">
                    <div className="b-block__title">Адрес</div>
                    <div className="b-block__text">
                        <div className="mappicker">
                            <div className="input">
                                <SelectAsync
                                    value={transit.address}
                                    onChange={this.onAddressChange.bind(this, `transit.${index}`)}
                                    loadOptions={this.props.suggestAddress}
                                    readonly={true}
                                    disabled={true}
                                    options={transit.address ? [
                                        {
                                            value: transit.address,
                                            label: transit.address,
                                        },
                                    ] : null}
                                />
                                <a className="rnis-icon rnis-icon_big rnis-icon_map"
                                   href="javascript:void(0)"
                                   onClick={this.focusMapOn.bind(this, `transit.${index}`)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 outer-indent-bottom">
                    <a className="remove-point" href="javascript:void(0)"
                       onClick={this.deleteTransit.bind(this, index)} draggable="false">- Удалить</a>
                    {(this.props.data.transit.length - 1 === index) ? (
                        <span className="add-point" onClick={::this.addTransit}>+ Добавить</span>
                    ) : null}
                </div>
            </div>
        );
    }

    onChange(field, {target: {value}}) {
        this.props.onChange(field, value);
    }

    onAddressChange(field, e) {
        const value = e ? e.value : null;
        this.props.onChange(field, value);
    }

    addTransit() {
        let transit = this.props.data.transit;
        transit.push({
            latitude: null,
            longitude: null,
            address: '',
        });
        this.props.onChange('transit', transit);
    }

    deleteTransit(index) {
        let transit = this.props.data.transit;
        transit.splice(index, 1);
        this.props.onChange('transit', transit);
    }

    focusMapOn(point) {
        this.props.routingFocus(point);
    }
}