import { getHostName } from '../wsrpc';
import { APIClient } from '@contracts/proto-js/Api_grpc_web_pb.js';
import { ObjectsStateRequest, DataFilter, FieldsToggle, ObjectsDataRangeRequest } from '@contracts/proto-js/Api_pb.js';
import { rpc } from './session';
import { gatewayService, gatewayServiceMetadata } from './constants';

export async function getTelematics(source, compression = 'precalculated', boundingBox = null, mapSize = null) {
	return await rpc.request('com.rnis.telematics.action.telematics.all', {
		compression,
		bounding_box: boundingBox,
		map_size: mapSize,
		source
	});
}

export async function getTelematicsWithDevices(
	devices,
	source,
	boundingBox = null,
	mapSize = null,
	compression = 'precalculated',
	zoom = null,
	withClusters = true,
	time_limit = null
) {
	const bounding_box = window.RNIS_SETTINGS.telematic_bounding_box ? { bounding_box: boundingBox } : null;
	return await rpc.request('com.rnis.telematics.action.telematics.get', {
		compression,
		map_size: mapSize,
		...bounding_box,
		source,
		zoom,
		devices,
		clusters: withClusters,
		time_limit
	});
}

export async function getTelematicsWithTemperatureGrpc(devices) {
	const metadata = {
		...gatewayServiceMetadata
	};
	const request = new ObjectsStateRequest();
	const filter = new DataFilter();
	const fieldsToggle = new FieldsToggle();
	filter.setDevicecodeList(devices);
	fieldsToggle.setPosition(true);
	fieldsToggle.setPorts(true);
	request.setFilter(filter);
	request.setFields(fieldsToggle);

	return new Promise((resolve, reject) => {
		gatewayService.getObjectsState(request, metadata, (err, response) => {
			if (err == null) {
				resolve(response.toObject());
			} else {
				reject(err);
			}
		});
	}).then((data) => data);
}

export const getObjectsHistoryData = (devices, dateFrom, dateTo) => {
	const request = new ObjectsDataRangeRequest();
	const filter = new DataFilter();
	filter.setDevicecodeList(devices);
	filter.setDatefrom(dateFrom);
	filter.setDateto(dateTo);
	request.setFilter(filter);
	return new Promise((resolve, reject) => {
		gatewayService.getObjectsDataRange(request, metadata, (err, response) => {
			if (err == null) {
				resolve(response.toObject());
			} else {
				reject(err);
			}
		});
	}).then((data) => data);
};

export async function getTelematicsBoundsWithDevices(devices, source) {
	return await rpc.request('com.rnis.telematics.action.telematics.bounds', {
		source,
		devices
	});
}

export function getTelematicsLogs(meta = {}) {
	const metadata = {
		...gatewayServiceMetadata,
		token: meta.token
	};
	const request = new ObjectsStateRequest();
	const filter = new DataFilter();
	filter.setDevicecodeList([ meta.bnso_number ]);
	filter.setLimit(10);
	filter.setDateto(1723235215);
	filter.setOrderdesc(true);
	request.setFilter(filter);

	return new Promise((resolve, reject) => {
		gatewayService.getObjectsDataRange(request, metadata, (err, response) => {
			if (err == null) {
				resolve(response.toObject());
			} else {
				reject(err);
			}
		});
	}).then((data) => data);
}

export async function getPanics(meta = {}) {
	return await rpc.request('com.rnis.telematics.action.panics.get', {}, { meta });
}
