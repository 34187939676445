import {Map} from "immutable";
import {api, CycleFetch} from "helpers/api";
import _ from "lodash";
import {success, error} from 'helpers/response';
import throttle from 'throttle-debounce/throttle';

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
const NOTIFICATION_READ = 'NOTIFICATION__READ';
const NOTIFICATION_EVENT_CREATED = 'NOTIFICATION_EVENT_CREATED';
const NOTIFICATION_EVENT_READ = 'NOTIFICATION_EVENT_READ';
const GET_TOTAL_UNREAD_NOTIFICATIONS = 'GET_TOTAL_UNREAD_NOTIFICATIONS';
const READ_NOTIFICATION = 'READ_NOTIFICATION';

const notificationsLoad = async (dispatch) => {
    try {
        let response = await api.notifications.getTotalUnreadNotifications();

        dispatch({
            type: GET_TOTAL_UNREAD_NOTIFICATIONS,
            payload: {
                data: response.payload
            }
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

const notificationsThrottle = throttle(1000, notificationsLoad);

const initialState = new Map({
    notifications: new Map([])
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case READ_NOTIFICATION:
            let notifications = _.clone(state.get('notifications'));
            const index = _.findIndex(notifications, {uuid: action.payload.item.uuid});
            if (index !== -1) {
                notifications.splice(index, 1);
            }
            return state.set('notifications', notifications);
        case GET_NOTIFICATIONS:
            return state.set('notifications', action.payload.data);
        case GET_TOTAL_UNREAD_NOTIFICATIONS:
            return state.set('count', action.payload.data.total);
        case NOTIFICATION_EVENT_CREATED:
            return state.set('notification', action.payload.data);
    }
    return state;
}

export const notificationEventCreated = (callback) => async (dispatch) => {
    api.notifications.notificationEventCreated((response) => {
        dispatch({
            type: NOTIFICATION_EVENT_CREATED,
            payload: {
                data: response.payload
            }
        });

        _.isFunction(callback) && callback(response.payload);
    });
};

export const notificationEventRead = (callback) => async (dispatch) => {
    api.notifications.notificationEventRead((response) => {
        dispatch({
            type: NOTIFICATION_READ,
            payload: {
                data: response.payload
            }
        });

        _.isFunction(callback) && callback(response.payload);
    });
};

export const readNotification = (item) => async (dispatch) => {
    try {
        let response = await api.notifications.readNotification(item);

        dispatch({
            type: READ_NOTIFICATION,
            payload: {
                item,
            },
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const readNotifications = (uuids) => async (dispatch) => {
    try {
        let response = await api.notifications.readNotifications(uuids);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getNotifications = (meta = {}) => async (dispatch) => {
    try {
        let response = await api.notifications.getNotifications(meta);

        dispatch({
            type: GET_NOTIFICATIONS,
            payload: {
                data: response.payload.notifications
            }
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getNotificationsList = (meta = {}) => async (dispatch) => {
    try {
        let response = await api.notifications.getNotifications(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getNotification = (uuid) => async (dispatch) => {
    try {
        let response = await api.notifications.getNotification(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTotalUnreadNotifications = () => async (dispatch) => {
    notificationsThrottle(dispatch);
};

export const getNotificationEvents = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.notifications.getNotificationEvents(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getNotificationEvent = (uuid) => async (dispatch) => {
    try {
        const response = await api.notifications.getNotificationEvent(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const sendNotificationEvent = (uuid) => async (dispatch) => {
    try {
        const response = await api.notifications.sendNotificationEvent(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createNotificationEvent = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.createNotificationEvent(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateNotificationEvent = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.updateNotificationEvent(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteNotificationEvent = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.deleteNotificationEvent(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};


export const getMailings = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.notifications.getMailings(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getMailing = (uuid) => async (dispatch) => {
    try {
        const response = await api.notifications.getMailing(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createMailing = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.createMailing(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateMailing = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.updateMailing(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteMailing = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.deleteMailing(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getEventRules = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.notifications.getEventRules(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getEventRule = (uuid) => async (dispatch) => {
    try {
        const response = await api.notifications.getEventRule(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createEventRule = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.createEventRule(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateEventRule = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.updateEventRule(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteEventRule = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.deleteEventRule(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getEventTypes = () => async (dispatch) => {
    try {
        const response = await api.notifications.getEventTypes();

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getEventRuleNotifications = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.notifications.getEventRuleNotifications(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getEventRuleNotification = (uuid) => async (dispatch) => {
    try {
        const response = await api.notifications.getEventRuleNotification(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createEventRuleNotification = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.createEventRuleNotification(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateEventRuleNotification = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.updateEventRuleNotification(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteEventRuleNotification = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.deleteEventRuleNotification(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};