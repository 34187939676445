import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import WidgetSelector from 'components/modules/analytics/base-components/WidgetSelector';
import BarChart from 'components/modules/analytics/base-components/charts/BarChart';
import LineChart from 'components/modules/analytics/base-components/charts/LineChart';
import Datepicker from "components/ui/form/datepicker";
import moment from 'moment';
import _ from 'lodash';
import formats from 'dictionaries/formats';
import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import GlobalLoaderComponent from "components/ui/global-loader";
import './style.less';
import Select from 'components/ui/select';
import {connect} from "react-redux";
import {orderRunSummary} from "store/reducers/kiutr/orders/order_runs";
import {getUnitContractsPlanSummary} from "store/reducers/kiutr/contracts/contracts";
import * as storage from "utils/storage";
import {Text} from "recharts";

@propTypes({
    barChartFillColor: PropTypes.string
})

@defaultProps({
    barChartFillColor: ''
})

@connect(state => ({}), {orderRunSummary, getUnitContractsPlanSummary})

export default class TransportWorkWidget extends Component {

    storageKey = 'analytics:passenger:transport-work';

    constructor(props) {
        super(props);

        const savedState = storage.get(this.storageKey);

        this.state = {
            type: savedState ? savedState.type : 'period',
            date: savedState ? moment(savedState.date).format(formats.DATE) : moment().format(formats.DATE),
            period: savedState ? savedState.period : null,
            data: {
                organizationUnits: [],
                unitsData: []
            },
            chartType: savedState ? savedState.chartType : 'bar',
        };
    }

    saveState() {
        storage.set(this.storageKey, {
            type: this.state.type,
            date: moment(this.state.date).format(formats.DATE_API),
            period: this.state.period,
            chartType: this.state.chartType,
            organizationUnits: _.map(_.filter(this.state.data.organizationUnits, {checked: true}), 'uuid'),
        });
    }

    componentWillMount() {
        this.loadUnits();
    }

    getUnits() {
        return api.organizational_units.getCarriers({
            filters: {
                withComponent: 'kiutr',
            },
        });
    }

    async loadUnits() {
        this.setState({isError: false, isLoading: true});

        try {
            const response = await this.getUnits();

            const savedState = storage.get(this.storageKey);
            let organizationUnits = _.map(response.payload.items, (item) => _.pick(item, ['uuid', 'name']));
            if (savedState) {
                _.each(savedState.organizationUnits, (uuid) => {
                    let unit = _.find(organizationUnits, {uuid});
                    if (unit) {
                        unit.checked = true;
                    }
                });
            }
            const data = {
                organizationUnits,
                unitsData: [],
            };

            this.setState({data, isError: false, isLoading: false});
        }
        catch (e) {
            this.setState({isError: true, isLoading: false});
            error(e).showErrors();
        }
    }

    async loadData() {
        this.setState({isLoading: true});

        const units = _.map(_.filter(this.state.data.organizationUnits, {checked: true}), 'uuid');

        let from;
        let to;
        let planField;
        if (this.state.type === 'period') {
            switch (this.state.period) {
                case 'quarter_1':
                    planField = ['mileage_quarter_1'];
                    from = moment().month(0).startOf('month');
                    to = moment().month(2).endOf('month');
                    break;
                case 'quarter_2':
                    planField = ['mileage_quarter_2'];
                    from = moment().month(3).startOf('month');
                    to = moment().month(5).endOf('month');
                    break;
                case 'quarter_3':
                    planField = ['mileage_quarter_3'];
                    from = moment().month(6).startOf('month');
                    to = moment().month(8).endOf('month');
                    break;
                case 'october':
                    planField = ['mileage_october'];
                    from = moment().month(9).startOf('month');
                    to = moment().month(9).endOf('month');
                    break;
                case 'november':
                    planField = ['mileage_november'];
                    from = moment().month(10).startOf('month');
                    to = moment().month(10).endOf('month');
                    break;
                case 'december':
                    planField = ['mileage_december'];
                    from = moment().month(11).startOf('month');
                    to = moment().month(11).endOf('month');
                    break;
            }
        } else {
            planField = [
                'mileage_quarter_1',
                'mileage_quarter_2',
                'mileage_quarter_3',
                'mileage_october',
                'mileage_november',
                'mileage_december',
            ];
            from = moment().startOf('year');
            to = moment(this.state.date);
        }

        if (!from || !to) {
            this.setState({isLoading: false});
            return;
        }

        const response = await this.props.orderRunSummary({
            units: units,
            date_from: from.format(formats.DATE),
            date_to: to.format(formats.DATE),
        });

        this.setState({isLoading: false});
        if (response.isOk) {
            const fact = response.payload.items;
            const plan = await this.loadPlan(units);

            let data = this.state.data;
            data.unitsData = _.map(units, (unitUuid) => {
                const unitFact = _.get(_.find(fact, {unit_uuid: unitUuid}), 'mileage') || 0;
                const unitPlanObject = _.find(plan, {unit_uuid: unitUuid});
                let unitPlan = 0;
                _.each(planField, (field) => {
                    unitPlan += unitPlanObject[field];
                });

                return {
                    name: _.get(_.find(this.state.data.organizationUnits, {uuid: unitUuid}), 'name'),
                    total: _.round(unitFact + unitPlan, 2),
                    plan: _.round(unitPlan, 2),
                    fact: _.round(unitFact, 2),
                    config: {
                        plan: {
                            name: 'План',
                            color: '#5caa5e'
                        },
                        fact: {
                            name: 'Факт',
                            color: '#3398aa'
                        }
                    }
                };
            });
            this.setState({data});
        } else {
            response.showErrors();
        }
    }

    async loadPlan(units) {
        const response = await this.props.getUnitContractsPlanSummary({
            units,
        });

        if (response.isOk) {
            return response.payload.items;
        } else {
            response.showErrors();
        }
    }

    dateChange = async ({target: {value}}) => {
        await this.setState({date: value});
        this.saveState();
        this.loadData();
    };

    onChartTypeButtonClick = async () => {
        const chartType = this.state.chartType === 'bar' ? 'line' : 'bar';
        await this.setState({chartType});
        this.saveState();
    };

    async typeChange(e) {
        await this.setState({
            type: _.get(e, 'value'),
        });
        this.saveState();
        this.loadData();
    }

    async periodChange(e) {
        await this.setState({
            period: _.get(e, 'value'),
        });
        this.saveState();
        this.loadData();
    }

    async onSelectedUnitsChange(param) {
        let data = this.state.data;
        _.each(param, ({index, checked}) => {
            data.organizationUnits[index].checked = checked;
        });
        await this.setState({data});
        this.saveState();
        this.loadData();
    }

    renderContent() {
        return (
            <div className="full-size">
                <div className="tools-wrapper">
                    <div className="tools-item select-wrapper">
                        <Select
                            value={this.state.type}
                            options={[{
                                value: 'period',
                                label: 'Период',
                            }, {
                                value: 'date',
                                label: 'Дата',
                            }]}
                            onChange={::this.typeChange}
                            clearable={false}
                        />
                    </div>
                    {(this.state.type === 'date') ? (
                        <div className="tools-item widget-datepicker">
                            <span className="date-label">Дата:</span>
                            <Datepicker style="light" value={this.state.date} onChange={this.dateChange}/>
                        </div>
                    ) : null}
                    {(this.state.type === 'period') ? (
                        <div className="tools-item select-wrapper">
                            <Select
                                value={this.state.period}
                                options={[
                                    {
                                        value: 'quarter_1',
                                        label: '1-й квартал',
                                    },
                                    {
                                        value: 'quarter_2',
                                        label: '2-й квартал',
                                    },
                                    {
                                        value: 'quarter_3',
                                        label: '3-й квартал',
                                    },
                                    {
                                        value: 'october',
                                        label: 'Октябрь',
                                    },
                                    {
                                        value: 'november',
                                        label: 'Ноябрь',
                                    },
                                    {
                                        value: 'december',
                                        label: 'Декабрь',
                                    },
                                ]}
                                onChange={::this.periodChange}/>
                        </div>
                    ) : null}
                    <div className="tools-item select-wrapper">
                        <WidgetSelector
                            title="Транспортное предприятие"
                            items={this.state.data.organizationUnits}
                            onChange={::this.onSelectedUnitsChange}
                        />
                    </div>
                    <div className="tools-item">
                        <div className="chart-type-button-wrapper">
                            <button className="chart-type-button" onClick={this.onChartTypeButtonClick}
                                    title="Тип графика">
                                <i className="chart-type-icon"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="chart-wrapper">
                    {this.state.chartType === 'bar' ?
                        <BarChart data={this.state.data.unitsData}
                                  xDataKey="name"
                                  yDataKey="total"
                                  yLabel={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle" angle={-90}>Км</Text>}
                                  configKey="config"
                                  color={this.props.barChartFillColor}
                        />
                        :
                        <LineChart data={this.state.data.unitsData}
                                   xDataKey="name"
                                   yDataKey="total"
                                   yLabel={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle" angle={-90}>Км</Text>}
                                   configKey="config"
                                   color={this.props.barChartFillColor}
                        />
                    }
                </div>
            </div>
        );
    }

    render() {
        const loader = this.state.isLoading ? (<GlobalLoaderComponent/>) : null;

        return (
            <div className="transport-work-widget">
                {loader}
                {this.renderContent()}
            </div>
        );
    }
}