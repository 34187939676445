import React, {Component} from 'react';
import {defaultProps, propTypes} from 'react-props-decorators';
import {Map, List} from 'immutable';

import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import Colors from '../../helpers/colors';

import './bar-chart.less';



@defaultProps({
  itemTypes: new Map({
    'ДТП': 'blue',
    'Пожары': 'orange',
    'Сигналы SOS': 'purple',
    'Другое': 'gray'
  }),
  items: new List([{name: 'Page A', 'ДТП': 27, 'Пожары': 4, 'Сигналы SOS': 22, 'Другое': 15}])
})

export default class BarChartComponent extends Component {

  render() {
    const Bars = this.props.itemTypes
      .map(::this.renderBar)
      .toArray();

    return (
      <div className="bar-chart-example">
        <ResponsiveContainer>
          <BarChart data={this.props.items.toArray()} barGap={40}>
            <Legend verticalAlign="top" margin={{left: 40, bottom: 100}} />
            {Bars}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  renderBar(color, key) {
    console.log("key", key, color, Colors[key]);
    return (
      <Bar key={`bar-${key}`} dataKey={key}  fill={Colors[color]}/>
    );
  }

}
