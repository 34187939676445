import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapMarker from "components/ui/map/base/marker";
import $ from 'jquery';
import VehicleIcon from "components/ui/map/markers/icons/vehicle-icon";
import ReactPopup from "react-leaflet-popup";
import RoutingIcon from "components/ui/map/markers/icons/routing-icon";

@propTypes({
    type: PropTypes.string,
    onDrag: PropTypes.func,
})

@defaultProps({
    type: 'transit',
})

export default class RoutingMarker extends AbstractMapMarker {

    getOptions() {
        return {
            icon: RoutingIcon.getIcon(this.props.type),
            riseOnHover: true,
            draggable: true,
        };
    }

    componentWillMount() {
        super.componentWillMount();

        if (this.props.onDrag) {
            this.getMarker().on('dragend', this.props.onDrag);
        }
        if (this.props.tooltip) {
            this.getMarker().unbindTooltip().bindTooltip(this.props.tooltip, {
                permanent: true,
                interactive: true,
            });
        }
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        if (this.props.tooltip) {
            this.getMarker().unbindTooltip().bindTooltip(this.props.tooltip, {
                permanent: true,
                interactive: true,
            });
        }
    }

}