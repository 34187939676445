import React from 'react';
import {Link} from "react-router";
import formats from "../../../../../../../../dictionaries/formats";
import moment from "moment";

export default function VehicleTable({uuid, stateNumber, garageNumber, capacity, model, bnso, environmentalClass, from, to, onClick}) {
    from = moment(from).format(formats.TIME)
    to = moment(to).format(formats.TIME)
    return (
        <div className="output__cell-group">
            <div className="output__cell">
                <span className="output-icon output-icon_bus"/>
            </div>
            <div className="output__cell">
                <span>Гос. номер</span><br/>
                <span>Гар. номер</span>
            </div>
            <div className="output__cell">
                <a href="javascript:void(0)" onClick={() => {
                    onClick(uuid);
                }}>{stateNumber}</a><br/>
                {garageNumber}
            </div>
            <div className="output__cell _border_left">
                <span>Тип</span><br/>
                <span>Модель</span>
            </div>
            <div className="output__cell">
                {capacity}<br/>
                {model}
            </div>
            <div className="output__cell _border_left">
                <span>БНСО</span><br/>
                <span>Класс</span>
            </div>
            <div className="output__cell">
                {bnso}<br/>
                {environmentalClass}
            </div>
            <div className="output__cell _border_left">
                <span>С</span><br/>
                <span>По</span>
            </div>
            <div className="output__cell">
                {from}<br/>
                {to}
            </div>
        </div>
    );
}