import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import _ from 'lodash';
import {success, error} from 'helpers/response';

const GET_UNITS = 'GET_UNITS';

const initialState = new Map({
    units: {},
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_UNITS:
            return state
                .set('units', _.mapValues(_.keyBy(action.payload.items, 'uuid'), 'name'));
    }

    return state;
}

export const getUsers = (meta) => async (dispatch) => {
    try {
        const response = await api.auth.getUsers(meta);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getCleanupSpecialists = (meta) => async (dispatch) => {
    try {
        const response = await api.auth.getCleanupSpecialists(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getUsersByUnits = (units, meta = {}) => async (dispatch) => {
    try {
        const response = await api.auth.getUsersByUnits(units, meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getUnits = (uuids = null) => async (dispatch) => {
    try {
        const params = uuids ? {
            'withUuid': uuids
        } : {};
        const response = await api.organizational_units.getUnits({filters: params});

        dispatch({
            type: GET_UNITS,
            payload: {
                items: response.payload.items
            },
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRoles = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.auth.getRoles(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getUserCan = (code, grant, unitUuid) => async (dispatch) => {
    try {
        const response = await api.auth.getUserCan(code, grant, unitUuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};