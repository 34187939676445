import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const GET_LAYERS = 'GET_LAYERS';
const GET_OBJECTS = 'GET_OBJECTS';

const initialState = new Map({
    layers: [],
    objects: [],
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_LAYERS:
            return state.set('layers', action.payload.items);
        case GET_OBJECTS:
            return state.set('objects', action.payload.items);
    }

    return state;
}

export const getLayers = () => async (dispatch) => {
    try {
        const response = await api.geo.getLayers({
            order: {
                column: 'title',
                direction: 'asc',
            },
        });
        console.log("🚀 ~ file: layer_list.js ~ line 32 ~ getLayers ~ response", response)

        dispatch({
            type: GET_LAYERS,
            payload: {
                items: response.payload.items
            }
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getLayerObjects = (layerUuid) => async (dispatch) => {
    try {
        // if (!layerUuid) {
        //     dispatch({
        //         type: GET_OBJECTS,
        //         payload: {
        //             items: null
        //         }
        //     });
        // } else {
            const response = await api.geo.getUserGeoObjectByLayers([layerUuid]);

            dispatch({
                type: GET_OBJECTS,
                payload: {
                    items: response.payload.items
                }
            });

            return success(response);
        // }
    }
    catch (e) {
        return error(e);
    }
};

export const getLayerObjectsWithoutStore = (layerUuid) => async (dispatch) => {
    try {
        const response = await api.geo.getUserGeoObjectByLayers([layerUuid]);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getLayersObjectsWithoutStore = (layersUuids) => async (dispatch) => {
    try {
        const response = await api.geo.getUserGeoObjectByLayers(layersUuids);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getLayersFull = () => async (dispatch) => {
    try {
        const response = await api.geo.getLayers();

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getLayerObjectsFull = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getUserGeoObjects(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};