import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import './stop-points-zoom.less';
import {LabeledCheckbox} from "components/ui/checkbox";

@propTypes({
    onClick: PropTypes.func.isRequired,
})

export default class StopPointsZoom extends Component {

    render() {
        return (
            <div className="checkbox-labeled" onClick={this.props.onClick}>
                <span style={{marginLeft: '38px'}}>Увеличить масштаб до видимости остановок</span>
            </div>
        );
    }

}