import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import _ from 'lodash';

import { connect } from 'react-redux';

import BaseEditorFormComponent from 'components/base/base-editor-form';
import Block from 'components/ui/form/block';
import { getDictionaryList } from 'store/reducers/dictionaries/dictionary';
import Accordion from 'components/ui/accordion/accordion';
import AccordionItem from 'components/ui/accordion/accordion-item';
import BaseEditor from 'components/base/base-editor';
import moment from 'moment';
import formats from 'dictionaries/formats';
import { getOrder, resourceCheck, updateOrder } from 'store/reducers/kiutr/orders/orders';
import { getUnit } from 'store/reducers/organizational_units/units';
import { getVehicle, getVehicleList } from 'store/reducers/vehicles/vehicles';
import { getContract } from 'store/reducers/kiutr/contracts/contracts';
import OrderVehicleViolations from './VehicleViolations';
import { getUsers } from 'store/reducers/staffing/staffing';
import { User } from 'helpers/user';
import { getUser } from 'store/reducers/staffing/staffing_editor';
import ContextTooltip from 'components/ui/context-tooltip';
import ModalTopMenuListItem from 'components/ui/modal/modal-top-menu-list-item';
import KiutrOrderRunsComponent from 'components/modules/kiutr/orders/OrderPlanningForm/OrderRuns/index';
import Tooltip from 'react-tooltip-component';
import { print } from 'helpers/print';
import ReactDOMServer from 'react-dom/server';
import OrderPrintComponent from 'components/modules/kiutr/orders/OrderPrint/index';
import { Link } from 'react-router';
import OrderEditor from 'components/modules/kiutr/orders/OrderOperationalForm/OrderEditor/index';
import { component_mapper } from 'helpers/component_mapper';
import currentUser from 'helpers/current-user';
import * as alerts from '../../../../../helpers/alerts';
import { checkLimitationInstallation } from 'helpers/functions';

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	uuid: PropTypes.string.isRequired,
	order: PropTypes.object
})
@connect((state) => ({}), { getOrder, getVehicleList, getUnit, getUsers, updateOrder, getDictionaryList, getContract })
export default class OrderPlanningForm extends BaseEditor {

    modalClassName = 'orders-modal orders-modal-main';

    static contextTypes = {
        resizeModals: PropTypes.func
    };

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            runsActive: false,
            vehicles: {},
            unit: {},
            drivers: {},
            checkTakers: {},
            fullDrivers: {},
            vehicle_models: [],
            vehicle_marks: [],
        });
    }

    componentDidMount() {
        this.forceUpdate();
    }

    getFullTitle() {
        return <span>План-наряд №{_.get(this.state.item, 'number', '-')} на {this.state.item ? moment(this.state.item.date).format(formats.DATE) : '-'}</span>;
    }

    async loadData(uuid) {
        const response = {
            isOk: true,
            payload: this.props.order,
        };

        if (response.isOk) {
            this.loadDictionaries([
                'vehicle_models',
                'vehicle_marks',
            ]);
            this.loadVehicles(_.filter(_.uniq(_.map(_.flatten(_.map(response.payload.shifts, 'runs')), 'vehicle_uuid'))));
            this.loadContract(response.payload.contract_uuid);
            await this.setState({
                drivers: _.map(await this.loadDrivers(_.filter(_.uniq(_.map(_.flatten(_.map(response.payload.shifts, 'runs')), 'driver_uuid')))), (user) => {
                    return new User(user).getFullName()
                }),
                checkTakers: _.map(await this.loadDrivers(_.filter(_.uniq(_.map(_.flatten(_.map(response.payload.shifts, 'runs')), 'check_taker_uuid')))), (user) => {
                    return new User(user).getFullName()
                }),
                fullDrivers: await this.loadDrivers(_.concat(
                    _.filter(_.uniq(_.map(_.flatten(_.map(response.payload.shifts, 'runs')), 'driver_uuid'))),
                    _.filter(_.uniq(_.map(_.flatten(_.map(response.payload.shifts, 'runs')), 'check_taker_uuid'))),
                )),
            });
        }

        return response;
    }

    async loadVehicles(uuids) {
        if (uuids.length === 0) {
            return;
        }

        const response = await this.props.getVehicleList({
            filters: {
                withUuid: uuids,
            },
            response_data: [
                'items/uuid',
                'items/state_number',
                'items/garage_number',
                'items/vehicle_model_uuid',
            ],
        });
        if (response.isOk) {
            this.setState({
                vehicles: _.keyBy(response.payload.items, 'uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadContract(uuid) {
        if (!uuid) {
            return null;
        }

        const response = await this.props.getContract(uuid);
        if (response.isOk) {
            this.loadUnit(response.payload.carrier_uuid);
        }
    }

    async loadUnit(uuid) {
        if (!uuid) {
            return null;
        }

        const response = await this.props.getUnit(uuid);
        if (response.isOk) {
            this.setState({
                unit: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadDrivers(uuids) {
        if (!uuids || (uuids.length === 0)) {
            return null;
        }

        const response = await this.props.getUsers({
            filters: {
                withUuid: uuids,
            },
            response_data: [
                'items/uuid',
                'items/info/surname',
                'items/info/name',
                'items/info/second_name',
                'items/info/personnel_number',
                'items/info/driver_serial',
                'items/info/driver_number',
            ],
        });
        if (response.isOk) {
            return _.keyBy(response.payload.items, 'uuid');
        } else {
            response.showErrors();
        }
        return {};
    }

    async updateItem(data) {
        if (currentUser.user.free_user_settings.vehicleTomorrow) {
            // берем задержку
            let delayTime = currentUser.user.free_user_settings.vehicleTomorrow;
            // ищем кнопку
            let btn = $('div.b-modal__button-block').find('._save');
            // дизейблим ее
            btn.addClass('b-button_disabled').text(`Сохранить (${delayTime})`);
            // если пользователь затыкал кнопку (уже был создан таймер) то сбрасываем таймер
            if (window.vehicleTomorrowTimer != undefined || window.vehicleTomorrowTimer != 'undefined') {
                clearInterval(window.vehicleTomorrowTimer);
            }
            let self = this;
            // запускаем таймер
            window.vehicleTomorrowTimer = setInterval(async () => {
                delayTime -= 1;
                if (delayTime <= 0) {
                    // если таймер закончился, то удаляем таймер
                    clearInterval(window.vehicleTomorrowTimer);
                    // удаляем класс дизейбл
                    btn.removeClass('b-button_disabled').text(`Сохранить`);
                    // и выполняем сохранение
                    return await this.__updateItem(data).then((response) => {
                        // редиректим на список план-нарядов
                        response.isOk ? this.props.router.push(`/${this.props.params.component}/orders/`) : null;
                    });
                } else {
                    btn.text(`Сохранить (${delayTime})`);
                }

            }, 1000);
        } else {
            return this.__updateItem(data);
        }
    }

    async __updateItem(data) {
        return await this.props.updateOrder(data);
    }

    renderHeaderBtns(mode) {
        if (mode === 'edit') {
            return (
                <ContextTooltip key="orders.runs" code="orders.runs" default="Порейсовое обеспечение">
                    <ModalTopMenuListItem
                        className="b-icon-link_icon_book"
                        onClick={::this.showRuns}
                    />
                </ContextTooltip>
            );
        }

        return null;
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                showEditor={::this.showEditor}
                vehicles={this.state.vehicles}
                drivers={this.state.drivers}
                checkTakers={this.state.checkTakers}
            />
        );
    }

    resize() {
        this.context.resizeModals && this.context.resizeModals();
    }

    renderModals() {
        if (this.state.runsActive) {
            return (
                <KiutrOrderRunsComponent
                    {...this.props}
                    order={this.state.item}
                    onSubmit={this.hideRuns.bind(this, true)}
                    onClose={this.hideRuns.bind(this, false)}
                />
            );
        }

        if (this.state.editorActive) {
            return (
                <OrderEditor
                    {...this.props}
                    key="editor"
                    onClose={this.hideEditor.bind(this, false)}
                    onSubmit={this.hideEditor.bind(this, true)}
                    shift={this.state.editorShift}
                    order={this.state.item}
                />
            );
        }

        return null;
    }


    async showRuns() {

        if (checkLimitationInstallation( _.get(this.state.item, 'date'))) {
            alerts.alert(`Данное действие невозможно после  ${moment(_.get(this.state.item, 'date')).format(formats.DATE)} ${window.RNIS_SETTINGS.installation_limitation_time}`);
            return;
        }

        await this.setState({
            runsActive: true,
        });

        this.resize();
    }

    async hideRuns(reload = false) {
        reload && this.loadData(this.props.uuid);

        await this.setState({
            runsActive: false,
        });

        this.resize();
    }

    printButton() {
        if (this.state.vehicles.length === 0) {
            return null;
        }
        return (
            <ContextTooltip key="base-editor.close" code="base-editor.close" default="Печать" position="bottom">
                <ModalTopMenuListItem
                    className="b-icon-link_icon_print"
                    onClick={::this.print}
                />
            </ContextTooltip>
        );
    }

    regularityMatrixButton() {
        return (
            <ContextTooltip key="base-editor.regularity" code="base-editor.close" default="Матрица регулярности" position="bottom">
                <ModalTopMenuListItem
                    className="b-icon-link_icon_print"
                    onClick={::this.openMatrix}
                />
            </ContextTooltip>
        );
    }

    openMatrix() {
        this.props.router.push('/kiutr/regularity-matrix');
    }

    print() {
        print(ReactDOMServer.renderToStaticMarkup(<OrderPrintComponent
            unit={this.state.unit}
            vehicles={this.state.vehicles}
            drivers={this.state.fullDrivers}
            order={this.state.item}
            vehicle_models={this.state.vehicle_models}
            vehicle_marks={this.state.vehicle_marks}
        />));
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.mapValues(_.keyBy(item.documents, 'uuid'), 'name');
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }

    async showEditor(editorShift = null) {
        await this.setState({
            editorActive: true,
            editorShift,
        });
        this.context.resizeModals();
    }

    async hideEditor(reload = false, type) {
        reload && this.reload();

        await this.setState({
            editorActive: false,
        });
        this.context.resizeModals();
    }
}

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	data: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	errors: PropTypes.object
})
@connect(
	(state) => ({}),
	{
		getUnit,
		getVehicleList,
		getContract,
		getUsers,
		getVehicle,
		getUser,
		resourceCheck
	},
	null,
	{ withRef: true }
)
class EditorForm extends BaseEditorFormComponent {
	state = {
		order: {},
		unit: {},
		contract: {},
		vehicle: {},
		vehicles: []
	};

	getData() {
		return this.state.order;
	}

	async componentDidMount() {
		await this.setState({
			order: this.props.data
		});
		this.loadContract(this.props.data.contract_uuid);
		this.loadContract();
		this.loadOrderVehicles();
	}

	async loadOrderVehicles() {
		const uuids = _.uniq(_.filter(_.map(_.flatten(_.map(this.state.order.shifts, 'runs')), 'vehicle_uuid')));

		const response = await this.props.getVehicleList({
			filters: {
				withUuid: uuids
			},
			response_data: [ 'items/state_number' ]
		});

		if (response.isOk) {
			this.setState({
				vehicles: _.map(response.payload.items, 'state_number')
			});
		} else {
			response.showErrors();
		}
	}

	async loadUnit(uuid) {
		if (!uuid) {
			return null;
		}

		const response = await this.props.getUnit(uuid);
		if (response.isOk) {
			this.setState({
				unit: response.payload
			});
		} else {
			response.showErrors();
		}
	}

	async loadContract() {
		const response = await this.props.getContract(this.state.order.contract_uuid);
		if (response.isOk) {
			this.setState({
				contract: response.payload
			});
			this.loadUnit(response.payload.carrier_uuid);
		}
	}

	get(path, defaultValue = null) {
		return _.get(this.state.order, path, defaultValue);
	}

	render() {
		const runs = _.flatten(_.map(this.state.order.shifts, 'runs'));
		const routes = _.filter(_.uniq(_.map(runs, 'route_uuid')));
		const from_time =
			this.state.order.work_from && this.state.order.work_from.slice(0, this.state.order.work_from.length - 6);
		const to_time =
			this.state.order.work_to && this.state.order.work_to.slice(0, this.state.order.work_from.length - 6);
		return (
			<div>
				<Accordion>
					<AccordionItem opened={true} title="Общая информация">
						<Block title="Исполнитель">{this.state.unit.name}</Block>
						<Block size="xl">
							<Link to={`/${this.props.params.component}/routes/${this.state.order.route_uuid}`}>Паспорт маршрута</Link>
						</Block>
						{_.map(routes, (routeUuid) => {
							const routeNumber = _.first(_.filter(runs, { route_uuid: routeUuid })).route_number;

							return (
								<Block key={routeUuid} size="xl">
									<a
										href={`/${this.props.params.component}/map/order/${this.state.order.uuid}?route=${routeUuid}`}
										target="_blank"
									>
										Маршрут {routeNumber} на карте
									</a>
								</Block>
							);
						})}
						{this.props.vehicles && (
							<Block size="xl">
								<Link
									to={`/${this.props.params.component}/map/order/${this.state.order.uuid}?route=${this.state.order
										.route_uuid}&vehicles=${Object.keys(this.props.vehicles).join(',')}&from_time=${from_time}&to_time=${to_time}`}
								>
									Мониторинг
								</Link>
							</Block>
						)}
					</AccordionItem>
					<AccordionItem
						opened={true}
						title={`Обеспечение выхода с ${this.get('schedule_turn.start_at')} (${this.get('route_name')})`}
					>
						{!this.get('is_only_run_edit') ? !window.RNIS_SETTINGS.different_ts_select ? (
							<Block size="md" title="ТС">
								{this.selectAsync('order.vehicle_uuid', ::this.loadVehicles, {
									onChange: ::this.changeOrderVehicle
								})}
								<div className="block-icons-outer">
									<OrderVehicleViolations vehicle={this.state.vehicle} contract={this.state.contract} />
								</div>
							</Block>
						) : (
							<div />
						) : (
							<Block size="md" title="ТС">
								{_.map(this.props.vehicles, 'state_number').join(', ')}
							</Block>
						)}
					</AccordionItem>
					{this.get('shifts', []).map(::this.renderShift)}
				</Accordion>
			</div>
		);
	}

	changeOrderVehicle(e) {
		const value = e ? e.value : null;
		const vehicle = e ? e.vehicle : {};
        const order = this.state.order
        order.shifts.map(items=>{
            items.runs.forEach(run=>{
                run.vehicle_uuid = value
                run.vehicle_state_number = vehicle.state_number
            })
        })
        order.vehicle_uuid = value
        this.onChangeInput("order", {target: {value: order}});
		this.onChangeInput('vehicle', { target: { value: vehicle } });

		value && this.checkVehicle();
	}

	changeOrderVehicle__(e) {
		/* const value = e ? e.value : null;
        const vehicle = e ? e.vehicle : {};

        this.onChangeInput('order.vehicle_uuid', {target: {value}});
        this.onChangeInput('vehicle', {target: {value: vehicle}});

        value && this.checkVehicle();*/
		///        console.log(e)
	}

	async loadVehicles(input, callback, index) {
		if ((!input && index) || (!input && index === 0)) {
			return this.loadVehicle(_.get(this.props.data, `shifts.${index}.vehicle_uuid`), callback);
			//return this.loadVehicle(this.props.data.vehicle_uuid, callback);
		}

		if (!input && !index) {
			return this.loadVehicle(this.props.data.vehicle_uuid, callback);
		}

		const result = await this.props.getVehicleList({
			filters: {
				withComponent: component_mapper(this.props.params.component),
				withoutScrapped: true,
				onlyApproved: true,
				withActiveBnso: true
			},
			search: input,
			pagination: {
				page: 1,
				limit: 20
			},
			response_data: [
				'items/uuid',
				'items/state_number',
				'items/environmental_class_uuid',
				'items/is_low_floor_level',
				'items/is_air_conditioning_installation',
				'items/is_electronic_scoreboard',
				'items/is_audio_video_fixation',
				'items/is_cashless_payment',
				'items/is_passenger_monitoring_system'
			]
		});

		if (result.isOk) {
			callback(null, {
				options: result.payload.items.map((item) => ({
					label: (
						<div>
							{item.state_number}
							<div className="block-icons-inner">
								<OrderVehicleViolations vehicle={item} contract={this.state.contract} />
							</div>
						</div>
					),
					value: item.uuid,
					vehicle: item
				})),
				complete: false
			});
		} else {
			result.showErrors();
		}
	}

	async loadVehicle(uuid, callback) {
		if (!uuid) {
			callback(null, {
				options: [],
				complete: false
			});
			return;
		}

		const result = await this.props.getVehicle(uuid);
		if (result.isOk) {
			const vehicle = result.payload;
			this.setState({ vehicle });
			callback(null, {
				options: [
					{
						label: vehicle.state_number,
						value: vehicle.uuid,
						vehicle
					}
				],
				complete: false
			});
		} else {
			result.showErrors();
		}
	}

	showEditor(shift) {
		this.props.showEditor(shift);
	}

	renderShift(shift, index) {
		return (
			<AccordionItem
				key={shift.shift}
				opened={true}
				title={`Смена ${shift.shift} ${moment(shift.start_at).format(formats.TIME)} - ${moment(shift.end_at).format(
					formats.TIME
				)}`}
			>
				<Block size="xl">
					<a className="edit" href="javascript:void(0)" onClick={this.showEditor.bind(this, shift.shift)}>
						Редактировать
					</a>
				</Block>
				<Block size="xl" title="Маршруты">
					{_.map(_.filter(_.uniq(_.map(shift.runs, 'route_number'))), (routeNumber) => {
						const run = _.find(shift.runs, { route_number: routeNumber });
						const runs = _.filter(shift.runs, { route_number: routeNumber }).length;

						return (
							<div key={run.route_uuid}>
								{run.route_number}: (с {moment(run.date_from).format(formats.TIME)}) {run.route_name}
								&nbsp; (Рейсов: {runs})
							</div>
						);
					})}
				</Block>

				{window.RNIS_SETTINGS.different_ts_select ? (
					<Block title="ТС">
						{!this.get('is_only_run_edit') ? (
							this.selectAsync(
								`order.shifts.${index}.vehicle_uuid`,
								async (input, callback) => {
									return await this.loadVehicles(input, callback, index);
								},
								{
									disabled: checkLimitationInstallation(_.get(this.state.item, 'date'))
								}
							)
						) : (
							_.map(this.props.vehicles, 'state_number').join(', ')
						)}
					</Block>
				) : null}
				<Block title="Водитель">
					{!this.get('is_only_run_edit') ? (
						this.selectAsync(
							`order.shifts.${index}.driver_uuid`,
							async (input, callback) => {
								return await this.loadDrivers(index, input, callback);
							},
							{
								disabled: checkLimitationInstallation(_.get(this.state.item, 'date'))
							}
						)
					) : (
						this.props.drivers.join(', ')
					)}
				</Block>
				<Block title="Кондуктор">
					{!this.get('is_only_run_edit') ? (
						this.selectAsync(
							`order.shifts.${index}.check_taker_uuid`,
							async (input, callback) => {
								return await this.loadCheckTakers(index, input, callback);
							},
							{
								disabled: checkLimitationInstallation(_.get(this.state.item, 'date'))
							}
						)
					) : (
						this.props.checkTakers.join(', ')
					)}
				</Block>
			</AccordionItem>
		);
	}

	async loadDrivers(index, input, callback) {
		if (!input) {
			return this.loadUser(_.get(this.props.data, `shifts.${index}.driver_uuid`), callback);
		}

		const result = await this.props.getUsers({
			filters: {
				withComponent: component_mapper(this.props.params.component),
				withPositionTypes: [ 'driver' ]
			},
			search: input,
			pagination: {
				page: 1,
				limit: 20
			},
			response_data: [ 'items/uuid', 'items/info/surname', 'items/info/name', 'items/info/second_name' ]
		});

		if (result.isOk) {
			callback(null, {
				options: _.sortBy(
					result.payload.items.map((i) => ({
						label: new User(i).getFullName(),
						value: i.uuid
					})),
					'label'
				),
				complete: false
			});
		} else {
			result.showErrors();
		}
	}

	async loadCheckTakers(index, input, callback) {
		if (!input) {
			return this.loadUser(_.get(this.props.data, `shifts.${index}.check_taker_uuid`), callback);
		}

		const result = await this.props.getUsers({
			withComponent: component_mapper(this.props.params.component),
			filters: {
				withPositionTypes: [ 'worker' ]
			},
			search: input,
			pagination: {
				page: 1,
				limit: 20
			},
			response_data: [ 'items/uuid', 'items/info/surname', 'items/info/name', 'items/info/second_name' ]
		});

		if (result.isOk) {
			callback(null, {
				options: _.sortBy(
					result.payload.items.map((i) => ({
						label: new User(i).getFullName(),
						value: i.uuid
					})),
					'label'
				),
				complete: false
			});
		} else {
			result.showErrors();
		}
	}

	async loadUser(uuid, callback) {
		if (!uuid) {
			callback(null, {
				options: [],
				complete: false
			});
			return;
		}

		const result = await this.props.getUser(uuid);
		if (result.isOk) {
			const user = result.payload;
			callback(null, {
				options: [
					{
						label: new User(user).getFullName(),
						value: user.uuid
					}
				],
				complete: false
			});
		} else {
			result.showErrors();
		}
	}

	async setValue(field, value) {
		super.setValue(field, value);

		const matches = /^order\.shifts\.([0-9]+)\.driver_uuid$/.exec(field);
		if (matches && value) {
			this.checkDriver(matches[1]);
		}
	}

	async checkVehicle() {
		const runs = _.flatten(_.map(this.get('shifts'), 'runs'));
		const response = await this.props.resourceCheck({
			order_uuid: this.get('uuid'),
			vehicle_uuid: this.get('vehicle_uuid'),
			date_from: _.get(_.first(_.sortBy(runs, 'date_from')), 'date_from'),
			date_to: _.get(_.last(_.sortBy(runs, 'date_to')), 'date_to')
		});

		if (!response.isOk) {
			response.showErrors();
			this.onChangeInput('order.vehicle_uuid', { target: { value: null } });
			this.onChangeInput('vehicle', { target: { value: {} } });
		}
	}

	async checkDriver(index) {
		const runs = this.get(`shifts.${index}.runs`);
		const response = await this.props.resourceCheck({
			order_uuid: this.get('uuid'),
			driver_uuid: this.get(`shifts.${index}.driver_uuid`),
			date_from: _.get(_.first(_.sortBy(runs, 'date_from')), 'date_from'),
			date_to: _.get(_.last(_.sortBy(runs, 'date_to')), 'date_to')
		});

		if (!response.isOk) {
			response.showErrors();
			this.setValue(`order.shifts.${index}.driver_uuid`, null);
		}
	}
}
