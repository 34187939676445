import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import IconButton from '../icon-button';
import SelectPopup from '../SelectPopup';
import './style.less';

@propTypes({
    showPopup: PropTypes.bool,
    onChange: PropTypes.func,
    items: PropTypes.array,
    fieldKey: PropTypes.string,
    disabled: PropTypes.bool
})

@defaultProps({
    showPopup: false,
    onChange: () => {},
    items: [],
    fieldKey: 'name',
    disabled: false
})

export default class SelectButtonPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showPopup: false
        };
    }

    componentWillMount() {
        this.setState({ showPopup: this.props.showPopup});
    }

    /*componentWillReceiveProps(nextProps) {
        if (this.state.showPopup !== nextProps.showPopup) {
            this.setState({ showPopup: nextProps.showPopup});
        }
    }*/

    onChangeShowPopup = () => {
        this.setState({ showPopup: !this.state.showPopup});
    };

    getItems() {
        return this.refs.selectPopup ? this.refs.selectPopup.getItems() : [];
    }

    render() {
        return (
            <div>
                <IconButton
                    icon="add-remove"
                    ref="button"
                    onClick={this.onChangeShowPopup}
                    disabled={this.props.disabled}
                />
                {this.state.showPopup && !this.props.disabled ?
                    <SelectPopup ref="selectPopup"
                                 items={this.props.items}
                                 onChange={this.props.onChange}
                                 fieldKey={this.props.fieldKey}
                                 className="select-button-popup top-link"/>
                    : null}
            </div>
        );
    }
}