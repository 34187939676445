import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import {List} from 'immutable';
import debounce from 'throttle-debounce/debounce';
import _ from 'lodash';
import classNames from 'classnames';
import * as alerts from "helpers/alerts";
import download from 'downloadjs'
import moment from "moment";
import formats from "dictionaries/formats";

import {api, mapDatatablesRequestToMeta} from "helpers/api";
import {makeResponse} from "helpers/response";
import {getTableForPrint, printTable} from "helpers/print";

import IconButton from '../ui/icon-button';
import SearchField from '../ui/search-field';
import FreeDriversList from "components/ui/free-drivers-list";
import ShowDeleted from "components/ui/show-deleted";
import ContextTooltip from "components/ui/context-tooltip";
import TableColumnSelectFields from "components/ui/Table/Column/SelectFields/TableColumnSelectFields";
import TableComponent from "components/ui/Table/Component/TableComponent";
import {isMosreg, isLocalhost, isTest} from "helpers/functions";

export default class BaseTableComponent extends Component {

    useCache = true;

    loadCallbackDebounce = ::this.loadCallback;
    reloadDebounce = debounce(1500, ::this.reloadWithDebounce);
    select = 'single';
    checkbox = false;

    defaultOrder = [[0, 'asc']];

    exportRowsCount = 1000;

    onRowCreate = null;

    constructor(props, context) {
        super(props, context);

        this.state = {
            lastCacheCheck: new Date(),
            success: false,
            showDeleted: false,
            query: '',
            queryBounced: '',
            selectedRowsCount: 0,
            selectedRows: [],
            showTableSearchFooter: false,
            showTableFilters: false,
            showSelectFieldsPopup: false,
            columns: List([]),
            visibleCheckbox: false,
        };
    }

    toggleVisibleCheckbox(visible) {
        this.setState({
            visibleCheckbox: typeof visible !== 'undefined' ? visible : !this.state.visibleCheckbox,
        })
    }

    renderTable() {
        if (this.state.columns.size === 0) {
            return;
        }

        const className = classNames({
            'show-table-filters': this.state.showTableFilters,
        });

        return (
            <div className={className}>
                <TableComponent
                    ref="table"
                    select={this.select}
                    checkbox={this.checkbox}
                    visibleCheckbox={this.state.visibleCheckbox}
                    columns={this.state.columns}
                    loadCallback={::this.loadCallbackMiddleware}
                    checkCache={::this.checkCache}
                    useCache={this.useCache}
                    onColsReordered={::this.onColsReordered}
                    onCheck={::this.selectedRowsRecalc}
                    query={this.state.query}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    onSettingsLoad={::this.onSettingsLoad}
                    onDblClick={::this.onDblClick}
                    showAudit={::this.onAuditClick}
                    onClick={::this.onClick}
                    onInitialized={::this.onInitialized}
                    defaultOrder={this.defaultOrder}
                    onRowCreate={this.onRowCreate}
                    onChangeSelectFieldsCheckbox={::this.onChangeSelectFieldsCheckbox}
                    onChangePage={::this.onChangePage}
                />
            </div>
        );
    }

    lastCheckCacheMeta = {};

    checkCache(request) {
        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);

        const result = (JSON.stringify(meta.filters) === JSON.stringify(this.lastCheckCacheMeta.filters)) &&
            (JSON.stringify(meta.column_search) === JSON.stringify(this.lastCheckCacheMeta.column_search)) &&
            (meta.search === this.lastCheckCacheMeta.search);

        setTimeout(() => {
            this.setState({
                lastCacheCheck: new Date()
            })
        }, 1000)
        this.lastCheckCacheMeta = _.cloneDeep(meta);
        return result;
    }

    reload() {
        this.refs.table && this.refs.table.getWrappedInstance().reload();
    }

    onInitialized(table) {

    }

    onClick(data) {

    }

    onDblClick() {
    }

    onAuditClick(rowData) {
        if (!this.modelClass || !this.modelSource) {
            this.showAudit(rowData);
            return;
        }

        alerts.askSelect('Выберите действие', [
            {
                value: 'audit',
                label: 'Перейти в аудит',
            },
            {
                value: 'restore',
                label: 'Восстановить запись',
            },
        ], (value) => {
            switch (value) {
                case 'audit':
                    this.showAudit(rowData);
                    break;
                case 'restore':
                    this.restoreRow(rowData);
                    break;
            }
        }, 'Выполнить');
    }

    async restoreRow(rowData) {
        const response = await makeResponse(() => {
            return api.system.restoreEntity({
                class: this.modelClass,
                uuid: rowData.uuid,
            }, this.modelSource);
        });

        if (response.isOk) {
            alerts.success('Выбранный объект восстановлен!');
            this.reload();
        } else {
            response.showErrors();
        }
    }

    showAudit(rowData) {
        this.props.router.push(`/system/audit/${rowData.uuid}`)
    }

    async loadCallbackMiddleware(request, drawCallback, settings) {
        this.loadCallbackDebounce(request, drawCallback, settings);
    }

    async reloadWithDebounce(columns) {
        await this.setState({columns, filterChange: true });
        this.refs.table && this.refs.table.getWrappedInstance().reload();
    }

    async onColumnFilterChange(index, filter) {
        let columns = this.state.columns.update(index, (column) => {
            if (_.isArray(filter) && filter.length == 0) {
                delete column['filterValue'];
            } else {
                column.filterValue = filter;
            }
            return column;
        });
        if (columns._tail.array[index]?.isDebounce) {
            this.reloadDebounce(columns)
        } else {
            await this.setState({columns, filterChange: true });
            this.refs.table && this.refs.table.getWrappedInstance().reload();
        }
    }

    getDataForMultiFilter(filters, callback) {

        return {
            simple: {
                filterName: filters.simple,
                callback: null
            },
            async: {
                filterName: filters.async,
                callback: callback
            },
            parentCallback: this.onChangeMultiFilter.bind(this)
        }
    }

    onChangeMultiFilter(tab, columnIndex) {

        let newColumn = _.find(this.state.columns._tail.array, (col, index) => index === columnIndex);

        newColumn.filterCurrentName = tab

        let copyColumns = _.cloneDeep (this.state.columns)
        copyColumns._tail.array[columnIndex] = newColumn

        this.setState({
            columns: copyColumns
        })
    }

    onChangePage() {
        this.setPrevPageSelectedRows();
    }

    // NOTE: при возвращении на пред. страницу вернуть активные чекбоксы
    setPrevPageSelectedRows() {
        const tableWrapped = this.refs.table.getWrappedInstance();
        const selected = [];
        tableWrapped.table.rows().data().toArray().map((row, index) => {
            if (this.state.selectedRows.find((selectedRow) => selectedRow.uuid === row.uuid)) {
                selected.push(index);
            }
        })
        
        selected.map((index) => {
            tableWrapped.table.row(index).select()
        })
        
    }

    deleteSelected(uuid) {
        const newSelected = this.state.selectedRows.filter((sr) => sr.uuid !== uuid);
        this.setState({
            selectedRowsCount: newSelected.length,
            selectedRows: newSelected,
        });
    }

    selectedRowsRecalc() {
        if (!this.refs.table) return;
        
        const tableWrapped = this.refs.table.getWrappedInstance();
        let newSelected = [];
        const rowsData = tableWrapped.table.rows().data().toArray();

        /* NOTE: сохранение выбранных строк, если они не отображаются в этот момент (другая страница или фильтр)
            отключать, если в этой таблице можно выбрать только одну строку.
        */

        if (this.select !== 'single') {
            this.state.selectedRows.map((selectedRow) => {
                if(!rowsData.find((row) => row.uuid === selectedRow.uuid)) {
                    newSelected.push(selectedRow);
                }
            });
        }

        if (tableWrapped.getSelected) {
            const selectedData = tableWrapped.getSelected().data().toArray();
            newSelected = newSelected.concat(selectedData);

            this.setState({
                selectedRowsCount: newSelected.length, // NOTE: вот хрень его знает где это нужно, но удалять страшно
                selectedRows: newSelected,
            });
        }
    }

    searchDebounce = debounce(1000, ::this.search);

    onSearchChange(query) {
        //this.setState({queryBounced: query});
        this.setState({ filterChange: true });
        this.searchDebounce(query);
    }

    search(query) {
        this.setState({query});
    }

    searchFieldShow() {
        this.setState({
            showTableSearchFooter: true,
        });
    }

    searchFieldHide() {
        this.setState({
            showTableSearchFooter: false,
            queryBounced: '',
        });
        this.searchDebounce('');
    }

    searchOpen() {
        this.refs.search_btn.open();
    }

    renderHeaderContents() {
        return [
            (this.props.location.pathname.includes("road/vehicles_control") && window.RNIS_SETTINGS.SHOWFREEDRIVERSLIST ?
                <FreeDriversList
                    onChange={::this.toggleShowDeleted}
                    className={this.state.showTableSearchFooter ? 'left-shift' : ''}
                />
                : null),
            <ContextTooltip key="base-table-list.show-deleted" code="base-table-list.show-deleted" space={20}
                            default="Отображать/скрыть удаленные записи">
                <ShowDeleted
                    active={this.state.showDeleted}
                    onChange={::this.toggleShowDeleted}
                    className={this.state.showTableSearchFooter ? 'left-shift' : ''}
                />
            </ContextTooltip>,
            <ContextTooltip key="base-table-list.search" code="base-table-list.search" default="Поиск (ctrl+shift+s)">
                <SearchField ref="search_btn" onChange={::this.onSearchChange} value={this.state.queryBounced}
                             onShow={::this.searchFieldShow}
                             onHide={::this.searchFieldHide}/>
            </ContextTooltip>,
            this.getFilterButton(),
            (((this.state.columns || new List()).size > 5) ? (
                <ContextTooltip key="base-table-list.columns-select" code="base-table-list.columns-select"
                                default="Отобразить/скрыть столбцы (ctrl+shift+h)">
                    <TableColumnSelectFields
                        ref="columnSelect"
                        columns={this.state.columns}
                        showPopup={this.state.showSelectFieldsPopup}
                        onChange={::this.onChangeSelectFieldsCheckbox}
                        onToggle={::this.onToggleSelectFieldsPopup}/>
                </ContextTooltip>
            ) : null),
            this.getCreateButton(),
            <ContextTooltip key="base-table-list.print" code="base-table-list.print" default="Печать (ctrl+shift+p)">
                <IconButton icon="print" onClick={::this.print}/>
            </ContextTooltip>,
            this.exportToXlsButton()
        ];
    }

    exportToXlsButton() {
        return (
            <ContextTooltip key="base-table-list.export" code="base-table-list.export"
                            default="Экспорт в Excel (ctrl+shift+e)">
                <IconButton icon="export" onClick={::this.exportToXls}/>
            </ContextTooltip>
        );
    }

    print() {
        if (!this.refs.table) return;
        const table = this.refs.table.getWrappedInstance().table;
        this.isInExport = true;
        table.page.len(300);
        table.ajax.reload(() => {
            this.isInExport = false;
            const headers = table.columns().header();
            const rows = table.rows().nodes();
            printTable(headers, rows, 'landscape', 'A3');

            setTimeout(() => {
                table.page.len(25);
                table.ajax.reload();
            }, 250);
        });
    }

    exportToXls() {
        // Новый метод экспорта
        // if (this.props.location.pathname.includes("bnso")) {
        // return window.location.href = window.location.origin.includes('localhost') ? 'https://rnis-test.t1-group.ru/export/devices' : window.location.origin + `/export/devices?withComponent=${this.props.params.component}`;
        // }

        if (!this.refs.table) return;
        const table = this.refs.table.getWrappedInstance().table;

        const total = table.page.info().recordsTotal;

        let pages = Math.ceil(total / this.exportRowsCount);
        //let pages = 1;
        if (pages <= 1) {
            this.exportToXlsReal();
        } else {
            this.exportRowsCount = 500;
            pages = Math.ceil(total / this.exportRowsCount)
            const cb = alerts.progress('Экспорт может занять некоторое время', () => {
                this.exportToXlsReal(pages, cb)
            }, 'Экспорт');
        }
    }

    async exportToXlsReal(pages = 1, cb) {
        cb && cb(0);
        const table = this.refs.table.getWrappedInstance().table;
        table.page.len(this.exportRowsCount);
        const headers = table.columns().header();
        let rows = [];
        for (let i = 0; i < pages; i++) {
            const loadingPercent = await new Promise((resolve) => {
                table.page(_.toInteger(i));
                table.ajax.reload(() => {
                    rows = [...rows, ...table.rows().nodes().toArray()];
                    resolve(Math.ceil(100 / pages * (i + 1)));
                }, false);
            });
            cb && cb(loadingPercent);
        }
        const html = getTableForPrint(headers, rows);
        this.convertToXls(html);
        table.page.len(25);
        table.ajax.reload();
        this.exportRowsCount = 25;
    }

    async convertToXls(content) {
        const alert = alerts.loading('Конвертация в .xls ...');
        alert('show');
        const response = await makeResponse(() => {
            return api.converter.convertHtmlToXls(content);
        });

        if (response.isOk) {
            const content = response.payload.content;
            download(`data:application/excel;base64,${content}`, `Экспорт ${moment().format(formats.DATETIME)}.xls`);
        } else {
            response.showErrors();
        }
        alert('hide');
    }

    getHotKeyHandlers() {
        return {
            search: ::this.searchOpen,
            filters: ::this.toggleTableFilters,
            columnSelect: ::this.onToggleSelectFieldsPopup,
            add: ::this.showEditor,
            print: ::this.print,
            export: ::this.exportToXls,
        };
    }

    async toggleTableFilters() {
        if (!this.state.showTableFilters && (isMosreg() || isLocalhost() || isTest())) {
            await this.clearFilters();
        }
        await this.setState({
            showTableFilters: !this.state.showTableFilters,
            filterChange: true
        });

        this.reload();
    }

    async clearFilters() {
        this.lastCheckCacheMeta = {}
        Object.keys(localStorage).forEach(function(key, index) {
            if (key.includes('table-')) {
                localStorage.removeItem(key)
            }
        });
        let columns = this.state.columns.map(column => {
            delete column['filterValue'];
            return column;
        });
        await this.setState({
            columns,
        });
        $(".dataTables_scrollHeadInner").find("input[type=text]").val('');
    }


    showEditor() {
    }

    onChangeSelectFieldsCheckbox(position, checked) {
        const column = this.state.columns.get(position);
        if (!column) {
            return;
        }
        column.hidden = !checked;

        this.setState({
            columns: this.state.columns.set(position, column)
        });
    }

    onToggleSelectFieldsPopup() {
        this.refs.columnSelect && this.refs.columnSelect.onToggle();
        //this.setState({showSelectFieldsPopup: !this.state.showSelectFieldsPopup});
    }

    onColsReordered(e, settings, {from, to}) {
        from = from - 1;
        to = to - 1;
        const columns = this.state.columns.map((column) => {
            let i = column.index;
            if (i > from && i < to) {
                i = i - 1;
            } else if (i > to && i < from) {
                i = i + 1;
            } else if (i == to) {
                i = (from < to) ? i - 1 : i + 1;
            } else if (i == from) {
                i = to;
            }
            column.index = i;
            return column;
        });
        this.setState({columns});
    }

    onClickSelectFieldsButton() {
        this.setState({showSelectFieldsPopup: !this.state.showSelectFieldsPopup});
    }

    prepareColumns(columns) {
        return columns.map((column, index) => {
            column.index = index;
            return column;
        });
    }

    getFilterButton() {
        return (
            <ContextTooltip key="base-table-list.filter" code="base-table-list.filter" default="Фильтр (ctrl+shift+f)">
                <IconButton icon="filter" tooltip="Фильтр" active={this.state.showTableFilters}
                            onClick={::this.toggleTableFilters}/>
            </ContextTooltip>
        );
    }

    getCreateButton() {
        return (
            <ContextTooltip key="base-table-list.create" code="base-table-list.create"
                            default="Добавить (ctrl+shift+a)">
                <IconButton
                    icon="plus"
                    tooltip="Добавить"
                    onClick={::this.showEditor}
                />
            </ContextTooltip>
        );
    }

    alertToSelect() {
        alerts.alert('Выберите запись');
    }

    onSettingsLoad(state) {
        this.onSettingsLoadReal(false, state);
    }

    onSettingsLoadReal(isTree, state) {
        if (!state) {
            return;
        }

        let columns = this.state.columns;
        let needReload = false;

        _.each(state.columns || [], (column, index) => {
            let tableColumn = columns.get(isTree ? (index - 1) : index);
            if (!tableColumn) {
                return;
            }

            if (!column.visible) {
                tableColumn.hidden = true;
                columns.set(isTree ? (index - 1) : index, tableColumn);

                needReload = true;
            } else if (column.visible && tableColumn.hidden) {
                tableColumn.hidden = false;
                columns.set(isTree ? (index - 1) : index, tableColumn);

                needReload = true;
            }
        });

        this.setState({columns});

        /*const search = _.get(state, 'search.search');
        if (search) {
            this.setState({queryBounced: search, query: search});
            this.refs.search_btn && this.refs.search_btn.open();
        }*/
    }

    async toggleShowDeleted() {
        await this.setState({
            showDeleted: !this.state.showDeleted,
        });
        this.reload();
    }

    async loadDictionaries(dictionaries, component = null) {
        _.each(dictionaries, async (dictionary) => {
            const documents = await this.getDictionary(dictionary, component);

            let state = this.state;
            state[dictionary] = documents;
            this.setState(state);
        });
    }

    async getDictionary(dictionary, component = null) {
        const response = await this.props.getDictionaryList(dictionary, {
            order: {
                column: 'name',
                direction: 'asc',
            },
            filters: {
                withComponent: component,
            },
        });
        if (response.isOk) {
            return _.keyBy(response.payload.documents, 'uuid');
        } else {
            response.showErrors();
        }
    }

    async loadListNumbers(search, filter, response_data, getData) {

        let meta = {
            pagination: {page: 1, limit: 25},
            filters: {
                withComponent: this.state.component,
            },
            response_data: [response_data]
        }
        _.set(meta, `filters.${filter}`, search);
        return await getData(meta);
    }
}
