import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';
import {cached} from "helpers/cached";

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getUnits = (meta = {}, isCached = true) => async (dispatch) => {
    if (!meta.pagination) {
        meta.pagination = {
            page: 1,
            limit: 10000,
        };
    }
    
    const cacheKey = 'units:' + JSON.stringify(meta);
    const callback = async () => {
        try {
            const response = await api.organizational_units.getUnits(meta);

            return success(response);
        }
        catch (e) {
            return error(e);
        }
    };
    return isCached ? cached(cacheKey, callback, 60, true) : await callback();
};

export const getUnit = (unitUuid, search = {}) => async (dispatch) => {
    try {
        const response = await api.organizational_units.getUnit(unitUuid, search);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createUnit = (data) => async (dispatch) => {
    try {
        const response = await api.organizational_units.createUnit(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateUnit = (data) => async (dispatch) => {
    try {
        const response = await api.organizational_units.updateUnit(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteUnit = (data) => async (dispatch) => {
    try {
        const response = await api.organizational_units.deleteUnit(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
