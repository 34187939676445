import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { propTypes, defaultProps } from 'react-props-decorators';
import { connect } from 'react-redux';

import _ from 'lodash';
import Editor from './editor';

import Column from 'components/ui/column';
import BaseTableWithEditorComponent from 'components/base/base_table_with_editor';
import moment from 'moment';
import formats from 'dictionaries/formats';
import { EntityList } from 'helpers/entity';
import { getBnsoCheckLink, getBnsoList, exportBNSOExcel } from 'store/reducers/vehicles/bnso';
import { getPanics, getTelematicsWithDevices } from 'store/reducers/maps';
import { getObjectsHistoryData, getTelematicsWithTemperatureGrpc } from 'helpers/api/telematics';
import './bnso_check.less';
import IconButton from 'components/ui/icon-button';
import systems from 'dictionaries/systems';
import ReactDOMServer from 'react-dom/server';
import { State } from 'components/ui/state';
import { getVehicleList, getVehicle } from 'store/reducers/vehicles/vehicles';
import { getUnits } from 'store/reducers/organizational_units/units';
import ContextTooltip from 'components/ui/context-tooltip';
import LoaderComponent from '../../../ui/loader';
import * as alerts from '../../../../helpers/alerts';
import { getPointsFromGrpc } from './utils';

@connect((state) => ({}), {
	getBnsoList,
	getTelematicsWithDevices,
	getPanics,
	getVehicleList,
	getVehicle,
	getUnits,
	getBnsoCheckLink,
	exportBNSOExcel
})
export default class OperatorBNSOCheckComponent extends BaseTableWithEditorComponent {
	title = `${systems.operator} → Диагностика БНСО`;
	baseUrl = '/operator/bnso_check';

	constructor(props) {
		super(props);

		Object.assign(this.state, {
			panics: [],
			vehicles: {},
			units: [],
			isBnsoTable: true,
			exportExcelLoading: false,
			exportFilters: null
		});
	}

	getEditor() {
		return (
			<Editor
				key="editor"
				onClose={::this.closeEditor}
				onSubmit={::this.submitEditor}
				mode={this.state.editorUuid ? 'edit' : 'add'}
				uuid={this.state.editorUuid}
				related={this.state.related}
				{...this.props}
			/>
		);
	}

	getColumns() {
		console.log(
			'window.RNIS_SETTINGS.show_column_ts_with_filter_bnso',
			window.RNIS_SETTINGS.show_column_ts_with_filter_bnso
		);
		return this.prepareColumns([
			new Column('Код БНСО').fromField('bnso_code'),

			...(window.RNIS_SETTINGS.show_column_status_bnso
				? [
						new Column('Статус').denyColumnFilter().denyOrder().withAlign('center').withDrawer((item) => {
							let status = false;
							const time = _.get(this.state.related.getItem(item.bnso_number), 'point.7');
							if (time) {
								status = !moment.unix(time).isBefore(moment().subtract(30, 'minutes'));
							}
							return ReactDOMServer.renderToStaticMarkup(<State positive={status} />);
						})
					]
				: []),

			new Column('Подсистема')
				.fromField('component')
				.withDrawer((item) => systems[item.component])
				.withFilter('withComponent', async () => {
					return _.map(systems, (name, uuid) => ({
						uuid,
						name
					}));
				}),

			...(!window.RNIS_SETTINGS.show_column_ts_with_filter_bnso
				? [
						new Column('ТС')
							.denyColumnFilter()
							.denyOrder()
							.withDrawer((item) => _.get(this.state.vehicles, `${item.bnso_number}.state_number`))
					]
				: []),

			...(!window.RNIS_SETTINGS.show_column_unit_with_filter_bnso
				? [
						new Column('Предприятие')
							.denyColumnFilter()
							.denyOrder()
							.withDrawer((item) => this.state.units[item.unit_uuid])
					]
				: []),

			...(window.RNIS_SETTINGS.show_column_ts_with_filter_bnso
				? [
						new Column('ТС')
							.fromField('item.bnso_number.state_number')
							.denyColumnFilter()
							.withDrawer((item) => _.get(this.state.vehicles, `${item.bnso_number}.state_number`))
							.withAsyncFilter('withVehicle', async (search) => {
								const response = await this.props.getVehicleList({
									search,
									filters: {
										withComponent: this.props.params.component
									},
									order: {
										column: 'state_number',
										direction: 'asc'
									},
									pagination: {
										page: 1,
										limit: 50
									}
								});
								if (response.isOk) {
									return _.map(response.payload.items, (vehicle) => ({
										uuid: vehicle.uuid,
										name: vehicle.state_number
									}));
								}
								return [];
							})
					]
				: []),

			...(window.RNIS_SETTINGS.show_column_unit_with_filter_bnso
				? [
						new Column('Предприятие')
							.fromField('item.bnso_number.unit')
							.withDrawer((item) => this.state.units[item.unit_uuid])
							.withAsyncFilter('withUnit', async (search) => {
								const response = await this.props.getUnits({
									search,
									filters: {
										withComponent: this.props.params.component
									},
									order: {
										column: 'name',
										direction: 'asc'
									},
									pagination: {
										page: 1,
										limit: 30
									},
									response_data: [ 'items/uuid', 'items/name' ]
								});
								if (response.isOk) {
									return response.payload.items;
								}
								return [];
							})
					]
				: []),

			new Column('Широта')
				.denyColumnFilter()
				.denyOrder()
				.withAlign('center')
				.withDrawer((item) => {
					return _.get(this.state.related.getItem(item.bnso_number), 'point.0', '-');
				}),

			new Column('Долгота')
				.denyColumnFilter()
				.denyOrder()
				.withAlign('center')
				.withDrawer((item) => {
					return _.get(this.state.related.getItem(item.bnso_number), 'point.1', '-');
				}),

			new Column('Скорость, км/ч')
				.denyColumnFilter()
				.denyOrder()
				.withAlign('center')
				.withDrawer((item) => _.get(this.state.related.getItem(item.bnso_number), 'point.4', '-')),

			new Column('Время фиксации положения').denyColumnFilter().denyOrder().withDrawer((item) => {
				const time = _.get(this.state.related.getItem(item.bnso_number), 'point.7');
				if (time) {
					return moment.unix(time).format(formats.DATETIME);
				}
				return '-';
			}),

			new Column('Тревожная кнопка').denyColumnFilter().denyOrder().withAlign('center').withDrawer((item) => {
				return _.find(this.state.panics, { device_id: item.bnso_number })
					? '<span class="status status-red"></span>'
					: '';
			})
		]);
	}

	async handlerLoadData(meta) {
		this.setState({
			exportFilters: meta
		});
		//TODO: В случае с загрузкой списка, отфильтрованного по ТС, сначала получаем
		// инфу о ТС по uuid, берем оттуда bnso_uuid и запрашиваем bnso.list
		// Лучше, конечно, придумать более надежную проверку, чем item.name === 'ТС'

		const columns = this.state.columns;
		let columnVehicle = {};
		columns.toArray().map((item) => {
			if (item.name === 'ТС') {
				columnVehicle = item;
			}
		});

		if (columnVehicle.filterValue && columnVehicle.filterValue.length) {
			const response = await this.props.getVehicleList({
				filters: {
					withUuid: meta.filters.withVehicle
				}
			});

			if (response.isOk) {
				let bnso_uuids = [];
				response.payload.items.map((el) => {
					el.bnso.map((item) => {
						bnso_uuids.push(item.bnso_uuid);
					});
				});
				delete meta.filters.withVehicle;
				meta.filters.withUuid = bnso_uuids;

				const bnso_list = await this.props.getBnsoList(meta);
				if (!this.state.units.length && bnso_list.payload.items && bnso_list.payload.items.length) {
					const uuids = new Set(bnso_list.payload.items.map((item) => item.unit_uuid));
					this.loadUnitsToMerge(uuids);
				}
				return bnso_list;
			} else {
				response.showErrors();
			}
		} else {
			const bnso_list = await this.props.getBnsoList(meta);
			if (!this.state.units.length && bnso_list.payload.items && bnso_list.payload.items.length) {
				const uuids = new Set(bnso_list.payload.items.map((item) => item.unit_uuid));
				this.loadUnitsToMerge(uuids);
			}
			return bnso_list;
		}
	}

	async loadData(meta) {
		if (window.RNIS_SETTINGS.show_column_unit_with_filter_bnso) {
			return this.handlerLoadData(meta);
		} else {
			const bnso_list = await this.props.getBnsoList(meta);
			if (!this.state.units.length && bnso_list.payload.items && bnso_list.payload.items.length) {
				const uuids = new Set(bnso_list.payload.items.map((item) => item.unit_uuid));
				this.loadUnitsToMerge(uuids);
			}
			return bnso_list;
		}
	}

	async loadUnitsToMerge(uuids) {
		const units = await this.loadUnits(Array.from(uuids));
		this.setState({
			units
		});
	}

	async loadRelatedEntities(json, drawCallback) {
		const result = json.data;
		const devices = _.uniq(_.filter(_.map(result, 'bnso_number')));
		// проверка случая когда уходят пустые devices, убрать setTimeout
		setTimeout(() => {
			this.loadTelematics(devices, json, drawCallback);
		}, 1500);
		this.loadPanics(devices, json, drawCallback);
		this.loadVehicles(devices, json, drawCallback);
	}

	async loadTelematics(devices, json, drawCallback) {
		const response = await getTelematicsWithTemperatureGrpc(devices);

		if (response.objectsList) {
			let richResponse = this.richTelematicsData(response);

			this.state.related.add(richResponse, 'device_id');

			drawCallback(json);
		}
	}

	richTelematicsData(response) {
		if (!response.objectsList) return;
		const result = { payload: {items: []} };
		result.payload.items = response.objectsList.map((data) => ({
			device_id: data.devicecode,
			point: getPointsFromGrpc(data.dataList[0])
		}));
		return result;
	}

	async loadPanics(devices, json, drawCallback) {
		const response = await this.props.getPanics({
			filters: {
				lastDay: true,
				withDevices: devices
			}
		});
		if (response.isOk) {
			await this.setState({
				panics: response.payload.items
			});

			drawCallback(json);
		}
	}

	async loadVehicles(devices, json, drawCallback) {
		const response = await this.props.getVehicleList({
			filters: {
				withCurrentBnsoNumber: devices
			}
		});

		if (response.isOk) {
			const units = await this.loadUnits(_.filter(_.map(response.payload.items, 'unit_uuid')));

			await this.setState({
				vehicles: _.mapValues(_.keyBy(response.payload.items, 'current_bnso.bnso_number'), (vehicle) => {
					vehicle.unit = _.get(units, vehicle.unit_uuid);
					return vehicle;
				})
			});
			drawCallback(json);
		}
	}

	async loadUnits(uuids) {
		const response = await this.props.getUnits({
			filters: {
				withUuid: uuids
			}
		});

		if (response.isOk) {
			return _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'name');
		} else {
			response.showErrors();
		}
	}

	renderHeaderActions() {
		const showExport = () => {
			if (window.RNIS_SETTINGS.show_operator_export) {
				return (
					<ContextTooltip key="base-table-list.export" code="base-table-list.export" default="Экспорт в Excel">
						<IconButton icon="export" onClick={::this.exportToXls} />
					</ContextTooltip>
				);
			} else {
				return null;
			}
		};
		return [
			this.state.exportExcelLoading ? (
				<div className="excelLoader">
					<LoaderComponent color="red" />
				</div>
			) : (
				showExport()
			),
			<ContextTooltip
				key="bnso-check.diagnostic"
				code="bnso-check.diagnostic"
				position="left"
				default="Инструменты диагностики"
			>
				<IconButton icon="diagnostics" onClick={::this.gotoDiagnostic} />
			</ContextTooltip>
		];
	}

	async gotoDiagnostic() {
		const response = await this.props.getBnsoCheckLink();

		if (response.isOk) {
			const link = response.payload.link;
			window.location.href = link;
		} else {
			response.showErrors();
		}
	}

	async exportToXls() {
		this.setState({
			exportExcelLoading: true
		});
		const response = await this.props.exportBNSOExcel(this.state.exportFilters);
		if (response.status !== 200) {
			alerts.error('Ошибка. Не удалось сделать экспорт отчета');
			this.setState({
				exportExcelLoading: false
			});
		} else {
			this.setState({
				exportExcelLoading: false
			});
		}
	}

	exportToXlsButton() {
		return null;
	}

	getCreateButton() {
		return null;
	}
}
