import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import {LabeledCheckbox} from "components/ui/checkbox";
import IconButton from "components/ui/icon-button";
import ContextTooltip from "components/ui/context-tooltip";
import Popup from "components/ui/popup";
import FilterHeader from "components/ui/filter-header";
import debounce from 'throttle-debounce/debounce';
import classNames from 'classnames';
import GlobalLoaderComponent from "components/ui/global-loader";
import LoaderComponent from "components/ui/loader";
import PageModalComponent from "components/ui/page-modal";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import {setActiveClose, setClosesTimes} from "store/reducers/geo/railroad-crossing";
import {antPath} from 'leaflet-ant-path';
import SelectFilter from "components/ui/select-filter";

import './RailroadCrossing.less';
import MapFilterExtendedTabSubjects from "components/modules/maps/Filter/Extended/Tab/MapFilterExtendedTabSubjects";
import MapFilterExtendedTabState from "components/modules/maps/Filter/Extended/Tab/MapFilterExtendedTabState";
import MapFilterExtendedTabParameters from "components/modules/maps/Filter/Extended/Tab/MapFilterExtendedTabParameters";
import MapFilterExtendedTabWorks from "components/modules/maps/Filter/Extended/Tab/MapFilterExtendedTabWorks";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import Datepicker from "../../../ui/form/datepicker";
import moment from "moment";
import formats from "../../../../dictionaries/formats";
import MapFilterBase from "../Filter/MapFilterBase";
import {getLayers, getObjects, toggleLayer} from "../../../../store/reducers/user-map-objects/layers";
import {getRoutes} from "../../../../store/reducers/routes/routes";
import railroadclose from "../../../../store/reducers/geo/railroad-crossing";
import {rpc} from "../../../../helpers/api/session";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getHistoryByVehicle} from "store/reducers/maps";
import TelematicsDevice, {HistoryTelematicsDevice} from "../telematics-packet";
import DirectionalPolyline from "../../../ui/map/objects/directional-polyline";
import * as alerts from "../../../../helpers/alerts";
import L from "leaflet";
import Loader from "../../../ui/loader";
import leafletKnn from "leaflet-knn"

@propTypes({
    selected: PropTypes.array,
    onSelectedChange: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
})

@connect((state) => ({
    railroadclose: state.railroadclose.get('activeClose'),
    closesTimes: state.railroadclose.get('closesTimes'),
}), {setActiveClose, setClosesTimes, getVehicleList, getHistoryByVehicle})

export default class RailroadCrossing extends MapFilterBase {

    constructor(props) {
        super(props);
    }

    icon = 'railroad-crossing';
    contextKey = 'map.filters.railroadcrossing';
    contextDefault = 'Железнодорожные переезды';

    state = {
        opened: false,
        activeTab: 0,
        isSubmiting: false,
        links: [
            {
                id: 1,
                name: "Все проезды",
                className: "top-menu__link top-menu__link_icon_mark-read"
            },
            {
                id: 2,
                name: "Без нарушений",
                className: "top-menu__link top-menu__link_icon_confirm"
            },
            {
                id: 3,
                name: "С нарушениями",
                className: "top-menu__link top-menu__link_icon_violations"
            },
            {
                id: 4,
                name: "Время перекрытия",
                className: "top-menu__link top-menu__link_icon_calendar"
            }
        ],
        events: [],
        vehicles: [],
        eventsFiltered: [],
        activeLink: 1,
        violations: [],
        activeClose: this.props.railroadclose,
        closes: [],
        from: moment().subtract(1, 'day').startOf('day'),
        to: moment().endOf('day'),
        history: [],
        loading: false,
        activeEvent: null
    };

    pathLayer = null;

    pathStyle = {
        color: '#FF0000',
        opacity: 0.6,
    };

    reload() {
    }

    async componentWillUpdate(props, state) {
        if (props.railroadclose.uuid !== state.activeClose.uuid) {
            await this.setState({
                activeClose: props.railroadclose,
                activeLink: 1
            });
            this.getRailRoadCloses();
            this.getRailRoadEvents();


            if (!props.closesTimes.length) {
                this.getRailRoadCloses();
            }
            state.activeClose && this.reload();
        }
    }

    componentDidMount() {
        if (!this.props.refs.map) return;
        const map = this.props.refs.map.refs.map.getWrappedInstance().map;
        this.pathLayer = new L.featureGroup().addTo(map);
        this.layerGroupMarkers = L.layerGroup().addTo(map);
        this.setState({violations: this.violations});
        this.getRailRoadEvents();
    }

    getButton() {
        return (
            <IconButton
                icon={this.icon}
                onClick={::this.toggleBlock}
            />
        );
    }

    handleClick = id => {
        this.setState({activeLink: id});
        let a = [...this.state.events];
        switch (id) {
            case 1:
                this.setState({eventsFiltered: a});
                break;
            case 2:
                this.setState({eventsFiltered: a.filter(v => !v.violated)});
                break;
            case 3:
                this.setState({eventsFiltered: a.filter(v => v.violated)});
                break;
        }
    };

    handleEventClick = event => {
        this.setState({activeEvent: event.event_uuid});
        this.loadHistory(event)
    };

    getHistoryCoordinates() {
        return _.map(this.state.history, (point) => ([
            point.lat,
            point.lng,
        ]));
    }

    async loadHistory(event) {
        this.setState({history: []});
        this.pathLayer.clearLayers();
        this.layerGroupMarkers.clearLayers();
        const response = await this.props.getHistoryByVehicle(event.vehicle_uuid, moment(event.event_time_start).subtract(15, 'minutes').format(formats.DATETIME_API), moment(event.event_time_start).add(15, 'minutes').format(formats.DATETIME_API), true);
        if (response.isOk) {
            const telematics = _.first(response.payload.telematics);
            if (telematics) {
                const markers = _.map(telematics.points, (point) => ([
                    point[0],
                    point[1],
                    point[2],
                    point[4],
                ]));
                const path = _.map(telematics.points, (point) => ([
                    point[0],
                    point[1],
                ]));
                if (path.length === 0) {
                    //  alerts.error('Не найден маршрут движения ТС за данный период');
                }
                this.drawPath(path, markers, event);
            }
        }
    }

    createMarker(marker, color, height, weight, cx, cy, r, zindex) {
        return new L.Marker(marker, {
            zIndexOffset: 10000,
            icon: L.divIcon({
                iconAnchor: [11, 11],
                className: 'ship-div-icon',
                html: `<svg height="${height}" width="${weight}"><circle cx="${cx}" cy="${cy}" r="${r}" stroke="#fff" stroke-width="1" fill="${color}"></circle></svg>`
            })
        });
    }

    drawPath(path, markers, event) {
        if (!path) return;
        const layer = antPath(path, {
            "delay": 675,
            "dashArray": [
                40,
                34
            ],
            "weight": 4,
            "color": "#0000FF",
            "pulseColor": "#FFFFFF",
            "paused": false,
            "reverse": false,
            "hardwareAccelerated": true
        });

        this.pathLayer.addLayer(layer);
        let latlonmarkers = {
            "type": "FeatureCollection",
            "features": [{"type": "Feature", "geometry": {"type": "LineString", "coordinates": []}}]
        };

        for (let i = 0; i < markers.length; i++) {

            let lat = markers[i][0];
            let lon = markers[i][1];

            latlonmarkers.features[0].geometry.coordinates.push([lon, lat]);

            let popupText = this.creteHTMLPopup({speed: markers[i][2], date: markers[i][3]});

            let markerLocation = new L.LatLng(lat, lon);
            let marker = new L.Marker(markerLocation, {
                icon: L.divIcon({
                    className: 'ship-div-icon',
                    html: '<svg height="20" width="20"><circle cx="7" cy="7" r="5" stroke="#f65d50" stroke-width="3" fill="#f65d50" /></svg>'
                })
            });
            marker.addTo(this.layerGroupMarkers);
            marker.bindPopup(popupText);
        }

        // ищем точки, которые попадают во время события
        let violationPoints = markers.filter(point => {
            return point[3] >= moment(event.violation_time_start).unix() && point[3] <= moment(event.violation_time_end).unix()
        })

        for (let i = 0; i < violationPoints.length; i++) {

            let lat = violationPoints[i][0];
            let lon = violationPoints[i][1];

            latlonmarkers.features[0].geometry.coordinates.push([lon, lat]);

            let popupText = this.creteHTMLPopup({speed: violationPoints[i][2], date: violationPoints[i][3]});

            let markerLocation = new L.LatLng(lat, lon);
            let marker = new L.Marker(markerLocation, {
                icon: L.divIcon({
                    className: 'ship-div-icon',
                    html: '<svg height="20" width="20"><circle cx="7" cy="7" r="5" stroke="#fff" stroke-width="1" fill="#FA0000" /></svg>'
                })
            });
            marker.addTo(this.layerGroupMarkers);
            marker.bindPopup(popupText);
        }

        let a = _.find(this.state.closes, (el) => {
            return event.violation_time_start === el.time_close
            //return moment(event.violation_time_start).unix() === moment(el.time_close).unix() && moment(event.violation_time_end).unix() === moment(el.time_open).unix()
        })


        if (!a) return;

        // точка закрытия переезда
        let timeCLosePoint = markers.filter(el => {
            return el[3] >= moment(a.time_close).unix();
        });


        let markerCloseData = new L.LatLng(_.first(timeCLosePoint)[0], _.first(timeCLosePoint)[1]);
        let popupTextClose = this.creteHTMLPopup({speed: _.first(timeCLosePoint)[2], date: _.first(timeCLosePoint)[3]});

        let markerClose = this.createMarker(markerCloseData, '#9b0', 23, 23, 12, 12, 9);

        markerClose.addTo(this.layerGroupMarkers);
        markerClose.bindPopup(popupTextClose);

        // точка открытия переезда
        let timeOpenPoint = markers.filter(el => {
            return el[3] >= moment(a.time_open).unix();
        });

        if (!timeOpenPoint.length) {
            timeOpenPoint = _.last(markers);
        }

        let markerOpenData = new L.LatLng(_.first(timeOpenPoint)[0], _.first(timeOpenPoint)[1]);
        let popupTextOpen = this.creteHTMLPopup({speed: _.first(timeOpenPoint)[2], date: _.first(timeOpenPoint)[3]});

        let markerOpen = this.createMarker(markerOpenData, '#9b0', 23, 23, 12, 12, 9);

        markerOpen.addTo(this.layerGroupMarkers);
        markerOpen.bindPopup(popupTextOpen);

    }

    creteHTMLPopup(data) {
        const {speed, date} = data;
        return `<div style="padding: 30px 10px 10px; line-height: 5px;"><h2>${speed} км/ч</h2> <p>${moment.unix(date).format(formats.TIME_FULL)}</p></div>`
    }


    toggleBlock() {
        this.pathLayer.clearLayers();
        this.layerGroupMarkers.clearLayers();
        this.setState({
            opened: !this.state.opened,
        });
    }


    render() {
        const button = this.getButton();

        return (
            <div>
                {this.contextKey ? (
                    <ContextTooltip key={this.contextKey} code={this.contextKey}
                                    default={this.contextDefault}>
                        {button}
                    </ContextTooltip>
                ) : button}
                {this.state.opened ? this.renderPopup() : null}
            </div>
        );
    }

    reset() {
        this.props.onReset();
    }


    async fromChange({target: {value}}) {
        await this.setState({from: value});
        this.onChange('railroadcrossing.from', value);
        this.getRailRoadCloses();
        this.getRailRoadEvents();
    }

    async toChange({target: {value}}) {
        await this.setState({
            to: value,
            from: moment(this.state.from).isAfter(moment(value)) ? value : this.state.from,
        });

        this.onChange('railroadcrossing.to', value);
        this.getRailRoadCloses();
        this.getRailRoadEvents();
    }

    onChange(path, value) {
        if (value && value.target) {
            value = value.target.value;
        }
        this.setValue(path, value);
    }

    setValue(path, value) {
        this.props.onFiltersChange(path, value);
    }

    // запрос инфы по ТС
    async loadVehicles(events) {
        if (!events.length) return;
        const uuids = _.uniq(_.filter(_.map(events, 'vehicle_uuid')));
        const response = await this.props.getVehicleList({
            filters: {
                withUuid: uuids,
            },
            response_data: [
                'items/uuid',
                'items/state_number',
            ],
        });

        if (response.isOk) {
            this.setState({
                vehicles: response.payload.items,
            });
            return response.payload.items;
        } else {
            response.showErrors();
            return [];
        }
    }

    /**
     * Запрос времени открытия/закрытия переезда
     * @returns {Promise<void>}
     */
    async getRailRoadCloses() {
        this.setState({
            loading: true
        });
        let meta = {
            filters: {
                crossroad_uuid: this.props.railroadclose.uuid,
                time_from: moment(this.state.from).format(formats.DATETIME_API),
                time_to: moment(this.state.to).endOf('day').format(formats.DATETIME_API)
            }
        };
        const response = await rpc.request('com.rnis.crossroads.action.closes.get', {}, {meta});
        this.setState({
            closes: response.payload.items || [],
            loading: false
        });
    }

    /**
     * Запрос событий
     * @returns {Promise<void>}
     */
    async getRailRoadEvents() {
        if (!this.props.railroadclose.uuid) return;
        let meta = {
            filters: {
                crossroad_uuid: this.props.railroadclose.uuid,
                time_from: moment(this.state.from).format(formats.DATETIME_API),
                time_to: moment(this.state.to).endOf('day').format(formats.DATETIME_API)
            }
        };
        const response = await rpc.request('com.rnis.crossroads.action.events.get', {}, {meta});

        // запрос ТС
        this.loadVehicles(response.payload.items);
        this.setState({
            events: response.payload.items || [],
            eventsFiltered: response.payload.items || [],
        });
    }

    // поиск времени открытия/закрытия переезда по времени нарушения
    findOpenCloseTimeForCrossing(crossEvent) {
        let event_time_end = moment(crossEvent.violation_time_end).unix();
        let event_time_start = moment(crossEvent.violation_time_start).unix();
        let a = _.find(this.state.closes, (el) => {
            //return moment(crossEvent).isBefore(moment(el.time_open)) && moment(crossEvent).isAfter(moment(el.time_close));
            return (crossEvent.violation_time_start === el.time_close)

        })
        return a ? `              (закрыт: ${moment(a.time_close).format(formats.TIME_FULL)} открыт:${moment(a.time_open).format(formats.TIME_FULL)} продолжительность.:${moment.utc(moment(a.time_open).diff(moment(a.time_close))).format(formats.TIME_FULL)})` : '';


    }

    renderPopup() {
        const {links, activeLink} = this.state;
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Закрыть">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.toggleBlock}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModalComponent
                header={{
                    title: 'Мониторинг переездов', buttons
                }}
                onClose={() => {
                }}
                className={`b-modal-settingsFilterObject grid-fix`}
                withFade={false}>
                {this.props.railroadclose.uuid ? <div className="b-modal__container">
                        {this.state.isSubmiting ? (<GlobalLoaderComponent/>) : null}
                        <div style={{
                            'position': 'fixed',
                            'background': 'white',
                            'padding': '52px 52px 10px 52px',
                            'margin-top': '-30px',
                            'margin-left': '-20px',
                            'zIndex': '1000'
                        }}>
                            <div className="b-modal__container__title">{this.props.railroadclose.name}</div>
                            <div key="diapason">
                                <div className="top-menu__label">Выбор диапазона:</div>
                                <Datepicker style="dark" value={this.state.from} onChange={::this.fromChange}/>
                                <Datepicker style="dark" value={this.state.to} onChange={::this.toChange}/>
                            </div>
                            <div className="filters-area-line">
                                <ul className="top-menu__list">
                                    {links.map(link => {
                                        return (
                                            <ContextTooltip default={link.name}>
                                                <li key={link.id} className="top-menu__item _edge"
                                                    onClick={() => this.handleClick(link.id)}>
                                                    <div>
                                                        <div className={
                                                            link.className +
                                                            (link.id === activeLink ? " active" : "")
                                                        }>
                                                        </div>
                                                        {link.id !== 4 ?
                                                            <span className="badge1" data-badge={link.id === 1 ?
                                                                this.state.events.length : link.id === 2 ?
                                                                    this.state.events.filter(v => !v.violated).length :
                                                                    this.state.events.filter(v => v.violated).length}></span> : null}
                                                    </div>
                                                </li>
                                            </ContextTooltip>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>

                        <div className="violation-list" style={{
                            'marginTop': '155px',
                        }}>
                            {this.state.activeLink !== 4 ?
                                <ul>
                                    {this.state.eventsFiltered.map(v => {
                                        return (
                                            <li className={v.violated ? "orange" : "green"} key={v.event_uuid}
                                                style={{'height': v.violated ? '60px' : '30px'}}>

                                                <a href="#" onClick={() => this.handleEventClick(v)}
                                                   className={v.event_uuid === this.state.activeEvent ? 'active' : null}>
                                                    {`${_.get(_.find(this.state.vehicles, {uuid: v.vehicle_uuid}), 'state_number') || '-'} :  
                                                    ${ moment(v.event_time_start).format(formats.DATETIME) + ' - ' +
                                                        moment(v.event_time_end).format(formats.TIME_FULL)}`}
                                                    <p
                                                        style={{
                                                            'lineHeight': '1px',
                                                            'fontSize': '14px',
                                                            'display': v.violated ? 'block' : 'none'
                                                        }}>{this.findOpenCloseTimeForCrossing(v)}</p>
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul> :
                                <div>
                                    <div className="b-block-closes">

                                        <div className="b-block__desc"><b>Переезд закрыт</b></div>
                                        <div className="b-block__value"><b>Переезд открыт</b></div>

                                        {this.state.loading ? <Loader color="red" align="center"/> :
                                            this.state.closes.map(link => {
                                                return (
                                                    <div className="b-block" key={link.time_close}>
                                                        <div
                                                            className="b-block__desc">{link.time_close ? moment(link.time_close).format(formats.DATETIME) : '-'}</div>
                                                        <div
                                                            className="b-block__value">{link.time_open ? moment(link.time_open).format(formats.DATETIME) : '-'}</div>
                                                    </div>
                                                )
                                            })}

                                    </div>
                                    {/* <div className="b-modal__footer"></div>*/}
                                </div>
                            }
                        </div>
                    </div> :
                    <div className="b-modal__container">
                        <div className="b-modal__container__centerwarning">
                            <p>Переезд не выбран</p>
                            <p>Включите слой с железнодорожными переездами и выберете интересующий переезд</p>
                        </div>
                    </div>}
            </PageModalComponent>
        );
    }
}
