import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';


import _ from 'lodash';
import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {EntityList} from "helpers/entity";
import {
    createEventRuleNotification, deleteEventRuleNotification, getEventRuleNotification,
    getEventRuleNotifications
} from "store/reducers/notifications";
import {getEntityNames} from "store/reducers/system";
import PageModal from 'components/ui/page-modal';
import GlobalLoaderComponent from "components/ui/global-loader";
import TableContainer from "components/ui/Table/Container/TableContainer";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import classNames from "classnames";
import {resizeModals} from 'helpers/modal-size';
import {events} from 'dom-helpers';
import notifications from "dictionaries/notifications";
import * as alerts from "helpers/alerts";

let resizeId;

@propTypes({
    eventRuleUuid: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
})

@connect(state => ({}), {getEventRuleNotification, getEventRuleNotifications, deleteEventRuleNotification, getEntityNames, createEventRuleNotification})

export default class NotificationRulesNotificationsComponent extends BaseTableWithEditorComponent {

    title = ``;

    static contextTypes = {
        resizeModals: PropTypes.func
    };

    resize() {
        this.context.resizeModals && this.context.resizeModals();
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                {...this.props}
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Наименование')
                .fromField('name'),

            new Column('Доставка')
                .fromField('via')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => _.keys(_.pickBy(item.via, (item) => !!item)).join('<br/>')),

            new Column('Код')
                .fromField('code'),

            new Column('Важность')
                .fromField('notification_level')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => item.notification_level && notifications.levels[item.notification_level]),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteEventRuleNotification(data);
    }

    async loadData(meta) {
        meta.filters.withEventRule = this.props.eventRuleUuid;

        return await this.props.getEventRuleNotifications(meta);
    }

    async closeEditor() {
        await this.setState({
            showEditorModal: false,
            editorUuid: null,
        });

        this.resize();
    }

    async showEditorWithUuid(uuid = null) {
        await this.closeEditor();
        this.setState({
            showEditorModal: true,
            editorUuid: uuid,
        });

        this.resize();
    }

    async clone() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            const response = await this.props.getEventRuleNotification(selected.uuid);
            if (response.isOk) {
                let item = response.payload;
                item.uuid = null;
                item.name += ' (копия)';

                const response2 = await this.props.createEventRuleNotification(item);
                if (response2.isOk) {
                    this.reload();
                }
            }
        } else {
            alerts.alert('Не выбрано оповещение');
        }
    }

    render() {
        const editor = this.state.showEditorModal ? this.getEditor() : '';

        const buttons = (
            <ModalTopMenuButtons>
                <ModalTopMenuList className="top-menu_modal_edit">
                    <ContextTooltip key="notifications.clone" code="notifications.clone" default="Клонировать">
                        <ModalTopMenuListItem
                            className="b-icon-link_params b-icon-link_icon_clone"
                            onClick={::this.clone}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="base-editor.create" code="base-editor.create" default="Добавить">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_plus"
                            onClick={::this.showEditor}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="base-editor.delete" code="base-editor.delete" default="Удалить">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_basket"
                            onClick={::this.deleteSelected}
                        />
                    </ContextTooltip>

                    <ModalTopMenuListSeparator key="separator"/>
                </ModalTopMenuList>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <div>
                <PageModal
                    header={{title: 'Оповещения', buttons}}
                    //className={classNames("profile-modal b-modal-edit b-modal-route traffic-options-modal", (this.state.variantPointsViewModalData || this.state.intervalMapsModalData) ? '_tablet' : '')}
                    className={classNames("b-modal-edit b-modal-event-rule-notification-list")}
                    withFade={false}
                    onClose={this.props.onClose}
                >
                    {this.state.loading ? <GlobalLoaderComponent/> : null}
                    <div className="b-modal__block">
                        <TableContainer>
                            {this.renderTable()}
                        </TableContainer>
                    </div>
                </PageModal>
                {editor}
            </div>
        );
    }

}