import React from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import Page from "components/ui/page";
import PageModal from 'components/ui/page-modal';
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUnits} from "store/reducers/organizational_units/units";
import GlobalLoaderComponent from "components/ui/global-loader";
import systems from "dictionaries/systems";
import TabBlock from "components/ui/tabs/tab-block";
import '../style.less';
import Button from "components/ui/button";
import {createRoad, getRoad, updateRoad} from "store/reducers/kurs/roads";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {getRoadParts} from "store/reducers/kurs/road_parts";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import IconButton from "components/ui/icon-button";
import MapComponent from "components/ui/map";
import MapGeojson from "components/ui/map/base/geojson";
import L from 'leaflet';
import currentUser from 'helpers/current-user';

@connect(state => ({}), {getRoad, createRoad, updateRoad})

export default class UtilityRoadEditor extends BaseEditor {

    title = 'дороги';
    modelClass = 'App\\Model\\Road';

    async componentDidMount() {
        const propsUuid = (this.props.params.uuid === 'create') ? null : this.props.params.uuid;
        if (this.state.uuid !== propsUuid) {
            this.setState({
                uuid: propsUuid,
                item: null,
                isLoading: !!propsUuid,
                mode: propsUuid ? 'edit' : 'add',
            });

            if (propsUuid) {
                const response = await this.loadData(propsUuid);
                if (response.isOk) {
                    this.setState({
                        item: response.payload,
                        isLoading: false,
                    });
                } else {
                    response.showErrors();
                }
            }
        }
    }

    componentWillReceiveProps(props) {
    }

    async loadData(uuid) {
        return await this.props.getRoad(uuid);
    }

    async createItem(data) {
        return await this.props.createRoad(data);
    }

    async updateItem(data) {
        return await this.props.updateRoad(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.state.mode}
                onClose={::this.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    onClose() {
        this.props.router.push('/utility/roads');
    }

    async create(data) {
        this.clearErrors();
        this.startSave();

        const response = await this.createItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            this.onClose();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();

        const response = await this.updateItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            this.onClose();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    render() {
        let title = 'Создание дороги';
        let form;
        let onSubmit;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.state.mode === 'edit') {
            title = '';

            if (this.state.item) {
                title = `Дорога "${_.truncate(this.state.item.name, {
                    length: 50,
                })}"`;
                form = this.getForm(this.state.item, ::this.edit);
            }

            onSubmit = ::this.onEdit;

        } else {
            form = this.getForm({}, ::this.create);

            onSubmit = ::this.onCreate;

        }

        return (
            <Page
                pageId="RoadManagement"
                title={`${systems.utility} → ${title}`}
                headerActions={this.renderHeaderActions()}
            >
                {loader}
                {form ? (
                    <Accordion>
                        <AccordionItem single={true}>
                            {form}
                        </AccordionItem>
                    </Accordion>
                ) : null}
                {this.renderModals()}
                <div className="page-footer">
                    <div className="page-footer__txt">Вы хотите сохранить все изменения?</div>
                    <Button size="md" color="white" shadow="gray" className="b-button_cancel" text="Отменить"
                            onClick={::this.onClose}/>
                    <Button size="md" color="red" className="b-button_save" text="Сохранить"
                            onClick={onSubmit}/>
                </div>
            </Page>
        );
    }

    renderHeaderActions() {
        if (this.state.mode !== 'edit') {
            return [];
        }

        return [
            ((this.state.mode === 'edit') && currentUser.can('com.rnis.system.permission.audit', 'read') && this.modelClass) ?
                (
                    <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                    default="Журнал аудита">
                        <IconButton icon="history" onClick={::this.gotoAudit}/>
                    </ContextTooltip>
                ) : null,
            <ContextTooltip key="road.map" code="road.map" default="На карте"
                            position="left">
                <IconButton icon="route" onClick={::this.showMap}/>
            </ContextTooltip>,
        ];
    }

    gotoAudit() {
        const url = `/system/audit/${this.state.uuid}?class=${this.modelClass}`;

        this.props.router.push(url);
    }

    showMap() {
        this.setState({
            mapVisible: true,
        });
    }

    hideMap() {
        this.setState({
            mapVisible: false,
        });
    }

    renderModals() {
        if (this.state.mapVisible) {
            const title = 'Дорога на карте';

            const buttons = (
                <ModalTopMenuList>
                    <ModalTopMenuButton
                        key="close"
                        className="_close"
                        tooltip="Отменить"
                        onClick={::this.hideMap}
                    />
                </ModalTopMenuList>
            );

            if (!this.refs.map) {
                setTimeout(() => {
                    this.forceUpdate();
                }, 300);
            }

            return (
                <PageModal
                    header={{title, buttons}}
                    onClose={::this.hideMap}
                    withFade
                >
                    <MapComponent
                        ref="map"
                        showLayers={false}
                    >
                        {(this.refs.map) ? (
                            <MapGeojson
                                map={this.refs.map}
                                ref={::this.fitBounds()}
                                leafletMap={this.refs.map.getWrappedInstance().map}
                                geometry={_.get(this.state.item, 'geometry')}
                                tooltip={_.get(this.state.item, 'name')}
                            />
                        ) : null}

                    </MapComponent>
                </PageModal>
            );
        }
    }

    fitBounds() {
        try {
            const group = L.geoJSON(_.get(this.state.item, 'geometry'));
            this.refs.map.getWrappedInstance().fitBounds(group.getBounds());
        } catch (e) {
        }
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getDictionaryList, getUnits, getRoadParts}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        road: {},
        road_parts: {},
        maintenance_groups: [],
    };

    getData() {
        return this.state.road;
    }

    async componentDidMount() {
        await this.setState({
            road: this.props.data,
        });

        this.loadDictionaries([
            'maintenance_groups',
        ]);
        this.loadRoadParts();
    }

    async loadRoadParts() {
        const response = await this.props.getRoadParts({
            filters: {
                withUuid: this.get('road_parts') || [],
            },
        });

        if (response.isOk) {
           // debugger
            this.setState({
                road_parts: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'name'),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.road, path, defaultValue);
    }

    render() {
        return (
            <div>
                <TabBlock withBorder={true}>
                    <div>
                        <Block title="Название автодороги">
                            {this.textInput('road.name')}
                        </Block>
                        <Block title="РГН">
                            {this.textInput('road.rgn')}
                        </Block>
                        <Block title="Протяженность">
                            {this.textInput('road.length')}
                        </Block>
                        <Block title="Вид дороги">
                            {this.textInput('road.kind')}
                        </Block>
                        <Block title="Тип дороги">
                            {this.textInput('road.type')}
                        </Block>
                        <Block title="Балансодержатель">
                            {this.textInput('road.owner_name')}
                        </Block>
                        <Block title="Инвентарный номер">
                            {this.textInput('road.number')}
                        </Block>
                        <Block title="Категория дороги по ГОСТ Р 52398-2005">
                            {this.textInput('road.gost_category')}
                        </Block>
                        <Block title="Тип покрытия дороги">
                            {this.textInput('road.cover_type')}
                        </Block>
                        <Block title="Группа по содержанию">
                            {this.select('road.maintenance_group_uuid', this.state.maintenance_groups)}
                        </Block>
                        <Block title="Дата ввода в экслуатацию">
                            {this.datepicker('road.date_from')}
                        </Block>
                        <Block title="Дата вывода из эксплуатации">
                            {this.datepicker('road.date_to')}
                        </Block>
                        <Block>
                            {this.checkbox('road.is_ownerless', 'Бесхозный объект')}
                        </Block>
                        {(this.get('road_parts', []).length > 0) ? (
                            <Block size="xl" title="Участки дорог">
                                {_.map(this.get('road_parts', []), (roadPartUuid) => {
                                    return (
                                        <div key={roadPartUuid}>
                                            {_.get(this.state.road_parts, roadPartUuid)}
                                        </div>
                                    );
                                })}
                            </Block>
                        ) : null}
                    </div>
                </TabBlock>
            </div>
        );
    }
}