import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import './TableContainer.less';

export default class TableContainer extends Component {

    render() {
        return (
            <div className="table-container" style={this.props.style}>
                {this.props.children}
            </div>
        );
    }

}