import {Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getRouteRegistries = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getRouteRegistries(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRouteRegistry = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getRouteRegistry(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRouteRegistryFact = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getRouteRegistryFact(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createRouteRegistry = (data) => async (dispatch) => {
    try {
        const response = await api.geo.createRouteRegistry(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateRouteRegistry = (data) => async (dispatch) => {
    try {
        const response = await api.geo.updateRouteRegistry(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteRouteRegistry = (data) => async (dispatch) => {
    try {
        const response = await api.geo.deleteRouteRegistry(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
