import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import PageModal from 'components/ui/page-modal';

import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";

import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import {getPermissions, getRoles} from "store/reducers/roles/roles";
import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";

import './editor.less';
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import {getUnitPermissions, updateUnitPermission} from "store/reducers/unit-permissions/unit-permissions";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ContextTooltip from "components/ui/context-tooltip";
import systems from "dictionaries/systems";
import {Slider} from "components/ui/slider";
import Select from "components/ui/select";
import getGroups from '../roles/permissionsList';
import * as alerts from "helpers/alerts";
import debounce from 'throttle-debounce/debounce';

@propTypes({
    uuid: PropTypes.string,
})

@defaultProps({})

@connect(state => ({}), {getUnits, getUnitPermissions, updateUnitPermission})

export default class UnitPermissionEditor extends Component {

    state = {
        uuid: null,
        units: null,
        unitPermissions: null,
        saving: false,
        errors: {},
        isLoading: true,
    };

    componentWillReceiveProps(props) {
        if (this.state.uuid !== props.uuid) {
            this.loadUnitPermissions(props.uuid);
        }
    }

    async componentDidMount() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 25,
            },
        });
        if (response.isOk) {
            this.setState({
                units: _.orderBy(response.payload.items, ['component', 'name'], ['asc', 'asc']),
            });
        } else {
            response.showErrors();
        }
    }

    async loadUnitPermissions(uuid) {
        this.setState({
            uuid,
            user: null,
            isLoading: true
        });
        const response = await this.props.getUnitPermissions(uuid);
        if (response.isOk) {
            this.setState({
                unitPermissions: _.keyBy(response.payload.items, 'under_unit_uuid'),
                isLoading: false,
            });
        } else {
            response.showErrors();
        }
    }

    gotoAudit() {
        this.refs.form && this.refs.form.getWrappedInstance().gotoAudit();
    }

    render() {
        let title = 'Редактирование структурных прав';

        let form;
        let buttons;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.state.units && this.state.unitPermissions) {
            form = <EditorForm
                {...this.props}
                parent={this}
                ref="form"
                mode="edit"
                onSubmit={::this.edit}
                onClose={::this.props.onClose}
                units={this.state.units}
                unitPermissions={this.state.unitPermissions}
                errors={this.state.errors}
                data={{base_unit_uuid: this.props.uuid}}
            />;
        }

        const unitPermission = (this.refs.form && this.refs.form.getWrappedInstance().get('under_unit_uuid')) ? _.get(this.state.unitPermissions, this.refs.form.getWrappedInstance().get('under_unit_uuid')) : null;

        buttons = (
            <ModalTopMenuButtons>
                {unitPermission ? (
                    <ModalTopMenuList className="top-menu_modal_edit">
                        <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                        default="Журнал аудита">
                            <ModalTopMenuListItem
                                className="b-icon-link_params b-icon-link_icon_history"
                                href={`/system/audit/${unitPermission.uuid}?class=App\\Model\\UnitPermission`}
                            />
                        </ContextTooltip>

                        <ModalTopMenuListSeparator key="separator"/>
                    </ModalTopMenuList>
                ) : null}

                <ModalTopMenuList className="top-menu_modal_edit">
                    <ContextTooltip key="unit-permissions.audit" code="unit-permissions.audit" default="Журнал аудита">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_history"
                            onClick={::this.gotoAudit}
                        />
                    </ContextTooltip>

                    <ContextTooltip key="unit-permissions.clear" code="unit-permissions.clear" default="Очистить">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_basket"
                            onClick={::this.clear}
                        />
                    </ContextTooltip>

                    <ModalTopMenuListSeparator key="separator"/>
                </ModalTopMenuList>

                <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                    <ModalTopMenuButton
                        className="_save"
                        title="Сохранить"
                        onClick={::this.onEdit}
                    />
                </ContextTooltip>

                <ModalTopMenuButtonsSeparator/>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}
            >
                {loader}
                {form}
            </PageModal>
        );
    }

    clear() {
        this.refs.form && this.refs.form.getWrappedInstance().clear();
    }

    clearErrors() {
        this.setState({errors: {}});
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    onEdit() {
        if (!this.refs.form) return;

        const state = this.refs.form.getWrappedInstance().getData();
        this.edit(state);
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();

        const unitPermission = this.composeUnitPermission(data);

        const response = await this.props.updateUnitPermission(unitPermission);
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    composeUnitPermission(data) {
        return {
            base_unit_uuid: this.state.uuid,
            under_unit_uuid: data.under_unit_uuid,
            permissions: _.map(data._permissions, (grants, code) => {
                return {
                    code: code.replace(/:::/g, '.'),
                    grants: _.keys(_.pickBy(grants, item => !!item)),
                };
            }),
        };
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    units: PropTypes.array.isRequired,
    unitPermissions: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({
    permissions: state.roles.get('permissions'),
}), {
    getPermissions, getRoles
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        unit_permission: {},
        roles: [],
        hiddenGroups: {},
        activeSection: 'system'
    };

    sections = [
        {value: 'system', label: `${_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '100']).text} и настройки`},
        {value: 'analytics', label: 'Аналитическая платформа'},
        {value: 'passengers', label: 'Управление пассажирскими перевозками'},
        {value: 'ems', label: 'Электронная маршрутная сеть'},
        {value: 'roads', label: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text},
        {value: 'utility', label: 'Жилищно-коммунальное хозяйство'},
        {value: 'communal', label: 'Коммунальная техника'},
        {value: 'children', label: 'Управление перевозками детей'},
        {value: 'garbage', label: 'Контроль вывоза мусора'},
        {value: 'gatn', label: window.RNIS_SETTINGS.GUGATNTITLE},
        {value: 'reports', label: 'Отчёты'},
        {value: 'taxi', label: 'Мониторинг легковых такси'},
        {value: 'agricultural', label: 'Транспорт сельского хозяйства'},
        {value: 'medic', label: 'Контроль медицинского транспорта'},
        {value: 'dangerous', label: 'Перевозки опасных грузов'},
        {value: 'medicine', label: 'Центр медицины и катастроф'},
        {value: 'forestry', label: 'Лесное хозяйство'},
        {value: 'farming', label: 'Сельское хозяйство'},
        {value: 'official', label: 'Служебный транспорт'},
        {value: 'emercom', label: 'Пожарно-спасательная служба'},
        {value: 'timber', label: 'Перевозки лесоматериалов'},
    ];

    getData() {
        return this.state.unit_permission;
    }

    async componentDidMount() {
        this.loadPermissions();
        //this.loadRoles();
    }

    async loadPermissions() {
        const response = await this.props.getPermissions();
        if (!response.isOk) {
            response.showErrors();
        }
    }

    async loadRoles() {
        const response = await this.props.getRoles();
        if (response.isOk) {
            this.setState({
                roles: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.unit_permission, path, defaultValue);
    }

    onChangeSection = ({value}) => {
        this.setState({activeSection: value});
    };

    clear() {
        if (this.get('under_unit_uuid')) {
            this.setValue(`unit_permission._permissions`, {});
        }
    }

    gotoAudit() {
        if (this.get('under_unit_uuid')) {
            const uuid = _.get(this.props.unitPermissions, this.get('under_unit_uuid') + '.uuid')
            if (uuid) {
                this.props.router.push(`/system/audit/${uuid}`);
            } else {
                alerts.alert('Данных аудита по данному связанному предприятию нет');
            }
        } else {
            alerts.alert('Необходимо выбрать связанное предприятие');
        }
    }

    /**
     * Асинхронная загрузка ролей
     * @param input текст из поля ввода
     * @param callback
     * @returns {Promise<void>}
     */
    async loadRolesLite(input, callback) {
        let result = await this.props.getRoles({
            search: input,
            pagination: {
                page: 1,
                limit: 25,
            },
        });

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: i.name,
                    value: i.uuid,
                    permissions: i.permissions
                })), 'label'),
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    async loadUnitsLite(input, callback) {
        let result = await this.props.getUnits({
            search: input,
            pagination: {
                page: 1,
                limit: 25,
            },
        });

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(unit => ({
                    value: unit.uuid,
                    label: `[${systems[unit.component]}] ${unit.name}`,
                })), 'label'),
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    rolesDebounce = debounce(500, ::this.loadRolesLite);
    unitsDebounce = debounce(500, ::this.loadUnitsLite);

    render() {
        const isChecked = (grant, permission) => {
            const code = permission.code.replace(/\./g, ':::');

            return this.get(`_permissions.${code}.${grant}`);
        };


        const hasGrant = (grant, permission) => {
            return _.indexOf(permission.grants, grant) !== -1;
        };

        const activeSection = this.sections.find(section => section.value === this.state.activeSection);
        const groups = {[this.state.activeSection]: getGroups()[this.state.activeSection].permissions};

        return (
            <div className="UnitPermissionEditorForm RoleEditorForm">
                <Block size="xl" title="Связанное предприятие">
                   {/* {this.select('unit_permission.under_unit_uuid', _.filter(this.props.units, (unit) => (unit.uuid !== this.props.uuid)).map(unit => ({
                        value: unit.uuid,
                        label: `[${systems[unit.component]}] ${unit.name}`,
                    })))}*/}

                    {this.selectAsync('unit.uuid', ::this.unitsDebounce, {
                        onChange: ::this.addUnit,
                    })}

                </Block>
                {this.get('under_unit_uuid') ? ([

                    <Block key="role-add" size="xl" title="Использовать как шаблон роль">
                        {this.selectAsync('role.uuid', ::this.rolesDebounce, {
                            onChange: ::this.addRole,
                        })}
                    </Block>,

                    <Block key="section" size="xl" title="Раздел">
                        <Select
                            value={activeSection}
                            options={this.sections}
                            onChange={this.onChangeSection}
                            clearable={false}
                        />
                    </Block>,
                    <Block size="xl">
                        <table key="permissions-table" className="permissions-table">
                            <thead>
                            <tr>
                                <th>Право доступа</th>
                                <th className="permission-cell">Создание</th>
                                <th className="permission-cell">Чтение</th>
                                <th className="permission-cell">Редакт.</th>
                                <th className="permission-cell">Удаление</th>
                            </tr>
                            </thead>
                            <tbody>
                            {_.map(groups, ::this.renderPermissionGroup)}
                            </tbody>
                        </table>
                    </Block>
                ]) : null}
            </div>
        );
    }

    renderPermissionGroup(permissionCodes, title) {
        const permissions = _.filter(this.props.permissions, (permission) => (_.indexOf(permissionCodes, permission.code) !== -1));
        const InnerCodes = _.flatMap(getGroups()[title].innerPermissions, codes => codes);
        const allCodes = _.concat(permissionCodes, InnerCodes);
        const allPermissions = _.filter(this.props.permissions, (permission) => (_.indexOf(allCodes, permission.code) !== -1));

        const createPermissions = _.filter(allPermissions, (permission) => {
            return _.indexOf(permission.grants, 'create') !== -1;
        });
        const readPermissions = _.filter(allPermissions, (permission) => {
            return _.indexOf(permission.grants, 'read') !== -1;
        });
        const updatePermissions = _.filter(allPermissions, (permission) => {
            return _.indexOf(permission.grants, 'update') !== -1;
        });
        const deletePermissions = _.filter(allPermissions, (permission) => {
            return _.indexOf(permission.grants, 'delete') !== -1;
        });

        const isChecked = (grant, permission) => {
            const code = permission.code.replace(/\./g, ':::');

            return this.get(`_permissions.${code}.${grant}`);
        };

        const hasGrant = (grant, permission) => {
            return _.indexOf(permission.grants, grant) !== -1;
        };

        return (
            [
                <tr key={`${title}:select`}>
                    <td/>
                    <td>
                        {(_.filter(createPermissions, hasGrant.bind(this, 'create')).length > 0) ? (
                            <Slider
                                selected={_.filter(createPermissions, isChecked.bind(this, 'create')).length}
                                total={createPermissions.length}
                                selectAll={this.selectAll.bind(this, createPermissions, 'create')}
                                deselectAll={this.deselectAll.bind(this, createPermissions, 'create')}
                            />
                        ) : null}
                    </td>
                    <td>
                        {(_.filter(readPermissions, hasGrant.bind(this, 'read')).length > 0) ? (
                            <Slider
                                selected={_.filter(readPermissions, isChecked.bind(this, 'read')).length}
                                total={readPermissions.length}
                                selectAll={this.selectAll.bind(this, readPermissions, 'read')}
                                deselectAll={this.deselectAll.bind(this, readPermissions, 'read')}
                            />
                        ) : null}
                    </td>
                    <td>
                        {(_.filter(updatePermissions, hasGrant.bind(this, 'update')).length > 0) ? (
                            <Slider
                                selected={_.filter(updatePermissions, isChecked.bind(this, 'update')).length}
                                total={updatePermissions.length}
                                selectAll={this.selectAll.bind(this, updatePermissions, 'update')}
                                deselectAll={this.deselectAll.bind(this, updatePermissions, 'update')}
                            />
                        ) : null}
                    </td>
                    <td>
                        {(_.filter(deletePermissions, hasGrant.bind(this, 'delete')).length > 0) ? (
                            <Slider
                                selected={_.filter(deletePermissions, isChecked.bind(this, 'delete')).length}
                                total={deletePermissions.length}
                                selectAll={this.selectAll.bind(this, deletePermissions, 'delete')}
                                deselectAll={this.deselectAll.bind(this, deletePermissions, 'delete')}
                            />
                        ) : null}
                    </td>
                </tr>,
                (!this.state.hiddenGroups[title]) ? permissions.map((el, i) => this.renderPermission(el, i, false)) : null,
                (_.flatMap(getGroups()[title].innerPermissions, this.renderInnerPermission))
            ]
        );
    }

    renderInnerPermission = (permissionCodes, title) => {
        const permissions = _.filter(this.props.permissions, (permission) => (_.indexOf(permissionCodes, permission.code) !== -1));
        return [
            <tr key={title} className="permission-group-title">
                <td>{title}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>,
            (permissions.map((el, i) => this.renderPermission(el, i, true)))
        ];
    };

    selectAll(permissions, grant) {
        let unit_permission = this.state.unit_permission;

        _.each(permissions, (permission) => {
            if (permission.code === 'com.rnis.portal.menu') {
                return;
            }
            if (_.indexOf(permission.grants, grant) === -1) {
                return;
            }
            const code = permission.code.replace(/\./g, ':::');
            _.set(unit_permission, `_permissions.${code}.${grant}`, true);
        });

        this.setState({unit_permission});
    }

    deselectAll(permissions, grant) {
        let unit_permission = this.state.unit_permission;

        _.each(permissions, (permission) => {
            const code = permission.code.replace(/\./g, ':::');
            _.set(unit_permission, `_permissions.${code}.${grant}`, false);
        });

        this.setState({unit_permission});
    }

    addRole(e) {
        const role = e;
        if (!role) {
            // удаляем текст роли из поля ввода
            this.setValue('role.uuid', e);
            // очищаем проставленные галочки, если поле роли очищено
            this.setValue(`unit_permission._permissions`, {});
            return;
        }
        this.setValue('role.uuid', role);
        // очищаем проставленные галочки
        this.setValue(`unit_permission._permissions`, {});
        _.each(role.permissions, (permission) => {
            _.each(permission.grants, (grant) => {
                const code = permission.code.replace(/\./g, ':::');
                // выставляем галочки
                this.setValue(`unit_permission._permissions.${code}.${grant}`, true);
            });
        });
    }

    addUnit(e) {
        const unit = e;
        if (!unit) {
            // удаляем текст  из поля ввода
            this.setValue('unit.uuid', e);
            // сбрасываем формы
            this.setState({
                unit_permission: e,
            });
            return;
        }
        this.setValue('unit.uuid', unit);
        this.onChangeInput('unit_permission.under_unit_uuid', {target: e})
    }

    async onChangeInput(field,  {target: {value}}) {
        this.setValue(field, value);
        if (field === 'unit_permission.under_unit_uuid') {
            await this.setState({
                unit_permission: this.decomposeUnitPermission(value),
            });

            this.props.parent.forceUpdate();
        }
    }

    decomposeUnitPermission(underUnitUuid) {
        let data = {
            under_unit_uuid: underUnitUuid,
            _permissions: {},
        };

        const unitPermission = _.get(this.props.unitPermissions, underUnitUuid, []);
        const unitPermissions = _.get(unitPermission, 'permissions');

        _.each(unitPermissions || [], (permission) => {
            const code = permission.code.replace(/\./g, ':::');
            data._permissions[code] = {};
            _.each(permission.grants, (grant) => {
                data._permissions[code][grant] = true;
            });
        });

        return data;
    }

    renderPermission(permission, index, isSubitem) {
        const code = permission.code.replace(/\./g, ':::');
        const hasGrant = (grant) => {
            return _.indexOf(permission.grants, grant) !== -1;
        };

        return (
            <tr key={index} className={isSubitem ? '' : 'permission-group-title'}>
                <td className={isSubitem ? "permission-title" : ''}>{permission.name}</td>
                <td className="permission-cell">{hasGrant('create') ? <ContextTooltip
                    default="Создание">{this.checkbox(`unit_permission._permissions.${code}.create`)}</ContextTooltip> : null}</td>
                <td className="permission-cell">{hasGrant('read') ? <ContextTooltip
                    default="Чтение">{this.checkbox(`unit_permission._permissions.${code}.read`)}</ContextTooltip> : null}</td>
                <td className="permission-cell">{hasGrant('update') ? <ContextTooltip
                    default="Редактирование">{this.checkbox(`unit_permission._permissions.${code}.update`)}</ContextTooltip> : null}</td>
                <td className="permission-cell">{hasGrant('delete') ? <ContextTooltip
                    default="Удаление">{this.checkbox(`unit_permission._permissions.${code}.delete`)}</ContextTooltip> : null}</td>
            </tr>
        );
    }

}