import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';
import * as alerts from 'helpers/alerts';

const initialState = new Map({
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getRouteVariants = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getRouteVariants(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRouteVariant = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getRouteVariant(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createRouteVariant = (routeVariant) => async (dispatch) => {
    try {
        const response = await api.geo.createRouteVariant(routeVariant);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateRouteVariant = (routeVariant) => async (dispatch) => {
    try {
        const response = await api.geo.updateRouteVariant(routeVariant);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteRouteVariant = (routeVariant) => async (dispatch) => {
    try {
        const response = await api.geo.deleteRouteVariant(routeVariant);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};