import React from 'react';
import {connect} from 'react-redux';


import _ from 'lodash';
import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {EntityList} from "helpers/entity";
import {
    getEventRuleNotifications,
} from "store/reducers/notifications";
import {getUserNotificationSettings} from "store/reducers/notifications/user-notification-settings";
import {getEntityNames} from "store/reducers/system";
import notifications from "dictionaries/notifications";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";

@connect(state => ({}), {getUserNotificationSettings, getEventRuleNotifications, getEntityNames})

export default class NotificationUserSettingsComponent extends BaseTableWithEditorComponent {

    title = `Пользовательские настройки оповещений`;

    getBaseUrl() {
        return '/notifications/settings';
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="base-table-list.delete" code="base-table-list.delete" default="Удалить" position="left">
                <IconButton icon="basket" disabled={this.state.selectedRowsCount !== 1}
                            onClick={::this.deleteSelected}/>
            </ContextTooltip>,
            <ContextTooltip key="notifications.back" code="notifications.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.gotoBack}/>
            </ContextTooltip>,
        ];
    }

    gotoBack() {
        this.props.router.push('/notifications');
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Оповещение')
                .denyOrder()
                .denyColumnFilter()
                .fromField('eventRuleNotification.name'),

            new Column('Доставка')
                .fromField('via')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => _.keys(_.pickBy(item.via, (item) => !!item)).join('<br/>')),

            new Column('Код')
                .denyOrder()
                .denyColumnFilter()
                .fromField('eventRuleNotification.code'),

            new Column('Важность')
                .fromField('notification_level')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => item.notification_level && notifications.levels[item.notification_level]),

        ]);
    }

    async loadData(meta) {
        meta.order = null;
        const response = await this.props.getUserNotificationSettings(meta);
        if (response.isOk) {
            const eventRuleNotificationUuids = _.uniq(_.filter(_.map(response.payload.items, 'event_rule_notification_uuid')));
            const eventRuleNotifications = await this.loadEventRuleNotifications(eventRuleNotificationUuids);

            response.payload.items = _.map(response.payload.items, (item) => {
                item.eventRuleNotification = eventRuleNotifications[item.event_rule_notification_uuid];
                return item;
            });
        }

        return response;
    }

    async loadEventRuleNotifications(uuids) {
        const response = await this.props.getEventRuleNotifications({
            filters: {
                withUuid: uuids,
            },
        });
        if (response.isOk) {
            return _.keyBy(response.payload.items, 'uuid');
        } else {
            response.showErrors();
        }

        return {};
    }
}