import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import _ from 'lodash';
import {success, error} from 'helpers/response';
import {User} from "helpers/user";

const initialState = new Map({
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getVehicleBnsoList = (vehicle_uuid, meta = {}) => async (dispatch) => {
    try {
        const response = await api.vehicles.getVehicleBnsoList(vehicle_uuid, meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getVehicleBnso = (uuid) => async (dispatch) => {
    try {
        const response = await api.vehicles.getVehicleBnso(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createVehicleBnso = (data) => async (dispatch) => {
    try {
        const response = await api.vehicles.createVehicleBnso(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateVehicleBnso = (data) => async (dispatch) => {
    try {
        const response = await api.vehicles.updateVehicleBnso(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteVehicleBnso = (data) => async (dispatch) => {
    try {
        const response = await api.vehicles.deleteVehicleBnso(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};