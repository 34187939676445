import React, {Component} from 'react';
import DefaultTooltipContent from "recharts/es6/component/DefaultTooltipContent";
import _ from 'lodash';
import {isNumOrStr} from "recharts/es6/util/DataUtils";

const defaultFormatter = value => (
    (_.isArray(value) && isNumOrStr(value[0]) && isNumOrStr(value[1])) ?
        value.join(' ~ ') :
        value
);

export default class MapRetrospectiveTooltip extends DefaultTooltipContent {
    renderContent() {
        const {payload, separator, formatter, itemStyle, itemSorter, linesData} = this.props;

        if (payload && payload.length) {
            const listStyle = {padding: 0, margin: 0};

            const items = payload
                .slice(0, 1)
                .sort(itemSorter)
                .map((entry, i) => {
                    const finalItemStyle = {
                        display: 'block',
                        paddingTop: 4,
                        paddingBottom: 4,
                        color: entry.color || '#000',
                        ...itemStyle,
                    };
                    const finalFormatter = entry.formatter || formatter || defaultFormatter;

                    return (
                        <li className="recharts-tooltip-item" key={`tooltip-item-${i}`} style={finalItemStyle}>
                            {entry.name ? <span className="recharts-tooltip-item-name">{entry.name}</span> : null}
                            {
                                entry.name ?
                                    <span className="recharts-tooltip-item-separator">{separator}</span> :
                                    null
                            }
                            <span className="recharts-tooltip-item-value">
                              {finalFormatter ? finalFormatter(linesData[entry.dataKey.substr(4)].name, entry.name, entry, i) : linesData[entry.dataKey.substr(4)].name}
                            </span>
                        </li>
                    );
                });

            return <ul className="recharts-tooltip-item-list" style={listStyle}>{items}</ul>;
        }

        return null;
    }
}