import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import {connect} from 'react-redux';
import MapComponent from "components/ui/map";
import {getHistory} from "store/reducers/maps";
//import TelematicsDevice from "components/modules/maps/telematics-packet";
import DirectionalPolyline from "components/ui/map/objects/directional-polyline";
import HistoryPointMarker from "components/ui/map/markers/history-point-marker";
//import VehicleMarker from "components/ui/map/markers/vehicle-marker";
import moment from 'moment';
import formats from 'dictionaries/formats';
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import DirectionalGeojson from "components/ui/map/objects/directional-geojson";
import MapGeojson from "components/ui/map/base/geojson";
import L from 'leaflet';
import KursPointMarker from "components/ui/map/markers/kurs-point-marker";
import MapPolyline from "components/ui/map/base/polyline";

@propTypes({
    data: PropTypes.object
})

@connect(state => ({}), {getHistory})

export default class KursTaskMapHistoryMap extends Component {

    zoomed = false;

    componentDidUpdate() {
        setTimeout(() => {
            const {history} = this.props.data;

            if (history.length && !this.zoomed) {

                const start = _.first(history);
                const end = _.last(history);

                const polyline = new L.polyline(_.map(history, (item) => ([
                    item.lat,
                    item.lng,
                ])));

                const bounds = polyline.getBounds();

                this.refs.map && this.refs.map.getWrappedInstance().fitBounds(bounds);
                this.zoomed = true;
            }
        }, 100);
    }

    render() {
        const {history, points} = this.props.data;
        const start = _.first(history);
        const end = _.last(history);

        return (
            <MapComponent
                ref="map"
                showLayers={false}
            >
                {(this.refs.map && this.refs.map.getWrappedInstance().map) ? ([
                    (history.length > 0) ? ([
                        <DirectionalPolyline
                            key="path"
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            coordinates={this.getHistoryCoordinates()}
                            color="#78909c"
                            opacity={1}
                        />,
                        <HistoryPointMarker
                            key="start"
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            latitude={_.first(history).lat}
                            longitude={_.first(history).lng}
                            title={`Начало: ${moment.unix(start.type).format(formats.DATETIME)}<br/>Координаты: ${start.lat}, ${start.lng}<br/>Скорость: ${start.speed} км/ч`}
                        />,
                        <HistoryPointMarker
                            key="end"
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            latitude={_.last(history).lat}
                            longitude={_.last(history).lng}
                            title={`Окончание: ${moment.unix(end.type).format(formats.DATETIME)}<br/>Координаты: ${end.lat}, ${end.lng}<br/>Скорость: ${end.speed} км/ч`}
                        />,
                    ]) : null,
                    (this.props.data.geojson ? (
                        <DirectionalGeojson
                            key="part"
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            geometry={this.props.data.geojson}
                            color="#f65c50"
                            opacity={1}
                        />
                    ) : null),
                    this.renderGeoJsonPoints(),
                    this.renderDebug(),
                ]) : null}
            </MapComponent>
        );
    }

    renderDebug() {
        const {debug} = this.props.data;
        return _.concat(_.map(debug.points || [], (point, index) => {
            return (
                <HistoryPointMarker
                    key={index}
                    map={this.refs.map}
                    leafletMap={this.refs.map.getWrappedInstance().map}
                    latitude={point[0]}
                    longitude={point[1]}
                    title={`${index + 1}`}
                    permanentToolTip={true}
                />
            );
        }), _.map(debug.checked_by || [], (info, index) => {
            const point = debug.points[info.index];

            return [
                <HistoryPointMarker
                    key={`${index}-first`}
                    map={this.refs.map}
                    leafletMap={this.refs.map.getWrappedInstance().map}
                    latitude={info.first[0]}
                    longitude={info.first[1]}
                    title={`${info.index + 1}.1 - ${info.first[2]}`}
                />,
                <HistoryPointMarker
                    key={`${index}-second`}
                    map={this.refs.map}
                    leafletMap={this.refs.map.getWrappedInstance().map}
                    latitude={info.second[0]}
                    longitude={info.second[1]}
                    title={`${info.index + 1}.2 - ${info.second[2]}`}
                />,
                <MapPolyline
                    key={`${index}-poly`}
                    map={this.refs.map}
                    leafletMap={this.refs.map.getWrappedInstance().map}
                    coordinates={[
                        [info.first[0], info.first[1]],
                        point,
                        [info.second[0], info.second[1]],
                    ]}
                    color="#00FFFF"
                />,
            ];
        }));
    }

    renderGeoJsonPoints() {
        if (!this.props.data.geojson) {
            return;
        }

        const {coordinates} = this.props.data.geojson;
        const first = _.first(coordinates);
        const last = _.last(coordinates);

        return [
            <KursPointMarker
                key="first"
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                latitude={first[1]}
                longitude={first[0]}
                color="red"
                icon={true}
                zIndex={100}
            />,
            <KursPointMarker
                key="last"
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                latitude={last[1]}
                longitude={last[0]}
                color="red"
                icon={true}
                zIndex={100}
            />,
        ];
    }

    renderPath(point, index) {
        const {stopPoints} = this.props.data;

        const path = point.path_to_the_next_point_geometry;
        if (point.point_type !== 'stop_point') {
            return;
        }

        const size = ((index === 0) || (index === this.props.data.points.length - 1)) ? 1.5 : 1;

        return [
            path ? (
                <DirectionalPolyline
                    key={`${point.point_type}:${index}:${point.type_uuid}:${point._uuid}`}
                    map={this.refs.map}
                    leafletMap={this.refs.map.getWrappedInstance().map}
                    color="gray"
                    coordinates={_.map(path.coordinates, (coord) => _.clone(coord).reverse())}
                    interactive={false}
                />
            ) : null,
            <StopPointMarker
                key={`${point.type_uuid}:${index}`}
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                latitude={point.latitude}
                longitude={point.longitude}
                item={stopPoints[point.type_uuid]}
                size={size}
            />,
        ];
    }

    getHistoryCoordinates() {
        return _.map(this.props.data.history, (point) => ([
            point.lat,
            point.lng,
        ]));
    }

    renderPointBounds(point, index) {
        const threshold = 199;
        const bounds = L.latLng(point.lat, point.lng).toBounds(threshold);

        const geometry = {
            type: 'Polygon',
            coordinates: [
                [
                    [
                        bounds.getWest(),
                        bounds.getNorth(),
                    ],
                    [
                        bounds.getEast(),
                        bounds.getNorth(),
                    ],
                    [
                        bounds.getEast(),
                        bounds.getSouth(),
                    ],
                    [
                        bounds.getWest(),
                        bounds.getSouth(),
                    ],
                ],
            ],
        };

        return (
            <MapGeojson
                key={`bounds:${index}`}
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                geometry={geometry}
                interactive={false}
            />
        );
    }
}