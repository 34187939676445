import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapMarker from "components/ui/map/base/marker";
import PointIcon from "components/ui/map/markers/icons/point-icon";

@propTypes({
    draggable: PropTypes.bool,
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
})

@defaultProps({
    draggable: true,
    onDrag: () => {
    },
    onDragEnd: () => {
    },
})

export default class PointMarker extends AbstractMapMarker {

    getOptions() {
        return {
            icon: PointIcon.getIcon(),
            riseOnHover: true,
            draggable: this.props.draggable,
        };
    }

    componentWillMount() {
        super.componentWillMount();

        this.getMarker().on('drag', this.props.onDrag);
        this.getMarker().on('dragend', this.props.onDragEnd);
    }

}