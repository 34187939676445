import React, {Component} from 'react';
import {connect} from 'react-redux';
import Moment from 'react-moment';
import {
    getNotifications,
    notificationEventCreated,
    notificationEventRead,
    readNotification
} from 'store/reducers/notifications';

import Page from '../../ui/page';
import Loader from '../../ui/loader';

import './list.less';

let subscribed = false;

@connect(state => ({
    notifications: state.notifications.get('notifications')
}), {getNotifications, readNotification, notificationEventCreated, notificationEventRead})

export default class NotificationsComponent extends Component {
    state = {
        loading: true
    };

    async componentDidMount() {
        this.loadNotifications();

        if (!subscribed) {
            this.props.notificationEventCreated((payload) => {
                this.loadNotifications();
            });

            this.props.notificationEventRead((payload) => {
                this.loadNotifications();
            });

            subscribed = true;
        }
    }

    async loadNotifications() {
        const response = await this.props.getNotifications();
        this.setState({loading: true});

        if (response.isOk) {
            this.setState({loading: false});
        } else {
            response.showErrors();
            this.setState({loading: false});
        }
    }

    render() {
        const loader = this.state.loading ? <Loader color="red" align="center"/> : null;

        return (
            <Page pageId="Main" title="Оповещения">
                {loader}
                {this.props.notifications.map(item => this.renderNotification(item))}
            </Page>
        );
    }

    async markAsRead(item) {
        await this.props.readNotification(item);
    }

    renderNotification(item) {
        return (
            <div key={item.uuid} className="notification-item">
                <div className="icon-button" onClick={(e) => this.markAsRead(item)}>
                    <i className="icon-cancel icon-24"></i>
                </div>

                {item.data.text}

                <div className="notification-item__meta">
                    <span>Код: <strong>{item.code}</strong></span>
                    <span>Источник <strong>{item.source}</strong></span>
                    <span>
            <Moment format="MMMM Do YYYY, h:mm">{item.created_at}</Moment>
          </span>
                </div>
            </div>
        );
    }
}