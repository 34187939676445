import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from 'react-props-decorators';
import _ from 'lodash';

import { connect } from 'react-redux';

import classNames from 'classnames';
import Page from 'components/ui/page';
import BaseEditorFormComponent from 'components/base/base-editor-form';
import BaseEditor from 'components/base/base-editor';
import Block from 'components/ui/form/block';
import Accordion from 'components/ui/accordion/accordion';
import AccordionItem from 'components/ui/accordion/accordion-item';
import DragAndDropModal from 'components/ui/drag-and-drop-modal/drag-and-drop-modal';
import Gallery from 'components/ui/gallery/gallery';
import { getDictionaryList } from 'store/reducers/dictionaries/dictionary';
import { getUnits } from 'store/reducers/organizational_units/units';
import {
	createMultipleTask,
	createTask,
	getTask,
	getTasks,
	getTaskViolations,
	repeatTask,
	updateTask,
	updateTaskCheck
} from 'store/reducers/kurs/tasks';
import { getContract, getContracts } from 'store/reducers/kurs/contracts';
import { getCleanupSpecialists, getUsers } from 'store/reducers/staffing/staffing';
import { User } from 'helpers/user';
import { getVehicles } from 'store/reducers/kurs/vehicles';
import ContextTooltip from 'components/ui/context-tooltip';
import { createTaskTemplate, getTaskTemplate } from 'store/reducers/kurs/task_templates';
import * as alerts from 'helpers/alerts';
import * as storage from 'utils/storage';
import { getLayers } from 'store/reducers/user-map-objects/layers';
import moment from 'moment';
import formats from 'dictionaries/formats';
import { getUserGeoObjects } from 'store/reducers/user-map-objects/object_editor';
import { getLayerObjectsWithoutStore, getLayersObjectsWithoutStore } from 'store/reducers/user-map-objects/layer_list';
import { searchFias } from 'store/reducers/fias/fias';
import { geocode } from 'store/reducers/geo/geocode';
import debounce from 'throttle-debounce/debounce';
import systems from 'dictionaries/systems';
import GlobalLoaderComponent from 'components/ui/global-loader';
import Button from 'components/ui/button';
import PageModalComponent from 'components/ui/page-modal';
import ModalTopMenuButtons from 'components/ui/modal/modal-top-menu-buttons';
import ModalTopMenuButton from 'components/ui/modal/modal-top-menu-button';
import TableContainer from 'components/ui/Table/Container/TableContainer';
import Sortable from 'react-sortablejs';
import UtilityTaskMapEditor from 'components/modules/utility/tasks/TaskMapEditor/index';
import IconButton from 'components/ui/icon-button';
import FilterHeader from 'components/ui/filter-header';
import {
	getRoadPart,
	getRoadParts,
	getRoadPartWorkTypes,
	getRoadPartWorkTypesMultiple,
	getRoadRepairPart
} from 'store/reducers/kurs/road_parts';
import { getStopPoint, getStopPoints } from 'store/reducers/geo/stop-points';
import { api, CycleFetch } from 'helpers/api';
import Tooltip from 'react-tooltip-component';
import { Slider } from 'components/ui/slider';
import currentUser from 'helpers/current-user';
import { getVehicleMechanisms } from 'store/reducers/kurs/vehicle_mechanisms';
import Settings from 'settings';
import { getWaybills } from 'store/reducers/kurs/waybills';
import { getUser } from 'store/reducers/staffing/staffing_editor';
import { State } from 'components/ui/state';
import Input from 'components/ui/form/input';
import { getDocument } from 'store/reducers/dictionaries/editor';
import { Link } from 'react-router';
import TaskItem from 'components/modules/utility/tasks/_elements/task_item';
import Popup from 'components/ui/popup';
import TaskItemUpdateTest from 'components/modules/utility/tasks/_elements/TaskItemUpdateTest';
import LoaderComponent from 'components/ui/loader';
import { itImage } from 'helpers/files';
import {
	getSubjectsDictionarySelector,
	getSubjectsTypeSelector,
	getTaskPriorityAdditionalSelector,
	getTaskPriorityMainSelector
} from '../../../../store/reducers/dictionaries/selectors';

export const layerNamesToDisplay = [
	'Региональные управления автомобильными дорогами (РУАД)',
	'Муниципальные образования (МО)',
	'Типы автомобильных дорог',
	'Бордюрный камень',
	'Земляное полотно',
	'Дорожные знаки',
	'Искусственные неровности',
	'Ливневые канализации',
	'Мосты',
	'Обочины',
	'Дорожное освещение',
	'Тротуары',
	'Площадки отдыха',
	'Светофоры',
	'Снегозащитные сооружения',
	'Съезды, пересечения, примыкания',
	'Тоннели',
	'Трубы',
	'Дренажи',
	'АЗС',
	'Ж/д переезды',
	'Пешеходные переходы',
	'Подпорные стенки',
	'Очаги аварийности',
	'Участки ремонта',
	'Участки дистанции',
	'Парковки'
];

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	uuid: PropTypes.string
})
@connect(
	(state) => ({
		subjectsDictionary: getSubjectsDictionarySelector(state),
		taskType: getSubjectsTypeSelector(state)
	}),
	{
		getTask,
		createTask,
		createMultipleTask,
		updateTask,
		updateTaskCheck,
		createTaskTemplate,
		getContract,
		getDictionaryList
	},
	null,
	{ withRef: true }
)
export default class UtilityTaskEditor extends BaseEditor {
	title = 'задания';
	modelClass = 'App\\Model\\Task';
	modalClassName = 'task-modal';

	state = {
		confirmModeActive: window.RNIS_SETTINGS.hide_confrim_mode ? true : undefined,
		contractFromPlanDaily: {},
		isLoading: true,
		error: false,
		statusNotClose: true
	};

	componentDidMount() {
		this.forceUpdate();
	}

	async componentWillUpdate(props, state) {
		const propsUuid = props.params.uuid === 'create' ? null : props.params.uuid;

		const contractUuidFromPlanDaily = props.location.query.fromPlanDaily ? props.location.query.contractUuid : null;
		const prevContractUuidFromPlanDaily = this.props.location.query.fromPlanDaily
			? this.props.location.query.contractUuid
			: null;

		if (
			state.uuid !== propsUuid ||
			this.props.params.type !== props.params.type ||
			prevContractUuidFromPlanDaily !== contractUuidFromPlanDaily
		) {
			await this.setState({
				uuid: propsUuid,
				item: null,
				isLoading: !!(propsUuid || contractUuidFromPlanDaily),
				mode: propsUuid ? 'edit' : 'add',
				itemIndex: _.toInteger(this.state.itemIndex) + 1,
				saveState: null
			});
			if (propsUuid) {
				const response = await this.loadData(propsUuid);
				if (response.isOk) {
					this.setState({
						item: response.payload,
						isLoading: false
					});
				} else {
					response.showErrors();
				}
			} else if (contractUuidFromPlanDaily) {
				const responseContract = await this.props.getContract(contractUuidFromPlanDaily);

				if (responseContract.isOk) {
					this.setState({
						contractFromPlanDaily: responseContract.payload,
						isLoading: false
					});
				} else {
					responseContract.showErrors();
				}
			}
		}
	}

	async create(data) {
		this.clearErrors();
		this.startSave();

		this.setState({
			formUpdated: false
		});
		const response = await this.createItem(this.composeItem(data));

		this.endSave();
		if (response.isOk) {
			let url;

			if (this.props.location.query.fromPlanDaily) {
				url = `/utility/tasks/${response.payload.items[0].uuid}`;
			} else {
				url = `/utility/tasks/${response.payload.uuid}`;
			}

			if (this.props.location.query.fromSkpdi) {
				url += '?fromSkpdi=1';
			}
			this.props.router.push(url);
		} else {
			this.setState({
				errors: response.validationErrors,
				saveState: false
			});
			response.showErrors();
		}
	}

	async edit(data) {
		this.clearErrors();
		this.startSave();

		this.setState({
			formUpdated: false
		});

		this.checkItem(this.composeItem(data));

		const response = await this.updateItem(this.composeItem(data));
		this.endSave();
		if (response.isOk) {
			//this.onClose();
			this.setState({
				item: response.payload,
				formUpdated: false,
				itemIndex: this.state.itemIndex + 1,
				saveState: true
			});
		} else {
			this.setState({
				errors: response.validationErrors,
				saveState: false
			});
			response.showErrors();
		}
	}

	async checkItem(item) {
		const response = await this.props.updateTaskCheck(item);

		if (!response.isOk) {
			this.setState({
				errors: response.validationErrors
			});
		}
	}

	onClose() {
		const url = this.props.location.query.fromSkpdi
			? '/utility/skpdi'
			: this.props.location.query.ns_id_complaint ? '/utility/complaints' : '/utility/tasks';

		if (this.state.formUpdated) {
			alerts.prompt(
				'Все несохраненные изменения будут утеряны!',
				'',
				() => {
					this.props.router.push(url);
				},
				'Продолжить'
			);
		} else {
			this.props.router.push(url);
		}
	}

	async loadData(uuid) {
		let result = {};
		const response = await this.props.getTask(uuid);
		if (response.isOk) {
			result = response.payload;
			const { time_to, time_from } = response.payload;
			if (time_from && time_from.split(':').length === 3) {
				result.time_from = time_from.split(':').slice(0, 2).join(':');
			}
			if (time_to && time_to.split(':').length === 3) {
				result.time_to = time_to.split(':').slice(0, 2).join(':');
			}
			response.payload = result;
		}
		return response;
	}

	async createItem(data) {
		if (this.props.location.query.fromPlanDaily) {
			return await this.props.createMultipleTask(data);
		}

		if (this.props.location.query.ns_id_complaint) {
			data.complaint_uuid = this.props.location.query.ns_id_complaint;
		}
		return await this.props.createTask(data);
	}

	async updateItem(data) {
		return await this.props.updateTask(data);
	}

	getForm(item, onSubmit) {
		const task = storage.get('utility-task') || {};
		storage.remove('utility-task');

		return (
			<EditorForm
				key={this.state.itemIndex}
				{...this.props}
				ref="form"
				mode={this.state.mode}
				onSubmit={onSubmit}
				onClose={::this.onClose}
				onDataLoad={::this.forceUpdate}
				data={_.isEmpty(item) ? task : item}
				errors={this.state.errors}
				loadTemplate={::this.useTemplate}
				saveAsTemplate={::this.saveAsTemplate}
				gotoMap={::this.gotoMap}
				onUpdate={::this.onFormUpdate}
				confirmModeActive={this.state.confirmModeActive}
				isFactFormErrors={this.isFactFormErrors}
			/>
		);
	}

	onFormUpdate(statusNotClose = true) {
		if (!statusNotClose) {
			this.setState({
				saveState: null,
				formUpdated: false,
				statusNotClose: false
			});
		} else {
			this.setState({
				formUpdated: true,
				saveState: null,
				statusNotClose: true
			});
		}
	}

	gotoMap() {
		if (!this.state.item.is_only_map_allowed) {
			alerts.prompt(
				'При переходе в режим построения маршрута на карте, вы не сможете редактировать его в табличном виде, продолжить?',
				'',
				() => {
					this.gotoMapReal();
				},
				'Продолжить'
			);
		} else {
			this.gotoMapReal();
		}
	}

	gotoMapReal() {
		if (this.state.formUpdated) {
			alerts.prompt(
				'Все несохраненные изменения будут утеряны!',
				'',
				() => {
					this.props.router.push(`/utility/tasks/${this.state.uuid}/map`);
				},
				'Продолжить'
			);
		} else {
			this.props.router.push(`/utility/tasks/${this.state.uuid}/map`);
		}
	}

	composeItem(data) {
		let item = super.composeItem(data);

		item.resources = item.resources || [];
		item.items = item.items || [];
		item.contracts = _.map(item.contracts, (item) => ({
			uuid: item.value || item
		}));

		//NOTE: проблемы с тем, что бэк возвращает время с секундами, а при отправке требует без
		if (item.time_from && item.time_from.split(':').length >= 3) {
			item.time_from = item.time_from.split(':').slice(0, 2).join(':');
		}
		if (item.time_to && item.time_to.split(':').length >= 3) {
			item.time_to = item.time_to.split(':').slice(0, 2).join(':');
		}

		return item;
	}

	useTemplate() {
		const state = this.refs.form.getWrappedInstance().getData();
		storage.set('kurs-task', state);
		this.props.router.push('/utility/task_templates');
	}

	async saveAsTemplate() {
		if (!this.refs.form) return;

		const task = _.cloneDeep(this.refs.form.getWrappedInstance().getData());
		delete task.uuid;

		task.contracts = _.map(task.contracts, (contract) => {
			return contract.value ? { uuid: contract.value } : { uuid: contract };
		});

		alerts.ask('Введите наименование шаблона:', async (name) => {
			this.startSave();
			const response = await this.props.createTaskTemplate({
				name,
				task
			});
			this.endSave();

			if (response.isOk) {
				alerts.success('Шаблон сохранен');
			} else {
				response.showErrors();
				if (response.validationErrors['task_template.name']) {
					alerts.error('Наименование обязательно для заполнения');
				}
			}
		});
	}

	getDefaultItem() {
		let item = {
			confirm_by_single: true
		};

		const date = this.props.location.query.date;
		if (date) {
			item.date = date;
		}

		const contractUuid = this.props.location.query.contractUuid;
		if (date) {
			item.contracts = [ contractUuid ];
		}
		const unitUuid = this.props.location.query.unitUuid;
		if (unitUuid) {
			item.unit_uuid = unitUuid;
		}

		const roadUuid = this.props.location.query.roadUuid;
		const workTypeUuid = this.props.location.query.workTypeUuid;
		if (roadUuid && workTypeUuid) {
			item.items = [
				{
					work_type_uuid: workTypeUuid,
					movement_type: 'work',
					geometry_type: 'road_part',
					geometry: [
						{
							item_uuid: roadUuid,
							direction: 'forward'
						}
					]
				}
			];
		}

		if (!this.props.location.query.fromPlanDaily && workTypeUuid && this.props.location.query.roadsUuids) {
			const roadsUuids = JSON.parse(this.props.location.query.roadsUuids);

			item.items = roadsUuids.map((roadUuid) => ({
				work_type_uuid: workTypeUuid,
				movement_type: 'work',
				geometry_type: 'road_part',
				geometry: [
					{
						item_uuid: roadUuid,
						direction: 'forward'
					}
				]
			}));
		}

		if (window.RNIS_SETTINGS.task_with_time) {
			item.time_from = '00:00';
			item.time_to = '23:59';
		}

		if (this.props.location.query.fromPlanDaily) {
			const contract = this.state.contractFromPlanDaily;
			const workTypeFromContract =
				(contract.works || []).find((work) => {
					return work.work_type_uuid === this.props.location.query.workTypeUuid;
				}) || {};

			if (
				workTypeFromContract.season &&
				(contract[`${workTypeFromContract.season}_from`] || contract[`${workTypeFromContract.season}_to`])
			) {
				item.date_from = contract[`${workTypeFromContract.season}_from`];
				item.date_to = contract[`${workTypeFromContract.season}_to`];
			} else {
				if (contract.date_from) item.date_from = contract.date_from;
				if (contract.date_to) item.date_to = contract.date_to;
			}

			item.contracts = [ { uuid: this.props.location.query.contractUuid } ];
			item.work_type_uuid = this.props.location.query.workTypeUuid;
			item.period_uuid = workTypeFromContract.period_uuid;
			item.period_value = workTypeFromContract.period_value;
			item.unit_uuid = contract.implementer_uuid;
			item.items = [];

			const roadsUuids = JSON.parse(this.props.location.query.roadsUuids);
			const geoObjectsData = JSON.parse(this.props.location.query.geoObjectsData);
			let roadsUuidsRoadParts = []

			/*roadsUuids.forEach((roadUuid) => {
				item.items.push({
					movement_type: 'work',
					geometry_type: 'road_part',
					date_from: '00:00',
					date_to: '23:59',
					time: '23:59',
					work_type_uuid: this.props.location.query.workTypeUuid,
					geometry: [
						{
							item_uuid: roadUuid
						}
					]
				});
			});*/

			roadsUuids.map((roadUuid) => {
				let found = false
				geoObjectsData.map((el) => {
					if(el.geoObject.uuid == roadUuid) {
						found = true

					}
				})
				if (!found) {
					roadsUuidsRoadParts.push(roadUuid)
				}
			})

			roadsUuidsRoadParts.forEach((roadUuid) => {
				item.items.push({
					movement_type: 'work',
					geometry_type: 'road_part',
					date_from: '00:00',
					date_to: '23:59',
					time: '23:59',
					work_type_uuid: this.props.location.query.workTypeUuid,
					geometry: [{
						item_uuid: roadUuid,
					}]
				})
			})

			geoObjectsData.forEach((geoObject) => {
				item.items.push({
					movement_type: 'work',
					geometry_type: 'user_geo_object',
					date_from: '00:00',
					date_to: '23:59',
					time: '23:59',
					work_type_uuid: this.props.location.query.workTypeUuid,
					geometry: [{
						item_uuid: geoObject.geoObject.uuid,
						layer_uuid: geoObject.geoObject.layer_uuid
					}]
				})
			})



		}

		return item;
	}

	isFactFormErrors = (field, index, task) => {
		const currentTask = task[field][index]
		const count = parseInt(currentTask.count)
		const factCount = parseInt(currentTask.fact_count)

		return count < factCount ? 'Факт больше плана' : false
	};

	render() {
		let title = this.getTitle() ? `Добавление ${this.getTitle()}` : '';

		let form = null;
		const loader = this.state.isLoading || this.state.saving ? <GlobalLoaderComponent /> : null;
		let onSubmit;
		if (this.state.mode === 'edit') {
			title = this.getTitle() ? `Редактирование ${this.getTitle()}` : '';

			if (!this.state.isLoading && this.state.item) {
				form = this.getForm(this.state.item, ::this.edit);
				onSubmit = ::this.onEdit;
			}
		} else if (!this.state.isLoading && this.state.mode === 'add') {
			form = this.getForm(this.getDefaultItem(), ::this.create);
			onSubmit = ::this.onCreate;
		}

		return (
			<Page
				pageId="PlanningWorkEditor"
				title={`${systems.utility} → ${title}`}
				headerActions={this.renderHeaderActions()}
				className={this.state.formUpdated ? '_save' : ''}
			>
				{loader}
				{form}
				{this.state.formUpdated ? (
					<div className="page-footer">
						<div className="page-footer__txt">Вы хотите сохранить все изменения?</div>
						<Button
							size="md"
							color="white"
							shadow="gray"
							className="b-button_cancel"
							text="Отменить"
							onClick={::this.onReset}
						/>
						<Button size="md" color="red" className="b-button_save" text="Сохранить" onClick={onSubmit} />
					</div>
				) : null}
				{!this.state.statusNotClose ? (
					<div className="page-footer">
						<div className="page-footer__txt">Статус задачи - "Закрыт". Обновление запрещено.</div>
						<Button
							size="md"
							color="white"
							shadow="gray"
							className="b-button_cancel"
							text="Отменить"
							onClick={::this.onReset}
						/>
					</div>
				) : null}
				{this.props.params.type === 'map' ? (
					<KursTaskMapEditor
						{...this.props}
						key="editor"
						onClose={this.closeMap.bind(this, false)}
						onSubmit={this.closeMap.bind(this, true)}
						mode={this.state.mode}
						uuid={this.props.params.uuid}
					/>
				) : null}
			</Page>
		);
	}

	renderHeaderActions() {
		if (this.props.params.type === 'map') {
			return [];
		}
		return [
			this.renderSaveState(),
			this.state.mode === 'edit' && currentUser.can('com.rnis.system.permission.audit', 'read') && this.modelClass ? (
				<ContextTooltip key="base-editor.audit" code="base-editor.audit" default="Журнал аудита">
					<IconButton icon="history" onClick={::this.gotoAudit} />
				</ContextTooltip>
			) : null,
			<ContextTooltip key="kurs.task.back" code="kurs.task.back" default="Назад">
				<IconButton icon="back-0" onClick={::this.onClose} />
			</ContextTooltip>,
			!window.RNIS_SETTINGS.hide_confrim_mode ? (
				<ContextTooltip
					key="kurs.task.confirm"
					code="kurs.task.confirm"
					isEdge={true}
					default="Включить/выключить режим подтверждения"
				>
					<IconButton icon={this.state.confirmModeActive ? 'confirm' : 'check'} onClick={::this.toggleConfirm} />
				</ContextTooltip>
			) : null
		];
	}

	renderSaveState() {
		if (this.state.saveState === true) {
			return <div className="changes changes-success">Изменения сохранены</div>;
		}
		if (this.state.saveState === false) {
			return <div className="changes changes-fail">Изменения не сохранены</div>;
		}

		return null;
	}

	gotoAudit() {
		const url = `/system/audit/${this.state.uuid}?class=${this.modelClass}`;

		this.props.router.push(url);
	}

	toggleConfirm() {
		this.setState({
			confirmModeActive: !this.state.confirmModeActive
		});
	}

	onReset() {
		this.refs.form.getWrappedInstance().onReset();
		this.setState({
			formUpdated: false,
			statusNotClose: true
		});
	}

	closeMap(reload = false) {
		this.props.router.push(`/utility/tasks/${this.props.params.uuid}`);
		reload && this.loadData(this.props.params.uuid);
	}
}

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	data: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDataLoad: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	errors: PropTypes.object
})
@connect(
	(state) => ({
		task_priority_main: getTaskPriorityMainSelector(state),
		task_priority_additional: getTaskPriorityAdditionalSelector(state)
	}),
	{
		getDictionaryList,
		getDocument,
		getUnits,
		getContracts,
		getUsers,
		getCleanupSpecialists,
		getUser,
		getVehicles,
		getLayers,
		getUserGeoObjects,
		getLayerObjectsWithoutStore,
		getLayersObjectsWithoutStore,
		searchFias,
		geocode,
		getRoadParts,
		getStopPoints,
		getRoadPart,
		getStopPoint,
		getTask,
		getTasks,
		repeatTask,
		getRoadPartWorkTypes,
		getRoadPartWorkTypesMultiple,
		getTaskTemplate,
		getVehicleMechanisms,
		getWaybills,
		getRoadRepairPart,
		getTaskViolations
	},
	null,
	{ withRef: true }
)
class EditorForm extends BaseEditorFormComponent {
	state = {
		task: {},
		communal_label: '',
		communal_municipality_uuid: 0,
		units: [],
		communal_municipalities: [],
		contracts: [],
		contracts_fact: [],
		kurs_task_statuses: [],
		work_types: [],
		measures: [],
		control_work: [],
		road_part_work_types: {},
		all_road_part_work_types: {},
		vehicle_types: [],
		layers: [],
		userGeoObjectsByLayers: {},
		kurs_violation_types: [],
		expandedBlock: null,
		expandedBlocks: [],
		vehicleWorkTypes: null,
		waybills: [],
		violations: [],
		inited: false,
		repeat: {
			interval: 1,
			interval_type: 'day',
			end_type: 'date',
			month_repeat_type: 'on-date',
			days: [ 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday' ]
		},
		recommendedCount: { human: 0, vehicle: 0 },
		road_parts: null,
		reload: [],
		attachments: [],
		attachmentsNotSave: [],
		visibleGalleryAttaches: false,
		visibleAttachesUpload: false
	};

	days = [ 'воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота' ];

	_cycleFetch = null;

	startLiveReload() {
		if (!this.props.data.uuid) {
			return;
		}

		setTimeout(() => {
			this._cycleFetch = new CycleFetch(
				() => {
					return this.reloadTask();
				},
				() => {},
				60000
			);
			this._cycleFetch.run();
		}, 60000);
	}

	stopLiveReload() {
		if (this._cycleFetch) {
			this._cycleFetch.stop();
			delete this['_cycleFetch'];
		}
	}

	async reloadTask() {
		const response = await this.props.getTask(this.props.data.uuid);

		if (response.isOk) {
			let task = response.payload;
			task.contracts = _.map(task.contracts || [], 'uuid');

			await this.setState({
				task
			});
		}
	}

	componentWillUnmount() {
		this.stopLiveReload();
	}

	componentDidMount() {
		const newTasks = {
			...this.state.task,
			email: currentUser.user.info.email, // set default emeil and name
			userName: currentUser.user.info.surname + ' ' + currentUser.user.info.name
		};

		this.setState({ task: newTasks });
	}

	addressSearchDebounce = debounce(500, ::this.searchAddress);

	getData() {
		return this.state.task;
	}

	async getCommunal(contractUuid) {
		let communalUuidFromContract = this.props.data.contracts
			? this.props.data.contracts[0].communal_municipality_uuid
			: null;
		if (contractUuid) {
			communalUuidFromContract = contractUuid;
		}

		if (this.props.data.contracts && this.props.data.contracts[0].uuid) {
			const responseContract = await this.props.getContract(this.props.data.contracts[0].uuid);
			if (responseContract.isOk) {
				communalUuidFromContract = responseContract.payload.communal_municipality_uuid;
			}
		}

		if (communalUuidFromContract && this.state.communal_municipalities && this.state.communal_municipalities.length) {
			const relevantCommunal = this.state.communal_municipalities.filter(
				(el) => el.value === communalUuidFromContract
			)[0];
			const label = relevantCommunal.label ? relevantCommunal.label : '';
			const implementItems = this.state.task.items.map((el) => {
				el.communal_municipality_uuid = communalUuidFromContract;
				return el;
			});
			await this.setState((prevState) => ({
				task: {
					...prevState.task,
					items: implementItems,
					communal_municipality_uuid: communalUuidFromContract
				},
				communal_municipality_uuid: communalUuidFromContract,
				communal_label: label
			}));
		}
	}

	onReset() {
		this.setState({
			task: _.cloneDeep(this.props.data)
		});
	}

	async componentWillMount() {
		let task = _.cloneDeep(this.props.data);
		const currentYear = new Date().getFullYear();
		task.date_from = moment(task.date_from).set('year', currentYear);
		task.date_to = moment(task.date_to).set('year', currentYear);
		if (this.props.location.query.template_uuid) {
			task = await this.loadTemplate(this.props.location.query.template_uuid);
		}
		task.contracts = _.map(task.contracts || [], 'uuid');
		let contracts_fact = _.map(this.props.data.contracts || [], 'name');
		await this.setState({
			task,
			contracts_fact
		});
		this.checkVehicleWorkTypes();

		// зачем периодически запрашивать контракт ???? RNISO-73
		// this.startLiveReload();

		this.loadDictionaries([ 'kurs_task_statuses' ]).then(() => {
			if (!this.get('status_uuid')) {
				this.setValue(
					'task.status_uuid',
					_.get(
						_.find(this.state.kurs_task_statuses, {
							label: 'Черновик'
						}),
						'value'
					)
				);
			}
		});

		if (window.RNIS_SETTINGS.is_need_communal_municipality) {
			await this.loadDictionaries([ 'communal_municipalities' ]);
		}

		const directories = [
			'work_types',
			'measures',
			'vehicle_types',
			'kurs_violation_types',
			'kurs_mechanism_bindings',
			'kurs_mechanism_types',
			'kurs_mechanism_uses',
			'work_graphics',
			'task_type',
			'task_priority_additional',
			'task_priority_main',
			'subjects'
		];

		if (window.RNIS_SETTINGS.graphic_with_periodicity) {
			directories.push('periods');
		}

		await this.loadDictionaries(directories, 'utility', true).then(async () => {
			await this.loadVehicleWorkTypes();

			/*if (this.isEditable('resources')) {
                this.checkResources();
            }*/

			this.setState({
				inited: true
			});
		});
		this.loadUnits();
		this.loadContracts();
		this.loadLayers();

		this.loadTaskViolations();

		this.preloadRoadPartWorkTypes();

		this.loadWaybills();

		this.computeRecommendCount(this.state.items);
		this.getCommunal();
	}

	async loadWaybills() {
		const uuid = this.get('uuid');
		if (!uuid) {
			return;
		}

		const response = await this.props.getWaybills({
			filters: {
				withTask: uuid
			}
		});

		if (response.isOk) {
			this.setState({
				waybills: response.payload.items
			});
		} else {
			response.showErrors();
		}
	}

	async loadTemplate(templateUuid) {
		const response = await this.props.getTaskTemplate(templateUuid);

		if (response.isOk) {
			const taskTemplate = response.payload.task;
			let task = {};
			task.unit_uuid = taskTemplate.unit_uuid;
			task.contracts = taskTemplate.contracts;
			task.responsive_uuid = taskTemplate.responsive_uuid;
			task.items = taskTemplate.items;
			task.communal_municipality_uuid = taskTemplate.communal_municipality_uuid;

			this.setState({
				communal_municipality_uuid: taskTemplate.communal_municipality_uuid,
			});

			return task;
		} else {
			response.showErrors();
		}
	}

	async loadUnits() {
		const response = await this.props.getUnits({
			pagination: {
				page: 1,
				limit: 1000
			},
			filters: {
				withComponent: 'utility'
			}
		});

		if (response.isOk) {
			await this.setState({
				units: _.map(response.payload.items, (unit) => ({
					value: unit.uuid,
					label: unit.name
				}))
			});
			if (this.get('unit_uuid') && !_.find(this.state.units, { value: this.get('unit_uuid') })) {
				this.setValue('task.unit_uuid', null);
			}
		} else {
			response.showErrors();
		}
	}

	async loadContracts() {
		const response = await this.props.getContracts();

		if (response.isOk) {
			this.setState({
				contracts: _.map(response.payload.items, (contract) => ({
					value: contract.uuid,
					label: contract.name,
					implementer_uuid: contract.implementer_uuid,
					subcontractors: contract.subcontractors
				}))
			});
		} else {
			response.showErrors();
		}

		const relevantCommunal = this.state.communal_municipalities.filter(
			(el) => el.value === this.state.communal_municipality_uuid
		)[0];

		const label = relevantCommunal.label ? relevantCommunal.label : '';

		this.setState({
			communal_label: label,
		});
	}

	async loadVehicleWorkTypes() {
		const vehicleUuids = _.map(this.get('resources') || [], 'base_vehicle_uuid');
		if (vehicleUuids.length === 0) {
			this.setState({
				vehicleWorkTypes: null
			});
			return;
		}

		const response = await this.props.getVehicleMechanisms({
			filters: {
				withVehicle: vehicleUuids,
				onlyActive: true
			}
		});
		if (response.isOk) {
			const mechanismUuids = _.map(response.payload.items, 'mechanism_model_uuid');
			if (mechanismUuids.length === 0) {
				this.setState({
					vehicleWorkTypes: null
				});
				return;
			}

			const mechanismBindings = _.map(
				_.filter(this.state.kurs_mechanism_bindings, (binding) => {
					return _.indexOf(mechanismUuids, binding.document.uuid) !== -1;
				}),
				'document'
			);

			const workTypeUuids = _.uniq(
				_.flatten(
					_.map(
						_.filter(this.state.kurs_mechanism_uses, (mechanismUse) => {
							return (
								_.filter(mechanismBindings, {
									mechanism_type_uuid: mechanismUse.document.mechanism_type_uuid
								}).length > 0
							);
						}),
						(item) => {
							return JSON.parse(_.get(item, 'document.work_types') || '[]');
						}
					)
				)
			);
			this.setState({
				vehicleWorkTypes: workTypeUuids
			});
		} else {
			response.showErrors();
		}
	}

	async loadLayers() {
		const response = await this.props.getLayers();

		if (response.isOk) {
			this.setState({
				layers: _.sortBy(
					_.filter(
						_.map(response.payload.items, (layer) => {
							return {
								value: layer.uuid,
								label: layer.title
							};
						})
					),
					'label'
				)
			});

			const task = this.getState().task;
			const userGeoObjectsUuids = [];
			(task.items || []).forEach((item) => {
				if (item.geometry_type === 'user_geo_object'
					&& item.geometry[0].item_uuid
					&& !item.geometry[0].layer_uuid // !!! зачем здесь это условие?
				) {
					userGeoObjectsUuids.push(item.geometry[0].item_uuid)
				}

				// Нужно загружать, даже если есть layer_uuid
				if(item.geometry_type === 'user_geo_object'
					&& item.geometry[0].item_uuid
					&& item.geometry[0].layer_uuid) {
					userGeoObjectsUuids.push(item.geometry[0].item_uuid)
				}


			});

			(task.items_fact || []).forEach((item) => {
				if (item.geometry_type === 'user_geo_object'
					&& item.geometry[0].item_uuid
					&& !item.geometry[0].layer_uuid
				) {
					userGeoObjectsUuids.push(item.geometry[0].item_uuid)
				}
			});


			if (!userGeoObjectsUuids.length) {
				return;
			}

			const responseObjects = await this.props.getUserGeoObjects({
				filters: {
					withUuid: userGeoObjectsUuids
				}
			});

			const userGeoObjectsByLayers = {};

			if (responseObjects.isOk) {
				(task.items || []).forEach((item) => {
					if (item.geometry_type === 'user_geo_object'
						&& item.geometry[0].item_uuid
						// !!! Добавлена загрузка слоев, даже если есть слой - для подгрузки в селект
						&& item.geometry[0].layer_uuid) {
						item.geometry[0].layer_uuid = responseObjects.payload.items.find(
							(userGeoObject) => userGeoObject.uuid === item.geometry[0].item_uuid
						).layer_uuid;
						userGeoObjectsByLayers[item.geometry[0].layer_uuid] = [];
					}
				});

				(task.items_fact || []).forEach((item) => {
					if (item.geometry_type === 'user_geo_object'
						&& item.geometry[0].item_uuid
						&& !item.geometry[0].layer_uuid) {
						item.geometry[0].layer_uuid = responseObjects.payload.items.find(
							(userGeoObject) => userGeoObject.uuid === item.geometry[0].item_uuid
						).layer_uuid;
						userGeoObjectsByLayers[item.geometry[0].layer_uuid] = [];
					}
				});
			} else {
				responseObjects.showErrors();
			}

			const responseObjectsByLayers = await this.props.getLayersObjectsWithoutStore(
				Object.keys(userGeoObjectsByLayers)
			);

			if (responseObjectsByLayers.isOk) {
				responseObjectsByLayers.payload.items.map((ugo) => {
					userGeoObjectsByLayers[ugo.layer_uuid].push({
						value: ugo.uuid,
						label: ugo.title,
						document: ugo
					});
				});
			} else {
				responseObjectsByLayers.showErrors();
			}

			this.setState({
				task,
				userGeoObjectsByLayers
			});
		} else {
			response.showErrors();
		}
	}

	get(path, defaultValue = null) {
		return _.get(this.state.task, path, defaultValue);
	}

	gotoHistory() {
		this.props.router.push(`/system/audit/${this.get('uuid')}?class=App\Model\Task`);
	}

	getContracts() {
		const uuid = this.get('unit_uuid');

		return _.filter(this.state.contracts, (contract) => {
			if (contract.implementer_uuid === uuid) {
				return true;
			}
			if (_.indexOf(contract.subcontractors, uuid) !== -1) {
				return true;
			}
			return false;
		});
	}

	async getCleanupSpecialists(index, input, callback) {
		let currentUuid;
		if (!input) {
			currentUuid = this.get(`cleanup_specialist_uuids.${index}`);
		}

		const time_from = this.state.task.time_from.split(':');
		const time_to = this.state.task.time_to.split(':');

		const date_from = moment(this.state.task.date);
		date_from.set({ hour: time_from[0], minute: time_from[1] });
		const date_to = moment(this.state.task.date);
		date_to.set({ hour: time_to[0], minute: time_to[1] });

		const driver = await this.getDriver(currentUuid);

		const result = await this.props.getCleanupSpecialists({
			filters: {
				withWorkPeriod: [ date_from.format(), date_to.format() ]
			},
			search: input,
			pagination: {
				page: 1,
				limit: 10000
			}
		});

		if (result.isOk) {
			if (!result.payload.items.length) {
				callback(null, {
					options: [],
					complete: false
				});
				return;
			}

			let options = _.sortBy(
				result.payload.items.map((i) => ({
					label: new User(i).getFullName(),
					value: i.uuid
				})),
				'label'
			);
			if (driver.info) {
				options.push({
					label: new User(driver).getFullName(),
					value: currentUuid
				});
			}
			callback(null, {
				options: options,
				complete: false
			});
		} else {
			callback(null, {
				options: [],
				complete: false
			});
			result.showErrors();
		}
	}

	async getContractsLite(input, callback) {
		/* if (!input) {
            const uuid = _.map(this.state.vehicle.bnso, 'bnso_uuid');
            return this.loadCurrentBnso(uuid, callback);
        }*/

		if (!input) {
			input = this.get('task.contracts') || _.get(this.props.data, 'task.contracts');
		}

		let result = await this.props.getContracts({
			search: input,
			filters: {
				onlyActive: true,
				withImplementer: [ this.get('unit_uuid') ]
			},
			pagination: {
				page: 1,
				limit: 25
			}
		});

		if (result.isOk) {
			const uuid = this.get('unit_uuid');
			let a = result.payload.items.filter((contract) => {
				if (contract.implementer_uuid === uuid || _.indexOf(contract.subcontractors, uuid) !== -1) {
					return contract;
				}
			});
			callback(null, {
				options: _.sortBy(a.map((i) => ({ label: i.name, value: i.uuid })), 'label'),
				complete: false
			});
		} else {
			result.showErrors();
		}
	}

	showRepeat() {
		this.setState({
			repeatActive: true
		});
	}

	hideRepeat() {
		this.setState({
			repeatActive: false
		});
	}

	async onMultiSelectAsyncChange(fieldName, e) {
		if (fieldName === 'task.contracts') {
			if (Array.isArray(e) && e.length) {
				const responseContract = await this.props.getContract(e[0].value);
				if (responseContract.isOk) {
					this.getCommunal(responseContract.payload.communal_municipality_uuid);
				}
			}
		}
		this.onChangeInput(fieldName, { target: { value: e } });
	}

	test() {
		let oldNames = _.map(
			_.filter(this.state.contracts, (contract) => {
				return _.indexOf(this.get('contracts'), contract.value) !== -1;
			}),
			'label'
		).join(', ');

		let newNames = this.state.contracts_fact.join(', ');

		return oldNames.length ? oldNames : newNames;
	}

	computeRecommendCount(items) {
		function reduceCountBySameWorkTypeUuid(items) {
			if (items) {
				return items.reduce((acc, val) => {
					if (!acc[val.work_type_uuid]) {
						acc[val.work_type_uuid] = +val.count;
						return acc;
					} else {
						acc[val.work_type_uuid] = acc[val.work_type_uuid] + +val.count;
						return acc;
					}
				}, {});
			} else {
				return {};
			}
		}

		function calculateCountRecommend(summary_counts, work_types) {
			let human = 0;
			let vehicle = 0;
			if (Object.keys(summary_counts).length) {
				Object.keys(summary_counts).forEach((itemUuid) => {
					const currentWorkType = work_types.filter((work) => work.value === itemUuid)[0].document;
					const count = summary_counts[itemUuid];

					if (currentWorkType.norm_human && currentWorkType.norm_vehicle) {
						human = +currentWorkType.norm_human / count;
						vehicle = +currentWorkType.norm_vehicle / count;
						return true;
					}
					if (currentWorkType.norm_human) {
						human = +currentWorkType.norm_human / count;
						return true;
					}
					if (currentWorkType.norm_vehicle) {
						vehicle = +currentWorkType.norm_vehicle / count;
						return true;
					}
				});
			}

			return { human: Math.ceil(human), vehicle: Math.ceil(vehicle) };
		}

		this.setState({
			recommendedCount: calculateCountRecommend(reduceCountBySameWorkTypeUuid(items), this.state.work_types)
		});
	}

	contractsDebounce = debounce(500, ::this.getContractsLite);

	cleanupSpecialistsDebounce = [];

	render() {
		const status = _.get(
			_.find(this.state.kurs_task_statuses, {
				value: _.get(this.props.data, 'status_uuid')
			}),
			'label'
		);
		const loader = !this.state.inited ? <LoaderComponent color="red" /> : null;

		return (
			<div className="block-mh">
				<Accordion>
					<AccordionItem
						opened={true}
						title={`Задание №${this.get('number', '-')}`}
						afterTitle={
							<div className="accordion__menu accordion__menu_right">
								<Tooltip title={this.get('confirm_by_single') ? 'Зачет по 1 ТС' : 'Зачет по всем ТС'} position="bottom">
									<Slider
										selected={this.get('confirm_by_single') ? 1 : 0}
										total={1}
										selectAll={() =>
											this.isEditable('confirm_by_single') && this.setValue('task.confirm_by_single', true)}
										deselectAll={() =>
											this.isEditable('confirm_by_single') && this.setValue('task.confirm_by_single', false)}
										clearAfter={false}
									/>
								</Tooltip>
								<ContextTooltip key="kurs.load-template" code="kurs.load-template" default="Загрузить из шаблона">
									<div
										className="accordion__link accordion__link_icon accordion__link_icon_load-pattern"
										onClick={this.props.loadTemplate}
									/>
								</ContextTooltip>
								<ContextTooltip key="kurs.save-template" code="kurs.save-template" default="Сохранить как шаблон">
									<div
										className="accordion__link accordion__link_icon accordion__link_icon_add-pattern"
										onClick={this.props.saveAsTemplate}
									/>
								</ContextTooltip>
								{this.get('uuid') ? (
									<ContextTooltip key="kurs.task.map" code="kurs.task.map" default="Режим карты">
										<div
											className="accordion__link accordion__link_icon accordion__link_icon_map"
											onClick={this.props.gotoMap}
										/>
									</ContextTooltip>
								) : null}
								<div className="accordion__menu-separator" />
							</div>
						}
					>
						<Block title="Номер">
							{this.textInput('task.number', {
								disabled: true
							})}
						</Block>
						<Block title="Предприятие">
							{this.isEditable('unit_uuid') && !this.props.location.query.fromPlanDaily ? (
								this.select('task.unit_uuid', this.state.units)
							) : (
								_.get(
									_.find(this.state.units, {
										value: this.get('unit_uuid')
									}),
									'label'
								)
							)}
						</Block>

						{/* <Block title={window.RNIS_SETTINGS.rename_contracts ? "Подрядное обязательство" : "Контракт"}>
                            {this.isEditable('contracts') ? this.select('task.contracts', this.getContracts(), {
                                multi: true,
                            }) : this.test()}
                        </Block>*/}

						<Block title={window.RNIS_SETTINGS.rename_contracts ? 'Подрядное обязательство' : 'Контракт'}>
							{this.isEditable('contracts') && !this.props.location.query.fromPlanDaily ? (
								this.selectAsync(`task.contracts`, ::this.contractsDebounce, {
									multi: true,
									onChange: this.onMultiSelectAsyncChange.bind(this, `task.contracts`)
								})
							) : (
								this.test()
							)}
						</Block>

						<Block title="Статус">
							{_.indexOf([ 'В работе', 'На рассмотрении', 'Закрыт' ], status) === -1 ? (
								this.select(
									'task.status_uuid',
									_.filter(this.state.kurs_task_statuses, (status) => {
										return _.indexOf([ 'Черновик', 'Открыт' ], status.label) !== -1;
									})
								)
							) : (
								status
							)}
						</Block>
						<Block title="Ответственный">
							{this.selectAsync('task.responsive_uuid', ::this.loadResponsives, {
								disabled: !this.isEditable('responsive_uuid')
							})}
						</Block>
						{this.props.location.query.fromPlanDaily ? (
							<span>
								<Block title="Дата с" size="sm">
									{this.datepicker('task.date_from', {
										disableYears: true,
										disabled: !this.isEditable('date_from')
									})}
								</Block>
								<Block title="Дата по" size="sm">
									{this.datepicker('task.date_to', {
										disableYears: true,
										disabled: !this.isEditable('date_to')
									})}
								</Block>
							</span>
						) : (
							<Block title="Дата" size="sm">
								{this.datepicker('task.date', {
									disabled: !this.isEditable('date')
								})}
							</Block>
						)}
						{window.RNIS_SETTINGS.task_with_time ? (
							<Block size="sm" title="Время с">
								{this.maskInput('task.time_from', '99:99', {
									withTimeIcon: true
								})}
							</Block>
						) : null}
						{window.RNIS_SETTINGS.task_with_time ? (
							<Block size="sm" title="Время по">
								{this.maskInput('task.time_to', '99:99', {
									withTimeIcon: true
								})}
							</Block>
						) : null}
						{this.props.location.query.fromPlanDaily ? (
							<span>
								<Block size="sm" title="Период">
									{this.textInput('task.period_value')}
								</Block>
								<Block size="sm" title="Вид периода">
									{this.select('task.period_uuid', this.state.periods)}
								</Block>
							</span>
						) : null}
						{this.get('uuid') && this.get('responsive_uuid') ? (
							<Block size="sm">
								<Button
									size="md"
									color="red"
									shadow="red"
									width="full"
									className="b-button_block-height"
									text="Повтор"
									onClick={::this.showRepeat}
								/>
							</Block>
						) : null}
						{!window.RNIS_SETTINGS.hide_road_tasks_create_skpdi ? (
							<Block size="sm" title="СКПДИ">
								{this.textInput('task.external_id', {
									disabled: true
								})}
							</Block>
						) : null}
						{this.props.location.query.fromPlanDaily ? (
							<Block title="Вид работ">
								{!this.props.location.query.fromPlanDaily ? (
									this.select('task.work_type_uuid', this.state.work_types)
								) : (
									_.get(
										_.find(this.state.work_types, {
											value: this.get('work_type_uuid')
										}),
										'label'
									)
								)}
							</Block>
						) : null}
						<Block size="sm" title="Рекомендуемое кол-во ТС">
							<div className="amount-block">{this.state.recommendedCount.vehicle}</div>
						</Block>
						<Block size="sm" title="Техника и водители">
							<div className="amount-block">Выбрано: {this.get('resources', []).length} ТС</div>
						</Block>
						<Block>
							<Button
								size="md"
								color="red"
								shadow="red"
								width="full"
								className="b-button_block-height"
								text="Добавить/редактировать ТС"
								onClick={::this.showResources}
							/>
						</Block>
						{window.RNIS_SETTINGS.cleanup_specialist_for_task ? (
							<span>
								<Block size="sm" title="Рекомендуемое кол-во СПУ">
									<div className="amount-block">{this.state.recommendedCount.human}</div>
								</Block>
								<Block size="sm" title="Счётчик СПУ">
									<div className="amount-block">Выбрано: {this.get('cleanup_specialist_uuids', []).length} СПУ</div>
								</Block>
								<Block>
									<Button
										size="md"
										color="red"
										shadow="red"
										width="full"
										className="b-button_block-height"
										text="Добавить/редактировать СПУ"
										onClick={::this.showCleanupSpecialists}
									/>
								</Block>
							</span>
						) : null}
						{window.RNIS_SETTINGS.CITY_MURMANSK ? (
							<Block key="communal_municipality" title="Муниципальное образование">
								{this.textInput('communal_label', {
									disabled: true
								})}
							</Block>
						) : null}
					</AccordionItem>
					{window.RNIS_SETTINGS.CITY_MURMANSK ? (
						<AccordionItem title={`Дополнительная информация`}>
							<div>
								<Block size="md" title="ФИО">
									{this.textInput(
										'task.userName',
										{
											// disabled: true
										}
									)}
								</Block>
								<Block size="md" title="Адрес эл-ной почты">
									{this.textInput(
										'task.email',
										{
											// disabled: true
										}
									)}
								</Block>
								<Block title="Номер телефона">{this.maskInput('task.phone', '+7 (999) 999-99-99')}</Block>

								<Block size="md" title="Приоритет">
									{this.select('task.priority_main_uuid', this.props.task_priority_main)}
								</Block>
								<Block size="md" title="Приоритет для подрядчика">
									{this.select('task.priority_additional_uuid', this.props.task_priority_additional)}
								</Block>
								<Block size="md" title="Тема">
									{this.select('task.subject_uuid', this.props.subjectsDictionary)}
								</Block>
								<Block title="Дата файла" size="sm">
									{this.datepicker(
										'task.file_date',
										{
											// disabled: !this.isEditable('date')
										}
									)}
								</Block>
								<Block size="sm" title="Тип задания">
									{this.select('task.task_type_uuid', this.props.taskType)}
								</Block>
								<Block size="xl" title="Описание">
									{this.textarea(`task.description`, {
										delayed: true
									})}
								</Block>

								<Block size="xl" title="Вложения">
									<Block size="md">
										<Button
											color="red"
											shadow="red"
											width="full"
											className="b-button_block-height"
											text="Добавить изображения"
											onClick={() => ::this.showAttachesUpload()}
										/>
										<span className="files_state_msg_wrong">{this.getError(`task.attaches`)}</span>
									</Block>

									<Block size="md">
										<Button
											color="red"
											shadow="red"
											width="full"
											className="b-button_block-height"
											text="Посмотреть изображения"
											onClick={() => ::this.showGalleryAttaches()}
											disabled={!this.state.attachments || !this.state.attachments.length}
										/>
									</Block>
									{this.state.visibleAttachesUpload ? this.renderGalleryAttachesUpload() : null}
									{this.state.visibleGalleryAttaches ? this.renderGalleryAttaches() : null}
								</Block>
							</div>
						</AccordionItem>
					) : null}
				</Accordion>
				<div className="page-block">
					{loader}
					{!loader && this.isFactVisible() ? (
						[
							<FilterHeader
								key="filter_header"
								items={[ 'Факт', 'План' ]}
								currentItem={this.state.currentFilterItem || 0}
								onChange={(e) => {
									this.setState({
										currentFilterItem: e.value
									});
								}}
							/>,
							(this.state.currentFilterItem || 0) === 0 ? (
								<div className="b-draggable" key="filter_content">
									{this.isEditableByType('fact') ? (
										<Sortable
											options={{
												forceFallback: true,
												handle: '.b-draggable__dragzone-handle'
											}}
											onChange={(order) => {
												this.setValue(
													'task.items_fact',
													_.map(_.filter(order, (item) => item.substr(0, 1) !== 'a'), (index) => {
														return this.get(`items_fact.${index}`);
													})
												);
											}}
										>
											<div data-id={`a0`} className="add-draggable" onClick={this.addItem.bind(this, -1, 'items_fact')}>
												<div className="add-draggable__icon">+</div>
											</div>
											{this.get('items_fact', []).map(this.renderItem.bind(this, 'fact'))}
										</Sortable>
									) : (
										this.get('items_fact', []).map(this.renderItem.bind(this, 'fact'))
									)}
								</div>
							) : null
						]
					) : null}
					{!loader && (this.state.currentFilterItem === 1 || !this.isFactVisible()) ? (
						<div className="b-draggable">
							{this.isEditableByType('plan') ? (
								<Sortable
									options={{
										forceFallback: true,
										handle: '.b-draggable__dragzone-handle'
									}}
									onChange={(order) => {
										this.setValue(
											'task.items',
											_.map(_.filter(order, (item) => item.substr(0, 1) !== 'a'), (index) => {
												return this.get(`items.${index}`);
											})
										);
									}}
								>
									<div data-id={`a0`} className="add-draggable" onClick={this.addItem.bind(this, -1, 'items')}>
										<div className="add-draggable__icon">+</div>
									</div>
									{this.get('items', []).map(this.renderItem.bind(this, 'plan'))}
								</Sortable>
							) : (
								this.get('items', []).map(this.renderItem.bind(this, 'plan'))
							)}
						</div>
					) : null}
				</div>
				{this.state.showResources ? this.renderResourcesModal() : null}
				{this.state.visibleCleanupSpecialists ? this.renderCleanupSpecialistsModal() : null}
				{this.state.repeatActive ? this.renderRepeatModal() : null}
				{this.state.visibleControlWorkUpload ? this.renderControlWorkUpload() : null}
				{this.state.visibleGalleryControlWork ? this.renderGalleryControlWork() : null}
			</div>
		);
	}

	async repeatTask() {
		let repeat = this.state.repeat;
		repeat.task_uuid = this.get('uuid');

		const response = await this.props.repeatTask(repeat);

		if (response.isOk) {
			alerts.success('Повтор задания выполнен');
		} else {
			response.showErrors();
		}
	}

	renderRepeatModal() {
		const buttons = (
			<ModalTopMenuButtons>
				<ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
					<ModalTopMenuButton className="_close" onClick={::this.hideRepeat} />
				</ContextTooltip>
			</ModalTopMenuButtons>
		);

		return (
			<PageModalComponent
				header={{ title: 'Повтор задания', buttons }}
				onClose={::this.hideRepeat}
				className={
					`b-modal-repeat-task` + (this.state.repeat.interval_type === 'week' ? ' b-modal-repeat-task_week' : '')
				}
				buttons={[
					<Block key="agreement" size="md">
						{this.checkbox('repeat.agreement', 'Я согласен скопировать это задание')}
					</Block>,
					<Block key="submit" size="md">
						{_.get(this.state, 'repeat.agreement') ? (
							<a
								onClick={::this.repeatTask}
								href="javascript:void(0)"
								className="b-button b-button_red b-button_size_md"
							>
								Cоздать
							</a>
						) : null}
					</Block>
				]}
			>
				<div className="b-modal__block">
					<div className="b-block__title">Повторять с интервалом</div>
					<Block size="sm">
						{this.textInput('repeat.interval', {
							type: 'number',
							positive: true
						})}
					</Block>
					<Block size="md">
						{this.select('repeat.interval_type', [
							{
								value: 'day',
								label: 'День'
							},
							{
								value: 'week',
								label: 'Неделя'
							},
							{
								value: 'month',
								label: 'Месяц'
							}
						])}
					</Block>
					{_.get(this.state, 'repeat.interval_type') === 'week' ? (
						<div className="table-container">
							<div className="Table-title">Дни повторения</div>
							<div className="Table _no-indent">
								<table className="b-table">
									<thead>
										<tr className="b-table__header _text-normal">
											<th>Пн</th>
											<th>Вт</th>
											<th>Ср</th>
											<th>Чт</th>
											<th>Пт</th>
											<th>Сб</th>
											<th>Вс</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												{this.checkbox('repeat.days.monday', '', {
													value: _.indexOf(this.state.repeat.days, 'monday') !== -1
												})}
											</td>
											<td>
												{this.checkbox('repeat.days.tuesday', '', {
													value: _.indexOf(this.state.repeat.days, 'tuesday') !== -1
												})}
											</td>
											<td>
												{this.checkbox('repeat.days.wednesday', '', {
													value: _.indexOf(this.state.repeat.days, 'wednesday') !== -1
												})}
											</td>
											<td>
												{this.checkbox('repeat.days.thursday', '', {
													value: _.indexOf(this.state.repeat.days, 'thursday') !== -1
												})}
											</td>
											<td>
												{this.checkbox('repeat.days.friday', '', {
													value: _.indexOf(this.state.repeat.days, 'friday') !== -1
												})}
											</td>
											<td>
												{this.checkbox('repeat.days.saturday', '', {
													value: _.indexOf(this.state.repeat.days, 'saturday') !== -1
												})}
											</td>
											<td>
												{this.checkbox('repeat.days.sunday', '', {
													value: _.indexOf(this.state.repeat.days, 'sunday') !== -1
												})}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					) : null}
					{_.get(this.state, 'repeat.interval_type') === 'month' ? (
						<Block size="xl">
							{this.select('repeat.month_repeat_type', [
								{
									value: 'on-date',
									label: `Ежемесячно ${moment().format('D')}-го числа`
								},
								{
									value: 'on-week',
									label: `Ежемесячно ${this.days[moment().day()]} кажд. ${Math.ceil(moment().date() / 7)}-й недели`
								}
							])}
						</Block>
					) : null}
					<div className="b-block__title">Окончание</div>
					<Block size="sm">{this.radio('repeat.end_type', 'date', 'Дата')}</Block>
					<Block size="md">{this.datepicker('repeat.end_date')}</Block>
					<Block size="sm">{this.radio('repeat.end_type', 'count', 'После')}</Block>
					<Block size="md">
						<ContextTooltip default="Кол-во повторов">
							{this.textInput('repeat.end_count', {
								type: 'number',
								positive: true
							})}
						</ContextTooltip>
					</Block>
				</div>
			</PageModalComponent>
		);
	}

	renderControlWorkUpload() {
		return (
			<DragAndDropModal
				onSave={::this.saveControlWork}
				onClose={::this.closeControlWorkUpload}
				onUpload={::this.uploadControlWork}
				onDelete={::this.deleteControlWork}
				files={this.state.control_work.filter((cw) => cw.url)}
				multiple
			/>
		);
	}

	renderGalleryControlWork() {
		return (
			<Gallery files={this.state.galleryFiles.filter((file) => file.url)} onClose={::this.closeGalleryControlWork} />
		);
	}

	showGalleryControlWork(field, index) {
		this.setState({
			galleryFiles: this.state.task[field][index].control_work,
			visibleGalleryControlWork: true
		});
	}

	closeGalleryControlWork() {
		this.setState({
			visibleGalleryControlWork: false
		});
	}

	showControlWorkUpload(field, index) {
		const control_work = [].concat(this.state.task[field][index].control_work || []);

		this.setState({
			controlWorkField: field,
			controlWorkIndex: index,
			visibleControlWorkUpload: true,
			control_work
		});
	}

	closeControlWorkUpload() {
		this.setState({
			control_work: [],
			visibleControlWorkUpload: false
		});
	}

	async uploadControlWork(files) {
		const responseToken = await api.storage.getUploadToken();
		const tokenInfo = responseToken.payload;

		const promises = [];
		files.forEach((file) => {
			let formData = new FormData();
			formData.append('file', file);
			formData.append('token', tokenInfo.token);

			promises.push(api.storage.uploadFile(tokenInfo.upload_url, formData));
		});

		const response = await Promise.all(promises);

		this.setState({
			control_work: [].concat(
				this.state.control_work,
				response.map((res) => ({
					...res,
					url: res.url.replace('http://', 'https://')
				}))
			)
		});
	}

	deleteControlWork(index) {
		const newControlWork = [].concat(this.state.control_work);
		newControlWork.splice(index, 1);
		this.setState({
			control_work: newControlWork
		});
	}

	saveControlWork() {
		const controlWorkField = this.state.controlWorkField;
		const controlWorkIndex = this.state.controlWorkIndex;

		const task = Object.assign({}, this.state.task);
		task[controlWorkField] = [].concat([], this.state.task[controlWorkField]);
		task[controlWorkField][controlWorkIndex] = Object.assign({}, task[controlWorkField][controlWorkIndex], {
			control_work: this.state.control_work
		});

		this.setState({
			task,
			visibleAttachesUpload: false,
			control_work: []
		});

		this.props.onUpdate();
	}

	renderGalleryAttachesUpload() {
		return (
			<DragAndDropModal
				onSave={::this.saveAttaches}
				onClose={::this.closeAttachesUpload}
				onUpload={::this.uploadAttachments}
				onDelete={::this.deleteAttaches}
				files={this.state.attachments.filter((file) => file.url)}
				multiple
			/>
		);
	}

	renderGalleryAttaches() {
		return <Gallery files={this.state.attachments.filter((file) => file.url)} onClose={::this.closeGalleryAttaches} />;
	}

	showAttachesUpload() {
		this.setState({
			visibleAttachesUpload: true
		});
	}

	closeAttachesUpload() {
		this.setState({
			visibleAttachesUpload: false
		});
	}

	showGalleryAttaches = () => {
		this.setState({
			visibleGalleryAttaches: true
		});
	};

	closeGalleryAttaches = () => {
		this.setState({
			visibleGalleryAttaches: false
		});
	};

	deleteAttaches(index) {
		const newAttaches = [].concat(this.state.attachments);
		newAttaches.splice(index, 1);
		this.setState({
			attachments: newAttaches
		});
	}

	saveAttaches() {
		const attachmentsNotSave = this.state.attachmentsNotSave;

		const attachments = Object.assign({}, this.state.attachments);
		this.setState({
			attachments: attachmentsNotSave,
			visibleAttachesUpload: false,
			attachmentsNotSave: []
		});

		this.props.onUpdate();
	}

	uploadAttachments = async (results) => {
		const tokenInfoResponse = await api.storage.getUploadToken();
		if (tokenInfoResponse.success) {
			let formData = new FormData();

			const allowedTypes = [ 'image/png', 'image/jpeg' ];

			let hasError = false;

			results.forEach((file) => {
				formData.append('file', file);

				let errors = [];
				if (_.indexOf(allowedTypes, file.type) === -1) {
					errors.push('Недопустимый формат файла');
				}
				if (file.size / (2048 * 2048) >= 20) {
					errors.push('Недопустимый размер файла');
				}
				if (errors.length > 0) {
					alerts.error(errors.join('<br/>'));
					hasError = true;
				}
			});

			if (hasError) {
				return;
			}

			formData.append('token', tokenInfoResponse.payload.token);

			const response = await api.storage.uploadFile(tokenInfoResponse.payload.upload_url, formData);
			const attachmentsWithNewImages = this.state.attachments;
			console.log(response)
			if (response.payload.url) {
				attachmentsWithNewImages.push(response.payload.url);
			}
			this.setState({ attachmentsNotSave: attachmentsWithNewImages });
		} else {
			console.log('error', tokenInfoResponse);
		}
	};

	showResources() {
		this.setState({
			showResources: true
		});
	}

	hideResources() {
		this.checkVehicles();
		this.setState({
			showResources: false
		});
		this.loadVehicleWorkTypes();
	}

	showCleanupSpecialists() {
		(this.state.task.cleanup_specialist_uuids || []).forEach((csu, index) => {
			this.cleanupSpecialistsDebounce[index] = debounce(500, ::this.getCleanupSpecialists);
		});

		this.setState({
			visibleCleanupSpecialists: true
		});
	}

	hideCleanupSpecialists() {
		this.cleanupSpecialistsDebounce = [];

		this.setState({
			visibleCleanupSpecialists: false
		});
	}

	repeat() {
		this.props.router.push('/utility/tasks/create');
	}

	async loadResponsives(input, callback) {
		if (!input) {
			input = this.get('responsive_uuid');
		}

		const result = await this.props.getUsers({
			filters: {
				withComponent: 'utility'
			},
			search: input,
			pagination: {
				page: 1,
				limit: 20
			}
		});

		if (result.isOk) {
			callback(null, {
				options: _.sortBy(
					result.payload.items.map((i) => ({
						label: new User(i).getFullName(),
						value: i.uuid
					})),
					'label'
				),
				complete: false
			});
		} else {
			result.showErrors();
		}
	}

	renderItems(type) {
		const field = type === 'plan' ? 'items' : 'items_fact';
		const list = this.get(field) || [];

		return list.map(this.renderItem.bind(this, type));
	}

	addItem(index, field) {
		let task = this.state.task;
		task[field] = task[field] || [];

		let time = '23:59';
		if (window.RNIS_SETTINGS.task_with_time) {
			const from = moment(task.time_from, formats.TIME);
			const to = moment(task.time_to, formats.TIME);
			const timeDiff = Math.abs(from.diff(to, 'minutes'));
			time = this.formatTime(timeDiff);
		}

		task[field].splice(index + 1, 0, {
			movement_type: 'work',
			geometry_type: 'road_part',
			date_from: window.RNIS_SETTINGS.task_with_time ? task.time_from : '00:00',
			date_to: window.RNIS_SETTINGS.task_with_time ? task.time_to : '23:59',
			time
		});

		this.setState({ task });

		this.stopLiveReload();
		this.props.onUpdate();
	}

	deleteItem(index, field, e) {
		e.preventDefault();

		let task = this.state.task;
		task[field].splice(index, 1);
		this.setState({ task });

		this.stopLiveReload();
		this.props.onUpdate();
	}

	getValue(field) {
		if (/\.movement_type$/.test(field)) {
			return _.get(this.getState(), field) === 'idle';
		}
		return _.get(this.getState(), field);
	}

	expand(index) {
		let expandedBlocks = this.state.expandedBlocks;
		expandedBlocks.push(index);
		expandedBlocks = _.uniq(expandedBlocks);

		this.setState({
			expandedBlock: this.state.expandedBlock === index ? null : index,
			expandedBlocks
		});
	}

	enableManual(field, index) {
		let task = this.state.task;
		task[field][index].is_manual = true;
		this.setState({ task });
	}

	logicEditable(...args) {
		const valueFromArgument = args;
		if (!valueFromArgument.includes(undefined)) {
			return valueFromArgument;
		}
		if (window.RNIS_SETTINGS.is_need_communal_municipality) {
			return !this.state.communal_municipality_uuid;
		}
		return false;
	}

	renderItem(type, item, index) {
		if (this.props.confirmModeActive && item.movement_type === 'idle') {
			return null;
		}

		const field = type === 'plan' ? 'items' : 'items_fact';

		const isFactError = this.props.isFactFormErrors(field, index, this.state.task);

		const isEditable = this.isEditableByType(type);

		return [
			<TaskItemUpdateTest
				key={`${type}:${index}`}
				item={JSON.stringify(item)}
				index={index}
				data={{
					expandedBlock: this.state.expandedBlock,
					confirmModeActive: this.props.confirmModeActive,
					expandedConfirmBlock: this.state.expandedConfirmBlock,
					inited: this.state.inited,
					errors: this.props.errors,
					userGeoObjectsByLayers: this.state.userGeoObjectsByLayers
				}}
				render={() => {
					const geometryType = item.geometry_type;
					const isEditableBySkpdi = this.isEditableBySkpdi(item);

					const measureUuid = _.get(
						_.find(this.state.work_types, {
							value: item.work_type_uuid
						}),
						'document.measure_uuid'
					);
					const measure = _.get(_.find(this.state.measures, { value: measureUuid }), 'label');

					const itemUuid = _.get(_.first(item.geometry), 'item_uuid');

					return (
						<TaskItem
							key={`${index}:${itemUuid}`}
							data-id={index}
							className={classNames('b-draggable__item sector', {
								sector_state_moving: item.movement_type === 'work',
								sector_idling: item.movement_type === 'idle',
								sector_state_success: item.is_confirmed === true,
								sector_state_violation: item.is_confirmed === false,
								sector_state_success_manual: item.is_confirmed_manual === true
							})}
						>
							{type === 'fact' ? <div className="b-draggable__state" /> : null}
							{isEditable ? (
								<div className="b-draggable__dragzone">
									<span className="b-draggable__dragzone-handle">
										.<br />.<br />.
									</span>
								</div>
							) : null}
							<div className="b-draggable__top">
								<input
									type="checkbox"
									className="b-draggable__open"
									checked={this.state.expandedBlock !== index}
									onChange={this.expand.bind(this, index)}
								/>
								<i className="b-draggable__arrow" />
								<div className="b-draggable__title">
									{type === 'fact' ? (
										<ContextTooltip default="Процент зачета участка работ по данным телематики">
											<div>
												{_.isNumber(item.percent) ? item.percent : '-'}
												%&nbsp;
											</div>
										</ContextTooltip>
									) : null}
									<div>Участок №{index + 1}</div>
									<span>
										/
										{this.get(`${field}.${index}.movement_type`) === 'idle' ? (
											'Холостой ход'
										) : (
											_.get(
												_.find(this.state.work_types, {
													value: item.work_type_uuid
												}),
												'label'
											)
										)}
									</span>
								</div>
								<div className="b-draggable__menu">
									{isEditable ? (
										<ContextTooltip key="kurs.task.map.delete" code="kurs.task.map.delete" default="Удалить">
											<div
												className="b-draggable__menu-link b-draggable__menu-link_basket"
												onClick={this.deleteItem.bind(this, index, field)}
											/>
										</ContextTooltip>
									) : null}
								</div>
							</div>
							{this.state.expandedBlock === index ? (
								<div
									className={classNames('b-draggable__content', {
										hidden: this.state.expandedBlock !== index
									})}
								>
									{this.get(`${field}.${index}.movement_type`) === 'work' && type === 'fact' ? (
										<div className="b-draggable__content-item">
											<div className="b-draggable__content-header">
												<div className="b-draggable__content-title">Факт</div>
												<Block size="md" title={true}>
													<Button
														size="md"
														color={item.is_manual ? 'red' : 'white'}
														shadow={item.is_manual ? 'red' : 'gray'}
														width="auto"
														text="Ручной ввод"
														onClick={this.enableManual.bind(this, field, index)}
													/>
												</Block>
											</div>
											<Block title="Факт время с">
												{this.maskInput(`task.${field}.${index}.fact_date_from`, '99:99:99', {
													withTimeIcon: true,
													disabled: !isEditable || !item.is_manual
												})}
											</Block>
											,
											<Block title="Факт время по">
												{this.maskInput(`task.${field}.${index}.fact_date_to`, '99:99:99', {
													withTimeIcon: true,
													disabled: !isEditable || !item.is_manual
												})}
											</Block>
											,
											<Block title="Факт продол.">
												{this.maskInput(`task.${field}.${index}.fact_time`, '99:99:99', {
													withTimeIcon: true,
													disabled: !isEditable || !item.is_manual
												})}
											</Block>
											,
										</div>
									) : null}
									{this.get(`${field}.${index}.movement_type`) === 'work' ? (
										<div className="b-draggable__content-item">
											<div className="b-draggable__content-header">
												<div className="b-draggable__content-title">План</div>
											</div>
											<Block title="Время с">
												{this.maskInput(`task.${field}.${index}.date_from`, '99:99', {
													withTimeIcon: true,
													disabled: !isEditable,
													minTime: window.RNIS_SETTINGS.task_with_time ? this.state.task.time_from : undefined,
													maxTime: window.RNIS_SETTINGS.task_with_time ? this.state.task.time_to : undefined
												})}
											</Block>
											<Block title="Время по">
												{this.maskInput(`task.${field}.${index}.date_to`, '99:99', {
													withTimeIcon: true,
													disabled: !isEditable,
													minTime: window.RNIS_SETTINGS.task_with_time ? this.state.task.time_from : undefined,
													maxTime: window.RNIS_SETTINGS.task_with_time ? this.state.task.time_to : undefined
												})}
											</Block>
											<Block title="Продолжительность">
												{this.maskInput(`task.${field}.${index}.time`, '99:99', {
													withTimeIcon: true,
													disabled: !isEditable
												})}
											</Block>
										</div>
									) : null}

									<div className="b-draggable__content-item">
										<div className="b-draggable__content-header">
											<div className="b-draggable__content-title">Маршрут</div>
										</div>
										<Block title="Вид маршрута">
											{window.RNIS_SETTINGS.CITY_MURMANSK ? (
												this.select(
													`task.${field}.${index}.geometry_type`,
													[
														{
															value: 'road_part',
															label: 'Участок дороги'
														},
														{
															value: 'user_geo_object',
															label: 'Объекты'
														}
													].filter((geometryType) => window.RNIS_SETTINGS.GEOMETRY_TYPES.includes(geometryType.value)),
													{
														disabled: !isEditable || !isEditableBySkpdi
													}
												)
											) : (
												this.select(
													`task.${field}.${index}.geometry_type`,
													[
														{
															value: 'road_part',
															label: 'Участок дороги'
														},
														{
															value: 'road_repair_part',
															label: 'Участок ремонта дороги'
														},
														{
															value: 'stop_point',
															label: 'Остановка'
														},
														{
															value: 'idle',
															label: 'Холостой ход'
														},
														{
															value: 'other',
															label: 'Произвольный маршрут'
														},
														{
															value: 'user_geo_object',
															label: 'Объекты'
														}
													].filter((geometryType) => window.RNIS_SETTINGS.GEOMETRY_TYPES.includes(geometryType.value)),
													{
														disabled: !isEditable || !isEditableBySkpdi
													}
												)
											)}
										</Block>

										{this.get(`${field}.${index}.movement_type`) === 'work' ? (
											[
												<Block key="work_type_uuid" size="lg" className="custom" title="Вид работы">
													<ContextTooltip
														default={
															_.get(
																_.find(this.state.work_types, {
																	value: _.get(item, 'work_type_uuid')
																}),
																'label'
															) || '-'
														}
													>
														{this.selectAsync(
															`task.${field}.${index}.work_type_uuid`,
															async (input, callback) => {
																const selectWorkTypes =
																	isEditable && isEditableBySkpdi && !item.external_id
																		? this.getWorkTypes(field, index)
																		: this.state.work_types;

																input = input.toLowerCase();

																if (!input) {
																	input = item.work_type_uuid;
																}
																const filteredWorkTypes = input
																	? _.filter(selectWorkTypes, ({ label, value }) => {
																			return label.toLowerCase().indexOf(input) !== -1 || value === input;
																		})
																	: selectWorkTypes;

																callback(null, {
																	options: _.sortBy(filteredWorkTypes.slice(0, 20), 'label'),
																	complete: false
																});
															},
															{
																disabled: !isEditable || !isEditableBySkpdi || item.external_id
															}
														)}
													</ContextTooltip>
												</Block>,
												<Block key="count" size="sm" className="custom" title="Объем работ">
													{this.textInput(`task.${field}.${index}.count`, {
														type: 'number',
														step: 0.01,
														positive: true,
														disabled: window.RNIS_SETTINGS.count_from_distance_or_object || !isEditable
													})}
												</Block>,
												<Block key="count_measure" size="sm" className="custom">
													<Input value={measure} disabled={true} />
												</Block>
											]
										) : null}
									</div>

									{this.get(`${field}.${index}.movement_type`) === 'work' ? (
										<div className="b-draggable__content-item">
											{geometryType !== 'user_geo_object' ? (
												<div className="b-draggable__content-header">
													<div className="b-draggable__content-title">Протяженность</div>
												</div>
											) : null}
											{this.get(`${field}.${index}.geometry_type`) === 'road_part' ||
											this.get(`${field}.${index}.geometry_type`) === 'road_repair_part' ? (
												[
													<Block key="part_start" title="Начало участка, км">
														{this.textInput(`task.${field}.${index}.part_start`, {
															disabled: !isEditable || !isEditableBySkpdi
														})}
													</Block>,
													<Block key="part_end" title="Конец участка, км">
														{this.textInput(`task.${field}.${index}.part_end`, {
															disabled: !isEditable || !isEditableBySkpdi
														})}
													</Block>
												]
											) : null}
											{geometryType !== 'user_geo_object' ? (
												<Block title="Протяж, км">
													{this.textInput(`task.${field}.${index}.distance`, {
														disabled: window.RNIS_SETTINGS.CITY_MURMANSK || !isEditable || !isEditableBySkpdi,
														onChange: window.RNIS_SETTINGS.count_from_distance_or_object
															? (e) => ::this.onChangeDistance(e, field, index)
															: undefined
													})}
												</Block>
											) : null}
										</div>
									) : null}

									{geometryType === 'road_part' ? (
										<div className="b-draggable__content-item">
											<div className="b-draggable__content-header">
												<div className="b-draggable__content-title">Участок дороги</div>
											</div>
											<Block size="lg">
												<ContextTooltip
													default={_.get(this.state.roadParts, _.get(item, 'geometry.0.item_uuid')) || '-'}
												>
													{this.selectAsync(
														`task.${field}.${index}.geometry.0.item_uuid`,
														async (input, callback) => {
															return await this.loadObjects(
																index,
																input,
																callback,
																field,
																this.state.communal_municipality_uuid
															);
														},
														{
															disabled: this.logicEditable(!isEditable || !isEditableBySkpdi || item.external_id),
															reload: this.state.reload.includes('roadPart') ? true : false,
															ref: `task.${field}.${index}.item_uuid`,
															withoutInit: this.state.expandedBlock !== index
														}
													)}
												</ContextTooltip>
											</Block>
											{window.RNIS_SETTINGS.is_need_communal_municipality ? (
												<Block size="lg" title="Муниципальное образование">
													{this.select(`communal_municipality_uuid`, this.state.communal_municipalities, {
														disabled: true
													})}
												</Block>
											) : null}

											<Block key="direction" title="Направление">
												{this.select(
													`task.${field}.${index}.geometry.0.direction`,
													[
														{
															value: 'forward',
															label: 'Прямое'
														},
														{
															value: 'reverse',
															label: 'Обратное'
														}
													],
													{
														disabled: !isEditable || !isEditableBySkpdi
													}
												)}
											</Block>
										</div>
									) : null}

									{geometryType === 'road_repair_part' ? (
										<div className="b-draggable__content-item">
											<div className="b-draggable__content-header">
												<div className="b-draggable__content-title">Участок ремонта дороги</div>
											</div>
											<Block size="lg">
												{this.selectAsync(
													`task.${field}.${index}.geometry.0.item_uuid`,
													async (input, callback) => {
														return await this.loadObjects(index, input, callback, field);
													},
													{
														disabled: !isEditable,
														ref: `task.${field}.${index}.item_uuid`
													}
												)}
											</Block>
											<Block key="direction" title="Направление">
												{this.select(
													`task.${field}.${index}.geometry.0.direction`,
													[
														{
															value: 'forward',
															label: 'Прямое'
														},
														{
															value: 'reverse',
															label: 'Обратное'
														}
													],
													{
														disabled: !isEditable
													}
												)}
											</Block>
										</div>
									) : null}

									{geometryType === 'stop_point' ? (
										<div className="b-draggable__content-item">
											<div className="b-draggable__content-header">
												<div className="b-draggable__content-title">Остановка</div>
											</div>
											<Block size="lg">
												{this.selectAsync(
													`task.${field}.${index}.geometry.0.item_uuid`,
													async (input, callback) => {
														return await this.loadObjects(index, input, callback, field);
													},
													{
														disabled: !isEditable,
														ref: `task.${field}.${index}.item_uuid`
													}
												)}
											</Block>
										</div>
									) : null}

									{this.get(`${field}.${index}.movement_type`) === 'work' && geometryType === 'other' ? (
										<div className="b-draggable__content-item b-draggable__content-item_full b-draggable__content-item_checkpoints">
											<div className="b-draggable__content-header">
												<div className="b-draggable__content-title">Точки участка на карте</div>
											</div>
											<div className="checkpoints-wrap">
												{_.map(item.geometry, this.renderItemGeometry.bind(this, index, isEditable, field))}
												{isEditable ? (
													<Block size="xl">
														<a
															className="add-point"
															key={`add:${index}`}
															href="javascript:void(0)"
															onClick={this.addGeometry.bind(this, index, field)}
														>
															+ Добавить
														</a>
													</Block>
												) : null}
											</div>
										</div>
									) : null}

									{geometryType === 'user_geo_object' ? (
										<div className="b-draggable__content-item">
											<div className="b-draggable__content-header">
												<div className="b-draggable__content-title">Слой</div>
											</div>
											<Block size="lg">
												{this.select(`task.${field}.${index}.geometry.0.layer_uuid`, this.state.layers, {
													disabled: !isEditable,
													onChange: (e) => ::this.onChangeLayer(e, field, index)
												})}
											</Block>
											<Block title="Объект">
												{this.select(
													`task.${field}.${index}.geometry.0.item_uuid`,
													this.state.userGeoObjectsByLayers[
														this.getValue(`task.${field}.${index}.geometry.0.layer_uuid`)
													],
													{
														isLoading: this.state.userGeoObjectIsLoading,
														onChange: window.RNIS_SETTINGS.count_from_distance_or_object
															? (e) =>
																	::this.onChangeUserGeoObject(
																		e,
																		field,
																		index,
																		this.getValue(`task.${field}.${index}.geometry.0.layer_uuid`)
																	)
															: undefined
													}
												)}
											</Block>
											{window.RNIS_SETTINGS.is_need_communal_municipality ? (
												<Block size="lg" title="Муниципальное образование">
													{this.select(`communal_municipality_uuid`, this.state.communal_municipalities, {
														disabled: true
													})}
												</Block>
											) : null}
										</div>
									) : null}
								</div>
							) : null}
							{this.props.confirmModeActive && type === 'fact' ? (
								<div>
									<div className="b-draggable__top">
										<input
											type="checkbox"
											className="b-draggable__open"
											checked={this.state.expandedConfirmBlock !== index}
											onChange={this.expandConfirm.bind(this, index)}
										/>
										<i className="b-draggable__arrow" />
										<div className="b-draggable__title b-draggable__title_sub">
											<div>Подробная информация об участке</div>
										</div>
									</div>
									{this.state.expandedConfirmBlock === index ? (
										<div className="b-draggable__content">
											<div className={window.RNIS_SETTINGS.CONTROLWORK ? 'b-draggable__content-item' : ''}>
												<div className="b-draggable__content_inner">
													{this.renderViolations(item, index)}
													<Block title="Причина корректировки">
														{this.select(`task.${field}.${index}.violation_type_uuid`, this.state.kurs_violation_types)}
													</Block>
													<Block title="Факт. объем работ">
														{this.textInput(`task.${field}.${index}.fact_count`, {
															type: 'number',
															delayed: true,
															error: isFactError
														})}
													</Block>
													<Block size="xl" title="Комментарий">
														{this.textarea(`task.${field}.${index}.comment`, {
															delayed: true
														})}
													</Block>
													{this.renderCheckBlock(item, index)}
												</div>
											</div>
											{window.RNIS_SETTINGS.CONTROLWORK ? (
												<div className="b-draggable__content-item">
													<div className="b-draggable__content_inner">
														<Block size="lg">
															<Button
																color="red"
																shadow="red"
																width="full"
																className="b-button_block-height"
																text="Загрузить материалы"
																onClick={() => ::this.showControlWorkUpload(field, index)}
															/>
															<span className="files_state_msg_wrong">
																{this.getError(`task.${field}.${index}.control_work`)}
															</span>
														</Block>

														<Block size="lg">
															<Button
																color="red"
																shadow="red"
																width="full"
																className="b-button_block-height"
																text="Посмотреть материалы"
																onClick={() => ::this.showGalleryControlWork(field, index)}
																disabled={
																	!this.state.task[field][index].control_work ||
																	!this.state.task[field][index].control_work.length
																}
															/>
														</Block>
														{this.state.task[field][index].control_work ? (
															<Block size="xl">
																<div className="files">
																	{(this.state.task[field][index].control_work || []).map(
																		(file) =>
																			!file.url ? null : itImage(file.url) ? (
																				<img src={file.url} className="files-preview" />
																			) : (
																				<a className="files-link" href={file.url}>
																					{index}
																					.
																					{file.url.split('.').pop()}
																				</a>
																			)
																	)}
																</div>
															</Block>
														) : null}
													</div>
												</div>
											) : null}
										</div>
									) : null}
								</div>
							) : null}
						</TaskItem>
					);
				}}
			/>,
			isEditable ? (
				<div
					key={`add-${index}`}
					data-id={`a${index}`}
					className="add-draggable"
					onClick={this.addItem.bind(this, index, field)}
				>
					<div className="add-draggable__icon">+</div>
				</div>
			) : null
		];
	}

	renderViolations(item, index) {
		const violations = _.filter(this.state.violations || [], {
			task_item_index: index.toString()
		});
		if (violations.length === 0) {
			return;
		}

		return (
			<div className="b-draggable__content-header">
				<div className="b-draggable__content-atten">Вид нарушения на участке работ:</div>
				<div className="b-draggable__content-title">
					{_.map(violations, (violation) => {
						switch (violation.type) {
							case 'max_speed':
								return `Превышение скорости ${violation.data
									? `(${moment(violation.data.time).format(formats.TIME_FULL)}, ${violation.data.latitude}, ${violation
											.data.longitude})`
									: ''})`;
							case 'mechanism':
								return `Не включен механизм "${this.getMechanismName(
									violation.mechanism_binding_uuid
								)}" ${violation.data
									? `(${moment(violation.data.time).format(formats.TIME_FULL)}, ${violation.data.latitude}, ${violation
											.data.longitude})`
									: ''})`;
							case 'route_fail':
								return `Отклонение от маршрута (${violation.start_at} км)`;
						}
					}).join('; ')}
				</div>
			</div>
		);
	}

	getMechanismName(mechanismBindingUuid) {
		const mechanismTypeUuid = _.get(
			_.find(this.state.kurs_mechanism_bindings, {
				value: mechanismBindingUuid
			}),
			'document.mechanism_type_uuid'
		);
		return _.get(_.find(this.state.kurs_mechanism_types, { value: mechanismTypeUuid }), 'label');
	}

	renderCheckBlock(item, index) {
		const sliderClassName = classNames({
			'b-slider__line': true,
			_selected_yes: item.is_confirmed !== true && item.is_confirmed !== false,
			_selected_all: item.is_confirmed === true
		});

		const sliderCircleClassName = classNames({
			'b-slider__circle': true,
			_selected_yes: item.is_confirmed !== true && item.is_confirmed !== false,
			_selected_all: item.is_confirmed === true
		});

		const onClick =
			item.is_confirmed === true
				? this.setConfirmed.bind(this, index, false)
				: this.setConfirmed.bind(this, index, true);

		return (
			<div className="b-block _xl-12-12 right">
				<div className="b-block__text b-block__text_no-header clearAfter">
					<div className="b-checkbox left">
						<State positive={!!item.is_auto_confirmed} /> БНСО
					</div>
					<div className="b-slider _options clearAfter right" onClick={onClick}>
						<div className="b-slider__title">Факт подтвержден</div>

						<div className="b-slider__control">
							<div className={sliderClassName} />
							<div className={sliderCircleClassName} />
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderGeometries(itemIndex, field, isEditable) {
		const geometryType = this.get(`${field}.${itemIndex}.geometry_type`);
		const list = this.state.task[field][itemIndex].geometry || [];

		let add = null;
		if (isEditable && geometryType === 'other') {
			add = (
				<div key="add" className="add-link">
					<a
						className="add-job b-icon-link b-icon-link_icon_plus"
						href="#"
						onClick={this.addGeometry.bind(this, itemIndex, field)}
					>
						Добавить контрольную точку
					</a>
				</div>
			);
		}

		return _.concat(list.map(this.renderGeometry.bind(this, itemIndex, field, isEditable)), add);
	}

	renderGeometry(itemIndex, field, isEditable, geometry, index) {
		const geometryType = this.get(`${field}.${itemIndex}.geometry_type`);
		if (!geometryType) {
			return;
		}

		return (
			<div key={`${itemIndex}:${index}`}>
				{geometryType !== 'other' ? (
					<Block title="Объект" className="expand-top">
						{this.selectAsync(
							`task.${field}.${itemIndex}.geometry.${index}.item_uuid`,
							async (input, callback) => {
								return await this.loadObjects(itemIndex, input, callback, field);
							},
							{
								disabled: !isEditable
							}
						)}
					</Block>
				) : null}
				{geometryType === 'other' && !geometry.is_address ? (
					<div>
						<Block title="Адрес">
							{isEditable ? (
								this.textInput(`task.${field}.${itemIndex}.geometry.${index}.address`)
							) : (
								this.get(`task.${field}.${itemIndex}.geometry.${index}.address`)
							)}
						</Block>
					</div>
				) : null}
				{geometryType === 'other' && geometry.is_address ? (
					<div>
						<Block title="Координаты">
							{geometry.latitude}, {geometry.longitude}
						</Block>
					</div>
				) : null}
				{geometryType !== 'other' ? (
					[
						<Block key="direction" title="Направление" className="expand-top">
							{this.select(
								`task.${field}.${itemIndex}.geometry.${index}.direction`,
								[
									{
										value: 'forward',
										label: 'Прямое'
									},
									{
										value: 'reverse',
										label: 'Обратное'
									}
								],
								{
									disabled: !isEditable
								}
							)}
						</Block>
					]
				) : null}
				{isEditable ? (
					<div className="add-link">
						{geometryType === 'other' ? (
							<a
								className="remove-job b-icon-link b-icon-link_icon_basket"
								href="javascript:void(0)"
								onClick={this.deleteGeometry.bind(this, itemIndex, index, field)}
							>
								Удалить контрольную точку
							</a>
						) : null}
					</div>
				) : null}
			</div>
		);
	}

	async addGeometry(itemIndex, field, e = null, latitude = null, longitude = null) {
		e && e.preventDefault();

		const geometry =
			latitude && longitude
				? {
						is_address: true,
						latitude,
						longitude
					}
				: {};

		let task = this.state.task;
		if (task[field][itemIndex].movement_type === 'idle') {
			if (itemIndex === 0) {
				task[field][itemIndex].geometry.splice(0, 0, geometry);
			} else if (itemIndex === task[field].length - 1) {
				task[field][itemIndex].geometry.push(geometry);
			} else {
				task[field][itemIndex].geometry.splice(task[field][itemIndex].geometry.length - 1, 0, geometry);
			}
		} else {
			task[field][itemIndex].geometry.push(geometry);
		}

		await this.setState({ task });
	}

	deleteGeometry(itemIndex, index, field, e) {
		e.preventDefault();

		let task = this.state.task;
		task[field][itemIndex].geometry.splice(index, 1);
		this.setState({ task });

		this.props.onUpdate();
	}

	async preloadRoadPartWorkTypes() {
		const uuids = _.uniq(
			_.filter(
				_.map(this.get('items', []), (item) => {
					return _.get(item, 'geometry.0.item_uuid');
				})
			)
		);
		this.loadRoadPartWorkTypes(uuids);
		this.loadAllRoadPartWorkTypes(uuids);
	}

	async loadRoadPartWorkTypes(uuids) {
		uuids = _.filter(uuids);
		if (uuids.length === 0) {
			return;
		}

		const response = await this.props.getRoadPartWorkTypesMultiple(uuids, this.get('date'));
		if (response.isOk) {
			let road_part_work_types = this.state.road_part_work_types;
			_.each(response.payload.items, (item) => {
				road_part_work_types[item.uuid] = item.items;
			});
			this.setState({
				road_part_work_types
			});
		} else {
			response.showErrors();
		}
	}

	async loadAllRoadPartWorkTypes(uuids) {
		uuids = _.filter(uuids);
		if (uuids.length === 0) {
			return;
		}

		const response = await this.props.getRoadPartWorkTypesMultiple(uuids, this.get('date'), true);
		if (response.isOk) {
			let all_road_part_work_types = this.state.all_road_part_work_types;
			_.each(response.payload.items, (item) => {
				all_road_part_work_types[item.uuid] = item.items;
			});
			this.setState({
				all_road_part_work_types
			});
		} else {
			response.showErrors();
		}
	}

	getTechnocardVehicles(fromAll = false) {
		let technocardVehicles = {};
		const technocardData = _.flatten(
			_.map(this.get('items') || [], (item) => {
				const uuid = _.get(item, 'geometry.0.item_uuid');
				const roadPartWorkTypes = _.get(
					fromAll ? this.state.all_road_part_work_types : this.state.road_part_work_types,
					uuid
				);
				if (!roadPartWorkTypes) {
					return null;
				}

				return _.map(_.flatten(_.map(roadPartWorkTypes, 'vehicle_types')), (vehicleType, b) => {
					return vehicleType.split(':');
				});
			})
		);
		if (technocardData.length === 0) {
			return null;
		}

		_.each(technocardData, (item) => {
			if (!item) {
				return;
			}
			if (!technocardVehicles[item[0]]) {
				technocardVehicles[item[0]] = 0;
			}
			technocardVehicles[item[0]] = Math.max(technocardVehicles[item[0]], _.toInteger(item[1]));
		});

		return technocardVehicles;
	}

	checkVehicles() {
		const technocardVehicles = this.getTechnocardVehicles();
		if (!technocardVehicles) {
			return;
		}

		const resources = this.get('resources') || [];

		let hasError = false;
		_.each(technocardVehicles, (count, vehicleTypeUuid) => {
			if (_.filter(resources, { vehicle_type_uuid: vehicleTypeUuid }).length !== count) {
				hasError = true;
			}
		});

		if (hasError) {
			alerts.error('Количество/тип ТС в технологической карте не совпадает с ресурсами');
		}
	}

	async setValue(field, value, ignoreRules = false) {
		const matches = /^repeat\.days\.(.+)$/.exec(field);
		if (matches) {
			const day = matches[1];
			let days = this.state.repeat.days;
			if (value) {
				days.push(day);
			} else {
				days = _.difference(days, [ day ]);
			}
			this.setValue('repeat.days', days, true);
			return;
		}

		if (field.includes('communal_municipality_uuid')) {
			const localIndex = field.match(/\d+/)[0];
			let task = this.state.task;
			if (task.items[localIndex] && task.items[localIndex].geometry && task.items[localIndex].geometry[0].item_uuid) {
				task.items[localIndex].geometry[0].item_uuid = null;
			}
			if (
				task.items_fact &&
				task.items_fact[localIndex] &&
				task.items_fact[localIndex].geometry &&
				task.items_fact[localIndex].geometry[0].item_uuid
			) {
				task.items_fact[localIndex].geometry[0].item_uuid = null;
			}
			await this.setState({
				task: task,
				reload: [ 'roadPart' ]
			});
		}

		if (/^task/.test(field)) {
			const statusNotClose =
				_.get(
					_.find(this.state.kurs_task_statuses, {
						value: _.get(this.props.data, 'status_uuid')
					}),
					'label'
				) !== 'Закрыт';
			this.props.onUpdate(statusNotClose);
		}

		if (!ignoreRules && /\.driver_uuid$/.test(field)) {
			if (!await this.checkDriverRTiO(field, value)) {
				value = null;
			}
		}

		await super.setValue(field, value);

		if (!ignoreRules && /\.vehicle_uuid$/.test(field)) {
			this.checkVehicleWorkTypes();
		}

		if (ignoreRules) {
			return;
		}

		if (/\.unit_uuid$/.test(field)) {
			this.setValue('task.contracts', []);
		}

		//this.stopLiveReload();

		if (/\.(violation_type_uuid|fact_count|comment)$/.test(field)) {
			return;
		}

		this.checkPlan(field, value);
		this.checkFact(field, value);
	}

	async checkPlan(field, value) {
		const matches = /^task\.items\.([0-9]+)\.geometry_type$/.exec(field);
		if (matches) {
			if (value === 'idle') {
				await this.setValue(`task.items.${matches[1]}.movement_type`, 'idle');
				return;
			} else {
				await this.setValue(`task.items.${matches[1]}.movement_type`, 'work', true);
			}
			if (!value || value === 'other') {
				await this.setValue(`task.items.${matches[1]}.geometry`, []);
			} else {
				await this.setValue(`task.items.${matches[1]}.geometry`, [ {} ]);
			}
			this.refs[`task.items.${matches[1]}.item_uuid`] && this.refs[`task.items.${matches[1]}.item_uuid`].reload();
		}

		const fromMatches = /^task\.items\.([0-9]+)\.date_from/.exec(field);
		const toMatches = /^task\.items\.([0-9]+)\.date_to/.exec(field);
		if (fromMatches || toMatches) {
			const itemIndex = fromMatches ? fromMatches[1] : toMatches[1];
			const from = moment(this.get(`items.${itemIndex}.date_from`), formats.TIME);
			const to = moment(this.get(`items.${itemIndex}.date_to`), formats.TIME);
			const time = Math.abs(from.diff(to, 'minutes'));
			this.setValue(`task.items.${itemIndex}.time`, this.formatTime(time), true);
		}
		const timeMatches = /^task\.items\.([0-9]+)\.time/.exec(field);
		if (timeMatches) {
			const itemIndex = timeMatches[1];
			const from = moment(this.get(`items.${itemIndex}.date_from`), formats.TIME);
			const time = moment(this.get(`items.${itemIndex}.time`), formats.TIME).diff(
				moment('00:00', formats.TIME),
				'minutes'
			);
			const to = from.add(time, 'minutes').format(formats.TIME);
			this.setValue(`task.items.${itemIndex}.date_to`, to, true);
		}

		const workTypeMatches = /^task\.items\.([0-9]+)\.work_type_uuid/.exec(field);
		if (workTypeMatches && value) {
			const itemIndex = workTypeMatches[1];
			let workTypeVehicleTypes = _.get(
				this.state.road_part_work_types,
				this.get(`items.${itemIndex}.geometry.0.item_uuid`)
			);
			if (_.isArray(workTypeVehicleTypes)) {
				workTypeVehicleTypes = _.get(_.find(workTypeVehicleTypes, { uuid: value }) || {}, 'vehicle_types');
				_.each(this.get('items'), (item) => {
					const itemUuid = _.get(item, 'geometry.0.item_uuid');
					const workTypeUuid = value;
					if (itemUuid && workTypeUuid) {
						let itemVehicleTypes = _.get(this.state.road_part_work_types, itemUuid);
						if (_.isArray(itemVehicleTypes)) {
							itemVehicleTypes = _.get(
								_.find(itemVehicleTypes, {
									uuid: workTypeUuid
								}) || {},
								'vehicle_types'
							);
							if (
								_.difference(workTypeVehicleTypes, itemVehicleTypes).length > 0 ||
								_.difference(itemVehicleTypes, workTypeVehicleTypes).length > 0
							) {
								alerts.error('Тип ТС/количество ТС отличается в технологической карте');
								this.setValue(field, null, true);
							}
						}
					}
				});
			}
		}

		const itemMatches = /^task\.items\.([0-9]+)\.geometry\.([0-9]+)\.item_uuid/.exec(field);
		if (itemMatches) {
			const itemIndex = itemMatches[1];
			await this.setValue(`task.items.${itemIndex}.part_start`, null, true);
			await this.setValue(`task.items.${itemIndex}.part_end`, null, true);

			/* if (this.state.municipality_uuid_by_item_uuid && this.state.municipality_uuid_by_item_uuid[value] != undefined && value != null) {
                this.setValue(`task.items.${itemIndex}.communal_municipality_uuid`, this.state.municipality_uuid_by_item_uuid[value]);
            } else {
                this.setValue(`task.items.${itemIndex}.communal_municipality_uuid`, null);
            } */

			this.storeGeometry(itemIndex, 'items');
			this.loadRoadPartWorkTypes([ value ]);
		}

		const sliceMatches = /^task\.items\.([0-9]+)\.(part_start|part_end)/.exec(field);
		if (sliceMatches) {
			const itemIndex = sliceMatches[1];
			this.storeGeometry(itemIndex, 'items');
		}

		const directionMatches = /^task\.items\.([0-9]+)\.geometry\.([0-9]+)\.direction/.exec(field);
		if (directionMatches) {
			const itemIndex = directionMatches[1];
			this.storeGeometry(itemIndex, 'items');
		}

		const addressMatches = /^task\.items\.([0-9]+)\.geometry\.([0-9]+)\.address/.exec(field);
		if (addressMatches) {
			const itemIndex = addressMatches[1];
			const index = addressMatches[2];

			this.addressSearchDebounce(itemIndex, index, 'items');
		}

		const latMatches = /^task\.items\.([0-9]+)\.geometry\.([0-9]+)\.latitude/.exec(field);
		if (latMatches) {
			const itemIndex = latMatches[1];
			const index = latMatches[2];

			await this.setValue(`task.items.${itemIndex}.geometry.${index}.is_address`, true, true);
			await this.setValue(`task.items.${itemIndex}.geometry.${index}.address`, null, true);
			// this.calcPath(itemIndex);
		}

		const lonMatches = /^task\.items\.([0-9]+)\.geometry\.([0-9]+)\.longitude/.exec(field);
		if (lonMatches) {
			const itemIndex = lonMatches[1];
			const index = lonMatches[2];

			await this.setValue(`task.items.${itemIndex}.geometry.${index}.is_address`, true, true);
			await this.setValue(`task.items.${itemIndex}.geometry.${index}.address`, null, true);
			// this.calcPath(itemIndex, 'items');
		}

		const movementMatches = /^task\.items\.([0-9]+)\.movement_type$/.exec(field);
		if (movementMatches) {
			this.setValue(`task.items.${movementMatches[1]}.geometry_type`, value === 'idle' ? 'idle' : 'other', true);
			this.setValue(`task.items.${movementMatches[1]}.geometry`, []);
			//this.fillIdle('items');
		}
	}

	async checkFact(field, value) {
		const matches = /^task\.items_fact\.([0-9]+)\.geometry_type$/.exec(field);
		if (matches) {
			if (value === 'idle') {
				await this.setValue(`task.items_fact.${matches[1]}.movement_type`, 'idle');
				return;
			} else {
				await this.setValue(`task.items_fact.${matches[1]}.movement_type`, 'work', true);
			}
			if (!value || value === 'other') {
				await this.setValue(`task.items_fact.${matches[1]}.geometry`, []);
			} else {
				await this.setValue(`task.items_fact.${matches[1]}.geometry`, [ {} ]);
			}
			this.refs[`task.items_fact.${matches[1]}.item_uuid`] &&
				this.refs[`task.items_fact.${matches[1]}.item_uuid`].reload();
		}

		const fromMatches = /^task\.items_fact\.([0-9]+)\.date_from/.exec(field);
		const toMatches = /^task\.items_fact\.([0-9]+)\.date_to/.exec(field);
		if (fromMatches || toMatches) {
			const itemIndex = fromMatches ? fromMatches[1] : toMatches[1];
			const from = moment(this.get(`items_fact.${itemIndex}.date_from`), formats.TIME);
			const to = moment(this.get(`items_fact.${itemIndex}.date_to`), formats.TIME);
			const time = Math.abs(from.diff(to, 'minutes'));
			this.setValue(`task.items_fact.${itemIndex}.time`, this.formatTime(time), true);
		}
		const timeMatches = /^task\.items_fact\.([0-9]+)\.time/.exec(field);
		if (timeMatches) {
			const itemIndex = timeMatches[1];
			const from = moment(this.get(`items_fact.${itemIndex}.date_from`), formats.TIME);
			const time = moment(this.get(`items_fact.${itemIndex}.time`), formats.TIME).diff(
				moment('00:00', formats.TIME),
				'minutes'
			);
			const to = from.add(time, 'minutes').format(formats.TIME);
			this.setValue(`task.items_fact.${itemIndex}.date_to`, to, true);
		}

		const fromFactMatches = /^task\.items_fact\.([0-9]+)\.fact_date_from/.exec(field);
		const toFactMatches = /^task\.items_fact\.([0-9]+)\.fact_date_to/.exec(field);
		if (fromFactMatches || toFactMatches) {
			const itemIndex = fromFactMatches ? fromFactMatches[1] : toFactMatches[1];
			const from = moment(this.get(`items_fact.${itemIndex}.fact_date_from`), formats.TIME_FULL);
			const to = moment(this.get(`items_fact.${itemIndex}.fact_date_to`), formats.TIME_FULL);
			const time = Math.abs(from.diff(to, 'seconds'));
			this.setValue(`task.items_fact.${itemIndex}.fact_time`, this.formatSeconds(time), true);
		}
		const timeFactMatches = /^task\.items_fact\.([0-9]+)\.fact_time/.exec(field);
		if (timeFactMatches) {
			const itemIndex = timeFactMatches[1];
			const from = moment(this.get(`items_fact.${itemIndex}.fact_date_from`), formats.TIME_FULL);
			const time = moment(this.get(`items_fact.${itemIndex}.fact_time`), formats.TIME_FULL).diff(
				moment('00:00:00', formats.TIME_FULL),
				'seconds'
			);
			const to = from.add(time, 'seconds').format(formats.TIME_FULL);
			this.setValue(`task.items_fact.${itemIndex}.fact_date_to`, to, true);
		}

		const itemMatches = /^task\.items_fact\.([0-9]+)\.geometry\.([0-9]+)\.item_uuid/.exec(field);
		if (itemMatches) {
			const itemIndex = itemMatches[1];
			await this.setValue(`task.items_fact.${itemMatches[1]}.part_start`, null, true);
			await this.setValue(`task.items_fact.${itemMatches[1]}.part_end`, null, true);
			this.storeGeometry(itemIndex, 'items_fact');
		}

		const sliceMatches = /^task\.items_fact\.([0-9]+)\.(part_start|part_end)/.exec(field);
		if (sliceMatches) {
			const itemIndex = sliceMatches[1];
			this.storeGeometry(itemIndex, 'items_fact');
		}

		const directionMatches = /^task\.items_fact\.([0-9]+)\.geometry\.([0-9]+)\.direction/.exec(field);
		if (directionMatches) {
			const itemIndex = directionMatches[1];
			this.storeGeometry(itemIndex, 'items_fact');
		}

		const addressMatches = /^task\.items_fact\.([0-9]+)\.geometry\.([0-9]+)\.address/.exec(field);
		if (addressMatches) {
			const itemIndex = addressMatches[1];
			const index = addressMatches[2];

			this.addressSearchDebounce(itemIndex, index, 'items_fact');
		}

		const latMatches = /^task\.items_fact\.([0-9]+)\.geometry\.([0-9]+)\.latitude/.exec(field);
		if (latMatches) {
			const itemIndex = latMatches[1];
			const index = latMatches[2];

			await this.setValue(`task.items_fact.${itemIndex}.geometry.${index}.is_address`, true, true);
			await this.setValue(`task.items_fact.${itemIndex}.geometry.${index}.address`, null, true);
			// this.calcPath(itemIndex, 'items_fact');
		}

		const lonMatches = /^task\.items\.([0-9]+)\.geometry\.([0-9]+)\.longitude/.exec(field);
		if (lonMatches) {
			const itemIndex = lonMatches[1];
			const index = lonMatches[2];

			await this.setValue(`task.items.${itemIndex}.geometry.${index}.is_address`, true, true);
			await this.setValue(`task.items.${itemIndex}.geometry.${index}.address`, null, true);
			// this.calcPath(itemIndex);
		}

		const movementMatches = /^task\.items_fact\.([0-9]+)\.movement_type$/.exec(field);
		if (movementMatches) {
			this.setValue(`task.items_fact.${movementMatches[1]}.geometry_type`, value === 'idle' ? 'idle' : 'other', true);
			this.setValue(`task.items_fact.${movementMatches[1]}.geometry`, []);
			//this.fillIdle('items_fact');
		}
	}

	formatTime(minutes) {
		if (isNaN(minutes)) {
			return '00:00';
		}
		return _.padStart(Math.floor(minutes / 60), 2, '0') + ':' + _.padStart(minutes % 60, 2, '0');
	}

	formatSeconds(seconds) {
		if (isNaN(seconds)) {
			return '00:00:00';
		}
		return (
			_.padStart(Math.floor(seconds / 3600), 2, '0') +
			':' +
			_.padStart(Math.floor((seconds % 3600) / 60), 2, '0') +
			':' +
			_.padStart(seconds % 60, 2, '0')
		);
	}

	toggleVehiclesInfo() {
		this.setState({
			vehiclesInfoActive: !this.state.vehiclesInfoActive
		});
	}

	closeVehiclesInfo() {
		this.setState({
			vehiclesInfoActive: false
		});
	}

	toggleWorkTypeNormals() {
		this.setState({
			workTypeNormals: !this.state.workTypeNormals
		});
	}

	closeWorkTypeNormals() {
		this.setState({
			workTypeNormals: false
		});
	}

	renderResourcesModal() {
		const technocardVehicles = this.getTechnocardVehicles(true);
		const vehiclesCount = technocardVehicles !== null ? _.sum(_.values(technocardVehicles)) : null;
		const buttons = (
			<ModalTopMenuButtons>
				{vehiclesCount > 0 ? (
					<div className="tasks-vehicles-plan-count">
						По тех. карте необходимо {vehiclesCount} ТС
						<ContextTooltip default="Рекомендации по тех.карте">
							<a href="javascript:void(0)" className="KursVehiclesInfoBtn" onClick={::this.toggleVehiclesInfo}>
								i
							</a>
						</ContextTooltip>
						{this.state.vehiclesInfoActive ? this.renderVehiclesInfo(technocardVehicles) : null}
					</div>
				) : null}
				<div className="tasks-vehicles-plan-count">
					<ContextTooltip default="Рекомендации по количеству ТС">
						<a href="javascript:void(0)" className="KursVehiclesInfoBtn" onClick={::this.toggleWorkTypeNormals}>
							i
						</a>
					</ContextTooltip>
					{this.state.workTypeNormals ? this.renderWorkTypeNormals() : null}
				</div>
				<ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
					<ModalTopMenuButton className="_close" onClick={::this.hideResources} />
				</ContextTooltip>
			</ModalTopMenuButtons>
		);

		return (
			<PageModalComponent
				header={{ title: 'Назначение ТС', buttons }}
				onClose={::this.hideResources}
				className="b-modal-edit-ts-list"
				buttons={this.getResourcesButtons()}
			>
				{this.renderResources()}
			</PageModalComponent>
		);
	}

	renderCleanupSpecialistsModal() {
		const buttons = (
			<ModalTopMenuButtons>
				<ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
					<ModalTopMenuButton className="_close" onClick={::this.hideCleanupSpecialists} />
				</ContextTooltip>
			</ModalTopMenuButtons>
		);

		return (
			<PageModalComponent
				header={{ title: 'Назначение СПУ', buttons }}
				onClose={::this.hideResources}
				className="b-modal-edit-ts-list"
				buttons={this.getCleanupSpecialistsButtons()}
			>
				{this.renderCleanupSpecialists()}
			</PageModalComponent>
		);
	}

	renderVehiclesInfo(technocardVehicles) {
		return (
			<Popup className="top-link KursVehiclesInfo" show={true} onClose={::this.closeVehiclesInfo}>
				<div className="popup-container__content">
					{_.map(technocardVehicles, (cnt, vehicleTypeUuid) => {
						return (
							<div key={vehicleTypeUuid}>
								{_.get(
									_.find(this.state.vehicle_types, {
										value: vehicleTypeUuid
									}),
									'label'
								)}
								: {cnt}
							</div>
						);
					})}
				</div>
			</Popup>
		);
	}

	getItemsT(workTypeUuid) {
		const mechanismTypes = _.map(
			_.filter(this.state.kurs_mechanism_uses, (item) => {
				const workTypes = JSON.parse(_.get(item, 'document.work_types') || '[]');

				return _.indexOf(workTypes, workTypeUuid) !== -1;
			}),
			'document.mechanism_type_uuid'
		);

		return (
			_.max(
				_.map(
					_.filter(this.state.kurs_mechanism_bindings, (item) => {
						return _.indexOf(mechanismTypes, _.get(item, 'document.mechanism_type_uuid')) !== -1;
					}),
					(item) => {
						return _.toNumber(_.get(item, 'document.max_speed'));
					}
				)
			) || 0
		);
	}

	renderWorkTypeNormals() {
		const workTypes = _.filter(_.uniq(_.map(this.get('items'), 'work_type_uuid')));

		return (
			<Popup className="top-link KursWorkTypeNormals" show={true} onClose={::this.closeWorkTypeNormals}>
				<div className="popup-container__content">
					{_.map(workTypes, (workTypeUuid) => {
						const items = _.filter(this.get('items'), {
							work_type_uuid: workTypeUuid
						});

						const from = _.get(_.first(_.orderBy(items, [ 'date_from' ], [ 'asc' ])), 'date_from');
						const to = _.get(_.first(_.orderBy(items, [ 'date_to' ], [ 'desc' ])), 'date_to');
						const r = (from && to ? this.timeToInt(to) - this.timeToInt(from) : 0) / 60;
						const d = _.sumBy(items, (item) => {
							return _.toNumber(item.distance || 0);
						});
						const t = this.getItemsT(workTypeUuid);

						let text = 'нет связанных механизмов';
						if (t > 0) {
							if (d / t > r) {
								text = 'недостаточно ТС';
							} else {
								text = 'достаточно ТС';
							}
						}

						return (
							<div key={workTypeUuid}>
								{_.get(
									_.find(this.state.work_types, {
										value: workTypeUuid
									}),
									'label'
								)}
								: {text}
							</div>
						);
					})}
				</div>
			</Popup>
		);
	}

	timeToInt(time) {
		const timeObject = moment(time, formats.TIME);
		return timeObject.hours() * 60 + timeObject.minutes();
	}

	getResourcesButtons() {
		if (!this.isEditable('resources')) {
			return [];
		}

		return [
			<div key="tooltip" className="b-modal__footer-txt">
				Вы хотите сохранить все изменения?
			</div>,
			<a
				key="cancel"
				href="javascript:void(0)"
				className="b-button b-button_size_md b-button_white b-button_shadow_gray b-button_cancel"
				onClick={::this.hideResources}
			>
				Отменить
			</a>,
			<a
				key="save"
				href="javascript:void(0)"
				className="b-button b-button_red b-button_size_md b-button_save"
				onClick={::this.hideResources}
			>
				Сохранить
			</a>
		];
	}

	getCleanupSpecialistsButtons() {
		return [
			<div key="tooltip" className="b-modal__footer-txt">
				Вы хотите сохранить все изменения?
			</div>,
			<a
				key="cancel"
				href="javascript:void(0)"
				className="b-button b-button_size_md b-button_white b-button_shadow_gray b-button_cancel"
				onClick={::this.hideCleanupSpecialists}
			>
				Отменить
			</a>,
			<a
				key="save"
				href="javascript:void(0)"
				className="b-button b-button_red b-button_size_md b-button_save"
				onClick={::this.hideCleanupSpecialists}
			>
				Сохранить
			</a>
		];
	}

	renderCleanupSpecialists() {
		return (
			<TableContainer>
				<div className="Table">
					<table className="b-table b-table-no-hover">
						<thead>
							<tr>
								<th>ФИО</th>
								<th className="column-delete" />
							</tr>
						</thead>
						<tbody>{this.get('cleanup_specialist_uuids', []).map(::this.renderCleanupSpecialist)}</tbody>
					</table>
					<span className="add-line" onClick={::this.addCleanupSpecialist}>
						Добавить строку
					</span>
				</div>
			</TableContainer>
		);
	}

	renderResources() {
		return (
			<TableContainer>
				<div className="Table">
					<table className="b-table b-table-no-hover">
						<thead>
							<tr>
								<th>Тип ТС</th>
								<th>Номер ТС</th>
								<th>Водитель</th>
								{!window.RNIS_SETTINGS.hide_pl ? <th>ПЛ</th> : null}
								{this.isEditable('resources') ? <th /> : null}
							</tr>
						</thead>
						<tbody>{this.get('resources', []).map(::this.renderResource)}</tbody>
					</table>
					{this.isEditable('resources') ? (
						<span className="add-line" onClick={::this.addResource}>
							Добавить строку
						</span>
					) : null}
				</div>
			</TableContainer>
		);
	}

	async getOpenedWaybillTaskNumber(vehicleUuid) {
		const response = await this.props.getWaybills({
			filters: {
				withStatus: 'opened',
				withVehicle: vehicleUuid,
				withDate: this.get('date')
			},
			response_data: [ 'items/task_uuid' ]
		});

		if (response.isOk) {
			const taskUuid = _.get(_.first(response.payload.items), 'task_uuid');
			if (taskUuid) {
				const taskResponse = await this.props.getTask(taskUuid);
				if (taskResponse.isOk) {
					return taskResponse.payload.number;
				}
			}
		}

		return null;
	}

	isCheckedVehicle(uuid) {
		if (uuid) {
			return _.indexOf(this.state.checkedVehicles || [], uuid) !== -1;
		}

		return true;
	}

	renderCleanupSpecialist(cleanupSpecialist, index) {
		return (
			<tr key={index}>
				<td className="input-cell">
					{this.selectAsync(`task.cleanup_specialist_uuids.${index}`, (input, callback) =>
						this.cleanupSpecialistsDebounce[index](index, input, callback)
					)}
				</td>
				<td className="align-center vertical-middle column-delete">
					<span className="edit" onClick={this.deleteCleanupSpecialist.bind(this, index)}>
						x
					</span>
				</td>
			</tr>
		);
	}

	renderResource(resource, index) {
		const waybill = _.find(this.state.waybills, {
			vehicle_uuid: resource.base_vehicle_uuid
		});

		return (
			<tr key={index}>
				<td className="input-cell">
					{this.select(`task.resources.${index}.vehicle_type_uuid`, this.state.vehicle_types, {
						disabled: !this.isEditable('resources'),
						onChange: (e) => {
							const value = e ? e.value : null;
							this.setValue(`task.resources.${index}.vehicle_type_uuid`, value);

							this.refs[`task.resources.${index}.vehicle_uuid`] &&
								this.refs[`task.resources.${index}.vehicle_uuid`].reload();
						}
					})}
				</td>
				<td className="input-cell">
					{this.selectAsync(
						`task.resources.${index}.vehicle_uuid`,
						async (input, callback) => {
							return await this.loadVehicles(index, input, callback);
						},
						{
							disabled: !this.isEditable('resources'),
							ref: `task.resources.${index}.vehicle_uuid`,
							onChange: async (e) => {
								const value = e ? e.value : null;
								const baseVehicleUuid = e ? e.base_vehicle_uuid : null;
								const canSelect = e ? e.can_select : true;
								const drivers = e ? e.drivers : [];

								if (!canSelect) {
									return;
								}

								if (value) {
									const isChecked = await this.checkVehicleWorkTypes(value);
									if (!isChecked) {
										alerts.error('Назначьте ТС с механизмом для выполнения работ');
										return;
									}
								}

								const callback = async () => {
									this.setValue(`task.resources.${index}.vehicle_uuid`, value);
									this.setValue(`task.resources.${index}.base_vehicle_uuid`, baseVehicleUuid);
									this.setValue(`task.resources.${index}.vehicle_type_uuid`, _.get(e, 'vehicle_type_uuid'));
									this.setValue(`task.resources.${index}.driver_uuid`, _.first(drivers));
									this.setValue(`task.resources.${index}.drivers`, await this.loadVehicleDrivers(drivers));

									this.refs[`task.resources.${index}.driver_uuid`] &&
										this.refs[`task.resources.${index}.driver_uuid`].reload();
								};

								const openedWaybillTaskNumber = await this.getOpenedWaybillTaskNumber(baseVehicleUuid);
								if (openedWaybillTaskNumber) {
									alerts.prompt(
										`Данное ТС уже участвует в другом задании №${openedWaybillTaskNumber}. Добавить в задание?`,
										'',
										callback,
										'Добавить'
									);
								} else {
									callback();
								}
							}
						}
					)}
				</td>
				<td className="input-cell">
					{this.selectAsync(
						`task.resources.${index}.driver_uuid`,
						async (input, callback) => {
							const drivers = this.get(`resources.${index}.drivers`) || [];
							await this.loadDrivers(drivers, index, input, callback);
						},
						{
							disabled: !this.isEditable('resources'),
							ref: `task.resources.${index}.driver_uuid`
						}
					)}
				</td>
				{!window.RNIS_SETTINGS.hide_pl ? (
					<td className="input-cell">
						{waybill ? (
							<Link to={`/utility/waybills/${waybill.uuid}`} className="has-waybill">
								Да
							</Link>
						) : !resource.tmp &&
						_.get(
							_.find(this.state.kurs_task_statuses, {
								value: _.get(this.props.data, 'status_uuid')
							}),
							'label'
						) === 'Открыт' ? (
							<Link
								to={`/utility/waybills/create?driverUuid=${resource.driver_uuid ||
									''}&vehicleUuid=${resource.base_vehicle_uuid || ''}&taskUuid=${this.get('uuid')}&unitUuid=${this.get(
									'unit_uuid'
								)}`}
								className="no-waybill"
							>
								+
							</Link>
						) : null}
					</td>
				) : null}
				{this.isEditable('resources') ? (
					<td className="align-center vertical-middle">
						{!waybill || waybill.status !== 'opened' ? (
							<span className="edit" onClick={this.deleteResource.bind(this, index)}>
								x
							</span>
						) : null}
					</td>
				) : null}
			</tr>
		);
	}

	async loadVehicleDrivers(drivers) {
		const response = await this.props.getUsers({
			filters: {
				withPositionTypes: [ 'driver' ],
				withComponent: 'utility',
				withUuid: drivers
			},
			pagination: {
				page: 1,
				limit: 20
			}
		});

		if (response.isOk) {
			return _.sortBy(
				response.payload.items.map((i) => {
					return {
						label: new User(i).getFullName(),
						value: i.uuid
					};
				}),
				'label'
			);
		}
		return [];
	}

	addResource(e) {
		e.preventDefault();

		let task = this.state.task;
		task.resources = task.resources || [];
		task.resources.push({
			tmp: true
		});

		this.setState({ task });
	}

	addCleanupSpecialist(e) {
		e.preventDefault();

		let task = this.state.task;
		task.cleanup_specialist_uuids = task.cleanup_specialist_uuids || [];
		task.cleanup_specialist_uuids.push('');

		this.setState({ task });

		this.cleanupSpecialistsDebounce.push(debounce(500, ::this.getCleanupSpecialists));
	}

	deleteResource(index, e) {
		e.preventDefault();

		let task = this.state.task;
		task.resources.splice(index, 1);
		this.setState({ task });

		this.props.onUpdate();
	}

	deleteCleanupSpecialist(index, e) {
		e.preventDefault();

		let task = Object.assign({}, this.state.task);
		task.cleanup_specialist_uuids.splice(index, 1);
		this.setState({ task });

		this.props.onUpdate();

		this.cleanupSpecialistsDebounce.splice(index, 1);
	}

	async loadDrivers(drivers, index, input, callback) {
		if (!input) {
			input = this.get(`resources.${index}.driver_uuid`);
		}

        const result = await this.props.getUsers({
            filters: {
                withPositionTypes: [
                    'driver',
                ],
                withComponent: 'utility',
            },
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
        });

		if (result.isOk) {
			callback(null, {
				options: _.concat(
					drivers,
					_.sortBy(
						result.payload.items.map((i) => {
							return {
								label: new User(i).getFullName(),
								value: i.uuid
							};
						}),
						'label'
					)
				),
				complete: false
			});
		} else {
			result.showErrors();
		}
	}

	async checkVehicleWorkTypes(vehicleUuid = null) {
		if (Settings.get('kurs_task_limit_work_types') !== '1') {
			return vehicleUuid ? true : false;
		}

		const workTypes = _.map(_.concat(this.get('items_fact') || [], this.get('items') || []), 'work_type_uuid');
		const response = await this.props.getVehicles({
			filters: {
				withWorkTypes: workTypes,
				withUuid: vehicleUuid ? [ vehicleUuid ] : _.map(this.get('resources'), 'vehicle_uuid') || []
			}
		});

		if (response.isOk) {
			if (vehicleUuid) {
				return response.payload.items.length > 0;
			}

			this.setState({
				checkedVehicles: _.map(response.payload.items, 'uuid')
			});
		}
	}

	async loadVehicles(index, input, callback) {
		const filters = {}
		const vehicleTypeUuid = this.get(`resources.${index}.vehicle_type_uuid`)

		if (!input) {
			input = this.get(`resources.${index}.vehicle_uuid`);
		}

		if (!input && vehicleTypeUuid) {
			filters.withTypes = [vehicleTypeUuid]
		}

		const result = await this.props.getVehicles({
			filters,
			search: input,
			pagination: {
				page: 1,
				limit: 20
			}
		});

		if (result.isOk) {
			callback(null, {
				options: result.payload.items.map((item) => ({
					value: item.uuid,
					label: (
						<div
							className={classNames({
								'option-deleted': _.get(item, 'general.malfunctions', []).length > 0
							})}
						>
							{item.state_number}
						</div>
					),
					base_vehicle_uuid: item.vehicle_uuid,
					vehicle_type_uuid: item.vehicle_type_uuid,
					can_select: _.get(item, 'general.malfunctions', []).length === 0,
					drivers: _.filter([ _.get(item, 'general.driver_uuid'), _.get(item, 'general.driver2_uuid') ])
				})),
				complete: false
			});
		} else {
			result.showErrors();
		}
	}

	async loadTaskViolations() {
		const response = await this.props.getTaskViolations({
			filters: {
				withTask: this.state.task.uuid
			}
		});

		if (response.isOk) {
			await this.setState({
				violations: response.payload.items
			});
		} else {
			response.showErrors();
		}
	}

	addRoadParts(items) {
		let roadParts = this.state.roadParts || {};
		_.each(items, (item) => {
			roadParts[item.uuid] = `${item.register_number} ${item.name}`;
		});

		this.setState({
			roadParts
		});
	}

	async getRoadParts() {
		return await await this.props.getRoadParts({
			pagination: {
				page: 1,
				limit: 20
			},
			response_data: [ 'items/uuid', 'items/register_number', 'items/name', 'items/communal_municipality_uuid' ]
		});
	}

	async loadObjects(index, input, callback, field, communal_uuid) {
		const type = this.get(`${field}.${index}.geometry_type`);

		if (!input) {
			input = this.get(`${field}.${index}.geometry.0.item_uuid`);
		}
		let result;
		switch (type) {
			case 'road_part':
				let meta = {
					pagination: {
						page: 1,
						limit: 20
					},
					response_data: [ 'items/uuid', 'items/register_number', 'items/name', 'items/communal_municipality_uuid' ]
				};
				if (communal_uuid) {
					meta.filters = {
						withCommunalMunicipalities: [ communal_uuid ]
					};
				}

				if (input) {
					meta.search = input;
				}
				await this.setState({
					reload: []
				});
				result = await this.props.getRoadParts(meta);

				if (result.isOk) {
					const final = [ ...result.payload.items ];
					this.addRoadParts(final);

					callback(null, {
						options: _.sortBy(
							final.map((item) => {
								return {
									value: item.uuid,
									label: `${item.register_number} ${item.name}`
								};
							}),
							'label'
						),
						complete: false
					});
				} else {
					result.showErrors();
				}
				break;
			case 'road_repair_part':
				result = await this.props.getDictionaryList(
					'kurs_road_repair_parts',
					{
						search: input,
						pagination: {
							page: 1,
							limit: 20
						}
					},
					false
				);

				if (result.isOk) {
					callback(null, {
						options: _.sortBy(
							result.payload.documents.map((item) => ({
								value: item.uuid,
								label: item.name
							})),
							'label'
						),
						complete: false
					});
				} else {
					result.showErrors();
				}
				break;
			case 'stop_point':
				result = await this.props.getStopPoints({
					search: input,
					pagination: {
						page: 1,
						limit: 20
					}
				});

				if (result.isOk) {
					callback(null, {
						options: _.sortBy(
							result.payload.items.map((item) => ({
								value: item.uuid,
								label: `${item.register_number} ${item.title}`
							})),
							'label'
						),
						complete: false
					});
				} else {
					result.showErrors();
				}
				break;
		}
	}

	async getCoordinates(itemIndex, index) {
		const area = this.get(`items.${itemIndex}.geometry.${index}.control_point.area_name`);
		const locality = this.get(`items.${itemIndex}.geometry.${index}.control_point.locality_name`);
		const street = this.get(`items.${itemIndex}.geometry.${index}.control_point.street_name`);
		const house = this.get(`items.${itemIndex}.geometry.${index}.control_point.house`);

		if (area && locality && street && house) {
			const address = `${area}, ${locality}, ${street}, ${house}`;

			const response = await this.props.geocode({
				q: address
			});
			if (response.isOk) {
				const coordinates = response.payload.coordinates;
				this.setValue(`task.items.${itemIndex}.geometry.${index}.control_point.latitude`, coordinates.latitude, true);
				this.setValue(`task.items.${itemIndex}.geometry.${index}.control_point.longitude`, coordinates.longitude, true);
			}
		}
	}

	onChangeInput(field, { target }) {
		const value = _.get(target, 'value');
		const label = _.get(target, 'label');

		this.setValue(field, value);
		if (label) {
			this.setValue(field + '_name', label, true);
		}
	}

	onChangeDistance(e, field, index) {
		this.setValue(`task.${field}.${index}.distance`, e.target.value);
		this.setValue(`task.${field}.${index}.count`, e.target.value);
	}

	async searchAddress(itemIndex, index) {
		const input = this.get(`items.${itemIndex}.geometry.${index}.address`);

		const response = await this.props.geocode({
			q: input
		});

		if (response.isOk) {
			await Promise.all([
				this.setValue(
					`task.items.${itemIndex}.geometry.${index}.latitude`,
					response.payload.coordinates.latitude,
					true
				),
				this.setValue(
					`task.items.${itemIndex}.geometry.${index}.longitude`,
					response.payload.coordinates.longitude,
					true
				)
			]);

			// this.calcPath(itemIndex);
		} else {
			response.showErrors();
		}
	}

	isEditableByType(type) {
		const status = _.get(
			_.find(this.state.kurs_task_statuses, {
				value: _.get(this.props.data, 'status_uuid')
			}),
			'label'
		);

		if (type === 'plan') {
			return !status || status === 'Черновик' || status === 'Открыт';
		} else if (type === 'fact') {
			return _.indexOf([ 'В работе', 'На рассмотрении' ], status) !== -1;
		}

		return false;
	}

	isEditableBySkpdi(item) {
		return !(item.external_id && Settings.get('kurs_skpdi_task_items_edit_deny') === '1');
	}

	isEditable(field) {
		const status = _.get(
			_.find(this.state.kurs_task_statuses, {
				value: _.get(this.props.data, 'status_uuid')
			}),
			'label'
		);

		if (!status || status === 'Черновик') {
			return true;
		}

		if (status === 'Открыт') {
			return _.indexOf([ 'responsive_uuid', 'resources', 'confirm_by_single' ], field) !== -1;
		}

		if (status === 'В работе') {
			return _.indexOf([ 'responsive_uuid' ], field) !== -1;
		}

		if (status === 'На рассмотрении') {
			return _.indexOf([ 'responsive_uuid' ], field) !== -1;
		}

		if (status === 'Закрыт') {
			return _.indexOf([], field) !== -1;
		}

		return false;
	}

	isFactVisible() {
		const status = _.get(
			_.find(this.state.kurs_task_statuses, {
				value: _.get(this.props.data, 'status_uuid')
			}),
			'label'
		);

		return _.indexOf([ 'В работе', 'На рассмотрении', 'Закрыт' ], status) !== -1;
	}

	renderItemGeometry(itemIndex, isEditable, field, geometry, index) {
		const isIdle = this.get(`${field}.${itemIndex}.movement_type`) === 'idle';

		return (
			<div className="check-points">
				<Block title="Широта">
					{this.textInput(`task.${field}.${itemIndex}.geometry.${index}.latitude`, {
						disabled:
							!isEditable ||
							(isIdle &&
								((itemIndex !== 0 && index === 0) ||
									(itemIndex !== this.state.task[field].length - 1 &&
										index === this.state.task[field][itemIndex].geometry.length - 1)))
					})}
				</Block>
				<Block title="Долгота">
					{this.textInput(`task.${field}.${itemIndex}.geometry.${index}.longitude`, {
						disabled:
							!isEditable ||
							(isIdle &&
								((itemIndex !== 0 && index === 0) ||
									(itemIndex !== this.state.task[field].length - 1 &&
										index === this.state.task[field][itemIndex].geometry.length - 1)))
					})}
				</Block>
				<Block size="lg" title="Адрес">
					{this.textInput(`task.${field}.${itemIndex}.geometry.${index}.address`, {
						disabled:
							!isEditable ||
							(isIdle &&
								((itemIndex !== 0 && index === 0) ||
									(itemIndex !== this.state.task[field].length - 1 &&
										index === this.state.task[field][itemIndex].geometry.length - 1)))
					})}
				</Block>

				{isEditable &&
				!(
					isIdle &&
					((itemIndex !== 0 && index === 0) ||
						(itemIndex !== this.state.task[field].length - 1 &&
							index === this.state.task[field][itemIndex].geometry.length - 1))
				) ? (
					<Block size="xl">
						<a
							className="remove-point"
							href="javascript:void(0)"
							onClick={this.deleteGeometry.bind(this, itemIndex, index, field)}
						>
							- Удалить
						</a>
					</Block>
				) : null}
			</div>
		);
	}

	expandConfirm(index) {
		this.setState({
			expandedConfirmBlock: this.state.expandedConfirmBlock === index ? null : index
		});
	}

	setConfirmed(index, value) {
		this.setValue(`task.items_fact.${index}.is_confirmed`, value);
		this.setValue(`task.items_fact.${index}.is_confirmed_manual`, true);
	}

	async onChangeLayer(e, field, index) {
		const value = e ? e.value : null;

		this.setValue(`task.${field}.${index}.geometry.0.layer_uuid`, value);
		this.setState({
			userGeoObjectIsLoading: true
		});

		const response = await this.props.getLayerObjectsWithoutStore([ value ]);

		if (response.isOk) {
			const userGeoObjectsByLayers = Object.assign({}, this.state.userGeoObjectsByLayers);
			userGeoObjectsByLayers[value] = response.payload.items.map((ugo) => ({
				value: ugo.uuid,
				label: ugo.title,
				document: ugo
			}));

			this.setState({
				userGeoObjectsByLayers,
				userGeoObjectIsLoading: false
			});
		} else {
			response.showErrors();
		}
	}

	onChangeUserGeoObject(e, field, index, layer_uuid) {
		const value = e ? e.value : null;

		this.setValue(`task.${field}.${index}.geometry.0.item_uuid`, value);

		if (value) {
			const userGeoObject = this.state.userGeoObjectsByLayers[layer_uuid].find((usgo) => usgo.document.uuid === value);
			if (userGeoObject.document.volume) {
				this.setValue(`task.${field}.${index}.count`, userGeoObject.document.volume);
			}
		} else {
			this.setValue(`task.${field}.${index}.count`, '');
		}
	}

	async storeGeometry(itemIndex, field) {
		const uuid = this.get(`${field}.${itemIndex}.geometry.0.item_uuid`);
		if (!uuid) {
			this.setValue(`task.${field}.${itemIndex}.part_start`, '', true);
			this.setValue(`task.${field}.${itemIndex}.part_end`, '', true);
			this.setValue(`task.${field}.${itemIndex}.distance`, '', true);

			if (window.RNIS_SETTINGS.count_from_distance_or_object) {
				this.setValue(`task.${field}.${itemIndex}.count`, '', true);
			}
			return;
		}
		const type = this.get(`${field}.${itemIndex}.geometry_type`);
		const direction = this.get(`${field}.${itemIndex}.geometry.0.direction`);
		if (direction === null) {
			this.setValue(`task.${field}.${itemIndex}.geometry.0.direction`, 'forward');
		}
		let response;

		switch (type) {
			case 'road_part':
				response = await this.props.getRoadPart(uuid, {
					slice_start: this.get(`${field}.${itemIndex}.part_start`) || 0,
					slice_end: this.get(`${field}.${itemIndex}.part_end`) || 0
				});

				if (response.isOk) {
					let geojson = _.cloneDeep(response.payload.geometry);
					if (!geojson) {
						alerts.alert('Для данного участка дороги отсутствует геометрия, выбор данного участка невозможен');
						this.setValue(`task.${field}.${itemIndex}.geometry.0.item_uuid`, null, true);
						return;
					}
					if (this.get(`${field}.${itemIndex}.part_start`) === null) {
						this.setValue(`task.${field}.${itemIndex}.part_start`, 0, true);
					}
					if (this.get(`${field}.${itemIndex}.part_end`) === null) {
						this.setValue(`task.${field}.${itemIndex}.part_end`, _.round(response.payload.slice_length, 3), true);
					}
					this.setValue(`task.${field}.${itemIndex}.distance`, _.round(response.payload.slice_length, 3), true);
					if (window.RNIS_SETTINGS.count_from_distance_or_object) {
						this.setValue(`task.${field}.${itemIndex}.count`, _.round(response.payload.slice_length, 3), true);
					}
					if (direction === 'reverse') {
						_.reverse(geojson.coordinates);
					}
					await this.setValue(`task.${field}.${itemIndex}.geojson`, geojson, true);

					let task = this.state.task;
					for (let i = itemIndex; i >= 0; i--) {
						if (_.get(task, `${field}.${i}.movement_type`) === 'idle') {
							task.items.splice(i, 1);
						} else {
							break;
						}
					}
					await this.setState({ task });

					//this.fillIdle(field);
					/*this.setState({
                        expandedBlock: this.state.expandedBlock + 1,
                    });*/
				} else {
					response.showErrors();
				}
				break;
			case 'road_repair_part':
				response = await this.props.getRoadRepairPart({
					uuid,
					slice_start: this.get(`${field}.${itemIndex}.part_start`) || 0,
					slice_end: this.get(`${field}.${itemIndex}.part_end`) || 0
				});

				if (response.isOk) {
					let geojson = _.cloneDeep(response.payload.geometry);
					if (!geojson) {
						alerts.alert('Для данного участка ремонта дороги отсутствует геометрия, выбор данного участка невозможен');
						this.setValue(`task.${field}.${itemIndex}.geometry.0.item_uuid`, null, true);
						return;
					}
					if (this.get(`${field}.${itemIndex}.part_start`) === null) {
						this.setValue(`task.${field}.${itemIndex}.part_start`, 0, true);
					}
					if (this.get(`${field}.${itemIndex}.part_end`) === null) {
						this.setValue(`task.${field}.${itemIndex}.part_end`, _.round(response.payload.slice_length, 3), true);
					}
					this.setValue(`task.${field}.${itemIndex}.distance`, _.round(response.payload.slice_length, 3), true);

					if (window.RNIS_SETTINGS.count_from_distance_or_object) {
						this.setValue(`task.${field}.${itemIndex}.count`, _.round(response.payload.slice_length, 3), true);
					}
					if (direction === 'reverse') {
						_.reverse(geojson.coordinates);
					}
					await this.setValue(`task.${field}.${itemIndex}.geojson`, geojson, true);

					let task = this.state.task;
					for (let i = itemIndex; i >= 0; i--) {
						if (_.get(task, `${field}.${i}.movement_type`) === 'idle') {
							task.items.splice(i, 1);
						} else {
							break;
						}
					}
					await this.setState({ task });

					// this.fillIdle(field);
				} else {
					response.showErrors();
				}
				break;
			case 'stop_point':
				response = await this.props.getStopPoint(uuid);

				if (response.isOk) {
					await this.setValue(`task.${field}.${itemIndex}.geojson`, {
						type: 'Point',
						coordinates: [ response.payload.longitude, response.payload.latitude ]
					});

					let task = this.state.task;
					for (let i = itemIndex; i >= 0; i--) {
						if (_.get(task, `${field}.${i}.movement_type`) === 'idle') {
							task.items.splice(i, 1);
						} else {
							break;
						}
					}
					await this.setState({ task });

					// this.fillIdle(field);
					/*this.setState({
                        expandedBlock: this.state.expandedBlock + 1,
                    });*/
				} else {
					response.showErrors();
				}
				break;
			case 'user_geo_object':
				const userGeoObjectsForLayer = this.state.userGeoObjectsByLayers[
					this.getValue(`task.${field}.${itemIndex}.geometry.0.layer_uuid`)
				];
				const geojson = (userGeoObjectsForLayer.find((userGeoObject) => userGeoObject.value === uuid) || {
					document: {}
				}).document.geometry;
				this.setValue(`task.${field}.${itemIndex}.geojson`, geojson, true);
				break;
		}
	}

	getWorkTypes(field, index) {
		const uuid = this.get(`${field}.${index}.geometry.0.item_uuid`);
		const workTypes = null; //_.get(this.state.road_part_work_types, uuid);
		let allWorkTypes = this.state.work_types;

		/*if ((Settings.get('kurs_task_limit_work_types') === '1') && this.state.vehicleWorkTypes) {
            allWorkTypes = _.filter(allWorkTypes, (workType) => {
                return _.indexOf(this.state.vehicleWorkTypes, workType.value) !== -1;
            });
        }*/

		const selectedWorkType = this.get(`${field}.${index}.work_type_uuid`);
		if (_.isArray(workTypes)) {
			const workTypeUuids = _.map(workTypes, 'uuid');
			return _.filter(allWorkTypes, (workType) => {
				return workType.value === selectedWorkType || _.indexOf(workTypeUuids, workType.value) !== -1;
			});
		}

		return allWorkTypes;
	}

	async checkDriverRTiO(field, driverUuid) {
		if (!driverUuid) {
			return true;
		}

		const shiftStartTime = _.get(
			_.first(
				_.sortBy(
					_.filter(this.get('items') || [], (item) => {
						return item.date_from;
					}),
					'date_from'
				)
			),
			'date_from'
		);
		if (!shiftStartTime) {
			return true;
		}

		const driver = await this.getDriver(driverUuid);
		if (!driver) {
			return true;
		}

		const response = await this.props.getWaybills({
			filters: {
				withDriver: driverUuid,
				withDate: moment(this.get('date')).subtract(1, 'day').format(formats.DATE_API)
			}
		});
		if (response.isOk) {
			const work_graphic_uuid = _.get(
				_.find(driver.work_graphics || [], (graphic) => {
					return (
						moment(graphic.date_from).isSameOrBefore(moment(), 'day') &&
						(!graphic.date_to || moment(graphic.date_to).isAfter(moment()))
					);
				}),
				'work_graphic_uuid'
			);
			const minRestHours = _.get(
				_.find(this.state.work_graphics, { value: work_graphic_uuid }),
				'document.min_rest_hours'
			);
			const waybill = _.first(response.payload.items);
			if (minRestHours && waybill && waybill.shift_end_time) {
				const shiftEnd = moment(moment(waybill.date).format(formats.DATE_URL) + ' ' + waybill.shift_end_time);
				const shiftStart = moment(moment(this.get('date')).format(formats.DATE_URL) + ' ' + shiftStartTime);

				if (shiftStart.diff(shiftEnd, 'hours') < minRestHours) {
					alerts.prompt(
						`Водитель ${new User(
							driver
						).getFullName()} отдыхал меньше положенного времени, вы уверены что хотите его назначить?`,
						'',
						() => {
							this.setValue(field, driverUuid, true);
						},
						'Назначить'
					);
				}
				return false;
			}
		} else {
			response.showErrors();
		}

		return true;
	}

	async getDriver(uuid) {
		const response = await this.props.getUser(uuid);

		if (response.isOk) {
			return response.payload;
		} else {
			response.showErrors();
		}
		return null;
	}

	async checkResources() {
		if (Settings.get('kurs_task_limit_work_types') !== '1') {
			return;
		}

		const workTypes = _.map(_.concat(this.get('items_fact') || [], this.get('items') || []), 'work_type_uuid');
		const response = await this.props.getVehicles({
			filters: {
				withWorkTypes: workTypes,
				withWorkTypesOnDate: [ this.get('date'), workTypes ],
				onlyActive: true
			},
			pagination: {
				page: 1,
				limit: 10000
			}
		});

		if (response.isOk) {
			const vehicles = _.map(response.payload.items, 'uuid');
			const resourceVehicles = _.filter(_.map(this.get('resources', []), 'vehicle_uuid'));
			const diff = _.difference(resourceVehicles, vehicles);
			if (diff.length > 0) {
				alerts.error('ТС не соответствует выбранным видам работ. Назначьте другое ТС');
				const resources = _.filter(this.get('resources', []), (resource) => {
					return _.indexOf(diff, resource.vehicle_uuid) === -1;
				});
				this.setValue('task.resources', resources);
			}
		}
	}
}
