import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import _ from 'lodash';
import {success, error} from 'helpers/response';

const initialState = new Map({
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }
    return state;
}

export const getAudit = (entityClass, entityUuid, humanNames = true, meta = {}) => async (dispatch) => {
    try {
        const response = await api.system.getAudit(entityClass, entityUuid, humanNames, meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};