import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import SelectFilter from './select-filter';

import './filter-header.less';

@propTypes({
    items: PropTypes.array,
    currentItem: PropTypes.number,
    currentTime: PropTypes.object,
    onChange: PropTypes.func
})

export default class FilterHeader extends Component {

    render() {


        return (
            <div className="filter-header">
                <SelectFilter
                    items={this.props.items}
                    classNames={this.props.classNames}
                    currentItem={this.props.currentItem}
                    onChange={::this.onChangeSelectFilter}/>
            </div>
        )
    }

    onChangeSelectFilter(newItem) {
        this.props.onChange({type: 'select', value: newItem});
    }

    onChangeTimeFilter(newTime) {
        this.props.onChange({type: 'time', value: newTime});
    }

}