import {DictionaryField} from "./dictionary_field";
import _ from 'lodash';

export class DictionaryMeta {
    constructor(meta) {
        this.__meta = meta;
        this.__meta.fields = _.map(this.__meta.fields, (field) => {
            return new DictionaryField(field);
        });
    }

    /**
     * @return {String}
     */
    get description() {
        return this.__meta.description;
    }

    /**
     * @return {Boolean}
     */
    get editable() {
        return this.__meta.editable;
    }

    /**
     * @return {Object}
     */
    get fields() {
        return this.__meta.fields;
    }

    /**
     * @param {String} key
     * @return {DictionaryField}
     */
    getField(key) {
        return _.find(this.__meta.fields, (field) => {
            return field.key == key;
        })
    }

    /**
     * @return {String}
     */
    get key() {
        return this.__meta.key;
    }

    /**
     * @return {String}
     */
    get title() {
        return this.__meta.title;
    }

    /**
     * @return {String}
     */
    get title_field() {
        return this.__meta.title_field;
    }

    /**
     * @param {Object} data
     * @param {Object} states
     * @return {Object}
     */
    prepareData(data, states) {
        _.forEach(data, (value, key) => {
            const field = this.getField(key);

            if (field) {
                data[key] = field.prepareValue(
                    this.getValueFromPreloadData(states, key, value)
                )
            }
        });

        return data;
    }

    getValueFromPreloadData(states, key, value)
    {
        const preloadedData = _.get(states, `loaded_${key}`);

        if (preloadedData) {
            const found = _.find(preloadedData, (row) => { return row.value == value });
            if (found) {
                if (found.deleted_at) {
                    return `<span class="entity-deleted" title="Сущность удалена">${found.label}</span>`;
                }
                return found.label;
            }
        }

        return value;
    }
}