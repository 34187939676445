import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';

import _ from 'lodash';
import formats from "dictionaries/formats";
import moment from "moment";
import * as alerts from "helpers/alerts";
import classNames from 'classnames';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {LabeledCheckbox} from "components/ui/checkbox";
import FormGroup from "components/ui/form-group";
import TextFieldMask from "components/ui/text-field-mask";
import TextField from "components/ui/text-field";
import Select, {SelectAsync} from 'components/ui/select';
import Input from "components/ui/form/input";
import Radio from "components/ui/form/radio";
import Checkbox from "components/ui/form/checkbox";
import Datepicker from "components/ui/form/datepicker";
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import Textarea from "components/ui/form/textarea";
import ContextTooltip from "components/ui/context-tooltip";
import debounce from 'throttle-debounce/debounce';

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    onClose: PropTypes.func,
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
})

export default class BaseEditorFormComponent extends Component {

    state = {
        dictionariesLoading: false,
        isEditingNow: false,
    };

    onChangeDebounceReal = debounce(500, ::this.onChangeInput);
    onChangeDebounce = (field, {target}) => {
        const value = _.get(target, 'value');
        const label = _.get(target, 'label');

        this.onChangeDebounceReal(field, {target: {value, label}});
    };

    async componentDidMount() {
        await this.setState(this.props.data);
    }


    getState() {
        return this.state;
    }

    getData() {
        return this.state;
    }

    /**
     * @param {String} field
     * @return {String}
     */
    getValue(field) {
        return _.get(this.getState(), field);
    }

    /**
     * @param {String} field
     * @param {*} value
     */
    async setValue(field, value) {
        let state = this.getState();

        _.set(state, field, value);

        return this.setState(state);
    }

    submit() {
        this.props.onSubmit(this.getState());
    }

    cancel() {
        this.props.onClose();
    }

    onDeleteClick() {
        alerts.prompt('Удалить?', '', () => {
            this.props.onDelete(this.getValue('uuid'));
        });
    }

    renderCheckbox(fieldName, label, props = {}) {
        return (
            <LabeledCheckbox
                checked={this.getValue(fieldName)}
                label={label}
                onChange={(value => this.onChangeInput(fieldName, {target: {value}}))}
                {...props} />
        );
    }

    renderDatepicker(fieldName, label, props = {}) {
        let value = this.getValue(fieldName);
        value = value && moment(value).format(formats.DATE);
        if (value === 'Invalid date') {
            value = null;
        }

        return this.renderInputMask(fieldName, label, '99.99.9999', value ? {value} : {});
    }


    renderInputMask(fieldName, label, mask, props = {}) {
        return (
            <FormGroup name={fieldName} label={label}>
                <TextFieldMask mask={mask} {...this.inputProps(fieldName)} {...props}/>
            </FormGroup>
        );
    }

    maskInput(fieldName, mask, props = {}) {
        return (
            <ContextTooltip key={`form.${fieldName}`} code={`form.${fieldName}`}>
                <TextFieldMask mask={mask} {...this.inputProps(fieldName)} {...props}/>
            </ContextTooltip>
        );
    }

    renderNumberField(fieldName, label, props = {}) {
        props['type'] = 'number';

        return (
            <FormGroup name={fieldName} label={label}>
                <TextField {...this.textFieldProps(fieldName)} {...props}/>
            </FormGroup>
        );
    }

    renderTextField(fieldName, label, props = {}) {
        return (
            <FormGroup name={fieldName} label={label}>
                <TextField {...this.textFieldProps(fieldName)} {...props}/>
            </FormGroup>
        );
    }

    textInput(fieldName, props = {}) {
        return (
            <ContextTooltip key={`form.${fieldName}`} code={`form.${fieldName}`}>
                <Input {...this.textFieldProps(fieldName, props)} {...props}/>
            </ContextTooltip>
        );
    }

    textarea(fieldName, props = {}) {
        return (
            <ContextTooltip key={`form.${fieldName}`} code={`form.${fieldName}`}>
                <Textarea {...this.textFieldProps(fieldName, props)} {...props}/>
            </ContextTooltip>
        );
    }

    wysiwyg(fieldName, props = {}) {
        props.onChange = (value) => {
            this.onChangeInput(fieldName, {target: {value}});
        };
        props.formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'align',
        ];
        props.modules = {
            toolbar: [
                [{'header': [1, 2, false]}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                [{'align': []}],
                ['link'],
                ['clean'],
            ],
        };

        const error = this.getError(fieldName);
        if (error) {
            props.className = 'wrong';
        }

        const styleClassName = classNames(error ? 'input__style_state_wrong' : '');

        let textFieldProps = this.textFieldProps(fieldName);
        textFieldProps.value = textFieldProps.value || '';

        return (
            <div>
                <ReactQuill {...textFieldProps} {...props}/>
                <div className={styleClassName}>
                    {error ?
                        <span className="input__style_state_msg_wrong">{error}</span> : null}
                </div>
            </div>
        );
    }

    select(fieldName, options = [], props = {}) {
        props.value = this.getValue(fieldName);
        props.name = fieldName;
        props.options = props.sort ? _.sortBy(options, 'label') : options;
        if (!props.onChange) {
            props.onChange = (e) => {
                let value = e ? e.value : null;

                if (props.multi) {
                    value = e;
                    value = _.map(value, 'value');
                }

                this.onChangeInput(fieldName, {target: {value}});
            };
        }

        const error = this.getError(fieldName);
        if (error) {
            props.className = 'wrong';
        }


        const textError = error !== "validation.required" ? error : ''
        const styleClassName = classNames(error ? 'input__style_state_wrong' : '');

        return (
            <ContextTooltip key={`form.${fieldName}`} code={`form.${fieldName}`}>
                <div>
                    <Select {...props}/>
                    <div className={styleClassName}>
                        {textError ?
                            <span className="input__style_state_msg_wrong">{textError}</span> : null}
                    </div>
                </div>
            </ContextTooltip>
        );
    }

    selectAsync(fieldName, loadOptions, props = {}) {
        props.value = this.getValue(fieldName);
        props.name = fieldName;
        props.loadOptions = loadOptions;
      
        if (props.blockAutoload) {
            props.autoload = false
        }
       
        if (!props.onChange) {
            props.onChange = (e) => {
                let value = e ? e.value : null;
                let label = e ? e.label : null;

                if (props.multi) {
                    value = e;
                    value = _.map(value, 'value');
                }

                this.onChangeInput(fieldName, {target: {value, label}});
            };
        }

        const error = this.getError(fieldName) || props.error;
        if (error) {
            props.className = 'wrong';
        }

        const textError = error !== "validation.required" ? error : ''
        const styleClassName = classNames(error ? 'input__style_state_wrong' : '');

        return (
            <ContextTooltip key={`form.${fieldName}`} code={`form.${fieldName}`}>
                <div>
                    {!props.withoutInit ? (
                        <SelectAsync {...props}/>
                    ) : null}
                    <div className={styleClassName}>
                        {textError ?
                            <span className="input__style_state_msg_wrong">{textError}</span> : null}
                    </div>
                </div>
            </ContextTooltip>
        );
    }

    radio(fieldName, radioValue, label, props = {}) {
        const value = this.getValue(fieldName);

        return (
            <ContextTooltip key={`form.${fieldName}.${radioValue}`} code={`form.${fieldName}.${radioValue}`}>
                <Radio
                    {...props}
                    checked={value === radioValue}
                    field={fieldName}
                    value={radioValue}
                    label={label}
                    onChange={this.onChangeInput.bind(this, fieldName)}
                />
            </ContextTooltip>
        );
    }

    checkbox(fieldName, label = '', props = {}) {
        const value = props.value || this.getValue(fieldName);
        const canChange = Object.keys(props).includes('canChange') ? props.canChange : true

        return (
            <ContextTooltip key={`form.${fieldName}`} code={`form.${fieldName}`}>
                <Checkbox
                    checked={_.isBoolean(value) ? value : value === '1'}
                    label={label}
                    onChange={canChange && this.newListener(fieldName)}
                    {...props} />
            </ContextTooltip>
        );
    }

    datepicker(fieldName, props = {}) {
        return (
            <ContextTooltip key={`form.${fieldName}`} code={`form.${fieldName}`}>
                <Datepicker
                    {...props}
                    value={this.getValue(fieldName)}
                    onChange={this.newListener(fieldName)}
                    error={this.getError(fieldName)}
                />
            </ContextTooltip>
        )
    }

    timepicker(fieldName, currentValue, minTime, maxTime) {
        const name = fieldName.split(".")[0];
        const selectedHour = currentValue ? formatting(currentValue.split(':')[0]) : null;
        let formatMinTime = minTime ? formatting(minTime.split(':')[0]) : null;
        let formatMaxTime = maxTime ? formatting(maxTime.split(':')[0] - logicSubstract()) : null;
        let formatMaxTimeMinutes = maxTime ? formatting(maxTime.split(':')[1]) : null;
        let formatMinTimeMinutes = minTime ? formatting(minTime.split(':')[1]) : null;

        function logicSubstract() {
            if (maxTime && maxTime.split(":")[1] === "00") {
                return 1
            } else {
                return 0
            }
        }

        function formatting(time) {
            if (time[0] === '0') {
                return time.replace("0", "")
            } else {
                return time
            }
        }

        function disabledHours(formatMinTime, formatMaxTime) {
            let disabledHours = [];
            for (let i=0; i < 24; i++) {
                disabledHours.push(i)
            }
            return disabledHours.filter(item => item < formatMinTime || item > formatMaxTime);
        }

        function disabledMinutes(formatMaxTimeMinutes, selectedHour, formatMaxTime, formatMinTime, formatMinTimeMinutes) {
            let disabledMinutes = [];
            for (let i=0; i < 60; i++) {
                disabledMinutes.push(i)
            }

            if (selectedHour && formatMaxTime && formatMaxTimeMinutes >= 0 && +selectedHour === formatMaxTime && +formatMaxTimeMinutes !== 0) {
                return disabledMinutes.filter(item => item >= formatMaxTimeMinutes);
            } else if (selectedHour !== formatMinTime) {
                return []
            }

            if (selectedHour && formatMinTime && formatMinTimeMinutes >= 0 && selectedHour === formatMinTime) {
                return disabledMinutes.filter(item => item <= formatMinTimeMinutes);
            } else {
                return []
            }
        }

        return <TimePicker
            showSecond={false}
            onChange={(value) => this.onChangeTimepicker(value, fieldName, maxTime, minTime)}
            className="timepicker-custom"
            name={name}
            value={currentValue ? moment(currentValue, 'HH:mm') : null}
            defaultOpenValue={moment(minTime, 'HH:mm')}
            disabledHours={() => disabledHours(formatMinTime, formatMaxTime)}
            disabledMinutes={() => disabledMinutes(formatMaxTimeMinutes, selectedHour, formatMaxTime, formatMinTime, formatMinTimeMinutes)}
            hideDisabledOptions={true}
        />
    }

    renderSelectField(fieldName, label, options = [], props = {}) {
        props['value'] = this.getValue(fieldName);
        props['name'] = fieldName;
        props['options'] = options;
        props['onChange'] = (e) => {
            const value = e ? e.value : null;
            this.onChangeInput(fieldName, {target: {value}});
        };

        return (
            <FormGroup name={fieldName} label={label}>
                <Select {...props}/>
            </FormGroup>
        );
    }

    textFieldProps(fieldName, props = {}) {
        return {
            size: 'md',
            ...this.inputProps(fieldName, props)
        };
    }

    inputProps(fieldName, props = {}) {
        if (props && props.delayed) {
            return {
                defaultValue: this.getValue(fieldName),
                onChange: this.newListener(fieldName, props),
                name: fieldName,
                error: this.getError(fieldName),
            };
        }
        return {
            value: this.getValue(fieldName),
            onChange: this.newListener(fieldName, props),
            name: fieldName,
            error: this.getError(fieldName),
        };
    }

    getError(fieldName) {
        const field = _.findLast(fieldName.split('.'));

        return _.get(this.props.errors, field) || _.get(this.props.errors, fieldName);
    }

    newListener(fieldName, props = {}) {
        if (props && props.delayed) {
            return this.onChangeDebounce.bind(this, fieldName);
        }

        return this.onChangeInput.bind(this, fieldName);
    }

    onChangeTimepicker(value, fieldName, maxTime, minTime) {
        function logicPrevent(time, maxTime) {
            if (moment(time, 'HH:mm').isBefore(moment(maxTime, 'HH:mm')) && moment(time, 'HH:mm').isAfter(moment(minTime, 'HH:mm'))) {
                return time
            } else if (moment(time, 'HH:mm').isAfter(moment(maxTime, 'HH:mm'))) {
                return moment(maxTime, 'HH:mm').subtract(1, 'minutes').format("HH:mm")
            } else if (moment(time, 'HH:mm').isBefore(moment(minTime, 'HH:mm'))) {
                return minTime
            } else if (moment(time, 'HH:mm').isSame(moment(minTime, 'HH:mm'))) {
                return moment(minTime, 'HH:mm').add(1, 'minutes').format("HH:mm")
            } else {
                return moment(maxTime, 'HH:mm').subtract(1, 'minutes').format("HH:mm")
            }
        }
        if (value) {
            const splitedStateFields = fieldName.split(".");
            const stateField = splitedStateFields[0];
            const newState = {[splitedStateFields[1]]: logicPrevent(value.format('HH:mm'), maxTime)}
            this.setState((prevState) => ({
            [stateField] : { ...prevState[stateField], ...newState }
            }));
        }
    }

    onChangeInput(field, {target: {value, type}}) {
        type && type === 'number' ? value = parseInt(value, 10) : null;
        this.setValue(field, value);
        this.setState({isEditingNow: true});
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }

        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }

    async getDictionary(dictionary, component = null, withoutOrder = false) {
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionary, meta);
        if (response.isOk) {
            return _.map(response.payload.documents, (document) => ({
                value: document.uuid,
                label: document.short_name || document.name,
                document,
            }));
        } else {
            response.showErrors();
        }
    }
}
