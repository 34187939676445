import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {api} from "helpers/api";
import classNames from 'classnames';

import FileReaderInput from 'react-file-reader-input';
import {getFaqItem, createFaq, updateFaq} from "store/reducers/portal/faq";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getFaqItem, createFaq, updateFaq})

export default class Editor extends BaseEditor {

    title = 'вопрос-ответа';
    modelClass = 'App\\Model\\Faq';
    disableOutsideClick = true;

    async loadData(uuid) {
        return await this.props.getFaqItem(uuid);
    }

    async createItem(data) {
        return await this.props.createFaq(data);
    }

    async updateItem(data) {
        return await this.props.updateFaq(data);
    }

    getForm(item, onSubmit) {
        item.is_active = !!item.is_active;

        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        faq: {},
    };

    getData() {
        return this.state.faq;
    }

    async componentDidMount() {
        await this.setState({
            faq: this.props.data,
        });
    }

    get(path, defaultValue = null) {
        return _.get(this.state.faq, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} single={true}>
                        <Block size="xl" title="Вопрос">
                            {this.textInput('faq.question')}
                        </Block>
                        <Block size="xl" title="Ответ">
                            {this.textarea('faq.answer')}
                        </Block>
                        <Block title="">
                            {this.checkbox('faq.is_active', 'Активна')}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}