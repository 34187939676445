import React from 'react';
import MapGeojson from 'components/ui/map/base/geojson';
import DirectionalGeojson from 'components/ui/map/objects/directional-geojson';
import { first, each, isArray } from 'lodash';

const HistoryGeojson = ({ historyGeoJsonData, leaflet, map }) => {
	if (isArray(historyGeoJsonData)) {
		let result = [];
		result.push(
			<MapGeojson
				key="first"
				map={map}
				leafletMap={leaflet}
				geometry={first(historyGeoJsonData).geojson}
				interactive={false}
				color={first(historyGeoJsonData).color}
				zIndex={998}
				opacity={1}
				weight={4}
			/>
		);
		each(historyGeoJsonData.slice(1), (item, index) => {
			result.push(
				<DirectionalGeojson
					key={index}
					map={map}
					leafletMap={leaflet}
					geometry={item.geojson}
					interactive={false}
					color={item.color}
					zIndex={999}
					opacity={1}
					weight={4}
				/>
			);
		});

		return result;
	}

	return (
		<DirectionalGeojson
			map={map}
			leafletMap={leaflet}
			geometry={historyGeoJsonData}
			interactive={false}
			color="#f65c50"
			opacity={1}
			weight={4}
		/>
	);
};

export { HistoryGeojson };
