export const filterMap = {
	'Отсутствие телематики от ТС, стоящих в наряде/задании': true,
	'Превышение скорости, абсолютное': true,
	'Превышение скорости, условное': true,
	'Необходимость закрыть задание': true,
	'Нераспределенные задания': true,
	'Отклонение от графика': true,
	'Слив топлива': true,
	'Вход в геозону': true,
	'Выход за геозону': true
};
