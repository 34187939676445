import React, { Component } from 'react';
import systems from "dictionaries/systems";
import WidgetPage from '../base-components/WidgetPage';
import BaseAnalyticsComponent from "components/modules/analytics/base";
import KiutrWidget from "components/modules/analytics/dashboard/widgets/KiutrWidget/index";
import KursWidget from "components/modules/analytics/dashboard/widgets/KursWidget/index";
import KursWorkWidget from "components/modules/analytics/dashboard/widgets/KursWidget2/index";
import ChildrenWidget from "components/modules/analytics/dashboard/widgets/ChildrenWidget/index";
import './dashboard.less';
import GatnWidget from "components/modules/analytics/dashboard/widgets/GatnWidget/index";
import GarbageWidget from "components/modules/analytics/dashboard/widgets/GarbageWidget/index";
import CommunalWidget from "components/modules/analytics/dashboard/widgets/CommunalWidget/index";
import TaxiWidget from "components/modules/analytics/dashboard/widgets/TaxiWidget/index";
import AgriculturalWidget from "components/modules/analytics/dashboard/widgets/AgriculturalWidget/index";
import MedicWidget from "components/modules/analytics/dashboard/widgets/MedicWidget/index";
import TimberWidget from "components/modules/analytics/dashboard/widgets/TimberWidget/index";
import DangerousWidget from "components/modules/analytics/dashboard/widgets/DangerousWidget/index";
import EmercomWidget from "components/modules/analytics/dashboard/widgets/EmercomWidget/index";
import UtilityWidget from "components/modules/analytics/dashboard/widgets/UtilityWidget/index";
import OfficialWidget from "components/modules/analytics/dashboard/widgets/OfficialWidget/index";
import FarmingWidget from "components/modules/analytics/dashboard/widgets/FarmingWidget/index";
import ForestryWidget from "components/modules/analytics/dashboard/widgets/ForestryWidget/index";
import MedicineWidget from "components/modules/analytics/dashboard/widgets/MedicineWidget/index";

export default class AnalyticsDashboard extends BaseAnalyticsComponent {

	constructor(props) {
		super(props);
		if (window.RNIS_SETTINGS.CUSTOMMENU) {
			this.widgetItems = [
				(_.find(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '116' ]).submenu, [ 'id', '118' ]).show ?
					{
						id: 'kiutrWidget',
						name: 'Пассажирские перевозки',
						widget: this.kiutrWidget,
						checked: true,
						className: 'widget-block_1',
						titleClassName: 'title__icon title__icon_bus',
						link: '/analytics/passenger-transportation',
					} : ''),
				(_.find(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '116' ]).submenu, [ 'id', '119' ]).show ?
					{
						id: 'kursWidget',
						name: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '161' ]).text,
						widget: this.kursWidget,
						checked: true,
						className: 'widget-block_2',
						titleClassName: 'title__icon title__icon_road',
						link: '/analytics/kurs',
					} : ''),
				(_.find(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '116' ]).submenu, [ 'id', '219' ]).show ?
					{
						id: 'kursWidget2',
						name: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '161' ]).text,
						widget: this.kursWorkWidget,
						checked: true,
						className: 'widget-block_2',
						titleClassName: 'title__icon title__icon_road',
						link: '/analytics/kurs',
					} : ''),
				(_.find(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '116' ]).submenu, [ 'id', '120' ]).show ?
					{
						id: 'childrenWidget',
						name: 'Перевозки детей',
						widget: this.childrenWidget,
						checked: true,
						className: 'widget-block_3',
						titleClassName: 'title__icon title__icon_children',
						link: '/analytics/children-transportation',
					} : ''),
				(_.find(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '116' ]).submenu, [ 'id', '122' ]).show ?
					{
						id: 'garbageWidget',
						name: 'Вывоз мусора',
						widget: this.garbageWidget,
						checked: true,
						className: 'widget-block_5',
						titleClassName: 'title__icon title__icon_trash',
						link: '/analytics/garbage',
					} : ''),
				(_.find(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '116' ]).submenu, [ 'id', '123' ]).show ?
					{
						id: 'gatnWidget',
						name: window.RNIS_SETTINGS.GUGATNTITLE || 'ГУ ГАТН',
						widget: this.gatnWidget,
						checked: true,
						className: 'widget-block_6',
						titleClassName: 'title__icon title__icon_analitics',
						link: '/analytics/gatn',
					} : ''),
				(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '181' ]).show ?
					{
						id: 'communalWidget',
						name: 'ЖКХ',
						widget: this.communalWidget,
						checked: true,
						className: 'widget-block_4',
						titleClassName: 'title__icon title__icon_communal',
						link: '/analytics/communal',
					} : ''),
				(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '300' ]).show ?
					{
						id: 'TaxiWidget',
						name: 'Мониторинг легковых такси',
						widget: this.taxiWidget,
						checked: true,
						className: 'widget-block_1',
						titleClassName: 'title__icon title__icon_taxi',
						link: '/analytics/taxi',
					} : ''),
				(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '307' ]).show ?
					{
						id: 'AgriculturalWidget',
						name: 'Транспорт сельского хозяйства',
						widget: this.agriculturalWidget,
						checked: true,
						className: 'widget-block_2',
						titleClassName: 'title__icon title__icon_agricultural',
						link: '/analytics/agricultural',
					} : ''),
				(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '314' ]).show ?
					{
						id: 'MedicWidget',
						name: 'Контроль медицинского транспорта',
						widget: this.medicWidget,
						checked: true,
						className: 'widget-block_3',
						titleClassName: 'title__icon title__icon_medic',
						link: '/analytics/medic',
					} : ''),
				(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '321' ]).show ?
					{
						id: 'TimberWidget',
						name: 'Перевозки лесоматериалов',
						widget: this.timberWidget,
						checked: true,
						className: 'widget-block_4',
						titleClassName: 'title__icon title__icon_timber',
						link: '/analytics/timber',
					} : ''),
				(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '332' ]).show ?
					{
						id: 'DangerousWidget',
						name: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '332' ]).text,
						widget: this.dangerousWidget,
						checked: true,
						className: 'widget-block_5',
						titleClassName: 'title__icon title__icon_dangerous',
						link: '/analytics/dangerous',
					} : ''),
				(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '379' ]).show ?
					{
						id: 'utilityWidget',
						name: 'Жилищно-коммунальное хозяйство',
						widget: this.utilityWidget,
						checked: true,
						className: 'widget-block_4',
						titleClassName: 'title__icon title__icon_communal',
						link: '/analytics/utility',
					} : ''),
				(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '414' ]).show ?
					{
						id: 'OfficialWidget',
						name: 'Служебный транспорт',
						widget: this.officialWidget,
						checked: true,
						className: 'widget-block_1',
						titleClassName: 'title__icon title__icon_official',
						link: '/analytics/official',
					} : ''),

				(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '407' ]).show ?
					{
						id: 'emercomWidget',
						name: 'Пожарно-спасательная служба',
						widget: this.emercomWidget,
						checked: true,
						className: 'widget-block_2',
						titleClassName: 'title__icon title__icon_emercom',
						link: '/analytics/emercom',
					} : ''),

				(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '400' ]).show ?
					{
						id: 'farmingWidget',
						name: 'Сельское хозяйство',
						widget: this.farmingWidget,
						checked: true,
						className: 'widget-block_3',
						titleClassName: 'title__icon title__icon_farming',
						link: '/analytics/farming',
					} : ''),

				(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '372' ]).show ?
					{
						id: 'forestryWidget',
						name: 'Лесное хозяйство',
						widget: this.forestryWidget,
						checked: true,
						className: 'widget-block_4',
						titleClassName: 'title__icon title__icon_forestry',
						link: '/analytics/forestry',
					} : ''),
				(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '365' ]).show ?
					{
						id: 'medicineWidget',
						name: 'Центр медицины и катастроф',
						widget: this.medicineWidget,
						checked: true,
						className: 'widget-block_5',
						titleClassName: 'title__icon title__icon_medicine',
						link: '/analytics/medicine',
					} : ''),


			];
			if (window.RNIS_SETTINGS.CITY_MURMANSK) {
				this.widgetItems = this.widgetItems.filter(widget => widget.id !== 'kursWidget')
			}
		} else {
			this.widgetItems = [
				{
					id: 'kiutrWidget',
					name: 'Пассажирские перевозки',
					widget: this.kiutrWidget,
					checked: true,
					className: 'widget-block_1',
					titleClassName: 'title__icon title__icon_bus',
					link: '/analytics/passenger-transportation',
				}, {
					id: 'kursWidget',
					name: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '161' ]).text,
					widget: this.kursWidget,
					checked: true,
					className: 'widget-block_2',
					titleClassName: 'title__icon title__icon_road',
					link: '/analytics/kurs',
				}, {
					id: 'childrenWidget',
					name: 'Перевозки детей',
					widget: this.childrenWidget,
					checked: true,
					className: 'widget-block_3',
					titleClassName: 'title__icon title__icon_children',
					link: '/analytics/children-transportation',
				}, {
					id: 'garbageWidget',
					name: 'Вывоз мусора',
					widget: this.garbageWidget,
					checked: true,
					className: 'widget-block_5',
					titleClassName: 'title__icon title__icon_trash',
					link: '/analytics/garbage',
				}, {
					id: 'gatnWidget',
					name: window.RNIS_SETTINGS.GUGATNTITLE || 'ГУ ГАТН',
					widget: this.gatnWidget,
					checked: true,
					className: 'widget-block_6',
					titleClassName: 'title__icon title__icon_analitics',
					link: '/analytics/gatn',
				}, {
					id: 'communalWidget',
					name: 'ЖКХ',
					widget: this.communalWidget,
					checked: true,
					className: 'widget-block_4',
					titleClassName: 'title__icon title__icon_analitics',
					link: '/analytics/communal',
				}
			];
			if (window.RNIS_SETTINGS.CITY_MURMANSK) {
				this.widgetItems = this.widgetItems.filter(widget => widget.id !== 'kursWidget')
			}
		}


		this.title = `${systems.analytics} → Сводные показатели`;
	}

	kiutrWidget(props) {
		return <KiutrWidget {...props}/>
	}

	kursWidget(props) {
		return <KursWidget {...props}/>
	}

	kursWorkWidget(props) {
		return <KursWorkWidget {...props} />
	}

	childrenWidget(props) {
		return <ChildrenWidget {...props}/>
	}

	gatnWidget(props) {
		return <GatnWidget {...props}/>
	}

	communalWidget(props) {
		return <CommunalWidget {...props}/>
	}

	garbageWidget(props) {
		return <GarbageWidget {...props}/>
	}

	taxiWidget(props) {
		return <TaxiWidget {...props}/>
	}

	agriculturalWidget(props) {
		return <AgriculturalWidget {...props}/>
	}

	medicWidget(props) {
		return <MedicWidget {...props}/>
	}

	timberWidget(props) {
		return <TimberWidget {...props}/>
	}

	dangerousWidget(props) {
		return <DangerousWidget {...props}/>
	}
	utilityWidget(props) {
		return <UtilityWidget {...props}/>
	}
	officialWidget(props) {
		return <OfficialWidget {...props}/>
	}
	emercomWidget(props) {
		return <EmercomWidget {...props}/>
	}
	farmingWidget(props) {
		return <FarmingWidget {...props}/>
	}
	forestryWidget(props) {
		return <ForestryWidget {...props}/>
	}
	medicineWidget(props) {
		return <MedicineWidget {...props}/>
	}

	render() {
		return (
			<WidgetPage cacheKey="dashboard"
						title={this.title}
						className="dashboard"
						router={this.props.router}
						widgetItems={this.widgetItems}
			/>
		);
	}
}