import React from 'react';
import {connect} from 'react-redux';

import Editor from './editor';


import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import formats from "dictionaries/formats";
import moment from "moment";
import './index.less';
import {deleteTemplateDocument, getTemplateDocuments} from "store/reducers/portal/template_documents";
import document_types from "dictionaries/document_types";
import ReactDOMServer from 'react-dom/server';
import {State} from "components/ui/state";

@connect(state => ({}), {getTemplateDocuments, deleteTemplateDocument})

export default class TemplateDocumentsComponent extends BaseTableWithEditorComponent {

    title = `${systems.operator} → Шаблоны документов`;
    baseUrl = '/operator/template_documents';

    getEditor() {
        return (
            <Editor
                {...this.props}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Документ')
                .fromField('document')
                .denyColumnFilter()
                .withDrawer(item => document_types[item.document]),

            new Column('Домен')
                .fromField('domain')
                .denyColumnFilter()
                .withDrawer(item => systems[item.domain]),

            new Column('Версия')
                .fromField('version'),

            new Column('Активный')
                .fromField('is_active')
                .denyColumnFilter()
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_active}/>)),

            new Column('Дата внесения')
                .fromField('created_at')
                .denyColumnFilter()
                .withDrawer(item => item.created_at ? moment(item.created_at).format(formats.DATE) : null),

            new Column('Дата активации')
                .fromField('activation_date')
                .denyColumnFilter()
                .withDrawer(item => item.activation_date ? moment(item.activation_date).format(formats.DATE) : null),

            new Column('Дата замены')
                .fromField('replace_date')
                .denyColumnFilter()
                .withDrawer(item => item.replace_date ? moment(item.replace_date).format(formats.DATE) : null),

            new Column('Использований')
                .fromField('number_uses'),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteTemplateDocument(data);
    }

    async loadData(meta) {
        return await this.props.getTemplateDocuments(meta);
    }
}