import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './select-filter.less';

@propTypes({
    items: PropTypes.array,
    currentItem: PropTypes.number,
    onChange: PropTypes.func,
    className: PropTypes.string,
})

@defaultProps({
    currentItem: 0
})

export default class SelectFilter extends Component {
    render() {
        const Items = this.props.items.map((item, i) => (
            <div
                className={classNames({
                    'item': true,
                    'selected': i === this.props.currentItem,
                }, this.props.classNames ? this.props.classNames[i] : null)}
                key={i}
                onClick={() => this.onClick(i)}>{item}</div>
        ));

        return (
            <div className={classNames('select-filter', this.props.className)}>
                {Items}
            </div>
        );
    }

    onClick(position) {
        this.props.onChange(position);
    }
}