import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getVehicleWorks = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.getVehicleWorks(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getVehicleWork = (uuid) => async (dispatch) => {
    try {
        const response = await api.commdept.getVehicleWork(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createVehicleWork = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.createVehicleWork(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateVehicleWork = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.updateVehicleWork(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteVehicleWork = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.deleteVehicleWork(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
