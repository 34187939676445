import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const listObjectVisits = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.listObjectVisits(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
