import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './popup.less';


@propTypes({
    position: PropTypes.object,
    show: PropTypes.bool,
    className: PropTypes.string,
    onClose: PropTypes.func
})

@defaultProps({
    left: null,
    top: null,
    onClose: () => {
    }
})

export default class Popup extends Component {

    constructor(...args) {
        super(...args);

        this.onClickDocument = ::this._onClickDocument;
    }

    componentDidMount() {
        $(document).on('click', this.onClickDocument);
    }

    componentWillUnmount() {
        $(document).off('click', this.onClickDocument);
    }

    _onClickDocument(e) {
        if (!$(e.target).hasClass('popup-container') && ($(e.target).parents('.popup-container').length === 0) && ($(e.target).closest('.top-menu__item')[0] !== $(this.refs.container).closest('.top-menu__item')[0])) {
            this.props.onClose();
        }
    }

    render() {
        const className = classNames('popup-container',
            !this.props.show ? 'popup__hidden' : '', this.props.className);

        let style = {};
        if (this.props.top !== null) {
            style.top = this.props.top;
        }
        if (this.props.left !== null) {
            style.left = this.props.left;
        }

        return (
            <div ref="container" className={className} style={style}>
                {this.props.children}
            </div>
        );
    }

}