import React, { Component } from 'react';
import systems from 'dictionaries/systems';
import WidgetPage from '../base-components/WidgetPage';
import ConvoyDriverScoreWidget from './widgets/ConvoyDriverScoreWidget';
import ConvoyRatingWidget from './widgets/ConvoyRatingWidget';
import VehicleDriverDataWidget from './widgets/VehicleDriverDataWidget';
import BacklogWidget from './widgets/BacklogWidget';
import SpeedViolationWidget from './widgets/SpeedViolationWidget';
import TransportWorkWidget from './widgets/TransportWorkWidget';
import randomColor from 'randomcolor';
import BaseAnalyticsComponent from 'components/modules/analytics/base';
import RegularityWidget from 'components/modules/analytics/passenger-transportation/widgets/RegularityWidget/index';
import ComplianceIntervalSchedule from 'components/modules/analytics/passenger-transportation/widgets/ComplianceIntervalSchedule/index';
import AverageCarAge from 'components/modules/analytics/passenger-transportation/widgets/AverageCarAge/index';
import SpeedViolationsLineWidget from 'components/modules/analytics/passenger-transportation/widgets/SpeedViolationsLineWidget/index';
import SpeedViolationsWidget from 'components/modules/analytics/passenger-transportation/widgets/SpeedViolationsWidget/index';
import VehiclesPlanWidget from 'components/modules/analytics/passenger-transportation/widgets/VehiclesPlanWidget/index';
import VehiclesBnsoWidget from 'components/modules/analytics/passenger-transportation/widgets/VehiclesBnsoWidget/index';
import TransportWorkChartWidget from 'components/modules/analytics/passenger-transportation/widgets/TransportWorkChartWidget/index';
import UnitsTableWidget from 'components/modules/analytics/passenger-transportation/widgets/UnitsTableWidget/index';
import Histogram from "components/ui/histogram";
import SpecialVehicles from 'components/modules/analytics/passenger-transportation/widgets/SpecialVehicles/index';
import ZoneViolationsExitCount from "components/modules/analytics/passenger-transportation/widgets/ZoneViolationsExitCount/index";
import ZoneViolationsExitCompany from "components/modules/analytics/passenger-transportation/widgets/ZoneViolationsExitCompany/index";

const convoyDriverScoreWidgetBarColor = randomColor();
const convoyRatingWidgetBarColor = randomColor();
const speedViolationWidgetBarColor = randomColor();
const transportWorkWidgetBarColor = randomColor();

export default class PassengerTransportation extends BaseAnalyticsComponent {
	constructor(props) {
		super(props);

		this.widgetItemsPassengerDefault = [
			{
				id: 'complianceWidget',
				name: 'Соблюдение интервального расписания',
				widget: this.complianceWidget,
				checked: true
			},
			{
				id: 'regularityWidget',
				name: 'Регулярность движения',
				widget: this.regularityWidget,
				checked: true,
				faq: true
			},
			{
				id: 'vehiclesPlanWidget',
				name: 'Выполнение плана по выходу ТС',
				widget: this.vehiclesPlanWidget,
				checked: true
			},
			{ id: 'vehiclesBnsoWidget', name: 'Оснащенность ГЛОНАСС', widget: this.vehiclesBnsoWidget, checked: true },
			{
				id: 'transportWorkChartWidget',
				name: 'Доля выполнения рейсов',
				widget: this.transportWorkChartWidget,
				checked: true
			},
			{ id: 'unitsTableWidget', name: 'Статистика по предприятиям', widget: this.unitsTableWidget, checked: true },
			{ id: 'convoyDriverScoreWidget', name: 'Баллы водителей по автоколонне', widget: this.convoyDriverScoreWidget },
			{ id: 'convoyRatingWidget', name: 'Рейтинг автоколонн', widget: this.convoyRatingWidget },
			{
				id: 'vehicleDriverDataWidget',
				name: 'Данные по ТС и водителям на текущую дату',
				widget: VehicleDriverDataWidget
			},
			{ id: 'backlogWidget', name: 'Отставание от графика', widget: BacklogWidget },
			//{ id: 'speedViolationWidget', name: 'Количество нарушений скоростного режима', widget: this.speedViolationWidget },
			{ id: 'transportWorkWidget', name: 'Учет транспортной работы', widget: this.transportWorkWidget },
		];

		if (!window.RNIS_SETTINGS.CITY_TOMSK) {
			this.widgetItemsPassengerDefault.push({
				id: 'speedViolationsWidget',
				name: 'Нарушители скоростного режима',
				widget: this.speedViolationsWidget,
				checked: true
			},
			{
				id: 'speedViolationsLineWidget',
				name: 'Количество нарушений скоростного режима',
				widget: this.speedViolationsLineWidget,
				checked: true
			}
			)
		}

		if (window.RNIS_SETTINGS.CITY_TULA) {
			this.widgetItemsPassengerDefault.push({ id: 'histogramWidget', name: 'Реши проблему', widget: this.histogramWidget, checked: true });
			this.widgetItemsPassengerDefault.push({ id: 'averageCarAgeWidget', name: 'Средний возраст ТС по классам с предельно-допустимым возрастом', widget: this.averageCarAge, checked: true });
			this.widgetItemsPassengerDefault.push({ id: 'specialVehiclesWidget', name: 'Оснащенность транспорта для перевозки инвалидов и людей с ОС', widget: this.specialVehicles, checked: true });
			this.widgetItemsPassengerDefault.push({ id: 'zoneViolationsExitCount', name: 'Количество выходов из геозоны', widget: this.zoneViolationsExitCount, checked: true })
			this.widgetItemsPassengerDefault.push({ id: 'zoneViolationsExitCompany', name: 'Выходы из геозоны по предприятиям', widget: this.zoneViolationsExitCompany, checked: true })
		}

		this.widgetItemsPassengerGrantMO = [
			{
				id: 'complianceWidget',
				name: 'Соблюдение интервального расписания',
				widget: this.complianceWidget,
				checked: true
			},
			{
				id: 'regularityWidget',
				name: 'Регулярность движения',
				widget: this.regularityWidget,
				checked: true,
				faq: true
			},
			{
				id: 'speedViolationsLineWidget',
				name: 'Количество нарушений скоростного режима',
				widget: this.speedViolationsLineWidget,
				checked: false
			},
			{
				id: 'speedViolationsWidget',
				name: 'Нарушители скоростного режима',
				widget: this.speedViolationsWidget,
				checked: false
			},
			{
				id: 'vehiclesPlanWidget',
				name: 'Выполнение плана по выходу ТС',
				widget: this.vehiclesPlanWidget,
				checked: true
			},
			{ id: 'vehiclesBnsoWidget', name: 'Оснащенность ГЛОНАСС', widget: this.vehiclesBnsoWidget, checked: false },
			{
				id: 'transportWorkChartWidget',
				name: 'Доля выполнения рейсов',
				widget: this.transportWorkChartWidget,
				checked: true
			},
			{
				id: 'averageCarAgeWidget',
				name: 'Средний возраст ТС по классам с предельно-допустимым возрастом',
				widget: this.averageCarAge,
				checked: true
			},
			{ id: 'unitsTableWidget', name: 'Статистика по предприятиям', widget: this.unitsTableWidget, checked: true },
			{ id: 'convoyDriverScoreWidget', name: 'Баллы водителей по автоколонне', widget: this.convoyDriverScoreWidget },
			{ id: 'convoyRatingWidget', name: 'Рейтинг автоколонн', widget: this.convoyRatingWidget },
			{
				id: 'vehicleDriverDataWidget',
				name: 'Данные по ТС и водителям на текущую дату',
				widget: VehicleDriverDataWidget
			},
			{ id: 'backlogWidget', name: 'Отставание от графика', widget: BacklogWidget },
			{ id: 'transportWorkWidget', name: 'Учет транспортной работы', widget: this.transportWorkWidget },
			{ id: 'histogramWidget', name: 'Гистограмма', widget: this.histogramWidget, checked: true }
		];

		if (!window.RNIS_SETTINGS.CITY_OMSK) {
			this.widgetItemsPassengerDefault = this.widgetItemsPassengerDefault.filter(
				(widget) => widget.id !== 'complianceWidget'
			);
			this.widgetItemsPassengerGrantMO = this.widgetItemsPassengerGrantMO.filter(
				(widget) => widget.id !== 'complianceWidget'
			);
		}

		this.title = `${systems.analytics} → Пассажирские перевозки`;
	}

	convoyDriverScoreWidget(props) {
		return <ConvoyDriverScoreWidget {...props} component="kiutr" barChartFillColor={convoyDriverScoreWidgetBarColor} />;
	}

	convoyRatingWidget(props) {
		return <ConvoyRatingWidget {...props} barChartFillColor={convoyRatingWidgetBarColor} />;
	}

	speedViolationWidget(props) {
		return <SpeedViolationWidget {...props} barChartFillColor={speedViolationWidgetBarColor} />;
	}

	transportWorkWidget(props) {
		return <TransportWorkWidget {...props} barChartFillColor={transportWorkWidgetBarColor} />;
	}

	regularityWidget(props) {
		return <RegularityWidget {...props} />;
	}

	complianceWidget(props) {
		return <ComplianceIntervalSchedule {...props} />;
	}

	speedViolationsLineWidget(props) {
		return <SpeedViolationsLineWidget {...props} />;
	}

	speedViolationsWidget(props) {
		return <SpeedViolationsWidget {...props} />;
	}

	vehiclesPlanWidget(props) {
		return <VehiclesPlanWidget {...props} />;
	}

	vehiclesBnsoWidget(props) {
		return <VehiclesBnsoWidget {...props} />;
	}

	transportWorkChartWidget(props) {
		return <TransportWorkChartWidget {...props} />;
	}

	unitsTableWidget(props) {
		return <UnitsTableWidget {...props} />;
	}

	specialVehicles(props) {
		return <SpecialVehicles {...props} />;
	}

	averageCarAge(props) {
		return <AverageCarAge {...props} />;
	}

	histogramWidget(props) {
		return <Histogram {...props} />;
	}
	zoneViolationsExitCount(props) {
		return <ZoneViolationsExitCount {...props}/>
	}

	zoneViolationsExitCompany(props) {
		return <ZoneViolationsExitCompany {...props}/>
	}

	render() {
		return (
			<WidgetPage
				cacheKey="passenger"
				router={this.props.router}
				title={this.title}
				widgetItems={window.RNIS_SETTINGS.grantMO ? this.widgetItemsPassengerGrantMO : this.widgetItemsPassengerDefault}
			/>
		);
	}
}
