import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';
import L from 'leaflet';
import 'leaflet.pm';
import 'leaflet.pm/dist/leaflet.pm.css';
import circleToPolygon from 'circle-to-polygon';

import ReactDOMServer from 'react-dom/server';
import MapComponent from "components/ui/map";

import './map.less';

@propTypes({
    displayControls: PropTypes.bool.isRequired,
    onlyMarker: PropTypes.bool,
    onObjectCreate: PropTypes.func.isRequired,
    geometryEdit: PropTypes.string,
})
@connect((state) => ({}), {}, null, {withRef: true})

export default class UserMapObjectsMap extends Component {

    state = {};

    layer = null;

    componentDidMount() {
        if (!this.refs.map) return;

        this.refs.map.getWrappedInstance().map.on('pm:create', (e) => {
            this.refs.map.getWrappedInstance().map.pm.disableDraw();
            if (e.layer.getBounds) {
                this.refs.map.getWrappedInstance().fitBounds(e.layer.getBounds());
            }
            let layer = e.layer;
            if (e.shape === 'Circle') {
                layer = this.transformCircleToPolygon(e.layer);
                layer.original = e.layer;
            }
            this.props.onObjectCreate(layer);
        });

        this.layer = L.layerGroup();
        this.refs.map.getWrappedInstance().map.addLayer(this.layer);
    }

    circleToPolygon(center, radius, numberOfSegments) {
        let n = numberOfSegments ? numberOfSegments : 32;
        let flatCoordinates = [];
        let coordinates = [];
        for (let i = 0; i < n; ++i) {
            flatCoordinates.push.apply(flatCoordinates, this.offset(center, radius, 2 * Math.PI * i / n));
        }
        flatCoordinates.push(flatCoordinates[0], flatCoordinates[1]);

        for (let i = 0, j = 0; j < flatCoordinates.length; j += 2) {
            coordinates[i++] = flatCoordinates.slice(j, j + 2);
        }

        return {
            type: 'Polygon',
            coordinates: [coordinates]
        };
    }

    transformCircleToPolygon(layer) {
        const radius = layer.getRadius();
        const center = layer.getLatLng();
        const numberOfEdges = 64;

        //let polygon = this.circleToPolygon([center.lng, center.lat], radius, numberOfEdges);
        let polygon = circleToPolygon([center.lng, center.lat], radius, numberOfEdges);

        return L.polygon(_.map(polygon.coordinates[0], (coordinate) => coordinate.reverse()));
    }

    componentWillUpdate(props) {
        if (!this.refs.map) return;

        if (props.displayControls) {
            const options = this.props.onlyMarker ? {
                position: 'topright',
                drawMarker: true,
                drawPolygon: false,
                drawCircle: false,
                drawPolyline: false,
                editPolygon: false,
                deleteLayer: false,
                cutPolygon: false,
            } : {
                position: 'topright',
                drawMarker: true,
                drawPolygon: true,
                drawCircle: true,
                drawPolyline: true,
                editPolygon: false,
                deleteLayer: false,
                cutPolygon: false,
            };

            this.refs.map.getWrappedInstance().map.pm.addControls(options);
        } else {
            this.refs.map.getWrappedInstance().map.pm.removeControls();
        }

        this.layer.clearLayers();

        _.each(props.objects, (object) => {
            const geometry = {
                type: 'Feature',
                geometry: object.geometry,
            };
            let layer = new L.GeoJSON(geometry, {
                style: {
                    fillColor:  object.color || '#3388ff',
                    color:  object.color || '#3388ff',
                  //  fillOpacity: 0.7
                }
            });
            layer._object = object;
            layer.bindTooltip(object.title, {sticky: true});
            this.layer.addLayer(layer);
        });

        _.each(this.layer.getLayers(), (layer) => {
            layer.pm.disable();
        });

        if (props.geometryEdit) {
            const layer = this.findLayer(props.geometryEdit);
            if (layer) {
                layer.pm.enable();
            }
        }
    }

    render() {
        return (
            <MapComponent ref="map" component={this.props.component} searchByMO={true}/>
        );
    }

    findLayer(objectUuid) {
        let finded = null;

        _.each(this.layer.getLayers(), (layer) => {
            if (layer._object.uuid === objectUuid) {
                finded = layer;
            }
        });

        return finded;
    }

    fitOn(objectUuid) {
        if (!this.refs.map) return;

        const layer = this.findLayer(objectUuid);
        if (layer) {
            this.refs.map.getWrappedInstance().fitBounds(layer.getBounds());
        }
    }

    getGeometry(objectUuid) {
        const layer = this.findLayer(objectUuid);
        if (layer) {
            const geoJSON = layer.toGeoJSON();
            return geoJSON.features[0].geometry;
        }
        return null;
    }
}