import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import classNames from 'classnames';

import {api} from 'helpers/api';

import Select, {SelectAsync} from 'components/ui/select';

import FileReaderInput from 'react-file-reader-input';

import './edit.less';

import BaseEditorFormComponent from "components/base/base-editor-form";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";
import {connect} from "react-redux";
import {getRoles} from "store/reducers/staffing/staffing";
import {getUserPositions} from "store/reducers/staffing/positions/positions";
import {getUserEducations} from "store/reducers/staffing/education/educations";
import {
    getUnitsForSelect, getUnitPositionsForSelect,
    getUnitUserGroupsForSelect
} from "store/reducers/organizational_units/organizational_units";
import currentUser from 'helpers/current-user';
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import ValidationError from "components/ui/validation-error";
import * as alerts from "helpers/alerts";
import systems from "dictionaries/systems";
import moment from "moment";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getWaybills} from "store/reducers/kurs/waybills";
import formats from "dictionaries/formats";
import {getOrders} from "store/reducers/kiutr/orders/orders";
import {refreshUser} from "store/reducers/auth";
import Button from "components/ui/button";
import {getUserTachographs} from "store/reducers/staffing/tachographs";
import debounce from 'throttle-debounce/debounce';

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getRoles,
    getUserPositions,
    getUserEducations,
    getUserTachographs,
    getUnitsForSelect,
    getUnitPositionsForSelect,
    getUnitUserGroupsForSelect,
    getDictionaryList,
    getWaybills,
    getOrders,
    refreshUser,
}, null, {withRef: true})

export default class StaffingEditorForm extends BaseEditorFormComponent {
    state = {
        user: {},
        rolesList: [],
        driver_license_categories: [],
        subsystems: [],
        work_graphics: [],
        kiutr_run_types: [],
        rtio: {
            month: moment().month(),
            year: moment().year(),
        },
        waybills: [],
        orders: [],
    };

    months = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентярбь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];

    getData() {
        return this.state.user;
    }

    async componentDidMount() {
        let user = this.props.data;
        user.positions = user.positions || [];
        user.educations = user.educations || [];
        user.is_blocked = !!user.blocked_at;
        user.is_notificated = false;
        user.component = this.props.component;
        if (this.props.requestInfo && this.props.requestInfo.snils) {
            user.info = {
                "surname": this.props.requestInfo.lastName,
                "name": this.props.requestInfo.firstName,
                "second_name": this.props.requestInfo.middleName,
                "snils": this.props.requestInfo.snils.match(/\d/g).join('')
            };
        }

        await this.setState({
            user,
        });

        let dictionaries = [
            'driver_license_categories',
            ...(window.RNIS_SETTINGS.columns_user_subsystems_column ? ['vehicle_subsystems'] : [])
        ];
        if (this.props.params.component === 'kiutr') {
            dictionaries.push('kiutr_run_types');
        }
        if (this.props.mode === 'edit') {
            dictionaries.push('work_graphics');
        }
        this.loadDictionaries(dictionaries, this.props.params.component);

        // this.loadRoles();
        if (this.props.mode === 'edit') {
            if (currentUser.can('com.rnis.auth.permission.user.position', 'read')) {
                this.loadPositions();
            }
            if (currentUser.can('com.rnis.auth.permission.user.education', 'read')) {
                this.loadEducations();
            }
            this.loadTachographs();
        }
        if ((this.props.mode === 'edit') && (this.props.params.component === 'road')) {
            this.loadWaybills();
        }
        if ((this.props.mode === 'edit') && (this.props.params.component === 'kiutr')) {
            // this.loadOrders();
        }
    }

    async loadRoles() {
        const components = _.filter(_.map(systems, (name, code) => {
            return currentUser.can('com.rnis.auth.permission.component', 'read', code) ? code : null;
        }));

        const meta = {
            filters: {
                withComponent: components,
            },
            pagination: {
                page: 1,
                limit: 10000,
            },
        };
        const response = await this.props.getRoles(meta);
        if (response.isOk) {
            const roles = _.sortBy(_.map(this.filterRoles(response.payload.items), (role) => ({
                value: role.uuid,
                label: role.name,
            })), 'label');
            this.setState({rolesList: roles});
        } else {
            response.showErrors();
        }
    }

    filterRoles(roles) {
        return _.filter(roles, (role) => {
            return _.filter(role.permissions, (permission) => {
                return _.filter(permission.grants, (grant) => {
                    return _.filter(role.components, (component) => {
                        return !currentUser.can(permission.code, grant, component);
                    }).length !== 0;
                }).length !== 0;
            }).length === 0;
        });
    }

    async loadPositions() {
        const response = await this.props.getUserPositions(this.state.user.uuid, {
            order: {
                column: 'hired_at',
                direction: 'desc',
            },
            pagination: {
                limit: 10,
                page: 1,
            },
        });
        if (response.isOk) {
            const items = response.payload.items;
            await this.setValue('user.positions', items);
        } else {
            response.showErrors();
        }
    }

    async loadEducations() {
        const response = await this.props.getUserEducations(this.state.user.uuid, {
            order: {
                column: 'date_from',
                direction: 'desc',
            },
            pagination: {
                limit: 10,
                page: 1,
            },
        });
        if (response.isOk) {
            const items = response.payload.items;
            await this.setValue('user.educations', items);
        } else {
            response.showErrors();
        }
    }

    async loadTachographs() {
        const response = await this.props.getUserTachographs(this.state.user.uuid, {
            order: {
                column: 'date_from',
                direction: 'desc',
            },
            pagination: {
                limit: 10,
                page: 1,
            },
        });
        if (response.isOk) {
            const items = response.payload.items;
            await this.setValue('user.tachographs', items);
        } else {
            response.showErrors();
        }
    }

    async uploadAvatar(e, results) {
        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        const allowedTypes = [
            'image/png',
            'image/jpeg',
        ];
        let hasError = false;

        results.forEach(result => {
            const [e, file] = result;
            formData.append('file', file);

            let errors = [];
            if (_.indexOf(allowedTypes, file.type) === -1) {
                errors.push('Недопустимый формат файла');
            }
            if ((file.size / (1024 * 1024)) >= 20) {
                errors.push('Недопустимый размер файла');
            }
            if (errors.length > 0) {
                alerts.error(errors.join('<br/>'));
                hasError = true;
            }
        });

        if (hasError) {
            return;
        }

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');

        this.onChangeInput('user.info.photo', {target: {value}});
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.user, path, defaultValue);
    }

    render() {
        return (
            <div className="StaffingEditorForm">
                <Accordion>
                    <AccordionItem title="Основные сведения" opened={true}>
                        {this.renderGeneral()}
                    </AccordionItem>
                    <AccordionItem title="Учет" opened={true}>
                        {this.renderPositions()}
                    </AccordionItem>
                    {(!window.RNIS_SETTINGS.hide_pers_data ? (<div>
                        <AccordionItem title="Паспортные данные">
                            {this.renderPassport()}
                        </AccordionItem>
                        <AccordionItem title="Персональные данные">
                            {this.renderPersonal()}
                        </AccordionItem>
                        <AccordionItem title="Водительское удостоверение">
                            {this.renderDriver()}
                        </AccordionItem>
                    </div>) : null )}
                    {(!window.RNIS_SETTINGS.hide_pers_education ? (
                        <AccordionItem title="Квалификация">
                            {this.renderEducations()}
                        </AccordionItem>
                    ) : null)}
                    {(!window.RNIS_SETTINGS.hide_pers_tachographs ? (
                        (this.get('info.position_type') === 'driver') ? (
                            <AccordionItem title="Тахографы">
                                {this.renderTachographs()}
                            </AccordionItem>
                        ) : null
                    ) : null)}
                    {(!window.RNIS_SETTINGS.hide_pers_graphic ? (
                        ((this.props.params.component === 'road') || ((this.get('info.position_type') !== 'specialist') && (this.props.params.component === 'kiutr')) && (this.props.mode === 'edit')) ? (
                            <AccordionItem title="График работ">
                                {this.renderWorkGraphicList()}
                            </AccordionItem>
                        ) : null
                    ) : null)}
                    {(!window.RNIS_SETTINGS.hide_pers_rtio ? (
                        (((this.props.params.component === 'road') || ((this.get('info.position_type') !== 'specialist') && (this.props.params.component === 'kiutr'))) && (this.props.mode === 'edit')) ? (
                            <AccordionItem title="Учет труда и отдыха водителей">
                                {this.renderRTiO()}
                            </AccordionItem>
                        ) : null
                    ) : null)}
                </Accordion>
            </div>
        );
    }

    RolesDebounce = debounce(500, ::this.loadRolesAsync);

    async loadRolesAsync(input, callback) {
        let result;
        const components = _.filter(_.map(systems, (name, code) => {
            return currentUser.can('com.rnis.auth.permission.component', 'read', code) ? code : null;
        }));


        if (!input) {
            if (this.state.user.roles) {
                result = await this.props.getRoles({
                        filters: {
                            withComponent: components,
                            withUuid: this.state.user.roles
                        },
                        pagination: {
                            page: 1,
                            limit: 20,
                        },
                    }
                );
            } else {
                result = await this.props.getRoles({
                        filters: {
                            withComponent: components,
                        },
                        pagination: {
                            page: 1,
                            limit: 20,
                        },
                    }
                );
            }
            // input = this.state.rolesList
            // console.log(this.props)
            // return input;

        } else {
            result = await this.props.getRoles({
                search: input,
                filters: {
                    withComponent: components,
                },
                pagination: {
                    page: 1,
                    limit: 20,
                },
            });
        }

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(role => ({
                    value: role.uuid,
                    label: role.name,
                })), 'label'),
                // complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    addRole(e) {
        const value = e ? e[0].value : null;
        this.setValue('user.roles', value);
    }

    onMultiSelectAsyncChange(fieldName, e) {
        this.onChangeInput(fieldName, {target: {value: e}});
    }

    onChangeInput(field, {target}) {
        const value = _.get(target, 'value');
        const label = _.get(target, 'label');

        this.setValue(field, value);
        if (label) {
            this.setValue(field + '_name', label, true);
        }
    }


    renderGeneral() {
        const photo = this.get('info.photo');
        const photoClassName = classNames('profile-pic__img', photo ? '' : 'img-empty');

        return (
            <div>
                <div className="profile-pic">
                    <div className={photoClassName}
                         style={{backgroundImage: `url(${this.get('info.photo', '/img/user_default_avatar.svg')})`}}>
                        <FileReaderInput as="binary" id="my-file-input" onChange={::this.uploadAvatar}>
                            <a className="b-icon-link  b-icon-link_icon_edit_white"/>
                        </FileReaderInput>
                    </div>
                </div>

                <div className="personal">
                    <Block title="Фамилия *">
                        {this.textInput('user.info.surname')}
                    </Block>
                    <Block title="Имя *">
                        {this.textInput('user.info.name')}
                    </Block>
                    {((!window.RNIS_SETTINGS.hide_pers_data || window.RNIS_SETTINGS.CITY_MURMANSK) ? (
                        <Block title="Отчество" withRightMargin={true}>
                            {this.textInput('user.info.second_name')}
                        </Block>
                    ) : null) }

                    <Block title="Дата рождения">
                        {this.datepicker('user.info.birthdate')}
                    </Block>
                    <Block title="Пол" withBottomMargin={true}>
                        {this.radio('user.info.gender', 'male', 'Мужской')}
                        {this.radio('user.info.gender', 'female', 'Женский')}
                    </Block>
                </div>

                <Block title="Телефон 1">
                    {this.maskInput('user.info.phone', '+7(999)999-99-99')}
                </Block>
                <Block title="Телефон 2">
                    {this.maskInput('user.info.phone2', '+7(999)999-99-99')}
                </Block>
                <Block title="Электронная почта *">
                    {this.textInput('user.info.email')}
                </Block>
                <Block title="SIP логин">
                    {this.textInput('user.info.sip_ext')}
                </Block>
                <Block title="SIP пароль">
                    {this.textInput('user.info.sip_password')}
                </Block>

                {!this.get('is_virtual') ? ([
                    <Block key="login" title="Логин *">
                        {this.textInput('user.login', {
                            autoComplete: 'new-password',
                        })}
                    </Block>,
                    ...(!this.state.user.is_notificated ? [(
                        <Block key="password" title="Пароль *">
                            {this.textInput('user.password', {
                                type: 'password',
                                autoComplete: 'new-password',
                            })}
                        </Block>
                    )] : []),
                    <Block key="roles" className="expand-top" title="Роли">
                        {/* {this.select('user.roles', this.state.rolesList, {multi: true})}*/}
                        {this.selectAsync('user.roles', ::this.RolesDebounce, {
                            onChange: this.onMultiSelectAsyncChange.bind(this, `user.roles`),
                            multi: true
                        })}
                    </Block>,
                    ...(window.RNIS_SETTINGS.columns_user_subsystems_column ? [(
                        <Block key="subsystems" className="expand-top" title="Подсистемы">
                            {this.select('user.subsystems', this.state.vehicle_subsystems, {
                                multi: true,
                            })}

                        </Block>
                    )] : [])
                    ,
                ]) : null}
                <Block>
                    {this.checkbox('user.is_virtual', 'Без возможности логина')}
                </Block>
                <Block>
                    {this.checkbox('user.is_blocked', 'Заблокирован')}
                </Block>
                <Block>
                    {this.checkbox('user.is_notificated', 'Отправить приглашение на регистрацию')}
                </Block>
            </div>
        );
    }

    renderPassport() {
        return (
            <div>
                <Block size="sm" title="Серия">
                    {this.textInput('user.info.passport_serial')}
                </Block>
                <Block size="mdl" title="Номер">
                    {this.textInput('user.info.passport_number')}
                </Block>
                <Block title="Дата выдачи">
                    {this.datepicker('user.info.passport_issue_date')}
                </Block>
                <Block title="Выдан">
                    {this.textInput('user.info.passport_issued')}
                </Block>
                <Block title="Код подразделения">
                    {this.textInput('user.info.passport_issuer')}
                </Block>
            </div>
        );
    }

    renderPersonal() {
        return (
            <div>
                <Block size="xl" title="Место рождения">
                    {this.textInput('user.info.birthplace')}
                </Block>
                <Block size="xl" title="Адрес прописки">
                    {this.textInput('user.info.residence_address')}
                </Block>
                <Block title="ИНН" border={false}>
                    {this.textInput('user.info.inn')}
                </Block>
                <Block title="СНИЛС">
                    {this.textInput('user.info.snils')}
                </Block>
            </div>
        );
    }

    renderDriver() {
        return (
            <div>
                <Block title="Серия">
                    {this.textInput('user.info.driver_serial')}
                </Block>
                <Block title="Номер">
                    {this.textInput('user.info.driver_number')}
                </Block>
                <Block title="Дата выдачи">
                    {this.datepicker('user.info.driver_date')}
                </Block>
                <Block title="Стаж с">
                    {this.textInput('user.info.driver_since')}
                </Block>
                <Block title="Категории">
                    {this.select('user.info.driver_license_categories', this.state.driver_license_categories, {
                        multi: true,
                    })}
                </Block>
            </div>
        );
    }

    renderEducations() {
        const list = this.state.user.educations || [];

        if (list.length === 0 && currentUser.can('com.rnis.auth.permission.user.education', 'create')) {
            return (
                <div className="add-link">
                    <a className="add-job b-icon-link b-icon-link_icon_plus" href="#" onClick={::this.addEducation}>Добавить
                        запись</a>
                </div>
            );
        }
        return list.map(::this.renderEducation);
    }

    renderEducation(education, index) {
        return (
            <div key={index}>
                <Block size="xl" title="Учебное заведение">
                    {this.textInput(`user.educations.${index}.name`)}
                </Block>
                <Block size="xl" title="Специальность">
                    {this.textInput(`user.educations.${index}.speciality`)}
                </Block>
                <Block className="expand-top" title="Дата поступления">
                    {this.datepicker(`user.educations.${index}.date_from`)}
                </Block>
                <Block className="expand-top" title="Дата окончания">
                    {this.datepicker(`user.educations.${index}.date_to`)}
                </Block>
                <div className="add-link">
                    {currentUser.can('com.rnis.auth.permission.user.education', 'delete') ? (
                        <a className="remove-job b-icon-link b-icon-link_icon_basket" href="#"
                           onClick={this.deleteEducation.bind(this, index)}>Удалить запись</a>
                    ) : null}
                    {((index === this.state.user.educations.length - 1) && (currentUser.can('com.rnis.auth.permission.user.education', 'create'))) ? (
                        <span>
                            <span className="add-link__separator"/>
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="#"
                               onClick={::this.addEducation}>Добавить запись</a>
                        </span>
                    ) : null}

                </div>
                {(index !== this.state.user.educations.length - 1) ? <div className="hr"/> : null}
            </div>
        );
    }

    addEducation(e) {
        e.preventDefault();

        let user = this.state.user;
        user.educations = user.educations || [];
        user.educations.push({
            name: '',
            speciality: '',
            date_from: '',
            date_to: '',
        });
        this.setState({user});
    }

    deleteEducation(index, e) {
        e.preventDefault();

        let user = this.state.user;
        user.educations.splice(index, 1);
        this.setState({user});
    }

    renderTachographs() {
        const list = this.state.user.tachographs || [];

        if (list.length === 0) {
            return (
                <div className="add-link">
                    <a className="add-job b-icon-link b-icon-link_icon_plus" href="#" onClick={::this.addTachograph}>Добавить
                        запись</a>
                </div>
            );
        }
        return list.map(::this.renderTachograph);
    }

    renderTachograph(tachograph, index) {
        return (
            <div key={index}>
                <Block size="xl" title="Код водителя по тахографу">
                    {this.textInput(`user.tachographs.${index}.code`)}
                </Block>
                <Block className="expand-top" title="Дата с">
                    {this.datepicker(`user.tachographs.${index}.date_from`)}
                </Block>
                <Block className="expand-top" title="Дата по">
                    {this.datepicker(`user.tachographs.${index}.date_to`)}
                </Block>
                <div className="add-link">
                    <a className="remove-job b-icon-link b-icon-link_icon_basket" href="#"
                       onClick={this.deleteTachograph.bind(this, index)}>Удалить запись</a>
                    {(index === this.state.user.tachographs.length - 1) ? (
                        <span>
                            <span className="add-link__separator"/>
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="#"
                               onClick={::this.addTachograph}>Добавить запись</a>
                        </span>
                    ) : null}

                </div>
                {(index !== this.state.user.tachographs.length - 1) ? <div className="hr"/> : null}
            </div>
        );
    }

    addTachograph(e) {
        e.preventDefault();

        let user = this.state.user;
        user.tachographs = user.tachographs || [];
        user.tachographs.push({
            code: '',
            date_from: '',
            date_to: '',
        });
        this.setState({user});
    }

    deleteTachograph(index, e) {
        e.preventDefault();

        let user = this.state.user;
        user.tachographs.splice(index, 1);
        this.setState({user});
    }

    renderPositions() {
        const list = this.state.user.positions || [];

        if (list.length === 0 && currentUser.can('com.rnis.auth.permission.user.position', 'create')) {
           
            return (
                <div className={`add-link${this.props.errors['user.positions']
                                       ? ' input__style_state_wrong'
                                       : ''}`}>
                    <a className="add-job b-icon-link b-icon-link_icon_plus" href="#" onClick={::this.addPosition}>
                        Добавить запись
                    </a>
                </div>
            );
        }
        return list.map(::this.renderPosition);
    }

    renderPosition(position, index) {
        // if (!position.unit_uuid && this.props.mode === 'edit') return;
        return (
            <div key={index}>
                <Block size="xl" title="Предприятие *">
                    <SelectAsync
                        loadOptions={(input) => {
                            return this.props.getUnitsForSelect(input || position.unit_uuid, this.get('component') || this.props.params.component);
                        }}
                        value={this.getValue(`user.positions.${index}.unit_uuid`)}
                        onChange={this.onChangeUnit.bind(this, index)}
                        className={_.get(this.props.errors, `user.positions.${index}.unit_uuid`) ? 'wrong' : ''}
                    />
                    <ValidationError error={_.get(this.props.errors, `user.positions.${index}.unit_uuid`)}/>
                </Block>
                <Block title="Должность *">
                    <SelectAsync
                        ref={`user.positions.${index}.position`}
                        loadOptions={(input) => {
                            return this.props.getUnitPositionsForSelect(this.getValue(`user.positions.${index}.unit_uuid`), input || position.position);
                        }}
                        value={this.getValue(`user.positions.${index}.position_uuid`)}
                        onChange={this.onChangePosition.bind(this, index)}
                        className={_.get(this.props.errors, `user.positions.${index}.position_uuid`) ? 'wrong' : ''}
                    />
                    <ValidationError error={_.get(this.props.errors, `user.positions.${index}.position_uuid`)}/>
                </Block>
                <Block title="Группы">
                    {this.selectAsync(`user.positions.${index}.groups`, this.props.getUnitUserGroupsForSelect.bind(this, this.getValue(`user.positions.${index}.unit_uuid`)), {
                        multi: true,
                    })}
                </Block>
                <Block title="Табельный номер *">
                    {this.textInput(`user.positions.${index}.personnel_number`)}
                </Block>
                <Block>
                    {this.checkbox(`user.positions.${index}.is_main_position`, 'Основная должность', {
                        className: 'b-checkbox_posit',
                    })}
                </Block>
                <Block title="Дата приема на работу *">
                    {this.datepicker(`user.positions.${index}.hired_at`)}
                </Block>
                <Block title="Дата увольнения">
                    {this.datepicker(`user.positions.${index}.fired_at`)}
                </Block>
                <div className="add-link">
                    {currentUser.can('com.rnis.auth.permission.user.position', 'delete') && (
                        <a className="remove-job b-icon-link b-icon-link_icon_basket" href="#"
                           onClick={this.deletePosition.bind(this, index)}>Удалить запись</a>
                    )}
                    {((index === this.state.user.positions.length - 1) && (currentUser.can('com.rnis.auth.permission.user.position', 'create'))) && (
                        <span>
                            <span className="add-link__separator"/>
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="#"
                               onClick={::this.addPosition}>Добавить запись</a>
                        </span>
                    )}
                </div>
                {(index !== this.state.user.positions.length - 1) ? <div className="hr"/> : null}
            </div>
        );
    }

    addPosition(e) {
        e.preventDefault();

        let user = this.state.user;
        user.positions = user.positions || [];
         //    let user = this.state.user;
            //    user.positions = user.positions || [];
            //    user.positions.push({
            //        unit_uuid: null,
            //        position_uuid: null,
            //        personnel_number: '',
            //        is_main_position: true,
            //        hired_at: '',
            //        fired_at: '',
            //    });
            //    this.setState({user})

        user.positions.push({
            unit_uuid: null,
            position_uuid: null,
            personnel_number: '',
            is_main_position: true,
            hired_at: '',
            fired_at: '',
        });
        this.setState({user});
    }

    deletePosition(index, e) {
        e.preventDefault();

        let user = this.state.user;
        user.positions.splice(index, 1);
        this.setState({user});
    }

    async onChangeUnit(index, e) {
        const value = e ? e.value : null;
        let user = this.state.user;
        user.positions[index].unit_uuid = value;
        user.positions[index].position_uuid = null;
        await this.setState({user});

        this.refs[`user.positions.${index}.position`].reload();
    }

    async onChangePosition(index, e) {
        let user = this.state.user;
        user.positions[index].position_uuid = _.get(e, 'value') || null;
        user.positions[index].position_type = _.get(e, 'type') || null;
        user.positions[index].position_name = _.get(e, 'label') || null;
        this.setState({user});
    }

    renderWorkGraphicList() {
        const list = this.state.user.work_graphics || [];

        if (list.length === 0) {
            return (
                <div className="add-link">
                    <a className="add-job b-icon-link b-icon-link_icon_plus" href="#" onClick={::this.addWorkGraphic}>Добавить
                        запись</a>
                </div>
            );
        }
        return list.map(::this.renderWorkGraphic);
    }

    renderWorkGraphic(workGraphic, index) {
        return (
            <div key={index}>
                <Block size="xl" title="График работ">
                    {this.select(`user.work_graphics.${index}.work_graphic_uuid`, this.state.work_graphics)}
                </Block>
                <Block className="expand-top" title="Дата с">
                    {this.datepicker(`user.work_graphics.${index}.date_from`)}
                </Block>
                <Block className="expand-top" title="Дата по">
                    {this.datepicker(`user.work_graphics.${index}.date_to`)}
                </Block>
                <div className="add-link">
                    <a className="remove-job b-icon-link b-icon-link_icon_basket" href="#"
                       onClick={this.deleteWorkGraphic.bind(this, index)}>Удалить запись</a>
                    {((index === this.state.user.work_graphics.length - 1)) ? (
                        <span>
                            <span className="add-link__separator"/>
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="#"
                               onClick={::this.addWorkGraphic}>Добавить запись</a>
                        </span>
                    ) : null}

                </div>
                {(index !== this.state.user.work_graphics.length - 1) ? <div className="hr"/> : null}
            </div>
        );
    }

    addWorkGraphic(e) {
        e.preventDefault();

        let user = this.state.user;
        user.work_graphics = user.work_graphics || [];
        user.work_graphics.push({
            work_graphic_uuid: null,
            date_from: '',
            date_to: '',
        });
        this.setState({user});
    }

    deleteWorkGraphic(index, e) {
        e.preventDefault();

        let user = this.state.user;
        user.work_graphics.splice(index, 1);
        this.setState({user});
    }

    renderRTiO() {
        const years = [];
        for (let i = 0; i < 5; i++) {
            years.push(moment().subtract(i, 'years').year());
        }

        const date = moment(`${this.state.rtio.year}-${this.state.rtio.month + 1}-01`);

        return (
            <div>
                <Block size="xl">
                    <Block size="sm">
                        <a href="javascript:void(0)" onClick={::this.prevRTiO}>&larr;</a>
                    </Block>
                    <Block size="sm">
                        {this.select('rtio.month', _.map(this.months, (month, index) => ({
                            value: index,
                            label: month,
                        })))}
                    </Block>
                    <Block size="sm">
                        {this.select('rtio.year', _.map(years, (year) => ({
                            value: year,
                            label: year,
                        })))}
                    </Block>
                    <Block size="sm" className="text-right">
                        <a href="javascript:void(0)" onClick={::this.nextRTiO}>&rarr;</a>
                    </Block>
                </Block>

                <TableContainer>
                    <div className="Table">
                        <table className="b-table b-table-thead-no-hover">
                            <thead>
                            <tr>
                                <td>День</td>
                                {_.map(_.range(1, date.daysInMonth() + 1), (i) => (
                                    <td key={i}>{i}</td>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Наименование графика</td>
                                {_.map(_.range(date.daysInMonth()), this.renderRTiOCell.bind(this, date, 'name'))}
                            </tr>
                            <tr>
                                <td>Ежедневный отдых</td>
                                {_.map(_.range(date.daysInMonth()), this.renderRTiOCell.bind(this, date, 'day_rest'))}
                            </tr>
                            <tr>
                                <td>Еженедельный отдых</td>
                                {_.map(_.range(date.daysInMonth()), this.renderRTiOCell.bind(this, date, 'week_rest'))}
                            </tr>
                            <tr>
                                <td colSpan={date.daysInMonth() + 1}>Итого</td>
                            </tr>
                            <tr>
                                <td>Рабочее время</td>
                                {_.map(_.range(date.daysInMonth()), this.renderRTiOCell.bind(this, date, 'work'))}
                            </tr>
                            {(this.props.params.component === 'road') ? (
                                <tr>
                                    <td>Балл вождения</td>
                                    {_.map(_.range(date.daysInMonth()), this.renderRTiOCell.bind(this, date, 'score'))}
                                </tr>
                            ) : null}
                            {(this.props.params.component === 'kiutr') ? (
                                <tr>
                                    <td>Время в управлении</td>
                                    {_.map(_.range(date.daysInMonth()), this.renderRTiOCell.bind(this, date, 'in_drive'))}
                                </tr>
                            ) : null}
                            {(this.props.params.component === 'kiutr') ? (
                                <tr>
                                    <td>Суммарная наработка (время в работе)</td>
                                    {_.map(_.range(date.daysInMonth()), this.renderRTiOCell.bind(this, date, 'sum_work'))}
                                </tr>
                            ) : null}
                            {(this.props.params.component === 'kiutr') ? (
                                <tr>
                                    <td>Тахограф</td>
                                    {_.map(_.range(date.daysInMonth()), this.renderRTiOCell.bind(this, date, 'tachograph'))}
                                </tr>
                            ) : null}
                            </tbody>
                        </table>
                    </div>
                </TableContainer>
            </div>
        );
    }

    prevRTiO() {
        const date = moment(`${this.state.rtio.year}-${this.state.rtio.month + 1}-01`).subtract(1, 'month');

        this.setState({
            rtio: {
                year: date.year(),
                month: date.month(),
            },
        });
    }

    nextRTiO() {
        const date = moment(`${this.state.rtio.year}-${this.state.rtio.month + 1}-01`).add(1, 'month');

        this.setState({
            rtio: {
                year: date.year(),
                month: date.month(),
            },
        });
    }

    renderRTiOCell(date, field, day) {
        const d = date.clone().add(day, 'days');
        const graphic = this.getGraphic(d);

        let value = '';
        let isRed = false;
        if (graphic) {
            const workGraphic = _.find(this.state.work_graphics, {value: graphic.work_graphic_uuid});
            if (workGraphic) {
                switch (field) {
                    case 'name':
                        value = _.get(workGraphic, 'document.name_short');
                        break;
                    case 'work':
                        value = this.getDayWork(d);
                        if (value !== null) {
                            isRed = value > workGraphic.document.max_per_day_hours;
                        }
                        break;
                    case 'day_rest':
                        value = this.getDayRest(d);
                        if (value !== null) {
                            isRed = value < workGraphic.document.min_rest_hours;
                        } else {
                            value = 'сутки+';
                        }
                        break;
                    case 'week_rest':
                        if (d.day() === 0) {
                            let sum = 0;
                            for (let i = 0; i < 7; i++) {
                                const dayWork = this.getDayWork(d.clone().subtract(i, 'days'));
                                if (dayWork !== null) {
                                    sum += 24 - dayWork;
                                } else {
                                    sum += 24;
                                }
                            }
                            value = sum;
                        }
                        break;
                    case 'in_drive':
                        const types = JSON.parse(_.get(_.find(this.state.kiutr_run_types, {label: 'В управлении'}), 'document.types') || '[]');
                        value = _.round(_.sumBy(_.flatten(_.map(_.flatten(_.map(this.getOrders(d), 'shifts')), 'runs')), (run) => {
                            if (_.indexOf(types, run.type) !== -1) {
                                return run.time;
                            }
                            return 0;
                        }) / 60);
                        break;
                    case 'tachograph':
                        value = _.round(_.sumBy(_.flatten(_.map(_.flatten(_.map(this.getOrders(d), 'shifts')), 'runs')), (run) => {
                            return parseFloat(run.tachograph || 0);
                        }), 2);
                        break;
                    case 'sum_work':
                        value = '-';
                        if (moment(d).add(1, 'day').month() !== d.month()) {
                            let from = moment(d).startOf('month');
                            const to = moment(d).endOf('month');

                            value = 0;
                            while (from.isSameOrBefore(to)) {
                                value += this.getDayWork(from) || 0;
                                from = from.add(1, 'day');
                            }
                        }
                        break;
                }
            }
        }
        if (field === 'score') {
            const waybills = _.filter(this.getWaybills(d), (waybill) => {
                return waybill.score !== null;
            });
            value = (waybills.length !== 0) ? (_.sumBy(waybills, waybill => _.toInteger(_.get(waybill, 'score'))) / waybills.length) : 0;
        }

        return (
            <td key={d.format(formats.DATE_URL)} className={isRed ? 'rtio-red' : ''}>{value}</td>
        );
    }

    getDayRest(d) {
        switch (this.props.params.component) {
            case 'road':
                const currentDayWaybill = this.getWaybill(d);
                const prevDayWaybill = this.getWaybill(d.clone().subtract(1, 'day'));
                if (currentDayWaybill && prevDayWaybill && currentDayWaybill.shift_start_time && prevDayWaybill.shift_end_time) {
                    const departure = moment(moment(currentDayWaybill.date).format(formats.DATE_URL) + ' ' + currentDayWaybill.shift_start_time);
                    const arrival = moment(moment(prevDayWaybill.date).format(formats.DATE_URL) + ' ' + prevDayWaybill.shift_end_time);
                    return _.round(departure.diff(arrival, 'hours', true));
                }
                break;
            case 'kiutr':
                const currentDayOrder = this.getOrder(d);
                const prevDayOrder = this.getOrder(d.clone().subtract(1, 'day'));
                if (currentDayOrder && prevDayOrder) {
                    const departure = moment(currentDayOrder.work_from);
                    const arrival = moment(prevDayOrder.work_to);
                    return _.round(departure.diff(arrival, 'hours', true));
                }
                break;
        }

        return null;
    }

    getDayWork(d) {
        switch (this.props.params.component) {
            case 'road':
                const currentDayWaybill = this.getWaybill(d);
                if (currentDayWaybill && currentDayWaybill.shift_start_time && currentDayWaybill.shift_end_time) {
                    const departure = moment(moment(currentDayWaybill.date).format(formats.DATE_URL) + ' ' + currentDayWaybill.shift_start_time);
                    const arrival = moment(moment(currentDayWaybill.date).format(formats.DATE_URL) + ' ' + currentDayWaybill.shift_end_time);
                    let result = _.round(arrival.diff(departure, 'hours', true));
                    if (result < 0) {
                        result += 24;
                    }
                    return result;
                }
                break;
            case 'kiutr':
                const currentDayOrder = this.getOrder(d);
                if (currentDayOrder) {
                    const departure = moment(currentDayOrder.work_from);
                    const arrival = moment(currentDayOrder.work_to);
                    let result = _.round(arrival.diff(departure, 'hours', true));
                    if (result < 0) {
                        result += 24;
                    }
                    return result;
                }
                break;
        }
        return null;
    }

    getWaybill(date) {
        return _.find(this.state.waybills || [], (waybill) => {
            return moment(waybill.date).isSame(date, 'day');
        });
    }

    getWaybills(date) {
        return _.filter(this.state.waybills || [], (waybill) => {
            return moment(waybill.date).isSame(date, 'day');
        });
    }

    getOrder(date) {
        return _.find(this.state.orders || [], (order) => {
            return moment(order.date).isSame(date, 'day');
        });
    }

    getOrders(date) {
        return _.filter(this.state.orders || [], (order) => {
            return moment(order.date).isSame(date, 'day');
        });
    }

    getGraphic(date) {
        return _.find(this.get('work_graphics') || [], (graphic) => {
            return moment(graphic.date_from).isSameOrBefore(date, 'day') && (!graphic.date_to || moment(graphic.date_to).isAfter(date));
        });
    }

    async loadWaybills() {
        const response = await this.props.getWaybills({
            filters: {
                withDriver: this.get('uuid'),
                withPeriod: [
                    moment(`${this.state.rtio.year}-${this.state.rtio.month + 1}-01`).subtract(1, 'day').format(formats.DATE_API),
                    moment(`${this.state.rtio.year}-${this.state.rtio.month + 1}-01`).add(1, 'month').subtract(1, 'day').format(formats.DATE_API),
                ],
            },
        });

        if (response.isOk) {
            this.setState({
                waybills: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadOrders() {
        const response = await this.props.getOrders({
            filters: {
                withDrivers: [this.get('uuid')],
                withPeriod: [
                    moment(`${this.state.rtio.year}-${this.state.rtio.month + 1}-01`).subtract(7, 'day').format(formats.DATE_API),
                    moment(`${this.state.rtio.year}-${this.state.rtio.month + 1}-01`).add(1, 'month').subtract(1, 'day').format(formats.DATE_API),
                ],
            },
        });

        if (response.isOk) {
            this.setState({
                orders: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async setValue(field, value) {
        super.setValue(field, value);

        if ((field === 'rtio.month') || (field === 'rtio.year')) {
            if (this.props.params.component === 'road') {
                this.loadWaybills();
            }
            if (this.props.params.component === 'kiutr') {
                this.loadOrders();
            }
        }
    }

    async refreshUser() {
        const response = await this.props.refreshUser(this.get('uuid'));

        if (response.isOk) {
            alerts.success('Сессия сброшена');
        } else {
            response.showErrors();
        }
    }
}
