import React, {Component} from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapLayer from "components/ui/map/base/layer";
import {connect} from "react-redux";
import {getStopPointsWithBounds} from "store/reducers/routes/routes";
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import StopPointsEditor from "components/modules/geo/stop-points/editor";

@propTypes({
    onStopPointClick: PropTypes.func,
    onStopPointDblClick: PropTypes.func,
})

@defaultProps({
    onStopPointClick: null,
    onStopPointDblClick: () => {
    },
})

@connect((state) => ({}), {getStopPointsWithBounds})

export default class StopPointsChildrenLayer extends AbstractMapLayer {

    state = {
        stopPoints: [],
        editorUuid: null,
    };

    loadStopPointsCallback = ::this.loadStopPoints;

    componentWillMount() {
        super.componentWillMount();
        if (this.props.leafletMap) {
            this.props.leafletMap.on('moveend', this.loadStopPointsCallback);

        }

        this.loadStopPoints();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.props.leafletMap.off('moveend', this.loadStopPointsCallback);
    }

    async loadStopPoints() {
        const boundingBox = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getBoundingBox() : this.props.map.getBoundingBox();
        const zoom = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getZoom() : this.props.map.getZoom();
        const response = await this.props.getStopPointsWithBounds(boundingBox, zoom, 'children');

        if (response.isOk) {
            this.setState({
                stopPoints: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    onEditClick(uuid) {
        this.setState({
            editorUuid: uuid,
        });
    }

    closeEditor() {
        this.setState({
            editorUuid: null,
        });
    }

    getEditor() {
        return (
            <StopPointsEditor
                mode="edit"
                uuid={this.state.editorUuid}
                onSubmit={::this.closeEditor}
                onClose={::this.closeEditor}
                />
        );
    }

    render() {
        const editor = this.state.editorUuid ? this.getEditor() : null;

        return (
            <div>
                {editor}
                <div style={{display: 'none'}}>
                    {this.state.stopPoints.map(::this.renderStopPoint)}
                </div>
            </div>
        );
    }

    renderStopPoint(stopPoint) {
        return (
            <StopPointMarker
                key={stopPoint.uuid}
                {...this.props}
                latitude={stopPoint.latitude}
                longitude={stopPoint.longitude}
                item={stopPoint}
                onClick={this.props.onStopPointClick}
                onDblClick={this.props.onStopPointDblClick.bind(this, stopPoint.uuid, stopPoint.latitude, stopPoint.longitude)}
                onEditClick={this.onEditClick.bind(this, stopPoint.uuid)}
                onForwardAddClick={this.props.onStopPointForwardAddClick}
                onReverseAddClick={this.props.onStopPointReverseAddClick}
            />
        );
    }

}