import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';
import {filterRolesBySafeList} from 'helpers/role_safeList';

const GET_PERMISSIONS = 'GET_PERMISSIONS';

const initialState = new Map({
    permissions: [],
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case 'GET_PERMISSIONS':
            return state.set('permissions', action.payload.items);
    }

    return state;
}

export const getPermissions = (isNeedCustomResponse) => async (dispatch) => {
    try {
        let response;

        if (isNeedCustomResponse) {
            const localResponse = await api.auth.getPermissions();
            localResponse.payload.items = filterRolesBySafeList(localResponse.payload.items);
            response = localResponse;
            dispatch({
                type: GET_PERMISSIONS,
                payload: {
                    items: localResponse.payload.items
                }
            });
        } else {
            response = await api.auth.getPermissions();
            dispatch({
                type: GET_PERMISSIONS,
                payload: {
                    items: response.payload.items
                }
            });
        }

        console.info("Response", response)
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRoles = (meta) => async (dispatch) => {
    try {
        const response = await api.auth.getRoles(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createRole = (data) => async (dispatch) => {
    try {
        const response = await api.auth.createRole(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateRole = (data) => async (dispatch) => {
    try {
        const response = await api.auth.updateRole(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteRole = (data) => async (dispatch) => {
    try {
        const response = await api.auth.deleteRole(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
