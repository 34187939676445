import React from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import L from 'leaflet';
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUnits} from "store/reducers/organizational_units/units";
import PageModal from 'components/ui/page-modal';
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import GlobalLoaderComponent from "components/ui/global-loader";
import ContextTooltip from "components/ui/context-tooltip";
import * as alerts from "helpers/alerts";
import Button from "components/ui/button";
import {createRoadPart, deleteRoadPart, getRoadPart, updateRoadPart} from "store/reducers/commdept/road_parts";
import MapComponent from "components/ui/map";
import DirectionalPolyline from "components/ui/map/objects/directional-polyline";
import MapGeojson from "components/ui/map/base/geojson";
import currentUser from 'helpers/current-user';

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getRoadPart, createRoadPart, updateRoadPart, deleteRoadPart})

export default class Editor extends BaseEditor {

    getFullTitle() {
        return 'Участок дороги';
    }

    modelClass = 'App\\Model\\RoadPart';
    modalClassName = 'b-modal-contract edit-work-modal';

    async loadData(uuid) {
        return await this.props.getRoadPart(uuid);
    }

    async createItem(data) {
        return await this.props.createRoadPart(data);
    }

    async updateItem(data) {
        return await this.props.updateRoadPart(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    deleteItem() {
        return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            const response = await this.props.deleteRoadPart({
                uuid: this.props.uuid,
            });
            if (response.isOk) {
                this.props.onSubmit();
            } else {
                response.showErrors();
            }
        });
    }

    showMap() {
        this.setState({
            mapVisible: true,
        });
    }

    hideMap() {
        this.setState({
            mapVisible: false,
        });
    }

    renderModals() {
        if (this.state.mapVisible) {
            const title = 'Участок дороги на карте';

            const buttons = (
                <ModalTopMenuList>
                    <ModalTopMenuButton
                        key="close"
                        className="_close"
                        tooltip="Отменить"
                        onClick={::this.hideMap}
                    />
                </ModalTopMenuList>
            );

            if (!this.refs.map) {
                setTimeout(() => {
                    this.forceUpdate();
                }, 300);
            }

            return (
                <PageModal
                    header={{title, buttons}}
                    onClose={::this.hideMap}
                    withFade
                >
                    <MapComponent
                        ref="map"
                        showLayers={false}
                    >
                        {(this.refs.map) ? (
                            <MapGeojson
                                map={this.refs.map}
                                ref={::this.fitBounds()}
                                leafletMap={this.refs.map.getWrappedInstance().map}
                                geometry={_.get(this.state.item, 'geometry')}
                                tooltip={_.get(this.state.item, 'name')}
                            />
                        ) : null}

                    </MapComponent>
                </PageModal>
            );
        }
    }

    fitBounds() {
        try {
            const group = L.geoJSON(_.get(this.state.item, 'geometry'));
            this.refs.map.getWrappedInstance().fitBounds(group.getBounds());
        } catch (e) {
        }
    }

    render() {
        let form;
        let buttons;
        let onSubmit;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {
            if (this.state.item) {
                form = this.getForm(this.state.item, ::this.edit);

                onSubmit = ::this.onEdit;

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            {(currentUser.can('com.rnis.system.permission.audit', 'read') && this.modelClass) ?
                                (
                                    <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                                    default="Журнал аудита">
                                        <ModalTopMenuListItem
                                            className="b-icon-link_params b-icon-link_icon_history"
                                            href={`/system/audit/${this.props.uuid}?class=${this.modelClass}`}
                                        />
                                    </ContextTooltip>
                                ) : null}
                            <ContextTooltip key="commdept.map" code="commdept.map" default="На карте">
                                <ModalTopMenuListItem
                                    className="b-icon-link_icon_book"
                                    onClick={::this.showMap}
                                />
                            </ContextTooltip>

                            <ContextTooltip key="base-table-list.delete" code="base-table-list.delete"
                                            default="Удалить">
                                <ModalTopMenuListItem
                                    className="b-icon-link_params b-icon-link_icon_basket"
                                    onClick={::this.deleteItem}/>
                            </ContextTooltip>

                            <ContextTooltip key="base-editor.print" code="base-editor.print" default="Печать">
                                <ModalTopMenuListItem
                                    className="b-icon-link_icon_print"
                                    onClick={::this.print}
                                />
                            </ContextTooltip>

                            <ModalTopMenuListSeparator key="separator"/>
                        </ModalTopMenuList>

                        <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                            <ModalTopMenuButton
                                className="_close"
                                onClick={::this.props.onClose}
                            />
                        </ContextTooltip>
                    </ModalTopMenuButtons>
                );
            }
        } else {
            form = this.getForm({}, ::this.create);

            onSubmit = ::this.onCreate;

            buttons = (
                <ModalTopMenuButtons>
                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={::this.props.onClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );
        }

        return (
            <PageModal
                header={{title: this.getFullTitle(), buttons}}
                onClose={this.props.onClose}
                className={`profile-modal b-modal-${this.props.mode} ${this.modalClassName}`}
                withFade={this.withFade}
            >
                {loader}
                {form}
                <div className="b-modal__footer">
                    <div className="b-modal__footer-txt">Вы хотите сохранить все изменения?</div>
                    <Button size="md" color="white" shadow="gray" className="b-button_cancel" text="Отменить"
                            onClick={this.props.onClose}/>
                    <Button size="md" color="red" className="b-button_save" text="Сохранить"
                            onClick={onSubmit}/>
                </div>
                {this.renderModals()}
            </PageModal>
        );
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
})

@connect((state) => ({}), {getDictionaryList, getUnits}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        road_part: {},
        maintenance_groups: [],
    };

    getData() {
        return this.state.road_part;
    }

    async componentDidMount() {
        let road_part = this.props.data;
        road_part.road_uuid = this.props.roadUuid;
        road_part.type = this.props.type;

        await this.setState({
            road_part,
        });

        this.loadDictionaries([
            'maintenance_groups',
        ]);
    }

    get(path, defaultValue = null) {
        return _.get(this.state.road_part, path, defaultValue);
    }

    render() {
        return (
            <div>
                <div className="b-modal__block">
                    <Block title="Название участка">
                        {this.textInput('road_part.name')}
                    </Block>
                    <Block title="Длина">
                        {this.textInput('road_part.length')}
                    </Block>
                    <Block title="Ширина проезжей части, м">
                        {this.textInput('road_part.width')}
                    </Block>
                    <Block title="Начало">
                        {this.textInput('road_part.start')}
                    </Block>
                    <Block title="Конец">
                        {this.textInput('road_part.end')}
                    </Block>
                    <Block title="Категория">
                        {this.textInput('road_part.category')}
                    </Block>
                    <Block title="Группа по содержанию">
                        {this.select('road_part.maintenance_group_uuid', this.state.maintenance_groups)}
                    </Block>
                    <Block title="Балансовая принадлежность">
                        {this.select('road_part.driveway_category', [
                            {
                                value: 'local',
                                label: 'Местные',
                            },
                            {
                                value: 'federal',
                                label: 'Региональные/межмуниципальные',
                            },
                            {
                                value: 'federal_roads',
                                label: 'Федеральные',
                            }
                        ])}
                    </Block>
                    <Block title="Регистрационный номер">
                        {this.textInput('road_part.register_number')}
                    </Block>
                    <Block title="Дата ввода в экслуатацию">
                        {this.datepicker('road_part.date_from')}
                    </Block>
                    <Block title="Дата вывода из эксплуатации">
                        {this.datepicker('road_part.date_to')}
                    </Block>
                    <Block title="Балансодержатель">
                        {this.textInput('road_part.owner_name')}
                    </Block>
                    <Block title="Категория дороги по ГОСТ Р 52398-2005">
                        {this.textInput('road_part.gost_category')}
                    </Block>
                    <Block title="Тип покрытия дороги">
                        {this.textInput('road_part.cover_type')}
                    </Block>
                    <Block title="Количество полос">
                        {this.textInput('road_part.lines_count')}
                    </Block>
                    <Block title="СКПДИ">
                        {this.textInput('road_part.external_id', {
                            disabled: true,
                        })}
                    </Block>
                    <Block>
                        {this.checkbox('road_part.is_ownerless', 'Бесхозный объект')}
                    </Block>
                </div>
            </div>
        );
    }
}
