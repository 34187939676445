import {Map} from 'immutable';
import moment from "moment";
const urlParams = new URLSearchParams(window.location.search);

export const initialState = {
    //smut
    defaultPolygonStyle: null,
    cleanupSpecialistsTelematics: new Map({}),
    cleanupSpecialists: [],
    selected_communal_uuid: urlParams.get('communal') || null,
    initialUpdate: true,
    mu: null,
    muTasks: null,
    muTasksObjects: null,
    colors: null,
    computedGeometry: null,
    groupsTasksByGeometryItem: null,
    isLegendShowBlue: false,
    bluePolygonsRoadPartUuid: null,
    contracts: null,
    contractsToRender: [],
    isNeedContractRender: false,
    faqOpened: false,
    selected_work_type: urlParams.get('work_type') || urlParams.get('work_type_uuid') || 'all',
    loading: false,
    telematics: new Map({}),
    clusters: [],
    sourceType: 't1sync',
    vehicles: {},
    gatn_responsives: [],
    bnso_indicators: [],
    clustersActive: true,
    vehiclesBnso: {},
    selectedRoutes: [],
    selectedRouteVariants: [],
    filterAnalyticType: 'passenger',

    showHistory: false,
    showDetalization: false,
    showHistorySlider: false,
    history: [],
    historyTasks: [],
    historyTaskViolations: [],
    historyGeojson: null,
    currentTime: null,
    playing: false,
    speed: 2,
    retrospectiveFullscreen: 0,
    fullscreen: false,
    showEvents: false,

    units: [],
    route: null,
    routes: [],
    routePanelOpened: false,
    routeStopPoints: {},
    reportsDetails: null,
    reportsSelectedItem: null,
    reportsByCommunal: null,
    isOpenDetailization: false,
    events: [],
    animationActive: window.RNIS_SETTINGS.active_ts_animation === false ? false : true,
    filterResultActive: false,
    filterResultDisplayedOnMap: false,
    notificationInited: false,
    notification: null,
    orderInited: false,
    order: null,
    tasks: [],
    work_types: [],
    vehicleEditUuid: null,
    vehicleFuelUuid: null,
    vehiclePopupActive: false,
    vehicle_popup: {},
    vehiclesLoading: false
};
