import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getDisplayConfigurations = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getDisplayConfigurations(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getDisplayConfiguration = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getDisplayConfiguration(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createDisplayConfiguration = (data) => async (dispatch) => {
    try {
        const response = await api.geo.createDisplayConfiguration(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateDisplayConfiguration = (data) => async (dispatch) => {
    try {
        const response = await api.geo.updateDisplayConfiguration(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteDisplayConfiguration = (data) => async (dispatch) => {
    try {
        const response = await api.geo.deleteDisplayConfiguration(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
