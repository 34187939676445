import { Map } from 'immutable';
import { api } from 'helpers/api';
import { success, error } from 'helpers/response';
import { getGatnViolationsGrpc } from 'helpers/api/gatn';
import { getGeoIntervalsGrpc, getOverspeedViolationGrpc } from '../../../helpers/api/gatn';
import moment from 'moment';
import formats from '../../../dictionaries/formats';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
	}

	return state;
}

export const getGatnViolationJournals = (meta = {}) => async (dispatch) => {
	try {
		const response = await api.gatn.getGatnViolationJournals(meta);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getGatnOverspeenIntervalsGrpc = (meta = {}) => async (dispatch) => {
	try {
		const response = await getOverspeedViolationGrpc(meta);
		const newResponse = {};
		if (response.dataList) {
			newResponse.success = true;
			newResponse.errors = [];
			newResponse.headers = { meta: {} };
			newResponse.payload = {};
			newResponse.payload.items = response.dataList.map((el) => ({
				bnso_number: el.vehicle.devicecode,
				component: el.vehicle.subsystem,
				ended_at: moment.unix(el.pointto.devicetime).format(formats.DATETIME_API),
				ended_latitude: el.pointto.position.latitude,
				ended_longitude: el.pointto.position.longitude,
				max_speed: el.maxspeed,
				speed_limit: el.speedlimit,
				started_at: moment.unix(el.pointfrom.devicetime).format(formats.DATETIME_API),
				started_latitude: el.pointfrom.position.latitude,
				started_longitude: el.pointfrom.position.longitude,
				type: 'speed',
				// unit_uuid: "357ee0a2-80b5-11eb-9c55-0238e74c6923",
				uuid: el.uuid
			}));
		}
		return success(newResponse);
	} catch (e) {
		return error(e);
	}
};

export const getGatnGeoIntervalsGrpc = (meta = {}) => async (dispatch) => {
	try {
		const response = await getGeoIntervalsGrpc(meta);
		const newResponse = {};
		if (response.dataList) {
			newResponse.success = true;
			newResponse.errors = [];
			newResponse.headers = { meta: {} };
			newResponse.payload = {};
			newResponse.payload.items = response.dataList.map((el) => ({
				bnso_number: el.vehicle.devicecode,
				component: el.vehicle.subsystem,
				ended_at: moment.unix(el.dateTo).format(formats.DATETIME_API),
				started_at: moment.unix(el.pointfrom.devicetime).format(formats.DATETIME_API),
				started_latitude: el.pointfrom.position.latitude,
				started_longitude: el.pointfrom.position.longitude,
				// ended_latitude: el.pointto ?  el.pointto.position.latitude : '-',
				// ended_longitude: el.pointto ?  el.pointto.position.longitude : '-',
				// unit_uuid: "357ee0a2-80b5-11eb-9c55-0238e74c6923",
				uuid: el.uuid
			}));
		}
		return success(newResponse);
	} catch (e) {
		return error(e);
	}
};

export const getGatnViolationJournalsGrpc = (meta = {}, timeFrom, timeTo) => async (dispatch) => {
	try {
		const response = await getGatnViolationsGrpc(meta, timeFrom, timeTo);
		const newResponse = {};
		if (response.dataList) {
			newResponse.success = true;
			newResponse.errors = [];
			newResponse.headers = { meta: {} };
			newResponse.headers.meta.pagination = {
				total: response.paginationinfo.total,
				per_page: response.paginationinfo.pagination.limit,
				current_page: response.paginationinfo.pagination.page,
				total_pages: response.paginationinfo.totalpages
			};
			newResponse.payload = {};
			newResponse.payload.items = response.dataList.map((el) => ({
				datadate: el.startDate,
				speed_violations: el.violationCount.speed,
				state_number: el.vehicle.statenumber,
				uuid: el.unit ? el.unit.uuid : '-',
				vehicle_mark_uuid: el.vehicle.vehiclemark,
				vehicle_model_uuid: el.vehicle.vehiclemodel,
				vehicle_uuid: el.vehicle.vehicleuuid,
				devicecode: el.vehicle.devicecode,
				zone_violations: el.violationCount.zone
			}));
		}
		return success(newResponse);
	} catch (e) {
		return error(e);
	}
};

export const getGatnViolations = (meta = {}) => async (dispatch) => {
	try {
		const response = await api.gatn.getGatnViolations(meta);

		return success(response);
	} catch (e) {
		return error(e);
	}
};
