
let systems = {}
if (window.location.hostname.includes("localhost")
|| window.location.hostname.includes("rnis-test")) {
    systems = {
        administration: 'Настройки',
        operator: 'Оператор системы',
        analytics: 'Аналитическая платформа',
        kiutr: 'Управление пассажирскими перевозками',
        ems: 'Электронная маршрутная сеть',
        'kiutr-control': 'Контроль пассажирских перевозок',
        road: 'Обслуживание дорог',
        communal: 'Коммунальная техника',
        children: 'Управление перевозками детей',
        garbage: 'Контроль вывоза мусора',
        taxi: 'Мониторинг легковых такси',
        agricultural: 'Транспорт сельского хозяйства',
        medic: 'Контроль медицинского транспорта',
        timber: 'Перевозки лесоматериалов',
        dangerous: 'Перевозки опасных грузов',
        commdept: 'Коммунальная техника (ДТ)',
        control: 'ГУ ГАТН',
        sbdd: 'Центр безопасности дорожного движения',
        complaints: 'Жалобы',
        forestry: 'Лесное хозяйство',
        utility: 'Жилищно-коммунальное хозяйство',
        farming: 'Сельское хозяйство',
        medicine: 'Центр медицины и катастроф',
        official: 'Служебный транспорт',
        emercom: 'Пожарно-спасательная служба'
    }
}

// Стенд МО
else if (window.location.hostname.includes("mosreg")) {
    systems = {
        administration: 'Настройки',
        operator: 'Оператор системы',
        analytics: 'Аналитическая платформа',
        kiutr: 'Управление пассажирскими перевозками',
        ems: 'Электронная маршрутная сеть',
        'kiutr-control': 'Контроль пассажирских перевозок',
        road: 'Обслуживание дорог',
        communal: 'Коммунальная техника',
        children: 'Управление перевозками детей',
        garbage: 'Контроль вывоза мусора',
        control: 'ГУ ГАТН',
        sbdd: 'Оператор системы',
    }
}

// Новосибирск
else if (window.location.hostname.includes("nso")) {
    systems = {
        administration: 'Настройки',
        operator: 'Оператор системы',
        analytics: 'Аналитическая платформа',
        kiutr: 'Управление пассажирскими перевозками',
        road: 'Обслуживание дорог',
        communal: 'Коммунальная техника',
        children: 'Управление перевозками детей',
        garbage: 'Контроль вывоза мусора',
        taxi: 'Мониторинг легковых такси',
        agricultural: 'Транспорт сельского хозяйства',
        medic: 'Контроль медицинского транспорта',
        timber: 'Перевозки лесоматериалов',
        dangerous: 'Перевозки опасных грузов',
        commdept: 'Коммунальная техника (ДТ)',
        control: 'Транспорт ОГВ'
    }
}

// Нижний Новгород
else if (window.location.hostname.includes("rnc52")) {
    systems = {
        administration: 'Настройки',
        operator: 'Оператор системы',
        analytics: 'Аналитическая платформа',
        kiutr: 'Управление пассажирскими перевозками',
        'kiutr-control': 'Контроль пассажирских перевозок',
        road: 'Обслуживание дорог',
        communal: 'Коммунальная техника',
        children: 'Управление перевозками детей',
        control: 'Прочие АТП'
    }
}

// Облако
else if (window.location.hostname.includes("t1-group")) {
    systems = {
        administration: 'Настройки',
        operator: 'Оператор системы',
        analytics: 'Аналитическая платформа',
        kiutr: 'Управление пассажирскими перевозками',
        ems: 'Электронная маршрутная сеть',
        'kiutr-control': 'Контроль пассажирских перевозок',
        road: 'Обслуживание дорог',
        communal: 'Коммунальная техника',
        children: 'Управление перевозками детей',
        garbage: 'Контроль вывоза мусора',
        control: 'Контроль служебного транспорта'
    }
}

// Омск
else if (window.location.hostname.includes("omskportal")) {
    systems = {
        administration: 'Настройки',
        operator: 'Оператор системы',
        analytics: 'Аналитическая платформа',
        kiutr: 'Управление пассажирскими перевозками',
        ems: 'Электронная маршрутная сеть',
        'kiutr-control': 'Контроль пассажирских перевозок',
        road: 'Обслуживание дорог',
        communal: 'Коммунальная техника',
        children: 'Управление перевозками детей',
        garbage: 'Контроль вывоза мусора',
        control: 'Транспорт ОИВ',
    }
}
// Мурманск
else if (window.location.hostname.includes("smut")) {
    systems = {
        administration: 'Настройки',
        operator: 'Оператор системы',
        analytics: 'Аналитическая платформа',
        road: 'Обслуживание дорог',
        complaints: 'Жалобы',
        sbdd: 'Оператор системы',
    }
}

// Стенд Тулы
else if (window.location.hostname.includes("orgpn")) {
    systems = {
        administration: 'Настройки',
        dangerous: 'Перевозки специальных опасных грузов',
        operator: 'Система администрирования',
        analytics: 'Аналитическая платформа',
        kiutr: 'Управление пассажирскими перевозками',
        ems: 'Электронная маршрутная сеть',
        'kiutr-control': 'Контроль пассажирских перевозок',
        road: 'Дорожное хозяйство',
        communal: 'Коммунальная техника',
        children: 'Перевозки школьников',
        garbage: 'Контроль вывоза мусора',
        control: 'ГУ ГАТН',
        sbdd: 'Центр безопасности дорожного движения',
        medicine: 'Центр медицины и катастроф',
        emercom: 'Пожарно-спасательная служба',
        forestry: 'Лесное хозяйство',
        utility: 'Жилищно-коммунальное хозяйство',
        farming: 'Сельское хозяйство',
        official: 'Служебный транспорт'
    }
}

// Стенд Томск
else if (window.location.hostname.includes("rnis70")) {
    systems = {
        administration: 'Настройки',
        operator: 'Оператор системы',
        analytics: 'Аналитическая платформа',
        kiutr: 'Управление пассажирскими перевозками',
        ems: 'Электронная маршрутная сеть',
        'kiutr-control': 'Контроль пассажирских перевозок',
        road: 'Обслуживание дорог',
        communal: 'Коммунальная техника',
        children: 'Управление перевозками детей',
        garbage: 'Контроль вывоза мусора',
        control: 'Транспорт ОГВ',
        sbdd: 'Центр безопасности дорожного движения',
        complaints: 'Жалобы'
    }
}

// дефолт
else {
    systems = {
        administration: 'Настройки',
        operator: 'Оператор системы',
        analytics: 'Аналитическая платформа',
        kiutr: 'Управление пассажирскими перевозками',
        ems: 'Электронная маршрутная сеть',
        'kiutr-control': 'Контроль пассажирских перевозок',
        road: 'Обслуживание дорог',
        communal: 'Коммунальная техника',
        children: 'Управление перевозками детей',
        garbage: 'Контроль вывоза мусора',
        control: 'ГУ ГАТН',
        sbdd: 'Центр безопасности дорожного движения',
        complaints: 'Жалобы'
    }
}


export default systems

