import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import TabletPage from 'components/ui/tablet/page';
import {Label, Pie, PieChart} from "recharts";
import moment from "moment";
import formats from "dictionaries/formats";
import Datepicker from "components/ui/form/datepicker";
import {getTabletSummary} from "store/reducers/reports/tablet";
import _ from 'lodash';
import {Link} from "react-router";
import * as storage from "utils/storage";
import GlobalLoaderComponent from "components/ui/global-loader";
import {CycleFetch} from "helpers/api";

class TabletTime {
    from = moment().subtract(1, 'day').format(formats.DATE_URL);
    to = moment().subtract(1, 'day').format(formats.DATE_URL);

    update(from, to) {
        this.from = from;
        this.to = to;
    }
}

export const TabletTimeInstance = new TabletTime;

@connect(state => ({}), {getTabletSummary})

export default class TabletHome extends Component {

    state = {
        data: {},
    };

    fields = [
        'kiutr.regularity',
        'kiutr.transport_work',
        'kiutr.vehicles_plan',
        'kiutr.vehicles_bnso',
        'kurs.vehicles',
        'kurs.vehicles_age',
        'kurs.vehicles_percent',
        'kurs.vehicles_bnso',
        'children.regularity',
        'children.transport_work',
        'children.vehicles_plan',
        'children.vehicles_bnso',
        'garbage.vehicles',
        'garbage.vehicles_bnso',
        'control.vehicles',
        'control.vehicles_bnso',
    ];

    _cycleFetch = null;

    async componentWillMount() {
        await this.init();

        this._cycleFetch = new CycleFetch(() => {
            return this.loadData();
        }, () => {
        }, 5 * 60000);
        this._cycleFetch.run();
    }

    componentDidUnmount() {
        this._cycleFetch.stop();
        delete this['_cycleFetch'];
    }

    async init() {
        await this.setState({
            from: TabletTimeInstance.from,
            to: TabletTimeInstance.to,
            displayed_from: TabletTimeInstance.from,
            displayed_to: TabletTimeInstance.to,
        });
    }

    render() {
        return (
            <TabletPage
                title="Сводные показатели"
                header_top_right={this.getHeaderTopRight()}
                header_bottom={this.getHeaderBottom()}
            >
                <div className="tiles">
                    {this.state.loading ? <GlobalLoaderComponent/> : null}
                    <div className="row">
                        <div className="col col-4">
                            <div className="tiles__item tiles__item_color1"
                                 onClick={this.goto.bind(this, '/tablet/kiutr/')}>
                                <div className="tiles__header"><Link className="tiles__title" to="/tablet/kiutr/">Пассажирские
                                    перевозки
                                    <svg className="icon icon_bus icon_white">
                                        <use xlinkHref="/img/spriteSVG.svg#icon_bus"/>
                                    </svg>
                                    <svg className="icon icon_arrow_right icon_white">
                                        <use xlinkHref="/img/spriteSVG.svg#icon_arrow_right"/>
                                    </svg>
                                </Link></div>
                                <div className="tiles__content">
                                    <div className="row">
                                        <div className="col col-5">
                                            <div className="tiles__row tiles__row_bdn">
                                                <div className="tiles__col">Регулярность движения</div>
                                                <div className="tiles__r-progress-bar">
                                                    <PieChart width={103} height={103}>
                                                        <Pie data={[
                                                            {
                                                                value: this.get('kiutr.regularity', false),
                                                            },
                                                            {
                                                                value: 100 - this.get('kiutr.regularity', false),
                                                                fill: 'rgba(255, 255, 255, 0.5)',
                                                            }
                                                        ]}
                                                             nameKey="name"
                                                             dataKey="value"
                                                             cx="50%"
                                                             cy="50%"
                                                             outerRadius={50}
                                                             innerRadius={43}
                                                             startAngle={90}
                                                             endAngle={-270}
                                                             fill="#fff"
                                                             stroke="none">
                                                            <Label value={`${this.get('kiutr.regularity')}%`}
                                                                   position="center" fill="#fff"/>
                                                        </Pie>
                                                    </PieChart>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-5">
                                            <div className="tiles__row tiles__row_bdn">
                                                <div className="tiles__col">План по выходу ТС</div>
                                                <div className="tiles__r-progress-bar">
                                                    <PieChart width={103} height={103}>
                                                        <Pie data={[
                                                            {
                                                                value: this.get('kiutr.vehicles_plan', false),
                                                            },
                                                            {
                                                                value: 100 - this.get('kiutr.vehicles_plan', false),
                                                                fill: 'rgba(255, 255, 255, 0.5)',
                                                            }
                                                        ]}
                                                             nameKey="name"
                                                             dataKey="value"
                                                             cx="50%"
                                                             cy="50%"
                                                             outerRadius={50}
                                                             innerRadius={43}
                                                             startAngle={90}
                                                             endAngle={-270}
                                                             fill="#fff"
                                                             stroke="none">
                                                            <Label value={`${this.get('kiutr.vehicles_plan')}%`}
                                                                   position="center" fill="#fff"/>
                                                        </Pie>
                                                    </PieChart>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-7">
                                            <div className="tiles__row tar">
                                                <div className="tiles__col">Выполнение транспортной работы</div>
                                                <div className="tiles__col">{this.get('kiutr.transport_work')}%</div>
                                                <div className="tiles__h-progress-bar">
                                                    <div className="tiles__h-progress-bar-fill"
                                                         style={{width: this.get('kiutr.transport_work', false) + '%'}}/>
                                                </div>
                                            </div>
                                            <div className="tiles__row tar">
                                                <div className="tiles__col">Оснащенность ГЛОНАСС</div>
                                                <div className="tiles__col">{this.get('kiutr.vehicles_bnso')}%</div>
                                                <div className="tiles__h-progress-bar">
                                                    <div className="tiles__h-progress-bar-fill"
                                                         style={{width: this.get('kiutr.vehicles_bnso', false) + '%'}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-4">
                            <div className="tiles__item tiles__item_color2"
                                 onClick={this.goto.bind(this, '/tablet/kurs/')}>
                                <div className="tiles__header"><Link className="tiles__title" to="/tablet/kurs/">Обслуживание
                                    дорог
                                    <svg className="icon icon_repair_road icon_white">
                                        <use xlinkHref="/img/spriteSVG.svg#icon_repair_road"/>
                                    </svg>
                                    <svg className="icon icon_arrow_right icon_white">
                                        <use xlinkHref="/img/spriteSVG.svg#icon_arrow_right"/>
                                    </svg>
                                </Link></div>
                                <div className="tiles__content">
                                    <div className="row">
                                        <div className="col col-5">
                                            <div className="tiles__row tiles__row_bdn">
                                                <div className="tiles__col">Техники в работе</div>
                                                <div className="tiles__r-progress-bar">
                                                    <PieChart width={103} height={103}>
                                                        <Pie data={[
                                                            {
                                                                value: this.get('kurs.vehicles', false),
                                                            },
                                                            {
                                                                value: 100 - this.get('kurs.vehicles', false),
                                                                fill: 'rgba(255, 255, 255, 0.5)',
                                                            }
                                                        ]}
                                                             nameKey="name"
                                                             dataKey="value"
                                                             cx="50%"
                                                             cy="50%"
                                                             outerRadius={50}
                                                             innerRadius={43}
                                                             startAngle={90}
                                                             endAngle={-270}
                                                             fill="#fff"
                                                             stroke="none">
                                                            <Label value={`${this.get('kurs.vehicles')}%`}
                                                                   position="center" fill="#fff"/>
                                                        </Pie>
                                                    </PieChart>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-7">
                                            <div className="tiles__row tar">
                                                <div className="tiles__col">Возраст ТС</div>
                                                <div className="tiles__col">{this.get('kurs.vehicles_age')}</div>
                                                <div className="tiles__h-progress-bar">
                                                    <div className="tiles__h-progress-bar-fill"
                                                         style={{width: this.get('kurs.vehicles_age', false) + '%'}}/>
                                                </div>
                                            </div>
                                            <div className="tiles__row tar">
                                                <div className="tiles__col">Оснащенность ГЛОНАСС</div>
                                                <div className="tiles__col">{this.get('kurs.vehicles_bnso')}%</div>
                                                <div className="tiles__h-progress-bar">
                                                    <div className="tiles__h-progress-bar-fill"
                                                         style={{width: this.get('kurs.vehicles_bnso', false) + '%'}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-4">
                            <div className="tiles__item tiles__item_color3"
                                 onClick={this.goto.bind(this, '/tablet/children/')}>
                                <div className="tiles__header"><Link className="tiles__title" to="/tablet/children/">Перевозки
                                    детей
                                    <svg className="icon icon_children icon_white">
                                        <use xlinkHref="/img/spriteSVG.svg#icon_children"/>
                                    </svg>
                                    <svg className="icon icon_arrow_right icon_white">
                                        <use xlinkHref="/img/spriteSVG.svg#icon_arrow_right"/>
                                    </svg>
                                </Link></div>
                                <div className="tiles__content">
                                    <div className="row">
                                        <div className="col col-5">
                                            <div className="tiles__row tiles__row_bdn">
                                                <div className="tiles__col">Регулярность движения</div>
                                                <div className="tiles__r-progress-bar">
                                                    <PieChart width={103} height={103}>
                                                        <Pie data={[
                                                            {
                                                                value: this.get('children.regularity', false),
                                                            },
                                                            {
                                                                value: 100 - this.get('children.regularity', false),
                                                                fill: 'rgba(255, 255, 255, 0.5)',
                                                            }
                                                        ]}
                                                             nameKey="name"
                                                             dataKey="value"
                                                             cx="50%"
                                                             cy="50%"
                                                             outerRadius={50}
                                                             innerRadius={43}
                                                             startAngle={90}
                                                             endAngle={-270}
                                                             fill="#fff"
                                                             stroke="none">
                                                            <Label value={`${this.get('children.regularity')}%`}
                                                                   position="center" fill="#fff"/>
                                                        </Pie>
                                                    </PieChart>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-5">
                                            <div className="tiles__row tiles__row_bdn">
                                                <div className="tiles__col">План по выходу ТС</div>
                                                <div className="tiles__r-progress-bar">
                                                    <PieChart width={103} height={103}>
                                                        <Pie data={[
                                                            {
                                                                value: this.get('children.vehicles_plan', false),
                                                            },
                                                            {
                                                                value: 100 - this.get('children.vehicles_plan', false),
                                                                fill: 'rgba(255, 255, 255, 0.5)',
                                                            }
                                                        ]}
                                                             nameKey="name"
                                                             dataKey="value"
                                                             cx="50%"
                                                             cy="50%"
                                                             outerRadius={50}
                                                             innerRadius={43}
                                                             startAngle={90}
                                                             endAngle={-270}
                                                             fill="#fff"
                                                             stroke="none">
                                                            <Label value={`${this.get('children.vehicles_plan')}%`}
                                                                   position="center" fill="#fff"/>
                                                        </Pie>
                                                    </PieChart>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-7">
                                            <div className="tiles__row tar">
                                                <div className="tiles__col">Выполнение транспортной работы</div>
                                                <div className="tiles__col">{this.get('children.transport_work')}%</div>
                                                <div className="tiles__h-progress-bar">
                                                    <div className="tiles__h-progress-bar-fill"
                                                         style={{width: this.get('children.transport_work', false) + '%'}}/>
                                                </div>
                                            </div>
                                            <div className="tiles__row tar">
                                                <div className="tiles__col">Оснащенность ГЛОНАСС</div>
                                                <div className="tiles__col">{this.get('children.vehicles_bnso')}%</div>
                                                <div className="tiles__h-progress-bar">
                                                    <div className="tiles__h-progress-bar-fill"
                                                         style={{width: this.get('children.vehicles_bnso', false) + '%'}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-4">
                            <div className="tiles__item tiles__item_color4"
                                 onClick={this.goto.bind(this, '/tablet/communal/')}>
                                <div className="tiles__header"><Link className="tiles__title" to="/tablet/communal/">Коммунальная техника
                                    <svg className="icon icon_repair_road icon_white">
                                        <use xlinkHref="/img/spriteSVG.svg#icon_buldozer"/>
                                    </svg>
                                    <svg className="icon icon_arrow_right icon_white">
                                        <use xlinkHref="/img/spriteSVG.svg#icon_arrow_right"/>
                                    </svg>
                                </Link></div>
                                <div className="tiles__content">
                                    <div className="row">
                                        <div className="col col-5">
                                            <div className="tiles__row tiles__row_bdn">
                                                <div className="tiles__col">ТС в работе</div>
                                                <div className="tiles__r-progress-bar">
                                                    <PieChart width={103} height={103}>
                                                        <Pie data={[
                                                            {
                                                                value: this.get('communal.vehicles', false),
                                                            },
                                                            {
                                                                value: 100 - this.get('communal.vehicles', false),
                                                                fill: 'rgba(255, 255, 255, 0.5)',
                                                            }
                                                        ]}
                                                             nameKey="name"
                                                             dataKey="value"
                                                             cx="50%"
                                                             cy="50%"
                                                             outerRadius={50}
                                                             innerRadius={43}
                                                             startAngle={90}
                                                             endAngle={-270}
                                                             fill="#fff"
                                                             stroke="none">
                                                            <Label value={`${this.get('communal.vehicles')}%`}
                                                                   position="center" fill="#fff"/>
                                                        </Pie>
                                                    </PieChart>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-7">
                                            <div className="tiles__row tar">
                                                <div className="tiles__col" style={{fontSize:`${this.state.fontSize}px`, lineHeight: `${this.state.lineHeight}px`}}>Оснащенность ГЛОНАСС</div>
                                                <div className="tiles__col" style={{fontSize:`${this.state.fontSize}px`, lineHeight: `${this.state.lineHeight}px`}}>{this.get('communal.vehicles_bnso')}%</div>
                                                <div className="tiles__h-progress-bar">
                                                    <div className="tiles__h-progress-bar-fill"
                                                         style={{width: this.get('communal.vehicles_bnso', false) + '%'}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-4">
                            <div className="tiles__item tiles__item_color5"
                                 onClick={this.goto.bind(this, '/tablet/garbage/')}>
                                <div className="tiles__header"><Link className="tiles__title" to="/tablet/garbage/">Вывоз мусора
                                    <svg className="icon icon_repair_road icon_white">
                                        <use xlinkHref="/img/spriteSVG.svg#icon_trash"/>
                                    </svg>
                                    <svg className="icon icon_arrow_right icon_white">
                                        <use xlinkHref="/img/spriteSVG.svg#icon_arrow_right"/>
                                    </svg>
                                </Link></div>
                                <div className="tiles__content">
                                    <div className="row">
                                        <div className="col col-5">
                                            <div className="tiles__row tiles__row_bdn">
                                                <div className="tiles__col">ТС в работе</div>
                                                <div className="tiles__r-progress-bar">
                                                    <PieChart width={103} height={103}>
                                                        <Pie data={[
                                                            {
                                                                value: this.get('garbage.vehicles', false),
                                                            },
                                                            {
                                                                value: 100 - this.get('garbage.vehicles', false),
                                                                fill: 'rgba(255, 255, 255, 0.5)',
                                                            }
                                                        ]}
                                                             nameKey="name"
                                                             dataKey="value"
                                                             cx="50%"
                                                             cy="50%"
                                                             outerRadius={50}
                                                             innerRadius={43}
                                                             startAngle={90}
                                                             endAngle={-270}
                                                             fill="#fff"
                                                             stroke="none">
                                                            <Label value={`${this.get('garbage.vehicles')}%`}
                                                                   position="center" fill="#fff"/>
                                                        </Pie>
                                                    </PieChart>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-7">
                                            <div className="tiles__row tar">
                                                <div className="tiles__col" style={{fontSize:`${this.state.fontSize}px`, lineHeight: `${this.state.lineHeight}px`}}>Оснащенность ГЛОНАСС</div>
                                                <div className="tiles__col" style={{fontSize:`${this.state.fontSize}px`, lineHeight: `${this.state.lineHeight}px`}}>{this.get('garbage.vehicles_bnso')}%</div>
                                                <div className="tiles__h-progress-bar">
                                                    <div className="tiles__h-progress-bar-fill"
                                                         style={{width: this.get('garbage.vehicles_bnso', false) + '%'}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-4">
                            <div className="tiles__item tiles__item_color6"
                                 onClick={this.goto.bind(this, '/tablet/gatn/')}>
                                <div className="tiles__header"><Link className="tiles__title" to="/tablet/gatn/">{window.RNIS_SETTINGS.GUGATNTITLE ? window.RNIS_SETTINGS.GUGATNTITLE : 'ГУ ГАТН'}
                                    <svg className="icon icon_repair_road icon_white">
                                        <use xlinkHref="/img/spriteSVG.svg#icon_analitics"/>
                                    </svg>
                                    <svg className="icon icon_arrow_right icon_white">
                                        <use xlinkHref="/img/spriteSVG.svg#icon_arrow_right"/>
                                    </svg>
                                </Link></div>
                                <div className="tiles__content">
                                    <div className="row">
                                        <div className="col col-5">
                                            <div className="tiles__row tiles__row_bdn">
                                                <div className="tiles__col">ТС в работе</div>
                                                <div className="tiles__r-progress-bar">
                                                    <PieChart width={103} height={103}>
                                                        <Pie data={[
                                                            {
                                                                value: this.get('gatn.vehicles', false),
                                                            },
                                                            {
                                                                value: 100 - this.get('gatn.vehicles', false),
                                                                fill: 'rgba(255, 255, 255, 0.5)',
                                                            }
                                                        ]}
                                                             nameKey="name"
                                                             dataKey="value"
                                                             cx="50%"
                                                             cy="50%"
                                                             outerRadius={50}
                                                             innerRadius={43}
                                                             startAngle={90}
                                                             endAngle={-270}
                                                             fill="#fff"
                                                             stroke="none">
                                                            <Label value={`${this.get('gatn.vehicles')}%`}
                                                                   position="center" fill="#fff"/>
                                                        </Pie>
                                                    </PieChart>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-7">
                                            <div className="tiles__row tar">
                                                <div className="tiles__col" style={{fontSize:`${this.state.fontSize}px`, lineHeight: `${this.state.lineHeight}px`}}>Оснащенность ГЛОНАСС</div>
                                                <div className="tiles__col" style={{fontSize:`${this.state.fontSize}px`, lineHeight: `${this.state.lineHeight}px`}}>{this.get('gatn.vehicles_bnso')}%</div>
                                                <div className="tiles__h-progress-bar">
                                                    <div className="tiles__h-progress-bar-fill"
                                                         style={{width: this.get('gatn.vehicles_bnso', false) + '%'}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </TabletPage>
        );
    }

    goto(href) {
        this.props.router.push(href);
    }

    getHeaderTopRight() {
        return (
            <ul className="header-top__icons header-top__icons_tar">
                <li className="header-top__icons-item">
                    <div className="search">
                        <form className="search__form">
                            <input className="search__input" type="text" placeholder="поиск"/>
                            <button className="search__submit" type="submit">
                                <svg className="icon icon_search icon_red">
                                    <use xlinkHref="/img/spriteSVG.svg#icon_search"/>
                                </svg>
                            </button>
                        </form>
                    </div>
                    <a className="header-top__icons-link header-top__icons-link_none-bg header-top__icons-link_search">
                        <svg className="icon icon_search icon_red">
                            <use xlinkHref="/img/spriteSVG.svg#icon_search"/>
                        </svg>
                    </a>
                </li>
            </ul>
        );
    }

    getHeaderBottom() {
        const from = moment(this.state.displayed_from);
        const to = moment(this.state.displayed_to);

        return (
            <div className="row">
                <div className="header-bottom__left col col-6">
                    <div className="title-text">
                        <div className="title-text__value">Текущий период</div>
                        <div className="title-text__dscn">{this.formatDate(from)} - {this.formatDate(to)}</div>
                    </div>
                </div>
                <div className="header-bottom__right col col-6">
                    <div className="row">
                        <div className="col col-4">
                            <Datepicker
                                value={this.state.from}
                                onChange={this.onChange.bind(this, 'from')}
                                readOnly={true}
                            />
                        </div>
                        <div className="col col-4">
                            <Datepicker
                                value={this.state.to}
                                onChange={this.onChange.bind(this, 'to')}
                                readOnly={true}
                            />
                        </div>
                        <div className="col col-4">
                            <button className="btn btn_form btn_colored" onClick={::this.reload}>Сформировать</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onChange(field, {target: {value}}) {
        let state = this.state;
        state[field] = value;
        this.setState(state);

        /*if (field === 'from' || field === 'to') {
            storage.save(`tablet-${field}`, value);
        }*/
    }

    async reload() {
        TabletTimeInstance.update(this.state.from, this.state.to);
        await this.setState({
            displayed_from: this.state.from,
            displayed_to: this.state.to,
        });

        this._cycleFetch.forceNext();
    }

    formatDate(date) {
        const months = {
            0: 'января',
            1: 'февраля',
            2: 'марта',
            3: 'апреля',
            4: 'мая',
            5: 'июня',
            6: 'июля',
            7: 'августа',
            8: 'сентября',
            9: 'октября',
            10: 'ноября',
            11: 'декабря',
        };
        return `${date.date()} ${months[date.month()]} ${date.year()}`;
    }

    async loadData() {
        this.setState({
            data: {},
        });

        if (moment(this.state.from).isBefore(moment().subtract(1, 'year'))) {
            _.each(this.fields, async (field) => {
                const response = await this.props.getTabletSummary(this.state.from, this.state.to, field);

                if (response.isOk) {
                    let data = this.state.data;
                    _.set(data, field, _.get(response.payload, field));
                    this.setState({data});
                }
            });
        } else {
            const response = await this.props.getTabletSummary(this.state.from, this.state.to);

            if (response.isOk) {
                this.setState({
                    data: response.payload,
                });
            } else {
                response.showErrors();
            }
        }
    }

    get(field, forDisplay = true) {
        const value = _.get(this.state.data, field, null);
        if (forDisplay) {
            if (value !== null) {
                return _.round(value, 0);
            } else {
                return '-';
            }
        }
        return value || 0;
    }

}