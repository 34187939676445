import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getWorkGroups = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.work_groups.getWorkGroups(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};


export const saveWorkGroup = (data) => async (dispatch) => {
    try {
        const response = await api.work_groups.saveWorkGroup(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
