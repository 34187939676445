import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import _ from 'lodash';
import PageModal from 'components/ui/page-modal';
import { connect } from 'react-redux';
import GlobalLoaderComponent from 'components/ui/global-loader';
import * as alerts from 'helpers/alerts';
import { uploadFile, getUploadToken } from 'helpers/api/storage';
import BaseEditorFormComponent from 'components/base/base-editor-form';
import Block from 'components/ui/form/block';
import { getDictionaryList } from 'store/reducers/dictionaries/dictionary';
import Accordion from 'components/ui/accordion/accordion';
import AccordionItem from 'components/ui/accordion/accordion-item';
import BaseEditor from 'components/base/base-editor';
import { getUnit, getUnits } from 'store/reducers/organizational_units/units';
import moment from 'moment';
import formats from 'dictionaries/formats';
import systems from 'dictionaries/systems';
import Button from 'components/ui/button';
import ContextTooltip from 'components/ui/context-tooltip';
import ModalTopMenuListItem from 'components/ui/modal/modal-top-menu-list-item';
import {
	getConfirmRequest,
	getConfirmRequests,
	updateConfirmRequest,
	createConfirmRequest
} from 'store/reducers/portal/confirm_requests';
import RegisterRequestHistory from 'components/modules/operator/confirm_requests/history';
import ConfirmRequestHistory from 'components/modules/operator/confirm_requests/history';
import { getUser } from 'store/reducers/staffing/staffing_editor';
import { User } from 'helpers/user';
import ConfirmRequestVehicles from 'components/modules/operator/confirm_requests/vehicles';
import { getRegisterRequest, getRegisterRequests } from 'store/reducers/portal/register_requests';
import { resizeModals } from 'helpers/modal-size';
import { events } from 'dom-helpers';
import { getUsers } from 'store/reducers/staffing/staffing';
import { debounce } from 'throttle-debounce';
import FileReaderInput from 'react-file-reader-input';
import { setConfirmRequest } from '../../../../store/reducers/kurs/actions';
import { getConfirmRequestSelector } from '../../../../store/reducers/kurs/selectors';
import currentUser from '../../../../helpers/current-user';

let resizeId;

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	uuid: PropTypes.string
})
@connect(
	(state) => ({
		confirmRequest: getConfirmRequestSelector(state)
	}),
	{ getConfirmRequest, updateConfirmRequest, getUnits, createConfirmRequest }
)
export default class Editor extends BaseEditor {
	modalClassName = 'b-modal-register-requests';

	static childContextTypes = {
		resizeModals: PropTypes.func
	};

	constructor(props, context) {
		super(props, context);

		Object.assign(this.state, {
			historyActive: false,
			vehiclesActive: false
		});

		this.onResize = this.onResize.bind(this);
	}

	getChildContext() {
		return { resizeModals: this.resizeModals };
	}

	componentWillMount() {
		events.on(window, 'resize', this.onResize);
	}

	async componentWillReceiveProps(props) {
		await super.componentWillReceiveProps(props);

		setTimeout(() => {
			this.resizeModals();
		}, 500);
	}

	componentWillUnmount() {
		events.off(window, 'resize', this.onResize);
	}

	onResize() {
		clearTimeout(resizeId);
		resizeId = setTimeout(this.resizeModals, 1000);
	}

	resizeModals() {
		const ignoreClasses = [ '' ];
		resizeModals('.b-modal', 'b-modal__collapse', 'move-right', ignoreClasses);
	}

	resize() {
		this.resizeModals();
	}

	async createItem() {
		if (window.RNIS_SETTINGS.CITY_TULA) {
			return await this.props.createConfirmRequest(this.props.confirmRequest);
		}
		return null;
	}

	getFullTitle() {
		return `Заявление №${_.get(this.state.item, 'number', '...')}`;
	}

	async loadData(uuid) {
		return await this.props.getConfirmRequest(uuid);
	}

	async updateItem(data) {
		data.source = 'operator';
		data.status = 'accepted';
		return await this.props.updateConfirmRequest(data);
	}

	getForm(item, onSubmit) {
		return (
			<EditorForm
				{...this.props}
				ref="form"
				mode={this.props.mode}
				onSubmit={onSubmit}
				onClose={::this.props.onClose}
				data={item}
				errors={this.state.errors}
				canUpdate={
					(this.state.item && this.state.item.status === 'sent_first') ||
					(this.state.item && this.state.item.status === 'sent')
				}
			/>
		);
	}

	showHistory() {
		this.hideVehicles();
		this.setState({
			historyActive: true
		});
	}

	hideHistory() {
		this.setState({
			historyActive: false
		});
	}

	async showVehicles() {
		this.hideHistory();
		await this.setState({
			vehiclesActive: true
		});
		this.resize();
	}

	async hideVehicles() {
		await this.setState({
			vehiclesActive: false
		});
		this.resize();
	}

	renderModals() {
		if (this.state.historyActive) {
			return <ConfirmRequestHistory request={this.state.item} onClose={::this.hideHistory} />;
		}
		if (this.state.vehiclesActive) {
			return <ConfirmRequestVehicles request={this.state.item} onClose={::this.hideVehicles} />;
		}
	}

	renderHeaderBtns(mode) {
		const canUpdate =
			(this.state.item && this.state.item.status === 'sent_first') ||
			(this.state.item && this.state.item.status === 'sent');

		return [
			<ContextTooltip key="confirm_requests.history" code="confirm_requests.history" default="История обработки">
				<ModalTopMenuListItem className="b-icon-link_icon_book" onClick={::this.showHistory} />
			</ContextTooltip>,
			<ContextTooltip key="confirm_requests.vehicles" code="confirm_requests.vehicles" default="Заявленный автопарк">
				<ModalTopMenuListItem className="b-icon-link_icon_ts" onClick={::this.showVehicles} />
			</ContextTooltip>,
			canUpdate ? (
				<ContextTooltip key="confirm_requests.return" code="confirm_requests.return" default="Вернуть с замечаниями">
					<ModalTopMenuListItem className="b-icon-link_icon_comments-return" onClick={::this.returnRequest} />
				</ContextTooltip>
			) : null,
			canUpdate ? (
				<ContextTooltip
					key="confirm_requests.confirm"
					code="confirm_requests.confirm"
					default="Одобрить заявление на подключение"
				>
					<ModalTopMenuListItem className="b-icon-link_icon_accept-registration" onClick={::this.confirmRequest} />
				</ContextTooltip>
			) : null
		];
	}

	returnRequest() {
		if (!this.refs.form) return;

		let state = this.refs.form.getWrappedInstance().getData();
		state.status = 'returned';
		this.edit(state);
	}

	confirmRequest() {
		if (!this.refs.form) return;

		let state = this.refs.form.getWrappedInstance().getData();
		state.status = 'confirmed';
		this.edit(state);
	}
}

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	data: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	errors: PropTypes.object
})
@connect(
	(state) => ({
		confirmRequest: getConfirmRequestSelector(state)
	}),
	{
		getDictionaryList,
		getUnit,
		getRegisterRequests,
		getUser,
		getUsers,
		getConfirmRequests,
		getUnits,
		setConfirmRequest
	},
	null,
	{ withRef: true }
)
class EditorForm extends BaseEditorFormComponent {
	state = {
		confirm_request: {},
		unit: {},
		register_requests: [],
		user: {},
		request_documents: [],
		users: [],
		max_user_length: 1
	};

	systems = {
		kiutr: 'Пассажирские перевозки',
		road: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '161' ]).text,
		utility: 'Жилищно-коммунальное хозяйство',
		communal: 'Коммунальные работы',
		children: 'Перевозка детей',
		garbage: 'Вывоз мусора'
	};

	getData() {
		return this.state.confirm_request;
	}

	async componentDidMount() {
		await this.setState({
			confirm_request: this.props.data
		});

		this.loadUser();
		this.loadDictionaries([ 'request_documents' ]);
		this.loadConfirmRequests();

		if (this.props.mode === 'add') {
			this.props.setConfirmRequest({
				...this.props.confirmRequest,
				user_uuid: currentUser.user.uuid,
				status: 'accepted'
			});
		}

		if (this.props.mode === 'edit') {
			this.loadUnit();
			this.loadUsers();
		}
	}

	async loadConfirmRequests() {
		const response = await this.props.getConfirmRequests({
			filters: {
				withUnit: this.get('unit_uuid')
			}
		});

		if (response.isOk) {
			this.setState({
				max_user_length: Math.max(
					1,
					_.toInteger(
						_.max(
							_.map(_.filter(response.payload.items, { status: 'confirmed' }), (item) => {
								return Math.max(1, _.toInteger(item.users_count));
							})
						)
					)
				)
			});
		} else {
			response.showErrors();
		}
	}

	async loadUnit(value) {
		const response = await this.props.getUnit(value || this.get('unit_uuid'));

		if (response.isOk) {
			const unit = response.payload;
			this.setState({
				unit
			});
			this.loadRegisterRequests(unit.uuid);
		} else {
			response.showErrors();
		}
	}

	async loadUsers() {
		const response = await this.props.getUsers({
			pagination: {
				page: 1,
				limit: 1000
			},
			filters: {
				withUnits: this.get('unit_uuid'),
				notVirtual: true
			}
		});

		if (response.isOk) {
			this.setState({
				users: response.payload.items
			});
		} else {
			response.showErrors();
		}
	}

	async loadRegisterRequests(unitUuid) {
		const response = await this.props.getRegisterRequests({
			filters: {
				withUnit: unitUuid
			}
		});
		if (response.isOk) {
			this.setState({
				register_requests: response.payload.items
			});
		} else {
			response.showErrors();
		}
	}

	loadUnitsDebounce = debounce(500, ::this.loadUnits);

	async loadUnits(input, callback) {
		const response = await this.props.getUnits({
			pagination: {
				page: 1,
				limit: 1000
			},
			search: input ? input : undefined,
			...(this.state.withComponent ? { withComponent: this.state.withComponent } : {})
		});

		if (response.isOk) {
			callback(null, {
				options: _.sortBy(
					response.payload.items.map((i) => {
						let newItem = {
							label: i.name,
							value: i.uuid,
							fullName: i.name_full
						};

						if (i.workday_begin) {
							newItem['workday_begin'] = i.workday_begin;
						}
						if (i.workday_finish) {
							newItem['workday_finish'] = i.workday_finish;
						}

						return newItem;
					}),
					'label'
				),
				complete: false
			});
		} else {
			response.showErrors();
		}
	}

	onСompanyChange = (e) => {
		const value = e ? e.value : null;
		this.loadUnit(value);
		this.props.setConfirmRequest({
			...this.props.confirmRequest,
			unit_uuid: value
		});
		this.setState({ unit_uuid: value });
	};

	async loadUser() {
		const response = await this.props.getUser(this.get('user_uuid'));

		if (response.isOk) {
			this.setState({
				user: response.payload
			});
		} else {
			response.showErrors();
		}
	}

	get(path, defaultValue = null) {
		return _.get(this.state.confirm_request, path, defaultValue);
	}

	async getUploadTokenRequest() {
		try {
			const response = await getUploadToken();
			return response.payload;
		} catch (e) {
			console.log('Ошибка получения токена загрузки', e);
		}
	}

	uploadDocument = async (e, results) => {
		e.preventDefault();

		const tokenInfo = await this.getUploadTokenRequest();

		let formData = new FormData();

		results.forEach((result) => {
			const [ e, file ] = result;
			formData.append('file', file);
		});

		formData.append('token', tokenInfo.token);

		const response = await uploadFile(tokenInfo.upload_url, formData);
		const value = response.url.replace('http://', 'https://');

		this.props.setConfirmRequest({
			...this.props.confirmRequest,
			document_url: value
		});
	};

	removeDocument = () => {
		this.props.setConfirmRequest({ ...this.props.confirmRequest, document_url: '' });
	};

	render() {
		return (
			<div>
				<Accordion>
					<AccordionItem opened={true} title="Организация-заявитель">
						<Block size="xl" title="Наименование">
							{this.props.mode === 'add' ? (
								this.selectAsync('unit_uuid', this.state.unit ? ::this.loadUnits : ::this.loadUnitsDebounce, {
									onChange: this.onСompanyChange
								})
							) : (
								this.state.unit.name
							)}
						</Block>
						<Block size="xl" title="Адрес фактический">
							{this.state.unit.actual_address}
						</Block>
						<Block title="ИНН">{this.state.unit.inn}</Block>
						<Block title="Руководитель">{this.state.unit.senior}</Block>
						<Block title="Телефон">{this.state.unit.phone}</Block>
						<Block title="Email">{this.state.unit.email}</Block>
						{!window.RNIS_SETTINGS.CITY_TULA && (
							<Block title="Запрашиваемый лимит учетных записей">
								{this.state.confirm_request.users_count} (действует {this.state.users.length}/{this.state.max_user_length})
							</Block>
						)}
					</AccordionItem>
					<AccordionItem opened={true} title="Авторизованное лицо">
						<Block size="xl" title="ФИО">
							{!_.isEmpty(this.state.user) ? new User(this.state.user).getFullName(true) : null}
						</Block>
						<Block title="Телефон">{_.get(this.state.user, 'info.phone')}</Block>
						<Block title="Email">{_.get(this.state.user, 'info.email')}</Block>
					</AccordionItem>
					<AccordionItem opened={true} title="Профильные документы">
						{this.props.mode === 'add' ? !this.props.confirmRequest.document_url ? (
							<div className="file-load">
								<FileReaderInput as="binary" id="my-file-input" onChange={this.uploadDocument}>
									<a href="javascript:void(0)">Загрузить заявление</a>
								</FileReaderInput>
							</div>
						) : (
							<div className="file-load">
								<a href="javascript:void(0)" onClick={this.removeDocument}>
									Удалить заявление
								</a>
								<a href={this.props.confirmRequest.document_url} target="_blank">
									{`  Посмотреть заявление`}
								</a>
							</div>
						) : (
							<div>
								<Block size="xl" title="Заявленные области деятельности">
									{this.getFromRegisterRequest('components', []).map((component) => this.systems[component]).join(', ')}
								</Block>
								<Block size="xl" title="Документы">
									<div className="files">{this.getFromRegisterRequest('documents', []).map(::this.renderDocument)}</div>
								</Block>
							</div>
						)}
					</AccordionItem>
					{this.props.mode !== 'add' && (
						<AccordionItem opened={true} title="Комментарий">
							<Block size="xl">
								{this.props.canUpdate ? this.textarea('confirm_request.status_comment') : this.get('status_comment')}
							</Block>
						</AccordionItem>
					)}
				</Accordion>
			</div>
		);
	}

	getFromRegisterRequest(fieldName, defaultValue) {
		return _.uniq(
			_.flatten(
				_.map(this.state.register_requests, (register_request) => {
					return _.get(register_request, fieldName, defaultValue);
				})
			)
		);
	}

	renderDocument(document, index) {
		if (document.valid_to && moment(document.valid_to).isBefore()) {
			return null;
		}

		return (
			<div key={index} className="files__item">
				<div className="files__info">
					<div>{document.filename}</div>
				</div>
				<div className="files__info">
					<div>
						Тип:{' '}
						<span>
							{_.get(_.find(this.state.request_documents, { value: document.request_document_uuid }), 'label')}
						</span>
					</div>
					<div>
						Дата оформления: <span>{moment(document.issued_at).format(formats.DATE)}</span>
					</div>
					<div>
						Дата истечения действия:{' '}
						<span>{document.valid_to ? moment(document.valid_to).format(formats.DATE) : 'бессрочно'}</span>
					</div>
				</div>
				<div className="files-menu">
					<a className="files-menu__item download" href={document.url} target="_blank">
						Скачать файл
					</a>
				</div>
			</div>
		);
	}
}
