import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import PageModal from 'components/ui/page-modal';

import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import * as alerts from "helpers/alerts";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import {getRegisterRequest, updateRegisterRequest} from "store/reducers/portal/register_requests";
import moment from "moment";
import formats from "dictionaries/formats";
import systems from "dictionaries/systems";
import Button from "components/ui/button";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import RegisterRequestHistory from "components/modules/operator/register_requests/history";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getRegisterRequest, updateRegisterRequest})

export default class Editor extends BaseEditor {

    modalClassName = 'b-modal-register-requests';

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            historyActive: false,
        });
    }

    saveButton(onClick) {
        return null;
    }

    getFullTitle() {
        return `Обращение №${_.get(this.state.item, 'number', '...')}`;
    }

    async loadData(uuid) {
        return await this.props.getRegisterRequest(uuid);
    }

    async updateItem(data) {
        return await this.props.updateRegisterRequest(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                canUpdate={(this.state.item.status === 'sent_first') || (this.state.item.status === 'sent')}
            />
        );
    }

    showHistory() {
        this.setState({
            historyActive: true,
        });
    }

    hideHistory() {
        this.setState({
            historyActive: false,
        });
    }

    renderModals() {
        if (this.state.historyActive) {
            return (
                <RegisterRequestHistory
                    request={this.state.item}
                    onClose={::this.hideHistory}
                />
            )
        }
    }

    renderHeaderBtns(mode) {
        const canUpdate = (this.state.item.status === 'sent_first') || (this.state.item.status === 'sent');

        return ([
            <ContextTooltip key="register_requests.history" code="register_requests.history"
                            default="История обработки">
                <ModalTopMenuListItem
                    className="b-icon-link_icon_book"
                    onClick={::this.showHistory}
                />
            </ContextTooltip>,
            canUpdate ? (
                <ContextTooltip key="register_requests.return" code="register_requests.return"
                                default="Вернуть с замечаниями">
                    <ModalTopMenuListItem
                        className="b-icon-link_icon_comments-return"
                        onClick={::this.returnComplect}
                    />
                </ContextTooltip>
            ) : null,
            canUpdate ? (
                <ContextTooltip key="register_requests.confirm" code="register_requests.confirm"
                                default="Одобрить заявление на регистрацию">
                    <ModalTopMenuListItem
                        className="b-icon-link_icon_accept-registration"
                        onClick={::this.confirmComplect}
                    />
                </ContextTooltip>
            ) : null,
        ]);
    }

    returnComplect() {
        if (!this.refs.form) return;

        let state = this.refs.form.getWrappedInstance().getData();
        state.status = 'returned';
        this.edit(state);
    }

    confirmComplect() {
        if (!this.refs.form) return;
        let state = this.refs.form.getWrappedInstance().getData();
        state.status = 'confirmed';
        state.is_notificated = false;
        this.edit(state);
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getDictionaryList, getUnit, getEntityNames}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        register_request: {},
        request_documents: [],
        unit: null,
        related: new EntityList,
    };

    systems = {
        kiutr: 'Пассажирские перевозки',
        road: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text,
        utility: 'Жилищно-коммунальное хозяйство',
        communal: 'Коммунальные работы',
        children: 'Перевозка детей',
        garbage: 'Вывоз мусора',
    };

    getData() {
        return this.state.register_request;
    }

    async componentDidMount() {
        await this.setState({
            register_request: this.props.data,
        });

        this.loadDictionaries([
            'request_documents',
        ]);
        this.loadUnit();
        this.loadUser();
    }

    async loadUser() {
        const user = {
            class: 'App\\Model\\UserInfo',
            uuid: this.get('user_uuid'),
            source: 'auth',
        };

        const response = await this.props.getEntityNames([user]);

        if (response.isOk) {
            this.setState({
                related: new EntityList(response),
            });
        }
    }

    async loadUnit() {
        const uuid = this.get('unit_uuid');
        if (!uuid) {
            return;
        }

        const response = await this.props.getUnit(uuid);
        if (response.isOk) {
            this.setState({
                unit: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.register_request, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Accordion>
                    {this.get('certificate.serial') ? (
                        <AccordionItem opened={true} title="Сведения об авторе обращения">
                            <Block size="xl" title="Сертификат ЭЦП">
                                {this.get('certificate.serial')}
                            </Block>
                            <Block title="Действителен до">
                                {moment(this.get('certificate.valid_to')).format(formats.DATE)}
                            </Block>
                            <Block title="Выдан">
                                {this.get('certificate.issuer')}
                            </Block>
                            <Block title="Владелец">
                                {this.get('certificate.owner')}
                            </Block>
                        </AccordionItem>
                    ) : (
                        <AccordionItem opened={true} title="Сведения об авторе обращения">
                            <Block size="xl" title="ФИО">
                                {this.state.related.getReact(this.get('user_uuid'))}
                            </Block>
                        </AccordionItem>
                    )}
                    {this.state.unit ? (
                        <AccordionItem opened={true} title="Организация">
                            <Block title="Наименование">
                                {_.get(this.state.unit, 'name')}
                            </Block>
                            <Block title="ИНН">
                                {_.get(this.state.unit, 'inn')}
                            </Block>
                            <Block title="ОГРН">
                                {_.get(this.state.unit, 'ogrn')}
                            </Block>
                            <Block title="Адрес регистрации">
                                {_.get(this.state.unit, 'legal_address')}
                            </Block>
                        </AccordionItem>
                    ) : (
                        <AccordionItem opened={true} title="Организация">
                            <Block title="Наименование">
                                {this.get('certificate.name')}
                            </Block>
                            <Block title="ИНН">
                                {this.get('certificate.inn')}
                            </Block>
                            <Block title="ОГРН">
                                {this.get('certificate.ogrn')}
                            </Block>
                            <Block title="Адрес регистрации">
                                {this.get('certificate.address')}
                            </Block>
                            <Block title="Должность">
                                {this.get('certificate.position')}
                            </Block>
                        </AccordionItem>
                    )}
                    <AccordionItem opened={true} title="Профильные документы">
                        <Block size="xl" title="Заявленные области деятельности">
                            {this.get('components', []).map(component => this.systems[component]).join(', ')}
                        </Block>
                        <Block size="xl" title="Документы">
                            <div className="files">
                                {this.get('documents', []).map(::this.renderDocument)}
                            </div>
                        </Block>
                    </AccordionItem>
                    <AccordionItem opened={true} title="Комментарий">
                        <Block size="xl">
                            {this.props.canUpdate ? this.textarea('register_request.status_comment') : this.get('status_comment')}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    renderDocument(document, index) {
        return (
            <div key={index} className="files__item">
                <div className="files__info">
                    <div>{document.filename}</div>
                </div>
                <div className="files__info">
                    <div>Тип:
                        <span>{_.get(_.find(this.state.request_documents, {value: document.request_document_uuid}), 'label')}</span>
                    </div>
                    <div>Дата оформления: <span>{moment(document.issued_at).format(formats.DATE)}</span></div>
                    <div>Дата истечения действия: <span>{document.valid_to ? moment(document.valid_to).format(formats.DATE) : 'бессрочно'}</span></div>
                </div>
                <div className="files-menu">
                    <a className="files-menu__item download" href={document.url} target="_blank">Скачать файл</a>
                </div>
            </div>
        );
    }
}