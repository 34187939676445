import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import PageModal from 'components/ui/page-modal';
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import currentUser from 'helpers/current-user';

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import classNames from 'classnames';

import {
    createRouteRegistry, getRouteRegistry, getRouteRegistryFact,
    updateRouteRegistry
} from "store/reducers/kiutr/route_registries";
import GlobalLoaderComponent from "components/ui/global-loader";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import TableContainer from "components/ui/Table/Container/TableContainer";
import ContextTooltip from "components/ui/context-tooltip";
import {resizeModals} from 'helpers/modal-size';
import {events} from 'dom-helpers';
import debounce from 'throttle-debounce/debounce';
import {getRoutes} from "store/reducers/routes/route_editor";
import {getSchedules} from "store/reducers/kiutr/schedules/schedules";
import moment from "moment";
import formats from "dictionaries/formats";
import IconButton from "components/ui/icon-button";
import Popup from "components/ui/popup";
import Button from "components/ui/button";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import {EntityList} from "helpers/entity";
import {getContract} from "store/reducers/kiutr/contracts/contracts";
import {component_mapper} from "helpers/component_mapper";
import months from "../../../../../dictionaries/months";
import {getRouteVariant} from "store/reducers/routes/route_variants";
import {prepareDateForPrint} from "../../../../../helpers/print_official";
import { render } from 'less';

let resizeId;

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
    contractUuid: PropTypes.string,
    isPageWithDetect: PropTypes.bool,
})

@connect(state => ({}), {getRouteRegistry, createRouteRegistry, updateRouteRegistry})

export default class RouteRegistrySummary extends BaseEditor {

    title = 'маршрута';
    modelClass = 'App\\Model\\RouteRegistry';
    modalClassName = 'b-modal-contract contract-route-param-modal';
    withFade = false;

    static childContextTypes = {
        resizeModals: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.onResize = this.onResize.bind(this);
    }

    getChildContext() {
        return {resizeModals: this.resizeModals};
    }

    componentWillMount() {
        events.on(window, 'resize', this.onResize);
    }

    componentDidUpdate() {
        setTimeout(() => {
            this.resizeModals();
        }, 500);
    }

    componentWillUnmount() {
        events.off(window, 'resize', this.onResize);

        if(this.props.isPageWithDetect && this.modalConfirmation) {
            document.removeEventListener('click', this.handleClickOutside)
        }
    }

    onResize() {
        clearTimeout(resizeId);
        resizeId = setTimeout(this.resizeModals, 1000);
    }

    resizeModals() {
        const ignoreClasses = ['route-registry-editor'];
        resizeModals('.b-modal', 'b-modal__collapse', 'move-right', ignoreClasses);
    }

    async loadData(uuid) {
        return await this.props.getRouteRegistry(uuid);
    }

    async createItem(data) {
        let cloneData = _.cloneDeep(data)

        // NOTE: BE хочет массив, но нам удобнее работать с мапой

        if(window.RNIS_SETTINGS.CITY_TULA && cloneData.vehicles_requirements) {
            cloneData.vehicles_requirements = _.map(Object.values(cloneData.vehicles_requirements), (el) => {
                if(el.maximum_age && !el.minimum_capacity) {
                    el.minimum_capacity = 0;
                }
                if(el.minimum_capacity && !el.maximum_age) {
                    el.maximum_age = 0;
                }
                return el
            });
        } else {
            cloneData.vehicles_requirements = [];
        }
        return await this.props.createRouteRegistry(cloneData);
    }

    async updateItem(data) {

        let cloneData = _.cloneDeep(data)
        if(window.RNIS_SETTINGS.CITY_TULA && cloneData.vehicles_requirements) {
            cloneData.vehicles_requirements = _.map(Object.values(cloneData.vehicles_requirements), (el) => {
                if(el.maximum_age && !el.minimum_capacity) {
                    el.minimum_capacity = 0;
                }
                if(el.minimum_capacity && !el.maximum_age) {
                    el.maximum_age = 0;
                }
                return el
            });
        } else {
            cloneData.vehicles_requirements = [];
        }

        return await this.props.updateRouteRegistry(cloneData);
    }

    async componentDidMount() {
        this.setState({
            uuid: this.props.uuid,
            item: null,
        });
        if (this.props.uuid) {
            this.setState({isLoading: true});
            const response = await this.loadData(this.props.uuid);
            if (response.isOk) {

                if(response.payload.vehicles_requirements
                    && response.payload.vehicles_requirements.length
                    && window.RNIS_SETTINGS.CITY_TULA) {
                        let obj = {}
                        _.map(response.payload.vehicles_requirements, (el) => {
                            obj[el.vehicle_capacity_type_uuid] = {...el}
                        })
                        response.payload.vehicles_requirements = obj
                } else {
                    response.payload.vehicles_requirements = {}
                }

                this.setState({
                    item: response.payload,
                    isLoading: false,
                });
            } else {
                response.showErrors();
            }
        }

        if(this.props.isPageWithDetect && this.modalConfirmation) {
            document.addEventListener('click', this.handleClickOutside, true)
        }
    }

    getForm(item, onSubmit) {
        item.contract_uuid = this.props.contractUuid;
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                turnedEditingMode={this.turnedEditingMode}
            />
        );
    }

    composeItem(data) {
        let result = _.clone(data);

        result.vehicles_plan = [];

        _.each(data.vehicles_plan_flat, (plan, seasonUuid) => {
            _.each(plan, (plan2, capacityTypeUuid) => {
                _.each(plan2, (value, scheduleTypeUuid) => {
                    result.vehicles_plan.push({
                        season_uuid: seasonUuid,
                        route_schedule_type_uuid: scheduleTypeUuid,
                        vehicle_capacity_type_uuid: capacityTypeUuid,
                        value,
                        fact: _.get(data.vehicles_fact_flat, `${seasonUuid}.${capacityTypeUuid}.${scheduleTypeUuid}`),
                    });
                });
            });
        });

        _.each(data.vehicles_fact_flat, (fact, seasonUuid) => {
            _.each(fact, (fact2, capacityTypeUuid) => {
                _.each(fact2, (fact, scheduleTypeUuid) => {
                    if (_.filter(result.vehicles_plan, {
                        season_uuid: seasonUuid,
                        route_schedule_type_uuid: scheduleTypeUuid,
                        vehicle_capacity_type_uuid: capacityTypeUuid,
                    }).length === 0) {
                        result.vehicles_plan.push({
                            season_uuid: seasonUuid,
                            route_schedule_type_uuid: scheduleTypeUuid,
                            vehicle_capacity_type_uuid: capacityTypeUuid,
                            value: _.get(data.vehicles_plan_flat, `${seasonUuid}.${capacityTypeUuid}.${scheduleTypeUuid}`),
                            fact,
                        });
                    }
                });
            });
        });

        return result;
    }

    checkForOfficialPrint() {
        return window.RNIS_SETTINGS.OFFICIALPRINT && this.state.item.contract.type === 'license' && moment(this.state.item.contract.date_to) > moment();
    }


    render() {
        let title = `Добавление ${this.title}`;

        let form;
        let buttons;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {
            title = `Редактирование ${this.title}`;

            if (this.state.item) {
                form = this.getForm(this.state.item, ::this.edit);

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            {currentUser.can('com.rnis.system.permission.audit', 'read') ? (
                                <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                                default="Журнал аудита">
                                    <ModalTopMenuListItem
                                        className="b-icon-link_params b-icon-link_icon_history"
                                        href={`/system/audit/${this.props.uuid}?class=${this.modelClass}`}
                                    />
                                </ContextTooltip>
                            ) : null}
                            {this.checkForOfficialPrint() ? (
                                <ContextTooltip key="base-editor.print.official" code="base-editor.print.official"
                                                default="Печать на бланке">
                                    <ModalTopMenuListItem
                                        key="print_official"
                                        className="b-icon-link_icon_print"
                                        title="Печать на бланке"
                                        onClick={::this.printOfficial}
                                    />
                                </ContextTooltip>
                            ) : null}


                            <ContextTooltip key="base-editor.print" code="base-editor.print" default="Печать">
                                <ModalTopMenuListItem
                                    key="print"
                                    className="b-icon-link_icon_print"
                                    title="Печать"
                                    onClick={::this.print}
                                />
                            </ContextTooltip>

                            <ModalTopMenuListSeparator key="separator"/>
                        </ModalTopMenuList>

                        <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                            <ModalTopMenuButton
                                className="_close"
                                onClick={this.props.isPageWithDetect && this.modalConfirmation ? this.openCheckModal : ::this.props.onClose}
                            />
                        </ContextTooltip>
                    </ModalTopMenuButtons>
                );
            }
        } else {
            form = this.getForm({}, ::this.create);

            buttons = (
                <ModalTopMenuButtons>
                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={::this.props.onClose}  
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );
        }

        const className = classNames(`profile-modal b-modal-${this.props.mode} ${this.modalClassName}`);

        return (
            <div ref={this.setWrapperRef}>
                <PageModal
                    header={{title, buttons}}
                    onClose={this.props.onClose}
                    className={className}
                    withFade={this.withFade}
                    buttons={[
                        <div key="tooltip" className="b-modal__footer-txt">Вы хотите сохранить все изменения?</div>,
                        <a key="cancel" href="#"
                           className="b-button b-button_size_md b-button_white b-button_shadow_gray b-button_cancel"
                           onClick={::this.props.onClose}>Отменить</a>,
                        <a key="save" href="#" className="b-button b-button_red b-button_size_md b-button_save"
                           onClick={(this.props.mode === 'add') ? ::this.onCreate : ::this.onEdit}>Сохранить</a>
                    ]}
                >
                    {loader}
                    {form}
                </PageModal>
                {this.state.isWarningModal && this.modalConfirmation ? this.renderWarningModal() : null}
            </div>
        );
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    turnedEditingMode: PropTypes.func
})

@connect((state) => ({}), {
    getDictionaryList,
    getRoutes,
    getRouteRegistryFact,
    getSchedules,
    getUnit,
    getUnits,
    getContract,
    getRouteVariant
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {

    state = {
        route_registry: {},
        route_registry_fact: {},
        seasons: [],
        contract_statuses: [],
        vehicle_capacity_types: [],
        route_schedule_types: [],
        route_statuses: [],
        rates_types: [],
        vehicle_types: [],
        vehicle_environmental_classes: [],
        schedules: [],
        units: [],
        search: {},
        searchResult: null,
        searchLoading: false,
        related: new EntityList,
    };

    searchDebounce = debounce(500, ::this.search);
    routesLoadDebounce = debounce(500, ::this.loadRoutes);

    getData() {
        return this.state.route_registry;
    }

    onChangeInput(field, {target: {value, type}}) {
        super.onChangeInput(field, {target: {value, type}});
        this.props.turnedEditingMode();
    }

    async componentDidMount() {
        await this.setState({
            route_registry: this.composeItem(this.props.data),
        });
        this.loadRouteRegistryFact();

        if (!this.props.data.rates_type_uuid) {
            this.loadRatesType();
        }

        this.loadDictionaries([
            'seasons',
            'vehicle_capacity_types',
            'route_schedule_types',
            'route_statuses',
            'contract_statuses',
            'rates_types',
            'vehicle_types',
            'vehicle_environmental_classes',
        ], null, true).then(() => {
            if (!this.get('status_uuid')) {
                this.setValue('route_registry.status_uuid', _.get(_.find(this.state.route_statuses, {label: 'Утверждён'}), 'value'));
            }
            if (!this.get('vehicle_type_uuid')) {
                this.setValue('route_registry.vehicle_type_uuid', _.get(_.find(this.state.vehicle_types, {label: 'Автобус'}), 'value'));
            }
            if (!this.get('vehicle_environmental_class_uuid')) {
                this.setValue('route_registry.vehicle_environmental_class_uuid', _.get(_.find(this.state.vehicle_environmental_classes, {label: 'Евро-1 '}), 'value'));
            }
        });
        this.loadSchedules();
        this.loadUnits();
        this.loadRelated();
        this.loadRouteVariant()
    }

    async loadRouteVariant() {
        if (!this.props.data.route) return;
        const response = await this.props.getRouteVariant(this.props.data.route.default_variant.uuid);

        if (response.isOk) {
            this.setState({
                routeVariant: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadRatesType() {
        const response = await this.props.getContract(this.props.data.contract_uuid);

        if (response.isOk) {
            this.setValue('route_registry.rates_type_uuid', response.payload.rates_type_uuid);
        }
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 10000,
            },
            filters: {
                withComponent: component_mapper(this.props.params.component),
            },
            response_data: [
                'items/uuid',
                'items/name',
                'items/component',
                'items/unit_types',
                'items/route',
                'items/name_full',
                'items/senior',
                'items/inn',
                'items/legal_address',
            ],
        });
        if (response.isOk) {
            await this.setState({
                units: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                    component: item.component,
                    unit_types: item.unit_types,
                    name_full: item.name_full,
                    senior: item.senior,
                    inn: item.inn,
                    legal_address: item.legal_address,
                    uuid: item.uuid
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async loadSchedules() {
        const routeUuid = this.get('route_uuid');
        if (!routeUuid) {
            return this.setState({
                schedules: [],
            });
        }

        const response = await this.props.getSchedules({
            filters: {
                withRoute: routeUuid,
            },
            response_data: [
                'items/uuid',
                'items/date_from',
                'items/date_to',
                'items/monday',
                'items/tuesday',
                'items/wednesday',
                'items/thursday',
                'items/friday',
                'items/saturday',
                'items/sunday',
                'items/turns/runs/type',
            ],
        });

        if (response.isOk) {
            await this.setState({
                schedules: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadRouteRegistryFact() {
        if (!this.state.route_registry.uuid) {
            return;
        }
        const response = await this.props.getRouteRegistryFact(this.state.route_registry.uuid);

        if (response.isOk) {
            this.setState({
                route_registry_fact: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    composeItem(item) {
        item.vehicles_plan_flat = {};
        item.vehicles_fact_flat = {};

        _.each(item.vehicles_plan, (plan) => {
            _.set(item, `vehicles_plan_flat.${plan.season_uuid}.${plan.vehicle_capacity_type_uuid}.${plan.route_schedule_type_uuid}`, plan.value);
            _.set(item, `vehicles_fact_flat.${plan.season_uuid}.${plan.vehicle_capacity_type_uuid}.${plan.route_schedule_type_uuid}`, plan.fact);
        });

        return item;
    }

    async loadRoutes(input, callback) {
        let result;
        if (!input && this.get('route_uuid')) {
            result = await this.props.getRoutes({
                filters: {
                    withUuid: [this.get('route_uuid')],
                    withoutArchived: true,
                },
                response_data: [
                    'items/uuid',
                    'items/title',
                    'items/registration_number',
                ],
            });
        } else {
            result = await this.props.getRoutes({
                filters: {
                    withComponent: component_mapper(this.props.params.component),
                    withoutArchived: true,
                },
                search: input,
                pagination: {
                    page: 1,
                    limit: 20,
                },
                response_data: [
                    'items/uuid',
                    'items/title',
                    'items/registration_number',
                ],
            });
        }

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    value: i.uuid,
                    label: `${i.registration_number} ${i.title}`,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.route_registry, path, defaultValue);
    }

    getAgencies() {
        const unitTypeUuid = _.get(_.find(this.state.company_types, {label: 'Агентство'}), 'value');
        return _.filter(this.state.units, (unit) => {
            return _.indexOf(unit.unit_types, unitTypeUuid) !== -1;
        });
    }

    // собираем данные для печати на бланке Свитедельства
    collectDataForPrintForm(item) {
        return prepareDateForPrint('certificate', item, this.state);
    }

    render() {
        this.props.data.printForm = this.collectDataForPrintForm(this.props.data);

        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} title="Общая информация">
                        <Block title="Рег. № маршрута (паспорт маршрута)">
                            {this.selectAsync('route_registry.route_uuid', ::this.routesLoadDebounce)}
                        </Block>
                        <Block title="Статус">
                            {this.select('route_registry.status_uuid', this.state.route_statuses)}
                        </Block>
                        <Block title="Вид тарифа">
                            {this.select('route_registry.rates_type_uuid', this.state.rates_types)}
                        </Block>
                        <Block title="Тип ТС">
                            {this.select('route_registry.vehicle_type_uuid', this.state.vehicle_types)}
                        </Block>
                        <Block title="Экологический класс">
                            {this.select('route_registry.vehicle_environmental_class_uuid', this.state.vehicle_environmental_classes)}
                        </Block>
                        <Block title={<span>Агентство обслуживания пассажиров <IconButton icon="search"
                                                                                          onClick={this.searchUnit.bind(this, 'agency_uuid')}/></span>}>
                            {(this.state.searchPopupField === 'agency_uuid') ? this.renderSearchPopup() : null}
                            {(!this.get('agency_uuid') || _.find(this.getAgencies(), {value: this.get('agency_uuid')})) ? (
                                this.select('route_registry.agency_uuid', this.getAgencies())
                            ) : (
                                this.state.related.getReact(this.get('agency_uuid'))
                            )}
                        </Block>
                    </AccordionItem>

                    {
                        window.RNIS_SETTINGS.CITY_TULA ? (
                            <AccordionItem title="Требования к подвижному составу">
                                <Block>
                                    {this.checkbox('route_registry.is_low_floor_level', 'Низкопольные', {
                                        className: '',
                                    })}
                                </Block>
                                <Block>
                                    {this.checkbox('route_registry.is_audio_video_fixation', 'Аудио/Видео фиксация', {
                                        className: '',
                                    })}
                                </Block>
                                <Block>
                                    {this.checkbox('route_registry.is_air_conditioning_installation', 'Кондиционирование', {
                                        className: '',
                                    })}
                                </Block>
                                <Block>
                                    {this.checkbox('route_registry.is_cashless_payment', 'Безналичная оплата', {
                                        className: '',
                                    })}
                                </Block>
                                <Block>
                                    {this.checkbox('route_registry.is_electronic_scoreboard', 'Электронное табло', {
                                        className: '',
                                    })}
                                </Block>
                                <Block>
                                    {this.checkbox('route_registry.is_passenger_monitoring_system', 'Мониторинг пассажиропотока', {
                                        className: '',
                                    })}
                                </Block>
                                <Block>
                                    {this.checkbox('route_registry.is_service', 'Свидетельство', {
                                        className: '',
                                    })}
                                </Block>
                                <div>
                                    <Block title="Доля ТС для перевозки инвалидов-колясочников в %">
                                        {this.textInput('route_registry.ratio_of_vehicles_for_disabled', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                    <Block title="Доля ТС с системой мониторинга пассажиропотока в %">
                                        {this.textInput('route_registry.ratio_passenger_monitoring_system', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </div>
                                {this.renderVehicleRequirements.bind(this)()}
                            </AccordionItem>
                        ) : null
                    }
                     
                    {(_.get(_.find(this.state.rates_types, {value: this.get('rates_type_uuid')}), 'label') === 'Регулируемый (ТОП)') ? (
                        <div>
                            <AccordionItem title="Плановый пробег">
                                <Block size="xl">
                                    <div className="Table indent-none" style={{width: 630}}>
                                        <TableContainer>
                                            <table className="b-table b-table-no-hover">
                                                <thead>
                                                <tr>
                                                    <th width="200px"/>
                                                    <th width="120px" className="text-center">План</th>
                                                    <th width="120px" className="text-center">Факт</th>
                                                    <th width="120px" className="text-center">% выполнения</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>1-й квартал</td>
                                                    <td className="text-center">{this.textInput('route_registry.mileage_quarter_1')}</td>
                                                    <td className="text-center">{this.state.route_registry_fact.mileage_quarter_1}</td>
                                                    <td className="text-center">{this.percent('mileage_quarter_1')}</td>
                                                </tr>
                                                <tr>
                                                    <td>2-й квартал</td>
                                                    <td className="text-center">{this.textInput('route_registry.mileage_quarter_2')}</td>
                                                    <td className="text-center">{this.state.route_registry_fact.mileage_quarter_2}</td>
                                                    <td className="text-center">{this.percent('mileage_quarter_2')}</td>
                                                </tr>
                                                <tr>
                                                    <td>3-й квартал</td>
                                                    <td className="text-center">{this.textInput('route_registry.mileage_quarter_3')}</td>
                                                    <td className="text-center">{this.state.route_registry_fact.mileage_quarter_3}</td>
                                                    <td className="text-center">{this.percent('mileage_quarter_3')}</td>
                                                </tr>
                                                <tr>
                                                    <td>Октябрь</td>
                                                    <td className="text-center">{this.textInput('route_registry.mileage_october')}</td>
                                                    <td className="text-center">{this.state.route_registry_fact.mileage_october}</td>
                                                    <td className="text-center">{this.percent('mileage_quarter_october')}</td>
                                                </tr>
                                                <tr>
                                                    <td>Ноябрь</td>
                                                    <td className="text-center">{this.textInput('route_registry.mileage_november')}</td>
                                                    <td className="text-center">{this.state.route_registry_fact.mileage_november}</td>
                                                    <td className="text-center">{this.percent('mileage_quarter_november')}</td>
                                                </tr>
                                                <tr>
                                                    <td>Декабрь</td>
                                                    <td className="text-center">{this.textInput('route_registry.mileage_december')}</td>
                                                    <td className="text-center">{this.state.route_registry_fact.mileage_december}</td>
                                                    <td className="text-center">{this.percent('mileage_quarter_december')}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </TableContainer>
                                    </div>
                                </Block>
                            </AccordionItem>
                            {this.state.seasons.map(::this.renderSeason)}
                            <AccordionItem title="Параметры расписания ТС на маршруте, количество кругорейсов">
                                <Block size="xl">
                                    <div className="Table indent-none">
                                        <TableContainer>
                                            <table className="b-table">
                                                <thead>
                                                <tr>
                                                    <th>С даты</th>
                                                    <th>По дату</th>
                                                    {this.state.route_schedule_types.map((scheduleType) => (
                                                        <th key={scheduleType.value}>{scheduleType.label}</th>
                                                    ))}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.schedules.map(::this.renderScheduleSummary)}
                                                </tbody>
                                            </table>
                                        </TableContainer>
                                    </div>
                                </Block>
                            </AccordionItem>
                        </div>
                    ) : null}
                </Accordion>
            </div>
        );
    }

    searchUnit(field) {
        this.setState({
            searchPopupField: (this.state.searchPopupField === field) ? null : field,
        });
    }

    renderSearchPopup() {
        const {searchResult} = this.state;
        return (
            <Popup>
                <Block title="ИНН">
                    {this.textInput('search.inn')}
                </Block>
                <Block title="КПП">
                    {this.textInput('search.kpp')}
                </Block>
                <Block title="ОГРН">
                    {this.textInput('search.ogrn')}
                </Block>
                <div className="search-result">
                    {this.state.searchLoading ? (<GlobalLoaderComponent/>) : null}
                    {(searchResult === null) ? 'Укажите реквизиты исполнителя' : (
                        (searchResult === false) ? 'Соответствие не установлено' : (
                            <div>
                                <Block title="Наименование">{searchResult.name}</Block>
                                <Block title="ИНН">{searchResult.inn}</Block>
                                <Block title="КПП">{searchResult.kpp}</Block>
                                <Block title="ОГРН">{searchResult.ogrn}</Block>
                                <Block title="Юр. адрес">{searchResult.legal_address}</Block>
                                <Button color="red" shadow="red" text="OK" onClick={::this.confirmSearch}/>
                            </div>
                        )
                    )}
                </div>
            </Popup>
        );
    }

    async confirmSearch() {
        const {searchPopupField} = this.state;
        await this.setValue(`route_registry.${searchPopupField}`, this.state.searchResult.uuid);
        this.setState({
            searchPopupField: null,
        });

        this.loadRelated();
    }

    dayMapping = {
        1: 'monday',
        2: 'tuesday',
        3: 'wednesday',
        4: 'thursday',
        5: 'friday',
        6: 'saturday',
        7: 'sunday',
    };

    renderScheduleSummary(schedule) {
        const turns = schedule.turns;
        const forwardRuns = _.filter(_.flatten(_.map(turns, 'runs')), {type: 'production_forward'}).length;
        const reverseRuns = _.filter(_.flatten(_.map(turns, 'runs')), {type: 'production_reverse'}).length;

        const runsCount = (reverseRuns === 0) ? forwardRuns : Math.round((forwardRuns + reverseRuns) / 2);

        return (
            <tr key={schedule.uuid}>
                <td>{moment(schedule.date_from).format(formats.DATE)}</td>
                <td>{moment(schedule.date_to).format(formats.DATE)}</td>
                {this.state.route_schedule_types.map((scheduleType) => {
                    const days = scheduleType.document.days.split(',');
                    const scheduleRunsCount = (_.filter(days, (day) => {
                        return schedule[this.dayMapping[day]];
                    }).length > 0) ? runsCount : 0;
                    return (
                        <td key={scheduleType.value}>{scheduleRunsCount}</td>
                    );
                })}
            </tr>
        );
    }

    percent(field) {
        const plan = _.toInteger(this.get(field));
        const fact = _.toInteger(this.state.route_registry_fact[field]);

        if (plan === 0) {
            return '0%';
        }

        return (Math.round((fact / plan) * 100 * 100) / 100) + '%';
    }

    renderSeason(season, index) {
        return (
            <AccordionItem key={index} title={`Сезон: ${season.label}`}>
                <Block size="xl">
                    <div className="Table indent-none">
                        <TableContainer>
                            <table className="b-table b-table-no-hover">
                                <thead>
                                <tr>
                                    <th width="200px">Класс</th>
                                    {this.state.route_schedule_types.map((scheduleType) => ([
                                        <th key={scheduleType.value + 'plan'}>{scheduleType.label}<br/>план</th>,
                                        <th key={scheduleType.value + 'fact'}>{scheduleType.label}<br/>факт</th>
                                    ]))}
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.vehicle_capacity_types.map(this.renderPlanRow.bind(this, season))}
                                {this.renderSummary(season)}
                                </tbody>
                            </table>
                        </TableContainer>
                    </div>
                </Block>
            </AccordionItem>
        )
    }

    renderSummary(season) {
        return (
            <tr key="summary">
                <td>Итого</td>
                {this.state.route_schedule_types.map((scheduleType, index) => {
                    return [
                        this.renderPlanSummaryCell(season, scheduleType, index),
                        this.renderFactSummaryCell(season, scheduleType, index),
                    ];
                })}
            </tr>
        );
    }

    renderPlanSummaryCell(season, scheduleType, index) {
        let value = 0;
        _.each(this.state.vehicle_capacity_types, (capacityType) => {
            value += _.toInteger(this.get(`vehicles_plan_flat.${season.value}.${capacityType.value}.${scheduleType.value}`));
        });
        return (
            <td key={`plan:${index}`} className="text-center">
                {value}
            </td>
        )
    }

    renderFactSummaryCell(season, scheduleType, index) {
        let value = 0;
        _.each(this.state.vehicle_capacity_types, (capacityType) => {
            value += _.toInteger(this.get(`vehicles_fact_flat.${season.value}.${capacityType.value}.${scheduleType.value}`));
        });
        return (
            <td key={`fact:${index}`} className="text-center">
                {value}
            </td>
        )
    }

    renderPlanRow(season, capacityType, index) {
        return (
            <tr key={index}>
                <td>{capacityType.label}</td>
                {this.state.route_schedule_types.map(this.renderPlanCell.bind(this, season, capacityType))}
            </tr>
        );
    }

    renderPlanCell(season, capacityType, scheduleType, index) {
        return ([
            <td key={index + 'plan'} className="text-center">
                {this.textInput(`route_registry.vehicles_plan_flat.${season.value}.${capacityType.value}.${scheduleType.value}`)}
            </td>,
            <td key={index + 'fact'} className="text-center">
                {this.get(`vehicles_fact_flat.${season.value}.${capacityType.value}.${scheduleType.value}`, 0)}
            </td>
        ])
    }

    renderVehicleRequirements() {

        return (
            <Block size="xl">
            <div className="Table indent-none">
                <TableContainer>
                    <table className="b-table">
                        <thead>
                        <tr>
                            <th width="200px">Класс</th>
                            <th className="text-center">Максимальный возраст ТС</th>
                            <th className="text-center">Минимальное количество мест</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.vehicle_capacity_types.map((item, index) => {

                                    return (
                                        <tr key={index + 'vehicles_requirements'}>
                                            <td>{item.label}</td>
                                            <td key={index + 'maxAge'} className="text-center">
                                                {this.textInput(`route_registry.vehicles_requirements.${item.value}.maximum_age`, {
                                                    onChange: (e) => {
                                                        this.setValue(`route_registry.vehicles_requirements.${item.value}.route_registry_uuid`, this.state.route_registry.uuid);
                                                        this.setValue(`route_registry.vehicles_requirements.${item.value}.vehicle_capacity_type_uuid`, item.value);
                                                        this.setValue(`route_registry.vehicles_requirements.${item.value}.maximum_age`, parseInt(e.target.value));
                                                    },
                                                    type: 'number',
                                                    positive: true,
                                                })}
                                            </td>
                                            <td key={index + 'minCapacity'} className="text-center">
                                                {this.textInput(`route_registry.vehicles_requirements.${item.value}.minimum_capacity`, {
                                                    onChange: (e) => {
                                                        this.setValue(`route_registry.vehicles_requirements.${item.value}.route_registry_uuid`, this.state.route_registry.uuid);
                                                        this.setValue(`route_registry.vehicles_requirements.${item.value}.vehicle_capacity_type_uuid`, item.value);
                                                        this.setValue(`route_registry.vehicles_requirements.${item.value}.minimum_capacity`, parseInt(e.target.value));
                                                    },
                                                    type: 'number',
                                                    positive: true,
                                                })}
                                            </td>
                                        </tr>
                                    )
                                  })
                            }
                        </tbody>
                    </table>
                </TableContainer>
            </div>
        </Block>
        )
    }

    async search() {
        this.setState({searchLoading: true});
        const searchData = this.state.search || {};
        const response = await this.props.getUnit(null, {
            inn: searchData.inn,
            kpp: searchData.kpp,
            ogrn: searchData.ogrn,
        });
        this.setState({searchLoading: false});

        if (response.isOk) {
            this.setState({
                searchResult: response.payload,
            });
        } else {
            this.setState({
                searchResult: false,
            });
        }
    }

    async setValue(field, value) {
        super.setValue(field, value);

        if (field === 'route_registry.route_uuid') {
            this.loadSchedules();
        }
        if (/^search\./.test(field)) {
            this.searchDebounce();
        }
    }

    async loadRelated() {
        const agency = {
            class: 'App\\Model\\Unit',
            uuid: this.get('agency_uuid'),
            source: 'organizational_units',
        };

        this.props.getEntityNames([agency]).then(async (response) => {
            if (response.isOk) {
                this.state.related.add(response);
                this.forceUpdate();
            }
        });
    }
}
