import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";
import {getUnits} from "store/reducers/organizational_units/units";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import FileReaderInput from 'react-file-reader-input';

import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import {api} from "helpers/api";
import {createVehicleWork} from "store/reducers/kurs/vehicle_works";
import TableContainer from "components/ui/Table/Container/TableContainer";
import UtilityDetailAddForm from "components/modules/utility/vehicles/vehicle-editor-form/operation-add-form/detail-add-form/index";
import {createVehicleMechanism} from "store/reducers/kurs/vehicle_mechanisms";

@connect(state => ({}), {createVehicleMechanism})

export default class UtilityMechanismAddForm extends BaseEditor {

    loadData(uuid) {
        return {
            isOk: true,
            data: {
                payload: null,
            },
        };
    }

    async createItem(data) {
        return await this.props.createVehicleMechanism(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    composeItem(data) {
        let item = _.cloneDeep(data);

        item.vehicle_uuid = this.props.vehicleUuid;

        return item;
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getUnits, getUsers, getDictionaryList}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {

    state = {
        kurs_vehicle_mechanism: {},
        seasons: [],
        kurs_mechanism_bindings: [],
    };

    getData() {
        return this.state.kurs_vehicle_mechanism;
    }

    async componentDidMount() {
        await this.setState({
            kurs_vehicle_mechanism: this.props.data,
        });
        this.loadDictionaries([
            'seasons',
            'kurs_mechanism_bindings',
            'kurs_mechanism_types',
        ]);
    }

    get(path, defaultValue = null) {
        return _.get(this.state.kurs_vehicle_mechanism, path, defaultValue);
    }

    async setValue(field, value) {
        if (field === 'kurs_vehicle_mechanism.mechanism_model_uuid') {
            const typeUuid = _.get(_.find(this.state.kurs_mechanism_bindings, {value}), 'document.mechanism_type_uuid');
            const seasonUuid = _.get(_.find(this.state.kurs_mechanism_types, {value: typeUuid}), 'document.season_uuid');
            this.setValue('kurs_vehicle_mechanism.season_uuid', seasonUuid);
        }

        return super.setValue(field, value);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem single={true} opened={true}>
                        <Block title="Механизм">
                            {this.select('kurs_vehicle_mechanism.mechanism_model_uuid', _.filter(this.state.kurs_mechanism_bindings, (item) => {
                                return _.indexOf(this.props.existing, item.value) === -1;
                            }))}
                        </Block>
                        <Block title="Сезон">
                            {this.select('kurs_vehicle_mechanism.season_uuid', this.state.seasons, {
                                disabled: true,
                            })}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

}