import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';
import classNames from 'classnames';

import _ from 'lodash';
import './index.less';
import * as alerts from "helpers/alerts";
import moment from "moment";
import formats from "dictionaries/formats";
import SIPButtonComponent from "components/ui/sip_button";
import Tooltip from 'react-tooltip-component';
import {GlobalEvent} from "helpers/event-system";
import {api} from "helpers/api";
import {makeResponse} from "helpers/response";
import {EntityList} from "helpers/entity";
import Checkbox from "components/ui/form/checkbox";
import {Link} from "react-router";
import ContextTooltip from "components/ui/context-tooltip";
import {User} from "helpers/user";

export default class CleanupSpecialistMarkerPopup extends Component {

    state = {
        related: new EntityList,
        geometryActive: false,
        tasks: [],
        ports: [],
        extendedData: [],
        orderExecution: null,
    };

    async componentWillMount() {
        if (this.props.component === 'road') {
            await this.loadTasks();
            // this.onGeometryActiveChange();
        }
        // this.loadBnsoPorts();
        // this.loadExtendedData();
        // if (this.isKiutrOrChildren()) {
        //     this.loadOrderExecution();
        // }
    }

    async loadTasks() {
        const cleanupSpecialist = this.props.cleanupSpecialist;

        const status = _.get(_.find(this.props.kurs_task_statuses, {name: 'В работе'}), 'uuid');

        const response = await makeResponse(() => {
            return api.kurs.getTasks({
                filters: {
                    withCleanupSpecialists: [
                        cleanupSpecialist.uuid,
                    ],
                    withStatus: status,
                },
            });
        });

        if (response.isOk) {
            this.setState({
                tasks: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadOrderExecution() {
        const response = await makeResponse(() => {
            return api.geo.getOrderExecutions({
                filters: {
                    withVehicles: this.props.vehicle.uuid,
                    current: true,
                },
            });
        });

        if (response.isOk) {
            this.setState({
                orderExecution: _.first(response.payload.items),
            });
        }
    }

    async loadBnsoPorts() {
        const response = await makeResponse(() => {
            return api.vehicles.getBnsoList({
                filters: {
                    withBnsoNumber: this.props.device.id,
                },
            });
        });

        if (response.isOk) {
            const ports = _.get(_.first(response.payload.items), 'ports') || [];

            this.setState({
                ports,
            });
        } else {
            response.showErrors();
        }
    }

    async loadExtendedData() {
        const response = await makeResponse(() => {
            return api.t1sync.getDeviceExtendedData(this.props.device.id, 1, 1, true);
        });

        if (response.isOk) {
            this.setState({
                extendedData: _.get(_.first(response.payload.items), 'mapped') || {},
            });
        }
    }

    onSettingsClick() {
        this.props.showVehiclePopupConfig();
        this.onClose();
    }

    isKiutrOrChildren() {
        return _.indexOf([
            'kiutr',
            'children',
            'ems',
        ], this.props.component) !== -1;
    }

    isKurs() {
        return this.props.component === 'road';
    }

    hasProductionRun() {
        return this.state.orderExecution && (_.indexOf([
            'production_forward',
            'production_reverse',
        ], this.state.orderExecution.type) !== -1);
    }

    isActive(field) {
        return _.get(this.props.vehiclePopup, field);
    }

    getProperties() {
        console.log(this.props)
        const {device, vehicle} = this.props;
        let items = [];

        if (this.isActive('speed')) {
            items.push({
                label: 'Скорость',
                value: (
                    <span>
                        <span>{device.speed}</span> км/ч
                        <span className="degree__block">
                            <i className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_arrow-up"
                               style={{transform: `rotate(${device.course - 45}deg)`}}/>
                            <span className="degree">{device.course}°</span>
                        </span>
                    </span>
                ),
            });
        }

        if (this.isActive('bnso_number')) {
            items.push({
                label: 'Номер БНСО',
                value: (
                    <span>
                        <span>{device.id}</span>
                    </span>
                ),
            });
        }

        if (this.isActive('bnso_model')) {
            items.push({
                label: 'Модель БНСО',
                value: vehicle.current_bnso.bnso_type ? this.props.data.bnso_types[vehicle.current_bnso.bnso_type].name : '-',
            });
        }

        if (this.isActive('overspeed')) {
            items.push({
                label: 'Превышение скорости',
                value: (device.speedLimit && device.speed && (device.speed >= device.speedLimit + 20)) ? (
                    <a href="javascript:void(0)" className="alarm">
                        <i className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_alarm"/>
                    </a>
                ) : '-',device
            });
        }

        if (this.isKiutrOrChildren() && this.isActive('fill')) {
            items.push({
                label: 'Заполнение',
                value: '-',
            });
        }

        if (this.props.vehicleEnvironmentalClass && this.isActive('environment')) {
            items.push({
                label: 'Экологический класс',
                value: this.props.vehicleEnvironmentalClass || '-',
            });
        }

        if (this.props.vehicleCapacityType && this.isActive('capacity')) {
            items.push({
                label: 'Класс вместимости',
                value: this.props.vehicleCapacityType || '-',
            });
        }

        if (this.isActive('properties')) {
            const properties = _.filter(this.properties, (className, name) => {
                return vehicle[name];
            });
            items.push({
                label: 'Оснащение',
                className: 'equipment',
                value: (
                    <div>
                        {(_.keys(properties).length === 0) ? '-' : null}
                        {_.map(properties, (className, name) => {
                            return (
                                <i key={name}
                                   className={`top-menu_modal__link b-icon-link b-icon-link_params ${className}`}/>
                            );
                        })}
                    </div>
                ),
            });
        }

        if (this.isActive('extended')) {
            if (this.state.ports.length === 0) {
                items.push({
                    label: 'Индивидуальные датчики',
                    value: '-',
                });
            }
            _.each(this.state.ports, (port) => {
                if (!port.bnso_indicator_uuid) {
                    return;
                }

                let title = _.get(_.get(this.props.data.bnso_indicators, port.bnso_indicator_uuid), 'name');

                if (port.type === 'numeric' && _.get(port, 'graduation.spreadsheets', []).length === 0) {
                    const indicatorMeasure = _.get(_.get(this.props.data.bnso_indicators, port.bnso_indicator_uuid), 'measure');

                    title += ', ' + indicatorMeasure;
                }

                items.push({
                    label: title,
                    value: _.get(this.state.extendedData, port.bnso_indicator_uuid, '-'),
                });
            });
        }

        if (this.isActive('unit')) {
            items.push({
                label: 'Предприятие',
                value: _.get(this.props.data.units, vehicle.unit_uuid, '-') || '-',
            });
        }

        if (this.isActive('parent_unit')) {
            items.push({
                label: 'Головное предприятие',
                value: _.get(this.props.data.units, _.get(this.props.data.parentUnits, vehicle.unit_uuid), '-') || '-',
            });
        }

        if (this.isActive('to')) {
            items.push({
                label: 'Территориальная принадлежность',
                value: '-',
            });
        }

        if (this.isKiutrOrChildren() && this.isActive('driver')) {
            items.push({
                label: 'Водитель',
                value: vehicle.driver_name ? vehicle.driver_name + (window.RNIS_SETTINGS.HINTDRIVERNAME ? ` (${vehicle.driver_personnel_number})` : '' ) : '-',
            });
        }

        if (this.isKurs() && this.isActive('driver')) {
            items.push({
                label: 'Водитель',
                value: _.map(this.state.drivers, 'name').join(', ') || '-',
            });
        }

        if (this.isActive('order')) {
            items.push({
                label: 'Наряд',
                value: vehicle.order_uuid ? (
                    <a href="javascript:void(0)"
                       onClick={this.goto.bind(this, `/kiutr/orders/${vehicle.order_uuid}`)}>
                        {vehicle.order_number}
                    </a>
                ) : '-',
            });
        }

        if (this.isKiutrOrChildren() && this.isActive('route')) {
            items.push({
                label: 'Маршрут',
                value: vehicle.route_number || '-',
            });
        }

        if (this.isKiutrOrChildren() && this.isActive('run_direction')) {
            if (this.hasProductionRun()) {
                items.push({
                    label: 'Направление рейса',
                    value: (this.state.orderExecution.type === 'production_forward') ? 'Прямое' : 'Обратное',
                });
            } else {
                items.push({
                    label: 'Направление рейса',
                    value: '-',
                });
            }
        }

        if (this.isKiutrOrChildren() && this.isActive('shift')) {
            if (this.hasProductionRun()) {
                items.push({
                    label: 'Смена',
                    value: this.state.orderExecution.shift,
                });
            } else {
                items.push({
                    label: 'Смена',
                    value: '-',
                });
            }
        }

        if (this.isKurs() && this.isActive('route')) {
            items.push({
                label: 'Задание',
                value: _.map(this.state.tasks, 'number').join(', ') || '-',
            });
        }

        if (this.isKurs() && this.isActive('done_road')) {
            items.push({
                label: 'Выполнение задания',
                value: _.map(this.state.tasks, (task) => {
                    const fact = _.filter(task.items_fact || [], {is_confirmed: true, movement_type: 'work'}).length;
                    const plan = _.filter(task.items_fact || [], {movement_type: 'work'}).length;
                    return ((plan !== 0) ? _.round(100 * fact / plan, 2) : 0) + '%';
                }).join(', ') || '-',
            });
        }

        if (this.isKiutrOrChildren() && this.isActive('done')) {
            if (this.hasProductionRun()) {
                const stopPoints = _.filter(this.state.orderExecution.data, {point_type: 'stop_point'});
                const factStopPoints = _.filter(stopPoints, item => item.time_fact);
                const lastFactStopPoint = _.last(factStopPoints);

                items.push({
                    label: 'Выполнение рейса',
                    value: ((stopPoints.length !== 0) ? _.round(100 * factStopPoints.length / stopPoints.length) : 0) + '%',
                });

                if (lastFactStopPoint) {
                    const diff = Math.abs(moment(lastFactStopPoint.time_fact).diff(moment(lastFactStopPoint.time_plan), 'minutes'));

                    items.push({
                        label: 'Отклонение в рейсе',
                        value: (lastFactStopPoint.is_before_time ? 'Нагон' : 'Отставание') + ` ${diff} мин.`,
                    });
                } else {
                    items.push({
                        label: 'Отклонение в рейсе',
                        value: '-',
                    });
                }
            } else {
                items.push({
                    label: 'Выполнение рейса',
                    value: '-',
                });
            }
        }

        return items;
    }

    async onRunClick() {
        this.props.onHistoryGeojsonChange(await this.loadRouteVariant(this.state.orderExecution.route_variant_uuid));
    }

    async loadRouteVariant(uuid) {
        const response = await makeResponse(() => {
            return api.geo.getRouteVariant(uuid);
        });

        if (response.isOk) {
            const route = response.payload;
            let coordinates = [];
            _.each(_.concat(route.forward_points, route.reverse_points), (point) => {
                coordinates = _.concat(coordinates, _.get(point, 'path_to_the_next_point_geometry.coordinates', []));
            });
            return {
                type: 'LineString',
                coordinates,
            };
        } else {
            response.showErrors();
        }
    }

    render() {
        const device = this.props.device;
        const cleanupSpecialist = this.props.cleanupSpecialist;

        return (
            <div className="b-modal b-modal-map cleanup-tooltip map-tooltip-modal">
                <div className="b-modal__header">
                    <div className="b-modal__header-link _close" onClick={::this.onClose}></div>
                    <span className="b-modal__header-separator"></span>
                    <div className="b-modal__name">
                        <div className="title">
                            <div className="title__name">
                                {cleanupSpecialist.info.name} {cleanupSpecialist.info.second_name} {cleanupSpecialist.info.surname}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="b-modal__footer">
                    <div className="b-modal__footer_left">
                        <div className="datatime">
                            <span className="data">{moment.unix(device.time).format(formats.DATE)}{' '}</span>
                            <span className="time">{moment.unix(device.time).format(formats.TIME_FULL)}</span>
                        </div>
                    </div>
                    <div className="b-modal__footer_right">
                            <div className="buttons">
                                {/* {this.hasProductionRun() ? (
                                    <a href="javascript:void(0)" onClick={::this.onRunClick}
                                       className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_way"></a>
                                ) : null} */}
                                {((this.state.tasks || []).length > 0) ? (
                                    <Tooltip title="Карточка задания" position="bottom">
                                        <a href={`/road/tasks/${_.first(this.state.tasks).uuid}`}
                                           target="_blank"
                                           className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_task"/>
                                    </Tooltip>
                                ) : null}
                                <Tooltip title="История" position="bottom">
                                    <a href="javascript:void(0)" onClick={::this.gotoHistory}
                                       className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_history"></a>
                                </Tooltip>
                                <Tooltip title="Карточка Пользователя" position="bottom">
                                    <a
                                        target="_blank"
                                        href={`/road/personnel/${cleanupSpecialist.uuid}/edit`}
                                        className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_card"
                                    ></a>
                                </Tooltip>
                                <div className="button-dropdown call">
                                    <Tooltip title="Позвонить" position="bottom">
                                        <input type="checkbox" className="button-dropdown__open"/>
                                    </Tooltip>
                                    <i className="button-dropdown__arrow"/>
                                    <a
                                        href="javascript:void(0)"
                                        className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_phone"
                                    ></a>
                                    <ul className="dropdown-menu">
                                        {cleanupSpecialist.info.phone ? (
                                            <li
                                                className="dropdown-menu__item"
                                                onClick={this.call.bind(this, cleanupSpecialist.info.phone)}
                                            >
                                                {cleanupSpecialist.info.phone}
                                            </li>
                                        ) : null}
                                        {!cleanupSpecialist.info.phone ? (
                                            <li className="dropdown-menu__item">
                                                <span>нет телефона</span>
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        );
    }

    goto(url) {
        this.onClose();
        this.props.router.push(url);
    }

    onGeometryActiveChange() {
        this.setState({
            geometryActive: !this.state.geometryActive,
        });

        this.props.onActiveGeometriesVehicleChange(this.props.vehicle.uuid);
    }

    call(phone) {
        GlobalEvent().call('call:start', phone);
    }

    getCourseText() {
        const course = this.props.device.course;

        if ((course >= 360 - 22.5) || (course <= 22.5)) {
            return 'Север';
        }
        if ((course >= 22.5) && (course <= 67.5)) {
            return 'Северо-Восток';
        }
        if ((course >= 67.5) && (course <= 112.5)) {
            return 'Восток';
        }
        if ((course >= 112.5) && (course <= 157.5)) {
            return 'Юго-Восток';
        }
        if ((course >= 157.5) && (course <= 202.5)) {
            return 'Юг';
        }
        if ((course >= 202.5) && (course <= 247.5)) {
            return 'Юго-Запад';
        }
        if ((course >= 247.5) && (course <= 292.5)) {
            return 'Запад';
        }
        if ((course >= 292.5) && (course <= 337.5)) {
            return 'Северо-Запад';
        }
    }

    onClose(e) {
        e && e.preventDefault();

        this.props.onClose();
    }

    gotoHistory(e) {
        e && e.preventDefault();

        this.onClose();
        this.props.showHistory(this.props.cleanupSpecialist.login);
    }

    gotoFuel(e) {
        e && e.preventDefault();

        this.onClose();
        this.props.vehicle.onFuelClick(this.props.vehicle);
    }
}