import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';
import Column from 'components/ui/column';
import Page from 'components/ui/page';
import BaseTableComponent from 'components/base/base_table';
import TableContainer from 'components/ui/Table/Container/TableContainer';
import SlideLeftTransition from 'components/ui/transitions/slide-left';
import ViolationBaseForm from './forms/ViolationBaseForm';
import moment from 'moment';
import formats from 'dictionaries/formats';
import {getEntityNames} from 'store/reducers/system';
import {getDailyViolations, getViolations} from 'store/reducers/kiutr/transport-work';
import {getUnits} from 'store/reducers/staffing/staffing';
import {EntityList} from 'helpers/entity';
import {mapDatatablesRequestToMeta} from 'helpers/api';
import {List} from 'immutable';
import Datepicker from "components/ui/form/datepicker";
import {propTypes, defaultProps} from 'react-props-decorators';
import HeaderTextButton from 'components/ui/header-text-button';
import * as alerts from 'helpers/alerts';
import systems from "dictionaries/systems";
const $ = require('jquery');
import './style.less';
import {component_mapper} from "helpers/component_mapper";

@propTypes({
    uuids: PropTypes.array,
    from: PropTypes.string,
    to: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onGoToDispatcher: PropTypes.func.isRequired
})

@defaultProps({
    uuids: [],
    from: moment().startOf('year').format(formats.DATE),
    to: moment().format(formats.DATE)
})

@connect(state => ({}), {getEntityNames, getDailyViolations, getViolations, getUnits})

export default class KiutrTransportWorkViolations extends BaseTableComponent {

    getTitle() {
        return `${systems[component_mapper(this.props.params.component)]} → Нарушения`;
    }

    select = 'none';
    noViolationsChar = '-';

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            columns: List(this.getColumns()),
            related: new EntityList(),
            from: moment().startOf('year'),
            to: moment().endOf('day'),
            violationUuid: null,
            violationType: null,
            data: [],
            isDataFetched: false
        });
    }

    componentWillMount() {
        this.setState((prevState, props) => {
            return {
                from: moment(props.from, formats.DATE),
                to: moment(props.to, formats.DATE)
            }
        }, this.reload);
    }

    componentWillReceiveProps(nextProps) {
        const from = moment(this.state.from).format(formats.DATE);
        const to = moment(this.state.to).format(formats.DATE);

        if (from !== nextProps.from || to !== nextProps.to) {
            this.setState((prevState, props) => {
                return {
                    from: moment(nextProps.from, formats.DATE),
                    to: moment(nextProps.to, formats.DATE)
                }
            }, this.reload);
        }
    }

    createColumn(violationCount) {
        const td = $($('<td/>'));
        td.attr('align', 'center');

        if (violationCount) {
            td.addClass('violation-cell');
            const content = $(`<div class="violation-cell_wrapper"><div class="violation-cell_content">${violationCount}</div></div>`);
            td.append($(content));
        }
        else {
            td.text(violationCount);
        }

        return td;
    }

    addRow() {
        setTimeout(() => {
            const table = this.refs.table.getWrappedInstance().table;

            const violations = {
                violations_environmental_class: 0,
                violations_point_skip: 0,
                violations_schedule_error: 0,
                violations_vehicles: 0
            };
            table.rows().every(function() {
                const data = this.data();
                violations.violations_environmental_class += data.violations_environmental_class;
                violations.violations_point_skip += data.violations_point_skip;
                violations.violations_schedule_error += data.violations_schedule_error;
                violations.violations_vehicles += data.violations_vehicles;
            });

            const $table = $(this.refs.table.getWrappedInstance().refs.table);

            let tbody = $table.closest('.dataTables_wrapper').find('.dataTables_scrollBody tbody');

            if (tbody.length) {
                tbody = tbody[0];

                let lastRow = $(tbody).children().last();

                if (lastRow.length && $(lastRow[0]).hasClass('total-row')) {
                    return;
                }

                const tr = $($('<tr/>'));
                tr.addClass('total-row');

                tr.append('<td>');
                tr.append('<td>');
                const td = $($('<td/>'));
                td.text('Сумма');
                td.attr('align', 'right');
                tr.append($(td));

                tr.append(this.createColumn(violations.violations_vehicles));
                tr.append(this.createColumn(violations.violations_environmental_class));
                tr.append(this.createColumn(violations.violations_point_skip));
                tr.append(this.createColumn(violations.violations_schedule_error));

                $(tbody).append(tr);
            }
        }, 100);
    }

    isShowViolationForm() {
        return !!(this.state.violationUuid && this.state.violationType);
    }

    getViolationType(field) {
        return field.replace('violations_', '');
    }

    onClick = (data) => {
        const field = data.field;
        const count = _.get(data, `data.${field}`);

        if (count) {
            const type = this.getViolationType(data.field);

            if (type) {
                this.setState({
                    violationUuid: data.data.uuid,
                    violationType: type
                })
            }
        }
    };

    getViolationCell(count) {
        return count ? `<div class="violation-cell_wrapper"><div class="violation-cell_content">${count}</div></div>` : this.noViolationsChar;
    }

    getUuids() {
        return this.state.isDataFetched ? this.state.data.map((item) => item['route_registry_uuid']) : this.props.uuids;
    }

    onGoToDispatcher = () => {
        this.props.onGoToDispatcher(/*this.getUuids(),*/ moment(this.state.from).format(formats.DATE), moment(this.state.to).format(formats.DATE));
    };

    getColumns() {
        return this.prepareColumns([
            new Column('Дата')
                .fromField('date')
                .withDateFilter()
                .withDrawer((item) => item.date && moment(item.date).format(formats.DATE)),

            new Column('Предприятие')
                .fromField('route_registry.contract.carrier_uuid')
                .withDrawer(item => this.state.related.get(_.get(item, 'route_registry.contract.carrier_uuid')))
                //.fromField('contract_unit_name')
                .denyColumnFilter(),

            new Column('Название маршрута')
                .fromField('route_registry.route.title')
                //.fromField('route_title')
                .denyColumnFilter(),

            new Column('Кол-во и вместимость ТС')
                .fromField('violations_vehicles')
                .withDrawer(item => this.getViolationCell(item.violations_vehicles))
                .withClassName('violation-cell')
                .denyColumnFilter(),

            new Column('Экологический класс ТС')
                .fromField('violations_environmental_class')
                .withDrawer(item => this.getViolationCell(item.violations_environmental_class))
                .withClassName('violation-cell')
                .denyColumnFilter(),

            new Column('Нет отметки КП')
                .fromField('violations_point_skip')
                .withDrawer(item => this.getViolationCell(item.violations_point_skip))
                .withClassName('violation-cell'),

            new Column('Отклонение от расписания')
                .fromField('violations_schedule_error')
                .withDrawer(item => this.getViolationCell(item.violations_schedule_error))
                .withClassName('violation-cell')
                .denyColumnFilter()
        ]);
    }

    renderHeaderActions() {
        return [
            <HeaderTextButton text="Рейсы" onClick={this.onGoToDispatcher}/>,
            <HeaderTextButton text="К списку" onClick={this.props.onClose}/>
        ];
    }

    fromChange = async ({target: {value}}) => {
        /*if (moment(value, formats.DATE_URL).isAfter(moment(this.state.to, formats.DATE_URL))) {
            const prevDate = moment(this.state.from).format(formats.DATE_API);
            await this.setState({from: prevDate});
            alerts.error('Неверный диапазон дат');
        }
        else {*/
            await this.setState({from: value});
            this.reload();
        //}
    };

    toChange = async ({target: {value}}) => {
        /*if (moment(value, formats.DATE_URL).isBefore(moment(this.state.from, formats.DATE_URL))) {
            const prevDate = moment(this.state.to).format(formats.DATE_API);
            await this.setState({to: prevDate});
            alerts.error('Неверный диапазон дат');
        }
        else {*/
            await this.setState({to: value});
            this.reload();
        //}
    };

    renderHeaderContents() {
        return [
            <div key="diapason">
                <div className="top-menu__label">Дата с:</div>
                <Datepicker style="dark" value={this.state.from} onChange={this.fromChange}/>
            </div>,
            <div key="diapason">
                <div className="top-menu__label">Дата по:</div>
                <Datepicker style="dark" value={this.state.to} onChange={this.toChange}/>
            </div>
        ];
    }

    async loadData(meta) {
        meta.filters.withRouteRegistry = this.props.uuids;
        meta.filters.withPeriod = [
            moment(this.state.from).format(formats.DATE_API),
            moment(this.state.to).format(formats.DATETIME_API)
        ];
        return await this.props.getDailyViolations(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const units = _.map(_.filter(_.map(result, 'route_registry.contract.carrier_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(_.concat(units));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
            this.addRow();
        }
    }

    async loadCallback(request, drawCallback, settings) {
        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        //console.log(meta);
        const response = await this.loadData(meta);

        if (response.isOk) {
            const data = response.payload.items;
            //console.log(data);

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: response.data.headers.meta.pagination.total,
                recordsTotal: response.data.headers.meta.pagination.total
            };

            drawCallback(json);
            this.addRow();
            this.loadRelatedEntities(json, drawCallback);
            this.selectedRowsRecalc();
            this.setState({success: true, data, isDataFetched: true});
        } else {
            response.showErrors();
        }
    }

    closeEditor() {
        this.setState({
            violationUuid: null,
            violationType: null
        });
    }

    renderViolationForm() {
        return this.isShowViolationForm()
            ? <ViolationBaseForm key="violation_form"
                                 onClose={::this.closeEditor}
                                 uuid={this.state.violationUuid}
                                 type={this.state.violationType}
            />
            : null;
    }

    render() {
        return (
            <Page title={this.getTitle()}
                  pageId="TransportWorkViolations"
                  headerActions={this.renderHeaderActions()}
                  headerContents={this.renderHeaderContents()}>

                <TableContainer>
                    {this.renderTable()}
                </TableContainer>

                <SlideLeftTransition>
                    {this.renderViolationForm()}
                </SlideLeftTransition>
            </Page>
        );
    }
}