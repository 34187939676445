import React, {Component} from 'react';
import systems from "dictionaries/systems";
import WidgetPage from '../base-components/WidgetPage';
import VehicleDataWidget from './widgets/VehicleDataWidget';
import TransportEnterpriseRatingWidget from './widgets/TransportEnterpriseRatingWidget';
import VehicleDataDateWidget from './widgets/VehicleDataDateWidget';
import MechanismDowntimeWidget from './widgets/MechanismDowntimeWidget';
import SpeedViolationWidget from './widgets/SpeedViolationWidget';
import TransportWorkWidget from './widgets/TransportWorkWidget';
import randomColor from 'randomcolor';
import BaseAnalyticsComponent from "components/modules/analytics/base";

const vehicleDataWidgetPieColor = randomColor();
const transportEnterpriseRatingWidgetBarColor = randomColor();
const speedViolationWidgetBarColor = randomColor();
const transportWorkWidgetBarColor = randomColor();

export default class Waste extends BaseAnalyticsComponent {

    constructor(props) {
        super(props);

        this.widgetItems = [
            { id: 'vehicleDataWidget', name: 'Данные по ТС', widget: this.vehicleDataWidget },
            { id: 'transportEnterpriseRatingWidget', name: 'Рейтинг транспортных предприятий', widget: this.transportEnterpriseRatingWidget },
            { id: 'vehicleDataDateWidget', name: 'Данные по ТС на текущую дату', widget: VehicleDataDateWidget },
            { id: 'mechanismDowntimeWidget', name: 'Простой техники', widget: MechanismDowntimeWidget },
            { id: 'speedViolationWidget', name: 'Количество нарушений скоростного режима', widget: this.speedViolationWidget },
            { id: 'transportWorkWidget', name: 'Учет транспортной работы', widget: this.transportWorkWidget }
        ];

        this.title = `${systems.analytics} → Вывоз мусора`;
    }

    vehicleDataWidget(props) {
        return <VehicleDataWidget {...props} pieChartFillColor={vehicleDataWidgetPieColor}/>
    }

    transportEnterpriseRatingWidget(props) {
        return <TransportEnterpriseRatingWidget {...props} barChartFillColor={transportEnterpriseRatingWidgetBarColor}/>
    }

    speedViolationWidget(props) {
        return <SpeedViolationWidget {...props} barChartFillColor={speedViolationWidgetBarColor}/>
    }

    transportWorkWidget(props) {
        return <TransportWorkWidget {...props} barChartFillColor={transportWorkWidgetBarColor}/>
    }

    render() {
        return (
            <WidgetPage cacheKey="waste"
                        title={this.title}
                        widgetItems={this.widgetItems}
            />
        );
    }
}