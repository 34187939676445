import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import DashboardTable from 'components/modules/analytics/base-components/DashboardTable';
import WidgetSelector from 'components/modules/analytics/base-components/WidgetSelector';
import GlobalLoaderComponent from "components/ui/global-loader";
import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import _ from 'lodash';
import {connect} from "react-redux";
import {User} from "helpers/user";
import * as storage from "utils/storage";
import moment from "moment";
import {getTabletKiutrSummary, getTabletSummary} from "store/reducers/reports/tablet";
import Datepicker from "components/ui/form/datepicker";
import {
    Bar, BarChart, Label, LabelList, Pie, PieChart, ResponsiveContainer, Text, Tooltip, XAxis,
    YAxis
} from "recharts";
import Select from "components/ui/select";
import formats from "dictionaries/formats";
import {speedViolationsDriversSummary} from "store/reducers/notifications/speed-violations";
import {getUsers} from "store/reducers/staffing/staffing";
import {getUnits} from "store/reducers/organizational_units/units";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import {getVehicleList} from "store/reducers/vehicles/vehicles";

@propTypes({
    barChartFillColor: PropTypes.string,
    component: PropTypes.string,
})

@defaultProps({
    barChartFillColor: '',
    component: 'kiutr',
})

@connect(state => ({}), {getTabletKiutrSummary, speedViolationsDriversSummary, getUsers, getUnits, getVehicleList, getEntityNames})

export default class SpeedViolationsWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isError: false,
            isLoading: false,
            from: moment().subtract(1, 'day').format(formats.DATE_API),
            to: moment().subtract(1, 'day').format(formats.DATE_API),
            speed_violations: 'units',
            data: {},
            data2: [],
            users: {},
            units: {},
            vehicles: {},
            related: new EntityList,
        };
    }

    componentWillMount() {
        this.loadData();
    }

    async componentWillReceiveProps(nextProps) {
        await this.setState({
            municipality: nextProps.municipality
        })
        this.loadData();
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value});
        this.loadData();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value});
        this.loadData();
    };

    async loadData() {
        this.setState({isLoading: true});
        await Promise.all([
            this.loadData1(),
            this.loadData2(),
        ]);
        this.setState({isLoading: false});
    }

    async loadData1() {

        let summaryOptions = {
            date_from: moment(this.state.from).format(formats.DATE_API),
            date_to: moment(this.state.to).format(formats.DATE_API),
            component: this.props.component,
            with_communal_municipalities: this.props.municipality
        };

        if (!window.RNIS_SETTINGS.analytic_filter_for_municipality) {
            delete summaryOptions.with_communal_municipalities
        }

        const response = await this.props.getTabletKiutrSummary(summaryOptions);

        if (response.isOk) {
            this.setState({
                data: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadData2() {
        const response2 = await this.props.speedViolationsDriversSummary(this.props.component, moment(this.state.from).format(formats.DATETIME_API), moment(this.state.to).endOf('day').format(formats.DATETIME_API));

        if (response2.isOk) {
            await this.setState({
                data2: response2.payload.items,
            });
            this.loadRelated();
        } else {
            response2.showErrors();
        }
    }

    async loadRelated() {
        this.loadRelatedEntities();
        await this.loadUsers();
    }

    async loadVehicles() {
        const response = await this.props.getVehicleList({
            filters: {
                withUuid: _.uniq(_.map(this.state.data2, 'vehicle_uuid')),
            },
        });

        if (response.isOk) {
            return response.payload.items;
        }
        return [];
    }

    async loadRelatedEntities() {
        const vehicles = await this.loadVehicles();
        this.loadUnits(vehicles);
        const marks = _.map(_.uniq(_.filter(_.map(vehicles, 'vehicle_mark_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleMark\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const models = _.map(_.uniq(_.filter(_.map(vehicles, 'vehicle_model_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleModel\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(_.concat(marks, models));

        if (response.isOk) {
            this.state.related.add(response);
            this.setState({
                vehicles: _.keyBy(vehicles, 'uuid'),
            });
        }
    }

    async loadUsers() {
        const usersResponse = await this.props.getUsers({
            filters: {
                withUuid: _.uniq(_.map(this.state.data2, 'driver_uuid')),
            },
        });

        if (usersResponse.isOk) {
            await this.setState({
                users: _.keyBy(usersResponse.payload.items, 'uuid'),
            });
        }
    }

    async loadUnits(vehicles) {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withUuid: _.uniq(_.map(vehicles, 'unit_uuid')),
            },
        });

        if (response.isOk) {
            this.setState({
                units: _.keyBy(response.payload.items, 'uuid'),
            });
        }
    }

    get(field, forDisplay = true) {
        const value = _.get(this.state.data, field, null);
        if (forDisplay) {
            if (value !== null) {
                return _.round(value, 2);
            } else {
                return '-';
            }
        }
        return value || 0;
    }

    renderContent() {
        return (
            <div className="full-size kiutr-widget widget-block_speed-violation">
                <div className="date-wrapper">
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange}/>
                    </div>
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange}/>
                    </div>
                </div>
                <div className="widgets__selector">
                    <Select value={this.state.speed_violations}
                            onChange={this.onChange.bind(this, 'speed_violations')}
                            clearable={false} options={[
                        {
                            value: 'units',
                            label: 'Предприятия',
                        },
                        {
                            value: 'drivers',
                            label: 'Водители',
                        },
                    ]}/>
                </div>
                <div className="chart">
                    {this.renderUnitSpeedViolationsChart()}
                </div>
            </div>
        );
    }

    renderUnitSpeedViolationsChart() {
        if (this.state.speed_violations === 'units') {
            const rows = _.slice(_.orderBy(this.state.data.units || [], ['speed_violations'], ['desc']), 0, 10);
            const data = _.map(rows, (row) => {
                return {
                    name: row.unit_name,
                    value: row.speed_violations,
                    fill: this.getCountFill(row.speed_violations, 'speed_violations'),
                    radius: [4, 4, 0, 0],
                };
            });

            return (
                <ResponsiveContainer>
                    <BarChart data={data} margin={{bottom: 100, top: 20}}>
                        <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" fontSize={10}
                               tick={props => <Text {...props} width={130}>{props.payload.value}</Text>}/>
                        <YAxis dataKey="value"
                               label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle"
                                                     angle={-90}>Количество</Text>}/>
                        <Tooltip/>
                        <Bar dataKey="value" fill="#57AF7D" name="Количество нарушений скоростного режима" barSize={50} isAnimationActive={false}>
                            <LabelList dataKey="value" position="top" fill="#666" formatter={(value) => {
                                return value ? value : null;
                            }}/>
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            );
        } else {
            const data = _.map(this.state.data2, (row) => {
                const user = _.get(this.state.users, row.driver_uuid);
                const vehicle = _.get(this.state.vehicles, row.vehicle_uuid);

                return {
                    name: user ? new User(user).getFullName() : null,
                    unit: (vehicle && vehicle.unit_uuid) ? _.get(this.state.units, vehicle.unit_uuid) : null,
                    state_number: _.get(vehicle, 'state_number'),
                    vehicle_mark: (vehicle && vehicle.vehicle_mark_uuid) ? this.state.related.getReact(vehicle.vehicle_mark_uuid) : null,
                    vehicle_model: (vehicle && vehicle.vehicle_model_uuid) ? this.state.related.getReact(vehicle.vehicle_model_uuid) : null,
                    value: row.volume,
                    fill: '#F65C50',
                    radius: [4, 4, 0, 0],
                };
            });

            return (
                <ResponsiveContainer>
                    <BarChart data={data} margin={{bottom: 100}}>
                        <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" fontSize={10}
                               tick={props => <Text {...props} width={130}>{props.payload.value}</Text>}/>
                        <YAxis dataKey="value"
                               label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle"
                                                     angle={-90}>Превышение на, км/ч</Text>}/>
                        <Tooltip content={<CustomTooltip/>}/>
                        <Bar dataKey="value" fill="#57AF7D" name="Превышение скоростного режима" barSize={50} isAnimationActive={false}>
                            <LabelList dataKey="value" position="insideTop" fill="#fff" formatter={(value) => {
                                return value ? value : null;
                            }}/>
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            );
        }
    }

    async onSelectedUnitsChange(param) {
        let data = this.state.data;
        _.each(param, (p) => {
            let index = p.index;
            let checked = p.checked;

            data.organizationUnits[index].checked = checked;
        });
        await this.setState({data});
        this.loadDriversData();
    }

    onChange(field, e) {
        const value = e.value || e.target.value;

        let state = this.state;
        state[field] = value;
        this.setState(state);
    }

    render() {
        const loader = this.state.isLoading ? <GlobalLoaderComponent/> : null;

        return (
            <div className="kiutr-widget">
                {loader}
                {this.renderContent()}
            </div>
        );
    }

    getFill(value, field) {
        let green = 80;
        let orange = 50;

        if (field === 'vehicles_bnso') {
            green = 99;
            orange = 80;
        }

        if (value >= green) {
            return '#95B400';
        }
        if (value >= orange) {
            return '#F4A44B';
        }
        return '#F65C50';
    }

    getCountFill(value, field, invert = true) {
        if (value > 0) {
            return '#F65C50';
        } else {
            return '#95B400';
        }
    }
}

const CustomTooltip = React.createClass({
    propTypes: {
        type: PropTypes.string,
        payload: PropTypes.array,
        label: PropTypes.string,
    },

    render() {
        const {active, wrapperStyle} = this.props;

        const finalStyle = {
            margin: 0,
            padding: 10,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            whiteSpace: 'nowrap',
            ...wrapperStyle,
        };

        if (active) {
            const {payload, label} = this.props;

            if (!payload) {
                return null;
            }

            return (
                <div className="recharts-default-tooltip" style={finalStyle}>
                    <p className="recharts-tooltip-label">{`Превышение на: ${payload[0].value} км/ч`}</p>
                    <p>ТС: {payload[0].payload.state_number}</p>
                    <p>Предприятие: {_.get(payload[0].payload.unit, 'name')}</p>
                    <p>Водитель: {payload[0].payload.name}</p>
                    <p>ТС: марка, модель: {payload[0].payload.vehicle_mark}, {payload[0].payload.vehicle_model}</p>
                </div>
            );
        }

        return null;
    }
});