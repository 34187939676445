import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import GlobalLoaderComponent from "components/ui/global-loader";
import _ from 'lodash';
import {connect} from "react-redux";
import moment from "moment";
import {getTabletKiutrSummary, getTabletSummary} from "store/reducers/reports/tablet";
import Datepicker from "components/ui/form/datepicker";
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Text,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import formats from "dictionaries/formats";
import DateTick from "components/modules/analytics/base-components/charts/DateTick/index";

@propTypes({
    barChartFillColor: PropTypes.string,
    component: PropTypes.string,
})

@defaultProps({
    barChartFillColor: '',
    component: 'kiutr',
})

@connect(state => ({}), {getTabletKiutrSummary})

export default class ZoneViolationsExitCount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isError: false,
            isLoading: false,
            from: moment().subtract(1, 'day').format(formats.DATE_API),
            to: moment().subtract(1, 'day').format(formats.DATE_API),
            data: {},
        };
    }

    componentWillMount() {
        this.loadData();
    }


    async componentWillReceiveProps(nextProps) {
        await this.setState({
            municipality: nextProps.municipality
        })
        this.loadData();
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value});
        this.loadData();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value});
        this.loadData();
    };

    async loadData() {
        this.setState({isLoading: true});

        let summaryOptions = {
            date_from: moment(this.state.from).format(formats.DATE_API),
            date_to: moment(this.state.to).format(formats.DATE_API),
            component: this.props.component,
            with_communal_municipalities: this.props.municipality
        };

        if (!window.RNIS_SETTINGS.analytic_filter_for_municipality) {
            delete summaryOptions.with_communal_municipalities
        }

        const response = await this.props.getTabletKiutrSummary(summaryOptions);
        this.setState({isLoading: false});

        if (response.isOk) {
            this.setState({
                data: response.payload,
            });

        } else {
            response.showErrors();
        }
    }

    get(field, forDisplay = true) {
        const value = _.get(this.state.data, field, null);
        if (forDisplay) {
            if (value !== null) {
                return _.round(value, 2);
            } else {
                return '-';
            }
        }
        return value || 0;
    }

    renderContent() {
        return (
            <div className="full-size kiutr-widget widget-block_speed-violation">
                <div className="date-wrapper">
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange}/>
                    </div>
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange}/>
                    </div>
                </div>
                <div className="chart">
                    {this.renderSpeedViolationsChart()}
                </div>
            </div>
        );
    }

    renderSpeedViolationsChart() {
        let rows = this.state.data.zone_violations || [];
        const data = _.map(rows, (row) => {
            return {
                name: moment(row.date).format(formats.DATE),
                value: row.count,
                label: row.count || '',
            };
        });
        return (
            <ResponsiveContainer>
                <AreaChart data={data} margin={{bottom: 50}}>
                    <XAxis dataKey="name" tick={<DateTick/>} interval="preserveStartEnd"/>
                    <YAxis dataKey="value" label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle" angle={-90}>Количество</Text>}/>
                    <Tooltip/>
                    <CartesianGrid horizontal={false}/>
                    <Area dataKey="value" fill="rgba(87, 175, 125, 0.5)" strokeWidth={3} r={5}  name="Количество выходов из геозоны" stroke="#57AF7D" type="monotone"/>
                </AreaChart>
            </ResponsiveContainer>
        );
    }

    async onSelectedUnitsChange(param) {
        let data = this.state.data;
        _.each(param, (p) => {
            let index = p.index;
            let checked = p.checked;

            data.organizationUnits[index].checked = checked;
        });
        await this.setState({data});
        this.loadDriversData();
    }

    onChange(field, e) {
        const value = e.value || e.target.value;

        let state = this.state;
        state[field] = value;
        this.setState(state);
    }

    render() {
        const loader = this.state.isLoading ? <GlobalLoaderComponent/> : null;

        return (
            <div className="kiutr-widget line">
                {loader}
                {this.renderContent()}
            </div>
        );
    }

    getFill(value, field) {
        let green = 80;
        let orange = 50;

        if (field === 'vehicles_bnso') {
            green = 99;
            orange = 80;
        }

        if (value >= green) {
            return '#95B400';
        }
        if (value >= orange) {
            return '#F4A44B';
        }
        return '#F65C50';
    }
}