import {rpc} from './session';

export async function convertHtmlToXls(content) {
    return await rpc.request('com.rnis.converter.action.convert.html_to_xls', {
        content,
    });
}

export async function convertPngToPdf(content) {
    return await rpc.request('com.rnis.converter.action.convert.png_to_pdf', {
        content,
    });
}
