import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import classNames from 'classnames';
import _, { reduce } from 'lodash';
import renderHTML from 'react-render-html';

import L from 'leaflet';
import './layers.less';
import { connect } from 'react-redux';
import { getLayers, getObjects, toggleLayer, deactivateLayer } from 'store/reducers/user-map-objects/layers';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import StopPointsMtaLayer from 'components/ui/map/layers/stop-points-mta';
import RailroadCrossLayer from 'components/ui/map/layers/railroad-cross';
import StopPointsInventarisationLayer from 'components/ui/map/layers/stop-points-inventarisation';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LayerEditor from 'components/redux-forms/LayerEditor';
import { LabeledCheckbox } from 'components/ui/checkbox';
import ContextTooltip from 'components/ui/context-tooltip';
import KursRoadPartsLayer from 'components/ui/map/layers/kurs-road-parts';
import GarbageObjectsLayer from 'components/ui/map/layers/garbage-objects';
import StopPointsChildrenLayer from 'components/ui/map/layers/stop-points-children';
import KursRoadsLayer from 'components/ui/map/layers/kurs-roads';
import KursRoadRepairPartsLayer from 'components/ui/map/layers/kurs-road-repair-parts';
import { getRoad, getRoadPart, getRoadRepairPart } from '../../store/reducers/maps/thunk';
import { getComponentSelector, getFiltersSelector } from '../../store/reducers/maps/selectors';
import { setFilters } from '../../store/reducers/maps/actions';

@propTypes({
	layers: PropTypes.array,
	map: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.instanceOf(null)]),
	parent: PropTypes.object.isRequired,
	onStopPointClick: PropTypes.func,
	onStopPointDblClick: PropTypes.func,
	withoutStopPoints: PropTypes.array,
	filters: PropTypes.object
})
@defaultProps({
	layers: [],
	onStopPointClick: null,
	onStopPointDblClick: () => {},
	withoutStopPoints: [],
	filters: {}
})
@connect(
	(state) => ({
		user_objects_layers: state.user_map_objects.layers.get('layers'),
		objects: state.user_map_objects.layers.get('objects'),
		activeLayers: state.user_map_objects.layers.get('activeLayers'),
		routes: state.routes.get('routes'),
		component: getComponentSelector(state),
		filters: getFiltersSelector(state),
	}),
	(dispatch) => ({
		onGetLayers: (meta) => dispatch(getLayers(meta)),
		onGetObjects: (layers, meta, zoom, availableFilters) => dispatch(getObjects(layers, meta, zoom, availableFilters)),
		onToggleLayer: (layerUuid) => dispatch(toggleLayer(layerUuid)),
		onDeactivateLayer: (layerUuid) => dispatch(deactivateLayer(layerUuid)),
		onRoadClick: (uuid) => dispatch(getRoad(uuid)),
		onRoadPartClick: (uuid) => dispatch(getRoadPart(uuid)),
		onRoadRepairPartClick: (uuid) => dispatch(getRoadRepairPart(uuid)),
		onSetFilters: (filters) => dispatch(setFilters(filters)),
	})
)
export default class Layers extends Component {
	state = {
		availableFilters: [],
		availableFiltersReady: false,
		opened: false,
		showEditor: false,
		search: '',
	};

	decoratorLayer = null;

	constructor(...args) {
		super(...args);

		this.onClickDocument = ::this._onClickDocument;
	}

	componentWillUnmount() {
		$(document).off('click', this.onClickDocument);
	}

	_onClickDocument(e) {
		if (!$(e.target).hasClass('LayersBlock') && $(e.target).parents('.LayersBlock').length === 0) {
			this.closeEditor();
		}
	}

	async componentDidMount() {
		$(document).on('click', this.onClickDocument);
		if (this.props.component === 'garbage' || !this.props.component) {
			// remove TKO layers if change from road to garbage
			// TODO: nees refactor whole layer filter system
			this.props.onDeactivateLayer('4b886e60-d698-11e7-b20b-6328f8d911fc');
		}
		await Promise.all([
			this.props.onGetLayers({
				filters: {
					onlyVisible: true
				}
			})
		]);

		this.decoratorLayer = new L.layerGroup().addTo(this.props.map);
		this.getContent();
		this.props.displayUserObjects && this.renderUserObjectsLayers();


		this.props.map.on('moveend', ::this.loadObjects);
		//this.loadObjects()
		this.checkAllLayers();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.component !== this.props.component) {
			this.refreshAvailableFilters()
			this.checkAllLayers();
		}
	}

	refreshAvailableFilters() {
		setTimeout(() => {
			const update = true;
			this.getContent(update);
			this.props.displayUserObjects && this.renderUserObjectsLayers(update);
		}, 300);
	}

	async loadObjects() {
		this.props.onGetObjects(this.getActiveLayers(), this.getMetaForObjects(), this.props.parent.getZoom(), this.state.availableFilters);
	}

	toggleEditor() {
		this.setState({ showEditor: !this.state.showEditor });
	}

	closeEditor() {
		this.setState({
			opened: false
		});
	}

	changeSearch() {
		const search = this.refs.search.value;
		this.setState({
			search
		});
	}

	getContent(update) {
		const layers = this.props.layers.map(::this.renderLayerItem);
		const stopPoints = this.renderStopPointsItem();
		const result = [...layers, ...stopPoints];
		if (this.state.availableFilters.length === 0 || update) {
			if (Array.isArray(result) && result.length) {
				this.setState({
					availableFilters: result.map(el => el && el.key)
				})
			} else {
				this.setState({
					availableFilters: []
				})
			}
		}
		return result;
	}

	render() {
		const { toggleEditor } = this.props;
		if (!this.props.map) return null;
		return (
			<div className="LayersBlock Layers">
				<ContextTooltip key="map.layers" code="map.layers" default="Объекты" position="top">
					<div className="Layers-btn" onClick={::this.toggleBlock} />
				</ContextTooltip>
				{this.state.opened ? (
					<div className="popup-container dropdown-slider bottom left SelectFieldsPopup">
						<div className="filtration__search">
							<input
								ref="search"
								type="text"
								className="filtration__pole"
								placeholder="Поиск"
								value={this.state.search}
								onChange={::this.changeSearch}
							/>
							<input type="button" className="filtration__button" value="" />
						</div>
						<div className="content">
							{this.getContent()}
							{this.props.displayUserObjects ? this.renderUserObjectsLayers() : null}
						</div>
					</div>
				) : null}
				{_.map(this.props.activeLayers, ::this.renderActiveLayer)}
			</div>
		);
	}

	renderActiveLayer(visibility, layerUuid) {
		if (!visibility) {
			return null;
		}

		const layers = this.getLayersV2();
		const layer = _.find(layers, { uuid: layerUuid });
		if (!layer) {
			return null;
		}

		return <div key={layerUuid}>{layer.class}</div>;
	}

	renderUserObjectsLayers(update) {
		const result = this.props.user_objects_layers.map(::this.renderLayerItem).filter(el => el && el.key);
		if (this.state.availableFiltersReady === false || update) {
			const keys = result.map(el => el.key)
			this.setState(prevState => ({
			availableFiltersReady: true,
			availableFilters: [...prevState.availableFilters, ...keys]
			}))
		}
		return (
			<div>
				<div className="checkbox-labeled">
					<span>Прочие</span>
				</div>
				{result}
			</div>
		);
	}

	renderLayerItem(layer) {
		if (this.props.layersFilter && !this.props.layersFilter(layer)) {
			return null;
		}
		if (this.state.search && layer.title.toLowerCase().indexOf(this.state.search.toLowerCase()) === -1) {
			return;
		}

		return (
			<LabeledCheckbox
				key={layer.uuid}
				label={layer.title}
				checked={this.props.activeLayers[layer.uuid]}
				onChange={this.onLayerCheckboxClick.bind(this, layer)}
			/>
		);
	}

	renderStopPointsItem() {
		if (this.props.component === 'control') {
			return [
				this.renderLayerItem({
					uuid: 'road_parts',
					title: 'Типы автомобильных дорог',
					version: 2
				})
			];
		} else if (
			this.props.component === 'road' ||
			this.props.component === 'communal' ||
			this.props.component === 'commdept' ||
			(window.RNIS_SETTINGS.CITY_TULA && this.props.component === 'utility')
		) {
			return [
				this.renderLayerItem({
					uuid: 'roads',
					title: 'Дороги',
					version: 2
				}),
				this.renderLayerItem({
					uuid: 'road_parts',
					title: 'Участки дорог',
					version: 2
				}),
				this.renderLayerItem({
					uuid: 'road_repair_parts',
					title: 'Участки ремонта дорог',
					version: 2
				}),
				this.renderLayerItem({
					uuid: 'stop_points_mta',
					title: 'Остановки',
					version: 2
				})
			];
		} else if (this.props.component === 'garbage') {
			return [
				this.renderLayerItem({
					uuid: 'stop_points_mta',
					title: 'Остановки "КиУТР"',
					version: 2
				}),
				this.renderLayerItem({
					uuid: 'stop_points_children',
					title: 'Остановки "Перевозка детей"',
					version: 2
				}),
				/*this.renderLayerItem({
                    uuid: 'road_parts',
                    title: 'Типы автомобильных дорог',
                    version: 2,
                }),*/
				this.renderLayerItem({
					uuid: 'garbage_objects_kp',
					title: 'Контейнерные площадки',
					version: 2
				}),
				this.renderLayerItem({
					uuid: 'garbage_objects_op',
					title: 'Объекты перегрузки',
					version: 2
				}),
				this.renderLayerItem({
					uuid: 'garbage_objects_opp',
					title: 'Объекты размещения',
					version: 2
				}),
				this.renderLayerItem({
					uuid: 'garbage_objects_os',
					title: 'Объекты сортировки',
					version: 2
				})
			];
		} else if (this.props.component === 'analytics') {
			return [
				this.renderLayerItem({
					uuid: 'kiutr',
					title: 'Пассажирские перевозки',
					version: 2
				}),
				this.renderLayerItem({
					uuid: 'road',
					title: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text,
					version: 2
				}),
				this.renderLayerItem({
					uuid: 'garbage_objects_kp',
					title: 'Контейнерные площадки',
					version: 2
				}),
				this.renderLayerItem({
					uuid: 'children',
					title: 'Перевозки детей',
					version: 2
				}),
				this.renderLayerItem({
					uuid: 'garbage',
					title: 'Вывоз мусора',
					version: 2
				}),
				this.renderLayerItem({
					uuid: 'control',
					title: 'ГУ ГАТН',
					version: 2
				}),
				this.renderLayerItem({
					uuid: 'communal',
					title: 'Комунальная техника',
					version: 2
				})
			];
		} else {
			const layersItems = [this.renderLayerItem({
				uuid: 'stop_points_mta',
				title: 'Остановки "КиУТР"',
				version: 2
			}),
			this.renderLayerItem({
				uuid: 'stop_points_children',
				title: 'Остановки "Перевозка детей"',
				version: 2
			})];
			if (window.RNIS_SETTINGS.RAILROADCROSSING) {
				layersItems.push(this.renderLayerItem({
					uuid: 'railroad_crossing',
					title: 'Железнодорожные переезды',
					version: 2
				}))
			}
			return layersItems
		}
	}

	checkAllLayers() {
		this.props.onGetObjects(this.getActiveLayers(), this.getMetaForObjects(), this.props.parent.getZoom(), this.state.availableFilters);
	}

	changeLayerCallback = (layer) => {
		let filters = JSON.parse(JSON.stringify(this.props.filters));

		switch (layer.uuid) {
			case 'road_parts':
				if (this.props.user_objects_layers.road_parts) {
					_.set(filters, 'base.road_parts.units', []);
				}
				if (!this.props.user_objects_layers.road_parts && filters.base.common) {
					_.set(filters, 'base.road_parts.units', filters.base.common.units);
				}
				break;
			case 'vehicles':
				if (this.props.user_objects_layers.vehicles) {
					_.set(filters, 'base.vehicle.owners', []);
				}
				if (!this.props.user_objects_layers.vehicles && filters.base.common) {
					_.set(filters, 'base.vehicle.owners', filters.base.common.units);
				}
				break;
		}
		this.props.onSetFilters(filters);
	}

	async onLayerCheckboxClick(layer) {
		if (window.RNIS_SETTINGS.road_map_filter_by_units_for_road_parts) {
			this.changeLayerCallback(layer);
		}
		await this.props.onToggleLayer(layer.uuid);
		if (layer.layer) {
			if (this.props.activeLayers[layer.uuid]) {
				this.props.map.addLayer(layer.layer);
				if (layer.decorator) {
					this.decoratorLayer.addLayer(layer.decorator);
				}
			} else {
				this.props.map.removeLayer(layer.layer);
				if (layer.decorator) {
					this.decoratorLayer.removeLayer(layer.decorator);
				}
			}
		} else if (!layer.version || layer.version < 2) {
			this.props.onGetObjects(this.getActiveLayers(), this.getMetaForObjects(), this.props.parent.getZoom(), this.state.availableFilters);
		}
	}

	getMetaForObjects() {
		const boundingBox = this.props.parent.getBoundingBox();

		return {
			filters: {
				withBoundingBox: boundingBox
			}
		};
	}

	getActiveLayers() {
		return _.keys(
			_.pickBy(this.props.activeLayers, (item, key) => item && _.find(this.props.user_objects_layers, { uuid: key }))
		);
	}

	toggleBlock() {
		this.setState({
			opened: !this.state.opened
		});
		this.refreshAvailableFilters();
	}

	getLayersV2() {
		if (this.props.component === 'control') {
			return [
				{
					uuid: 'road_parts',
					title: 'Типы автомобильных дорог',
					class: (
						<KursRoadPartsLayer
							leafletMap={this.props.map}
							map={this.props.parent}
							onClick={this.props.onRoadPartClick}
							filters={this.props.filters}
						/>
					)
				}
			];
		} else if (this.props.component === 'road' || this.props.component === 'commdept' || (window.RNIS_SETTINGS.CITY_TULA && this.props.component === 'utility')) {
			return [
				{
					uuid: 'road_parts',
					title: 'Типы автомобильных дорог',
					class: (
						<KursRoadPartsLayer
							leafletMap={this.props.map}
							map={this.props.parent}
							onClick={this.props.onRoadPartClick}
							filters={this.props.filters}
						/>
					)
				},
				{
					uuid: 'road_repair_parts',
					title: 'Участки ремонта дорог',
					class: (
						<KursRoadRepairPartsLayer
							leafletMap={this.props.map}
							map={this.props.parent}
							onClick={this.props.onRoadRepairPartClick}
						/>
					)
				},
				{
					uuid: 'roads',
					title: 'Дороги',
					class: <KursRoadsLayer leafletMap={this.props.map} map={this.props.parent} onClick={this.props.onRoadClick} />
				},
				{
					uuid: 'stop_points_mta',
					title: 'Остановки',
					class: (
						<StopPointsMtaLayer
							leafletMap={this.props.map}
							map={this.props.parent}
							withoutEdit={true}
							onStopPointClick={this.props.onStopPointClick}
							onStopPointDblClick={this.props.onStopPointDblClick}
							onStopPointForwardAddClick={this.props.onStopPointForwardAddClick}
							onStopPointReverseAddClick={this.props.onStopPointReverseAddClick}
						/>
					)
				}
			];
		} else if (this.props.component === 'garbage') {
			return [
				{
					uuid: 'stop_points_mta',
					title: 'Остановки "КиУТР"',
					class: (
						<StopPointsMtaLayer
							leafletMap={this.props.map}
							map={this.props.parent}
							onStopPointClick={this.props.onStopPointClick}
							onStopPointDblClick={this.props.onStopPointDblClick}
							onStopPointForwardAddClick={this.props.onStopPointForwardAddClick}
							onStopPointReverseAddClick={this.props.onStopPointReverseAddClick}
						/>
					)
				},
				{
					uuid: 'stop_points_children',
					title: 'Остановки "Перевозка детей"',
					class: (
						<StopPointsChildrenLayer
							leafletMap={this.props.map}
							map={this.props.parent}
							onStopPointClick={this.props.onStopPointClick}
							onStopPointDblClick={this.props.onStopPointDblClick}
							onStopPointForwardAddClick={this.props.onStopPointForwardAddClick}
							onStopPointReverseAddClick={this.props.onStopPointReverseAddClick}
						/>
					)
				},
				{
					uuid: 'garbage_objects_kp',
					title: 'Контейнерные площадки',
					class: <GarbageObjectsLayer leafletMap={this.props.map} map={this.props.parent} type="kp" />
				},
				{
					uuid: 'garbage_objects_op',
					title: 'Объекты перегрузки',
					class: <GarbageObjectsLayer leafletMap={this.props.map} map={this.props.parent} type="op" />
				},
				{
					uuid: 'garbage_objects_opp',
					title: 'Объекты размещения',
					class: <GarbageObjectsLayer leafletMap={this.props.map} map={this.props.parent} type="opp" />
				},
				{
					uuid: 'garbage_objects_os',
					title: 'Объекты сортировки',
					class: <GarbageObjectsLayer leafletMap={this.props.map} map={this.props.parent} type="os" />
				}
			];
		} else {
			const layersComponents = [{
				uuid: 'stop_points_mta',
				title: 'Остановки "КиУТР"',
				class: (
					<StopPointsMtaLayer
						leafletMap={this.props.map}
						map={this.props.parent}
						onStopPointClick={this.props.onStopPointClick}
						onStopPointDblClick={this.props.onStopPointDblClick}
						onStopPointForwardAddClick={this.props.onStopPointForwardAddClick}
						onStopPointReverseAddClick={this.props.onStopPointReverseAddClick}
						setSelectedRoutes={this.props.setSelectedRoutes}
					/>
				)
			},
			{
				uuid: 'stop_points_children',
				title: 'Остановки "Перевозка детей"',
				class: (
					<StopPointsChildrenLayer
						leafletMap={this.props.map}
						map={this.props.parent}
						onStopPointClick={this.props.onStopPointClick}
						onStopPointDblClick={this.props.onStopPointDblClick}
						onStopPointForwardAddClick={this.props.onStopPointForwardAddClick}
						onStopPointReverseAddClick={this.props.onStopPointReverseAddClick}
					/>
				)
			}]
			if (window.RNIS_SETTINGS.RAILROADCROSSING) {
				layersComponents.push({
					uuid: 'railroad_crossing',
					title: 'Железнодорожные переезды',
					class: (
						<RailroadCrossLayer
							leafletMap={this.props.map}
							map={this.props.parent}
							onRailroadCrossClick={this.props.onStopPointClick}
							//onStopPointDblClick={this.props.onStopPointDblClick}
							//onStopPointForwardAddClick={this.props.onStopPointForwardAddClick}
							//onStopPointReverseAddClick={this.props.onStopPointReverseAddClick}
							//setSelectedRoutes={this.props.setSelectedRoutes}
						/>
					)
				})
			}
			return layersComponents
		}
	}
}
