import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import _ from 'lodash';

import BaseTableComponent from 'components/base/base_table';
import Page from 'components/ui/page';
import IconButton from 'components/ui/icon-button';

import {mapDatatablesRequestToMeta} from 'helpers/api';

import './roles.less';
import * as alerts from "helpers/alerts";
import TableContainer from "components/ui/Table/Container/TableContainer";
import Column from "components/ui/column";
import AppearTransition from "components/ui/transitions/appear";
import {deleteRole, getRoles} from "store/reducers/roles/roles";
import RolesEditor from "./editor";

import currentUser from 'helpers/current-user';
import systems from "dictionaries/systems";
import ContextTooltip from "components/ui/context-tooltip";
import GlobalLoaderComponent from "components/ui/global-loader";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import ReactDOMServer from 'react-dom/server';
import {State} from "components/ui/state";
import {getUnits} from "store/reducers/organizational_units/units";
import {component_mapper} from "helpers/component_mapper";

@connect(state => ({}), {getRoles, deleteRole, getEntityNames, getUnits})

export default class RolesComponent extends BaseTableComponent {

    useCache = false;

    accessTypes = {
        unit: 'Свое предприятие',
        unit_with_children: 'Свое и дочерние',
        all: 'Все предприятия',
    };

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            roles: {},
            columns: List(this.getColumns(props)),
            editorUuid: null,
            showEditorModal: false,
            component: null,
            refresh: false,
            related: new EntityList(),
        });
    }

    showAudit(rowData) {
        this.props.router.push(`/system/audit/${rowData.uuid}?class=App\\Model\\Role`)
    }

    async componentWillUpdate(props, state) {
        if (props.params.component !== state.component) {
            await this.setState({
                component: props.params.component,
                refresh: true,
                columns: List(this.getColumns(props)),
            });
            state.component && this.reload();
            this.setState({
                refresh: false,
            });
        }
    }

    componentDidMount() {
        this.forceUpdate();
    }

    render() {
        let title = '';
        
        if (this.state.component === 'operator') {
            title = `${window.RNIS_SETTINGS.operator_title || systems[this.state.component]} → Права доступа`;
        } else {
            title = `${systems[this.state.component]} → Права доступа`;
        }
        if (this.state.refresh) {
            return (
                <Page title={title}
                      pageId="BaseTableWithEditor"
                      headerActions={this.renderHeaderActions()}
                      headerContents={this.renderHeaderContents()}>
                    <GlobalLoaderComponent/>
                </Page>
            );
        }

        let table = this.renderTable();

        const editor = this.state.showEditorModal
            ? <RolesEditor
                {...this.props}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                onEditClick={::this.onEditorEditClick}
                onDeleteClick={::this.onEditorDeleteClick}
                role={_.get(this.state.roles, this.state.editorUuid, {})}
            />
            : '';

        return (
            <Page title={title}
                  pageId="Roles"
                  headerActions={this.renderHeaderActions()}
                  headerContents={this.renderHeaderContents()}>

                <TableContainer>
                    {table}
                </TableContainer>

                <AppearTransition>
                    {editor}
                </AppearTransition>
            </Page>
        );
    }

    getColumns(props = null) {
        props = props || this.props;

         

        if (props.params.component === 'operator') {
            const showedSystems = {}
            Object.keys(systems).forEach((systemKey) => {
                if (!window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[systemKey]) {
                    showedSystems[systemKey] = systems[systemKey]
                } else if (!window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[systemKey].hideForRoles) {
                    showedSystems[systemKey] = window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[systemKey].name || systems[systemKey]
                }
            })

            return this.prepareColumns([

                new Column('Наименование роли')
                    .fromField('name'),

                new Column('Подсистема')
                    .fromField('component')
                    .withDrawer((item) => _.map(item.components || [], component => showedSystems[component] || '').join('<br/>'))
                    .denyColumnFilter()
                    .withFilter('withComponent', async () => {
                        return _.map(showedSystems, (title, system) => ({
                            uuid: system,
                            name: title,
                        }));
                    }),

                new Column('Предприятие')
                    .fromField('unit_uuid')
                    .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid))
                    .denyColumnFilter()
                    .withFilter('withUnits', async () => {
                        const response = await this.props.getUnits({
                            pagination: {
                                page: 1,
                                limit: 1000,
                            },
                            filters: {
                                withComponent: this.props.params.component,
                            },
                            response_data: [
                                'items/uuid',
                                'items/name',
                            ],
                        });
                        if (response.isOk) {
                            return response.payload.items;
                        }
                        return {};
                    }),

                new Column('Системная')
                    .denyOrder()
                    .denyColumnFilter()
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_system}/>)),

                new Column('Доступ к объектам')
                    .denyOrder()
                    .denyColumnFilter()
                    .withDrawer(item => _.get(this.accessTypes, item.access_type)),

            ]);
        } else {
            return this.prepareColumns([

                new Column('Наименование роли')
                    .fromField('name'),

                new Column('Предприятие')
                    .fromField('unit_uuid')
                    .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid))
                    .denyColumnFilter()
                    .withFilter('withUnits', async () => {
                        const response = await this.props.getUnits({
                            pagination: {
                                page: 1,
                                limit: 1000,
                            },
                            filters: {
                                withComponent: this.props.params.component,
                            },
                            response_data: [
                                'items/uuid',
                                'items/name',
                            ],
                        });
                        if (response.isOk) {
                            return response.payload.items;
                        }
                        return {};
                    }),

                new Column('Системная')
                    .denyOrder()
                    .denyColumnFilter()
                    .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_system}/>)),

                new Column('Доступ к объектам')
                    .denyOrder()
                    .denyColumnFilter()
                    .withDrawer(item => _.get(this.accessTypes, item.access_type)),

            ]);
        }
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const units = _.filter(_.map(result, 'unit_uuid'));
        const response = await this.props.getEntityNames(_.map(units, (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        })));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }

        return result;
    }

    async closeEditor() {
        await this.setState({
            editorUuid: null,
            showEditorModal: false,
        });
    }

    async submitEditor() {
        this.reload();
        await this.closeEditor();
    }

    async showEditor() {
        this.showEditorWithUuid();
    }

    async onEditorEditClick() {
        const uuid = this.state.editorUuid;
        await this.closeEditor();
        return this.showEditorWithUuid(uuid);
    }

    async onEditorDeleteClick() {
        return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            await this.props.deleteRole({
                uuid: this.state.editorUuid,
            });

            await this.closeEditor();
            this.reload();
        });
    }

    async showEditorWithUuid(uuid = null) {
        await this.closeEditor();

        this.setState({
            editorUuid: uuid,
            showEditorModal: true,
        });
    }

    onDblClick(data) {
        if (data.uuid && currentUser.can('com.rnis.auth.permission.role', 'update', this.props.params.component)) {
            this.showEditorWithUuid(data.uuid);
        }
    }

    async editSelected() {
        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            this.showEditorWithUuid(selected.uuid);
        }
    }

    async deleteSelected() {
        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
                await this.props.deleteRole({
                    uuid: selected.uuid,
                });
                this.reload();
            });
        }
    }

    async loadCallback(request, drawCallback, settings) {
        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        if (this.state.component !== 'operator' && !_.get(meta, 'filters.withComponent')) {
            meta.filters.withComponent = component_mapper(this.state.component, {
                'ems': 'kiutr'
            });
        }
        meta.order.column === 'component' ? meta.order.column = 'components' : null;

        const response = await this.props.getRoles(meta);

        if (response.isOk) {
            const data = response.payload.items;

            this.setState({
                roles: _.keyBy(data, 'uuid'),
            });

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: response.data.headers.meta.pagination.total,
                recordsTotal: response.data.headers.meta.pagination.total
            };

            drawCallback(json);
            this.loadRelatedEntities(json, drawCallback);

            this.selectedRowsRecalc();
            this.setState({success: true});
        } else {
            response.showErrors();
        }
    }

    getCreateButton() {
        if (!currentUser.can('com.rnis.auth.permission.role', 'create', this.props.params.component)) {
            return null;
        }
        return (
            <ContextTooltip key="base-table-list.create" code="base-table-list.create" default="Добавить">
                <IconButton
                    icon="plus"
                    tooltip="Добавить"
                    onClick={::this.showEditor}
                    active={!!(this.state.showEditorModal && !this.state.editorUuid)}
                    className="popup-link"
                />
            </ContextTooltip>
        );
    }

    renderHeaderActions() {
        return [
            currentUser.can('com.rnis.auth.permission.role', 'delete', this.props.params.component) ? (
                <ContextTooltip key="base-table-list.delete" code="base-table-list.delete" default="Удалить">
                    <IconButton icon="basket" disabled={this.state.selectedRowsCount === 0}
                                onDisabledClick={::this.alertToSelect}
                                onClick={::this.deleteSelected}/>
                </ContextTooltip>
            ) : null,
        ];
    }
}
