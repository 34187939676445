import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getStoWorks = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.kurs.getStoWorks(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getStoWork = (uuid) => async (dispatch) => {
    try {
        const response = await api.kurs.getStoWork(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createStoWork = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.createStoWork(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateStoWork = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.updateStoWork(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteStoWork = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.deleteStoWork(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};