import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import classNames from 'classnames';

import ReactDOMServer from 'react-dom/server';
import Loader from 'components/ui/loader';
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import * as alerts from "helpers/alerts";
import PageModal from 'components/ui/page-modal';
import {deleteRouteVariant, getRouteVariants} from "store/reducers/routes/route_variants";
import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import TableContainer from "components/ui/Table/Container/TableContainer";
import VariantPointsEditor from "components/modules/kiutr/routes/variant_points_editor";
import KiutrIntervalMapsComponent from "components/modules/kiutr/routes/interval_maps";
import KiutrRouteVariantViewComponent from "components/modules/kiutr/routes/view/variant_view";
import ContextTooltip from "components/ui/context-tooltip";
import KiutrRouteVariantsPrintComponent from "components/modules/kiutr/routes/print/variants";
import {print} from "helpers/print";
import {getRoute} from "store/reducers/routes/route_editor";
import GlobalLoaderComponent from "components/ui/global-loader";
import {getStopPoints} from "store/reducers/geo/stop-points";
import {deleteRouteVariantNullRun, getRouteVariantNullRuns} from "store/reducers/kiutr/route_variant_null_run";
import RouteVariantNullRunEditor from "components/modules/kiutr/routes/RouteVariantNullRunEditor/index";
import RouteGroups from "components/modules/kiutr/routes/RouteGroups/index";
import RouteSpiral from "components/modules/kiutr/routes/RouteSpiral/index";
import TableTreeComponent from "components/ui/Table/TreeComponent/TableTreeComponent";
import currentUser from 'helpers/current-user';

@propTypes({
    routeUuid: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    turnedEditingMode: PropTypes.func,
})

@connect(state => ({}), {
    getRoute,
    getRouteVariants,
    getRouteVariantNullRuns,
    getStopPoints,
    deleteRouteVariant,
    deleteRouteVariantNullRun
})

export default class KiutrRouteVariantsComponent extends BaseTableWithEditorComponent {

    title = '';

    modelClass = 'App\\Model\\RouteVariant';
    modelSource = 'geo';

    static contextTypes = {
        resizeModals: PropTypes.func
    };

    resize() {
        this.context.resizeModals && this.context.resizeModals();
    }

    getBaseUrl() {
        return `/${this.props.params.component}/routes/${this.props.params.uuid}/variants`;
    }

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            loading: false,
            variantPointsModalData: null,
            variantPointsViewModalData: null,
            intervalMapsModalData: null,
            routeVariantNullRunModalData: null,
            routeGroupsActive: false,
            routeSpiralActive: false,
        });
    }

    async componentWillUpdate(props, state) {
        if (props.params) {
            const type = props.params.type;
            if (type === 'intervals') {
                if (!state.intervalMapsModalData || state.intervalMapsModalData.uuid !== props.params.uuid2) {
                    this.setState({
                        showEditorModal: false,
                        editorUuid: null,
                        variantPointsViewModalData: null,
                        variantPointsModalData: null,
                        routeGroupsActive: false,
                        routeSpiralActive: false,
                        intervalMapsModalData: {
                            uuid: props.params.uuid2,
                        },
                    });
                }
            } else if (type === 'map') {
                if (!state.variantPointsModalData || state.variantPointsModalData.uuid !== props.params.uuid2) {
                    this.setState({
                        showEditorModal: false,
                        editorUuid: null,
                        intervalMapsModalData: null,
                        variantPointsViewModalData: null,
                        routeGroupsActive: false,
                        routeSpiralActive: false,
                        variantPointsModalData: {
                            uuid: props.params.uuid2,
                        },
                    });
                }
            } else if (type === 'view') {
                if (!state.variantPointsViewModalData || state.variantPointsViewModalData.uuid !== props.params.uuid2) {
                    this.setState({
                        showEditorModal: false,
                        editorUuid: null,
                        intervalMapsModalData: null,
                        variantPointsModalData: null,
                        routeGroupsActive: false,
                        routeSpiralActive: false,
                        variantPointsViewModalData: {
                            uuid: props.params.uuid2,
                            routeUuid: this.props.routeUuid
                        },
                    });
                }
            } else {
                if (state.intervalMapsModalData || state.variantPointsModalData || state.variantPointsViewModalData) {
                    this.setState({
                        intervalMapsModalData: null,
                        variantPointsModalData: null,
                        variantPointsViewModalData: null,
                        routeGroupsActive: false,
                        routeSpiralActive: false,
                    });
                }
            }
        }
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Наименование')
                .fromField('name')
                .withDrawer(item => {
                    if (item.is_stop_point_list) {
                        return `<strong class="stop-point-list">${item.name}</strong>`;
                    }
                    if (item.is_default) {
                        return `<strong>${item.name}</strong>`;
                    }
                    return item.name;
                })
                .withClassName('link'),

            new Column('Комментарий')
                .fromField('comment')
                .withDrawer(item => item.comment || (item.route_variant_uuid ? (item.is_forward ? 'На маршрут' : 'С маршрута') : null)),

            ...(window.RNIS_SETTINGS.CITY_NOVOSIBIRSK ? [(new Column(<span>Общая <br/>протяженность, м</span>)
                .withDrawer(item => {
                    let f = 0, r = 0;
                    if (item.forward_points.length) {
                        f = item.forward_points.reduce( (acc, el)  => {
                            return acc + el.distance_to_the_next_point || acc + 0;
                        }, 0)
                    }
                    if (item.reverse_points.length) {
                        r = item.reverse_points.reduce( (acc, el)  => {
                            return acc + el.distance_to_the_next_point || acc + 0;
                        }, 0)
                    }
                    return f + r;
                })

                .withNumberFilter())] : []),
        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteRouteVariant(data);
    }

    async loadData(meta) {
        meta.filters.withRoute = this.props.routeUuid;
        meta.filters.appendChildrenAttribute = true;
        meta.filters.withIsStopPointList = false;
        if (currentUser.isSupervisor()) {
            meta.filters.withTrashed = true;
        }

        return await this.props.getRouteVariants(meta);
    }

    async onDblClick(data) {
        if (data && data.uuid) {
            if (data.route_variant_uuid) {
                this.showRouteVariantNullRun(data.uuid);
            } else {
                this.showVariantPoints(data);
            }
        }
    }

    onCreateVariant = async () => {
        this.showVariantPoints();
    };

    showRouteVariantNullRun(uuid = 'create') {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            if (selected.is_stop_point_list) {
                alerts.alert('Добавление маневра в "Список остановок" невозможно');
                return;
            }
            this.setState({
                routeVariantNullRunModalData: {
                    uuid,
                    routeVariantUuid: selected.route_variant_uuid || selected.uuid,
                },
            });
        } else {
            alerts.alert('Выберите вариант движения');
        }
    };

    hideRouteVariantNullRun(reload) {
        this.setState({
            routeVariantNullRunModalData: null,
        });
        if (reload) {
            this.reload();
        }
    };

    async showRouteGroups() {
        await this.props.router.push(this.getBaseUrl());
        await this.setState({
            routeGroupsActive: true,

            routeSpiralActive: false,
            showEditorModal: false,
            editorUuid: null,
            intervalMapsModalData: null,
            variantPointsModalData: null,
            variantPointsViewModalData: null,
        });

        this.resize();
    }

    async hideRouteGroups() {
        await this.setState({
            routeGroupsActive: false,
        });

        this.resize();
    }

    async showRouteSpiral() {
        await this.props.router.push(this.getBaseUrl());
        await this.setState({
            routeSpiralActive: true,

            routeGroupsActive: false,
            showEditorModal: false,
            editorUuid: null,
            intervalMapsModalData: null,
            variantPointsModalData: null,
            variantPointsViewModalData: null,
        });

        this.resize();
    }

    async hideRouteSpiral() {
        await this.setState({
            routeSpiralActive: false,
        });

        this.resize();
    }

    submitVariantPointsEditor = async (uuid) => {
        this.reload();
        if (!this.props.params.uuid) {
            return;
        }
        this.closeSubmodals();
    };

    renderTable() {
        const className = classNames({
            'show-table-filters': this.state.showTableFilters,
        }, 'indent-none');

        return (
            <div className={className}>
                <TableTreeComponent
                    ref="table"
                    className="indent-none"
                    select={this.select}
                    columns={this.state.columns}
                    loadCallback={::this.loadCallbackMiddleware}
                    onColsReordered={::this.onColsReordered}
                    onCheck={::this.selectedRowsRecalc}
                    checkCache={::this.checkCache}
                    query={this.state.query}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    onSettingsLoad={::this.onSettingsLoad}
                    onDblClick={::this.onDblClick}
                    shortPagination={true}
                    onExpand={this.onExpand}
                    onCollapse={this.onCollapse}
                    showAudit={::this.onAuditClick}
                />
            </div>
        );
    }

    async loadSuboardinationData(data, callback) {
        if (!data.uuid) {
            callback([]);
            return;
        }
        const meta = {
            filters: {
                withRouteVariant: data.uuid,
            },
        };

        const response = await this.props.getRouteVariantNullRuns(meta);

        if (response.isOk) {
            const data = response.payload.items;

            callback(data);
        } else {
            response.showErrors();
            callback([]);
        }
    }

    collapseAll() {
        this.refs.table && this.refs.table.getWrappedInstance().collapseAll();
    }

    expand(element) {
        this.refs.table && this.refs.table.getWrappedInstance().expand(element);
    }

    collapse(element) {
        this.refs.table && this.refs.table.getWrappedInstance().collapse(element);
    }

    onExpand = (data, element, index) => {
        if (!data.children) data.children = [];

        if (!data.children.length) {
            //this.collapse(element);
            const columns = this.state.columns.toArray();

            if (columns.length) {
                data.children.push({
                    [columns[0].field]: ReactDOMServer.renderToStaticMarkup(<Loader color="red" align="none"/>)
                });

                this.expand(element);
            }

            this.loadSuboardinationData(data, (suboardData) => {
                data.children = suboardData;
                this.collapse(element);
                this.expand(element);
            });
        }
        else {
            this.expand(element);
        }

    };

    onCollapse = (data, element) => {

    };

    reload() {
        if (!this.refs.table) return;

        this.refs.table.getWrappedInstance().collapseAll();
        this.refs.table.getWrappedInstance().reload();
    }

    render() {
        //const editor = this.state.showEditorModal ? this.getEditor() : '';

        const buttons = (
            <ModalTopMenuButtons>
                <ModalTopMenuList className="top-menu_modal_edit">
                    <ContextTooltip key="base-table-list.print" code="base-table-list.print" default="Печать">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_print"
                            onClick={::this.printVariants}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="route-variant.create" code="route-variant.create"
                                    default="Создание варианта движения">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_plus"
                            onClick={this.onCreateVariant}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="route-variant.create-null-run" code="route-variant.create-null-run"
                                    default="Добавить маневр">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_maneuver"
                            onClick={::this.showRouteVariantNullRun}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="route-variant.groups" code="route-variant.groups"
                                    default="Группировка остановок маршрута">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_stopping"
                            onClick={::this.showRouteGroups}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="route-variant.spiral" code="route-variant.spiral"
                                    default="Структура спирального расписания по маршруту">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_spiral"
                            onClick={::this.showRouteSpiral}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="base-editor.delete" code="base-editor.delete" default="Удалить">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_basket"
                            onClick={::this.deleteSelected}
                        />
                    </ContextTooltip>

                    <ModalTopMenuListSeparator key="separator"/>
                </ModalTopMenuList>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <div>
                <PageModal
                    header={{title: 'Варианты движения', buttons}}
                    //className={classNames("profile-modal b-modal-edit b-modal-route traffic-options-modal", (this.state.variantPointsViewModalData || this.state.intervalMapsModalData) ? '_tablet' : '')}
                    className={classNames("b-modal-edit b-modal-route traffic-options-modal")}
                    withFade={false}
                    onClose={this.props.onClose}
                >
                    {this.state.loading ? <GlobalLoaderComponent/> : null}
                    <div className="b-modal__block">
                        <TableContainer>
                            {this.renderTable()}
                        </TableContainer>
                    </div>
                </PageModal>
                {/*{this.state.showEditorModal ? (
                 <SlideLeftTransition>
                 {editor}
                 </SlideLeftTransition>
                 ) : null}*/}
                {this.state.variantPointsViewModalData ? (
                    <KiutrRouteVariantViewComponent
                        isPageWithDetect={true}
                        params={this.props.params}
                        uuid={this.state.variantPointsViewModalData.uuid}
                        routeUuid={this.state.variantPointsViewModalData.routeUuid}
                        onSubmit={this.submitVariantPointsEditor}
                        onClose={::this.closeSubmodals}
                        showIntervalMaps={::this.showIntervalMaps}
                        showVariantPointMap={::this.showVariantPointMap}
                        turnedEditingMode={this.props.turnedEditingMode}
                    />
                ) : null}
                {this.state.variantPointsModalData ? (
                    <VariantPointsEditor
                        router={this.props.router}
                        uuid={this.state.variantPointsModalData.uuid}
                        mode="edit"
                        onClose={::this.closeSubmodals}
                        onSubmit={::this.closeSubmodals}
                    />
                ) : null}
                {this.state.intervalMapsModalData ? (
                    <KiutrIntervalMapsComponent
                        {...this.props}
                        routeUuid={this.props.routeUuid}
                        routeVariantUuid={this.state.intervalMapsModalData.uuid}
                        onClose={::this.closeSubmodals}
                        className="b-modal-route"
                        isPageWithDetect={true}
                    />
                ) : null}
                {this.state.routeVariantNullRunModalData ? (
                    <RouteVariantNullRunEditor
                        routeUuid={this.props.routeUuid}
                        routeVariantUuid={this.state.routeVariantNullRunModalData.routeVariantUuid}
                        uuid={(this.state.routeVariantNullRunModalData.uuid === 'create') ? null : this.state.routeVariantNullRunModalData.uuid}
                        mode={(this.state.routeVariantNullRunModalData.uuid === 'create') ? 'add' : 'edit'}
                        onSubmit={this.hideRouteVariantNullRun.bind(this, true)}
                        onClose={this.hideRouteVariantNullRun.bind(this, false)}
                    />
                ) : null}
                {this.state.routeGroupsActive ? (
                    <RouteGroups
                        isPageWithDetect={true}
                        uuid={this.props.routeUuid}
                        mode="edit"
                        onSubmit={::this.hideRouteGroups}
                        onClose={::this.hideRouteGroups}
                        {...this.props}
                    />
                ) : null}
                {this.state.routeSpiralActive ? (
                    <RouteSpiral
                        isPageWithDetect={true}
                        uuid={this.props.routeUuid}
                        mode="edit"
                        onSubmit={::this.hideRouteSpiral}
                        onClose={::this.hideRouteSpiral}
                        {...this.props}
                    />
                ) : null}
            </div>
        );
    }

    async loadVariantsForPrint() {
        const response = await this.props.getRouteVariants({
            filters: {
                withRoute: this.props.routeUuid,
            },
        });

        if (response.isOk) {

            console.log("loadVariantsForPrint", response.payload.items)
            return response.payload.items;
        } else {
            response.showErrors();
        }
        return null;
    }

    async loadRouteForPrint() {
        const response = await this.props.getRoute(this.props.routeUuid);

        if (response.isOk) {
            return response.payload;
        } else {
            response.showErrors();
        }
        return null;
    }

    async loadStopPointsForPrint(variants) {
        const uuids = _.uniq(_.flatten(_.map(variants, (variant) => {
            return _.map(_.filter(_.concat(variant.forward_points, variant.reverse_points), {point_type: 'stop_point'}), 'type_uuid');
        })));
        const response = await this.props.getStopPoints({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            return _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'title');
        } else {
            response.showErrors();
        }
        return null;
    }

    async printVariants() {
        this.setState({loading: true});
        const route = await this.loadRouteForPrint();
        const variants = await this.loadVariantsForPrint();
        const stopPoints = await this.loadStopPointsForPrint(variants);
        this.setState({loading: false});
        if (route && variants && stopPoints) {
            const style = `
            <style>
                @page {
                    size: A4;
                }
                
                table {
                    width: 100%;
                    border-collapse: collapse;
                    text-align: center;
                }
                
                th, td {
                    border: 1px black solid !important;
                    padding: 5px;
                    page-break-inside: avoid;
                }
                
                .no-border {
                    border: none !important;
                    padding: 0 !important;
                }
                
                .route-number, .route-name, .variant-name, .variant-direction {
                    text-align: center;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border: 1px #000 solid;
                }
                
                .route-number {
                    font-weight: bold;
                    border-bottom: none;
                }
                .route-name {
                    font-style: italic;
                    color: #666;
                    margin-bottom: 10px;
                }
                .variant-name {
                    font-weight: bold;
                }
                
                .variant-name, .variant-direction {
                    border-bottom: none;
                }
            </style>
        `;

            print(style + ReactDOMServer.renderToStaticMarkup(<KiutrRouteVariantsPrintComponent
                route={route}
                variants={variants}
                stopPoints={stopPoints}
            />));
        }
    }

    async deleteSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (!selected) {
            return;
        }
        if (selected.route_variant_uuid) {
            return alerts.prompt('Вы действительно хотите удалить выбранный маневр?', '', async () => {
                const response = await this.props.deleteRouteVariantNullRun({
                    uuid: selected.uuid,
                });
                if (response.isOk) {
                    this.reload();
                } else {
                    response.showErrors();
                }
            });
        } else {
            return alerts.prompt('Вы действительно хотите удалить выбранный объект?<br/>Будут удалены интервальные карты.', '', async () => {
                const response = await this.deleteItem({
                    uuid: selected.uuid,
                });
                if (response.isOk) {
                    this.reload();
                } else {
                    response.showErrors();
                }
            });
        }
    }

    async closeSubmodals() {
        const pathname = this.props.router.getCurrentLocation().pathname;
        let matches = /\/([^/]+)\/(intervals|map)/.exec(pathname);
        if (matches) {
            const uuid = matches[1];
            await this.props.router.push(this.getBaseUrl() + '/' + uuid + '/view');
        } else {
            await this.props.router.push(this.getBaseUrl());
        }
    }

    async showIntervalMaps(uuid) {
        this.props.router.push(`${this.getBaseUrl()}/${uuid}/intervals`);
    }

    async showVariantPoints(routeVariant) {
        const uuid = routeVariant ? routeVariant.uuid : 'create';
        this.props.router.push(`${this.getBaseUrl()}/${uuid}/view`);
    }

    async showVariantPointMap(uuid) {
        this.props.router.push(`${this.getBaseUrl()}/${uuid}/map`);
    }
}
