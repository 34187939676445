import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import $ from 'jquery';

import {connect} from "react-redux";

import nl2br from 'react-nl2br';

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import classNames from 'classnames';

import GlobalLoaderComponent from "components/ui/global-loader";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import PageModal from 'components/ui/page-modal';
import TableContainer from "components/ui/Table/Container/TableContainer";
import "./variant_points_editor.less";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import ValidationError from "components/ui/validation-error";
import debounce from 'throttle-debounce/debounce';
import {getStopPoints} from "store/reducers/routes/routes";
import Select from "components/ui/select";
import {api} from "helpers/api";
import LoaderComponent from "components/ui/loader";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import KiutrVariantMapComponent from "components/modules/kiutr/routes/variant_map";
import SlideLeftTransition from "components/ui/transitions/slide-left";
import {createIntervalMap, getIntervalMap, updateIntervalMap} from "store/reducers/routes/interval_maps";
import {getRouteVariant} from "store/reducers/routes/route_variants";
import "./interval_map_values_editor.less";
import IntervalMapMassFillEditor from "components/modules/kiutr/routes/interval_map_mass_fill_editor";
import Tables from "dictionaries/tables";
import ContextTooltip from "components/ui/context-tooltip";

import {StickyTable, Row, Cell} from 'react-sticky-table';
import 'react-sticky-table/dist/react-sticky-table.css';

import {resizeModals} from 'helpers/modal-size';
import {events} from 'dom-helpers';
import moment from "moment";
import formats from "dictionaries/formats";
import currentUser from 'helpers/current-user';

let resizeId;
@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
    isForward: PropTypes.bool,
    className: PropTypes.string
})

@connect(state => ({}), {getIntervalMap, createIntervalMap, updateIntervalMap, getRouteVariant, getStopPoints})

export default class IntervalMapValuesEditor extends BaseEditor {

    title = 'значений карты интервалов';
    modelClass = 'App\\Model\\IntervalMap';

    static childContextTypes = {
        resizeModals: PropTypes.func
    };

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            routeVariantStopPoints: [],
            stopPoints: {},
            showMassFillEditor: false,
            loaded: false,
        });
        this.onResize = this.onResize.bind(this);
    }

    getChildContext() {
        return {resizeModals: this.resizeModals};
    }

    componentWillMount() {
        events.on(window, 'resize', this.onResize);
    }

    componentDidUpdate() {
        setTimeout(() => {
            this.resizeModals();
        }, 500);
    }

    componentWillUnmount() {
        this.onResize();
        events.off(window, 'resize', this.onResize);
    }

    onResize() {
        clearTimeout(resizeId);
        resizeId = setTimeout(this.resizeModals, 1000);
    }

    resizeModals() {
        const ignoreClasses = ['route-add-points-modal', 'add-time-interval-modal', 'map-route-editor-modal', 'add-stopping-point-modal'];
        resizeModals('.b-modal', 'b-modal__collapse', 'move-right', ignoreClasses);
    }

    componentWillMount() {
        this.loadRouteVariant(this.props.routeVariantUuid);
    }

    async loadData(uuid) {
        return await this.props.getIntervalMap(uuid);
    }

    async loadRouteVariant(uuid) {
        const response = await this.props.getRouteVariant(uuid);
        if (response.isOk) {
            await this.setState({
                routeVariant: response.payload,
                routeVariantStopPoints: _.filter(response.payload[this.props.isForward ? 'forward_points' : 'reverse_points'], {point_type: 'stop_point'}),
            });

            await this.loadStopPoints();
        } else {
            response.showErrors();
        }
    }

    async loadStopPoints() {
        const stopPointUuids = _.uniq(_.map(this.state.routeVariantStopPoints, 'type_uuid'));

        const response = await this.props.getStopPoints({
            pagination: {
                page: 1,
            },
            filters: {
                withUuid: stopPointUuids,
            },
        });
        if (response.isOk) {
            const stopPoints = _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'title');

            this.setState({
                stopPoints,
                loaded: true,
            });
        } else {
            response.showErrors();
        }
    }

    async createItem(data) {
        return await this.props.createIntervalMap(data);
    }

    async updateItem(data) {
        return await this.props.updateIntervalMap(data);
    }

    async componentDidMount() {
        this.setState({
            uuid: this.props.uuid,
            item: null
        });
        if (this.props.uuid) {
            this.setState({isLoading: true});
            const response = await this.loadData(this.props.uuid);
            if (response.isOk) {
                this.setState({
                    item: response.payload,
                    isLoading: false,
                });
            } else {
                response.showErrors();
            }
        }
    }

    getForm(item, onSubmit) {
        if (_.isEmpty(item)) {
            let values = [];
            _.each(this.state.routeVariantStopPoints, (stopPoint, index) => {
                for (let i = 0; i <= 47; i++) {
                    _.set(values, `${index}.${i}`, stopPoint.time_to_get_to_the_next_point);
                }
            });
            item = {
                name: '1',
                date_from: moment().startOf('month').format(formats.DATE_API),
                date_to: moment().endOf('month').format(formats.DATE_API),
                is_full: false,
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
                holiday: true,
                values,
            };
        }

        return (
            <EditorForm
                {...this.props}
                ref="form"
                currentFilterItem={this.state.currentFilterItem}
                defaultRouteValues={_.cloneDeep(this.props.defaultRouteValues)}
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                routeVariantStopPoints={this.state.routeVariantStopPoints}
                stopPoints={this.state.stopPoints}
                showMassFillEditor={this.state.showMassFillEditor}
                hideMassFillEditor={::this.hideMassFillEditor}
                routeVariant={this.state.routeVariant}
                forceUpdate={::this.forceUpdate}
            />
        );
    }

    composeItem(data) {
        let item = _.clone(data);

        item.route_variant_uuid = this.props.routeVariantUuid;
        item.is_forward = this.props.isForward;

        return item;
    }

    render() {
        let form;
        let buttons;
        let loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        const title = `Карта интервалов (№ ${_.truncate(_.get(this.state.item, 'name', '...'), {length: 20})})`;

        let onSubmit;

        if (this.props.mode === 'edit') {
            if (this.state.item) {
                form = this.getForm(this.state.item, ::this.edit);
                onSubmit = ::this.onEdit;

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            {(currentUser.can('com.rnis.system.permission.audit', 'read') && this.modelClass) ?
                                (
                                    <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                                    default="Журнал аудита">
                                        <ModalTopMenuListItem
                                            className="b-icon-link_params b-icon-link_icon_history"
                                            href={`/system/audit/${this.props.uuid}?class=${this.modelClass}`}
                                        />
                                    </ContextTooltip>
                                ) : null}
                            {(this.refs.form && this.refs.form.getWrappedInstance().getData().is_full) ? (
                                <ContextTooltip key="interval-map-values.fill" code="interval-map-values.fill"
                                                default="Редактировать">
                                    <ModalTopMenuListItem
                                        className="b-icon-link_icon_edit"
                                        onClick={::this.showMassFillEditor}
                                    />
                                </ContextTooltip>
                            ) : null}
                            <ModalTopMenuListSeparator key="separator"/>
                        </ModalTopMenuList>

                        <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                            <ModalTopMenuButton
                                className="_close"
                                onClick={::this.props.onClose}
                            />
                        </ContextTooltip>
                    </ModalTopMenuButtons>
                );
            }
        } else {
            if (this.state.loaded) {
                form = this.getForm(this.state.item, ::this.create);
                onSubmit = ::this.onCreate;

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            {(this.refs.form && this.refs.form.getWrappedInstance().getData().is_full) ? (
                                <ContextTooltip key="interval-map-values.fill" code="interval-map-values.fill"
                                                default="Редактировать">
                                    <ModalTopMenuListItem
                                        className="b-icon-link_icon_edit"
                                        onClick={::this.showMassFillEditor}
                                    />
                                </ContextTooltip>
                            ) : null}
                        </ModalTopMenuList>

                        <ModalTopMenuButtonsSeparator/>

                        <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                            <ModalTopMenuButton
                                className="_close"
                                onClick={::this.props.onClose}
                            />
                        </ContextTooltip>
                    </ModalTopMenuButtons>
                );
            } else {
                loader = (<GlobalLoaderComponent/>);
            }
        }

        const className = classNames(`b-modal-${this.props.mode} interval-map-values-editor ${this.props.className} time-interval-map-modal b-modal_stretch`);

        return (
            <div>
                <PageModal
                    header={{title, buttons}}
                    onClose={this.props.onClose}
                    className={className}
                    withFade={false}
                    buttons={[
                        <div key="tooltip" className="b-modal__footer-txt">Вы хотите сохранить все изменения?</div>,
                        <a key="cancel" href="#"
                           className="b-button b-button_size_md b-button_white b-button_shadow_gray b-button_cancel"
                           onClick={::this.props.onClose}>Отменить</a>,
                        <a key="save" href="#" className="b-button b-button_red b-button_size_md b-button_save"
                           onClick={onSubmit}>Сохранить</a>
                    ]}
                >
                    {loader}
                    {form}
                </PageModal>
            </div>
        );
    }

    async showMassFillEditor() {
        this.setState({
            showMassFillEditor: true,
        });
    }

    async hideMassFillEditor() {
        this.setState({
            showMassFillEditor: false,
        });
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    routeVariantStopPoints: PropTypes.array,
    stopPoints: PropTypes.object,
    showMassFillEditor: PropTypes.bool,
    hideMassFillEditor: PropTypes.func,
    routeVariant: PropTypes.object,
})

@connect((state) => ({}), {getStopPoints}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        interval_map: {},
        totalDurations: [],
        defaultRouteValues: []
    };

    table = null;

    getData() {
        return this.state.interval_map;
    }

    componentDidMount() {

        const totalDurations = Array.from({ length: 48 }).map((_, index) => (
            this.props.data.values.reduce((acc, curr) => acc + +curr[index], 0)
        ))

        this.setState({
            totalDurations,
            interval_map: this.props.data,
            defaultRouteValues: _.cloneDeep(this.props.data.values)
        });
    }

    get(path, defaultValue = null) {
        return _.get(this.state.interval_map, path, defaultValue);
    }

    setTotalDuration = index => {
        this.setValue(
            `totalDurations.${index}`,
            this.state.interval_map.values.reduce((acc, curr) => acc + +curr[index], 0)
        )
    }

    setTimeBetweenStops = ({ index, newTotal }) => {
        if (!window.RNIS_SETTINGS.shedule_stop_points_total_time_changing) {
            return;
        }
        let total = 0;
        const currentStopsTime = this.state.defaultRouteValues.map(stopArray => {
            total += +stopArray[index]
            return +stopArray[index]
        })

        let recalculatedStopsTime = currentStopsTime.map(time => (
            Math.round(+newTotal * (+time / total))
        ))

       let sumRecalculatedStopsTime = _.reduce(recalculatedStopsTime,  (memo, item) => (memo + item), 0);

       let maxStopsTime = Math.max.apply(Math, recalculatedStopsTime)

        if(sumRecalculatedStopsTime < parseInt(newTotal)) {
            
            let diff = parseInt(newTotal) - sumRecalculatedStopsTime

            recalculatedStopsTime = _.map(recalculatedStopsTime, (el) => {
                if(el === maxStopsTime) {
                    return el += diff
                 }
                 return el;
            })
        } 

        if(sumRecalculatedStopsTime > parseInt(newTotal)) {
            
            let diff = sumRecalculatedStopsTime - parseInt(newTotal)

            recalculatedStopsTime = _.map(recalculatedStopsTime, (el) => {
                if(el === maxStopsTime) {
                    return el -= diff
                 }
                 return el;
            })
        }

        const values = _.cloneDeep(this.state.defaultRouteValues)
        values.map((time, idx) => time[index] = recalculatedStopsTime[idx])

        this.setValue('interval_map.values', values)
    }

    render() {
        return (
            <div className="b-modal__block">
                <Block size="sm" title="№ карты">
                    {this.textInput('interval_map.name')}
                </Block>
                <Block size="sm" title="Дата начала">
                    {this.datepicker('interval_map.date_from')}
                </Block>
                <Block size="sm" title="Дата конца">
                    {this.datepicker('interval_map.date_to')}
                </Block>
                <Block size="xl">
                    {this.checkbox('interval_map.is_full', 'Интервалы по времени суток')}
                </Block>
                <Block size="xl">
                    <div className="Table">
                        <TableContainer>
                            <table className="b-table">
                                <thead>
                                <tr className="b-table__header">
                                    <th className="b-table__mon align-center">Пн</th>
                                    <th className="b-table__tue align-center">Вт</th>
                                    <th className="b-table__wed align-center">Ср</th>
                                    <th className="b-table__thu align-center">Чт</th>
                                    <th className="b-table__fri align-center">Пт</th>
                                    <th className="b-table__sat align-center">Сб</th>
                                    <th className="b-table__sun align-center">Вс</th>
                                    <th className="b-table__holidays align-center">Пр. дни</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.monday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.tuesday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.wednesday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.thursday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.friday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.saturday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.sunday')}
                                    </td>
                                    <td className="align-center select-checkbox">
                                        {this.checkbox('interval_map.holiday')}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </TableContainer>
                    </div>
                </Block>
                <Block size="xl" nowrap={true} className="parent-height">
                    <div className="b-block__text parent-height">
                        <div className="Table indent-none">
                            <TableContainer>
                                <StickyTable stickyHeaderCount={2} stickyColumnCount={2}>
                                    <Row>
                                        <Cell>№</Cell>
                                        <Cell>Остановочный пункт</Cell>
                                        {this.get('is_full') ? (
                                            _.range(0, 24, 0.5).map(::this.renderIntervalHeader)
                                        ) : (
                                            <Cell>00:00-23:59</Cell>
                                        )}
                                    </Row>
                                    <Row>
                                        <Cell/>
                                        <Cell>Длительность</Cell>
                                            {this.get('is_full') ? (
                                                this.state.totalDurations.map((duration, index) => {
                                                    return (
                                                        window.RNIS_SETTINGS.shedule_stop_points_total_time_changing ? (
                                                            <Cell key={index} className="input-cell">
                                                                {this.textInput(`totalDurations.${index}`, {
                                                                    className: '_table-cell',
                                                                    onChange: e => {
                                                                        this.setTimeBetweenStops({
                                                                            index,
                                                                            newTotal: e.target.value
                                                                        })
                                                                        this.setValue(`totalDurations.${index}`, e.target.value)
                                                                    }
                                                                })}
                                                            </Cell>
                                                        ) : <Cell key={index}>{this.formatTime(duration)}</Cell>
                                                    );
                                                })
                                            ) : (
                                                window.RNIS_SETTINGS.shedule_stop_points_total_time_changing ? (
                                                    <Cell className="input-cell">
                                                        {this.textInput('totalDurations.0', {
                                                            className: '_table-cell',
                                                            onChange: e => {
                                                                this.setTimeBetweenStops({
                                                                    index: 0,
                                                                    newTotal: e.target.value
                                                                })
                                                                this.setValue(`totalDurations.0`, e.target.value)
                                                            }
                                                        })}
                                                    </Cell>
                                                ) : (
                                                    <Cell>{this.formatTime(this.getFirstSum())}</Cell>
                                                )
                                            )}
                                    </Row>

                                    {this.props.routeVariantStopPoints.map(::this.renderRow)}
                                </StickyTable>
                            </TableContainer>
                        </div>
                    </div>
                </Block>
                {this.props.showMassFillEditor ? (
                    <IntervalMapMassFillEditor
                        intervalMap={this.state.interval_map}
                        onUpdate={::this.onUpdate}
                        onClose={this.props.hideMassFillEditor}
                        stopPoints={this.props.stopPoints}
                        routeVariantStopPoints={this.props.routeVariantStopPoints}
                    />
                ) : null}
            </div>
        );
    }

    formatTime(minutes) {
        if (isNaN(minutes)) {
            return '00:00';
        }
        return _.padStart(Math.floor(minutes / 60), 2, '0') + ':' + _.padStart(minutes % 60, 2, '0');
    }

    getFirstSum() {
        let sum = 0;
        _.each(this.get('values'), (values, stopPointIndex) => {
            sum += _.toInteger(_.get(values, 0));
        });

        return sum;
    }

    async onUpdate(interval_map) {
        await this.setState({interval_map});
        this.props.hideMassFillEditor();
    }

    renderIntervalHeader(index) {
        const timeStart = _.padStart(Math.floor(index), 2, '0') + ':' + ((index === Math.floor(index)) ? '00' : '30');

        index += 0.50;
        const timeEnd = _.padStart(Math.floor(index - 0.01), 2, '0') + ':' + ((index === Math.floor(index)) ? '59' : '29');

        return (
            <Cell key={index} width="50px">{timeStart}<br/>{timeEnd}</Cell>
        );
    }

    renderRow(point, index) {
        return (
            <Row key={index}>
                <Cell className="align-center">{index + 1}</Cell>
                <Cell>{this.props.stopPoints[point.type_uuid]}</Cell>
                {this.get('is_full') ? (
                    _.range(48).map(this.renderIntervalValueCell.bind(this, index))
                ) : (
                    this.renderIntervalValueCell(index, 0)
                )}
            </Row>
        );
    }

    renderIntervalValueCell(stopPointIndex, index) {
        const onChange = e => {
            this.setValue(`interval_map.values.${stopPointIndex}.${index}`, e.target.value)
            this.setTotalDuration(index)
        }
        return (
            <Cell key={index} className="input-cell">
                {this.textInput(`interval_map.values.${stopPointIndex}.${index}`, {
                    className: '_table-cell',
                    onChange
                })}
            </Cell>
        )
    }

    async setValue(field, value, ignoreRules = false) {
        await super.setValue(field, value);

        if (ignoreRules) {
            return;
        }

        const matches = /^interval_map\.values\.([0-9]+)\./.exec(field);
        if (!this.get('is_full') && matches) {
            for (let i = 1; i < 48; i++) {
                this.setValue(`interval_map.values.${matches[1]}.${i}`, value, true);
            }
        }
        if (field === 'interval_map.is_full') {
            this.props.forceUpdate();
        }
    }
}