import {api} from 'helpers/api';
import {Map} from "immutable";

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getOrderInfo = (data) => async (dispatch) => {
    try {
        const response = await api.matrix.getOrderInfo(data);
        return response;
    }
    catch (e) {
        return error(e);
    }
};

export const getRouteVariantInfo = (data) => async (dispatch) => {
    try {
        const response = await api.matrix.getRouteVariantInfo(data);
        return response;
    }
    catch (e) {
        return error(e);
    }
};

export const getRouteVariantTracks = (data) => async (dispatch) => {
    try {
        const response = await api.matrix.getRouteVariantTracks(data);
        return response;
    }
    catch (e) {
        return error(e);
    }
};