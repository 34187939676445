import _ from 'lodash';
import moment from "moment";
import currentUser from "./current-user";

export const getDefaultVariant = (items) => {
    let result = _.find(items, {is_default: true});
    if (!result) {
        result = items[0];
    }

    _.each(items, (other) => {
        const forwardLastStopPointIndex = _.findLastIndex(other.forward_points, {point_type: 'stop_point'});
        const forwardLastInactiveStopPointIndex = _.findLastIndex(other.forward_points, {point_type: 'stop_point_inactive'});
        if ((forwardLastStopPointIndex < forwardLastInactiveStopPointIndex) && (forwardLastStopPointIndex < result.forward_points.length) && result.forward_points[forwardLastStopPointIndex]) {
            result.forward_points[forwardLastStopPointIndex].key = true;
        }

        const reverseLastStopPointIndex = _.findLastIndex(other.reverse_points, {point_type: 'stop_point'});
        const reverseLastInactiveStopPointIndex = _.findLastIndex(other.reverse_points, {point_type: 'stop_point_inactive'});
        if ((reverseLastStopPointIndex < reverseLastInactiveStopPointIndex) && (reverseLastStopPointIndex < result.reverse_points.length) && result.reverse_points[reverseLastStopPointIndex]) {
            result.reverse_points[reverseLastStopPointIndex].key = true;
        }

        result.forward_points = result.forward_points && _.sortBy(result.forward_points, ['point_order']);
        result.reverse_points = result.reverse_points && _.sortBy(result.reverse_points, ['point_order']);
    });

    if (result) {
        result.forward_points = result.forward_points && _.sortBy(result.forward_points, ['point_order']);
        result.reverse_points = result.reverse_points && _.sortBy(result.reverse_points, ['point_order']);
    }

    return result;
};

export const getGroupsFromRoute = (route, items) => {
    return _.filter(_.map(route.spiral || [], (groupUuid) => {
        return _.find(route.groups, {uuid: groupUuid});
    }));
};

export const timeCorrection = (data) => {
    return data.map((item) => {
        item.start_at =  moment(item.start_at, 'HH:mm').add(currentUser.user.is_timecorrection, 'hours').format('HH:mm');
        item.end_at =  moment(item.end_at, 'HH:mm').add(currentUser.user.is_timecorrection, 'hours').format('HH:mm');
        item.runs.map((run) => {
            run.start_time = moment(run.start_time, 'HH:mm').add(currentUser.user.is_timecorrection, 'hours').format('HH:mm');
            return run;
        });
        return item;
    })
};

export const saveTimeCorrection = (data) => {
    let dataArr = [data];
    dataArr.map((item) => {
        item.start_at = moment(item.start_at, 'HH:mm').add(-currentUser.user.is_timecorrection, 'hours').format('HH:mm');
        item.end_at = moment(item.end_at, 'HH:mm').add(-currentUser.user.is_timecorrection, 'hours').format('HH:mm');
        item.runs.map((run) => {
            run.start_time = moment(run.start_time, 'HH:mm').add(-currentUser.user.is_timecorrection, 'hours').format('HH:mm');
            return run;
        });
        return item;
    });
};

export const motohoursCorrrection = (item) => {
    item.departure_motohours = (item.departure_motohours / 3600).toFixed(2);
    item.arrival_motohours = (item.arrival_motohours / 3600).toFixed(2);
    item.motohours = (item.motohours / 3600).toFixed(2);
    return item;
};
