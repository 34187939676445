import React from 'react';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import moment from "moment";
import formats from "dictionaries/formats";
import _ from 'lodash';
import {deleteSto, getStos} from "store/reducers/kurs/stos";
import './style.less';
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";

@connect(state => ({}), {getStos, deleteSto, getEntityNames})

export default class KursStosComponent extends BaseTableWithEditorComponent {

    title = `${systems.road} → Нормативы`;
    baseUrl = '/road/sto';

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Номер документа')
                .fromField('number')
                .withClassName('link'),

            new Column('Предприятие')
                .fromField('unit_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid)),

            new Column('Дата документа')
                .fromField('date')
                .withDateFilter()
                .withDrawer(item => item.date && moment(item.date).format(formats.DATE)),

            new Column('Дата с')
                .fromField('date_from')
                .withDateFilter()
                .withDrawer(item => item.date_from && moment(item.date_from).format(formats.DATE)),

            new Column('Дата по')
                .fromField('date_to')
                .withDateFilter()
                .withDrawer(item => item.date_to && moment(item.date_to).format(formats.DATE)),

            new Column('Статус')
                .fromField('status_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.status_uuid && this.state.related.get(item.status_uuid)),
        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteSto(data);
    }

    async loadData(meta) {
        return await this.props.getStos(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const units = _.map(_.filter(_.map(result, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const statuses = _.map(_.filter(_.map(result, 'status_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursContractStatuses\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(_.concat(units, statuses));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}