import React from 'react';
import TableContainer from "components/ui/Table/Container/TableContainer";

export default function MileageTable({plan, fact}) {
    return (
        <div className="Table indent-none">
            <TableContainer>
                <table className="b-table">
                    <thead>
                    <tr>
                        <th/>
                        <th>План</th>
                        <th>Факт</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Пробег, км</td>
                        <td>{plan}</td>
                        <td>{fact}</td>
                    </tr>
                    </tbody>
                </table>
            </TableContainer>
        </div>
    );
}