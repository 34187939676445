import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defaultProps, propTypes } from 'react-props-decorators';
import L from 'leaflet';
import ReactPopup from 'react-leaflet-popup';
import ReactDOMServer from 'react-dom/server';
import _, { isEmpty } from 'lodash';
import $ from 'jquery';
import moment from 'moment';
import { connect } from 'react-redux';
import MapComponent from '../../ui/map';
import MapVehicleClusterIcon from 'components/ui/map-vehicle-cluster-icon';
import MapVehicleIcon from 'components/ui/map-vehicle-icon';
import MapCleanupSpecialistIcon from 'components/ui/map-cleanup-specialist-icon';
import { api } from 'helpers/api';
import TelematicsDevice from 'components/modules/maps/telematics-packet';
import DirectionalPolyline from 'components/ui/map/objects/directional-polyline';
import HistoryPointMarker from 'components/ui/map/markers/history-point-marker';
import formats from 'dictionaries/formats';
import VehicleMarker from 'components/ui/map/markers/vehicle-marker';
import VehicleMarkerPopup from 'components/modules/maps/elements/popup/index';
import CleanupSpecialistMarkerPopup from 'components/modules/maps/elements/popup/cleanup-specialist';
import DirectionalGeojson from 'components/ui/map/objects/directional-geojson';
import { makeResponse } from 'helpers/response';
import CleanupSpecialistTooltip from 'components/modules/maps/elements/tooltip/CleanupSpecialistTooltip';
import ReactLeafletTooltip from 'components/modules/maps/elements/tooltip/ReactTooltip';
import { PermamentTooltipGetBlocks } from 'components/modules/maps/elements/tooltip/PermanentTooltip';
import currentUser from '../../../helpers/current-user';
import 'leaflet.marker.slideto';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet.css';
import './leaflet.less';
import { historyColor } from './constants';
@propTypes({
	telematics: PropTypes.object,
	vehicles: PropTypes.object,
	clusters: PropTypes.array,
	onClusterRendered: PropTypes.func,
	onZoom: PropTypes.func,
	components: PropTypes.array,
	animationActive: PropTypes.bool
})
@defaultProps({
	onClusterRendered: () => {},
	onZoom: () => {},
	components: [],
	history: []
})
export default class LeafletComponent extends Component {
	state = {
		geometries: []
	};

	cleanupSpecialistsLayer = null;
	cleanupSpecialistMarkers = null;

	markersLayer = null;
	markers = null;
	clustersLayer = null;
	allMarkers = {};
	allCleanupSpecialistsMarkers = {};
	popup = null;

	componentDidMount() {
		if (!this.refs.map) return;

		const map = this.refs.map.getWrappedInstance().map;

		this.markersLayer = new L.layerGroup().addTo(map);

		this.cleanupSpecialistsLayer = new L.layerGroup().addTo(map);

		this.markers = new L.layerGroup();
		this.markersLayer.addLayer(this.markers);

		if (window.RNIS_SETTINGS.cleanup_specialist_for_task) {
			this.cleanupSpecialistMarkers = new L.layerGroup();
			this.cleanupSpecialistsLayer.addLayer(this.cleanupSpecialistMarkers);
		}

		this.clustersLayer = new L.layerGroup();
		this.markersLayer.addLayer(this.clustersLayer);
	}

	componentWillReceiveProps(props) {
		this.removeUnusedMarkers(props.telematics.keySeq().toArray());

		for (let deviceId of props.telematics.keys()) {
			let marker = this.allMarkers[deviceId];
			const device = new TelematicsDevice(deviceId, props.telematics.get(deviceId));
			if (
				this.state.openPopupId &&
				deviceId === this.props.shownTelematics.id &&
				!_.isEqual(device.fullObject, this.props.shownTelematics)
			) {
				this.props.onSetTelematicsToStore(device.fullObject);
				this.loadPopupData(this.props.shownTelematics, marker);
			}

			if (!marker) {
				this.allMarkers[deviceId] = marker = this.makeMarker(device);
				this.markers.addLayer(marker);
			} else {
				try {
					this.moveMarkerToNewPlace(marker, device);
					/*marker.on('click', (e) => {
							this.loadPopupData(device, e.target)
						});*/
				} catch (e) {}
			}
		}

		if (window.RNIS_SETTINGS.cleanup_specialist_for_task) {
			this.removeUnusedCleanupSpecialistsMarkers(props.cleanupSpecialistsTelematics.keySeq().toArray());

			for (let deviceId of props.cleanupSpecialistsTelematics.keys()) {
				const device = new TelematicsDevice(deviceId, props.cleanupSpecialistsTelematics.get(deviceId));

				let marker = this.allCleanupSpecialistsMarkers[deviceId];

				if (!marker) {
					this.allCleanupSpecialistsMarkers[deviceId] = marker = this.makeCleanupSpecialistMarker(device);
					this.cleanupSpecialistMarkers.addLayer(marker);
					i++;
				} else {
					try {
						this.moveMarkerToNewPlace(marker, device);
						/*marker.on('click', (e) => {
                            this.loadPopupData(device, e.target)
                        });*/
					} catch (e) {}
				}
			}
		}

		if (this.clustersLayer) {
			this.clustersLayer.clearLayers();

			_.each(props.clusters, (cluster) => {
				const clusterInfo = cluster[2];
				let marker = new L.marker([ cluster[0], cluster[1] ], {
					keyboard: false,
					interactive: true,
					riseOnHover: true,
					icon: this.createClusterIcon(clusterInfo)
				});
				/*marker.on('mouseover', function(event){
                    console.log(marker)
                });*/
				marker.on('click', (event) => {
					let latLngs = [ event.target.getLatLng() ];
					if (cluster[3]) {
						latLngs = [
							[ cluster[3].bottomRight.lat, cluster[3].bottomRight.lng ],
							[ cluster[3].topLeft.lat, cluster[3].topLeft.lng ]
						];
					}
					this.fitBounds(latLngs);
				});
				this.clustersLayer.addLayer(marker);
			});
		}
	}

	createClusterIcon(clusterInfo) {
		const html = ReactDOMServer.renderToStaticMarkup(<MapVehicleClusterIcon info={clusterInfo} />);

		return new L.DivIcon({
			html: html,
			className: 'cluster',
			iconSize: new L.Point(window.RNIS_SETTINGS.cluster_size_coeff, window.RNIS_SETTINGS.cluster_size_coeff),
			iconAnchor: new L.Point(36, 36)
		});
	}

	createMarkerIconCleanupSpecialist(device) {
		if (this.props.component === 'control') {
			device.type = 'control';
		}

		const html = ReactDOMServer.renderToStaticMarkup(
			<MapCleanupSpecialistIcon color={'#1186e9'} device={device} direction={device.course} />
		);

		let className = `marker marker_type_cleanup`;
		if (device.speed === 0) {
			className += ' marker_motionless';
		}
		const size = this.getMarkerSize();
		return new L.DivIcon({
			html: html,
			className: className,
			iconSize: new L.Point(size, size),
			iconAnchor: new L.Point(size / 2, size / 2)
		});
	}

	createMarkerIcon(device, vehicle) {
		if (this.props.component === 'control') {
			device.type = 'control';
		}

		let icon = null;
		let stateNumber = [];

		const tooltipBlocks = this.getTooltipBlocks(vehicle, device);
		if (tooltipBlocks) {
			stateNumber = tooltipBlocks;
		}

		if (this.props.component === 'road') {
			icon = 'kdm';
			//stateNumber = vehicle.state_number;
			if (vehicle.vehicle_type_uuid) {
				icon = _.get(_.find(this.props.data.vehicle_types, { uuid: vehicle.vehicle_type_uuid }), 'icon') || 'kdm';
			}
			device.type = icon;
		}

		let color = _.get(_.find(this.props.units, { value: vehicle.unit_uuid }), 'color') || '#1186e9';
		/*if (this.props.component === 'road') {
            color = null;
        }*/

		const html = ReactDOMServer.renderToStaticMarkup(
			<MapVehicleIcon color={color} device={device} direction={device.course} stateNumber={stateNumber} />
		);

		let type = 'bus';
		switch (device.type) {
			case 'road':
				type = icon;
				break;
			case 'trash':
				type = 'trash';
				break;
			case 'control':
				type = 'control';
				break;
			case 'school':
				type = 'school';
				break;
		}

		let className = `marker marker_type_${type}`;
		if (device.speed === 0) {
			className += ' marker_motionless';
		}
		const size = this.getMarkerSize();

		// const size = this.getVehicleMarkerSize();
		return new L.DivIcon({
			html: html,
			className: className,
			iconSize: new L.Point(size, size),
			iconAnchor: new L.Point(size / 2, size / 2),
			props: 'asd'
		});
	}

	getMarkerSize() {
		if (this.props.component === 'road') {
			return 46;
		}
		const zoom = this.getZoom();
		const maxZoom = 16;
		const minZoom = 11;
		// размер иконок берем либо из файла настроек либо ставим по умолчанию
		const maxSize =
			!currentUser.user.is_custommarker && currentUser.user.is_custommarker.max
				? currentUser.user.is_custommarker.max
				: 46;
		const minSize =
			!currentUser.user.is_custommarker && currentUser.user.is_custommarker.min
				? currentUser.user.is_custommarker.min
				: 23;
		if (zoom >= maxZoom) {
			return maxSize;
		}
		if (zoom <= minZoom) {
			return minSize;
		}

		const k = (zoom - minZoom) / (maxZoom - minZoom);

		return minSize + (maxSize - minSize) * k;
	}

	removeUnusedMarkers(markers) {
		const unused = _.difference(_.keys(this.allMarkers), markers);

		_.each(unused, (deviceId) => {
			const marker = this.allMarkers[deviceId];
			this.markers.removeLayer(marker);
			delete this.allMarkers[deviceId];
		});
	}

	removeUnusedCleanupSpecialistsMarkers(markers) {
		const unused = _.difference(_.keys(this.allCleanupSpecialistsMarkers), markers);

		_.each(unused, (deviceId) => {
			const marker = this.allCleanupSpecialistsMarkers[deviceId];
			this.cleanupSpecialistMarkers.removeLayer(marker);
			delete this.allCleanupSpecialistsMarkers[deviceId];
		});
	}

	getBoundingBox() {
		if (!this.refs.map) return;
		return this.refs.map.getWrappedInstance().getBoundingBox();
	}

	fitBounds(bounds) {
		this.refs.map && this.refs.map.getWrappedInstance().fitBounds(bounds);
	}

	getMapSize() {
		if (!this.refs.map) return;

		return this.refs.map.getWrappedInstance().getMapSize();
	}

	getZoom() {
		if (!this.refs.map) return;

		return this.refs.map.getWrappedInstance().getZoom();
	}

	invalidateSize() {
		if (!this.refs.map) return;

		return this.refs.map.getWrappedInstance().invalidateSize();
	}

	setZoom(zoom) {
		this.refs.map && this.refs.map.getWrappedInstance().setZoom(zoom);
	}

	getDeviceStateNumber = (deviceId) => {
		const vehicle = this.props.vehicles[deviceId]; //fixme перепривязаться на vehicle_uuid
		if (!vehicle) return;
		return vehicle.state_number;
	};
	makeMarker(device) {
		const vehicle = this.props.vehicles[device.id];

		let marker;

		marker = L.marker([ device.lat, device.lng ], {
			icon: this.createMarkerIcon(device, vehicle),
			deviceId: device.id,
			riseOnHover: true
		});

		marker.on('click', (e) => {
			this.setState({ openedPopupId: device.id });
			this.loadPopupData(device, e.target);
		});

		/*if (this.props.component === 'control') {
            marker.bindTooltip(vehicle.state_number, {sticky: true, className: 'map-tooltip'});
        }*/

		return marker;
	}

	makeCleanupSpecialistMarker(device) {
		let marker;

		marker = L.marker([ device.lat, device.lng ], {
			icon: this.createMarkerIconCleanupSpecialist(device),
			deviceId: device.id,
			riseOnHover: true
		});

		marker.on('mouseover', (e) => {
			this.loadTooltipCleanupSpecialistData(device, e.target);
		});

		marker.on('click', (e) => {
			this.loadPopupCleanupSpecialistData(device, e.target);
		});

		marker.on('mouseout', (e) => {
			marker.unbindTooltip();
			marker._tooltipLoading = false;
		});

		return marker;
	}

	getTooltipBlocks(vehicle, device) {
		return PermamentTooltipGetBlocks(vehicle, device, this.props.vehiclePopup, this.props.data, this.props.component);
	}

	moveMarkerToNewPlace(marker, device) {
		const latlng = marker.getLatLng();

		const vehicle = this.props.vehicles[device.id];

		marker.setIcon(this.createMarkerIcon(device, vehicle));
		const size = this.getMarkerSize();

		let iconObject = marker.options.icon;
		iconObject.options.iconSize = new L.Point(size, size);
		iconObject.options.iconAnchor = new L.Point(size / 2, size / 2);
		marker.setIcon(iconObject);

		const icon = marker._icon;
		if (icon) {
			if (device.speed === 0) {
				$(icon).addClass('marker_motionless');
			} else {
				$(icon).removeClass('marker_motionless');
			}
		}

		// если положение машины изменилось, то плавно передвигаем
		if (!(latlng.lat === device.lat && latlng.lng === device.lng)) {
			if (icon) {
				$(icon).find('.marker__direction').css('transform', `rotate(${device.course}deg)`);
			}
			const to = new L.latLng(device.lat, device.lng);
			const animationDuration = 10000;
			if (this.props.animationActive) {
				const animationHistoryData = device.animationHistoryData;
				if (animationHistoryData && animationHistoryData.length > 0) {
					const animationPartDuration = animationDuration / animationHistoryData.length;
					_.each(animationHistoryData, (part, index) => {
						setTimeout(() => {
							marker.slideTo(new L.latLng(part[0], part[1]), {
								duration: animationPartDuration
							});
						}, animationPartDuration * index);
					});
				} else {
					marker.slideTo(to, {
						duration: animationDuration
					});
				}
			} else {
				marker.setLatLng(to);
			}
		}
	}

	async loadPopupData(device, marker) {
		if (marker._popup !== undefined) {
			marker.unbindPopup();
			marker.closePopup();
		}
		this.setState({ openPopupId: device.id });
		this.props.onSetTelematicsToStore(device.fullObject);
		const response = await makeResponse(() => {
			return api.vehicles.getVehicleList({
				filters: {
					withCurrentBnsoNumber: [ device.id ]
				}
			});
		});
		const onClose = () => {
			this.props.onSetTelematicsToStore({});
			this.setState({ openPopupId: '' });
			marker.closePopup();
		};

		if (response.isOk) {
			const vehicle = _.first(response.payload.items);
			marker.bindPopup(this.renderPopup(vehicle, device, onClose, this.props.showHistory));
			marker.openPopup();
		} else {
			response.showErrors();
		}
		marker.openPopup();
	}

	gotoCurrentHistory = async (deviceId) => {
		const response = await makeResponse(() => {
			return api.vehicles.getVehicleList({
				filters: {
					withCurrentBnsoNumber: [ deviceId ]
				}
			});
		});
		if (response.isOk) {
			const vehicle = _.first(response.payload.items);
			this.props.showHistory(vehicle.uuid);
		} else {
			response.showErrors();
		}
	};

	loadPopupCleanupSpecialistData(device, marker) {
		marker.unbindTooltip();
		if (marker._popup !== undefined) {
			marker.unbindPopup();
		}

		marker.closePopup();

		const cleanupSpecialist = this.props.cleanupSpecialists.find((cs) => cs.login === device.id);

		marker.bindPopup(
			this.renderCleanupSpecialistPopup(
				device,
				cleanupSpecialist,
				::marker.closePopup,
				this.props.showCleanupSpecialistHistory
			)
		);
		marker.openPopup();
	}

	loadTooltipCleanupSpecialistData(device, marker) {
		const cleanupSpecialist = this.props.cleanupSpecialists.find((cs) => cs.login === device.id);
		marker
			.bindTooltip(this.renderCleanupSpecialistTooltip(cleanupSpecialist, device), {
				sticky: false,
				className: 'map-tooltip'
			})
			.openTooltip();
	}

	renderCleanupSpecialistTooltip(cleanupSpecialist, device) {
		return new ReactLeafletTooltip({
			reactComponent: CleanupSpecialistTooltip,
			reactComponentProps: {
				device,
				cleanupSpecialist
			},
			minWidth: 434,
			maxWidth: 434,
			offset: new L.Point(43, 0)
		});
	}

	layersFilter(layer) {
		if (this.props.component === 'control') {
			return (
				_.indexOf(
					[ 'Муниципальные образования (МО)', 'Типы автомобильных дорог', 'Очаги аварийности' ],
					layer.title
				) !== -1
			);
		}
		if (this.props.component === 'garbage') {
			return (
				!layer.is_visible ||
				layer.title === 'Муниципальные образования (МО)' ||
				(window.RNIS_SETTINGS.CITY_MO && layer.title === 'ОССиГ')
			);
		}
		return true;
	}

	getHistoryCoordinates(history) {
		return _.map(history, (point) => [ point.lat, point.lng ]);
	}

	renderPopup(vehicle, device, onClose, showHistory) {
		// TODO: pickout all data transformation to store/maps/thunk
		if (isEmpty(vehicle)) return;
		vehicle.onEditClick = this.props.vehicles[device.id].onEditClick;
		vehicle.onFuelClick = this.props.vehicles[device.id].onFuelClick;
		let vehicleModel = null;
		if (vehicle.vehicle_model_uuid) {
			vehicleModel = _.get(_.find(this.props.data.vehicle_models, { uuid: vehicle.vehicle_model_uuid }), 'name');
		}
		let vehicleMark = null;
		if (vehicle.vehicle_mark_uuid) {
			vehicleMark = _.get(_.find(this.props.data.vehicle_marks, { uuid: vehicle.vehicle_mark_uuid }), 'name');
		}
		let vehicleType = null;
		if (vehicle.vehicle_type_uuid) {
			vehicleType = _.get(_.find(this.props.data.vehicle_types, { uuid: vehicle.vehicle_type_uuid }), 'name');
		}
		let vehicleEnvironmentalClass = null;
		if (vehicle.environmental_class_uuid) {
			vehicleEnvironmentalClass = _.get(
				_.find(this.props.data.vehicle_environmental_classes, { uuid: vehicle.environmental_class_uuid }),
				'name'
			);
		}
		let vehicleCapacityType = null;
		if (vehicle.capacity_type_uuid) {
			vehicleCapacityType = _.get(
				_.find(this.props.data.vehicle_capacity_types, { uuid: vehicle.capacity_type_uuid }),
				'name'
			);
		}
		const props = {
			...this.props,
			vehicle,
			vehicleModel,
			vehicleMark,
			vehicleType,
			vehicleEnvironmentalClass,
			vehicleCapacityType,
			onClose,
			showHistory,
			work_types: this.props.data.work_types,
			bnso_indicators: this.props.data.bnso_indicators,
			activeGeometriesVehicle: _.indexOf(this.state.activeGeometriesVehicles || [], vehicle.uuid) !== -1,
			onActiveGeometriesVehicleChange: ::this.onActiveGeometriesVehicleChange,
			kurs_task_statuses: this.props.data.kurs_task_statuses
		};
		this.props.setDataForModal(props);

		return new ReactPopup({
			reactComponent: VehicleMarkerPopup,
			reactComponentProps: props,
			minWidth: 434,
			maxWidth: 434,
			offset: new L.Point(-32, -308)
		});
	}

	renderCleanupSpecialistPopup(device, cleanupSpecialist, onClose, showHistory) {
		if (!cleanupSpecialist) return;
		// vehicle.onEditClick = this.props.vehicles[device.id].onEditClick;
		// vehicle.onFuelClick = this.props.vehicles[device.id].onFuelClick;

		const props = {
			...this.props,
			device,
			cleanupSpecialist,
			onClose,
			showHistory,
			kurs_task_statuses: this.props.data.kurs_task_statuses
		};
		this.props.setDataForModal(props);

		return new ReactPopup({
			reactComponent: CleanupSpecialistMarkerPopup,
			reactComponentProps: props,
			minWidth: 434,
			maxWidth: 434,
			offset: new L.Point(3, -100)
		});
	}

	async onActiveGeometriesVehicleChange(uuid) {
		if (_.indexOf(this.state.activeGeometriesVehicles || [], uuid) !== -1) {
			await this.setState({
				activeGeometriesVehicles: _.difference(this.state.activeGeometriesVehicles || [], [ uuid ])
			});
		} else {
			await this.setState({
				activeGeometriesVehicles: _.concat(this.state.activeGeometriesVehicles || [], [ uuid ])
			});
			this.props.loadTasks(this.state.activeGeometriesVehicles);
		}
	}

	renderRoadGeometry(geometry, index) {
		if (!this.refs.map || !geometry.geojson) {
			return;
		}

		return (
			<DirectionalGeojson
				key={index}
				map={this.refs.map}
				leafletMap={this.refs.map.getWrappedInstance().map}
				geometry={geometry.geojson}
				interactive={false}
				color={geometry.movement_type === 'idle' ? '#c9c9c9' : '#f65c50'}
			/>
		);
	}

	render() {
		const geometries = _.flatten(
			_.map(this.state.activeGeometriesVehicles || [], (vehicleUuid) => {
				const vehicle = _.find(this.props.vehicles, { uuid: vehicleUuid });
				return _.flatten(_.map(vehicle.tasks, 'items'));
			})
		);

		let defaultLayers = [
			{
				uuid: 'vehicles',
				title: 'ТС',
				layer: this.markersLayer
			},
			{
				uuid: 'cleanup_specialists',
				title: 'СПУ',
				layer: this.cleanupSpecialistsLayer
			}
		];
		if (window.RNIS_SETTINGS.hide_spu_filter) {
			defaultLayers.pop();
		}
		const isMultiHistory = this.props.history.length > 1;

		return (
			<MapComponent
				ref="map"
				onZoom={this.props.onZoom}
				components={this.props.components}
				showLayers={this.props.showLayers}
				showSearch={this.props.showSearch}
				layersFilter={::this.layersFilter}
				component={this.props.component}
				isHistoryMulti={this.props.isHistoryMulti}
				setSelectedRoutes={this.props.setSelectedRoutes}
				layers={defaultLayers}
				withRouting={this.props.withRouting}
				filters={this.props.filters}
				//changeLayerCallback={::this.props.changeLayerCallback}
				changeLayerCallback={this.props.changeLayerCallback ? ::this.props.changeLayerCallback : undefined}
			>
				{this.props.children}
				{(geometries || []).map(::this.renderRoadGeometry)}
				{this.refs.map && this.props.history.length > 0 ? (
					[
						[
							...this.props.history.map((history, i) => {
								return (
									<DirectionalPolyline
										key={'path' + i}
										map={this.refs.map}
										leafletMap={this.refs.map.getWrappedInstance().map}
										coordinates={this.getHistoryCoordinates(history)}
										interactive={false}
										color={isMultiHistory && historyColor[i]}
										historyArrow={true}
									/>
								);
							})
						],
						[
							...this.props.history.map((history, i) => {
								return (
									<HistoryPointMarker
										key={'start' + i}
										map={this.refs.map}
										leafletMap={this.refs.map.getWrappedInstance().map}
										permanentTooltip
										latitude={_.first(history).lat}
										color={isMultiHistory && historyColor[i]}
										longitude={_.first(history).lng}
										title={`Начало: ${moment
											.unix(_.first(history).time)
											.format(formats.DATETIME)}<br/>Координаты: ${_.first(history).lat}, ${_.first(history)
											.lng}<br/>Скорость: ${_.first(history).speed} км/ч`}
									/>
								);
							})
						],
						[
							...this.props.history.map((history, i) => (
								<HistoryPointMarker
									key={'end' + i}
									map={this.refs.map}
									leafletMap={this.refs.map.getWrappedInstance().map}
									latitude={_.last(history).lat}
									longitude={_.last(history).lng}
									color={isMultiHistory && historyColor[i]}
									title={`Окончание: ${moment
										.unix(_.last(history).time)
										.format(formats.DATETIME)}<br/>Координаты: ${_.last(history).lat}, ${_.last(history)
										.lng}<br/>Скорость: ${_.last(history).speed} км/ч`}
								/>
							))
						],
						this.props.onGetCurrentHistory().length
							? [
									...this.props.onGetCurrentHistory().map((history, i) => {
										return (
											<VehicleMarker
												key={`current-history${i}`}
												map={this.refs.map}
												leafletMap={this.refs.map.getWrappedInstance().map}
												latitude={history.lat}
												longitude={history.lng}
												device={history}
												riseOnHover
												permanentTooltip
												onClick={() => this.gotoCurrentHistory(history.id)}
												// vehicle={this.props.currentVehicle}
												fast={true}
												color={isMultiHistory && historyColor[i]}
												title={`Гос. номер
												${this.getDeviceStateNumber(history.id)}`}
											/>
										);
									})
								]
							: null
					]
				) : null}
			</MapComponent>
		);
	}
}
