import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import {deleteBnso, getBnsoList, exportBNSOExcel} from "store/reducers/vehicles/bnso";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import {Link} from "react-router";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import _ from 'lodash';
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import ReactDOMServer from 'react-dom/server';
import {State} from "components/ui/state";

import './index.less';
import ContextTooltip from "../../../ui/context-tooltip";
import IconButton from "../../../ui/icon-button";
import LoaderComponent from "../../../ui/loader";
import * as alerts from "../../../../helpers/alerts";

@connect(state => ({}), {getBnsoList, deleteBnso, getEntityNames, getDictionaryList, getUnits, exportBNSOExcel})

export default class BNSOComponent extends BaseTableWithEditorComponent {

    getTitle() {
        return <span>{systems[this.state.component]} → <Link to={`/${this.state.component}/dictionaries`}>Справочники</Link> → БНСО</span>;
    }

    getBaseUrl() {
        return `/${this.state.component}/vehicles/bnso`;
    }

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            component: null,
            exportExcelLoading: false,
            exportFilters: null,
        });
    }

    async componentWillUpdate(props, state) {
        super.componentWillUpdate(props, state);

        if (props.params.component !== state.component) {
            await this.setState({
                component: props.params.component,
            });
            this.reload();
        }
    }

    componentDidMount() {
        this.forceUpdate();
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Код БНСО')
                .fromField('bnso_code'),

            new Column('Номер БНСО')
                .fromField('bnso_number'),

            new Column('Гос. номер ТС')
                .fromField('state_number'),

            new Column('Предприятие')
                .fromField('unit_uuid')
                .withDrawer(item => item.unit_uuid ? this.state.related.get(item.unit_uuid) : '<code>-не указано-</code>')
                .withAsyncFilter('withUnit', async (search) => {
                    const response = await this.props.getUnits({
                        search,
                        filters: {
                            withComponent: this.props.params.component,
                            appendChildrenAttribute: true
                        },
                        order: {
                            column: 'name',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 30,
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return [];
                }),

            new Column('Тип БНСО')
                .fromField('bnso_type')
                .withDrawer(item => item.bnso_type ? this.state.related.get(item.bnso_type) : '<code>-</code>')
                .withFilter('withType', async () => {
                    const response = await this.props.getDictionaryList('bnso_types');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return {};
                }),

            new Column('Производитель')
                .fromField('manufacturer')
                .withDrawer(item => item.manufacturer ? this.state.related.get(item.manufacturer) : '<code>-</code>')
                .withFilter('withManufacturer', async () => {
                    const response = await this.props.getDictionaryList('bnso_manufacturers');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return {};
                }),

            new Column('Серийный номер')
                .fromField('serial_number'),

            new Column('Мобильный оператор')
                .fromField('mobile_operator')
                .withDrawer(item => item.mobile_operator ? this.state.related.get(item.mobile_operator) : '<code>-</code>')
                .withFilter('withMobileOperator', async () => {
                    const response = await this.props.getDictionaryList('bnso_operators');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return {};
                }),

            new Column('Номер SIM - карты')
                .fromField('sim_card_number'),

            new Column('Номер телефона')
                .fromField('phone_number'),

            new Column('Версия ПО')
                .fromField('version'),

            new Column('Установлено')
                .fromField('is_installed')
                .withDrawer(item => item.is_installed ? '&check;' : '&times;')
                .withFilter('withInUse', async () => {
                    return [
                        {
                            uuid: 1,
                            name: 'Установлено'
                        },
                        {
                            uuid: 0,
                            name: 'Не установлено'
                        },
                    ]
                }),

            new Column('Одобрено')
                .fromField('is_approved')
                .withAlign('center')
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_approved}/>)),

        ]);
    }

    async exportToXlsCustom() {
        this.setState({
            exportExcelLoading: true,
        })
        const response = await this.props.exportBNSOExcel(this.state.exportFilters)
        if (response.status !== 200) {
            alerts.error('Ошибка. Не удалось сделать экспорт отчета');
            this.setState({
                exportExcelLoading: false,
            })
        } else {
            this.setState({
                exportExcelLoading: false,
            })
        }
    }

    exportToXlsButton() {
        const isShow = window.RNIS_SETTINGS.show_bnso_export[this.state.component];
        if (isShow && isShow.show) {
            return (
                this.state.exportExcelLoading ? <div className="excelLoader"><LoaderComponent color="red"/></div> : <ContextTooltip key="base-table-list.export" code="base-table-list.export"
                                                                                                                                    default="Экспорт в Excel (ctrl+shift+e)">
                    <IconButton icon="export" onClick={::this.exportToXlsCustom}/>
                </ContextTooltip>
            );
        } else if (isShow && isShow.show === false) {
            return null;
        } else {
            return <ContextTooltip key="base-table-list.export" code="base-table-list.export"
                                   default="Экспорт в Excel (ctrl+shift+e)">
                <IconButton icon="export" onClick={::this.exportToXls}/>
            </ContextTooltip>
        }
    }

    async deleteItem(data) {
        return await this.props.deleteBnso(data);
    }

    async loadData(meta) {
        meta.filters.withComponent = [this.state.component];
        meta.response_data = [
            'items/uuid',
            'items/deleted_at',
            'items/bnso_code',
            'items/bnso_number',
            'items/unit_uuid',
            'items/bnso_type',
            'items/manufacturer',
            'items/serial_number',
            'items/mobile_operator',
            'items/sim_card_number',
            'items/phone_number',
            'items/version',
            'items/is_installed',
            'items/is_approved',
            'items/state_number'
        ];

        this.setState({
            exportFilters: meta,
        })
        return await this.props.getBnsoList(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const units = _.map(_.filter(_.map(result, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));
        const bnso_types = _.map(_.filter(_.map(result, 'bnso_type')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\BnsoType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const bnso_manufacturers = _.map(_.filter(_.map(result, 'manufacturer')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\BnsoManufacturer\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const bnso_operators = _.map(_.filter(_.map(result, 'mobile_operator')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\BnsoOperator\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const response = await this.props.getEntityNames(_.concat(units, bnso_types, bnso_manufacturers, bnso_operators));
        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}
