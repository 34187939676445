import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import _ from 'lodash';

import Page from 'components/ui/page';

import './reports.less';
import ReportList from "./reports-list";
import ReportParameters from "./reports-parameters";
import DocumentList from "components/modules/reports/documents-list";
import {connect} from "react-redux";
import {getDocumentsList, listReportTemplates} from "store/reducers/reports/reports";
import {CycleFetch} from "helpers/api";
import debounce from 'throttle-debounce/debounce';
import Datepicker from "components/ui/form/datepicker";
import moment from "moment";
import formats from "dictionaries/formats";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";

@connect((state) => ({}), {getDocumentsList, listReportTemplates, getEntityNames, getDictionaryList})

export default class NotificationsComponent extends Component {

    state = {
        report: null,
        documents: [],
        related: new EntityList,
        templates: {},
        search: '',
        allTemplates: [],
        template: null,
        from: moment().startOf('day').format(formats.DATE_API),
        to: moment().add(1, 'day').startOf('day').format(formats.DATE_API),
        currentPage: 1,
        pages: 1,
        periods:{},
        periodsLoadDone: false,
        loadCommunalListDone: false,
        communal_list: {},
        communalList: {},

    };

    searchDebounce = debounce(500, ::this.search);

    _cycleFetch = null;

    async componentDidMount() {
        this.init();
        this.loadAllTemplates();
        this._cycleFetch = new CycleFetch(
            async () => {
                let filters = {
                    /*withPeriod: [
                        this.state.from,
                        this.state.to,
                    ],*/
                };
                if (this.state.template) {
                    filters.withTemplate = this.state.template;
                }
                const promise = this.props.getDocumentsList({
                    filters,
                    order: {
                        column: 'created_at',
                        direction: 'desc',
                    },
                    search: this.state.search,
                    pagination: {
                        page: this.state.currentPage,
                        limit: 10,
                    },
                });
                promise.then(async (response) => {
                    if (response.isOk) {
                        await this.setState({
                            pages: response.data.headers.meta.pagination.total_pages,
                            documents: response.payload.items,
                        });
                        this.loadRelatedEntities();
                        this.loadTemplates();
                    }
                });

                return promise;
            },
            () => {
            },
            10000);

        this._cycleFetch.run();
    }

    init() {
        if (this.props.location.query.uri) {
            const uri = this.props.location.query.uri;
            this.setState({
                report: {
                    uri,
                },
            });

            this.refs.list.getWrappedInstance().setActiveReport(uri);
        }
    }

    async setCurrentPage(currentPage) {
        await this.setState({currentPage});
        this._cycleFetch.forceNext();
    }

    loadPeriod = async () => {
        if(this.state.periodsLoadDone) return
        const res = await this.props.getDictionaryList("periods")
        if (res.isOk){
            const periods = {}
            for(let i of res.payload.documents){
                periods[i.uuid] = i.name
            }
            this.setState({
                periodsLoadDone: true,
                periods: periods
            })
        }
    }

    loadCommunalList = async ()=>{
        if(this.state.loadCommunalListDone) return
        const res = await this.props.getDictionaryList("communal_municipalities")
        if(res.isOk){
            let communalList = {}
            for(let i of res.payload.documents){
                communalList[i.uuid] = i.name
            }
            this.setState({ communalList })
        }
    }

    async loadRelatedEntities() {
        let items = [];
        let communals = 0
        let periods = 0

        _.each(this.state.documents, (document) => {
            _.each(document.parameters, (parameter) => {
                const options = parameter.options;
                switch (parameter.type) {
                    case 'singleSelectAsync':
                        if (!_.get(options, 'model') || !_.get(options, 'service')) {
                            return
                        }
                        if (options.model === '\\App\\Dictionaries\\Common\\Periods\\Model'){
                            periods++
                        }
                        items.push({
                            class: options.model,
                            uuid: parameter.value,
                            source: options.service,
                        });
                        break;
                    case 'multiSelectAsync':
                        if (!_.get(options, 'model') || !_.get(options, 'service')) {
                            return;
                        }
                        _.each(_.filter(parameter.value.split(';')), (value) => {
                            items.push({
                                class: options.model,
                                uuid: value,
                                source: options.service,
                            });
                        });
                        break;
                }
                if(parameter.key === 'communal_municipality_uuids'){
                    if(parameter.value.split(';')[0] !== ''){
                        communals++
                    }
                }

            });
        });
        const response = await this.props.getEntityNames(_.uniqBy(items, 'uuid'));
        if (response.isOk) {
            this.state.related.add(response);
        }
        if (periods){
            this.loadPeriod()
        }

        if(communals){
            this.loadCommunalList()
        }

    }

    async loadAllTemplates() {
        const response = await this.props.listReportTemplates();

        if (response.isOk) {
            this.setState({
                allTemplates: _.sortBy(_.map(response.payload.items, (template) => ({
                    value: template.uuid,
                    label: template.name,
                })), 'label'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadTemplates() {
        const uuids = _.uniq(_.filter(_.map(this.state.documents, 'report_template_uuid')));
        if (uuids.length === 0) {
            return;
        }

        const response = await this.props.listReportTemplates({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            this.setState({
                templates: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'name'),
            });
        }
    }

    componentWillUnmount() {

        this._cycleFetch.stop();
        delete this['_cycleFetch'];
    }

    render() {
        return (
            <Page
                pageId="Reports"
                title="Отчеты"
                headerActions={this.renderHeaderActions()}>
                <div className="page-content">
                    <ReportList
                        ref="list"
                        selectedReport={this.state.report}
                        onReportClick={::this.onReportClick}
                    />
                    <ReportParameters
                        {...this.props}
                        selectedReport={this.state.report}
                        onClose={::this.onParametersClose}
                    />
                    <DocumentList
                        location={this.props.location}
                        documents={this.state.documents}
                        related={this.state.related}
                        templates={this.state.templates}
                        search={this.state.search}
                        onSearch={::this.onSearch}
                        allTemplates={this.state.allTemplates}
                        template={this.state.template}
                        onTemplateChange={::this.onTemplateChange}
                        setCurrentPage={::this.setCurrentPage}
                        pages={this.state.pages}
                        page={this.state.currentPage}
                        periods={this.state.periods}
                        communalList={this.state.communalList}
                    />
                </div>
            </Page>
        );
    }

    async onTemplateChange(e) {
        const value = e ? e.value : null;

        await this.setState({
            template: value,
        });

        this._cycleFetch.forceNext();
    }

    onSearch({target: {value}}) {
        this.setState({
            search: value,
            currentPage: 1,
        });
        this.searchDebounce();
    }

    search() {
        this._cycleFetch.forceNext();
    }

    onReportClick(report) {
        if (this.state.report && report && (this.state.report.uri === report.uri)) {
            report = null;
        }
        this.setState({report});
    }

    onParametersClose() {
        this.setState({
            report: null,
        });
        this._cycleFetch.forceNext();
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="reports.schedule" code="reports.schedule" default="Расписание формирования">
                <IconButton icon="history_fill" onClick={::this.gotoSchedule}/>
            </ContextTooltip>,
            //this.diapasonFilter(),
        ];
    }

    gotoSchedule() {
        this.props.router.push('/reports/schedule');
    }

    diapasonFilter() {
        return (
            <div key="diapason">
                <div className="top-menu__label">Выбор диапазона:</div>
                <Datepicker style="dark" value={this.state.from} onChange={::this.fromChange}/>
                <Datepicker style="dark" value={this.state.to} onChange={::this.toChange}/>
            </div>
        );
    }

    async fromChange({target: {value}}) {
        await this.setState({from: value});
        this._cycleFetch.forceNext();
    }

    async toChange({target: {value}}) {
        await this.setState({
            to: value,
            from: moment(this.state.from).isAfter(moment(value)) ? value : this.state.from,
        });
        this._cycleFetch.forceNext();
    }

}