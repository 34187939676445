import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './block.less';
import _ from 'lodash';

@propTypes({
    title: PropTypes.any,
    border: PropTypes.bool,
    nowrap: PropTypes.bool,
    withRightMargin: PropTypes.bool,
    withBottomMargin: PropTypes.bool,
    size: PropTypes.oneOf(['extra-xs', 'xs', 'sm', 'mdl', 'md', 'lg', 'xl', 'flex']),
    className: PropTypes.string,
})

@defaultProps({
    title: '',
    border: false,
    nowrap: false,
    withRightMargin: false,
    withBottomMargin: false,
    size: 'md',
    className: '',
})

export default class Block extends Component {
    render() {
        const className = classNames({
            'b-block': true,
            '_border': this.props.border,
            '_marg': this.props.withRightMargin,
        }, `_${this.props.size}`, this.props.className);

        const content = this.props.nowrap ? this.props.children : (
            <div className={classNames('b-block__text', {
                'b-block__text_no-header': !this.props.title,
            })}>
                {this.props.children}
            </div>
        );

        const titleClassName = classNames({
            'b-block__title': true,
            '_bposit': this.props.withBottomMargin,
        });

        return (
            <div className={className}>
                {(this.props.title && !_.isBoolean(this.props.title)) ? (
                    <div className={titleClassName}>{this.props.title}</div>
                ) : null}
                {content}
            </div>
        );
    }
}