import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import DashboardTable from 'components/modules/analytics/base-components/DashboardTable';
import WidgetSelector from 'components/modules/analytics/base-components/WidgetSelector';
import GlobalLoaderComponent from "components/ui/global-loader";
import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import _ from 'lodash';
import {connect} from "react-redux";
import {User} from "helpers/user";
import * as storage from "utils/storage";
import moment from "moment";
import {getTabletKiutrSummary, getTabletKiutrSummaryUnit} from "store/reducers/reports/tablet";
import Datepicker from "components/ui/form/datepicker";
import {
    Bar, BarChart, Label, LabelList, Pie, PieChart, ResponsiveContainer, Text, Tooltip, XAxis,
    YAxis
} from "recharts";
import Select from "components/ui/select";
import './style.less';
import formats from "dictionaries/formats";

@propTypes({
    barChartFillColor: PropTypes.string,
    component: PropTypes.string,
})

@defaultProps({
    barChartFillColor: '',
    component: 'kiutr',
})

@connect(state => ({}), {getTabletKiutrSummary, getTabletKiutrSummaryUnit})

export default class UnitsTableWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isError: false,
            isLoading: false,
            from: moment().subtract(1, 'day').format(formats.DATE_API),
            to: moment().subtract(1, 'day').format(formats.DATE_API),
            table_sort_column: 'unit_name',
            table_sort_direction: 'asc',
            data: {},
            uuid: window.location.pathname.split('/')[4] || null
        };

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('dateFrom') && urlParams.get('dateTo')) {
            this.state.from = urlParams.get('dateFrom')
            this.state.to = urlParams.get('dateTo')
        }
    }

    componentWillMount() {
        this.props.isUnitStats ? this.loadDataUnit() : this.loadData();
    }

    async componentWillReceiveProps(nextProps) {
        await this.setState({
            municipality: nextProps.municipality
        })
        this.props.isUnitStats ? this.loadDataUnit() : this.loadData();
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value});
        this.props.isUnitStats ? this.loadDataUnit() : this.loadData();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value});
        this.props.isUnitStats ? this.loadDataUnit() : this.loadData();
    };

    async loadData() {
        this.setState({isLoading: true});

        let summaryOptions = {
            date_from: moment(this.state.from).format(formats.DATE_API),
            date_to: moment(this.state.to).format(formats.DATE_API),
            component: this.props.component,
            with_communal_municipalities: this.props.municipality
        };

        if (!window.RNIS_SETTINGS.analytic_filter_for_municipality) {
            delete summaryOptions.with_communal_municipalities
        }

        const response = await this.props.getTabletKiutrSummary(summaryOptions);

        this.setState({isLoading: false});

        if (response.isOk) {
            this.setState({
                data: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadDataUnit() {
        this.setState({isLoading: true});

        let summaryOptions = {
            date_from: moment(this.state.from).format(formats.DATE_API),
            date_to: moment(this.state.to).format(formats.DATE_API),
            component: this.props.component,
            uuid: this.state.uuid
        };

        if (!window.RNIS_SETTINGS.analytic_filter_for_municipality) {
            delete summaryOptions.with_communal_municipalities
        }

        const response = await this.props.getTabletKiutrSummaryUnit(summaryOptions);
        this.setState({isLoading: false});

        if (response.isOk) {
            this.setState({
                data: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    get(field, forDisplay = true) {
        const value = _.get(this.state.data, field, null);
        if (forDisplay) {
            if (value !== null) {
                return _.round(value, 2);
            } else {
                return '-';
            }
        }
        return value || 0;
    }

    renderContent() {
        return (
            <div className="full-size kiutr-widget">
                <div className="date-wrapper">
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange}/>
                    </div>
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange}/>
                    </div>
                </div>
                <div className="table-wrap">
                    <table className="table b-table">
                        <tbody>
                        <tr>
                            <th onClick={this.onSortChange.bind(this, 'unit_name')}>
                                {this.props.isUnitStats && window.RNIS_SETTINGS.grantMO ? 'Маршрут' : 'Предприятие'}
                                {(this.state.table_sort_column === 'unit_name') ? (
                                    (this.state.table_sort_direction === 'asc') ? (
                                        <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                            <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                        </svg>
                                    ) : (
                                        <svg className="icon icon_long-arrow icon_red">
                                            <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                        </svg>
                                    )
                                ) : null}
                            </th>
                            <th onClick={this.onSortChange.bind(this, 'regularity')}>
                                {(this.props.component !== 'road') ? 'Регулярность рейсов' : 'Техники в работе'}
                                {(this.state.table_sort_column === 'regularity') ? (
                                    (this.state.table_sort_direction === 'asc') ? (
                                        <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                            <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                        </svg>
                                    ) : (
                                        <svg className="icon icon_long-arrow icon_red">
                                            <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                        </svg>
                                    )
                                ) : null}
                            </th>
                            <th onClick={this.onSortChange.bind(this, 'speed_violations')}>
                                Количество нарушений скоростного режима
                                {(this.state.table_sort_column === 'speed_violations') ? (
                                    (this.state.table_sort_direction === 'asc') ? (
                                        <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                            <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                        </svg>
                                    ) : (
                                        <svg className="icon icon_long-arrow icon_red">
                                            <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                        </svg>
                                    )
                                ) : null}
                            </th>
                            {(this.props.component !== 'road') ? (
                                <th onClick={this.onSortChange.bind(this, 'vehicles_plan')}>
                                    План по выходу ТС
                                    {(this.state.table_sort_column === 'vehicles_plan') ? (
                                        (this.state.table_sort_direction === 'asc') ? (
                                            <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                                <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                            </svg>
                                        ) : (
                                            <svg className="icon icon_long-arrow icon_red">
                                                <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                            </svg>
                                        )
                                    ) : null}
                                </th>
                            ) : null}
                            <th onClick={this.onSortChange.bind(this, 'vehicles_bnso')}>
                                Оснащенность ГЛОНАСС
                                {(this.state.table_sort_column === 'vehicles_bnso') ? (
                                    (this.state.table_sort_direction === 'asc') ? (
                                        <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                            <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                        </svg>
                                    ) : (
                                        <svg className="icon icon_long-arrow icon_red">
                                            <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                        </svg>
                                    )
                                ) : null}
                            </th>
                            {(this.props.component !== 'road') ? (
                                <th onClick={this.onSortChange.bind(this, 'transport_work')}>
                                    Выполнение рейсов
                                    {(this.state.table_sort_column === 'transport_work') ? (
                                        (this.state.table_sort_direction === 'asc') ? (
                                            <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                                <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                            </svg>
                                        ) : (
                                            <svg className="icon icon_long-arrow icon_red">
                                                <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                            </svg>
                                        )
                                    ) : null}
                                </th>
                            ) : null}
                            {(window.RNIS_SETTINGS.grantMO ) ? (
                                <th>Средний возраст ТС по классам</th>
                            ) : null}
                        </tr>
                        {RNIS_SETTINGS.grantMO ? (_.map(this.getRows(), ::this.renderRowUnit)) : (_.map(this.getRows(), ::this.renderRow))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    onSortChange(field) {
        if (field === this.state.table_sort_column) {
            this.setState({
                table_sort_direction: (this.state.table_sort_direction === 'asc') ? 'desc' : 'asc',
            });
        } else {
            this.setState({
                table_sort_column: field,
                table_sort_direction: 'asc',
            });
        }
    }

    getRows() {
        return _.orderBy(this.props.isUnitStats ? this.state.data.routes : this.state.data.units || [], [this.state.table_sort_column], [this.state.table_sort_direction]);
    }


    goto(unit_uuid) {
        console.log(this.state.from)
        this.props.router.push(`/analytics/passenger-transportation/units/${unit_uuid}/?dateFrom=${moment(this.state.from).format(formats.DATE_URL)}&dateTo=${moment(this.state.to).format(formats.DATE_URL)}`);
    }

    renderRow(data) {
        const {unit_uuid, unit_name, regularity, speed_violations, vehicles_plan, vehicles_bnso, transport_work} = data;

        return (
            <tr key={unit_uuid}>
                <td>{unit_name}</td>
                <td>{regularity}</td>
                <td>{speed_violations}</td>
                {(this.props.component !== 'road') ? (
                    <td>{vehicles_plan}</td>
                ) : null}
                <td>{vehicles_bnso}</td>
                {(this.props.component !== 'road') ? (
                    <td>{transport_work}</td>
                ) : null}
            </tr>
        );
    }

    renderRowUnit(data) {
        const {unit_uuid, route_uuid, route_name, regularity, speed_violations, vehicles_plan, vehicles_bnso, transport_work, transport_class, route_number, unit_name} = data;

        return (
            <tr key={route_uuid ? route_uuid : unit_uuid} onClick={this.goto.bind(this, unit_uuid)}>
                <td>{unit_uuid ? unit_name : `${route_number} ${route_name}`}</td>
                <td>{regularity}</td>
                <td>{speed_violations}</td>
                {(this.props.component !== 'road') ? (
                    <td>{vehicles_plan}</td>
                ) : null}
                <td>{vehicles_bnso}</td>
                {(this.props.component !== 'road') ? (
                    <td>{transport_work}</td>
                ) : null}
                <td>{transport_class.length ? transport_class.map(cl => `${cl.type}: ${cl.average_age} \n`) : '' }</td>
            </tr>
        );
    }

    async onSelectedUnitsChange(param) {
        let data = this.state.data;
        _.each(param, (p) => {
            let index = p.index;
            let checked = p.checked;

            data.organizationUnits[index].checked = checked;
        });
        await this.setState({data});
        this.loadDriversData();
    }

    onChange(field, e) {
        const value = e.value || e.target.value;

        let state = this.state;
        state[field] = value;
        this.setState(state);
    }

    render() {
        const loader = this.state.isLoading ? <GlobalLoaderComponent/> : null;

        return (
            <div className="kiutr-widget">
                {loader}
                {this.renderContent()}
            </div>
        );
    }

    getFill(value, field) {
        let green = 80;
        let orange = 50;

        if (field === 'vehicles_bnso') {
            green = 99;
            orange = 80;
        }

        if (value >= green) {
            return '#95B400';
        }
        if (value >= orange) {
            return '#F4A44B';
        }
        return '#F65C50';
    }
}
