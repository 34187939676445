import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import {LabeledCheckbox} from "components/ui/checkbox";
import IconButton from "components/ui/icon-button";
import ContextTooltip from "components/ui/context-tooltip";
import Popup from "components/ui/popup";
import FilterHeader from "components/ui/filter-header";
import debounce from 'throttle-debounce/debounce';
import classNames from 'classnames';
import GlobalLoaderComponent from "components/ui/global-loader";
import LoaderComponent from "components/ui/loader";

@propTypes({
    title: PropTypes.string,
    selected: PropTypes.array,
    onSelectedChange: PropTypes.func.isRequired,
    loadData: PropTypes.func,
})

export default class MapFilterExtendedFilter extends Component {

    limit = 25;

    loadDebounce = debounce(500, ::this.loadDataMiddleware);

    state = {
        opened: false,
        items: [],
        isDeselectAll: true,
        search: '',
        loading: false,
    };

    async loadDataMiddleware() {
        this.setLoading(true);
        const newItems = await this.props.loadData(this.state.search, this.limit);
        this.setLoading(false);

        let items = this.state.items;
        items = _.uniqBy(_.concat(items, newItems), 'value');

        this.setState({items});
    }

    setLoading(loading) {
        this.setState({
            loading,
        });
    }

    getFilteredItems() {
        const searchLower = this.state.search.toLowerCase();
        const selected = this.props.selected;

        return _.sortBy(_.filter(this.state.items, (item, index) => {
            if (searchLower) {
                return (item.label.toLowerCase().indexOf(searchLower) !== -1);
            }
            if (selected.length > 0) {
                return (_.indexOf(selected, item.value) !== -1) || (index < this.limit - selected.length);
            }
            return index < this.limit;
        }), 'label');
    }

    render() {
        return (
            <div className="settingsFilterObject__item">
                <div className="settingsFilterObject__text">
                    {this.props.title}
                </div>
                <div className="settingsFilterObject__wrap-icon">
                    <span className="settingsFilterObject__all">{(this.props.selected.length === 0) ? 'все' : this.props.selected.length}</span>
                    <i className="rnis-icon rnis-icon_close rnis-icon_close_red" onClick={::this.clearSelected}/>
                    <div className="settingsFilterObject__search">
                        <i className="sort-icon sort-icon_filter" onClick={::this.toggleBlock}/>
                        {this.state.opened ? this.renderPopup() : null}
                    </div>
                </div>
            </div>
        );
    }

    renderPopup() {
        const items = this.state.items || [];
        const selectedCount = _.filter(items, (item) => {
            return _.indexOf(this.props.selected, item.value) !== -1;
        }).length;
        const totalCount = items.length;

        const sliderClassName = classNames({
            'b-slider__line': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        const sliderCircleClassName = classNames({
            'b-slider__circle': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        const filteredItems = this.getFilteredItems();

        return (
            <Popup
                className={classNames("SelectFieldsPopup top-link checkbox-dropdown active")}
                show={true}
                onClose={::this.toggleBlock}>
                <div className="popup-container__content">
                    <div className="filtration__search">
                        <input type="text" ref="search" value={this.state.search}
                               className="filtration__pole"
                               placeholder="Поиск"
                               onChange={::this.onSearchChange}
                        />
                        <input type="button" className="filtration__button"/>
                    </div>

                    <div className="content">
                        {this.state.loading ? (
                            <LoaderComponent color="red" size={64}/>
                        ) : null}
                        {filteredItems.map((item) => (
                            <LabeledCheckbox
                                key={item.value}
                                checked={_.indexOf(this.props.selected, item.value) !== -1}
                                label={item.label}
                                onChange={this.onClick.bind(this, item)}/>
                        ))}
                    </div>
                    {(totalCount > 0) ? (
                        <div className="b-slider _options clearAfter" onClick={::this.selectDeselectAll}>
                            <div className="b-slider__title" style={{display: 'none'}}>Выбрать все</div>
                            <div
                                className="b-slider__title">{this.state.isDeselectAll ? 'Снять все' : 'Выбрать все'}</div>

                            <div className="b-slider__control">
                                <div className={sliderClassName}/>
                                <div className={sliderCircleClassName}/>
                            </div>
                        </div>
                    ) : null}
                </div>
            </Popup>
        );
    }

    async onSearchChange() {
        const value = this.refs.search.value;
        await this.setState({
            search: value,
        });

        this.loadDebounce();
    }

    selectDeselectAll() {
        let selected;

        if (this.state.isDeselectAll) {
            selected = [];
        } else {
            selected = _.map(this.getFilteredItems(), 'value');
        }
        this.props.onSelectedChange(selected);

        this.setState({
            isDeselectAll: !this.state.isDeselectAll,
        });
    }

    async onClick(item) {
        let selected = this.props.selected;
        const index = _.indexOf(selected, item.value);
        if (index !== -1) {
            selected.splice(index, 1);
        } else {
            selected.push(item.value);
        }

        this.props.onSelectedChange(selected);
    }

    clearSelected() {
        this.props.onSelectedChange([]);
    }

    toggleBlock() {
        this.setState({
            opened: !this.state.opened,
        });
    }
}