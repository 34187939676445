import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import {getUnits} from "store/reducers/organizational_units/organizational_units";
import TaskFilterBase from "components/modules/kurs/tasks_day/filters/TaskFilterBase";

@propTypes({})

@connect(state => ({}), {
    getUnits,
})

export default class TaskFilterUnits extends TaskFilterBase {

    tabs = [
        'Предприятие',
    ];
    icon = 'organizer';
    contextKey = 'map.filters.unitsMultiple';
    contextDefault = 'Предприятия';

    async loadData(tab = null, search = null) {
        let filters = {};
    
        const response = await this.props.getUnits({
            filters,
            search,
            pagination: {
                page: 1,
                limit: this.limit,
            },
        });

        if (response.isOk) {
            return _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.name,
            }));
        } else {
            response.showErrors();
            return [];
        }
    }

}