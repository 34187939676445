import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import BarChart from 'components/modules/analytics/base-components/charts/BarChart';
import DashboardTable from 'components/modules/analytics/base-components/DashboardTable';
import './style.less';

@propTypes({
    barChartFillColor: PropTypes.string
})

@defaultProps({
    barChartFillColor: ''
})

export default class DepartmentRatingWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [
                {name: 'Отдел 1', rating: 50},
                {name: 'Отдел 2', rating: 20},
                {name: 'Отдел 3', rating: 70},
                {name: 'Отдел 4', rating: 100},
                {name: 'Отдел 5', rating: 30}
            ]
        };

        this.metadata = {
            0: {field: 'rating', name: 'Рейтинг', sort: true},
            1: {field: 'name', name: 'Отдел', sort: true}
        };
    }

    render() {
        return (
            <div className="department-rating-widget">
                <div className="chart-wrapper">
                    <BarChart data={this.state.data}
                              xDataKey="name"
                              yDataKey="rating"
                              xName="Отделы"
                              color={this.props.barChartFillColor}
                    />
                </div>
                <div className="table-wrapper">
                    <DashboardTable data={this.state.data} metadata={this.metadata}/>
                </div>
            </div>
        );
    }
}