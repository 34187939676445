import formats from 'dictionaries/formats';
import moment from 'moment';
export const scheduleInitialValues = {
	start_time_first_backward: '00:00',
	start_time_last_backward: '23:59',
	start_time_first_forward: '00:00',
	start_time_last_forward: '23:59'
};
export const months = [
	'is_april',
	'is_august',
	'is_december',
	'is_february',
	'is_january',
	'is_july',
	'is_march',
	'is_may',
	'is_november',
	'is_october',
	'is_september',
	'is_june'
];

export const inititalScheduleFormValues = {
	name: 'Основное расписание',
	is_null_run_separated: true,
	date_from: moment().startOf('month').format(formats.DATE_API),
	date_to: moment().endOf('month').format(formats.DATE_API),
	monday: true,
	tuesday: true,
	wednesday: true,
	thursday: true,
	friday: true,
	saturday: true,
	sunday: true,
	holiday: true,
	is_april: false,
	is_august: false,
	is_december: false,
	is_february: false,
	is_january: false,
	is_july: false,
	is_march: false,
	is_may: false,
	is_november: false,
	is_october: false,
	is_september: false,
	is_june: false
};

export const days = [ 'friday', 'holiday', 'monday', 'saturday', 'sunday', 'thursday', 'tuesday', 'wednesday' ];

export const date = '10/10/2020';
