import React, {Component} from 'react';

import Page from 'components/ui/page';
import ExecutedWorksContainer from "../executed-works/ExecutedWorksContainer";

export default class ExecutedWorksList extends Component {

    render() {
        return (
            <Page pageId="ExecutedWorks"
                  title={`Аналитическая платформа → Выполненные работы`}
                  headerContents={[]}>
                {this.renderContent()}
            </Page>
        );
    }

    renderContent() {
        return (
            <div>
                <ExecutedWorksContainer municipality_uuid={this.props.params.uuid} />
            </div>
        );
    }
}
