import {Map} from "immutable";
import {api} from "helpers/api";
import {error, success} from "helpers/response";
import _ from 'lodash';
import {getCurrentUser} from "helpers/api/session";

const SETTINGS_GET = 'SETTINGS_GET';
const SETTING_GET = 'SETTING_GET';
const SETTING_SET = 'SETTING_SET';
const SETTING_DELETE = 'SETTING_DELETE';
const USER_SETTING_SET = 'USER_SETTING_SET';
const SETTING_CREATE = 'SETTING_CREATE';
const SETTINGS_UPDATED = 'SETTINGS_UPDATED';

const initialState = new Map({
    settings: null,
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SETTINGS_GET:
            return state.set('settings', action.payload.data);
        case SETTING_SET:
            let settings = state.get('settings');
            const userUuid = _.get(getCurrentUser(), 'uuid');
            _.set(settings, `${(action.payload.type === 'global') ? 'global' : userUuid}.${action.payload.key}`, action.payload.data);
            return state.set('settings', settings);
    }

    return state;
}

export const getOptions = () => async (dispatch) => {
    try {
        let response = await api.system.getOptions();

        dispatch({
            type: SETTINGS_GET,
            payload: {
                data: response.payload.items
            }
        });

        return response;
    }
    catch (e) {
        dispatch({
            type: SETTINGS_GET,
            payload: {
                data: {}
            }
        });
        return error(e);
    }
};

export const createOption = (key, value, is_system) => async (dispatch) => {
    try {
        let response = await api.system.createOption(key, value, is_system);

        dispatch({
            type: SETTING_CREATE,
            payload: {
                data: response.payload
            }
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getOption = (key) => async (dispatch, getState) => {
    const settings = getState().settings.get('settings');
    const userUuid = _.get(getCurrentUser(), 'uuid');
    const setting = _.get(settings, `${userUuid}.${key}`);
    if (setting) {
        return success({
            success: true,
            payload: {
                items: [
                    setting,
                ],
            },
        });
    }

    try {
        const response = await api.system.getOption(key);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const setOptionValue = (key, value) => async (dispatch) => {
    try {
        let response = await api.system.setOptionValue(key, value);

        dispatch({
            type: SETTING_SET,
            payload: {
                key,
                type: 'global',
                data: response.payload
            }
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const setUserOptionValue = (key, value) => async (dispatch) => {
    try {
        const response = await api.system.setUserOptionValue(key, value);

        dispatch({
            type: SETTING_SET,
            payload: {
                key,
                type: 'user',
                data: value,
            },
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteOption = (key) => async (dispatch) => {
    try {
        let response = await api.system.deleteOption(key);

        dispatch({
            type: SETTING_DELETE,
            payload: {}
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const optionsUpdated = (callback) => async (dispatch) => {
    api.system.optionsUpdated((response) => {

        dispatch({
            type: SETTINGS_UPDATED,
            payload: {
                data: response.payload.items
            }
        });

        _.isFunction(callback) && callback(response.payload.items);
    });
};