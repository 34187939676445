import {rpc} from './session';
import currentUser from 'helpers/current-user';
import moment from "moment";
import formats from "../../dictionaries/formats";

export async function getBnsoList(meta) {
    const search = meta.search;
    if(search) {
        delete meta.search;
        meta.column_search = [{"column": "bnso_code", "value": search}];
    }
    return await rpc.request('com.rnis.vehicles.action.bnso.list', {}, {meta});
}

export async function getBnso(uuid) {
    return await rpc.request('com.rnis.vehicles.action.bnso.get', {uuid});
}

export async function createBnso(data) {
    return await rpc.request('com.rnis.vehicles.action.bnso.create', data);
}

export async function updateBnso(data) {
    return await rpc.request('com.rnis.vehicles.action.bnso.update', data);
}

export async function deleteBnso(data) {
    return await rpc.request('com.rnis.vehicles.action.bnso.delete', data);
}

export async function getVehicleList(meta) {
    let userRole = currentUser.user.roles.filter((role) =>
        role.name === '[КиУТР] Коммерческий перевозчик (диспетчер, копия с расширенными правами)'  //TSRNIS-3330
        || role.name === window.RNIS_SETTINGS.PAIDACCOUNTROLENAME);
    userRole.length ? meta.filters.withPaid = true : null;
    return await rpc.request('com.rnis.vehicles.action.vehicle.list', {}, {meta});
}

export async function getVehiclesByUnits(units, meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.vehicle.by_units.list', {units}, {meta});
}

export async function getVehicleListForPortal(meta) {
    return await rpc.request('com.rnis.vehicles.action.vehicle.list.portal', {}, {meta});
}

export async function getVehicle(uuid) {
    return await rpc.request('com.rnis.vehicles.action.vehicle.get', {uuid});
}

export async function createVehicle(data) {
    return await rpc.request('com.rnis.vehicles.action.vehicle.create', data);
}

export async function getVehicleDistance(data) {
    return await rpc.request('com.rnis.vehicles.action.vehicle_distances.list', data);
}

export async function updateVehicle(data) {
    if (data.date_activation === null) data.date_activation = '';
    if (data.date_disactivation === null) data.date_disactivation = '';
    return await rpc.request('com.rnis.vehicles.action.vehicle.update', data);
}

export async function deleteVehicle(data) {
    return await rpc.request('com.rnis.vehicles.action.vehicle.delete', data);
}

export async function getVehicleBnsoList(vehicleUuid, meta) {
    meta.filters = meta.filters || {};
    meta.filters.withVehicle = vehicleUuid;

    return await rpc.request('com.rnis.vehicles.action.bnso_to_vehicle.list', {}, {meta});
}

export async function getVehicleBnso(uuid) {
    return await rpc.request('com.rnis.vehicles.action.bnso_to_vehicle.get', {uuid});
}

export async function createVehicleBnso(data) {
    return await rpc.request('com.rnis.vehicles.action.bnso_to_vehicle.create', data);
}

export async function updateVehicleBnso(data) {
    return await rpc.request('com.rnis.vehicles.action.bnso_to_vehicle.update', data);
}

export async function deleteVehicleBnso(data) {
    return await rpc.request('com.rnis.vehicles.action.bnso_to_vehicle.delete', data);
}

export async function importVehicles(data) {
    return await rpc.request('com.rnis.vehicles.action.vehicle.import', data);
}

export async function importVehiclesGet(uuid) {
    return await rpc.request('com.rnis.vehicles.action.vehicle.import.get', {uuid});
}

export async function getEvents(meta) {
    return await rpc.request('com.rnis.vehicles.action.event.list', {}, {meta});
}

export async function getBnsoCheckLink(bnsoUuid) {
    return await rpc.request('com.rnis.vehicles.action.bnso.check.link', {
        bnso_uuid: bnsoUuid,
    });
}

export async function getGraduationTemplates(meta) {
    return await rpc.request('com.rnis.vehicles.action.graduation_templates.list', {}, {meta});
}

export async function createGraduationTemplate(data) {
    return await rpc.request('com.rnis.vehicles.action.graduation_templates.create', data);
}

export async function getVehicleMalfunctions(meta) {
    return await rpc.request('com.rnis.vehicles.action.vehicle_malfunctions.list', {}, {meta});
}

export async function getBnsoPeriods(data) {
    return await rpc.request('com.rnis.vehicles.action.bnso_periods.list', data);
}

export async function exportBNSOExcel(meta = {}, from, to) {
    const url = location.origin.includes('localhost') ? "https://rnis-test.t1-group.ru" : location.origin;
    const body = {
        headers: {
            meta: meta,
            token: JSON.parse(localStorage.getItem("user")).token
        },
        payload: {}
    }
    const response = await fetch(`${url}/export/devices`, {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify(body),
    });

    if (response.status === 200) {
        const blob = await response.blob();
        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.setAttribute("download", `report${moment(new Date()).format(formats.DATE_URL)}.xlsx`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return response;
}
