import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import $ from 'jquery';
import uuidv1 from 'uuid/v1';
import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";

import {getRouteVariants} from "store/reducers/routes/route_variants";
import {getStopPoints} from "store/reducers/routes/routes";
import {api} from "helpers/api";
import Block from "components/ui/form/block";
import {getRoute, updateRoute} from "store/reducers/routes/route_editor";
import Settings from 'settings';
import FileReaderInput from 'react-file-reader-input';
import GlobalLoaderComponent from "components/ui/global-loader";
import {EntityList} from "helpers/entity";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
    isPageWithDetect:  PropTypes.bool,
})

@connect(state => ({}), {getRoute, updateRoute})

export default class RouteGroups extends BaseEditor {

    modalClassName = 'b-modal-route route-grouping-modal';
    withFade = false;

    componentDidMount() {
        if(this.props.isPageWithDetect && this.modalConfirmation) {
            document.addEventListener('click', this.handleClickOutside, true)
        }
    }

    componentWillUnmount() {
        if(this.props.isPageWithDetect && this.modalConfirmation) {
            document.removeEventListener('click', this.handleClickOutside)
        }
    }

    getFullTitle() {
        return `Группировка остановок маршрута №${_.get(this.state.item, 'number', '-')}`;
    }

    async loadData(uuid) {
        return await this.props.getRoute(uuid);
    }

    async updateItem(data) {
        return await this.props.updateRoute(data);
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();

        const response = await this.updateItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            this.props.onSubmit(response.payload);
        } else {
            this.setState({
                errors: response.validationErrors
            });
            if (_.get(_.first(_.get(response, 'errors') || []), 'code') === '1-0-0-10') {
                this.props.onSubmit(response.payload);
                return;
            }
            response.showErrors();
        }
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
                routeUuid={this.props.uuid}
                turnedEditingMode={this.turnedEditingMode}
            />
        );
    }

    printButton() {
        return null;
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    turnedEditingMode: PropTypes.func,
})

@connect((state) => ({}), {
    getRouteVariants,
    getStopPoints,
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        route: {},
        routeVariants: {},
        stopPoints: [],
        selectedGroup: null,
        selectedItem: null,
    };

    getData() {
        return this.state.route;
    }

    async componentDidMount() {
        await this.setState({
            route: this.props.data,
        });

        this.loadRouteVariants();
    }

    async loadRouteVariants() {
        const response = await this.props.getRouteVariants({
            filters: {
                withRoute: this.state.route.uuid,
            },
        });

        if (response.isOk) {
            await this.setState({
                routeVariants: _.keyBy(response.payload.items, 'uuid'),
            });

            this.loadStopPoints();
        } else {
            response.showErrors();
        }
    }

    onChangeInput(field, {target: {value, type}}) {
        super.onChangeInput(field, {target: {value, type}});
        this.props.turnedEditingMode();
    }

    async loadStopPoints() {
        const uuids = _.uniq(_.flatten(_.map(this.state.routeVariants, (routeVariant) => {
            return _.filter(_.concat(_.map(routeVariant.forward_points, 'type_uuid'), _.map(routeVariant.reverse_points, 'type_uuid')));
        })));

        const response = await this.props.getStopPoints({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            this.setState({
                stopPoints: _.sortBy(_.map(response.payload.items, item => ({
                    value: item.uuid,
                    label: `${item.register_number} ${item.title}`,
                    originalTitle: item.title,
                })), 'label'),
            });
        } else {
            response.showErrors();
        }
    }

    get(fieldName, defaultValue = null) {
        return _.get(this.state.route, fieldName, defaultValue);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem title="Группы" opened={true}>
                        {_.map(this.get('groups') || [], ::this.renderGroup)}
                        <div className="add-link">
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="#"
                               onClick={::this.addGroup}>Добавить запись</a>
                        </div>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    renderSelectedItem() {
        const selectedItem = this.get(`groups.${this.state.selectedGroup}.items.${this.state.selectedItem}`);

        const inclusions = this.getAllInclusions(selectedItem.stop_point_uuid);
        return (
            <div>
                <div className="b-modal__block-title">Включение в группе</div>
                <Block size="xl"
                       title={_.get(_.find(this.state.stopPoints, {value: selectedItem.stop_point_uuid}), 'label')}>
                    {this.select(`route.groups.${this.state.selectedGroup}.items.${this.state.selectedItem}.inclusions`, _.map(inclusions, (inclusion) => ({
                        value: `${inclusion.route_variant_uuid}:${inclusion.is_forward ? 1 : 0}:${inclusion.index}`,
                        label: `${_.get(this.state.routeVariants, `${inclusion.route_variant_uuid}.name`)} - ${inclusion.is_forward ? 'прямой' : 'обратный'} - ${inclusion.index}`,
                        inclusion,
                    })), {
                        multi: true,
                        onChange: this.onChangeInclusions.bind(this, this.state.selectedGroup, this.state.selectedItem),
                    })}
                </Block>
            </div>
        );
    }

    renderGroup(group, index) {
        return (
            <div key={group.uuid}>
                <Block size="md" title="Наименование">
                    {this.textInput(`route.groups.${index}.name`)}
                </Block>
                <Block size="md" title="Остановки">
                    {_.map(group.items, (item, itemIndex) => {
                        if (!item.inclusions || item.inclusions.length === 0) {
                            return null;
                        }

                        return (
                            <div key={itemIndex} className="stop-point clearAfter">
                                <a className="stop-point__link" href="javascript:void(0)"
                                   onClick={this.toggleSelected.bind(this, index, itemIndex)}>
                                    {_.get(_.find(this.state.stopPoints, {value: item.stop_point_uuid}), 'label')}
                                    &nbsp;
                                    ({this.getAllItemInclusions(item).length}/{this.getAllInclusions(item.stop_point_uuid).length})
                                </a>
                                &nbsp;
                                <a className="remove-point" href="javascript:void(0)"
                                   onClick={this.deleteItem.bind(this, index, itemIndex)}>&times;</a>
                            </div>
                        );
                    })}
                </Block>
                <Block size="md" title="Добавить остановку">
                    {this.select(`route.groups.${index}.stop_point`, this.getUnusedStopPoints())}
                </Block>
                <Block size="md" className="b-block_posit">
                    <a className="remove-group" href="javascript:void(0)"
                       onClick={this.deleteGroup.bind(this, index)}>&times; Удалить группу</a>
                </Block>
                {(this.state.selectedGroup === index) ? this.renderSelectedItem() : null}
                <hr/>
            </div>
        );
    }

    getAllItemInclusions(item) {
        return _.filter(item.inclusions, (inclusion) => {
            return _.indexOf(_.keys(this.state.routeVariants), inclusion.route_variant_uuid) !== -1;
        });
    }

    toggleSelected(groupIndex, itemIndex) {
        if (this.state.selectedGroup === groupIndex && this.state.selectedItem === itemIndex) {
            groupIndex = null;
            itemIndex = null;
        }
        this.setState({
            selectedGroup: groupIndex,
            selectedItem: itemIndex,
        });
    }

    deleteItem(groupIndex, itemIndex) {
        let route = this.state.route;

        route.groups = route.groups || [];
        route.groups[groupIndex].items.splice(itemIndex, 1);

        this.setState({
            route,
            selectedGroup: null,
            selectedItem: null,
        });
    }

    async setValue(field, value) {
        const matches = /^route\.groups.([0-9]+)\.stop_point$/.exec(field);
        if (matches) {
            const index = matches[1];
            this.addStopPoint(index, value);
            return;
        }

        return super.setValue(field, value);
    }

    addStopPoint(index, uuid) {
        let route = this.state.route;

        route.groups = route.groups || [];
        route.groups[index].items.push({
            stop_point_uuid: uuid,
            inclusions: this.getAllInclusions(uuid),
        });
        if (!route.groups[index].name) {
            route.groups[index].name = _.get(_.find(this.state.stopPoints, {value: uuid}), 'originalTitle');
        }

        this.setState({
            route,
        });
    }

    getUsedInclusions(uuid) {
        let result = [];

        _.each(this.get('groups') || [], (group) => {
            _.each(group.items, (item) => {
                if (item.stop_point_uuid === uuid) {
                    result = _.concat(result, item.inclusions);
                }
            });
        });

        return result;
    }

    getAllInclusions(uuid) {
        let result = [];

        _.each(this.state.routeVariants, (routeVariant) => {
            const forwardPoints = _.filter(routeVariant.forward_points, {point_type: 'stop_point'});
            _.each(forwardPoints, (point, index) => {
                if (point.type_uuid === uuid) {
                    result.push({
                        route_variant_uuid: routeVariant.uuid,
                        is_forward: true,
                        index,
                    });
                }
            });
            const reversePoints = _.filter(routeVariant.reverse_points, {point_type: 'stop_point'});
            _.each(reversePoints, (point, index) => {
                if (point.type_uuid === uuid) {
                    result.push({
                        route_variant_uuid: routeVariant.uuid,
                        is_forward: false,
                        index,
                    });
                }
            });
        });

        return result;
    }

    addGroup() {
        let route = this.state.route;

        route.groups = route.groups || [];
        route.groups.push({
            uuid: uuidv1(),
            name: '',
            items: [],
        });

        this.setState({
            route,
        });
    }

    deleteGroup(index) {
        let route = this.state.route;

        route.groups = route.groups || [];
        route.groups.splice(index, 1);

        this.setState({
            route,
        });
    }

    onChangeInclusions(groupIndex, itemIndex, e) {
        const values = e ? _.map(e, 'inclusion') : [];

        this.setValue(`route.groups.${groupIndex}.items.${itemIndex}.inclusions`, values);
    }

    getValue(field) {
        const matches = /^route\.groups\.([0-9]+)\.items\.([0-9]+)\.inclusions$/.exec(field);
        if (matches) {
            return _.map(super.getValue(field), (inclusion) => {
                return `${inclusion.route_variant_uuid}:${inclusion.is_forward ? 1 : 0}:${inclusion.index}`;
            });
        }

        return super.getValue(field);
    }

    getUnusedStopPoints() {
        return _.filter(this.state.stopPoints, (stopPoint) => {
            const uuid = stopPoint.value;

            return this.getUsedInclusions(uuid) < this.getAllInclusions(uuid);
        });
    }
}