import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';
import _ from 'lodash';
import * as storage from 'utils/storage';

const GET_MAP_SEARCH = 'GET_MAP_SEARCH';
const SELECT_SEARCH_RESULT = 'SELECT_SEARCH_RESULT';

const initialState = new Map({
    map_search: [],
    map_search_string: '',
    selectedSearchResult: null,
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_MAP_SEARCH:
            return state.set('map_search', action.payload.items).set('map_search_string', action.payload.search);
        case SELECT_SEARCH_RESULT:
            return state.set('selectedSearchResult', action.payload.index);
    }

    return state;
}

export const getMapSearch = (search, boundingBox, limit = 20, searchEntityList = null, component = null) => async (dispatch) => {
    try {
        dispatch({
            type: GET_MAP_SEARCH,
            payload: {
                items: [],
            },
        });

        if (!search) {
            return;
        }
        const response = await api.geo.searchGeoObjects({
            component,
            search_text: search,
            bounding_box: boundingBox,
            rows_count_limit: limit,
            search_entity_list: searchEntityList,
        });

        dispatch({
            type: GET_MAP_SEARCH,
            payload: {
                search: search,
                items: response.payload.items
            }
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};


export const selectSearchResult = (index) => async (dispatch) => {
    dispatch({
        type: SELECT_SEARCH_RESULT,
        payload: {
            index,
        },
    });
};

