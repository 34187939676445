import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import classNames from 'classnames';
import Block from "components/ui/form/block";
import Select from "components/ui/select";
import systems from "dictionaries/systems";
import MapFilterExtendedTabBase from "components/modules/maps/Filter/Extended/Tab/MapFilterExtendedTabBase";
import MapFilterExtendedFilter from "components/modules/maps/Filter/Extended/Filter/MapFilterExtendedFilter";
import {getUnits} from "store/reducers/organizational_units/organizational_units";

@propTypes({
    isActive: PropTypes.bool,
})

@connect(state => ({}), {
    getUnits,
})

export default class MapFilterExtendedTabSubjects extends MapFilterExtendedTabBase {

    state = {};

    systems = {
        kiutr: 'Управление пассажирскими перевозками',
        road: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text,
        utility: 'Жилищно-коммунальное хозяйство',
        communal: 'Коммунальная техника',
        children: 'Управление перевозками детей',
        garbage: 'Контроль вывоза мусора',
        control: 'ГУ ГАТН',
    };

    getModificators() {
        if (this.isKurs()) {
            return {
                'Включая дочерние предприятия': 'extended.subjects.modificators.with_children',
                'Балансодержатель': 'extended.subjects.modificators.balanceholder',
                'Дислокация': 'extended.subjects.modificators.dislocation',
            };
        }

        return {
            'Включая дочерние предприятия': 'extended.subjects.modificators.with_children',
            'Аренда/лизинг': 'extended.subjects.modificators.rent',
            'Собственность': 'extended.subjects.modificators.own',
        };
    }

    renderContent() {
        return (
            <div>
                <Block size="xl" title="Подсистема">
                    {_.get(this.systems, _.get(this.props.filters, 'extended.parameters.component'))}
                </Block>
                <MapFilterExtendedFilter
                    title="Предприятие-владелец"
                    selected={_.get(this.props.filters, 'extended.subjects.units')}
                    onSelectedChange={this.onChange.bind(this, 'extended.subjects.units')}
                    loadData={::this.loadUnits}
                />
            </div>
        )
    }

    async loadUnits(search = null, limit = 25) {
        let filters = {};
        if (_.get(this.props.filters, 'extended.parameters.component')) {
            filters.withComponent = _.get(this.props.filters, 'extended.parameters.component');
        }

        const response = await this.props.getUnits({
            filters,
            search,
            pagination: {
                page: 1,
                limit: limit,
            },
        });

        if (response.isOk) {
            return _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.name,
            }));
        } else {
            response.showErrors();
            return [];
        }
    }

    isKurs() {
        return _.get(this.props.filters, 'extended.parameters.component') === 'road';
    }
}