import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import DashboardTable from 'components/modules/analytics/base-components/DashboardTable';
import './style.less';
import GlobalLoaderComponent from "components/ui/global-loader";
import {connect} from "react-redux";
import {getUnits} from "store/reducers/organizational_units/units";
import {getUsers, getUsersByUnits} from "store/reducers/staffing/staffing";
import _ from 'lodash';
import {getVehicleList, getVehiclesByUnits} from "store/reducers/vehicles/vehicles";
import {orderRunVehicles} from "store/reducers/kiutr/orders/order_runs";

@propTypes({})

@defaultProps({})

@connect(state => ({}), {getUnits, getUsersByUnits, getVehiclesByUnits, orderRunVehicles})

export default class VehicleDriverDataWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            data: []
        };

        this.metadata = {
            0: {field: 'name', name: 'Транспортные предприятия', sort: true},
            1: {field: 'vehicleCountOnLine', name: 'Кол-во ТС на линии', sort: true},
            2: {field: 'vehicleCountInPark', name: 'Кол-во ТС в парке', sort: true},
            3: {field: 'driverCount', name: 'Кол-во водителей', sort: true}
        };
    }

    componentWillMount() {
        this.loadUnits();
    }

    async loadUnits() {
        this.setState({
            isLoading: true,
        });
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withComponent: 'kiutr',
            },
        });

        if (response.isOk) {
            const units = response.payload.items;
            const users = await this.loadUsers(units);
            const vehicles = await this.loadVehicles(units);
            const vehiclesOnline = await this.loadVehiclesOnline(units);

            this.setState({
                data: _.map(units, (unit) => {
                    const vehicleCountOnLine = _.get(vehiclesOnline, unit.uuid);

                    return {
                        name: _.truncate(unit.name, {
                            length: 20,
                        }),
                        vehicleCountOnLine,
                        vehicleCountInPark: Math.max(0, _.get(vehicles, unit.uuid) - vehicleCountOnLine),
                        driverCount: _.get(users, unit.uuid),
                    };
                }),
            });
        } else {
            response.showErrors();
        }

        this.setState({
            isLoading: false,
        });
    }

    async loadUsers(units) {
        const response = await this.props.getUsersByUnits(_.map(units, 'uuid'), {
            filters: {
                withPositionTypes: [
                    'driver',
                ],
            },
        });
        if (response.isOk) {
            return response.payload.data;
        }
        return {};
    }

    async loadVehicles(units) {
        const response = await this.props.getVehiclesByUnits(_.map(units, 'uuid'));
        if (response.isOk) {
            return response.payload.data;
        }
        return {};
    }

    async loadVehiclesOnline(units) {
        const response = await this.props.getVehiclesByUnits(_.map(units, 'uuid'), {
            filters: {
                withStatus: [
                    'active',
                ],
            },
        });
        if (response.isOk) {
            return response.payload.data;
        }
        return {};
    }

    render() {
        const loader = this.state.isLoading ? (<GlobalLoaderComponent/>) : null;

        return (
            <div className="vehicle-driver-data-widget">
                {loader}

                <DashboardTable data={this.state.data} metadata={this.metadata}/>
            </div>
        );
    }
}