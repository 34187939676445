import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import {LabeledCheckbox} from "components/ui/checkbox";
import IconButton from "components/ui/icon-button";
import ContextTooltip from "components/ui/context-tooltip";
import Popup from "components/ui/popup";
import FilterHeader from "components/ui/filter-header";
import debounce from 'throttle-debounce/debounce';
import classNames from 'classnames';
import GlobalLoaderComponent from "components/ui/global-loader";
import LoaderComponent from "components/ui/loader";
import MapFilterExtendedFilter from "components/modules/maps/Filter/Extended/Filter/MapFilterExtendedFilter";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";

@propTypes({
    title: PropTypes.string,
    dictionaryKey: PropTypes.string.isRequired,
    selected: PropTypes.array,
    component: PropTypes.string,
    onSelectedChange: PropTypes.func.isRequired,
})

@connect(state => ({}), {
    getDictionaryList,
})

export default class MapFilterExtendedFilterDictionary extends MapFilterExtendedFilter {

    async componentWillMount() {
        const items = await this.getDictionary();
        this.setState({items});
    }

    async getDictionary() {
        let meta = {
            order: {
                column: 'name',
                direction: 'asc',
            },
        };

        if (this.props.component) {
            meta.filters = {
                withComponent: this.props.component,
            };
        }

        const response = await this.props.getDictionaryList(this.props.dictionaryKey, meta);
        if (response.isOk) {
            return _.map(response.payload.documents, (document) => ({
                value: document.uuid,
                label: document.short_name || document.name,
            }));
        } else {
            response.showErrors();
        }
    }

    async loadDataMiddleware() {
    }
}