import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import L from 'leaflet';
import 'leaflet-polylinedecorator';

import MapComponent from "components/ui/map";

import {connect} from "react-redux";
import {getHistory} from "store/reducers/maps";
import moment from "moment";
import formats from "dictionaries/formats";
import * as alerts from "helpers/alerts";

const pathStyle = {
    color: '#FF0000',
    opacity: 0.6,
};

@propTypes({
    from: PropTypes.string,
    to: PropTypes.string,
    bnso_number: PropTypes.string,
})

@connect(state => ({}), {getHistory})

export default class TimberViolationsMapComponent extends Component {

    state = {
    };

    pathLayer = null;

    componentDidMount() {
        const map = this.refs.map.getWrappedInstance().map;

        this.pathLayer = new L.featureGroup().addTo(map);
        this.loadHistory();
    }

    async loadHistory() {
        const response = await this.props.getHistory([this.props.bnso_number], moment(this.props.from).format(formats.DATETIME_API), moment(this.props.to).format(formats.DATETIME_API));
        if (response.isOk) {
            const telematics = _.first(response.payload.telematics);
            if (telematics) {
                const path = _.map(telematics.points, (point) => ([
                    point[0],
                    point[1],
                ]));
                if (path.length === 0) {
                    alerts.error('Не найден маршрут движения ТС за данный период');
                }
                this.drawPath(path);
            }
        } else {
            response.showErrors();
        }
    }

    render() {
        return (
            <MapComponent
                ref="map"
                showLayers={true}
                showSearch={false}
            />
        );
    }

    drawPath(path) {
        if (!path) return;

        const layer = new L.polyline(path, pathStyle);

        this.pathLayer.addLayer(layer);

        layer.setText('\u25B6          ', {
            repeat: true,
            offset: 5,
            attributes: {
                fill: pathStyle.color,
                'font-size': '12',
            },
        });

        this.refs.map.getWrappedInstance().fitBounds(layer.getBounds());
    }
}