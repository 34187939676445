import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import './appear.less';
import {CSSTransitionGroup} from "react-transition-group";

export default class AppearTransition extends Component {

    render() {
        return (
            <CSSTransitionGroup
                transitionName="appear"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>
                {this.props.children}
            </CSSTransitionGroup>
        )
    }

}