import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import {LabeledCheckbox} from "components/ui/checkbox";
import IconButton from "components/ui/icon-button";
import ContextTooltip from "components/ui/context-tooltip";
import Popup from "components/ui/popup";
import FilterHeader from "components/ui/filter-header";
import debounce from 'throttle-debounce/debounce';
import classNames from 'classnames';
import GlobalLoaderComponent from "components/ui/global-loader";
import LoaderComponent from "components/ui/loader";
import MapFilterExtendedFilter from "components/modules/maps/Filter/Extended/Filter/MapFilterExtendedFilter";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";

@propTypes({
    title: PropTypes.string,
    items: PropTypes.array.isRequired,
    selected: PropTypes.array,
    onSelectedChange: PropTypes.func.isRequired,
})

export default class MapFilterExtendedFilterEnum extends MapFilterExtendedFilter {

    async componentWillMount() {
        this.setState({
            items: this.props.items,
        });
    }

    async loadDataMiddleware() {
    }
}