import React from 'react';
import Popup from 'components/ui/popup';
import { LabeledCheckbox } from 'components/ui/checkbox';
import classNames from 'classnames';

export const FilterPopupView = ({
	filtersData,
	isAllSelected,
	onSelectAll,
	onCheckBoxClick,
	onPopupClose,
	choosenFilters
}) => {
	const sliderClassName = classNames({
		'b-slider__line': true,
		_selected_all: isAllSelected
	});

	const sliderCircleClassName = classNames({
		'b-slider__circle': true,
		_selected_all: isAllSelected
	});
	return (
		<Popup className={classNames('SelectFieldsPopup top-link checkbox-dropdown')} onClose={onPopupClose}>
			<div className="popup-container__content">
				<div className="content">
					{filtersData.map((item) => (
						<LabeledCheckbox
							key={item.value}
							checked={choosenFilters.includes(item.value)}
							label={item.label}
							onChange={(checked) => onCheckBoxClick(checked, item.value)}
						/>
					))}
				</div>
				{filtersData.length > 0 && (
					<div className="b-slider _options clearAfter" onClick={() => onSelectAll(isAllSelected)}>
						<div className="b-slider__title">{isAllSelected ? 'Снять все' : 'Выбрать все'}</div>
						<div className="b-slider__control">
							<div className={sliderClassName} />
							<div className={sliderCircleClassName} />
						</div>
					</div>
				)}
			</div>
		</Popup>
	);
};
