import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import $ from 'jquery';

import {connect} from "react-redux";

import nl2br from 'react-nl2br';
import L from 'leaflet';
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import classNames from 'classnames';

import {getRouteVariant, createRouteVariant, updateRouteVariant} from "store/reducers/routes/route_variants";
import GlobalLoaderComponent from "components/ui/global-loader";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import PageModal from 'components/ui/page-modal';
import TableContainer from "components/ui/Table/Container/TableContainer";
import "./variant_points_editor.less";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import ValidationError from "components/ui/validation-error";
import debounce from 'throttle-debounce/debounce';
import {getStopPoints} from "store/reducers/routes/routes";
import Select, {SelectAsync} from "components/ui/select";
import {api} from "helpers/api";
import LoaderComponent from "components/ui/loader";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import KiutrVariantMapComponent from "components/modules/kiutr/routes/variant_map";
import SlideLeftTransition from "components/ui/transitions/slide-left";
import * as alerts from "helpers/alerts";
import ContextTooltip from "components/ui/context-tooltip";
import {getRoute} from "store/reducers/routes/route_editor";
import Settings from 'settings';
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getRouteVariant, getRoute, updateRouteVariant})

export default class VariantPointsEditor extends BaseEditor {

    title = 'маршрута';

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            showMap: true,
            route: {},
        });

        this.onKeyup = ::this._onKeyup;
    }

    _onKeyup(e) {
        if (String.fromCharCode(e.which) === 'S' && e.ctrlKey === true) {
            this.onEdit();
        }
    }

    componentWillUnmount() {
        $(document).off('keyup', this.onKeyup);
    }

    async loadData(uuid) {
        const response = await this.props.getRouteVariant(uuid);
        if (response.isOk) {
            response.payload.forward_points = _.map(response.payload.forward_points, (point) => {
                point._uuid = Math.random();
                return point;
            });
            response.payload.reverse_points = _.map(response.payload.reverse_points, (point) => {
                point._uuid = Math.random();
                return point;
            });
            response.payload.route_number = await this.getRouteNumber(response.payload.route_uuid);
        }

        return response;
    }

    async getRouteNumber(uuid) {
        const response = await this.props.getRoute(uuid);

        if (response.isOk) {
            this.setState({
                route: response.payload,
            });
            return response.payload.number;
        }
    }

    async createItem(data) {
    }

    async updateItem(data) {
        return await this.props.updateRouteVariant(data);
    }

    async editReal(data) {
        this.clearErrors();
        this.startSave();

        const response = await this.updateItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            this.props.onSubmit(response.payload);
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async edit(data) {
        this.editReal(data);
    }

    async componentDidMount() {
        $(document).on('keyup', this.onKeyup);
        this.setState({
            uuid: this.props.uuid,
            item: null,
        });
        if (this.props.uuid) {
            this.setState({isLoading: true});
            const response = await this.loadData(this.props.uuid);
            if (response.isOk) {
                this.setState({
                    item: response.payload,
                    isLoading: false,
                });
            } else {
                response.showErrors();
            }
        }
    }

    getForm(item, onSubmit) {
        item.forward_points = _.map(item.forward_points, (point) => {
            if (point.distance_to_the_next_point) {
                point.distance_manual_edit = true;
            }
            if (point.time_to_get_to_the_next_point) {
                point.time_manual_edit = true;
            }
            return point;
        });
        item.reverse_points = _.map(item.reverse_points, (point) => {
            if (point.distance_to_the_next_point) {
                point.distance_manual_edit = true;
            }
            if (point.time_to_get_to_the_next_point) {
                point.time_manual_edit = true;
            }
            return point;
        });

        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                showMap={this.state.showMap}
                errors={this.state.errors}
                route={this.state.route}
                clearErrors={::this.clearErrors}
                turnedEditingMode={this.turnedEditingMode}
            />
        );
    }

    async toggleMap() {
        await this.setState({showMap: !this.state.showMap});
    }

    async showMap() {
        await this.setState({showMap: true});
    }

    async hideMap() {
        await this.setState({showMap: false});
    }

    render() {
        let form;
        let buttons;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        let title = '';

        if (this.state.item) {
            form = this.getForm(this.state.item, ::this.edit);

            title = `Редактирование маршрута №${this.state.item.route_number}`;

            buttons = (
                <ModalTopMenuButtons>
                    <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                        <ModalTopMenuButton
                            className="_save"
                            title="Сохранить"
                            onClick={::this.onEdit}
                        />
                    </ContextTooltip>

                    <ModalTopMenuButtonsSeparator/>
                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={::this.props.onClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );
        }

        const className = classNames(`profile-modal b-modal-${this.props.mode} b-modal-route map-route-editor-modal`);

        return (
            <div>
                <PageModal
                    header={{title, buttons}}
                    onClose={this.props.onClose}
                    className={className}
                >
                    {loader}
                    {form}
                </PageModal>
            </div>
        );
    }

    composeItem(data) {
        let item = _.clone(data);

        return item;
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    turnedEditingMode: PropTypes.func,
})

@connect((state) => ({}), {getStopPoints, getDictionaryList}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        route_variant: {},
        route_recalc_in_progress: {
            forward_points: [],
            reverse_points: [],
        },
        forwardVisible: true,
        reverseVisible: true,
        permanentTooltips: true,
        stopPoints: {},
        pairedStopPoints: {},
        route_types: [],
        isNeedReverseRouteVariantsFill: true,
    };

    getData() {
        return this.state.route_variant;
    }

    async componentDidMount() {
        await this.setState({
            route_variant: this.props.data,
        });
        await this.loadDictionaries([
            'route_types',
        ]);
        await this.preloadStopPoints();
        if (_.filter(_.concat((this.state.route_variant.forward_points || []).slice(0, -1), (this.state.route_variant.reverse_points || []).slice(0, -1)), (point) => {
                return (point.point_type === 'stop_point') && !point.path_to_the_next_point_geometry;
            }).length > 0) {
            this.recalcPath();
        }
    }

    onChangeInput(field, {target: {value, type}}) {
        super.onChangeInput(field, {target: {value, type}});
        this.props.turnedEditingMode();
    }

    async preloadPairedStopPoints(updatedIndex = null) {
        let pairedStopPoints = this.state.pairedStopPoints;

        const threshold = Settings.get('paired_stop_points_threshold', 300);

        await Promise.all(_.map(this.state.route_variant.forward_points, async (point, index) => {
            if ((updatedIndex !== null) && (updatedIndex !== index)) {
                return;
            }
            if (point.point_type !== 'stop_point') {
                return;
            }

            const reverseIndex = this.state.route_variant.reverse_points.length - index - 1;
            const reversePointUuid = _.get(this.state.route_variant.reverse_points, `${reverseIndex}.type_uuid`);
            const reversePoint = _.get(this.state.stopPoints, reversePointUuid);

            if (!point.latitude || !point.longitude) {
                return;
            }
            const bounds = L.latLng(point.latitude, point.longitude).toBounds(threshold);

            const response = await this.props.getStopPoints({
                filters: {
                    withBoundingBoxExact: {
                        left_top: {
                            latitude: bounds.getNorth(),
                            longitude: bounds.getWest(),
                        },
                        right_bottom: {
                            latitude: bounds.getSouth(),
                            longitude: bounds.getEast(),
                        },
                    },
                },
            });

            if (response.isOk) {
                pairedStopPoints[point.type_uuid] = _.map(_.uniqBy(_.filter(_.concat([reversePoint], response.payload.items)), 'uuid'), (item) => ({
                    label: `${item.register_number} ${item.title}`,
                    value: item.uuid,
                    title: item.title,
                    latitude: item.latitude,
                    longitude: item.longitude,
                }));
            } else {
                response.showErrors();
            }
        }));

        this.setState({
            pairedStopPoints,
        });
    }

    get(path, defaultValue = null) {
        return _.get(this.state.route_variant, path, defaultValue);
    }

    handleTumbler() {
        this.setState(prevState => ({
            isNeedReverseRouteVariantsFill: !prevState.isNeedReverseRouteVariantsFill
        }))
    }

    render() {
        return (
            <div className="VariantPointsEditor">
                <Accordion>
                    <AccordionItem
                        opened={true}
                        title={`Прямое направление, ${this.calculateLength('forward_points')}км`}
                        afterTitle={<div className="accordion__menu">
                            <div>
                                {window.RNIS_SETTINGS.reverseStopPointsTumbler ? <ContextTooltip key="variant-map.reverse-toggle"
                                                                                                 code="variant-map.reverse-toggle"
                                                                                                 default="изменять обратное направление">
                                    <div
                                        className={classNames('accordion__link accordion__link_icon accordion__link_icon_stop_reverse', {
                                            active: this.state.isNeedReverseRouteVariantsFill,
                                        })} onClick={::this.handleTumbler}/>
                                </ContextTooltip> : null}
                                <ContextTooltip key="variant-map.permanent-toggle"
                                                code="variant-map.permanent-toggle"
                                                default="Включить/отключить наименования остановок">
                                    <div
                                        className={classNames('accordion__link accordion__link_icon accordion__link_icon_stop', {
                                            active: this.state.permanentTooltips,
                                        })} onClick={::this.togglePermanentTooltips}/>
                                </ContextTooltip>
                                <ContextTooltip key="variant-map.toggle"
                                                code="variant-map.toggle"
                                                default="Включить/отключить отображение направления">
                                    <div
                                        className={classNames('accordion__link accordion__link_icon accordion__link_icon_direction', {
                                            active: this.state.forwardVisible,
                                        })} onClick={::this.toggleForwardVisible}/>
                                </ContextTooltip>
                            </div>
                        </div>}
                    >
                        {this.renderTable('forward_points')}
                    </AccordionItem>
                    {(this.isBothSide()) ? (
                        <AccordionItem
                            opened={true}
                            title={`Обратное направление, ${this.calculateLength('reverse_points')}км`}
                            afterTitle={<div className="accordion__menu">
                                <ContextTooltip key="variant-map.toggle"
                                                code="variant-map.toggle"
                                                default="Включить/отключить отображение направления">
                                    <div
                                        className={classNames('accordion__link accordion__link_icon accordion__link_icon_direction', {
                                            active: this.state.reverseVisible,
                                        })} onClick={::this.toggleReverseVisible}/>
                                </ContextTooltip>
                            </div>}
                        >
                            {this.renderTable('reverse_points')}
                        </AccordionItem>
                    ) : null}
                </Accordion>
                {this.props.showMap ? (
                    <KiutrVariantMapComponent
                        router={this.props.router}
                        ref="map"
                        variant={this.state.route_variant}
                        onUpdate={::this.onUpdate}
                        deletePoint={::this.deletePoint}
                        addStopPoint={::this.addStopPoint}
                        killRecalc={::this.killRecalc}
                        stopPoints={this.state.stopPoints}
                        forwardVisible={this.state.forwardVisible}
                        reverseVisible={this.state.reverseVisible}
                        permanentTooltips={this.state.permanentTooltips}
                        isBothSide={this.isBothSide()}
                    />
                ) : null}
            </div>
        );
    }

    togglePermanentTooltips() {
        this.setState({
            permanentTooltips: !this.state.permanentTooltips,
        });
    }

    toggleForwardVisible() {
        this.setState({
            forwardVisible: !this.state.forwardVisible,
        });
    }

    toggleReverseVisible() {
        this.setState({
            reverseVisible: !this.state.reverseVisible,
        });
    }

    calculateLength(direction) {
        const points = _.filter(this.get(direction) || [], (point) => {
            return point.point_type === 'stop_point';
        });
        const distance = _.sumBy(points, 'distance_to_the_next_point') / 1000;
        return Math.round(distance * 100) / 100;
    }

    async deletePoint(direction, index) {
        let variant = this.state.route_variant;
        const type = variant[direction][index].point_type;

        variant[direction].splice(index, 1);

        await this.setState({
            route_variant: variant,
        });

        if (type === 'point' || type === 'control_point') {
            this.recalcPath(direction, index - 1);
        } else {
            this.recalcPath();
        }
    }

    async onUpdate(route_variant, direction = null, index = null) {
        await this.setState({route_variant});

        this.recalcPath(direction, index);
    }

    isBothSide() {
        return _.get(_.find(this.state.route_types, {value: this.props.route.route_type_uuid}), 'label') === 'Маятниковый';
    }

    renderTable(direction) {
        const points = this.get(direction) || [];

        return (
            <div className="PointsTable Table">
                <TableContainer>
                    <table className="b-table b-table-no-hover">
                        <thead>
                        <tr>
                            <th width="65px">№</th>
                            <th key="distance" width="100px">Расстояние, м</th>
                            <th key="time" width="100px">Время, мин</th>
                            <th key="control" width="50px">КП</th>
                            <th>Остановочный пункт</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr key={`${direction}:first-add`}>
                            <td className="height-zero">
                                <a className="add-point" href="javascript:void(0)"
                                   onClick={this.addRow.bind(this, direction, -1)}>+</a>
                            </td>
                            <td className="height-zero" colSpan={4}/>
                        </tr>
                        {points.map(this.renderRow.bind(this, direction))}
                        </tbody>
                    </table>
                </TableContainer>
            </div>
        );
    }

    async addStopPoint(stopPointUuid, latitude, longitude, direction = null) {
        let route_variant = this.state.route_variant;

        if (!direction) {
            direction = this.state.forwardVisible ? 'forward_points' : 'reverse_points';
        }

        route_variant[direction].push({
            latitude: latitude,
            longitude: longitude,
            is_control: true,
            point_type: 'stop_point',
            type_uuid: stopPointUuid,
            is_routing_enabled: true,
            distance_to_the_next_point: 0,
            time_to_get_to_the_next_point: 0,
        });

        await this.setState({route_variant});

        this.recalcPath(direction, route_variant[direction].length - 2);
        if (window.RNIS_SETTINGS.reverseStopPointsTumbler === false) {
            if ((direction === 'forward_points') && this.isBothSide()) {
                await this.preloadPairedStopPoints(route_variant.forward_points.length - 1);

                const stopPointTitle = _.get(_.find(_.get(this.state.pairedStopPoints, stopPointUuid, []), {value: stopPointUuid}), 'title');
                const pairedStopPoints = _.filter(_.get(this.state.pairedStopPoints, stopPointUuid, []), (stopPoint) => {
                    return (stopPoint.value !== stopPointUuid) && (stopPoint.title === stopPointTitle);
                });

                route_variant.reverse_points.splice(0, 0, {
                    latitude: (pairedStopPoints.length === 1) ? _.first(pairedStopPoints).latitude : latitude,
                    longitude: (pairedStopPoints.length === 1) ? _.first(pairedStopPoints).longitude : longitude,
                    point_type: 'stop_point',
                    type_uuid: (pairedStopPoints.length === 1) ? _.first(pairedStopPoints).value : stopPointUuid,
                    is_routing_enabled: true,
                    distance_to_the_next_point: 0,
                    time_to_get_to_the_next_point: 0,
                    is_control: true,
                });

                await this.setState({route_variant});
                this.recalcPath('reverse_points', 0);
            }
        } else {
            if (this.state.isNeedReverseRouteVariantsFill) {
                if ((direction === 'forward_points') && this.isBothSide()) {
                    await this.preloadPairedStopPoints(route_variant.forward_points.length - 1);

                    const stopPointTitle = _.get(_.find(_.get(this.state.pairedStopPoints, stopPointUuid, []), {value: stopPointUuid}), 'title');
                    const pairedStopPoints = _.filter(_.get(this.state.pairedStopPoints, stopPointUuid, []), (stopPoint) => {
                        return (stopPoint.value !== stopPointUuid) && (stopPoint.title === stopPointTitle);
                    });

                    route_variant.reverse_points.splice(0, 0, {
                        latitude: (pairedStopPoints.length === 1) ? _.first(pairedStopPoints).latitude : latitude,
                        longitude: (pairedStopPoints.length === 1) ? _.first(pairedStopPoints).longitude : longitude,
                        point_type: 'stop_point',
                        type_uuid: (pairedStopPoints.length === 1) ? _.first(pairedStopPoints).value : stopPointUuid,
                        is_routing_enabled: true,
                        distance_to_the_next_point: 0,
                        time_to_get_to_the_next_point: 0,
                        is_control: true,
                    });

                    await this.setState({route_variant});
                    this.recalcPath('reverse_points', 0);
                }
            }
        }

    }

    async addRow(direction, index = null) {
        let route_variant = this.state.route_variant;

        if (index === null) {
            route_variant[direction].push({
                latitude: null,
                longitude: null,
                is_control: true,
                point_type: 'stop_point',
                type_uuid: null,
                distance_to_the_next_point: 0,
                time_to_get_to_the_next_point: 0,
                is_routing_enabled: true,
            });
        } else {
            route_variant[direction].splice(index + 1, 0, {
                latitude: null,
                longitude: null,
                is_control: index + 1 === route_variant[direction].length,
                point_type: 'stop_point',
                type_uuid: null,
                distance_to_the_next_point: 0,
                time_to_get_to_the_next_point: 0,
                is_routing_enabled: true,
            });
        }

        await this.setState({route_variant});

        //this.refs[`stop_point.forward_points.${route_variant.forward_points.length - 1}`].focus();
        this.props.clearErrors();
    }

    async deleteRow(direction, index, e) {
        e.preventDefault();


        let route_variant = this.state.route_variant;

        alerts.prompt('Удалить точку маршрута?', '', async () => {
            route_variant[direction].splice(index, 1);

            await this.setState({route_variant});
            this.recalcPath(direction, index - 1);

            this.props.clearErrors();
        });
    }

    async loadStopPoints(direction, index, input, callback) {
        if (!input) {
            callback(null, {
                option: [],
                complete: false,
            });
            return;
        }
        let meta = {
            search: input,
        };
        if (this.props.route.vis_id) {
            meta.filters = {
                withUuid: _.uniq(_.filter(_.map(this.props.route.default_variant[direction], 'type_uuid'))),
            };
        }
        const result = await this.props.getStopPoints(meta, true);

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: `${i.register_number} ${i.title}`,
                    value: i.uuid,
                    latitude: i.latitude,
                    longitude: i.longitude,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    async setValue(field, value) {
        let state = this.getState();

        _.set(state, field, value);

        const matches = /route_variant\.([^.]+)\.([^.]+)\.distance_to_the_next_point/.exec(field);
        if (matches) {
            _.set(state, `route_variant.${matches[1]}.${matches[2]}.distance_manual_edit`, !!value);
        }
        const matches2 = /route_variant\.([^.]+)\.([^.]+)\.time_to_get_to_the_next_point/.exec(field);
        if (matches2) {
            _.set(state, `route_variant.${matches2[1]}.${matches2[2]}.time_manual_edit`, !!value);
        }

        await this.setState(state);
    }

    stopPointIndex(direction, index) {
        let count = 0;
        while (index >= 0) {
            if (this.state.route_variant[direction][index].point_type === 'stop_point') {
                count++;
            }
            index--;
        }
        return count;
    }

    async moveUp(direction, index) {
        let route_variant = this.state.route_variant;

        const point = route_variant[direction].splice(index, 1);
        route_variant[direction].splice(index - 1, 0, ...point);

        await this.setState({
            route_variant,
        });

        this.recalcPath(direction, index - 1);
        this.recalcPath(direction, index);
    }

    async moveDown(direction, index) {
        let route_variant = this.state.route_variant;

        const point = route_variant[direction].splice(index, 1);
        route_variant[direction].splice(index + 1, 0, ...point);

        await this.setState({
            route_variant,
        });

        this.recalcPath(direction, index);
        this.recalcPath(direction, index + 1);
    }

    renderRow(direction, point, index) {
        if (point.point_type !== 'stop_point' && point.point_type !== 'stop_point_inactive') {
            return;
        }
        const reverseIndex = this.state.route_variant.reverse_points.length - index - 1;

        const streets = this.getAllStopPointStreets(direction, index);

        const inactive = point.point_type === 'stop_point_inactive';

        return ([
            <tr key={`${index}:${point.type_uuid}`} className={classNames({inactive})}>
                <td className="align-center">
                    {inactive ? '' : this.stopPointIndex(direction, index)}
                    <a href="#" onClick={this.deleteRow.bind(this, direction, index)}>&times;</a>
                    {(index > 0) ? (
                        <a className="move-up" href="javascript:void(0)"
                           onClick={this.moveUp.bind(this, direction, index)}>&uarr;</a>
                    ) : null}
                    {(index < this.get(direction).length - 1) ? (
                        <a className="move-down" href="javascript:void(0)"
                           onClick={this.moveDown.bind(this, direction, index)}>&darr;</a>
                    ) : null}
                </td>
                <td key={`distance:${index}`}
                    className="align-center">{this.formatDistance(this.get(`${direction}.${index}.distance_to_the_next_point`))}</td>
                <td key={`time:${index}`}
                    className="align-center">{this.get(`${direction}.${index}.time_to_get_to_the_next_point`)}</td>
                <td>
                    {this.checkbox(`route_variant.${direction}.${index}.is_control`)}
                </td>
                <td>
                    <SelectAsync
                        ref={`stop_point.${direction}.${index}`}
                        onInputKeyDown={::this.onStopPointKeyDown}
                        name={`route_variant.${direction}.${index}.type_uuid`}
                        value={this.get(`${direction}.${index}.type_uuid`)}
                        options={_.map(this.state.stopPoints, (item) => ({
                            value: item.uuid,
                            label: `${item.register_number} ${item.title}`,
                            latitude: item.latitude,
                            longitude: item.longitude,
                        }))}
                        loadOptions={this.loadStopPoints.bind(this, direction, index)}
                        onChange={this.onChangeStopPoint.bind(this, direction, index)}
                    />
                    <ValidationError error={_.get(this.props.errors, `route_variant.${direction}.${index}.type_uuid`)}/>
                    {((streets.length > 0)) ? (
                        <div>
                            <span className="streets-toggle"
                                  onClick={::this.toggleStreet}>Отобразить/скрыть улицы</span>
                            <div className="streets hide">
                                {nl2br(streets.join("\n"))}
                            </div>
                        </div>
                    ) : null}
                </td>
            </tr>,
            <tr key={`${direction}:${index}:add`}>
                <td className="height-zero">
                    <a className="add-point" href="javascript:void(0)"
                       onClick={this.addRow.bind(this, direction, index)}>+</a>
                </td>
                <td className="height-zero" colSpan={3}/>
            </tr>
        ]);
    }

    async onStopPointKeyDown(e) {
        if (e.keyCode === 13) {
            this.addRow('forward_points');
        }
    }

    formatDistance(distance) {
        return Math.round(distance * 100) / 100;
    }

    toggleStreet(e) {
        const el = $(e.target).next();
        el.toggleClass('hide');
    }

    getAllStopPointStreets(direction, index) {
        let streets = this.state.route_variant[direction][index].streets || [];
        index++;

        while (index < this.state.route_variant[direction].length && this.state.route_variant[direction][index].point_type !== 'stop_point') {
            streets = _.concat(streets, this.state.route_variant[direction][index].streets);
            index++;
        }

        return this.filterStreets(streets);
    }

    filterStreets(streets) {
        let result = [];
        let current = null;

        _.each(streets, (name) => {
            if (name !== current) {
                current = name;
                result.push(name);
            }
        });

        return result;
    }

    shouldHasGeometry(direction, index) {
        const route_variant = this.state.route_variant;

        if ((route_variant.forward_points.length > 0) && (route_variant.reverse_points.length > 0)) {
            if (direction === 'reverse_points' && index === route_variant.reverse_points.length - 1) {
                return false;
            }
        } else {
            return index !== route_variant[direction].length - 1;
        }

        return true;
    }

    async onChangeStopPoint(direction, index, selected) {
        const value = selected ? selected.value : null;
        const latitude = selected ? selected.latitude : null;
        const longitude = selected ? selected.longitude : null;

        await Promise.all([
            this.setValue(`route_variant.${direction}.${index}.type_uuid`, value),
            this.setValue(`route_variant.${direction}.${index}.latitude`, latitude),
            this.setValue(`route_variant.${direction}.${index}.longitude`, longitude),
        ]);

        let reverseIndex = null;
        if (window.RNIS_SETTINGS.reverseStopPointsTumbler === false) {
            if ((direction === 'forward_points') && this.isBothSide()) {
                await this.preloadPairedStopPoints(index);

                const stopPointTitle = _.get(_.find(_.get(this.state.pairedStopPoints, value, []), {value: value}), 'title');
                const pairedStopPoints = _.filter(_.get(this.state.pairedStopPoints, value, []), (stopPoint) => {
                    return (stopPoint.value !== value) && (stopPoint.title === stopPointTitle);
                });

                reverseIndex = this.state.route_variant.reverse_points.length - index - 1;
                await Promise.all([
                    this.setValue(`route_variant.reverse_points.${reverseIndex}.type_uuid`, (pairedStopPoints.length === 1) ? _.first(pairedStopPoints).value : value),
                    this.setValue(`route_variant.reverse_points.${reverseIndex}.latitude`, (pairedStopPoints.length === 1) ? _.first(pairedStopPoints).latitude : latitude),
                    this.setValue(`route_variant.reverse_points.${reverseIndex}.longitude`, (pairedStopPoints.length === 1) ? _.first(pairedStopPoints).longitude : longitude),
                ]);
            }
        } else {
            if(this.state.isNeedReverseRouteVariantsFill) {
                if ((direction === 'forward_points') && this.isBothSide()) {
                    await this.preloadPairedStopPoints(index);

                    const stopPointTitle = _.get(_.find(_.get(this.state.pairedStopPoints, value, []), {value: value}), 'title');
                    const pairedStopPoints = _.filter(_.get(this.state.pairedStopPoints, value, []), (stopPoint) => {
                        return (stopPoint.value !== value) && (stopPoint.title === stopPointTitle);
                    });

                    reverseIndex = this.state.route_variant.reverse_points.length - index - 1;
                    await Promise.all([
                        this.setValue(`route_variant.reverse_points.${reverseIndex}.type_uuid`, (pairedStopPoints.length === 1) ? _.first(pairedStopPoints).value : value),
                        this.setValue(`route_variant.reverse_points.${reverseIndex}.latitude`, (pairedStopPoints.length === 1) ? _.first(pairedStopPoints).latitude : latitude),
                        this.setValue(`route_variant.reverse_points.${reverseIndex}.longitude`, (pairedStopPoints.length === 1) ? _.first(pairedStopPoints).longitude : longitude),
                    ]);
                }
            }
        }

        this.preloadStopPoints();

        if (index > 0) {
            this.recalcPath('forward_points', index - 1);
        }
        this.recalcPath('forward_points', index);
        if (reverseIndex !== null) {
            this.recalcPath('reverse_points', reverseIndex);
            if (reverseIndex > 0) {
                this.recalcPath('reverse_points', reverseIndex - 1);
            }
        }

        if (latitude && longitude) {
            this.refs.map && this.refs.map.panTo([latitude, longitude]);
        }
    }

    async clearPointsPaths() {
        let route_variant = this.state.route_variant;
        _.each(['forward_points', 'reverse_points'], (direction) => {
            _.each(route_variant[direction], (point) => {
                point.distance_to_the_next_point = 0;
                point.time_to_get_to_the_next_point = 0;
                point.path_to_the_next_point_geometry = null;
            });
        });
        await this.setState({route_variant});
    }

    getPreviousStopPointIndex(direction, index) {
        while (index >= 0 && this.state.route_variant[direction][index].point_type !== 'stop_point') {
            index--;
        }
        return index;
    }

    updatePointInfo(direction, index, pointInfo = null) {
        let route_variant = this.state.route_variant;

        this.stopRecalc(direction, index);

        const createPointGeometry = (points) => {
            if (points && points.length > 0) {
                return {
                    type: 'LineString',
                    coordinates: _.map(points, (coord) => _.clone(coord).reverse()),
                };
            }

            return null;
        };

        route_variant[direction][index].path_to_the_next_point_geometry = pointInfo ? createPointGeometry(pointInfo.points) : null;
        route_variant[direction][index].streets = pointInfo ? _.map(pointInfo.streets, 'name') : [];

        const pointIndex = this.getPreviousStopPointIndex(direction, index);
        //if (!route_variant[direction][pointIndex].distance_manual_edit) {
        route_variant[direction][pointIndex].distance_to_the_next_point = pointInfo ? pointInfo.distance : 0;
        //}
        //if (!route_variant[direction][pointIndex].time_manual_edit) {
        if (pointInfo && pointInfo.time !== null) {
            route_variant[direction][pointIndex].time_to_get_to_the_next_point = pointInfo ? Math.round(pointInfo.time / 60) : 0;
        }
        //}

        this.setState({
            route_variant,
        });
    }

    inRecalc(direction, index) {
        return this.state.route_recalc_in_progress[direction][index];
    }

    async startRecalc(direction, index) {
        let recalcProgress = this.state.route_recalc_in_progress;
        recalcProgress[direction][index] = true;
        this.setState({route_recalc_in_progress: recalcProgress});
    }

    async stopRecalc(direction, index) {
        let recalcProgress = this.state.route_recalc_in_progress;
        recalcProgress[direction][index] = false;
        this.setState({route_recalc_in_progress: recalcProgress});
    }

    killRecalc() {
        this.setState({});
    }

    async recalcPath(updatedDirection = null, updatedIndex = null) {
        const route_variant = this.state.route_variant;
        if (!updatedDirection) {
            await this.clearPointsPaths();
        }
        _.each(['forward_points', 'reverse_points'], async (direction) => {
            if (updatedDirection && direction !== updatedDirection) {
                return;
            }

            for (let i = 0; i <= route_variant[direction].length - 1; i++) {
                if ((updatedIndex !== null) && (i !== this.getPreviousStopPointIndex(direction, updatedIndex))) {
                    continue;
                }
                if (route_variant[direction][i].point_type !== 'stop_point') {
                    continue;
                }
                await this.startRecalc(direction, i);
                let points = [];
                points.push(route_variant[direction][i]);
                let index = i + 1;
                while ((index <= route_variant[direction].length - 1) && (route_variant[direction][index].point_type === 'stop_point_inactive' || route_variant[direction][index].point_type === 'point' || route_variant[direction][index].point_type === 'control_point')) {
                    index++;
                }
                if (index === route_variant[direction].length) {
                    this.updatePointInfo(direction, i, null);
                    continue;
                }
                for (let _i = i + 1; _i < index; _i++) {
                    if (route_variant[direction][_i].point_type !== 'stop_point_inactive') {
                        points.push(route_variant[direction][_i]);
                    }
                }

                points.push(route_variant[direction][index]);
                const sector = _.map(points, ({latitude, longitude}) => ({
                    latitude,
                    longitude,
                }));
                if (!route_variant[direction][i].is_routing_enabled) {
                    let distance = 0;
                    for (let i = 1; i < points.length; i++) {
                        const from = new L.latLng(points[i - 1].latitude, points[i - 1].longitude);
                        const to = new L.latLng(points[i].latitude, points[i].longitude);
                        distance += from.distanceTo(to);
                    }
                    this.updatePointInfo(direction, i, {
                        points: _.map(points, ({latitude, longitude}) => ([
                            latitude,
                            longitude,
                        ])),
                        distance,
                        time: null,
                        streets: [],
                    });
                } else {
                    api.geo.routing(sector).then((response) => {
                        if (response.success) {
                            this.updatePointInfo(direction, i, response.payload);
                        }
                    }, () => {
                        this.updatePointInfo(direction, i, {
                            points: [],
                            distance: 0,
                            time: 0,
                            streets: [],
                        });
                    });
                }
            }
        });
        this.preloadStopPoints();
    }

    async preloadStopPoints() {
        const variant = this.state.route_variant;
        const stopPointsUuids = _.filter(_.concat(_.map(variant.forward_points, 'type_uuid'), _.map(variant.reverse_points, 'type_uuid')));

        const response = await this.props.getStopPoints({
            filters: {
                withUuid: stopPointsUuids,
            },
            pagination: {
                page: 1,
                limit: 1000,
            },
        });
        if (response.isOk) {
            await this.setState({
                stopPoints: _.keyBy(response.payload.items, 'uuid'),
            });
        }
    }
}
