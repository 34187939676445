import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import _ from 'lodash';

import './map-vehicle-cluster-icon.less';

@propTypes({
    info: PropTypes.object.isRequired,
})

@defaultProps({})

export default class MapVehicleClusterIcon extends Component {

    sizeClassNames = {
        1: 'sector_size_onehundred',
        2: 'sector_size_fifty',
        3: 'sector_size_thirtythree',
        4: 'sector_size_twentyfive',
        5: 'sector_size_twenty',
    };

    render() {
        const info = this.props.info;

        const sizeClassName = this.sizeClassNames[_.keys(info).length];

        const sectors = _.map(info, (count, type) => {
            const className = classNames('sector', sizeClassName, `sector_type_${type}`);

            return (
                <div className={className} key={type}>
                    <div className="sector__slice">
                        <div className="sector__content"/>
                    </div>
                </div>
            );
        });

        const sectorsInfo = _.map(info, (count, type) => {
            const className = classNames('sector', sizeClassName);

            return (
                <div className={className} key={type}>
                    <span className="sector__value">{count}</span>
                </div>
            );
        });

        return (
            <div>
                <div className="cluster__sectors">
                    {sectors}
                </div>
                <div className="cluster__info">
                    {sectorsInfo}
                </div>
            </div>
        );
    }
}