import React from 'react';
import ReactDOM from 'react-dom';
import { Router, browserHistory, createRoutes, match } from 'react-router';
import { Provider } from 'react-redux'
import { AppContainer } from 'react-hot-loader';
import createStore  from './store/createStore';
import routes from './components/routes';
import 'whatwg-fetch';
import injectTapEventPlugin from 'react-tap-event-plugin';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

global.Buffer = require('buffer');

injectTapEventPlugin();

export const store = createStore();

const content = (
    <Provider store={store}>
        <Router history={browserHistory} routes={routes} />
    </Provider>
);

Sentry.init({
    dsn: 'https://3b051899914448c085a8bdae8c093a8e@sentry.t1-group.ru/21',
    integrations: [
        new BrowserTracing(Sentry.reactRouterV5Instrumentation(
            browserHistory,
            createRoutes(routes),
            match,
        )),
        new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.3,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // uncomment to enable sentry in production
    // enabled: process.env.NODE_ENV === 'production',
    enabled: false,
});

const render = Component => ReactDOM.render(
    <AppContainer>
        {content}
    </AppContainer>,
    document.getElementById('root'));

render();
//console.info("Version of app: ", "19.05.2021 - 15:40")