import React, {PureComponent} from 'react';
import _ from 'lodash';
import L from 'leaflet';
import ReactPopup from "react-leaflet-popup";
import 'leaflet.pm';
import 'leaflet.pm/dist/leaflet.pm.css';

import './styles.less';
import {geocode} from 'store/reducers/geo/geocode';


export default class RouteCreator extends PureComponent {
    static defaultProps = {
        onChange: () => {
        },
    }

    constructor(props) {
        super(props);
        this.totalDistance = 0;
        this.nodes = [];
        this.lines = [];
        this.passGroup = null;
        this.nodesGroup = null;
        this.nodeHighlight = null;
        this.tempNodeStatus = "outline";
        this.highlightStyles = {
            radius: 15,
            color: '#f65d50',
            fill: true,
            fillOpacity: 0.3,
            stroke: false,
        };
        this.nodeStyles = {
            radius: 5,
            color: '#f65d50',
            fill: true,
            fillOpacity: 1,
            fillColor: 'white',
            weight: 4
        };
        this.lineStyles = {
            color: '#f75d50',
            weight: 2
        };
        this.state = {
            isDrawMode: false
        }
    }

    componentDidUpdate() {
        this.props.action && this.actionHandler(this.props.action);
        this.init();
    }

    actionHandler = (action) => {
        if (action.type === 'removeNode') {
            this.removeNode(action.data)();
        }
    }

    init = () => {
        const {map} = this.props;
        if (!map) {
            return;
        }

        if (this.props.isActive && !this.state.isDrawMode) {
            this.passGroup = L.layerGroup().addTo(map);
            this.nodesGroup = L.layerGroup().addTo(map);
            map.on('click', this.drawPass);
            this.setState({isDrawMode: true});
        } else if (!this.props.isActive && this.state.isDrawMode) {
            map.off('click');
            this.nodes = [];
            this.lines = [];
            this.totalDistance = 0;
            this.passGroup && this.passGroup.remove();
            this.nodesGroup && this.nodesGroup.remove();
            this.nodeHighlight && this.nodeHighlight.remove();
            this.setState({isDrawMode: false});
        }
    }

    drawPass = (e) => {
        if (this.props.isFrozen) return;
        const {map} = this.props;
        const currentCoords = [e.latlng.lat, e.latlng.lng];
        if (this.nodes.length) {
            const lastNode = _.last(this.nodes);
            const lineCoords = [lastNode.coords, currentCoords];
            const polyline = L.polyline(lineCoords, this.lineStyles).addTo(map);
            this.passGroup.addLayer(polyline);
            const distance = e.latlng.distanceTo(lastNode.coords);
            this.totalDistance += distance;
            const line = {
                id: _.uniqueId(),
                position: this.lines.length + 1,
                element: polyline,
                distance
            };
            this.lines.push(line);
            lastNode.element.bringToFront();
            polyline.on('mouseover', this.addTempNode(line.id));
            polyline.on('mouseout', this.removeTempNode);
        }
        const circle = L.circleMarker(currentCoords, this.nodeStyles).addTo(map);
        const node = {
            id: _.uniqueId(),
            position: this.nodes.length + 1,
            element: circle,
            coords: currentCoords,
        };
        this.nodes.push(node);
        this.nodes.length > 1 && this.showPopup(node.id, circle)();
        this.nodesGroup.addLayer(circle);

        circle.on('click', this.showPopup(node.id, circle))
        circle.on('mousedown', this.startMoveNode(node.id));
        circle.on('mouseup', () => {
            this.stopMoveNode();
            this.showPopup(node.id, circle)();
        });
        circle.on('dblclick', this.removeNode(node.id));
        L.DomEvent.disableClickPropagation(circle);
        this.props.onChange([...this.nodes])
    }

    showPopup = (nodeId, element) => {
        return async () => {
            element.bindPopup(this.createPopup(nodeId)).openPopup()
            const node = this.nodes.find(node => node.id === nodeId);
            this.nodeHighlight && this.nodeHighlight.remove();
            this.nodeHighlight = L.circleMarker(node.coords, this.highlightStyles).addTo(this.props.map).bringToBack();
            const response = await geocode({
                coordinates: {
                    latitude: node.coords[0],
                    longitude: node.coords[1],
                },
            })();
            if (response.isOk) {
                node.address = response.payload.address;
                element.bindPopup(this.createPopup(nodeId, response.payload.address)).openPopup()
            } else {
                node.address = '-';
                element.bindPopup(this.createPopup(nodeId, '-')).openPopup()
            }
        }
    }

    createPopup = (nodeId, address = '') => {
        const node = this.nodes.find(node => node.id === nodeId);
        const distance = this.lines.filter(line => line.position < node.position)
            .reduce((acc, item) => acc + item.distance, 0);

        return new ReactPopup({
            reactComponent: this.props.popup,
            reactComponentProps: {
                totalDistance: this.totalDistance,
                distance,
                node,
                address,
            },
            minWidth: 390,
            maxWidth: 390,
            offset: new L.Point(0, -6),
        });
    }

    startMoveNode = id => () => {
        if (this.props.isFrozen) return;
        this.props.map.dragging.disable();
        const node = this.nodes.find(node => node.id === id);
        const lineBefore = this.lines.find(line => line.position === node.position - 1);
        const lineAfter = this.lines.find(line => line.position === node.position);
        const nodeBefore = this.nodes.find(n => n.position === node.position - 1);
        const nodeAfter = this.nodes.find(n => n.position === node.position + 1);
        this.props.map.on('mousemove', (e) => {
            node.element.setLatLng(e.latlng);
            this.nodeHighlight && this.nodeHighlight.setLatLng(e.latlng);
            node.coords = [e.latlng.lat, e.latlng.lng];
            node.address = '';
            if (lineBefore) {
                lineBefore.element.setLatLngs([nodeBefore.coords, e.latlng]);
                const newDistance = e.latlng.distanceTo(nodeBefore.coords);
                this.totalDistance = this.totalDistance - lineBefore.distance + newDistance;
                lineBefore.distance = newDistance;
            }
            if (lineAfter) {
                lineAfter.element.setLatLngs([e.latlng, nodeAfter.coords]);
                const newDistance = e.latlng.distanceTo(nodeAfter.coords);
                this.totalDistance = this.totalDistance - lineAfter.distance + newDistance;
                lineAfter.distance = newDistance;
            }
        })
    }

    stopMoveNode = () => {
        this.props.map.off('mousemove');
        this.props.map.dragging.enable();
        this.props.onChange([...this.nodes]);
    }

    removeNode = (id) => () => {
        if (this.props.isFrozen) return;
        this.nodeHighlight && this.nodeHighlight.remove();
        const node = this.nodes.find(node => node.id === id);
        if (!node) return;
        const lineBefore = this.lines.find(line => line.position === node.position - 1);
        const lineAfter = this.lines.find(line => line.position === node.position);
        const nodeBefore = this.nodes.find(n => n.position === node.position - 1);
        const nodeAfter = this.nodes.find(n => n.position === node.position + 1);

        this.nodes = this.nodes.filter(node => node.id !== id)
            .map((node, i) => ({...node, position: i + 1}));

        if (lineBefore && lineAfter) {
            const lineCoords = [
                lineBefore.element.getLatLngs()[0],
                lineAfter.element.getLatLngs()[1]
            ];
            const polyline = L.polyline(lineCoords, this.lineStyles).addTo(this.props.map);
            this.passGroup.addLayer(polyline.bringToBack());
            const distance = nodeBefore.element.getLatLng().distanceTo(nodeAfter.coords);
            this.totalDistance = this.totalDistance + distance;

            const index = _.findIndex(this.lines, {position: lineBefore.position});
            const line = {
                id: _.uniqueId(),
                position: lineBefore.position,
                element: polyline,
                distance
            };
            this.lines.splice(index, 1, line);
            this.lines = this.lines.filter(line => line.position !== node.position);
            polyline.on('mouseover', this.addTempNode(line.id));
            polyline.on('mouseout', this.removeTempNode);
        } else {
            this.lines = this.lines.filter(line => line.position !== node.position && line.position !== node.position - 1);
        }

        this.lines = this.lines.map((line, i) => ({...line, position: i + 1}))

        if (lineBefore) {
            this.totalDistance = this.totalDistance - lineBefore.distance;
            lineBefore.element.remove();
        }
        if (lineAfter) {
            this.totalDistance = this.totalDistance - lineAfter.distance;
            lineAfter.element.remove();
        }
        node.element.remove();
        this.props.onChange([...this.nodes]);
    }

    addTempNode = (lineId) => () => {
        this.tempNodeStatus = 'inline';
        const line = this.lines.find(line => line.id === lineId);
        const coords = line.element.getCenter();
        this.tempNode = L.circleMarker(coords, this.nodeStyles).addTo(this.props.map);
        this.tempNode.on('mouseover', () => {
            this.tempNodeStatus = 'atnode';
        })
        this.tempNode.on('mouseout', () => {
            this.tempNodeStatus = 'outline';
            this.tempNode.remove();
        })
        this.tempNode.on('mousedown', this.addNode(line.position));
    }

    removeTempNode = () => {
        setTimeout(() => {
            if (this.tempNodeStatus === 'inline') {
                this.tempNode.remove();
            }
        }, 0);
    }

    addNode = (linePosition) => () => {
        if (this.props.isFrozen) return;
        const line = this.lines.find(line => line.position === linePosition);
        const coords = line.element.getCenter();
        const circle = L.circleMarker(coords, this.nodeStyles).addTo(this.props.map);
        this.nodesGroup.addLayer(circle);
        const node = {
            id: _.uniqueId(),
            position: linePosition + 1,
            element: circle,
            coords: [coords.lat, coords.lng],
        };
        const index = _.findIndex(this.nodes, {position: linePosition + 1});
        this.nodes.splice(index, 0, node);
        this.nodes = this.nodes.map((node, i) => ({...node, position: i + 1}));

        circle.on('click', this.showPopup(node.id, circle))
        circle.on('mousedown', this.startMoveNode(node.id));
        circle.on('mouseup', () => {
            this.stopMoveNode();
            this.showPopup(node.id, circle)();
        });
        circle.on('dblclick', this.removeNode(node.id));
        L.DomEvent.disableClickPropagation(circle);

        const coords1 = [
            line.element.getLatLngs()[0],
            coords
        ];
        const coords2 = [
            coords,
            line.element.getLatLngs()[1]
        ];
        const polyline1 = L.polyline(coords1, this.lineStyles).addTo(this.props.map);
        const polyline2 = L.polyline(coords2, this.lineStyles).addTo(this.props.map);
        this.passGroup.addLayer(polyline1.bringToBack());
        this.passGroup.addLayer(polyline2.bringToBack());

        const distance = line.distance / 2;
        const line1 = {
            id: _.uniqueId(),
            element: polyline1,
            distance
        };
        const line2 = {
            id: _.uniqueId(),
            element: polyline2,
            distance
        };

        const i = _.findIndex(this.lines, {position: linePosition});
        this.lines.splice(i, 1, line1, line2);
        this.lines = this.lines.map((line, i) => ({...line, position: i + 1}));

        polyline1.on('mouseover', this.addTempNode(line1.id));
        polyline1.on('mouseout', this.removeTempNode);
        polyline2.on('mouseover', this.addTempNode(line2.id));
        polyline2.on('mouseout', this.removeTempNode);

        line.element.remove();
        this.startMoveNode(node.id)();
    }

    render() {
        return null;
    }
}