import React from 'react';
import ReactDOMServer from 'react-dom/server';
import MapVehicleIcon from "components/ui/map-vehicle-icon";
import L from 'leaflet';

export default class PointIcon {

    static getIcon(color) {
        const html = ReactDOMServer.renderToStaticMarkup(<MapVehicleIcon device={{type: null}} color={color}/>);

        let className = `marker marker_type_bus marker_sm`;
        let size = 26;
        return new L.DivIcon({
            html: html,
            className: className,
            iconSize: new L.Point(size, size),
            iconAnchor: new L.Point(size / 2, size / 2),
        });
    }
}