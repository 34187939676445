import React, {Component} from "react";
import TableContainer from "components/ui/Table/Container/TableContainer";
import SmsTemplateEditor from "./TemplateEditor/TemplateEditor";

import Button from "../../../ui/button";
import GlobalLoaderComponent from "components/ui/global-loader";
import classnames from "classnames";
import { callInitialOnce } from "helpers/functions";
import {connect} from "react-redux";
import { getSmsTemplatesList, deleteSmsTemplate } from "store/reducers/sms/sms";
import * as alerts from "helpers/alerts";

import "./style.less"

@connect(state => ({}), { getSmsTemplatesList, deleteSmsTemplate })

export default class SmsTemplate extends Component {

    constructor() {
        super();

        this.state = {
            loading: true,
            smsTemplates: [],
            editorActive: false,
            editorActiveUuid: null,
            selectedTableItem: null,
        }
    }

    componentDidMount() {
        callInitialOnce(::this.loadTemplatesList)
    }

    componentWillUnmount() {
        localStorage.removeItem("initial")
        this.props.reset && this.props.reset()
    }

    loadTemplatesList() {
        this.props.getSmsTemplatesList().then(resolve => {
            if (resolve.isOk) {
                this.setState({
                    loading: false,
                    selectedTableItem: null,
                    smsTemplates: resolve.payload.items
                })
            }
        })
    }

    renderTableBody() {
        return null
    }

    handleToggleEditor() {
        this.setState({
            editorActive: !this.state.editorActive,
        })
    }

    handleGoBack() {
        this.props.handleButtonClickCase('button')
    }

    hideEditor() {
        this.handleToggleEditor();
    }

    submitEditor() {
        this.loadTemplatesList();
        this.handleToggleEditor();
    }

    handleTableItemClick(item) {
        if (!this.state.selectedTableItem) {
            this.setState({
                selectedTableItem: item.uuid
            })
        }
        if (this.state.selectedTableItem && this.state.selectedTableItem !== item.uuid) {
            this.setState({
                selectedTableItem: item.uuid
            })
        }
        if (this.state.selectedTableItem === item.uuid) {
            this.setState({
                editorActive: true,
                editorActiveUuid: item.uuid
            })
        }
    }

    async handleDeleteTableItem() {
        const smsTemplate = this.state.smsTemplates.filter(item => item.uuid === this.state.selectedTableItem)[0];
        const isConfirmed = await new Promise((resolve, reject) => {
            alerts.prompt(`Вы уверены, что хотите удалить смс шаблон ${smsTemplate.title}?`, null, () => {
                resolve(true);
            }, 'Удалить', () => {
                resolve(false);
            });
        });
        if (!isConfirmed) {
            return;
        }
        await this.props.deleteSmsTemplate({ uuid: smsTemplate.uuid });
        this.loadTemplatesList();
    }

    render() {
        if (this.state.loading) {
         return <GlobalLoaderComponent/>
        }
        return (
            <div className="sms-templates">
                {this.state.editorActive ? <SmsTemplateEditor uuid={this.state.editorActiveUuid} mode={this.state.editorActiveUuid ? 'edit' : 'add'} onClose={::this.hideEditor}
                                                              onSubmit={::this.submitEditor} /> : null}
                <h2 className="sms-templates__title">Шаблоны сообщений</h2>
                <p className="sms-templates__goback" onClick={() => this.handleGoBack()}>&#129044; Назад</p>
                <Button className="sms-templates__add-button" size="md" text="Добавить" color="red" shadow="red"
                        onClick={() => this.handleToggleEditor()}/>
                <Button disabled={!this.state.selectedTableItem} className="sms-templates__delete-button" size="md" text="Удалить" color="red" shadow="red"
                        onClick={() => this.handleDeleteTableItem()}/>
                <div className="Table">
                    <TableContainer>
                        <table className="b-table b-table-no-hover">
                            <thead>
                            <tr className="b-table__header">
                                <th className="sms-templates__header-name">Наименование шаблона</th>
                                <th className="sms-templates__header-body">Тело сообщения</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.smsTemplates.map((bodyItem, index) => {
                                return (<tr key={bodyItem.title + index}
                                            onClick={() => this.handleTableItemClick(bodyItem)}
                                            className={classnames("sms-templates__row-body", bodyItem.uuid === this.state.selectedTableItem && "active")}>
                                    <td>{bodyItem.title}</td>
                                    <td>{bodyItem.template}</td>
                                </tr>)
                            })}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </div>
        );
    }
}