import _ from "lodash";
import moment from "moment";
import months from "../dictionaries/months";

export const prepareDateForPrint = (printType, item, state) => {

    if (!item.contract) return ;
    //перевозчик
    let carrier = _.find(state.units, {uuid: item.contract.carrier_uuid});

    //уполномоченный орган
    let unit = _.find(state.units, {uuid: item.contract.unit_uuid});

    // улицы по которым проходит маршрут
    let streets = collectStreets(item.route);
    return {
        _printType: printType,
        // данные по контракту
        contract: {
            number: item.contract.number,
            serial: item.contract.serial,
            dates: {
                startDay: item.contract.work_from ? `${moment(item.contract.work_from).format('D')} ${months[moment(item.contract.work_from).format('M') - 1]}` : '',
                startYear: item.contract.work_from ? moment(item.contract.work_from).get('year').toString().slice(2, 4) : '',
                endDay: item.contract.work_to ? `${moment(item.contract.work_to).format('D')} ${months[moment(item.contract.work_to).format('M') - 1]}` : '',
                endYear: item.contract.work_to ? moment(item.contract.work_to).get('year').toString().slice(2, 4) : '',
            },
            status: item.contract.work_to ? 'Срочно' : 'Бессрочно',
            //statusGlobal: item.contract.status_uuid && state.contract_statuses.length ? _.find(state.contract_statuses, {value: item.contract.status_uuid}).label : ''
        },
        // название уполномоченного органа
        org_name: unit ? unit.name_full : '',
        // глава уполномоченного органа
        senior: unit ? unit.senior : '',
        // регистрационный номер в реестре
        registration_number: item.route.registration_number ? item.route.registration_number : '',
        // порядковый номер маршрута
        number: item.route.number ? item.route.number : null,
        // наименование маршрута
        title: item.route.title ? item.route.title : '',
        // название перевозчика
        carrier: carrier ? carrier.label || carrier.name : '',
        // местонахождение перевозчика
        place: carrier ? carrier.legal_address : '',
        // ИНН перевозчика
        inn: carrier ? carrier.inn : '',
        // тип ТС
        tstype: _.find(state.vehicle_types, {value: item.vehicle_type_uuid}) ? _.find(state.vehicle_types, {value: item.vehicle_type_uuid}).label : '',
        // tsclass: 'Малый',
        tsclass: _.find(state.vehicle_capacity_types, {value: item.vehicle_capacity_type_uuid}) ? _.find(state.vehicle_capacity_types, {value: item.vehicle_capacity_type_uuid}).label : '',
        // экологические характеристики
        eco: _.find(state.vehicle_environmental_classes, {value: item.vehicle_environmental_class_uuid}) ? _.find(state.vehicle_environmental_classes, {value: item.vehicle_environmental_class_uuid}).label : '',
        // список остановок
        stops: item.route.groups ? item.route.groups.map(item => `${item.name}`).join(', ') : '',
        // порядок высадки и посадки
        landing: item.route.groups.length ? 'Остановочные пункты' : 'В любом месте',
        // улицы движения маршрута
        streets: streets.join(', '),
        // х-ки ТС
        properties: `${item.contract.is_air_conditioning_installation ? 'Кондиционирование ' : ' '} 
            ${item.contract.is_audio_video_fixation ? 'Аудио/Видео фиксация ' : ' '}
            ${item.contract.is_cashless_payment ? 'Безналичная оплата ' : ' '}
            ${item.contract.is_electronic_scoreboard ? 'Электронное табло ' : ' '}
            ${item.contract.is_low_floor_level ? 'Низкопольные ' : ' '}
            ${item.contract.is_passenger_monitoring_system ? 'Мониторинг пассажиропотока ' : ' '}`
    }
};

const  collectStreets = (item) => {
    let streetsArray = [];
    if (item.default_variant && item.default_variant.forward_points) {
        _.forEach(item.default_variant.forward_points, (value) => {
            _.forEach(value.streets, (item) => {
                streetsArray.push(item)
            })
        });
    }

    if (item.default_variant && item.default_variant.reverse_points) {
        _.forEach(item.default_variant.reverse_points, (value) => {
            _.forEach(value.streets, (item) => {
                streetsArray.push(item)
            })
        });
    }


    return _.uniq(streetsArray);
};
