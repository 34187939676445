import React from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import RouteSummary from './RouteSummary';
import RouteSchedule from './RouteSchedule';

import './style.less';
import GlobalLoaderComponent from "components/ui/global-loader";
import moment from "moment";
import formats from "dictionaries/formats";
import {connect} from "react-redux";
import {getSchedules} from "store/reducers/kiutr/schedules/schedules";
import _ from 'lodash';
import {getOrders} from "store/reducers/kiutr/orders/orders";
import {offDayGet} from "store/reducers/dictionaries/dictionary";

@propTypes({
    data: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    load: PropTypes.bool.isRequired
})

@connect(state => ({}), {getSchedules, getOrders, offDayGet})
export default class RouteInfo extends React.Component {

    state = {
        isLoading: true,
        schedule: null,
        orders: [],
    };

    componentWillMount() {
        this.loadData();
    }

    async getOffDay() {
        const response = await this.props.offDayGet({
            unit_uuid: this.props.data.contract.carrier_uuid,
            date: this.props.date,
        });

        if (response.isOk) {
            const result = _.first(response.payload.documents);
            return _.get(result, 'data.day_of_week');
        }

        return null;
    }

    async loadSchedule() {
        const offDay = await this.getOffDay();
        const status = _.get(_.find(this.props.data.scheduleStatuses, {name: 'Утверждено'}), 'uuid');
        const day = moment(this.props.date, formats.DATE).format('dddd');

        const meta = {
            filters: {
                withRoute: this.props.data.route.uuid,
                withDays: offDay ? [
                    //'holiday', //fixme IT2019-612 непонята логика выходных и праздничных дней
                    offDay,
                ] : [
                    day.toLowerCase(),
                ],
                withDate: moment(this.props.date, formats.DATE).format(formats.DATE_API),
            },
        };
        const response = await this.props.getSchedules(meta);
        if (response.isOk) {
            return _.filter(response.payload.items, {
                schedule_status_uuid: status,
            });
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadOrders(runs) {
        const meta = {
            filters: {
                withDate: moment(this.props.date, formats.DATE).format(formats.DATE_API),
                withScheduleTurnRuns: _.map(runs, 'uuid'),
            },
        };
        const fullMethod = true;
        const response = await this.props.getOrders(meta, fullMethod);
        if (response.isOk) {
            return response.payload.items;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadOrdersOld(unitUuid, scheduleUuid) {
        const meta = {
            filters: {
                withSchedule: scheduleUuid,
                withUnits: [
                    unitUuid,
                ],
                withDate: moment(this.props.date, formats.DATE).format(formats.DATE_API)
            }
        };
        const fullMethod = true;
        const response = await this.props.getOrders(meta, fullMethod);
        if (response.isOk) {
            return response.payload.items;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadData() {
        this.setState({isLoading: true});

        const schedules = await this.loadSchedule();

        if (schedules && schedules.length) {
            const schedule = _.first(schedules);

            schedule.turns = _.orderBy(schedule.turns, ['number'], ['asc']);

            let orders = await this.loadOrders(_.flatten(_.map(schedule.turns, 'runs')));
            let isOld = false;
            if (orders.length === 0) {
                orders = await this.loadOrdersOld(this.props.data.contract.unit_uuid, schedule.uuid);
                isOld = true;
            }

            this.setState({
                isLoading: false,
                schedule,
                orders,
                isOld,
            });
        }
        this.setState({
            isLoading: false,
        });
    }

    renderContent() {
        const {data, date} = this.props;

        return (
            <div>
                <RouteSummary data={{...data, ...{orders: this.state.orders, schedule: this.state.schedule, isOld: this.state.isOld}}}
                              date={date}/>
                {this.state.schedule ? (
                    <RouteSchedule params={this.props.params} data={{...data, ...{orders: this.state.orders, schedule: this.state.schedule, isOld: this.state.isOld}}}
                               date={date}/>
                ) : null}
            </div>
        );
    }

    render() {
        if (this.state.isLoading) {
            return <GlobalLoaderComponent/>;
        }

        return this.props.load ? this.renderContent() : null;
    }
}