import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import './block.less';

//import { connect } from 'react-redux';

import IconButton from './icon-button';

@propTypes({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    onClose: PropTypes.func,
})

export default class Block extends Component {

    render() {

        return (
            <div className="block">
                <div className="title">
                    <h3>{this.props.title}</h3>
                    <span>{this.props.subtitle}</span>
                    <div className="b-modal__button-block">
                        <div className="top-menu_modal clearAfter top-menu_modal_edit">
                            <ul className="top-menu_modal__list">
                                <li className="top-menu_modal__item">
                                    <div className="top-menu_modal__separator"/>
                                </li>
                            </ul>
                        </div>
                        {this.props.onClose ? (
                            <div className="b-modal__header-link _close" onClick={this.props.onClose}/>
                        ) : null}
                    </div>
                </div>
                <div className="body">
                    {this.props.children}
                </div>
            </div>
        )
    }

}