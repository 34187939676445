import { List, Map } from 'immutable';
import { api } from 'helpers/api';
import { success, error } from 'helpers/response';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
	}

	return state;
}

export const getSchedules = (meta) => async (dispatch) => {
	try {
		const response = await api.geo.getSchedules(meta);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getSchedule = (uuid) => async (dispatch) => {
	try {
		const response = await api.geo.getSchedule(uuid);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getScheduleIntervals = (uuid) => async (dispatch) => {
	try {
		const response = await api.geo.getScheduleIntervals(uuid);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const postScheduleIntervals = (schedule_uuid, intervals) => async (dispatch) => {
	try {
		const response = await api.geo.createScheduleIntervals({
			schedule_uuid,
			intervals
		});

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const updateScheduleIntervals = (schedule_uuid, intervals) => async (dispatch) => {
	try {
		const response = await api.geo.updateScheduleIntervals({
			schedule_uuid,
			intervals
		});

		return success(response);
	} catch (e) {
		return error(e);
	}
};


export const getScheduleInfo = (uuid) => async (dispatch) => {
	try {
		const response = await api.geo.getScheduleInfo(uuid);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const createSchedule = (data) => async (dispatch) => {
	try {
		const response = await api.geo.createSchedule(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const cloneSchedule = (data) => async (dispatch) => {
	try {
		const response = await api.geo.cloneSchedule(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const updateSchedule = (data) => async (dispatch) => {
	try {
		const response = await api.geo.updateSchedule(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const deleteSchedule = (data) => async (dispatch) => {
	try {
		const response = await api.geo.deleteSchedule(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const createScheduleInterval = (data) => async (dispatch) => {
	try {
		const response = await api.geo.createScheduleInterval(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const updateScheduleInterval = (data) => async (dispatch) => {
	try {
		const response = await api.geo.updateScheduleInterval(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const deleteScheduleInterval = (uuid) => async (dispatch) => {
	try {
		const response = await api.geo.deleteScheduleInterval(uuid);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getScheduleInterval = (uuid) => async (dispatch) => {
	try {
		const response = await api.geo.getScheduleInterval(uuid);

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const exportExcelSchedule = (payload) => async (dispatch) => {
	function timeout(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	async function recursiveAjax(method, payload, interval, limitMinutes, time) {
		const response = await method(payload);
		let limit = Math.ceil(60000 * limitMinutes / interval);
		if (time) {
			limit = time;
		}
		if (time === 0) {
			return false;
		}
		if (response.payload.status === 'pending') {
			await timeout(interval);
			return await recursiveAjax(method, payload, interval, limitMinutes, limit - 1);
		} else {
			return response.payload;
		}
	}

	try {
		const response = await api.geo.exportExcelSchedule(payload);
		if (!response.success) {
			return success(response);
		} else {
			await timeout(5000);
			const payload = { uuid: response.payload.uuid };
			const result = await recursiveAjax(api.geo.getExportHtmlByExportUuid, payload, 5000, 5);
			return success(result);
		}
	} catch (e) {
		return error(e);
	}
};
