import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './input.less';
import {connect} from "react-redux";

@propTypes({
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password']),
    success: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    withoutEye: PropTypes.bool,
})

@defaultProps({
    value: '',
    onChange: () => {
    },
    type: 'text',
    placeholder: '',
    withoutEye: false,
})

export default class Input extends Component {

    state = {
        innerOpened: false
    };

    render() {
        const textFieldState = this.getCurrentTextfieldState();
        const className = classNames('input__control', this.props.value ? 'filled' : '');

        const inputProps = {};

        if (this.props.onEnter)
            inputProps.onKeyPress = ::this.handleKeyPress;

        const hint = this.props.error ? this.renderHint() : null;

        return (
            <div className="input">
                <input
                    className={className}
                    value={this.props.value}
                    type={this.state.innerOpened ? 'text' : this.props.type}
                    onChange={this.props.onChange}
                    {...inputProps} />
                <div
                    className={classNames('input__style', textFieldState ? `input__style_state_${textFieldState}` : '', (this.props.type === 'password') ? 'input__style_password' : '')}/>
                <div
                    className={classNames('input__inner', (!this.props.withoutEye && this.props.type === 'password') ? 'input__inner_password' : '', (this.state.innerOpened ? 'input__inner_password_open' : ''))}
                    onClick={::this.toggleInner}>{this.props.placeholder}</div>
                {hint}
            </div>
        );
    }

    renderHint() {
        return (
            <div className="input__title input__title_wrong">{this.props.error}</div>
        );
    }

    toggleInner() {
        this.setState({
            innerOpened: !this.state.innerOpened
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.props.onEnter();
        }
    }

    getCurrentTextfieldState() {
        if (this.props.success)
            return 'correct';

        if (this.props.error)
            return 'wrong';

        return false;
    }

}