import React, {Component} from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

@propTypes({
    map: PropTypes.object.isRequired,
    leafletMap: PropTypes.object.isRequired,
})

export default class AbstractMapLayer extends Component {

    __layer = null;

    componentWillMount() {
        this.__layer = new L.layerGroup();
    }

    componentDidMount() {
        this.props.leafletMap.addLayer(this.__layer);
    }

    componentWillUnmount() {
        this.props.leafletMap.removeLayer(this.__layer);
    }

    render() {
        return null;
    }

}