import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import GlobalLoaderComponent from "components/ui/global-loader";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getSchedule, getSchedules} from "store/reducers/kiutr/schedules/schedules";
import formats from "dictionaries/formats";
import moment from "moment";
import './style.less';
import {getScheduleTurns, updateScheduleTurn} from "store/reducers/kiutr/schedules/schedule_turns";
import Button from "components/ui/button";
import Page from 'components/ui/page';
import {getRouteVariants} from "store/reducers/routes/route_variants";
import {getStopPoints} from "store/reducers/geo/stop-points";
import systems from "dictionaries/systems";
import IconButton from "components/ui/icon-button";
import {Line} from 'react-chartjs-2';
import runs from "dictionaries/runs";
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import debounce from 'throttle-debounce/debounce';
import * as alerts from "helpers/alerts";
import classNames from 'classnames';
import ContextTooltip from "components/ui/context-tooltip";
import {getOrders} from "store/reducers/kiutr/orders/orders";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {CycleFetch} from "helpers/api";
import CheckboxDropdown from "components/ui/checkbox-dropdown";
import {getOrderExecutions} from "store/reducers/kiutr/orders/order_executions";
import {getScheduleSwitches} from "store/reducers/kiutr/schedules/schedule_switches";
import {getDefaultVariant, getGroupsFromRoute} from "helpers/kiutr";
import $ from 'jquery';
import {GlobalEvent} from "helpers/event-system";
import {getRoute} from "store/reducers/routes/route_editor";
import Checkbox from "components/ui/form/checkbox";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from "components/ui/ReactTooltip/index";
import ReactResizeDetector from 'react-resize-detector';

@propTypes({
    routeUuid: PropTypes.string.isRequired,
})

@connect(state => ({}), {
    getSchedules,
    getScheduleTurns,
    getScheduleSwitches,
    getRoute,
    getRouteVariants,
    getStopPoints,
    updateScheduleTurn,
    getOrders,
    getVehicleList,
    getOrderExecutions,
    getDictionaryList,
    getEntityNames,
})

export default class WidgetEllipse extends Component {

    state = {
        routeUuid: null,
        scheduleUuid: null,
        schedule: null,
        loading: false,
        loadingOrders: false,
        ordersFirstLoad: true,
        turns: [],
        defaultVariant: null,
        defaultStopPoints: null,
        firstStopPoint: null,
        lastStopPoint: null,
        orders: [],
        ordersSwitches: [],
        vehicles: [],
        orderExecutions: [],
        selectedStopPoint: null,
        selectedVehicles: {},
        routeVariants: [],

        showType: '_show_minimum',

        proportionalModeActive: false,

        vehicle_types: [],
        vehicle_models: [],
        related: new EntityList,
    };

    _cycleFetch = null;
    _calculateAlign = this.calculateAlignClosure();

    constructor(...args) {
        super(...args);

        this.onClickDocument = ::this._onClickDocument;
    }

    componentWillUnmount() {
        $(document).off('click', this.onClickDocument);
    }

    _onClickDocument(e) {
        if ($(e.target).hasClass('ellipse-point')) {
            return;
        }
        if (this.state.selectedStopPoint && !$(e.target).hasClass('ellipse-point-popup') && $(e.target).parents('.ellipse-point-popup').length === 0) {
            this.setState({
                selectedStopPoint: null,
            });
        }
    }

    async componentDidMount() {
        await this.setState({
            routeUuid: this.props.routeUuid,
        });

        this.loadDictionaries([
            'vehicle_types',
            'vehicle_models',
        ], 'kiutr');

        //this.loadRoute();
        this.loadRouteVariant();

        await this.loadSchedules();
        this._cycleFetch = new CycleFetch(::this.loadOrders, () => {
        }, 10000);
        this._cycleFetch.run();

        this.onResize = ::this.onResize;
        //GlobalEvent().subscribe('grid-resize', this.onResize);

        //this.onResize();
    }

    componentWillUnmount() {
        //GlobalEvent().unsubscribe('grid-resize', this.onResize);
        this._cycleFetch && this._cycleFetch.stop();
        delete this['_cycleFetch'];
    }

    onResize() {
        if (!this.state.stopPointsCount) {
            return;
        }
        const width = $(this.refs.container).width();
        if (width / this.state.stopPointsCount < 30) {
            this.setState({
                showType: '_show_minimum',
            });
        } else {
            this.setState({
                showType: '_show_all',
            });
        }
    }

    async loadRoute() {
        const response = await this.props.getRoute(this.state.routeUuid);
        if (response.isOk) {
            const defaultVariant = getGroupsFromRoute(response.payload);
            this.setState({
                defaultVariant,
            });
        } else {
            response.showErrors();
        }
    }

    async loadRouteVariant() {
        const response = await this.props.getRouteVariants({
            filters: {
                withRoute: this.state.routeUuid,
            },
        });
        if (response.isOk) {
            await this.setState({
                routeVariants: response.payload.items,
                stopPointsCount: _.max(_.map(response.payload.items, (routeVariant) => {
                    return Math.max(routeVariant.forward_points.length, routeVariant.reverse_points.length);
                })),
            });
            this.onResize();
            const uuids = _.uniq(_.map(_.filter(_.flatten(_.concat(_.map(response.payload.items, 'forward_points'), _.map(response.payload.items, 'reverse_points'))), {
                point_type: 'stop_point',
            }), 'type_uuid'));
            this.loadStopPoints(uuids);
        } else {
            response.showErrors();
        }
    }

    isRing() {
        return _.filter(this.state.defaultVariant, (group) => {
            return _.filter(group.items, (item) => {
                return _.filter(item.inclusions, {
                    is_forward: false,
                }).length > 0;
            }).length > 0;
        }).length === 0;
    }

    async loadStopPoints(uuids) {
        const response = await this.props.getStopPoints({
            filters: {
                withUuid: _.uniq(_.filter(uuids)),
            },
        });
        if (response.isOk) {
            this.setState({
                defaultStopPoints: _.keyBy(response.payload.items, 'uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    getDaysForSchedules() {
        let days = [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
        ];

        return [
            days[moment().day()],
        ];
    }

    async loadSchedulesSwitch() {
        const response = await this.props.getScheduleSwitches({
            filters: {
                withRouteTo: this.state.routeUuid,
            },
        });
        if (response.isOk) {
            if (response.payload.items.length) {
                const schedule_uuid = response.payload.items[0].schedule.uuid;
                this.loadOrdersSwitch(schedule_uuid)
            } else {
                await Promise.resolve("true")
            }
        } else {
            response.showErrors();
        }
    }

    async loadOrdersSwitch(schedule_uuid) {
        const responseOrders = await this.props.getOrders({
            filters: {
                withSchedule: schedule_uuid,
                withDate: moment().format(formats.DATE_API),
            },
        });
        if (responseOrders.isOk) {
            await this.setState({
                ordersSwitches: responseOrders.payload.items
            });
        } else {
            responseOrders.showErrors();
        }
    }

    async loadSchedules() {
        this.setState({loading: true});
        const response = await this.props.getSchedules({
            filters: {
                withRoute: this.state.routeUuid,
                withDays: this.getDaysForSchedules(),
                withDate: moment().format(formats.DATE_API),
            },
        });
        if (response.isOk) {
            const schedule = _.first(response.payload.items);
            await this.setState({
                schedule,
                scheduleUuid: _.get(schedule, 'uuid'),
            });
            await this.loadTurns();

            this.setState({loading: false});
        } else {
            response.showErrors();
        }
    }

    async loadOrders() {
        if (!this.state.scheduleUuid) {
            this.setState({loadingOrders: false});
            return;
        }
        this.state.ordersFirstLoad && this.setState({loadingOrders: true});
        await this.loadSchedulesSwitch();
        const response = await this.props.getOrders({
            filters: {
                withSchedule: this.state.scheduleUuid,
                withDate: moment().format(formats.DATE_API),
            },
        });
        this.setState({
            loadingOrders: false,
            ordersFirstLoad: false,
        });
        if (response.isOk) {
            const orders = [...response.payload.items, ...this.state.ordersSwitches ];
            const runs = _.filter(_.flatten(_.map(_.flatten(_.map(orders, 'shifts')), 'runs')), (run) => {
                return run.route_uuid === this.state.routeUuid;
            });
            this.setState({
                orders,
            });
            await this.loadOrderExecutions(runs);
            this.loadVehicles(_.map(this.state.orderExecutions, 'vehicle_uuid'));
        } else {
            response.showErrors();
        }
    }

    async loadOrderExecutions(orderRuns) {
        const response = await this.props.getOrderExecutions({
            filters: {
                withOrderRuns: _.map(orderRuns, 'uuid'),
                withTypes: [
                    'production_forward',
                    'production_reverse',
                ],
                withPeriod: [
                    moment().subtract(60, 'minutes').format(formats.DATETIME_API),
                    moment().add(60, 'minutes').format(formats.DATETIME_API),
                ],
            },
        });


        if (response.isOk) {
            await this.setState({
                orderExecutions: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadVehicles(vehicleUuids) {
        const response = await this.props.getVehicleList({
            filters: {
                withUuid: vehicleUuids,
            },
        });
        if (response.isOk) {
            let selectedVehicles = this.state.selectedVehicles;
            _.each(response.payload.items, (vehicle) => {
                if (_.isUndefined(selectedVehicles[vehicle.uuid])) {
                    selectedVehicles[vehicle.uuid] = true;
                }
            });

            await this.setState({
                vehicles: _.map(response.payload.items, (item) => {
                    item.position = 0;
                    return item;
                }),
                selectedVehicles,
            });
            this.loadRelatedEntities(response.payload.items);
        } else {
            response.showErrors();
        }
    }

    async loadRelatedEntities(vehicles) {
        const units = _.map(_.filter(_.map(vehicles, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(units);

        if (response.isOk) {
            this.state.related.add(response);
        }
    }

    async loadTurns() {
        if (!this.state.scheduleUuid) {
            return;
        }

        const response = await this.props.getScheduleTurns({
            order: {
                column: 'number',
                direction: 'asc',
            },
            filters: {
                withSchedule: this.state.scheduleUuid,
            },
        });
        if (response.isOk) {
            await this.setState({
                turns: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        const loader = (this.state.loading || this.state.loadingOrders) ? (<GlobalLoaderComponent/>) : null;

        return (
            <div className="Widget-Ellipse Widget-Ellipse_compacted" ref="container">
                {loader}
                {(!loader && (!this.state.scheduleUuid || (this.state.orders.length === 0))) ? (
                    <div className="no-data-block">
                        {(!loader && !this.state.scheduleUuid) ? (
                            <p>Отсутствуют данные о ведущейся транспортной работе на маршруте</p>
                        ) : null}
                        {(!loader && this.state.orders.length === 0) ? (
                            <p>Отсутствуют данные о план-наряде на маршруте</p>
                        ) : null}
                    </div>
                ): null}
                {(this.state.schedule) ? this.renderSchedule() : null}
                <ReactResizeDetector handleWidth handleHeight onResize={::this.onResize}/>
            </div>
        );
    }

    findPointByGroup(group, index, isForward, groups) {
        if ((isForward && index >= groups.length - 1) || (!isForward && index <= 0)) {
            return null;
        }

        const nextGroup = isForward ? groups[index + 1] : groups[index - 1];
        const currentStopPoints = _.map(group.items, 'stop_point_uuid');
        const nextStopPoints = _.map(nextGroup.items, 'stop_point_uuid');

        let result = null;
        _.each(this.state.routeVariants, (routeVariant) => {
            const points = _.filter(_.concat(routeVariant.forward_points, routeVariant.reverse_points), {point_type: 'stop_point'});
            for (let i = 0; i < points.length - 1; i++) {
                if ((_.indexOf(currentStopPoints, points[i].type_uuid) !== -1) && (_.indexOf(nextStopPoints, points[i + 1].type_uuid) !== -1)) {
                    result = points[i];
                    return false;
                }
            }
        });

        if (result) {
            result.title = group.name;
        }

        return result;
    }

    toggleProportionalMode() {
        this.setState({
            proportionalModeActive: !this.state.proportionalModeActive,
        });
    }

    renderSchedule() {
        return (
            <div>
                <div className="ellipse-stats">
                    <span>ТС в наряде </span>
                    <span title="количество уникальных ТС в нарядах" className={(this.getTotalVehiclesFact() !== this.getTotalVehiclesPlan()) ? 'failure' : ''}>{this.getWorkVehiclesPlan()}</span>/<span title="плановое количество выходов по маршруту">{this.getTotalVehiclesPlan()}</span>
                    <span> ТС на линии</span>
                    <span title="фактическое количество ТС отображаемых в данный момент" className={(this.getWorkVehiclesFact() !== this.getTotalVehiclesPlan()) ? 'failure' : ''}>{this.getWorkVehiclesFact()}</span>/<span title="плановое количество выходов по маршруту">{this.getTotalVehiclesPlan()}</span>
                </div>
                <div className="ellipse-mode-toggle">
                    <ContextTooltip key="ellipse.mode" code="ellipse.mode" default="Переключение режима отображения">
                        <Checkbox
                            checked={this.state.proportionalModeActive}
                            onChange={::this.toggleProportionalMode}
                        />
                    </ContextTooltip>
                </div>
                <div className="ellipse-vehicles-filter">
                    <CheckboxDropdown
                        items={_.map(this.state.vehicles, item => ({
                            value: item.uuid,
                            label: item.state_number,
                        }))}
                        icon="bus"
                        selectedItems={this.state.selectedVehicles}
                        toggleSelectedItem={::this.toggleSelectedVehicle}
                        contextKey="ellipse.vehicle-filter"
                        contextDefault="Фильтр по ТС"
                    />
                </div>
                <div className="content">
                    {_.map(this.state.routeVariants || [], ::this.renderRouteVariant)}
                </div>
            </div>
        );
    }

    getHeight() {
        if (!this.refs.container) {
            return 0;
        }

        return $(this.refs.container).height();
    }

    renderRouteVariant(routeVariant) {
        if (_.filter(_.flatten(_.map(_.flatten(_.map(this.state.orders || [], 'shifts')), 'runs')), {
                route_variant_uuid: routeVariant.uuid,
            }).length === 0) {
            return null;
        }

        let {forward_points, reverse_points} = routeVariant;
        if (forward_points.length > 0) {
            forward_points[forward_points.length - 1].distance_to_the_next_point = 0;
        }
        if (reverse_points.length > 0) {
            reverse_points[reverse_points.length - 1].distance_to_the_next_point = 0;
        }

        if (!this.state.proportionalModeActive) {
            forward_points = _.map(_.cloneDeep(forward_points), (point, index) => {
                if (index !== forward_points.length - 1) {
                    point.distance_to_the_next_point = 1;
                }
                return point;
            });
            reverse_points = _.map(_.cloneDeep(reverse_points), (point, index) => {
                if (index !== reverse_points.length - 1) {
                    point.distance_to_the_next_point = 1;
                }
                return point;
            });
        }

        const forwardLength = _.sumBy(forward_points, 'distance_to_the_next_point');
        const reverseLength = _.sumBy(reverse_points, 'distance_to_the_next_point');

        return (
            <div key={routeVariant.uuid} className={classNames('ellipse-content', this.state.showType, {
                'ellipse-content_single': ((forward_points || []).length === 0) || ((reverse_points || []).length === 0),
                'ellipse-content_single-top': (reverse_points || []).length === 0,
                'ellipse-content_single-bottom': (forward_points || []).length === 0,
                responsive: this.getHeight() >= 566,
            })}>
                {((forward_points || []).length > 0) ? (
                    <div className="ellipse-content-top"/>
                ) : null}
                {((reverse_points || []).length > 0) ? (
                    <div className="ellipse-content-bottom"/>
                ) : null}
                {((forward_points || []).length > 0) ? (
                    <div className="ellipse-forward">
                        <div className="ellipse-ribs">
                            {((reverse_points || []).length > 0) ? (
                                <div className="ellipse-rib-arc left _state_normal"/>
                            ) : null}
                            {forward_points.map(this.renderStopPointRib.bind(this, true, forwardLength, forward_points))}
                            {(forward_points.length === 0) ? (
                                <div className="ellipse-rib _state_normal" style={{
                                    width: '100%',
                                    left: '0%',
                                }}/>
                            ) : null}
                            {((reverse_points || []).length > 0) ? (
                                <div className="ellipse-rib-arc right _state_normal"/>
                            ) : null}
                        </div>
                        <div className="ellipse-points">
                            {forward_points.map(this.renderStopPoint.bind(this, true, forwardLength, forward_points, routeVariant.uuid))}
                        </div>
                    </div>
                ) : null}
                {((reverse_points || []).length > 0) ? (
                    <div className="ellipse-reverse">
                        <div className="ellipse-ribs">
                            {((forward_points || []).length > 0) ? (
                                <div className="ellipse-rib-arc left _state_normal"/>
                            ) : null}
                            {reverse_points.map(this.renderStopPointRib.bind(this, false, reverseLength, reverse_points))}
                            {(reverse_points.length === 0) ? (
                                <div className="ellipse-rib _state_normal" style={{
                                    width: '100%',
                                    left: '0%',
                                }}/>
                            ) : null}
                            {((forward_points || []).length > 0) ? (
                                <div className="ellipse-rib-arc right _state_normal"/>
                            ) : null}
                        </div>
                        <div className="ellipse-points">
                            {reverse_points.map(this.renderStopPoint.bind(this, false, reverseLength, reverse_points, routeVariant.uuid))}
                        </div>
                    </div>
                ) : null}
                <div className="ellipse-vehicles">
                    {this.getVehiclesForRouteVariant(routeVariant).map(this.renderVehicle.bind(this, forward_points, reverse_points))}
                </div>
            </div>
        );
    }

    renderStopPoint(isForward, totalLength, points, routeVariantUuid, point, index) {
        if (point.point_type !== 'stop_point') {
            return;
        }

        const previousStopPoints = _.filter(points, (_point, _index) => (_point.point_type === 'stop_point') && _index < index);
        /*if (previousStopPoints.length === 0) {
            return;
        }
        if (_.filter(this.state.defaultVariant[isForward ? 'forward_points' : 'reverse_points'], (_point, _index) => (_point.point_type === 'stop_point') && _index > index).length === 0) {
            return;
        }*/

        const currentLength = _.sumBy(_.filter(points, (_point, _index) => isForward ? (_index < index) : (_index >= index)), 'distance_to_the_next_point');
        // let currentLength = _.filter(this.state.defaultVariant[isForward ? 'forward_points' : 'reverse_points'], (_point, _index) => isForward ? (_index < index) : (_index >= index)).length;
        // if (!isForward) {
        //     currentLength--;
        // }
        const percent = (totalLength !== 0) ? (100 * currentLength / totalLength) : 0;

        const title = _.get(this.state.defaultStopPoints, `${point.type_uuid}.title`);

        const lastVisit = this.getLastVisit(point.type_uuid);
        const lastVisitDiff = this.getLastVisitDiff(lastVisit);

        const content = (
            <div
                style={{left: `${percent}%`}}
                className={classNames('ellipse-point', {active: ((this.state.selectedRouteVariant === routeVariantUuid) && (this.state.selectedStopPoint === (index + 1) * (isForward ? 1 : -1)))}, point.key ? 'partly-used' : '', (previousStopPoints.length % 2) ? 'ellipse-point-up' : 'ellipse-point-down')}
                onClick={this.selectStopPoint.bind(this, (index + 1) * (isForward ? 1 : -1), routeVariantUuid)}
            >
                <div className="ellipse-point__title">{title}</div>
                {((this.state.selectedRouteVariant === routeVariantUuid) && (this.state.selectedStopPoint === (index + 1) * (isForward ? 1 : -1))) ? (
                    <div className="ellipse-point-popup">
                        <strong className="ellipse-point-popup_title">{title}</strong>
                        <div className="ellipse-point-popup_visit">Последнее посещение:</div>
                        <div>
                            <strong>{lastVisit ? moment(lastVisit.time_fact).format(formats.TIME) : '-'}</strong>
                            {lastVisit ? (
                                <span>
                                    (<span
                                    className={classNames('ellipse-point-popup_time', {
                                        'ellipse-point-popup_time_green': lastVisit.is_in_time,
                                        'ellipse-point-popup_time_yellow': lastVisit.is_before_time || lastVisit.is_after_time,
                                        'ellipse-point-popup_time_red': lastVisit.is_skipped,
                                    })}>{lastVisitDiff}</span>)
                                </span>
                            ) : null}
                        </div>
                        <div>
                            от:
                            <strong>{lastVisit ? this.getVehicleStateNumber(lastVisit.vehicle_uuid) : '-'}</strong>
                        </div>
                    </div>
                ) : null}
            </div>
        );

        if ((this.state.selectedRouteVariant === routeVariantUuid) && (this.state.selectedStopPoint === (index + 1) * (isForward ? 1 : -1))) {
            return content;
        } else {
            return (
                <ReactTooltip key={`${isForward}:${index}`}
                              title={title}
                              position="top"
                              offset={{left: -15}}
                >
                    {content}
                </ReactTooltip>
            );
        }
    }

    getLastVisit(uuid) {
        return _.last(_.sortBy(_.filter(_.flatten(_.map(this.state.orderExecutions, (orderExecution) => {
            return _.map(orderExecution.data, (item) => {
                item.vehicle_uuid = orderExecution.vehicle_uuid;
                return item;
            });
        })), (item) => {
            return item.time_fact && (item.type_uuid === uuid);
        }), 'time_fact'));
    }

    getLastVisitDiff(lastVisit) {
        if (!lastVisit) {
            return null;
        }

        const diff = moment(lastVisit.time_fact).diff(moment(lastVisit.time_plan), 'seconds');

        const minutes = Math.floor(Math.abs(diff) / 60);
        const seconds = _.padStart(Math.abs(diff % 60), 2, '0');

        if (diff >= 0) {
            return `+${minutes}:${seconds}`;
        }
        return `-${minutes}:${seconds}`;
    }

    getVehicleStateNumber(uuid) {
        return _.get(_.find(this.state.vehicles, {uuid}), 'state_number');
    }

    renderStopPointRib(isForward, totalLength, points, point, index) {
        if (point.point_type !== 'stop_point') {
            return;
        }

        /*if (isForward) {
            const previousStopPoints = _.filter(this.state.defaultVariant[isForward ? 'forward_points' : 'reverse_points'], (_point, _index) => (_point.point_type === 'stop_point') && _index < index);
            if (previousStopPoints.length === 0) {
                return;
            }
        } else {
            if (_.filter(this.state.defaultVariant[isForward ? 'forward_points' : 'reverse_points'], (_point, _index) => (_point.point_type === 'stop_point') && _index > index).length === 0) {
                return;
            }
        }*/

        const previousLength = _.sumBy(_.filter(points, (_point, _index) => isForward ? (_index < index - 1) : (_index > index)), 'distance_to_the_next_point');
        const currentLength = _.sumBy(_.filter(points, (_point, _index) => isForward ? (_index < index) : (_index >= index)), 'distance_to_the_next_point');
        // let previousLength = _.filter(this.state.defaultVariant[isForward ? 'forward_points' : 'reverse_points'], (_point, _index) => isForward ? (_index < index - 1) : (_index > index)).length;
        // let currentLength = _.filter(this.state.defaultVariant[isForward ? 'forward_points' : 'reverse_points'], (_point, _index) => isForward ? (_index < index) : (_index >= index)).length;
        // if (!isForward) {
        //     previousLength--;
        //     currentLength--;
        // }
        const previousPercent = (totalLength !== 0) ? (100 * previousLength / totalLength) : 0;
        const percent = (totalLength !== 0) ? (100 * currentLength / totalLength) : 0;

        let width = percent - previousPercent;
        let left = previousPercent;
        if (width === 0) {
            width = percent;
            left = 0;
        }

        return (
            <div key={`rib:${index}:${isForward ? 'forward' : 'reverse'}`} className="ellipse-rib _state_normal"
                 style={{
                     width: `${width}%`,
                     left: `${left}%`,
                 }}/>
        );
    }

    selectStopPoint(index, routeVariantUuid) {
        this.setState({
            selectedStopPoint: (this.state.selectedStopPoint === index) ? null : index,
            selectedRouteVariant: routeVariantUuid,
        });
    }

    close() {
        this.props.router.push(`/kiutr/routes/${this.state.routeUuid}/schedules/${this.state.scheduleUuid}`);
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                <IconButton
                    icon="times"
                    onClick={::this.close}/>
            </ContextTooltip>,
        ];
    }

    calculateAlignClosure(number, position) {
        let base = {}
        let verticalAssignPixels = {
            0: '-45px',
            1: '-45px',
            2: '-76px',
            3: '-107px'
        }
        let result;
        return function(number, position) {
            base[position] = base[position] ? _.uniq(base[position].concat([number])) : [number];
            result = verticalAssignPixels[base[position] ? base[position].length : 0];
            if (Object.keys(base).map(key => base[key]).flat(1).length === this.getWorkVehiclesFact()) {
                base = {}
            }
            return result;
        }
    }

    renderVehicle(forward_points, reverse_points, vehicle) {
        const isEnded = this.isEnded(this.getVehicleOrderExecution(vehicle));
        if (isEnded && window.RNIS_SETTINGS.vehicle_skipped) {
            return null;
        }
        if (!this.state.selectedVehicles[vehicle.uuid]) {
            return null;
        }
        if (!vehicle.status) {
            return null;
        }

        const vehiclePosition = this.getVehiclePosition(vehicle, forward_points, reverse_points);
        if (vehiclePosition === null) {
            return null;
        }
        let {position, is_forward, turn, status} = vehiclePosition;

        /*const isRing = this.isRing();
        if (isRing) {
            position /= 2;
        }*/
        const line = /*isRing ? ((position >= 50) ? 'top' : 'bottom') : */(is_forward ? 'top' : 'bottom');
        const verticalAlign = this._calculateAlign(vehicle.state_number, position, line);

        const title = `
            <div>
                <strong>${_.get(_.find(this.state.vehicle_types, {value: vehicle.vehicle_type_uuid}), 'label') || ''} ${_.get(_.find(this.state.vehicle_models, {value: vehicle.vehicle_model_uuid}), 'label') || ''}
                    (${vehicle.state_number})</strong>
                <div class="tooltip-inner_grey-block">
                    <div>
                        <strong class="tooltip-inner_grey-block-title">Перевозчик</strong>
                        <br/>
                        <span class="tooltip-inner_grey-block-text">${this.state.related.get(vehicle.unit_uuid) || ''}</span>
                    </div>
                </div>
            </div>
        `;
        return (
            <ReactTooltip key={vehicle.uuid} title={title}
                          position={line}>
                <div
                    className={classNames('ellipse-vehicle-item', `vehicle-${status}`, line + '-line', Math.abs(parseInt(verticalAlign)) > 45 && 'vehicle-align')}
                    style={{left: ((line === 'bottom') ? (100 - position) : (position)) + '%',
                        [line]: verticalAlign}}>
                    <div className="ellipse-vehicle-item__info">
                        {vehicle.state_number}
                    </div>
                    <div className="ellipse-vehicle-item__output">{turn}</div>
                </div>
            </ReactTooltip>
        );
    }

    isEnded(orderExecution) {
        return _.filter(orderExecution.data, (item) => {
            if (item.point_type === "stop_point") {
                return !item.is_skipped && !item.time_fact;
            }
        }).length === 0;
    }

    getVehiclePosition(vehicle, forward_points, reverse_points) {
        let orderExecution = this.getVehicleOrderExecution(vehicle);
        if (!orderExecution) {
            return null;
            /*orderExecution = _.first(_.sortBy(_.filter(this.state.orderExecutions, {
                vehicle_uuid: vehicle.uuid,
            }), 'date_start'));
            if (!orderExecution) {
                return (orderExecution.type === 'production_forward') ? 0 : 100;
            } else {
                return (orderExecution.type === 'production_forward') ? 100 : 0;
            }*/
        }

        let factStopPointsWithSkippedInTail = _.filter(orderExecution.data, (item) => {
            return (item.is_skipped || item.time_fact) && item.point_type === 'stop_point';
        });
        let factStopPoints = [];
        let foundTimeFact = false;
        for (let i = factStopPointsWithSkippedInTail.length - 1; i >= 0; i--) {
            if (factStopPointsWithSkippedInTail[i].time_fact) {
                foundTimeFact = true;
            }
            if (foundTimeFact) {
                factStopPoints.push(factStopPointsWithSkippedInTail[i]);
            }
        }
        _.reverse(factStopPoints);
        const latestTimeFact = _.first(_.orderBy(_.filter(_.map(orderExecution.data, 'time_fact')), ['time_fact'], ['desc']));
        for (let j = factStopPoints.length - 1; j >= 0; j--) {
            const lastStopPoint = _.get(factStopPoints[j], 'type_uuid');

            const points = (orderExecution.type === 'production_forward') ? forward_points : reverse_points;
            const totalLength = _.sumBy(points, 'distance_to_the_next_point');
            let length = 0;
            let finded = false;
            for (let i = 0; i < points.length; i++) {
                if (points[i].type_uuid === lastStopPoint) {
                    finded = true;
                    break;
                }
                length += points[i].distance_to_the_next_point;
            }

            if (finded) {
                return {
                    position: (totalLength === 0) ? 0 : ((length / totalLength) * 100),
                    is_forward: orderExecution.type === 'production_forward',
                    turn: this.getOrderExecutionTurn(orderExecution),
                    status: (latestTimeFact && moment(latestTimeFact).isSameOrAfter(moment().subtract(15, 'minutes'))) ? 'active' : 'coming_off',
                };
            }
        }
        return {
            position: 0,
            is_forward: orderExecution.type === 'production_forward',
            turn: this.getOrderExecutionTurn(orderExecution),
            status: 'coming_off',
        };//(orderExecution.type === 'production_forward') ? 0 : 100;
    }

    async toggleSelectedVehicle(uuid) {
        let selectedVehicles = this.state.selectedVehicles || {};
        selectedVehicles[uuid] = !selectedVehicles[uuid];
        await this.setState({selectedVehicles});
    }

    getTotalVehiclesPlan() {
        return this.state.turns.length;
    }

    getTotalVehiclesFact() {
        return this.state.vehicles.length;
    }

    getWorkVehiclesPlan() {
        return _.uniq(_.filter(_.map(_.flatten(_.map(_.flatten(_.map(this.state.orders, 'shifts')), 'runs')), 'vehicle_uuid'))).length;
    }

    getWorkVehiclesFact() {
        return _.filter(this.state.vehicles, (vehicle) => {
            const orderExecution = this.getVehicleOrderExecution(vehicle);
            return vehicle.status && orderExecution;
        }).length;
    }

    getVehicleOrderExecution(vehicle) {
        return _.first(_.sortBy(_.filter(this.state.orderExecutions, (orderExecution) => {
            return orderExecution.vehicle_uuid === vehicle.uuid && moment(orderExecution.date_start).isSameOrBefore(moment()) && moment(orderExecution.date_end).isSameOrAfter(moment());
        }), 'date_start'));
    }

    getOrderExecutionTurn(orderExecution) {
        return _.get(_.find(this.state.orders, (order) => {
            const runUuids = _.map(_.flatten(_.map(order.shifts, 'runs')), 'uuid');
            return (runUuids.indexOf(orderExecution.order_run_uuid) !== -1);
        }), 'turn');
    }

    getOrderExecutionRun(orderExecution) {
        let result = null;
        _.each(this.state.orders, (order) => {
            _.each(_.flatten(_.map(order.shifts, 'runs')), (run) => {
                if (run.uuid === orderExecution.order_run_uuid) {
                    result = run;
                    return false;
                }
            });
            if (result) {
                return false;
            }
        });
        return result;
    }

    getVehiclesForRouteVariant(routeVariant) {
        return _.filter(this.state.vehicles, (vehicle) => {
            const orderExecution = this.getVehicleOrderExecution(vehicle);
            if (orderExecution) {
                const run = this.getOrderExecutionRun(orderExecution);
                return run.route_variant_uuid === routeVariant.uuid;
            }
            return false;
        });
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }
}