import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';
import * as alerts from 'helpers/alerts';

const initialState = new Map({
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getIntervalMaps = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getIntervalMaps(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getIntervalMap = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getIntervalMap(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createIntervalMap = (intervalMap) => async (dispatch) => {
    try {
        const response = await api.geo.createIntervalMap(intervalMap);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const cloneIntervalMap = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.cloneIntervalMap(uuid);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateIntervalMap = (intervalMap) => async (dispatch) => {
    try {
        const response = await api.geo.updateIntervalMap(intervalMap);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteIntervalMap = (intervalMap) => async (dispatch) => {
    try {
        const response = await api.geo.deleteIntervalMap(intervalMap);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};