import _ from 'lodash';

export default class TelematicsDevice {
	/**
     * @param {String} id
     * @param {Object} device
     */
	constructor(id, device) {
		this.__id = id;
		this.__lat = _.get(device, 0);
		this.__lng = _.get(device, 1);
		this.__speed = _.get(device, 2);
		this.__course = _.get(device, 3);
		this.__type = _.get(device, 4);
		this.__time = _.get(device, 5);
		this.__animationHistoryData = _.get(device, 6);
		this.__ignition = _.get(device, 7);
		this.__satellites = _.get(device, 8);
		this.__geo_signal = _.get(device, 9);
		this.__gsm_signal = _.get(device, 10);
		this.__voltage = _.get(device, 11);
		this.__battery = _.get(device, 12);
		this.__speedLimit = _.get(device, 13);
	}

	/**
     * @return {String}
     */
	get id() {
		return this.__id;
	}

	/**
     * @return {Number}
     */
	get lat() {
		return this.__lat;
	}

	/**
     * @return {Number}
     */
	get lng() {
		return this.__lng;
	}

	/**
     * @return {Number}
     */
	get course() {
		return this.__course;
	}

	/**
     * @return {Number}
     */
	get speed() {
		return this.__speed;
	}

	/**
     * @return {String}
     */
	get type() {
		return this.__type;
	}

	/**
     * @return {String}
     */
	get time() {
		return this.__time;
	}

	get animationHistoryData() {
		return this.__animationHistoryData;
	}

	get ignition() {
		return this.__ignition;
	}

	get satellites() {
		return this.__satellites;
	}

	get geo_signal() {
		return this.__geo_signal;
	}

	get gsm_signal() {
		return this.__gsm_signal;
	}

	get voltage() {
		return this.__voltage;
	}

	get battery() {
		return this.__battery;
	}

	get speedLimit() {
		return this.__speedLimit;
	}

	get fullObject() {
		return({
			id: this.__id,
			lat: this.__lat,
			lng: this.__lng,
			speed: this.__speed,
			course: this.__course,
			type: this.__type,
			time: this.__time,
			animationHistoryData: this.__animationHistoryData,
			ignition: this.__ignition,
			satellites: this.__satellites,
			geo_signal: this.__geo_signal,
			gsm_signal: this.__gsm_signal,
			voltage: this.__voltage,
			battery: this.__battery,
			speedLimit: this.__speedLimit
		});
	}

	/**
     * @param {String} type
     */
	set type(type) {
		this.__type = type;
	}
}

export class HistoryTelematicsDevice {
	/**
     * @param {String} id
     * @param {Object} device
     */
	constructor(id, device) {
		this.__id = id;
		this.__lat = _.get(device, 0);
		this.__lng = _.get(device, 1);
		this.__speed = _.get(device, 2);
		this.__course = _.get(device, 3);
		this.__time = _.get(device, 4);
		this.__extended_data_raw = _.get(device, 5);
		this.__ignition = _.get(device, 6);
		this.__extended_data = _.get(device, 7);

		this.__satellites = _.get(device, 8);
		this.__geo_signal = _.get(device, 9);
		this.__gsm_signal = _.get(device, 10);
		this.__voltage = _.get(device, 11);
		this.__battery = _.get(device, 12);
		this.__speedLimit = _.get(device, 13);
		this.__receivedTime = _.get(device, 14);
		/*

        this.__time = _.get(device, 5);
        this.__animationHistoryData = _.get(device, 6);
        this.__ignition = _.get(device, 7);
        */
	}

	/**
     * @return {String}
     */
	get id() {
		return this.__id;
	}

	/**
     * @return {Number}
     */
	get lat() {
		return this.__lat;
	}

	/**
     * @return {Number}
     */
	get lng() {
		return this.__lng;
	}

	/**
     * @return {Number}
     */
	get course() {
		return this.__course;
	}

	/**
     * @return {Number}
     */
	get speed() {
		return this.__speed;
	}

	/**
     * @return {String}
     */
	get type() {
		return this.__type;
	}

	/**
     * @return {String}
     */
	get time() {
		return this.__time;
	}

	get extendedDataRaw() {
		return this.__extended_data_raw;
	}

	get extendedData() {
		return this.__extended_data;
	}

	get ignition() {
		return this.__ignition;
	}

	get satellites() {
		return this.__satellites;
	}

	get geo_signal() {
		return this.__geo_signal;
	}

	get gsm_signal() {
		return this.__gsm_signal;
	}

	get voltage() {
		return this.__voltage;
	}

	get battery() {
		return this.__battery;
	}

	get speedLimit() {
		return this.__speedLimit;
	}

	get receivedTime() {
		return this.__receivedTime;
	}

     get isHistory() {
        return true;
    }

    /**
     * @param {String} type
     */
	set type(type) {
		this.__type = type;
	}
}
