import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import DashboardTable from 'components/modules/analytics/base-components/DashboardTable';
import Datepicker from "components/ui/form/datepicker";
import moment from 'moment';
import formats from 'dictionaries/formats';
import './style.less';

@propTypes({

})

@defaultProps({

})

export default class MechanismDowntimeWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            from: mmoment().subtract(1, 'day').format(formats.DATE_API),
            to: moment().subtract(1, 'day').format(formats.DATE_API),
            data: [
                {name: 'Отдел 1', total: 1, breaking: 0, driverAbsenteeism: 1, downtime: 0},
                {name: 'Отдел 2', total: 5, breaking: 1, driverAbsenteeism: 4, downtime: 0},
                {name: 'Отдел 3', total: 6, breaking: 3, driverAbsenteeism: 2, downtime: 1},
                {name: 'Отдел 4', total: 0, breaking: 0, driverAbsenteeism: 0, downtime: 0},
                {name: 'Отдел 5', total: 12, breaking: 5, driverAbsenteeism: 2, downtime: 5}
            ]
        };

        this.metadata = {
            0: {field: 'name', name: 'Отделы', sort: true},
            1: {field: 'total', name: 'Всего', sort: true},
            2: {field: 'breaking', name: 'Поломка', sort: true},
            3: {field: 'driverAbsenteeism', name: 'Невыход водителя', sort: true},
            4: {field: 'downtime', name: 'Простой', sort: true}
        };
    }

    fromChange = ({target: {value}}) => {
        this.setState({from: value});
    };

    toChange = ({target: {value}}) => {
        this.setState({to: value});
    };

    render() {
        return (
            <div className="mechanism-downtime-widget">
                <div className="date-wrapper">
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange}/>
                    </div>
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange}/>
                    </div>
                </div>
                <div className="table-wrapper">
                    <DashboardTable data={this.state.data} metadata={this.metadata}/>
                </div>
            </div>
        );
    }
}