import React from 'react';
import './index.less';
import moment from "moment";
import formats from "dictionaries/formats";
import classNames from 'classnames';
import currentUser from 'helpers/current-user';

export default function LogItem({log, getUserName, showDefect}) {
    switch (log.type) {
        case 'status':
            return (
                <div className="LogItem LogItem-status">
                    {log.text} с {moment(log.time).add(currentUser.user.is_timezoneoffset, 'hours').format(formats.DATETIME)}
                </div>
            );
        case 'defect':
            return (
                <div className={classNames('LogItem', log.is_canceled ? 'canceled' : '')}>
                    <div className="LogItem-table">
                        <div className="LogItem-cell LogItem-time border-right">
                            {moment(log.time).add(currentUser.user.is_timezoneoffset, 'hours').format(formats.DATETIME)}
                        </div>
                        <div className="LogItem-cell LogItem-text">
                            {log.defectUuid ? (
                                <a href="javascript:void(0)"
                                   onClick={showDefect.bind(this, log.defectUuid)}>{log.text}</a>
                            ) : log.text}
                            <br/>
                            {log.cancelDefect ? (
                                <a className="cancel" href="javascript:void(0)"
                                   onClick={log.cancelDefect.bind(this, log.defectUuid)}>&times;</a>
                            ) : null}
                        </div>
                        <div
                            className="LogItem-cell LogItem-user border-left">{log.user_uuid ? getUserName(log.user_uuid) : null}</div>
                    </div>

                    <div className="LogItem-history">
                        {log.history.map((history, index) => {
                            if (history.type === 'created') {
                                return;
                            }

                            return (
                                <div key={index} className="LogItem-history-item">
                                    <span>{(history.type === 'updated') ? 'Изменено' : 'Отменено'}</span>
                                    <div>{moment(history.time).add(currentUser.user.is_timezoneoffset, 'hours').format(formats.TIME)}</div>
                                    <div>{getUserName(history.user_uuid)}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        default:
            return (
                <div className="LogItem">
                    <div className="LogItem-table">
                        <div className="LogItem-cell LogItem-time border-right">
                            {moment(log.time).add(currentUser.user.is_timezoneoffset, 'hours').format(formats.DATETIME)}
                        </div>
                        <div className="LogItem-cell LogItem-text">{log.text}</div>
                        <div
                            className="LogItem-cell LogItem-user border-left">{log.user_uuid ? getUserName(log.user_uuid) : null}</div>
                    </div>
                </div>
            );
    }
}