import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Page from 'components/ui/page';
import $ from 'jquery';
import _ from 'lodash';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import moment from "moment";
import formats from "dictionaries/formats";
import {EntityList} from "helpers/entity";
import IconButton from "components/ui/icon-button";
import systems from "dictionaries/systems";
import {getGatnViolationJournals} from "store/reducers/gatn/gatn_violations";
import {getEntityNames} from "store/reducers/system";
import Datepicker from "components/ui/form/datepicker";

@connect(state => ({}), {getGatnViolationJournals, getEntityNames})

export default class AgricultureWorkComponent extends Component {

    render() {
        return (
            <Page
                pageId="Main"
                title="Контроль работы">
                <div id="blocks">
                    Нет данных
                </div>
            </Page>
        );
    }
}