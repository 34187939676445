import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import $ from 'jquery';
import _ from 'lodash';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import moment from "moment";
import formats from "dictionaries/formats";
import {EntityList} from "helpers/entity";
import systems from "dictionaries/systems";
import {getGatnViolationJournals} from "store/reducers/gatn/gatn_violations";
import {getEntityNames} from "store/reducers/system";
import Datepicker from "components/ui/form/datepicker";
import * as alerts from "helpers/alerts";

@connect(state => ({}), {})

export default class GarbageViolationsComponent extends BaseTableWithEditorComponent {

    title = `${systems.garbage} → Контроль работы`;
    baseUrl = '/garbage/violations';

    select = 'none';

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            from: moment().subtract(1, 'day').startOf('day'),
            to: moment().endOf('day'),
        });
    }

    componentDidMount() {
        if (!this.refs.table) return;
        $(this.refs.table.getWrappedInstance().refs.table).on('click', '.has-violation', ::this.onViolationCellClick);

        alerts.alert('Отсутствуют данные из АИС Отходы');
    }

    async componentWillUpdate(props) {
        if (props.params) {
            const vehicle = props.params.vehicle;
            const date = props.params.date;
            const type = props.params.type;
            if (this.state.editorUuid !== vehicle || this.state.editorDate !== date || this.state.editorType !== type || this.state.showEditorModal !== !!vehicle) {
                this.setState({
                    showEditorModal: !!vehicle,
                    editorUuid: vehicle,
                    editorDate: date,
                    editorType: type,
                });
            }
        }
    }

    onViolationCellClick(e) {
        const el = $(e.target);
        const vehicle = el.data('vehicle');
        const date = el.data('date');
        const type = el.data('type');

        this.props.router.push(`${this.baseUrl}/${vehicle}/${date}/${type}`);
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Дата')
                .fromField('date')
                .denyColumnFilter()
                .withDrawer((item) => moment(item.date).format(formats.DATE)),

            new Column('Гос. номер')
                .fromField('state_number'),

            new Column('Марка ТС')
                .fromField('vehicle_mark_uuid')
                .withDrawer(item => item.vehicle_mark_uuid ? this.state.related.get(item.vehicle_mark_uuid) : '<code>-</code>')
                .denyColumnFilter(),

            new Column('Модель ТС')
                .fromField('vehicle_model_uuid')
                .withDrawer(item => item.vehicle_model_uuid ? this.state.related.get(item.vehicle_model_uuid) : '<code>-</code>')
                .denyColumnFilter(),

            new Column('Затор на дорогах')
                .fromField('speed_violations')
                .withAlign('center')
                .withDrawer((item) => (item.speed_violations > 0) ? `<span class="has-violation" data-type="speed" data-vehicle="${item.vehicle_uuid}" data-date="${moment(item.date).format(formats.DATE_URL)}">${item.speed_violations}</span>` : '<span class="no-violation"></span>'),

            new Column('Сход с маршрута')
                .fromField('speed_violations')
                .withAlign('center')
                .withDrawer((item) => (item.speed_violations > 0) ? `<span class="has-violation" data-type="speed" data-vehicle="${item.vehicle_uuid}" data-date="${moment(item.date).format(formats.DATE_URL)}">${item.speed_violations}</span>` : '<span class="no-violation"></span>'),

            new Column('Неисправность ТС')
                .fromField('speed_violations')
                .withAlign('center')
                .withDrawer((item) => (item.speed_violations > 0) ? `<span class="has-violation" data-type="speed" data-vehicle="${item.vehicle_uuid}" data-date="${moment(item.date).format(formats.DATE_URL)}">${item.speed_violations}</span>` : '<span class="no-violation"></span>'),

        ]);
    }

    loadData(meta) {
        meta.filters.withPeriod = [
            this.state.from,
            this.state.to,
        ];

        return {
            isOk: true,
            data: {
                headers: {
                    meta: {
                        pagination: {
                            total: 0,
                        },
                    },
                },
                payload: {
                    items: [],
                },
            },
            payload: {
                items: [],
            },
        };
    }

    renderHeaderActions() {
        return [
            this.diapasonFilter(),
        ];
    }

    diapasonFilter() {
        return (
            <div key="diapason">
                <div className="top-menu__label">Выбор диапазона:</div>
                <Datepicker style="dark" value={this.state.from} onChange={::this.fromChange}
                            disabledDays={::this.disabledDays}/>
                <Datepicker style="dark" value={this.state.to} onChange={::this.toChange}
                            disabledDays={::this.disabledDays}/>
            </div>
        );
    }

    disabledDays(day) {
        return moment(day).isAfter(moment(), 'day')
    }

    async fromChange({target: {value}}) {
        await this.setState({from: value});
        this.reload();
    }

    async toChange({target: {value}}) {
        await this.setState({
            to: value,
            from: moment(this.state.from).isAfter(moment(value)) ? value : this.state.from,
        });
        this.reload();
    }

    getCreateButton() {
        return null;
    }
}