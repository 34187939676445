import React, {Component} from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import GlobalLoaderComponent from "components/ui/global-loader";
import _ from 'lodash';
import './style.less';
import {connect} from "react-redux";
import moment from "moment";
import {getPercentForWorksDone} from 'helpers/math';
import {getTabletWorkSummary, getTabletWorkSummaryPercent} from "store/reducers/reports/tablet";
import ReactResizeDetector from 'react-resize-detector';
import formats from "dictionaries/formats";
import WorkPercent from "./WorkPercent/index";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";

@propTypes({
  barChartFillColor: PropTypes.string
})

@defaultProps({
  barChartFillColor: ''
})

@connect(state => ({}), {getTabletWorkSummary, getTabletWorkSummaryPercent, getDictionaryList})

class KursWorkWidget extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isError: false,
      isLoading: false,
      from: this.props.location.query.date_from ? moment(this.props.location.query.date_from).format(formats.DATE_API) : moment().format(formats.DATE_API),
      to: this.props.location.query.date_to ? moment(this.props.location.query.date_to).format(formats.DATE_API) : moment().format(formats.DATE_API),
      data: [],
      normalizedJobsData: [],
      calculateMiddlePercent: 0,
      fontSize: 12,
      lineHeight: 15,
      work_types: [],
      work_type_uuid: 'all',
      communal_municipalities_uuid: 'all'
    };
  }

  async componentWillMount() {
    this.loadDictionaries([
      'work_types',
    ]);
    this.loadData();
  }

  fromChange = async ({target: {value}}) => {
    await this.setState({from: value});
    const query = Object.assign({}, this.props.location.query);
    query.date_from = value;
    this.props.router.replace(`${this.props.location.pathname}?${(new URLSearchParams(query)).toString()}`);
    this.loadData();
  };

  toChange = async ({target: {value}}) => {
    await this.setState({to: value});
    const query = Object.assign({}, this.props.location.query);
    query.date_to = value;
    this.props.router.replace(`${this.props.location.pathname}?${(new URLSearchParams(query)).toString()}`);
    this.loadData();
  };

  async loadData() {
    await this.setState({ normalizedJobsData: [], data: [], isLoading: true });
    const meta = {
      filters: {
        withPeriod: [this.state.from, this.state.to],
      }
    }

    if (this.state.communal_municipalities_uuid !== 'all') {
      meta.filters.withCommunalMunicipalities = [this.state.communal_municipalities_uuid]
    }

    if (this.state.work_type_uuid !== 'all') {
      meta.filters.withWorkTypes = [this.state.work_type_uuid]
    }

    if (window.RNIS_SETTINGS.CITY_MURMANSK) {
      const response = await this.props.getTabletWorkSummaryPercent(meta);
      this.setState({ isLoading: false });

      if (response.isOk) {
        this.setState({
          data: response.payload.municipalities
        })

        const { municipalities } = response.payload
        let uniqueJobsUuid = []
        let worksData = {}
        municipalities.map(municipalitie => {
          if (municipalitie.work_types && municipalitie.work_types.length > 0) {
            municipalitie.work_types.forEach(item => {
              const { work_type_uuid, percent } = item
              if (!uniqueJobsUuid.includes(work_type_uuid)) {
                uniqueJobsUuid.push(work_type_uuid)
              }
              if (worksData[work_type_uuid]){
                let {allProcent, midle, count} = worksData[work_type_uuid]
                count += 1
                allProcent += percent
                midle = Math.round(allProcent / count)
                worksData[work_type_uuid] = {
                  count,
                  allProcent,
                  midle
                }
              } else {
                worksData[work_type_uuid] = {
                  count: 1,
                  allProcent: percent,
                  midle: percent
                }
              }
            })
          }
        })

        this.setState({
          calculateMiddlePercent: response.payload.total_percent
        })
        uniqueJobsUuid.map(job => {
          const localWorkType = this.state.work_types.filter(item => item.value === job);
          const percent = worksData[job].midle
          this.setState(prevState => ({

            normalizedJobsData: [ ...prevState.normalizedJobsData, {
              uuid: job,
              label: localWorkType.length ? localWorkType[0].label : "Без названия",
              work_type_uuid: localWorkType.length ? localWorkType[0].value : "",
              percent: percent
            } ]
          }))
        })
      } else {
        response.showErrors();
      }
    } else {
      const response = await this.props.getTabletWorkSummary(meta);
      this.setState({isLoading: false});

      if (response.isOk) {
        this.setState({
          data: response.payload.items
        })
        const uniqueJobsUuid = _.uniq(response.payload.items.map(item => item.work_type_uuid)).filter(item => item);
        const middlePercent = [];
        uniqueJobsUuid.map(job => {
          const localWorkType = this.state.work_types.filter(item => item.value === job);
          const localAllJobes = response.payload.items.filter(item => item.work_type_uuid === job);

          const [percent, fact, plan] = getPercentForWorksDone(localAllJobes);
          const calculatePercent = getPercentForWorksDone(localAllJobes, null, true);
          middlePercent.push(calculatePercent)

          this.setState(prevState => ({
            normalizedJobsData: [...prevState.normalizedJobsData, {
              uuid: job,
              label: localWorkType.length ? localWorkType[0].label : "Без названия",
              work_type_uuid: localWorkType.length ? localWorkType[0].value : "",
              fact,
              plan,
              percent: _.round(percent, 0),
            }]
          }))
        })

        let sum = 0;
        const lengthMiddlePercentArray = middlePercent.length;
        for(let i = 0; i < lengthMiddlePercentArray; i++){
          sum = sum + middlePercent[i];
        }

        await this.setState({
          calculateMiddlePercent: sum/lengthMiddlePercentArray
        })

      } else {
        response.showErrors();
      }
    }
  }

  async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
    this.setState({dictionariesLoading: true});
    let meta = {
      filters: {
        withComponent: component,
      },
    };
    if (!withoutOrder) {
      meta.order = {
        column: 'name',
        direction: 'asc',
      };
    }
    const response = await this.props.getDictionaryList(dictionaries, meta);
    this.setState({dictionariesLoading: false});
    if (response.isOk) {
      let state = this.state;
      _.each(response.payload.items, (item) => {
        state[item.key] = _.map(item.documents, (document) => ({
          value: document.uuid,
          label: document.short_name || document.name,
          document,
        }));
      });
      this.setState(state);
    } else {
      response.showErrors();
    }
  }

  _onResize(width, height) {
    const minWidth = 624;
    const minHeight = 268;
    const maxWidth = 1000;
    const maxHeight = 500;
    const minFontSize = 12;
    const maxFontSize = 30;
    const minLineHeight = 15;
    const maxLineHeight = 40;

    const widthRatio = Math.min(1, Math.max(0, (width - minWidth) / (maxWidth - minWidth)));
    const heightRatio = Math.min(1, Math.max(0, (height - minHeight) / (maxHeight - minHeight)));
    const ratio = (widthRatio + heightRatio) / 2;

    this.setState({
      fontSize: minFontSize + (maxFontSize - minFontSize) * ratio,
      lineHeight: minLineHeight + (maxLineHeight - minLineHeight) * ratio,
      height: height,
    });
  }

  render() {
    const loader = this.state.isLoading ? <GlobalLoaderComponent/> : null;

    return (
      <div className="kiutr-widget">
        {loader}
        <div className="full-size kiutr-widget">
          <WorkPercent
              calculateMiddlePercent={this.state.calculateMiddlePercent}
            fontSize={this.state.fontSize}
            data={this.state.data}
            normalizedJobsData={this.state.normalizedJobsData}
            height={this.state.height}
            from={moment(this.state.from).format(formats.DATE_API)}
            to={moment(this.state.to).format(formats.DATE_API)}
            fromChange={this.fromChange}
            toChange={this.toChange}
          />
        </div>
        <ReactResizeDetector handleWidth handleHeight onResize={::this._onResize}/>
      </div>
    );
  }
}

export default withRouter(KursWorkWidget)