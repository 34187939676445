import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import _, { result } from 'lodash';
import L from 'leaflet';
import MapComponent from "components/ui/map";
import {connect} from "react-redux";
import {getRoadPart, getRoadParts} from "store/reducers/kurs/road_parts";
import {getDocumentsList} from "store/reducers/reports/reports";
import Page from 'components/ui/page';
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import GlobalLoaderComponent from "components/ui/global-loader";
import DirectionalGeojson from "components/ui/map/objects/directional-geojson";

import './index.less';
import DirectionalPolyline from "components/ui/map/objects/directional-polyline";
import MapPolyline from 'components/ui/map/base/polyline';
import MapGeojson from "components/ui/map/base/geojson";
import 'lib/leaflet.export';
import {api} from "helpers/api";
import moment from "moment";
import formats from "dictionaries/formats";
import {makeResponse} from "helpers/response";
//import 'html2canvas';
import download from 'downloadjs';

@connect(state => ({}), {getDocumentsList, getRoadPart, getRoadParts}, null, {withRef: true})

export default class KursRoadPartsMap extends Component {

    state = {
        loading: true,
        document: null,
        roadParts: {},
        roadPartTasks: {},
        polylines: [],
    };

    excludeUuid = [
    "08147dd6-3f72-11eb-bba5-0252e7a59e15",
    "0e5f4332-3f73-11eb-9abc-0252e7a59e15",
    "22f12de8-1ab5-11eb-8a75-0252e7a59e15",
    "3e30ecf0-1b56-11eb-aedd-0252e7a59e15",
    "4256414e-1acb-11eb-bf95-0252e7a59e15",
    "57929272-1df7-11eb-988b-0252e7a59e15",
    "5f959e90-1aac-11eb-9a6a-0252e7a59e15",
    "61e21024-3f74-11eb-9bd2-0252e7a59e15",
    "7829de8e-3f74-11eb-9883-0252e7a59e15",
    "7a7e8ef2-3f77-11eb-b736-0252e7a59e15",
    "93d0ebac-1ab5-11eb-812c-0252e7a59e15",
    "9a0b508e-3f72-11eb-ac21-0252e7a59e15",
    "9d6852d0-1aac-11eb-b27f-0252e7a59e15",
    "a1065b5a-1ab4-11eb-a2be-0252e7a59e15",
    "b6ccf8ce-1ab7-11eb-9e3b-0252e7a59e15",
    "b7f6219a-1b6a-11eb-a8fd-0252e7a59e15",
    "b8d7c7d0-1b65-11eb-b05f-0252e7a59e15",
    "b94b8ed6-3f73-11eb-9513-0252e7a59e15",
    "bcb4a81c-1b53-11eb-bc28-0252e7a59e15",
    "c1002be2-1a79-11eb-89aa-0252e7a59e15",
    "c3365716-3f76-11eb-9b5f-0252e7a59e15",
    "c523b442-1b5e-11eb-a00e-0252e7a59e15",
    "ce541430-1ded-11eb-aaa1-0252e7a59e15",
    "e6926492-1b64-11eb-9694-0252e7a59e15"];

    async componentWillMount() {
        this.loadDocument();
    }

    async loadDocument() {
        const response = await this.props.getDocumentsList({
            filters: {
                withUuid: this.props.params.uuid,
            },
        });

        if (response.isOk) {
            await this.setState({
                document: _.first(response.payload.items),
            });
            await this.preloadRoadParts();
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
            });
            response.showErrors();
        }
    }

    getRows() {
        if (this.state.document.report_uri === 'road_part_combined_report') {
            return _.concat(this.state.document.data.road_part_vehicle_visits,
                this.state.document.data.road_part_visits);
        } else {
            return this.state.document.data.rows;
        }
    }

    async preloadRoadParts() {
        let roadParts = [];
        if (this.state.document.report_uri === 'road_part_combined_report') {
            const response = await this.props.getRoadParts({
                pagination: {
                    page: 1,
                    limit: 10000,
                },
                filters: {
                    withUuid: _.uniq(_.filter(_.concat(this.state.document.data.road_part_not_visits,
                        _.map(this.state.document.data.road_part_visits, 'road_part_uuid'),
                        _.map(this.state.document.data.road_part_visits_unconfirmed || [], 'road_part_uuid'),
                        _.map(this.state.document.data.road_part_vehicle_visits, 'road_part_uuid'),
                    ))),
                },
            });
            if (response.isOk) {
                roadParts = _.concat(roadParts, response.payload.items);
            }
        } else {
            return;
        }

        await this.setState({
            roadParts,
        });

        let group = new L.FeatureGroup();
        _.each(roadParts, (roadPart) => {
            if (!roadPart.geometry || _.isEmpty(roadPart.geometry)) {
                return;
            }
            group.addLayer(L.geoJSON(roadPart.geometry));
        });
        try {
            this.refs.map.getWrappedInstance().fitBounds(group.getBounds());
        } catch (e) {
        }
    }

    render() {
        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;
        let title = '';
        if (this.state.document) {
            title = _.get(this.state.document, 'report_name', '...') + ' | '
              + (this.state.document.data.dateFrom ? moment(this.state.document.data.dateFrom.date).format(formats.DATE) : ' ') + ' '
              + (this.state.document.data.dateTo ? moment(this.state.document.data.dateTo.date).format(formats.DATE) : ' ') + ' | '
              + (this.state.document.data.unit ? this.state.document.data.unit : ' ') + ' | '
              + (this.state.document.data.vehicle ? this.state.document.data.vehicle : ' ')

        }
         return (
            <Page pageId="KursRoadPartsMap"
                  title={title}
                  className="r-block_noIndent"
                  headerActions={this.renderHeaderActions()}>
                {loader}
                {this.renderContent()}
            </Page>
        );
    }

    renderHeaderActions() {
        return [
            (
                window.RNIS_SETTINGS.CITY_NNOVGOROD ? (
                <ContextTooltip key="kurs.report.export" code="kurs.report.export" default="Печать">
                    <IconButton icon="print" onClick={::this.printWindow}/>
                </ContextTooltip>
                ) : (
                <ContextTooltip key="kurs.report.export" code="kurs.report.export" default="Экспорт">
                    <IconButton icon="export" onClick={::this.exportToPdf}/>
                </ContextTooltip>
                )
            ),
            <ContextTooltip key="kurs.task.back" code="kurs.task.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ];
    }

    printWindow() {
        window.print()
    }

    async exportToPdf() {
        this.refs.map.getWrappedInstance().map.export({
            format: 'image/png',
            fileName: _.get(this.state.document, 'report_name', '...') + '.png',
            container: this.refs.map.getWrappedInstance().map._container,
            afterExport: (dataUrl) => {
                const image = dataUrl.data.replace('data:image/png;base64,', '');
                this.convertToPdf(image);
            },
        });
    }

    async convertToPdf(content) {
        const response = await makeResponse(() => {
            return api.converter.convertPngToPdf(content);
        });

        if (response.isOk) {
            const content = response.payload.content;
            download(`data:application/pdf;base64,${content}`, _.get(this.state.document, 'report_name', '...') + '.pdf');
        } else {
            response.showErrors();
        }
    }

    onClose() {
        this.props.router.push('/reports');
    }

    renderContent() {
        return (
            <MapComponent
                ref="map"
                component="road"
            >
                {this.refs.map ? this.renderMapContents() : null}
            </MapComponent>
        );
    }

    revertCoordinates(element, coordinatesArray) {
        // Делаем из координат [33, 66] => [66, 33]
        let result;
        result = coordinatesArray.map(item => {
            if (Array.isArray(item[0])) {
                return item.map(item => {
                    return [item[1], item[0]]
                })
            } else {
                return [item[1], item[0]]
            }
        })
        return result;
    }

    renderMapContents() {
        if (this.state.loading) {
            return null;
        }

        if(this.state.document.report_uri && this.state.document.report_uri === 'contracts_street_maintenance_map_report') {
            const mainTained = this.state.document.data.maintained_objects;
            const unMainTained = this.state.document.data.unmaintained_objects;
            const Polylines = [];
            for (let mainTainedElement in mainTained) {
                const isExclude = this.excludeUuid.includes(mainTained[mainTainedElement].uuid) ? true : false;
                const isMOPolygon = mainTained[mainTainedElement].geometry.type === "Polygon" && window.RNIS_SETTINGS.CITY_MURMANSK;
                if (mainTained[mainTainedElement].geometry.coordinates && isExclude === false) {
                    Polylines.push(<MapPolyline
                        isNeedArrows={false}
                        key={mainTainedElement}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        coordinates={this.revertCoordinates(mainTainedElement, mainTained[mainTainedElement].geometry.coordinates)}
                        opacity= {isMOPolygon ? 0.8 : 0.6}
                        color={"#0000ff"}
                    />)
                }
            }
            for (let unMainTainedElement in unMainTained) {
                const isExclude = this.excludeUuid.includes(unMainTained[unMainTainedElement].uuid) ? true : false;
                const isMOPolygon = unMainTained[unMainTainedElement].geometry.type === "Polygon" && window.RNIS_SETTINGS.CITY_MURMANSK;
                if (unMainTained[unMainTainedElement].geometry.coordinates && isExclude === false) {
                    Polylines.push(<MapPolyline
                        isNeedArrows={false}
                        key={unMainTainedElement}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        coordinates={this.revertCoordinates(unMainTainedElement, unMainTained[unMainTainedElement].geometry.coordinates)}
                        opacity={isMOPolygon ? 0.8 : 0.6}
                        fill={"#ff5733"}
                        fillOpacity={isMOPolygon ? 0.4 : 0}
                        color={isMOPolygon ? "#ff5733" : "#8b00ff"}
                    />)
                }
            }
            return Polylines
        }

        if ((this.state.document.report_uri && this.state.document.report_uri === 'telematics_history_report') || (this.state.document.report_uri && this.state.document.report_uri === 'communal_telematics_history_report') || (this.state.document.report_uri && this.state.document.report_uri === 'kiutr_telematics_history_report')) {
            return (
                <DirectionalPolyline
                    map={this.refs.map}
                    leafletMap={this.refs.map.getWrappedInstance().map}
                    coordinates={_.map(this.state.document.data.rows, (row) => {
                        return [
                            row.latitude,
                            row.longitude,
                        ];
                    })}
                    opacity={1}
                />
            );
        }

        if (this.state.document.report_uri && this.state.document.report_uri === 'road_part_combined_report') {
            return [
                _.map(this.state.document.data.road_part_not_visits, this.renderRow.bind(this, 'road_part_not_visits')),
                _.map(this.state.document.data.road_part_visits_unconfirmed || [], this.renderRow.bind(this, 'road_part_visits_unconfirmed')),
                //_.map(this.state.document.data.road_part_vehicle_visits, this.renderRow.bind(this, 'road_part_vehicle_visits')),
                _.map(this.state.document.data.road_part_visits, this.renderRow.bind(this, 'road_part_visits')),
            ];
        } else {
            return _.map(this.state.document.data.rows, this.renderRow.bind(this, this.state.document.report_uri));
        }
    }

    renderRow(reportUri, row, index) {
        if ((reportUri === 'road_part_visits') && (this.hasUnconfirmedRoadPart(row.road_part_uuid))) {
            return null;
        }
        if (reportUri === 'road_part_not_visits') {
            const roadPart = _.find(this.state.roadParts, {uuid: row});
            if (!roadPart || !roadPart.geometry || _.isEmpty(roadPart.geometry)) {
                return null;
            }

            return (this.state.document.report_uri && this.state.document.report_uri === 'road_part_combined_report') ? (
                <MapGeojson
                    key={`${reportUri}:${index}`}
                    map={this.refs.map}
                    leafletMap={this.refs.map.getWrappedInstance().map}
                    geometry={roadPart.geometry}
                    tooltip={`${roadPart.register_number !== "None" ? roadPart.register_number : ''} ${roadPart.name}`}
                    color={this.getColor(reportUri, row)}
                    opacity={1}
                    zIndex={this.getZIndex(reportUri)}
                />
            ) : (
                <DirectionalGeojson
                    key={`${reportUri}:${index}`}
                    map={this.refs.map}
                    leafletMap={this.refs.map.getWrappedInstance().map}
                    geometry={roadPart.geometry}
                    tooltip={`${roadPart.register_number !== "None" ? roadPart.register_number : ''} ${roadPart.name}`}
                    color={this.getColor(reportUri, row)}
                    opacity={1}
                />
            );
        }

        let geometry = row.geometry;
        const roadPart = _.find(this.state.roadParts, {uuid: row.road_part_uuid});
        if (!roadPart) {
            return;
        }

        !this.state.roadPartTasks[row.road_part_uuid] ? this.state.roadPartTasks[row.road_part_uuid] = [[reportUri, row, roadPart]] : this.state.roadPartTasks[row.road_part_uuid].push([reportUri, row, roadPart]);

        if (!geometry) {
            if (!roadPart || !roadPart.geometry || _.isEmpty(roadPart.geometry)) {
                return null;
            }
            geometry = roadPart.geometry;
        }

        return (this.state.document.report_uri && this.state.document.report_uri === 'road_part_combined_report') ? (
            <MapGeojson
                key={`${reportUri}:${index}`}
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                geometry={geometry}
                tooltip={`${roadPart.register_number !== "None" ? roadPart.register_number : ''} ${roadPart.name}`}
                color={this.getColor(reportUri, row)}
                opacity={1}
                popup={this.getPopup.bind(this, reportUri, row, roadPart)}
                zIndex={this.getZIndex(reportUri)}
            />
        ) : (
            <DirectionalGeojson
                key={`${reportUri}:${index}`}
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                geometry={geometry}
                tooltip={`${roadPart.register_number !== "None" ? roadPart.register_number : ''} ${roadPart.name}`}
                color={this.getColor(reportUri, row)}
                opacity={1}
                popup={this.getPopup.bind(this, reportUri, row, roadPart)}
            />
        );
    }

    getColor(reportUri, row) {
        switch (reportUri) {
            case 'road_part_not_visits':
                return '#0000FF';
            case 'road_part_visits_unconfirmed':
                if (this.hasConfirmedRoadPart(row.road_part_uuid)) {
                    return 'yellow';
                }
                return '#f65c50';
            case 'road_part_vehicle_visits':
                return '#81c784';
            case 'road_part_visits':
                if (row.visits <= 2) {
                    return '#81c784';
                } else if (row.visits <= 5) {
                    return '#4d8050';
                } else if (row.visits <= 7) {
                    return '#385739';
                } else {
                    return '#254528';
                }
        }
        return '#81c784';
    }

    hasConfirmedRoadPart(uuid) {
        return _.filter(this.state.document.data.road_part_visits, {road_part_uuid: uuid}).length > 0;
    }

    hasUnconfirmedRoadPart(uuid) {
        return _.filter(this.state.document.data.road_part_visits_unconfirmed, {road_part_uuid: uuid}).length > 0;
    }

    getZIndex(reportUri) {
        switch (reportUri) {
            case 'road_part_not_visits':
                return 0;
            case 'road_part_visits_unconfirmed':
                return 1;
            case 'road_part_vehicle_visits':
                return 2;
            case 'road_part_visits':
                return 3;
        }
        return 0;
    }

    getPopup(reportUri, row, roadPart, onClose) {
        return (
            <div className="b-modal b-modal-map map-tooltip-modal tooltip-event">
                <div className="b-modal__header">
                    <a href="#" className="b-modal__close" onClick={onClose}/>
                    <div className="b-modal__name">{roadPart.register_number !== "None" ? roadPart.register_number : ''} {roadPart.name}</div>
                </div>
                <div className="b-modal__body">
                {this.state.roadPartTasks[row.road_part_uuid] ? this.state.roadPartTasks[row.road_part_uuid].map(item => {
                    reportUri = item[0];
                    row = item[1];
                    roadPart = item[2];
                    return (
                        <div>
                            {row.date ? (
                                <div className="b-block">
                                    <div className="b-block__desc">Дата</div>
                                    <div className="b-block__value">{row.date}</div>
                                </div>
                                ) : null}
                            {row.date_from ? (
                                <div className="b-block">
                                    <div className="b-block__desc">Время начала</div>
                                    <div className="b-block__value">{row.date_from}</div>
                                </div>
                                ) : null}
                            {row.date_to ? (
                                <div className="b-block">
                                    <div className="b-block__desc">Время окончания</div>
                                    <div className="b-block__value">{row.date_to}</div>
                                </div>
                                ) : null}
                            {row.time ? (
                                <div className="b-block">
                                    <div className="b-block__desc">Время работы</div>
                                    <div className="b-block__value">{row.time}</div>
                                </div>
                                ) : null}
                                <div className="b-block">
                                    <div className="b-block__desc">Вид работы</div>
                                    <div className="b-block__value">{row.work_type}</div>
                                </div>
                                <div className="b-block">
                                    <div className="b-block__desc">Начало участка</div>
                                    <div className="b-block__value">{row.from}</div>
                                </div>
                                <div className="b-block">
                                    <div className="b-block__desc">Конец участка</div>
                                    <div className="b-block__value">{row.to}</div>
                                </div>
                                <div className="b-block">
                                    <div className="b-block__desc">Протяженность участка</div>
                                    <div className="b-block__value">{row.length}</div>
                                </div>
                            {row.visits ? (
                                <div className="b-block">
                                    <div className="b-block__desc">Посещений</div>
                                    <div className="b-block__value">{row.visits}</div>
                                </div>
                                ) : null}
                            {row.tasks ? this.renderTasks(reportUri, row) : null}
                        </div>
                    )}) : null}
                </div>
                <div className="b-modal__footer"/>
            </div>
        );
    }

    renderTasks(reportUri, row) {
        if (!row.task_uuids) {
            return (
                <div className="b-block">
                    <div className="b-block__desc">Задания</div>
                    <div className="b-block__value">{row.tasks}</div>
                </div>
            );
        }

        let taskNumbers = row.tasks.split(';');
        let taskUuids = row.task_uuids.split(';');

        if (reportUri === 'road_part_visits_unconfirmed') {
            const confirmedPart = _.find(this.state.document.data.road_part_visits, {road_part_uuid: row.road_part_uuid});
            if (confirmedPart) {
                taskNumbers = _.concat(taskNumbers, confirmedPart.tasks.split(';'));
                taskUuids = _.concat(taskUuids, confirmedPart.task_uuids.split(';'));
            }
        }
        let tasks = {};
        _.each(taskNumbers, (number, index) => {
            const uuid = _.get(taskUuids, index);
            if (uuid) {
                tasks[uuid] = number;
            }
        });

        return (
            <div className="b-block">
                <div className="b-block__desc">Задания</div>
                <div className="b-block__value">
                    {_.map(tasks, (number, uuid) => {
                        return (
                            <a key={uuid} href={`/road/tasks/${uuid}`} target="_blank"
                               style={{display: 'block'}}>{number}</a>
                        );
                    })}
                </div>
            </div>
        );
    }

    goto(url) {
        this.props.router.push(url);
    }

}
