import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import _ from 'lodash';

import {Link} from "react-router";

import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import Column from "components/ui/column";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import {deleteStopPoint, getStopPoints} from "store/reducers/geo/stop-points";
import systems from "dictionaries/systems";
import {deleteSupernumerarySituation, getSupernumerarySituations} from "store/reducers/kurs/supernumerary_situations";
import moment from "moment";
import formats from "dictionaries/formats";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getTasks} from "store/reducers/kurs/tasks";
import {getVehicles} from "store/reducers/kurs/vehicles";
import {getUnits} from "store/reducers/organizational_units/units";
import {getWaybills} from "store/reducers/kurs/waybills";
import {getUsers} from "store/reducers/staffing/staffing";
import {getOdometrMultiple} from "store/reducers/maps";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {CycleFetch} from 'helpers/api';
import {getVehicleDistance} from "store/reducers/vehicles/vehicles";

@connect(state => ({}), {
    getTasks,
    getVehicles,
    getEntityNames,
    getDictionaryList,
    getUnits,
    getWaybills,
    getUsers,
    getOdometrMultiple,
    getVehicleList,
    getVehicleDistance
})

export default class UtilityVehiclesControl extends BaseTableWithEditorComponent {

    _cycleFetch = null;

    useCache = false;

    exportRowsCount = 1500;

    getTitle() {
        return <span>{systems.utility} → Контроль ТС</span>;
    }

    baseUrl = '/utility/vehicles_control';

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            tasks: {},
            work_graphics: [],
        });

        this.loadDictionaries([
            'work_graphics',
            'kurs_task_statuses',
        ]);
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Гос. номер ТС')
                .fromField('state_number'),

            new Column('Марка ТС')
                .fromField('vehicle_mark_uuid')
                .withDrawer(item => item.vehicle_mark_uuid ? this.state.related.get(item.vehicle_mark_uuid) : '<code>-</code>')
                .denyColumnFilter(),

            new Column('Модель ТС')
                .fromField('vehicle_model_uuid')
                .withDrawer(item => item.vehicle_model_uuid ? this.state.related.get(item.vehicle_model_uuid) : '<code>-</code>')
                .denyColumnFilter(),

            new Column('Тип ТС')
                .fromField('vehicle_type_uuid')
                .withDrawer(item => item.vehicle_type_uuid ? this.state.related.get(item.vehicle_type_uuid) : '<code>-</code>')
                .denyColumnFilter()
                .withFilter('withTypes', async () => {
                    return await this.getDictionary('vehicle_types', 'utility');
                }),

            new Column('№ задания')
                .fromField('task_number')
                .withDrawer(item => {
                    return _.get(_.find(this.state.tasks, (task) => {
                        return _.find(task.resources, {vehicle_uuid: item.uuid});
                    }), 'number');
                })
                .denyOrder(),

            new Column('Водитель')
                .withDrawer(item => {
                    const uuid = _.get(_.find(_.flatten(_.map(this.state.tasks, 'resources')), {vehicle_uuid: item.uuid}), 'driver_uuid');
                    return uuid && this.state.related.get(uuid);
                })
                .denyOrder()
                .denyColumnFilter(),

            new Column('Дислокация')
                .fromField('unit_uuid')
                .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid))
                .denyColumnFilter()
                .withFilter('withUnits', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 1000,
                        },
                        filters: {
                            withComponent: 'utility',
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            new Column('Балансодержатель')
                .fromField('general.balanceholder_uuid')
                .withDrawer(item => _.get(item, 'general.balanceholder_uuid') && this.state.related.get(_.get(item, 'general.balanceholder_uuid')))
                .denyColumnFilter()
                .withFilter('withBalanceholders', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 1000,
                        },
                        filters: {
                            withComponent: 'utility',
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            new Column('Исправность')
                .withDrawer(item => (_.get(item, 'general.malfunctions', []).length === 0) ? 'Исправен' : 'Неисправен')
                .denyColumnFilter()
                .denyOrder(),

            new Column('Статус')
                .fromField('status')
                .denyOrder()
                .withDrawer(item => {
                    const statusUuid = _.get(_.find(this.state.kurs_task_statuses, {name: 'Закрыт'}), 'uuid');
                    const task = _.find(_.flatten(_.map(_.filter(this.state.tasks, (task) => {
                        return task.status_uuid !== statusUuid;
                    }), 'resources')), {vehicle_uuid: item.uuid});
                    return task ? 'На задании' : 'Свободен';
                })
                .withFilter('withInTaskStatus', async () => {
                    return [
                        {
                            uuid: 'free',
                            name: 'Свободен',
                        },
                        {
                            uuid: 'busy',
                            name: 'На задании',
                        },
                    ];
                }),

            new Column('Закрытое задание')
                .fromField('task_closed')
                .denyOrder()
                .withDrawer(item => {
                    const statusUuid = _.get(_.find(this.state.kurs_task_statuses, {name: 'Закрыт'}), 'uuid');
                    const task = _.find(_.flatten(_.map(_.filter(this.state.tasks, {status_uuid: statusUuid}), 'resources')), {vehicle_uuid: item.uuid});
                    return task ? 'Да' : 'Нет';
                })
                .withFilter('withClosedTask', async () => {
                    return [
                        {
                            uuid: 'no',
                            name: 'Нет',
                        },
                        {
                            uuid: 'yes',
                            name: 'Да',
                        },
                    ];
                }),

            new Column('Время водителя в работе')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => {
                    const waybill = _.find(this.state.waybills, {vehicle_uuid: item.vehicle_uuid});
                    if (!waybill) {
                        return;
                    }

                    const minutes = moment().diff(moment(waybill.shift_start_time, formats.TIME), 'minutes');

                    return this.formatTime(minutes);
                }),

            new Column('Максимальное время работы')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => {
                    const waybill = _.find(this.state.waybills, {vehicle_uuid: item.vehicle_uuid});
                    if (!waybill) {
                        return;
                    }
                    return _.get(this.state.drivers, waybill.driver_uuid);
                }),

            new Column('Текущий пробег')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => {
                    return _.get(this.state.odometr, _.get(this.state.vehicles, item.vehicle_uuid));
                }),

            ...(window.RNIS_SETTINGS.show_vehicle_kurs_distance ? [ new Column('Пробег за текущий день')
                .fromField('distance')
                .withDrawer(item => item.distance ? item.distance : '<code>-</code>')
                .denyColumnFilter(),] : []),
        ]);
    }

    formatTime(minutes) {
        if (isNaN(minutes)) {
            return '00:00';
        }
        return _.padStart(Math.floor(minutes / 60), 2, '0') + ':' + _.padStart(minutes % 60, 2, '0');
    }

    async loadData(meta) {
        const taskNumberSearch = _.find(meta.column_search || [], {column: 'task_number'});
        let tasksResponse;
        let tasks;
        if (taskNumberSearch) {
            meta.column_search = _.filter(meta.column_search, (column) => column.column !== 'task_number');
            tasksResponse = await this.props.getTasks({
                column_search: [
                    {
                        column: 'number',
                        value: taskNumberSearch.value,
                    },
                ],
                pagination: {
                    page: 1,
                    limit: 10000,
                },
                filters: {
                    today: true,
                    onlyUuid: true,
                },
                response_data: [
                    'items/uuid',
                    'items/number',
                    'items/resources',
                    'items/status_uuid',
                ],
            });
            if (tasksResponse.isOk) {
                tasks = _.keyBy(tasksResponse.payload.items, 'uuid');

                this.setState({
                    tasks,
                });
                _.set(meta, 'filters.withUuid', _.uniq(_.filter(_.map(_.flatten(_.map(tasks, 'resources')), 'vehicle_uuid'))));
            }
        }

        meta.response_data = [
            'items/uuid',
            'items/deleted_at',
            'items/state_number',
            'items/vehicle_mark_uuid',
            'items/vehicle_model_uuid',
            'items/vehicle_type_uuid',
            'items/unit_uuid',
            'items/general/balanceholder_uuid',
            'items/general/malfunctions',
            'items/vehicle_uuid',
        ];

        const response = await this.props.getVehicles(meta);

        if (!taskNumberSearch && response.isOk) {
            tasksResponse = await this.props.getTasks({
                filters: {
                    today: true,
                    onlyUuid: true,
                    withBaseVehicles: _.map(response.payload.items, 'vehicle_uuid'),
                },
                response_data: [
                    'items/uuid',
                    'items/number',
                    'items/resources',
                    'items/status_uuid',
                ],
            });
            if (tasksResponse.isOk) {
                this.setState({
                    tasks: _.keyBy(tasksResponse.payload.items, 'uuid'),
                });
            }

            if (window.RNIS_SETTINGS.show_vehicle_kurs_distance) {
                let metaDist = {
                    date_from: moment().startOf( 'day').format(formats.DATETIME_API),
                    date_to: moment().format(formats.DATETIME_API),
                    vehicle_uuids: response.payload.items.map(v => v.vehicle_uuid )
                }
                let distance = await this.props.getVehicleDistance(metaDist);
                if (distance.isOk) {
                    response.payload.items.forEach((item, index) => {
                        let a = _.find(distance.payload.items, {uuid: item.vehicle_uuid});
                        if (a) {
                            item.distance = a.distance.toFixed(1);
                            return item;
                        }
                    })
                }

            }
        }

        return response;
    }

    async onDblClick(data) {
        const task = _.get(_.find(this.state.tasks, (task) => {
            return _.find(task.resources, {vehicle_uuid: data.uuid});
        }), 'uuid');
        if (task) {
            this.props.router.push(`/utility/tasks/${task}`);
        }
    }

    renderHeaderActions() {
        return null;
    }

    getCreateButton() {
        return null;
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        this.setState({
            waybills: null,
            drivers: null,
        });
        this.loadWaybills(_.map(result, 'vehicle_uuid'), drawCallback, json);
        this.loadVehicles(_.map(result, 'vehicle_uuid'), drawCallback, json);

        const types = _.map(_.uniq(_.filter(_.map(result, 'vehicle_type_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const marks = _.map(_.filter(_.map(result, 'vehicle_mark_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleMark\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const models = _.map(_.filter(_.map(result, 'vehicle_model_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleModel\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const drivers = _.map(_.map(_.flatten(_.map(this.state.tasks, 'resources')), 'driver_uuid'), (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));
        const units = _.map(_.uniq(_.filter(_.map(result, 'unit_uuid'))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));
        const balanceholders = _.map(_.uniq(_.filter(_.map(result, 'general.balanceholder_uuid'))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));
        const response = await this.props.getEntityNames(_.concat(types, marks, models, drivers, units, balanceholders));
        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    async loadVehicles(uuids, drawCallback, json) {
        const response = await this.props.getVehicleList({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            this.setState({
                vehicles: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'current_bnso.bnso_number'),
            });
            this.initOdometr(_.filter(_.map(response.payload.items, 'current_bnso.bnso_number')), drawCallback, json);
        }
    }

    initOdometr(bnsoNumbers, drawCallback, json) {
        if (this._cycleFetch) {
            this._cycleFetch.stop();
            delete this['_cycleFetch'];
        }

        this._cycleFetch = new CycleFetch(
            async () => {
                const response = await this.props.getOdometrMultiple(bnsoNumbers, moment().format(formats.DATETIME_API));
                if (response.isOk) {
                    await this.setState({
                        odometr: response.payload.items,
                    });

                    !this.isInExport && drawCallback(json);
                }
            }, () => {
            }, 600000);

        this._cycleFetch.run();
    }

    async loadWaybills(uuids, drawCallback, json) {
        const response = await this.props.getWaybills({
            filters: {
                withVehicle: uuids,
                withDate: moment().format(formats.DATE_API),
            },
        });

        if (response.isOk) {
            const waybills = response.payload.items;
            this.setState({
                waybills,
            });

            this.loadDrivers(_.map(waybills, 'driver_uuid'), drawCallback, json);
        } else {
            response.showErrors();
        }
    }

    getGraphic(workGraphics, date) {
        return _.find(workGraphics || [], (graphic) => {
            return moment(graphic.date_from).isSameOrBefore(date, 'day') && (!graphic.date_to || moment(graphic.date_to).isAfter(date));
        });
    }

    async loadDrivers(uuids, drawCallback, json) {
        const response = await this.props.getUsers({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            await this.setState({
                drivers: _.mapValues(_.keyBy(response.payload.items, 'uuid'), (user) => {
                    const graphic = this.getGraphic(_.get(user, 'work_graphics', []), moment());
                    if (!graphic) {
                        return;
                    }
                    const workGraphic = _.get(this.state.work_graphics, graphic.work_graphic_uuid);

                    if (!workGraphic) {
                        return;
                    }

                    return workGraphic.max_per_day_hours;
                }),
            });
            !this.isInExport && drawCallback(json);
        } else {
            response.showErrors();
        }
    }
}
