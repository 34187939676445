import React, {Component} from 'react';
import DefaultTooltipContent from "recharts/es6/component/DefaultTooltipContent";
import _ from 'lodash';
import {isNumOrStr} from "recharts/es6/util/DataUtils";
import * as moment from "moment";
import formats from "dictionaries/formats";

const defaultFormatter = value => (
    (_.isArray(value) && isNumOrStr(value[0]) && isNumOrStr(value[1])) ?
        value.join(' ~ ') :
        value
);

export default class MapRetrospectiveTelematicsTooltip extends DefaultTooltipContent {
    renderContent() {
        const {payload, separator, formatter, itemStyle, itemSorter, linesData} = this.props;

        if (payload && payload.length) {
            const listStyle = {padding: 0, margin: 0};

            const items = payload
                .slice(0, 1)
                .sort(itemSorter)
                .map((entry, i) => {
                    const finalItemStyle = {
                        display: 'block',
                        paddingTop: 4,
                        paddingBottom: 4,
                        color: entry.color || '#000',
                        ...itemStyle,
                    };
                    const finalFormatter = entry.formatter || formatter || defaultFormatter;

                    const {latitude, longitude, timestamp, received} = entry.payload;
                    return [
                        <li className="recharts-tooltip-item" key={`tooltip-item-${i}`} style={finalItemStyle}>
                            <span className="recharts-tooltip-item-name">Координаты</span>
                            <span className="recharts-tooltip-item-separator">{separator}</span>
                            <span className="recharts-tooltip-item-value">
                              {_.round(latitude, 6)}, {_.round(longitude, 6)}
                            </span>
                        </li>,
                        <li className="recharts-tooltip-item" key={`tooltip-item2-${i}`} style={finalItemStyle}>
                            <span className="recharts-tooltip-item-name">Время создания пакета</span>
                            <span className="recharts-tooltip-item-separator">{separator}</span>
                            <span className="recharts-tooltip-item-value">
                                {moment.unix(timestamp).format(formats.DATETIME)}
                            </span>
                        </li>,
                        <li className="recharts-tooltip-item" key={`tooltip-item3-${i}`} style={finalItemStyle}>
                            <span className="recharts-tooltip-item-name">Время получения пакета</span>
                            <span className="recharts-tooltip-item-separator">{separator}</span>
                            <span className="recharts-tooltip-item-value">
                              {received ? moment.unix(received).format(formats.DATETIME) : '-'}
                            </span>
                        </li>,
                    ];
                });

            return <ul className="recharts-tooltip-item-list" style={listStyle}>{items}</ul>;
        }

        return null;
    }
}