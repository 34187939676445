import React from 'react';
import Select from 'react-select-plus';


const RenderSelectField = ({children, placeholder, input, label, meta: {touched, error}}) => (
    <div>
        {label && <label>{label}</label>}
        <select onChange={input.onChange}>
            <option value="">{placeholder}</option>
            {children}
        </select>
        {touched && error && <span className='error' >{error}</span>}
    </div>
);

export default RenderSelectField;
