import {rpc} from './session';

export async function getHistory(devices, date_start, date_end, full = false) {
    return await rpc.request('com.rnis.t1sync.action.history', {
        devices,
        date_start,
        date_end,
        full,
        events: [],
    });
}

export async function getHistoryByVehicle(vehicle_uuid, date_start, date_end, full = false) {
    return await rpc.request('com.rnis.t1sync.action.history', {
        vehicle_uuid,
        date_start,
        date_end,
        full,
        events: [],
    });
}

export async function getHistoryMultiple(data) {
    return await rpc.request('com.rnis.t1sync.action.history.multiple', data);
}

export async function getDeviceExtendedData(bnsoNumber, port, sensor, full = false) {
    return await rpc.request('com.rnis.t1sync.action.extended_data', {
        bnso_number: bnsoNumber,
        inner_port: port,
        inner_sensor: sensor,
        full,
    });
}

export async function getOdometrMultiple(bnsoNumbers, timestamp) {
    return await rpc.request('com.rnis.t1sync.action.odometr.multiple', {
        bnso_numbers: bnsoNumbers,
        timestamp,
    });
}

export async function getEtpEvents(data) {
    return await rpc.request('com.rnis.t1sync.action.events', data);
}

export async function getMotohours(bnsoNumber, timestamp) {
    return await rpc.request('com.rnis.t1sync.action.motohours', {
        bnso_number: bnsoNumber,
        timestamp,
    });
}

export async function getMileage(bnsoNumber, timestamp) {
    return await rpc.request('com.rnis.t1sync.action.mileage', {
        bnso_number: bnsoNumber,
        timestamp,
    });
}