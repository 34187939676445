import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';


import _ from 'lodash';
import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import moment from "moment";
import formats from "dictionaries/formats";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import {deleteRouteRegistry, getRouteRegistries} from "store/reducers/kiutr/route_registries";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import systems from "dictionaries/systems";
import './style.less';
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import {component_mapper} from "helpers/component_mapper";

@connect(state => ({}), {getRouteRegistries, getEntityNames, deleteRouteRegistry, getDictionaryList, getUnits})

export default class KiutrRouteRegistriesComponent extends BaseTableWithEditorComponent {

    getTitle() {
        return `${systems[this.props.params.component]} → Реестр маршрутов`;
    }

    getBaseUrl() {
        return `/${this.props.params.component}/route_registries`;
    }

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            component: null,
        });
    }

    componentDidMount() {
        this.forceUpdate();
    }

    async componentWillUpdate(props, state) {
        super.componentWillUpdate(props, state);

        if (component_mapper(props.params.component) !== state.component) {
            await this.setState({
                component: component_mapper(props.params.component),
                columns: List(this.getColumns(component_mapper(props.params.component))),
            });
            state.component && this.reload();
        }
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        
        return this.prepareColumns(_.filter([

            ...(window.RNIS_SETTINGS.CITY_MO ? ([
                new Column('№ маршрута')
                    .fromField('route.number')
                    .withMultiFilter(this.getDataForMultiFilter({simple: 'withRouteNumber', async: 'withRouteNumbers'}, async (search) => {
                        const response = await this.loadListNumbers(
                            search,
                            'withRouteNumber',
                            'items/route/number',
                            this.props.getRouteRegistries);
                        if (response.isOk) {
                            return _.map(response.payload.items, item => ({
                                uuid: item.route.number,
                                name: item.route.number
                            }));
                        }
                        return [];
                    }))
                    .withDebounce(true),
            ]) : [
                new Column('№ маршрута')
                    .fromField('route.number')
            ]),
            
            ...(window.RNIS_SETTINGS.CITY_MO ? ([
                new Column('Рег № маршрута')
                    .fromField('route.registration_number')
                    .withMultiFilter(this.getDataForMultiFilter({simple: 'withRouteRegNumber', async: 'withRouteRegNumbers'}, async (search) => {
                        const response = await this.loadListNumbers(
                            search,
                            'withRouteRegNumber',
                            'items/route/registration_number',
                            this.props.getRouteRegistries);
                        if (response.isOk) {
                            return _.map(response.payload.items, item => ({
                                uuid: item.route.registration_number,
                                name: item.route.registration_number
                            }));
                        }
                        return [];
                    }))
                    .withDebounce(true),
            ]) : [
                new Column('Рег № маршрута')
                .fromField('route.registration_number')
            ]),

            new Column('Наименование')
                .fromField('route.title'),

            new Column('Перевозчик')
                .fromField('contract.carrier_uuid')
                .withDrawer(item => this.state.related.get(_.get(item, 'contract.carrier_uuid')))
                .denyColumnFilter()
                .withFilter('withCarriers', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 1000,
                        },
                        filters: {
                            withComponent: component_mapper(this.props.params.component),
                        },
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            new Column('Статус маршрута')
                .fromField('status_uuid')
                .withDrawer(item => this.state.related.get(item.status_uuid))
                .denyColumnFilter()
                .withFilter('withStatus', async () => {
                    const response = await this.props.getDictionaryList('route_statuses');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }),

            new Column('Дата начала перевозок')
                .fromField('contract.work_from')
                .withDrawer((item) => _.get(item, 'contract.work_from') && moment(_.get(item, 'contract.work_from')).format(formats.DATE))
                .withDateFilter(),

            new Column(`Номер ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}`)
                .fromField('contract.number'),

            (component_mapper(this.props.params.component) === 'kiutr') ? (
                new Column('Тип маршрута')
                    .fromField('route.route_type_uuid')
                    .withDrawer(item => this.state.related.get(_.get(item, 'route.route_type_uuid')))
                    .denyColumnFilter()
                    .withFilter('withRouteTypes', async () => {
                        const response = await this.props.getDictionaryList('route_types');
                        if (response.isOk) {
                            return response.payload.documents;
                        }
                        return [];
                    })
            ) : null,

            (component_mapper(this.props.params.component) === 'kiutr') ? (
                new Column('Вид сообщения')
                    .fromField('route.transport_connection_type_uuid')
                    .withDrawer(item => this.state.related.get(_.get(item, 'route.transport_connection_type_uuid')))
                    .denyColumnFilter()
                    .withFilter('withRouteTransportConnectionTypes', async () => {
                        const response = await this.props.getDictionaryList('transport_connection_types');
                        if (response.isOk) {
                            return response.payload.documents;
                        }
                        return [];
                    })
            ) : null,

            new Column('Вид маршрута')
                .fromField('route.route_kind_uuid')
                .withDrawer(item => this.state.related.get(_.get(item, 'route.route_kind_uuid')))
                .denyColumnFilter()
                .withFilter('withRouteKinds', async () => {
                    const response = await this.props.getDictionaryList('route_kinds');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }),

            new Column((component_mapper(this.props.params.component) === 'kiutr') ? 'Организатор перевозок' : 'Организация фрахтователь')
                .fromField('contract.unit_uuid')
                .withDrawer(item => this.state.related.get(_.get(item, 'contract.unit_uuid')))
                .denyColumnFilter()
                .withFilter('withUnits', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 1000,
                        },
                        filters: {
                            withComponent: component_mapper(this.props.params.component),
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

        ]));
    }

    async deleteItem(data) {
        return await this.props.deleteRouteRegistry(data);
    }

    async loadData(meta) {
        _.set(meta, 'filters.withComponent', this.state.component);
        _.set(meta, 'response_data', [
            'items/uuid',
            'items/deleted_at',
            'items/route/number',
            'items/route/registration_number',
            'items/route/title',
            'items/status_uuid',
            'items/work_from',
            'items/number',
            'items/route/route_type_uuid',
            'items/route/transport_connection_type_uuid',
            'items/route/route_kind_uuid',
            'items/contract/unit_uuid',
            'items/contract/work_from',
            'items/contract/number',
            'items/contract/carrier_uuid',
        ]);

        return await this.props.getRouteRegistries(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const carriers = _.map(_.filter(_.map(result, 'contract.carrier_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const statuses = _.map(_.filter(_.map(result, 'status_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RouteStatuses\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const routeTypes = _.map(_.filter(_.map(result, 'route.route_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RouteTypes\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const connectionTypes = _.map(_.filter(_.map(result, 'route.transport_connection_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\TransportConnectionType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const routeKinds = _.map(_.filter(_.map(result, 'route.route_kind_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RouteKinds\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const units = _.map(_.filter(_.map(result, 'contract.unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(_.concat(statuses, units, carriers, routeTypes, connectionTypes, routeKinds));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}