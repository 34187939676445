import React, {Component} from 'react';
import {Link} from 'react-router';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import PageModal from 'components/ui/page-modal';
import Page from "components/ui/page";
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Checkbox from "components/ui/form/checkbox";
import {api} from "helpers/api";
import {createContract, getContract, updateContract} from "store/reducers/kurs/contracts";
import {
    createContractWork,
} from "store/reducers/kurs/contract_works";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import FileReaderInput from 'react-file-reader-input';
import GlobalLoaderComponent from "components/ui/global-loader";
import TabItem from "components/ui/tabs/tab-item";
import Tabs from "components/ui/tabs/tabs";
import systems from "dictionaries/systems";
import TabBlock from "components/ui/tabs/tab-block";
import './style.less';
import BlockGroup from "components/ui/form/block-group";
import Button from "components/ui/button";
import TableContainer from "components/ui/Table/Container/TableContainer";
import KursContractWorksComponent from "components/modules/kurs/contract_works/list";
import {updateContractWork} from "store/reducers/kurs/contract_works";
import UnitsEditor from "components/modules/organizational-units/units/editor";
import moment from "moment";
import formats from "dictionaries/formats";
import months from "dictionaries/months";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import Modal from "components/ui/modal";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import {getStos} from "store/reducers/kurs/stos";
import * as alerts from "helpers/alerts";
import IconButton from "components/ui/icon-button";
import ReactDOM from 'react-dom'
import {printForm} from "helpers/print";
import currentUser from 'helpers/current-user';

@connect(state => ({}), {getContract, createContract, updateContract, createContractWork, updateContractWork, getUnit })

export default class KurdContractEditor extends BaseEditor {

    title = 'контракта';
    modelClass = 'App\\Model\\Contract';


    state = {
        selectedWorks: [],
        selectedContracts: [],
    }

    async componentWillMount() {
        this.updateData()
    }

    async componentWillUpdate(props, state) {
    }

    async componentDidUpdate(prevProps) {
        if (this.props.params.delegation_uuid !== prevProps.params.delegation_uuid) {
            this.updateData();

            if (this.props.params.delegation_uuid) {
                const requiredFields = [];
                requiredFields['contract.date_to'] = ' ';
                requiredFields['contract.date_from'] = ' ';
                requiredFields['contract.signed_at'] = ' ';
                requiredFields['contract.implementer_uuid'] = ' ';
                requiredFields['contract.name'] = ' ';

                this.setState({
                    errors: requiredFields,
                })
            } else {
                this.setState({
                    errors: [],
                })
            }
        }
    }

    async updateData() {
        const propsUuid = (this.props.params.uuid === 'create') ? null : this.props.params.uuid;
        if (this.state.uuid !== propsUuid) {
            this.setState({
                uuid: propsUuid,
                item: null,
                isLoading: !!propsUuid || this.props.params.delegation_uuid,
                mode: propsUuid ? 'edit' : 'add',
                maxDateTo: '',
            });

            if (propsUuid) {
                const response = await this.loadData(propsUuid);

                let motherContractName = '';
                if (response.payload && response.payload.mother_contract_uuid) {
                    const responseMotherContract = await this.loadData(response.payload.mother_contract_uuid);
                    if (response.isOk) {
                        motherContractName = responseMotherContract.payload.name;
                    } else {
                        responseMotherContract.showErrors();
                    }
                }

                if (response.isOk) {
                    this.setState({
                        item: {
                            mother_contract_name: motherContractName,
                            ...response.payload,
                        },
                        isLoading: false,
                    });
                } else {
                    response.showErrors();
                }
            } else if (this.props.params.delegation_uuid) {
                const response = await this.loadData(this.props.params.delegation_uuid)


                const responseUnit = await this.props.getUnit(response.payload.implementer_uuid)

                if (response.isOk) {
                    const item = Object.assign({}, response.payload, {
                        signed_at: '',
                        date_from: '',
                        date_to: '',
                        name: '',
                        implementer_uuid: '',
                        client: responseUnit.payload.name,
                        mother_contract_uuid: response.payload.uuid,
                        mother_contract_name: response.payload.name,
                    })

                    this.setState({
                        item: item,
                        isLoading: false,
                        maxDateTo: response.payload.date_to,
                    });
                } else {
                    response.showErrors();
                }
            }
        }
    }

    async loadData(uuid) {
        return await this.props.getContract(uuid);
    }

    async createItem(data) {
        return await this.props.createContract(data);
    }

    async updateItem(data) {
        return await this.props.updateContract(data);
    }

    selectWork(uuid) {
        const newSelected = [].concat(this.state.selectedWorks);

        const findedIndex = newSelected.indexOf(uuid);

        if (findedIndex < 0) {
            newSelected.push(uuid)
        } else {
            newSelected.splice(findedIndex, 1);
        }

        this.setState({
            selectedWorks: newSelected,
            formUpdated: true,
        });
    }

    selectContract(uuids) {
        this.setState({
            selectedContracts: uuids,
            formUpdated: true,
        });
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.state.mode}
                maxDateTo={this.state.maxDateTo}
                onClose={::this.onClose}
                onDataLoad={::this.forceUpdate}
                data={item}
                selectWork={::this.selectWork}
                selectContract={::this.selectContract}
                selectedContracts={this.state.selectedContracts}
                selectedWorks={this.state.selectedWorks}
                onUpdate={::this.onFormUpdate}
                errors={this.state.errors}
            />
        );
    }

    onFormUpdate() {
        this.setState({
            formUpdated: true,
        });
    }

    onClose() {
        if (this.state.formUpdated) {
            alerts.prompt('Вы действительно хотите закрыть эту страницу без сохранения данных?', '', () => {
                this.props.router.push('/road/contracts');
            }, 'Закрыть');
        } else {
            this.props.router.push('/road/contracts');
        }
    }

    async create(data) {
        if (this.props.params.delegation_uuid) {
            if (!this.state.selectedWorks.length) {
                alerts.error('Выберите вид работ')
                return
            }
            if (!this.state.selectedContracts.length) {
                alerts.error('Выберите объект по содержанию')
                return
            }

            for (let selectedContract in this.state.selectContracts) {
                if (!selectedContract.start_at || !selectedContract.end_at) {
                    if (!selectedContract.start_at && !selectedContract.end_at) {
                        alerts.error('Объекты по содержанию: Начальный км обязательно для заполнения. Конечный км обязательно для заполнения.');
                    }
                    else if (!selectedContract.start_at) {
                        alerts.error('Объекты по содержанию: Начальный км обязательно для заполнения.');
                    } else if (!selectedContract.end_at) {
                        alerts.error('Объекты по содержанию: Конечный км обязательно для заполнения.');
                    }
                    return;
                }
            }
        }

        this.clearErrors();
        this.startSave();

        this.setState({
            formUpdated: false,
        });

        const item = this.composeItem(data)

        if (this.props.params.delegation_uuid) {
            item.works = []
        }

        const response = await this.createItem(item);

        this.endSave();
        if (response.isOk) {
            if (this.props.params.delegation_uuid) {
                try {
                    const selectedWorks = data.works.filter((work) => this.state.selectedWorks.includes(work.uuid))
                    const works = selectedWorks.map((work) => ({
                        ...work,
                        uuid: '',
                        contract_uuid: response.payload.uuid,
                    }));

                    const contracts = this.state.selectedContracts.map((contract) => ({
                        ...contract,
                        contract_uuid: response.payload.uuid,
                    }))

                    const resUpdate = await this.updateItem({
                        ...response.payload,
                        works,
                    }); // NOTE: works при создании не сохраняются

                    if (!resUpdate.isOk) {
                        alerts.error(resUpdate.errors.map((error) => Object.values(error.data).join(' ')).join(' '));
                    } else {
                        const resContracts = await Promise.all(contracts.map((contract) => this.props.createContractWork(contract)))

                        let withError = false
                        resContracts.forEach((resContract) => {
                            if (!resContract.isOk) {
                                withError = true;
                                alerts.error(resContract.errors.map((error) => Object.values(error.data).join(' ')).join(' '));
                            }
                        })

                        if (!withError) {
                            alerts.success('Субконтракт создан', () => {
                                this.props.router.push('/road/contracts');
                                this.props.router.push(`/road/contracts/${response.payload.uuid}`);
                            });
                        }
                    }
                } catch (error) {
                    error.showErrors();
                }
            } else {
                await this.props.router.push('/road/contracts');
                this.props.router.push(`/road/contracts/${response.payload.uuid}`);
                //this.onClose();
            }
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();

        this.setState({
            formUpdated: false,
        });
        const response = await this.updateItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            this.setState({
                item: response.payload,
            });
            //this.onClose();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async saveGraph() {
        this.startSave();

        const data = this.refs.graph.getWrappedInstance().getData();

        await Promise.all(_.map(data, async (contractWork) => {
            return this.props.updateContractWork(contractWork);
        }));
    }

    async onEdit(e) {
        e && e.preventDefault();
        if (!this.refs.form) return;

        if (this.refs.graph) {
            await this.saveGraph();
        }

        const state = this.refs.form.getWrappedInstance().getData();
        this.edit(state);
    }

    render() {
        let title = `Создание ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}`;

        let form;
        let onSubmit;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.params.delegation_uuid) {
            title = 'Черновик';

            if (this.state.item) {
                form = this.getForm(this.state.item, ::this.create);

                onSubmit = ::this.onCreate;
            }
        } else if (this.state.mode === 'edit') {
            title = '';

            if (this.state.item) {
                title = `${window.RNIS_SETTINGS.rename_contracts ? 'Подрядное обязательство' : 'Контракт'} №${this.state.item.number || 'не указан'}`;
                form = this.getForm(this.state.item, ::this.edit);
            }

            onSubmit = ::this.onEdit;
        } else {
            form = this.getForm({}, ::this.create);

            onSubmit = ::this.onCreate;
        }

        return (
            <Page
                pageId="ContractManagement"
                title={`${systems.road} → ${title}`}
                headerActions={this.renderHeaderActions()}
            >
                {loader}
                {form}
                {this.state.formUpdated ? (
                    <div className="page-footer">
                        <div className="page-footer__txt">Вы хотите сохранить все изменения?</div>
                        <Button size="md" color="white" shadow="gray" className="b-button_cancel" text="Отменить"
                                onClick={::this.onReset}/>
                        <Button size="md" color="red" className="b-button_save" text="Сохранить"
                                onClick={onSubmit}/>
                    </div>
                ) : null}
            </Page>
        );
    }

    renderHeaderActions() {
        return [
            this.state.mode === 'edit' && window.RNIS_SETTINGS.show_delegate_button ? (
                <Link to={`/road/contracts/create/${this.props.params.uuid}`}>
                    <Button size="md" text="Делегировать" />
                </Link>
            ) : null,
            ((this.state.mode === 'edit') && currentUser.can('com.rnis.system.permission.audit', 'read') && this.modelClass) ?
                (
                    <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                    default="Журнал аудита">
                        <IconButton icon="history" onClick={::this.gotoAudit}/>
                    </ContextTooltip>
                ) : null,
            <ContextTooltip key="base-table-list.print" code="base-table-list.print" default="Печать">
                <IconButton icon="print" onClick={::this.print}/>
            </ContextTooltip>,
            <ContextTooltip key="kurs.contract.back" code="kurs.contract.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ];
    }

    gotoAudit() {
        const url = `/system/audit/${this.state.uuid}?class=${this.modelClass}`;

        this.props.router.push(url);
    }

    onReset() {
        this.refs.form.getWrappedInstance().onReset();
        this.setState({
            formUpdated: false,
        });
    }

    print() {
        if (!this.refs.form) return;

        printForm(ReactDOM.findDOMNode(this.refs.form.getWrappedInstance()));
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDataLoad: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getDictionaryList, getUnits, getUnit, getStos}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        contract: {},
        work_categories: [],
        units: [],
        unitsFull: [],
        kurs_contract_statuses: [],
        implementer: {},
        implementerActive: null,
        work_types: [],
        detailsUuid: null,
        stos: [],
        communal_municipalities: []
    };

    getData() {
        return this.state.contract;
    }

    onReset() {
        let contract = _.cloneDeep(this.props.data);
        contract.works = _.map(contract.works || [], (work) => {
            work.key = work.uuid;
            return work;
        });
        this.setState({
            contract,
        });
    }

    async componentDidMount() {
        let contract = _.cloneDeep(this.props.data);
        const currentYear = new Date().getFullYear();
        contract.summer_from = moment(contract.summer_from).set('year', currentYear);
        contract.summer_to = moment(contract.summer_to).set('year', currentYear);
        contract.winter_from = moment(contract.winter_from).set('year', currentYear);
        contract.winter_to = moment(contract.winter_to).set('year', currentYear);
        contract.works = _.map(contract.works || [], (work) => {
            work.key = work.uuid;
            return work;
        });
        await this.setState({
            contract,
        });

        this.loadUnits(contract.client);
        this.loadStos();
        this.loadImplementer();

        const dictionaries = [
            'work_categories',
            'kurs_contract_statuses',
            'work_types',
            'maintenance_groups'
        ];

        if (window.RNIS_SETTINGS.graphic_with_periodicity) {
            dictionaries.push('periods');
        }

        if (window.RNIS_SETTINGS.CITY_MURMANSK) {
            dictionaries.push('communal_municipalities');
        }

        this.loadDictionaries(dictionaries);
    }

    componentDidUpdate(prevProps) {
        if (this.props.mode !== 'add' && this.props.data !== prevProps.data) {
            let contract = _.cloneDeep(this.props.data);
            contract.works = _.map(contract.works || [], (work) => {
                work.key = work.uuid;
                return work;
            });

            this.setState({
                contract,
            });
        }
    }

    async loadStos() {
        /*if (!this.get('implementer_uuid')) {
            return;
        }*/
        const response = await this.props.getStos({
            filters: {
                onlyActive: true,
                //withUnit: this.get('implementer_uuid'),
            },
        });

        if (response.isOk) {
            this.setState({
                stos: _.map(response.payload.items, item => ({
                    value: item.uuid,
                    label: item.number,
                    works: item.works,
                    status_uuid: item.status_uuid,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async loadImplementer() {
        const uuid = this.get('implementer_uuid');
        if (!uuid) {
            this.setState({
                implementer: {},
            });
            return;
        }

        const response = await this.props.getUnit(uuid);

        if (response.isOk) {
            this.setState({
                implementer: response.payload,
            });
        }
    }

    async loadUnits(currentClient) {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withComponent: 'road',
            },
        });
        if (response.isOk) {
            const clients = [];
            response.payload.items.forEach((unit) => {
                if (!clients.find((client) => client.value === unit.name)) {
                    clients.push({
                        value: unit.name,
                        label: unit.name,
                    })
                }
            })

            if (currentClient && !clients.find((client) => client.value === currentClient)) {
                clients.push({
                    value: currentClient,
                    label: currentClient,
                })
            }

            this.setState({
                unitsFull: _.keyBy(response.payload.items, 'uuid'),
                units: _.sortBy(_.map(response.payload.items, (item) => ({
                    label: item.name,
                    value: item.uuid,
                })), 'label'),
                clients,
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.contract, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Tabs>
                    <TabItem key="main" title={`Страница ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}`}>
                        <TabBlock withBorder={true}>
                            <div>
                                <Block title={`Наименование ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'} *`}>
                                    {this.textInput('contract.name')}
                                </Block>
                                <Block title="Заказчик *">
                                    {this.select('contract.client', this.state.clients)}
                                </Block>
                                <Block title="Статус *">
                                    {this.select('contract.status_uuid', this.state.kurs_contract_statuses)}
                                </Block>
                                {window.RNIS_SETTINGS.mother_contract && this.get('mother_contract_uuid') ? (
                                    <Block size="md" title={window.RNIS_SETTINGS.CITY_MURMANSK ? "Материнское подрядное обязательство" : "Материнский контракт"}>
                                        <Link
                                            to={`/road/contracts/${this.get('mother_contract_uuid')}`}
                                            target="_blank"
                                        >
                                            {this.get('mother_contract_name')}
                                        </Link>
                                    </Block>
                                ) : null}
                                {! window.RNIS_SETTINGS.CITY_MURMANSK ? (
                                    <Block size="md" title="СКПДИ">
                                        {this.textInput('contract.external_id', {
                                            disabled: true,
                                        })}
                                    </Block>
                                ): null}
                            </div>
                            <div>
                                {!window.RNIS_SETTINGS.mother_contract ? (
                                    <Block size="md" title="root_id СКПДИ">
                                        {this.textInput('contract.root_id', {
                                            disabled: true,
                                        })}
                                    </Block>
                                ) : null}
                                {!window.RNIS_SETTINGS.mother_contract ? (
                                    <Block size="md" title="Статус СКПДИ">
                                        {this.textInput('contract.skpdi_status', {
                                            disabled: true,
                                        })}
                                    </Block>
                                ) : null}
                                <Block title="Категория работ *">
                                    {this.select('contract.work_category_uuid', this.state.work_categories, {
                                        disabled: this.props.mode === 'edit',
                                    })}
                                </Block>
                                {(!this.get('is_economic_method')) ? ([
                                    <Block key="number" title="Рег. номер документа">
                                        {this.textInput('contract.number')}
                                    </Block>,
                                    <Block key="signed_at" title="Дата заключения">
                                        {this.datepicker('contract.signed_at')}
                                    </Block>,
                                ]) : null}
                                {window.RNIS_SETTINGS.CITY_MURMANSK ? (
                                    <Block key="communal_municipality" title="Муниципальное образование">
                                        {this.select('contract.communal_municipality_uuid', this.state.communal_municipalities)}
                                    </Block>
                                ) : null}
                            </div>
                            {!window.RNIS_SETTINGS.CITY_MURMANSK ? (
                                <div>
                                    <Block size="xl">
                                        {this.checkbox('contract.is_economic_method', 'Выполняется хозяйственным способом')}
                                    </Block>
                                </div>
                            ) : null}
                        </TabBlock>
                        <TabBlock withBorder={true}>
                            <div>
                                <Block title="Действует с *">
                                    {this.datepicker('contract.date_from')}
                                </Block>
                                <Block title="Действует по *">
                                    {this.datepicker('contract.date_to',
                                        {
                                            disabledDays: (date) => {
                                                if (!this.props.maxDateTo) return false;
                                                return moment(date).set('h', 0).isAfter(moment(this.props.maxDateTo).set('h', 0))
                                            }
                                        }
                                    )}
                                </Block>
                                <Block title={`Скан ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}`}>
                                    <div className="input">
                                        <div className="input__style"/>
                                        {this.get('file') ? (
                                            <span className="input__edit-file">
                                        <a className="open-file" href={this.get('file')} target="_blank">Открыть</a>
                                        <a className="delete-file" href="javascript:void(0)"
                                           onClick={::this.deleteFile}>Удалить</a>
                                    </span>
                                        ) : (
                                            <FileReaderInput as="binary" id="my-file-input"
                                                             onChange={::this.uploadFile}>
                                                <span className="input__load-file">Добавить скан {window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}</span>
                                            </FileReaderInput>
                                        )}
                                    </div>
                                </Block>
                            </div>
                            {this.isMaintenance() ? ([
                                <BlockGroup key="summer" title="Сезон Лето">
                                    <Block title="Дата с">
                                        {this.datepicker('contract.summer_from', {disableYears: true})}
                                    </Block>
                                    <Block title="Дата по">
                                        {this.datepicker('contract.summer_to', {disableYears: true})}
                                    </Block>
                                </BlockGroup>,
                                <BlockGroup key="winter" title="Сезон Зима">
                                    <Block title="Дата с">
                                        {this.datepicker('contract.winter_from', {disableYears: true})}
                                    </Block>
                                    <Block title="Дата по">
                                        {this.datepicker('contract.winter_to', {disableYears: true})}
                                    </Block>
                                </BlockGroup>,
                            ]) : null}
                        </TabBlock>
                        <TabBlock withBorder={true}>
                            <div>
                                <Block size="lg" title="Исполнитель *">
                                    {this.select('contract.implementer_uuid', this.state.units)}
                                </Block>
                                {this.get('implementer_uuid') ? (
                                    <Block>
                                        <Button size="lg" color="white" shadow="gray" width="full"
                                                text="Данные о контрагенте"
                                                onClick={::this.gotoImplementer}/>
                                    </Block>
                                ) : null}
                            </div>
                            {this.get('implementer_uuid') ? (
                                <div>
                                    <Block title="Руководитель">
                                        {this.state.implementer.senior}
                                    </Block>
                                    <Block title="Телефон">
                                        {this.state.implementer.phone}
                                    </Block>
                                    <Block title="Email">
                                        {this.state.implementer.email}
                                    </Block>
                                </div>
                            ) : null}
                        </TabBlock>
                        {!window.RNIS_SETTINGS.hide_subcontractors ? (
                            <TabBlock>
                                <Block size="xl">
                                    <TableContainer>
                                        <div className="Table-title">Список субподрядных организаций</div>
                                        <div className="Table">
                                            <table className="b-table b-table-no-hover">
                                                <thead>
                                                <tr className="b-table__header">
                                                    <th>Подрядчик</th>
                                                    <th>Телефон</th>
                                                    <th>Руководитель</th>
                                                    <th/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.renderSubcontractors()}
                                                </tbody>
                                            </table>
                                        </div>
                                        <span className="add-line"
                                            onClick={::this.addSubcontractor}>Добавить организацию</span>
                                    </TableContainer>
                                </Block>
                            </TabBlock>
                        ) : null}
                        {this.state.implementerActive ? (
                            <UnitsEditor
                                mode="edit"
                                uuid={this.state.implementerActive}
                                onSubmit={::this.hideImplementer}
                                onClose={::this.hideImplementer}
                                params={{
                                    component: 'road',
                                }}
                            />
                        ) : null}
                    </TabItem>
                    {((this.props.mode === 'edit') && this.isRepair()) ? (
                        <TabItem key="works" title={`Работы по ${window.RNIS_SETTINGS.rename_contracts ? 'подрядному обязательству' : 'контракту'}`}>
                            <KursContractWorksComponent
                                contractUuid={this.state.contract.uuid}
                                type="works"
                            />
                        </TabItem>
                    ) : null}
                    {((this.props.mode === 'edit' || this.props.params.delegation_uuid) && this.isMaintenance()) ? (
                        <TabItem key="graphic" title={`График работ по ${window.RNIS_SETTINGS.rename_contracts ? 'подрядному обязательству' : 'контракту'}`}>
                            {this.renderGraphic()}
                        </TabItem>
                    ) : null}
                    {((this.props.mode === 'edit' || this.props.params.delegation_uuid) && this.isMaintenance()) ? (
                        <TabItem key="maintenance" title="Объекты по содержанию">
                            <KursContractWorksComponent
                                contractUuid={this.state.contract.uuid}
                                type="maintenance"
                                delegation_uuid={this.props.params.delegation_uuid}
                                selectContract={this.props.selectContract}
                                selectedContracts={this.props.selectedContracts}
                            />
                        </TabItem>
                    ) : null}
                </Tabs>
            </div>
        );
    }

    selectSto(value) {
        const sto = _.find(this.state.stos, {value});
        const {works} = sto;

        let contractWorks = this.get('works', []);
        _.each(works, (work) => {
            let graphicByMaintenance = {};
            _.each(work.data, (item) => {
                graphicByMaintenance[item.maintenance_group_uuid] = {
                    contract_series_count: item.count,
                };
            });
            contractWorks.push({
                key: 'work-' + moment().format(formats.DATETIME) + '-' + work.uuid,
                work_type_uuid: work.work_type_uuid,
                season: _.find(this.state.work_types, {value: work.work_type_uuid}).document.season,
                contract_series_count: _.sumBy(work.data, 'count'),
                graphic_by_maintenance: graphicByMaintenance,
            });
        });
        this.setValue('contract.works', contractWorks);
    }

    getStos() {
        const statusUuid = _.get(_.find(this.state.kurs_contract_statuses || [], {label: 'Открыт'}), 'value');
        return _.filter(this.state.stos, {
            status_uuid: statusUuid,
        });
    }


    renderGraphicWithoutPeriodicity() {
        const years = _.groupBy(this.getMonths(), 'year');

        return (
            <TabBlock>
                <div className="b-block _full">
                    <Block size="xl" title="Реестр СТО">
                        {this.select('sto', this.getStos())}
                    </Block>
                    <div className="Table KursContractWorksTable">
                        <TableContainer>
                            <table className="b-table b-table-no-hover">
                                <thead>
                                <tr className="b-table__header">
                                    <th rowSpan="2">Вид работ</th>
                                    <th rowSpan="2">Сезон</th>
                                    {_.map(years, (months, year) => <th key={year} colSpan={months.length}
                                                                        className="align-center">{year} год</th>)}
                                    <th rowSpan="2">Кол-во циклов<br/>по {window.RNIS_SETTINGS.rename_contracts ? 'подрядному обязательству' : 'контракту'}</th>
                                </tr>
                                <tr>
                                    {_.flatten(_.values(years)).map(month => <th key={month.date}>{month.label}</th>)}
                                </tr>
                                </thead>
                                <tbody>
                                {(this.state.contract.works || []).map(::this.renderWork)}
                                </tbody>
                            </table>
                            <span className="add-line" onClick={::this.addWork}>Добавить запись</span>
                        </TableContainer>
                        {this.state.detailsUuid ? (
                            this.renderDetails()
                        ) : null}
                    </div>
                </div>
            </TabBlock>
        );
    }

    renderGraphicWithPeriodicity() {
        return (
            <TabBlock>
                <div className="b-block _full">
                    <div className="Table KursContractWorksTable overflow-initial">
                        <TableContainer>
                            <table className="b-table b-table-no-hover">
                                <thead>
                                    <tr className="b-table__header">
                                        {this.props.params.delegation_uuid ? (
                                            <th></th>
                                        ) : null}
                                        <th>Вид работ</th>
                                        <th>Сезон</th>
                                        <th>Периодичность</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(this.state.contract.works || []).map(::this.renderWorkWithPeriodicity)}
                                </tbody>
                            </table>
                            {!this.props.params.delegation_uuid ? (<span className="add-line" onClick={::this.addWork}>Добавить запись</span>) : null}
                        </TableContainer>
                        {this.state.detailsUuid ? (
                            this.renderDetails()
                        ) : null}
                    </div>
                </div>
            </TabBlock>
        )
    }

    renderGraphic() {
        if (window.RNIS_SETTINGS.graphic_with_periodicity) {
            return ::this.renderGraphicWithPeriodicity();
        }
        return ::this.renderGraphicWithoutPeriodicity();
    }

    renderDetails() {
        const years = _.groupBy(this.getMonths(), 'year');

        const workUuid = this.state.detailsUuid;
        const workIndex = _.findIndex(this.get('works', []), {uuid: workUuid});
        const work = this.get(`works.${workIndex}`);
        if (!work) {
            return null;
        }

        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.hideDetails}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{title: 'Распределение циклов работ по содержанию', buttons}}
                onClose={::this.hideDetails}
                className={`profile-modal`}
            >
                <div className="Table KursContractWorksTable">
                    <TableContainer>
                        <table className="b-table b-table-no-hover">
                            <thead>
                            <tr className="b-table__header">
                                <th rowSpan="2">Группы по содержанию</th>
                                {_.map(years, (months, year) => <th key={year} colSpan={months.length}
                                                                    className="align-center">{year} год</th>)}
                                <th rowSpan="2">Кол-во циклов<br/>по {window.RNIS_SETTINGS.rename_contracts ? 'подрядному обязательству' : 'контракту'}</th>
                            </tr>
                            <tr>
                                {_.flatten(_.values(years)).map(month => <th key={month.date}>{month.label}</th>)}
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.maintenance_groups.map(this.renderMaintenanceGroup.bind(this, workIndex))}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </PageModal>
        );
    }

    renderMaintenanceGroup(workIndex, maintenanceGroup) {
        const months = this.getMonths();

        return (
            <tr key={maintenanceGroup.value}>
                <td>
                    {maintenanceGroup.label}
                </td>
                {months.map((month, index2) => {
                    return (
                        <td key={index2}>
                            {this.textInput(`contract.works.${workIndex}.graphic_by_maintenance.data.${maintenanceGroup.value}.${month.date}`)}
                        </td>
                    );
                })}
                <td>{this.textInput(`contract.works.${workIndex}.graphic_by_maintenance.${maintenanceGroup.value}.contract_series_count`)}</td>
            </tr>
        );
    }

    renderWork(work, index) {
        const months = this.getMonths();

        return (
            <tr key={work.key}>
                <td className="input-cell wide">
                    {this.select(`contract.works.${index}.work_type_uuid`, this.state.work_types, {
                        autosize: false,
                    })}
                    {work.uuid ? (
                        <a href="javascript:void(0)" onClick={this.showDetails.bind(this, work.uuid)}>Распределение
                            циклов работ по содержанию</a>
                    ) : null}
                </td>
                <td className="input-cell">
                    {this.select(`contract.works.${index}.season`, [
                        {
                            label: 'Лето',
                            value: 'summer',
                        },
                        {
                            label: 'Зима',
                            value: 'winter',
                        },
                    ])}
                </td>
                {months.map((month, index2) => {
                    return (
                        <td className="input-cell" key={index2}>
                            {this.textInput(`contract.works.${index}.graphic.${month.date}`)}
                        </td>
                    );
                })}
                <td className="input-cell">{this.textInput(`contract.works.${index}.contract_series_count`)}</td>
            </tr>
        );
    }

    renderWorkWithPeriodicity(work, index) {
        return (
            <tr key={work.key}>
                {this.props.params.delegation_uuid ? (
                    <td className="input-cell checkbox-cell">
                        <div className="checkbox-cell">
                            <Checkbox
                                checked={this.props.selectedWorks.includes(work.uuid)}
                                onChange={() => this.props.selectWork(work.uuid)}
                            />
                        </div>
                    </td>
                ) : null}
                <td className="input-cell">
                    {this.select(`contract.works.${index}.work_type_uuid`, this.state.work_types, {
                        autosize: false,
                    })}
                    {work.uuid && window.RNIS_SETTINGS.show_cycle_spread_button && (
                        <a href="javascript:void(0)" onClick={this.showDetails.bind(this, work.uuid)}>Распределение
                            циклов работ по содержанию</a>
                    )}
                </td>
                <td className="input-cell">
                    {this.select(`contract.works.${index}.season`, [
                        {
                            label: 'Лето',
                            value: 'summer',
                        },
                        {
                            label: 'Зима',
                            value: 'winter',
                        },
                    ])}
                </td>
                <td className="input-cell ">
                    <div className="composite">
                        {this.textInput(`contract.works.${index}.period_value`, { className: "composite-small" })}
                        <span className="composite-text">раз в</span>
                        <div className="composite-big">

                            {this.select(`contract.works.${index}.period_uuid`, this.state.periods)}
                        </div>
                    </div>
                </td>
            </tr>
        )
    }

    showDetails(workUuid) {
        this.setState({
            detailsUuid: workUuid,
        });
    }

    hideDetails() {
        this.setState({
            detailsUuid: null,
        });
    }

    getMonths() {
        let result = [];
        let start = moment(this.state.contract.date_from).startOf('month');
        let end = moment(this.state.contract.date_to).endOf('month');
        while (start.isBefore(end)) {
            result.push({
                label: months[start.month()],
                year: start.year(),
                date: start.format(formats.DATE_URL),
            });

            start = start.add(1, 'month');
        }

        return result;
    }

    gotoImplementer() {
        this.setState({
            implementerActive: this.get('implementer_uuid'),
        });
    }

    hideImplementer() {
        this.setState({
            implementerActive: null,
        });
    }

    deleteFile(e) {
        e.preventDefault();

        this.setValue('contract.file', null);
    }

    async uploadFile(e, results) {
        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        results.forEach(result => {
            const [e, file] = result;
            //formData.append('file', e.target.result);
            formData.append('file', file);
        });

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');

        this.onChangeInput('contract.file', {target: {value}});
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }

    renderSubcontractors() {
        const list = this.state.contract.subcontractors || [];

        return list.map(::this.renderSubcontractorItem);
    }

    addSubcontractor(e) {
        e.preventDefault();

        let contract = this.state.contract;
        contract.subcontractors = contract.subcontractors || [];
        contract.subcontractors.push(null);

        this.setState({contract});
    }

    deleteSubcontractor(index, e) {
        e.preventDefault();

        let contract = this.state.contract;
        contract.subcontractors.splice(index, 1);
        this.setState({contract});
    }

    renderSubcontractorItem(subcontractor, index) {
        return (
            <tr key={index}>
                <td className="input-cell expand-top">
                    {this.select(`contract.subcontractors.${index}`, this.state.units)}
                </td>
                <td>
                    {_.get(this.state.unitsFull[this.get(`subcontractors.${index}`)], 'phone')}
                </td>
                <td>
                    {_.get(this.state.unitsFull[this.get(`subcontractors.${index}`)], 'senior')}
                </td>
                <td>
                    <a href="javascript:void(0)" onClick={this.deleteSubcontractor.bind(this, index)}>Удалить</a>
                </td>
            </tr>
        );
    }

    async setValue(field, value) {
        if (field === 'sto') {
            this.selectSto(value);
            return;
        }
        const prevState = _.cloneDeep(this.state.contract);

        this.props.onUpdate();
        await super.setValue(field, value);

        const matches2 = /^contract\.works\.([0-9]+)\.(graphic|contract_series_count)/.exec(field);
        if (matches2) {
            const index = matches2[1];
            const works = this.get(`works.${index}`);
            if (_.sum(_.map(_.values(works.graphic), item => _.toInteger(item))) > works.contract_series_count) {
                this.setState({
                    contract: prevState,
                });
                return;
            }
        }

        if (field === 'contract.implementer_uuid') {
            this.loadImplementer();
        }
        if (field === 'contract.is_economic_method') {
            this.setValue('contract.number', null);
            this.setValue('contract.signed_at', null);
        }
        const matches = /^contract\.works\.([0-9]+)\.work_type_uuid$/.exec(field);
        if (matches) {
            if (value === null) {
                const index = matches[1];
                let contract = this.state.contract;
                contract.works.splice(index, 1);
                this.setState({contract});
            } else {
                const workType = _.find(this.state.work_types, {value});
                if (workType) {
                    this.setValue(field.replace('work_type_uuid', 'season'), workType.document.season);
                }
            }
        }

        if (field === 'contract.implementer_uuid') {
            this.loadStos();
        }
    }

    isMaintenance() {
        const key = _.get(_.find(this.state.work_categories, {value: this.get('work_category_uuid')}), 'document.key');

        return key === 'maintenance';
    }

    isRepair() {
        const key = _.get(_.find(this.state.work_categories, {value: this.get('work_category_uuid')}), 'document.key');

        return key === 'repair';
    }

    addWork() {
        let works = this.get('works', []);
        works.push({
            key: 'work-' + moment().format(formats.DATETIME),
        });
        this.setValue('contract.works', works);
    }
}
