import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getUserNotificationSettings = (meta) => async (dispatch) => {
    try {
        const response = await api.notifications.getUserNotificationSettings(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getUserNotificationSetting = (unitUuid) => async (dispatch) => {
    try {
        const response = await api.notifications.getUserNotificationSetting(unitUuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createUserNotificationSetting = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.createUserNotificationSetting(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateUserNotificationSetting = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.updateUserNotificationSetting(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteUserNotificationSetting = (data) => async (dispatch) => {
    try {
        const response = await api.notifications.deleteUserNotificationSetting(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
