import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './text-field.less';

@propTypes({
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    withoutWrapper: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password', 'number']),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    success: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onEnter: PropTypes.func
})

@defaultProps({
    value: '',
    defaultValue: '',
    withoutWrapper: false,
    className: '',
    onChange: () => {
    },
    placeholder: '',
    type: 'text',
    size: 'sm',
})

export default class TextField extends Component {

    render() {
        const textFieldState = this.getCurrentTextfieldState();
        const className = classNames(
            'text-field', `text-field-${this.props.size}`,
            textFieldState ? `text-field-${textFieldState}` : '',
            this.props.className);

        const style = {};

        const Hint = this.renderHint();

        const inputProps = {};

        if (this.props.onEnter)
            inputProps.onKeyPress = ::this.handleKeyPress;

        if (this.props.name)
            inputProps.name = this.props.name;

        if (this.props.withoutWrapper) {
            inputProps.className = this.props.className;
        }

        if (this.props.value) {
            inputProps.value = this.props.value;
        }

        const input = (
            <input
                ref="input"
                defaultValue={this.props.defaultValue}
                type={this.props.type}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                {...inputProps} />
        );

        return this.props.withoutWrapper ? input : (
            <div className={className} style={style}>
                {input}
                {Hint}
            </div>
        )
    }

    getValue() {
        return this.refs.input.value;
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.props.onEnter();
        }
    }

    getCurrentTextfieldState() {
        if (this.props.success)
            return 'success';

        if (this.props.error)
            return 'error';

        return false;
    }

    renderHint() {
        if (this.props.success && this.props.success.length > 0) {
            return (<div className="hint">{this.props.success}</div>);
        }
        else if (this.props.error && this.props.error.length) {
            return (<div className="hint">{this.props.error}</div> )
        }
    }

}