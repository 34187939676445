import React from 'react';
import ReactDOMServer from 'react-dom/server';
import MapVehicleIcon from "components/ui/map-vehicle-icon";
import L from 'leaflet';

export default class EventIcon {

    static getIcon() {
        const html = ReactDOMServer.renderToStaticMarkup(<MapVehicleIcon/>);

        let className = `marker marker-event marker_sm`;
        let size = 23;
        return new L.DivIcon({
            html: html,
            className: className,
            iconSize: new L.Point(size, size),
            iconAnchor: new L.Point(size / 2, size / 2),
        });
    }
}