import React, {Component} from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import 'leaflet-polylinedecorator';
import getIcon from "./arrow.js";

@propTypes({
    map: PropTypes.object.isRequired,
    leafletMap: PropTypes.object.isRequired,
    coordinates: PropTypes.array.isRequired,
    color: PropTypes.string,
    width: PropTypes.number,
    tooltip: PropTypes.string,
    dash: PropTypes.string,
    options: PropTypes.object,
    onClick: PropTypes.func,
    onDblClick: PropTypes.func,
    onRightClick: PropTypes.func,
    interactive: PropTypes.bool,
    permanentToolTip: PropTypes.bool,
    static: PropTypes.bool,
    isNeedArrows: PropTypes.bool,
})

@defaultProps({
    color: 'red',
    width: 3,
    onClick: null,
    tooltip: null,
    dash: null,
    onDblClick: () => {
    },
    onRightClick: () => {
    },
    options: {},
    interactive: true,
    permanentToolTip: false,
    static: false,
    isNeedArrows: true,
})

export default class MapPolyline extends Component {

    __layer = null;
    __decoratorLayer = null;

    componentWillMount() {
        this.__layer = new L.polyline(this.props.coordinates, this.getStyle());
        const arrowColor = this.props.color ? this.props.color : window.RNIS_SETTINGS.TRACKCOLOR;

        if (this.props.historyArrow) {
          this.__decoratorLayer = new L.polylineDecorator(this.__layer, {
              patterns: [
                  {offset: 0, repeat: window.RNIS_SETTINGS.arrowRepeat, symbol: L.Symbol.marker({rotate: true, markerOptions: {
                      icon: L.icon({
                          iconUrl: getIcon(arrowColor),
                          iconAnchor: [4, 0],
                          iconSize: [10, 10],
                          className: 'arrowIcon'
                      })
                  }})}
                ]});
        } else {
          this.getLayer().setText('\u25B6          ', {
              repeat: window.RNIS_SETTINGS.arrowRepeat,
              offset: 5,
              attributes: {
                  fill: this.getColor(),
                  'font-size': '12',
              },
              below: true,
          });
        }

        if (this.props.onClick) {
            this.getLayer().on('click', this.props.onClick);
        }
        this.getLayer().on('contextmenu', this.props.onRightClick);
        this.getLayer().on('dblclick', this.props.onDblClick);

        if (this.props.tooltip) {
            this.getLayer().bindTooltip(this.props.tooltip, {
                sticky: true,
                permanent: this.props.permanentToolTip,
            });
        }
    }

    componentWillUpdate(props) {
        if (this.props.static) {
            return;
        }

        this.getLayer().setLatLngs(props.coordinates);
        this.getLayer().setStyle(this.getStyle());
    }

    componentDidMount() {
        if (this.props.leafletMap && this.getLayer()) {
            this.props.leafletMap.addLayer(this.getLayer());
        }

        if (this.props.historyArrow && this.__decoratorLayer && this.props.leafletMap) {
          this.props.leafletMap.addLayer(this.__decoratorLayer);
        }
    }

    componentWillUnmount() {
        this.props.leafletMap.removeLayer(this.getLayer());
        if (this.props.historyArrow) {
          this.props.leafletMap.removeLayer(this.__decoratorLayer);
        }
    }

    getLayer() {
        return this.__layer;
    }

    getStyle() {
        return {
            color: this.getColor(),
            opacity: this.props.opacity || 0.6,
            fill: this.props.fill || "none",
            fillOpacity: this.props.fillOpacity || 0,
            weight: this.props.width,
            dashArray: this.props.dash,
            interactive: this.props.interactive,
        };
    }

    getColor() {
        switch (this.props.color) {
            case 'blue':
                return '#0000FF';
            case 'gray':
                return '#333';
            case 'red':
                return '#FF0000';
            default:
                return this.props.color || window.RNIS_SETTINGS.TRACKCOLOR;
        }
    }

    render() {
        return null;
    }

}
