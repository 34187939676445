import React, { Component } from 'react';
import Select from "components/ui/select";
import Block from "components/ui/form/block";
import SheduleComponent from "../SheduleComponent/SheduleComponent";
import LoaderComponent from "components/ui/loader";

import { connect } from "react-redux";

import { getStopPointRoutes } from "store/reducers/kiutr/displays";

import './StopPointsComponent.less';

@connect((state) => ({}), { getStopPointRoutes })
export default class StopPointsComponent extends Component {

    state = {
        loading: false,
        isFirst: true,
        error: false,
        selectedStopPoint: "",
        stopPointID: "",
        routes: [],
        timerCount: 0,
    }

    componentWillUnmount() {
        clearInterval(window.updateRoutesTimer);
    }

    onChange(field) {
        clearInterval(window.updateRoutesTimer);
        const timerTickValue = window.RNIS_SETTINGS.shedule_stop_points_page_interval || 30000;
        this.setState({ selectedStopPoint: { label: field.label }, stopPointID: field.value, routes: [], error: false })
        this.getRoutes(field.value);
        if (field.value) {
            window.updateRoutesTimer = setInterval(() => {
                this.setState((prevState) => {
                    { timerCount: prevState.timerCount++ }
                })
                this.getRoutes(this.state.stopPointID);
            }, timerTickValue);
        }
    }

    getUniqueRoutes(routes) {
        return routes.filter((item, pos, self) => self.findIndex(v => v.route_uuid === item.route_uuid) === pos)
    }

    async getRoutes(uuid) {
        this.setState({ loading: true })
        const result = await this.props.getStopPointRoutes(uuid);
        if (result.isOk) {
            this.setState({ routes: this.getUniqueRoutes(result.payload.items), loading: false, isFirst: false, error: false })
        } else {
            this.setState({ loading: false, error: true})
        }
    }

    render() {
        let { stopPoints } = this.props;
        return (
            <div className="stopPoints">
                <div className="stopPoints__selector">
                    <Block title="Остановочный пункт">
                        <Select value={this.state.selectedStopPoint}
                            isLoading={!stopPoints.length}
                            onChange={::this.onChange}
                            clearable={false} options={stopPoints}/>
                </Block>
                </div>
                <div className="stopPoints__shedule">
                    {this.state.error ? <p>К сожалению, что-то пошло не так :(</p> : null}
                    {this.state.stopPointID.length && this.state.loading ? <LoaderComponent color="red" size={64} /> : null}
                    {this.state.stopPointID.length && !this.state.routes.length && this.state.isFirst && this.state.loading? <LoaderComponent color="red" size={64} /> : null}
                    {this.state.routes.length || !this.state.isFirst ? <SheduleComponent stopPointID={this.state.stopPointID} routes={this.state.routes} timer={this.state.timerCount} /> : null}
                </div>
            </div>
        );
    }
}