import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getPositions = (meta) => async (dispatch) => {
    try {
        const response = await api.organizational_units.getPositions(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getPosition = (positionUuid) => async (dispatch) => {
    try {
        const response = await api.organizational_units.getPosition(positionUuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createPosition = (data) => async (dispatch) => {
    try {
        const response = await api.organizational_units.createPosition(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updatePosition = (data) => async (dispatch) => {
    try {
        const response = await api.organizational_units.updatePosition(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deletePosition = (data) => async (dispatch) => {
    try {
        const response = await api.organizational_units.deletePosition(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
