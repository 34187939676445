export const countTasks = (items) =>
	items.reduce((prev, cur) => {
		if (!prev[cur.work_type_uuid]) {
			prev[cur.work_type_uuid] = Number(cur.count);
		} else {
			prev[cur.work_type_uuid] = prev[cur.work_type_uuid] + Number(cur.count);
		}
		return prev;
    }, {});

export const countVehicleByTechCard = (items, workTypesDictionary) => {
	const test = Math.ceil(Object.keys(countTasks(items)).reduce((prev, cur) => {
		if (cur && cur !== 'undefined' && workTypesDictionary[cur]) {
			const normVehicleValue = workTypesDictionary[cur].norm_vehicle ? workTypesDictionary[cur].norm_vehicle : 1;
			return prev + countTasks(items)[cur] / normVehicleValue
		}
	}, 0));
	return test;
}
