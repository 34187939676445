import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const createConfirmRequest = (data) => async (dispatch) => {
    try {
        const response = await api.portal.createConfirmRequest(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getConfirmRequest = (uuid = null) => async (dispatch) => {
    try {
        const response = await api.portal.getConfirmRequest(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getConfirmRequests = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.portal.listConfirmRequests(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateConfirmRequest = (data) => async (dispatch) => {
    try {
        const response = await api.portal.updateConfirmRequest(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const confirmRequestUnits = () => async (dispatch) => {
    try {
        const response = await api.portal.confirmRequestUnits();

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};