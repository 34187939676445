import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { propTypes, defaultProps } from 'react-props-decorators';
import { connect } from 'react-redux';

import $ from 'jquery';
import _ from 'lodash';
import Editor from './editor';
import * as alerts from 'helpers/alerts';
import Column from 'components/ui/column';
import BaseTableWithEditorComponent from 'components/base/base_table_with_editor';
import moment from 'moment';
import formats from 'dictionaries/formats';
import { EntityList } from 'helpers/entity';
import './gatn_violations.less';
import IconButton from 'components/ui/icon-button';
import systems from 'dictionaries/systems';
import { getGatnViolationJournals, getGatnViolationJournalsGrpc } from 'store/reducers/gatn/gatn_violations';
import { getEntityNames } from 'store/reducers/system';
import Datepicker from 'components/ui/form/datepicker';
import * as storage from 'utils/storage';
import TableComponent from 'components/ui/Table/Component/TableComponent';
import classNames from 'classnames';

@connect((state) => ({}), { getGatnViolationJournals, getEntityNames, getGatnViolationJournalsGrpc })
export default class GatnViolationsComponent extends BaseTableWithEditorComponent {
	getTitle() {
		return `${systems[this.props.params.component]} → Нарушения`;
	}

	getBaseUrl() {
		return `/${this.props.params.component}/violations`;
	}

	select = 'none';

	constructor(props) {
		super(props);

		const savedState = storage.get(this.getStorageKey() + ':diapason');

		if (savedState) {
			Object.assign(this.state, {
				from: moment(savedState.from),
				to: moment(savedState.to)
			});
		} else {
			Object.assign(this.state, {
				from: moment().subtract(1, 'day').startOf('day'),
				to: moment().endOf('day')
			});
		}
	}

	componentDidUpdate() {
		if (!this.refs.table) return;
		$(this.refs.table.getWrappedInstance().refs.table).on('click', '.has-violation', ::this.onViolationCellClick);
	}

	componentDidMount() {
		this.forceUpdate();
	}

	async componentWillUpdate(props, state) {
		if (props.params.component !== state.component) {
			await this.setState({
				component: props.params.component,
				empty: true
			});

			setTimeout(async () => {
				await this.setState({
					columns: List(this.getColumns(props.params.component)),
					empty: false,
					related: new EntityList()
				});
				this.reload();
			}, 200);
		}
		if (props.params) {
			const vehicle = props.params.vehicle;
			const date = props.params.date;
			const type = props.params.type;
			if (
				this.state.editorUuid !== vehicle ||
				this.state.editorDate !== date ||
				this.state.editorType !== type ||
				this.state.showEditorModal !== !!vehicle
			) {
				this.setState({
					showEditorModal: !!vehicle,
					editorUuid: vehicle,
					editorDate: date,
					editorType: type
				});
			}
		}
	}

	renderTable() {
		if (this.state.empty) {
			return null;
		}

		return super.renderTable();
	}

	onViolationCellClick(e) {
		const el = $(e.target);
		const vehicle = el.data('vehicle');
		const date = el.data('date');
		const type = el.data('type');
		const vehicle_uuid = el.data('uuid');

		this.props.router.push(`${this.getBaseUrl()}/${vehicle}/${date}/${type}/${vehicle_uuid}`);
	}

	getEditor() {
		return (
			<Editor
				key="editor"
				onClose={::this.closeEditor}
				onSubmit={::this.submitEditor}
				mode="edit"
				uuid={this.state.editorUuid}
				dateFrom={this.state.from}
				dateTo={moment(this.state.from).add(1, 'day').format(formats.DATE_API)}
				type={this.state.editorType}
				{...this.props}
			/>
		);
	}

	getColumns(component = null) {
		const componentsNames = [ 'control', 'taxi', 'medic', 'timber', 'agricultural', 'dangerous' ];

		if (!component) {
			component = this.props.params.component;
		}

		if (_.indexOf(componentsNames, component)) {
			return this.prepareColumns([
				new Column('Дата')
					.fromField('date')
					// .withDateFilter()
					.withDrawer((item) => {
						return moment(this.state.from).format(formats.DATE);
					})
					.denyColumnFilter(),

				new Column('Гос. номер').fromField('state_number'),

				new Column('Марка ТС')
					.fromField('vehicle_mark_uuid')
					.withDrawer(
						(item) => (item.vehicle_mark_uuid ? this.state.related.get(item.vehicle_mark_uuid) : '<code>-</code>')
					)
					.denyColumnFilter(),
				new Column('Модель ТС')
					.fromField('vehicle_model_uuid')
					.withDrawer(
						(item) => (item.vehicle_model_uuid ? this.state.related.get(item.vehicle_model_uuid) : '<code>-</code>')
					)
					.denyColumnFilter(),
				new Column('Ответственное лицо')
					.fromField('gatn_responsive_uuid')
					.withDrawer(
						(item) => (item.gatn_responsive_uuid ? this.state.related.get(item.gatn_responsive_uuid) : '<code>-</code>')
					)
					.denyColumnFilter(),

				new Column('Нарушение скоростного режима')
					.fromField('speed_violations')
					.withAlign('center')
					.withDrawer(
						(item) =>
							item.speed_violations > 0
								? `<span class="has-violation" data-uuid="${item.vehicle_uuid}" data-type="speed" data-vehicle="${item.state_number}" data-date="${moment(
										this.state.from
									).format(formats.DATE_URL)}">${item.speed_violations}</span>`
								: '<span class="no-violation"></span>'
					)
					.denyColumnFilter(),
				...(!window.RNIS_SETTINGS.hide_zone_violations
					? [
							new Column('Выезд за пределы региона')
								.fromField('zone_violations')
								.withAlign('center')
								.withDrawer(
									(item) =>
										item.zone_violations > 0
											? `<span class="has-violation" data-uuid="${item.vehicle_uuid}" data-type="zone" data-vehicle="${item.state_number}" data-date="${moment(
													this.state.from
												).format(formats.DATE_URL)}">${item.zone_violations}</span>`
											: '<span class="no-violation"></span>'
								)
								.denyColumnFilter()
						]
					: [])
			]);
		} else {
			return this.prepareColumns([
				new Column('Дата')
					.fromField('date')
					// .withDateFilter()
					.withDrawer((item) => moment(item.date).format(formats.DATE))
					.denyColumnFilter(),

				new Column('Гос. номер').fromField('state_number'),

				new Column('Марка ТС')
					.fromField('vehicle_mark_uuid')
					.withDrawer(
						(item) => (item.vehicle_mark_uuid ? this.state.related.get(item.vehicle_mark_uuid) : '<code>-</code>')
					)
					.denyColumnFilter(),

				new Column('Модель ТС')
					.fromField('vehicle_model_uuid')
					.withDrawer(
						(item) => (item.vehicle_model_uuid ? this.state.related.get(item.vehicle_model_uuid) : '<code>-</code>')
					)
					.denyColumnFilter(),
				new Column('Нарушение скоростного режима')
					.fromField('speed_violations')
					.withAlign('center')
					.withDrawer(
						(item) =>
							item.speed_violations > 0
								? `<span class="has-violation" data-type="speed" data-vehicle="${item.vehicle_uuid}" data-date="${moment(
										item.date
									).format(formats.DATE_URL)}">${item.speed_violations}</span>`
								: '<span class="no-violation"></span>'
					)
					.denyColumnFilter()
			]);
		}
	}

	async loadData(meta) {
		meta.filters.withComponent =
			this.props.params.component === 'kiutr-control' ? 'kiutr' : this.props.params.component;
		meta.filters.withPeriod = [
			moment(this.state.from).format(formats.DATE_API),
			moment(this.state.from).add(1, 'day').format(formats.DATE_API)
		];
		const response = await this.props.getGatnViolationJournalsGrpc(meta);
		return response;
	}

	async loadRelatedEntities(json, drawCallback) {
		const result = json.data;

		const marks = _.map(_.uniq(_.filter(_.map(result, 'vehicle_mark_uuid'))), (uuid) => ({
			class: 'App\\Dictionaries\\Vehicles\\VehicleMark\\Model',
			uuid: uuid,
			source: 'dictionary'
		}));
		const models = _.map(_.uniq(_.filter(_.map(result, 'vehicle_model_uuid'))), (uuid) => ({
			class: 'App\\Dictionaries\\Vehicles\\VehicleModel\\Model',
			uuid: uuid,
			source: 'dictionary'
		}));
		const responsives = _.map(_.uniq(_.filter(_.map(result, 'gatn_responsive_uuid'))), (uuid) => ({
			class: 'App\\Dictionaries\\Gatn\\GatnResponsives\\Model',
			uuid: uuid,
			source: 'dictionary'
		}));
		const response = await this.props.getEntityNames(_.concat(marks, models, responsives));
		if (response.isOk) {
			this.state.related.add(response);

			drawCallback(json);
		}
	}

	renderHeaderActions() {
		return [ this.diapasonFilter() ];
	}

	diapasonFilter() {
		return (
			<div key="diapason">
				<div className="top-menu__label">Выбор даты:</div>
				<Datepicker
					style="dark"
					value={this.state.from}
					onChange={::this.fromChange}
					disabledDays={::this.disabledDays}
				/>
				{/* <Datepicker style="dark" value={this.state.to} onChange={::this.toChange} disabledDays={::this.disabledDays} /> */}
			</div>
		);
	}

	disabledDays(day) {
		return moment(day).isAfter(moment(), 'day');
	}

	async fromChange({ target: { value } }) {
		await this.setState({ from: value });
		this.saveState();
		this.reload();
	}

	async toChange({ target: { value } }) {
		await this.setState({
			to: value,
			from: moment(this.state.from).isAfter(moment(value)) ? value : this.state.from
		});
		this.saveState();
		this.reload();
	}

	saveState() {
		storage.save(this.getStorageKey() + ':diapason', {
			from: moment(this.state.from).format(formats.DATE_API),
			to: moment(this.state.to).format(formats.DATE_API)
		});
	}

	getCreateButton() {
		return null;
	}
}
