export default {
    PROCESSING: '<div class="UsersLoaderContainer"><div class="Loader" style="color: red; align: center;"><div class="tail-spin tail-spin-64 tail-spin-red" /></div>',
    PAGINATION: {
        FIRST: 'Первая',
        LAST: 'Последняя',
        PREVIOUS: 'Предыдущая',
        NEXT: 'Следующая'
    },
    EMPTY: 'Нет данных',
    INFO: 'Записи c _START_ по _END_ из _TOTAL_',
    INFO_SHORT: 'с _START_ по _END_ из _TOTAL_',
    INFOEMPTY: '',
    ROWS: {
        _: '',
        0: ''
    }
}