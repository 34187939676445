import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import './route-modal.less';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import PageModal from 'components/ui/page-modal';
import TableContainer from "components/ui/Table/Container/TableContainer";
import moment from "moment";
import formats from "dictionaries/formats";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {connect} from "react-redux";
import {getStopPoints} from "store/reducers/geo/stop-points";

@propTypes({
    route: PropTypes.object,
    routes: PropTypes.array,
    onClose: PropTypes.func,
})

@connect(state => ({}), {getStopPoints})

export default class RouteModal extends Component {

    state = {
        stopPoints: {},
    };

    componentDidMount() {
        this.loadStopPoints();
    }

    async loadStopPoints() {
        const uuids = _.uniq(_.flatten(_.map(this.props.routes, (route) => {
            return _.map(_.filter(_.concat(route.forward_points, route.reverse_points), {point_type: 'stop_point'}), 'type_uuid');
        })));
        console.log("🚀 ~ file: route-modal.js ~ line 41 ~ RouteModal ~ uuids ~ uuids", uuids)
        const response = await this.props.getStopPoints({
            filters: {
                withUuid: uuids,
            },
        });
        if (response.isOk) {
            this.setState({
                stopPoints: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'title'),
            })
        } else {
            response.showErrors();
        }
    }

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );
        return (
            <PageModal
            header={{ title: this.props.route ? this.props.route.title : '', buttons}}
                onClose={this.props.onClose}
                className={`profile-modal b-modal-edit RouteModal`}
                withFade={false}
            >
                <Accordion>
                    {this.props.routes.map(::this.renderRoute)}
                </Accordion>
            </PageModal>
        )
    }

    renderRoute(route) {
        return (
            <AccordionItem key={route.uuid} opened={true} title={route.name}>
                <div className="Table">
                    <TableContainer>
                        <table className="b-table">
                            <thead>
                            <tr>
                                <th>Остановка</th>
                                <th>Улицы</th>
                                <th>Расст, км</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td colSpan={3} className="block-title">Прямое направление</td>
                            </tr>
                            {route.forward_points.map(this.renderPoint.bind(this, 'forward'))}
                            <tr>
                                <td colSpan={3} className="block-title">Обратное направление</td>
                            </tr>
                            {route.reverse_points.map(this.renderPoint.bind(this, 'reverse'))}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </AccordionItem>
        );
    }

    renderPoint(direction, point, index) {
        if (point.point_type !== 'stop_point') {
            return null;
        }

        return (
            <tr key={`${direction}:${index}`}>
                <td>{_.get(this.state.stopPoints, point.type_uuid)}</td>
                <td>{(point.streets || []).map((street, index) => {
                    return <span key={index} className="street">{street}</span>;
                })}</td>
                <td>{this.round(point.distance_to_the_next_point / 1000)}</td>
            </tr>
        )
    }

    round(val) {
        return Math.round(val * 100) / 100;
    }
}