import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {Link} from "react-router";
import classNames from 'classnames';

@propTypes({
})

@defaultProps({
})

export default class ModalTopMenuListSeparator extends Component {
    render() {
        return (
            <li className="top-menu_modal__item">
                <div className="top-menu_modal__separator"/>
            </li>
        );
    }
}