import { api } from 'helpers/api';
import { error as setErrorMessage } from 'helpers/response';
import { stopPointsResponseData, unitsResponseData } from '../../../components/modules/maps/constants';
import { getOrderBy, getPagination, mapSelectedRouteVariants, mapSelectedRouteVariantsToStopPoints } from '../../../components/modules/maps/utils';
import formats from '../../../dictionaries/formats';
import {
	setBnsoList,
	setCarrierUnits,
	setDictionaries,
	setLoading,
	setRoad,
	setRoadPart,
	setRoadRepairPart,
	setHistoryOrders,
	setHistoryOrderExecutions,
	setMo,
	setMunicipalities,
	setRoute,
	setRoutes,
	setRouteStopPoints,
	setSelectedRouteVariants,
	setSelectedRoutes,
	setVehicleForPopup,
	setUnits,
} from './actions';
import { getUniqueRoutesUuid, mapUnits, reduceDictionariesResponseToMap } from './utils';
import { getAnalyticDiapasonSelector } from './selectors';
import { reduceDictionariesToObject } from '../dictionaries/utils';

export const getDictionaries = ({ dictionaries, component }) => async (dispatch) => {
	try {
		let response;
		if (component === 'road/task') {
			response = await api.dictionary.getDictionaryLists(dictionaries, {
				order: getOrderBy('name', 'asc'),
				pagination: {
					page: 1,
					limit: 10000
				}
			});

			if (response.success) {
				dispatch(setDictionaries(reduceDictionariesToObject(response.payload.items)));
			} else {
				throw new Error(errors);
			}
		} else {
			response = await api.dictionary.getDictionaryLists(dictionaries, {
				order: getOrderBy('name', 'asc'),
				filters: {
					withComponent: component
				},
			});

			if (response.success) {
				dispatch(setDictionaries(reduceDictionariesToObject(response.payload.items)));
			} else {
				throw new Error(errors);
			}
		}

	} catch (error) {
		setErrorMessage(error);
	}
};

export const getUnits = ({ component }) => async (dispatch) => {
	try {
		const payload = {
			pagination: getPagination(1, 1500),
			filters: {
				withComponent: component
			},
			response_data: unitsResponseData
		};
		if (!payload.filters.withComponent.length || !payload.filters.withComponent || payload.filters.withComponent.includes(null)) {
			delete payload.filters.withComponent;
		}
		if (!Object.keys(payload.filters).length) {
			delete payload.filters;
		}
		let response = await api.organizational_units.getUnits(payload);
		if (response.success) {
			dispatch(setUnits(mapUnits(response.payload.items)));
		} else {
			throw new Error(errors);
		}
	} catch (error) {
		setErrorMessage(error);
	}
};

export const getCarrierUnits = () => async (dispatch, getState) => {
	try {
		let response = await api.geo.getContracts();
		if (response.success) {
			const contracts = response.payload.items;
			const carriers = contracts.map((contract) => contract.carrier_uuid);
			const units = getState.maps.units.filter((unit) => carriers.includes(unit.value));
			dispatch(setCarrierUnits(units));
		} else {
			throw new Error(errors);
		}
	} catch (error) {
		setErrorMessage(error);
	}
};

export const getBnsoList = () => async (dispatch) => {
	try {
		const response = await api.vehicles.getBnsoList();
		if (response.success) {
			dispatch(setBnsoList(_.keyBy(response.payload.items, 'bnso_number')));
		} else {
			throw new Error(response.errors);
		}
	} catch (error) {
		setErrorMessage(error);
	}
};

export const getRoad = (uuid) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await api.kurs.getRoad(uuid);
		if (response.success) {
			dispatch(setRoad(response.payload));
		} else {
			throw new Error(response.errors);
		}
	} catch (error) {
		setErrorMessage(error);
	} finally {
		setLoading(false);
	}
};

export const getRoadPart = (uuid, slice = {}) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await api.kurs.getRoadPart(uuid, slice);
		if (response.success) {
			dispatch(setRoadPart(response.payload));
		} else {
			throw new Error(response.errors);
		}
	} catch (error) {
		setErrorMessage(error);
	} finally {
		setLoading(false);
	}
};
export const getRoadRepairPart = (uuid) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await api.dictionary.getDocument('kurs_road_repair_parts', uuid);
		if (response.success) {
			dispatch(setRoadRepairPart(response.payload.data));
		} else {
			throw new Error(response.errors);
		}
	} catch (error) {
		setErrorMessage(error);
	} finally {
		setLoading(false);
	}
};

export const getHistoryOrders = (uuids) => async (dispatch) => {
	try {
		const response = await api.geo.getOrders({
			filters: {
				withUuid: uuids
			}
		});
		if (response.success) {
			dispatch(setHistoryOrders(response.payload.items));
		} else {
			throw new Error(response.errors);
		}
	} catch (error) {
		setErrorMessage(error);
	}
};

export const getHistoryOrderExecutions = (from, to, vehicleUuid) => async (dispatch) => {
	try {
		const response = await api.geo.getOrderExecutions({
			filters: {
				withPeriod: [ from.format(formats.DATETIME_API), to.format(formats.DATETIME_API) ],
				withVehicles: [ vehicleUuid ],
				withTypes: [ 'production_forward', 'production_reverse' ]
			}
		});
		if (response.success) {
			dispatch(setHistoryOrderExecutions(response.payload.items));
			dispatch(getHistoryOrders(_.uniq(_.map(response.payload.items, 'order_uuid'))));
		} else {
			throw new Error(response.errors);
		}
	} catch (error) {
		setErrorMessage(error);
	}
};

export const getMo = (type) => async (dispatch) => {
	try {
		const response = await api.geo.listUserGeoObjectsMO({
			filters: {
				withComponent: type
			}
		});
		if (response.success) {
			dispatch(setMo(response.payload.items));
		} else {
			throw new Error(response.errors);
		}
	} catch (error) {
		setErrorMessage(error);
	}
};

export const getMunicipalitiesKiutrSummary = (type) => async (dispatch, state) => {
	try {
		const { from_analytic, to_analytic } = getAnalyticDiapasonSelector(state());
		const response = await api.reports.getMunicipalitiesKiutrSummary({
			date_from: from_analytic.format(formats.DATETIME_API),
			date_to: to_analytic.format(formats.DATETIME_API),
			component: 'kiutr',
			type
		});
		if (response.success) {
			dispatch(setMunicipalities(response.payload.municipalities));
		} else {
			throw new Error(response.errors);
		}
	} catch (error) {
		setErrorMessage(error);
	}
};

export const getRouteStopPoints = (uuids) => async (dispatch) => {
	try {
		const response = await api.geo.getStopPoints({
			filters: {
				withUuid: uuids
			},
			response_data: stopPointsResponseData
		});
		if (response.isOk) {

			dispatch(setRouteStopPoints(_.keyBy(response.payload.items, 'uuid')));
		} else {
			throw new Error(response.errors);
		}
	} catch (error) {
		setErrorMessage(error);
	}
};

export const getRoutes = (routeUuid) => async (dispatch) => {
	try {
		const response = await api.geo.getRoutes({
			filters: {
				withUuid: routeUuid
			}
		});
		if (response.success) {

			dispatch(setRoutes(response.payload.items));
			const uuids = getUniqueRoutesUuid(response.payload.items);
			dispatch(getRouteStopPoints(uuids));
		} else {
			throw new Error(response.errors);
		}
	} catch (error) {
		setErrorMessage(error);
	}
};
export const getRoute = (routeUuid) => async (dispatch) => {
	try {
		const response = await api.geo.getRoute(routeUuid);

		if (response.success) {
			dispatch(setRoute(response.payload));
			dispatch(getRoutes([routeUuid]));
		} else {
			throw new Error(response.errors);
		}
	} catch (error) {
		setErrorMessage(error);
	}
};

export const getSelectedRoutes = (routes) => async (dispatch) => {
	if (!routes.length) {
		dispatch(setSelectedRoutes([]));
		dispatch(setSelectedRouteVariants([]));
	}
	try {
		const response = await api.geo.getRouteVariants({
			filters: {
				withRoute: routes
			}
		});

		if (response.success) {
			dispatch(setSelectedRoutes(_.mapValues(_.keyBy(routes, 'uuid'), 'title')));
			dispatch(setSelectedRouteVariants(mapSelectedRouteVariants(response.payload.items)));
			dispatch(getRouteStopPoints(mapSelectedRouteVariantsToStopPoints(response.payload.items)));
		} else {
			throw new Error(response.errors);
		}
	} catch (error) {
		setErrorMessage(error);
	}
};

export const getVehicleForPopup = (deviceId) => async (dispatch) => {
	try {
		const response = await api.vehicles.getVehicleList({
			filters: {
				withCurrentBnsoNumber: [ deviceId ]
			}
		});
		if (response.success) {
			dispatch(setVehicleForPopup(response.payload.items[0]));
			return response.payload.items[0];
		} else {
			throw new Error(response.errors);
		}
	} catch (error) {
		setErrorMessage(error);
	}
};
