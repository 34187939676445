import React from 'react';
import Datepicker from 'components/ui/form/datepicker';
import InputMask from 'components/ui/form/input-mask';
import './styles.less';
export const DiapasonFilter = ({
	onDiapasonChange,
	onKeyPress,
	from_date,
	from_time,
	to_date,
	to_time,
	onPlusWeek,
	onMinusWeek
}) => (
	<div style={{ display: 'flex' }}>
		<div className="top-menu__label">Выбор диапазона:</div>
		{window.RNIS_SETTINGS.CITY_TULA && (
			<div className="back" onClick={onMinusWeek}>
				<i className="player__icon player__icon_angle-left" />
			</div>
		)}
		<Datepicker
			style="dark"
			value={from_date}
			onChange={(event) => onDiapasonChange('from_date', event.target.value)}
			onKeyPress={onKeyPress}
		/>
		<InputMask
			mask="99:99"
			value={from_time}
			onChange={(event) => onDiapasonChange('from_time', event.target.value)}
			className="header-time"
			onKeyPress={onKeyPress}
		/>
		<Datepicker
			style="dark"
			value={to_date}
			onChange={(event) => onDiapasonChange('to_date', event.target.value)}
			onKeyPress={onKeyPress}
		/>
		<InputMask
			mask="99:99"
			value={to_time}
			onChange={(event) => onDiapasonChange('to_time', event.target.value)}
			onKeyPress={onKeyPress}
		/>
		{window.RNIS_SETTINGS.CITY_TULA && (
			<div className="back" onClick={onPlusWeek}>
				<i className="player__icon player__icon_angle-right" />
			</div>
		)}
	</div>
);
