// source: distanceapi.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.pb.DistancesRequest', null, global);
goog.exportSymbol('proto.pb.DistancesResponse', null, global);
goog.exportSymbol('proto.pb.DistancesResponse.Data', null, global);
goog.exportSymbol('proto.pb.RecalcDistancesRequest', null, global);
goog.exportSymbol('proto.pb.RecalcDistancesResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.DistancesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.DistancesRequest.repeatedFields_, null);
};
goog.inherits(proto.pb.DistancesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.DistancesRequest.displayName = 'proto.pb.DistancesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RecalcDistancesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.RecalcDistancesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.RecalcDistancesRequest.displayName = 'proto.pb.RecalcDistancesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.DistancesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.DistancesResponse.repeatedFields_, null);
};
goog.inherits(proto.pb.DistancesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.DistancesResponse.displayName = 'proto.pb.DistancesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.DistancesResponse.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.DistancesResponse.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.DistancesResponse.Data.displayName = 'proto.pb.DistancesResponse.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RecalcDistancesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.RecalcDistancesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.RecalcDistancesResponse.displayName = 'proto.pb.RecalcDistancesResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.DistancesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.DistancesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.DistancesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.DistancesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.DistancesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceCodeList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    dateFrom: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dateTo: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.DistancesRequest}
 */
proto.pb.DistancesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.DistancesRequest;
  return proto.pb.DistancesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.DistancesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.DistancesRequest}
 */
proto.pb.DistancesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateFrom(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.DistancesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.DistancesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.DistancesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.DistancesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceCodeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getDateFrom();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDateTo();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated string device_code = 1;
 * @return {!Array<string>}
 */
proto.pb.DistancesRequest.prototype.getDeviceCodeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pb.DistancesRequest} returns this
 */
proto.pb.DistancesRequest.prototype.setDeviceCodeList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.DistancesRequest} returns this
 */
proto.pb.DistancesRequest.prototype.addDeviceCode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.DistancesRequest} returns this
 */
proto.pb.DistancesRequest.prototype.clearDeviceCodeList = function() {
  return this.setDeviceCodeList([]);
};


/**
 * optional int64 date_from = 2;
 * @return {number}
 */
proto.pb.DistancesRequest.prototype.getDateFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.DistancesRequest} returns this
 */
proto.pb.DistancesRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 date_to = 3;
 * @return {number}
 */
proto.pb.DistancesRequest.prototype.getDateTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.DistancesRequest} returns this
 */
proto.pb.DistancesRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.RecalcDistancesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.RecalcDistancesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.RecalcDistancesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RecalcDistancesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateFrom: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dateTo: jspb.Message.getFieldWithDefault(msg, 3, 0),
    from: jspb.Message.getFieldWithDefault(msg, 4, ""),
    to: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RecalcDistancesRequest}
 */
proto.pb.RecalcDistancesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.RecalcDistancesRequest;
  return proto.pb.RecalcDistancesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RecalcDistancesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RecalcDistancesRequest}
 */
proto.pb.RecalcDistancesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateFrom(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateTo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RecalcDistancesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.RecalcDistancesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RecalcDistancesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RecalcDistancesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateFrom();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDateTo();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string device_code = 1;
 * @return {string}
 */
proto.pb.RecalcDistancesRequest.prototype.getDeviceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.RecalcDistancesRequest} returns this
 */
proto.pb.RecalcDistancesRequest.prototype.setDeviceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 date_from = 2;
 * @return {number}
 */
proto.pb.RecalcDistancesRequest.prototype.getDateFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.RecalcDistancesRequest} returns this
 */
proto.pb.RecalcDistancesRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 date_to = 3;
 * @return {number}
 */
proto.pb.RecalcDistancesRequest.prototype.getDateTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.RecalcDistancesRequest} returns this
 */
proto.pb.RecalcDistancesRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string from = 4;
 * @return {string}
 */
proto.pb.RecalcDistancesRequest.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.RecalcDistancesRequest} returns this
 */
proto.pb.RecalcDistancesRequest.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string to = 5;
 * @return {string}
 */
proto.pb.RecalcDistancesRequest.prototype.getTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.RecalcDistancesRequest} returns this
 */
proto.pb.RecalcDistancesRequest.prototype.setTo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.DistancesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.DistancesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.DistancesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.DistancesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.DistancesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.pb.DistancesResponse.Data.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.DistancesResponse}
 */
proto.pb.DistancesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.DistancesResponse;
  return proto.pb.DistancesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.DistancesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.DistancesResponse}
 */
proto.pb.DistancesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pb.DistancesResponse.Data;
      reader.readMessage(value,proto.pb.DistancesResponse.Data.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.DistancesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.DistancesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.DistancesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.DistancesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pb.DistancesResponse.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.DistancesResponse.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.DistancesResponse.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.DistancesResponse.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.DistancesResponse.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.DistancesResponse.Data}
 */
proto.pb.DistancesResponse.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.DistancesResponse.Data;
  return proto.pb.DistancesResponse.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.DistancesResponse.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.DistancesResponse.Data}
 */
proto.pb.DistancesResponse.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.DistancesResponse.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.DistancesResponse.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.DistancesResponse.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.DistancesResponse.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string device_code = 1;
 * @return {string}
 */
proto.pb.DistancesResponse.Data.prototype.getDeviceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.DistancesResponse.Data} returns this
 */
proto.pb.DistancesResponse.Data.prototype.setDeviceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double distance = 2;
 * @return {number}
 */
proto.pb.DistancesResponse.Data.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pb.DistancesResponse.Data} returns this
 */
proto.pb.DistancesResponse.Data.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated Data data = 1;
 * @return {!Array<!proto.pb.DistancesResponse.Data>}
 */
proto.pb.DistancesResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.pb.DistancesResponse.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pb.DistancesResponse.Data, 1));
};


/**
 * @param {!Array<!proto.pb.DistancesResponse.Data>} value
 * @return {!proto.pb.DistancesResponse} returns this
*/
proto.pb.DistancesResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pb.DistancesResponse.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.DistancesResponse.Data}
 */
proto.pb.DistancesResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pb.DistancesResponse.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.DistancesResponse} returns this
 */
proto.pb.DistancesResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.RecalcDistancesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.RecalcDistancesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.RecalcDistancesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RecalcDistancesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RecalcDistancesResponse}
 */
proto.pb.RecalcDistancesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.RecalcDistancesResponse;
  return proto.pb.RecalcDistancesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RecalcDistancesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RecalcDistancesResponse}
 */
proto.pb.RecalcDistancesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistance(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RecalcDistancesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.RecalcDistancesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RecalcDistancesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RecalcDistancesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string device_code = 1;
 * @return {string}
 */
proto.pb.RecalcDistancesResponse.prototype.getDeviceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.RecalcDistancesResponse} returns this
 */
proto.pb.RecalcDistancesResponse.prototype.setDeviceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double distance = 2;
 * @return {number}
 */
proto.pb.RecalcDistancesResponse.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pb.RecalcDistancesResponse} returns this
 */
proto.pb.RecalcDistancesResponse.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double percent = 3;
 * @return {number}
 */
proto.pb.RecalcDistancesResponse.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pb.RecalcDistancesResponse} returns this
 */
proto.pb.RecalcDistancesResponse.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


goog.object.extend(exports, proto.pb);
