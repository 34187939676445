import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import $ from 'jquery';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";

import {getRouteVariant} from "store/reducers/routes/route_variants";
import {getStopPoints} from "store/reducers/routes/routes";
import {api} from "helpers/api";
import {
    createRouteVariantNullRun, getRouteVariantNullRun,
    updateRouteVariantNullRun
} from "store/reducers/kiutr/route_variant_null_run";
import Block from "components/ui/form/block";
import {getUserGeoObjects} from "store/reducers/user-map-objects/object_editor";
import RouteVariantNullRunMap from "components/modules/kiutr/routes/RouteVariantNullRunEditor/RouteVariantNullRunMap/index";
import L from 'leaflet';
import {getRoute} from "store/reducers/routes/route_editor";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import ValidationError from "components/ui/validation-error";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getRouteVariantNullRun, createRouteVariantNullRun, updateRouteVariantNullRun})

export default class RouteVariantNullRunEditor extends BaseEditor {

    title = 'маневра';
    modalClassName = 'b-modal-route map-route-editor-modal';

    componentDidMount() {
        this.forceUpdate();
    }

    async loadData(uuid) {
        return await this.props.getRouteVariantNullRun(uuid);
    }

    async createItem(data) {
        return await this.props.createRouteVariantNullRun(data);
    }

    async updateItem(data) {
        return await this.props.updateRouteVariantNullRun(data);
    }

    getForm(item, onSubmit) {
        if (_.isEmpty(item)) {
            item = {
                is_forward: true,
                is_first_stop_point: true,
                distance: 0,
                time: 0,
            };
        }

        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }


    composeItem(data) {
        let item = _.clone(data);

        item.route_variant_uuid = this.props.routeVariantUuid;

        return item;
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getStopPoints,
    getRouteVariant,
    getUserGeoObjects,
    getRoute,
    getDictionaryList
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        route_variant_null_run: {},
        routeVariant: {},
        forwardPoints: {
            first: {},
            last: {},
        },
        reversePoints: {
            first: {},
            last: {},
        },
        userGeoObjects: [],
        user_geo_object: null,
        user_geo_object_uuid: null,
        stopPoints: {},
        route: {},
        route_types: [],
    };

    getData() {
        return this.state.route_variant_null_run;
    }

    async componentDidMount() {
        await this.setState({
            route_variant_null_run: this.props.data,
        });
        this.loadRouteVariant();
        this.loadUserGeoObjects();
        this.loadRoute();
        this.loadDictionaries([
            'route_types',
        ]);
    }

    async loadRoute() {
        const response = await this.props.getRoute(this.props.routeUuid);

        if (response.isOk) {
            this.setState({
                route: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    isBothSide() {
        return _.get(_.find(this.state.route_types, {value: this.state.route.route_type_uuid}), 'label') === 'Маятниковый';
    }

    get(path, defaultValue = null) {
        return _.get(this.state.route_variant_null_run, path, defaultValue);
    }

    async loadUserGeoObjects() {
        const response = await this.props.getUserGeoObjects({
            filters: {
                onlyNullRun: true,
            },
        });

        if (response.isOk) {
            const userGeoObjects = _.sortBy(_.map(response.payload.items, item => ({
                value: item.uuid,
                label: item.title,
                geometry: item.geometry,
                title: item.title,
                uuid: item.uuid,
            })), 'label');

            this.setState({
                userGeoObjects,
            });

            if (this.state.route_variant_null_run.points) {
                const uuid = _.get(_.find(this.state.route_variant_null_run.points, {point_type: 'object'}), 'type_uuid');
                if (uuid) {
                    const object = _.find(userGeoObjects, {value: uuid});
                    this.setValue('user_geo_object', object, true);
                    this.setValue('user_geo_object_uuid', uuid, true);
                }
            }
        } else {
            response.showErrors();
        }
    }

    async loadRouteVariant() {
        const response = await this.props.getRouteVariant(this.props.routeVariantUuid);

        if (response.isOk) {
            const routeVariant = response.payload;
            this.setState({
                routeVariant,
            });

            const forwardPoints = {
                first: _.first(_.filter(routeVariant.forward_points, {point_type: 'stop_point'})),
                last: _.last(_.filter(routeVariant.forward_points, {point_type: 'stop_point'})),
            };
            const reversePoints = {
                first: _.first(_.filter(routeVariant.reverse_points, {point_type: 'stop_point'})),
                last: _.last(_.filter(routeVariant.reverse_points, {point_type: 'stop_point'})),
            };
            const routeVariantPoints = _.filter(_.uniq(_.map(_.filter(_.concat(routeVariant.forward_points, routeVariant.reverse_points), {point_type: 'stop_point'}), 'type_uuid')));
            const stopPoints = await this.preloadStopPoints(_.filter(routeVariantPoints));

            if (forwardPoints.first) {
                forwardPoints.first.title = _.get(stopPoints, forwardPoints.first.type_uuid);
            }
            if (forwardPoints.last) {
                forwardPoints.last.title = _.get(stopPoints, forwardPoints.last.type_uuid);
            }
            if (reversePoints.first) {
                reversePoints.first.title = _.get(stopPoints, reversePoints.first.type_uuid);
            }
            if (reversePoints.last) {
                reversePoints.last.title = _.get(stopPoints, reversePoints.last.type_uuid);
            }

            await this.setState({
                forwardPoints,
                reversePoints,
            });
        } else {
            response.showErrors();
        }
    }

    async preloadStopPoints(uuids) {
        const response = await this.props.getStopPoints({
            filters: {
                withUuid: uuids,
            },
            pagination: {
                page: 1,
            },
        });
        if (response.isOk) {
            this.setState({
                stopPoints: _.keyBy(response.payload.items, 'uuid'),
            });
            return _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'title');
        }
    }

    async setValue(field, value, ignoreUpdate = false) {
        await super.setValue(field, value);

        if (ignoreUpdate) {
            return;
        }
        if ((field === 'user_geo_object_uuid') || (field === 'route_variant_null_run.is_forward') || (field === 'route_variant_null_run.is_first_stop_point')) {
            this.recalcPoints();
        }
    }

    async recalcPoints() {
        if (!this.state.user_geo_object) {
            return null;
        }

        const objectPoint = {
            latitude: this.state.user_geo_object.geometry.coordinates[1],
            longitude: this.state.user_geo_object.geometry.coordinates[0],
            point_type: 'object',
            name: this.state.user_geo_object.title,
            type_uuid: this.state.user_geo_object.uuid,
            is_routing_enabled: true,
        };

        const stopPointType = this.get('is_first_stop_point') ? 'first' : 'last';
        const stopPointTypeReverse = this.get('is_first_stop_point') ? 'last' : 'first';
        let stopPoint = this.get('is_forward') ? this.state.forwardPoints[stopPointType] : this.state.reversePoints[stopPointType];
        if (!this.isBothSide()) {
            stopPoint = this.state.forwardPoints[stopPointTypeReverse];
        }
        const stopPointPoint = {
            latitude: stopPoint.latitude,
            longitude: stopPoint.longitude,
            point_type: 'stop_point',
            type_uuid: stopPoint.type_uuid,
            is_routing_enabled: true,
        };

        const points = this.get('is_forward') ? [
            objectPoint,
            stopPointPoint,
        ] : [
            stopPointPoint,
            objectPoint,
        ];

        await this.setValue('route_variant_null_run.points', points);
        this.recalcPath();
    }

    onUserGeoObjectChange(e) {
        const value = e ? e.value : null;

        this.setValue('user_geo_object', e);
        this.setValue('user_geo_object_uuid', value);
    }

    render() {
        const stopPointType = this.get('is_first_stop_point') ? 'first' : 'last';
        const stopPointTypeReverse = this.get('is_first_stop_point') ? 'last' : 'first';
        let stopPointBlock = (
            <Block key="stop-point" size="xl" title="Остановка">
                {this.get('is_forward') ? (
                    _.get(this.state.forwardPoints[stopPointType], 'title')
                ) : (
                    _.get(this.state.reversePoints[stopPointType], 'title')
                )}
            </Block>
        );
        if (!this.isBothSide()) {
            stopPointBlock = (
                <Block key="stop-point" size="xl" title="Остановка">
                    {this.get('is_forward') ? (
                        _.get(this.state.forwardPoints[stopPointType], 'title')
                    ) : (
                        _.get(this.state.forwardPoints[stopPointTypeReverse], 'title')
                    )}
                </Block>
            );
        }
        const objectBlock = (
            <Block key="object" size="xl" title="Пункт">
                {this.select('user_geo_object_uuid', this.state.userGeoObjects, {
                    onChange: ::this.onUserGeoObjectChange
                })}
                <ValidationError error={this.getError('route_variant_null_run.points')}/>
            </Block>
        );

        return (
            <div className="VariantPointsEditor">
                <Block size="xl" title="Направление маневра">
                    {this.select('route_variant_null_run.is_forward', _.filter([
                        this.state.forwardPoints[stopPointType] ? {
                            value: true,
                            label: 'На маршрут',
                        } : null,
                        (this.state.reversePoints[stopPointType] || !this.isBothSide() && this.state.forwardPoints[stopPointTypeReverse]) ? {
                            value: false,
                            label: 'С маршрута',
                        } : null,
                    ]), {
                        clearable: false,
                    })}
                </Block>
                {this.isBothSide() ? (
                    <Block size="xl" title="Остановка">
                        {this.select('route_variant_null_run.is_first_stop_point', _.filter([
                            {
                                value: true,
                                label: 'Первая',
                            },
                            this.isBothSide() ? {
                                value: false,
                                label: 'Последняя',
                            } : null,
                        ]), {
                            clearable: false,
                        })}
                    </Block>
                ) : null}
                {this.get('is_forward') ? [
                    objectBlock,
                    stopPointBlock,
                ] : [
                    stopPointBlock,
                    objectBlock,
                ]}
                <Block size="xl" title="Расстояние, м">
                    {this.textInput('route_variant_null_run.distance', {
                        type: 'number',
                        positive: true,
                    })}
                </Block>
                <Block size="xl" title="Время, мин">
                    {this.textInput('route_variant_null_run.time', {
                        type: 'number',
                        positive: true,
                    })}
                </Block>
                <Block size="xl" title="Наименование">
                    {this.textInput('route_variant_null_run.name')}
                </Block>
                <RouteVariantNullRunMap
                    routeVariantPoints={this.state.routeVariant[(!this.isBothSide() || this.get('is_forward')) ? 'forward_points' : 'reverse_points']}
                    routeVariantNullRun={this.state.route_variant_null_run}
                    onUpdate={::this.onUpdate}
                    deletePoint={::this.deletePoint}
                    stopPoints={this.state.stopPoints}
                />
            </div>
        );
    }

    async deletePoint(index) {
        let variantNullRun = this.state.route_variant_null_run;

        variantNullRun.points.splice(index, 1);

        await this.setState({
            route_variant_null_run: variantNullRun,
        });

        this.recalcPath();
    }

    async onUpdate(route_variant_null_run) {
        await this.setState({route_variant_null_run});

        this.recalcPath();
    }

    async clearPointsPaths() {
        let route_variant_null_run = this.state.route_variant_null_run;
        _.each(route_variant_null_run.points, (point) => {
            point.distance_to_the_next_point = 0;
            point.time_to_get_to_the_next_point = 0;
            point.path_to_the_next_point_geometry = null;
        });
        await this.setState({route_variant_null_run});
    }

    updatePointInfo(pointInfo) {
        let route_variant_null_run = this.state.route_variant_null_run;

        const createPointGeometry = (points) => {
            if (points && points.length > 0) {
                return {
                    type: 'LineString',
                    coordinates: _.map(points, (coord) => _.clone(coord).reverse()),
                };
            }

            return null;
        };

        route_variant_null_run.points[0].path_to_the_next_point_geometry = createPointGeometry(pointInfo.points);

        route_variant_null_run.points[0].distance_to_the_next_point = pointInfo.distance;
        route_variant_null_run.points[0].time_to_get_to_the_next_point = Math.round(pointInfo.time / 60);

        this.setValue('route_variant_null_run.time', Math.round(pointInfo.time / 60));
        this.setValue('route_variant_null_run.distance', pointInfo.distance);

        this.setState({
            route_variant_null_run,
        });
    }

    async recalcPath() {
        await this.clearPointsPaths();

        if (!this.state.route_variant_null_run.points[0].is_routing_enabled) {
            const points = this.state.route_variant_null_run.points;
            let distance = 0;
            for (let i = 1; i < points.length; i++) {
                const from = new L.latLng(points[i - 1].latitude, points[i - 1].longitude);
                const to = new L.latLng(points[i].latitude, points[i].longitude);
                distance += from.distanceTo(to);
            }
            this.updatePointInfo({
                points: _.map(points, ({latitude, longitude}) => ([
                    latitude,
                    longitude,
                ])),
                distance,
                time: 0,
                streets: [],
            });
        } else {
            const sector = _.map(this.state.route_variant_null_run.points, ({latitude, longitude}) => ({
                latitude,
                longitude,
            }));
            api.geo.routing(sector).then((response) => {
                if (response.success) {
                    this.updatePointInfo(response.payload);
                }
            }, () => {
                this.updatePointInfo({
                    points: [],
                    distance: 0,
                    time: 0,
                });
            });
        }
    }
}