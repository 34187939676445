import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defaultProps, propTypes} from 'react-props-decorators';
import {Text} from 'recharts';
import _ from 'lodash';
import moment from "moment";
import formats from "dictionaries/formats";

export default class DateTick extends Component {

    render() {
        const value = this.props.payload.value;
        const date = moment(value, formats.DATE);

        const isHoliday = date.day() === 0 || date.day() === 6;

        return (
            <Text {...this.props} style={{fill: isHoliday ? '#F65C50' : '#000'}}>{value}</Text>
        );
    }
}