import React from 'react';
import ReactDOMServer from 'react-dom/server';
import MapVehicleIcon from "components/ui/map-vehicle-icon";
import L from 'leaflet';

export default class VehicleIcon {

    static getIcon(vehicle, device, fast = false, color) {
        const type = 'bus';
        const html = ReactDOMServer.renderToStaticMarkup(<MapVehicleIcon device={{type}} direction={device.course} fast={fast} color={color} />);

        const isMotionlessMarker = device.speed === 0 && !device.isHistory;

        let className = `marker marker_type_${type}`;
        if (isMotionlessMarker) {
            className += ' marker_motionless';
        }
        const size = 46;
        const yAxis = isMotionlessMarker ? size + 10 : size / 2;

        return new L.DivIcon({
            html: html,
            className: className,
            iconSize: new L.Point(size, size),
            iconAnchor: new L.Point(size / 2, yAxis),
        });

    }
}
