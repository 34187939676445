import React, {Component} from 'react';
import L from 'leaflet';
import polylable from 'polylabel';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import ReactPopup from "react-leaflet-popup";
import _ from 'lodash';

@propTypes({
    map: PropTypes.oneOfType([
        PropTypes.object.isRequired,
        PropTypes.instanceOf(null)
    ]),
    leafletMap: PropTypes.oneOfType([
        PropTypes.object.isRequired,
        PropTypes.instanceOf(null)
    ]),
    geometry: PropTypes.object.isRequired,
    tooltip: PropTypes.string,
    label: PropTypes.string,
    interactive: PropTypes.bool,
    permanentTooltip: PropTypes.bool,
    onClick: PropTypes.func,
    popup: PropTypes.func,
    popupOffset: PropTypes.number,
    popupWidth: PropTypes.number,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    color: PropTypes.string,
    zIndex: PropTypes.number,
    opacity: PropTypes.number,
    weight: PropTypes.number,
    bringToBack: PropTypes.bool,
})

@defaultProps({
    tooltip: null,
    label: null,
    interactive: true,
    permanentTooltip: false,
    onClick: null,
    popup: null,
    popupOffset: -250,
    popupWidth: 350,
    color: 'blue',
    zIndex: 0,
    opacity: 0.5,
    weight: 3,
    bringToBack: false,
})

export default class MapGeojson extends Component {

    __layer = null;

    componentWillMount() {
        this.__layer = new L.GeoJSON(this.props.geometry, this.getOptions());

        if (this.props.onClick) {
            this.getLayer().on('click', this.props.onClick);
        }
        if (this.props.onMouseOver) {
            this.getLayer().on('mouseover', this.props.onMouseOver);
        }
        if (this.props.onMouseOut) {
            this.getLayer().on('mouseout', this.props.onMouseOut);
        }

        if (this.props.tooltip) {
            this.getLayer().bindTooltip(this.props.tooltip, {
                sticky: true,
                permanent: this.props.permanentTooltip,
            });
        }

        if (this.props.label) {
            const center = polylable(this.props.geometry.coordinates, 1.0);
            const polygonCenter = this.__layer.getBounds().getCenter();
            let centerNew = new L.LatLng(center[1], center[0]);
            if (this.props.label.includes('data-title=6f8d5c6a-f68b-11ea-8f8a-0252e77e7abb')) {
                centerNew = new L.LatLng(center[1] - 0.16, center[0] + 0.25);
            }
            this.getLayer().addLayer(new L.tooltip({
                permanent: true,
                direction: 'center',
                className: 'leaflet-tooltip-own'
            })
                .setContent(this.props.label)
                .setLatLng(centerNew));
        }

        if (this.props.popup && (this.props.onClick === null)) {
            this.getLayer().on('click', ::this.loadPopup)
        }
    }

    getOptions() {
        const self = this;
        return {
            zIndexOffset: this.props.zIndex,
            interactive: this.props.interactive,
            style: this.getStyle.bind(this, this.props),
            weight: this.props.weight,
            pointToLayer: function (feature, latlng) {
                return new L.circleMarker(latlng, {
                    radius: 8,
                    color: self.getColor(),
                    weight: 1,
                    opacity: 1,
                    fillOpacity: self.props ? self.props.opacity : 1,
                });
            },
        };
    }

    addLayer() {
        const addLayerInterval = setInterval(() => {
            if (this.props.leafletMap) {
                this.props.leafletMap.addLayer(this.getLayer());
                clearInterval(addLayerInterval)
            }
        }, 300);
    }

    componentDidMount() {
        this.addLayer();
        if (this.props.bringToBack) {
            this.getLayer().bringToBack();
        }
    }

    componentWillUnmount() {
        this.props.leafletMap.removeLayer(this.getLayer());
    }

    componentWillUpdate(props) {
        if (!_.isEqual(this.props.geometry, props.geometry)) {
            const layer = this.getLayer();
            layer.clearLayers();
            layer.addData(props.geometry);
        }
        if (props.onClick !== this.props.onClick) {
            this.props.onClick && this.getLayer().off('click', this.props.onClick);
            props.onClick && this.getLayer().on('click', props.onClick);
        }
        this.getLayer().setStyle(this.getStyle.bind(this, props));
        if (this.getLayer().getPopup()) {
            this.getLayer().getPopup().update();
        }
    }

    getStyle(props = null) {
        return {
            color: this.getColor(props),
            opacity: this.props.opacity ? this.props.opacity : 0.6,
            fillOpacity: 0.4,
            strokeWidth: 10,
        };
    }

    getColor(props) {
        const color = props ? props.color : this.props.color;
        switch (color) {
            case 'blue':
                return '#0000FF';
            case 'gray':
                return '#333';
            case 'green':
                return '#00FF00';
            case 'red':
                return '#FF0000';
            default:
                return color || '#FF0000';
        }
    }

    getLayer() {
        return this.__layer;
    }

    getWrapper() {
        return this.__wrapper;
    }

    render() {
        return null;
    }

    loadPopup() {
        const layer = this.getLayer();
        layer.unbindPopup();
        layer.closePopup();
        layer.bindPopup(
            this.createPopup(::layer.closePopup)
        );
        layer.openPopup();
    }

    createPopup(onClose) {
        return new ReactPopup({
            reactComponent: Popup,
            reactComponentProps: {
                ...this.props,
                onClose
            },
            minWidth: this.props.popupWidth ? this.props.popupWidth : 434,
            maxWidth: 434,
            offset: new L.Point(3, this.props.popupOffset),
        });
    }
}

class Popup extends Component {
    render() {
        return this.props.popup(this.props.onClose);
    }
}