import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import _ from 'lodash';

import Editor from './editor';
import {Link} from "react-router";

import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import Column from "components/ui/column";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import {deleteStopPoint, getStopPoints} from "store/reducers/geo/stop-points";
import systems from "dictionaries/systems";
import Settings from "settings";

@connect(state => ({}), {getStopPoints, deleteStopPoint, getEntityNames})

export default class StopPointsComponent extends BaseTableWithEditorComponent {

    sources = {
        mta: 'МТА',
        mta2: 'МТА 2',
        inventarisation_active: 'Инвентаризация',
        children: 'Перевозка детей',
    };

    showAudit(rowData) {
        this.props.router.push(`/system/audit/${rowData.uuid}?class=App\\Model\\StopPoint`)
    }

    getTitle() {
        const lastSync = Settings.get('inventarisation_import', '-');
        return <span>{systems[this.props.params.component]} → <Link to={`/${this.props.params.component}/dictionaries`}>Справочники</Link> → Остановки (Синхронизировано: {lastSync})</span>;
    }

    getBaseUrl() {
        return `/${this.props.params.component}/stop-points`;
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Наименование')
                .fromField('title'),

            new Column('Регистрационный номер')
                .fromField('register_number'),

            new Column('Пропускная способность')
                .fromField('throughput'),

            new Column('Организация-владелец')
                .fromField('owner_department_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.owner_department_uuid ? this.state.related.get(item.owner_department_uuid) : '<code>-не задано-</code>'),

            new Column('Источник')
                .fromField('source')
                .denyColumnFilter()
                .withDrawer(item => this.sources[item.source])
                .withFilter('withSources', async () => {
                    return _.map(this.sources, (name, uuid) => ({
                        uuid,
                        name,
                    }));
                }),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteStopPoint(data);
    }

    async loadData(meta) {
        meta.filters.withSource = null;
        meta.filters.withSources = meta.filters.withSources || ((_.indexOf(['kiutr', 'ems'], this.props.params.component) !== -1) ? [
            'mta',
            'mta2',
            'inventarisation',
            'inventarisation_active',
        ] : [
            'mta',
            'mta2',
            'inventarisation',
            'inventarisation_active',
            'children',
        ]);
        return await this.props.getStopPoints(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const units = _.filter(_.map(result, 'owner_department_uuid'));
        const response = await this.props.getEntityNames(_.map(units, (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        })));
        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}