import React, {Component} from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapLayer from "components/ui/map/base/layer";
import {connect} from "react-redux";
import {getStopPointsWithBounds} from "store/reducers/routes/routes";
import StopPointMarker from "components/ui/map/markers/stop-point-marker";

@propTypes({
    onStopPointDblClick: PropTypes.func,
})

@defaultProps({
    onStopPointDblClick: () => {
    },
})

@connect((state) => ({}), {getStopPointsWithBounds})

export default class StopPointsInventarisationLayer extends AbstractMapLayer {

    state = {
        stopPoints: [],
    };

    loadStopPointsCallback = ::this.loadStopPoints;

    componentWillMount() {
        super.componentWillMount();

        this.props.leafletMap.on('moveend', this.loadStopPointsCallback);

        this.loadStopPoints();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.props.leafletMap.off('moveend', this.loadStopPointsCallback);
    }

    async loadStopPoints() {
        const boundingBox = this.props.map.getBoundingBox();
        const zoom = this.props.map.getZoom();
        const response = await this.props.getStopPointsWithBounds(boundingBox, zoom, 'inventarisation');

        if (response.isOk) {
            this.setState({
                stopPoints: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        return (
            <div style={{display: 'none'}}>
                {this.state.stopPoints.map(::this.renderStopPoint)}
            </div>
        );
    }

    renderStopPoint(stopPoint) {
        return (
            <StopPointMarker
                key={stopPoint.uuid}
                {...this.props}
                latitude={stopPoint.latitude}
                longitude={stopPoint.longitude}
                item={stopPoint}
                onDblClick={this.props.onStopPointDblClick.bind(this, stopPoint.uuid, stopPoint.latitude, stopPoint.longitude)}
                color="red"
            />
        );
    }

}