import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _, { intersection } from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import {DefectTypes} from "dictionaries/kiutr";
import {
    createDefect, getOrder, getOrderRecalc, updateDefect, updateOrder,
    updateOrderRecalc
} from "store/reducers/kiutr/orders/orders";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import GlobalLoaderComponent from "components/ui/global-loader";
import PageModal from 'components/ui/page-modal';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import TableContainer from "components/ui/Table/Container/TableContainer";
import runs from "dictionaries/runs";
import moment from "moment";
import formats from "dictionaries/formats";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import Input from "components/ui/form/input";
import * as alerts from "helpers/alerts";
import uuidv1 from 'uuid/v1';
import {getRouteVariant, getRouteVariants} from "store/reducers/routes/route_variants";
import {getDefaultVariant} from "helpers/kiutr";
import {getStopPoints} from "store/reducers/geo/stop-points";
import {getIntervalMaps} from "store/reducers/routes/interval_maps";
import ProductionRunEditor
    from "components/modules/kiutr/orders/OrderOperationalForm/OrderEditor/ProductionRunEditor/index";
import OrderCloseSummary from "components/modules/kiutr/orders/OrderOperationalForm/OrderClose/OrderCloseSummary/index";
import {
    getOrderExecutionRecalc, getOrderExecutions,
    recalcOrderExecution
} from "store/reducers/kiutr/orders/order_executions";
import LoaderComponent from "components/ui/loader";
import OrderCloseRuns from "components/modules/kiutr/orders/OrderOperationalForm/OrderClose/OrderCloseRuns/index";
import {getHistory, getHistoryByVehicle} from "store/reducers/maps";
import TelematicsDevice from "components/modules/maps/telematics-packet";
import ShiftMap from "components/modules/kiutr/transport-work/dispatcher/ShiftMapForm/ShiftMap/index";
import OrderCloseRunsResources
    from "components/modules/kiutr/orders/OrderOperationalForm/OrderClose/OrderCloseRunsResources/index";

import './index.less';
import {getUserCan} from "store/reducers/staffing/staffing";
import currentUser from 'helpers/current-user';
import { checkLimitationInstallationMidnight } from "helpers/functions";

@propTypes({
    order: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    readonly: PropTypes.bool,
})

@connect(state => ({}), {
    getEntityNames,
    getOrderRecalc,
    updateOrderRecalc,
    getRouteVariants,
    getRouteVariant,
    getStopPoints,
    getOrderExecutions,
    recalcOrderExecution,
    getOrderExecutionRecalc,
    getDictionaryList,
    getHistory,
    getOrder,
    updateOrder,
    getUserCan,
})

export default class OrderClose extends Component {

    state = {
        saving: false,
        percentToCheckSemimanual: null,
        order: {},
        order_recalc: {},
        order_executions: [],
        defaultStopPoints: {},
        related: new EntityList,
        recalc_in_progress: {},
        route_variants: {},
        routeVariant: {},
        stop_points: {},
        stopPointsObjects: {},
        turn_start: null,
        run_check_reasons: [],
        stop_point_check_reasons: [],
        history: null,
        resourcesOpened: false,
        order_is_in_recalc: false,
        routeVisible: true,
        canManualEdit: false,
        snakeDalay: true,
    };

    async componentWillMount() {
        this.getPercentSemiManualFromBackend();

        await this.setState({
            order: _.cloneDeep(this.props.order),
            order_is_in_recalc: this.props.order.is_in_recalc,
        });

        this.checkCanManualEdit();

        this.checkRecalc();

        this.loadDictionaries([
            'run_check_reasons',
            'run_uncheck_reasons',
            'stop_point_check_reasons',
            'stop_point_uncheck_reasons',
        ]);

        this.loadOrderExecutions();
        await this.setState({
            order_recalc: _.cloneDeep(this.props.order.order_recalc),
            turn_start: moment(_.get(_.first(_.get(_.first(this.props.order.order_recalc.shifts), 'runs', [])), 'date_from')).format(formats.TIME),
        });
        this.loadRelatedEntities();
        this.loadRouteVariants();
    }

    componentDidMount() {
        let snakeDelaySetting = currentUser.user.free_user_settings.snakeDalay;
        if (snakeDelaySetting > 0) {
            let self = this;
            setTimeout(() => {
                self.setState({snakeDalay: false});
            }, snakeDelaySetting);
        } else {
            this.setState({snakeDalay: false});
        }
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    async getPercentSemiManualFromBackend() {
        const percent = JSON.parse(localStorage.getItem("options"))?.value?.__response?.payload?.items?.global?.run_passed_threshold_for_stop_points;
        await this.setState({
            percentToCheckSemimanual:  parseInt(percent)
        })
    }

    async checkCanManualEdit() {
        const response = await this.props.getUserCan('com.rnis.geo.order.manual', 'update', this.state.order.carrier_uuid);

        if (response.isOk) {
            this.setState({
                canManualEdit: response.payload.result,
            });
        } else {
            response.showErrors();
        }
    }

    async loadRouteVariants() {
        const runs = _.flatten(_.map(this.state.order.shifts, 'runs'));
        const response = await this.props.getRouteVariants({
            filters: {
                withRoute: _.uniq(_.filter(_.map(runs, 'route_uuid'))),
                withTrashed: true,
            },
        });

        if (response.isOk) {
            const route_variants = response.payload.items;
            this.setState({
                route_variants: _.keyBy(route_variants, 'uuid'),
            });

            this.loadStopPoints(_.flatten(_.concat(_.map(route_variants, 'forward_points'), _.map(route_variants, 'reverse_points'))));
        } else {
            response.showErrors();
        }
    }

    async loadStopPoints(points) {
        const meta = {
            filters: {
                withUuid: _.uniq(_.filter(_.map(points, 'type_uuid')))
            },
            response_data: [
                'items/uuid',
                'items/title',
            ],
        };

        const response = await this.props.getStopPoints(meta);
        if (response.isOk) {
            window._stop_points =  _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'title') //fixme для теста кидаю через window ибо вложенность компонентов не проследить
            this.setState({
                stop_points: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'title'),
            });
        } else {
            response.showErrors();
            return null;
        }
    }

    async loadOrderExecutions() {
        const response = await this.props.getOrderExecutions({
            filters: {
                withOrders: [
                    this.state.order.uuid,
                ],
            },
        });

        if (response.isOk) {
            this.setState({
                order_executions: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadOrderRecalc() {
        const response = await this.props.getOrderRecalc(this.state.order.uuid);

        if (response.isOk) {
            this.setState({
                order_recalc: response.payload,
                turn_start: moment(_.get(_.first(_.get(_.first(response.payload.shifts), 'runs', [])), 'date_from')).format(formats.TIME),
            });
        } else {
            response.showErrors();
        }
    }

    async loadRelatedEntities() {
        const runs = _.flatten(_.map(this.state.order_recalc.shifts, 'runs'));

        const vehicles = _.map(_.uniq(_.filter(_.map(runs, 'vehicle_uuid'))), (uuid) => ({
            class: 'App\\Model\\Vehicle',
            uuid: uuid,
            source: 'vehicles',
        }));

        const drivers = _.map(_.uniq(_.filter(_.map(runs, 'driver_uuid'))), (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));

        const routes = _.map(_.uniq(_.filter(_.map(runs, 'route_uuid'))), (uuid) => ({
            class: 'App\\Model\\Route',
            uuid: uuid,
            source: 'geo',
        }));

        const response = await this.props.getEntityNames(_.concat(vehicles, drivers, routes));

        if (response.isOk) {
            this.state.related.add(response);
        }
    }

    async recalc() {
        if (currentUser.user.free_user_settings.recalcDelay) {
            alerts.success('ЗАЯВКА ПРИНЯТА, при подтверждении проблемы приема телематики Ваша заявка будет выполнена');
        } else {
            alerts.prompt('При пересчете все ручные зачеты будут сброшены. Продолжить?', '', async () => {
                const response = await this.props.recalcOrderExecution({
                    order_uuid: this.state.order.uuid,
                });

                if (response.isOk) {
                    this.props.reload();
                    await this.setState({
                        recalc_in_progress: response.payload,
                        order_is_in_recalc: true,
                    });
                    setTimeout(::this.reloadRecalcInProgress, 5000);
                } else {
                    response.showErrors();
                }
            }, 'Продолжить');
        }
    }

    async checkRecalc() {
        const response = await this.props.getOrderExecutionRecalc({order_uuid: this.state.order.uuid});

        if (response.isOk) {
            this.setState({
                recalc_in_progress: response.payload,
                order_is_in_recalc: true,
            });
            setTimeout(::this.reloadRecalcInProgress, 5000);
        }
    }

    async reloadRecalcInProgress() {
        const response = await this.props.getOrderExecutionRecalc({uuid: this.state.recalc_in_progress.uuid});

        if (response.isOk) {
            if (response.payload.finished_at) {
                this.setState({
                    recalc_in_progress: {},
                    order_is_in_recalc: false,
                });

                this.loadOrderExecutions();
                await this.loadOrderRecalc();
                this.loadRelatedEntities();
                this.loadRouteVariants();
            } else {
                await this.setState({
                    recalc_in_progress: response.payload,
                });
                setTimeout(::this.reloadRecalcInProgress, 5000);
            }
        }
    }

    async resetToDefault() {
        let order_recalc = this.state.order_recalc;
        order_recalc.shifts = this.state.order.shifts;

        this.startSave();
        const response = await this.props.updateOrderRecalc(order_recalc);
        this.endSave();

        if (response.isOk) {
            this.setState({
                order_recalc: response.payload,
            });
        } else {
            response.showErrors();
        }
    }


    async save() {
        console.info("Уходит на сервер: ", this.state.order_recalc.shifts[0].runs.filter(item => item?.case))
        if (this.state.order_recalc.shifts[0].runs.filter(run => run.time < 0).length) {
            alerts.error('Задана некорретная длительность!');
            return;
        }
        this.startSave();
        const response = await this.props.updateOrderRecalc(this.state.order_recalc);
        this.endSave();

        if (response.isOk) {
            this.setState({
                order_recalc: response.payload,
                saveState: true,
            });
            this.props.reload();
        } else {
            response.showErrors();
            this.setState({
                saveState: false,
            });
        }
    }

    renderSaveState() {
        if (this.state.saveState === true) {
            return (
                <div className="changes changes-in-order changes-success">Изменения сохранены</div>
            );
        }
        if (this.state.saveState === false) {
            return (
                <div className="changes changes-in-order changes-fail">Изменения не сохранены</div>
            );
        }

        return null;
    }

    async closeOrder() {
        alerts.prompt('Закрыть план-наряд?', '', async () => {
            let order = this.state.order;
            const logs = (await this.props.getOrder(order.uuid)).payload.logs

            order = {
                ...order,
                logs: logs.length ? logs : order.logs,
                processing_status:'closed'
            }

            this.startSave();
            const response = await this.props.updateOrder(order);
            this.endSave();

            if (response.isOk) {
                this.props.onSubmit();
                let h = window.location.href;
                if (/kiutr/i.test(h)) {
                    this.props.router.push('/kiutr/orders');
                }
                if (/children/i.test(h)) {
                    this.props.router.push('/children/orders');
                }
            } else {
                this.setState({
                    errors: response.validationErrors
                });
                alerts.error(Object.values(response.errors[0].data))
                response.showErrors();
            }
        }, 'Подтвердить');
    }

    render() {
        const loader = (this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        const form = this.renderForm();

        const buttons = (
            <ModalTopMenuButtons>
                {this.renderSaveState()}
                {(!this.props.readonly) ? ([
                    <ModalTopMenuList className="top-menu_modal_edit" key="list">
                        {_.isEmpty(this.state.recalc_in_progress) ? (
                            <ContextTooltip key="order.close.recalc" code="order.close.recalc"
                                            default="Пересчитать выход">
                                <ModalTopMenuListItem
                                    className="b-icon-link_icon_recount"
                                    onClick={::this.recalc}
                                />
                            </ContextTooltip>
                        ) : (
                            <li className="top-menu_modal__item">
                                <LoaderComponent color="red"/>
                            </li>
                        )}

                        {(!this.state.order_is_in_recalc) ? (
                            <ContextTooltip key="order.close.reset" code="order.close.reset"
                                            default="Вернуть оперплан">
                                <ModalTopMenuListItem
                                    className="b-icon-link_icon_return"
                                    onClick={::this.resetToDefault}
                                />
                            </ContextTooltip>
                        ) : null}

                        {(!this.state.order_is_in_recalc) ? (
                            <ContextTooltip key="order.close.resources" code="order.close.resources"
                                            default="Переопределить ресурсы">
                                <ModalTopMenuListItem
                                    className="b-icon-link_icon_order-resources"
                                    onClick={::this.openResources}
                                />
                            </ContextTooltip>
                        ) : null}

                        {(!this.state.order_is_in_recalc) ? (
                            <ContextTooltip key="order.close.submit" code="order.close.submit"
                                            default="Закрыть наряд">
                                <ModalTopMenuListItem
                                    className="b-icon-link_icon_order-close"
                                    onClick={::this.closeOrder}
                                />
                            </ContextTooltip>
                        ) : null}

                        <ModalTopMenuListSeparator key="separator"/>
                    </ModalTopMenuList>,

                    (this.state.order_recalc.uuid && !this.state.order_is_in_recalc) ? (
                        <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                            <ModalTopMenuButton
                                key="save"
                                className="_save"
                                title="Сохранить"
                                onClick={::this.save}
                            />
                        </ContextTooltip>
                    ) : null,

                    <ModalTopMenuButtonsSeparator key="separator"/>,
                ]) : null}

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        const titleDate = this.state.order.processing_status === 'ended' || this.state.order.processing_status === 'closed' ? moment(this.state.order.date).format(formats.DATE) : ''

        return (
            <div>
                <PageModal
                    header={{
                        title: `${this.props.readonly ? 'Результаты выполнения наряда' : 'Закрытие наряда'} №${this.state.order.number}${this.state.order_is_in_recalc ? ' (находится в очереди ручного пересчета)' : ''} ${titleDate}`,
                        buttons
                    }}
                    onClose={this.props.onClose}
                    className={`orders-modal-close b-modal_stretch`}
                    withFade={false}
                    disableOutsideClick={false}
                >
                    {loader}
                    {form}
                </PageModal>

                {this.state.history ? (
                    this.renderMapModal()
                ) : null}

                {this.state.resourcesOpened ? (
                    <OrderCloseRunsResources
                        params={this.props.params}
                        order={this.state.order_recalc}
                        onSubmit={this.closeResources.bind(this, true)}
                        onClose={this.closeResources.bind(this, false)}
                    />
                ) : null}
            </div>
        );
    }

    openResources() {

        if (checkLimitationInstallationMidnight( this.state.order.date)) {
            alerts.alert(`Данное действие невозможно после ${moment(_.get(this.state.item, 'date')).format(formats.DATE)} 23:59`);
            return;
        }

        this.setState({
            resourcesOpened: true,
        });
    }

    async closeResources(reload) {
        this.setState({
            resourcesOpened: false,
        });
        if (reload) {
            await this.loadOrderRecalc();
            this.loadRelatedEntities();
        }
    }

    async loadOrder() {
        const response = await this.props.getOrder(this.state.order.uuid);

        if (response.isOk) {
            this.setState({
                order: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    renderForm() {
        return (
            <div>
                <div className="b-modal__block">
                    <OrderCloseSummary
                        order={this.state.order}
                        order_recalc={this.state.order_recalc}
                        related={this.state.related}
                        order_executions={this.state.order_executions}
                    />
                </div>
                <Accordion className="with-menu" style={{display: this.state.snakeDalay}}>
                    {this.state.snakeDalay ?
                        <p className="accent" style={{"text-align": "center", "padding": "30px"}}>Вы используете бесплатную версию
                            приложения</p> : this.renderRuns()}
                </Accordion>
            </div>
        );
    }

    renderRuns() {
        setTimeout(function () {
            return result;
        }, 5000);
        const runs = _.flatten(_.map(this.state.order_recalc.shifts, 'runs'));

        if (runs.length === 0) {
            return;
        }

        let lastVehicle = _.first(runs).vehicle_uuid;
        let lastDriver = _.first(runs).driver_uuid;

        let isFirst = true;
        let result = [];
        let part = [];
        for (let i = 0; i < runs.length; i++) {
            const run = runs[i];
            const currentVehicle = run.vehicle_uuid;
            const currentDriver = run.driver_uuid;

            if ((currentVehicle === lastVehicle) && (currentDriver === lastDriver)) {
                part.push(run);
            } else {
                result.push(
                    <OrderCloseRuns
                        key={i}
                        isFirst={isFirst}
                        order={this.state.order_recalc}
                        order_executions={this.state.order_executions}
                        related={this.state.related}
                        route_variants={this.state.route_variants}
                        stop_points={this.state.stop_points}
                        onChangeRunTime={::this.onChangeRunTime}
                        onRunCheckToggle={::this.onRunCheckToggle}
                        onRunCheckReasonChange={::this.onRunCheckReasonChange}
                        onStopPointCheckToggle={::this.onStopPointCheckToggle}
                        onStopPointRegularToggle={::this.onStopPointRegularToggle}
                        onStopPointCheckReasonChange={::this.onStopPointCheckReasonChange}
                        run_check_reasons={this.state.run_check_reasons}
                        run_uncheck_reasons={this.state.run_uncheck_reasons}
                        stop_point_check_reasons={this.state.stop_point_check_reasons}
                        stop_point_uncheck_reasons={this.state.stop_point_uncheck_reasons}
                        onMapOpen={::this.onMapOpen}
                        runs={part}
                        turn_start={this.state.turn_start}
                        onChangeTurnStart={::this.onChangeTurnStart}
                        readonly={this.props.readonly || this.state.order_is_in_recalc}
                        canManualEdit={this.state.canManualEdit}
                    />
                );
                isFirst = false;
                lastVehicle = currentVehicle;
                lastDriver = currentDriver;
                part = [];
                part.push(run);
            }
        }
        if (part.length > 0) {
            result.push(
                <OrderCloseRuns
                    key="total"
                    isFirst={isFirst}
                    order={this.state.order_recalc}
                    order_executions={this.state.order_executions}
                    related={this.state.related}
                    route_variants={this.state.route_variants}
                    stop_points={this.state.stop_points}
                    onChangeRunTime={::this.onChangeRunTime}
                    onRunCheckToggle={::this.onRunCheckToggle}
                    onRunCheckReasonChange={::this.onRunCheckReasonChange}
                    onStopPointCheckToggle={::this.onStopPointCheckToggle}
                    onStopPointRegularToggle={::this.onStopPointRegularToggle}
                    onStopPointCheckReasonChange={::this.onStopPointCheckReasonChange}
                    run_check_reasons={this.state.run_check_reasons}
                    run_uncheck_reasons={this.state.run_uncheck_reasons}
                    stop_point_check_reasons={this.state.stop_point_check_reasons}
                    onMapOpen={::this.onMapOpen}
                    runs={part}
                    turn_start={this.state.turn_start}
                    onChangeTurnStart={::this.onChangeTurnStart}
                    canManualEdit={this.state.canManualEdit}
                    readonly={this.props.readonly || this.state.order_is_in_recalc}
                />
            );
        }

        return result;
    }

    async onChangeRunTime(run, value) {
        let order_recalc = this.state.order_recalc;
        let runs = _.flatten(_.map(order_recalc.shifts, 'runs'));
        const index = _.findIndex(runs, {uuid: run.uuid});
        runs[index].time = value;

        await this.setState({
            order_recalc,
        });

        this.recalcTimes();
    }

    onRunCheckToggle(run) {
        let order_recalc = this.state.order_recalc;
        let runs = _.flatten(_.map(order_recalc.shifts, 'runs'));
        const index = _.findIndex(runs, {uuid: run.uuid});

        runs[index].is_checked = !runs[index].is_checked
        runs[index].run_check_reason_uuid = null;

        this.setState({
            order_recalc,
        });
    }

    onRunCheckReasonChange(run, value) {
        let order_recalc = this.state.order_recalc;
        let runs = _.flatten(_.map(order_recalc.shifts, 'runs'));
        const index = _.findIndex(runs, {uuid: run.uuid});
        runs[index].run_check_reason_uuid = value;

        this.setState({
            order_recalc,
        });
    }

    casesForRun(runs, stopPoints, runIndex, run) {
        const checkedRedLength = runs[runIndex].stop_point_checks.filter(item => item.is_checked && !item.in_time && item.overshoot === false).length;
        const regularRedLength = runs[runIndex].stop_point_checks.filter(item => item.is_checked && !item.in_time && item.is_regular).length;
        runs[runIndex]["case"] = "";
        const save = "manual";
        //всего зачтеных: желтых/зеленных + красных с чекбоксом зачтен
        const isCheckedLength = run.points.filter(item => item.in_time || item.overshoot ).length + checkedRedLength;
        const greenAndYellowStopPoints = run.points.filter(item => item.in_time || item.overshoot ).length;

        //только зеленные остановки в run
        const isCheckedGreenLength = run.points.filter(item => item.in_time).length;
        //только зеленные остановки в run
        const isCheckedYellowLength = run.points.filter(item => item.overshoot).length;
        //только те остановки, у которых и зачтен и регуляр стоит чекбокс
        const isPassAndRegularLength = run.stop_point_checks.filter(item => item.is_checked && item.is_regular).length;

        const stopPointsLength = stopPoints.length;
        const calculatePercentOfChecked = Math.round((isCheckedLength * 100) / stopPointsLength);

        // кейс-1: красные остановки и все зачет
        if (checkedRedLength === stopPointsLength && greenAndYellowStopPoints === 0) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case1:true"
            runs[runIndex].check_type = 'semimanual';
            runs[runIndex].is_checked = false;
            run.check_type = 'semimanual';
            run.is_checked = false;
        } else if (greenAndYellowStopPoints === 0) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case1:false"
            delete runs[runIndex].check_type;
        }

        // кейс-2: красные остановки и все зачет + регулярность
        if (checkedRedLength === stopPointsLength && regularRedLength === stopPointsLength && greenAndYellowStopPoints === 0) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case2:true"
            runs[runIndex].check_type = 'semimanual';
            runs[runIndex].is_checked = true;
            run.check_type = 'semimanual';
            run.is_checked = true;
            return true;
        }

        // кейс-3: зеленные остановки и 1 красная с зачетом
        if (isCheckedGreenLength > 0 && isCheckedGreenLength + checkedRedLength === stopPointsLength) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case3:true"
            runs[runIndex].check_type = 'semimanual';
            runs[runIndex].is_checked = false;
            run.check_type = 'semimanual';
            run.is_checked = false;
        } else if (isCheckedGreenLength > 0 && isCheckedGreenLength + checkedRedLength < stopPointsLength && isCheckedYellowLength === 0) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case3:false"
            delete runs[runIndex].check_type;
        }

        // кейс-4: зеленные остановки и 2 красных с зачетом и регулярностью
        if (isPassAndRegularLength + isCheckedGreenLength === stopPointsLength && isCheckedGreenLength > 0) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case4:true"
            runs[runIndex].check_type = 'semimanual';
            runs[runIndex].is_checked = true;
            run.check_type = 'semimanual';
            run.is_checked = true;
        }

        // кейс-5: все желтые остановки и 1 красная с зачетом
        if (isCheckedYellowLength > 0 && isCheckedYellowLength + checkedRedLength === stopPointsLength) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case5:true"
            runs[runIndex].check_type = 'semimanual';
            runs[runIndex].is_checked = false;
            run.check_type = 'semimanual';
            run.is_checked = false;
        } else if (isCheckedYellowLength > 0 && isCheckedYellowLength + checkedRedLength < stopPointsLength && isCheckedGreenLength === 0) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case5:false"
            delete runs[runIndex].check_type;
        }

        // кейс-6:
        if (isCheckedYellowLength + isPassAndRegularLength === stopPointsLength) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case6:true"
            runs[runIndex].check_type = 'semimanual';
            runs[runIndex].is_checked = false;
            run.check_type = 'semimanual';
            run.is_checked = false;
        }

        // кейс-7: желтые + зеленные + красные с зачетом
        if (isCheckedYellowLength > 0 && isCheckedGreenLength > 0 && isCheckedYellowLength + isCheckedGreenLength + checkedRedLength === stopPointsLength) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case7:true"
            runs[runIndex].check_type = 'semimanual';
            runs[runIndex].is_checked = false;
            run.check_type = 'semimanual';
            run.is_checked = false;
        } else if (isCheckedYellowLength > 0 && isCheckedGreenLength > 0 && isCheckedYellowLength + isCheckedGreenLength + checkedRedLength < stopPointsLength) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case7:false"
            delete runs[runIndex].check_type;
        }

        // кейс-8: желтые + зеленные + красные с зачетом и регулярностью
        if (isCheckedYellowLength > 0 && isCheckedYellowLength + isCheckedGreenLength + isPassAndRegularLength === stopPointsLength) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case8:true"
            runs[runIndex].check_type = 'semimanual';
            runs[runIndex].is_checked = false;
            run.check_type = 'semimanual';
            run.is_checked = false;
        }

        // кейс-9: все зеленные остановки + все желтые остановки + все красные остановки с признаком Зачтен
        // если сумма всех остановок в % больше или равно % с сервера - то признак semimanual
        if (calculatePercentOfChecked >= this.state.percentToCheckSemimanual && isCheckedGreenLength > 0 && isCheckedYellowLength > 0) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case9:true"
            runs[runIndex].check_type = 'semimanual';
            run.check_type = 'semimanual';
        }

        // кейс-10 все остановки красные и никаких признаков нет
        if (greenAndYellowStopPoints === 0 && checkedRedLength === 0) {
            runs[runIndex]["case"] = runs[runIndex]["case"] + "case10:true"
            runs[runIndex].check_type = save;
            runs[runIndex].is_checked = false;
            run.check_type = save;
            run.is_checked = false;
        }
    }

    onStopPointCheckToggle(run, index) {
        let order_recalc = {...this.state.order_recalc};

        let runs = _.flatten(_.map(order_recalc.shifts, 'runs'));
        const runIndex = _.findIndex(runs, {uuid: run.uuid});
        const checkIndex = _.findIndex(runs[runIndex].stop_point_checks, {index});

        if (checkIndex === -1) {
            const stopPointCheck = {
                index,
                is_checked: true,
                check_type: 'manual',
                stop_point_check_reason_uuid: null,
            };
            if (runs[runIndex].stop_point_checks && runs[runIndex].stop_point_checks.length) {
                runs[runIndex].stop_point_checks.push(stopPointCheck);
            } else {
                runs[runIndex].stop_point_checks = [stopPointCheck];
            }
        } else {
            runs[runIndex].stop_point_checks[checkIndex].is_checked = !runs[runIndex].stop_point_checks[checkIndex].is_checked;
            runs[runIndex].stop_point_checks[checkIndex].check_type = 'manual';
            if (!runs[runIndex].stop_point_checks[checkIndex].is_checked) {
                runs[runIndex].stop_point_checks[checkIndex].stop_point_check_reason_uuid = null;
            }
        }

        if (runs[runIndex].check_type !== 'auto') {
            runs[runIndex].is_checked = false;

            let updatedRun = runs[runIndex];
            const stopPoints = _.filter(updatedRun.points, {point_type: 'stop_point'});

            // кусок кода для зачета по контрольным пунктам
            if ((_.filter(stopPoints, {is_control: true}).length > 0) && (_.filter(stopPoints, (stopPoint, stopPointIndex) => {
                const isChecked = _.find(updatedRun.stop_point_checks, {index: stopPointIndex, is_checked: true});
                return stopPoint.is_control && !isChecked;
            }).length === 0)) {
                runs[runIndex].check_type = 'semimanual';
             }

            run.points.forEach( (item, index) => {
                const current = runs[runIndex].stop_point_checks.filter(item => item.index === index)[0];
                if (current) {
                    current["overshoot"] = item.overshoot
                }
            });
            this.casesForRun(runs, stopPoints, runIndex, run);
            if (!runs[runIndex].is_checked) {
                runs[runIndex].run_check_reason_uuid = null;
            }
        }

        this.setState({
            order_recalc,
        });
    }

    onStopPointRegularToggle(run, index) {
        let order_recalc = this.state.order_recalc;
        let runs = _.flatten(_.map(order_recalc.shifts, 'runs'));
        const runIndex = _.findIndex(runs, {uuid: run.uuid});
        const checkIndex = _.findIndex(runs[runIndex].stop_point_checks, {index});

        const updatedRun = runs[runIndex];
        const stopPoints = _.filter(updatedRun.points, {point_type: 'stop_point'});
        

        if (checkIndex === -1) {
            runs[runIndex].stop_point_checks.push({
                index,
                is_regular: true,
            });
        } else {
            runs[runIndex].stop_point_checks[checkIndex].is_regular = !runs[runIndex].stop_point_checks[checkIndex].is_regular;
        }

        run.points.forEach( (item, index) => {
            const current = runs[runIndex].stop_point_checks.filter(item => item.index === index)[0];
            if (current) {
                current["overshoot"] = item.overshoot
            }
        });
        this.casesForRun(runs, stopPoints, runIndex, run);

        this.setState({
            order_recalc,
        });
    }

    onStopPointCheckReasonChange(run, index, value) {
        let order_recalc = this.state.order_recalc;

        let runs = _.flatten(_.map(order_recalc.shifts, 'runs'));
        const runIndex = _.findIndex(runs, {uuid: run.uuid});
        const checkIndex = _.findIndex(runs[runIndex].stop_point_checks, {index});
        if (checkIndex === -1) {
            runs[runIndex].stop_point_checks.push({
                index,
                is_checked: false,
                check_type: 'manual',
                stop_point_check_reason_uuid: value,
            });
        } else {
            runs[runIndex].stop_point_checks[checkIndex].stop_point_check_reason_uuid = value;

        }

        this.setState({
            order_recalc,
        });
    }

    async onChangeTurnStart({target: {value}}) {
        await this.setState({
            turn_start: value,
        });

        this.recalcTimes();
    }

    recalcTimes() {
        let order_recalc = this.state.order_recalc;

        const firstRun = _.first(_.get(_.first(this.state.order_recalc.shifts), 'runs', []));

        let time = moment(moment(firstRun.date_from).format(formats.DATE) + ' ' + this.state.turn_start, formats.DATETIME_SHORT);

        _.each(order_recalc.shifts, (shift) => {
            _.each(shift.runs, (run) => {
                run.date_from = time.format(formats.DATETIME_API);
                time = time.add(run.time, 'minutes');
                run.date_to = time.format(formats.DATETIME_API);
            });
        });

        this.setState({
            order_recalc,
        });
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }

    async onMapOpen(run) {
        this.startSave();
        const history = await this.loadHistory(run.vehicle_bnso, run.date_from, run.date_to);
        this.endSave();
        const routeVariant = _.find(this.state.route_variants, {
            uuid: run.route_variant_uuid,
        }) || {};
        routeVariant.type = run.type;
        const points = _.get(routeVariant, (routeVariant.type === 'production_forward') ? 'forward_points' : 'reverse_points');
        const stopPointsObjects = await this.fetchStopPoints(_.filter(_.uniq(_.map(points, 'type_uuid'))));
        this.endSave();
        this.setState({
            history,
            routeVariant,
            stopPointsObjects,
        });
    }

    async closeMap() {
        this.setState({
            history: null,
        });
    }

    async loadHistory(device, from, to) {
        const response = await this.props.getHistory([device], from, to);

        if (response.isOk) {
            const telematics = _.first(response.payload.telematics);
            if (telematics) {
                return _.map(telematics.points, (point) => {
                    return new TelematicsDevice(telematics.device_id, point);
                });
            } else {
                return [];
            }
        } else {
            response.showErrors();
            return [];
        }
    }

    async fetchStopPoints(uuids) {
        const response = await this.props.getStopPoints({
            filters: {
                withUuid: uuids,
            },
        });
        if (response.isOk) {

            return _.keyBy(response.payload.items, 'uuid');

        } else {
            response.showErrors();
        }
    }

    toggleRoute = () => {
        this.setState({
            routeVisible: !this.state.routeVisible,
        });
    }

    renderMapModal() {
        const title = 'Рейс на карте';

        const buttons = (
            <ModalTopMenuList>
                <ModalTopMenuList className="top-menu_modal_edit">
                    <ContextTooltip key="shift-map.toggle-route" code="shift-map.toggle-route"
                                    default="Отображение планового маршрута">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_planned-route"
                            onClick={this.toggleRoute}
                        />
                    </ContextTooltip>
                    <ModalTopMenuListSeparator key="separator"/>
                </ModalTopMenuList>
                <ModalTopMenuButton
                    key="close"
                    className="_close"
                    tooltip="Отменить"
                    onClick={::this.closeMap}
                />
            </ModalTopMenuList>
        );

        const points = this.state.routeVisible ? _.get(this.state.routeVariant, (this.state.routeVariant.type === 'production_forward') ? 'forward_points' : 'reverse_points') : [];

        return (
            <PageModal
                header={{title, buttons}}
                onClose={::this.closeMap}
                className="shift-map-modal"
                withFade={true}
            >
                <ShiftMap
                    data={{
                        history: this.state.history,
                        points,
                        stopPoints: this.state.stopPointsObjects,
                    }}
                />
            </PageModal>
        );
    }
}