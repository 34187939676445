import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';

import './index.less';
import PageModal from "components/ui/page-modal";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import _ from 'lodash';
import Datepicker from "components/ui/form/datepicker";
import moment from "moment";
import formats from "dictionaries/formats";
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Radio from "components/ui/form/radio";
import Checkbox from "components/ui/form/checkbox";
import Button from "components/ui/button";
import {connect} from "react-redux";
import classNames from 'classnames';
import "slick-carousel/slick/slick.css";

import {default as Slick} from "react-slick";
import {formatDistance, formatTime} from "helpers/functions";

const Handle = Slider.Handle;

@propTypes({
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object,
})

export default class RoutingResultModal extends Component {

    state = {
        expanded: {
            up: {},
            down: {},
        },
    };

    componentDidMount() {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'))
        }, 0);
    }

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ModalTopMenuButton
                    className="_close"
                    onClick={this.props.onClose}
                />
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{title: 'Планировщик поездки', buttons}}
                className="b-modal-trip-planner-result b-modal_w-footer"
                withFade={false}
                buttons={this.getButtons()}>
                {this.renderContent()}
            </PageModal>
        );
    }

    getButtons() {
        return [
            <Button key="routing-config" size="md" color="red" text={(
                <span>
                    <i className="arrow-icon arrow-icon_left arrow-icon_white"/>
                    Параметры поездки
                </span>
            )} onClick={this.props.onRoutingConfigure}/>,
            <Button key="routing-reset" size="md" color="red" text="Новая поездка"
                    onClick={this.props.onRoutingReset}/>,
        ];
    }

    renderContent() {
        const {data: {from, to, items}, activeRoutingResultItem} = this.props;
        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            infinite: false,
            focusOnSelect: true,
            prevArrow: <PrevArrow/>,
            nextArrow: <NextArrow/>,
            variableWidth: true,
            afterChange: (index) => {
                this.props.setActiveRoutingResultItem(index);
            },
        };

        return (
            <Accordion>
                <AccordionItem title="Результаты планирования" opened={true} withoutWrapper={true} afterTitle={(
                    <div className="accordion__dscn">
                        <div className="accordion__route">
                            <i className="p-result__icon p-result__icon_wh20 p-result__icon_start p-result__icon_border"/>
                            {from.address}
                        </div>
                        {/*<i className="arrow-icon arrow-icon_red arrow-icon_right"/>*/}
                        <div className="accordion__route">
                            <i className="p-result__icon p-result__icon_wh20 p-result__icon_end p-result__icon_border"/>
                            {to.address}
                        </div>
                    </div>
                )}>
                    <div className="accordion__posit accordion__posit_top-bord">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="accordion__slider">

                                        {(items.length === 0) ? (
                                            <div>Не найдено маршрута</div>
                                        ) : (
                                            <div>
                                                <div className="accordion__display">
                                                    <Slick {...settings}>
                                                        {_.map(items, ::this.renderItem)}
                                                    </Slick>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AccordionItem>
                {this.renderSegments(_.get(items, activeRoutingResultItem))}
            </Accordion>
        );
    }

    renderItem(item, index) {
        return (
            <div className="accordion__slide">
                <div className="p-result">
                    <div className="p-result__title">
                        <span className="p-result__num">{index + 1}.</span>
                        <span className="p-result__path">{formatDistance(item.distance_total)}
                            / {formatTime(item.time)}</span>
                        <div className="p-result__foot">
                            <i className="p-result__icon p-result__icon_wh24 p-result__icon_walk"/>
                            <div className="p-result__foot-text">{formatDistance(item.distance_walk)}</div>
                        </div>
                    </div>
                    <div className="p-result__route">
                        <div className="p-result__zone">
                            <i className="p-result__icon p-result__icon_wh32 p-result__icon_start p-result__icon_border"/>
                            <span className="p-result__zone_start">{item.departure_time}</span>
                        </div>
                        {_.map(item.segments, (segment, segmentIndex) => {
                            return (
                                <div
                                    key={segmentIndex}
                                    className="p-result__step d-flex justify-content-center align-items-center">
                                    <span className="p-result__req-time">{formatTime(segment.time)}</span>
                                    <i className={`p-result__icon p-result__icon_white p-result__icon_wh24 p-result__icon_${segment.mode} p-result__icon_border`}
                                       onClick={this.setActiveSegment.bind(this, segmentIndex)}/>
                                    <span className="p-result__adding">{(segment.route_number) ? (
                                        <span>
                                            Автобус {segment.route_number}
                                            <br/>
                                            Посадка: {segment.start_at}
                                        </span>
                                    ) : `${formatDistance(segment.distance)}`}</span>
                                </div>
                            );
                        })}
                        <div className="p-result__zone">
                            <i className="p-result__icon p-result__icon_wh32 p-result__icon_end p-result__icon_border"/>
                            <span className="p-result__zone_start">{item.arrive_time}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    prevItem() {
        this.props.setActiveRoutingResultItem(this.props.activeRoutingResultItem - 1);
    }

    nextItem() {
        this.props.setActiveRoutingResultItem(this.props.activeRoutingResultItem + 1);
    }

    renderSegments(item) {
        if (!item) {
            return;
        }

        return _.map(item.segments, (segment, index) => {
            const nextSegment = _.get(item.segments, index + 1);

            return this.renderSegment(segment, nextSegment, index)
        });
    }

    renderSegment(segment, nextSegment, segmentIndex) {
        let result = [];

        if (segmentIndex !== this.props.activeSegment) {
            return;
        }

        switch (segment.mode) {
            case 'walk':
                result.push(
                    <AccordionItem key={segmentIndex} title="Сегмент поездки" opened={true} withoutWrapper={true}>
                        <div className="accordion__posit accordion__posit_top-bord">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="t-segment">
                                            <div className="t-segment__title">
                                                <i className="p-result__icon p-result__icon_walk p-result__icon_wh24"/>
                                                <span className="t-segment__title-text">Переход: {segment.start_at}
                                                    - {segment.end_at}</span>
                                            </div>
                                            <div className="t-segment__content flex-column">
                                                <div className="t-segment__content-row d-flex">
                                                    <div className="t-segment__content-prop">От:</div>
                                                    <div
                                                        className="t-segment__content-val">{_.get(_.first(segment.stop_points), 'name')}</div>
                                                </div>
                                                <div className="t-segment__content-row d-flex">
                                                    <div className="t-segment__content-prop">До:</div>
                                                    <div
                                                        className="t-segment__content-val">{_.get(_.last(segment.stop_points), 'name')}</div>
                                                </div>
                                                <div className="t-segment__content-row d-flex">
                                                    <div className="t-segment__content-prop">Продолжительность:</div>
                                                    <div
                                                        className="t-segment__content-val">{formatTime(segment.time)}</div>
                                                </div>
                                                <div className="t-segment__content-row d-flex">
                                                    <div className="t-segment__content-prop">Расстояние:</div>
                                                    <div
                                                        className="t-segment__content-val">{formatDistance(segment.distance)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionItem>
                );
                break;
            case 'bus':
                const {prev, next} = this.getRouteStopPoints(segment);
                const isFromFirst = (_.get(_.first(segment.route_stop_points), 'uuid') === _.get(_.first(segment.stop_points), 'uuid'));
                const isToLast = (_.get(_.last(segment.route_stop_points), 'uuid') === _.get(_.last(segment.stop_points), 'uuid'));

                result.push(
                    <AccordionItem key={segmentIndex} title="Сегмент поездки" opened={true} withoutWrapper={true}>
                        <div className="accordion__posit accordion__posit_top-bord">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="t-segment">
                                            <div className="t-segment__title">
                                                <i className="p-result__icon p-result__icon_bus p-result__icon_wh24"/>
                                                <span
                                                    className="t-segment__title-text">Автобус {segment.route_number}</span>
                                            </div>
                                            <div className="t-segment__content">
                                                <div className="t-segment__content-left">
                                                    <div
                                                        className="t-segment__req-time">{formatTime(segment.time)}</div>
                                                </div>
                                                <div className="t-segment__content-right">
                                                    <div className="t-segment__point">
                                                        <i className={`p-result__icon p-result__icon_${isFromFirst ? 'green' : 'red'} p-result__icon_border p-result__icon_wh24`}/>
                                                        <div
                                                            className="t-segment__point-text">
                                                            <span
                                                                className="t-segment__board">{isFromFirst ? '[Посадка] ' : ''}{_.get(_.first(segment.route_stop_points), 'name')} </span>
                                                            <span
                                                                className='t-segment__time'>{isFromFirst ? segment.start_at : ''}</span>
                                                        </div>
                                                    </div>
                                                    {(prev.length > 0) ? (
                                                        <div className="t-segment__stops t-segment__stops-no-active">
                                                            <div className="t-segment__stops-title"
                                                                 onClick={this.toggle.bind(this, 'up', segmentIndex)}>
                                                                <i className="p-result__icon p-result__icon_rect p-result__icon_wh24"/>
                                                                <span
                                                                    className={classNames('t-segment__stops-title-text', {
                                                                        collapse: this.state.expanded.up[segmentIndex],
                                                                    })}>Остановок: {prev.length}</span>
                                                            </div>
                                                            {(this.state.expanded.up[segmentIndex]) ? (
                                                                <div className="t-segment__stops-list">
                                                                    {_.map(prev, (stopPoint, index) => {
                                                                        return (
                                                                            <div
                                                                                key={`prev:${index}`}
                                                                                className="t-segment__stops-item">
                                                                                <i className="p-result__icon p-result__icon_inner-gray p-result__icon_wh24"/>
                                                                                <div
                                                                                    className="t-segment__stops-item-text">{stopPoint.name}</div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    ) : null}
                                                    <div className="t-segment__stops">
                                                        <div className="t-segment__stops-list">
                                                            {_.map(segment.stop_points, (stopPoint, index) => {
                                                                if (index === 0) {
                                                                    if (isFromFirst) {
                                                                        return;
                                                                    }
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className="t-segment__stops-item t-segment__stops-item_bold">
                                                                            <i className="p-result__icon p-result__icon_inner-green p-result__icon_wh24"/>
                                                                            <div
                                                                                className="t-segment__stops-item-text">
                                                                                [Посадка] <span>{stopPoint.name}</span>
                                                                            </div>
                                                                            <div
                                                                                className="t-segment__stops-item-time">{segment.start_at}</div>
                                                                        </div>
                                                                    );
                                                                }

                                                                if (index === segment.stop_points.length - 1) {
                                                                    if (isToLast) {
                                                                        return;
                                                                    }
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className="t-segment__stops-item t-segment__stops-item_bold">
                                                                            <i className="p-result__icon p-result__icon_inner-green p-result__icon_wh24"/>
                                                                            <div className="t-segment__stops-item-text">
                                                                                [Высадка] <span>{stopPoint.name}</span>
                                                                            </div>
                                                                            <div
                                                                                className="t-segment__stops-item-time">{segment.end_at}</div>
                                                                        </div>
                                                                    );
                                                                }
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className="t-segment__stops-item">
                                                                        <i className="p-result__icon p-result__icon_inner-green p-result__icon_wh24"/>
                                                                        <div
                                                                            className="t-segment__stops-item-text">{stopPoint.name}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                    {(next.length > 0) ? (
                                                        <div className="t-segment__stops t-segment__stops-no-active">
                                                            <div className="t-segment__stops-title"
                                                                 onClick={this.toggle.bind(this, 'down', segmentIndex)}>
                                                                <i className="p-result__icon p-result__icon_rect p-result__icon_wh24"/>
                                                                <span
                                                                    className={classNames('t-segment__stops-title-text', {
                                                                        collapse: this.state.expanded.down[segmentIndex],
                                                                    })}>Остановок: {next.length}</span>
                                                            </div>
                                                            {(this.state.expanded.down[segmentIndex]) ? (
                                                                <div className="t-segment__stops-list">
                                                                    {_.map(next, (stopPoint, index) => {
                                                                        return (
                                                                            <div
                                                                                key={`next:${index}`}
                                                                                className="t-segment__stops-item">
                                                                                <i className="p-result__icon p-result__icon_inner-gray p-result__icon_wh24"/>
                                                                                <div
                                                                                    className="t-segment__stops-item-text">{stopPoint.name}</div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    ) : null}
                                                    <div className="t-segment__point">
                                                        <i className={`p-result__icon p-result__icon_${isToLast ? 'green' : 'red'} p-result__icon_border p-result__icon_wh24`}/>
                                                        <div
                                                            className="t-segment__point-text">
                                                            <span
                                                                className="t-segment__board">{isToLast ? '[Высадка] ' : ''}{_.get(_.last(segment.route_stop_points), 'name')} </span>
                                                            <span
                                                                className="t-segment__time">{isToLast ? segment.end_at : ''}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionItem>
                );
                if (nextSegment && nextSegment.mode === 'bus') {
                    result.push(
                        <AccordionItem key={`${segmentIndex}:switch`} title="Сегмент поездки" opened={true}
                                       withoutWrapper={true}>
                            <div className="accordion__posit accordion__posit_top-bord">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="t-segment">
                                                <div className="t-segment__title">
                                                    <i className="p-result__icon p-result__icon_refresh p-result__icon_wh24"/>
                                                    <span
                                                        className="t-segment__title-text">Пересадка: {segment.end_at}
                                                        - {nextSegment.start_at}</span>
                                                </div>
                                                <div className="t-segment__content flex-column">
                                                    <div className="t-segment__content-row d-flex">
                                                        <div className="t-segment__content-prop">Узел:</div>
                                                        <div
                                                            className="t-segment__content-val">{_.get(_.last(segment.stop_points), 'name')}</div>
                                                    </div>
                                                    <div className="t-segment__content-row d-flex">
                                                        <div className="t-segment__content-prop">Продолжительность:
                                                        </div>
                                                        <div
                                                            className="t-segment__content-val">{formatTime(moment(nextSegment.start_at, formats.TIME).diff(moment(segment.end_at, formats.TIME), 'minutes'))}
                                                        </div>
                                                    </div>
                                                    <div className="t-segment__content-row d-flex">
                                                        <div className="t-segment__content-prop">Сход:</div>
                                                        <div className="t-segment__content-val">
                                                            Автобус {segment.route_number}</div>
                                                    </div>
                                                    <div className="t-segment__content-row d-flex">
                                                        <div className="t-segment__content-prop">Посадка на:</div>
                                                        <div className="t-segment__content-val">
                                                            Автобус {nextSegment.route_number}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionItem>
                    );
                }
                break;
        }
        return result;
    }

    toggle(direction, index) {
        let expanded = this.state.expanded;
        expanded[direction][index] = !expanded[direction][index];
        this.setState({expanded});
    }

    getRouteStopPoints(segment) {
        let prev = [];
        let next = [];

        const stopPointsUuids = _.map(segment.stop_points, 'uuid');

        let founded = false;
        _.each(segment.route_stop_points, (stopPoint) => {
            if (_.indexOf(stopPointsUuids, stopPoint.uuid) === -1) {
                if (founded) {
                    next.push(stopPoint);
                } else {
                    prev.push(stopPoint);
                }
            } else {
                founded = true;
            }
        });

        return {
            prev,
            next,
        };
    }

    setActiveSegment(index) {
        this.props.setActiveSegment(index);
    }
}

function NextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div className={`${className} accordion__btn accordion__btn_next`} style={{...style}}
             onClick={onClick}/>
    );
}

function PrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div className={`${className} accordion__btn accordion__btn_prev`} style={{...style}}
             onClick={onClick}/>
    );
}