import React from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ContextTooltip from "components/ui/context-tooltip";
import {createStoWork, deleteStoWork, getStoWork, updateStoWork} from "store/reducers/commdept/sto_works";
import TableContainer from "components/ui/Table/Container/TableContainer";
import Button from "components/ui/button";
import * as alerts from "helpers/alerts";
import debounce from 'throttle-debounce/debounce';
import systems from "../../../../../dictionaries/systems";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getStoWork, createStoWork, updateStoWork, deleteStoWork})

export default class StoWorkEditor extends BaseEditor {

    modelClass = 'App\\Model\\StoWork';
    modalClassName = 'b-modal-contract edit-order-work-modal';

    getFullTitle() {
        return 'Добавление работ';
    }

    async loadData(uuid) {
        return await this.props.getStoWork(uuid);
    }

    async createItem(data) {
        return await this.props.createStoWork(data);
    }

    async updateItem(data) {
        return await this.props.updateStoWork(data);
    }

    saveButton(onClick) {
        return null;
    }

    async onDelete() {
        alerts.prompt('Удалить запись?', '', async () => {
            const response = await this.props.deleteStoWork({
                uuid: this.state.item.uuid,
            });

            if (response.isOk) {
                this.props.onSubmit();
            } else {
                response.showErrors();
            }
        });
    }

    renderHeaderBtns(mode) {
        if (mode === 'edit') {
            return (
                <ContextTooltip key="base-editor.delete" code="base-editor.delete"
                                default="Удалить">
                    <ModalTopMenuListItem
                        className="b-icon-link_params b-icon-link_icon_basket"
                        onClick={::this.onDelete}
                    />
                </ContextTooltip>
            );
        }
        return null;
    }

    printButton() {
        return null;
    }

    getForm(item, onSubmit) {
        return (
            <div>
                <EditorForm
                    {...this.props}
                    ref="form"
                    mode={this.props.mode}
                    onSubmit={onSubmit}
                    onClose={this.props.onClose}
                    onDataLoad={::this.forceUpdate}
                    data={item}
                    errors={this.state.errors}
                />
                <div className="b-modal__footer">
                    <div className="b-modal__footer-txt">Вы хотите сохранить все изменения?</div>
                    <Button size="md" color="white" shadow="gray" className="b-button_cancel" text="Отменить"
                            onClick={this.props.onClose}/>
                    <Button size="md" color="red" className="b-button_save" text="Сохранить"
                            onClick={(this.props.mode === 'add') ? ::this.onCreate : ::this.onEdit}/>
                </div>
            </div>
        );
    }

    composeItem(data) {
        let item = _.clone(data);

        item.sto_uuid = this.props.stoUuid;

        return item;
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDataLoad: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getDictionaryList}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        sto_work: {},
        maintenance_groups: [],
        work_types: [],
    };

    getData() {
        return this.state.sto_work;
    }

    async componentDidMount() {
        await this.loadDictionaries([
            'maintenance_groups',
           // 'work_types',
            'measures',
        ]);

        await this.setState({
            sto_work: this.prepareData(this.props.data),
        });
    }

    prepareData(item) {
        item.data = item.data || [];

        _.each(this.state.maintenance_groups, (group) => {
            if (!_.find(item.data, {maintenance_group_uuid: group.value})) {
                item.data.push({
                    maintenance_group_uuid: group.value,
                    count: 0,
                });
            }
        })

        return item;
    }

    get(path, defaultValue = null) {
        return _.get(this.state.sto_work, path, defaultValue);
    }

    async loadWorkTypes(input, callback) {
        let result;
        if (!input) {
            result = await this.props.getDictionaryList('work_types', {
                pagination: {
                    page: 1,
                    limit: 20,
                },
            }, false);
        } else {
            result = await this.props.getDictionaryList('work_types', {
                search: input,
                pagination: {
                    page: 1,
                    limit: 20,
                },
            }, false);
        }

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.documents.map(type => ({
                    value: type.uuid,
                    label: `${type.name}`,
                })), 'label'),
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    addWorkType(e) {
        const value = e ? e.value : null;
        this.setValue('sto_work.work_type_uuid', value);
    }

    workTypesDebounce = debounce(500, ::this.loadWorkTypes);

    render() {
        const workType = _.find(this.state.work_types, {value: this.get('work_type_uuid')});

        return (
            <div className="b-modal__block">
                <Block title="Вид работ">
                   {/* {this.select('sto_work.work_type_uuid', this.state.work_types)}*/}
                    {this.selectAsync('sto_work.work_type_uuid', ::this.workTypesDebounce, {
                        onChange: ::this.addWorkType,
                    })}
                </Block>
                <Block title="Единица измерения">
                    {_.get(_.find(this.state.measures, {value: _.get(workType, 'document.measure_uuid')}), 'label')}
                </Block>
                <Block size="xl">
                    <TableContainer>
                        <div className="Table-title">Группы по содержанию</div>
                        <div className="Table indent-none">
                            <table className="b-table b-table-no-hover">
                                <thead>
                                <tr>
                                    <th>Группа по содержанию</th>
                                    <th>Количество циклов</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.maintenance_groups.map(::this.renderMaintenanceGroup)}
                                </tbody>
                            </table>
                        </div>
                    </TableContainer>
                </Block>
            </div>
        );
    }

    renderMaintenanceGroup(group) {
        const index = _.findIndex(this.state.sto_work.data, {maintenance_group_uuid: group.value});
        if (index === -1) {
            return null;
        }

        return (
            <tr key={group.value}>
                <td>{group.label}</td>
                <td>{this.textInput(`sto_work.data.${index}.count`, {
                    type: 'number',
                })}</td>
            </tr>
        );
    }
}
