import React, {Component} from 'react';
import Page from 'components/ui/page';
import Button from "components/ui/button";
import systems from "dictionaries/systems";
import StopPointsContainer from "./Container/StopPointsContainer";
import HotKeysManager from "components/ui/HotKeys/Manager/HotKeysManager";

export default class DisplaysStopPointsComponent extends Component {

    constructor() {
        super();

        this.state = {
            is_fullscreen: false
        }
    }

    stopPointsFullscreen() {
        if (this.state.is_fullscreen) {
            this.show()
            this.setState({
                is_fullscreen: false
            })
        } else {
            this.hide()
            this.setState({
                is_fullscreen: true
            })
        }
    }

    hide() {
        $(".header.header_fixed.clearAfter, .stopPoints__selector").hide();
        $(".page").addClass("full");
    }

    show() {
        $(".header.header_fixed.clearAfter, .stopPoints__selector").show();
        $(".page").removeClass("full");
    }

    getHotKeyHandlers() {
        return {
            stopPointsFullscreen: :: this.stopPointsFullscreen,
        }
    }

    render() {
        return (
            <HotKeysManager {...this.getHotKeyHandlers()}>
            <Page withoutMenu={this.state.is_fullscreen ? true : false}
                  pageId="DisplaysStopPointsComponent"
                  title={`${systems[this.props.params.component]} → Остановочные табло`}
                  headerContents={this.renderHeaderContents()}>
                {this.renderContent()}
            </Page>
            </HotKeysManager>
        );
    }

    gotoTable() {
        this.props.router.push('/kiutr/displays/table');
    }

    gotoMap() {
        this.props.router.push('/kiutr/displays/map');
    }

    renderHeaderContents() {
        return [
            <Button key="table" text="Таблица" color="dark"
                    className="top-menu__button top-menu__button_recovery" onClick={::this.gotoTable}/>,
            <Button key="map" text="Карта" color="dark"
                    className="top-menu__button top-menu__button_recovery" onClick={::this.gotoMap}/>,
        ];
    }

    renderContent() {
        return (
            <StopPointsContainer />
        );
    }
}