import React from 'react';
import _ from 'lodash';
import {api} from "helpers/api";
import {makeResponse} from "helpers/response";

let environmentalClasses = null;

async function loadEnvironmentalClasses() {
    const response = await makeResponse(() => {
        return api.dictionary.getDictionaryList('vehicle_environmental_classes', {});
    });
    if (response.isOk) {
        environmentalClasses = _.mapValues(_.keyBy(response.payload.documents, 'uuid'), 'rank');
    }
}

function getEnvironmentalRank(uuid) {
    if (!environmentalClasses) {
        loadEnvironmentalClasses();
    }

    return _.get(environmentalClasses, uuid, 1);
}

export default function OrderVehicleViolations({contract, vehicle}) {
    if (!vehicle.uuid || !contract) {
        return (
            <div/>
        );
    }

    const violations = {
        'Экологический класс': contract.environmental_class_uuid && (getEnvironmentalRank(vehicle.environmental_class_uuid) >= getEnvironmentalRank(contract.environmental_class_uuid)),
        'Низкопольные': contract.is_low_floor_level && (vehicle.is_low_floor_level !== contract.is_low_floor_level),
        'Кондиционирование': contract.is_air_conditioning_installation && (vehicle.is_air_conditioning_installation !== contract.is_air_conditioning_installation),
        'Электронное табло': contract.is_electronic_scoreboard && (vehicle.is_electronic_scoreboard !== contract.is_electronic_scoreboard),
        'Аудио/видео фиксация': contract.is_audio_video_fixation && (vehicle.is_audio_video_fixation !== contract.is_audio_video_fixation),
        'Безналичная оплата': contract.is_cashless_payment && (vehicle.is_cashless_payment !== contract.is_cashless_payment),
        'Мониторинг пассажиропотока': contract.is_passenger_monitoring_system && (vehicle.is_passenger_monitoring_system !== contract.is_passenger_monitoring_system),
    };

    const violationsList = _.pickBy(violations, item => !!item);

    if (_.size(violationsList) === 0) {
        return null;
    }

    return (
        <div className="icon-violation">
            <div className="tooltip-content">
                {_.map(violationsList, (item, title) => {
                    return (
                        <div key={title}>
                            {title}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}