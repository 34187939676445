import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import _ from 'lodash';
import classNames from 'classnames';

import './detalization-modal.less';
import PageModal from 'components/ui/page-modal';
import { api } from "helpers/api";
import Slider from 'components/ui/top-menu-slider';

const vehicleFields = ['task', 'driver', 'type', 'mark', 'model'];
const routeFields = ['routeNumber', 'dispatcher', 'workType', 'distance'];



@propTypes({
  onClose: PropTypes.func,
})

export default class DetalizationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
      isSelectedAllVehicle: this.isSelectedAll(vehicleFields),
      isSelectedAllRoute: this.isSelectedAll(routeFields),
    }
  }

  async componentWillMount() {
    if (this.props.component === 'road') {
      await this.loadTasks();
    }
  }

  isSelectedAll = (fields) => {
    const filters = { ...this.props.filters };
    return fields.filter(field => filters[field]).length === fields.length;
  }

  toggleOption = (name) => () => {
    this.props.onFilterChange(name);
  }

  async loadTasks() {
    const vehicle = this.props.data.vehicle;

    const status = _.get(_.find(this.props.data.kurs_task_statuses, { name: 'В работе' }), 'uuid');

    const response = await makeResponse(() => {
      return api.kurs.getTasks({
        filters: {
          withBaseVehicles: [
            vehicle.uuid,
          ],
          withStatus: status,
        },
      });
    });

    if (response.isOk) {
      this.setState({
        tasks: response.payload.items,
      });
    } else {
      response.showErrors();
    }
  }

  toggleAllVehicle = () => {
    const filters = {...this.props.filters};
    vehicleFields.forEach( field => filters[field] = !this.state.isSelectedAllVehicle );
    this.setState({ isSelectedAllVehicle: !this.state.isSelectedAllVehicle });
    this.toggleOption(filters)();
  }

  toggleAllRoute = () => {
    const filters = { ...this.props.filters };
    routeFields.forEach(field => filters[field] = !this.state.isSelectedAllRoute);
    this.setState({ isSelectedAllRoute: !this.state.isSelectedAllRoute });
    this.toggleOption(filters)();
  }

  gotoVehicle = (e) => {
    e && e.preventDefault();
    this.props.onClose();
    this.props.data.vehicle.onEditClick(this.props.data.vehicle);
  }

  gotoHistory = (e) => {
    e && e.preventDefault();
    this.props.onClose();
    this.props.data.showHistory(this.props.data.vehicle.uuid);
  }

  gotoRoute = (e) => {
    e && e.preventDefault();
    this.props.onClose();
    this.props.data.openRoutePanel();
  }

  render() {
    const { data } = this.props;
    const { filters} = this.props;
    const bnsoPhone = _.get(data.vehicle, 'current_bnso.phone_number');
    return (
      <PageModal
        header={{ title: '' }}
        onClose={this.props.onClose}
        className={`profile-modal b-modal-edit DetalizationModal`}
        withFade={false}
      >
      <div>
        <div className="block-panel">
          <div className="block-panel__header">
            {(data.component !== 'control') ? data.vehicleType : data.vehicleMark} {data.vehicle.state_number} {`(${data.vehicle.garage_number || '-'})`}
            <div className="b-modal__button-block" onClick={this.props.onClose}><div className="b-modal__header-link _close"></div></div>
          </div>
          <div className="block-panel__content">
              <div>Текущая скорость {data.device.speed} км/ч{(data.device.speed !== 0) ? (
                <span>, {this.getCourseText()} <span className="direction-wrapper"
                  style={{ transform: `rotate(${data.device.course}deg)` }} /></span>
              ) : null}</div>
            <table>
              <tr>
                <td></td>
                <td>Задание\маршрут</td>
                <td>{data.vehicle.route_number}</td>
                <td><Slider onChange={this.toggleOption('task')} active={filters.task} /></td>
              </tr>
              <tr>
                <td></td>
                <td>Водитель</td>
                <td>{data.vehicle.driver_name}</td>
                <td><Slider onChange={this.toggleOption('driver')} active={filters.driver} /></td>
              </tr>
              <tr>
                <td></td>
                <td>Тип ТС</td>
                <td>{data.vehicleType}</td>
                <td><Slider onChange={this.toggleOption('type')} active={filters.type} /></td>
              </tr>
              <tr>
                <td></td>
                <td>Марка</td>
                <td>{data.vehicleMark}</td>
                <td><Slider onChange={this.toggleOption('mark')} active={filters.mark} /></td>
              </tr>
              <tr>
                <td></td>
                <td>Модель</td>
                <td>{data.vehicleModel}</td>
                <td><Slider onChange={this.toggleOption('model')} active={filters.model} /></td>
              </tr>
            </table>
          </div>
          <div className="block-panel__footer">
              {this.renderToggleAll('vehicle')}
          </div>
        </div>

        <div className="block-panel">
          <div className="block-panel__header">
            Задание\Маршрут
          </div>
          <div className="block-panel__content">
            <table>
              <tr>
                <td></td>
                <td> Номер маршрута</td>
                <td>{data.vehicle.route_number}</td>
                <td><Slider onChange={this.toggleOption('routeNumber')} active={filters.routeNumber} /></td>
              </tr>
              <tr>
                <td></td>
                <td>Оператор\диспетчер</td>
                <td>-</td>
                <td><Slider onChange={this.toggleOption('dispatcher')} active={filters.dispatcher} /></td>
              </tr>
              <tr>
                <td></td>
                <td>Тип работ</td>
                <td>
                  {_.map(this.state.tasks, (task) => {
                    const workTypes = _.map(_.map(task.items, 'work_type_uuid'), (workTypeUuid) => {
                      return _.get(this.props.data.work_types, `${workTypeUuid}.name`);
                    });

                    return (
                      <div>{task.number} ({workTypes.join(', ')})</div>
                    );
                  })}
                </td>
                <td><Slider onChange={this.toggleOption('workType')} active={filters.workType} /></td>
              </tr>
              <tr>
                <td></td>
                <td>Протяженность</td>
                <td>-</td>
                <td><Slider onChange={this.toggleOption('distance')} active={filters.distance} /></td>
              </tr>
            </table>
          </div>
          <div className="block-panel__footer">
              {this.renderToggleAll('route')}
          </div>
        </div>

        <div className="block-panel">
          <div className="block-panel__content">
              <div className="block-panel__buttons">
              <a
                href="javascript:void(0)"
                className="b-button b-button_size_md b-button_red b-button_card"
                onClick={this.gotoVehicle}
              >
                Карточка ТС
              </a>
              <a
                href="javascript:void(0)"
                className="b-button b-button_size_md b-button_red b-button_history"
                onClick={this.gotoHistory}
              >
                История
              </a>
              <a
                href="javascript:void(0)"
                className="b-button b-button_size_md b-button_red b-button_route"
                onClick={this.gotoRoute}
              >
                Маршрут
            </a>
            </div>
              <div className="b-modal-map">
              <div className="button-dropdown call">
                <input type="checkbox" className="button-dropdown__open" />
                <i className="button-dropdown__arrow" />
                <span className="b-button b-button_red b-button_size_md">
                  Голосовая связь
                </span>
                <ul className="dropdown-menu">
                  {data.vehicle.driver_phone ? (
                    <li className="dropdown-menu__item"
                      onClick={this.call.bind(this, data.vehicle.driver_phone)}>
                      <span>номер водителя</span>
                      {data.vehicle.driver_phone}
                    </li>
                  ) : null}
                  {bnsoPhone ? (
                    <li className="dropdown-menu__item"
                      onClick={this.call.bind(this, bnsoPhone)}>
                      <span>номер БНСО</span>
                      {bnsoPhone}
                    </li>
                  ) : null}
                  {(!data.vehicle.driver_phone && !bnsoPhone) ? (
                    <li className="dropdown-menu__item">
                      <span>нет телефона</span>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      </PageModal>
    )
  }

  call(phone) {
    GlobalEvent().call('call:start', phone);
  }

  getCourseText() {
    const course = this.props.data.device.course;

    if ((course >= 360 - 22.5) || (course <= 22.5)) {
      return 'Север';
    }
    if ((course >= 22.5) && (course <= 67.5)) {
      return 'Северо-Восток';
    }
    if ((course >= 67.5) && (course <= 112.5)) {
      return 'Восток';
    }
    if ((course >= 112.5) && (course <= 157.5)) {
      return 'Юго-Восток';
    }
    if ((course >= 157.5) && (course <= 202.5)) {
      return 'Юг';
    }
    if ((course >= 202.5) && (course <= 247.5)) {
      return 'Юго-Запад';
    }
    if ((course >= 247.5) && (course <= 292.5)) {
      return 'Запад';
    }
    if ((course >= 292.5) && (course <= 337.5)) {
      return 'Северо-Запад';
    }
  }

  renderToggleAll(blockName) {
    let isSelectedAll;
    let onClick;
    if (blockName === 'vehicle') {
      isSelectedAll = this.state.isSelectedAllVehicle;
      onClick = this.toggleAllVehicle
    }
    if (blockName === 'route') {
      isSelectedAll = this.state.isSelectedAllRoute;
      onClick = this.toggleAllRoute;
    }
    const sliderClassName = classNames({
      'b-slider__line': true,
      '_selected_all': isSelectedAll,
    });

    const sliderCircleClassName = classNames({
      'b-slider__circle': true,
      '_selected_all': isSelectedAll,
    });

    return (
      <div className="b-slider _options clearAfter" onClick={onClick}>
        <div className="b-slider__control">
          <div className={sliderClassName} />
          <div className={sliderCircleClassName} />
        </div>
        <div className="b-slider__title">{isSelectedAll ? 'Скрыть все' : 'Показать все'}</div>
      </div>
    );
  }
}


