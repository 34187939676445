import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getRoads = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.getRoads(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRoad = (uuid) => async (dispatch) => {
    try {
        const response = await api.commdept.getRoad(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createRoad = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.createRoad(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateRoad = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.updateRoad(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteRoad = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.deleteRoad(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
