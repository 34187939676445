import React, {Component} from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapLayer from "components/ui/map/base/layer";
import {connect} from "react-redux";
import {getStopPointsWithBounds} from "store/reducers/routes/routes";
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import StopPointsEditor from "components/modules/geo/stop-points/editor";
import {getRoadParts} from "store/reducers/kurs/road_parts";
import MapGeojson from "components/ui/map/base/geojson";
import {getRoads} from "store/reducers/kurs/roads";

@propTypes({
    onClick: PropTypes.func,
})

@defaultProps({
    onClick: null,
})

@connect((state) => ({}), {getRoads})

export default class KursRoadsLayer extends AbstractMapLayer {

    state = {
        roads: [],
        active: null,
    };

    loadCallback = ::this.load;

    componentWillMount() {
        super.componentWillMount();

        this.props.leafletMap.on('moveend', this.loadCallback);

        this.load();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.props.leafletMap.off('moveend', this.loadCallback);
    }

    async load() {
        const boundingBox = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getBoundingBox() : this.props.map.getBoundingBox();
        const zoom = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getZoom() : this.props.map.getZoom();

        const response = await this.props.getRoads({
            filters: {
                withBoundingBox: boundingBox,
            },
        });

        if (response.isOk) {
            this.setState({
                roads: response.payload.items,
            });
        }
    }

    render() {
        return (
            <div>
                <div style={{display: 'none'}}>
                    {this.state.roads.map(::this.renderRoad)}
                </div>
            </div>
        );
    }

    renderRoad(road) {
        return (
            <MapGeojson
                key={road.uuid}
                {...this.props}
                geometry={road.geometry}
                tooltip={road.name}
                onClick={this.onClick.bind(this, road)}
                onMouseOver={this.setActive.bind(this, road.uuid, true)}
                onMouseOut={::this.setNotActive}
                color={(this.state.active && (this.state.active === road.uuid)) ? 'red' : 'blue'}
            />
        );
    }

    setActive(uuid) {
        this.setState({
            active: uuid,
        });
    }

    setNotActive() {
        this.setState({
            active: null,
        });
    }

    onClick(road) {
        this.props.onClick && this.props.onClick(road.uuid, road.geometry);
    }

}