import React, {Component} from 'react';
import ReactDOMServer from 'react-dom/server';
import {List, Map} from 'immutable';
import debounce from 'throttle-debounce/debounce';
import _ from 'lodash';
import classNames from 'classnames';

import IconButton from '../ui/icon-button';
import SearchField from '../ui/search-field';
import * as alerts from "helpers/alerts";
import {printTable} from "helpers/print";
import ShowDeleted from "components/ui/show-deleted";
import Loader from 'components/ui/loader';
import ContextTooltip from "components/ui/context-tooltip";
import TableComponent from "components/ui/Table/Component/TableComponent";
import TableColumnSelectFields from "components/ui/Table/Column/SelectFields/TableColumnSelectFields";
import TableTreeComponent from "components/ui/Table/TreeComponent/TableTreeComponent";

export default class BaseTreeTableComponent extends Component {

    loadCallbackDebounce = debounce(1000, ::this.loadCallback);
    select = 'single';

    constructor(props, context) {
        super(props, context);

        this.state = {
            success: false,
            showDeleted: false,
            query: '',
            queryBounced: '',
            selectedRowsCount: 0,
            showTableSearchFooter: false,
            showTableFilters: false,
            showSelectFieldsPopup: false,
            columns: List([])
        };
    }

    renderTable() {
        const className = classNames({
            'show-table-filters': this.state.showTableFilters,
        });

        return (
            <div className={className}>
                <TableTreeComponent
                    ref="table"
                    select={this.select}
                    columns={this.state.columns}
                    loadCallback={::this.loadCallbackMiddleware}
                    onColsReordered={::this.onColsReordered}
                    onCheck={::this.selectedRowsRecalc}
                    query={this.state.query}
                    showAudit={::this.showAudit}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    onSettingsLoad={::this.onSettingsLoad}
                    onDblClick={::this.onDblClick}
                    onClick={this.onClick}
                    onExpand={this.onExpand}
                    onCollapse={this.onCollapse}
                />
            </div>
        );
    }

    renderNonTreeTable() {
        if (this.state.columns.size === 0) {
            return;
        }

        const className = classNames({
            'show-table-filters': this.state.showTableFilters,
        });

        return (
            <div className={className}>
                <TableComponent
                    ref="table"
                    select={this.select}
                    columns={this.state.columns}
                    loadCallback={::this.loadCallbackMiddleware}
                    onColsReordered={::this.onColsReordered}
                    onCheck={::this.selectedRowsRecalc}
                    query={this.state.query}
                    showAudit={::this.showAudit}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    onSettingsLoad={::this.onSettingsLoad}
                    onDblClick={::this.onDblClick}
                    onClick={this.onClick}
                    onExpand={this.onExpand}
                    onCollapse={this.onCollapse}
                />
            </div>
        );
    }

    showAudit(rowData) {
        this.props.router.push(`/system/audit/${rowData.uuid}`)
    }

    reload() {
        if (!this.refs.table) return;

        this.refs.table.getWrappedInstance().collapseAll && this.refs.table.getWrappedInstance().collapseAll();
        this.refs.table.getWrappedInstance().reload();
    }

    collapseAll() {
        this.refs.table && this.refs.table.getWrappedInstance().collapseAll && this.refs.table.getWrappedInstance().collapseAll();
    }

    expand(element) {
        this.refs.table && this.refs.table.getWrappedInstance().expand && this.refs.table.getWrappedInstance().expand(element);
    }

    collapse(element) {
        this.refs.table && this.refs.table.getWrappedInstance().collapse && this.refs.table.getWrappedInstance().collapse(element);
    }

    onClick = (data) => {
    };

    onDblClick() {
    }

    loadSuboardinationData(data, callback) {
        callback([]);
    }

    onExpand = (data, element, index) => {
        if (!data.children) data.children = [];

        if (!data.children.length) {
            //this.collapse(element);
            const columns = this.state.columns.toArray();

            if (columns.length) {
                data.children.push({
                    [columns[0].field]: ReactDOMServer.renderToStaticMarkup(<Loader color="red" align="none"/>)
                });

                this.expand(element);
            }

            this.loadSuboardinationData(data, (suboardData) => {
                data.children = suboardData;
                this.collapse(element);
                this.expand(element);
            });
        }
        else {
            this.expand(element);
        }

    };

    onCollapse = (data, element) => {

    };

    async loadCallbackMiddleware(request, drawCallback, settings) {
        this.loadCallbackDebounce(request, drawCallback, settings);
    }

    async onColumnFilterChange(index, filter) {
        let columns = this.state.columns.update(index, (column) => {
            if (_.isArray(filter) && filter.length == 0) {
                delete column['filterValue'];
            } else {
                column.filterValue = filter;
            }
            return column;
        });
        await this.setState({columns});
        this.refs.table && this.refs.table.getWrappedInstance().reload();
    }

    selectedRowsRecalc() {
        if (!this.refs.table) return;

        if (this.refs.table.getWrappedInstance().getSelected) {
            this.setState({
                selectedRowsCount: this.refs.table.getWrappedInstance().getSelected().data().length,
            });
        }
    }

    searchDebounce = debounce(300, ::this.search);

    onSearchChange(query) {
        this.setState({queryBounced: query});

        this.searchDebounce(query);
    }

    search(query) {
        this.setState({query});
    }

    searchFieldShow() {
        this.setState({
            showTableSearchFooter: true
        })
    }

    searchFieldHide() {
        this.setState({
            showTableSearchFooter: false
        })
    }

    renderHeaderContents() {
        return [
            <ContextTooltip key="base-table-list.show-deleted" code="base-table-list.show-deleted" space={20}
                            default="Отображать/скрыть удаленные записи">
                <ShowDeleted
                    key="deleted"
                    active={this.state.showDeleted}
                    onChange={::this.toggleShowDeleted}
                    className={this.state.showTableSearchFooter ? 'left-shift' : ''}/>
            </ContextTooltip>,
            <ContextTooltip key="base-table-list.search" code="base-table-list.search" default="Поиск">
                <SearchField key="search" ref="search_btn" onChange={::this.onSearchChange}
                             value={this.state.queryBounced}
                             onShow={::this.searchFieldShow}
                             onHide={::this.searchFieldHide}/>
            </ContextTooltip>,
            <ContextTooltip key="base-table-list.filter" code="base-table-list.filter" default="Фильтр">
                <IconButton key="filter" icon="filter" tooltip="Фильтр" active={this.state.showTableFilters}
                            onClick={::this.toggleTableFilters}/>
            </ContextTooltip>,
            (((this.state.columns || new List()).size > 5) ? (
                <ContextTooltip key="base-table-list.columns-select" code="base-table-list.columns-select"
                                default="Отобразить/скрыть столбцы">
                    <TableColumnSelectFields
                        key="select-fields-popup"
                        columns={this.state.columns}
                        showPopup={this.state.showSelectFieldsPopup}
                        onChange={::this.onChangeSelectFieldsCheckbox}
                        onToggle={::this.onToggleSelectFieldsPopup}/>
                </ContextTooltip>
            ) : null),
            this.getCreateButton(),
            this.exportToXlsButton ? this.exportToXlsButton() : null,
            <ContextTooltip key="base-table-list.print" code="base-table-list.print" default="Печать">
                <IconButton key="print" icon="print" tooltip="Печать" onClick={::this.print}/>
            </ContextTooltip>,
        ];
    }

    print() {
        if (!this.refs.table) return;

        const table = this.refs.table.getWrappedInstance().table;
        //const originalPageLength = table.page.len();
        table.page.len(300);
        table.ajax.reload(() => {
            const headers = table.columns().header();
            const rows = table.rows().nodes();
            printTable(headers, rows, this.select);

            setTimeout(() => {
                table.page.len(25);
                table.ajax.reload();
            }, 250);
        });
    }

    getHotKeyHandlers() {
        return {
            search: ::this.searchOpen,
            filters: ::this.toggleTableFilters,
            columnSelect: ::this.onToggleSelectFieldsPopup,
            add: ::this.showEditor,
            print: ::this.print,
        };
    }

    searchOpen() {
        this.refs.search_btn.open();
    }

    async toggleTableFilters() {
        await this.setState({
            showTableFilters: !this.state.showTableFilters,
        });

        this.reload();
    }

    showEditor() {
    }

    onChangeSelectFieldsCheckbox(position, checked) {
        const column = this.state.columns.get(position);
        column.hidden = !checked;

        this.setState({
            columns: this.state.columns.set(position, column)
        });
    }

    onToggleSelectFieldsPopup() {
        this.setState({showSelectFieldsPopup: !this.state.showSelectFieldsPopup});
    }

    onColsReordered(e, settings, {from, to}) {
        from = from - 1;
        to = to - 1;
        const columns = this.state.columns.map((column) => {
            let i = column.index;
            if (i > from && i < to) {
                i = i - 1;
            } else if (i > to && i < from) {
                i = i + 1;
            } else if (i == to) {
                i = (from < to) ? i - 1 : i + 1;
            } else if (i == from) {
                i = to;
            }
            column.index = i;
            return column;
        });
        this.setState({columns});
    }

    onClickSelectFieldsButton() {
        this.setState({showSelectFieldsPopup: !this.state.showSelectFieldsPopup});
    }

    prepareColumns(columns) {
        return columns.map((column, index) => {
            column.index = index;
            return column;
        });
    }

    getCreateButton() {
        return (
            <ContextTooltip key="base-table-list.create" code="base-table-list.create">
                <IconButton
                    icon="plus"
                    tooltip="Добавить"
                    onClick={::this.showEditor}
                />
            </ContextTooltip>
        );
    }

    alertToSelect() {
        alerts.alert('Выберите запись');
    }

    onSettingsLoad(state) {
        /*const search = _.get(state, 'search.search');
        if (search) {
            this.setState({queryBounced: search, query: search});
            this.refs.search_btn && this.refs.search_btn.open();
        }*/
    }

    async toggleShowDeleted() {
        await this.setState({
            showDeleted: !this.state.showDeleted,
        });
        this.reload();
    }

    async loadDictionaries(dictionaries, component = null) {
        _.each(dictionaries, async (dictionary) => {
            const documents = await this.getDictionary(dictionary, component);

            let state = this.state;
            state[dictionary] = documents;
            this.setState(state);
        });
    }

    async getDictionary(dictionary, component = null) {
        const response = await this.props.getDictionaryList(dictionary, {
            order: {
                column: 'name',
                direction: 'asc',
            },
            filters: {
                withComponent: component,
            },
        });
        if (response.isOk) {
            return _.keyBy(response.payload.documents, 'uuid');
        } else {
            response.showErrors();
        }
    }

}