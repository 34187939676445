import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import WidgetSelector from 'components/modules/analytics/base-components/WidgetSelector';
import BarChart from 'components/modules/analytics/base-components/charts/BarChart';
import LineChart from 'components/modules/analytics/base-components/charts/LineChart';
import Datepicker from "components/ui/form/datepicker";
import moment from 'moment';
import _ from 'lodash';
import formats from 'dictionaries/formats';
import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import GlobalLoaderComponent from "components/ui/global-loader";
import './style.less';

@propTypes({
    barChartFillColor: PropTypes.string
})

@defaultProps({
    barChartFillColor: ''
})

export default class TransportWorkWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            from: moment().subtract(1, 'day').format(formats.DATE_API),
            to: moment().subtract(1, 'day').format(formats.DATE_API),
            data: {
                organizationUnits: [],
                unitsByDate: []
            },
            chartType: 'bar'
        };
    }

    componentWillMount() {
        this.loadData();
    }

    getUnits() {
        return api.organizational_units.getUnits();
    }

    async loadData() {
        this.setState({isError: false, isLoading: true});

        try {
            const response = await this.getUnits();

            const data = {
                organizationUnits: _.map(response.payload.items, (item) => _.pick(item, ['uuid', 'name'])),
                unitsByDate: [
                    {
                        date: '01.01.20017', total: 20, plan: 10, fact: 10,
                        config: {
                            plan: {
                                name: 'План',
                                color: '#5caa5e'
                            },
                            fact: {
                                name: 'Факт',
                                color: '#3398aa'
                            }
                        }
                    },
                    {
                        date: '02.01.20017', total: 25, plan: 20, fact: 5,
                        config: {
                            plan: {
                                name: 'План',
                                color: '#5caa5e'
                            },
                            fact: {
                                name: 'Факт',
                                color: '#3398aa'
                            }
                        }
                    },
                    {
                        date: '03.01.20017', total: 35, plan: 25, fact: 35,
                        config: {
                            plan: {
                                name: 'План',
                                color: '#5caa5e'
                            },
                            fact: {
                                name: 'Факт',
                                color: '#3398aa'
                            }
                        }
                    }
                ]
            };

            this.setState({data, isError: false, isLoading: false});
        }
        catch (e) {
            this.setState({isError: true, isLoading: false});
            error(e).showErrors();
        }
    }

    fromChange = ({target: {value}}) => {
        this.setState({from: value});
    };

    toChange = ({target: {value}}) => {
        this.setState({to: value});
    };

    onChartTypeButtonClick = () => {
        const chartType = this.state.chartType === 'bar' ? 'line' : 'bar';
        this.setState({ chartType });
    };

    renderContent() {
        return (
            <div className="full-size">
                <div className="tools-wrapper">
                    <div className="tools-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange}/>
                    </div>
                    <div className="tools-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange}/>
                    </div>
                    <div className="tools-item select-wrapper">
                        <WidgetSelector title="Транспортное предприятие" items={this.state.data.organizationUnits}/>
                    </div>
                    <div className="tools-item">
                        <div className="chart-type-button-wrapper">
                            <button className="chart-type-button" onClick={this.onChartTypeButtonClick} title="Тип графика">
                                <i className="chart-type-icon"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="chart-wrapper">
                    {this.state.chartType === 'bar' ?
                        <BarChart data={this.state.data.unitsByDate}
                                  xDataKey="date"
                                  yDataKey="total"
                                  configKey="config"
                                  color={this.props.barChartFillColor}
                        />
                        :
                        <LineChart data={this.state.data.unitsByDate}
                                  xDataKey="date"
                                  yDataKey="total"
                                  configKey="config"
                                  color={this.props.barChartFillColor}
                        />
                    }
                </div>
            </div>
        );
    }

    render() {
        const content = this.state.isError ? null : this.state.isLoading ? <GlobalLoaderComponent/> : this.renderContent();

        return (
            <div className="transport-work-widget">
                {content}
            </div>
        );
    }
}