import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import GlobalLoaderComponent from "components/ui/global-loader";
import Page from 'components/ui/page';
import {getDisplays} from "store/reducers/kiutr/displays";
import MapComponent from "components/ui/map";
import Button from "components/ui/button";
import CheckboxDropdown from "components/ui/checkbox-dropdown";
import _ from 'lodash';
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import debounce from 'throttle-debounce/debounce';
import {getRoutes} from "store/reducers/routes/routes";
import systems from "dictionaries/systems";
import PointMarker from "components/ui/map/markers/point-marker";
import {getStopPoints} from "store/reducers/geo/stop-points";
import DisplayMarker from "components/ui/map/markers/display-marker";
import './index.less';
import DirectionalPolyline from "components/ui/map/objects/directional-polyline";
import DirectionalGeojson from "components/ui/map/objects/directional-geojson";
import MapGeojson from "components/ui/map/base/geojson";


@connect(state => ({}), {getDisplays, getUnits, getRoutes})
export default class DisplaysMapComponent extends Component {

    state = {
        loading: false,
        displays: [],
        units: [],
        selectedUnits: {},
        routeRegistries: [],
        selectedRouteRegistries: {},
    };

    loadRouteRegistriesDebounce = debounce(1000, ::this.loadRouteRegistries);

    componentDidMount() {
        this.loadDisplays();
        this.loadUnits();
    }

    async loadDisplays() {
        this.setState({loading: true});
        const response = await this.props.getDisplays({
            pagination: {
                page: 1,
                limit: 1000,
            },
            response_data: [
                'items/uuid',
                'items/stop_point/latitude',
                'items/stop_point/longitude',
                'items/stop_point/title',
                'items/unit_uuid',
                'items/routes',
                'items/stop_point_uuid',
                'items/identifier',
                'items/last_success_update',
            ],
        });
        this.setState({loading: false});

        if (response.isOk) {
            await this.setState({
                displays: response.payload.items,
            });
            this.focusOnDisplay();
        } else {
            response.showErrors();
        }
    }

    focusOnDisplay() {
        const uuid = this.props.location.query.uuid;
        if (!uuid) {
            return;
        }

        const display = _.find(this.state.displays, {uuid});
        if (display) {
            this.refs.map.getWrappedInstance().flyTo([
                _.get(display, 'stop_point.latitude'),
                _.get(display, 'stop_point.longitude'),
            ], 13);
        }
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            filters: {
                withComponent: 'kiutr',
            },
            response_data: [
                'items/uuid',
                'items/name',
            ],
        });

        if (response.isOk) {
            this.setState({
                units: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async loadRouteRegistries() {
        const units = _.keys(_.pickBy(this.state.selectedUnits, item => !!item));

        const response = await this.props.getRoutes({
            pagination: {
                page: 1,
                limit: 10000,
            },
            filters: {
                withCarriers: units,
                withoutGeometry: true,
            },
            response_data: [
                'items/uuid',
                'items/number',
                'items/title',
                'items/geometry',
            ],
        });
        if (response.isOk) {
            const routeRegistries = _.sortBy(_.map(response.payload.items, routeRegistry => ({
                value: _.get(routeRegistry, 'uuid'),
                label: `${_.get(routeRegistry, 'number')} ${_.get(routeRegistry, 'title')}`,
                geometry: _.get(routeRegistry, 'geometry'),
            })), 'label');

            await this.setState({
                routeRegistries,
                selectedRouteRegistries: (this.state.selectedRouteRegistries === null) ? _.mapValues(_.keyBy(routeRegistries, 'value'), route => true) : this.state.selectedRouteRegistries,
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;

        return (
            <Page pageId="DisplaysMapComponent"
                  className="r-block_noIndent"
                  title={`${systems[this.props.params.component]} → Остановочные табло`}
                  headerContents={this.renderHeaderContents()}>
                {loader}
                {this.renderContent()}
            </Page>
        );
    }

    gotoTable() {
        this.props.router.push('/kiutr/displays/table');
    }

    gotoStopPoint() {
        this.props.router.push('/kiutr/displays/stoppoint');
    }

    renderHeaderContents() {
        return [
            <Button key="table" text="Таблица" color="dark"
                    className="top-menu__button top-menu__button_recovery" onClick={::this.gotoTable}/>,
            (window.RNIS_SETTINGS.shedule_stop_points_page ?
                        <Button key="stoppoint" text="Остановки" color="dark"
                        className="top-menu__button top-menu__button_recovery" onClick={::this.gotoStopPoint}/>
                        : null),
            <CheckboxDropdown
                key="units-select"
                items={this.state.units}
                selectedItems={this.state.selectedUnits}
                toggleSelectedItem={::this.toggleSelectedUnit}
                contextKey="display.unit-select"
                contextDefault="Балансодержатель"
                icon="organizer"
            />,
            <CheckboxDropdown
                key="routes-select"
                items={this.state.routeRegistries}
                selectedItems={this.state.selectedRouteRegistries}
                toggleSelectedItem={::this.toggleSelectedRouteRegistry}
                contextKey="display.route-select"
                contextDefault="Выбор маршрутов"
                icon="route"
            />,
        ];
    }

    async toggleSelectedUnit(unitUuid) {
        let selectedUnits = this.state.selectedUnits || {};
        selectedUnits[unitUuid] = !selectedUnits[unitUuid];
        await this.setState({selectedUnits});

        this.loadRouteRegistriesDebounce();
    }

    async toggleSelectedRouteRegistry(routeRegistryUuid) {
        let selectedRouteRegistries = this.state.selectedRouteRegistries || {};
        selectedRouteRegistries[routeRegistryUuid] = !selectedRouteRegistries[routeRegistryUuid];
        await this.setState({selectedRouteRegistries});
    }

    renderContent() {
        return (
            <MapComponent
                ref="map"
                showLayers={false}
                showSearch={false}
            >
                {_.map(this.getDisplays(), ::this.renderDisplay)}
                {this.renderRoutes()}
            </MapComponent>
        );
    }

    getDisplays() {
        let displays = this.state.displays;

        const units = _.keys(_.pickBy(this.state.selectedUnits, item => !!item));
        if (units.length > 0) {
            displays = _.filter(displays, display => _.indexOf(units, display.unit_uuid) !== -1);
        }

        const routes = _.keys(_.pickBy(this.state.selectedRouteRegistries, item => !!item));
        if (routes.length > 0) {
            displays = _.filter(displays, display => _.intersection(routes, display.routes).length > 0);
        }

        return displays;
    }

    renderDisplay(display) {
        return (
            <DisplayMarker
                key={display.uuid}
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                latitude={display.stop_point.latitude}
                longitude={display.stop_point.longitude}
                display={display}
                onEditClick={this.onDisplayEditClick.bind(this, display.uuid)}
            />
        );
    }

    onDisplayEditClick(uuid) {
        this.props.router.push(`/kiutr/displays/table/${uuid}`);
    }

    renderRoutes() {
        const routeUuids = _.keys(_.pickBy(this.state.selectedRouteRegistries, item => !!item));

        return _.map(this.state.routeRegistries, (route) => {
            if (_.indexOf(routeUuids, route.value) !== -1) {
                return this.renderRoute(route);
            }
            return null;
        });
    }

    renderRoute(route) {
        let geometry = _.cloneDeep(route.geometry);
        geometry.coordinates = _.values(geometry.coordinates);

        return (
            <DirectionalGeojson
                key={route.value}
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                geometry={geometry}
                tooltip={route.label}
            />
        );
    }
}