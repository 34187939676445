import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import {getUserGeoObjects} from "store/reducers/user-map-objects/object_editor";
import {getWorkGroups} from 'store/reducers/kurs/work_groups'
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import classNames from 'classnames';
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUnits} from "store/reducers/organizational_units/units";
import {
    getContractWork, createContractWork, updateContractWork,
    deleteContractWork
} from "store/reducers/kurs/contract_works";
import {getContract} from "store/reducers/kurs/contracts";
import PageModal from 'components/ui/page-modal';
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import currentUser from 'helpers/current-user';
import GlobalLoaderComponent from "components/ui/global-loader";
import ContextTooltip from "components/ui/context-tooltip";
import * as alerts from "helpers/alerts";
import Button from "components/ui/button";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getRoads} from "store/reducers/kurs/roads";
import {getRoadParts} from "store/reducers/kurs/road_parts";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getContract, getContractWork, getWorkGroups, createContractWork, updateContractWork, deleteContractWork})

export default class Editor extends BaseEditor {

    getFullTitle() {
        return this.isRepair() ? `Создание/редактирование работы по ${window.RNIS_SETTINGS.rename_contracts ? 'подрядному обязательству' : 'контракту'}` : 'Создание/редактирование объекта обслуживания';
    }

    modelClass = 'App\\Model\\ContractWork';
    modalClassName = 'b-modal-contract edit-work-contract-modal';

    state = {
        work_groups: [],
    }

    isRepair() {
        return this.props.type === 'works';
    }

    componentDidMount() {
        super.componentDidMount();

        this.loadContract(this.props.contractUuid);

        if (window.RNIS_SETTINGS.CITY_MURMANSK) {
            this.loadWorksGroups();
        }
    }

    async loadWorksGroups(){
        const response = await this.props.getWorkGroups({
            filters: {
                withContract: [this.props.contractUuid]
            },
        });

        if (response.isOk) {
            this.setState({
                work_groups: response.payload.items.map((workGroup) => ({
                    document: workGroup,
                    value: workGroup.uuid,
                    label: workGroup.name,
                })),
            })
        } else {
            response.showErrors();
        }
    }

    async loadData(uuid) {
        return await this.props.getContractWork(uuid);
    }

    async loadContract(contractUuid) {
        const response = await this.props.getContract(contractUuid);
        if (response.isOk) {
            return this.setState({
                contract: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    normalizeContractWork(contractWork) {
        const workGroups = [];
        (contractWork.work_groups || []).forEach(workGroupUuid => {
            if (typeof workGroupUuid === 'string') {
                const workGroup = this.state.work_groups.find((wg) => wg.value === workGroupUuid);
                workGroups.push(workGroup.document);
            } else {
                workGroups.push(workGroupUuid)
            }
        });
        contractWork.work_groups = workGroups;
        return contractWork;
    }

    async createItem(data) {
        const contractWork = ::this.normalizeContractWork(data);
        return await this.props.createContractWork(contractWork);
    }

    async updateItem(data) {
        const contractWork = ::this.normalizeContractWork(data);
        return await this.props.updateContractWork(contractWork);
    }

    getForm(item, onSubmit) {
        if (!this.state.contract) {
            return null;
        }
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                contract={this.state.contract}
                work_groups={this.state.work_groups}
                isRepair={this.isRepair()}
                errors={this.state.errors}
            />
        );
    }

    deleteItem() {
        return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            const response = await this.props.deleteContractWork({
                uuid: this.props.uuid,
            });
            if (response.isOk) {
                this.props.onSubmit();
            } else {
                response.showErrors();
            }
        });
    }

    render() {

        let form;
        let buttons;
        let onSubmit;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {
            if (this.state.item) {
                form = this.getForm(this.state.item, ::this.edit);

                onSubmit = ::this.onEdit;

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            <ContextTooltip key="base-table-list.delete" code="base-table-list.delete">
                                <ModalTopMenuListItem
                                    className="b-icon-link_params b-icon-link_icon_basket"
                                    onClick={::this.deleteItem}/>
                            </ContextTooltip>

                            <ContextTooltip key="base-editor.print" code="base-editor.print" default="Печать">
                                <ModalTopMenuListItem
                                    className="b-icon-link_icon_print"
                                    onClick={::this.print}
                                />
                            </ContextTooltip>

                            <ModalTopMenuListSeparator key="separator"/>
                        </ModalTopMenuList>

                        <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                            <ModalTopMenuButton
                                className="_close"
                                onClick={::this.props.onClose}
                            />
                        </ContextTooltip>
                    </ModalTopMenuButtons>
                );
            }
        } else {
            form = this.getForm({}, ::this.create);

            onSubmit = ::this.onCreate;

            buttons = (
                <ModalTopMenuButtons>
                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={::this.props.onClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );
        }

        return (
            <PageModal
                header={{title: this.getFullTitle(), buttons}}
                onClose={this.props.onClose}
                className={`profile-modal b-modal-${this.props.mode} ${this.modalClassName}`}
                withFade={this.withFade}
            >
                {loader}
                {form}
                <div className="b-modal__footer">
                    <div className="b-modal__footer-txt">Вы хотите сохранить все изменения?</div>
                    <Button size="md" color="white" shadow="gray" className="b-button_cancel" text="Отменить"
                            onClick={this.props.onClose}/>
                    <Button size="md" color="red" className="b-button_save" text="Сохранить"
                            onClick={onSubmit}/>
                </div>
            </PageModal>
        );
    }
}

const GEOMETRY_TYPES =  [
    {
        label: 'Участок дороги',
        value: 'road_part'
    },
    {
        label: 'Объект',
        value: 'user_geo_object'
    }
]

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    contract: PropTypes.object,
    isRepair: PropTypes.bool,
})

@connect((state) => ({}), {
    getDictionaryList,
    getUnits,
    getRoads,
    getRoadParts,
    getUserGeoObjects,
    getWorkGroups,
}, null, {withRef: true})


class EditorForm extends BaseEditorFormComponent {
    state = {
        contract_work: {},
        areas: [],
        units: [],
        work_types: [],
        measures: [],
        maintenance_groups: [],
        communal_municipalities: [],
        communal_municipality_uuid: '',
        geometry_types: [
            {
                label: 'Участок дороги',
                value: 'road_part'
            },
            {
                label: 'Объект',
                value: 'user_geo_object'
            },
        ],
    };


    getData() {
        return this.state.contract_work;
    }

    async componentWillMount() {
        let contract_work = this.props.data;
        contract_work.contract_uuid = this.props.contract.uuid;
        contract_work.work_groups = (contract_work.work_groups || []).map((workGroup) => workGroup.uuid)

        await this.setState({
            contract_work,
        });

        this.loadUnits(_.filter(this.props.contract.subcontractors));


        const directories = [
            'areas',
            'work_types',
            'measures',
            'maintenance_groups',
        ];

        if (window.RNIS_SETTINGS.is_need_communal_municipality) {
            directories.push('communal_municipalities');
        }

        this.loadDictionaries(directories);
    }

    async componentDidMount() {

        if(window.RNIS_SETTINGS.different_object_types_in_contract) {
            // т.к. и объект и участок приходит в одном и том же поле road_uuid,
            // проверям, что это - участок или объект
            const road_uuid = this.state.contract_work.road_uuid

            if(road_uuid) {
                const result_roads = await this.props.getRoadParts({
                    search: road_uuid,
                    pagination: {
                        page: 1,
                    },
                });
                if(result_roads.payload.items.length) {
                    this.setValue('contract_work.geometry_type', 'road_part');
                    this.setValue('communal_municipality_uuid', result_roads.payload.items[0].communal_municipality_uuid)
                } else {
                    const responseUserObject = await this.props.getUserGeoObjects({
                        search: road_uuid,
                        pagination: {
                            page: 1,
                        },
                    })

                    if (responseUserObject.isOk) {
                        this.setValue('contract_work.geometry_type', 'user_geo_object');
                        if (responseUserObject.payload.items.length) {
                            this.setValue('communal_municipality_uuid', responseUserObject.payload.items[0].communal_municipality_uuid);
                        }
                    } else {
                        responseUserObject.showErrors();
                    }
                }
            }
        }
    }

    async loadUnits(unitUuids) {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withUuid: unitUuids,
            },
        });
        if (response.isOk) {
            this.setState({
                units: _.sortBy(_.map(response.payload.items, (item) => ({
                    label: item.name,
                    value: item.uuid,
                })), 'label'),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.contract_work, path, defaultValue);
    }

    renderLengthRoad() {
        return (
            <span>
                <Block   Block title="Начальный км.">
                    {this.textInput('contract_work.start_at')}
                </Block>
                <Block title="Конечный км.">
                    {this.textInput('contract_work.end_at')}
                </Block>
                <Block title="Протяженность участка">
                    {this.get('length')}
                </Block>
            </span>
        )
    }

    renderVolumeObject() {
        return (
            <Block title="Объем работ">
                {this.textInput('contract_work.volume')}
            </Block>
        )
    }

    render() {
        return (
            <div>
                <div className="b-modal__block">
                    {this.props.isRepair ? (
                        <Block title="Дорога">
                            {this.selectAsync('contract_work.road_uuid', ::this.loadRoadRepairParts, {
                                onChange: ::this.onRoadPartChange,
                            })}
                        </Block>
                    ) : (
                        window.RNIS_SETTINGS.different_object_types_in_contract ? (
                            <span>
                                <Block title="Вид объекта">
                                    {this.select('contract_work.geometry_type', GEOMETRY_TYPES, {
                                        onChange: ::this.onChangeTypeObject,
                                    })}
                                </Block>
                                {
                                    this.state.contract_work.geometry_type === 'road_part' ? (
                                        <span>
                                            {window.RNIS_SETTINGS.is_need_communal_municipality ? (
                                                <Block title="Муниципальное образование">
                                                    {this.select('communal_municipality_uuid', this.state.communal_municipalities)}
                                                </Block>
                                            ) : null}
                                            <Block title="Участок дороги">
                                                {this.selectAsync('contract_work.road_uuid', ::this.loadRoadParts, {
                                                    onChange: ::this.onRoadPartChange,
                                                })}
                                            </Block>
                                        </span>

                                    ) : this.state.contract_work.geometry_type === 'user_geo_object' ? (
                                        <span>
                                            {window.RNIS_SETTINGS.is_need_communal_municipality ? (
                                                <Block title="Муниципальное образование">
                                                    {this.select('communal_municipality_uuid', this.state.communal_municipalities)}
                                                </Block>
                                            ) : null}
                                            <Block title="Объект">
                                                {this.selectAsync('contract_work.road_uuid', ::this.loadUserGeoObjects, {
                                                    onChange: ::this.onChangeGeoObject,
                                                })}
                                            </Block>
                                        </span>
                                    ) : null
                                }
                            </span>
                        ) : (
                            <span>
                                 {window.RNIS_SETTINGS.is_need_communal_municipality ? (
                                    <Block title="Муниципальное образование">
                                        {this.select('communal_municipality_uuid', this.state.communal_municipalities)}
                                    </Block>
                                ) : null}
                                <Block title="Объект">
                                    {this.selectAsync('contract_work.road_uuid', ::this.loadRoadParts, {
                                        onChange: ::this.onRoadPartChange,
                                    })}
                                </Block>
                            </span>
                        )
                    )}
                    {(!this.props.isRepair) ? (
                        <Block title="Группа по содержанию">
                            {this.select('contract_work.maintenance_group_uuid', this.state.maintenance_groups, {
                                disabled: true,
                            })}
                        </Block>
                    ) : null}
                    {
                        !window.RNIS_SETTINGS.different_object_types_in_contract ? (
                            this.renderLengthRoad()
                        ) : (
                            this.state.contract_work.geometry_type === 'road_part' ? (
                                this.renderLengthRoad()
                            ) :  (
                                this.renderVolumeObject()
                            )
                        )
                    }
                    {!window.RNIS_SETTINGS.CITY_MURMANSK ? (
                        <Block title="Субподрядчик">
                            {this.select('contract_work.unit_uuid', this.state.units)}
                        </Block>
                    ):null}
                    {this.props.isRepair ? ([
                        <Block key="date_from" title="Дата начала">
                            {this.datepicker('contract_work.date_from')}
                        </Block>,
                        <Block key="date_to" title="Дата окончания">
                            {this.datepicker('contract_work.date_to')}
                        </Block>,
                    ]) : null}
                    {window.RNIS_SETTINGS.CITY_MURMANSK ? (
                        <Block key="work_groups" title="Группы объектов">
                            {this.select('contract_work.work_groups', this.props.work_groups, { multi: true })}
                        </Block>
                    ) : null}
                    {this.props.isRepair ? (
                        <TableContainer>
                            <div className="Table">
                                <table className="b-table b-table-no-hover">
                                    <thead>
                                    <tr className="b-table__header wrap-normal">
                                        <th>Виды работ</th>
                                        <th>Ед. измер. (справ.)</th>
                                        <th>Объем работ</th>
                                        <th>Срок начала</th>
                                        <th>Срок окончания</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.renderWorks()}
                                    </tbody>
                                </table>
                            </div>
                            <span className="add-line" onClick={::this.addWork}>Добавить строку</span>
                        </TableContainer>
                    ) : null}
                </div>
            </div>
        );
    }

    onChangeTypeObject(e) {
        const value = e ? e.value : null;

        this.setValue('contract_work.geometry_type', value);
        this.setValue('contract_work.road_uuid', null);
        this.setValue('contract_work.end_at', 0);
        this.setValue('contract_work.start_at', 0);
        this.setValue('contract_work.length', 0);
    }

    onRoadPartChange(e) {
        const value = e ? e.value : null;

        this.setValue('contract_work.road_uuid', value);
        if (e) {
            this.setValue('contract_work.start_at', 0);
            this.setValue('contract_work.end_at', e.length);
        }
        if (!this.props.isRepair) {
            this.setValue('contract_work.maintenance_group_uuid', e ? e.maintenance_group_uuid : null);
        }
    }

    onChangeGeoObject(e) {
        const value = e ? e.value : null;
        //Аналитик сказал отправлять объект в том же поле, что и участок дороги
        this.setValue('contract_work.road_uuid', value);
       // this.setValue('contract_work.user_geo_object_uuid', value);
    }

    async loadRoadRepairParts(input, callback) {
        let meta = {
            pagination: {
                page: 1,
                limit: 20,
            },
        };
        if (!input) {
            input = this.get('road_uuid');
            meta.column_search = [
                {
                    column: 'uuid',
                    value: input.toString(),
                },
            ];
        } else {
            meta.search = input.toString();
        }
        const result = await this.props.getDictionaryList('kurs_road_repair_parts', meta, false);

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.documents.map(i => ({
                    label: i.name,
                    value: i.uuid,
                    start: i.start,
                    end: i.end,
                    length: i.length,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }
    async loadEmptyDirectories(type, callback) {

        let result

        const meta = { filters: { withUuid: [null] } }
        switch (type) {
            case 'road_part':
                if (this.state.communal_municipality_uuid) {
                    meta.filters.withCommunalMunicipalities = [this.state.communal_municipality_uuid]
                }
                result =  await this.props.getRoadParts(meta)
                break;
            case 'geo_object':
                if (this.state.communal_municipality_uuid) {
                    meta.filters.withCommunalMunicipalitiesUuid = [this.state.communal_municipality_uuid]
                }
                result =  await this.props.getUserGeoObjects(meta)
                break;
        }
        if (result.isOk) {
            callback(null, {
                options: {label: '', value: ''},
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    async loadRoadParts(input, callback) {

        let result;
        const meta = { pagination: { page: 1 } };
        if (this.state.communal_municipality_uuid) {
            meta.filters = {
                withCommunalMunicipalities: [this.state.communal_municipality_uuid],
            }
        }

        if (!input && !this.state.contract_work.road_uuid) {
            return this.loadEmptyDirectories('road_parts', callback);
        } else if (!input && this.state.contract_work.road_uuid) {
            meta.search = this.state.contract_work.road_uuid


            result = await this.props.getRoadParts(meta);
        } else {
            meta.search = input;
            result = await this.props.getRoadParts(meta);
        }

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: i.name,
                    value: i.uuid,
                    maintenance_group_uuid: i.maintenance_group_uuid,
                    start: i.start,
                    end: i.end,
                    length: i.length,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    async loadUserGeoObjects(input, callback) {
        let response;
        const meta = { pagination: { page: 1 } };

        if (this.state.communal_municipality_uuid) {
            meta.filters = { withCommunalMunicipalitiesUuid: [this.state.communal_municipality_uuid] }
        }

        if (!input && !this.state.contract_work.road_uuid) {
            return this.loadEmptyDirectories('geo_object', callback);
        } else if (!input && this.state.contract_work.road_uuid) {
            meta.search = this.state.contract_work.road_uuid
            response = await this.props.getUserGeoObjects(meta);

        } else {
            meta.search = input
            response = await this.props.getUserGeoObjects(meta);
        }
        if (response.isOk) {
            callback(null, {
                options: _.sortBy(_.map(response.payload.items, item => ({
                    value: item.uuid,
                    label: item.title,
                    geometry: item.geometry,
                    title: item.title,
                    uuid: item.uuid,
                    volume: item.volume,
                })), 'label'),
                complete: false
            });
        } else {
            response.showErrors();
        }
    }


    renderWorks() {
        const list = this.get('works', []);

        return list.map(::this.renderWorkItem);
    }

    addWork(e) {
        e.preventDefault();

        let contract_work = this.state.contract_work;
        contract_work.works = contract_work.works || [];
        contract_work.works.push({});

        this.setState({contract_work});
    }

    deleteWork(index, e) {
        e.preventDefault();

        let contract_work = this.state.contract_work;
        contract_work.works.splice(index, 1);
        this.setState({contract_work});
    }

    renderWorkItem(work, index) {
        return (
            <tr key={index} className="_text_normal">
                <td className="input-cell">
                    {this.select(`contract_work.works.${index}.work_type_uuid`, this.state.work_types)}
                </td>
                <td className="input-cell">
                    {this.select(`contract_work.works.${index}.measure_uuid`, this.state.measures, {
                        disabled: true,
                    })}
                </td>
                <td className="input-cell">
                    {this.textInput(`contract_work.works.${index}.volume`, {
                        type: 'number',
                        positive: true,
                    })}
                </td>
                <td className="input-cell expand-top">
                    {this.datepicker(`contract_work.works.${index}.date_from`)}
                </td>
                <td className="input-cell expand-top">
                    {this.datepicker(`contract_work.works.${index}.date_to`)}
                </td>
                <td>
                    <a href="javascript:void(0)" onClick={this.deleteWork.bind(this, index)}>x</a>
                </td>
            </tr>
        );
    }

    async setValue(field, value) {
        await super.setValue(field, value);

        if ((field === 'contract_work.start_at') || (field === 'contract_work.end_at')) {
            this.calculateLength();
        }

        const matches = /^contract_work\.works\.([0-9]+)\.work_type_uuid$/.exec(field);
        if (matches) {
            const index = matches[1];
            this.setValue(`contract_work.works.${index}.measure_uuid`, _.get(_.find(this.state.work_types, {value}), 'document.measure_uuid'));
        }
    }

    calculateLength() {
        const start = this.get('start_at');
        const end = this.get('end_at');
        const length = Math.abs(end - start) || 0;

        this.setValue('contract_work.length', Math.round(length * 100) / 100);
    }
}
