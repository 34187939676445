import React, {Component} from "react";
import {connect} from "react-redux";

import Page from "../../ui/page";
import Loader from "../../ui/loader";
import Button from "components/ui/button";
import BaseEditorFormComponent from "components/base/base-editor-form";

import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";

import {getOptions, setUserOptionValue} from "store/reducers/settings";
import {getUser, updateUser} from "store/reducers/staffing/staffing_editor";
import {getUserPositions} from "store/reducers/staffing/positions/positions";
import {get, forEach, isBoolean, merge} from 'lodash';
import currentUser from 'helpers/current-user';

import "./profile.less";
import systems from "dictionaries/systems";
import GlobalLoaderComponent from "components/ui/global-loader";
import stopPointsCache from 'helpers/components/cache/StopPointsCache';
import stopPointsBoundingBoxCache from 'helpers/components/cache/StopPointsBoundingBoxCache';
import * as alerts from "helpers/alerts";

const newPasswordKey = 'user.password';
const confirmPasswordKey = 'user.confirm_password';
const newPasswordConfirmKey = 'user.password_confirm';
const currentPasswordKey = 'user.current_password';
import {getUnits} from "store/reducers/organizational_units/units";
import _ from 'lodash';

@connect(state => ({}), {
    getOptions,
    setUserOptionValue,
    getUser,
    getUserPositions,
    updateUser,
    getUnits,
})

export default class ProfileComponent extends Component {

    state = {
        loading: true,
        saving: false,
        data: null,
        user: null,
        errors: {}
    };

    async componentDidMount() {
        await this.loadOptions();
    }

    async loadOptions() {
        this.setState({
            loading: true
        });

        return await Promise.all([this.props.getOptions(), this.props.getUser()])
            .then((data) => {
                const optionsResponse = data[0];
                const userResponse = data[1];

                let optionsData, userData;

                if (!optionsResponse.success) {
                    optionsResponse.showErrors();
                } else {
                    optionsData = get(optionsResponse.payload.items, currentUser.uuid(), {});
                }

                if (!userResponse.isOk) {
                    userResponse.showErrors();
                } else {
                    userData = userResponse.payload;
                    this.props.getUserPositions(userData.uuid).then((responseUserPositions) => {
                        if (responseUserPositions.isOk) {
                            userData.positions = responseUserPositions.payload.items;
                        } else {
                            responseUserPositions.showErrors();
                        }

                        this.setState({
                            data: optionsData,
                            user: userData,
                            loading: false
                        });
                    });
                }
            });
    }

    clearErrors() {
        this.setState({errors: {}});
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    async onSubmit(data) {
        this.clearErrors();
        this.startSave();

        let errors = {};

        const user = merge({}, this.state.user, {
            'password': get(data, newPasswordKey),
            'confirm_password': get(data, newPasswordConfirmKey),
            'current_password': get(data, currentPasswordKey),
        });

        delete data.user;

        for (let key in data) {
            let value = data[key];
            value = isBoolean(value) ? (value ? 1 : 0) : value;
            const response = await this.props.setUserOptionValue(key, value);

            if (!response.isOk) {
                errors[key] = response.validationErrors
            }
        }

        if (user['password'] != user['confirm_password']) {
            errors[confirmPasswordKey] = 'Новый пароль и подтверждение должны совпадать';
        }

        if (user['password'] && user['current_password'] && user['password'] == user['confirm_password']) {
            delete user.uuid;
            const response = await this.props.updateUser({
                ...user,
                roles: user.roles.map((role) => ({ uuid: role.uuid })),
            });

            if (!response.isOk) {
                response.showErrors();
                errors[currentPasswordKey] = response.validationErrors;
            } else {
                alerts.success('Пароль успешно изменен');
            }
        }

        this.endSave();
        this.setState({
            errors: errors
        });

        this.loadOptions();
    }

    onClose() {

    }

    render() {
        const loader = (this.state.loading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;
        let form = null;

        if (this.state.data) {
            form = <ProfileEditorComponent
                data={this.state.data}
                onSubmit={::this.onSubmit}
                onClose={::this.onClose}
                errors={this.state.errors}
            />;
        }
        return (
            <Page pageId="Main" title={`Профиль`}>
                {loader}

                {form}
            </Page>
        )
    }
}


@connect(state => ({}), {getUnits})
class ProfileEditorComponent extends BaseEditorFormComponent {

    async componentDidMount() {
        await this.setState(this.props.data);
    }

    async componentWillMount() {
        window.RNIS_SETTINGS.TIMESOZESETTING ? this.loadGMT() : null
    }

    async loadGMT() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withComponent: 'road',
            },
        });

        if (response.isOk) {
            await this.setState({
                units: _.map(response.payload.items, (unit) => ({
                    value: unit.uuid,
                    label: unit.name,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    /**
     * @param {String} field
     * @return {String}
     */
    getValue(field) {
        let value = get(this.getState(), field);

        switch (field) {
            case 'vehicle-clusters-active':
                value = value == 1;
                break;
        }

        return value;
    }

    submit() {
        let data = {};

        forEach(this.state, (option, key) => {
            if (!this.isChanged(key, option)) {
                return;
            }

            this.state[key] = option;
            data[key] = option;
        });

        this.props.onSubmit(data);
    }

    isChanged(key, value) {
        return get(this.props.data, key) !== value;
    }

    renderChangePassword() {
        return (
            <div>
                <Block title="Текущий пароль">
                    {this.textInput(currentPasswordKey, {type: 'password'})}
                </Block>
                <Block title="Новый пароль">
                    {this.textInput(newPasswordKey, {
                        type: 'password',
                        ref: 'password',
                        onPasswordOpenedChange: () => this.forceUpdate()
                    })}
                </Block>
                {(!this.refs.password || !this.refs.password.isPasswordOpened()) ? (
                    <Block title="Подтверждение нового пароля">
                        {this.textInput(newPasswordConfirmKey, {type: 'password'})}
                    </Block>
                ) : null}
            </div>
        );
    }

    renderChangeGMT() {
        return (
            <div>
                <Block title="Текущий часовой пояс">
                    {this.select('task.unit_uuid', this.state.units)}
                </Block>
            </div>
        );
    }



    flushStopPointsCache() {
        stopPointsCache.flush();
        stopPointsBoundingBoxCache.flush();
    }

    render() {
        return (
            <div className="ProfileForm ProfileForm_wrap">
                <div className="row">
                    <div className="col-md-12">
                        <div className="ProfileForm__title">Настройки отображения элементов</div>
                        {this.renderCheckbox('vehicle-clusters-active', 'Кластеры ТС по умолчанию вкл/выкл')}
                        {!window.RNIS_SETTINGS.CITY_MURMANSK ? (
                            <Button text="Сбросить кэш остановок" width="auto" size="md"
                                    shadow="red"
                                    onClick={::this.flushStopPointsCache}/>
                        ) : null}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="ProfileForm__title">Сменить пароль доступа в {`${currentUser.user.title.SHORT_TITLE}`}</div>
                    </div>
                </div>

                <div>
                    {this.renderChangePassword()}
                </div>
                {window.RNIS_SETTINGS.TIMESOZESETTING ? (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ProfileForm__title">Сменить часовой пояс</div>
                        </div>
                    </div>
                ) : null}
                {window.RNIS_SETTINGS.TIMESOZESETTING ? (
                    <div>
                        {this.renderChangeGMT()}
                    </div>
                ) : null}

                <div className="ProfileForm__buttons-block">
                    <div className="ProfileForm__buttons-block-text">Вы хотите сохранить все изменения?</div>
                    <Button size="md" text="Сохранить" color="red" shadow="red" onClick={::this.submit}
                            disabled={!(this.refs.password && this.refs.password.isPasswordOpened()) && (_.get(this.state, newPasswordKey) !== _.get(this.state, newPasswordConfirmKey))}/>
                </div>

            </div>
        )
    }
}