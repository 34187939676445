import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import formats from "dictionaries/formats";
import moment from "moment";
import {formatMileage} from "helpers/math";
import runs from "dictionaries/runs";
import TableContainer from "../../../../../ui/Table/Container/TableContainer";
import {Cell, Row, StickyTable} from "react-sticky-table";
import Input from "../../../../../ui/form/input";
import '../../../../../modules/kiutr/routes/schedule/view.less'
import '../../../../../modules/kiutr/routes/schedule/turn.less'

export default class KiutrRouteScheduleTurnPrintComponent extends Component {

    render() {
        if (!this.props.turn) {
            return null;
        }

        return this.renderTurn();
    }

    getProductionRuns() {
        return _.filter(this.props.turn.runs, (run) => (run.type === 'production_forward' || run.type === 'production_reverse'));
    }

    getDays() {
        let days = [];

        if (this.props.schedule.monday) {
            days.push('Пн');
        }
        if (this.props.schedule.tuesday) {
            days.push('Вт');
        }
        if (this.props.schedule.wednesday) {
            days.push('Ср');
        }
        if (this.props.schedule.thursday) {
            days.push('Чт');
        }
        if (this.props.schedule.friday) {
            days.push('Пт');
        }
        if (this.props.schedule.saturday) {
            days.push('Сб');
        }
        if (this.props.schedule.sunday) {
            days.push('Вс');
        }
        if (this.props.schedule.holiday) {
            days.push('Праздник');
        }

        return days;
    }

    timeCellDblClick(editProductionRunValue, productionRunIndex, stopPointIndex) {
        this.setState({
            editProductionRunValue,
            editProductionRunIndex: productionRunIndex,
            editStopPointIndex: stopPointIndex,
        });
    }

    renderPointTime(group, productionRunIndex, run, runIndex) {
        let point = null;
        _.each(group.items, (item) => {
            point = _.find(item.inclusions, {
                route_variant_uuid: run.route_variant_uuid,
                is_forward: run.type === 'production_forward',
            });
            if (point) {
                return false;
            }
        });
        if (!point) {
            return (
                <div className="col col-10 bdn" key={`${productionRunIndex}:${runIndex}`}/>
            );
        }

        const routeVariant = _.find(this.props.routeVariants, {uuid: run.route_variant_uuid});
        if (!routeVariant) {
            return (
                <div className="col col-10 bdn" key={`${productionRunIndex}:${runIndex}`}/>
            );
        }

        const time = _.sumBy(_.filter(run.production_interval_map, (item) => {
            return item.index < point.index;
        }), 'interval');

        const timeObject = moment(run.start_time, formats.TIME).add(time, 'minutes');

        return (
            <div className="col col-10 bdn" key={`${productionRunIndex}:${runIndex}`}>
                {((this.props.editProductionRunIndex === runIndex) && (this.props.editStopPointIndex === point.index)) ? (
                    <Input
                        value={this.props.editProductionRunValue}
                    />
                ) : timeObject.format(formats.TIME)}
            </div>
        );
    }

    renderPointRow(productionRuns, group, index) {
        if (!group) {
            return null;
        }
        return (
            <div className="row" key={index} style={{'border-bottom': '1px solid black'}}>
                <div className="col col-10 bdn">{group.name}</div>
                {productionRuns.map(this.renderPointTime.bind(this, group, index))}
            </div>
        );
    }

    renderProductionRuns() {
        const productionRuns = this.getProductionRuns();

        return (
            <div className="table">
                <div className="row">
                    <div className="col col-10 bdn">Остановка/рейс</div>
                    {productionRuns.map((run, index) => (
                        <div className="col col-10 bdn" key={index} width="100px">{index + 1}</div>
                    ))}
                </div>
                {this.props.defaultVariant && this.props.defaultVariant.map(this.renderPointRow.bind(this, productionRuns))}
                <div className="row">
                    <div className="col col-10 bdn"><b>Пробег итоговый, км</b></div>
                    {productionRuns.map((run, index) => (
                        <div className="col col-10 bdn"
                             key={index}>
                            <b>{formatMileage(_.sumBy(_.filter(productionRuns, (item, runIndex) => runIndex <= index), 'distance') / 1000)}</b>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    renderTurn() {
        const defaultVariant = this.props.defaultVariant;
        let arr = window.location.pathname.match(/turn\/(.*)/);
        let turnNumber = arr.length ? arr[1] - 1 : 0;
        let turn = this.props.turns[turnNumber];

        const from = _.get(_.first(defaultVariant), 'name');
        const to = _.get(_.last(defaultVariant), 'name');

        const nullRun = _.find(this.props.turn.runs, {type: 'null'});

        const productionRuns = this.getProductionRuns();

        let lastTime = nullRun ? nullRun.start_time : '-';
        return (
            <div className="page print by-flex">
                <h1>Расписание движения маршрута
                    № {this.props.schedule.route_number} {this.props.schedule.route_name}</h1>
                <div className="clearfix"/>
                <p> Последнее изменение: {moment(this.props.schedule.updated_at).format(formats.DATETIME)}</p>
                <p>
                    <span className="bold">Время действия расписания</span>
                    &nbsp;с {moment(this.props.schedule.date_from).format(formats.DATE)}
                    &nbsp;по {moment(this.props.schedule.date_to).format(formats.DATE)}
                    <span className="days">{this.getDays().join(', ')}</span>
                </p>
                <div className="legend">
                    <div className="legend__row">
                        <div className="legend__cell">Начало</div>
                        <div className="legend__cell">{turn.start_at}</div>
                    </div>
                    <div className="legend__row">
                        <div className="legend__cell">Окончание</div>
                        <div className="legend__cell">{turn.end_at}</div>
                    </div>
                    <div className="legend__row">
                        <div className="legend__cell">Начало пересменки</div>
                        <div className="legend__cell">{}</div>
                    </div>
                    <div className="legend__row">
                        <div className="legend__cell">Окончание пересменки</div>
                        <div className="legend__cell">{}</div>
                    </div>
                </div>
                <div className="info-block">
                    Протяженность: {formatMileage(_.sum(_.map(this.props.turn.runs, (run) => (_.toInteger(run.distance)))) / 1000)}
                    км
                </div>
                <div className="schedule-content">
                    <div className="schedule-main">
                        {this.renderProductionRuns()}
                    </div>
                </div>

                {/* <div className="table">
                    <div className="row bdn">
                        <div className="col col-2 bdn">
                            <div className="row">
                                <div className="col col-6">
                                    <div className="table__header">Выход</div>
                                    <div className="table__content">
                                        <div className="table__content-row"><span>{this.props.turn.number}</span></div>
                                        <div className="table__content-row"><span>&nbsp;</span></div>
                                        <div className="table__content-row"><span>&nbsp;</span></div>
                                    </div>
                                </div>
                                <div className="col col-6 bdrn">
                                    <div className="table__header">Парк</div>
                                    <div className="table__content">
                                        <div className="table__content-row">
                                            <span>{nullRun ? nullRun.start_time : '-'}</span></div>
                                        <div className="table__content-row table__content-row_mh"><span>&nbsp;</span>
                                        </div>
                                        <div className="table__content-row"><span>&nbsp;</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-10 bdn">
                            <div className="row">
                                <div className="col col-1">
                                    <div className="table__header"></div>
                                    <div className="table__content">
                                        <div className="table__content-row"><span>Прибытие</span></div>
                                        <div className="table__content-row"><span>&nbsp;</span></div>
                                        <div className="table__content-row"><span>Убытие</span></div>
                                    </div>
                                </div>
                                {productionRuns.map((run, index) => {
                                    const nonProductionRuns = _.filter(this.props.turn.runs, (_run) => {
                                        return moment(_run.start_time, formats.TIME).isBefore(moment(run.start_time, formats.TIME)) &&
                                            ((lastTime !== '-') ? moment(_run.start_time, formats.TIME).isSameOrAfter(moment(lastTime, formats.TIME)) : true) &&
                                            _run.type !== 'production_forward' &&
                                            _run.type !== 'production_reverse';
                                    });

                                    const result = (
                                        <div key={index} className="col col-1">
                                            <div className="table__header">Пункт {(index % 2) ? 'Б' : 'А'}</div>
                                            <div className="table__content">
                                                <div className="table__content-row"><span>{lastTime}</span></div>
                                                <div
                                                    className="table__content-row table__content-row_g table__content-row_mh">{_.map(nonProductionRuns, (run) => {
                                                    return (
                                                        <span>{runs[run.type]}</span>
                                                    );
                                                })}
                                                </div>
                                                <div className="table__content-row"><span>{run.start_time}</span></div>
                                            </div>
                                        </div>
                                    );

                                    lastTime = moment(run.start_time, formats.TIME).add(run.time, 'minutes').format(formats.TIME);

                                    return result;
                                })}
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
        );
    }
}