import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import moment from "moment";
import formats from "dictionaries/formats";
import _ from 'lodash';
import Page from 'components/ui/page';
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import {
    getDuplicationMatrix, getDuplicationMatrixRouteParts,
    getDuplicationMatrixRoutes
} from "store/reducers/kiutr/duplication_matrix";
import GlobalLoaderComponent from "components/ui/global-loader";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import SelectFilter from "components/ui/select-filter";
import {getRouteVariants} from "store/reducers/routes/route_variants";
import Select from "components/ui/select";
import Block from "components/ui/form/block";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import {print} from "helpers/print";
import {
    duplicationMatrixPrintPart,
    duplicationMatrixPrintRoutes, duplicationMatrixPrintSummary
} from "components/modules/kiutr/DuplicationMatrix/Print/DuplicationMatrixPrint";
import {api} from "helpers/api";
import {makeResponse} from "helpers/response";
import download from 'downloadjs'
import TableComponent from "components/ui/Table/Component/TableComponent";
import Tables from "dictionaries/tables";
import $ from 'jquery';
import SearchField from "components/ui/search-field";
import {TableColumnFilterText} from "components/ui/Table/Column/Filter/TableColumnFilterText";
import classNames from 'classnames';
import {getRoutes} from "store/reducers/routes/route_editor";
import {TableColumnFilterDate} from "components/ui/Table/Column/Filter/TableColumnFilterDate";
import {TableColumnFilterDateSingle} from "components/ui/Table/Column/Filter/TableColumnFilterDateSingle";
import {getTableForPrint, printTable} from "helpers/print";
import {TableColumnFilterSelect} from "components/ui/Table/Column/Filter/TableColumnFilterSelect";

@connect(state => ({}), {
    getDuplicationMatrix,
    getDuplicationMatrixRoutes,
    getDuplicationMatrixRouteParts,
    getRoutes,
    getDictionaryList,
    getRouteVariants,
    getEntityNames,
})

export default class DuplicationMatrix extends Component {

    state = {
        isLoading: false,
        matrix: {},
        matrixRoutes: [],
        matrixParts: [],
        selectedRoute: null,
        routeVariants: {},
        routes: {},
        selectedType: 0,
        routeVariantUuid: null,
        direction: 'forward',
        stopPointUuid: null,
        stopPoint2Uuid: null,
        partLength: null,
        related: new EntityList,
        showTableFilters: false,
        index: 1,
    };

    tables = {};

    componentWillMount() {
        this.loadData();
        this.loadDictionaries([
            'transport_connection_types',
            'route_kinds',
        ]);
    }

    componentDidMount() {
        this.initTable('content');
    }

    initTable(table) {
        if (!this.refs[table]) {
            setTimeout(this.initTable.bind(this, table), 500);
            return;
        }

        if ($.fn.DataTable.isDataTable(this.refs[table])) {
            return;
        }
        let datatableConfig = {
            dom: 'lfrt<"t-pagination"i<"split"<"splitter">>p>',
            orderMulti: false,
            lengthChange: false,
            searching: true,
            ordering: true,
            paging: true,
            info: true,
            scrollCollapse: false,
            scrollY: '100%',

            pageLength: 25,

            oLanguage: {
                sProcessing: Tables.PROCESSING,
                oPaginate: {
                    sFirst: this.props.shortPagination ? '' : Tables.PAGINATION.FIRST,
                    sLast: this.props.shortPagination ? '' : Tables.PAGINATION.LAST,
                    sPrevious: this.props.shortPagination ? '' : Tables.PAGINATION.PREVIOUS,
                    sNext: this.props.shortPagination ? '' : Tables.PAGINATION.NEXT
                },
                sEmptyTable: Tables.EMPTY,
                sZeroRecords: Tables.EMPTY,
                sInfo: this.props.shortPagination ? Tables.INFO_SHORT : Tables.INFO,
                sInfoEmpty: Tables.INFOEMPTY,
                sInfoFiltered: " (отфильтровано из _MAX_)",
                select: {
                    rows: Tables.ROWS
                }
            },
        };
        this.tables[table] = $(this.refs[table]).DataTable(datatableConfig);

        const self = this;
        window.$(this.refs[table]).closest('.dataTables_wrapper').find('thead th').each(function () {
            const $this = window.$(this);
            const container = window.$(self.refs[table]);
            $this.on('mouseover', () => {
                const index = $this.index() + 1;
                container.find(`td:nth-child(${index})`).addClass('active');
            }).on('mouseout', () => {
                const index = $this.index() + 1;
                container.find(`td:nth-child(${index})`).removeClass('active');
            });
            const events = window.$._data(this, 'events');
            const clickEvent = events && events.click && events.click[0];
            if (clickEvent) {
                $this.unbind('click');
                $this.click((e) => {
                    if (window.$(e.target).is('th')) {
                        clickEvent.handler(e.originalEvent);
                    }
                })
            }
            const mouseDownEvent = events && events.mousedown && events.mousedown[0];
            if (mouseDownEvent) {
                $this.unbind('mousedown');
                $this.mousedown((e) => {
                    if (window.$(e.target).is('th')) {
                        mouseDownEvent.handler(e.originalEvent);
                    }
                })
            }
        });
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }

    async loadMatrixRelated() {
        const items = [this.state.matrix];
        const carriers = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'carriers')))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const units = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'units')))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const tos = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'tos')))), (uuid) => ({
            class: 'App\\Model\\TerritorialEntity',
            uuid: uuid,
            source: 'geo',
        }));

        const contracts = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'contracts')))), (uuid) => ({
            class: 'App\\Model\\Contract',
            uuid: uuid,
            source: 'geo',
        }));

        const routes = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'routes')))), (uuid) => ({
            class: 'App\\Model\\Route',
            uuid: uuid,
            source: 'geo',
        }));

        const transportConnectionTypes = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'transport_connection_types')))), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\TransportConnectionType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const routeTypes = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'route_types')))), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RouteType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const ratesTypes = _.map(_.uniq(_.filter(_.flatten(_.map(items, 'rates_types')))), (uuid) => ({
            class: 'App\\Dictionaries\\Common\\RatesType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(_.concat(
            carriers,
            units,
            tos,
            contracts,
            routes,
            transportConnectionTypes,
            routeTypes,
            ratesTypes
        ));

        if (response.isOk) {
            this.state.related.add(response);
        }
    }

    async loadData() {
        this.setState({isLoading: true});
        await Promise.all([
            this.loadMatrix(),
            this.loadMatrixRoutes(),
        ]);
        this.setState({isLoading: false});
    }

    async loadMatrix() {
        const response = await this.props.getDuplicationMatrix(this.props.params.uuid);

        if (response.isOk) {
            await this.setState({
                matrix: response.payload,
            });

            this.loadMatrixRelated();

            await this.loadRoutes(response.payload.matrix_routes);
        } else {
            response.showErrors();
        }
    }

    async loadMatrixParts() {
        if (!this.state.routeVariantUuid) {
            return;
        }

        const response = await this.props.getDuplicationMatrixRouteParts({
            filters: {
                withMatrix: this.props.params.uuid,
                withRoute: this.state.selectedRoute,
                withRouteVariant: this.state.routeVariantUuid,
                withDirection: this.state.direction,
            },
        });

        if (response.isOk) {
            this.setState({
                matrixParts: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadRoutes(uuids) {
        const response = await this.props.getRoutes({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            this.setState({
                routes: _.keyBy(response.payload.items, 'uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadRouteVariants(route) {
        const response = await this.props.getRouteVariants({
            filters: {
                withRoute: route.uuid,
            },
        });

        if (response.isOk) {
            this.setState({
                routeVariants: _.keyBy(response.payload.items, 'uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadMatrixRoutes() {
        const response = await this.props.getDuplicationMatrixRoutes({
            filters: {
                withMatrix: this.props.params.uuid,
            },
        });

        if (response.isOk) {
            this.setState({
                matrixRoutes: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadRelated() {
        if (!this.state.routeVariantUuid) {
            return;
        }

        const routeVariant = _.get(this.state.routeVariants, this.state.routeVariantUuid);
        if (!routeVariant) {
            return;
        }

        const stopPoints = _.map(_.uniq(_.filter(_.concat(_.map(routeVariant.forward_points || [], 'type_uuid'), _.map(routeVariant.reverse_points || [], 'type_uuid')))), (uuid) => ({
            class: 'App\\Model\\StopPoint',
            uuid: uuid,
            source: 'geo',
        }));

        const response = await this.props.getEntityNames(stopPoints);

        if (response.isOk) {
            this.state.related.add(response);

            this.forceUpdate();
        }
    }

    onClose() {
        this.props.router.push('/kiutr/orders?matrices=1');
    }

    async toggleTableFilters() {
        await this.setState({
            showTableFilters: !this.state.showTableFilters,
        });
    }

    renderHeaderContents() {
        return [
            <ContextTooltip key="base-table-list.filter" default="Фильтр">
                <IconButton icon="filter" tooltip="Фильтр" active={this.state.showTableFilters}
                            onClick={::this.toggleTableFilters}/>
            </ContextTooltip>,
        ];
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="base-table-list.export"
                            default="Экспорт в Excel">
                <IconButton icon="export" onClick={::this.exportToXls}/>
            </ContextTooltip>,
            <ContextTooltip key="base-table-list.print" default="Печать">
                <IconButton icon="print" onClick={::this.printSummary}/>
            </ContextTooltip>,
            <ContextTooltip key="kurs.task.back" code="kurs.task.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ];
    }

    async exportToXls() {
        const exportRowsCount = 99999;
        const table = this.tables['content'];
        table.page.len(exportRowsCount);
        const headers = table.columns().header();
        const rows = table.rows().nodes();

        const html = getTableForPrint(headers, rows);
        this.convertToXls(html);
        table.page.len(25);
    }

    render() {
        return (
            <Page pageId="DuplicationMatrix"
                  title={`Матрицы дублирования → «${this.state.matrix.name || '...'}»`}
                  headerActions={this.renderHeaderActions()}
                  headerContents={this.renderHeaderContents()}
                  className="matrix-doubling"
            >
                {this.state.isLoading ? (<GlobalLoaderComponent/>) : this.renderContent()}
            </Page>
        );
    }

    onFilterOpened = (table, field, opened) => {
        this.setState({
            table,
            field,
            opened
        });
    };

    async onColumnFilterChange(filterType, index, filter) {
        const column = this.tables[this.state.table].column(index);
        if (column) {

            let regex = false;
            switch (filterType) {
                case 'date':
                    filter = moment(filter).format(formats.DATE);
                    break;
                case 'select':
                    regex = true;
                    filter = _.map(filter, (uuid) => {
                        return '(' + (_.get(_.find(this.state.route_kinds, {value: uuid}), 'label') || _.get(_.find(this.state.transport_connection_types, {value: uuid}), 'label')) + ')';
                    }).join('|');
                    break;
            }

            column.search(filter, regex).draw();
        }
    }

    renderContent() {
        const columns = [
            '№',
            'Рег. №',
            'Наименование',
            'Дата внесения',
            'Вид сообщения',
            'Вид маршрута',
            'Территориальные субъекты',
            'ИИДП',
            'ИИДО',
        ];

        const className = classNames({
            'show-table-filters': this.state.showTableFilters,
        });

        const table = 'content';

        return (
            <div className={className}>
                <div className="Table">
                    <TableContainer>
                        <table className="b-table" ref="content">
                            <thead>
                            <tr>
                                {_.map(columns, (name, index) => {
                                    let column = {
                                        field: index,
                                    };

                                    const isOpened = (table === this.state.table) && (column.field === this.state.field) && this.state.opened;

                                    let FilterComponent = TableColumnFilterText;
                                    let filterType = 'text';

                                    switch (index) {
                                        case 3:
                                            FilterComponent = TableColumnFilterDateSingle;
                                            filterType = 'date';
                                            break;
                                        case 4:
                                            FilterComponent = TableColumnFilterSelect;
                                            column.filter = () => {
                                                return _.map(this.state.transport_connection_types, (item) => {
                                                    return {
                                                        uuid: item.value,
                                                        name: item.label,
                                                    };
                                                });
                                            };
                                            filterType = 'select';
                                            break;
                                        case 5:
                                            FilterComponent = TableColumnFilterSelect;
                                            column.filter = () => {
                                                return _.map(this.state.route_kinds, (item) => {
                                                    return {
                                                        uuid: item.value,
                                                        name: item.label,
                                                    };
                                                });
                                            };
                                            filterType = 'select';
                                            break;
                                    }

                                    return (
                                        <th key={index}>
                                            {name}
                                            <FilterComponent
                                                index={index}
                                                column={column}
                                                onChange={this.onColumnFilterChange.bind(this, filterType)}
                                                onFilterOpened={this.onFilterOpened.bind(this, table)}
                                                opened={isOpened}
                                            />
                                        </th>
                                    );
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {_.map(this.state.routes, ::this.renderRoute)}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
                {this.renderSelectedRoute()}
            </div>
        )
    }

    onRouteClick(route) {
        this.setState({
            selectedRoute: (this.state.selectedRoute === route.uuid) ? null : route.uuid,
            index: this.state.index + 1,
        });

        this.loadRouteVariants(route);

        setTimeout(() => {
            this.initTable('doubling-route');
        }, 500);
    }

    renderRoute(route) {
        const matrixRoutes = _.filter(this.state.matrixRoutes, {
            route_uuid: route.uuid,
        });
        const iidp = _.sumBy(matrixRoutes, 'idp');
        const iido = _.sumBy(matrixRoutes, 'ido');

        return (
            <tr key={route.uuid} onClick={this.onRouteClick.bind(this, route)}>
                <td>{route.number}</td>
                <td>{route.registration_number}</td>
                <td>{route.title}</td>
                <td>{moment(route.created_at).format(formats.DATETIME_SHORT)}</td>
                <td>{route.transport_connection_type_uuid && _.get(_.find(this.state.transport_connection_types, {value: route.transport_connection_type_uuid}), 'label')}</td>
                <td>{route.route_kind_uuid && _.get(_.find(this.state.route_kinds, {value: route.route_kind_uuid}), 'label')}</td>
                <td></td>
                <td>{_.round(iidp, 2)}</td>
                <td>{_.round(iido, 2)}</td>
            </tr>
        );
    }

    renderSelectedRoute() {
        if (!this.state.selectedRoute) {
            return;
        }

        return (
            <div className="matrix-doubling__ctrl" key={this.state.index}>
                <div className="filter-header">
                    <SelectFilter
                        items={[
                            'Дублирующие маршруты',
                            'Участки',
                        ]}
                        currentItem={this.state.selectedType}
                        onChange={async (index) => {
                            await this.setState({
                                selectedType: index,
                                index: this.state.index + 1,
                            });

                            setTimeout(() => {
                                this.initTable('doubling-route');
                                this.initTable('double-part');
                            }, 500);
                        }}/>
                </div>
                {this.state.selectedType ? this.renderSelectedRouteParts() : this.renderSelectedRouteRoutes()}
            </div>
        );
    }

    renderSelectedRouteRoutes() {
        const matrixRoutes = _.filter(this.state.matrixRoutes, {
            route_uuid: this.state.selectedRoute,
        });

        const columns = [
            'ИНП, %',
            'ИНО, %',
            '№',
            'Рег. №',
            'Наименование',
            'Дата внесения',
            'Вид сообщения',
            'Вид маршрута',
            'Территориальные субъекты',
        ];

        const table = 'doubling-route';

        return (
            <div className="doubling-route" key={this.state.index}>
                <div className="Table">
                    <TableContainer>
                        <table className="b-table" ref="doubling-route">
                            <thead>
                            <tr>
                                <th colSpan={9}>
                                    <div className="table-header">
                                        <div className="properties">
                                            <span className="total-apparel">
                                                <span className="table-header_title">ИИДП:</span>
                                                <span
                                                    className="table-header_text">{_.round(_.sumBy(matrixRoutes, 'idp'), 2)}</span>
                                            </span>,
                                            <span className="selection-apparel">
                                                <span className="table-header_title">ИИДО:</span>
                                                <span
                                                    className="table-header_text">{_.round(_.sumBy(matrixRoutes, 'ido'), 2)}</span>
                                            </span>
                                        </div>
                                        <div className="upload">
                                            <ul className="top-menu_modal__list">
                                                <li className="top-menu_modal__item">
                                                    <a href="javascript:void(0)"
                                                       onClick={::this.printSelectedRouteRoutes}
                                                       className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_print"/>
                                                </li>
                                                <li className="top-menu_modal__item">
                                                    <a href="javascript:void(0)"
                                                       onClick={::this.exportSelectedRouteRoutes}
                                                       className="top-menu__link top-menu__link_icon_export"/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                {_.map(columns, (name, index) => {
                                    let column = {
                                        field: index,
                                    };

                                    const isOpened = (table === this.state.table) && (column.field === this.state.field) && this.state.opened;

                                    let FilterComponent = TableColumnFilterText;
                                    let filterType = 'text';

                                    switch (index) {
                                        case 5:
                                            FilterComponent = TableColumnFilterDateSingle;
                                            filterType = 'date';
                                            break;
                                        case 6:
                                            FilterComponent = TableColumnFilterSelect;
                                            column.filter = () => {
                                                return _.map(this.state.transport_connection_types, (item) => {
                                                    return {
                                                        uuid: item.value,
                                                        name: item.label,
                                                    };
                                                });
                                            };
                                            filterType = 'select';
                                            break;
                                        case 7:
                                            FilterComponent = TableColumnFilterSelect;
                                            column.filter = () => {
                                                return _.map(this.state.route_kinds, (item) => {
                                                    return {
                                                        uuid: item.value,
                                                        name: item.label,
                                                    };
                                                });
                                            };
                                            filterType = 'select';
                                            break;
                                    }

                                    return (
                                        <th key={index}>
                                            {name}
                                            <FilterComponent
                                                index={index}
                                                column={column}
                                                onChange={this.onColumnFilterChange.bind(this, filterType)}
                                                onFilterOpened={this.onFilterOpened.bind(this, table)}
                                                opened={isOpened}
                                            />
                                        </th>
                                    );
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {_.map(matrixRoutes, (matrixRoute) => {
                                const route = _.get(this.state.routes, matrixRoute.route2_uuid);
                                if (!route) {
                                    return;
                                }

                                return (
                                    <tr key={matrixRoute.uuid}>
                                        <td>{_.round(matrixRoute.inp * 100, 2)}</td>
                                        <td>{_.round(matrixRoute.ino * 100, 2)}</td>
                                        <td>{route.number}</td>
                                        <td>{route.registration_number}</td>
                                        <td>{route.title}</td>
                                        <td>{moment(route.created_at).format(formats.DATETIME_SHORT)}</td>
                                        <td>{route.transport_connection_type_uuid && _.get(_.find(this.state.transport_connection_types, {value: route.transport_connection_type_uuid}), 'label')}</td>
                                        <td>{route.route_kind_uuid && _.get(_.find(this.state.route_kinds, {value: route.route_kind_uuid}), 'label')}</td>
                                        <td></td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </div>
        );
    }

    printSelectedRouteRoutes() {
        const matrixRoutes = _.filter(this.state.matrixRoutes, {
            route_uuid: this.state.selectedRoute,
        });
        const route = _.get(this.state.routes, this.state.selectedRoute);

        print(duplicationMatrixPrintRoutes({
            withCss: true,
            route,
            matrixRoutes,
            related: this.state.related,
            matrix: this.state.matrix,
            routes: this.state.routes,
            transport_connection_types: this.state.transport_connection_types,
            route_kinds: this.state.route_kinds,
        }));
    }

    exportSelectedRouteRoutes() {
        const matrixRoutes = _.filter(this.state.matrixRoutes, {
            route_uuid: this.state.selectedRoute,
        });
        const route = _.get(this.state.routes, this.state.selectedRoute);

        this.convertToXls(duplicationMatrixPrintRoutes({
            withCss: false,
            route,
            matrixRoutes,
            related: this.state.related,
            matrix: this.state.matrix,
            routes: this.state.routes,
            transport_connection_types: this.state.transport_connection_types,
            route_kinds: this.state.route_kinds,
        }));
    }

    async convertToXls(content) {
        const response = await makeResponse(() => {
            return api.converter.convertHtmlToXls(content);
        });

        if (response.isOk) {
            const content = response.payload.content;
            download(`data:application/excel;base64,${content}`, `Экспорт ${moment().format(formats.DATETIME)}.xls`);
        } else {
            response.showErrors();
        }
    }

    async onDirectionChange(index) {
        await this.setState({
            direction: index ? 'reverse' : 'forward',
        });

        this.loadMatrixParts();
    }

    async onRouteVariantChange(e) {
        const value = e ? e.value : null;

        await this.setState({
            routeVariantUuid: value,
            stopPointUuid: null,
            stopPoint2Uuid: null,
        });

        this.loadRelated();
        this.loadMatrixParts();
    }

    renderSelectedRouteParts() {
        return (
            <div className="matrix-doubling__sections" key={this.state.index}>
                <div className="sections__left">
                    <Block size="xl">
                        <Select
                            value={this.state.routeVariantUuid}
                            options={_.map(this.state.routeVariants, (routeVariant) => ({
                                value: routeVariant.uuid,
                                label: routeVariant.name,
                            }))}
                            onChange={::this.onRouteVariantChange}
                        />
                    </Block>
                    <SelectFilter
                        items={[
                            'Прямой',
                            'Обратный',
                        ]}
                        currentItem={(this.state.direction === 'forward') ? 0 : 1}
                        onChange={::this.onDirectionChange}/>
                </div>
                <div className="sections__right">
                    {this.renderSnake()}
                    {this.renderPart()}
                </div>
            </div>
        );
    }

    async onPointsClick(stopPointUuid, stopPoint2Uuid, partLength) {
        await this.setState({
            stopPointUuid,
            stopPoint2Uuid,
            partLength,
            index: this.state.index + 1,
        });

        setTimeout(() => {
            this.initTable('double-part');
        }, 500);
    }

    renderSnake() {
        if (!this.state.routeVariantUuid) {
            return;
        }

        const routeVariant = _.get(this.state.routeVariants, this.state.routeVariantUuid);
        if (!routeVariant) {
            return;
        }
        const points = _.filter(_.get(routeVariant, this.state.direction + '_points') || [], {point_type: 'stop_point'});
        const pointsLength = points.length - 1;

        return (
            <div className="snake">
                <div className="snake-sector">
                    <div className="snake-content show-minimum">
                        <div className="snake-line">
                            <div className="snake-points">
                                {_.map(points, (point, index) => {
                                    const left = (index / pointsLength) * 100;
                                    let onClick = null;
                                    if (index < pointsLength) {
                                        onClick = this.onPointsClick.bind(this, point.type_uuid, points[index + 1].type_uuid, point.distance_to_the_next_point);
                                    }

                                    return (
                                        <div key={point.uuid} className="snake-point pass" style={{left: `${left}%`}}
                                             onClick={onClick}>
                                            <div className="snake-point__icon"></div>
                                            <div className="snake-point__title">
                                                <span>{this.state.related.getReact(point.type_uuid)}</span></div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    printSummary() {
        print(duplicationMatrixPrintSummary({
            withCss: true,
            matrixRoutes: this.state.matrixRoutes,
            related: this.state.related,
            routes: this.state.routes,
            matrix: this.state.matrix,
            routeVariants: this.state.routeVariants,
            transport_connection_types: this.state.transport_connection_types,
            route_kinds: this.state.route_kinds,
        }));
    }

    printSelectedRoutePart() {
        const matrixRoutes = _.filter(this.state.matrixParts, {
            stop_point_uuid: this.state.stopPointUuid,
            stop_point2_uuid: this.state.stopPoint2Uuid,
        });

        print(duplicationMatrixPrintPart({
            withCss: true,
            stopPoint: this.state.related.getReact(this.state.stopPointUuid),
            stopPoint2: this.state.related.getReact(this.state.stopPoint2Uuid),
            matrixRoutes,
            related: this.state.related,
            matrix: this.state.matrix,
            routes: this.state.routes,
            partLength: _.round(this.state.partLength / 1000, 1),
            routeVariants: this.state.routeVariants,
            transport_connection_types: this.state.transport_connection_types,
            route_kinds: this.state.route_kinds,
        }));
    }

    exportSelectedRoutePart() {
        const matrixRoutes = _.filter(this.state.matrixParts, {
            stop_point_uuid: this.state.stopPointUuid,
            stop_point2_uuid: this.state.stopPoint2Uuid,
        });

        this.convertToXls(duplicationMatrixPrintPart({
            withCss: false,
            stopPoint: this.state.related.getReact(this.state.stopPointUuid),
            stopPoint2: this.state.related.getReact(this.state.stopPoint2Uuid),
            matrixRoutes,
            related: this.state.related,
            matrix: this.state.matrix,
            routes: this.state.routes,
            partLength: _.round(this.state.partLength / 1000, 1),
            routeVariants: this.state.routeVariants,
            transport_connection_types: this.state.transport_connection_types,
            route_kinds: this.state.route_kinds,
        }));
    }

    renderPart() {
        if (!this.state.stopPointUuid || !this.state.stopPoint2Uuid) {
            return;
        }

        const matrixRoutes = _.filter(this.state.matrixParts, {
            stop_point_uuid: this.state.stopPointUuid,
            stop_point2_uuid: this.state.stopPoint2Uuid,
        });

        const columns = [
            'ИДП, %',
            'ИДО, %',
            '№',
            'Рег. №',
            'Наименование',
            'Дата внесения',
            'Вид сообщения',
            'Вид маршрута',
            'Территориальные субъекты',
        ];

        const table = 'double-part';

        return (
            <div className="Table" key={this.state.index}>
                <TableContainer>
                    <table className="b-table" ref="double-part">
                        <thead>
                        <tr>
                            <th colSpan={9}>
                                <div className="table-header">
                                    <div className="properties">
                                        <span className="bold">Участок</span>
                                        <span>{this.state.related.getReact(this.state.stopPointUuid)}
                                            - {this.state.related.getReact(this.state.stopPoint2Uuid)}</span>
                                        |
                                        <span>{_.round(this.state.partLength / 1000, 1)} км</span>
                                        |
                                        <span className="total-apparel">
                                            <span className="table-header_title">ИИДП:</span>
                                            <span
                                                className="table-header_text">{_.round(_.sumBy(matrixRoutes, 'idp'), 2)}</span>
                                        </span>,
                                        <span className="selection-apparel">
                                            <span className="table-header_title">ИИДО:</span>
                                            <span
                                                className="table-header_text">{_.round(_.sumBy(matrixRoutes, 'ido'), 2)}</span>
                                        </span>
                                    </div>
                                    <div className="upload">
                                        <ul className="top-menu_modal__list">
                                            <li className="top-menu_modal__item">
                                                <a href="javascript:void(0)" onClick={::this.printSelectedRoutePart}
                                                   className="top-menu_modal__link b-icon-link b-icon-link_params b-icon-link_icon_print"/>
                                            </li>
                                            <li className="top-menu_modal__item">
                                                <a href="javascript:void(0)" onClick={::this.exportSelectedRoutePart}
                                                   className="top-menu__link top-menu__link_icon_export"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            {_.map(columns, (name, index) => {
                                let column = {
                                    field: index,
                                };

                                const isOpened = (table === this.state.table) && (column.field === this.state.field) && this.state.opened;

                                let FilterComponent = TableColumnFilterText;
                                let filterType = 'text';

                                switch (index) {
                                    case 5:
                                        FilterComponent = TableColumnFilterDateSingle;
                                        filterType = 'date';
                                        break;
                                    case 6:
                                        FilterComponent = TableColumnFilterSelect;
                                        column.filter = () => {
                                            return _.map(this.state.transport_connection_types, (item) => {
                                                return {
                                                    uuid: item.value,
                                                    name: item.label,
                                                };
                                            });
                                        };
                                        filterType = 'select';
                                        break;
                                    case 7:
                                        FilterComponent = TableColumnFilterSelect;
                                        column.filter = () => {
                                            return _.map(this.state.route_kinds, (item) => {
                                                return {
                                                    uuid: item.value,
                                                    name: item.label,
                                                };
                                            });
                                        };
                                        filterType = 'select';
                                        break;
                                }

                                return (
                                    <th key={index}>
                                        {name}
                                        <FilterComponent
                                            index={index}
                                            column={column}
                                            onChange={this.onColumnFilterChange.bind(this, filterType)}
                                            onFilterOpened={this.onFilterOpened.bind(this, table)}
                                            opened={isOpened}
                                        />
                                    </th>
                                );
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {_.map(matrixRoutes, (matrixRoute) => {
                            const route = _.get(this.state.routes, matrixRoute.route2_uuid);
                            if (!route) {
                                return;
                            }
                            const routeVariantsLength = _.keys(this.state.routeVariants).length;

                            return (
                                <tr key={matrixRoute.uuid}>
                                    <td>{(routeVariantsLength > 0) ? _.round(matrixRoute.idp / routeVariantsLength, 2) : 0}</td>
                                    <td>{(routeVariantsLength > 0) ? _.round(matrixRoute.ido / routeVariantsLength, 2) : 0}</td>
                                    <td>{route.number}</td>
                                    <td>{route.registration_number}</td>
                                    <td>{route.title}</td>
                                    <td>{moment(route.created_at).format(formats.DATETIME_SHORT)}</td>
                                    <td>{route.transport_connection_type_uuid && _.get(_.find(this.state.transport_connection_types, {value: route.transport_connection_type_uuid}), 'label')}</td>
                                    <td>{route.route_kind_uuid && _.get(_.find(this.state.route_kinds, {value: route.route_kind_uuid}), 'label')}</td>
                                    <td></td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </TableContainer>
            </div>
        );
    }

}