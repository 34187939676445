import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import PageModal from 'components/ui/page-modal';

import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import * as alerts from "helpers/alerts";

import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import currentUser from 'helpers/current-user';
import {getUnit, createUnit, updateUnit, deleteUnit, getUnits} from "store/reducers/organizational_units/units";
import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {getPositions} from "store/reducers/organizational_units/positions";
import ContextTooltip from "components/ui/context-tooltip";
import ColorPicker from 'react-color-picker';
import InputMask from "components/ui/form/input-mask";
import 'react-color-picker/index.css';
import FileReaderInput from "react-file-reader-input";
import {api} from "helpers/api";
import {getHostName} from "helpers/wsrpc";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
})

@defaultProps({
    onEditClick: () => {
    },
    onDeleteClick: () => {
    },
})

@connect(state => ({}), {getUnit, createUnit, updateUnit, deleteUnit, getPositions})

export default class UnitsEditor extends Component {

    state = {
        uuid: null,
        unit: null,
        isLoading: this.props.mode === 'edit',
        saving: false,
        errors: {},
    };

    componentWillReceiveProps(props) {
        if (this.state.uuid !== props.uuid) {
            this.loadUnit(props.uuid);
        }
    }

    componentWillMount() {
        this.componentWillReceiveProps(this.props);
    }

    render() {
        let title = 'Добавление нового предприятия';

        let form;
        let buttons;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.props.mode === 'edit') {
            title = 'Редактирование данных предприятия';

            if (this.state.unit) {
                form = <EditorForm
                    {...this.props}
                    ref="form"
                    mode={this.props.mode}
                    onSubmit={::this.edit}
                    onDelete={::this.deleteItem}
                    onClose={::this.props.onClose}
                    data={this.state.unit}
                    errors={this.state.errors}
                    clearErrors={::this.clearErrors}
                />;

                buttons = (
                    <ModalTopMenuButtons>
                        <ModalTopMenuList className="top-menu_modal_edit">
                            {currentUser.can('com.rnis.system.permission.audit', 'read') ? (
                                <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                                default="Журнал аудита">
                                    <ModalTopMenuListItem
                                        className="b-icon-link_params b-icon-link_icon_history"
                                        href={`/system/audit/${this.props.uuid}?class=App\\Model\\Unit`}
                                    />
                                </ContextTooltip>
                            ) : null}

                            <ModalTopMenuListSeparator key="separator"/>
                        </ModalTopMenuList>

                        <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                            <ModalTopMenuButton
                                className="_save"
                                title="Сохранить"
                                onClick={::this.onEdit}
                            />
                        </ContextTooltip>

                        <ModalTopMenuButtonsSeparator/>

                        <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                            <ModalTopMenuButton
                                className="_close"
                                onClick={::this.props.onClose}
                            />
                        </ContextTooltip>
                    </ModalTopMenuButtons>
                );
            }
        } else {
            form = <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={::this.create}
                onClose={::this.props.onClose}
                data={{}}
                errors={this.state.errors}
                clearErrors={::this.clearErrors}
            />;

            buttons = (
                <ModalTopMenuButtons>
                    <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                        <ModalTopMenuButton
                            className="_save"
                            title="Сохранить"
                            onClick={::this.onCreate}
                        />
                    </ContextTooltip>

                    <ModalTopMenuButtonsSeparator/>

                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={::this.props.onClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );
        }

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}
                className={`profile-modal b-modal-${this.props.mode}`}
            >
                {loader}
                {form}
            </PageModal>
        );
    }

    async loadUnit(uuid) {
        this.setState({
            uuid,
            unit: null,
            isLoading: true
        });
        if (uuid) {
            const response = await this.props.getUnit(uuid);
            if (response.isOk) {
                let unit = response.payload;
                unit.positions = await this.loadPositions(unit);

                this.setState({
                    unit,
                    isLoading: false,
                });
            } else {
                response.showErrors();
            }
        }
    }

    async loadPositions(unit) {
        const response = await this.props.getPositions({
            order: {
                column: 'name',
                direction: 'asc',
            },
            filters: {
                withUnitUuid: [unit.uuid],
            },
        });

        if (response.isOk) {
            return response.payload.items;
        } else {
            response.showErrors();
        }
    }

    clearErrors() {
        this.setState({errors: {}});
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    onCreate() {
        if (!this.refs.form) return;
        const state = this.refs.form.getWrappedInstance().getData();
        this.create(state);
    }

    onEdit() {
        if (!this.refs.form) return;
        const state = this.refs.form.getWrappedInstance().getData();
        this.edit(state);
    }

    async create(data) {
        this.clearErrors();
        this.startSave();

        const response = await this.props.createUnit(this.composeData(data));
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();

        const response = await this.props.updateUnit(this.composeData(data));
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async deleteItem(uuid) {
        this.startSave();
        const response = await this.props.deleteUnit({
            uuid,
        });
        this.endSave();
        if (response.isOk) {
            this.props.onSubmit();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    composeData(data) {
        let item = _.clone(data);

        item.departments = item.departments || [];
        if (window.RNIS_SETTINGS.temporarily_sbdd && this.props.params.component === 'operator') {
            item.component = 'sbdd';
        } else {
            item.component = this.props.params.component;
        }

        if (item.workday_begin) {
            item.workday_begin = `${item.workday_begin}:00`
        }

        if (item.workday_finish) {
            item.workday_finish = `${item.workday_finish}:00`
        }

        return item;
    }

}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
})

@connect((state) => ({}), {getDictionaryList, getUnits, getUnit}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        unit: {},
        company_types: [],
        ownership_forms: [],
        organizational_forms: [],
        communal_municipalities: [],
        units: [],
        userUnit: null,
        selectSiogv: [],
    };

    getData() {
        return this.state.unit;
    }

    async setValue(field, value) {
        this.props.clearErrors();

        return super.setValue(field, value);
    }

    async componentWillMount() {
        await this.setState({
            unit: this.props.data,
        });
        this.showOmsuSiogv() ? this.loadSIOGV() : null;
        this.loadDictionaries([
            'company_types',
            'organizational_forms',
            'ownership_forms',
            'communal_municipalities',
        ]);
        this.loadUserUnit();
    }

    async loadUserUnit() {
        const response = await this.props.getUnit(currentUser.unitUuid());

        if (response.isOk) {
            this.setState({
                userUnit: response.payload,
            });
        }
    }

    async loadUnits(input, callback) {
        if (!input) {
            input = this.get('parent_unit_uuid');
        }
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 20,
            },
            search: input,
            filters: {
                //withComponent: this.props.params.component,
            },
        });
        if (response.isOk) {
            const units = _.sortBy(_.filter(_.map(response.payload.items, (unit) => ({
                value: unit.uuid,
                label: unit.name,
            })), (item) => item.value !== this.state.unit.uuid), 'label');
            callback(null, {
                options: units,
                complete: false,
            });
            //this.setState({units});
        } else {
            response.showErrors();
        }
    }

    async loadSIOGV() {
        const response = await this.props.getUnits({
            filters: {
                withComponent: this.props.params.component,
            },
        });
        if (response.isOk) {
            const units = _.sortBy(_.filter(_.map(response.payload.items, (unit) => ({
                value: unit.uuid,
                label: unit.name,
            })), (item) => item.value !== this.state.unit.uuid), 'label');
            this.setState({
                selectSiogv: units
            })
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.unit, path, defaultValue);
    }

    hasImage() {
        return _.has(this.props.data, 'organization_image') && this.props.data.organization_image.length;
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }

    async deleteFile(e) {
        e && e.preventDefault();
        let stop_point = this.state.stop_point;
        stop_point.image = "";
        this.setState({stop_point});
        const response = await this.updateItem(this.state.stop_point)
    }

    onSetWorkDayBeginTime = ({ target: { value } }) => {
        const workday_begin = this.formatTime(value);

        this.setState(prev => ({
            unit: { ...prev.unit, workday_begin }
        }))
    }

    onSetWorkDayFinishTime = ({ target: { value } }) => {
        const workday_finish = this.formatTime(value);

        this.setState(prev => ({
            unit: { ...prev.unit, workday_finish }
        }))
    }

    formatTime = (value) => {
        let [hours, minutes] = value.split(':');

        // Time formatting
        if (+hours && +hours > 23) { // 33:00 => 23:00
            hours = 23;
        }
        if (+minutes && +minutes > 59) { // 23:69 => 23:59
            minutes = 59;
        }
        if (!+hours[0] && +hours[1]) { // _3:00 => 03:00
            hours = `0${hours[1]}`
        }
        if (!+minutes[0] && +minutes[1]) { // 23:_9 => 23:09
            minutes = `0${minutes[1]}`
        }

        return `${hours}:${minutes}`;
    }

    uploadFile = async (e, results) => {
        e.preventDefault();

        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        const allowedTypes = [
            'image/png',
            'image/jpeg',
        ];

        let hasError = false;

        results.forEach(result => {
            const [e, file] = result;
            formData.append('file', file);

            let errors = [];
            if (_.indexOf(allowedTypes, file.type) === -1) {
                errors.push('Недопустимый формат файла');
            }
            if ((file.size / (2048 * 2048)) >= 20) {
                errors.push('Недопустимый размер файла');
            }
            if (errors.length > 0) {
                alerts.error(errors.join('<br/>'));
                hasError = true;
            }
        });

        if (hasError) {
            return;
        }

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);

        let unit = this.state.unit;
        unit.organization_image = response.file;
        this.setState({unit});
        const response2 = await this.props.updateUnit(unit);
        //let stop_point = this.state.stop_point;
        //stop_point.image = response.file;

        //this.setState({stop_point});

        //const response2 = await this.updateItem(this.state.stop_point)

    }

    render() {
        return (
            <div className="StaffingEditorForm">
                <Accordion>
                    <AccordionItem title="Основные сведения" opened={true}>
                        {this.renderGeneral()}
                    </AccordionItem>
                    <AccordionItem title="Банковские реквизиты">
                        {this.renderBank()}
                    </AccordionItem>
                    <AccordionItem title="Должности">
                        {this.renderPositions()}
                    </AccordionItem>
                    <AccordionItem title="Группы пользователей">
                        {this.renderUserGroups()}
                    </AccordionItem>
                    {(this.props.params.component === 'road') ? (
                        <AccordionItem title="Отделения">
                            {this.renderDepartments()}
                        </AccordionItem>
                    ) : null}
                </Accordion>
            </div>
        );
    }

    showOmsuSiogv() {
        if (window.RNIS_SETTINGS.show_omsu) {
            return location.pathname.includes('communal/units/units')
            || location.pathname.includes('garbage/units/units') ? true : false;
        } else {
            return false;
        }
    }

    renderGeneral() {
        const protocol = App.isSecure ? 'https://' : 'http://';
        const url = protocol + getHostName('ajax', false) + `/storage/${this.props.data.organization_image}`;

        let roleNames = currentUser.user.roles.map((role) => {
            return role.name
        });
        let isAdmin = currentUser.user.is_supervisor;
        switch (this.props.params.component) {
            case 'road':
            case 'control':
                return (
                    <div>
                        <Block size="xl" title="Полное наименование *">
                            {this.textInput('unit.name_full')}
                        </Block>
                        <Block size="xl" title="Краткое наименование *">
                            {this.textInput('unit.name')}
                        </Block>
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD && this.props.params.component !== 'road') ? (
                            <Block title="КПП">
                                {this.textInput('unit.kpp')}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD && this.props.params.component !== 'road') ? (
                            <Block title="ИНН">
                                {this.textInput('unit.inn')}
                            </Block>
                        ) : null}

                            <Block title="ОГРН">
                                {this.textInput('unit.ogrn')}
                            </Block>


                            <Block title="ОКПО">
                                {this.textInput('unit.okpo')}
                            </Block>

                        <Block title="Плановое количество ТС, подлежащих оснащению БНСО">
                            {this.textInput('unit.vehicles_plan', {
                                type: 'number',
                                positive: true,
                            })}
                        </Block>
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD && this.props.params.component !== 'road') ? (
                            <Block title="Родительское предприятие">
                                {this.selectAsync('unit.parent_unit_uuid', ::this.loadUnits)}
                            </Block>
                        ) : null}
                        {(this.props.params.component === 'road') ? (
                            <Block title="Родительское предприятие">
                                {this.selectAsync('unit.parent_unit_uuid', ::this.loadUnits)}
                            </Block>
                        ) : null}
                        <Block size="xl" title="Юридический адрес">
                            {this.textInput('unit.legal_address')}
                        </Block>
                        <Block size="xl" title="Фактический адрес">
                            {this.textInput('unit.actual_address')}
                        </Block>
                        {(this.props.params.component === 'road') ? (
                            <Block title="ID СКПДИ">
                                {this.textInput('unit.external_id')}
                            </Block>
                        ) : null}
                        <Block title="Руководитель">
                            {this.textInput('unit.senior')}
                        </Block>

                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="Муниципальное образование">
                                {this.select('unit.communal_municipality_uuid', this.state.communal_municipalities)}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="№ Лицензионного Договора">
                                {this.textInput('unit.contract_license_number')}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="№ Договора с Теле-оператором">
                                {this.textInput('unit.contract_tele_operator_number')}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="№ Договора АРМ">
                                {this.textInput('unit.contract_apm_number')}
                            </Block>
                        ) : null}
                        <Block title="Роли предприятия *">
                            {this.select('unit.unit_types', this.getCompanyTypes(), {
                                multi: true
                            })}
                        </Block>
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="Форма собственности">
                                {this.select('unit.ownership_type_uuid', this.state.ownership_forms)}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="Организационная форма">
                                {this.select('unit.organizational_form_uuid', this.state.organizational_forms)}
                            </Block>
                        ) : null}
                        {(this.props.params.component === 'road') ? (
                            <Block title="КПП">
                                {this.textInput('unit.kpp')}
                            </Block>
                        ) : null}
                        {(this.props.params.component === 'road') ? (
                            <Block title="ИНН">
                                {this.textInput('unit.inn')}
                            </Block>
                        ) : null}
                        <Block title="Номер телефона">
                            {this.textInput('unit.phone')}
                        </Block>
                        <Block title="Электронная почта">
                            {this.textInput('unit.email')}
                        </Block>
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="URL">
                                {this.textInput('unit.url')}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="Широта">
                                {this.textInput('unit.latitude')}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="Долгота">
                                {this.textInput('unit.longitude')}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="Телефон клиентской службы">
                                {this.maskInput('unit.customer_phone', '+7 (999) 999-99-99')}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="URL тарификации">
                                {this.textInput('unit.fare_url')}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="Email клиентской службы">
                                {this.textInput('unit.customer_email')}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.unit_color_picker) ? (
                            <Block size="md" title="Цвет">
                                <div className="colorpicker-container">
                                    <div className="colorpicker-color"
                                         style={{backgroundColor: this.get('color') || '#1186e9'}}/>
                                    <ColorPicker color={this.get('color')} onDrag={::this.onColorChange}
                                                 saturationWidth={200} saturationHeight={200}/>
                                </div>
                            </Block>
                        ) : null}
                        <Block>
                            {this.checkbox('unit.is_active', 'Активное')}
                        </Block>
                        <Block size="md" title="Логотип">
                            <div className="b-block">
                                {(this.hasImage() ? (
                                    <img src={url} className="org_img-preview"/>) : null)}
                                <FileReaderInput as="binary" id="my-file-input" onChange={this.uploadFile}>
                                    <a href="#">{(this.hasImage()) ? 'Заменить логотип' : 'Загрузить логотип'}</a>
                                </FileReaderInput>
                            </div>
                        </Block>
                        <Block title="Начало рабочего дня">
                            <InputMask
                                mask="99:99"
                                value={this.state.unit.workday_begin}
                                onChange={this.onSetWorkDayBeginTime}
                            />
                        </Block>
                        <Block title="Окончание рабочего дня">
                            <InputMask
                                mask="99:99"
                                value={this.state.unit.workday_finish}
                                onChange={this.onSetWorkDayFinishTime}
                            />
                        </Block>
                    </div>
                );
            default:
                return (
                    <div>
                        <Block size="xl" title="Полное наименование *">
                            {this.textInput('unit.name_full')}
                        </Block>
                        <Block size="xl" title="Краткое наименование *">
                            {this.textInput('unit.name')}
                        </Block>
                        {this.showOmsuSiogv() ? <Block title="ОМСУ">
                            {this.select('unit.unit_municipals', this.state.communal_municipalities, {
                                multi: true,
                            })}
                        </Block> : null}
                        {this.showOmsuSiogv() ? <Block title="ЦИОГВ">
                            {this.select('unit.unit_siogv', this.state.selectSiogv, {
                                multi: true,
                            })}
                        </Block> : null}
                        {location.pathname.includes("/garbage/units/units") && window.RNIS_SETTINGS.show_omsu ? <Block title="Рег.оператор">
                            {this.select('unit.unit_regoper', this.state.selectSiogv, {
                                multi: true,
                            })}
                        </Block> : null}
                        <Block title="КПП">
                            {this.textInput('unit.kpp')}
                        </Block>
                        <Block title="ИНН">
                            {this.textInput('unit.inn')}
                        </Block>
                        <Block title="ОГРН">
                            {this.textInput('unit.ogrn')}
                        </Block>
                        <Block title="ОКПО">
                            {this.textInput('unit.okpo')}
                        </Block>
                        <Block title="Плановое количество ТС, подлежащих оснащению БНСО">
                            {this.textInput('unit.vehicles_plan', {
                                type: 'number',
                                positive: true,
                            })}
                        </Block>
                        <Block title="Родительское предприятие">
                            {this.selectAsync('unit.parent_unit_uuid', ::this.loadUnits)}
                        </Block>
                        {window.RNIS_SETTINGS.CITY_OMSK ? (
                            <Block title="ID перевозчика (АСОП)">
                                {this.textInput('unit.asop_unit_id', {
                                    type: 'number',
                                    positive: true,
                                })}
                            </Block>
                        ) : null}
                        {window.RNIS_SETTINGS.CITY_OMSK ? (
                            <Block title="ID подразделения (АСОП)">
                                {this.textInput('unit.asop_department_id', {
                                    type: 'number',
                                    positive: true,
                                })}
                            </Block>
                        ) : null}
                        <Block size="xl" title="Юридический адрес">
                            {this.textInput('unit.legal_address')}
                        </Block>
                        <Block size="xl" title="Фактический адрес">
                            {this.textInput('unit.actual_address')}
                        </Block>
                        <Block title="Руководитель">
                            {this.textInput('unit.senior')}
                        </Block>
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD || this.props.params.component === 'children' || this.props.params.component === 'communal' || this.props.params.component === 'garbage') ? (
                            <Block title="Муниципальное образование">
                                {this.select('unit.communal_municipality_uuid', this.state.communal_municipalities)}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="№ Лицензионного Договора">
                                {this.textInput('unit.contract_license_number')}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="№ Договора с Теле-оператором">
                                {this.textInput('unit.contract_tele_operator_number')}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="№ Договора АРМ">
                                {this.textInput('unit.contract_apm_number')}
                            </Block>
                        ) : null}
                        <Block title="Роли предприятия *">
                            {this.select('unit.unit_types', this.getCompanyTypes(), {
                                multi: true
                            })}
                        </Block>
                        <Block title="Форма собственности">
                            {this.select('unit.ownership_type_uuid', this.state.ownership_forms)}
                        </Block>

                        <Block title="Организационная форма">
                            {this.select('unit.organizational_form_uuid', this.state.organizational_forms)}
                        </Block>
                        <Block title="Номер телефона">
                            {this.maskInput('unit.phone', '+7 (999) 999-99-99')}
                        </Block>
                        <Block title="Электронная почта">
                            {this.textInput('unit.email')}
                        </Block>
                        <Block title="URL">
                            {this.textInput('unit.url')}
                        </Block>
                        <Block title="Широта">
                            {this.textInput('unit.latitude')}
                        </Block>
                        <Block title="Долгота">
                            {this.textInput('unit.longitude')}
                        </Block>
                        <Block title="URL тарификации">
                            {this.textInput('unit.fare_url')}
                        </Block>
                        <Block title="Телефон клиентской службы">
                            {this.maskInput('unit.customer_phone', '+7 (999) 999-99-99')}
                        </Block>
                        <Block title="Email клиентской службы">
                            {this.textInput('unit.customer_email')}
                        </Block>

                        <Block size="md" title="Цвет">
                            <div className="colorpicker-container">
                                <div className="colorpicker-color"
                                     style={{backgroundColor: this.get('color') || '#1186e9'}}/>
                                <ColorPicker color={this.get('color')} onDrag={::this.onColorChange}
                                             saturationWidth={200} saturationHeight={200}/>
                            </div>
                        </Block>

                        <Block size="md" title="Логотип">
                            <div className="b-block">
                                {(this.hasImage() ? (
                                    <img src={url} className="org_img-preview"/>) : null)}
                                <FileReaderInput as="binary" id="my-file-input" onChange={this.uploadFile}>
                                    <a href="#">{(this.hasImage()) ? 'Заменить логотип' : 'Загрузить логотип'}</a>
                                </FileReaderInput>
                            </div>
                        </Block>
                        {(window.RNIS_SETTINGS.UNITISACTIVECHECKBOX || isAdmin || (window.RNIS_SETTINGS.CITY_NOVOSIBIRSK && roleNames.includes('Администратор РНИС'))) ? (
                            <Block>
                                {this.checkbox('unit.is_active', 'Активное')}
                            </Block>
                        ) : null}
                    </div>
                );
        }
    }

    renderBank() {
        return (
            <div>
                <Block size="xl" title="Банк">
                    {this.textInput('unit.bank')}
                </Block>
                <Block size="md" title="БИК">
                    {this.textInput('unit.bik', {
                        type: 'number'
                    })}
                </Block>
                <Block size="xl" title="Прокси">
                    {this.textInput('unit.proxy')}
                </Block>
                <Block size="md" title="Л/с прокси">
                    {this.textInput('unit.account_proxy')}
                </Block>
                <Block size="md" title="Лицевой счет">
                    {this.textInput('unit.personal_account')}
                </Block>
                <Block size="md" title="Расчетный счет">
                    {this.textInput('unit.bank_account')}
                </Block>
            </div>
        );
    }

    onColorChange(color) {
        this.setValue('unit.color', color);
    }

    renderPositions() {
        const list = this.state.unit.positions || [];

        if (list.length === 0 && currentUser.can('com.rnis.organizational_units.position', 'create')) {
            return (
                <div className="add-link">
                    <a className="add-job b-icon-link b-icon-link_icon_plus" href="#" onClick={::this.addPosition}>Добавить
                        должность</a>
                </div>
            );
        }
        return list.map(::this.renderPosition);
    }

    addPosition(e) {
        e.preventDefault();

        let unit = this.state.unit;
        unit.positions = unit.positions || [];
        unit.positions.push({
            name: '',
        });

        this.setState({unit});
    }

    deletePosition(positionIndex, e) {
        e.preventDefault();

        let unit = this.state.unit;
        unit.positions.splice(positionIndex, 1);
        this.setState({unit});
    }

    renderPosition(position, index) {
        return (
            <div key={index}>
                <Block title="Должность">
                    {this.textInput(`unit.positions.${index}.name`)}
                </Block>
                <Block title="Описание">
                    {this.textInput(`unit.positions.${index}.description`)}
                </Block>
                <Block title="Тип">
                    {this.select(`unit.positions.${index}.type`, window.RNIS_SETTINGS.cleanup_specialist_for_task ? [
                        {
                            value: 'driver',
                            label: 'Водитель',
                        },
                        {
                            value: 'specialist',
                            label: 'Пользователь',
                        },
                        {
                            value: 'worker',
                            label: 'Специалист по уборке',
                        },
                    ] : [
                        {
                            value: 'driver',
                            label: 'Водитель',
                        },
                        {
                            value: 'specialist',
                            label: 'Специалист',
                        },
                        {
                            value: 'worker',
                            label: 'Рабочий',
                        },
                    ])}
                </Block>
                <div className="add-link">
                    {currentUser.can('com.rnis.organizational_units.position', 'delete') ? (
                        <a className="remove-job b-icon-link b-icon-link_icon_basket" href="#"
                           onClick={this.deletePosition.bind(this, index)}>Удалить должность</a>
                    ) : null}
                    {((index === this.state.unit.positions.length - 1) && (currentUser.can('com.rnis.organizational_units.position', 'create'))) ? (
                        <span>
                            <span className="add-link__separator"/>
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="#"
                               onClick={::this.addPosition}>Добавить должность</a>
                        </span>
                    ) : null}

                </div>
                {(index !== this.state.unit.positions.length - 1) ? <div className="hr"/> : null}
            </div>
        );
    }

    renderUserGroups() {
        const list = this.state.unit.user_groups || [];

        if (list.length === 0) {
            return (
                <div className="add-link">
                    <a className="add-job b-icon-link b-icon-link_icon_plus" href="#" onClick={::this.addUserGroup}>Добавить
                        группу пользователей</a>
                </div>
            );
        }
        return list.map(::this.renderUserGroup);
    }

    addUserGroup(e) {
        e.preventDefault();

        let unit = this.state.unit;
        unit.user_groups = unit.user_groups || [];
        unit.user_groups.push({
            name: '',
        });

        this.setState({unit});
    }

    deleteUserGroup(index, e) {
        e.preventDefault();

        let unit = this.state.unit;
        unit.user_groups.splice(index, 1);
        this.setState({unit});
    }

    renderUserGroup(user_group, index) {
        return (
            <div key={index}>
                <Block title="Наименование">
                    {this.textInput(`unit.user_groups.${index}.name`)}
                </Block>
                <Block title="Описание">
                    {this.textInput(`unit.user_groups.${index}.description`)}
                </Block>
                <div className="add-link">
                    <a className="remove-job b-icon-link b-icon-link_icon_basket" href="#"
                       onClick={this.deleteUserGroup.bind(this, index)}>Удалить группу пользователей</a>
                    {((index === this.state.unit.user_groups.length - 1)) ? (
                        <span>
                            <span className="add-link__separator"/>
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="#"
                               onClick={::this.addUserGroup}>Добавить группу пользователей</a>
                        </span>
                    ) : null}

                </div>
                {(index !== this.state.unit.user_groups.length - 1) ? <div className="hr"/> : null}
            </div>
        );
    }

    renderDepartments() {
        const list = this.state.unit.departments || [];

        if (list.length === 0) {
            return (
                <div className="add-link">
                    <a className="add-job b-icon-link b-icon-link_icon_plus" href="#" onClick={::this.addDepartment}>Добавить
                        отделение</a>
                </div>
            );
        }
        return list.map(::this.renderDepartment);
    }

    addDepartment(e) {
        e.preventDefault();

        let unit = this.state.unit;
        unit.departments = unit.departments || [];
        unit.departments.push({});

        this.setState({unit});
    }

    deleteDepartment(departmentIndex, e) {
        e.preventDefault();

        let unit = this.state.unit;
        unit.departments.splice(departmentIndex, 1);
        this.setState({unit});
    }

    renderDepartment(department, index) {
        return (
            <div key={index}>
                <Block title="Наименование">
                    {this.textInput(`unit.departments.${index}.name`)}
                </Block>
                <Block title="Адрес">
                    {this.textInput(`unit.departments.${index}.address`)}
                </Block>
                <Block title="Телефон">
                    {this.textInput(`unit.departments.${index}.phone`)}
                </Block>
                <Block title="Электронная почта">
                    {this.textInput(`unit.departments.${index}.email`)}
                </Block>
                <Block title="Руководитель">
                    {this.textInput(`unit.departments.${index}.senior`)}
                </Block>
                <div className="add-link">
                    <a className="remove-job b-icon-link b-icon-link_icon_basket" href="#"
                       onClick={this.deleteDepartment.bind(this, index)}>Удалить отделение</a>
                    {(index === this.state.unit.departments.length - 1) ? (
                        <span>
                            <span className="add-link__separator"/>
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="#"
                               onClick={::this.addDepartment}>Добавить отделение</a>
                        </span>
                    ) : null}

                </div>
                {(index !== this.state.unit.departments.length - 1) ? <div className="hr"/> : null}
            </div>
        );
    }

    getCompanyTypes() {
        if (currentUser.isSupervisor()) {
            return this.state.company_types;
        }

        const operator = _.get(_.find(this.state.company_types, {label: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '100']).text}), 'value');
        const userUnitCompanyTypes = _.get(this.state.userUnit, 'unit_types') || [];

        if (_.indexOf(userUnitCompanyTypes, operator) !== -1) {
            return this.state.company_types;
        }

        return _.filter(this.state.company_types, (item) => {
            return _.indexOf(userUnitCompanyTypes, item.value) !== -1;
        });
    }
}
