export const reduceDictionariesToObject = (items) => {
	return items.reduce(
		(prev, cur) => ({
			...prev,
			[cur.key]: reduceDictionaryToObject(cur.documents),
		}),
		{}
	);
};

export const reduceDictionaryToObject = (items) => {
	const result = {};
	items.forEach((el) => {
		result[el.uuid] = el;
	});
	return result;
};
