import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import classNames from 'classnames';

import TableContainer from "components/ui/Table/Container/TableContainer";
import './switches.less';
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import {connect} from "react-redux";
import Checkbox from "components/ui/form/checkbox";
import moment from "moment";
import formats from "dictionaries/formats";
import Button from "components/ui/button";
import {hideSwitch, listSwitches, showSwitch} from "store/reducers/kiutr/schedules/schedule_switches";

@propTypes({
    turn: PropTypes.object.isRequired,
    scheduleSwitches: PropTypes.array.isRequired,
    turns: PropTypes.array.isRequired,
})

@connect((state) => ({}), {getEntityNames, listSwitches, showSwitch, hideSwitch}, null, {withRef: true})

export default class KiutrRouteScheduleTurnSwitchesComponent extends Component {

    state = {
        related: new EntityList(),
        selectedRow: null,
        showHidden: false,
        hiddenSwitches: [],
        turnUuid: null,
    };

    componentDidUpdate() {
        this.loadRelatedEntities();

        if (this.state.turnUuid === this.props.turn.uuid) {
            return;
        }
        this.setState({
            turnUuid: this.props.turn.uuid,
        });

        this.loadHiddenSwitches();
    }

    async loadHiddenSwitches() {
        if (!this.props.turn.uuid) {
            return;
        }

        const response = await this.props.listSwitches({
            filters: {
                withScheduleTurn: this.props.turn.uuid,
            },
        });

        if (response.isOk) {
            this.setState({
                hiddenSwitches: _.map(response.payload.items, 'schedule_switch_uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadRelatedEntities() {
        const fromRoutes = _.map(_.filter(_.map(this.props.scheduleSwitches, 'from_route_uuid')), (uuid) => ({
            class: 'App\\Model\\Route',
            uuid: uuid,
            source: 'geo',
        }));

        const stopPoints = _.map(_.filter(_.map(this.props.scheduleSwitches, 'stop_point_uuid')), (uuid) => ({
            class: 'App\\Model\\StopPoint',
            uuid: uuid,
            source: 'geo',
        }));

        const list = _.concat(fromRoutes, stopPoints);
        if (_.filter(list, (object) => {
                return !this.state.related.getItem(object.uuid);
            }).length === 0) {
            return;
        }

        const response = await this.props.getEntityNames(list);

        if (response.isOk) {
            this.state.related.add(response);
            this.forceUpdate();
        }
    }

    async showHideSelected() {
        const items = {
            items: [
                {
                    schedule_switch_uuid: this.state.selectedRow,
                    schedule_turn_uuid: this.props.turn.uuid,
                }
            ],
        };

        if (this.state.showHidden) {
            await this.props.showSwitch(items)
        } else {
            await this.props.hideSwitch(items)
        }

        this.loadHiddenSwitches()
    }

    toggleShowHidden() {
        this.setState({
            showHidden: !this.state.showHidden,
        });
    }

    render() {
        const switches = this.getSwitches();
        if (switches.length === 0) {
            return null;
        }
        return (
            <div className="schedule-switching">
                <div className="Table">
                    <div className="Table-title">
                        Нераспределенные входящие переключения
                        <Button size="md" width="auto" color="red" shadow="red"
                                text={this.state.showHidden ? 'Отобразить отмеченное' : 'Скрыть отмеченное'}
                                onClick={::this.showHideSelected}/>
                        <Button size="md" width="auto" color="red" shadow="red"
                                text={this.state.showHidden ? 'Скрыть скрытые' : 'Показать скрытые'}
                                onClick={::this.toggleShowHidden}/>
                    </div>
                    <TableContainer>
                        <table className="b-table">
                            <thead>
                            <tr>
                                <th width="50px"/>
                                <th>С маршрута</th>
                                <th>Из расписания</th>
                                <th>Выход</th>
                                <th>Смена</th>
                                <th>Рейс</th>
                                <th>ОП</th>
                            </tr>
                            </thead>
                            <tbody>
                            {switches.map(::this.renderSwitch)}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </div>
        );
    }

    getSwitches() {
        return _.filter(this.props.scheduleSwitches, (scheduleSwitch) => {
            return _.filter(_.concat(this.props.turns, this.props.turn), (turn) => {
                return _.filter(turn.runs, {
                    type: 'switch_in',
                    schedule_switch_uuid: scheduleSwitch.uuid,
                }).length > 0;
            }).length === 0;
        });
    }

    renderSwitch(scheduleSwitch, index) {
        if (!this.state.showHidden && (_.indexOf(this.state.hiddenSwitches, scheduleSwitch.uuid) !== -1)) {
            return null;
        }
        if (this.state.showHidden && (_.indexOf(this.state.hiddenSwitches, scheduleSwitch.uuid) === -1)) {
            return null;
        }

        return (
            <tr key={index} onClick={this.selectRow.bind(this, scheduleSwitch.uuid)}>
                <td>
                    <Checkbox
                        checked={this.state.selectedRow === scheduleSwitch.uuid}
                    />
                    <span className="switch-stars">{_.map(_.range(scheduleSwitch.use_count || 0), (item) => {
                        return '*';
                    }).join(' ')}</span>
                </td>
                <td>{this.state.related.getReact(scheduleSwitch.from_route_uuid)}</td>
                <td>{this.renderScheduleInfo(scheduleSwitch.schedule)}</td>
                <td>{scheduleSwitch.schedule_turn_number}</td>
                <td>{scheduleSwitch.schedule_turn_shift_number}</td>
                <td>{scheduleSwitch.schedule_turn_run_number}</td>
                <td>{scheduleSwitch.stop_point_uuid ? this.state.related.getReact(scheduleSwitch.stop_point_uuid) : null}</td>
            </tr>
        )
    }

    renderScheduleInfo(schedule) {
        if (!schedule) {
            return null;
        }

        return `${schedule.name} [${moment(schedule.date_from).format(formats.DATE)} - ${schedule.date_to ? moment(schedule.date_to).format(formats.DATE) : 'n/a'}]`;
    }

    selectRow(uuid) {
        this.setState({
            selectedRow: (this.state.selectedRow === uuid) ? null : uuid,
        });
    }

    getSelected() {
        return (this.state.selectedRow !== null) ? _.find(this.props.scheduleSwitches, {uuid: this.state.selectedRow}) : null;
    }
}