import _ from 'lodash';
import * as store from "utils/storage";
import {api} from "helpers/api";
import {makeResponse} from "helpers/response";

class StopPointsBoundingBoxCache {

    stopPoints = {};
    key = 'stop-points:bounding-box';

    constructor() {
        this.stopPoints = store.get(this.key) || {};
    }

    isCacheable(meta) {
        const filters = meta.filters;
        return (_.size(filters) === 1) && _.get(filters, 'withBoundingBox');
    }

    save(key, items) {
        this.stopPoints[key] = [];

        _.each(items, (item) => {
            this.stopPoints[key].push({
                uuid: item.uuid,
                latitude: item.latitude,
                longitude: item.longitude,
                title: item.title,
                register_number: item.register_number,
            });
        });

        store.set(this.key, this.stopPoints);
    }

    flush() {
        store.remove(this.key);
    }

    getKey(boundingBox) {
        return [
            boundingBox.left_top.latitude,
            boundingBox.left_top.longitude,
            boundingBox.right_bottom.latitude,
            boundingBox.right_bottom.longitude,
        ].join(',');
    }

    async get(meta) {
        const boundingBox = _.get(meta, 'filters.withBoundingBoxExact');
        const key = this.getKey(boundingBox);

        if (!_.has(this.stopPoints, key)) {
            const response = await makeResponse(() => {
                return api.geo.getStopPoints({
                    filters: {
                        withBoundingBoxExact: boundingBox,
                    },
                }, true);
            });
            if (response.isOk) {
                this.save(key, response.payload.items);
            }
        }

        return {
            isOk: true,
            payload: {
                items: _.get(this.stopPoints, key, []),
            },
        };
    }

}

const stopPointsBoundingBoxCache = new StopPointsBoundingBoxCache();

export default stopPointsBoundingBoxCache;