import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import classNames from 'classnames';

import './validation-error.less'


@propTypes({
    error: PropTypes.string,
    className: PropTypes.string,
})

@defaultProps({
    error: null,
    className: '',
})

export default class ValidationError extends Component {

    render() {
        if (this.props.error) {
            return <div className={classNames('input__style_state_wrong', this.props.className)}>
                <span className="input__style_state_msg_wrong">{this.props.error}</span>
            </div>;
        }

        return null;
    }

}
