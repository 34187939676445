import React from 'react';

import Loader from 'components/ui/loader';
import {formatDistance} from 'helpers/math';

const RulerPopup = ({node, distance, totalDistance, address}) => {
    const restDistance = totalDistance - distance;
    const distanceToPoint = distance && Math.round(restDistance) ? <tr>
        <td>От начала до этой точки:</td>
        <td>{formatDistance(distance)}</td>
    </tr> : '';
    const distanceFromPoint = distance && Math.round(restDistance) ? <tr>
        <td>От этой точки до конца:</td>
        <td>{formatDistance(restDistance)}</td>
    </tr> : '';
    const loader = <Loader color="red" align="bottom"/>
    return (
        <div className="route-info-popup">
            <h2>Измеренное расстояние</h2>
            <table>
                <tbody>
                <tr>
                    <td>Общая длина:</td>
                    <td>{formatDistance(totalDistance)}</td>
                </tr>
                {distanceToPoint}
                {distanceFromPoint}
                <tr>
                    <td className="route-info-popup__address">Адрес:</td>
                    <td>{address ? address : loader}</td>
                </tr>
                <tr>
                    <td>Координаты точки</td>
                    <td>{node.coords[0].toFixed(6)}, {node.coords[1].toFixed(6)}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export default RulerPopup;