import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import classNames from 'classnames';

import IconButton from './icon-button';
import debounce from 'throttle-debounce/debounce';

import './top-menu-slider.less';


@propTypes({
    active: PropTypes.bool,
    onChange: PropTypes.func,
    title: PropTypes.string,
})

@defaultProps({
    active: false,
    title: '',
    onChange: () => {
    },
})

export default class TopMenuSlider extends Component {

    state = {};

    render() {
        const lineClassName = classNames('b-slider__line', this.props.active ? '_selected_all' : '');
        const circleClassName = classNames('b-slider__circle', this.props.active ? '_selected_all' : '');

        return (
            <div className="b-slider b-slider-top-menu _top-menu" onClick={this.props.onChange}>
                <div className="b-slider__control">
                    <div className={lineClassName}/>
                    <div className={circleClassName}/>
                </div>
            </div>
        );
    }
}