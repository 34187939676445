import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import PageModal from 'components/ui/page-modal';
import moment from 'moment';
import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import formats from "dictionaries/formats";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import {driverSummary} from 'store/reducers/kiutr/transport-work';
import './style.less';

@propTypes({
  data: PropTypes.object,
  onClose: PropTypes.func
})

@defaultProps({
  onClose: () => {}
})

@connect(state => ({}), {driverSummary})

export default class RTiOForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      isError: false,
      isLoading: false
    };

    //в минутах
    this.shiftDuration5Day = 480; //Длительность смены (5-дневная рабочая неделя)
    this.shiftDuration6Day = 420; //Длительность смены (6-дневная рабочая неделя)
    this.driveDurationShift = 600; //Время управления в течение смены
    this.driveDurationShift5Day = 540; //Время управления в течение смены (5-дневная рабочая неделя)
    this.driveDuration2Weeks = 5400; //Время управления за 2 недели подряд
    this.shiftInterval5Day = 2 * this.shiftDuration5Day; //Межсменный интервал (5-дневная рабочая неделя)
    this.shiftInterval6Day = 2 * this.shiftDuration6Day; //Межсменный интервал (6-дневная рабочая неделя)
  }

  componentWillMount() {
    this.loadData();
  }

  getTime(minutesTime) {
    //let time = moment(minutes, 'mm').format('HH:mm');
    let hours = 0;
    let minutes = 0;

    if (minutesTime >= 60) {
      hours = parseInt(minutesTime / 60);
      minutes = minutesTime % 60;
    }
    else {
      minutes = minutesTime;
    }

    const time = `${hours}ч ${minutes}м`;

    return time;
  }

  renderContent() {
    const { name, date } = this.props.data;
    const { work_shift, drive_shift, drive_two_weeks, shift_interval } = this.state.data;

    return (
      <div className="rtio_content">
        <div>
          <div className="rtio_field">
            <span className="rtio_field__name">Водитель:</span>
            <span className="rtio_field__value">{name}</span>
          </div>
          <div className="rtio_field">
            <span className="rtio_field__name">Данные на:</span>
            <span className="rtio_field__value">{date}</span>
          </div>
          <div className="rtio_field">
            <span className="rtio_field__name">Отработано за смену:</span>
            <span className={'rtio_field__value' + ((work_shift > this.shiftDuration5Day) ? ' violation' : '')}>{this.getTime(work_shift)}</span>
          </div>
          <div className="rtio_field">
            <span className="rtio_field__name">В управлении за смену:</span>
            <span className={'rtio_field__value' + ((drive_shift > this.driveDurationShift5Day) ? ' violation' : '')}>{this.getTime(drive_shift)}</span>
          </div>
          <div className="rtio_field">
            <span className="rtio_field__name">В управлении за 2 недели:</span>
            <span className={'rtio_field__value' + ((drive_two_weeks > this.driveDuration2Weeks) ? ' violation' : '')}>{this.getTime(drive_two_weeks)}</span>
          </div>
          <div className="rtio_field">
            <span className="rtio_field__name">Межсменный интервал:</span>
            <span className={'rtio_field__value' + ((shift_interval !== this.shiftInterval5Day) ? ' violation' : '')}>{this.getTime(shift_interval)}</span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const title = 'Сводка по режиму труда водителя';
    const content = this.state.isError ? null : this.state.isLoading ? <GlobalLoaderComponent/> : this.renderContent();

    const buttons = (
      <ModalTopMenuList>
        <ModalTopMenuButton
          key="close"
          className="_close"
          tooltip="Отменить"
          onClick={this.props.onClose}
        />
      </ModalTopMenuList>
    );

    return (
      <PageModal
        header={{title, buttons}}
        onClose={this.props.onClose}
        className="rtio-modal"
        withFade
      >
        {content}
      </PageModal>
    );
  }

  async loadData() {
    this.setState({isError: false, isLoading: true});
    const { uuid, date } = this.props.data;

    const meta = {
      user_uuid: uuid,
      date: moment(date, formats.DATE).format(formats.DATE_API)
    };

    let response = await this.props.driverSummary(meta);

    if (response.isOk) {
      this.setState({isError: false, isLoading: false, data: response.payload});
    }
    else {
      this.setState({isError: true, isLoading: false});
      response.showErrors();
    }
  }
}