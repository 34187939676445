import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Editor from './WaybillEditor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import _ from 'lodash';
import {deleteWaybill, getWaybills} from "store/reducers/kurs/waybills";
import {getTasks} from "store/reducers/kurs/tasks";
import moment from "moment";
import formats from "dictionaries/formats";
import {getUnits} from "store/reducers/organizational_units/units";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {User} from "helpers/user";
import {getUsers} from "store/reducers/staffing/staffing";

@connect(state => ({}), {getTasks, getWaybills, deleteWaybill, getEntityNames, getUnits, getVehicleList, getUsers})

export default class KursWaybillsComponent extends BaseTableWithEditorComponent {

    showWaybillBarchCloseBtn = true
    title = `${systems.road} → Журнал выдачи путевых листов`;
    baseUrl = '/road/waybills';

    defaultOrder = [[2, 'desc']];

    statuses = {
        draft: 'Черновик',
        opened: 'Открыт',
        closed: 'Закрыт',
    };

    componentDidMount() {
        if (!this.refs.table) return;
        $(this.refs.table.getWrappedInstance().refs.table).on('click', '.link', ::this.onLinkClick);
    }

    async onLinkClick(e) {
        const el = $(e.target);
        const url = el.data('url');

        this.props.router.push(url);
    }

    getEditor() {
        return (
            <Editor
                {...this.props}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('№ путевого листа')
                .fromField('waybills.number')
                .withDrawer(item => item.number),

            new Column('Задание')
                .fromField('task_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.task_uuid ? `<a href="javascript:void(0)" class="link" data-url="/road/tasks/${item.task_uuid}">${this.state.related.get(item.task_uuid)}</a>` : null)
                .withAsyncFilter('withTask', async (search) => {
                    const response = await this.props.getTasks({
                        search,
                        order: {
                            column: 'number',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 30,
                        },
                    });
                    if (response.isOk) {
                        return _.map(response.payload.items, item => ({
                            uuid: item.uuid,
                            name: item.number,
                        }));
                    }
                    return [];
                }),

            new Column('Дата составления')
                .fromField('waybills.date')
                .withDrawer(item => {return (moment(item.date).format(formats.DATE))})
                .withDateFilter(),

            new Column('ТС')
                .fromField('vehicle_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.vehicle_uuid && this.state.related.get(item.vehicle_uuid))
                .withAsyncFilter('withVehicle', async (search) => {
                    const response = await this.props.getVehicleList({
                        search,
                        filters: {
                            withComponent: 'road',
                        },
                        order: {
                            column: 'state_number',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 50,
                        },
                    });
                    if (response.isOk) {
                        return _.map(response.payload.items, vehicle => ({
                            uuid: vehicle.uuid,
                            name: vehicle.state_number,
                        }));
                    }
                    return [];
                }),

            new Column('Водитель')
                .fromField('driver_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.driver_uuid && this.state.related.get(item.driver_uuid))
                .withAsyncFilter('withDriver', async (search) => {
                    const response = await this.props.getUsers({
                        search,
                        filters: {
                            withComponent: 'road',
                        },
                        order: {
                            column: 'surname',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 50,
                        },
                    });
                    if (response.isOk) {
                        return _.map(response.payload.items, user => ({
                            uuid: user.uuid,
                            name: new User(user).getFullName()
                        }));
                    }
                    return [];
                }),

            new Column('Время выезда')
                .fromField('departure_time'),

            new Column('Время возврата')
                .fromField('arrival_time'),

            new Column('Одометр, км')
                .fromField('departure_mileage'),

            new Column('Предприятие')
                .fromField('unit_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid))
                .withFilter('withUnits', async () => {
                    const response = await this.props.getUnits({
                        filters: {
                            withComponent: 'road',
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            new Column('Статус')
                .fromField('status')
                .denyColumnFilter()
                .withDrawer(item => this.statuses[item.status])
                .withFilter('withStatus', () => {
                    return _.map(this.statuses, (name, uuid) => ({
                        uuid,
                        name,
                    }));
                }),
        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteWaybill(data);
    }

    async loadData(meta) {
        return await this.props.getWaybills(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;
        const vehicles = _.map(_.filter(_.map(result, 'vehicle_uuid')), (uuid) => ({
            class: 'App\\Model\\Vehicle',
            uuid: uuid,
            source: 'vehicles',
        }));

        const drivers = _.map(_.filter(_.map(result, 'driver_uuid')), (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));

        const units = _.map(_.filter(_.map(result, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const tasks = _.map(_.filter(_.map(result, 'task_uuid')), (uuid) => ({
            class: 'App\\Model\\Task',
            uuid: uuid,
            source: 'kurs',
        }));

        const response = await this.props.getEntityNames(_.concat(vehicles, drivers, units, tasks));

        if (response.isOk) {

            this.state.related.add(response);

            drawCallback(json);
        }
    }
}