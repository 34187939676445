import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import DashboardTable from 'components/modules/analytics/base-components/DashboardTable';
import Datepicker from "components/ui/form/datepicker";
import moment from 'moment';
import formats from 'dictionaries/formats';
import './style.less';
import {connect} from "react-redux";
import {getUnits} from "store/reducers/organizational_units/units";
import _ from 'lodash';
import {getOrderExecutions, getOrderExecutionUnitsSummary} from "store/reducers/kiutr/orders/order_executions";
import * as storage from "utils/storage";

@propTypes({})

@defaultProps({})

@connect((state) => ({}), {getUnits, getOrderExecutionUnitsSummary})

export default class BacklogWidget extends Component {

    storageKey = 'analytics:passenger:backlog';

    constructor(props) {
        super(props);

        const savedState = storage.get(this.storageKey);
        this.state = {
            from: moment().subtract(1, 'day').format(formats.DATE_API),
            to: moment().subtract(1, 'day').format(formats.DATE_API),
            data: [],
            units: [],
        };

        this.metadata = {
            0: {field: 'name', name: 'Транспортные предприятия', sort: true},
            1: {field: 'shiftCount', name: 'Кол-во рейсов', sort: true},
            2: {field: 'tardinessCount', name: 'Кол-во опозданий', sort: true},
            3: {field: 'advanceCount', name: 'Кол-во опережений', sort: true}
        };
    }

    async loadData() {
        this.setState({isLoading: true});

        const from = this.state.from;
        const to = this.state.to;
        if (!from || !to) {
            this.setState({isLoading: false});
            return;
        }

        const response = await this.props.getOrderExecutionUnitsSummary({
            units: _.keys(this.state.units),
            date_from: moment(from).format(formats.DATE),
            date_to: moment(to).format(formats.DATE),
        });

        this.setState({isLoading: false});
        if (response.isOk) {
            const orderExecutionsData = _.keyBy(response.payload.items, 'unit_uuid');

            const data = _.map(this.state.units, (unitName, unitUuid) => {
                const item = _.get(orderExecutionsData, unitUuid);
                return {
                    name: _.truncate(unitName, {
                        length: 20,
                    }),
                    shiftCount: _.get(item, 'count'),
                    tardinessCount: _.get(item, 'after'),
                    advanceCount: _.get(item, 'before'),
                };
            });

            this.setState({data});
        } else {
            response.showErrors();
        }
    }

    componentWillMount() {
        this.loadUnits();
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value});
        this.saveState();
        this.loadData();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value});
        this.saveState();
        this.loadData();
    };

    saveState() {
        storage.set(this.storageKey, {
            from: moment(this.state.from).format(formats.DATE_API),
            to: moment(this.state.to).format(formats.DATE_API),
        });
    }

    async loadUnits() {
        this.setState({isError: false, isLoading: true});

        try {
            const response = await this.props.getUnits({
                pagination: {
                    page: 1,
                    limit: 1000,
                },
                filters: {
                    withComponent: 'kiutr',
                },
            });

            await this.setState({
                units: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'name'),
                data: [],
                isError: false,
                isLoading: false,
            });
            this.loadData();
        }
        catch (e) {
            this.setState({isError: true, isLoading: false});
            error(e).showErrors();
        }
    }

    render() {
        return (
            <div className="backlog-widget">
                <div className="date-wrapper">
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange}/>
                    </div>
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange}/>
                    </div>
                </div>
                <div className="table-wrapper">
                    <DashboardTable data={this.state.data} metadata={this.metadata}/>
                </div>
            </div>
        );
    }
}