import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import _ from 'lodash';
import {success, error} from 'helpers/response';

const GET_USER_LOG_CODES = 'GET_USER_LOG_CODES';

const initialState = new Map({
    log_codes: {},
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_USER_LOG_CODES:
            return state.set('log_codes', _.mapValues(_.keyBy(action.payload.items, 'code'), 'name'));
    }
    return state;
}

export const getUserLogs = (meta) => async (dispatch) => {
    try {
        const response = await api.system.getUserLogs(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getUserLogCodes = () => async (dispatch) => {
    try {
        const response = await api.system.getUserLogCodes();

        dispatch({
            type: GET_USER_LOG_CODES,
            payload: response.payload
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};