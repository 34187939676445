import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import PageModal from 'components/ui/page-modal';

import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import * as alerts from "helpers/alerts";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {getUnits} from "store/reducers/organizational_units/units";
import {getRegisterRequest, updateRegisterRequest} from "store/reducers/portal/register_requests";
import moment from "moment";
import formats from "dictionaries/formats";
import systems from "dictionaries/systems";
import Button from "components/ui/button";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";

@propTypes({
    request: PropTypes.object,
    onClose: PropTypes.func,
})

@connect(state => ({}), {getEntityNames})

export default class ConfirmRequestHistory extends BaseEditor {

    state = {
        related: new EntityList,
    };

    async componentWillMount() {
        const userUuids = _.uniq(_.filter(_.map(this.props.request.history, 'user_uuid')));
        const users = _.map(userUuids, (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));

        const response = await this.props.getEntityNames(users);

        if (response.isOk) {
            this.state.related.add(response);
            this.forceUpdate();
        }
    }

    getFullTitle() {
        return `История обработки заявления №${_.get(this.props.request, 'number', '...')}`;
    }

    render() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <div>
                <PageModal
                    header={{title: this.getFullTitle(), buttons}}
                    onClose={this.props.onClose}
                    className={`profile-modal b-modal-register-requests-history`}
                    withFade={false}
                >
                    {this.props.request.history.map(::this.renderHistory)}
                </PageModal>
            </div>
        );
    }

    renderHistory(history, index) {
        return (
            <div key={index} className="history">
                <div className="history__table">
                    <div className="history__cell date border-right">
                        {moment(history.timestamp).format(formats.DATETIME)}
                    </div>
                    <div className="history__cell state">
                        {history.text}
                    </div>
                    <div className="history__cell name border-left">
                        {history.user_uuid ? this.state.related.getReact(history.user_uuid) : null}
                    </div>
                </div>
                {history.comment ? (
                    <div className="history__comment">
                        {history.comment}
                    </div>
                ) : null}
            </div>
        );
    }
}