import React, {Component} from 'react';
import moment from "moment";

import "./style.less";

export default class RegularityFooter extends Component {

    handleScroll() {
        const scrollLeft = this._stopPointContainer.scrollLeft;
        document.querySelector(".regularityMatrix__row__container").scrollLeft = scrollLeft;
        document.querySelector(".regularityHeader__stopPointsContainer").scrollLeft = scrollLeft;
    }

    renderFooterItems() {
        return this.props.stopPoints.concat(this.props.stopPoints).map((stopPoint, indexPoint) => {
            return <div key={indexPoint} className="regularityFooter__row">
                <div className="regularityFooter__item">
                    <div className="route_regularity regularityFooter__time">{moment.unix(stopPoint.lastrunplannedtime).format("HH:mm")}</div>
                    <div className="route_regularity regularityFooter__index">{indexPoint + 1}</div>
                    <div className="route_regularity regularityFooter__time">{(stopPoint.regularity * 100).toFixed(1)}%</div>
                </div>
            </div>
        });
    }

    render() {
        return (
            <div className="regularityFooter">
                <div className="regularityFooter__leftSideBar"></div>
                <div onScroll={() => this.handleScroll()} ref={(node) => this._stopPointContainer = node} className="regularityFooter__stopPointsContainer">
                    <div className="regularityFooter__regularity">
                        {this.renderFooterItems()}
                    </div>
                </div>
                <div className="regularityFooter__rightSideBar"></div>
            </div>
        )
    }
}