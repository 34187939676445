import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import _ from 'lodash';

import './history-modal.less';
import ModalTopMenuButtons from 'components/ui/modal/modal-top-menu-buttons';
import ContextTooltip from 'components/ui/context-tooltip';
import ModalTopMenuButton from 'components/ui/modal/modal-top-menu-button';
import PageModal from 'components/ui/page-modal';
import TableContainer from 'components/ui/Table/Container/TableContainer';
import moment from 'moment';
import formats from 'dictionaries/formats';
import { getBnsoList } from 'store/reducers/vehicles/bnso';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-component';
import Block from 'components/ui/form/block';
import SelectFilter from 'components/ui/select-filter';
import { getUsers } from 'store/reducers/staffing/staffing';
import { getEntityNames } from 'store/reducers/system';
import { EntityList } from 'helpers/entity';
import runTypes from 'dictionaries/runs';
import classNames from 'classnames';
import { getRouteVariant } from 'store/reducers/routes/route_variants';
import { Link } from 'react-router';
import { makeResponse } from 'helpers/response';
import { api } from 'helpers/api';
import download from 'downloadjs';
import InputMask from 'components/ui/form/input-mask';
import debounce from 'throttle-debounce/debounce';
import currentUser from 'helpers/current-user';

@propTypes({
	history: PropTypes.array,
	onClick: PropTypes.func,
	loadHistory: PropTypes.func,
	loadHistoryTasks: PropTypes.func,
	works: PropTypes.array
})
@connect(
	(state) => ({}),
	{
		getBnsoList,
		getEntityNames,
		getRouteVariant
	},
	null,
	{ withRef: true }
)
export default class HistoryModal extends Component {
	state = {
		opened: false,
		expanded: [],
		ports: [],
		currentType: 0,
		related: new EntityList(),
		page: 1,
		perPage: 50,

		pageTasks: 1,

		from_time: '',
		to_time: '',
		search: '',
		selected: {},
		searhOptions: false
	};
	activeOrderExecution = null;

	searchCallbackDebounce = debounce(500, ::this.onSearchChange);

	componentDidMount() {
		this.loadBnsoPorts(this.props.bnsoNumber);
		this.loadRelated(this.props);
	}

	componentWillUpdate(props) {
		if (props.bnsoNumber !== this.props.bnsoNumber) {
			this.loadBnsoPorts(props.bnsoNumber);
		}

		if (props.component === 'road') {
			const drivers = _.map(_.flatten(_.map(props.tasks, 'resources')), 'driver_uuid');
			const oldDrivers = _.map(_.flatten(_.map(this.props.tasks, 'resources')), 'driver_uuid');
			if (_.difference(drivers, oldDrivers).length > 0) {
				this.loadRelated(props);
			}
		}
	}

	async loadBnsoPorts(bnsoNumber) {
		const response = await this.props.getBnsoList({
			filters: {
				withBnsoNumber: bnsoNumber
			}
		});

		if (response.isOk) {
			const ports = _.get(_.first(response.payload.items), 'ports') || [];

			this.setState({
				ports
			});
		} else {
			response.showErrors();
		}
	}

	async loadRelated(props) {
		let itemsToLoad = [];
		if (props.component === 'road' || props.component === 'commdept') {
			const drivers = _.map(
				_.uniq(_.filter(_.map(_.flatten(_.map(props.tasks, 'resources')), 'driver_uuid'))),
				(uuid) => ({
					class: 'App\\Model\\UserInfo',
					uuid: uuid,
					source: 'auth'
				})
			);

			let items = [];
			_.each(props.tasks, (task) => {
				_.each(task.items_fact || task.items, (item) => {
					if (item.geometry_type === 'road_part') {
						items.push({
							class: 'App\\Model\\RoadPart',
							uuid: _.get(item, 'geometry.0.item_uuid'),
							source: 'kurs'
						});
					} else if (item.geometry_type === 'stop_point') {
						items.push({
							class: 'App\\Model\\StopPoint',
							uuid: _.get(item, 'geometry.0.item_uuid'),
							source: 'geo'
						});
					} else if (item.geometry_type === 'road_repair_part') {
						items.push({
							class: 'App\\Dictionaries\\Kurs\\KursRoadRepairParts\\Model',
							uuid: _.get(item, 'geometry.0.item_uuid'),
							source: 'dictionary'
						});
					}
				});
			});

			itemsToLoad = _.concat(drivers, items);
		}

		const response = await this.props.getEntityNames(itemsToLoad);
		if (response.isOk) {
			this.state.related.add(response);
			this.forceUpdate();
		}
	}

	render() {
		const modal = this.state.opened ? this.renderModal() : null;

		return (
			<div>
				<ContextTooltip key="map.history" code="map.history" default="История состояний" position="top">
					<div className="history-modal-btn" onClick={::this.showModal}>
						История состояний
					</div>
				</ContextTooltip>
				{modal}
			</div>
		);
	}

	showModal(isWork = false) {
		this.setState({
			opened: true
			// currentType: isWork ? 1 : 0,
		});
	}

	hideModal() {
		this.props.clearHistoryGeojson();
		this.activeOrderExecution = null;
		this.setState({
			opened: false
		});
	}

	onFilterChange(field, { target: { value } }) {
		let state = this.state;
		state[field] = value;
		this.setState(state);
	}

	onFilterKeyPress(e, type) {
		if (e.key === 'Enter') {
			e.preventDefault();

			if (type === 'tasks') {
				const from = moment(moment(this.props.from).format(formats.DATE_URL) + ' ' + this.state.from_time_tasks);
				const to = moment(moment(this.props.to).format(formats.DATE_URL) + ' ' + this.state.to_time_tasks);
				this.props.loadHistoryTasks(from, to);

				this.setState({
					pageTasks: 1
				});
			} else {
				this.props.loadHistory(this.state.from_time, this.state.to_time);

				this.setState({
					page: 1
				});
			}
		}
	}

	renderTimeFilter(type) {
		return (
			<div className="filter-time" key="diapason">
				<InputMask
					mask="99:99"
					defaultValue="__:__"
					value={type === 'tasks' ? this.state.from_time_tasks : this.state.from_time}
					onChange={this.onFilterChange.bind(this, type === 'tasks' ? 'from_time_tasks' : 'from_time')}
					className="filter-time__input"
					onKeyPress={(e) => {
						::this.onFilterKeyPress(e, type);
					}}
				/>

				<InputMask
					mask="99:99"
					defaultValue="__:__"
					value={type === 'tasks' ? this.state.to_time_tasks : this.state.to_time}
					onChange={this.onFilterChange.bind(this, type === 'tasks' ? 'to_time_tasks' : 'to_time')}
					className="filter-time__input"
					onKeyPress={(e) => {
						::this.onFilterKeyPress(e, type);
					}}
				/>
			</div>
		);
	}

	renderModal() {
		let drivers = [];

		// this.props.tasks.map((task) => {
		// 	task.resources.map((item) => {
		// 		drivers.push(item.driver_uuid);
		// 	});
		// });

		const title = <span>Транзакции</span>;

		const buttons = (
			<ModalTopMenuButtons>
				<ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
					<ModalTopMenuButton className="_close" onClick={::this.hideModal} />
				</ContextTooltip>
			</ModalTopMenuButtons>
		);

		return (
			<PageModal
				header={{
					title: (
						<div>
							История состояний
							<div className="b-modal__subname">
								{this.props.stateNumber}
								{this.props.displayExportBtn && (
									<a href="javascript:void(0)" onClick={::this.exportData}>
										Экспорт
									</a>
								)}
							</div>
						</div>
					),
					buttons
				}}
				onClose={::this.hideModal}
				className={`profile-modal b-modal-edit HistoryModal`}
				withFade={false}
			>
				{this.renderToggle()}
				{this.state.currentType === 0 ? (
					<span>
						<div className="period">
							{this.props.currentTime ? (
								<span>
									{' '}
									период с{' '}
									{moment(moment(this.props.currentTime, formats.DATETIME_SHORT)).format(formats.DATETIME_SHORT)}&nbsp;
								</span>
							) : null}
							{this.props.currentTimeTo ? (
								<span>
									{' '}
									по {moment(moment(this.props.currentTimeTo, formats.DATETIME_SHORT)).format(formats.DATETIME_SHORT)}
								</span>
							) : null}
						</div>
						<Block size="xl" title={title}>
							Водители:
							{_.map(drivers, (uuid, index) => {
								return <span key={`${uuid}:${index}`}>{this.state.related.getReact(uuid)}</span>;
							})}
						</Block>
					</span>
				) : null}
				{this.state.currentType === 0 ? (
					<div className="Table history__transactions">
						{this.renderPages()}
						<TableContainer>
							<table className="b-table">
								<thead>
									<tr>
										<th>
											<i
												className="b-block-indicators__icon b-block-indicators__icon_previous"
												onClick={::this.prevPage}
											/>
											Время
											<i className="b-block-indicators__icon b-block-indicators__icon_next" onClick={::this.nextPage} />
										</th>
										<th>Местонахождение</th>
										<th>Скорость</th>
										<th />
									</tr>
								</thead>
								<tbody>
									{this.props.history[0] && this.props.history[0]
										.slice((this.state.page - 1) * this.state.perPage, this.state.page * this.state.perPage)
										.map(::this.renderHistoryRow)}
								</tbody>
							</table>
						</TableContainer>
					</div>
				) : null}
				{this.state.currentType === 1 ? this.renderWork() : null}
			</PageModal>
		);
	}

	prevPage() {
		this.setCurrentPage(Math.max(1, this.state.page - 1));
	}

	nextPage() {
		const pagesCount = Math.ceil(this.props.history[0].length / this.state.perPage);
		this.setCurrentPage(Math.min(pagesCount, this.state.page + 1));
	}

	renderPages() {
		const currentPage = this.state.page;
		const pagesCount = Math.ceil(this.props.history[0].length / this.state.perPage);

		let pages;

		if (window.RNIS_SETTINGS.monitoring_work_history) {
			pages = _.range(1, pagesCount + 1);
		} else {
			pages = _.filter(_.range(1, pagesCount + 1), (page) => {
				return Math.abs(page - currentPage) <= 3;
			});
		}
		return (
			<div className="dataTables_paginate paging_simple_numbers">
				<span>
					{_.map(pages, (page) => {
						return (
							<a
								key={page}
								href="javascript:void(0)"
								onClick={this.setCurrentPage.bind(this, page)}
								className={classNames('paginate_button', page === this.state.page ? 'current' : '')}
							>
								{page}
							</a>
						);
					})}
				</span>
			</div>
		);
	}

	renderPagesWork(actualWorks) {
		const pagesCount = Math.ceil(actualWorks.length / this.state.perPage);
		let pages = _.range(1, pagesCount + 1);

		if (pagesCount === 0) {
			return [];
		} else {
			return (
				<div className="dataTables_paginate paging_simple_numbers">
					<span>
						{_.map(pages, (page) => {
							return (
								<a
									key={page}
									href="javascript:void(0)"
									onClick={this.setCurrentPageTasks.bind(this, page)}
									className={classNames('paginate_button', page === this.state.pageTasks ? 'current' : '')}
								>
									{page}
								</a>
							);
						})}
					</span>
				</div>
			);
		}
	}

	async setCurrentPage(page) {
		await this.setState({ page });
	}

	onSearchChange() {
		this.setState({ search: this.refs.search.value.toLowerCase() });
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	renderSearch(works) {
		return (
			<div className="filtration">
				<div className="filtration__search">
					<input
						key="search"
						type="text"
						ref="search"
						className="filtration__pole"
						placeholder="Поиск операции"
						onChange={this.searchCallbackDebounce}
						onKeyDown={::this.handleKeyPress}
					/>
					<input key="button" type="button" className="filtration__button" />
				</div>
			</div>
		);
	}

	setCurrentPageTasks(pageTasks) {
		this.setState({ pageTasks });
	}

	renderWork() {
		if (this.props.component === 'road' || this.props.component === 'commdept') {
			return this.renderTasks();
		}
		if (_.indexOf([ 'kiutr', 'ems' ], this.props.component) !== -1) {
			return this.renderRuns();
		}
	}

	onTaskItemClick(task, item, index) {
		if ((item.fact_date_from || item.date_from) && item.geojson) {
			const time = moment(moment(task.date).format(formats.DATE_URL) + ' ' + (item.fact_date_from || item.date_from));
			this.props.onCurrentTimeChange(time.unix());

			let items = [
				{
					geojson: item.geojson,
					color: this.getLineColor(item),
					index,
					task
				}
			];

			if (this.props.mapDisplayParts) {
				_.each(item.confirmed_parts || [], (confirmedPart, _index) => {
					items.push({
						geojson: confirmedPart,
						color: 'green'
					});
				});
			}

			this.props.onGeojsonChange(items);
		}
	}

	getLineColor(item) {
		if (this.props.mapDisplayParts) {
			return item.is_confirmed ? 'yellow' : '#c400f6';
		}
		return item.is_confirmed ? 'green' : 'red';
	}

	async onOrderExecutionClick(orderExecution) {
		if (this.activeOrderExecution === orderExecution.uuid) {
			this.props.clearHistoryGeojson();
			this.activeOrderExecution = null;
		} else {
			this.activeOrderExecution = orderExecution.uuid;
			const time = moment(orderExecution.date_start);
			this.props.onCurrentTimeChange(time.unix());
			this.props.onGeojsonChange(await this.loadRouteVariant(orderExecution.route_variant_uuid));
		}
	}

	async loadRouteVariant(uuid) {
		const response = await this.props.getRouteVariant(uuid);

		if (response.isOk) {
			const route = response.payload;
			let coordinates = [];
			_.each(_.concat(route.forward_points, route.reverse_points), (point) => {
				coordinates = _.concat(coordinates, _.get(point, 'path_to_the_next_point_geometry.coordinates', []));
			});
			return {
				type: 'LineString',
				coordinates
			};
		} else {
			response.showErrors();
		}
	}

	renderTasks() {
		let works = [];
		this.props.tasks.map((task) => {
			if (task.items_fact) {
				works.push(...task.items_fact);
			} else if (task.items) {
				works.push(...task.items);
			}
		});

		works.map((item) => {
			item.work_type = {};
			if (item.work_type) {
				item.work_type.name = _.get(_.get(this.props.workTypes, item.work_type_uuid), 'name');
				item.work_type.uuid = item.work_type_uuid;
			}
		});

		const filteredList = _.filter(works, (item) => {
			if (this.state.search) {
				if (item.work_type.name.toLowerCase().indexOf(this.state.search) === -1) {
					return false;
				}
			}
			return true;
		});

		let actualWorks = works;
		if (this.state.search) {
			actualWorks = filteredList;
		}

		return (
			<div className="history__work">
				{window.RNIS_SETTINGS.monitoring_work_history ? (
					<div>
						{this.renderSearch(works)}
						{this.renderPagesWork(actualWorks)}
					</div>
				) : null}
				{_.map(this.props.tasks, (task) => {
					const drivers = _.map(task.resources, 'driver_uuid');
					const title = (
						<span>
							Структура работ ТС на задании{' '}
							<Link to={`/${this.props.component}/tasks/${task.uuid}`} target="_blank">
								{task.number}
							</Link>{' '}
							на {moment(task.date).format(formats.DATE)}
						</span>
					);

					return (
						<div key={task.uuid}>
							<Block size="xl" title={title}>
								Водители:
								{_.map(drivers, (uuid, index) => {
									return <span key={`${uuid}:${index}`}>{this.state.related.getReact(uuid)}</span>;
								})}
							</Block>
							<div className="Table history__transactions">
								<TableContainer>
									<table className="b-table">
										<thead>
											<tr>
												<th>Операция</th>
												<th>Время начала</th>
												<th>Время окончания</th>
												<th>
													<span>Статус</span>
													<br />
													<span>выполнения</span>
												</th>
											</tr>
										</thead>
										<tbody>
											{_.map(
												(window.RNIS_SETTINGS.monitoring_work_history
													? (task.items_fact || task.items)
													: actualWorks).slice(
													(this.state.pageTasks - 1) * this.state.perPage,
													this.state.pageTasks * this.state.perPage
												),
												(item, index) => {
													let title;
													let workType;
													if (item.movement_type === 'work') {
														if (window.RNIS_SETTINGS.monitoring_work_history) {
															workType = item.work_type.name;
														} else {
															workType = _.get(_.get(this.props.workTypes, item.work_type_uuid), 'name');
														}

														if (item.geometry_type !== 'other') {
															title = (
																<span>
																	{workType}
																	({this.state.related.getReact(_.get(item, 'geometry.0.item_uuid'))})
																</span>
															);
														} else {
															title = <span>{workType} (произвольный участок)</span>;
														}
													} else {
														title = 'Холостой ход';
													}

													return (
														<tr key={index} onClick={this.onTaskItemClick.bind(this, task, item, index)}>
															<td>
																{index + 1}. {title}
															</td>
															<td>{item.fact_date_from || item.date_from}</td>
															<td>{item.fact_date_to || item.date_to}</td>
															<td>
																<span
																	style={{
																		color: item.is_confirmed ? 'green' : 'red'
																	}}
																>
																	Проход: {item.percent}%
																</span>
																<br />
																Р/з: {item.is_manual ? '+' : '-'}
															</td>
														</tr>
													);
												}
											)}
										</tbody>
									</table>
								</TableContainer>
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	renderRuns() {
		return _.map(this.props.orders, (order) => {
			const runs = _.flatten(_.map(order.shifts, 'runs'));
			const recalcRuns = _.flatten(_.map(_.get(order, 'order_recalc.shifts', []), 'runs'));
			const drivers = _.filter(_.uniq(_.concat(_.map(runs, 'driver_name'), _.map(recalcRuns, 'driver_name'))));

			const title = (
				<span>
					Структура работ ТС в наряде <Link to={`/kiutr/orders/${order.uuid}`}>{order.number}</Link> на{' '}
					{moment(order.date).format(formats.DATE)}
				</span>
			);

			return (
				<div key={order.uuid}>
					<Block size="xl" title={title}>
						<div className="b-block__text drivers">
							<span className="drivers__title">Водители:</span>
							{drivers.join(', ')}
						</div>
					</Block>
					<div className="Table history__work">
						<TableContainer>
							<table className="b-table">
								<thead>
									<tr>
										<th>Операция</th>
										<th>Время начала</th>
										<th>Время окончания</th>
										<th>Статус выполнения</th>
									</tr>
								</thead>
								<tbody>
									{_.map(runs, (run, index) => {
										const orderExecution = _.find(this.props.orderExecutions, { order_run_uuid: run.uuid });

										const first = orderExecution
											? _.first(_.filter(orderExecution.data, (item) => item.time_fact))
											: null;
										const last = orderExecution
											? _.last(_.filter(orderExecution.data, (item) => item.time_fact))
											: null;
										const isInactive =
											moment(run.date_to).isBefore(this.props.from) || moment(run.date_from).isAfter(this.props.to);

										return (
											<tr
												key={run.uuid}
												onClick={orderExecution ? this.onOrderExecutionClick.bind(this, orderExecution) : null}
												className={classNames({
													'history-modal-row_inactive': isInactive,
													'active history-modal-row_active':
														orderExecution && orderExecution.uuid === this.activeOrderExecution
												})}
											>
												<td>
													{index + 1}. {runTypes[run.type]}
												</td>
												<td className="timing">
													<div className="timing__plan">{/* {moment(run.date_from).format(formats.TIME)} */}</div>
													{!isInactive && first ? (
														<div
															className={classNames({
																timing__fact: true,
																timing__fact_green: first.is_in_time,
																timing__fact_red: !first.is_in_time
															})}
														>
															{moment(first.time_fact).format(formats.TIME)}
														</div>
													) : null}
												</td>
												<td className="timing">
													<div className="timing__plan">{/* {moment(run.date_to).format(formats.TIME)} */}</div>
													{!isInactive && last ? (
														<div
															className={classNames({
																timing__fact: true,
																timing__fact_green: last.is_in_time,
																timing__fact_red: !last.is_in_time
															})}
														>
															{moment(last.time_fact).format(formats.TIME)}
														</div>
													) : null}
												</td>
												<td>
													{!isInactive && orderExecution ? (
														<div className="status">
															<div className="status__block">
																<span className="status__title">Взятие ОП:</span>
																<span
																	className={classNames({
																		status__value: true,
																		status__value_green: orderExecution.is_checked,
																		status__value_red: !orderExecution.is_checked
																	})}
																>
																	{this.getRunPercent(orderExecution)}%
																</span>
															</div>
															<div className="status__block">
																<span className="status__title">Рег:</span>
																<span
																	className={classNames({
																		status__value: true,
																		status__value_green: orderExecution.is_checked,
																		status__value_red: !orderExecution.is_checked
																	})}
																>
																	{this.getRunRegularity(orderExecution)}%
																</span>
															</div>
														</div>
													) : null}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</TableContainer>
					</div>
				</div>
			);
		});
	}

	getRunPercent(orderExecution) {
		const stopPoints = _.filter(orderExecution.data, { point_type: 'stop_point' });
		const checkedStopPoints = _.filter(stopPoints, { is_skipped: false });

		return stopPoints.length !== 0 ? _.round(100 * checkedStopPoints.length / stopPoints.length) : 0;
	}

	getRunRegularity(orderExecution) {
		const stopPoints = _.filter(orderExecution.data, { point_type: 'stop_point' });
		const checkedStopPoints = _.filter(stopPoints, { is_in_time: true });

		return stopPoints.length !== 0 ? _.round(100 * checkedStopPoints.length / stopPoints.length) : 0;
	}

	renderToggle() {
		if (_.indexOf([ 'kiutr', 'ems', 'road', 'children', 'commdept' ], this.props.component) === -1) {
			return;
		}

		return (
			<Block size="xl">
				<div className="filter-wrapper">
					<SelectFilter
						items={[ 'Транзакции', 'Работы' ]}
						currentItem={this.state.currentType}
						onChange={(index) => this.setState({ currentType: index })}
					/>
				</div>
				{window.RNIS_SETTINGS.monitoring_work_history && this.state.currentType === 0 ? this.renderTimeFilter() : null}
			</Block>
		);
	}

	renderHistoryRow(history, index) {
		return [
			<tr key={index}>
				<td onClick={this.onClick.bind(this, history)}>{moment.unix(history.time).format(formats.DATETIME)}</td>
				<td>
					{_.round(history.lat, 6)}, {_.round(history.lng, 6)}
				</td>
				<td>{history.speed} км/ч</td>
				<td>
					{_.indexOf(this.state.expanded, index) !== -1 ? (
						<a href="javascript:void(0)" className="history__open" onClick={this.toggleRow.bind(this, index)}>
							<i className="b-block-indicators__icon b-block-indicators__icon_arrow visible" />
						</a>
					) : (
						<a href="javascript:void(0)" className="history__open" onClick={this.toggleRow.bind(this, index)}>
							<i className="b-block-indicators__icon b-block-indicators__icon_arrow" />
						</a>
					)}
				</td>
			</tr>,
			_.indexOf(this.state.expanded, index) !== -1 ? (
				<tr key={`${index}:expand`} className="history__transaction-info">
					<td colSpan={4}>
						<div className="b-block">
							<div className="b-block-indicators">
								<Tooltip title="Зажигание" position="bottom">
									<div className="b-block-indicators__item">
										<i className="b-block-indicators__icon b-block-indicators__icon_ignition" />
										<span className="b-block-indicators__dscn">{_.toInteger(history.ignition) ? 'Есть' : 'Нет'}</span>
									</div>
								</Tooltip>

								<Tooltip title="Спутники (кол-во)" position="bottom">
									<div className="b-block-indicators__item">
										<i className="b-block-indicators__icon b-block-indicators__icon_satellite" />
										<span className="b-block-indicators__dscn">{history.satellites}</span>
									</div>
								</Tooltip>

								{history.geo_signal !== undefined ? (
									<Tooltip title="Уровень сигнала ГЛОНАСС/GPS" position="bottom">
										<div className="b-block-indicators__item">
											<i className="b-block-indicators__icon b-block-indicators__icon_signal-strength" />
											<span className="b-block-indicators__dscn">{history.geo_signal}%</span>
										</div>
									</Tooltip>
								) : null}

								{history.gsm_signal !== undefined ? (
									<Tooltip title="Уровень сигнала GSM" position="bottom">
										<div className="b-block-indicators__item">
											<i className="b-block-indicators__icon b-block-indicators__icon_signal" />
											<span className="b-block-indicators__dscn">{history.gsm_signal}%</span>
										</div>
									</Tooltip>
								) : null}

								{history.voltage !== undefined ? (
									<Tooltip title="Питание" position="bottom">
										<div className="b-block-indicators__item">
											<i className="b-block-indicators__icon b-block-indicators__icon_supply" />
											<span className="b-block-indicators__dscn">{history.voltage}В</span>
										</div>
									</Tooltip>
								) : null}

								{history.battery !== undefined ? (
									<Tooltip title="Заряд батареи" position="bottom">
										<div className="b-block-indicators__item">
											<i className="b-block-indicators__icon b-block-indicators__icon_charge" />
											<span className="b-block-indicators__dscn">{history.battery}%</span>
										</div>
									</Tooltip>
								) : null}

								<div className="b-block-indicators__item">
									<i className="b-block-indicators__icon b-block-indicators__icon_compass" />
									<span className="b-block-indicators__dscn">{history.course}°</span>
								</div>
							</div>
						</div>

						<div className="subtable-container">
							<table className="subtable">
								<thead>
									{(this.state.ports || []).length > 0 ? (
										<tr>
											<th>Физич. №</th>
											<th>Вирт. №</th>
											<th>Значение</th>
											<th>Индикатор</th>
											<th>Знач. индикатора</th>
										</tr>
									) : (
										<tr>
											<th colSpan={5}>Порты БНСО не настроены</th>
										</tr>
									)}
								</thead>
								<tbody>
									{_.map(this.state.ports, (port, portIndex) => {
										if (!port.bnso_indicator_uuid) {
											return null;
										}

										const field = `${port.type === 'numeric'
											? 'Analog'
											: 'Digital'}_${port.inner_port}_${port.inner_sensor}`;

										return (
											<tr key={portIndex}>
												<td>{port.inner_port}</td>
												<td>{port.inner_sensor}</td>
												<td>{_.get(history.extendedDataRaw, field)}</td>
												<td>{_.get(_.get(this.props.bnsoIndicators, port.bnso_indicator_uuid), 'name')}</td>
												<td>{_.get(history.extendedData, port.bnso_indicator_uuid)}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</td>
				</tr>
			) : null
		];
	}

	toggleRow(index) {
		const position = _.indexOf(this.state.expanded, index);
		let expanded = this.state.expanded;
		if (position !== -1) {
			expanded.splice(position, 1);
		} else {
			expanded.push(index);
		}
		this.setState({ expanded });
	}

	onClick(history) {
		this.props.onClick(history);
	}

	exportData() {
		const html = `<table>
            <thead>
                <tr>
                    <th>Время</th>
                    <th>Широта</th>
                    <th>Долгота</th>
                    <th>Скорость</th>
                    <th>Зажигание</th>
                </tr>
            </thead>
            <tbody>
                ${_.map(this.props.history[0], (history) => {
									return `<tr>
                            <td>${moment.unix(history.time).format(formats.DATETIME)}</td>
                            <td>${_.round(history.lat, 6)}</td>
                            <td>${_.round(history.lng, 6)}</td>
                            <td>${history.speed}</td>
                            <td>${_.toInteger(history.ignition) ? 1 : 0}</td>
                        </tr>`;
								})}
            </tbody>
        </table>`;

		this.convertToXls(html);
	}

	async convertToXls(content) {
		const response = await makeResponse(() => {
			return api.converter.convertHtmlToXls(content);
		});

		if (response.isOk) {
			const content = response.payload.content;
			download(`data:application/excel;base64,${content}`, `Экспорт ${moment().format(formats.DATETIME)}.xls`);
		} else {
			response.showErrors();
		}
	}
}
