import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {Link} from 'react-router';
import classNames from 'classnames';
import SystemStatus from 'components/ui/system_status';
import _ from 'lodash';

import './left-side.less';
import {connect} from "react-redux";
import {closeSubmenu, openSubmenu} from "store/reducers/menu";
import * as alerts from "helpers/alerts";
import currentUser from 'helpers/current-user';
import $ from 'jquery';
import IScroll from 'iscroll';
import ReactResizeDetector from 'react-resize-detector';
import {getHostName} from "helpers/wsrpc";

@propTypes({
    menu: PropTypes.array
})

@defaultProps({
    menu: [],
})

@connect((state) => ({
    submenu: state.menu.get('submenu'),
}), {openSubmenu, closeSubmenu})

export default class LeftSide extends Component {
    state = {
        opened: false
    };

    scroll = null;

    updateCount = 0;

    componentDidUpdate() {
        this.updateCount++;
        if (!$('.sub-menu_open').length) {
            this.scroll = new IScroll('.left-menu__wrapper', {
                mouseWheel: true,
                scrollbars: false,
                click: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
            });
        } else {
            this.scroll = new IScroll('.sub-menu_open', {
                mouseWheel: true,
                scrollbars: false,
                click: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
            });
        }
        if (this.scroll) {
            this.scroll.on('scrollEnd', ::this.onResize);
        }
        this.onResize();
    }

    componentWillMount() {
        setTimeout(() => this.setUnitImage(), 1000);
    }

    componentDidMount() {
        this.forceUpdate();
        this.setUnitImage()
    }



    render() {
        const menuClassName = classNames('left-menu', this.state.opened ? 'left-menu_expanded' : '');
        const buttonsClassName = classNames('left-menu-button', this.state.opened ? 'left-menu-button_open' : '');

        return (
            <div id='leftMenu'  key={this.updateCount} className={menuClassName} style={{'top': `${currentUser.user.is_demobar ? '19px' : '0'}`}}>
                <div className="left-menu-button-scroll left-menu-button-scroll_up" onClick={::this.scrollUp}/>
                <a href="#" className={buttonsClassName} onClick={::this.toggleMenu}/>
                <div className="left-menu-button-scroll left-menu-button-scroll_down" onClick={::this.scrollDown}/>
                <div className="left-menu__wrapper">
                    <ul className="left-menu__list">
                        {(this.hasImage() ? (<li className="left-menu__item"><a className="left-menu__link logo" href="/">
                            <img src={this.state.organization_image} alt="" width="64px"/>
                        </a></li>) : null)}
                        {_.filter(this.props.menu).map(this.renderItem.bind(this, 0))}
                    </ul>
                    <SystemStatus/>
                </div>
                <ReactResizeDetector handleHeight onResize={::this.onResize}/>
            </div>
        );
    }

    onResize() {
        if (!this.scroll) {
            return;
        }

        const scrollHeight = Math.max(0, this.scroll.scrollerHeight - this.scroll.wrapperHeight);
        const nextY = Math.max(-scrollHeight, this.scroll.y);
        if (nextY !== -scrollHeight) {
            $('.left-menu-button-scroll_down').show();
        } else {
            $('.left-menu-button-scroll_down').hide();
        }
        if (this.scroll.y === 0) {
            $('.left-menu-button-scroll_up').hide();
        } else {
            $('.left-menu-button-scroll_up').show();
        }
    }

    scrollDown() {
        const scrollHeight = Math.max(0, this.scroll.scrollerHeight - this.scroll.wrapperHeight);
        this.scroll.scrollTo(0, Math.max(-scrollHeight, this.scroll.y - 40));
        this.onResize();
    }

    scrollUp() {
        this.scroll.scrollTo(0, Math.min(0, this.scroll.y + 40));
        this.onResize();
    }

    toggleMenu(e) {
        e.preventDefault();

        this.setState({
            opened: !this.state.opened
        });
    }

    setUnitImage() {
        let image =  JSON.parse(localStorage.getItem("localsettings"));
        if (!image) return ;
        const protocol = App.isSecure ? 'https://' : 'http://';
        let url = protocol + getHostName('ajax', false) + '/storage/' + image.organization_image;
        this.setState({
            organization_image: url,
        });
    }

    hasImage() {
        let img = JSON.parse(localStorage.getItem("localsettings"));
        return _.has(img, 'organization_image') && img.organization_image && img.organization_image.length;
    }

    renderItem(level, item, i) {
        if (!item) {
            return null;
        }
        if (item.permission) {
            if (_.isArray(item.permission)) {
                if (!currentUser.can(item.permission[0], item.permission[2] || 'read', item.permission[1])) {
                    return null;
                }
            } else if (!currentUser.can(item.permission)) {
                return null;
            }
        }

        const submenuOpened = this.props.submenu && (this.props.submenu.length > 0);

        let link;

        if (item.route) {
            link = <Link key={i} className={`left-menu__link ${item.class}`} activeClassName="active" to={item.route}
                         onClick={item.submenu ? this.openSubmenu.bind(this, item.key, false) : (item.onClick || ::this.closeAlerts)}>
                {item.counter}
                <span className="left-menu__text">{item.text}</span>
                <span className="left-menu__title">{item.text}</span>
            </Link>
        } else if (item.href) {
            link = <a key={i} target="_blank" className={`left-menu__link ${item.class}`} href={item.href}
                      onClick={item.onClick || ::this.closeAlerts}>
                {item.counter}
                <span className="left-menu__text">{item.text}</span>
                <span className="left-menu__title">{item.text}</span>
            </a>
        } else {
            link = <a key={i} className={`left-menu__link ${item.class}`} href="#"
                      onClick={this.openSubmenu.bind(this, item.key, true)}>
                {item.counter}
                <span className="left-menu__text">{item.text}</span>
                <span className="left-menu__title">{item.text}</span>
            </a>
        }

        let submenu = null;

        if (item.submenu) {
            if (!this.hasSubmenu(item)) {
                if (submenuOpened && (item.key && this.props.submenu.indexOf(item.key) !== -1)) {
                    this.props.closeSubmenu();
                }
                return null;
            }
            const submenuClass = classNames('sub-menu', (item.key && this.props.submenu && this.props.submenu.indexOf(item.key) !== -1) ? 'sub-menu_open' : '');

            submenu = <div className={submenuClass}>
                <ul className="left-menu__list">
                    {(this.hasImage() ? (<li className="left-menu__item"><a className="left-menu__link logo" href="/">
                        <img src={this.state.organization_image} alt="" width="64px"/>
                    </a></li>) : null)}
                    <li className="left-menu__item">
                        <a className="left-menu__link back" href="#" onClick={::this.closeSubmenu}>
                            <span className="left-menu__text">Вернуться назад</span>
                            <span className="left-menu__title">Вернуться назад</span>
                        </a>
                    </li>
                    {item.submenu.map(this.renderItem.bind(this, level + 1))}
                </ul>
            </div>;
        }

        if (level !== 0 || (level === 0 && !submenuOpened) || (item.key && this.props.submenu.indexOf(item.key) !== -1)) {
            return (
                <li key={i} className={classNames('left-menu__item', item.superclass)}>
                    {link}
                    {submenu}
                </li>
            );
        }
        return null;
    }

    hasSubmenu(item) {
        return _.filter(item.submenu, (item) => {
            if (!item) {
                return false;
            }
            if (item.submenu) {
                return this.hasSubmenu(item);
            }
            if (!item.permission) {
                return true;
            }
            if (_.isArray(item.permission)) {
                if (!currentUser.can(item.permission[0], 'read', item.permission[1])) {
                    return false;
                }
            } else if (!currentUser.can(item.permission)) {
                return false;
            }
            return true;
        }).length > 0;
    }

    closeAlerts() {
        alerts.close();
    }

    openSubmenu(submenuKey, preventDefault, e) {
        preventDefault && e.preventDefault();

        this.props.openSubmenu(submenuKey);
    }

    closeSubmenu(e) {
        e.preventDefault();
        this.props.closeSubmenu();
    }
}