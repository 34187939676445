import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './input.less';
import _ from 'lodash';

@propTypes({})

@defaultProps({})

export default class Input extends Component {

    state = {
        passwordOpened: false,
    };

    isPasswordOpened() {
        return this.state.passwordOpened;
    }

    render() {
        const inputProps = {};

        if (this.props.onEnter)
            inputProps.onKeyPress = ::this.handleKeyPress;

        if (this.props.name)
            inputProps.name = this.props.name;

        if (!_.isUndefined(this.props.value)) {
            inputProps.value = this.props.value;
        }

        if (!_.isUndefined(this.props.min)) {
            inputProps.min = this.props.min;
        }

        if (!_.isUndefined(this.props.max)) {
            inputProps.max = this.props.max;
        }

        if (this.props.autoComplete) {
            inputProps.autoComplete = this.props.autoComplete;
        }

        if (this.props.step) {
            inputProps.step = this.props.step;
        }
        inputProps.disabled = this.props.disabled;
        inputProps.onKeyPress = this.props.onKeyPress;

        if (this.props.positive) {
            if (this.props.min >= 1) {
                inputProps.min = this.props.min;
            } else {
                inputProps.min = 0;
            }
            inputProps.onKeyPress = (e) => {
                if (this.props.min >= 1 && !this.props.value) {
                    if ((e.charCode < 46) || (e.charCode > 57) || (e.charCode === 48)) {
                        e.preventDefault();
                    }
                } else {
                    if ((e.charCode < 46) || (e.charCode > 57)) {
                        e.preventDefault();
                    }
                }
            }
        }

        if (this.props.percent) {
            inputProps.onChange = (e) => {
                let value = _.get(e, 'target.value');
                if (value) {
                    value = Math.max(0, Math.min(100, _.toInteger(value)));
                }

                this.props.onChange({
                    target: {
                        value,
                    },
                });
            };
        }

        const styleClassName = classNames('input__style', this.props.error ? 'input__style_state_wrong' : '');
        return (
            <div className={classNames('input', this.props.className, `input-${this.props.type}`)}>
                <input
                    ref="input"
                    className="input__control"
                    defaultValue={this.props.defaultValue}
                    type={this.state.passwordOpened ? 'text' : this.props.type}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    {...inputProps} />
                <div className={styleClassName}>
                    {this.props.error ? <span
                        className="input__style_state_msg input__style_state_msg_wrong">{/[а-яА-ЯЁё ]/.test(this.props.error) ? this.props.error : 'Поле обязательно для заполнения'}</span> : null}
                </div>
                {(this.props.type === 'password') ? (
                    <div
                        className={classNames('input__inner input__inner_password', this.state.passwordOpened ? 'input__inner_password_open' : '')}
                        onClick={::this.togglePasswordOpened}/>
                ) : null}
            </div>
        );
    }

    async togglePasswordOpened() {
        await this.setState({
            passwordOpened: !this.state.passwordOpened,
        });

        this.props.onPasswordOpenedChange && this.props.onPasswordOpenedChange();
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.props.onEnter();
        }
    }
}
