import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defaultProps, propTypes} from 'react-props-decorators';
import {PieChart, Pie, Legend, Tooltip, Label, ResponsiveContainer} from 'recharts';
import './index.less';

@propTypes({
    data: PropTypes.array,
    nameKey: PropTypes.string,
    dataKey: PropTypes.string,
    title: PropTypes.string
})

@defaultProps({
    data: [],
    nameKey: 'name',
    dataKey: 'value',
    title: ''
})


export default class PieChartComponent extends Component {
    render() {
        return (
            <ResponsiveContainer>
                <PieChart>
                    <Pie data={this.props.data}
                         nameKey={this.props.nameKey}
                         dataKey={this.props.dataKey}
                         cx="50%" cy="50%" innerRadius={40} outerRadius={80}
                         label
                    >
                        <Label value={this.props.title} position="center" />
                    </Pie>
                    <Legend/>
                    <Tooltip active={true}/>
                </PieChart>
            </ResponsiveContainer>

        );
    }
}