import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";
import BaseEditorFormComponent from "components/base/base-editor-form";
import {getVehicle, createVehicle, updateVehicle, deleteVehicle} from "store/reducers/vehicles/vehicles";
import BaseEditor from "components/base/base-editor";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";
import {getUnits} from "store/reducers/organizational_units/units";
import currentUser from 'helpers/current-user';
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getBnsoList} from "store/reducers/vehicles/bnso";
import {getVehicleBnsoList} from "store/reducers/vehicles/vehicle_bnso";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import {State} from "components/ui/state";
import {api} from "helpers/api";
import FileReaderInput from 'react-file-reader-input';
import {getKursMaintenances} from "store/reducers/kurs/maintenances";
import TableContainer from "components/ui/Table/Container/TableContainer";
import moment from "moment";
import formats from "dictionaries/formats";
import {getVehicleWorks} from "store/reducers/kurs/vehicle_works";
import Button from "components/ui/button";
import KursOperationAddForm from "components/modules/kurs/vehicles/vehicle-editor-form/operation-add-form/index";
import pluralize from "pluralize-ru";
import * as alerts from "../../../../helpers/alerts";
import {getHostName} from "../../../../helpers/wsrpc";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getVehicle, createVehicle, updateVehicle, deleteVehicle, getVehicleBnsoList})

export default class VehicleEditor extends BaseEditor {

    title = 'ТС';
    modelClass = 'App\\Model\\Vehicle';

    async loadData(uuid) {
        const response = await this.props.getVehicle(uuid);
        if (response.isOk) {
            let vehicle = response.payload;
            vehicle.bnso = await this.loadBnso(vehicle);

            response.payload = vehicle;
        }
        return response;
    }

    async loadBnso(vehicle) {
        const response = await this.props.getVehicleBnsoList(vehicle.uuid);

        if (response.isOk) {
            return _.sortBy(response.payload.items, (el) => new moment(el.installation_date)).reverse();
        } else {
            response.showErrors();
        }
    }

    async createItem(data) {
        return await this.props.createVehicle(data);
    }

    async updateItem(data) {
        return await this.props.updateVehicle(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={this.decomposeItem(item)}
                errors={this.state.errors}
            />
        );
    }

    composeItem(data) {
        let item = _.clone(data);

        item.malfunctions = _.keys(_.pickBy(_.get(data, 'malfunctions') || [], item => !!item));
        item.component = this.props.params.component;

        return item;
    }

    decomposeItem(data) {
        let item = _.cloneDeep(data);

        let malfunctions = {};
        _.each(_.get(item, 'malfunctions', []), (malfunctionUuid) => {
            _.set(malfunctions, malfunctionUuid, true);
        });

        _.set(item, 'malfunctions', malfunctions);

        return item;
    }

    renderHeaderBtns(mode) {
        if ((mode === 'edit') && (_.indexOf([
            'kiutr',
            'children',
        ], this.props.params.component) !== -1)) {
            return [
                <ContextTooltip key="vehicles.repair" code="vehicles.repair"
                                default="Ремонты и учет деталей">
                    <ModalTopMenuListItem
                        className="b-icon-link_params b-icon-link_icon_repair"
                        href={`/${this.props.params.component}/vehicles/vehicles/${this.props.uuid}/repair`}
                    />
                </ContextTooltip>,
                <ContextTooltip key="vehicles.fuel" code="vehicles.fuel"
                                default="Топливо">
                    <ModalTopMenuListItem
                        className="b-icon-link_params b-icon-link_icon_fuel"
                        href={`/${this.props.params.component}/vehicles/vehicles/${this.props.uuid}/fuel`}
                    />
                </ContextTooltip>,
            ];
        }
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getUnits,
    getDictionaryList,
    getBnsoList,
    getKursMaintenances,
    getVehicleWorks
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {

    state = {
        vehicle: {},
        seasons: [],
        selectedSeasons: [],
        vehicle_capacity_types: [],
        vehicle_environmental_classes: [],
        vehicle_types: [],
        vehicle_marks: [],
        vehicle_models: [],
        gatn_responsives: [],
        kurs_malfunctions: [],
        bnso: [],
        unused_bnso: [],
        vehicle_capacities: [],
        organizational_units: {
            units: [],
        },
        vehicle_works: [],
        communal_municipalities: [],
        maintenance: {},
        vehicle_techreg_categories: [],
        vehicle_engine_type: [],
        vehicle_engine_kind: [],
    };

    getData() {
        return this.state.vehicle;
    }

    async componentDidMount() {
        await this.setState({
            vehicle: this.props.data,
        });

        let dictionaries = [
            'vehicle_capacity_types',
            'seasons',
            'vehicle_environmental_classes',
            'vehicle_types',
            'vehicle_marks',
            'vehicle_models',
            'vehicle_capacities',
            'kurs_malfunctions',
            'kurs_service_types',
            'kurs_service_units',
            'communal_municipalities',
        ]

        let dictionatiesTula = [
            'vehicle_techreg_categories',
            'vehicle_engine_types',
            'vehicle_engine_kind'
        ]

        if (window.RNIS_SETTINGS.CITY_TULA) {
            dictionaries = [...dictionaries, ...dictionatiesTula]
        }


        await this.loadDictionaries(dictionaries, this.props.params.component);
        if (this.props.params.component === 'control') {
            this.loadDictionaries([
                'gatn_responsives',
            ]);
        }
        if (this.props.params.component === 'children') {
            this.loadDictionaries([
                'transportation_types',
                'communal_municipalities',
            ]);
        }
        if (this.props.mode === 'edit') {
            this.loadMaintenance();
        }
        if (window.RNIS_SETTINGS.vehicle_editor_subsystem) {
            this.loadDictionaries([
                'vehicle_subsystems',
            ], this.props.params.component);
        }
        this.loadUnits();
        //this.loadBnso();
        //this.loadUnusedBnso();
        this.loadVehicleWorks();


        if (this.props.data.season.length) {
            this.initialSelectedSeason();
        }
    }

    initialSelectedSeason() {
        const seasons_uuid = this.props.data.season.map(season => season.season_uuid );
        if (seasons_uuid.length) {
            const prepareSeasonsSelected = this.state.seasons.filter(season => seasons_uuid.includes(season.value)).map(season => {
                delete season.document;
                season.isInTree = true;
                return season;
            });
            this.setState({
                selectedSeasons: prepareSeasonsSelected
            })
        }
    }

    async loadVehicleWorks() {
        const response = await this.props.getVehicleWorks({
            order: {
                column: 'date',
                direction: 'desc',
            },
            filters: {
                withVehicle: this.state.vehicle.uuid,
            },
        });
        if (response.isOk) {
            await this.setState({
                vehicle_works: response.payload.items,
            });
            this.loadMaintenance();
        } else {
            response.showErrors();
        }
    }

    async loadMaintenance() {
        const response = await this.props.getKursMaintenances({
            filters: {
                withModel: this.get('vehicle_model_uuid'),
                withMark: this.get('vehicle_mark_uuid'),
            },
        });
        if (response.isOk) {
            const maintenance = _.first(response.payload.items) || {};
            await this.setState({
                maintenance,
            });

            this.setValue('vehicle.service.next_service_date', moment(this.getNextServiceDate(), formats.DATE).format(formats.DATE_URL), false, false);
            this.setValue('vehicle.service.next_service_mileage', this.getNextServiceMileage(), false, false);
        } else {
            response.showErrors();
        }
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            filters: {
                withComponent: this.props.params.component,
            },
            response_data: [
                'items/uuid',
                'items/name',
                'items/contracts',
            ],
        });
        if (response.isOk) {
            const units = _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.name,
                contracts: item.contracts
            }));
            this.setState({units});
        } else {
            response.showErrors();
        }
    }

    async loadBnso() {
        const uuids = _.map(this.state.vehicle.bnso, 'bnso_uuid');
        const response = await this.props.getBnsoList({
            pagination: {
                page: 1,
                limit: 10000,
            },
            filters: {
                withUuid: uuids,
                //withComponent: this.props.params.component,
            },
            response_data: [
                'items/uuid',
                'items/bnso_code',
            ],
        });
        if (response.isOk) {
            const bnso = _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.bnso_code,
            }));
            this.setState({bnso});

        } else {
            response.showErrors();
        }
    }

    async loadUnusedBnso() {
        const response = await this.props.getBnsoList({
            pagination: {
                page: 1,
                limit: 25,
            },
            filters: {
                //withComponent: this.props.params.component,
                notInUse: true,
            },
            response_data: [
                'items/uuid',
                'items/bnso_code',
            ],
        });
        if (response.isOk) {
            const unused_bnso = _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.bnso_code,
            }));
            this.setState({unused_bnso});
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.vehicle, path, defaultValue);
    }

    async setValue(field, value) {
        super.setValue(field, value);

        if (field === 'vehicle.vehicle_mark_uuid') {
            this.setValue('vehicle.vehicle_model_uuid', null);
        }
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem title="Основные сведения" opened={true}>
                        {this.renderGeneral()}
                    </AccordionItem>
                    <AccordionItem title="БНСО">
                        {this.renderBnso()}
                    </AccordionItem>
                    <AccordionItem title="Неисправности">
                        {this.renderMalfunctions()}
                    </AccordionItem>
                    {(this.props.params.component === 'kiutr') ? (
                        <AccordionItem title="Регламент ТО">
                            {this.renderService()}
                        </AccordionItem>
                    ) : null}
                    {(this.props.params.component === 'kiutr' && window.RNIS_SETTINGS.CITY_MO) ? (
                        <AccordionItem title="Фотография ТС">
                            {this.renderPhotos()}
                        </AccordionItem>
                    ) : null}
                </Accordion>
                {this.state.showOperationAddForm ? this.renderOperationAddForm() : null}
            </div>
        );
    }

    getVehicleModels() {
        return _.filter(this.state.vehicle_models, (model) => {
            return !this.get('vehicle_mark_uuid') || (_.get(model, 'document.vehicle_mark_uuid') === this.get('vehicle_mark_uuid'));
        });
    }

    getContactNumbers() {
        let a = _.find(this.state.units, (unit) => {
            return _.get(unit, 'value') === this.get('unit_uuid');
        })
        if (!a) return
        return a.contracts.filter(con => {
            if (con.number) {
                return con;
            }
        }).map(contract => ({
            value: contract.number,
            label: contract.number,
        }))
    }

    onMultiSelectChange(fieldName, e) {
        this.onChangeInput(fieldName, {target: {value: e}});
        const season = this.state.selectedSeasons.map(item => {
            return {season_uuid: item.value, vehicle_uuid: this.state.vehicle.uuid }
        })
        this.setState(prevState => ({
            vehicle: {...prevState.vehicle, season }
        }))
    }

    renderGeneral() {
        const communal = location.pathname.includes('/communal');
        switch (this.props.params.component) {
            case 'control':
                return (
                    <div>
                        <Block size="xl" title="Государственный номер *">
                            {this.textInput('vehicle.state_number')}
                        </Block>
                        <Block title="Одобрено">
                            <State positive={this.get('is_approved')}/>
                        </Block>
                        <Block title="Марка ТС">
                            {this.select('vehicle.vehicle_mark_uuid', this.state.vehicle_marks)}
                        </Block>
                        <Block title="Модель ТС">
                            {this.select('vehicle.vehicle_model_uuid', this.getVehicleModels())}
                        </Block>
                        <Block title="Ответственное лицо">
                            {this.select('vehicle.gatn_responsive_uuid', this.state.gatn_responsives)}
                        </Block>
                        <Block title="Подразделение">
                            {this.select('vehicle.unit_uuid', this.state.units)}
                        </Block>
                        <Block title="Объем топливного бака">
                            {this.textInput('vehicle.fuel_tank_volume', {
                                type: 'number',
                                positive: true,
                            })}
                        </Block>
                        {(window.RNIS_SETTINGS.vehicle_editor_subsystem) ? (
                            <Block title="Подсистема ТС">
                                {this.select('vehicle.vehicle_subsystem_uuid', this.state.vehicle_subsystems)}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="№ Договора">
                                {this.select('vehicle.contract_unit_number', this.getContactNumbers())}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.columns_acc_and_control_fuel_consumption) ? (
                            <Block title="Норматив расхода топлива">
                                {this.textInput('vehicle.acc_and_control_fuel_consumption', {
                                    type: 'number',
                                    positive: true,
                                })}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.date_activation) ? (
                            <Block title="Дата подключения ТС">
                                {this.datepicker('vehicle.date_activation')}
                            </Block>
                        ) : null}

                        {(window.RNIS_SETTINGS.date_disactivation) ? (
                            <Block title="Дата отключения ТС">
                                {this.datepicker('vehicle.date_disactivation')}
                            </Block>
                        ) : null}

                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="Серия, номер свидетельства о регистрации">
                                {this.textInput('vehicle.registration_certificate')}
                            </Block>
                        ) : null}

                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="Дата свидетельства о регистрации">
                                {this.datepicker('vehicle.registration_certificate_date')}
                            </Block>
                        ) : null}

                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="VIN">
                                {this.textInput('vehicle.vin')}
                            </Block>
                        ) : null}

                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="Серия, номер ПТС">
                                {this.textInput('vehicle.pts')}
                            </Block>
                        ) : null}

                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="Дата ПТС">
                                {this.datepicker('vehicle.pts_date')}
                            </Block>
                        ) : null}

                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD || window.RNIS_SETTINGS.CITY_OMSK) ? (
                            <Block title="Тип ТС">
                                {this.select('vehicle.vehicle_type_uuid', this.state.vehicle_types)}
                            </Block>
                        ) : null}

                    </div>
                );
            case 'garbage':
                return (
                    <div>
                        <Block size="xl" title="Государственный номер *">
                            {this.textInput('vehicle.state_number')}
                        </Block>
                        <Block title="Одобрено">
                            <State positive={this.get('is_approved')}/>
                        </Block>
                        <Block title="Предприятие владелец *">
                            {this.select('vehicle.unit_uuid', this.state.units)}
                        </Block>
                        <Block title="Предприятие арендатор">
                            {this.select('vehicle.lessee_company_uuid', this.state.units)}
                        </Block>
                        <Block title="Год выпуска ТС">
                            {this.textInput('vehicle.release_year')}
                        </Block>
                        <Block title="VIN">
                            {this.textInput('vehicle.vin')}
                        </Block>
                        <Block title="Номер кузова">
                            {this.textInput('vehicle.chassis_number')}
                        </Block>
                        <Block title="Номер двигателя">
                            {this.textInput('vehicle.engine_number')}
                        </Block>
                        <Block title="Серия, номер ПТС">
                            {this.textInput('vehicle.pts')}
                        </Block>
                        <Block title="Дата ПТС">
                            {this.datepicker('vehicle.pts_date')}
                        </Block>
                        <Block title="Серия, номер свидетельства о регистрации">
                            {this.textInput('vehicle.registration_certificate')}
                        </Block>
                        <Block title="Дата свидетельства о регистрации">
                            {this.datepicker('vehicle.registration_certificate_date')}
                        </Block>
                        <Block title="Гаражный номер">
                            {this.textInput('vehicle.garage_number')}
                        </Block>
                        <Block title="Марка ТС">
                            {this.select('vehicle.vehicle_mark_uuid', this.state.vehicle_marks)}
                        </Block>
                        <Block title="Тип ТС">
                            {this.select('vehicle.vehicle_type_uuid', this.state.vehicle_types)}
                        </Block>
                        <Block title="Емкость ТС">
                            {this.select('vehicle.vehicle_capacity_uuid', this.state.vehicle_capacities)}
                        </Block>
                        <Block title="Объем топливного бака">
                            {this.textInput('vehicle.fuel_tank_volume', {
                                type: 'number',
                                positive: true,
                            })}
                        </Block>
                        {(window.RNIS_SETTINGS.vehicle_editor_subsystem) ? (
                            <Block title="Подсистема ТС">
                                {this.select('vehicle.vehicle_subsystem_uuid', this.state.vehicle_subsystems)}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="№ Договора">
                                {this.select('vehicle.contract_unit_number', this.getContactNumbers())}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.columns_acc_and_control_fuel_consumption) ? (
                            <Block title="Норматив расхода топлива">
                                {this.textInput('vehicle.acc_and_control_fuel_consumption', {
                                    type: 'number',
                                    positive: true,
                                })}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.date_activation) ? (
                            <Block title="Дата подключения ТС">
                                {this.datepicker('vehicle.date_activation')}
                            </Block>
                        ) : null}

                        {(window.RNIS_SETTINGS.date_disactivation) ? (
                            <Block title="Дата отключения ТС">
                                {this.datepicker('vehicle.date_disactivation')}
                            </Block>
                        ) : null}
                        <Block title="Параметры">
                            {this.checkbox('vehicle.is_malfunction', 'Неисправность ТС')}
                        </Block>
                    </div>
                );
            case 'children':
                return (
                    <div>
                        <Block size="xl" title="Государственный номер *">
                            {this.textInput('vehicle.state_number')}
                        </Block>
                        <Block title="Одобрено">
                            <State positive={this.get('is_approved')}/>
                        </Block>
                        <Block title="Класс транспортного средства">
                            {this.select('vehicle.capacity_type_uuid', this.state.vehicle_capacity_types)}
                        </Block>
                        <Block title="Экологический класс">
                            {this.select('vehicle.environmental_class_uuid', this.state.vehicle_environmental_classes)}
                        </Block>
                        <Block title="Тип ТС">
                            {this.select('vehicle.vehicle_type_uuid', this.state.vehicle_types)}
                        </Block>
                        <Block title="Муниципальное образование">
                            {this.select('vehicle.communal_municipality_uuid', this.state.communal_municipalities)}
                        </Block>
                        <Block title="Контакты в муниципальном образовании">
                            {this.textInput('vehicle.municipality_contact')}
                        </Block>
                        <Block title="Контакты предприятия-владельца">
                            {this.textInput('vehicle.owner_contact')}
                        </Block>
                        <Block title="Предприятие владелец *">
                            {this.select('vehicle.unit_uuid', this.state.units)}
                        </Block>
                        <Block title="Предприятие арендатор">
                            {this.select('vehicle.lessee_company_uuid', this.state.units)}
                        </Block>
                        <Block title="Вид перевозок">
                            {this.select('vehicle.transportation_type_uuid', this.state.transportation_types)}
                        </Block>
                        {(window.RNIS_SETTINGS.columns_acc_and_control_fuel_consumption) ? (
                            <Block title="Норматив расхода топлива">
                                {this.textInput('vehicle.acc_and_control_fuel_consumption', {
                                    type: 'number',
                                    positive: true,
                                })}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.date_activation) ? (
                            <Block title="Дата подключения ТС">
                                {this.datepicker('vehicle.date_activation')}
                            </Block>
                        ) : null}

                        {(window.RNIS_SETTINGS.date_disactivation) ? (
                            <Block title="Дата отключения ТС">
                                {this.datepicker('vehicle.date_disactivation')}
                            </Block>
                        ) : null}
                        <Block title="VIN">
                            {this.textInput('vehicle.vin')}
                        </Block>
                        <Block title="Номер кузова">
                            {this.textInput('vehicle.chassis_number')}
                        </Block>
                        <Block title="Номер двигателя">
                            {this.textInput('vehicle.engine_number')}
                        </Block>
                        <Block title="Серия, номер ПТС">
                            {this.textInput('vehicle.pts')}
                        </Block>
                        <Block title="Дата ПТС">
                            {this.datepicker('vehicle.pts_date')}
                        </Block>
                        <Block title="Дата выпуска ТС">
                            {this.datepicker('vehicle.release_date')}
                        </Block>
                        <Block title="Серия, номер свидетельства о регистрации">
                            {this.textInput('vehicle.registration_certificate')}
                        </Block>
                        <Block title="Дата свидетельства о регистрации">
                            {this.datepicker('vehicle.registration_certificate_date')}
                        </Block>
                        <Block title="Гаражный номер">
                            {this.textInput('vehicle.garage_number')}
                        </Block>
                        <Block title="Марка ТС">
                            {this.select('vehicle.vehicle_mark_uuid', this.state.vehicle_marks)}
                        </Block>
                        <Block title="Модель ТС">
                            {this.select('vehicle.vehicle_model_uuid', this.getVehicleModels())}
                        </Block>
                        <Block title="Объем топливного бака">
                            {this.textInput('vehicle.fuel_tank_volume', {
                                type: 'number',
                                positive: true,
                            })}
                        </Block>
                        {(window.RNIS_SETTINGS.vehicle_editor_subsystem) ? (
                            <Block title="Подсистема ТС">
                                {this.select('vehicle.vehicle_subsystem_uuid', this.state.vehicle_subsystems)}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="№ Договора">
                                {this.select('vehicle.contract_unit_number', this.getContactNumbers())}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.columns_acc_and_control_fuel_consumption) ? (
                            <Block title="Норматив расхода топлива">
                                {this.textInput('vehicle.acc_and_control_fuel_consumption', {
                                    type: 'number',
                                    positive: true,
                                })}
                            </Block>
                        ) : null}
                        <Block title="Параметры">
                            {this.checkbox('vehicle.is_malfunction', 'Неисправность ТС')}
                            {this.checkbox('vehicle.is_low_floor_level', 'Низкий уровень пола')}
                            {this.checkbox('vehicle.is_air_conditioning_installation', 'Климатическая установка')}
                            {this.checkbox('vehicle.is_electronic_scoreboard', 'Электронное табло')}
                            {this.checkbox('vehicle.is_cashless_payment', 'Безналичная оплата')}
                            {this.checkbox('vehicle.is_passenger_monitoring_system', 'Система мониторинга пассажиропотока')}
                            {this.checkbox('vehicle.is_audio_video_fixation', 'Аудио/Видео фиксация')}
                        </Block>
                        <Block size="xl" title="Дополнительное оборудование">
                            {this.textarea('vehicle.additional_equipment')}
                        </Block>
                    </div>
                );
            default:
                return (
                    <div>
                        <Block size="xl" title="Государственный номер *">
                            {this.textInput('vehicle.state_number')}
                        </Block>
                        <Block title="Одобрено">
                            <State positive={this.get('is_approved')}/>
                        </Block>
                        {window.RNIS_SETTINGS.CITY_MO && communal ? (
                            <Block title="Сезонность">
                                {this.select('selectedSeasons', _.map(this.state.seasons, (label, value) => ({
                                    value: label.value,
                                    label: label.label,
                                })), {
                                    multi: true,
                                    onChange: this.onMultiSelectChange.bind(this, `selectedSeasons`),
                                })}
                            </Block>
                        ) : <Block title="Класс транспортного средства">
                            {this.select('vehicle.capacity_type_uuid', this.state.vehicle_capacity_types)}
                        </Block>}
                        <Block title="Экологический класс">
                            {this.select('vehicle.environmental_class_uuid', this.state.vehicle_environmental_classes)}
                        </Block>
                        <Block title="Тип ТС">
                            {this.select('vehicle.vehicle_type_uuid', this.state.vehicle_types)}
                        </Block>
                        <Block title="Предприятие владелец *">
                            {this.select('vehicle.unit_uuid', this.state.units)}
                        </Block>
                        <Block title="Предприятие арендатор">
                            {this.select('vehicle.lessee_company_uuid', this.state.units)}
                        </Block>
                        <Block title="Год выпуска ТС">
                            {this.textInput('vehicle.release_year')}
                        </Block>
                        <Block title="VIN">
                            {this.textInput('vehicle.vin')}
                        </Block>
                        {(window.RNIS_SETTINGS.columns_acc_and_control_fuel_consumption) ? (
                            <Block title="Норматив расхода топлива">
                                {this.textInput('vehicle.acc_and_control_fuel_consumption', {
                                    type: 'number',
                                    positive: true,
                                })}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.date_activation) ? (
                            <Block title="Дата подключения ТС">
                                {this.datepicker('vehicle.date_activation')}
                            </Block>
                        ) : null}

                        {(window.RNIS_SETTINGS.date_disactivation) ? (
                            <Block title="Дата отключения ТС">
                                {this.datepicker('vehicle.date_disactivation')}
                            </Block>
                        ) : null}
                        <Block title="Номер кузова">
                            {this.textInput('vehicle.chassis_number')}
                        </Block>
                        <Block title="Номер двигателя">
                            {this.textInput('vehicle.engine_number')}
                        </Block>
                        <Block title="Серия, номер ПТС">
                            {this.textInput('vehicle.pts')}
                        </Block>
                        <Block title="Дата ПТС">
                            {this.datepicker('vehicle.pts_date')}
                        </Block>
                        <Block title="Серия, номер свидетельства о регистрации">
                            {this.textInput('vehicle.registration_certificate')}
                        </Block>
                        <Block title="Дата свидетельства о регистрации">
                            {this.datepicker('vehicle.registration_certificate_date')}
                        </Block>
                        <Block title="Гаражный номер">
                            {this.textInput('vehicle.garage_number')}
                        </Block>
                        <Block title="Марка ТС">
                            {this.select('vehicle.vehicle_mark_uuid', this.state.vehicle_marks)}
                        </Block>
                        <Block title="Модель ТС">
                            {this.select('vehicle.vehicle_model_uuid', this.getVehicleModels())}
                        </Block>
                        <Block title="Объем топливного бака">
                            {this.textInput('vehicle.fuel_tank_volume', {
                                type: 'number',
                                positive: true,
                            })}
                        </Block>
                        {(window.RNIS_SETTINGS.CITY_MO) ? (
                            <Block title="Вместимость">
                                {this.textInput('vehicle.capacity', {
                                    type: 'number',
                                    positive: true,
                                })}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.CITY_MO) ? (
                            <Block title="Количество сидячих мест">
                                {this.textInput('vehicle.seating_capacity', {
                                    type: 'number',
                                    positive: true,
                                })}
                            </Block>
                        ) : null}
                        {(window.RNIS_SETTINGS.vehicle_editor_subsystem) ? (
                            <Block title="Подсистема ТС">
                                {this.select('vehicle.vehicle_subsystem_uuid', this.state.vehicle_subsystems)}
                            </Block>
                        ) : null}

                        {(window.RNIS_SETTINGS.CITY_NNOVGOROD) ? (
                            <Block title="№ Договора">
                                {this.select('vehicle.contract_unit_number', this.getContactNumbers())}
                            </Block>
                        ) : null}

                        {
                            (window.RNIS_SETTINGS.CITY_TULA) ? (
                                <Block title="Категория ТС по техрегламенту">
                                    {this.select('vehicle.vehicle_techreg_category_uuid', this.state.vehicle_techreg_categories)}
                                </Block>) : null
                        }
                        {
                            (window.RNIS_SETTINGS.CITY_TULA) ? (
                                <Block title="Типы двигателей">
                                    {this.select('vehicle.vehicle_engine_type_uuid', this.state.vehicle_engine_type)}
                                </Block>) : null
                        }
                        {
                            (window.RNIS_SETTINGS.CITY_TULA) ? (
                                <Block title="Виды движителей">
                                    {this.select('vehicle.vehicle_engine_kind_uuid', this.state.vehicle_engine_kind)}
                                </Block>) : null
                        }

                        <Block title="Параметры">
                            {this.checkbox('vehicle.is_malfunction', 'Неисправность ТС')}
                            {window.RNIS_SETTINGS.CITY_MO && communal ? null : (this.checkbox('vehicle.is_low_floor_level', 'Низкий уровень пола'))}
                            {window.RNIS_SETTINGS.CITY_MO && communal ? null : (this.checkbox('vehicle.is_air_conditioning_installation', 'Климатическая установка'))}
                            {window.RNIS_SETTINGS.CITY_MO && communal ? null : (this.checkbox('vehicle.is_electronic_scoreboard', 'Электронное табло'))}
                            {window.RNIS_SETTINGS.CITY_MO && communal ? null : (this.checkbox('vehicle.is_cashless_payment', 'Безналичная оплата'))}
                            {window.RNIS_SETTINGS.CITY_MO && communal ? null : (this.checkbox('vehicle.is_passenger_monitoring_system', 'Система мониторинга пассажиропотока'))}
                            {this.checkbox('vehicle.is_scrapped', 'ТС списано')}
                            {window.RNIS_SETTINGS.CITY_MO && communal ? null : (this.checkbox('vehicle.is_for_disabled', 'Оснащено для перевозки инвалидов'))}
                            {window.RNIS_SETTINGS.CITY_MO && communal ? null : (this.checkbox('vehicle.is_audio_video_fixation', 'Комплекс видеонаблюдения'))}

                            {window.RNIS_SETTINGS.CITY_MO && communal ? null : (this.checkbox('vehicle.branded', 'Брендирование'))}
                            {window.RNIS_SETTINGS.CITY_MO && communal ? null : (this.checkbox('vehicle.audioinformer', 'Аудиоинформатор'))}
                            {window.RNIS_SETTINGS.CITY_MO && communal ? null : (this.checkbox('vehicle.seat_belts_for_passengers', 'Ремни безопасности пассажиров'))}

                            {window.RNIS_SETTINGS.CITY_MO && communal ? null : (this.checkbox('vehicle.monitor_for_information', 'Монитор для информирования'))}
                            {window.RNIS_SETTINGS.CITY_MO && communal ? null : (this.checkbox('vehicle.stationary_validator', 'Стационарный валидатор'))}
                            {window.RNIS_SETTINGS.CITY_MO ? (this.checkbox('vehicle.turnstile', 'Турникет')) : null}
                            {window.RNIS_SETTINGS.CITY_MO ? (this.checkbox('vehicle.luggage_compartment', 'Багажный отсек')) : null}
                            {window.RNIS_SETTINGS.CITY_MO ? (this.checkbox('vehicle.tachometer', 'Тахограф')) : null}

                            {window.RNIS_SETTINGS.CITY_TULA ? (this.checkbox('vehicle.heating_system', 'Система отопления')) : null}
                            {window.RNIS_SETTINGS.CITY_TULA ? (this.checkbox('vehicle.ventilation_system', 'Cистема вентиляции')) : null}
                            {window.RNIS_SETTINGS.CITY_TULA ? (this.checkbox('vehicle.passenger_door_opener_buttons', 'Кнопка открытия дверей пассажирами')) : null}
                            {window.RNIS_SETTINGS.CITY_TULA ? (this.checkbox('vehicle.wi_fi', 'Сеть Wi-Fi')) : null}
                        </Block>
                        {(this.state.vehicle.is_audio_video_fixation) ? (
                            <Block title="Количество камер видеонаблюдения ">
                                {this.textInput('vehicle.number_of_cctv_cameras', {
                                    type: 'number',
                                    positive: true,
                                })}
                            </Block>
                        ) : null}
                        <Block title="Файл ПТС">
                            {this.get('pts_file', '').length ? ([
                                <a key="open_pts_file" href={this.get('pts_file', '')} target="_blank">Открыть</a>,
                                <br/>,
                                <a key="delete_pts_file" href="#" onClick={this.deleteFile.bind(this)}>Удалить</a>,
                                <br/>,
                                <br/>,
                            ]) : null}
                            <FileReaderInput as="binary" id="my-file-input" onChange={::this.uploadFile}>
                                <a href="#">Загрузить файл</a>
                            </FileReaderInput>
                        </Block>
                    </div>
                );
        }
    }

    showAlert(url, e) {
        e && e.preventDefault();
        alerts.gallery(url);
    }


    async deletePhoto(e) {
        e && e.preventDefault();
        super.setValue('vehicle.photo', '');
    }

    hasImage() {
        return _.has(this.state.vehicle, 'photo') && this.state.vehicle.photo.length;
    }

    async uploadImage(e, results) {
        e.preventDefault();

        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        const allowedTypes = [
            'application/pdf',
            'image/png',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'image/jpeg',
        ];

        let hasError = false;

        results.forEach(result => {
            const [e, file] = result;
            formData.append('file', file);

            let errors = [];
            if (_.indexOf(allowedTypes, file.type) === -1) {
                errors.push('Недопустимый формат файла');
            }
            if ((file.size / (1024 * 1024)) >= 20) {
                errors.push('Недопустимый размер файла');
            }
            if (errors.length > 0) {
                alerts.error(errors.join('<br/>'));
                hasError = true;
            }
        });

        if (hasError) {
            return;
        }

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);

        let vehicle = this.state.vehicle;
        vehicle.photo = response.file
        this.setState({vehicle});
        // this.props.onUpdate();
    }

    openImage(url) {
        this.setState({
            openedImage: url,
        });
    }

    closeImage() {
        this.setState({
            openedImage: null,
        });
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }

    renderMalfunctions() {
        return (
            <div>
                <Block size="xl">
                    {this.state.kurs_malfunctions.map((malfunction) => {
                        return (
                            <Block key={malfunction.value} size="lg">
                                {this.checkbox(`vehicle.malfunctions.${malfunction.value}`, malfunction.label, {
                                    className: 'b-checkbox_right',
                                })}
                            </Block>
                        );
                    })}
                </Block>
            </div>
        );
    }


    deleteFile(e) {
        e && e.preventDefault();
        super.setValue('vehicle.pts_file', '');
    }

    async uploadFile(e, results) {
        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        results.forEach(result => {
            const [e, file] = result;
            formData.append('file', file);
        });

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');

        super.setValue('vehicle.pts_file', value);
    }

    renderBnso() {
        const list = this.state.vehicle.bnso || [];

        if (list.length === 0 && currentUser.can('com.rnis.vehicles.bnso_to_vehicle', 'create')) {
            return (
                <div className="add-link">
                    <a className="add-job b-icon-link b-icon-link_icon_plus" href="#" onClick={::this.addBnso}>Добавить
                        БНСО</a>
                </div>
            );
        }
        return list.map(::this.renderBnsoItem);
    }

    addBnso(e) {
        e.preventDefault();

        let vehicle = this.state.vehicle;
        vehicle.bnso = vehicle.bnso || [];
        vehicle.bnso.push({});

        this.setState({vehicle});
    }

    deleteBnso(index, e) {
        e.preventDefault();

        let vehicle = this.state.vehicle;
        vehicle.bnso.splice(index, 1);
        this.setState({vehicle});
    }

    async getBnso(input) {
        const result = await this.props.getBnsoList({
            pagination: {
                page: 1,
                limit: 3,
                search: input
            },
            filters: {
                //withComponent: this.props.params.component,
                notInUse: true,
            }
        });

        if (result.isOk) {
            return {
                options: _.sortBy(result.payload.items.map(i => ({label: i.bnso_code, value: i.uuid})), 'label'),
                complete: false
            };
        } else {
            result.showErrors();
        }

        return Promise.reject();
    }

    async loadCurrentBnso(uuid, callback) {
        if (!uuid) {
            callback(null, {
                options: [],
                complete: false,
            });
            return;
        }

        const result = await this.props.getBnsoList({
            pagination: {
                page: 1,
                limit: 1,
            },
            filters: {
                withUuid: uuid,
            },
            response_data: [
                'items/uuid',
                'items/bnso_code',
            ],
        });

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({label: i.bnso_code, value: i.uuid})), 'label'),
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    async loadBnsoLite(input, index, callback) {
        if (!input) {
            const uuid = _.get(this.state.vehicle.bnso[index], 'bnso_uuid');
            return this.loadCurrentBnso([uuid], callback);
        }

        let result = await this.props.getBnsoList({
            search: input,
            pagination: {
                page: 1,
                limit: 25,
            },
            filters: {
                notInUse: true,
            },
            response_data: [
                'items/uuid',
                'items/bnso_code',
            ],
        });

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({label: i.bnso_code, value: i.uuid})), 'label'),
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    renderBnsoItem(bnso, index) {
        return (
            <div key={index}>
                <Block title="Дата установки">
                    {this.datepicker(`vehicle.bnso.${index}.installation_date`)}
                </Block>
                <Block title="Время установки">
                    {this.maskInput(`vehicle.bnso.${index}.installation_time`, '99:99')}
                </Block>
                <Block title="Дата демонтажа">
                    {this.datepicker(`vehicle.bnso.${index}.dismantling_date`)}
                </Block>
                <Block title="Время демонтажа">
                    {this.maskInput(`vehicle.bnso.${index}.dismantling_time`, '99:99')}
                </Block>
                <Block title="БНСО">
                    {this.selectAsync(`vehicle.bnso.${index}.bnso_uuid`, async (input, callback) => {
                        return await this.loadBnsoLite(input, index, callback);
                    })}
                </Block>
                {(this.props.params.component === 'children') ? ([
                    <Block key="data_send_parameters" title="Параметры отправки данных">
                        {this.textInput(`vehicle.bnso.${index}.data_send_parameters`)}
                    </Block>,
                    <Block key="data_send_protocol" title="Протокол передачи данных">
                        {this.textInput(`vehicle.bnso.${index}.data_send_protocol`)}
                    </Block>,
                    <Block key="data_egts" title="Поддержка EGTS">
                        {this.textInput(`vehicle.bnso.${index}.data_egts`)}
                    </Block>,
                    <Block key="data_285" title="Соответствие 285 Приказу Минтранса от 31 июля 2012 г.">
                        {this.textInput(`vehicle.bnso.${index}.data_285`)}
                    </Block>,
                    <Block key="bnso_unit"
                           title="Наименование организации, обслуживающей навигационное оборудование, установленное на ТС">
                        {this.textInput(`vehicle.bnso.${index}.bnso_unit`)}
                    </Block>,
                ]) : null}
                <div className="add-link">
                    {currentUser.can('com.rnis.vehicles.bnso_to_vehicle', 'delete') ? (
                        <a className="remove-job b-icon-link b-icon-link_icon_basket" href="#"
                           onClick={this.deleteBnso.bind(this, index)}>Удалить БНСО</a>
                    ) : null}
                    {((index === this.state.vehicle.bnso.length - 1) && (currentUser.can('com.rnis.vehicles.bnso_to_vehicle', 'create'))) ? (
                        <span>
                            <span className="add-link__separator"/>
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="#"
                               onClick={::this.addBnso}>Добавить БНСО</a>
                        </span>
                    ) : null}

                </div>
                {(index !== this.state.vehicle.bnso.length - 1) ? <div className="hr"/> : null}
            </div>
        );
    }

    /***************************************************************
     * Фотографии ТС
     ***************************************************************/
    renderPhotos() {
        // const maintenances = _.sortBy(_.filter(this.state.vehicle_works, {type: 'maintenance'}), 'maintenance_name');
        const protocol = App.isSecure ? 'https://' : 'http://';
        const url = protocol + getHostName('ajax', false) + `/storage/${this.state.vehicle.photo}`;
        return (
            <div className="b-block">
                {(this.hasImage() ? (<a href="" onClick={this.showAlert.bind(this, url)} target="_blank">
                    <img src={url} className="small_preview"/></a>) : null)}
                <p>{(this.hasImage()) ? (
                    <a href="#" onClick={this.deletePhoto.bind(this)}>Удалить фото</a>
                ) : null}
                    <FileReaderInput as="binary" id="my-file-input" onChange={::this.uploadImage}>
                        <a href="#">{(this.hasImage()) ? 'Заменить фото' : 'Загрузить фото'}</a>
                    </FileReaderInput></p>
            </div>
        );
    }

    /***************************************************************
     * Регламент ТО
     ***************************************************************/
    renderService() {
        const maintenances = _.sortBy(_.filter(this.state.vehicle_works, {type: 'maintenance'}), 'maintenance_name');

        return (
            <div>
                <div className="tab__block-title">Обслуживание</div>
                <Block size="lg" className="b-block_right" title="Способ обслуживания">
                    {this.select('vehicle.service.service_type_uuid', this.state.kurs_service_types)}
                </Block>
                {this.isContractServiceType() ? ([
                    <Block key="service_unit_uuid" size="lg" className="b-block_right"
                           title="Обслуживающая организация">
                        {this.select('vehicle.service.service_unit_uuid', this.state.kurs_service_units)}
                    </Block>,
                    <Block key="contract_number" size="lg" className="b-block_right" title="Договор №, от">
                        {this.textInput('vehicle.service.contract_number')}
                    </Block>,
                ]) : null}
                <div className="tab__block-title">Наработка</div>
                {!window.RNIS_SETTINGS.CITY_NNOVGOROD ? (
                    <Block size="lg" className="b-block_right" title="Моточасы (путевой лист)">
                        {this.textInput('vehicle.service.machine_hours', {
                            type: 'number',
                            min: 0,
                            positive: true,
                        })}
                    </Block>
                ) : null}

                <Block size="lg" className="" title="">
                    {this.checkbox('vehicle.service.is_new_vehicle', 'Новое ТС')}
                </Block>
                {(this.get('service.is_new_vehicle')) ? (
                    <Block size="lg" className="b-block_right" title="Дата приобретения">
                        {this.datepicker('vehicle.service.purchase_date')}
                    </Block>
                ) : null}
                {(!this.get('service.is_new_vehicle')) ? (
                    (this.get('service.is_purchase_date_not_editable')) ? (
                        <Block size="lg" className="b-block_right" title="Дата последнего ТО">
                            {moment(this.get('service.last_service_date')).format(formats.DATE)}
                        </Block>
                    ) : (
                        <Block size="lg" className="b-block_right" title="Дата последнего ТО">
                            {this.datepicker('vehicle.service.last_service_date')}
                        </Block>
                    )
                ) : null}
                {(!this.get('service.is_new_vehicle')) ? (
                    <Block size="lg" className="b-block_right" title="Пробег/моточасы посл. ТО">
                        {this.textInput('vehicle.service.last_service_mileage', {
                            type: 'number',
                            min: 0,
                            positive: true,
                        })}
                    </Block>
                ) : null}
                {(!this.get('service.is_new_vehicle')) ? (
                    <Block size="lg" className="b-block_right" title="Номер последнего ТО">
                        {this.select('vehicle.service.last_service_number', _.map(this.state.maintenance.data, (item) => ({
                            value: item.number,
                            label: `ТО ${item.number}`,
                        })))}
                    </Block>
                ) : null}
                {!window.RNIS_SETTINGS.CITY_NNOVGOROD ? (
                    <Block size="lg" className="b-block_right" title="Время простоя, ч">
                        {this.textInput('vehicle.idle_time', {
                            disabled: true,
                        })}
                    </Block>
                ) : null}

                <div className="tab__block-title">Сроки следующего планового ТО</div>
                <Block size="lg" className="b-block_right" title="Дата ТО">
                    <div className="b-block__text_align-center">
                        {this.getNextServiceDate()}
                    </div>
                </Block>
                <Block size="lg" className="b-block_right" title="Пробег, моточасы">
                    <div className="b-block__text_align-center">
                        {this.getNextServiceMileage()}
                    </div>
                </Block>
                <div className="tab__block-title">
                    Список ТО
                    <Button size="md" text="Указать факт ТО" color="white" shadow="gray" width="auto"
                            className="b-button-show-fact"
                            onClick={::this.requestOperationAdd}/>
                </div>
                <Block size="xl">
                    <div className="Table">
                        <TableContainer>
                            <table className="b-table">
                                <thead>
                                <tr>
                                    <th>№ ТО</th>
                                    <th>План. срок</th>
                                    {(this.state.maintenance.type === 'mileage') ? (
                                        <th>План. пробег</th>
                                    ) : null}
                                    {(this.state.maintenance.type === 'machine_hours') ? (
                                        <th>План. м/ч</th>
                                    ) : null}
                                    <th>Факт. срок</th>
                                    {(this.state.maintenance.type === 'mileage') ? (
                                        <th>Факт. пробег</th>
                                    ) : null}
                                    {(this.state.maintenance.type === 'machine_hours') ? (
                                        <th>Факт. м/ч</th>
                                    ) : null}
                                </tr>
                                </thead>
                                <tbody>
                                {(this.state.maintenance.data || []).map(::this.renderMaintenanceRow)}
                                {maintenances.map(::this.renderMaintenanceFactRow)}
                                {this.renderMaintenanceFactRow({
                                    maintenance_name: _.toInteger(_.get(_.last(maintenances), 'maintenance_name', 0)) + 1,
                                }, -1)}
                                </tbody>
                            </table>
                        </TableContainer>
                    </div>
                </Block>
            </div>
        );
    }

    renderMaintenanceRow(row, index) {
        const months = row.months;
        const years = Math.floor(months / 12);
        const planned = (months < 12) ? `${months} мес` : `${years} ${pluralize(years, 'лет', 'год', 'года', 'лет')}`;
        const fact = _.find(this.state.vehicle_works, {type: 'maintenance', maintenance_name: row.number.toString()});

        return (
            <tr key={index}>
                <td className="link">ТО {row.number}</td>
                <td>{planned}</td>
                {(this.state.maintenance.type === 'mileage') ? (
                    <td>{row.mileage_value}</td>
                ) : null}
                {(this.state.maintenance.type === 'machine_hours') ? (
                    <td>{row.machine_hours_value}</td>
                ) : null}
                <td>{fact ? moment(fact.date).format(formats.DATE) : null}</td>
                {(this.state.maintenance.type === 'mileage') ? (
                    <td>-</td>
                ) : null}
                {(this.state.maintenance.type === 'machine_hours') ? (
                    <td>-</td>
                ) : null}
            </tr>
        );
    }

    renderMaintenanceFactRow(maintenance, index) {
        const maintenanceNumber = _.toInteger(maintenance.maintenance_name);
        const row = _.find(this.state.maintenance.data || [], {number: maintenanceNumber});
        if (row) {
            return null;
        }
        const repeated = _.find(this.state.maintenance.data || [], {is_repeated: true});
        if (!repeated) {
            return null;
        }
        if (maintenanceNumber <= repeated.number) {
            return null;
        }
        const months = repeated.months * (maintenanceNumber - repeated.number);

        const years = Math.floor(months / 12);
        const planned = (months < 12) ? `${months} мес` : `${years} ${pluralize(years, 'лет', 'год', 'года', 'лет')}`;

        return (
            <tr key={index}>
                <td className="link">ТО {maintenanceNumber}</td>
                <td>{planned}</td>
                {(this.state.maintenance.type === 'mileage') ? (
                    <td>{repeated.mileage_value * (maintenanceNumber - repeated.number)}</td>
                ) : null}
                {(this.state.maintenance.type === 'machine_hours') ? (
                    <td>{repeated.machine_hours_value * (maintenanceNumber - repeated.number)}</td>
                ) : null}
                <td>{maintenance.date ? moment(maintenance.date).format(formats.DATE) : null}</td>
                {(this.state.maintenance.type === 'mileage') ? (
                    <td>-</td>
                ) : null}
                {(this.state.maintenance.type === 'machine_hours') ? (
                    <td>-</td>
                ) : null}
            </tr>
        );
    }

    isContractServiceType() {
        return _.get(_.find(this.state.kurs_service_types, {value: this.get('service.service_type_uuid')}), 'label') !== 'Хозяйственный';
    }

    getNextServiceDate() {
        const lastMaintenance = _.last(_.sortBy(_.filter(this.state.vehicle_works, {type: 'maintenance'}), 'maintenance_name'));
        if (!lastMaintenance) {
            const lastServiceDate = this.get('service.is_new_vehicle') ? this.get('service.purchase_date') : this.get('service.last_service_date');
            const lastServiceNumber = this.get('service.is_new_vehicle') ? -1 : this.get('service.last_service_number');

            if (lastServiceDate) {
                const maintenance = this.getMaintenanceByNumber(1 + _.toInteger(lastServiceNumber));
                if (maintenance) {
                    return moment(lastServiceDate).add(maintenance.months, 'months').format(formats.DATE);
                }
            }
        } else {
            const maintenance = this.getMaintenanceByNumber(1 + _.toInteger(lastMaintenance.maintenance_name));
            if (lastMaintenance.date && maintenance) {
                return moment(lastMaintenance.date).add(maintenance.months, 'months').format(formats.DATE);
            }
        }
    }

    getNextServiceMileage() {
        const lastServiceMileage = this.get('service.is_new_vehicle') ? 0 : _.toInteger(this.get('service.last_service_mileage'));
        const lastServiceNumber = this.get('service.is_new_vehicle') ? -1 : this.get('service.last_service_number');

        const maintenance = this.getMaintenanceByNumber(1 + _.toInteger(lastServiceNumber));
        if (maintenance) {
            return lastServiceMileage + _.toInteger(maintenance[(this.state.maintenance.type === 'mileage') ? 'mileage_value' : 'machine_hours_value']);
        }
    }

    getMaintenanceByNumber(maintenanceNumber) {
        const row = _.find(this.state.maintenance.data || [], {number: maintenanceNumber});
        if (row) {
            return row;
        }
        const repeated = _.find(this.state.maintenance.data || [], {is_repeated: true});
        if (!repeated) {
            return null;
        }
        if (maintenanceNumber <= repeated.number) {
            return null;
        }
        return repeated;
    }

    requestOperationAdd(callback, type = 'details') {
        this.operationAddSubmitCallback = callback;
        this.setState({
            showOperationAddForm: true,
            operationAddFormType: type,
        });
    }

    closeOperationAdd(withSubmit = false) {
        if (withSubmit) {
            this.loadVehicleWorks();
        }
        if (withSubmit && this.operationAddSubmitCallback) {
            try {
                this.operationAddSubmitCallback();
            } catch (e) {
            }
        }
        this.operationAddSubmitCallback = null;
        this.setState({
            showOperationAddForm: false,
        });
    }

    renderOperationAddForm() {
        let maintenanceNames = [];
        (this.state.maintenance.data || []).map(function (item) {
            maintenanceNames.push(item.number);
        });
        if (!_.isEmpty(this.state.maintenance)) {
            const maintenances = _.sortBy(_.filter(this.state.vehicle_works, {type: 'maintenance'}), 'maintenance_name');
            _.each(maintenances, (maintenance) => {
                maintenanceNames.push(_.toInteger(maintenance.maintenance_name));
            });
            maintenanceNames.push(_.toInteger(_.get(_.last(maintenances), 'maintenance_name')) + 1);
        }
        maintenanceNames = _.uniq(maintenanceNames);

        maintenanceNames = _.difference(_.map(maintenanceNames, name => name.toString()), _.map(_.filter(this.state.vehicle_works, {type: 'maintenance'}), 'maintenance_name'));

        return (
            <KursOperationAddForm
                component={this.props.params.component}
                mode="add"
                uuid="create"
                type={this.state.operationAddFormType}
                onSubmit={this.closeOperationAdd.bind(this, true)}
                onClose={this.closeOperationAdd.bind(this, false)}
                vehicleUuid={this.state.vehicle.uuid}
                maintenanceNames={maintenanceNames}
            />
        );
    }
}
