import _ from 'lodash';

const VALIDATION_ERROR_CODE = '1-0-0-3';

export const getErrors = (e) => {
    let errors = [];
    if (e.errors) {
        e.errors.map((error) => {
            if (error.code === VALIDATION_ERROR_CODE) {
                _.forEach(error.data, (messages, field) => {
                    errors[field] = messages.join(', ');
                });
            }
        });
    }
    return errors;
};