import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defaultProps, propTypes} from 'react-props-decorators';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import _ from 'lodash';

const defaultColor = '#00ff00';

@propTypes({
    data: PropTypes.array,
    xDataKey: PropTypes.string,
    yDataKey: PropTypes.string,
    xName: PropTypes.string,
    configKey: PropTypes.string,
    color: PropTypes.string,
    layout: PropTypes.oneOf(['vertical', 'horizontal']),
})

@defaultProps({
    data: [],
    xDataKey: 'name',
    yDataKey: 'value',
    xName: '',
    configKey: '',
    color: defaultColor,
    layout: 'horizontal',
})

export default class BarChartComponent extends Component {

    renderBar = () => {
        /*const data = [];
        const keys = _.uniq(_.flatten(_.map(this.props.data, this.props.fieldsKey)));
        const arrNames = _.filter(_.flatten(_.map(this.props.data, this.props.fieldNamesKey)));

        const fieldNames = {};

        _.forEach(arrNames, (item) => {
            const keys = Object.keys(item);

            if (keys.length) {
                fieldNames[keys[0]] = item[keys[0]];
            }
        });

        keys.forEach((key, index) => {
            data.push(<Bar key={index} dataKey={key} fill={this.props.fill || defaultBarFillColor}
                           name={fieldNames[key]}
                           barSize={50}
                        />);
        });*/

        const data = [];
        let fields = {};

        const fieldItems = _.filter(_.flatten(_.map(this.props.data, this.props.configKey)));

        fieldItems.forEach((item) => {
            const keys = Object.keys(item);

            keys.forEach((key) => {
                fields[key] = item[key];
            });
            if (keys.length) {
                fields[keys[0]] = item[keys[0]];
            }
        });

        Object.keys(fields).forEach((key, index) => {
            data.push(<Bar key={index} dataKey={key} fill={fields[key].color || this.props.color || defaultColor}
                           name={fields[key].name}
                           barSize={50}
            />);
        });

        return data;
    };

    render() {
        return (
            <ResponsiveContainer>
                <BarChart data={this.props.data} layout={this.props.layout}>
                    {(this.props.layout === 'vertical') ? ([
                        <XAxis key="x" offset={-20} dy={-20} type="number" dataKey={this.props.yDataKey} label={this.props.yLabel} /*label="Баллы"*/ />,
                        <YAxis key="y" type="category" dataKey={this.props.xDataKey} /*label="Баллы"*//>,
                    ]) : ([
                        <XAxis key="x" dataKey={this.props.xDataKey} /*label="Баллы"*/ />,
                        <YAxis key="y" dataKey={this.props.yDataKey} label={this.props.yLabel} /*label="Баллы"*//>,
                    ])}
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip/>
                    {/*<Legend />*/}
                    {this.props.configKey ?
                        this.renderBar()
                        :
                        <Bar dataKey={this.props.yDataKey} fill={this.props.color || defaultColor}
                             name={this.props.xName} barSize={50} isAnimationActive={false}/>
                    }
                </BarChart>
            </ResponsiveContainer>

        );
    }
}