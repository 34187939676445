import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import _ from 'lodash';

import BaseTableComponent from 'components/base/base_table';
import Page from 'components/ui/page';
import IconButton from 'components/ui/icon-button';
import Editor from './editor';

import {mapDatatablesRequestToMeta} from 'helpers/api';

import * as alerts from "helpers/alerts";
import TableContainer from "components/ui/Table/Container/TableContainer";
import Column from "components/ui/column";
import AppearTransition from "components/ui/transitions/appear";
import {EntityList} from "helpers/entity";
import currentUser from 'helpers/current-user';
import {getEntityNames} from "store/reducers/system";
import {deleteCarrier, getCarriers} from "store/reducers/organizational_units/carriers";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import {getRegisterRequests} from "store/reducers/portal/register_requests";
import formats from "dictionaries/formats";
import moment from "moment";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import './index.less';
import ReactDOMServer from 'react-dom/server';
import {State} from "components/ui/state";
import {getUnits} from "store/reducers/organizational_units/units";
import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";

@connect(state => ({}), {getRegisterRequests, getEntityNames, getDictionaryList, getUnits, getUsers})

export default class RegisterRequestsComponent extends BaseTableWithEditorComponent {

    title = `${systems.operator} → Комплекты регистрационных документов`;
    baseUrl = '/operator/register_requests';

    statuses = {
        sent_first: 'Отправлено впервые',
        returned: 'Возвращено',
        sent: 'Отправлено повторно',
        confirmed: 'Принято',
    };

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            request_documents: [],
        });
    }

    componentWillMount() {
        this.loadDictionaries([
            'request_documents',
        ]);
    }

    getEditor() {
        return (
            <Editor
                {...this.props}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Номер')
                .fromField('number'),

            new Column('Поступление')
                .fromField('created_at')
                .withDateFilter()
                .withDrawer(item => moment(item.created_at).format(formats.DATETIME)),

            new Column('Обновление')
                .fromField('updated_at')
                .withDateFilter()
                .withDrawer(item => moment(item.updated_at).format(formats.DATETIME)),

            new Column('Статус')
                .fromField('status')
                .denyColumnFilter()
                .withDrawer(item => this.statuses[item.status])
                .withFilter('withStatuses', async () => {
                    return _.map(this.statuses, (name, uuid) => ({
                        uuid,
                        name,
                    }));
                }),

            new Column('Обрабатывают')
                .fromField('operators')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => _.map(_.uniq(_.filter(_.map(item.history, 'user_uuid'))), (userUuid) => {
                    return this.state.related.get(userUuid);
                }).join(', '))
                .withAsyncFilter('withOperators', async (search) => {
                    const response = await this.props.getUsers({
                        search,
                        pagination: {
                            page: 1,
                            limit: 30,
                        },
                    });
                    if (response.isOk) {
                        return _.map(response.payload.items, (user) => ({
                            uuid: user.uuid,
                            name: new User(user).getFullName(),
                        }));
                    }
                    return [];
                }),

            new Column('Предприятие')
                .fromField('certificate.name')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer((item) => {
                    if (item.unit_uuid) {
                        return this.state.related.get(item.unit_uuid);
                    }
                    return _.get(item, 'certificate.name');
                })
                .withAsyncFilter('withUnits', async (search) => {
                    const response = await this.props.getUnits({
                        search,
                        order: {
                            column: 'name',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 30,
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return [];
                }),

            new Column('Комплектность')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => this.getComplectStatus(item)),

            new Column('Актуализация')
                .fromField('actualization')
                .denyColumnFilter()
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.login === '~register-from-system~'}/>))
                .withFilter('withActualization', async () => {
                    return [
                        {
                            name: 'Активна',
                            uuid: 1,
                        },
                        {
                            name: 'Неактивна',
                            uuid: 0,
                        },
                    ];
                }),

        ]);
    }

    getComplectStatus(item) {
        const documentTypes = _.map(_.filter(this.state.request_documents, (document) => {
            return _.indexOf(item.components, document.component) !== -1;
        }), 'uuid');

        let full = true;
        _.each(documentTypes, (uuid) => {
            if (!_.find(item.documents, {
                    request_document_uuid: uuid,
                })) {
                full = false;
            }
        });

        return `${full ? 'Полная' : 'Частичная'} (${(item.documents || []).length})`;
    }

    async loadData(meta) {
        _.set(meta, 'filters.withoutDraft', 1);
        return await this.props.getRegisterRequests(meta);
    }

    getCreateButton() {
        return null;
    }

    renderHeaderActions() {
        return null;
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const userUuids = _.uniq(_.filter(_.map(_.flatten(_.map(result, 'history')), 'user_uuid')));
        const users = _.map(userUuids, (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));

        const unitUuids = _.uniq(_.filter(_.map(result, 'unit_uuid')));
        const units = _.map(unitUuids, (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(_.concat(users, units));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}