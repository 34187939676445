import { StopPointsTableCell } from './stop-points-table-cell';
import React from 'react';
import moment from 'moment';
export const sumArrayByKey = (array, key) =>
	array.reduce((prev, cur) => {
		prev = prev + Number(cur[key]);
		return prev;
	}, 0);

export const getSmallTableIntervals = (intervals, runs) =>
	intervals.sort((a, b) => Number(a.start_at.split(':')[0]) - Number(b.start_at.split(':')[0])).reduce((prev, cur) => {
		prev['Время суток (ч)'].push(`${cur.start_at.slice(0, 5)} - ${cur.end_at.slice(0, 5)}`);
		prev['Интервал (мин)'].push(cur.interval);
		prev['Минимальное необходимое кол-во ТС на линии'].push(
			Math.ceil((runs[runs.length - 1][3] + runs[runs.length - 1][7]) / cur.interval)
		);
		return prev;
	}, {
		'Время суток (ч)': [],
		'Интервал (мин)': [],
		'Минимальное необходимое кол-во ТС на линии': []
	});

export const getIntervalTableData = (
	lastTimeForward,
	firstTimeForward,
	lastTimeBackward,
	firstTimeBackward,
	forwardPoints,
	reversePoints,
	stopPoints
) => {
	const runs = [];
	forwardPoints.forEach((point, i) => {
		lastTimeForward = moment(lastTimeForward, 'HH:mm')
			.add(i > 0 ? forwardPoints[i - 1].time_to_get_to_the_next_point : 0, 'minutes')
			.format('HH:mm');
		firstTimeForward = moment(firstTimeForward, 'HH:mm')
			.add(i > 0 ? forwardPoints[i - 1].time_to_get_to_the_next_point : 0, 'minutes')
			.format('HH:mm');
		lastTimeBackward = moment(lastTimeBackward, 'HH:mm')
			.add(i > 0 && reversePoints[i] ? reversePoints[i - 1].time_to_get_to_the_next_point : 0, 'minutes')
			.format('HH:mm');
		firstTimeBackward = moment(firstTimeBackward, 'HH:mm')
			.add(i > 0 && reversePoints[i] ? reversePoints[i - 1].time_to_get_to_the_next_point : 0, 'minutes')
			.format('HH:mm');
		runs.push([
			lastTimeForward,
			firstTimeForward,
			(point.distance_to_the_next_point / 1000).toFixed(2),
			point.time_to_get_to_the_next_point,
			stopPoints[point.type_uuid] && stopPoints[point.type_uuid].title,
			point && i + 1,
			reversePoints[i] && i + 1,
			reversePoints[i] && stopPoints[reversePoints[i].type_uuid] && stopPoints[reversePoints[i].type_uuid].title,
			reversePoints[i] && reversePoints[i].time_to_get_to_the_next_point,
			reversePoints[i] && (reversePoints[i].distance_to_the_next_point / 1000).toFixed(2),
			reversePoints[i] && firstTimeBackward,
			reversePoints[i] && lastTimeBackward
		]);
	});
	runs.push([
		'',
		'',
		(sumArrayByKey(forwardPoints, 'distance_to_the_next_point') / 1000).toFixed(2),
		sumArrayByKey(forwardPoints, 'time_to_get_to_the_next_point'),
		'',
		'Итого',
		'',
		sumArrayByKey(reversePoints, 'time_to_get_to_the_next_point'),
		(sumArrayByKey(reversePoints, 'distance_to_the_next_point') / 1000).toFixed(2),
		'',
		''
	]);
	return runs;
};

export const getMonthsTitle = (schedule) => {
	const data = [
		schedule.is_january,
		schedule.is_february,
		schedule.is_march,
		schedule.is_april,
		schedule.is_may,
		schedule.is_june,
		schedule.is_july,
		schedule.is_august,
		schedule.is_september,
		schedule.is_october,
		schedule.is_november,
		schedule.is_december
	];
	const months = [
		'Январь',
		'Февраль',
		'Март',
		'Апрель',
		'Май',
		'Июнь',
		'Июль',
		'Август',
		'Сентябрь',
		'Октябрь',
		'Ноябрь',
		'Декабрь'
	];
	if (data.every((el) => el)) {
		return 'Всесезонный';
	}
	if (data.every((el) => !el)) {
		return 'Не указано';
	}
	return data.reduce((prev, cur, i, array) => {
		let month = '';
		if (cur) {
			month = `${months[i]}${i === 11 ? '' : ', '}`;
		}
		prev = `${prev}${month}`;
		return prev;
	}, '');
};
