import {Map} from 'immutable';
import _ from 'lodash';
import * as storage from "utils/storage";

const OPEN_SUBMENU = 'OPEN_SUBMENU';
const CLOSE_SUBMENU = 'CLOSE_SUBMENU';

const storageKey = 'menu';
const saveState = (submenu) => {
    storage.set(storageKey, submenu);
};

const loadState = () => {
    return storage.get(storageKey, []);
};

const initialState = new Map({
    submenu: loadState() || [],
});

export default function reducer(state = initialState, action = {}) {
    let submenu;
    switch (action.type) {
        case OPEN_SUBMENU:
            submenu = _.clone(state.get('submenu')) || [];
            submenu.push(action.payload);
            saveState(submenu);
            return state.set('submenu', submenu);
        case CLOSE_SUBMENU:
            submenu = _.clone(state.get('submenu')) || [];
            submenu.splice(-1, 1);
            saveState(submenu);
            return state.set('submenu', submenu);
    }
    return state;
}

export const openSubmenu = (submenu) => async (dispatch) => {
    dispatch({type: OPEN_SUBMENU, payload: submenu});
};

export const closeSubmenu = (submenu) => async (dispatch) => {
    dispatch({type: CLOSE_SUBMENU});
};