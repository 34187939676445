import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import AbstractMapMarker from 'components/ui/map/base/marker';
import StopPointIcon from 'components/ui/map/markers/icons/stop-point-icon';
import _ from 'lodash';
import ReactPopup from 'react-leaflet-popup';
import L from 'leaflet';
import { getHostName } from '../../../../helpers/wsrpc';
import * as alerts from '../../../../helpers/alerts';

@propTypes({
	item: PropTypes.object,
	color: PropTypes.oneOf([null, 'red', 'green']),
	permanentTooltip: PropTypes.bool,
	withoutPopup: PropTypes.bool,
	static: PropTypes.bool,
	onEditClick: PropTypes.func,
	size: PropTypes.number
})
@defaultProps({
	color: null,
	permanentTooltip: false,
	withoutPopup: false,
	static: false,
	onEditClick: null,
	size: 1,
})
export default class StopPointMarker extends AbstractMapMarker {
	state = {
		openPopup: false,
	}
	getOptions() {
		return {
			icon: StopPointIcon.getIcon(this.props.color, this.props.size),
			riseOnHover: true,
			closeButton: false,
		};
	}

	componentWillMount() {
		super.componentWillMount();
		if (this.props.onClick === null && !this.props.withoutPopup) {
			this.getMarker().on('click', :: this.loadPopup);
		}
		// this.getMarker().on('mouseover', this.openPopupOnHover);
		// this.getMarker().on('mouseout', this.openPopupOnOut);
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		// this.getMarker().off('mouseover', this.openPopupOnHover);
		// this.getMarker().off('mouseout', this.openPopupOnOut);
		this.getMarker().off('click', :: this.loadPopup);
	}

	componentDidMount() {
		super.componentDidMount();
		this.forceUpdate();
	}

	openPopupOnHover = (e) => {
		this.getMarker()
			.bindPopup(`<div style="padding: 10px;padding-right: 15px;">${this.props.item.stop_point_title || this.props.item.title}</div>`, { closeButton: false })
			.openPopup();

	};
	openPopupOnOut = (e) => {
		this.getMarker()
			.closePopup();
	};

	componentWillUpdate(props) {
		// if (this.props.static) {
		// 	return;
		// }

		const oldItem = this.props.item;
		const newItem = props.item;

		this.checkTitleUpdate(oldItem, newItem, props);
	}

	checkTitleUpdate(oldItem, newItem, props) {
		if (!oldItem || !newItem) return
		const marker = this.getMarker();
		const title = newItem.stop_point_title || oldItem.title;
		marker.unbindTooltip().bindTooltip(title, {
			sticky: true,
			// permanent: newPermanentToolTip,
			direction: 'top'
		});
	}

	loadPopup() {
		if (!this.props.item) {
			return;
		}
		const marker = this.getMarker();
		if (this.state.openPopup) {
			marker.closePopup();
			marker.unbindPopup();
			this.setState({
				openPopup: false
			})
			return;
		}
		marker.unbindPopup();
		marker.closePopup();
		marker.bindPopup(this.createStopPointPopup(:: marker.closePopup));
		marker.openPopup();
		this.setState({
			openPopup: true
		})
	}

	createStopPointPopup(onClose) {
		const stopPoint = this.props.item;
		if (!stopPoint) {
			return null;
		}

		return new ReactPopup({
			reactComponent: StopPointPopup,
			reactComponentProps: {
				...this.props,
				stopPoint,
				onClose
			},
			minWidth: 434,
			maxWidth: 434,
			offset: new L.Point(3, -250)
		});
	}
}

class StopPointPopup extends Component {
	render() {
		const { stopPoint, onEditClick, onRoutesClick, onForwardAddClick, onReverseAddClick } = this.props;
		const protocol = App.isSecure ? 'https://' : 'http://';
		const url = protocol + getHostName('ajax', false) + `/storage/${stopPoint.image}`;

		const title = stopPoint.stop_point_title ? stopPoint.stop_point_title : stopPoint.title;

		return (
			<div className="b-modal b-modal-map map-tooltip-modal tooltip-stop">
				<div className="b-modal__header">
					<a href="#" className="b-modal__close" onClick={::this.onClose} />
					<div className="b-modal__name align-middle">{title}</div>
				</div>
				<div className="b-modal__body">
					<div className="b-block">
						<div className="b-block__desc">Координаты</div>
						<div className="b-block__value">
							{_.round(stopPoint.latitude, 6)}, {_.round(stopPoint.longitude, 6)}
						</div>
					</div>
					<div className="b-block">
						<div className="b-block__desc">Рег. номер</div>
						<div className="b-block__value">{stopPoint.register_number}</div>
					</div>
					{_.has(stopPoint, 'image') && stopPoint.image.length ? (
						<div className="b-block">
							<div className="b-block__desc">Фото</div>
							<div className="b-block__value">
								{' '}
								<a href="" onClick={this.showAlert.bind(this, url)} target="_blank">
									<img src={url} className="micro_preview" />
								</a>
							</div>
						</div>
					) : null}
				</div>
				<div className="b-modal__footer">
					{onEditClick ? (
						<a
							href="javascript:void(0)"
							className="b-button b-button_size_md b-button_red b-button_card"
							onClick={onEditClick}
						>
							Посмотреть
						</a>
					) : null}
					{onRoutesClick ? (
						<a
							href="javascript:void(0)"
							className="b-button b-button_size_md b-button_red b-button_routes"
							onClick={onRoutesClick}
						>
							Список маршрутов
						</a>
					) : null}
					{onForwardAddClick ? (
						<a
							href="javascript:void(0)"
							className="b-button b-button_size_md b-button_red b-button_card b-button_forward"
							onClick={::this.onForwardAddClick}
						>
					Прямое
						</a>
					) : null}
				{onReverseAddClick ? (
					<a
						href="javascript:void(0)"
						className="b-button b-button_size_md b-button_red b-button_card b-button_reverse"
						onClick={::this.onReverseAddClick}
						>
				Обратное
						</a>
		) : null
	}
				</div>
			</div >
		);
	}

onForwardAddClick() {
	const { stopPoint, onForwardAddClick } = this.props;

	onForwardAddClick(stopPoint.uuid, stopPoint.latitude, stopPoint.longitude);
	this.onClose();
}

onReverseAddClick() {
	const { stopPoint, onReverseAddClick } = this.props;

	onReverseAddClick(stopPoint.uuid, stopPoint.latitude, stopPoint.longitude);
	this.onClose();
}

showAlert(url, e) {
	e && e.preventDefault();
	alerts.gallery(url);
}

onClose(e) {
	e && e.preventDefault();
	this.props.onClose();
}
}
