import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getReportSchedules = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.reports.getReportSchedules(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getReportSchedule = (uuid) => async (dispatch) => {
    try {
        const response = await api.reports.getReportSchedule(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createReportSchedule = (data) => async (dispatch) => {
    try {
        const response = await api.reports.createReportSchedule(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateReportSchedule = (data) => async (dispatch) => {
    try {
        const response = await api.reports.updateReportSchedule(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteReportSchedule = (data) => async (dispatch) => {
    try {
        const response = await api.reports.deleteReportSchedule(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};