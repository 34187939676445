import React, {Component} from "react";
import {connect} from "react-redux";

import Page from "../../ui/page";
import Loader from "../../ui/loader";
import Button from "components/ui/button";
import BaseEditorFormComponent from "components/base/base-editor-form";

import {getOption, getOptions, setOptionValue, setUserOptionValue, optionsUpdated} from "store/reducers/settings";

import currentUser from 'helpers/current-user';
import "./settings.less";
import systems from "dictionaries/systems";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BlockGroup from "components/ui/form/block-group";
import Block from "components/ui/form/block";
import _ from 'lodash';
import {getMaintenanceStatus, maintenanceDown, maintenanceUp} from "store/reducers/auth";
import * as alerts from "helpers/alerts";
import {disableTooltipEdit, enableTooltipEdit} from "store/reducers/tooltips";
import {getStopPoints} from "store/reducers/geo/stop-points";
import Progress from 'react-progressbar';
import json2csv from "json2csv";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {orderExecutionsStatistics} from "store/reducers/kiutr/orders/order_executions";
import {api, CycleFetch} from "helpers/api";
import ContextTooltip from "components/ui/context-tooltip";
import Tooltip from 'react-tooltip-component';
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import {getSkpdiLogs, skpdiImportTasks} from "store/reducers/kurs/skpdi_logs";
import moment from "moment";
import formats from "dictionaries/formats";
import {makeResponse} from "helpers/response";
import download from 'downloadjs'
import Tabs from "components/ui/tabs/tabs";
import TabItem from "components/ui/tabs/tab-item";
import TabBlock from "components/ui/tabs/tab-block";
import IconButton from "components/ui/icon-button";
import GlobalLoaderComponent from "components/ui/global-loader";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ColorBox from 'components/ui/ColorBox/ColorBox';
import SelectContainer from 'components/ui/SelectContainer/SelectContainer';
import SmsTemplate from "./gateway/smsTemplate";
import SmsDevices from "./gateway/smsDevices";
import {getHostName} from "helpers/wsrpc";
import {divIcon} from "leaflet/dist/leaflet-src.esm";
import {Slider} from "components/ui/slider";

@connect(state => ({
    settings: state.settings.get('settings'),
}), {getOptions, getOption, setOptionValue, setUserOptionValue, optionsUpdated})

export default class SettingsComponent extends Component {

    state = {
        loading: true,
        saving: false,
        data: null,
        errors: {},
    };

    async componentDidMount() {
        await this.loadOptions();
    }

    async loadOptions() {
        this.setState({
            loading: true
        });

        const response = await this.props.getOptions();
        this.setState({loading: false});

        if (response) {
            this.setState({
                data: this.transformValue(_.get(response.payload.items, 'global', {}))
            });
        } else {
            response.showErrors();

        }
    }

    clearErrors() {
        this.setState({errors: {}});
    }

    startSave() {
        this.setState({saving: true});
    }

    endSave() {
        this.setState({saving: false});
    }

    async onSubmit(data) {
        this.clearErrors();
        this.startSave();

        let errors = {};
        const transformedData = this.transformValue(data);
        await Promise.all(_.map(transformedData, async (value, key) => {
            const response = await this.props.setOptionValue(key, value);

            if (!response.isOk) {
                errors[key] = response.validationErrors
            }
        }));
        this.endSave();
        this.setState({
            errors: errors
        });

        this.loadOptions();
    }

    transformValue(data) {
        return _.mapValues(data, (value, key) => {
            if (key === 'visualConfig') {
                return typeof value === 'string' ? JSON.parse(value) : JSON.stringify(value);
            }
            return value
        })
    }

    onClose() {

    }

    render() {
        const loader = (this.state.loading || this.state.saving) ? <GlobalLoaderComponent/> : null;
        let form = null;

        if (this.state.data) {
            form = <SettingsEditorComponent
                ref="form"
                data={this.state.data}
                onSubmit={:: this.onSubmit}
                onClose={:: this.onClose}
                errors={this.state.errors}
                location={this.props.location}
            />;
        }
        return (
            <Page pageId="Main"
                  title={`${systems.administration} → Системные настройки`}
                  headerActions={this.renderHeaderActions()}>
                {loader}

                {form}
            </Page>
        )
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                            default="Журнал аудита">
                <IconButton icon="history" onClick={::this.gotoAudit}/>
            </ContextTooltip>,
            <ContextTooltip key="settings.save" code="settings.save" default="Сохранить">
                <IconButton icon="save" onClick={::this.save}/>
            </ContextTooltip>,
        ];
    }

    gotoAudit() {
        this.props.router.push(`/system/audit/settings?class=settings`);
    }

    save() {
        this.refs.form.getWrappedInstance().submit();
    }
}

@connect(state => ({
    tooltipedit: state.tooltips.get('active'),
}), {
    getMaintenanceStatus,
    maintenanceUp,
    maintenanceDown,
    enableTooltipEdit,
    disableTooltipEdit,
    getStopPoints,
    orderExecutionsStatistics,
    getUnits,
    getSkpdiLogs,
    skpdiImportTasks,
}, null, {withRef: true})
class SettingsEditorComponent extends BaseEditorFormComponent {

    defaultState = {
        maintenance: null,

        units: [],

        stopPointsExportInProgress: false,
        stopPointsExportProgress: 0,
        statistics: null,

        smsGatewayCase: 'button',

        logs: {
            date: moment().format(formats.DATE_URL),
            source: 'skpdi',
            fuel_from: moment().format(formats.DATE_URL),
        },
    };

    sources = {
        'skpdi': 'СКПДИ',
        'vis': 'ВИС',
        //'mta': 'МТА',
        'inventarisation': 'Инвентаризация',
    };

    state = _.cloneDeep(this.defaultState);

    _cycleFetch = null;

    async componentDidMount() {
        await this.setState(this.props.data);
        this.setState(this.defaultState);
        const {
            run_passed_is_сheck_by_control_points,
            run_passed_threshold_for_stop_points,
            run_passed_is_сheck_by_stop_points,
            run_passed_use_all_conditions_together
        } = this.props.data

        if (!run_passed_is_сheck_by_control_points || !run_passed_threshold_for_stop_points || !run_passed_is_сheck_by_stop_points || !run_passed_use_all_conditions_together) {
            if (!run_passed_is_сheck_by_control_points) {
                this.setState({run_passed_is_сheck_by_control_points: true})
            }
            if (!run_passed_threshold_for_stop_points) {
                this.setState({run_passed_threshold_for_stop_points: 50})
            }
            if (!run_passed_is_сheck_by_stop_points) {
                this.setState({run_passed_is_сheck_by_stop_points: true})
            }
            if (!run_passed_use_all_conditions_together) {
                this.setState({run_passed_use_all_conditions_together: 0})
            }
        }


        this.loadMaintenance();
        this.loadUnits();

        this._cycleFetch = new CycleFetch(:: this.loadStatistics, () => {
        }, 60000);
        this._cycleFetch.run();
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 10000,
            },
            filters: {
                withComponent: 'road',
            },
        });

        if (response.isOk) {
            this.setState({
                units: _.sortBy(_.map(response.payload.items, (unit) => ({
                    value: unit.uuid,
                    label: unit.name,
                })), 'label'),
            });
        } else {
            response.showErrors();
        }
    }

    componentWillUnmount() {
        this._cycleFetch && this._cycleFetch.stop();
        delete this['_cycleFetch'];
    }

    async loadStatistics() {
        const response = await this.props.orderExecutionsStatistics();

        if (response.isOk) {
            this.setState({
                statistics: response.payload,
            });
        }
    }

    async loadMaintenance() {
        const response = await this.props.getMaintenanceStatus();

        if (response.isOk) {
            this.setState({
                maintenance: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    /**
     * @param {String} field
     * @return {String}
     */
    getValue(field) {
        let value = _.get(this.getState(), field);

        switch (field) {
            case 'checkbox':
                value = (value == 1);
                break;
            case 'kurs_task_import_units':
                value = value ? _.filter(value.split(',')) : [];
                break;
        }

        return value;
    }

    submit() {
        let data = {};
        const keys = _.keys(this.defaultState);
        _.forEach(this.state, (option, key) => {
            if (_.indexOf(keys, key) !== -1) {
                return;
            }
            if (key === 'logs') {
                return;
            }
            if (!this.isChanged(key, option)) {
                return;
            }

            this.state[key] = option;
            data[key] = !_.isBoolean(option) ? option : (option ? 1 : 0);
        });
        this.props.onSubmit(data);
    }

    isChanged(key, value) {
        if (typeof value === 'object') return true;
        return _.get(this.props.data, key) !== value;
    }

    render() {
        return (
            <div className="SettingsEditorForm">
                <Tabs>
                    {(window.RNIS_SETTINGS.HIDESETTINGSCOMMONTAB === false || window.RNIS_SETTINGS.HIDESETTINGSCOMMONTAB === undefined || window.RNIS_SETTINGS.HIDESETTINGSCOMMONTAB === "undefined") ? (
                        <TabItem key="Общие настройки" title="Общие настройки" className="settings-common">
                            {!window.RNIS_SETTINGS.CITY_TULA ? (
                                <TabBlock size="md">

                                    {window.RNIS_SETTINGS.bnso_activity_time ? (
                                        <Tooltip
                                            title="Время, спустя которое ТС перестаёт отображаться на карте">
                                            <Block size="md" title="Время активности БНСО, мин">
                                                {this.textInput('bnso_activity_time', {
                                                    type: 'number',
                                                    min: 0,
                                                    positive: true,
                                                })}
                                            </Block>
                                        </Tooltip>) : null}

                                    {window.RNIS_SETTINGS.bnso_activity_time ? (
                                        <Tooltip
                                            title="Время, спустя которое ТС перестаёт отображаться на карте в подсистеме ГАТН">
                                            <Block size="md" title="Время активности БНСО ГУ ГАТН, мин">
                                                {this.textInput('gatn_bnso_active_limit', {
                                                    type: 'number',
                                                    min: 0,
                                                    positive: true,
                                                })}
                                            </Block>
                                        </Tooltip>
                                    ) : null}

                                    <Tooltip
                                        title="Укажите количество точек отклонения по ГЛОНАСС, которые система допускает в расчет">
                                        <Block size="md" title="Количество точек отклонения по ГЛОНАСС">
                                            {this.textInput('kurs_task_route_fail_points_count', {
                                                type: 'number',
                                                min: 1,
                                                positive: true,
                                            })}
                                        </Block>
                                    </Tooltip>
                                    <Tooltip
                                        title="Введите время, через которое система будет проверять скорость соединения">
                                        <Block size="md" title="Частота проверки скорости соединения, мин">
                                            {this.textInput('connection_check_interval', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}
                                        </Block>
                                    </Tooltip>
                                </TabBlock>
                            ) : null}

                            {window.RNIS_SETTINGS.bnso_activity_time ? (
                                <TabBlock size="md">
                                    <Tooltip
                                        title="Время, спустя которое ТС перестаёт отображаться на карте">
                                        <Block size="md" title="Время активности БНСО, мин">
                                            {this.textInput('bnso_activity_time', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}
                                        </Block>
                                    </Tooltip>
                                    <Tooltip
                                        title="Время, спустя которое ТС перестаёт отображаться на карте в подсистеме ГАТН">
                                        <Block size="md" title="Время активности БНСО ГУ ГАТН, мин">
                                            {this.textInput('gatn_bnso_active_limit', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}
                                        </Block>
                                    </Tooltip>

                                </TabBlock>

                            ) : null}


                            <TabBlock size="md">
                                <Tooltip
                                    title="Метрика по расчету превышения скорости. Превышение скорости рассчитывается в нескольких точках в разные моменты времени.">
                                    <Block size="md" title="Количество точек превышения скорости, шт">
                                        {this.textInput('speed_violation_points', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Разница между максимально допустимой и фактической скорости, при которой учитывается нарушение">
                                    <Block size="md" title="Отчеты: пороговое знач. превышения скорости, км/ч">
                                        {this.textInput('speed_limit_threshold_value', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Настройка длительности нарушения, при которой оно засчитывается. Разница между фактической скорости и ограничения, умноженная на время">
                                    <Block size="md" title="Отчеты: пороговый объем превышения скорости, км">
                                        {this.textInput('speed_limit_threshold_volume', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>
                            </TabBlock>

                            <TabBlock size="md">
                                <Tooltip title="Введите текстовое сообщение">
                                    <Block size="md" title="Сообщение для пользователей">
                                        {this.textInput('alert_message')}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Введите Email, на которое будут приходить обращения пользователей Портала">
                                    <Block size="md" title="Email обращений портала">
                                        {this.textInput('portal_feedback_email')}
                                    </Block>
                                </Tooltip>

                                <Tooltip title="Введите Email, на которое будут приходить обращения пользователей МП">
                                    <Block size="md" title="Email обращений мобильного приложения">
                                        {this.textInput('mobile_feedback_email')}
                                    </Block>
                                </Tooltip>

                                <Tooltip title="Введите Email, на которое будут приходить жалобы пользователей МП">
                                    <Block size="md" title="Email жалоб мобильного приложения">
                                        {this.textInput('mobile_complaint_email')}
                                    </Block>
                                </Tooltip>
                            </TabBlock>

                            <TabBlock size="md">
                                {currentUser.can('com.rnis.system.maintenance', 'update') ? (
                                    <Block size="md">
                                        {_.get(this.state.maintenance, 'status') ? (
                                            <Button size="lg" text="Выключить режим обслуживания" color="white"
                                                    shadow="gray"
                                                    onClick={::this.disableMaintenance}/>
                                        ) : (
                                            <Button size="lg" text="Включить режим обслуживания" color="red"
                                                    shadow="red"
                                                    onClick={::this.enableMaintenance}/>
                                        )}
                                    </Block>
                                ) : null}

                                {currentUser.can('com.rnis.system.tooltip', 'update') ? (
                                    this.renderTooltipEdit()
                                ) : null}

                                {window.RNIS_SETTINGS.HIDESETTINGSBTNAPIDESCRIPTION === false ?
                                    <Block size="md">
                                        <Button size="lg" className="custom" text="Перейти к описанию API" color="red"
                                                shadow="red"
                                                onClick={::this.gotoApiDocs}/>
                                    </Block> : null}
                                <Block size="md">
                                    {this.checkbox('limit_not_approved_vehicles', 'Разрешительный порядок деятельности')}
                                    {currentUser.can('only-supervisor', 'read') ? (
                                        this.checkbox('display_telematics_quality', 'Отображать качество телематики')
                                    ) : null}
                                    {this.checkbox('order_execution_recalc_available', 'Отображать кнопку "пересчитать" в УТР')}
                                </Block>
                            </TabBlock>
                        </TabItem>
                    ) : null}
                    {(window.RNIS_SETTINGS.HIDESETTINGSKIUTRTAB === false || window.RNIS_SETTINGS.HIDESETTINGSKIUTRTAB === undefined || window.RNIS_SETTINGS.HIDESETTINGSKIUTRTAB === "undefined") ? (
                        <TabItem key="Настройки КиУТР" title="Настройки КиУТР" className="settings-kiutr">
                            <TabBlock size="md">
                                <Tooltip
                                    title="Расстояние, на которое ТС должно приблизиться для ее зачета">
                                    <Block size="md" title="Радиус зачета остановки, м">
                                        {this.textInput('fact_radius', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>
                                <Tooltip title="Минимальная продолжительность остановки ТС по вывозу мусора для отчета о незапланированных остановках">
                                    <Block size="md" title="Время определения остановки для ТС по вывозу мусора, мин">
                                        {this.textInput('garbage_vehicle_stop_limit', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                {(currentUser.login === 'admin2') ? (
                                    <Tooltip
                                        title="Множитель окна зачета по опережению">
                                        <Block size="md" title="Множитель окна зачета по опережению">
                                            {this.textInput('kiutr_before_multiplier')}
                                        </Block>
                                    </Tooltip>
                                ) : null}

                                {(currentUser.login === 'admin2') ? (
                                    <Tooltip
                                        title="Множитель окна зачета по опозданию">
                                        <Block size="md" title="Множитель окна зачета по опозданию">
                                            {this.textInput('kiutr_after_multiplier')}
                                        </Block>
                                    </Tooltip>
                                ) : null}
                            </TabBlock>
                            <TabBlock size="md">
                                <Tooltip
                                    title="Настройка расстояния в алгоритме идентификации парной остановки. Учитывается при построении маршрута и отображении расписаний на портале">
                                    <Block size="md" title="Радиус поиска парных остановок, м" position="bottom">
                                        {this.textInput('paired_stop_points_threshold', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>
                                <Tooltip
                                    title="Остановка будет зачтена автоматически, если она находится не далее чем на указанном расстоянии от зачтенной">
                                    <Block size="md" title="Радиус зачета остановок по соседним остановкам, м">
                                        {this.textInput('order_execution_nearby_points_threshold', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>
                            </TabBlock>
                            <TabBlock size="md">
                                <Tooltip
                                    title="Ограничение по возрасту для МК">
                                    <Block size="md" title="Ограничение по возрасту для МК" position="bottom">
                                        {this.textInput('mtdi_age_limit_mk', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>
                                <Tooltip
                                    title="Ограничение по возрасту для СК, БК">
                                    <Block size="md" title="Ограничение по возрасту для СК, БК">
                                        {this.textInput('mtdi_age_limit_sk_bk', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>
                                <Tooltip
                                    title="Допустимая величина заполнения ТС для рекомендации повышения класса ТС, %">
                                    <Block size="md"
                                           title="Допустимая величина заполнения ТС для рекомендации повышения класса ТС, %"
                                           position="bottom">
                                        {this.textInput('kiutr_capacity_limit', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>
                            </TabBlock>
                            {window.RNIS_SETTINGS.schedule_interval_deviations ? (
                                <TabBlock size="md">
                                    <BlockGroup key="deviation_interval" title="Интервальное расписание"
                                                className="interval-deviation">
                                        <Block
                                            title="Допустимое отклонение интервала в сторону увеличения, мин"
                                            position="bottom">
                                            {this.textInput('deviation_forward', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}
                                        </Block>
                                        <Block
                                            title="Допустимое отклонение интервала в сторону уменьшения, мин"
                                            position="bottom">
                                            {this.textInput('deviation_backward', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}
                                        </Block>
                                    </BlockGroup>
                                </TabBlock>
                            ) : null}
                            {currentUser.isSupervisor() ? (
                                <TabBlock size="md">
                                    <Block size="md"
                                           title="Минимальное время между запусками пересчета, мин">
                                        {this.textInput('kiutr_order_recalc_timeout', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                    <Block size="md"
                                           title="Фильтр повторных посещений первого ОП, сек">
                                        {this.checkbox('kiutr_second_visit_limit_first_op', 'Фильтр активен')}
                                        {_.get(this.state, 'kiutr_second_visit_limit_first_op') ? this.textInput('kiutr_second_visit_limit_first_op_value', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        }) : null}
                                    </Block>
                                    <Block size="md"
                                           title="Фильтр повторных посещений последнего ОП, сек">
                                        {this.checkbox('kiutr_second_visit_limit_last_op', 'Фильтр активен')}
                                        {_.get(this.state, 'kiutr_second_visit_limit_last_op') ? this.textInput('kiutr_second_visit_limit_last_op_value', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        }) : null}
                                    </Block>
                                    <Block size="md"
                                           title="Фильтр повторных посещений остальных ОП, сек">
                                        {this.checkbox('kiutr_second_visit_limit_mid_op', 'Фильтр активен')}
                                        {_.get(this.state, 'kiutr_second_visit_limit_mid_op') ? this.textInput('kiutr_second_visit_limit_mid_op_value', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        }) : null}
                                    </Block>
                                </TabBlock>
                            ) : null}

                            <div className="Table">
                                <TableContainer>
                                    <table className="b-table b-table-no-hover">
                                        <thead>
                                        <tr>
                                            <td rowSpan={2}/>
                                            <td colSpan={3}>Допустимые отклонения, мин</td>
                                        </tr>
                                        <tr>
                                            <td>По опережению расписания</td>
                                            <td>По отставанию от расписания</td>
                                            <td>Диапазон для увеличения отставания</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Городское</td>
                                            <td>{this.textInput('check_city_before_threshold', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}</td>
                                            <td>{this.textInput('check_city_after_threshold', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}</td>
                                            <td>{this.textInput('check_city_after_limit', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}</td>
                                        </tr>
                                        <tr>
                                            <td>Пригородное</td>
                                            <td>{this.textInput('check_suburb_before_threshold', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}</td>
                                            <td>{this.textInput('check_suburb_after_threshold', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}</td>
                                            <td>{this.textInput('check_suburb_after_limit', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}</td>
                                        </tr>
                                        <tr>
                                            <td>Междугородное</td>
                                            <td>{this.textInput('check_intercity_before_threshold', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}</td>
                                            <td>{this.textInput('check_intercity_after_threshold', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}</td>
                                            <td>{this.textInput('check_intercity_after_limit', {
                                                type: 'number',
                                                min: 0,
                                                positive: true,
                                            })}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </TableContainer>
                            </div>
                        </TabItem>
                    ) : null}
                    {(window.RNIS_SETTINGS.HIDESETTINGSKURSTAB === false || window.RNIS_SETTINGS.HIDESETTINGSKURSTAB === undefined || window.RNIS_SETTINGS.HIDESETTINGSKURSTAB === "undefined") ? (
                        <TabItem key="Настройки КУРС-Д" title="Настройки КУРС-Д" className="settings-kurs">
                            <TabBlock size="md">
                                <Tooltip
                                    title="Расстояние, на которое ТС должно приблизиться к остановке для ее зачета в подсистеме КУРС-Д">
                                    <Block size="md" title="Радиус зачета остановки, м">
                                        {this.textInput('kurs_fact_radius', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Расстояние, на которое ТС должно приблизиться к объекту ТКО для его зачета в подсистеме КУРС-Д">
                                    <Block size="md" title="Радиус зачёта объекта ТКО, м">
                                        {this.textInput('garbage_object_threshold', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Допустимое количество точек отклонения телематики от участка, по которому идет выполнения работ">
                                    <Block size="md" title="Допустимое кол-во точек для пропуска, шт">
                                        {this.textInput('kurs_allow_skip_count', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Сход с маршрута, мин">
                                    <Block size="md"
                                           title="Сход с маршрута, мин">
                                        {this.textInput('kurs_check_time_limit', {
                                            type: 'number',
                                            min: 1,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Сход с посещения участка работ, мин">
                                    <Block size="md"
                                           title="Сход с посещения участка работ, мин">
                                        {this.textInput('kurs_check_time_limit2', {
                                            type: 'number',
                                            min: 1,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Количество последовательных точек телематики для проверки работы механизмов на участке работ">
                                    <Block size="md"
                                           title="Кол-во точек для проверки работы механизмов, шт">
                                        {this.textInput('kurs_mechanism_violation_points_count', {
                                            type: 'number',
                                            min: 1,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>
                            </TabBlock>

                            <TabBlock size="md">
                                <Tooltip
                                    title="Количество времени с момента наступления события, через которое происходит расчет заправленных литров">
                                    <Block size="md" title="Кол-во минут для начала расчета заправленных литров, мин.">
                                        {this.textInput('fill_amount_time', {
                                            type: 'number',
                                            min: 1,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Количество минут в течение резкого изменения топлива в событии заправка топлива">
                                    <Block size="md"
                                           title="Кол-во минут в течение резкого изменения топлива (заправка), мин">
                                        {this.textInput('fill_time', {
                                            type: 'number',
                                            min: 1,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Количество времени с момента наступления события, через которое происходит расчет слитого топлива">
                                    <Block size="md"
                                           title="Кол-во минут для начала расчета слитого топлива, мин.">
                                        {this.textInput('drain_amount_time', {
                                            type: 'number',
                                            min: 1,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Количество минут в течение резкого изменения топлива в событии возможного слива топлива">
                                    <Block size="md"
                                           title="Кол-во минут в течение резкого изменения топлива (возможный слив), мин">
                                        {this.textInput('drain_time', {
                                            type: 'number',
                                            min: 1,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>
                            </TabBlock>

                            <TabBlock size="md">
                                <Tooltip title="Период времени, через который происходит фиксация события «Заправка»">
                                    <Block size="md" title="Частота фиксации события «Заправка», мин">
                                        {this.textInput('fill_frequency', {
                                            type: 'number',
                                            min: 1,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Количество процентов изменения уровня топлива в событии возможного слива топлива">
                                    <Block size="md"
                                           title="Процент изменения уровня топлива (возможный слив)">
                                        {this.textInput('drain_percent', {
                                            type: 'number',
                                            min: 1,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Период времени, через который происходит фиксация события «Возможный слив»">
                                    <Block size="md" title="Частота фиксации события «Возможный слив», мин">
                                        {this.textInput('drain_frequency', {
                                            type: 'number',
                                            min: 1,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Количество процентов изменения уровня топлива в событии заправка топлива">
                                    <Block size="md"
                                           title="Процент изменения уровня топлива (заправка)">
                                        {this.textInput('fill_percent', {
                                            type: 'number',
                                            min: 1,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>
                            </TabBlock>

                            <TabBlock size="md">
                                <Tooltip
                                    title="Доля выполнения работ, при которой производится их зачет">
                                    <Block size="md" title="Процент выполнения участка работ для зачета">
                                        {this.textInput('kurs_task_complete_percent', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                            percent: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Укажите на каком расстоянии система будет принимать отклонение от маршрута (участка работ)">
                                    <Block size="md" title="Кол-во метров отклонения от маршрута/участка работы, м">
                                        {this.textInput('kurs_task_route_fail_meters', {
                                            type: 'number',
                                            min: 0,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip title="Нормализация данных по медиане">
                                    <Block size="md"
                                           title="Уровень сглаживания по медиане">
                                        {this.textInput('kurs_fuel_median', {
                                            type: 'number',
                                            min: 1,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Tooltip
                                    title="Для фиксации съезда с маршрута укажите количество последних точек на участке работ">
                                    <Block size="md"
                                           title="Кол-во точек для фиксации съезда с маршрута">
                                        {this.textInput('kurs_task_route_fail_last_points', {
                                            type: 'number',
                                            min: 1,
                                            positive: true,
                                        })}
                                    </Block>
                                </Tooltip>

                                <Block size="md"
                                       title="Добавлять промежуточные точки телематики при расстоянии от, м">
                                    {this.textInput('kurs_telematics_max_distance', {
                                        type: 'number',
                                        min: 1,
                                        positive: true,
                                    })}
                                </Block>

                                <Block size="md"
                                       title="Расстояние между промежуточными точками, м">
                                    {this.textInput('kurs_telematics_part_distance', {
                                        type: 'number',
                                        min: 1,
                                        positive: true,
                                    })}
                                </Block>
                            </TabBlock>

                            <Block size="xl">
                                {this.checkbox('kurs_task_max_speed', 'Учет работ на участке в соответствии с максимальной разрешенной скоростью работы механизма')}
                                <br/>
                                {this.checkbox('kurs_task_mechanism_use', 'Учет работ на участке в соответствии с использованием механизмов')}
                                <br/>
                                {this.checkbox('kurs_task_limit_work_types', 'Учет видов работ для ТС')}
                                <br/>
                                {this.checkbox('kurs_skpdi_task_items_edit_deny', 'Запретить изменения данных по распределенным участкам СКПДИ')}
                            </Block>
                            {(this.props.location.query.fuel && currentUser.isSupervisor()) ? (
                                <TabBlock size="md">
                                    <Block size="md" title="Дата с">
                                        {this.datepicker('logs.fuel_from')}
                                    </Block>
                                    <Block>
                                        <Button size="lg" color="red" shadow="red" text="Запустить пересчет"
                                                onClick={::this.fuelRecalc}/>
                                    </Block>
                                </TabBlock>
                            ) : null}
                        </TabItem>
                    ) : null}
                    {(window.RNIS_SETTINGS.HIDESETTINGSSTATISTICSTAB === false || window.RNIS_SETTINGS.HIDESETTINGSSTATISTICSTAB === undefined || window.RNIS_SETTINGS.HIDESETTINGSSTATISTICSTAB === "undefined") ? (
                        <TabItem key="Статистика расчетов" title="Статистика расчетов" className="settings-statistics">
                            <TabBlock>
                                {this.renderStatistics()}
                            </TabBlock>
                        </TabItem>
                    ) : null}
                    {window.RNIS_SETTINGS.sms_gateway ? (
                        <TabItem key="СМС шлюз" title="СМС шлюз" className="settings-statistics">
                            <TabBlock>
                                {this.renderSmsGateway()}
                            </TabBlock>
                        </TabItem>
                    ) : null}
                    {(window.RNIS_SETTINGS.HIDEIMPORTSKPDITAB === false || window.RNIS_SETTINGS.HIDEIMPORTSKPDITAB === undefined || window.RNIS_SETTINGS.HIDEIMPORTSKPDITAB === "undefined") ? (
                        <TabItem key="Импорт СКПДИ" title="Импорт СКПДИ" className="settings-skpdi">
                            <TabBlock size="xl">
                                <Block size="xl" title="Предприятия импорта">
                                    {this.select('kurs_task_import_units', this.state.units, {
                                        multi: true,
                                    })}
                                </Block>
                                {(this.props.location.query.skpdi && currentUser.isSupervisor()) ? (
                                    <Block size="xl">
                                        <Button size="lg" color="red" shadow="red" text="Импорт заданий СКПДИ"
                                                onClick={::this.importSkpdiTasks}/>
                                    </Block>
                                ) : null}
                            </TabBlock>
                        </TabItem>) : null}
                    {(window.RNIS_SETTINGS.HIDESETTINGSSKPDILOGSTAB === false || window.RNIS_SETTINGS.HIDESETTINGSSKPDILOGSTAB === undefined || window.RNIS_SETTINGS.HIDESETTINGSSKPDILOGSTAB === "undefined") ? (
                        <TabItem key="Логи импорта" title="Логи импорта" className="settings-skpdi">
                            <TabBlock size="xl">
                                <Block title="Источник">
                                    {this.select('logs.source', _.map(this.sources, (label, value) => ({
                                        value,
                                        label,
                                    }), {
                                        clearable: false,
                                    }))}
                                </Block>
                                <Block title="Дата">
                                    {this.datepicker('logs.date')}
                                </Block>
                                <Block>
                                    <Button size="lg" color="red" shadow="red" text="Скачать лог"
                                            onClick={::this.downloadLog}/>
                                </Block>
                            </TabBlock>
                        </TabItem>
                    ) : null}
                    {(window.RNIS_SETTINGS.HIDESETTINGSSTOPPOINTSTAB === false || window.RNIS_SETTINGS.HIDESETTINGSSTOPPOINTSTAB === undefined || window.RNIS_SETTINGS.HIDESETTINGSSTOPPOINTSTAB === "undefined") ? (
                        <TabItem key="Экспорт остановок" title="Экспорт остановок" className="settings-stop-points">
                            <TabBlock size="xl">
                                {this.renderStopPoints()}
                            </TabBlock>
                        </TabItem>
                    ) : null}
                    {/*<TabItem title="Настройка презентации" className="settingsMapContentSys grid-fix settingsMapVisContent">
                        <TabBlock size="xl">
                          {this.renderPresentation()}
                        </TabBlock>
                      </TabItem>*/}
                    {/* <TabItem title="Настройка презентации" className="settingsMapContentSys grid-fix settingsMapVisContent">
                <TabBlock size="xl">
                  {this.renderStopPoints()}
                      </TabBlock>
                    </TabItem> */}
                    {((window.RNIS_SETTINGS.HIDESETTINGSLOGICTRIPCHECKTAB === false || window.RNIS_SETTINGS.HIDESETTINGSLOGICTRIPCHECKTAB === undefined || window.RNIS_SETTINGS.HIDESETTINGSLOGICTRIPCHECKTAB === "undefined") && currentUser.isSupervisor()) ? (
                        <TabItem title="Логика зачета рейсов" className="settings-logic-trip-check">
                            <TabBlock size="xl">
                                {this.renderLogicTripCheck()}
                            </TabBlock>
                        </TabItem>
                    ) : null}
                </Tabs>
            </div>
        )
    }

    gotoApiDocs() {
        const a = document.createElement("a");
        a.setAttribute("href", '/files/docs/index.html');
        a.setAttribute("target", "_blank");
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
        }, 0);
    }

    importSkpdiTasks() {
        this.props.skpdiImportTasks();
        alerts.alert('Импорт запущен');
    }

    handleButtonClickCase(currentCase) {
        this.setState({
            smsGatewayCase: currentCase
        })
    }

    resetCase() {
        if (this.state.smsGatewayCase !== 'button') {
            this.setState({
                smsGatewayCase: 'button'
            })
        }
    }

    renderSmsGateway() {
        return (
            <div>
                {this.state.smsGatewayCase === 'button' ? (<div>
                    <Button size="lg" text="Шаблоны сообщений" color="red" shadow="red"
                            onClick={() => this.handleButtonClickCase('template')}/>
                    <Button size="lg" text="Устройства" color="red" shadow="red"
                            onClick={() => this.handleButtonClickCase('devices')}/>
                </div>) : null}
                {this.state.smsGatewayCase === 'template' ? <SmsTemplate reset={::this.resetCase}
                                                                         handleButtonClickCase={(value) => this.handleButtonClickCase(value)}/> : null}
                {this.state.smsGatewayCase === 'devices' ? <SmsDevices reset={::this.resetCase}
                                                                       handleButtonClickCase={(value) => this.handleButtonClickCase(value)}/> : null}
            </div>
        )
    }

    renderStatistics() {
        if (!this.state.statistics) {
            return null;
        }

        return (
            <div>
                <TabBlock size="md">
                    <Block size="md" title="Общее количество рейсов на текущие сутки">
                        {_.get(this.state.statistics, 'total')}
                    </Block>
                    <Block size="md" title="Среднее время вычислений за последние 30 минут, сек">
                        {_.get(this.state.statistics, 'average_time_30_min', '-')}
                    </Block>
                </TabBlock>
                <TabBlock size="md">
                    <Block size="md" title="Количество рейсов в очереди">
                        {_.get(this.state.statistics, 'count')}
                    </Block>
                    <Block size="md" title="Максимальное время вычислений за последние 6 часов, сек">
                        {_.get(this.state.statistics, 'max_time_6_hour', '-')}
                    </Block>
                </TabBlock>
                <TabBlock size="md">
                    <Block size="md" title="Максимальное время вычислений за последние 30 минут, сек">
                        {_.get(this.state.statistics, 'max_time_30_min', '-')}
                    </Block>
                    <Block size="md" title="Среднее время вычислений за последние 6 часов, сек">
                        {_.get(this.state.statistics, 'average_time_6_hour', '-')}
                    </Block>
                </TabBlock>
            </div>
        );
    }

    changeValue = (settingsName, value) => {
        const newState = {...this.state};
        _.set(newState, settingsName, value);
        this.setState(newState);
    }

    renderPresentation() {
        return (
            <div className="container-fluid">

                <div className="settingsMapVisContent__row row">
                    <div className="col-xs-6">
                        <div className="settingsMapVisContent__sect">
                            <div className="settingsMapVisContent__left">
                                Иконка объекта:
                            </div>
                            <div className="settingsMapVisContent__right">
                                <div className="rnis-icon rnis-icon_book"></div>
                                <div className="rnis-icon rnis-icon_download"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="settingsMapVisContent__sect">
                            <div className="settingsMapVisContent__left">
                                Ордер:
                            </div>
                            <div className="settingsMapVisContent__right">
                                <div className="b-block__text">
                                    {this.textInput('visualConfig.order', {
                                        type: 'number',
                                        min: 0,
                                        positive: true,
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="settingsMapVisContent__row row">
                    <div className="col-xs-12">
                        <div className="settingsMapVisContent__title">
                            Подложка
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="settingsMapVisContent__sect">
                            <div className="settingsMapVisContent__left">
                                Цвет заливки:
                            </div>
                            <div className="settingsMapVisContent__right">
                                <div className="picker">
                                    <ColorBox
                                        defaultValue={this.state.visualConfig && this.state.visualConfig.fill_color}
                                        settingsName="visualConfig.fill_color"
                                        onChange={this.changeValue}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="settingsMapVisContent__sect">
                            <div className="settingsMapVisContent__left">
                                Прозрачность заливки:
                            </div>
                            <div className="settingsMapVisContent__right">
                                <div className="b-block__text">
                                    {this.textInput('visualConfig.fill_opacity', {
                                        type: 'number',
                                        percent: true,
                                        positive: true,
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="settingsMapVisContent__sect">
                            <div className="settingsMapVisContent__left">
                                Форма подложки:
                            </div>
                            <div className="settingsMapVisContent__right dimmSelect dimmSelect_small">
                                <div className="b-block__text">
                                    <div>
                                        <SelectContainer
                                            defaultValue={this.state.visualConfig && this.state.visualConfig.fill_type}
                                            settingsName="visualConfig.fill_type"
                                            onChange={this.changeValue}
                                        >
                                            <div className="Select-option" data-value="round" role="option">
                                                <div
                                                    className="settingsMapVisContent__type settingsMapVisContent__type_round"></div>
                                            </div>
                                            <div className="Select-option" data-value="square" role="option">
                                                <div
                                                    className="settingsMapVisContent__type settingsMapVisContent__type_square"></div>
                                            </div>
                                            <div className="Select-option" data-value="rect" role="option">
                                                <div
                                                    className="settingsMapVisContent__type settingsMapVisContent__type_rect_r"></div>
                                            </div>
                                        </SelectContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="settingsMapVisContent__row row">
                    <div className="col-xs-12">
                        <div className="settingsMapVisContent__title">
                            Граница
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="settingsMapVisContent__sect">
                            <div className="settingsMapVisContent__left">
                                Цвет границы:
                            </div>
                            <div className="settingsMapVisContent__right">
                                <div className="picker">
                                    <ColorBox
                                        defaultValue={this.state.visualConfig && this.state.visualConfig.stroke_color}
                                        settingsName="visualConfig.stroke_color"
                                        onChange={this.changeValue}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="settingsMapVisContent__sect">
                            <div className="settingsMapVisContent__left">
                                Прозрачность границы:
                            </div>
                            <div className="settingsMapVisContent__right">
                                <div className="b-block__text">
                                    {this.textInput('visualConfig.stroke_opacity', {
                                        type: 'number',
                                        percent: true
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="settingsMapVisContent__sect">
                            <div className="settingsMapVisContent__left">
                                Тип линии границы:
                            </div>
                            <div className="settingsMapVisContent__right dimmSelect">
                                <div className="b-block__text">
                                    <div>
                                        <SelectContainer
                                            defaultValue={this.state.visualConfig && this.state.visualConfig.stroke_type}
                                            settingsName="visualConfig.stroke_type"
                                            onChange={this.changeValue}
                                        >
                                            <div className="Select-option" data-value="01" role="option">
                                                <div className="settingsMapVisContent__option">
                                                    <div className="settingsMapVisContent__option-left">
                                                        01
                                                    </div>
                                                    <div className="settingsMapVisContent__option-right">
                                                        <div
                                                            className="settingsMapVisContent__border-type type-one"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Select-option" data-value="02" role="option">
                                                <div className="settingsMapVisContent__option">
                                                    <div className="settingsMapVisContent__option-left">
                                                        02
                                                    </div>
                                                    <div className="settingsMapVisContent__option-right">
                                                        <div
                                                            className="settingsMapVisContent__border-type type-two"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Select-option" data-value="03" role="option">
                                                <div className="settingsMapVisContent__option">
                                                    <div className="settingsMapVisContent__option-left">
                                                        03
                                                    </div>
                                                    <div className="settingsMapVisContent__option-right">
                                                        <div
                                                            className="settingsMapVisContent__border-type type-three"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Select-option" data-value="04" role="option">
                                                <div className="settingsMapVisContent__option">
                                                    <div className="settingsMapVisContent__option-left">
                                                        04
                                                    </div>
                                                    <div className="settingsMapVisContent__option-right">
                                                        <div
                                                            className="settingsMapVisContent__border-type type-four"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Select-option" data-value="05" role="option">
                                                <div className="settingsMapVisContent__option">
                                                    <div className="settingsMapVisContent__option-left">
                                                        05
                                                    </div>
                                                    <div className="settingsMapVisContent__option-right">
                                                        <div
                                                            className="settingsMapVisContent__border-type type-five"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SelectContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="settingsMapVisContent__sect">
                            <div className="settingsMapVisContent__left">
                                Толщина линии границы:
                            </div>
                            <div className="settingsMapVisContent__right">
                                <div className="b-block__text">
                                    {this.textInput('visualConfig.stroke_width', {
                                        type: 'number',
                                        min: 0,
                                        positive: true
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="settingsMapVisContent__row row">
                    <div className="col-xs-12">
                        <div className="settingsMapVisContent__title">
                            Эффекты
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="settingsMapVisContent__sect">
                            <div className="settingsMapVisContent__left">
                                Свечение: сила
                            </div>
                            <div className="settingsMapVisContent__right">
                                <div className="b-block__text">
                                    {this.textInput('visualConfig.glow_power', {
                                        type: 'number',
                                        min: 0,
                                        positive: true,
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="settingsMapVisContent__sect">
                            <div className="settingsMapVisContent__left">
                                Свечение: цвет
                            </div>
                            <div className="settingsMapVisContent__right">
                                <div className="picker">
                                    <ColorBox
                                        defaultValue={this.state.visualConfig && this.state.visualConfig.glow_color}
                                        settingsName="visualConfig.glow_color"
                                        onChange={this.changeValue}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="settingsMapVisContent__sect">
                            <div className="settingsMapVisContent__left">
                                Тень: смещение
                            </div>
                            <div className="settingsMapVisContent__right">
                                <div className="b-block__text">
                                    {this.textInput('visualConfig.shadow_offset', {
                                        type: 'number',
                                        min: 0,
                                        positive: true,
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="settingsMapVisContent__sect">
                            <div className="settingsMapVisContent__left">
                                Тень: цвет
                            </div>
                            <div className="settingsMapVisContent__right">
                                <div className="picker">
                                    <ColorBox
                                        defaultValue={this.state.visualConfig && this.state.visualConfig.shadow_color}
                                        settingsName="visualConfig.shadow_color"
                                        onChange={this.changeValue}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLogicTripCheck = () => {
        let {
            run_passed_is_сheck_by_stop_points,
            run_passed_is_сheck_by_control_points,
            run_passed_use_all_conditions_together
        } = this.state
        if (run_passed_is_сheck_by_stop_points === '0') {
            run_passed_is_сheck_by_stop_points = false
        }
        if (run_passed_is_сheck_by_control_points === '0') {
            run_passed_is_сheck_by_control_points = false
        }
        const logic_trip_check_points = !!run_passed_is_сheck_by_control_points && !!run_passed_is_сheck_by_stop_points
        return (
            <Block size="xl">
                <TabBlock size="md">
                    <Block size="md">
                        {this.checkbox('run_passed_is_сheck_by_control_points',
                            'Зачет всех контрольных пунктов'
                        )}
                    </Block>
                    <Block size="md">
                        {this.checkbox('run_passed_is_сheck_by_stop_points',
                            'Зачет остановочных пунктов (не менее порогового значения)',
                        )}
                    </Block>
                </TabBlock>
                <TabBlock size="md">
                    <Block size="md">
                        <Slider selected={
                            logic_trip_check_points ?
                                parseInt(run_passed_use_all_conditions_together)
                                : 0}
                                title={
                                    logic_trip_check_points
                                    ? (parseInt(run_passed_use_all_conditions_together) === 1)
                                        ? 'выполняются оба условия (run_passed_use_all_conditions_together)'
                                        : 'выполняется хотя бы одно условие'
                                    : 'выбраны не все варианты'
                                }
                                total={1}
                                selectAll={
                                    logic_trip_check_points ?
                                        () => this.setState({
                                            run_passed_use_all_conditions_together: 1
                                        })
                                        : () => false}
                                deselectAll={logic_trip_check_points ?
                                    () => this.setState({
                                        run_passed_use_all_conditions_together: 0
                                    })
                                    : () => false}
                        />
                    </Block>
                    <Block size="md" title="Пороговое значение для зачета ОП (%)">
                        {this.textInput('run_passed_threshold_for_stop_points', {
                            type: 'number',
                            min: 0,
                            max: 100,
                            positive: true,
                        })}
                    </Block>
                </TabBlock>
            </Block>)

    }

    renderStopPoints() {
        let buttonTitle = window.RNIS_SETTINGS.export_button_mta_name ? window.RNIS_SETTINGS.export_button_mta_name : "Экспортировать остановки слоя ЭРМ МТА";
        return (
            <div>
                {this.state.stopPointsExportInProgress ? (
                    <Block size="xl" title="Процесс экспорта">
                        <Progress completed={this.state.stopPointsExportProgress} color="#f65d50"/>
                    </Block>
                ) : (
                    <Block size="xl">
                        <Button size="lg" text={buttonTitle} color="red" shadow="red"
                                onClick={::this.exportStopPoints}/>
                    </Block>
                )}
            </div>
        );
    }

    async exportStopPoints() {
        const totalResponse = await this.props.getStopPoints({
            pagination: {
                limit: 1,
            },
        });
        const total = _.get(totalResponse.data, 'headers.meta.pagination.total');
        const perPage = 300;
        const pages = Math.ceil(total / perPage);
        let currentPage = 1;
        this.setState({
            stopPointsExportInProgress: true,
            stopPointsExportProgress: 0,
        });
        let result = [];
        while (currentPage <= pages) {
            const response = await this.props.getStopPoints({
                pagination: {
                    limit: perPage,
                    page: currentPage,
                },
                order: {
                    column: 'uuid',
                    direction: 'asc',
                },
            });
            this.setState({
                stopPointsExportProgress: 100 * (currentPage * perPage) / total,
            });
            currentPage++;
            _.each(response.payload.items, (stopPoint) => {
                const row = {
                    uuid: stopPoint.uuid,
                    title: stopPoint.title,
                    title_short: stopPoint.title_short,
                    register_number: stopPoint.register_number,
                    latitude: stopPoint.latitude,
                    longitude: stopPoint.longitude,
                };
                result.push(row);
            });
        }
        const csv = json2csv({
            data: result,
            fields: [
                'uuid',
                'title',
                'title_short',
                'register_number',
                'latitude',
                'longitude',
            ],
            fieldNames: [
                'Идентификатор',
                'Наименование',
                'Короткое наименование',
                'Регистрационный номер',
                'Широта',
                'Долгота',
            ],
        });
        this.downloadStopPoints(csv);
    }

    downloadStopPoints(text) {
        const blob = new Blob(["\ufeff" + text]);
        const a = document.createElement("a");
        a.setAttribute("href", URL.createObjectURL(blob, {type: 'text/csv;charset=utf-8'}));
        a.setAttribute("download", 'stop-points.csv');
        a.setAttribute("target", "_blank");
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
        }, 0);

        this.setState({
            stopPointsExportInProgress: false,
        });
    }

    renderTooltipEdit() {
        return (
            <Block size="md">
                {this.props.tooltipedit ? (
                    <Button size="lg" text="Выключить режим редактирования" color="white" shadow="gray"
                            onClick={::this.props.disableTooltipEdit}/>
                ) : (
                    <Button size="lg" className="custom" text="Включить режим редактирования" color="red" shadow="red"
                            onClick={::this.props.enableTooltipEdit}/>
                )}
            </Block>
        );
    }

    async enableMaintenance() {
        alerts.ask('Сообщение режима обслуживания?', async (message) => {
            alerts.ask('Время режима обслуживания (мин)?', async (retry) => {
                const response = await this.props.maintenanceUp(message, retry);
                this.loadMaintenance();
                if (response.isOk) {
                    alerts.success('Режим обслуживания включен');
                } else {
                    response.showErrors();
                }
            });
        });
    }

    async disableMaintenance() {
        alerts.prompt('Выключить режим обслуживания?', '', async () => {
            const response = await this.props.maintenanceDown();
            this.loadMaintenance();
            if (response.isOk) {
                alerts.success('Режим обслуживания выключен');
            } else {
                response.showErrors();
            }
        }, 'Выключить');
    }


    async setValue(field, value) {
        if (field === 'kurs_task_import_units') {
            value = value.join(',');
        }

        return super.setValue(field, value);
    }

    async downloadLog() {
        const protocol = App.isSecure ? 'https://' : 'http://';
        const url = protocol + getHostName('ajax', false) + `/storage/logs/integration_log_${this.state.logs.source}_${this.state.logs.date}.xls`;
        window.location.href = url;
    }

    async fuelRecalc() {
        makeResponse(() => {
            return api.kurs.vehicleFuelRecalc({
                vehicle_uuid: 'bf81aeae-2db3-11e8-84af-02f42b7df5b5',
                from: this.state.logs.fuel_from,
            });
        });

        alerts.success('Пересчет запущен');
    }

    async convertToXls(content) {
        const response = await makeResponse(() => {
            return api.converter.convertHtmlToXls(content);
        });

        if (response.isOk) {
            const content = response.payload.content;
            download(`data:application/excel;base64,${content}`, `Экспорт ${moment(this.state.logs.date).format(formats.DATE)}.xls`);
        } else {
            response.showErrors();
        }
    }
}
