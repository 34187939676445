export default {
    NETWORK_ERROR: {
        code: 'network_error',
        message: 'Ошибка сети'
    },
    UNKNOWN_ERROR: {
        code: 'unknown_error',
        message: 'Неизвестная ошибка сервера'
    },
    PERMISSION_ERROR: {
        code: 'permission_error',
        serverCode: '1-0-0-4',
        message: 'Недостаточно прав'
    },
    InvalidLoginPasswordException: {
        code: '2-0-0-1',
        serverCode: '2-0-0-1',
        message: 'Неверный логин/пароль'
    }
}