import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {createObject, getObject, updateObject} from "store/reducers/garbage/objects";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUserGeoObjects} from "store/reducers/user-map-objects/object_editor";
import MapComponent from "components/ui/map";
import HistoryPointMarker from "components/ui/map/markers/history-point-marker";
import GarbagePointMarker from "components/ui/map/markers/garbage-point-marker";
import {createRepairProgram, getRepairProgram, updateRepairProgram} from "store/reducers/kurs/repair_programs";
import {getRoadParts} from "store/reducers/kurs/road_parts";
import {getUnits} from "store/reducers/organizational_units/units";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getRepairProgram, createRepairProgram, updateRepairProgram})

export default class Editor extends BaseEditor {

    title = 'программы ремонта';
    modelClass = 'App\\Model\\RepairProgram';

    async loadData(uuid) {
        return await this.props.getRepairProgram(uuid);
    }

    async createItem(data) {
        return await this.props.createRepairProgram(data);
    }

    async updateItem(data) {
        return await this.props.updateRepairProgram(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getDictionaryList, getRoadParts, getUnits}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        repair_program: {},
        units: [],
    };

    getData() {
        return this.state.repair_program;
    }

    async componentWillMount() {
        await this.setState({
            repair_program: this.props.data,
        });

        this.loadUnits();
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            filters: {
                withComponent: 'road',
            },
        });

        if (response.isOk) {
            this.setState({
                units: _.map(response.payload.items, (unit) => ({
                    value: unit.uuid,
                    label: unit.name,
                })),
            });
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.repair_program, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} title="Основные сведения">
                        <Block title="Участок ремонта дорог">
                            {this.selectAsync('repair_program.road_part_uuid', ::this.loadRoadParts)}
                        </Block>
                        <Block title="РУАД">
                            {this.select('repair_program.unit_uuid', this.state.units)}
                        </Block>
                        <Block title="Нач. участка">
                            {this.textInput('repair_program.start')}
                        </Block>
                        <Block title="Конец участка">
                            {this.textInput('repair_program.end')}
                        </Block>
                        <Block title="Протяженность">
                            {this.textInput('repair_program.length')}
                        </Block>
                        <Block title="Вид ремонта">
                            {this.textInput('repair_program.repair_type')}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    async loadRoadParts(input, callback) {
        if (!input) {
            input = this.get('road_part_uuid');
        }
        const result = await this.props.getRoadParts({
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
        });

        if (result.isOk) {
            callback(null, {
                options: result.payload.items.map(item => ({
                    value: item.uuid,
                    label: item.name,
                })),
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }
}