import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import TabletPage from 'components/ui/tablet/page';
import {
    Bar, BarChart, CartesianGrid, Label, LabelList, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis,
    YAxis, Text, Area, AreaChart
} from "recharts";
import moment from "moment";
import formats from "dictionaries/formats";
import Datepicker from "components/ui/form/datepicker";
import {getTabletKiutrSummary, getTabletSummary} from "store/reducers/reports/tablet";
import _ from 'lodash';
import {Link} from "react-router";
import Select from "components/ui/select";
import classNames from 'classnames';
import * as storage from "utils/storage";
import GlobalLoaderComponent from "components/ui/global-loader";
import DateTick from "components/modules/analytics/base-components/charts/DateTick/index";
import CustomTooltip from "components/modules/tablet/tooltip/index";
import {TabletTimeInstance} from 'components/modules/tablet/home/index';
import {CycleFetch} from "helpers/api";
import {EntityList} from "helpers/entity";
import {speedViolationsDriversSummary} from "store/reducers/notifications/speed-violations";
import {getUsers} from "store/reducers/staffing/staffing";
import {getUnits} from "store/reducers/organizational_units/units";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getEntityNames} from "store/reducers/system";
import {User} from "helpers/user";

@connect(state => ({}), {
    getTabletKiutrSummary,
    speedViolationsDriversSummary,
    getUsers,
    getUnits,
    getVehicleList,
    getEntityNames,
})

export default class TabletChildren extends Component {

    state = {
        data: {},
        regularity: 'best',
        speed_violations: 'units',
        vehicles_plan: 'best',
        vehicles_bnso: 'best',
        transport_work: 'best',
        table_sort_column: 'unit_name',
        table_sort_direction: 'asc',

        fullscreen: null,
        search: '',
        data2: [],
        users: {},
        units: {},
        vehicles: {},
        related: new EntityList,
    };

    onSearchChange({target: {value}}) {
        this.setState({
            search: value,
        });
    }

    _cycleFetch = null;

    async componentWillMount() {
        await this.init();

        this._cycleFetch = new CycleFetch(() => {
            return Promise.all([
                this.loadData(),
                this.loadData2(),
            ]);
        }, () => {
        }, 5 * 60000);
        this._cycleFetch.run();
    }

    componentDidUnmount() {
        this._cycleFetch.stop();
        delete this['_cycleFetch'];
    }

    async init() {
        await this.setState({
            from: TabletTimeInstance.from,
            to: TabletTimeInstance.to,
            displayed_from: TabletTimeInstance.from,
            displayed_to: TabletTimeInstance.to,
        });
    }

    activateFullscreen(widget) {
        this.setState({
            fullscreen: widget,
        });
    }

    deactivateFullscreen() {
        this.setState({
            fullscreen: null,
        });
    }

    render() {
        return (
            <TabletPage
                title="Перевозки детей"
                header_btns={this.getHeaderBtns()}
                header_top_right={this.getHeaderTopRight()}
                header_bottom={this.getHeaderBottom()}
                modalsActive={this.state.fullscreen !== null}
            >
                <div className="rating">
                    {this.state.loading ? <GlobalLoaderComponent/> : null}
                    <div className="rating__top">
                        <div className="row">
                            <div className="col col-8">
                                <ul className="breadcrumbs">
                                    <li className="breadcrumbs__item"><Link
                                        className="breadcrumbs__link breadcrumbs__link_na" to="/tablet/dashboard">Дашборд</Link><span
                                        className="breadcrumbs__jump"><span
                                        className="breadcrumbs__jump-dot"></span><span
                                        className="breadcrumbs__jump-dot"></span><span
                                        className="breadcrumbs__jump-arrow"></span></span></li>
                                    <li className="breadcrumbs__item"><a className="breadcrumbs__link">Перевозки детей</a></li>
                                </ul>
                            </div>
                            <div className="col col-1"/>
                            <div className="col col-3"/>
                        </div>
                    </div>
                    <div className="rating__bottom">
                        <div className="widgets">
                            <div className="row">
                                <div className="col col-6">
                                    <div className={classNames('widgets__item', (this.state.fullscreen === 'regularity') ? 'fullscreen' : '')}>
                                        <div className="widgets__header">
                                            <div className="widgets__title">Регулярность движения</div>
                                            {(this.state.fullscreen === 'regularity') ? (
                                                <svg className="icon icon_cross icon_gray"
                                                     onClick={::this.deactivateFullscreen}>
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                                </svg>
                                            ) : (
                                                <svg className="icon icon_fullscreen icon_gray"
                                                     onClick={this.activateFullscreen.bind(this, 'regularity')}>
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                                </svg>
                                            )}
                                        </div>
                                        <div className="widgets__content">
                                            <div className="widgets__selector">
                                                <Select value={this.state.regularity}
                                                        onChange={this.onChange.bind(this, 'regularity')}
                                                        clearable={false} searchable={false} options={[
                                                    {
                                                        value: 'best',
                                                        label: 'Топ лучших',
                                                    },
                                                    {
                                                        value: 'worst',
                                                        label: 'Топ худших',
                                                    },
                                                ]}/>
                                            </div>
                                            {this.renderRegularityChart()}
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className={classNames('widgets__item', (this.state.fullscreen === 'speed_violations_line') ? 'fullscreen' : '')}>
                                        <div className="widgets__header">
                                            <div className="widgets__title">Количество нарушений скоростного режима
                                            </div>
                                            {(this.state.fullscreen === 'speed_violations_line') ? (
                                                <svg className="icon icon_cross icon_gray"
                                                     onClick={::this.deactivateFullscreen}>
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                                </svg>
                                            ) : (
                                                <svg className="icon icon_fullscreen icon_gray"
                                                     onClick={this.activateFullscreen.bind(this, 'speed_violations_line')}>
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                                </svg>
                                            )}
                                        </div>
                                        <div className="widgets__content">
                                            {this.renderSpeedViolationsChart()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-6 widget-block_speed-violation">
                                    <div
                                        className={classNames('widgets__item', (this.state.fullscreen === 'speed_violations') ? 'fullscreen' : '')}>
                                        <div className="widgets__header">
                                            <div className="widgets__title">Нарушители скоростного режима</div>
                                            {(this.state.fullscreen === 'speed_violations') ? (
                                                <svg className="icon icon_cross icon_gray"
                                                     onClick={::this.deactivateFullscreen}>
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                                </svg>
                                            ) : (
                                                <svg className="icon icon_fullscreen icon_gray"
                                                     onClick={this.activateFullscreen.bind(this, 'speed_violations')}>
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                                </svg>
                                            )}
                                        </div>
                                        <div className="widgets__content">
                                            <div className="widgets__selector">
                                                <Select value={this.state.speed_violations}
                                                        onChange={this.onChange.bind(this, 'speed_violations')}
                                                        clearable={false} searchable={false} options={[
                                                    {
                                                        value: 'units',
                                                        label: 'Предприятия',
                                                    },
                                                    {
                                                        value: 'drivers',
                                                        label: 'Водители',
                                                    },
                                                ]}/>
                                            </div>
                                            {this.renderUnitSpeedViolationsChart()}
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div
                                        className={classNames('widgets__item', (this.state.fullscreen === 'vehicles_plan') ? 'fullscreen' : '')}>
                                        <div className="widgets__header">
                                            <div className="widgets__title">Выполнение плана по выходу ТС</div>
                                            {(this.state.fullscreen === 'vehicles_plan') ? (
                                                <svg className="icon icon_cross icon_gray"
                                                     onClick={::this.deactivateFullscreen}>
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                                </svg>
                                            ) : (
                                                <svg className="icon icon_fullscreen icon_gray"
                                                     onClick={this.activateFullscreen.bind(this, 'vehicles_plan')}>
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                                </svg>
                                            )}
                                        </div>
                                        <div className="widgets__content">
                                            <div className="widgets__selector">
                                                <Select value={this.state.vehicles_plan}
                                                        onChange={this.onChange.bind(this, 'vehicles_plan')}
                                                        clearable={false} searchable={false} options={[
                                                    {
                                                        value: 'best',
                                                        label: 'Топ лучших',
                                                    },
                                                    {
                                                        value: 'worst',
                                                        label: 'Топ худших',
                                                    },
                                                ]}/>
                                            </div>
                                            {this.renderVehiclePlanChart()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-6">
                                    <div className={classNames('widgets__item', (this.state.fullscreen === 'vehicles_bnso') ? 'fullscreen' : '')}>
                                        <div className="widgets__header">
                                            <div className="widgets__title">Оснащенность ГЛОНАСС</div>
                                            {(this.state.fullscreen === 'vehicles_bnso') ? (
                                                <svg className="icon icon_cross icon_gray"
                                                     onClick={::this.deactivateFullscreen}>
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                                </svg>
                                            ) : (
                                                <svg className="icon icon_fullscreen icon_gray"
                                                     onClick={this.activateFullscreen.bind(this, 'vehicles_bnso')}>
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                                </svg>
                                            )}
                                        </div>
                                        <div className="widgets__content">
                                            <div className="widgets__selector">
                                                <Select value={this.state.vehicles_bnso}
                                                        onChange={this.onChange.bind(this, 'vehicles_bnso')}
                                                        clearable={false} searchable={false} options={[
                                                    {
                                                        value: 'best',
                                                        label: 'Топ лучших',
                                                    },
                                                    {
                                                        value: 'worst',
                                                        label: 'Топ худших',
                                                    },
                                                ]}/>
                                            </div>
                                            {this.renderVehiclesBnsoChart()}
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className={classNames('widgets__item', (this.state.fullscreen === 'transport_work') ? 'fullscreen' : '')}>
                                        <div className="widgets__header">
                                            <div className="widgets__title">Доля выполнения рейсов</div>
                                            {(this.state.fullscreen === 'transport_work') ? (
                                                <svg className="icon icon_cross icon_gray"
                                                     onClick={::this.deactivateFullscreen}>
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_cross"/>
                                                </svg>
                                            ) : (
                                                <svg className="icon icon_fullscreen icon_gray"
                                                     onClick={this.activateFullscreen.bind(this, 'transport_work')}>
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_fullscreen"/>
                                                </svg>
                                            )}
                                        </div>
                                        <div className="widgets__content">
                                            <div className="widgets__selector">
                                                <Select value={this.state.transport_work}
                                                        onChange={this.onChange.bind(this, 'transport_work')}
                                                        clearable={false} searchable={false} options={[
                                                    {
                                                        value: 'best',
                                                        label: 'Топ лучших',
                                                    },
                                                    {
                                                        value: 'worst',
                                                        label: 'Топ худших',
                                                    },
                                                ]}/>
                                            </div>
                                            {this.renderTransportWorkChart()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rating__search">
                            <div className="search">
                                <form className="search__form" action="#">
                                    <input className="search__input" type="text" placeholder="Поиск по названию компании перевозчика" value={this.state.search} onChange={::this.onSearchChange}/>
                                </form>
                                <svg className="icon icon_search icon_red">
                                    <use xlinkHref="/img/spriteSVG.svg#icon_search"/>
                                </svg>
                            </div>
                        </div>
                        <div className="table-wrap">
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th onClick={this.onSortChange.bind(this, 'unit_name')} className={classNames((this.state.table_sort_column == 'unit_name') ? 'sorted-font' : '')}>
                                        Перевозчик
                                        {(this.state.table_sort_column === 'unit_name') ? (
                                            (this.state.table_sort_direction === 'asc') ? (
                                                <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                                </svg>
                                            ) : (
                                                <svg className="icon icon_long-arrow icon_red">
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                                </svg>
                                            )
                                        ) : null}
                                    </th>
                                    <th onClick={this.onSortChange.bind(this, 'regularity')} className={classNames((this.state.table_sort_column == 'regularity') ? 'sorted-font' : '')}>
                                        Регулярность рейсов
                                        {(this.state.table_sort_column === 'regularity') ? (
                                            (this.state.table_sort_direction === 'asc') ? (
                                                <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                                </svg>
                                            ) : (
                                                <svg className="icon icon_long-arrow icon_red">
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                                </svg>
                                            )
                                        ) : null}
                                    </th>
                                    <th onClick={this.onSortChange.bind(this, 'speed_violations')} className={classNames((this.state.table_sort_column == 'speed_violations') ? 'sorted-font' : '')}>
                                        Количество нарушений скоростного режима
                                        {(this.state.table_sort_column === 'speed_violations') ? (
                                            (this.state.table_sort_direction === 'asc') ? (
                                                <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                                </svg>
                                            ) : (
                                                <svg className="icon icon_long-arrow icon_red">
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                                </svg>
                                            )
                                        ) : null}
                                    </th>
                                    <th onClick={this.onSortChange.bind(this, 'vehicles_plan')} className={classNames((this.state.table_sort_column == 'vehicles_plan') ? 'sorted-font' : '')}>
                                        План по выходу ТС
                                        {(this.state.table_sort_column === 'vehicles_plan') ? (
                                            (this.state.table_sort_direction === 'asc') ? (
                                                <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                                </svg>
                                            ) : (
                                                <svg className="icon icon_long-arrow icon_red">
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                                </svg>
                                            )
                                        ) : null}
                                    </th>
                                    <th onClick={this.onSortChange.bind(this, 'vehicles_bnso')} className={classNames((this.state.table_sort_column == 'vehicles_bnso') ? 'sorted-font' : '')}>
                                        Оснащенность ГЛОНАСС
                                        {(this.state.table_sort_column === 'vehicles_bnso') ? (
                                            (this.state.table_sort_direction === 'asc') ? (
                                                <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                                </svg>
                                            ) : (
                                                <svg className="icon icon_long-arrow icon_red">
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                                </svg>
                                            )
                                        ) : null}
                                    </th>
                                    <th onClick={this.onSortChange.bind(this, 'transport_work')} className={classNames((this.state.table_sort_column == 'transport_work') ? 'sorted-font' : '')}>
                                        Выполнение рейсов
                                        {(this.state.table_sort_column === 'transport_work') ? (
                                            (this.state.table_sort_direction === 'asc') ? (
                                                <svg className="icon icon_long-arrow icon_green2 icon_rotate180">
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                                </svg>
                                            ) : (
                                                <svg className="icon icon_long-arrow icon_red">
                                                    <use xlinkHref="/img/spriteSVG.svg#icon_long-arrow"></use>
                                                </svg>
                                            )
                                        ) : null}
                                    </th>
                                </tr>
                                {_.map(this.getRows(), ::this.renderRow)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </TabletPage>
        );
    }

    onSortChange(field) {
        if (field === this.state.table_sort_column) {
            this.setState({
                table_sort_direction: (this.state.table_sort_direction === 'asc') ? 'desc' : 'asc',
            });
        } else {
            this.setState({
                table_sort_column: field,
                table_sort_direction: 'asc',
            });
        }
    }

    getRows() {
        const rows = _.orderBy(this.state.data.units || [], [this.state.table_sort_column], [this.state.table_sort_direction]);

        if (this.state.search) {
            return _.filter(rows, (row) => {
                return row.unit_name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
            });
        }

        return rows;
    }

    renderRow(data) {
        const {unit_uuid, unit_name, regularity, speed_violations, vehicles_plan, vehicles_bnso, transport_work} = data;

        return (
            <tr key={unit_uuid}>
                <td onClick={this.showUnitSummary.bind(this, unit_uuid)}>{unit_name}</td>
                <td>{regularity}</td>
                <td>{speed_violations}</td>
                <td>{vehicles_plan}</td>
                <td>{vehicles_bnso}</td>
                <td>{transport_work}</td>
            </tr>
        );
    }

    showUnitSummary(uuid) {
        this.props.router.push(`/tablet/children/${uuid}`);
    }
    getHeaderBtns() {
        return (
            <li className="header-top__icons-item">
                <Link className="header-top__icons-link header-top__icons-link_back" to="/tablet/dashboard">
                    <svg className="icon icon_back icon_white">
                        <use xlinkHref="/img/spriteSVG.svg#icon_back"/>
                    </svg>
                </Link>
            </li>
        );
    }

    getHeaderTopRight() {
        return (
            <ul className="header-top__icons header-top__icons_tar">
                <li className="header-top__icons-item">
                    <div className="search">
                        <form className="search__form">
                            <input className="search__input" type="text" placeholder="поиск"/>
                            <button className="search__submit" type="submit">
                                <svg className="icon icon_search icon_red">
                                    <use xlinkHref="/img/spriteSVG.svg#icon_search"/>
                                </svg>
                            </button>
                        </form>
                    </div>
                    <a className="header-top__icons-link header-top__icons-link_none-bg header-top__icons-link_search">
                        <svg className="icon icon_search icon_red">
                            <use xlinkHref="/img/spriteSVG.svg#icon_search"/>
                        </svg>
                    </a>
                </li>
            </ul>
        );
    }

    getHeaderBottom() {
        const from = moment(this.state.displayed_from);
        const to = moment(this.state.displayed_to);

        return (
            <div className="row">
                <div className="header-bottom__left col col-6">
                    <div className="title-text">
                        <div className="title-text__value">Текущий период</div>
                        <div className="title-text__dscn">{this.formatDate(from)} - {this.formatDate(to)}</div>
                    </div>
                </div>
                <div className="header-bottom__right col col-6">
                    <div className="row">
                        <div className="col col-4">
                            <Datepicker
                                value={this.state.from}
                                onChange={this.onChange.bind(this, 'from')}
                                readOnly={true}
                            />
                        </div>
                        <div className="col col-4">
                            <Datepicker
                                value={this.state.to}
                                onChange={this.onChange.bind(this, 'to')}
                                readOnly={true}
                            />
                        </div>
                        <div className="col col-4">
                            <button className="btn btn_form btn_colored" onClick={::this.reload}>Сформировать</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onChange(field, e) {
        const value = e.value || e.target.value;

        let state = this.state;
        state[field] = value;
        this.setState(state);

        if (field === 'from' || field === 'to') {
            storage.save(`tablet-${field}`, value);
        }
    }

    async reload() {
        TabletTimeInstance.update(this.state.from, this.state.to);
        await this.setState({
            displayed_from: this.state.from,
            displayed_to: this.state.to,
        });

        this._cycleFetch.forceNext();
    }

    formatDate(date) {
        const months = {
            0: 'января',
            1: 'февраля',
            2: 'марта',
            3: 'апреля',
            4: 'мая',
            5: 'июня',
            6: 'июля',
            7: 'августа',
            8: 'сентября',
            9: 'октября',
            10: 'ноября',
            11: 'декабря',
        };
        return `${date.date()} ${months[date.month()]} ${date.year()}`;
    }

    async loadData() {
        this.setState({loading: true});
        const response = await this.props.getTabletKiutrSummary({
            date_from: this.state.from,
            date_to: this.state.to,
            component: 'children',
        });
        this.setState({loading: false});

        if (response.isOk) {
            this.setState({
                data: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadData2() {
        const response2 = await this.props.speedViolationsDriversSummary('children', moment(this.state.from).format(formats.DATETIME_API), moment(this.state.to).endOf('day').format(formats.DATETIME_API));

        if (response2.isOk) {
            await this.setState({
                data2: response2.payload.items,
            });
            this.loadRelated();
        } else {
            response2.showErrors();
        }
    }

    async loadRelated() {
        this.loadRelatedEntities();
        await this.loadUsers();
    }

    async loadVehicles() {
        const response = await this.props.getVehicleList({
            filters: {
                withUuid: _.uniq(_.map(this.state.data2, 'vehicle_uuid')),
            },
        });

        if (response.isOk) {
            return response.payload.items;
        }
        return [];
    }

    async loadRelatedEntities() {
        const vehicles = await this.loadVehicles();
        this.loadUnits(vehicles);
        const marks = _.map(_.uniq(_.filter(_.map(vehicles, 'vehicle_mark_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleMark\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const models = _.map(_.uniq(_.filter(_.map(vehicles, 'vehicle_model_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleModel\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(_.concat(marks, models));

        if (response.isOk) {
            this.state.related.add(response);
            this.setState({
                vehicles: _.keyBy(vehicles, 'uuid'),
            });
        }
    }

    async loadUsers() {
        const usersResponse = await this.props.getUsers({
            filters: {
                withUuid: _.uniq(_.map(this.state.data2, 'driver_uuid')),
            },
        });

        if (usersResponse.isOk) {
            await this.setState({
                users: _.keyBy(usersResponse.payload.items, 'uuid'),
            });
        }
    }

    async loadUnits(vehicles) {
        const response = await this.props.getUnits({
            filters: {
                withUuid: _.uniq(_.map(vehicles, 'unit_uuid')),
            },
        });

        if (response.isOk) {
            this.setState({
                units: _.keyBy(response.payload.items, 'uuid'),
            });
        }
    }

    renderRegularityChart() {
        let rows;
        if (this.state.regularity === 'best') {
            rows = _.slice(_.orderBy(this.state.data.units || [], ['regularity'], ['desc']), 0, 10);
        } else {
            rows = _.slice(_.orderBy(this.state.data.units || [], ['regularity'], ['asc']), 0, 10);
        }

        const data = _.map(rows, (row) => {
            return {
                name: row.unit_name,
                value: row.regularity,
                label: row.regularity ? `${row.regularity}` : '',
                fill: this.getFill(row.regularity, 'regularity'),
                radius: [4, 4, 0, 0],
            };
        });

        return (
            <ResponsiveContainer>
                <BarChart data={data} margin={{bottom: 50}}>
                    <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" fontSize={10} tick={props => <Text {...props} width={130}>{props.payload.value}</Text>}/>
                    <YAxis dataKey="value"
                           label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle"
                                                 angle={-90}>%</Text>}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Bar dataKey="value" fill="#57AF7D" name="Регулярность движения" barSize={30} isAnimationActive={false}>
                        <LabelList dataKey="label" position="insideTop" fill="#fff"/>
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    }

    renderUnitSpeedViolationsChart() {
        if (this.state.speed_violations === 'units') {
            const rows = _.slice(_.orderBy(this.state.data.units || [], ['speed_violations'], ['desc']), 0, 10);
            const data = _.map(rows, (row) => {
                return {
                    name: row.unit_name,
                    value: row.speed_violations,
                    fill: this.getCountFill(row.speed_violations, 'speed_violations'),
                    radius: [4, 4, 0, 0],
                };
            });

            return (
                <ResponsiveContainer>
                    <BarChart data={data} margin={{bottom: 100}}>
                        <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" fontSize={10}
                               tick={props => <Text {...props} width={130}>{props.payload.value}</Text>}/>
                        <YAxis dataKey="value"
                               label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle"
                                                     angle={-90}>Количество</Text>}/>
                        <Tooltip/>
                        <Bar dataKey="value" fill="#57AF7D" name="Количество нарушений скоростного режима" barSize={50}
                             isAnimationActive={false}>
                            <LabelList dataKey="value" position="insideTop" fill="#fff" formatter={(value) => {
                                return value ? value : null;
                            }}/>
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            );
        } else {
            let data = _.map(this.state.data2, (row) => {
                const user = _.get(this.state.users, row.driver_uuid);
                const vehicle = _.get(this.state.vehicles, row.vehicle_uuid);

                return {
                    name: user ? new User(user).getFullName() : null,
                    unit: (vehicle && vehicle.unit_uuid) ? _.get(this.state.units, vehicle.unit_uuid) : null,
                    state_number: _.get(vehicle, 'state_number'),
                    vehicle_mark: (vehicle && vehicle.vehicle_mark_uuid) ? this.state.related.getReact(vehicle.vehicle_mark_uuid) : null,
                    vehicle_model: (vehicle && vehicle.vehicle_model_uuid) ? this.state.related.getReact(vehicle.vehicle_model_uuid) : null,
                    value: row.volume,
                    fill: '#F65C50',
                    radius: [4, 4, 0, 0],
                };
            });

            if (data.length === 0) {
                data = [{
                    name: '',
                    unit: '',
                    state_number: '',
                    vehicle_mark: '',
                    vehicle_model: '',
                    value: 0,
                    fill: '#F65C50',
                    radius: [4, 4, 0, 0],
                }];
            }

            return (
                <ResponsiveContainer>
                    <BarChart data={data} margin={{bottom: 100}}>
                        <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" fontSize={10}
                               tick={props => <Text {...props} width={130}>{props.payload.value}</Text>}/>
                        <YAxis dataKey="value"
                               label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle"
                                                     angle={-90}>Превышение на, км/ч</Text>}/>
                        <Tooltip content={<CustomTooltip2/>}/>
                        <Bar dataKey="value" fill="#57AF7D" name="Превышение скоростного режима" barSize={50}
                             isAnimationActive={false}>
                            <LabelList dataKey="value" position="insideTop" fill="#fff" formatter={(value) => {
                                return value ? value : null;
                            }}/>
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            );
        }
    }

    renderVehiclePlanChart() {
        let rows;
        if (this.state.vehicles_plan === 'best') {
            rows = _.slice(_.orderBy(this.state.data.units || [], ['vehicles_plan'], ['desc']), 0, 10);
        } else {
            rows = _.slice(_.orderBy(this.state.data.units || [], ['vehicles_plan'], ['asc']), 0, 10);
        }

        const data = _.map(rows, (row) => {
            return {
                name: row.unit_name,
                value: row.vehicles_plan,
                label: row.vehicles_plan ? `${row.vehicles_plan}` : '',
                fill: this.getFill(row.vehicles_plan, 'vehicles_plan'),
                radius: [4, 4, 0, 0],
            };
        });

        return (
            <ResponsiveContainer>
                <BarChart data={data} margin={{bottom: 50}}>
                    <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" fontSize={10} tick={props => <Text {...props} width={130}>{props.payload.value}</Text>}/>
                    <YAxis dataKey="value"
                           label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle"
                                                 angle={-90}>%</Text>}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Bar dataKey="value" fill="#57AF7D" name="Выполнение плана по выходу ТС" barSize={30} isAnimationActive={false}>
                        <LabelList dataKey="label" position="insideTop" fill="#fff"/>
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    }

    renderVehiclesBnsoChart() {
        let rows;
        if (this.state.vehicles_bnso === 'best') {
            rows = _.slice(_.orderBy(this.state.data.units || [], ['vehicles_bnso'], ['desc']), 0, 10);
        } else {
            rows = _.slice(_.orderBy(this.state.data.units || [], ['vehicles_bnso'], ['asc']), 0, 10);
        }

        const data = _.map(rows, (row) => {
            return {
                name: row.unit_name,
                value: row.vehicles_bnso,
                label: row.vehicles_bnso ? `${row.vehicles_bnso}` : '',
                fill: this.getFill(row.vehicles_bnso, 'vehicles_bnso'),
                radius: [4, 4, 0, 0],
            };
        });

        return (
            <ResponsiveContainer>
                <BarChart data={data} margin={{bottom: 50}}>
                    <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" fontSize={10} tick={props => <Text {...props} width={130}>{props.payload.value}</Text>}/>
                    <YAxis dataKey="value"
                           label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle"
                                                 angle={-90}>%</Text>}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Bar dataKey="value" fill="#57AF7D" name="Оснащенность ГЛОНАСС" barSize={30} isAnimationActive={false}>
                        <LabelList dataKey="label" position="insideTop" fill="#fff"/>
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    }

    renderTransportWorkChart() {
        let rows;
        if (this.state.transport_work === 'best') {
            rows = _.slice(_.orderBy(this.state.data.units || [], ['transport_work'], ['desc']), 0, 10);
        } else {
            rows = _.slice(_.orderBy(this.state.data.units || [], ['transport_work'], ['asc']), 0, 10);
        }

        const data = _.map(rows, (row) => {
            return {
                name: row.unit_name,
                value: row.transport_work,
                label: row.transport_work ? `${row.transport_work}` : '',
                fill: this.getFill(row.transport_work, 'transport_work'),
                radius: [4, 4, 0, 0],
            };
        });

        return (
            <ResponsiveContainer>
                <BarChart data={data} margin={{bottom: 50}}>
                    <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" fontSize={10} tick={props => <Text {...props} width={130}>{props.payload.value}</Text>}/>
                    <YAxis dataKey="value"
                           label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle"
                                                 angle={-90}>%</Text>}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Bar dataKey="value" fill="#57AF7D" name="Доля выполнения рейсов" barSize={30} isAnimationActive={false}>
                        <LabelList dataKey="label" position="insideTop" fill="#fff"/>
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    }

    renderSpeedViolationsChart() {
        let rows = this.state.data.speed_violations || [];

        const data = _.map(rows, (row) => {
            return {
                name: moment(row.date).format(formats.DATE),
                value: row.count,
                label: row.count || '',
            };
        });

        return (
            <ResponsiveContainer>
                <AreaChart data={data} margin={{bottom: 50}}>
                    <XAxis dataKey="name" tick={<DateTick/>} interval="preserveStartEnd"/>
                    <YAxis dataKey="value" label={props => <Text x={15} y={props.viewBox.height / 2} textAnchor="middle" angle={-90}>Количество</Text>}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <CartesianGrid horizontal={false}/>
                    <Area dataKey="value" fill="rgba(87, 175, 125, 0.5)" name="Количество нарушений скоростного режима" stroke="#57AF7D" type="monotone"/>
                </AreaChart>
            </ResponsiveContainer>
        );
    }

    getFill(value, field) {
        let green = 80;
        let orange = 50;

        if (field === 'vehicles_bnso') {
            green = 99;
            orange = 80;
        }

        if (value >= green) {
            return '#95B400';
        }
        if (value >= orange) {
            return '#F4A44B';
        }
        return '#F65C50';
    }

    getCountFill(value, field, invert = true) {
        if (value > 0) {
            return '#F65C50';
        } else {
            return '#95B400';
        }
    }
}

const CustomTooltip2 = React.createClass({
    propTypes: {
        type: PropTypes.string,
        payload: PropTypes.array,
        label: PropTypes.string,
    },

    render() {
        const {active, wrapperStyle} = this.props;

        const finalStyle = {
            margin: 0,
            padding: 10,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            whiteSpace: 'nowrap',
            ...wrapperStyle,
        };

        if (active) {
            const {payload, label} = this.props;

            if (!payload) {
                return;
            }

            return (
                <div className="recharts-default-tooltip" style={finalStyle}>
                    <p className="recharts-tooltip-label">{`Превышение на: ${payload[0].value} км/ч`}</p>
                    <p>ТС: {payload[0].payload.state_number}</p>
                    <p>Предприятие: {_.get(payload[0].payload.unit, 'name')}</p>
                    <p>Водитель: {payload[0].payload.name}</p>
                    <p>ТС: марка, модель: {payload[0].payload.vehicle_mark}, {payload[0].payload.vehicle_model}</p>
                </div>
            );
        }

        return null;
    }
});