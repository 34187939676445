export const filterRolesBySafeList = (items) => {
    const safeListAP = ['com.rnis.system.analytics_platform.road', 'com.rnis.system.analytics_platform.summary', 'com.rnis.system.analytics_platform'];
    return items.filter(item => {
        if (item.code.includes('com.rnis.system.analytics_platform') && safeListAP.includes(item.code)) {
            return item;
        }
        if (!item.code.includes('com.rnis.system.analytics_platform')) {
            return item;
        }
    })
}