import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import {HotKeys} from 'react-hotkeys';

export default class HotKeysManager extends Component {

    getHotKeyHandlers() {
        return {
            'search': this.props.search,
            'filters': this.props.filters,
            'column-select': this.props.columnSelect,
            'add': this.props.add,
            'print': this.props.print,
            'export': this.props.export,
            'delete': this.props.delete,
            'table': this.props.table,
            'tree': this.props.tree,
            'violations': this.props.violations,
            'list': this.props.list,
            'stop-points-fullscreen': this.props.stopPointsFullscreen,
        };
    }

    render() {
        return (
            <HotKeys handlers={this.getHotKeyHandlers()} focused={true} attach={window}>
                {this.props.children}
            </HotKeys>
        )
    }

}