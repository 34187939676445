import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import moment from "moment";
import formats from "dictionaries/formats";
import {deleteNews, getNews} from "store/reducers/portal/news";
import ReactDOMServer from 'react-dom/server';
import {State} from "components/ui/state";
import currentUser from 'helpers/current-user';

@connect(state => ({}), {getNews, deleteNews})

export default class PortalNewsComponent extends BaseTableWithEditorComponent {

    title = `Портал ${currentUser.user.title.SHORT_TITLE} → Новости`;
    baseUrl = '/portal/news';

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Дата публикации')
                .fromField('timestamp')
                .denyColumnFilter()
                .withDrawer(item => moment(item.timestamp).format(formats.DATE)),

            new Column('Заголовок')
                .fromField('title'),

            new Column('Активна')
                .fromField('is_active')
                .withAlign('center')
                .withFilter('withActive', async () => {
                    return [
                        {
                            name: 'Активна',
                            uuid: 1,
                        },
                        {
                            name: 'Неактивна',
                            uuid: 0,
                        },
                    ];
                })
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_active}/>)),

            new Column('Закреплена')
                .fromField('is_pinned')
                .withAlign('center')
                .withFilter('withPinned', async () => {
                    return [
                        {
                            name: 'Закреплена',
                            uuid: 1,
                        },
                        {
                            name: 'Не закреплена',
                            uuid: 0,
                        },
                    ];
                })
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_pinned}/>)),

            ...(window.RNIS_SETTINGS.grantMO ? [
                new Column('Платформа')
                    .fromField('platform')
                    .withDrawer(item =>  item.platform ? item.platform.join('<br/>') : null )
            ] : []),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteNews(data);
    }

    async loadData(meta) {
        let response =  await this.props.getNews(meta);
        if (window.RNIS_SETTINGS.grantMO) {
            response.payload.items.forEach(news => {
                news.platform = [];
                news.mobile ? news.platform.push('мобильное приложение') : null;
                news.web ? news.platform.push('сайт') : null;
                return news
            })
        }
        return response;

    }
}
