import { rpc } from './session';
// import { DataFilter } from './grpc-api/Api_pb.js';
import {
	GetViolationCountRequest,
	DataFilter,
	DataPagination,
	OverspeedIntervalsRequest,
	GeoViolationRequest,
	OverspeedViolationRequest
} from '@contracts/proto-js/eventsapi_pb';
import { eventsService, eventsServiceMetadata } from './constants';
import moment from 'moment';

export async function getGatnViolationJournals(meta = {}) {
	meta.filters.withComponent === 'kiutr-control' ? (meta.filters.withComponent = 'kiutr') : null;
	return await rpc.request('com.rnis.gatn.action.gatn_violation_journal.list', {}, { meta });
}

export async function getGatnViolations(meta = {}) {
	return await rpc.request('com.rnis.gatn.action.gatn_violation.list', {}, { meta });
}

export function getOverspeedViolationGrpc(meta = {}) {
	const metadata = {
		...eventsServiceMetadata,
		token: meta.token
	};

	const filter = new DataFilter();
	filter.setStatenumberList([ meta.filters.withVehicle ]);
	filter.setDatefrom(moment(meta.filters.withPeriod[0]).unix());
	filter.setDateto(moment(meta.filters.withPeriod[1]).unix());

	const request = new OverspeedViolationRequest();
	request.setFilter(filter);

	return new Promise((resolve, reject) => {
		eventsService.getOverspeedViolation(request, metadata, (err, response) => {
			if (err == null) {
				resolve(response.toObject());
			} else {
				reject(err);
			}
		});
	}).then((data) => data);
}

export function getGeoIntervalsGrpc(meta = {}) {
	const metadata = {
		...eventsServiceMetadata,
		token: meta.token
	};

	const filter = new DataFilter();
	filter.setStatenumberList([ meta.filters.withVehicle ]);
	filter.setDatefrom(moment(meta.filters.withPeriod[0]).unix());
	filter.setDateto(moment(meta.filters.withPeriod[1]).unix());

	const request = new GeoViolationRequest();
	request.setFilter(filter);
	return new Promise((resolve, reject) => {
		eventsService.getGeoViolation(request, metadata, (err, response) => {
			if (err == null) {
				resolve(response.toObject());
			} else {
				reject(err);
			}
		});
	}).then((data) => data);
}

export function getGatnViolationsGrpc(meta = {}) {
	const metadata = {
		...eventsServiceMetadata,
		token: meta.token
	};

	const pagination = new DataPagination();
	pagination.setPage(meta.pagination.page);
	pagination.setLimit(meta.pagination.limit);

	const filter = new DataFilter();
	filter.setSubsystemList([ meta.filters.withComponent ]);
	filter.setDatefrom(moment(meta.filters.withPeriod[0]).unix());
	filter.setDateto(moment(meta.filters.withPeriod[1]).unix());

	if (meta.column_search && meta.column_search.length) {
		meta.column_search.forEach(({ column, value }) => {
			if (column === 'state_number') {
				filter.setStatenumberList([ value ]);
			}
		});
	}

	const request = new GetViolationCountRequest();
	request.setFilter(filter);
	request.setPagination(pagination);

	return new Promise((resolve, reject) => {
		eventsService.getViolationCount(request, metadata, (err, response) => {
			if (err == null) {
				resolve(response.toObject());
			} else {
				reject(err);
			}
		});
	}).then((data) => data);
}

export async function speedViolationsDriversSummary(component, dateFrom, dateTo) {
	return await rpc.request('com.rnis.gatn.action.speed_violation.drivers.summary', {
		date_from: dateFrom,
		date_to: dateTo,
		component
	});
}
