import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import _ from 'lodash';
import {success, error} from 'helpers/response';

export const getUserTachographs = (user_uuid, meta) => async (dispatch) => {
    try {
        let response = await api.auth.getUserTachographs(user_uuid, meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};