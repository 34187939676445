import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const GET_POSITION = 'GET_POSITION';

const initialState = new Map({
    position: Map({})
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_POSITION:
            return state
                .set('position', action.payload);
    }

    return state;
}

export const getUserPosition = (user_uuid, uuid) => async (dispatch) => {
    try {
        let response = await api.auth.getUserPositions(user_uuid, {
            filters: {
                withUuid: uuid
            }
        });

        response = response.payload.items[0];

        dispatch({
            type: GET_POSITION,
            payload: response
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createUserPosition = (userPosition) => async (dispatch) => {
    try {
        let response = await api.auth.createUserPosition(userPosition);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateUserPosition = (userPosition) => async (dispatch) => {
    try {
        let response = await api.auth.updateUserPosition(userPosition);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteUserPosition = (userPosition) => async (dispatch) => {
    try {
        let response = await api.auth.deleteUserPosition(userPosition);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};