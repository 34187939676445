import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import L from 'leaflet';
import MapComponent from "components/ui/map";
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import PointMarker from "components/ui/map/markers/point-marker";
import DirectionalPolyline from "components/ui/map/objects/directional-polyline";
import debounce from 'throttle-debounce/debounce';
import ControlPointMarker from "components/ui/map/markers/control-point-marker";
import * as alerts from "helpers/alerts";

@propTypes({
    routeVariantPoints: PropTypes.array,
    routeVariantNullRun: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    deletePoint: PropTypes.func.isRequired,
    stopPoints: PropTypes.object,
})

@defaultProps({
    routeVariantPoints: [],
    stopPoints: {},
})

export default class RouteVariantNullRunMap extends Component {

    state = {};

    pointDragDebounce = debounce(150, ::this.onPointDragEnd);

    inited = false;

    componentDidUpdate() {
        if (!this.inited) {
            this.fitBounds();
            this.inited = true;
        }
    }

    deletePoint(index) {
        this.props.deletePoint(index);
    }

    onPathClick(index, {latlng: {lat, lng}}) {
        alerts.askSelect('Выберите действие:', [
            {
                value: 'point',
                label: 'Добавить промежуточную точку',
            },
            {
                value: 'toggle_routing',
                label: 'Переключить автоматическое построение геометрии отрезка',
            },
        ], (selected) => {
            switch (selected) {
                case 'point':
                    this.addPoint(index, lat, lng, 'point');
                    break;
                case 'toggle_routing':
                    this.toggleRoutingStatus(index);
                    break;
            }
        });
    }

    toggleRoutingStatus(index) {
        let routeVariantNullRun = this.props.routeVariantNullRun;
        let point = routeVariantNullRun.points[index];
        point.is_routing_enabled = !point.is_routing_enabled;

        routeVariantNullRun.points[index] = point;
        this.props.onUpdate(routeVariantNullRun);
    }

    addPoint(index, lat, lng, point_type, name = null) {
        const from = new L.LatLng(this.props.routeVariantNullRun.points[index].latitude, this.props.routeVariantNullRun.points[index].longitude);

        let i = index + 1;
        let points = [];
        points.push({
            distance: from.distanceTo(new L.LatLng(lat, lng)),
            latitude: lat,
            longitude: lng,
            point_type,
            name,
            _uuid: Math.random(),
        });
        for (; (i < this.props.routeVariantNullRun.points.length) && (this.props.routeVariantNullRun.points[i].point_type === 'point'); i++) {
            points.push(this.props.routeVariantNullRun.points[i]);
        }
        const sorted = points.concat().sort((a, b) => {
            return a.distance - b.distance
        })

        let variant = this.props.routeVariantNullRun;
        variant.points.splice(index + 1, i - (index + 1), ...sorted);
        this.props.onUpdate(variant);
    }

    onPointDragEnd(index, {target}) {
        const {lat, lng} = target.getLatLng();

        let variant = this.props.routeVariantNullRun;
        variant.points[index].latitude = lat;
        variant.points[index].longitude = lng;

        this.props.onUpdate(variant);
    }

    render() {
        return (
            <div className="route-variant-map-container">
                <MapComponent
                    ref="map"
                >
                    {this.props.routeVariantNullRun.points && this.props.routeVariantNullRun.points.map(::this.renderPoint)}
                    {this.props.routeVariantNullRun.points && this.props.routeVariantNullRun.points.map(this.renderPath.bind(this, 'blue'))}

                    {this.props.routeVariantPoints.map(::this.renderPoint)}
                    {this.props.routeVariantPoints.map(this.renderPath.bind(this, 'red'))}
                </MapComponent>
            </div>
        );
    }

    renderPoint(point, index) {
        if (!point.latitude || !point.longitude) {
            return null;
        }

        switch (point.point_type) {
            case 'stop_point':
                return (
                    <StopPointMarker
                        key={`${index}:${point.type_uuid}`}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        latitude={point.latitude}
                        longitude={point.longitude}
                        item={this.props.stopPoints[point.type_uuid]}
                        color="green"
                        options={{
                            zIndexOffset: 100,
                        }}
                    />
                );
            case 'point':
                return (
                    <PointMarker
                        key={`${index}:${point._uuid}`}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        latitude={point.latitude}
                        longitude={point.longitude}
                        options={{
                            zIndexOffset: 100,
                        }}
                        onRightClick={this.deletePoint.bind(this, index)}
                        onDrag={this.pointDragDebounce.bind(this, index)}
                        onDragEnd={this.onPointDragEnd.bind(this, index)}
                    />
                );
            case 'control_point':
            case 'object':
                return (
                    <ControlPointMarker
                        key={`${index}:${point._uuid}`}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        latitude={point.latitude}
                        longitude={point.longitude}
                        options={{
                            zIndexOffset: 100,
                        }}
                        name={point.name}
                    />
                );
        }

        return null;
    }

    renderPath(color, point, index) {
        const path = point.path_to_the_next_point_geometry;
        if (!path || (index === this.props.routeVariantPoints.length - 1)) {
            return;
        }

        return (
            <DirectionalPolyline
                key={`${point.point_type}:${index}:${point.type_uuid}:${point._uuid}`}
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                color={color}
                dash={point.is_routing_enabled ? null : "7, 10"}
                coordinates={_.map(path.coordinates, (coord) => _.clone(coord).reverse())}
                onClick={(color === 'blue') ? this.onPathClick.bind(this, index) : () => {
                }}
                interactive={color === 'blue'}
            />
        );
    }

    fitBounds() {
        try {
            const markers = this.props.routeVariantNullRun.points && this.props.routeVariantNullRun.points.map((point) => new L.Marker([point.latitude, point.longitude]));
            const group = new L.featureGroup(markers);
            this.refs.map.getWrappedInstance().fitBounds(group.getBounds());
        } catch (e) {
        }
    }
}
