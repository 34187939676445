import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './textarea.less';

@propTypes({})

@defaultProps({})

export default class Textarea extends Component {
    render() {
        const inputProps = {};

        if (this.props.onEnter)
            inputProps.onKeyPress = ::this.handleKeyPress;

        if (this.props.name)
            inputProps.name = this.props.name;

        if (this.props.value) {
            inputProps.value = this.props.value;
        }

        if (this.props.disabled){
            inputProps.disabled = this.props.disabled;
        }

        const styleClassName = classNames('textarea_input__style', this.props.error ? 'textarea_input__style_state_wrong' : '');

        return (
            <div className="textarea_input">
                <textarea
                    ref="input"
                    className="textarea_input__control"
                    defaultValue={this.props.defaultValue}
                    type={this.props.type}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    {...inputProps} />
                <div className={styleClassName}>
                    {this.props.error ? <span className="textarea_input__style_state_msg textarea_input__style_state_msg_wrong">{this.props.error}</span> : null}
                </div>
            </div>
        );
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.props.onEnter();
        }
    }
}