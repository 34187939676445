import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import classNames from 'classnames';

import {getRouteVariant, createRouteVariant, updateRouteVariant} from "store/reducers/routes/route_variants";
import PageModal from 'components/ui/page-modal';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import * as alerts from "helpers/alerts";
import ContextTooltip from "components/ui/context-tooltip";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
    intervalMap: PropTypes.object.isRequired,
    stopPoints: PropTypes.object.isRequired,
    routeVariantStopPoints: PropTypes.array,
    onUpdate: PropTypes.func.isRequired,
})

@connect(state => ({}), {})

export default class IntervalMapMassFillEditor extends Component {

    state = {};

    getForm(item, onSubmit) {
        if (_.isEmpty(item)) {
            item = {
                all: true,
            };
        }

        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    render() {
        const title = 'Интервал остановочного пункта';

        const form = this.getForm({});

        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                    <ModalTopMenuButton
                        className="_save"
                        title="Сохранить"
                        onClick={::this.save}
                    />
                </ContextTooltip>

                <ModalTopMenuButtonsSeparator/>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}
                className={`profile-modal b-modal-${this.props.mode} interval-map-values-editor clarify-sel add-stopping-point-modal`}
            >
                {form}
            </PageModal>
        );
    }

    save() {
        if (!this.refs.form) return;

        const data = this.refs.form.getWrappedInstance().getData();

        const stopPointIndex = data.stop_point_index;
        if (stopPointIndex === null) {
            alerts.error('Не выбрана остановка');
            return;
        }
        if (data.all) {
            data.from = 0;
            data.to = 47;
        }
        let value = _.toInteger(data.value);
        if (data.base) {
            const stopPoint = this.props.routeVariantStopPoints[stopPointIndex];
            if (!stopPoint) {
                alerts.error('Не найдено базовое значение');
                return;
            }
            value = stopPoint.time_to_get_to_the_next_point;
        }

        let intervalMap = this.props.intervalMap;
        for (let i = data.from; i <= data.to; i++) {
            _.set(intervalMap, `values.${stopPointIndex}.${i}`, value);
        }

        this.props.onUpdate(intervalMap);
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        data: {},
    };

    getData() {
        return this.state.data;
    }

    async componentDidMount() {
        await this.setState({
            data: this.props.data,
            intervals: _.map(_.range(48), (index) => {
                const timeStart = _.padStart(Math.floor(index / 2), 2, '0') + ':' + ((index / 2 === Math.floor(index / 2)) ? '00' : '30');

                const timeEnd = _.padStart(Math.floor((index + 1) / 2), 2, '0') + ':' + (((index + 1) / 2 === Math.floor((index + 1) / 2)) ? '00' : '30');

                return {
                    value: index,
                    label: `${timeStart}-${timeEnd}`,
                };
            }),
        });
    }

    get(path, defaultValue = null) {
        return _.get(this.state.data, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Block title="Остановочный пункт">
                    {this.select('data.stop_point_index', _.map(this.props.routeVariantStopPoints, (routeVariantStopPoint, index) => ({
                        value: index,
                        label: _.get(this.props.stopPoints, routeVariantStopPoint.type_uuid),
                    })))}
                </Block>
                <Block size="xs" title="Нач. интервал">
                    {this.get('all') ? '00:00' : this.select('data.from', this.state.intervals)}
                </Block>
                <Block size="xs" title="Кон. интервал">
                    {this.get('all') ? '24:00' : this.select('data.to', this.state.intervals)}
                </Block>
                <Block size="xs" title="Интервал">
                    {this.get('base') ? '-' : this.textInput('data.value')}
                </Block>
                <Block size="sm">
                    {this.checkbox('data.all', 'Установить для всех интервалов', {
                        className: 'b-checkbox_posit',
                    })}
                </Block>
                <Block size="sm">
                    {this.checkbox('data.base', 'Базовые значения', {
                        className: 'b-checkbox_posit',
                    })}
                </Block>
            </div>
        );
    }
}