import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {api} from "helpers/api";
import classNames from 'classnames';
import {createContact, getContact, updateContact} from "store/reducers/mobile/contacts";
import contact_types from "dictionaries/contact_types";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getContact, createContact, updateContact})

export default class Editor extends BaseEditor {

    title = 'контакта';
    modelClass = 'App\\Model\\Contact';
    disableOutsideClick = true;

    async loadData(uuid) {
        return await this.props.getContact(uuid);
    }

    async createItem(data) {
        return await this.props.createContact(data);
    }

    async updateItem(data) {
        return await this.props.updateContact(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        contact: {},
    };

    getData() {
        return this.state.contact;
    }

    async componentDidMount() {
        await this.setState({
            contact: this.props.data,
        });
    }

    get(path, defaultValue = null) {
        return _.get(this.state.contact, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} single={true}>
                        <Block title="Тип контакта">
                            {this.select('contact.type', [
                                {
                                    label: contact_types.contact,
                                    value: 'contact',
                                },
                                {
                                    label: contact_types.forgotten,
                                    value: 'forgotten',
                                },
                            ])}
                        </Block>
                        <Block title="Наименование">
                            {this.textInput('contact.name')}
                        </Block>
                        <Block title="Значение контакта">
                            {this.textInput('contact.value')}
                        </Block>
                        <Block title="Тип значения контакта">
                            {this.select('contact.value_type', [
                                {
                                    label: 'Телефон',
                                    value: 'phone',
                                },
                                {
                                    label: 'Email',
                                    value: 'email',
                                },
                            ])}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}