import React, {Component} from 'react';
import systems from "dictionaries/systems";
import WidgetPage from '../base-components/WidgetPage';
import ConvoyRatingWidget from './widgets/ConvoyRatingWidget';
import VehicleDriverDataWidget from './widgets/VehicleDriverDataWidget';
import BacklogWidget from './widgets/BacklogWidget';
import SpeedViolationWidget from './widgets/SpeedViolationWidget';
import TransportWorkWidget from './widgets/TransportWorkWidget';
import randomColor from 'randomcolor';
import BaseAnalyticsComponent from "components/modules/analytics/base";
import UnitsTableWidget from "components/modules/analytics/passenger-transportation/widgets/UnitsTableWidget/index";
import TransportWorkChartWidget from "components/modules/analytics/passenger-transportation/widgets/TransportWorkChartWidget/index";
import VehiclesBnsoWidget from "components/modules/analytics/passenger-transportation/widgets/VehiclesBnsoWidget/index";
import VehiclesPlanWidget from "components/modules/analytics/passenger-transportation/widgets/VehiclesPlanWidget/index";
import SpeedViolationsWidget from "components/modules/analytics/passenger-transportation/widgets/SpeedViolationsWidget/index";
import SpeedViolationsLineWidget from "components/modules/analytics/passenger-transportation/widgets/SpeedViolationsLineWidget/index";
import RegularityWidget from "components/modules/analytics/passenger-transportation/widgets/RegularityWidget/index";
import ConvoyDriverScoreWidget from "components/modules/analytics/passenger-transportation/widgets/ConvoyDriverScoreWidget/index";
import AverageCarAge from 'components/modules/analytics/passenger-transportation/widgets/AverageCarAge/index';
import ZoneViolationsExitCount from "components/modules/analytics/passenger-transportation/widgets/ZoneViolationsExitCount/index";
import ZoneViolationsExitCompany from "components/modules/analytics/passenger-transportation/widgets/ZoneViolationsExitCompany/index";

const convoyDriverScoreWidgetBarColor = randomColor();
const convoyRatingWidgetBarColor = randomColor();
const speedViolationWidgetBarColor = randomColor();
const transportWorkWidgetBarColor = randomColor();

export default class ChildrenTransportation extends BaseAnalyticsComponent {

    constructor(props) {
        super(props);

        this.widgetItems = [
            { id: 'regularityWidget', name: 'Регулярность движения', widget: this.regularityWidget, checked: true, faq: true, },
            { id: 'speedViolationsLineWidget', name: 'Количество нарушений скоростного режима', widget: this.speedViolationsLineWidget, checked: true },
            { id: 'speedViolationsWidget', name: 'Нарушители скоростного режима', widget: this.speedViolationsWidget, checked: true },
            { id: 'vehiclesPlanWidget', name: 'Выполнение плана по выходу ТС', widget: this.vehiclesPlanWidget, checked: true },
            { id: 'vehiclesBnsoWidget', name: 'Оснащенность ГЛОНАСС', widget: this.vehiclesBnsoWidget, checked: true },
            { id: 'transportWorkChartWidget', name: 'Доля выполнения рейсов', widget: this.transportWorkChartWidget, checked: true },
            { id: 'unitsTableWidget', name: 'Статистика по предприятиям', widget: this.unitsTableWidget, checked: true },
            { id: 'convoyDriverScoreWidget', name: 'Баллы водителей по автоколонне', widget: this.convoyDriverScoreWidget },
            { id: 'convoyRatingWidget', name: 'Рейтинг автоколонн', widget: this.convoyRatingWidget },
            { id: 'vehicleDriverDataWidget', name: 'Данные по ТС и водителям на текущую дату', widget: VehicleDriverDataWidget },
            { id: 'backlogWidget', name: 'Отставание от графика', widget: BacklogWidget },
            //{ id: 'speedViolationWidget', name: 'Количество нарушений скоростного режима', widget: this.speedViolationWidget },
            //{ id: 'transportWorkWidget', name: 'Учет транспортной работы', widget: this.transportWorkWidget }
        ];

        if (window.RNIS_SETTINGS.CITY_TULA) {
			this.widgetItems.push({ id: 'averageCarAgeWidget', name: 'Средний возраст ТС по классам с предельно-допустимым возрастом', widget: this.averageCarAge, checked: true });
			this.widgetItems.push({ id: 'zoneViolationsExitCount', name: 'Количество выходов из геозоны', widget: this.zoneViolationsExitCount, checked: true })
            this.widgetItems.push({ id: 'zoneViolationsExitCompany', name: 'Выходы из геозоны по предприятиям', widget: this.zoneViolationsExitCompany, checked: true })

		}

        this.title = `${systems.analytics} → Перевозки детей`;
    }

    averageCarAge(props) {
		return <AverageCarAge {...props} component="children"/>;
	}

    convoyDriverScoreWidget(props) {
        return <ConvoyDriverScoreWidget {...props} component="children" barChartFillColor={convoyDriverScoreWidgetBarColor}/>
    }

    convoyRatingWidget(props) {
        return <ConvoyRatingWidget {...props} barChartFillColor={convoyRatingWidgetBarColor}/>
    }

    speedViolationWidget(props) {
        return <SpeedViolationWidget {...props} barChartFillColor={speedViolationWidgetBarColor}/>
    }

    transportWorkWidget(props) {
        return <TransportWorkWidget {...props} barChartFillColor={transportWorkWidgetBarColor}/>
    }

    regularityWidget(props) {
        return <RegularityWidget {...props} component="children"/>
    }

    speedViolationsLineWidget(props) {
        return <SpeedViolationsLineWidget {...props} component="children"/>
    }

    speedViolationsWidget(props) {
        return <SpeedViolationsWidget {...props} component="children"/>
    }

    vehiclesPlanWidget(props) {
        return <VehiclesPlanWidget {...props} component="children"/>
    }

    vehiclesBnsoWidget(props) {
        return <VehiclesBnsoWidget {...props} component="children"/>
    }

    transportWorkChartWidget(props) {
        return <TransportWorkChartWidget {...props} component="children"/>
    }

    unitsTableWidget(props) {
        return <UnitsTableWidget {...props} component="children"/>
    }

    zoneViolationsExitCount(props) {
        return <ZoneViolationsExitCount {...props} component="children"/>
    }

    zoneViolationsExitCompany(props) {
        return <ZoneViolationsExitCompany {...props} component="children"/>
    }

    render() {
        return (
            <WidgetPage cacheKey="children"
                        title={this.title}
                        widgetItems={this.widgetItems}
            />
        );
    }
}
