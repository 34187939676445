import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {createObject, getObject, updateObject} from "store/reducers/garbage/objects";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUserGeoObjects} from "store/reducers/user-map-objects/object_editor";
import MapComponent from "components/ui/map";
import HistoryPointMarker from "components/ui/map/markers/history-point-marker";
import GarbagePointMarker from "components/ui/map/markers/garbage-point-marker";
import MapGeojson from "../../../ui/map/base/geojson";
import L from "leaflet";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getObject, createObject, updateObject})

export default class Editor extends BaseEditor {

    title = 'объекта ТКО';
    modelClass = 'App\\Model\\Object';

    async loadData(uuid) {
        return await this.props.getObject(uuid);
    }

    async createItem(data) {
        return await this.props.createObject(data);
    }

    async updateItem(data) {
        return await this.props.updateObject(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getDictionaryList, getUserGeoObjects}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        object: {},
        garbage_object_types: [],
        communal_municipalities: [],
        userGeoObject: {},
        user_geo_object_list: []
    };

    getData() {
        return this.state.object;
    }

    async componentWillMount() {
        await this.setState({
            object: this.props.data,
        });

        this.loadDictionaries([
            'garbage_object_types',
            'communal_municipalities',
        ]);
    }

    get(path, defaultValue = null) {
        return _.get(this.state.object, path, defaultValue);
    }

    render() {
        if( this.state.object.type || this.state.object.user_geo_object_uuid) {
        }
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} title="Основные сведения">
                        <Block title="Наименование">
                            {this.textInput('object.name')}
                        </Block>
                        <Block title="Адрес">
                            {this.textInput('object.address')}
                        </Block>
                        <Block title="Муниципальное образование">
                            {this.select('object.communal_municipality_uuid', this.state.communal_municipalities)}
                        </Block>
                        <Block title="Тип объекта">
                            {this.select('object.garbage_object_type_uuid', this.state.garbage_object_types)}
                        </Block>
                        <Block title="Тип геометрии">
                            {this.select('object.type', [
                                {
                                    value: 'coordinates',
                                    label: 'Координаты',
                                },
                                {
                                    value: 'geozone',
                                    label: 'Пользовательский объект',
                                },
                            ])}
                        </Block>
                        {(this.get('type') === 'geozone') ? (
                            <Block title="Геозона">
                                {this.selectAsync('object.user_geo_object_uuid', ::this.loadGeozones, {
                                    onChange: ::this.onChangeItem
                                })}
                            </Block>
                        ) : null}
                        {(this.get('type') === 'coordinates') ? ([
                            <Block key="latitude" title="Широта">
                                {this.maskInput('object.latitude', '99.999999')}
                            </Block>,
                            <Block key="longitude" title="Долгота">
                                {this.maskInput('object.longitude', '99.999999')}
                            </Block>,
                        ]) : null}
                        {(_.get(_.find(this.state.garbage_object_types, {value: this.get('garbage_object_type_uuid')}), 'label') === 'Контейнерные площадки') ? ([
                            <Block key="settlement" title="Поселение">
                                {this.textInput('object.settlement')}
                            </Block>,
                            <Block key="kind" title="Вид площадки">
                                {this.textInput('object.kind')}
                            </Block>,
                            <Block key="container_count" title="Количество контейнеров">
                                {this.textInput('object.container_count')}
                            </Block>,
                            <Block key="container_capacity" title="Вместимость каждого контейнера">
                                {this.textInput('object.container_capacity')}
                            </Block>,
                        ]) : ([
                            <Block key="capacity" title="Емкость">
                                {this.textInput('object.capacity')}
                            </Block>,
                            <Block key="contact" title="Контактная информация">
                                {this.textInput('object.contact')}
                            </Block>,
                            <Block key="license" title="Лицензия">
                                {this.textInput('object.license')}
                            </Block>,
                        ])}
                    </AccordionItem>
                    <AccordionItem title="Карта">
                        <div className="map-container">
                            <MapComponent
                                ref="map"
                                onClick={::this.onMapClick}
                                onDblClick={::this.onDblClick}
                                showLayers={false}
                            >
                                {this.refs.map && this.state.object.type !== "geozone"? ([
                                    (this.get('latitude') && this.get('longitude')) ? (
                                        <GarbagePointMarker
                                            key="stop-point"
                                            ref={::this.panToStopPoint}
                                            map={this.refs.map}
                                            leafletMap={this.refs.map.getWrappedInstance().map}
                                            latitude={this.get('latitude')}
                                            longitude={this.get('longitude')}
                                            type={this.getMarkerType()}
                                            options={{
                                                zIndexOffset: 100,
                                            }}
                                        />
                                    ) : null,
                                ]) : null}
                                {(this.refs.map && this.state.userGeoObject.geometry) ? (
                                    <MapGeojson
                                        map={this.refs.map}
                                        ref={() => {
                                            try {
                                                const group = L.geoJSON(this.state.userGeoObject.geometry);
                                                this.refs.map.getWrappedInstance().fitBounds(group.getBounds());
                                            } catch (e) {
                                            }
                                        }}
                                        leafletMap={this.refs.map.getWrappedInstance().map}
                                        geometry={this.state.userGeoObject.geometry}
                                    />
                                ) : null}
                            </MapComponent>
                        </div>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    getMarkerType() {
        const type = _.get(_.find(this.state.garbage_object_types, {value: this.get('garbage_object_type_uuid')}), 'label');
        switch (type) {
            case 'Контейнерные площадки':
                return 'kp';
            case 'Объекты перегрузки':
                return 'op';
            case 'Объекты размещения':
                return 'opp';
            case 'Объекты сортировки':
                return 'os';
        }
    }

    panToStopPoint() {
        this.refs.map && this.refs.map.getWrappedInstance().panTo([
            this.get('latitude'),
            this.get('longitude'),
        ]);
        this.refs.map && this.refs.map.getWrappedInstance().setZoom(17);
    }

    onMapClick(latitude, longitude) {
        this.setValue('object.latitude', _.round(latitude, 6));
        this.setValue('object.longitude', _.round(longitude, 6));
    }

    onDblClick(e) {
        if (this.state.object.type !== 'coordinates') return;
        let latitude = e.latlng.lat;
        let longitude = e.latlng.lng;
        this.setValue('object.latitude', _.round(latitude, 6));
        this.setValue('object.longitude', _.round(longitude, 6));
    }


    async onChangeItem(e) {
        const value = e ? e.value : null;
        this.setValue('object.user_geo_object_uuid', value);
            await this.setState({
                userGeoObject: value ? _.find(this.state.user_geo_object_list, {uuid: value}) : {}
            })
            //this.setValue('userGeoObject', _.find(this.state.user_geo_object_list, {uuid: value}))

    }

    async loadGeozones(input, callback) {
        if (!input) {
            input = this.get('user_geo_object_uuid');
        }
        const result = await this.props.getUserGeoObjects({
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
        });

        if (result.isOk) {
            await this.setState({
                userGeoObject: result.payload.items.length === 1 ? result.payload.items[0] : {},
                user_geo_object_list: result.payload.items,
            });
            callback(null, {
                options: result.payload.items.map(item => ({
                    value: item.uuid,
                    label: item.title,
                })),
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }
}
