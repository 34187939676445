import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import TableContainer from "components/ui/Table/Container/TableContainer";
import './runs.less';
import Dropdown, {DropdownContent, DropdownTrigger} from "react-simple-dropdown";
import Button from "components/ui/button";
import runs from "dictionaries/runs";
import {formatMileage} from "helpers/math";
import PageModal from 'components/ui/page-modal';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import Block from "components/ui/form/block";
import Select, {SelectAsync} from 'components/ui/select';
import Input from "components/ui/form/input";
import * as alerts from "helpers/alerts";
import {connect} from "react-redux";
import {getRouteVariantNullRuns} from "store/reducers/kiutr/route_variant_null_run";
import {getRoutes} from "store/reducers/routes/route_editor";
import moment from "moment";
import formats from "dictionaries/formats";
import {Link} from "react-router";

@propTypes({
    turn: PropTypes.object.isRequired,
    addRun: PropTypes.func.isRequired,
    addRunReal: PropTypes.func.isRequired,
    deleteRun: PropTypes.func.isRequired,
    routeVariants: PropTypes.array,
    isBothSide: PropTypes.bool.isRequired,
    updateRun: PropTypes.func.isRequired,
    scheduleSwitches: PropTypes.array,
})

@defaultProps({
    routeVariants: [],
    scheduleSwitches: [],
})

@connect(state => ({}), {getRouteVariantNullRuns, getRoutes})

export default class KiutrRouteScheduleTurnRunsComponent extends Component {
    types = runs;

    state = {
        addRunSelectActive: false,
        run_type: null,
        run_time: null,
        index: null,
        run_distance: null,
        route_uuid: null,
        route_variant_null_run: {},
        route_variant_null_run_uuid: null,
        route_variant_uuid: null,
        routeVariantNullRuns: [],
        editType: null,
        editIndex: null,
    };

    render() {
        return (
            <div className="page-block schedule-runs">
                <div className="page-block__caption">Структура выхода</div>
                <Button size="md" color="red" shadow="red" text="Добавить" onClick={::this.requestRunAdd}/>
                <TableContainer>
                    <div className="Table">
                        <table className="b-table b-table-no-hover">
                            <thead>
                            <tr className="wrap-normal">
                                <th width="50px">№</th>
                                <th width="150px">Тип рейса</th>
                                <th width="100px">Расстояние, км</th>
                                <th width="100px">Время, мин</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.turn.runs.map(::this.renderRun)}
                            <tr className="bold">
                                <td/>
                                <td>Итого</td>
                                <td>{formatMileage(_.sumBy(this.props.turn.runs, 'distance') / 1000)}</td>
                                <td>{_.sumBy(this.props.turn.runs, 'time')}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </TableContainer>
                {this.state.addRunSelectActive ? (
                    this.renderRunSelectModal()
                ) : null}
            </div>
        );
    }

    async loadRouteVariantNullRuns() {
        const response = await this.props.getRouteVariantNullRuns({
            filters: {
                withRouteVariants: _.map(this.props.routeVariants, 'uuid'),
            },
        });

        if (response.isOk) {
            this.setState({
                routeVariantNullRuns: _.sortBy(_.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                    distance: item.distance,
                    time: item.time,
                })), 'label'),
            });
        } else {
            response.showErrors();
        }
    }

    async requestRunAdd() {
        await this.loadRouteVariantNullRuns();
        this.setState({
            addRunSelectActive: true,
            index: (this.props.turn.runs || []).length + 1,
        });
    }

    hideRunAdd() {
        this.setState({
            addRunSelectActive: false,
            run_type: null,
            run_time: null,
            run_distance: null,
        });
    }

    addRun() {
        const {timeInput, distanceInput} = this.getRunFields();

        const type = this.state.run_type;
        const time = timeInput ? _.toInteger(this.state.run_time) : 0;
        const distance = distanceInput ? _.toInteger(this.state.run_distance) : 0;

        if (!type) {
            alerts.error('Не выбран тип рейса');
            return;
        }

        if (type === 'null') {
            if (!this.state.route_variant_null_run_uuid) {
                alerts.error('Не выбран нулевой рейс');
                return;
            }
            this.props.addRunReal({
                type,
                distance: this.state.route_variant_null_run.distance,
                time: this.state.route_variant_null_run.time,
                route_variant_null_run_uuid: this.state.route_variant_null_run_uuid,
            }, this.state.index);

            this.hideRunAdd();
        } else if (type === 'switch_out') {
            this.props.addRun(type, time, distance, this.state.index, null, this.state.route_uuid);

            this.hideRunAdd();
        } else {
            this.props.addRun(type, time, distance, this.state.index, this.state.route_variant_uuid);

            this.hideRunAdd();
        }
    }

    findSwitch(run) {
        return _.find(this.props.scheduleSwitches, {uuid: run.schedule_switch_uuid});
    }

    findSwitchIn(run) {
        return _.find(this.props.scheduleSwitchesIn, {uuid: run.schedule_switch_uuid});
    }

    renderRun(run, index) {
        let comment;
        if (run.type === 'production_forward' || run.type === 'production_reverse') {
            comment = `"${_.get(_.find(this.props.routeVariants, {uuid: run.route_variant_uuid}), 'name') || '-'}"`;
            if (run.interval_map_uuid) {
                comment = (
                    <span>
                        {comment}
                        <br/>
                        <Link
                            to={`/${this.props.component}/routes/${this.props.routeUuid}/variants/${run.route_variant_uuid}/intervals/${run.interval_map_uuid}`}>Карта
                            инт.: "{run.interval_map_name}"</Link>
                    </span>
                );
            }
        }
        if (run.type === 'switch_out') {
            const switchObject = this.findSwitch(run);
            if (switchObject) {
                const route = switchObject.to_route_uuid;
                comment = `${this.props.scheduleSwitchesFromRelated.getReact(route) || '-'}`;
            }
        }
        let hasSwitchError = false;
        if (run.type === 'switch_in') {
            const switchObject = this.findSwitchIn(run);
            if (switchObject) {
                const switchTurn = _.find(_.get(switchObject, 'schedule.turns', []), (item) => {
                    return _.toInteger(item.number) === _.toInteger(switchObject.schedule_turn_number)
                });
                const switchRun = _.find(_.get(switchTurn, 'runs', []), {schedule_switch_uuid: run.schedule_switch_uuid});
                if (switchRun) {
                    if (moment(switchRun.start_time, formats.TIME).add(switchRun.time, 'minutes').isAfter(moment(run.start_time, formats.TIME))) {
                        hasSwitchError = true;
                    }
                }

                const route = switchObject.from_route_uuid;
                comment = `${this.props.scheduleSwitchesRelated.getReact(route) || '-'} | Выход ${switchObject.schedule_turn_number}`;
            }
        }
        return (
            <tr key={index} className={hasSwitchError ? 'has-violation' : ''}>
                <td>
                    {index + 1}
                    <br/>
                    <a href="javascript:void(0)" onClick={this.onDelete.bind(this, index)}>x</a>
                </td>
                <td>{this.types[run.type]} <br/><i>{comment}</i></td>
                <td onDoubleClick={this.toggleEdit.bind(this, 'distance', index)}>
                    {((this.state.editType === 'distance') && (this.state.editIndex === index)) ? (
                        <Input
                            value={run.distance || 0}
                            onChange={::this.onEditInputChange}
                            onKeyPress={::this.onEditInputKeyPress}
                        />
                    ) : formatMileage(run.distance / 1000)}
                </td>
                <td onDoubleClick={this.toggleEdit.bind(this, 'time', index)}>
                    {((this.state.editType === 'time') && (this.state.editIndex === index)) ? (
                        <Input
                            value={run.time || 0}
                            onChange={::this.onEditInputChange}
                            onKeyPress={::this.onEditInputKeyPress}
                        />
                    ) : run.time}
                    <br/>
                    <i>({run.start_time})</i>
                </td>
            </tr>
        );
    }

    async onEditInputChange({target: {value}}) {
        let run = this.props.turn.runs[this.state.editIndex];

        run[this.state.editType] = _.toInteger(value);

        this.props.updateRun(run, this.state.editIndex);
    }

    onEditInputKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({
                editType: null,
                editIndex: null,
            });
        }
    }

    toggleEdit(type, index) {
        if ((this.state.editType === type) && (this.state.editIndex === index)) {
            this.setState({
                editType: null,
                editIndex: null,
            });
        } else {
            const run = this.props.turn.runs[index];
            if ((run.type === 'production_forward') || (run.type === 'production_reverse')) {
                return;
            }
            this.setState({
                editType: type,
                editIndex: index,
            });
        }
    }

    onDelete(index) {
        this.props.deleteRun(index);
    }

    onRunTypeChange(e) {
        const value = e ? e.value : null;

        this.setState({
            run_type: value,
        });
    }

    onRouteVariantNullRunUuidChange(e) {
        const value = e ? e.value : null;

        this.setState({
            route_variant_null_run_uuid: value,
            route_variant_null_run: e,
        });
    }

    onRouteUuidChange(e) {
        const value = e ? e.value : null;

        this.setState({
            route_uuid: value,
        });
    }

    async loadRoutes(input, callback) {
        let result;
        if (!input) {
            callback(null, {
                options: [],
                complete: false,
            });
        } else {
            result = await this.props.getRoutes({
                search: input,
                pagination: {
                    page: 1,
                    limit: 20,
                },
            });
        }

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: `${i.number} ${i.title}`,
                    value: i.uuid,
                })), 'label'),
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    onRouteVariantUuidChange(e) {
        const value = e ? e.value : null;

        this.setState({
            route_variant_uuid: value,
        });
    }

    onTimeChange({target: {value}}) {
        this.setState({
            run_time: value,
        });
    }

    onIndexChange({target: {value}}) {
        this.setState({
            index: value,
        });
    }

    onDistanceChange({target: {value}}) {
        this.setState({
            run_distance: value,
        });
    }

    getRunFields() {
        let timeInput = false;
        let distanceInput = false;

        switch (this.state.run_type) {
            // только время
            case 'parking':
            case 'dinner':
            case 'settling':
            case 'gap':
            case 'reshift':
                timeInput = true;
                break;
            case 'refill':
            case 'techno':
            case 'switch_out':
                timeInput = true;
                distanceInput = true;
                break;
        }

        return {
            timeInput,
            distanceInput,
        };
    }

    getLastRun() {
        return _.last(this.props.turn.runs);
    }

    getLastRunType() {
        return _.get(this.getLastRun(), 'type');
    }

    isLastRunProductive() {
        const lastRunType = this.getLastRunType();
        return (lastRunType === 'production_forward') || (lastRunType === 'production_reverse')
    }

    renderRunSelectModal() {
        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                    <ModalTopMenuButton
                        className="_save"
                        title="Сохранить"
                        onClick={::this.addRun}
                    />
                </ContextTooltip>

                <ModalTopMenuButtonsSeparator/>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.hideRunAdd}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        const {timeInput, distanceInput} = this.getRunFields();
        const routeVariantNullRunInput = this.state.run_type === 'null';
        const routeInput = this.state.run_type === 'switch_out';

        const routeVariantInput = (this.state.run_type === 'production_forward') || (this.state.run_type === 'production_reverse');

        let types = _.map(this.types, (text, type) => ({
            value: type,
            label: text,
        }));
        if (!this.props.isBothSide) {
            types = _.filter(types, (type) => {
                return type.value !== 'production_reverse';
            });
        }

        return (
            <PageModal
                header={{title: 'Добавление рейса', buttons}}
                className="b-modal-route add-route-schedule-modal b-modal_centered"
                headerClassName="b-modal__header_dark"
            >
                <Block size="xl" title="Тип рейса">
                    <Select
                        value={this.state.run_type}
                        options={types}
                        onChange={::this.onRunTypeChange}
                    />
                </Block>
                <Block size="md" title="Позиция">
                    <Input
                        value={this.state.index}
                        onChange={::this.onIndexChange}
                    />
                </Block>
                {routeVariantNullRunInput ? (
                    <Block size="lg" title="Нулевой рейс">
                        <Select
                            value={this.state.route_variant_null_run_uuid}
                            options={this.state.routeVariantNullRuns}
                            onChange={::this.onRouteVariantNullRunUuidChange}
                        />
                    </Block>
                ) : null}
                {routeInput ? (
                    <Block size="lg" title="Переключение на маршрут">
                        <SelectAsync
                            value={this.state.route_uuid}
                            onChange={::this.onRouteUuidChange}
                            loadOptions={::this.loadRoutes}
                        />
                    </Block>
                ) : null}
                {routeVariantInput ? (
                    <Block size="lg" title="Вариант движения">
                        <Select
                            value={this.state.route_variant_uuid}
                            options={_.map(_.filter(this.props.routeVariants, item => !item.is_stop_point_list), (routeVariant) => ({
                                value: routeVariant.uuid,
                                label: `${routeVariant.name} (${routeVariant.comment})`,
                            }))}
                            onChange={::this.onRouteVariantUuidChange}
                        />
                    </Block>
                ) : null}
                {distanceInput ? (
                    <Block size="md" title="Расстояние, м">
                        <Input
                            value={this.state.run_distance}
                            onChange={::this.onDistanceChange}
                        />
                    </Block>
                ) : null}
                {timeInput ? (
                    <Block size="md" title="Время, мин">
                        <Input
                            value={this.state.run_time}
                            onChange={::this.onTimeChange}
                        />
                    </Block>
                ) : null}
            </PageModal>
        );
    }
}