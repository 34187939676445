import React from 'react';
import TableContainer from 'components/ui/Table/Container/TableContainer';

const columnsHeaders = [
	{
		title: ' Вариант движения',
		size: 9
	},
	{
		title: 'Направление движения',
		size: 9
	},
	{
		title: 'L общ. (км)',
		size: 3
	},
	{
		title: 'T общ. (мин)',
		size: 3
	},
	{
		title: 'V cp. (км/ч)',
		size: 3
	},
	{
		title: 'T нач.',
		size: 3
	},
	{
		title: 'T ок.',
		size: 3
	}
];
export const NormativeTable = ({ data }) => {
	return (
		<TableContainer>
			<div className="Table">
				<table className="b-table b-table-thead-no-hover">
					<thead>
						<tr className="border-top-bold-2 border-bottom-bold border-right-bold-2">
							{columnsHeaders.map((column, i) => (
								<th key={i} className={`border-left-bold-2 border-bottom-bold-2 ${i === 6 && 'border-right-bold-2'}`} rowSpan={`${column.size}`}>
									{column.title}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data &&
							data.map((line, index) => (
								<tr className='border-bottom-bold-2' key={index}>
									{line.map((line, i) => (
										<td
											className={`border-left-bold-2 border-bottom-bold-2 ${i === 6 && 'border-right-bold-2'}`}
											key={i}
										>
											{line}
										</td>
									))}
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</TableContainer>
	);
};
