import React, {Component} from "react";
import PropTypes from "prop-types";
import {propTypes} from "react-props-decorators";
import {connect} from "react-redux";
import {Link} from "react-router";
import {List} from 'immutable';

import {getDictionaryStructure} from "store/reducers/dictionaries/structure";
import _ from 'lodash';
import $ from 'jquery';
import currentUser from 'helpers/current-user';

import "./structure.less";
import systems from "dictionaries/systems";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import Column from "components/ui/column";
import IconButton from "components/ui/icon-button";
import classNames from 'classnames';
import ContextTooltip from "components/ui/context-tooltip";
import GlobalLoaderComponent from "components/ui/global-loader";
import Page from 'components/ui/page';
import {EntityList} from "helpers/entity";
import TableComponent from "components/ui/Table/Component/TableComponent";
import {component_mapper} from "helpers/component_mapper";

@connect(state => ({}), {getDictionaryStructure})

export default class DictionaryStructure extends BaseTableWithEditorComponent {

	constructor(props) {
		super(props);

		Object.assign(this.state, {
			system: null,
			refresh: false,
			subsystems: null,
		});
	}

	async componentWillUpdate(props, state) {
		if (props.params.system !== state.system) {
			await this.setState({
				system: props.params.system,
				refresh: true,
				columns: List(this.getColumns(props)),
				related: new EntityList(),
			});
			state.system && this.reload();
			this.setState({
				refresh: false,
			});
		}
	}

	render() {
		if (this.state.refresh) {
			return (
				<Page title={this.getTitle()}
					  pageId="BaseTableWithEditor"
					  headerActions={this.renderHeaderActions()}
					  headerContents={this.renderHeaderContents()}>
					<GlobalLoaderComponent/>
				</Page>
			);
		}

		return super.render();
	}

	componentDidMount() {

		this.forceUpdate();

		$('body').on('click', '.internal-link', (e) => {
			const href = $(e.target).data('href');
			this.props.router.push(href);
		});
	}

	renderTable() {
		const className = classNames({
			'show-table-filters': this.state.showTableFilters,
		});

		return (
			<div className={className}>
				<TableComponent
					ref="table"
					select={this.select}
					columns={this.state.columns}
					loadCallback={::this.loadCallbackMiddleware}
					onColsReordered={::this.onColsReordered}
					onCheck={::this.selectedRowsRecalc}
					checkCache={::this.checkCache}
					query={this.state.query}
					showTableSearchFooter={this.state.showTableSearchFooter}
					onColumnFilterChange={::this.onColumnFilterChange}
					onSettingsLoad={::this.onSettingsLoad}
					onDblClick={::this.onDblClick}
				/>
			</div>
		);
	}

	async loadData(meta) {
		console.log('loadData')
		if (!systems[this.state.system]) {
			this.props.router.push(`/error-404`);
			return;
		}

		let response = await this.props.getDictionaryStructure();
		if (response.isOk) {

			let items = [];

			if (this.state.system === 'operator') {
				_.each(response.payload.structure.sections, (section) => {

					const component = section.title;

					const sectionItems = this.getSectionItems(section, component);
					items = _.concat(items, sectionItems);

					_.each(section.dictionaries, (dictionary) => {
						items.push({
							title: dictionary.title,
							href: `/${this.state.system}/dictionaries/${dictionary.key}?component=${component}`,
							component,
						});
					});
				});
			} else {
				const section = _.find(response.payload.structure.sections, {title: component_mapper(this.state.system)}) || {
					sections: [],
					dictionaries: [],
				};
				const sectionItems = this.getSectionItems(section);
				items = _.concat(items, sectionItems);

				_.each(section.dictionaries, (dictionary) => {
					items.push({
						title: this.getDictionaryTitle(dictionary),
						href: `${this.getBaseUrl()}/${dictionary.key}`,
					});
				});
			}


			let other = [];

			switch (this.state.system) {
				case 'kiutr':
					other = _.concat(other, this.getKiutrDictionaries());
					break;

				case 'kiutr-control':
					other = _.concat(other, this.getKiutrControlDictionaries());
					break;

				case 'ems':
					other = _.concat(other, this.getEmsDictionaries());
					break;

				case 'children':
					other = _.concat(other, this.getChildrenDictionaries());
					break;

				case 'control':
					other = _.concat(other, this.getControlDictionaries());
					break;

				case 'taxi':
					other = _.concat(other, this.getTaxiDictionaries());
					break;

				case 'agricultural':
					other = _.concat(other, this.getAgriculturalDictionaries());
					break;

				case 'medic':
					other = _.concat(other, this.getMedicDictionaries());
					break;

				case 'timber':
					other = _.concat(other, this.getTimberDictionaries());
					break;

				case 'dangerous':
					other = _.concat(other, this.getDangerousDictionaries());
					break;
				case 'medicine':
					other = _.concat(other, this.getMedicineDictionaries());
					break;
				case 'forestry':
					other = _.concat(other, this.getForestryDictionaries());
					break;
				case 'farming':
					other = _.concat(other, this.getFarmingDictionaries());
					break;
				case 'official':
					other = _.concat(other, this.getOfficialDictionaries());
					break;
				case 'emercom':
					other = _.concat(other, this.getEmercomDictionaries());
					break;
				case 'road':
					other = _.concat(other, this.getRoadDictionaries());
					break;

				case 'utility':
					other = _.concat(other, this.getUtilityDictionaries());
					break;

				case 'garbage':
					other = _.concat(other, this.getGarbageDictionaries());
					break;

				case 'communal':
					other = _.concat(other, this.getCommunalDictionaries());
					break;

				case 'commdept':
					other = _.concat(other, this.getCommdeptDictionaries());
					break;

				case 'sbdd':
					other = _.concat(other, this.getSbddDictionaries());
					break;

				case 'operator':
					other = _.concat(other, this.getKiutrDictionaries());
					other = _.concat(other, this.getKiutrControlDictionaries());
					other = _.concat(other, this.getChildrenDictionaries());
					other = _.concat(other, this.getControlDictionaries());
					other = _.concat(other, this.getTaxiDictionaries());
					other = _.concat(other, this.getAgriculturalDictionaries());
					other = _.concat(other, this.getMedicDictionaries());
					other = _.concat(other, this.getTimberDictionaries());
					other = _.concat(other, this.getRoadDictionaries());
					other = _.concat(other, this.getUtilityDictionaries());
					other = _.concat(other, this.getGarbageDictionaries());
					other = _.concat(other, this.getCommunalDictionaries());
					other = _.concat(other, this.getEmsDictionaries());

					other = _.concat(other, this.getOperatorDictionaries());
					if(window.RNIS_SETTINGS.dictionary_sbdd) {
						other = _.concat(other, this.getSbddDictionaries())
					}
					break;
			}

			if (other.length > 0) {
				_.each(other, (item) => {
					items.push(item);
				});
			}

			if (window.RNIS_SETTINGS.hide_all_dictionaries_except_road && !window.location.hostname.includes("road/dictionaries")) { // hide dictionaries except road murmansk
				const showItemsWithTitle = ["Муниципальные образования", "Индикаторы БНСО", "Предприятия"];
				items = items.filter((item) => !item.component || item.component === 'road' || item.component === 'operator' && showItemsWithTitle.includes(item.title));
			}

			if (window.RNIS_SETTINGS.CITY_MURMANSK) {
				const excludes = ['Формулы норм расхода топлива', 'Категории работ']
				items = items.filter(item => !excludes.includes(item.title))
				items = items.filter(item => !window.RNIS_SETTINGS.HIDDENROADDICTIONARIES.includes(item.title))

				if (this.state.system === 'operator') {
					items = items.filter(item => item.component !== 'operator')
				}
			}

			const subsystems = _.uniq(items.map(system => system.component));
			this.setState({
				subsystems: subsystems,
			});
			const componentFilter = meta.filters.withComponent || [];
			if (componentFilter.length > 0) {
				items = _.filter(items, (item) => {
					return _.indexOf(componentFilter, item.component) !== -1;
				});
			}
			const search = (meta.search || '').toLowerCase();
			if (search) {
				items = _.filter(items, (item) => {
					return item.title.toLowerCase().indexOf(search) !== -1;
				});
			}
			_.each(meta.column_search || [], (columnSearch) => {
				let columnSearchValue = columnSearch.value.toLowerCase();
				items = _.filter(items, (item) => {
					return item[columnSearch.column].toLowerCase().indexOf(columnSearchValue) !== -1;
				});
			});

			items = _.orderBy(items, meta.order.column, meta.order.direction);

			items = items.filter((item) => !(window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[item.component] || {}).hideForRoles)

			items = items.filter(
				(item) => !(window.RNIS_SETTINGS.HIDDENROADDICTIONARIES || []).find((value) => value === item.title)
			);

			const total = items.length;
			response.payload.items = items.splice((meta.pagination.page - 1) * meta.pagination.limit, meta.pagination.limit);
			response.data.headers.meta.pagination = {
				total,
			};
		}
		return response;
	}

	getDictionaryTitle(dictionary) {
		if ((this.state.system === 'garbage') && (dictionary.key === 'vehicle_models')) {
			return 'Типы ТС';
		}
		return dictionary.title;
	}

	async onDblClick(data) {
		if (data && data.href) {
			this.props.router.push(data.href);
		}
	}

	getSectionItems(section, component = null) {
		let items = [];

		_.each(section.sections, (_section) => {
			if (((_section.sections || []).length > 0) || ((_section.dictionaries || []).length > 0)) {
				items = _.concat(items, this.getSectionItems(_section, component));

				_.each(_section.dictionaries, (dictionary) => {
					items.push({
						title: dictionary.title,
						href: `/${this.state.system}/dictionaries/${dictionary.key}${component ? `?component=${component}` : ''}`,
						component,
					});
				});
			}
		});

		return items;
	}

	getTitle() {
		if (this.state.system === 'operator') {
			return `${window.RNIS_SETTINGS.operator_title || systems[this.state.system]} → Справочники`;
		}
		return `${systems[this.state.system]} → Справочники`;
	}

	getBaseUrl() {
		return `/${this.state.system}/dictionaries`;
	}

	getEditor() {
		return null;
	}

	filterSystems(systems) {
		if (!window.RNIS_SETTINGS.SUBSYSTEMMENU || !window.RNIS_SETTINGS.SUBSYSTEMMENU.length) return systems;
		let newSystems =  {};
		window.RNIS_SETTINGS.SUBSYSTEMMENU.forEach(el => {
			newSystems[el.id] = el.name;
		});
		return newSystems;
	}

	getColumns(props = null) {
		let newSystems = this.filterSystems(systems);

		const showedSystems = {}
		Object.keys(systems).forEach((systemKey) => {
			if (!window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[systemKey]) {
				showedSystems[systemKey] = systems[systemKey]
			} else if (!window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[systemKey].hideForRoles) {
				showedSystems[systemKey] = window.RNIS_SETTINGS.DICTIONARIESSYSTEMS[systemKey].name || systems[systemKey]
			}
		})

		props = props || this.props;
		if (props.params.system === 'operator') {
			return this.prepareColumns([

				new Column('Наименование')
					.fromField('title'),

				new Column('Подсистема')
					.fromField('component')
					.withDrawer((item) => showedSystems[item.component])
					.denyColumnFilter()
					.withFilter('withComponent', async () => {
						const filteredSystems = {};
						if (this.state.subsystems) {
							this.state.subsystems.forEach(item => {
								if (showedSystems[item]) {
									filteredSystems[item] = showedSystems[item]
								}
							})
						}
						const filterColumnSybsystems = Object.keys(filteredSystems) ? filteredSystems : showedSystems;
						return _.map(filterColumnSybsystems, (title, system) => ({
							uuid: system,
							name: title,
						}));
					}),

			]);
		} else {
			return this.prepareColumns([

				new Column('Наименование')
					.fromField('title')
					.denyOrder()
					.denyColumnFilter(),

			]);
		}
	}


	getCreateButton() {
		return null;
	}

	getFilterButton() {
		return null;
	}

	renderHeaderActions() {
		return [
			<ContextTooltip key="base-table-list.delete" code="base-table-list.delete" default="Удалить">
				<IconButton icon="basket" disabled={true}
							onClick={::this.deleteSelected}/>
			</ContextTooltip>,
		];
	}

	async deleteItem(data) {
		return null;
	}

	getSbddDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'sbdd')) {
			//TODO: нужны права юзеру для компонента sbdd
		}

		result.push({
			title: 'ТС',
			href: `/sbdd/vehicles/vehicles`,
			component: 'sbdd',
		});

		result.push({
			title: 'БНСО',
			href: `/sbdd/vehicles/bnso`,
			component: 'sbdd',
		});

		return result;
	}

	getKiutrDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'kiutr')) {
			result.push({
				title: 'Предприятия',
				href: `/kiutr/units/units`,
				component: 'kiutr',
			});
		}
		if (currentUser.can('com.rnis.geo.territorial_entity', 'read', 'kiutr')) {
			result.push({
				title: 'Территориальные образования',
				href: `/kiutr/territorial_entities/tree`,
				component: 'kiutr',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'kiutr')) {
			result.push({
				title: 'ТС',
				href: `/kiutr/vehicles/vehicles`,
				component: 'kiutr',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'kiutr')) {
			result.push({
				title: 'БНСО',
				href: `/kiutr/vehicles/bnso`,
				component: 'kiutr',
			});
		}
		if (currentUser.can('com.rnis.geo.stop_point')) {
			result.push({
				title: 'Остановки',
				href: '/kiutr/stop-points',
				component: 'kiutr',
			});
		}
		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/kiutr/map',
				component: 'kiutr',
			});
		}

		return result;
	}

	getEmsDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'ems')) {
			result.push({
				title: 'Предприятия',
				href: `/ems/units/units`,
				component: 'ems',
			});
		}
		if (currentUser.can('com.rnis.geo.territorial_entity', 'read', 'ems')) {
			result.push({
				title: 'Территориальные образования',
				href: `/ems/territorial_entities/tree`,
				component: 'ems',
			});
		}
		if (currentUser.can('com.rnis.geo.stop_point', 'read', 'ems')) {
			result.push({
				title: 'Остановки',
				href: '/ems/stop-points',
				component: 'ems',
			});
		}

		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'ems')) {
			result.push({
				title: 'ТС',
				href: `/ems/vehicles/vehicles`,
				component: 'ems',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'ems')) {
			result.push({
				title: 'БНСО',
				href: `/ems/vehicles/bnso`,
				component: 'ems',
			});
		}
		if (currentUser.can('com.rnis.geo.user_geo_object', 'read', 'ems')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/ems/map',
				component: 'ems',
			});
		}

		return result;
	}

	getKiutrControlDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'kiutr-control')) {
			result.push({
				title: 'Предприятия',
				href: `/kiutr-control/units/units`,
				component: 'kiutr-control',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'kiutr-control')) {
			result.push({
				title: 'ТС',
				href: `/kiutr-control/vehicles/vehicles`,
				component: 'kiutr-control',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'kiutr-control')) {
			result.push({
				title: 'БНСО',
				href: `/kiutr-control/vehicles/bnso`,
				component: 'kiutr-control',
			});
		}
		if (currentUser.can('com.rnis.geo.territorial_entity', 'read', 'kiutr-control')) {
			result.push({
				title: 'Территориальные образования',
				href: `/kiutr-control/territorial_entities/tree`,
				component: 'kiutr-control',
			});
		}
		if (currentUser.can('com.rnis.geo.stop_point', 'read', 'kiutr-control')) {
			result.push({
				title: 'Остановки',
				href: '/kiutr-control/stop-points',
				component: 'kiutr-control',
			});
		}
		if (currentUser.can('com.rnis.geo.user_geo_object', 'read', 'kiutr-control')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/kiutr-control/map',
				component: 'kiutr-control',
			});
		}

		return result;
	}

	getChildrenDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.geo.stop_point')) {
			result.push({
				title: 'Остановки',
				href: '/children/stop-points',
				component: 'children',
			});
		}
		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'children')) {
			result.push({
				title: 'Предприятия',
				href: `/children/units/units`,
				component: 'children',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'children')) {
			result.push({
				title: 'ТС',
				href: `/children/vehicles/vehicles`,
				component: 'children',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'children')) {
			result.push({
				title: 'БНСО',
				href: `/children/vehicles/bnso`,
				component: 'children',
			});
		}

		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/children/map',
				component: 'children',
			});
		}

		return result;
	}

	getControlDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'control')) {
			result.push({
				title: 'Предприятия (подразделения)',
				href: `/control/units/units`,
				component: 'control',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'control')) {
			result.push({
				title: 'ТС',
				href: `/control/vehicles/vehicles`,
				component: 'control',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'control')) {
			result.push({
				title: 'БНСО',
				href: `/control/vehicles/bnso`,
				component: 'control',
			});
		}
		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/control/map',
				component: 'control',
			});
		}
		/*if (currentUser.can('com.rnis.dictionary.dictionary.vehicle_types')) {
            result.push({
                title: 'Типы транспортных средств',
                href: '/control/dictionaries/vehicle_types',
                component: 'control',
            });
        }*/

		return result;
	}

	getTaxiDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'taxi')) {
			result.push({
				title: 'Предприятия (подразделения)',
				href: `/taxi/units/units`,
				component: 'taxi',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'taxi')) {
			result.push({
				title: 'ТС',
				href: `/taxi/vehicles/vehicles`,
				component: 'taxi',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'taxi')) {
			result.push({
				title: 'БНСО',
				href: `/taxi/vehicles/bnso`,
				component: 'taxi',
			});
		}
		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/taxi/map',
				component: 'taxi',
			});
		}

		return result;
	}

	getAgriculturalDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'agricultural')) {
			result.push({
				title: 'Предприятия (подразделения)',
				href: `/agricultural/units/units`,
				component: 'agricultural',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'agricultural')) {
			result.push({
				title: 'ТС',
				href: `/agricultural/vehicles/vehicles`,
				component: 'agricultural',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'agricultural')) {
			result.push({
				title: 'БНСО',
				href: `/agricultural/vehicles/bnso`,
				component: 'agricultural',
			});
		}
		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/agricultural/map',
				component: 'agricultural',
			});
		}

		return result;
	}

	getMedicDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'medic')) {
			result.push({
				title: 'Предприятия (подразделения)',
				href: `/medic/units/units`,
				component: 'medic',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'medic')) {
			result.push({
				title: 'ТС',
				href: `/medic/vehicles/vehicles`,
				component: 'medic',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'medic')) {
			result.push({
				title: 'БНСО',
				href: `/medic/vehicles/bnso`,
				component: 'medic',
			});
		}
		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/medic/map',
				component: 'medic',
			});
		}

		return result;
	}

	getTimberDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'timber')) {
			result.push({
				title: 'Предприятия (подразделения)',
				href: `/timber/units/units`,
				component: 'timber',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'timber')) {
			result.push({
				title: 'ТС',
				href: `/timber/vehicles/vehicles`,
				component: 'timber',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'timber')) {
			result.push({
				title: 'БНСО',
				href: `/timber/vehicles/bnso`,
				component: 'timber',
			});
		}
		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/timber/map',
				component: 'timber',
			});
		}

		return result;
	}

	getDangerousDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'dangerous')) {
			result.push({
				title: 'Предприятия (подразделения)',
				href: `/dangerous/units/units`,
				component: 'dangerous',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'dangerous')) {
			result.push({
				title: 'ТС',
				href: `/dangerous/vehicles/vehicles`,
				component: 'dangerous',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'dangerous')) {
			result.push({
				title: 'БНСО',
				href: `/dangerous/vehicles/bnso`,
				component: 'dangerous',
			});
		}
		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/dangerous/map',
				component: 'dangerous',
			});
		}

		return result;
	}

	getMedicineDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'medicine')) {
			result.push({
				title: 'Предприятия (подразделения)',
				href: `/medicine/units/units`,
				component: 'medicine',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'medicine')) {
			result.push({
				title: 'ТС',
				href: `/medicine/vehicles/vehicles`,
				component: 'medicine',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'medicine')) {
			result.push({
				title: 'БНСО',
				href: `/medicine/vehicles/bnso`,
				component: 'medicine',
			});
		}

		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/medicine/map',
				component: 'medicine',
			});
		}

		return result;
	}

	getOfficialDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'official')) {
			result.push({
				title: 'Предприятия (подразделения)',
				href: `/official/units/units`,
				component: 'official',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'official')) {
			result.push({
				title: 'ТС',
				href: `/official/vehicles/vehicles`,
				component: 'official',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'official')) {
			result.push({
				title: 'БНСО',
				href: `/official/vehicles/bnso`,
				component: 'official',
			});
		}

		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/official/map',
				component: 'official',
			});
		}

		return result;
	}

	getForestryDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'forestry')) {
			result.push({
				title: 'Предприятия (подразделения)',
				href: `/forestry/units/units`,
				component: 'forestry',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'forestry')) {
			result.push({
				title: 'ТС',
				href: `/forestry/vehicles/vehicles`,
				component: 'forestry',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'forestry')) {
			result.push({
				title: 'БНСО',
				href: `/forestry/vehicles/bnso`,
				component: 'forestry',
			});
		}
		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/forestry/map',
				component: 'forestry',
			});
		}

		return result;
	}

	getFarmingDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'farming')) {
			result.push({
				title: 'Предприятия (подразделения)',
				href: `/farming/units/units`,
				component: 'farming',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'farming')) {
			result.push({
				title: 'ТС',
				href: `/farming/vehicles/vehicles`,
				component: 'farming',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'farming')) {
			result.push({
				title: 'БНСО',
				href: `/farming/vehicles/bnso`,
				component: 'farming',
			});
		}

		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/farming/map',
				component: 'farming',
			});
		}

		return result;
	}

	getEmercomDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'emercom')) {
			result.push({
				title: 'Предприятия (подразделения)',
				href: `/emercom/units/units`,
				component: 'emercom',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'emercom')) {
			result.push({
				title: 'ТС',
				href: `/emercom/vehicles/vehicles`,
				component: 'emercom',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'emercom')) {
			result.push({
				title: 'БНСО',
				href: `/emercom/vehicles/bnso`,
				component: 'emercom',
			});
		}

		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/emercom/map',
				component: 'emercom',
			});
		}

		return result;
	}

	getRoadDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.kurs.road', 'read', 'road')) {
			result.push({
				title: 'Дороги',
				href: `/road/roads`,
				component: 'road',
			});
		}
		if (currentUser.can('com.rnis.kurs.road_part', 'read', 'road')) {
			result.push({
				title: 'Участки дорог',
				href: `/road/road_parts`,
				component: 'road',
			});
		}
		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'road')) {
			result.push({
				title: window.RNIS_SETTINGS.organizational_units_name || 'Предприятия (РУАД)',
				href: `/road/units/units`,
				component: 'road',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'road')) {
			result.push({
				title: 'БНСО',
				href: `/road/vehicles/bnso`,
				component: 'road',
			});
		}
		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/road/map',
				component: 'road',
			});
		}

		return result;
	}

	getUtilityDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.utility.road', 'read', 'utility')) {
			result.push({
				title: 'Дороги',
				href: `/utility/roads`,
				component: 'utility',
			});
		}
		if (currentUser.can('com.rnis.utility.road_part', 'read', 'utility')) {
			result.push({
				title: 'Участки дорог',
				href: `/utility/road_parts`,
				component: 'utility',
			});
		}
		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'utility')) {
			result.push({
				title: 'Предприятия',
				href: `/utility/units/units`,
				component: 'utility',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'utility')) {
			result.push({
				title: 'БНСО',
				href: `/utility/vehicles/bnso`,
				component: 'utility',
			});
		}
		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/utility/map',
				component: 'utility',
			});
		}

		return result;
	}

	getGarbageDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.garbage.object', 'read', 'garbage')) {
			result.push({
				title: 'Объекты ТКО',
				href: `/garbage/objects`,
				component: 'garbage',
			});
		}
		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'garbage')) {
			result.push({
				title: 'Предприятия',
				href: `/garbage/units/units`,
				component: 'garbage',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'garbage')) {
			result.push({
				title: 'ТС',
				href: `/garbage/vehicles/vehicles`,
				component: 'garbage',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'garbage')) {
			result.push({
				title: 'БНСО',
				href: `/garbage/vehicles/bnso`,
				component: 'garbage',
			});
		}
		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/garbage/map',
				component: 'garbage',
			});
		}

		return result;
	}

	getCommdeptDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.commdept.road', 'read', 'commdept')) {
			result.push({
				title: 'Дороги',
				href: `/commdept/roads`,
				component: 'commdept',
			});
		}

		if (currentUser.can('com.rnis.commdept.road_part', 'read', 'commdept')) {
			result.push({
				title: 'Участки дорог',
				href: `/commdept/road_parts`,
				component: 'commdept',
			});
		}

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'commdept')) {
			result.push({
				title: window.RNIS_SETTINGS.organizational_units_name || 'Предприятия (РУАД)',
				href: `/commdept/units/units`,
				component: 'commdept',
			});
		}

		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'commdept')) {
			result.push({
				title: 'БНСО',
				href: `/commdept/vehicles/bnso`,
				component: 'commdept',
			});
		}

		if (currentUser.can('com.rnis.geo.user_geo_object')) {
			result.push({
				title: 'Пользовательские объекты на карте',
				href: '/settings/commdept/map',
				component: 'commdept',
			});
		}

		return result;
	}

	getCommunalDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'communal')) {
			result.push({
				title: 'Предприятия',
				href: `/communal/units/units`,
				component: 'communal',
			});
		}
		if (currentUser.can('com.rnis.vehicles.vehicle', 'read', 'communal')) {
			result.push({
				title: 'ТС',
				href: `/communal/vehicles/vehicles`,
				component: 'communal',
			});
		}
		if (currentUser.can('com.rnis.vehicles.bnso', 'read', 'communal')) {
			result.push({
				title: 'БНСО',
				href: `/communal/vehicles/bnso`,
				component: 'communal',
			});
		}

		return result;
	}

	getOperatorDictionaries() {
		let result = [];

		if (currentUser.can('com.rnis.organizational_units.unit', 'read', 'operator')) {
			result.push({
				title: 'Предприятия',
				href: '/operator/units/units',
				component: 'operator',
			});
		}

		return result;
	}
}
