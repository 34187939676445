import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './map-zoom-button.less';

@propTypes({
    type: PropTypes.oneOf(['in', 'out', 'routing']).isRequired,
    onClick: PropTypes.func.isRequired,
})

export default class MapZoomButton extends Component {

    render() {
        const className = classNames(
            'b-button-zoom ',
            `b-button-zoom_${this.props.type}`,
        );

        return (
            <a className={className} href="#" onClick={::this.onClick}/>
        )
    }

    onClick(e) {
        e.preventDefault();

        this.props.onClick();
    }
}