import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import {LabeledCheckbox} from "components/ui/checkbox";
import IconButton from "components/ui/icon-button";
import ContextTooltip from "components/ui/context-tooltip";
import Popup from "components/ui/popup";
import FilterHeader from "components/ui/filter-header";
import debounce from 'throttle-debounce/debounce';
import classNames from 'classnames';
import GlobalLoaderComponent from "components/ui/global-loader";
import LoaderComponent from "components/ui/loader";
import './index.less';


@propTypes({
    selected: PropTypes.array,
    onSelectedChange: PropTypes.array.isRequired,
})

export default class TaskFilterBase extends Component {

    tabs = [];
    icon = 'legend';
    contextKey = null;
    contextDefault = null;
    limit = 25;
    title = '';

    loadDebounce = debounce(500, ::this.loadDataMiddleware);

    state = {
        opened: false,
        items: {},
        isDeselectAll: false,
        search: {},
        activeTab: 0,
        loading: {},
    };

    componentWillMount() {
        let search = {};

        _.each(this.tabs, (tabName, tabIndex) => {
            this.loadDataMiddleware(tabIndex);
            search[tabIndex] = '';
        });

        this.setState({search});
    }

    getButton() {
        return (
            <IconButton 
                icon={this.icon}
                onClick={::this.toggleBlock}
            />
        );
    }

    getTitle(){
        return(
            <div className='tasks-filter__toggle' role='button' onClick={::this.toggleBlock}>
               {this.state.opened ? 'Скрыть' : 'Показать'} фильтр
            </div>
        )
    } 

    async loadData(tab = null, search = null) {
    }

    async reloadFull() {
        let items = this.state.items;
        _.each(this.tabs, (tabName, tabIndex) => {
            _.set(items, tabIndex, _.filter(_.get(items, tabIndex, []), (item) => {
                return _.indexOf(this.props.selected[tabIndex], item.value) !== -1;
            }));
        });
        await this.setState({items});

        _.each(this.tabs, (tabName, tabIndex) => {
            this.loadDataMiddleware(tabIndex);
        });
    }

    async loadDataMiddleware(tabIndex = null) {
        if (tabIndex === null) {
            tabIndex = this.state.activeTab;
        }
        this.setLoading(tabIndex, true);
        const newItems = await this.loadData(tabIndex, _.get(this.state.search, this.state.activeTab, null));
        this.setLoading(tabIndex, false);

        let items = this.state.items;
        _.set(items, tabIndex, _.uniqBy(_.concat(_.get(items, tabIndex, []), newItems), 'value'));

        this.setState({items});
    }

    setLoading(tabIndex, value) {
        let loading = this.state.loading;
        _.set(loading, tabIndex, value);
        this.setState({loading});
    }

    getFilteredItems() {
        const searchLower = _.get(this.state.search, this.state.activeTab, '').toLowerCase();
        const selected = this.props.selected[this.state.activeTab];

        return _.sortBy(_.filter(_.get(this.state.items, this.state.activeTab), (item, index) => {
            if (searchLower) {
                return (item.label.toLowerCase().indexOf(searchLower) !== -1);
            }
            /*if (selected.length > 0) {
                return (_.indexOf(selected, item.value) !== -1) || (index < this.limit - selected.length);
            }*/
            return index < this.limit;
        }), 'label');
    }

    render() {
        const button = this.getButton();

        return (
            <div className='tasks-filter'>
                {this.contextKey ? (
                    <ContextTooltip key={this.contextKey} code={this.contextKey}
                                    default={this.contextDefault}>
                        {button}
                    </ContextTooltip>
                ) : this.props.withButton ? (button) : this.getTitle()}
                {this.state.opened ? this.renderPopup() : null}
            </div>
        );
    }

    renderPopup() { // TODO: pick out to other component
        const items = this.state.items[this.state.activeTab] || [];
        const selectedCount = _.filter(items, (item) => {
            return _.indexOf(this.props.selected[this.state.activeTab], item.value) !== -1;
        }).length;

        console.log('items', items)
        const totalCount = items.length;

        const sliderClassName = classNames({
            'b-slider__line': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        const sliderCircleClassName = classNames({
            'b-slider__circle': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        const filteredItems = this.getFilteredItems();

        return (
            <Popup
                className={classNames("SelectFieldsPopup top-link checkbox-dropdown")}
                show={true}
                // onClose={::this.closeBlock}
                >
                <div className="popup-container__content">
                    {(this.tabs.length > 1) && (
                        <FilterHeader onChange={::this.onChangeTab} items={this.tabs}
                                      currentItem={this.state.activeTab}/>
                                      )
                    }
                    <div className="filtration__search">
                        <input type="text" ref="search" value={this.state.search[this.state.activeTab]}
                               className="filtration__pole"
                               placeholder="Поиск"
                               onChange={::this.onSearchChange}
                        />
                        <input type="button" className="filtration__button"/>
                    </div>

                    <div className="content">
                        {_.get(this.state.loading, this.state.activeTab) ? (
                            <LoaderComponent color="red" size={64}/>
                        ) : null}
                        {filteredItems.map((item) => (
                            <LabeledCheckbox
                                key={item.value}
                                checked={_.indexOf(this.props.selected[this.state.activeTab], item.value) !== -1}
                                label={item.label}
                                onChange={this.onClick.bind(this, item)}/>
                        ))}
                    </div>
                    {(totalCount > 0) && (
                        <div className="b-slider _options clearAfter" onClick={::this.selectDeselectAll}>
                            <div className="b-slider__title" style={{display: 'none'}}>Выбрать все</div>
                            <div
                                className="b-slider__title">{this.state.isDeselectAll ? 'Снять все' : 'Выбрать все'}</div>

                            <div className="b-slider__control">
                                <div className={sliderClassName}/>
                                <div className={sliderCircleClassName}/>
                            </div>
                        </div>
                    )}
                </div>
            </Popup>
        );
    }

    onChangeTab({value}) {
        this.setState({
            activeTab: value,
        });
    }

    async onSearchChange() {
        const value = this.refs.search.value;
        let search = this.state.search;
        _.set(search, this.state.activeTab, value);
        await this.setState({
            search,
        });

        this.loadDebounce();
    }

    selectDeselectAll() {
        let selected;

        if (this.state.isDeselectAll) {
            selected = [];
        } else {
            selected = _.map(_.get(this.state.items, this.state.activeTab), 'value');
        }
        this.props.onSelectedChange[this.state.activeTab](selected);

        this.setState({
            isDeselectAll: !this.state.isDeselectAll,
        });
    }

    async onClick(item) {
        let selected = _.get(this.props.selected, this.state.activeTab, []);
        const index = _.indexOf(selected, item.value);
        if (index !== -1) {
            selected.splice(index, 1);
        } else {
            selected.push(item.value);
        }

        this.props.onSelectedChange[this.state.activeTab](selected);
    }

    toggleBlock(e) {
        this.setState({
            opened: !this.state.opened,
        });
    }

    closeBlock() {
        this.setState({
            opened: false,
        });
    }
}