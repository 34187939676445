import React, { Component } from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import _ from 'lodash';

@propTypes({
    map: PropTypes.object.isRequired,
    leafletMap: PropTypes.object.isRequired,
    latitude: PropTypes.oneOfType([
        PropTypes.array.isRequired,
        PropTypes.number.isRequired
    ]),
    longitude: PropTypes.oneOfType([
        PropTypes.array.isRequired,
        PropTypes.number.isRequired
    ]),
    options: PropTypes.object,
    onClick: PropTypes.func,
    onDblClick: PropTypes.func,
    onRightClick: PropTypes.func,
    fast: PropTypes.bool,
    draggable: PropTypes.bool,
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
})

@defaultProps({
    onClick: null,
    onDblClick: () => {
    },
    onRightClick: () => {
    },
    options: {},
    fast: false,
    draggable: false,
})

export default class AbstractMapMarker extends Component {

    animation = false;

    __marker = null;

    componentWillMount() {
        this.init();
    }

    reinit() {
        this.props.leafletMap.removeLayer(this.getMarker());
        this.init();
        this.props.leafletMap.addLayer(this.getMarker());
    }

    init(props = null) {
        this.__marker = new L.Marker([
            this.props.latitude,
            this.props.longitude,
        ], _.merge(this.getOptions(props), this.props.options));

        if (this.props.onClick) {
            this.getMarker().on('click', this.props.onClick);
        }
        this.getMarker().on('contextmenu', this.props.onRightClick);
        this.getMarker().on('dblclick', this.props.onDblClick);

        if (this.props.draggable) {
            this.getMarker().on('drag', this.props.onDrag);
            this.getMarker().on('dragend', this.props.onDragEnd);
        }
    }

    addLayerInitOnMount() {
        if (this.getMarker().getLatLng()) {
            if (this.getMarker().getLatLng().lat && this.getMarker().getLatLng().lng) {
                this.props.leafletMap.addLayer(this.getMarker());
            }
        } else {
            setTimeout(() => {
                this.addLayerInitOnMount();
            }, 500);
        }
    }

    componentDidMount() {
        this.addLayerInitOnMount();
    }

    componentWillUnmount() {
        this.props.leafletMap.removeLayer(this.getMarker());
    }

    componentDidUpdate(prevProps) {
        let coordinates = { lat: null, lng: null };
        coordinates = { ...coordinates, ...this.getMarker().getLatLng() }
        const { lat, lng } = coordinates;
        if (lat && lng) {
            if (lat !== this.props.latitude || lng !== this.props.longitude) {
                if (!this.props.fast && this.animation) {
                    this.getMarker().slideTo([this.props.latitude, this.props.longitude], {
                        duration: 5000,
                    });
                } else {
                    this.getMarker().setLatLng([this.props.latitude, this.props.longitude]);
                }
            }
        }
        if (prevProps.draggable !== this.props.draggable) {
            this.reinit();
        }
    }

    getMarker() {
        return this.__marker;
    }

    getOptions(props = null) {
        return {
            draggable: props ? props.draggable : this.props.draggable,
        };
    }

    render() {
        return null;
    }

}