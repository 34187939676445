import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import {connect} from 'react-redux';
import {logout} from 'store/reducers/auth';

import Button from '../../ui/button';
import Modal from '../../ui/modal';

import './logout-modal.less';
import GlobalLoaderComponent from "components/ui/global-loader";
import $ from "jquery";
import { installSettings } from 'helpers/settings';


@connect(null, {logout})

@propTypes({
    onClose: PropTypes.func
})

@defaultProps({
    onClose: () => {
    }
})

export default class LogoutModal extends Component {

    state = {
        loading: false,
    };

    render() {
        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;

        return (
            <Modal {...this.props} className="LogoutModal">
                {loader}
                <div className="b-modal__img"/>
                <div className="b-modal__title">
                    <span className="b-modal__first">Вы уверены, что</span>
                    хотите выйти из системы?
                </div>
                <div className="b-modal__footer clearAfter">
                    <Button
                        text="Выйти"
                        size="md"
                        color="red"
                        shadow="red"
                        onClick={::this.logout}/>
                    <Button
                        text="Отменить"
                        size="md"
                        color="white"
                        shadow="gray"
                        onClick={this.props.onClose}/>
                </div>
            </Modal>
        );
    }

    async logout() {
        this.setState({loading: true});
        window.clearInterval(window.payTimer);
        window.clearInterval(window.settingsTimer);
        // снова запрашиваем файл настроек, например, чтобы отобразить логотип

        const res = await fetch(`${window.location.origin}/settings/settings.json`)
        const settings = await res.json()
        installSettings(settings)
        
        await this.props.logout();
    }

}
