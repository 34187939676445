import React from 'react';
import {List} from 'immutable';
import {connect} from 'react-redux';
import _ from 'lodash';

import BaseTreeTableComponent from 'components/base/base_tree_table';
import Page from 'components/ui/page';
import IconButton from 'components/ui/icon-button';
import UnitsEditor from './editor';

import {mapDatatablesRequestToMeta} from 'helpers/api';

import * as alerts from "helpers/alerts";
import $ from "jquery";
import moment from "moment";
import TableContainer from "components/ui/Table/Container/TableContainer";
import Column from "components/ui/column";
import AppearTransition from "components/ui/transitions/appear";
import {EntityList} from "helpers/entity";
import currentUser from 'helpers/current-user';
import {deleteUnit, getUnits} from "store/reducers/organizational_units/units";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getEntityNames} from "store/reducers/system";
import ContextTooltip from "components/ui/context-tooltip";
import LoaderComponent from "components/ui/loader";
import systems from "dictionaries/systems";
import {Link} from "react-router";
import HotKeysManager from "components/ui/HotKeys/Manager/HotKeysManager";
import {preventDefaultEventHandlers} from "helpers/hot_key_handlers";
import * as storage from "utils/storage";

@connect(state => ({}), {getUnits, deleteUnit, getDictionaryList, getEntityNames})

export default class UnitsTree extends BaseTreeTableComponent {

    modelClass = 'App\\Model\\Units';
    modelSource = 'organizational_units';

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            component: null,
            columns: List(this.getColumns()),
            related: new EntityList(),
            exportExcelMeta: null,
            isXlsExport: false,
            omsuFilters: [],
            siogvFilters: [],
            regoperFilters: [],
            communal_municipalities: [],
            siogvUnits: [],
        });
    }

    getBaseUrl() {
        return `/${this.state.component}/units/units`;
    }

    getTitle() {
        let title = 'Предприятия';
        if (this.state.component === 'road') {
            title = window.RNIS_SETTINGS.organizational_units_name || 'Предприятия (РУАД)';
        }
        if (this.state.component === 'control') {
            title = 'Предприятия (подразделения)';
        }
        return <span>{systems[this.state.component]} → <Link
            to={`/${this.state.component}/dictionaries`}>Справочники</Link> → {title}</span>;
    }

    async componentWillUpdate(props, state) {
        if (props.params.component !== state.component) {
            await this.setState({
                component: props.params.component,
            });
            this.reload();
        }

        const uuid = props.params.uuid;
        const newEditorUuid = (uuid && uuid !== 'create') ? uuid : null;
        if (this.state.editorUuid !== newEditorUuid || this.state.showEditorModal !== !!uuid) {
            this.setState({
                showEditorModal: !!uuid,
                editorUuid: newEditorUuid,
                editorModalMode: newEditorUuid ? 'edit' : 'add',
            });
        }
    }

    componentDidMount() {
        this.forceUpdate();
    }

    getHotKeyHandlers() {
        return preventDefaultEventHandlers(Object.assign(super.getHotKeyHandlers(), {
            delete: ::this.deleteSelected,
            table: ::this.goToTableView,
        }));
    }

    render() {
        let table = (!this.state.queryBounced) ? this.renderTable() : this.renderNonTreeTable();

        const editor = this.state.showEditorModal
            ? (
                <UnitsEditor
                    {...this.props}
                    key="editor"
                    onClose={::this.closeEditor}
                    onSubmit={::this.submitEditor}
                    onEditClick={::this.onEditorEditClick}
                    onDeleteClick={::this.onEditorDeleteClick}
                    mode={this.state.editorModalMode}
                    uuid={this.state.editorUuid}
                />
            ) : '';

        return (
            <HotKeysManager {...this.getHotKeyHandlers()}>
                <Page title={this.getTitle()}
                      pageId="Units"
                      headerActions={this.renderHeaderActions()}
                      headerContents={this.renderHeaderContents()}>

                    <TableContainer>
                        {table}
                    </TableContainer>

                    <AppearTransition>
                        {editor}
                    </AppearTransition>
                </Page>
            </HotKeysManager>
        );
    }

    getOmsuNames(array_omsu) {
        return array_omsu.map(omsuUuid => {
            const variants = this.state.communal_municipalities[omsuUuid];
            return  variants ? variants.name : '—'
        }).join('<br/>')
    }

    getSiogvNames(array_siogv) {
        return array_siogv.map(siogvUuid => {
            const variants = this.state.siogvUnits.filter(item => item.value === siogvUuid);
            return variants.length ? variants[0].label : '—'
        }).join('<br/>')
    }

    getRegoperNames(array_regoper) {
        return array_regoper.map(regoperUuid => {
            const variants = this.state.siogvUnits.filter(item => item.value === regoperUuid);
            return variants.length ? variants[0].label : regoperUuid
        }).join('<br/>')
    }

    showOmsuSiogv() {
        if (window.RNIS_SETTINGS.show_omsu) {
            return location.pathname.includes('communal/units/units')
            || location.pathname.includes('garbage/units/units') ? true : false;
        } else {
            return false;
        }
    }

    getColumns() {
        return this.prepareColumns([
            new Column('Наименование')
                .fromField('name'),

            ...(this.showOmsuSiogv() ? [new Column('ОМСУ')
                .fromField('unit_municipals')
                .withDrawer(item => item.unit_municipals && item.unit_municipals.length ? this.getOmsuNames(item.unit_municipals) : '<code>-</code>')
                .withFilter('withOMSU', () => {
                    return this.state.omsuFilters.length ? this.state.omsuFilters.map(uuid => {
                        return { name: this.state.communal_municipalities[uuid] ? this.state.communal_municipalities[uuid].name : uuid, uuid: uuid }
                    }) : {};
                })] : []),

            ...(this.showOmsuSiogv() ? [new Column('ЦИОГВ')
                .fromField('unit_siogv')
                .withDrawer(item => item.unit_siogv && item.unit_siogv.length ? this.getSiogvNames(item.unit_siogv) : '<code>-</code>')
                .withFilter('withSIOGV', () => {
                    return this.state.siogvFilters.length ? this.state.siogvFilters.map(uuid => {
                        const variants = this.state.siogvUnits.filter(item => item.value === uuid);
                        const name = variants.length ? variants[0].label : uuid;
                        return { name, uuid: uuid }
                    }) : {};
                })] : []),

            ...(location.pathname.includes("/garbage/units/units") && window.RNIS_SETTINGS.show_omsu ? [new Column('Рег.оператор').fromField('unit_regoper')
                .withDrawer(item => item.unit_regoper && item.unit_regoper.length ? this.getRegoperNames(item.unit_regoper) : '<code>-</code>')
                .withFilter('withRegOper', () => {
                    return this.state.regoperFilters.length ? this.state.regoperFilters.map(uuid => {
                        const variants = this.state.siogvUnits.filter(item => item.value === uuid);
                        const name = variants.length ? variants[0].label : uuid;
                        return { name, uuid: uuid }
                    }) : {};
                })] : []),

            new Column('Роли предприятия')
                .denyOrder()
                .withDrawer(item => _.map(item.unit_types, (unit_type_uuid) => this.state.related.get(unit_type_uuid)).join('<br/>'))
                .denyColumnFilter()
                .withFilter('withUnitType', async () => {
                    const response = await this.props.getDictionaryList('company_types');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return {};
                }),

            ...(window.RNIS_SETTINGS.CITY_NNOVGOROD ? [
                new Column('Муниципальное образование')
                    .fromField('communal_municipality_uuid')
                    .withDrawer(item => item.communal_municipality_uuid ? this.state.related.get(item.communal_municipality_uuid) : '<code>-</code>')
                    .withFilter('withMunicipality', async () => {
                        return await this.getDictionary('communal_municipalities');
                    }),
            ] : []),

            ...(window.RNIS_SETTINGS.CITY_NNOVGOROD ? [(new Column('№ Лицензионного Договора')
                .fromField('contract_license_number')
                )] : []),

            ...(window.RNIS_SETTINGS.CITY_NNOVGOROD ? [(new Column('№ Договора с Теле-оператором')
                .fromField('contract_tele_operator_number')
                )] : []),

            ...(window.RNIS_SETTINGS.CITY_NNOVGOROD ? [(new Column('№ Договора АРМ')
                .fromField('contract_apm_number')
                )] : []),

            new Column('Юридический адрес')
                .fromField('legal_address'),

            new Column('Фактический адрес')
                .fromField('actual_address'),

            new Column('Номер телефона')
                .fromField('phone'),

            new Column('Электронная почта')
                .fromField('email'),

            new Column('КПП')
                .fromField('kpp'),

            new Column('ИНН')
                .fromField('inn'),

            new Column('ОГРН')
                .fromField('ogrn'),
        ]);
    }

    setChildrenCountSpan(children_count) {
        if (location.pathname.includes("/road/units/units")) {
            $( `<span class="children-count__span">+ ${children_count} вложенных</span>` ).insertAfter( ".splitter" );
        }
    }

	exportToXls = () => {
		if (!this.refs.table) return;
		this.setState({
			isXlsExport: true
		})
		const table = this.refs.table.getWrappedInstance().table;
		table.page.len(10000);
		table.ajax.reload(() => {
			const tableExport = document.getElementsByClassName("b-table order-column dataTable no-footer")[1];
			const tableExportCopy = tableExport.cloneNode(true);
			tableExportCopy.querySelectorAll('th .b-table__filetr .filtration').forEach(item => item.remove())
			TableToExcel.convert(tableExportCopy, {
				name: `Экспорт_${moment(new Date()).format('DD.MM.YYYY')}_${moment(new Date()).format('HH:mm')}.xlsx`,
				sheet: {
					name: 'Таблица 1'
				}
			})

			setTimeout(() => {
				table.page.len(25);
				table.ajax.reload();
				this.setState({
					isXlsExport: false
				})
			}, 250);
		});
	}

    exportToXlsButton() {
        return this.state.isXlsExport ? <LoaderComponent size={22} color="red" align="none"/> : <ContextTooltip key="base-table-list.export" code="base-table-list.export"
                                                                                                     default="Экспорт в Excel">
            <IconButton icon="export" onClick={::this.exportToXls} />
        </ContextTooltip>
    }

    async closeEditor() {
        await this.props.router.push(this.getBaseUrl());
    }

    async submitEditor() {
        this.reload();
        await this.closeEditor();
    }

    async showEditor() {
        this.showEditorWithUuid();
    }

    async onEditorEditClick() {
        const uuid = this.state.editorUuid;
        await this.closeEditor();
        return this.showEditorWithUuid(uuid);
    }

    async onEditorDeleteClick() {
        return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            await this.props.deleteUnit({
                uuid: this.state.editorUuid,
            });

            await this.closeEditor();
            this.reload();
        });
    }

    async showEditorWithUuid(uuid = null) {
        await this.closeEditor();
        this.props.router.push(`${this.getBaseUrl()}/${uuid || 'create'}`);
    }

    onDblClick(data) {
        if (data.uuid && currentUser.can('com.rnis.organizational_units.unit', 'update')) {
            this.showEditorWithUuid(data.uuid);
        }
    }

    async editSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            this.showEditorWithUuid(selected.uuid);
        }
    }

    async deleteSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
                await this.props.deleteUnit({
                    uuid: selected.uuid,
                });
                this.reload();
            });
        }
    }

    goToTableView() {
        this.props.router.push(`/${this.state.component}/units/units-table`);
    }

    async loadSuboardinationData(data, callback) {
        let response2Sbdd = null;
        let dataSbdd = [];

        const meta = {
            filters: {
                appendChildrenAttribute: true,
                withParentUnit: data.uuid,
                withComponent: this.state.component,
            },
        };

        if (window.RNIS_SETTINGS.temporarily_sbdd && location.pathname.includes("operator/units/units") && this.state.component === 'operator') {
            const metaSbdd = {
                filters: {
                    appendChildrenAttribute: true,
                    withParentUnit: data.uuid,
                    withComponent: "sbdd",
                },
            };
            const responseSbdd = await this.props.getUnits(metaSbdd, false);
            if (responseSbdd.isOk) {
                dataSbdd = responseSbdd.payload.items;
                const sbddSuboardination = await this.loadSuboardinationRelatedEntities(responseSbdd.payload.items);
                if (sbddSuboardination.isOk) {
                    response2Sbdd = sbddSuboardination
                }
            }
        }

        const response = await this.props.getUnits(meta, false);

        if (response.isOk) {
            const data = response.payload.items;
            const response2 = await this.loadSuboardinationRelatedEntities(data);

            if (response2.isOk) {
                this.setState((prevState, props) => {
                        const related = prevState.related;
                        related.add(response2);
                        if (response2Sbdd) {
                            related.add(response2Sbdd)
                        }
                        return {related: related};
                    },
                    () => {
                        callback(data);
                    });
            }
            else {
                callback([...data, ...dataSbdd]);
            }
        } else {
            response.showErrors();
            callback([]);
        }
    }

    loadSuboardinationRelatedEntities(data) {
        const unitTypes = _.map(_.filter(_.flatten(_.map(data, 'unit_types'))), (uuid) => ({
            class: 'App\\Dictionaries\\Common\\CompanyTypes\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        return this.props.getEntityNames(unitTypes);
    }

    async collectOMSUandSiogvFilters(items) {
        const allOmsu = items.reduce((acc, value) => {
            if (value.unit_municipals && !acc.includes(value.unit_municipals[0])) {
                acc = [...acc, ...value.unit_municipals];
                return acc
            }
            return acc
        }, []);
        const allSiogv = items.reduce((acc, value) => {
            if (value.unit_siogv && !acc.includes(value.unit_siogv[0])) {
                acc = [...acc, ...value.unit_siogv];
                return acc
            }
            return acc
        }, []);
        const allRegOper = items.reduce((acc, value) => {
            if (value.unit_regoper && !acc.includes(value.unit_regoper[0])) {
                acc = [...acc, ...value.unit_regoper];
                return acc
            }
            return acc
        }, []);
        await this.setState({
            omsuFilters: [...new Set(allOmsu)],
            siogvFilters: [...new Set(allSiogv)],
            regoperFilters: [...new Set(allRegOper)],
        })
    }

    getStorageKey() {
        const path = window.location.pathname.replace(/\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/g, '/_uuid_');
        return `table-filters:${path}`;
    }

    async loadCallback(request, drawCallback) {
        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        let metaSbdd = {};
        let sbddData = [];
        let sbddPageTotal = 0;
        if (this.state.showTableFilters) {
            storage.save(this.getStorageKey(), Object.assign(_.cloneDeep(meta.filters), _.mapValues(_.keyBy(meta.column_search, 'column'), 'value')));
        } else {
            storage.remove(this.getStorageKey());
        }
        meta.filters.appendChildrenAttribute = true;
        meta.filters.withParentUnit = null;
        meta.filters.withComponent = this.state.component;

        meta.response_data = [
            'items/uuid',
            'items/deleted_at',
            'items/has_children',
            'items/name',
            'items/unit_types',
            'items/legal_address',
            'items/actual_address',
            'items/phone',
            'items/email',
            'items/kpp',
            'items/ogrn',
            'items/inn',
            'items/communal_municipality_uuid',
            'items/contract_license_number',
            'items/contract_tele_operator_number',
            'items/contract_apm_number',
            'items/unit_municipals',
            'items/unit_siogv',
        ];
        if (location.pathname.includes("/garbage/units/units")) {
            meta.response_data.push('items/unit_regoper')
        }

        if (window.RNIS_SETTINGS.temporarily_sbdd && location.pathname.includes("operator/units/units") && this.state.component === 'operator') {
            const copy = Object.assign(_.cloneDeep(meta));
            copy.filters.withComponent = 'sbdd';
            metaSbdd = copy;
            const responseSbdd = await this.props.getUnits(metaSbdd, false);
            if (responseSbdd.isOk) {
                sbddData = responseSbdd.payload.items;
                sbddPageTotal = sbddPageTotal + responseSbdd.data.headers.meta.pagination.total;
            }
        }

        const response = await this.props.getUnits(meta, false);
        if (response.isOk) {
            sbddPageTotal = response.data.headers.meta.pagination.total;
            if (window.RNIS_SETTINGS.unitsTree_childrenCount) {
                this.setChildrenCountSpan(55);
            }

            const data = [...response.payload.items, ...sbddData];

            if (location.pathname.includes("communal/units/units") || location.pathname.includes("garbage/units/units")) {
                if (window.RNIS_SETTINGS.show_omsu) {
                    const metaSiogv = {
                        pagination: {page: 1, limit: 10000}
                    }
                    if (this.state.siogvUnits.length === 0) {
                        const responseSiogv = await this.props.getUnits(metaSiogv, false);
                        if (responseSiogv.isOk) {
                            const namesMatchForSIOGV = _.sortBy(_.filter(_.map(responseSiogv.payload.items, (unit) => ({
                                value: unit.uuid,
                                label: unit.name,
                            })), (item) => item.value), 'label')
                            this.setState({
                                siogvUnits: namesMatchForSIOGV
                            })
                        }
                    }
                }
            }

            let isLocation;
            if (window.RNIS_SETTINGS.show_omsu) {
                isLocation = location.pathname.includes('communal/units/units')
                || location.pathname.includes('garbage/units/units') ? true : false;
            } else {
                isLocation = false;
            }
            if (isLocation && response.payload.items.length && !this.state.omsuFilters.length) {
                await this.collectOMSUandSiogvFilters(data)
            }

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: sbddPageTotal,
                recordsTotal: sbddPageTotal
            };

            drawCallback(json);

            this.loadRelatedEntities(json, drawCallback);
            this.selectedRowsRecalc();
            this.setState({success: true, exportExcelMeta: meta});
        } else {
            response.showErrors();
        }
    }

    async loadRelatedEntities(json, drawCallback) {
        if (!this.state.communal_municipalities.length) {
            const communal_municipalities = await this.getDictionary('communal_municipalities');
            this.setState({
                communal_municipalities: communal_municipalities
            })
        }

        const result = json.data;

        const unitTypes = _.map(_.filter(_.flatten(_.map(result, 'unit_types'))), (uuid) => ({
            class: 'App\\Dictionaries\\Common\\CompanyTypes\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const municipalities = _.map(_.filter(_.map(result, 'communal_municipality_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Communal\\CommunalMunicipalities\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(_.concat(unitTypes, municipalities));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }

        return result;
    }

    getCreateButton() {
        if (!currentUser.can('com.rnis.organizational_units.unit', 'create')) {
            return null;
        }
        return (
            <ContextTooltip key="base-table-list.create" code="base-table-list.create" default="Добавить">
                <IconButton
                    icon="plus"
                    tooltip="Добавить"
                    onClick={::this.showEditor}
                    active={this.state.showEditorModal && (this.state.editorUuid === 'create')}
                    className="popup-link"
                />
            </ContextTooltip>
        );
    }

    renderHeaderActions() {
        return [
            currentUser.can('com.rnis.organizational_units.unit', 'delete') ? (
                <ContextTooltip key="base-table-list.delete" code="base-table-list.delete" default="Удалить">
                    <IconButton icon="basket" disabled={this.state.selectedRowsCount === 0}
                                onDisabledClick={::this.alertToSelect}
                                onClick={::this.deleteSelected}/>
                </ContextTooltip>
            ) : null,
            <ContextTooltip key="units.table" code="units.table" default="Табличный вид">
                <IconButton
                    icon="thumbnails"
                    onClick={::this.goToTableView}
                />
            </ContextTooltip>
        ];
    }
}
