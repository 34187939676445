import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getTasks = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.getTasks(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTask = (uuid) => async (dispatch) => {
    try {
        const response = await api.commdept.getTask(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createTask = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.createTask(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const importTask = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.importTask(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateTask = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.updateTask(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateTaskCheck = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.updateTaskCheck(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteTask = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.deleteTask(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const repeatTask = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.repeatTask(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTaskViolations = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.getTaskViolations(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const unitTasksDoneChart = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.unitTasksDoneChart(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const dateTasksDoneChart = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.dateTasksDoneChart(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
