import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import {LabeledCheckbox} from '../checkbox';
import Popup from '../popup';
import './style.less';
import _ from 'lodash';

@propTypes({
    onChange: PropTypes.func,
    items: PropTypes.array,
    className: PropTypes.string,
    fieldIdKey: PropTypes.string,
    fieldNameKey: PropTypes.string,
    withToggle: PropTypes.bool,
    withSearch: PropTypes.bool,
})

@defaultProps({
    onChange: () => {
    },
    items: [],
    className: '',
    fieldIdKey: 'id',
    fieldNameKey: 'name',
    withToggle: true,
    withSearch: true,
})

export default class SelectPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkedItems: {}
        };
    }

    componentWillMount() {
        this.updateCheckedItems(this.props.items);
    }

    componentWillReceiveProps(nextProps) {
        this.updateCheckedItems(nextProps.items);
    }

    updateCheckedItems(items) {
        const checkedItems = {};
        items.forEach((item, index) => {
            checkedItems[index] = !!(item.checked);
        });
        this.setState({checkedItems})
    }

    getItems() {
        const items = {};
        this.props.items.forEach((item, index) => {
            items[index] = {checked: !!(this.state.checkedItems[index])};
            /*items.push({
                index,
                checked: !!(this.state.checkedItems[index]),
                item
            });*/
        });

        return items;
    }

    isAllChecked() {
        const checkedCount = Object.keys(this.state.checkedItems).filter((key) => this.state.checkedItems[key]).length;
        return checkedCount === this.props.items.length;
    }

    selectDeselectAll = () => {
        const items = [];
        const isAllChecked = this.isAllChecked();
        this.props.items.forEach((item, index) => {
            if (!!(this.state.checkedItems[index]) === isAllChecked) {
                items.push({index, checked: !isAllChecked});
                //this.onChangeSelectFieldsCheckbox(index, !isAllChecked);
            }
        });

        const checkedItems = this.state.checkedItems;

        items.forEach((item) => {
            checkedItems[item.index] = item.checked;
            item.item = this.props.items[item.index]
        });

        this.setState({checkedItems});
        this.props.onChange(items);
    };

    onChangeSelectFieldsCheckbox(index, checked) {
        let checkedItems = this.state.checkedItems;

        if (!this.props.withToggle && checked) {
            const lastCheckedItemIndex = _.findKey(checkedItems, (item) => {
                return item;
            });
            if (lastCheckedItemIndex !== undefined && lastCheckedItemIndex !== -1) {
                checkedItems[lastCheckedItemIndex] = false;
                this.props.onChange([{
                    index: lastCheckedItemIndex,
                    checked: false,
                    item: this.props.items[lastCheckedItemIndex]
                }]);
            }
        }

        checkedItems[index] = checked;

        this.setState({checkedItems});
        this.props.onChange([{index, checked, item: this.props.items[index]}]);
    }

    async onSearchChange() {
        const search = this.refs.search.value;
        await this.setState({
            search_lower: search.toLowerCase(),
            search,
        });
    }

    render() {
        const selectedCount = Object.keys(this.state.checkedItems).filter((key) => !!(this.state.checkedItems[key])).length;
        const totalCount = this.props.items.length;

        const sliderClassName = classNames({
            'b-slider__line': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        const sliderCircleClassName = classNames({
            'b-slider__circle': true,
            '_selected_yes': selectedCount && selectedCount !== totalCount,
            '_selected_all': selectedCount === totalCount,
        });

        const containerClassName = classNames('select-popup', this.props.className);

        const filteredItems = _.filter(this.props.items, (item) => {
            if (!item) return false; // filter on empty strings
            if (this.state.search_lower) {
                return (item[this.props.fieldNameKey].toLowerCase().indexOf(this.state.search_lower) !== -1);
            }
            return true;
        });

        return (
            <Popup
                //className="select-popup top-link"
                className={containerClassName}
                show={true}
                onClose={this.onChangeShowPopup}>

                <div className="select-popup__content">
                    {this.props.withSearch ? (
                        <div className="filtration__search">
                            <input type="text" ref="search" value={this.state.search} className="filtration__pole"
                                   placeholder="Поиск"
                                   onChange={::this.onSearchChange}
                            />
                            <input type="button" className="filtration__button"/>
                        </div>
                    ) : null}

                    {_.sortBy(filteredItems, (item) => {

                        return item[this.props.fieldNameKey].toLowerCase();
                    }).map((item, i) => (
                        <LabeledCheckbox
                            key={i}
                            checked={!!item.checked}
                            label={item[this.props.fieldNameKey]}
                            onChange={this.onChangeSelectFieldsCheckbox.bind(this, this.props.items.indexOf(item))}/>
                    ))}
                    {this.props.withToggle ? (
                        <div className="b-slider _options clearAfter" onClick={this.selectDeselectAll}>
                            <div className="b-slider__title" style={{display: 'none'}}>Выбрать все</div>
                            <div className="b-slider__title">{this.isAllChecked() ? 'Снять все' : 'Выбрать все'}</div>

                            <div className="b-slider__control">
                                <div className={sliderClassName}/>
                                <div className={sliderCircleClassName}/>
                            </div>
                        </div>
                    ) : null}
                </div>
            </Popup>
        );
    }
}