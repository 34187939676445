import swal from 'sweetalert2';
import $ from 'jquery';

import '../../node_modules/sweetalert2/dist/sweetalert2.css';

import '../styles/modal.less';
import '../components/ui/button.less';

export async function prompt(title, text, callback, confirmBtnText = 'Удалить', callback2 = null) {
    const modal = $(`
        <div class="b-modal-mini b-modal-mini-remove">
            <div class="b-modal-mini__overlay"></div>
            <div class="b-modal-mini__window b-modal-mini__window_small">
                <div class="b-modal-mini__img"></div>
                <div class="b-modal-mini__title">
                    <div class="b-modal-mini__txt">${title}</div>               
                </div>
                <div class="b-modal-mini__buttons">
                    <a class="b-button b-button-confirm b-button_red b-button_shadow_red b-button_size_md" href="#">${confirmBtnText}</a>
                    <a class="b-button b-button-cancel b-button_white b-button_shadow_gray b-button_size_md" href="#">Отменить</a>                
                </div>
            </div>
        </div>
    `);
    $('.b-modal-mini').remove();
    $('body').append(modal);
    modal.find('.b-button-cancel').click((e) => {
        e.preventDefault();
        modal.remove();

        callback2 && callback2();
    });
    modal.find('.b-button-confirm').click((e) => {
        e.preventDefault();

        modal.remove();
        callback();
    });
}

export async function close() {
    return swal.close();
}

export async function alert(title, text = null) {
    return swal({
        title,
        text,
        type: 'info',
        confirmButtonColor: '#FF5C55',
        confirmButtonText: 'ОК',
    });
}

export async function success(text, callback) {
    const modal = $(`
        <div class="b-modal-mini b-modal-mini-recovery-completed">
            <div class="b-modal-mini__overlay"></div>
            <div class="b-modal-mini__window b-modal-mini__window_small">
                <div class="b-modal-mini__img"></div>
                <div class="b-modal-mini__title">
                    <div class="b-modal-mini__txt">${text}</div>               
                </div>
                <div class="b-modal-mini__buttons">
                    <a class="b-button b-button-close b-button_red b-button_shadow_red b-button_size_md" href="#">Закрыть </a>
                </div>
            </div>
        </div>
    `);
    $('.b-modal-mini').remove();
    $('body').append(modal);
    modal.find('.b-button-close').click((e) => {
        e.preventDefault();
        modal.remove();
        callback && callback();
    });
}

export async function error(text, title) {

    function renderArray(values) {
        if (Array.isArray(values)) {
            let str = ``;
            values.map(item => {
                str = str + item + `<br/><br/>`
            })
            return str;
        } else {
            return values
        }
    }

    const modal = $(`
        <div class="b-modal-mini b-modal-mini-recovery-rejected">
            <div class="b-modal-mini__overlay"></div>
            <div class="b-modal-mini__window b-modal-mini__window_small">
                <div class="b-modal-mini__img"></div>
                <div class="b-modal-mini__title">
                    <div class="b-modal-mini__txt">${renderArray(text)}</div>               
                </div>
                <div class="b-modal-mini__buttons">
                    <a class="b-button b-button-close b-button_red b-button_shadow_red b-button_size_md" href="#">Закрыть </a>
                </div>
            </div>
        </div>
    `);
    $('.b-modal-mini').remove();
    $('body').append(modal);
    modal.find('.b-button-close').click((e) => {
        e.preventDefault();
        modal.remove();
    });
}

export async function ask(text, callback, value = null) {
    const modal = $(`
        <div class="b-modal-mini b-modal-mini-recovery-completed">
            <div class="b-modal-mini__overlay"></div>
            <div class="b-modal-mini__window b-modal-mini__window_md">
                <div class="b-modal-mini__img"></div>
                <div class="b-modal-mini__title">
                    <div class="b-modal-mini__txt">${text}</div>               
                </div>
                <div class="b-modal-mini__title">
                    <div class="input">
                        <input class="input__control" type="text" />
                        <div class="input__style"></div>
                    </div>
                </div>
                <div class="b-modal-mini__buttons">
                    <a class="b-button b-button-confirm b-button_red b-button_shadow_red b-button_size_md" href="#">Сохранить</a>
                    <a class="b-button b-button-cancel b-button_white b-button_shadow_gray b-button_size_md" href="#">Отменить</a>                
                </div>
            </div>
        </div>
    `);
    $('.b-modal-mini').remove();
    $('body').append(modal);
    modal.find('input').val(value).focus();
    modal.find('.b-button-cancel').click((e) => {
        e.preventDefault();
        modal.remove();
    });
    modal.find('.b-button-confirm').click((e) => {
        e.preventDefault();
        const value = modal.find('input').val();

        modal.remove();

        callback(value);
    });
}

export async function askSelect(text, options, callback, confirmButtonText = 'Сохранить') {
    const modal = $(`
        <div class="b-modal-mini b-modal-mini-recovery-completed">
            <div class="b-modal-mini__overlay"></div>
            <div class="b-modal-mini__window b-modal-mini__window_wide">
                <div class="b-modal-mini__img"></div>
                <div class="b-modal-mini__title">
                    <div class="b-modal-mini__txt">${text}</div>               
                </div>
                <div class="b-modal-mini__title">
                    <select>
                        ${options.map((item) => `<option value="${item.value}">${item.label}</option>`)}
                    </select>
                </div>
                <div class="b-modal-mini__buttons">
                    <a class="b-button b-button-confirm b-button_red b-button_shadow_red b-button_size_md" href="#">${confirmButtonText}</a>
                    <a class="b-button b-button-cancel b-button_white b-button_shadow_gray b-button_size_md" href="#">Отменить</a>                
                </div>
            </div>
        </div>
    `);
    $('.b-modal-mini').remove();
    $('body').append(modal);
    modal.find('.b-button-cancel').click((e) => {
        e.preventDefault();
        modal.remove();
    });
    modal.find('.b-button-confirm').click((e) => {
        e.preventDefault();
        const value = modal.find('select').val();

        modal.remove();

        callback(value);
    });
}

export function progress(title, confirmCallback, confirmBtnText = 'OK', cancelCallback = null) {
    const modal = $(`
        <div class="b-modal-mini b-modal-mini-remove">
            <div class="b-modal-mini__overlay"></div>
            <div class="b-modal-mini__window b-modal-mini__window_small">
                <div class="b-modal-mini__img"></div>
                <div class="b-modal-mini__title">
                    <div class="b-modal-mini__txt">${title}</div>
                </div>
                <div class="b-modal-mini__txt progress"></div>
                <div class="b-modal-mini__buttons">
                    <a class="b-button b-button-confirm b-button_red b-button_shadow_red b-button_size_md" href="#">${confirmBtnText}</a>
                    <a class="b-button b-button-cancel b-button_white b-button_shadow_gray b-button_size_md" href="#">Отменить</a>                
                </div>
            </div>
        </div>
    `);
    $('.b-modal-mini').remove();
    $('body').append(modal);
    modal.find('.b-button-cancel').click((e) => {
        e.preventDefault();
        modal.remove();

        cancelCallback && cancelCallback();
    });
    modal.find('.b-button-confirm').click((e) => {
        e.preventDefault();
        confirmCallback && confirmCallback();
    });

    return (progress) => {
        if (progress === 100) {
            $(modal.find('.progress')).remove();
            $(modal.find('.b-modal-mini__buttons')).html('<a class="b-button b-button-confirm b-button_red b-button_shadow_red b-button_size_md">OK</a>');
            $(modal.find('.b-modal-mini__buttons')).click((e) => {
                e.preventDefault();
                modal.remove();
            });
        } else {
            const color = progress > 57 ? '#fff' : '##1c1c1c';
            $(modal.find('.b-modal-mini__buttons')).html(`
        <div style="position: relative; margin: 0 25px; border: 1px solid #dddcdc; border-radius: 5px; background: #e5e5e4; height: 30px;">
          <div style="width: ${progress}%; height: 30px; border-radius: 5px; background: #f75d50;"></div>
          <div style="position: absolute; left: 50%; margin-left: -5px; top:20%; color: ${color}; font-weight: bold;">${progress}%</div>
          </div>
      `);
        }
    }
}

export const loading = text => {
    const modal = $(`
    <div class="b-modal-mini b-modal-mini-remove">
        <div class="b-modal-mini__overlay"></div>
        <div class="b-modal-mini__window b-modal-mini__window_small">
            <div class="b-modal-mini__img"></div>
            <div class="b-modal-mini__title">
                <div class="b-modal-mini__txt">${text}</div>
            </div>
        </div>
    </div>
  `);
    return action => {
        if (action === 'show') {
            $('.b-modal-mini').remove();
            $('body').append(modal);
        } else if (action === 'hide') {
            modal.remove();
        }
    }
};

export const gallery = url => {
    const modal = $(`
        <div class="b-modal-mini b-modal-mini-recovery-rejected">
            <div class="b-modal-mini__overlay"></div>
            <img src=${url} class="large_gallery_img">
        </div>
    `);
    $('.b-modal-mini').remove();
    $('body').append(modal);
    modal.find('.b-modal-mini__overlay').click((e) => {
        e.preventDefault();
        modal.remove();
    });
};

export async function countdown(text, delayTime) {
    const modal = $(`
        <div class="b-modal-mini b-modal-mini-recovery-rejected">
            <div class="b-modal-mini__overlay"></div>
            <div class="b-modal-mini__window b-modal-mini__window_small">
                <div class="b-modal-mini__img"></div>
                <div class="b-modal-mini__title">
                    <div class="b-modal-mini__txt">${text}</div>               
                </div>
                <div class="b-modal-mini__buttons">
                    <a class="b-button b-button-close b-button_red b-button_shadow_red b-button_size_md b-button_disabled" href="#">Закрыть (${delayTime})</a>
                </div>
            </div>
        </div>
    `);
    $('.b-modal-mini').remove();
    $('body').append(modal);
    let timer = setInterval(function () {
        delayTime -= 1;
        if (delayTime <= 0) {
            clearInterval(timer);
            modal.find('.b-button-close').removeClass('b-button_disabled').text('Закрыть').click((e) => {
                e.preventDefault();
                modal.remove();
            });
        } else {
            modal.find('.b-button-close').text(`Закрыть (${delayTime})`)
        }

    }, 1000);
};

let divId ;

export async function handyCountdown(text, delayTime) {
    divId ? $(`#${divId}`).remove() : null;
    divId = idGenerator();
    const modal = $(`
        <div style="z-index: ${Math.floor(Math.random() * 98) + 9900}; position: fixed; top: ${Math.floor(Math.random() * 15) + 0}px; right: ${Math.floor(Math.random() * 15) + 0}px; bottom: ${Math.floor(Math.random() * 15) + 0}px; left: ${Math.floor(Math.random() * 15) + 0}px;" id="${divId}">
            <div style="position: fixed; top: 0; right: ${Math.floor(Math.random() * 15) + 0}px; bottom: ${Math.floor(Math.random() * 15) + 0}px; left:0; width: 100%; height: 100%; opacity: .8; background-color: #1f2326;"></div>
            <div style="width: ${Math.floor(Math.random() * 21) + 314}px; padding-bottom: ${Math.floor(Math.random() * 15) + 0}px; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); position: absolute; top: 50%; left: 50%; text-align: center; background-color: #f1efeb; -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.35); box-shadow: 0 3px 10px rgba(0, 0, 0, 0.35); border-radius: 3px; overflow-y: auto; max-height: 100%;">
                <div style="height: 102px; margin: 10px auto; color: #373d42; background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9Imljb24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA5NiA5NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgOTYgOTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRjY1RDUwO30KPC9zdHlsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNDgsMEMyMS41LDAsMCwyMS41LDAsNDhjMCwyNi41LDIxLjUsNDgsNDgsNDhzNDgtMjEuNSw0OC00OEM5NiwyMS41LDc0LjUsMCw0OCwweiBNNDgsOTQKCQlDMjIuNiw5NCwyLDczLjQsMiw0OFMyMi42LDIsNDgsMnM0NiwyMC42LDQ2LDQ2UzczLjQsOTQsNDgsOTR6Ii8+CjwvZz4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSI3MC42LDI3LjggNjkuMiwyNi40IDQ4LDQ3LjYgMjYuOCwyNi40IDI1LjQsMjcuOCA0Ni42LDQ5IDI1LjQsNzAuMiAyNi44LDcxLjYgNDgsNTAuNCA2OS4yLDcxLjYgCgk3MC42LDcwLjIgNDkuNCw0OSAiLz4KPC9zdmc+') no-repeat center; width: 96px;"></div>
                <div style="font-size: 14px; font-weight: bold; line-height: 1.3; padding: 0 20px; width: 100%; word-wrap: break-word; position: relative; margin-bottom: 10px;">
                    <div>${text}</div>               
                </div>
                <div style=" display: block; bottom: 10px; left: 0; right: 0; text-align: center;">
                    <a class="b-button b-button-close b-button_red b-button_shadow_red b-button_size_md b-button_disabled" href="#">Закрыть (${delayTime})</a>
                </div>
            </div>
        </div>
    `);
    //$('.b-modal-mini').remove();
    $('body').append(modal);
    let timer = setInterval(function () {
        delayTime -= 1;
        if (delayTime <= 0) {
            clearInterval(timer);
            modal.find('.b-button-close').removeClass('b-button_disabled').text('Закрыть').click((e) => {
                e.preventDefault();
                modal.remove();
            });
        } else {
            modal.find('.b-button-close').text(`Закрыть (${delayTime})`)
        }

    }, 1000);
}
function idGenerator () {
    return '' + Math.random().toString(36).substr(2, 9);
};
