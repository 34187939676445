import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import DashboardTable from 'components/modules/analytics/base-components/DashboardTable';
import WidgetSelector from 'components/modules/analytics/base-components/WidgetSelector';
import GlobalLoaderComponent from "components/ui/global-loader";
import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import _ from 'lodash';
import './style.less';
import {connect} from "react-redux";
import {User} from "helpers/user";
import * as storage from "utils/storage";
import moment from "moment";
import {getTabletKiutrSummary, getTabletKiutrSummaryUnit} from "store/reducers/reports/tablet";
import Datepicker from "components/ui/form/datepicker";
import {
    Bar, BarChart, Label, LabelList, Pie, PieChart, ResponsiveContainer, Text, Tooltip, XAxis,
    YAxis, CartesianGrid, Legend, Cell, ComposedChart, Line, CartesianAxis
} from "recharts";
import Select from "components/ui/select";
import formats from "dictionaries/formats";
import ReactResizeDetector from 'react-resize-detector';

@propTypes({
    barChartFillColor: PropTypes.string,
    component: PropTypes.string,
})

@defaultProps({
    barChartFillColor: '',
    component: 'kiutr',
})

@connect(state => ({}), {getTabletKiutrSummary, getTabletKiutrSummaryUnit})

export default class AverageCarAge extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isError: false,
            isLoading: false,
            from: moment().subtract(1, 'day').format(formats.DATE_API),
            to: moment().subtract(1, 'day').format(formats.DATE_API),
            regularity: 'best',
            data: {},
            uuid: window.location.pathname.split('/')[4] || null
        };

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('dateFrom') && urlParams.get('dateTo')) {
            this.state.from = urlParams.get('dateFrom')
            this.state.to = urlParams.get('dateTo')
        }
    }

    componentWillMount() {
        this.props.isUnitStats ? this.loadDataUnit() : this.loadData();
    }

    async componentWillReceiveProps(nextProps) {
        await this.setState({
            municipality: nextProps.municipality
        })
        this.props.isUnitStats ? this.loadDataUnit() : this.loadData();
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value});
        this.props.isUnitStats ? this.loadDataUnit() : this.loadData();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value});
        this.props.isUnitStats ? this.loadDataUnit() : this.loadData();
    };

    async loadData() {
        this.setState({isLoading: true});
        let summaryOptions = {
            date_from: moment(this.state.from).format(formats.DATE_API),
            date_to: moment(this.state.to).format(formats.DATE_API),
            component: this.props.component,
            with_communal_municipalities: this.props.municipality
        };

        if (!window.RNIS_SETTINGS.analytic_filter_for_municipality) {
            delete summaryOptions.with_communal_municipalities
        }

        const response = await this.props.getTabletKiutrSummary(summaryOptions);


        if (response.isOk) {
             await this.setState({
                data: response.payload,
            });
            this.setState({isLoading: false});
        } else {
            response.showErrors();
        }
    }

    async loadDataUnit() {
        this.setState({isLoading: true});

        let summaryOptions = {
            date_from: moment(this.state.from).format(formats.DATE_API),
            date_to: moment(this.state.to).format(formats.DATE_API),
            component: this.props.component,
            uuid: this.state.uuid
        };

        if (!window.RNIS_SETTINGS.analytic_filter_for_municipality) {
            delete summaryOptions.with_communal_municipalities
        }

        const response = await this.props.getTabletKiutrSummaryUnit(summaryOptions);
        this.setState({isLoading: false});

        if (response.isOk) {
            this.setState({
                data: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    get(field, forDisplay = true) {
        const value = _.get(this.state.data, field, null);
        if (forDisplay) {
            if (value !== null) {
                return _.round(value, 2);
            } else {
                return '-';
            }
        }
        return value || 0;
    }

    renderContent() {
        return (
            <div className="full-size kiutr-widget averageCarAgeWidget">
                <div className="date-wrapper">
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange}/>
                    </div>
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange}/>
                    </div>
                </div>
                <div className="chart">
                    {this.props.isUnitStats ? this.renderRegularityChartUnits() : this.renderRegularityChart()}
                </div>
            </div>
        );
    }

    renderRegularityChart() {
        if (!this.state.data.units) return;

        let classes = [{
            type: "Особо малый класс ",
            amount: 0,
            count: 0,
            average_age: 0,
            permissible_age: 5,
            radius: [4, 4, 0, 0],
        }, {
            type: "Малый класс",
            amount: 0,
            count: 0,
            average_age: 0,
            permissible_age: 5,
            radius: [4, 4, 0, 0],
        },  {
            type: "Особо большой класс",
            amount: 0,
            count: 0,
            average_age: 0,
            permissible_age: 7,
            radius: [4, 4, 0, 0],
        },  {
            type: "Большой класс",
            amount: 0,
            count: 0,
            average_age: 0,
            permissible_age: 7,
            radius: [4, 4, 0, 0],
        }, {
            type: "Средний класс",
            amount: 0,
            count: 0,
            average_age: 0,
            permissible_age: 7,
            radius: [4, 4, 0, 0]}]


        this.state.data.units.forEach(unit => {
            unit.transport_class.forEach((unitClass => {
                let a = _.find(classes, {'type': unitClass.type});
                if (a) {
                    a.amount += unitClass.amount ? unitClass.amount : 0;
                    a.count++;
                    a.average_age += unitClass.average_age ? unitClass.average_age : 0;
                }
            }))
        });


        classes.forEach(c => {
            c.average_age = parseFloat((c.average_age / c.count).toFixed(2)) || 0;
            if (c.amount !== 0) {
                c.amount = c.amount + ' ТС';
            } else {
                delete c.amount
            }
        })



        return (
            <ResponsiveContainer>
                <ComposedChart  data={classes}
                          margin={{bottom: 60}} barGap='-19%' barCategoryGap='20%' isAnimationActive={false}>
                    <CartesianGrid horizontalPoints={[83, 45, 8]} strokeDasharray="3 3" vertical={false}/>
                    <XAxis dataKey="type" interval={0} angle={-45} textAnchor="end" />
                    <YAxis label={'Лет'} dataKey="permissible_age"/>
                    <Tooltip itemStyle={{color: '#000'}}/>
                    <Bar dataKey="permissible_age" fill="#ddd" name="Допустимый возраст" />
                    <Bar dataKey="average_age" name="Средний возраст" fill="#000">
                        {classes.map((entry, index) => (
                            <Cell fill={this.getFill(entry.average_age, entry.permissible_age)} key={index}/>
                        ))}
                        <LabelList dataKey="amount" position="top" fill="#666" />
                    </Bar>
                </ComposedChart >
            </ResponsiveContainer>
        );
    }

    renderRegularityChartUnits() {
        if (!this.state.data.routes) return;

        let classes = [{
            type: "Особо малый класс ",
            amount: 0,
            count: 0,
            average_age: 0,
            permissible_age: 5,
            radius: [4, 4, 0, 0],
        }, {
            type: "Малый класс",
            amount: 0,
            count: 0,
            average_age: 0,
            permissible_age: 5,
            radius: [4, 4, 0, 0],
        },  {
            type: "Особо большой класс",
            amount: 0,
            count: 0,
            average_age: 0,
            permissible_age: 7,
            radius: [4, 4, 0, 0],
        },  {
            type: "Большой класс",
            amount: 0,
            count: 0,
            average_age: 0,
            permissible_age: 7,
            radius: [4, 4, 0, 0],
        }, {
            type: "Средний класс",
            amount: 0,
            count: 0,
            average_age: 0,
            permissible_age: 7,
            radius: [4, 4, 0, 0]}]


        this.state.data.routes.forEach(unit => {
            unit.transport_class.forEach((unitClass => {
                let a = _.find(classes, {'type': unitClass.type});
                if (a) {
                    a.amount += unitClass.amount ? unitClass.amount : 0;
                    a.count++;
                    a.average_age += unitClass.average_age ? unitClass.average_age : 0;
                }
            }))
        });


        classes.forEach(c => {
            c.average_age = parseFloat((c.average_age / c.count).toFixed(2)) || 0;
            if (c.amount !== 0) {
                c.amount = c.amount + ' ТС';
            } else {
                delete c.amount
            }
        })

        return (
           /* <ResponsiveContainer>
                <BarChart data={classes}
                          margin={{bottom: 60}}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="type" interval={0} angle={-45} textAnchor="end" fontSize={10}/>
                    <YAxis/>
                    <Tooltip/>

                    <Bar dataKey="permissible_age" stackId="a" fill="#95B400" name="Допустимый возраст"/>

                    <Bar dataKey="average_age" name="Средний возраст" fill="#000">
                        {classes.map((entry, index) => (
                            <Cell fill={this.getFill(entry.average_age, entry.permissible_age)}/>
                        ))}
                        <LabelList dataKey="amount" position="insideTop" fill="#fff"/>
                    </Bar>
                </BarChart>
            </ResponsiveContainer>*/
            <ResponsiveContainer>
                <ComposedChart  data={classes}
                          margin={{bottom: 60}} barGap='-19%' barCategoryGap='20%'>
                    <CartesianGrid horizontalPoints={[83, 45, 8]} strokeDasharray="3 3" vertical={false}/>
                    <XAxis dataKey="type" interval={0} angle={-45} textAnchor="end" />
                    <YAxis label={'Лет'} dataKey="permissible_age"/>
                    <Tooltip itemStyle={{color: '#000'}}/>
                    <Bar dataKey="permissible_age" fill="#ddd" name="Допустимый возраст" />
                    <Bar dataKey="average_age" name="Средний возраст" fill="#000">
                        {classes.map((entry, index) => (
                            <Cell fill={this.getFill(entry.average_age, entry.permissible_age)} key={index}/>
                        ))}
                        <LabelList dataKey="amount" position="insideTop" fill="#fff" />
                    </Bar>
                </ComposedChart >
            </ResponsiveContainer>
        );
    }

    async onSelectedUnitsChange(param) {
        let data = this.state.data;
        _.each(param, (p) => {
            let index = p.index;
            let checked = p.checked;

            data.organizationUnits[index].checked = checked;
        });
        await this.setState({data});
        this.loadDriversData();
    }

    onChange(field, e) {
        const value = e.value || e.target.value;

        let state = this.state;
        state[field] = value;
        this.setState(state);
    }

    _onResize(width, height) {
        $( ".averageCarAgeWidget .recharts-responsive-container" ).css( "width", "99.9%").css("height", "99%");
    }

    render() {
        $( ".averageCarAgeWidget .recharts-responsive-container" ).css( "width", "99.9%").css("height", "99%");
        const loader = this.state.isLoading ? <GlobalLoaderComponent/> : null;

        return (
            <div className="kiutr-widget">
                {loader}
                {this.renderContent()}
                <ReactResizeDetector handleWidth handleHeight onResize={::this._onResize}/>
            </div>
        );
    }

    getFill(averageAge, permissibleAge) {
        let green = '#95B400';
        let orange = '#F4A44B';
        let red = '#F65C50'

        if (averageAge >= permissibleAge) {
            return red;
        }
        if (averageAge >= permissibleAge / 2) {
            return orange;
        }
        return green;
    }
}
