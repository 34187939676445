export default {
    'production_forward': 'Производственный рейс (прямой)',
    'production_reverse': 'Производственный рейс (обратный)',
    'parking': 'Стоянка',
    'dinner': 'Обед',
    'settling': 'Отстой',
    'gap': 'Разрыв',
    'refill': 'Заправка',
    'reshift': 'Пересменка',
    'null': 'Нулевой рейс',
    'techno': 'Технологический рейс',
    'switch_out': 'Переключение: исходящее',
    'switch_in': 'Переключение: входящее',
};