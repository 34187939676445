import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import './style.less';
import ContextTooltip from "components/ui/context-tooltip";

@propTypes({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    className: PropTypes.string,
    maximize: PropTypes.bool,
    onClose: PropTypes.func,
    onMaximize: PropTypes.func,
    onMinimize: PropTypes.func
})

@defaultProps({
    title: '',
    subtitle: '',
    className: '',
    maximize: false,
    onClose: () => {
    },
    onMaximize: () => {
    },
    onMinimize: () => {
    }
})

export default class WidgetBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMaximized: false
        };
    }

    /*onMaximize = () => {
        this.setState({ isMaximized: true });
        this.props.onMaximize();
    };

    onMinimize = () => {
        this.setState({ isMaximized: false });
        this.props.onMinimize();
    };*/

    onClick() {
        if (this.props.link) {
            this.props.router.push(this.props.link);
        }
    }

    render() {
        const containerClassName = classNames('widget-block', this.props.className, {
            'vert-offset': this.props.subtitle.trim().length > 0
        });

        return (
            <div className={containerClassName}>
                <div className="widget-block_header">
                    <div className="buttons">
                        <span className="act-btn act-btn_close" onClick={this.props.onClose}>&times;</span>
                        {this.props.maximize ?
                            <span className="act-btn act-btn_square" onClick={this.props.onMinimize}>&#x2212;</span>
                            :
                            <span className="act-btn act-btn_square" onClick={this.props.onMaximize}>&#x025FB;</span>}
                        {this.props.faq ? (
                            <ContextTooltip
                                default="Общий показатель рейтинга рассчитывается, как взвешенная сумма показателей предприятий. Например, если у 3 предприятий выполнено 99 из 99, 0 из 1 и 0 из 0 рейсов соответственно, то их рейтинги будут 100%, 0% и 0%, а общий рейтинг - 99%"><span
                                className="act-btn act-btn_faq">?</span></ContextTooltip>
                        ) : null}
                    </div>
                    <div className={classNames('title', this.props.titleClassName)}
                         onClick={::this.onClick}>{this.props.title}</div>
                    <span className="subtitle">{this.props.subtitle}</span>
                </div>
                <div className="widget-block_body">
                    {this.props.children}
                </div>
            </div>
        )
    }

}