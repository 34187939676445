import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';
import * as alerts from 'helpers/alerts';

const initialState = new Map({
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getRoutes = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getRoutes(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRoute = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getRoute(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createRoute = (route) => async (dispatch) => {
    try {
        const response = await api.geo.createRoute(route);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const cloneRoute = (route) => async (dispatch) => {
    try {
        const response = await api.geo.cloneRoute(route);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const cloneFillRoute = (data) => async (dispatch) => {
    try {
        const response = await api.geo.cloneFillRoute(data);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateRoute = (route) => async (dispatch) => {
    try {
        const response = await api.geo.updateRoute(route);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteRoute = (route) => async (dispatch) => {
    try {
        const response = await api.geo.deleteRoute(route);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};