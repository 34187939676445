import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import { itImage } from "helpers/files";

import './gallery.less';


@propTypes({
  files: PropTypes.array,
  onClose: PropTypes.func,
})

@defaultProps({
  files: [],
  onClose: () => {},
})

export default class Gallery extends Component {

  constructor(props) {
    super(props)

    this.state = {
      activeSlide: 0,
      zoom: 1,
      imgWidth: 0,
    }

    this.bindMouseDown = this.mouseDown.bind(this);
    this.bindMouseUp = this.mouseUp.bind(this);
    this.bindScrollImage = this.scrollImage.bind(this);
  }

  mouseIsDown = false;
  curSX = 0;
  curSY = 0;

  componentDidMount() {
    this.refs.imageContainerRef.addEventListener('mousedown', this.bindMouseDown)
    this.refs.imageContainerRef.addEventListener('mouseup', this.bindMouseUp)
    this.refs.imageContainerRef.addEventListener('mousemove', this.bindScrollImage)
  }

  componentWillUnmount() {
    this.refs.imageContainerRef.removeEventListener('mousedown', this.bindMouseDown)
    this.refs.imageContainerRef.removeEventListener('mouseup', this.bindMouseUp)
    this.refs.imageContainerRef.removeEventListener('mousemove', this.bindScrollImage)
  }

  mouseDown(e) {
    this.mouseIsDown = true;
    this.curSX = e.pageX;
    this.curSY = e.pageY;
  }

  mouseUp() {
    this.mouseIsDown = false;
  }

  scrollImage(e) {
    if (this.mouseIsDown) {
      this.refs.imageContainerRef.scrollBy(this.curSX - e.pageX, this.curSY - e.pageY);
      this.curSX = e.pageX;
      this.curSY = e.pageY;
    }
  }
  

  toPrevSlide() {
    this.setState((state, props) => ({
      activeSlide: state.activeSlide - 1 >= 0 ? state.activeSlide - 1 : props.files.length - 1,
      imgWidth: 0,
    }))
  }

  toNextSlide() {
    this.setState((state, props) => ({
      activeSlide: state.activeSlide + 1 < props.files.length ? state.activeSlide + 1 : 0,
      imgWidth: 0,
    }))
  }

  zoomIn() {
    if (this.state.zoom < 6) {
      const withGrab = this.state.imgWidth * (this.state.zoom + 1) > window.innerWidth
      this.setState({
        zoom: this.state.zoom + 1,
        withGrab,
      })
    }
  }

  zoomOut() {
    if (this.state.zoom > 1) {
      const withGrab = this.state.imgWidth * (this.state.zoom - 1) > window.innerWidth
      this.setState({
        zoom: this.state.zoom - 1,
        withGrab,
      })
    }
  }

  renderControls() {
    return (
      <div className="gallery__controls">
        <div
          role="button" tabIndex="1" className="gallery__controls_zoom"
          onClick={::this.zoomIn}
        >
          +
        </div>
        <div
          role="button" tabIndex="1" className="gallery__controls_zoom"
          onClick={::this.zoomOut}
        >
          -
        </div>
      </div>
    )
  }

  onLoadImage({ target: img }) {
    this.setState({
      imgWidth: img.offsetWidth,
    })
  } 

  render() {
    const currentFileUrl = this.props.files[this.state.activeSlide].url;

    return (
      <span>
        <div className="gallery_fade" onClick={this.props.onClose}>
        </div>
        <ModalTopMenuButton
            key="close"
            className="_close gallery_close"
            tooltip="Закрыть"
            onClick={this.props.onClose}
          />
          {this.props.files.length > 1 ? (
            <div
              role="button"
              tabIndex="0"
              className="gallery_btn-slide prev"
              onClick={::this.toPrevSlide}>
            </div>
          ) : null}
          {this.props.files.length > 1 ? (
            <div
              role="button"
              tabIndex="0"
              className="gallery_btn-slide next"
              onClick={::this.toNextSlide}>
            </div>
          ) : null}
        <div className="gallery__root">
          {currentFileUrl ? itImage(currentFileUrl) ? (
            <div
              className="gallery_file_container"
              style={this.state.withGrab ? {
                cursor: 'grab',
              } : {}}
              ref="imageContainerRef"
            >
              <img
                src={currentFileUrl}
                className="gallery_file"
                style={this.state.imgWidth ? {
                  width: this.state.imgWidth * this.state.zoom,
                } : {
                  maxWidth: '70vw',
                  maxHeight: '70vh',
                }}
                onLoad={::this.onLoadImage}
              />
              <span className="gallery_file_helper" />
            </div>
          ) : (
            <embed src={currentFileUrl} className="gallery_file-full" style={{
              transform: `scale(${this.state.zoom})`
            }} />
          ) : null}
          
        </div>
        {this.renderControls()} 
      </span>
    );
  }
}