import React from 'react';
import {reduxForm, Field, FieldArray} from 'redux-form';
import {connect} from 'react-redux';
import RenderTextField from './fields/RenderTextField';
import RenderDropzoneField from './fields/RenderDropzoneInput';
import RenderSelectField from './fields/RenderSelectField';
import ColorPicker from 'components/ui/color-picker';
import {getLayer} from "../../store/reducers/user-map-objects/layer_editor";


const options = [
    {value: 'text', text: 'Текст'},
    {value: 'number', text: 'Число'},
    {value: 'bool', text: 'Логическое'},
    {value: 'file', text: 'Файл'}
];

const scaleOptions = [
    {value: '1', text: '1:100'},
    {value: '2', text: '1:500'},
    {value: '3', text: '1:1000'},
    {value: '4', text: '1:5000'},
    {value: '5', text: '1:10000'},
    {value: '6', text: '1:50000'},
    {value: '7', text: '1:100000'},
];

const renderAttributes = ({fields}) => (
    <ul className="list-unstyled">
        {fields.map((attribute, index) =>
            <li key={index}>
                <Field name={`${attribute}.attrname`} type="text"
                       component={RenderTextField} label="Имя атрибута"
                       placeholder="Имя атрибута"
                />
                <Field name={`${attribute}.attrtype`} component={RenderSelectField} placeholder="Тип атрибута">
                    {options.map((option, index) => <option key={index} value={option.value}>{option.text}</option>)}
                </Field>
                <button type="button" onClick={() => fields.remove(index)}>Удалить</button>
            </li>
        )}
        <button onClick={() => fields.push({})}>Добавить атрибут</button>
    </ul>
);

class LayerForm extends React.Component {

    async componentDidMount() {
        this.props.reset('layerForm');
        if (this.props.isEditing && this.props.selectedLayer) {
           await this.props.getLayer(this.props.selectedLayer);
           this.props.initialize({
               layerName: this.props.layer
           })
        }
    }

    render() {
        const {handleSubmit, change, layer} = this.props;
        return (
            <div>
                <Field
                    name="layerName"
                    component={RenderTextField}
                    label="Наименование слоя"
                    type="text"
                />
                <Field
                    name="frameColor"
                    pickerName="frameColor"
                    component={ColorPicker}
                    change={change}
                    placeholder="Цвет рамки"
                />
                <Field
                    name="fillColor"
                    pickerName="fillColor"
                    component={ColorPicker}
                    change={change}
                    placeholder="Цвет заливки"
                />
                <Field
                    name="dotIcon"
                    component={RenderDropzoneField}
                />
                <Field name="layerOpacity"
                       component={RenderTextField}
                       placeholder="Прозрачность"
                       label="Прозрачность"
                       type="number"
                       min="0"
                       max="100"
                />
                <Field name="group" component={RenderSelectField} placeholder="Группа" label="Группа" />
                <Field name="scale" component={RenderSelectField} placheholder="Масштаб" label="Масштаб">
                    {scaleOptions.map((option, index) => <option key={index} value={option.value}>{option.text}</option>)}
                </Field>
                <FieldArray name="attributes" component={renderAttributes}/>
                <button onClick={handleSubmit((values) => console.log('VALUES', values))}>Сохранить</button>
            </div>
        );
    }
}

const ConnectedForm = reduxForm({
   form: 'layerForm'
})(LayerForm);

const mapStateToProps = (state) => ({
    layer: state.user_map_objects.layer_editor.get('layer').title
});

const mapDispatchToProps = dispatch => ({
   getLayer: uuid => dispatch(getLayer(uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedForm);