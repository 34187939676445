export const ReportsMap = {
    'road_part_combined_report': '/reports/map/road_part_combined_report/',
    'telematics_history_report': '/reports/map/telematics_history_report/',
    'communal_telematics_history_report': '/reports/map/telematics_history_report/',
    'kiutr_telematics_history_report': '/reports/map/telematics_history_report/',
    'vehicles_late_telematics_report': '/reports/graphic/',
    'vehicles_connect_report': '/reports/graphic/',
    'routes_length_report': '/reports/graphic/',
    'component_route_rates_report': '/reports/graphic/',
    'component_route_connections_report': '/reports/graphic/',
    'telematics_insert_report': '/reports/graphic/',
    'kurs_vehicle_fuel_level_report': '/reports/graphic/',
    'children_vehicle_fuel_level_report': '/reports/graphic/',
    'gatn_vehicle_fuel_level_report': '/reports/graphic/',
    'kiutr_vehicle_fuel_level_report': '/reports/graphic/',
    'contracts_street_maintenance_map_report': '/reports/map/street_maintenance_map_report/'
};

export const ReportsWithoutPdf = [
    'kiutr_route_vehicles_count_report',
    'kiutr_vehicles_count_report',
    'carrier_source_for_failures',
    'vehicle_count_work_report',
    'carrier_vehicle_turns',
    'route_summary_report',
    'contract_runs_count_report',
    'work_implementation_report',
];
