import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import classNames from 'classnames';
import $ from 'jquery';

import './accordion.less';

@propTypes({
    title: PropTypes.any,
    afterTitle: PropTypes.object,
    opened: PropTypes.bool,
    single: PropTypes.bool,
    collapsable: PropTypes.bool,
    withoutWrapper: PropTypes.bool,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    className: PropTypes.string,
})

@defaultProps({
    title: '',
    afterTitle: null,
    opened: false,
    single: false,
    collapsable: true,
    withoutWrapper: false,
    onOpened: () => {
    },
    onClosed: () => {
    },
    className: '',
})

export default class AccordionItem extends Component {
    componentDidUpdate() {
        const hasError = $(this.refs.children).find('.input__style_state_wrong').length > 0;
        $(this.refs.container).toggleClass('with_error', hasError);
    }

    stateChanged = (e) => {
        if (e.target.checked) {
            this.props.onClosed();
        }
        else {
            this.props.onOpened();
        }
    };

    toggle() {
        $(this.refs.checkbox).click();
    }

    render() {
        const className = classNames({
            accordion__item: true,
        });

        if (this.props.single) {
            return (
                <div className={className} ref="container">
                    <div className="accordion__content">
                        <div className={classNames('accordion__posit accordion__posit_top-bord', this.props.className)}
                             ref="children">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            );
        }

        const content = this.props.withoutWrapper ? (
            this.props.children
        ) : (
            <div className={classNames('accordion__posit accordion__posit_top-bord', this.props.className)}
                 ref="children">
                {this.props.children}
            </div>
        );

        return (
            <div className={className} ref="container">
                {this.props.collapsable ? ([
                    <input key="input" ref="checkbox" className="accordion__open" type="checkbox"
                           defaultChecked={!this.props.opened}
                           onClick={this.stateChanged}
                    />,
                    (content.length ? <i key="icon" className="accordion__arrow"/> : null),
                ]) : null}
                {this.props.title ? (
                    <div className="accordion__title"><span>{this.props.title}</span></div>
                ) : null}
                {this.props.afterTitle}
                {this.props.label ? (
                    <label className="accordion__dscn" onClick={::this.toggle}>
                        {this.props.label}
                    </label>
                ) : null}
                <div className="accordion__content">
                    {content}
                </div>
            </div>
        );
    }
}