import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import Colors from '../../helpers/colors';

import './percent-viewer.less'


@propTypes({
  progress: PropTypes.number,
  color: PropTypes.oneOf(Colors.All),
  fill: PropTypes.oneOf(Colors.All),
  className: PropTypes.string
})

@defaultProps({
  progress: 10,
  fill: Colors.grayLight,
  color: Colors.asphalt2
})

export default class PercentViewer extends Component {

  render() {
    const className = classNames('Percent-Viewer', this.props.className);
    const style = {
      color: this.props.color,
      background: this.props.fill
    };

    return (
      <div className={className} style={style}>
        {this.props.progress}<span>%</span>
      </div>
    );
  }

}
