import _ from 'lodash';
import moment from "moment";
import formats from "dictionaries/formats";
import {User} from "helpers/user";

export function duplicationMatrixPrintSummary({withCss, routes, matrixRoutes, matrix, related, transport_connection_types, route_kinds}) {
    const fields = {
        carriers: 'Перевозчики',
        units: 'Заказчики',
        tos: 'Территории',
        contracts: window.RNIS_SETTINGS.rename_contracts ? 'Подрядные обязательства' : 'Контракты',
        routes: 'Маршруты',
        transport_connection_types: 'Виды сообщений',
        route_types: 'Типы маршрутов',
        rates_types: 'Виды тарифов',
    };

    return `
        ${withCss ? '<link rel="stylesheet" href="/css/duplication-matrix-print.css">' : ''}
        <div class="container print">
            <table>
                <thead>
                    <tr>
                      <th colspan="16" class="border-none text-right font-normal">Сводная таблица</th>
                    </tr>
                    <tr>
                      <th colspan="16" class="border-none">&nbsp;</th>
                    </tr>
                    <tr>
                      <th colspan="10" class="border-none text-center font-bold" style="font-size: 16px">Матрица дублирования маршрутов</th>
                    </tr>
                    <tr>
                        <th colspan="10" class="border-none text-center font-bold">Дата формирования: ${moment().format(formats.DATE)}</th>
                    </tr>
                    ${_.map(fields, (label, field) => {
                        const values = _.get(matrix, field) || [];
                        if (values.length === 0) {
                            return '';
                        }
                
                        return `<tr>
                                    <th colspan="3" class="border-none text-left font-bold">${label}:</th>
                                    <th colspan="7" class="border-none text-left font-normal">${_.map(values, (value) => {
                                        return related.get(value);
                                    }).join(', ')}</th>
                                </tr>`;                 
                            }).join('')}
                    <tr>
                      <th colspan="10" class="border-none">&nbsp;</th>
                    </tr>
                    <tr>
                        <th>№</th>
                        <th>Номер</th>
                        <th>Рег. номер</th>
                        <th>Наименование</th>
                        <th>Дата внесения</th>
                        <th>Вид сообщения</th>
                        <th>Тип маршрута</th>
                        <th>Территориальные субъекты</th>
                        <th>ИИД-П</th>
                        <th>ИИД-О</th>
                    </tr>
                </thead>
                <tbody>
                    ${_.map(_.values(routes), (route, index) => {
                        const matrixRoutes2 = _.filter(matrixRoutes, {
                            route_uuid: route.uuid,
                        });
                        const iidp = _.sumBy(matrixRoutes2, 'idp');
                        const iido = _.sumBy(matrixRoutes2, 'ido');
                        
                        return `<tr>
                            <td class="border-left border-right">${index + 1}</td>
                            <td class="border-right">${route.number}</td>
                            <td class="border-right">${route.registration_number || ''}</td>
                            <td class="border-right">${route.title}</td>
                            <td class="border-right">${moment(route.created_at).format(formats.DATETIME_SHORT)}</td>
                            <td class="border-right">${route.transport_connection_type_uuid && _.get(_.find(transport_connection_types, {value: route.transport_connection_type_uuid}), 'label') || ''}</td>
                            <td class="border-right">${route.route_kind_uuid && _.get(_.find(route_kinds, {value: route.route_kind_uuid}), 'label') || ''}</td>
                            <td class="border-right"></td>
                            <td class="border-right">${_.round(iidp, 2)}</td>
                            <td class="border-right">${_.round(iido, 2)}</td>
                        </tr>`;                
                    }).join('')}
                </tbody>
            </table>
        </div>
    `;
}

export function duplicationMatrixPrintRoutes({withCss, route, routes, matrixRoutes, matrix, related, transport_connection_types, route_kinds}) {
    const fields = {
        carriers: 'Перевозчики',
        units: 'Заказчики',
        tos: 'Территории',
        contracts: window.RNIS_SETTINGS.rename_contracts ? 'Подрядные обязательства' : 'Контракты',
        routes: 'Маршруты',
        transport_connection_types: 'Виды сообщений',
        route_types: 'Типы маршрутов',
        rates_types: 'Виды тарифов',
    };

    return `
        ${withCss ? '<link rel="stylesheet" href="/css/duplication-matrix-print.css">' : ''}
        <div class="container print">
            <table>
                <thead>
                    <tr>
                        <th colspan="16" class="border-none text-right font-normal">Детализация по маршруту</th>
                    </tr>
                    <tr>
                        <th colspan="16" class="border-none text-right font-normal">${route.number} ${route.title}</th>
                    </tr>
                    <tr>
                        <th colspan="16" class="border-none text-right font-normal">Создан ${moment(route.created_at).format(formats.DATE)}</th>
                    </tr>
                    <tr>
                        <th colspan="16" class="border-none">&nbsp;</th>
                    </tr>
                    <tr>
                        <th colspan="10" class="border-none text-center font-bold">Матрица дублирования участка маршрута</th>
                    </tr>
                    <tr>
                        <th colspan="10" class="border-none text-center font-bold">Дата формирования: ${moment().format(formats.DATE)}</th>
                    </tr>
                    ${_.map(fields, (label, field) => {
                        const values = _.get(matrix, field) || [];
                        if (values.length === 0) {
                            return '';
                        }
                        
                        return `<tr>
                            <th colspan="3" class="border-none text-left font-bold">${label}:</th>
                            <th colspan="7" class="border-none text-left font-normal">${_.map(values, (value) => {
                                return related.get(value);
                            }).join(', ')}</th>
                        </tr>`;                 
                    }).join('')}
                    <tr>
                        <th colspan="10" class="border-none">&nbsp;</th>
                    </tr>
                    <tr>
                        <th>№</th>
                        <th>ИН-П, %</th>
                        <th>ИН-О, %</th>
                        <th>Номер</th>
                        <th>Рег. номер</th>
                        <th>Наименование</th>
                        <th>Дата внесения</th>
                        <th>Вид сообщения</th>
                        <th>Тип маршрута</th>
                        <th>Территориальные субъекты</th>
                    </tr>
                </thead>
                <tbody>
                    ${_.map(matrixRoutes, (matrixRoute, index) => {
                        const route2 = _.get(routes, matrixRoute.route2_uuid);
                        if (!route2) {
                            return;
                        }
                        return `<tr>
                            <td class="border-left border-right">${index + 1}</td>
                            <td class="border-right">${_.round(matrixRoute.inp * 100, 2)}</td>
                            <td class="border-right">${_.round(matrixRoute.ino * 100, 2)}</td>
                            <td class="border-right">${route2.number || ''}</td>
                            <td class="border-right">${route2.registration_number || ''}</td>
                            <td class="border-right">${route2.title || ''}</td>
                            <td class="border-right">${moment(route2.created_at).format(formats.DATETIME_SHORT)}</td>
                            <td class="border-right">${route2.transport_connection_type_uuid ? _.get(_.find(transport_connection_types, {value: route2.transport_connection_type_uuid}), 'label') : ''}</td>
                            <td class="border-right">${route2.route_kind_uuid ? _.get(_.find(route_kinds, {value: route2.route_kind_uuid}), 'label') : ''}</td>
                            <td class="border-right"></td>
                        </tr>`;
                    }).join('')}
                </tbody>
                <tfoot class="font-bold" style="border-top: 2px solid #000000">
                    <tr>
                        <td colspan="10" class="text-left border-left border-right">Интегральные значения: ИН-П = ${_.round(_.sumBy(matrixRoutes, 'idp'), 2)}, ИН-О = ${_.round(_.sumBy(matrixRoutes, 'ido'), 2)}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    `;
}

export function duplicationMatrixPrintPart({withCss, stopPoint, stopPoint2, partLength, matrixRoutes, related, matrix, routes, routeVariants, transport_connection_types, route_kinds}) {
    const fields = {
        carriers: 'Перевозчики',
        units: 'Заказчики',
        tos: 'Территории',
        contracts: window.RNIS_SETTINGS.rename_contracts ? 'Подрядные обязательства' : 'Контракты',
        routes: 'Маршруты',
        transport_connection_types: 'Виды сообщений',
        route_types: 'Типы маршрутов',
        rates_types: 'Виды тарифов',
    };

    return `
        ${withCss ? '<link rel="stylesheet" href="/css/duplication-matrix-print.css">' : ''}
        <div class="container print">
            <table>
                <thead>
                    <tr>
                        <th colspan="16" class="border-none text-right font-normal">Участок (${partLength} км)</th>
                    </tr>
                    <tr>
                        <th colspan="16" class="border-none text-right font-normal">${stopPoint} - ${stopPoint2}</th>
                    </tr>
                    <tr>
                        <th colspan="16" class="border-none">&nbsp;</th>
                    </tr>
                    <tr>
                        <th colspan="10" class="border-none text-center font-bold">Матрица дублирования участка маршрута</th>
                    </tr>
                    <tr>
                        <th colspan="10" class="border-none text-center font-bold">Дата формирования: ${moment().format(formats.DATE)}</th>
                    </tr>
                    ${_.map(fields, (label, field) => {
                        const values = _.get(matrix, field) || [];
                        if (values.length === 0) {
                            return '';
                        }
                
                        return `<tr>
                            <th colspan="3" class="border-none text-left font-bold">${label}:</th>
                            <th colspan="7" class="border-none text-left font-normal">${_.map(values, (value) => {
                                return related.get(value);
                            }).join(', ')}</th>
                        </tr>`;
                    }).join('')}
                    <tr>
                        <th colspan="10" class="border-none">&nbsp;</th>
                    </tr>
                    <tr>
                        <th>№</th>
                        <th>ИДП, %</th>
                        <th>ИДО, %</th>
                        <th>Номер</th>
                        <th>Рег. номер</th>
                        <th>Наименование</th>
                        <th>Дата внесения</th>
                        <th>Вид сообщения</th>
                        <th>Тип маршрута</th>
                        <th>Территориальные субъекты</th>
                    </tr>
                </thead>
                <tbody>
                    ${_.map(matrixRoutes, (matrixRoute, index) => {
                        const route2 = _.get(routes, matrixRoute.route2_uuid);
                        if (!route2) {
                            return;
                        }
                        const routeVariantsLength = _.keys(routeVariants).length;
                        
                        return `<tr>
                            <td class="border-left border-right">${index + 1}</td>
                            <td class="border-right">${(routeVariantsLength > 0) ? _.round(matrixRoute.idp / routeVariantsLength, 2) : 0}</td>
                            <td class="border-right">${(routeVariantsLength > 0) ? _.round(matrixRoute.ido / routeVariantsLength, 2) : 0}</td>
                            <td class="border-right">${route2.number || ''}</td>
                            <td class="border-right">${route2.registration_number || ''}</td>
                            <td class="border-right">${route2.title || ''}</td>
                            <td class="border-right">${moment(route2.created_at).format(formats.DATETIME_SHORT)}</td>
                            <td class="border-right">${route2.transport_connection_type_uuid ? _.get(_.find(transport_connection_types, {value: route2.transport_connection_type_uuid}), 'label') : ''}</td>
                            <td class="border-right">${route2.route_kind_uuid ? _.get(_.find(route_kinds, {value: route2.route_kind_uuid}), 'label') : ''}</td>
                            <td class="border-right"></td>
                        </tr>`;
                    }).join('')}
                </tbody>
                <tfoot class="font-bold" style="border-top: 2px solid #000000">
                    <tr>
                        <td colspan="10" class="text-left border-left border-right">Интегральные значения: ИИД-П = ${_.round(_.sumBy(matrixRoutes, 'idp'), 2)}, ИИД-О = ${_.round(_.sumBy(matrixRoutes, 'ido'), 2)}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    `;
}