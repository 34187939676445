import React, { Component } from 'react';
import GlobalLoader from 'components/ui/global-loader';
import * as store from 'utils/storage';

export default class Refresh extends Component {
    componentDidMount() {
        store.clear()
        console.log('localStorage clear')
        this.props.router.push('/auth');
    }

    render() {
        return <GlobalLoader/>
    }
}