import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';
import Page from 'components/ui/page';
import TableContainer from 'components/ui/Table/Container/TableContainer';
import SlideLeftTransition from 'components/ui/transitions/slide-left';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import formats from 'dictionaries/formats';
import {getEntityNames} from 'store/reducers/system';
import {getUnits} from 'store/reducers/staffing/staffing';
import {getRouteRegistries, getRouteRegistry} from 'store/reducers/kiutr/route_registries';
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {EntityList} from 'helpers/entity';
import systems from 'dictionaries/systems';
import {mapDatatablesRequestToMeta} from 'helpers/api';
import Datepicker from "components/ui/form/datepicker";
import IconButton from 'components/ui/icon-button';
import {propTypes, defaultProps} from 'react-props-decorators';
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";
import GlobalLoaderComponent from "components/ui/global-loader";
import HeaderTextButton from 'components/ui/header-text-button';
import ContextTooltip from "components/ui/context-tooltip";
import RouteInfo from './RouteInfo';
import RTiOForm from './RTiOForm';
import ShiftMapForm from './ShiftMapForm';
import KiutrTransportWorkViolations from '../violations';
import * as alerts from 'helpers/alerts';
//import RouteHeader from './RouteHeader';

import './style.less';
import {component_mapper} from "helpers/component_mapper";

const moment = extendMoment(Moment);

function getDays(start, end, start2, end2) {
    let days = [];

    //const start = moment('02.10.2017', formats.DATE);
    //const end   = moment('05.10.2017', formats.DATE);
    const range = moment.range(start, end);

    //const start2 = moment('01.10.2017', formats.DATE);
    //const end2   = moment('02.10.2017', formats.DATE);
    const range2 = moment.range(start2, end2);

    const range3 = range.intersect(range2);

    if (range3) {
        days = Array.from(range3.by('day'));
        days = days.map(day => day.format(formats.DATE));
    }
    else {
        if (start.isSame(end2)) {
            days = [start.format(formats.DATE)];
        }
        else if (end.isSame(start2)) {
            days = [end.format(formats.DATE)];
        }
    }

    return days;
}

@propTypes({
    uuids: PropTypes.array,
    isCurrent: PropTypes.bool,
    from: PropTypes.string,
    to: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onGoToViolations: PropTypes.func.isRequired
})

@defaultProps({
    uuids: [],
    isCurrent: false,
    from: moment().startOf('year').format(formats.DATE),
    to: moment().format(formats.DATE)
})

@connect(state => ({}), {getRouteRegistries, getRouteRegistry, getEntityNames, getUnits, getDictionaryList})

export default class KiutrTransportWorkDispatcher extends React.Component {

    getTitle() {
        return `${systems[component_mapper(this.props.params.component)]} → Детализация по рейсам`;
    }

    constructor(props) {
        super(props);

        this.state = {
            from: moment().startOf('year'),
            to: moment().endOf('day'),
            isLoading: false,
            data: [],
            openedRoutes: {},

            isCurrent: false,

            rtioData: {},
            isShowRTiOForm: false,
            mapData: {},
            isShowMapForm: false,

            isDataFetched: false
        };
    }

    componentWillMount() {
        this.setState({
            from: moment(this.props.from, formats.DATE),
            to: moment(this.props.to, formats.DATE),
            isCurrent: this.props.isCurrent
        });

        this.loadData();
    }

    componentWillReceiveProps(nextProps) {
        const from = moment(this.state.from).format(formats.DATE);
        const to = moment(this.state.to).format(formats.DATE);

        if (from !== nextProps.from || to !== nextProps.to || this.state.isCurrent !== nextProps.isCurrent) {
            this.setState({
                from: moment(nextProps.from, formats.DATE),
                to: moment(nextProps.to, formats.DATE),
                isCurrent: nextProps.isCurrent
            });
        }
    }

    recomputeDays(from, to) {
        this.state.data.forEach((item) => {
            const days = getDays(moment(from, formats.DATE_URL),
                moment(to, formats.DATE_URL),
                moment(_.get(item, 'contract.work_from'), formats.DATE_URL),
                moment(_.get(item, 'contract.work_to'), formats.DATE_URL));

            item.days = days;
        });
    }

    onCurrentClick = () => {
        const from = moment(new Date(), formats.DATE);
        const to = moment(new Date(), formats.DATE);

        this.recomputeDays(from, to);

        this.setState({
            from,
            to,
            isCurrent: true
        });
    };

    getCurrentButton() {
        if (!this.state.isCurrent) {
            return <HeaderTextButton text="Сегодня" onClick={this.onCurrentClick}/>
        }

        return null;
    }

    getUuids() {
        return this.state.isDataFetched ? this.state.data.map((item) => item.uuid) : this.props.uuids;
    }

    onGoToViolations = () => {
        this.props.onGoToViolations(/*this.getUuids(),*/ moment(this.state.from).format(formats.DATE), moment(this.state.to).format(formats.DATE));
    };

    renderHeaderActions() {
        return [
            this.getCurrentButton(),
            <ContextTooltip key="dispatcher.goto-violations" code="dispatcher.goto-violations"
                            default="К нарушениям">
                <IconButton icon="violations" onClick={this.onGoToViolations}/>
            </ContextTooltip>,
            <HeaderTextButton text="К списку" onClick={this.props.onClose}/>
        ];
    }

    fromChange = async ({target: {value}}) => {
        /*if (moment(value, formats.DATE_URL).isAfter(moment(this.state.to, formats.DATE_URL))) {
            const prevDate = moment(this.state.from).format(formats.DATE_API);
            await this.setState({from: prevDate});
            alerts.error('Неверный диапазон дат');
        }
        else {*/
            this.recomputeDays(value, this.state.to);
            await this.setState({from: value});
            this.loadData();
        //}
    };

    toChange = async ({target: {value}}) => {
        /*if (moment(value, formats.DATE_URL).isBefore(moment(this.state.from, formats.DATE_URL))) {
            const prevDate = moment(this.state.to).format(formats.DATE_API);
            await this.setState({to: prevDate});
            alerts.error('Неверный диапазон дат');
        }
        else {*/
            this.recomputeDays(this.state.from, value);
            await this.setState({to: value});
            this.loadData();
        //}
    };

    renderHeaderContents() {
        return [
            <div key="diapason">
                <div className="top-menu__label">Дата с:</div>
                <Datepicker style="dark" value={this.state.from} onChange={this.fromChange}
                            disabled={this.state.isCurrent}/>
            </div>,
            <div key="diapason">
                <div className="top-menu__label">Дата по:</div>
                <Datepicker style="dark" value={this.state.to} onChange={this.toChange}
                            disabled={this.state.isCurrent}/>
            </div>
        ];
    }

    async loadMainData() {
        const meta = {
            filters: {
                withUuid: this.props.uuids,
                withContractWorkPeriod: [
                    this.props.from,
                    this.props.to,
                ],
            },
        };

        const response = await this.props.getRouteRegistries(meta);

        if (response.isOk) {
            return response.payload.items;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadVehicleModels() {
        let data = {};

        const response = await this.props.getDictionaryList('vehicle_models');
        if (response.isOk) {
            const vehicleModels = response.payload.documents;

            vehicleModels.forEach((item) => {
                data[item.uuid] = item;
            });
            return data;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadScheduleStatuses() {
        let data = {};

        const response = await this.props.getDictionaryList('schedule_statuses');
        if (response.isOk) {
            const scheduleStatuses = response.payload.documents;

            scheduleStatuses.forEach((item) => {
                data[item.uuid] = item;
            });
            return data;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadSeasons() {
        let data = {};

        const response = await this.props.getDictionaryList('seasons');
        if (response.isOk) {
            const seasons = response.payload.documents;

            seasons.forEach((season) => {
                const months = season.months.replace(/\s/g, '').split(',');
                months.forEach((month) => {
                    data[month] = season.uuid;
                });
            });

            return data;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadRouteScheduleTypes() {
        let data = {};

        const response = await this.props.getDictionaryList('route_schedule_types');
        if (response.isOk) {
            const routeScheduleTypes = response.payload.documents;

            routeScheduleTypes.forEach((type) => {
                const days = type.days.replace(/\s/g, '').split(',');
                days.forEach((day) => {
                    data[day] = type.uuid;
                });
            });

            return data;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadVehicleCapacityTypes() {
        let data = {};

        const response = await this.props.getDictionaryList('vehicle_capacity_types');
        if (response.isOk) {
            const vehicleCapacityTypes = response.payload.documents;

            vehicleCapacityTypes.forEach((type) => {
                data[type.uuid] = {
                    name: type.name,
                    shortName: type['short_name']
                };
            });

            return data;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadVehicleEnvironmentClasses() {
        let data = {};

        const response = await this.props.getDictionaryList('vehicle_environmental_classes');
        if (response.isOk) {
            const vehicleEnvironmentClasses = response.payload.documents;

            vehicleEnvironmentClasses.forEach((item) => {
                data[item.uuid] = {name: item.name};
            });

            return data;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadRelatedEntities(data) {
        const units = _.map(_.filter(_.concat(_.map(data, 'contract.unit_uuid'), _.map(data, 'contract.carrier_uuid'))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(units);

        if (response.isOk) {
            return response.payload.items;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadData() {
        this.setState({isLoading: true});
        const data = await this.loadMainData();

        if (data) {
            const relatedData = await this.loadRelatedEntities(data);

            if (relatedData) {
                //console.log(relatedData);
                const seasons = await this.loadSeasons();

                if (seasons) {
                    const routeScheduleTypes = await this.loadRouteScheduleTypes();

                    if (routeScheduleTypes) {
                        const vehicleCapacityTypes = await this.loadVehicleCapacityTypes();

                        const vehicleEnvironmentClasses = await this.loadVehicleEnvironmentClasses();

                        if (vehicleEnvironmentClasses) {

                            const vehicleModels = await this.loadVehicleModels();

                            const scheduleStatuses = await this.loadScheduleStatuses();

                            if (vehicleModels) {

                                data.forEach((item) => {
                                    const relatedItem = _.find(relatedData, {'uuid': _.get(item, 'contract.carrier_uuid')});

                                    if (relatedItem) {
                                        _.set(item, 'contract.unit_title', relatedItem.name)
                                    }

                                    const days = _.reverse(getDays(moment(this.state.from, formats.DATE_URL),
                                        moment(this.state.to, formats.DATE_URL),
                                        moment(_.get(item, 'contract.work_from'), formats.DATE_URL),
                                        moment(_.get(item, 'contract.work_to'), formats.DATE_URL)));

                                    item.days = days;
                                    item.seasons = seasons;
                                    item.routeScheduleTypes = routeScheduleTypes;
                                    item.vehicleCapacityTypes = vehicleCapacityTypes;
                                    item.vehicleEnvironmentClasses = vehicleEnvironmentClasses;
                                    item.vehicleModels = vehicleModels;
                                    item.scheduleStatuses = scheduleStatuses;
                                    item.onRTiOClick = this.onRTiOClick;
                                    item.onMapClick = this.onMapClick;
                                    item.isCurrent = this.state.isCurrent;

                                });

                                this.setState({isLoading: false, data, isDataFetched: true});
                            }
                        }
                    }
                }
            }
        }
    }

    onRTiOClick = (data) => {
        this.setState({isShowRTiOForm: true, rtioData: data});
    };

    onCloseRTiOForm = () => {
        this.setState({isShowRTiOForm: false});
    };

    renderRTiOForm() {
        return this.state.isShowRTiOForm
            ? <RTiOForm
                key="rtio_form"
                data={this.state.rtioData}
                onClose={this.onCloseRTiOForm}
            />
            : null;
    }

    onMapClick = (data) => {
        this.setState({isShowMapForm: true, mapData: data});
    };

    onCloseMapForm = () => {
        this.setState({isShowMapForm: false});
    };

    renderMapForm() {
        return this.state.isShowMapForm
            ? <ShiftMapForm
                key="shift-map_form"
                data={this.state.mapData}
                onClose={this.onCloseMapForm}
            />
            : null;
    }

    onOpened(uuid, date) {
        const openedRoutes = this.state.openedRoutes;
        openedRoutes[uuid + '_' + date] = true;
        this.setState({openedRoutes});
    }

    renderRouteDay(data, date, index) {
        return (
            <RouteInfo key={index}
                       data={data}
                       load={true}
                       params={this.props.params}
                       date={date}
            />
        );
    }

    renderRouteData() {
        return (
            <div>
                {this.state.data.map((item, index) => {
                    item.isCurrent = this.state.isCurrent;
                    return (
                        <div key={index}>
                            {item.days.map((day, index) =>
                                this.renderRouteDay(item, day, index)
                            )}
                        </div>
                    );
                })
                }
            </div>
        );
    }

    render() {
        const rtioForm = this.renderRTiOForm();
        const mapForm = this.renderMapForm();

        return (
            <Page title={this.getTitle()}
                  pageId="TransportWorkDispatcher"
                  headerActions={this.renderHeaderActions()}
                  headerContents={this.renderHeaderContents()}>

                {this.state.isLoading ? <GlobalLoaderComponent/> :
                    <div>
                        {this.renderRouteData()}
                        <SlideLeftTransition>
                            {rtioForm}
                        </SlideLeftTransition>
                        <SlideLeftTransition>
                            {mapForm}
                        </SlideLeftTransition>
                    </div>
                }
            </Page>
        );
    }
}
