import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getWaybills = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.kurs.getWaybills(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getWaybill = (uuid) => async (dispatch) => {

    try {
        const response = await api.kurs.getWaybill(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createWaybill = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.createWaybill(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateWaybill = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.updateWaybill(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteWaybill = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.deleteWaybill(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getDriversScoreSummary = (drivers) => async (dispatch) => {
    try {
        const response = await api.kurs.getDriversScoreSummary(drivers);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getFreeDrivers = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.kurs.getFreeDrivers(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};