import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import _ from 'lodash';

import Editor from './editor';
import {Link} from "react-router";

import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import Column from "components/ui/column";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import {deleteStopPoint, getStopPoints} from "store/reducers/geo/stop-points";
import systems from "dictionaries/systems";
import {deleteSupernumerarySituation, getSupernumerarySituations} from "store/reducers/commdept/supernumerary_situations";
import moment from "moment";
import formats from "dictionaries/formats";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";

@connect(state => ({}), {getSupernumerarySituations, deleteSupernumerarySituation, getEntityNames, getDictionaryList})

export default class CommdeptSupernumerarySituationsComponent extends BaseTableWithEditorComponent {

    statuses = {
        opened: 'Открыт',
        closed: 'Закрыт',
    };

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            supernumerary_situation_types: {},
        });
    }

    getTitle() {
        return <span>{systems.commdept} → Внештатные ситуации</span>;
    }

    baseUrl = '/commdept/supernumerary-situations';

    componentDidMount() {
        this.loadDictionaries([
            'supernumerary_situation_types',
        ]);
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Тип')
                .fromField('supernumerary_situation_type_uuid')
                .denyColumnFilter()
                .withDrawer(item => _.get(this.state.supernumerary_situation_types[item.supernumerary_situation_type_uuid], 'type'))
                .withFilter('withSupernumerarySituationType', async () => {
                    const response = await this.props.getDictionaryList('supernumerary_situation_types');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }),

            new Column('Внештатная ситуация')
                .fromField('supernumerary_situation_type_uuid')
                .denyColumnFilter()
                .withDrawer(item => _.get(this.state.supernumerary_situation_types[item.supernumerary_situation_type_uuid], 'name')),

            new Column('Дата и время')
                .fromField('date_from')
                .withDateFilter()
                .withDrawer(item => {
                    return moment(moment(item.date_from).format(formats.DATE_URL) + ' ' + item.time_from).format(formats.DATETIME_SHORT);
                }),

            new Column('Время решения')
                .fromField('date_to')
                .withDateFilter()
                .withDrawer(item => {
                    if (!item.date_to || !item.time_to) {
                        return null;
                    }
                    return moment(moment(item.date_to).format(formats.DATE_URL) + ' ' + item.time_to).format(formats.DATETIME_SHORT);
                }),

            new Column('Статус')
                .fromField('status')
                .withDrawer(item => this.statuses[item.status]),

            new Column('Превышения норматива, мин')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer((item) => {
                    const supernumerarySituationType = this.state.supernumerary_situation_types[item.supernumerary_situation_type_uuid];
                    if (supernumerarySituationType && item.date_to && item.time_to) {
                        const plan = supernumerarySituationType.minutes_to_solve;
                        const fact = moment(moment(item.date_to).format(formats.DATE_URL) + ' ' + item.time_to).diff(moment(moment(item.date_from).format(formats.DATE_URL) + ' ' + item.time_from), 'minutes');

                        return (fact >= plan) ? `+${fact - plan}` : `-${Math.abs(fact - plan)}`;
                    }
                    return null;
                }),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteSupernumerarySituation(data);
    }

    async loadData(meta) {
        meta.filters.withSource = null;
        return await this.props.getSupernumerarySituations(meta);
    }
}
