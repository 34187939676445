import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import _ from 'lodash';
import renderHTML from 'react-render-html';

import L from 'leaflet';
import './index.less';
import {connect} from "react-redux";
import {getLayers, getObjects, toggleLayer} from "store/reducers/user-map-objects/layers";
import {getRoutes} from "store/reducers/routes/routes";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import StopPointsMtaLayer from "components/ui/map/layers/stop-points-mta";
import StopPointsInventarisationLayer from "components/ui/map/layers/stop-points-inventarisation";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LayerEditor from 'components/redux-forms/LayerEditor';
import {LabeledCheckbox} from "components/ui/checkbox";
import ContextTooltip from "components/ui/context-tooltip";

export default class ButtonBlock extends Component {

    state = {
        opened: false,
    };

    render() {
        return (
            <div className="ButtonBlock Layers">
                <ContextTooltip key="map.layers" code="map.layers" default="Настройки карты" position="top">
                    <div className="Layers-btn" onClick={::this.toggleBlock}/>
                </ContextTooltip>
                {this.state.opened ? (
                    <div className="popup-container dropdown-slider bottom left SelectFieldsPopup">
                        <div className="content">
                            {this.props.children}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }

    constructor(...args) {
        super(...args);

        this.onClickDocument = ::this._onClickDocument;
    }

    async componentDidMount() {
        $(document).on('click', this.onClickDocument);
    }

    componentWillUnmount() {
        $(document).off('click', this.onClickDocument);
    }

    _onClickDocument(e) {
        if (!$(e.target).hasClass('ButtonBlock') && $(e.target).parents('.ButtonBlock').length === 0) {
            this.closeEditor();
        }
    }

    closeEditor() {
        this.setState({
            opened: false,
        });
    }

    toggleBlock() {
        this.setState({
            opened: !this.state.opened,
        });
    }
}