import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getTaskTemplates = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.getTaskTemplates(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTaskTemplate = (uuid) => async (dispatch) => {
    try {
        const response = await api.commdept.getTaskTemplate(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createTaskTemplate = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.createTaskTemplate(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateTaskTemplate = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.updateTaskTemplate(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteTaskTemplate = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.deleteTaskTemplate(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
