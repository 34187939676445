import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import BarChart from 'components/modules/analytics/base-components/charts/BarChart';
import DashboardTable from 'components/modules/analytics/base-components/DashboardTable';
import WidgetSelector from 'components/modules/analytics/base-components/WidgetSelector';
import GlobalLoaderComponent from "components/ui/global-loader";
import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import _ from 'lodash';
import './style.less';
import {connect} from "react-redux";
import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import * as storage from "utils/storage";
import moment from "moment";
import {Text} from "recharts";
import {getDriversScoreSummary} from "store/reducers/kurs/waybills";

@propTypes({
    barChartFillColor: PropTypes.string
})

@defaultProps({
    barChartFillColor: ''
})

@connect(state => ({}), {getUsers, getDriversScoreSummary})

export default class ConvoyDriverScoreWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isError: false,
            isLoading: false,
            data: {
                organizationUnits: [],
                drivers: []
            }
        };

        this.metadata = {
            0: {field: 'score', name: 'Баллы', sort: true},
            1: {field: 'name', name: 'Водитель', sort: true}
        };
    }

    componentWillMount() {
        this.loadData();
    }

    getUnits() {
        return api.organizational_units.getUnits({
            filters: {
                withComponent: this.props.component || 'road',
            },
            pagination: {
                page: 1,
                limit: 1000,
            },
        });
    }

    async loadData() {
        this.setState({isError: false, isLoading: true});

        try {
            //let response = await api.dictionary.getDictionaryStructure(meta);
            const response = await this.getUnits();

            const data = {
                organizationUnits: _.map(response.payload.items, (item) => _.pick(item, ['uuid', 'name'])),
                drivers: [
                    // {name: 'Иванов', score: 30},
                    // {name: 'Петров', score: 20},
                    // {name: 'Сидоров', score: 10}
                ]
            };

            this.setState({data, isError: false, isLoading: false});
        }
        catch (e) {
            this.setState({isError: true, isLoading: false});
            error(e).showErrors();
        }
    }

    async loadDriversData() {
        this.setState({isLoading: true});

        const units = _.map(_.filter(this.state.data.organizationUnits, {checked: true}), 'uuid');

        const response = await this.props.getUsers({
            order: {
                column: 'surname',
                direction: 'asc',
            },
            filters: {
                withPositionTypes: [
                    'driver',
                ],
                withUnits: units,
            },
        });

        this.setState({isLoading: false});
        if (response.isOk) {
            const drivers = response.payload.items;

            const scores = await this.getScores(drivers);
            let data = this.state.data;
            data.drivers = _.map(drivers, (user) => {
                return {
                    name: new User(user).getFullName(),
                    score: _.get(scores, user.uuid),
                };
            });
            this.setState({data});
        } else {
            response.showErrors();
        }
    }

    async getScores(drivers) {
        const response = await this.props.getDriversScoreSummary(_.map(drivers, 'uuid'));

        if (response.isOk) {
            return _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'score');
        } else {
            response.showErrors();
        }
    }

    renderContent() {
        return (
            <div className="full-size">
                <div className="chart-wrapper">
                    <BarChart data={this.state.data.drivers}
                              xDataKey="name"
                              yDataKey="score"
                              xName="Рейтинг"
                              yLabel="Баллы"
                              layout="vertical"
                              color={this.props.barChartFillColor}
                    />
                </div>
                <div className="right-wrapper">
                    <div className="select-wrapper">
                        <WidgetSelector
                            title="Транспортное предприятие"
                            items={this.state.data.organizationUnits}
                            onChange={::this.onSelectedUnitsChange}
                            withToggle={false}
                        />
                    </div>
                    <div className="table-wrapper">
                        <DashboardTable data={this.state.data.drivers} metadata={this.metadata}/>
                    </div>
                </div>
            </div>
        );
    }

    async onSelectedUnitsChange(param) {
        let data = this.state.data;
        _.each(param, (p) => {
            let index = p.index;
            let checked = p.checked;

            data.organizationUnits[index].checked = checked;
        });
        await this.setState({data});
        this.loadDriversData();
    }

    render() {
        const loader = this.state.isLoading ? <GlobalLoaderComponent/> : null;

        return (
            <div className="convoy-driver-score-widget">
                {loader}
                {this.renderContent()}
            </div>
        );
    }
}
