import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

export const getDictionariesStateSelector = (state) => state.dictionary_list;
export const getKursStateSelector = (state) => state.kurs;

export const getisRoadAddModalOpenedSelector = createSelector(
	[ getKursStateSelector ],
	({ isRoadAddModalOpened }) => isRoadAddModalOpened
);
export const getRoadPartsForAddModalSelector = createSelector(
	[ getKursStateSelector ],
	({ roadParts, currentTaskRoadParts }) =>
		roadParts.filter(({ uuid }) => !currentTaskRoadParts.map(({ uuid }) => uuid).includes(uuid))
);
export const getCurrentTaskRoadParts = createSelector(
	[ getKursStateSelector ],
	({ currentTaskRoadParts }) => currentTaskRoadParts
);
export const getSearchRoadPartsInput = createSelector(
	[ getKursStateSelector ],
	({ searchRoadParts }) => searchRoadParts
);

export const getLoadingRoadPartsSelector = createSelector(
	[ getKursStateSelector ],
	({ loadingRoadParts }) => loadingRoadParts
);

export const getWorkTypesForRoadPartsModalSelector = createSelector(
	[ getKursStateSelector, getDictionariesStateSelector ],
	({ kursTasks }, { dictionaries: { work_types } }) => {
		return kursTasks.map((workTypeUuid) => {
			return { label: work_types[workTypeUuid].name, value: workTypeUuid };
		});
	}
);

export const getCurrentTaskTypeSelector = createSelector(
	[ getKursStateSelector ],
	({ currentTaskType }) => currentTaskType
);

export const getItemsForAddToTaskSelector = createSelector(
	[ getKursStateSelector ],
	({ currentTaskRoadParts, currentTaskType }) => {
		let temp = [];
		const res = currentTaskRoadParts.map(({ direction, name, uuid, length }) => {
			if (direction === 'all') {
				temp.push({
					geometry: [ { direction: 'reverse', item_name: name, item_uuid: uuid } ],
					part_start: 0,
					part_end: length,
					distance: length,
					date_from: '00:00',
					date_to: '23:59',
					work_type_uuid: currentTaskType
				});
				return {
					geometry: [ { direction: 'forward', item_name: name, item_uuid: uuid } ],
					part_start: 0,
					part_end: length,
					distance: length,
					date_from: '00:00',
					date_to: '23:59',
					work_type_uuid: currentTaskType
				};
			}
			return {
				geometry: [ { direction, item_name: name, item_uuid: uuid } ],
				part_start: 0,
				part_end: length,
				distance: length,
				date_from: '00:00',
				date_to: '23:59',
				work_type_uuid: currentTaskType
			};
		});

		return res.concat(temp).sort((a, b) => {
			if (a.geometry[0].item_name > b.geometry[0].item_name) {
				return 1;
			}
			if (a.geometry[0].item_name < b.geometry[0].item_name) {
				return -1;
			}
			return 0;
		});
	}
);

export const getIsFormValidSelector = createSelector(
	[ getKursStateSelector ],
	({ currentTaskRoadParts, currentTaskType }) =>
		!isEmpty(currentTaskRoadParts) && currentTaskType && currentTaskRoadParts.every(({ direction }) => direction)
);

export const getCollapseMultiSelector = createSelector([ getKursStateSelector ], ({ collapseMulti }) => collapseMulti);

export const getExpandedSkpdiSelector = createSelector([ getKursStateSelector ], ({ expandedSkpdi }) => expandedSkpdi);
export const getExpandedRnisSelector = createSelector([ getKursStateSelector ], ({ expandedRnis }) => expandedRnis);
export const getCooperationAgreementSelector = createSelector([ getKursStateSelector ], ({ cooperationAgreement }) => cooperationAgreement);

export const getConfirmRequestSelector = createSelector(
	[ getKursStateSelector ],
	({ confirmRequest }) => confirmRequest
);
