import React, {Component} from 'react';
import {connect} from "react-redux";
import RegularityRow from "../RegularityRow";
import _ from 'lodash';
import {getVehicles} from "store/reducers/commdept/vehicles";

import classNames from 'classnames';

import { computeTimeZoneOffset } from "helpers/regularity_matrix";

import "./style.less";

@connect((state) => ({}), {
    getVehicles,
}, null, {withRef: true})

export default class RegularityMatrix extends Component {
    renderRegularityMatrixLeftSidebar() {
        return this.props.vehicles.map((vehicleObject, index) => {
            return <div key={_.uniqueId("leftSideBar" + vehicleObject.vehicle.statenumber + index)} className="regularityMatrix__leftSideBar__block">
            <div className="regularityMatrix__leftSideBar__column1">
                {index + 1}
            </div>
            <div className={`regularityMatrix__leftSideBar__column2 vehicle_${index}`}>
                {vehicleObject.vehicle.statenumber ? vehicleObject.vehicle.statenumber : "NODATA"}
            </div>
            </div>
        })
    }

    renderRegularityMatrixRow(matrixElement, matrixIndex) {
        return <div key={_.uniqueId("mainRowMatrix" + matrixElement.endtime + matrixIndex)} className="regularityMatrix__row__container__item">
            {matrixElement.visitsList.map((visit, index) => {
                    return <div key={_.uniqueId("regularityRowContainer" + visit.uuid + index)} className="regularityMatrix__row">
                        <RegularityRow rowIndex={matrixIndex} status={visit.status} uuid={visit.uuid} interval={visit.interval} isFirst={matrixIndex === 0} visittime={visit.factvisittime} nextTime={parseInt(visit.interval / 60)} time={visit.factvisittime !== 0 ? computeTimeZoneOffset(visit.factvisittime) : '-'} />
                        </div>
                })}
        </div>
    }

    renderRegularityMatrixRightSidebar() {
        return this.props.matrix.map((matrix, index) => {
            return <div key={_.uniqueId("rightSideBar" + matrix.starttime + index)} className="regularityMatrix__rightSideBar__block">
            <div className="regularityMatrix__rightSideBar__column1">
                <p>{matrix.completed ? "зачтен" : "не зачтен"}</p>
             </div>
             <a className="regularityMatrix__rightSideBar__column2" target="_blank" href={`/kiutr/map/route/${this.props.track}`}></a>
            </div>
        });
    }

    render() {
        return (
            <div className="regularityMatrix">
                <div className="regularityMatrix__leftSideBar">
                    {this.renderRegularityMatrixLeftSidebar()}
                </div>

                <div className={classNames("regularityMatrix__row__container", this.props.loading === false && "loaded")}>
                    {this.props.matrix.map((matrixElement, matrixIndex) => {
                        return this.renderRegularityMatrixRow(matrixElement, matrixIndex)
                    })}
                </div>

                <div className="regularityMatrix__rightSideBar">
                    {this.renderRegularityMatrixRightSidebar()}
                </div>
            </div>
        );
    }
}