import React, {Component} from 'react';
import {connect} from "react-redux";

import { RouteVariantInfoRequest, RouteVariantTracksRequest, OrderInfoRequest } from '@contracts/proto-js/Geofence_pb.js';

import RegularityMatrixStats from "../RegularityMatrixStats";
import RegularityMatrix from "../RegularityMatrix";
import RegularityHeader from "../RegularityHeader";
import RegularityFooter from "../RegularityFooter";
import GlobalLoader from "components/ui/global-loader";

import { getOrderInfo, getRouteVariantInfo, getRouteVariantTracks } from "store/reducers/kiutr/regularity_matrix";

import "./style.less"

class RegularityMatrixContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderInfo: null,
            orderUUID: null,
            stoppointsList: null,
            regularityMatrix: null,
            vehicles: null,
            routeDirection: "forward",
            isLoading: false,
            EmptyOrderResponse: false,
            track: null,
            plateNumber: null,
        };
    }

    async componentDidMount() {
        if (this.props.location?.query?.route_direction) {
            await this.setState({
                routeDirection: this.props.location.query.route_direction
            })
        }
        if (this.props.params.order) {
            await this.setState({
                orderUUID: this.props.params.order
            }) 
        }
        if (this.props.location.query["order.uuid"] && !this.props.params.order) {
            await this.setState({
                orderUUID: this.props.location.query["order.uuid"]
            })
        }
        if(this.props.params.order && this.props.location.query["order.uuid"]) {
            await this.setState({
                orderUUID: this.props.params.order
            }) 
        }
        
        this.loadMatrixRegularity();
        const loadMatrixRegularityInterval = setInterval(() => {
            this.loadMatrixRegularity();
        }, 150000);
        window.loadMatrixRegularityInterval = loadMatrixRegularityInterval;
    }

    componentWillUnmount() {
        clearInterval(window.loadMatrixRegularityInterval)
    }

    async loadMatrixRegularity(vehicle, isNeedCandidates) {
        if (isNeedCandidates) {
            alert("Грузим с кандидатами")
        }
        let orderUUID = null;
        if (this.state.orderUUID) {
            orderUUID = this.state.orderUUID
        } else {
            orderUUID = "ea70f15e-cac9-11eb-9811-0242ac110002"
        }
        const orderInfoRequest = new OrderInfoRequest();
	    orderInfoRequest.setOrderuuid(orderUUID);

        await this.setState({
            isLoading: true
        });
        const directionMap = {
            "forward": 0,
            "backward": 1
        };
        let routeVariantList;
        let responseRouteVariant;
        let responseRouteVariantTracks;

        try {
            const response = await this.props.getOrderInfo(orderInfoRequest);
            if (response.uuid) {
                routeVariantList = response.routevariantsList[directionMap[this.state.routeDirection]];
                const routeVariantInfoRequest = new RouteVariantInfoRequest();
                routeVariantInfoRequest.setOrderuuid(orderUUID);
                routeVariantInfoRequest.setRoutevariantuuid(routeVariantList.uuid);
                routeVariantInfoRequest.setIsforwarddirection(routeVariantList.isforwarddirection)
    
                responseRouteVariant = await this.props.getRouteVariantInfo(routeVariantInfoRequest);
    
    
                const routeVariantTracksRequest = new RouteVariantTracksRequest();
                if (vehicle) {
                    routeVariantTracksRequest.setPlatenumber(vehicle)
                }
                if (this.props.location?.query?.plate_number) {
                    routeVariantTracksRequest.setPlatenumber(this.props.location.query.plate_number)
                }
                routeVariantTracksRequest.setOrderuuid(orderUUID);
                routeVariantTracksRequest.setRoutevariantuuid(routeVariantList.uuid);
                routeVariantTracksRequest.setIsforwarddirection(routeVariantList.isforwarddirection)
    
                responseRouteVariantTracks = await this.props.getRouteVariantTracks(routeVariantTracksRequest);
    
                console.info("OrderInfo: ", response);
                console.info("RouteVariantList: ", responseRouteVariant);
                console.info("RouterVariantTracks: ", responseRouteVariantTracks);
            
                    await this.setState({
                    orderInfo: response,
                    stoppointsList: responseRouteVariant ? responseRouteVariant.stoppointsList : null,
                    regularityMatrix: responseRouteVariantTracks ? responseRouteVariantTracks.tracksList : null,
                    vehicles: responseRouteVariantTracks.tracksList,
                    track: routeVariantList ? routeVariantList.uuid : null,
                    isLoading: false
                })
            } else {
                await this.setState({
                    EmptyOrderResponse: true 
                });
            }
        } catch(e) {
            console.error(e);
            await this.setState({
                EmptyOrderResponse: true 
            });
        }
    }

    async handleToggleDirection(val) {
        await this.setState({
            routeDirection: val
        });
        this.loadMatrixRegularity();
    }

    handleReloadMatrix() {
        this.loadMatrixRegularity();
    }

    handleVehicleChange(vehicle) {
        this.loadMatrixRegularity(vehicle);
    }

    handleCandidates() {
        const isNeedCandidates = true;
        this.loadMatrixRegularity(null, isNeedCandidates);
    }

    render() {
        if (this.state.EmptyOrderResponse) {
            return <h1>Данных для отображения матрицы не найдено.</h1>
        }
        return (
            <div className={"regularityContainer"}>
                {this.state.orderInfo?.carrieruuid && <RegularityMatrixStats  order={this.state.orderInfo} />}
                <div className="regularityResponsive">
                    {!this.state.regularityMatrix && <GlobalLoader />}
                    {this.state.stoppointsList && this.state.vehicles && <RegularityHeader  {...this.props} onCandidates={() => this.handleCandidates()} onReloadMatrix={this.handleReloadMatrix.bind(this)} onToggleDirection={(val) => this.handleToggleDirection(val)} onGetOrderInfo={(vehicle) => this.handleVehicleChange(vehicle)} onFilterStatusChange={(status) => this.handleFilterChange(status)} direction={this.state.routeDirection} vehicles={this.state.vehicles} stopPoints={this.state.stoppointsList} order={this.state.orderInfo} />}
                    {this.state.vehicles && <RegularityMatrix loading={this.state.isLoading} vehicles={this.state.vehicles} track={this.state.track} matrix={this.state.regularityMatrix} />}
                    {this.state.stoppointsList && <RegularityFooter loading={this.state.isLoading} stopPoints={this.state.stoppointsList} />}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getOrderInfo: (payload) => dispatch(getOrderInfo(payload)),
    getRouteVariantInfo: (payload) => dispatch(getRouteVariantInfo(payload)),
    getRouteVariantTracks: (payload) => dispatch(getRouteVariantTracks(payload))
 });

export default connect(null, mapDispatchToProps)(RegularityMatrixContainer);