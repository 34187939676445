import React from 'react'
import _ from "lodash";
import DetalizationTable from "components/modules/maps/elements/detalization";
import classNames from 'classnames';

export const renderMoPopup = (item, onClose, municipalities, routerPush, filterAnalyticType, isOpenDetailization, reportsByCommunal, getViolationsDetails) => {
    const linkToAnalytics = (item, event) => {
        event.preventDefault();
        let type =
            filterAnalyticType === 'passenger' ? 'passenger-transportation' : filterAnalyticType;
            routerPush(`/analytics/${type}/municipality/${item.uuid}`);
    }

    let leftWidth = window.RNIS_SETTINGS.grantMO ? '40px' : '140px';
    let deviationSchedule = _.get(
        _.find(municipalities, { communal_municipality_uuid: item.uuid }),
        'deviation.schedule'
    );
    let deviationRoute = _.get(
        _.find(municipalities, { communal_municipality_uuid: item.uuid }),
        'deviation.route'
    );
    return (
        <div className="b-modal b-modal-map map-tooltip-modal tooltip-event" style={{height: '300px'}}>
            <div className="b-modal__header">
                <a href="#" className="b-modal__close" onClick={onClose}/>
                <div className="b-modal__name">{item.title}</div>
            </div>
            <div className="b-modal__body">
                <div className="b-block">
                    <div className="b-block__desc" style={{width: '210px'}}>Отклонения от расписания</div>
                    <div className="b-block__value">{deviationSchedule || 0}</div>
                </div>
                <div className="b-block">
                    <div className="b-block__desc" style={{width: '210px'}}>Отклонения от маршрута</div>
                    <div className="b-block__value">{deviationRoute || 0}</div>
                </div>
            </div>
            <div className="b-modal__footer">
                <a href="javascript:void(0)" className="b-button b-button_size_md b-button_red b-button_routes" style={{left: leftWidth}} onClick={(event) => linkToAnalytics(item, event)}>Аналитика</a>
                {window.RNIS_SETTINGS.grantMO ? <a href="javascript:void(0)" className="b-button b-button_size_md b-button_red b-button_routes b-button_arrowRight" style={{left: '220px'}} onClick={() => getViolationsDetails(item)}>Детализация</a> : ''}
            </div>
            <div className={classNames("block-slide", "block-slide_closed")}>
                <DetalizationTable data={reportsByCommunal} />
            </div>

        </div>
    );
}

