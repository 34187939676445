const SET_FROM = 'SET_FROM';
const SET_TO = 'SET_TO';

const initialState = {
    widget_from: null,
    widget_to: null,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_FROM:
            return { ...state, widget_from: action.payload };
        case SET_TO:
            return { ...state, widget_to: action.payload };
    }
    return state;
}

export const setWidgetFrom = (from) => async (dispatch) => {
    dispatch({
        type: SET_FROM,
        payload: from,
    });
};

export const setWidgetTo = (to) => async (dispatch) => {
    dispatch({
        type: SET_TO,
        payload: to,
    });
};