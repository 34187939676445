import React from 'react';
import moment from 'moment';
import formats from 'dictionaries/formats';
import MapFullscreenControl from 'components/ui/map/controls/fullscreen/index';
import ButtonBlock from 'components/modules/maps/controls/ButtonBlock/index';
import StopPointsZoom from 'components/modules/maps/stop-points-zoom';
import ContextTooltip from 'components/ui/context-tooltip';
import MapAnimationToggleControl from 'components/modules/maps/controls/animation-toggle/index';
import AnalyticsFilters from 'components/modules/maps/controls/AnalyticsFilters/index';
import HistoryModal from 'components/modules/maps/modals/history-modal';
import RouteModal from 'components/modules/maps/modals/route-modal';
import EventsModal from 'components/modules/maps/modals/events-modal';
import DetalizationModal from 'components/modules/maps/modals/detalization-modal';
import ClustersToggle from 'components/modules/maps/clusters-toggle';

export const renderModalsComponents = (
	fullScreen,
	onToggleFullscreen,
	onZoomStopPoints,
	clustersActive,
	onToggleClusters,
	animationActive,
	onToggleAnimationActive,
	filterAnalyticType,
	onTypeChange,
	shouldShowHistory,
	refsRetrospective,
	historyWorks,
	history,
	onHistoryClick,
	diapason,
	dictionaries,
	bnsoNumber,
	stateNumber,
	component,
	historyTasks,
	historyOrders,
	historyOrderExecutions,
	onCurrentTimeChange,
	onGeojsonChange,
	clearHistoryGeojson,
	displayExportBtn,
	loadHistory,
	loadHistoryTasks,
	currentTime,
	currentTimeTo,
	route,
	routes,
	onCloseRoutePanel,
	allVehicles,
	events,
	onLoadEvents,
	onEventClick,
	onToggleShowEvents,
	routePanelOpened,
	showEvents,
	showDetalization,
	onToggleDetalization,
	detalizationFilter,
	onDetalizationFilterChange,
    dataForModal,
    paramsComponent,
	selectedVehicles,
	showHistory,
) => {
	let components = [
		<MapFullscreenControl key="map-fullscreen" active={fullScreen} toggle={onToggleFullscreen} />,
	];

	if (paramsComponent !== 'analytics') {
		components.push(<ButtonBlock key="button-block">
		<StopPointsZoom key="stop-points-zoom" onClick={onZoomStopPoints} />
		<ContextTooltip
			key="map.clusters"
			code="map.clusters"
			default="Включить отображение кластеров"
			position="right"
		>
			<ClustersToggle key="clusters-toggle" active={clustersActive} toggle={onToggleClusters} />
		</ContextTooltip>
		<ContextTooltip
			key="map.animation"
			code="map.animation"
			default="Включить анимацию ТС на карте"
			position="right"
		>
			<MapAnimationToggleControl
				key="map-animation-toggle"
				active={animationActive}
				toggle={onToggleAnimationActive}
			/>
		</ContextTooltip>
	</ButtonBlock>)
	}

	if (!window.RNIS_SETTINGS.CITY_MURMANSK && paramsComponent === 'analytics' && window.RNIS_SETTINGS.grantMO) {
		components.push(<AnalyticsFilters key="map.layers" filterAnalyticType={filterAnalyticType} onTypeChange={onTypeChange} />)
	}

	if (selectedVehicles.length === 1 && showHistory) {
		let mapDisplayParts = false;
		if (refsRetrospective) {
			mapDisplayParts = refsRetrospective.getWrappedInstance().isMapDisplayParts();
		}
		components.push(
			<HistoryModal
				key="history-modal"
				ref="historyModal"
				works={historyWorks}
				history={history}
				onClick={onHistoryClick}
				from={moment(moment(diapason.from_date).format(formats.DATE_URL) + ' ' + diapason.from_time)}
				to={moment(moment(diapason.to_date).format(formats.DATE_URL) + ' ' + diapason.to_time)}
				bnsoIndicators={dictionaries.bnso_indicators}
				bnsoNumber={bnsoNumber}
				stateNumber={stateNumber}
				workTypes={dictionaries.work_types}
				component={component}
				tasks={historyTasks}
				orders={historyOrders}
				orderExecutions={historyOrderExecutions}
				onCurrentTimeChange={onCurrentTimeChange}
				onGeojsonChange={onGeojsonChange}
				clearHistoryGeojson={clearHistoryGeojson}
				mapDisplayParts={mapDisplayParts}
				displayExportBtn={displayExportBtn}
				loadHistory={loadHistory}
				loadHistoryTasks={loadHistoryTasks}
				currentTime={currentTime}
				currentTimeTo={currentTimeTo}
			/>
		);
	}
	if (routePanelOpened) {
		components.push(<RouteModal key="route-modal" route={route} routes={routes} onClose={onCloseRoutePanel} />);
	}
	if (showEvents) {
		components.push(
			<EventsModal
				key="events"
				component={component}
				vehicles={allVehicles}
				events={events}
				gatn_responsives={dictionaries.gatn_responsives}
				onSubmit={onLoadEvents}
				onClick={onEventClick}
				onClose={onToggleShowEvents}
			/>
		);
	}
	if (showDetalization) {
		components.push(
			<DetalizationModal
				key="detalization"
				onClose={onToggleDetalization}
				filters={detalizationFilter}
				onFilterChange={onDetalizationFilterChange}
				data={dataForModal}
			/>
		);
	}
	return components;
};
