import React from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUnits} from "store/reducers/organizational_units/units";
import Page from 'components/ui/page';
import GlobalLoaderComponent from "components/ui/global-loader";
import ContextTooltip from "components/ui/context-tooltip";
import {createSto, getSto, updateSto} from "store/reducers/kurs/stos";
import {deleteStoWork, getStoWorks} from "store/reducers/kurs/sto_works";
import TableContainer from "components/ui/Table/Container/TableContainer";
import StoWorkEditor from "components/modules/utility/stos/sto_work/editor";

import './style.less';
import Button from "components/ui/button";
import systems from "dictionaries/systems";
import IconButton from "components/ui/icon-button";
import * as alerts from "helpers/alerts";
import currentUser from 'helpers/current-user';

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getSto, createSto, updateSto})

export default class UtilityRoadStoEditor extends BaseEditor {

    modelClass = 'App\\Model\\Sto';
    modalClassName = 'sto-modal';

    async componentWillMount() {
        const propsUuid = (this.props.params.uuid === 'create') ? null : this.props.params.uuid;
        if (this.state.uuid !== propsUuid) {
            this.setState({
                uuid: propsUuid,
                item: null,
                isLoading: !!propsUuid,
                mode: propsUuid ? 'edit' : 'add',
            });

            if (propsUuid) {
                const response = await this.loadData(propsUuid);
                if (response.isOk) {
                    this.setState({
                        item: response.payload,
                        isLoading: false,
                    });
                } else {
                    response.showErrors();
                }
            }
        }
    }

    componentWillUpdate() {
    }

    componentWillReceiveProps(props) {
    }

    async loadData(uuid) {
        return await this.props.getSto(uuid);
    }

    async createItem(data) {
        return await this.props.createSto(data);
    }

    async updateItem(data) {
        return await this.props.updateSto(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onClose={this.props.onClose}
                onDataLoad={::this.forceUpdate}
                showEditorWithUuid={::this.showEditorWithUuid}
                data={item}
                errors={this.state.errors}
                onUpdate={::this.onFormUpdate}
            />
        );
    }

    onFormUpdate() {
        this.setState({
            formUpdated: true,
        });
    }

    onClose() {
        if (this.state.formUpdated) {
            alerts.prompt('Вы действительно хотите закрыть эту страницу без сохранения данных?', '', () => {
                this.props.router.push('/utility/sto');
            }, 'Закрыть');
        } else {
            this.props.router.push('/utility/sto');
        }
    }

    async create(data) {
        this.clearErrors();
        this.startSave();

        const response = await this.createItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            await this.setState({
                formUpdated: false,
            });
            this.onClose();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();

        const response = await this.updateItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            await this.setState({
                formUpdated: false,
            });
            this.onClose();
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    render() {
        let title = 'Создание документа';

        let form;
        let onSubmit;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.state.mode === 'edit') {
            title = '';

            if (this.state.item) {
                title = `Документ "${_.truncate(this.state.item.number, {
                    length: 50,
                })}"`;
                form = this.getForm(this.state.item, ::this.edit);
            }

            onSubmit = ::this.onEdit;
        } else {
            form = this.getForm({}, ::this.create);

            onSubmit = ::this.onCreate;
        }

        return (
            <Page
                pageId="Order"
                title={`${systems.utility} → ${title}`}
                headerContents={this.renderHeaderContents()}
                headerActions={this.renderHeaderActions()}
            >
                {loader}
                {form}
                {this.state.editorActive ? (
                    <StoWorkEditor
                        {...this.props}
                        onClose={::this.hideEditor}
                        onSubmit={::this.submitEditor}
                        mode={this.state.editorActiveUuid ? 'edit' : 'add'}
                        uuid={this.state.editorActiveUuid}
                        stoUuid={this.state.uuid}
                    />
                ) : null}
                {this.state.formUpdated ? (
                    <div className="page-footer">
                        <div className="page-footer__txt">Вы хотите сохранить все изменения?</div>
                        <Button size="md" color="white" shadow="gray" className="b-button_cancel" text="Отменить"
                                onClick={::this.onReset}/>
                        <Button size="md" color="red" className="b-button_save" text="Сохранить"
                                onClick={onSubmit}/>
                    </div>
                ) : null}
            </Page>
        );
    }

    renderHeaderActions() {
        return [
            ((this.state.mode === 'edit') && currentUser.can('com.rnis.system.permission.audit', 'read') && this.modelClass) ?
                (
                    <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                    default="Журнал аудита">
                        <IconButton icon="history" onClick={::this.gotoAudit}/>
                    </ContextTooltip>
                ) : null,
            <ContextTooltip key="kurs.sto.back" code="kurs.sto.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ];
    }

    gotoAudit() {
        const url = `/system/audit/${this.state.uuid}?class=${this.modelClass}`;

        this.props.router.push(url);
    }

    onReset() {
        this.refs.form.getWrappedInstance().onReset();
        this.setState({
            formUpdated: false,
        });
    }

    renderHeaderContents() {
        return ([
            (this.state.mode === 'edit') ? (
                <ContextTooltip key="kurs.sto.reglament.create" code="kurs.sto.reglament.create">
                    <IconButton
                        icon="plus"
                        tooltip="Добавить регламент работ"
                        onClick={::this.showEditor}
                    />
                </ContextTooltip>
            ) : null,
        ])
    }

    showEditorWithUuid(uuid) {
        this.setState({
            editorActive: true,
            editorActiveUuid: uuid,
        });
    }

    showEditor() {
        this.setState({
            editorActive: true,
            editorActiveUuid: null,
        });
    }

    hideEditor() {
        this.setState({
            editorActive: false,
        });
    }

    submitEditor() {
        this.refs.form.getWrappedInstance().loadStoWorks();
        this.hideEditor();
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDataLoad: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    showEditorWithUuid: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getDictionaryList, getStoWorks, deleteStoWork, getUnits}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        sto: {},
        stoWorks: [],
        maintenance_groups: [],
        work_types: [],
        measures: [],
        units: [],
    };

    getData() {
        return this.state.sto;
    }

    onReset() {
        this.setState({
            sto: _.cloneDeep(this.props.data),
        });
    }

    async componentDidMount() {
        await this.setState({
            sto: _.cloneDeep(this.props.data),
        });

        this.loadUnits();
        this.loadStoWorks();
        this.loadDictionaries([
            'maintenance_groups',
            'work_types',
            'measures',
            'kurs_contract_statuses',
        ]);
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                withComponent: 'utility',
            },
        });

        if (response.isOk) {
            this.setState({
                units: _.map(response.payload.items, item => ({
                    value: item.uuid,
                    label: item.name,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    async loadStoWorks() {
        if (!this.state.sto.uuid) {
            return;
        }
        const response = await this.props.getStoWorks({
            filters: {
                withSto: this.state.sto.uuid,
            },
        });

        if (response.isOk) {
            this.setState({
                stoWorks: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.sto, path, defaultValue);
    }

    render() {
        return (
            <div className="page-block">
                <Block title="Номер документа">
                    {this.textInput('sto.number')}
                </Block>
                <Block title="Предприятие">
                    {this.select('sto.unit_uuid', this.state.units)}
                </Block>
                <Block title="Дата документа">
                    {this.datepicker('sto.date')}
                </Block>
                <Block title="Действует с">
                    {this.datepicker('sto.date_from')}
                </Block>
                <Block title="Действует по">
                    {this.datepicker('sto.date_to')}
                </Block>
                <Block title="Статус">
                    {this.select('sto.status_uuid', this.state.kurs_contract_statuses)}
                </Block>
                <Block size="xl">
                    <div className="Table indent-none">
                        <TableContainer>
                            <table className="b-table">
                                <thead>
                                <tr>
                                    <th>Вид работ</th>
                                    <th>Элемент работ</th>
                                    <th>Единица измерения</th>
                                    {this.state.maintenance_groups.map((group) => {
                                        return (
                                            <th key={group.value}>{group.label}</th>
                                        );
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {(this.state.stoWorks || []).map(::this.renderStoWorkRow)}
                                </tbody>
                            </table>
                        </TableContainer>
                    </div>
                </Block>
            </div>
        );
    }

    showEditor(uuid) {
        this.props.showEditorWithUuid(uuid);
    }

    renderStoWorkRow(stoWork) {
        const workType = _.find(this.state.work_types, {value: stoWork.work_type_uuid});

        return (
            <tr key={stoWork.uuid}>
                <td className="link" onClick={this.showEditor.bind(this, stoWork.uuid)}>
                    {_.get(workType, 'label')}
                </td>
                <td>{_.get(workType, 'document.element')}</td>
                <td>{_.get(_.find(this.state.measures, {value: _.get(workType, 'document.measure_uuid')}), 'label')}</td>
                {this.state.maintenance_groups.map((group) => {
                    return (
                        <td key={`${stoWork.uuid}:${group.value}`}>{_.get(_.find(stoWork.data, {maintenance_group_uuid: group.value}), 'count')}</td>
                    );
                })}
            </tr>
        );
    }

    async setValue(field, value) {
        this.props.onUpdate();
        return super.setValue(field, value);
    }
}