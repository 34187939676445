import React from 'react';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import classNames from 'classnames';
import './kurs-segment-icon.less';

export default class KursSegmentIcon {

    static getIcon(leftColor, rightColor) {
        const html = ReactDOMServer.renderToStaticMarkup(<div>
            <div className={`route-dots__segment route-dots__segment_${leftColor} route-dots__segment_left`}/>
            <div className={`route-dots__segment route-dots__segment_${rightColor} route-dots__segment_right`}/>
        </div>);

        let className = 'marker';
        let size = 20;
        return new L.DivIcon({
            html: html,
            className: className,
            iconSize: new L.Point(size, size),
            iconAnchor: new L.Point(size / 2, size / 2),
        });
    }
}