import React from 'react';
import { connect } from 'react-redux';
import { setOperatorComponents, setOperatorComponentsFilterShown } from '../../../../../store/reducers/maps/actions';
import {
	getOperatorComponentsSelector,
	getisOperatorComponentsFilterShownSelector
} from '../../../../../store/reducers/maps/selectors';
import { FilterButtonWithTooltip } from './filter-button-with-tooltip';
import { FilterPopupView } from './filter-popup-view';
import { systemsOptions } from '../../constants';
import { getSystemsSelectOptions } from '../../utils';


const filtersData = getSystemsSelectOptions(systemsOptions);

let MapOperatorComponentFilter = ({
	operatorComponents,
	onSetOperatorComponents,
	onSetOperatorComponentsFilterShown,
	isOperatorComponentsFilterShown,
	onGetUnits,
	onGetVehicles
}) => {
	const handleLoadData = (componentsFilter) => {
		onGetUnits(componentsFilter);
		onGetVehicles(componentsFilter);
	};
	const handleSetOperatorComponents = (checked, value) => {
		if (checked) {
			onSetOperatorComponents([ ...operatorComponents, value ]);
			handleLoadData([ ...operatorComponents, value ]);
		} else {
			onSetOperatorComponents(operatorComponents.filter((el) => el !== value));
			handleLoadData(operatorComponents.filter((el) => el !== value));
		}
	};
	const handleSelectAll = (checked) => {
		if (!checked) {
			onSetOperatorComponents(filtersData.map(({ value }) => value));
			handleLoadData(filtersData.map(({ value }) => value));
		} else {
			onSetOperatorComponents([]);
			handleLoadData([]);
		}
	};
	const filteredFiltersData = (filtersData) => {
		const exludeSubsystems = ['administration', 'analytics'];
		return filtersData.filter(subsystem => !exludeSubsystems.includes(subsystem.value));
	}

	const handleOpenFilter = () => onSetOperatorComponentsFilterShown(!isOperatorComponentsFilterShown);
	const isAllSelected = filtersData.length === operatorComponents.length;
	return (
		<div>
			<FilterButtonWithTooltip tooltipText="Подсистемы" onOpenFilter={handleOpenFilter} icon="hierarchy" />
			{isOperatorComponentsFilterShown && window.RNIS_SETTINGS.CITY_MO && (
				<FilterPopupView
					filtersData={filteredFiltersData(filtersData)}
					choosenFilters={operatorComponents}
					isAllSelected={isAllSelected}
					onSelectAll={handleSelectAll}
					onCheckBoxClick={handleSetOperatorComponents}
					onPopupClose={handleOpenFilter}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	operatorComponents: getOperatorComponentsSelector(state),
	isOperatorComponentsFilterShown: getisOperatorComponentsFilterShownSelector(state)
});

const mapDispatchToProps = {
	onSetOperatorComponents: setOperatorComponents,
	onSetOperatorComponentsFilterShown: setOperatorComponentsFilterShown
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
MapOperatorComponentFilter = withConnect(MapOperatorComponentFilter);

export { MapOperatorComponentFilter };
