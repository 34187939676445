import React from 'react';
import {connect} from 'react-redux';


import _ from 'lodash';
import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {EntityList} from "helpers/entity";
import ReactDOMServer from 'react-dom/server';
import {
    getEventTypes, getNotifications, notificationEventCreated, notificationEventRead,
    readNotifications
} from "store/reducers/notifications";
import {getEntityNames} from "store/reducers/system";
import {State} from "components/ui/state";
import moment from "moment";
import formats from "dictionaries/formats";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import {GlobalEvent} from "helpers/event-system";
import {getUnits} from "store/reducers/organizational_units/units";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import notifications from "dictionaries/notifications";
import Datepicker from "components/ui/form/datepicker";

let subscribed = false;

@connect(state => ({}), {
    getEventTypes,
    getNotifications,
    getEntityNames,
    readNotifications,
    notificationEventCreated,
    notificationEventRead,
    getUnits,
    getVehicleList,
})

export default class NotificationsViewComponent extends BaseTableWithEditorComponent {

    title = `Оповещения`;

    defaultOrder = [[0, 'desc']];

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            from: moment().subtract(1, 'day').startOf('day').format(formats.DATETIME_API),
            to: moment().startOf('day').format(formats.DATETIME_API),
        });
    }

    componentDidMount() {
        this.loadEventTypes();

        if (!subscribed) {
            this.props.notificationEventCreated(() => {
                this.reload();
            });

            this.props.notificationEventRead(() => {
                this.reload();
            });

            subscribed = true;
        }
    }

    async loadEventTypes() {
        const response = await this.props.getEventTypes();
        if (response.isOk) {
            this.setState({
                event_types: _.mapValues(_.keyBy(response.payload.items, 'key'), 'name'),
            });
        } else {
            response.showErrors();
        }
    }

    getBaseUrl() {
        return '/notifications';
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Время поступления')
                .fromField('created_at')
                .withDateFilter()
                .withDrawer(item => moment(item.created_at).format(formats.DATETIME)),

            new Column('Событие')
                .fromField('data->\'title\'')
                .denyOrder()
                .withDrawer(item => _.get(item.data, 'title')),

            new Column('Код')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => _.get(item.data, 'event_rule_code') || item.code),

            new Column('Приоритет')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => _.get(item.data, 'notification_level') && notifications.levels[_.get(item.data, 'notification_level')]),

            new Column('Новое')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={!item.is_read}/>)),

            new Column('Источник-ТС')
                .fromField('data.vehicle_uuid')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => _.get(item.data, 'vehicle_uuid') ? this.state.related.get(_.get(item.data, 'vehicle_uuid')) : '-')
                .withAsyncFilter('withVehicles', async (search) => {
                    const response = await this.props.getVehicleList({
                        search,
                        order: {
                            column: 'state_number',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 50,
                        },
                        response_data: [
                            'items/uuid',
                            'items/state_number',
                        ],
                    });
                    if (response.isOk) {
                        return _.map(response.payload.items, vehicle => ({
                            uuid: vehicle.uuid,
                            name: vehicle.state_number,
                        }));
                    }
                    return [];
                }),

            new Column('Источник-организация')
                .fromField('data.unit_uuid')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => _.get(item.data, 'unit_uuid') ? this.state.related.get(_.get(item.data, 'unit_uuid')) : '-')
                .withFilter('withUnits', async () => {
                    const response = await this.props.getUnits({
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });

                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            new Column('Тип события')
                .fromField('data->\'event_type_name\'')
                .withDrawer(item => _.get(item.data, 'event_type_name')),

        ]);
    }

    async loadData(meta) {
        meta.filters.withPeriod = [
            moment(this.state.from).startOf('day').format(formats.DATETIME_API),
            moment(this.state.to).endOf('day').format(formats.DATETIME_API),
        ];

        return await this.props.getNotifications(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const vehicles = _.map(_.filter(_.flatten(_.map(result, 'data.vehicle_uuid'))), (uuid) => ({
            class: 'App\\Model\\Vehicle',
            uuid: uuid,
            source: 'vehicles',
        }));
        const units = _.map(_.filter(_.flatten(_.map(result, 'data.unit_uuid'))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));
        const response = await this.props.getEntityNames(_.concat(vehicles, units));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    renderHeaderActions() {
        return [
            this.diapasonFilter(),
            <ContextTooltip key="notifications.read" code="notifications.read" default="Отметить как прочитанные">
                <IconButton icon="mark-read" onClick={::this.markAllAsRead}/>
            </ContextTooltip>,
            <ContextTooltip key="notifications.user-settings" code="notifications.user-settings"
                            default="Пользовательские настройки" position="left">
                <IconButton icon="settings" onClick={::this.gotoUserSettings}/>
            </ContextTooltip>,
        ];
    }

    diapasonFilter() {
        return (
            <div key="diapason">
                <div className="top-menu__label">Выбор диапазона:</div>
                <Datepicker style="dark" value={this.state.from} onChange={::this.fromChange}/>
                <Datepicker style="dark" value={this.state.to} onChange={::this.toChange}/>
            </div>
        );
    }

    async fromChange({target: {value}}) {
        await this.setState({
            from: value,
        });
        this.reload();
    }

    async toChange({target: {value}}) {
        await this.setState({
            to: value,
            from: moment(this.state.from).isAfter(moment(value)) ? value : this.state.from,
        });
        this.reload();
    }

    gotoUserSettings() {
        this.props.router.push('/notifications/settings');
    }

    async markAllAsRead() {
        const response = await this.props.readNotifications([]);

        if (response.isOk) {
            this.reload();
        } else {
            response.showErrors();
        }
    }

    getCreateButton() {
        return null;
    }
}