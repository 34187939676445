import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import _ from 'lodash';
import L from 'leaflet';
import MapComponent from "components/ui/map";
import './index.less';
import {getUserGeoObjects} from "store/reducers/user-map-objects/object_editor";
import {connect} from "react-redux";
import MapGeojson from "components/ui/map/base/geojson";
import DirectionalPolyline from "components/ui/map/objects/directional-polyline";
import HistoryPointMarker from "components/ui/map/markers/history-point-marker";
import debounce from 'throttle-debounce/debounce';
import {getStopPoints} from "store/reducers/geo/stop-points";
import {getRoadPart, getRoadParts, getRoadRepairPart} from "store/reducers/kurs/road_parts";
import KursRoadPartsLayer from 'components/ui/map/layers/kurs-road-parts';
import StopPointsMtaLayer from "components/ui/map/layers/stop-points-mta";
import DirectionalGeojson from "components/ui/map/objects/directional-geojson";
import KursPointMarker from "components/ui/map/markers/kurs-point-marker";
import KursSegmentMarker from "components/ui/map/markers/kurs-segment-marker";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import KursRoadRepairPartsLayer from "components/ui/map/layers/kurs-road-repair-parts";
import Settings from 'settings';

@propTypes({
    task: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    onStopPointAdd: PropTypes.func.isRequired,
    onRoadPartAdd: PropTypes.func.isRequired,
    addGeometry: PropTypes.func.isRequired,
})

@defaultProps({})

@connect(state => ({}), {
    getDictionaryList,
    getStopPoints,
    getRoadParts,
    getRoadPart,
    getRoadRepairPart
}, null, {withRef: true})

export default class KursTaskMapEditorMap extends Component {

    state = {
        stopPoints: {},
        roadParts: {},
        roadRepairParts: {},
    };

    pointDragDebounce = debounce(150, ::this.onPointDragEnd);

    inited = false;

    componentDidUpdate(prevProps) {
        if (!this.inited) {
            this.fitBounds();
            this.inited = true;
        }
        this.preloadRoadParts(prevProps);
        this.preloadRoadRepairParts();
        this.preloadStopPoints();
    }

    getItems(props = null) {
        return (props || this.props).task[(this.props.type === 'plan') ? 'items' : 'items_fact'];
    }

    async preloadRoadParts(prevProps) {
        const {task} = this.props;
        if (!task) {
            return;
        }

        const uuids = _.filter(_.map(this.getItems(), (item) => {
            if (item.geometry_type === 'road_part') {
                return _.get(item.geometry, '0.item_uuid');
            }
            return null;
        }));

        const prevUuids = _.filter(_.map(this.getItems(prevProps), (item) => {
            if (item.geometry_type === 'road_part') {
                return _.get(item.geometry, '0.item_uuid');
            }
            return null;
        }));

        if (_.difference(uuids, prevUuids).length === 0) {
            return;
        }

        const response = await this.props.getRoadParts({
            filters: {
                withUuid: uuids,
            },
        });
        const filteredResponse = response.payload.items.filter(el => el.register_number); 
        // filter items without register_number

        if (response.isOk) {
            this.setState({
                roadParts: _.keyBy(filteredResponse, 'uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    async preloadRoadRepairParts() {
        const {task} = this.props;
        if (!task) {
            return;
        }

        //return;

        const uuids = _.filter(_.map(this.getItems(), (item) => {
            if (item.geometry_type === 'road_repair_part') {
                return _.get(item.geometry, '0.item_uuid');
            }
            return null;
        }));

        if (_.difference(uuids, _.keys(this.state.roadRepairParts)).length === 0) {
            return;
        }

        const response = await this.props.getDictionaryList('kurs_road_repair_parts', {
            pagination: {
                page: 1,
                limit: 1000,
            },
            filters: {
                uuid: uuids,
            },
        }, false);
        if (response.isOk) {
            this.setState({
                roadRepairParts: _.keyBy(response.payload.documents, 'uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    async preloadStopPoints() {
        const {task} = this.props;
        if (!task) {
            return;
        }

        const uuids = _.filter(_.map(this.getItems(), (item) => {
            if (item.geometry_type === 'stop_point') {
                return _.get(item.geometry, '0.item_uuid');
            }
            return null;
        }));

        if (_.difference(uuids, _.keys(this.state.stopPoints)).length === 0) {
            return;
        }

        const response = await this.props.getStopPoints({
            filters: {
                withUuid: uuids,
            },
        });
        if (response.isOk) {
            this.setState({
                stopPoints: _.keyBy(response.payload.items, 'uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        return (
            <div className="kurs-map-editor-map">
                <MapComponent
                    ref="map"
                    onStopPointDblClick={this.props.isEditable ? this.props.onStopPointAdd : null}
                    onDblClick={(event) => this.handleMapDblClick(event)}
                    component="road"
                >
                    {(this.refs.map && this.props.geometryType === 'road_part' && this.props.task.communal_municipality_uuid) ? (
                        <KursRoadPartsLayer
                            communalUuid={this.props.task.communal_municipality_uuid}
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            onClick={this.props.isEditable ? this.props.onRoadPartAdd : null}
                        />
                    ) : null}
                    {(this.refs.map && this.props.geometryType === 'road_repair_part') ? (
                        <KursRoadRepairPartsLayer
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            onClick={this.props.isEditable ? this.props.onRoadPartAdd : null}
                        />
                    ) : null}
                    {(this.refs.map && this.props.geometryType === 'stop_point') ? (
                        <StopPointsMtaLayer
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            onStopPointDblClick={this.props.isEditable ? this.props.onStopPointAdd : null}
                        />
                    ) : null}
                    {this.renderTask()}
                    {this.renderFirstItem()}
                    {this.renderLastItem()}
                </MapComponent>
            </div>
        );
    }

    handleMapDblClick = (e) => {
        const latitude = e.latlng.lat;
        const longitude = e.latlng.lng;

        this.props.addGeometry(latitude, longitude);
    }

    renderTask() {
        const {task} = this.props;

        if (!task) {
            return null;
        }

        const items = this.getItems();

        if (!this.focused && this.refs.map) {
            this.focused = true;

            let group = new L.FeatureGroup();
            _.each(items, (item) => {
                group.addLayer(L.geoJSON(item.geojson));
            });
            if (group.getBounds().isValid()) {
                this.refs.map.getWrappedInstance().fitBounds(group.getBounds());
            }
        }

        return _.map(items, (item, index) => {
            let result = [
                this.renderTaskItem(item, index),
            ];

            if (this.props.mapDisplayParts && !item.hidden) {
                _.each(item.confirmed_parts || [], (confirmedPart, _index) => {
                    result.push(
                        <DirectionalGeojson
                            key={`part:${_index}`}
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            geometry={confirmedPart}
                            color="green"
                            opacity={1}
                            onClick={this.onItemClick.bind(this, index)}
                            zIndex={999}
                            interactive={false}
                            weight={4}
                        />
                    );
                });
            }

            if (index < items.length - 1) {
                const coordinates = this.getLastCoordinates(item);
                if (coordinates && (_.filter(coordinates).length === 2)) {
                    const nextItem = items[index + 1];

                    result.push(<KursSegmentMarker
                        key={`${index}:after`}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        latitude={coordinates[0]}
                        longitude={coordinates[1]}
                        zIndex={100}
                        leftColor={this.getColor(item)}
                        rightColor={this.getColor(nextItem)}
                        draggable={this.isEditableBySkpdi(item) && ((item.movement_type === 'work') || (nextItem.movement_type === 'work'))}
                        onDrag={() => {
                        }}
                        onDragEnd={this.onPointDragEnd.bind(this, (item.movement_type === 'work') ? index : index + 1, 0, (item.movement_type === 'work') ? 'last' : 'first')}
                    />);
                }
            }

            return result;
        });
    }

    isEditableBySkpdi(item) {
        if (!item) {
            return true;
        }
        return !(item.external_id && (Settings.get('kurs_skpdi_task_items_edit_deny') === '1'));
    }

    getColor(item) {
        let color = 'gray';
        if (item.movement_type === 'work') {
            color = 'red';
            if (item.is_confirmed) {
                color = 'green';
            }
        }

        return color;
    }

    getLineColor(item) {
        if (this.props.type === 'plan') {
            return 'red';
        }
        if (this.props.mapDisplayParts) {
            return item.is_confirmed ? 'yellow' : '#c400f6';
        }
        return item.is_confirmed ? 'green' : 'red';
    }

    renderTaskItem(item, index) {
        const geometryType = item.geometry_type;
        const movementType = item.movement_type;

        if (item.hidden) {
            return null;
        }

        if (movementType === 'idle') {
            return [
                item.geojson ? (
                    <DirectionalGeojson
                        key={index}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        geometry={item.geojson}
                        onClick={this.props.isEditable ? this.onPathClick.bind(this, index) : null}
                        color="#c9c9c9"
                        weight={4}
                    />
                ) : null,
                _.map(item.geometry, (geometry, geometryIndex) => {
                    const latitude = _.toNumber(_.get(geometry, 'latitude'));
                    const longitude = _.toNumber(_.get(geometry, 'longitude'));

                    const address = _.get(geometry, 'address');

                    if ((geometryIndex === 0) || (geometryIndex === item.geometry.length - 1)) {
                        return null;
                    }
                    if (latitude && longitude) {
                        const isIdle = item.movement_type === 'idle';
                        const disabled = isIdle && ((index !== 0 && geometryIndex === 0) || ((index !== this.getItems().length - 1) && (geometryIndex === this.getItems()[index].geometry.length - 1)))

                        return (
                            <KursPointMarker
                                key={`marker:${index}:${geometryIndex}`}
                                map={this.refs.map}
                                leafletMap={this.refs.map.getWrappedInstance().map}
                                latitude={latitude}
                                longitude={longitude}
                                color="gray"
                                icon={true}
                                draggable={this.props.isEditable && !disabled}
                                onDrag={this.pointDragDebounce.bind(this, index, geometryIndex, 'idle')}
                                onDragEnd={this.onPointDragEnd.bind(this, index, geometryIndex, 'idle')}
                            />
                        );
                    }
                }),
            ];
        }
        const GeoJsonComponent = this.props.mapDisplayParts ? MapGeojson : DirectionalGeojson;
        if (geometryType === 'road_part') {
            const roadPart = _.get(this.state.roadParts, _.get(item.geometry, '0.item_uuid'));

            if (roadPart && item.geojson && (item.geojson.type !== 'Point')) {

                return (
                    <GeoJsonComponent
                        key={index}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        geometry={item.geojson}
                        tooltip={`${roadPart.register_number} ${roadPart.name}`}
                        color={this.getLineColor(item)}
                        opacity={1}
                        onClick={this.onItemClick.bind(this, index)}
                        weight={4}
                    />
                );
            }
        }
        if (geometryType === 'road_repair_part') {
            const roadRepairPart = _.get(this.state.roadRepairParts, _.get(item.geometry, '0.item_uuid'));

            if (roadRepairPart && item.geojson && (item.geojson.type !== 'Point')) {

                return (
                    <GeoJsonComponent
                        key={index}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        geometry={item.geojson}
                        tooltip={`${roadRepairPart.name}`}
                        color={this.getLineColor(item)}
                        opacity={1}
                        onClick={this.onItemClick.bind(this, index)}
                        weight={4}
                    />
                );
            }
        }
        if (geometryType === 'user_geo_object') {
            if (item.geojson) {
                return (
                    <GeoJsonComponent
                        key={index}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        geometry={item.geojson}
                        color={this.getLineColor(item)}
                        opacity={1}
                        weight={4}
                    />
                );
            }
        }
        if (geometryType === 'other') {
            if (item.geojson && (item.geojson.type !== 'Point')) {
                return (
                    <GeoJsonComponent
                        key={index}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        geometry={item.geojson}
                        color={this.getLineColor(item)}
                        opacity={1}
                        weight={4}
                    />
                );
            }
        }
        if (geometryType === 'stop_point') {
            const stopPoint = _.get(this.state.stopPoints, _.get(item.geometry, '0.item_uuid'));

            if (stopPoint && stopPoint.latitude && stopPoint.longitude && item.geojson) {
                return (
                    <KursPointMarker
                        key={index}
                        map={this.refs.map}
                        leafletMap={this.refs.map.getWrappedInstance().map}
                        latitude={stopPoint.latitude}
                        longitude={stopPoint.longitude}
                        color="orange"
                        icon={false}
                    />
                );
            }
        }
    }

    onItemClick(index) {
        this.props.onItemClick(index);
    }

    onPathClick(itemIndex, {latlng: {lat, lng}}) {
        const field = (this.props.type === 'plan') ? 'items' : 'items_fact';
        const from = new L.LatLng(_.get(this.props.task, `${field}.${itemIndex}.geometry.0.latitude`), _.get(this.props.task, `${field}.${itemIndex}.geometry.0.longitude`));

        let points = _.cloneDeep(_.get(this.props.task, `${field}.${itemIndex}.geometry`, []));
        points.push({
            is_address: true,
            latitude: lat,
            longitude: lng,
        });
        const sorted = _.sortBy(points, (point) => {
            return from.distanceTo(new L.LatLng(point.latitude, point.longitude));
        });

        let task = this.props.task;
        _.set(task, `${field}.${itemIndex}.geometry`, sorted);

        this.props.onUpdate(task, itemIndex);
    }

    async onPointDragEnd(itemIndex, index, key, {target}) {
        const {lat, lng} = target.getLatLng();

        const field = (this.props.type === 'plan') ? 'items' : 'items_fact';

        let task = this.props.task;
        const item = _.get(task, `${field}.${itemIndex}`);
        if (item && item.movement_type === 'idle') {
            _.set(task, `${field}.${itemIndex}.geometry.${index}.is_address`, true);
            _.set(task, `${field}.${itemIndex}.geometry.${index}.address`, null);
            _.set(task, `${field}.${itemIndex}.geometry.${index}.latitude`, lat);
            _.set(task, `${field}.${itemIndex}.geometry.${index}.longitude`, lng);

            this.props.onUpdate(task, itemIndex, field);
        } else if (item && item.movement_type === 'work' && (_.get(item, 'geometry_type') === 'road_part')) {
            const firstCoordinates = this.getFirstCoordinates(item);
            const lastCoordinates = this.getLastCoordinates(item);
            if (!firstCoordinates || !lastCoordinates) {
                return;
            }
            const from = (key === 'first') ? {
                latitude: lat,
                longitude: lng,
            } : {
                latitude: firstCoordinates[0],
                longitude: firstCoordinates[1],
            };
            const to = (key === 'last') ? {
                latitude: lat,
                longitude: lng,
            } : {
                latitude: lastCoordinates[0],
                longitude: lastCoordinates[1],
            };

            const uuid = _.get(item, 'geometry.0.item_uuid');
            const response = await this.props.getRoadPart(uuid, {
                slice_from: from,
                slice_to: to,
            });

            if (response.isOk) {
                let geojson = _.cloneDeep(response.payload.geometry);
                if (_.get(task, `${field}.${itemIndex}.geometry.0.direction`) === 'reverse') {
                    _.reverse(geojson.coordinates);
                }
                _.set(task, `${field}.${itemIndex}.part_start`, _.round(response.payload.slice_start, 2));
                _.set(task, `${field}.${itemIndex}.part_end`, _.round(response.payload.slice_end, 2));
                _.set(task, `${field}.${itemIndex}.distance`, _.round(_.round(response.payload.slice_end, 2) - _.round(response.payload.slice_start, 2), 2));
                _.set(task, `${field}.${itemIndex}.geojson`, geojson);

                this.props.onUpdate(task, null, field);
            } else {
                response.showErrors();
            }
        } else if (item && item.movement_type === 'work' && (_.get(item, 'geometry_type') === 'road_repair_part')) {
            const firstCoordinates = this.getFirstCoordinates(item);
            const lastCoordinates = this.getLastCoordinates(item);
            if (!firstCoordinates || !lastCoordinates) {
                return;
            }
            const from = (key === 'first') ? {
                latitude: lat,
                longitude: lng,
            } : {
                latitude: firstCoordinates[0],
                longitude: firstCoordinates[1],
            };
            const to = (key === 'last') ? {
                latitude: lat,
                longitude: lng,
            } : {
                latitude: lastCoordinates[0],
                longitude: lastCoordinates[1],
            };

            const uuid = _.get(item, 'geometry.0.item_uuid');
            const response = await this.props.getRoadRepairPart({
                uuid,
                slice_from: from,
                slice_to: to,
            });

            if (response.isOk) {
                let geojson = _.cloneDeep(response.payload.geometry);
                if (_.get(task, `${field}.${itemIndex}.geometry.0.direction`) === 'reverse') {
                    _.reverse(geojson.coordinates);
                }
                _.set(task, `${field}.${itemIndex}.part_start`, _.round(response.payload.slice_start, 2));
                _.set(task, `${field}.${itemIndex}.part_end`, _.round(response.payload.slice_end, 2));
                _.set(task, `${field}.${itemIndex}.distance`, _.round(_.round(response.payload.slice_end, 2) - _.round(response.payload.slice_start, 2), 2));
                _.set(task, `${field}.${itemIndex}.geojson`, geojson);

                this.props.onUpdate(task, null, field);
            } else {
                response.showErrors();
            }
        } else if (item && item.movement_type === 'work' && (_.get(item, 'geometry_type') === 'other')) {
            _.set(task, `${field}.${itemIndex}.geometry.${index}.latitude`, lat);
            _.set(task, `${field}.${itemIndex}.geometry.${index}.longitude`, lng);

            this.props.onUpdate(task, itemIndex, field);
        }
    }

    fitBounds() {
        try {
            /*const forward_markers = this.props.variant.forward_points && this.props.variant.forward_points.map((point) => new L.Marker([point.latitude, point.longitude]));
            const reverse_markers = this.props.variant.reverse_points && this.props.variant.reverse_points.map((point) => new L.Marker([point.latitude, point.longitude]));
            const group = new L.featureGroup(_.concat(forward_markers, reverse_markers));
            this.refs.map.getWrappedInstance().fitBounds(group.getBounds());*/
        } catch (e) {
        }
    }

    panTo(coordinates) {
        this.refs.map.getWrappedInstance().panTo(coordinates);
    }

    getLastCoordinates(item) {
        if (item.movement_type === 'idle') {
            const geometry = _.last(item.geometry);
            if (!geometry) {
                return null;
            }
            return [
                geometry.latitude,
                geometry.longitude,
            ];
        }
        if (!item.geojson) {
            return null;
        }

        if (item.geojson.type === 'Point') {
            const coordinates = _.clone(item.geojson.coordinates);
            _.reverse(coordinates);
            return coordinates;
        } else {
            const coordinates = _.clone(_.last(item.geojson.coordinates));
            _.reverse(coordinates);
            return coordinates;
        }
    }

    getFirstCoordinates(item) {
        if (item.movement_type === 'idle') {
            const geometry = _.first(item.geometry);
            if (!geometry) {
                return null;
            }
            return [
                geometry.latitude,
                geometry.longitude,
            ];
        }
        if (!item.geojson) {
            return null;
        }

        if (item.geojson.type === 'Point') {
            const coordinates = _.clone(item.geojson.coordinates);
            _.reverse(coordinates);
            return coordinates;
        } else {
            const coordinates = _.clone(_.first(item.geojson.coordinates));
            _.reverse(coordinates);
            return coordinates;
        }
    }

    renderFirstItem() {
        const item = _.first(this.getItems());
        return item && this.renderKursPoint('first', 0, 0, item.movement_type === 'idle', this.getFirstCoordinates(item));
    }

    renderLastItem() {
        const item = _.last(this.getItems());
        return item && item.geometry && this.renderKursPoint('last', this.getItems().length - 1, item.geometry.length - 1, item.movement_type === 'idle', this.getLastCoordinates(item));
    }

    renderKursPoint(key, index, geometryIndex, isIdle, coordinates) {
        if (coordinates && (_.filter(coordinates).length === 2)) {
            const item = _.get(this.getItems(), index);
            return (
                <KursPointMarker
                    key={`${key}:${index}:${geometryIndex}:${isIdle ? 1 : 0}`}
                    map={this.refs.map}
                    leafletMap={this.refs.map.getWrappedInstance().map}
                    latitude={coordinates[0]}
                    longitude={coordinates[1]}
                    color="red"
                    icon={true}
                    draggable={this.isEditableBySkpdi(item)}
                    onDrag={isIdle ? this.pointDragDebounce.bind(this, index, geometryIndex, key) : () => {
                    }}
                    onDragEnd={this.onPointDragEnd.bind(this, index, geometryIndex, key)}
                    zIndex={100}
                />
            );
        }
    }

}
