import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import classNames from 'classnames';

import ReactDOMServer from 'react-dom/server';
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import PageModal from 'components/ui/page-modal';
import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import TableContainer from "components/ui/Table/Container/TableContainer";
import ContextTooltip from "components/ui/context-tooltip";
import GlobalLoaderComponent from "components/ui/global-loader";
import {getDisplayLogs} from "store/reducers/kiutr/display_logs";
import moment from "moment";
import formats from "dictionaries/formats";
import {State} from "components/ui/state";
import TableComponent from "components/ui/Table/Component/TableComponent";

@propTypes({
    displayUuid: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
})

@connect(state => ({}), {
    getDisplayLogs,
})

export default class DisplayLogsComponent extends BaseTableWithEditorComponent {

    title = '';

    types = {
        request: 'Запрос',
        data: 'Конфигурация',
        response: 'Ответ',
    };

    getColumns() {
        return this.prepareColumns([

            new Column('Дата/время')
                .fromField('created_at')
                .denyColumnFilter()
                .withDrawer(item => moment(item.created_at).format(formats.DATETIME)),

            new Column('Тип')
                .fromField('type')
                .denyColumnFilter()
                .withDrawer(item => this.types[item.type]),

            new Column('Текст')
                .fromField('text')
                .denyColumnFilter()
                .withDrawer(item => item.text.replace(/\n/g, '<br/>')),
            
            new Column('Результат')
                .fromField('result')
                .denyColumnFilter()
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.result === 'success'}/>)),
        ]);
    }

    async loadData(meta) {
        meta.filters.withDisplay = this.props.displayUuid;

        return await this.props.getDisplayLogs(meta);
    }

    async onDblClick(data) {
    }

    renderTable() {
        const className = classNames({
            'show-table-filters': this.state.showTableFilters,
        });

        return (
            <div className={className}>
                <TableComponent
                    ref="table"
                    select={this.select}
                    columns={this.state.columns}
                    loadCallback={::this.loadCallbackMiddleware}
                    onColsReordered={::this.onColsReordered}
                    onCheck={::this.selectedRowsRecalc}
                    checkCache={::this.checkCache}
                    query={this.state.query}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    onSettingsLoad={::this.onSettingsLoad}
                    onDblClick={::this.onDblClick}
                    shortPagination={true}
                />
            </div>
        );
    }

    render() {
        //const editor = this.state.showEditorModal ? this.getEditor() : '';

        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <div>
                <PageModal
                    header={{title: 'Журнал операций', buttons}}
                    className={classNames("b-modal-edit")}
                    withFade={false}
                    onClose={this.props.onClose}
                >
                    {this.state.loading ? <GlobalLoaderComponent/> : null}
                    <div className="b-modal__block">
                        <TableContainer>
                            {this.renderTable()}
                        </TableContainer>
                    </div>
                </PageModal>
            </div>
        );
    }
}