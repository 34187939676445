import React, {Component} from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapLayer from "components/ui/map/base/layer";
import {connect} from "react-redux";
import {getStopPointsWithBounds} from "store/reducers/routes/routes";
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import StopPointsEditor from "components/modules/geo/stop-points/editor";
import {getRoadParts} from "store/reducers/kurs/road_parts";
import MapGeojson from "components/ui/map/base/geojson";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";

@propTypes({
    onClick: PropTypes.func,
    onDblClick: PropTypes.func,
})

@defaultProps({
    onClick: null,
    onDblClick: () => {
    },
})

@connect((state) => ({}), {getDictionaryList})

export default class KursRoadRepairPartsLayer extends AbstractMapLayer {

    state = {
        roadRepairParts: [],
        active: null,
    };

    loadCallback = ::this.load;

    componentWillMount() {
        super.componentWillMount();

        this.props.leafletMap.on('moveend', this.loadCallback);
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.props.leafletMap.off('moveend', this.loadCallback);
    }

    async load() {
        const boundingBox = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getBoundingBox() : this.props.map.getBoundingBox();
        const zoom = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getZoom() : this.props.map.getZoom();

        const response = await this.props.getDictionaryList('kurs_road_repair_parts', {
            filters: {
                withBoundingBox: boundingBox,
            },
        }, false);

        if (response.isOk) {
            this.setState({
                roadRepairParts: response.payload.documents,
            });
        }
    }

    render() {
        return (
            <div>
                <div style={{display: 'none'}}>
                    {this.state.roadRepairParts.map(::this.renderRoadRepairPart)}
                </div>
            </div>
        );
    }

    renderRoadRepairPart(roadRepairPart) {
        if (!roadRepairPart.geometry) {
            return null;
        }
        return (
            <MapGeojson
                key={roadRepairPart.uuid}
                {...this.props}
                geometry={JSON.parse(roadRepairPart.geometry)}
                tooltip={roadRepairPart.name}
                onClick={this.onClick.bind(this, roadRepairPart)}
            />
        );
    }

    setActive(uuid) {
        this.setState({
            active: uuid,
        });
    }

    setNotActive() {
        this.setState({
            active: null,
        });
    }

    onClick(roadRepairPart) {
        this.props.onClick && this.props.onClick(roadRepairPart.uuid, roadRepairPart.geometry);
    }

}