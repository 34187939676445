import {rpc} from './session';

export async function getContacts(meta = {}) {
    return await rpc.request('com.rnis.mobile.action.contact.list', {}, {meta});
}

export async function getContact(uuid) {
    return await rpc.request('com.rnis.mobile.action.contact.get', {uuid});
}

export async function createContact(data) {
    return await rpc.request('com.rnis.mobile.action.contact.create', data);
}

export async function updateContact(data) {
    return await rpc.request('com.rnis.mobile.action.contact.update', data);
}

export async function deleteContact(data) {
    return await rpc.request('com.rnis.mobile.action.contact.delete', data);
}

export async function getMobilePages(meta = {}) {
    return await rpc.request('com.rnis.mobile.action.mobile_page.list', {}, {meta});
}

export async function getMobilePage(uuid) {
    return await rpc.request('com.rnis.mobile.action.mobile_page.get', {uuid});
}

export async function updateMobilePage(data) {
    return await rpc.request('com.rnis.mobile.action.mobile_page.update', data);
}


