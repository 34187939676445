import { getHostName } from '../wsrpc';
const { GeofenceClient } = require('@contracts/proto-js/Geofence_grpc_web_pb.js');

const gatewayService = new GeofenceClient(`https://${getHostName('', false)}`)

export async function getOrderInfo(request) {
	const metadata = {
		'x-service': 'gateway'
	};
	return new Promise((resolve, reject) => {
		gatewayService.getOrderInfo(request, metadata, (err, response) => {
			if (err == null) {
				resolve(response.toObject());
			} else {
				reject(err);
			}
		});
	}).then((data) => data);
}

export async function getRouteVariantInfo(request) {
	const metadata = {
		'x-service': 'gateway'
	};
	return new Promise((resolve, reject) => {
		gatewayService.getRouteVariantInfo(request, metadata, (err, response) => {
			if (err == null) {
				resolve(response.toObject());
			} else {
				reject(err);
			}
		});
	}).then((data) => data);
}

export async function getRouteVariantTracks(request) {
	const metadata = {
		'x-service': 'gateway'
	};
	return new Promise((resolve, reject) => {
		gatewayService.getRouteVariantTracks(request, metadata, (err, response) => {
			if (err == null) {
				resolve(response.toObject());
			} else {
				reject(err);
			}
		});
	}).then((data) => data);
}
