import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './modal-top-menu-list.less';

@propTypes({
    className: PropTypes.string,
})

@defaultProps({
    className: '',
})

export default class ModalTopMenuList extends Component {
    render() {
        const className = classNames('top-menu_modal clearAfter', this.props.className);

        return (
            <div className={className}>
                <ul className="top-menu_modal__list">
                    {this.props.children}
                </ul>
            </div>
        );
    }
}