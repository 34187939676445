import React from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';
import _ from 'lodash';
import "./style.less";
import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import TableContainer from "components/ui/Table/Container/TableContainer";
import {
    createTechnocardAssign, getTechnocardAssign,
    updateTechnocardAssign
} from "store/reducers/kurs/technocard_assigns";
import {getRoadParts} from "store/reducers/kurs/road_parts";
import {getTechnocards} from "store/reducers/kurs/technocards";

@connect(state => ({}), {getTechnocardAssign, createTechnocardAssign, updateTechnocardAssign})

export default class Editor extends BaseEditor {

    title = 'назначения технологической карты';
    modelClass = 'App\\Model\\TechnocardAssign';

    async loadData(uuid) {
        return await this.props.getTechnocardAssign(uuid);
    }

    async createItem(data) {
        return await this.props.createTechnocardAssign(data);
    }

    async updateItem(data) {
        return await this.props.updateTechnocardAssign(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
})

@connect((state) => ({}), {getDictionaryList, getRoadParts, getTechnocards}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    types = {
        'road_part': 'Участки дорог',
        'repair_part': 'Участки ремонта',
    };

    statuses = {
        draft: 'Черновик',
        active: 'Действует',
    };

    state = {
        technocard_assign: {},
        technocards: [],
    };

    getData() {
        return this.state.technocard_assign;
    }

    async componentWillMount() {
        await this.setState({
            technocard_assign: this.props.data,
        });

        this.loadTechnocards();
    }

    async loadTechnocards() {
        const response = await this.props.getTechnocards();

        if (response.isOk) {
            this.setState({
                technocards: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.technocard_assign, path, defaultValue);
    }

    async setValue(field, value) {
        await super.setValue(field, value);

        if (field === 'technocard_assign.type') {
            await this.setValue('technocard_assign.item_uuid', null);
            this.refs.item && this.refs.item.reload();
        }

        if (field === 'technocard_assign.technocard_uuid') {
            this.setValue('technocard_assign.work_name', null);
        }
    }

    onChangeItem(e) {
        const value = e ? e.value : null;

        this.setValue('technocard_assign.item_uuid', value);
    }

    async loadItems(input, callback) {
        if (!this.get('type')) {
            callback(null, {
                options: [],
                complete: false,
            });
            return;
        }

        if (!input) {
            input = this.get('item_uuid');
        }
        if (this.get('type') === 'road_part') {
            const result = await this.props.getRoadParts({
                search: input,
                pagination: {
                    page: 1,
                    limit: 20,
                },
            });

            if (result.isOk) {
                callback(null, {
                    options: result.payload.items.map(item => ({
                        label: item.name,
                        value: item.uuid,
                    })),
                    complete: false,
                });
            } else {
                result.showErrors();
            }
        } else {
            const result = await this.props.getDictionaryList('kurs_road_repair_parts', {
                search: input,
                pagination: {
                    page: 1,
                    limit: 20,
                },
            }, false);

            if (result.isOk) {
                callback(null, {
                    options: result.payload.documents.map(item => ({
                        label: item.name,
                        value: item.uuid,
                    })),
                    complete: false,
                });
            } else {
                result.showErrors();
            }
        }
    }

    getStatuses() {
        if ((this.get('status') === 'done') || (this.get('status') === 'closed')) {
            return {
                closed: 'Закрыт',
                done: 'Обработан',
            };
        }

        return this.statuses;
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem title="Основные сведения" opened={true} single={true}>
                        <Block title="Тип участка">
                            {this.select('technocard_assign.type', _.map(this.types, (label, value) => ({
                                value,
                                label,
                            })))}
                        </Block>
                        <Block title="Участок">
                            {this.selectAsync('technocard_assign.item_uuid', ::this.loadItems, {
                                onChange: ::this.onChangeItem,
                                ref: 'item',
                            })}
                        </Block>
                        <Block title="Технологическая карта">
                            {this.select('technocard_assign.technocard_uuid', _.map(this.state.technocards, (item) => ({
                                value: item.uuid,
                                label: item.name,
                            })))}
                        </Block>
                        <Block title="Дата с">
                            {this.datepicker('technocard_assign.date_from')}
                        </Block>
                        <Block title="Дата по">
                            {this.datepicker('technocard_assign.date_to')}
                        </Block>
                        <Block title="Статус">
                            {this.select('technocard_assign.status', _.map(this.getStatuses(), (label, value) => ({
                                value,
                                label,
                            })))}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}