import React from 'react';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import moment from "moment";
import formats from "dictionaries/formats";
import _ from 'lodash';
import './style.less';
import {deleteTask, getTasks} from "store/reducers/kurs/tasks";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import UtilityTaskMapEditor from "components/modules/utility/tasks/TaskMapEditor/index";
import Datepicker from "components/ui/form/datepicker";
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import * as storage from "utils/storage";
import ReactDOMServer from 'react-dom/server';
import {State} from "components/ui/state";

@connect(state => ({}), {getTasks, deleteTask, getEntityNames, getUnits, getDictionaryList, getVehicleList})

export default class UtilityTasksComponent extends BaseTableWithEditorComponent {

    title = `${systems.utility} → Планирование работ`;
    baseUrl = '/utility/tasks';

    defaultOrder = [[1, 'desc']];

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            from: storage.get('utility-tasks-from') || moment(),
            to: storage.get('utility-tasks-to') || moment().add(1, 'day'),
        });
    }

    async componentWillUpdate(props, nextState, nextContext) {
        if (props.params) {
            const uuid = props.params.uuid;
            const type = props.params.type || 'form';
            const newEditorUuid = (uuid && uuid !== 'create') ? uuid : null;
            if (this.state.editorUuid !== newEditorUuid || this.state.showEditorModal !== !!uuid || this.state.editorType !== type) {
                this.setState({
                    showEditorModal: !!uuid,
                    editorUuid: newEditorUuid,
                    editorType: type,
                });
            }
        }
    }

    getEditor() {
        switch (this.state.editorType) {
            case 'form':
                return (
                    <Editor
                        {...this.props}
                        key="editor"
                        onClose={::this.closeEditor}
                        onSubmit={::this.submitEditor}
                        mode={this.state.editorUuid ? 'edit' : 'add'}
                        uuid={this.state.editorUuid}
                    />
                );
            case 'map':
                return (
                    <KursTaskMapEditor
                        {...this.props}
                        key="editor"
                        onClose={::this.closeEditor}
                        onSubmit={::this.submitEditor}
                        mode={this.state.editorUuid ? 'edit' : 'add'}
                        uuid={this.state.editorUuid}
                    />
                );
        }
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Номер')
                .fromField('number')
                .withClassName('link')
                .withDrawer(item => `<a href="/utility/tasks/${item.uuid}">${item.number}</a>`),

            ...(!window.RNIS_SETTINGS.hide_skpdi ? [
                new Column('СКПДИ')
                    .fromField('external_id'),
            ] : []),

            new Column('Дата')
                .fromField('date')
                .withDateFilter()
                .withDrawer(item => item.date && moment(item.date).format(formats.DATE)),

            new Column('Предприятие')
                .fromField('unit_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid))
                .withFilter('withUnits', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 2000,
                        },
                        filters: {
                            withComponent: 'utility',
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            new Column('Статус')
                .fromField('status_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.status_uuid && this.state.related.get(item.status_uuid))
                .withFilter('withStatus', async () => {
                    const response = await this.props.getDictionaryList('utility_task_statuses');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }),

            new Column('Ручной зачет')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_confirmed_manual}/>)),

            new Column('Назначенные ТС')
                .fromField('vehicles')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => {
                    return _.filter(_.map(item.resources || [], (resource) => {
                        return this.state.related.get(resource.base_vehicle_uuid);
                    })).join('<br/>');
                })
                .withAsyncFilter('withBaseVehicles', async (search) => {
                    const response = await this.props.getVehicleList({
                        search,
                        order: {
                            column: 'state_number',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 50,
                        },
                    });
                    if (response.isOk) {
                        return _.map(response.payload.items, item => ({
                            uuid: item.uuid,
                            name: item.state_number,
                        }));
                    }
                    return [];
                }),

            new Column('Виды работ')
                .fromField('work_types')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => {
                    return _.truncate(_.uniq(_.filter(_.map(item.item_roads || [], (item) => {
                        if (!item[1]) {
                            return null;
                        }
                        return this.state.related.get(item[1]);
                    }))).join('<br/>'), {
                        length: 255,
                    });
                })
                .withFilter('withWorkTypes', async () => {
                    const response = await this.props.getDictionaryList('work_types');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }),

            new Column('Участки дорог')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => {
                    return _.truncate(_.uniq(_.filter(_.map(item.item_roads || [], (item) => {
                        if (!item[0]) {
                            return null;
                        }
                        return this.state.related.get(item[0]);
                    }))).join('<br/>'), {
                        length: 255,
                    });
                }),

            new Column('Архивная телематика')
                .denyColumnFilter()
                .denyOrder()
                .withDrawer(item => item.archived_telematics_time ? moment(item.archived_telematics_time).format(formats.DATETIME) : null),
        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteTask(data);
    }

    async loadData(meta) {
        meta.filters.onlyUuid = true;
        meta.filters.withPeriod = [
            moment(this.state.from).format(formats.DATE_API),
            moment(this.state.to).format(formats.DATE_API),
        ];
        return await this.props.getTasks(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const statuses = _.map(_.uniq(_.filter(_.map(result, 'status_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursTaskStatuses\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const units = _.map(_.uniq(_.filter(_.map(result, 'unit_uuid'))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const vehicles = _.map(_.uniq(_.filter(_.map(_.flatten(_.map(result, 'resources')), 'base_vehicle_uuid'))), (uuid) => ({
            class: 'App\\Model\\Vehicle',
            uuid: uuid,
            source: 'vehicles',
        }));

        const workTypes = _.map(_.uniq(_.filter(_.map(_.flatten(_.map(result, 'item_roads')), 1))), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\WorkTypes\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const roadParts = _.map(_.uniq(_.filter(_.map(_.flatten(_.map(result, 'item_roads')), 0))), (uuid) => ({
            class: 'App\\Model\\RoadPart',
            uuid: uuid,
            source: 'utility',
        }));

        const roadRepairParts = _.map(_.uniq(_.filter(_.map(_.flatten(_.map(result, 'item_roads')), 0))), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursRoadRepairParts\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(_.concat(statuses, units, vehicles, workTypes, roadParts, roadRepairParts));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value});
        storage.set('utility-tasks-from', value);
        this.reload();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value});
        storage.set('utility-tasks-to', value);
        this.reload();
    };

    renderHeaderActions() {
        return [
            <div key="diapason">
                <div className="top-menu__label">Период:</div>
                <Datepicker style="dark" value={this.state.from} onChange={this.fromChange}/>
                <Datepicker style="dark" value={this.state.to} onChange={this.toChange}/>
            </div>,
            super.renderHeaderActions(),
        ];
    }
}