import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import GlobalLoaderComponent from "components/ui/global-loader";
import Page from 'components/ui/page';
import {getDisplays} from "store/reducers/kiutr/displays";
import MapComponent from "components/ui/map";
import Button from "components/ui/button";
import CheckboxDropdown from "components/ui/checkbox-dropdown";
import _ from 'lodash';
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import debounce from 'throttle-debounce/debounce';
import {getRoutes} from "store/reducers/routes/routes";
import systems from "dictionaries/systems";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import PageModalComponent from "components/ui/page-modal";
import StopPointsMtaLayer from "components/ui/map/layers/stop-points-mta";

@connect(state => ({}), {})

export default class DisplaysSelectOnMapComponent extends Component {

    map = null;
    state = {};

    render() {
        const buttons = (
            <ModalTopMenuList>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuList>
        );

        return (
            <PageModalComponent
                header={{title: 'Выбор места размещения', buttons}}
                onClose={this.props.onClose}
                className={`profile-modal`}
            >
                {this.renderContent()}
            </PageModalComponent>
        );
    }

    renderContent() {
        return (
            <MapComponent
                ref={n => {
                    if (!this.map) {
                        this.map = n;
                        this.forceUpdate();
                    }
                }}
                showLayers={false}
                showSearch={false}
            >
                {this.map ? (
                    <StopPointsMtaLayer
                        key="layer"
                        map={this.map.getWrappedInstance()}
                        leafletMap={this.map.getWrappedInstance().map}
                        onStopPointClick={this.props.onStopPointClick}
                    />
                ) : null}
            </MapComponent>
        );
    }
}