import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/button';
import * as alerts from "helpers/alerts";
import GlobalLoader from 'components/ui/global-loader';
import {Link} from 'react-router';

import {connect} from 'react-redux';
import {getMaintenanceStatus, login, esiaLogin, getEsiaUrl, esiaToRnisUserConfirm} from 'store/reducers/auth';
import {connectWsRpc} from 'store/reducers/main';

import Errors from 'dictionaries/errors';

import './login.less';
import gosuslugi from "../../../../static/img/gosuslugi.png";
import rosatom from "../../../../static/img/rosatom.png";
import Input from "components/ui/input";
import moment from "moment";
import formats from "dictionaries/formats";
import classNames from 'classnames';
import * as storage from "utils/storage";

@connect(state => ({
    isConnected: state.main.get('isConnected'),
    isAuthorized: state.auth.get('isAuthorized')
}), {login, connectWsRpc, getMaintenanceStatus, getEsiaUrl, esiaLogin, esiaToRnisUserConfirm})

export default class LoginComponent extends Component {

    state = {
        loading: false,
        uuidForNewUser: null,
        errorUnitName: null,
        errorUnitActivity: null,

        username: '',
        password: '',

        esiaUrl: '',
        error: null,

        maintenance: {
            status: false,
            message: '',
        },
    };

    async getMS() {
        const response = await this.props.getMaintenanceStatus();
        if (response.isOk) {
            this.setState({
                maintenance: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async componentDidMount() {
        if (window.RNIS_SETTINGS.esia) {
            const { state, code } = localStorage.getItem("esia") ? JSON.parse(localStorage.getItem("esia")) : {state: null, code: null};
            const remote_host = location.origin;
            if (state && code) {
                const response = await this.props.esiaLogin({ state, code, remote_host });
                if (!response.payload.status) {
                    response.isOk ? this.loginSuccess(response) : this.loginError(response);
                } else {
                    const status = response.payload.status;
                    if (status === "new") {
                        await this.setState({
                            uuidForNewUser: response.payload.uuid
                        });
                    }
                    if (status === "canceled") {
                        alerts.prompt('Заявка была отменена пользователем. Вы хотите пройти процедуру повторной регистрации в РНИС?', null, async () => {
                            await this.setState({
                                uuidForNewUser: response.payload.uuid
                            });
                        }, 'Пройти повторно');
                    }
                    if (status === "confirmed") {
                        alerts.alert("Ваша заявка находится на рассмотрении администратора РНИС.")
                    }
                    if (status === "rejected") {
                        alerts.alert("Ваша заявка была отклонена администратором РНИС.")
                    }
                }
            }
            const response = await this.props.getEsiaUrl(location.origin);
            if (response.isOk) {
                this.setState({
                    esiaUrl: response.payload.authorization_url
                })
            }
        }

        if (!this.props.isConnected) {
            this.props.connectWsRpc();
        }
        if(window.RNIS_SETTINGS.maintenanceStatus) {
            await this.getMS();
        }
    }

    async getMS() {
        const response = await this.props.getMaintenanceStatus();
        if (response.isOk) {
            this.setState({
                maintenance: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    getForm() {
        const esiaImgClass = window.RNIS_SETTINGS.CITY_OMSK ? 'esia__image omsk' : 'esia__image';
        const esiaIconName = window.RNIS_SETTINGS.CITY_OMSK ? 'esia__icon omsk' : 'esia__icon';
        if (!this.state.uuidForNewUser) {
            return <form action="#" className="login-form">
                <Input
                    placeholder="Логин"
                    error={this.state.error}
                    onChange={::this.onChangeUsername}
                    value={this.state.username}
                    onEnter={::this.login}
                />
                <div className="password-input">
                    <Input
                        placeholder="Пароль"
                        type="password"
                        error={this.state.error}
                        onChange={::this.onChangePassword}
                        value={this.state.password}
                        onEnter={::this.login}
                    />
                </div>
                <div className="notification">Если вы забыли пароль, то просьба обратиться к администратору
                    системы
                </div>

                <Button
                    text="Войти"
                    size={window.RNIS_SETTINGS.CITY_OMSK ? 'md' : 'lg'}
                    disabled={!(this.state.username && this.state.password)}
                    onClick={::this.login}
                />
                {window.RNIS_SETTINGS.esia ? (
                    <div className="esia">
                        <hr className="esia__splitter"/>
                        <span className="esia__title">{window.RNIS_SETTINGS.CITY_MURMANSK ? 'Авторизация через Наш Север' : 'Войти через Госуслуги'}</span>
                        <a className={esiaIconName} href={this.state.esiaUrl ? this.state.esiaUrl : '/'}>
                            <img className={esiaImgClass} src={window.RNIS_SETTINGS.CITY_MURMANSK ? rosatom : gosuslugi} alt="gosuslugi_icon"/>
                        </a>
                    </div>) : null}

            </form>
        } else {
            return <form action="#">
                <div className={classNames("input-field", this.state.unitName && "filled")}>
                    <Input
                        placeholder="Название предприятия"
                        onChange={::this.onChangeUnitName}
                        value={this.state.unitName}
                    />
                    {this.state.errorUnitName && <p className="text-error">{this.state.errorUnitName}</p>}
                </div>
                <div className={classNames("input-field", this.state.unitActivity && "filled")}>
                    <Input
                        placeholder="Направление деятельности предприятия"
                        onChange={::this.onChangeUnitActivity}
                        value={this.state.unitActivity}
                    />
                    {this.state.errorUnitActivity && <p className="text-error">{this.state.errorUnitActivity}</p>}
                </div>
                <Button
                    text="Подтвердить"
                    size={'md'}
                    disabled={this.state.loading}
                    onClick={::this.confirmCreateNewUser}
                />
                <Button
                    text="Отменить"
                    size={'md'}
                    disabled={this.state.loading}
                    onClick={::this.refuteCreateNewUser}
                />
            </form>
        }
    }

    render() {
        const loader = (this.state.loading) ? (<GlobalLoader/>) : '';
        return (
            <div className={classNames("Login", window.RNIS_SETTINGS.esia && "Login--omsk")}>
                {loader}
                {!window.RNIS_SETTINGS.LOGOIMAGE ? (<Link to="/" className={classNames('b-logo', {
                    empty: window.location.hostname.indexOf('mosreg') === -1,
                })}/>) : (<a className="image-logo" href="/">
                    <img src={window.RNIS_SETTINGS.LOGOIMAGE} alt=""/>
                </a>)}

                <div className="Login__title">
                    {this.state.uuidForNewUser ? "Регистрация нового пользователя" : "Вход в систему"}
                </div>
                {this.state.maintenance.status ? (
                    <div>{this.state.maintenance.message} до {moment(this.state.maintenance.will_be_available_at).format(formats.DATETIME_SHORT)}</div>
                ) : null}
                {this.getForm()}
            </div>
        );
    }

    onChangeUnitName({target: {value}}) {
        this.setState({error: null, unitName: value});
    }

    onChangeUnitActivity({target: {value}}) {
        this.setState({error: null, unitActivity: value});
    }

    onChangePassword({target: {value}}) {
        this.setState({error: null, password: value});
    }

    onChangeUsername({target: {value}}) {
        this.setState({error: null, username: value});
    }

    async confirmCreateNewUser() {
        if (this.state.unitName && this.state.unitActivity) {
            this.setState({loading: true, errorUnitName: null, errorUnitActivity: null});
            try {
                const response = await this.props.esiaToRnisUserConfirm({
                    "uuid": this.state.uuidForNewUser,
                    "status": "confirmed",
                    "unit_name": this.state.unitName,
                    "unit_description": this.state.unitActivity
                })
                if (response.isOk) {
                    this.setState({loading: false, uuidForNewUser: null});
                    alerts.alert('Ваша заявка принята, ожидайте подтверждения администратором системы');
                } else {
                    this.setState({loading: false});
                    console.error(response.data.responseText);
                    alerts.error("Что-то пошло не так :(")
                }
            } catch(e) {
                this.setState({loading: false});
                console.error(e);
                alerts.error("Что-то пошло не так :(")
            }
        } else {
            if (!this.state.unitName) {
                this.setState({
                    errorUnitName: 'Требуется заполнить поле'
                })
            }
            if (!this.state.unitActivity) {
                this.setState({
                    errorUnitActivity: 'Требуется заполнить поле'
                })
            }
        }
    }

    async refuteCreateNewUser() {
        if (this.state.unitName && this.state.unitActivity) {
            this.setState({loading: true, errorUnitName: null, errorUnitActivity: null});
            try {
                const response = await this.props.esiaToRnisUserConfirm({
                    "uuid": this.state.uuidForNewUser,
                    "status": "canceled",
                    "unit_name": this.state.unitName,
                    "unit_description": this.state.unitActivity
                })
                if (response.isOk) {
                    this.setState({loading: false, uuidForNewUser: null});
                    alerts.alert('Заявка отменена');
                } else {
                    this.setState({loading: false});
                    console.error(response.data.responseText);
                    alerts.error("Что-то пошло не так :(")
                }
            } catch (e) {
                this.setState({loading: false});
                console.error(e);
                alerts.error("Что-то пошло не так :(")
            }
        } else {
            if (!this.state.unitName) {
                this.setState({
                    errorUnitName: 'Требуется заполнить поле'
                })
            }
            if (!this.state.unitActivity) {
                this.setState({
                    errorUnitActivity: 'Требуется заполнить поле'
                })
            }
        }
    }

    async login() {
        this.setState({loading: true});
        const response = await this.props.login(this.state.username, this.state.password);
        this.setState({loading: false});

        if(response.isOk) {
            localStorage.setItem('loginTime', moment())
            return this.loginSuccess(response);
        } else {
            return this.loginError(response);
        }
    }

    loginSuccess(response) {
        localStorage.removeItem("esia");
        const url = storage.get('auth');
        this.props.router.push(url || '/');
    }

    loginError(error) {
        if (error.hasErrorWithCode(Errors.InvalidLoginPasswordException.serverCode)) {
            this.setState({
                error: Errors.InvalidLoginPasswordException.message
            });
        } else {
            error.showErrors();
        }
    }
}
