import React from 'react';
import {List} from 'immutable';
import {connect} from 'react-redux';
import _ from 'lodash';

import BaseTableComponent from 'components/base/base_table';
import Page from 'components/ui/page';
import IconButton from 'components/ui/icon-button';
import UnitsEditor from './editor';

import {mapDatatablesRequestToMeta} from 'helpers/api';

import * as alerts from "helpers/alerts";
import TableContainer from "components/ui/Table/Container/TableContainer";
import Column from "components/ui/column";
import AppearTransition from "components/ui/transitions/appear";
import {EntityList} from "helpers/entity";
import currentUser from 'helpers/current-user';
import {deleteUnit, getUnits} from "store/reducers/organizational_units/units";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getEntityNames} from "store/reducers/system";
import ContextTooltip from "components/ui/context-tooltip";
import systems from "dictionaries/systems";
import {Link} from "react-router";
import HotKeysManager from "components/ui/HotKeys/Manager/HotKeysManager";
import {preventDefaultEventHandlers} from "helpers/hot_key_handlers";

@connect(state => ({}), {getUnits, deleteUnit, getDictionaryList, getEntityNames})

export default class UnitsTable extends BaseTableComponent {

    modelClass = 'App\\Model\\Units';
    modelSource = 'organizational_units';

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            component: null,
            columns: List(this.getColumns()),
            related: new EntityList(),
        });
    }

    getBaseUrl() {
        return `/${this.state.component}/units/units-table`;
    }

    getTitle() {
        let title = 'Предприятия';
        if (this.state.component === 'road') {
            title = window.RNIS_SETTINGS.organizational_units_name || 'Предприятия (РУАД)';
        }
        if (this.state.component === 'control') {
            title = 'Предприятия (подразделения)';
        }
        return <span>{systems[this.state.component]} → <Link
            to={`/${this.state.component}/dictionaries`}>Справочники</Link> → {title}</span>;
    }

    async componentWillUpdate(props, state) {
        if (props.params.component !== state.component) {
            await this.setState({
                component: props.params.component,
            });
            state.component && this.reload();
        }

        const uuid = props.params.uuid;
        const newEditorUuid = (uuid && uuid !== 'create') ? uuid : null;
        if (this.state.editorUuid !== newEditorUuid || this.state.showEditorModal !== !!uuid) {
            this.setState({
                showEditorModal: !!uuid,
                editorUuid: newEditorUuid,
                editorModalMode: newEditorUuid ? 'edit' : 'add',
            });
        }
    }

    componentDidMount() {
        this.forceUpdate();
    }

    getHotKeyHandlers() {
        return preventDefaultEventHandlers(Object.assign(super.getHotKeyHandlers(), {
            delete: ::this.deleteSelected,
            tree: ::this.goToTreeView,
        }));
    }

    render() {
        let table = this.renderTable();

        const editor = this.state.showEditorModal
            ? (
                <UnitsEditor
                    {...this.props}
                    key="editor"
                    onClose={::this.closeEditor}
                    onSubmit={::this.submitEditor}
                    onEditClick={::this.onEditorEditClick}
                    onDeleteClick={::this.onEditorDeleteClick}
                    mode={this.state.editorModalMode}
                    uuid={this.state.editorUuid}
                />
            ) : '';

        return (
            <HotKeysManager {...this.getHotKeyHandlers()}>
                <Page title={this.getTitle()}
                      pageId="Units"
                      headerActions={this.renderHeaderActions()}
                      headerContents={this.renderHeaderContents()}>

                    <TableContainer>
                        {table}
                    </TableContainer>

                    <AppearTransition>
                        {editor}
                    </AppearTransition>
                </Page>
            </HotKeysManager>
        );
    }

    getColumns() {
        return this.prepareColumns([
            new Column('Наименование')
                .fromField('name'),

            new Column('Роли предприятия')
                .denyOrder()
                .withDrawer(item => _.map(item.unit_types, (unit_type_uuid) => this.state.related.get(unit_type_uuid)).join('<br/>'))
                .denyColumnFilter()
                .withFilter('withUnitType', async () => {
                    const response = await this.props.getDictionaryList('company_types');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return {};
                }),

            new Column('Родительское предприятие')
                .fromField('parent_unit_uuid')
                .withDrawer(item => item.parent_unit_uuid && this.state.related.get(item.parent_unit_uuid))
                .denyColumnFilter(),

            new Column('Юридический адрес')
                .fromField('legal_address'),

            new Column('Фактический адрес')
                .fromField('actual_address'),

            new Column('Номер телефона')
                .fromField('phone'),

            new Column('Электронная почта')
                .fromField('email'),

            new Column('КПП')
                .fromField('kpp'),

            new Column('ИНН')
                .fromField('inn'),
        ]);
    }

    async closeEditor() {
        await this.props.router.push(this.getBaseUrl());
    }

    async submitEditor() {
        this.reload();
        await this.closeEditor();
    }

    async showEditor() {
        this.showEditorWithUuid();
    }

    async onEditorEditClick() {
        const uuid = this.state.editorUuid;
        await this.closeEditor();
        return this.showEditorWithUuid(uuid);
    }

    async onEditorDeleteClick() {
        return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            await this.props.deleteUnit({
                uuid: this.state.editorUuid,
            });

            await this.closeEditor();
            this.reload();
        });
    }

    async showEditorWithUuid(uuid = null) {
        await this.closeEditor();
        this.props.router.push(`${this.getBaseUrl()}/${uuid || 'create'}`);
    }

    onDblClick(data) {
        if (data.uuid && currentUser.can('com.rnis.organizational_units.unit', 'update')) {
            this.showEditorWithUuid(data.uuid);
        }
    }

    async editSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            this.showEditorWithUuid(selected.uuid);
        }
    }

    async deleteSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
                await this.props.deleteUnit({
                    uuid: selected.uuid,
                });
                this.reload();
            });
        }
    }

    goToTreeView() {
        this.props.router.push(`/${this.state.component}/units/units`);
    }

    async loadCallback(request, drawCallback) {
        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        meta.filters.withComponent = this.state.component;
        meta.response_data = [
            'items/uuid',
            'items/deleted_at',
            'items/has_children',
            'items/name',
            'items/unit_types',
            'items/parent_unit_uuid',
            'items/legal_address',
            'items/actual_address',
            'items/phone',
            'items/email',
            'items/kpp',
            'items/inn',
        ];

        const response = await this.props.getUnits(meta, false);

        if (response.isOk) {
            const data = response.payload.items;
            //console.log(data);

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: response.data.headers.meta.pagination.total,
                recordsTotal: response.data.headers.meta.pagination.total
            };

            drawCallback(json);

            this.loadRelatedEntities(json, drawCallback);
            this.selectedRowsRecalc();
            this.setState({success: true});
        } else {
            response.showErrors();
        }
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const unitTypes = _.map(_.filter(_.flatten(_.map(result, 'unit_types'))), (uuid) => ({
            class: 'App\\Dictionaries\\Common\\CompanyTypes\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const parentUnits = _.map(_.filter(_.map(result, 'parent_unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));
        const response = await this.props.getEntityNames(_.concat(unitTypes, parentUnits));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }

        return result;
    }

    getCreateButton() {
        if (!currentUser.can('com.rnis.organizational_units.unit', 'create')) {
            return null;
        }
        return (
            <ContextTooltip key="base-table-list.create" code="base-table-list.create" default="Добавить">
                <IconButton
                    icon="plus"
                    tooltip="Добавить"
                    onClick={::this.showEditor}
                    active={this.state.showEditorModal && (this.state.editorUuid === 'create')}
                    className="popup-link"
                />
            </ContextTooltip>
        );
    }

    renderHeaderActions() {
        return [
            currentUser.can('com.rnis.organizational_units.unit', 'delete') ? (
                <ContextTooltip key="base-table-list.delete" code="base-table-list.delete" default="Удалить">
                    <IconButton icon="basket" disabled={this.state.selectedRowsCount === 0}
                                onDisabledClick={::this.alertToSelect}
                                onClick={::this.deleteSelected}/>
                </ContextTooltip>
            ) : null,
            <ContextTooltip key="units.tree" code="units.tree" default="Иерархический вид">
                <IconButton
                    icon="hierarchy"
                    onClick={::this.goToTreeView}
                />
            </ContextTooltip>
        ];
    }
}
