import React from 'react';
import ColorPicker from 'react-color-picker';
import 'react-color-picker/index.css'

import './color-picker.less';

export default class ColourPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {color: 'red', visible: false};
    }
    onDrag(color) {
        const { change, pickerName } = this.props;
        this.setState({
            color
        }, () => change(pickerName, this.state.color))
    }

    togglePicker() {
        this.setState({visible: !this.state.visible})
    }

    render() {
        const {placeholder} = this.props;
        return (
          <div>
              {this.state.visible &&
                  <div>
                      <ColorPicker
                          value={this.state.color}
                          onDrag={::this.onDrag}
                      />
                      <button onClick={::this.togglePicker}>Закрыть</button>
                  </div>
              }
              <div
                  onClick={::this.togglePicker}
                  style={{
                  background: this.state.color,
                  width: 100,
                  height: 50,
                  color: 'white',
              }}
              >
                  {placeholder}: {this.state.color}
              </div>
          </div>
        );
    }
}