import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getTechnocards = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.kurs.getTechnocards(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getTechnocard = (uuid) => async (dispatch) => {
    try {
        const response = await api.kurs.getTechnocard(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createTechnocard = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.createTechnocard(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateTechnocard = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.updateTechnocard(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteTechnocard = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.deleteTechnocard(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};