import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getDisplays = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getDisplays(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getDisplay = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getDisplay(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createDisplay = (data) => async (dispatch) => {
    try {
        const response = await api.geo.createDisplay(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateDisplay = (data) => async (dispatch) => {
    try {
        const response = await api.geo.updateDisplay(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteDisplay = (data) => async (dispatch) => {
    try {
        const response = await api.geo.deleteDisplay(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getStopPointRoutes = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getStopPointRoutes(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getStopPointRoutesRun = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getStopPointRoutesRun(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
