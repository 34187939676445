import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import PageModal from 'components/ui/page-modal';
import {connect} from "react-redux";
import GlobalLoaderComponent from "components/ui/global-loader";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import TelematicsDevice from "components/modules/maps/telematics-packet";
import {getVehicle, getVehicleList} from "store/reducers/vehicles/vehicles";
import {getHistory, getHistoryByVehicle} from "store/reducers/maps";
import _ from 'lodash';
import './style.less';
import {getRouteVariant} from "store/reducers/routes/route_variants";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import {getStopPoints} from "store/reducers/geo/stop-points";
import {getVehicles} from "store/reducers/kurs/vehicles";
import KursTaskMapHistoryMap from "components/modules/kurs/tasks/KursTaskMapHistory/KursTaskMapHistoryMap/index";
import CheckboxDropdown from "components/ui/checkbox-dropdown";

@propTypes({
    data: PropTypes.object,
    onClose: PropTypes.func
})

@defaultProps({
    onClose: () => {
    }
})

@connect(state => ({}), {getVehicleList, getVehicles, getHistory, getHistoryByVehicle, getRouteVariant, getStopPoints})

export default class KursTaskMapHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vehicles: {},
            history: [],
            isError: false,
            isLoading: false,
            routeVisible: true,
            selectedVehicle: null,
        };
    }

    componentDidMount() {
        this.loadData();
    }

    renderContent() {
        const {run} = this.props.data;

        const geojson = this.state.routeVisible ? this.props.data.geojson : null;

        return <KursTaskMapHistoryMap {...this.props} data={{
            ...this.props.data, ...{
                history: this.state.history,
                geojson,
            }
        }}/>
    }

    render() {
        const stateNumber = _.get(_.find(this.state.vehicles, {uuid: this.state.selectedVehicle}), 'state_number');
        const title = 'Мониторинг ТС' + (stateNumber ? ` - гос. номер ${stateNumber}` : '');
        const content = this.renderContent();

        const selected = {};
        selected[this.state.selectedVehicle] = true;

        const buttons = (
            <ModalTopMenuList>
                <div className="top-menu__item">
                    <CheckboxDropdown
                        withToggle={false}
                        items={_.map(this.state.vehicles, (vehicle) => ({
                            value: vehicle.uuid,
                            label: vehicle.state_number,
                        }))}
                        selectedItems={selected}
                        toggleSelectedItem={::this.selectVehicle}
                        contextKey="kurs.task-map-history.vehicle-select"
                        contextDefault="Выбор ТС"
                        icon="select"
                        className="radio-dropdown"
                        withSearch={false}
                        closeOnSelect={true}
                    />
                </div>
                <ModalTopMenuList className="top-menu_modal_edit">
                    <ContextTooltip key="shift-map.toggle-route" code="shift-map.toggle-route"
                                    default="Отображение планового маршрута">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_eye"
                            onClick={::this.toggleRoute}
                        />
                    </ContextTooltip>
                    <ModalTopMenuListSeparator key="separator"/>
                </ModalTopMenuList>
                <ModalTopMenuButton
                    key="close"
                    className="_close"
                    tooltip="Отменить"
                    onClick={this.props.onClose}
                />
            </ModalTopMenuList>
        );

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}
                className="shift-map-modal b-modal__map"
                withFade
            >
                {content}
            </PageModal>
        );
    }

    async selectVehicle(uuid) {
        await this.setState({
            selectedVehicle: uuid,
        });

        const vehicle = _.find(this.state.vehicles, {uuid});
        const history = await this.loadHistory(vehicle);
        this.setState({
            history,
        });
    }

    toggleRoute() {
        this.setState({
            routeVisible: !this.state.routeVisible,
        });
    }

    async loadVehicles() {
        const uuids = this.props.data.vehicleUuids;
        if (uuids.length === 0) {
            return [];
        }
        const response = await this.props.getVehicles({
            filters: {
                withUuid: uuids,
            },
        });
        if (response.isOk) {
            return response.payload.items;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadHistory(vehicle) {
        const response = await this.props.getHistoryByVehicle(vehicle.uuid, this.props.data.from, this.props.data.to);

        if (response.isOk) {
            const telematics = _.first(response.payload.telematics);
            if (telematics) {
                return _.map(telematics.points, (point) => {
                    return new TelematicsDevice(telematics.device_id, point);
                });
            }
            else {
                return [];
            }
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadBaseVehicles(uuids) {
        if (uuids.length === 0) {
            return [];
        }

        const response = await this.props.getVehicleList({
            filters: {
                withUuid: uuids,
            },
        });
        if (response.isOk) {
            return response.payload.items;
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadData() {
        this.setState({isError: false, isLoading: true});

        const baseVehicles = await this.loadVehicles();

        const vehicles = await this.loadBaseVehicles(_.map(baseVehicles, 'vehicle_uuid'));
        const history = await this.loadHistory(_.first(vehicles));
        //console.log(history);

        if (history) {
            this.setState({
                isError: false,
                isLoading: false,
                selectedVehicle: _.get(_.first(vehicles), 'uuid'),
                vehicles,
                history,
            });
        }
    }
}
