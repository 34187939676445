import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import classNames from 'classnames';

import currentUser from 'helpers/current-user';

import * as alerts from "helpers/alerts";

import '../../modules/tablet/_style/style.less';
import TabletMenu from "components/ui/tablet/menu";

@propTypes({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    header_top_right: PropTypes.object,
    header_btns: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    header_bottom: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    modalsActive: PropTypes.bool,
})

@defaultProps({
    title: '',
    header_top_right: null,
    header_btns: null,
    header_bottom: null,
    modalsActive: false,
})

export default class TabletPage extends Component {

    state = {
        menuExpanded: false,
    };

    render() {
        const menu = this.getMenu();

        return (
            <div id="main-wrapper">
                <button
                    className={classNames('hamburger', this.props.header_btns ? 'w-back' : '', this.state.menuExpanded ? 'expand' : '')}
                    title="Меню"
                    onClick={::this.toggleMenu}>
                    <svg className="icon icon_menu_w icon_white">
                        <use xlinkHref="/img/spriteSVG.svg#icon_menu_w"/>
                    </svg>
                </button>
                <TabletMenu items={menu} expanded={this.state.menuExpanded}/>
                <div id="main-content">
                    <div className="header-top">
                        <div className="header-top__left">
                            <ul className="header-top__icons">
                                {this.props.header_btns}
                            </ul>
                        </div>
                        <div className="header-top__title">{this.props.title}</div>
                        <div className="header-top__right">
                        </div>
                    </div>
                    <div className="header-bottom">
                        {this.props.header_bottom}
                    </div>
                    {this.props.children}
                </div>
                {this.props.modalsActive ? (
                    <div className="modals">
                        <div className="modals__overlay"/>
                    </div>
                ) : null}
            </div>
        );
    }

    toggleMenu() {
        this.setState({
            menuExpanded: !this.state.menuExpanded,
        });
    }

    onClickLeftProfile(e) {
        e.preventDefault();

        this.setState({showLogoutModal: true});
    }

    closeModal() {
        this.setState({showLogoutModal: false});
    }

    logout() {

    }

    onDemoClick(e) {
        e.preventDefault();

        alerts.alert('Находится в разработке');
    }

    getMenu() {
        return [
            {
                text: 'Сводные показатели',
                class: 'analytic',
                route: '/tablet/dashboard',
            },
            {
                text: 'Карта',
                class: 'map',
                superclass: 'm-menu__link_bb',
                route: '/tablet/map/',
                disabled: true,
            },
            {
                text: 'Пассажирские перевозки',
                class: 'bus',
                route: '/tablet/kiutr/',
            },
            {
                text: 'Обслуживание дорог',
                class: 'repair_road',
                route: '/tablet/kurs/',
            },
            {
                text: 'Перевозки детей',
                class: 'children',
                route: '/tablet/children/',
            },
            {
                text: 'Коммунальная техника',
                class: 'buldozer',
                route: '/tablet/communal/',
            },
            {
                text: 'Вывоз мусора',
                class: 'trash',
                route: '/tablet/garbage/',
            },
            {
                text: 'ГУ ГАТН',
                class: 'analitics',
                route: '/tablet/gatn/',
            },
        ];
    }

}