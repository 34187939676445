import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {deleteFaq, getFaq} from "store/reducers/portal/faq";
import {deletePage, getPages} from "store/reducers/portal/pages";
import IconButton from "components/ui/icon-button";
import currentUser from 'helpers/current-user';

@connect(state => ({}), {getPages, deletePage})

export default class PortalPagesComponent extends BaseTableWithEditorComponent {

    title = `Портал ${currentUser.user.title.SHORT_TITLE} → Страницы`;
    baseUrl = '/portal/pages';

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Заголовок')
                .fromField('title'),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deletePage(data);
    }

    async loadData(meta) {
        return await this.props.getPages(meta);
    }

    renderHeaderActions() {
        return [
        ];
    }

    getCreateButton() {
        return null;
    }
}