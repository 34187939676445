import React from 'react';

export default function ShiftBoundsTable({startTimePlan, endTimePlan, startTimeFact, endTimeFact}) {
    return (
        <div className="output-timeline">
            <div className="output-timeline__interval">
                Начало выхода <span>(факт/план)</span>
                <span className="output-timeline__value">{startTimePlan}</span>
                <span className="output-timeline__value">{startTimeFact}</span>
            </div>
            <div className="output-timeline__interval">
                Окончание выхода <span>(факт/план)</span>
                <span className="output-timeline__value">{endTimePlan}</span>
                <span className="output-timeline__value">{endTimeFact}</span>
            </div>
        </div>
    );
}