import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import _ from 'lodash';

import { connect } from 'react-redux';

import BaseEditorFormComponent from 'components/base/base-editor-form';
import Block from 'components/ui/form/block';
import Accordion from 'components/ui/accordion/accordion';
import AccordionItem from 'components/ui/accordion/accordion-item';
import BaseEditor from 'components/base/base-editor';
import { getEventRule, createEventRule, updateEventRule, getEventTypes } from 'store/reducers/notifications';
import debounce from 'throttle-debounce/debounce';
import systems from 'dictionaries/systems';
import { getUnits } from 'store/reducers/organizational_units/units';
import vehicle_statuses from 'dictionaries/vehicle_statuses';
import { getVehicleList } from 'store/reducers/vehicles/vehicles';
import { getDictionaryList } from 'store/reducers/dictionaries/dictionary';
import { getLayerObjectsFull, getLayersFull } from 'store/reducers/user-map-objects/layer_list';
import NotificationRuleEditorMapComponent from 'components/modules/notifications/rules/map';
import ContextTooltip from 'components/ui/context-tooltip';
import ModalTopMenuListItem from 'components/ui/modal/modal-top-menu-list-item';
import NotificationRulesNotificationsComponent from 'components/modules/notifications/rules/notifications/index';
import './editor.less';
import SelectFilter from 'components/ui/select-filter';
import TableContainer from 'components/ui/Table/Container/TableContainer';
import NotificationTextParameters from 'components/modules/notifications/rules/text-parameters/index';
import { resizeModals } from 'helpers/modal-size';
import { events } from 'dom-helpers';
import currentUser from 'helpers/current-user';
import { getVehicles } from 'store/reducers/kurs/vehicles';
import { Link } from 'react-router';
import { getContract, getContracts } from 'store/reducers/kurs/contracts';
import { getRoadParts } from 'store/reducers/kurs/road_parts';
import { getContractWorks } from 'store/reducers/kurs/contract_works';
import { getRoutes } from 'store/reducers/routes/routes';
import { filterMap } from './constants';
import {getUserGeoObjects} from "store/reducers/user-map-objects/object_editor";

let resizeId;

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	uuid: PropTypes.string,
	entityUuid: PropTypes.string,
	entityClass: PropTypes.string
})
@connect((state) => ({}), { getEventRule, createEventRule, updateEventRule })
export default class Editor extends BaseEditor {
	title = '';
	modelClass = 'App\\Model\\EventRule';
	modalClassName = 'b-modal-event-rule-editor';

	getFullTitle() {
		return 'Событие';
	}

	static childContextTypes = {
		resizeModals: PropTypes.func
	};

	constructor(props, context) {
		super(props, context);

		Object.assign(this.state, {
			notificationsActive: false
		});

		this.onResize = this.onResize.bind(this);
	}

	getChildContext() {
		return { resizeModals: this.resizeModals };
	}

	componentWillMount() {
		events.on(window, 'resize', this.onResize);
	}

	async componentWillReceiveProps(props) {
		await super.componentWillReceiveProps(props);

		setTimeout(() => {
			this.resizeModals();
		}, 500);
	}

	componentWillUnmount() {
		events.off(window, 'resize', this.onResize);
	}

	onResize() {
		clearTimeout(resizeId);
		resizeId = setTimeout(this.resizeModals, 1000);
	}

	resizeModals() {
		const ignoreClasses = [ '' ];
		resizeModals('.b-modal', 'b-modal__collapse', 'move-right', ignoreClasses);
	}

	async loadData(uuid) {
		return await this.props.getEventRule(uuid);
	}

	async createItem(data) {
		data.parameters.days ? Number(data.parameters.days) : null;
		data.parameters.mileage ? Number(data.parameters.mileage) : null;
		data.parameters.workhours ? Number(data.parameters.workhours) : null;
		return await this.props.createEventRule(data);
	}

	async updateItem(data) {
		data.parameters.days ? Number(data.parameters.days) : null;
		data.parameters.mileage ? Number(data.parameters.mileage) : null;
		data.parameters.workhours ? Number(data.parameters.workhours) : null;

		return await this.props.updateEventRule(data);
	}

	renderHeaderBtns(mode) {
		if (mode === 'edit') {
			return (
				<ContextTooltip key="event-rule.notifications" code="event-rule.notifications" default="Оповещения">
					<ModalTopMenuListItem className="b-icon-link_icon_book" onClick={::this.showNotifications} />
				</ContextTooltip>
			);
		}

		return null;
	}

	getForm(item, onSubmit) {
		return (
			<EditorForm
				{...this.props}
				ref="form"
				mode={this.props.mode}
				onSubmit={onSubmit}
				onClose={::this.props.onClose}
				data={item}
				errors={this.state.errors}
			/>
		);
	}

	renderModals() {
		if (this.state.notificationsActive) {
			return (
				<NotificationRulesNotificationsComponent eventRuleUuid={this.props.uuid} onClose={::this.hideNotifications} />
			);
		}

		return null;
	}

	showNotifications() {
		this.setState({
			notificationsActive: true
		});

		this.resizeModals();
	}

	hideNotifications() {
		this.setState({
			notificationsActive: false
		});

		setTimeout(() => {
			this.resizeModals();
		}, 500);
	}

	composeItem(data) {
		let item = super.composeItem(data);

		item.parameters = item.parameters || {};
		item.components = item.components || [];
		item.units = item.units || [];
		item.vehicle_types = item.vehicle_types || [];
		item.vehicle_statuses = item.vehicle_statuses || [];
		item.vehicles = item.vehicles || [];

		return item;
	}
}

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	data: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	errors: PropTypes.object
})
@connect(
	(state) => ({}),
	{
		getEventTypes,
		getUnits,
		getVehicleList,
		getDictionaryList,
		getLayersFull,
		getLayerObjectsFull,
		getVehicles,
		getContract,
		getContracts,
		getContractWorks,
		getRoadParts,
		getRoutes,
		getUserGeoObjects
	},
	null,
	{ withRef: true }
)
class EditorForm extends BaseEditorFormComponent {
	state = {
		event_rule: {},
		event_types: [],
		units: [],
		vehicle_types: [],
		selectedVehicles: [],
		geozones: [],
		geozonesRaw: [],
		geozoneModalActive: false,
		geozoneModalGeometry: null,
		currentTextType: 0,
		work_types: [],
		road_parts: [],
		loadedVehicles: [],
		loadedRoutes: [],
		user_geo_object_list:[]
	};

	vehiclesLoadDebounce = debounce(500, ::this.loadVehicles);
	routesLoadDebounce = debounce(500, ::this.loadRoutes);

	order_statuses = {
		draft: 'Оформляется',
		active: 'Действует',
		ended: 'Завершен',
		defect: 'Дефект'
	};

	getData() {
		return this.state.event_rule;
	}

	async componentDidMount() {
		await this.setState({
			event_rule: this.prepareData(this.props.data)
		});
		this.loadEventTypes();
		this.loadUnits();
		this.loadDictionaries([ 'vehicle_types', 'kurs_task_statuses' ]);
		this.loadSelectedVehicles();
		// this.loadGeozones();
		this.loadSelectedRoutes();

		const contract = this.get('parameters.contract');
		if (contract) {
			this.loadContractData(contract);
		}
	}

	prepareData(data) {
		if (_.isArray(data.parameters)) {
			data.parameters = {};
		}

		return data;
	}

	async loadSelectedRoutes() {
		const response = await this.props.getRoutes({
			filters: {
				withComponent: 'kiutr',
				withUuid: this.get('parameters.routes') || [],
				withoutGeometry: true
			},
			pagination: {
				page: 1,
				limit: 9999
			},
			response_data: [ 'items/uuid', 'items/number', 'items/title' ]
		});
		if (response.isOk) {
			this.setState({
				loadedRoutes: _.map(response.payload.items, (route) => ({
					value: route.uuid,
					label: `${_.get(route, 'number')} ${_.get(route, 'title')}`
				}))
			});
		}
	}

	async loadEventTypes() {
		const response = await this.props.getEventTypes();
		let event_types = _.groupBy(_.sortBy(_.keyBy(response.payload.items, 'key'), 'name'), 'category');
		if (window.RNIS_SETTINGS.CITY_MURMANSK) {
			// FILTER event types for murmansk
			event_types = _.groupBy(
				_.sortBy(_.keyBy(response.payload.items.filter((el) => filterMap[el.name]), 'key'), 'name'),
				'category'
			);
		}

		if (response.isOk) {
			await this.setState({
				event_types
			});

			const dictionaries = _.filter(
				_.uniq(
					_.map(_.filter(_.flatten(_.map(this.state.event_types, 'parameters')), { type: 'dictionary' }), 'description')
				)
			);
			this.loadDictionaries(dictionaries);
		} else {
			response.showErrors();
		}
	}

	async loadUnits() {
		const response = await this.props.getUnits({
			filters: {
				withComponent: this.get('components', [])
			},
			pagination: {
				page: 1,
				limit: 10000
			}
		});

		if (response.isOk) {
			this.setState({
				units: _.map(response.payload.items, (unit) => ({
					label: unit.name,
					value: unit.uuid
				}))
			});
		} else {
			response.showErrors();
		}
	}
	loadGeozones = async (input, callback) => {
		const result = await this.props.getUserGeoObjects({
			search: input,
			response_data: [ 'items/uuid', "items/title" ],
			pagination: {
				page: 1,
				limit: 10000
			}
		});

		if (result.isOk) {
			await this.setState({
				userGeoObject: result.payload.items.length === 1 ? result.payload.items[0] : {},
				user_geo_object_list: result.payload.items
			});
			callback(null, {
				options: result.payload.items.map((item) => ({
					value: item.uuid,
					label: item.title
				})),
				complete: false
			});
		} else {
			result.showErrors();
		}
	};

	async loadLayers() {
		const response = await this.props.getLayersFull();

		if (response.isOk) {
			return response.payload.items;
		} else {
			return [];
		}
	}

	get(path, defaultValue = null) {
		return _.get(this.state.event_rule, path, defaultValue);
	}

	render() {
		const selectedEventType = _.find(_.flatten(_.values(this.state.event_types)), { key: this.get('type') });
		return (
			<div>
				<Accordion>
					<AccordionItem opened={true} title="Общая информация">
						<Block title="Наименование">{this.textInput('event_rule.name')}</Block>
						<Block title="Код">{this.textInput('event_rule.code')}</Block>
						<Block title=" ">{this.checkbox('event_rule.is_active', 'Активно')}</Block>
					</AccordionItem>
					<AccordionItem title="Структурная область действия">
						<Block size="xl" title="Область деятельности">
							{this.select(
								'event_rule.components',
								_.map(this.getComponents(), (label, value) => ({
									label,
									value
								})),
								{ multi: true, sort: true }
							)}
						</Block>
						<Block size="xl" title="Организация">
							{this.select('event_rule.units', this.state.units, { multi: true, sort: true })}
						</Block>
						{_.get(selectedEventType, 'target') === 'vehicle' ? (
							[
								<Block key="vehicle_types" size="xl" title="Тип ТС">
									{this.select('event_rule.vehicle_types', this.state.vehicle_types, {
										multi: true,
										sort: true
									})}
								</Block>,
								<Block key="vehicle_statuses" size="xl" title="Статус ТС">
									{this.select(
										'event_rule.vehicle_statuses',
										_.map(vehicle_statuses, (label, value) => ({
											label,
											value
										})),
										{ multi: true, sort: true }
									)}
								</Block>,
								<Block key="vehicles" size="xl" title="ТС">
									{this.get('vehicles', []).map((vehicleUuid, index) => {
										return (
											<div key={vehicleUuid + index}>
												{_.get(_.find(this.state.selectedVehicles, { value: vehicleUuid }), 'label', '...')}
												<a href="#" onClick={this.removeVehicle.bind(this, index)}>
													&times;
												</a>
											</div>
										);
									})}
									{this.selectAsync('event_rule.vehicle', ::this.vehiclesLoadDebounce)}
								</Block>
							]
						) : null}
						{_.get(selectedEventType, 'target') === 'order' ? (
							[
								<Block key="order_statuses" size="xl" title="Статус план-наряда">
									{this.select(
										'event_rule.order_statuses',
										_.map(this.order_statuses, (label, value) => ({
											label,
											value
										})),
										{ multi: true, sort: true }
									)}
								</Block>
							]
						) : null}
					</AccordionItem>
					<AccordionItem opened={true} title="Событие">
						<Block title="Тип события">
							{this.select(
								'event_rule.type',
								_.map(this.state.event_types, (eventTypes, category) => {
									return {
										label: category,
										options: _.map(eventTypes, (eventType) => ({
											label: eventType.name,
											value: eventType.key
										}))
									};
								})
							)}
						</Block>
						<Block title="Категория">{_.get(selectedEventType, 'category')}</Block>
						<Block title="Примечание">{_.get(selectedEventType, 'description')}</Block>
					</AccordionItem>
					<AccordionItem opened={true} title="Параметры события">
						{_.get(selectedEventType, 'parameters', []).map(::this.renderEventParameter)}
					</AccordionItem>
					<AccordionItem title="Текст сообщения">
						<Block size="xl">
							{this.checkbox('event_rule.text.is_override_text', 'Выводить текст сообщения в теле ленты')}
						</Block>
						<Block size="xl">
							<SelectFilter
								items={[ 'Журнал', 'Лента', 'Почта', 'Смс' ]}
								currentItem={this.state.currentTextType}
								onChange={(index) => this.setState({ currentTextType: index })}
							/>
						</Block>
						{this.state.currentTextType === 0 ? (
							<Block size="xl" title="Журнал">
								{this.textarea('event_rule.text.journal')}
							</Block>
						) : null}
						{this.state.currentTextType === 1 ? (
							<Block size="xl" title="Лента">
								{this.textarea('event_rule.text.system')}
							</Block>
						) : null}
						{this.state.currentTextType === 2 ? (
							<Block size="xl" title="Почта">
								{this.textarea('event_rule.text.email')}
							</Block>
						) : null}
						{this.state.currentTextType === 3 ? (
							<Block size="xl" title="Смс">
								{this.textarea('event_rule.text.sms')}
							</Block>
						) : null}
						<Block size="xl" title="Параметры">
							<NotificationTextParameters target={_.get(selectedEventType, 'target')} event={this.get('type')} />
						</Block>
					</AccordionItem>
				</Accordion>
				{this.state.geozoneModalActive ? (
					<NotificationRuleEditorMapComponent
						onClose={::this.closeGeozone}
						geometry={this.state.geozoneModalGeometry}
					/>
				) : null}
			</div>
		);
	}

	removeVehicle(index, e) {
		e.preventDefault();

		let event_rule = this.state.event_rule;
		event_rule.vehicles.splice(index, 1);

		this.setState({ event_rule });
	}

	removeParameterVehicle(key, index, e) {
		e.preventDefault();

		let event_rule = this.state.event_rule;
		event_rule.parameters[key].splice(index, 1);

		this.setState({ event_rule });
	}

	async setValue(key, value) {
		super.setValue(key, value);

		if (key === 'event_rule.vehicle' && value) {
			let event_rule = this.state.event_rule;
			event_rule.vehicles = event_rule.vehicles || [];
			event_rule.vehicles.push(value);

			await this.setState({ event_rule });
			this.loadSelectedVehicles();
			this.setValue(key, null);
		}
		if (key === 'event_rule.parameters.vehicle' && value) {
			let event_rule = this.state.event_rule;
			event_rule.parameters.vehicles = event_rule.parameters.vehicles || [];
			event_rule.parameters.vehicles.push(value);

			await this.setState({ event_rule });
			this.loadParameterVehicles();
			this.setValue(key, null);
		}
		if (key === 'event_rule.parameters.route' && value) {
			let event_rule = this.state.event_rule;
			event_rule.parameters.routes = event_rule.parameters.routes || [];
			event_rule.parameters.routes.push(value);

			await this.setState({ event_rule });
			this.loadSelectedRoutes();
			this.setValue(key, null);
		}
		if (key === 'event_rule.components') {
			this.loadUnits();
		}
		if (key === 'event_rule.parameters.contract') {
			this.loadContractData(value);
		}
	}

	async loadContractData(uuid) {
		if (!uuid) {
			this.setState({
				work_types: [],
				road_parts: []
			});
			return;
		}

		const contract = await this.loadContract(uuid);
		if (!contract) {
			return;
		}

		const workTypeUuids = _.map(contract.works, 'work_type_uuid');
		this.loadWorkTypes(workTypeUuids);
		this.loadContractRoads(uuid);
	}

	async loadContract(uuid) {
		const response = await this.props.getContract(uuid);

		if (response.isOk) {
			return response.payload;
		} else {
			response.showErrors();
		}

		return null;
	}

	async loadContractRoads(uuid) {
		const response = await this.props.getContractWorks({
			filters: {
				withContract: uuid
			},
			pagination: {
				page: 1,
				limit: 10000
			}
		});

		if (response.isOk) {
			this.loadRoadParts(_.map(response.payload.items, 'road_uuid'));
		} else {
			response.showErrors();
		}
	}

	async loadWorkTypes(uuids) {
		const response = await this.props.getDictionaryList('work_types', {
			filters: {
				uuid: uuids
			}
		});

		if (response.isOk) {
			this.setState({
				work_types: _.map(response.payload.documents, (document) => ({
					value: document.uuid,
					label: document.name
				}))
			});
		}
	}

	async loadRoadParts(uuids) {
		const response = await this.props.getRoadParts({
			filters: {
				withUuid: uuids
			}
		});

		if (response.isOk) {
			this.setState({
				road_parts: _.map(response.payload.items, (item) => ({
					value: item.uuid,
					label: item.name
				}))
			});
		}
	}

	async loadSelectedVehicles() {
		const response = await this.props.getVehicleList({
			filters: {
				withUuid: this.get('vehicles')
			}
		});
		if (response.isOk) {
			this.setState({
				selectedVehicles: _.map(response.payload.items, (i) => ({
					label: i.state_number,
					value: i.uuid
				}))
			});
		}
	}

	async loadParameterVehicles() {
		const response = await this.props.getVehicleList({
			filters: {
				withUuid: this.get('parameters.vehicles')
			}
		});
		if (response.isOk) {
			this.setState({
				loadedVehicles: _.map(response.payload.items, (i) => ({
					label: i.state_number,
					value: i.uuid
				}))
			});
		}
	}

	async getHeavyVehicles(search) {
		const response = await this.props.getVehicles({
			search,
			filters: {
				withMaxWeightWithoutLoad: 12000
			},
			pagination: {
				page: 1,
				limit: 20
			}
		});

		if (response.isOk) {
			return _.map(response.payload.items, 'vehicle_uuid');
		}

		return [];
	}

	async loadVehicles(input, callback) {
		const selectedEventType = _.find(_.flatten(_.values(this.state.event_types)), { key: this.get('type') });
		if (!input) {
			callback(null, {
				options: [],
				complete: false
			});
			return;
		}

		let filters = {};
		if (_.get(selectedEventType, 'key') === 'highway_in') {
			filters = {
				withUuid: await this.getHeavyVehicles(input)
			};
		}

		const result = await this.props.getVehicleList({
			search: input,
			filters,
			pagination: {
				page: 1,
				limit: 20
			}
		});

		if (result.isOk) {
			callback(null, {
				options: _.sortBy(
					result.payload.items.map((item) => ({
						label: item.state_number,
						value: item.uuid
					})),
					'label'
				),
				complete: false
			});
		} else {
			result.showErrors();
		}
	}

	async loadRoutes(input, callback) {
		if (!input) {
			callback(null, {
				options: [],
				complete: false
			});
			return;
		}

		const response = await this.props.getRoutes({
			search: input,
			filters: {
				withComponent: 'kiutr',
				withoutGeometry: true
			},
			pagination: {
				page: 1,
				limit: 20
			},
			response_data: [ 'items/uuid', 'items/number', 'items/title' ]
		});

		if (response.isOk) {
			const routes = _.map(response.payload.items, (route) => ({
				value: _.get(route, 'uuid'),
				label: `${_.get(route, 'number')} ${_.get(route, 'title')}`
			}));

			callback(null, {
				options: _.sortBy(routes, 'label'),
				complete: false
			});

			this.setState({
				routes
			});
		} else {
			response.showErrors();
		}
	}

	renderEventParameter(parameter) {
		switch (parameter.type) {
			case 'string':
				return (
					<Block key={parameter.key} title={parameter.name}>
						{this.textInput(`event_rule.parameters.${parameter.key}`)}
						<span className="input__style_state_msg">{parameter.description}</span>
					</Block>
				);
			case 'number':
				return (
					<Block key={parameter.key} title={parameter.name}>
						{this.textInput(`event_rule.parameters.${parameter.key}`, {
							type: 'number',
							min: 0,
							positive: true
						})}
						<span className="input__style_state_msg">{parameter.description}</span>
					</Block>
				);
			case 'time':
				return (
					<Block key={parameter.key} title={parameter.name}>
						{this.maskInput(`event_rule.parameters.${parameter.key}`, '99:99', {
							withTimeIcon: true
						})}
						<span className="input__style_state_msg">{parameter.description}</span>
					</Block>
				);
			case 'bool':
				return (
					<Block key={parameter.key} title=" ">
						{this.checkbox(`event_rule.parameters.${parameter.key}`, parameter.name)}
						<span className="input__style_state_msg">{parameter.description}</span>
					</Block>
				);
			case 'geozone':
				return (
					<Block key={parameter.key} title={parameter.name}>
						{this.selectAsync(`event_rule.parameters.${parameter.key}`, this.loadGeozones, {
							onChange: (e) => {
								this.setValue(`event_rule.parameters.${parameter.key}`, e.value)
							}
						})}
						<span className="input__style_state_msg">
							{parameter.description}
							{this.get(`parameters.${parameter.key}`) ? (
								[
									<a key="view" href="#" onClick={this.showGeozone.bind(this, this.get(`parameters.${parameter.key}`))}>
										посмотреть
									</a>,
									<br key="br" />,
									<Link key="edit" to={`/settings/kiutr/map?uuid=${this.get(`parameters.${parameter.key}`)}`}>
										редактировать
									</Link>
								]
							) : null}
						</span>
					</Block>
				);
			case 'contract':
				return (
					<Block key={parameter.key} title={parameter.name}>
						{this.selectAsync(`event_rule.parameters.${parameter.key}`, this.loadContracts.bind(this, parameter.key))}
						<span className="input__style_state_msg">{parameter.description}</span>
					</Block>
				);
			case 'work_types':
				return (
					<Block key={parameter.key} title={parameter.name}>
						{this.select(`event_rule.parameters.${parameter.key}`, this.state.work_types, {
							multi: true
						})}
						<span className="input__style_state_msg">{parameter.description}</span>
					</Block>
				);
			case 'road_parts':
				return (
					<Block key={parameter.key} title={parameter.name}>
						{this.select(`event_rule.parameters.${parameter.key}`, this.state.road_parts, {
							multi: true
						})}
						<span className="input__style_state_msg">{parameter.description}</span>
					</Block>
				);
			case 'routes':
				return (
					<Block key={parameter.key} title={parameter.name}>
						{this.get(`parameters.${parameter.key}`, []).map((routeUuid, index) => {
							return (
								<div key={routeUuid + index}>
									{_.get(_.find(this.state.loadedRoutes, { value: routeUuid }), 'label', '...')}
									<a href="#" onClick={this.removeParameterVehicle.bind(this, parameter.key, index)}>
										&times;
									</a>
								</div>
							);
						})}
						{this.selectAsync(`event_rule.parameters.route`, ::this.routesLoadDebounce)}
					</Block>
				);
			case 'vehicles':
				return (
					<Block key={parameter.key} title={parameter.name}>
						{this.get(`parameters.${parameter.key}`, []).map((vehicleUuid, index) => {
							return (
								<div key={vehicleUuid + index}>
									{_.get(_.find(this.state.loadedVehicles, { value: vehicleUuid }), 'label', '...')}
									<a href="#" onClick={this.removeParameterVehicle.bind(this, parameter.key, index)}>
										&times;
									</a>
								</div>
							);
						})}
						{this.selectAsync(`event_rule.parameters.vehicle`, ::this.vehiclesLoadDebounce)}
					</Block>
				);
			case 'select':
				return (
					<Block key={parameter.key} title={parameter.name}>
						{this.select(`event_rule.parameters.${parameter.key}`, JSON.parse(parameter.description))}
					</Block>
				);
			case 'dictionary':
				return (
					<Block key={parameter.key} title={parameter.name}>
						{this.select(`event_rule.parameters.${parameter.key}`, this.state[parameter.description])}
					</Block>
				);
		}

		return <div key={parameter.key}>Неизвестный тип: {parameter.type}</div>;
	}

	async loadContracts(code, input, callback) {
		if (!input) {
			input = this.get(`parameters.${code}`);
		}
		const result = await this.props.getContracts({
			filters: {
				withImplementer: this.get('units')
			},
			search: input,
			pagination: {
				page: 1,
				limit: 20
			}
		});

		if (result.isOk) {
			callback(null, {
				options: result.payload.items.map((item) => ({
					value: item.uuid,
					label: `${item.number} ${item.name}`
				})),
				complete: false
			});
		} else {
			result.showErrors();
		}
	}

	showGeozone(geozoneUuid, e) {
		e.preventDefault();

		const geozone = _.find(this.state.geozonesRaw, { uuid: geozoneUuid });
		if (!geozone) {
			return;
		}

		this.setState({
			geozoneModalActive: true,
			geozoneModalGeometry: geozone.geometry
		});
	}

	closeGeozone() {
		this.setState({
			geozoneModalActive: false,
			geozoneModalGeometry: null
		});
	}

	getComponents() {
		let result = {};
		let excludesValues = ['administration', 'operator','analytics', 'complaints', 'sbdd']
		_.each(systems, (label, value) => {
			if (!excludesValues.includes(value)){
				if (currentUser.can('com.rnis.auth.permission.component', 'read', value)) {
					result[value] = label;
				}
			}
		});

		return result;
	}
}
