import * as storage from "utils/storage";
import moment from "moment";
import {Response} from 'helpers/response';

export async function cached(key, callback, lifetime = 60, isResponse = false) {
    let inStorage = storage.get(key);
    if (inStorage && moment(inStorage.timestamp).isSameOrAfter(moment().subtract(lifetime, 'minutes'))) {
        if (isResponse) {
            const response = new Response(inStorage.value.__response);
            if (inStorage.value.__response.success) {
                return response;
            }
        } else {
            return inStorage.value;
        }
    }

    const value = await callback();

    try {
        storage.set(key, {
            timestamp: moment(),
            value,
        });
    } catch (e) {
    }

    return value;
}