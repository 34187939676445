import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import Loader from '../ui/loader';
import classNames from 'classnames';

import './global-loader.less';

@propTypes({
    style: PropTypes.object,
    block: PropTypes.bool
})

@defaultProps({
    block: true
})

export default class GlobalLoaderComponent extends Component {

    render() {
        const className = classNames('GlobalLoader', this.props.block ? 'blocked' : 'not-blocked');

        return (
            <div className={className} style={this.props.style}>
                <div>
                    <Loader color="red" size={64}/>
                </div>
            </div>
        );
    }

}