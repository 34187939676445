const Colors = {
  'red': '#FF5C55',
  'asphalt': '#454C54',
  'asphalt2': '#373d42',
  'blue': '#39a1db',
  'green': '#98c200',
  'purple': '#b070eb',
  'orange': '#fb9237',
  'yellow': '#ffb400',
  'gray': '#a1a4a6',
  'grayLight': '#dcdcdc',
  'white': '#fff',
  'steel': '#8a8e92'
};

const getAllColors = () => {
  const names = Object.keys(Colors);
  return names.concat(names.map(c => Colors[c]));
};

const getColorNames = () => {
  const out = {};
  for(let color of Object.keys(Colors))
    out[color] = color;

  return out;
};


export default {
  ...Colors,
  All: getAllColors(),
  AllNames: Object.keys(Colors),
  Names: getColorNames()
};