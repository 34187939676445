import React from 'react';
import classNames from 'classnames';
export const RecalculationAlert = ({ className, id, styles, onClick, notificationLevel, item, onClose, message }) => (
	<div className={className} id={id} style={styles} onClick={onClick}>
		<div
			className={classNames(
				'alert',
				'alert_level_' + notificationLevel,
				item.reminder ? 'alert_status_reminder' : null
			)}
		>
			<div className="alert__header" onClick={onClose}>
				<div className="alert__name">{_.get(item, 'data.title')}</div>
			</div>
			<div className="alert__body">{message}</div>
		</div>
	</div>
);
