import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import formats from "dictionaries/formats";
import moment from "moment";
import {getEcpSignLogs} from "store/reducers/routes/routes";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import GlobalLoaderComponent from "components/ui/global-loader";
import TableContainer from "components/ui/Table/Container/TableContainer";
import PageModalComponent from "components/ui/page-modal";

@propTypes({
    entityUuid: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
})

@defaultProps({
})

@connect(state => ({}), {getEcpSignLogs})

export default class EcpSignLog extends BaseTableWithEditorComponent {

    title = 'Лог подписания ЭЦП';

    actions = {
        sign: 'Подпись',
        unsign: 'Снятие подписи',
    };

    static contextTypes = {
        resizeModals: PropTypes.func
    };

    async componentWillUpdate(props, state) {
    }

    showEditorWithUuid() {
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Действие')
                .fromField('action')
                .withDrawer(item => _.get(this.actions, item.action)),

            new Column('Пользователь')
                .fromField('user_uuid')
                .withDrawer(item => this.state.related.get(item.user_uuid)),

            new Column('Дата/время')
                .fromField('created_at')
                .withDrawer(item => moment(item.created_at).format(formats.DATETIME)),
        ]);
    }

    async loadData(meta) {
        meta.filters.withEntity = this.props.entityUuid;
        return await this.props.getEcpSignLogs(meta);
    }

    async onDblClick(data) {
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const users = _.map(_.uniq(_.flatten(_.map(result, 'user_uuid'))), (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));

        const response = await this.props.getEntityNames(users);

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    render() {
        let table = this.renderTable();

        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        const loader = this.state.loading ? <GlobalLoaderComponent/> : null;

        return (
            <div>
                <PageModalComponent
                    header={{title: 'Лог подписания ЭЦП', buttons}}
                    className="b-modal-edit b-modal-route route-schedules-modal Schedules"
                    withFade={false}
                    onClose={this.props.onClose}
                >
                    {loader}
                    <TableContainer>
                        {table}
                    </TableContainer>
                </PageModalComponent>
            </div>
        );
    }
}