import {Map} from "immutable";
import {api} from "helpers/api";
import {connect} from "helpers/api/session";
import _ from 'lodash';

const initialState = new Map({
    isConnected: false,
    isWebsocketsActive: false,
});

const RPC_CONNECTED = 'RPC_CONNECTED';
const RPC_CONNECTION_LOST = 'RPC_CONNECTION_LOST';
const RPC_CONNECTION_ESTABLISHED = 'RPC_CONNECTION_ESTABLISHED';

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case RPC_CONNECTED:
            return state.set('isConnected', true);
        case RPC_CONNECTION_ESTABLISHED:
            return state.set('isWebsocketsActive', true);
        case RPC_CONNECTION_LOST:
            return state.set('isWebsocketsActive', false);
    }
    return state;
}

export const connectWsRpc = () => async (dispatch) => {
    await connect();

    dispatch({type: RPC_CONNECTED});
};

export const connectionLost = (callback) => async (dispatch) => {
    await api.wsrpc.connectionLost((reason) => {
        dispatch({
            type: RPC_CONNECTION_LOST
        });

        _.isFunction(callback) && callback(reason);
    });
};

export const connectionEstablished = (callback) => async (dispatch) => {
    await api.wsrpc.connectionEstablished(() => {
        dispatch({
            type: RPC_CONNECTION_ESTABLISHED
        });

        _.isFunction(callback) && callback();
    });
};

