import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from 'react-props-decorators';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as alerts from 'helpers/alerts';
import BaseEditorFormComponent from 'components/base/base-editor-form';
import BaseEditor from 'components/base/base-editor';
import Block from 'components/ui/form/block';
import Accordion from 'components/ui/accordion/accordion';
import AccordionItem from 'components/ui/accordion/accordion-item';
import {
	getSchedule,
	createSchedule,
	updateSchedule,
	getScheduleIntervals,
	postScheduleIntervals,
	updateScheduleIntervals
} from 'store/reducers/kiutr/schedules/schedules';
import { getDictionaryList } from 'store/reducers/dictionaries/dictionary';
import TableContainer from 'components/ui/Table/Container/TableContainer';
import moment from 'moment';
import formats from 'dictionaries/formats';
import { isCarriersLimits, isMunicipalType } from 'helpers/functions';
import { getRouteVariants } from 'store/reducers/routes/route_variants';
import { getRoute } from 'store/reducers/routes/route_editor';
import SelectFilter from '../../../../../ui/select-filter';
import { IntervalEditor } from './components/interval-editor';
import {
	getHours,
	getIntervalsBetweenTime,
	getLastTurnTime,
	getSelectedDaysCount,
	getSelectedMonthCount,
	isIntervalIntersectsOthers,
	isIntervalTimesNotValid
} from './utils';
import { SelectAllSlider } from './components/select-all-slider';
import './editor.less';
import { days, inititalScheduleFormValues, months } from './constants';

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	uuid: PropTypes.string
})
@connect(() => ({}), {
	getSchedule,
	createSchedule,
	updateSchedule,
	getScheduleIntervals,
	getRouteVariants,
	postScheduleIntervals,
	updateScheduleIntervals
})
export default class Editor extends BaseEditor {
	title = 'расписания';
	modelClass = 'App\\Model\\Schedule';
	withFade = false;
	getModalClassName() {
		return `b-modal-route route-schedule-editor-modal ${this.props.single ? 'route-schedule-editor-modal-single' : ''}`;
	}

	state = {
		intervals: {},
		currentScheduleType: 0,
		intervalType: 0
	};

	async loadData(uuid) {
		return await this.props.getSchedule(uuid);
	}

	async createItem(data) {
		if (data.is_interval) {
			if (!data.route_variant_forward || !data.route_variant_backward) {
				alerts.alert('Вариант движения не заполнен');
				return;
			}
		}

		const response = await this.props.createSchedule(data);
		if (response.isOk) {
			this.handlePostScheduleIntervals(response.payload.uuid, Object.values(this.state.intervals));
		}
		return response;
	}

	async updateItem(data) {
		if (data.is_interval) {
			if (!data.route_variant_forward || !data.route_variant_backward) {
				alerts.alert('Вариант движения не заполнен');
				return;
			}
		}
		const response = await this.props.updateSchedule(data);
		if (response.isOk) {
			this.handleUpdateScheduleIntervals(this.props.uuid, Object.values(this.state.intervals));
		}
		return response;
	}

	async componentDidMount() {
		if (this.props.isPageWithDetect && this.modalConfirmation) {
			document.addEventListener('click', this.handleClickOutside, true);
		}
		await this.loadRouteVariants(this.props.routeUuid);

		this.setState({
			uuid: this.props.uuid,
			item: null
		});

		if (this.props.uuid) {
			// if is editing
			this.setState({ isLoading: true });
			const response = await this.loadData(this.props.uuid);
			let intervals = this.state.intervals;
			let is_interval = false;
			if (response.isOk) {
				if (response.payload.is_interval) {
					// if interval schedule
					is_interval = true;
					intervals = await this.props.getScheduleIntervals(response.payload.uuid);
					intervals = intervals.payload.intervals.reduce((prev, cur) => {
						prev[`${cur.start_at.slice(0, 5)} - ${cur.end_at.slice(0, 5)}`] = {
							interval: cur.interval,
							end_at: cur.end_at.slice(0, 5),
							start_at: cur.start_at.slice(0, 5)
						};
						return prev;
					}, {});
				}
				this.setState({
					item: { ...response.payload, is_interval },
					isLoading: false,
					intervals,
					currentScheduleType: is_interval ? 1 : 0,
					intervalType: is_interval ? 1 : 0
				});
			} else {
				response.showErrors();
			}
		}

		if (this.props.isPageWithDetect && this.modalConfirmation) {
			document.addEventListener('click', this.handleClickOutside, true);
		}
	}

	async handlePostScheduleIntervals(scheduleUuid, intervals) {
		const response = await this.props.postScheduleIntervals(scheduleUuid, intervals);
		if (response.success) {
			console.log('🚀 ~ file: index.js ~ line 108 ~ Editor ~ handlePostScheduleIntervals ~ response', response);
		} else {
			response.showErrors();
		}
	}

	async handleUpdateScheduleIntervals(scheduleUuid, intervals) {
		const response = await this.props.updateScheduleIntervals(scheduleUuid, intervals);
		if (response.success) {
			console.log('🚀 ~ file: index.js ~ line 108 ~ Editor ~ handlePostScheduleIntervals ~ response', response);
		} else {
			response.showErrors();
		}
	}

	async loadRouteVariants(uuid) {
		const response = await this.props.getRouteVariants({
			filters: {
				withRoute: uuid
			}
		});

		if (response.isOk) {
			await this.setState({
				routeVariants: response.payload.items.map((variant) => ({
					label: variant.name,
					value: variant.uuid,
					forward_points: variant.forward_points,
					reverse_points: variant.reverse_points
				}))
			});
		} else {
			response.showErrors();
		}
	}

	handleSetIntervals = (intervals) => {
		this.setState({ intervals });
	};

	handleSetScheduleType = (scheduleType) => {
		this.setState({ currentScheduleType: scheduleType });
	};

	handleSetIntervalType = (type) => {
		this.setState({ intervalType: type });
	};

	componentWillUnmount() {
		if (this.props.isPageWithDetect && this.modalConfirmation) {
			document.removeEventListener('click', this.handleClickOutside);
		}
	}

	composeItem(data) {
		let item = _.clone(data);

		item.route_uuid = this.props.routeUuid;

		return item;
	}

	getForm(item, onSubmit) {
		if (_.isEmpty(item)) {
			item = inititalScheduleFormValues;
		}

		return (
			<EditorForm
				{...this.props}
				ref="form"
				mode={this.props.mode}
				onSubmit={onSubmit}
				onClose={::this.props.onClose}
				data={item}
				errors={this.state.errors}
				turnedEditingMode={this.turnedEditingMode}
				routeVariants={this.state.routeVariants}
				intervals={this.state.intervals}
				currentScheduleType={this.state.currentScheduleType}
				intervalType={this.state.intervalType}
				onSetIntervals={this.handleSetIntervals}
				onSetSheduleType={this.handleSetScheduleType}
				onSetIntervalType={this.handleSetIntervalType}
			/>
		);
	}
}

@propTypes({
	mode: PropTypes.oneOf([ 'edit', 'add' ]),
	data: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	errors: PropTypes.object,
	turnedEditingMode: PropTypes.func
})
@connect((state) => ({}), { getDictionaryList, getRoute }, null, { withRef: true })
class EditorForm extends BaseEditorFormComponent {
	state = {
		schedule: {},
		schedule_statuses: [],
		route: {},
		route_kinds: []
	};

	async loadRoute(uuid) {
		const response = await this.props.getRoute(uuid);

		if (response.isOk) {
			this.setState({
				route: response.payload
			});
		} else {
			response.showErrors();
		}
	}

	getData() {
		return this.state.schedule;
	}

	async componentDidMount() {
		await this.setState({
			schedule: this.props.data
		});

		if (isCarriersLimits()) {
			await this.loadRoute(this.state.schedule.route_uuid || window.location.pathname.split('/')[3]);
		}
		await this.loadDictionaries([ 'schedule_statuses', 'seasons', 'route_kinds' ]).then(() => {
			if (!this.get('schedule_status_uuid')) {
				this.setValue(
					'schedule.schedule_status_uuid',
					_.get(_.find(this.state.schedule_statuses, { label: 'Утверждено' }), 'value')
				);
			}
			if (
				isCarriersLimits() &&
				isMunicipalType(this.state.route_kinds, this.state.route.route_kind_uuid) &&
				this.props.mode === 'add'
			) {
				this.setValue(
					'schedule.schedule_status_uuid',
					_.get(_.find(this.state.schedule_statuses, { label: 'Проект' }), 'value')
				);
			}

			if (
				isCarriersLimits() &&
				isMunicipalType(this.state.route_kinds, this.state.route.route_kind_uuid) &&
				this.props.mode === 'edit'
			) {
				$('._save').hide();
				$('.top-menu_modal__separator').hide();
			}

			if (
				isCarriersLimits() &&
				isMunicipalType(this.state.route_kinds, this.state.route.route_kind_uuid) &&
				this.props.mode === 'edit' &&
				this.get('schedule_status_uuid') === _.get(_.find(this.state.schedule_statuses, { label: 'Проект' }), 'value')
			) {
				// если бесплатный перевозчик и редактирование расписание со статусом Проект
				$('._save').show();
				$('.top-menu_modal__separator').show();
			}
		});
	}

	handleCountDefaultIntervals = () => {
		const date = '10/10/20';
		alerts.prompt(
			'Уверены что хотите пересчитать под стандартное расписание? Все текущие расписание будут затерто!',
			'',
			() => {
				let intervals = {};
				let hours = getHours();
				if (
					this.state.schedule.start_time_first_backward &&
					this.state.schedule.start_time_last_backward &&
					this.state.schedule.start_time_first_forward &&
					this.state.schedule.start_time_last_forward &&
					!this.state.schedule.start_time_first_forward.includes('_') &&
					!this.state.schedule.start_time_last_backward.includes('_')
				) {
					hours = hours.filter((hour) => {
						if (
							moment(date + ' ' + hour).isAfter(moment(date + ' ' + this.state.schedule.start_time_first_forward)) &&
							moment(date + ' ' + hour).isBefore(moment(date + ' ' + this.state.schedule.start_time_last_backward))
						) {
							return true;
						}
						return false;
					});
					const lastTurnTime = getLastTurnTime(this.props.routeVariants, this.state.schedule.start_time_last_backward);
					hours = [ this.state.schedule.start_time_first_forward, ...hours, lastTurnTime ];
				}

				getIntervalsBetweenTime(hours, intervals, this.state.schedule.interval.replace('_', ''));
				this.props.onSetIntervals(intervals);
			},
			'Пересчитать'
		);
	};

	onChangeInput(field, { target: { value, type } }) {
		super.onChangeInput(field, { target: { value, type } });
		this.props.turnedEditingMode();
	}

	get(path, defaultValue = null) {
		return _.get(this.state.schedule, path, defaultValue);
	}

	handleIntervalLink() {
		this.props.router.push(`${this.props.router.getCurrentLocation().pathname}/interval/${this.state.schedule.uuid}`);
	}

	handleChangeScheduleType = (type) => {
		const schedule = this.state.schedule;
		schedule.is_interval = type === 1 ? true : false;
		this.setState({ schedule });
		this.props.onSetSheduleType(type);
	};

	handleChangeIntervalType = (type) => {
		this.props.onSetIntervalType(type);
	};

	logicViewScheduleIntervals() {
		const correctUrl = location.pathname.includes('kiutr/routes');
		if (window.RNIS_SETTINGS.shedule_modalFooter && correctUrl) {
			return (
				<div className="b-modal__footer">
					<a
						href="javascript:void(0)"
						onClick={::this.handleIntervalLink}
						className="b-button b-button_red b-button_size_md b-button_routes"
					>
						Интервалы движения
					</a>
					<div className="b-modal__checkbox">
						{this.checkbox('schedule.is_interval', 'Контроль интервалов движения', {
							canChange: this.state.schedule.interval_uuid ? true : false
						})}
					</div>
				</div>
			);
		}
		return null;
	}

	handleChangeScheduleTime = (type, value) => {
		const newSchedule = { ...this.state.schedule };
		newSchedule[type] = value;
		this.setState({ schedule: newSchedule });
	};

	handleAddInterval = () => {
		const intervals = this.props.intervals;
		const intervalKey = `${this.state.schedule.interval_start} - ${this.state.schedule.interval_end}`;
		if (!this.state.schedule.interval) {
			alerts.alert(`Интервал не задан`);
			return;
		}
		if (Number(this.state.schedule.interval) > 60) {
			alerts.alert(`Интервал слишком большой`);
			return;
		}
		if (isIntervalTimesNotValid(this.state.schedule.interval_start, this.state.schedule.interval_end)) {
			alerts.alert(`Время задано не верно`);
			return;
		}
		if (this.state.schedule.interval_start === this.state.schedule.interval_end) {
			alerts.alert(`Интервал отсутствует`);
			return;
		}
		if (intervals[intervalKey]) {
			alerts.alert(`${intervalKey} интервал уже есть`);
			return;
		}
		if (
			isIntervalIntersectsOthers(
				this.props.intervals,
				this.state.schedule.interval_start,
				this.state.schedule.interval_end
			)
		) {
			alerts.alert(`${intervalKey} пересекает другие интервалы`);
			return;
		}
		const interval = this.state.schedule.interval.includes('_')
			? this.state.schedule.interval[0]
			: this.state.schedule.interval;
		intervals[intervalKey] = {
			start_at: this.state.schedule.interval_start,
			end_at: this.state.schedule.interval_end,
			interval
		};
		this.props.onSetIntervals(intervals);
	};

	handleRemoveInterval = (intervalKey) => {
		const intervals = this.props.intervals;
		delete intervals[intervalKey];
		this.props.onSetIntervals(intervals);
	};

	handleRemoveAllIntervals = () => {
		alerts.prompt(
			'Уверены что хотите удалить все интервалы?',
			'',
			() => {
				this.props.onSetIntervals({});
			},
			'Удалить'
		);
	};

	handleSelectAllMonths = (checked) => {
		const schedule = this.state.schedule;
		Object.keys(schedule).forEach((key) => {
			if (months.includes(key)) {
				schedule[key] = checked;
			}
		});
		this.setState({ schedule });
	};

	handleSelectAllDays = (checked) => {
		const schedule = this.state.schedule;
		Object.keys(schedule).forEach((key) => {
			if (days.includes(key)) {
				schedule[key] = checked;
			}
		});
		this.setState({ schedule });
	};

	render() {
		const {
			start_time_first_backward,
			start_time_last_backward,
			start_time_first_forward,
			start_time_last_forward
		} = this.state.schedule;

		let selectedMonthsCount;
		let isDeselectAllMonths;
		if (this.state.schedule) {
			selectedMonthsCount = getSelectedMonthCount(this.state.schedule);
			isDeselectAllMonths = selectedMonthsCount === 0;
		}

		let selectedDaysCount;
		let isDeselectAllDays;
		if (this.state.schedule) {
			selectedDaysCount = getSelectedDaysCount(this.state.schedule);
			isDeselectAllDays = selectedDaysCount === 0;
		}
		const sortedIntervals = Object.values(this.props.intervals).sort(
			(a, b) => Number(a.start_at.split(':')[0]) - Number(b.start_at.split(':')[0])
		);
		const isInterval = this.props.currentScheduleType === 1;

		return (
			<div className="Shedule__editorForm">
				{this.logicViewScheduleIntervals()}

				<Accordion>
					<SelectFilter
						items={[ 'Строгое расписание', 'Интервальное расписание' ]}
						classNames={[ 'Shedule__editorForm__type-selector__item', 'Shedule__editorForm__type-selector__item' ]}
						className="Shedule__editorForm__type-selector"
						currentItem={this.props.currentScheduleType}
						onChange={this.handleChangeScheduleType}
					/>
					<AccordionItem opened={true} title="Основная информация">
						<Block size="xl" title="Наименование">
							{this.textInput('schedule.name')}
						</Block>
						<Block size="xl">
							{this.checkbox('schedule.is_null_run_separated', 'Нулевые рейсы отображаются отдельным столбцом')}
						</Block>
						<Block title="Дата начала">{this.datepicker('schedule.date_from')}</Block>
						<Block title="Дата конца">{this.datepicker('schedule.date_to')}</Block>
						{!isInterval && (
							<Block title="Сезоны">
								{this.select('schedule.seasons', this.state.seasons, {
									multi: true
								})}
							</Block>
						)}
						<Block title="Статус">
							{this.select('schedule.schedule_status_uuid', this.state.schedule_statuses, {
								disabled:
									isCarriersLimits() && isMunicipalType(this.state.route_kinds, this.state.route.route_kind_uuid)
							})}
						</Block>
					</AccordionItem>
					{isInterval && (
						<AccordionItem opened={true} title="Месяцы">
							<Block size="xl">
								<SelectAllSlider
									onSelectDeselectAll={this.handleSelectAllMonths}
									isDeselectAll={isDeselectAllMonths}
									selectedCount={selectedMonthsCount}
									totalCount={12}
								/>
								<TableContainer>
									<div className="Table">
										<table className="b-table">
											<thead>
												<tr className="b-table__header">
													<th className="b-table__mon align-center">Янв</th>
													<th className="b-table__tue align-center">Фев</th>
													<th className="b-table__wed align-center">Март</th>
													<th className="b-table__thu align-center">Апр</th>
													<th className="b-table__fri align-center">Май</th>
													<th className="b-table__sat align-center">Июнь</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className="align-center select-checkbox">{this.checkbox('schedule.is_january')}</td>
													<td className="align-center select-checkbox">{this.checkbox('schedule.is_february')}</td>
													<td className="align-center select-checkbox">{this.checkbox('schedule.is_march')}</td>
													<td className="align-center select-checkbox">{this.checkbox('schedule.is_april')}</td>
													<td className="align-center select-checkbox">{this.checkbox('schedule.is_may')}</td>
													<td className="align-center select-checkbox">{this.checkbox('schedule.is_june')}</td>
												</tr>
											</tbody>
											<thead>
												<tr className="b-table__header">
													<th className="b-table__sun align-center">Июль</th>
													<th className="b-table__mon align-center">Авг</th>
													<th className="b-table__tue align-center">Сент</th>
													<th className="b-table__wed align-center">Окт</th>
													<th className="b-table__thu align-center">Ноябрь</th>
													<th className="b-table__fri align-center">Дек</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className="align-center select-checkbox">{this.checkbox('schedule.is_july')}</td>
													<td className="align-center select-checkbox">{this.checkbox('schedule.is_august')}</td>
													<td className="align-center select-checkbox">{this.checkbox('schedule.is_september')}</td>
													<td className="align-center select-checkbox">{this.checkbox('schedule.is_october')}</td>
													<td className="align-center select-checkbox">{this.checkbox('schedule.is_november')}</td>
													<td className="align-center select-checkbox">{this.checkbox('schedule.is_december')}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</TableContainer>
							</Block>
						</AccordionItem>
					)}

					<AccordionItem opened={true} title="Дни недели">
						<Block size="xl">
							<SelectAllSlider
								onSelectDeselectAll={this.handleSelectAllDays}
								isDeselectAll={isDeselectAllDays}
								selectedCount={selectedDaysCount}
								totalCount={8}
							/>
							<TableContainer>
								<div className="Table">
									<table className="b-table">
										<thead>
											<tr className="b-table__header">
												<th className="b-table__mon align-center">Пн</th>
												<th className="b-table__tue align-center">Вт</th>
												<th className="b-table__wed align-center">Ср</th>
												<th className="b-table__thu align-center">Чт</th>
												<th className="b-table__fri align-center">Пт</th>
												<th className="b-table__sat align-center">Сб</th>
												<th className="b-table__sun align-center">Вс</th>
												<th className="b-table__holidays align-center">Пр. дни</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="align-center select-checkbox">{this.checkbox('schedule.monday')}</td>
												<td className="align-center select-checkbox">{this.checkbox('schedule.tuesday')}</td>
												<td className="align-center select-checkbox">{this.checkbox('schedule.wednesday')}</td>
												<td className="align-center select-checkbox">{this.checkbox('schedule.thursday')}</td>
												<td className="align-center select-checkbox">{this.checkbox('schedule.friday')}</td>
												<td className="align-center select-checkbox">{this.checkbox('schedule.saturday')}</td>
												<td className="align-center select-checkbox">{this.checkbox('schedule.sunday')}</td>
												<td className="align-center select-checkbox">{this.checkbox('schedule.holiday')}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</TableContainer>
						</Block>
					</AccordionItem>
					{isInterval && (
						<IntervalEditor
							start_time_first_backward={start_time_first_backward}
							start_time_last_forward={start_time_last_forward}
							start_time_first_forward={start_time_first_forward}
							start_time_last_backward={start_time_last_backward}
							onChangeScheduleTime={this.handleChangeScheduleTime}
							onChangeIntervalType={this.handleChangeIntervalType}
							intervalType={this.props.intervalType}
							select={this.select.bind(this)}
							maskInput={this.maskInput.bind(this)}
							intervals={sortedIntervals}
							onAddInterval={this.handleAddInterval}
							onRemoveInterval={this.handleRemoveInterval}
							isIntervalTypeDefault={this.props.intervalType === 0 && this.props.currentScheduleType === 1}
							routeVariants={this.props.routeVariants}
							interval={this.state.schedule.interval}
							interval_start={this.state.schedule.interval_start}
							interval_end={this.state.schedule.interval_end}
							onCountDefaultIntervals={this.handleCountDefaultIntervals}
							onDeleteIntervals={this.handleRemoveAllIntervals}
						/>
					)}
				</Accordion>
			</div>
		);
	}
}
