import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import {getUnits} from "store/reducers/organizational_units/organizational_units";
import MapFilterBase from "components/modules/maps/Filter/MapFilterBase";
import {getRoutes} from "store/reducers/routes/routes";

@propTypes({
    component: PropTypes.string.isRequired,
    units: PropTypes.array,
})

@connect(state => ({}), {
    getRoutes,
})

export default class MapFilterRoute extends MapFilterBase {

    tabs = [
        'Маршруты',
    ];
    icon = 'route';
    contextKey = 'map.filters.base.route';
    contextDefault = 'Маршруты';

    componentWillUpdate(props, state) {
        if (!_.isEqual(props.units, state.units) || !_.isEqual(props.owners, state.owners)) {
            this.setState({
                owners: _.cloneDeep(props.owners),
                units: _.cloneDeep(props.units),
            });
            this.reloadFull();
        }
    }

    async loadData(tab = null, search = null) {
        let filters = {
            withComponent: this.props.component,
            withoutGeometry: true,
            withActive: true,
            withStatus: ["1abd2f98-7845-11e7-be3f-3a4e0357cc4a"] // статус "Утвержден"
        };

        if (this.props.units.length > 0) {
            filters.withUnits = this.props.units;
        }

        if (this.props.owners.length > 0) {
            filters.withCarriers = this.props.owners;
        }

        const response = await this.props.getRoutes({
            search,
            pagination: {
                page: 1,
                limit: this.limit,
            },
            filters,
        });
        if (response.isOk) {
            return _.map(response.payload.items, routeRegistry => ({
                value: _.get(routeRegistry, 'uuid'),
                label: `${_.get(routeRegistry, 'number')} ${_.get(routeRegistry, 'title')}`,
            }));
        } else {
            response.showErrors();
            return [];
        }
    }

}