import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import DashboardTable from 'components/modules/analytics/base-components/DashboardTable';
import './style.less';

@propTypes({

})

@defaultProps({

})

export default class VehicleDataDateWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [
                {entity: 'Всего ТС', ruad1: 1, ruad2: 2, ruad3: 3, ruad4: 4, ruad5: 5},
                {entity: 'Каток дорожный', ruad1: 5, ruad2: 1, ruad3: 0, ruad4: 8, ruad5: 10},
                {entity: 'КДС', ruad1: 7, ruad2: 12, ruad3: 3, ruad4: 6, ruad5: 5},
                {entity: 'Самосвал', ruad1: 1, ruad2: 2, ruad3: 5, ruad4: 8, ruad5: 2}
            ]
        };

        this.metadata = {
            0: {field: 'entity', name: '', sort: true},
            1: {field: 'ruad1', name: 'РУАД 1', sort: true},
            2: {field: 'ruad2', name: 'РУАД 2', sort: true},
            3: {field: 'ruad3', name: 'РУАД 3', sort: true},
            4: {field: 'ruad4', name: 'РУАД 4', sort: true},
            5: {field: 'ruad5', name: 'РУАД 5', sort: true}
        };
    }

    render() {
        return (
            <div className="vehicle-data-date-widget">
                <DashboardTable data={this.state.data} metadata={this.metadata}/>
            </div>
        );
    }
}