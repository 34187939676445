import React from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import './index.less';
import classNames from 'classnames';

import TableContainer from "components/ui/Table/Container/TableContainer";
import {getStopPoints} from "store/reducers/routes/routes";
import {api, CycleFetch} from "helpers/api";
import * as alerts from "helpers/alerts";
import ContextTooltip from "components/ui/context-tooltip";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getTask, getTaskViolations, updateTask} from "store/reducers/commdept/tasks";
import {createTaskTemplate, getTaskTemplate} from "store/reducers/commdept/task_templates";
import KursTaskMapEditorMap from "components/modules/commdept/tasks/KursTaskMapEditor/KursTaskMapEditorMap/index";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import moment from "moment";
import formats from "dictionaries/formats";
import {geocode, geoSearch} from "store/reducers/geo/geocode";
import debounce from 'throttle-debounce/debounce';
import Sortable from "react-sortablejs";
import {getRoadPart, getRoadParts, getRoadPartWorkTypes, getRoadRepairPart} from "store/reducers/commdept/road_parts";
import {getStopPoint} from "store/reducers/geo/stop-points";
import FilterHeader from "components/ui/filter-header";
import Button from "components/ui/button";
import Checkbox from "components/ui/form/checkbox";
import {listObjectVisits} from "store/reducers/commdept/object_visits";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import KursTaskMapHistory from "components/modules/commdept/tasks/KursTaskMapHistory/index";
import {State} from "components/ui/state";
import Input from "components/ui/form/input";
import {getDocument} from "store/reducers/dictionaries/editor";
import {getVehicleMechanisms} from "store/reducers/commdept/vehicle_mechanisms";
import Settings from 'settings';
import TaskItem from "components/modules/commdept/tasks/_elements/task_item";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ShowDeleted from "components/ui/show-deleted";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getTask, updateTask, createTaskTemplate})

export default class KursTaskMapEditor extends BaseEditor {

    modalClassName = 'b-modal-route-edit';

    getFullTitle() {
        return 'Построение рейса';
    }

    async loadData(uuid) {
        return await this.props.getTask(uuid);
    }

    async createItem(data) {
    }

    async updateItem(data) {
        data.is_only_map_allowed = true;

        return await this.props.updateTask(data);
    }

    async componentWillUpdate(props, state) {
        const propsUuid = (props.uuid === 'create') ? null : props.uuid;
        if (state.uuid !== propsUuid) {
            this.setState({
                uuid: propsUuid,
                item: null,
                mode: propsUuid ? 'edit' : 'add',
                isLoading: !!propsUuid
            });
            this.init();
            if (propsUuid) {
                const response = await this.loadData(propsUuid);
                if (response.isOk) {
                    this.setState({
                        item: response.payload,
                        isLoading: false,
                    });
                } else {
                    response.showErrors();
                }
            }
        }
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={this.props.onClose}
                data={this.prepareItem(item)}
                errors={this.state.errors}
                onLoad={::this.forceUpdate}
                confirmModeActive={this.state.confirmModeActive}
                copyModeActive={this.state.copyModeActive}
                mapDisplayParts={this.state.mapDisplayParts}
            />
        );
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();

        const response = await this.updateItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            this.setState({
                item: response.payload,
            });
        } else {
            this.setState({
                errors: response.validationErrors
            });
            if (response.validationErrors) {
                alerts.error('Изменения не сохранены<br/>Проверьте заполнение полей');
            }
            response.showErrors();
        }
    }

    onCreate() {
        this.props.router.push(`/commdept/tasks/create?template_uuid=${this.props.location.query.template_uuid}`);
    }

    getButtons() {
        return [
            <div key="tooltip" className="b-modal__footer-txt">Вы хотите сохранить все изменения?</div>,
            <a key="cancel" href="javascript:void(0)"
               className="b-button b-button_size_md b-button_white b-button_shadow_gray b-button_cancel"
               onClick={::this.closeMap}>Отменить</a>,
            <a key="save" href="javascript:void(0)" className="b-button b-button_red b-button_size_md b-button_save"
               onClick={(this.props.mode === 'edit') ? ::this.onEdit : ::this.onCreate}>Сохранить</a>
        ];
    }

    prepareItem(item) {
        item.items = _.map(_.get(item, 'items', []), (_item) => {
            if (!_item.movement_type) {
                _item.movement_type = 'work';
            }
            return _item;
        });

        return item;
    }

    async recalcRequest() {
        if (!this.refs.form) return;

        alerts.prompt('Внимание. Пересчет может занять некоторое время, продолжить?', '', async () => {
            const state = this.refs.form.getWrappedInstance().getData();
            if (state.items_fact) {
                state.items_fact = _.map(state.items_fact || [], (item) => {
                    item.is_parsed = false;
                    item.is_confirmed = null;
                    item.is_confirmed_manual = null;
                    item.comment = null;
                    item.fact_date_from = null;
                    item.fact_date_to = null;
                    item.fact_time = null;
                    item.fact_count = null;
                    item.percent = null;

                    return item;
                });
            }
            state.is_parsed = false;
            this.clearErrors();
            this.startSave();

            const response = await this.updateItem(this.composeItem(state));

            this.endSave();
            if (response.isOk) {
                //window.location.reload();
            } else {
                this.setState({
                    errors: response.validationErrors
                });
                response.showErrors();
            }
        }, 'Продолжить');
    }

    getRecalcIcon() {
        if (this.refs.form && _.filter(_.get(this.refs.form.getWrappedInstance().getData(), 'items_fact', []), (item) => {
            return !item.is_parsed;
        }).length > 0) {
            return 'b-icon-link_icon_refresh_rotate';
        }
        return 'b-icon-link_icon_done';
    }

    closeButton() {
        return (
            <ContextTooltip key="base-editor.close" code="base-editor.close" default="Закрыть">
                <ModalTopMenuButton
                    className="_close"
                    onClick={(this.state.mode === 'add') ? ::this.onClose : ::this.closeMap}
                />
            </ContextTooltip>
        );
    }

    closeMap() {
        let state = this.refs.form.getWrappedInstance().getData();
        state.items = _.map(state.items, (i) => {
            delete i.hidden;
            return i;
        });
        state.items_fact = _.map(state.items_fact, (i) => {
            delete i.hidden;
            return i;
        });
        if (!_.isEqual(state, this.state.item)) {
            alerts.prompt('Все несохраненные изменения будут утеряны!', '', () => {
                this.props.onClose();
            }, 'Продолжить');
        } else {
            this.props.onClose();
        }
    }

    onClose() {
        this.props.router.push('/commdept/task_templates');
    }

    toggleMapDisplayParts() {
        this.setState({
            mapDisplayParts: !this.state.mapDisplayParts,
        });
    }

    async toggleShowAllParts() {
        await this.setState({
            showAllParts: !this.state.showAllParts,
        });

        if (this.state.showAllParts) {
            // hide all
            this.refs.form.getWrappedInstance().hideAll();
        } else {
            // show all
            this.refs.form.getWrappedInstance().showAll();
        }
    }

    renderHeaderBtns(mode) {
        if (this.state.mode === 'add') {
            return [];
        }

        return ([
            <ContextTooltip key="kurs-task.eye" code="kurs-task.eye"
                            default="Отобразить всё/Скрыть всё">
                <ModalTopMenuListItem
                    className={`b-icon-link_icon_eye ${this.state.showAllParts ? 'active' : ''}`}
                    onClick={::this.toggleShowAllParts}
                />
            </ContextTooltip>,
            <ContextTooltip key="kurs-task.display-parts" code="kurs-task.display-parts"
                            default="Частичная закраска">
                <ShowDeleted
                    active={this.state.mapDisplayParts}
                    onChange={::this.toggleMapDisplayParts}
                />
            </ContextTooltip>,
            <ContextTooltip key="kurs-task.recalc" code="kurs-task.recalc"
                            default={(this.getRecalcIcon() === 'b-icon-link_icon_refresh_rotate') ? 'Идет пересчет' : 'Запустить пересчет'}>
                <ModalTopMenuListItem
                    className={this.getRecalcIcon()}
                    onClick={::this.recalcRequest}
                />
            </ContextTooltip>,
            (this.refs.form && this.refs.form.getWrappedInstance().isEditable('plan')) ? (
                <ContextTooltip key="kurs.tasks.load-template" code="kurs.tasks.load-template"
                                default="Загрузить из шаблона">
                    <ModalTopMenuListItem
                        className="b-icon-link_icon_load-pattern"
                        onClick={::this.loadTemplate}
                    />
                </ContextTooltip>
            ) : null,
            (this.refs.form && this.refs.form.getWrappedInstance().isEditable('fact_confirm')) ? (
                <ContextTooltip key="kurs.tasks.confirm" code="kurs.tasks.confirm"
                                default="Включить/выключить режим подтверждения">
                    <ModalTopMenuListItem
                        className={this.state.confirmModeActive ? 'b-icon-link_icon_confirm' : 'b-icon-link_icon_check'}
                        onClick={::this.toggleConfirm}
                    />
                </ContextTooltip>
            ) : null,
            (!this.state.copyModeActive) ? (
                <ContextTooltip key="kurs.tasks.copy-activate" code="kurs.tasks.copy-activate"
                                default="Режим копирования">
                    <ModalTopMenuListItem
                        className="b-icon-link_icon_clone"
                        onClick={::this.activateCopyMode}
                    />
                </ContextTooltip>
            ) : null,
            (this.state.copyModeActive) ? (
                <ContextTooltip key="kurs.tasks.copy-confirm" code="kurs.tasks.copy-confirm"
                                default="Копировать">
                    <ModalTopMenuListItem
                        className="b-icon-link_icon_clone"
                        onClick={::this.confirmCopy}
                    />
                </ContextTooltip>
            ) : null,
            <ContextTooltip key="kurs.tasks.create-template" code="kurs.tasks.create-template"
                            default="Сохранить в шаблон">
                <ModalTopMenuListItem
                    className="b-icon-link_icon_add-pattern"
                    onClick={::this.saveAsTemplate}
                />
            </ContextTooltip>,
            /*<ContextTooltip key="kurs.tasks.fill-idle" code="kurs.tasks.fill-idle"
                            default="Заполнить холостой ход">
                <ModalTopMenuListItem
                    className="b-icon-link_icon_book"
                    onClick={::this.fillIdle}
                />
            </ContextTooltip>,*/
        ]);
    }

    activateCopyMode() {
        this.setState({
            copyModeActive: true,
        });
    }

    confirmCopy() {
        this.setState({
            copyModeActive: false,
        });

        this.refs.form.getWrappedInstance().confirmCopy();
    }

    toggleConfirm() {
        this.setState({
            confirmModeActive: !this.state.confirmModeActive,
        });
    }

    saveButton(onClick) {
        return null;
    }

    loadTemplate() {
        this.props.router.push('/commdept/task_templates');
    }

    async saveAsTemplate() {
        if (!this.refs.form) return;

        const task = _.cloneDeep(this.refs.form.getWrappedInstance().getData());
        delete task.uuid;

        alerts.ask('Введите наименование шаблона:', async (name) => {
            this.startSave();
            const response = await this.props.createTaskTemplate({
                name,
                task,
            });
            this.endSave();

            if (response.isOk) {
                alerts.success('Шаблон сохранен');
            } else {
                response.showErrors();
            }
        });
    }

    fillIdle() {
        this.setState({loading: true});
        this.refs.form.getWrappedInstance().fillIdle();
        this.setState({loading: false});
    }

    gotoForm() {
        this.props.router.push(`/commdept/tasks/${this.props.params.uuid}`);
    }

    printButton() {
        return null;
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getDictionaryList,
    getDocument,
    geoSearch,
    geocode,
    getRoadParts,
    getStopPoints,
    getRoadPart,
    getRoadRepairPart,
    getStopPoint,
    listObjectVisits,
    getVehicleList,
    getTaskViolations,
    getRoadPartWorkTypes,
    getVehicleMechanisms,
    getTaskTemplate,
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        task: {},
        object_visits: [],
        vehicles: {},
        work_types: [],
        measures: [],
        kurs_task_statuses: [],
        kurs_violation_types: [],
        kurs_mechanism_uses: [],
        vehicle_marks: [],
        mapActive: null,
        violations: [],
        road_part_work_types: {},
        vehicleWorkTypes: null,
        expandedBlocks: [],
    };

    _cycleFetch = null;

    startLiveReload() {
        if (!this.props.data.uuid) {
            return;
        }

        this._cycleFetch = new CycleFetch(() => {
            return this.reloadTask();
        }, () => {
        }, 60000);
        this._cycleFetch.run();
    }

    stopLiveReload() {
        if (this._cycleFetch) {
            this._cycleFetch.stop();
            delete this['_cycleFetch'];
        }
    }

    async reloadTask() {
        const response = await this.props.getTask(this.props.data.uuid);

        if (response.isOk) {
            let task = response.payload;
            _.each([
                'items',
                'items_fact',
            ], (field) => {
                if (task[field]) {
                    task[field] = _.map(task[field], (item, index) => {
                        item.hidden = _.get(this.state.task, field + '.' + index + '.hidden', false);
                        return item;
                    });
                }
            });
            await this.setState({
                task,
            });
        }
    }

    componentWillUnmount() {
        this.stopLiveReload();
    }

    addressSearchDebounce = debounce(500, ::this.searchAddress);
    addressReverseGeocodeDebounce = debounce(500, ::this.reverseGeocode);

    getData() {
        return this.state.task;
    }

    async componentDidMount() {
        let task = _.cloneDeep(this.props.data);
        if (this.props.location.query.template_uuid) {
            task = await this.loadTemplate(this.props.location.query.template_uuid);
        }
        await this.setState({
            task,
        });

        this.startLiveReload();

        await this.loadDictionaries([
            'kurs_task_statuses',
        ]);
        await this.loadDictionaries([
            //'work_types',
            'measures',
            'kurs_violation_types',
            'vehicle_marks',
            'kurs_mechanism_bindings',
            'kurs_mechanism_types',
            'kurs_mechanism_uses',
        ], 'commdept', true);

        this.loadVehicleWorkTypes();
        this.preloadRoadPartWorkTypes();

        this.loadObjectVisits();
        this.loadTaskViolations();
        this.props.onLoad();
    }

    async loadTemplate(templateUuid) {
        const response = await this.props.getTaskTemplate(templateUuid);

        if (response.isOk) {
            const taskTemplate = response.payload.task;
            let task = {};
            task.unit_uuid = taskTemplate.unit_uuid;
            task.items = taskTemplate.items;

            return task;
        } else {
            response.showErrors();
        }
    }

    async loadVehicleWorkTypes() {
        const vehicleUuids = _.map(this.get('resources') || [], 'base_vehicle_uuid');
        if (vehicleUuids.length === 0) {
            this.setState({
                vehicleWorkTypes: null,
            });
            return;
        }

        const response = await this.props.getVehicleMechanisms({
            filters: {
                withVehicle: vehicleUuids,
                onlyActive: true,
            },
        });
        if (response.isOk) {
            const mechanismUuids = _.map(response.payload.items, 'mechanism_model_uuid');
            if (mechanismUuids.length === 0) {
                this.setState({
                    vehicleWorkTypes: null,
                });
                return;
            }

            const mechanismBindings = _.map(_.filter(this.state.kurs_mechanism_bindings, (binding) => {
                return _.indexOf(mechanismUuids, binding.document.uuid) !== -1;
            }), 'document');

            const workTypeUuids = _.uniq(_.flatten(_.map(_.filter(this.state.kurs_mechanism_uses, (mechanismUse) => {
                return _.filter(mechanismBindings, {
                    mechanism_type_uuid: mechanismUse.document.mechanism_type_uuid,
                }).length > 0;
            }), (item) => {
                return JSON.parse(_.get(item, 'document.work_types') || '[]');
            })));
            this.setState({
                vehicleWorkTypes: workTypeUuids,
            });
        } else {
            response.showErrors();
        }
    }

    async preloadRoadPartWorkTypes() {
        _.each(_.uniq(_.filter(_.map(this.get('items', []), (item) => {
            return _.get(item, 'geometry.0.item_uuid');
        }))), (uuid) => {
            this.loadRoadPartWorkTypes(uuid);
        });
    }

    async loadRoadPartWorkTypes(uuid) {
        if (!uuid) {
            return;
        }

        const response = await this.props.getRoadPartWorkTypes(uuid, this.get('date'));

        if (response.isOk) {
            let road_part_work_types = this.state.road_part_work_types;
            road_part_work_types[uuid] = response.payload.items;
            this.setState({
                road_part_work_types,
            });
        } else {
            response.showErrors();
        }
    }

    async loadObjectVisits() {
        const response = await this.props.listObjectVisits({
            filters: {
                withTask: this.state.task.uuid,
            },
        });

        if (response.isOk) {
            await this.setState({
                object_visits: response.payload.items,
            });
            this.loadVehicles();
        } else {
            response.showErrors();
        }
    }

    async loadTaskViolations() {
        const response = await this.props.getTaskViolations({
            filters: {
                withTask: this.state.task.uuid,
            },
        });

        if (response.isOk) {
            await this.setState({
                violations: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadVehicles() {
        const uuids = _.uniq(_.map(this.state.object_visits, 'vehicle_uuid'));
        if (uuids.length === 0) {
            return;
        }

        const response = await this.props.getVehicleList({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            this.setState({
                vehicles: _.keyBy(response.payload.items, 'uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.task, path, defaultValue);
    }

    addItem(index, field) {
        let task = this.state.task;
        task[field] = task[field] || [];

        const previous = task[field][index];
        const next = task[field][index + 1];
        let geometry = [];
        if (previous) {
            const prevCoords = this.getLastCoordinates(previous);

            if (prevCoords) {
                geometry.push({
                    is_address: true,
                    latitude: _.round(prevCoords[0], 6),
                    longitude: _.round(prevCoords[1], 6),
                });
            }
        }
        if (next) {
            const nextCoords = this.getFirstCoordinates(next);

            if (nextCoords) {
                geometry.push({
                    is_address: true,
                    latitude: _.round(nextCoords[0], 6),
                    longitude: _.round(nextCoords[1], 6),
                });
            }
        }

        task[field].splice(index + 1, 0, {
            movement_type: 'idle',
            geometry_type: 'other',
            geometry,
            date_from: '00:00',
            date_to: '23:59',
            time: '23:59',
        });

        this.setState({task});
    }

    deleteItem(index, field, e) {
        e && e.preventDefault();

        let task = this.state.task;
        task[field].splice(index, 1);
        this.setState({task});
    }

    toggleVisible(index, field, e) {
        e && e.preventDefault();

        let task = this.state.task;
        task[field][index].hidden = !task[field][index].hidden;
        this.setState({task});
    }

    isEditable(type) {
        if (this.props.mode === 'add') {
            return false;
        }

        const status = _.get(_.find(this.state.kurs_task_statuses, {value: _.get(this.props.data, 'status_uuid')}), 'label');

        if ((type === 'fact_confirm') && (_.indexOf([
            'В работе',
            'На рассмотрении',
            'Закрыт'
        ], status) !== -1)) {
            return true;
        }

        if (status === 'Закрыт') {
            return false;
        }

        if (type === 'plan') {
            return !status || (status === 'Черновик') || (status === 'Открыт');
        } else if (type === 'fact') {
            return _.indexOf([
                'В работе',
                'На рассмотрении',
            ], status) !== -1;
        }

        return false;
    }

    isEditableBySkpdi(item) {
        return !(item.external_id && (Settings.get('kurs_skpdi_task_items_edit_deny') === '1'));
    }

    isFactVisible() {
        const status = _.get(_.find(this.state.kurs_task_statuses, {value: _.get(this.props.data, 'status_uuid')}), 'label');

        return _.indexOf([
            'В работе',
            'На рассмотрении',
            'Закрыт',
        ], status) !== -1;
    }

    render() {
        return (
            <div className="b-modal__block">
                {this.isFactVisible() ? ([
                    <FilterHeader key="filter_header"
                                  items={['Факт', 'План']}
                                  currentItem={this.state.currentFilterItem || 0}
                                  onChange={(e) => {
                                      this.setState({currentFilterItem: e.value});
                                  }}
                    />,
                    ((this.state.currentFilterItem || 0) === 0) ? (
                        <div className="b-draggable">
                            {this.isEditable('fact') ? (
                                <Sortable
                                    options={{
                                        forceFallback: true,
                                        handle: '.b-draggable__dragzone-handle',
                                    }}
                                    onChange={(order) => {
                                        this.setValue('task.items_fact', _.map(_.filter(order, (item) => item.substr(0, 1) !== 'a'), (index) => {
                                            return this.get(`items_fact.${index}`);
                                        }));
                                    }}
                                >
                                    <div data-id={`a0`} className="add-draggable"
                                         onClick={this.addItem.bind(this, -1, 'items_fact')}>
                                        <div className="add-draggable__icon">+</div>
                                    </div>
                                    {this.get('items_fact', []).map(this.renderItem.bind(this, 'fact'))}
                                </Sortable>
                            ) : (
                                this.get('items_fact', []).map(this.renderItem.bind(this, 'fact'))
                            )}
                        </div>
                    ) : null,
                ]) : null}
                {((this.state.currentFilterItem === 1) || !this.isFactVisible()) ? (
                    <div className="b-draggable">
                        {this.isEditable('plan') ? (
                            <Sortable
                                options={{
                                    forceFallback: true,
                                    handle: '.b-draggable__dragzone-handle',
                                }}
                                onChange={(order) => {
                                    this.setValue('task.items', _.map(_.filter(order, (item) => item.substr(0, 1) !== 'a'), (index) => {
                                        return this.get(`items.${index}`);
                                    }));
                                }}
                            >
                                <div data-id={`a0`} className="add-draggable"
                                     onClick={this.addItem.bind(this, -1, 'items')}>
                                    <div className="add-draggable__icon">+</div>
                                </div>
                                {this.get('items', []).map(this.renderItem.bind(this, 'plan'))}
                            </Sortable>
                        ) : (
                            this.get('items', []).map(this.renderItem.bind(this, 'plan'))
                        )}
                    </div>
                ) : null}
                <KursTaskMapEditorMap
                    ref="map"
                    task={this.state.task}
                    type={((this.state.currentFilterItem === 1) || !this.isFactVisible()) ? 'plan' : 'fact'}
                    isEditable={this.isEditable(((this.state.currentFilterItem === 1) || !this.isFactVisible()) ? 'plan' : 'fact')}
                    onStopPointAdd={::this.onStopPointAdd}
                    onRoadPartAdd={::this.onRoadPartAdd}
                    onUpdate={::this.onUpdate}
                    addGeometry={::this.addGeometryFromMap}
                    geometryType={this.getGeometryType()}
                    onItemClick={::this.onItemClick}
                    mapDisplayParts={this.props.mapDisplayParts}
                />
                {(this.state.mapActive !== null) ? this.renderMap() : null}
            </div>
        );
    }

    onItemClick(index) {
        this.setState({
            expandedBlock: index,
        });
    }

    async onUpdate(task, itemIndex = null, field) {
        await this.setState({
            task,
        });

        if (itemIndex !== null) {
            this.calcPath(itemIndex, field);

            this.refs.map.getWrappedInstance().forceUpdate();
        } else {
            this.fillIdle(field);
        }
    }

    async onStopPointAdd(uuid, latitude, longitude) {
        let task = this.state.task;

        const field = ((this.state.currentFilterItem === 1) || !this.isFactVisible()) ? 'items' : 'items_fact';
        const index = this.state.expandedBlock;
        if (index === null || index === undefined) {
            return;
        }

        task[field][index].geojson = {
            type: 'Point',
            coordinates: [
                longitude,
                latitude,
            ],
        };
        task[field][index].geometry[0].item_uuid = uuid;

        await this.setState({
            task,
        });

        this.fillIdle(field);

        this.refs[`task.${field}.${index}.item_uuid`] && this.refs[`task.${field}.${index}.item_uuid`].reload();
    }

    async onRoadPartAdd(uuid, geojson) {
        let task = this.state.task;

        const field = ((this.state.currentFilterItem === 1) || !this.isFactVisible()) ? 'items' : 'items_fact';
        const index = this.state.expandedBlock;
        if (index === null || index === undefined) {
            return;
        }

        await this.setValue(`task.${field}.${index}.geometry.0.item_uuid`, uuid);
        /*
                task[field][index].geojson = geojson;
                task[field][index].geometry[0].item_uuid = uuid;

                await this.setState({
                    task,
                });

                this.fillIdle(field);*/

        this.refs[`task.${field}.${index}.item_uuid`] && this.refs[`task.${field}.${index}.item_uuid`].reload();
    }

    getValue(field) {
        if (/\.movement_type$/.test(field)) {
            return _.get(this.getState(), field) === 'idle';
        }
        return _.get(this.getState(), field);
    }

    async checkPlan(field, value) {
        const matches = /^task\.items\.([0-9]+)\.geometry_type$/.exec(field);
        if (matches) {
            if (value === 'idle') {
                await this.setValue(`task.items.${matches[1]}.movement_type`, 'idle');
                return;
            } else {
                await this.setValue(`task.items.${matches[1]}.movement_type`, 'work', true);
            }
            await this.setValue(`task.items.${matches[1]}.geojson`, null);
            if (!value || value === 'other') {
                await this.setValue(`task.items.${matches[1]}.geometry`, []);
            } else {
                await this.setValue(`task.items.${matches[1]}.geometry`, [{}]);
            }
            this.refs[`task.items.${matches[1]}.item_uuid`] && this.refs[`task.items.${matches[1]}.item_uuid`].reload();
        }

        const fromMatches = /^task\.items\.([0-9]+)\.date_from/.exec(field);
        const toMatches = /^task\.items\.([0-9]+)\.date_to/.exec(field);
        if (fromMatches || toMatches) {
            const itemIndex = fromMatches ? fromMatches[1] : toMatches[1];
            const from = moment(this.get(`items.${itemIndex}.date_from`), formats.TIME);
            const to = moment(this.get(`items.${itemIndex}.date_to`), formats.TIME);
            const time = Math.abs(from.diff(to, 'minutes'));
            this.setValue(`task.items.${itemIndex}.time`, this.formatTime(time), true);
        }
        const timeMatches = /^task\.items\.([0-9]+)\.time/.exec(field);
        if (timeMatches) {
            const itemIndex = timeMatches[1];
            const from = moment(this.get(`items.${itemIndex}.date_from`), formats.TIME);
            const time = moment(this.get(`items.${itemIndex}.time`), formats.TIME).diff(moment('00:00', formats.TIME), 'minutes');
            const to = from.add(time, 'minutes').format(formats.TIME);
            this.setValue(`task.items.${itemIndex}.date_to`, to, true);
        }

        const itemMatches = /^task\.items\.([0-9]+)\.geometry\.([0-9]+)\.item_uuid/.exec(field);
        if (itemMatches) {
            const itemIndex = itemMatches[1];
            await this.setValue(`task.items.${itemMatches[1]}.part_start`, null, true);
            await this.setValue(`task.items.${itemMatches[1]}.part_end`, null, true);
            this.storeGeometry(itemIndex, 'items');
        }

        const sliceMatches = /^task\.items\.([0-9]+)\.(part_start|part_end)/.exec(field);
        if (sliceMatches) {
            const itemIndex = sliceMatches[1];
            this.storeGeometry(itemIndex, 'items');
        }

        const directionMatches = /^task\.items\.([0-9]+)\.geometry\.([0-9]+)\.direction/.exec(field);
        if (directionMatches) {
            const itemIndex = directionMatches[1];
            this.storeGeometry(itemIndex, 'items');
        }

        const addressMatches = /^task\.items\.([0-9]+)\.geometry\.([0-9]+)\.address/.exec(field);
        if (addressMatches) {
            const itemIndex = addressMatches[1];
            const index = addressMatches[2];

            this.addressSearchDebounce(itemIndex, index, 'items');
        }

        const latMatches = /^task\.items\.([0-9]+)\.geometry\.([0-9]+)\.latitude/.exec(field);
        if (latMatches) {
            const itemIndex = latMatches[1];
            const index = latMatches[2];

            await this.setValue(`task.items.${itemIndex}.geometry.${index}.is_address`, true, true);
            await this.setValue(`task.items.${itemIndex}.geometry.${index}.address`, null, true);
            this.calcPath(itemIndex);
            this.addressReverseGeocodeDebounce(itemIndex, index, 'items');
        }

        const lonMatches = /^task\.items\.([0-9]+)\.geometry\.([0-9]+)\.longitude/.exec(field);
        if (lonMatches) {
            const itemIndex = lonMatches[1];
            const index = lonMatches[2];

            await this.setValue(`task.items.${itemIndex}.geometry.${index}.is_address`, true, true);
            await this.setValue(`task.items.${itemIndex}.geometry.${index}.address`, null, true);
            this.calcPath(itemIndex, 'items');
            this.addressReverseGeocodeDebounce(itemIndex, index, 'items');
        }

        const movementMatches = /^task\.items\.([0-9]+)\.movement_type$/.exec(field);
        if (movementMatches) {
            this.setValue(`task.items.${movementMatches[1]}.geometry_type`, (value === 'idle') ? 'idle' : 'other', true);
            this.setValue(`task.items.${movementMatches[1]}.geometry`, []);
            this.fillIdle('items');
        }
    }

    async checkFact(field, value) {
        const matches = /^task\.items_fact\.([0-9]+)\.geometry_type$/.exec(field);
        if (matches) {
            if (value === 'idle') {
                await this.setValue(`task.items_fact.${matches[1]}.movement_type`, 'idle');
                return;
            } else {
                await this.setValue(`task.items_fact.${matches[1]}.movement_type`, 'work', true);
            }
            await this.setValue(`task.items_fact.${matches[1]}.geojson`, null);
            if (!value || value === 'other') {
                await this.setValue(`task.items_fact.${matches[1]}.geometry`, []);
            } else {
                await this.setValue(`task.items_fact.${matches[1]}.geometry`, [{}]);
            }
            this.refs[`task.items_fact.${matches[1]}.item_uuid`] && this.refs[`task.items_fact.${matches[1]}.item_uuid`].reload();
        }

        const fromMatches = /^task\.items_fact\.([0-9]+)\.date_from/.exec(field);
        const toMatches = /^task\.items_fact\.([0-9]+)\.date_to/.exec(field);
        if (fromMatches || toMatches) {
            const itemIndex = fromMatches ? fromMatches[1] : toMatches[1];
            const from = moment(this.get(`items_fact.${itemIndex}.date_from`), formats.TIME);
            const to = moment(this.get(`items_fact.${itemIndex}.date_to`), formats.TIME);
            const time = Math.abs(from.diff(to, 'minutes'));
            this.setValue(`task.items_fact.${itemIndex}.time`, this.formatTime(time), true);
        }
        const timeMatches = /^task\.items_fact\.([0-9]+)\.time/.exec(field);
        if (timeMatches) {
            const itemIndex = timeMatches[1];
            const from = moment(this.get(`items_fact.${itemIndex}.date_from`), formats.TIME);
            const time = moment(this.get(`items_fact.${itemIndex}.time`), formats.TIME).diff(moment('00:00', formats.TIME), 'minutes');
            const to = from.add(time, 'minutes').format(formats.TIME);
            this.setValue(`task.items_fact.${itemIndex}.date_to`, to, true);
        }

        const fromFactMatches = /^task\.items_fact\.([0-9]+)\.fact_date_from/.exec(field);
        const toFactMatches = /^task\.items_fact\.([0-9]+)\.fact_date_to/.exec(field);
        if (fromFactMatches || toFactMatches) {
            const itemIndex = fromFactMatches ? fromFactMatches[1] : toFactMatches[1];
            const from = moment(this.get(`items_fact.${itemIndex}.fact_date_from`), formats.TIME);
            const to = moment(this.get(`items_fact.${itemIndex}.fact_date_to`), formats.TIME);
            const time = Math.abs(from.diff(to, 'minutes'));
            this.setValue(`task.items_fact.${itemIndex}.fact_time`, this.formatTime(time), true);
        }
        const timeFactMatches = /^task\.items_fact\.([0-9]+)\.fact_time/.exec(field);
        if (timeFactMatches) {
            const itemIndex = timeFactMatches[1];
            const from = moment(this.get(`items_fact.${itemIndex}.fact_date_from`), formats.TIME);
            const time = moment(this.get(`items_fact.${itemIndex}.fact_time`), formats.TIME).diff(moment('00:00', formats.TIME), 'minutes');
            const to = from.add(time, 'minutes').format(formats.TIME);
            this.setValue(`task.items_fact.${itemIndex}.fact_date_to`, to, true);
        }

        const itemMatches = /^task\.items_fact\.([0-9]+)\.geometry\.([0-9]+)\.item_uuid/.exec(field);
        if (itemMatches) {
            const itemIndex = itemMatches[1];
            await this.setValue(`task.items_fact.${itemMatches[1]}.part_start`, null, true);
            await this.setValue(`task.items_fact.${itemMatches[1]}.part_end`, null, true);
            this.storeGeometry(itemIndex, 'items_fact');
        }

        const sliceMatches = /^task\.items_fact\.([0-9]+)\.(part_start|part_end)/.exec(field);
        if (sliceMatches) {
            const itemIndex = sliceMatches[1];
            this.storeGeometry(itemIndex, 'items_fact');
        }

        const directionMatches = /^task\.items_fact\.([0-9]+)\.geometry\.([0-9]+)\.direction/.exec(field);
        if (directionMatches) {
            const itemIndex = directionMatches[1];
            this.storeGeometry(itemIndex, 'items_fact');
        }

        const addressMatches = /^task\.items_fact\.([0-9]+)\.geometry\.([0-9]+)\.address/.exec(field);
        if (addressMatches) {
            const itemIndex = addressMatches[1];
            const index = addressMatches[2];

            this.addressSearchDebounce(itemIndex, index, 'items_fact');
        }

        const latMatches = /^task\.items_fact\.([0-9]+)\.geometry\.([0-9]+)\.latitude/.exec(field);
        if (latMatches) {
            const itemIndex = latMatches[1];
            const index = latMatches[2];

            await this.setValue(`task.items_fact.${itemIndex}.geometry.${index}.is_address`, true, true);
            await this.setValue(`task.items_fact.${itemIndex}.geometry.${index}.address`, null, true);
            this.calcPath(itemIndex, 'items_fact');
            this.addressReverseGeocodeDebounce(itemIndex, index, 'items_fact');
        }

        const lonMatches = /^task\.items_fact\.([0-9]+)\.geometry\.([0-9]+)\.longitude/.exec(field);
        if (lonMatches) {
            const itemIndex = lonMatches[1];
            const index = lonMatches[2];

            await this.setValue(`task.items.${itemIndex}.geometry.${index}.is_address`, true, true);
            await this.setValue(`task.items.${itemIndex}.geometry.${index}.address`, null, true);
            this.calcPath(itemIndex, 'items_fact');
            this.addressReverseGeocodeDebounce(itemIndex, index, 'items_fact');
        }

        const movementMatches = /^task\.items_fact\.([0-9]+)\.movement_type$/.exec(field);
        if (movementMatches) {
            this.setValue(`task.items_fact.${movementMatches[1]}.geometry_type`, (value === 'idle') ? 'idle' : 'other', true);
            this.setValue(`task.items_fact.${movementMatches[1]}.geometry`, []);
            this.fillIdle('items_fact');
        }
    }

    async setValue(field, value, ignoreRules = false) {
        await super.setValue(field, value);

        if (ignoreRules) {
            this.refs.map.getWrappedInstance().forceUpdate();
            return;
        }

        this.stopLiveReload();

        this.checkPlan(field, value);
        this.checkFact(field, value);

        this.refs.map.getWrappedInstance().forceUpdate();
    }

    async calcPath(index, field) {
        const sector = _.filter(_.map(this.get(`${field}.${index}.geometry`, []), (geometry) => {
            const latitude = _.get(geometry, 'latitude');
            const longitude = _.get(geometry, 'longitude');
            if (latitude && longitude) {
                return {
                    latitude,
                    longitude,
                };
            }
            return null;
        }));
        api.geo.routing(sector).then((response) => {
            if (response.success) {
                this.setValue(`task.${field}.${index}.geojson`, this.createPointGeometry(response.payload.points));
                this.setValue(`task.${field}.${index}.distance`, _.round(response.payload.distance / 1000, 2));
            }
        }, () => {
        });
    }

    createPointGeometry(points) {
        if (points && points.length > 0) {
            return {
                type: 'LineString',
                coordinates: _.map(points, (coord) => _.clone(coord).reverse()),
            };
        }

        return null;
    };

    async storeGeometry(itemIndex, field) {
        const uuid = this.get(`${field}.${itemIndex}.geometry.0.item_uuid`);
        if (!uuid) {
            this.setValue(`task.${field}.${itemIndex}.part_start`, '', true);
            this.setValue(`task.${field}.${itemIndex}.part_end`, '', true);
            this.setValue(`task.${field}.${itemIndex}.distance`, '', true);
            return;
        }
        const type = this.get(`${field}.${itemIndex}.geometry_type`);
        const direction = this.get(`${field}.${itemIndex}.geometry.0.direction`);
        if (direction === null) {
            this.setValue(`task.${field}.${itemIndex}.geometry.0.direction`, 'forward');
        }
        let response;

        switch (type) {
            case 'road_part':
                response = await this.props.getRoadPart(uuid, {
                    slice_start: this.get(`${field}.${itemIndex}.part_start`) || 0,
                    slice_end: this.get(`${field}.${itemIndex}.part_end`) || 0,
                });

                if (response.isOk) {
                    let geojson = _.cloneDeep(response.payload.geometry);
                    if (!geojson) {
                        alerts.alert('Для данного участка дороги отсутствует геометрия, выбор данного участка невозможен');
                        this.setValue(`task.${field}.${itemIndex}.geometry.0.item_uuid`, null, true);
                        return;
                    }
                    if (this.get(`${field}.${itemIndex}.part_start`) === null) {
                        this.setValue(`task.${field}.${itemIndex}.part_start`, 0, true);
                    }
                    if (this.get(`${field}.${itemIndex}.part_end`) === null) {
                        this.setValue(`task.${field}.${itemIndex}.part_end`, _.round(response.payload.slice_length, 3), true);
                    }
                    this.setValue(`task.${field}.${itemIndex}.distance`, _.round(response.payload.slice_length, 3), true);
                    if (direction === 'reverse') {
                        _.reverse(geojson.coordinates);
                    }
                    await this.setValue(`task.${field}.${itemIndex}.geojson`, geojson, true);

                    let task = this.state.task;
                    for (let i = itemIndex; i >= 0; i--) {
                        if (_.get(task, `${field}.${i}.movement_type`) === 'idle') {
                            task.items.splice(i, 1);
                        } else {
                            break;
                        }
                    }
                    await this.setState({task});

                    this.fillIdle(field);
                    /*this.setState({
                        expandedBlock: this.state.expandedBlock + 1,
                    });*/
                } else {
                    response.showErrors();
                }
                break;
            case 'road_repair_part':
                response = await this.props.getRoadRepairPart({
                    uuid,
                    slice_start: this.get(`${field}.${itemIndex}.part_start`) || 0,
                    slice_end: this.get(`${field}.${itemIndex}.part_end`) || 0,
                });

                if (response.isOk) {
                    let geojson = _.cloneDeep(response.payload.geometry);
                    if (!geojson) {
                        alerts.alert('Для данного участка ремонта дороги отсутствует геометрия, выбор данного участка невозможен');
                        this.setValue(`task.${field}.${itemIndex}.geometry.0.item_uuid`, null, true);
                        return;
                    }
                    if (this.get(`${field}.${itemIndex}.part_start`) === null) {
                        this.setValue(`task.${field}.${itemIndex}.part_start`, 0, true);
                    }
                    if (this.get(`${field}.${itemIndex}.part_end`) === null) {
                        this.setValue(`task.${field}.${itemIndex}.part_end`, _.round(response.payload.slice_length, 3), true);
                    }
                    this.setValue(`task.${field}.${itemIndex}.distance`, _.round(response.payload.slice_length, 3), true);
                    if (direction === 'reverse') {
                        _.reverse(geojson.coordinates);
                    }
                    await this.setValue(`task.${field}.${itemIndex}.geojson`, geojson, true);

                    let task = this.state.task;
                    for (let i = itemIndex; i >= 0; i--) {
                        if (_.get(task, `${field}.${i}.movement_type`) === 'idle') {
                            task.items.splice(i, 1);
                        } else {
                            break;
                        }
                    }
                    await this.setState({task});

                    this.fillIdle(field);
                } else {
                    response.showErrors();
                }
                break;
            case 'stop_point':
                response = await this.props.getStopPoint(uuid);

                if (response.isOk) {
                    await this.setValue(`task.${field}.${itemIndex}.geojson`, {
                        type: 'Point',
                        coordinates: [
                            response.payload.longitude,
                            response.payload.latitude,
                        ],
                    });

                    let task = this.state.task;
                    for (let i = itemIndex; i >= 0; i--) {
                        if (_.get(task, `${field}.${i}.movement_type`) === 'idle') {
                            task.items.splice(i, 1);
                        } else {
                            break;
                        }
                    }
                    await this.setState({task});

                    this.fillIdle(field);
                    /*this.setState({
                        expandedBlock: this.state.expandedBlock + 1,
                    });*/
                } else {
                    response.showErrors();
                }
                break;
        }
    }

    async getCoordinates(itemIndex, index) {
        const area = this.get(`items.${itemIndex}.geometry.${index}.address`);
        const locality = this.get(`items.${itemIndex}.geometry.${index}.control_point.locality_name`);
        const street = this.get(`items.${itemIndex}.geometry.${index}.control_point.street_name`);
        const house = this.get(`items.${itemIndex}.geometry.${index}.control_point.house`);

        if (area && locality && street && house) {
            const address = `${area}, ${locality}, ${street}, ${house}`;

            const response = await this.props.geocode({
                q: address,
            });
            if (response.isOk) {
                const coordinates = response.payload.coordinates;
                this.setValue(`task.items.${itemIndex}.geometry.${index}.control_point.latitude`, coordinates.latitude, true);
                this.setValue(`task.items.${itemIndex}.geometry.${index}.control_point.longitude`, coordinates.longitude, true);
            }
        }
    }

    formatTime(minutes) {
        if (isNaN(minutes)) {
            return '00:00';
        }
        return _.padStart(Math.floor(minutes / 60), 2, '0') + ':' + _.padStart(minutes % 60, 2, '0');
    }

    addRoadParts(items) {
        let roadParts = this.state.roadParts || {};
        _.each(items, (item) => {
            roadParts[item.uuid] = `${item.register_number} ${item.name}`;
        });

        this.setState({
            roadParts,
        });
    }

    async loadRoadPartByUuid(uuid, callback) {
        const result = await this.props.getRoadParts({
            filters: {
                withUuid: [uuid],
            },
            response_data: [
                'items/uuid',
                'items/register_number',
                'items/name',
            ],
        });

        if (result.isOk) {
            this.addRoadParts(result.payload.items);
            callback(null, {
                options: _.sortBy(result.payload.items.map(item => ({
                    value: item.uuid,
                    label: `${item.register_number} ${item.name}`,
                })), 'label'),
                complete: false,
            });
            /*console.log('qwe', {
                options: _.sortBy(result.payload.items.map(item => ({
                    value: item.uuid,
                    label: `${item.register_number} ${item.name}`,
                })), 'label'),
                complete: false,
            });*/
        } else {
            result.showErrors();
        }
    }


    async loadObjects(index, input, callback, field) {
        const type = this.get(`${field}.${index}.geometry_type`);
        if (!input) {
            input = this.get(`${field}.${index}.geometry.0.item_uuid`);

            if (type === 'road_part') {
                return this.loadRoadPartByUuid(input, callback);
            }
        }
        let result;
        switch (type) {
            case 'road_part':
                result = await this.props.getRoadParts({
                    search: input,
                    pagination: {
                        page: 1,
                        limit: 20,
                    },
                    response_data: [
                        'items/uuid',
                        'items/register_number',
                        'items/name',
                    ],
                });

                if (result.isOk) {
                    this.addRoadParts(result.payload.items);
                    callback(null, {
                        options: _.sortBy(result.payload.items.map(item => ({
                            value: item.uuid,
                            label: `${item.register_number} ${item.name}`,
                        })), 'label'),
                        complete: false,
                    });
                } else {
                    result.showErrors();
                }
                break;
            case 'road_repair_part':
                result = await this.props.getDictionaryList('kurs_road_repair_parts', {
                    search: input,
                    pagination: {
                        page: 1,
                        limit: 20,
                    },
                }, false);

                if (result.isOk) {
                    callback(null, {
                        options: _.sortBy(result.payload.documents.map(item => ({
                            value: item.uuid,
                            label: item.name,
                        })), 'label'),
                        complete: false,
                    });
                } else {
                    result.showErrors();
                }
                break;
            case 'stop_point':
                result = await this.props.getStopPoints({
                    search: input,
                    pagination: {
                        page: 1,
                        limit: 20,
                    },
                });

                if (result.isOk) {
                    callback(null, {
                        options: _.sortBy(result.payload.items.map(item => ({
                            value: item.uuid,
                            label: `${item.register_number} ${item.title}`,
                        })), 'label'),
                        complete: false,
                    });
                } else {
                    result.showErrors();
                }
                break;
        }
    }

    async searchAddress(itemIndex, index, field) {
        const input = this.get(`${field}.${itemIndex}.geometry.${index}.address`);

        const response = await this.props.geocode({
            q: input,
        });

        if (response.isOk) {
            await Promise.all([
                this.setValue(`task.${field}.${itemIndex}.geometry.${index}.latitude`, response.payload.coordinates.latitude, true),
                this.setValue(`task.${field}.${itemIndex}.geometry.${index}.longitude`, response.payload.coordinates.longitude, true),
            ]);

            this.calcPath(itemIndex, field);
        }
    }

    async reverseGeocode(itemIndex, index, field) {
        const latitude = this.get(`${field}.${itemIndex}.geometry.${index}.latitude`);
        const longitude = this.get(`${field}.${itemIndex}.geometry.${index}.longitude`);

        if (!latitude || !longitude) {
            return;
        }

        const response = await this.props.geocode({
            coordinates: {
                latitude,
                longitude,
            },
        });

        if (response.isOk) {
            await this.setValue(`task.${field}.${itemIndex}.geometry.${index}.address`, response.payload.address, true);
        }
    }

    async addGeometry(itemIndex, field, e = null, latitude = null, longitude = null) {
        e && e.preventDefault();

        const geometry = (latitude && longitude) ? {
            is_address: true,
            latitude,
            longitude,
        } : {};

        let index;

        let willCalcNext = false;
        let task = this.state.task;
        if (task[field][itemIndex].movement_type === 'idle') {
            if (itemIndex === 0) {
                task[field][itemIndex].geometry.splice(0, 0, geometry);
                index = 0;
            } else if (itemIndex === task[field].length - 1) {
                task[field][itemIndex].geometry.push(geometry);
                index = task[field][itemIndex].geometry.length - 1;
            } else {
                task[field][itemIndex].geometry.splice(task[field][itemIndex].geometry.length - 1, 0, geometry);
                index = task[field][itemIndex].geometry.length - 2;
            }
        } else {
            task[field][itemIndex].geometry.push(geometry);
            index = task[field][itemIndex].geometry.length - 1;
            if (task[field][itemIndex + 1] && (task[field][itemIndex + 1].movement_type === 'idle')) {
                task[field][itemIndex + 1].geometry[0] = geometry;
                willCalcNext = true;
            }
        }

        await this.setState({task});
        this.reverseGeocode(itemIndex, index, field);
        if (willCalcNext) {
            this.calcPath(itemIndex + 1, field);
        }
    }

    getGeometryType() {
        const field = ((this.state.currentFilterItem === 1) || !this.isFactVisible()) ? 'items' : 'items_fact';
        const index = this.state.expandedBlock;
        if (index === null || index === undefined) {
            return;
        }

        return _.get(this.state.task[field][index], 'geometry_type');
    }

    async addGeometryFromMap(latitude, longitude) {
        const field = ((this.state.currentFilterItem === 1) || !this.isFactVisible()) ? 'items' : 'items_fact';
        const index = this.state.expandedBlock;
        if (index === null || index === undefined) {
            return;
        }
        if (!((this.state.task[field][index].movement_type === 'idle') || (this.state.task[field][index].geometry_type === 'other'))) {
            return null;
        }
        await this.addGeometry(index, field, null, latitude, longitude);
        this.calcPath(index, field);
        this.fillIdle(field);
    }

    deleteGeometry(itemIndex, index, field, e) {
        e && e.preventDefault();

        let task = this.state.task;
        task[field][itemIndex].geometry.splice(index, 1);
        this.setState({task});
        this.calcPath(index, field);
        this.fillIdle(field);
    }

    expand(index) {
        let expandedBlocks = this.state.expandedBlocks;
        expandedBlocks.push(index);
        expandedBlocks = _.uniq(expandedBlocks);

        this.setState({
            expandedBlock: (this.state.expandedBlock === index) ? null : index,
            expandedBlocks,
        });
    }

    expandConfirm(index) {
        this.setState({
            expandedConfirmBlock: (this.state.expandedConfirmBlock === index) ? null : index,
        });
    }

    expandObjectVisits(index) {
        this.setState({
            expandedObjectVisitsBlock: (this.state.expandedObjectVisitsBlock === index) ? null : index,
        });
    }

    setConfirmed(index, value) {
        this.setValue(`task.items_fact.${index}.is_confirmed`, value);
        this.setValue(`task.items_fact.${index}.is_confirmed_manual`, true);
    }

    toggleSelectedForCopy(type, index) {
        const field = (type === 'plan') ? 'items' : 'items_fact';
        let task = this.state.task;
        task[field][index].selected_for_copy = !task[field][index].selected_for_copy;
        this.setState({task});
    }

    async confirmCopy() {
        const field = ((this.state.currentFilterItem === 1) || !this.isFactVisible()) ? 'items' : 'items_fact';
        let task = this.state.task;
        task[field] = _.concat(task[field], _.cloneDeep(_.filter(task[field], {
            selected_for_copy: true,
        })));
        task[field] = _.map(task[field], (item) => {
            item.selected_for_copy = false;
            return item;
        });

        await this.setState({task});
        this.fillIdle(field);
    }

    enableManual(field, index) {
        let task = this.state.task;
        task[field][index].is_manual = true;
        this.setState({task});
    }

    async loadWorkTypes(index, input, callback, field) {
        let result;

        if (!input) {
            input = this.get(`${field}.${index}.work_type_uuid`);
            result = await this.props.getDictionaryList('work_types', {
                search: input,
                pagination: {
                    page: 1,
                    limit: 20,
                },
            }, false);
        } else {
            result = await this.props.getDictionaryList('work_types', {
                search: input,
                pagination: {
                    page: 1,
                    limit: 20,
                },
            }, false);
        }

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.documents.map(type => ({
                    value: type.uuid,
                    label: `${type.name}`,
                })), 'label'),
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    renderItem(type, item, index) {
        if (this.props.confirmModeActive && (item.movement_type === 'idle')) {
            return null;
        }

        const geometryType = item.geometry_type;
        const isEditable = this.isEditable(type);
        const isEditableBySkpdi = this.isEditableBySkpdi(item);

        const field = (type === 'plan') ? 'items' : 'items_fact';

        const measureUuid = _.get(_.find(this.state.work_types, {value: item.work_type_uuid}), 'document.measure_uuid');
        const measure = _.get(_.find(this.state.measures, {value: measureUuid}), 'label');

        const itemUuid = _.get(_.first(item.geometry), 'item_uuid');

        return ([
            <TaskItem key={`${index}:${itemUuid}`} data-id={index} className={classNames('b-draggable__item sector', {
                sector_state_moving: item.movement_type === 'work',
                sector_idling: item.movement_type === 'idle',
                sector_state_success: item.is_confirmed === true,
                sector_state_violation: item.is_confirmed === false,
                copy_mode: this.isEditable(type) && this.props.copyModeActive,
            })}>
                {(type === 'fact') ? (
                    <div className="b-draggable__state"/>
                ) : null}
                {isEditable ? (
                    <div className="b-draggable__dragzone">
                        <span className="b-draggable__dragzone-handle">.<br/>.<br/>.</span>
                    </div>
                ) : null}
                <div className="b-draggable__top">
                    <input type="checkbox" className="b-draggable__open" checked={this.state.expandedBlock !== index}
                           onChange={this.expand.bind(this, index)}/>
                    <i className="b-draggable__arrow"/>
                    <Checkbox
                        checked={item.selected_for_copy}
                        onChange={this.toggleSelectedForCopy.bind(this, type, index)}
                    />
                    <div className="b-draggable__title">
                        {(type === 'fact') ? (
                            <ContextTooltip default="Процент зачета участка работ по данным телематики">
                                <div>{_.isNumber(item.percent) ? item.percent : '-'}%&nbsp;</div>
                            </ContextTooltip>
                        ) : null}
                        <div>Участок №{index + 1}</div>
                        <span>/{(this.get(`${field}.${index}.movement_type`) === 'idle') ? 'Холостой ход' : _.get(_.find(this.state.work_types, {value: item.work_type_uuid}), 'label')}</span>
                    </div>
                    <div className="b-draggable__menu">
                        {(type === 'fact' && this.get(`${field}.${index}.movement_type`) !== 'idle') ? (
                            <ContextTooltip key="kurs.task.map.location" code="kurs.task.map.location"
                                            default="Отобразить данные телематики">
                                <div className="b-draggable__menu-link b-draggable__menu-link_location"
                                     onClick={this.showMap.bind(this, index)}/>
                            </ContextTooltip>
                        ) : null}
                        <ContextTooltip key="kurs.task.map.eye" code="kurs.task.map.eye"
                                        default="Отобразить/скрыть">
                            <div
                                className={classNames('b-draggable__menu-link b-draggable__menu-link_eye', item.hidden ? 'active' : '')}
                                onClick={this.toggleVisible.bind(this, index, field)}/>
                        </ContextTooltip>
                        {isEditable ? (
                            <ContextTooltip key="kurs.task.map.delete" code="kurs.task.map.delete"
                                            default="Удалить">
                                <div className="b-draggable__menu-link b-draggable__menu-link_basket"
                                     onClick={this.deleteItem.bind(this, index, field)}/>
                            </ContextTooltip>
                        ) : null}
                    </div>
                </div>
                {(_.indexOf(this.state.expandedBlocks, index) !== -1) ? (
                    <div className={classNames('b-draggable__content', {
                        'hidden': this.state.expandedBlock !== index,
                    })}>
                        {((this.get(`${field}.${index}.movement_type`) === 'work') && (type === 'fact')) ? (
                            <div className="b-draggable__content-item">
                                <div className="b-draggable__content-header">
                                    <div className="b-draggable__content-title">Факт</div>
                                    <Block size="lg" title={true}>
                                        <Button size="md" color={item.is_manual ? 'red' : 'white'}
                                                shadow={item.is_manual ? 'red' : 'gray'}
                                                width="auto" text="Ручной ввод"
                                                onClick={this.enableManual.bind(this, field, index)}/>
                                    </Block>
                                </div>
                                <Block title="Факт время с">
                                    {this.maskInput(`task.${field}.${index}.fact_date_from`, '99:99:99', {
                                        withTimeIcon: true,
                                        disabled: !isEditable || !item.is_manual,
                                    })}
                                </Block>,
                                <Block title="Факт время по">
                                    {this.maskInput(`task.${field}.${index}.fact_date_to`, '99:99:99', {
                                        withTimeIcon: true,
                                        disabled: !isEditable || !item.is_manual,
                                    })}
                                </Block>,
                                <Block title="Факт продол.">
                                    {this.maskInput(`task.${field}.${index}.fact_time`, '99:99:99', {
                                        withTimeIcon: true,
                                        disabled: !isEditable || !item.is_manual,
                                    })}
                                </Block>,
                            </div>
                        ) : null}

                        {(this.get(`${field}.${index}.movement_type`) === 'work') ? (
                            <div className="b-draggable__content-item">
                                <div className="b-draggable__content-header">
                                    <div className="b-draggable__content-title">План</div>
                                </div>
                                <Block title="Время с">
                                    {this.maskInput(`task.${field}.${index}.date_from`, '99:99', {
                                        withTimeIcon: true,
                                        disabled: !isEditable,
                                    })}
                                </Block>
                                <Block title="Время по">
                                    {this.maskInput(`task.${field}.${index}.date_to`, '99:99', {
                                        withTimeIcon: true,
                                        disabled: !isEditable,
                                    })}
                                </Block>
                                <Block title="Продолжительность">
                                    {this.maskInput(`task.${field}.${index}.time`, '99:99', {
                                        withTimeIcon: true,
                                        disabled: !isEditable,
                                    })}
                                </Block>
                            </div>
                        ) : null}

                        <div className="b-draggable__content-item">
                            <div className="b-draggable__content-header">
                                <div className="b-draggable__content-title">Маршрут</div>
                            </div>
                            <Block title="Вид маршрута">
                                {window.RNIS_SETTINGS.CITY_MURMANSK ? this.select(`task.${field}.${index}.geometry_type`, [
                                    {
                                        value: 'road_part',
                                        label: 'Участок дороги',
                                    },
                                ], {
                                    disabled: !isEditable || !isEditableBySkpdi,
                                }) : this.select(`task.${field}.${index}.geometry_type`, [
                                    {
                                        value: 'road_part',
                                        label: 'Участок дороги',
                                    },
                                    {
                                        value: 'road_repair_part',
                                        label: 'Участок ремонта дороги',
                                    },
                                    {
                                        value: 'stop_point',
                                        label: 'Остановка',
                                    },
                                    {
                                        value: 'other',
                                        label: 'Произвольный маршрут',
                                    },
                                    {
                                        value: 'idle',
                                        label: 'Холостой ход',
                                    },
                                ], {
                                    disabled: !isEditable || !isEditableBySkpdi,
                                })}
                            </Block>
                            {(this.get(`${field}.${index}.movement_type`) === 'work') ? ([
                                <Block key="work_type_uuid" size="lg" className="custom" title="Вид работы">
                                   {/* {this.select(`task.${field}.${index}.work_type_uuid`, isEditable ? this.getWorkTypes(field, index) : this.state.work_types, {
                                        disabled: !isEditable || !isEditableBySkpdi || item.external_id,
                                    })}*/}

                                    {this.selectAsync(`task.${field}.${index}.work_type_uuid`, async (input, callback) => {
                                        return await this.loadWorkTypes(index, input, callback, field);
                                    }, {
                                        disabled: !isEditable || !isEditableBySkpdi || item.external_id,
                                        ref: `task.${field}.${index}.work_type_uuid`,
                                    })}

                                </Block>,
                                <Block key="count" size="sm" className="custom" title="Объем работ">
                                    {this.textInput(`task.${field}.${index}.count`, {
                                        type: 'number',
                                        positive: true,
                                        disabled: !isEditable,
                                    })}
                                </Block>,
                                <Block key="count_measure" size="sm" className="custom">
                                    <Input value={measure} disabled={true}/>
                                </Block>,
                            ]) : null}
                        </div>

                        {(this.get(`${field}.${index}.movement_type`) === 'work') ? (
                            <div className="b-draggable__content-item">
                                <div className="b-draggable__content-header">
                                    <div className="b-draggable__content-title">Протяженность</div>
                                </div>
                                {((this.get(`${field}.${index}.geometry_type`) === 'road_part') || (this.get(`${field}.${index}.geometry_type`) === 'road_repair_part')) ? ([
                                    <Block key="part_start" title="Начало участка, км">
                                        {this.textInput(`task.${field}.${index}.part_start`, {
                                            disabled: !isEditable || !isEditableBySkpdi,
                                        })}
                                    </Block>,
                                    <Block key="part_end" title="Конец участка, км">
                                        {this.textInput(`task.${field}.${index}.part_end`, {
                                            disabled: !isEditable || !isEditableBySkpdi,
                                        })}
                                    </Block>,
                                ]) : null}
                                <Block title="Протяж, км">
                                    {this.textInput(`task.${field}.${index}.distance`, {
                                        disabled: !isEditable || !isEditableBySkpdi,
                                    })}
                                </Block>
                            </div>
                        ) : null}

                        {(geometryType === 'road_part') ? (
                            <div className="b-draggable__content-item">
                                <div className="b-draggable__content-header">
                                    <div className="b-draggable__content-title">Участок дороги</div>
                                </div>
                                <Block size="lg">
                                    {this.selectAsync(`task.${field}.${index}.geometry.0.item_uuid`, async (input, callback) => {
                                        return await this.loadObjects(index, input, callback, field);
                                    }, {
                                        disabled: !isEditable || !isEditableBySkpdi || item.external_id,
                                        ref: `task.${field}.${index}.item_uuid`,
                                    })}
                                </Block>
                                <Block key="direction" title="Направление">
                                    {this.select(`task.${field}.${index}.geometry.0.direction`, [
                                        {
                                            value: 'forward',
                                            label: 'Прямое',
                                        },
                                        {
                                            value: 'reverse',
                                            label: 'Обратное',
                                        },
                                    ], {
                                        disabled: !isEditable || !isEditableBySkpdi,
                                    })}
                                </Block>
                            </div>
                        ) : null}

                        {(geometryType === 'road_repair_part') ? (
                            <div className="b-draggable__content-item">
                                <div className="b-draggable__content-header">
                                    <div className="b-draggable__content-title">Участок ремонта дороги</div>
                                </div>
                                <Block size="lg">
                                    {this.selectAsync(`task.${field}.${index}.geometry.0.item_uuid`, async (input, callback) => {
                                        return await this.loadObjects(index, input, callback, field);
                                    }, {
                                        disabled: !isEditable,
                                        ref: `task.${field}.${index}.item_uuid`,
                                    })}
                                </Block>
                                <Block key="direction" title="Направление">
                                    {this.select(`task.${field}.${index}.geometry.0.direction`, [
                                        {
                                            value: 'forward',
                                            label: 'Прямое',
                                        },
                                        {
                                            value: 'reverse',
                                            label: 'Обратное',
                                        },
                                    ], {
                                        disabled: !isEditable,
                                    })}
                                </Block>
                            </div>
                        ) : null}

                        {(geometryType === 'stop_point') ? (
                            <div className="b-draggable__content-item">
                                <div className="b-draggable__content-header">
                                    <div className="b-draggable__content-title">Остановка</div>
                                </div>
                                <Block size="lg">
                                    {this.selectAsync(`task.${field}.${index}.geometry.0.item_uuid`, async (input, callback) => {
                                        return await this.loadObjects(index, input, callback, field);
                                    }, {
                                        disabled: !isEditable,
                                        ref: `task.${field}.${index}.item_uuid`,
                                    })}
                                </Block>
                            </div>
                        ) : null}

                        {((geometryType === 'other')) ? (
                            <div
                                className="b-draggable__content-item b-draggable__content-item_full b-draggable__content-item_checkpoints">
                                <div className="b-draggable__content-header">
                                    <div className="b-draggable__content-title">Точки участка на карте</div>
                                </div>
                                <div className="checkpoints-wrap">
                                    {_.map(item.geometry, this.renderItemGeometry.bind(this, index, isEditable, field))}
                                    {isEditable ? (
                                        <Block size="xl">
                                            <a className="add-point" key={`add:${index}`} href="javascript:void(0)"
                                               onClick={this.addGeometry.bind(this, index, field)}>+ Добавить</a>
                                        </Block>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : null}
                {(type === 'fact') ? (
                    <div>
                        <div className="b-draggable__top">
                            <input type="checkbox" className="b-draggable__open"
                                   checked={this.state.expandedObjectVisitsBlock !== index}
                                   onChange={this.expandObjectVisits.bind(this, index)}/>
                            <i className="b-draggable__arrow"/>
                            <div className="b-draggable__title b-draggable__title_sub">
                                <div>Посещения участка</div>
                            </div>
                        </div>
                        {(this.state.expandedObjectVisitsBlock === index) ? (
                            <div className="b-draggable__content">
                                <div className="b-draggable__content_inner">
                                    <TableContainer>
                                        <div className="Table">
                                            <table className="b-table b-table-thead-no-hover">
                                                <thead>
                                                <tr>
                                                    <th>№</th>
                                                    <th>Марка</th>
                                                    <th>Госномер</th>
                                                    <th>Прибытие</th>
                                                    <th>Убытие</th>
                                                    <th>Точка входа</th>
                                                    <th>Точка выхода</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {_.map(_.filter(this.state.object_visits, {task_item_index: index}), this.renderObjectVisit.bind(this, item, index))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </TableContainer>
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : null}
                {(this.props.confirmModeActive && (type === 'fact')) ? (
                    <div>
                        <div className="b-draggable__top">
                            <input type="checkbox" className="b-draggable__open"
                                   checked={this.state.expandedConfirmBlock !== index}
                                   onChange={this.expandConfirm.bind(this, index)}/>
                            <i className="b-draggable__arrow"/>
                            <div className="b-draggable__title b-draggable__title_sub">
                                <div>Подробная информация об участке</div>
                            </div>
                        </div>
                        {(this.state.expandedConfirmBlock === index) ? (
                            <div className="b-draggable__content">
                                <div className="b-draggable__content_inner">
                                    {this.renderViolations(item, index)}
                                    <Block title="Причина корректировки">
                                        {this.select(`task.${field}.${index}.violation_type_uuid`, this.state.kurs_violation_types)}
                                    </Block>
                                    <Block title="Факт. объем работ">
                                        {this.textInput(`task.${field}.${index}.fact_count`, {
                                            type: 'number',
                                        })}
                                    </Block>
                                    <Block size="xl" title="Комментарий">
                                        {this.textarea(`task.${field}.${index}.comment`)}
                                    </Block>
                                    {this.renderCheckBlock(item, index)}
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </TaskItem>,
            isEditable ? (
                <div key={`add-${index}`} data-id={`a${index}`} className="add-draggable"
                     onClick={this.addItem.bind(this, index, field)}>
                    <div className="add-draggable__icon">+</div>
                </div>
            ) : null,
        ]);
    }

    getWorkTypes(field, index) {
        const uuid = this.get(`${field}.${index}.geometry.0.item_uuid`);
        const workTypes = _.get(this.state.road_part_work_types, uuid);
        let allWorkTypes = this.state.work_types;

        /*if ((Settings.get('kurs_task_limit_work_types') === '1') && this.state.vehicleWorkTypes) {
            allWorkTypes = _.filter(allWorkTypes, (workType) => {
                return _.indexOf(this.state.vehicleWorkTypes, workType.value) !== -1;
            });
        }*/

        const selectedWorkType = this.get(`${field}.${index}.work_type_uuid`);
        if (_.isArray(workTypes)) {
            const workTypeUuids = _.map(workTypes, 'uuid');
            return _.filter(allWorkTypes, (workType) => {
                return (workType.value === selectedWorkType) || (_.indexOf(workTypeUuids, workType.value) !== -1);
            });
        }

        return allWorkTypes;
    }

    renderViolations(item, index) {
        const violations = _.filter(this.state.violations || [], {task_item_index: index.toString()});
        if (violations.length === 0) {
            return;
        }

        let lastViolationTimes = {
            max_speed: null,
            mechanism: null,
            route_fail: null,
        };

        return (
            <div className="b-draggable__content-header">
                <div className="b-draggable__content-atten">Вид нарушения на участке работ:</div>
                <div className="b-draggable__content-title">
                    {_.filter(_.map(violations, (violation) => {
                        const lastViolationTime = lastViolationTimes[violation.type];
                        if (lastViolationTime && (moment(violation.data.time).diff(lastViolationTime, 'minutes') <= 2)) {
                            return null;
                        }
                        lastViolationTimes[violation.type] = moment(violation.data.time);

                        switch (violation.type) {
                            case 'max_speed':
                                return `Превышение скорости ${violation.data ? `(${moment(violation.data.time).format(formats.TIME_FULL)}, ${violation.data.latitude}, ${violation.data.longitude})` : ''}`;
                            case 'mechanism':
                                return `Не включен механизм "${this.getMechanismName(violation.mechanism_binding_uuid)}" ${violation.data ? `(${moment(violation.data.time).format(formats.TIME_FULL)}, ${violation.data.latitude}, ${violation.data.longitude})` : ''}`;
                            case 'route_fail':
                                return `Отклонение от маршрута (${violation.start_at} км, ${violation.data ? `${moment(violation.data.time).format(formats.TIME_FULL)}, ${violation.data.latitude}, ${violation.data.longitude}` : ''})`;
                        }
                    })).join('; ')}
                </div>
            </div>
        )
    }

    getMechanismName(mechanismBindingUuid) {
        const mechanismTypeUuid = _.get(_.find(this.state.kurs_mechanism_bindings, {value: mechanismBindingUuid}), 'document.mechanism_type_uuid');
        return _.get(_.find(this.state.kurs_mechanism_types, {value: mechanismTypeUuid}), 'label');
    }

    renderCheckBlock(item, index) {
        const sliderClassName = classNames({
            'b-slider__line': true,
            '_selected_yes': (item.is_confirmed !== true) && (item.is_confirmed !== false),
            '_selected_all': item.is_confirmed === true,
        });

        const sliderCircleClassName = classNames({
            'b-slider__circle': true,
            '_selected_yes': (item.is_confirmed !== true) && (item.is_confirmed !== false),
            '_selected_all': item.is_confirmed === true,
        });

        const onClick = (item.is_confirmed === true) ? this.setConfirmed.bind(this, index, false) : this.setConfirmed.bind(this, index, true);

        return (
            <div className="b-block _xl-12-12 right">
                <div className="b-block__text b-block__text_no-header clearAfter">
                    <div className="b-checkbox left">
                        <State positive={!!item.is_auto_confirmed}/> БНСО
                    </div>
                    <div className="b-slider _options clearAfter right" onClick={onClick}>
                        <div className="b-slider__title">Факт подтвержден</div>

                        <div className="b-slider__control">
                            <div className={sliderClassName}/>
                            <div className={sliderCircleClassName}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderObjectVisit(item, itemIndex, objectVisit, index) {
        const vehicleUuid = objectVisit.vehicle_uuid;
        const vehicle = _.get(this.state.vehicles, vehicleUuid);

        return (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{vehicle ? _.get(_.find(this.state.vehicle_marks, {value: vehicle.vehicle_mark_uuid}), 'label') : ''}</td>
                <td>{_.get(vehicle, 'state_number')}</td>
                <td>{moment(objectVisit.date_from).format(formats.TIME_FULL)}</td>
                <td>{moment(objectVisit.date_to).format(formats.TIME_FULL)}</td>
                <td>{_.round(objectVisit.coordinates_from.latitude, 6)}, {_.round(objectVisit.coordinates_from.longitude, 6)}</td>
                <td>{_.round(objectVisit.coordinates_to.latitude, 6)}, {_.round(objectVisit.coordinates_to.longitude, 6)}</td>
            </tr>
        );
    }

    renderItemGeometry(itemIndex, isEditable, field, geometry, index) {
        const isIdle = this.get(`${field}.${itemIndex}.movement_type`) === 'idle';

        return (
            <div className="check-points">
                <Block title="Широта">
                    {this.textInput(`task.${field}.${itemIndex}.geometry.${index}.latitude`, {
                        disabled: !isEditable || (isIdle && ((itemIndex !== 0 && index === 0) || ((itemIndex !== this.state.task[field].length - 1) && (index === this.state.task[field][itemIndex].geometry.length - 1)))),
                    })}
                </Block>
                <Block title="Долгота">
                    {this.textInput(`task.${field}.${itemIndex}.geometry.${index}.longitude`, {
                        disabled: !isEditable || (isIdle && ((itemIndex !== 0 && index === 0) || ((itemIndex !== this.state.task[field].length - 1) && (index === this.state.task[field][itemIndex].geometry.length - 1)))),
                    })}
                </Block>
                <Block size="lg" title="Адрес">
                    {this.textInput(`task.${field}.${itemIndex}.geometry.${index}.address`, {
                        disabled: !isEditable || (isIdle && ((itemIndex !== 0 && index === 0) || ((itemIndex !== this.state.task[field].length - 1) && (index === this.state.task[field][itemIndex].geometry.length - 1)))),
                    })}
                </Block>

                {(isEditable && !((isIdle && ((itemIndex !== 0 && index === 0) || ((itemIndex !== this.state.task[field].length - 1) && (index === this.state.task[field][itemIndex].geometry.length - 1)))))) ? (
                    <Block size="xl">
                        <a className="remove-point" href="javascript:void(0)"
                           onClick={this.deleteGeometry.bind(this, itemIndex, index, field)}>- Удалить</a>
                    </Block>
                ) : null}
            </div>
        );
    }

    async fillIdle(field) {
        let task = this.state.task;
        if (task[field].length <= 1) {
            return;
        }

        for (let i = 1; i <= task[field].length - 1; i++) {
            let lastItem = task[field][i - 1];
            let currentItem = task[field][i];
            const lastCoordinates = this.getLastCoordinates(lastItem);
            const currentCoordinates = this.getFirstCoordinates(currentItem);

            if (lastCoordinates && currentCoordinates && ((_.floor(lastCoordinates[0], 6) !== _.floor(currentCoordinates[0], 6)) || (_.floor(lastCoordinates[1], 6) !== _.floor(currentCoordinates[1], 6)))) {
                if (task[field][i - 1].movement_type === 'idle') {
                    if (task[field][i - 1].geometry.length <= 1) {
                        task[field][i - 1].geometry.push({
                            is_address: true,
                            latitude: _.round(currentCoordinates[0], 6),
                            longitude: _.round(currentCoordinates[1], 6),
                        });
                    } else if (task[field][i - 1]) {
                        task[field][i - 1].geometry[task[field][i - 1].geometry.length - 1] = {
                            is_address: true,
                            latitude: _.round(currentCoordinates[0], 6),
                            longitude: _.round(currentCoordinates[1], 6),
                        };
                    }
                    await this.setState({task});
                    await this.calcPath(i - 1, field);
                } else if (task[field][i].movement_type === 'idle') {
                    task[field][i].geometry.splice(0, 1, {
                        is_address: true,
                        latitude: _.round(lastCoordinates[0], 6),
                        longitude: _.round(lastCoordinates[1], 6),
                    });
                    await this.setState({task});
                    await this.calcPath(i, field);
                } else {
                    currentItem = {
                        movement_type: 'idle',
                        geometry_type: 'other',
                        geometry: [
                            {
                                is_address: true,
                                latitude: _.round(lastCoordinates[0], 6),
                                longitude: _.round(lastCoordinates[1], 6),
                            },
                            {
                                is_address: true,
                                latitude: _.round(currentCoordinates[0], 6),
                                longitude: _.round(currentCoordinates[1], 6),
                            },
                        ],
                    };
                    task[field].splice(i, 0, currentItem);
                    await this.setState({
                        task,
                        expandedBlock: (i <= this.state.expandedBlock) ? (this.state.expandedBlock + 1) : this.state.expandedBlock,
                    });
                    await this.calcPath(i, field);
                    i++;
                }
            }
        }
    }

    getLastCoordinates(item) {
        if ((item.movement_type === 'idle') || (item.geometry_type === 'other')) {
            const geometry = _.last(item.geometry);
            if (!geometry) {
                return null;
            }
            return [
                geometry.latitude,
                geometry.longitude,
            ];
        }
        if (!item.geojson) {
            return null;
        }

        if (item.geojson.type === 'Point') {
            const coordinates = _.clone(item.geojson.coordinates);
            _.reverse(coordinates);
            return coordinates;
        } else {
            const coordinates = _.clone(_.last(item.geojson.coordinates));
            _.reverse(coordinates);
            return coordinates;
        }
    }

    getFirstCoordinates(item) {
        if ((item.movement_type === 'idle') || (item.geometry_type === 'other')) {
            const geometry = _.first(item.geometry);
            if (!geometry) {
                return null;
            }
            return [
                geometry.latitude,
                geometry.longitude,
            ];
        }
        if (!item.geojson) {
            return null;
        }

        if (item.geojson.type === 'Point') {
            const coordinates = _.clone(item.geojson.coordinates);
            _.reverse(coordinates);
            return coordinates;
        } else {
            const coordinates = _.clone(_.first(item.geojson.coordinates));
            _.reverse(coordinates);
            return coordinates;
        }
    }

    showMap(index) {
        this.setState({
            mapActive: index,
        });
    }

    hideMap() {
        this.setState({
            mapActive: null,
        });
    }

    renderMap() {
        const part = this.get(`items_fact.${this.state.mapActive}`);

        const from = moment(moment(this.get('date')).format(formats.DATE_URL) + ' ' + part.date_from + ' +0300');
        const to = moment(moment(this.get('date')).format(formats.DATE_URL) + ' ' + part.date_to + ' +0300');

        if (!from.isValid() || !to.isValid()) {
            return null;
        }

        return (
            <KursTaskMapHistory
                {...this.props}
                data={{
                    vehicleUuids: _.map(this.get('resources', []), 'vehicle_uuid'),
                    geojson: part.geojson,
                    debug: (this.props.location.query.debug) ? (part.debug || {}) : {},
                    from: from.format(formats.DATETIME_API),
                    to: to.format(formats.DATETIME_API),
                }}
                onClose={::this.hideMap}
            />
        );
    }

    hideAll() {
        let task = this.state.task;
        task.items = _.map(task.items, (item) => {
            item.hidden = true;
            return item;
        });
        if (task.items_fact) {
            task.items_fact = _.map(task.items_fact, (item) => {
                item.hidden = true;
                return item;
            });
        }
        this.setState({task});
    }

    showAll() {
        let task = this.state.task;
        task.items = _.map(task.items, (item) => {
            item.hidden = false;
            return item;
        });
        if (task.items_fact) {
            task.items_fact = _.map(task.items_fact, (item) => {
                item.hidden = false;
                return item;
            });
        }
        this.setState({task});
    }
}
