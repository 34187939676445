import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import classNames from 'classnames';

import LeftSide from './left-side';
import PageHeader from './page-header';

import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import currentUser from 'helpers/current-user';

import LogoutModal from 'components/modules/auth/logout-modal';
import NotificationsCounter from "components/ui/notifications_counter";
import * as alerts from "helpers/alerts";

import './page.less';
import AlertTemplate from "components/ui/alert/alert-template";
import {GlobalEvent} from "helpers/event-system";
import SIPButtonComponent from "components/ui/sip_button";
import Settings from 'settings';
import _ from "lodash";

/* todo: Хорошо бы вынести page на уровень выше т.к. у него есть зависимости из модулей */

@propTypes({
    pageId: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object,]),
    headerActions: PropTypes.array,
    headerContents: PropTypes.array,
    showHeader: PropTypes.bool,
    rootClassName: PropTypes.string,
    fullscreen: PropTypes.bool,
    withoutMenu: PropTypes.bool,
})

@defaultProps({
    pageId: 'Main',
    className: '',
    headerActions: [],
    headerContents: [],
    showHeader: true,
    rootClassName: '',
    fullscreen: false,
    withoutMenu: false,
})

export default class PageComponent extends Component {

    state = {
        showLogoutModal: false,
        disabledMenu: false,
    };

    constructor(props, context) {
        super(props, context);

        this.call = ::this._call;
    }

    _call(phone) {
        phone = phone.replace(/[^0-9]/g, '');
        phone = phone.replace(/^8/, '7');
        this.refs.sip && this.refs.sip.call(phone);
    }

    componentWillMount() {
        GlobalEvent().subscribe('call:start', this.call);
    }

    componentWillUnmount() {
        GlobalEvent().unsubscribe('call:start', this.call);
    }

    handleDisabledMenu() {
        this.setState({
            disabledMenu: true,
        });
    }

    render() {
        const menu = this.getMenu();

        const header = this.props.showHeader ? (
            <PageHeader
                ref="header"
                title={this.props.title}
                actions={this.props.headerActions}
                contents={this.props.headerContents}
            />
        ) : '';

        const className = classNames('page', this.props.showHeader ? 'with-page-header' : '', this.props.rootClassName, {
            'page-fullscreen': this.props.fullscreen,
            'page-with-alert': Settings.get('alert_message'),
        });

        return (
            <div className={className}>
                {this.renderAlertMessage()}
                {header}
                {!this.props.withoutMenu ? ([
                    <SIPButtonComponent key="sip" ref="sip"/>,
                    <LeftSide key="menu" menu={menu}/>,
                ]) : null}

                <div id={this.props.pageId} className={classNames('r-block _fullheight', this.props.className)}>
                    {this.props.children}
                </div>

                <LogoutModal
                    show={this.state.showLogoutModal}
                    onClose={::this.closeModal}
                />

                <Alert stack={{limit: 4}} position="bottom-right" timeout={60000} effect="slide"
                       contentTemplate={AlertTemplate}/>
            </div>
        );
    }

    renderAlertMessage() {
        const message = Settings.get('alert_message');
        if (!message) {
            return null;
        }

        return (
            <div className="system-alert-message">
                {message}
            </div>
        );
    }

    onClickLeftProfile(e) {
        e.preventDefault();

        this.setState({showLogoutModal: true});
    }

    closeModal() {
        this.setState({showLogoutModal: false});
    }

    logout() {

    }

    onDemoClick(e) {
        e.preventDefault();

        alerts.alert('Находится в разработке');
    }

    getMenu() {
        if (!currentUser.isSupervisor() && currentUser.can('com.rnis.portal.menu')) {
            return [
                {
                    text: 'Новости',
                    class: 'news',
                    route: '/portal/news',
                    permission: 'com.rnis.portal.news',
                },
                {
                    text: 'Вопрос-ответ',
                    class: 'faq',
                    route: '/portal/faq',
                    permission: 'com.rnis.portal.faq',
                },
                {
                    text: 'Страницы',
                    class: 'pages',
                    route: '/portal/pages',
                    permission: 'com.rnis.portal.page',
                },
                {
                    text: 'Выход',
                    class: 'profile',
                    onClick: ::this.onClickLeftProfile,
                    superclass: 'left-menu__item_profile',
                    route: '/logout',
                },
            ];
        }

        if (window.RNIS_SETTINGS.CUSTOMMENU === false || window.RNIS_SETTINGS.CUSTOMMENU === undefined || window.RNIS_SETTINGS.CUSTOMMENU === "undefined") {
            return [
                {
                    text: 'Стартовая страница',
                    class: 'home',
                    route: '/',
                },
                {
                    text: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '100']).text,
                    class: 'operator-system',
                    key: 'operator',
                    permission: ['com.rnis.auth.permission.component', 'operator'],
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/operator/personnel',
                            permission: ['com.rnis.auth.permission.user', 'operator'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            key: 'permissions',
                            submenu: [
                                {
                                    text: 'Роли',
                                    class: 'character',
                                    route: '/operator/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'operator'],
                                },
                                {
                                    text: 'Структурные права',
                                    class: 'structural-rights',
                                    route: '/operator/permissions/unit_permissions',
                                    permission: 'com.rnis.auth.permission.unit_permission',
                                },
                            ],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/operator/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'operator'],
                        },
                        {
                            text: 'Настройки',
                            class: 'settings',
                            route: '/settings',
                            permission: ['com.rnis.system.options', null, 'update'],
                        },
                        {
                            text: 'Диагностика БНСО',
                            class: 'diagnostics-bnso',
                            route: '/operator/bnso_check',
                            permission: ['com.rnis.bnso.check.link', 'operator'],
                        },
                        {
                            text: 'Комплекты регистрационных документов',
                            class: 'request-checkin',
                            route: '/operator/register_requests',
                            permission: ['com.rnis.portal.register_request', 'operator'],
                        },
                        {
                            text: 'Заявления на подключение',
                            class: 'request-connect',
                            route: '/operator/confirm_requests',
                            permission: ['com.rnis.portal.confirm_request', 'operator'],
                        },
                        {
                            text: 'Заявки из ESIA',
                            class: 'request-connect',
                            route: '/operator/esia_requests',
                            permission: ['com.rnis.portal.confirm_request', 'operator'],
                        },
                        {
                            text: 'Шаблоны документов',
                            class: 'document-templ',
                            route: '/operator/template_documents',
                            permission: ['com.rnis.portal.template_document', 'operator'],
                        },
                        {
                            text: 'Соглашения о взаимодействии',
                            class: 'agreement-coop',
                            route: '/operator/cooperation_agreements',
                            permission: ['com.rnis.portal.cooperation_agreements', 'operator'],
                        },
                        {
                            text: 'Корзина',
                            class: 'basket',
                            route: '/system/trash_logs',
                            permission: 'com.rnis.system.permission.trash',
                        },
                        {
                            text: 'Журнал событий',
                            class: 'event-log',
                            route: '/system/user_logs',
                            permission: 'com.rnis.system.permission.log',
                        },
                        {
                            text: 'Мониторинг пользователей',
                            class: 'monitoring',
                            route: '/system/activity',
                            permission: 'com.rnis.system.permission.activity',
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/operator/map',
                            permission: ['com.rnis.geo.monitoring', 'operator'],
                        },
                        /*{
                            text: 'Диагностика системы',
                            class: 'system-diagnostics',
                            href: 'https://rnis.regeora.ru:3000/dashboard/db/docker-and-system-monitoring?refresh=5m&orgId=1',
                            permission: 'com.rnis.auth.permission.grafana',
                        },*/
                    ],
                },
                {
                    text: 'Аналитическая платформа',
                    class: 'analytical-platform',
                    key: 'analytics',
                    permission: 'com.rnis.system.analytics_platform',
                    route: '/analytics/dashboard',
                    submenu: [
                        {
                            text: 'Сводные показатели',
                            class: 'dashboard',
                            route: '/analytics/dashboard',
                            permission: 'com.rnis.system.analytics_platform.summary',
                        },
                        {
                            text: 'Карта нарушений',
                            class: 'map',
                            route: '/analytics/map',
                            permission: 'com.rnis.system.analytics_platform.summary',
                        },
                        {
                            text: 'Пассажирские перевозки',
                            class: 'passenger',
                            route: '/analytics/passenger-transportation',
                            permission: 'com.rnis.system.analytics_platform.kiutr',
                        },
                        {
                            text: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text,
                            class: 'road',
                            route: '/analytics/kurs',
                            permission: 'com.rnis.system.analytics_platform.road',
                        },
                        {
                            text: 'Перевозки детей',
                            class: 'children',
                            route: '/analytics/children-transportation',
                            permission: 'com.rnis.system.analytics_platform.children',
                        },
                        /*{
                         text: 'Обслуживание дорог',
                         class: 'road',
                         route: '/analytics/road-maintenance'
                         },*/
                        {
                            text: 'Вывоз мусора',
                            class: 'waste',
                            route: '/analytics/garbage',
                            permission: 'com.rnis.system.analytics_platform.garbage',
                        },
                        {
                            text: 'ГУ ГАТН',
                            class: 'transport',
                            route: '/analytics/gatn',
                            permission: 'com.rnis.system.analytics_platform.control',
                        },
                        {
                            text: 'Мониторинг легковых такси',
                            class: 'taxi',
                            route: '/analytics/taxi',
                            permission: 'com.rnis.system.analytics_platform.taxi',
                        },
                        {
                            text: 'Транспорт сельского хозяйства',
                            class: 'agricultural',
                            route: '/analytics/agricultural',
                            permission: 'com.rnis.system.analytics_platform.agricultural',
                        },
                        {
                            text: 'Контроль медицинского транспорта',
                            class: 'medic',
                            route: '/analytics/medic',
                            permission: 'com.rnis.system.analytics_platform.medic',
                        },
                        {
                            text: 'Перевозки лесоматериалов',
                            class: 'timber',
                            route: '/analytics/timber',
                            permission: 'com.rnis.system.analytics_platform.timber',
                        },
                        {
                            text: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '332' ]).text,
                            class: 'dangerous',
                            route: '/analytics/dangerous',
                            permission: 'com.rnis.system.analytics_platform.dangerous',
                        },
                        /*{
                            text: 'Интерактивная карта',
                            class: 'interactive-map',
                            //route: '/analytics/interactive-map'
                            route: '/in-development',
                            onClick: ::this.onDemoClick
                        },*/
                    ],
                },
                {
                    text: 'Управление пассажирскими перевозками',
                    class: 'passenger',
                    key: 'passenger',
                    permission: ['com.rnis.auth.permission.component', 'kiutr'],
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/kiutr/personnel',
                            permission: ['com.rnis.auth.permission.user', 'kiutr'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/kiutr/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'kiutr'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/kiutr/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'kiutr'],
                        },
                        {
                            text: 'Паспорта маршрутов',
                            class: 'network',
                            route: '/kiutr/routes',
                            permission: ['com.rnis.geo.route', 'kiutr'],
                        },
                        {
                            text: 'Реестр маршрутов',
                            class: 'roster-routes',
                            route: '/kiutr/route_registries',
                            permission: ['com.rnis.geo.route_registry', 'kiutr'],
                        },
                        {
                            text: 'Расписания',
                            class: 'timetables',
                            route: '/kiutr/schedules',
                            permission: ['com.rnis.geo.route', 'kiutr'],
                        },
                        {
                            text: 'План-наряды',
                            class: 'outfit',
                            route: '/kiutr/orders',
                            permission: ['com.rnis.geo.daily_order', 'kiutr'],
                        },
                        {
                            text: window.RNIS_SETTINGS.rename_contracts ? 'Подрядные обязательства' : 'Контракты',
                            class: 'contracts',
                            route: '/kiutr/contracts',
                            permission: ['com.rnis.geo.contract', 'kiutr'],
                        },
                        {
                            text: 'Учет транспортной работы',
                            class: 'work-control',
                            route: '/kiutr/transport-work',
                            permission: ['com.rnis.geo.route_registry', 'kiutr'],
                        },
                        {
                            text: 'Контроль прохождения КП',
                            class: 'ellipse',
                            route: '/kiutr/ellipse',
                            permission: ['com.rnis.geo.ellipse', 'kiutr'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/kiutr/map',
                            permission: ['com.rnis.geo.monitoring', 'kiutr'],
                        },
                        {
                            text: 'Остановочные табло',
                            class: 'stopping-boards',
                            route: '/kiutr/displays/map',
                            permission: ['com.rnis.geo.display', 'kiutr'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/kiutr/violations',
                            permission: ['com.rnis.geo.speed-violations', 'kiutr'],
                        },
                    ],
                },
                {
                    text: 'Электронная маршрутная сеть',
                    class: 'interactive-map',
                    key: 'ems',
                    permission: ['com.rnis.auth.permission.component', 'ems'],
                    submenu: [
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/ems/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'ems'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/ems/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'ems'],
                        },
                        {
                            text: 'Паспорта маршрутов',
                            class: 'network',
                            route: '/ems/routes',
                            permission: ['com.rnis.geo.route', 'ems'],
                        },
                        {
                            text: 'Реестр маршрутов',
                            class: 'roster-routes',
                            route: '/ems/route_registries',
                            permission: ['com.rnis.geo.route_registry', 'ems'],
                        },
                        {
                            text: 'Расписания',
                            class: 'timetables',
                            route: '/ems/schedules',
                            permission: ['com.rnis.geo.route', 'ems'],
                        },
                        {
                            text: window.RNIS_SETTINGS.rename_contracts ? 'Подрядные обязательства' : 'Контракты',
                            class: 'contracts',
                            route: '/ems/contracts',
                            permission: ['com.rnis.geo.contract', 'ems'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/ems/map',
                            permission: ['com.rnis.geo.monitoring', 'ems'],
                        },
                    ],
                },
                {
                    text: 'Контроль пассажирских перевозок',
                    class: 'control-passenger-traffic',
                    key: 'passenger-control',
                    permission: ['com.rnis.auth.permission.component', 'kiutr-control'],
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/kiutr-control/personnel',
                            permission: ['com.rnis.auth.permission.user', 'kiutr-control'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/kiutr-control/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'kiutr-control'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/kiutr-control/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'kiutr-control'],
                        },
                        {
                            text: 'Паспорта маршрутов',
                            class: 'network',
                            route: '/kiutr-control/routes',
                            permission: ['com.rnis.geo.route', 'kiutr-control'],
                        },
                        {
                            text: 'Реестр маршрутов',
                            class: 'roster-routes',
                            route: '/kiutr-control/route_registries',
                            permission: ['com.rnis.geo.route_registry', 'kiutr-control'],
                        },
                        {
                            text: 'Расписания',
                            class: 'timetables',
                            route: '/kiutr-control/schedules',
                            permission: ['com.rnis.geo.route', 'kiutr-control'],
                        },
                        {
                            text: 'План-наряды',
                            class: 'outfit',
                            route: '/kiutr-control/orders',
                            permission: ['com.rnis.geo.daily_order', 'kiutr-control'],
                        },
                        {
                            text: window.RNIS_SETTINGS.rename_contracts ? 'Подрядные обязательства' : 'Контракты',
                            class: 'contracts',
                            route: '/kiutr-control/contracts',
                            permission: ['com.rnis.geo.contract', 'kiutr-control'],
                        },
                        {
                            text: 'Учет транспортной работы',
                            class: 'work-control',
                            route: '/kiutr-control/transport-work',
                            permission: ['com.rnis.geo.route_registry', 'kiutr-control'],
                        },
                        {
                            text: 'Контроль прохождения КП',
                            class: 'ellipse',
                            route: '/kiutr-control/ellipse',
                            permission: ['com.rnis.geo.ellipse', 'kiutr-control'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/kiutr-control/map',
                            permission: ['com.rnis.geo.monitoring', 'kiutr-control'],
                        },
                        {
                            text: 'Остановочные табло',
                            class: 'stopping-boards',
                            route: '/kiutr-control/displays/map',
                            permission: ['com.rnis.geo.display', 'kiutr-control'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/kiutr-control/violations',
                            permission: ['com.rnis.geo.speed-violations', 'kiutr-control'],
                        },
                    ],
                },
                {
                    text: _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text,
                    class: 'road',
                    key: 'road',
                    permission: ['com.rnis.auth.permission.component', 'road'],
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/road/personnel',
                            permission: ['com.rnis.auth.permission.user', 'road'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/road/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'road'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/road/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'road'],
                        },
                        {
                            text: `Ведение ${window.RNIS_SETTINGS.rename_contracts ? 'подрядных обязательств' : 'контрактов'}`,
                            class: 'contract-management',
                            route: '/road/contracts',
                            permission: ['com.rnis.kurs.contract', 'road'],
                        },
                        {
                            text: 'Нормативы',
                            class: 'standarts',
                            route: '/road/sto',
                            permission: ['com.rnis.kurs.sto', 'road'],
                        },
                        {
                            text: 'Внештатные ситуации',
                            class: 'super-situations',
                            route: '/road/supernumerary-situations',
                            permission: ['com.rnis.kurs.supernumerary_situation', 'road'],
                        },
                        {
                            text: 'Технологические карты',
                            class: 'tech-maps',
                            key: 'technocard',
                            permission: ['com.rnis.kurs.technocard', 'road'],
                            submenu: [
                                {
                                    text: 'Технологические карты',
                                    class: 'tech-maps',
                                    route: '/road/technocards',
                                    permission: ['com.rnis.kurs.technocard', 'road'],
                                },
                                {
                                    text: 'Назначение карт',
                                    class: 'mapping',
                                    route: '/road/technocard_assigns',
                                    permission: ['com.rnis.kurs.technocard_assign', 'road'],
                                },
                            ],
                        },
                        {
                            text: 'Контроль ТС',
                            class: 'vehicle-monitoring',
                            route: '/road/vehicles_control',
                            permission: ['com.rnis.kurs.task', 'road'],
                        },
                        {
                            text: 'Планирование работ',
                            class: 'timetables',
                            route: '/road/tasks',
                            permission: ['com.rnis.kurs.task', 'road'],
                        },
                        {
                            text: 'Распределение работ СКПДИ',
                            class: 'work-planning',
                            route: '/road/skpdi',
                            permission: ['com.rnis.kurs.task', 'road'],
                        },
                        {
                            text: 'Задания (плиточное представление)',
                            class: 'tiles',
                            route: '/road/tasks-day',
                            permission: ['com.rnis.kurs.task', 'road'],
                        },
                        {
                            text: 'Путевые листы',
                            class: 'waybills',
                            route: '/road/waybills',
                            permission: ['com.rnis.kurs.waybill', 'road'],
                        },
                        {
                            text: 'Программа ремонта',
                            class: 'repairs-program',
                            route: '/road/repair-program',
                            permission: ['com.rnis.kurs.repair_program', 'road'],
                        },
                        {
                            text: 'Плановые задачи',
                            class: 'planned-tasks',
                            route: '/road/plan/daily',
                            permission: ['com.rnis.kurs.task', 'road'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/road/map',
                            permission: ['com.rnis.geo.monitoring', 'road'],
                        },
                        {
                            text: 'Учет дорожной техники',
                            class: 'calculation-road-engineering',
                            route: '/road/vehicles',
                            permission: ['com.rnis.kurs.vehicle', 'road'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/road/violations',
                            permission: ['com.rnis.geo.speed-violations', 'road'],
                        },
                        {
                            text: 'Жалобы',
                            class: 'complaints',
                            route: '/road/complaints',
                        },
                    ],
                },
                {
                    text: 'Жилищно-коммунальное хозяйство',
                    class: 'utility',
                    key: 'utility',
                    permission: ['com.rnis.auth.permission.component', 'utility'],
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/utility/personnel',
                            permission: ['com.rnis.auth.permission.user', 'utility'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/utility/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'utility'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/utility/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'utility'],
                        },
                        {
                            text: `Ведение ${window.RNIS_SETTINGS.rename_contracts ? 'подрядных обязательств' : 'контрактов'}`,
                            class: 'contract-management',
                            route: '/utility/contracts',
                            permission: ['com.rnis.utility.contract', 'utility'],
                        },
                        {
                            text: 'Нормативы',
                            class: 'standarts',
                            route: '/utility/sto',
                            permission: ['com.rnis.utility.sto', 'road'],
                        },
                        {
                            text: 'Внештатные ситуации',
                            class: 'super-situations',
                            route: '/utility/supernumerary-situations',
                            permission: ['com.rnis.utility.supernumerary_situation', 'utility'],
                        },
                        {
                            text: 'Технологические карты',
                            class: 'tech-maps',
                            key: 'technocard',
                            permission: ['com.rnis.utility.technocard', 'utility'],
                            submenu: [
                                {
                                    text: 'Технологические карты',
                                    class: 'tech-maps',
                                    route: '/utility/technocards',
                                    permission: ['com.rnis.utility.technocard', 'utility'],
                                },
                                {
                                    text: 'Назначение карт',
                                    class: 'mapping',
                                    route: '/utility/technocard_assigns',
                                    permission: ['com.rnis.utility.technocard_assign', 'utility'],
                                },
                            ],
                        },
                        {
                            text: 'Контроль ТС',
                            class: 'vehicle-monitoring',
                            route: '/utility/vehicles_control',
                            permission: ['com.rnis.utility.task', 'utility'],
                        },
                        {
                            text: 'Планирование работ',
                            class: 'timetables',
                            route: '/utility/tasks',
                            permission: ['com.rnis.utility.task', 'utility'],
                        },
                        {
                            text: 'Распределение работ СКПДИ',
                            class: 'work-planning',
                            route: '/utility/skpdi',
                            permission: ['com.rnis.utility.task', 'utility'],
                        },
                        {
                            text: 'Задания (плиточное представление)',
                            class: 'tiles',
                            route: '/utility/tasks-day',
                            permission: ['com.rnis.utility.task', 'utility'],
                        },
                        {
                            text: 'Путевые листы',
                            class: 'waybills',
                            route: '/utility/waybills',
                            permission: ['com.rnis.utility.waybill', 'utility'],
                        },
                        {
                            text: 'Программа ремонта',
                            class: 'repairs-program',
                            route: '/utility/repair-program',
                            permission: ['com.rnis.utility.repair_program', 'utility'],
                        },
                        {
                            text: 'Плановые задачи',
                            class: 'planned-tasks',
                            route: '/utility/plan/daily',
                            permission: ['com.rnis.utility.task', 'utility'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/utility/map',
                            permission: ['com.rnis.geo.monitoring', 'utility'],
                        },
                        {
                            text: 'Учет дорожной техники',
                            class: 'calculation-road-engineering',
                            route: '/utility/vehicles',
                            permission: ['com.rnis.utility.vehicle', 'utility'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/utility/violations',
                            permission: ['com.rnis.geo.speed-violations', 'utility'],
                        },
                        {
                            text: 'Жалобы',
                            class: 'complaints',
                            route: '/utility/complaints',
                        },
                    ],
                },
                {
                    text: 'Коммунальная техника',
                    class: 'communal-equipment',
                    key: 'commdept',
                    permission: ['com.rnis.auth.permission.component', 'commdept'],
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/commdept/personnel',
                            permission: ['com.rnis.auth.permission.user', 'commdept'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/commdept/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'commdept'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/commdept/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'commdept'],
                        },
                        {
                            text: `Ведение ${window.RNIS_SETTINGS.rename_contracts ? 'подрядных обязательств' : 'контрактов'}`,
                            class: 'contract-management',
                            route: '/commdept/contracts',
                            permission: ['com.rnis.kurs.contract', 'commdept'],
                        },
                        {
                            text: 'Нормативы',
                            class: 'standarts',
                            route: '/commdept/sto',
                            permission: ['com.rnis.kurs.sto', 'commdept'],
                        },
                        {
                            text: 'Внештатные ситуации',
                            class: 'super-situations',
                            route: '/commdept/supernumerary-situations',
                            permission: ['com.rnis.kurs.supernumerary_situation', 'commdept'],
                        },
                        {
                            text: 'Технологические карты',
                            class: 'tech-maps',
                            key: 'technocard',
                            permission: ['com.rnis.kurs.technocard', 'commdept'],
                            submenu: [
                                {
                                    text: 'Технологические карты',
                                    class: 'tech-maps',
                                    route: '/commdept/technocards',
                                    permission: ['com.rnis.kurs.technocard', 'commdept'],
                                },
                                {
                                    text: 'Назначение карт',
                                    class: 'mapping',
                                    route: '/commdept/technocard_assigns',
                                    permission: ['com.rnis.kurs.technocard_assign', 'commdept'],
                                },
                            ],
                        },
                        {
                            text: 'Контроль ТС',
                            class: 'vehicle-monitoring',
                            route: '/commdept/vehicles_control',
                            permission: ['com.rnis.kurs.task', 'commdept'],
                        },
                        {
                            text: 'Планирование работ',
                            class: 'timetables',
                            route: '/commdept/tasks',
                            permission: ['com.rnis.kurs.task', 'commdept'],
                        },
                        {
                            text: 'Распределение работ СКПДИ',
                            class: 'work-planning',
                            route: '/commdept/skpdi',
                            permission: ['com.rnis.kurs.task', 'commdept'],
                        },
                        {
                            text: 'Задания (плиточное представление)',
                            class: 'tiles',
                            route: '/commdept/tasks-day',
                            permission: ['com.rnis.kurs.task', 'commdept'],
                        },
                        {
                            text: 'Путевые листы',
                            class: 'waybills',
                            route: '/commdept/waybills',
                            permission: ['com.rnis.kurs.waybill', 'commdept'],
                        },
                        {
                            text: 'Программа ремонта',
                            class: 'repairs-program',
                            route: '/commdept/repair-program',
                            permission: ['com.rnis.kurs.repair_program', 'commdept'],
                        },
                        {
                            text: 'Плановые задачи',
                            class: 'planned-tasks',
                            route: '/commdept/plan/daily',
                            permission: ['com.rnis.kurs.task', 'commdept'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/commdept/map',
                            permission: ['com.rnis.geo.monitoring', 'commdept'],
                        },
                        {
                            text: 'Учет дорожной техники',
                            class: 'calculation-road-engineering',
                            route: '/commdept/vehicles',
                            permission: ['com.rnis.kurs.vehicle', 'commdept'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/commdept/violations',
                            permission: ['com.rnis.geo.speed-violations', 'commdept'],
                        },
                    ],
                },
                {
                    text: 'Коммунальная техника',
                    class: 'communal-equipment',
                    key: 'communal',
                    permission: ['com.rnis.auth.permission.component', 'communal'],
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/communal/personnel',
                            permission: ['com.rnis.auth.permission.user', 'communal'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/communal/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'communal'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/communal/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'communal'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/communal/violations',
                            permission: ['com.rnis.gatn.gatn_violation', 'communal'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/communal/map',
                            permission: ['com.rnis.geo.monitoring', 'communal'],
                        },
                    ],
                },
                {
                    text: 'Управление перевозками детей',
                    class: 'children',
                    key: 'children',
                    permission: ['com.rnis.auth.permission.component', 'children'],
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/children/personnel',
                            permission: ['com.rnis.auth.permission.user', 'children'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/children/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'children'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/children/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'children'],
                        },
                        {
                            text: 'Паспорта маршрутов',
                            class: 'network',
                            route: '/children/routes',
                            permission: ['com.rnis.geo.route', 'children'],
                        },
                        {
                            text: 'Реестр маршрутов',
                            class: 'roster-routes',
                            route: '/children/route_registries',
                            permission: ['com.rnis.geo.route_registry', 'children'],
                        },
                        {
                            text: 'Расписания',
                            class: 'timetables',
                            route: '/children/schedules',
                            permission: ['com.rnis.geo.route', 'children'],
                        },
                        {
                            text: 'План-наряды',
                            class: 'outfit',
                            route: '/children/orders',
                            permission: ['com.rnis.geo.daily_order', 'children'],
                        },
                        {
                            text: window.RNIS_SETTINGS.rename_contracts ? 'Подрядные обязательства' : 'Контракты',
                            class: 'contracts',
                            route: '/children/contracts',
                            permission: ['com.rnis.geo.contract', 'children'],
                        },
                        {
                            text: 'Учет транспортной работы',
                            class: 'work-control',
                            route: '/children/transport-work',
                            permission: ['com.rnis.geo.route_registry', 'children'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/children/violations',
                            permission: ['com.rnis.gatn.gatn_violation', 'children'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/children/map',
                            permission: ['com.rnis.geo.monitoring', 'children'],
                        },
                    ],
                },
                {
                    text: 'Контроль вывоза мусора',
                    class: 'waste',
                    key: 'waste',
                    permission: ['com.rnis.auth.permission.component', 'garbage'],
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/garbage/personnel',
                            permission: ['com.rnis.auth.permission.user', 'garbage'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/garbage/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'garbage'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/garbage/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'garbage'],
                        },
                        {
                            text: 'Контроль работы',
                            class: 'control-work',
                            route: '/garbage/violations',
                            permission: ['com.rnis.dictionary.document', 'garbage'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/garbage/map',
                            permission: ['com.rnis.geo.monitoring', 'garbage'],
                        },
                        {
                            text: 'Карта вывоза ТБО',
                            class: 'interactive-map',
                            route: '/garbage/garbagemap',
                            permission: ['com.rnis.geo.monitoring', 'garbage'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/kiutr/violationscopy',
                            permission: ['com.rnis.geo.speed-violations', 'kiutr'], // fixme нужны права garbage - добавлено для демонстранции для Омска
                        }
                    ],
                },
                {
                    text: 'ГУ ГАТН',
                    class: 'transport',
                    key: 'control',
                    permission: ['com.rnis.auth.permission.component', 'control'],
                    route: '/control/map',
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/control/personnel',
                            permission: ['com.rnis.auth.permission.user', 'control'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/control/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'control'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/control/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'control'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/control/violations',
                            permission: ['com.rnis.gatn.gatn_violation', 'control'],
                        },
                        /*{
                            text: 'Контроль работы',
                            class: 'control-work',
                            route: '/control/work',
                            permission: ['com.rnis.gatn.gatn_violation', 'control'],
                        },*/
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/control/map',
                            permission: ['com.rnis.geo.monitoring', 'control'],
                        },
                    ],
                },
                {
                    text: 'Мониторинг легковых такси',
                    class: 'taxi',
                    key: 'taxi',
                    permission: ['com.rnis.auth.permission.component', 'taxi'],
                    route: '/taxi/map',
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/taxi/personnel',
                            permission: ['com.rnis.auth.permission.user', 'taxi'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/taxi/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'taxi'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/taxi/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'taxi'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/taxi/violations',
                            permission: ['com.rnis.gatn.gatn_violation', 'taxi'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/taxi/map',
                            permission: ['com.rnis.geo.monitoring', 'taxi'],
                        },
                    ],
                },
                {
                    text: 'Транспорт сельского хозяйства',
                    class: 'agricultural',
                    key: 'agricultural',
                    permission: ['com.rnis.auth.permission.component', 'agricultural'],
                    route: '/agricultural/map',
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/agricultural/personnel',
                            permission: ['com.rnis.auth.permission.user', 'agricultural'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/agricultural/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'agricultural'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/agricultural/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'agricultural'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/agricultural/violations',
                            permission: ['com.rnis.gatn.gatn_violation', 'agricultural'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/agricultural/map',
                            permission: ['com.rnis.geo.monitoring', 'agricultural'],
                        },
                    ],
                },
                {
                    text: 'Контроль медицинского транспорта',
                    class: 'medic',
                    key: 'medic',
                    permission: ['com.rnis.auth.permission.component', 'medic'],
                    route: '/medic/map',
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/medic/personnel',
                            permission: ['com.rnis.auth.permission.user', 'medic'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/medic/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'medic'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/medic/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'medic'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/medic/violations',
                            permission: ['com.rnis.gatn.gatn_violation', 'medic'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/medic/map',
                            permission: ['com.rnis.geo.monitoring', 'medic'],
                        },
                    ],
                },
                {
                    text: 'Перевозки лесоматериалов',
                    class: 'timber',
                    key: 'timber',
                    permission: ['com.rnis.auth.permission.component', 'timber'],
                    route: '/timber/map',
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/timber/personnel',
                            permission: ['com.rnis.auth.permission.user', 'timber'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/timber/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'timber'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/timber/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'timber'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/timber/violations',
                            permission: ['com.rnis.gatn.gatn_violation', 'timber'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/timber/map',
                            permission: ['com.rnis.geo.monitoring', 'timber'],
                        },
                    ],
                },
                {
                    text: 'Перевозки опасных грузов',
                    class: 'dangerous',
                    key: 'dangerous',
                    permission: ['com.rnis.auth.permission.component', 'dangerous'],
                    route: '/dangerous/map',
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/dangerous/personnel',
                            permission: ['com.rnis.auth.permission.user', 'dangerous'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/dangerous/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'dangerous'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/dangerous/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'dangerous'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/dangerous/violations',
                            permission: ['com.rnis.gatn.gatn_violation', 'dangerous'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/dangerous/map',
                            permission: ['com.rnis.geo.monitoring', 'dangerous'],
                        },
                    ],
                },
                {
                    text: 'Центр медицины и катастроф',
                    class: 'medicine',
                    key: 'medicine',
                    permission: ['com.rnis.auth.permission.component', 'medicine'],
                    route: '/medicine/map',
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/medicine/personnel',
                            permission: ['com.rnis.auth.permission.user', 'medicine'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/medicine/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'medicine'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/medicine/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'medicine'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/medicine/violations',
                            permission: ['com.rnis.gatn.gatn_violation', 'medicine'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/medicine/map',
                            permission: ['com.rnis.geo.monitoring', 'medicine'],
                        },
                    ],
                },
                {
                    text: 'Пожарно-спасательная служба',
                    class: 'emercom',
                    key: 'emercom',
                    permission: ['com.rnis.auth.permission.component', 'emercom'],
                    route: '/emercom/map',
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/emercom/personnel',
                            permission: ['com.rnis.auth.permission.user', 'emercom'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/emercom/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'emercom'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/emercom/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'emercom'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/emercom/violations',
                            permission: ['com.rnis.gatn.gatn_violation', 'emercom'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/emercom/map',
                            permission: ['com.rnis.geo.monitoring', 'emercom'],
                        },
                    ],
                },
                {
                    text: 'Сельское хозяйство',
                    class: 'farming',
                    key: 'farming',
                    permission: ['com.rnis.auth.permission.component', 'farming'],
                    route: '/farming/map',
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/farming/personnel',
                            permission: ['com.rnis.auth.permission.user', 'farming'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/farming/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'farming'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/farming/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'farming'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/farming/violations',
                            permission: ['com.rnis.gatn.gatn_violation', 'farming'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/farming/map',
                            permission: ['com.rnis.geo.monitoring', 'farming'],
                        },
                    ],
                },
                {
                    text: 'Лесное хозяйство',
                    class: 'forestry',
                    key: 'forestry',
                    permission: ['com.rnis.auth.permission.component', 'forestry'],
                    route: '/forestry/map',
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/forestry/personnel',
                            permission: ['com.rnis.auth.permission.user', 'forestry'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/forestry/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'forestry'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/forestry/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'forestry'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/forestry/violations',
                            permission: ['com.rnis.gatn.gatn_violation', 'forestry'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/forestry/map',
                            permission: ['com.rnis.geo.monitoring', 'forestry'],
                        },
                    ],
                },
                {
                    text: 'Служебный транспорт',
                    class: 'official',
                    key: 'official',
                    permission: ['com.rnis.auth.permission.component', 'official'],
                    route: '/official/map',
                    submenu: [
                        {
                            text: 'Кадровый учет',
                            class: 'accounting',
                            route: '/official/personnel',
                            permission: ['com.rnis.auth.permission.user', 'official'],
                        },
                        {
                            text: 'Права доступа',
                            class: 'management',
                            route: '/official/permissions/roles',
                            permission: ['com.rnis.auth.permission.role', 'official'],
                        },
                        {
                            text: 'Справочники',
                            class: 'dictionaries',
                            route: '/official/dictionaries',
                            permission: ['com.rnis.dictionary.document', 'official'],
                        },
                        {
                            text: 'Нарушения',
                            class: 'control-ts',
                            route: '/official/violations',
                            permission: ['com.rnis.gatn.gatn_violation', 'official'],
                        },
                        {
                            text: 'Мониторинг ТС',
                            class: 'map',
                            route: '/official/map',
                            permission: ['com.rnis.geo.monitoring', 'official'],
                        },
                    ],
                },
                {
                    text: 'Отчёты',
                    class: 'reports',
                    route: '/reports',
                    permission: 'com.rnis.reports.document',
                },
                {
                    text: 'Настройка событий',
                    class: 'events-notifications',
                    route: '/operator/notifications',
                    permission: 'com.rnis.notifications.event_rule',
                },
                {
                    text: 'Оповещения',
                    class: 'alerts',
                    counter: <NotificationsCounter/>,
                    route: '/notifications',
                },
                {
                    text: 'Прочее',
                    class: 'other',
                    key: 'misc',
                    submenu: [
                        {
                            text: `Портал`,
                            class: 'control-ts',
                            key: 'portal',
                            submenu: [
                                {
                                    text: 'Новости',
                                    class: 'news',
                                    route: '/portal/news',
                                    permission: 'com.rnis.portal.news',
                                },
                                {
                                    text: 'Вопрос-ответ',
                                    class: 'faq',
                                    route: '/portal/faq',
                                    permission: 'com.rnis.portal.faq',
                                },
                                {
                                    text: 'Страницы',
                                    class: 'pages',
                                    route: '/portal/pages',
                                    permission: 'com.rnis.portal.page',
                                },
                            ],
                        },
                        {
                            text: 'МП',
                            class: 'user-monitoring',
                            key: 'mobile',
                            submenu: [
                                {
                                    text: 'Контакты',
                                    class: 'contacts',
                                    route: '/mobile/contacts',
                                    permission: 'com.rnis.mobile.contact',
                                },
                                {
                                    text: 'Страницы',
                                    class: 'pages',
                                    route: '/mobile/mobile_pages',
                                    permission: 'com.rnis.mobile.mobile_page',
                                },
                            ],
                        },
                        {
                            text: 'Импорт ТС',
                            class: 'import',
                            route: '/system/vehicles/import',
                            permission: 'com.rnis.vehicles.vehicle.import',
                        },
                        currentUser.unitUuid() ? {
                            text: 'Профиль организации',
                            class: 'company-profile',
                            route: '/profile/unit',
                            permission: 'com.rnis.portal.unit_profile'
                        } : null,
                    ],
                },
                {
                    text: 'Профиль',
                    class: 'profile',
                    route: '/profile',
                },
                {
                    text: 'Справка',
                    class: 'help',
                    route: '/help',
                },
                {
                    text: 'Выход',
                    class: 'exit',
                    onClick: ::this.onClickLeftProfile,
                    superclass: 'left-menu__item_profile',
                    route: '/logout',
                },
            ]
        }

        if (window.RNIS_SETTINGS.CUSTOMMENU === true) {
            let a = window.RNIS_SETTINGS.CUSTOMMENUSTATE;
            return [
                {
                    text: 'Стартовая страница',
                    class: 'home',
                    route: '/',
                },

                _.find(a, ['id', '100']).show ?
                    {
                        text: _.find(a, ['id', '100']).text,
                        class: 'operator-system',
                        key: 'operator',
                        permission: ['com.rnis.auth.permission.component', 'operator'],
                        submenu: [
                            _.find(a, ['id', '100']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/operator/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'operator'],
                                } : null,
                            _.find(a, ['id', '100']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[1].text,
                                    class: 'management',
                                    key: 'permissions',
                                    submenu: [
                                        _.find(a, ['id', '100']).submenu[1].submenu[0].show ?
                                            {
                                                text: _.find(a, ['id', '100']).submenu[1].submenu[0].text,
                                                class: 'character',
                                                route: '/operator/permissions/roles',
                                                permission: ['com.rnis.auth.permission.role', 'operator'],
                                            } : null,
                                        _.find(a, ['id', '100']).submenu[1].submenu[1].show ?
                                            {
                                                text: _.find(a, ['id', '100']).submenu[1].submenu[1].text,
                                                class: 'structural-rights',
                                                route: '/operator/permissions/unit_permissions',
                                                permission: 'com.rnis.auth.permission.unit_permission',
                                            } : null,
                                    ],
                                } : null,
                            _.find(a, ['id', '100']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/operator/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'operator'],
                                } : null,
                            _.find(a, ['id', '100']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[3].text,
                                    class: 'settings',
                                    route: '/settings',
                                    permission: ['com.rnis.system.options', null, 'update'],
                                } : null,
                            _.find(a, ['id', '100']).submenu[4].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[4].text,
                                    class: 'diagnostics-bnso',
                                    route: '/operator/bnso_check',
                                    permission: ['com.rnis.bnso.check.link', 'operator'],
                                } : null,
                            _.find(a, ['id', '100']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[5].text,
                                    class: 'request-checkin',
                                    route: '/operator/register_requests',
                                    permission: ['com.rnis.portal.register_request', 'operator'],
                                } : null,
                            _.find(a, ['id', '100']).submenu[6].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[6].text,
                                    class: 'request-connect',
                                    route: '/operator/confirm_requests',
                                    permission: ['com.rnis.portal.confirm_request', 'operator'],
                                } : null,
                            _.find(a, ['id', '100']).submenu[7].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[7].text,
                                    class: 'request-connect',
                                    route: '/operator/esia_requests',
                                    permission: ['com.rnis.portal.confirm_request', 'operator'],
                                } : null,
                            _.find(a, ['id', '100']).submenu[8].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[8].text,
                                    class: 'document-templ',
                                    route: '/operator/template_documents',
                                    permission: ['com.rnis.portal.template_document', 'operator'],
                                } : null,
                            _.find(a, ['id', '100']).submenu[9].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[9].text,
                                    class: 'agreement-coop',
                                    route: '/operator/cooperation_agreements',
                                    permission: ['com.rnis.portal.cooperation_agreements', 'operator'],
                                } : null,
                            _.find(a, ['id', '100']).submenu[10].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[10].text,
                                    class: 'basket',
                                    route: '/system/trash_logs',
                                    permission: 'com.rnis.system.permission.trash',
                                } : null,
                            _.find(a, ['id', '100']).submenu[11].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[11].text,
                                    class: 'event-log',
                                    route: '/system/user_logs',
                                    permission: 'com.rnis.system.permission.log',
                                } : null,
                            _.find(a, ['id', '100']).submenu[12].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[12].text,
                                    class: 'monitoring',
                                    route: '/system/activity',
                                    permission: 'com.rnis.system.permission.activity',
                                } : null,
                            _.find(a, ['id', '100']).submenu[13].show ?
                                {
                                    text: _.find(a, ['id', '100']).submenu[13].text,
                                    class: 'map',
                                    route: '/operator/map',
                                    permission: ['com.rnis.geo.monitoring', 'operator'],
                                } : null,
                            /*{
                                text: 'Диагностика системы',
                                class: 'system-diagnostics',
                                href: 'https://rnis.regeora.ru:3000/dashboard/db/docker-and-system-monitoring?refresh=5m&orgId=1',
                                permission: 'com.rnis.auth.permission.grafana',
                            },*/
                        ],
                    } : null,

                _.find(a, ['id', '116']).show ?
                    {
                        text: _.find(a, ['id', '116']).text,
                        class: 'analytical-platform',
                        key: 'analytics',
                        permission: 'com.rnis.system.analytics_platform',
                        route: '/analytics/dashboard',
                        submenu: [
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '117') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '117') || {}).text,
                                    class: 'dashboard',
                                    route: '/analytics/dashboard',
                                    permission: 'com.rnis.system.analytics_platform.summary',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '342') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '342') || {}).text,
                                    class: 'map',
                                    route: '/analytics/map',
                                    permission: 'com.rnis.system.analytics_platform.summary',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '118') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '118') || {}).text,
                                    class: 'passenger',
                                    route: '/analytics/passenger-transportation',
                                    permission: 'com.rnis.system.analytics_platform.kiutr',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '119') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '119') || {}).text,
                                    class: 'road',
                                    route: '/analytics/kurs',
                                    permission: 'com.rnis.system.analytics_platform.road',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '120') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '120') || {}).text,
                                    class: 'children',
                                    route: '/analytics/children-transportation',
                                    permission: 'com.rnis.system.analytics_platform.children',
                                } : null,
                            /*{
                             text: 'Обслуживание дорог',
                             class: 'road',
                             route: '/analytics/road-maintenance'
                             },*/
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '122') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '122') || {}).text,
                                    class: 'waste',
                                    route: '/analytics/garbage',
                                    permission: 'com.rnis.system.analytics_platform.garbage',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '123') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '123') || {}).text,
                                    class: 'transport',
                                    route: '/analytics/gatn',
                                    permission: 'com.rnis.system.analytics_platform.control',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '328') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '328') || {}).text,
                                    class: 'taxi',
                                    route: '/analytics/taxi',
                                    permission: 'com.rnis.system.analytics_platform.taxi',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '329') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '329') || {}).text,
                                    class: 'agricultural',
                                    route: '/analytics/agricultural',
                                    permission: 'com.rnis.system.analytics_platform.agricultural',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '330') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '330') || {}).text,
                                    class: 'medic',
                                    route: '/analytics/medic',
                                    permission: 'com.rnis.system.analytics_platform.medic',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '331') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '331') || {}).text,
                                    class: 'timber',
                                    route: '/analytics/timber',
                                    permission: 'com.rnis.system.analytics_platform.timber',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '332') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '332') || {}).text,
                                    class: 'communal-equipment',
                                    route: '/analytics/communal',
                                    permission: 'com.rnis.system.analytics_platform.communal',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '340') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '340') || {}).text,
                                    class: 'dangerous',
                                    route: '/analytics/dangerous',
                                    permission: 'com.rnis.system.analytics_platform.dangerous',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '379') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '379') || {}).text,
                                    class: 'utility',
                                    route: '/analytics/utility',
                                    permission: 'com.rnis.system.analytics_platform.utility',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '414') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '414') || {}).text,
                                    class: 'official',
                                    route: '/analytics/official',
                                    permission: 'com.rnis.system.analytics_platform.official',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '407') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '407') || {}).text,
                                    class: 'emercom',
                                    route: '/analytics/emercom',
                                    permission: 'com.rnis.system.analytics_platform.emercom',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '400') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '400') || {}).text,
                                    class: 'farming',
                                    route: '/analytics/farming',
                                    permission: 'com.rnis.system.analytics_platform.farming',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '372') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '372') || {}).text,
                                    class: 'forestry',
                                    route: '/analytics/forestry',
                                    permission: 'com.rnis.system.analytics_platform.forestry',
                                } : null,
                            (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '365') || {}).show ?
                                {
                                    text: (_.find(a, ['id', '116']).submenu.find((sm) => sm.id === '365') || {}).text,
                                    class: 'medicine',
                                    route: '/analytics/medicine',
                                    permission: 'com.rnis.system.analytics_platform.medicine',
                                } : null,


                            /*{
                                text: 'Интерактивная карта',
                                class: 'interactive-map',
                                //route: '/analytics/interactive-map'
                                route: '/in-development',
                                onClick: ::this.onDemoClick
                            },*/
                        ],
                    } : null,
                _.find(a, ['id', '125']).show ?
                    {
                        text: _.find(a, ['id', '125']).text,
                        class: 'passenger',
                        key: 'passenger',
                        permission: ['com.rnis.auth.permission.component', 'kiutr'],
                        submenu: [
                            _.find(a, ['id', '125']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '125']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/kiutr/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'kiutr'],
                                } : null,
                            _.find(a, ['id', '125']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '125']).submenu[1].text,
                                    class: 'management',
                                    route: '/kiutr/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'kiutr'],
                                } : null,
                            _.find(a, ['id', '125']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '125']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/kiutr/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'kiutr'],
                                } : null,
                            _.find(a, ['id', '125']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '125']).submenu[3].text,
                                    class: 'network',
                                    route: '/kiutr/routes',
                                    permission: ['com.rnis.geo.route', 'kiutr'],
                                } : null,
                            _.find(a, ['id', '125']).submenu[4].show ?
                                {
                                    text: _.find(a, ['id', '125']).submenu[4].text,
                                    class: 'roster-routes',
                                    route: '/kiutr/route_registries',
                                    permission: ['com.rnis.geo.route_registry', 'kiutr'],
                                } : null,
                            _.find(a, ['id', '125']).submenu[5].show ?
                                {
                                    text: 'Расписания',
                                    class: 'timetables',
                                    route: '/kiutr/schedules',
                                    permission: ['com.rnis.geo.route', 'kiutr'],
                                } : null,
                            _.find(a, ['id', '125']).submenu[6].show ?
                                {
                                    text: _.find(a, ['id', '125']).submenu[6].text,
                                    class: 'outfit',
                                    route: '/kiutr/orders',
                                    permission: ['com.rnis.geo.daily_order', 'kiutr'],
                                } : null,
                            _.find(a, ['id', '125']).submenu[7].show ?
                                {
                                    text: _.find(a, ['id', '125']).submenu[7].text,
                                    class: 'contracts',
                                    route: '/kiutr/contracts',
                                    permission: ['com.rnis.geo.contract', 'kiutr'],
                                } : null,
                            _.find(a, ['id', '125']).submenu[8].show ?
                                {
                                    text: _.find(a, ['id', '125']).submenu[8].text,
                                    class: 'work-control',
                                    route: '/kiutr/transport-work',
                                    permission: ['com.rnis.geo.route_registry', 'kiutr'],
                                } : null,
                            _.find(a, ['id', '125']).submenu[9].show ?
                                {
                                    text: _.find(a, ['id', '125']).submenu[9].text,
                                    class: 'ellipse',
                                    route: '/kiutr/ellipse',
                                    permission: ['com.rnis.geo.ellipse', 'kiutr'],
                                } : null,
                            _.find(a, ['id', '125']).submenu[10].show ?
                                {
                                    text: _.find(a, ['id', '125']).submenu[10].text,
                                    class: 'map',
                                    route: '/kiutr/map',
                                    permission: ['com.rnis.geo.monitoring', 'kiutr'],
                                } : null,
                            _.find(a, ['id', '125']).submenu[11].show ?
                                {
                                    text: _.find(a, ['id', '125']).submenu[11].text,
                                    class: 'stopping-boards',
                                    route: '/kiutr/displays/map',
                                    permission: ['com.rnis.geo.display', 'kiutr'],
                                } : null,
                            _.find(a, ['id', '125']).submenu[12].show ?
                                {
                                    text: _.find(a, ['id', '125']).submenu[12].text,
                                    class: 'control-ts',
                                    route: '/kiutr/violations',
                                    permission: ['com.rnis.geo.speed-violations', 'kiutr'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '139']).show ?
                    {
                        text: _.find(a, ['id', '139']).text,
                        class: 'interactive-map',
                        key: 'ems',
                        permission: ['com.rnis.auth.permission.component', 'ems'],
                        submenu: [
                            _.find(a, ['id', '139']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '139']).submenu[0].text,
                                    class: 'management',
                                    route: '/ems/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'ems'],
                                } : null,
                            _.find(a, ['id', '139']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '139']).submenu[1].text,
                                    class: 'dictionaries',
                                    route: '/ems/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'ems'],
                                } : null,
                            _.find(a, ['id', '139']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '139']).submenu[2].text,
                                    class: 'network',
                                    route: '/ems/routes',
                                    permission: ['com.rnis.geo.route', 'ems'],
                                } : null,
                            _.find(a, ['id', '139']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '139']).submenu[3].text,
                                    class: 'roster-routes',
                                    route: '/ems/route_registries',
                                    permission: ['com.rnis.geo.route_registry', 'ems'],
                                } : null,
                            _.find(a, ['id', '139']).submenu[4].show ?
                                {
                                    text: _.find(a, ['id', '139']).submenu[4].text,
                                    class: 'timetables',
                                    route: '/ems/schedules',
                                    permission: ['com.rnis.geo.route', 'ems'],
                                } : null,
                            _.find(a, ['id', '139']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '139']).submenu[5].text,
                                    class: 'contracts',
                                    route: '/ems/contracts',
                                    permission: ['com.rnis.geo.contract', 'ems'],
                                } : null,
                            _.find(a, ['id', '139']).submenu[6].show ?
                                {
                                    text: _.find(a, ['id', '139']).submenu[6].text,
                                    class: 'map',
                                    route: '/ems/map',
                                    permission: ['com.rnis.geo.monitoring', 'ems'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '147']).show ?
                    {
                        text: _.find(a, ['id', '147']).text,
                        class: 'control-passenger-traffic',
                        key: 'passenger-control',
                        permission: ['com.rnis.auth.permission.component', 'kiutr-control'],
                        submenu: [
                            _.find(a, ['id', '147']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '147']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/kiutr-control/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'kiutr-control'],
                                } : null,
                            _.find(a, ['id', '147']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '147']).submenu[1].text,
                                    class: 'management',
                                    route: '/kiutr-control/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'kiutr-control'],
                                } : null,
                            _.find(a, ['id', '147']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '147']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/kiutr-control/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'kiutr-control'],
                                } : null,
                            _.find(a, ['id', '147']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '147']).submenu[3].text,
                                    class: 'network',
                                    route: '/kiutr-control/routes',
                                    permission: ['com.rnis.geo.route', 'kiutr-control'],
                                } : null,
                            _.find(a, ['id', '147']).submenu[4].show ?
                                {
                                    text: _.find(a, ['id', '147']).submenu[4].text,
                                    class: 'roster-routes',
                                    route: '/kiutr-control/route_registries',
                                    permission: ['com.rnis.geo.route_registry', 'kiutr-control'],
                                } : null,
                            _.find(a, ['id', '147']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '147']).submenu[5].text,
                                    class: 'timetables',
                                    route: '/kiutr-control/schedules',
                                    permission: ['com.rnis.geo.route', 'kiutr-control'],
                                } : null,
                            _.find(a, ['id', '147']).submenu[6].show ?
                                {
                                    text: _.find(a, ['id', '147']).submenu[6].text,
                                    class: 'outfit',
                                    route: '/kiutr-control/orders',
                                    permission: ['com.rnis.geo.daily_order', 'kiutr-control'],
                                } : null,
                            _.find(a, ['id', '147']).submenu[7].show ?
                                {
                                    text: _.find(a, ['id', '147']).submenu[7].text,
                                    class: 'contracts',
                                    route: '/kiutr-control/contracts',
                                    permission: ['com.rnis.geo.contract', 'kiutr-control'],
                                } : null,
                            _.find(a, ['id', '147']).submenu[8].show ?
                                {
                                    text: _.find(a, ['id', '147']).submenu[8].text,
                                    class: 'work-control',
                                    route: '/kiutr-control/transport-work',
                                    permission: ['com.rnis.geo.route_registry', 'kiutr-control'],
                                } : null,
                            _.find(a, ['id', '147']).submenu[9].show ?
                                {
                                    text: _.find(a, ['id', '147']).submenu[9].text,
                                    class: 'ellipse',
                                    route: '/kiutr-control/ellipse',
                                    permission: ['com.rnis.geo.ellipse', 'kiutr-control'],
                                } : null,
                            _.find(a, ['id', '147']).submenu[10].show ?
                                {
                                    text: _.find(a, ['id', '147']).submenu[10].text,
                                    class: 'map',
                                    route: '/kiutr-control/map',
                                    permission: ['com.rnis.geo.monitoring', 'kiutr-control'],
                                } : null,
                            _.find(a, ['id', '147']).submenu[11].show ?
                                {
                                    text: _.find(a, ['id', '147']).submenu[11].text,
                                    class: 'stopping-boards',
                                    route: '/kiutr-control/displays/map',
                                    permission: ['com.rnis.geo.display', 'kiutr-control'],
                                } : null,
                            _.find(a, ['id', '147']).submenu[12].show ?
                                {
                                    text: _.find(a, ['id', '147']).submenu[12].text,
                                    class: 'control-ts',
                                    route: '/kiutr-control/violations',
                                    permission: ['com.rnis.geo.speed-violations', 'kiutr-control'],
                                } : null,
                        ],
                    } : null,

                _.find(a, ['id', '161']).show ?
                    {
                        text: _.find(a, ['id', '161']).text,
                        class: 'road',
                        key: 'road',
                        permission: ['com.rnis.auth.permission.component', 'road'],
                        submenu: [
                            _.find(a, ['id', '161']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/road/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[1].text,
                                    class: 'management',
                                    route: '/road/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/road/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[3].text,
                                    class: 'contract-management',
                                    route: '/road/contracts',
                                    permission: ['com.rnis.kurs.contract', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[4].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[4].text,
                                    class: 'standarts',
                                    route: '/road/sto',
                                    permission: ['com.rnis.kurs.sto', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[5].text,
                                    class: 'super-situations',
                                    route: '/road/supernumerary-situations',
                                    permission: ['com.rnis.kurs.supernumerary_situation', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[6].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[6].text,
                                    class: 'tech-maps',
                                    key: 'technocard',
                                    permission: ['com.rnis.kurs.technocard', 'road'],
                                    submenu: [
                                        _.find(a, ['id', '161']).submenu[6].submenu[0].show ?
                                            {
                                                text: _.find(a, ['id', '161']).submenu[6].submenu[0].text,
                                                class: 'tech-maps',
                                                route: '/road/technocards',
                                                permission: ['com.rnis.kurs.technocard', 'road'],
                                            } : null,
                                        _.find(a, ['id', '161']).submenu[6].submenu[1].show ?
                                            {
                                                text: _.find(a, ['id', '161']).submenu[6].submenu[1].text,
                                                class: 'mapping',
                                                route: '/road/technocard_assigns',
                                                permission: ['com.rnis.kurs.technocard_assign', 'road'],
                                            } : null,
                                    ],
                                } : null,
                            _.find(a, ['id', '161']).submenu[7].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[7].text,
                                    class: 'vehicle-monitoring',
                                    route: '/road/vehicles_control',
                                    permission: ['com.rnis.kurs.task', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[8].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[8].text,
                                    class: 'timetables',
                                    route: '/road/tasks',
                                    permission: ['com.rnis.kurs.task', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[9].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[9].text,
                                    class: 'work-planning',
                                    route: '/road/skpdi',
                                    permission: ['com.rnis.kurs.task', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[10].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[10].text,
                                    class: 'tiles',
                                    route: '/road/tasks-day',
                                    permission: ['com.rnis.kurs.task', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[11].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[11].text,
                                    class: 'waybills',
                                    route: '/road/waybills',
                                    permission: ['com.rnis.kurs.waybill', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[12].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[12].text,
                                    class: 'repairs-program',
                                    route: '/road/repair-program',
                                    permission: ['com.rnis.kurs.repair_program', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[13].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[13].text,
                                    class: 'planned-tasks',
                                    route: '/road/plan/daily',
                                    permission: ['com.rnis.kurs.task', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[14].show ?
                                {
                                    text: window.RNIS_SETTINGS.CITY_MURMANSK ? 'Мониторинг ТС и СПУ' : _.find(a, ['id', '161']).submenu[14].text,
                                    class: 'map',
                                    route: '/road/map',
                                    permission: ['com.rnis.geo.monitoring', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[15].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[15].text,
                                    class: 'calculation-road-engineering',
                                    route: '/road/vehicles',
                                    permission: ['com.rnis.kurs.vehicle', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[16].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[16].text,
                                    class: 'control-ts',
                                    route: '/road/violations',
                                    permission: ['com.rnis.geo.speed-violations', 'road'],
                                } : null,
                            _.find(a, ['id', '161']).submenu[17].show ?
                                {
                                    text: _.find(a, ['id', '161']).submenu[17].text,
                                    class: 'complaints',
                                    superclass: 'left-menu__item_profile',
                                    route: '/road/complaints',
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '379']).show ?
                    {
                        text: _.find(a, ['id', '379']).text,
                        class: 'utility',
                        key: 'utility',
                        permission: ['com.rnis.auth.permission.component', 'road'],
                        submenu: [
                            _.find(a, ['id', '379']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/utility/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[1].text,
                                    class: 'management',
                                    route: '/utility/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/utility/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[3].text,
                                    class: 'contract-management',
                                    route: '/utility/contracts',
                                    permission: ['com.rnis.utility.contract', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[4].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[4].text,
                                    class: 'standarts',
                                    route: '/utility/sto',
                                    permission: ['com.rnis.utility.sto', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[5].text,
                                    class: 'super-situations',
                                    route: '/utility/supernumerary-situations',
                                    permission: ['com.rnis.utility.supernumerary_situation', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[6].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[6].text,
                                    class: 'tech-maps',
                                    key: 'technocard',
                                    permission: ['com.rnis.utility.technocard', 'utility'],
                                    submenu: [
                                        _.find(a, ['id', '379']).submenu[6].submenu[0].show ?
                                            {
                                                text: _.find(a, ['id', '379']).submenu[6].submenu[0].text,
                                                class: 'tech-maps',
                                                route: '/utility/technocards',
                                                permission: ['com.rnis.utility.technocard', 'utility'],
                                            } : null,
                                        _.find(a, ['id', '379']).submenu[6].submenu[1].show ?
                                            {
                                                text: _.find(a, ['id', '379']).submenu[6].submenu[1].text,
                                                class: 'mapping',
                                                route: '/utility/technocard_assigns',
                                                permission: ['com.rnis.utility.technocard_assign', 'utility'],
                                            } : null,
                                    ],
                                } : null,
                            _.find(a, ['id', '379']).submenu[7].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[7].text,
                                    class: 'vehicle-monitoring',
                                    route: '/utility/vehicles_control',
                                    permission: ['com.rnis.utility.task', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[8].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[8].text,
                                    class: 'timetables',
                                    route: '/utility/tasks',
                                    permission: ['com.rnis.utility.task', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[9].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[9].text,
                                    class: 'work-planning',
                                    route: '/utility/skpdi',
                                    permission: ['com.rnis.utility.task', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[10].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[10].text,
                                    class: 'tiles',
                                    route: '/utility/tasks-day',
                                    permission: ['com.rnis.utility.task', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[11].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[11].text,
                                    class: 'waybills',
                                    route: '/utility/waybills',
                                    permission: ['com.rnis.utility.waybill', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[12].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[12].text,
                                    class: 'repairs-program',
                                    route: '/utility/repair-program',
                                    permission: ['com.rnis.utility.repair_program', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[13].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[13].text,
                                    class: 'planned-tasks',
                                    route: '/utility/plan/daily',
                                    permission: ['com.rnis.utility.task', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[14].show ?
                                {
                                    text: window.RNIS_SETTINGS.CITY_MURMANSK ? 'Мониторинг ТС и СПУ' : _.find(a, ['id', '379']).submenu[14].text,
                                    class: 'map',
                                    route: '/utility/map',
                                    permission: ['com.rnis.geo.monitoring', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[15].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[15].text,
                                    class: 'calculation-road-engineering',
                                    route: '/utility/vehicles',
                                    permission: ['com.rnis.utility.vehicle', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[16].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[16].text,
                                    class: 'control-ts',
                                    route: '/utility/violations',
                                    permission: ['com.rnis.geo.speed-violations', 'utility'],
                                } : null,
                            _.find(a, ['id', '379']).submenu[17].show ?
                                {
                                    text: _.find(a, ['id', '379']).submenu[17].text,
                                    class: 'complaints',
                                    superclass: 'left-menu__item_profile',
                                    route: '/utility/complaints',
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '343']).show ?
                    {
                        text: _.find(a, ['id', '343']).text,
                        class: 'communal-equipment',
                        key: 'commdept',
                        permission: ['com.rnis.auth.permission.component', 'commdept'],
                        submenu: [
                            _.find(a, ['id', '343']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/commdept/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[1].text,
                                    class: 'management',
                                    route: '/commdept/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/commdept/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[3].text,
                                    class: 'contract-management',
                                    route: '/commdept/contracts',
                                    permission: ['com.rnis.kurs.contract', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[4].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[4].text,
                                    class: 'standarts',
                                    route: '/commdept/sto',
                                    permission: ['com.rnis.kurs.sto', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[5].text,
                                    class: 'super-situations',
                                    route: '/commdept/supernumerary-situations',
                                    permission: ['com.rnis.kurs.supernumerary_situation', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[6].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[6].text,
                                    class: 'tech-maps',
                                    key: 'technocard',
                                    permission: ['com.rnis.kurs.technocard', 'commdept'],
                                    submenu: [
                                        _.find(a, ['id', '343']).submenu[6].submenu[0].show ?
                                            {
                                                text: _.find(a, ['id', '343']).submenu[6].submenu[0].text,
                                                class: 'tech-maps',
                                                route: '/commdept/technocards',
                                                permission: ['com.rnis.kurs.technocard', 'commdept'],
                                            } : null,
                                        _.find(a, ['id', '343']).submenu[6].submenu[1].show ?
                                            {
                                                text: _.find(a, ['id', '343']).submenu[6].submenu[1].text,
                                                class: 'mapping',
                                                route: '/commdept/technocard_assigns',
                                                permission: ['com.rnis.kurs.technocard_assign', 'commdept'],
                                            } : null,
                                    ],
                                } : null,
                            _.find(a, ['id', '343']).submenu[7].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[7].text,
                                    class: 'vehicle-monitoring',
                                    route: '/commdept/vehicles_control',
                                    permission: ['com.rnis.kurs.task', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[8].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[8].text,
                                    class: 'timetables',
                                    route: '/commdept/tasks',
                                    permission: ['com.rnis.kurs.task', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[9].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[9].text,
                                    class: 'work-planning',
                                    route: '/commdept/skpdi',
                                    permission: ['com.rnis.kurs.task', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[10].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[10].text,
                                    class: 'tiles',
                                    route: '/commdept/tasks-day',
                                    permission: ['com.rnis.kurs.task', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[11].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[11].text,
                                    class: 'waybills',
                                    route: '/commdept/waybills',
                                    permission: ['com.rnis.kurs.waybill', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[12].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[12].text,
                                    class: 'repairs-program',
                                    route: '/commdept/repair-program',
                                    permission: ['com.rnis.kurs.repair_program', 'road'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[13].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[13].text,
                                    class: 'planned-tasks',
                                    route: '/commdept/plan/daily',
                                    permission: ['com.rnis.kurs.task', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[14].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[14].text,
                                    class: 'map',
                                    route: '/commdept/map',
                                    permission: ['com.rnis.geo.monitoring', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[15].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[15].text,
                                    class: 'calculation-road-engineering',
                                    route: '/commdept/vehicles',
                                    permission: ['com.rnis.kurs.vehicle', 'commdept'],
                                } : null,
                            _.find(a, ['id', '343']).submenu[16].show ?
                                {
                                    text: _.find(a, ['id', '343']).submenu[16].text,
                                    class: 'control-ts',
                                    route: '/commdept/violations',
                                    permission: ['com.rnis.geo.speed-violations', 'commdept'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '181']).show ?
                    {
                        text: _.find(a, ['id', '181']).text,
                        class: 'communal-equipment',
                        key: 'communal',
                        permission: ['com.rnis.auth.permission.component', 'communal'],
                        submenu: [
                            _.find(a, ['id', '181']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '181']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/communal/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'communal'],
                                } : null,
                            _.find(a, ['id', '181']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '181']).submenu[1].text,
                                    class: 'management',
                                    route: '/communal/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'communal'],
                                } : null,
                            _.find(a, ['id', '181']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '181']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/communal/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'communal'],
                                } : null,
                            _.find(a, ['id', '181']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '181']).submenu[3].text,
                                    class: 'map',
                                    route: '/communal/map',
                                    permission: ['com.rnis.geo.monitoring', 'communal'],
                                } : null,
                            _.find(a, ['id', '181']).submenu[4].show ?
                                {
                                    text: _.find(a, ['id', '181']).submenu[4].text,
                                    class: 'control-ts',
                                    route: '/communal/violations',
                                    permission: ['com.rnis.gatn.gatn_violation', 'communal'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '186']).show ?
                    {
                        text: _.find(a, ['id', '186']).text,
                        class: 'children',
                        key: 'children',
                        permission: ['com.rnis.auth.permission.component', 'children'],
                        submenu: [
                            _.find(a, ['id', '186']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '186']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/children/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'children'],
                                } : null,
                            _.find(a, ['id', '186']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '186']).submenu[1].text,
                                    class: 'management',
                                    route: '/children/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'children'],
                                } : null,
                            _.find(a, ['id', '186']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '186']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/children/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'children'],
                                } : null,
                            _.find(a, ['id', '186']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '186']).submenu[3].text,
                                    class: 'network',
                                    route: '/children/routes',
                                    permission: ['com.rnis.geo.route', 'children'],
                                } : null,
                            _.find(a, ['id', '186']).submenu[4].show ?
                                {
                                    text: _.find(a, ['id', '186']).submenu[4].text,
                                    class: 'roster-routes',
                                    route: '/children/route_registries',
                                    permission: ['com.rnis.geo.route_registry', 'children'],
                                } : null,
                            _.find(a, ['id', '186']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '186']).submenu[5].text,
                                    class: 'timetables',
                                    route: '/children/schedules',
                                    permission: ['com.rnis.geo.route', 'children'],
                                } : null,
                            _.find(a, ['id', '186']).submenu[6].show ?
                                {
                                    text: _.find(a, ['id', '186']).submenu[6].text,
                                    class: 'outfit',
                                    route: '/children/orders',
                                    permission: ['com.rnis.geo.daily_order', 'children'],
                                } : null,
                            _.find(a, ['id', '186']).submenu[7].show ?
                                {
                                    text: _.find(a, ['id', '186']).submenu[7].text,
                                    class: 'contracts',
                                    route: '/children/contracts',
                                    permission: ['com.rnis.geo.contract', 'children'],
                                } : null,
                            _.find(a, ['id', '186']).submenu[8].show ?
                                {
                                    text: _.find(a, ['id', '186']).submenu[8].text,
                                    class: 'work-control',
                                    route: '/children/transport-work',
                                    permission: ['com.rnis.geo.route_registry', 'children'],
                                } : null,
                            _.find(a, ['id', '186']).submenu[9].show ?
                                {
                                    text: _.find(a, ['id', '186']).submenu[9].text,
                                    class: 'control-ts',
                                    route: '/children/violations',
                                    permission: ['com.rnis.gatn.gatn_violation', 'children'],
                                } : null,
                            _.find(a, ['id', '186']).submenu[10].show ?
                                {
                                    text: _.find(a, ['id', '186']).submenu[10].text,
                                    class: 'map',
                                    route: '/children/map',
                                    permission: ['com.rnis.geo.monitoring', 'children'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '198']).show ?
                    {
                        text: _.find(a, ['id', '198']).text,
                        class: 'waste',
                        key: 'waste',
                        permission: ['com.rnis.auth.permission.component', 'garbage'],
                        submenu: [
                            _.find(a, ['id', '198']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '198']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/garbage/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'garbage'],
                                } : null,
                            _.find(a, ['id', '198']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '198']).submenu[1].text,
                                    class: 'management',
                                    route: '/garbage/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'garbage'],
                                } : null,
                            _.find(a, ['id', '198']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '198']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/garbage/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'garbage'],
                                } : null,
                            _.find(a, ['id', '198']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '198']).submenu[3].text,
                                    class: 'control-work',
                                    route: '/garbage/violations',
                                    permission: ['com.rnis.dictionary.document', 'garbage'],
                                } : null,
                            _.find(a, ['id', '198']).submenu[4].show ?
                                {
                                    text: _.find(a, ['id', '198']).submenu[4].text,
                                    class: 'map',
                                    route: '/garbage/map',
                                    permission: ['com.rnis.geo.monitoring', 'garbage'],
                                } : null,
                            _.find(a, ['id', '198']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '198']).submenu[5].text,
                                    class: 'interactive-map',
                                    route: '/garbage/garbagemap',
                                    permission: ['com.rnis.geo.monitoring', 'garbage'],
                                } : null,
                            _.find(a, ['id', '198']).submenu[6].show ?
                                {
                                    text: _.find(a, ['id', '198']).submenu[6].text,
                                    class: 'control-ts',
                                    route: '/garbage/violationscopy',
                                    permission: ['com.rnis.geo.speed-violations', 'kiutr'], // fixme нужны права garbage - добавлено для демонстранции для Омска
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '205']).show ?
                    {
                        text: _.find(a, ['id', '205']).text,
                        class: 'transport',
                        key: 'control',
                        permission: ['com.rnis.auth.permission.component', 'control'],
                        route: '/control/map',
                        submenu: [
                            _.find(a, ['id', '205']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '205']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/control/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'control'],
                                } : null,
                            _.find(a, ['id', '205']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '205']).submenu[1].text,
                                    class: 'management',
                                    route: '/control/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'control'],
                                } : null,
                            _.find(a, ['id', '205']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '205']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/control/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'control'],
                                } : null,
                            _.find(a, ['id', '205']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '205']).submenu[3].text,
                                    class: 'control-ts',
                                    route: '/control/violations',
                                    permission: ['com.rnis.gatn.gatn_violation', 'control'],
                                } : null,
                            /*  _.find(a, ['id', '205']).submenu[4].show ?
                                  {
                                      text: _.find(a, ['id', '205']).submenu[4].text,
                                      class: 'control-work',
                                      route: '/control/work',
                                      permission: ['com.rnis.gatn.gatn_violation', 'control'],
                                  } : null,*/
                            _.find(a, ['id', '205']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '205']).submenu[5].text,
                                    class: 'map',
                                    route: '/control/map',
                                    permission: ['com.rnis.geo.monitoring', 'control'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '300']).show ?
                    {
                        text: _.find(a, ['id', '300']).text,
                        class: 'taxi',
                        key: 'taxi',
                        permission: ['com.rnis.auth.permission.component', 'taxi'],
                        route: '/taxi/map',
                        submenu: [
                            _.find(a, ['id', '300']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '300']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/taxi/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'taxi'],
                                } : null,
                            _.find(a, ['id', '300']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '300']).submenu[1].text,
                                    class: 'management',
                                    route: '/taxi/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'taxi'],
                                } : null,
                            _.find(a, ['id', '300']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '300']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/taxi/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'taxi'],
                                } : null,
                            _.find(a, ['id', '300']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '300']).submenu[3].text,
                                    class: 'control-ts',
                                    route: '/taxi/violations',
                                    permission: ['com.rnis.gatn.gatn_violation', 'taxi'],
                                } : null,
                            /* _.find(a, ['id', '300']).submenu[4].show ?
                                 {
                                     text: _.find(a, ['id', '300']).submenu[4].text,
                                     class: 'control-work',
                                     route: '/taxi/work',
                                     permission: ['com.rnis.gatn.gatn_violation', 'taxi'],
                                 } : null,*/
                            _.find(a, ['id', '300']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '300']).submenu[5].text,
                                    class: 'map',
                                    route: '/taxi/map',
                                    permission: ['com.rnis.geo.monitoring', 'taxi'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '307']).show ?
                    {
                        text: _.find(a, ['id', '307']).text,
                        class: 'agricultural',
                        key: 'agricultural',
                        permission: ['com.rnis.auth.permission.component', 'agricultural'],
                        route: '/agricultural/map',
                        submenu: [
                            _.find(a, ['id', '307']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '307']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/agricultural/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'agricultural'],
                                } : null,
                            _.find(a, ['id', '307']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '307']).submenu[1].text,
                                    class: 'management',
                                    route: '/agricultural/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'agricultural'],
                                } : null,
                            _.find(a, ['id', '307']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '307']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/agricultural/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'agricultural'],
                                } : null,
                            _.find(a, ['id', '307']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '307']).submenu[3].text,
                                    class: 'control-ts',
                                    route: '/agricultural/violations',
                                    permission: ['com.rnis.gatn.gatn_violation', 'agricultural'],
                                } : null,
                            /*  _.find(a, ['id', '307']).submenu[4].show ?
                                  {
                                      text: _.find(a, ['id', '307']).submenu[4].text,
                                      class: 'control-work',
                                      route: '/agricultural/work',
                                      permission: ['com.rnis.gatn.gatn_violation', 'agricultural'],
                                  } : null,*/
                            _.find(a, ['id', '307']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '307']).submenu[5].text,
                                    class: 'map',
                                    route: '/agricultural/map',
                                    permission: ['com.rnis.geo.monitoring', 'agricultural'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '314']).show ?
                    {
                        text: _.find(a, ['id', '314']).text,
                        class: 'medic',
                        key: 'medic',
                        permission: ['com.rnis.auth.permission.component', 'medic'],
                        route: '/medic/map',
                        submenu: [
                            _.find(a, ['id', '314']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '314']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/medic/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'medic'],
                                } : null,
                            _.find(a, ['id', '314']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '314']).submenu[1].text,
                                    class: 'management',
                                    route: '/medic/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'medic'],
                                } : null,
                            _.find(a, ['id', '314']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '314']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/medic/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'medic'],
                                } : null,
                            _.find(a, ['id', '314']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '314']).submenu[3].text,
                                    class: 'control-ts',
                                    route: '/medic/violations',
                                    permission: ['com.rnis.gatn.gatn_violation', 'medic'],
                                } : null,
                            /*  _.find(a, ['id', '314']).submenu[4].show ?
                                  {
                                      text: _.find(a, ['id', '314']).submenu[4].text,
                                      class: 'control-work',
                                      route: '/medic/work',
                                      permission: ['com.rnis.gatn.gatn_violation', 'medic'],
                                  } : null,*/
                            _.find(a, ['id', '314']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '314']).submenu[5].text,
                                    class: 'map',
                                    route: '/medic/map',
                                    permission: ['com.rnis.geo.monitoring', 'medic'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '321']).show ?
                    {
                        text: _.find(a, ['id', '321']).text,
                        class: 'timber',
                        key: 'timber',
                        permission: ['com.rnis.auth.permission.component', 'timber'],
                        route: '/timber/map',
                        submenu: [
                            _.find(a, ['id', '321']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '321']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/timber/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'timber'],
                                } : null,
                            _.find(a, ['id', '321']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '321']).submenu[1].text,
                                    class: 'management',
                                    route: '/timber/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'timber'],
                                } : null,
                            _.find(a, ['id', '321']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '321']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/timber/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'timber'],
                                } : null,
                            _.find(a, ['id', '321']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '321']).submenu[3].text,
                                    class: 'control-ts',
                                    route: '/timber/violations',
                                    permission: ['com.rnis.gatn.gatn_violation', 'timber'],
                                } : null,
                            /*  _.find(a, ['id', '314']).submenu[4].show ?
                                  {
                                      text: _.find(a, ['id', '314']).submenu[4].text,
                                      class: 'control-work',
                                      route: '/medic/work',
                                      permission: ['com.rnis.gatn.gatn_violation', 'medic'],
                                  } : null,*/
                            _.find(a, ['id', '321']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '321']).submenu[5].text,
                                    class: 'map',
                                    route: '/timber/map',
                                    permission: ['com.rnis.geo.monitoring', 'timber'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '332']).show ?
                    {
                        text: _.find(a, ['id', '332']).text,
                        class: 'dangerous',
                        key: 'dangerous',
                        permission: ['com.rnis.auth.permission.component', 'dangerous'],
                        route: '/dangerous/map',
                        submenu: [
                            _.find(a, ['id', '332']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '332']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/dangerous/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'dangerous'],
                                } : null,
                            _.find(a, ['id', '332']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '332']).submenu[1].text,
                                    class: 'management',
                                    route: '/dangerous/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'dangerous'],
                                } : null,
                            _.find(a, ['id', '332']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '332']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/dangerous/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'dangerous'],
                                } : null,
                            _.find(a, ['id', '332']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '332']).submenu[3].text,
                                    class: 'control-ts',
                                    route: '/dangerous/violations',
                                    permission: ['com.rnis.gatn.gatn_violation', 'dangerous'],
                                } : null,
                            /*  _.find(a, ['id', '314']).submenu[4].show ?
                                  {
                                      text: _.find(a, ['id', '314']).submenu[4].text,
                                      class: 'control-work',
                                      route: '/medic/work',
                                      permission: ['com.rnis.gatn.gatn_violation', 'medic'],
                                  } : null,*/
                            _.find(a, ['id', '332']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '332']).submenu[5].text,
                                    class: 'map',
                                    route: '/dangerous/map',
                                    permission: ['com.rnis.geo.monitoring', 'dangerous'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '400']).show ?
                    {
                        text: _.find(a, ['id', '400']).text,
                        class: 'farming',
                        key: 'farming',
                        permission: ['com.rnis.auth.permission.component', 'farming'],
                        route: '/farming/map',
                        submenu: [
                            _.find(a, ['id', '400']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '400']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/farming/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'farming'],
                                } : null,
                            _.find(a, ['id', '400']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '400']).submenu[1].text,
                                    class: 'management',
                                    route: '/farming/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'farming'],
                                } : null,
                            _.find(a, ['id', '400']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '400']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/farming/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'farming'],
                                } : null,
                            _.find(a, ['id', '400']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '400']).submenu[3].text,
                                    class: 'control-ts',
                                    route: '/farming/violations',
                                    permission: ['com.rnis.gatn.gatn_violation', 'farming'],
                                } : null,
                            _.find(a, ['id', '400']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '400']).submenu[5].text,
                                    class: 'map',
                                    route: '/farming/map',
                                    permission: ['com.rnis.geo.monitoring', 'farming'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '365']).show ?
                    {
                        text: _.find(a, ['id', '365']).text,
                        class: 'medicine',
                        key: 'medicine',
                        permission: ['com.rnis.auth.permission.component', 'medicine'],
                        route: '/medicine/map',
                        submenu: [
                            _.find(a, ['id', '365']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '365']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/medicine/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'medicine'],
                                } : null,
                            _.find(a, ['id', '365']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '365']).submenu[1].text,
                                    class: 'management',
                                    route: '/medicine/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'medicine'],
                                } : null,
                            _.find(a, ['id', '365']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '365']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/medicine/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'medicine'],
                                } : null,
                            _.find(a, ['id', '365']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '365']).submenu[3].text,
                                    class: 'control-ts',
                                    route: '/medicine/violations',
                                    permission: ['com.rnis.gatn.gatn_violation', 'medicine'],
                                } : null,
                            _.find(a, ['id', '365']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '365']).submenu[5].text,
                                    class: 'map',
                                    route: '/medicine/map',
                                    permission: ['com.rnis.geo.monitoring', 'medicine'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '407']).show ?
                    {
                        text: _.find(a, ['id', '407']).text,
                        class: 'emercom',
                        key: 'emercom',
                        permission: ['com.rnis.auth.permission.component', 'emercom'],
                        route: '/emercom/map',
                        submenu: [
                            _.find(a, ['id', '407']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '407']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/emercom/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'emercom'],
                                } : null,
                            _.find(a, ['id', '407']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '407']).submenu[1].text,
                                    class: 'management',
                                    route: '/emercom/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'emercom'],
                                } : null,
                            _.find(a, ['id', '407']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '407']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/emercom/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'emercom'],
                                } : null,
                            _.find(a, ['id', '407']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '407']).submenu[3].text,
                                    class: 'control-ts',
                                    route: '/emercom/violations',
                                    permission: ['com.rnis.gatn.gatn_violation', 'emercom'],
                                } : null,
                            _.find(a, ['id', '407']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '407']).submenu[5].text,
                                    class: 'map',
                                    route: '/emercom/map',
                                    permission: ['com.rnis.geo.monitoring', 'emercom'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '372']).show ?
                    {
                        text: _.find(a, ['id', '372']).text,
                        class: 'forestry',
                        key: 'forestry',
                        permission: ['com.rnis.auth.permission.component', 'forestry'],
                        route: '/forestry/map',
                        submenu: [
                            _.find(a, ['id', '372']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '372']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/forestry/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'forestry'],
                                } : null,
                            _.find(a, ['id', '372']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '372']).submenu[1].text,
                                    class: 'management',
                                    route: '/forestry/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'forestry'],
                                } : null,
                            _.find(a, ['id', '372']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '372']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/forestry/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'forestry'],
                                } : null,
                            _.find(a, ['id', '372']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '372']).submenu[3].text,
                                    class: 'control-ts',
                                    route: '/forestry/violations',
                                    permission: ['com.rnis.gatn.gatn_violation', 'forestry'],
                                } : null,
                            _.find(a, ['id', '372']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '372']).submenu[5].text,
                                    class: 'map',
                                    route: '/forestry/map',
                                    permission: ['com.rnis.geo.monitoring', 'forestry'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '414']).show ?
                    {
                        text: _.find(a, ['id', '414']).text,
                        class: 'official',
                        key: 'official',
                        permission: ['com.rnis.auth.permission.component', 'official'],
                        route: '/official/map',
                        submenu: [
                            _.find(a, ['id', '414']).submenu[0].show ?
                                {
                                    text: _.find(a, ['id', '414']).submenu[0].text,
                                    class: 'accounting',
                                    route: '/official/personnel',
                                    permission: ['com.rnis.auth.permission.user', 'official'],
                                } : null,
                            _.find(a, ['id', '414']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '414']).submenu[1].text,
                                    class: 'management',
                                    route: '/official/permissions/roles',
                                    permission: ['com.rnis.auth.permission.role', 'official'],
                                } : null,
                            _.find(a, ['id', '414']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '414']).submenu[2].text,
                                    class: 'dictionaries',
                                    route: '/official/dictionaries',
                                    permission: ['com.rnis.dictionary.document', 'official'],
                                } : null,
                            _.find(a, ['id', '414']).submenu[3].show ?
                                {
                                    text: _.find(a, ['id', '414']).submenu[3].text,
                                    class: 'control-ts',
                                    route: '/official/violations',
                                    permission: ['com.rnis.gatn.gatn_violation', 'official'],
                                } : null,
                            _.find(a, ['id', '414']).submenu[5].show ?
                                {
                                    text: _.find(a, ['id', '414']).submenu[5].text,
                                    class: 'map',
                                    route: '/official/map',
                                    permission: ['com.rnis.geo.monitoring', 'official'],
                                } : null,
                        ],
                    } : null,
                _.find(a, ['id', '212']).show ?
                    {
                        text: _.find(a, ['id', '212']).text,
                        class: 'reports',
                        route: '/reports',
                        permission: 'com.rnis.reports.document',
                    } : null,
                _.find(a, ['id', '213']).show ?
                    {
                        text: _.find(a, ['id', '213']).text,
                        class: 'events-notifications',
                        route: '/operator/notifications',
                        permission: 'com.rnis.notifications.event_rule',
                    } : null,
                _.find(a, ['id', '214']).show ?
                    {
                        text: _.find(a, ['id', '214']).text,
                        class: 'alerts',
                        counter: <NotificationsCounter/>,
                        route: '/notifications',
                    } : null,
                _.find(a, ['id', '215']).show ?
                    {
                        text: _.find(a, ['id', '215']).text,
                        class: 'other',
                        key: 'misc',
                        submenu: [
                            _.find(a, ['id', '215']).submenu[0].show ?
                                {
                                    text: `Портал ${currentUser.user.title.SHORT_TITLE}`,
                                    class: 'dashboard',
                                    key: 'portal',
                                    submenu: [
                                        _.find(a, ['id', '215']).submenu[0].submenu[0].show ?
                                            {
                                                text: _.find(a, ['id', '215']).submenu[0].submenu[0].text,
                                                class: 'news',
                                                route: '/portal/news',
                                                permission: 'com.rnis.portal.news',
                                            } : null,
                                        _.find(a, ['id', '215']).submenu[0].submenu[1].show ?
                                            {
                                                text: _.find(a, ['id', '215']).submenu[0].submenu[1].text,
                                                class: 'faq',
                                                route: '/portal/faq',
                                                permission: 'com.rnis.portal.faq',
                                            } : null,
                                        _.find(a, ['id', '215']).submenu[0].submenu[2].show ?
                                            {
                                                text: _.find(a, ['id', '215']).submenu[0].submenu[2].text,
                                                class: 'pages',
                                                route: '/portal/pages',
                                                permission: 'com.rnis.portal.page',
                                            } : null,
                                    ],
                                } : null,
                            _.find(a, ['id', '215']).submenu[1].show ?
                                {
                                    text: _.find(a, ['id', '215']).submenu[1].text,
                                    class: 'user-monitoring',
                                    key: 'mobile',
                                    submenu: [
                                        {
                                            text: 'Контакты',
                                            class: 'contacts',
                                            route: '/mobile/contacts',
                                            permission: 'com.rnis.mobile.contact',
                                        },
                                        {
                                            text: 'Страницы',
                                            class: 'pages',
                                            route: '/mobile/mobile_pages',
                                            permission: 'com.rnis.mobile.mobile_page',
                                        },
                                    ],
                                } : null,
                            _.find(a, ['id', '215']).submenu[2].show ?
                                {
                                    text: _.find(a, ['id', '215']).submenu[2].text,
                                    class: 'import',
                                    route: '/system/vehicles/import',
                                    permission: 'com.rnis.vehicles.vehicle.import',
                                } : null,
                            _.find(_.find(a, ['id', '215']).submenu, ['id', '364']).show ?
                                currentUser.unitUuid() ? {
                                    text: 'Профиль организации',
                                    class: 'company-profile',
                                    route: '/profile/unit',
                                    permission: 'com.rnis.portal.unit_profile'
                                } : null
                                : null,
                        ],
                    } : null,
                _.find(a, ['id', '224']).show ?
                    {
                        text: _.find(a, ['id', '224']).text,
                        class: 'profile',
                        route: '/profile',
                    } : null,
                _.find(a, ['id', '225']).show ?
                    {
                        text: _.find(a, ['id', '225']).text,
                        class: 'help',
                        route: '/help',
                    } : null,
                _.find(a, ['id', '226']).show ?
                    {
                        text: _.find(a, ['id', '226']).text,
                        class: 'exit',
                        onClick: ::this.onClickLeftProfile,
                        superclass: 'left-menu__item_profile',
                        route: '/logout',
                    } : null,

            ]
        }
    }

}