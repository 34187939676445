import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import _ from 'lodash';
import { connect } from 'react-redux';
import MapFilterBase from 'components/modules/maps/Filter/MapFilterBase';
import { getOperatorComponentsSelector, getUnitsSelector } from '../../../../store/reducers/maps/selectors';
import { getUnits } from 'store/reducers/organizational_units/organizational_units';

@propTypes({})
@connect(
	(state) => ({
		operatorComponents: getOperatorComponentsSelector(state),
		units: getUnitsSelector(state),
	}),
	{ getUnits }
)
export default class MapFilterOwner extends MapFilterBase {
	tabs = [ 'Владелец' ];
	icon = 'organizer';
	contextKey = 'map.filters.base.unit';
	contextDefault = 'Субъекты';

	// NOTE: сбиваем сохранённые итемы, если изменились сторонние фильтры
	componentDidUpdate(prevProps, ...args) {
		if (super.componentDidUpdate) {
			super.componentDidUpdate(prevProps, ...args);
		}

		if (this.props.operatorComponents !== prevProps.operatorComponents) {
			const newItems = Object.assign({}, this.state.items);
			this.tabs.forEach((title, index) => {
				_.set(newItems, index, []);
			});

			this.setState({
				items: newItems,
			});
		}

		if (this.props.units !== prevProps.units) {
			const newItems = Object.assign({}, this.state.items);
			this.tabs.forEach((title, index) => {
				if (title === 'Владелец') {
					_.set(newItems, index, this.props.units);
				}
			});

			this.setState({
				items: newItems,
			});
		}
	}

	async loadData(tab = null, search = null) {
		let filters = {};
		if (this.props.component !== 'operator') {
			if (this.props.component) {
				filters.withComponent = this.props.component;
			}
		} else {
			if (this.props.operatorComponents) {
				filters.withComponent = this.props.operatorComponents;
			}
		}

		const payload = {
			filters,
			search,
			pagination: {
				page: 1,
				limit: this.limit
			}
		}

		if (!Object.keys(payload.filters).length) {
			delete payload.filters
		}

		if (payload.filters && Object.keys(payload.filters).length) {
			if(!payload.filters.withComponent.length) {
				delete payload.filters;
			}
		}

		const response = await this.props.getUnits(payload);

		if (response.isOk) {
			return _.map(response.payload.items, (item) => ({
				value: item.uuid,
				label: item.name
			}));
		} else {
			response.showErrors();
			return [];
		}
	}
}
