import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {api} from "helpers/api";
import classNames from 'classnames';

import {getMobilePage, updateMobilePage} from "store/reducers/mobile/mobile_pages";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string
})

@connect(state => ({}), {getMobilePage, updateMobilePage})

export default class Editor extends BaseEditor {

    title = 'страницы';
    modelClass = 'App\\Model\\MobilePage';
    disableOutsideClick = true;

    async loadData(uuid) {
        return await this.props.getMobilePage(uuid);
    }

    async updateItem(data) {
        return await this.props.updateMobilePage(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        mobile_page: {},
    };

    getData() {
        return this.state.mobile_page;
    }

    async componentDidMount() {
        await this.setState({
            mobile_page: this.props.data,
        });
    }

    get(path, defaultValue = null) {
        return _.get(this.state.mobile_page, path, defaultValue);
    }

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} single={true}>
                        <Block size="xl" title="Содержимое">
                            {this.wysiwyg('mobile_page.text')}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}