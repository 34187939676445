import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import * as alerts from "helpers/alerts";
import {success, error} from 'helpers/response';

const GET_OBJECT = 'GET_OBJECT';

const initialState = new Map({
    object: Map({})
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_OBJECT:
            return state.set('object', action.payload);
    }

    return state;
}

export const getUserGeoObject = (layerUuid, objectUuid) => async (dispatch) => {
    try {
        const response = await api.geo.getUserGeoObject(objectUuid);

        dispatch({
            type: GET_OBJECT,
            payload: response.payload
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getUserGeoObjects = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.geo.getUserGeoObjects(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createUserGeoObject = (object) => async (dispatch) => {
    try {
        const response = await api.geo.createUserGeoObject(object);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateUserGeoObject = (object) => async (dispatch) => {
    try {
        let response = await api.geo.updateUserGeoObject(object);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteUserGeoObject = (object) => async (dispatch) => {
    try {
        let response = await api.geo.deleteUserGeoObject(object);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const listUserGeoObjectsMO = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.geo.listUserGeoObjectsMO(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const listUserGeoObjectsMunicipalitiesList = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.geo.listUserGeoObjectsMunicipalitiesList(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
