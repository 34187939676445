import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import systems from "dictionaries/systems";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import _, {cloneDeep} from 'lodash';
import {deleteVehicle, getVehicles} from "store/reducers/kurs/vehicles";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import {getUnits} from "store/reducers/organizational_units/units";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import './index.less';
import KursMaintenancesComponent from "components/modules/kurs/maintenances/list";
import moment from "moment";
import formats from "dictionaries/formats";
import {getBnsoList} from "store/reducers/vehicles/bnso";
import {getVehicleDistance} from "store/reducers/vehicles/vehicles";

@connect(state => ({}), {getVehicles, deleteVehicle, getEntityNames, getUnits, getDictionaryList, getBnsoList, getVehicleDistance})

export default class KursVehicleListComponent extends BaseTableWithEditorComponent {

    title = `${systems.road} → Учет дорожной техники`;
    baseUrl = '/road/vehicles';
    exportRowsCount = 500;
    modelClass = 'App\\Model\\KursVehicle';
    modelSource = 'kurs';

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            maintenanceGraphicActive: false,
        });
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Государственный номер')
                .fromField('state_number')
                .withClassName('link'),

            new Column('Дислокация')
                .fromField('unit_uuid')
                .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid))
                .denyColumnFilter()
                .withFilter('withUnits', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 1500,
                        },
                        filters: {
                            withComponent: 'road',
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            ...(window.RNIS_SETTINGS.CITY_NNOVGOROD ? [
                new Column('Дата подключения ТС')
                    .fromField('general.date_activation')
                    .withDrawer( item => _.get(item, 'general.date_activation')
                        ? moment(_.get(item, 'general.date_activation') ).format('DD.MM.YYYY')
                        : '<code>-не установлено-</code>'),

                new Column('Дата отключения ТС')
                    .fromField('general.date_disactivation')
                    .withDrawer(item => _.get(item, 'general.date_disactivation')
                        ? moment(_.get(item, 'general.date_disactivation')).format('DD.MM.YYYY')
                        : '<code>-не установлено-</code>'),
            ] : []),

            new Column('Тип ТС')
                .fromField('vehicle_type_uuid')
                .withDrawer(item => item.vehicle_type_uuid ? this.state.related.get(item.vehicle_type_uuid) : '<code>-</code>')
                .denyColumnFilter()
                .withFilter('withTypes', async () => {
                    return await this.getDictionary('vehicle_types', 'road');
                }),

            ...(window.RNIS_SETTINGS.vehicle_editor_subsystem ? [(new Column('Подсистема ТС')
                .fromField('vehicle_subsystem_uuid')
                .withDrawer(item => item.vehicle_subsystem_uuid ? this.state.related.get(item.vehicle_subsystem_uuid) : '<code>-не указано-</code>')
                .withFilter('withSubsystem', async () => {
                    const response = await this.props.getDictionaryList('vehicle_subsystems', {
                        filters: {
                            withComponent: 'road',
                        },
                    });
                    if (response.isOk) {
                        console.log(response)
                        return response.payload.documents;
                    }
                    return {};
                }))] : []),

            new Column('Экологический класс')
                .fromField('environmental_class_uuid')
                .withDrawer(item => item.environmental_class_uuid ? this.state.related.get(item.environmental_class_uuid) : '<code>-</code>')
                .denyColumnFilter(),

            new Column('Марка ТС')
                .fromField('vehicle_mark_uuid')
                .withDrawer(item => item.vehicle_mark_uuid ? this.state.related.get(item.vehicle_mark_uuid) : '<code>-</code>')
                .denyColumnFilter(),

            new Column('Модель ТС')
                .fromField('vehicle_model_uuid')
                .withDrawer(item => item.vehicle_model_uuid ? this.state.related.get(item.vehicle_model_uuid) : '<code>-</code>')
                .denyColumnFilter(),

            new Column('Наименование по ПТС')
                .withDrawer(item => _.get(item, 'general.pts_name'))
                .denyColumnFilter()
                .denyOrder(),

            new Column('Орган регистрации')
                .withDrawer(item => _.get(item, 'general.registration_unit_uuid') ? this.state.related.get(_.get(item, 'general.registration_unit_uuid')) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder(),

            new Column('Технич. состояние на момент инвентаризации')
                .withDrawer(item => _.get(item, 'general.technical_condition_uuid') ? this.state.related.get(_.get(item, 'general.technical_condition_uuid')) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder(),

            new Column('Дата инвентаризации')
                .fromField('general->inventarisation_date')
                .withDrawer(item => _.get(item, 'general.inventarisation_date') ? moment(_.get(item, 'general.inventarisation_date')).format(formats.DATE) : '<code>-</code>')
                .withDateFilter()
                .denyOrder(),

            new Column('Сезонность')
                .withDrawer(item => _.get(item, 'general.season_uuid') ? this.state.related.get(_.get(item, 'general.season_uuid')) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder(),

            new Column('Водитель 1')
                .withDrawer(item => _.get(item, 'general.driver_uuid') ? this.state.related.get(_.get(item, 'general.driver_uuid')) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder(),

            new Column('Водитель 2')
                .withDrawer(item => _.get(item, 'general.driver2_uuid') ? this.state.related.get(_.get(item, 'general.driver2_uuid')) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder(),

            new Column('Дата установки БНСО')
                .fromField('current_bnso_installation_date')
                .withDateFilter()
                .withDrawer(item => _.get(item, 'current_bnso_installation_date') ? moment(_.get(item, 'current_bnso_installation_date')).format(formats.DATE) : '<code>-</code>'),

            new Column('Дата демонтажа БНСО')
                .fromField('current_bnso_dismantling_date')
                .withDateFilter()
                .withDrawer(item => _.get(item, 'current_bnso_dismantling_date') ? moment(_.get(item, 'current_bnso_dismantling_date')).format(formats.DATE) : '<code>-</code>'),

            new Column('БНСО №')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => item.current_bnso_uuid ? this.state.related.get(item.current_bnso_uuid) : '<code>-не установлено-</code>')
                .withAsyncFilter('withBnso', async (search) => {
                    const response = await this.props.getBnsoList({
                        search,
                        order: {
                            column: 'bnso_code',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 50,
                        },
                    });
                    if (response.isOk) {
                        return _.map(response.payload.items, item => ({
                            uuid: item.uuid,
                            name: item.bnso_code,
                        }));
                    }
                    return [];
                }),

            new Column('Балансодержатель')
                .fromField('general->balanceholder_uuid')
                .withDrawer(item => _.get(item, 'general.balanceholder_uuid') ? this.state.related.get(_.get(item, 'general.balanceholder_uuid')) : null)
                .denyColumnFilter()
                .denyOrder()
                .withFilter('withBalanceholders', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 10000,
                        },
                        filters: {
                            withComponent: 'road',
                        },
                        response_data: [
                            'items/uuid',
                            'items/name',
                        ],
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            ...(!window.RNIS_SETTINGS.CITY_MURMANSK ? [
                new Column('Год выпуска')
                    .withDrawer(item => _.get(item, 'technical.release_year'))
                    .denyColumnFilter()
                    .denyOrder(),
            ] : []),



            new Column('VIN')
                .withDrawer(item => _.get(item, 'technical.vin'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Номер шасси')
                .withDrawer(item => _.get(item, 'technical.chassis_number'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Номер кузова (кабина)')
                .withDrawer(item => _.get(item, 'technical.body_number'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Номер КПП')
                .withDrawer(item => _.get(item, 'technical.gear_number'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Модель, номер двигателя')
                .withDrawer(item => _.get(item, 'technical.engine_number'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Тип двигателя')
                .withDrawer(item => _.get(item, 'technical.engine_type_uuid') ? this.state.related.get(_.get(item, 'technical.engine_type_uuid')) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Мощность двигателя, л.с.\\кВт')
                .withDrawer(item => _.get(item, 'technical.engine_power'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Раб. объем двигателя куб. см')
                .withDrawer(item => _.get(item, 'technical.engine_volume'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Основной ведущий мост (мосты), номер')
                .withDrawer(item => _.get(item, 'technical.drive_number'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Разрешенная макс масса, кг')
                .withDrawer(item => _.get(item, 'technical.max_weight'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Масса без нагр., кг')
                .withDrawer(item => _.get(item, 'technical.max_weight_without_load'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Максим. констр. скорость, км/ч')
                .withDrawer(item => _.get(item, 'technical.max_speed'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Вид движителя')
                .withDrawer(item => _.get(item, 'technical.thruster_type_uuid') ? this.state.related.get(_.get(item, 'technical.thruster_type_uuid')) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Габаритные размеры, длина, мм')
                .withDrawer(item => _.get(item, 'technical.dimensions_x'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Габаритные размеры, ширина, мм')
                .withDrawer(item => _.get(item, 'technical.dimensions_y'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Габаритные размеры, высота, мм')
                .withDrawer(item => _.get(item, 'technical.dimensions_z'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Цвет')
                .withDrawer(item => _.get(item, 'technical.color_uuid') ? this.state.related.get(_.get(item, 'technical.color_uuid')) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Маяк')
                .withDrawer(item => _.get(item, 'technical.beacon'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Категория ТС')
                .withDrawer(item => _.get(item, 'technical.vehicle_category_uuid') ? this.state.related.get(_.get(item, 'technical.vehicle_category_uuid')) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Организация изготовитель')
                .withDrawer(item => _.get(item, 'technical.vehicle_manufacturer_uuid') ? this.state.related.get(_.get(item, 'technical.vehicle_manufacturer_uuid')) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Объем топливного бака, л')
                .withDrawer(item => _.get(item, 'technical.fuel_tank_volume'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Кол-во пассаж. мест')
                .withDrawer(item => _.get(item, 'technical.passengers_count'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Дата окончания гарантии')
                .withDrawer(item => _.get(item, 'technical.warranty_end_date') ? moment(_.get(item, 'technical.warranty_end_date')).format(formats.DATE) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('ПТС Серия, номер')
                .withDrawer(item => _.get(item, 'documents.pts_number'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('ПТС Дата выдачи')
                .withDrawer(item => _.get(item, 'documents.pts_date') ? moment(_.get(item, 'documents.pts_date')).format(formats.DATE) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('ПТС Кем выдан')
                .withDrawer(item => _.get(item, 'documents.pts_issuer'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('СТС Серия, номер')
                .withDrawer(item => _.get(item, 'documents.sts_number'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('СТС Дата выдачи')
                .withDrawer(item => _.get(item, 'documents.sts_date') ? moment(_.get(item, 'documents.sts_date')).format(formats.DATE) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('СТС Кем выдан')
                .withDrawer(item => _.get(item, 'documents.sts_issuer'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Полис ОСАГО Страховая компания')
                .withDrawer(item => _.get(item, 'documents.osago_insurance_company_uuid') ? this.state.related.get(_.get(item, 'documents.osago_insurance_company_uuid')) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Полис ОСАГО Номер полиса')
                .withDrawer(item => _.get(item, 'documents.osago_number'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Полис ОСАГО Дата окончания')
                .withDrawer(item => _.get(item, 'documents.osage_end_date') ? moment(_.get(item, 'documents.osage_end_date')).format(formats.DATE) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Полис КАСКО Страховая компания')
                .withDrawer(item => _.get(item, 'documents.kasko_insurance_company_uuid') ? this.state.related.get(_.get(item, 'documents.kasko_insurance_company_uuid')) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Полис КАСКО Номер полиса')
                .withDrawer(item => _.get(item, 'documents.kasko_number'))
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            new Column('Полис КАСКО Дата окончания')
                .withDrawer(item => _.get(item, 'documents.kasko_end_date') ? moment(_.get(item, 'documents.kasko_end_date')).format(formats.DATE) : '<code>-</code>')
                .denyColumnFilter()
                .denyOrder()
                .defaultHidden(),

            /*new Column('Исправность')
                .withDrawer(item => (_.get(item, 'general.malfunctions', []).length === 0) ? 'Исправен' : 'Неисправен')
                .denyColumnFilter()
                .denyOrder(),*/

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteVehicle(data);
    }

    async loadData(meta) {
        let response = await this.props.getVehicles(meta);

        if (response.isOk) {
            response.payload.items = _.map(response.payload.items, (item) => {
                const bnsoItem = _.first(_.filter(item.bnso, (bnso) => {
                    return moment(bnso.installation_date).isSameOrBefore() && (!bnso.dismantling_date || moment(bnso.dismantling_date).isSameOrAfter());
                }));

                item.current_bnso_uuid = _.get(bnsoItem, 'bnso_uuid');
                item.current_bnso = bnsoItem;

                return item;
            });

        }

        return response;
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const types = _.map(_.uniq(_.filter(_.map(result, 'vehicle_type_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const subsystems = _.map(_.uniq(_.filter(_.map(result, 'vehicle_subsystem_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleSubsystems\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const marks = _.map(_.uniq(_.filter(_.map(result, 'vehicle_mark_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleMark\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const models = _.map(_.uniq(_.filter(_.map(result, 'vehicle_model_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleModel\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const environmentalClasses = _.map(_.uniq(_.filter(_.map(result, 'environmental_class_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleEnvironmentalClass\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const registrationUnits = _.map(_.uniq(_.filter(_.map(result, 'general.registration_unit_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursRegistrationUnits\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const technicalConditions = _.map(_.uniq(_.filter(_.map(result, 'general.technical_condition_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursTechnicalConditions\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const engineTypes = _.map(_.uniq(_.filter(_.map(result, 'technical.engine_type_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursEngineTypes\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const thrusterTypes = _.map(_.uniq(_.filter(_.map(result, 'technical.thruster_type_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursThrusterTypes\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const colors = _.map(_.uniq(_.filter(_.map(result, 'technical.color_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursColors\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const categories = _.map(_.uniq(_.filter(_.map(result, 'technical.vehicle_category_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursVehicleCategories\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const manufacturers = _.map(_.uniq(_.filter(_.map(result, 'technical.vehicle_manufacturer_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursVehicleManufacturers\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const seasons = _.map(_.uniq(_.filter(_.map(result, 'general.season_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\Seasons\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const osago = _.map(_.uniq(_.filter(_.map(result, 'documents.osago_insurance_company_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursInsuranceCompanies\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const kasko = _.map(_.uniq(_.filter(_.map(result, 'documents.kasko_insurance_company_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursInsuranceCompanies\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const drivers = _.map(_.uniq(_.filter(_.map(result, 'general.driver_uuid'))), (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));
        const drivers2 = _.map(_.uniq(_.filter(_.map(result, 'general.driver2_uuid'))), (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));
        const units = _.map(_.uniq(_.filter(_.map(result, 'unit_uuid'))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));
        const balanceholders = _.map(_.uniq(_.filter(_.map(result, 'general.balanceholder_uuid'))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));
        const bnso = _.map(_.uniq(_.filter(_.map(result, 'current_bnso_uuid'))), (uuid) => ({
            class: 'App\\Model\\Bnso',
            uuid: uuid,
            source: 'vehicles',
        }));
        const response = await this.props.getEntityNames(_.flatten([
            types,
            subsystems,
            marks,
            models,
            units,
            bnso,
            environmentalClasses,
            registrationUnits,
            technicalConditions,
            seasons,
            drivers,
            drivers2,
            engineTypes,
            thrusterTypes,
            colors,
            categories,
            manufacturers,
            osago,
            kasko,
            balanceholders,
        ]));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="kurs-vehicles.maintenance" code="kurs-vehicles.maintenance"
                            default="График прохождения ТО">
                <IconButton icon="pass-schedule" onClick={::this.showMaintenanceGraphic}/>
            </ContextTooltip>,
            <ContextTooltip key="base-table-list.delete" code="base-table-list.delete" default="Удалить">
                <IconButton icon="basket" disabled={this.state.selectedRowsCount !== 1}
                            onClick={::this.deleteSelected}/>
            </ContextTooltip>,
        ];
    }

    showMaintenanceGraphic() {
        this.setState({
            maintenanceGraphicActive: true,
        });
    }

    hideMaintenanceGraphic() {
        this.setState({
            maintenanceGraphicActive: false,
        });
    }

    renderModals() {
        if (this.state.maintenanceGraphicActive) {
            return (
                <KursMaintenancesComponent
                    component="road"
                    onClose={::this.hideMaintenanceGraphic}
                />
            );
        }
    }
}
