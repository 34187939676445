import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import currentUser from 'helpers/current-user';

import Page from '../../ui/page';

import './index.less';


export default class HelpComponent extends Component {

    state = {};

    componentWillMount() {

    }

    componentWillUnmount() {
    }


    render() {
        let link = `${window.location.origin}/settings/operator-manual.pdf`;
        return (
            <Page pageId="Help" title="Справка">
                <div className="full">
                    <p>
                        <a href={link} target="_blank"><strong>Руководство оператора {`${currentUser.user.title.SHORT_TITLE}`}</strong></a>
                    </p>
                </div>
            </Page>
        );
    }

}