import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import Page from "components/ui/page";
import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import {api} from "helpers/api";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUnit, getUnits} from "store/reducers/organizational_units/units";
import GlobalLoaderComponent from "components/ui/global-loader";
import systems from "dictionaries/systems";
import TabBlock from "components/ui/tabs/tab-block";
import '../style.less';
import Button from "components/ui/button";
import {createWaybill, getWaybill, getWaybills, updateWaybill} from "store/reducers/kurs/waybills";

import {getComplaintById, updateComplaint} from "store/reducers/complaints/complaints";

import {getTask, getTasks} from "store/reducers/kurs/tasks";
import {User} from "helpers/user";
import {getUsers} from "store/reducers/staffing/staffing";
import {getVehicle, getVehicleList} from "store/reducers/vehicles/vehicles";
import moment from "moment";
import formats from "dictionaries/formats";
import * as alerts from "helpers/alerts";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import {getVehicleMechanisms} from "store/reducers/kurs/vehicle_mechanisms";
import {getVehicles} from "store/reducers/kurs/vehicles";
import './index.less';
import {getUser} from "store/reducers/staffing/staffing_editor";
import {Link} from "react-router";
import currentUser from 'helpers/current-user';
import Lightbox from "react-images";

@connect(state => ({}), {
    getWaybill,
    createWaybill,
    updateWaybill,
    getUnit,
    getVehicle,
    getUser,
    getVehicleMechanisms,
    getVehicles,
    getDictionaryList,

    getComplaintById,
    updateComplaint,
    getUnits
})

export default class ComplaintsEditor extends BaseEditor {

    modelClass = 'App\\Model\\Complaint';

    async componentWillMount() {
        const response = await this.props.getDictionaryList('complaint_statuses');
        if(response.isOk) {

            this.setState({
                statuses:  response.payload.documents.map(item => ({value: item.uuid, label: item.name})),
            })
        }
    }

    async componentWillUpdate(props, state) {
        const propsUuid = (props.params.uuid === 'create') ? null : props.params.uuid;
        if (state.uuid !== propsUuid) {
            await this.setState({
                uuid: propsUuid,
                item: null,
                isLoading: !!propsUuid,
                mode: propsUuid ? 'edit' : 'add',
                itemIndex: _.toInteger(this.state.itemIndex) + 1,
                formUpdated: propsUuid ? false : true,
            });
            if (propsUuid) {
                const response = await this.loadData(propsUuid);

                if (response.isOk) {
                    this.setState({
                        item: response.payload,
                        isLoading: false,
                    });


                } else {
                    response.showErrors();
                }
            }
        }
    }

    async componentDidMount() {
        this.forceUpdate();
    }

    componentWillReceiveProps(props) {
    }

    async loadData(uuid) {

       const response = await this.props.getComplaintById(uuid);
        if (response.isOk) {
            await this.loadUnits();
        }
        return response;
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            filters: {
                withComponent: this.props.params.component,
            },
            response_data: [
                'items/uuid',
                'items/name',
                //'items/contracts',
            ],
        });
        if (response.isOk) {
            const units = _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.name,
               // contracts: item.contracts
            }));
            this.setState({units});

        } else {
            response.showErrors();
        }
    }

    async updateItem(data) {

        let data_to_send = {
            uuid: data.uuid,
            status_uuid: data.status_uuid,
            unit_uuid: data.unit_uuid,
            images: data.images,
            resolution: data.resolution,
        }
        return await this.props.updateComplaint(data_to_send);
    }

    getForm(item, onSubmit) {

        return (
            <EditorForm
                key={this.state.itemIndex}
                {...this.props}
                ref="form"
                mode={this.state.mode}
                onClose={::this.onClose}
                data={item}
                unitsList={this.state.units}
                errors={this.state.errors}
                onSubmit={onSubmit}
                onUpdate={::this.onFormUpdate}
                statusesList={this.state.statuses}
            />
        );
    }

    onFormUpdate() {
        this.setState({
            formUpdated: true,
        });
    }

    onClose() {
        alerts.prompt('Вы действительно хотите закрыть эту страницу без сохранения данных?', '', () => {
            this.props.router.push('/road/complaints');
        }, 'Закрыть');
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();
        this.realEdit(data);
    }

    async realEdit(data) {
        const response = await this.updateItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            await this.setState({
                item: response.payload,
                formUpdated: false,
                itemIndex: this.state.itemIndex + 1,
            });
        } else {
            this.setState({
                errors: response.validationErrors,
            });
            if (_.keys(response.validationErrors).length > 0) {
                alerts.error('Изменения не сохранены. Проверьте заполнение полей');
            }
            response.showErrors();
        }
    }

    render() {
        let title;

        let form;
        let onSubmit;

        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        if (this.state.mode === 'edit') {
            title = '';

            if (this.state.item) {
                title = `Жалоба №${this.state.item.external_id}`;
                form = this.getForm(this.state.item, ::this.edit);
            }

            onSubmit = () => {
                let data = this.refs.form.refs.wrappedInstance.state.complaint;

                if(!data.active) {
                    alerts.alert('','Дальнейшая отработка Жалобы возможна только на стороне Нашего Севера')
                }

                this.onEdit()
                this.setState({
                    isLoading: false,
                    saving: false,
                });
            };
        }

        return (
            <Page
                pageId="ViewComplaint"
                title={`Жалобы → ${title}`}
                headerActions={this.renderHeaderActions()}
            >
                {loader}
                {form}
                {(this.state.formUpdated && !this.state.saving) ? (
                    <div className="page-footer">
                        <div className="page-footer__txt">Вы хотите сохранить все изменения?</div>
                        <Button size="md" color="white" shadow="gray" className="b-button_cancel" text="Отменить"
                                onClick={::this.onReset}/>
                        <Button size="md" color="red" className="b-button_save" text="Сохранить"
                                onClick={onSubmit}/>
                    </div>
                ) : null}
            </Page>
        );
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="kurs.sto.back" code="kurs.sto.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ];
    }

    onReset() {
        this.refs.form.getWrappedInstance().onReset();
        this.setState({
            formUpdated: false,
        });
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    unitsList: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    statusesList: PropTypes.array,
})

@connect((state) => ({}), {
    getDictionaryList,
    getUnits,
    getTasks,
    getUsers,
    getVehicleList,
    getVehicleMechanisms,
    getVehicles,
    getTask,
    getWaybills,
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        waybill: {},
        tasks: [],
        kurs_task_statuses: [],
        vehicles: [],
        trailer: null,
        fuel_norma: null,
        waybill_vehicles: [],

        execution_date: null,
        complaint: {},
        openedImage: null,
    };

    goToTask() {

        const task_uuid = this.state.complaint.tasks[0] ? this.state.complaint.tasks[0].uuid : null;

        if(task_uuid) {
            this.props.router.push({
                pathname: `/road/tasks/${task_uuid}`,
                query: {
                    ns_id_complaint: this.props.data.uuid,
                }
            });
        } else {
            this.props.router.push({
                pathname: '/road/tasks/create',
                query: {
                    ns_id_complaint: this.props.data.uuid,
                }
            });
        }

    }

    toAppointCompany() {
        console.log('toAppointCompany')
        //PUT на  admin/api/complaints/{id} - НЕТ
    }

    getData() {
        return this.state.complaint;
    }

    onReset() {
        this.setState({
            complaint: _.cloneDeep(this.props.data),
        });
    }

    async componentDidMount() {
        await this.setState({
            complaint: _.cloneDeep(this.props.data),
            execution_date: moment(this.props.data.execution_date).format('DD.MM.YYYY HH:MM')
        });
    }

    // async loadTasks() {
    //     const filters = {withUuid: [this.get('task_uuid')]};

    //     const response = await this.props.getTasks({
    //         filters,
    //     });
    //     if (response.isOk) {
    //         this.setState({
    //             tasks: _.sortBy(_.map(response.payload.items, (task) => ({
    //                 value: task.uuid,
    //                 label: task.number,
    //                 unit_uuid: task.unit_uuid,
    //                 resources: task.resources,
    //             })), 'label'),
    //         });
    //     } else {
    //         response.showErrors();
    //     }
    // }

    async onUnitChange(e) {

        const value = e ? e.value : null;

        await this.setValue('complaint.unit_uuid', value);


        // const taskUuid = this.get('task_uuid');
        // const task = _.find(this.state.tasks, {value: taskUuid});
        // if (task) {
        //     const driverUuid = _.get(_.find(task.resources, {base_vehicle_uuid: value}), 'driver_uuid');
        //     this.setValue('waybill.driver_uuid', driverUuid);

        //     this.refs.driver && this.refs.driver.reload();
        // }
    }


    get(path, defaultValue = null) {
        return _.get(this.state.waybill, path, defaultValue);
    }



    getUnits() {
        return _.filter(this.state.vehicles, (vehicle) => {
            return this.state.waybill_vehicles.indexOf(vehicle.value) === -1;
        });
    }

    openImage(url) {
        this.setState({
            openedImage: url,
        });
    }

    closeImage() {
        this.setState({
            openedImage: null,
        });
    }

    renderImages(image) {
        return (
            <div className='page-block__content__images__item'
                style={{backgroundImage: `url(${image.url})`, cursor: 'pointer'}}
                onClick={this.openImage.bind(this, image.url)}
            />
        )

}

    render() {
        if (_.isEmpty(this.state.complaint)) {
            return null;
        }

        const images_before = this.props.data.images.filter(image => image.type === 'before');
        const images_after= this.props.data.images.filter(image => image.type === 'after');

        console.log('images_before', images_before)
        console.log('images_after', images_after)

        return (
            <div className="page-block">
                <TabBlock withBorder={false}>
                    <div className='page-block__title'>Основная информация</div>
                    <div className="page-block__container">
                        <div className="page-block__content _lg">

                        <Block size="lg" title="Заголовок">
                            <span>{this.state.complaint.title}</span>

                        </Block>
                        <Block size="lg" title="Описание">
                            <span>{this.state.complaint.description}</span>
                        </Block>
                        <div className="page-block__content__row">
                            <Block size="md" title="Дата составления">
                                {this.datepicker('complaint.external_created_at', {
                                    disabled: true,
                                })}
                            </Block>
                            <Block size="md" title="Статус">
                                {this.select('complaint.status_uuid', _.map(this.props.statusesList))}
                            </Block>
                            <Block size="md" title="Адрес">
                                {this.textInput('complaint.address', {
                                    disabled: true
                                })}
                            </Block>
                            <Block size="md" title="Выполнить до">
                                {this.textInput('execution_date', {
                                    disabled: true
                                })}
                            </Block>
                        </div>
                        <Block size="lg">
                            <div className='page-block__content__images'>
                                <div className='page-block__content__images__title'>Фотографии проблемы</div>
                                {
                                    images_before.length ? (

                                        <div  className='page-block__content__images'>
                                            <div className='page-block__content__images__title'>До:</div>
                                           {
                                            images_before.map((item) => {
                                                    return (this.renderImages(item))
                                                })
                                            }
                                        </div>

                                    ) : null
                                }
                                {
                                    images_after.length ? (
                                        <div className='page-block__content__images'>
                                            <div className='page-block__content__images__title'>После:</div>
                                            {
                                                images_after.map((item) => {
                                                    return (this.renderImages(item))
                                                })
                                            }
                                        </div>

                                    ) : null
                                }
                            </div>


                            <Lightbox
                                images={[
                                    {src: this.state.openedImage},
                                ]}
                                isOpen={this.state.openedImage}
                                onClose={::this.closeImage}
                            />
                        </Block>
                    </div>
                        <div className="page-block__content _lg">
                        <Block size="lg">
                            <div className='page-block__content__buttons'>
                                <Button text={this.state.complaint.tasks.length ? "Посмотреть задание" : "Создать задание"} width="full" size="md"
                                    shadow="red"
                                    onClick={this.goToTask.bind(this)}
                                />
                            </div>
                        </Block>

                        <Block size="lg" title="Предприятие">
                            {this.select('complaint.unit_uuid', this.props.unitsList, {
                                onChange: ::this.onUnitChange,
                               // disabled: this.get('status') !== 'draft',
                            })}
                            {/* <div className='page-block__content__buttons'>
                                <Button text="Назначить" width="full" size="md"
                                    shadow="red"
                                    //после подключения api НС заменить проверку на наличие в пропсах жалобы назначенного предприятия
                                    disabled={this.props.data.score === 0}
                                    onClick={this.toAppointCompany.bind(this)}
                                />
                                <Button text="Переназначить" width="full" size="md"
                                    shadow="red"
                                     //после подключения api НС заменить проверку на наличие в пропсах жалобы назначенного предприятия
                                    disabled={this.props.data.score !== 0}
                                    onClick={this.toAppointCompany.bind(this)}
                                />
                            </div> */}
                        </Block>
                        <div style={{height: 125}}></div>
                        <Block size="lg" title="Комментарий">
                         {this.textarea('complaint.resolution')}
                        </Block>
                    </div>
                    </div>

                </TabBlock>
            </div>
        );
    }

    async setValue(field, value) {

        //заменить на проверку поля с предприятием
        if (field !== 'waybill.driver_uuid' && field !== 'waybill.vehicle_uuid') {
            this.props.onUpdate();
        }

        await super.setValue(field, value);

        if (field === 'waybill.task_uuid') {
            this.loadWaybills();
        }
    }


    async getVehicle() {
        if (!this.get('vehicle_uuid')) {
            return null;
        }


        const response = await this.props.getVehicles({
            filters: {
                withBaseVehicles: [this.get('vehicle_uuid')],
            },
        });
        if (response.isOk) {
            return _.first(response.payload.items);
        } else {
            response.showErrors();
        }
    }

    async getTask() {
        if (!this.get('task_uuid')) {
            return null;
        }

        const response = await this.props.getTask(this.get('task_uuid'));
        if (response.isOk) {
            return response.payload;
        } else {
            response.showErrors();
        }
    }
}
