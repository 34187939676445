import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import _ from 'lodash';
import TableContainer from "components/ui/Table/Container/TableContainer";
import Block from "components/ui/form/block";
import moment from "moment";
import classNames from 'classnames';
import Settings from 'settings';

@propTypes({
    order: PropTypes.object.isRequired,
    order_recalc: PropTypes.object.isRequired,
    related: PropTypes.object.isRequired,
    order_executions: PropTypes.array.isRequired,
})

export default class OrderCloseSummary extends Component {
    render() {
        const structure = this.getStructure();

        return (
            <div className="blocks clearAfter">
                <div className="block block-left">
                    <TableContainer>
                        <div className="Table-title">Сводка</div>
                        <div className="Table">
                            <table className="b-table">
                                <thead>
                                <tr className="b-table__header align-right bold">
                                    <th colSpan={5}>Зачет ТР</th>
                                    <th colSpan={4}>Пробеги, км</th>
                                </tr>
                                <tr className="b-table__header align-center bold">
                                    <th colSpan={5}>Рейсы: автоматический и ручной зачет</th>
                                    <th colSpan={2}>Линейный</th>
                                    <th colSpan={2}>Общий</th>
                                </tr>
                                <tr className="b-table__header wrap-normal align-center">
                                    <th/>
                                    <th>Авто (план)</th>
                                    <th>Авто (оперплан)</th>
                                    <th>Авто (пересчет)</th>
                                    <th>Итог + ручной зачет</th>
                                    <th>План/оперплан</th>
                                    <th>Факт/ручной зачет</th>
                                    <th>План/оперплан</th>
                                    <th>Факт</th>
                                </tr>
                                </thead>
                                <tbody>
                                {_.map(structure, ::this.renderVehicleRow)}
                                <tr className="bold align-center">
                                    <td className="align-left">Сумма</td>
                                    <td>{this.getCheckedProductionRuns('plan').length}/{this.getProductionRuns('plan').length}</td>
                                    <td>{this.getCheckedProductionRuns('operplan').length}/{this.getProductionRuns('operplan').length}</td>
                                    <td>{this.getCheckedProductionRuns('recalced').length}/{this.getProductionRuns('recalced').length}</td>
                                    <td>{this.getAllCheckedRuns('recalced').length}/{this.getProductionRuns('recalced').length}</td>
                                    <td>{this.formatDistance(_.sumBy(this.getProductionRuns('recalced'), 'distance'))}/{this.formatDistance(_.sumBy(this.getProductionRuns('operplan'), 'distance'))}</td>
                                    <td>{this.formatDistance(_.sumBy(this.getCheckedProductionRuns('recalced'), this.getDist), true)}/{this.formatDistance(_.sumBy(this.getAllCheckedProductionRunsCorrection('recalced'), this.getDist), true)}</td>
                                    <td>{this.formatDistance(_.sumBy(this.getRuns('recalced'), 'distance'))}/{this.formatDistance(_.sumBy(this.getRuns('operplan'), 'distance'))}</td>
                                    <td>{this.formatDistance(_.sumBy(this.getRuns('recalced'), this.getMileage), true)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </TableContainer>
                </div>
                <div className="block block-right">
                    <div className="indexes">
                        {(Settings.get('display_telematics_quality', '0') === '1') ? (
                            <div className="indexes__item">
                                <div className="indexes__title">Индекс качества телематики</div>
                                {_.map(structure, ::this.renverVehicleTelematicsIndex)}
                            </div>
                        ) : null}
                        <div className="indexes__item">
                            <div className="indexes__title">Индекс соответствия плану</div>
                            <div>
                                <div
                                    className={classNames('indexes__value', this.getIndexStatus(this.getScheduleAccordance(), 80, 60))}>
                                    {this.getScheduleAccordance()}%
                                </div>
                            </div>
                        </div>
                        <div className="indexes__item">
                            <div className="indexes__title">Индекс ресурсообеспечения</div>
                            <div>
                                <div
                                    className={classNames('indexes__value', this.getIndexStatus(this.getResourceIndex(), 70, 40))}>
                                    {this.getResourceIndex()}%
                                </div>
                            </div>
                        </div>
                        <div className="indexes__item">
                            <div className="indexes__title">Индекс следования расписанию</div>
                            <div>
                                <div
                                    className={classNames('indexes__value', this.getIndexStatus(this.getRouteFactAccordance(), 75, 50))}>
                                    {this.getRouteFactAccordance()}%
                                </div>
                            </div>
                        </div>
                        <div className="indexes__item">
                            <div className="indexes__title">Индекс соответствия плану по расписанию</div>
                            <div>
                                <div
                                    className={classNames('indexes__value', this.getIndexStatus(this.getRouteAccordance(), 75, 50))}>
                                    {this.getRouteAccordance()}%
                                </div>
                            </div>
                        </div>
                        <div className="indexes__group-title">Индекс выполнения работ</div>
                        <div className="indexes__item">
                            <div className="indexes__title">Индекс выполнения работ (авто)</div>
                            <div>
                                <div
                                    className={classNames('indexes__value', this.getIndexStatus(this.getWorkAutoIndex(), 80, 60))}>
                                    {this.getWorkAutoIndex()}%
                                </div>
                            </div>
                        </div>
                        <div className="indexes__item">
                            <div className="indexes__title">Индекс выполнения работ (авто+ручной)</div>
                            <div>
                                <div
                                    className={classNames('indexes__value', this.getIndexStatus(this.getWorkCheckedIndex(), 80, 60))}>
                                    {this.getWorkCheckedIndex()}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    factMileage() {

    }

    getWorkAutoIndex() {
        const runs = this.getProductionRuns('recalced');
        const checked = _.filter(runs, (run) => {
            const orderExecution = this.findOrderExecution(run);

            return _.get(orderExecution, 'is_checked') || (run.check_type === 'auto');
        });

        const getTime = (run) => {
            return _.toNumber(run.time);
        };

        return (runs.length > 0) ? (Math.round(10000 * _.sumBy(checked, getTime) / _.sumBy(runs, getTime)) / 100) : 0;
    }

    getWorkCheckedIndex() {
        const runs = this.getProductionRuns('recalced');
        const checked = _.filter(runs, (run) => {
            const orderExecution = this.findOrderExecution(run);

            return _.get(orderExecution, 'is_checked') || run.is_checked;
        });

        const getTime = (run) => {
            return _.toNumber(run.time);
        };

        return (runs.length > 0) ? (Math.round(10000 * _.sumBy(checked, getTime) / _.sumBy(runs, getTime)) / 100) : 0;
    }

    getResourceIndex() {
        const runs = this.getProductionRuns('recalced');
        const runsWithResources = _.filter(runs, (run) => {
            return run.vehicle_uuid && run.driver_uuid;
        });

        const getTime = (run) => {
            return _.toNumber(run.time);
        };

        return (runs.length > 0) ? Math.round((_.sumBy(runsWithResources, getTime) / _.sumBy(runs, getTime)) * 100) : 0;
    }

    getRouteAccordance() {
        const planRuns = this.getRuns('plan').length;
        const operplanRuns = this.getRuns('operplan').length;

        return (planRuns > 0) ? Math.min(100, (operplanRuns / planRuns) * 100) : 0;
    }

    getRouteFactAccordance() {
        const {order_executions} = this.props;

        const penalties = _.filter(_.map(order_executions, (orderExecution) => {
            const points = _.filter(orderExecution.data, (point) => {
                return point.point_type === 'stop_point';
            });

            return (points.length > 0) ? (_.sumBy(points, (point) => (1 - _.toNumber(_.isUndefined(point.penalty) ? 1 : point.penalty))) / points.length) : null;
        }), (item) => (item !== null));

        return (penalties.length > 0) ? _.round(_.sum(penalties) / penalties.length * 100, 2) : 0;
    }

    getScheduleAccordance() {
        const runs = this.getRuns('plan');
        const accordance = Math.max(0, 1 - _.sum(_.map(runs, (run) => {
            const {offset, compression, originalLength} = this.getAccordance(run);

            return (originalLength !== 0) ? ((Math.abs(offset) + Math.abs(compression)) / originalLength) : 0;
        })));

        return Math.round(accordance * 100);
    }

    getAccordance(run) {
        const offset = Math.abs(moment(run.original_date_from).diff(moment(run.date_from), 'minutes'));
        const originalLength = Math.abs(moment(run.original_date_from).diff(moment(run.original_date_to), 'minutes'));
        const runLength = Math.abs(moment(run.date_from).diff(moment(run.date_to), 'minutes'));
        const compression = runLength - originalLength;

        return {
            offset,
            compression,
            originalLength
        };
    }

    renverVehicleTelematicsIndex(vehicle) {
        const {order_executions} = this.props;

        const items = _.filter(_.map(_.filter(order_executions, {vehicle_uuid: vehicle.uuid}), (item) => {
            return _.toNumber(item.telematics_index) || null;
        }), (index) => index !== null);

        const index = (items.length > 0) ? (_.sum(items) / items.length) : 0;
        const value = Math.round(index * 100);

        return (
            <div key={vehicle.uuid}>
                <div className={classNames('indexes__value', this.getIndexStatus(value, 95, 90))}>
                    {vehicle.name}: {value}%
                </div>
            </div>
        );
    }

    getIndexStatus(value, normal, warning) {
        if (value >= normal) {
            return 'normal';
        }
        if (value >= warning) {
            return 'warning';
        }
        return 'alarm';
    }

    renderVehicleRow(vehicle) {
        return _.concat([
            <tr key={vehicle.uuid} className="accent align-center">
                <td className="wrap-nowrap align-left">{vehicle.name}</td>
                <td>{this.getCheckedProductionRuns('plan', vehicle).length}/{this.getProductionRuns('plan', vehicle).length}</td>
                <td>{this.getCheckedProductionRuns('operplan', vehicle).length}/{this.getProductionRuns('operplan', vehicle).length}</td>
                <td>{this.getCheckedProductionRuns('recalced', vehicle).length}/{this.getProductionRuns('recalced', vehicle).length}</td>
                <td>{this.getAllCheckedRuns('recalced', vehicle).length}/{this.getProductionRuns('recalced', vehicle).length}</td>
                <td>{this.formatDistance(_.sumBy(this.getProductionRuns('recalced', vehicle), 'distance'))}/{this.formatDistance(_.sumBy(this.getProductionRuns('operplan', vehicle), 'distance'))}</td>
                <td>{this.formatDistance(_.sumBy(this.getCheckedProductionRuns('recalced', vehicle), this.getDist), true)}/{this.formatDistance(_.sumBy(this.getAllCheckedProductionRunsCorrection('recalced', vehicle), this.getDist), true)}</td>
                <td>{this.formatDistance(_.sumBy(this.getRuns('recalced', vehicle), 'distance'))}/{this.formatDistance(_.sumBy(this.getRuns('operplan', vehicle), 'distance'))}</td>
                <td>{this.formatDistance(_.sumBy(this.getRuns('recalced', vehicle), this.getMileage), true)}</td>
            </tr>
        ], _.map(vehicle.drivers, this.renderDriverRow.bind(this, vehicle)));
    }

    renderDriverRow(vehicle, driver) {
        return _.concat([
            <tr key={`${vehicle.uuid}:${driver.uuid}`} className="align-center">
                <td className="wrap-nowrap align-left padding-left-20">{driver.name}</td>
                <td>{this.getCheckedProductionRuns('plan', vehicle, driver).length}/{this.getProductionRuns('plan', vehicle, driver).length}</td>
                <td>{this.getCheckedProductionRuns('operplan', vehicle, driver).length}/{this.getProductionRuns('operplan', vehicle, driver).length}</td>
                <td>{this.getCheckedProductionRuns('recalced', vehicle, driver).length}/{this.getProductionRuns('recalced', vehicle, driver).length}</td>
                <td>{this.getAllCheckedRuns('recalced', vehicle, driver).length}/{this.getProductionRuns('recalced', vehicle, driver).length}</td>
                <td>{this.formatDistance(_.sumBy(this.getProductionRuns('recalced', vehicle, driver), 'distance'))}/{this.formatDistance(_.sumBy(this.getProductionRuns('operplan', vehicle, driver), 'distance'))}</td>
                <td>{this.formatDistance(_.sumBy(this.getCheckedProductionRuns('recalced', vehicle, driver), this.getDist), true)}/{this.formatDistance(_.sumBy(this.getAllCheckedProductionRunsCorrection('recalced', vehicle, driver), this.getDist), true)}</td>
                <td>{this.formatDistance(_.sumBy(this.getRuns('recalced', vehicle, driver), 'distance'))}/{this.formatDistance(_.sumBy(this.getRuns('operplan', vehicle, driver), 'distance'))}</td>
                <td>{this.formatDistance(_.sumBy(this.getRuns('recalced', vehicle, driver), this.getMileage), true)}</td>
            </tr>
        ], _.map(driver.routes, this.renderRouteRow.bind(this, vehicle, driver)));
    }

    renderRouteRow(vehicle, driver, route) {
        return (
            <tr key={`${vehicle.uuid}:${driver.uuid}:${route.uuid}`} className="align-center">
                <td className="wrap-nowrap align-left padding-left-40">{route.name}</td>
                <td>{this.getCheckedProductionRuns('plan', vehicle, driver, route).length}/{this.getProductionRuns('plan', vehicle, driver, route).length}</td>
                <td>{this.getCheckedProductionRuns('operplan', vehicle, driver, route).length}/{this.getProductionRuns('operplan', vehicle, driver, route).length}</td>
                <td>{this.getCheckedProductionRuns('recalced', vehicle, driver, route).length}/{this.getProductionRuns('recalced', vehicle, driver, route).length}</td>
                <td>{this.getAllCheckedRuns('recalced', vehicle, driver, route).length}/{this.getProductionRuns('recalced', vehicle, driver, route).length}</td>
                <td>{this.formatDistance(_.sumBy(this.getProductionRuns('recalced', vehicle, driver, route), 'distance'))}/{this.formatDistance(_.sumBy(this.getProductionRuns('operplan', vehicle, driver, route), 'distance'))}</td>
                <td>{this.formatDistance(_.sumBy(this.getCheckedProductionRuns('recalced', vehicle, driver, route), this.getDist), true)}/{this.formatDistance(_.sumBy(this.getAllCheckedProductionRunsCorrection('recalced', vehicle, driver, route), this.getDist), true)}</td>
                <td>{this.formatDistance(_.sumBy(this.getRuns('recalced', vehicle, driver, route), 'distance'))}/{this.formatDistance(_.sumBy(this.getRuns('operplan', vehicle, driver, route), 'distance'))}</td>
                <td>{this.formatDistance(_.sumBy(this.getRuns('recalced', vehicle, driver, route), this.getMileage), true)}</td>
            </tr>
        );
    }

    getMileage(run) {
        return _.toNumber(_.get(run, 'mileage')) || 0;
    }

    getDist(run) {
        return _.toNumber(_.get(run, 'distance')) || 0;
    }

    formatDistance(distance, inMeters = true) {
        if (inMeters) {
            distance /= 1000;
        }
        return _.round(distance, 2);
    }

    findOrderExecution(run) {
        return _.find(this.props.order_executions, {order_run_uuid: run.uuid});
    }

    getCheckedRuns(source, vehicle = null, driver = null, route = null) {
        return _.filter(this.getRuns(source, vehicle, driver, route), {
            check_type: 'auto',
        });
    }

    getCheckedProductionRuns(source, vehicle = null, driver = null, route = null) {
        return _.filter(this.getRuns(source, vehicle, driver, route), (run) => {
            const orderExecution = this.findOrderExecution(run);

            return (_.get(orderExecution, 'is_checked') || (run.check_type === 'auto')) && run.vehicle_uuid && ((run.type === 'production_forward') || (run.type === 'production_reverse'));
        });
    }

    getAutoCheckedProductionRuns(source, vehicle = null, driver = null, route = null) {
        return _.filter(this.getRuns(source, vehicle, driver, route), (run) => {
           // console.log(run)
           // const orderExecution = this.findOrderExecution(run);

            return  (run.check_type === 'auto')  && ((run.type === 'production_forward') || (run.type === 'production_reverse'));
        });
    }

    getAllCheckedRuns(source, vehicle = null, driver = null, route = null) {
        return _.filter(this.getRuns(source, vehicle, driver, route), (run) => {

            const orderExecution = this.findOrderExecution(run);

           // console.log(run)

            if ((_.get(orderExecution, 'is_checked') || (run.check_type === 'auto')) && run.vehicle_uuid && ((run.type === 'production_forward') || (run.type === 'production_reverse'))) {
                return false;
            }

            return run.vehicle_uuid && run.is_checked;
        });
    }

    getAllCheckedProductionRuns(source, vehicle = null, driver = null, route = null) {
        return _.filter(this.getRuns(source, vehicle, driver, route), (run) => {
            const orderExecution = this.findOrderExecution(run);

           /* if ((_.get(orderExecution, 'is_checked') || (run.chfeck_type === 'auto')) && run.vehicle_uuid && ((run.type === 'production_forward') || (run.type === 'production_reverse'))) {
                return false;
            }*/

            return (run.is_checked === true) || run.vehicle_uuid && ((run.type === 'production_forward') || (run.type === 'production_reverse'));
        });
    }


    getAllCheckedProductionRunsCorrection(source, vehicle = null, driver = null, route = null) {
        return _.filter(this.getRuns(source, vehicle, driver, route), (run) => {
            // const orderExecution = this.findOrderExecution(run);
            /* if ((_.get(orderExecution, 'is_checked') || (run.chfeck_type === 'auto')) && run.vehicle_uuid && ((run.type === 'production_forward') || (run.type === 'production_reverse'))) {
                 return false;
             }*/
            return (
                run.is_checked
                && ['manual', 'semimanual'].includes(run.check_type)
                && ['production_forward', 'production_reverse'].includes(run.type)
            );
        });
    }

    getProductionRuns(source, vehicle = null, driver = null, route = null) {
        return _.filter(this.getRuns(source, vehicle, driver, route), (run) => {
            return (run.type === 'production_forward') || (run.type === 'production_reverse');
        })
    }

    getRuns(source, vehicle = null, driver = null, route = null) {
        const {order, order_recalc} = this.props;

        let runs;
        switch (source) {
            case 'plan':
                runs = _.filter(_.flatten(_.map(order.shifts, 'runs')), (run) => {
                    return run.original_date_from;
                });
                break;
            case 'operplan':
                runs = _.flatten(_.map(order.shifts, 'runs'));
                break;
            case 'recalced':
                runs = _.flatten(_.map(order_recalc.shifts, 'runs'));
                break;
        }

        if (vehicle) {
            runs = _.filter(runs, {vehicle_uuid: vehicle.uuid});
        } else {
            //runs = _.filter(runs, run => run.vehicle_uuid);
        }
        if (driver) {
            runs = _.filter(runs, {driver_uuid: driver.uuid});
        }
        if (route) {
            runs = _.filter(runs, {route_uuid: route.uuid});
        }

        return runs;
    }

    getStructure() {
        const {order_recalc, related} = this.props;

        const runs = _.flatten(_.map(order_recalc.shifts, 'runs'));

        return _.map(_.uniq(_.filter(_.map(runs, 'vehicle_uuid'))), (vehicleUuid) => {
            const vehicleRuns = _.filter(runs, {vehicle_uuid: vehicleUuid});

            return {
                uuid: vehicleUuid,
                name: related.getReact(vehicleUuid),
                drivers: _.map(_.uniq(_.filter(_.map(vehicleRuns, 'driver_uuid'))), (driverUuid) => {
                    const driverRuns = _.filter(vehicleRuns, {driver_uuid: driverUuid});

                    return {
                        uuid: driverUuid,
                        name: related.getReact(driverUuid),
                        routes: _.map(_.uniq(_.filter(_.map(driverRuns, 'route_uuid'))), (routeUuid) => {
                            return {
                                uuid: routeUuid,
                                name: related.getReact(routeUuid),
                            };
                        }),
                    };
                }),
            };
        });
    }
}
