import systems from "dictionaries/systems";

export { initialState } from './initial-state';
export const vehicleListFilters = {
    withActiveBnso: true,
    short: true,
    onlyApproved: true,
};

export const speeds = [
    1,
    2,
    4,
    8,
];

export const systemsOptions = systems;

export const currentSpeed = window.RNIS_SETTINGS.telematics_frequency ? window.RNIS_SETTINGS.telematics_frequency * 1000 : 15000;

export const unitsResponseData = [
    'items/uuid',
    'items/name',
    'items/component',
    'items/color',
    'items/communal_municipality_uuid',
    'items/parent_unit_uuid',
];

export const stopPointsResponseData = [
        'items/uuid',
        'items/title',
        'items/latitude',
        'items/longitude',
        'items/register_number',
];

export const driversResponseData = [
    'items/uuid',
    'items/info/surname',
    'items/info/name',
    'items/info/second_name',
    'items/info/phone',
];

export const filtersMap = {
    'vehicle_unit': {
        path: 'filters.extended.subjects.units',
        modifiersPath: {
            with_children: 'filters.extended.subjects.modificators.with_children',
            balanceholder: 'filters.extended.subjects.modificators.balanceholder',
            dislocation: 'filters.extended.subjects.modificators.dislocation',
            rent: 'filters.extended.subjects.modificators.rent',
            own: 'filters.extended.subjects.modificators.own',
        },
    },
    'vehicle_work_unit': {
        path: 'filters.extended.works.units',
        modifiersPath: {
            with_children: 'filters.extended.works.modificators.with_children',
        },
    },
    'vehicle_work_carrier': {
        path: 'filters.extended.works.carriers',
        modifiersPath: {
            with_children: 'filters.extended.works.modificators.with_children',
        },
    },
    'vehicle_work_order': {
        path: 'filters.extended.works.orders',
    },
    'vehicle_work_task': {
        path: 'filters.extended.works.tasks',
    },
    'vehicle_work_kurs_contract': {
        path: 'filters.extended.works.kurs_contracts',
    },
    'vehicle_work_type': {
        path: 'filters.extended.works.work_types',
    },
    'vehicle_work_route_kind': {
        path: 'filters.extended.works.route_kinds',
    },
    'vehicle_work_rate_type': {
        path: 'filters.extended.works.rates_types',
    },
    'vehicle_work_contract': {
        path: 'filters.extended.works.contracts',
    },
    'vehicle_work_route': {
        path: 'filters.extended.works.routes',
    },
    'vehicle_work_driveway_category': {
        path: 'filters.extended.works.driveway_categories',
    },
    'vehicle_work_in_work': {
        path: 'filters.extended.works.in_work',
    },
    'vehicle_work_driver': {
        path: 'filters.extended.works.drivers',
    },
    'vehicle_uuid': {
        path: 'filters.extended.parameters.vehicles',
    },
    'vehicle_malfunction': {
        path: 'filters.extended.parameters.malfunction',
    },
    'vehicle_mechanism_type': {
        path: 'filters.extended.parameters.kurs_mechanism_types',
    },
    'vehicle_parameter': {
        path: 'filters.extended.parameters.parameters',
    },
    'vehicle_type': {
        path: 'filters.extended.parameters.vehicle_types',
    },
    'vehicle_capacity_type': {
        path: 'filters.extended.parameters.vehicle_capacity_types',
    },
    'vehicle_gatn_responsive': {
        path: 'filters.extended.parameters.responsives',
    },
    'vehicle_telematics_position': {
        path: 'filters.extended.state.placements',
        modifiersPath: {
            geozone_inverse: 'filters.extended.state.modificators.inverse',
        },
    },
    'vehicle_telematics_port': {
        path: 'filters.extended.state.bnso_indicators',
        modifiersPath: {
            inverse_ports: 'filters.extended.state.modificators.inverse_ports',
        },
    },
    'vehicle_telematics_signal': {
        path: 'filters.extended.state.signal',
    },
    'vehicle_telematics_satellite': {
        path: 'filters.extended.state.satellites_level',
    },
};

export const vehiclePopupDefaultValues = (capacity) => ({
    speed: true,
    overspeed: true,
    base: true,
    extended: true,
    model: true,
    type: true,
    environment: true,
    capacity,
    garage_number: true,
    unit: true,
    parent_unit: true,
    //to: true,
    driver: false,
    //diff: true,
    order: false,
    route: false,
    done: false,
    done_road: false,
    bnso_number: true,
    bnso_model: true,
    driver_tab_num: false,
    state_number: false,

    // хинт при наведении
    speed_tp: true,
    overspeed_tp: true,
    base_tp: true,
    extended_tp: true,
    model_tp: true,
    type_tp: true,
    environment_tp: true,
    capacity_tp: capacity,
    garage_number_tp: true,
    unit_tp: true,
    parent_unit_tp: true,
    driver_tp: false,
    order_tp: false,
    route_tp: false,
    done_tp: false,
    done_road_tp: false,
    bnso_number_tp: true,
    bnso_model_tp: false,
    driver_tab_num_tp: false,
    state_number_tp: false,

    // постоянный хинт (серый)
    speed_ptp: false,
    overspeed_ptp: false,
    base_ptp: false,
    extended_ptp: false,
    model_ptp: false,
    type_ptp: false,
    environment_ptp: false,
    capacity_ptp: false,
    garage_number_ptp: false,
    unit_ptp: false,
    parent_unit_ptp: false,
    driver_ptp: false,
    order_ptp: false,
    route_ptp: false,
    done_ptp: false,
    done_road_ptp: false,
    bnso_number_ptp: false,
    bnso_model_ptp: false,
    state_number_ptp: true,
    driver_tab_num_ptp: false
});

export const dictionaries = [
    'vehicle_models',
    'vehicle_marks',
    'vehicle_types',
    'bnso_types',
    'maintenance_groups',
    'communal_municipalities',
    'bnso_indicators',
    'vehicle_capacity_types',
    'vehicle_environmental_classes'
];

export const historyColor = [
    'rgb(255,0,0)',
    'rgb(255,128,0)',
    'rgb(128,00,255)',
    'rgb(0,255,0)',
    'rgb(0,0,255)',
]