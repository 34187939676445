import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import _ from 'lodash';
import debounce from 'throttle-debounce/debounce';

import './TableColumnFilterBase.less';
import $ from 'jquery';
import Datepicker from "components/ui/form/datepicker";
import moment from "moment";
import formats from "dictionaries/formats";
import {isMosreg, isLocalhost} from "helpers/functions";

@propTypes({
    index: PropTypes.number.isRequired,
    column: PropTypes.object.isRequired,
    opened: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onFilterOpened: PropTypes.func,
    classNameRoot: PropTypes.string
})

@defaultProps({
    opened: false,
    onFilterOpened: () => {
    }
})

export class TableColumnFilterBase extends Component {

    state = {
        visible: false,
    };

    constructor(...args) {
        super(...args);

        this.onClickDocument = ::this._onClickDocument;
    }

    componentDidMount() {
        $(document).on('click', this.onClickDocument);
    }

    componentWillUnmount() {
        $(document).off('click', this.onClickDocument);
    }

    _onClickDocument(e) {
        if ($(e.target).closest('.b-table__filetr').length === 0) {
            this.setState({
                visible: false,
            });
        }
    }

    componentWillMount() {
        this.setState({
            visible: this.props.opened
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.opened
        });
    }

    hasValue() {
        return this.state.value;
    }

    render() {
        const btnClassName = classNames({
            'b-table__icon-filter': true,
            'b-table__icon-filter_big': isMosreg() || isLocalhost(),
            'active': this.state.visible || this.hasValue(),
        });

        const blockClassName = classNames({
            'filtration': true,
            'visible': this.state.visible,
        });

        return (
            <div className={`b-table__filetr ${this.props.classNameRoot ? this.props.classNameRoot : ''}`}>
                <a href="#" className={btnClassName} onClick={::this.toggleBlock}/>

                <div className={blockClassName}>
                    {this.renderContent()}
                </div>
            </div>
        );
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    onChange(uuid) {
        let selected = this.state.selected;
        const position = selected.indexOf(uuid);
        if (position === -1) {
            selected.push(uuid);
        } else {
            selected.splice(position, 1);
        }
        this.setState({selected});
        this.props.onChange(this.props.index, selected);
    }

    toggleBlock(e) {
        e.preventDefault();
        e.stopPropagation();

        const visible = !this.state.visible;

        this.setState({
            visible: visible,
        });

        this.props.onFilterOpened(this.props.column.field, visible);
    }

    onDateChange({target: {value}}) {
        this.setState({
            value,
        });

        this.props.onChange(this.props.index, value ? moment(value).format(formats.DATE) : null);
    }

    async onSearchChange() {
        this.setState({value: this.refs.search.value});
        this.props.onChange(this.props.index, this.refs.search.value);
    }
}