import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import Loader from "components/ui/loader";
import ScheduleTable from './ScheduleTable';
import ShiftInfo from './ShiftInfo';
import {getRouteVariants} from "store/reducers/routes/route_variants";
import {getStopPoints} from "store/reducers/geo/stop-points";
import {CycleFetch} from "helpers/api";

import './style.less';
import {getOrderExecutions} from "store/reducers/kiutr/orders/order_executions";
import {getGroupsFromRoute} from "helpers/kiutr";
import Select from "components/ui/select";

@propTypes({
    data: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired
})

@connect(state => ({}), {getRouteVariants, getStopPoints, getOrderExecutions})

export default class RouteSchedule extends React.Component {

    _cycleFetch = null;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            turn: 1,
            defaultVariant: null,
            defaultStopPoints: {},
            routeVariants: [],
            orderExecutions: [],
            openedShifts: {}
        };
    }

    componentWillMount() {
        this.loadData();
    }

    componentWillUnmount() {
        this._cycleFetch.stop();
        delete this['_cycleFetch'];
    }

    async loadOrderExecutions(orderRunUuids) {
        const meta = {
            filters: {
                withOrderRuns: orderRunUuids,
            }
        };
        const response = await this.props.getOrderExecutions(meta);
        if (response.isOk) {
            this.setState({
                orderExecutions: response.payload.items,
            });
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadOrderExecutionsOld(orderUuids) {
        const meta = {
            filters: {
                withOrders: orderUuids,
            }
        };
        const response = await this.props.getOrderExecutions(meta);
        if (response.isOk) {
            this.setState({
                orderExecutions: response.payload.items,
            });
        }
        else {
            response.showErrors();
            return null;
        }
    }

    async loadRouteVariant() {
        const meta = {
            filters: {
                withRoute: this.props.data.route.uuid
            }
        };

        const response = await this.props.getRouteVariants(meta);
        if (response.isOk) {
            const defaultVariant = getGroupsFromRoute(this.props.data.route, response.payload.items);
            return {
                defaultVariant,
                routeVariants: response.payload.items,
            };
        } else {
            response.showErrors();
            return null;
        }
    }

    async loadStopPoints(uuids) {
        const meta = {
            filters: {
                withUuid: _.uniq(_.filter(uuids))
            }
        };

        const response = await this.props.getStopPoints(meta);
        if (response.isOk) {
            return _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'title');
        } else {
            response.showErrors();
            return null;
        }
    }

    async loadData() {
        this.setState({isLoading: true});

        this._cycleFetch = new CycleFetch(() => {
            if (this.props.data.isOld) {
                return this.loadOrderExecutionsOld(_.map(_.filter(this.props.data.orders, {turn: this.state.turn}), 'uuid'));
            } else {
                return this.loadOrderExecutions(_.map(_.flatten(_.map(_.flatten(_.map(_.filter(this.props.data.orders, {turn: this.state.turn}), 'shifts')), 'runs')), 'uuid'));
            }
        }, () => {
        }, 60000);
        this._cycleFetch.run();

        const data = await this.loadRouteVariant();

        if (data) {
            const {defaultVariant, routeVariants} = data;

            const defaultStopPoints = await this.loadStopPoints(_.map(defaultVariant, 'stop_point_uuid'));

            //console.log(defaultVariant);
            //console.log(routeVariants);
            //console.log(defaultStopPoints);

            if (defaultStopPoints) {

                this.setState({
                    isLoading: false,
                    defaultVariant,
                    defaultStopPoints,
                    routeVariants,
                });
            }
        }

        this.setState({
            isLoading: false,
        });
    }

    getOpenedShiftKey(uuid, date, turn, shift) {
        return uuid + '_' + date + '_' + turn + '_' + shift;
    }

    onOpened(uuid, date, turn, shift) {
        const openedShifts = this.state.openedShifts;
        const key = this.getOpenedShiftKey(uuid, date, turn, shift);
        openedShifts[key] = true;
        this.setState({openedShifts});
    }

    renderShift(order, shift) {
        const productionForwardRuns = _.filter(shift.runs, {type: 'production_forward'});
        const productionReverseRuns = _.filter(shift.runs, {type: 'production_reverse'});

        if (!productionForwardRuns.length && !productionReverseRuns.length) {
            return null;
        }

        const data = {
            defaultVariant: this.state.defaultVariant,
            defaultStopPoints: this.state.defaultStopPoints,
            routeVariants: this.state.routeVariants,
            date: this.props.date,
            onMapClick: this.props.data.onMapClick,
            isCurrent: this.props.data.isCurrent,
            orderExecutions: this.getOrderExecutionsForOrder(order),
        };

        return (
            <div>
                {productionForwardRuns.length ? <div className="route-shift_table">
                    <ScheduleTable
                        data={{...data, ...{runs: productionForwardRuns, direction: 'forward'}}}/>
                </div> : null}
                {productionReverseRuns.length ? <div className="route-shift_table">
                    <ScheduleTable
                        data={{...data, ...{runs: productionReverseRuns, direction: 'reverse'}}}/>
                </div> : null}
            </div>
        );
    }

    renderTurn(turn) {
        const {data, date} = this.props;

        let order = null;
        if (data.isOld) {
            order = _.find(this.props.data.orders, {
                'turn': parseInt(turn.number),
            });
            if (!order) {
                return;
            }
        } else {
            order = {
                route_uuid: data.route_uuid,
                shifts: [],
            };
            let currentShift = {
                shift: 1,
                runs: [],
            };
            _.each(turn.runs, (run) => {
                if (run.type === 'reshift') {
                    order.shifts.push(_.cloneDeep(currentShift));
                    currentShift.shift++;
                    currentShift.runs = [];
                    return;
                }
                const orderWithRun = _.find(this.props.data.orders, (order) => {
                    return _.indexOf(order.schedule_turn_runs, run.uuid) !== -1;
                });
                if (!orderWithRun) {
                    return;
                }
                const orderRun = _.find(_.flatten(_.map(orderWithRun.shifts, 'runs')), {
                    schedule_turn_run_uuid: run.uuid,
                });
                if (orderRun) {
                    currentShift.runs.push(orderRun);
                }
            });
            order.shifts.push(_.cloneDeep(currentShift));
        }

        return (
            <div>
                {order.shifts.map((shift, index) => {
                        return (
                            <div key={index}>
                                <ShiftInfo
                                    params={this.props.params}
                                    data={{
                                        date,
                                        order,
                                        shift,
                                        turn,
                                        orders: this.props.data.orders,
                                        vehicleCapacityTypes: data.vehicleCapacityTypes,
                                        vehicleEnvironmentClasses: data.vehicleEnvironmentClasses,
                                        vehicleModels: data.vehicleModels,
                                        onRTiOClick: data.onRTiOClick,
                                        isCurrent: data.isCurrent,
                                        orderExecutions: this.getOrderExecutionsForOrder(order),
                                    }}
                                    load={true}
                                />

                                {this.renderShift(order, shift)}
                            </div>
                        )
                    }
                )}
            </div>
        );
    }

    getOrderExecutionsForOrder(order) {
        if (this.props.data.isOld) {
            return _.filter(this.state.orderExecutions, {order_uuid: order.uuid});
        }

        return this.state.orderExecutions;
    }

    getTurnLabel(turn) {
        return `Выход ${turn.number}`;
    }

    async onTurnChange(e) {
        const value = e ? e.value : null;
        await this.setState({
            turn: value,
        });

        this._cycleFetch && this._cycleFetch.forceNext();
    }

    renderContent() {
        const {date, data} = this.props;

        return (
            <div className="page-block outputs">
                <div className="page-block__caption"><Select
                    value={this.state.turn}
                    clearable={false}
                    onChange={::this.onTurnChange}
                    options={_.map(_.sortBy(this.props.data.schedule.turns, turn => _.toInteger(turn.number)), turn => ({
                        value: _.toInteger(turn.number),
                        label: this.getTurnLabel(turn),
                    }))}
                /> за {date} по маршруту №{_.get(data, 'route.number')}
                    &nbsp;
                    ({_.get(data, 'route.title')})
                </div>

                {_.filter(this.props.data.schedule.turns, {
                    number: this.state.turn.toString(),
                }).map((turn, index) =>
                    <div key={index}>
                        {this.renderTurn(turn)}
                    </div>
                )}
            </div>
        );
    }

    render() {
        return this.state.isLoading ? <div className="route-schedule_loader">
                <Loader color="red" size={32}/>
            </div>
            : <div>
                {this.renderContent()}
            </div>
    }
}