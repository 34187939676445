import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';


import _ from 'lodash';
import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import moment from "moment";
import formats from "dictionaries/formats";
import {EntityList} from "helpers/entity";
import systems from "dictionaries/systems";
import {deleteNotificationEvent, getNotificationEvents} from "store/reducers/notifications";
import notifications from "dictionaries/notifications";
import {getEntityNames} from "store/reducers/system";

@connect(state => ({}), {getNotificationEvents, deleteNotificationEvent, getEntityNames})

export default class NotificationEventsComponent extends BaseTableWithEditorComponent {

    title = `${systems.administration} → Фильтры оповещений`;
    getBaseUrl() {
        return `/operator/notification_events/${this.state.entityClass.replace(/\\/g, '_')}/${this.state.entityUuid}`;
    }

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            users: {},
            columns: List(this.getColumns()),
            entityUuid: props.params.entityUuid,
            entityClass: props.params.entityClass.replace(/_/g, '\\'),
        });
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                entityUuid={this.state.entityUuid}
                entityClass={this.state.entityClass}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Событие')
                .fromField('event')
                .withDrawer((item) => _.get(_.get(notifications.events, this.state.entityClass, []), item.event)),

            new Column('Типы оповещений')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer((item) => _.map(item.via, (via) => _.get(notifications.via, via)).join(', ')),

            new Column('Пользователи')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer((item) => _.map(item.users, (user) => this.state.related.get(user)).join('<br/>')),

            new Column('Роли')
                .denyOrder()
                .denyColumnFilter()
                .withClassName('no-wrap')
                .withWidth('500px')
                .withDrawer((item) => _.map(item.roles, (role) => this.state.related.get(role)).join('<br/>')),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteNotificationEvent(data);
    }

    async loadData(meta) {
        _.set(meta, 'filters.withEntity', [this.state.entityClass, this.state.entityUuid]);

        return await this.props.getNotificationEvents(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const users = _.filter(_.flatten(_.map(result, 'users')));
        const roles = _.filter(_.flatten(_.map(result, 'roles')));
        const response = await this.props.getEntityNames(_.concat(_.map(users, (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        })), _.map(roles, (uuid) => ({
            class: 'App\\Model\\Role',
            uuid: uuid,
            source: 'auth',
        }))));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

}