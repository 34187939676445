import React, {Component} from 'react';
import {List} from 'immutable';
import {connect} from 'react-redux';
import classNames from 'classnames';

import _ from 'lodash';

import BaseTableComponent from 'components/base/base_table';
import Page from 'components/ui/page';
import {Link} from 'react-router';
import ReactDOMServer from 'react-dom/server';

import {mapDatatablesRequestToMeta} from 'helpers/api';

import './user-logs.less';

import TableContainer from "components/ui/Table/Container/TableContainer";
import Column from "components/ui/column";
import IconButton from "components/ui/icon-button";
import {getUsers} from "store/reducers/staffing/staffing";
import moment from "moment";
import formats from "dictionaries/formats";
import {getUserLogCodes, getUserLogs} from "store/reducers/user-logs/user-logs";
import {User} from "helpers/user";
import {getEntityNames} from "store/reducers/system";
import systems from "dictionaries/systems";
import ContextTooltip from "components/ui/context-tooltip";
import TableColumnSelectFields from "components/ui/Table/Column/SelectFields/TableColumnSelectFields";
import TableComponent from "components/ui/Table/Component/TableComponent";

@connect(state => ({
    log_codes: state.user_logs.get('log_codes'),
}), {getUserLogs, getUsers, getUserLogCodes, getEntityNames})

export default class UserLogsComponent extends BaseTableComponent {

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            users: {},
            columns: List(this.getColumns())
        });
    }

    componentWillMount() {
        this.props.getUserLogCodes();
    }

    renderTable() {
        const className = classNames({
            'show-table-filters': this.state.showTableFilters,
        });

        return (
            <div className={className}>
                <TableComponent
                    ref="table"
                    columns={this.state.columns}
                    select="none"
                    loadCallback={::this.loadCallbackMiddleware}
                    onDblClick={::this.onDblClick}
                    onColsReordered={::this.onColsReordered}
                    checkCache={::this.checkCache}
                    onCheck={::this.selectedRowsRecalc}
                    query={this.state.query}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    defaultOrder={[[0, 'desc']]}
                />
            </div>
        );
    }

    render() {
        let table = this.renderTable();

        return (
            <Page title={`${systems.administration} → Журнал событий`}
                  pageId="UserLogs"
                  headerActions={this.renderHeaderActions()}
                  headerContents={this.renderHeaderContents()}>

                <TableContainer>
                    {table}
                </TableContainer>
            </Page>
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Дата/время')
                .fromField('timestamp')
                .withDateFilter()
                .withDrawer(item => moment(item.timestamp).format(formats.DATETIME)),

            new Column('Пользователь')
                .fromField('user_uuid')
                .withDrawer(item => (_.get(this.state.users, item.user_uuid) || '<code><nobr>-неизвестно-</nobr></code>') + ` (${item.ip || '-'})`)
                .denyColumnFilter()
                .withAsyncFilter('withUsers', async (search) => {
                    const response = await this.props.getUsers({
                        search,
                        order: {
                            column: 'surname',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 50,
                        },
                    });
                    if (response.isOk) {
                        return _.map(response.payload.items, user => ({
                            uuid: user.uuid,
                            name: new User(user).getFullName()
                        }));
                    }
                    return [];
                }),

            new Column('Событие')
                .fromField('code')
                .withDrawer(item => this.getLogCode(item))
                .withFilter('withCodes', async () => {
                    await this.props.getUserLogCodes();

                    let result = [];
                    _.each(this.props.log_codes, (name, uuid) => {
                        result.push({uuid, name});
                    });
                    return result;
                }),

            new Column('Комментарий')
                .fromField('comment'),

        ]);
    }

    getLogCode(item) {
        const code = this.props.log_codes[item.code];
        const entity = this.getLogEntity(item);

        return ReactDOMServer.renderToStaticMarkup(<span>{code}{entity ? <span><br/>{entity}</span> : ''}</span>);
    }

    getLogEntity(item) {
        if (!item.entity_uuid || !item.entity_class) {
            return null;
        }

        let text = null;
        switch (item.entity_class) {
            case 'App\\Model\\User':
                text = <Link href={`/staffing/${item.entity_uuid}/view`}>перейти</Link>;
                break;
        }

        return (<span>
            {text}
            {' / '}
            <Link href={`/system/audit/${item.entity_uuid}?class=${item.entity_class}`}>журнал аудита</Link>
        </span>);
    }

    async reload() {
        this.refs.table && this.refs.table.getWrappedInstance().reload();
    }

    async loadCallback(request, drawCallback, settings) {
        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        const response = await this.props.getUserLogs(meta);

        if (response.isOk) {
            const data = await this.prepareData(response.payload.items);

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: response.data.headers.meta.pagination.total,
                recordsTotal: response.data.headers.meta.pagination.total
            };

            drawCallback(json);
            this.selectedRowsRecalc();
            this.setState({success: true});
        } else {
            response.showErrors();
        }
    }

    async prepareData(data) {
        const result = data;

        const users = _.filter(_.map(result, 'user_uuid'));
        const response = await this.props.getEntityNames(_.map(users, (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        })));
        if (response.isOk) {
            this.setState({
                users: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'name'),
            });
        }

        return result;
    }

    renderHeaderActions() {
        return [];
    }

    renderHeaderContents() {
        return [
            <ContextTooltip key="base-table-list.filter" code="base-table-list.filter">
                <IconButton key="filter" icon="filter" tooltip="Фильтр" active={this.state.showTableFilters}
                            onClick={::this.toggleTableFilters}/>
            </ContextTooltip>,
            <ContextTooltip key="base-table-list.columns-select" code="base-table-list.columns-select">
                <TableColumnSelectFields
                    key="select-fields-popup"
                    columns={this.state.columns}
                    showPopup={this.state.showSelectFieldsPopup}
                    onChange={::this.onChangeSelectFieldsCheckbox}
                    onToggle={::this.onToggleSelectFieldsPopup}/>
            </ContextTooltip>,
        ];
    }
}