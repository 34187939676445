import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import Colors from '../../helpers/colors';

import './spinner.less'


@propTypes({
  score: PropTypes.number,
  max: PropTypes.number,
  text: PropTypes.string
})

@defaultProps({
  text: 'баллов'
})

export class ScoreSpinner extends Component {

  render() {
    const props = {
      ...this.props,
      className: 'Spinner-Score',
      progress: Math.ceil(this.props.score / (this.props.max / 100))
    };

    return (
      <Spinner {...props}>
        <div>
          <div>{this.props.score}<span>/{this.props.max}</span></div>
          <span>{this.props.text}</span>
        </div>
      </Spinner>
    )
  }

}


@propTypes({
  progress: PropTypes.number
})

export class PercentSpinner extends Component {

  render() {
    const props = {
      ...this.props,
      className: 'Spinner-Percent'
    };

    return (
      <Spinner {...props}>
        {this.props.progress}<span>%</span>
      </Spinner>
    )
  }

}



@propTypes({
  progress: PropTypes.number,
  color: PropTypes.oneOf(Colors.All),
  fill: PropTypes.oneOf(Colors.All),
  className: PropTypes.string
})

@defaultProps({
  progress: 10,
  color: Colors.red,
  fill: Colors.asphalt
})

export default class Spinner extends Component {

  render() {
    const size = 80;
    const miterLimit = 20;
    const strokeWidth = 10;

    const cx = size / 2;
    const cy = size / 2;

    const r = (size - strokeWidth) / 2;

    const total = Math.PI * (size - strokeWidth * 2);
    const start = total / size * (this.props.progress) - 15;

    const circleStyle = {
      stroke: this.props.color,
      strokeLinecap: 'round',
      transition: 'all 0.3s linear 0ms',
      strokeDasharray: `${start} ${total}`
    };

    const className = classNames('Spinner', this.props.className);

    return (
      <div className={className} style={{width: `${size}px`, height: `${size}px`}}>
        <svg viewBox={`0 0 ${size} ${size}`} style={{ transform: 'rotate(-85deg)', width: `${size}px`, height: `${size}px`, position: 'relative'}}>
          <circle
            cx={cy}
            cy={cx}
            r={r}
            fill="none"
            strokeWidth={strokeWidth-1}
            strokeMiterlimit={miterLimit}
            stroke={this.props.fill}
          />
          <circle
            cx={cx}
            cy={cy}
            r={r}
            fill="none"
            strokeWidth={strokeWidth}
            strokeMiterlimit={miterLimit}
            style={circleStyle}
          />
        </svg>

        <div className="text">
          {this.props.children}
        </div>
      </div>
    )
  }

}
