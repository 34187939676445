import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import debounce from 'throttle-debounce/debounce';

import {TableColumnFilterBase} from "components/ui/Table/Column/Filter/TableColumnFilterBase";
import Select from "components/ui/select";

@propTypes({
    index: PropTypes.number.isRequired,
    column: PropTypes.object.isRequired,
    opened: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onFilterOpened: PropTypes.func
})

@defaultProps({
    opened: false,
    onFilterOpened: () => {
    }
})

export class TableColumnFilterNumber extends TableColumnFilterBase {

    searchCallbackDebounce = debounce(1000, ::this.onSearchChange);

    options = [
        {
            value: 'eq',
            label: 'Равно',
        },
        {
            value: 'gt',
            label: 'Больше',
        },
        {
            value: 'gte',
            label: 'Больше или равно',
        },
        {
            value: 'lt',
            label: 'Меньше',
        },
        {
            value: 'lte',
            label: 'Меньше или равно',
        },
    ];

    constructor(...args) {
        super(...args);

        Object.assign(this.state, {
            value: '',
            compare: 'eq',
        });
    }

    componentWillMount() {
        super.componentWillMount();

        if (this.props.column.filterValue) {
            const filterValue = this.props.column.filterValue.split(';');

            if (filterValue.length === 3) {
                this.setState({
                    value: filterValue[2],
                    compare: filterValue[1],
                });
            }
        }
    }

    async componentDidUpdate() {
        if ($(".top-menu__link.top-menu__link_icon_filter.active").length === 0 && this.state.value.length > 0) {
            this.setState({
                value: '',
                compare: 'eq',
            })
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    async onSearchChange() {
        this.setState({value: this.refs.search.value});

        const value = `number;${this.state.compare};${this.refs.search.value}`;
        this.props.onChange(this.props.index, value);
    }

    onChangeCompare(e) {
        const value = e ? e.value : null;

        this.setState({
            compare: value,
        });
    }

    renderContent() {
        return (
            <div>
                <div className="filtration__search">
                    <Select value={this.state.compare} options={this.options} onChange={::this.onChangeCompare}/>
                </div>
                <div className="filtration__search">
                    <input type="text" ref="search" className="filtration__pole" placeholder="Поиск"
                           onChange={this.searchCallbackDebounce} onKeyDown={::this.handleKeyPress}/>
                    <input type="button" className="filtration__button"/>
                </div>
            </div>
        );
    }
}