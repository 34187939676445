import {List, Map} from 'immutable';

import {api} from 'helpers/api';
import {isAuthorized, checkToken as sessionCheckToken, resetCheckToken as sessionResetCheckToken} from 'helpers/api/session';
import {success, error} from 'helpers/response';
import {User} from "helpers/user";
import * as storage from "utils/storage";

const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const GET_USER = 'GET_USER';

const initialState = new Map({
    isAuthorized: isAuthorized(),
    user: new Map({})
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            api.session.saveCredits(action.payload);
            return state.set('isAuthorized', true);
        case LOGOUT_SUCCESS:
            return state.set('isAuthorized', false);
        case GET_USER:
            return state.set('user', new User(action.payload));
    }
    return state;
}

export const getUser = (uuid) => async (dispatch) => {
    try {
        let response = await api.auth.getUser(uuid);

        dispatch({
            type: GET_USER,
            payload: response.payload
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const refreshUser = (uuid) => async (dispatch) => {
    try {
        let response = await api.auth.refreshUser(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const login = (username, password) => async (dispatch) => {
    try {
        let response = await api.session.login(username, password);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: response.payload
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const esiaToRnisUserConfirm = (payload) => async (dispatch) => {
    try {
        const response = await api.session.esiaToRnisUserConfirm(payload);
        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const esiaLogin = (payload) => async (dispatch) => {
    try {
        let response = await api.session.esiaLogin(payload);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: response.payload
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getEsiaUrl = (host) => async (dispatch) => {
    try {
        let response = await api.session.getEsiaUrl(host);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getMaintenanceStatus = () => async (dispatch) => {
    try {
        const response = await api.system.getMaintenanceStatus();

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const maintenanceUp = (message, retry = 60) => async (dispatch) => {
    try {
        const response = await api.system.maintenanceUp({message, retry});

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const maintenanceDown = () => async (dispatch) => {
    try {
        const response = await api.system.maintenanceDown();

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const checkToken = () => async (dispatch) => {
    sessionCheckToken().then((response) => {
        if ( ! response) {
            sessionResetCheckToken();
            dispatch({
                type: LOGOUT_SUCCESS
            });
        }
    });

};

export const logout = () => async (dispatch) => {
    try {
        let response = await api.session.logout();

        storage.set('auth-from-logout');
        storage.remove('menu');

        dispatch({type: LOGOUT_SUCCESS});

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};