import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import {connect} from 'react-redux';
import MapComponent from "components/ui/map";
import {getHistory} from "store/reducers/maps";
//import TelematicsDevice from "components/modules/maps/telematics-packet";
import DirectionalPolyline from "components/ui/map/objects/directional-polyline";
import HistoryPointMarker from "components/ui/map/markers/history-point-marker";
//import VehicleMarker from "components/ui/map/markers/vehicle-marker";
import moment from 'moment';
import formats from 'dictionaries/formats';
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import currentUser from 'helpers/current-user';

@propTypes({
    data: PropTypes.object
})

@connect(state => ({}), {getHistory})

export default class ShiftMap extends Component {

    componentDidMount() {
        this.forceUpdate();

        setTimeout(() => {
            const {history, points} = this.props.data;

            if (points && points.length) {
                let coordinates = [];
                _.each(points, (point) => {
                    coordinates = _.concat(coordinates, _.get(point, 'path_to_the_next_point_geometry.coordinates', []));
                });
                const geojson = {
                    type: 'LineString',
                    coordinates,
                };
                const group = L.geoJSON(geojson);
                this.refs.map && this.refs.map.getWrappedInstance().fitBounds(group.getBounds());
            } else if (history.length) {

                const start = _.first(history);
                const end = _.last(history);

                const bounds = [
                    [start.lat, start.lng],
                    [end.lat, end.lng]
                ];

                this.refs.map && this.refs.map.getWrappedInstance().fitBounds(bounds);
            }
        }, 100);
    }

    componentWillUpdate(props) {
        if (!_.isEqual(props.points, this.props.points)) {
            const points = props.points;
            
            if (points.length) {
                let coordinates = [];
                _.each(points, (point) => {
                    coordinates = _.concat(coordinates, _.get(point, 'path_to_the_next_point_geometry.coordinates', []));
                });
                const geojson = {
                    type: 'LineString',
                    coordinates,
                };
                const group = L.geoJSON(geojson);
                this.refs.map && this.refs.map.getWrappedInstance().fitBounds(group.getBounds());
            }
        }
    }

    render() {
        const {history, points} = this.props.data;
        const start = _.first(history);
        const end = _.last(history);

        return (
            <MapComponent
                ref="map"
                showLayers={false}
            >
                {(this.refs.map) ? ([
                    (history.length > 0) ? ([
                        <DirectionalPolyline
                            key="path"
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            coordinates={this.getHistoryCoordinates()}
                            isNeedArrows
                        />,
                        (0) ? (
                            _.map(history, (point, index) => {
                                return [
                                    <HistoryPointMarker
                                        key={index}
                                        map={this.refs.map}
                                        leafletMap={this.refs.map.getWrappedInstance().map}
                                        title={moment.unix(point.type).format(formats.DATETIME)}
                                        latitude={point.lat}
                                        longitude={point.lng}
                                    />,
                                ];
                            })) : null,
                        <HistoryPointMarker
                            key="start"
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            latitude={_.first(history).lat}
                            longitude={_.first(history).lng}
                            title={`Начало: ${moment.unix(start.type).add(currentUser.user.is_timezoneoffset, 'hours').format(formats.DATETIME)}<br/>Координаты: ${start.lat}, ${start.lng}<br/>Скорость: ${start.speed} км/ч`}
                        />,
                        <HistoryPointMarker
                            key="end"
                            map={this.refs.map}
                            leafletMap={this.refs.map.getWrappedInstance().map}
                            latitude={_.last(history).lat}
                            longitude={_.last(history).lng}
                            title={`Окончание: ${moment.unix(end.type).format(formats.DATETIME)}<br/>Координаты: ${end.lat}, ${end.lng}<br/>Скорость: ${end.speed} км/ч`}
                        />,
                    ]) : null,
                    _.map(points, ::this.renderPath),
                    //(window.location.hostname === 'localhost') ? _.map(this.props.data.orderExecution.data, ::this.renderOrderExecutionPoint) : null,
                ]) : null}
            </MapComponent>
        );
    }

    renderPath(point, index) {
        const {stopPoints} = this.props.data;

        const path = point.path_to_the_next_point_geometry;
        if (point.point_type !== 'stop_point') {
            return;
        }

        const size = ((index === 0) || (index === this.props.data.points.length - 1)) ? 1.5 : 1;

        return [
            path ? (
                <DirectionalPolyline
                    key={`${point.point_type}:${index}:${point.type_uuid}:${point._uuid}`}
                    map={this.refs.map}
                    leafletMap={this.refs.map.getWrappedInstance().map}
                    color="gray"
                    coordinates={_.map(path.coordinates, (coord) => _.clone(coord).reverse())}
                    interactive={false}
                />
            ) : null,
            <StopPointMarker
                key={`${point.type_uuid}:${index}`}
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                latitude={point.latitude}
                longitude={point.longitude}
                item={stopPoints[point.type_uuid]}
                size={size}
            />,
        ];
    }

    renderOrderExecutionPoint(point, index) {
        return (
            <HistoryPointMarker
                key={index}
                map={this.refs.map}
                leafletMap={this.refs.map.getWrappedInstance().map}
                latitude={point.latitude}
                longitude={point.longitude}
                title={point.time_fact ? `Отмечена: ${moment(point.time_fact).format(formats.TIME)}` : (point.is_skipped ? 'Пропуск' : 'Отмечена')}
            />
        );
    }

    getHistoryCoordinates() {
        return _.map(this.props.data.history, (point) => ([
            point.lat,
            point.lng,
        ]));
    }
}
