import React, { Component } from 'react';
import {connect} from "react-redux";
import { withRouter } from "react-router";
import Page from 'components/ui/page';
import TableContainer from "components/ui/Table/Container/TableContainer";
import moment from 'moment';
import smsDictionary from 'dictionaries/sms';

import { getLastSmses } from "store/reducers/sms/sms";

import "./bnso_report.less";
import _ from "lodash";

@connect(state => ({}), { getLastSmses })

class OperatorBnsoSmsReportComponent extends Component {

    constructor(props) {
        super();

        this.state = {
            reports: [],
            bnso_uuid: ''
        }
    }

    async componentWillMount() {
        if (localStorage.getItem("bnso")) {
            await this.loadInitialData();
            this.loadLastSmses();
        }
    }

    async loadInitialData() {
        const bnso = JSON.parse(localStorage.getItem("bnso"))
        await this.setState({
            bnso_uuid: bnso.bnso_uuid,
            bnso_code: bnso.bnso_code,
        })
    }

    async loadLastSmses() {
        const response = await this.props.getLastSmses({bnso_uuid: this.state.bnso_uuid, count: 10});
        if (response.isOk) {
            this.setState({
                reports: response.payload.items
            })
        }
    }

    handleGoBack() {
        this.props.router.push(`/operator/bnso_check/${this.state.bnso_uuid}`);
    }

    render() {
        return (
            <Page pageId="OperatorBnsoSmsReportComponent"
                  title={`${_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '100']).text} → Отчет об отправленных СМС`}>
                <div className="sms-report-form">
                    <p className="sms-report-form__goBack" onClick={() => this.handleGoBack()}>&#129044; Назад</p>
                    <div className="Table _no-indent outer-indent-left">
                        <TableContainer>
                            <table className="b-table">
                                <thead>
                                <tr className="b-table__header _text-normal">
                                    <th>Код БНСО</th>
                                    <th>Номер телефона</th>
                                    <th>Тело сообщения</th>
                                    <th>Дата и время отправки</th>
                                    <th>Статус</th>
                                    <th>ID смс в шлюзе провайдера</th>
                                    <th>ID устройства в шлюзе провайдера</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.reports.map((report, index) => {
                                    return <tr key={report.bnso_uuid + index}>
                                        <td>{this.state.bnso_code}</td>
                                        <td>{report.phone_number}</td>
                                        <td>{report.message}</td>
                                        <td>{moment(report.sent_at).format('YYYY.MM.DD, HH:mm:ss')}</td>
                                        <td>{smsDictionary[report.status] ? smsDictionary[report.status] : smsDictionary[2]}</td>
                                        <td>{report.provider_sms_id}</td>
                                        <td>{report.provider_device_id}</td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </TableContainer>
                    </div>
                </div>
            </Page>
        );
    }
}

export default withRouter(OperatorBnsoSmsReportComponent);
