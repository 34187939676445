import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import PageModal from 'components/ui/page-modal';
import debounce from 'throttle-debounce/debounce';
import GlobalLoaderComponent from "components/ui/global-loader";
import {getStopPoints} from "store/reducers/geo/stop-points";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";

import './stop_point_add_editor.less';
import StreetAddEditor from "components/modules/kiutr/routes/view/street_add_editor";
import {getFias} from "store/reducers/fias/fias";
import * as alerts from "helpers/alerts";
import ContextTooltip from "components/ui/context-tooltip";
import {getRoute} from "store/reducers/routes/route_editor";
import {component_mapper} from "helpers/component_mapper";

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
    routeUuid: PropTypes.string,
    number: PropTypes.number,
    onAdd: PropTypes.func.isRequired,
})

@connect(state => ({}), {})

export default class StopPointAddEditor extends BaseEditor {

    title = '';

    async createItem(data) {
        if (!data[0].type_uuid) {
            return {
                isOk: false,
                validationErrors: {
                    "point.stop_point": [
                        'Обязательно к заполнению',
                    ],
                },
                showErrors: () => {
                },
            };
        }
        if (!data[1].type_uuid) {
            return {
                isOk: false,
                validationErrors: {
                    "point.stop_point_pair": [
                        'Обязательно к заполнению',
                    ],
                },
                showErrors: () => {
                },
            };
        }

        this.props.onAdd(data);

        return {
            isOk: true,
        };
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={::this.onCreate}
                onClose={::this.props.onClose}
                data={{
                    number: this.props.number,
                }}
                errors={this.state.errors}
            />
        );
    }

    composeItem(data) {
        return [
            {
                number: data.number || 1,
                latitude: data.latitude,
                longitude: data.longitude,
                point_type: 'stop_point',
                is_routing_enabled: true,
                type_uuid: data.stop_point,
                distance_to_the_next_point: (data.distance || 0),
                time_to_get_to_the_next_point: data.time || 0,
                streets: data.streets || [],
                is_control: true,
            },
            {
                latitude: data.pair_latitude,
                longitude: data.pair_longitude,
                point_type: 'stop_point',
                is_routing_enabled: true,
                type_uuid: data.stop_point_pair,
                distance_to_the_next_point: (data.distance || 0),
                time_to_get_to_the_next_point: data.time || 0,
                streets: data.streets || [],
                is_control: true,
            },
            !!data.reopen,
        ];
    }

    render() {
        const title = 'Добавление остановочных пунктов и улиц';

        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;

        const form = this.getForm({}, ::this.create);

        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                    <ModalTopMenuButton
                        className="_save"
                        title="Сохранить"
                        onClick={::this.onCreate}
                    />
                </ContextTooltip>

                <ModalTopMenuButtonsSeparator/>

                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <PageModal
                header={{title, buttons}}
                onClose={this.props.onClose}
                className={`b-modal-${this.props.mode} b-modal-route route-add-points-modal`}
            >
                {loader}
                {form}
            </PageModal>
        );
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {getStopPoints, getFias, getRoute}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        point: {},
        showEditor: false,
        route: {},
    };

    stopPointLoadDebounce = debounce(500, ::this.loadStopPoints);
    pairStopPointLoadDebounce = debounce(500, ::this.loadStopPoints);

    getData() {
        return this.state.point;
    }

    showEditor() {
        this.setState({
            showEditor: true,
        });
    }

    hideEditor() {
        this.setState({
            showEditor: false,
        });
    }

    async componentDidMount() {
        await this.setState({
            point: this.props.data,
        });

        this.focusOnStopPoint();
        this.loadRoute();
    }

    async loadRoute() {
        const response = await this.props.getRoute(this.props.routeUuid);

        if (response.isOk) {
            this.setState({
                route: response.payload,
            });
        }
    }

    focusOnStopPoint() {
        this.refs.stop_point && this.refs.stop_point.focus();
    }

    get(path, defaultValue = null) {
        return _.get(this.state.point, path, defaultValue);
    }

    async onStreetAdd({street}) {
        if (!street) {
            alerts.error('Не выбрана улица');
            return;
        }
        const response = await this.props.getFias(street);
        if (response.isOk) {
            let streets = this.get('streets', []);
            streets.push(response.payload.name);
            this.setValue('point.streets', streets);
        } else {
            response.showErrors();
        }
    }

    render() {
        const editor = this.state.showEditor ? (
            <StreetAddEditor
                mode="add"
                uuid={null}
                onAdd={::this.onStreetAdd}
                onSubmit={::this.hideEditor}
                onClose={::this.hideEditor}
            />
        ) : null;

        return (
            <div className="StopPointAddEditor">
                {editor}

                <Block size="lg">
                    <Block title="№ остан. пункта">
                        {this.textInput('point.number')}
                    </Block>
                    <Block title="Расст. между ост. п. (км)">
                        {this.textInput('point.distance', {
                            type: 'number',
                            positive: true,
                        })}
                    </Block>
                    <Block title="Время между ост. п. (мин)">
                        {this.textInput('point.time', {
                            type: 'number',
                            positive: true,
                        })}
                    </Block>
                    <Block title="Остановочный пункт">
                        {this.selectAsync('point.stop_point', ::this.stopPointLoadDebounce, {
                            onChange: ::this.onStopPointChange,
                            onInputKeyDown: ::this.onStopPointKeyDown,
                            ref: 'stop_point',
                        })}
                    </Block>
                </Block>
                <Block size="sm" title="Улицы" nowrap={true}>
                    <ModalTopMenuList className="top-menu_modal_edit">
                        <ContextTooltip key="base-editor.add" code="base-editor.add" default="Добавить">
                            <ModalTopMenuListItem
                                className="b-icon-link_params b-icon-link_icon_plus"
                                onClick={::this.showEditor}
                            />
                        </ContextTooltip>
                    </ModalTopMenuList>

                    <div className="b-block__text">
                        <div className="textarea_input">
                            <div className="textarea_input__control">
                                {this.get('streets', []).map((street, index) => (
                                    <div key={index}>{street}</div>
                                ))}
                            </div>
                            <div className="textarea_input__style"/>
                        </div>
                    </div>
                    <Block title="Парный остановочный пункт">
                        {this.selectAsync('point.stop_point_pair', ::this.pairStopPointLoadDebounce, {
                            onChange: ::this.onPairStopPointChange
                        })}
                    </Block>
                </Block>
            </div>
        );
    }

    onStopPointChange(e) {
        this.onChangeInput('point.stop_point', {target: {value: _.get(e, 'value')}});
        this.onChangeInput('point.latitude', {target: {value: _.get(e, 'latitude')}});
        this.onChangeInput('point.longitude', {target: {value: _.get(e, 'longitude')}});

        this.onChangeInput('point.stop_point_pair', {target: {value: _.get(e, 'value')}});
        this.onChangeInput('point.pair_latitude', {target: {value: _.get(e, 'latitude')}});
        this.onChangeInput('point.pair_longitude', {target: {value: _.get(e, 'longitude')}});
    };

    async onStopPointKeyDown(e) {
        if (e.keyCode === 13) {
            await this.setValue('point.reopen', true);
            setTimeout(() => {
                this.props.onSubmit();
            }, 100);
        }
    }

    onPairStopPointChange(e) {
        this.onChangeInput('point.stop_point_pair', {target: {value: _.get(e, 'value')}});
        this.onChangeInput('point.pair_latitude', {target: {value: _.get(e, 'latitude')}});
        this.onChangeInput('point.pair_longitude', {target: {value: _.get(e, 'longitude')}});
    };

    async loadStopPoints(input, callback) {
        let meta = {
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
        };
        if (this.state.route.vis_id) {
            meta.filters = {
                withUuid: _.uniq(_.filter(_.map(this.state.route.default_variant[this.props.direction], 'type_uuid'))),
            };
        }
        if (component_mapper(this.props.params.component) === 'children') {
            meta.filters = {
                withSource: null,
                withSources: [
                    'inventarisation_active',
                ],
            };
        }
        const result = await this.props.getStopPoints(meta);

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: `${i.register_number} ${i.title}`,
                    value: i.uuid,
                    latitude: i.latitude,
                    longitude: i.longitude,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }
}