import React, {Component} from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapLayer from "components/ui/map/base/layer";
import {connect} from "react-redux";
import {getStopPointsWithBounds} from "store/reducers/routes/routes";
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import StopPointsEditor from "components/modules/geo/stop-points/editor";
import {getRoadParts} from "store/reducers/kurs/road_parts";
import MapGeojson from "components/ui/map/base/geojson";
import debounce from 'throttle-debounce/debounce';

@propTypes({
    onClick: PropTypes.func,
    onDblClick: PropTypes.func,
    filters: PropTypes.object
})

@defaultProps({
    onClick: null,
    onDblClick: () => {
    },
})

@connect((state) => ({activeLayers: state.user_map_objects.layers.get('activeLayers')}), {getRoadParts})

export default class KursRoadPartsLayer extends AbstractMapLayer {

    state = {
        roadParts: [],
        active: null,
    };


    loadCallback = ::this.load;

    componentWillMount() {
        super.componentWillMount();

        this.props.leafletMap.on('moveend', this.loadCallback);

        if(window.RNIS_SETTINGS.road_map_filter_by_units_for_road_parts
            && this.props.filters
            && this.props.filters.base
            && this.props.filters.base.road_parts
            && this.props.filters.base.road_parts.units
            && this.props.filters.base.road_parts.units.length) {
            let filters = {
                withImplementers: this.props.filters.base.road_parts.units
            }
            this.load(filters);
        } else {
            this.load()
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.props.leafletMap.off('moveend', this.loadCallback);
    }

    componentDidUpdate(prevProps, prevState) {
        if(window.RNIS_SETTINGS.road_map_filter_by_units_for_road_parts && prevProps.filters) {
            let filtersPrev = prevProps.filters.base;
            let filtersCurrent = this.props.filters.base;

            if(filtersCurrent && filtersCurrent.road_parts && filtersCurrent.road_parts.units) {

                if(filtersPrev.road_parts !== undefined) {
                    if(JSON.stringify(filtersPrev.road_parts.units) !== JSON.stringify(filtersCurrent.road_parts.units)) {
                        if(filtersCurrent.road_parts.units.length) {
                            let filters = {
                                withImplementers: filtersCurrent.road_parts.units
                            }
                            this.load(filters)
                            } else {
                                this.load()
                            }
                    }
                } else if (filtersCurrent.road_parts.units.length) {
                    let filters = {
                        withImplementers: filtersCurrent.road_parts.units
                    }
                    this.load(filters)
                    } else {
                        this.load()
                }

            }
        }
    }

    async load(filters_arg) {
        const boundingBox = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getBoundingBox() : this.props.map.getBoundingBox();
        const zoom = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getZoom() : this.props.map.getZoom();

        let filters = {
            withBoundingBox: boundingBox,
            withZoom: zoom
        }

        let pagination = {
                page: 1,
                limit: 1000,
            }


        if (this.props.communalUuid) {
            delete filters.withBoundingBox
            filters.withCommunalMunicipalities = [this.props.communalUuid]
        }

        if(filters_arg && filters_arg.withImplementers) {
            filters = {
                ...filters,
                ...filters_arg
            }
        }

        const response = await this.props.getRoadParts({
            filters: filters,
            pagination
        });

        if (response.isOk) {
            this.setState({
                roadParts: response.payload.items,
            });
        }
    }

    render() {
        return (
            <div>
                <div style={{display: 'none'}}>
                    {this.state.roadParts.map(::this.renderRoadPart)}
                </div>
            </div>
        );
    }

    renderRoadPart(roadPart) {
        return (
            <MapGeojson
                key={roadPart.uuid}
                {...this.props}
                geometry={roadPart.geometry}
                tooltip={roadPart.name}
                onClick={this.onClick.bind(this, roadPart)}
                onMouseOver={this.setActive.bind(this, roadPart.uuid, true)}
                onMouseOut={::this.setNotActive}
                color={(this.state.active && (this.state.active === roadPart.uuid)) ? 'red' : 'blue'}
            />
        );
    }

    setActive(uuid) {
        this.setState({
            active: uuid,
        });
    }

    setNotActive() {
        this.setState({
            active: null,
        });
    }

    onClick(roadPart) {
        this.props.onClick && this.props.onClick(roadPart.uuid, roadPart.geometry);
    }

}
