import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import $ from 'jquery';
import _ from 'lodash';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import moment from "moment";
import formats from "dictionaries/formats";
import {EntityList} from "helpers/entity";
import IconButton from "components/ui/icon-button";
import systems from "dictionaries/systems";
import {getGatnViolationJournals} from "store/reducers/gatn/gatn_violations";
import {getEntityNames} from "store/reducers/system";
import Datepicker from "components/ui/form/datepicker";
import {getSpeedViolationJournal, getSpeedViolations} from "store/reducers/notifications/speed-violations";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Editor from './editor';

@connect(state => ({}), {getSpeedViolationJournal, getVehicleList, getEntityNames, getDictionaryList})

export default class SpeedViolations extends BaseTableWithEditorComponent {

    getTitle() {
        return `${systems[this.props.params.component]} → Нарушения`;
    }

    getBaseUrl() {
        return `/${this.props.params.component}/speed-violations`;
    }

    select = 'none';

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            from: moment().subtract(1, 'day').startOf('day'),
            to: moment().endOf('day'),
        });
    }

    componentDidUpdate() {
        if (!this.refs.table) return;
        $(this.refs.table.getWrappedInstance().refs.table).on('click', '.has-violation', ::this.onViolationCellClick);
    }

    async componentWillUpdate(props) {
        if (props.params) {
            const vehicle = props.params.vehicle;
            const date = props.params.date;
            if (this.state.editorUuid !== vehicle || this.state.editorDate !== date || this.state.showEditorModal !== !!vehicle) {
                this.setState({
                    showEditorModal: !!vehicle,
                    editorUuid: vehicle,
                    editorDate: date,
                });
            }
        }
    }

    onViolationCellClick(e) {
        const el = $(e.target);
        const vehicle = el.data('vehicle');
        const date = el.data('date');

        this.props.router.push(`${this.getBaseUrl()}/${vehicle}/${date}`);
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Дата')
                .fromField('timestamp')
                .withDrawer((item) => moment(item.timestamp).format(formats.DATETIME))
                .withDateFilter(),

            new Column('Гос. номер')
                .fromField('state_number')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => this.state.related.get(item.vehicle_uuid))
                .withAsyncFilter('withVehicle', async (search) => {
                    const response = await this.props.getVehicleList({
                        search,
                        filters: {
                            withComponent: this.props.params.component,
                        },
                        order: {
                            column: 'state_number',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 50,
                        },
                    });
                    if (response.isOk) {
                        return _.map(response.payload.items, vehicle => ({
                            uuid: vehicle.uuid,
                            name: vehicle.state_number,
                        }));
                    }
                    return [];
                }),

            new Column('Марка ТС')
                .fromField('vehicle_mark_uuid')
                .withDrawer(item => this.state.related.get(item.vehicle_mark_uuid))
                .denyOrder()
                .denyColumnFilter()
                .withFilter('withVehicleMarks', async () => {
                    const response = await this.props.getDictionaryList('vehicle_marks');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }),

            new Column('Модель ТС')
                .fromField('vehicle_model_uuid')
                .withDrawer(item => this.state.related.get(item.vehicle_model_uuid))
                .denyOrder()
                .denyColumnFilter()
                .withFilter('withVehicleModels', async () => {
                    const response = await this.props.getDictionaryList('vehicle_models');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }),

            new Column('Нарушение скоростного режима')
                .denyOrder()
                .denyColumnFilter()
                .fromField('amount')
                .withAlign('center')
                .withDrawer((item) => (item.amount > 0) ? `<span class="has-violation" data-type="speed" data-vehicle="${item.vehicle_uuid}" data-date="${moment(item.timestamp).format(formats.DATE_URL)}">${item.amount}</span>` : '<span class="no-violation"></span>'),

        ]);
    }

    async loadData(meta) {
        meta.filters.withComponent = this.props.params.component;
        meta.filters.withPeriod = [
            moment(this.state.from).format(formats.DATETIME_API),
            moment(this.state.to).endOf('day').format(formats.DATETIME_API),
        ];
        return await this.props.getSpeedViolationJournal(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const vehicles = _.map(_.uniq(_.filter(_.map(result, 'vehicle_uuid'))), (uuid) => ({
            class: 'App\\Model\\Vehicle',
            uuid: uuid,
            source: 'vehicles',
        }));
        const marks = _.map(_.uniq(_.filter(_.map(result, 'vehicle_mark_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleMark\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const models = _.map(_.uniq(_.filter(_.map(result, 'vehicle_model_uuid'))), (uuid) => ({
            class: 'App\\Dictionaries\\Vehicles\\VehicleModel\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));
        const response = await this.props.getEntityNames(_.concat(vehicles, marks, models));
        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    async loadVehicles(json) {
        const uuids = _.uniq(_.filter(_.map(json.data, 'vehicle_uuid')));

        const response = await this.props.getVehicleList({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            return response.payload.items;
        } else {
            response.showErrors();
            return [];
        }
    }

    renderHeaderActions() {
        return [
            this.diapasonFilter(),
        ];
    }

    diapasonFilter() {
        return (
            <div key="diapason">
                <div className="top-menu__label">Выбор диапазона:</div>
                <Datepicker style="dark" value={this.state.from} onChange={::this.fromChange}
                            disabledDays={::this.disabledDays}/>
                <Datepicker style="dark" value={this.state.to} onChange={::this.toChange}
                            disabledDays={::this.disabledDays}/>
            </div>
        );
    }

    disabledDays(day) {
        return moment(day).isAfter(moment(), 'day')
    }

    async fromChange({target: {value}}) {
        await this.setState({from: value});
        this.reload();
    }

    async toChange({target: {value}}) {
        await this.setState({
            to: value,
            from: moment(this.state.from).isAfter(moment(value)) ? value : this.state.from,
        });
        this.reload();
    }

    getCreateButton() {
        return null;
    }

    async showEditorWithUuid(uuid = null) {
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode="edit"
                uuid={this.state.editorUuid}
                date={this.state.editorDate}
                {...this.props}
            />
        );
    }
}