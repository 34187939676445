import React, {Component} from 'react';
import moment from "moment";
import Select from "components/ui/select";
import RegularityMatrixActions from "../RegularityMatrixActions/index.js";

import { createUrlWithQueries } from "helpers/regularity_matrix";

import "./style.less";

export default class RegularityHeader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isActiveFilter: false,
            vehicle: null,
            optionsVehicles: []
        };
    }

    async componentDidMount() {
        const vehiclesStateNumbers = _.uniq(this.props.vehicles.map(item => item.vehicle.statenumber));
        const vehiclesForSelect = vehiclesStateNumbers.map(statenumber => {
            return {
                value: statenumber,
                label: statenumber
            }
        });
        await this.setState({
            optionsVehicles: vehiclesForSelect
        })

        if (this.props?.location?.query?.plate_number) {
            await this.setState({
                vehicle: this.props.location.query.plate_number
            })
        }
    }

    renderStopPoints() {
        return this.props.stopPoints.map((stopPoint, indexPoint) => {
            return <div key={indexPoint} className="regularityHeader__row">
                <div className="regularityHeader__item">
            <div className="route_title regularityHeader__stopPoint"><span title={stopPoint.uuid}>{stopPoint.title}</span></div>
            <p className="regularityHeader__stopPointIndex">{indexPoint + 1}</p>
            <div className="route_firstrunplannedtime regularityHeader__time">{moment.unix(stopPoint.firstrunplannedtime).format("HH:mm")}</div>
            </div>
        </div>
        });
    }

    onChangeSelect(field, e) {
        const pathname = this.props.location.pathname;
        const query = this.props.location.query;
        const lastQuery = "plate_number";
        const vehicleValue = e && e.value ? e.value : null;
        const url = createUrlWithQueries(pathname, query, lastQuery, vehicleValue);

        if (!e) {
            this.setState({
                vehicle: null
            });
            this.props.router.push(`${url}`);
            this.props.onGetOrderInfo(null)
            return;
        }

        const value = e.value;

        this.setState({
            vehicle: value
        });
        this.handleFilter();
        this.props.router.push(`${url}`);
        this.props.onGetOrderInfo(value)
    }

    handleFilter() {
        const status = !this.state.isActiveFilter;
        this.setState({
            isActiveFilter: status
        })
    }

    handleReloadMatrix() {
        this.props.onReloadMatrix();
    }

    handleToggleDirection(direction) {
        this.props.onToggleDirection(direction);
    }

    handleCandidates() {
        this.props.onCandidates();
    }

    render() {
        return (
            <div className="regularityHeaderWrapper">
            <RegularityMatrixActions {...this.props} vehicle={this.state.vehicle} onCandidates={() => this.handleCandidates()} onReloadMatrix={this.handleReloadMatrix.bind(this)} onToggleDirection={(val) => this.handleToggleDirection(val)} order={this.props.order} />
            <div className="regularityHeader">
                <div className="regularityHeader__column1">
                    <div className="regularityHeader__routeNumber">Номер <br/>рейса</div>
                    <div className="regularityHeader__vehicleNumber">Гос.<br/>номер
                    <div onMouseDown={() => this.handleFilter()} className={`filter__icon ${this.state.isActiveFilter && 'active'}`}></div>
                    {this.state.isActiveFilter && <div >
                    <Select
                                className="regularityHeader__vehicleFilter"
                                value={this.state.vehicle}
                                onChange={this.onChangeSelect.bind(this, 'state_number')}
                                options={this.state.optionsVehicles}
                    />
                    </div>}
                    </div>
                </div>
                
                <div className="regularityHeader__stopPointsContainer">
                {this.renderStopPoints()}
                </div>
            </div>
            </div>
        );
    }
}