import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './block.less';

@propTypes({
    title: PropTypes.string,
    className: PropTypes.string,
})

@defaultProps({
    title: '',
})

export default class BlockGroup extends Component {
    render() {
        const {title, children, className} = this.props;

        return (
            <div className="b-block__group">
                <div className={classNames("b-block__caption", className && className)}>{title}</div>
                {children}
            </div>
        );
    }
}