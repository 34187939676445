import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import _ from 'lodash';
import debounce from 'throttle-debounce/debounce';

import {LabeledCheckbox} from "components/ui/checkbox";
import LoaderComponent from "components/ui/loader";
import {TableColumnFilterBase} from "components/ui/Table/Column/Filter/TableColumnFilterBase";

@propTypes({
    index: PropTypes.number.isRequired,
    column: PropTypes.object.isRequired,
    opened: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onFilterOpened: PropTypes.func
})

@defaultProps({
    opened: false,
    onFilterOpened: () => {
    }
})

export class TableColumnFilterSelect extends TableColumnFilterBase {

    searchCallbackDebounce = debounce(500, ::this.onSearchChange);

    state = {
        list: [],
        visible: false,
        selected: [],
        isDeselectAll: false,
        search: null,
        loading: false,
    };

    listLoaded =false;

    constructor(...args) {
        super(...args);

        Object.assign(this.state, {
            list: [],
            selected: [],
            isDeselectAll: false,
            search: null,
            loading: false,
        });
    }

    componentWillMount() {
        super.componentWillMount();

        if (this.props.column.filterValue) {
            this.setState({
                selected: this.props.column.filterValue,
            });
        }
    }

    async componentDidUpdate() {
        if ($(".top-menu__link.top-menu__link_icon_filter.active").length === 0 && this.state.selected.length > 0 && !this.state.visible) {
            this.setState({
                selected: [],
            });
        }
    }

    async componentDidMount() {
        super.componentDidMount();

        if (!this.props.column.filterAsync) {
            const list = await this.props.column.filter();
            this.setState({
                list,
            });
        }
        $(document).on('click', this.onClickDocument);
    }

    hasValue() {
        return this.state.selected.length > 0;
    }

    renderContent() {
        let selectedCount = this.state.selected.length;
        let totalCount = _.values(this.state.list).length;

        const filteredList = _.filter(this.state.list, (item) => {
            if (this.state.search) {
                if (item.name.toLowerCase().indexOf(this.state.search) === -1) {
                    return false;
                }
            }
            return true;
        });
        if (this.props.column.filterAsync) {
            totalCount = filteredList.length;
        }

        const sliderClassName = classNames({
            'b-slider__line': true,
            '_selected_yes': selectedCount && selectedCount < totalCount,
            '_selected_all': selectedCount >= totalCount,
        });

        const sliderCircleClassName = classNames({
            'b-slider__circle': true,
            '_selected_yes': selectedCount && selectedCount < totalCount,
            '_selected_all': selectedCount >= totalCount,
        });

        return (
            <div>
                <div className="filtration__search">
                    <input type="text" ref="search" className="filtration__pole" placeholder="Поиск"
                           onChange={this.props.column.filterAsync ? this.searchCallbackDebounce : ::this.onSearchChange}
                           onKeyDown={::this.handleKeyPress}/>
                    <input type="button" className="filtration__button"/>
                </div>

                <div className="filtration__block">
                    {this.state.list && _.orderBy(filteredList, 'name').map(::this.renderItem)}
                    {this.state.loading ? <LoaderComponent color="red"/> : null}
                </div>

                <div className="b-slider _options clearAfter" onClick={::this.selectDeselectAll}>
                    {(!this.props.column.filterAsync) ? (
                        <div
                            className="b-slider__title">{this.state.isDeselectAll ? 'Снять все' : 'Выбрать все'}</div>
                    ) : null}

                    <div className="b-slider__control">
                        <div className={sliderClassName}/>
                        <div className={sliderCircleClassName}/>
                    </div>

                    {this.props.column.filterAsync ? (
                        <div className="b-slider__counter">
                            {(this.state.selected.length > 0) ? (
                                <span className="b-slider__selected">{this.state.selected.length}</span>
                            ) : null}
                        </div>
                    ) : (
                        <div className="b-slider__counter">
                            <span className="b-slider__selected">{this.state.selected.length}</span>
                            /
                            <span className="b-slider__all">{totalCount}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    renderItem(item) {
        return (
            <div className="filter-name" key={item.uuid}>
                <LabeledCheckbox
                    label={item.name}
                    checked={this.state.selected.indexOf(item.uuid) !== -1}
                    onChange={this.onChange.bind(this, item.uuid)}
                />
            </div>
        );
    }

    selectDeselectAll() {
        let selected;
        if (this.props.column.filterAsync) {
            const filteredList = _.filter(this.state.list, (item) => {
                if (this.state.search) {
                    if (item.name.toLowerCase().indexOf(this.state.search) === -1) {
                        return false;
                    }
                }
                return true;
            });
            if (this.state.isDeselectAll) {
                selected = [];
            } else {
                selected = _.uniq(_.concat(this.state.selected, _.map(filteredList, 'uuid')));
            }
        } else {
            if (this.state.isDeselectAll) {
                selected = [];
            } else {
                selected = _.map(this.state.list, 'uuid');
            }
        }

        this.setState({
            selected,
            isDeselectAll: !this.state.isDeselectAll,
        });
        this.props.onChange(this.props.index, selected);
    }

    setSelected(selected = []) {
        this.setState({selected});
        this.props.onChange(this.props.index, selected);
    }

    onChange(uuid) {
        let selected = this.state.selected;
        const position = selected.indexOf(uuid);
        if (position === -1) {
            selected.push(uuid);
        } else {
            selected.splice(position, 1);
        }
        this.setState({selected});


        this.props.onChange(this.props.index, selected);
    }

    async toggleBlock(e) {
        e.preventDefault();
        e.stopPropagation();

        const visible = !this.state.visible;

        this.setState({
            visible: visible,
        });

        this.props.onFilterOpened(this.props.column.field, visible);

        if (visible && !this.listLoaded) {
            this.listLoaded = true;
            if (!this.props.column.filterAsync) {
                const list = await this.props.column.filter();
                this.setState({
                    list,
                });
            }
        }
    }

    async onSearchChange() {
        await this.setState({search: this.refs.search.value.toLowerCase()});
        if (this.props.column.filterAsync) {
            this.setState({loading: true});
            const list = await this.props.column.filter(this.state.search);
            let currentList = this.state.list;
            _.each(list, (item) => {
                if (!_.find(currentList, {uuid: item.uuid})) {
                    currentList.push(item);
                }
            });
            this.setState({
                loading: false,
                list: currentList,
            });
        }
    }
}