import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import "./style.less";
import {unitTasksDoneChart} from "store/reducers/kurs/tasks";
import * as alerts from "helpers/alerts";

@connect(state => ({}), {
    unitTasksDoneChart
    },
    null, {withRef: true}
)

class UnitPercentTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            unit_data: [],
        }
    }

    componentDidMount() {
        this.loadUnitDoneTasks()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
            this.loadUnitDoneTasks();
        }
    }

    async loadUnitDoneTasks() {

        const response = await this.props.unitTasksDoneChart({
            filters: this.props.filters,
        });

        if (response.isOk) {
            this.setState({
                unit_data: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    normalizePercent(percent) {
        return (+percent * 100).toFixed(0) + '%';
    }

    render() {

        return (
            <div className="full-size unit-percent-table__container">
                <div className="Table">
                    <table className="b-table" ref={this.props.setRef}>
                        <thead className="b-table__header">
                            <tr>
                                <td>Предприятие</td>
                                <td>Выполнено</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.unit_data.map((unitData) => (
                                <tr>
                                    <td>{(this.props.units.find((unit) => unit.value === unitData.unit_uuid) || {} ).label}</td>
                                    <td>{this.normalizePercent(unitData.percent)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default UnitPercentTable;

