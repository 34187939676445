import _ from 'lodash';
import moment from "moment";
import currentUser from 'helpers/current-user';

export function formatDistance(meters) {
    if (meters >= 1000) {
        return `${_.round(meters / 1000, 1)} км`;
    }

    return `${meters} м`;
}

export function formatTime(minutes) {
    if (minutes >= 60) {
        return `${_.floor(minutes / 60)} ч ${_.padStart(minutes % 60, 2, '0')} мин`;
    }

    return `${minutes} мин`;
}

export function canOpDispEdit(currentUser, date, status, vehicle_uuid) {
    let disabled = false;
    // если у пользователя имеется одна из ролей (платный или бесплатный)
    let userRole = currentUser.user.roles.filter((role) =>
        role.name === window.RNIS_SETTINGS.FREEACCOUNTROLENAME
        || role.name === window.RNIS_SETTINGS.PAIDACCOUNTROLENAME);
    // и его время больше чем +3 часа от даты формирования план-наряда
    let dateDiff = moment() > moment(date).add(3, 'hours');
    //если модалка "порейсовое обеспечение")
    if (status !== 'active') {
        userRole.length && dateDiff && window.RNIS_SETTINGS.EXITCONTROL ? disabled = true : null;
    }

    if (status === 'active') {
        vehicle_uuid === null ? disabled = false : null;
        userRole.length && dateDiff && window.RNIS_SETTINGS.EXITCONTROL && !vehicle_uuid ? disabled = true : null;
    }

    if (status === 'active') {
        vehicle_uuid === null ? disabled = false : null;
    }

    return disabled;
}

export function isMosreg() {
    return (/\.mosreg.ru$/.test(window.location.hostname));
}

export function isNso() {
    return (/\.nso.ru$/.test(window.location.hostname));
}

export function isTest() {
    return ((window.location.hostname).includes('rnis-test'));
}

export function isLocalhost() {
    return ((window.location.hostname).includes('localhost'));
}

export function isCarriersLimits() {
    return !!currentUser.user.roles.filter((role) => role.name === window.RNIS_SETTINGS.PAIDACCOUNTROLENAME).length && window.RNIS_SETTINGS.CITY_MO;
}

export function isMunicipalType(route_kinds, route_kind_uuid) {
    return _.get(_.find(route_kinds, {value: route_kind_uuid}), 'label') === 'Муниципальный';
}

export function callInitialOnce(arg_func) {
    if (!localStorage.getItem("initial")) {
        arg_func();
        localStorage.setItem("initial", true)
        setTimeout(() => {
            localStorage.removeItem("initial")
        }, 4500)
    }
}


/**
 * Проверка возможности открытия порейсового обеспечения после 9 утра IT2019-5217
 */
export function checkLimitationInstallation(date) {
    if (!date) return

    const userRole = currentUser.user.roles.find((role) => role.name === window.RNIS_SETTINGS.installation_limitation_role_name);
    let nowTime, itemTime = null, splitConfig = {};

    splitConfig = {
        hours: window.RNIS_SETTINGS.installation_limitation_time.split(':')[0],
        minutes: window.RNIS_SETTINGS.installation_limitation_time.split(':')[1]
    };
    // время план-наряда + 9 часов
    itemTime = moment(date).startOf('day').add(splitConfig);
    // текущее время
    nowTime = moment();

    return (nowTime.isAfter(itemTime) && !!userRole);

}

/**
 * Проверка возможности открытия порейсового обеспечения до 24:00 IT2019-5217
 */
export function checkLimitationInstallationMidnight(date) {
    if (!date) return

    const userRole = currentUser.user.roles.find((role) => role.name === window.RNIS_SETTINGS.installation_limitation_role_name);
    let nowTime, itemTime = null, splitConfig = {};

    splitConfig = {
        hours: '23',
        minutes: '59',
        seconds: '59'
    };
    // время план-наряда +  24:00
    itemTime = moment(date).startOf('day').add(splitConfig);
    // текущее время
    nowTime = moment();

    return (nowTime.isAfter(itemTime) && !!userRole);


}
