import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import './header-text-button.less';


@propTypes({
    text: PropTypes.string,
    onClick: PropTypes.func,
    onDisabledClick: PropTypes.func,
    disabled: PropTypes.bool
})

@defaultProps({
    text: '',
    onClick: () => {
    },
    onDisabledClick: () => {
    },
    disabled: false
})

export default class HeaderTextButton extends Component {

    onClick = () => {
        this.props.disabled ? this.props.onDisabledClick() : this.props.onClick();
    };

    render() {
        const classes = classNames('header-text_button', this.props.disabled ? 'disabled' : '');
        return (
            <div className="header-text">
                <button className={classes}
                        onClick={this.onClick}>
                    {this.props.text}
                </button>
            </div>
        );
    }
}