import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import classNames from 'classnames';
import Block from "components/ui/form/block";
import Select from "components/ui/select";
import systems from "dictionaries/systems";
import MapFilterExtendedTabBase from "components/modules/maps/Filter/Extended/Tab/MapFilterExtendedTabBase";
import MapFilterExtendedFilter from "components/modules/maps/Filter/Extended/Filter/MapFilterExtendedFilter";
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import {getUserGeoObjects} from "store/reducers/user-map-objects/object_editor";
import MapFilterExtendedFilterDictionary from "components/modules/maps/Filter/Extended/Filter/MapFilterExtendedFilterDictionary";

@propTypes({
    isActive: PropTypes.bool,
})

@connect(state => ({}), {
    getUserGeoObjects,
})

export default class MapFilterExtendedTabState extends MapFilterExtendedTabBase {

    signal_levels = {
        95: '>95%',
        75: '>75%',
        50: '>50%',
        0: '<50%',
    };
    satellites_levels = {
        'green': 'Высокий',
        'yellow': 'Средний',
        'red': 'Низкий',
    };

    state = {};

    getModificators() {
        return {
            'Инверсия местоположения': 'extended.state.modificators.inverse',
            'Инверсия датчиков': 'extended.state.modificators.inverse_ports',
        };
    }

    renderContent() {
        return (
            <div>
                <MapFilterExtendedFilter
                    title="Местоположение"
                    selected={_.get(this.props.filters, 'extended.state.placements')}
                    onSelectedChange={this.onChange.bind(this, 'extended.state.placements')}
                    loadData={::this.loadPlacements}
                />
                <Block size="xl" title="Сигнал">
                    <Select
                        options={_.map(this.signal_levels, (label, value) => ({
                            value,
                            label,
                        }))}
                        value={_.get(this.props.filters, 'extended.state.signal')}
                        onChange={this.onSelectChange.bind(this, 'extended.state.signal')}
                    />
                </Block>
                <Block size="xl" title="Спутники">
                    <Select
                        options={_.map(this.satellites_levels, (label, value) => ({
                            value,
                            label,
                        }))}
                        value={_.get(this.props.filters, 'extended.state.satellites_level')}
                        onChange={this.onSelectChange.bind(this, 'extended.state.satellites_level')}
                    />
                </Block>
                <MapFilterExtendedFilterDictionary
                    title="Датчики"
                    dictionaryKey="bnso_indicators"
                    selected={_.get(this.props.filters, 'extended.state.bnso_indicators')}
                    onSelectedChange={this.onChange.bind(this, 'extended.state.bnso_indicators')}
                />
            </div>
        )
    }

    async loadPlacements(search = null, limit = 25) {
        let filters = {};

        const response = await this.props.getUserGeoObjects({
            filters,
            search,
            pagination: {
                page: 1,
                limit: limit,
            },
        });

        if (response.isOk) {
            return _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.title,
            }));
        } else {
            response.showErrors();
            return [];
        }
    }
}