import React from 'react';
import Datepicker from 'components/ui/form/datepicker';

export const DiapasonFilterSimple = ({ from_analytic, to_analytic, onFromChange, onToChange }) => (
	<div key="diapason">
		<div className="top-menu__label">Выбор диапазона:</div>
		<Datepicker style="dark" value={from_analytic} onChange={onFromChange} />
		<Datepicker style="dark" value={to_analytic} onChange={onToChange} />
	</div>
);
