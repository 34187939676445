import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import _ from 'lodash';

import BaseTableComponent from 'components/base/base_table';
import Page from 'components/ui/page';

import {CycleFetch, mapDatatablesRequestToMeta} from 'helpers/api';

import './audit.less';

import TableContainer from "components/ui/Table/Container/TableContainer";
import Column from "components/ui/column";
import IconButton from "components/ui/icon-button";
import {getUsers} from "store/reducers/staffing/staffing";
import moment from "moment";
import formats from "dictionaries/formats";
import {User} from "helpers/user";
import {getAudit} from "store/reducers/audit/audit";
import {getEntityNames} from "store/reducers/system";
import ContextTooltip from "components/ui/context-tooltip";
import Button from "components/ui/button";
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery';
import {getRoad, updateRoad} from "store/reducers/kurs/roads";
import * as alerts from "helpers/alerts";
import {getRoadPart, updateRoadPart} from "store/reducers/kurs/road_parts";
import {getDocument, updateDocument} from "store/reducers/dictionaries/editor";
import TableColumnSelectFields from "components/ui/Table/Column/SelectFields/TableColumnSelectFields";
import TableComponent from "components/ui/Table/Component/TableComponent";

@connect(state => ({}), {
    getAudit,
    getUsers,
    getEntityNames,
    getRoad,
    updateRoad,
    getRoadPart,
    updateRoadPart,
    getDocument,
    updateDocument
})

export default class AuditComponent extends BaseTableComponent {

    revertable = {
        'App\\Model\\Road': ::this.revertRoad,
        'App\\Model\\RoadPart': ::this.revertRoadPart,
        'App\\Dictionaries\\Kurs\\KursRoadRepairParts\\Model': ::this.revertRoadRepairPart,
    };

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            users: {},
            columns: List(this.getColumns()),
            entityUuid: props.params.entityUuid,
            entityClass: props.location.query.class,
        });
    }

    renderTable() {
        return (
            <div>
                <TableComponent
                    ref="table"
                    columns={this.state.columns}
                    select="none"
                    loadCallback={::this.loadCallbackMiddleware}
                    onDblClick={::this.onDblClick}
                    onColsReordered={::this.onColsReordered}
                    onCheck={::this.selectedRowsRecalc}
                    checkCache={::this.checkCache}
                    query={this.state.query}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    defaultOrder={[[0, 'desc']]}
                />
            </div>
        );
    }

    render() {
        let table = this.renderTable();

        return (
            <Page title="Журнал аудита"
                  pageId="Audit"
                  headerActions={this.renderHeaderActions()}
                  headerContents={this.renderHeaderContents()}>

                <TableContainer>
                    {table}
                </TableContainer>
            </Page>
        );
    }

    componentDidMount() {
        if (!this.refs.table) return;
        $(this.refs.table.getWrappedInstance().refs.table).on('click', '.b-button', ::this.onRevertClick);
    }

    goBack(e) {
        e.preventDefault();

        this.props.router.goBack();
    }

    getColumns() {
        let columns = [

            new Column('Дата/время')
                .fromField('timestamp')
                .withDrawer(item => moment(item.timestamp).format(formats.DATETIME)),

            new Column('Сущность')
                .fromField('entity_class'),

            new Column('Пользователь')
                .fromField('user_uuid')
                .withDrawer(item => (_.get(this.state.users, item.user_uuid) || '<code><nobr>-неизвестно-</nobr></code>')/* + ` (${item.ip || '-'})`*/)
                .denyColumnFilter()
                .withAsyncFilter('withUsers', async (search) => {
                    const response = await this.props.getUsers({
                        search,
                        order: {
                            column: 'surname',
                            direction: 'asc',
                        },
                        pagination: {
                            page: 1,
                            limit: 50,
                        },
                    });
                    if (response.isOk) {
                        return _.map(response.payload.items, user => ({
                            uuid: user.uuid,
                            name: new User(user).getFullName()
                        }));
                    }
                    return [];
                }),

            new Column('Изменения')
                .fromField('data')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => this.renderChanges(item.data)),

        ];

        const revertCallback = _.get(this.revertable, this.props.location.query.class);
        if (revertCallback) {
            columns.push(new Column('')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer(item => ReactDOMServer.renderToStaticMarkup(
                    <a className="b-button b-button_size_md b-button_red b-button_shadow_red b-button_width_full"
                       type="button"
                       data-data={JSON.stringify(item.data)}>Обратить</a>
                )));
        }

        return this.prepareColumns(columns);
    }

    renderChanges(data) {
        return _.map(data, (value, key) => {
            if (value.diff) {
                return _.map(value.diff, (item) => {
                    if (!item[0]) {
                        return;
                    }
                    return `${key} / ${item[0]}: <pre>${item[1]}</pre> &rarr; <pre class="audit-to">${item[2]}</pre>`;
                }).join('<br/>');
            } else {
                const oldValue = value.old_value || '-';
                const newValue = value.new_value || '-';

                return `${key}: <pre>${oldValue}</pre> &rarr; <pre class="audit-to">${newValue}</pre>`;
            }
        }).join('<br/>');
    }

    async reload() {
        this.refs.table && this.refs.table.getWrappedInstance().reload();
    }

    async loadCallback(request, drawCallback, settings) {
        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        const response = await this.props.getAudit(this.state.entityClass, this.state.entityUuid, true, meta);

        if (response.isOk) {
            const data = await this.prepareData(response.payload.items);

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: response.data.headers.meta.pagination.total,
                recordsTotal: response.data.headers.meta.pagination.total
            };

            drawCallback(json);
            this.selectedRowsRecalc();
            this.setState({success: true});
        } else {
            response.showErrors();
        }
    }

    async prepareData(data) {
        let result = data;

        const users = _.filter(_.map(result, 'user_uuid'));
        const response = await this.props.getEntityNames(_.map(users, (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        })));
        if (response.isOk) {
            this.setState({
                users: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'name'),
            });
        }

        return result;
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="kurs.task.back" code="kurs.task.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.goBack}/>
            </ContextTooltip>,
        ];
    }

    renderHeaderContents() {
        return [
            <TableColumnSelectFields
                key="select-fields-popup"
                columns={this.state.columns}
                showPopup={this.state.showSelectFieldsPopup}
                onChange={::this.onChangeSelectFieldsCheckbox}
                onToggle={::this.onToggleSelectFieldsPopup}/>,
        ];
    }

    async onRevertClick(e) {
        const el = $(e.target);
        const data = el.data('data');

        const revertCallback = _.get(this.revertable, this.props.location.query.class);
        if (revertCallback) {
            await revertCallback(data);
        }
    }

    async revertRoad(data) {
        const response = await this.props.getRoad(this.state.entityUuid);

        if (response.isOk) {
            let item = response.payload;
            _.each(data, (values, key) => {
                item[values.original_key] = (values.original_old !== undefined) ? values.original_old : null;
            });

            const response2 = await this.props.updateRoad(item);
            if (response2.isOk) {
                alerts.success('Изменения обращены');
                this.reload();
            } else {
                response2.showErrors();
            }
        } else {
            response.showErrors();
        }
    }

    async revertRoadPart(data) {
        const response = await this.props.getRoadPart(this.state.entityUuid);

        if (response.isOk) {
            let item = response.payload;
            _.each(data, (values, key) => {
                item[values.original_key] = (values.original_old !== undefined) ? values.original_old : null;
            });

            const response2 = await this.props.updateRoadPart(item);
            if (response2.isOk) {
                alerts.success('Изменения обращены');
                this.reload();
            } else {
                response2.showErrors();
            }
        } else {
            response.showErrors();
        }
    }

    async revertRoadRepairPart(data) {
        const response = await this.props.getDocument('kurs_road_repair_parts', this.state.entityUuid);

        if (response.isOk) {
            let item = response.payload;
            _.each(data, (values, key) => {
                item.data[values.original_key] = (values.original_old !== undefined) ? values.original_old : null;
            });

            const response2 = await this.props.updateDocument(item);
            if (response2.isOk) {
                alerts.success('Изменения обращены');
                this.reload();
            } else {
                response2.showErrors();
            }
        } else {
            response.showErrors();
        }
    }
}