import {rpc} from './session';

export async function createSmsTemplate(payload = {}, meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.template.create', payload, {meta});
}

export async function getSmsTemplate(meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.template.get', {}, {meta});
}

export async function getSmsTemplatesList(meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.templates.list', {}, {meta});
}

export async function updateSmsTemplate(payload = {}, meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.template.update', payload, {meta});
}

export async function deleteSmsTemplate(meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.template.delete', {}, {meta});
}

export async function createSmsDevice(payload = {}, meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.device.create', payload, {meta});
}

export async function getSmsDevice(payload = {}, meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.device.get', payload, {meta});
}

export async function getSmsDeviceByID(payload = {}, meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.device.get_by_device_id', payload, {meta});
}

export async function getSmsDevicesList(meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.devices.list', {}, {meta});
}

export async function updateSmsDevice(payload = {}, meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.device.update', payload, {meta});
}

export async function deleteSmsDevice(payload = {}, meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.device.delete', payload, {meta});
}

export async function getSmsDefaultDevice(meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.device.get_default', {}, {meta});
}

export async function smsCreate(payload = {}, meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.message.create', payload, {meta});
}

export async function getLastSmses(payload = {}, meta = {}) {
    return await rpc.request('com.rnis.vehicles.action.sms.messages.list', payload, {meta});
}
