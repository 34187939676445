import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, defaultProps } from 'react-props-decorators';
import _ from 'lodash';
import { connect } from 'react-redux';
import { LabeledCheckbox } from 'components/ui/checkbox';
import IconButton from 'components/ui/icon-button';
import ContextTooltip from 'components/ui/context-tooltip';
import Popup from 'components/ui/popup';
import FilterHeader from 'components/ui/filter-header';
import debounce from 'throttle-debounce/debounce';
import classNames from 'classnames';
import GlobalLoaderComponent from 'components/ui/global-loader';
import LoaderComponent from 'components/ui/loader';

@propTypes({
	selected: PropTypes.array,
	onSelectedChange: PropTypes.array.isRequired
})
export default class MapFilterBase extends Component {
	_isMounted = false;

	tabs = [];
	icon = 'legend';
	contextKey = null;
	contextDefault = null;
	limit = 25;

	state = {
		opened: false,
		items: {},
		isDeselectAll: false,
		search: {},
		activeTab: 0,
		loading: false,
		vehicleLoading: false,
		isFirstSearch: false,
		isVehicleOpened: false,
	};

	componentWillMount() {
		let search = {};
		_.each(this.tabs, (tabName, tabIndex) => {
			this.loadDataMiddleware(tabIndex);
			search[tabIndex] = '';
		});

		this.setState({ search });
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getCurrentStatus() {
		const selected = JSON.parse(localStorage.getItem("map:vehicles:kiutr"));
		if (this.props.selected && this.props.selected[1] && this.props.selected[1].length > 0) {
			return true;
		}
		if (Array.isArray(selected) && selected.length) {
			return true;
		}
		return this.state.isDeselectAll;
	}

	getButton() {
		return <IconButton icon={this.icon} onClick={:: this.toggleBlock} />;
	}

async loadData(tab = null, search = null) { }

async reloadFull() {
	let items = this.state.items;
	_.each(this.tabs, (tabName, tabIndex) => {
		_.set(
			items,
			tabIndex,
			_.filter(_.get(items, tabIndex, []), (item) => {
				return _.indexOf(this.props.selected[tabIndex], item.value) !== -1;
			})
		);
	});

	await this.setState({ items });

	_.each(this.tabs, (tabName, tabIndex) => {
		this.loadDataMiddleware(tabIndex);
	});

}

loadDataMiddleware = async (tabIndex = null) => {
	if (tabIndex === null) {
		tabIndex = this.state.activeTab;
	}
	this.setState({ loading: true })
	const newItems = await this.loadData(tabIndex, _.get(this.state.search, this.state.activeTab, null));
	setTimeout(() => {
		this.setState({ loading: false })
	}, 800);

	let items = this.state.items;
	items[tabIndex] = newItems;
	// в списке показывать только результат поиска актуального, убрать конкантенацию со старыми значениями
	// _.set(items, tabIndex, _.uniqBy(_.concat(_.get(items, tabIndex, []), newItems), 'value'));

	if (this._isMounted) {
		this.setState({ items });
	}
}

loadDebounce = debounce(500, this.loadDataMiddleware); // pick out to utils

getFilteredItems() {
	const searchLower = _.get(this.state.search, this.state.activeTab, '').toLowerCase();
	const selected = this.props.selected[this.state.activeTab];

	return _.sortBy(
		_.filter(_.get(this.state.items, this.state.activeTab), (item, index) => {
			if (searchLower) {
				const search_label = item.search_label ? item.search_label : item.label;
				return search_label.toLowerCase().indexOf(searchLower) !== -1;
			}
			/*if (selected.length > 0) {
			return (_.indexOf(selected, item.value) !== -1) || (index < this.limit - selected.length);
		}*/
			return index < this.limit;
		}),
		'label'
	);
}

render() {
	const button = this.getButton();

	return (
		<div>
			{this.contextKey ? (
				<ContextTooltip key={this.contextKey} code={this.contextKey} default={this.contextDefault}>
					{button}
				</ContextTooltip>
			) : (
				button
			)}
			{this.state.opened ? this.renderPopup() : null}
		</div>
	);
}

setLoading(isLoad) {
	this.setState({
		vehicleLoading: isLoad,
	})
}

renderPopup() {
	// TODO: pick out to other component
	const items = this.state.items[this.state.activeTab] || [];
	const selectedCount = _.filter(items, (item) => {
		return _.indexOf(this.props.selected[this.state.activeTab], item.value) !== -1;
	}).length;
	const totalCount = items.length;

	const sliderClassName = classNames({
		'b-slider__line': true,
		_selected_yes: selectedCount && selectedCount !== totalCount,
		_selected_all: selectedCount === totalCount
	});

	const sliderCircleClassName = classNames({
		'b-slider__circle': true,
		_selected_yes: selectedCount && selectedCount !== totalCount,
		_selected_all: selectedCount === totalCount
	});

	const filteredItems = this.getFilteredItems();

	let content;
	if (this.state.loading || this.state.vehicleLoading) {
		content = <LoaderComponent color="red" size={64} />
	}
	if (!filteredItems.length && !this.state.loading && !this.state.vehicleLoading) {
		content = <div style={{ fontSize: '14px', textAlign: 'center', color: 'gray' }}>Не найдено</div>
	} else if (filteredItems.length) {
		content = filteredItems.map((item, index) => (
			<LabeledCheckbox
				key={item.value + index + item.label}
				checked={_.indexOf(this.props.selected[this.state.activeTab], item.value) !== -1}
				label={item.label}
				onChange={this.onClick.bind(this, item)}
			/>
		))
	}

	return (
		<Popup
			className={classNames('SelectFieldsPopup top-link checkbox-dropdown')}
			show={true}
		>
			<div className="popup-container__content">
				{this.tabs.length > 1 && (
					<FilterHeader onChange={this.onChangeTab} items={this.tabs} currentItem={this.state.activeTab} />
				)}
				<div className="filtration__search">
					<input
						type="text"
						value={this.state.search[this.state.activeTab]}
						className="filtration__pole"
						placeholder="Поиск"
						onChange={(e) => this.onSearchChange(e)}
					/>
					<input type="button" className="filtration__button" />
				</div>
				<div className={classNames("content", !filteredItems.length && "minHeight")}>
						{content}
				</div>
				{totalCount > 0 && (
					<div className="b-slider _options">
						<div className="b-slider__title" onClick={this.selectDeselectAll}>{this.getCurrentStatus() ? 'Снять все' : 'Выбрать все'}</div>
						<div className="b-slider__control" onClick={this.selectDeselectAll}>
							<div className={sliderClassName} />
							<div className={sliderCircleClassName} />
						</div>
					</div>
				)
				}
			</div >
		</Popup >
	);
}

onChangeTab = ({ value }) => {
	this.setState({
		activeTab: value
	});
}

onSearchChange(event) {
	const value = event.target.value;
	const activeTab = this.state.activeTab;
	let search = this.state.search;
	search[activeTab] = value
	if (this.state.isFirstSearch === false) {
		this.setState({
			isFirstSearch: true,
			search
		});
		this.loadDataMiddleware(activeTab);
	} else {
		this.setState({
			search
		});
		this.loadDebounce();
	}
}

selectDeselectAll = () => {
	let selected;

	if (this.state.isDeselectAll) {
		selected = [];
	} else {
		selected = _.map(_.get(this.state.items, this.state.activeTab), 'value');
	}

	this.props.onSelectedChange[this.state.activeTab](selected);
	if (this.props.onForceNextCycle) {
		this.props.onForceNextCycle(selected, this.state.isDeselectAll);
	}

	this.setState({
		isDeselectAll: !this.state.isDeselectAll
	});
}

onClick(item) {
	let selectedTest = _.get(this.props.selected, this.state.activeTab, []);

	let selected = JSON.parse(JSON.stringify(selectedTest));
	const index = _.indexOf(selected, item.value);
	if (index !== -1) {
		selected.splice(index, 1);
	} else {
		selected.push(item.value);
	}
	if (this.props.onForceNextCycle) {
		console.info("Здесь Selected: ", selected)
		this.props.onForceNextCycle(selected);
	}

	this.props.onSelectedChange[this.state.activeTab](selected);
}

async toggleBlock(e) {
	if (window.RNIS_SETTINGS.CITY_MO) {
		if (e.target.classList.contains("top-menu__link_icon_ts")) {
			await this.setState({
				isVehicleOpened: !this.state.isVehicleOpened
			});
			const activeTab = this.state.activeTab;
			this.loadDataMiddleware(activeTab);
		}
	}
	this.setState({
		opened: !this.state.opened
	});
}

closeBlock() {
	this.setState({
		opened: false
	});
}
}
