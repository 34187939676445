import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import _ from 'lodash';

import BaseTableComponent from 'components/base/base_table';
import Page from 'components/ui/page';

import {CycleFetch, mapDatatablesRequestToMeta} from 'helpers/api';

import './activity.less';

import TableContainer from "components/ui/Table/Container/TableContainer";
import Column from "components/ui/column";
import {getActivity} from "store/reducers/activity/activity";
import IconButton from "components/ui/icon-button";
import SearchField from "components/ui/search-field";
import {getUsers} from "store/reducers/staffing/staffing";
import moment from "moment";
import formats from "dictionaries/formats";
import {User} from "helpers/user";
import {getEntityNames} from "store/reducers/system";
import systems from "dictionaries/systems";
import TableColumnSelectFields from "components/ui/Table/Column/SelectFields/TableColumnSelectFields";
import TableComponent from "components/ui/Table/Component/TableComponent";

@connect(state => ({}), {getActivity, getUsers, getEntityNames})

export default class ActivityComponent extends BaseTableComponent {

    _activityRefreshCycle = null;
    _activityRefreshCycleTimeout = 15000;

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            columns: List(this.getColumns()),
            users: {},
        });
    }

    componentDidMount() {
        /*this._activityRefreshCycle = new CycleFetch(
            () => {
                if (!this.refs.table) {
                    return null;
                }
                return this.reload();
            },
            () => {
            }, this._activityRefreshCycleTimeout);

        this._activityRefreshCycle.run();*/
    }

    componentWillUnmount() {
        this._activityRefreshCycle.stop();
        delete this['_activityRefreshCycle'];
    }

    renderTable() {
        return (
            <div>
                <TableComponent
                    ref="table"
                    columns={this.state.columns}
                    select="none"
                    loadCallback={::this.loadCallbackMiddleware}
                    onDblClick={::this.onDblClick}
                    onColsReordered={::this.onColsReordered}
                    onCheck={::this.selectedRowsRecalc}
                    checkCache={::this.checkCache}
                    query={this.state.query}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    defaultOrder={[[0, 'asc']]}
                    isPaging={false}
                />
            </div>
        );
    }

    render() {
        let table = this.renderTable();

        return (
            <Page title={`${systems.administration} → Мониторинг пользователей`}
                  pageId="Activity"
                  headerActions={this.renderHeaderActions()}
                  headerContents={this.renderHeaderContents()}>

                <TableContainer>
                    {table}
                </TableContainer>
            </Page>
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Пользователь')
                .fromField('user_uuid')
                .withDrawer(item => _.get(this.state.users, item.user_uuid) || '<code>-неизвестно-</code>')
                .denyColumnFilter(),

            new Column('АРМ')
                .fromField('path')
                .withDrawer(item => `${item.path_description}<br/><code>${item.path}</code>`),

            new Column('Активность')
                .fromField('timestamp')
                .withDrawer(item => moment(item.timestamp).format(formats.DATETIME)),

            new Column('Инфо')
                .withDrawer(item => this.getInfo(item))
                .denyOrder()
                .denyColumnFilter(),

        ]);
    }

    getInfo(item) {
        const parts = [
            `Вебсокеты: ${item.data.websockets ? '<span class="success">активны</span>' : '<span class="failure">не активны</span>'}`,
            item.data.os,
            item.data.browser,
            `${item.data.resolution.width}x${item.data.resolution.height}`,
            `Часовой пояс: ${item.data.timezone}`,
            `IP: ${item.data.ip || '-'}`,
        ];

        return parts.join('<br/>');
    }

    async reload() {
        this.refs.table && this.refs.table.getWrappedInstance().reload();
    }

    async loadCallback(request, drawCallback, settings) {
        let meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        meta.pagination.limit = 10000;
        const response = await this.props.getActivity(meta);

        if (response.isOk) {
            const data = await this.prepareData(response.payload.items);

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: response.data.headers.meta.pagination.total,
                recordsTotal: response.data.headers.meta.pagination.total
            };

            drawCallback(json);
            this.selectedRowsRecalc();
            this.setState({success: true});
        } else {
            response.showErrors();
        }
    }

    async prepareData(data) {
        let result = data;

        const users = _.filter(_.map(result, 'user_uuid'));
        const response = await this.props.getEntityNames(_.map(users, (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        })));
        if (response.isOk) {
            this.setState({
                users: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'name'),
            });
        }

        return result;
    }

    renderHeaderActions() {
        return [];
    }

    renderHeaderContents() {
        return [
            <TableColumnSelectFields
                key="select-fields-popup"
                columns={this.state.columns}
                showPopup={this.state.showSelectFieldsPopup}
                onChange={::this.onChangeSelectFieldsCheckbox}
                onToggle={::this.onToggleSelectFieldsPopup}/>,
        ];
    }
}