import React from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import moment from 'moment';
import formats from 'dictionaries/formats';
import _ from 'lodash';
import Checkbox from 'components/ui/checkbox';
import TableContainer from "components/ui/Table/Container/TableContainer";
import {connect} from "react-redux";
import {recalcOrderExecution} from "store/reducers/kiutr/orders/order_executions";
import * as alerts from "helpers/alerts";
import Settings from 'settings';
import ContextTooltip from "components/ui/context-tooltip";
import  Push from "push.js"
import {filterEventAttributes, getPresentationAttributes} from "recharts/es6/util/ReactUtils";
import currentUser from "helpers/current-user";

@propTypes({
    data: PropTypes.object.isRequired
})

@connect(state => ({}), {recalcOrderExecution})
export default class ScheduleTable extends React.Component {

    oldOrderExecutions = _.cloneDeep(this.props.data.orderExecutions);
    newOrderExecutions = {};
    pushelementsOld = [];
    pushFired = false;

    renderRow = (direction, group, index) => {
        const {runs, defaultStopPoints, defaultVariant} = this.props.data;

        return (
            <tr key={index}>
                <td>{group.name}</td>
                {runs.map((run, _index) => {
                    const planTime = this.getPlan(run, index, group);
                    const factTime = this.getFact(run, index, group);
                    const isSiblingsAlg = this.isSiblingsAlg(run, index, group);
                    const {is_before_time, is_after_time} = this.getViolations(run, index, group);
                    const timeDiff = factTime ? factTime.diff(planTime, 'seconds') : null;
                    let violation = (timeDiff !== null) ? (
                        <span
                            className={(is_before_time || is_after_time) ? 'failure' : 'normal'}>{this.formatTimeDiff(timeDiff)}</span>
                    ) : null;
                    if (this.isSkipped(run, index, group)) {
                        violation = (
                            <span className="failure">Пропуск</span>
                        );
                    }

                    return [
                        <td key={`plan-${_index}`}
                            className="cell-time">{planTime ? planTime.format(formats.TIME_FULL) : null}</td>,
                        <td key={`fact-${_index}`}
                            className="cell-time">{factTime ? factTime.format(formats.TIME_FULL) : null}<span
                            className="failure"
                            title={this.getCheckComment(run, index, group)}>{isSiblingsAlg ? '*' : ''}</span></td>,
                        <td key={`violation-${_index}`}>{violation}</td>,
                    ]
                })}
            </tr>
        );
    };

    formatTimeDiff(diff) {
        const minutes = Math.floor(Math.abs(diff) / 60);
        const seconds = _.padStart(Math.abs(diff % 60), 2, '0');

        if (diff >= 0) {
            return `+${minutes}:${seconds}`;
        }
        return `-${minutes}:${seconds}`;
    }

    getPlan(run, index, group) {
        const stopPointIndex = this.getStopPointIndex(run, index, group);

        const orderExecution = this.findOrderExecution(run);

        if (!orderExecution || stopPointIndex === null) {
            return this.getTime(run, index, group);
        }

        const points = _.filter(orderExecution.data, {point_type: 'stop_point'});

        const time = _.get(points, `${stopPointIndex}.time_plan`);
        if (!time) {
            return this.getTime(run, index, group);
        }

        return moment(time);
    }

    getCheckComment(run, index, group) {
        const stopPointIndex = this.getStopPointIndex(run, index, group);

        const orderExecution = this.findOrderExecution(run);

        if (!orderExecution || stopPointIndex === null) {
            return;
        }

        const points = _.filter(orderExecution.data, {point_type: 'stop_point'});

        return _.get(points, `${stopPointIndex}.check_comment`);
    }

    findOrderExecution(run) {
        const orderExecutions = _.filter(this.props.data.orderExecutions, {order_run_uuid: run.uuid});
        if (orderExecutions.length > 1) {
            return _.find(orderExecutions, (orderExecution) => {
                return orderExecution.vehicle_uuid && orderExecution.driver_uuid;
            });
        }
        return _.find(this.props.data.orderExecutions, {order_run_uuid: run.uuid});
    }

    getFact(run, index, group) {
        const stopPointIndex = this.getStopPointIndex(run, index, group);

        const orderExecution = this.findOrderExecution(run);
        if (!orderExecution || stopPointIndex === null) {
            return null;
        }
        const points = _.filter(orderExecution.data, {point_type: 'stop_point'});

        const time = _.get(points, `${stopPointIndex}.time_fact`);
        if (!time) {
            return null;
        }

        return moment(time);
    }

    isSiblingsAlg(run, index, group) {
        const stopPointIndex = this.getStopPointIndex(run, index, group);

        const orderExecution = this.findOrderExecution(run);
        if (!orderExecution || stopPointIndex === null) {
            return false;
        }
        const points = _.filter(orderExecution.data, {point_type: 'stop_point'});

        return _.get(points, `${stopPointIndex}.check_source`) === 'siblings';
    }

    getViolations(run, index, group) {
        const stopPointIndex = this.getStopPointIndex(run, index, group);

        let is_before_time = false;
        let is_after_time = false;

        const orderExecution = this.findOrderExecution(run);
        if (orderExecution && stopPointIndex !== null) {
            const points = _.filter(orderExecution.data, {point_type: 'stop_point'});

            is_before_time = _.get(points, `${stopPointIndex}.is_before_time`, false);
            is_after_time = _.get(points, `${stopPointIndex}.is_after_time`, false);
        }

        return {
            is_before_time,
            is_after_time,
        };
    }

    isSkipped(run, index, group) {
        const stopPointIndex = this.getStopPointIndex(run, index, group);

        const orderExecution = this.findOrderExecution(run);
        if (!orderExecution || stopPointIndex === null) {
            return null;
        }

        const points = _.filter(orderExecution.data, {point_type: 'stop_point'});

        return !!_.get(points, `${stopPointIndex}.is_skipped`);
    }

    getTime(run, productionRunIndex, group) {
        return null;
        let point = null;
        _.each(group.items, (item) => {
            point = _.find(item.inclusions, {
                route_variant_uuid: run.route_variant_uuid,
                is_forward: run.type === 'production_forward',
            });
            if (point) {
                return false;
            }
        });

        if (!point) {
            return null;
        }

        const time = _.sumBy(_.filter(run.production_interval_map, (item) => {
            return item.index < point.index;
        }), 'interval');

        return moment(run.date_from).add(time, 'minutes');
    }

    getStopPointIndex(run, index, group) {
        const routeVariant = _.find(this.props.data.routeVariants, {uuid: run.route_variant_uuid});
        if (!routeVariant) {
            return null;
        }
        if (group) {
            const items = _.filter(_.flatten(_.map(group.items, 'inclusions')), {
                route_variant_uuid: run.route_variant_uuid,
                is_forward: run.type === 'production_forward',
            });
            if (items.length === 0) {
                return null;
            }
            return _.first(items).index;
        }

        let points = (run.type === 'production_reverse') ? routeVariant.reverse_points : routeVariant.forward_points;
        points = _.filter(points, (point) => {
            return point.point_type === 'stop_point';
        });

        if (index >= points.length) {
            return null;
        }

        return _.filter(points, (point, _index) => {
            return (_index <= index) && (point.point_type === 'stop_point');
        }).length - 1;
    }

    onMapClick(run) {
        const orderExecution = this.findOrderExecution(run);
        if (!orderExecution) {
            return null;
        }

        const from = moment(orderExecution.date_start_real || orderExecution.date_start).format(formats.DATETIME_API);
        const to = moment(orderExecution.date_end_real || orderExecution.date_end).format(formats.DATETIME_API);

        const data = {
            vehicleUuid: run.vehicle_uuid,
            from,
            to,
            orderExecution,
            run,
        };

        this.props.data.onMapClick(data);
    }

    onSetShiftClick() {

    }

    async recalcRun(uuid) {
        const orderExecution = _.find(this.props.data.orderExecutions, {order_run_uuid: uuid});
        if (orderExecution) {
            const response = await this.props.recalcOrderExecution(orderExecution.uuid);
            if (response.isOk) {
                alerts.success('Пересчет запущен, ожидайте');
            }
        } else {
            alerts.error('Не найден план выполнения план-наряда');
        }
    }

    renderScheduleTable() {
        //console.log(localStorage.getItem('options'));

        //this.oldOrderExecutions = {};

        const {runs, direction, defaultVariant, routeVariants} = this.props.data;

        let groups = _.cloneDeep(defaultVariant);
        if (direction !== 'forward') {
            _.reverse(groups);
        }

        groups = _.filter(groups, (group) => {
            if (!group) {
                return false;
            }
            return _.filter(group.items, (item) => {
                return _.filter(item.inclusions, {
                    is_forward: direction === 'forward',
                }).length > 0;
            }).length > 0;
        });

        this.newOrderExecutions = _.cloneDeep(this.props.data.orderExecutions);

        //if (!_.isEmpty(this.newOrderExecutions) && !_.isEmpty(this.oldOrderExecutions)) {
        this.calcpush(this.newOrderExecutions, this.oldOrderExecutions, groups)
        // }

        this.oldOrderExecutions = _.cloneDeep(this.props.data.orderExecutions);


        const directionTitle = direction === 'forward' ? 'Рейсы в прямом направлении' : 'Рейсы в обратном направлении';
        return (
            <div className="output-direction">
                <div className="Table indent-none">
                    <TableContainer>
                        <table className="b-table b-table-thead-no-hover">
                            <thead>
                            <tr>
                                <th rowSpan="2">{directionTitle}</th>
                                {runs.map((run, index) =>
                                    <th key={index} colSpan="3">
                                        {`Рейс ${index + 1} (${_.get(_.find(routeVariants, {uuid: run.route_variant_uuid}), 'name')})`}
                                        {(window.location.hostname === 'localhost') ? _.get(this.findOrderExecution(run), 'uuid') : null}
                                        &nbsp;
                                        {(Settings.get('order_execution_recalc_available') === '1') ? (
                                            <a className="recount-link" href="javascript:void(0)"
                                               onClick={this.recalcRun.bind(this, run.uuid)}>Пересчитать</a>
                                        ) : null}
                                        <ContextTooltip key="transport-work.location" code="transport-work.location"
                                                        default="Мониторинг ТС">
                                            <span className="icon-location" onClick={this.onMapClick.bind(this, run)}/>
                                        </ContextTooltip>
                                        <br/>
                                        {this.renderTelematicsIndex(run)}
                                    </th>
                                )}
                            </tr>
                            <tr>
                                {runs.map((run, index) =>
                                    [
                                        <th key={`plan-${index}`}>Время план</th>,
                                        <th key={`fact-${index}`}>Время факт</th>,
                                        <th key={`violation-${index}`}>Нарушение</th>
                                    ]
                                )}
                            </tr>

                            </thead>
                            <tbody>
                            {groups && groups.map((group, index) => this.renderRow(direction, group, index))}

                            {this.props.data.isCurrent ?
                                <tr>
                                    <td>Зачесть рейс</td>
                                    {runs.map((run, index) =>
                                        <td key={index} colSpan="3">
                                            <div style={{position: 'relative'}}>
                                                <Checkbox checked={false} onChange={this.onSetShiftClick.bind(this)}/>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                                : null
                            }
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </div>
        );
    }

    renderTelematicsIndex(run) {
        if (!(Settings.get('display_telematics_quality', '0') === '1')) {
            return null;
        }

        const orderExecution = this.findOrderExecution(run);

        if (orderExecution && orderExecution.telematics_index) {
            return (
                <span>Качество телематики: {_.round(orderExecution.telematics_index * 100, 2)}%</span>
            )
        }

        return null;
    }

    render() {
        return this.renderScheduleTable();
    }


    calcpush(newobj, base, groups) {
        // если пуши оключены в настройках пользователя (глобальной настройке) то вычисления не нужны
        if (!currentUser.user.is_custompush) return;
        let stopPointsArray = [], arr = [], elements = [], pushelementsNew = [];
        let a =  this.props.data.runs[0].vehicle_uuid;
        let pushTime =  JSON.parse(localStorage.getItem('pushtime'));

        (!pushTime) ? pushTime = {} : null;
        (!pushTime[a]) ? pushTime[a] = { time: 1, isShowingPush: false, notschedulepush: false, failureschedulepush: false, schedulepush: false} : null;

        // слишком частый запрос
        if (pushTime[a].time && (Date.now() - pushTime[a].time < 3000)) {
            return;
        }
        pushTime[a].time = Date.now();
        localStorage.setItem('pushtime',JSON.stringify(pushTime));

        // не считаем, если выключен push
        if (!pushTime[a].schedulepush && !pushTime[a].notschedulepush && !pushTime[a].failureschedulepush) {
            return;
        }
        _.forEach(groups, (value, key) => {
            _.forEach(value.items, (v, k) => { stopPointsArray.push({name: value.name, uuid: v.stop_point_uuid} )
            });
        });

        // находим разницу между прошлым ответом и новым
        let diff = this.difference(newobj, base);

        _.forEach(diff, (value, key) => {
            if (value) { arr.push({ k: key, v: value})}

        });

        _.forEach(arr, (value, key) => { elements.push(newobj[value.k]) });

        _.forEach(elements, (value, key) => {
            if (value.data.length) {
                _.forEach(value.data, (stopPoint, k) => {
                    let a = _.find(stopPointsArray, {'uuid': stopPoint.type_uuid});
                    if (a) {
                        stopPoint.name = a.name;
                        pushelementsNew.push(stopPoint);
                    }
                })
            }
        });

        // урезаем длину массива уведомлений, при превышении порога
        if (pushelementsNew.length > 30) pushelementsNew.length = 30;

        _.forEach(pushelementsNew, (value, key) => {
            const protocol = App.isSecure ? 'https://' : 'http://';
            const timeDiff = value.time_fact ? moment(value.time_fact).diff(moment(value.time_plan), 'seconds') : null;
            let bodyMsg = '', titleMsg = '', iconType = '';
            // пропуск
            if (value.is_skipped && pushTime[a].failureschedulepush) {
                titleMsg = 'Пропуск';
                bodyMsg = `${this.props.data.runs[0].vehicle_state_number}\nОстановка: ${value.name}\nПлан: ${moment(value.time_plan).format(formats.TIME_FULL)}`;
                iconType = 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/OOjs_UI_icon_alert_destructive.svg/1024px-OOjs_UI_icon_alert_destructive.svg.png';
                Push.create( titleMsg, {
                    body: bodyMsg,
                    icon: iconType,
                    requireInteraction: true,
                    onClick: function () {
                        window.open(protocol + window.location.host + `/kiutr/orders/${elements[0].order_uuid}`, '_blank');
                    }
                });
            }

            if (!timeDiff)  {
                console.log(timeDiff);
                return
            }

            // во время - по расписанию
            if (value.is_in_time && pushTime[a].schedulepush) { //if((value.is_before_time || value.is_after_time)) {
                titleMsg = 'По расписанию';
                bodyMsg = `${this.props.data.runs[0].vehicle_state_number}\nОстановка: ${value.name}\nПлан: ${moment(value.time_plan).format(formats.TIME_FULL)} Факт: ${moment(value.time_fact).format(formats.TIME_FULL)} (${this.formatTimeDiff(timeDiff)})`;
                iconType = 'http://icons.iconarchive.com/icons/graphicloads/colorful-long-shadow/256/Check-3-icon.png';
                Push.create( titleMsg, {
                    body: bodyMsg,
                    icon: iconType,
                    requireInteraction: true,
                    onClick: function () {
                        window.open(protocol + window.location.host + `/kiutr/orders/${elements[0].order_uuid}`, '_blank');
                    }
                });
            }

            if (!value.is_in_time  && pushTime[a].notschedulepush) {
                titleMsg = 'Не по расписанию';
                bodyMsg = `${this.props.data.runs[0].vehicle_state_number}\nОстановка: ${value.name}\nПлан: ${moment(value.time_plan).format(formats.TIME_FULL)} Факт: ${moment(value.time_fact).format(formats.TIME_FULL)} (${this.formatTimeDiff(timeDiff)})`;
                iconType = 'https://www.safetysign.com/images/source/product-grid-images/J6520.png';
                Push.create( titleMsg, {
                    body: bodyMsg,
                    icon: iconType,
                    requireInteraction: true,
                    onClick: function () {
                        window.open(protocol + window.location.host + `/kiutr/orders/${elements[0].order_uuid}`, '_blank');
                    }
                });

            }


        });
    }

    difference(object, base) {
        function changes(object, base) {
            return _.transform(object, function (result, value, key) {
                if (!_.isEqual(value, base[key])) {
                    result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
                }
            });
        }

        return changes(object, base);
    }


}
