import React, {Component} from "react";
import {connect} from "react-redux";

import {HotKeys} from 'react-hotkeys';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'react-virtualized/styles.css';
import * as bowser from "bowser";
import moment from "moment";

import {connectionEstablished, connectionLost, connectWsRpc} from "store/reducers/main";
import {checkToken} from "store/reducers/auth";
import {CycleFetch} from "helpers/api";
import {activityStore} from "store/reducers/system";
import {getOptions, optionsUpdated} from "store/reducers/settings";
import currentUser from 'helpers/current-user';
import {GlobalEvent} from "helpers/event-system";
import * as storage from "utils/storage";
import {makeResponse} from "helpers/response";
import {cached} from "helpers/cached";
import { getUserInfo } from 'helpers/api/utils';

import GlobalLoaderComponent from "components/ui/global-loader";

import "./App.less";
import * as session from "helpers/api/session";

let subscribed = false;

@connect(state => ({
    isConnected: state.main.get('isConnected'),
    isAuthorized: state.auth.get('isAuthorized')
}), {connectWsRpc, checkToken, getOptions, optionsUpdated, activityStore, connectionLost, connectionEstablished})

export default class AppComponent extends Component {
    state = {
        websocketsActive: false,
        loaded: false,
    };

    keyMap = {
        'search': 'ctrl+shift+s',
        'filters': 'ctrl+shift+f',
        'column-select': 'ctrl+shift+h',
        'add': 'ctrl+shift+a',
        'print': 'ctrl+shift+p',
        'export': 'ctrl+shift+e',
        'delete': 'ctrl+shift+d',
        'table': 'ctrl+shift+u',
        'tree': 'ctrl+shift+y',
        'violations': 'ctrl+shift+v',
        'list': 'ctrl+shift+l',
        'stop-points-fullscreen': 'ctrl+shift+k',
    };

    _activityStoreCycle = null;
    _activityStoreCycleTimeout = 30000;
    _reloadTimeout = null;

    setUserInfo() {
        const userInfo = getUserInfo();
        this.setState({
            entryTime: localStorage.getItem('loginTime'),
            OSName: userInfo.OSName,
            systemMemory: userInfo.systemMemory,
            language: userInfo.language,
            browser: userInfo.browser,
            buildTime: moment(process.env.BUILD_DATE).lang("ru").format('DD MMMM YYYY, HH:mm'),
            buildNumber: moment(process.env.BUILD_DATE).format('YYMMDD.HHmm'),
        })
    }

    componentWillMount() {
        this.setUserInfo();

        document.addEventListener('keydown', (e) => this._handleKeyPress(e), false);

        this.requireAuthorization(this.props);

        if (this.props.isAuthorized && !this.props.isConnected) {
            this.props.connectWsRpc();
        }

        if (!subscribed) {
            /*this.props.optionsUpdated((settings) => {
                Settings.set(settings);
            });*/
            subscribed = true;
        }

        GlobalEvent().subscribe('goto', (url) => {
            this.props.router.push(url);
        });
    }

    async componentDidMount() {
        this.listenWebsocketEvents();
        setTimeout(() => {
            this._activityStoreCycle = new CycleFetch(
                () => {
                    if (!this.props.isConnected) {
                        return null;
                    }
                    return this.props.activityStore(this.getActivityData());
                },
                () => {
                }, this._activityStoreCycleTimeout);

            this._activityStoreCycle.run();
        }, 10000);

        try {
            await cached('options', async () => {
                return await makeResponse(() => {
                    return this.props.getOptions();
                }, 'options');
            });
            await currentUser.init();
            this.setState({
                loaded: true,
            });
        } catch (e) {
            if (session.hasCredits()) {
                session.removeCredits();
                this._reloadTimeout = setTimeout(() => {
                    window.location.reload();
                }, 15000);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', (e) => this._handleKeyPress(e), false);

        if (this._activityStoreCycle) {
            this._activityStoreCycle.stop();
            delete this['_activityStoreCycle'];
        }
    }

    componentWillUpdate(props) {
        this.requireAuthorization(props);
    }

    _handleKeyPress(e) {
        if(e.code === 'Digit1' && e.shiftKey === true) {
            alert(`
                ОС: ${this.state.OSName}
                Количество RAM: ${this.state.systemMemory}gb${this.state.systemMemory >= 8 ? ' (или более)' : ''}
                Язык системы: ${this.state.language}
                Версия Браузера: ${this.state.browser}
                Сборка: ${this.state.buildTime}
                Номер сборки: ${this.state.buildNumber}
                Дата и время входа: ${moment(this.state.entryTime).lang('ru').format('DD MMMM YYYY, HH:mm')}
                (${moment().diff(this.state.entryTime, 'days')} дн, ${moment().diff(this.state.entryTime, 'hours')  % 24} часов ${moment().diff(this.state.entryTime, 'minutes') % 60} минут назад)
            `)
        }
        if (e.code === 'F5' && e.ctrlKey === true) {
            e.preventDefault();
            localStorage.removeItem("cacheVehicle");
            window.location.reload();
        }
        if (e.code === 'Digit1' && e.ctrlKey === true) {
            alert(`Дата сборки ${process.env.BUILD_DATE}`);
            e.preventDefault();
        }
    }

    requireAuthorization(props) {
        if (window.RNIS_SETTINGS.esia) {
            if (this.props.location.query) {
                storage.set('esia', this.props.location.query);
            }
        }
        if (window.location.pathname === '/registration') return;
        if (!props.isAuthorized) {
            if (storage.get('auth-from-logout')) {
                storage.remove('auth-from-logout');
                storage.remove('auth');
            } else {
                storage.set('auth', this.props.router.location.pathname);
            }
            this.props.router.push('/auth');
        } else {
            this.props.checkToken();
        }
    }

    render() {
        if (this.props.isAuthorized) {
            if (this.state.loaded) {
                if (this._reloadTimeout) {
                    clearTimeout(this._reloadTimeout);
                }
                return (
                    <HotKeys keyMap={this.keyMap}>
                        {this.props.children}
                    </HotKeys>
                );
            } else {
                return (<GlobalLoaderComponent/>);
            }
        }

        return null;
    }

    getActivityData() {
        let os = 'Другое';
        if (bowser.mac) {
            os = `MacOS ${bowser.osversion}`;
        } else if (bowser.windows) {
            os = `Windows ${bowser.osversion}`;
        }

        const timezone = moment().utcOffset() / 60;

        return {
            path: window.location.pathname,
            data: {
                websockets: this.state.websocketsActive,
                os: os,
                browser: `${bowser.name} ${bowser.version}`,
                resolution: {
                    width: window.screen.availWidth,
                    height: window.screen.availHeight,
                },
                timezone: (timezone > 0) ? ('+' + timezone) : timezone,
            },
        };
    }

    listenWebsocketEvents() {
        this.props.connectionLost(() => {
            this.setState({websocketsActive: false});
        });

        this.props.connectionEstablished(() => {
            this.setState({websocketsActive: true});
        });
    }
}
