import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {
    getEventRuleNotification,
    createEventRuleNotification,
    updateEventRuleNotification, getEventTypes, getEventRule
} from "store/reducers/notifications";
import debounce from 'throttle-debounce/debounce';
import systems from "dictionaries/systems";
import {getUnits} from "store/reducers/organizational_units/units";
import vehicle_statuses from "dictionaries/vehicle_statuses";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getLayerObjectsFull, getLayersFull} from "store/reducers/user-map-objects/layer_list";
import NotificationRuleEditorMapComponent from "components/modules/notifications/rules/map";
import ContextTooltip from "components/ui/context-tooltip";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import {getRoles} from "store/reducers/roles/roles";
import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import NotificationTextParameters from "components/modules/notifications/rules/text-parameters/index";
import SelectFilter from "components/ui/select-filter";
import reactions from "dictionaries/reactions";
import notifications from "dictionaries/notifications";
import currentUser from 'helpers/current-user';

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    uuid: PropTypes.string,
})

@connect(state => ({}), {getEventRuleNotification, createEventRuleNotification, updateEventRuleNotification})

export default class Editor extends BaseEditor {

    title = '';
    modelClass = 'App\\Model\\EventRuleNotification';
    modalClassName = 'b-modal-event-rule-notification-editor';
    withFade = false;

    static contextTypes = {
        resizeModals: PropTypes.func
    };

    resize() {
        this.context.resizeModals && this.context.resizeModals();
    }

    componentDidMount() {
        this.componentWillReceiveProps(this.props);
    }

    async loadData(uuid) {
        return await this.props.getEventRuleNotification(uuid);
    }

    async createItem(data) {
        return await this.props.createEventRuleNotification(data);
    }

    async updateItem(data) {
        return await this.props.updateEventRuleNotification(data);
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.props.onClose}
                data={item}
                errors={this.state.errors}
            />
        );
    }

    composeItem(data) {
        let item = _.clone(data);

        item.event_rule_uuid = this.props.eventRuleUuid;

        return item;
    }
}

@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getRoles,
    getUnits,
    getDictionaryList,
    getUsers,
    getEventTypes,
    getEventRule,
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        event_rule_notification: {},
        units: [],
        roles: [],
        users: [],
        event_types: {},
        event_rule: {},
        currentTextType: 0,
    };

    getData() {
        return this.state.event_rule_notification;
    }

    async componentDidMount() {
        let data = this.props.data;
        data.roles = _.filter(data.roles || []);
        data.units = _.filter(data.units || []);
        data.users = _.filter(data.users || []);

        await this.setState({
            event_rule_notification: data,
        });
        this.loadUnits();
        this.loadRoles();
        this.loadUsers();
        this.loadEventRule();
        this.loadEventTypes();
    }

    async loadEventRule() {
        const response = await this.props.getEventRule(this.props.eventRuleUuid);

        if (response.isOk) {
            this.setState({
                event_rule: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    async loadEventTypes() {
        const response = await this.props.getEventTypes();
        if (response.isOk) {
            this.setState({
                event_types: _.mapValues(_.keyBy(response.payload.items, 'key'), 'target'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadUnits() {
        const response = await this.props.getUnits();

        if (response.isOk) {
            this.setState({
                units: _.sortBy(_.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.name,
                })), 'name'),
            });
        } else {
            response.showErrors();
        }
    }

    createUnitsTree(units, parentUnitUuid = null, level = 0) {
        const coreUnits = _.filter(units, (unit) => {
            if (parentUnitUuid === null) {
                return !unit.parent_unit_uuid;
            }
            return unit.parent_unit_uuid === parentUnitUuid;
        });

        return _.flatten(_.map(coreUnits, (unit) => {
            let result = [
                {
                    value: unit.uuid,
                    label: unit.name,//<span style={{paddingLeft: `${level * 10}px`}}>{unit.name}</span>,
                },
            ];
            result = _.concat(result, this.createUnitsTree(units, unit.uuid, level + 1));
            return result;
        }));
    }

	async loadRoles() {
		const response = await this.props.getRoles({
			response_data: [
				'items/access_type',
				'items/components',
				'items/created_at',
				'items/description',
				'items/is_reverse',
				'items/is_system',
				'items/name',
				'items/updated_at',
				'items/uuid'
			],
			filters: {
				withComponent: [
					"operator",
					"road",
					"administration",
					"analytics"
				]
			}
		});

        if (response.isOk) {
            this.setState({
                roles: _.map(response.payload.items, unit => ({
                    label: unit.name,
                    value: unit.uuid,
                })),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.event_rule_notification, path, defaultValue);
    }

    render() {
        const target = _.get(this.state.event_types, this.state.event_rule.type);

        return (
            <div>
                <Accordion>
                    <AccordionItem opened={true} title="Общая информация">
                        <Block size="xl" title="Наименование">
                            {this.textInput('event_rule_notification.name')}
                        </Block>
                        <Block title="Код">
                            {this.textInput('event_rule_notification.code')}
                        </Block>
                        <Block title="Уровень важности">
                            {this.select('event_rule_notification.notification_level', _.map(notifications.levels, (label, value) => ({
                                value,
                                label
                            })))}
                        </Block>
                        <Block size="md" title="Методы доставки по умолчанию">
                            {this.checkbox('event_rule_notification.via.email', 'Email')}
                        </Block>
                        <Block size="sm" title="Разрешить изменение">
                            {this.checkbox('event_rule_notification.via_allow_add.email')}
                        </Block>
                        <Block size="md">
                            {this.checkbox('event_rule_notification.via.sms', 'СМС')}
                        </Block>
                        <Block size="sm">
                            {this.checkbox('event_rule_notification.via_allow_add.sms')}
                        </Block>
                        <Block size="md">
                            {this.checkbox('event_rule_notification.via.system', 'Лента')}
                        </Block>
                        <Block size="sm">
                            {this.checkbox('event_rule_notification.via_allow_add.system')}
                        </Block>
                        <Block size="md">
                            {this.checkbox('event_rule_notification.via.journal', 'Журнал')}
                        </Block>
                        <Block size="sm">
                            {this.checkbox('event_rule_notification.via_allow_add.journal')}
                        </Block>
                    </AccordionItem>
                    <AccordionItem title="Время действия">
                        <Block>
                            {this.checkbox('event_rule_notification.days.always', 'Всегда')}
                        </Block>
                        {!this.get('days.always') ? ([
                            <Block key="days" size="xl">
                                {this.checkbox('event_rule_notification.days.0', 'Пн')}
                                {this.checkbox('event_rule_notification.days.1', 'Вт')}
                                {this.checkbox('event_rule_notification.days.2', 'Ср')}
                                {this.checkbox('event_rule_notification.days.3', 'Чт')}
                                {this.checkbox('event_rule_notification.days.4', 'Пт')}
                                {this.checkbox('event_rule_notification.days.5', 'Сб')}
                                {this.checkbox('event_rule_notification.days.6', 'Вс')}
                                {this.checkbox('event_rule_notification.days.7', 'Пр')}
                            </Block>,
                            <Block key="from" title="Время с">
                                {this.maskInput('event_rule_notification.time_from', '99:99')}
                            </Block>,
                            <Block key="to" title="Время по">
                                {this.maskInput('event_rule_notification.time_to', '99:99')}
                            </Block>,
                        ]) : null}
                    </AccordionItem>
                    <AccordionItem title="Аудитория доставки">
                        <Block size="xl" title="Роли">
                            {this.select('event_rule_notification.roles', this.state.roles, {multi: true, sort: true})}
                        </Block>
                        <Block size="xl" title="Предприятия">
                            {this.select('event_rule_notification.units', this.state.units, {multi: true, sort: true})}
                        </Block>
                        <Block size="xl" title="Пользователи">
                            {this.select('event_rule_notification.users', this.state.users, {multi: true, sort: true})}
                        </Block>
                        <Block size="xl">
                            Всего получателей: {((this.get('users') || []).length > 0) ? this.get('users').length : this.state.users.length}
                        </Block>
                    </AccordionItem>
                    <AccordionItem title="Текст сообщения">
                        <Block size="xl">
                            <SelectFilter
                                items={[
                                    'Журнал',
                                    'Лента',
                                    'Почта',
                                    'Смс',
                                ]}
                                currentItem={this.state.currentTextType}
                                onChange={(index) => this.setState({currentTextType: index})}/>
                        </Block>
                        {(this.state.currentTextType === 0) ? (
                            <Block size="xl" title="Журнал">
                                {this.textarea('event_rule_notification.text.journal')}
                            </Block>
                        ) : null}
                        {(this.state.currentTextType === 1) ? (
                            <Block size="xl" title="Лента">
                                {this.textarea('event_rule_notification.text.system')}
                            </Block>
                        ) : null}
                        {(this.state.currentTextType === 2) ? (
                            <Block size="xl" title="Почта">
                                {this.textarea('event_rule_notification.text.email')}
                            </Block>
                        ) : null}
                        {(this.state.currentTextType === 3) ? (
                            <Block size="xl" title="Смс">
                                {this.textarea('event_rule_notification.text.sms')}
                            </Block>
                        ) : null}
                        <Block size="xl" title="Параметры">
                            <NotificationTextParameters target={target} event={this.state.event_rule.type}/>
                        </Block>
                    </AccordionItem>
                    <AccordionItem title="Поведение оповещения">
                        <Block>
                            {this.checkbox('event_rule_notification.settings.at_time', 'Напоминать в фикс. время')}
                            {this.checkbox('event_rule_notification.settings.is_to_the_day_end', 'Напоминать до конца дня')}
                        </Block>
                        {this.get('settings.at_time') ? (
                            <Block title="Время суток">
                                {this.textInput('event_rule_notification.settings.time')}
                            </Block>
                        ) : (
                            <Block title="Периодичность напоминаний, мин">
                                {this.textInput('event_rule_notification.settings.period')}
                            </Block>
                        )}
                        {!this.get('settings.is_to_the_day_end') ? (
                            <Block title="Напоминать в течение, мин">
                                {this.textInput('event_rule_notification.settings.duration')}
                            </Block>
                        ) : null}
                        <Block title="Ожидаемый тип реакции">
                            {this.select('event_rule_notification.settings.reaction', _.map(reactions, (label, value) => ({
                                label,
                                value
                            })))}
                        </Block>
                        <Block>
                            {this.checkbox('event_rule_notification.settings.allow_mass_read', 'Разрешить пакетное изменение статусов прочтения')}
                            {this.checkbox('event_rule_notification.settings.allow_level_change', 'Разрешить изменять важность')}
                            {this.checkbox('event_rule_notification.settings.allow_config', 'Разрешить настраивать напоминания')}
                        </Block>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }


    async setValue(field, value) {
        super.setValue(field, value);

        if ((field === 'event_rule_notification.roles') || (field === 'event_rule_notification.units')) {
            this.loadUsers();
        }
    }

	async loadUsers() {
		const response_data =  [
				'items/component',
				'items/created_at',
				'items/dismissals',
				'items/driver_license',
				'items/info',
				'items/is_notificated',
				'items/is_semi_confirmed',
				'items/is_supervisor',
				'items/is_system',
				'items/is_telematics_active',
				'items/is_virtual',
				'items/login',
				'items/roles/access_type',
				'items/roles/components',
				'items/roles/created_at',
				'items/roles/description',
				'items/roles/is_reverse',
				'items/roles/is_system',
				'items/roles/name',
				'items/roles/unit_uuid',
				'items/roles/updated_at',
				'items/roles/uuid',
				'items/subsystems',
				'items/updated_at',
				'items/uuid',
				'items/work_graphics',
			]
		if ((this.get('roles', []).length + this.get('units', []).length) === 0) {
			this.setState({
				users: [],
			});
			return;
		}

		let result = {
			isOk: true,
			payload: {
				items: [],
			},
		};
		if ((this.get('roles', []).length > 0) && (this.get('units', []).length > 0)) {
			result = await this.props.getUsers({
				response_data,
				filters: {
					notVirtual: true,
					withRoles: this.get('roles', []),
					withUnits: this.get('units', []),
				},
			});
		} else if (this.get('roles', []).length > 0) {
			result = await this.props.getUsers({
				response_data,
				filters: {
					notVirtual: true,
					withRoles: this.get('roles', []),
				},
			});
		} else if (this.get('units', []).length > 0) {
			result = await this.props.getUsers({
				response_data,
				filters: {
					notVirtual: true,
					withUnits: this.get('units', []),
				},
			});
		}

        if (result.isOk) {
            this.setState({
                users: _.map(result.payload.items, (item) => ({
                    label: new User(item).getFullName(),
                    value: item.uuid,
                })),
            });
        } else {
            result.showErrors();
        }
    }
}