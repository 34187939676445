import {combineReducers} from 'redux'
import layer_list from "./layer_list";
import layer_editor from "./layer_editor";
import object_editor from "./object_editor";
import layers from "./layers";
import map_search from "./map_search";

export default combineReducers({
    layer_list,
    layer_editor,
    object_editor,
    layers,
    map_search,
});