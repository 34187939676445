import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const geocode = (data) => async (dispatch) => {
    try {
        const response = await api.geo.geocode(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const geoSearch = (query) => async (dispatch) => {
    try {
        const response = await api.geo.geoSearch(query);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const routing = (data) => async (dispatch) => {
    try {
        const response = await api.routing.find(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const searchAddress = (q, bounding_box, zoom) => async (dispatch) => {
    try {
        const response = await api.geo.searchAddress(q, bounding_box, zoom);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const reverseGeocode = (coordinates, boundingBox, zoom) => async (dispatch) => {
    try {
        const response = await api.geo.reverseGeocode(coordinates, boundingBox, zoom);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};