import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import classNames from 'classnames';
import Block from "components/ui/form/block";
import Select from "components/ui/select";
import systems from "dictionaries/systems";
import MapFilterExtendedTabBase from "components/modules/maps/Filter/Extended/Tab/MapFilterExtendedTabBase";
import MapFilterExtendedFilter from "components/modules/maps/Filter/Extended/Filter/MapFilterExtendedFilter";
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import MapFilterExtendedFilterDictionary from "components/modules/maps/Filter/Extended/Filter/MapFilterExtendedFilterDictionary";
import {getRoutes} from "store/reducers/routes/routes";
import {getContracts} from "store/reducers/kiutr/contracts/contracts";
import MapFilterExtendedFilterEnum from "components/modules/maps/Filter/Extended/Filter/MapFilterExtendedFilterEnum";
import {getVehicleList} from "store/reducers/vehicles/vehicles";
import Checkbox from "components/ui/form/checkbox";

@propTypes({
    isActive: PropTypes.bool,
})

@connect(state => ({}), {
    getVehicleList,
})

export default class MapFilterExtendedTabParameters extends MapFilterExtendedTabBase {

    state = {};

    parameters = {
        is_low_floor_level: 'Низкий уровень пола',
        is_air_conditioning_installation: 'Климатическая установка',
        is_electronic_scoreboard: 'Электронное табло',
        is_cashless_payment: 'Безналичная оплата',
        is_passenger_monitoring_system: 'Система мониторинга пассажиропотока',
        is_audio_video_fixation: 'Аудио/Видео фиксация',
    };

    getModificators() {
        return {};
    }

    renderContent() {
        return (
            <div>
                <MapFilterExtendedFilter
                    title="ТС"
                    selected={_.get(this.props.filters, 'extended.parameters.vehicles')}
                    onSelectedChange={this.onChange.bind(this, 'extended.parameters.vehicles')}
                    loadData={::this.loadVehicles}
                />
                <Block size="xl">
                    <Checkbox
                        checked={!!_.get(this.props.filters, 'extended.parameters.malfunction')}
                        label="ТС в ремонте"
                        onChange={this.onChange.bind(this, 'extended.parameters.malfunction')}
                        />
                </Block>
                {this.isKurs() ? (
                    <MapFilterExtendedFilterDictionary
                        title="Оборудование ТС"
                        dictionaryKey="kurs_mechanism_types"
                        selected={_.get(this.props.filters, 'extended.parameters.kurs_mechanism_types')}
                        onSelectedChange={this.onChange.bind(this, 'extended.parameters.kurs_mechanism_types')}
                    />
                ) : null}
                {this.isKiutrOrChildren() ? (
                    <MapFilterExtendedFilterEnum
                        title="Оснащение ТС"
                        items={_.map(this.parameters, (label, value) => ({
                            value,
                            label,
                        }))}
                        selected={_.get(this.props.filters, 'extended.parameters.parameters')}
                        onSelectedChange={this.onChange.bind(this, 'extended.parameters.parameters')}
                    />
                ) : null}
                <MapFilterExtendedFilterDictionary
                    title="Тип ТС"
                    dictionaryKey="vehicle_types"
                    component={_.get(this.props.filters, 'extended.parameters.component')}
                    selected={_.get(this.props.filters, 'extended.parameters.vehicle_types')}
                    onSelectedChange={this.onChange.bind(this, 'extended.parameters.vehicle_types')}
                />
                {this.isKiutrOrChildren() ? (
                    <MapFilterExtendedFilterDictionary
                        title="Класс вместимости"
                        dictionaryKey="vehicle_capacity_types"
                        selected={_.get(this.props.filters, 'extended.parameters.vehicle_capacity_types')}
                        onSelectedChange={this.onChange.bind(this, 'extended.parameters.vehicle_capacity_types')}
                    />
                ) : null}
                {this.isGatn() ? (
                    <MapFilterExtendedFilterDictionary
                        title="Ответственный"
                        dictionaryKey="gatn_responsives"
                        selected={_.get(this.props.filters, 'extended.parameters.responsives')}
                        onSelectedChange={this.onChange.bind(this, 'extended.parameters.responsives')}
                    />
                ) : null}
            </div>
        )
    }

    isKiutrOrChildren() {
        return _.indexOf(['kiutr', 'children', 'ems'], _.get(this.props.filters, 'extended.parameters.component')) !== -1;
    }

    isKurs() {
        return _.get(this.props.filters, 'extended.parameters.component') === 'road';
    }

    isGatn() {
        return _.get(this.props.filters, 'extended.parameters.component') === 'control';
    }

    async loadVehicles(search = null, limit = 25) {
        let filters = {};
        if (_.get(this.props.filters, 'extended.parameters.component')) {
            filters.withComponent = _.get(this.props.filters, 'extended.parameters.component');
        }
        if ((_.get(this.props.filters, 'extended.parameters.vehicle_types') || []).length > 0) {
            filters.withVehicleType = _.get(this.props.filters, 'extended.parameters.vehicle_types');
        }
        if ((_.get(this.props.filters, 'extended.parameters.vehicle_capacity_types') || []).length > 0) {
            filters.withCapacityType = _.get(this.props.filters, 'extended.parameters.vehicle_capacity_types');
        }
        if ((_.get(this.props.filters, 'extended.parameters.responsives') || []).length > 0) {
            filters.withGatnResponsive = _.get(this.props.filters, 'extended.parameters.responsives');
        }

        const response = await this.props.getVehicleList({
            filters,
            search,
            pagination: {
                page: 1,
                limit: limit,
            },
        });

        if (response.isOk) {
            return _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: `${item.state_number}${item.garage_number ? (` (${item.garage_number})`) : ''}`,
            }));
        } else {
            response.showErrors();
            return [];
        }
    }
}