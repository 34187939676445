import React from 'react';
import PageModalComponent from "components/ui/page-modal";
import Block from "components/ui/form/block";
import TabBlock from "components/ui/tabs/tab-block";
import PropTypes from 'prop-types';

const RoadModal = ({ title, buttons, data, hideRoadModals }) => (
    <PageModalComponent
        header={{title, buttons}}
        onClose={hideRoadModals}
        className='profile-modal'
        withFade={false}
    >
        <TabBlock withBorder>
            {data.map(({title, data}) => 
                (<Block title={title}>
                    {data}
                </Block>)
            )}
        </TabBlock>
    </PageModalComponent>
)

RoadModal.propTypes = {
    title: PropTypes.string.isRequired,
    buttons: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    hideRoadModals: PropTypes.func.isRequired,
}
export  { RoadModal };