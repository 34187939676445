import { api } from 'helpers/api';
import { error, success } from 'helpers/response';
import { DictionaryMeta } from 'helpers/components/dictionaries/dictionary_meta';
import _ from 'lodash';
import { cached } from 'helpers/cached';
import { setDictionary, SET_DICTIONARY } from './actions';
import { reduceDictionariesToObject, reduceDictionaryToObject } from './utils';

const RESET = 'RESET';
const GET_DICTIONARY_META = 'GET_DICTIONARY_META';
const GET_DICTIONARY_LIST = 'GET_DICTIONARY_LIST';

const initialState = {
	meta: {
		title: 'Справочник'
	},
	dictionaries: {}
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_DICTIONARY_META:
			return { ...state, meta: action.payload };
		case GET_DICTIONARY_LIST:
			return state;
		case RESET:
			return initialState;
		case SET_DICTIONARY:
			return { ...state, dictionaries: { ...state.dictionaries, ...action.payload } };
	}

	return state;
}

export const getDictionaryMeta = (key) => async (dispatch) => {
	try {
		let response = await api.dictionary.getDictionaryMeta(key);

		dispatch({
			type: GET_DICTIONARY_META,
			payload: new DictionaryMeta(response.payload)
		});

		return success(response);
	} catch (e) {
		return error(e);
	}
};

export const getDictionaryList = (key, meta = null, isCached = true) => async (dispatch) => {
	const cacheKey = _.isArray(key) ? `dictionary:array:${key.join(',')}` : `dictionary:single:${key}`;
	const callback = async () => {
		try {
			meta = meta || {
				order: {
					column: 'name',
					direction: 'asc'
				}
			};
			if (isCached) {
				meta.pagination = {
					page: 1,
					limit: 10000
				};
			}
			let response;
			if (_.isArray(key)) {
				response = await api.dictionary.getDictionaryLists(key, meta);
				const objectToDispatch = setDictionary(reduceDictionariesToObject(response.payload.items));
				dispatch(objectToDispatch);
			} else {
				response = await api.dictionary.getDictionaryList(key, meta);
				const objectToDispatch = setDictionary({ [key]: reduceDictionaryToObject(response.payload.documents) });
				dispatch(objectToDispatch);
			}

			return success(response);
		} catch (e) {
			return error(e);
		}
	};
	return await callback();
	//return isCached ? cached(cacheKey, callback, 60, true) : await callback();
};

export const reset = (key) => async (dispatch) => {
	dispatch({
		type: RESET,
		payload: {}
	});
};

export const offDayGet = (data) => async (dispatch) => {
	try {
		const response = await api.dictionary.offDayGet(data);

		return success(response);
	} catch (e) {
		return error(e);
	}
};
