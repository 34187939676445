export const MaintenancePeriods = [
    {
        label: '0 мес',
        value: 0,
    },
    {
        label: '3 мес',
        value: 3,
    },
    {
        label: '6 мес',
        value: 6,
    },
    {
        label: '9 мес',
        value: 9,
    },
];