import React from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';
import _ from 'lodash';
import "./style.less";
import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import BaseEditor from "components/base/base-editor";
import {
    createTechnocard, getTechnocard,
    updateTechnocard
} from "store/reducers/kurs/technocards";
import TableContainer from "components/ui/Table/Container/TableContainer";
import ContextTooltip from "components/ui/context-tooltip";
import Button from "components/ui/button";
import IconButton from "components/ui/icon-button";
import * as alerts from "helpers/alerts";
import Page from "components/ui/page";
import systems from "dictionaries/systems";
import currentUser from 'helpers/current-user';
import GlobalLoaderComponent from "components/ui/global-loader";
import PageModalComponent from "components/ui/page-modal";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import classNames from 'classnames';
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import {api} from "helpers/api";
import FileReaderInput from 'react-file-reader-input';

@connect(state => ({}), {getTechnocard, createTechnocard, updateTechnocard})

export default class KursTechnocardEditor extends BaseEditor {

    title = 'технологической карты';
    modelClass = 'App\\Model\\Technocard';

    componentDidMount() {
        this.forceUpdate();
    }

    async componentWillUpdate(props, state) {
        const propsUuid = (props.params.uuid === 'create') ? null : props.params.uuid;
        if (state.uuid !== propsUuid) {
            this.setState({
                uuid: propsUuid,
                item: null,
                isLoading: !!propsUuid,
                mode: propsUuid ? 'edit' : 'add',
            });
            if (propsUuid) {
                const response = await this.loadData(propsUuid);
                if (response.isOk) {
                    this.setState({
                        item: response.payload,
                        isLoading: false,
                    });
                } else {
                    response.showErrors();
                }
            }
        }
    }

    async loadData(uuid) {
        return await this.props.getTechnocard(uuid);
    }

    async createItem(data) {
        return await this.props.createTechnocard(data);
    }

    async updateItem(data) {
        return await this.props.updateTechnocard(data);
    }

    async create(data) {
        this.clearErrors();
        this.startSave();

        this.setState({
            formUpdated: false,
        });
        const response = await this.createItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            this.props.router.push(`/road/technocards/${response.payload.uuid}`);
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();

        this.setState({
            formUpdated: false,
        });
        const response = await this.updateItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            this.setState({
                item: response.payload,
                formUpdated: false,
            });
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                ref="form"
                mode={this.props.mode}
                onSubmit={onSubmit}
                onClose={::this.onClose}
                data={item}
                errors={this.state.errors}
                onUpdate={::this.onFormUpdate}
            />
        );
    }

    onFormUpdate() {
        this.setState({
            formUpdated: true,
        });
    }

    onClose() {
        if (this.state.formUpdated) {
            alerts.prompt('Вы действительно хотите закрыть эту страницу без сохранения данных?', '', () => {
                this.props.router.push('/road/technocards');
            }, 'Закрыть');
        } else {
            this.props.router.push('/road/technocards');
        }
    }

    render() {
        let title = this.getTitle() ? `Добавление ${this.getTitle()}` : '';

        let form;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;
        let onSubmit;

        if (this.state.mode === 'edit') {
            title = this.getTitle() ? `Редактирование ${this.getTitle()}` : '';

            if (this.state.item) {
                form = this.getForm(this.state.item, ::this.edit);
                onSubmit = ::this.onEdit;
            }
        } else {
            form = this.getForm({}, ::this.create);
            onSubmit = ::this.onCreate;
        }

        return (
            <Page
                pageId="RoadMachineryAccountingView"
                title={`${systems.road} → ${title}`}
                className="profile-modal"
                headerActions={this.renderHeaderActions()}
            >
                {loader}
                {form}
                {this.state.formUpdated ? (
                    <div className="page-footer">
                        <div className="page-footer__txt">Вы хотите сохранить все изменения?</div>
                        <Button size="md" color="white" shadow="gray" className="b-button_cancel" text="Отменить"
                                onClick={::this.onReset}/>
                        <Button size="md" color="red" className="b-button_save" text="Сохранить"
                                onClick={onSubmit}/>
                    </div>
                ) : null}
            </Page>
        );
    }

    renderHeaderActions() {
        return [
            ((this.state.mode === 'edit') && currentUser.can('com.rnis.system.permission.audit', 'read') && this.modelClass) ?
                (
                    <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                    default="Журнал аудита">
                        <IconButton icon="history" onClick={::this.gotoAudit}/>
                    </ContextTooltip>
                ) : null,
            <ContextTooltip key="kurs.vehicle.back" code="kurs.vehicle.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ];
    }

    gotoAudit() {
        const url = `/system/audit/${this.state.uuid}?class=${this.modelClass}`;

        this.props.router.push(url);
    }

    onReset() {
        this.refs.form.getWrappedInstance().onReset();
        this.setState({
            formUpdated: false,
        });
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
})

@connect((state) => ({}), {getDictionaryList}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {
    state = {
        technocard: {},
        work_types: [],
        vehicle_types: [],
        itemActive: null,
        subitemActive: null,
    };

    getData() {
        return this.state.technocard;
    }

    async componentDidMount() {
        await this.setState({
            technocard: this.props.data,
        });
        this.loadDictionaries([
            'work_types',
            'vehicle_types',
            'kurs_materials',
        ], 'road');
    }

    get(path, defaultValue = null) {
        return _.get(this.state.technocard, path, defaultValue);
    }

    deleteFile() {
        this.setValue('technocard.file', null);
    }

    render() {
        return (
            <div className="page-block">
                <Block title="Наименование">
                    {this.textInput('technocard.name')}
                </Block>
                <Block title="Дата утверждения">
                    {this.datepicker('technocard.confirm_date')}
                </Block>
                <Block title="Документ">
                    {this.get('file') ? (
                        <span>
                            <a href={this.get('file')} target="_blank">Открыть</a>
                            <br/>
                            <a href="#" onClick={::this.deleteFile}>Удалить</a>
                        </span>
                    ) : null}
                    <FileReaderInput as="binary" id="my-file-input" onChange={::this.uploadFile}>
                        <a href="#">Загрузить файл</a>
                    </FileReaderInput>
                </Block>
                <Block size="xl">
                    <div className="Table">
                        <TableContainer>
                            <table className="b-table">
                                <thead>
                                <tr>
                                    <th>Вид работ по дефектной ведомости</th>
                                    <th>Виды работы</th>
                                    <th>Материалы</th>
                                    <th>Типы ТС</th>
                                    <th>Количество</th>
                                    <th>Комментарий</th>
                                </tr>
                                </thead>
                                <tbody>
                                {_.map(this.get('items') || [], ::this.renderItem)}
                                </tbody>
                            </table>
                        </TableContainer>
                    </div>
                    <span className="add-line" onClick={this.addItem.bind(this, -1)}>Добавить строку</span>
                </Block>
                {this.renderItemModal()}
                {this.renderSubitemModal()}
            </div>
        );
    }

    async uploadFile(e, results) {
        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        results.forEach(result => {
            const [e, file] = result;
            //formData.append('file', e.target.result);
            formData.append('file', file);
        });

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');

        this.setValue('technocard.file', value);
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }

    renderItem(item, index) {
        const rows = 1 + _.sumBy(item.work_types || [], (workType) => {
            return (workType.vehicle_types || []).length;
        }) + (item.work_types || []).length;

        return ([
            <tr key={index}>
                <td rowSpan={rows} className="link"
                    onClick={this.addItem.bind(this, index)}>{this.get(`items.${index}.name`)}</td>
                <td className="invisible-cell"/>
                <td className="invisible-cell"/>
                <td className="invisible-cell"/>
                <td className="invisible-cell"/>
                <td rowSpan={rows}>{this.get(`items.${index}.comment`)}</td>
            </tr>,
            _.map(item.work_types, this.renderItemWorkType.bind(this, index)),
        ]);
    }

    renderItemWorkType(itemIndex, workType, index) {
        const rows = 1 + (workType.vehicle_types || []).length;

        return ([
            <tr key={`${itemIndex}:${index}`}>
                <td rowSpan={rows}>{_.get(_.find(this.state.work_types, {value: this.get(`items.${itemIndex}.work_types.${index}.work_type_uuid`)}), 'label')}</td>
                <td rowSpan={rows}>{_.get(_.find(this.state.kurs_materials, {value: this.get(`items.${itemIndex}.work_types.${index}.material_uuid`)}), 'label')}</td>
            </tr>,
            _.map(workType.vehicle_types, this.renderItemWorkTypeVehicleType.bind(this, itemIndex, index)),
        ]);
    }

    renderItemWorkTypeVehicleType(itemIndex, workTypeIndex, vehicleType, index) {
        return (
            <tr key={`${itemIndex}:${workTypeIndex}:${index}`}>
                <td>{_.get(_.find(this.state.vehicle_types, {value: this.get(`items.${itemIndex}.work_types.${workTypeIndex}.vehicle_types.${index}.vehicle_type_uuid`)}), 'label')}</td>
                <td>{vehicleType.count}</td>
            </tr>
        );
    }

    addItem(itemActive) {
        if (itemActive === -1) {
            let technocard = this.state.technocard;
            technocard.items = technocard.items || [];
            technocard.items.push({
                name: '',
                comment: '',
                work_types: [],
            });
            this.setState({technocard});
            itemActive = technocard.items.length - 1;
        }
        this.setState({
            itemActive,
        });
    }

    itemClose() {
        this.setState({
            itemActive: null,
            subitemActive: null,
        });
    }

    subitemClose() {
        this.setState({
            subitemActive: null,
        });
    }

    async setValue(field, value) {
        if (/^technocard.items.[0-9]+.work_types.[0-9]+.vehicle_types.[0-9]+.count$/.test(field)) {
            value = Math.max(1, value);
        }

        super.setValue(field, value);

        this.props.onUpdate();
    }

    onReset() {
        this.setState({
            technocard: _.cloneDeep(this.props.data),
        });
    }

    deleteItem() {
        let technocard = this.state.technocard;
        technocard.items.splice(this.state.itemActive, 1);
        this.setState({
            technocard,
        });

        this.itemClose()
    }

    renderItemModal() {
        if (this.state.itemActive !== null) {
            const prefix = `technocard.items.${this.state.itemActive}`;

            const title = 'Редактирование записи';
            const buttons = (
                <ModalTopMenuButtons>
                    <ModalTopMenuList className="top-menu_modal_edit">
                        <ContextTooltip key="base-editor.delete" code="base-editor.delete" default="Удалить">
                            <ModalTopMenuListItem
                                className="b-icon-link_icon_basket"
                                onClick={::this.deleteItem}
                            />
                        </ContextTooltip>

                        <ModalTopMenuListSeparator key="separator"/>
                    </ModalTopMenuList>

                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={::this.itemClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );

            return (
                <PageModalComponent
                    header={{title, buttons}}
                    className={classNames("profile-modal b-modal-edit b-modal-routing-edit")}
                    onClose={this.props.onClose}>
                    <div className="b-modal__block">
                        <Block size="xl" title="Вид работ по дефектной ведомости">
                            {this.textInput(`${prefix}.name`)}
                        </Block>
                        <Block size="xl" title="Комментарий">
                            {this.textInput(`${prefix}.comment`)}
                        </Block>
                        <Block size="xl">
                            <div className="Table">
                                <TableContainer>
                                    <table className="b-table">
                                        <thead>
                                        <tr>
                                            <th>Вид работ</th>
                                            <th>Типы ТС</th>
                                            <th>Количество</th>
                                            <th/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {_.map(_.get(this.state, `${prefix}.work_types`) || [], ::this.renderItemInModal)}
                                        </tbody>
                                    </table>
                                </TableContainer>
                            </div>
                            <span className="add-line"
                                  onClick={this.addItemInModal.bind(this, -1)}>Добавить строку</span>
                        </Block>
                    </div>
                </PageModalComponent>
            );
        }
    }

    renderItemInModal(workType, index) {
        const rows = 1 + (workType.vehicle_types || []).length;

        return ([
            <tr key={index}>
                <td rowSpan={rows} className="link"
                    onClick={this.addItemInModal.bind(this, index)}>{_.get(_.find(this.state.work_types, {value: workType.work_type_uuid}), 'label')}</td>
                <td className="invisible-cell"/>
                <td className="invisible-cell"/>
                <td rowSpan={rows} className="align-center">
                    <span className="edit" onClick={this.removeItem.bind(this, index)}>×</span>
                </td>
            </tr>,
            _.map(workType.vehicle_types, ::this.renderItemWorkTypeVehicleTypeInModal),
        ]);
    }

    removeItem(index) {
        if (this.state.itemActive === -1) {
            let new_item = this.state.new_item;
            new_item.work_types = new_item.work_types || [];
            new_item.work_types.splice(index, 1);
            this.setState({new_item});
        } else {
            let technocard = this.state.technocard;
            technocard.items[this.state.itemActive].work_types.splice(index, 1);
            this.setState({technocard});
        }
    }

    renderItemWorkTypeVehicleTypeInModal(vehicleType, index) {
        return (
            <tr key={index}>
                <td>{_.get(_.find(this.state.vehicle_types, {value: vehicleType.vehicle_type_uuid}), 'label')}</td>
                <td>{vehicleType.count}</td>
            </tr>
        );
    }

    addItemInModal(subitemActive) {
        if (subitemActive === -1) {
            let technocard = this.state.technocard;
            technocard.items[this.state.itemActive].work_types.push({
                work_type_uuid: null,
                material_uuid: null,
                vehicle_types: [],
            });
            this.setState({technocard});
            subitemActive = technocard.items[this.state.itemActive].work_types.length - 1;
        }
        this.setState({
            subitemActive,
        });
    }

    renderSubitemModal() {
        if (this.state.subitemActive !== null) {
            const prefix = `technocard.items.${this.state.itemActive}.work_types.${this.state.subitemActive}`;

            const title = 'Редактирование записи';
            const buttons = (
                <ModalTopMenuButtons>
                    <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                        <ModalTopMenuButton
                            className="_close"
                            onClick={::this.subitemClose}
                        />
                    </ContextTooltip>
                </ModalTopMenuButtons>
            );

            return (
                <PageModalComponent
                    header={{title, buttons}}
                    className={classNames("profile-modal b-modal-edit b-modal-routing-work-edit")}
                    onClose={this.props.onClose}
                    withFade={false}>
                    <div className="b-modal__block">
                        <Block size="xl" title="Вид работ">
                            {this.select(`${prefix}.work_type_uuid`, this.state.work_types)}
                        </Block>
                        <Block size="xl" title="Материалы/ресурсы">
                            {this.select(`${prefix}.material_uuid`, this.state.kurs_materials)}
                        </Block>
                        {_.map(_.get(this.state, `${prefix}.vehicle_types`) || [], ::this.renderVehicleType)}
                        <span className="add-line"
                              onClick={::this.addVehicleType}>Добавить тип ТС</span>
                    </div>
                </PageModalComponent>
            );
        }
    }

    renderVehicleType(vehicleType, index) {
        const prefix = `technocard.items.${this.state.itemActive}.work_types.${this.state.subitemActive}.vehicle_types.${index}`;

        return (
            <div key={index}>
                <Block size="sm" title={(index === 0) ? 'Тип' : null}>
                    {this.select(`${prefix}.vehicle_type_uuid`, this.state.vehicle_types)}
                </Block>
                <Block size="sm" title={(index === 0) ? 'Кол-во ТС' : null}>
                    {this.textInput(`${prefix}.count`, {
                        type: 'number',
                        positive: true,
                    })}
                </Block>
                <Block size="extra-xs">
                    <span className="edit" onClick={this.deleteVehicleType.bind(this, index)}>×</span>
                </Block>
            </div>
        );
    }

    addVehicleType() {
        let technocard = this.state.technocard;
        technocard.items[this.state.itemActive].work_types[this.state.subitemActive].vehicle_types.push({
            vehicle_type_uuid: null,
            count: '1',
        });
        this.setState({technocard});
    }

    deleteVehicleType(index) {
        let technocard = this.state.technocard;
        technocard.items[this.state.itemActive].work_types[this.state.subitemActive].vehicle_types.splice(index, 1);
        this.setState({technocard});
    }
}