import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import Block from "components/ui/form/block";

import {getRoutes} from "store/reducers/routes/route_editor";
import * as alerts from "helpers/alerts";
import GlobalLoaderComponent from "components/ui/global-loader";
import TableContainer from "components/ui/Table/Container/TableContainer";
import formats from "dictionaries/formats";
import moment from "moment";
import './turn.less';
import {createScheduleTurn} from "store/reducers/kiutr/schedules/schedule_turns";
import Page from 'components/ui/page';
import IconButton from "components/ui/icon-button";
import {getRouteVariants} from "store/reducers/routes/route_variants";
import {getIntervalMaps} from "store/reducers/routes/interval_maps";
import {getStopPoints} from "store/reducers/geo/stop-points";
import Input from "components/ui/form/input";
import KiutrRouteScheduleTurnShiftInfoComponent from "components/modules/kiutr/routes/schedule/components/shift-info";
import KiutrRouteScheduleTurnRunsComponent from "components/modules/kiutr/routes/schedule/components/runs";
import ContextTooltip from "components/ui/context-tooltip";

@connect(state => ({}), {
    createScheduleTurn,
    getRouteVariants,
    getIntervalMaps,
    getStopPoints,
    getRoutes,
})

export default class KiutrRouteScheduleAdditionalTurnComponent extends BaseEditorFormComponent {

    state = {
        turns: [],
        routeUuid: null,
        turnNumber: null,
        loading: false,
        turn: null,
        routeVariants: [],
        routes: [],
        defaultStopPoints: [],
        editProductionRunIndex: null,
        editStopPointIndex: null,
    };

    async componentWillUpdate(props, state) {
        if (props.params.routeUuid !== state.routeUuid) {
            await this.setState({
                routeUuid: props.params.routeUuid,
                turn: {
                    schedule_uuid: null,
                    daily_order_uuid: props.params.uuid,
                    route_uuid: props.params.routeUuid,
                    number: 1,
                    start_at: '08:00',
                    end_at: '08:00',
                    runs: [],
                },
            });
            this.loadRouteVariants();
        }
    }

    componentDidMount() {
        this.forceUpdate();
    }

    async loadRouteVariants() {
        const response = await this.props.getRouteVariants({
            filters: {
                withRoute: this.state.routeUuid,
            },
        });
        if (response.isOk) {
            this.setState({
                routeVariants: response.payload.items,
            });
            const defaultVariant = _.find(response.payload.items, {is_default: true});
            this.loadStopPoints(_.concat(defaultVariant.forward_points, defaultVariant.reverse_points));
        } else {
            response.showErrors();
        }
    }

    async loadStopPoints(points) {
        const response = await this.props.getStopPoints({
            filters: {
                withUuid: _.uniq(_.filter(_.map(points, 'type_uuid'))),
            },
            response_data: [
                'items/uuid',
                'items/title',
            ],
        });
        if (response.isOk) {
            this.setState({
                defaultStopPoints: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'title'),
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;

        return (
            <Page
                title="Дополнительный выход"
                pageId="ScheduleTurn"
                headerActions={this.renderHeaderActions()}
            >
                {loader}

                {this.state.turn ? (
                    <div>
                        {this.renderHeader()}
                        {this.renderTurnInfo()}
                        <KiutrRouteScheduleTurnRunsComponent
                            turn={this.state.turn}
                            addRun={::this.addRun}
                            deleteLastRun={::this.deleteLastRun}
                            routeVariants={this.state.routeVariants}
                        />
                        <KiutrRouteScheduleTurnShiftInfoComponent
                            turn={this.state.turn}
                        />
                        {this.renderProductionRuns()}
                    </div>
                ) : null}
            </Page>
        );
    }

    renderHeaderActions() {
        return [
            <ContextTooltip key="base-editor.save" code="base-editor.save" default="Сохранить">
                <IconButton
                    icon="save"
                    onClick={::this.save}/>
            </ContextTooltip>,
            <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                <IconButton
                    icon="times"
                    onClick={::this.close}/>
            </ContextTooltip>,
        ];
    }

    async save() {
        this.setState({loading: true});
        const turn = this.state.turn;

        const response = await this.props.createScheduleTurn(turn);
        this.setState({loading: false});
        if (response.isOk) {
            this.setState({
                turn: response.payload,
            });
            this.close();
            return true;
        } else {
            response.showErrors();
        }
        return false;
    }

    close() {
        this.props.router.push(`/${this.props.params.component}/orders/${this.props.params.uuid}`);
    }

    renderHeader() {
        return (
            <div className="turn-header">
                <Block title="Номер выхода">
                    {this.textInput('turn.number')}
                </Block>
            </div>
        );
    }

    renderTurnInfo() {
        const reshifts = _.filter(this.state.turn.runs, {type: 'reshift'});
        const shiftStarts = _.map(reshifts, 'start_time');
        const shiftEnds = _.map(reshifts, (run) => (moment(run.start_time, formats.TIME).add(run.time, 'minutes').format(formats.TIME)));

        return (
            <div className="turn-info">
                <Block title="Начало">
                    {this.textInput('turn.start_at')}
                </Block>
                <Block title="Конец">
                    {this.textInput('turn.end_at', {disabled: true})}
                </Block>
                <Block title="Начало пересмен.">
                    <Input disabled={true} value={shiftStarts.join(', ')}/>
                </Block>
                <Block title="Конец пересмен.">
                    <Input disabled={true} value={shiftEnds.join(', ')}/>
                </Block>
            </div>
        );
    }

    getProductionRuns() {
        return _.filter(this.state.turn.runs, (run) => (run.type === 'production_forward' || run.type === 'production_reverse'));
    }

    renderProductionRuns() {
        const productionRuns = this.getProductionRuns();
        const defaultRouteVariant = _.find(this.state.routeVariants, {is_default: true});

        return (
            <div className="turn-production-runs">
                <div className="Table">
                    <TableContainer>
                        <table className="b-table b-table-no-hover">
                            <thead>
                            <tr>
                                <th>Остановка/рейс</th>
                                {productionRuns.map((run, index) => (
                                    <th key={index} width="100px">{index + 1}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {defaultRouteVariant && defaultRouteVariant.forward_points.map(this.renderPointRow.bind(this, productionRuns, defaultRouteVariant.forward_points, defaultRouteVariant.reverse_points))}
                            <tr>
                                <td>Итого пробег</td>
                                {productionRuns.map((run, index) => (
                                    <td key={index}>{_.sumBy(_.filter(productionRuns, (item, runIndex) => runIndex <= index), 'distance')}</td>
                                ))}
                            </tr>
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </div>
        );
    }

    renderPointRow(productionRuns, forwardPoints, reversePoints, point, index) {
        if (point.point_type !== 'stop_point') {
            return;
        }
        const pointTitle = this.state.defaultStopPoints[point.type_uuid];
        const pointIndexWithEqualTitle = _.filter(forwardPoints, (_point, _index) => {
            return (this.state.defaultStopPoints[_point.type_uuid] === pointTitle) && (_index < index);
        }).length;
        const reversePoint = _.filter(_.reverse(reversePoints), (_point, _index) => {
            return this.state.defaultStopPoints[_point.type_uuid] === pointTitle;
        })[pointIndexWithEqualTitle];

        return (
            <tr key={index}>
                <td>{pointTitle}</td>
                {productionRuns.map(this.renderPointTime.bind(this, point, reversePoint))}
            </tr>
        );
    }

    renderPointTime(point, reversePoint, run, productionRunIndex) {
        const _point = (run.type === 'production_reverse') ? reversePoint : point;
        if (!_point) {
            return (
                <td key={`${productionRunIndex}`}/>
            );
        }
        let time = 0;
        let index = 0;
        if (!_.find(run.production_interval_map, {uuid: _point.type_uuid})) {
            return (
                <td key={`${productionRunIndex}:${index}`}/>
            );
        }

        _.each(run.production_interval_map, (item) => {
            if (_point.type_uuid === item.uuid) {
                return false;
            }
            time += item.interval;
            index++;
        });
        const timeObject = moment(run.start_time, formats.TIME).add(time, 'minutes');

        return (
            <td key={`${productionRunIndex}:${index}`}
                onDoubleClick={this.timeCellDblClick.bind(this, productionRunIndex, index)}>
                {((this.state.editProductionRunIndex === productionRunIndex) && (this.state.editStopPointIndex === index)) ? (
                    <Input
                        value={run.production_interval_map[index].interval || 0}
                        onChange={::this.onTimeCellChange}
                        onKeyPress={::this.timeCellKeyPress}
                    />
                ) : timeObject.format(formats.TIME)}
            </td>
        );
    }

    timeCellDblClick(productionRunIndex, stopPointIndex) {
        this.setState({
            editProductionRunIndex: productionRunIndex,
            editStopPointIndex: stopPointIndex,
        });
    }

    async onTimeCellChange({target: {value}}) {
        let runIndex = 0;
        let index = 0;
        _.each(this.state.turn.runs, (run) => {
            if (run.type === 'production_forward' || run.type === 'production_reverse') {
                if (runIndex === this.state.editProductionRunIndex) {
                    return false;
                }
                runIndex++;
            }
            index++;
        });

        let turn = this.state.turn;
        turn.runs[index].production_interval_map[this.state.editStopPointIndex].interval = Math.abs(_.toInteger(value));
        turn.runs[index].time = _.sumBy(turn.runs[index].production_interval_map, 'interval');

        await this.setState({
            turn,
        });
        this.recalcTimes();
    }

    timeCellKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({
                editProductionRunIndex: null,
                editStopPointIndex: null,
            });
        }
    }

    async deleteLastRun() {
        let turn = this.state.turn;
        if (turn.runs.length > 0) {
            turn.runs.splice(turn.runs.length - 1, 1);
            await this.setState({turn});
            this.recalcTimes();
        }
    }

    async addRun(type) {
        switch (type) {
            // только время
            case 'parking':
            case 'dinner':
            case 'settling':
            case 'gap':
            case 'reshift':
                alerts.ask('Введите время:', (time) => {
                    time = _.toInteger(time);

                    this.addRunReal({
                        type,
                        distance: 0,
                        time,
                    });
                });
                break;

            //время и расстояние
            case 'refill':
            case 'null':
            case 'techno':
                alerts.ask('Введите время:', (time) => {
                    time = _.toInteger(time);
                    alerts.ask('Введите расстояние:', (distance) => {
                        distance = _.toInteger(distance);

                        this.addRunReal({
                            type,
                            distance,
                            time,
                        });
                    });
                });
                break;

            case 'production_forward':
            case 'production_reverse':
                alerts.askSelect('Выберите вариант движения:', _.map(this.state.routeVariants, (routeVariant) => ({
                    value: routeVariant.uuid,
                    label: routeVariant.name,
                })), async (routeVariantUuid) => {
                    const routeVariant = _.find(this.state.routeVariants, {uuid: routeVariantUuid}) || {};
                    const intervalMap = await this.loadIntervalMap(routeVariantUuid, type === 'production_forward');
                    if (!intervalMap) {
                        alerts.error('Не найдена подходящая интервальная карта');
                        return;
                    }
                    const endAt = moment(this.state.turn.end_at, formats.TIME);
                    const time = endAt.hours() * 2 + ((endAt.minutes() < 30) ? 0 : 1);

                    const intervalMapValuesUnordered = _.mapValues(intervalMap.values, (values) => _.toInteger(values[time]) || 0);
                    const intervalMapValues = _.map(_.filter(_.map(routeVariant[(type === 'production_forward') ? 'forward_points' : 'reverse_points'], 'type_uuid')), (stopPointUuid, index) => ({
                        uuid: stopPointUuid,
                        interval: intervalMapValuesUnordered[index],
                        index,
                    }));

                    this.addRunReal({
                        type,
                        distance: _.sumBy(routeVariant[(type === 'production_forward') ? 'forward_points' : 'reverse_points'], 'distance_to_the_next_point'),
                        time: _.sum(_.map(intervalMapValues, 'interval')),
                        route_variant_uuid: routeVariantUuid,
                        production_interval_map: intervalMapValues,
                    });
                });
                break;
        }
    }

    async loadIntervalMap(routeVariantUuid, isForward) {
        const response = await this.props.getIntervalMaps({
            filters: {
                withRouteVariant: routeVariantUuid,
                withDirection: isForward,
                withDays: this.getDaysForIntervalMap(),
            },
        });
        if (response.isOk) {
            return _.first(response.payload.items);
        } else {
            response.showErrors();
        }
    }

    async addRunReal(run) {
        let turn = this.state.turn;
        turn.runs.push(run);
        await this.setState({turn});

        this.recalcTimes();
    }

    recalcTimes() {
        let turn = this.state.turn;
        let time = moment(turn.start_at, formats.TIME);

        _.each(turn.runs, (run) => {
            run.start_time = time.format(formats.TIME);
            time = time.add(run.time, 'minutes');
        });

        turn.end_at = time.format(formats.TIME);

        this.setState({turn});
    }

    getDaysForIntervalMap() {
        let days = [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
        ];

        return [
            days[moment().day()],
        ];
    }

    async setValue(field, value) {
        await super.setValue(field, value);

        if (field === 'turn.start_at') {
            this.recalcTimes();
        }
    }
}