import { reject } from "lodash-es";
import {rpc} from "./session";

/**
 * @param {string} code
 * @param {string} comment
 * @param {string} user_uuid
 * @return {Promise.<*>}
 */
export async function log(code, comment, user_uuid) {
    return await rpc.request('com.rnis.system.event.log.store', {
        code, comment, user_uuid
    });
}

export async function checkServiceStatuses() {
    return await rpc.request('com.rnis.system.action.status.get', {});
}


export async function getProjectsVersion() {
    return await rpc.request('com.rnis.system.action.version', {});
}

/**
 * @param {function} callback
 * @return {Promise.<void>}
 */
export async function serviceStatusesChecked(callback) {
    await rpc.subscribe('com.rnis.system.event.status.checked', callback);
}

/**
 * @return {Promise.<void>}
 */
export async function getOptions() {
    localStorage.removeItem('options');
    let count = 5;

    async function waitUntil() {
        return await new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            if (count === 0) {
                clearInterval(interval);
                setTimeout(() => {
                    localStorage.removeItem('options')
                }, 800)
                reject(false);
            }
            if (localStorage.getItem('user')) {
                clearInterval(interval);
                resolve(rpc.request('com.rnis.system.action.option.list', {}));
            } else {
                count -= 1;
            }
          }, 1000);
        });
      }
    
    return await waitUntil();
}

/**
 * @param {string} key
 * @param {string} value
 * @param {boolean} is_system
 * @return {Promise.<void>}
 */
export async function createOption(key, value, is_system) {
    return await rpc.request('com.rnis.system.action.option.create', {key, value, is_system});
}

/**
 * @param {string} key
 * @return {Promise.<void>}
 */
export async function getOption(key) {
    return await rpc.request('com.rnis.system.action.option.get', {key});
}

/**
 * @param {string} key
 * @param {string} value
 * @return {Promise.<void>}
 */
export async function setOptionValue(key, value) {
    return await rpc.request('com.rnis.system.action.option.set', {key, value});
}

/**
 * @param {string} key
 * @param {string} value
 * @param {string} user_uuid
 * @return {Promise.<void>}
 */
export async function setUserOptionValue(key, value) {
    return await rpc.request('com.rnis.system.action.user_option.set', {key, value});
}

/**
 * @param {string} key
 * @return {Promise.<void>}
 */
export async function deleteOption(key) {
    return await rpc.request('com.rnis.system.action.option.delete', {key});
}

/**
 * @param {function} callback
 * @return {Promise.<void>}
 */
export async function optionsUpdated(callback) {
    await rpc.subscribe('com.rnis.system.event.options.update', callback);
}

export async function activityStore(data) {
    return await rpc.request('com.rnis.system.action.activity.store', data);
}

export async function getActivity(meta = {}) {
    return await rpc.request('com.rnis.system.action.activity.online', {}, {meta});
}

export async function getUserLogs(meta = {}) {
    return await rpc.request('com.rnis.system.action.log.search', {}, {meta});
}

export async function getTrashLogs(meta = {}) {
    return await rpc.request('com.rnis.system.action.trash.search', {}, {meta});
}

export async function getUserLogCodes() {
    return await rpc.request('com.rnis.system.action.log.codes');
}

export async function getAudit(entityClass, entityUuid, humanNames = true, meta = {}) {
    if (!meta.filters) {
        meta.filters = {};
    }
    meta.filters.withEntity = [entityClass, entityUuid];
    return await rpc.request('com.rnis.system.action.audit.search', {
        human_names: humanNames,
    }, {meta});
}

export async function getEntityNames(payload) {
    return await rpc.request('com.rnis.system.action.entity.names', payload);
}

export async function getMaintenanceStatus() {
    return await rpc.request('com.rnis.system.maintenance.status');
}

export async function maintenanceUp(data) {
    return await rpc.request('com.rnis.system.down', data);
}

export async function maintenanceDown() {
    return await rpc.request('com.rnis.system.up');
}

export async function restoreEntity(entity, source) {
    return await rpc.request(`com.rnis.${source}.action.entity.restore`, entity);
}

export async function getTooltips() {
    return await rpc.request(`com.rnis.system.action.tooltip.list`);
}

export async function updateTooltip(data) {
    return await rpc.request(`com.rnis.system.action.tooltip.update`, data);
}

export async function tableExportCreate(source, entity, meta = {}) {
    return await rpc.request(`com.rnis.${source}.action.table.export`, {entity}, {meta});
}

export async function tableExportGet(uuid) {
    return await rpc.request('com.rnis.system.action.table.export.get', {uuid});
}

export async function tableExportList(meta = {}) {
    return await rpc.request('com.rnis.system.action.table.export.list', {}, {meta});
}
