import {Map} from "immutable";
import {api, CycleFetch} from "helpers/api";
import _ from "lodash";
import {success, error} from 'helpers/response';
import {rpc} from "../../../helpers/api/session";

const SET_CLOSE = 'SET_CLOSE';
const SET_CLOSES_TIMES = 'SET_CLOSES_TIMES';

const initialState = new Map({
    activeClose: {},
    closesTimes: []
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_CLOSE:
            return state.set('activeClose', action.payload.item);
        case SET_CLOSES_TIMES:
            return state.set('closesTimes', action.payload.items);
    }
    return state;
}

export const setActiveClose = (close) => async (dispatch) => {
    //console.log(close)
    dispatch({
        type: SET_CLOSE,
        payload: close
    });
};

export const setClosesTimes = (meta) => async (dispatch) => {
    try {
        const response = await rpc.request('com.rnis.crossroads.action.closes.get', {}, {meta});

        dispatch({
            type: SET_CLOSES_TIMES,
            payload: {
                items: response.payload.items
            }
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};