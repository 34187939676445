import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import _ from 'lodash';

import BaseTableComponent from 'components/base/base_table';
import Page from 'components/ui/page';
import IconButton from 'components/ui/icon-button';
import Button from "components/ui/button";
import StaffingEditor from './editor';

import {mapDatatablesRequestToMeta} from 'helpers/api';
import {getUsers} from 'store/reducers/staffing/staffing';
import {getTasks} from "store/reducers/kurs/tasks";

import './staffing.less';
import {deleteUser} from "store/reducers/staffing/staffing_editor";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import * as alerts from "helpers/alerts";
import TableContainer from "components/ui/Table/Container/TableContainer";
import Column from "components/ui/column";
import AppearTransition from "components/ui/transitions/appear";
import {getEntityNames, tableExportCreate, tableExportGet, tableExportList} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import currentUser from 'helpers/current-user';
import systems from "dictionaries/systems";
import ContextTooltip from "components/ui/context-tooltip";
import {getUnits} from "store/reducers/organizational_units/units";
import {State} from "components/ui/state";
import ReactDOMServer from 'react-dom/server';
import HotKeysManager from "components/ui/HotKeys/Manager/HotKeysManager";
import {getRoles} from "store/reducers/roles/roles";
import {preventDefaultEventHandlers} from "helpers/hot_key_handlers";
import Popup from "components/ui/popup";
import moment from "moment";
import formats from "dictionaries/formats";
import download from 'downloadjs'
import ExportTableButton from "components/ui/Export/ExportTableButton";
import LoaderComponent from "components/ui/loader";

@connect(state => ({}), {
    getUsers,
    getTasks,
    getUnits,
    deleteUser,
    getEntityNames,
    getRoles,
    tableExportList,
    tableExportGet,
    tableExportCreate,
    getDictionaryList,
})

export default class StaffingComponent extends BaseTableComponent {

    modelClass = 'App\\Model\\User';
    modelSource = 'auth';

    position_types = window.RNIS_SETTINGS.cleanup_specialist_for_task ? {
        'driver': 'Водитель',
        'specialist': 'Пользователь',
        'worker': 'Специалист по уборке',
    } : {
        'driver': 'Водитель',
        'specialist': 'Специалист',
        'worker': 'Рабочий',
    };
    exportRowsCount = 100000;

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            columns: List(this.getColumns()),
            related: new EntityList(),
            component: null,
        });
    }

    showAudit(rowData) {
        this.props.router.push(`/system/audit/${rowData.uuid}?class=App\\Model\\User`)
    }

    async componentWillUpdate(props, state) {
        const uuid = props.params.uuid;
        const newStaffEditorUuid = (uuid && uuid !== 'create') ? uuid : null;
        if (this.state.staffEditorUuid !== newStaffEditorUuid || this.state.showStaffEditorModal !== !!uuid) {
            this.setState({
                showStaffEditorModal: !!uuid,
                staffEditorUuid: newStaffEditorUuid,
                staffEditorModalMode: props.params.mode || 'add',
            });
        }
        if (props.params.component !== state.component) {
            await this.setState({
                component: props.params.component,
            });
            state.component && this.reload();
        }
    }

    componentDidMount() {
        this.forceUpdate();
        this.loadDictionaries(['kurs_task_statuses'])
    }

    getHotKeyHandlers() {
        return preventDefaultEventHandlers(Object.assign(super.getHotKeyHandlers(), {
            delete: ::this.deleteSelected,
        }));
    }

    render() {
        let table = this.renderTable();

        const editor = this.state.showStaffEditorModal
            ? <StaffingEditor
                {...this.props}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                onEditClick={::this.onEditorEditClick}
                onDeleteClick={::this.onEditorDeleteClick}
                mode={this.state.staffEditorModalMode}
                uuid={this.state.staffEditorUuid}
            />
            : '';

        const title = this.state.component === 'operator' ? (window.RNIS_SETTINGS.operator_title || systems[this.state.component])
            : systems[this.state.component]

        return (
            <HotKeysManager {...this.getHotKeyHandlers()}>
                <Page title={`${title} → Кадровый учет`}
                      pageId="Staffing"
                      headerActions={this.renderHeaderActions()}
                      headerContents={this.renderHeaderContents()}>

                    <TableContainer>
                        {table}
                    </TableContainer>

                    <AppearTransition>
                        {editor}
                    </AppearTransition>
                </Page>
            </HotKeysManager>
        );
    }

    getColumns() {
        switch (this.props.params.component) {
            case 'control':
                return this.prepareColumns([

                    new Column('Фамилия')
                        .fromField('info.surname'),

                    new Column('Имя')
                        .fromField('info.name'),

                    ...(!window.RNIS_SETTINGS.hide_pers_data ? [(new Column('Отчество')
                        .fromField('info.second_name'))] : []),

                    new Column('Табельный №')
                        .fromField('info.personnel_number'),

                    ...(window.RNIS_SETTINGS.show_column_unit_staffing ? [(
                    new Column('Предприятие')
                        .fromField('info.unit_uuid')
                        .withDrawer(item => item.info.unit_uuid ? this.state.related.get(item.info.unit_uuid) : '<code>-не указано-</code>')
                        .withAsyncFilter('withUnits', async (search) => {
                            const response = await this.props.getUnits({
                                search,
                                filters: {
                                    withComponent: this.props.params.component,
                                },
                                order: {
                                    column: 'name',
                                    direction: 'asc',
                                },
                                pagination: {
                                    page: 1,
                                    limit: 30,
                                },
                                response_data: [
                                    'items/uuid',
                                    'items/name',
                                ],
                            });
                            if (response.isOk) {
                                return response.payload.items;
                            }
                            return [];
                        }) )] : []),

                    new Column('Телефон')
                        .fromField('info.phone')
                        .withDrawer(item => item.info && item.info.phone && item.info.phone.replace(/((^\+7 \()|([()-]))/g, '<span class="gray-tex">$1</span>')),

                    ...(!window.RNIS_SETTINGS.hide_columns_personnel_deviations_column ? [(
                        new Column('Отклонения')
                            .withDrawer(item => item.dismissals.length || '<span class="gray-tex">Нет</span>')
                            .denyColumnFilter()
                            .withAlign('center')
                            .denyOrder()
                    )] : [])

                ]);
            case 'kiutr':
            default:
                return this.prepareColumns([

                    new Column('Фамилия')
                        .fromField('info.surname'),

                    new Column('Имя')
                        .fromField('info.name'),

                    ...((!window.RNIS_SETTINGS.hide_pers_data || window.RNIS_SETTINGS.CITY_MURMANSK) ? [(new Column('Отчество')
                        .fromField('info.second_name'))]: []),

                    new Column('Табельный №')
                        .fromField('info.personnel_number'),

                    new Column('Логин')
                        .withDrawer('login')
                        .fromField('users.login'),

                    new Column('Роли')
                        .fromField('roles')
                        .denyOrder()
                        .denyColumnFilter()
                        .withDrawer((item) => _.map(item.roles, 'name').join(', '))
                        .withFilter('withRoles', async () => {
                            const response = await this.props.getRoles({
                                pagination: {
                                    page: 1,
                                    limit: 1000,
                                },
                                filters: {
                                    withComponent: this.props.params.component,
                                },
                            });
                            if (response.isOk) {
                                return response.payload.items;
                            }
                            return {};
                        }),

                    new Column('Сквозной доступ')
                        .fromField('role_access')
                        .denyOrder()
                        .denyColumnFilter()
                        .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                            positive={_.filter(item.roles, role => role.access_type === 'all').length > 0}/>))
                        .withFilter('withRoleAccessAll', async () => {
                            return [
                                {
                                    name: 'Активен',
                                    uuid: 1,
                                },
                                {
                                    name: 'Неактивен',
                                    uuid: 0,
                                },
                            ];
                        }),

                    new Column('Мастер-аккаунт')
                        .fromField('is_system')
                        .denyOrder()
                        .denyColumnFilter()
                        .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State
                            positive={_.filter(item.roles, role => role.is_system).length > 0}/>))
                        .withFilter('withSystemRoles', async () => {
                            return [
                                {
                                    name: 'Активен',
                                    uuid: 1,
                                },
                                {
                                    name: 'Неактивен',
                                    uuid: 0,
                                },
                            ];
                        }),

                    new Column('Телефон')
                        .fromField('info.phone')
                        .withDrawer(item => item.info && item.info.phone && item.info.phone.replace(/((^\+7 \()|([()-]))/g, '<span class="gray-tex">$1</span>')),

                    ...(!window.RNIS_SETTINGS.hide_columns_personnel_deviations_column ? [(
                        new Column('Отклонения')
                            .withDrawer(item => item.dismissals.length || '<span class="gray-tex">Нет</span>')
                            .denyColumnFilter()
                            .withAlign('center')
                            .denyOrder()
                    )] : []),

                    new Column('Предприятие')
                        .fromField('info.unit_uuid')
                        .withDrawer(item => item.info && item.info.unit_uuid && this.state.related.get(item.info.unit_uuid))
                        .denyColumnFilter()
                        .withFilter('withUnits', async () => {
                            const response = await this.props.getUnits({
                                pagination: {
                                    page: 1,
                                    limit: 5000,
                                },
                                filters: {
                                    withComponent: this.props.params.component,
                                },
                                response_data: [
                                    'items/uuid',
                                    'items/name',
                                ],
                            });
                            if (response.isOk) {
                                return response.payload.items;
                            }
                            return {};
                        }),

                    new Column('Должность')
                        .fromField('info.position_name'),

                    new Column('Тип должности')
                        .fromField('info.position_type')
                        .withDrawer(item => item.info && item.info.position_type && this.position_types[item.info.position_type])
                        .denyColumnFilter()
                        .withFilter('withPositionTypes', async () => {
                            return _.map(this.position_types, (name, uuid) => ({
                                uuid,
                                name,
                            }));
                        }),

                    ...(!window.RNIS_SETTINGS.hide_columns_personnel_groups_column ? [(
                        new Column('Группы')
                            .fromField('info.groups')
                            .withDrawer(item => {
                                if (!item.info || !item.info.groups) {
                                    return '';
                                }

                                return _.map(item.info.groups, (uuid) => {
                                    return this.state.related.get(uuid);
                                }).join('<br/>');
                            })
                            .denyOrder()
                            .denyColumnFilter()
                    )] : []),
                    ...(window.RNIS_SETTINGS.cleanup_specialist_for_task ? [(
                        new Column('Статус')
                            .fromField('status')
                            .denyOrder()
                            .denyColumnFilter()
                            .withDrawer(item => {                              
                                const task = this.state.tasks.find((activeTask) => {
                                    return !!(activeTask.cleanup_specialist_uuids || []).find((cleanupSpecialistUuids) => {
                                        return cleanupSpecialistUuids === item.uuid;
                                    })
                                })

                                return task ? 'На задании' : 'Свободен';
                            })
                    )] : []),
                ]);
        }
    }

    async closeEditor() {
        await this.props.router.push(`/${this.state.component}/personnel`);
    }

    async submitEditor() {
        this.reload();
        await this.closeEditor();
    }

    async showEditor() {
        this.showEditorWithUuid();
    }

    async onEditorEditClick() {
        const uuid = this.state.staffEditorUuid;
        await this.closeEditor();
        return this.showEditorWithUuid(uuid, 'edit');
    }

    async onEditorDeleteClick() {
        return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            await this.props.deleteUser({
                items: [
                    {
                        uuid: this.state.staffEditorUuid,
                    },
                ],
            });

            await this.closeEditor();
            this.reload();
        });
    }

    async showEditorWithUuid(uuid = null, mode = 'edit') {
        await this.closeEditor();
        this.props.router.push(`/${this.state.component}/personnel/${uuid || 'create'}${uuid ? `/${mode}` : ''}`);
    }

    async showSelected() {
        if (this.state.showStaffEditorModal && (this.state.staffEditorModalMode === 'view')) {
            return this.closeEditor();
        }

        if (!this.refs.table) return;
        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            this.showEditorWithUuid(selected.uuid, 'view');
        }
    }

    onDblClick(data) {
        console.log(currentUser.can('com.rnis.auth.permission.user', 'update'))
        if (data.uuid && currentUser.can('com.rnis.auth.permission.user', 'update')) {
            this.showEditorWithUuid(data.uuid);
        }
    }

    async editSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            this.showEditorWithUuid(selected.uuid);
        }
    }

    async deleteSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data();
        return alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            const users = _.map(selected, (item) => {
                const uuid = item.uuid;
                return {uuid};
            });
            await this.props.deleteUser({
                items: users
            });
            this.reload();
        });
    }

    setFilterForCleanupSpecialist() {
        const positionTypeColumnIndex = this.state.columns.findIndex(
            (column) => column.field === 'info.position_type'
        );
        if (positionTypeColumnIndex >= 0) {
            if (!this.state.showTableFilters) {
                this.setState({
                    showTableFilters: true,
                });
            }

            // NOTE: кучка вынужденных рефов
            const tableFilterSelect = this.refs.table.getWrappedInstance()
                .refs.tableHeader.refs[`tableFilterSelect${positionTypeColumnIndex}`];
            tableFilterSelect.setSelected(['worker'])
        }
    }

    async loadData(meta) {
        meta.filters.withComponent = this.state.component;
        meta.response_data = [
            'items/uuid',
            'items/deleted_at',
            'items/info/surname',
            'items/info/name',
            'items/info/second_name',
            'items/info/personnel_number',
            'items/info/unit_uuid',
            'items/info/phone',
            'items/info/position_name',
            'items/dismissals/uuid',
            'items/login',
            'items/roles/name',
            'items/roles/access_type',
            'items/roles/is_system',
            'items/info/unit_uuid',
            'items/info/position_uuid',
            'items/info/position_type',
            'items/info/groups',
            'items/info/groups',
        ];

        const response = await this.props.getUsers(meta)

        if (window.RNIS_SETTINGS.cleanup_specialist_for_task) {
            const responseTasks = await this.props.getTasks({
                filters: {
                    onlyUuid: true,
                    withCleanupSpecialists: response.payload.items.map((user) => user.uuid),
                },
                response_data: [
                    'items/uuid',
                    'items/cleanup_specialist_uuids',
                    'items/status_uuid',
                ],
            })

            if (responseTasks.isOk) {
                const statusUuid = _.get(_.find(this.state.kurs_task_statuses, {name: 'Закрыт'}), 'uuid');
                const activeTasks = responseTasks.payload.items.filter((task) => task.status_uuid !== statusUuid)

                this.setState({
                    tasks: activeTasks,
                })
            } else {
                responseTasks.showErrors();
            }
        }

        return response;
    }

    async loadCallback(request, drawCallback, settings) {
        this.lastRequest = request;

        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        const response = await this.loadData(meta);

        if (response.isOk) {
            const data = response.payload.items;

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: response.data.headers.meta.pagination.total,
                recordsTotal: response.data.headers.meta.pagination.total
            };

            if (request.length === 25) {
                drawCallback(json);
                this.loadRelatedEntities(json, drawCallback);
            } else {
                await this.loadRelatedEntities(json, drawCallback);
            }

            this.selectedRowsRecalc();
            this.setState({success: true});
        } else {
            response.showErrors();
        }
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const units = _.filter(_.map(result, 'info.unit_uuid'));
        const positions = _.filter(_.map(result, 'info.position_uuid'));
        const groups = _.filter(_.flatten(_.filter(_.map(result, 'info.groups'))));
        const response = await this.props.getEntityNames(_.concat(_.map(units, (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        })), _.map(positions, (uuid) => ({
            class: 'App\\Model\\Position',
            uuid: uuid,
            source: 'organizational_units',
        })), _.map(groups, (uuid) => ({
            class: 'App\\Model\\UserGroup',
            uuid: uuid,
            source: 'organizational_units',
        }))));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }

        return result;
    }

    getCreateButton() {
        if (!currentUser.can('com.rnis.auth.permission.user', 'create')) {
            return null;
        }
        return (
            <ContextTooltip key="base-table-list.create" code="base-table-list.create" default="Добавить">
                <IconButton
                    icon="plus"
                    tooltip="Добавить"
                    onClick={::this.showEditor}
                    active={this.state.showStaffEditorModal && (this.state.staffEditorUuid === 'create')}
                    className="popup-link"
                />
            </ContextTooltip>
        );
    }

    renderHeaderActions() {
        return [
            currentUser.can('com.rnis.auth.permission.user', 'delete') ? (
                <ContextTooltip key="base-table-list.delete" code="base-table-list.delete" default="Удалить">
                    <IconButton icon="basket" disabled={this.state.selectedRowsCount === 0}
                                onDisabledClick={::this.alertToSelect}
                                onClick={::this.deleteSelected}/>
                </ContextTooltip>
            ) : null,
        ];
    }

    exportToXlsButton() {
        if (this.refs.export && this.refs.export.getWrappedInstance().isLoading()) {
            return (
                <LoaderComponent color="red"/>
            );
        }

        return (
            <ContextTooltip key="base-table-list.export" code="base-table-list.export" default="Экспорт в Excel">
                <IconButton icon="export" onClick={::this.exportToXls}/>
            </ContextTooltip>
        );
    }

    renderHeaderContents() {
        let headerContent = _.concat(
            super.renderHeaderContents(),
            [
                <ExportTableButton
                    key="export-log"
                    ref="export"
                    source="auth"
                    entity="users"
                    forceUpdate={::this.forceUpdate}
                />,
            ]);
        
        if (window.RNIS_SETTINGS.personnel_with_cleanup_specialist_filter) {
            headerContent = headerContent.concat(
                <Button
                    text="Сотрудники по уборке"
                    color="red"
                    size="md"
                    width="auto"
                    onClick={::this.setFilterForCleanupSpecialist}
                />
            )
        }

        return headerContent;
    }

    async exportToXlsReal(page) {
        if (!this.refs.export) {
            return;
        }
        let meta = mapDatatablesRequestToMeta(this.lastRequest, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        _.set(meta, 'filters.withComponent', this.state.component);
        _.set(meta, 'pagination', {
            page: 1,
            limit: this.exportRowsCount,
        });
        this.refs.export.getWrappedInstance().exportToXls(meta);
    }
}
