import {padStart} from 'lodash';

export const formatMileage = (mileage) => {
    return Math.round(mileage * 100) / 100;
};

export const formatTime = (minutes) => {
    if (isNaN(minutes)) {
        return '00:00';
    }
    return padStart(Math.floor(minutes / 60).toString(), 2, '0') + ':' + padStart((minutes % 60).toString(), 2, '0');
};

export const formatDistance = (distance) => {
    if (distance < 1000) {
        return Math.round(distance) + ' м.';
    }
    return Math.round(distance / 1000 * 100) / 100 + ' км.';
};

export const normalizationFactPlan = (fact, plan, factDistance, planDistance) => {
    let lfact = fact;
    let lplan = plan;
    let lfactDistance = factDistance;
    let lplanDistance = planDistance;
    if (lfactDistance > lplanDistance) {
        return 100
    }
    if (lfactDistance > 0 && lplanDistance === 0) {
        return 0
    }
    if (lplanDistance !== 0) {
        return lfactDistance/lplanDistance * 100;
    }
    return 0
}

export const getPercentForWorksDone = (localAllJobes, title, isNeedByJobUniqueCalculate) => {
    if (isNeedByJobUniqueCalculate) {
        const uniqueJobsUuid = _.uniq(localAllJobes.map(item => item.work_type_uuid)).filter(item => item);
        let percent = 0;
        const middle = uniqueJobsUuid.map(job => {
            const innerJobs = localAllJobes.filter(item => item.work_type_uuid === job);
            const fact = innerJobs.filter(item => item.type === "fact").length;
            const plan = innerJobs.filter(item => item.type === "plan").length;

            let factDistance = 0;
            let planDistance = 0;

            if (fact) {
                factDistance = innerJobs.filter(item => item.type === "fact").reduce((acc, val) => {
                    if (val.count) {
                        return acc + val.count
                    }
                    return acc
                }, 0)
            }


            if (plan) {
                planDistance = innerJobs.filter(item => item.type === "plan").reduce((acc, val) => {
                    if (val.count) {
                        return acc + val.count
                    }
                    return acc
                }, 0)
            }

            return [normalizationFactPlan(fact, plan, factDistance, planDistance), fact, plan]
        });

        if (middle.map(item => item[0]).length) {
            const summaryJobsPercent = middle.map(item => item[0]).reduce((acc, item) => {
                return acc + item
            });
            percent = summaryJobsPercent/uniqueJobsUuid.length
        }
        return percent;
    }
    const fact = localAllJobes.filter(item => item.type === "fact").length;
    const plan = localAllJobes.filter(item => item.type === "plan").length;

    let factDistance = 0;
    let planDistance = 0;

    if (fact) {
        factDistance = localAllJobes.filter(item => item.type === "fact").reduce((acc, val) => {
            if (val.count) {
                return acc + val.count
            }
            return acc
        }, 0)
    }


    if (plan) {
        planDistance = localAllJobes.filter(item => item.type === "plan").reduce((acc, val) => {
            if (val.count) {
                return acc + val.count
            }
            return acc
        }, 0)
    }

    console.info("factCount: ", factDistance, "planCount: ", planDistance)

    return [normalizationFactPlan(fact, plan, factDistance, planDistance), fact, plan]
}
