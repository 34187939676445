import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defaultProps, propTypes} from 'react-props-decorators';
import {Responsive, WidthProvider} from 'react-grid-layout';
const ResponsiveReactGridLayout = WidthProvider(Responsive);
import WidgetBlock from 'components/modules/analytics/base-components/WidgetBlock';
import classnames from 'classnames';
import _ from 'lodash';
import { query } from 'dom-helpers';
import './style.less';
import * as storage from "utils/storage";
import Settings from 'settings';

@propTypes({
    widgetItems: PropTypes.array,
    maximize: PropTypes.bool,
    municipality: PropTypes.bool,
    onCloseWidget: PropTypes.func,
    onMaximizeWidget: PropTypes.func,
    onMinimizeWidget: PropTypes.func
})

@defaultProps({
    widgetItems: [],
    maximize: false,
    municipality: false,
    onCloseWidget: () => {},
    onMaximizeWidget: () => {},
    onMinimizeWidget: () => {}
})

export default class WidgetLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            widgetItems: [],
            layout: {},
            activeWidget: null,
        };

        this.savedLayouts = {};
        this.maximizedId = null;
    }

    componentWillMount() {
        const widgetItems = _.clone(this.props.widgetItems);

        widgetItems.forEach((item, index) => {
            item.layout = _.cloneDeep(item.layout || this.defaultLayout(item.id, index));
            item.layout.maxH = Infinity;
            item.layout.maxW = Infinity;
            item.layout.y = Infinity;
        });

        this.setState({widgetItems});

        this.CITY_MURMANSK = window.RNIS_SETTINGS.CITY_MURMANSK

    }

    componentWillReceiveProps(nextProps) {
        const widgetItems = _.clone(nextProps.widgetItems);
        const breakpoint = this.getBreakpoint();
        const layout = _.cloneDeep(this.state.layout);
        layout[breakpoint] = [];

        widgetItems.forEach((item, index) => {
            const currentItem = this.state.widgetItems.find((curItem) => curItem.id === item.id);
            item.layout = _.cloneDeep(currentItem && currentItem.layout ? currentItem.layout : this.defaultLayout(item.id, index));
            item.hide = currentItem ? !!(currentItem.hide) : false;
            item.maximize = currentItem ? !!(currentItem.maximize) : false;
            layout[breakpoint].push(item.layout);
        });

        //console.log('componentWillReceiveProps: ', widgetItems);

        this.setState({widgetItems, layout});
    }

    getBreakpoint() {
        return this.state.breakpoint || 'lg';
    }

    getWidgetWidth(cols) {
        return cols === 12 ? 6 : cols;
    }

    getWidgetX(index, cols) {
        return cols === 12 ? index * this.getWidgetWidth(cols) % cols : 0;
    }

    defaultLayout(id, index) {
        const cols = this.state.cols || 12;
        return {
            i: id,
            x: this.getWidgetX(index, cols),
            y: Infinity,
            w: this.getWidgetWidth(cols),
            h: 9,
            minW: 5,
            maxW: Infinity,
            minH: 8,
            maxH: Infinity
        };
    }

    onCloseWidget(id) {
        const index = this.state.widgetItems.findIndex((item) => item.id === id);

        if (index >= 0) {
            const widgetItems = this.state.widgetItems;
            widgetItems.splice(index, 1);
            delete this.savedLayouts[id];

            if (this.maximizedId) {
                const breakpoint = this.getBreakpoint();
                const layout = _.cloneDeep(this.state.layout);
                layout[breakpoint] = [];

                widgetItems.forEach((item) => {
                    item.maximize = false;
                    item.hide = false;
                    item.layout = _.cloneDeep(this.savedLayouts[item.layout.i]);
                    layout[breakpoint].push(item.layout);
                });

                this.maximizedId = null;

                this.setState({ widgetItems, layout });
            }
            else {
                this.setState({widgetItems});
            }

            this.props.onCloseWidget(id);
        }
    }

    onMaximizeWidget(id) {
        let element = query.querySelectorAll(document, '#analytics-widget-layout');
        element = element.length ? element[0] : null;

        if (element) {
            const height = query.height(element) / 40;

            const widgetItems = this.state.widgetItems;

            const breakpoint = this.getBreakpoint();
            const layout = _.cloneDeep(this.state.layout);
            layout[breakpoint] = [];

            widgetItems.forEach((item) => {
                if (item.id === id) {
                    item.maximize = true;
                    /*item.layout.x = 0;
                    item.layout.y = 0;
                    item.layout.w = Infinity;
                    item.layout.h = height;
                    item.layout.static = true;*/
                }
                else {
                    item.hide = true;
                    /*item.layout.w = 0;
                    item.layout.h = 0;
                    item.layout.minW = 0;
                    item.layout.minH = 0;
                    item.layout.y = Infinity;*/
                }

                layout[breakpoint].push(item.layout);
            });

            this.maximizedId = id;

            this.setState({ widgetItems});

            this.props.onMaximizeWidget(id);
        }
    }

    onMinimizeWidget(id) {
        const widgetItems = this.state.widgetItems;

        const breakpoint = this.getBreakpoint();
        const layout = _.cloneDeep(this.state.layout);
        layout[breakpoint] = [];

        widgetItems.forEach((item) => {
            item.maximize = false;
            item.hide = false;
            item.layout = _.cloneDeep(this.savedLayouts[item.layout.i]);
            layout[breakpoint].push(_.cloneDeep(item.layout));
        });

        this.maximizedId = null;

        this.setState({ widgetItems, layout });

        this.props.onMinimizeWidget(id);
    }

    onBreakpointChange = (breakpoint, cols) => {
        const widgetItems = this.state.widgetItems;
        const width = this.getWidgetWidth(cols);

        const layout = _.cloneDeep(this.state.layout);
        layout[breakpoint] = [];

        widgetItems.forEach((item, index) => {
            item.layout.w = width;
            item.layout.x = this.getWidgetX(index, cols);
            layout[breakpoint].push(item.layout);
        });

        //console.log('onBreakpointChange: ', /*widgetItems,*/ layout, breakpoint, cols);

        this.setState({
            widgetItems,
            layout,
            breakpoint,
            cols
        });
    };

    onLayoutChange = (layouts) => {
        _.each(layouts, (layout) => {
            Settings.setUser(`analytics:layout:${this.props.cacheKey}:${layout.i}`, JSON.stringify(_.clone(layout)));
        });
        const breakpoint = this.getBreakpoint();
        const layout = _.cloneDeep(this.state.layout);
        layout[breakpoint] = layouts;

        if (!this.maximizedId) {
            this.savedLayouts = {};
            layouts.forEach((layoutItem) => {
                this.savedLayouts[layoutItem.i] = _.cloneDeep(layoutItem);
            });
        }
        else {
            layout[breakpoint].forEach((item) => {
                if (item.i === this.maximizedId) {
                    item.x = 0;
                    item.y = 0;
                }
            });
            this.setState({layout});
        }
    };

    onWidgetClick(id) {
        this.setState({
            activeWidget: id,
        });
    }

    render() {
        return (
            <div id="analytics-widget-layout">
                <ResponsiveReactGridLayout className="layout"
                                           layouts={this.state.layout}
                                           breakpoints={{lg: 1200, md: 996, sm: 768}}
                                           cols={{lg: 12, md: 12, sm: 6}}
                                           rowHeight={30}
                                           containerPadding={[0, 0]}
                                           onLayoutChange={this.onLayoutChange}
                                           onBreakpointChange={this.onBreakpointChange}
                                           draggableHandle=".widget-block_header"
                                           draggableCancel=".buttons, .widget-selector"
                                           //autoSize={false}
                >

                    {this.state.widgetItems.map((item) => {

                        const Widget = item.widget;

                        let className = classnames('widget-layout', {
                            'hide-widget': item.hide,
                            'active': this.state.activeWidget === item.id,
                            'fullscreen': item.maximize,
                        });
                        return (
                            <div key={item.id} data-grid={item.layout} className={className} onClick={this.onWidgetClick.bind(this, item.id)}>
                                <WidgetBlock title={item.name}
                                             link={item.link}
                                             faq={item.faq}
                                             router={this.props.router}
                                             maximize={item.maximize}
                                             onClose={this.onCloseWidget.bind(this, item.id)}
                                             onMaximize={this.onMaximizeWidget.bind(this, item.id)}
                                             onMinimize={this.onMinimizeWidget.bind(this, item.id)}
                                             className={item.className}
                                             titleClassName={item.titleClassName}
                                             units={this.props.units}
                                >
                                    {/*window.RNIS_SETTINGS.CITY_MURMANSK ? <Widget municipality={item.municipality} filters={this.props.filters}/> : <Widget municipality={item.municipality}  router={this.props.router}/>*/}
                                    {/*<Widget municipality={item.municipality} filters={this.props.filters}/>// smut */}
                                    <Widget
                                        units={this.props.units}
                                        municipality={item.municipality}
                                        router={this.props.router}
                                        filters={this.CITY_MURMANSK ? this.props.filters : null}
                                    />
                                </WidgetBlock>
                            </div>
                        )
                    })
                    }

                </ResponsiveReactGridLayout>
            </div>
        );
    }
}
