import React from 'react';
import {connect} from 'react-redux';
import systems from "dictionaries/systems";
import {getEntityNames} from "store/reducers/system";
import _ from 'lodash';
import './style.less';
import moment from "moment";
import formats from "dictionaries/formats";
import {EntityList} from "helpers/entity";
import {getTasks} from "store/reducers/kurs/tasks";
import {getUnits} from "store/reducers/organizational_units/units";
import {getVehicles} from "store/reducers/kurs/vehicles";
import Checkbox from "components/ui/form/checkbox";
import Button from "components/ui/button";
import Datepicker from "components/ui/form/datepicker";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getRoadParts} from "store/reducers/kurs/road_parts";
import {getStopPoints} from "store/reducers/geo/stop-points";
import classNames from 'classnames';
import debounce from 'throttle-debounce/debounce';

//
import Page from 'components/ui/page';
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import ReactResizeDetector from 'react-resize-detector';
import BaseEditorFormComponent from "components/base/base-editor-form";
import Popup from "components/ui/popup";
import {getContracts} from "store/reducers/kurs/contracts";
import {getContractWorks} from "store/reducers/kurs/contract_works";
import {getLayerObjectsFull} from "store/reducers/user-map-objects/layer_list";
import GlobalLoaderComponent from "components/ui/global-loader";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import PageModalComponent from "components/ui/page-modal";
import TableContainer from "components/ui/Table/Container/TableContainer";
import * as storage from "utils/storage";
import * as alerts from "helpers/alerts";

const VIEW_MONTH = 'month';
const VIEW_DAY = 'day';

@connect(state => ({}), {
    getTasks,
    getEntityNames,
    getUnits,
    getVehicles,
    getRoadParts,
    getStopPoints,
    getDictionaryList,
    getContracts,
    getContractWorks,
    getLayerObjectsFull,
})

// TODO: крайне не оптимизировано

export default class UtilityPlanDailyComponent extends BaseEditorFormComponent {

    title = `${systems.utility} → Плановые задачи`;
    baseUrl = '/utility/plan/daily';


    perPage = 40;

    state = {
        loading: false,
        showFilters: false,
        activeContract: {},
        work_types: [],
        utility_task_statuses: [],
        filters: {
            work_type: null,
            vehicle_type: null,
            kurs_task_status: null,
        },
        page: 1,
        months: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентярбь',
            'Октябрь',
            'Ноябрь',
            'Декабрь'
        ],
        faqOpened: false,
        // from: moment().startOf('year'),
        // to: moment().endOf('year'),
        from: moment().startOf('day'),
        to: moment().add(1, 'day').startOf('day'),
        opened: [],
        tasks: [],
        modalTasks: null,
        kurs_contract_statuses: [],
        show_road_parts_with_tasks: false,
        show_work_types_with_tasks: false,
        selected_road_parts: [],
        selected_work_types: [],
        choisedContractWorksForCreating: [],
        choisedWorkGroupsForCreating: [],
        related: new EntityList,
        loadIndex: 0,
    };
    contractsDebounce = debounce(500, ::this.getContractsLite);

    async componentDidMount() {
        this.loadSavedState();
        await this.loadDictionaries([
            'work_types',
            //'kurs_road_repair_parts', //fixme сделать асинхронный селект на этот запрос
            'kurs_task_statuses',
            'kurs_contract_statuses',
        ], 'utility');
        await this.loadContracts();
        await this.loadGeoObjects();
    }

    loadSavedState() {
        const planDailyState = storage.get('plan:daily:state');
        if (planDailyState) {
            this.setState({
                from: moment(planDailyState.from),
                to: moment(planDailyState.to),
                filters: planDailyState.filters,
                activeContract: planDailyState.activeContract,
            });
        }
    }

    saveState() {
        storage.set('plan:daily:state', _.cloneDeep({
            from: this.state.from,
            to: this.state.to,
            filters: this.state.filters,
            activeContract: this.state.activeContract,
        }));
    }

    async loadData() {
        this.setState({
            opened: [],
            loadIndex: this.state.loadIndex + 1,
        });
        this.saveState();
        await this.loadContractsWork();
        await this.loadRoadParts(_.map(this.state.contractWorks || [], roadPart => roadPart.road_uuid));
        await this.loadRoadRepairParts(_.map(this.state.contractWorks || [], roadPart => roadPart.road_uuid));
        this.loadTasks();
    }

    _onResize() {
        $('.b-tiles__progress-text').css('width', $('.b-tiles__wrap-progress').width());
    }

    async loadGeoObjects() {
        const response = await this.props.getLayerObjectsFull({
            pagination: {
                page: 1,
                limit: 3000,
            }
        })
        if (response.isOk) {
            this.setState({
                geoObjects: _.keyBy(_.map(response.payload.items, (item) => {

                    return {
                        uuid: item.uuid,
                        title: item.title,
                        geometry_type: 'object',
                        layer_uuid: item.layer_uuid,
                    }
                }), 'uuid')
            });
        } else {
            response.showErrors();
        }
    }

    async loadTasks() {
        const limit = 1000;
        const response = await this.props.getTasks({
            pagination: {
                page: 1,
                limit,
            },
            filters: {
                onlyUuid: true,
                withContract: this.state.activeContract.value,
            },
        });

        if (response.isOk) {
            let data = response.payload.items;

            const pages = response.data.headers.meta.pagination.total_pages;
            await Promise.all(_.map(_.range(2, pages + 1), async (page) => {
                const response = await this.props.getTasks({
                    pagination: {
                        page,
                        limit,
                    },
                    filters: {
                        onlyUuid: true,
                        withContract: this.state.activeContract.value,
                    },
                });

                if (response.isOk) {
                    data = _.concat(data, response.payload.items);
                }
            }));
            this.setState({
                tasks: data,
            });
        }
    }

    async loadContractsWork() {
        const response = await this.props.getContractWorks({
            filters: {
                withContract: [this.state.activeContract.value],
            },
        });

        if (response.isOk) {
            this.setState({
                contractWorks: response.payload.items
            })
        } else {
            response.showErrors();
        }
    }

    async loadContracts() {
        this.setState({loading: true});
        const response = await this.props.getContracts({
            search: this.state.activeContract.label,
            pagination: {
                page: 1,
                limit: 30,
            },
            filters: {
                withStatus: _.get(_.find(this.state.kurs_contract_statuses, {label: 'Открыт'}), 'value'),
                /*withPeriod: [
                    moment(this.state.from).format(formats.DATE_API),
                    moment(this.state.to).format(formats.DATE_API),
                ],*/
            },
        });

        if (response.isOk) {
            await this.setState({
                contracts: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.number,
                    data: item,
                })),
                selectedContracts: _.mapValues(_.keyBy(response.payload.items, 'uuid'), item => true),
                activeContract: _.isEmpty(this.state.activeContract) ? {
                    value: _.get(_.first(response.payload.items), 'uuid'),
                    label: _.get(_.first(response.payload.items), 'number'),
                } : this.state.activeContract,
                selected_road_parts: [],
            });
        } else {
            response.showErrors();
        }

        await this.loadData();
        this.setState({loading: false});
    }

    async loadRoadParts(roads) {
        const response = await this.props.getRoadParts({
            filters: {
                withUuid: this.state.contractWorks.map(roadPart => roadPart.road_uuid) || [],
            },
        });

        if (response.isOk) {
            this.setState({
                road_parts: _.mapValues(_.keyBy(response.payload.items, 'uuid'), 'name'),
            });
        } else {
            response.showErrors();
        }
    }

    async loadRoadRepairParts(roads) {
        let meta = {
            pagination: {
                page: 1,
                limit: 20,
            },
            filters: {
                uuid: roads,
            },
        };
        const response = await this.props.getDictionaryList('kurs_road_repair_parts', meta, false);

        if (response.isOk) {
            this.setState({
                kurs_road_repair_parts: _.mapValues(_.keyBy(response.payload.documents, 'uuid'), 'name'),
            });
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.road, path, defaultValue);
    }

    render() {
        const loader = this.state.loading ? (<GlobalLoaderComponent/>) : null;

        const viewType = (Math.abs(moment(this.state.from).diff(moment(this.state.to), 'days')) <= 60) ? VIEW_DAY : VIEW_MONTH;
        const additionalClass = viewType === VIEW_DAY ? 'planning-works2-1' : '';

        return (
            <Page pageId="Main"
                  title="Плановые задачи → Задания (гант)"
                  headerActions={this.renderHeaderActions()}
                  className={`r-block_noIndent planning-works ${additionalClass}`}>
                {loader}
                {this.renderContent(viewType)}
                {this.renderModal()}
                <ReactResizeDetector handleWidth handleHeight onResize={::this._onResize}/>
            </Page>
        );
    }

    openWorkType(idx) {
        let opened = this.state.opened;
        opened.push(idx);
        this.setState({opened});
    }

    closeWorkType(idx) {
        let opened = this.state.opened;
        opened = _.filter(opened, item => item !== idx);
        this.setState({opened});
    }

    getAllContractWorks() {
        return _.filter(this.state.contractWorks || [], (contractWork) => {
            if (this.state.show_road_parts_with_tasks) {
                return !!this.state.work_types.find((wt) => {
                    return !!this.getTasks(contractWork.road_uuid, wt.value);
                })
            }
            if (this.state.selected_road_parts.length > 0) {
                if (_.indexOf(this.state.selected_road_parts, contractWork.road_uuid) === -1) {
                    return false;
                }
            }
            return true;
        });
    }

    getFilteredContractWorks() {
        return _.slice(this.getAllContractWorks(), (this.state.page - 1) * this.perPage, this.state.page * this.perPage);
    }

    getWorkTypes(contractWorks, contract = {works: []}) {
        let workTypes = this.state.work_types.filter((workType) => contract.works.find((work) => work.work_type_uuid === workType.value))

        if (this.state.show_work_types_with_tasks) {
            workTypes = this.state.work_types.filter((wt) => {
                return !!contractWorks.find((contractWork) => {
                    return this.getTasks(contractWork.road_uuid, wt.value).length > 0;
                })
            });
        }


        if (this.state.selected_work_types.length > 0) {
            return workTypes.filter((wt) => this.state.selected_work_types.indexOf(wt.value) >= 0)
        }


        return workTypes;
    }

    renderMonthView() {
        let months = [];
        let date = moment(this.state.from);
        while (date.isSameOrBefore(moment(this.state.to))) {
            const month = date.format('M') - 1;
            months.push({
                label: this.state.months[month],
                value: date.clone(),
            });
            date.add(1, 'month');
        }

        const contract = _.get(_.find(this.state.contracts, {value: this.state.activeContract.value}), 'data');

        const contractWorks = this.getFilteredContractWorks();

        const workTypes = this.getWorkTypes(contractWorks, contract);

        const geoObjects = this.state.geoObjects;

        return (
            <div className="planning-works__content">
                <div className="col col-4">
                    <Accordion>
                        <div className="accordion__header">Виды работ / Объекты</div>

                        {workTypes.map((workType, idx) => {
                            return (
                                <AccordionItem key={`${idx}:${this.state.loadIndex}`}
                                               title={this.getWorkTypeName(workType) || '-'}
                                               withoutWrapper={true}
                                               onOpened={this.openWorkType.bind(this, idx)}
                                               onClosed={this.closeWorkType.bind(this, idx)}>
                                    {this.renderContractWorkHeader(contract, contractWorks, workType, idx, geoObjects)}
                                </AccordionItem>
                            );
                        })}
                    </Accordion>
                </div>
                <div className="col col-8">
                    <div className="p-works__scroll">
                        <div className="p-works__right">
                            <div className="p-works__wrap">
                                <div className="p-works__row">
                                    {_.map(months, (value, idx) => {
                                        return (
                                            <div className="p-works__month" key={idx}>
                                                <div className="p-works__month-name">{value.label}</div>
                                                <div className="p-works__month-weeks">
                                                    {_.map(this.getWeeks(value.value), (week, index) => {
                                                        return (
                                                            <div key={index} className="p-works__month-week"/>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {workTypes.map((workType, idx) => {
                                    const tasks = this.getTasksForWorkType(workType, contractWorks);

                                    const mainRow = (
                                        <div key={`main:${idx}`} className="p-works__row">
                                            {_.map(months, (value, idx) => {

                                                const monthTasks = _.filter(tasks, (task) => {
                                                    return moment(task.date).isSameOrAfter(value.value) && moment(task.date).isBefore(value.value.clone().add(1, 'month'));
                                                });
                                                return (
                                                    <div key={idx} className="p-works__month">
                                                        <div className="p-works__month-weeks">
                                                            {_.map(this.getWeeks(value.value), (week, index) => {
                                                                const part = _.filter(monthTasks, (task) => {
                                                                    return moment(task.date).isSameOrAfter(week.from, 'day') && moment(task.date).isSameOrBefore(week.to, 'day');
                                                                });

                                                                let statusColor = this.getPriorityStatusColor(part);

                                                                if (!statusColor) {
                                                                    if (contract) {
                                                                        if (moment(contract.date_from).isSameOrBefore(week.from, 'day') && moment(contract.date_to).isSameOrAfter(week.to, 'day')) {
                                                                            statusColor = '#b2b7fa';
                                                                        }
                                                                    }
                                                                }

                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className="p-works__month-cell"
                                                                        style={{
                                                                            backgroundColor: statusColor,
                                                                        }}
                                                                    />
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                    return _.concat(mainRow, (_.filter(this.state.opened, item => item === idx).length > 0) ? this.renderContractWork(months, contract, contractWorks, workType, idx, 'month') : []);
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    getTotalWeeks(from, to) {
        if (moment(to).isBefore(from)) {
            return [];
        }

        let weeks = [];
        let date = moment(from);
        while (date.isBefore(moment(to))) {
            weeks = _.concat(weeks, this.getWeeks(date.clone(), moment(to)));
            date.add(1, 'month');
        }
        return weeks;
    }

    getWeeks(date, dateEnd = null) {
        let result = [];
        let start = date.clone().startOf('month');
        //start = start.isSameOrAfter(this.state.from) ? start : this.state.from;
        let end = date.clone().endOf('month');
        if (dateEnd && dateEnd.isBefore(end)) {
            end = dateEnd;
        }
        end = end.isSameOrBefore(this.state.to) ? end : this.state.to;
        let d = start.clone();
        let currentWeek = null;
        let startD = null;
        while (d.isSameOrBefore(end)) {
            if (currentWeek === null) {
                currentWeek = d.isoWeek();
                startD = d.clone();
            }
            if (d.isoWeek() !== currentWeek) {
                result.push({
                    from: startD,
                    to: d.clone().subtract(1, 'day'),
                });
                startD = d.clone();
                currentWeek = d.isoWeek();
            }
            d.add(1, 'day');
        }
        if (!d.isSame(startD, 'day')) {
            result.push({
                from: startD,
                to: d.clone().subtract(1, 'day'),
            });
        }
        return result;
    }

    getPriorityStatusColor(tasks) {
        const draftStatus = _.get(_.find(this.state.kurs_task_statuses, {label: 'Черновик'}), 'value');
        if (tasks.find((task) => task.status_uuid === draftStatus)) {
            return '#d7dbd7';
        }

        const notCompletedStatus = _.get(_.find(this.state.kurs_task_statuses, {label: 'Открыт'}), 'value');
        if (tasks.find((task) => task.status_uuid === notCompletedStatus)) {
            return '#fa9895';
        }

        const notAllCompletedStatus = _.get(_.find(this.state.kurs_task_statuses, {label: 'В работе'}), 'value');
        const onReviewStatus = _.get(_.find(this.state.kurs_task_statuses, {label: 'На рассмотрении'}), 'value');

        if (tasks.find((task) => task.status_uuid === notAllCompletedStatus || task.status_uuid === onReviewStatus)) {
            return '#f5ffa5';
        }

        const completedStatus = _.get(_.find(this.state.kurs_task_statuses, {label: 'Закрыт'}), 'value');
        if (tasks.find((task) => task.status_uuid === completedStatus)) {
            return '#63daab';
        }
    }

    getWorkTypeName(workType) {
        let workTypeNameShort = workType.label.length > 90 ? `${workType.label.substring(0, 70)}...` : workType.label;

        return workTypeNameShort;
    }

    getContractWorksForWorkType(contractWorks, workType) {
        if (this.state.show_road_parts_with_tasks) {
            return contractWorks.filter((contractWork) => {
                return this.getTasks(contractWork.road_uuid, workType.value).length !== 0
            })
        }

        return contractWorks
    }

    getWorkGroups(contractWorks) {
        const workGroups = {}
        contractWorks.map((contractWork) => {
            contractWork.work_groups.map((workGroup) => {
                workGroups[workGroup.uuid] = {
                    uuid: workGroup.uuid,
                    name: workGroup.name,
                    roadsUuids: ((workGroups[workGroup.uuid] || {}).roadsUuids || []).concat(contractWork.road_uuid)
                }
            })
        })

        return Object.values(workGroups);
    }

    renderContractWorkHeader(contract, contractWorks, workType, idx, geoObjects) {
        const contractWorksForWorkType = this.getContractWorksForWorkType(contractWorks, workType);

        const workGroups = this.getWorkGroups(contractWorksForWorkType)

        return [
            contractWorksForWorkType.map((contractWork, contractWorkIndex) => {
                const title = _.get(this.state.road_parts, contractWork.road_uuid)
                    || _.get(this.state.kurs_road_repair_parts, contractWork.road_uuid)
                    || _.get(geoObjects, `${contractWork.road_uuid}.title`);

                const geoObject = geoObjects && geoObjects[contractWork.road_uuid] || null

                if (geoObject) {
                    //  delete geoObject.title
                }

                return (
                    <div key={`${idx}:${contractWorkIndex}`} className="accordion__content-row">
                        <span title={title} className="accordion__content-row_with-checkbox">
                            {title}
                            <Checkbox
                                checked={!!this.state.choisedContractWorksForCreating.find((choisedContractWork) =>
                                    choisedContractWork.contractWorkUuid === contractWork.uuid &&
                                    choisedContractWork.workTypeUuid === workType.value
                                )}
                                onChange={() => ::this.toggleContractWorks(contractWork.uuid, workType.value, geoObject)}
                            />
                        </span>
                    </div>
                )
            }),
            workGroups.map((workGroup) => (
                <div key={`${idx}:${workGroup.uuid}`} className="accordion__content-row">
                    <span title={workGroup.name} className="accordion__content-row_with-checkbox">
                        {workGroup.name}
                        <Checkbox
                            checked={!!this.state.choisedWorkGroupsForCreating.find((choisedWorkGroup) =>
                                choisedWorkGroup.uuid === workGroup.uuid &&
                                choisedWorkGroup.workTypeUuid === workType.value
                            )}
                            onChange={() => ::this.toggleWorkGroup(workGroup.uuid, workType.value, workGroup.roadsUuids)}
                        />
                    </span>
                </div>
            ))
        ];
    }

    getTasks(roadUuid, workTypeUuid) {
        return _.filter(this.state.tasks, (task) => {
            return _.find(task.item_roads, (itemRoad) => {
                if (_.isArray(workTypeUuid)) {
                    return (itemRoad[0] === roadUuid) && (_.indexOf(workTypeUuid, itemRoad[1]) !== -1);
                } else {
                    return (itemRoad[0] === roadUuid) && (itemRoad[1] === workTypeUuid);
                }
            });
        });
    }

    getTasksForWorkType(workType, contractWorks) {
        const contractWorksForWorkType = this.getContractWorksForWorkType(contractWorks, workType);

        let tasks = []
        contractWorksForWorkType.forEach((cw) => {
            const tasksForContractWork = this.getTasks(cw.road_uuid, workType.value)

            if (tasksForContractWork.length) {
                tasks = tasks.concat(tasksForContractWork)
            }
        })

        return tasks
    }

    renderColoredValues(idx, contractWorkIndex, months, tasks, viewMode, contract, roadsUuids, workTypeUuid) {
        return (
            <div key={`${idx}:${contractWorkIndex}`} className="p-works__row">
                {_.map(months, (value, _idx) => {
                    const monthTasks = _.filter(
                        tasks,
                        (task) => (
                            moment(task.date).isSameOrAfter(value.value)
                            && moment(task.date).isBefore(value.value.clone().add(1, 'month')))
                    )

                    if (viewMode === 'month') {
                        return (
                            <div key={`${idx}:${contractWorkIndex}:${_idx}`} className="p-works__month">
                                <div className="p-works__month-weeks">
                                    {_.map(this.getWeeks(value.value), (week, index) => {
                                        const part = _.filter(
                                            monthTasks,
                                            (task) => (
                                                moment(task.date).isSameOrAfter(week.from, 'day')
                                                && moment(task.date).isSameOrBefore(week.to, 'day')
                                            )
                                        );

                                        let statusColor = this.getPriorityStatusColor(part);

                                        if (!statusColor) {
                                            if (contract) {
                                                if (moment(contract.date_from).isSameOrBefore(week.from, 'day')
                                                    && moment(contract.date_to).isSameOrAfter(week.to, 'day')) {
                                                    statusColor = '#b2b7fa';
                                                }
                                            }
                                        }

                                        return (
                                            <div
                                                key={index}
                                                className="p-works__month-cell"
                                                style={{
                                                    backgroundColor: statusColor,
                                                }}
                                            >
                                                {(part.length > 0) ? (
                                                    <span
                                                        onClick={this.showTasks.bind(this, part)}
                                                        className="planning-works__cell-inner planning-works__cell-inner_show"

                                                    />
                                                ) : (
                                                    <span
                                                        className="planning-works__cell-inner planning-works__cell-inner_create"
                                                        onClick={this.createTask.bind(this, week.from, roadsUuids, workTypeUuid)}
                                                    >+</span>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div key={`${idx}:${contractWorkIndex}:${_idx}`}
                                 className="p-works__month p-works__month_row">
                                {_.map(this.getWeeks(value.value), (week, index) => {
                                    return (
                                        <div key={index} className="p-works__week">
                                            {_.map(_.range(week.to.date() - week.from.date() + 1), (i) => {
                                                const part = _.filter(
                                                    monthTasks,
                                                    (task) => (
                                                        moment(task.date).isSame(week.from.clone().add(i, 'days'), 'day')
                                                    )
                                                );

                                                let statusColor = this.getPriorityStatusColor(part);

                                                if (!statusColor) {
                                                    if (contract) {
                                                        if (moment(contract.date_from).isSameOrBefore(week.from.clone().add(i, 'days'), 'day')
                                                            && moment(contract.date_to).isSameOrAfter(week.to, 'day')) {
                                                            statusColor = '#b2b7fa';
                                                        }
                                                    }
                                                }

                                                return (
                                                    <div
                                                        key={i}
                                                        className="p-works__cell"
                                                        style={{
                                                            backgroundColor: statusColor,
                                                        }}
                                                    >
                                                        {(part.length > 0) ? (
                                                            <span
                                                                onClick={this.showTasks.bind(this, part)}
                                                                className="planning-works__cell-inner planning-works__cell-inner_show">
                                                                {part.length}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className="planning-works__cell-inner planning-works__cell-inner_create"
                                                                onClick={this.createTask.bind(
                                                                    this,
                                                                    week.from.clone().add(i, 'days'),
                                                                    roadsUuids,
                                                                    workTypeUuid
                                                                )}>
                                                                +
                                                            </span>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    }
                })}
            </div>
        );
    }

    renderContractWork(months, contract, contractWorks, workType, idx, viewMode) {

        const contractWorksForWorkType = this.getContractWorksForWorkType(contractWorks, workType);

        const workGroups = this.getWorkGroups(contractWorksForWorkType)

        return [
            contractWorksForWorkType.map((contractWork, contractWorkIndex) => {
                const tasks = this.getTasks(contractWork.road_uuid, workType.value);

                return this.renderColoredValues(
                    idx,
                    contractWorkIndex,
                    months,
                    tasks,
                    viewMode,
                    contract,
                    [contractWork.road_uuid],
                    workType.value
                );
            }),
            workGroups.map((workGroup, workGroupIndex) => {
                let tasks = [];
                workGroup.roadsUuids.forEach((roadUuid) => {
                    tasks = tasks.concat(this.getTasks(roadUuid, workType.value))
                });
                return this.renderColoredValues(
                    idx,
                    workGroupIndex,
                    months,
                    tasks,
                    viewMode,
                    contract,
                    workGroup.roadsUuids,
                    workType.value
                );
            })
        ];
    }

    showTasks(tasks) {
        this.setState({
            modalTasks: tasks,
        });
        this.preloadUnits(_.filter(_.uniq(_.map(tasks, 'unit_uuid'))));
    }

    toggleWorkGroup(workGroupUuid, workTypeUuid, roadsUuids) {
        const indexOfWorkGroup = this.state.choisedWorkGroupsForCreating.findIndex((choisedWorkGroup) =>
            choisedWorkGroup.uuid === workGroupUuid &&
            choisedWorkGroup.workTypeUuid === workTypeUuid
        )

        const newChoisedWorkGroup = [].concat(this.state.choisedWorkGroupsForCreating);
        if (indexOfWorkGroup >= 0) {
            newChoisedWorkGroup.splice(indexOfWorkGroup, 1);
        } else {
            newChoisedWorkGroup.push({
                uuid: workGroupUuid,
                workTypeUuid,
                roadsUuids,
            })
        }

        this.setState({
            choisedWorkGroupsForCreating: newChoisedWorkGroup,
        })
    }

    toggleContractWorks(contractWorkUuid, workTypeUuid, geoObject) {
        const indexOfContractWork = this.state.choisedContractWorksForCreating.findIndex((choisedContractWork) =>
            choisedContractWork.contractWorkUuid === contractWorkUuid &&
            choisedContractWork.workTypeUuid === workTypeUuid
        )

        const newChoisedContractWorks = [].concat(this.state.choisedContractWorksForCreating);
        if (indexOfContractWork >= 0) {
            newChoisedContractWorks.splice(indexOfContractWork, 1);
        } else {

            let obj = {
                contractWorkUuid,
                workTypeUuid
            }
            if (geoObject) {
                obj = {
                    ...obj,
                    geoObject: geoObject,
                }
            }
            newChoisedContractWorks.push({
                ...obj
            })
        }
        this.setState({
            choisedContractWorksForCreating: newChoisedContractWorks,
        })
    }

    closeTasks() {
        this.setState({
            modalTasks: null,
        });
    }

    async preloadUnits(uuids) {
        const response = await this.props.getEntityNames(_.map(uuids, (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        })));

        if (response.isOk) {
            this.state.related.add(response);
            this.forceUpdate();
        }
    }

    renderDayView() {
        let months = [];
        let date = moment(this.state.from);
        while (date.isSameOrBefore(moment(this.state.to))) {
            const month = date.format('M') - 1;
            months.push({
                label: this.state.months[month],
                value: date.clone(),
            });
            date.add(1, 'month');
        }

        const contract = _.get(_.find(this.state.contracts, {value: this.state.activeContract.value}), 'data');

        const contractWorks = this.getFilteredContractWorks();

        const workTypes = this.getWorkTypes(contractWorks, contract);

        const geoObjects = this.state.geoObjects;

        return (
            <div className="planning-works__content">
                <div className="col col-4">
                    <Accordion>
                        <div className="accordion__header">Виды работ / Объекты</div>

                        {workTypes.map((workType, idx) => {
                            return (
                                <AccordionItem key={`${idx}:${this.state.loadIndex}`}
                                               title={this.getWorkTypeName(workType) || '-'}
                                               withoutWrapper={true}
                                               onOpened={this.openWorkType.bind(this, idx)}
                                               onClosed={this.closeWorkType.bind(this, idx)}>
                                    {this.renderContractWorkHeader(contract, contractWorks, workType, idx, geoObjects)}
                                </AccordionItem>
                            );
                        })}
                    </Accordion>
                </div>
                <div className="col col-8">
                    <div className="p-works__scroll">
                        <div className="p-works__right">
                            <div className="p-works__wrap">
                                <div className="p-works__row">
                                    {_.map(months, (value, idx) => {
                                        return (
                                            <div className="p-works__month" key={idx}>
                                                <div className="p-works__month-name">{value.label}</div>
                                                <div className="p-works__month-days">
                                                    {_.map(this.getWeeks(value.value), (week, index) => {
                                                        return (
                                                            <div key={index} className="p-works__week">
                                                                {_.map(_.range(week.to.date() - week.from.date() + 1), (i) => {
                                                                    return (
                                                                        <div key={i}
                                                                             className="p-works__day">{week.from.clone().add(i, 'days').date()}</div>
                                                                    );
                                                                })}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {workTypes.map((workType, idx) => {
                                    const tasks = this.getTasksForWorkType(workType, contractWorks);

                                    const mainRow = (
                                        <div key={`main:${idx}`} className="p-works__row">
                                            {_.map(months, (value, idx) => {
                                                const monthTasks = _.filter(tasks, (task) => {
                                                    return moment(task.date).isSameOrAfter(value.value) && moment(task.date).isBefore(value.value.clone().add(1, 'month'));
                                                });

                                                return (
                                                    <div key={idx} className="p-works__month p-works__month_row">
                                                        {_.map(this.getWeeks(value.value), (week, index) => {
                                                            return (
                                                                <div key={index} className="p-works__week">
                                                                    {_.map(_.range(week.to.date() - week.from.date() + 1), (i) => {
                                                                        const part = _.filter(monthTasks, (task) => {
                                                                            return moment(task.date).isSame(week.from.clone().add(i, 'days'), 'day');
                                                                        });

                                                                        let statusColor = this.getPriorityStatusColor(part);

                                                                        if (!statusColor) {
                                                                            if (contract) {
                                                                                if (moment(contract.date_from).isSameOrBefore(week.from, 'day') && moment(contract.date_to).isSameOrAfter(week.to, 'day')) {
                                                                                    statusColor = '#b2b7fa';
                                                                                }
                                                                            }
                                                                        }

                                                                        return (
                                                                            <div
                                                                                key={i}
                                                                                className="p-works__cell"
                                                                                style={{
                                                                                    backgroundColor: statusColor,
                                                                                }}
                                                                            ></div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                    return _.concat(mainRow, (_.filter(this.state.opened, item => item === idx).length > 0) ? this.renderContractWork(months, contract, contractWorks, workType, idx, 'day') : []);
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    setCurrentPage(page) {
        this.setState({
            page: page,
        });
    }

    renderPages() {
        const currentPage = this.state.page;
        const pageCount = _.ceil(this.getAllContractWorks().length / this.perPage);
        const pages = _.filter(_.range(1, pageCount + 1), (page) => {
            return Math.abs(page - currentPage) <= 3;
        });
        return (
            <div className="dataTables_paginate paging_simple_numbers">
                <span>
                    {_.map(pages, (page) => {
                        return (
                            <a key={page} href="javascript:void(0)" onClick={this.setCurrentPage.bind(this, page)}
                               className={classNames('paginate_button', (page === this.state.page) ? 'current' : '')}>{page}</a>
                        );
                    })}
                </span>
            </div>
        );
    }

    renderContent(viewType) {

        return (
            <div className="page-block">

                {viewType === VIEW_MONTH
                    ? this.renderMonthView()
                    : this.renderDayView()
                }

                {this.renderPages()}

                <div className={classNames('faq', {
                    'expand': this.state.faqOpened,
                })}>
                    <div className={classNames('faq__btn', {
                        'faq__btn_opened': this.state.faqOpened,
                    })} onClick={::this.toggleFaq}></div>
                    <div className="faq__inner big">
                        <div className="faq__title">Расшифровка обозначений на графике</div>
                        <div className="faq__content">
                            <div className="faq__row">
                                подрядное обязательство
                                <div className="indicators indicators_violet"/>
                            </div>
                            <div className="faq__row">
                                задание в статусе "Черновик"
                                <div className="indicators indicators_gray2"/>
                            </div>
                            <div className="faq__row">
                                задание выполнено не полностью
                                <div className="indicators indicators_yellow"/>
                            </div>
                            <div className="faq__row">
                                задание выполнено
                                <div className="indicators indicators_green2"/>
                            </div>
                            <div className="faq__row">
                                задание не выполнено
                                <div className="indicators indicators_red2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    toggleFaq() {
        this.setState({
            faqOpened: !this.state.faqOpened,
        });
    }

    async getContractsLite(input, callback) {
        if (!input) {
            input = this.get('activeContract') || _.get(this.props.data, 'activeContract');
        }

        let response = await this.props.getContracts({
            search: input,
            filters: {
                withStatus: _.get(_.find(this.state.kurs_contract_statuses, {label: 'Открыт'}), 'value'),
                //withImplementer: [this.get('unit_uuid')],
            },
            pagination: {
                page: 1,
                limit: 25,
            }
        });

        if (response.isOk) {
            /*const uuid = this.get('unit_uuid');
            let a = result.payload.items.filter((contract) => {
                if (contract.implementer_uuid === uuid || _.indexOf(contract.subcontractors, uuid) !== -1) {
                    return contract;
                }
            });
            callback(null, {
                options: _.sortBy(a.map(i => ({label: i.name, value: i.uuid})), 'label'),
                complete: false,
            });*/
            await this.setState({
                contracts: _.map(response.payload.items, (item) => ({
                    value: item.uuid,
                    label: item.number || item.name,
                    data: item,
                })),
                selectedContracts: _.mapValues(_.keyBy(response.payload.items, 'uuid'), item => true),
                activeContract: _.isEmpty(this.state.activeContract) ? {
                    value: _.get(_.first(response.payload.items), 'uuid'),
                    label: _.get(_.first(response.payload.items), 'number'),
                } : this.state.activeContract,
                selected_road_parts: [],
            });
            callback(null, {
                options: _.sortBy(response.payload.items.map(i => ({
                    label: i.number,
                    value: i.uuid,
                    data: i
                })), 'label'),
                complete: false,
            });
        } else {
            result.showErrors();
        }
    }

    onMultiSelectAsyncChange(fieldName, e) {
        this.onChangeInput(fieldName, {target: {value: e}});
    }

    renderFiltersPopup() {
        return (
            <Popup
                className="SelectFieldsPopup SelectFieldsPopup-planning top-link checkbox-dropdown"
                show={true}>
                <div className="popup-container__content">
                    <div className="popup-container__content_inner">
                        <div className="popup-container__title">Фильтр работ</div>
                        <div className="popup-container__row">
                            <div
                                className="popup-container__row-title">Выбор {window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}</div>
                            <div>
                                {/*{this.select('activeContract', _.sortBy(_.map(this.state.contracts, ({label, value}) => ({
                                    label,
                                    value,
                                })), 'label'), {
                                    clearable: false,
                                    onChange: ::this.changeContract
                                })}*/}
                                {this.selectAsync(`activeContract`, ::this.contractsDebounce, {
                                    // multi: false,
                                    // onChange: this.onMultiSelectAsyncChange.bind(this, `task.contracts`),
                                    clearable: false,
                                    onChange: ::this.changeContract
                                })}
                            </div>
                            <div>
                                {this.checkbox('show_work_types_with_tasks', 'Отображать виды работ с заданиями')}
                            </div>
                            <div className="popup-container__row-title">Виды работ</div>
                            <div>
                                {this.select('selected_work_types', _.sortBy(this.state.work_types, 'label'), {
                                    multi: true,
                                })}
                            </div>
                        </div>
                        <hr/>
                        <div className="popup-container__title">Фильтр участков</div>
                        <div className="popup-container__row">
                            <div>
                                {this.checkbox('show_road_parts_with_tasks', 'Отображать участки с заданиями')}
                            </div>
                        </div>
                        <div className="popup-container__row">
                            <div className="popup-container__row-title">Участки</div>
                            <div>
                                {this.select('selected_road_parts', _.sortBy(_.map(this.state.contractWorks, (contractWork) => ({
                                    label: _.get(this.state.road_parts, contractWork.road_uuid) || _.get(_.find(this.state.kurs_road_repair_parts, {value: contractWork.road_uuid}), 'label'),
                                    value: contractWork.road_uuid,
                                })), 'label'), {
                                    multi: true,
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }

    renderHeaderActions() {
        return [
            <Button
                text="Создать задание"
                color="red"
                size="md"
                disabled={!this.state.choisedContractWorksForCreating.length && !this.state.choisedWorkGroupsForCreating.length}
                onClick={::this.createTaskWithChoisedContractWorks}
            />,
            <div key="diapason">
                <div className="top-menu__label">Период:</div>
                <Datepicker style="dark" value={this.state.from} onChange={::this.fromChange}/>
                <Datepicker style="dark" value={this.state.to} onChange={::this.toChange}/>
            </div>,
            <div key="filter">
                <ContextTooltip key="base-table-list.filter" code="base-table-list.filter" default="Фильтр">
                    <IconButton icon="filter" tooltip="Фильтр" active={this.state.showFilters}
                                onClick={::this.toggleFilters}/>
                </ContextTooltip>
                {this.state.showFilters ? this.renderFiltersPopup() : null},
            </div>,
        ];
    }

    onFilterChange(type, e) {
        const value = e ? e.value : null;

        let filters = this.state.filters;
        filters[type] = value;
        this.setState({
            filters,
        });
    }

    fromChange = async ({target: {value}}) => {
        let from = moment(value).isAfter(moment(this.state.to)) ? this.state.to : value;
        await this.setState({from: from});
        this.saveState();
        //this.loadContracts();
    };

    toChange = async ({target: {value}}) => {
        let to = moment(value).isBefore(moment(this.state.from)) ? this.state.from : value;
        await this.setState({to: to});
        this.saveState();
        //this.loadContracts();
    };

    toggleFilters() {
        this.setState({
            showFilters: !this.state.showFilters,
        });
    }

    async changeContract(e) {
        await this.setState({
            activeContract: e
        });
        this.loadData();
    }

    showEditor() {

    }

    print() {

    }

    renderModal() {
        if (!this.state.modalTasks) {
            return null;
        }

        const buttons = (
            <ModalTopMenuButtons>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Закрыть">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.closeTasks}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        const title = 'Список заданий';

        return (
            <PageModalComponent
                header={{title, buttons}}
                onClose={::this.closeTasks}
                className="b-modal-planning-task-list"
            >
                <div className="Table">
                    <TableContainer>
                        <table className="b-table">
                            <thead>
                            <tr>
                                <th>Номер</th>
                                <th>Исполнитель</th>
                                <th>Статус задания</th>
                            </tr>
                            </thead>
                            <tbody>
                            {_.map(this.state.modalTasks, ::this.renderModalTask)}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </PageModalComponent>
        )
    }

    renderModalTask(task) {
        return (
            <tr key={task.uuid}>
                <td className="link" onClick={this.openTask.bind(this, task)}>{task.number}</td>
                <td>{this.state.related.getReact(task.unit_uuid)}</td>
                <td>{_.get(_.find(this.state.kurs_task_statuses, {value: task.status_uuid}), 'label')}</td>
            </tr>
        );
    }

    openTask(task) {
        this.props.router.push(`/utility/tasks/${task.uuid}`);
    }

    createTaskWithChoisedContractWorks() {
        const contractWorksWithWorkType = this.state.choisedContractWorksForCreating;
        const choisedWorkGroup = this.state.choisedWorkGroupsForCreating;

        // NOTE: проверка, что выбрали только из одного вида работ. UI такой UI
        const work_types = [];
        for (let i = 0; i < contractWorksWithWorkType.length; i++) {
            if (!work_types.includes(contractWorksWithWorkType[i].workTypeUuid)) {
                work_types.push(contractWorksWithWorkType[i].workTypeUuid)
            }

            if (work_types.length > 1) {
                alerts.error('Выберите объекты из одно вида работ');
                return
            }
        }

        for (let i = 0; i < choisedWorkGroup.length; i++) {
            if (!work_types.includes(choisedWorkGroup[i].workTypeUuid)) {
                work_types.push(choisedWorkGroup[i].workTypeUuid)
            }

            if (work_types.length > 1) {
                alerts.error('Выберите объекты из одно вида работ');
                return
            }
        }

        const contract = _.find(this.state.contracts, {value: this.state.activeContract.value}).data;

        let roadsUuids = []
        let geoObjectsData = []

        this.state.contractWorks.forEach((contractWork) => {
            if (contractWorksWithWorkType.find((cwwt) => {
                if (cwwt.contractWorkUuid === contractWork.uuid && cwwt.geoObject) {
                    geoObjectsData.push(cwwt)
                }
                return cwwt.contractWorkUuid === contractWork.uuid
            })) {
                roadsUuids.push(contractWork.road_uuid)
            }
        })

        choisedWorkGroup.forEach((workGroup) => {
            roadsUuids = roadsUuids.concat(workGroup.roadsUuids);
            geoObjectsData = geoObjectsData.concat(workGroup.geoObjectsData)
        })

        const roadsUuidsString = JSON.stringify(roadsUuids)
        const geoObjectsDataString = JSON.stringify(geoObjectsData)

        this.props.router.push(
            `/utility/tasks/create?contractUuid=${contract.uuid}&workTypeUuid=${work_types[0]}&roadsUuids=${roadsUuidsString}&fromPlanDaily=true&geoObjectsData=${geoObjectsDataString}`
        );
    }

    createTask(date, roadsUuids, workTypeUuid) {
        const contract = _.find(this.state.contracts, {value: this.state.activeContract.value}).data;

        if (roadsUuids.length === 1) {
            this.props.router.push(
                `/utility/tasks/create?date=${date.format(formats.DATE_URL)}&roadUuid=${roadsUuids[0]}&workTypeUuid=${workTypeUuid}&contractUuid=${contract.uuid}&unitUuid=${contract.implementer_uuid}`
            );
        } else {
            const roadsUuidsString = JSON.stringify(roadsUuids)
            this.props.router.push(
                `/utility/tasks/create?date=${date.format(formats.DATE_URL)}&roadsUuids=${roadsUuidsString}&workTypeUuid=${workTypeUuid}&contractUuid=${contract.uuid}&unitUuid=${contract.implementer_uuid}`
            );
        }
    }
}
