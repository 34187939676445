import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';

import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import {deleteContract, getContracts} from "store/reducers/kurs/contracts";
import systems from "dictionaries/systems";
import moment from "moment";
import formats from "dictionaries/formats";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import _ from 'lodash';
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getUnits} from "store/reducers/organizational_units/units";

@connect(state => ({}), {getContracts, deleteContract, getEntityNames, getDictionaryList, getUnits})

export default class UtilityContractsComponent extends BaseTableWithEditorComponent {

    title = `${systems.utility} → Ведение ${window.RNIS_SETTINGS.rename_contracts ? 'подрядных обязательств' : 'контрактов'}`;
    baseUrl = '/utility/contracts';

    getEditor() {
        return (
            <Editor
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Регистрационный номер')
                .fromField('number'),
            ...(!window.RNIS_SETTINGS.hide_skpdi ? [
                new Column('СКПДИ')
                    .fromField('external_id'),

                new Column('root_id СКПДИ')
                    .fromField('root_id'),

                new Column('Статус СКПДИ')
                    .fromField('skpdi_status'),
            ] : []),

            new Column('Наименование')
                .fromField('name'),

            new Column('Дата заключения')
                .fromField('signed_at')
                .withDateFilter()
                .withDrawer(item => item.signed_at && moment(item.signed_at).format(formats.DATE)),

            new Column('Тип')
                .fromField('work_category_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.work_category_uuid && this.state.related.get(item.work_category_uuid))
                .withFilter('withWorkCategory', async () => {
                    const response = await this.props.getDictionaryList('work_categories');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }),

            new Column('Действует с')
                .fromField('date_from')
                .withDateFilter()
                .withDrawer(item => item.date_from && moment(item.date_from).format(formats.DATE)),

            new Column('Действует по')
                .fromField('date_to')
                .withDateFilter()
                .withDrawer(item => item.date_to && moment(item.date_to).format(formats.DATE)),

            new Column('Заказчик')
                .fromField('client'),

            new Column('Исполнитель')
                .fromField('implementer_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.implementer_uuid && this.state.related.get(item.implementer_uuid))
                .withFilter('withImplementer', async () => {
                    const response = await this.props.getUnits({
                        pagination: {
                            page: 1,
                            limit: 1000,
                        },
                        filters: {
                            withComponent: 'utility',
                        },
                    });
                    if (response.isOk) {
                        return response.payload.items;
                    }
                    return {};
                }),

            new Column('Статус')
                .fromField('status_uuid')
                .denyColumnFilter()
                .withDrawer(item => item.status_uuid && this.state.related.get(item.status_uuid))
                .withFilter('withStatus', async () => {
                    const response = await this.props.getDictionaryList('utility_contract_statuses');
                    if (response.isOk) {
                        return response.payload.documents;
                    }
                    return [];
                }),
        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteContract(data);
    }

    async loadData(meta) {
        return await this.props.getContracts(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const workCategories = _.map(_.filter(_.map(result, 'work_category_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\WorkCategories\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const statuses = _.map(_.filter(_.map(result, 'status_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Kurs\\KursContractStatuses\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const units = _.map(_.filter(_.map(result, 'implementer_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(_.concat(workCategories, statuses, units));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }
}