import {List, Map} from 'immutable';
import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

const initialState = new Map({});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getScheduleTurns = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getScheduleTurns(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getScheduleTurn = (unitUuid) => async (dispatch) => {
    try {
        const response = await api.geo.getScheduleTurn(unitUuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createScheduleTurn = (data) => async (dispatch) => {
    try {
        const response = await api.geo.createScheduleTurn(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateScheduleTurn = (data) => async (dispatch) => {
    try {
        const response = await api.geo.updateScheduleTurn(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteScheduleTurn = (data) => async (dispatch) => {
    try {
        const response = await api.geo.deleteScheduleTurn(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
