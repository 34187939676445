import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapMarker from "components/ui/map/base/marker";
import StopPointIcon from "components/ui/map/markers/icons/stop-point-icon";
import _ from 'lodash';
import ReactPopup from "react-leaflet-popup";
import L from 'leaflet';
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import './route-stop-point-marker.less';
import ReactTooltip from 'react-tooltip'

@propTypes({
    item: PropTypes.object,
    color: PropTypes.oneOf([null, 'red', 'green']),
    permanentTooltip: PropTypes.bool,
    withoutPopup: PropTypes.bool,
    onEditClick: PropTypes.func,
    size: PropTypes.number,
})

@defaultProps({
    color: null,
    permanentTooltip: false,
    withoutPopup: false,
    onEditClick: null,
    size: 1,
})

export default class RouteStopPointMarker extends StopPointMarker {

    createStopPointPopup(onClose) {
        const stopPoint = this.props.item;
        if (!stopPoint) {
            return null;
        }

        return new ReactPopup({
            reactComponent: StopPointPopup,
            reactComponentProps: {
                ...this.props,
                stopPoint,
                onClose,
            },
            minWidth: 48,
            maxWidth: 48,
            offset: new L.Point(0, 0),
            closeButton: false,
        });
    }
}

class StopPointPopup extends Component {

    render() {
        const {onRoutingStatusToggle, onEditClick, onDeleteClick, onStopPointForwardAddClick, onStopPointReverseAddClick} = this.props;

        return (
            <div className="tooltip-custom bottom">
                <ReactTooltip/>
                {onEditClick ? (
                    <span data-tip="Посмотреть остановку" className="tooltip-icon inform" onClick={onEditClick}/>
                ) : null}
                <span data-tip="Удалить остановку" className="tooltip-icon remove" onClick={onDeleteClick}/>
                <span data-tip="Включить/выключить автоматическое прокладывание маршрута на участке"
                      className="tooltip-icon route" onClick={onRoutingStatusToggle}/>
                <span data-tip="Добавить остановку в вариант движения в прямом направлении"
                      className="tooltip-icon front-plus" onClick={onStopPointForwardAddClick}/>
                <span data-tip="Добавить остановку в вариант движения в обратном направлении"
                      className="tooltip-icon back-plus" onClick={onStopPointReverseAddClick}/>
            </div>
        );
    }

    onClose(e) {
        e.preventDefault();
        this.props.onClose();
    }
}