import _ from 'lodash';

export class User {
    user = null;

    constructor(user) {
        this.user = user;
    }

    getFullName(full = false) {
        if (full) {
            return `${_.get(this.user, 'info.surname')} ${_.get(this.user, 'info.name') ? _.get(this.user, 'info.name') : ''} ${_.get(this.user, 'info.second_name') ? _.get(this.user, 'info.second_name') : ''}`;
        } else {
            return `${_.get(this.user, 'info.surname')} ${_.get(this.user, 'info.name') ? (this.user.info.name.substr(0, 1) + '.') : ''} ${_.get(this.user, 'info.second_name') ? (this.user.info.second_name.substr(0, 1) + '.') : ''}`;
        }
    }

    getDriverNumber() {
        return [
            _.get(this.user, 'info.driver_serial'),
            _.get(this.user, 'info.driver_number'),
        ].join(' ');
    }

    getPhone() {
        return this.user.info.phone;
    }

    /**
     * Получение данных авторизации на Asterisk
     *
     * @return {{ext: string, password: string}}
     */
    get SIPData() {
        return {
            ext: _.get(this.user, 'info.sip_ext'),
            password: _.get(this.user, 'info.sip_password')
        };
    }

    /**
     * Проверка на наличие внутреннего SIP номера
     *
     * @return {boolean}
     */
    get hasSipExt() {
        return _.has(this.user, 'info.sip_ext');
    }
}