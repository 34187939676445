import {api} from 'helpers/api';
import {success, error} from 'helpers/response';

export const getRoadParts = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.getRoadParts(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRoadPart = (uuid, slice = {}) => async (dispatch) => {
    try {
        const response = await api.commdept.getRoadPart(uuid, slice);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRoadPartWorkTypes = (uuid, date) => async (dispatch) => {
    try {
        const response = await api.commdept.getRoadPartWorkTypes(uuid, date);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRoadPartWorkTypesMultiple = (uuids, date, withoutUsed = false) => async (dispatch) => {
    try {
        const response = await api.commdept.getRoadPartWorkTypesMultiple(uuids, date, withoutUsed);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const checkRoadPartWorkType = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.checkRoadPartWorkType(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createRoadPart = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.createRoadPart(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateRoadPart = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.updateRoadPart(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteRoadPart = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.deleteRoadPart(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRoadRepairPart = (data) => async (dispatch) => {
    try {
        const response = await api.commdept.getRoadRepairPart(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
