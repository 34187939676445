import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';

import './modal.less';

@propTypes({
    show: PropTypes.bool,
    className: PropTypes.string
})

@defaultProps({
    show: false
})

export default class Modal extends Component {

    render() {
        if (!this.props.show)
            return null;

        const className = classNames('b-modal_outdated', this.props.show ? '' : 'hidden', this.props.className);

        return (
            <div className={className}>
                <div className="b-modal__paranja"/>
                <div className="b-modal__window b-modal__window_small">{this.props.children}</div>
            </div>
        )
    }

}