import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { propTypes, defaultProps } from 'react-props-decorators';
import { connect } from 'react-redux';

import './routes.less';

import _ from 'lodash';
import Editor from './editor';

import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import moment from "moment";
import formats from "dictionaries/formats";
import { deleteRoute, getRoutes } from "store/reducers/routes/route_editor";
import { getDictionaryList } from "store/reducers/dictionaries/dictionary";
import { getEntityNames } from "store/reducers/system";
import { EntityList } from "helpers/entity";
import { isRouteA } from "helpers/route";
import systems from "dictionaries/systems";
import ReactDOMServer from 'react-dom/server';
import Loader from 'components/ui/loader';
import classNames from 'classnames';
import { getTableForPrint, printTable } from "helpers/print";
import { getUnits } from "store/reducers/organizational_units/units";
import ContextTooltip from "components/ui/context-tooltip";
import { State } from "components/ui/state";
import $ from 'jquery';
import { User } from "helpers/user";
import TableTreeComponent from "components/ui/Table/TreeComponent/TableTreeComponent";
import { component_mapper } from "helpers/component_mapper";
import { getTerritorialEntities } from "store/reducers/geo/territorial-entities";
import TableComponent from "components/ui/Table/Component/TableComponent";
import { listUserGeoObjectsMunicipalitiesList } from "store/reducers/user-map-objects/object_editor";
import { concat } from '../../../../lib/autobahn';
import {getUsers} from "store/reducers/staffing/staffing";

@connect(state => ({}), {
    getRoutes,
    getEntityNames,
    deleteRoute,
    getDictionaryList,
    getUnits,
    getTerritorialEntities,
    listUserGeoObjectsMunicipalitiesList,
    getUsers,
})

export default class KiutrRoutesComponent extends BaseTableWithEditorComponent {

    modelClass = 'App\\Model\\Route';
    modelSource = 'geo';

    getTitle() {
        return `${systems[this.props.params.component]} → ${(_.find(_.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, [ 'id', '125' ]).submenu, [ 'id', '129' ]).text)}`;
    }

    getBaseUrl() {
        return `/${this.props.params.component}/routes`;
    }

    constructor(props, context) {
        super(props, context);

        Object.assign(this.state, {
            component: null,
            showVis: false,
            communal_municipalities: {},
        });
    }

    componentDidMount() {
        this.forceUpdate();
        if (window.RNIS_SETTINGS.grantMO) {
            this.loadMunicipalities();
        }

    }

    showAudit(rowData) {
        this.props.router.push(`/system/audit/${rowData.uuid}?class=App\\Model\\Route`)
    }

    async componentWillUpdate(props, state) {
        super.componentWillUpdate(props, state);

        if (component_mapper(props.params.component) !== state.component) {
            await this.setState({
                component: component_mapper(props.params.component),
                columns: List(this.getColumns(component_mapper(props.params.component))),
            });
            state.component && this.reload();
        }
    }

    getStorageKey() {
        return 'routes:filters'
    }

    async onDblClick(data, row) {
        if (data && data.uuid) {
            if (data.has_children) {
                $(row.node()).find('.treegrid-control').click();
            } else {
                this.showEditorWithUuid(data.uuid);
            }
        }
    }

    onRowCreate = (row, data, index) => {
        if (data.deleted_at) {
            $(row).addClass('deleted');
        }

        if (data.has_children) {
            $(row).addClass('has-children');
        }
    };

    /*exportToXls() {
        if (!this.refs.table) return;
        const table = this.refs.table.getWrappedInstance().table;
        const headers = table.columns().header();
        const rows = table.rows().nodes();
        const html = getTableForPrint(headers, rows);

        this.convertToXls(html);
    }*/

    closeEditor() {
        super.closeEditor();
    }

    getEditor() {
        return (
            <Editor
                isPageWithDetect={true}
                key="editor"
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                {...this.props}
            />
        );
    }

    async submitEditor(item) {
        const mode = this.state.editorUuid ? 'edit' : 'add';

        super.submitEditor();

        if (mode === 'add') {
            this.props.router.push(`/${this.props.params.component}/routes/${item.uuid}/variants/${item.default_variant.uuid}/view`);
        }
    }

    async loadMunicipalities() {
        const response = await this.props.listUserGeoObjectsMunicipalitiesList();
        if (response.isOk) {
            const communal_municipalities = _.map(response.payload.municipalities, (item) => ({
                name: item.communal_municipality_name,
                uuid: item.communal_municipality_uuid,
            }));
            await this.setState({ communal_municipalities });

        } else {
            response.showErrors();
        }
    }

    getColumns(component) {
        switch (component) {
            case 'kiutr':
            case 'ems':
                return this.prepareColumns([

                    ...(window.RNIS_SETTINGS.CITY_MO ? ([
                        new Column('№ маршрута')
                            .withDebounce(true)
                            .fromField('number')
                            .withClassName('link')
                            .withMultiFilter(this.getDataForMultiFilter({
                                simple: 'withRouteNumber',
                                async: 'withRouteNumbers'
                            }, async (search) => {
                                const response = await this.loadListNumbers(
                                    search,
                                    'withRouteNumber',
                                    'items/number',
                                    this.props.getRoutes);
                                if (response.isOk) {
                                    return _.map(response.payload.items, item => ({
                                        uuid: item.number,
                                        name: item.number
                                    }));
                                }
                                return [];
                            })),
                    ]) : [
                        new Column('№ маршрута')
                            .withDebounce(true)
                            .fromField('number')
                            .withClassName('link')
                    ]),

                    new Column('Версия')
                        .fromField('version'),

                    new Column('Рег. № маршрута')
                        .fromField('registration_number'),

                    new Column('Наименование')
                        .fromField('title'),

                    new Column('Статус')
                        .fromField('status_uuid')
                        .withDrawer(item => item.status_uuid && this.state.related.get(item.status_uuid))
                        .denyColumnFilter()
                        .withFilter('withStatus', async () => {
                            const response = await this.props.getDictionaryList('route_passport_statuses');
                            if (response.isOk) {
                                return response.payload.documents;
                            }
                            return [];
                        }),

                    ...(window.RNIS_SETTINGS.CITY_TULA ? [ (new Column('Дата ввода в действие')
                        .fromField('work_from')
                        .withDateFilter()
                        .withDrawer(item => item.work_from && moment(item.work_from).format(formats.DATETIME_SHORT))) ] : []),

                    new Column('Дата внесения')
                        .fromField('created_at')
                        .withDateFilter()
                        .withDrawer(item => item.created_at && moment(item.created_at).format(formats.DATETIME_SHORT)),

                    new Column('Создатель')
                        .fromField('created_by_uuid')
                        .denyColumnFilter()
                        .withDrawer(item => item.created_by_uuid && this.state.related.get(item.created_by_uuid))
                        .withAsyncFilter('withCreatedBy', async (search) => {
                            const response = await this.props.getUsers({
                                search,
                                filters: {
                                    withComponent: component_mapper(this.props.params.component),
                                },
                                order: {
                                    column: 'surname',
                                    direction: 'asc',
                                },
                                pagination: {
                                    page: 1,
                                    limit: 50,
                                },
                            });
                            if (response.isOk) {
                                return _.map(response.payload.items, user => ({
                                    uuid: user.uuid,
                                    name: new User(user).getFullName()
                                }));
                            }
                            return [];
                        }),

                    new Column('Дата составления')
                        .fromField('composed_at')
                        .withDateFilter()
                        .withDrawer(item => item.composed_at && moment(item.composed_at).format(formats.DATE)),

                    new Column('Тип маршрута')
                        .fromField('route_type_uuid')
                        .withDrawer(item => item.route_type_uuid && this.state.related.get(item.route_type_uuid))
                        .denyColumnFilter()
                        .withFilter('withRouteType', async () => {
                            const response = await this.props.getDictionaryList('route_types');
                            if (response.isOk) {
                                return response.payload.documents;
                            }
                            return [];
                        }),

                    new Column('Вид сообщения')
                        .fromField('transport_connection_type_uuid')
                        .withDrawer(item => item.transport_connection_type_uuid && this.state.related.get(item.transport_connection_type_uuid))
                        .denyColumnFilter()
                        .withFilter('withTransportConnectionType', async () => {
                            const response = await this.props.getDictionaryList('transport_connection_types');
                            if (response.isOk) {
                                return response.payload.documents;
                            }
                            return [];
                        }),

                    new Column('Вид маршрута')
                        .fromField('route_kind_uuid')
                        .withDrawer(item => item.route_kind_uuid && this.state.related.get(item.route_kind_uuid))
                        .denyColumnFilter()
                        .withFilter('withRouteKind', async () => {
                            const response = await this.props.getDictionaryList('route_kinds');
                            if (response.isOk) {
                                return response.payload.documents;
                            }
                            return [];
                        }),

                    ...(!window.RNIS_SETTINGS.CITY_NOVOSIBIRSK ? [ (new Column(<span>Общая <br/>протяженность, м</span>)
                        .fromField('length_total')
                        .withNumberFilter()) ] : []),

                    ...(!window.RNIS_SETTINGS.CITY_NOVOSIBIRSK ? [ (new Column(<span>Протяженность в <br/>прямом направлении, м</span>)
                        .fromField('length_forward')
                        .withNumberFilter()) ] : []),

                    ...(!window.RNIS_SETTINGS.CITY_NOVOSIBIRSK ? [ (new Column(<span>Протяженность в <br/>обратном направлении, м</span>)
                        .fromField('length_reverse')
                        .withNumberFilter()) ] : []),

                    new Column('Образ документа')
                        .fromField('files')
                        .denyOrder()
                        .withDrawer(item => _.map(item.files || [], file => `<a href="${file}" target="_blank">Открыть</a>`).join('<br/>'))
                        .denyColumnFilter()
                        .withFilter('withFile', async () => {
                            return [
                                {
                                    uuid: '0',
                                    name: 'Отсутствует',
                                },
                                {
                                    uuid: '1',
                                    name: 'Присутствует',
                                },
                            ];
                        }),

                    new Column('Организатор перевозок')
                        .fromField('unit_uuid')
                        .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid))
                        .denyColumnFilter()
                        .withFilter('withOriginalUnits', async () => {
                            const response = await this.props.getUnits({
                                pagination: {
                                    page: 1,
                                    limit: 1000,
                                },
                                filters: {
                                    withComponent: component_mapper(this.props.params.component),
                                },
                                response_data: [
                                    'items/uuid',
                                    'items/name',
                                ],
                            });
                            if (response.isOk) {
                                return response.payload.items;
                            }
                            return {};
                        }),

                    new Column('Территориальные Образования')
                        .fromField('territorial_entities')
                        .withDrawer(item => _.map(item.territorial_entities || [], uuid => this.state.related.get(uuid)).join('<br/>'))
                        .denyColumnFilter()
                        .denyOrder()
                        .defaultHidden()
                        .withFilter('withTerritorialEntity', async () => {
                            const response = await this.props.getTerritorialEntities({
                                pagination: {
                                    page: 1,
                                    limit: 1000,
                                },
                            });
                            if (response.isOk) {
                                return response.payload.items;
                            }
                            return {};
                        }),

                    ...(window.RNIS_SETTINGS.grantMO ? [
                        new Column('Муниципальное образование')
                            .fromField('communal_municipality_uuid')
                            .denyColumnFilter()
                            .withDrawer(item => item.communal_municipality_uuid ? _.get(_.find(this.state.communal_municipalities, { uuid: item.communal_municipality_uuid }), 'name') : '<code>-</code>')
                            .withFilter('withCommunalMunicipality', async () => {
                                const response = await this.props.listUserGeoObjectsMunicipalitiesList();
                                if (response.isOk) {
                                    const communal_municipalities = _.map(response.payload.municipalities, (item) => ({
                                        name: item.communal_municipality_name,
                                        uuid: item.communal_municipality_uuid,
                                    }));
                                    return communal_municipalities;
                                }
                                return {};
                            }),
                    ] : []),

                    new Column('Публичный')
                        .fromField('is_public')
                        .defaultHidden()
                        .withDrawer(item => ReactDOMServer.renderToStaticMarkup(<State positive={item.is_public}/>))
                        .denyColumnFilter()
                        .withFilter('withIsPublic', () => {
                            return [
                                {
                                    uuid: 'public',
                                    name: 'Публичный',
                                },
                                {
                                    uuid: 'not_public',
                                    name: 'Не публичный',
                                },
                            ];
                        }),

                ]);
            case 'children':
                return this.prepareColumns([

                    new Column('№ маршрута')
                        .fromField('number')
                        .withClassName('link'),

                    new Column('Рег. № маршрута')
                        .fromField('registration_number'),

                    new Column('Наименование')
                        .fromField('title'),

                    new Column('Статус')
                        .fromField('status_uuid')
                        .withDrawer(item => item.status_uuid && this.state.related.get(item.status_uuid))
                        .denyColumnFilter()
                        .withFilter('withStatus', async () => {
                            const response = await this.props.getDictionaryList('route_passport_statuses');
                            if (response.isOk) {
                                return response.payload.documents;
                            }
                            return [];
                        }),

                    new Column('Дата внесения')
                        .fromField('created_at')
                        .withDateFilter()
                        .withDrawer(item => item.created_at && moment(item.created_at).format(formats.DATE)),

                    new Column('Дата составления')
                        .fromField('composed_at')
                        .withDateFilter()
                        .withDrawer(item => item.composed_at && moment(item.composed_at).format(formats.DATE)),

                    new Column('Вид маршрута')
                        .fromField('route_kind_uuid')
                        .withDrawer(item => item.route_kind_uuid && this.state.related.get(item.route_kind_uuid))
                        .denyColumnFilter(),

                    ...(!window.RNIS_SETTINGS.CITY_NOVOSIBIRSK ? [ new Column('Общая протяженность')
                        .fromField('length_total') ] : []),

                    ...(!window.RNIS_SETTINGS.CITY_NOVOSIBIRSK ? [ new Column('Протяженность в прямом направлении')
                        .fromField('length_forward') ] : []),

                    ...(!window.RNIS_SETTINGS.CITY_NOVOSIBIRSK ? [ new Column('Протяженность в обратном направлении')
                        .fromField('length_reverse') ] : []),

                    new Column('Образ документа')
                        .fromField('files')
                        .denyOrder()
                        .withDrawer(item => _.map(item.files || [], file => `<a href="${file}" target="_blank">Открыть</a>`).join('<br/>'))
                        .denyColumnFilter()
                        .withFilter('withFile', async () => {
                            return [
                                {
                                    uuid: '0',
                                    name: 'Отсутствует',
                                },
                                {
                                    uuid: '1',
                                    name: 'Присутствует',
                                },
                            ];
                        }),

                    new Column('Организация фрахтователь')
                        .fromField('unit_uuid')
                        .withDrawer(item => item.unit_uuid && this.state.related.get(item.unit_uuid))
                        .denyColumnFilter()
                        .withFilter('withOriginalUnits', async () => {
                            const response = await this.props.getUnits({
                                pagination: {
                                    page: 1,
                                    limit: 1000,
                                },
                                filters: {
                                    withComponent: component_mapper(this.props.params.component),
                                },
                                response_data: [
                                    'items/uuid',
                                    'items/name',
                                ],
                            });
                            if (response.isOk) {
                                return response.payload.items;
                            }
                            return {};
                        }),

                    new Column('ТО')
                        .fromField('territorial_entities')
                        .withDrawer(item => _.map(item.territorial_entities || [], uuid => this.state.related.get(uuid)).join('<br/>'))
                        .denyColumnFilter()
                        .denyOrder(),

                ]);
        }

        return [];
    }

    async deleteItem(data) {
        return await this.props.deleteRoute(data);
    }

    async loadData(meta) {

        _.set(meta, 'filters.withComponent', this.state.component);
        _.set(meta, 'filters.withoutGeometry', true);
        _.set(meta, 'filters.onlyVis', this.state.showVis);

        if (window.RNIS_SETTINGS.CITY_TULA) {
            _.set(meta, 'response_data', [
                'items/uuid',
                'items/deleted_at',
                'items/number',
                'items/version',
                'items/registration_number',
                'items/title',
                'items/status_uuid',
                'items/created_at',
                'items/created_by_uuid',
                'items/composed_at',
                'items/route_type_uuid',
                'items/transport_connection_type_uuid',
                'items/route_kind_uuid',
                'items/length_total',
                'items/length_forward',
                'items/length_reverse',
                'items/files',
                'items/unit_uuid',
                'items/is_public',
                'items/territorial_entities',
                'items/communal_municipality_uuid',
                'items/work_from'
            ])
        } else {
            _.set(meta, 'response_data', [
                'items/uuid',
                'items/deleted_at',
                'items/number',
                'items/version',
                'items/registration_number',
                'items/title',
                'items/status_uuid',
                'items/created_at',
                'items/created_by_uuid',
                'items/composed_at',
                'items/route_type_uuid',
                'items/transport_connection_type_uuid',
                'items/route_kind_uuid',
                'items/length_total',
                'items/length_forward',
                'items/length_reverse',
                'items/files',
                'items/unit_uuid',
                'items/is_public',
                'items/territorial_entities',
                'items/communal_municipality_uuid',
            ])
        }

        _.set(meta, 'filters.withComponent', this.state.component);
        _.set(meta, 'filters.withoutGeometry', true);
        _.set(meta, 'filters.onlyVis', this.state.showVis);

        return await this.props.getRoutes(meta);
    }

    async loadRoutesNumbers(search) {

        let meta = {
            pagination: { page: 1, limit: 25 },
        }
        _.set(meta, 'filters.withRouteNumber', search);
        _.set(meta, 'filters.withComponent', this.state.component);
        _.set(meta, 'filters.withoutGeometry', true);
        _.set(meta, 'filters.onlyVis', this.state.showVis);
        _.set(meta, 'response_data', [
            'items/number',
        ]);

        return await this.props.getRoutes(meta);
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const statuses = _.map(_.filter(_.map(result, 'status_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RoutePassportStatuses\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const routeTypes = _.map(_.filter(_.map(result, 'route_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RouteTypes\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const connectionTypes = _.map(_.filter(_.map(result, 'transport_connection_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\TransportConnectionType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const routeKinds = _.map(_.filter(_.map(result, 'route_kind_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RouteKinds\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const units = _.map(_.filter(_.map(result, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const users = _.map(_.filter(_.map(result, 'created_by_uuid')), (uuid) => ({
            class: 'App\\Model\\UserInfo',
            uuid: uuid,
            source: 'auth',
        }));

        const tos = _.map(_.filter(_.flatten(_.map(result, 'territorial_entities'))), (uuid) => ({
            class: 'App\\Model\\TerritorialEntity',
            uuid: uuid,
            source: 'geo',
        }));

        const municipalities = _.map(_.filter(_.map(result, 'communal_municipality_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Communal\\CommunalMunicipalities\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(_.concat(statuses, routeTypes, connectionTypes, routeKinds, units, users, tos, municipalities));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    loadSuboardinationRelatedEntities(result) {
        const statuses = _.map(_.filter(_.map(result, 'status_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RoutePassportStatuses\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const routeTypes = _.map(_.filter(_.map(result, 'route_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RouteTypes\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const connectionTypes = _.map(_.filter(_.map(result, 'transport_connection_type_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\TransportConnectionType\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const routeKinds = _.map(_.filter(_.map(result, 'route_kind_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RouteKinds\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const units = _.map(_.filter(_.map(result, 'unit_uuid')), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        return this.props.getEntityNames(_.concat(statuses, routeTypes, connectionTypes, routeKinds, units));
    }

    print() {
        if (!this.refs.table) return;
        const table = this.refs.table.getWrappedInstance().table;

        const headers = table.columns().header();
        const rows = table.rows().nodes();
        printTable(headers, rows, this.select);
    }

    renderTable() {
        const className = classNames({
            'show-table-filters': this.state.showTableFilters,
        });

        if (this.state.columns.size === 0) {
            return null;
        }

        return (
            <div className={className}>
                <TableComponent
                    ref="table"
                    select={this.select}
                    columns={this.state.columns}
                    loadCallback={::this.loadCallbackMiddleware}
                    onColsReordered={::this.onColsReordered}
                    onCheck={::this.selectedRowsRecalc}
                    query={this.state.query}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    onSettingsLoad={::this.onSettingsLoad}
                    onDblClick={::this.onDblClick}
                    onClick={this.onClick}
                    onExpand={this.onExpand}
                    onCollapse={this.onCollapse}
                    stateKey="routes"
                    onRowCreate={this.onRowCreate}
                    showAudit={::this.onAuditClick}
                />
            </div>
        );
    }

    async loadSuboardinationData(data, callback) {

        const meta = {
            filters: {
                withComponent: this.state.component,
                withoutGeometry: true,
                withActive: null,
                withRegistrationNumber: data.registration_number,
                onlyVis: this.state.showVis,
            },
        };

        const response = await this.props.getRoutes(meta);

        if (response.isOk) {
            const data = response.payload.items;
            const response2 = await this.loadSuboardinationRelatedEntities(data);

            if (response2.isOk) {
                this.setState((prevState) => {
                        const related = prevState.related;
                        related.add(response2);
                        return { related: related };
                    },
                    () => {
                        callback(data);
                    });
            } else {
                callback(data);
            }
        } else {
            response.showErrors();
            callback([]);
        }
    }

    collapseAll() {
        this.refs.table && this.refs.table.getWrappedInstance().collapseAll();
    }

    expand(element) {
        this.refs.table && this.refs.table.getWrappedInstance().expand(element);
    }

    collapse(element) {
        this.refs.table && this.refs.table.getWrappedInstance().collapse(element);
    }

    onExpand = (data, element, index) => {
        if (!data.children) data.children = [];

        if (!data.children.length) {
            //this.collapse(element);
            const columns = this.state.columns.toArray();

            if (columns.length) {
                data.children.push({
                    [columns[0].field]: ReactDOMServer.renderToStaticMarkup(<Loader color="red" align="none"/>)
                });

                this.expand(element);
            }

            this.loadSuboardinationData(data, (suboardData) => {
                data.children = suboardData;
                this.collapse(element);
                this.expand(element);
            });
        } else {
            this.expand(element);
        }

    };

    onCollapse = (data, element) => {

    };

    reload() {
        if (!this.refs.table) return;

        //this.refs.table.getWrappedInstance().collapseAll();
        this.refs.table.getWrappedInstance().reload();
    }

    renderHeaderContents() {
        return _.concat([
            <ContextTooltip key="base-table-list.show-vis" code="base-table-list.show-vis" space={20}
                            default="РНИС/ВИС">
                {this.renderVisToggle()}
            </ContextTooltip>,
        ], super.renderHeaderContents());
    }

    renderVisToggle() {
        const lineClassName = classNames('b-slider__line', this.state.showVis ? '_selected_all' : '');
        const circleClassName = classNames('b-slider__circle', this.state.showVis ? '_selected_all' : '');

        return (
            <div className={`b-slider b-slider-deleted _top-menu`} onClick={::this.toggleShowVis}>
                <div className="b-slider__control">
                    <div className={lineClassName}/>
                    <div className={circleClassName}/>
                </div>
            </div>
        );
    }

    async toggleShowVis() {
        await this.setState({
            showVis: !this.state.showVis,
        });
        this.reload();
    }
}
