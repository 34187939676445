import {api} from 'helpers/api';
import {success, error} from 'helpers/response';
import { setRoadParts, setLoadingRoadParts } from './actions';

export const getRoadParts = (meta = {}) => async (dispatch) => {
    dispatch(setLoadingRoadParts(true));

    try {
        const response = await api.kurs.getRoadParts(meta);
        dispatch(setRoadParts(response.payload.items));
        dispatch(setLoadingRoadParts(false));

        return success(response);
    }
    catch (e) {
        dispatch(setLoadingRoadParts(false));
        return error(e);
    }
};

export const getRoadPart = (uuid, slice = {}) => async (dispatch) => {
    try {
        const response = await api.kurs.getRoadPart(uuid, slice);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRoadPartWorkTypes = (uuid, date) => async (dispatch) => {
    try {
        const response = await api.kurs.getRoadPartWorkTypes(uuid, date);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRoadPartWorkTypesMultiple = (uuids, date, withoutUsed = false) => async (dispatch) => {
    try {
        const response = await api.kurs.getRoadPartWorkTypesMultiple(uuids, date, withoutUsed);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const checkRoadPartWorkType = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.checkRoadPartWorkType(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createRoadPart = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.createRoadPart(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateRoadPart = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.updateRoadPart(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteRoadPart = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.deleteRoadPart(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getRoadRepairPart = (data) => async (dispatch) => {
    try {
        const response = await api.kurs.getRoadRepairPart(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};