import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import _ from 'lodash';
import TableContainer from "components/ui/Table/Container/TableContainer";

@propTypes({
    target: PropTypes.string,
    event: PropTypes.string,
})

@defaultProps({
    target: null,
    event: null,
})

export default class NotificationTextParameters extends Component {
    render() {
        return (
            <div className="Table indent-none">
                <TableContainer>
                    <table ref="table" className="b-table">
                        <tbody>
                        {this.renderParameters()}
                        </tbody>
                    </table>
                </TableContainer>
            </div>
        );
    }

    renderParameters() {
        return _.map(this.getParameters(), (title, parameter) => {
            return (
                <tr key={parameter}>
                    <td>%{parameter}%</td>
                    <td>{title}</td>
                </tr>
            );
        });
    }

    getParameters() {
        let parameters = {};

        if (this.props.event === 'bnso_connection_lost') {
            return {
                'vehicle.state_number': 'Госномер ТС',
                'vehicle.garage_number': 'Гаражный номер ТС',
                'vehicle.mark': 'Марка ТС',
                'vehicle.model': 'Модель ТС',
                'driver.name': 'Водитель',
                'unit.name': 'Предприятие',
                'route.number': 'Номер маршрута',
            };
        }

        if (this.props.target === 'vehicle') {
            Object.assign(parameters, {
                'vehicle.state_number': 'Госномер ТС',
                'vehicle.garage_number': 'Гаражный номер ТС',
                'vehicle.mark': 'Марка ТС',
                'vehicle.model': 'Модель ТС',
                'driver.name': 'Водитель',
                'unit.name': 'Предприятие',
                'route.number': 'Номер маршрута',
                'latitude': 'Широта',
                'longitude': 'Долгота',
                'device_time': 'Время поступления данных в ЕТП',
                'received_time': 'Время отправки данных в ЕТП',
            });
        }

        if (this.props.event === 'highway_in') {
            Object.assign(parameters, {
                'vehicle.road_part_name': 'Наименование участка дороги',
            });
        }

        switch (this.props.event) {
            case 'tasks_more_resources':
                return {
                    'entry.free_drivers_count': 'Количество свободных водителей',
                    'entry.free_vehicles_count': 'Количество свободных ТС',
                    'entry.free_drivers': 'Свободные водители',
                    'entry.free_vehicles': 'Свободные ТС',
                };
            case 'contract_end_date':
                return {
                    'entry.name': `Наименование ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}`,
                    'entry.number': `Номер ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}`,
                    'entry.external_id': `Идентификатор СКПДИ ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}`,
                };
            case 'kurs_schedule_error':
                return {
                    'entry.number': 'Номер задания',
                    'entry.vehicles': 'ТС',
                };
            case 'tasks_in_status':
            case 'tasks_less_resources':
                return {
                    'entry.numbers': 'Номера заданий',
                    'entry.count': 'Количество заданий',
                };
            case 'kurt_task_close':
                return {
                    'entry.number': 'Номер задания',
                    'entry.external_id': 'Идентификатор СКПДИ задания',
                };
            case 'maintenance_time':
            case 'maintenance_time_overdue':
            case 'vehicle_contract_end_date':
            case 'fuel_drain':
                return {
                    'vehicle.state_number': 'Госномер ТС',
                    'vehicle.garage_number': 'Гаражный номер ТС',
                    'unit.name': 'Наименование предприятия',
                    'vehicle.mark': 'Марка ТС',
                    'vehicle.model': 'Модель ТС',
                };
            case 'technocard_work_types':
                return {
                    'entry.name': 'Наименование тех. карты',
                    'entry.work_types': 'Виды работ',
                };
            case 'contract_work_graphic':
            case 'contract_work_plan_graphic':
                return {
                    'entry.number': `Номер ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}`,
                    'unit.name': 'Предприятие-исполнитель',
                    'entry.work_type': 'Вид работ',
                };
            case 'waybill_wrong_mileages':
                return {
                    'entry.number': 'Номер путевого листа',
                    'unit.name': 'Наименование предприятия',
                };
            case 'op_control':
                return {
                    'entry.name': 'Наименование остановки',
                    'entry.period': 'Плановое время начала рейса - плановое время окончания рейса',
                    'entry.time': 'Плановое время ОП',
                    'entry.turn': 'Номер выхода',
                    'entry.shift': 'Номер смены',
                    'entry.violation': 'Нарушение',
                };
            case 'operator_contract_end_date':
                return {
                    'entry.number': `Номер ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}`,
                    'unit.name': 'Организатор перевозок',
                    'carrier.name': 'Перевозчик',
                };
        }

        return parameters;
    }
}
