import * as storage from "../../utils/storage";
import {createConnection} from "../wsrpc";
import {api} from "../api";
import {makeResponse} from "helpers/response";
import $ from 'jquery';
import cookies from 'browser-cookies';

let credits = storage.get('user');

let token = null;

if (credits && credits.token) {
    token = credits.token;
}

export function getCurrentUser() {
    return credits;
}

export const rpc = createConnection(token);
export const rpcMosreg = createConnection(token, 'rnis.mosreg.ru');

rpc.ip().then((ip) => {
    rpc.setIp(ip);
});

async function loginAtStorage(token) {
    cookies.set('token', token, {
       // domain: '.mosreg.ru',
        //domain: 'localhost',
        expired: 30,
    });
    /*$.ajax(
        `${rpc.options.ajax.url.replace('\/ajax', '/ajax/login')}`,
        {
            method: 'POST',
            data: {
                token,
            },
            success: (event, status, xhr) => {
            },
            complete: (xhr) => {
            }
        }
    );*/
}

let checkTokenResult = null;
let checkTokenInProgress = false;

export async function checkToken() {
    if (checkTokenResult !== null) {
        return checkTokenResult;
    }
    if (checkTokenInProgress) {
        return true;
    }
    checkTokenInProgress = true;
    try {
        await makeResponse(() => {
            return api.auth.getUser();
        }, 'current-user');
        checkTokenResult = true;
        loginAtStorage(rpc.getToken());
        return true;
    } catch (e) {
    }
    await removeCredits();
    checkTokenResult = false;
    return false;
}

export async function resetCheckToken() {
    checkTokenResult = null;
    removeCredits();
}

export const isAuthorized = () => !!(credits && credits.token);

export async function connect() {
    return rpc.connect();
}

export async function connectionLost(callback) {
    await rpc.onLost(callback)
}

export async function connectionEstablished(callback) {
    await rpc.onEstablished(callback)
}

export async function login(username, password) {
    return rpc.request(
        'com.rnis.auth.action.login', {login: username, password}
    );
}

export async function esiaToRnisUserConfirm(payload) {
    return rpc.request(
        'com.rnis.auth.action.esia.new_user.confirm', {...payload}
    );
}

export async function esiaLogin(payload) {
    if (RNIS_SETTINGS.CITY_MURMANSK) {
        return rpc.request(
            'com.rnis.auth.action.rosatom.login', {...payload}
        )
    }
    return rpc.request(
        'com.rnis.auth.action.esia.login', {...payload}
    );
}

export async function getEsiaUrl(host) {
    if (RNIS_SETTINGS.CITY_MURMANSK) {
        return rpc.request(
            'com.rnis.auth.action.rosatom.get_authorization_url', { remote_host: host }
        )
    }
    return rpc.request(
      'com.rnis.auth.action.esia.get_authorization_url', { remote_host: host }
    )
}

export async function logout() {
    await rpc.request('com.rnis.auth.action.logout', {});
    await removeCredits();
}

export async function saveCredits(payload) {
    storage.set('user', payload);
    credits = payload;
    rpc.setToken(credits.token);
}

export function hasCredits() {
    return credits !== null;
}

export async function removeCredits() {
    storage.clear();
    credits = null;
    cookies.erase('token');
    rpc.setToken(null);
}

/**
 *
 * @param {string} method
 * @param args
 * @return {Promise.<*>}
 */
export async function request(method, ...args) {
    const response = await makeResponse(() => {
        return rpc.request(method, ...args);
    });

    return response;
}
