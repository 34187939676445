import _ from 'lodash';

export function component_mapper(component, mapRules = null) {
    if (!mapRules) {
        mapRules = {
            'kiutr-control': 'kiutr',
            'ems': 'kiutr',
        };
    }
    return _.get(mapRules, component) || component;
}