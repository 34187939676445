import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {Link} from "react-router";
import classNames from 'classnames';

@propTypes({
})

@defaultProps({
})

export default class ModalTopMenuButtonsSeparator extends Component {
    render() {
        return (
            <span className="b-modal__header-separator"/>
        );
    }
}