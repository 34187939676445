import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';
import classNames from 'classnames';
import $ from 'jquery';

export default class TaskItem extends Component {
    componentDidUpdate() {
        const hasError = $(this.refs.container).find('.input__style_state_wrong').length > 0;
        $(this.refs.container).toggleClass('tab_error', hasError);
    }

    render() {
        return (
            <div {...this.props} ref="container">
                {this.props.children}
            </div>
        );
    }
}