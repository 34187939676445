import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import Page from "components/ui/page";
import {connect} from "react-redux";
import BaseEditorFormComponent from "components/base/base-editor-form";
import {
    getVehicle, createVehicle, updateVehicle, deleteVehicle, getVehicles,
    getKursVehicleByBaseVehicle
} from "store/reducers/commdept/vehicles";
import BaseEditor from "components/base/base-editor";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import Block from "components/ui/form/block";
import {getUnits} from "store/reducers/organizational_units/units";
import currentUser from 'helpers/current-user';
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getBnsoList} from "store/reducers/vehicles/bnso";
import {getVehicleBnsoList} from "store/reducers/vehicles/vehicle_bnso";
import GlobalLoaderComponent from "components/ui/global-loader";
import systems from "dictionaries/systems";
import ContextTooltip from "components/ui/context-tooltip";
import IconButton from "components/ui/icon-button";
import FileReaderInput from 'react-file-reader-input';

import './style.less';
import {getUsers} from "store/reducers/staffing/staffing";
import {User} from "helpers/user";
import {api} from "helpers/api";
import KursOperationAddForm from "components/modules/commdept/vehicles/vehicle-editor-form/operation-add-form/index";
import {deleteVehicleWork, getVehicleWorks} from "store/reducers/commdept/vehicle_works";
import TableContainer from "components/ui/Table/Container/TableContainer";
import moment from "moment";
import formats from "dictionaries/formats";
import Button from "components/ui/button";
import {getKursMaintenances} from "store/reducers/commdept/maintenances";
import {MaintenancePeriods} from "dictionaries/commdept";
import {
    createVehicleMechanism, getVehicleMechanisms,
    updateVehicleMechanism
} from "store/reducers/commdept/vehicle_mechanisms";
import * as alerts from "helpers/alerts";
import KursMechanismAddForm from "components/modules/commdept/vehicles/vehicle-editor-form/mechanism-add-form/index";
import KursTrailerAddForm from "components/modules/commdept/vehicles/vehicle-editor-form/trailer-add-form/index";
import KursVehicleTasksForm from "components/modules/commdept/vehicles/vehicle-editor-form/tasks/index";
import Tabs from "components/ui/tabs/tabs";
import TabItem from "components/ui/tabs/tab-item";
import TabBlock from "components/ui/tabs/tab-block";
import DictionaryEditor from "components/modules/dictionaries/editor";
import {makeResponse} from "helpers/response";
import pluralize from "pluralize-ru";
import KursVehicleFuel from "components/modules/commdept/vehicles/vehicle-editor-form/vehicle-fuel/index";
import {getTasks} from "store/reducers/commdept/tasks";
import Lightbox from "react-images";
import {getWaybills} from "store/reducers/commdept/waybills";
import Input from "components/ui/form/input";
import {getMileage, getMotohours} from "store/reducers/maps";
import Loader from "../../../../ui/loader";
import debounce from 'throttle-debounce/debounce';
import {component_mapper} from "../../../../../helpers/component_mapper";


@connect(state => ({}), {
    getVehicle,
    getKursVehicleByBaseVehicle,
    createVehicle,
    updateVehicle,
    deleteVehicle,
    getVehicleBnsoList,
    deleteVehicleWork,
})

export default class CommdeptVehicleEditorForm extends BaseEditor {

    title = 'ТС';
    modelClass = 'App\\Model\\KursVehicle';


    componentDidMount() {
        this.forceUpdate();
    }

    async componentWillUpdate(props, state) {
        const propsUuid = (props.params.uuid === 'create') ? null : props.params.uuid;
        if (state.uuid !== propsUuid) {
            this.setState({
                uuid: propsUuid,
                item: null,
                isLoading: !!propsUuid,
                mode: propsUuid ? 'edit' : 'add',
                itemIndex: 0,
            });
            if (propsUuid) {
                const response = await this.loadData(propsUuid);
                if (response.isOk) {
                    this.setState({
                        item: response.payload,
                        isLoading: false,
                    });
                } else {
                    response.showErrors();
                }
            }
        }
    }

    async loadData(uuid) {
        const response = (window.location.pathname.indexOf('/base/') !== -1) ? await this.props.getKursVehicleByBaseVehicle(uuid) : await this.props.getVehicle(uuid);
        if (response.isOk) {
            let vehicle = response.payload;
            vehicle.bnso = await this.loadBnso(vehicle);

            response.payload = vehicle;
        }
        return response;
    }

    async loadBnso(vehicle) {
        const response = await this.props.getVehicleBnsoList(vehicle.vehicle_uuid);

        if (response.isOk) {
            return response.payload.items;
        } else {
            response.showErrors();
        }
    }

    async createItem(data) {
        return await this.props.createVehicle(data);
    }

    async updateItem(data) {
        return await this.props.updateVehicle(data);
    }

    async create(data) {
        this.clearErrors();
        this.startSave();

        this.setState({
            formUpdated: false,
        });
        const response = await this.createItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            this.props.router.push(`/commdept/vehicles/${response.payload.uuid}`);
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    async edit(data) {
        this.clearErrors();
        this.startSave();

        this.setState({
            formUpdated: false,
        });
        const response = await this.updateItem(this.composeItem(data));

        this.endSave();
        if (response.isOk) {
            this.setState({
                item: response.payload,
                formUpdated: false,
                itemIndex: this.state.itemIndex + 1,
            });
        } else {
            this.setState({
                errors: response.validationErrors
            });
            response.showErrors();
        }
    }

    getForm(item, onSubmit) {
        return (
            <EditorForm
                {...this.props}
                key={this.state.itemIndex}
                ref="form"
                mode={this.state.mode}
                onSubmit={onSubmit}
                onClose={::this.onClose}
                data={this.decomposeItem(item)}
                errors={this.state.errors}
                onUpdate={::this.onFormUpdate}
            />
        );
    }

    onFormUpdate() {
        this.setState({
            formUpdated: true,
        });
    }

    onClose() {
        if (this.state.formUpdated) {
            alerts.prompt('Вы действительно хотите закрыть эту страницу без сохранения данных?', '', () => {
                this.props.router.push('/commdept/vehicles');
            }, 'Закрыть');
        } else {
            this.props.router.push('/commdept/vehicles');
        }
    }

    trimDeep(obj) {
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                if (typeof obj[prop] === 'string') {
                    obj[prop] = obj[prop].trim();
                } else if (typeof obj[prop] === 'object') {
                    this.trimDeep(obj[prop]);
                }
            }
        }
    }

    composeItem(data) {
        const malfunctions = _.keys(_.pickBy(_.get(data, 'general.malfunctions'), item => !!item));
        let item = _.cloneDeep(data);

        _.set(item, 'general.malfunctions', malfunctions);
        _.set(item, 'general.state_number', _.get(item, 'state_number'));
        _.set(item, 'general.vehicle_type_uuid', _.get(item, 'vehicle_type_uuid'));
        _.set(item, 'general.vehicle_mark_uuid', _.get(item, 'vehicle_mark_uuid'));
        _.set(item, 'general.vehicle_model_uuid', _.get(item, 'vehicle_model_uuid'));
        _.set(item, 'general.unit_uuid', _.get(item, 'unit_uuid'));

        this.trimDeep(item);

        return item;
    }

    decomposeItem(data) {
        let item = _.cloneDeep(data);

        let malfunctions = {};
        _.each(_.get(item, 'general.malfunctions', []), (malfunctionUuid) => {
            _.set(malfunctions, malfunctionUuid, true);
        });

        _.set(item, 'general.malfunctions', malfunctions);

        return item;
    }

    render() {
        let title = this.getTitle() ? `Добавление ${this.getTitle()}` : '';

        let form;
        const loader = (this.state.isLoading || this.state.saving) ? (<GlobalLoaderComponent/>) : null;
        let onSubmit;

        if (this.state.mode === 'edit') {
            title = this.getTitle() ? `Редактирование ${this.getTitle()}` : '';

            if (this.state.item) {
                form = this.getForm(this.state.item, ::this.edit);
                onSubmit = ::this.onEdit;
            }
        } else {
            form = this.getForm({}, ::this.create);
            onSubmit = ::this.onCreate;
        }

        return (
            <Page
                pageId="RoadMachineryAccountingView"
                title={`${systems.commdept} → ${title}`}
                className="profile-modal"
                headerActions={this.renderHeaderActions()}
            >
                {loader}
                {form}
                {this.state.formUpdated ? (
                    <div className="page-footer">
                        <div className="page-footer__txt">Вы хотите сохранить все изменения?</div>
                        <Button size="md" color="white" shadow="gray" className="b-button_cancel" text="Отменить"
                                onClick={::this.onReset}/>
                        <Button size="md" color="red" className="b-button_save" text="Сохранить"
                                onClick={onSubmit}/>
                    </div>
                ) : null}
            </Page>
        );
    }

    renderHeaderActions() {
        return [
            ((this.state.mode === 'edit') && currentUser.can('com.rnis.system.permission.audit', 'read') && this.modelClass) ?
                (
                    <ContextTooltip key="base-editor.audit" code="base-editor.audit"
                                    default="Журнал аудита">
                        <IconButton icon="history" onClick={::this.gotoAudit}/>
                    </ContextTooltip>
                ) : null,
            <ContextTooltip key="kurs.vehicle.back" code="kurs.vehicle.back" default="Назад">
                <IconButton icon="back-0" onClick={::this.onClose}/>
            </ContextTooltip>,
        ];
    }

    gotoAudit() {
        const url = `/system/audit/${this.state.uuid}?class=${this.modelClass}`;

        this.props.router.push(url);
    }

    onReset() {
        this.refs.form.getWrappedInstance().onReset();
        this.setState({
            formUpdated: false,
        });
    }
}


@propTypes({
    mode: PropTypes.oneOf(['edit', 'add']),
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object
})

@connect((state) => ({}), {
    getUnits,
    getUsers,
    getDictionaryList,
    getVehicleWorks,
    getBnsoList,
    getKursMaintenances,
    getVehicleMechanisms,
    createVehicleMechanism,
    updateVehicleMechanism,
    deleteVehicleWork,
    getVehicles,
    getTasks,
    getWaybills,
    getMileage,
    getMotohours
}, null, {withRef: true})

class EditorForm extends BaseEditorFormComponent {

    state = {
        kurs_vehicle: {},
        vehicle_types: [],
        vehicle_marks: [],
        vehicle_models: [],
        kurs_malfunctions: [],
        kurs_registration_units: [],
        kurs_technical_conditions: [],
        seasons: [],
        kurs_mechanism_bindings: [],
        kurs_tenures: [],
        vehicle_subsystems: [],
        kurs_lease_prolongation_types: [],
        kurs_engine_types: [],
        vehicle_capacities: [],
        kurs_thruster_types: [],
        vehicle_environmental_classes: [],
        kurs_colors: [],
        kurs_vehicle_categories: [],
        kurs_vehicle_manufacturers: [],
        kurs_service_units: [],
        kurs_insurance_companies: [],
        kurs_service_types: [],
        showOperationAddForm: false,
        showMechanismAddForm: false,
        showTrailerAddForm: false,
        vehicle_works: [],
        kurs_vehicle_repair_types: [],
        kurs_accounting_groups: [],
        kurs_details: [],
        bnso: [],
        unused_bnso: [],
        maintenance: {},
        vehicle_mechanisms: [],
        operationAddFormType: null,
        showAddMalfunction: false,
        malfunctionDictionaryMeta: null,
        trailers: {},
        kurs_formulas: [],
        latestWaybill: null,
        mileage: null,
        motohours: null,
    };

    operationAddSubmitCallback = null;

    isTrailer() {
        return _.get(_.find(this.state.vehicle_types, {value: this.get('vehicle_type_uuid')}), 'document.is_trailer');
    }

    async loadVehicleWorks() {
        const response = await this.props.getVehicleWorks({
            order: {
                column: 'date',
                direction: 'desc',
            },
            filters: {
                withVehicle: this.state.kurs_vehicle.vehicle_uuid,
            },
        });
        if (response.isOk) {
            this.setState({
                vehicle_works: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    async loadVehicleMechanisms() {
        const response = await this.props.getVehicleMechanisms({
            filters: {
                withVehicle: this.state.kurs_vehicle.vehicle_uuid,
            },
        });
        if (response.isOk) {
            let items = response.payload.items;
            _.reverse(items);
            this.setState({
                vehicle_mechanisms: items,
            });
            this.loadVehicles(_.filter(_.map(response.payload.items, 'trailer_uuid')));
        } else {
            response.showErrors();
        }
    }

    async loadVehicles(uuids) {
        if (uuids.length === 0) {
            return;
        }

        const response = await this.props.getVehicles({
            filters: {
                withUuid: uuids,
            },
        });

        if (response.isOk) {
            this.setState({
                trailers: _.keyBy(response.payload.items, 'uuid'),
            });
        } else {
            response.showErrors();
        }
    }

    getData() {
        return this.state.kurs_vehicle;
    }

    onReset() {
        this.setState({
            kurs_vehicle: _.cloneDeep(this.props.data),
        });
    }

    async componentWillMount() {
        await this.setState({
            kurs_vehicle: _.cloneDeep(this.props.data),
        });
        if (this.props.mode === 'edit') {
            this.loadVehicleWorks();
            this.loadVehicleMechanisms();
        }
        this.loadDictionaries([
            'vehicle_types',
            'vehicle_marks',
            'vehicle_models',
            'kurs_malfunctions',
            'kurs_registration_units',
            'kurs_technical_conditions',
            'seasons',
            'vehicle_capacities',
            'kurs_mechanism_bindings',
            'kurs_tenures',
            'kurs_lease_prolongation_types',
            'kurs_engine_types',
            'kurs_thruster_types',
            'vehicle_environmental_classes',
            'kurs_colors',
            'kurs_vehicle_categories',
            'kurs_vehicle_manufacturers',
            'kurs_service_units',
            'kurs_insurance_companies',
            'kurs_service_types',
            'kurs_vehicle_repair_types',
            'kurs_accounting_groups',
            'kurs_details',
            'vehicle_environmental_classes',
            'bnso_types',
            'bnso_manufacturers',
            'bnso_operators',
            'kurs_task_statuses',
            'kurs_formulas'
        ], 'commdept');
        if (window.RNIS_SETTINGS.vehicle_editor_subsystem) {
            this.loadDictionaries([
                'vehicle_subsystems',
            ], 'commdept');
        }
        this.loadUnits();
        //this.loadBnso();
        this.loadUnusedBnso();
        if (this.props.mode === 'edit') {
            this.loadMaintenance();
            this.loadLatestWaybill();
            //this.loadMotorhours();
            //this.loadMileage();
        }
        this.loadMalfunctionDictionaryMeta();
    }

    async loadMotorhours() {
        if (!this.state.kurs_vehicle.bnso) return;
        const bnso = _.get(_.find(this.state.bnso, {value: this.state.kurs_vehicle.bnso[0].bnso_uuid}), 'document');
        // const date = this.get('service.last_service_date') ? moment(this.get('service.last_service_date')).format(formats.DATETIME_API) : moment().format(formats.DATETIME_API);
        const response = await this.props.getMotohours(
            bnso.bnso_code,
            moment().format(formats.DATETIME_API));
        if (response.isOk) {
            this.setState({
                motohours: Math.round(response.payload.odometr / 60 / 60)
            })
        } else {
            response.showErrors();
        }
        if (this.get('service.last_service_date')) {
            const prevDate = moment(this.get('service.last_service_date')).format(formats.DATETIME_API);
            const responsePrev = await this.props.getMotohours(
                bnso.bnso_code,
                prevDate);
            if (response.isOk) {
                this.setState({
                    motohours: Math.round(this.state.motohours - responsePrev.payload.odometr / 60 / 60)
                })
            } else {
                response.showErrors();
            }
        }
    }

    async loadMileage() {

        //console.log( moment(this.get('service.last_service_date')).format(formats.DATE))
        if (!this.state.kurs_vehicle.bnso) return;
        // if (!this.get('service.last_service_date')) return;


        const bnso = _.get(_.find(this.state.bnso, {value: this.state.kurs_vehicle.bnso[0].bnso_uuid}), 'document');
        const response = await this.props.getMileage(
            bnso.bnso_code,
            moment().format(formats.DATETIME_API)
        );
        if (response.isOk) {
            this.setState({
                mileage: response.payload.mileage
            })
        } else {
            response.showErrors();
        }
        if (this.get('service.last_service_date')) {
            const prevDate = moment(this.get('service.last_service_date')).format(formats.DATETIME_API);
            const responsePrev = await this.props.getMileage(
                bnso.bnso_code,
                prevDate);
            if (response.isOk) {
                this.setState({
                    mileage: Number(this.state.mileage - responsePrev.payload.mileage).toFixed(1)
                })
            } else {
                response.showErrors();
            }
        }
    }

    async loadLatestWaybill() {
        const response = await this.props.getWaybills({
            filters: {
                withVehicle: this.get('vehicle_uuid'),
                withStatus: 'closed',
            },
            order: {
                column: 'date',
                direction: 'desc',
            },
            pagination: {
                page: 1,
                limit: 1,
            },
        });

        if (response.isOk) {
            this.setState({
                latestWaybill: _.first(response.payload.items),
            });
        } else {
            response.showErrors();
        }
    }

    async loadMalfunctionDictionaryMeta() {
        const response = await makeResponse(() => {
            return api.dictionary.getDictionaryMeta('kurs_malfunctions');
        });

        if (response.isOk) {
            this.setState({
                malfunctionDictionaryMeta: response.payload,
            })
        } else {
            response.showErrors();
        }
    }

    async loadMaintenance() {
        const response = await this.props.getKursMaintenances({
            filters: {
                withModel: this.get('vehicle_model_uuid'),
                withMark: this.get('vehicle_mark_uuid'),
            },
        });
        if (response.isOk) {
            const maintenance = _.first(response.payload.items) || {};
            await this.setState({
                maintenance,
            });

            this.setValue('kurs_vehicle.service.next_service_date', moment(this.getNextServiceDate(), formats.DATE).format(formats.DATE_URL), false, false);
            this.setValue('kurs_vehicle.service.next_service_mileage', this.getNextServiceMileage(), false, false);
        } else {
            response.showErrors();
        }
    }

    async loadUnits() {
        const response = await this.props.getUnits({
            filters: {
                withComponent: 'commdept',
            },
        });
        if (response.isOk) {
            const units = _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.name,
            }));
            this.setState({units});
        } else {
            response.showErrors();
        }
    }

    async loadBnso() {
        const response = await this.props.getBnsoList({
            pagination: {
                page: 1,
                limit: 10000,
            },
            filters: {
                withComponent: 'commdept',
            },
        });
        if (response.isOk) {
            const bnso = _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.bnso_code,
                document: item,
            }));
            this.setState({bnso});
            this.loadMotorhours();
            this.loadMileage();
        } else {
            response.showErrors();
        }
    }

    async loadUnusedBnso() {
        const response = await this.props.getBnsoList({
            pagination: {
                page: 1,
                limit: 10000,
            },
            filters: {
                withComponent: 'commdept',
                notInUse: true,
            },
        });
        if (response.isOk) {
            const unused_bnso = _.map(response.payload.items, (item) => ({
                value: item.uuid,
                label: item.bnso_code,
            }));
            this.setState({unused_bnso});
        } else {
            response.showErrors();
        }
    }

    get(path, defaultValue = null) {
        return _.get(this.state.kurs_vehicle, path, defaultValue);
    }

    render() {
        const loader = this.state.dictionariesLoading ? (<GlobalLoaderComponent/>) : null;
        return (
            <div>
                {loader}
                <Tabs>
                    <TabItem key="general" title="Общие сведения">
                        {this.renderGeneral()}
                    </TabItem>
                    {!this.isTrailer() ? (
                        <TabItem key="bnso" title="БНСО">
                            {this.renderBnso()}
                        </TabItem>
                    ) : null}
                    <TabItem key="technical" title="Технические данные">
                        {this.renderTechnical()}
                    </TabItem>
                    <TabItem key="documents" title="Документы на ТС">
                        {this.renderDocuments()}
                    </TabItem>
                    {(this.props.mode === 'edit') ? (
                        <TabItem key="service" title="Регламент ТО">
                            {this.renderService()}
                        </TabItem>
                    ) : null}
                    {(this.props.mode === 'edit') ? (
                        <TabItem key="details" title="Учет з/ч и деталей">
                            {this.renderDetails()}
                        </TabItem>
                    ) : null}
                    {(this.props.mode === 'edit') ? (
                        <TabItem key="mechanisms" title="Механизмы">
                            {this.renderMechanisms()}
                        </TabItem>
                    ) : null}
                    {(this.props.mode === 'edit') ? (
                        <TabItem key="tasks" title="Список заданий">
                            {this.renderTasks()}
                        </TabItem>
                    ) : null}
                    {(this.props.mode === 'edit') ? (
                        <TabItem key="fuel" title="Топливо">
                            {this.renderFuel()}
                        </TabItem>
                    ) : null}
                </Tabs>
                {this.state.showOperationAddForm ? this.renderOperationAddForm() : null}
                {this.state.showMechanismAddForm ? this.renderMechanismAddForm() : null}
                {this.state.showTrailerAddForm ? this.renderTrailerAddForm() : null}
                {this.state.showAddMalfunction ? this.renderAddMalfunction() : null}
            </div>
        );
    }

    requestOperationAdd(callback, type = 'details') {
        this.operationAddSubmitCallback = callback;
        this.setState({
            showOperationAddForm: true,
            operationAddFormType: type,
            operationAddFormUuid: null,
        });
    }

    closeOperationAdd(withSubmit = false) {
        if (withSubmit) {
            this.loadVehicleWorks();
        }
        if (withSubmit && this.operationAddSubmitCallback) {
            try {
                this.operationAddSubmitCallback();
            } catch (e) {
            }
        }
        this.operationAddSubmitCallback = null;
        this.setState({
            showOperationAddForm: false,
        });
    }

    renderOperationAddForm() {
        let maintenanceNames = [];
        (this.state.maintenance.data || []).map(function (item) {
            maintenanceNames.push(item.number);
        });
        if (!_.isEmpty(this.state.maintenance)) {
            const maintenances = _.sortBy(_.filter(this.state.vehicle_works, {type: 'maintenance'}), 'maintenance_name');
            _.each(maintenances, (maintenance) => {
                maintenanceNames.push(_.toInteger(maintenance.maintenance_name));
            });
            maintenanceNames.push(_.toInteger(_.get(_.last(maintenances), 'maintenance_name')) + 1);
        }
        maintenanceNames = _.uniq(maintenanceNames);

        //maintenanceNames = _.difference(_.map(maintenanceNames, name => name.toString()), _.map(_.filter(this.state.vehicle_works, {type: 'maintenance'}), 'maintenance_name'));

        return (
            <KursOperationAddForm
                component="commdept"
                mode={this.state.operationAddFormUuid ? 'edit' : 'add'}
                uuid={this.state.operationAddFormUuid || 'create'}
                type={this.state.operationAddFormType}
                onSubmit={this.closeOperationAdd.bind(this, true)}
                onClose={this.closeOperationAdd.bind(this, false)}
                vehicleUuid={this.state.kurs_vehicle.vehicle_uuid}
                maintenanceNames={maintenanceNames}
                existingMaintenances={_.map(_.filter(this.state.vehicle_works, {type: 'maintenance'}), (item) => _.toNumber(item.maintenance_name))}
            />
        );
    }

    /**
     * @param {String} field
     * @param {*} value
     * @param force
     */
    async setValue(field, value, force = false, updateForm = true) {
        if (_.isString(value) && !value) {
            value = null;
        }

        if (!force && (this.props.mode === 'edit') && !value && field.indexOf('kurs_vehicle.general.malfunctions.') === 0) {
            this.requestOperationAdd(this.setValue.bind(this, field, false, true), 'repair');
        } else {
            updateForm && this.props.onUpdate();
            super.setValue(field, value);
        }

        if (field === 'kurs_vehicle.documents.without_compulsory_insurance') {
            this.setValue('kurs_vehicle.documents.osago_insurance_company_uuid', null);
            this.setValue('kurs_vehicle.documents.osago_number', null);
            this.setValue('kurs_vehicle.documents.osage_end_date', null);
        }

        if (field === 'kurs_vehicle.vehicle_mark_uuid') {
            this.setValue('kurs_vehicle.vehicle_model_uuid', null);
        }
    }

    getKursMalfunctions() {
        return this.isTrailer() ? _.filter(this.state.kurs_malfunctions, item => item.document.is_for_trailers) : _.filter(this.state.kurs_malfunctions, item => item.document.is_for_vehicles);
    }

    /***************************************************************
     * Общие сведения
     ***************************************************************/
    renderGeneral() {
        return (
            <div>
                <TabBlock size="lg">
                    <div className="tab__block-title">Неисправности ТС</div>
                    {this.getKursMalfunctions().map((malfunction) => {
                        return (
                            <Block key={malfunction.value} size="lg">
                                {this.checkbox(`kurs_vehicle.general.malfunctions.${malfunction.value}`, malfunction.label, {
                                    className: 'b-checkbox_right',
                                })}
                            </Block>
                        );
                    })}
                    <Block size="lg" className="b-block_right" title="Ориентир. дата ремонта">
                        {this.datepicker('kurs_vehicle.general.repair_date')}
                    </Block>
                    {(currentUser.can('com.rnis.dictionary.document', 'create') && currentUser.can('com.rnis.dictionary.dictionary.kurs_malfunctions', 'create')) ? (
                        <Block size="lg">
                            <Button size="lg" text="Добавить типовую неисправность" color="red" shadow="red"
                                    width="full"
                                    className="b-button-add-fault"
                                    onClick={::this.addMalfunction}/>
                        </Block>
                    ) : null}
                    <Block size="xl" title="Комментарий">
                        {this.textarea('kurs_vehicle.general.comment')}
                    </Block>
                </TabBlock>
                <TabBlock size="lg" withLeftBorder={true} withRightBorder={true}>
                    <div className="tab__block-title">Данные по ТС</div>
                    <Block size="lg" className="b-block_right" title="Госномер ТС *">
                        {this.textInput('kurs_vehicle.state_number')}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Дислокация *">
                        {this.select('kurs_vehicle.unit_uuid', this.state.units)}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Тип ТС">
                        {this.select('kurs_vehicle.vehicle_type_uuid', this.state.vehicle_types)}
                    </Block>
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right" title="Экологический класс ТС">
                            {this.select('kurs_vehicle.environmental_class_uuid', this.state.vehicle_environmental_classes)}
                        </Block>
                    ) : null}
                    <Block size="lg" className="b-block_right" title="Марка ТС">
                        {this.select('kurs_vehicle.vehicle_mark_uuid', this.state.vehicle_marks)}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Модель ТС">
                        {this.select('kurs_vehicle.vehicle_model_uuid', _.filter(this.state.vehicle_models, (model) => {
                            return _.get(model, 'document.vehicle_mark_uuid') === this.get('vehicle_mark_uuid');
                        }))}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Наименование по ПТС">
                        {this.textInput('kurs_vehicle.general.pts_name')}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Орган регистрации">
                        {this.select('kurs_vehicle.general.registration_unit_uuid', this.state.kurs_registration_units)}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Технич. состояние на момент инвентаризации">
                        {this.select('kurs_vehicle.general.technical_condition_uuid', this.state.kurs_technical_conditions)}
                    </Block>
                    <Block size="lg" className="b-block_right expand-top" title="Дата инвентаризации">
                        {this.datepicker('kurs_vehicle.general.inventarisation_date')}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Сезонность">
                        {this.select('kurs_vehicle.general.season_uuid', this.state.seasons)}
                    </Block>
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right expand-top" title="Водитель 1">
                            {this.selectAsync('kurs_vehicle.general.driver_uuid', this.loadUsersForSelect.bind(this, 'general.driver_uuid'))}
                        </Block>
                    ) : null}
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right expand-top" title="Водитель 2">
                            {this.selectAsync('kurs_vehicle.general.driver2_uuid', this.loadUsersForSelect.bind(this, 'general.driver2_uuid'))}
                        </Block>
                    ) : null}
                    {(window.RNIS_SETTINGS.columns_acc_and_control_fuel_consumption) ? (
                        <Block size="lg" className="b-block_right expand-top" title="Норматив расхода топлива">
                            {this.textInput('kurs_vehicle.acc_and_control_fuel_consumption', {
                                type: 'number',
                                positive: true,
                            })}
                        </Block>
                    ) : null }
                </TabBlock>

                <hr/>

                <TabBlock size="lg" className="_no-padding_top">
                    <TabBlock withBorder={true}>
                        <div className="tab__block-title">Принадлежность</div>
                        {(window.RNIS_SETTINGS.vehicle_editor_subsystem) ? (
                            <Block size="lg" className="b-block_right" title="Подсистема ТС">
                                {this.select('kurs_vehicle.vehicle_subsystem_uuid', this.state.vehicle_subsystems)}
                            </Block>
                        ) : null }
                        <Block size="lg" className="b-block_right" title="Право владения ТС">
                            {this.select('kurs_vehicle.general.tenure_uuid', this.state.kurs_tenures)}
                        </Block>
                        <Block size="lg" className="b-block_right" title="Балансодержатель">
                            {this.select('kurs_vehicle.general.balanceholder_uuid', this.state.units)}
                        </Block>
                        <Block size="lg" className="b-block_right" title="Договор (аренда)">
                            {this.textInput('kurs_vehicle.general.contract_number')}
                        </Block>
                        <Block size="lg" className="b-block_right" title="Дата окончания договора">
                            {this.datepicker('kurs_vehicle.general.contract_end_date')}
                        </Block>
                        <Block size="lg" className="b-block_right" title="Пролонгация аренды">
                            {this.select('kurs_vehicle.general.lease_prolongation_type_uuid', this.state.kurs_lease_prolongation_types)}
                        </Block>
                        <Block size="lg" className="b-block_right" title="Инвентарный номер">
                            {this.textInput('kurs_vehicle.general.inventory_number')}
                        </Block>
                        <Block size="lg" className="b-block_right" title="Гаражный номер">
                            {this.textInput('kurs_vehicle.general.garage_number')}
                        </Block>
                    </TabBlock>
                    <TabBlock>
                        <div className="tab__block-title">Фотографии ТС</div>
                        <Block size="lg">
                            <div className="photo">
                                <div className="photo__list">
                                    <div className="photo__manual">Размер: не более 20 Мб на файл</div>
                                    {this.get('general.photos', []).map(::this.renderPhoto)}
                                    <div className="photo__item add-photo">
                                        <FileReaderInput as="binary" id="my-file-input" onChange={::this.uploadFile}>
                                            <span className="add-photo__btn">
                                                +<br/>Добавить<br/>фото
                                            </span>
                                        </FileReaderInput>
                                    </div>
                                </div>
                            </div>
                        </Block>
                        <Lightbox
                            images={[
                                {src: this.state.openedImage},
                            ]}
                            isOpen={this.state.openedImage}
                            onClose={::this.closeImage}
                        />
                    </TabBlock>
                </TabBlock>
            </div>
        );
    }

    addMalfunction() {
        //this.props.router.push('/commdept/dictionaries/kurs_malfunctions/create');
        this.setState({
            showAddMalfunction: true,
        });
    }

    closeMalfunctionAdd(reload) {
        this.setState({
            showAddMalfunction: false,
        });
        if (reload) {
            this.loadDictionaries([
                'kurs_malfunctions',
            ], 'commdept');
        }
    }

    renderAddMalfunction() {
        return (
            <DictionaryEditor
                mode="add"
                uuid={null}
                dictionary="kurs_malfunctions"
                dictionaryMeta={this.state.malfunctionDictionaryMeta}
                onSubmit={this.closeMalfunctionAdd.bind(this, true)}
                onClose={this.closeMalfunctionAdd.bind(this, false)}
            />
        );
    }

    renderPhoto(photo, index) {
        return (
            <div key={photo.url} className="photo__item">
                <span className="photo__extension">{photo.extension}</span>
                <div className="photo__img" style={{backgroundImage: `url(${photo.url})`}}
                     onClick={this.openImage.bind(this, photo.url)}>
                    <span className="photo__title">{photo.name}</span>
                </div>
                <span className="photo__remove" onClick={this.deletePhoto.bind(this, index)}/>
            </div>
        )
    }

    openImage(url) {
        this.setState({
            openedImage: url,
        });
    }

    closeImage() {
        this.setState({
            openedImage: null,
        });
    }

    async loadUsersForSelect(fieldName, input, callback) {
        if (!input) {
            input = this.get(fieldName) || _.get(this.props.data, fieldName);
        }
        const result = await this.props.getUsers({
            filters: {
                withComponent: 'commdept',
            },
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
        });

        if (result.isOk) {
            callback(null, {
                options: _.sortBy(result.payload.items.map(i => ({
                    label: new User(i).getFullName(),
                    value: i.uuid,
                })), 'label'),
                complete: false
            });
        } else {
            result.showErrors();
        }
    }

    deletePhoto(index, e) {
        e.preventDefault();

        let kurs_vehicle = this.state.kurs_vehicle;
        let photos = _.get(kurs_vehicle, 'general.photos', []);
        photos.splice(index, 1);
        _.set(kurs_vehicle, 'general.photos', photos);
        this.setState({kurs_vehicle});

        this.props.onUpdate();
    }

    async uploadFile(e, results) {
        e.preventDefault();

        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        const allowedTypes = [
            'application/pdf',
            'image/png',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'image/jpeg',
        ];

        let hasError = false;

        results.forEach(result => {
            const [e, file] = result;
            formData.append('file', file);

            let errors = [];
            if (_.indexOf(allowedTypes, file.type) === -1) {
                errors.push('Недопустимый формат файла');
            }
            if ((file.size / (1024 * 1024)) >= 20) {
                errors.push('Недопустимый размер файла');
            }
            if (errors.length > 0) {
                alerts.error(errors.join('<br/>'));
                hasError = true;
            }
        });

        if (hasError) {
            return;
        }

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');
        const name = response.original_filename;
        const matches = /\.([a-z0-9]+)$/.exec(name);
        const extension = matches[1];

        let kurs_vehicle = this.state.kurs_vehicle;
        let photos = _.get(kurs_vehicle, 'general.photos', []);
        photos.push({
            url: value,
            name,
            extension,
        });
        _.set(kurs_vehicle, 'general.photos', photos);
        this.setState({kurs_vehicle});
        this.props.onUpdate();
    }

    async getUploadToken() {
        try {
            const response = await api.storage.getUploadToken();
            return response.payload;
        } catch (e) {
            console.log('Ошибка получения токена загрузки', e);
        }
    }

    /***************************************************************
     * БНСО
     ***************************************************************/
    renderBnso() {
        const list = this.state.kurs_vehicle.bnso || [];
        return (
            <div>
                {(list.length === 0 && currentUser.can('com.rnis.vehicles.bnso_to_vehicle', 'create')) ? (
                    <div className="add-link">
                        <a className="add-job b-icon-link b-icon-link_icon_plus" href="javascript:void(0)"
                           onClick={::this.addBnso}>Добавить
                            БНСО</a>
                    </div>
                ) : list.map(::this.renderBnsoItem)}
            </div>
        );
    }

    addBnso(e) {
        e.preventDefault();

        let kurs_vehicle = this.state.kurs_vehicle;
        kurs_vehicle.bnso = kurs_vehicle.bnso || [];
        kurs_vehicle.bnso.push({});

        this.setState({kurs_vehicle});
    }

    deleteBnso(index, e) {
        e.preventDefault();

        let kurs_vehicle = this.state.kurs_vehicle;
        kurs_vehicle.bnso.splice(index, 1);
        this.setState({kurs_vehicle});

        this.props.onUpdate();
    }

    bnsoListLoadDebounce = debounce(500, ::this.bnsoListLoadAsync);

    bnsoChange(e) {
        this.loadMotorhours();
        this.loadMileage();
    }

    async bnsoListLoadAsync(input, callback) {
        let activeBnso = false;
        let meta = {
            search: input,
            pagination: {
                page: 1,
                limit: 20,
            },
            filters: {
                withComponent: 'commdept',
                notInUse: true,
            }
        };
        if (this.state.state_number  && !input.length) {
            activeBnso = true;
            meta.search = this.state.state_number;
            delete meta.filters.notInUse;
        }

        const result = await this.props.getBnsoList(meta);

        if (result.isOk) {

            let bnso = _.sortBy(result.payload.items.map(item => ({
                value: item.uuid,
                label: item.bnso_code,
                document: item,
            })), 'label');

            callback(null, {
                options: bnso,
                complete: false
            });

            await this.setState({bnso});

            if (activeBnso) {
                this.loadMotorhours();
                this.loadMileage();
            }

        } else {
            result.showErrors();
        }
    }

    renderBnsoItem(bnso, index) {
        const document = bnso.uuid ? _.get(_.find(this.state.bnso, {value: bnso.bnso_uuid}), 'document') : null;

        return (
            <div key={index} className="hr_bottom">
                <div className="tab__block _lg _border_right">
                    <Block size="lg" className="b-block_right" title="Дата установки *">
                        {this.datepicker(`kurs_vehicle.bnso.${index}.installation_date`)}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Время установки">
                        {this.maskInput(`kurs_vehicle.bnso.${index}.installation_time`, '99:99')}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Дата демонтажа">
                        {this.datepicker(`kurs_vehicle.bnso.${index}.dismantling_date`)}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Время демонтажа">
                        {this.maskInput(`kurs_vehicle.bnso.${index}.dismantling_time`, '99:99')}
                    </Block>
                    <Block size="lg" className="b-block_right" title="БНСО *">
                        {/* {this.select(`kurs_vehicle.bnso.${index}.bnso_uuid`, bnso.uuid ? this.state.bnso : this.state.unused_bnso)}*/}
                        {this.selectAsync(`kurs_vehicle.bnso.${index}.bnso_uuid`, ::this.bnsoListLoadAsync, {
                           // onChange: ::this.bnsoChange
                            placeholder: 'Поиск',
                            cache: false,
                        })}
                    </Block>
                    <div className="add-link">
                        {currentUser.can('com.rnis.vehicles.bnso_to_vehicle', 'delete') ? (
                            <a className="remove-job b-icon-link b-icon-link_icon_basket" href="javascript:void(0)"
                               onClick={this.deleteBnso.bind(this, index)}>Удалить БНСО</a>
                        ) : null}
                        {((index === this.state.kurs_vehicle.bnso.length - 1) && (currentUser.can('com.rnis.vehicles.bnso_to_vehicle', 'create'))) ? (
                            <span>
                            <span className="add-link__separator"/>
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="javascript:void(0)"
                               onClick={::this.addBnso}>Добавить БНСО</a>
                        </span>
                        ) : null}

                    </div>
                </div>
                <div className="tab__block _lg">
                    {document ? (
                        <div>
                            <Block title="Тип БНСО">
                                {_.get(_.find(this.state.bnso_types, {value: document.bnso_type}), 'label')}
                            </Block>
                            <Block title="Производитель">
                                {_.get(_.find(this.state.bnso_manufacturers, {value: document.manufacturer}), 'label')}
                            </Block>
                            <Block title="Версия ПО">
                                {document.version}
                            </Block>
                            <Block title="Номер телефона">
                                {document.phone_number}
                            </Block>
                            <Block title="Номер SIM - карты">
                                {document.sim_card_number}
                            </Block>
                            <Block title="Мобильный оператор">
                                {_.get(_.find(this.state.bnso_operators, {value: document.mobile_operator}), 'label')}
                            </Block>
                            <Block title="Номер телефона 2">
                                {document.phone_number2}
                            </Block>
                            <Block title="Выход тревожной кнопки">
                                {document.alarm_number}
                            </Block>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    /***************************************************************
     * Технические данные
     ***************************************************************/
    renderTechnical() {
        console.log(this.props)
        return (
            <div>
                <TabBlock size="lg">
                    <div className="tab__block-title">Данные по ТС</div>
                    <Block size="lg" className="b-block_right" title="Год выпуска">
                        {this.textInput('kurs_vehicle.technical.release_year', {
                            type: 'number',
                        })}
                    </Block>
                    <Block size="lg" className="b-block_right" title="VIN">
                        {this.textInput('kurs_vehicle.technical.vin')}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Номер шасси">
                        {this.textInput('kurs_vehicle.technical.chassis_number')}
                    </Block>
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right" title="Номер кузова (кабина)">
                            {this.textInput('kurs_vehicle.technical.body_number')}
                        </Block>
                    ) : null}
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right" title="Номер КПП">
                            {this.textInput('kurs_vehicle.technical.gear_number')}
                        </Block>
                    ) : null}
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right" title="Модель, номер двигателя">
                            {this.textInput('kurs_vehicle.technical.engine_number')}
                        </Block>
                    ) : null}
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right" title="Тип двигателя">
                            {this.select('kurs_vehicle.technical.engine_type_uuid', this.state.kurs_engine_types)}
                        </Block>
                    ) : null}
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right" title="Мощность двигателя, л.с.\кВт">
                            {this.textInput('kurs_vehicle.technical.engine_power')}
                        </Block>
                    ) : null}
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right" title="Раб. объем двигателя куб. см">
                            {this.textInput('kurs_vehicle.technical.engine_volume', {
                                type: 'number',
                                min: 0,
                                positive: true,
                            })}
                        </Block>
                    ) : null}
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right" title="Основной ведущий мост (мосты), номер">
                            {this.textInput('kurs_vehicle.technical.drive_number')}
                        </Block>
                    ) : null}
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right" title="Ёмкость ТС">
                            {this.textInput('kurs_vehicle.vehicle_capacity_integer', {
                                type: 'number',
                                min: 0,
                                positive: true,
                            })}
                        </Block>
                    ) : null}
                </TabBlock>
                <TabBlock size="lg" className="_border_left _border_right">
                    <div className="tab__block-title">Технические характеристики по ТС</div>
                    <Block size="lg" className="b-block_right" title="Разрешенная макс масса, кг">
                        {this.textInput('kurs_vehicle.technical.max_weight', {
                            type: 'number',
                            min: 0,
                            positive: true,
                        })}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Масса без нагр., кг">
                        {this.textInput('kurs_vehicle.technical.max_weight_without_load', {
                            type: 'number',
                            min: 0,
                            positive: true,
                        })}
                    </Block>
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right" title="Максим. констр. скорость, км/ч">
                            {this.textInput('kurs_vehicle.technical.max_speed', {
                                type: 'number',
                                min: 0,
                                positive: true,
                            })}
                        </Block>
                    ) : null}
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right" title="Вид движителя">
                            {this.select('kurs_vehicle.technical.thruster_type_uuid', this.state.kurs_thruster_types)}
                        </Block>
                    ) : null}
                    <Block size="lg" className="b-block_right" title="Габаритные размеры, длина, мм">
                        {this.textInput('kurs_vehicle.technical.dimensions_x', {
                            type: 'number',
                            min: 0,
                            positive: true,
                        })}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Габаритные размеры, ширина, мм">
                        {this.textInput('kurs_vehicle.technical.dimensions_y', {
                            type: 'number',
                            min: 0,
                            positive: true,
                        })}
                    </Block>
                    <Block size="lg" className="b-block_right" title="Габаритные размеры, высота, мм">
                        {this.textInput('kurs_vehicle.technical.dimensions_z', {
                            type: 'number',
                            min: 0,
                            positive: true,
                        })}
                    </Block>
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right" title="Экологический класс">
                            {this.select('kurs_vehicle.technical.environmental_class_uuid', this.state.vehicle_environmental_classes)}
                        </Block>
                    ) : null}
                    <Block size="lg" className="b-block_right" title="Цвет">
                        {this.select('kurs_vehicle.technical.color_uuid', this.state.kurs_colors)}
                    </Block>
                    {!this.isTrailer() ? (
                        <Block size="lg" className="b-block_right" title="Маяк">
                            {this.textInput('kurs_vehicle.technical.beacon')}
                        </Block>
                    ) : null}
                    <Block size="lg" className="b-block_right expand-top" title="Категория ТС">
                        {this.select('kurs_vehicle.technical.vehicle_category_uuid', this.state.kurs_vehicle_categories)}
                    </Block>
                    <Block size="lg" className="b-block_right expand-top" title="Организация изготовитель">
                        {this.select('kurs_vehicle.technical.vehicle_manufacturer_uuid', this.state.kurs_vehicle_manufacturers)}
                    </Block>
                </TabBlock>

                <hr/>

                <TabBlock size="lg" withBorderLeft={true}>
                    {!this.isTrailer() ? (
                        <TabBlock className="_no-padding_top">
                            <div className="tab__block-title">Данные по ТС</div>
                            <Block size="lg" className="b-block_right" title="Объем топливного бака, л">
                                {this.textInput('kurs_vehicle.technical.fuel_tank_volume', {
                                    type: 'number',
                                    min: 0,
                                    positive: true,
                                })}
                            </Block>
                            <Block size="lg" className="b-block_right" title="Кол-во пассаж. мест">
                                {this.textInput('kurs_vehicle.technical.passengers_count', {
                                    type: 'number',
                                    min: 0,
                                    positive: true,
                                })}
                            </Block>
                        </TabBlock>
                    ) : null}
                    <TabBlock className="_no-padding_top" withBorder={true}>
                        <div className="tab__block-title">Гарантия</div>
                        <Block size="lg" className="b-block_right" title="Дата окончания гарантии">
                            {this.datepicker('kurs_vehicle.technical.warranty_end_date')}
                        </Block>
                        <Block size="lg" className="b-block_right" title="Значение">
                            {this.get('technical.warranty_end_type') ? (
                                this.textInput('kurs_vehicle.technical.warranty_end_value', {
                                    type: 'number',
                                    min: 0,
                                    positive: true,
                                })
                            ) : null}
                        </Block>
                        <Block size="lg" className="b-block_right" title=" ">
                            {this.select('kurs_vehicle.technical.warranty_end_type', [
                                {
                                    value: 'mileage',
                                    label: 'Пробег',
                                },
                                {
                                    value: 'machine_hours',
                                    label: 'Моточасы',
                                },
                            ])}
                        </Block>
                    </TabBlock>
                    {!this.isTrailer() ? (
                        <TabBlock>
                            <div className="tab__block-title">Тахограф</div>
                            <Block size="lg" title="">
                                {this.checkbox('kurs_vehicle.technical.require_tachograph', 'Подлежит обязательному оснащению')}
                            </Block>
                            {this.get('technical.require_tachograph') ? ([
                                <Block size="lg" className="b-block_right" key="tachograph_number" title="Номер">
                                    {this.textInput('kurs_vehicle.technical.tachograph_number')}
                                </Block>,
                                <Block size="lg" className="b-block_right" key="tachograph_installer_uuid"
                                       title="Установщик">
                                    {this.select('kurs_vehicle.technical.tachograph_installer_uuid', this.state.kurs_service_units)}
                                </Block>,
                                <Block size="lg" className="b-block_right" key="tachograph_next_verification_date"
                                       title="Дата след. поверки">
                                    {this.datepicker('kurs_vehicle.technical.tachograph_next_verification_date')}
                                </Block>,
                            ]) : null}
                        </TabBlock>
                    ) : null}
                </TabBlock>
            </div>
        );
    }

    isInFormula(field) {
        const formula = this.getFormula();
        if (!formula) {
            return false;
        }

        switch (formula.document.key) {
            case '1':
                return _.indexOf([
                    'technical.fuel_spent',
                ], field) !== -1;
            case '2':
                return _.indexOf([
                    'technical.fuel_spent',
                    'technical.fuel_spent_warm',
                ], field) !== -1;
            case '3':
                return _.indexOf([
                    'technical.fuel_spent',
                    'technical.fuel_spent_trailer',
                    'technical.trailer_weight',
                    'technical.fuel_spent_work',
                ], field) !== -1;
            case '4':
                return _.indexOf([
                    'technical.fuel_spent',
                    'technical.fuel_spent_work',
                    'technical.trailer_weight',
                    'technical.fuel_spent_additional',
                ], field) !== -1;
            case '5':
                return _.indexOf([
                    'technical.fuel_spent',
                    'technical.fuel_spent_additional_2',
                ], field) !== -1;
            case '6':
                return _.indexOf([
                    'technical.fuel_spent',
                    'technical.fuel_spent_2',
                ], field) !== -1;
            case '7':
                return _.indexOf([
                    'technical.fuel_spent',
                    'technical.fuel_spent_additional_2',
                ], field) !== -1;
        }

        return false;
    }

    /***************************************************************
     * Документы на ТС
     ***************************************************************/
    renderDocuments() {
        return (
            <div>
                <TabBlock size="lg" className="_no-padding_bottom">
                    <TabBlock className="_no-padding_top">
                        <div className="tab__block-title">ПТС</div>
                        <Block size="lg" className="b-block_right" title="Серия, номер">
                            {this.textInput('kurs_vehicle.documents.pts_number')}
                        </Block>
                        <Block size="lg" className="b-block_right" title="Дата выдачи">
                            {this.datepicker('kurs_vehicle.documents.pts_date')}
                        </Block>
                        <Block size="lg" className="b-block_right" title="Кем выдан">
                            {this.textInput('kurs_vehicle.documents.pts_issuer')}
                        </Block>
                    </TabBlock>
                    <TabBlock className="_no-padding_top">
                        <div className="tab__block-title">СТС</div>
                        <Block size="lg" className="b-block_right" title="Серия, номер">
                            {this.textInput('kurs_vehicle.documents.sts_number')}
                        </Block>
                        <Block size="lg" className="b-block_right expand-top" title="Дата выдачи">
                            {this.datepicker('kurs_vehicle.documents.sts_date')}
                        </Block>
                        <Block size="lg" className="b-block_right" title="Кем выдан">
                            {this.textInput('kurs_vehicle.documents.sts_issuer')}
                        </Block>
                    </TabBlock>
                </TabBlock>
                <TabBlock size="lg" withLeftBorder={true} withRightBorder={true}>
                    <Block size="xl" title="">
                        {this.checkbox('kurs_vehicle.documents.without_compulsory_insurance', 'Не подлежит обязательному страхованию')}
                    </Block>
                    {!this.get('documents.without_compulsory_insurance') ? (
                        <TabBlock className="_no-padding_top">
                            <div className="tab__block-title">Полис ОСАГО</div>
                            <Block size="lg" className="b-block_right" title="Страховая компанию">
                                {this.select('kurs_vehicle.documents.osago_insurance_company_uuid', this.state.kurs_insurance_companies)}
                            </Block>
                            <Block size="lg" className="b-block_right" title="Номер полиса">
                                {this.textInput('kurs_vehicle.documents.osago_number')}
                            </Block>
                            <Block size="lg" className="b-block_right" title="Дата окончания">
                                {this.datepicker('kurs_vehicle.documents.osage_end_date')}
                            </Block>
                        </TabBlock>
                    ) : null}
                    <TabBlock className="_no-padding_top">
                        <div className="tab__block-title">Полис КАСКО</div>
                        <Block size="lg" className="b-block_right" title="Страховая компанию">
                            {this.select('kurs_vehicle.documents.kasko_insurance_company_uuid', this.state.kurs_insurance_companies)}
                        </Block>
                        <Block size="lg" className="b-block_right" title="Номер полиса">
                            {this.textInput('kurs_vehicle.documents.kasko_number')}
                        </Block>
                        <Block size="lg" className="b-block_right expand-top" title="Дата окончания">
                            {this.datepicker('kurs_vehicle.documents.kasko_end_date')}
                        </Block>
                    </TabBlock>
                </TabBlock>

                <hr/>

                <TabBlock size="lg" className="_no-padding_bottom">
                    {!this.isTrailer() ? (
                        <TabBlock className="_no-padding_top _no-padding_bottom">
                            <div className="tab__block-title">Платон</div>
                            <Block size="lg" className="b-block_right" title="Номер БУ">
                                {this.textInput('kurs_vehicle.documents.platon_number')}
                            </Block>
                        </TabBlock>
                    ) : null}
                    <TabBlock className="_no-padding_bottom">
                        <div className="tab__block-title">Сканы документов</div>
                        {this.renderDocumentScans()}
                    </TabBlock>
                </TabBlock>
            </div>
        );
    }

    renderDocumentScans() {
        const list = this.get('documents.documents', []);

        if (list.length === 0) {
            return (
                <div className="add-link">
                    <a className="add-job b-icon-link b-icon-link_icon_plus" href="javascript:void(0)"
                       onClick={::this.addDocumentScan}>Добавить
                        документ</a>
                </div>
            );
        }
        return list.map(::this.renderDocumentScan);
    }

    addDocumentScan(e) {
        e.preventDefault();

        let kurs_vehicle = this.state.kurs_vehicle;
        let documents = _.get(kurs_vehicle, 'documents.documents', []);
        documents.push({});
        _.set(kurs_vehicle, 'documents.documents', documents);

        this.setState({kurs_vehicle});
    }

    deleteDocumentScan(index, e) {
        e.preventDefault();

        let kurs_vehicle = this.state.kurs_vehicle;
        let documents = _.get(kurs_vehicle, 'documents.documents', []);
        documents.splice(index, 1);
        _.set(kurs_vehicle, 'documents.documents', documents);

        this.setState({kurs_vehicle});
    }

    renderDocumentScan(bnso, index) {
        return (
            <div key={index}>
                <Block size="lg" className="b-block_right" title="Наименование">
                    {this.textInput(`kurs_vehicle.documents.documents.${index}.name`)}
                </Block>
                <Block size="lg" className="b-block_right" title=" ">
                    <div className="file-functions clearAfter">
                        <div className="file-open">
                            {this.get(`documents.documents.${index}.file`) ? ([
                                <a key="open" href={this.get(`documents.documents.${index}.file`)}
                                   target="_blank">Открыть</a>,
                                <br key="separator"/>,
                                <br key="separator2"/>,
                            ]) : null}
                        </div>
                        <div className="file-load">
                            <FileReaderInput as="binary" id="my-file-input"
                                             onChange={this.uploadDocumentScan.bind(this, index)}>
                                <a href="javascript:void(0)">Загрузить файл</a>
                            </FileReaderInput>
                        </div>
                    </div>
                </Block>
                <div className="add-link">
                    <a className="remove-job b-icon-link b-icon-link_icon_basket" href="javascript:void(0)"
                       onClick={this.deleteDocumentScan.bind(this, index)}>Удалить документ</a>
                    {(index === this.get('documents.documents', []).length - 1) ? (
                        <span>
                            <span className="add-link__separator"/>
                            <a className="add-job b-icon-link b-icon-link_icon_plus" href="javascript:void(0)"
                               onClick={::this.addDocumentScan}>Добавить документ</a>
                        </span>
                    ) : null}

                </div>
                {(index !== this.get('documents.documents', []).length - 1) ? <div className="hr"/> : null}
            </div>
        );
    }

    async uploadDocumentScan(index, e, results) {
        e.preventDefault();

        const tokenInfo = await this.getUploadToken();

        let formData = new FormData();

        results.forEach(result => {
            const [e, file] = result;
            formData.append('file', file);
        });

        formData.append('token', tokenInfo.token);

        const response = await api.storage.uploadFile(tokenInfo.upload_url, formData);
        const value = response.url.replace('http://', 'https://');

        let kurs_vehicle = this.state.kurs_vehicle;
        let documents = _.get(kurs_vehicle, 'documents.documents', []);
        documents[index].file = value;
        _.set(kurs_vehicle, 'documents.documents', documents);
        this.setState({kurs_vehicle});
        this.props.onUpdate();
    }

    getMaintenanceByNumber(maintenanceNumber) {
        const row = _.find(this.state.maintenance.data || [], {number: maintenanceNumber});
        if (row) {
            return row;
        }
        const repeated = _.find(this.state.maintenance.data || [], {is_repeated: true});
        if (!repeated) {
            return null;
        }
        if (maintenanceNumber <= repeated.number) {
            return null;
        }
        return repeated;
    }

    getNextServiceDate() {
        const lastMaintenance = _.last(_.sortBy(_.filter(this.state.vehicle_works, {type: 'maintenance'}), 'maintenance_name'));
        if (!lastMaintenance) {
            const lastServiceDate = this.get('service.is_new_vehicle') ? this.get('service.purchase_date') : this.get('service.last_service_date');
            const lastServiceNumber = this.get('service.is_new_vehicle') ? -1 : this.get('service.last_service_number');

            if (lastServiceDate) {
                const maintenance = this.getMaintenanceByNumber(1 + _.toInteger(lastServiceNumber));
                if (maintenance) {
                    return moment(lastServiceDate).add(maintenance.months, 'months').format(formats.DATE);
                }
            }
        } else {
            const maintenance = this.getMaintenanceByNumber(1 + _.toInteger(lastMaintenance.maintenance_name));
            if (lastMaintenance.date && maintenance) {
                return moment(lastMaintenance.date).add(maintenance.months, 'months').format(formats.DATE);
            }
        }
    }

    getNextServiceMileage() {
        const lastServiceMileage = this.get('service.is_new_vehicle') ? 0 : _.toInteger(this.get('service.last_service_mileage'));
        const lastServiceNumber = this.get('service.is_new_vehicle') ? -1 : this.get('service.last_service_number');

        const maintenance = this.getMaintenanceByNumber(1 + _.toInteger(lastServiceNumber));
        if (maintenance) {
            return lastServiceMileage + _.toInteger(maintenance[(this.state.maintenance.type === 'mileage') ? 'mileage_value' : 'machine_hours_value']);
        }
    }

    /***************************************************************
     * Регламент ТО
     ***************************************************************/
    renderService() {
        const maintenances = _.sortBy(_.filter(this.state.vehicle_works, {type: 'maintenance'}), 'maintenance_name');

        return (
            <div>
                <TabBlock size="lg" withRightBorder={true}>
                    <div className="tab__block-title">Обслуживание</div>
                    <Block size="lg" className="b-block_right" title="Способ обслуживания">
                        {this.select('kurs_vehicle.service.service_type_uuid', this.state.kurs_service_types)}
                    </Block>
                    {this.isContractServiceType() ? ([
                        <Block key="service_unit_uuid" size="lg" className="b-block_right"
                               title="Обслуживающая организация">
                            {this.select('kurs_vehicle.service.service_unit_uuid', this.state.kurs_service_units)}
                        </Block>,
                        <Block key="contract_number" size="lg" className="b-block_right" title="Договор №, от">
                            {this.textInput('kurs_vehicle.service.contract_number')}
                        </Block>,
                    ]) : null}
                </TabBlock>
                <TabBlock size="lg">
                    <div className="tab__block-title">Наработка</div>
                    <Block size="lg" className="b-block_right" title="Моточасы (путевой лист), ч.">
                        {this.state.motohours ?
                            <Input
                                value={this.state.motohours || '-'}
                                disabled={true}
                            /> : <Loader color="red" align="center"/>}
                    </Block>


                    <Block size="lg" className="b-block_right" title="Пробег (путевой лист), км">
                        {this.state.mileage ?
                            <Input
                                value={this.state.mileage || '-'}
                                disabled={true}
                            /> : <Loader color="red" align="center"/>}
                    </Block>
                </TabBlock>
                <TabBlock size="lg" withLeftBorder={true}>
                    <Block size="lg" className="" title="">
                        {this.checkbox('kurs_vehicle.service.is_new_vehicle', 'Новое ТС')}
                    </Block>
                    {(this.get('service.is_new_vehicle')) ? (
                        <Block size="lg" className="b-block_right" title="Дата приобретения">
                            {this.datepicker('kurs_vehicle.service.purchase_date')}
                        </Block>
                    ) : null}
                    {(!this.get('service.is_new_vehicle')) ? (
                        (this.get('service.is_purchase_date_not_editable')) ? (
                            <Block size="lg" className="b-block_right" title="Дата последнего ТО">
                                {moment(this.get('service.last_service_date')).format(formats.DATE)}
                            </Block>
                        ) : (
                            <Block size="lg" className="b-block_right" title="Дата последнего ТО">
                                {this.datepicker('kurs_vehicle.service.last_service_date')}
                            </Block>
                        )
                    ) : null}
                    {(!this.get('service.is_new_vehicle')) ? (
                        <Block size="lg" className="b-block_right" title="Пробег/моточасы посл. ТО">
                            {this.textInput('kurs_vehicle.service.last_service_mileage', {
                                type: 'number',
                                min: 0,
                                positive: true,
                            })}
                        </Block>
                    ) : null}
                    {(!this.get('service.is_new_vehicle')) ? (
                        <Block size="lg" className="b-block_right" title="Номер последнего ТО">
                            {this.select('kurs_vehicle.service.last_service_number', _.map(this.state.maintenance.data, (item) => ({
                                value: item.number,
                                label: `ТО ${item.number}`,
                            })))}
                        </Block>
                    ) : null}
                    <div className="tab__block-title">Сроки следующего планового ТО</div>
                    <Block size="lg" className="b-block_right" title="Дата ТО">
                        <div className="b-block__text_align-center">
                            {this.getNextServiceDate()}
                        </div>
                    </Block>
                    <Block size="lg" className="b-block_right" title="Пробег, моточасы">
                        <div className="b-block__text_align-center">
                            {this.getNextServiceMileage()}
                        </div>
                    </Block>
                </TabBlock>
                <TabBlock withTopBorder={true}>
                    <div className="tab__block-title">
                        Список ТО
                        <Button size="md" text="Указать факт ТО" color="white" shadow="gray" width="auto"
                                className="b-button-show-fact"
                                onClick={::this.requestOperationAdd}/>
                    </div>
                </TabBlock>
                <Block size="xl">
                    <div className="Table">
                        <TableContainer>
                            <table className="b-table">
                                <thead>
                                <tr>
                                    <th>№ ТО</th>
                                    <th>План. срок</th>
                                    {(this.state.maintenance.type === 'mileage') ? (
                                        <th>План. пробег</th>
                                    ) : null}
                                    {(this.state.maintenance.type === 'machine_hours') ? (
                                        <th>План. м/ч</th>
                                    ) : null}
                                    <th>Факт. срок</th>
                                    {(this.state.maintenance.type === 'mileage') ? (
                                        <th>Факт. пробег</th>
                                    ) : null}
                                    {(this.state.maintenance.type === 'machine_hours') ? (
                                        <th>Факт. м/ч</th>
                                    ) : null}
                                </tr>
                                </thead>
                                <tbody>
                                {(this.state.maintenance.data || []).map(::this.renderMaintenanceRow)}
                                {maintenances.map(::this.renderMaintenanceFactRow)}
                                {this.renderMaintenanceFactRow({
                                    maintenance_name: _.toInteger(_.get(_.last(maintenances), 'maintenance_name', 0)) + 1,
                                }, -1)}
                                </tbody>
                            </table>
                        </TableContainer>
                    </div>
                </Block>
            </div>
        );
    }

    isContractServiceType() {
        return _.get(_.find(this.state.kurs_service_types, {value: this.get('service.service_type_uuid')}), 'label') !== 'Хозяйственный';
    }

    renderMaintenanceRow(row, index) {
        const months = row.months;
        const years = Math.floor(months / 12);
        const planned = (months < 12) ? `${months} мес` : `${years} ${pluralize(years, 'лет', 'год', 'года', 'лет')}`;
        const fact = _.find(this.state.vehicle_works, {type: 'maintenance', maintenance_name: row.number.toString()});

        return (
            <tr key={index}>
                <td className="link">ТО {row.number}</td>
                <td>{planned}</td>
                {(this.state.maintenance.type === 'mileage') ? (
                    <td>{row.mileage_value}</td>
                ) : null}
                {(this.state.maintenance.type === 'machine_hours') ? (
                    <td>{row.machine_hours_value}</td>
                ) : null}
                <td>{fact ? moment(fact.date).format(formats.DATE) : null}</td>
                {(this.state.maintenance.type === 'mileage') ? (
                    <td>-</td>
                ) : null}
                {(this.state.maintenance.type === 'machine_hours') ? (
                    <td>-</td>
                ) : null}
            </tr>
        );
    }

    async deleteVehicleWork(uuid) {
        const response = await this.props.deleteVehicleWork({uuid});

        if (response.isOk) {
            this.loadMaintenance();
            this.loadVehicleWorks();
        } else {
            response.showErrors();
        }
    }

    renderMaintenanceFactRow(maintenance, index) {
        const maintenanceNumber = _.toInteger(maintenance.maintenance_name);
        const row = _.find(this.state.maintenance.data || [], {number: maintenanceNumber});
        if (row) {
            return null;
        }
        const repeated = _.find(this.state.maintenance.data || [], {is_repeated: true});
        if (!repeated) {
            return null;
        }
        if (maintenanceNumber <= repeated.number) {
            return null;
        }
        const months = repeated.months * (maintenanceNumber - repeated.number);

        const years = Math.floor(months / 12);
        const planned = (months < 12) ? `${months} мес` : `${years} ${pluralize(years, 'лет', 'год', 'года', 'лет')}`;

        return (
            <tr key={index}>
                <td className="link">ТО {maintenanceNumber}</td>
                <td>{planned}</td>
                {(this.state.maintenance.type === 'mileage') ? (
                    <td>{repeated.mileage_value * (maintenanceNumber - repeated.number)}</td>
                ) : null}
                {(this.state.maintenance.type === 'machine_hours') ? (
                    <td>{repeated.machine_hours_value * (maintenanceNumber - repeated.number)}</td>
                ) : null}
                <td>{maintenance.date ? moment(maintenance.date).format(formats.DATE) : null}</td>
                {(this.state.maintenance.type === 'mileage') ? (
                    <td>-</td>
                ) : null}
                {(this.state.maintenance.type === 'machine_hours') ? (
                    <td>-</td>
                ) : null}
            </tr>
        );
    }

    /***************************************************************
     * Учет з/ч и деталей
     ***************************************************************/
    renderDetails() {
        return (
            <div>
                <TabBlock withBorder={true}>
                    <div className="tab__block-title">Перечень работ</div>
                    <Block size="xl">
                        <TableContainer>
                            <div className="Table">
                                <table className="b-table">
                                    <thead>
                                    <tr>
                                        <th>Дата</th>
                                        <th>Тип работ</th>
                                        <th>Комментарий</th>
                                        <th>Документы</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.getVehicleWorks().map(::this.renderVehicleWorkRow)}
                                    </tbody>
                                </table>
                            </div>
                            <span className="add-line" onClick={::this.requestOperationAdd}>
                                Добавить строку
                            </span>
                        </TableContainer>
                    </Block>
                </TabBlock>
                <TabBlock>
                    <div className="tab__block-title">Перечень з/ч</div>
                    <Block size="xl">
                        <TableContainer>
                            <div className="Table">
                                <table className="b-table">
                                    <thead>
                                    <tr>
                                        <th>Группа учета</th>
                                        <th>Наименование детали</th>
                                        <th>Номенклатурный номер</th>
                                        <th>Количество</th>
                                        <th>Тип работ</th>
                                        <th>Дата списания</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.vehicle_works.map(::this.renderVehicleDetailsRow)}
                                    </tbody>
                                </table>
                            </div>
                            <span className="add-line" onClick={::this.requestOperationAdd}>
                                Добавить строку
                            </span>
                        </TableContainer>
                    </Block>
                </TabBlock>
            </div>
        )
    }

    getVehicleWorks() {
        const vehicleWorks = _.map(this.state.vehicle_works, (work) => {
            return {
                uuid: work.uuid,
                date: work.date,
                name: (work.type === 'maintenance') ? `ТО ${work.maintenance_name}` : _.get(_.find(this.state.kurs_vehicle_repair_types, {value: work.repair_type_uuid}), 'label'),
                comment: work.comment,
                documents: work.documents,
                editable: true,
                type: 3,
            };
        });

        const vehicleBnso = _.filter(_.flatten(_.map(this.state.kurs_vehicle.bnso || [], (bnso) => {
            const bnsoCode = _.get(_.find(this.state.bnso, {value: bnso.bnso_uuid}), 'label');

            return [
                {
                    uuid: bnso.uuid,
                    date: bnso.installation_date,
                    name: `Установка БНСО ${bnsoCode}`,
                    comment: null,
                    documents: [],
                    editable: false,
                    type: 2,
                },
                bnso.dismantling_date ? {
                    uuid: `dismantle:${bnso.uuid}`,
                    date: bnso.dismantling_date,
                    name: `Снятие БНСО ${bnsoCode}`,
                    comment: null,
                    documents: [],
                    editable: false,
                    type: 1,
                } : null,
            ];
        })));

        return _.sortBy(_.concat(vehicleWorks, vehicleBnso), ['date', 'type'], ['asc', 'asc']);
    }

    renderVehicleWorkRow(work) {
        return (
            <tr key={work.uuid}>
                <td>{work.editable ? (
                    <a href="javascript:void(0)"
                       onClick={this.updateVehicleWork.bind(this, work)}>{moment(work.date).format(formats.DATE)}</a>
                ) : (
                    moment(work.date).format(formats.DATE)
                )}
                </td>
                <td>{work.name}</td>
                <td>{work.comment}</td>
                <td>{(work.documents || []).map(::this.renderVehicleWorkDocument)}</td>
                <td>{work.editable ? (
                    <a href="javascript:void(0)" onClick={this.deleteVehicleWork.bind(this, work)}>x</a>
                ) : null}</td>
            </tr>
        );
    }

    updateVehicleWork(work) {
        this.setState({
            showOperationAddForm: true,
            operationAddFormType: 'details',
            operationAddFormUuid: work.uuid,
        });
    }

    async deleteVehicleWork(work) {
        alerts.prompt('Вы действительно хотите удалить выбранный объект?', '', async () => {
            const response = await this.props.deleteVehicleWork({
                uuid: work.uuid,
            });

            if (response.isOk) {
                this.loadVehicleWorks();
            } else {
                response.showErrors();
            }
        });
    }

    renderVehicleDetailsRow(work) {
        return (work.details || []).map(this.renderVehicleDetailRow.bind(this, work));
    }

    renderVehicleDetailRow(work, detail, index) {
        return (
            <tr key={`${work.uuid}.${index}`}>
                <td>{_.get(_.find(this.state.kurs_accounting_groups, {value: detail.accounting_group_uuid}), 'label')}</td>
                <td>{_.get(_.find(this.state.kurs_details, {value: detail.detail_uuid}), 'label')}</td>
                <td>{_.get(_.find(this.state.kurs_details, {value: detail.detail_uuid}), 'document.number')}</td>
                <td>{detail.amount}</td>
                <td>{(work.type === 'maintenance') ? `ТО ${work.maintenance_name}` : _.get(_.find(this.state.kurs_vehicle_repair_types, {value: work.repair_type_uuid}), 'label')}</td>
                <td>{moment(work.date).format(formats.DATE)}</td>
            </tr>
        );
    }

    renderVehicleWorkDocument(document, index) {
        return (
            <div key={index}>
                <a href={document.file} target="_blank">{document.name}</a>
            </div>
        );
    }

    /***************************************************************
     * Механизмы
     ***************************************************************/
    renderMechanisms() {
        return (
            <div>
                <TabBlock withBorder={true}>
                    <div className="tab__block-title">Модели механизмов</div>
                    <Block size="xl">
                        <TableContainer>
                            <div className="Table">
                                <table className="b-table">
                                    <thead>
                                    <tr>
                                        <th>Модель механизма</th>
                                        <th>Сезон</th>
                                        <th>Дата установки</th>
                                        <th>Дата снятия</th>
                                        <th>Функции</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {_.orderBy(_.filter(this.state.vehicle_mechanisms, mechanism => mechanism.mechanism_model_uuid), ['installation_date'], ['desc']).map(::this.renderVehicleMechanismRow)}
                                    </tbody>
                                </table>
                            </div>
                            <span className="add-line"
                                  onClick={::this.requestMechanismAdd}>Добавить модель механизма</span>
                        </TableContainer>
                    </Block>
                </TabBlock>
                <TabBlock>
                    <div className="tab__block-title">
                        Прицепное оборудование
                        {this.get('general.can_has_trailer') ? (
                            <Button size="md" text="Добавить прицеп" color="white" shadow="gray" width="auto"
                                    className="b-button-add-trailer"
                                    onClick={::this.requestTrailerAdd}/>
                        ) : null}
                    </div>
                    <Block size="xl">
                        {this.checkbox('kurs_vehicle.general.can_has_trailer', 'Возможность работы с прицепом')}
                    </Block>
                    <Block size="xl">
                        {this.get('general.can_has_trailer') ? (
                            <div className="Table">
                                <TableContainer>
                                    <table className="b-table">
                                        <thead>
                                        <tr>
                                            <th>Госномер</th>
                                            <th>Тип</th>
                                            <th>Дата установки</th>
                                            <th>Дата снятия</th>
                                            <th>Функции</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {_.orderBy(_.filter(_.filter(this.state.vehicle_mechanisms, (mechanism) => !mechanism.dismantle_date), mechanism => mechanism.trailer_uuid), ['installation_date'], ['desc']).map(::this.renderVehicleTrailerRow)}
                                        </tbody>
                                    </table>
                                </TableContainer>
                            </div>
                        ) : null}
                    </Block>
                </TabBlock>
                <TabBlock>
                    <div className="tab__block-title">
                        История работы с механизмами и прицепным оборудованием
                    </div>
                    <Block size="xl">
                        <div className="Table">
                            <TableContainer>
                                <table className="b-table">
                                    <thead>
                                    <tr>
                                        <th>№ п/п</th>
                                        <th>Госномер</th>
                                        <th>Модель механизма</th>
                                        <th>Дата установки</th>
                                        <th>Дата снятия</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {_.orderBy(this.state.vehicle_mechanisms, ['installation_date'], ['desc']).map(::this.renderVehicleMechanismHistoryRow)}
                                    </tbody>
                                </table>
                            </TableContainer>
                        </div>
                    </Block>
                </TabBlock>
            </div>
        );
    }

    renderVehicleMechanismRow(mechanism, index) {
        if (mechanism.dismantle_date && _.filter(this.state.vehicle_mechanisms, (item) => {
            return !item.dismantle_date && item.mechanism_model_uuid === mechanism.mechanism_model_uuid;
        }).length > 0) {
            return;
        }
        if (mechanism.dismantle_date && _.filter(this.state.vehicle_mechanisms, (item, index2) => {
            return index2 < index && item.mechanism_model_uuid === mechanism.mechanism_model_uuid;
        }).length > 0) {
            return;
        }

        if (mechanism.installation_date && _.filter(this.state.vehicle_mechanisms, (item) => {
            return (item.mechanism_model_uuid === mechanism.mechanism_model_uuid) && item.final_dismantle_date && (moment(item.final_dismantle_date).isSameOrAfter(moment(mechanism.installation_date)));
        }).length > 0) {
            return;
        }

        return (
            <tr key={mechanism.uuid}>
                <td>{_.get(_.find(this.state.kurs_mechanism_bindings, {value: mechanism.mechanism_model_uuid}), 'label')}</td>
                <td>{_.get(_.find(this.state.seasons, {value: mechanism.season_uuid}), 'label')}</td>
                <td>{mechanism.installation_date ? moment(mechanism.installation_date).format(formats.DATE) : null}</td>
                <td>{mechanism.dismantle_date ? moment(mechanism.dismantle_date).format(formats.DATE) : null}</td>
                <td>
                    {(!mechanism.installation_date) ? (
                        <Button size="md" text="Установить" shadow="red"
                                onClick={this.installMechanism.bind(this, mechanism.uuid, false)}/>
                    ) : ((!mechanism.dismantle_date) ? (
                        <Button size="md" text="Снять" shadow="red"
                                onClick={this.dismantleMechanism.bind(this, mechanism.uuid)}/>
                    ) : ([
                        <Button key="install" size="md" text="Установить" shadow="red"
                                onClick={this.installMechanism.bind(this, mechanism.uuid, true)}/>,
                        <Button key="final_dismantle" size="md" text="Удалить" shadow="red"
                                onClick={this.dismantleMechanismFinal.bind(this, mechanism.uuid)}/>,
                    ]))}
                </td>
            </tr>
        );
    }

    renderVehicleTrailerRow(mechanism, index) {
        return (
            <tr key={mechanism.uuid}>
                <td>{_.get(this.state.trailers, `${mechanism.trailer_uuid}.state_number`)}</td>
                <td>{_.get(_.find(this.state.vehicle_types, {value: _.get(this.state.trailers, `${mechanism.trailer_uuid}.vehicle_type_uuid`)}), 'label')}</td>
                <td>{mechanism.installation_date ? moment(mechanism.installation_date).format(formats.DATE) : null}</td>
                <td>{mechanism.dismantle_date ? moment(mechanism.dismantle_date).format(formats.DATE) : null}</td>
                <td>
                    {(!mechanism.installation_date) ? (
                        <Button size="md" text="Установить" shadow="red"
                                onClick={this.installMechanism.bind(this, mechanism.uuid, false)}/>
                    ) : ((!mechanism.dismantle_date) ? (
                        <Button size="md" text="Снять" shadow="red"
                                onClick={this.dismantleMechanism.bind(this, mechanism.uuid)}/>
                    ) : null)}
                </td>
            </tr>
        );
    }

    renderVehicleMechanismHistoryRow(mechanism, index) {
        return (
            <tr key={mechanism.uuid}>
                <td>{index + 1}</td>
                <td>{_.get(this.state.trailers, `${mechanism.trailer_uuid}.state_number`)}</td>
                <td>{_.get(_.find(this.state.kurs_mechanism_bindings, {value: mechanism.mechanism_model_uuid}), 'label')}</td>
                <td>{mechanism.installation_date ? moment(mechanism.installation_date).format(formats.DATE) : null}</td>
                <td>{mechanism.dismantle_date ? moment(mechanism.dismantle_date).format(formats.DATE) : null}</td>
            </tr>
        );
    }

    async getVehicleTasksInWork() {
        const response = await this.props.getTasks({
            filters: {
                withVehicle: this.get('uuid'),
                withStatus: _.get(_.find(this.state.kurs_task_statuses, {label: 'В работе'}), 'value'),
            },
        });

        if (response.isOk) {
            return response.payload.items;
        } else {
            response.showErrors();
            return [];
        }
    }

    async installMechanism(uuid, installAsNew = false) {
        const tasks = await this.getVehicleTasksInWork();
        if (tasks.length === 0) {
            let mechanism = _.find(this.state.vehicle_mechanisms, {uuid});
            mechanism.installation_date = moment().format(formats.DATE_API);
            mechanism.dismantle_date = null;

            let response = null;
            if (installAsNew) {
                response = await this.props.createVehicleMechanism(mechanism);
            } else {
                response = await this.props.updateVehicleMechanism(mechanism);
            }
            if (response.isOk) {
                alerts.success('Механизм установлен');
                this.loadVehicleMechanisms();
            } else {
                alerts.error('Ошибка установки механизма');
            }
        } else {
            alerts.error('Невозможно установить механизм, пока у ТС есть задания в работе');
        }
    }

    async dismantleMechanism(uuid) {
        let mechanism = _.find(this.state.vehicle_mechanisms, {uuid});
        mechanism.dismantle_date = moment().format(formats.DATE_API);

        const response = await this.props.updateVehicleMechanism(mechanism);
        if (response.isOk) {
            alerts.success('Механизм снят');
            this.loadVehicleMechanisms();
        } else {
            alerts.error('Ошибка снятия механизма');
        }
    }

    async dismantleMechanismFinal(uuid) {
        let mechanism = _.find(this.state.vehicle_mechanisms, {uuid});
        mechanism.final_dismantle_date = moment().format(formats.DATE_API);

        const response = await this.props.updateVehicleMechanism(mechanism);
        if (response.isOk) {
            alerts.success('Механизм удален');
            this.loadVehicleMechanisms();
        } else {
            alerts.error('Ошибка удаления механизма');
        }
    }

    requestMechanismAdd() {
        this.setState({
            showMechanismAddForm: true,
        });
    }

    closeMechanismAdd(withSubmit = false) {
        if (withSubmit) {
            this.loadVehicleMechanisms();
        }
        this.setState({
            showMechanismAddForm: false,
        });
    }

    renderMechanismAddForm() {
        return (
            <KursMechanismAddForm
                mode="add"
                uuid="create"
                onSubmit={this.closeMechanismAdd.bind(this, true)}
                onClose={this.closeMechanismAdd.bind(this, false)}
                vehicleUuid={this.state.kurs_vehicle.vehicle_uuid}
                existing={_.map(_.filter(this.state.vehicle_mechanisms, (mechanism) => {
                    return !mechanism.dismantle_date;
                }), 'mechanism_model_uuid')}
            />
        );
    }

    requestTrailerAdd() {
        this.setState({
            showTrailerAddForm: true,
        });
    }

    closeTrailerAdd(withSubmit = false) {
        if (withSubmit) {
            this.loadVehicleMechanisms();
        }
        this.setState({
            showTrailerAddForm: false,
        });
    }

    renderTrailerAddForm() {
        return (
            <KursTrailerAddForm
                mode="add"
                uuid="create"
                onSubmit={this.closeTrailerAdd.bind(this, true)}
                onClose={this.closeTrailerAdd.bind(this, false)}
                vehicleUuid={this.state.kurs_vehicle.vehicle_uuid}
            />
        );
    }

    /***************************************************************
     * Список заданий
     ***************************************************************/
    renderTasks() {
        if (!this.state.kurs_vehicle.uuid) {
            return null;
        }

        return (
            <KursVehicleTasksForm
                uuid={this.state.kurs_vehicle.uuid}
            />
        );
    }

    renderFuel() {
        if (!this.state.kurs_vehicle.vehicle_uuid) {
            return null;
        }

        return (
            <KursVehicleFuel
                uuid={this.state.kurs_vehicle.vehicle_uuid}
            />
        );
    }

    getFormula() {
        return _.first(this.state.kurs_formulas || [], (formula) => {
            const vehicle_models = JSON.parse(formula.document.vehicle_models || '[]');
            return _.indexOf(vehicle_models, this.get('vehicle_model_uuid'));
        });
    }
}
