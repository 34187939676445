import React, {Component} from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapLayer from "components/ui/map/base/layer";
import {connect} from "react-redux";
import {getStopPointsWithBounds} from "store/reducers/routes/routes";
import StopPointMarker from "components/ui/map/markers/stop-point-marker";
import StopPointsEditor from "components/modules/geo/stop-points/editor";
import MapModalStopPointRoutes from "components/ui/map/Modal/MapModalStopPointRoutes";

@propTypes({
    onStopPointClick: PropTypes.func,
    onStopPointDblClick: PropTypes.func,
    setSelectedRoutes: PropTypes.func,
})

@defaultProps({
    onStopPointClick: null,
    onStopPointDblClick: () => {
    },
})

@connect((state) => ({}), {getStopPointsWithBounds})

export default class StopPointsMtaLayer extends AbstractMapLayer {

    state = {
        stopPoints: [],
        editorUuid: null,
        routesStopPointUuid: null,
    };

    loadStopPointsCallback = ::this.loadStopPoints;

    componentWillMount() {
        super.componentWillMount();

        this.props.leafletMap.on('moveend', this.loadStopPointsCallback);

        this.loadStopPoints();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.props.leafletMap.off('moveend', this.loadStopPointsCallback);
    }

    async loadStopPoints() {
        const boundingBox = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getBoundingBox() : this.props.map.getBoundingBox();
        const zoom = this.props.map.getWrappedInstance ? this.props.map.getWrappedInstance().getZoom() : this.props.map.getZoom();
        const response = await this.props.getStopPointsWithBounds(boundingBox, zoom);

        if (response.isOk) {
            this.setState({
                stopPoints: response.payload.items,
            });
        } else {
            response.showErrors();
        }
    }

    onEditClick(uuid) {
        this.setState({
            editorUuid: uuid,
        });
    }

    closeEditor() {
        this.setState({
            editorUuid: null,
        });
    }

    getEditor() {
        return (
            <StopPointsEditor
                mode="edit"
                uuid={this.state.editorUuid}
                onSubmit={::this.closeEditor}
                onClose={::this.closeEditor}
            />
        );
    }

    getStopPointRoutes() {
        return (
            <MapModalStopPointRoutes
                stopPointUuid={this.state.routesStopPointUuid}
                onClose={::this.stopPointRoutesClose}
                setSelectedRoutes={this.props.setSelectedRoutes}
            />
        );
    }

    render() {
        const editor = this.state.editorUuid ? this.getEditor() : null;
        const stopPointRoutes = this.state.routesStopPointUuid ? this.getStopPointRoutes() : null;

        return (
            <div>
                {editor}
                {stopPointRoutes}
                <div style={{display: 'none'}}>
                    {this.state.stopPoints.map(::this.renderStopPoint)}
                </div>
            </div>
        );
    }

    renderStopPoint(stopPoint) {
        return (
            <StopPointMarker
                key={stopPoint.uuid}
                {...this.props}
                latitude={stopPoint.latitude}
                longitude={stopPoint.longitude}
                item={stopPoint}
                onClick={this.props.onStopPointClick ? this.onStopPointClick.bind(this, stopPoint.uuid) : null}
                onDblClick={this.props.onStopPointDblClick.bind(this, stopPoint.uuid, stopPoint.latitude, stopPoint.longitude)}
                onEditClick={(!this.props.withoutEdit) ? this.onEditClick.bind(this, stopPoint.uuid) : null}
                onRoutesClick={this.onRoutesClick.bind(this, stopPoint.uuid)}
                onForwardAddClick={this.props.onStopPointForwardAddClick}
                onReverseAddClick={this.props.onStopPointReverseAddClick}
            />
        );
    }

    onStopPointClick(uuid, e) {
        this.props.onStopPointClick(e, uuid);
    }

    onRoutesClick(uuid) {
        this.setState({
            routesStopPointUuid: uuid,
        });
    }

    stopPointRoutesClose() {
        this.props.setSelectedRoutes([]);
        this.setState({
            routesStopPointUuid: null,
        });
    }

}