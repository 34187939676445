import {List, Map} from 'immutable';
import {CycleFetch} from 'helpers/api';
import {api} from 'helpers/api';
import _ from 'lodash';
import {success, error} from 'helpers/response';
import {User} from "helpers/user";

const initialState = new Map({
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    }

    return state;
}

export const getStopPoints = (meta) => async (dispatch) => {
    try {
        const response = await api.geo.getStopPoints(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getStopPoint = (uuid) => async (dispatch) => {
    try {
        const response = await api.geo.getStopPoint(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const createStopPoint = (data) => async (dispatch) => {
    try {
        const response = await api.geo.createStopPoint(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const updateStopPoint = (data) => async (dispatch) => {
    try {
        const response = await api.geo.updateStopPoint(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const deleteStopPoint = (data) => async (dispatch) => {
    try {
        const response = await api.geo.deleteStopPoint(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const getClosestStopPoint = (latitude, longitude) => async (dispatch, state) => {
    try {
        const response = await api.geo.getClosestStopPoint(latitude, longitude);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
