import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import {connect} from 'react-redux';
import _ from 'lodash';

import BaseTableComponent from 'components/base/base_table';
import Page from 'components/ui/page';
import IconButton from 'components/ui/icon-button';

import {mapDatatablesRequestToMeta} from 'helpers/api';

import './unit-permissions.less';
import * as alerts from "helpers/alerts";
import TableContainer from "components/ui/Table/Container/TableContainer";
import Column from "components/ui/column";
import AppearTransition from "components/ui/transitions/appear";
import UnitPermissionEditor from "./editor";

import currentUser from 'helpers/current-user';
import {getUnits} from "store/reducers/organizational_units/organizational_units";
import systems from "dictionaries/systems";
import {getUnitPermissions} from "store/reducers/unit-permissions/unit-permissions";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";

@connect(state => ({}), {getUnits, getUnitPermissions, getEntityNames})

export default class UnitPermissionsComponent extends BaseTableComponent {

    constructor(props) {
        super(props);

        Object.assign(this.state, {
            columns: List(this.getColumns()),
            editorUuid: null,
            showEditorModal: false,
            related: new EntityList,
            permissions: [],
        });
    }

    componentWillUpdate(props) {
        const uuid = props.params.uuid;
        const newEditorUuid = (uuid && uuid !== 'create') ? uuid : null;
        if (this.state.editorUuid !== newEditorUuid || this.state.showEditorModal !== !!uuid) {
            this.setState({
                showEditorModal: !!uuid,
                editorUuid: newEditorUuid,
            });
        }
    }

    render() {
        let table = this.renderTable();

        const editor = this.state.showEditorModal
            ? (
                <UnitPermissionEditor
                    {...this.props}
                    key="editor"
                    onClose={::this.closeEditor}
                    onSubmit={::this.submitEditor}
                    uuid={this.state.editorUuid}
                />
            ) : '';


        return (
            <Page title={`${window.RNIS_SETTINGS.operator_title || systems.operator} → Структурные права`}
                  pageId="UnitPermissions"
                  headerActions={this.renderHeaderActions()}
                  headerContents={this.renderHeaderContents()}>

                <TableContainer>
                    {table}
                </TableContainer>

                <AppearTransition>
                    {editor}
                </AppearTransition>
            </Page>
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Предприятие-владелец')
                .fromField('name'),

            new Column('Распространение структурных прав')
                .denyOrder()
                .denyColumnFilter()
                .withDrawer((item) => {
                    return _.join(_.map(_.filter(this.state.permissions, {base_unit_uuid: item.uuid}), (row) => {
                        return this.state.related.get(row.under_unit_uuid);
                    }), ', ');
                }),

        ]);
    }

    async closeEditor() {
        await this.props.router.push('/operator/permissions/unit_permissions');
    }

    async submitEditor() {
        this.reload();
        await this.closeEditor();
    }

    async showEditor() {
        this.showEditorWithUuid();
    }

    async showEditorWithUuid(uuid = null) {
        await this.closeEditor();
        this.props.router.push(`/operator/permissions/unit_permissions/${uuid || 'create'}`);
    }

    onDblClick(data) {
        if (data.uuid && currentUser.can('com.rnis.auth.permission.unit_permission', 'update')) {
            this.showEditorWithUuid(data.uuid);
        }
    }

    async editSelected() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            this.showEditorWithUuid(selected.uuid);
        }
    }

    async loadCallback(request, drawCallback) {
        const meta = mapDatatablesRequestToMeta(request, this.state.columns, this.state.showTableFilters, this.state.showDeleted);
        const response = await this.props.getUnits(meta);

        if (response.isOk) {
            const data = response.payload.items;

            const json = {
                draw: request.draw,
                data,
                recordsFiltered: response.data.headers.meta.pagination.total,
                recordsTotal: response.data.headers.meta.pagination.total
            };

            drawCallback(json);

            this.selectedRowsRecalc();
            this.setState({success: true});
            this.loadRelated(data, drawCallback, json);
        } else {
            response.showErrors();
        }
    }

    async loadRelated(data, drawCallback, json) {
        const response = await this.props.getUnitPermissions(_.map(data, 'uuid'));

        if (response.isOk) {
            const related = await this.loadRelatedEntities(response.payload.items);

            await this.setState({
                permissions: response.payload.items,
                related,
            });

            drawCallback(json);
        } else {
            response.showErrors();
        }
    }

    async loadRelatedEntities(data) {
        const units = _.map(_.uniq(_.filter(_.map(data, 'under_unit_uuid'))), (uuid) => ({
            class: 'App\\Model\\Unit',
            uuid: uuid,
            source: 'organizational_units',
        }));

        const response = await this.props.getEntityNames(units);

        if (response.isOk) {
            return new EntityList(response);
        }
    }

    getCreateButton() {
        return null;
    }

    renderHeaderActions() {
        return [
        ];
    }
}