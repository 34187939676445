import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import {Link} from "react-router";
import classNames from 'classnames';

import './modal-top-menu-list-item.less';

@propTypes({
    href: PropTypes.string,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
})

@defaultProps({
    href: '#',
    className: '',
    innerClassName: '',
    title: '',
    onClick: null,
})

export default class ModalTopMenuListItem extends Component {
    render() {
        const className = classNames('top-menu_modal__link b-icon-link b-icon-link_params', this.props.className);

        return (
            <li className="top-menu_modal__item">
                <Link to={this.props.href} className={className} onClick={::this.onClick}/>
            </li>
        );
    }

    onClick(e) {
        if (this.props.onClick) {
            e.preventDefault();

            this.props.onClick();
        }
    }
}