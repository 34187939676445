import React, {cloneElement, Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import classNames from 'classnames';
import _ from 'lodash';

import './tabs.less';

@propTypes({})

@defaultProps({})

export default class Tabs extends Component {
    state = {
        selectedIndex: 0,
    };

    onTabSelect(index) {
        this.setState({
            selectedIndex: index,
        });
    }

    getChildren() {
        const {children} = this.props;

        return _.map(children, (child, index) => {
            if (!child) {
                return null;
            }

            const props = {
                index,
                selected: this.state.selectedIndex === index,
                onSelect: ::this.onTabSelect,
            };

            return cloneElement(child, props);
        });
    }

    render() {
        return (
            <div className="tabs clearAfter">
                {this.getChildren()}
            </div>
        );
    }
}