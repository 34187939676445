import React, {Component} from 'react';
import systems from "dictionaries/systems";
import randomColor from 'randomcolor';

import WidgetPage from '../base-components/WidgetPage';
import BaseAnalyticsComponent from "components/modules/analytics/base";
import SpeedViolationsLineWidget from "components/modules/analytics/passenger-transportation/widgets/SpeedViolationsLineWidget/index";
import SpeedViolationsWidget from "components/modules/analytics/passenger-transportation/widgets/SpeedViolationsWidget/index";
import VehiclesBnsoWidget from "components/modules/analytics/passenger-transportation/widgets/VehiclesBnsoWidget/index";
import UnitsTableWidget from "components/modules/analytics/passenger-transportation/widgets/UnitsTableWidget/index";
import KursRegularityWidget from "components/modules/analytics/passenger-transportation/widgets/KursRegularityWidget/index";
import ConvoyDriverScoreWidget from "components/modules/analytics/road-maintenance/widgets/ConvoyDriverScoreWidget/index";
import UnitPercentTable from "components/modules/analytics/unit-percent-table";
import UnitCountVehicles from "components/modules/analytics/unit-count-vehicles";

import { UnitPercentType } from 'components/modules/kurs/tasks_day/UnitPercentType';
import ZoneViolationsExitCount from "components/modules/analytics/passenger-transportation/widgets/ZoneViolationsExitCount/index";
import ZoneViolationsExitCompany from "components/modules/analytics/passenger-transportation/widgets/ZoneViolationsExitCompany/index";

import download from 'downloadjs'
import moment from "moment";
import formats from "dictionaries/formats";
import {api} from "helpers/api"
import {makeResponse} from "helpers/response";

import {connect} from 'react-redux';

const convoyDriverScoreWidgetBarColor = randomColor();

@connect(state => ({}), {
    },
    null, {withRef: true}
)

export default class KursAnalytics extends BaseAnalyticsComponent {

    constructor(props) {
        super(props);

        if (window.RNIS_SETTINGS.CITY_MURMANSK) {
            this.widgetItems = [
                { id: 'unitPercentType', name: 'Процент выполнения работ', widget: this.unitPercentType, checked: true },
                { id: 'unitPercentTable', name: 'Процент выполнения работ', widget: this.unitPercentTable.bind(this), checked: true },
                { id: 'unitCountVehicles', name: 'Количество ТС на заданиях', widget: this.unitCountVehicles.bind(this), checked: true },
            ];
        } else {
            this.widgetItems = [
                { id: 'regularityWidget', name: 'Техники в работе', widget: this.regularityWidget, checked: true },
                { id: 'speedViolationsLineWidget', name: 'Количество нарушений скоростного режима', widget: this.speedViolationsLineWidget, checked: true },
                { id: 'speedViolationsWidget', name: 'Нарушители скоростного режима', widget: this.speedViolationsWidget, checked: true },
                { id: 'vehiclesBnsoWidget', name: 'Оснащенность ГЛОНАСС', widget: this.vehiclesBnsoWidget, checked: true },
                { id: 'unitsTableWidget', name: 'Статистика по предприятиям', widget: this.unitsTableWidget, checked: true },
            ];

            if(window.RNIS_SETTINGS.CITY_MO) {
                this.widgetItems.push({ id: 'convoyDriverScoreWidget', name: 'Баллы водителей по предприятию', widget: this.convoyDriverScoreWidget, checked: true});
            }
            if(window.RNIS_SETTINGS.CITY_TULA) {
                this.widgetItems.push({ id: 'zoneViolationsExitCount', name: 'Количество выходов из геозоны', widget: this.zoneViolationsExitCount, checked: true })
                this.widgetItems.push({ id: 'zoneViolationsExitCompany', name: 'Выходы из геозоны по предприятиям', widget: this.zoneViolationsExitCompany, checked: true })
            }

        }

        const roadTitle = _.find(window.RNIS_SETTINGS.CUSTOMMENUSTATE, ['id', '161']).text;
        this.title = `${systems.analytics} → ${roadTitle}`;
        this.setRef = this.setRef.bind(this);
    }

    setRef(node) {
        this.tableAnalytic = node;
    }

    convoyDriverScoreWidget(props) {
        return <ConvoyDriverScoreWidget {...props} barChartFillColor={convoyDriverScoreWidgetBarColor}/>
    }

    regularityWidget(props) {
        return <KursRegularityWidget {...props} component="road"/>
    }

    speedViolationsLineWidget(props) {
        return <SpeedViolationsLineWidget {...props} component="road"/>
    }

    speedViolationsWidget(props) {
        return <SpeedViolationsWidget {...props} component="road"/>
    }

    vehiclesBnsoWidget(props) {
        return <VehiclesBnsoWidget {...props} component="road"/>
    }

    unitsTableWidget(props) {
        return <UnitsTableWidget {...props} component="road"/>
    }

    unitPercentType(props) {
        return <UnitPercentType {...props} />
    }

    unitPercentTable(props) {
        return <UnitPercentTable {...props} setRef={this.setRef} />     
    }

    unitCountVehicles(props) {
        return <UnitCountVehicles {...props} />     
    }

    exportToXls() {
        if (!this.tableAnalytic) return;

        const table = $(this.tableAnalytic);
        this.exportToXlsSimple(table.html());
    }

    zoneViolationsExitCount(props) {
        return <ZoneViolationsExitCount {...props} component="road"/>
    }

    zoneViolationsExitCompany(props) {
        return <ZoneViolationsExitCompany {...props} component="road"/>
    }

    async exportToXlsSimple(content) {

        const response = await makeResponse(() => {
            return api.converter.convertHtmlToXls(content);
        });

        if (response.isOk) {
            const content = response.payload.content;
            download(`data:application/excel;base64,${content}`, `Экспорт ${moment().format(formats.DATETIME)}.xls`);
        } else {
            response.showErrors();
        }
    }

    render() {

        return (
            <WidgetPage cacheKey="kurs"
                        title={this.title}
                        widgetItems={this.widgetItems}
                        withExportTable={window.RNIS_SETTINGS.CITY_MURMANSK ? true : false}
                        exportToXls={::this.exportToXls}
            />
        );
    }
}