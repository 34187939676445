import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import classNames from 'classnames';

import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import * as alerts from "helpers/alerts";
import PageModal from 'components/ui/page-modal';
import {deleteRouteVariant, getRouteVariants} from "store/reducers/routes/route_variants";
import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import TableContainer from "components/ui/Table/Container/TableContainer";
import SlideLeftTransition from "components/ui/transitions/slide-left";
import VariantPointsEditor from "components/modules/kiutr/routes/variant_points_editor";
import KiutrIntervalMapsComponent from "components/modules/kiutr/routes/interval_maps";
import KiutrRouteVariantViewComponent from "components/modules/kiutr/routes/view/variant_view";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import './style.less';
import { resizeModals } from 'helpers/modal-size';
import { events } from 'dom-helpers';
import TableComponent from "components/ui/Table/Component/TableComponent";

let resizeId;

@propTypes({
    routeUuid: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
})

@connect(state => ({}), {getRouteVariants, deleteRouteVariant})

export default class KiutrScheduleRouteVariantsComponent extends BaseTableWithEditorComponent {

    title = '';

    static childContextTypes = {
        resizeModals: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.onResize = this.onResize.bind(this);
    }

    getChildContext() {
        return { resizeModals: this.resizeModals };
    }

    componentWillMount() {
        events.on(window, 'resize', this.onResize);
    }

    async componentWillReceiveProps(props) {
        setTimeout(() => {
            this.resizeModals();
        }, 500);
    }

    componentWillUnmount() {
        events.off(window, 'resize', this.onResize);
    }

    onResize() {
        clearTimeout(resizeId);
        resizeId = setTimeout(this.resizeModals, 1000);
    }


    resizeModals() {
        const ignoreClasses = ['add-time-interval-modal', 'add-stopping-point-modal'];
        resizeModals('.b-modal', 'b-modal__collapse', 'move-right', ignoreClasses);
    }

    async componentWillUpdate(props, state) {
        if (props.params) {
            const uuid = props.params.uuid2;
            const newEditorUuid = (uuid && uuid !== 'create') ? uuid : null;
            if (state.editorUuid !== newEditorUuid || state.showEditorModal !== !!uuid) {
                this.setState({
                    showEditorModal: !!uuid,
                    editorUuid: newEditorUuid
                });
            }
        }
    }

    getBaseUrl() {
        return `/${this.props.params.component}/schedules/${this.props.routeUuid}`;
    }

    getEditor() {
        return (
            <KiutrIntervalMapsComponent
                routeUuid={this.props.routeUuid}
                routeVariantUuid={this.state.editorUuid}
                onClose={::this.closeEditor}
                className="b-modal-schedule"
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Наименование')
                .fromField('name')
                .withDrawer(item => item.is_default ? `<strong>${item.name}</strong><br/><i>${item.comment || ''}</i>` : `${item.name}<br/><i>${item.comment || ''}</i>`),

        ]);
    }

    async deleteItem(data) {
    }

    async loadData(meta) {
        meta.filters.withRoute = this.props.routeUuid;
        return await this.props.getRouteVariants(meta);
    }

    renderTable() {
        const className = classNames({
            'show-table-filters': this.state.showTableFilters,
        });

        return (
            <div className={className}>
                <TableComponent
                    ref="table"
                    select={this.select}
                    columns={this.state.columns}
                    loadCallback={::this.loadCallbackMiddleware}
                    onColsReordered={::this.onColsReordered}
                    onCheck={::this.selectedRowsRecalc}
                    checkCache={::this.checkCache}
                    query={this.state.query}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    onSettingsLoad={::this.onSettingsLoad}
                    onDblClick={::this.onDblClick}
                    shortPagination={true}
                />
            </div>
        );
    }

    render() {
        let table = this.renderTable();

        const editor = this.state.showEditorModal ? this.getEditor() : '';

        const buttons = (
            <ModalTopMenuButtons>
                <ModalTopMenuButton
                    key="close"
                    className="_close"
                    tooltip="Отменить"
                    onClick={::this.props.onClose}
                />
            </ModalTopMenuButtons>
        );

        return (
            <div>
                <PageModal
                    header={{title: 'Варианты', buttons}}
                    className={classNames("profile-modal b-modal-edit b-modal-schedule schedule-traffic-options-modal")}
                    onClose={this.props.onClose}
                >
                    <TableContainer>
                        {table}
                    </TableContainer>
                </PageModal>
                {this.state.showEditorModal ? (
                    <SlideLeftTransition>
                        {editor}
                    </SlideLeftTransition>
                ): null}
            </div>
        );
    }
}