import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import BarChart from 'components/modules/analytics/base-components/charts/BarChart';
import DashboardTable from 'components/modules/analytics/base-components/DashboardTable';
import WidgetSelector from 'components/modules/analytics/base-components/WidgetSelector';
import GlobalLoaderComponent from "components/ui/global-loader";
import {api} from "helpers/api";
import {success, error} from 'helpers/response';
import _ from 'lodash';
import './style.less';
import {connect} from "react-redux";
import {User} from "helpers/user";
import * as storage from "utils/storage";
import moment from "moment";
import {getTabletSummary} from "store/reducers/reports/tablet";
import Datepicker from "components/ui/form/datepicker";
import {Label, Pie, PieChart} from "recharts";
import ReactResizeDetector from 'react-resize-detector';
import formats from "dictionaries/formats";

@propTypes({
    barChartFillColor: PropTypes.string
})

@defaultProps({
    barChartFillColor: ''
})

@connect(state => ({}), {getTabletSummary})

export default class ForestryWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isError: false,
            isLoading: false,
            from: moment().subtract(1, 'day').format(formats.DATE_API),
            to: moment().subtract(1, 'day').format(formats.DATE_API),
            data: {},
            fontSize: 12,
            lineHeight: 15,
        };
    }

    componentWillMount() {
        this.loadData();
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value});
        this.loadData();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value});
        this.loadData();
    };

    async loadData() {
        this.setState({isLoading: true});
        const response = await this.props.getTabletSummary(this.state.from, this.state.to);
        this.setState({isLoading: false});

        if (response.isOk) {
            this.setState({
                data: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    get(field, forDisplay = true) {
        const value = _.get(this.state.data, field, null);
        if (forDisplay) {
            if (value !== null) {
                return _.round(value, 0);
            } else {
                return '-';
            }
        }
        return value || 0;
    }

    renderContent() {
        return (
            <div className="full-size kiutr-widget">
                <div className="date-wrapper">
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange}/>
                    </div>
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-5">
                        <div className="tiles__row tiles__row_bdn">
                            <div className="tiles__col">ТС в работе</div>
                            <div className="tiles__r-progress-bar">
                                <PieChart width={103} height={103}>
                                    <Pie data={[
                                        {
                                            value: this.get('forestry.vehicles', false),
                                        },
                                        {
                                            value: 100 - this.get('forestry.vehicles', false),
                                            fill: 'rgba(255, 255, 255, 0.5)',
                                        }
                                    ]}
                                         nameKey="name"
                                         dataKey="value"
                                         cx="50%"
                                         cy="50%"
                                         outerRadius={50}
                                         innerRadius={43}
                                         startAngle={90}
                                         endAngle={-270}
                                         fill="#FFF"
                                         stroke="none">
                                        <Label value={`${this.get('forestry.vehicles')}%`}
                                               position="center" fill="#000"/>
                                    </Pie>
                                </PieChart>
                            </div>
                        </div>
                    </div>
                    <div className="col col-7">
                        <div className="tiles__row tar">
                            <div className="tiles__col" style={{fontSize:`${this.state.fontSize}px`, lineHeight: `${this.state.lineHeight}px`}}>Возраст ТС</div>
                            <div className="tiles__col" style={{fontSize:`${this.state.fontSize}px`, lineHeight: `${this.state.lineHeight}px`}}>{this.get('forestry.vehicles_age')}</div>
                            <div className="tiles__h-progress-bar">
                                <div className="tiles__h-progress-bar-fill"
                                     style={{width: this.get('forestry.vehicles_age', false) + '%'}}/>
                            </div>
                        </div>
                        <div className="tiles__row tar">
                            <div className="tiles__col" style={{fontSize:`${this.state.fontSize}px`, lineHeight: `${this.state.lineHeight}px`}}>Оснащенность ГЛОНАСС</div>
                            <div className="tiles__col" style={{fontSize:`${this.state.fontSize}px`, lineHeight: `${this.state.lineHeight}px`}}>{this.get('forestry.vehicles_bnso')}%</div>
                            <div className="tiles__h-progress-bar">
                                <div className="tiles__h-progress-bar-fill"
                                     style={{width: this.get('forestry.vehicles_bnso', false) + '%'}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async onSelectedUnitsChange(param) {
        let data = this.state.data;
        _.each(param, (p) => {
            let index = p.index;
            let checked = p.checked;

            data.organizationUnits[index].checked = checked;
        });
        await this.setState({data});
        this.loadDriversData();
    }

    _onResize(width, height) {
        const minWidth = 624;
        const minHeight = 268;
        const maxWidth = 1000;
        const maxHeight = 500;
        const minFontSize = 12;
        const maxFontSize = 30;
        const minLineHeight = 15;
        const maxLineHeight = 40;

        const widthRatio = Math.min(1, Math.max(0, (width - minWidth) / (maxWidth - minWidth)));
        const heightRatio = Math.min(1, Math.max(0, (height - minHeight) / (maxHeight - minHeight)));
        const ratio = (widthRatio + heightRatio) / 2;

        this.setState({
            fontSize: minFontSize + (maxFontSize - minFontSize) * ratio,
            lineHeight: minLineHeight + (maxLineHeight - minLineHeight) * ratio,
        });
    }

    render() {
        const loader = this.state.isLoading ? <GlobalLoaderComponent/> : null;

        return (
            <div className="kiutr-widget">
                {loader}
                {this.renderContent()}
                <ReactResizeDetector handleWidth handleHeight onResize={::this._onResize}/>
            </div>
        );
    }
}