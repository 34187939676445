import {combineReducers} from 'redux'
import auth from './auth';
import main from './main';
import maps from './maps';
import staffing from './staffing/staffing';
import staffing_editor from './staffing/staffing_editor';
import educations from './staffing/education/educations';
import education_editor from './staffing/education/education_editor';
import positions from './staffing/positions/positions';
import dictionary_structure from './dictionaries/structure';
import dictionary_list from './dictionaries/dictionary';
import dictionary_editor from "./dictionaries/editor";
import notifications from "./notifications";
import system from "./system";
import settings from "./settings";
import routes from "./routes/routes";
import route_editor from "./routes/route_editor";
import position_editor from "./staffing/positions/position_editor";
import user_map_objects from "./user-map-objects/index";
import vehicles from "./vehicles/index";
import portal from "./portal/index";
import geo from "./geo/index";
import activity from "./activity/activity";
import menu from "./menu";
import user_logs from './user-logs/user-logs';
import trash_logs from './trash-logs/trash-logs';
import audit from "./audit/audit";
import roles from "./roles/roles";
import kurs from "./kurs";
import unit_permissions from "./unit-permissions/unit-permissions";
import route_variants from "./routes/route_variants";
import interval_maps from "./routes/interval_maps";
import tooltips from "./tooltips";
import railroadclose from "./geo/railroad-crossing";
import kiutrOrders from './kiutr/orders/orders';
import regularityMatrix from "./kiutr/regularity_matrix";
import widget from './widget/widget';
import {reducer as form} from 'redux-form';

export default combineReducers({
    main,
    maps,
    kurs,
    auth,
    form,
    staffing,
    staffing_editor,
    dictionary_structure,
    dictionary_list,
    dictionary_editor,
    notifications,
    educations,
    education_editor,
    routes,
    route_editor,
    positions,
    position_editor,
    system,
    menu,
    user_map_objects,
    settings,
    activity,
    user_logs,
    trash_logs,
    audit,
    vehicles,
    roles,
    unit_permissions,
    geo,
    portal,
    route_variants,
    interval_maps,
    tooltips,
    railroadclose,
    kiutrOrders,
    regularityMatrix,
    widget
});