export const filters = {
    base: {
        result: [],
        vehicle: {
            owners: [],
            units: [],
            vehicles: [],
            types: [],
        },
        routes: [],
    },
    extended: {
        result: [],
        from: '00:00',
        to: '23:59',
        subjects: {
            units: [],
            modificators: {
                with_children: false,
                rent: false,
                own: true,
                balanceholder: false,
                dislocation: true,
            },
        },
        works: {
            units: [],
            carriers: [],
            drivers: [],
            route_kinds: [],
            rates_types: [],
            routes: [],
            work_types: [],
            kurs_repair_types: [],
            driveway_categories: [],
            in_work: [],
            contracts: [],
            modificators: {
                with_children: false,
            },
        },
        parameters: {
            component: null,
            vehicles: [],
            kurs_mechanism_types: [],
            parameters: [],
            vehicle_types: [],
            vehicle_capacity_types: [],
            responsives: [],
            modificators: {},
        },
        state: {
            placements: [],
            signal: null,
            satellites_level: null,
            bnso_indicators: [],
            modificators: {
                inverse: false,
            },
        },
    },
}