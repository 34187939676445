import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import AbstractMapMarker from "components/ui/map/base/marker";
import PointIcon from "components/ui/map/markers/icons/point-icon";
import _ from 'lodash';
import GarbageIcon from "components/ui/map/markers/icons/garbage-icon";

@propTypes({
    title: PropTypes.string,
    type: PropTypes.string,
    permanentTooltip: PropTypes.bool,
})

@defaultProps({
    title: '',
    type: null,
    permanentTooltip: false,
})

export default class GarbagePointMarker extends AbstractMapMarker {

    getOptions(props = null) {
        return _.merge(super.getOptions(), {
            icon: GarbageIcon.getIcon((props ? props.type : this.props.type) || 'kp'),
            riseOnHover: true,
        });
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.props.title) {
            this.getMarker().bindTooltip(this.props.title, {
                sticky: true,
                permanent: this.props.permanentToolTip,
            });
        }
    }

    componentWillUpdate(props) {
        const marker = this.getMarker();
        const oldTitle = this.props.title;
        const newTitle = props.title;
        if (((oldTitle !== newTitle) || !marker.getTooltip())) {
            marker.unbindTooltip();
            if (newTitle) {
                marker.bindTooltip(newTitle, {
                    sticky: true,
                    permanent: this.props.permanentToolTip,
                });
            }
        }

        if (this.props.type !== props.type) {
            this.props.leafletMap.removeLayer(this.getMarker());
            this.init(props);
            this.props.leafletMap.addLayer(this.getMarker());
        }
    }

}