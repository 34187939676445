import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GlobalLoader from 'components/ui/global-loader';

import {connect} from 'react-redux';
import {getMaintenanceStatus, login} from 'store/reducers/auth';
import {connectWsRpc} from 'store/reducers/main';

import Errors from 'dictionaries/errors';

import '../_style/style.less';
import Input from "components/ui/input";
import moment from "moment";
import formats from "dictionaries/formats";

@connect(state => ({
    isConnected: state.main.get('isConnected'),
    isAuthorized: state.auth.get('isAuthorized')
}), {login, connectWsRpc, getMaintenanceStatus})

export default class TabletLogin extends Component {

    state = {
        loading: false,

        username: '',
        password: '',

        error: null,

        maintenance: {
            status: false,
            message: '',
        },
    };

    async componentDidMount() {
        if (!this.props.isConnected) {
            this.props.connectWsRpc();
        }
        const response = await this.props.getMaintenanceStatus();
        if (response.isOk) {
            this.setState({
                maintenance: response.payload,
            });
        } else {
            response.showErrors();
        }
    }

    render() {
        const loader = (!this.props.isConnected || this.state.loading) ? (<GlobalLoader/>) : '';
        return (
            <div id='main-wrapper' className="main-wrapper">
                {loader}
                <div className="auth">
                    {window.RNIS_SETTINGS.LOGOIMAGE ?  (<a className="image-logo" href="/tablet/dashboard">
                        <img src={window.RNIS_SETTINGS.LOGOIMAGE} alt="" className="logo_tablet lg"/>
                    </a>): null}
                    <div className="auth__title">Авторизация</div>
                    {this.state.maintenance.status ? (
                        <div>{this.state.maintenance.message}
                            до {moment(this.state.maintenance.will_be_available_at).format(formats.DATETIME_SHORT)}</div>
                    ) : null}
                    <div className="auth__place">
                        <form className="auth__form" action="#">
                            <label className="auth__label"><span className="auth__label-text">Логин</span><span
                                className="input">
                                <Input
                                    placeholder="Логин"
                                    className="input__orig input__orig_auth"
                                    error={this.state.error}
                                    onChange={::this.onChangeUsername}
                                    value={this.state.username}
                                    onEnter={::this.login}
                                />
                            </span></label>
                            <label className="auth__label"><span className="auth__label-text">Пароль</span><span
                                className="input">
                                <Input
                                    placeholder="Пароль"
                                    type="password"
                                    withoutEye={true}
                                    className="input__orig input__orig_auth"
                                    error={this.state.error}
                                    onChange={::this.onChangePassword}
                                    value={this.state.password}
                                    onEnter={::this.login}
                                />
                            </span></label>
                            <p className="auth__forget">Если вы забыли пароль, то просьба обратиться к администратору
                                системы</p>
                            <div className="btn-wrap">
                                <a className="btn btn_colored btn_enter" onClick={::this.login}>Войти</a>
                            </div>
                            <div className="support-contact">Техподдержка портала 8 (495) 734-78-81</div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    onChangePassword({target: {value}}) {
        this.setState({error: null, password: value});
    }

    onChangeUsername({target: {value}}) {
        this.setState({error: null, username: value});
    }

    async login() {
        this.setState({loading: true});
        const response = await this.props.login(this.state.username, this.state.password);
        this.setState({loading: false});

        return response.isOk ? this.loginSuccess(response) : this.loginError(response);
    }

    loginSuccess(response) {
        this.props.router.push('/tablet/dashboard');
    }

    loginError(error) {
        if (error.hasErrorWithCode(Errors.InvalidLoginPasswordException.serverCode)) {
            this.setState({
                error: Errors.InvalidLoginPasswordException.message
            });
        } else {
            error.showErrors();
        }
    }
}