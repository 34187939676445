import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes} from 'react-props-decorators';
import classNames from 'classnames';

import './index.less';
import {LabeledCheckbox} from "components/ui/checkbox";

@propTypes({
    active: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
})

export default class MapAnimationToggleControl extends Component {

    render() {
        return (
            <LabeledCheckbox
                label="Анимация"
                checked={this.props.active}
                onChange={this.props.toggle}
            />
        );
    }

}