import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import formats from '../../../../dictionaries/formats';
import { State } from '../../../ui/state';
import L from 'leaflet';

export const getPagination = (page, limit) => ({
    page,
    limit,
});

export const getOrderBy = (column, direction) => ({
    column,
    direction,
});

export const isKurs = component => component === 'road';

export const mapSelectedRouteVariants = (routeVariants) => _.values(_.groupBy(_.map(routeVariants, (route) => { //TODO: refactor this
    let coordinates = [];
    _.each(_.concat(route.forward_points, route.reverse_points), (point) => {
        coordinates = _.concat(coordinates, _.map(_.get(point, 'path_to_the_next_point_geometry.coordinates', []), (coord) => _.clone(coord).reverse()));
    });
    route.coordinates = coordinates;

    return route;
}), 'route_uuid'))

export const mapSelectedRouteVariantsToStopPoints = (routeVariants) => _.uniq(_.map(_.flatten(_.map(routeVariants, (item) => {
    return _.filter(_.concat(item.forward_points, item.reverse_points), {point_type: 'stop_point'});
})), 'type_uuid'));

export const getVehicleTasks = (data, vehicleUuid) => {
    return _.filter(data, (task) => {
        return _.find(task.resources, {base_vehicle_uuid: vehicleUuid});
    });
}

export const getSystemsSelectOptions = (systemsOptions) => _.map(systemsOptions, (label, value) => ({
    value,
    label,
}))
export const mapHistoryWorks = (tasks, work_types) => tasks.map((task) => {
    if (task.items_fact) {
        return ({...task.items_fact, work_type: {
            name:_.get(_.get(work_types, task.items_fact.work_type_uuid), 'name'),
            uuid: task.items_fact.work_type_uuid,
            }
        })
    }
    return task.items
});

export const timeStrToMinutes = (timeString) => {
    const timeObject = moment(timeString, formats.TIME);
    return timeObject.hours() * 60 + timeObject.minutes();
};

export const timeToInt = (time) => time.unix();

export const getSelectedRouteColor = (index) => {
    const step = 75;

    const red = 150 - Math.floor(index / 4) * step;
    const blue = 150 - Math.floor(index / 2) * step;
    const green = 150 - (index % 2) * step;

    return '#' + _.padStart(red.toString(16), 2, '0') + _.padStart(blue.toString(16), 2, '0') + _.padStart(green.toString(16), 2, '0');
}
export const isKiutrOrChildren = component => {
    return _.indexOf([
        'kiutr',
        'children',
        'ems',
    ], component) !== -1;
}

export const getDefaultFilters = (component) => ({
    base: {
        result: [],
        vehicle: {
            owners: [],
            units: [],
            vehicles: [],
            types: [],
        },
        routes: [],
    },
    extended: {
        result: [],
        from: '00:00',
        to: '23:59',
        subjects: {
            units: [],

            modificators: {
                with_children: false,
                rent: false,
                own: true,
                balanceholder: false,
                dislocation: true,
            },
        },
        works: {
            units: [],
            carriers: [],
            orders: [],
            tasks: [],
            kurs_contracts: [],
            drivers: [],
            route_kinds: [],
            rates_types: [],
            routes: [],
            work_types: [],
            kurs_repair_types: [],
            driveway_categories: [],
            in_work: [],
            contracts: [],

            modificators: {
                with_children: false,
            },
        },
        parameters: {
            component,
            vehicles: [],
            kurs_mechanism_types: [],
            parameters: [],
            vehicle_types: [],
            vehicle_capacity_types: [],
            responsives: [],

            modificators: {},
        },
        state: {
            placements: [],
            signal: null,
            satellites_level: null,
            bnso_indicators: [],

            modificators: {
                inverse: false,
            },
        },
    },
})

export const getRoadModalData = (road, maintenance_groups, ) => [
    {title: "Название автодороги", data: road.name},
    {title: "РГН", data: road.name},
    {title: "Протяженность", data: road.length},
    {title: "Вид дороги", data: road.kind},
    {title: "Тип дороги", data: road.type},
    {title: "Балансодержатель", data: road.owner_name},
    {title: "Инвентарный номер", data: road.number},
    {title: "Категория дороги по ГОСТ Р 52398-2005", data: road.gost_category},
    {title: "Тип покрытия дороги", data: road.cover_type},
    {title: "Группа по содержанию", data: _.get(_.find(maintenance_groups, {uuid: road.maintenance_group_uuid}), 'name')},
    {title: "Дата ввода в экслуатацию", data: moment(road.date_from).format(formats.DATE)},
    {title: "Дата вывода из эксплуатации", data: moment(road.date_to).format(formats.DATE)},
    {title: "Бесхозный объект", data: road.is_ownerless},
];

export const getRoadPartModalData = (roadPart, maintenance_groups, ) => [
    {title: "Название участка", data: roadPart.name},
    {title: "Длина", data: roadPart.length},
    {title: "Ширина проезжей части", data: roadPart.width},
    {title: "Начало", data: roadPart.start},
    {title: "Конец", data: roadPart.end},
    {title: "Категория", data: roadPart.category},
    {title: "Группа по содержанию", data: _.get(_.find(maintenance_groups, {uuid: roadPart.maintenance_group_uuid}), 'name')},
    {title: "Балансовая принадлежность", data: (roadPart.driveway_category === 'federal') ? 'Региональные/межмуниципальные' : (roadPart.driveway_category === 'federal_roads') ? 'Федеральные' : 'Местные'},
    {title: "Регистрационный номер", data: roadPart.register_number},
    {title: "Дата ввода в экслуатацию", data: moment(roadPart.date_from).format(formats.DATE)},
    {title: "Дата вывода из эксплуатации", data: moment(roadPart.date_to).format(formats.DATE)},
    {title: "Балансодержатель", data: roadPart.owner_name},
    {title: "Категория дороги по ГОСТ Р 52398-2005", data: roadPart.gost_category},
    {title: "Тип покрытия дороги", data: roadPart.cover_type},
    {title: "Количество полос", data: roadPart.lines_count},
    {title: "Бесхозный объект", data: <State positive={roadPart.is_ownerless}/>},
];

export const getRoadRepairModalData = (roadRepairPart) => [
    {title: "Название участка", data: roadRepairPart.name},
    {title: "Ответственная организация за ремонт участка", data: roadRepairPart.unit_name},
    {title: `Название ${window.RNIS_SETTINGS.rename_contracts ? 'подрядного обязательства' : 'контракта'}`, data: roadRepairPart.contract},
    {title: "Начало участка, км", data: roadRepairPart.start},
    {title: "Окончание участка, км", data: roadRepairPart.end},
    {title: "Приведенная протяженность, прив.км", data: roadRepairPart.length},
    {title: "Рейтинг участка", data: roadRepairPart.rating},
    {title: "Рейтинг Добродела", data: roadRepairPart.dobrodel_rating},
    {title: "Статус участка ремонта", data: roadRepairPart.status},
    {title: "Плановая дата начала работ на участке", data: moment(roadRepairPart.plan_work_start).format(formats.DATE)},
    {title: "Плановая дата окончания работ на участке", data: moment(roadRepairPart.plan_work_end).format(formats.DATE)},
    {title: "Фактическая дата начала работ на участке", data: moment(roadRepairPart.fact_work_start).format(formats.DATE)},
    {title: "Фактическая дата окончания работ на участке", data: moment(roadRepairPart.fact_work_end).format(formats.DATE)},
];

export const focusOnRoutes = (stateRoutes, fitBounds) => {
    const routes = _.map(stateRoutes, (route) => {
        let coordinates = [];
        _.each(_.concat(route.forward_points, route.reverse_points), (point) => {
            coordinates = _.concat(
                coordinates,
                _.map(_.get(point, 'path_to_the_next_point_geometry.coordinates', []), (coord) => _.clone(coord).reverse())
            );
        });

        return new L.polyline(coordinates);
    });

    const group = new L.featureGroup(routes);
    fitBounds(group.getBounds());
}

export const filteredByCommunalUuid = (items, hasProperty) => {
    return items.filter(item => "communal_municipality_uuid" in item === hasProperty && item.hasOwnProperty('communal_municipality_uuid') === hasProperty);
}

export const logicViewWorkTypeSelect = () => {
    if (location.pathname.includes('executedworks')) {
        return true
    } else if (location.pathname.includes('roadparts')) {
        return true
    }
    return false
}

export const getWeekDifference = (field, value, diapason) => {
    let timeDifference;
    if (field === 'from_date') {
        timeDifference = moment(diapason.to_date).diff(value, 'days');
        console.log("🚀 ~ file: maps.js ~ line 2295 ~ MapsComponent ~ handleDiapasonChange ~ timeDifference", timeDifference)
    }
    if (field === 'to_date') {
        timeDifference = moment(diapason.from_date).diff(value, 'days');
        console.log("🚀 ~ file: maps.js ~ line 2299 ~ MapsComponent ~ handleDiapasonChange ~ timeDifference", timeDifference)
    }
    return timeDifference;

}



