import * as session from './api/session';
import * as telematics from './api/telematics';
import * as storage from './api/storage';
import * as auth from './api/auth';
import * as organizational_units from './api/organizational_units';
import * as portal from './api/portal';
import * as dictionary from './api/dictionary';
import * as notifications from './api/notifications';
import * as system from './api/system';
import * as geo from './api/geo';
import * as vehicles from './api/vehicles';
import * as reports from './api/reports';
import * as t1sync from './api/t1sync';
import * as gatn from './api/gatn';
import * as fias from './api/fias';
import * as mobile from './api/mobile';
import * as ecp from './api/ecp';
import * as kurs from './api/kurs';
import * as work_groups from './api/work_groups';
import * as commdept from './api/commdept';
import * as converter from './api/converter';
import * as garbage from './api/garbage';
import * as routing from './api/routing';
import * as monitoring from './api/monitoring';
import * as complaints from './api/complaints';
import * as sms from './api/sms';
import * as regularity_matrix from './api/regularityMatrix';

import _ from 'lodash';
import { getTabletWorkSummaryPercent } from "./api/reports";

export const api = {
    wsrpc: {
        connectionLost: session.connectionLost,
        connectionEstablished: session.connectionEstablished
    },
    matrix: {
        getOrderInfo: regularity_matrix.getOrderInfo,
        getRouteVariantInfo: regularity_matrix.getRouteVariantInfo,
        getRouteVariantTracks: regularity_matrix.getRouteVariantTracks
    },
    sms: {
        createSmsTemplate: sms.createSmsTemplate,
        getSmsTemplate: sms.getSmsTemplate,
        getSmsTemplatesList: sms.getSmsTemplatesList,
        updateSmsTemplate: sms.updateSmsTemplate,
        deleteSmsTemplate: sms.deleteSmsTemplate,
        createSmsDevice: sms.createSmsDevice,
        getSmsDevice: sms.getSmsDevice,
        getSmsDeviceByID: sms.getSmsDeviceByID,
        getSmsDevicesList: sms.getSmsDevicesList,
        updateSmsDevice: sms.updateSmsDevice,
        deleteSmsDevice: sms.deleteSmsDevice,
        getSmsDefaultDevice: sms.getSmsDefaultDevice,
        smsCreate: sms.smsCreate,
        getLastSmses: sms.getLastSmses
    },
    session: {
        login: session.login,
        logout: session.logout,
        getEsiaUrl: session.getEsiaUrl,
        esiaLogin: session.esiaLogin,
        saveCredits: session.saveCredits,
        esiaToRnisUserConfirm: session.esiaToRnisUserConfirm,
    },
    telematics: {
        getTelematics: telematics.getTelematics,
        getTelematicsWithDevices: telematics.getTelematicsWithDevices,
        getTelematicsBoundsWithDevices: telematics.getTelematicsBoundsWithDevices,
        getTelematicsLogs: telematics.getTelematicsLogs,
        getPanics: telematics.getPanics,
        getTelematicsWithTemperatureGrpc: telematics.getTelematicsWithTemperatureGrpc,
        getObjectsHistoryData: telematics.getObjectsHistoryData,
    },
    storage: {
        getUploadToken: storage.getUploadToken,
        uploadFile: storage.uploadFile,
    },
    auth: {
        createUser: auth.createUser,
        updateUser: auth.updateUser,
        deleteUser: auth.deleteUser,
        getUser: auth.getUser,
        refreshUser: auth.refreshUser,
        getUsers: auth.getUsers,
        getCleanupSpecialists: auth.getCleanupSpecialists,
        getUsersByUnits: auth.getUsersByUnits,
        getUserTachographs: auth.getUserTachographs,
        getUserEducations: auth.getUserEducations,
        createUserEducation: auth.createUserEducation,
        updateUserEducation: auth.updateUserEducation,
        deleteUserEducation: auth.deleteUserEducation,
        getUserPositions: auth.getUserPositions,
        createUserPosition: auth.createUserPosition,
        updateUserPosition: auth.updateUserPosition,
        deleteUserPosition: auth.deleteUserPosition,
        getPermissions: auth.getPermissions,
        getUserCan: auth.getUserCan,
        getUserPermissions: auth.getUserPermissions,
        getRoles: auth.getRoles,
        createRole: auth.createRole,
        updateRole: auth.updateRole,
        deleteRole: auth.deleteRole,
        getUnitPermissions: auth.getUnitPermissions,
        updateUnitPermission: auth.updateUnitPermission,
    },
    organizational_units: {
        getUnits: organizational_units.getUnits,
        getUnit: organizational_units.getUnit,
        createUnit: organizational_units.createUnit,
        updateUnit: organizational_units.updateUnit,
        deleteUnit: organizational_units.deleteUnit,
        getUserGroups: organizational_units.getUserGroups,
        getPositions: organizational_units.getPositions,
        getPosition: organizational_units.getPosition,
        createPosition: organizational_units.createPosition,
        updatePosition: organizational_units.updatePosition,
        deletePosition: organizational_units.deletePosition,
        getCarriers: organizational_units.getCarriers,
        getCarrier: organizational_units.getCarrier,
        createCarrier: organizational_units.createCarrier,
        updateCarrier: organizational_units.updateCarrier,
        deleteCarrier: organizational_units.deleteCarrier,
    },
    dictionary: {
        getDictionaryStructure: dictionary.getDictionaryStructure,
        getDictionaryMeta: dictionary.getDictionaryMeta,
        getDictionaryList: dictionary.getDictionaryList,
        getDictionaryLists: dictionary.getDictionaryLists,
        getDocument: dictionary.getDocument,
        createDocument: dictionary.createDocument,
        updateDocument: dictionary.updateDocument,
        deleteDocument: dictionary.deleteDocument,
        offDayGet: dictionary.offDayGet,
    },
    notifications: {
        getNotifications: notifications.getNotifications,
        getNotification: notifications.getNotification,
        getTotalUnreadNotifications: notifications.getTotalUnreadNotifications,
        readNotification: notifications.readNotification,
        readNotifications: notifications.readNotifications,
        notificationEventCreated: notifications.notificationEventCreated,
        notificationEventRead: notifications.notificationEventRead,
        getNotificationEvents: notifications.getNotificationEvents,
        getNotificationEvent: notifications.getNotificationEvent,
        sendNotificationEvent: notifications.sendNotificationEvent,
        createNotificationEvent: notifications.createNotificationEvent,
        updateNotificationEvent: notifications.updateNotificationEvent,
        deleteNotificationEvent: notifications.deleteNotificationEvent,
        getMailings: notifications.getMailings,
        getMailing: notifications.getMailing,
        createMailing: notifications.createMailing,
        updateMailing: notifications.updateMailing,
        deleteMailing: notifications.deleteMailing,
        getEventRules: notifications.getEventRules,
        getEventRule: notifications.getEventRule,
        createEventRule: notifications.createEventRule,
        updateEventRule: notifications.updateEventRule,
        deleteEventRule: notifications.deleteEventRule,
        getEventTypes: notifications.getEventTypes,
        getEventRuleNotifications: notifications.getEventRuleNotifications,
        getEventRuleNotification: notifications.getEventRuleNotification,
        createEventRuleNotification: notifications.createEventRuleNotification,
        updateEventRuleNotification: notifications.updateEventRuleNotification,
        deleteEventRuleNotification: notifications.deleteEventRuleNotification,
        getUserNotificationSettings: notifications.getUserNotificationSettings,
        getUserNotificationSetting: notifications.getUserNotificationSetting,
        createUserNotificationSetting: notifications.createUserNotificationSetting,
        updateUserNotificationSetting: notifications.updateUserNotificationSetting,
        deleteUserNotificationSetting: notifications.deleteUserNotificationSetting,
        getSpeedViolations: notifications.getSpeedViolations,
        getSpeedViolationJournal: notifications.getSpeedViolationJournal,
        speedViolationsSummary: notifications.speedViolationsSummary,
        speedViolationsDriversSummary: notifications.speedViolationsDriversSummary,
    },
    system: {
        getProjectsVersion: system.getProjectsVersion,
        checkServiceStatuses: system.checkServiceStatuses,
        serviceStatusesChecked: system.serviceStatusesChecked,
        getOptions: system.getOptions,
        createOption: system.createOption,
        getOption: system.getOption,
        setOptionValue: system.setOptionValue,
        setUserOptionValue: system.setUserOptionValue,
        deleteOption: system.deleteOption,
        optionsUpdated: system.optionsUpdated,
        log: system.log,
        activityStore: system.activityStore,
        getActivity: system.getActivity,
        getUserLogs: system.getUserLogs,
        getUserLogCodes: system.getUserLogCodes,
        getAudit: system.getAudit,
        getEntityNames: system.getEntityNames,
        getTrashLogs: system.getTrashLogs,
        getMaintenanceStatus: system.getMaintenanceStatus,
        maintenanceUp: system.maintenanceUp,
        maintenanceDown: system.maintenanceDown,
        restoreEntity: system.restoreEntity,
        getTooltips: system.getTooltips,
        updateTooltip: system.updateTooltip,
        tableExportCreate: system.tableExportCreate,
        tableExportGet: system.tableExportGet,
        tableExportList: system.tableExportList,
    },
    geo: {
        getRoutes: geo.getRoutes,
        getRoutesShort: geo.getRoutesShort,
        createdByUuidList: geo.createdByUuidList,
        getRoute: geo.getRoute,
        createRoute: geo.createRoute,
        cloneRoute: geo.cloneRoute,
        cloneFillRoute: geo.cloneFillRoute,
        updateRoute: geo.updateRoute,
        deleteRoute: geo.deleteRoute,
        getStopPoints: geo.getStopPoints,
        getRailroadCrossings: geo.getRailroadCrossings,
        getStopPoint: geo.getStopPoint,
        createStopPoint: geo.createStopPoint,
        updateStopPoint: geo.updateStopPoint,
        deleteStopPoint: geo.deleteStopPoint,
        routing: geo.routing,
        getClosestStopPoint: geo.getClosestStopPoint,
        routingByStreets: geo.routingByStreets,
        getLayers: geo.getLayers,
        getLayer: geo.getLayer,
        createLayer: geo.createLayer,
        updateLayer: geo.updateLayer,
        deleteLayer: geo.deleteLayer,
        getUserGeoObjects: geo.getUserGeoObjects,
        getUserGeoObject: geo.getUserGeoObject,
        getUserGeoObjectByLayers: geo.getUserGeoObjectByLayers,
        createUserGeoObject: geo.createUserGeoObject,
        updateUserGeoObject: geo.updateUserGeoObject,
        deleteUserGeoObject: geo.deleteUserGeoObject,
        searchGeoObjects: geo.searchGeoObjects,
        createStopPointPavilion: geo.createStopPointPavilion,
        getStopPointPavilion: geo.getStopPointPavilion,
        listStopPointPavilions: geo.listStopPointPavilions,
        updateStopPointPavilion: geo.updateStopPointPavilion,
        deleteStopPointPavilion: geo.deleteStopPointPavilion,
        getRouteVariants: geo.getRouteVariants,
        getRouteVariant: geo.getRouteVariant,
        createRouteVariant: geo.createRouteVariant,
        updateRouteVariant: geo.updateRouteVariant,
        deleteRouteVariant: geo.deleteRouteVariant,
        getRouteVariantNullRuns: geo.getRouteVariantNullRuns,
        getRouteVariantNullRun: geo.getRouteVariantNullRun,
        createRouteVariantNullRun: geo.createRouteVariantNullRun,
        updateRouteVariantNullRun: geo.updateRouteVariantNullRun,
        deleteRouteVariantNullRun: geo.deleteRouteVariantNullRun,
        getIntervalMaps: geo.getIntervalMaps,
        getIntervalMap: geo.getIntervalMap,
        createIntervalMap: geo.createIntervalMap,
        cloneIntervalMap: geo.cloneIntervalMap,
        updateIntervalMap: geo.updateIntervalMap,
        deleteIntervalMap: geo.deleteIntervalMap,
        getSchedules: geo.getSchedules,
        getSchedule: geo.getSchedule,
        getScheduleIntervals: geo.getScheduleIntervals,
        createScheduleIntervals: geo.createScheduleIntervals,
        updateScheduleIntervals: geo.updateScheduleIntervals,
        deleteScheduleIntervals: geo.deleteScheduleIntervals,
        getScheduleInfo: geo.getScheduleInfo,
        createSchedule: geo.createSchedule,
        createScheduleInterval: geo.createScheduleInterval,
        updateScheduleInterval: geo.updateScheduleInterval,
        deleteScheduleInterval: geo.deleteScheduleInterval,
        getScheduleInterval: geo.getScheduleInterval,
        cloneSchedule: geo.cloneSchedule,
        updateSchedule: geo.updateSchedule,
        deleteSchedule: geo.deleteSchedule,
        getScheduleTurns: geo.getScheduleTurns,
        getScheduleTurn: geo.getScheduleTurn,
        createScheduleTurn: geo.createScheduleTurn,
        updateScheduleTurn: geo.updateScheduleTurn,
        deleteScheduleTurn: geo.deleteScheduleTurn,
        getScheduleSwitches: geo.getScheduleSwitches,
        getScheduleSwitch: geo.getScheduleSwitch,
        createScheduleSwitch: geo.createScheduleSwitch,
        updateScheduleSwitch: geo.updateScheduleSwitch,
        deleteScheduleSwitch: geo.deleteScheduleSwitch,
        getContracts: geo.getContracts,
        getContract: geo.getContract,
        getContractPlanSummary: geo.getContractPlanSummary,
        createContract: geo.createContract,
        updateContract: geo.updateContract,
        deleteContract: geo.deleteContract,
        getRouteRegistries: geo.getRouteRegistries,
        getRouteRegistry: geo.getRouteRegistry,
        getRouteRegistryFact: geo.getRouteRegistryFact,
        createRouteRegistry: geo.createRouteRegistry,
        updateRouteRegistry: geo.updateRouteRegistry,
        deleteRouteRegistry: geo.deleteRouteRegistry,
        getOrder: geo.getOrder,
        getUniqueVehicles: geo.getUniqueVehicles,
        getOrders: geo.getOrders,
        getOrdersUniqueList: geo.getOrdersUniqueList,
        exportOrders: geo.exportOrders,
        exportOrdersExcel: geo.exportOrdersExcel,
        exportOrderGet: geo.exportOrderGet,
        exportOrderList: geo.exportOrderList,
        updateOrder: geo.updateOrder,
        deleteOrder: geo.deleteOrder,
        cloneOrder: geo.cloneOrder,
        createDefect: geo.createDefect,
        updateDefect: geo.updateDefect,
        generateOrder: geo.generateOrder,
        getOrderRecalc: geo.getOrderRecalc,
        updateOrderRecalc: geo.updateOrderRecalc,
        getDailyViolations: geo.getDailyViolations,
        getDailyViolation: geo.getDailyViolation,
        getViolations: geo.getViolations,
        driverSummary: geo.driverSummary,
        orderVehicles: geo.orderVehicles,
        orderRunVehicles: geo.orderRunVehicles,
        orderRunSummary: geo.orderRunSummary,
        getUnitContractsPlanSummary: geo.getUnitContractsPlanSummary,
        getOrderExecutions: geo.getOrderExecutions,
        orderExecutionsStatistics: geo.orderExecutionsStatistics,
        getOrderExecutionUnitsSummary: geo.getOrderExecutionUnitsSummary,
        geocode: geo.geocode,
        searchAddress: geo.searchAddress,
        reverseGeocode: geo.reverseGeocode,
        geoSearch: geo.geoSearch,
        recalcOrderExecution: geo.recalcOrderExecution,
        getOrderExecutionRecalc: geo.getOrderExecutionRecalc,
        getRouteDeviations: geo.getRouteDeviations,
        createRouteDeviation: geo.createRouteDeviation,
        resourceCheck: geo.resourceCheck,
        resourceGroupCheck: geo.resourceGroupCheck,
        getDriversScoreSummary: geo.getDriversScoreSummary,
        getDisplays: geo.getDisplays,
        getDisplay: geo.getDisplay,
        createDisplay: geo.createDisplay,
        updateDisplay: geo.updateDisplay,
        deleteDisplay: geo.deleteDisplay,
        getDisplayConfigurations: geo.getDisplayConfigurations,
        getDisplayConfiguration: geo.getDisplayConfiguration,
        createDisplayConfiguration: geo.createDisplayConfiguration,
        updateDisplayConfiguration: geo.updateDisplayConfiguration,
        deleteDisplayConfiguration: geo.deleteDisplayConfiguration,
        getDisplayLogs: geo.getDisplayLogs,
        getStopPointRoutes: geo.getStopPointRoutes,
        getStopPointRoutesRun: geo.getStopPointRoutesRun,
        getTerritorialEntities: geo.getTerritorialEntities,
        getTerritorialEntity: geo.getTerritorialEntity,
        createTerritorialEntity: geo.createTerritorialEntity,
        updateTerritorialEntity: geo.updateTerritorialEntity,
        deleteTerritorialEntity: geo.deleteTerritorialEntity,
        listSwitches: geo.listSwitches,
        hideSwitch: geo.hideSwitch,
        showSwitch: geo.showSwitch,
        calcDuplicationMatrix: geo.calcDuplicationMatrix,
        createDuplicationMatrix: geo.createDuplicationMatrix,
        recalcDuplicationMatrix: geo.recalcDuplicationMatrix,
        getDuplicationMatrix: geo.getDuplicationMatrix,
        getDuplicationMatrices: geo.getDuplicationMatrices,
        deleteDuplicationMatrix: geo.deleteDuplicationMatrix,
        getDuplicationMatrixRoutes: geo.getDuplicationMatrixRoutes,
        getDuplicationMatrixRouteParts: geo.getDuplicationMatrixRouteParts,
        getEcpSignLogs: geo.getEcpSignLogs,
        getVehicleIdleTimes: geo.getVehicleIdleTimes,
        listUserGeoObjectsMO: geo.listUserGeoObjectsMO,
        listUserGeoObjectsMunicipalitiesList: geo.listUserGeoObjectsMunicipalitiesList,
        exportExcelSchedule: geo.exportExcelSchedule,
        getExportHtmlByExportUuid: geo.getExportHtmlByExportUuid,
    },
    vehicles: {
        getBnsoList: vehicles.getBnsoList,
        getBnso: vehicles.getBnso,
        createBnso: vehicles.createBnso,
        updateBnso: vehicles.updateBnso,
        deleteBnso: vehicles.deleteBnso,
        getVehicleList: vehicles.getVehicleList,
        getVehicleListForPortal: vehicles.getVehicleListForPortal,
        getVehicle: vehicles.getVehicle,
        createVehicle: vehicles.createVehicle,
        getVehicleDistance: vehicles.getVehicleDistance,
        updateVehicle: vehicles.updateVehicle,
        deleteVehicle: vehicles.deleteVehicle,
        getVehicleBnsoList: vehicles.getVehicleBnsoList,
        getVehicleBnso: vehicles.getVehicleBnso,
        createVehicleBnso: vehicles.createVehicleBnso,
        updateVehicleBnso: vehicles.updateVehicleBnso,
        deleteVehicleBnso: vehicles.deleteVehicleBnso,
        importVehicles: vehicles.importVehicles,
        importVehiclesGet: vehicles.importVehiclesGet,
        getEvents: vehicles.getEvents,
        getBnsoCheckLink: vehicles.getBnsoCheckLink,
        getVehiclesByUnits: vehicles.getVehiclesByUnits,
        getGraduationTemplates: vehicles.getGraduationTemplates,
        createGraduationTemplate: vehicles.createGraduationTemplate,
        getVehicleMalfunctions: vehicles.getVehicleMalfunctions,
        getBnsoPeriods: vehicles.getBnsoPeriods,
        exportBNSOExcel: vehicles.exportBNSOExcel,
    },
    reports: {
        getReports: reports.getReports,
        getReportsViolationsKiutr: reports.getReportsViolationsKiutr,
        getReportParameters: reports.getReportParameters,
        createReport: reports.createReport,
        getDocumentsList: reports.getDocumentsList,
        listReportTemplates: reports.listReportTemplates,
        deleteReportTemplate: reports.deleteReportTemplate,
        getTabletSummary: reports.getTabletSummary,
        getTabletKiutrSummary: reports.getTabletKiutrSummary,
        getTabletKiutrSummaryUnit: reports.getTabletKiutrSummaryUnit,
        getMunicipalitiesKiutrSummary: reports.getMunicipalitiesKiutrSummary,
        getTabletScheduleIntervalSummary: reports.getTabletScheduleIntervalSummary,
        getTabletUnitSummary: reports.getTabletUnitSummary,
        getReportSchedules: reports.getReportSchedules,
        getReportSchedule: reports.getReportSchedule,
        createReportSchedule: reports.createReportSchedule,
        updateReportSchedule: reports.updateReportSchedule,
        deleteReportSchedule: reports.deleteReportSchedule,
        getTabletWorkSummary: reports.getTabletWorkSummary,
        getTabletWorkSummaryPercent: reports.getTabletWorkSummaryPercent,
        getSolveProblemWidgetData: reports.getSolveProblemWidgetData,
    },
    portal: {
        getNews: portal.getNews,
        getNewsItem: portal.getNewsItem,
        createNews: portal.createNews,
        updateNews: portal.updateNews,
        deleteNews: portal.deleteNews,
        getFaq: portal.getFaq,
        getFaqItem: portal.getFaqItem,
        createFaq: portal.createFaq,
        updateFaq: portal.updateFaq,
        deleteFaq: portal.deleteFaq,
        getPages: portal.getPages,
        getPage: portal.getPage,
        createPage: portal.createPage,
        updatePage: portal.updatePage,
        deletePage: portal.deletePage,
        getRegisterRequests: portal.getRegisterRequests,
        getRegisterRequest: portal.getRegisterRequest,
        updateRegisterRequest: portal.updateRegisterRequest,
        updateRegisterEsiaRequest: portal.updateRegisterEsiaRequest,
        createConfirmRequest: portal.createConfirmRequest,
        getConfirmRequest: portal.getConfirmRequest,
        getRegisterEsiaRequests: portal.getRegisterEsiaRequests,
        listConfirmRequests: portal.listConfirmRequests,
        updateConfirmRequest: portal.updateConfirmRequest,
        confirmRequestUnits: portal.confirmRequestUnits,
        createTemplateDocument: portal.createTemplateDocument,
        updateTemplateDocument: portal.updateTemplateDocument,
        deleteTemplateDocument: portal.deleteTemplateDocument,
        getTemplateDocumentNextVersion: portal.getTemplateDocumentNextVersion,
        listTemplateDocuments: portal.listTemplateDocuments,
        getTemplateDocument: portal.getTemplateDocument,
        getCooperationAgreements: portal.getCooperationAgreements,
        updateCooperationAgreement: portal.updateCooperationAgreement,
        documentCooperationAgreement: portal.documentCooperationAgreement,
        createCooperationAgreement: portal.createCooperationAgreement,
        createCooperationAgreementWarning: portal.createCooperationAgreementWarning,
        getCooperationAgreementWarnings: portal.getCooperationAgreementWarnings,
        updateCooperationAgreementWarning: portal.updateCooperationAgreementWarning,
        testTemplateDocument: portal.testTemplateDocument,
    },
    routing: {
        find: routing.find,
    },
    t1sync: {
        getHistory: t1sync.getHistory,
        getHistoryByVehicle: t1sync.getHistoryByVehicle,
        getHistoryMultiple: t1sync.getHistoryMultiple,
        getDeviceExtendedData: t1sync.getDeviceExtendedData,
        getOdometrMultiple: t1sync.getOdometrMultiple,
        getEtpEvents: t1sync.getEtpEvents,
        getMotohours: t1sync.getMotohours,
        getMileage: t1sync.getMileage,

    },
    gatn: {
        getGatnViolationJournals: gatn.getGatnViolationJournals,
        getGatnViolations: gatn.getGatnViolations,
        speedViolationsDriversSummary: gatn.speedViolationsDriversSummary,
    },
    fias: {
        searchFias: fias.searchFias,
        getFias: fias.getFias,
    },
    mobile: {
        getContacts: mobile.getContacts,
        getContact: mobile.getContact,
        createContact: mobile.createContact,
        updateContact: mobile.updateContact,
        deleteContact: mobile.deleteContact,
        getMobilePages: mobile.getMobilePages,
        getMobilePage: mobile.getMobilePage,
        updateMobilePage: mobile.updateMobilePage,
    },
    garbage: {
        getObjects: garbage.getObjects,
        getObject: garbage.getObject,
        createObject: garbage.createObject,
        updateObject: garbage.updateObject,
        deleteObject: garbage.deleteObject,
    },
    ecp: {
        xmlVerify: ecp.xmlVerify,
    },
    kurs: {
        getFreeDrivers: kurs.getFreeDrivers,
        getContracts: kurs.getContracts,
        getContract: kurs.getContract,
        createContract: kurs.createContract,
        updateContract: kurs.updateContract,
        deleteContract: kurs.deleteContract,
        getRepairPrograms: kurs.getRepairPrograms,
        getRepairProgram: kurs.getRepairProgram,
        createRepairProgram: kurs.createRepairProgram,
        updateRepairProgram: kurs.updateRepairProgram,
        deleteRepairProgram: kurs.deleteRepairProgram,
        getContractWorks: kurs.getContractWorks,
        getContractWork: kurs.getContractWork,
        createContractWork: kurs.createContractWork,
        updateContractWork: kurs.updateContractWork,
        deleteContractWork: kurs.deleteContractWork,
        getVehicles: kurs.getVehicles,
        getVehicle: kurs.getVehicle,
        createVehicle: kurs.createVehicle,
        updateVehicle: kurs.updateVehicle,
        deleteVehicle: kurs.deleteVehicle,
        getVehicleWorks: kurs.getVehicleWorks,
        getVehicleWork: kurs.getVehicleWork,
        createVehicleWork: kurs.createVehicleWork,
        updateVehicleWork: kurs.updateVehicleWork,
        deleteVehicleWork: kurs.deleteVehicleWork,
        getKursMaintenances: kurs.getKursMaintenances,
        getKursMaintenance: kurs.getKursMaintenance,
        createKursMaintenance: kurs.createKursMaintenance,
        updateKursMaintenance: kurs.updateKursMaintenance,
        deleteKursMaintenance: kurs.deleteKursMaintenance,
        getVehicleMechanisms: kurs.getVehicleMechanisms,
        getVehicleMechanism: kurs.getVehicleMechanism,
        createVehicleMechanism: kurs.createVehicleMechanism,
        updateVehicleMechanism: kurs.updateVehicleMechanism,
        deleteVehicleMechanism: kurs.deleteVehicleMechanism,
        getStos: kurs.getStos,
        getSto: kurs.getSto,
        createSto: kurs.createSto,
        updateSto: kurs.updateSto,
        deleteSto: kurs.deleteSto,
        getStoWorks: kurs.getStoWorks,
        getStoWork: kurs.getStoWork,
        createStoWork: kurs.createStoWork,
        updateStoWork: kurs.updateStoWork,
        deleteStoWork: kurs.deleteStoWork,
        getTasks: kurs.getTasks,
        getTask: kurs.getTask,
        createTask: kurs.createTask,
        createMultipleTask: kurs.createMultipleTask,
        importTask: kurs.importTask,
        updateTask: kurs.updateTask,
        updateTaskCheck: kurs.updateTaskCheck,
        deleteTask: kurs.deleteTask,
        repeatTask: kurs.repeatTask,
        getTaskTemplates: kurs.getTaskTemplates,
        getTaskTemplate: kurs.getTaskTemplate,
        createTaskTemplate: kurs.createTaskTemplate,
        updateTaskTemplate: kurs.updateTaskTemplate,
        deleteTaskTemplate: kurs.deleteTaskTemplate,
        getRoads: kurs.getRoads,
        getRoad: kurs.getRoad,
        createRoad: kurs.createRoad,
        updateRoad: kurs.updateRoad,
        deleteRoad: kurs.deleteRoad,
        getRoadParts: kurs.getRoadParts,
        getRoadPart: kurs.getRoadPart,
        getRoadPartWorkTypes: kurs.getRoadPartWorkTypes,
        getRoadPartWorkTypesMultiple: kurs.getRoadPartWorkTypesMultiple,
        checkRoadPartWorkType: kurs.checkRoadPartWorkType,
        createRoadPart: kurs.createRoadPart,
        updateRoadPart: kurs.updateRoadPart,
        deleteRoadPart: kurs.deleteRoadPart,
        getWaybills: kurs.getWaybills,
        getWaybill: kurs.getWaybill,
        createWaybill: kurs.createWaybill,
        updateWaybill: kurs.updateWaybill,
        deleteWaybill: kurs.deleteWaybill,
        getDriversScoreSummary: kurs.getDriversScoreSummary,
        getSupernumerarySituations: kurs.getSupernumerarySituations,
        getSupernumerarySituation: kurs.getSupernumerarySituation,
        createSupernumerarySituation: kurs.createSupernumerarySituation,
        updateSupernumerarySituation: kurs.updateSupernumerarySituation,
        deleteSupernumerarySituation: kurs.deleteSupernumerarySituation,
        importKursVehicles: kurs.importKursVehicles,
        importKursVehiclesGet: kurs.importKursVehiclesGet,
        listObjectVisits: kurs.listObjectVisits,
        getTechnocards: kurs.getTechnocards,
        getTechnocard: kurs.getTechnocard,
        createTechnocard: kurs.createTechnocard,
        updateTechnocard: kurs.updateTechnocard,
        deleteTechnocard: kurs.deleteTechnocard,
        getTechnocardAssigns: kurs.getTechnocardAssigns,
        getTechnocardAssign: kurs.getTechnocardAssign,
        createTechnocardAssign: kurs.createTechnocardAssign,
        updateTechnocardAssign: kurs.updateTechnocardAssign,
        deleteTechnocardAssign: kurs.deleteTechnocardAssign,
        getVehicleFuelEvents: kurs.getVehicleFuelEvents,
        getVehicleFuelLevels: kurs.getVehicleFuelLevels,
        getTaskViolations: kurs.getTaskViolations,
        getSkpdiLogs: kurs.getSkpdiLogs,
        getRoadRepairPart: kurs.getRoadRepairPart,
        skpdiImportTasks: kurs.skpdiImportTasks,
        vehicleFuelRecalc: kurs.vehicleFuelRecalc,
        unitTasksDoneChart: kurs.unitTasksDoneChart,
        dateTasksDoneChart: kurs.dateTasksDoneChart,
        getGeometryFromContract: kurs.getGeometryFromContract,

        unitCountVehiclesChart: kurs.unitCountVehiclesChart,
    },
    work_groups: {
        saveWorkGroup: work_groups.saveWorkGroup,
        getWorkGroups: work_groups.getWorkGroups,
    },
    commdept: {
        getFreeDrivers: commdept.getFreeDrivers,
        getContracts: commdept.getContracts,
        getContract: commdept.getContract,
        createContract: commdept.createContract,
        updateContract: commdept.updateContract,
        deleteContract: commdept.deleteContract,
        getRepairPrograms: commdept.getRepairPrograms,
        getRepairProgram: commdept.getRepairProgram,
        createRepairProgram: commdept.createRepairProgram,
        updateRepairProgram: commdept.updateRepairProgram,
        deleteRepairProgram: commdept.deleteRepairProgram,
        getContractWorks: commdept.getContractWorks,
        getContractWork: commdept.getContractWork,
        createContractWork: commdept.createContractWork,
        updateContractWork: commdept.updateContractWork,
        deleteContractWork: commdept.deleteContractWork,
        getVehicles: commdept.getVehicles,
        getVehicle: commdept.getVehicle,
        createVehicle: commdept.createVehicle,
        updateVehicle: commdept.updateVehicle,
        deleteVehicle: commdept.deleteVehicle,
        getVehicleWorks: commdept.getVehicleWorks,
        getVehicleWork: commdept.getVehicleWork,
        createVehicleWork: commdept.createVehicleWork,
        updateVehicleWork: commdept.updateVehicleWork,
        deleteVehicleWork: commdept.deleteVehicleWork,
        getKursMaintenances: commdept.getKursMaintenances,
        getKursMaintenance: commdept.getKursMaintenance,
        createKursMaintenance: commdept.createKursMaintenance,
        updateKursMaintenance: commdept.updateKursMaintenance,
        deleteKursMaintenance: commdept.deleteKursMaintenance,
        getVehicleMechanisms: commdept.getVehicleMechanisms,
        getVehicleMechanism: commdept.getVehicleMechanism,
        createVehicleMechanism: commdept.createVehicleMechanism,
        updateVehicleMechanism: commdept.updateVehicleMechanism,
        deleteVehicleMechanism: commdept.deleteVehicleMechanism,
        getStos: commdept.getStos,
        getSto: commdept.getSto,
        createSto: commdept.createSto,
        updateSto: commdept.updateSto,
        deleteSto: commdept.deleteSto,
        getStoWorks: commdept.getStoWorks,
        getStoWork: commdept.getStoWork,
        createStoWork: commdept.createStoWork,
        updateStoWork: commdept.updateStoWork,
        deleteStoWork: commdept.deleteStoWork,
        getTasks: commdept.getTasks,
        getTask: commdept.getTask,
        createTask: commdept.createTask,
        importTask: commdept.importTask,
        updateTask: commdept.updateTask,
        updateTaskCheck: commdept.updateTaskCheck,
        deleteTask: commdept.deleteTask,
        repeatTask: commdept.repeatTask,
        getTaskTemplates: commdept.getTaskTemplates,
        getTaskTemplate: commdept.getTaskTemplate,
        createTaskTemplate: commdept.createTaskTemplate,
        updateTaskTemplate: commdept.updateTaskTemplate,
        deleteTaskTemplate: commdept.deleteTaskTemplate,
        getRoads: commdept.getRoads,
        getRoad: commdept.getRoad,
        createRoad: commdept.createRoad,
        updateRoad: commdept.updateRoad,
        deleteRoad: commdept.deleteRoad,
        getRoadParts: commdept.getRoadParts,
        getRoadPart: commdept.getRoadPart,
        getRoadPartWorkTypes: commdept.getRoadPartWorkTypes,
        getRoadPartWorkTypesMultiple: commdept.getRoadPartWorkTypesMultiple,
        checkRoadPartWorkType: commdept.checkRoadPartWorkType,
        createRoadPart: commdept.createRoadPart,
        updateRoadPart: commdept.updateRoadPart,
        deleteRoadPart: commdept.deleteRoadPart,
        getWaybills: commdept.getWaybills,
        getWaybill: commdept.getWaybill,
        createWaybill: commdept.createWaybill,
        updateWaybill: commdept.updateWaybill,
        deleteWaybill: commdept.deleteWaybill,
        getDriversScoreSummary: commdept.getDriversScoreSummary,
        getSupernumerarySituations: commdept.getSupernumerarySituations,
        getSupernumerarySituation: commdept.getSupernumerarySituation,
        createSupernumerarySituation: commdept.createSupernumerarySituation,
        updateSupernumerarySituation: commdept.updateSupernumerarySituation,
        deleteSupernumerarySituation: commdept.deleteSupernumerarySituation,
        importKursVehicles: commdept.importKursVehicles,
        importKursVehiclesGet: commdept.importKursVehiclesGet,
        listObjectVisits: commdept.listObjectVisits,
        getTechnocards: commdept.getTechnocards,
        getTechnocard: commdept.getTechnocard,
        createTechnocard: commdept.createTechnocard,
        updateTechnocard: commdept.updateTechnocard,
        deleteTechnocard: commdept.deleteTechnocard,
        getTechnocardAssigns: commdept.getTechnocardAssigns,
        getTechnocardAssign: commdept.getTechnocardAssign,
        createTechnocardAssign: commdept.createTechnocardAssign,
        updateTechnocardAssign: commdept.updateTechnocardAssign,
        deleteTechnocardAssign: commdept.deleteTechnocardAssign,
        getVehicleFuelEvents: commdept.getVehicleFuelEvents,
        getVehicleFuelLevels: commdept.getVehicleFuelLevels,
        getTaskViolations: commdept.getTaskViolations,
        getSkpdiLogs: commdept.getSkpdiLogs,
        getRoadRepairPart: commdept.getRoadRepairPart,
        skpdiImportTasks: commdept.skpdiImportTasks,
        vehicleFuelRecalc: commdept.vehicleFuelRecalc,
        unitTasksDoneChart: commdept.unitTasksDoneChart,
        dateTasksDoneChart: commdept.dateTasksDoneChart,
    },
    converter: {
        convertHtmlToXls: converter.convertHtmlToXls,
        convertPngToPdf: converter.convertPngToPdf,
    },
    monitoring: {
        getRetrospective: monitoring.getRetrospective,
        monitoringFilterVehicles: monitoring.monitoringFilterVehicles,
        getTripList: monitoring.getTripList,
        getParkingList: monitoring.getParkingList,
        getStopList: monitoring.getStopList,
    },
    complaints: {
        getComplaints: complaints.getComplaints,
        getComplaintById: complaints.getComplaintById,
        updateComplaint: complaints.updateComplaint,
        deleteComplaint: complaints.deleteComplaint,
        updateNSUsernameWithUnitUuid: complaints.updateNSUsernameWithUnitUuid,
        getNSUsernameWithUnitUuid: complaints.getNSUsernameWithUnitUuid,
        getNSUsernameWithEmailPhoneInfo: complaints.getNSUsernameWithEmailPhoneInfo,
    }
};

export function mapDatatablesRequestToMeta(request, columns = null, useFilters = true, showDeleted = false) {

    const page = request.start / request.length + 1;
    const limit = request.length;
    const requestOrder = request.order[0];
    const columnOrder = request.columns[requestOrder.column];
    const order = {
        column: columnOrder.name || columnOrder.data,
        direction: requestOrder.dir
    };
    const search = request.search.value;
    let meta = {
        pagination: {
            page,
            limit
        }
    };
    if (order) {
        meta.order = order;
    }
    if (search) {
        meta.search = search;
    }

    meta.filters = {};
    if (useFilters) {
        const columnSearchRaw = _.pickBy(_.mapValues(_.keyBy(columns ? columns.toArray() : [], 'field'), 'filterValue'), value => (_.isString(value) && !_.isEmpty(value)));
        const columnSearch = _.map(columnSearchRaw, (value, column) => {
            return {value, column};
        });

        if (!_.isEmpty(columnSearch)) {
            meta.column_search = columnSearch;
        }

        meta.column_search && meta.column_search.map((el, index) => {
            _.each(columns.toArray(), (column) => {
                if(el.value === column.filterValue && column.filterType === 'simple') {
                    meta.column_search.splice(index, 1)
                }
            });
            
        })

        if(meta.column_search && !meta.column_search.length) {
           delete meta.column_search
        }

        let filters = {};
        if (columns && columns.size) {

            _.each(columns.toArray(), (column) => {

                if(column.filterCurrentName && column.filterType === 'simple' && column.filterValue && !_.isEmpty(column.filterValue)) {
                    filters[column.filterCurrentName] = column.filterValue;

                } else if (column.filterName && column.filterValue !== undefined && column.filterValue !== '') {
                    filters[column.filterName] = column.filterValue;
                }

            });
        }
        meta.filters = filters;
    }
    if (showDeleted) {
        meta.filters.withTrashed = true;
    }

    console.log('---meta', meta)
    return meta;
}

export function generateMetaHeader({page, limit, search, filters}) {
    const meta = {};
    if (page || limit) {
        meta.pagination = {page, limit};
    }

    if (search) {
        meta.search = search;
    }

    if (filters) {
        meta.filters = filters;
    }

    return meta;
}

export class CycleFetch {
    constructor(fetchResult, cb, delay = 1000) {
        this._fetch = fetchResult;
        this._onFetched = cb;
        this._delay = delay;
        this._runned = true;
        this._timeout = null;
    }

    run() {
        this._runned = true;
        this._fetchOne();
    }

    forceNext() {
        if (this._timeout)
            this._timeout = clearTimeout(this._timeout);

        this._fetchOne();
    }

    forceNextWithDelay(delay) {
        if (this._timeout)
            this._timeout = clearTimeout(this._timeout);

        this._next(delay);
    }

    _fetchOne() {
        if (!this._runned) return;

        const fetchResult = this._fetch();
        if (fetchResult === null) {
            this.forceNextWithDelay(200);
        } else {
            fetchResult.then((result) => {
                if (!this._runned)
                    return;

                this._onFetched(result);
                this._next();
            })
                .catch(() => {
                    if (this._runned)
                        this._next();
                });
        }
    }

    _next(delay = null) {
        if (this._timeout)
            this._timeout = clearTimeout(this._timeout);

        this._timeout = setTimeout(::this._fetchOne, delay || this._delay)
    }

    stop() {
        this._runned = false;
        if (this._timeout)
            this._timeout = clearTimeout(this._timeout);
    }

}

