export const getUserInfo = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let OSName = "Неизвестно";
    let language = window.navigator.language;
    let browser = '';
    const systemMemory = window.navigator.deviceMemory;

    // Check Browser
    if (macosPlatforms.indexOf(platform) !== -1) {
        OSName = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        OSName = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        if (window.navigator.userAgent.indexOf("Windows NT 10.0")!= -1) OSName="Windows 10";
        if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1) OSName="Windows 8.1";
        if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) OSName="Windows 8";
        if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) OSName="Windows 7";
        if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) OSName="Windows Vista";
        if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) OSName="Windows XP";
        if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) OSName="Windows 2000";
    } else if (/Android/.test(userAgent)) {
        OSName = 'Android';
    } else if (/Linux/.test(platform)) {
        OSName = 'Linux';
    } else if (window.navigator.userAgent.indexOf("X11") != -1) OSName="UNIX";

    // Check Language
    if(language.indexOf("en") != -1) {
        language = 'English';
    } else if(language.indexOf("ru") != -1) {
        language = 'Russia';
    }

    function getBrowser(browser) {
        return `${browser} v${userAgent.split(`${browser}/`)[1].substr(0, userAgent.split(`${browser}/`)[1].indexOf(' '))}`;
    }
    // Check Browser
    if((userAgent.indexOf("Opera") || userAgent.indexOf('OPR')) != -1 ) {
        browser = getBrowser('Opera');
    } else if(userAgent.indexOf("Edg") != -1 ) {
        browser = getBrowser('Edg');
    } else if(userAgent.indexOf("Chrome") != -1 ) {
        browser = getBrowser('Chrome');
    } else if(userAgent.indexOf("Safari") != -1) {
        browser = getBrowser('Safari');
    } else if(userAgent.indexOf("Firefox") != -1 ) {
        browser = getBrowser('Firefox');
    } else if((userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true )) {
        browser = getBrowser('IE');
    }

    return {
        OSName,
        systemMemory,
        language,
        browser,
    }
}