import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';

import './best-worst-companies.less'


@propTypes({
  bestOnes: PropTypes.array,
  worstOnes: PropTypes.array
})

export default class BestWorstCompanies extends Component {

  render() {
    const Items = [
      this.renderOne('best', 'Лучшие', this.props.bestOnes),
      this.renderOne('worst', 'Худшие', this.props.worstOnes)
    ];

    return (<div className="BestWorstCompanies">{Items}</div>);
  }

  renderOne(type, label, items){
    const companies = items.map(name => <div key={`c-${name}`}>{name}</div>);

    return (
      <div onClick={() => this.onClick(type)} key={`cc-${type}`}>
        <h4>{label}</h4>
        {companies}
      </div>
    )
  }

  onClick(type){
    console.log('on click', type)
  }

}
