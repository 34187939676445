import {Map} from "immutable";
import {api} from "helpers/api";
import {error, Response, success} from "helpers/response";
import * as storage from "utils/storage";

import _ from 'lodash';

const STATUS_CHECK = 'STATUS_CHECK';
const STATUS_CHECKED = 'STATUS_CHECKED';
const USER_LOG = 'USER_LOG';
const SYSTEM_VERSION = 'SYSTEM_VERSION';

const initialState = new Map({
    system: new Map([])
});

function mapServices(payload) {
    return _.map(payload.services, (response, service) => {
        response.service = service
        return response
    });
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case STATUS_CHECK:
            return state.set('services', action.payload.data);
        case STATUS_CHECKED:
            return state.set('services', action.payload.data);
        case SYSTEM_VERSION:
            return state.set('versions', action.payload.data.versions);
    }

    return state;
}

export const log = (code, comment) => async (dispatch) => {
    try {
        let credits = storage.get('user');
        let response = await api.system.log(code, comment, credits.uuid);

        dispatch({
            type: USER_LOG,
            payload: {}
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

let versionsLoaded = false;

export const getProjectsVersion = () => async (dispatch) => {
    if (versionsLoaded) {
        return;
    }
    versionsLoaded = true;
    try {
        let response = await api.system.getProjectsVersion();

        dispatch({
            type: SYSTEM_VERSION,
            payload: {
                data: response.payload
            }
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

let statusChecked = false;

export const checkServiceStatuses = () => async (dispatch) => {
    if (statusChecked) {
        return;
    }
    statusChecked = true;
    try {
        let response = await api.system.checkServiceStatuses();

        dispatch({
            type: STATUS_CHECK,
            payload: {
                data: mapServices(response.payload)
            }
        });

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const serviceStatusesChecked = (callback) => async (dispatch) => {
    api.system.serviceStatusesChecked((response) => {
        const services = mapServices(response.payload);

        dispatch({
            type: STATUS_CHECKED,
            payload: {
                data: services
            }
        });

        _.isFunction(callback) && callback(services);
    });
};

export const activityStore = (data) => async (dispatch) => {
    try {
        const response = await api.system.activityStore(data);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

let entityNames = [];

export const getEntityNames = (items, full = false) => async (dispatch) => {
    let result = _.filter(_.map(items, (item) => {
        return _.find(entityNames, item);
    }));
    const notCachedItems = _.filter(items, (item) => {
        return !_.find(entityNames, item);
    });
    if (notCachedItems.length > 0) {
        try {
            const response = success(await api.system.getEntityNames({items: notCachedItems, full}));
            const items = response.payload.items;
            entityNames = _.concat(entityNames, items);
            result = _.concat(result, items);
        }
        catch (e) {
        }
    }
    return new Response({
        payload: {
            items: result,
        },
    });
};

export const restoreEntity = (entity, source) => async (dispatch) => {
    try {
        const response = await api.system.restoreEntity(entity, source);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const tableExportCreate = (source, entity, meta = {}) => async (dispatch) => {
    try {
        const response = await api.system.tableExportCreate(source, entity, meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const tableExportGet = (uuid) => async (dispatch) => {
    try {
        const response = await api.system.tableExportGet(uuid);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};

export const tableExportList = (meta = {}) => async (dispatch) => {
    try {
        const response = await api.system.tableExportList(meta);

        return success(response);
    }
    catch (e) {
        return error(e);
    }
};
