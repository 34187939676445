import {rpc} from "./session";

export async function getDictionaryStructure(meta = {}) {
    return await rpc.request('com.rnis.dictionary.action.structure', {}, {meta});
}

export async function getDictionaryMeta(key) {
    return await rpc.request('com.rnis.dictionary.action.meta', {key});
}

export async function getDictionaryList(key, meta) {
    return await rpc.request('com.rnis.dictionary.action.list', {
        dictionary: {key}
    }, {meta});
}

export async function getDictionaryLists(keys, meta) {
    return await rpc.request('com.rnis.dictionary.action.lists', {
        keys
    }, {meta});
}

export async function getDocument(key, uuid) {
    return await rpc.request('com.rnis.dictionary.action.find', {
        dictionary: {key},
        uuid
    });
}

export async function createDocument(document) {
    return await rpc.request('com.rnis.dictionary.action.create', document);
}

export async function updateDocument(document) {
    return await rpc.request('com.rnis.dictionary.action.update', document);
}

export async function deleteDocument(document) {
    return await rpc.request('com.rnis.dictionary.action.delete', document);
}

export async function offDayGet(data) {
    return await rpc.request('com.rnis.dictionary.action.off_day.get', data);
}
