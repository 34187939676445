import _ from "lodash";
import {success, error} from 'helpers/response';
import {rpc} from 'helpers/api/session';

export default async function shortSummaryContractWorkReportCase(args) {
    if (this.state.report.uri === "short_summary_contract_work_report") {
        const [condition, field, originalValue, subject, callback, filters, itemsKey, labelKey, valueKey] = args;

        if (condition === 'select') {
            if (field === 'parameterValues.4' && this.getValue(`parameterValues.6`)) {
                return false;
            }
            if (field === 'parameterValues.5' && this.getValue(`parameterValues.6`)) {
                return false;
            }
            if (field === 'parameterValues.4' && Array.isArray(originalValue) && originalValue.length === 0) {
                await this.setValue('parameterValues.5', undefined);
                await this.setValue('parameterValues.6', undefined);
            }
            if (field === 'parameterValues.6' && !this.getValue(`parameterValues.4`)) {
                this.refs['contracts'] && this.refs['contracts'].reload && this.refs['contracts'].reload();
                const timer = setInterval(() => {
                    if (this.refs['contracts'].refs.item.state.isLoading === false) {
                        this.refs['units'] && this.refs['units'].reload && this.refs['units'].reload();
                        clearInterval(timer)
                    }
                }, 200)
            }
        }

        if (condition === 'load') {
            if(subject === 'com.rnis.geo.action.contract.list') {
                const routes = this.getValue(`parameterValues.6`);
                if (routes !== undefined && routes !== "") {
                    const response = success(await rpc.request('com.rnis.geo.action.route_registry.list', {}, {
                        meta: {
                            filters: {
                                withRouteNumbers: routes.map(el => el.document.number),
                            },
                            response_data: [
                                'items/contract',
                            ],
                        },
                    }));
                    if (response.isOk) {
                        const carriers = _.uniq(response.payload.items.map(el => el.contract.carrier_uuid));
                        localStorage.setItem("carriers", carriers);
                        callback(null, {
                            options: _.sortBy(response.payload.items.map(item => ({
                                label: item.contract.number,
                                value: item.contract.uuid,
                                document: item.contract,
                            })), 'label'),
                            complete: false
                        });
                        return
                    } else {
                        response.showErrors();
                        return
                    }
                }
            }

            if (subject === 'com.rnis.organizational_units.action.units') {
                if (localStorage.getItem('carriers')) {
                    const carriers = localStorage.getItem('carriers');
                    filters.withUuid = carriers.split(',')
                }
                const response = success(await rpc.request(subject, {}, {
                    meta: {
                        filters,
                        pagination: {
                            page: 1,
                            limit: 50,
                        },
                    }
                }));
                localStorage.removeItem("carriers");
                if (response.isOk) {
                    callback(null, {
                        options: _.sortBy(response.payload[itemsKey].map(item => ({
                            label: this.getLabel(item, labelKey),
                            value: _.get(item, valueKey),
                            document: item,
                        })), 'label'),
                        complete: false
                    });
                    return
                } else {
                    response.showErrors();
                    return
                }
            }
        }
    }
}