import React, { Component } from 'react';

import { withRouter } from "react-router";
import moment from "moment";
import formats from "dictionaries/formats";
import _ from "lodash";
import {getPercentForWorksDone} from 'helpers/math';
import {connect} from "react-redux";
import {getTabletWorkSummary, getTabletWorkSummaryPercent} from "store/reducers/reports/tablet";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import {getLayers, getLayerObjects} from "store/reducers/user-map-objects/layer_list";

import "./ExecutedWorksContainer.less";
import Select from "../../../ui/select";
import Datepicker from "../../../ui/form/datepicker";

@connect(state => ({}), {getTabletWorkSummary, getTabletWorkSummaryPercent, getDictionaryList, getLayers, getLayerObjects})

class ExecutedWorksContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isError: false,
            isLoading: false,
            from: this.props.location.query.date_from ? moment(this.props.location.query.date_from).format(formats.DATE_API) : moment().format(formats.DATE_API),
            to: this.props.location.query.date_to ? moment(this.props.location.query.date_to).format(formats.DATE_API) : moment().format(formats.DATE_API),
            normalizedJobsData: [],
            work_types: [],
            work_type_uuid: 'all',
            communal_municipalities_uuid: 'all',
            selected_communal_uuid: this.props.municipality_uuid,
            municipalities: [],
        };
    }

    async componentWillMount() {
        if (this.props.municipality_uuid) {
            this.setState({selected_communal_uuid: this.props.municipality_uuid})
        }
        await this.loadDictionaries([
            'work_types',
        ]);
        await this.loadMunicapalities();
        this.loadData();
    }

    filteredByCommunalUuid(items, hasProperty) {
        return items.filter(item => "communal_municipality_uuid" in item === hasProperty && item.hasOwnProperty('communal_municipality_uuid') === hasProperty);
    }

    async loadMunicapalities() {
        const layers = await this.props.getLayers();
        const Uuid = layers.payload.items.filter(layer => layer.title.includes("Муниципальные образования"))[0].uuid;
        const response = await this.props.getLayerObjects(Uuid);
        if (response.isOk) {
            const withCommunal = this.filteredByCommunalUuid(response.payload.items, true)
            this.setState({
                municipalities: withCommunal
            })
        } else {
            response.showErrors();
        }
    }

    async loadData() {
        this.setState({isLoading: true});
        const municipalityUuid = this.state.selected_communal_uuid ? this.state.selected_communal_uuid : this.state.communal_municipalities_uuid

        const meta = {
            filters: {
              withPeriod: [this.state.from, this.state.to],
            }
        }
      
        if (municipalityUuid !== 'all') {
            meta.filters.withCommunalMunicipalities = [municipalityUuid]
        }
    
        if (this.state.work_type_uuid !== 'all') {
            meta.filters.withWorkTypes = [this.state.work_type_uuid]
        }

        if (window.RNIS_SETTINGS.CITY_MURMANSK) {
            const response = await this.props.getTabletWorkSummaryPercent(meta);
            this.setState({ isLoading: false });
            if (response.isOk) {
                this.setState({
                    data: response.payload.municipalities
                })
                const { municipalities } = response.payload
                let uniqueJobsUuid = []
                let worksData = {}
                municipalities.map(municipalitie => {
                    if (municipalitie.work_types && municipalitie.work_types.length > 0) {
                        worksData[municipalitie.municipality_uuid] = {}
                        municipalitie.work_types.forEach(item => {
                            const { work_type_uuid, percent } = item
                            if (!uniqueJobsUuid.includes(work_type_uuid)) {
                                uniqueJobsUuid.push(work_type_uuid)
                            }
                            worksData[municipalitie.municipality_uuid][work_type_uuid] = percent
                        })
                    }
                })

                uniqueJobsUuid.map(job => {
                    const localWorkType = this.state.work_types.filter(item => item.value === job);
                    const percent = worksData[municipalityUuid][job]

                    this.setState(prevState => ({
                        normalizedJobsData: [ ...prevState.normalizedJobsData, {
                            uuid: job,
                            label: localWorkType.length ? localWorkType[0].label : "Без названия",
                            percent: _.round(percent, 0),
                        } ]
                    }))
                })
            } else {
                response.showErrors();
            }
        }else{
            const response = await this.props.getTabletWorkSummary(meta);
            this.setState({isLoading: false});

            if (response.isOk) {
                this.setState({
                    data: response.payload.items
                })
                const uniqueJobsUuid = _.uniq(response.payload.items.map(item => item.work_type_uuid)).filter(item => item);
                uniqueJobsUuid.map(job => {
                    const localWorkType = this.state.work_types.filter(item => item.value === job);
                    const localAllJobes = response.payload.items.filter(item => item.work_type_uuid === job);

                    const [percent, fact, plan] = getPercentForWorksDone(localAllJobes);
                    this.setState(prevState => ({
                        normalizedJobsData: [...prevState.normalizedJobsData, {
                            uuid: job,
                            label: localWorkType.length ? localWorkType[0].label : "Без названия",
                            fact,
                            plan,
                            percent: _.round(percent, 0),
                        }]
                    }))
                })
            } else {
                response.showErrors();
            }
        }
    }

    async loadDictionaries(dictionaries, component = null, withoutOrder = false) {
        this.setState({dictionariesLoading: true});
        let meta = {
            filters: {
                withComponent: component,
            },
        };
        if (!withoutOrder) {
            meta.order = {
                column: 'name',
                direction: 'asc',
            };
        }
        const response = await this.props.getDictionaryList(dictionaries, meta);
        this.setState({dictionariesLoading: false});
        if (response.isOk) {
            let state = this.state;
            _.each(response.payload.items, (item) => {
                state[item.key] = _.map(item.documents, (document) => ({
                    value: document.uuid,
                    label: document.short_name || document.name,
                    document,
                }));
            });
            this.setState(state);
        } else {
            response.showErrors();
        }
    }

    fromChange = async ({target: {value}}) => {
        await this.setState({from: value, normalizedJobsData: []});

        const query = Object.assign({}, this.props.location.query);
        query.date_from = value;
        this.props.router.replace(`${this.props.location.pathname}?${(new URLSearchParams(query)).toString()}`);
        this.loadData();
    };

    toChange = async ({target: {value}}) => {
        await this.setState({to: value, normalizedJobsData: []});
        const query = Object.assign({}, this.props.location.query);
        query.date_to = value;
        this.props.router.replace(`${this.props.location.pathname}?${(new URLSearchParams(query)).toString()}`);
        this.loadData();
    };

    handleGoBack() {
        localStorage.setItem("previousUrl", location.pathname);
        const date_from = (new Date(this.state.from)).toISOString()
        const date_to = (new Date(this.state.to)).toISOString()
        this.props.router.push(`/analytics/executedworks/map?date_from=${date_from}&date_to=${date_to}`);
    }

    handleLink(item) {
        localStorage.setItem("previousUrl", location.pathname);
        const date_from = (new Date(this.state.from)).toISOString();
        const date_to = (new Date(this.state.to)).toISOString();
        this.props.router.push(`/analytics/roadparts/map?communal=${this.state.selected_communal_uuid}&work_type=${item.uuid}&date_from=${date_from}&date_to=${date_to}`);
    }

    render() {
        return (
            <div className="executed-works">
                <div onClick={() => this.handleGoBack()}className="goback-wrapper"><p>Назад</p></div>
                <div className="select-wrapper">
                    <Select
                        options={_.map(this.state.municipalities, (municipality) => ({
                            value: municipality.communal_municipality_uuid,
                            label: municipality.title
                        }))}
                        value={this.state.selected_communal_uuid}
                        clearable={false}
                        onChange={async (e) => {
                            const value = e ? e.value : null;
                            await this.setState({
                                normalizedJobsData: [],
                                selected_communal_uuid: value,
                            });
                            this.loadData();
                        }}
                    />
                </div>
                <div className="date-wrapper">
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата с:</span>
                        <Datepicker style="light" value={this.state.from} onChange={this.fromChange}/>
                    </div>
                    <div className="date-item widget-datepicker">
                        <span className="date-label">Дата по:</span>
                        <Datepicker style="light" value={this.state.to} onChange={this.toChange}/>
                    </div>
                </div>
                <h3>Виды работ:</h3>
                <ul className="executed-works__list">
                    {this.state.normalizedJobsData.map((item, index) => {
                        return <li onClick={() => this.handleLink(item)} className="executed-works__item" key={index}><span className="executed-works__title">{item.label}</span><span className="executed-works__percent">{item.percent} %</span></li>
                    })}
                </ul>
            </div>
        );
    }
}

export default withRouter(ExecutedWorksContainer)
