import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from "react-redux";

import BaseEditorFormComponent from "components/base/base-editor-form";
import BaseEditor from "components/base/base-editor";
import Block from "components/ui/form/block";
import Accordion from "components/ui/accordion/accordion";
import AccordionItem from "components/ui/accordion/accordion-item";
import {api} from "helpers/api";
import classNames from 'classnames';

import FileReaderInput from 'react-file-reader-input';
import {getRoute, createRoute, updateRoute} from "store/reducers/routes/route_editor";
import {getUnits} from "store/reducers/organizational_units/units";
import {getDictionaryList} from "store/reducers/dictionaries/dictionary";
import ModalTopMenuListItem from "components/ui/modal/modal-top-menu-list-item";
import ModalTopMenuButtonsSeparator from "components/ui/modal/modal-top-menu-buttons-separator";
import ModalTopMenuButton from "components/ui/modal/modal-top-menu-button";
import ModalTopMenuButtons from "components/ui/modal/modal-top-menu-buttons";
import ModalTopMenuListSeparator from "components/ui/modal/modal-top-menu-list-separator";
import currentUser from 'helpers/current-user';
import ModalTopMenuList from "components/ui/modal/modal-top-menu-list";
import * as alerts from "helpers/alerts";
import GlobalLoaderComponent from "components/ui/global-loader";
import PageModal from 'components/ui/page-modal';
import {deleteRouteVariant, getRouteVariants} from "store/reducers/routes/route_variants";
import Column from "components/ui/column";
import BaseTableWithEditorComponent from "components/base/base_table_with_editor";
import TableContainer from "components/ui/Table/Container/TableContainer";
import SlideLeftTransition from "components/ui/transitions/slide-left";
import Editor from "./editor";
import {deleteRouteRegistry, getRouteRegistries} from "store/reducers/kiutr/route_registries";
import {EntityList} from "helpers/entity";
import {getEntityNames} from "store/reducers/system";
import RouteRegistryEditor from "components/modules/kiutr/route_registries/editor";
import IconButton from "components/ui/icon-button";
import ContextTooltip from "components/ui/context-tooltip";
import TableComponent from "components/ui/Table/Component/TableComponent";

@propTypes({
    contractUuid: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
})

@connect(state => ({}), {getRouteRegistries, getEntityNames, deleteRouteRegistry})

export default class KiutrContractRouteRegistriesComponent extends BaseTableWithEditorComponent {

    title = '';

    static contextTypes = {
        resizeModals: PropTypes.func
    };

    constructor(props, context) {
        super(props, context);
    }

    resize() {
        this.context.resizeModals && this.context.resizeModals();
    }

    getEditor() {
        return (
            <Editor
                key="editor"
                {...this.props}
                onClose={::this.closeEditor}
                onSubmit={::this.submitEditor}
                mode={this.state.editorUuid ? 'edit' : 'add'}
                uuid={this.state.editorUuid}
                contractUuid={this.props.contractUuid}
                isPageWithDetect={true}
            />
        );
    }

    getRouteRegistryEditor() {
        return (
            <RouteRegistryEditor
                {...this.props}
                key="route-registry-editor"
                onClose={::this.closeRouteRegistryEditor}
                onSubmit={::this.submitRouteRegistryEditor}
                mode={this.state.routeRegistryEditorModalUuid ? 'edit' : 'add'}
                uuid={this.state.routeRegistryEditorModalUuid}
                contractUuid={this.props.contractUuid}
                fromContract={true}
            />
        );
    }

    getColumns() {
        return this.prepareColumns([

            new Column('Наименование')
                .fromField('route.title')
                .withClassName('link'),

            new Column('Номер маршрута')
                .fromField('route.number'),

            new Column('Вид маршрута')
                .fromField('route.route_kind_uuid')
                .withDrawer(item => this.state.related.get(_.get(item, 'route.route_kind_uuid')))
                .denyColumnFilter(),

            new Column('Статус')
                .fromField('status_uuid')
                .withDrawer(item => this.state.related.get(item.status_uuid))
                .denyColumnFilter(),

        ]);
    }

    async deleteItem(data) {
        return await this.props.deleteRouteRegistry(data);
    }

    async loadData(meta) {
        meta.filters.withContract = this.props.contractUuid;
        meta.response_data = [
            'items/uuid',
            'items/deleted_at',
            'items/status_uuid',
            'items/route/title',
            'items/route/number',
            'items/route/route_kind_uuid',
        ];

        return await this.props.getRouteRegistries(meta);
    }

    render() {
        let table = this.renderTable();

        const editor = this.state.showEditorModal ? this.getEditor() : '';
        const routeRegistryEditor = this.state.showRouteRegistryEditorModal ? this.getRouteRegistryEditor() : '';

        const buttons = (
            <ModalTopMenuButtons>
                <ModalTopMenuList className="top-menu_modal_edit">
                    <ContextTooltip key="base-editor.create" code="base-editor.create" default="Добавить">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_plus"
                            onClick={::this.showEditor}
                        />
                    </ContextTooltip>
                    <ContextTooltip key="base-editor.delete" code="base-editor.delete" default="Удалить">
                        <ModalTopMenuListItem
                            className="b-icon-link_icon_basket"
                            onClick={::this.deleteSelected}
                        />
                    </ContextTooltip>
                    <ModalTopMenuListSeparator key="separator"/>
                </ModalTopMenuList>
                <ContextTooltip key="base-editor.close" code="base-editor.close" default="Отменить">
                    <ModalTopMenuButton
                        className="_close"
                        onClick={::this.props.onClose}
                    />
                </ContextTooltip>
            </ModalTopMenuButtons>
        );

        return (
            <div>
                <PageModal
                    header={{title: 'Список маршрутов', buttons}}
                    className="b-modal-contract contract-route-list-modal"
                    withFade={false}
                    onClose={this.props.onClose}
                >
                    <TableContainer>
                        {table}
                    </TableContainer>
                </PageModal>
                {this.state.showEditorModal ? (
                    <SlideLeftTransition>
                        {editor}
                    </SlideLeftTransition>
                ) : null}
                {this.state.showRouteRegistryEditorModal ? (
                    <SlideLeftTransition>
                        {routeRegistryEditor}
                    </SlideLeftTransition>
                ) : null}
            </div>
        );
    }

    async showRouteRegistryEditor() {
        await this.setState({
            showRouteRegistryEditorModal: true,
            routeRegistryEditorModalUuid: null,
        });
        this.resize();
    }

    async showRouteRegistryEditorEdit() {
        if (!this.refs.table) return;

        const selected = this.refs.table.getWrappedInstance().getSelected().data()[0];
        if (selected) {
            await this.setState({
                showRouteRegistryEditorModal: true,
                routeRegistryEditorModalUuid: selected.uuid,
            });
            this.resize();
        }
    }

    async closeRouteRegistryEditor() {

        await this.setState({
            showRouteRegistryEditorModal: false,
            routeRegistryEditorModalUuid: null,
        });
        this.resize();
    }

    async submitRouteRegistryEditor() {
        this.reload();
        await this.closeRouteRegistryEditor();
    }

    async closeEditor() {
        await this.setState({
            showEditorModal: false,
            editorUuid: null,
        });
        this.resize();
    }

    async showEditorWithUuid(uuid = null) {

        await this.closeEditor();

        this.setState({
            showEditorModal: true,
            editorUuid: uuid,
        });
    }

    async loadRelatedEntities(json, drawCallback) {
        const result = json.data;

        const statuses = _.map(_.filter(_.map(result, 'status_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RouteStatuses\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const routeKinds = _.map(_.filter(_.map(result, 'route.route_kind_uuid')), (uuid) => ({
            class: 'App\\Dictionaries\\Route\\RouteKinds\\Model',
            uuid: uuid,
            source: 'dictionary',
        }));

        const response = await this.props.getEntityNames(_.concat(statuses, routeKinds));

        if (response.isOk) {
            this.state.related.add(response);

            drawCallback(json);
        }
    }

    renderTable() {
        const className = classNames({
            'show-table-filters': this.state.showTableFilters,
        });

        return (
            <div className={className}>
                <TableComponent
                    ref="table"
                    select={this.select}
                    columns={this.state.columns}
                    loadCallback={::this.loadCallbackMiddleware}
                    onColsReordered={::this.onColsReordered}
                    onCheck={::this.selectedRowsRecalc}
                    checkCache={::this.checkCache}
                    query={this.state.query}
                    showTableSearchFooter={this.state.showTableSearchFooter}
                    onColumnFilterChange={::this.onColumnFilterChange}
                    onSettingsLoad={::this.onSettingsLoad}
                    onDblClick={::this.onDblClick}
                    shortPagination={true}
                />
            </div>
        );
    }
}